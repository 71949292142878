/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageUpdateIncidentUpdateRequestBody
 */
export interface StatusPageUpdateIncidentUpdateRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageUpdateIncidentUpdateRequestBody
     */
    message: TextNode;
    /**
     * When this incident update was published to the status page
     * @type {Date}
     * @memberof StatusPageUpdateIncidentUpdateRequestBody
     */
    published_at: Date;
    /**
     * Set to true if the update's timestamp has been edited
     * @type {boolean}
     * @memberof StatusPageUpdateIncidentUpdateRequestBody
     */
    timestamp_updated?: boolean;
}

export function StatusPageUpdateIncidentUpdateRequestBodyFromJSON(json: any): StatusPageUpdateIncidentUpdateRequestBody {
    return StatusPageUpdateIncidentUpdateRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateIncidentUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateIncidentUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': TextNodeFromJSON(json['message']),
        'published_at': (new Date(json['published_at'])),
        'timestamp_updated': !exists(json, 'timestamp_updated') ? undefined : json['timestamp_updated'],
    };
}

export function StatusPageUpdateIncidentUpdateRequestBodyToJSON(value?: StatusPageUpdateIncidentUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': TextNodeToJSON(value.message),
        'published_at': (value.published_at.toISOString()),
        'timestamp_updated': value.timestamp_updated,
    };
}

