/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EngineEvaluateJavascriptResponseBody
 */
export interface EngineEvaluateJavascriptResponseBody {
    /**
     * JSON result of executing the expression
     * @type {string}
     * @memberof EngineEvaluateJavascriptResponseBody
     */
    result: string;
}

export function EngineEvaluateJavascriptResponseBodyFromJSON(json: any): EngineEvaluateJavascriptResponseBody {
    return EngineEvaluateJavascriptResponseBodyFromJSONTyped(json, false);
}

export function EngineEvaluateJavascriptResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineEvaluateJavascriptResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': json['result'],
    };
}

export function EngineEvaluateJavascriptResponseBodyToJSON(value?: EngineEvaluateJavascriptResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
    };
}

