import {
  drawerUrlFor,
  IntegrationConfigFor,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Badge, BadgeSize, BadgeTheme, Card, IconEnum } from "@incident-ui";
import { ReactElement } from "react";
import { IntegrationSettingsReconnectionReasonEnum as ReconnectionReasonEnum } from "src/contexts/ClientContext";

export const IntegrationCard = ({
  integration,
}: {
  integration: IntegrationsListObject;
}): ReactElement => {
  const config = IntegrationConfigFor(integration.provider);
  const navigate = useOrgAwareNavigate();
  const reinstallNeeded =
    integration.reconnection_reason !== ReconnectionReasonEnum.Empty &&
    integration.installed;

  return (
    <button
      className="cursor-pointer"
      // Use an onClick so we can replace the current URL with the drawer URL to avoid adding
      // tonnes of stuff to the history stack.
      onClick={() =>
        navigate(drawerUrlFor(integration.provider), { replace: true })
      }
    >
      <Card
        compact={!integration.installed}
        icon={config.icon}
        title={config.label}
        color={ColorPaletteEnum.SlateOnWhite}
        hexColor={config.hexColor}
        description={config.description}
        error={reinstallNeeded}
        footerNode={
          <CardHeaderAccessory
            reinstallNeeded={reinstallNeeded}
            installed={integration.installed}
          />
        }
      />
    </button>
  );
};

const CardHeaderAccessory = ({
  installed,
  reinstallNeeded,
}: {
  installed: boolean;
  reinstallNeeded: boolean;
}) => {
  if (reinstallNeeded) {
    return (
      <Badge
        icon={IconEnum.Warning}
        theme={BadgeTheme.Warning}
        size={BadgeSize.ExtraSmall}
        className="float-left"
      >
        Connection problem
      </Badge>
    );
  }

  if (installed) {
    return (
      <Badge
        icon={IconEnum.Tick}
        theme={BadgeTheme.Success}
        size={BadgeSize.ExtraSmall}
        className="float-left"
      >
        Connected
      </Badge>
    );
  }

  return null;
};
