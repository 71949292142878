import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter } from "@incident-ui";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentLifecycle,
  IncidentLifecyclesCreateRequestBody,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const LifecycleCreateModal = ({
  lifecycles,
  onClose,
}: {
  lifecycles: IncidentLifecycle[];
  onClose: () => void;
}): React.ReactElement => {
  const defaultLifecycle = lifecycles.find((x) => x.is_default);
  if (!defaultLifecycle) {
    throw new Error("Unreachable: no default lifecycle found");
  }

  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<IncidentLifecyclesCreateRequestBody>({
    defaultValues: {
      copy_from_lifecycle_id: defaultLifecycle.id,
    },
  });

  // This allows us to not navigate to the new lifecycle until we've re-fetched
  // the list of all lifecycles. Otherwise, we'll error because the (outdated)
  // lifecycle list doesn't contain our new ID.
  const createdId = useRef<string | null>(null);
  const {
    trigger: onSubmit,
    isMutating,
    genericError,
  } = useAPIMutation(
    "incidentLifecyclesList",
    undefined,
    async (apiClient, data: IncidentLifecyclesCreateRequestBody) => {
      const created = await apiClient.incidentLifecyclesCreate({
        createRequestBody: data,
      });
      createdId.current = created.incident_lifecycle.id;
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        navigate(`/settings/lifecycle/${createdId.current}`);
      },
    },
  );

  return (
    <Form.Modal<IncidentLifecyclesCreateRequestBody>
      formMethods={formMethods}
      analyticsTrackingId="incident-lifecycle-create"
      onSubmit={onSubmit}
      genericError={genericError}
      saving={isMutating}
      onClose={onClose}
      title="Create lifecycle"
      footer={<ModalFooter confirmButtonType="submit" onClose={onClose} />}
    >
      <InputV2 formMethods={formMethods} name="name" label="Name" required />
      <StaticSingleSelectV2
        formMethods={formMethods}
        required
        name="copy_from_lifecycle_id"
        label="Copy from another lifecycle"
        options={lifecycles.map((l) => ({ label: l.name, value: l.id }))}
      />
    </Form.Modal>
  );
};
