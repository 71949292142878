import {
  OnCallNotificationMethod,
  OnCallNotificationsConfirmMethodVerificationRequestBody,
  OnCallNotificationsResetMethodVerificationRequestBodyPhoneVerificationTypeEnum,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Button, ButtonTheme, Txt } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { intervalToDuration, isBefore } from "date-fns";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { tcx } from "src/utils/tailwind-classes";

import { useAPI, useAPIMutation } from "../../../utils/swr";
import { useRevalidate } from "../../../utils/use-revalidate";

type VerifyNotificationMethodFormData =
  OnCallNotificationsConfirmMethodVerificationRequestBody;
export const VerifyNotificationMethodModal = ({
  method: defaultMethod,
  onClose,
}: {
  method: OnCallNotificationMethod;
  onClose: () => void;
}) => {
  const refetchNotificationMethods = useRevalidate([
    "onCallNotificationsShowMethod",
    "onCallNotificationsListRules",
    "onCallNotificationsListMethodsV2",
    "onCallOnboardingCurrentOnboardingState",
  ]);

  // The method passed in to this component might get outdated _by this
  // component itself_: e.g. if we trigger a reset of the verification code we
  // need to reload the method to get the new verification_available_at time.
  const {
    data: { method },
  } = useAPI(
    "onCallNotificationsShowMethod",
    { id: defaultMethod.id },
    { fallbackData: { method: defaultMethod } },
  );

  const formMethods = useForm<VerifyNotificationMethodFormData>();

  const {
    trigger: onSubmit,
    isMutating,
    genericError: verifyGenericErr,
  } = useAPIMutation(
    "onCallNotificationsListMethodsV2",
    undefined,
    async (
      apiClient,
      confirmMethodVerificationRequestBody: OnCallNotificationsConfirmMethodVerificationRequestBody,
    ) => {
      await apiClient.onCallNotificationsConfirmMethodVerification({
        id: method?.id ?? "",
        confirmMethodVerificationRequestBody,
      });
      await refetchNotificationMethods();
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        onClose();
      },
    },
  );

  const {
    trigger: resetVerification,
    isMutating: isResetting,
    genericError: resetGenericErr,
  } = useAPIMutation(
    "onCallNotificationsListMethodsV2",
    undefined,
    async (apiClient, method: OnCallNotificationMethod) => {
      await apiClient.onCallNotificationsResetMethodVerification({
        id: method?.id ?? "",
        resetMethodVerificationRequestBody: {
          phone_verification_type:
            OnCallNotificationsResetMethodVerificationRequestBodyPhoneVerificationTypeEnum.Sms,
        },
      });
      await refetchNotificationMethods();
    },
    {
      onSuccess: () => formMethods.clearErrors(),
    },
  );

  const prevCode = useRef(formMethods.getValues("code"));
  formMethods.watch((values, { name }) => {
    if (isMutating) {
      return;
    }
    if (name !== "code") {
      return;
    }

    const newCode = values.code;
    if (!newCode) {
      return;
    }

    // Without this, this callback runs way way too much!
    if (newCode === prevCode.current) {
      return;
    }
    prevCode.current = newCode;

    if (newCode.length === 6) {
      onSubmit({ code: newCode });
    }
  });

  const genericError = verifyGenericErr || resetGenericErr;

  return (
    <Form.Modal
      title={`Verify ${method?.method_type}`}
      analyticsTrackingId={"verify-on-call-notification-method-modal"}
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={onSubmit}
      saving={isMutating}
      genericError={genericError}
    >
      <InputV2
        formMethods={formMethods}
        label={"Verification code"}
        helptext={
          <span>
            Please input the 6 digit code sent to{" "}
            <span className="font-semibold">
              {parsePhoneNumber(
                method.phone_number || "",
              ).formatInternational()}
            </span>
          </span>
        }
        name={"code"}
        type={InputType.Number}
        autoComplete="one-time-code"
        inputMode="numeric"
        maxLength={6}
        pattern="\d{6}"
        autoFocus
        className={tcx({ "animate-pulse": isMutating })}
        inputClassName="text-center tabular-nums tracking-[1em]"
        placeholder="000000"
      />
      <Txt grey xs>
        Didn&apos;t receive a code?{" "}
        <RetryButton
          availableAt={method.verification_available_at}
          isResetting={isResetting}
          onReset={() => resetVerification(method)}
        />
      </Txt>
    </Form.Modal>
  );
};

const RetryButton = ({
  availableAt,
  isResetting,
  onReset,
}: {
  availableAt: Date | undefined;
  isResetting: boolean;
  onReset: () => void;
}) => {
  const now = useNow(availableAt !== undefined);
  const inCoolOff = availableAt && isBefore(now, availableAt);

  if (!inCoolOff) {
    return (
      <Button
        loading={isResetting}
        theme={ButtonTheme.Naked}
        className={"underline text-xs"}
        analyticsTrackingId={"verification-code-another"}
        onClick={onReset}
      >
        Send another
      </Button>
    );
  }

  const timeLeftSeconds = intervalToDuration({
    start: now,
    end: availableAt,
  }).seconds;
  return (
    <Button
      disabled
      theme={ButtonTheme.Naked}
      className={"underline text-xs"}
      analyticsTrackingId={"verification-code-another"}
    >
      Send another in {timeLeftSeconds} seconds
    </Button>
  );
};

const useNow = (enabled: boolean) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    if (!enabled) return () => null;
    const timeout = setTimeout(() => {
      setNow(new Date());
    }, 1000);

    return () => clearTimeout(timeout);
  }, [enabled, now]);

  return now;
};
