import {
  CustomField,
  InternalStatusPageComponent,
  InternalStatusPageComponentGroup,
  InternalStatusPageComponentOrGroup,
} from "@incident-io/api";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  StackedList,
} from "@incident-ui";
import { compact } from "lodash";
import React, { useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { groupIsHidden as groupHiddenUtil } from "../../../../utils/use-internal-status-page-structure";
import { expressionToPayload } from "../../../@shared/engine/expressions/expressionToPayload";
import { TooltipButton } from "../shared/TooltipButton";
import { InternalStatusPageFormType } from "./create/CreateInternalStatusPage";

type StructureItem =
  | {
      group: GroupStructureItem;
      option?: never;
    }
  | {
      group?: never;
      option: OptionStructureItem;
    };

type GroupStructureItem = {
  group: { id: string; label: string; hidden: boolean };
  options: OptionStructureItem[];
};

type OptionStructureItem = {
  id: string;
  value: string;
  hidden: boolean;
};

export type StructureFormType = Pick<
  InternalStatusPageFormType,
  | "hidden_custom_field_group_ids"
  | "hidden_catalog_entry_ids"
  | "hidden_catalog_group_values"
  | "hidden_custom_field_option_ids"
  | "component_source_expression_payload"
  | "groups_over_components"
> & { structure?: never }; // This exists just to hold the error on the whole form

export const StructureFormContent = <TFormType extends StructureFormType>({
  selectedField,
  formMethods: rawFormMethods,
  label = "Components",
}: {
  selectedField: CustomField;
  formMethods: UseFormReturn<TFormType>;
  label?: string;
}): React.ReactElement => {
  // Typescript doesn't understand the TFormType extends stuff, so cast it here
  const formMethods = rawFormMethods as UseFormReturn<StructureFormType>;

  const {
    hidden_custom_field_group_ids,
    hidden_custom_field_option_ids,
    hidden_catalog_entry_ids,
    hidden_catalog_group_values,
    component_source_expression_payload,
  } = formMethods.watch();

  const {
    data: structure,
    error,
    isLoading: loading,
  } = useAPI("internalStatusPageBuildStructure", {
    buildStructureRequestBody: {
      component_custom_field_id: selectedField.id,
      hidden_catalog_entry_ids: [],
      hidden_custom_field_group_ids: [],
      hidden_catalog_group_values: [],
      hidden_custom_field_option_ids: [],
      component_source_expression: component_source_expression_payload
        ? expressionToPayload(component_source_expression_payload)
        : undefined,
    },
  });

  // We need to memoise this, otherwise we trigger a _lot_ of effects while the
  // structure is loading
  const components = useMemo(
    () => structure?.structure.components ?? [],
    [structure],
  );
  useValidateVisibleComponents(formMethods, components);

  if (loading || !structure) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return <GenericErrorMessage error={error} />;
  }

  const options = structure.structure.components;

  // This wraps formMethods.setValue to force it to mark the field as dirty when
  // we're changing it. By default, programatically setting a field value
  // doesn't dirty it, because react-hook-form assumes that's likely to be
  // driven by the data in the API changing, rather than a form interaction.
  // this is slow to typecheck and not worth it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValue = (name: any, value: any) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formMethods.setValue(name as any, value as any, { shouldDirty: true });

  const setGroupHidden = (
    group: InternalStatusPageComponentGroup,
    hidden: boolean,
  ) => {
    if (structure.structure.from_catalog) {
      if (hidden) {
        setValue("hidden_catalog_group_values", [
          ...hidden_catalog_group_values,
          group.label,
        ]);
      } else {
        setValue(
          "hidden_catalog_group_values",
          hidden_catalog_group_values.filter((label) => label !== group.label),
        );
      }
    } else {
      if (!group.custom_field_group_id) {
        throw new Error(
          "Unreachable: expected custom field group ID for non-catalog group",
        );
      }
      if (hidden) {
        setValue("hidden_custom_field_group_ids", [
          ...hidden_custom_field_group_ids,
          group.custom_field_group_id,
        ]);
      } else {
        setValue(
          "hidden_custom_field_group_ids",
          hidden_custom_field_group_ids.filter(
            (id) => id !== group.custom_field_group_id,
          ),
        );
      }
    }
  };

  const setComponentHidden = (
    option: InternalStatusPageComponent,
    hidden: boolean,
  ) => {
    if (structure.structure.from_catalog) {
      if (!option.catalog_entry_id) {
        throw new Error(
          "Unreachable: expected catalog entry ID for catalog option",
        );
      }
      if (hidden) {
        setValue("hidden_catalog_entry_ids", [
          ...hidden_catalog_entry_ids,
          option.catalog_entry_id,
        ]);
      } else {
        setValue(
          "hidden_catalog_entry_ids",
          hidden_catalog_entry_ids.filter(
            (entryId) => entryId !== option.catalog_entry_id,
          ),
        );
      }
    } else {
      if (!option.custom_field_option_id) {
        throw new Error(
          "Unreachable: expected catalog entry ID for catalog option",
        );
      }
      if (hidden) {
        setValue("hidden_custom_field_option_ids", [
          ...hidden_custom_field_option_ids,
          option.custom_field_option_id,
        ]);
      } else {
        setValue(
          "hidden_custom_field_option_ids",
          hidden_custom_field_option_ids.filter(
            (id) => id !== option.custom_field_option_id,
          ),
        );
      }
    }
  };

  const componentIsHidden = (
    component: InternalStatusPageComponent,
  ): boolean => {
    if (structure.structure.from_catalog) {
      if (!component.catalog_entry_id) {
        throw new Error(
          "Unreachable: expected catalog entry ID for catalog option",
        );
      }
      return (
        hidden_catalog_entry_ids?.includes(component.catalog_entry_id) ?? false
      );
    } else {
      if (!component.custom_field_option_id) {
        throw new Error(
          "Unreachable: expected option ID for non-catalog field",
        );
      }
      return (
        hidden_custom_field_option_ids?.includes(
          component.custom_field_option_id,
        ) ?? false
      );
    }
  };

  const numberOfComponentsIncludingGrouped = options.reduce(
    (numComponents, item) => {
      if (item.group) {
        numComponents += item.group.components.length;
      }
      if (item.component) {
        numComponents++;
      }
      return numComponents;
    },
    0,
  );

  const groupIsHidden = (group: InternalStatusPageComponentGroup): boolean =>
    groupHiddenUtil(
      group,
      structure,
      hidden_catalog_group_values,
      hidden_custom_field_group_ids,
    );

  return (
    <Form.InputWrapper
      name="structure"
      label={label}
      helptext={
        <>
          The components on your page have been defined by the custom field you
          selected. You can hide individual components or groups if you
          don&apos;t want them to appear on your page.
        </>
      }
    >
      <StackedList className="w-full">
        {options.map((item, idx) => {
          if (item.group) {
            return (
              <StructureGroup
                group={item.group}
                hidden={groupIsHidden(item.group)}
                key={idx}
                setHidden={(hidden: boolean) =>
                  setGroupHidden(
                    item.group as InternalStatusPageComponentGroup,
                    hidden,
                  )
                }
                getComponentHidden={componentIsHidden}
                setComponentHidden={setComponentHidden}
              />
            );
          } else if (item.component) {
            return (
              <StructureItem
                component={item.component}
                hidden={componentIsHidden(item.component)}
                key={idx}
                setHidden={(hidden: boolean) =>
                  setComponentHidden(
                    item.component as InternalStatusPageComponent,
                    hidden,
                  )
                }
              />
            );
          } else {
            throw new Error("Unreachable: expected group or component");
          }
        })}
      </StackedList>

      {structure.structure.total_components_count >
        numberOfComponentsIncludingGrouped && (
        <Callout theme={CalloutTheme.Warning} className="mt-3">
          Your custom field has {structure.structure.total_components_count}{" "}
          options. We&apos;ll only display the first{" "}
          {numberOfComponentsIncludingGrouped} on your status page.
        </Callout>
      )}
    </Form.InputWrapper>
  );
};

const useValidateVisibleComponents = (
  formMethods: UseFormReturn<StructureFormType>,
  options: InternalStatusPageComponentOrGroup[],
) => {
  const componentIsVisible = (
    formState: StructureFormType,
    component: InternalStatusPageComponent,
  ) => {
    const { custom_field_option_id, catalog_entry_id } = component;
    if (
      custom_field_option_id &&
      formState.hidden_custom_field_option_ids.includes(custom_field_option_id)
    ) {
      return false;
    }

    if (
      catalog_entry_id &&
      formState.hidden_catalog_entry_ids.includes(catalog_entry_id)
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    formMethods.register("structure", {
      validate: (_, data) => {
        const visibleOptions = compact(
          options.map((opt) => {
            if (opt.component) {
              return componentIsVisible(data, opt.component) ? opt : null;
            }

            if (opt.group) {
              const { custom_field_group_id, label } = opt.group;
              if (
                custom_field_group_id &&
                data.hidden_custom_field_group_ids.includes(
                  custom_field_group_id,
                )
              ) {
                return null;
              }
              if (data.hidden_catalog_group_values.includes(label)) {
                return null;
              }

              const visibleContents = opt.group.components.filter((comp) =>
                componentIsVisible(data, comp),
              );
              if (visibleContents.length === 0) return null;

              return opt;
            }

            return opt;
          }),
        );

        if (visibleOptions.length === 0) {
          return "At least one component must be visible";
        }

        return undefined;
      },
    });

    return () => formMethods.unregister("structure");
  }, [formMethods, options]);
};

const StructureGroup = ({
  group,
  hidden,
  setHidden,
  getComponentHidden,
  setComponentHidden,
}: {
  group: InternalStatusPageComponentGroup;
  hidden: boolean;
  setHidden: (hidden: boolean) => void;
  getComponentHidden: (component: InternalStatusPageComponent) => boolean;
  setComponentHidden: (
    component: InternalStatusPageComponent,
    hidden: boolean,
  ) => void;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "py-3",
        hidden ? "line-through text-slate-600" : "bg-white",
      )}
    >
      <div className="w-full px-3 mb-2 flex text-sm justify-between">
        <div className="flex items-center">
          <Icon
            id={IconEnum.FolderNoPadding}
            size={IconSize.Small}
            className="text-slate-400 shrink-0 self-center !mr-2"
          />
          {group.label}
        </div>
        <TooltipButton
          title={hidden ? "Show group" : "Hide group"}
          onClick={() => setHidden(!hidden)}
          iconName={hidden ? IconEnum.Hide : IconEnum.View}
        />
      </div>
      <StackedList className="ml-7 mr-2">
        {group.components.map((component, idx) => (
          <StructureItem
            component={component}
            hidden={getComponentHidden(component)}
            key={idx}
            setHidden={(hidden: boolean) =>
              setComponentHidden(component, hidden)
            }
            groupHidden={hidden}
          />
        ))}
      </StackedList>
    </div>
  );
};

const StructureItem = ({
  component,
  hidden,
  setHidden,
  groupHidden = false,
}: {
  component: InternalStatusPageComponent;
  hidden: boolean;
  setHidden: (hidden: boolean) => void;
  groupHidden?: boolean;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "w-full px-3 py-3 flex text-sm justify-between",
        hidden || groupHidden ? "line-through text-slate-600" : "bg-white",
      )}
    >
      {component.label}
      <TooltipButton
        title={
          groupHidden
            ? "Show group to show this component"
            : hidden
            ? "Show component"
            : "Hide component"
        }
        disabled={groupHidden}
        onClick={() => {
          setHidden(!hidden);
        }}
        iconName={hidden || groupHidden ? IconEnum.Hide : IconEnum.View}
      />
    </div>
  );
};
