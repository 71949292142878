import React from "react";
import { Route, Routes } from "react-router";

import { InternalStatusPageDetailsPage } from "../legacy/status-page-config/internal/InternalStatusPageDetailsPage";
import { StatusPageIncidentDetails } from "./incidents/view/StatusPageIncidentDetails";
import { CustomerPageCreate } from "./sub-pages/create/CustomerPageCreate";
import { ParentPageCreate } from "./sub-pages/create/ParentPageCreate";
import { StatusPageDetailsPage } from "./view/StatusPagesDetailsViewPage";
import {
  CreateStatusPageModals,
  StatusPagesHomepage,
} from "./view/StatusPagesHomepage";

export const StatusPagesRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route
          path={""}
          element={<StatusPagesHomepage showCreateModal={null} />}
        />
        <Route
          path={"create"}
          element={
            <StatusPagesHomepage
              showCreateModal={CreateStatusPageModals.Choose}
            />
          }
        />
        <Route
          path={"create-public"}
          element={
            <StatusPagesHomepage
              showCreateModal={CreateStatusPageModals.ChoosePublic}
            />
          }
        />
        <Route
          path={"public/sub-pages/create"}
          element={<ParentPageCreate />}
        />
        <Route
          path={"public/create"}
          element={
            <StatusPagesHomepage
              showCreateModal={CreateStatusPageModals.PublicStandalone}
            />
          }
        />
        <Route path={"customer/create"} element={<CustomerPageCreate />} />
        <Route
          path={"internal/create"}
          element={
            <StatusPagesHomepage
              showCreateModal={CreateStatusPageModals.Internal}
            />
          }
        />
        <Route
          path=":id/incident/:incidentId/*"
          element={<StatusPageIncidentDetails />}
        />
        <Route
          path="internal/:pageId/*"
          element={<InternalStatusPageDetailsPage />}
        />
        <Route path=":pageId/*" element={<StatusPageDetailsPage />} />
      </Routes>
    </>
  );
};
