export const AutoCloseDelayOptions = [
  {
    label: "1 day",
    sort_key: "1",
    value: "1",
  },
  {
    label: "3 days",
    sort_key: "2",
    value: "3",
  },
  {
    label: "7 days",
    sort_key: "3",
    value: "7",
  },
  {
    label: "14 days",
    sort_key: "4",
    value: "14",
  },
  {
    label: "30 days",
    sort_key: "5",
    value: "30",
  },
];
