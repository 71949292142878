/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalPagerDutyIncident
 */
export interface ExternalPagerDutyIncident {
    /**
     * Body of the PagerDuty Incident
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    body: string;
    /**
     * Escalation policy used, if any
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    escalation_policy_name?: string;
    /**
     * Unique number of the PagerDuty Incident
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    incident_number: string;
    /**
     * PagerDuty service linked to the incident
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    service: string;
    /**
     * Current status of the PagerDuty Incident
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    status: string;
    /**
     * Summary of the PagerDuty Incident
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    summary: string;
    /**
     * Title of the PagerDuty Incident
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    title: string;
    /**
     * Current urgency of the PagerDuty Incident
     * @type {string}
     * @memberof ExternalPagerDutyIncident
     */
    urgency: string;
}

export function ExternalPagerDutyIncidentFromJSON(json: any): ExternalPagerDutyIncident {
    return ExternalPagerDutyIncidentFromJSONTyped(json, false);
}

export function ExternalPagerDutyIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalPagerDutyIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'escalation_policy_name': !exists(json, 'escalation_policy_name') ? undefined : json['escalation_policy_name'],
        'incident_number': json['incident_number'],
        'service': json['service'],
        'status': json['status'],
        'summary': json['summary'],
        'title': json['title'],
        'urgency': json['urgency'],
    };
}

export function ExternalPagerDutyIncidentToJSON(value?: ExternalPagerDutyIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'escalation_policy_name': value.escalation_policy_name,
        'incident_number': value.incident_number,
        'service': value.service,
        'status': value.status,
        'summary': value.summary,
        'title': value.title,
        'urgency': value.urgency,
    };
}

