/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianConnectOrganisationInfo,
    AtlassianConnectOrganisationInfoFromJSON,
    AtlassianConnectOrganisationInfoFromJSONTyped,
    AtlassianConnectOrganisationInfoToJSON,
} from './AtlassianConnectOrganisationInfo';

/**
 * 
 * @export
 * @interface AtlassianConnectAppShowOrganisationsWithInstallationResponseBody
 */
export interface AtlassianConnectAppShowOrganisationsWithInstallationResponseBody {
    /**
     * 
     * @type {Array<AtlassianConnectOrganisationInfo>}
     * @memberof AtlassianConnectAppShowOrganisationsWithInstallationResponseBody
     */
    organisations: Array<AtlassianConnectOrganisationInfo>;
}

export function AtlassianConnectAppShowOrganisationsWithInstallationResponseBodyFromJSON(json: any): AtlassianConnectAppShowOrganisationsWithInstallationResponseBody {
    return AtlassianConnectAppShowOrganisationsWithInstallationResponseBodyFromJSONTyped(json, false);
}

export function AtlassianConnectAppShowOrganisationsWithInstallationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianConnectAppShowOrganisationsWithInstallationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisations': ((json['organisations'] as Array<any>).map(AtlassianConnectOrganisationInfoFromJSON)),
    };
}

export function AtlassianConnectAppShowOrganisationsWithInstallationResponseBodyToJSON(value?: AtlassianConnectAppShowOrganisationsWithInstallationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisations': ((value.organisations as Array<any>).map(AtlassianConnectOrganisationInfoToJSON)),
    };
}

