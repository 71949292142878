import {
  Condition,
  ConditionGroup,
  ConditionGroupPayload,
  ConditionPayload,
} from "src/contexts/ClientContext";

export const conditionsToPayload = (
  conditions: Condition[],
): ConditionPayload[] => {
  return conditions.map((condition): ConditionPayload => {
    return {
      subject: condition.subject.reference,
      operation: condition.operation.value,
      param_bindings: condition.param_bindings,
    };
  });
};

export const conditionsToGroupPayload = (
  conditions: Condition[],
): ConditionGroupPayload[] => {
  if (conditions.length === 0) {
    return [];
  }
  return [
    {
      conditions: conditionsToPayload(conditions),
    },
  ];
};

export const conditionGroupsToConditions = (
  groups: ConditionGroup[] | undefined,
): Condition[] => {
  if (!groups || (groups?.length ?? 0) === 0) {
    return [];
  }

  return groups[0].conditions;
};

export const conditionGroupsToGroupPayloads = (
  conditionGroups: ConditionGroup[],
): ConditionGroupPayload[] => {
  return conditionGroups
    .filter((grp) => grp.conditions?.length > 0)
    .map(conditionGroupToPayload);
};

const conditionGroupToPayload = (
  conditionGroup: ConditionGroup,
): ConditionGroupPayload => {
  return {
    conditions: conditionsToPayload(conditionGroup.conditions),
  };
};
