/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParameterisedResourceOption,
    ParameterisedResourceOptionFromJSON,
    ParameterisedResourceOptionFromJSONTyped,
    ParameterisedResourceOptionToJSON,
} from './ParameterisedResourceOption';

/**
 * 
 * @export
 * @interface CatalogListParameterisedResourceOptionsResponseBody
 */
export interface CatalogListParameterisedResourceOptionsResponseBody {
    /**
     * 
     * @type {Array<ParameterisedResourceOption>}
     * @memberof CatalogListParameterisedResourceOptionsResponseBody
     */
    resources: Array<ParameterisedResourceOption>;
}

export function CatalogListParameterisedResourceOptionsResponseBodyFromJSON(json: any): CatalogListParameterisedResourceOptionsResponseBody {
    return CatalogListParameterisedResourceOptionsResponseBodyFromJSONTyped(json, false);
}

export function CatalogListParameterisedResourceOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListParameterisedResourceOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(ParameterisedResourceOptionFromJSON)),
    };
}

export function CatalogListParameterisedResourceOptionsResponseBodyToJSON(value?: CatalogListParameterisedResourceOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(ParameterisedResourceOptionToJSON)),
    };
}

