import _ from "lodash";
import {
  DependentResource,
  StatusPage,
  StatusPageComponentDependentResource,
  StatusPageDisplayUptimeModeEnum,
  StatusPageStructure,
  StatusPageStructureAndComponentsItemPayload,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";

export type FormType = {
  displayUptimeMode: StatusPageDisplayUptimeModeEnum;
  structureItems: StructureItem[];
  components: Record<string, Component>;
};

export type Component = {
  componentId?: string;
  name: string;
  description: string | null;
};

export type GroupedComponent = {
  id: string;
  // componentKey is the key of the component in the form state.
  componentKey: string;
  displayUptime: boolean;
  hidden: boolean;
};

export type PreviewItem = {
  id: string;
  displayUptimeChart: boolean;
  displayUptimePercentage: boolean;
  hidden: boolean;
  name: string;
  description?: string;
  children?: PreviewItem[];
};

export type StructureItem = {
  id: string;
  displayUptime: boolean;
  hidden: boolean;
} & (
  | {
      groupId: string;
      contents: GroupedComponent[];
      name: string;
      description: string | null;
      componentKey?: never;
    }
  | {
      groupId?: never;
      name?: never;
      description?: never;
      contents?: never;
      // componentKey is a form-state-only random string that lets us manage
      // components that don't exist (yet), as well as those that already exist
      // in the backend. The actual component is stored at
      // `components.{string}`.
      componentKey: string;
    }
);

// ComponentStructureItem asserts that the structure item contains a component
// (not a group)
export type ComponentStructureItem = StructureItem & { componentKey: string };
// GroupStructureItem asserts that the structure item contains a group (not a
// component)
export type GroupStructureItem = StructureItem & { groupId: string };

// EditablePath points to the current thing that is being inline-edited. It's either:
export type EditablePath =
  // A component (by form-state-only key)
  | { componentKey: string; groupId?: never }
  // or a group (by form-state-only ID)
  | { groupId: string; componentKey?: never }
  // or nothing
  | null;

// buildDefaultValues constructs an initial form state based on the structure
// and components on an existing status page from the API.
//
// It generates a set of random component keys and group IDs and rearranges the
// existing data into a react-hook-form friendly format.
export function buildDefaultValues(
  page: Pick<StatusPage, "components" | "display_uptime_mode"> & {
    structure: Pick<StatusPageStructure, "items">;
  },
): FormType {
  const initialComponents: Record<string, Component> = {};
  const componentKeysByID: Record<string, string> = {};
  page.components.forEach((component) => {
    if (!component) return;
    // Generate a form-state-only reference for this component
    const key = uuidv4();
    // Put the component into the form state
    initialComponents[key] = {
      componentId: component.id,
      name: component.name,
      description: component.description || null,
    };

    // Keep track of how we can go from id -> key
    componentKeysByID[component.id] = key;
  });

  const initialItems: StructureItem[] = _.compact(
    _.sortBy(page.structure.items || [], "rank").map(
      (item): StructureItem | undefined => {
        if (item.component) {
          const componentKey = componentKeysByID[item.component.component_id];
          return {
            id: componentKey,
            displayUptime: item.component.display_uptime,
            hidden: item.component.hidden,
            componentKey,
          };
        }

        if (item.group) {
          const groupId = uuidv4();
          const contents = (item.group.components || []).map((component) => {
            const componentKey = componentKeysByID[component.component_id];

            return {
              id: componentKey,
              componentKey,
              displayUptime: component.display_uptime,
              hidden: component.hidden,
            };
          });

          return {
            id: groupId,
            groupId,
            displayUptime: item.group.display_aggregated_uptime,
            hidden: item.group.hidden,
            name: item.group.name,
            description: item.group.description || null,
            contents,
          };
        }
        return undefined;
      },
    ),
  );

  return {
    components: initialComponents,
    structureItems: initialItems,
    displayUptimeMode: page.display_uptime_mode,
  };
}

export function buildStructureAndComponentsItems({
  components,
  structureItems,
}: FormType): StatusPageStructureAndComponentsItemPayload[] {
  const items = _.compact(
    structureItems?.map(
      (item): StatusPageStructureAndComponentsItemPayload | undefined => {
        if (item.componentKey !== undefined) {
          // A non-grouped component just requires converting the component
          // form key to the real ID, than annotating with structural stuff
          // about how to display it
          const component = components[item.componentKey];
          return {
            component: {
              existing_component_id: component.componentId,
              display_uptime: item.displayUptime,
              hidden: item.hidden,
              name: component.name,
              description:
                component.description == null
                  ? undefined
                  : component.description,
            },
          };
        } else if (item.groupId !== undefined) {
          // A group of components requires mapping all the component keys
          // over to real component IDs, annotating those with structural
          // settings, then building the group structure around that.
          const groupComponents = _.compact(
            item.contents.map((groupedComponent) => {
              if (groupedComponent.componentKey == null) return undefined;

              const component = components[groupedComponent.componentKey];
              return {
                existing_component_id: component.componentId,
                display_uptime: groupedComponent.displayUptime,
                hidden: groupedComponent.hidden,
                name: component.name,
                description:
                  component.description == null
                    ? undefined
                    : component.description,
              };
            }),
          );

          return {
            group: {
              components: groupComponents,
              name: item.name,
              description: item.description || undefined,
              display_aggregated_uptime: item.displayUptime,
              hidden: item.hidden,
            },
          };
        } else {
          return assertUnreachable(item);
        }
      },
    ),
  );

  return items;
}

export const buildDependentsForComponent = (
  deps: StatusPageComponentDependentResource[],
): Record<string, DependentResource[] | undefined> => {
  const res: Record<string, DependentResource[] | undefined> = {};
  deps.forEach(({ status_page_component_id, dependent_resource }) => {
    const prev = res[status_page_component_id];
    if (prev === undefined) {
      res[status_page_component_id] = [dependent_resource];
    } else {
      prev.push(dependent_resource);
    }
  });

  return res;
};
