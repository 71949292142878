import {
  closestCenter,
  Collision,
  CollisionDetection,
  DndContext,
  DragOverEvent,
  getFirstCollision,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DependentResource,
  ScopeNameEnum,
  StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum,
  StatusPageThemeEnum,
} from "@incident-io/api";
import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { AddNewButton, ConfirmationDialog, StackedList } from "@incident-ui";
import { captureException } from "@sentry/react";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { v4 as uuidv4 } from "uuid";

import { getPreviewItems } from "../../create/StandalonePageCreateModal";
import { ChooseUptimeDisplayMode } from "./ChooseUptimeDisplayMode";
import { EditableComponent } from "./EditableComponent";
import { GroupRow } from "./GroupRow";
import { Preview } from "./Preview";
import { EditablePath, FormType, StructureItem } from "./utils";

export const ComponentsEditor = ({
  editing,
  saving = false,
  setEditing,
  dependentsForComponent,
}: {
  editing: EditablePath;
  saving?: boolean;
  setEditing: (path: EditablePath) => void;
  dependentsForComponent: Record<string, DependentResource[] | undefined>;
}): React.ReactElement => {
  const [showDependentResources, setShowDependentResources] = useState<{
    name: string;
    resources: DependentResource[];
  } | null>(null);

  const { hasScope } = useIdentity();
  const canConfigure = hasScope(ScopeNameEnum.StatusPagesConfigure);

  const formMethods = useFormContext<FormType>();
  const { control, clearErrors, setError, watch, setValue } = formMethods;

  const formData = watch();
  const { structureItems, components, displayUptimeMode } = formData;

  // Apply a validation rule that you must have at least _one_ visible component or group
  const hasVisibleItems = structureItems.some(({ hidden }) => !hidden);
  useEffect(() => {
    if (hasVisibleItems) {
      clearErrors("structureItems");
    } else {
      setError("structureItems", {
        message: "You must have at least one visible component or group",
      });
    }
  }, [clearErrors, hasVisibleItems, setError]);

  // This controls the array of structure items at the top level.
  const arrayMethods = useFieldArray({ control, name: "structureItems" });

  const addComponent = () => {
    // Generate a random key to link parts of the form together
    const componentKey = uuidv4();

    // Store info about the component itself
    setValue(`components.${componentKey}`, {
      componentId: undefined, // this will get set when we save the form
      name: "",
      description: null,
    });

    // Add this component to the top-level structure
    arrayMethods.append({
      id: componentKey,
      displayUptime: true,
      hidden: false,
      componentKey,
    });

    // Start editing the new component immediately
    setEditing({ componentKey });
  };

  const addGroup = () => {
    // Generate a random ID to tie bits of the form state together
    const groupId = uuidv4();

    // Add the group to the end of the main list
    arrayMethods.append({
      id: groupId,
      groupId,
      displayUptime: false,
      hidden: true,
      name: "",
      description: null,
      contents: [],
    });

    // Start editing the new group name immediately
    setEditing({ groupId });
  };

  const previewItems = getPreviewItems(
    structureItems,
    components,
    displayUptimeMode,
  );

  const hideUptimeToggle = displayUptimeMode === DisplayUptimeModeEnum.Nothing;

  return (
    <>
      {showDependentResources != null ? (
        <ConfirmationDialog
          title={`Delete component`}
          analyticsTrackingId={"delete-sp-component-blocked"}
          isOpen
          onCancel={() => setShowDependentResources(null)}
          onConfirm={() => {
            return; // nothing
          }}
          hideConfirmButton
          cancelButtonText="OK"
        >
          <DependentResourceList
            title={showDependentResources.name}
            requiresDeletionResources={[showDependentResources.resources]}
          />
        </ConfirmationDialog>
      ) : null}
      <div className="text-sm text-slate-700 space-y-4 mb-4">
        <Form.Helptext>
          Components help your customers understand what parts of your system
          are impacted. You can group components of similar types together.
        </Form.Helptext>
      </div>
      {canConfigure ? (
        <div className="space-y-4">
          {structureItems?.length > 0 ? (
            <ComponentList
              arrayMethods={arrayMethods}
              canEdit={false}
              editing={editing}
              setEditing={setEditing}
              saving={saving}
              hideUptimeToggle={hideUptimeToggle}
              dependentsForComponent={dependentsForComponent}
              setShowDependentResources={setShowDependentResources}
            />
          ) : (
            <div className="bg-white border border-brand rounded text-sm text-slate-600 text-center p-3 mx-4">
              You must have at least one visible component on your status page
            </div>
          )}

          <div className="space-x-2">
            <AddNewButton
              title="Add component"
              analyticsTrackingId="create-status-page-component"
              onClick={addComponent}
              iconOnlyOnMobile={false}
            />
            <AddNewButton
              title="Add group"
              analyticsTrackingId="create-status-page-group"
              onClick={addGroup}
              iconOnlyOnMobile={false}
            />
          </div>
          <ChooseUptimeDisplayMode
            formMethods={formMethods}
            name="displayUptimeMode"
            label="Historical Data"
            helptext="How should we show historical data for your components?"
          />
        </div>
      ) : (
        <div className="p-2 text-sm text-center text-slate-400 md:rounded-2 border border-dashed border-slate-400">
          You do not have permission to configure this public status page
        </div>
      )}

      {previewItems.some((i) => !i.hidden) && (
        <>
          <p className="font-medium text-sm mt-4 mb-2">Preview</p>
          <div className="rounded-[6px] bg-surface-tertiary p-6">
            <Preview
              items={previewItems}
              theme={StatusPageThemeEnum.Light}
              showWarning
              {...formData}
            />
          </div>
        </>
      )}
    </>
  );
};

const ComponentList = ({
  canEdit,
  editing,
  setEditing,
  hideUptimeToggle,
  dependentsForComponent,
  setShowDependentResources,
}: {
  arrayMethods: UseFieldArrayReturn<FormType, "structureItems">;
  canEdit: boolean;
  editing: EditablePath;
  hideUptimeToggle: boolean;
  // TODO: add an overlay when we're saving
  saving: boolean;
  setEditing: (path: EditablePath) => void;
  dependentsForComponent: Record<string, DependentResource[] | undefined>;
  setShowDependentResources: (params: {
    name: string;
    resources: DependentResource[];
  }) => void;
}): React.ReactElement => {
  const { setValue, watch } = useFormContext<FormType>();
  const [components, structureItems] = watch(["components", "structureItems"]);

  // This matches the state of the form when not-dragging. During dragging this
  // is the 'drag-applied' state. When the drag ends, we'll push this back into
  // the form. If the drag is cancelled, we'll revert this to the form state.
  const [sortingState, setSortingState] = useState<{
    id: string;
    items: StructureItem[];
  } | null>(null);

  const items = sortingState != null ? sortingState.items : structureItems;

  const deleteGroup = (groupId: string) => {
    // Replace the group with its contents
    setValue(
      "structureItems",
      structureItems.flatMap((item) =>
        item.groupId === groupId
          ? item.contents.map((gc): StructureItem => ({ ...gc }))
          : [item],
      ),
      { shouldDirty: true },
    );
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    // TODO: keyboard
    // useSensor(KeyboardSensor, {
    //   coordinateGetter,
    // })
  );

  const lastOverId = useRef<string | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingGroup =
    sortingState &&
    !!sortingState.items.find(({ groupId }) => groupId === sortingState.id);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const topLevelKeys = items.map(({ componentKey, groupId }) =>
    componentKey === undefined ? groupId : componentKey,
  );
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args: Parameters<CollisionDetection>[0]): Collision[] => {
      if (isSortingGroup) {
        // Nested groups are not allowed, so we just find which position in the top-level list is closest
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) =>
            topLevelKeys.includes(container.id as string),
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id") as string;

      if (typeof overId === "string") {
        const [groupId, mustBeContents] = overId.split(":");
        if (mustBeContents === "contents") {
          // We're trying to drop inside the contents of the group (rather than the group itself - which is to sort)
          const group = items.find(
            (group) => group.groupId === groupId,
          ) as StructureItem & { groupId: string };
          if (group && group.contents.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== groupId &&
                  group.contents
                    .map(({ componentKey }) => componentKey)
                    .includes(container.id as string),
              ),
            })[0]?.id as string;
          }
        } else {
          // Check if we're dropping on a top-level or nested component
        }

        lastOverId.current = overId;

        console.debug("collision", {
          overId,
          groupId,
          pointerIntersections,
          intersections,
        });
        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = sortingState ? sortingState.id : null;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [isSortingGroup, items, sortingState, topLevelKeys],
  );

  const findContainingGroup = (
    id: UniqueIdentifier,
  ): (StructureItem & { groupId: string }) | undefined => {
    return items.find(
      (item) => item.contents?.find((item) => item.id === id),
    ) as StructureItem & { groupId: string };
  };

  const onDragOver = sortingState
    ? (ev: DragOverEvent) => {
        /*
         * What's going on here? Great question.
         * The 'active' (being dragged) object is either:
         * 1. Top-level components
         * 2. Groups
         * 3. Components inside a group
         *
         * The 'over' (thing we're hovering over) thing is slightly different:
         * 1. A top-level component or group (meaning it has a plain ID); or
         * 2. The contents of a group
         *
         * Each combination is valid, except that if the 'active' item is (2)
         * and the 'over' is the contents of a group (2), we treat it the same
         * as its containing group.
         *
         * That leaves 5 possibilities:
         * a. A top-level component over a top-level component or group -> reordering
         * b. A top-level component over the group contents -> moving into a group
         * c. A group over anything -> reordering
         * d. A grouped component over a top-level component or group -> moving out of a group
         * e. A grouped component over the contents of a group -> reordering or moving to another group
         *
         * The rest of this function applies that. First, let's figure out what case we're in
         */
        const { active, over } = ev;
        if (!over) return;
        // overId is the ID of a _droppable_, which could be `componentKey` or
        // `groupId` or `groupId:contents`.
        //
        // We trim off the `:contents` suffix to treat 'over the contents drop
        // area' the same as 'over the group'.
        const overId = (over.id as string).split(":")[0];
        const activeId = active.id as string;

        const activeItem = sortingState.items.find(({ id }) => id === activeId);
        const activeType = activeItem?.componentKey
          ? "top-level-component"
          : activeItem?.groupId
          ? "group"
          : "grouped-component";
        const isInGroup = (id: string) =>
          !!items.find(
            (item) => item.contents?.find((nestedItem) => nestedItem.id === id),
          );
        const overType =
          (over.id as string).split(":")[1] === "contents" ||
          isInGroup(over.id as string)
            ? "group-contents"
            : "top-level-item";

        if (
          activeType === "top-level-component" &&
          overType === "top-level-item"
        ) {
          // This is (a): reordering
          const overIndex = _.findIndex(items, ({ id }) => id === overId);
          const activeIndex = _.findIndex(items, ({ id }) => id === activeId);

          setSortingState({
            id: sortingState.id,
            items: arrayMove(sortingState.items, activeIndex, overIndex),
          });
        } else if (
          activeType === "top-level-component" &&
          overType === "group-contents"
        ) {
          // This is (b): moving into a group
          const group = items.find(
            (item) =>
              item.id === overId ||
              item.contents?.find(({ id }) => id === overId),
          );
          if (!group || !group.groupId) {
            console.error("over group but not able to find group");
            return;
          }

          if (!activeItem) {
            console.error("no active item");
            return;
          }
          if (activeItem.groupId !== undefined) {
            console.error("active is group - unreachable");
            return;
          }

          // If this is the first item being added to the group, we'll infer it's visibility from the item being added
          const hidden =
            group.contents.length === 0 ? activeItem.hidden : group.hidden;
          const displayUptime =
            group.contents.length === 0
              ? activeItem.displayUptime
              : group.displayUptime;

          const newItems = sortingState.items
            .filter((item) => item.id !== activeId)
            .map((item) =>
              item.id === group.id
                ? {
                    ...group,
                    contents: [...group.contents, { ...activeItem }],
                    hidden,
                    displayUptime,
                  }
                : item,
            );

          setSortingState({ id: sortingState.id, items: newItems });

          recentlyMovedToNewContainer.current = true;
        } else if (activeType === "group") {
          // This is (c): reordering (but if it's over group contents, we treat them as the group)
          if (!activeItem) {
            console.error("no active item - but it's a group");
            return;
          }
          const activeGroup =
            activeItem.groupId === undefined
              ? items.find(
                  ({ contents }) =>
                    contents && contents.find(({ id }) => id === activeItem.id),
                )
              : activeItem;
          if (!activeGroup) {
            console.error("no active group");
            return;
          }
          if (activeGroup.groupId === undefined) {
            console.error("active group is not a group");
            return;
          }

          const activeIndex = _.findIndex(
            items,
            ({ id }) => id === activeGroup.id,
          );
          const overIndex = _.findIndex(
            items,
            (item) =>
              item.id === overId ||
              !!item.contents?.find(({ id }) => id === overId),
          );

          setSortingState({
            id: sortingState.id,
            items: arrayMove(sortingState.items, activeIndex, overIndex),
          });
        } else if (
          activeType === "grouped-component" &&
          overType === "top-level-item"
        ) {
          // This is (d): moving out of a group
          const targetIndex = _.findIndex(items, ({ id }) => overId === id);
          const group = findContainingGroup(activeId);
          if (!group || group.groupId === undefined) {
            console.error(
              "moving out of group but activeContainer is not a group",
            );
            return;
          }
          const activeItem = group.contents.find(({ id }) => id === activeId);
          if (!activeItem) {
            console.error("active item is not in the group");
            return;
          }
          if (activeId === overId) {
            // This happens when you've just grouped something
            return;
          }

          const updatedContents = group.contents.filter(
            ({ id }) => id !== activeItem.id,
          );

          // If this group is becoming empty, we want to hide it and not display its uptime
          const hidden = updatedContents.length === 0 ? true : group.hidden;
          const displayUptime =
            updatedContents.length === 0 ? false : group.displayUptime;

          setSortingState({
            id: sortingState.id,
            items: [
              // First insert the item into the top-level list
              ...items.slice(0, targetIndex),
              activeItem,
              ...items.slice(targetIndex),
            ].map((item) =>
              // Then remove it from the relevant group
              item.id === group.id
                ? {
                    ...group,
                    contents: updatedContents,
                    hidden,
                    displayUptime,
                  }
                : item,
            ),
          });
        } else {
          // This is (e): reordering within a group, or moving to another group
          const activeGroup = findContainingGroup(activeId);
          const overGroup = findContainingGroup(overId);
          if (!overGroup || !activeGroup) {
            console.error("missing active / over group", {
              activeGroup,
              overGroup,
            });
            return;
          }

          if (activeGroup.id === overGroup.id) {
            // Sweet: plain reordering within a group
            const group = activeGroup;
            const activeIndex = _.findIndex(
              group.contents,
              ({ id }) => id === activeId,
            );
            const overIndex = _.findIndex(
              group.contents,
              ({ id }) => id === overId,
            );

            setSortingState({
              id: sortingState.id,
              items: sortingState.items.map((item) =>
                item.id === group.id
                  ? {
                      ...group,
                      contents: arrayMove(
                        group.contents,
                        activeIndex,
                        overIndex,
                      ),
                    }
                  : item,
              ),
            });
          } else {
            // We're moving between groups. Bit more fun this.
            const targetIndex =
              _.findIndex(overGroup.contents, ({ id }) => id === overId) || 0;

            if (!activeItem || activeItem.componentKey === undefined) {
              console.error("active item is not a component: odd");
              return;
            }

            const updatedOverGroup = {
              ...overGroup,
              contents: [
                ...overGroup.contents.slice(0, targetIndex),
                { ...activeItem },
                ...overGroup.contents.slice(targetIndex),
              ],
            };
            const updatedActiveGroup = {
              ...activeGroup,
              contents: activeGroup.contents.filter(
                ({ id }) => id !== activeId,
              ),
            };

            setSortingState({
              id: sortingState.id,
              items: sortingState.items.map((item) =>
                item.id === overGroup.id
                  ? updatedOverGroup
                  : item.id === activeGroup.id
                  ? updatedActiveGroup
                  : item,
              ),
            });
            recentlyMovedToNewContainer.current = true;
          }
        }
      }
    : undefined;

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const deleteComponent = (componentKey: string) => {
    const component = components[componentKey];
    const dependentResources = component.componentId
      ? dependentsForComponent[component.componentId] ?? []
      : [];
    if (dependentResources.length > 0) {
      setShowDependentResources({
        name: component.name,
        resources: dependentResources,
      });
      return;
    }

    setValue(
      "structureItems",
      structureItems.filter((item) => item.componentKey !== componentKey),
      { shouldDirty: true },
    );
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      onDragStart={({ active }) => {
        setSortingState({ id: active.id as string, items: structureItems });
      }}
      onDragCancel={() => setSortingState(null)}
      onDragOver={(ev) => {
        try {
          onDragOver && onDragOver(ev);
        } catch (e) {
          captureException(e, { extra: { dragEvent: ev } });
          console.error(e, { dragEvent: ev });
        }
      }}
      onDragEnd={() => {
        if (sortingState) {
          // If the drag ended over something, we should commit. Otherwise revert.
          setValue("structureItems", sortingState.items, { shouldDirty: true });
        }

        // No matter what, clear out the during-drag state
        setSortingState(null);
      }}
    >
      <div style={{ boxSizing: "border-box" }}>
        <SortableContext
          id={"root"}
          items={items.flatMap((item) =>
            item.componentKey !== undefined ? [item] : [item, ...item.contents],
          )}
          strategy={verticalListSortingStrategy}
        >
          <StackedList className="w-full">
            {items.map((structureItem, index) => {
              if (structureItem.componentKey !== undefined) {
                return (
                  <EditableComponent
                    key={structureItem.componentKey}
                    item={structureItem}
                    structurePath={`structureItems.${index}`}
                    editing={editing}
                    setEditing={setEditing}
                    hideUptimeToggle={hideUptimeToggle}
                    displayUptime={structureItem.displayUptime}
                    hidden={structureItem.hidden}
                    deleteComponent={deleteComponent}
                  />
                );
              } else {
                return (
                  <GroupRow
                    key={structureItem.groupId}
                    item={structureItem}
                    canEdit={canEdit}
                    index={index}
                    editing={editing}
                    hideUptimeToggle={hideUptimeToggle}
                    setEditing={setEditing}
                    deleteComponent={deleteComponent}
                    deleteGroup={() => deleteGroup(structureItem.groupId)}
                  />
                );
              }
            })}
          </StackedList>
        </SortableContext>
      </div>
    </DndContext>
  );
};
