/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpsSkipSuggestedFollowUpRequestBody
 */
export interface FollowUpsSkipSuggestedFollowUpRequestBody {
    /**
     * The follow up suggestion ID
     * @type {string}
     * @memberof FollowUpsSkipSuggestedFollowUpRequestBody
     */
    incident_follow_up_suggestion_id: string;
}

export function FollowUpsSkipSuggestedFollowUpRequestBodyFromJSON(json: any): FollowUpsSkipSuggestedFollowUpRequestBody {
    return FollowUpsSkipSuggestedFollowUpRequestBodyFromJSONTyped(json, false);
}

export function FollowUpsSkipSuggestedFollowUpRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsSkipSuggestedFollowUpRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_follow_up_suggestion_id': json['incident_follow_up_suggestion_id'],
    };
}

export function FollowUpsSkipSuggestedFollowUpRequestBodyToJSON(value?: FollowUpsSkipSuggestedFollowUpRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_follow_up_suggestion_id': value.incident_follow_up_suggestion_id,
    };
}

