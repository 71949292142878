import React from "react";
import { Route, Routes } from "react-router";
import { SlackChannelPage } from "src/components/settings/slack-channel/SlackChannelPage";

export const SlackChannelRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<SlackChannelPage />} />
    </Routes>
  );
};
