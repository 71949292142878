import { Button, ButtonTheme, IconEnum } from "@incident-ui";

export const LabelWithReset = ({
  label,
  onReset,
  hasValue,
  canChange = true,
}: {
  label: React.ReactNode;
  onReset: () => void;
  hasValue: boolean;
  canChange?: boolean;
}): React.ReactElement => {
  return (
    <>
      {label}
      {hasValue && (
        <Button
          onClick={onReset}
          icon={IconEnum.Refresh1}
          analyticsTrackingId="jira-change-site"
          theme={ButtonTheme.Unstyled}
          className="float-right text-xs font-medium text-red-600 hover:text-red-800 mt-0.5"
          disabled={!canChange}
        >
          Change
        </Button>
      )}
    </>
  );
};
