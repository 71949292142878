/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueTrackerSyncConfig,
    IssueTrackerSyncConfigFromJSON,
    IssueTrackerSyncConfigFromJSONTyped,
    IssueTrackerSyncConfigToJSON,
} from './IssueTrackerSyncConfig';

/**
 * 
 * @export
 * @interface IssueTrackersUpdateIssueTrackerSyncConfigResponseBody
 */
export interface IssueTrackersUpdateIssueTrackerSyncConfigResponseBody {
    /**
     * 
     * @type {IssueTrackerSyncConfig}
     * @memberof IssueTrackersUpdateIssueTrackerSyncConfigResponseBody
     */
    sync_config: IssueTrackerSyncConfig;
}

export function IssueTrackersUpdateIssueTrackerSyncConfigResponseBodyFromJSON(json: any): IssueTrackersUpdateIssueTrackerSyncConfigResponseBody {
    return IssueTrackersUpdateIssueTrackerSyncConfigResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersUpdateIssueTrackerSyncConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersUpdateIssueTrackerSyncConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sync_config': IssueTrackerSyncConfigFromJSON(json['sync_config']),
    };
}

export function IssueTrackersUpdateIssueTrackerSyncConfigResponseBodyToJSON(value?: IssueTrackersUpdateIssueTrackerSyncConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sync_config': IssueTrackerSyncConfigToJSON(value.sync_config),
    };
}

