/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyReportSchedule,
    PolicyReportScheduleFromJSON,
    PolicyReportScheduleFromJSONTyped,
    PolicyReportScheduleToJSON,
} from './PolicyReportSchedule';

/**
 * 
 * @export
 * @interface PoliciesCreateReportScheduleResponseBody
 */
export interface PoliciesCreateReportScheduleResponseBody {
    /**
     * 
     * @type {PolicyReportSchedule}
     * @memberof PoliciesCreateReportScheduleResponseBody
     */
    report_schedule: PolicyReportSchedule;
}

export function PoliciesCreateReportScheduleResponseBodyFromJSON(json: any): PoliciesCreateReportScheduleResponseBody {
    return PoliciesCreateReportScheduleResponseBodyFromJSONTyped(json, false);
}

export function PoliciesCreateReportScheduleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesCreateReportScheduleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report_schedule': PolicyReportScheduleFromJSON(json['report_schedule']),
    };
}

export function PoliciesCreateReportScheduleResponseBodyToJSON(value?: PoliciesCreateReportScheduleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report_schedule': PolicyReportScheduleToJSON(value.report_schedule),
    };
}

