import { IconEnum } from "@incident-ui";
import _ from "lodash";
import { ExternalResource } from "src/contexts/ClientContext";

import { AttachmentRenderProps } from "./IncidentAttachment";

export const ZendeskTicket = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.zendesk_ticket) {
    throw new Error("Zendesk ticket resource without expected content");
  }

  return {
    icon: IconEnum.Zendesk,
    title: resource.zendesk_ticket.label,
    unsafeBadges: [
      {
        icon: IconEnum.Status,
        text: _.startCase(resource.zendesk_ticket.status),
      },
    ],
  };
};
