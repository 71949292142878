import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { Avatar, AvatarProps } from "./Avatar";

export const SlackTeamAvatar = ({
  className,
  ...rest
}: AvatarProps): React.ReactElement => {
  return (
    <Avatar
      placeholderImg="/slack-team-avatar-placeholder.svg"
      {...rest}
      className={tcx("rounded", className)}
    />
  );
};
