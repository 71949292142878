/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFormEscalateConfig
 */
export interface IncidentFormEscalateConfig {
    /**
     * Default escalation provider
     * @type {string}
     * @memberof IncidentFormEscalateConfig
     */
    default_escalation_provider?: IncidentFormEscalateConfigDefaultEscalationProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormEscalateConfigDefaultEscalationProviderEnum {
    Native = 'native',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty',
    SplunkOnCall = 'splunk_on_call'
}

export function IncidentFormEscalateConfigFromJSON(json: any): IncidentFormEscalateConfig {
    return IncidentFormEscalateConfigFromJSONTyped(json, false);
}

export function IncidentFormEscalateConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormEscalateConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_escalation_provider': !exists(json, 'default_escalation_provider') ? undefined : json['default_escalation_provider'],
    };
}

export function IncidentFormEscalateConfigToJSON(value?: IncidentFormEscalateConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_escalation_provider': value.default_escalation_provider,
    };
}

