import { UserLinkConfirmationStatusEnum as ConfirmationStatus } from "@incident-io/api";
import { GenericErrorMessage, LoadingWrapper } from "@incident-ui";
import { Table, TableHeaderCell } from "@incident-ui/Table/Table";
import { useAPI } from "src/utils/swr";

import { ExternalUserRow } from "./ExternalUserRow";

export const ConnectedAccountsTable = () => {
  const { data, isLoading, error } = useAPI("userLinksListSelf", undefined, {
    fallbackData: { user_links: [] },
  });

  const confirmedLinks = data.user_links
    .filter((link) => link.confirmation_status === ConfirmationStatus.Linked)
    .sort((a, b) => {
      return (a.catalog_type || "") < (b.catalog_type || "") ||
        b.catalog_type === "SlackUser"
        ? 1
        : -1;
    });
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <LoadingWrapper loading={isLoading}>
      <Table
        gridTemplateColumns="min-content 1fr 1fr min-content"
        wrappedInBox
        header={
          <>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell>User</TableHeaderCell>
            <TableHeaderCell>External ID</TableHeaderCell>
            <TableHeaderCell> </TableHeaderCell>
          </>
        }
        data={confirmedLinks}
        renderRow={(link, idx) => (
          <ExternalUserRow
            user={link.external_user}
            editable={link.editable}
            linkID={link.id}
            key={link.id}
            isLastRow={idx === confirmedLinks.length - 1}
          />
        )}
      />
    </LoadingWrapper>
  );
};
