import {
  fetchTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { LoadingModal, ModalFooter } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  SeatDescriptionTypeEnum,
  SelectOption,
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
  UsersCreateRequestBodyBaseRoleSlugEnum,
  UsersCreateRequestBodySeatTypesEnum,
  UsersCreateRequestBodyStateEnum,
  UsersUpdateSeatAndRoleRequestBody,
  UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum as BaseRoleSlugEnum,
  UsersUpdateSeatAndRoleRequestBodyStateEnum as SeatTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  SeatAndRolesSelect,
  SeatAndRolesSelectFormData,
} from "../common/SeatAndRolesSelect";

type Props = {
  onClose: () => void;
  refetchUsers: () => Promise<void>;
};
type FormType = UsersUpdateSeatAndRoleRequestBody & {
  user_id: string;
  seat_types: SeatDescriptionTypeEnum[];
};

export const UserInviteModal = ({
  onClose,
  refetchUsers,
}: Props): React.ReactElement => {
  const { identity } = useIdentity();
  const { onCallOnlyBillingSeats } = useFlags();

  const formMethods = useForm<FormType>({
    defaultValues: {
      state: SeatTypeEnum.Viewer,
      seat_types: [],
      base_role_slug: BaseRoleSlugEnum.User,
    },
  });
  const {
    setError,
    watch,
    formState: { isDirty },
  } = formMethods;

  const selectedUserId = watch("user_id");

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "usersList",
    {},
    async (apiClient, data: FormType) => {
      await apiClient.usersCreate({
        createRequestBody: {
          user_id: data.user_id,
          state: data.state as unknown as UsersCreateRequestBodyStateEnum,
          seat_types: onCallOnlyBillingSeats
            ? data.seat_types.map((seatType) => {
                return seatType as unknown as UsersCreateRequestBodySeatTypesEnum;
              })
            : undefined,
          base_role_slug:
            data.base_role_slug as unknown as UsersCreateRequestBodyBaseRoleSlugEnum,
          custom_role_ids: data.custom_role_ids ?? [],
        },
      });
      await refetchUsers();
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  const apiClient = useClient();
  const getFilteredTypeaheadOptions = async (
    inputValue: string,
  ): Promise<SelectOption[]> => {
    const typeaheadOptions = await fetchTypeaheadOptions(
      apiClient,
      TypeaheadsListTypeaheadTypeEnum.SlackUser,
      inputValue,
    );

    let sortedOptions: SelectOption[] = [];
    if (typeaheadOptions) {
      sortedOptions = _.sortBy(typeaheadOptions.options, "sort_key");
    }

    return sortedOptions;
  };

  const { data: seatsAndRolesResp } = useAPI(
    "usersListAvailableSeatsAndRoles",
    {},
  );

  if (!identity || !seatsAndRolesResp) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <Form.Modal
      onSubmit={onSubmit}
      formMethods={formMethods}
      genericError={genericError}
      onClose={onClose}
      disableQuickClose
      title="Invite user to incident.io"
      analyticsTrackingId="invite-user"
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Invite"
          confirmButtonType="submit"
          disabled={!(isDirty && selectedUserId)}
        />
      }
    >
      <DynamicSingleSelectV2
        formMethods={formMethods}
        className="pt-2 pb-2"
        name="user_id"
        loadOptions={getFilteredTypeaheadOptions}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadsListTypeaheadTypeEnum.SlackUser,
        )}
        placeholder="Select user..."
        required="Please select a user"
      />
      <div className="w-[calc(100%)] h-px bg-surface-tertiary" />
      <SeatAndRolesSelect
        userId={null}
        formMethods={
          formMethods as unknown as UseFormReturn<SeatAndRolesSelectFormData>
        }
        dependentResources={[]}
      />
    </Form.Modal>
  );
};
