import type { SVGProps } from "react";
import * as React from "react";
const SvgUsers = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.247 12.048A5.503 5.503 0 0 0 13.306 9c-.284 0-.56.031-.837.063a6.205 6.205 0 0 0-1.18.277 7.713 7.713 0 0 1 3.43 3.414c.41.811.48 1.74.243 2.614.209-.022.397-.049.545-.083.677-.158 1.188-.289 1.646-.452a1.97 1.97 0 0 0 1.163-1.125 2.023 2.023 0 0 0-.07-1.661v.001ZM13.382 13.432A6.219 6.219 0 0 0 7.807 10a6.219 6.219 0 0 0-5.575 3.432 2.175 2.175 0 0 0-.07 1.802 2.266 2.266 0 0 0 1.359 1.292 13.056 13.056 0 0 0 8.572 0 2.268 2.268 0 0 0 1.357-1.292 2.18 2.18 0 0 0-.068-1.802ZM13.307 2c-.575 0-1.108.171-1.565.452A4.952 4.952 0 0 1 12.807 5.5c0 .83-.222 1.601-.582 2.29.337.131.699.21 1.082.21 1.654 0 3-1.346 3-3s-1.346-3-3-3ZM7.807 9a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
    />
  </svg>
);
export default SvgUsers;
