import { isEmptyBinding } from "@incident-shared/engine";
import {
  Condition,
  ConditionGroup,
  ConditionGroupPayload,
  ConditionPayload,
  Reference,
  StepConfig,
  StepConfigPayload,
  Workflow,
  WorkflowPayload,
  WorkflowPayloadRunsOnIncidentsEnum as RunsOnIncidentsEnum,
  WorkflowPayloadStateEnum,
} from "src/contexts/ClientContext";
import { v4 as uuid4 } from "uuid";

import { expressionToPayload } from "../../../@shared/engine/expressions/expressionToPayload";
import {
  ClonedStep,
  ClonedWorkflow,
  WorkflowFormData,
  WorkflowOnceFor,
  WorkflowStep,
  WorkflowStepGroup,
  WorkflowStepGroupListItem,
  WorkflowStepListItem,
} from "./types";

export function stepGroupsToStepConfigPayload(
  groups: WorkflowStepGroup[],
): StepConfigPayload[] {
  const steps: StepConfigPayload[] = [];
  groups.forEach((group) => {
    steps.push(
      ...group.steps.map((step) =>
        workflowStepToStepPayloadRequestBody(step, group),
      ),
    );
  });
  return steps;
}

function workflowStepToStepPayloadRequestBody(
  s: WorkflowStep,
  group: WorkflowStepGroup,
): StepConfigPayload {
  const { id, name, param_bindings } = s;
  return {
    id,
    name,
    for_each: group.forEach,
    // this is a bit of a hack: when we serialize param bindings, if the
    // array_value is empty, then goa strips it from the payload. That means if
    // you edit a workflow where the final param binding is empty, it'll look
    // invalid to the backend as it ends up as nil. To get around this, we
    // assume anything that's totally empty should really be {array_value:[]}
    param_bindings: param_bindings.map((paramBinding) =>
      isEmptyBinding(paramBinding) ? { array_value: [] } : paramBinding,
    ),
  };
}

// ----------------------------------------------------------------------------------------------
// Marshall from the api client type Workflow to UI type Workflow
// ----------------------------------------------------------------------------------------------
export function workflowResponseBodyToFormWorkflow(
  body: Workflow | ClonedWorkflow,
): WorkflowFormData {
  return {
    ...body,
    runs_on_incidents: body.runs_on_incidents as unknown as RunsOnIncidentsEnum,
    once_for: body.once_for.map(referenceToFormOnceFor),
    condition_groups: body.condition_groups || [],
    step_groups: stepsResponseToWorkflowStepGroups(body.steps),
    trigger: body.trigger.name,
  };
}

function stepsResponseToWorkflowStepGroups(
  steps: StepConfig[] | ClonedStep[],
): WorkflowStepGroup[] {
  const stepGroups: WorkflowStepGroup[] = [];
  let curGroup: WorkflowStepGroup = {
    steps: [],
    key: uuid4(),
    isLoop: false,
  };
  steps.forEach((step) => {
    // If our step is part of a loop...
    if (step.for_each) {
      if (curGroup.forEach === step.for_each) {
        // if that loop aleady exists, add it
        curGroup.steps.push(stepInstanceResponseBodyToWorkflowStep(step));
      } else {
        // otherwise, create a new loop
        curGroup.steps.length && stepGroups.push(curGroup);
        curGroup = {
          steps: [stepInstanceResponseBodyToWorkflowStep(step)],
          key: uuid4(),
          forEach: step.for_each,
          isLoop: true,
        };
      }
    } else {
      // If we're not in a loop, we want to commit
      // the current group and create a new one.
      curGroup.steps.length && stepGroups.push(curGroup);
      curGroup = {
        steps: [stepInstanceResponseBodyToWorkflowStep(step)],
        key: uuid4(),
        isLoop: false,
      };
    }
  });
  // Add the last pending group, if necessary
  curGroup.steps.length && stepGroups.push(curGroup);
  return stepGroups;
}

export function referenceToFormOnceFor(ref: Reference): WorkflowOnceFor {
  return {
    value: ref.key,
    label: ref.label,
    node_label: ref.node_label,
    key: ref.key,
    type: ref.type,
    hide_filter: ref.hide_filter,
    array: ref.array,
  };
}

function stepInstanceResponseBodyToWorkflowStep(
  step: StepConfig | ClonedStep,
): WorkflowStep {
  const { id, name, description, label, params, param_bindings } = step;

  return {
    id,
    name,
    description,
    label,
    key: uuid4(),
    param_bindings,
    params,
  };
}

/**
 * stepGroupToListItem takes a step group and adds
 * rank and id to the group and each of the steps, so they
 * can be drag-and-drop re-ordered
 */
export const stepGroupToListItem = (
  stepGroup: WorkflowStepGroup,
  idx: number,
) => {
  return {
    steps: stepGroup.steps.map((step, stepIdx) => {
      return {
        step,
        rank: stepIdx,
        id: step.key,
      };
    }),
    rank: idx,
    id: stepGroup.key,
    forEach: stepGroup.forEach,
    isLoop: stepGroup.isLoop,
  };
};

/**
 * listItemToStepGroup takes a WorkflowStepGroupListItem,
 * drops the rank and id fields and unwraps the steps
 */
export const listItemToStepGroup = (
  stepGroupListItem: WorkflowStepGroupListItem,
): WorkflowStepGroup => {
  return {
    steps: stepGroupListItem.steps.map((x) => x.step),
    forEach: stepGroupListItem.forEach,
    key: stepGroupListItem.id,
    isLoop: stepGroupListItem.isLoop,
  };
};

/**
 * listItemsToSteps takes a list of WorkflowStepListItems
 * drops the rank and id fields and unwraps the steps
 */
export const listItemsToSteps = (
  steps: WorkflowStepListItem[],
): WorkflowStep[] => steps.map((step) => step.step);

export const formStateToPayloadRequestBody = (
  data: WorkflowFormData,
): WorkflowPayload => {
  const steps = stepGroupsToStepConfigPayload(data.step_groups);
  const onceFor: string[] = data.once_for.map((x) => marshallOnceFor(x));

  return {
    ...data,
    runs_on_incidents: data.runs_on_incidents || RunsOnIncidentsEnum.Created,
    condition_groups:
      data.trigger === "manual"
        ? []
        : marshallConditionGroups(data.condition_groups || []),
    expressions: data.expressions?.map(expressionToPayload) || [],
    include_private_incidents: data.include_private_incidents || false,
    include_test_incidents: data.include_test_incidents || false,
    include_retrospective_incidents:
      data.include_retrospective_incidents || false,
    continue_on_step_error: data.continue_on_step_error || false,
    name: data.name || "",
    once_for: data.trigger === "manual" ? [] : onceFor,
    steps: steps || [],
    folder: data.folder,
    state: data.state as unknown as WorkflowPayloadStateEnum,
  };
};

const marshallConditionGroups = (
  conditionGroups: ConditionGroup[],
): ConditionGroupPayload[] => {
  return conditionGroups
    .filter((grp) => grp.conditions?.length > 0)
    .map(marshallConditionGroup);
};

const marshallConditionGroup = (
  conditionGroup: ConditionGroup,
): ConditionGroupPayload => {
  return {
    conditions: conditionGroup.conditions.map(marshallCondition),
  };
};

const marshallCondition = (condition: Condition): ConditionPayload => {
  return {
    operation: condition.operation.value,
    subject: condition.subject.reference,
    param_bindings: condition.param_bindings,
  };
};

const marshallOnceFor = (onceFor: WorkflowOnceFor): string => {
  return onceFor.key;
};
