import { ScopeNameEnum } from "@incident-io/api";
import { UpgradeRequiredProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { AppStoreButton } from "@incident-shared/stores/AppStoreButton";
import { GooglePlayButton } from "@incident-shared/stores/GooglePlayButton";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Heading,
  Icon,
  IconEnum,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  Txt,
} from "@incident-ui";
import { FrontEndIconEnum } from "@incident-ui/Icon/Icon";
import { SelectionIndicator } from "@incident-ui/SelectionIndicator/SelectionIndicator";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { ScheduleCreateEditDrawer } from "../schedules/ScheduleCreateEditDrawer";
import { ScheduleImportModal } from "../schedules/ScheduleImportModal";
import CreateEscalationPath from "./images/create_escalation_path.png";
import CreateSchedule from "./images/create_schedule.png";
import DarkFlame from "./images/dark-flame.png";
import Glow from "./images/glow.png";
import ImportSchedule from "./images/import_schedule.png";

type TitleCopyAndImageProps = {
  title: string;
  copy: string;
  colorClass: string;
  icon: FrontEndIconEnum;
};
const copyComponentProps: TitleCopyAndImageProps[] = [
  {
    title: "On-call schedules that fit around your team",
    copy: "Ensure the right people are on call at all times with simple but powerful scheduling that supports even the most complex use cases.",
    colorClass: "bg-yellow-100 text-yellow-600",
    icon: IconEnum.CalendarPlus,
  },
  {
    title: "Rock solid notifications",
    copy: "Whether by push notification, phone, email or Slack, you’re always in full control of how you’re notified when something goes wrong.",
    colorClass: "bg-purple-surface text-purple-600",
    icon: IconEnum.Alert,
  },
  {
    title: "Built for humans",
    copy: "With sensible defaults, an intuitive app and crystal clear notifications, on-call is designed to make that 2am call as stress-free as can be.",
    colorClass: "bg-green-surface text-green-600",
    icon: IconEnum.Gift,
  },
];

const TitleCopyAndImage = ({
  title,
  copy,
  colorClass,
  icon,
}: TitleCopyAndImageProps) => {
  return (
    <div className="p-10 flex-1">
      <Icon id={icon} className={`mb-6 h-9 w-9 p-1 rounded ${colorClass}`} />
      <Txt bold className="mb-2">
        {title}
      </Txt>
      <Txt grey>{copy}</Txt>
    </div>
  );
};

export const GetStartedPage = (): React.ReactElement => {
  const [showGetStartedModal, setShowGetStartedModal] = React.useState(false);
  const [showScheduleDrawer, setShowScheduleDrawer] = React.useState(false);
  const [showImportScheduleModal, setShowImportScheduleModal] = useState(false);
  const [externalScheduleImportId, setExternalScheduleImportId] = useState<
    string | undefined
  >(undefined);
  const navigate = useOrgAwareNavigate();
  const { identity } = useIdentity();

  if (!identity) {
    return <Loader />;
  }

  // If they are seeing the get started page they must have 0 schedules so we should only gate it if
  // they are on a legacy plan with 0 users or schedules provisioned.
  const upgradeRequired = identity.feature_gates.on_call_schedules_count === 0;

  const inWaitList =
    upgradeRequired && identity.onboarding_information.requested_on_call;

  const importModal = showImportScheduleModal ? (
    <ScheduleImportModal
      setExternalScheduleImportId={(id) => {
        setExternalScheduleImportId(id);
        setShowScheduleDrawer(true);
      }}
      onClose={() => setShowImportScheduleModal(false)}
    />
  ) : null;

  return (
    <>
      {importModal}
      <div className="bg-white">
        <div className="bg-slate-950 rounded-3xl p-10 flex flex-1 justify-around">
          <AnimatePresence>
            <motion.div
              className="flex-center flex-col justify-center -mt-[210px]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.1 }}
            >
              <div className="relative flex flex-col items-center justify-end -mb-[130px]">
                <div className="max-w-[600px] 2xl:max-w-[800px] z-[5]">
                  <img
                    src={DarkFlame}
                    alt="On-call: get started"
                    width={1494}
                    height={1284}
                  />
                </div>
                <div className="absolute w-full bottom-0 left-[50%]">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 3, ease: "easeInOut" }}
                  >
                    <img
                      src={Glow}
                      alt="On-call: get started"
                      className="-ml-[50%] w-full"
                    />
                  </motion.div>
                </div>
              </div>

              <SplashInner
                onGetStarted={() => setShowGetStartedModal(true)}
                upgradeRequired={upgradeRequired}
                inWaitList={inWaitList}
                upgradeRequiredProps={{
                  featureName: "on call",
                  gate: {
                    type: "numeric",
                    value: identity.feature_gates.on_call_schedules_count,
                    featureNameSingular: "on call",
                  },
                }}
              />
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="flex min-h-[228px]">
          {copyComponentProps.map((props: TitleCopyAndImageProps) => (
            <TitleCopyAndImage key={props.title} {...props} />
          ))}
        </div>
        <GetStartedModal
          isOpen={showGetStartedModal}
          onClose={() => setShowGetStartedModal(false)}
          setShowScheduleDrawer={setShowScheduleDrawer}
          setShowImportScheduleModal={setShowImportScheduleModal}
        />
        <AnimatePresence>
          {showScheduleDrawer && (
            <ScheduleCreateEditDrawer
              onClose={() => {
                setExternalScheduleImportId(undefined);
                setShowScheduleDrawer(false);
              }}
              onScheduleSaved={() => {
                navigate("/on-call/schedules");
              }}
              externalScheduleImportId={externalScheduleImportId}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

const SplashInner = ({
  upgradeRequired,
  upgradeRequiredProps,
  onGetStarted,
  inWaitList,
}: {
  upgradeRequired: boolean;
  upgradeRequiredProps: UpgradeRequiredProps;
  onGetStarted: () => void;
  inWaitList: boolean;
}) => {
  const { trigger: requestAccess, isMutating: requestingAccess } =
    useAPIMutation("identitySelf", undefined, (apiClient) =>
      apiClient.onboardingRequestOnCall(),
    );

  return (
    <div className="max-w-[524px] flex flex-col gap-6 items-center justify-center z-10">
      <Heading
        level={1}
        className="text-5xl text-white font-bold tracking-tight text-center"
      >
        On-call as it should be
      </Heading>
      {/* Don't show the copy if they've just been granted access */}
      {(inWaitList || upgradeRequired) && (
        <Txt className="text-center text-white">
          Connect all of your alerts, configure a schedule for every team and
          have confidence the right people will be notified, every time.
        </Txt>
      )}

      {inWaitList && (
        <>
          <WaitListCallout />
          <AppStoreButtons />
        </>
      )}

      {!inWaitList && (
        <div className="flex gap-3">
          {upgradeRequired ? (
            <>
              <Button
                analyticsTrackingId={"on-call-request-access"}
                loading={requestingAccess}
                onClick={() => requestAccess({})}
                theme={ButtonTheme.UnstyledPill}
                className="bg-alarmalade-600 text-white hover:brightness-90"
              >
                Get On-call
              </Button>
              <Button
                href="https://help.incident.io/en/articles/8887476"
                analyticsTrackingId="on-call-learn-more"
                theme={ButtonTheme.Secondary}
              >
                Learn more
              </Button>
            </>
          ) : (
            <>
              <GatedButton
                disabledTooltipContent="Talk to our customer support to enable this."
                upgradeRequired={
                  // This will always be false, but I'm leaving it here to
                  // make removing the waitlist stuff easier.
                  upgradeRequired
                }
                upgradeRequiredProps={upgradeRequiredProps}
                onClick={onGetStarted}
                analyticsTrackingId="on-call-get-started"
                theme={ButtonTheme.Unstyled}
                requiredScope={ScopeNameEnum.SchedulesCreate}
                className="bg-alarmalade-600 font-medium text-white hover:brightness-90 transition rounded-2 text-sm px-4 py-2"
              >
                Configure On-call
              </GatedButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const WaitListCallout = () => {
  return (
    <div className="bg-surface-invert py-2 px-5 rounded-full">
      <div className="flex gap-3">
        <Txt className="!text-green-500">Access requested</Txt>
        <Txt className="!text-slate-400">&middot;</Txt>
        <Txt className="!text-slate-400">
          We&apos;ll be in touch later, once it&apos;s enabled
        </Txt>
      </div>
    </div>
  );
};

const AppStoreButtons = () => {
  return (
    <div className="flex gap-3">
      <AppStoreButton
        buttonProps={{
          analyticsTrackingId: "on-call-waitlist-app-store",
          className: "!border-0",
        }}
        iconClassName={"fill-white"}
      />
      <GooglePlayButton
        buttonProps={{
          analyticsTrackingId: "on-call-waitlist-google-play",
          className: "!border-0",
        }}
        iconClassName={"fill-white"}
      />
    </div>
  );
};

enum GetStartedOptionEnum {
  CreateEscalationPath = "CreateEscalationPath",
  CreateSchedule = "CreateSchedule",
  ImportSchedule = "ImportSchedule",
}

const GetStartedModal = ({
  isOpen,
  onClose,
  setShowScheduleDrawer,
  setShowImportScheduleModal,
}: {
  isOpen: boolean;
  onClose: () => void;
  setShowScheduleDrawer: (val: boolean) => void;
  setShowImportScheduleModal: (val: boolean) => void;
}) => {
  // Users can get started from an escalation path or a schedule, but we'll default them to a path.
  const [selectedOption, setSelectedOption] = useState<GetStartedOptionEnum>(
    GetStartedOptionEnum.CreateEscalationPath,
  );
  const closeModal = () => {
    setSelectedOption(GetStartedOptionEnum.CreateEscalationPath);
    onClose();
  };
  const navigate = useOrgAwareNavigate();
  return (
    <Modal
      analyticsTrackingId="on-call-get-started"
      isOpen={isOpen}
      onClose={closeModal}
      title="Get started with On-call"
      className="!max-w-[700px]"
    >
      <ModalContent className="!p-6">
        <div className="flex flex-col space-y-3">
          <GetStartedOption
            title="Create an escalation path"
            subtitle="Choose who to notify and in what order when alerts are fired."
            img={CreateEscalationPath}
            selected={
              selectedOption === GetStartedOptionEnum.CreateEscalationPath
            }
            onClick={() =>
              setSelectedOption(GetStartedOptionEnum.CreateEscalationPath)
            }
          />
          <GetStartedOption
            title="Create a schedule"
            subtitle="Create a group of people who take turns being on call."
            img={CreateSchedule}
            selected={selectedOption === GetStartedOptionEnum.CreateSchedule}
            onClick={() =>
              setSelectedOption(GetStartedOptionEnum.CreateSchedule)
            }
          />
          <GetStartedOption
            title="Import a schedule"
            subtitle="Import your existing schedules from PagerDuty or OpsGenie."
            img={ImportSchedule}
            selected={selectedOption === GetStartedOptionEnum.ImportSchedule}
            onClick={() => {
              setSelectedOption(GetStartedOptionEnum.ImportSchedule);
            }}
          />
          <Callout
            showIcon={false}
            theme={CalloutTheme.Plain}
            className="!border-0 p-4 !mt-5 w-full"
            cta={
              <Button
                analyticsTrackingId="on-call-get-started-learn-more"
                theme={ButtonTheme.Secondary}
                openInNewTab
                href="https://help.incident.io/en/articles/8887476-getting-started-with-on-call"
              >
                Learn more
              </Button>
            }
          >
            <div className="flex-col grow">
              <Txt bold>Automate your on-call setup</Txt>
              <Txt grey className="">
                Automatically create escalations from alerts with Alert Routes
              </Txt>
            </div>
          </Callout>
        </div>
      </ModalContent>
      <ModalFooter
        cancelButtonText="Cancel"
        onClose={closeModal}
        confirmButtonText="Get started"
        confirmButtonType="button"
        disabled={!selectedOption}
        onConfirm={() => {
          if (selectedOption === GetStartedOptionEnum.CreateSchedule) {
            setShowScheduleDrawer(true);
            closeModal();
          } else if (
            selectedOption === GetStartedOptionEnum.CreateEscalationPath
          ) {
            navigate("/on-call/escalation-paths/create");
          } else if (selectedOption === GetStartedOptionEnum.ImportSchedule) {
            setShowImportScheduleModal(true);
            closeModal();
          }
        }}
      />
    </Modal>
  );
};

const GetStartedOption = ({
  title,
  subtitle,
  img,
  selected,
  onClick,
}: {
  title: string;
  subtitle: string;
  img: string;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={tcx(
        "flex  items-center relative group border cursor-pointer border-stroke rounded-xl p-6 gap-4",
        { "hover:border-red-300": !selected, "border-red-500": selected },
      )}
      onClick={onClick}
    >
      <SelectionIndicator
        className="absolute top-2 right-2"
        selected={selected}
      />
      <img className="w-12" src={img} alt="On-call get started hero" />
      <div>
        <Heading level={3} className="text-base">
          {title}
        </Heading>
        <Txt grey>{subtitle}</Txt>
      </div>
    </div>
  );
};
