/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnCallOnboardingTaskGroupState,
    OnCallOnboardingTaskGroupStateFromJSON,
    OnCallOnboardingTaskGroupStateFromJSONTyped,
    OnCallOnboardingTaskGroupStateToJSON,
} from './OnCallOnboardingTaskGroupState';

/**
 * 
 * @export
 * @interface OnCallOnboardingState
 */
export interface OnCallOnboardingState {
    /**
     * 
     * @type {OnCallOnboardingTaskGroupState}
     * @memberof OnCallOnboardingState
     */
    android?: OnCallOnboardingTaskGroupState;
    /**
     * 
     * @type {OnCallOnboardingTaskGroupState}
     * @memberof OnCallOnboardingState
     */
    ios?: OnCallOnboardingTaskGroupState;
    /**
     * 
     * @type {OnCallOnboardingTaskGroupState}
     * @memberof OnCallOnboardingState
     */
    web?: OnCallOnboardingTaskGroupState;
}

export function OnCallOnboardingStateFromJSON(json: any): OnCallOnboardingState {
    return OnCallOnboardingStateFromJSONTyped(json, false);
}

export function OnCallOnboardingStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallOnboardingState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'android': !exists(json, 'android') ? undefined : OnCallOnboardingTaskGroupStateFromJSON(json['android']),
        'ios': !exists(json, 'ios') ? undefined : OnCallOnboardingTaskGroupStateFromJSON(json['ios']),
        'web': !exists(json, 'web') ? undefined : OnCallOnboardingTaskGroupStateFromJSON(json['web']),
    };
}

export function OnCallOnboardingStateToJSON(value?: OnCallOnboardingState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'android': OnCallOnboardingTaskGroupStateToJSON(value.android),
        'ios': OnCallOnboardingTaskGroupStateToJSON(value.ios),
        'web': OnCallOnboardingTaskGroupStateToJSON(value.web),
    };
}

