import {
  CatalogEntry,
  CatalogResource,
  CatalogType,
  CatalogTypeAttribute,
  CatalogTypeAttributeModeEnum as AttributeModeEnum,
  CatalogTypeModeEnum as CatalogTypeMode,
  CatalogUpdateEntryRequestBody,
  Resource,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { EngineFormElement } from "@incident-shared/engine";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  MultiTextInput,
  TextEditorRow,
} from "@incident-shared/forms/v2/inputs/MultiTextInput";
import {
  IntegrationConfigFor,
  IntegrationListProvider,
} from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Button, ButtonTheme, IconEnum, Tooltip } from "@incident-ui";
import { DrawerBody, DrawerFooter } from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { InputType } from "@incident-ui/Input/Input";
import { groupBy, isEmpty } from "lodash";
import pluralize from "pluralize";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { ALIASES_HELP_TEXT } from "src/components/catalog/entry-view/CatalogEntryAttributes";
import { useAPIMutation } from "src/utils/swr";
import { assertUnreachable, slugify } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";

import { marshallEntryPayload } from "./marshall";

// We can use the update request for both update and create, because we never
// allow the catalog type ID to be edited in the form, and that's the only thing
// in the create request that isn't in update.
export type FormType = Omit<CatalogUpdateEntryRequestBody, "aliases"> & {
  rank_as_str: string;
  aliases: TextEditorRow[];
};

type Props = CreateEditFormProps<CatalogEntry> & {
  catalogType: CatalogType;
  resources: CatalogResource[];
  onClose: (entryId?: string) => void;
  refetchEntries: () => void;
};

const catalogEntryToFormValues = (entry: CatalogEntry): FormType => {
  const { aliases, ...rest } = entry;
  const mappedAliases = (aliases ?? []).map((alias) => ({
    value: alias,
    key: uuidv4(),
  }));

  return {
    ...rest,
    aliases: mappedAliases.length > 0 ? mappedAliases : [],
    rank_as_str: String(entry.rank),
  };
};

const emptyValueFromType = (type: string) => {
  switch (type) {
    case "String":
      return "";
    case "Number":
      return "";
    case "Boolean":
      return false;
    default:
      return "";
  }
};

// Create a set of default values for the form, based on the catalog type.
const catalogTypeToDefaultValues = (catalogType: CatalogType): FormType => {
  const defaultValues: FormType = {
    name: "",
    attribute_values: {},
    rank_as_str: undefined as unknown as string,
    aliases: [],
  };
  catalogType.schema.attributes.forEach(
    (attr) =>
      (defaultValues[`attribute_values.${attr.id}.value.literal`] =
        emptyValueFromType(attr.type)),
  );

  return defaultValues;
};

export const CatalogEntryCreateEditForm = ({
  mode,
  initialData,
  catalogType,
  resources,
  onClose,
  refetchEntries,
}: Props) => {
  const navigate = useOrgAwareNavigate();

  const defaultValues =
    mode === Mode.Edit
      ? catalogEntryToFormValues(initialData)
      : catalogTypeToDefaultValues(catalogType);
  const formMethods = useForm<FormType>({
    defaultValues,
  });

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const createMutation = useAPIMutation(
    "catalogListEntries",
    { catalogTypeId: catalogType.id },
    async (apiClient, data) => {
      await apiClient.catalogCreateEntry({
        createEntryRequestBody: {
          catalog_type_id: catalogType.id,
          ...marshallEntryPayload(data),
        },
      });
      // We also need to refresh the SWR cache for the entry list with the updated data
      await refetchEntries();
    },
    {
      onSuccess: async (_) => {
        navigate(`/catalog/${slugForCatalogType(catalogType)}`, {
          replace: true,
        });
      },
      setError: formMethods.setError,
    },
  );

  const updateMutation = useAPIMutation(
    "catalogShowEntry",
    {
      id: initialData?.id || "",
      // The view and edit page both include these query params, so we clear
      // this cache key specifically
      includeReferences: true,
      includeDerivedAttributes: true,
    },
    async (apiClient, data) => {
      await apiClient.catalogUpdateEntry({
        id: initialData?.id || "",
        updateEntryRequestBody: marshallEntryPayload(data),
      });
      // We also need to refresh the SWR cache for the entry list with the updated data
      await refetchEntries();
    },
    {
      onSuccess: async (resp) => {
        onClose(resp.catalog_entry.id);
      },
      setError: formMethods.setError,
    },
  );

  const { genericError, trigger, isMutating } =
    mode === Mode.Edit ? updateMutation : createMutation;
  const engineResources: Resource[] = resources.map((res) => res.config);

  return (
    <>
      <DrawerBody className="overflow-y-auto">
        <Form.Root
          genericError={genericError}
          onSubmit={trigger}
          formMethods={formMethods}
          saving={isMutating}
          id="catalog-entry-create-edit"
        >
          <EntryEditForm
            resources={resources}
            engineResources={engineResources}
            formMethods={formMethods}
            catalogType={catalogType}
            initialData={initialData}
          />
        </Form.Root>
      </DrawerBody>
      <DrawerFooter className="flex justify-end gap-2">
        <Button
          analyticsTrackingId={null}
          onClick={() => onCloseWithWarn(isDirty)}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        <Button
          type={"submit"}
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          disabled={isMutating}
          loading={isMutating}
          form="catalog-entry-create-edit"
        >
          {mode === Mode.Edit ? "Save" : "Create"}
        </Button>
      </DrawerFooter>
    </>
  );
};

const EntryEditForm = ({
  resources,
  engineResources,
  catalogType,
  initialData,
  formMethods,
}: {
  resources: CatalogResource[];
  engineResources: Resource[];
} & EntryPropsData): React.ReactElement | null => {
  const entryPropsData = {
    formMethods,
    catalogType,
    initialData,
  };

  // Group attributes by their mode
  const attributesByMode = groupBy(
    catalogType.schema.attributes,
    (attr) => attr.mode,
  );

  // We render this slightly differently depending on which mode the catalog type is
  switch (catalogType.mode) {
    case CatalogTypeMode.Manual:
      // Manual types are easy, we just render the entry properties and then the rest
      return (
        <>
          <EntryPropertiesForm {...entryPropsData} />
          <AttributesForm
            resources={resources}
            engineResources={engineResources}
            attributes={catalogType.schema.attributes}
          />
        </>
      );

    case CatalogTypeMode.Internal:
      // For internal types, we show the internal attributes in our 'disabled' box as you can't edit
      // them, and then any manual attributes
      return (
        <>
          <ManagedSomewhereElseAttributes
            resources={resources}
            engineResources={engineResources}
            attributes={attributesByMode[AttributeModeEnum.Internal]}
            editedInText={pluralize(catalogType.name)}
            entryPropertiesForm={<EntryPropertiesForm {...entryPropsData} />}
          />
          <AttributesForm
            resources={resources}
            engineResources={engineResources}
            attributes={attributesByMode[AttributeModeEnum.Manual]}
          />
        </>
      );
    case CatalogTypeMode.External:
      // This is the most complex, we might have (for e.g. PagerDutyUser) external attributes (from
      // PD), internal attributes (from Connected accounts), and manual attributes.
      return (
        <>
          {/* First, the external attributes (including id/external ID etc) */}
          <ManagedSomewhereElseAttributes
            resources={resources}
            engineResources={engineResources}
            attributes={attributesByMode[AttributeModeEnum.External]}
            editedInText={
              catalogType.required_integration
                ? IntegrationConfigFor(
                    catalogType.required_integration as unknown as IntegrationListProvider,
                  ).label
                : "the source system"
            }
            entryPropertiesForm={<EntryPropertiesForm {...entryPropsData} />}
          />
          {/* Second, internal attributes (if there are any) */}
          <ManagedSomewhereElseAttributes
            resources={resources}
            engineResources={engineResources}
            attributes={attributesByMode[AttributeModeEnum.Internal]}
            // Special case: the only place we currently use internal attributes on an external type
            // is for connected accounts. In this case, we want to say "connected account" instead of
            // "Pagerduty Users".
            editedInText={
              catalogType.mode === CatalogTypeMode.External &&
              catalogType.name.includes("User")
                ? "Connected accounts"
                : pluralize(catalogType.name)
            }
          />
          <AttributesForm
            resources={resources}
            engineResources={engineResources}
            attributes={attributesByMode[AttributeModeEnum.Manual]}
          />
        </>
      );
    default:
      assertUnreachable(catalogType.mode);
  }

  return null;
};

const ManagedSomewhereElseAttributes = ({
  resources,
  engineResources,
  attributes = [],
  editedInText,
  entryPropertiesForm,
}: {
  resources: CatalogResource[];
  engineResources: Resource[];
  attributes?: CatalogTypeAttribute[];
  editedInText: string;
  entryPropertiesForm?: React.ReactNode;
}) => {
  if (isEmpty(attributes) && !entryPropertiesForm) {
    return null;
  }

  return (
    <div className="bg-surface-secondary rounded-3 p-4 flex flex-col gap-4">
      <div className="flex items-center gap-1">
        The following attributes can be edited in{" "}
        <div className="font-medium">{editedInText}</div>
      </div>
      {entryPropertiesForm}
      <AttributesForm
        resources={resources}
        engineResources={engineResources}
        attributes={attributes}
      />
    </div>
  );
};

const AttributesForm = ({
  resources,
  engineResources,
  attributes = [],
}: {
  resources: CatalogResource[];
  engineResources: Resource[];
  attributes: CatalogTypeAttribute[];
}) => {
  return (
    <>
      {attributes.map((attr) => {
        const resource = resources.find((res) => res.type === attr.type);
        if (!resource) {
          return null; // this would be very strange
        }

        return (
          <EngineFormElement
            mode="plain_input"
            key={attr.id}
            name={`attribute_values.${attr.id}`}
            label={attr.name}
            resources={engineResources}
            required={false}
            array={attr.array}
            resourceType={resource.config.type}
            className="w-full"
            showPlaceholder
            disabled={attr.mode !== AttributeModeEnum.Manual}
          />
        );
      })}
    </>
  );
};

type EntryPropsData = {
  formMethods: UseFormReturn<FormType>;
  catalogType: CatalogType;
  initialData?: CatalogEntry;
};

const EntryPropertiesForm = ({
  formMethods,
  catalogType,
  initialData,
}: EntryPropsData) => {
  const [aliases] = formMethods.watch(["aliases"]);

  return (
    <>
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        placeholder="Enter a name for your entry"
        disabled={!!catalogType.registry_type}
        autoFocus={true}
        onValueChange={(value) => {
          if (initialData?.external_id) {
            return;
          }
          formMethods.setValue("external_id", slugify(value));
        }}
      />
      <div className="pl-4 border-l-[3px] border-stroke flex flex-col grow gap-4">
        <div className="text-content-secondary text-sm-med">
          How do your systems refer to this catalog entry?
        </div>
        <InputV2
          formMethods={formMethods}
          name="external_id"
          disabledTooltipContent="External ID can't be modified after it's been set"
          // Only mark it as explicitly 'optional' when it's not already set.
          required={!!initialData?.external_id}
          label={
            <div className="flex gap-1">
              External ID
              <Tooltip content="This is how your systems refer to this entry. It cannot be modified after it's been set" />
            </div>
          }
          disabled={initialData?.external_id != null}
        />
        {aliases.length > 0 ? (
          <div>
            <MultiTextInput
              canReorderItems={false}
              name={"aliases"}
              disabled={catalogType.mode !== "manual"}
              label={
                <div className="flex gap-1 mb-2">
                  Aliases
                  <Tooltip content={ALIASES_HELP_TEXT} />
                </div>
              }
              placeholder="e.g. previous-name"
            />
          </div>
        ) : (
          <Tooltip content="An alias is another way that your systems might refer to this entry. This is useful if something has a legacy name in some systems.">
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              icon={IconEnum.Add}
              className="w-fit"
              onClick={() =>
                formMethods.setValue("aliases", [{ key: uuidv4(), value: "" }])
              }
            >
              Add an alias
            </Button>
          </Tooltip>
        )}
      </div>
      {catalogType.ranked ? (
        <InputV2
          type={InputType.Number}
          formMethods={formMethods}
          name="rank_as_str"
          label="Rank"
          placeholder="Enter a rank for your entry"
        />
      ) : null}
    </>
  );
};
