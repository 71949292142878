import { useEffect, useState } from "react";
import {
  Notification,
  useClient,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useAPIInfinite } from "src/utils/swr";

type UseNotificationsReturn = {
  notifications: Notification[];
  unreadCount: number;
  loadingInitialNotifications: boolean;
  loadingMoreNotifications: boolean;
  hasReturnedAllNotifications: boolean;
  refetchNotifications: () => Promise<void>;
  fetchMoreNotifications: () => Promise<void>;
  markNotificationsAsRead: () => Promise<void>;
  muteIncident: (incidentId: string) => Promise<void>;
};

export const useNotifications = (): UseNotificationsReturn => {
  const apiClient = useClient();
  const isAuthenticated = useIsAuthenticated();

  const {
    responses,
    isLoading: loadingMoreNotifications,
    refetch,
    loadMore: fetchMoreNotifications,
    isFullyLoaded: hasReturnedAllNotifications,
  } = useAPIInfinite(
    isAuthenticated ? "notificationsList" : null,
    {},
    // Reload every 30s
    { refreshInterval: 30000 },
  );

  const notifications = responses.flatMap(({ notifications }) => notifications);

  const loadingInitialNotifications = responses.length === 0;

  const [markingNotificationsAsRead, setMarkingNotificationsAsRead] =
    useState(false);
  const [unreadCount, setUnreadCount] = useState(
    countUnreadNotifications(notifications),
  );
  useEffect(() => {
    setUnreadCount(countUnreadNotifications(notifications));
  }, [notifications]);

  const markNotificationsAsRead = async () => {
    // Mark notifications as read when we close the bar. Doing this here means we'll
    // correctly mark any new notifications that came in whilst the bar was open.
    if (markingNotificationsAsRead || !notifications) {
      return;
    }

    if (unreadCount > 0) {
      // Clear the count immediately
      setUnreadCount(0);

      setMarkingNotificationsAsRead(true);

      await apiClient.notificationsMarkAsRead({
        markAsReadRequestBody: {
          latest_read_notification_id: notifications[0].id,
        },
      });
      setMarkingNotificationsAsRead(false);
    }

    await refetch();
  };

  const muteIncident = async (incidentId: string) => {
    await apiClient.notificationsMuteIncident({
      muteIncidentRequestBody: { incident_id: incidentId },
    });
    await refetch();
  };

  return {
    notifications,
    unreadCount,
    loadingInitialNotifications,
    loadingMoreNotifications,
    hasReturnedAllNotifications,
    refetchNotifications: async () => {
      await refetch();
    },
    fetchMoreNotifications: async () => {
      await fetchMoreNotifications();
    },
    markNotificationsAsRead,
    muteIncident,
  };
};

const countUnreadNotifications = (notifications: Notification[]) => {
  if (!notifications) {
    return 0;
  }
  return notifications.filter((n) => !n.is_read).length;
};
