/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineScope,
    EngineScopeFromJSON,
    EngineScopeFromJSONTyped,
    EngineScopeToJSON,
} from './EngineScope';

/**
 * 
 * @export
 * @interface PoliciesBuildScopeResponseBody
 */
export interface PoliciesBuildScopeResponseBody {
    /**
     * 
     * @type {EngineScope}
     * @memberof PoliciesBuildScopeResponseBody
     */
    incident_scope: EngineScope;
    /**
     * For each policy type, the scope that is offered
     * @type {{ [key: string]: EngineScope; }}
     * @memberof PoliciesBuildScopeResponseBody
     */
    policy_scopes: { [key: string]: EngineScope; };
}

export function PoliciesBuildScopeResponseBodyFromJSON(json: any): PoliciesBuildScopeResponseBody {
    return PoliciesBuildScopeResponseBodyFromJSONTyped(json, false);
}

export function PoliciesBuildScopeResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesBuildScopeResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_scope': EngineScopeFromJSON(json['incident_scope']),
        'policy_scopes': (mapValues(json['policy_scopes'], EngineScopeFromJSON)),
    };
}

export function PoliciesBuildScopeResponseBodyToJSON(value?: PoliciesBuildScopeResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_scope': EngineScopeToJSON(value.incident_scope),
        'policy_scopes': (mapValues(value.policy_scopes, EngineScopeToJSON)),
    };
}

