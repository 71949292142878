/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulesCreateReplicaRequestBody
 */
export interface SchedulesCreateReplicaRequestBody {
    /**
     * Source schedule rotation layer
     * @type {string}
     * @memberof SchedulesCreateReplicaRequestBody
     */
    layer_id: string;
    /**
     * The user that will be used in the replica system whenever NOBODY is scheduled
     * @type {string}
     * @memberof SchedulesCreateReplicaRequestBody
     */
    replica_fallback_user_id: string;
    /**
     * Name of replica external provider, e.g. pagerduty
     * @type {string}
     * @memberof SchedulesCreateReplicaRequestBody
     */
    replica_provider: SchedulesCreateReplicaRequestBodyReplicaProviderEnum;
    /**
     * ID of replica schedule in external system
     * @type {string}
     * @memberof SchedulesCreateReplicaRequestBody
     */
    replica_provider_id: string;
    /**
     * Source schedule rotation
     * @type {string}
     * @memberof SchedulesCreateReplicaRequestBody
     */
    rotation_id: string;
    /**
     * Source schedule
     * @type {string}
     * @memberof SchedulesCreateReplicaRequestBody
     */
    schedule_id: string;
}

/**
* @export
* @enum {string}
*/
export enum SchedulesCreateReplicaRequestBodyReplicaProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}

export function SchedulesCreateReplicaRequestBodyFromJSON(json: any): SchedulesCreateReplicaRequestBody {
    return SchedulesCreateReplicaRequestBodyFromJSONTyped(json, false);
}

export function SchedulesCreateReplicaRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateReplicaRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'layer_id': json['layer_id'],
        'replica_fallback_user_id': json['replica_fallback_user_id'],
        'replica_provider': json['replica_provider'],
        'replica_provider_id': json['replica_provider_id'],
        'rotation_id': json['rotation_id'],
        'schedule_id': json['schedule_id'],
    };
}

export function SchedulesCreateReplicaRequestBodyToJSON(value?: SchedulesCreateReplicaRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'layer_id': value.layer_id,
        'replica_fallback_user_id': value.replica_fallback_user_id,
        'replica_provider': value.replica_provider,
        'replica_provider_id': value.replica_provider_id,
        'rotation_id': value.rotation_id,
        'schedule_id': value.schedule_id,
    };
}

