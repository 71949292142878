import type { SVGProps } from "react";
import * as React from "react";
const SvgShare = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.966 9.658a.75.75 0 0 1-.364-1.406l5.07-2.816a.75.75 0 0 1 .727 1.312L7.33 9.564a.746.746 0 0 1-.364.094ZM12.034 14.658a.746.746 0 0 1-.364-.094l-5.069-2.816a.75.75 0 0 1 .728-1.312l5.07 2.816a.75.75 0 0 1-.365 1.406Z"
    />
    <path
      fill="currentColor"
      d="M5 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14 18a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
  </svg>
);
export default SvgShare;
