/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageParentPageOptions,
    StatusPageParentPageOptionsFromJSON,
    StatusPageParentPageOptionsFromJSONTyped,
    StatusPageParentPageOptionsToJSON,
} from './StatusPageParentPageOptions';

/**
 * 
 * @export
 * @interface StatusPageCreateRequestBody
 */
export interface StatusPageCreateRequestBody {
    /**
     * The GCS key where the logo for this status page is stored
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    darkmode_logo_key?: string;
    /**
     * Whether to use Calendar or List view
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    date_view?: StatusPageCreateRequestBodyDateViewEnum;
    /**
     * The GCS key where the email logo for this status page is stored
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    email_logo_key?: string;
    /**
     * The GCS key where the favicon for this status page is stored
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    favicon_key?: string;
    /**
     * The GCS key where the lightmode logo for this status page is stored
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    lightmode_logo_key?: string;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    name: string;
    /**
     * 
     * @type {StatusPageParentPageOptions}
     * @memberof StatusPageCreateRequestBody
     */
    parent_page_options?: StatusPageParentPageOptions;
    /**
     * The publicly accessible URL of the privacy policy for this status page
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    privacy_policy_url?: string;
    /**
     * The subpath of the status page for our standard domain
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    subpath: string;
    /**
     * The publicly accessible URL of the terms of service for this status page
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    terms_of_service_url?: string;
    /**
     * Whether to use Light mode or Dark mode
     * @type {string}
     * @memberof StatusPageCreateRequestBody
     */
    theme: StatusPageCreateRequestBodyThemeEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageCreateRequestBodyDateViewEnum {
    Calendar = 'calendar',
    List = 'list'
}/**
* @export
* @enum {string}
*/
export enum StatusPageCreateRequestBodyThemeEnum {
    Light = 'light',
    Dark = 'dark'
}

export function StatusPageCreateRequestBodyFromJSON(json: any): StatusPageCreateRequestBody {
    return StatusPageCreateRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'darkmode_logo_key': !exists(json, 'darkmode_logo_key') ? undefined : json['darkmode_logo_key'],
        'date_view': !exists(json, 'date_view') ? undefined : json['date_view'],
        'email_logo_key': !exists(json, 'email_logo_key') ? undefined : json['email_logo_key'],
        'favicon_key': !exists(json, 'favicon_key') ? undefined : json['favicon_key'],
        'lightmode_logo_key': !exists(json, 'lightmode_logo_key') ? undefined : json['lightmode_logo_key'],
        'name': json['name'],
        'parent_page_options': !exists(json, 'parent_page_options') ? undefined : StatusPageParentPageOptionsFromJSON(json['parent_page_options']),
        'privacy_policy_url': !exists(json, 'privacy_policy_url') ? undefined : json['privacy_policy_url'],
        'subpath': json['subpath'],
        'terms_of_service_url': !exists(json, 'terms_of_service_url') ? undefined : json['terms_of_service_url'],
        'theme': json['theme'],
    };
}

export function StatusPageCreateRequestBodyToJSON(value?: StatusPageCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'darkmode_logo_key': value.darkmode_logo_key,
        'date_view': value.date_view,
        'email_logo_key': value.email_logo_key,
        'favicon_key': value.favicon_key,
        'lightmode_logo_key': value.lightmode_logo_key,
        'name': value.name,
        'parent_page_options': StatusPageParentPageOptionsToJSON(value.parent_page_options),
        'privacy_policy_url': value.privacy_policy_url,
        'subpath': value.subpath,
        'terms_of_service_url': value.terms_of_service_url,
        'theme': value.theme,
    };
}

