import { Button, IconEnum } from "@incident-ui";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import React from "react";
import { useFormContext } from "react-hook-form";

import { WorkflowFormData } from "../../common/types";
import { WorkflowDelaySection } from "../../delay/WorkflowDelaySection";
import { WorkflowStepsSection } from "../../steps/WorkflowStepsSection";
import { WorkflowsTriggerSelector } from "../../triggers/WorkflowsTriggerSelector";
import { useWorkflowsSteps } from "../hooks/useStepsController";
import { useWorkflowsTrigger } from "../hooks/useTriggerController";
import {
  useWorkflowsViewState,
  WorkflowDrawerState,
  WorkflowModalState,
} from "../hooks/useViewController";
import { useWorkflowsDeps } from "../WorkflowsFormContext";
import { WorkflowsCreateEditBanners } from "./WorkflowsCreateEditBanners";
import { WorkflowsCreateEditConditions } from "./WorkflowsCreateEditConditions";

export const WorkflowsCreateEditBody = (): React.ReactElement => {
  const { trigger, triggerLoading, onEditTrigger, isManualTrigger } =
    useWorkflowsTrigger();
  const { scope, resources, isDraft } = useWorkflowsDeps();
  const formMethods = useFormContext<WorkflowFormData>();
  const { watch } = formMethods;

  const { onOpenDrawer: onOpenDrawer, onOpenModal } = useWorkflowsViewState();

  const {
    showEditStepForm,
    clearStepFormState,
    onClickAddStep,
    onDeleteStepGroup,
    onDeleteStep,
    onSetForEach,
    onReorderStepsWithinGroup,
    onReorderGroupSteps,
    hasSteps,
    hasStepGroups,
  } = useWorkflowsSteps();

  const [conditionGroups, onceFor] = watch(["condition_groups", "once_for"]);

  const onClickEditTrigger = () => {
    // If the user has set steps, added conditionGroups, or edited once for,
    // we should warn them they'll lose those changes if they change the trigger
    if (hasSteps || conditionGroups.length > 0 || onceFor.length > 0) {
      onOpenModal(WorkflowModalState.ChangeTriggerWarning);
      return;
    }

    // Otherwise it's safe to let them choose a new trigger
    onEditTrigger();
  };

  return (
    <div className="w-full flex justify-center">
      <div className="flex-1 flex flex-col items-center max-w-[560px]">
        {/* Banners if it's read-only and/or disabled */}
        <WorkflowsCreateEditBanners />

        <FlowCards.List compact>
          <WorkflowsTriggerSelector
            key="trigger"
            triggerLoading={triggerLoading}
            trigger={trigger}
            isDraft={isDraft}
            onClickEditTrigger={onClickEditTrigger}
            onClickChooseTrigger={() => {
              onOpenDrawer(WorkflowDrawerState.ChooseTrigger);
            }}
          />

          {!isManualTrigger && (
            <WorkflowsCreateEditConditions
              trigger={trigger}
              loading={triggerLoading}
              scopeWithExpressions={scope}
            />
          )}

          {!isManualTrigger && (
            <WorkflowDelaySection formMethods={formMethods} />
          )}

          {/* Steps */}
          {hasStepGroups && (
            <WorkflowStepsSection
              resources={resources}
              scope={scope}
              onSetForEach={onSetForEach}
              onReorderGroupSteps={onReorderGroupSteps}
              onReorderStepsWithinGroup={onReorderStepsWithinGroup}
              onEditStep={showEditStepForm}
              onRemoveStepGroup={onDeleteStepGroup}
              onRemoveStep={onDeleteStep}
              onClickAddStep={onClickAddStep}
            />
          )}
          {/* Add step */}
          <div className="flex flex-col items-center">
            <Button
              onClick={() => {
                clearStepFormState();
                onClickAddStep(null, null);
              }}
              disabled={!trigger}
              analyticsTrackingId={"workflows-v2-add-step-button"}
              icon={IconEnum.Add}
            >
              Add step
            </Button>
          </div>
        </FlowCards.List>
      </div>
    </div>
  );
};

export const LineDivider = () => (
  <div className="h-[16px]  bg-slate-300 w-[1px]"></div>
);
