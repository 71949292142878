import type { SVGProps } from "react";
import * as React from "react";
const SvgLinear = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#5E6AD2"
      d="m2.1 11.35 6.55 6.55a8.013 8.013 0 0 1-6.55-6.55ZM2 9.572 10.428 18a8 8 0 0 0 1.463-.215L2.214 8.11A8.004 8.004 0 0 0 2 9.572Zm.634-2.721 10.515 10.515a7.978 7.978 0 0 0 1.1-.578L3.211 5.751c-.22.351-.413.719-.577 1.1Zm1.29-2.065a8.006 8.006 0 1 1 11.29 11.29L3.924 4.786Z"
    />
  </svg>
);
export default SvgLinear;
