import { tcx } from "src/utils/tailwind-classes";

export type HeadingProps = {
  level: HeadingLevel;
  // These are the blessed sizes from design. If anyone asks you for a different size,
  // check with Design first that we want to introduce it.
  size?: "small" | "medium" | "2xl" | "5xl";
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLHeadingElement>;

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

/**
 * A Heading is used to introduce a page or section. It takes a `level` prop which corresponds to HTML heading levels.
 *
 * Guidelines:
 *
 * - there should only ever be _one level-1 heading_ on a page, and it should describe what the page is for e.g. "Workflows".
 * - levels should nest sequentially, so a h3 should be a subheading of a section with an h2, etc.
 * - use the `size` prop, not levels, to control heading size.
 * - if you're unsure what level to use, see what other headings are on the page.
 */
export function Heading({
  level = 3,
  size = "medium",
  children,
  className,
  ...restProps
}: HeadingProps): React.ReactElement {
  const Tag = `h${level}` as HeadingTag;
  return (
    <Tag
      className={tcx(
        "font-semibold",
        "text-content-primary",
        {
          "text-sm": size === "small",
          "text-base": size === "medium",
          "text-2xl": size === "2xl",
          "text-5xl": size === "5xl",
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </Tag>
  );
}
