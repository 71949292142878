import { useOrgAwareNavigate } from "@incident-shared/org-aware/OrgAware";
import React from "react";
import { useLocation } from "react-router-dom";

export const useSafeUpdateQueryString = (): ((
  queryString: string,
  replace?: boolean,
) => void) => {
  const navigate = useOrgAwareNavigate();
  const location = useLocation();

  const updateHistory = (queryString: string, replace?: boolean) => {
    const currentQueryString = location.search.slice(1);
    // if we don't do this check, we end up infinite looping because
    // react-router forces a re-render every time we update history.
    if (currentQueryString !== queryString) {
      navigate(
        {
          pathname: location.pathname,
          search: queryString,
        },
        {
          replace,
        },
      );
    }
  };

  return updateHistory;
};

export function useQueryParams(): URLSearchParams {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function paramsAsObj(params: URLSearchParams): Record<string, string> {
  const paramsObj = {};

  for (const [key, value] of params.entries()) {
    paramsObj[key] = value;
  }
  return paramsObj;
}

export function useNavigateToModal() {
  const location = useLocation();
  const navigate = useOrgAwareNavigate();

  return (newURL: string): void => {
    navigate(
      {
        pathname: `${
          location.pathname.endsWith("/")
            ? location.pathname
            : location.pathname + "/"
        }${newURL}`,
        search: location.search,
      },
      { replace: true },
    );
  };
}
