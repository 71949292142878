/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpStatisticsRow
 */
export interface FollowUpStatisticsRow {
    /**
     * The number of follow-ups in this split that have been completed
     * @type {number}
     * @memberof FollowUpStatisticsRow
     */
    completed_follow_ups: number;
    /**
     * The number of follow-ups in this split that have been exported
     * @type {number}
     * @memberof FollowUpStatisticsRow
     */
    exported_follow_ups: number;
    /**
     * The number of follow-ups in this split that violate at least one policy
     * @type {number}
     * @memberof FollowUpStatisticsRow
     */
    follow_ups_with_policy_violations: number;
    /**
     * The number of incidents that have follow-ups which violate at least one policy
     * @type {number}
     * @memberof FollowUpStatisticsRow
     */
    incidents_with_follow_ups_with_policy_violations: number;
    /**
     * Number of incidents that have open follow-ups
     * @type {number}
     * @memberof FollowUpStatisticsRow
     */
    incidents_with_open_follow_ups: number;
    /**
     * Number of open follow-ups
     * @type {number}
     * @memberof FollowUpStatisticsRow
     */
    open_follow_ups: number;
    /**
     * Name of the value that this split relates to
     * @type {string}
     * @memberof FollowUpStatisticsRow
     */
    split_label: string;
    /**
     * The total number of open or completed follow-ups in this split
     * @type {number}
     * @memberof FollowUpStatisticsRow
     */
    total_follow_ups: number;
}

export function FollowUpStatisticsRowFromJSON(json: any): FollowUpStatisticsRow {
    return FollowUpStatisticsRowFromJSONTyped(json, false);
}

export function FollowUpStatisticsRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpStatisticsRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed_follow_ups': json['completed_follow_ups'],
        'exported_follow_ups': json['exported_follow_ups'],
        'follow_ups_with_policy_violations': json['follow_ups_with_policy_violations'],
        'incidents_with_follow_ups_with_policy_violations': json['incidents_with_follow_ups_with_policy_violations'],
        'incidents_with_open_follow_ups': json['incidents_with_open_follow_ups'],
        'open_follow_ups': json['open_follow_ups'],
        'split_label': json['split_label'],
        'total_follow_ups': json['total_follow_ups'],
    };
}

export function FollowUpStatisticsRowToJSON(value?: FollowUpStatisticsRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_follow_ups': value.completed_follow_ups,
        'exported_follow_ups': value.exported_follow_ups,
        'follow_ups_with_policy_violations': value.follow_ups_with_policy_violations,
        'incidents_with_follow_ups_with_policy_violations': value.incidents_with_follow_ups_with_policy_violations,
        'incidents_with_open_follow_ups': value.incidents_with_open_follow_ups,
        'open_follow_ups': value.open_follow_ups,
        'split_label': value.split_label,
        'total_follow_ups': value.total_follow_ups,
    };
}

