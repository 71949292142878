/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkflowSlim,
    WorkflowSlimFromJSON,
    WorkflowSlimFromJSONTyped,
    WorkflowSlimToJSON,
} from './WorkflowSlim';

/**
 * 
 * @export
 * @interface WorkflowsListWorkflowsResponseBody
 */
export interface WorkflowsListWorkflowsResponseBody {
    /**
     * 
     * @type {Array<WorkflowSlim>}
     * @memberof WorkflowsListWorkflowsResponseBody
     */
    workflows: Array<WorkflowSlim>;
}

export function WorkflowsListWorkflowsResponseBodyFromJSON(json: any): WorkflowsListWorkflowsResponseBody {
    return WorkflowsListWorkflowsResponseBodyFromJSONTyped(json, false);
}

export function WorkflowsListWorkflowsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsListWorkflowsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflows': ((json['workflows'] as Array<any>).map(WorkflowSlimFromJSON)),
    };
}

export function WorkflowsListWorkflowsResponseBodyToJSON(value?: WorkflowsListWorkflowsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflows': ((value.workflows as Array<any>).map(WorkflowSlimToJSON)),
    };
}

