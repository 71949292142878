import "./index.scss";
import "what-input";

import * as Sentry from "@sentry/react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { App } from "src/App";
import reportWebVitals from "src/reportWebVitals";
import { Environment, getEnvironment } from "src/utils/environment";

if (!process.env.REACT_APP_STAGE) {
  // We use the same images in staging as we do in production, so we have to
  // resolve our Sentry environment by inspecting our origin. Default to
  // production unless we're sure we're in staging (ie, we can see we're running
  // on app.staging.incident.io).
  const environment = getEnvironment();

  console.debug("Initialising Sentry", {
    environment,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });

  Sentry.init({
    dsn: "https://c051daad93994d9fa0edc5c476c9d653@o494704.ingest.sentry.io/5566274",
    // We do not use tracing in Sentry, so there's no reason to pay for it!
    tracesSampleRate: 0.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: environment,
  });
}

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
  },
]);

// Initialising this asynchronously means we wait until we have feature flags
// before loading the app. This will prevent any "flashes" of stuff not appearing
// until we've loaded in flags.
// ⚠️ It may add max 200ms to loading time
// See https://docs.launchdarkly.com/sdk/client-side/react/react-web#initializing-asyncwithldprovider
(async () => {
  const LaunchDarklyKeys: Record<Environment, string> = {
    [Environment.Development]: "61701e540151b6254f84b6de",
    [Environment.Staging]: "6177c842bc3c320ccaf33cef",
    [Environment.Production]: "61701e540151b6254f84b6df",
  };
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: LaunchDarklyKeys[getEnvironment()],
  });
  ReactDOM.render(
    <React.StrictMode>
      <LaunchDarklyProvider>
        <RouterProvider router={router} />
      </LaunchDarklyProvider>
    </React.StrictMode>,
    document.getElementById("root"),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
