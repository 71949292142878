/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableBacklinkV2,
    AvailableBacklinkV2FromJSON,
    AvailableBacklinkV2FromJSONTyped,
    AvailableBacklinkV2ToJSON,
} from './AvailableBacklinkV2';
import {
    AvailablePathAttribute,
    AvailablePathAttributeFromJSON,
    AvailablePathAttributeFromJSONTyped,
    AvailablePathAttributeToJSON,
} from './AvailablePathAttribute';

/**
 * 
 * @export
 * @interface CatalogListDerivedAttributesResponseBody
 */
export interface CatalogListDerivedAttributesResponseBody {
    /**
     * 
     * @type {Array<AvailableBacklinkV2>}
     * @memberof CatalogListDerivedAttributesResponseBody
     */
    backlinks: Array<AvailableBacklinkV2>;
    /**
     * 
     * @type {Array<AvailablePathAttribute>}
     * @memberof CatalogListDerivedAttributesResponseBody
     */
    paths: Array<AvailablePathAttribute>;
}

export function CatalogListDerivedAttributesResponseBodyFromJSON(json: any): CatalogListDerivedAttributesResponseBody {
    return CatalogListDerivedAttributesResponseBodyFromJSONTyped(json, false);
}

export function CatalogListDerivedAttributesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListDerivedAttributesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backlinks': ((json['backlinks'] as Array<any>).map(AvailableBacklinkV2FromJSON)),
        'paths': ((json['paths'] as Array<any>).map(AvailablePathAttributeFromJSON)),
    };
}

export function CatalogListDerivedAttributesResponseBodyToJSON(value?: CatalogListDerivedAttributesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backlinks': ((value.backlinks as Array<any>).map(AvailableBacklinkV2ToJSON)),
        'paths': ((value.paths as Array<any>).map(AvailablePathAttributeToJSON)),
    };
}

