import React, { ChangeEvent } from "react";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./RadioButton.module.scss";

type Props = {
  id: string;
  label: React.ReactNode;
  className?: string;
  value: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
} & React.HTMLAttributes<HTMLInputElement>;
export const RadioButton = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      id,
      className,
      onChange,
      checked,
      disabled = false,
      children,
      ...restProps
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div>
        <label
          htmlFor={id}
          className={tcx(
            styles.radioButton,
            { "text-content-tertiary": disabled },
            className,
          )}
        >
          <input
            // Inputs get priority over things with tabIndex=0, since inputs are
            // generally more important than other things on screen.
            tabIndex={1}
            {...restProps}
            ref={ref}
            type="radio"
            id={id}
            className={tcx(styles.radioButton, "mr-2")}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
          <span>{label}</span>
          {children}
        </label>
      </div>
    );
  },
);

RadioButton.displayName = "RadioButton";
