import type { SVGProps } from "react";
import * as React from "react";
const SvgServer = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.75 3H4.25A2.252 2.252 0 0 0 2 5.25v2A2.252 2.252 0 0 0 4.25 9.5h11.5A2.252 2.252 0 0 0 18 7.25v-2A2.252 2.252 0 0 0 15.75 3ZM5.25 7a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm2.5 0a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM15.75 10.5H4.25A2.252 2.252 0 0 0 2 12.75v2A2.252 2.252 0 0 0 4.25 17h11.5A2.252 2.252 0 0 0 18 14.75v-2a2.252 2.252 0 0 0-2.25-2.25Zm-10.5 4a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm2.5 0a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgServer;
