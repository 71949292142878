import { ModalFooter } from "@incident-ui";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPIMutation } from "src/utils/swr";

type RemoveMobileAppFormData = {
  mobileKeyID: string;
};

export const RemoveMobileAppModal = ({
  onClose,
  mobileKeyID,
}: {
  onClose: () => void;
  mobileKeyID: string;
}) => {
  const showToast = useToast();

  const formMethods = useForm<RemoveMobileAppFormData>({
    defaultValues: {
      mobileKeyID,
    },
  });

  const { trigger: onSubmit } = useAPIMutation(
    "onCallNotificationsListMethodsV2",
    undefined,
    async (apiClient) => {
      await apiClient.mobileDestroyMobileAppInstance({
        id: mobileKeyID,
      });
    },
    {
      onSuccess: () => {
        showToast({
          title: "Removed mobile device!",
          toastSide: ToastSideEnum.Bottom,
          theme: ToastTheme.Success,
        });

        onClose();
      },
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal
      title="Remove your mobile device?"
      analyticsTrackingId="remove-mobile-app"
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Remove"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <div className="flex flex-col gap-4">
        We&apos;ll sign you out and remove your mobile device from the list of
        active devices.
      </div>
    </Form.Modal>
  );
};
