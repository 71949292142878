import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItemButtonsProps } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import {
  BadgeTheme,
  ButtonTheme,
  IconEnum,
  StackedList,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import React from "react";
import {
  LegacyPostmortemTemplate,
  PostmortemsDestroyLegacyTemplateRequest,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../SettingsSubHeading";

export const LegacyPostmortemTemplateList = ({
  postMortemTemplates,
}: {
  postMortemTemplates: LegacyPostmortemTemplate[];
}): React.ReactElement => {
  const { identity } = useIdentity();

  const maxPostmortemTemplatesAvailable =
    identity?.feature_gates.postmortem_templates_count ??
    Number.MAX_SAFE_INTEGER;
  const orgCanCreateTemplates =
    postMortemTemplates.length < maxPostmortemTemplatesAvailable;
  return (
    <div>
      <SettingsSubHeading
        title="Templates"
        titleHeadingLevel={2}
        explanation={
          <>
            Define the structure and content of your post-mortem document.
            You&apos;ll be able to choose which template to use when generating
            a post-mortem.
          </>
        }
        className="!mb-4"
        accessory={
          <GatedButton
            href="/settings/post-mortem/templates/create"
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            analyticsTrackingId="create-postmortem-template"
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
            upgradeRequired={!orgCanCreateTemplates}
            upgradeRequiredProps={{
              gate: {
                type: "numeric",
                value: identity?.feature_gates.postmortem_templates_count,
                featureNameSingular: `post-mortem template`,
              },
              featureName: `post-mortem templates`,
            }}
          >
            Add template
          </GatedButton>
        }
      />
      <StackedList>
        {_.orderBy(
          postMortemTemplates,
          ["is_default", "name"],
          ["desc", "asc"],
        ).map((template, index) => (
          <PostmortemTemplateRow key={index} postMortemTemplate={template} />
        ))}
      </StackedList>
    </div>
  );
};

const PostmortemTemplateRow = ({
  postMortemTemplate,
}: {
  postMortemTemplate: LegacyPostmortemTemplate;
}): React.ReactElement => {
  const { trigger: destroyTemplate } = useAPIMutation(
    "postmortemsListLegacyTemplates",
    {},
    async (apiClient, data: PostmortemsDestroyLegacyTemplateRequest) => {
      await apiClient.postmortemsDestroyLegacyTemplate(data);
    },
  );
  const { hasScope } = useIdentity();
  const { postmortemNameFormatted } = usePostmortemName(null);

  const buttons: SettingsListItemButtonsProps = {
    requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
    delete: {
      resourceTitle: postMortemTemplate.name,
      deleteConfirmationTitle: `Delete ${postmortemNameFormatted} template`,
      deleteConfirmationContent: (
        <Txt>
          Are you sure you want to delete the{" "}
          <Txt inline bold>
            {postMortemTemplate.name}
          </Txt>{" "}
          template?
        </Txt>
      ),
      onDelete: () => destroyTemplate({ id: postMortemTemplate.id }),
      isGatedText: postMortemTemplate.is_default
        ? "You can't delete the default template."
        : undefined,
    },
    edit: {
      editHref: `/settings/post-mortem/templates/${postMortemTemplate.id}/edit`,
    },
    view: {
      viewHref: `/settings/post-mortem/templates/${postMortemTemplate.id}/edit`,
    },
  };

  if (hasScope(ScopeNameEnum.OrganisationSettingsUpdate)) {
    delete buttons.view;
  } else {
    delete buttons.edit;
  }

  return (
    <SettingsListItem
      title={postMortemTemplate.name}
      badgeProps={
        postMortemTemplate.is_default
          ? { theme: BadgeTheme.Tertiary, label: "Default" }
          : undefined
      }
      buttons={buttons}
    />
  );
};
