/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';

/**
 * 
 * @export
 * @interface BulkIncidentUpdatePayload
 */
export interface BulkIncidentUpdatePayload {
    /**
     * Assign incident roles to these people
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof BulkIncidentUpdatePayload
     */
    incident_role_assignments?: Array<IncidentRoleAssignmentPayload>;
    /**
     * Update moved the incident to this incident status ID
     * @type {string}
     * @memberof BulkIncidentUpdatePayload
     */
    to_incident_status_id?: string;
    /**
     * Update moved the incident to this severity
     * @type {string}
     * @memberof BulkIncidentUpdatePayload
     */
    to_severity_id?: string;
}

export function BulkIncidentUpdatePayloadFromJSON(json: any): BulkIncidentUpdatePayload {
    return BulkIncidentUpdatePayloadFromJSONTyped(json, false);
}

export function BulkIncidentUpdatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkIncidentUpdatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_role_assignments': !exists(json, 'incident_role_assignments') ? undefined : ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
        'to_incident_status_id': !exists(json, 'to_incident_status_id') ? undefined : json['to_incident_status_id'],
        'to_severity_id': !exists(json, 'to_severity_id') ? undefined : json['to_severity_id'],
    };
}

export function BulkIncidentUpdatePayloadToJSON(value?: BulkIncidentUpdatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_role_assignments': value.incident_role_assignments === undefined ? undefined : ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
        'to_incident_status_id': value.to_incident_status_id,
        'to_severity_id': value.to_severity_id,
    };
}

