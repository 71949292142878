import {
  AnnouncementPostsUpdateActionRequestBody,
  AnnouncementPostsUpdateFieldRequestBody,
} from "@incident-io/api";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { IconEnum, ModalFooter, ToastTheme } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPIMutation } from "src/utils/swr";

import { SLACK_EMOJI_TO_UNICODE } from "../../../../../utils/slack";
import { SortableItem } from "./AnnouncementPostEditPage";

export function AnnouncementItemEditModal<T extends SortableItem>({
  onClose,
  item,
  refetchPreview,
}: {
  onClose: () => void;
  item: T;
  refetchPreview: () => void;
}): React.ReactElement {
  const showToast = useToast();

  const { trigger: onEditField, isMutating: isFieldMutating } = useAPIMutation(
    "announcementPostsListFields",
    undefined,
    async (apiClient, data: AnnouncementPostsUpdateFieldRequestBody) => {
      await apiClient.announcementPostsUpdateField({
        updateFieldRequestBody: data,
        id: item.id,
      });
    },
    {
      onSuccess: () => {
        refetchPreview();
        onClose();
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error updating announcement post configuration",
        });
      },
    },
  );

  const { trigger: onEditAction, isMutating: isActionMutating } =
    useAPIMutation(
      "announcementPostsListActions",
      undefined,
      async (apiClient, data: AnnouncementPostsUpdateActionRequestBody) => {
        await apiClient.announcementPostsUpdateAction({
          updateActionRequestBody: data,
          id: item.id,
        });
      },
      {
        onSuccess: () => {
          refetchPreview();
          onClose();
        },
        onError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: "Error updating announcement post configuration",
          });
        },
      },
    );

  const formMethods = useForm<SortableItem>({
    defaultValues: { emoji: item.emoji },
  });

  const emojiOptions: SelectOption[] = [];
  Object.entries(SLACK_EMOJI_TO_UNICODE).forEach(([key, value]) => {
    emojiOptions.push({
      label: value + "  " + key,
      sort_key: key,
      value: key.slice(1, -1),
    });
  });
  // Fake slack as an emoji
  emojiOptions.push({
    label: ":slack:",
    sort_key: ":slack:",
    value: "slack",
    icon: IconEnum.Slack,
  });

  const onConfirm = (data) => {
    if (item.field_type) {
      return onEditField(data as AnnouncementPostsUpdateFieldRequestBody);
    } else if (item.action_type) {
      return onEditAction(data as AnnouncementPostsUpdateActionRequestBody);
    }
    throw new Error("unreachable: unexpected item type");
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onConfirm}
      title={"Edit emoji"}
      analyticsTrackingId={"edit-announcement-post-item"}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Save"
          confirmButtonType="submit"
          saving={isFieldMutating || isActionMutating}
        />
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Emoji"
        required={false}
        name="emoji"
        options={emojiOptions}
        isClearable={true}
        placeholder="Select emoji..."
      />
    </Form.Modal>
  );
}
