import type { SVGProps } from "react";
import * as React from "react";
const SvgBook = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="m8.297 4.096-4.72-1.302a1.625 1.625 0 0 0-1.43.276 1.63 1.63 0 0 0-.646 1.305v7.9c0 .735.495 1.386 1.204 1.582L8.298 15.4V4.096h-.001ZM15.854 3.07a1.63 1.63 0 0 0-1.431-.276l-4.72 1.302V15.4l5.594-1.543a1.646 1.646 0 0 0 1.203-1.583V4.375c0-.516-.235-.991-.646-1.305Z"
    />
  </svg>
);
export default SvgBook;
