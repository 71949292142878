/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogType,
    CatalogTypeFromJSON,
    CatalogTypeFromJSONTyped,
    CatalogTypeToJSON,
} from './CatalogType';

/**
 * 
 * @export
 * @interface CatalogTypeSuggestion
 */
export interface CatalogTypeSuggestion {
    /**
     * 
     * @type {CatalogType}
     * @memberof CatalogTypeSuggestion
     */
    catalog_type: CatalogType;
    /**
     * How well we can match this catalog type to the custom field options
     * @type {number}
     * @memberof CatalogTypeSuggestion
     */
    score: number;
}

export function CatalogTypeSuggestionFromJSON(json: any): CatalogTypeSuggestion {
    return CatalogTypeSuggestionFromJSONTyped(json, false);
}

export function CatalogTypeSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogTypeSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type': CatalogTypeFromJSON(json['catalog_type']),
        'score': json['score'],
    };
}

export function CatalogTypeSuggestionToJSON(value?: CatalogTypeSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type': CatalogTypeToJSON(value.catalog_type),
        'score': value.score,
    };
}

