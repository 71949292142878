/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIKey,
    APIKeyFromJSON,
    APIKeyFromJSONTyped,
    APIKeyToJSON,
} from './APIKey';
import {
    ZendeskConfig,
    ZendeskConfigFromJSON,
    ZendeskConfigFromJSONTyped,
    ZendeskConfigToJSON,
} from './ZendeskConfig';

/**
 * 
 * @export
 * @interface IntegrationsZendeskGenerateAPIKeyResponseBody
 */
export interface IntegrationsZendeskGenerateAPIKeyResponseBody {
    /**
     * 
     * @type {APIKey}
     * @memberof IntegrationsZendeskGenerateAPIKeyResponseBody
     */
    api_key: APIKey;
    /**
     * 
     * @type {string}
     * @memberof IntegrationsZendeskGenerateAPIKeyResponseBody
     */
    token: string;
    /**
     * 
     * @type {ZendeskConfig}
     * @memberof IntegrationsZendeskGenerateAPIKeyResponseBody
     */
    zendesk_config: ZendeskConfig;
}

export function IntegrationsZendeskGenerateAPIKeyResponseBodyFromJSON(json: any): IntegrationsZendeskGenerateAPIKeyResponseBody {
    return IntegrationsZendeskGenerateAPIKeyResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsZendeskGenerateAPIKeyResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsZendeskGenerateAPIKeyResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_key': APIKeyFromJSON(json['api_key']),
        'token': json['token'],
        'zendesk_config': ZendeskConfigFromJSON(json['zendesk_config']),
    };
}

export function IntegrationsZendeskGenerateAPIKeyResponseBodyToJSON(value?: IntegrationsZendeskGenerateAPIKeyResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_key': APIKeyToJSON(value.api_key),
        'token': value.token,
        'zendesk_config': ZendeskConfigToJSON(value.zendesk_config),
    };
}

