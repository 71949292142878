import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, GenericErrorMessage, IconEnum } from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  IncidentDurationMetric,
  IncidentTimestamp,
  IncidentTimestampTimestampTypeEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";

import { SettingsSubHeading } from "../../SettingsSubHeading";
import { CustomTimestampsList } from "./CustomTimestampsList";
import { DefaultTimestampsList } from "./DefaultTimestampsList";
import { IncidentTimestampCreateModal } from "./timestamp-create/IncidentTimestampCreateModal";
import { IncidentTimestampEditModal } from "./timestamp-edit/IncidentTimestampEditModal";

export const TimestampType = IncidentTimestampTimestampTypeEnum;
export const uneditableTimestampTypes = [
  TimestampType.ReportedAt,
  TimestampType.AcceptedAt,
  TimestampType.DeclinedAt,
  TimestampType.MergedAt,
  TimestampType.CanceledAt,
  TimestampType.ResolvedAt,
  TimestampType.ClosedAt,
];
export const reservedTimestampRanks = uneditableTimestampTypes.length - 1; // ClosedAt always has the largest rank

export const IncidentTimestampsSection = ({
  metrics,
  showAddTimestampModal,
  setShowAddTimestampModal,
}: {
  metrics: IncidentDurationMetric[];
  showAddTimestampModal: string | boolean;
  setShowAddTimestampModal: Dispatch<SetStateAction<string | boolean>>;
}): React.ReactElement => {
  const [editingTimestamp, setEditingTimestamp] =
    useState<IncidentTimestamp | null>(null);

  return (
    <div>
      <ErrorBoundary fallback={<GenericErrorMessage />}>
        <SettingsSubHeading
          title="Core timestamps"
          explanation={`Timestamps are used to store data on the important moments that occur throughout the lifecycle of an incident.`}
          className="!mb-4"
        />
        <DefaultTimestampsList className={"mb-8"} />
        <SettingsSubHeading
          title="Custom timestamps"
          explanation={`By default, each incident status has an associated timestamp. You can edit these, or add additional timestamps below.`}
          className="!mb-4"
          accessory={
            <GatedButton
              onClick={() => setShowAddTimestampModal(true)}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
              requiredProduct={Product.Response}
              analyticsTrackingId="add-incident-timestamp"
              icon={IconEnum.Add}
              theme={ButtonTheme.Secondary}
            >
              Add new timestamp
            </GatedButton>
          }
        />
        <CustomTimestampsList
          metrics={metrics}
          onEdit={(item: IncidentTimestamp) => setEditingTimestamp(item)}
        />
        {showAddTimestampModal && (
          <IncidentTimestampCreateModal
            onClose={() => setShowAddTimestampModal(false)}
          />
        )}
        {editingTimestamp && (
          <IncidentTimestampEditModal
            onClose={() => setEditingTimestamp(null)}
            existingTimestamp={editingTimestamp}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};
