/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GitLabAttributes,
    GitLabAttributesFromJSON,
    GitLabAttributesFromJSONTyped,
    GitLabAttributesToJSON,
} from './GitLabAttributes';
import {
    GitLabProject,
    GitLabProjectFromJSON,
    GitLabProjectFromJSONTyped,
    GitLabProjectToJSON,
} from './GitLabProject';
import {
    GitLabUser,
    GitLabUserFromJSON,
    GitLabUserFromJSONTyped,
    GitLabUserToJSON,
} from './GitLabUser';

/**
 * 
 * @export
 * @interface IntegrationsGitLabReceiveWebhookRequestBody
 */
export interface IntegrationsGitLabReceiveWebhookRequestBody {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsGitLabReceiveWebhookRequestBody
     */
    event_type?: string;
    /**
     * 
     * @type {GitLabAttributes}
     * @memberof IntegrationsGitLabReceiveWebhookRequestBody
     */
    object_attributes?: GitLabAttributes;
    /**
     * 
     * @type {string}
     * @memberof IntegrationsGitLabReceiveWebhookRequestBody
     */
    object_kind?: string;
    /**
     * 
     * @type {GitLabProject}
     * @memberof IntegrationsGitLabReceiveWebhookRequestBody
     */
    project?: GitLabProject;
    /**
     * 
     * @type {GitLabUser}
     * @memberof IntegrationsGitLabReceiveWebhookRequestBody
     */
    user?: GitLabUser;
}

export function IntegrationsGitLabReceiveWebhookRequestBodyFromJSON(json: any): IntegrationsGitLabReceiveWebhookRequestBody {
    return IntegrationsGitLabReceiveWebhookRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsGitLabReceiveWebhookRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGitLabReceiveWebhookRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event_type': !exists(json, 'event_type') ? undefined : json['event_type'],
        'object_attributes': !exists(json, 'object_attributes') ? undefined : GitLabAttributesFromJSON(json['object_attributes']),
        'object_kind': !exists(json, 'object_kind') ? undefined : json['object_kind'],
        'project': !exists(json, 'project') ? undefined : GitLabProjectFromJSON(json['project']),
        'user': !exists(json, 'user') ? undefined : GitLabUserFromJSON(json['user']),
    };
}

export function IntegrationsGitLabReceiveWebhookRequestBodyToJSON(value?: IntegrationsGitLabReceiveWebhookRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_type': value.event_type,
        'object_attributes': GitLabAttributesToJSON(value.object_attributes),
        'object_kind': value.object_kind,
        'project': GitLabProjectToJSON(value.project),
        'user': GitLabUserToJSON(value.user),
    };
}

