/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackUserGroup,
    SlackUserGroupFromJSON,
    SlackUserGroupFromJSONTyped,
    SlackUserGroupToJSON,
} from './SlackUserGroup';

/**
 * 
 * @export
 * @interface SchedulesSetMirroredSlackUserGroupRequestBody
 */
export interface SchedulesSetMirroredSlackUserGroupRequestBody {
    /**
     * Whether to add the bot to the mirrored user group
     * @type {boolean}
     * @memberof SchedulesSetMirroredSlackUserGroupRequestBody
     */
    add_bot_to_mirrored_user_group?: boolean;
    /**
     * Slack ID for a Slack user group that we will mirror to
     * @type {string}
     * @memberof SchedulesSetMirroredSlackUserGroupRequestBody
     */
    mirrored_slack_user_group_id?: string;
    /**
     * 
     * @type {SlackUserGroup}
     * @memberof SchedulesSetMirroredSlackUserGroupRequestBody
     */
    new_slack_user_group?: SlackUserGroup;
}

export function SchedulesSetMirroredSlackUserGroupRequestBodyFromJSON(json: any): SchedulesSetMirroredSlackUserGroupRequestBody {
    return SchedulesSetMirroredSlackUserGroupRequestBodyFromJSONTyped(json, false);
}

export function SchedulesSetMirroredSlackUserGroupRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesSetMirroredSlackUserGroupRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add_bot_to_mirrored_user_group': !exists(json, 'add_bot_to_mirrored_user_group') ? undefined : json['add_bot_to_mirrored_user_group'],
        'mirrored_slack_user_group_id': !exists(json, 'mirrored_slack_user_group_id') ? undefined : json['mirrored_slack_user_group_id'],
        'new_slack_user_group': !exists(json, 'new_slack_user_group') ? undefined : SlackUserGroupFromJSON(json['new_slack_user_group']),
    };
}

export function SchedulesSetMirroredSlackUserGroupRequestBodyToJSON(value?: SchedulesSetMirroredSlackUserGroupRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add_bot_to_mirrored_user_group': value.add_bot_to_mirrored_user_group,
        'mirrored_slack_user_group_id': value.mirrored_slack_user_group_id,
        'new_slack_user_group': SlackUserGroupToJSON(value.new_slack_user_group),
    };
}

