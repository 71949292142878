import type { SVGProps } from "react";
import * as React from "react";
const SvgAttachment = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.735 11.134a.75.75 0 0 0-1.061 0l-4.773 4.773c-1.416 1.416-3.887 1.416-5.303 0a3.727 3.727 0 0 1-1.099-2.652c0-1.002.391-1.943 1.099-2.651l4.949-4.95a2.255 2.255 0 0 1 3.183 0 2.253 2.253 0 0 1 0 3.182l-4.95 4.95a.752.752 0 0 1-1.061 0 .752.752 0 0 1 0-1.061l4.772-4.773a.75.75 0 1 0-1.061-1.061l-4.772 4.773c-.425.425-.659.99-.659 1.591s.234 1.167.659 1.591a2.253 2.253 0 0 0 3.182 0l4.95-4.95a3.756 3.756 0 0 0 0-5.303c-1.417-1.418-3.889-1.416-5.304 0l-4.948 4.95A5.213 5.213 0 0 0 3 13.255c0 1.402.546 2.721 1.538 3.712a5.213 5.213 0 0 0 3.712 1.538 5.213 5.213 0 0 0 3.712-1.538l4.773-4.773a.749.749 0 0 0 0-1.06Z"
    />
  </svg>
);
export default SvgAttachment;
