"use client";

import {
  FormatOptions,
  formatTimestampLocale,
} from "@incident-io/status-page-ui";
import { DateTime } from "luxon";

// Displays a time in appropriate locale format. This polyfills for the more
// complex <LocalDateTime/> in the dashboard project, which is too heavyweight
// for us to use here.
export const LocalDateTime = ({
  timestamp,
  className,
  children,
  format,
}: {
  timestamp: DateTime;
  className?: string;
  format?: FormatOptions;
  children?: React.ReactNode;
}): React.ReactElement => {
  const displayTimestamp = formatTimestampLocale(timestamp, format);

  return (
    <span className={className} suppressHydrationWarning>
      {children || displayTimestamp}
    </span>
  );
};
