/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnCallNotificationMethod,
    OnCallNotificationMethodFromJSON,
    OnCallNotificationMethodFromJSONTyped,
    OnCallNotificationMethodToJSON,
} from './OnCallNotificationMethod';

/**
 * 
 * @export
 * @interface OnCallNotificationsListMethodsResponseBody
 */
export interface OnCallNotificationsListMethodsResponseBody {
    /**
     * 
     * @type {Array<OnCallNotificationMethod>}
     * @memberof OnCallNotificationsListMethodsResponseBody
     */
    methods: Array<OnCallNotificationMethod>;
}

export function OnCallNotificationsListMethodsResponseBodyFromJSON(json: any): OnCallNotificationsListMethodsResponseBody {
    return OnCallNotificationsListMethodsResponseBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsListMethodsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsListMethodsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'methods': ((json['methods'] as Array<any>).map(OnCallNotificationMethodFromJSON)),
    };
}

export function OnCallNotificationsListMethodsResponseBodyToJSON(value?: OnCallNotificationsListMethodsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'methods': ((value.methods as Array<any>).map(OnCallNotificationMethodToJSON)),
    };
}

