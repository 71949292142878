import {
  EngineReferenceBadge,
  MenuEntry,
  ReferenceSelectorPopover,
  ReferenceSelectorPopoverProps,
  referenceSource,
} from "@incident-shared/engine";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { FieldValues, Path, useController } from "react-hook-form";
import { lookupInScope } from "src/utils/scope";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

type SelectorProps = Omit<
  ReferenceSelectorPopoverProps,
  "renderTriggerButton" | "onSelectReference" | "renderOnSelectedForm"
> & {
  disabled: boolean;
};

export const ReferenceSelectorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, SelectorProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const {
    // We don't want to pass the ref onwards here: this is a complex component
    // which references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  const selectedReferenceKey = field.value;
  const source = referenceSource(selectedReferenceKey);

  const selectedReference = lookupInScope(props.scope, selectedReferenceKey);

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <div className="flex items-center space-x-2 min-w-0 grow">
        <ReferenceSelectorPopover
          {...inputProps}
          onSelectReference={(entry: MenuEntry) => field.onChange(entry.key)}
          renderTriggerButton={({ onClick }) => (
            <button
              onClick={onClick}
              className="group flex space-x-1 items-center min-w-0 grow text-sm"
              disabled={props.disabled}
            >
              <EngineReferenceBadge
                className="text-sm !rounded-2 shadow-sm !py-2"
                label={selectedReference?.label || "Choose a variable"}
                editable={!props.disabled}
                referenceSource={source}
                iconOverride={selectedReference?.icon as unknown as IconEnum}
                colorOverride={
                  selectedReference?.color as unknown as ColorPaletteEnum
                }
                reference={selectedReference?.key ?? ""}
                scope={props.scope}
              />
            </button>
          )}
        />
      </div>
    </FormInputWrapper>
  );
};
