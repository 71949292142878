/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimestampSetByRulePayload,
    IncidentTimestampSetByRulePayloadFromJSON,
    IncidentTimestampSetByRulePayloadFromJSONTyped,
    IncidentTimestampSetByRulePayloadToJSON,
} from './IncidentTimestampSetByRulePayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IncidentTimestampsCreateRequestBody
 */
export interface IncidentTimestampsCreateRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof IncidentTimestampsCreateRequestBody
     */
    description: TextNode;
    /**
     * Unique name of this timestamp
     * @type {string}
     * @memberof IncidentTimestampsCreateRequestBody
     */
    name: string;
    /**
     * Rules that are used to auto-set this timestamp
     * @type {Array<IncidentTimestampSetByRulePayload>}
     * @memberof IncidentTimestampsCreateRequestBody
     */
    set_by_rules?: Array<IncidentTimestampSetByRulePayload>;
    /**
     * Whether this incident timestamp should be set on incident creation.
     * @type {boolean}
     * @memberof IncidentTimestampsCreateRequestBody
     */
    set_on_creation: boolean;
}

export function IncidentTimestampsCreateRequestBodyFromJSON(json: any): IncidentTimestampsCreateRequestBody {
    return IncidentTimestampsCreateRequestBodyFromJSONTyped(json, false);
}

export function IncidentTimestampsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': TextNodeFromJSON(json['description']),
        'name': json['name'],
        'set_by_rules': !exists(json, 'set_by_rules') ? undefined : ((json['set_by_rules'] as Array<any>).map(IncidentTimestampSetByRulePayloadFromJSON)),
        'set_on_creation': json['set_on_creation'],
    };
}

export function IncidentTimestampsCreateRequestBodyToJSON(value?: IncidentTimestampsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': TextNodeToJSON(value.description),
        'name': value.name,
        'set_by_rules': value.set_by_rules === undefined ? undefined : ((value.set_by_rules as Array<any>).map(IncidentTimestampSetByRulePayloadToJSON)),
        'set_on_creation': value.set_on_creation,
    };
}

