import { Icon, IconEnum, IconSize } from "@incident-ui";
import { AnimatePresence, motion } from "framer-motion";
import React, { forwardRef, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { useResize } from "src/utils/use-resize";

export const AutoSizer = forwardRef<
  HTMLDivElement,
  { children: ({ width }) => React.ReactNode }
>(({ children }, ref) => {
  const { width } = useResize(ref as React.MutableRefObject<HTMLDivElement>);
  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      {children({ width })}
    </div>
  );
});

AutoSizer.displayName = "AutoSizer";

export const CollapsableContainer = ({
  title,
  collapsable,
  collapseByDefault,
  children,
}: {
  title?: string;
  collapsable?: boolean;
  collapseByDefault?: boolean;
  children: React.ReactNode;
}) => {
  const [collapsed, setCollapsed] = useState(collapseByDefault ?? false);
  return (
    <div
      className={tcx("bg-white border border-stroke rounded-md flex flex-col", {
        "flex-1": !collapsed,
      })}
    >
      <div
        className={tcx("flex flex-row items-center px-4 py-2 justify-between")}
      >
        <div className={"text-content-primary font-medium text-sm"}>
          {title ?? ""}
        </div>
        {collapsable ? (
          !collapsed ? (
            <CollapseButton
              label={"Hide"}
              iconName={IconEnum.Collapse}
              onClick={() => setCollapsed(true)}
            />
          ) : (
            <CollapseButton
              label={"Show"}
              iconName={IconEnum.Expand}
              onClick={() => setCollapsed(false)}
            />
          )
        ) : null}
      </div>
      <div className={"flex-1"}>
        <AnimatePresence>
          {!collapsed && (
            <motion.div
              initial={{ height: "0" }}
              animate={{ height: "100%" }}
              exit={{ height: "0" }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const CollapseButton = ({
  label,
  iconName,
  onClick,
}: {
  label: string;
  iconName?: IconEnum;
  onClick?: () => void;
}) => (
  <div
    className={
      "text-content-tertiary hover:text-slate-800 !no-underline text-xs flex flex-row items-center gap-0.5 hover:cursor-pointer"
    }
    onClick={onClick}
  >
    {label}
    {iconName && <Icon id={iconName} size={IconSize.Small} />}
  </div>
);
