/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DynamicAttributeOption,
    DynamicAttributeOptionFromJSON,
    DynamicAttributeOptionFromJSONTyped,
    DynamicAttributeOptionToJSON,
} from './DynamicAttributeOption';

/**
 * 
 * @export
 * @interface CatalogListAdditionalAttributesResponseBody
 */
export interface CatalogListAdditionalAttributesResponseBody {
    /**
     * 
     * @type {Array<DynamicAttributeOption>}
     * @memberof CatalogListAdditionalAttributesResponseBody
     */
    additional_attributes: Array<DynamicAttributeOption>;
}

export function CatalogListAdditionalAttributesResponseBodyFromJSON(json: any): CatalogListAdditionalAttributesResponseBody {
    return CatalogListAdditionalAttributesResponseBodyFromJSONTyped(json, false);
}

export function CatalogListAdditionalAttributesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListAdditionalAttributesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additional_attributes': ((json['additional_attributes'] as Array<any>).map(DynamicAttributeOptionFromJSON)),
    };
}

export function CatalogListAdditionalAttributesResponseBodyToJSON(value?: CatalogListAdditionalAttributesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'additional_attributes': ((value.additional_attributes as Array<any>).map(DynamicAttributeOptionToJSON)),
    };
}

