import { ModalContent, ModalHeader } from "@incident-ui/Modal";
import { Modal, ModalProps } from "@incident-ui/Modal/Modal";
import {
  TabPane,
  TabSection,
  TabSectionProps,
} from "@incident-ui/TabSection/TabSection";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type TabModalProps = ModalProps &
  TabSectionProps & {
    footer?: React.ReactNode;
    bgGrey?: boolean;
    contentClassName?: string;
  };

/**
 * A special modal that has tabs.
 */
export function TabModal({
  title,
  analyticsTrackingId,
  isOpen,
  onClose,
  children,
  footer,
  ...modalProps
}: TabModalProps): React.ReactElement {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      analyticsTrackingId={analyticsTrackingId}
      onClose={onClose as () => void}
      hideHeader
      {...modalProps}
    >
      <TabModalBody {...modalProps} title={title} onClose={onClose}>
        {children}
      </TabModalBody>
      {footer}
    </Modal>
  );
}

export function TabModalBody({
  onTabChange,
  tabs,
  children,
  defaultTab,
  tabBarAccessory,
  tabBarClassName,
  tabClassName,
  headingLevel,
  title,
  onClose,
  bgGrey,
  contentClassName,
}: Omit<TabModalProps, "isOpen" | "analyticsTrackingId">): React.ReactElement {
  return (
    <>
      <ModalHeader
        headingLevel={headingLevel}
        title={title}
        onClose={onClose as () => void}
      />
      <ModalContent
        backgroundColor={bgGrey ? "grey" : undefined}
        className={contentClassName}
      >
        <TabSection
          rootClassName={tcx("flex flex-col overflow-hidden")}
          tabBarClassName={tcx(
            "border-b border-stroke -mt-2 mb-2",
            bgGrey ? "bg-surface-secondary" : "",
            tabBarClassName,
          )}
          withIndicator
          tabs={tabs}
          defaultTab={defaultTab}
          onTabChange={onTabChange}
          tabBarAccessory={tabBarAccessory}
          tabClassName={tcx("!text-sm text-content-tertiary", tabClassName)}
        >
          {children}
        </TabSection>
      </ModalContent>
    </>
  );
}

export function TabModalPane({
  tabId,
  children,
  className,
}: {
  tabId: string;
  children: React.ReactNode;
  className?: string;
}): React.ReactElement {
  return (
    <TabPane tabId={tabId} className={className}>
      {children}
    </TabPane>
  );
}
