import { AIAssistantThreadMessage } from "@incident-io/api";
import { useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

import { AssistantQuery, Query } from "./AssistantQuery";

// AssistantThread is the main body of Assistant's chat overlay. It contains all the messages
// between the user and Assistant.
export const AssistantThread = ({
  messageLog,
}: {
  messageLog: AIAssistantThreadMessage[];
}) => {
  const queries: Record<string, Query> = {};

  const scrollRef = useRef<HTMLDivElement>(null);

  const [lastRef, setLastRef] =
    useState<React.RefObject<HTMLDivElement>>(scrollRef);

  const lastRefIsVisible = useInView(lastRef);

  // scrollToEnd scrolls to the end of the thread when new message are added, thereby keeping the
  // most recent message on screen. It won't scroll down if someone is looking at the thread further
  // up, as this would be jarring.
  const scrollToEnd = () => {
    if (!lastRefIsVisible) {
      return;
    }
    scrollRef.current?.scrollIntoView({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      behavior: "instant",
      block: "end",
    });
  };

  // When there are new messages, scroll to the bottom of the thread.
  useEffect(() => {
    scrollToEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(messageLog)]);

  // Group messages by question.
  messageLog.forEach((message) => {
    if (message.author === "user") {
      if (queries[message.id]) {
        queries[message.id].question = message;
      } else {
        queries[message.id] = { question: message, responses: [] };
      }
    }

    if (message.author === "assistant") {
      if (message.question_id && queries[message.question_id]) {
        queries[message.question_id].responses.push(message);
      } else if (message.question_id) {
        queries[message.question_id] = { question: null, responses: [message] };
      } else {
        // this is mostly used for displaying errors. see `addErrorMessageToMessageLog`
        queries[message.id] = { question: null, responses: [message] };
      }
    }
  });

  const isLast = (index: number) => Object.values(queries).length - 1 === index;

  return (
    <div className="w-full">
      {Object.values(queries).map((query, index) => (
        <AssistantQuery
          key={index}
          value={query}
          isLast={isLast(index)}
          scrollToEnd={scrollToEnd}
          setLastRef={setLastRef}
        />
      ))}
      <div ref={scrollRef}></div>
    </div>
  );
};
