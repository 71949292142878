import { Heading, StaticSingleSelect } from "@incident-ui";
import React from "react";

import { FilterFormElement } from "./FilterFormElement";
import { AvailableFilter } from "./utils";

export function FilterControls({
  filter,
  selectedOperator,
  onOperatorSelect,
}: {
  filter: AvailableFilter;
  selectedOperator: string | undefined;
  onOperatorSelect: (operator: string) => void;
}): React.ReactElement {
  const operator = filter.operators.find((op) => op.key === selectedOperator);

  return (
    <div className="px-2 flex flex-col gap-2 py-2">
      <Heading level={3} className="sr-only">
        {filter.label}: choose a filter option
      </Heading>
      <StaticSingleSelect
        options={filter.operators.map((op) => ({
          label: op.label,
          value: op.key,
        }))}
        value={selectedOperator}
        onChange={(v) => onOperatorSelect(v as string)}
      />
      {operator && (
        <div key={operator.key} aria-expanded={true}>
          <div aria-live="polite" id={`input-${operator.key}`}>
            <FilterFormElement
              formField={filter}
              operator={operator}
              overrideLabel={<></>} // Hide the label, as we already have it in the header bar
            />
          </div>
        </div>
      )}
    </div>
  );
}
