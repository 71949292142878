/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserOption,
    UserOptionFromJSON,
    UserOptionFromJSONTyped,
    UserOptionToJSON,
} from './UserOption';

/**
 * 
 * @export
 * @interface UsersTypeaheadResponseBody
 */
export interface UsersTypeaheadResponseBody {
    /**
     * 
     * @type {Array<UserOption>}
     * @memberof UsersTypeaheadResponseBody
     */
    options: Array<UserOption>;
}

export function UsersTypeaheadResponseBodyFromJSON(json: any): UsersTypeaheadResponseBody {
    return UsersTypeaheadResponseBodyFromJSONTyped(json, false);
}

export function UsersTypeaheadResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersTypeaheadResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': ((json['options'] as Array<any>).map(UserOptionFromJSON)),
    };
}

export function UsersTypeaheadResponseBodyToJSON(value?: UsersTypeaheadResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': ((value.options as Array<any>).map(UserOptionToJSON)),
    };
}

