/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody
 */
export interface DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody {
    /**
     * Whether or not all debrief invite rules will be ignored for test and tutorial incidents.
     * @type {boolean}
     * @memberof DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody
     */
    exclude_test_or_tutorial_from_invites?: boolean;
}

export function DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBodyFromJSON(json: any): DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody {
    return DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBodyFromJSONTyped(json, false);
}

export function DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exclude_test_or_tutorial_from_invites': !exists(json, 'exclude_test_or_tutorial_from_invites') ? undefined : json['exclude_test_or_tutorial_from_invites'],
    };
}

export function DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBodyToJSON(value?: DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exclude_test_or_tutorial_from_invites': value.exclude_test_or_tutorial_from_invites,
    };
}

