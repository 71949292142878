import { icons } from "@aeaton/react-prosemirror-config-default/icons";
import { Node, NodeSpec } from "prosemirror-model";
import { EditorState, Transaction } from "prosemirror-state";
import {
  addColumnAfter,
  addRowAfter,
  deleteColumn,
  deleteRow,
  deleteTable,
} from "prosemirror-tables";
import { isInTable, tableNodes } from "prosemirror-tables";

import { ToolbarGroup } from "./Toolbar";

// createTable is used by the "Insert Table" toolbar button to insert a new table of given size in
// to the editor.
const createTable = (
  state: EditorState,
  dispatch: (tr: Transaction) => void,
  {
    rowsCount,
    colsCount,
  }: {
    rowsCount: number;
    colsCount: number;
  },
): boolean => {
  const { schema } = state;
  const tableNode = schema.nodes.table;
  const tableRow = schema.nodes.tableRow;
  const tableCell = schema.nodes.tableDataCell;

  const cells: Node[] = [];
  const cell = tableCell.createAndFill();
  for (let i = 0; i < colsCount; i++) {
    cells.push(cell as Node);
  }

  const row = tableRow.create(null, cells as Node[]);
  const rows: Node[] = [];
  for (let i = 0; i < rowsCount; i++) {
    rows.push(row);
  }

  const table = tableNode.create(null, rows);

  if (dispatch) {
    dispatch(state.tr.replaceSelectionWith(table).scrollIntoView());
  }

  return true;
};

// tableToolbar is the UI elements for the editor toolbar for carrying out table related actions.
export const tableToolbar: ToolbarGroup = {
  id: "table",
  // Must be wrapped in a <div> or else will not open.
  dropDownButton: <div className="cursor-pointer pl-2">{icons.table}</div>,
  items: [
    {
      id: "insert-table",
      action: (state: EditorState, dispatch: (tr: Transaction) => void) => {
        // Detection via the enable property is not working correctly.
        if (isInTable(state)) {
          return false;
        }

        return createTable(state, dispatch, {
          rowsCount: 2,
          colsCount: 3,
        });
      },
      content: <div>Insert Table</div>,
      enable: (state: EditorState) => !isInTable(state),
      title: "Insert Table",
    },
    {
      id: "delete-table",
      action: (state: EditorState, dispatch: (tr: Transaction) => void) => {
        return deleteTable(state, dispatch);
      },
      content: <div>Delete Table</div>,
      enable: (state: EditorState) => isInTable(state),
      title: "Delete Table",
    },
    // TODO: https://linear.app/incident-io/issue/PINC-206/support-header-row-when-exporting-to-google-docs
    // {
    //   id: "toggle-header",
    //   action: (state: EditorState, dispatch: (tr: Transaction) => void) => {
    //     return toggleHeaderRow(state, dispatch);
    //   },
    //   content: <div>Toggle Header</div>,
    //   enable: (state: EditorState) => isInTable(state),
    //   title: "Toggle Header",
    // },
    {
      id: "add-row",
      action: (state: EditorState, dispatch: (tr: Transaction) => void) => {
        return addRowAfter(state, dispatch);
      },
      content: <div>Add Row</div>,
      enable: (state: EditorState) => isInTable(state),
      title: "Add Row",
    },
    {
      id: "delete-row",
      action: (state: EditorState, dispatch: (tr: Transaction) => void) => {
        return deleteRow(state, dispatch);
      },
      content: <div>Delete Row</div>,
      enable: (state: EditorState) => isInTable(state),
      title: "Delete Row",
    },
    {
      id: "add-column",
      action: (state: EditorState, dispatch: (tr: Transaction) => void) => {
        return addColumnAfter(state, dispatch);
      },
      content: <div>Add Column</div>,
      enable: (state: EditorState) => isInTable(state),
      title: "Add Column",
    },
    {
      id: "delete-column",
      action: (state: EditorState, dispatch: (tr: Transaction) => void) => {
        return deleteColumn(state, dispatch);
      },
      content: <div>Delete Column</div>,
      enable: (state: EditorState) => isInTable(state),
      title: "Delete Column",
    },
  ],
};

const tableNodesSpec = tableNodes({
  tableGroup: "block",
  cellContent: "block+",
  cellAttributes: {},
});

// table is a custom ProseMirror NodeSpec that uses camelCase (what we use) instead of snake_case.
export const table: NodeSpec = {
  ...tableNodesSpec.table,
  content: "tableRow+",
  attrs: {
    layout: { default: "default" },
  },
};

// tableDataCell is a custom ProseMirror NodeSpec that uses camelCase (what we use) instead of
// snake_case.
export const tableDataCell: NodeSpec = {
  ...tableNodesSpec.table_cell,
  group: "tableCell",
  attrs: {
    ...tableNodesSpec.table_cell.attrs,
    background: { default: "" },
  },
};

// tableHeaderCell is a custom ProseMirror NodeSpec that uses camelCase (what we use) instead of
// snake_case.
export const tableHeaderCell: NodeSpec = {
  ...tableNodesSpec.table_header,
  group: "tableCell",
};

// tableRow is a custom ProseMirror NodeSpec that uses camelCase (what we use) instead of
// snake_case.
export const tableRow: NodeSpec = {
  ...tableNodesSpec.table_row,
  content: "tableCell+",
};

// isInFirstCellOfTableRow is a utility function for checking if the cursor is currently in the
// first cell of a table row.
export const isInFirstCellOfTableRow = (state): boolean => {
  const { selection } = state;

  if (selection.$head) {
    const { depth } = selection.$head;

    for (let i = depth; i > 0; i--) {
      const nodeType = selection.$head.node(i).type.name;

      if (nodeType === "tableRow") {
        return selection.$head.index(i) === 0;
      }
    }
  }

  return false;
};

// checks if table row that the cursor is currently in is empty
export const tableRowIsEmpty = (state: EditorState): boolean => {
  const { selection } = state;
  if (selection.$head) {
    const { depth } = selection.$head;
    for (let i = depth; i > 0; i--) {
      const nodeType = selection.$head.node(i).type.name;
      if (nodeType === "tableRow") {
        const row = selection.$head.node(i);
        const cells = row.content;
        let isEmpty = true;
        cells.forEach((cell) => {
          if (cell.content.size > 2) {
            isEmpty = false;
          }
        });
        return isEmpty;
      }
    }
  }
  return true;
};
