/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageComponent,
    InternalStatusPageComponentFromJSON,
    InternalStatusPageComponentFromJSONTyped,
    InternalStatusPageComponentToJSON,
} from './InternalStatusPageComponent';
import {
    InternalStatusPageComponentGroup,
    InternalStatusPageComponentGroupFromJSON,
    InternalStatusPageComponentGroupFromJSONTyped,
    InternalStatusPageComponentGroupToJSON,
} from './InternalStatusPageComponentGroup';

/**
 * 
 * @export
 * @interface InternalStatusPageComponentOrGroup
 */
export interface InternalStatusPageComponentOrGroup {
    /**
     * 
     * @type {InternalStatusPageComponent}
     * @memberof InternalStatusPageComponentOrGroup
     */
    component?: InternalStatusPageComponent;
    /**
     * 
     * @type {InternalStatusPageComponentGroup}
     * @memberof InternalStatusPageComponentOrGroup
     */
    group?: InternalStatusPageComponentGroup;
}

export function InternalStatusPageComponentOrGroupFromJSON(json: any): InternalStatusPageComponentOrGroup {
    return InternalStatusPageComponentOrGroupFromJSONTyped(json, false);
}

export function InternalStatusPageComponentOrGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageComponentOrGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': !exists(json, 'component') ? undefined : InternalStatusPageComponentFromJSON(json['component']),
        'group': !exists(json, 'group') ? undefined : InternalStatusPageComponentGroupFromJSON(json['group']),
    };
}

export function InternalStatusPageComponentOrGroupToJSON(value?: InternalStatusPageComponentOrGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': InternalStatusPageComponentToJSON(value.component),
        'group': InternalStatusPageComponentGroupToJSON(value.group),
    };
}

