/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageUpdateComponentRequestBody
 */
export interface StatusPageUpdateComponentRequestBody {
    /**
     * A short description of this component
     * @type {string}
     * @memberof StatusPageUpdateComponentRequestBody
     */
    description?: string;
    /**
     * A human readable name for the component
     * @type {string}
     * @memberof StatusPageUpdateComponentRequestBody
     */
    name: string;
}

export function StatusPageUpdateComponentRequestBodyFromJSON(json: any): StatusPageUpdateComponentRequestBody {
    return StatusPageUpdateComponentRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateComponentRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateComponentRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
    };
}

export function StatusPageUpdateComponentRequestBodyToJSON(value?: StatusPageUpdateComponentRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
    };
}

