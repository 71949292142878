/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlackTokenScope
 */
export interface SlackTokenScope {
    /**
     * The reason we need this scope
     * @type {string}
     * @memberof SlackTokenScope
     */
    reason: string;
    /**
     * Slack scope name
     * @type {string}
     * @memberof SlackTokenScope
     */
    scope: string;
}

export function SlackTokenScopeFromJSON(json: any): SlackTokenScope {
    return SlackTokenScopeFromJSONTyped(json, false);
}

export function SlackTokenScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackTokenScope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': json['reason'],
        'scope': json['scope'],
    };
}

export function SlackTokenScopeToJSON(value?: SlackTokenScope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'scope': value.scope,
    };
}

