import { Trigger, Workflow } from "@incident-io/api";
import { useState } from "react";
import { useContext } from "react";
import { useQueryParams } from "src/utils/query-params";
import { assertUnreachable } from "src/utils/utils";

import { ClonedWorkflow } from "../../common/types";
import { WorkflowsFormContext } from "../WorkflowsFormContext";

export enum WorkflowDrawerState {
  None = "none",
  ChooseTrigger = "choose_trigger",
  AddStep = "add_step",
  EditStep = "edit_step",
  ViewActivity = "view_activity",
  ExportToTerraform = "export_to_terraform",
  AdvancedSettings = "advanced_settings",
}

export enum WorkflowModalState {
  None = "none",
  DeploySettings = "deploy_settings",
  DeleteConfirmation = "delete_confirmation",
  ConfigureTest = "configure_test",
  ChangeTriggerWarning = "change_trigger_warning",
  DisconnectTerraform = "disconnect_terraform",
}

export type WorkflowViewState = {
  drawerState: WorkflowDrawerState;
  onOpenDrawer: (state: WorkflowDrawerState) => void;
  onCloseDrawer: () => void;

  hasOpenDrawer: boolean;

  modalState: WorkflowModalState;
  onOpenModal: (state: WorkflowModalState) => void;
  onCloseModal: () => void;
};

export const useViewController = ({
  workflow,
  template,
  defaultTrigger,
}: {
  workflow?: Workflow | ClonedWorkflow;
  template?: Workflow | ClonedWorkflow;
  defaultTrigger?: Trigger;
}): WorkflowViewState => {
  const activity = useQueryParams().get("activity");

  // When you create a new workflow, we initialise the page with the choose trigger drawer open, as it's the
  // first thing you need to do.
  let defaultDrawerState = WorkflowDrawerState.ChooseTrigger;
  if (activity === "true") {
    defaultDrawerState = WorkflowDrawerState.ViewActivity;
    // We check both IDs and names because drafts have IDs but not names and clones have names but not IDs
  } else if (workflow?.id || workflow?.name || template || defaultTrigger) {
    defaultDrawerState = WorkflowDrawerState.None;
  }

  const [drawerState, setDrawerState] =
    useState<WorkflowDrawerState>(defaultDrawerState);

  const [modalState, setModalState] = useState<WorkflowModalState>(
    WorkflowModalState.None,
  );

  const hasOpenDrawer = drawerState !== WorkflowDrawerState.None;

  return {
    drawerState,
    hasOpenDrawer,
    onOpenDrawer: (state: WorkflowDrawerState) => setDrawerState(state),
    onCloseDrawer: () => setDrawerState(WorkflowDrawerState.None),
    modalState,
    onOpenModal: (state: WorkflowModalState) => setModalState(state),
    onCloseModal: () => setModalState(WorkflowModalState.None),
  };
};

// useSafeCloseWorkflowDrawer is a hook that returns a function that can be used to safely close the drawer.
// It guards against closing the drawer when there's potentially state to be lost.
// Because you can close the drawer by clicking outside of it, we need to host this logic centrally, and
// separate from each individual drawer.
export const useSafeCloseWorkflowDrawer = ({
  isStepsFormDirty,
  clearStepState,
  drawerState,
  onCloseDrawer,
}: {
  isStepsFormDirty: boolean;
  clearStepState: () => void;
  drawerState: WorkflowDrawerState;
  onCloseDrawer: () => void;
}) => {
  // The idea here is to guard against closing the drawer
  // when there's potentially state to be lost.
  const onSafeCloseDrawer = (opts?: { forceClose: boolean } | undefined) => {
    const forceClose = opts?.forceClose || false;

    switch (drawerState) {
      case WorkflowDrawerState.AddStep:
      case WorkflowDrawerState.ViewActivity:
      case WorkflowDrawerState.ExportToTerraform:
      case WorkflowDrawerState.AdvancedSettings:
      case WorkflowDrawerState.ChooseTrigger:
        // You can always close these
        onCloseDrawer();
        return;
      case WorkflowDrawerState.EditStep:
        if (isStepsFormDirty && !forceClose) {
          const confirmed = confirm(
            "You have unsaved changes, are you sure you want to close the step editor?",
          );
          // If the user doesn't confirm, don't close the drawer.
          if (!confirmed) return;
        }
        // Close the drawer and clean up any
        // state related with create or editing steps.
        clearStepState();
        onCloseDrawer();
        return;
      case WorkflowDrawerState.None:
        // Do nothing, it's not open...
        return;
      default:
        assertUnreachable(drawerState);
    }
  };

  return onSafeCloseDrawer;
};

export const useWorkflowsViewState = (): WorkflowViewState => {
  const ctx = useContext(WorkflowsFormContext);
  if (ctx && ctx.viewState) {
    return ctx.viewState;
  }
  throw new Error(
    "useWorkflowsViewState must be used within a WorkflowsFormProvider",
  );
};
