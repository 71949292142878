import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Route, Routes } from "react-router";

import { isDevelopment } from "../../utils/environment";
import { SlackCanvasPreviewCanvasPage } from "./SlackCanvasPreviewCanvasPage";
import { SlackCanvasPreviewPage } from "./SlackCanvasPreviewPage";

export const SlackCanvasPreviewRoute = () => {
  if (!isDevelopment()) {
    return <OrgAwareNavigate to="/404" replace />;
  }
  return (
    <Routes>
      <Route path="/:targetName" element={<SlackCanvasPreviewCanvasPage />} />
      <Route path="/" element={<SlackCanvasPreviewPage />} />
    </Routes>
  );
};
