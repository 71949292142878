import type { SVGProps } from "react";
import * as React from "react";
const SvgPriority = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.111 1.333h-1.778a2 2 0 0 0-2 2v1.778a2 2 0 0 0 2 2h1.778a2 2 0 0 0 2-2V3.333a2 2 0 0 0-2-2ZM13.111 8.889h-1.778c-1.103 0-2 .897-2 2v1.778c0 1.103.897 2 2 2h1.778c1.103 0 2-.897 2-2v-1.778c0-1.103-.897-2-2-2Zm.667 3.778a.667.667 0 0 1-.667.666h-1.778a.667.667 0 0 1-.666-.666v-1.778c0-.368.298-.667.666-.667h1.778c.368 0 .667.299.667.667v1.778ZM6.471 1.307a.667.667 0 1 0-.943.943l.862.862h-.612A4.893 4.893 0 0 0 .888 8a4.894 4.894 0 0 0 4.89 4.889h1.555a.667.667 0 0 0 0-1.333H5.778A3.56 3.56 0 0 1 2.222 8a3.56 3.56 0 0 1 3.556-3.556h.612l-.862.863a.667.667 0 0 0 .942.943l2-2a.667.667 0 0 0 0-.943l-1.999-2Z"
    />
  </svg>
);
export default SvgPriority;
