import type { SVGProps } from "react";
import * as React from "react";
const SvgClock = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm3.867 10.677a.75.75 0 0 1-1.043.19l-3.25-2.25A.749.749 0 0 1 9.251 10V5.75a.75.75 0 0 1 1.5 0v3.857l2.927 2.026a.749.749 0 0 1 .19 1.043l-.001.001Z"
    />
  </svg>
);
export default SvgClock;
