import { ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, EmptyState, IconEnum } from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPIMutation } from "src/utils/swr";

import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";

export const PostIncidentFlowEmptyState = (): React.ReactElement => {
  const { hasProduct } = useProductAccess();

  const { trigger, isMutating } = useAPIMutation(
    "postIncidentFlowList",
    undefined,
    async (apiClient) => {
      await apiClient.postIncidentFlowCreate({
        createRequestBody: { name: "Default" },
      });
    },
  );

  return (
    <LoadingWrapper loading={isMutating}>
      {hasProduct(Product.Response) ? (
        <EmptyState
          icon={IconEnum.Checklist}
          content="You don't have a post-incident flow configured yet."
          cta={
            <GatedButton
              analyticsTrackingId={"post-incident-flow-empty-state-get-started"}
              onClick={() => trigger({})}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
              theme={ButtonTheme.Primary}
              className="!inline-flex"
            >
              Get started
            </GatedButton>
          }
        />
      ) : (
        <ProductUpsellNotice
          featureName="Post-incident flows"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
    </LoadingWrapper>
  );
};
