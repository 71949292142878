import { ContentBox } from "../ContentBox";
import { Page } from "../Icons/Page";

export const NotFoundError = (): React.ReactElement => {
  return (
    <ContentBox>
      <div className="p-3 text-center min-h-[300px] flex flex-col items-center content-center justify-center space-y-2">
        <Page />
        <div className="text-content-primary dark:text-slate-50 text-lg font-medium pt-1">
          We couldn&rsquo;t find that page
        </div>
        <div className="text-sm text-slate-500 dark:text-slate-200">
          Please check that you have the correct URL.
        </div>
      </div>
    </ContentBox>
  );
};
