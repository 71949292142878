import type { SVGProps } from "react";
import * as React from "react";
const SvgCloudwatch = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#D72B6B"
      fillRule="evenodd"
      d="m16.907 16.022-1.954-1.734c-.132.14-.275.265-.424.385l1.993 1.772a.293.293 0 0 0 .408-.022.282.282 0 0 0-.023-.4m-4.882-1.44c1.652 0 2.995-1.324 2.995-2.951S13.677 8.68 12.025 8.68c-1.65 0-2.995 1.325-2.995 2.952s1.344 2.95 2.995 2.95Zm5.647 2.499a1.298 1.298 0 0 1-.958.418c-.308 0-.615-.106-.86-.324l-2.203-1.957a4.02 4.02 0 0 1-1.626.348c-2.202 0-3.993-1.765-3.993-3.935s1.79-3.935 3.993-3.935c2.202 0 3.993 1.766 3.993 3.935a3.85 3.85 0 0 1-.487 1.844l2.045 1.816a1.262 1.262 0 0 1 .096 1.79Zm-13.134-5.45h2.495v.984H4.538C3.313 12.616 2 11.46 2 9.739c0-1.393.752-2.462 2.033-2.93l-.001-.105c0-1.595 1.098-3.25 2.555-3.848 1.696-.7 3.5-.355 4.814.93.348.339.643.739.88 1.195.324-.2.697-.307 1.078-.306.903 0 1.913.64 2.146 2.039 1.255.328 2.468 1.269 2.468 3.047 0 .372-.052.727-.157 1.05l-.95-.297c.071-.228.11-.48.11-.753 0-1.646-1.412-2.057-2.018-2.158a.497.497 0 0 1-.408-.577c-.076-.984-.69-1.368-1.19-1.368a1.08 1.08 0 0 0-.859.443.507.507 0 0 1-.716.086.497.497 0 0 1-.154-.214c-.218-.597-.532-1.1-.932-1.49-.49-.475-1.834-1.503-3.727-.72-1.09.448-1.942 1.74-1.942 2.94 0 .136.007.269.024.398a.484.484 0 0 1-.12.387.505.505 0 0 1-.282.159c-.628.162-1.654.649-1.654 2.092 0 1.116.812 1.893 1.54 1.893Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudwatch;
