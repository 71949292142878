/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';
import {
    PostIncidentTaskConfig,
    PostIncidentTaskConfigFromJSON,
    PostIncidentTaskConfigFromJSONTyped,
    PostIncidentTaskConfigToJSON,
} from './PostIncidentTaskConfig';

/**
 * 
 * @export
 * @interface PostIncidentFlow
 */
export interface PostIncidentFlow {
    /**
     * Unique ID of this flow
     * @type {string}
     * @memberof PostIncidentFlow
     */
    id: string;
    /**
     * The post-incident statuses in this flow
     * @type {Array<IncidentStatus>}
     * @memberof PostIncidentFlow
     */
    incident_statuses: Array<IncidentStatus>;
    /**
     * The name of this post-incident flow. This must be unique within an organisation
     * @type {string}
     * @memberof PostIncidentFlow
     */
    name: string;
    /**
     * The configured tasks in this flow
     * @type {Array<PostIncidentTaskConfig>}
     * @memberof PostIncidentFlow
     */
    task_configs: Array<PostIncidentTaskConfig>;
}

export function PostIncidentFlowFromJSON(json: any): PostIncidentFlow {
    return PostIncidentFlowFromJSONTyped(json, false);
}

export function PostIncidentFlowFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_statuses': ((json['incident_statuses'] as Array<any>).map(IncidentStatusFromJSON)),
        'name': json['name'],
        'task_configs': ((json['task_configs'] as Array<any>).map(PostIncidentTaskConfigFromJSON)),
    };
}

export function PostIncidentFlowToJSON(value?: PostIncidentFlow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_statuses': ((value.incident_statuses as Array<any>).map(IncidentStatusToJSON)),
        'name': value.name,
        'task_configs': ((value.task_configs as Array<any>).map(PostIncidentTaskConfigToJSON)),
    };
}

