import { Attrs, Fragment, Node, NodeSpec, Slice } from "prosemirror-model";
import { Command, EditorState, Transaction } from "prosemirror-state";
import { EnrichedScope, lookupInScope } from "src/utils/scope";

import { schema } from "./schema";
import { InterpolatedRef } from "./TemplatedTextEditor";

// varSpec describes the prosemirror schema for an engine variable.
export const varSpec: NodeSpec = {
  attrs: {
    name: { default: "incident.name" },
    label: { default: "Incident Name" },
    missing: { default: false },
  },
  marks: "bold italic",
  inline: true,
  group: "inline",
  draggable: false,

  toDOM: (node) => [
    "span",
    {
      "var-name": node.attrs.type,
      "var-missing": node.attrs.missing,
      class: !node.attrs.missing
        ? "rounded text-content-primary border border-[1px] bg-surface-secondary border-stroke px-1.5 py-0.5 select-text hover:border-slate-500 hover:bg-surface-tertiary hover:cursor-pointer"
        : "rounded text-slate-600 border border-[1px] bg-surface-tertiary px-1.5 py-0.5 select-text",
    },
    node.attrs.label,
  ],
  parseDOM: [
    {
      tag: "span[var-name]",
      getAttrs: (dom: string | HTMLElement): false | Attrs | null => {
        if (typeof dom !== "string") {
          const name = dom.getAttribute("var-name");
          const missing = dom.getAttribute("var-missing");
          if (!name) {
            return false;
          }
          return { name, missing };
        }
        return false;
      },
    },
  ],
};

// insertVar is an editor command to insert a variable node.
export const insertVar = (name: string, label: string): Command => {
  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    if (dispatch) {
      dispatch(
        state.tr.replaceSelectionWith(
          schema.nodes.varSpec.create({ name, label }),
        ),
      );
    }
    return true;
  };
};

// replaceLabels updates any varSpec nodes in the document with the correct
// label, as they're not guaranteed to be correct in the serialised format. eg
// if a role is renamed, or when a legacy step is migrated on the backend & we
// don't have access to labels.
export const replaceLabels = (
  doc: Node,
  variableScope: EnrichedScope<InterpolatedRef>,
): Node => {
  type row = {
    node: Node;
    pos: number;
    parent: Node | null;
  };
  const results: Array<row> = [];
  doc.descendants((node: Node, pos: number, parent: Node | null) => {
    if (node?.type.name === "varSpec") results.push({ node, pos, parent });
  });
  results.forEach(({ node, pos }, _index) => {
    const variable = lookupInScope(variableScope, node.attrs.name);
    if (!variable) {
      const name = node.attrs.name;
      const label = "missing variable";
      const missing = true;
      const newNode = schema.nodes.varSpec.create({ name, label, missing });
      doc = doc.replace(
        pos,
        pos + node.nodeSize,
        new Slice(Fragment.from(newNode), 0, 0),
      );
      return;
    }
    const name = variable?.key;
    const label = variable?.label;
    const marks = node.marks;
    const newNode = schema.nodes.varSpec.create(
      { name, label },
      undefined,
      marks,
    );
    doc = doc.replace(
      pos,
      pos + node.nodeSize,
      new Slice(Fragment.from(newNode), 0, 0),
    );
  });
  return doc;
};
