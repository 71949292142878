import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import React from "react";
import { useLocation } from "react-router";
import { useIntercom } from "react-use-intercom";
import {
  IntegrationSettingsProviderEnum as IntegrationProvider,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import NotionOAuthScreenshot from "./notion_oauth_screenshot.png";

export const ConnectToNotionModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const { showArticle } = useIntercom();
  const { search } = useLocation();
  const { hasScope, identity } = useIdentity();
  const config = IntegrationConfigFor(IntegrationProvider.Notion);
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const errorOAuthCancelled = search.includes("error=cancelled");
  const errorOAuthFailed = search.includes("error=oauth_failed");
  const errorNoTemplate = search.includes("error=no_template");

  const onSubmit = () => {
    window.location.replace(
      `/auth/notion_install?organisation_id=${identity?.organisation_id}`,
    );
  };

  return (
    <Modal
      title={`Connect to ${config.label}`}
      isOpen
      analyticsTrackingId="connect-integration-deeplink"
      analyticsTrackingMetadata={{ integration: IntegrationProvider.Notion }}
      onClose={onClose}
    >
      <ModalContent>
        {errorOAuthCancelled && (
          <Callout theme={CalloutTheme.Danger} className={"mb-6 text-center"}>
            The installation flow was cancelled. Please try again.
          </Callout>
        )}
        {errorNoTemplate && (
          <Callout theme={CalloutTheme.Danger} className={"mb-6 text-center"}>
            {
              "The Notion Template wasn't used during the installation process. To use your own Notion database, please get in contact."
            }
          </Callout>
        )}
        {errorOAuthFailed && (
          <Callout theme={CalloutTheme.Danger} className={"mb-6 text-center"}>
            There was an error connecting to Notion.
            <br />
            Please try again, or{" "}
            <Button
              analyticsTrackingId={"jiracloud-multi-site-upsell"}
              theme={ButtonTheme.Link}
              href="mailto:support@incident.io"
            >
              talk to us{" "}
            </Button>
            for help
          </Callout>
        )}
        <p className="text-sm mb-2 text-slate-700">
          We&apos;re about to send you to {config.label}, where (if you have
          permission) you can set up the incident.io integration.
        </p>
        <p className="text-sm mb-2 text-slate-700">
          Review the permissions we request, and click{" "}
          <span className="font-semibold">Next</span>. You&apos;ll need to allow
          us to{" "}
          <span className="font-semibold">
            &quot;Use a template provided by the developer&quot;
          </span>
        </p>
        <Callout theme={CalloutTheme.Info} className="mb-2">
          This connection will belong to a specific Notion user, and you may
          wish to{" "}
          <Button
            theme={ButtonTheme.Link}
            onClick={() => showArticle(6804517)}
            analyticsTrackingId="notion-connection-help-page"
          >
            use a service account
          </Button>
          .
        </Callout>
        <p className="text-sm text-slate-700 mt-2">
          A page will be created within your{" "}
          <span className="font-semibold">private teamspace</span>. You should
          move this page to a place where your team will be able to access it.
        </p>
        <p className="text-sm text-slate-700">
          Once installed, you&apos;ll be redirected back to the integrations
          page.
        </p>
        <img
          src={NotionOAuthScreenshot}
          className={"shadow-lg mt-4 w-9/12 mx-auto"}
        />
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        onConfirm={onSubmit}
        confirmButtonText={"Install in Notion"}
        confirmButtonType={"button"}
        disabled={!canEditSettings}
      />
    </Modal>
  );
};
