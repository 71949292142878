import { useEffect, useRef } from "react";
import { tcx } from "src/utils/tailwind-classes";

// ErrorMessage displays an error message with our standard styling (red text)
export const ErrorMessage = ({
  message,
  className,
}: {
  className?: string;
  message?: React.ReactElement | string | null;
}): React.ReactElement | null => {
  const ref = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (message && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [ref, message]);

  if (!message) {
    return null;
  }

  return (
    <p
      ref={ref}
      className={tcx("text-sm text-red-500 break-words", className)}
      aria-live="assertive"
    >
      {message}
    </p>
  );
};
