import { StatusPageContentStatusSummaryWorstComponentStatusEnum as ComponentStatusEnum } from "@incident-io/api";
import cx from "classnames";
import _ from "lodash";
import { DateTime } from "luxon";

import { formatTimestampLocale } from "../../time-helpers";
import { useUIContext } from "../../UIContext";
import { useTranslations } from "../../use-translations";
import { ComponentStatusIcon } from "../Icons/ComponentStatusIcon";

export type RenderableIncident = {
  name: string;
  incident_id: string;
  status: ComponentStatusEnum;
};

export const DayOfIncidentsTooltipContents = ({
  date,
  hasData,
  incidents,
}: {
  date: DateTime;
  hasData: boolean;
  incidents: RenderableIncident[];
}): React.ReactElement => {
  const t = useTranslations("Tooltip");
  const { IncidentLink } = useUIContext();

  const uniqueIncidents = _.uniqBy(incidents, "incident_id");

  return (
    <>
      <div
        className={cx(
          "whitespace-nowrap text-left p-1",
          "text-content-tertiary",
          "dark:text-slate-500",
          "notranslate", // No need to translate dates when we're setting via locale
        )}
        suppressHydrationWarning
      >
        {formatTimestampLocale(date, "dateOnly")}
      </div>
      {uniqueIncidents.length === 0 ? (
        hasData ? (
          <div
            className={cx("text-sm flex items-center", "text-content-tertiary")}
          >
            <ComponentStatusIcon
              flavour="ok"
              className="flex-none h-7 py-1.5 px-1.5"
            />
            <span>{t("no_incidents")}</span>
          </div>
        ) : (
          <div
            className={cx(
              "text-sm flex items-center p-1",
              "text-content-tertiary",
            )}
          >
            {t("no_data_available")}
          </div>
        )
      ) : (
        uniqueIncidents.map((i) => (
          <IncidentLink
            key={i.incident_id}
            incident={i}
            className={incidentLinkClassNames(i.status)}
            analyticsTrackingId={null}
          >
            <IncidentLinkContents name={i.name} status={i.status} />
          </IncidentLink>
        ))
      )}
    </>
  );
};

export const incidentLinkClassNames = (status: ComponentStatusEnum) =>
  cx(
    // Have consistent heights
    "min-h-[32px] md:min-w-[200px]",
    "text-sm flex items-center font-medium group rounded transition cursor-pointer",
    "text-content-primary hover:bg-surface-secondary",
    "dark:text-slate-100 dark:hover:bg-surface-invert/50",
    "py-0.5 pr-2",
    // When there's no icon, pad the left a bit more
    status === "operational" ? "pl-1.5" : "pl-0.5",
  );

export const IncidentLinkContents = ({
  status,
  name,
}: Pick<RenderableIncident, "status" | "name">) => {
  return (
    <>
      {status === "operational" ? null : (
        <ComponentStatusIcon
          componentStatus={status}
          className="flex-none h-7 py-1.5 px-1.5"
        />
      )}
      <span className="text-left">{name}</span>
    </>
  );
};
