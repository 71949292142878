/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SalesforceAccountsView,
    SalesforceAccountsViewFromJSON,
    SalesforceAccountsViewFromJSONTyped,
    SalesforceAccountsViewToJSON,
} from './SalesforceAccountsView';

/**
 * 
 * @export
 * @interface IntegrationsSalesforceUpdateAccountViewResponseBody
 */
export interface IntegrationsSalesforceUpdateAccountViewResponseBody {
    /**
     * 
     * @type {SalesforceAccountsView}
     * @memberof IntegrationsSalesforceUpdateAccountViewResponseBody
     */
    account_view: SalesforceAccountsView;
}

export function IntegrationsSalesforceUpdateAccountViewResponseBodyFromJSON(json: any): IntegrationsSalesforceUpdateAccountViewResponseBody {
    return IntegrationsSalesforceUpdateAccountViewResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsSalesforceUpdateAccountViewResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsSalesforceUpdateAccountViewResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_view': SalesforceAccountsViewFromJSON(json['account_view']),
    };
}

export function IntegrationsSalesforceUpdateAccountViewResponseBodyToJSON(value?: IntegrationsSalesforceUpdateAccountViewResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_view': SalesforceAccountsViewToJSON(value.account_view),
    };
}

