import {
  HeaderBar,
  HeaderBarTitle,
} from "@incident-shared/layout/HeaderBar/HeaderBar";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
} from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useLocation } from "react-router";
import {
  Incident,
  IncidentModeEnum,
  IncidentVisibilityEnum,
} from "src/contexts/ClientContext";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "src/routes/legacy/IncidentRoute";
import { tcx } from "src/utils/tailwind-classes";

import {
  useNavigateToModal,
  useQueryParams,
} from "../../../../utils/query-params";
import { incidentInEditableStatus } from "../helpers";
import { useIncident } from "../hooks";
import { CommsChannelButton } from "./CommsChannelButton";
import { EditIncidentOverflowButton } from "./EditIncidentOverflowButton";

export const IncidentHeader = ({
  incident,
  detailSidebarOpen,
  setDetailSidebarOpen,
  tabBarRef,
}: {
  incident: Incident | null;
  detailSidebarOpen: boolean;
  setDetailSidebarOpen: (open: boolean) => void;
  tabBarRef: React.RefObject<HTMLDivElement>;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const navigateToModal = useNavigateToModal();
  const goBack = () => {
    // If the user came from elsewhere in incident.io, just go back to the previous page.
    // This should preserve any filters.
    if (history.state?.usr?.isInternalRedirect) {
      history.back();
    } else {
      // Otherwise, they must have clicked on a link to the incident from elsewhere. In this case,
      // just go back to the incident list.
      navigate("/incidents");
    }
  };

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  return (
    <div>
      <HeaderBar
        className="border-b-0"
        title={incident ? `INC-${incident.external_id}: ${incident.name}` : ""}
        icon={
          incident?.visibility === IncidentVisibilityEnum.Public
            ? IconEnum.Incident
            : IconEnum.Lock
        }
        backOnClick={goBack}
        titleNode={
          incident && (
            <HeaderBarTitle
              title={incident.name}
              titleNode={<IncidentName incidentId={incident.id} />}
              crumbs={[{ title: "Incidents", to: "/incidents" }]}
              onEditTitle={() =>
                navigateToModal(IncidentHeaderModal.RenameIncident)
              }
              titleAccessory={
                <>
                  {isPrivate && <PrivateIncidentBadge />}
                  <IncidentModeBadge incident={incident} />
                </>
              }
            />
          )
        }
        accessory={
          <IncidentDetailsAccessory
            incidentId={incident?.id || null}
            detailSidebarOpen={detailSidebarOpen}
            openSidebar={() => setDetailSidebarOpen(!detailSidebarOpen)}
          />
        }
      />
      <div ref={tabBarRef} />
    </div>
  );
};

function IncidentDetailsAccessory({
  incidentId,
  detailSidebarOpen,
  openSidebar,
}: {
  incidentId: string | null;
  detailSidebarOpen: boolean;
  openSidebar: () => void;
}): React.ReactElement {
  const { incident } = useIncident(incidentId);

  const location = useLocation();
  const navigate = useOrgAwareNavigate();
  const queryParams = useQueryParams();
  const { pathname } = location;
  const modalInPath = Object.values(IncidentHeaderModal).find((m) =>
    pathname.includes(m),
  );
  const setModalOpen = (modal: IncidentHeaderModal | null) => {
    if (modal) {
      const newPath =
        `${pathname + (pathname.endsWith("/") ? "" : "/")}${modal}?` +
        queryParams.toString();
      navigate(newPath, { replace: true });
    } else {
      navigate(pathname.replace(modalInPath ?? "", ""), { replace: true });
    }
  };
  const streamDrawerInPath = pathname.includes(IncidentDrawer.Streams);

  const incidentIsEditable =
    incident && incidentInEditableStatus(incident) && !streamDrawerInPath;

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  return (
    <div className="flex items-center gap-2">
      {isPrivate ? (
        <Button
          theme={ButtonTheme.Secondary}
          icon={IconEnum.LockClosed}
          analyticsTrackingId="manage-access"
          onClick={() => setModalOpen(IncidentHeaderModal.ManageAccess)}
        >
          Manage access
        </Button>
      ) : null}
      {incidentIsEditable ? (
        <EditIncidentOverflowButton
          incident={incident}
          setModalOpen={setModalOpen}
        />
      ) : null}
      <CommsChannelButton incidentId={incidentId} />
      <Button
        theme={ButtonTheme.Secondary}
        analyticsTrackingId="incident-sidebar-toggle shrink-0"
        onClick={openSidebar}
        className={"xl:hidden"}
        icon={
          detailSidebarOpen
            ? IconEnum.SidebarLeftShow
            : IconEnum.SidebarLeftHide
        }
        title={detailSidebarOpen ? "Hide details" : "Show details"}
      />
    </div>
  );
}

const IncidentName = ({
  incidentId,
}: {
  incidentId: string;
}): React.ReactElement => {
  const { incident } = useIncident(incidentId);
  const showToast = useToast();

  const copyToClipboard = (incident: Incident) => {
    navigator.clipboard.writeText(`INC-${incident.external_id}`);
    showToast({
      theme: ToastTheme.Info,
      title: `"INC-${incident.external_id}" copied to clipboard`,
      description: "Paste it wherever you like.",
    });
  };

  if (!incident) {
    return <LoadingBar />;
  }

  return (
    <div className={tcx("flex items-center truncate gap-1")}>
      <span
        className="hover:cursor-pointer shrink-0"
        onClick={() => copyToClipboard(incident)}
        title="Copy incident ID to clipboard"
      >
        {`INC-${incident.external_id}`}
      </span>
      <span className="min-w-0 truncate">{incident.name}</span>
    </div>
  );
};

function PrivateIncidentBadge() {
  return (
    <Badge
      size={BadgeSize.Small}
      theme={BadgeTheme.Unstyled}
      className="bg-alarmalade-surface text-alarmalade-content"
    >
      Private
    </Badge>
  );
}

function IncidentModeBadge({
  incident,
}: {
  incident: Incident;
}): React.ReactElement | null {
  const modeToLabelAndIcon = {
    [IncidentModeEnum.Test]: "Test",
    [IncidentModeEnum.Tutorial]: "Tutorial",
    [IncidentModeEnum.Retrospective]: "Retrospective",
  };

  const label = modeToLabelAndIcon[incident.mode];

  if (!label) {
    return null;
  }

  return (
    <Badge size={BadgeSize.Small} theme={BadgeTheme.Warning} label={label} />
  );
}
