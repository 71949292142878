import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { FormModeEnum } from "@incident-shared/forms/v2/modes";
import { CreateEditFormProps } from "@incident-shared/forms/v2/modes";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import { STATUS_PAGE_INCIDENT_STATUS_NAME } from "@incident-shared/utils/StatusPages";
import {
  ButtonTheme,
  EmptyState,
  IconEnum,
  ModalFooter,
  StackedList,
} from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  MessageTemplateConfigIncidentStatusEnum as IncidentStatusEnum,
  PreDefinedUpdateConfig,
  PreDefinedUpdateConfigIncidentStatusEnum as RequestIncidentStatusEnum,
  ScopeNameEnum,
  StatusPage,
  StatusPageCreateTemplateRequestBodyStatusPageIncidentTypeEnum as IncidentTypeEnum,
  StatusPageCreateTemplateRequestBodyTemplateTypeEnum as TemplateTypeEnum,
  StatusPageDeleteTemplateRequest,
  StatusPageTemplate,
  TextNode,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { INCIDENT_TYPE_TO_CONFIG } from "./TemplateSettingsPage";

export type PreDefinedUpdate = Omit<
  StatusPageTemplate,
  "message_template_config"
> & {
  predefined_update_config: PreDefinedUpdateConfig; // This is now mandatory!
};

export const PreDefinedUpdatesSection = ({
  incidentType,
  page,
  templates: allTemplates,
}: {
  incidentType: IncidentTypeEnum;
  page: StatusPage;
  templates: PreDefinedUpdate[];
}): React.ReactElement => {
  const { label, preDefinedUpdateHelpText: helptext } =
    INCIDENT_TYPE_TO_CONFIG[incidentType];

  const templates = allTemplates.filter(
    (t) =>
      (t.status_page_incident_type as unknown as IncidentTypeEnum) ===
      incidentType,
  );

  const [createEditModalState, setCreateEditModalState] = useState<
    PreDefinedUpdate | "create" | null
  >(null);

  const getCreateEditProps = (
    modalState: PreDefinedUpdate | "create",
  ): CreateEditFormProps<PreDefinedUpdate> =>
    modalState === "create"
      ? {
          mode: FormModeEnum.Create,
          initialData: undefined,
        }
      : {
          mode: FormModeEnum.Edit,
          initialData: modalState,
        };

  const { trigger: onDelete } = useAPIMutation(
    "statusPageListTemplates",
    { statusPageId: page.id },
    async (apiClient, data: StatusPageDeleteTemplateRequest) => {
      await apiClient.statusPageDeleteTemplate(data);
      return;
    },
  );

  return (
    <>
      <div className="flex justify-between items-start space-x-4">
        <div className="space-y-4">
          <h3 className="font-medium">{label} templates</h3>
          <div className="mt-1 mb-2 text-sm text-slate-700">{helptext}</div>
        </div>
        <GatedButton
          theme={ButtonTheme.Naked}
          icon={IconEnum.Add}
          analyticsTrackingId={"create-pre-defined-update"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          onClick={() => setCreateEditModalState("create")}
          requiredScope={ScopeNameEnum.StatusPagesConfigure}
        >
          Create template
        </GatedButton>
      </div>
      <StackedList>
        {_.sortBy(templates, (t) => [
          t.predefined_update_config.template_name,
        ]).map((template) => {
          return (
            <PreDefinedUpdateListItem
              template={template}
              onDelete={onDelete}
              key={template.id}
              onEdit={() => setCreateEditModalState(template)}
            />
          );
        })}
      </StackedList>
      {templates.length === 0 && (
        <EmptyState
          icon={IconEnum.Message}
          content="You haven't configured any templates for this status page yet."
          cta={
            <GatedButton
              icon={IconEnum.Add}
              analyticsTrackingId={"create-pre-defined-update"}
              analyticsTrackingMetadata={{ status_page_id: page.id }}
              onClick={() => setCreateEditModalState("create")}
              requiredScope={ScopeNameEnum.StatusPagesConfigure}
            >
              Create template
            </GatedButton>
          }
        />
      )}
      {createEditModalState && (
        <PreDefinedUpdateCreateEditModal
          {...getCreateEditProps(createEditModalState)}
          incidentType={incidentType}
          page={page}
          onClose={() => setCreateEditModalState(null)}
        />
      )}
    </>
  );
};

const PreDefinedUpdateListItem = ({
  template,
  onDelete,
  onEdit,
}: {
  template: PreDefinedUpdate;
  onDelete: SubmitHandler<StatusPageDeleteTemplateRequest>;
  onEdit: () => void;
}) => {
  const update = template.predefined_update_config;
  return (
    <SettingsListItem
      title={update.template_name}
      buttons={{
        requiredScope: ScopeNameEnum.StatusPagesConfigure,
        edit: { onEdit },
        delete: {
          resourceTitle: update.template_name,
          onDelete: () => onDelete({ id: template.id }),
          deleteConfirmationTitle: "Delete template",
          deleteConfirmationContent: (
            <>
              Are you sure you want to delete the{" "}
              <span className="font-bold">{update.template_name}</span>{" "}
              template?
            </>
          ),
        },
      }}
    />
  );
};

type FormData = {
  template_name: string;
  incident_name?: string;
  message_content: TextNode;
  incident_status?: IncidentStatusEnum;
};

const PreDefinedUpdateCreateEditModal = ({
  mode,
  initialData,
  onClose,
  page,
  incidentType,
}: {
  onClose: () => void;
  page: StatusPage;
  incidentType: IncidentTypeEnum;
} & CreateEditFormProps<PreDefinedUpdate>): React.ReactElement => {
  const {
    statuses,
    label: incidentTypeLabel,
    exampleIncidentName,
  } = INCIDENT_TYPE_TO_CONFIG[incidentType];

  const title =
    mode === FormModeEnum.Edit ? "Edit template" : "Create template";

  const formMethods = useForm<FormData>({
    defaultValues: {
      ...initialData?.predefined_update_config,
      incident_status: initialData?.predefined_update_config
        .incident_status as unknown as IncidentStatusEnum,
    },
  });

  const {
    trigger: onSubmit,
    isMutating,
    genericError,
  } = useAPIMutation(
    "statusPageListTemplates",
    { statusPageId: page.id },
    async (apiClient, data: FormData) => {
      const config = {
        message_content: data.message_content,
        template_name: data.template_name,
        incident_name: data.incident_name,
        incident_status: data.incident_status as unknown as
          | RequestIncidentStatusEnum
          | undefined,
      };
      switch (mode) {
        case FormModeEnum.Create:
          await apiClient.statusPageCreateTemplate({
            createTemplateRequestBody: {
              status_page_id: page.id,
              template_type: TemplateTypeEnum.PreDefinedUpdate,
              status_page_incident_type: incidentType,
              predefined_update_config: config,
            },
          });
          return;
        case FormModeEnum.Edit: {
          if (!initialData) {
            throw new Error(
              "unreachable: form is in edit mode, but no template was provided",
            );
          }
          await apiClient.statusPageUpdateTemplate({
            id: initialData.id,
            updateTemplateRequestBody: {
              predefined_update_config: config,
            },
          });
          return;
        }
      }
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal<FormData>
      saving={isMutating}
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      title={title}
      disableQuickClose
      onClose={onClose}
      analyticsTrackingId={
        mode === FormModeEnum.Create
          ? "create-pre-defined-update"
          : "edit-pre-defined-update"
      }
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          saving={isMutating}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="template_name"
        label="Template Name"
        required="Please provide a name"
      />
      <InputV2
        formMethods={formMethods}
        name="incident_name"
        label={`${incidentTypeLabel} Name`}
        placeholder={`e.g. ${exampleIncidentName}`}
        helptext="The name of the status page incident, if it's currently unset"
        required={false}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="incident_status"
        label={`${incidentTypeLabel} Status`}
        helptext="The status of the incident"
        required={false}
        options={statuses.map((status) => ({
          value: status,
          label: STATUS_PAGE_INCIDENT_STATUS_NAME[status],
        }))}
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="message_content"
        label="Update Message"
        helptext="The text of the update message"
        includeVariables={false}
        includeExpressions={false}
        required="Please provide a message"
        format="mrkdwn"
      />
    </Form.Modal>
  );
};
