import { insertNodeOfType } from "@aeaton/prosemirror-commands";
import {
  blockquote,
  bold as proseMirrorBold,
  code,
  codeBlock,
  doc,
  heading,
  horizontalRule,
  image,
  italic,
  lineBreak,
  link as proseMirrorLink,
  list as proseMirrorList,
  listItem,
  paragraph,
  strikethrough,
  subscript,
  superscript,
  text,
} from "@aeaton/prosemirror-schema";
import {
  baseKeymap,
  chainCommands,
  exitCode,
  joinDown,
  joinUp,
  lift,
  selectParentNode,
  toggleMark,
} from "prosemirror-commands";
import { dropCursor } from "prosemirror-dropcursor";
import { gapCursor } from "prosemirror-gapcursor";
import { history, redo, undo } from "prosemirror-history";
import { undoInputRule } from "prosemirror-inputrules";
import { keydownHandler, keymap } from "prosemirror-keymap";
import { NodeSpec } from "prosemirror-model";
import { MarkSpec, Schema, SchemaSpec } from "prosemirror-model";
import { splitListItem } from "prosemirror-schema-list";
import { EditorState, Plugin } from "prosemirror-state";
import { goToNextCell, isInTable } from "prosemirror-tables";
import { tableEditing } from "prosemirror-tables";
import { addRowAfter, deleteRow } from "prosemirror-tables";
import { Decoration, DecorationSet } from "prosemirror-view";
import { formatTimestampLocale } from "src/utils/datetime";

import {
  isInFirstCellOfTableRow,
  table,
  tableDataCell,
  tableHeaderCell,
  tableRow,
  tableRowIsEmpty as tableRowIsEmpty,
} from "./tables";
import styles from "./TemplatedTextEditor.module.scss";
import { varSpec } from "./variable";

const link: MarkSpec = {
  ...proseMirrorLink,
  attrs: {
    ...proseMirrorLink.attrs,
    editing: { default: false },
    // The href is optional, if you're using href_var instead!
    href: { default: null },
    // We added `card_link` so that we can style confluence links using their spec. We don't actually use this in the
    // frontend, but we tell prose-mirror about it, so that it's not surprised if that attributes turns up.
    card_link: { default: false },
    // To support variables as link targets, we add these two attributes. First
    // the variable reference (e.g. incident.link)
    href_var: { default: null },
    // ...then the variable name
    href_var_label: { default: null },
  },
  toDOM: (node) => {
    return [
      "a",
      {
        href: node.attrs.href,
        "data-editing": node.attrs.editing,
        title: node.attrs.title,
        target: "_blank",
        rel: "noopener noreferrer",
      },
      0,
    ];
  },
};

const bold: MarkSpec = {
  ...proseMirrorBold,
  toDOM: (_) => {
    return [
      "span",
      {
        class: "font-semibold",
      },
      0,
    ];
  },
};

// list is a custom ProseMirror NodeSpec that uses the "bulleted" list type, instead of "numbered".
export const list: NodeSpec = {
  ...proseMirrorList,
  attrs: {
    ...proseMirrorList.attrs,
    type: {
      default: "bulleted",
    },
  },
};

export const user: NodeSpec = {
  attrs: {
    name: { default: "unset" },
    slack_user_id: { default: "unset" },
  },
  inline: true,
  group: "inline",
  draggable: false,

  toDOM: (node) => {
    return [
      "span",
      {
        class: "text-slate-600",
      },
      "@" + node.attrs.name,
    ];
  },
};

export const slackUserGroup: NodeSpec = {
  attrs: {
    name: { default: "unset" },
    slack_user_group_id: { default: "unset" },
  },
  inline: true,
  group: "inline",
  draggable: true,

  toDOM: (node) => {
    return [
      "span",
      {
        class: "text-slate-600",
      },
      "@" + node.attrs.name,
    ];
  },
};
export const slackChannel: NodeSpec = {
  attrs: {
    name: { default: "unset" },
    slack_channel_id: { default: "unset" },
    href: { default: null },
  },
  inline: true,
  group: "inline",
  draggable: true,

  toDOM: (node) => {
    return [
      node.attrs.href ? "a" : "span",
      {
        href: node.attrs.href,
        class: "bg-blue-200 !text-blue-content !no-underline",
        target: "_blank",
        rel: "noopener noreferrer",
      },
      "#" + node.attrs.name,
    ];
  },
};

const timestampFormatToOptions = {
  full: {
    dateStyle: "short",
    timeStyle: "short",
  },
  date: {
    dateStyle: "short",
  },
  "yyyy-MM-dd": {
    dateStyle: "short", // This isn't totally true, but it's close enough
  },
  "yyyy-dd-MM": {
    dateStyle: "short", // This isn't totally true, but it's close enough
  },
  iso8601: (timestamp: Date) => timestamp.toISOString(),
};

export const timestamp: NodeSpec = {
  attrs: {
    value_str: {},
    // format:
    // full= date and time
    // date = just date
    // yyyy-MM-dd: date for nerds
    // yyyy-dd-MM: date for american nerds
    format: { default: "full" },
  },
  inline: true,
  group: "inline",
  draggable: true,

  toDOM: (node) => {
    if (!node.attrs.value_str) {
      // Just render an empty span
      return ["span", {}, ""];
    }

    const timestamp = new Date(node.attrs.value_str);
    const format = node.attrs.format ?? "full";

    const opts =
      timestampFormatToOptions[format] ?? timestampFormatToOptions["full"];

    const formatted =
      typeof opts === "function"
        ? opts(timestamp)
        : formatTimestampLocale({
            timestamp,
            ...opts,
          });

    return [
      "time",
      {
        datetime: timestamp.toISOString(),
      },
      formatted,
    ];
  },
};

export const schemaSpec: SchemaSpec = {
  marks: {
    bold,
    italic,
    link,
    code,
    strikethrough,
    // superscript & subscript are here but not used, a dependency gets sad when
    // they're missing so i'm gonna leave em for now, they're not hurting
    // anyone.
    superscript,
    subscript,
  },
  nodes: {
    doc, // top-level node
    text, // plain text node
    paragraph, // paragraph must be the first node type of the "block" group
    codeBlock,
    image,
    lineBreak,
    horizontalRule, // a line break (<hr/>)
    heading,
    list,
    listItem,
    blockquote,
    varSpec, // our template variable node
    user, // our custom user node
    slackChannel, // our custom slack channel node
    slackUserGroup, // our custom slack user group node
    table,
    tableDataCell,
    tableHeaderCell,
    tableRow,
    timestamp,
  },
};

export const schema = new Schema(schemaSpec);

export const editorKeys = (): Plugin => {
  return keymap({
    "Mod-z": undo,
    "Shift-Mod-z": redo,
    Backspace: undoInputRule,
    "Mod-y": redo,
    "Alt-ArrowUp": joinUp,
    "Alt-ArrowDown": joinDown,
    "Mod-BracketLeft": lift,
    Escape: selectParentNode,
    "Meta-b": toggleMark(schema.marks.bold),
    "Meta-i": toggleMark(schema.marks.italic),
    "Mod-Enter": chainCommands(
      exitCode,
      insertNodeOfType(schema.nodes.lineBreak),
    ),
    "Shift-Enter": chainCommands(
      exitCode,
      insertNodeOfType(schema.nodes.lineBreak),
    ),
    "Ctrl-Enter": chainCommands(
      exitCode,
      insertNodeOfType(schema.nodes.lineBreak),
    ),

    // Related to actions within a table.
    Tab: goToNextCell(1),
    "Shift-Tab": goToNextCell(-1),

    // Related to actions within a list.
    Enter: splitListItem(schema.nodes.listItem),
  });
};

export const baseKeys = (): Plugin => {
  return keymap(baseKeymap);
};

// here we need to dynamically init a plugin to capture the placeholder text.
// we append this plugin to the editor's plugins array when it gets initialized
export const placeholderPlugin = (text: string): Plugin => {
  return new Plugin({
    props: {
      decorations(state: EditorState) {
        const doc = state.doc;
        const textNode = document.createElement("span");
        textNode.className = styles.placeholder;
        textNode.innerText = text;
        if (
          doc.childCount === 1 &&
          doc.firstChild &&
          doc.firstChild.isTextblock &&
          doc.firstChild.content.size === 0
        ) {
          return DecorationSet.create(doc, [Decoration.widget(1, textNode)]);
        }
        return undefined;
      },
    },
  });
};

// tabInLastTableCellPlugin creates a new row in an existing table if the cursor is within the
// last cell of the table, and `TAB` is pressed.
const tabInLastTableCellPlugin = (): Plugin => {
  return new Plugin({
    props: {
      handleKeyDown: keydownHandler({
        Tab: (state, dispatch, editorView) => {
          if (!isInTable(state)) {
            return false;
          }

          const { selection } = state;

          const isLastCell =
            selection.$from.indexAfter(selection.$from.depth - 1) ===
            selection.$from.node(-1).childCount;

          if (!isLastCell) {
            return goToNextCell(1)(state, dispatch, editorView);
          }

          if (dispatch) {
            addRowAfter(state, dispatch);
          }

          return true;
        },
      }),
    },
  });
};

// backspaceInFirstTableRowCellPlugin is deletes the current row of a table if the cursor is in
// the first cell of the table, and `backspace` is pressed.
const backspaceInFirstTableRowCellPlugin = (): Plugin => {
  return new Plugin({
    props: {
      handleKeyDown: keydownHandler({
        Backspace: (state, dispatch) => {
          if (!isInTable(state)) {
            return false;
          }

          if (!isInFirstCellOfTableRow(state)) {
            return false;
          }

          if (!tableRowIsEmpty(state)) {
            return false;
          }

          if (dispatch) {
            deleteRow(state, dispatch);
          }

          return true;
        },
      }),
    },
  });
};

// pressingRightKeyWithinVariable; ensures that when cursor is inside a varSpec
// and simultaneously at the end of the document's content, we add a space
// instead of preventing the user from jumping out of the variable. Similar to
// how Slack do it.
const pressingRightKeyWithinVariable = (): Plugin => {
  return new Plugin({
    props: {
      handleKeyDown: keydownHandler({
        ArrowRight: (state, dispatch) => {
          const pos = state.selection.$head.pos;

          // If we're not at the end of the document's content. Bail and exit
          // early.
          const isAtEndOfContent = state.doc.content.size - 1 === pos;
          if (!isAtEndOfContent) return false;

          // If we're not inside a variable. Bail and exit early.
          const isInsideVariable =
            state.doc.nodeAt(pos - 1)?.type?.name === "varSpec";
          if (!isInsideVariable) return false;

          if (dispatch) {
            // Append a space to the end of the document. Allowing the user to
            // break out of the variable.
            dispatch(state.tr.insertText(" ", pos));
          }

          // If we return true here, cursor will not end up after the space.
          return false;
        },
      }),
    },
  });
};

export const plugins = [
  // undo/redo
  history(),

  // nice cursor for dropping blocks
  dropCursor(),
  gapCursor(),

  // default key bindings
  editorKeys(),
  baseKeys(),

  // table support
  tableEditing(),
  tabInLastTableCellPlugin(),
  backspaceInFirstTableRowCellPlugin(),

  // variable modifiers
  pressingRightKeyWithinVariable(),
];
