/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MiscSettings,
    MiscSettingsFromJSON,
    MiscSettingsFromJSONTyped,
    MiscSettingsToJSON,
} from './MiscSettings';
import {
    WorkflowsAlphaSettings,
    WorkflowsAlphaSettingsFromJSON,
    WorkflowsAlphaSettingsFromJSONTyped,
    WorkflowsAlphaSettingsToJSON,
} from './WorkflowsAlphaSettings';

/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * 
     * @type {MiscSettings}
     * @memberof Settings
     */
    misc: MiscSettings;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof Settings
     */
    organisation_id: string;
    /**
     * 
     * @type {WorkflowsAlphaSettings}
     * @memberof Settings
     */
    workflows_alpha: WorkflowsAlphaSettings;
}

export function SettingsFromJSON(json: any): Settings {
    return SettingsFromJSONTyped(json, false);
}

export function SettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Settings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'misc': MiscSettingsFromJSON(json['misc']),
        'organisation_id': json['organisation_id'],
        'workflows_alpha': WorkflowsAlphaSettingsFromJSON(json['workflows_alpha']),
    };
}

export function SettingsToJSON(value?: Settings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'misc': MiscSettingsToJSON(value.misc),
        'organisation_id': value.organisation_id,
        'workflows_alpha': WorkflowsAlphaSettingsToJSON(value.workflows_alpha),
    };
}

