import React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from "src/components/@ui/Markdown/Markdown.module.scss";
import { tcx } from "src/utils/tailwind-classes";

export type MarkdownProps = {
  children: string;
  className?: string;
  components?: Components;
  unstyled?: boolean;
};

/**
Markdown renders a markdown string using the react-markdown library. In general, we
prefer using TemplatedText but this is useful if we haven't migrated over yet.
*/
export const Markdown = ({
  children,
  className,
  components,
  unstyled = false,
}: MarkdownProps): React.ReactElement => {
  const plugins = [remarkGfm];
  return (
    <ReactMarkdown
      className={tcx(styles.base, className, {
        [styles.styled]: !unstyled,
      })}
      remarkPlugins={plugins}
      disallowedElements={["img"]}
      components={components}
    >
      {children}
    </ReactMarkdown>
  );
};
