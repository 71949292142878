import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { DragHandle } from "@incident-shared/settings";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useCallback, useEffect } from "react";
import { FieldPath, useFormContext } from "react-hook-form";
import { TooltipButton } from "src/components/legacy/status-page-config/shared/TooltipButton";
import { tcx } from "src/utils/tailwind-classes";

import { EditablePath, FormType, StructureItem } from "./utils";

export const EditableComponent = ({
  item,
  structurePath,
  editing,
  setEditing,
  displayUptime,
  hideUptimeToggle,
  hidden,
  deleteComponent,
  group,
}: {
  item: { id: string; componentKey: string }; // either a component StructureItem or a GroupedStructureItem
  structurePath:
    | `structureItems.${number}`
    | `structureItems.${number}.contents.${number}`;
  editing: EditablePath;
  setEditing: (path: EditablePath) => void;
  deleteComponent: (componentKey: string) => void;
  hideUptimeToggle: boolean;
  displayUptime: boolean;
  hidden: boolean;
  group?: StructureItem;
}): React.ReactElement => {
  const { componentKey } = item;
  const formMethods = useFormContext<FormType>();
  const { getValues, setValue, watch } = formMethods;
  const component = watch(`components.${componentKey}`);

  const uptimeHelp = displayUptime
    ? "Hide historical data"
    : "Show historical data";

  const hideHelp = hidden ? "Show component" : "Hide component";

  const finishEditing = useCallback(() => {
    const formPath =
      `components.${componentKey}.description` as FieldPath<FormType>;
    const description = getValues(formPath);
    if (description === "") {
      // We never want to keep empty strings around: we use `null` to mean 'no
      // description set' and `""` to mean 'about to start typing a
      // description'. If someone clicked 'add description' but then did
      // nothing, reset to the 'no description set' state before saving.
      setValue(formPath, null);
    }

    setEditing(null);
  }, [componentKey, getValues, setEditing, setValue]);

  const checkForFinishEditing = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Escape" || e.code === "Enter") {
      // Don't submit the whole form
      e.stopPropagation();

      finishEditing();
    }
  };

  // If the component doesn't have a name, keep it in 'editing' mode so that the input can get an error attached
  const isEditing =
    editing?.componentKey === componentKey || component.name === "";
  const startEditing = () => setEditing({ componentKey });

  const {
    active,
    attributes,
    listeners,
    over,
    node,
    setNodeRef,
    setActivatorNodeRef,
    transition,
    transform,
  } = useSortable({
    id: item.id,
    animateLayoutChanges: defaultAnimateLayoutChanges,
  });
  const isOverContainer = over
    ? item.id === over.id && active?.data.current?.type !== "container"
    : false;

  // The type of structurePath means this will either be
  // `structureItems.${number}` (i.e. top-level) or
  // `structureItems.${number}.contents.${number}` (in a group's contents
  // array)
  const isInGroup = structurePath.split(".").length === 4;

  // If we're currently being edited and someone clicks outside the div that
  // contains the form inputs, end the editing.
  const detectClickOutside = useCallback(
    (ev: MouseEvent | TouchEvent) => {
      if (!node.current) return;
      // For some reason Typescript doesn't understand that ev.target is a Node
      if (node.current.contains(ev.target as Node | null)) return;

      finishEditing();
    },
    [finishEditing, node],
  );
  useEffect(() => {
    if (!isEditing) return undefined;
    window.addEventListener("mousedown", detectClickOutside);
    window.addEventListener("touchstart", detectClickOutside);
    return () => {
      window.removeEventListener("mousedown", detectClickOutside);
      window.removeEventListener("touchstart", detectClickOutside);
    };
  }, [detectClickOutside, isEditing]);

  return (
    <div
      ref={setNodeRef}
      className={tcx(
        "w-full px-3 flex flex-auto text-sm space-x-2 items-center bg-white",
        {
          // This is odd: at the top-level, we need to add 15px padding to make
          // the height match the editable height. When we're nested, it needs to
          // be 14px. Sorry.
          [isInGroup ? "py-[14px]" : "py-[15px]"]: !isEditing,
          "py-1": isEditing,
          "opacity-90": isOverContainer,
        },
      )}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      <button ref={setActivatorNodeRef} {...attributes} {...listeners}>
        <DragHandle />
      </button>
      {isEditing ? (
        <>
          <InputV2
            formMethods={formMethods}
            name={`components.${componentKey}.name`}
            onKeyDown={checkForFinishEditing}
            onFocus={startEditing}
            autoFocus
            className="w-1/3"
            required={" "}
            highlightErrors
            placeholder="Set a title"
          />
          {component.description == null ? (
            <Button
              analyticsTrackingId={null}
              className="flex-auto"
              theme={ButtonTheme.Naked}
              onClick={() => {
                setValue<`components.${string}.description`>(
                  `components.${componentKey}.description`,
                  "",
                );
                // Because the focus will leave this area, we force this back into an editing state
                startEditing();
              }}
            >
              Add description
            </Button>
          ) : (
            <InputV2
              formMethods={formMethods}
              name={`components.${componentKey}.description`}
              className="w-2/3"
              highlightErrors
              onKeyDown={checkForFinishEditing}
              /* We only want this to auto-focus if we're already editing this item (which means someone just clicked 'add description') */
              autoFocus={component.description === ""}
            />
          )}
        </>
      ) : (
        <>
          <div className={"flex-none cursor-pointer"} onClick={startEditing}>
            {component.name}
          </div>
          <div
            className={
              "text-content-tertiary flex-auto truncate cursor-pointer"
            }
            onClick={startEditing}
          >
            {/* Without the nbsp this doesn't render and therefore makes the
            containing div zero-height, and therefore not clickable */}
            {component.description || <>&nbsp;</>}
          </div>
        </>
      )}

      <div className="flex-none flex items-center space-x-1">
        {!hideUptimeToggle && (
          <TooltipButton
            title={
              group?.hidden
                ? "Show the group to toggle a components uptime"
                : uptimeHelp
            }
            onClick={() =>
              setValue(`${structurePath}.displayUptime`, !displayUptime, {
                shouldDirty: true,
              })
            }
            disabled={group?.hidden}
            iconName={displayUptime ? IconEnum.Chart : IconEnum.ChartCrossedOut}
          />
        )}

        <TooltipButton
          title={
            group?.hidden ? "Show the group to display a component" : hideHelp
          }
          disabled={group?.hidden}
          onClick={() =>
            setValue(`${structurePath}.hidden`, !hidden, { shouldDirty: true })
          }
          iconName={hidden ? IconEnum.Hide : IconEnum.View}
        />

        <TooltipButton
          iconName={IconEnum.Delete2}
          title="Delete component"
          onClick={() => deleteComponent(item.componentKey)}
        />
      </div>
    </div>
  );
};
