import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum } from "@incident-ui";
import _ from "lodash";
import React from "react";
import {
  IncidentLifecycle,
  IncidentLifecyclesCreateStatusRequestBodyCategoryEnum as StatusCategory,
  IncidentStatus,
  IncidentStatusCategoryEnum as AnyStatusCategory,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { SettingsSortableList } from "../../../SettingsSortableList";
import { IncidentStatusRow } from "./IncidentStatusRow";

type Props = {
  className?: string;
  onEdit: (status: IncidentStatus) => void;
  onCreate: () => void;
  additionalContext?: React.ReactNode;
} & (
  | {
      statuses: IncidentStatus[];
      category: StatusCategory.Closed;
      lifecycle?: never;
    }
  | {
      statuses?: never;
      category: StatusCategory.Active;
      lifecycle: IncidentLifecycle;
    }
);
export const IncidentStatusList = ({
  lifecycle,
  statuses,
  category,
  className,
  onEdit,
  onCreate,
  additionalContext,
}: Props): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const shouldHideAddStatus = category === StatusCategory.Closed;

  const refetchLifecycles = useAPIRefetch("incidentLifecyclesList", undefined);

  const { trigger: updateStatusRanks, isMutating: saving } = useAPIMutation(
    "incidentLifecyclesListAllStatuses",
    undefined,
    async (apiClient, updatedStatuses: IncidentStatus[]) => {
      const rank_updates = updatedStatuses.map(({ id, rank }) => ({
        resource_id: id,
        rank: rank,
      }));

      if (category === StatusCategory.Closed) {
        await apiClient.incidentLifecyclesUpdateClosedStatusRanks({
          updateClosedStatusRanksRequestBody: { rank_updates },
        });
      } else {
        await apiClient.incidentLifecyclesUpdateActiveStatusRanks({
          id: lifecycle?.id,
          updateActiveStatusRanksRequestBody: { rank_updates },
        });
        await refetchLifecycles();
      }
    },
  );

  // 0 and lower are saved for triage, declined, merged and canceled statuses
  const offset = 1;

  const statusesInThisCategory = statuses ?? lifecycle.active_statuses ?? [];

  // This is a bit of a hack. We want to nudge orgs to having only one closed
  // status called "Closed". If that is the case, we just hide the entire
  // section to customise it.
  //
  // We deliberately only compute this once on first render so that the
  // section doesn't suddenly disappear when making closed status changes. It
  // will be removed on page refresh.
  const hideSection = React.useRef(
    category === StatusCategory.Closed &&
      statusesInThisCategory.length === 1 &&
      statusesInThisCategory[0].name === "Closed",
  ).current;

  if (hideSection) {
    return null;
  }

  return (
    <div className="ml-1 mt-0.5 mb-2.5">
      <div className="space-y-3">
        <SettingsSortableList
          className={className}
          items={statusesInThisCategory}
          updateItemRanks={updateStatusRanks}
          canEdit={canEditSettings}
          dragHandleAtTop={true}
          renderItem={(item) => (
            <IncidentStatusRow
              isOnlyStatusInCategory={statusesInThisCategory.length === 1}
              status={item}
              onEdit={() => onEdit(item)}
            />
          )}
          offset={offset}
          saving={saving}
        />
      </div>
      {shouldHideAddStatus ? undefined : (
        <GatedButton
          onClick={onCreate}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          analyticsTrackingId="add-incident-status"
          icon={IconEnum.Add}
          theme={ButtonTheme.Naked}
          className="bg-surface-secondary font-medium mt-6 mb-2.5 ml-3"
        >
          Add status
        </GatedButton>
      )}
      {additionalContext}
    </div>
  );
};

// Find the highest rank of a category in a set of statuses
export const maxRankOfCategory = (
  statuses: IncidentStatus[],
  category: AnyStatusCategory,
): number | undefined => {
  const applicableStatuses = statuses.filter((x) => x.category === category);
  return _.max(applicableStatuses.map((x) => x.rank));
};
