import {
  ErrorResponse,
  InsightsDateRangeAggregationEnum,
  Panel,
} from "@incident-io/api";
import { ExtendedFormFieldValue } from "@incident-shared/filters";
import {
  DateRangePickerState,
  DEFAULT_DATE_RANGE_PICKER_STATE,
} from "@incident-shared/forms/v1/DateRangePicker";
import { captureException } from "@sentry/react";
import { createContext, useContext } from "react";
import { Environment, getEnvironment } from "src/utils/environment";

export type InsightsContextType = {
  dateRange: InsightsDateRangeState;
  setDateRange: (range: Partial<InsightsDateRangeState>) => void;
  saving?: boolean;
  filtersLoading: boolean;
  filtersError: ErrorResponse | null;
  viewMode: DashboardViewMode;
};

export enum DashboardViewMode {
  View = "view",
  EditFiltersAndVariables = "edit_filters_and_variables",
  EditDashboard = "edit_dashboard",
}

export type InsightsDateRangeState = {
  range: DateRangePickerState;
  aggregation: InsightsDateRangeAggregationEnum;
  is_comparison: boolean;
};

export type EditDashboardFormData = {
  name: string;
  icon: string;
  color: string;
  dateRange: InsightsDateRangeState;
  filters: ExtendedFormFieldValue[];
  panels: PanelFormData[];
};

export type PanelFormData = Omit<Panel, "filters"> & {
  variables_data: PanelVariableFormData;
  filters: ExtendedFormFieldValue[];
};

export type PanelVariableFormData = {
  [variableName: string]:
    | { label: string; value: string }
    | { label: string; value: string }[];
};
export type VariablesFormData = {
  [panelKey: string]: PanelVariableFormData;
};

export const InsightsContext =
  createContext<Partial<InsightsContextType> | null>(null);

export const useInsightsContext = (): InsightsContextType => {
  const ctx = useContext(InsightsContext);

  if (ctx) {
    return ctx as InsightsContextType;
  }

  const error = new Error(
    "useInsightsContext must be used within a InsightsShowDashboardProvider",
  );

  const environment = getEnvironment();
  if (environment === Environment.Development) {
    throw error;
  }

  captureException(error);

  // Return a dummy context to prevent the app from crashing
  return {
    dateRange: {
      range: DEFAULT_DATE_RANGE_PICKER_STATE,
      aggregation: InsightsDateRangeAggregationEnum.Days,
      is_comparison: false,
    },
    setDateRange: () => {
      /* do nothing */
    },
    filtersLoading: false,
    filtersError: null,
    viewMode: DashboardViewMode.View,
  };
};
