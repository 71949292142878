import { BaseAPI } from "@incident-io/api";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/react";
import _, { isEmpty } from "lodash";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FieldValues,
  Path,
  SubmitHandler,
  UseFormSetError,
} from "react-hook-form";
import {
  APITypes,
  ClientType,
  ErrorResponse,
  OrgHeaders,
  PaginationMeta,
  useClient,
} from "src/contexts/ClientContext";
import useSWR, {
  preload,
  SWRConfiguration,
  SWRResponse,
  useSWRConfig,
} from "swr";
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteFetcher,
  SWRInfiniteKeyLoader,
} from "swr/infinite";
import useSWRMutation, {
  MutationFetcher,
  SWRMutationResponse,
} from "swr/mutation";
import { useDeepCompareEffect } from "use-deep-compare";

import { GENERIC_ERROR_MESSAGE } from "./fetchData";

/**
 * useAPI is a very funky horror show, but it works. It wraps useSWR in such a way that:
 * - the SWR key is the name of the API you're calling (e.g. `"incidentsList"`),
 *   plus any request body
 * - you don't have to provide any explicit type parameters - it's inferred from
 *   the `api` param. For example if you pass `"incidentsList"`, the request body
 *   type (`IncidentsList`) and response type
 *   (`IncidentsListResponseBody`) will be inferred.
 */
export function useAPI<
  // TApi is the name of the API you want to call. Something like "incidentsList"
  // or "customFieldShow".
  TApi extends Exclude<keyof APITypes, keyof BaseAPI>,
  TAPIFunc extends APITypes[TApi],
  // TFetcher is the type of the function on the API client this fetches. E.g.
  // `(req: IncidentListRequest) => Promise<IncidentList>`
  TFetcher extends TAPIFunc extends (req: TRequest) => Promise<TResponse>
    ? TAPIFunc
    : never,
  SWROptions extends SWRConfiguration<TResponse, ErrorResponse>,
  // These two are then inferred, but helpful to name: it's the request param,
  // and the response body type that gets returned.
  TRequest extends Parameters<APITypes[TApi]>[0],
  TResponse extends Awaited<ReturnType<APITypes[TApi]>>,
>(
  // If you pass null, the request will not be executed.
  api: TApi | null,
  req: TRequest,
  swrOptions?: SWROptions,
): SWRResponse<TResponse, ErrorResponse, SWROptions> {
  const apiClient = useClient();
  const { orgHeaders } = apiClient;

  // In some Storybook stuff we mock the client: this tells you more clearly if
  // you've done that wrong
  if (api != null && !apiClient[api]) {
    throw new Error(`Missing API ${api}`);
  }

  // We need to bind `this` as the apiClient
  const fetcher =
    api != null ? (apiClient[api].bind(apiClient) as TFetcher) : null;

  // @ts-expect-error TS doesn't know this is allowed, but I don't think it matters enough to debug it.
  let options: SWROptions = {
    onErrorRetry: (error, _key, config, revalidate, { retryCount }) => {
      const retry = getRetryInterval(error, config, { retryCount });
      if (retry) {
        setTimeout(revalidate, retry.timeout, { retryCount: retry.retryCount });
      }
    },
  };
  if (swrOptions) {
    options = { ...options, ...swrOptions };
  }

  return useSWR(
    makeCacheKeyGenerator(api, req, orgHeaders),
    async (): Promise<TResponse> => {
      if (fetcher == null) throw new Error("not ready");
      try {
        return await fetcher(req);
      } catch (e) {
        if (e instanceof Response) {
          throw await e.json();
        }

        throw e;
      }
    },
    options,
  );
}

export type UseAPIInfiniteResponse<TResponse> = {
  responses: TResponse[];
  isLoading: boolean;
  isFullyLoaded: boolean;
  isValidating: boolean;
  loadMore: () => Promise<void>;
  refetch: () => Promise<void>;
  error: ErrorResponse | undefined;
};

/**
 * useAPIInfinite handles paginating through a set of data. It should feel very
 * similar to `useAPI`, but returns some extra functions:
 * - loadMore triggers fetching the next page
 * - isFullyLoaded indicates whether all pages have been loaded
 */
export function useAPIInfinite<
  // TApi is the name of the API you want to call. Something like "incidentsList"
  // or "customFieldShow".
  TApi extends Exclude<keyof APITypes, keyof BaseAPI>,
  TAPIFunc extends APITypes[TApi],
  // TFetcher is the type of the function on the API client this fetches. E.g.
  // `(req: IncidentListRequest) => Promise<IncidentList>`
  TFetcher extends TAPIFunc extends (req: TRequest) => Promise<TResponse>
    ? TAPIFunc
    : never,
  // These two are then inferred, but helpful to name: it's the request param,
  // and the response body type that gets returned.
  TRequest extends Parameters<APITypes[TApi]>[0] & {
    after: string | undefined;
  },
  TRequestArg extends Omit<TRequest, "after">,
  TResponse extends Awaited<ReturnType<APITypes[TApi]>> & {
    pagination_meta: PaginationMeta;
  },
>(
  api: TApi | null,
  req: TRequestArg,
  opts?: SWRInfiniteConfiguration<TResponse, ErrorResponse> & {
    eagerLoad?: boolean;
  },
): UseAPIInfiniteResponse<TResponse> {
  const apiClient = useClient();
  // We need to bind `this` as the apiClient
  const fetcher = api ? (apiClient[api].bind(apiClient) as TFetcher) : null;

  // NOTE: By default SWRInfinite will revalidate the first page when you call
  // `loadMore`. This is not what we want, because it'll perform two fetches
  // for each page. So we set `revalidateFirstPage` to false if not explicitly set by the caller.
  opts = _.defaults(opts, { revalidateFirstPage: false });
  const { orgHeaders } = apiClient;

  const getKey: SWRInfiniteKeyLoader<TResponse> = (
    _idx: number,
    prevPage: TResponse | null,
  ): [string, TRequest, OrgHeaders] | null => {
    if (!api) return null;

    // If we've reached the last page, don't try to load another one
    if (prevPage != null && prevPage.pagination_meta.page_size === undefined) {
      return null;
    }

    return [
      api,
      {
        after: prevPage?.pagination_meta?.after,
        ...req,
      } as unknown as TRequest,
      orgHeaders.current,
    ];
  };

  const fetchFn: SWRInfiniteFetcher<
    TResponse,
    SWRInfiniteKeyLoader<TResponse>
  > = async (key) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore this is apparently too complex for TypeScript to understand. It's going to be TResponse.
    await fetcher(key[1]);

  const { data, isLoading, isValidating, size, setSize, mutate, error } =
    useSWRInfinite(getKey, fetchFn, {
      onErrorRetry: (error, _key, config, revalidate, { retryCount }) => {
        const retry = getRetryInterval(error, config, { retryCount });
        if (retry) {
          setTimeout(revalidate, retry.timeout, {
            retryCount: retry.retryCount,
          });
        }
      },
      ...opts,
    });

  const responses = useMemo(() => data ?? [], [data]);

  // Reset the number of pages to load when the request shape changes: otherwise
  // we might show unrelated other pages which looks really silly.
  useDeepCompareEffect(() => {
    setSize((size) => (size > 1 ? 1 : size));
  }, [req]);

  // If eager loading is requested, keep bumping the number of pages until we're
  // fully loaded
  useEffect(() => {
    if (!opts?.eagerLoad) return;

    // If we're already loading the last page, do nothing
    if (size > responses.length) return;

    // If for some reason we have nothing, request the first page
    if (responses.length === 0) {
      setSize(1);
      return;
    }

    // If there are more pages to load, load them
    if (responses[responses.length - 1]?.pagination_meta?.after !== undefined) {
      setSize(size + 1);
      return;
    }

    // Otherwise, do nothing.
  }, [opts?.eagerLoad, size, setSize, responses]);

  const isFullyLoaded =
    responses.length > 0 &&
    responses[responses.length - 1]?.pagination_meta?.after === undefined;

  return {
    responses,
    error,
    isLoading: isLoading || size > responses.length,
    isFullyLoaded,
    isValidating,
    loadMore: useCallback(async () => {
      if (isFullyLoaded) return;

      await setSize((size) => size + 1);
    }, [isFullyLoaded, setSize]),
    refetch: useCallback(async () => {
      await mutate();
    }, [mutate]),
  };
}

/**
 * usePreload returns a preload function that will have SWR start fetching the
 * data in anticipation of it being used by `useAPI`.
 */
export function usePreload<
  // TApi is the name of the API you want to call. Something like "incidentsList"
  // or "customFieldShow".
  TApi extends Exclude<keyof APITypes, keyof BaseAPI>,
  // TFetcher is the type of the function on the API client this fetches. E.g.
  // `(req: IncidentListRequest) => Promise<IncidentList>`
  TFetcher extends APITypes[TApi] extends (req: TRequest) => Promise<TResponse>
    ? APITypes[TApi]
    : never,
  // These two are then inferred, but helpful to name: it's the request param,
  // and the response body type that gets returned.
  TRequest extends Parameters<APITypes[TApi]>[0],
  TResponse extends Awaited<ReturnType<APITypes[TApi]>>,
>(
  // If you pass null, the request will not be executed.
  api: TApi | null,
): (req: TRequest) => void {
  const apiClient = useClient();
  // We need to bind `this` as the apiClient
  const fetcher =
    api != null ? (apiClient[api].bind(apiClient) as TFetcher) : null;

  const { orgHeaders } = apiClient;

  return (req) =>
    preload(makeCacheKeyGenerator(api, req, orgHeaders), () => {
      if (fetcher == null) throw new Error("not ready");

      return fetcher(req);
    });
}

export function useInsertIntoSWRCache<
  TApi extends Exclude<keyof APITypes, keyof BaseAPI>,
  TRequest extends Parameters<APITypes[TApi]>[0],
  TResponse extends Awaited<ReturnType<APITypes[TApi]>>,
>(api: TApi): (req: TRequest, resp: TResponse) => void {
  const { mutate } = useSWRConfig();
  const { orgHeaders } = useClient();

  return (req: TRequest, res: TResponse) => {
    const key = makeCacheKeyGenerator(api, req, orgHeaders)();

    mutate(key, res, { revalidate: false });
  };
}

function makeCacheKeyGenerator<
  TApi extends Exclude<keyof APITypes, keyof BaseAPI>,
  TRequest = Parameters<APITypes[TApi]>[0],
>(api: TApi | null, req: TRequest, orgHeaders: MutableRefObject<OrgHeaders>) {
  return () => {
    // We stringify the request here to ensure that the cache key is unique even when the request
    // object is deeply nested. SWR thinks it should be able to handle nested objects, but we've
    // found otherwise!
    // Isaac has a theory about why this is the case here: https://github.com/incident-io/core/pull/25156#discussion_r1665646668
    return api != null ? [api, JSON.stringify(req), orgHeaders.current] : null;
  };
}

/** useAPIRefetch can be used to trigger a refresh of an API when you've done something that mutates the data, and you
 * don't actually use the data in the calling component.
 * For example: we might have 'UserList.tsx' and 'UserEditModal.tsx'
 * When we finish editing the user in the 'UserEditModal.tsx', we want the `userList` in the background
 * to reload with the updated information.  UserEditModal.tsx doesn't use the user list response, it just wants
 * to trigger a refresh of 'UserList.tsx' when it's done. Rather than pulling `userList` into the modal, we can instead
 * use `useAPIRefetch()` to trigger a refresh of the list before we close the modal.
 *
 * THIS DOES NOT WORK FOR UseAPIInfinite. Those caches require the much more zealous useRevalidate()
 *
 * https://swr.vercel.app/docs/mutation#revalidation
 */
export function useAPIRefetch<
  // TApi is the name of the API you want to call. Something like "incidentsList"
  // or "customFieldShow".
  TApi extends Exclude<keyof APITypes, keyof BaseAPI>,
  TAPIFunc extends APITypes[TApi],
  // TFetcher is the type of the function on the API client this fetches. E.g.
  // `(req: IncidentListRequest) => Promise<IncidentList>`
  _TFetcher extends TAPIFunc extends (req: TRequest) => Promise<TResponse>
    ? TAPIFunc
    : never,
  // These two are then inferred, but helpful to name: it's the request param,
  // and the response body type that gets returned.
  TRequest extends Parameters<APITypes[TApi]>[0],
  TResponse extends Awaited<ReturnType<APITypes[TApi]>>,
>(api: TApi | null, req: TRequest) {
  // If you pass null, the request will not be executed.
  const { mutate } = useSWRConfig();
  const { orgHeaders } = useClient();

  return () => mutate(makeCacheKeyGenerator(api, req, orgHeaders)());
}

export type UseAPIMutationReturn<
  TRequest extends FieldValues,
  TFetchResponse,
> = {
  genericError: string | undefined;
  trigger: (req: TRequest) => Promise<TFetchResponse>;
  isMutating: boolean;
  fieldErrors: ValidationErrors<TRequest> | null;
};

export type ValidationErrors<T> = Partial<Record<Path<T>, string>>;

/**
 * useAPIMutation wraps useSWRMutation, and is designed for mutating a resource
 * in our API.
 *
 * If the resource already exists, the key should be for the equivalent `show`
 * API, e.g.:
 *
 *     const { trigger , isMutating } = useAPIMutation(
 *       "severitiesShow",
 *       { id: severityId },
 *       async (client, payload) => client.severitiesUpdate(payload)
 *     )
 *
 * This will immediately update the resource using the response from the update
 * call, without another fetch call.
 *
 * If you're creating a new resource, or this resource is managed as a
 * whole-list (e.g. you're about to close a modal and show the whole list
 * again), use the list API as the key, and make sure to not return anything
 * from the mutation function:
 *
 *     const { trigger , isMutating } = useAPIMutation(
 *       "severitiesList",
 *       {},
 *       async (client, payload) => {
 *         await client.severitiesUpdate(payload);
 *         return;
 *       },
 *     )
 *
 * You might want to use the `return;` trick if the response from your API call
 * doesn't match the corresponding show API. This should be rare in our internal
 * API, but will cause this to re-fetch from the show API.
 */
export function useAPIMutation<
  TRequest extends FieldValues,
  // TApi is the name of the API you want to call. Something like "severityShow".
  TApi extends Exclude<keyof APITypes, keyof BaseAPI>,
  // TFetcher is the type of the function on the API client this fetches. E.g.
  // `(req: IncidentListRequest) => Promise<IncidentList>`
  TFetcher extends APITypes[TApi] extends (
    req: TFetchRequest,
  ) => Promise<TFetchResponse>
    ? APITypes[TApi]
    : never,
  // These two are then inferred, but helpful to name: it's the request param,
  // and the response body type that gets returned.
  TFetchRequest extends Parameters<APITypes[TApi]>[0],
  TFetchResponse extends Awaited<ReturnType<APITypes[TApi]>>,
>(
  fetchAPI: TApi,
  // This is the likely `{ id: resourceId }`, or similar.
  fetchReq: TFetchRequest,
  mutator: (
    apiClient: ClientType,
    payload: TRequest,
  ) => Promise<void | TFetchResponse>,
  // Note: this _could_ take options for useSWRMutation directly, but the ones
  // that aren't set by this hook are about optimistic updates, which we
  // wouldn't really make sense if you're using this hook.
  opts: {
    setError?: UseFormSetError<TRequest>;
    onError?: (err: Error, fieldErrors?: ValidationErrors<TRequest>) => void;
    showErrorToast?: string;
    onSuccess?: (data: TFetchResponse) => void;
  } = {},
): UseAPIMutationReturn<TRequest, TFetchResponse> {
  const apiClient = useClient();
  const { orgHeaders } = apiClient;

  const showToast = useToast();
  const fetcher = apiClient[fetchAPI].bind(apiClient) as TFetcher;
  const { onError, showErrorToast, onSuccess, setError } = opts || {};

  const [fieldErrors, setFieldErrors] =
    useState<ValidationErrors<TRequest> | null>(null);

  const doMutation: MutationFetcher<TFetchResponse, TRequest> = async (
    _key,
    { arg },
  ) => {
    // Reset any previous errors
    setFieldErrors(null);

    const res = await mutator(apiClient, arg);
    if (res) {
      return res;
    }

    return await fetcher(fetchReq);
  };

  const {
    trigger,
    isMutating,
    error,
  }: SWRMutationResponse<TFetchResponse, Error, TRequest> = useSWRMutation(
    makeCacheKeyGenerator(fetchAPI, fetchReq, orgHeaders),
    doMutation,
    {
      // We'll always either have just got back the new thing from the API, or
      // have refetched it inside the mutator function. That means we should:
      // 1. put that value in the SWR cache; and
      populateCache: true,
      // 2. cancel the automatic async revalidation that runs after a mutation by default
      revalidate: false,
      // We handle errors as return values
      throwOnError: false,
      onSuccess,
      onError: async (err: Error) => {
        // NOTE: this is very similar to the code in useMutation, however this
        // version builds the react-hook-form FieldErrors type directly, rather
        // than building an array of a custom `FieldError` type.

        // Try to handle this as a validation error first
        if (err instanceof Response) {
          const jsonErr: ErrorResponse = await err.json();
          if (
            jsonErr.type === "validation_error" &&
            jsonErr.errors.length > 0
          ) {
            const localFieldErrors: ValidationErrors<TRequest> = {};
            jsonErr.errors.forEach((e) => {
              const path = e?.source?.pointer as unknown as Path<TRequest>;
              const error = { type: "manual", message: e.message };

              localFieldErrors[path] = e.message;

              if (setError) {
                // I can't work out how to dynamically check whether the
                // pointer is valid for the form type, it would be really cool
                // if we could.
                setError(path, error);
              }
              if (showErrorToast) {
                let description = "";
                if (
                  localFieldErrors &&
                  Object.keys(localFieldErrors).length > 0
                ) {
                  description =
                    (Object.values(localFieldErrors)[0] as string) || "";
                }
                showToast({
                  theme: ToastTheme.Error,
                  title: showErrorToast,
                  description: description,
                  toastSide: ToastSideEnum.TopRight,
                });
              }
              if (onError) {
                onError(err, localFieldErrors);
              }
            });
            setFieldErrors(localFieldErrors);
            return;
          }
        }

        // Clearly this isn't a validation error, shame.
        console.error(err);
        captureException(err);
        if (onError) {
          onError(err);
        }
      },
    },
  );

  // If there's been an error, and we haven't translated it to field errors, say
  // "something went wrong".
  const genericError =
    error && isEmpty(fieldErrors) ? GENERIC_ERROR_MESSAGE : undefined;

  return {
    // Don't allow passing SWR options: it makes using this in `handleSubmit` awkward
    // @ts-expect-error TS thinks the first arg to trigger should be `(null | undefined) & TRequest, which is just silly.
    trigger: ((data: TRequest) => trigger(data)) as SubmitHandler<TRequest>,
    genericError,
    isMutating,
    fieldErrors,
  };
}

// Calculates the timeout for the next retry attempt.
const getRetryInterval: (
  error: ErrorResponse,
  config: SWRConfiguration,
  opts: { retryCount: number },
) => { retryCount: number; timeout: number } | false = (
  error: ErrorResponse,
  config: SWRConfiguration,
  opts: { retryCount: number },
) => {
  if (error.status === 404) return false; // not-found is not transient

  // Only retry up to 3 times.
  if (opts.retryCount >= 3) return false;

  // If there's no errors, we should exponentially back off the retry
  // time. This is to prevent a thundering herd problem.
  const maxRetryCount = config.errorRetryCount ?? 3;
  if (maxRetryCount !== undefined && opts.retryCount > maxRetryCount) {
    return false;
  }

  if (config.errorRetryInterval === undefined) {
    return false;
  }

  const timeout =
    Math.floor((Math.random() + 0.5) * Math.pow(2, opts.retryCount)) *
    config.errorRetryInterval;

  return { retryCount: opts.retryCount, timeout };
};
