/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentSubscriptionsCreateRequestBody
 */
export interface IncidentSubscriptionsCreateRequestBody {
    /**
     * Unique identifier of the incident that the user is subscribed to
     * @type {string}
     * @memberof IncidentSubscriptionsCreateRequestBody
     */
    incident_id: string;
    /**
     * ID of the internal status page from which this subscription originated
     * @type {string}
     * @memberof IncidentSubscriptionsCreateRequestBody
     */
    internal_status_page_id?: string;
}

export function IncidentSubscriptionsCreateRequestBodyFromJSON(json: any): IncidentSubscriptionsCreateRequestBody {
    return IncidentSubscriptionsCreateRequestBodyFromJSONTyped(json, false);
}

export function IncidentSubscriptionsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSubscriptionsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'internal_status_page_id': !exists(json, 'internal_status_page_id') ? undefined : json['internal_status_page_id'],
    };
}

export function IncidentSubscriptionsCreateRequestBodyToJSON(value?: IncidentSubscriptionsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'internal_status_page_id': value.internal_status_page_id,
    };
}

