/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkflowRun,
    WorkflowRunFromJSON,
    WorkflowRunFromJSONTyped,
    WorkflowRunToJSON,
} from './WorkflowRun';

/**
 * 
 * @export
 * @interface WorkflowsShowWorkflowRunResponseBody
 */
export interface WorkflowsShowWorkflowRunResponseBody {
    /**
     * 
     * @type {WorkflowRun}
     * @memberof WorkflowsShowWorkflowRunResponseBody
     */
    workflow_run: WorkflowRun;
}

export function WorkflowsShowWorkflowRunResponseBodyFromJSON(json: any): WorkflowsShowWorkflowRunResponseBody {
    return WorkflowsShowWorkflowRunResponseBodyFromJSONTyped(json, false);
}

export function WorkflowsShowWorkflowRunResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsShowWorkflowRunResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflow_run': WorkflowRunFromJSON(json['workflow_run']),
    };
}

export function WorkflowsShowWorkflowRunResponseBodyToJSON(value?: WorkflowsShowWorkflowRunResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflow_run': WorkflowRunToJSON(value.workflow_run),
    };
}

