import { TimeZoneSelectV2 } from "@incident-shared/forms/v2/inputs/TimeZoneSelectV2";
import { SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import {
  PostmortemSettings,
  PostmortemsSettingsUpdateDefaultTimezoneRequestBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../../SettingsSection";

type FormData = PostmortemsSettingsUpdateDefaultTimezoneRequestBody & {
  enabled: boolean;
};
const toForm = (settings: PostmortemSettings): FormData => ({
  default_timezone: settings.default_timezone,
  enabled: !!settings.default_timezone,
});

export const PostmortemTimezoneForm = ({
  postmortemSettings,
}: {
  postmortemSettings: PostmortemSettings;
}): React.ReactElement => {
  const showToast = useToast();
  const formMethods = useForm<FormData>({
    defaultValues: toForm(postmortemSettings),
  });
  const { setError, reset } = formMethods;

  const mutation = useAPIMutation(
    "postmortemsSettingsShow",
    undefined,
    async (apiClient, formData: FormData) =>
      await apiClient.postmortemsSettingsUpdateDefaultTimezone({
        updateDefaultTimezoneRequestBody: {
          default_timezone: formData.enabled
            ? formData.default_timezone
            : undefined,
        },
      }),
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(toForm(settings));
      },
      setError,
    },
  );

  const { hasScope } = useIdentity();
  const canEdit = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  return (
    <SettingsSection
      formMethods={formMethods}
      mutation={mutation}
      enabledPath="enabled"
      title="Specify a default timezone for all post-mortems"
      titleHeadingLevel={3}
      explanation={
        <>
          This timezone will be selected by default on post-mortem export. If no
          default timezone is set, we will preselect the local time of the user
          exporting the post-mortem.
        </>
      }
    >
      <TimeZoneSelectV2
        formMethods={formMethods}
        name="default_timezone"
        isClearable={false}
        disabled={!canEdit}
        placeholder="Default to user's local time"
      />
    </SettingsSection>
  );
};
