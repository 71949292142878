/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface IncidentLifecyclesListStatusesForIncidentTypeResponseBody
 */
export interface IncidentLifecyclesListStatusesForIncidentTypeResponseBody {
    /**
     * 
     * @type {Array<IncidentStatus>}
     * @memberof IncidentLifecyclesListStatusesForIncidentTypeResponseBody
     */
    incident_statuses: Array<IncidentStatus>;
}

export function IncidentLifecyclesListStatusesForIncidentTypeResponseBodyFromJSON(json: any): IncidentLifecyclesListStatusesForIncidentTypeResponseBody {
    return IncidentLifecyclesListStatusesForIncidentTypeResponseBodyFromJSONTyped(json, false);
}

export function IncidentLifecyclesListStatusesForIncidentTypeResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecyclesListStatusesForIncidentTypeResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_statuses': ((json['incident_statuses'] as Array<any>).map(IncidentStatusFromJSON)),
    };
}

export function IncidentLifecyclesListStatusesForIncidentTypeResponseBodyToJSON(value?: IncidentLifecyclesListStatusesForIncidentTypeResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_statuses': ((value.incident_statuses as Array<any>).map(IncidentStatusToJSON)),
    };
}

