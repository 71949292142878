import { DisableConfirmationModal } from "@incident-shared/forms/DisableConfirmationModal/DisableConfirmationModal";
import { ListEditorValue } from "@incident-shared/forms/v1/ListEditor";
import { ListEditorV2 } from "@incident-shared/forms/v2/editors/ListEditorV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Checkbox,
  ContentBox,
  GenericErrorMessage,
  Link,
  Loader,
  ModalFooter,
} from "@incident-ui";
import { CheckboxHelptextDisplayEnum } from "@incident-ui/Checkbox/Checkbox";
import { InputType } from "@incident-ui/Input/Input";
import { captureMessage } from "@sentry/react";
import { default as React, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { Form } from "src/components/@shared/forms";
import {
  SAMLSettings,
  SAMLShowResponseBody,
  ScopeNameEnum,
  TrialStatusPlanNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";

import { SettingsSubHeading } from "../../SettingsSubHeading";
import { UpsellNotice } from "../../UpsellNotice";

const heading = "Single sign-on (SAML)";

export const SamlSSOForm = (): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { showArticle } = useIntercom();

  const isProV1 =
    identity?.trial_status.plan_name === TrialStatusPlanNameEnum.ProV1;

  if (!identity?.feature_gates.saml) {
    return <SamlUpsellBanner showBadge={!isProV1} />;
  }

  return (
    <ContentBox className="p-6">
      <div className="fle x -center-y">
        <div className="grow">
          <SettingsSubHeading
            title={heading}
            explanation={
              <>
                Allow users to login through your organisation&apos;s SAML
                identity provider, such as Okta or Google SAML. You can learn
                more in our{" "}
                <Button
                  theme={ButtonTheme.Link}
                  analyticsTrackingId={"upsell-notice-learn-more"}
                  onClick={() => showArticle(6991102)}
                >
                  help center
                </Button>
                .
              </>
            }
          />
          <div className="mt-2 space-y-4">
            <SamlSSOFormInner />
          </div>
        </div>
      </div>
    </ContentBox>
  );
};

const SamlSSOFormInner = () => {
  const { hasScope } = useIdentity();

  const navigate = useOrgAwareNavigate();
  const [isConfiguringDomains, setIsConfiguringDomains] = useState(false);
  const [action, setAction] = useState<"configuring" | "deleting" | null>(null);
  const [showDisableModal, setShowDisableModal] = useState(false);

  const {
    data: samlConfigState,
    isLoading: loading,
    error,
  } = useAPI("sAMLShow", undefined);

  const { trigger: createSamlConfig } = useAPIMutation(
    "sAMLShow",
    undefined,
    async (apiClient, _) => {
      const res = await apiClient.sAMLCreate();
      // We now know that yes, this is enabled
      return { enabled: true, ...res };
    },
  );
  const onClickConfigure = async () => {
    setAction("configuring");
    const openSelfServeUrl = (settings: SAMLSettings | undefined) => {
      if (!settings?.self_serve_portal_url) {
        throw new Error('Received saml config without "self_serve_portal_url"');
      }
      window.location.href = settings.self_serve_portal_url;
    };
    if (samlConfigState?.enabled) {
      openSelfServeUrl(samlConfigState.saml_settings);
    } else {
      try {
        const { saml_settings } = await createSamlConfig({});
        openSelfServeUrl(saml_settings);
      } finally {
        setAction(null);
      }
    }
  };

  const { trigger: uninstallSamlConfig } = useAPIMutation(
    "sAMLShow",
    undefined,
    async (apiClient, _) => {
      await apiClient.sAMLDestroy();
      return { enabled: false };
    },
  );
  const onClickDisable = async () => {
    setAction("deleting");
    setShowDisableModal(false);
    try {
      await uninstallSamlConfig({});
      navigate("/login");
    } finally {
      setAction(null);
    }
  };

  const { trigger: setIsOptional, isMutating: settingIsOptional } =
    useAPIMutation(
      "sAMLShow",
      undefined,
      async (apiClient, { isOptional }: { isOptional: boolean }) => {
        await apiClient.sAMLSetIsOptional({
          setIsOptionalRequestBody: {
            is_optional: isOptional,
          },
        });
      },
    );

  if (loading || !samlConfigState) {
    return <Loader />;
  }

  if (error) {
    captureMessage("loading SAML state", { extra: { error } });
    return <GenericErrorMessage error={error} />;
  }

  const hasIncompleteSetup =
    samlConfigState.saml_settings !== undefined &&
    samlConfigState.saml_settings.has_connection &&
    samlConfigState.saml_settings.connection_state !== "active";

  // Only users with the required scope should be able to edit these settings.
  // But we still want to show the button to other users.
  const canEditSamlSettings = hasScope(ScopeNameEnum.SamlUpdate);
  const tooltipContent = canEditSamlSettings
    ? undefined
    : "You don't have permission to manage security settings.";

  return (
    <>
      {samlConfigState.enabled && samlConfigState.saml_settings && (
        <>
          <SamlEnabledBanner settings={samlConfigState.saml_settings} />
          <Checkbox
            id={"is_optional"}
            className="font-medium text-content-primary text-sm mb-4"
            onChange={() =>
              setIsOptional({
                isOptional: !samlConfigState.saml_settings?.is_optional,
              })
            }
            disabled={!canEditSamlSettings || settingIsOptional}
            label="Additionally show Sign in with Slack on login page"
            helptext={
              <>
                Use the &quot;Bypass SAML Login&quot; privilege in{" "}
                <Link
                  analyticsTrackingId={"user-roles"}
                  to="/settings/users/roles"
                >{`Users > Roles`}</Link>{" "}
                settings to set which roles are able to sign in with Slack
              </>
            }
            helptextDisplay={CheckboxHelptextDisplayEnum.Newline}
            checked={samlConfigState.saml_settings.is_optional}
          />
        </>
      )}
      <div className="flex flex-row gap-2">
        {samlConfigState.enabled &&
        samlConfigState.saml_settings?.connection_state === "active" ? (
          <>
            <GatedButton
              onClick={() => setShowDisableModal(true)}
              disabledTooltipContent={tooltipContent}
              analyticsTrackingId={"disable-saml"}
              theme={ButtonTheme.DestroySecondary}
              loading={action === "deleting"}
              disabled={!canEditSamlSettings || action === "configuring"}
            >
              Disable SAML
            </GatedButton>
            <GatedButton
              analyticsTrackingId={"configure-domains"}
              disabledTooltipContent={tooltipContent}
              onClick={() => setIsConfiguringDomains(true)}
              disabled={!canEditSamlSettings || !!action}
            >
              Configure domains
            </GatedButton>
            <GatedButton
              analyticsTrackingId={"manage-saml"}
              disabledTooltipContent={tooltipContent}
              onClick={onClickConfigure}
              loading={action === "configuring"}
              disabled={!canEditSamlSettings}
            >
              Manage connection
            </GatedButton>
          </>
        ) : (
          <GatedButton
            analyticsTrackingId={"connect-saml"}
            disabledTooltipContent={tooltipContent}
            onClick={onClickConfigure}
            loading={action === "configuring"}
            disabled={!canEditSamlSettings || action === "deleting"}
          >
            {hasIncompleteSetup ? "Finish setup" : "Connect"}
          </GatedButton>
        )}
      </div>
      {isConfiguringDomains && samlConfigState.saml_settings && (
        <ConfigureSamlDomainsModal
          settings={samlConfigState.saml_settings}
          onClose={() => setIsConfiguringDomains(false)}
        />
      )}
      {showDisableModal && (
        <DisableConfirmationModal
          helpText={
            <>
              <p className="mb-2">
                This action cannot be undone. To re-enable SAML, you will have
                to go through the set-up steps again.
              </p>
              <p className="mb-2">
                Users will be logged out and will have to log back in again
                using Sign-in with Slack.
              </p>
              <p className="mb-2">
                Please type &quot;disable SAML&quot; below to confirm.
              </p>
            </>
          }
          title={"Disable SAML"}
          submitText={"Disable SAML"}
          typeToConfirmPhrase={"disable SAML"}
          onSubmit={onClickDisable}
          onClose={() => setShowDisableModal(false)}
        />
      )}
    </>
  );
};

type DomainFormData = { domains: ListEditorValue<string>[] };
const ConfigureSamlDomainsModal = ({
  settings,
  onClose,
}: {
  settings: SAMLSettings;
  onClose: () => void;
}) => {
  const formMethods = useForm<DomainFormData>({
    defaultValues: {
      domains: settings.domains.map((d, i) => ({
        is_new: false,
        value: d.domain,
        sort_key: i,
        id: d.id,
      })),
    },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "sAMLShow",
    undefined,
    async (apiClient, data: DomainFormData): Promise<SAMLShowResponseBody> => {
      const sanitizedDomains = new Set(
        data.domains.map((d) => d.value.trim().toLowerCase()),
      );

      const { saml_domains } = await apiClient.sAMLSetDomains({
        setDomainsRequestBody: {
          domains: [...sanitizedDomains],
        },
      });

      // Map this back into the saml config structure
      return {
        enabled: true,
        saml_settings: { ...settings, domains: saml_domains },
      };
    },
    {
      setError: (name, err, options) => {
        // If we get an `domains.$idx` error, just map it domains as our list editor doesn't show errors at array indexes
        if (name.split(".")[0] === "domains") {
          formMethods.setError(name.split(".")[0] as "domains", err, options);
        } else {
          formMethods.setError(name, err, options);
        }
      },
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal
      analyticsTrackingId="configure-saml-domains-modal"
      title={"Configure SAML domains"}
      onClose={onClose}
      onSubmit={onSubmit}
      formMethods={formMethods}
      saving={saving}
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Save"
        />
      }
    >
      <ListEditorV2
        formMethods={formMethods}
        name="domains"
        helptext={"Configure the domains that can be used to log in with SAML."}
        allowEdit={true}
        getDefaultRow={() => ({
          is_new: true,
          value: "",
          sort_key: 100000,
          id: uuidv4(),
        })}
        rowPlaceholder={"e.g. dunder-mifflin.com"}
        addNewText="Add another domain"
        onClearErrors={() => formMethods.clearErrors("domains")}
        inputType={InputType.Text}
        allowReordering={false}
      />
    </Form.Modal>
  );
};

const SamlEnabledBanner = ({ settings }: { settings: SAMLSettings }) => {
  if (!settings.has_connection || settings.connection_state !== "active") {
    return (
      <Callout theme={CalloutTheme.Warning}>
        <div className="space-y-2">
          <p>You&apos;ve not finished connecting your SAML yet</p>
        </div>
      </Callout>
    );
  }

  const domainToText = ({ domain, id }) => <strong id={id}>{domain}</strong>;
  let domains: React.ReactElement | null = null;
  if (settings.domains.length >= 4) {
    const [first, second, ...others] = settings.domains;
    domains = (
      <>
        {domainToText(first)}, {domainToText(second)}, and{" "}
        <strong>{others.length} others</strong>
      </>
    );
  } else {
    const spanDomains = settings.domains.map(domainToText);
    domains = joinSpansWithCommasAndConnectorWord(spanDomains);
  }

  return (
    <Callout theme={CalloutTheme.Success}>
      <div className="space-y-2">
        <p>
          You&apos;re connected to SAML via {settings.connection_type} for all
          emails matching {domains}
        </p>
        <p>
          Anyone who has permission to configure SAML will still be allowed to
          sign in with Slack, in case they need to reconfigure or disable SAML.
          Other users will automatically be signed in with SAML if they try to
          sign in with Slack.
        </p>
      </div>
    </Callout>
  );
};

const SamlUpsellBanner = (props: { showBadge: boolean }) => {
  return (
    <UpsellNotice
      analyticsId={"saml-upsell-banner"}
      title={heading}
      planName={props.showBadge ? "Pro" : undefined}
      description={
        "Allow users to login through your organisation's SAML identity provider, such as Okta or Google SAML."
      }
      articleId={6991102}
    />
  );
};
