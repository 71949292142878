import React from "react";
import { Route, Routes } from "react-router";

import { AIPage } from "./AIPage";

export const AIRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<AIPage />} />
    </Routes>
  );
};
