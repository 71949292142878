/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface IncidentLifecyclesCreateStatusResponseBody
 */
export interface IncidentLifecyclesCreateStatusResponseBody {
    /**
     * 
     * @type {IncidentStatus}
     * @memberof IncidentLifecyclesCreateStatusResponseBody
     */
    incident_status: IncidentStatus;
}

export function IncidentLifecyclesCreateStatusResponseBodyFromJSON(json: any): IncidentLifecyclesCreateStatusResponseBody {
    return IncidentLifecyclesCreateStatusResponseBodyFromJSONTyped(json, false);
}

export function IncidentLifecyclesCreateStatusResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecyclesCreateStatusResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_status': IncidentStatusFromJSON(json['incident_status']),
    };
}

export function IncidentLifecyclesCreateStatusResponseBodyToJSON(value?: IncidentLifecyclesCreateStatusResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_status': IncidentStatusToJSON(value.incident_status),
    };
}

