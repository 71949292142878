export const NoImpactIcon = ({ className }: { className?: string }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.63422 2.19162C9.17333 1.27158 7.86032 1.27138 7.39915 2.19121L7.39914 2.19122L2.13614 12.6882L1.46569 12.3521L2.13614 12.6882C1.7194 13.5194 2.32373 14.4985 3.25356 14.4985H13.7748C14.7044 14.4985 15.3087 13.5198 14.8924 12.6887L14.8924 12.6887L9.63423 2.19163L9.63422 2.19162ZM6.05824 1.51891C7.07287 -0.504787 9.96145 -0.504211 10.9754 1.5198L10.9754 1.51981L16.2336 12.0169L16.2336 12.0169C17.1495 13.8454 15.82 15.9985 13.7748 15.9985H3.25356C1.20794 15.9985 -0.121611 13.8446 0.795244 12.0159L6.05824 1.51892L6.05824 1.51891ZM8.51342 4.99848C9.06571 4.99848 9.51342 5.4462 9.51342 5.99848V7.99848C9.51342 8.55077 9.06571 8.99848 8.51342 8.99848C7.96114 8.99848 7.51342 8.55077 7.51342 7.99848V5.99848C7.51342 5.4462 7.96114 4.99848 8.51342 4.99848ZM9.51342 11.9985C9.51342 12.5508 9.06571 12.9985 8.51342 12.9985C7.96114 12.9985 7.51342 12.5508 7.51342 11.9985C7.51342 11.4462 7.96114 10.9985 8.51342 10.9985C9.06571 10.9985 9.51342 11.4462 9.51342 11.9985Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.63422 2.19162C9.17333 1.27158 7.86032 1.27138 7.39915 2.19121L7.39914 2.19122L2.13614 12.6882L1.46569 12.3521L2.13614 12.6882C1.7194 13.5194 2.32373 14.4985 3.25356 14.4985H13.7748C14.7044 14.4985 15.3087 13.5198 14.8924 12.6887L14.8924 12.6887L9.63423 2.19163L9.63422 2.19162ZM6.05824 1.51891C7.07287 -0.504787 9.96145 -0.504211 10.9754 1.5198L10.9754 1.51981L16.2336 12.0169L16.2336 12.0169C17.1495 13.8454 15.82 15.9985 13.7748 15.9985H3.25356C1.20794 15.9985 -0.121611 13.8446 0.795244 12.0159L6.05824 1.51892L6.05824 1.51891ZM8.51342 4.99848C9.06571 4.99848 9.51342 5.4462 9.51342 5.99848V7.99848C9.51342 8.55077 9.06571 8.99848 8.51342 8.99848C7.96114 8.99848 7.51342 8.55077 7.51342 7.99848V5.99848C7.51342 5.4462 7.96114 4.99848 8.51342 4.99848ZM9.51342 11.9985C9.51342 12.5508 9.06571 12.9985 8.51342 12.9985C7.96114 12.9985 7.51342 12.5508 7.51342 11.9985C7.51342 11.4462 7.96114 10.9985 8.51342 10.9985C9.06571 10.9985 9.51342 11.4462 9.51342 11.9985Z"
      fill="currentColor"
    />
  </svg>
);
