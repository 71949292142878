/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogConfigType
 */
export interface CatalogConfigType {
    /**
     * ID of the catalog type
     * @type {string}
     * @memberof CatalogConfigType
     */
    catalog_type_id: string;
    /**
     * Description of the catalog type
     * @type {string}
     * @memberof CatalogConfigType
     */
    description: string;
    /**
     * The way this resource type is referenced in the engine
     * @type {string}
     * @memberof CatalogConfigType
     */
    engine_type_name: string;
    /**
     * Name of the catalog type
     * @type {string}
     * @memberof CatalogConfigType
     */
    name: string;
    /**
     * The type name of this catalog type, to be used when defining attributes.
     * @type {string}
     * @memberof CatalogConfigType
     */
    type_name: string;
}

export function CatalogConfigTypeFromJSON(json: any): CatalogConfigType {
    return CatalogConfigTypeFromJSONTyped(json, false);
}

export function CatalogConfigTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogConfigType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_id': json['catalog_type_id'],
        'description': json['description'],
        'engine_type_name': json['engine_type_name'],
        'name': json['name'],
        'type_name': json['type_name'],
    };
}

export function CatalogConfigTypeToJSON(value?: CatalogConfigType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_id': value.catalog_type_id,
        'description': value.description,
        'engine_type_name': value.engine_type_name,
        'name': value.name,
        'type_name': value.type_name,
    };
}

