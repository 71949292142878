import type { SVGProps } from "react";
import * as React from "react";
const SvgLoader = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 6a.75.75 0 0 1-.75-.75v-2.5a.75.75 0 0 1 1.5 0v2.5A.75.75 0 0 1 10 6ZM13.359 7.391a.75.75 0 0 1-.53-1.281l1.768-1.768a.75.75 0 1 1 1.061 1.061L13.89 7.171a.75.75 0 0 1-.531.22ZM17.25 10.75h-2.5a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 1 0 1.5ZM15.126 15.876a.744.744 0 0 1-.53-.22l-1.768-1.768a.75.75 0 1 1 1.061-1.061l1.768 1.768a.75.75 0 0 1-.531 1.281ZM10 18a.75.75 0 0 1-.75-.75v-2.5a.75.75 0 0 1 1.5 0v2.5A.75.75 0 0 1 10 18ZM4.874 15.876a.75.75 0 0 1-.53-1.281l1.768-1.768a.75.75 0 1 1 1.061 1.061l-1.768 1.768a.75.75 0 0 1-.531.22ZM5.25 10.75h-2.5a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 1 0 1.5ZM6.641 7.391a.744.744 0 0 1-.53-.22L4.343 5.403a.75.75 0 1 1 1.061-1.061L7.172 6.11a.75.75 0 0 1-.53 1.281h-.001Z"
    />
  </svg>
);
export default SvgLoader;
