import { formatLoginWithRedirectURL } from "@incident-shared/utils/loginWithRedirect";
import { Heading, IconEnum } from "@incident-ui";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import React from "react";
import { useNavigate } from "react-router";
import { AtlassianConnectAppInstallationInfoProductTypeEnum as AtlassianProductType } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

export const OrganisationSwitcher = ({
  productInstalled,
  switcherURL,
}: {
  productInstalled: string;
  switcherURL: string;
}): React.ReactElement => {
  const { data: sessionsData } = useAPI("identitySessionsList", undefined);
  const orgs = sessionsData
    ? sessionsData.sessions.sort((a, b) => {
        return a.organisation_name.localeCompare(b.organisation_name);
      })
    : [];
  let options: SelectOption[] = orgs.map((org) => ({
    label: org.organisation_name,
    value: org.organisation_slug,
  }));
  options = options.concat({
    label: "Add organisation",
    value: "addOrganisation",
    icon: IconEnum.Add,
  });
  const { identity } = useIdentity();
  const navigate = useNavigate();

  const handleOnChange = (orgSlug: string | null | undefined) => {
    if (!orgSlug) {
      return;
    }

    const returnPath = `/${switcherURL}`;
    switch (orgSlug) {
      case identity.organisation_slug:
        // We are already on the correct page
        return;
      case "addOrganisation":
        // Let's redirect to the login page
        const loginURL = formatLoginWithRedirectURL({
          returnPath: returnPath,
          loginURL: "/login/additional-organisation",
        });
        navigate(loginURL);
        return;
      default:
        // Let's redirect to the correct org
        navigate(`/${orgSlug}${returnPath}`);
        return;
    }
  };

  return (
    <Heading level={2} size="2xl" className="mb-2 w-full">
      <div className="flex flex-row justify-center items-center gap-1">
        <p className="flex min-w-[160px]">
          Connect{" "}
          {productInstalled === AtlassianProductType.Jira
            ? "Jira"
            : "Confluence"}{" "}
          to{" "}
        </p>
        {sessionsData && identity ? (
          <StaticSingleSelect
            placeholder={"Choose an organisation"}
            options={options}
            value={identity.organisation_slug}
            onChange={(value) => handleOnChange(value)}
            className="max-w-[200px]"
          />
        ) : (
          <span>{identity?.organisation_name}</span>
        )}
      </div>
    </Heading>
  );
};
