import type { SVGProps } from "react";
import * as React from "react";
const SvgKey = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.247 3.748a.745.745 0 0 0-.237-.545.767.767 0 0 0-.56-.202l-2.847.177a.75.75 0 0 0-.484.218l-5.599 5.6a4.25 4.25 0 0 0-.77-.073A4.756 4.756 0 0 0 2 13.673a4.756 4.756 0 0 0 4.75 4.75 4.756 4.756 0 0 0 4.75-4.75 4.23 4.23 0 0 0-.081-.811l1.619-1.667a.749.749 0 0 0 .212-.522v-1.5h1.5c.19 0 .374-.073.513-.203l1.753-1.645a.754.754 0 0 0 .237-.548l-.006-3.03ZM6.25 15.173a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
    />
  </svg>
);
export default SvgKey;
