import type { SVGProps } from "react";
import * as React from "react";
const SvgUnorderedList = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.25 8.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM4.25 16a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM16.75 7h-7.5a.75.75 0 0 1 0-1.5h7.5a.75.75 0 0 1 0 1.5ZM16.75 14.5h-7.5a.75.75 0 0 1 0-1.5h7.5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgUnorderedList;
