/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecommendedAttributeResource
 */
export interface RecommendedAttributeResource {
    /**
     * The integration required to use this resource
     * @type {string}
     * @memberof RecommendedAttributeResource
     */
    required_integration: string;
    /**
     * The type of the resource
     * @type {string}
     * @memberof RecommendedAttributeResource
     */
    resource_type: string;
    /**
     * The human-readable name of the resource
     * @type {string}
     * @memberof RecommendedAttributeResource
     */
    resource_type_label: string;
}

export function RecommendedAttributeResourceFromJSON(json: any): RecommendedAttributeResource {
    return RecommendedAttributeResourceFromJSONTyped(json, false);
}

export function RecommendedAttributeResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendedAttributeResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'required_integration': json['required_integration'],
        'resource_type': json['resource_type'],
        'resource_type_label': json['resource_type_label'],
    };
}

export function RecommendedAttributeResourceToJSON(value?: RecommendedAttributeResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'required_integration': value.required_integration,
        'resource_type': value.resource_type,
        'resource_type_label': value.resource_type_label,
    };
}

