import type { SVGProps } from "react";
import * as React from "react";
const SvgCode = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.23 3.925a.75.75 0 1 0-1.46-.35l-3 12.5a.75.75 0 1 0 1.46.35l3-12.5ZM6.78 5.97a.75.75 0 0 1 0 1.06L3.81 10l2.97 2.97a.75.75 0 1 1-1.06 1.06l-3.5-3.5a.75.75 0 0 1 0-1.06l3.5-3.5a.75.75 0 0 1 1.06 0Zm6.44 0a.75.75 0 0 1 1.06 0l3.5 3.5a.75.75 0 0 1 0 1.06l-3.5 3.5a.75.75 0 1 1-1.06-1.06L16.19 10l-2.97-2.97a.75.75 0 0 1 0-1.06Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCode;
