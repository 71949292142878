/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingsUpdateStoreIncidentChannelMessagesRequestBody
 */
export interface SettingsUpdateStoreIncidentChannelMessagesRequestBody {
    /**
     * Which messages in incident channels should be stored in incident.io?
     * @type {string}
     * @memberof SettingsUpdateStoreIncidentChannelMessagesRequestBody
     */
    store_incident_channel_messages: SettingsUpdateStoreIncidentChannelMessagesRequestBodyStoreIncidentChannelMessagesEnum;
}

/**
* @export
* @enum {string}
*/
export enum SettingsUpdateStoreIncidentChannelMessagesRequestBodyStoreIncidentChannelMessagesEnum {
    Never = 'never',
    TimelineOnly = 'timeline_only',
    All = 'all'
}

export function SettingsUpdateStoreIncidentChannelMessagesRequestBodyFromJSON(json: any): SettingsUpdateStoreIncidentChannelMessagesRequestBody {
    return SettingsUpdateStoreIncidentChannelMessagesRequestBodyFromJSONTyped(json, false);
}

export function SettingsUpdateStoreIncidentChannelMessagesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdateStoreIncidentChannelMessagesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'store_incident_channel_messages': json['store_incident_channel_messages'],
    };
}

export function SettingsUpdateStoreIncidentChannelMessagesRequestBodyToJSON(value?: SettingsUpdateStoreIncidentChannelMessagesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'store_incident_channel_messages': value.store_incident_channel_messages,
    };
}

