import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, ConfirmationDialog } from "@incident-ui";
import { useState } from "react";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { getProductAccess } from "src/hooks/useProductAccess";
import { useAPIMutation } from "src/utils/swr";

export const DisableOnCallButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.TeardownOnCallForOrganisation,
  );

  const { hasOnCall } = getProductAccess(data);
  if (!hasOnCall) {
    disabledProps = {
      disabled: true,
      disabledTooltipContent: "Org does not have On-call enabled",
    };
  }

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        theme={hasOnCall ? ButtonTheme.Destroy : ButtonTheme.Secondary}
        {...disabledProps}
      >
        📟 Remove On-call configuration
      </GatedButton>

      {isOpen && (
        <DisableOnCallModal data={data} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

const DisableOnCallModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    { organisationSlug: data.organisation_slug },
    async (apiClient) => {
      await apiClient.staffArchiveOnCallForOrganisation({
        organisationSlug: data.organisation_slug,
      });
    },
    { onSuccess: onClose },
  );

  return (
    <ConfirmationDialog
      isOpen
      analyticsTrackingId={null}
      title="Remove On-call configuration"
      onCancel={onClose}
      onConfirm={() => trigger({})}
      confirmButtonText="Do it"
      saving={isMutating}
      className="text-sm space-y-2"
    >
      {genericError && <Form.ErrorMessage message={genericError} />}
      <p>This will remove all on-call configuration for this organisation.</p>
      <ul className="space-y-1 list-disc pl-3">
        <li>
          Any <strong>alert routes</strong> that escalate to someone will have
          that toggle disabled
        </li>
        <li>
          All of the organisation&apos;s <strong>schedules</strong> will be
          deleted
        </li>
        <li>
          All of the organisation&apos;s <strong>escalation paths</strong> will
          be deleted
        </li>
        <li>
          All of the organisation&apos;s <strong>on-call users</strong> will be
          downgraded to regular responders
        </li>
      </ul>
    </ConfirmationDialog>
  );
};
