import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
} from "@incident-ui";
import {
  PopoverSelectOption,
  SelectOptionWithGroup,
} from "@incident-ui/PopoverSelect";
import { SelectOption } from "@incident-ui/Select/types";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  IncidentLifecycle,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentTimestamp,
  IncidentTimestampSetByRulePayload,
  IncidentTimestampSetByRulePayloadSetOnTransitionEnum as PayloadTransitionEnum,
  IncidentTimestampSetByRulePayloadSetOnVisitEnum as PayloadVisitEnum,
  IncidentTimestampSetOnTransitionEnum,
  IncidentTimestampSetOnVisitEnum,
  IncidentTimestampTimestampTypeEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { flowsInUseForLifecycle } from "../../post-incident-flow/PostIncidentFlowsList";
import { isEditableCategory } from "../overview/LifecycleOverview";

export enum SetByEnum {
  Manual = "manual",
  OnCreation = "on_creation",
  FromTransition = "from_transition",
}

type SetByFormType = {
  set_by_rules?: IncidentTimestampSetByRulePayload[];
  set_on_creation: boolean;
  set_by_mode: SetByEnum;
};

export const marshallRequestPayloadSetByConfig = <
  FormType extends SetByFormType,
>(
  formState: FormType,
): FormType => {
  return {
    ...formState,
    set_on_creation: !!(formState.set_by_mode === SetByEnum.OnCreation),
    set_by_rules:
      formState.set_by_mode === SetByEnum.FromTransition
        ? formState.set_by_rules
        : undefined,
  };
};

export const marshallResponseBodyPayloadSetByConfig = (
  responseBody: IncidentTimestamp,
): SetByFormType => {
  const hasSetByRules =
    responseBody.set_by_rules && responseBody.set_by_rules.length > 0;

  const rules: Partial<IncidentTimestampSetByRulePayload>[] = hasSetByRules
    ? responseBody.set_by_rules?.map((rule) => ({
        id: rule.id,
        set_on_status_id: rule.set_on_status?.id,
        set_on_transition:
          rule.set_on_transition as unknown as PayloadTransitionEnum,
        set_on_visit: rule.set_on_visit as unknown as PayloadVisitEnum,
      }))
    : // Always put a single empty rule in the form state, so that we render the rule UI if you pick 'automatically set' in the radio buttons
      [{}];

  return {
    ...responseBody,
    set_by_mode: responseBody.set_on_creation
      ? SetByEnum.OnCreation
      : hasSetByRules
      ? SetByEnum.FromTransition
      : SetByEnum.Manual,
    set_by_rules: rules,
  } as SetByFormType;
};

export const VISIT_OPTIONS: SelectOption[] = [
  {
    value: IncidentTimestampSetOnVisitEnum.First,
    label: "first",
    sort_key: "1",
  },
  {
    value: IncidentTimestampSetOnVisitEnum.Last,
    label: "last",
    sort_key: "2",
  },
];

const TRANSITION_OPTIONS: SelectOption[] = [
  {
    value: IncidentTimestampSetOnTransitionEnum.Enter,
    label: "enters",
    sort_key: "1",
  },
  {
    value: IncidentTimestampSetOnTransitionEnum.Leave,
    label: "leaves",
    sort_key: "2",
  },
];

export const TimestampSetSection = ({
  statuses,
  timestampType,
}: {
  statuses: IncidentStatus[];
  timestampType: IncidentTimestampTimestampTypeEnum;
}): React.ReactElement | null => {
  const formMethods = useFormContext<SetByFormType>();

  if (timestampType === IncidentTimestampTimestampTypeEnum.ReportedAt) {
    return (
      <Callout theme={CalloutTheme.Plain}>
        This timestamp will be set when an incident is declared
      </Callout>
    );
  }

  return (
    <div>
      <RadioButtonGroupV2
        formMethods={formMethods}
        label="This timestamp should be set:"
        name="set_by_mode"
        srLabel="Set this timestamp"
        required
        boxed
        options={[
          {
            label: "Manually",
            value: SetByEnum.Manual,
            description: "Responders can set this timestamp manually.",
          },
          {
            label: "Automatically when an incident is declared",
            value: SetByEnum.OnCreation,
            description:
              "We'll default this timestamp to when incident was declared.",
          },
          {
            label: "Automatically based on a status transition",
            value: SetByEnum.FromTransition,
            description:
              "We'll default this timestamp when an incident transitions into or out of a particular status.",
            renderWhenSelectedNode: () => (
              <TimestampSetByRuleSection statuses={statuses} />
            ),
          },
        ]}
      />
    </div>
  );
};

const TimestampSetByRuleSection = ({
  statuses,
}: {
  statuses: IncidentStatus[];
}): React.ReactElement | null => {
  const formMethods = useFormContext<SetByFormType>();
  const { append, fields, remove } = useFieldArray({
    control: formMethods.control,
    name: "set_by_rules",
  });

  const {
    data: { incident_lifecycles: lifecycles },
    isLoading: lifecyclesLoading,
  } = useAPI("incidentLifecyclesList", undefined, {
    fallbackData: { incident_lifecycles: [] },
  });

  if (lifecyclesLoading) {
    return null;
  }

  // Only show the slightly confusing 'add rule' UI if you've got multiple lifecycles configured, otherwise
  // it's just noise.
  const showAddRule = lifecycles.length > 1;

  if (fields.length === 0) {
    append({} as IncidentTimestampSetByRulePayload);
  }

  return (
    <div className="space-y-2">
      {fields.map((_, idx) => (
        <TimestampSetByRuleRow
          key={idx}
          idx={idx}
          onRemoveRule={() => remove(idx)}
          statuses={statuses}
          lifecycles={lifecycles}
        />
      ))}
      {showAddRule ? (
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          // @ts-expect-error this is actually fine, but react-hook-form seems to want a fully-fledged rule (but we want it to be empty)
          onClick={() => append({})}
          icon={IconEnum.Add}
        >
          Add rule
        </Button>
      ) : null}
    </div>
  );
};

const TimestampSetByRuleRow = ({
  idx,
  onRemoveRule,
  statuses,
  lifecycles,
}: {
  idx: number;
  onRemoveRule: () => void;
  statuses: IncidentStatus[];
  lifecycles: IncidentLifecycle[];
}): React.ReactElement => {
  const formMethods = useFormContext<SetByFormType>();

  const isFirstRule = idx === 0;

  const introText = isFirstRule
    ? "Set this timestamp when an incident:"
    : "Or when an incident:";

  const statusOptions: SelectOptionWithGroup<PopoverSelectOption>[] = statuses
    .filter((s) => isEditableCategory(s.category))
    .map((status) => {
      const fudgedLifecycle = fudgeLifecycleForStatus(status, lifecycles);
      return {
        label: status.name,
        value: status.id,
        // This is a bit of a fudge: you can share a single post-incident flow across multiple lifecycles, but
        // we'll just put it into the first relevant 'group' so it's somewhere vaguely sensible.
        group:
          lifecycles.length > 1 && fudgedLifecycle
            ? fudgedLifecycle.name
            : undefined,
        sortKey: sortKeyForStatus(status, lifecycles),
      };
    });

  return (
    <div className="flex items-center text-content-primary text-sm space-x-2 flex-wrap mt-1">
      {introText}
      <PopoverSingleSelectV2
        formMethods={formMethods}
        required
        name={`set_by_rules.${idx}.set_on_visit`}
        options={VISIT_OPTIONS}
        triggerStyle="inline-button"
      />
      <PopoverSingleSelectV2
        formMethods={formMethods}
        required
        name={`set_by_rules.${idx}.set_on_transition`}
        options={TRANSITION_OPTIONS}
        triggerStyle="inline-button"
      />
      <PopoverSingleSelectV2
        formMethods={formMethods}
        required
        triggerStyle="inline-button"
        name={`set_by_rules.${idx}.set_on_status_id`}
        options={statusOptions}
      />
      {!isFirstRule && (
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          onClick={() => onRemoveRule()}
        >
          Remove rule
        </Button>
      )}
    </div>
  );
};

const sortKeyForStatus = (
  status: IncidentStatus,
  lifecycles: IncidentLifecycle[],
): string => {
  // Sort key is made up of:
  // 1. What's the lifecycle index, so they are in the right order
  // 2. Whats the category, so active comes before post-incident, which comes before closed
  // 3. What's the status rank

  const lifecycleIdx = lifecycles.findIndex(
    (l) => l.id === fudgeLifecycleForStatus(status, lifecycles)?.id,
  );

  const categoryRank =
    status.category === IncidentStatusCategoryEnum.Active
      ? "1"
      : status.category === IncidentStatusCategoryEnum.PostIncident
      ? "2"
      : "3";

  return `${lifecycleIdx.toString().padStart(2)}-${categoryRank}-${status.rank
    .toString()
    .padStart(4)}`;
};

const fudgeLifecycleForStatus = (
  status: IncidentStatus,
  lifecycles: IncidentLifecycle[],
): IncidentLifecycle | undefined => {
  if (status.incident_lifecycle_id || status.post_incident_flow_id) {
    return lifecycles.find((l) => {
      const flowsForLifecycle = flowsInUseForLifecycle(l);
      // Find the lifecycle that this status belongs to, or a lifecycle that uses the post-incident
      // flow that this status belongs to.
      return (
        l.id === status.incident_lifecycle_id ||
        (status.post_incident_flow_id &&
          flowsForLifecycle.includes(status.post_incident_flow_id))
      );
    });
  }

  return lifecycles.find((l) => l.is_default);
};
