import { renderCustomFieldValues } from "@incident-shared/custom-fields/renderCustomFieldValues";
import { IconEnum, Tooltip } from "@incident-ui";
import _, { sortBy } from "lodash";
import React from "react";
import {
  CustomFieldEntry,
  Incident,
  IncidentApplicableFields,
} from "src/contexts/ClientContext";

import { incidentInEditableStatus } from "../helpers";
import { Sidebar } from "./Components";

type Props = {
  onEdit: () => void;
  entries: Array<CustomFieldEntry>;
  incident: Incident;
  applicableFields: IncidentApplicableFields;
};

export function CustomFieldEntries({
  entries,
  onEdit,
  incident,
  applicableFields,
}: Props): React.ReactElement | null {
  const entriesToShow = entries
    // Exclude custom fields that haven't been set
    .filter(({ values }) => !_.isEmpty(values))
    // Exclude custom fields that aren't applicable to this incident, and haven't been manually edited
    .filter(({ custom_field }) => {
      if (applicableFields.custom_fields.includes(custom_field.id)) {
        return true;
      }

      const hasBeenManuallyEdited = incident.manual_edits?.some(
        (edit) => edit.custom_field_id === custom_field.id,
      );

      return hasBeenManuallyEdited;
    });

  if (
    entriesToShow.length === 0 &&
    applicableFields.custom_fields.length === 0
  ) {
    return null;
  }

  return (
    <Sidebar.Section
      title="Custom fields"
      accessory={
        incidentInEditableStatus(incident) ? (
          <Tooltip content="Edit custom fields">
            <Sidebar.TitleAccessoryButton
              onClick={onEdit}
              icon={IconEnum.Edit}
              title=""
              analyticsTrackingId="edit-custom-field"
            />
          </Tooltip>
        ) : undefined
      }
    >
      {entriesToShow.length === 0 ? (
        <Sidebar.EmptyValue>None set</Sidebar.EmptyValue>
      ) : (
        <div className="space-y-2">
          {sortBy(entriesToShow, ({ custom_field }) => custom_field.rank).map(
            ({ custom_field: customField, values }) => {
              return (
                <Sidebar.Entry
                  key={customField.id}
                  label={
                    <Tooltip content={customField.name} align="start">
                      <span>{customField.name}</span>
                    </Tooltip>
                  }
                  allowLabelShrink
                  value={renderCustomFieldValues(customField, values)}
                />
              );
            },
          )}
        </div>
      )}
    </Sidebar.Section>
  );
}
