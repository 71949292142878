/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    EnrichedFollowUp,
    EnrichedFollowUpFromJSON,
    EnrichedFollowUpFromJSONTyped,
    EnrichedFollowUpToJSON,
} from './EnrichedFollowUp';

/**
 * 
 * @export
 * @interface FollowUpCreated
 */
export interface FollowUpCreated {
    /**
     * 
     * @type {Actor}
     * @memberof FollowUpCreated
     */
    creator: Actor;
    /**
     * 
     * @type {EnrichedFollowUp}
     * @memberof FollowUpCreated
     */
    follow_up: EnrichedFollowUp;
}

export function FollowUpCreatedFromJSON(json: any): FollowUpCreated {
    return FollowUpCreatedFromJSONTyped(json, false);
}

export function FollowUpCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpCreated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': ActorFromJSON(json['creator']),
        'follow_up': EnrichedFollowUpFromJSON(json['follow_up']),
    };
}

export function FollowUpCreatedToJSON(value?: FollowUpCreated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': ActorToJSON(value.creator),
        'follow_up': EnrichedFollowUpToJSON(value.follow_up),
    };
}

