import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Icon, IconEnum, SharedToasts, Tooltip } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import {
  AutoArchiveIncidentsSettings,
  Identity,
  ScopeNameEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useSettings } from "src/hooks/useSettings";
import { ScopesNeededReinstallMessage } from "src/routes/legacy/ReinstallSlackRoute";
import { useAPIMutation } from "src/utils/swr";

import { AddToSlackButton } from "../../slack/AddToSlackButton";
import { SettingsSection } from "../SettingsSection";

type FormType = {
  enabled: boolean;
  config?: {
    delay_days: string;
  };
};

// Makes the conversion from the API number of days into a string, which is
// what our form deals in.
const toFormType = (body: AutoArchiveIncidentsSettings): FormType => ({
  enabled: body.enabled,
  config: {
    delay_days: body.config?.delay_days.toString() as string,
  },
});

export const AutomationAutoArchiveIncidentsEditForm =
  (): React.ReactElement | null => {
    const { settings } = useSettings();
    const { identity } = useIdentity();

    if (!settings || !identity) {
      return null;
    }

    return (
      <AutomationAutoArchiveIncidentsEditFormInner
        settings={settings}
        identity={identity}
      />
    );
  };

const AutomationAutoArchiveIncidentsEditFormInner = ({
  settings,
  identity,
}: {
  identity: Identity;
  settings: Settings;
}) => {
  const showToast = useToast();

  const { hasScope } = useIdentity();
  const commsPlatform = usePrimaryCommsPlatform();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const formMethods = useForm<FormType>({
    defaultValues: toFormType(settings.misc.auto_archive_incidents),
  });

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data) => {
      const dataToSubmit: AutoArchiveIncidentsSettings = {
        enabled: data.enabled,
      };
      if (data.config) {
        dataToSubmit.config = {
          delay_days: parseInt(data.config.delay_days),
        };
      }

      const { settings } = await apiClient.settingsUpdateAutoArchiveIncidents({
        updateAutoArchiveIncidentsRequestBody: {
          auto_archive_incidents: dataToSubmit,
        },
      });
      formMethods.reset(toFormType(settings.misc.auto_archive_incidents));
    },
    {
      onSuccess: () => showToast(SharedToasts.SETTINGS_SAVED),
      setError: formMethods.setError,
    },
  );

  const slackInfo = identity.slack_info;
  const missingRequiredScopes =
    slackInfo?.missing_token_scopes.filter(
      ({ scope }) => scope === "channels:join",
    ) ?? [];

  return (
    <SettingsSection
      mutation={mutation}
      formMethods={formMethods}
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      enabledPath="enabled"
      title={
        commsPlatform === CommsPlatform.Slack
          ? "Auto-archive Slack channels"
          : "Auto-delete Teams channels"
      }
      explanation={
        commsPlatform === CommsPlatform.Slack ? (
          <AutoArchiveExplanation />
        ) : (
          <AutoDeleteExplanation />
        )
      }
    >
      {slackInfo && missingRequiredScopes.length > 0 ? (
        <div className="rounded-[6px] bg-blue-surface text-content-primary p-4 text-sm flex">
          <Icon id={IconEnum.Warning} className="mr-2 flex-shrink-0" />
          <div>
            <ScopesNeededReinstallMessage
              feature={"Auto-archiving"}
              missingTokenScopes={missingRequiredScopes}
            />
            <AddToSlackButton
              url={slackInfo.install_url}
              returnPath={"settings/security"}
            />
          </div>
        </div>
      ) : (
        <StaticSingleSelectV2<FormType>
          formMethods={formMethods}
          label={
            commsPlatform === CommsPlatform.Slack
              ? "Auto-archive channels after:"
              : "Auto-delete channels after:"
          }
          required
          name={"config.delay_days"}
          options={[
            {
              label: "1 day",
              sort_key: "1",
              value: "1",
            },
            {
              label: "3 days",
              sort_key: "2",
              value: "3",
            },
            {
              label: "7 days",
              sort_key: "3",
              value: "7",
            },
            {
              label: "14 days",
              sort_key: "4",
              value: "14",
            },
            {
              label: "28 days",
              sort_key: "5",
              value: "28",
            },
          ]}
          placeholder="Select delay..."
          isClearable={false}
          disabled={!canEditSettings}
        />
      )}
    </SettingsSection>
  );
};

const AutoArchiveExplanation = () => {
  return (
    <>
      With this feature enabled, we&apos;ll automatically archive incident Slack
      channels where the incident is closed and we haven&apos;t seen any
      activity within the configured time period.
      <Tooltip
        content={
          <div className="flex flex-col">
            <span>What counts as activity?</span>
            <span>• Posting messages in a channel, including threads</span>
            <span>
              • Updating details like custom fields, timestamps or follow-ups
            </span>
          </div>
        }
      />
    </>
  );
};

const AutoDeleteExplanation = () => {
  // TODO - improve the copy here
  return (
    <>
      With this feature enabled, we&apos;ll automatically delete incident Teams
      channels where the incident is closed and we haven&apos;t seen any
      activity within the configured time period.
      <Tooltip
        content={
          <div className="flex flex-col">
            <span>What counts as activity?</span>
            <span>• Posting messages in a channel, including threads</span>
            <span>
              • Updating details like custom fields, timestamps or follow-ups
            </span>
          </div>
        }
      />{" "}
      Microsoft users may have the permission to restore channels before they
      are permanently deleted.
    </>
  );
};
