import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type AddNewButtonProps = {
  title: string;
  onClick: () => void;
  analyticsTrackingId: string;
  iconOnlyOnMobile?: boolean;
  className?: string;
  disabled?: boolean;
};

export const AddNewButton = React.forwardRef<
  HTMLButtonElement,
  AddNewButtonProps
>(
  (
    {
      title,
      onClick,
      analyticsTrackingId,
      iconOnlyOnMobile = true,
      className,
      disabled,
    }: AddNewButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Button
        analyticsTrackingId={analyticsTrackingId}
        onClick={onClick}
        className={tcx("ml-auto !gap-0.5", className)}
        theme={ButtonTheme.Naked}
        icon={IconEnum.Add}
        ref={ref}
        iconProps={{ size: IconSize.Medium }}
        disabled={disabled}
      >
        <div className={iconOnlyOnMobile ? "mobile-hidden" : ""}>{title}</div>
      </Button>
    );
  },
);

AddNewButton.displayName = "AddNewButton";
