/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertSourceConfigTransition
 */
export interface AlertSourceConfigTransition {
    /**
     * When this type was created
     * @type {Date}
     * @memberof AlertSourceConfigTransition
     */
    created_at: Date;
    /**
     * Ordering of this transition
     * @type {number}
     * @memberof AlertSourceConfigTransition
     */
    sort_key: number;
    /**
     * The state this transition moved to
     * @type {string}
     * @memberof AlertSourceConfigTransition
     */
    to_state: AlertSourceConfigTransitionToStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum AlertSourceConfigTransitionToStateEnum {
    PendingSetup = 'pending_setup',
    Setup = 'setup'
}

export function AlertSourceConfigTransitionFromJSON(json: any): AlertSourceConfigTransition {
    return AlertSourceConfigTransitionFromJSONTyped(json, false);
}

export function AlertSourceConfigTransitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceConfigTransition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'sort_key': json['sort_key'],
        'to_state': json['to_state'],
    };
}

export function AlertSourceConfigTransitionToJSON(value?: AlertSourceConfigTransition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'sort_key': value.sort_key,
        'to_state': value.to_state,
    };
}

