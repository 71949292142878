/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntryMatchEmbeddedEntry,
    CatalogEntryMatchEmbeddedEntryFromJSON,
    CatalogEntryMatchEmbeddedEntryFromJSONTyped,
    CatalogEntryMatchEmbeddedEntryToJSON,
} from './CatalogEntryMatchEmbeddedEntry';
import {
    CustomFieldOption,
    CustomFieldOptionFromJSON,
    CustomFieldOptionFromJSONTyped,
    CustomFieldOptionToJSON,
} from './CustomFieldOption';

/**
 * 
 * @export
 * @interface CatalogEntryMatch
 */
export interface CatalogEntryMatch {
    /**
     * 
     * @type {CatalogEntryMatchEmbeddedEntry}
     * @memberof CatalogEntryMatch
     */
    catalog_entry?: CatalogEntryMatchEmbeddedEntry;
    /**
     * 
     * @type {CustomFieldOption}
     * @memberof CatalogEntryMatch
     */
    custom_field_option: CustomFieldOption;
}

export function CatalogEntryMatchFromJSON(json: any): CatalogEntryMatch {
    return CatalogEntryMatchFromJSONTyped(json, false);
}

export function CatalogEntryMatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogEntryMatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry': !exists(json, 'catalog_entry') ? undefined : CatalogEntryMatchEmbeddedEntryFromJSON(json['catalog_entry']),
        'custom_field_option': CustomFieldOptionFromJSON(json['custom_field_option']),
    };
}

export function CatalogEntryMatchToJSON(value?: CatalogEntryMatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry': CatalogEntryMatchEmbeddedEntryToJSON(value.catalog_entry),
        'custom_field_option': CustomFieldOptionToJSON(value.custom_field_option),
    };
}

