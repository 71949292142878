/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BranchesOnlyExpression,
    BranchesOnlyExpressionFromJSON,
    BranchesOnlyExpressionFromJSONTyped,
    BranchesOnlyExpressionToJSON,
} from './BranchesOnlyExpression';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface IncidentLifecycle
 */
export interface IncidentLifecycle {
    /**
     * The active statuses in this lifecycle
     * @type {Array<IncidentStatus>}
     * @memberof IncidentLifecycle
     */
    active_statuses: Array<IncidentStatus>;
    /**
     * 
     * @type {BranchesOnlyExpression}
     * @memberof IncidentLifecycle
     */
    automatic_post_incident_flow_expression?: BranchesOnlyExpression;
    /**
     * Unique ID of this incident lifecycle
     * @type {string}
     * @memberof IncidentLifecycle
     */
    id: string;
    /**
     * Is this the default lifecycle for this organisation?
     * @type {boolean}
     * @memberof IncidentLifecycle
     */
    is_default: boolean;
    /**
     * Unique name of this lifecycle
     * @type {string}
     * @memberof IncidentLifecycle
     */
    name: string;
    /**
     * Is the post-incident flow enabled for this lifecycle?
     * @type {boolean}
     * @memberof IncidentLifecycle
     */
    post_incident_flow_enabled: boolean;
}

export function IncidentLifecycleFromJSON(json: any): IncidentLifecycle {
    return IncidentLifecycleFromJSONTyped(json, false);
}

export function IncidentLifecycleFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecycle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active_statuses': ((json['active_statuses'] as Array<any>).map(IncidentStatusFromJSON)),
        'automatic_post_incident_flow_expression': !exists(json, 'automatic_post_incident_flow_expression') ? undefined : BranchesOnlyExpressionFromJSON(json['automatic_post_incident_flow_expression']),
        'id': json['id'],
        'is_default': json['is_default'],
        'name': json['name'],
        'post_incident_flow_enabled': json['post_incident_flow_enabled'],
    };
}

export function IncidentLifecycleToJSON(value?: IncidentLifecycle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_statuses': ((value.active_statuses as Array<any>).map(IncidentStatusToJSON)),
        'automatic_post_incident_flow_expression': BranchesOnlyExpressionToJSON(value.automatic_post_incident_flow_expression),
        'id': value.id,
        'is_default': value.is_default,
        'name': value.name,
        'post_incident_flow_enabled': value.post_incident_flow_enabled,
    };
}

