/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody
 */
export interface PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody {
    /**
     * Whether to sync followups from external postmortems
     * @type {boolean}
     * @memberof PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody
     */
    postmortem_followup_sync?: boolean;
}

export function PostmortemsSettingsTogglePostmortemFollowupSyncRequestBodyFromJSON(json: any): PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody {
    return PostmortemsSettingsTogglePostmortemFollowupSyncRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsSettingsTogglePostmortemFollowupSyncRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postmortem_followup_sync': !exists(json, 'postmortem_followup_sync') ? undefined : json['postmortem_followup_sync'],
    };
}

export function PostmortemsSettingsTogglePostmortemFollowupSyncRequestBodyToJSON(value?: PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postmortem_followup_sync': value.postmortem_followup_sync,
    };
}

