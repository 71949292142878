/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Identity,
    IdentityFromJSON,
    IdentityFromJSONTyped,
    IdentityToJSON,
} from './Identity';

/**
 * 
 * @export
 * @interface IdentitySelfResponseBody
 */
export interface IdentitySelfResponseBody {
    /**
     * 
     * @type {Identity}
     * @memberof IdentitySelfResponseBody
     */
    identity: Identity;
}

export function IdentitySelfResponseBodyFromJSON(json: any): IdentitySelfResponseBody {
    return IdentitySelfResponseBodyFromJSONTyped(json, false);
}

export function IdentitySelfResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentitySelfResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identity': IdentityFromJSON(json['identity']),
    };
}

export function IdentitySelfResponseBodyToJSON(value?: IdentitySelfResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identity': IdentityToJSON(value.identity),
    };
}

