import { Mode } from "@incident-shared/forms/v2/formsv2";
import { CreateEditFormProps } from "@incident-shared/forms/v2/formsv2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  SeveritiesUpdateRequestBody,
  Severity,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

type FormData = SeveritiesUpdateRequestBody & {
  // Typescript can't handle the TextNode type
  description: {
    text_node: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  };
};
export const SeverityCreateEditModal = ({
  mode,
  initialData,
}: CreateEditFormProps<Severity>): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<FormData>({
    defaultValues: initialData,
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const onClose = () => navigate(`/settings/severities`);
  const { mutate } = useAPI("severitiesShow", { id: initialData?.id || "" });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "severitiesList",
    undefined,
    async (apiClient, data: FormData) => {
      switch (mode) {
        case Mode.Create:
          await apiClient.severitiesCreate({
            createRequestBody: data,
          });
          break;

        case Mode.Edit:
          if (!initialData) {
            throw new Error(
              "unreachable: form is in edit mode, but no severity was provided",
            );
          }
          {
            const res = await apiClient.severitiesUpdate({
              id: initialData.id,
              updateRequestBody: data,
            });
            // We mutate the cache for severitiesList with useAPIMutation above, but we also need to update the cache
            // for severitiesShow, which is used by the edit modal. See apps/dashboard/src/utils/swr.md for more info.
            await mutate(res);
          }
          break;
      }
    },
    { onSuccess: onClose, setError },
  );

  return (
    <Form.Modal<FormData>
      analyticsTrackingId={
        mode === Mode.Create ? "create-severity" : "edit-severity"
      }
      title={mode === Mode.Create ? "Create severity" : "Edit severity"}
      disableQuickClose
      onSubmit={onSubmit}
      formMethods={formMethods}
      genericError={genericError}
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={!isDirty}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        label="Name"
        name="name"
        required="Please provide a name"
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        includeVariables={false}
        includeExpressions={false}
        name="description.text_node"
        label="Description"
        helptext="How would you explain this severity to someone?"
        placeholder="What's the likely impact on the business? What kind of customer comms are needed? Which people might need to be involved?"
        format="mrkdwn"
        required
      />
    </Form.Modal>
  );
};
