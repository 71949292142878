/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface IntegrationsZoomHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsZoomHandleRedirectRequest {
    code?: string;
    state?: string;
}

/**
 * 
 */
export class IntegrationsZoomApi extends runtime.BaseAPI {

    /**
     * Handle Zoom installation by redirecting to the Zoom install endpoint
     * HandleInstall Integrations - Zoom
     */
    async integrationsZoomHandleInstallRaw(requestParameters: IntegrationsZoomHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsZoomHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zoom_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Zoom installation by redirecting to the Zoom install endpoint
     * HandleInstall Integrations - Zoom
     */
    async integrationsZoomHandleInstall(requestParameters: IntegrationsZoomHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsZoomHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Zoom OAuth redirect
     * HandleRedirect Integrations - Zoom
     */
    async integrationsZoomHandleRedirectRaw(requestParameters: IntegrationsZoomHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/zoom`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Zoom OAuth redirect
     * HandleRedirect Integrations - Zoom
     */
    async integrationsZoomHandleRedirect(requestParameters: IntegrationsZoomHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsZoomHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Zoom uninstallation request by deactivating the token and clearing the credentials
     * HandleUninstall Integrations - Zoom
     */
    async integrationsZoomHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zoom_uninstall`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Zoom uninstallation request by deactivating the token and clearing the credentials
     * HandleUninstall Integrations - Zoom
     */
    async integrationsZoomHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsZoomHandleUninstallRaw(initOverrides);
    }

}
