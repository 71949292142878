/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SAMLSetDomainsRequestBody
 */
export interface SAMLSetDomainsRequestBody {
    /**
     * Which domains are applicable for this organisation's SAML?
     * @type {Array<string>}
     * @memberof SAMLSetDomainsRequestBody
     */
    domains: Array<string>;
}

export function SAMLSetDomainsRequestBodyFromJSON(json: any): SAMLSetDomainsRequestBody {
    return SAMLSetDomainsRequestBodyFromJSONTyped(json, false);
}

export function SAMLSetDomainsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLSetDomainsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domains': json['domains'],
    };
}

export function SAMLSetDomainsRequestBodyToJSON(value?: SAMLSetDomainsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domains': value.domains,
    };
}

