import type { SVGProps } from "react";
import * as React from "react";
const SvgMute = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.338 16.185A.5.5 0 0 0 10.95 16h-2.4a.5.5 0 0 0-.488.603C8.235 17.425 8.93 18 9.75 18c.82 0 1.516-.575 1.689-1.397a.5.5 0 0 0-.102-.418ZM3 15h1.75l9.928-9.928A5.5 5.5 0 0 0 9.75 2a5.506 5.506 0 0 0-5.5 5.5v4.75c0 .689-.561 1.25-1.25 1.25A.75.75 0 0 0 3 15ZM16.5 13.5c-.69 0-1.25-.56-1.25-1.25V7.682L7.932 15H16.5a.75.75 0 0 0 0-1.5Z"
    />
    <path
      fill="currentColor"
      d="M2.75 17.75a.75.75 0 0 1-.53-1.281l14-14a.75.75 0 1 1 1.06 1.062l-14 13.999a.748.748 0 0 1-.53.22Z"
    />
  </svg>
);
export default SvgMute;
