import {
  Severity,
  SeverityAlias,
  TextDocument,
} from "src/contexts/ClientContext";
export type AliasedSeverity = {
  id: string;
  name: string;
  description: TextDocument;
  rank: number;
  bucketed_rank: number;
  hidden: boolean;
};

export function aliasSeverities(
  severities: Severity[],
  incidentTypeId?: string,
): AliasedSeverity[] {
  return severities.map((sev) => applyAlias(sev, incidentTypeId));
}

export function applyAlias(
  severity: Severity,
  incidentTypeId?: string,
): AliasedSeverity {
  const alias = getSeverityAlias(severity, incidentTypeId);

  return {
    id: severity.id,
    name: severity.name,
    description: alias.override_description || severity.description,
    rank: severity.rank,
    bucketed_rank: severity.bucketed_rank,
    hidden: alias.hidden,
  };
}

const getSeverityAlias = (
  sev: Severity,
  incidentTypeId?: string,
): SeverityAlias => {
  return (
    sev.aliases.find((alias) => alias.incident_type_id === incidentTypeId) || {
      severity_id: sev.id,
      incident_type_id: incidentTypeId || "",
      hidden: false,
    }
  );
};
