/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IssueTrackerIssueTemplatesListResponseBody,
    IssueTrackerIssueTemplatesListResponseBodyFromJSON,
    IssueTrackerIssueTemplatesListResponseBodyToJSON,
} from '../models';

export interface IssueTrackerIssueTemplatesDestroyRequest {
    id: string;
}

/**
 * 
 */
export class IssueTrackerIssueTemplatesApi extends runtime.BaseAPI {

    /**
     * Removes an existing issue tracker issue template
     * Destroy IssueTrackerIssueTemplates
     */
    async issueTrackerIssueTemplatesDestroyRaw(requestParameters: IssueTrackerIssueTemplatesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackerIssueTemplatesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_tracker_issue_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing issue tracker issue template
     * Destroy IssueTrackerIssueTemplates
     */
    async issueTrackerIssueTemplatesDestroy(requestParameters: IssueTrackerIssueTemplatesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.issueTrackerIssueTemplatesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Get all Issue Tracker Issue Templates for the current organisation
     * List IssueTrackerIssueTemplates
     */
    async issueTrackerIssueTemplatesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackerIssueTemplatesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_tracker_issue_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackerIssueTemplatesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get all Issue Tracker Issue Templates for the current organisation
     * List IssueTrackerIssueTemplates
     */
    async issueTrackerIssueTemplatesList(initOverrides?: RequestInit): Promise<IssueTrackerIssueTemplatesListResponseBody> {
        const response = await this.issueTrackerIssueTemplatesListRaw(initOverrides);
        return await response.value();
    }

}
