/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    ExternalResource,
    ExternalResourceFromJSON,
    ExternalResourceFromJSONTyped,
    ExternalResourceToJSON,
} from './ExternalResource';

/**
 * 
 * @export
 * @interface IncidentAttachment
 */
export interface IncidentAttachment {
    /**
     * When the attachment was created
     * @type {Date}
     * @memberof IncidentAttachment
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof IncidentAttachment
     */
    creator: Actor;
    /**
     * Unique identifier of this incident membership
     * @type {string}
     * @memberof IncidentAttachment
     */
    id: string;
    /**
     * Unique identifier of the incident
     * @type {string}
     * @memberof IncidentAttachment
     */
    incident_id: string;
    /**
     * Unique identifier of the organisation
     * @type {string}
     * @memberof IncidentAttachment
     */
    organisation_id: string;
    /**
     * 
     * @type {ExternalResource}
     * @memberof IncidentAttachment
     */
    resource: ExternalResource;
}

export function IncidentAttachmentFromJSON(json: any): IncidentAttachment {
    return IncidentAttachmentFromJSONTyped(json, false);
}

export function IncidentAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'creator': ActorFromJSON(json['creator']),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'organisation_id': json['organisation_id'],
        'resource': ExternalResourceFromJSON(json['resource']),
    };
}

export function IncidentAttachmentToJSON(value?: IncidentAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'id': value.id,
        'incident_id': value.incident_id,
        'organisation_id': value.organisation_id,
        'resource': ExternalResourceToJSON(value.resource),
    };
}

