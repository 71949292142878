/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructure,
    StatusPageStructureFromJSON,
    StatusPageStructureFromJSONTyped,
    StatusPageStructureToJSON,
} from './StatusPageStructure';

/**
 * 
 * @export
 * @interface StatusPageCreateStructureResponseBody
 */
export interface StatusPageCreateStructureResponseBody {
    /**
     * 
     * @type {StatusPageStructure}
     * @memberof StatusPageCreateStructureResponseBody
     */
    status_page_structure: StatusPageStructure;
}

export function StatusPageCreateStructureResponseBodyFromJSON(json: any): StatusPageCreateStructureResponseBody {
    return StatusPageCreateStructureResponseBodyFromJSONTyped(json, false);
}

export function StatusPageCreateStructureResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateStructureResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status_page_structure': StatusPageStructureFromJSON(json['status_page_structure']),
    };
}

export function StatusPageCreateStructureResponseBodyToJSON(value?: StatusPageCreateStructureResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_page_structure': StatusPageStructureToJSON(value.status_page_structure),
    };
}

