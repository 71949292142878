/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GitLabConfig,
    GitLabConfigFromJSON,
    GitLabConfigFromJSONTyped,
    GitLabConfigToJSON,
} from './GitLabConfig';

/**
 * 
 * @export
 * @interface IntegrationsGitLabShowResponseBody
 */
export interface IntegrationsGitLabShowResponseBody {
    /**
     * 
     * @type {GitLabConfig}
     * @memberof IntegrationsGitLabShowResponseBody
     */
    git_lab_config?: GitLabConfig;
}

export function IntegrationsGitLabShowResponseBodyFromJSON(json: any): IntegrationsGitLabShowResponseBody {
    return IntegrationsGitLabShowResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsGitLabShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGitLabShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'git_lab_config': !exists(json, 'git_lab_config') ? undefined : GitLabConfigFromJSON(json['git_lab_config']),
    };
}

export function IntegrationsGitLabShowResponseBodyToJSON(value?: IntegrationsGitLabShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'git_lab_config': GitLabConfigToJSON(value.git_lab_config),
    };
}

