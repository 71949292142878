import { Heading, Icon, IconEnum, Link, StackedList } from "@incident-ui";
import { sortBy } from "lodash";
import React from "react";
import {
  StatusPage,
  StatusPagePageTypeEnum,
  StatusPageSubPageSlim,
  StatusPageSubPageSlimWorstComponentStatusEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { customerPageVisitURL } from "../../../../utils/utils";
import { StatusPageComponentStatusIcon } from "../../incidents/view/StatusIcons";

export const SubPagesListPage = ({
  subpages,
  page,
}: {
  subpages: StatusPageSubPageSlim[];
  page: StatusPage;
}): React.ReactElement => {
  const sortedSubpages =
    page.page_type !== StatusPagePageTypeEnum.Customer
      ? subpages
      : sortBy(subpages, (subpage) => {
          const severity = {
            [StatusPageSubPageSlimWorstComponentStatusEnum.Operational]: 4,
            [StatusPageSubPageSlimWorstComponentStatusEnum.UnderMaintenance]: 3,
            [StatusPageSubPageSlimWorstComponentStatusEnum.DegradedPerformance]: 2,
            [StatusPageSubPageSlimWorstComponentStatusEnum.PartialOutage]: 1,
            [StatusPageSubPageSlimWorstComponentStatusEnum.FullOutage]: 0,
          };

          return `${
            severity[subpage.worst_component_status]
          }-${subpage.name.toLowerCase()}`;
        });
  return (
    <>
      <Heading level={3} size="small" className="font-medium mb-2 text-sm">
        {page.page_type === StatusPagePageTypeEnum.Customer
          ? "Customer pages"
          : "Sub-pages"}
      </Heading>

      <StackedList className="text-sm">
        {sortedSubpages?.map((subpage, idx) => {
          return <ComponentRow key={idx} page={page} subpage={subpage} />;
        })}
      </StackedList>
    </>
  );
};

const ComponentRow = ({
  page,
  subpage,
  isSmall = false,
}: {
  page: StatusPage;
  subpage: StatusPageSubPageSlim;
  isSmall?: boolean;
}): React.ReactElement => {
  const { identity } = useIdentity();
  return (
    <Link
      className={tcx(
        "flex items-center justify-between no-underline",
        "hover:!text-content-primary hover:bg-surface-secondary",
        "px-2 space-x-1 w-full",
        isSmall ? "py-1" : "py-3",
      )}
      analyticsTrackingId={null}
      href={
        page.page_type === StatusPagePageTypeEnum.Customer && identity
          ? customerPageVisitURL(page.id, subpage.id, identity.organisation_id)
          : subpage.public_url
      }
      openInNewTab
    >
      <div className="flex items-center gap-1">
        {subpage.worst_component_status && (
          <StatusPageComponentStatusIcon
            status={subpage.worst_component_status}
          />
        )}
        <span className="shrink truncate">{subpage.name}</span>
      </div>

      <Icon id={IconEnum.ExternalLink} />
    </Link>
  );
};
