import { useInsightsServiceInsightsShowWrappedData } from "@incident-io/query-api";
import { useCollapseSidebar } from "@incident-shared/layout/MainSidebar/MainSidebar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { ImgWithLoadingSkeleton } from "@incident-ui/ImgLoadingSkeleton/ImgWithLoadingSkeleton";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { captureException, ErrorBoundary } from "@sentry/react";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact } from "lodash";
import Lottie from "lottie-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useTrackVisibility } from "react-intersection-observer-hook";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { Identity } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useDisableNPS } from "src/contexts/SurvicateContext";
import { tcx } from "src/utils/tailwind-classes";

import emptyStateGraphic from "./empty-2024.svg";
import bornLeaderBg from "./era-bgs/born-leader.png";
import ctrlAltDelegateBg from "./era-bgs/ctrl-alt-delegate.png";
import gettingShitDoneBg from "./era-bgs/getting-shit-done.png";
import jackOfAllTradesBg from "./era-bgs/jack-of-all-trades.png";
import kimPossibleBg from "./era-bgs/kim-possible.png";
import nightOwlBg from "./era-bgs/night-owl.png";
import sherlockHolmesBg from "./era-bgs/sherlock-holmes.png";
import superheroBg from "./era-bgs/superhero.png";
import bornLeaderIcon from "./era-icons/born-leader.svg";
import ctrlAltDelegateIcon from "./era-icons/ctrl-alt-delegate.svg";
import gettingShitDoneIcon from "./era-icons/getting-shit-done.svg";
import jackOfAllTradesIcon from "./era-icons/jack-of-all-trades.svg";
import kimPossibleIcon from "./era-icons/kim-possible.svg";
import nightOwlIcon from "./era-icons/night-owl.svg";
import sherlockHolmesIcon from "./era-icons/sherlock-holmes.svg";
import superheroIcon from "./era-icons/superhero.svg";

enum EraSlug {
  Superhero = "superhero",
  NightOwl = "night-owl",
  KimPossible = "kim-possible",
  SherlockHolmes = "sherlock-holmes",
  BornLeader = "born-leader",
  GettingShitDone = "getting-shit-done",
  CtrlAltDelegate = "ctrl-alt-delegate",
  JackOfAllTrades = "jack-of-all-trades",
}

type EraProps = {
  name: string;
  icon: string;
  content: (identity: Identity) => React.ReactElement;
  cardBackground: string;
};

const ERAS: Record<EraSlug, EraProps> = {
  superhero: {
    icon: superheroIcon,
    name: "Superhero",
    cardBackground: superheroBg,
    content: (identity) => (
      <>
        <p>
          You&rsquo;ve handled more incidents than most, making you a superhero
          of incident response at {identity.organisation_name} 🦸‍♂️
        </p>
        <p>
          While everyone enjoys their moment in the spotlight (and you certainly
          earned it!), even the best need a hand now and then. Don&rsquo;t
          hesitate to call for backup!
        </p>
      </>
    ),
  },

  "night-owl": {
    icon: nightOwlIcon,
    name: "Night Owl",
    cardBackground: nightOwlBg,
    content: (_) => (
      <>
        <p>
          You come alive after dark and with you on the team, it&rsquo;s always
          a hoot 🦉
        </p>
        <p>
          Need a little extra rest? Don&rsquo;t hesitate to ask for cover — your
          team&rsquo;s got your back!
        </p>
      </>
    ),
  },

  "kim-possible": {
    icon: kimPossibleIcon,
    name: "Kim Possible",
    cardBackground: kimPossibleBg,
    content: (_) => (
      <>
        <p>*Call me, beep me, ya wanna reach me!* 📟</p>
        <p>
          You&rsquo;ve probably heard your pager go off more often than
          you&rsquo;ve heard your own name this year. Make sure to check out the
          Chainsmokers-produced alert sounds to keep things fresh!
        </p>
      </>
    ),
  },

  "sherlock-holmes": {
    icon: sherlockHolmesIcon,
    name: "Sherlock Holmes",
    cardBackground: sherlockHolmesBg,
    content: (_) => (
      <>
        <p>
          The great detective himself would be wowed by your skills of deduction
          🕵️
        </p>
        <p>
          Given the number of images and attachments you&rsquo;ve added this
          year, you clearly LOVE collecting evidence to help debug even the
          gnarliest of incidents. I&rsquo;m sure your team appreciates your
          single minded quest to uncover the truth!
        </p>
      </>
    ),
  },

  "born-leader": {
    icon: bornLeaderIcon,
    name: "Born Leader",
    cardBackground: bornLeaderBg,
    content: (_) => (
      <>
        <p>When the going gets tough, the tough escalate to you 📟</p>
        <p>
          You can lead any incident — none is too big, or too small and you dive
          into them all head first. Calm in a crisis, everyone feels better
          knowing you&rsquo;re leading the response.
        </p>
      </>
    ),
  },

  "getting-shit-done": {
    icon: gettingShitDoneIcon,
    name: "Getting sh*t done",
    cardBackground: gettingShitDoneBg,
    content: (_) => (
      <>
        <p>
          You don&rsquo;t let a single detail (or follow up!) slip through the
          cracks 🤓
        </p>
        <p>
          You&rsquo;re one of the most organized members of your team,
          consistently using actions and follow-ups to stay on top of things.
          you display some next-level accountability and your team can always
          count on you to get the job done!
        </p>
      </>
    ),
  },

  "ctrl-alt-delegate": {
    icon: ctrlAltDelegateIcon,
    name: "Ctrl + Alt + Delegate",
    cardBackground: ctrlAltDelegateBg,
    content: (_) => (
      <>
        <p>Wow, turns out you really love calling the shots with `/inc` ⌨️</p>
        <p>
          You&rsquo;re an efficiency machine and we bet you know all shortcuts
          for every app you use. You&rsquo;re probably one of those people who
          has shortcuts for everything and I bet you use Raycast
        </p>
      </>
    ),
  },

  "jack-of-all-trades": {
    icon: jackOfAllTradesIcon,
    name: "Jack Of All Trades",
    cardBackground: jackOfAllTradesBg,
    content: (_) => (
      <>
        <p>Whatever needs doing, you&rsquo;re always happy to lend a hand 🤝</p>
        <p>
          Whether you&rsquo;re in the lead, diving into logs, drafting comms or
          taking notes, you&rsquo;re always there to help and your team know
          they can rely on you in a crisis. Keep being awesome.
        </p>
      </>
    ),
  },
};

export const InsightsYearInReviewRoute = (): React.ReactElement => {
  const { isLoading, data } = useInsightsServiceInsightsShowWrappedData();

  const { incidentIoWrapped } = useFlags();
  useDisableNPS();

  if (!incidentIoWrapped) {
    return <NotFoundPage />;
  }

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!data) {
    return <EmptyState />;
  }

  return (
    <PageWrapper
      icon={IconEnum.Calendar}
      width={PageWidth.Full}
      title="Year in review"
      noPadding
      overflowY={false}
      color={ColorPaletteEnum.Purple}
      noHeader
    >
      <div className="p-4 lg:p-8 !pb-0 h-full w-full flex flex-col">
        <div className="bg-gradient-to-b from-purple-100 via-[#feefec] to-white rounded-3 snap-y snap-mandatory overflow-y-auto grow">
          <WrappedContent data={data.data as unknown as WrappedData} />
        </div>
      </div>
    </PageWrapper>
  );
};

const EmptyState = () => {
  // Always collapse the sidebar
  useCollapseSidebar();

  return (
    <PageWrapper
      icon={IconEnum.Calendar}
      width={PageWidth.Full}
      title="Year in review"
      overflowY={false}
      overflowX={false}
    >
      <div className="h-[830px] bg-gradient-to-b from-[#f7f7f8] to-white rounded-lg flex-col justify-start items-center gap-60 inline-flex">
        <div className="w-full grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
          <img src={emptyStateGraphic} />
          <div className="flex-col justify-center items-center gap-2 flex">
            <div className="text-center text-[#161618] text-2xl font-semibold font-['Inter'] leading-loose">
              You haven&rsquo;t been involved in enough incidents this year
            </div>
            <div className="w-[428px] text-center text-[#5f5f68] text-base font-semibold font-['Inter'] leading-normal">
              Still, look on the bright side, that&rsquo;s probably a good thing
              😅
              <br />
              Here&rsquo;s to more things breaking next year!
            </div>
          </div>
          <div className="justify-center items-center gap-2 inline-flex">
            <Button
              theme={ButtonTheme.Primary}
              href="/"
              analyticsTrackingId={null}
            >
              Go to dashboard
            </Button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

type Section = Omit<
  Parameters<typeof WrappedSection>[0],
  "nextSectionId" | "children"
> & {
  Component: React.FC<{ data: WrappedData }>;
};
const WrappedContent = ({ data }: { data: WrappedData }) => {
  // Always collapse the sidebar - we want the big screen!
  useCollapseSidebar();

  const sections: Section[] = compact([
    {
      id: "intro",
      Component: SectionHeader,
      nextSectionButtonDelay: 1,
      noPadding: true,
    },
    { id: "usage-metrics", Component: SectionUsageMetrics },
    data.s_major_incidents__num_major_incidents > 3 && {
      id: "major-incidents",
      Component: SectionMajorIncidents,
    },
    {
      id: "hours-on-incidents",
      Component: SectionHoursOnIncidents,
    },
    {
      id: "overnight",
      Component: SectionOvernight,
    },
    data.s_working_smarter__show_section && {
      id: "working-smarter",
      Component: SectionWorkingSmarter,
    },
    { id: "yap-to-zap", Component: SectionYapToZap },
    data.s_slash_commands__show_section && {
      id: "slash-commands",
      Component: SectionSlashCommands,
    },
    { id: "key-milestones", Component: SectionKeyMilestones },
    { id: "era", Component: SectionEra },
    { id: "share", Component: SectionShare },
  ]);

  const sectionsWithNextId = sections.map((section, index) => {
    const nextSectionId = sections[index + 1]?.id;
    return { ...section, nextSectionId };
  });

  // We want to preload this image
  const shareImageUrl = useShareImageUrl();

  return (
    <>
      {/* For preloading */}
      <img src={shareImageUrl} className="hidden" />

      {sectionsWithNextId.map(
        ({ id, nextSectionId, nextSectionButtonDelay, Component }) => (
          <WrappedSection
            key={id}
            id={id}
            nextSectionId={nextSectionId}
            nextSectionButtonDelay={nextSectionButtonDelay}
          >
            <Component data={data} />
          </WrappedSection>
        ),
      )}
    </>
  );
};

const SectionHeader = (_: { data: WrappedData }) => {
  const { identity } = useIdentity();

  return (
    <>
      <Lottie animationData={require("./header-lottie.json")} loop autoplay />
      <div className="text-5xl-bold text-content-primary mt-16">
        Hey {identity.user_name.split(" ")[0]} 👋
      </div>
      <div className="text-content-secondary text-2xl-bold">
        You&rsquo;ve had an impressive year tackling incidents.
        <br /> Let&rsquo;s take a look at your highlights!
      </div>
    </>
  );
};

const SectionUsageMetrics = ({ data }: { data: WrappedData }) => {
  const { identity } = useIdentity();
  return (
    <>
      <HeadingBadge icon={IconEnum.Chart}>Incredible effort</HeadingBadge>
      <Heading
        title="You’ve been working hard all year"
        subtitle="Take a moment to appreciate everything you’ve done!"
      />
      <WrappedStatsCards
        stats={[
          {
            title: "Incidents joined",
            value: displayInt(
              data.s_usage_metrics__num_joined_incident_channels_ytd,
            ),
          },
          {
            title: "Where you got involved",
            value: displayInt(
              data.s_usage_metrics__num_incidents_worked_on_ytd,
            ),
          },
          {
            title: "Where you took the lead",
            value: displayInt(data.s_usage_metrics__num_incidents_led_ytd),
          },
        ]}
      />
      {(data.s_usage_metrics__show_percentiles || true) && (
        <div className="text-2xl-bold text-content-secondary">
          This puts you in the{" "}
          <Stat>
            top{" "}
            {displayPerc(1 - data.s_usage_metrics__perc_incidents_worked_on)} of{" "}
            {identity.organisation_name}
          </Stat>{" "}
          for number of incidents worked on, and{" "}
          <Stat>
            top {displayPerc(1 - data.s_usage_metrics__perc_incidents_led)}
          </Stat>{" "}
          for number of incidents led. Not too shabby 😏
        </div>
      )}
    </>
  );
};

const SectionMajorIncidents = ({ data }: { data: WrappedData }) => {
  return (
    <>
      <HeadingBadge icon={IconEnum.Chart}>Incredible effort</HeadingBadge>
      <Heading
        title={
          <>
            You&rsquo;ve dealt with{" "}
            <Stat>
              {displayInt(data.s_major_incidents__num_major_incidents)} major
              incidents
            </Stat>{" "}
            this year, no small feat!
          </>
        }
        titleClassName="text-content-secondary"
        subtitle="Congrats on keeping calm through the chaos 🧘"
      />
    </>
  );
};

const SectionHoursOnIncidents = ({ data }: { data: WrappedData }) => {
  const { identity } = useIdentity();

  return (
    <>
      <HeadingBadge icon={IconEnum.Clock}>The hustle never stops</HeadingBadge>
      <Heading
        title={
          <>
            You&rsquo;ve clocked up{" "}
            <Stat>
              {displayInt(data.s_hours_on_incidents__num_hours_ytd)} hours
            </Stat>{" "}
            on incidents this year 😲
          </>
        }
        titleClassName="text-content-secondary"
        subtitle={
          <>
            That&rsquo;s more than{" "}
            <Stat>
              {displayPerc(data.s_hours_on_incidents__perc_incidents_worked_on)}{" "}
              of your team
            </Stat>{" "}
            at {identity.organisation_name}!
          </>
        }
      />
    </>
  );
};

const SectionOvernight = ({ data }: { data: WrappedData }) => {
  return (
    <>
      <HeadingBadge icon={IconEnum.Clock}>The hustle never stops</HeadingBadge>
      <Heading
        title={
          <>
            <Stat>
              {displayPerc(data.s_overnight__perc_outside_working_hours)}
            </Stat>{" "}
            of your incident time was spent outside working hours.
          </>
        }
        titleClassName="text-content-secondary"
        subtitle={
          data.s_overnight__worked_late_nights ? (
            <>
              Looks like you spent a lot of time burning the midnight oil!
              <br /> Hopefully you can get some rest over the holidays 🥂😴
            </>
          ) : (
            <>
              Good news: seems like your nights were mostly drama-free.
              <br /> Hopefully you woke up on the right side of the bed 🛌
            </>
          )
        }
      />
    </>
  );
};

const SectionWorkingSmarter = ({ data }: { data: WrappedData }) => {
  return (
    <>
      <HeadingBadge icon={IconEnum.DecisionFlows}>
        Working smarter (and harder)
      </HeadingBadge>
      <Heading
        title="Where does all that time go?"
        subtitle={
          <>Let&rsquo;s take a look what you&rsquo;ve been up to&hellip;</>
        }
      />
      <WrappedStatsCards
        stats={[
          {
            title: "Messages sent",
            value: displayInt(data.s_working_smarter__num_slack_messages_sent),
          },
          {
            title: "Attachments added",
            value: displayInt(data.s_working_smarter__num_attachments_added),
          },
          {
            title: "Actions created",
            value: displayInt(data.s_working_smarter__num_actions_created),
          },
          {
            title: "Updates shared",
            value: displayInt(data.s_working_smarter__num_incident_updates),
          },
        ].filter((x) => x.value)}
      />
    </>
  );
};

const SectionYapToZap = ({ data }: { data: WrappedData }) => {
  return (
    <>
      <HeadingBadge icon={IconEnum.DecisionFlows}>
        Working smarter (and harder)
      </HeadingBadge>
      <Heading
        title={
          <>
            Actions speak louder than words <br />
            (or do they?)
          </>
        }
      />
      <WrappedStatsCards
        stats={[
          {
            title: "Messages sent",
            value: displayInt(data.s_yapzap__num_slack_messages_sent),
          },
          {
            title: "Actions performed",
            value: displayInt(data.s_yapzap__num_actions_performed),
          },
        ]}
      />
      <div className="text-2xl-bold text-content-primary">
        {data.s_yapzap__persona === "conversationalist"
          ? "You're clearly a fantastic conversationalist 💬"
          : "You're all about the action! ✅"}
      </div>
    </>
  );
};

const SectionSlashCommands = ({ data }: { data: WrappedData }) => {
  return (
    <>
      <HeadingBadge icon={IconEnum.Keyboard}>
        /inc all day, everyday
      </HeadingBadge>
      <div className="text-5xl-bold text-content-secondary">
        Slash commands were a staple part of your diet this year, with a total
        of{" "}
        <Stat>
          {displayInt(data.s_slash_commands__num_slash_commands_used)}
        </Stat>{" "}
        used!
      </div>
      <div className="text-2xl-bold text-content-secondary">
        Let&rsquo;s take a look at your favourites:
      </div>
      <div className="flex flex-col gap-4 lg:flex-row lg:gap-1 lg:items-end font-mono text-content-primary">
        <div
          className={tcx(
            "flex flex-col items-center gap-1 w-[280px] bg-amber-200 py-5 rounded-2",
            // When we have space, make it cute
            "lg:py-10 lg:order-2 lg:rounded-t-2 lg:rounded-b-0",
          )}
        >
          <Icon
            id={IconEnum.Medal1}
            size={IconSize.XL}
            className="text-amber-400"
          />
          <div className="text-2xl-bold">
            {data.s_slash_commands__top_commands_1}
          </div>
        </div>
        <div
          className={tcx(
            "flex flex-col items-center gap-1 w-[280px] bg-slate-100 py-5 rounded-2",
            "lg:py-8 lg:order-1 lg:rounded-r-1",
          )}
        >
          <Icon
            id={IconEnum.Medal2}
            size={IconSize.XL}
            className="text-slate-300"
          />
          <div className="text-2xl-bold">
            {data.s_slash_commands__top_commands_2}
          </div>
        </div>
        <div
          className={tcx(
            "flex flex-col items-center gap-1 w-[280px] bg-[#EED2CC] py-5 rounded-2",
            "lg:order-3 lg:rounded-l-1",
          )}
        >
          <Icon
            id={IconEnum.Medal3}
            size={IconSize.XL}
            className="text-[#C27E6F]"
          />
          <div className="text-2xl-bold">
            {data.s_slash_commands__top_commands_3}
          </div>
        </div>
      </div>
    </>
  );
};

const SectionKeyMilestones = ({ data }: { data: WrappedData }) => {
  return (
    <>
      <HeadingBadge icon={IconEnum.Trophy}>Key milestones</HeadingBadge>
      <Heading
        title="From humble beginnings…"
        subtitle={
          <>
            &hellip;to the battle&ndash;hardened incident responder you are
            today,
            <br />
            let&rsquo;s not forget how it all began.
          </>
        }
      />

      <WrappedStatsCards
        small
        stats={[
          {
            date: data.s_key_milestones__first_incident_joined_at,
            title: "First incident joined",
            value: data.s_key_milestones__first_incident_joined_at
              ? displayDate(data.s_key_milestones__first_incident_joined_at)
              : "",
          },
          {
            date: data.s_key_milestones__first_incident_created_at,
            title: "First incident declared",
            value: data.s_key_milestones__first_incident_created_at
              ? displayDate(data.s_key_milestones__first_incident_created_at)
              : "",
          },
          {
            date: data.s_key_milestones__first_incident_led_at,
            title: "First incident led",
            value: data.s_key_milestones__first_incident_led_at
              ? displayDate(data.s_key_milestones__first_incident_led_at)
              : "",
          },
        ]
          .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
          )
          .map(({ title, value }) => ({ title, value }))
          .filter((x) => x.value)}
      />
    </>
  );
};

const SectionEra = ({ data }: { data: WrappedData }) => {
  const { identity } = useIdentity();

  return (
    <>
      <div className="text-3xl font-semibold text-content-primary">
        Based on the whirlwind that was 2024,
        <br /> you were clearly in your&hellip;
      </div>
      <GradientCard eraSlug={data.s_era__slug}>
        <div className="flex flex-col gap-6 items-start">
          <img
            src={ERAS[data.s_era__slug].icon}
            className="w-[42px] h-[42px]"
          />
          <div className="text-5xl-bold">{ERAS[data.s_era__slug].name} era</div>
          <div className="text-xl font-medium text-left text-shadow space-y-3">
            {ERAS[data.s_era__slug].content(identity)}
          </div>
        </div>
      </GradientCard>
    </>
  );
};

const useShareImageUrl = () => {
  const { identity } = useIdentity();

  return useMemo(
    () =>
      "/api/insights/wrapped/image?organisation_id=" + identity.organisation_id,
    [identity.organisation_id],
  );
};

const SectionShare = (_: { data: WrappedData }) => {
  const shareImageUrl = useShareImageUrl();

  const onDownload = useCallback(async () => {
    const imageData = await fetch(shareImageUrl);
    const blob = await imageData.blob();

    const dataUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = dataUrl;
    a.download = "incident-wrapped.png";
    a.click();
  }, [shareImageUrl]);

  const [copyState, setCopyState] = useState<"pending" | "copied" | "error">(
    "pending",
  );

  useEffect(() => {
    if (copyState !== "copied") return undefined;

    const timeout = setTimeout(() => setCopyState("pending"), 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copyState]);

  const onCopy = useCallback(async () => {
    try {
      const imageData = await fetch(shareImageUrl);
      const blob = await imageData.blob();

      await navigator.clipboard.write([
        new ClipboardItem({ "image/png": blob }),
      ]);
      setCopyState("copied");
    } catch (e) {
      console.error("Copying image", e);
      captureException(e, { extra: { shareImageUrl } });
      setCopyState("error");
    }
  }, [shareImageUrl]);

  return (
    <>
      <Heading
        title="Thank you ❤️‍🔥"
        subtitle="You absolutely crushed it in 2024 and handled things like a pro. Get some downtime over the holidays (the good kind) and we&rsquo;ll see you in 2025!"
      />
      <div className="bg-alarmalade-500/5 rounded-[26px] mx-auto p-4 space-y-4">
        <div className="flex items-center justify-between pl-2">
          <div className="text-base-bold text-content-secondary">
            Share your year in review
          </div>
          <div className="flex items-center gap-2 px-4">
            <Tooltip
              light
              content={
                copyState === "error" ? (
                  <>
                    We couldn&rsquo;t copy your incident.io wrapped for you. You
                    can right-click on the image to copy it instead!
                  </>
                ) : undefined
              }
            >
              <Button
                onClick={onCopy}
                analyticsTrackingId={"wrapped-copy"}
                icon={
                  copyState === "copied"
                    ? IconEnum.Success
                    : copyState === "pending"
                    ? IconEnum.Copy
                    : IconEnum.Warning
                }
                className={tcx("transition", {
                  "text-green-500": copyState === "copied",
                  "text-red-500": copyState === "error",
                })}
                theme={ButtonTheme.Secondary}
                disabled={copyState === "error"}
              >
                {copyState === "copied" ? "Copied" : "Copy"}
              </Button>
            </Tooltip>
            <Button
              onClick={onDownload}
              analyticsTrackingId={"wrapped-download"}
              icon={IconEnum.Download}
              theme={ButtonTheme.Secondary}
              className="transition"
            >
              Share
            </Button>
          </div>
        </div>

        <ImgWithLoadingSkeleton
          src={shareImageUrl}
          className="rounded-[20px] max-h-[400px]"
          width={622}
          height={400}
          alt={"Share your incident.io year in review"}
        />
      </div>
    </>
  );
};

const WrappedSection = ({
  id,
  nextSectionId,
  nextSectionButtonDelay,
  noPadding = false,
  children,
}: {
  id: string | null;
  nextSectionId: string | null;
  nextSectionButtonDelay?: number;
  noPadding?: boolean;
  children: React.ReactNode;
}) => {
  // We only mount the next-section button when it's visible, so it doesn't appear until you've looked at the section for 10s
  const [ref, { isVisible, wasEverVisible }] = useTrackVisibility({
    threshold: 0.8,
    rootMargin: "100px",
  });

  return (
    <ErrorBoundary
      onError={(error) => {
        console.error("Error in WrappedSection", { id, error });
        captureException(error, { contexts: { wrappedSection: { id } } });

        // skip to the next section
        if (nextSectionId) {
          const nextSection = document.getElementById(nextSectionId);
          nextSection?.scrollIntoView();
        }
      }}
    >
      <div
        className={tcx(
          "flex flex-col items-center justify-center snap-start relative w-full min-h-full px-8 lg:px-12",
          !noPadding && "py-28",
        )}
        id={id ?? undefined}
      >
        <div ref={ref}>
          {wasEverVisible && (
            <motion.div
              className="flex flex-col gap-10 items-center text-center max-w-full lg:max-w-[840px]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.9 }}
            >
              {children}
            </motion.div>
          )}
        </div>

        {wasEverVisible && nextSectionId && (
          <NextSectionButton
            nextSectionId={nextSectionId}
            delay={nextSectionButtonDelay}
            isVisible={isVisible}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

const NextSectionButton = ({
  nextSectionId,
  isVisible,
  delay = 5,
}: {
  nextSectionId: string;
  isVisible: boolean;
  delay?: number;
}) => {
  return (
    // Fix the height, for spacing purposes, and push it downwards so it doesn't take up space
    <div className="w-[96px] h-[96px] fixed bottom-[20px]">
      {isVisible && (
        // Outer div fades in after 10s
        <motion.div
          initial={{ opacity: 0, display: "none" }}
          animate={{ opacity: 1, display: "block" }}
          transition={{ delay, duration: 1 }}
        >
          <Button
            size={BadgeSize.Large}
            theme={ButtonTheme.Naked}
            className="text-content-secondary transition p-8" // lots of padding to make clicking easy
            analyticsTrackingId={null}
            onClick={() => {
              const nextSection = document.getElementById(nextSectionId);
              nextSection?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <motion.div
              animate={{
                opacity: [0.7, 1, 0.7],
                transform: [
                  "translateY(0px)",
                  "translateY(24px)",
                  "translateY(0px)",
                ],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "loop",
              }}
            >
              <Icon id={IconEnum.ChevronDown} size={IconSize.XL} />
            </motion.div>
          </Button>
        </motion.div>
      )}
    </div>
  );
};

const displayDate = (dateStr: string): string => {
  const d = new Date(dateStr);
  return format(d, "d MMM yy");
};

const displayPerc = (perc: number): string => {
  return (perc * 100).toFixed(0) + "%";
};

const displayInt = (num: number): string => {
  const rounded = Math.round(num);
  return rounded.toLocaleString();
};

const HeadingBadge = ({
  icon,
  children,
}: {
  icon: IconEnum;
  children: React.ReactNode;
}) => {
  return (
    <Badge
      theme={BadgeTheme.Primary}
      className="bg-[linear-gradient(66deg,_#F25533_20%,_#C084FC_100%)] shadow-sm"
      size={BadgeSize.Large}
      icon={icon}
    >
      {children}
    </Badge>
  );
};

const Heading = ({
  title,
  titleClassName,
  subtitle,
}: {
  title: React.ReactNode;
  titleClassName?: string;
  subtitle?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div
        className={tcx("text-5xl-bold text-content-primary", titleClassName)}
      >
        {title}
      </div>
      {subtitle && (
        <div className="text-2xl-bold text-content-secondary">{subtitle}</div>
      )}
    </div>
  );
};

const GradientCard = ({
  className,
  children,
  eraSlug,
}: {
  className?: string;
  children: React.ReactNode;
  eraSlug: string;
}) => (
  <div
    className={tcx(
      "p-12 rounded-[20px] text-white bg-cover bg-center bg-no-repeat w-full",
      className,
    )}
    style={{
      backgroundImage: `url(${ERAS[eraSlug].cardBackground})`,
    }}
  >
    {children}
  </div>
);

type Stat = {
  title: string;
  value: string | number;
};

const WrappedStatsCards = ({
  stats,
  small,
  className,
  transparent,
}: {
  stats: Stat[];
  className?: string;
  transparent?: boolean;
  small?: boolean;
}) => {
  return (
    <div
      className={tcx(
        "grid gap-8",
        stats.length > 1 && "w-full",
        "grid-cols-1",
        stats.length === 4
          ? "lg:grid-cols-[repeat(2,minmax(200px,1fr))]"
          : "lg:grid-cols-[repeat(auto-fit,minmax(180px,1fr))]",
        className,
      )}
    >
      {stats.map((stat) => (
        <div
          key={stat.title}
          className={tcx(
            "flex flex-col gap-2 bg-surface-secondary rounded-2 py-8 text-center items-center justify-center",
            stats.length === 1 && "min-w-[300px]",
            transparent
              ? "bg-purple-50/10 px-6 backdrop-blur-[6px]"
              : "bg-purple-500/5 px-4 lg:px-10",
          )}
        >
          <div
            className={tcx(
              "font-semibold text-[30px] leading-[30px] shrink-0 text-nowrap",
              small ? "" : "lg:text-[64px] lg:leading-[64px] tracking-tighter",
              transparent ? "text-white" : "text-content-primary",
            )}
          >
            {stat.value}
          </div>
          <div
            className={tcx(
              "text-base font-medium text-nowrap shrink-0",
              transparent ? "text-white" : "text-content-secondary",
            )}
          >
            {stat.title}
          </div>
        </div>
      ))}
    </div>
  );
};

const Stat = ({ children }: { children: React.ReactNode }) => (
  <span className="text-content-primary">{children}</span>
);

interface WrappedData {
  // General Properties
  slack_user_id: string;
  avatar_url: string;
  name: string;
  email: string;
  role: string;
  state: string;
  has_mobile_app: boolean;

  // Section: UsageMetrics
  s_usage_metrics__num_joined_incident_channels_ytd: number;
  s_usage_metrics__num_incidents_worked_on_ytd: number;
  s_usage_metrics__num_incidents_led_ytd: number;
  s_usage_metrics__show_percentiles: boolean;
  s_usage_metrics__perc_incidents_worked_on: number;
  s_usage_metrics__perc_incidents_led: number;

  // Section: MajorIncidents
  s_major_incidents__num_major_incidents: number;

  // Section: HoursOnIncidents
  s_hours_on_incidents__num_hours_ytd: number;
  s_hours_on_incidents__perc_incidents_worked_on: number;

  // Section: Overnight
  s_overnight__perc_outside_working_hours: number;
  s_overnight__worked_late_nights: boolean;

  // Section: WorkingSmarter
  s_working_smarter__num_slack_messages_sent: number;
  s_working_smarter__num_attachments_added: number;
  s_working_smarter__num_actions_created: number;
  s_working_smarter__num_incident_updates: number;
  s_working_smarter__show_section: number;

  // Section: YapToZap
  s_yapzap__persona: string;
  s_yapzap__num_slack_messages_sent: number;
  s_yapzap__num_actions_performed: number;

  // Section: SlashCommands
  s_slash_commands__num_slash_commands_used: number;
  s_slash_commands__top_commands_1: string;
  s_slash_commands__top_commands_2: string;
  s_slash_commands__top_commands_3: string;
  s_slash_commands__show_section: boolean;

  // Section: KeyMilestones
  s_key_milestones__first_incident_joined_at: string;
  s_key_milestones__first_incident_created_at: string;
  s_key_milestones__first_incident_led_at: string;

  // Section: Era
  s_era__slug: EraSlug;
}
