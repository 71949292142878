/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallSession,
    CallSessionFromJSON,
    CallSessionFromJSONTyped,
    CallSessionToJSON,
} from './CallSession';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface IncidentCallSessionWithTranscript
 */
export interface IncidentCallSessionWithTranscript {
    /**
     * 
     * @type {CallSession}
     * @memberof IncidentCallSessionWithTranscript
     */
    call_session: CallSession;
    /**
     * 
     * @type {TextDocument}
     * @memberof IncidentCallSessionWithTranscript
     */
    summary?: TextDocument;
}

export function IncidentCallSessionWithTranscriptFromJSON(json: any): IncidentCallSessionWithTranscript {
    return IncidentCallSessionWithTranscriptFromJSONTyped(json, false);
}

export function IncidentCallSessionWithTranscriptFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallSessionWithTranscript {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_session': CallSessionFromJSON(json['call_session']),
        'summary': !exists(json, 'summary') ? undefined : TextDocumentFromJSON(json['summary']),
    };
}

export function IncidentCallSessionWithTranscriptToJSON(value?: IncidentCallSessionWithTranscript | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_session': CallSessionToJSON(value.call_session),
        'summary': TextDocumentToJSON(value.summary),
    };
}

