import type { SVGProps } from "react";
import * as React from "react";
const SvgToggleLeft = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.5 4.5h-6a.75.75 0 0 0-.75.75v.076c.246-.039.493-.076.75-.076a4.75 4.75 0 0 1 0 9.5c-.257 0-.504-.036-.75-.076v.076c0 .414.336.75.75.75h6c3.033 0 5.5-2.467 5.5-5.5s-2.467-5.5-5.5-5.5Z"
    />
    <path
      fill="currentColor"
      d="M7.5 15.5A5.506 5.506 0 0 1 2 10c0-3.033 2.467-5.5 5.5-5.5S13 6.967 13 10s-2.467 5.5-5.5 5.5Zm0-9.5c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4Z"
    />
  </svg>
);
export default SvgToggleLeft;
