import { ImpersonationSession } from "@incident-io/api";
import { useEffect } from "react";

import { ImpersonateModal } from "./ImpersonateModal";
import { useStoredSession } from "./useStoredSession";

export const CheckForImpersonateSession = ({
  orgSlug,
  startImpersonating,
  onClose,
}: {
  orgSlug: string;
  startImpersonating: (session: ImpersonationSession) => void;
  onClose: () => void;
}) => {
  const { session } = useStoredSession({ orgSlug });

  useEffect(() => {
    if (session) {
      startImpersonating(session);
    }
  }, [session, startImpersonating]);

  return <ImpersonateModal onClose={onClose} prefillSlug={orgSlug} />;
};
