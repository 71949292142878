import { useAPIMutation } from "src/utils/swr";

export const useSkip = (incidentId: string) => {
  const {
    trigger: skipFollowUpSuggestion,
    isMutating: isSkipping,
    genericError: skipError,
    ...rest
  } = useAPIMutation(
    "followUpsListSuggestedFollowUps",
    { incidentId },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.followUpsSkipSuggestedFollowUp({
        skipSuggestedFollowUpRequestBody: {
          incident_follow_up_suggestion_id: id,
        },
      });
    },
  );

  // Rename some of the fields so they're skip-specific
  return {
    skipFollowUpSuggestion,
    isSkipping,
    skipError,
    ...rest,
  };
};
