import { Badge, BadgeSize, BadgeTheme } from "@incident-ui";
import _ from "lodash";
import React from "react";
import { Severity } from "src/contexts/ClientContext";

export const SeverityRankingLabel = ({
  severity,
  severities,
}: {
  severity: Severity;
  severities: Severity[];
}): React.ReactElement | null => {
  // The settings severity badge is different from the more commonly used severity badge because it
  // does not depend on the bucketed rank; we calculate the min and max rank from the list of
  // severities on the fly. This is to prevent bugs like two severities being labelled as "Lowest
  // severity".
  const minSeverityId = _.minBy(severities, "rank")?.id;
  const maxSeverityId = _.maxBy(severities, "rank")?.id;

  if (severity.id === maxSeverityId) {
    return <HighOrLowBadge>Highest</HighOrLowBadge>;
  }

  if (severity.id === minSeverityId) {
    return <HighOrLowBadge>Lowest</HighOrLowBadge>;
  }

  return null;
};

const HighOrLowBadge = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
      {children}
    </Badge>
  );
};
