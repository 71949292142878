import {
  InternalStatusPage,
  InternalStatusPageCreatePageRequestBodyThemeEnum,
  InternalStatusPageThemeEnum,
} from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { GenericErrorMessage, Loader } from "@incident-ui";
import _ from "lodash";
import { UseFormReturn } from "react-hook-form";
import {
  LogoData,
  StatusPagesLogoUploader,
} from "src/components/status-pages/edit/StatusPagesLogoUploader";
import {
  PageStructurePreview,
  PageType,
} from "src/components/status-pages/view/PageStructurePreview";

import { useInternalStatusPageStructure } from "../../../../utils/use-internal-status-page-structure";
import {
  ExpressionFormData,
  expressionToPayload,
} from "../../../@shared/engine/expressions/expressionToPayload";

export type BrandingFormType = {
  name: string;
  theme: InternalStatusPageThemeEnum;
  component_source_expression_payload?: ExpressionFormData;
  logo: LogoData;
  component_custom_field_id: string;
  hidden_catalog_group_values: string[];
  hidden_custom_field_group_ids: string[];
  hidden_custom_field_option_ids: string[];
  hidden_catalog_entry_ids: string[];
};

export const toBrandingForm = (page: InternalStatusPage): BrandingFormType => ({
  name: page.name,
  theme: page.theme,
  component_source_expression_payload: page.component_source_expression,
  logo:
    page.theme === InternalStatusPageThemeEnum.Dark &&
    page.darkmode_logo_key &&
    page.darkmode_logo_url
      ? {
          key: page.darkmode_logo_key,
          preview_url: page.darkmode_logo_url,
        }
      : page.theme === InternalStatusPageThemeEnum.Light &&
        page.lightmode_logo_key &&
        page.lightmode_logo_url
      ? {
          key: page.lightmode_logo_key,
          preview_url: page.lightmode_logo_url,
        }
      : null,
  component_custom_field_id: page.component_custom_field_id,
  hidden_catalog_group_values: page.hidden_catalog_group_values,
  hidden_custom_field_group_ids: page.hidden_custom_field_group_ids,
  hidden_custom_field_option_ids: page.hidden_custom_field_option_ids,
  hidden_catalog_entry_ids: page.hidden_catalog_entry_ids,
});

export const BrandingFormContent = <TFormType extends BrandingFormType>({
  subpath,
  formMethods: origFormMethods,
}: {
  subpath: string;
  formMethods: UseFormReturn<TFormType>;
}) => {
  // Typescript doesn't seem to understand that this is valid, so we do the
  // unsafe cast once up here and the rest is type-checked correctly.
  const formMethods =
    origFormMethods as unknown as UseFormReturn<BrandingFormType>;

  const {
    theme,
    name,
    logo,
    component_custom_field_id,
    hidden_custom_field_group_ids,
    hidden_catalog_group_values,
    hidden_custom_field_option_ids,
    hidden_catalog_entry_ids,
    component_source_expression_payload,
  } = formMethods.watch();

  const {
    previewItems,
    error,
    isLoading: loading,
  } = useInternalStatusPageStructure({
    component_custom_field_id,
    hidden_catalog_group_values,
    hidden_custom_field_group_ids,
    hidden_custom_field_option_ids,
    hidden_catalog_entry_ids,
    component_source_expression: component_source_expression_payload
      ? expressionToPayload(component_source_expression_payload)
      : undefined,
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <>
      <div className="space-y-4">
        <RadioButtonGroupV2
          name={"theme"}
          formMethods={formMethods}
          srLabel="Theme"
          label="Theme"
          boxed
          horizontal
          helptext={
            <>
              Choose whether or not you&apos;d like your colleagues to view your
              status page in light mode or dark mode.
            </>
          }
          options={[
            {
              label: "Dark mode",
              value: InternalStatusPageCreatePageRequestBodyThemeEnum.Dark,
            },
            {
              label: "Light mode",
              value: InternalStatusPageCreatePageRequestBodyThemeEnum.Light,
            },
          ]}
        />
      </div>
      <StatusPagesLogoUploader
        key="logo"
        name="logo"
        subpath={subpath}
        label={"Company logo"}
        displayFilename={(previewURL) =>
          `logo.${_.last(previewURL.split(".")) || "png"}`
        }
        dropText="Drop your logo here in PNG or JPEG format"
      />

      <PageStructurePreview
        name={name}
        logoPreviewURL={logo?.preview_url ?? null}
        faviconPreviewURL="/apple-icon.png"
        theme={theme}
        items={previewItems}
        type={PageType.Internal}
      />
    </>
  );
};
