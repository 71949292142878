/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';

/**
 * 
 * @export
 * @interface AlertRouteAlertSourcePayload
 */
export interface AlertRouteAlertSourcePayload {
    /**
     * The alert source ID that will match for the route
     * @type {string}
     * @memberof AlertRouteAlertSourcePayload
     */
    alert_source_id: string;
    /**
     * What conditions should alerts from this source meet to be included in this alert route?
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRouteAlertSourcePayload
     */
    condition_groups: Array<ConditionGroupPayload>;
}

export function AlertRouteAlertSourcePayloadFromJSON(json: any): AlertRouteAlertSourcePayload {
    return AlertRouteAlertSourcePayloadFromJSONTyped(json, false);
}

export function AlertRouteAlertSourcePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteAlertSourcePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_id': json['alert_source_id'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
    };
}

export function AlertRouteAlertSourcePayloadToJSON(value?: AlertRouteAlertSourcePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_id': value.alert_source_id,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
    };
}

