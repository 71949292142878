/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TutorialsCreateRequestBody,
    TutorialsCreateRequestBodyFromJSON,
    TutorialsCreateRequestBodyToJSON,
    TutorialsCreateResponseBody,
    TutorialsCreateResponseBodyFromJSON,
    TutorialsCreateResponseBodyToJSON,
} from '../models';

export interface TutorialsCreateRequest {
    createRequestBody: TutorialsCreateRequestBody;
}

/**
 * 
 */
export class TutorialsApi extends runtime.BaseAPI {

    /**
     * Creates a tutorial on Slack
     * Create Tutorials
     */
    async tutorialsCreateRaw(requestParameters: TutorialsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TutorialsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling tutorialsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tutorials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TutorialsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TutorialsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a tutorial on Slack
     * Create Tutorials
     */
    async tutorialsCreate(requestParameters: TutorialsCreateRequest, initOverrides?: RequestInit): Promise<TutorialsCreateResponseBody> {
        const response = await this.tutorialsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
