/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParameterisedResourceArguments
 */
export interface ParameterisedResourceArguments {
    /**
     * Whether the type this attribute is a reference to exists
     * @type {boolean}
     * @memberof ParameterisedResourceArguments
     */
    _exists: boolean;
    /**
     * The name of the parameterised type that this attribute is a reference to
     * @type {string}
     * @memberof ParameterisedResourceArguments
     */
    name: string;
    /**
     * The parameterised type that this attribute is a reference to
     * @type {string}
     * @memberof ParameterisedResourceArguments
     */
    parameter: string;
    /**
     * The registry type of the resource that this attribute is a reference to
     * @type {string}
     * @memberof ParameterisedResourceArguments
     */
    registry_type: string;
}

export function ParameterisedResourceArgumentsFromJSON(json: any): ParameterisedResourceArguments {
    return ParameterisedResourceArgumentsFromJSONTyped(json, false);
}

export function ParameterisedResourceArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterisedResourceArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': json['exists'],
        'name': json['name'],
        'parameter': json['parameter'],
        'registry_type': json['registry_type'],
    };
}

export function ParameterisedResourceArgumentsToJSON(value?: ParameterisedResourceArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
        'name': value.name,
        'parameter': value.parameter,
        'registry_type': value.registry_type,
    };
}

