import { Button, ButtonTheme } from "@incident-ui";
import _ from "lodash";
import {
  EscalationPathStatusForUser,
  EscalationPathStatusForUserStatusEnum,
  Identity,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { formatTimestampLocale } from "../../../../utils/datetime";
import { useAPI } from "../../../../utils/swr";

// Show a small widget in the bottom left of the screen that indicates whether
// you're on call.
export const OnCallHelper = ({
  identity,
  isExpanded,
}: {
  identity: Identity;
  isExpanded: boolean;
}): React.ReactElement | null => {
  const { data: escalationPathsData, isLoading: isLoadingEscalationPaths } =
    useAPI(
      identity ? "escalationPathsListForUserV3" : null,
      {
        userId: identity?.user_id ?? "",
      },
      {
        revalidateOnFocus: true,
        refreshInterval: 15000,
        shouldRetryOnError: false,
      },
    );

  const escalationPathsForUser = escalationPathsData?.escalation_paths;

  const displayInfo = createStackedEscalationPathDisplayInfo({
    identity,
    paths: escalationPathsForUser ?? [],
  });

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      className={tcx(
        "transition",
        "flex items-center gap-2 w-full",
        "shadow-button-dark",
        "text-sm rounded-2 grow",
        isExpanded
          ? {
              "py-3 px-4": true,
              "bg-green-800": displayInfo.appearance === "first-on-call",
              "bg-slate-700": displayInfo.appearance !== "first-on-call",
            }
          : "w-10 h-10 justify-center bg-slate-700",
      )}
      analyticsTrackingId={"on-call-helper"}
      href={"/on-call/escalation-paths"}
    >
      {isLoadingEscalationPaths ? (
        // Show something with some size to prevent too much layout shift
        <div className={isExpanded ? "p-4" : ""} />
      ) : (
        <div className="flex items-center justify-center w-full">
          <div
            className={tcx(
              "transition-all",
              isExpanded
                ? // hide when collapsed
                  "w-0 opacity-0"
                : "w-2 h-2 rounded-full",
              {
                "bg-green-600": displayInfo.appearance === "first-on-call",
                "bg-slate-500":
                  displayInfo.appearance === "secondary-or-later-on-call",
                "bg-slate-800": displayInfo.appearance === "not-on-call",
              },
            )}
          />
          <div
            className={tcx(
              "flex flex-col gap-2 w-full",
              isExpanded
                ? "transition-all ease-in-out delay-200"
                : "opacity-0 absolute",
            )}
          >
            <div className={"flex flex-col text-left w-full"}>
              <span className="text-slate-50 text-xs-bold truncate">
                {displayInfo.title}
              </span>
              <span
                className={tcx("text-content-tertiary text-xs-med truncate", {
                  "text-green-200": displayInfo.appearance === "first-on-call",
                  "text-content-tertiary":
                    displayInfo.appearance !== "first-on-call",
                })}
              >
                {displayInfo.subtitle}
              </span>
            </div>
          </div>
        </div>
      )}
    </Button>
  );
};

type Appearance =
  | "first-on-call"
  | "secondary-or-later-on-call"
  | "not-on-call";
const createStackedEscalationPathDisplayInfo = ({
  paths,
  identity,
}: {
  identity: Identity;
  paths: Array<EscalationPathStatusForUser>;
}): {
  title: string;
  subtitle: string;
  appearance: Appearance;
} => {
  const pathsCurrentlyOnCallFor = paths.filter(
    (escalationPath) =>
      escalationPath.status ===
        EscalationPathStatusForUserStatusEnum.OnCallPrimary ||
      escalationPath.status ===
        EscalationPathStatusForUserStatusEnum.OnCallSecondary,
  );

  const pathsCurrentlyFirstFor = paths.filter((escalationPath) =>
    escalationPath.currently_on_call_users.some(
      (u) => u.id === identity.user_id,
    ),
  );

  let appearance: Appearance = "not-on-call";
  let title = "Not on call";
  let subtitle = "";
  if (pathsCurrentlyOnCallFor.length > 0) {
    appearance =
      pathsCurrentlyFirstFor.length > 0
        ? "first-on-call"
        : "secondary-or-later-on-call";

    const currentShiftEnds = _.chain(pathsCurrentlyOnCallFor)
      .filter((p) => !!p.current_shift_for_user)
      .map((p) => p.current_shift_for_user?.end_at)
      .maxBy((d) => d?.getTime())
      .value() as Date;

    subtitle = currentShiftEnds
      ? `Until ${formatTimestampLocale({
          addWeekday: true,
          dateStyle: "short",
          timestamp: currentShiftEnds,
          timeStyle: "short",
        })}`
      : "Indefinitely";

    if (pathsCurrentlyFirstFor.length === 1) {
      // Design responder level 1
      const path = pathsCurrentlyFirstFor[0];
      title = `${path.escalation_path.name} level ${
        path.most_significant_level_for_user?.node_level_depth ?? 1
      }`;
    } else if (pathsCurrentlyFirstFor.length > 1) {
      // Level 1 on multiple paths, or
      // Design responder level 2
      const [lowestLevel, levelsWithLowestLevel] = pathsWithLowestLevelDepth(
        pathsCurrentlyFirstFor,
      );
      title =
        levelsWithLowestLevel.length === 1
          ? `${levelsWithLowestLevel[0].escalation_path.name} level ${lowestLevel}`
          : `Level ${lowestLevel} on multiple paths`;
    } else if (pathsCurrentlyOnCallFor.length === 1) {
      // Design responder level 2
      const path = pathsCurrentlyOnCallFor[0];
      title = `${path.escalation_path.name} level ${
        path.current_level_for_user?.node_level_depth ?? 1
      }`;
    } else {
      // Level 2+ on multiple paths
      const [lowestLevel, levelsWithLowestLevel] = pathsWithLowestLevelDepth(
        pathsCurrentlyOnCallFor,
      );
      title =
        levelsWithLowestLevel.length === 1
          ? `${levelsWithLowestLevel[0].escalation_path.name} level ${lowestLevel}`
          : `Level ${lowestLevel} on multiple paths`;
    }
  } else {
    // Otherwise, you're on call in the future
    const soonestShift: Date | undefined = _.chain(paths)
      .filter((p) => !!p.next_shift_for_user)
      .map((p) => p.next_shift_for_user?.start_at as Date)
      .minBy((d) => d.getTime())
      .value();

    if (soonestShift) {
      subtitle = `Next shift: ${formatTimestampLocale({
        addWeekday: true,
        dateStyle: "short",
        timestamp: soonestShift,
        timeStyle: "short",
      })}`;
    } else {
      subtitle = "No shifts in the next three months";
    }
  }

  return {
    title,
    subtitle,
    appearance,
  };
};

const pathsWithLowestLevelDepth = (
  p: EscalationPathStatusForUser[],
): [number, EscalationPathStatusForUser[]] => {
  const lowestLevel = Math.min(
    ...p.map((p) => p.current_level_for_user?.node_level_depth ?? 1),
  );

  return [
    lowestLevel,
    p.filter(
      (p) => (p.current_level_for_user?.node_level_depth ?? 1) === lowestLevel,
    ),
  ];
};
