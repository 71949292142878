import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { SavedView } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { useSavedViews } from "./SavedViewContext";

type Props = {
  onClose: () => void;
  savedView: SavedView;
};

type FormState = { id: string; name: string };

export const SavedViewRenameModal = ({
  onClose,
  savedView,
}: Props): React.ReactElement => {
  const formMethods = useForm<FormState>({
    defaultValues: { id: savedView.id, name: savedView.name },
  });

  const { context, renameSavedView } = useSavedViews();

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation("savedViewsList", { context }, renameSavedView, {
    setError: formMethods.setError,
    onSuccess: onClose,
  });

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Rename saved view"
      analyticsTrackingId="saved-view-rename-modal"
      onClose={onClose}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          saving={saving}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        label="Name"
        name="name"
        autoFocus
        type={InputType.Text}
        required
      />
    </Form.Modal>
  );
};
