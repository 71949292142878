/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FollowUpPriority,
    FollowUpPriorityFromJSON,
    FollowUpPriorityFromJSONTyped,
    FollowUpPriorityToJSON,
} from './FollowUpPriority';

/**
 * 
 * @export
 * @interface FollowUpsUpdatePriorityOptionRanksResponseBody
 */
export interface FollowUpsUpdatePriorityOptionRanksResponseBody {
    /**
     * 
     * @type {Array<FollowUpPriority>}
     * @memberof FollowUpsUpdatePriorityOptionRanksResponseBody
     */
    priority_options: Array<FollowUpPriority>;
}

export function FollowUpsUpdatePriorityOptionRanksResponseBodyFromJSON(json: any): FollowUpsUpdatePriorityOptionRanksResponseBody {
    return FollowUpsUpdatePriorityOptionRanksResponseBodyFromJSONTyped(json, false);
}

export function FollowUpsUpdatePriorityOptionRanksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsUpdatePriorityOptionRanksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priority_options': ((json['priority_options'] as Array<any>).map(FollowUpPriorityFromJSON)),
    };
}

export function FollowUpsUpdatePriorityOptionRanksResponseBodyToJSON(value?: FollowUpsUpdatePriorityOptionRanksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priority_options': ((value.priority_options as Array<any>).map(FollowUpPriorityToJSON)),
    };
}

