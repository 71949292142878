/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotInteractionScores,
    CopilotInteractionScoresFromJSON,
    CopilotInteractionScoresFromJSONTyped,
    CopilotInteractionScoresToJSON,
} from './CopilotInteractionScores';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CopilotThread
 */
export interface CopilotThread {
    /**
     * When the thread was created.
     * @type {Date}
     * @memberof CopilotThread
     */
    created_at: Date;
    /**
     * 
     * @type {User}
     * @memberof CopilotThread
     */
    from_user?: User;
    /**
     * ID of the thread
     * @type {string}
     * @memberof CopilotThread
     */
    id: string;
    /**
     * The incident this thread is related to
     * @type {number}
     * @memberof CopilotThread
     */
    incident_external_id?: number;
    /**
     * Total cost of all interactions in the thread
     * @type {number}
     * @memberof CopilotThread
     */
    overall_cost_cents: number;
    /**
     * 
     * @type {CopilotInteractionScores}
     * @memberof CopilotThread
     */
    scores?: CopilotInteractionScores;
    /**
     * The initial message that triggered the copilot interaction
     * @type {string}
     * @memberof CopilotThread
     */
    source_message: string;
}

export function CopilotThreadFromJSON(json: any): CopilotThread {
    return CopilotThreadFromJSONTyped(json, false);
}

export function CopilotThreadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotThread {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'from_user': !exists(json, 'from_user') ? undefined : UserFromJSON(json['from_user']),
        'id': json['id'],
        'incident_external_id': !exists(json, 'incident_external_id') ? undefined : json['incident_external_id'],
        'overall_cost_cents': json['overall_cost_cents'],
        'scores': !exists(json, 'scores') ? undefined : CopilotInteractionScoresFromJSON(json['scores']),
        'source_message': json['source_message'],
    };
}

export function CopilotThreadToJSON(value?: CopilotThread | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'from_user': UserToJSON(value.from_user),
        'id': value.id,
        'incident_external_id': value.incident_external_id,
        'overall_cost_cents': value.overall_cost_cents,
        'scores': CopilotInteractionScoresToJSON(value.scores),
        'source_message': value.source_message,
    };
}

