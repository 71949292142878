import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import React from "react";
import { useIntercom } from "react-use-intercom";
import { tcx } from "src/utils/tailwind-classes";

export type FullPageEmptyStateProps = {
  graphic: React.ReactNode;
  cta: React.ReactNode;
  title: string;
  subtitle: string;
  cards: React.ReactNode;
  className?: string;
  gradientClasses: string;
  helpcenterArticleID?: number;
};

export const FullPageEmptyState = ({
  graphic,
  cta,
  title,
  subtitle,
  cards,
  className,
  gradientClasses,
  helpcenterArticleID,
}: FullPageEmptyStateProps) => {
  const { showArticle } = useIntercom();

  return (
    <div className={tcx("flex flex-col grow gap-6", className)}>
      <div
        className={tcx(
          "flex flex-col grow justify-center items-center rounded-2 bg-gradient-to-b",
          gradientClasses,
        )}
      >
        <div className="flex flex-col gap-6 items-center justify-center max-w-[428px]">
          {graphic}
          <div className="flex flex-col gap-2 items-center">
            <div className="text-2xl-bold text-center">{title}</div>
            <div className="text-base text-center text-sm-normal text-content-secondary">
              {subtitle}
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            {cta}
            {helpcenterArticleID && (
              <Button
                onClick={() => showArticle(helpcenterArticleID)}
                theme={ButtonTheme.Secondary}
                analyticsTrackingId={null}
              >
                Learn more
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6 items-stretch max-w-[2000px] mx-auto">
        {cards}
      </div>
    </div>
  );
};
