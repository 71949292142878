import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { IntegrationsListObject } from "@incident-shared/integrations";
import { ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { IntegrationsSetIntegrationAPITokenRequestBodyProviderEnum as ProviderEnum } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

type FormData = {
  api_token: string;
};

enum Step {
  Tokens = "tokens",
}

export const ConnectToCortexModal = ({
  onClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}): React.ReactElement | null => {
  const [step, _] = useState<Step>(Step.Tokens);

  switch (step) {
    case Step.Tokens:
      return <SetTokens onClose={onClose} />;
    default:
      assertUnreachable(step);
      return null;
  }
};

function SetTokens({ onClose }: { onClose: () => void }): ReactElement {
  const formMethods = useForm<FormData>();
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, { api_token }: FormData) => {
      await apiClient.integrationsSetIntegrationApiToken({
        setIntegrationApiTokenRequestBody: {
          provider: ProviderEnum.Cortex,
          api_token,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-cortex-integration"
      title="Connect to Cortex"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add your Cortex API token to begin syncing your catalog. You can use the
        catalog data to power custom fields and workflows, such as sending
        alerts to the teams who own affected services.
      </p>
      <hr className="my-3" />
      <div className="space-y-4">
        <InputV2
          formMethods={formMethods}
          label="API Token"
          helptext={`This is the API token created from the Cortex dashboard.`}
          type={InputType.Password}
          required="Please enter an API token"
          name="api_token"
          autoComplete="none"
          placeholder={"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"}
        />
      </div>
    </Form.Modal>
  );
}
