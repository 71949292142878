/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MobileKey,
    MobileKeyFromJSON,
    MobileKeyFromJSONTyped,
    MobileKeyToJSON,
} from './MobileKey';

/**
 * 
 * @export
 * @interface MobileListKeysResponseBody
 */
export interface MobileListKeysResponseBody {
    /**
     * The mobile keys for this user
     * @type {Array<MobileKey>}
     * @memberof MobileListKeysResponseBody
     */
    mobile_keys: Array<MobileKey>;
}

export function MobileListKeysResponseBodyFromJSON(json: any): MobileListKeysResponseBody {
    return MobileListKeysResponseBodyFromJSONTyped(json, false);
}

export function MobileListKeysResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileListKeysResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobile_keys': ((json['mobile_keys'] as Array<any>).map(MobileKeyFromJSON)),
    };
}

export function MobileListKeysResponseBodyToJSON(value?: MobileListKeysResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile_keys': ((value.mobile_keys as Array<any>).map(MobileKeyToJSON)),
    };
}

