import { EscalationPathStatusForUser, ScopeNameEnum } from "@incident-io/api";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { SearchBar, SearchProvider } from "@incident-ui/SearchBar/SearchBar";
import { Searcher } from "fast-fuzzy";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useOutlet } from "react-router";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";
import { useLocalStorage } from "usehooks-ts";

import { useIdentity } from "../../contexts/IdentityContext";
import { EmptyState } from "../legacy/on-call/EmptyState";
import { useOnCallEntityCount } from "../legacy/on-call/utils";
import EmptyStateImg from "./images/escalation-path-empty-state.png";
import { EscalationPathsList } from "./list/EscalationPathsList";

export const EscalationPathsPage = () => {
  const {
    data: escalationPathsResp,
    isLoading: escalationPathsIsLoading,
    error: escalationPathsError,
  } = useAPI("escalationPathsList", undefined);

  const {
    data: entityCount,
    isLoading: entityCountLoading,
    error: entityCountError,
  } = useOnCallEntityCount();

  const [searchText, setSearchText] = useState("");

  const escalationPaths = escalationPathsResp?.escalation_paths ?? [];

  const drawer = useOutlet();

  const { hasOnCall } = useProductAccess();

  const { identity } = useIdentity();
  const { data: escalationPathsForUserResp, isLoading: isLoadingUserEps } =
    useAPI("escalationPathsListForUserV3", {
      userId: identity?.user_id,
    });

  const escalationPathStatuses: EscalationPathStatusForUser[] | undefined =
    escalationPathsForUserResp?.escalation_paths;

  const userEscalationPathsFirstLevelUserIDs = _.chain(
    escalationPathsForUserResp?.escalation_paths,
  )
    .groupBy((ep) => ep.escalation_path.id)
    .mapValues((ep: EscalationPathStatusForUser[]) =>
      ep.flatMap((e: EscalationPathStatusForUser): string[] =>
        e.currently_on_call_users.map((u) => u.id),
      ),
    )
    .value();

  const userEscalationPaths =
    escalationPathStatuses?.map((ep) => ep.escalation_path) ?? [];

  if (!hasOnCall) {
    return <OrgAwareNavigate to="/on-call" replace={true} />;
  }

  if (escalationPathsError || entityCountError) {
    return <GenericErrorMessage error={escalationPathsError} />;
  }

  if (
    escalationPathsIsLoading ||
    isLoadingUserEps ||
    !escalationPathsResp ||
    entityCountLoading
  ) {
    return <Loader />;
  }

  // If we've just deleted the last on-call entity, we want to send the user back to the get started page
  if (entityCount === 0 && !drawer) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  const searcher = new Searcher(escalationPaths, {
    keySelector: (schedule) => schedule.name,
  });

  let filteredEscalationPolicies = escalationPaths;
  if (searchText) {
    filteredEscalationPolicies = searcher.search(searchText);
  }

  return (
    <SearchProvider>
      {/* This means any sub-routes (e.g. create/edit) get a fully-animated drawer */}
      <AnimatePresence>{drawer}</AnimatePresence>

      <Helmet title={"Escalation paths - incident.io"} />

      {escalationPaths.length === 0 ? (
        <EmptyState
          copy="Configure which schedules and individuals to notify and in what order when an escalation is triggered."
          imageSrc={EmptyStateImg}
          title="No escalation paths created"
          buttons={[
            {
              href: "/on-call/escalation-paths/create",
              analyticsTrackingId: "on-call-create-escalation-path",
              children: "Create escalation path",
              isGated: true,
              gatingProps: {
                requiredScope: ScopeNameEnum.EscalationPathsCreate,
                disabledTooltipContent:
                  "You do not have permission to create escalation policies",
              },
            },
          ]}
        />
      ) : (
        <>
          <EscalationPathsPageHeader
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <div className={"pb-5 flex flex-col space-y-16"}>
            {!searchText && userEscalationPaths.length > 0 && (
              <div>
                <div className={"text-content-primary text-sm font-semibold"}>
                  {"My escalation paths"}
                </div>
                <div className=" text-content-secondary text-sm mb-4">
                  {"Escalation paths you're a part of."}
                </div>
                <EscalationPathsList
                  escalationPaths={userEscalationPaths}
                  firstLevelUserIDs={userEscalationPathsFirstLevelUserIDs}
                />
              </div>
            )}
            <div>
              <div className={"text-content-primary text-sm font-semibold"}>
                {"All escalation paths"}
              </div>
              <div className=" text-content-secondary text-sm mb-4">
                {"All escalation paths for your organisation."}
              </div>
              <EscalationPathsList
                escalationPaths={filteredEscalationPolicies ?? []}
                firstLevelUserIDs={escalationPathsResp.first_level_users}
                showEmptyState
              />
            </div>
          </div>
        </>
      )}
    </SearchProvider>
  );
};

export const EscalationPathsPageHeader = ({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: (newValue: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex">
        <SearchBar
          value={searchText}
          onChange={setSearchText}
          placeholder="Search"
          autoFocus
        />
        <div className="grow" />
        <Button
          href={"/on-call/escalation-paths/create"}
          analyticsTrackingId={null}
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Add}
        >
          New escalation path
        </Button>
      </div>
      <ConnectToAlertsBanner />
    </div>
  );
};

export const ConnectToAlertsBanner = () => {
  const localStorageKey = "connectToAlertsBannerDismissed";
  const [isDismissed, setDismissed] = useLocalStorage(localStorageKey, false);
  const handleDismiss = () => setDismissed(true);

  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined);

  const doNotShowConnectToAlertsBanner =
    alertRoutesLoading ||
    alertRoutesError ||
    !alertRoutesData ||
    alertRoutesData.alert_routes.some(
      (alertRoute) => alertRoute.escalation_bindings.length > 0,
    );

  return isDismissed || doNotShowConnectToAlertsBanner ? (
    <></>
  ) : (
    <Callout
      theme={CalloutTheme.Info}
      iconOverride={IconEnum.SplitArrow}
      title="Automate escalations with alert routes"
      subtitle="Escalation paths won't be triggered unless they're connected to an alert source via an alert route."
      cta={
        <Button
          href="/alerts/routes"
          analyticsTrackingId="escalation-paths-go-to-alerts"
          theme={ButtonTheme.Secondary}
          size={BadgeSize.Large}
        >
          View alert routes
        </Button>
      }
      secondaryCta={
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          onClick={() => handleDismiss()}
          title="Dismiss banner"
        >
          Dismiss
        </Button>
      }
      ctaPosition="bottom"
    />
  );
};
