/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Shoutout,
    ShoutoutFromJSON,
    ShoutoutFromJSONTyped,
    ShoutoutToJSON,
} from './Shoutout';

/**
 * 
 * @export
 * @interface ShoutoutsCreateResponseBody
 */
export interface ShoutoutsCreateResponseBody {
    /**
     * 
     * @type {Shoutout}
     * @memberof ShoutoutsCreateResponseBody
     */
    shoutout: Shoutout;
}

export function ShoutoutsCreateResponseBodyFromJSON(json: any): ShoutoutsCreateResponseBody {
    return ShoutoutsCreateResponseBodyFromJSONTyped(json, false);
}

export function ShoutoutsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoutoutsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shoutout': ShoutoutFromJSON(json['shoutout']),
    };
}

export function ShoutoutsCreateResponseBodyToJSON(value?: ShoutoutsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shoutout': ShoutoutToJSON(value.shoutout),
    };
}

