/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageViewCountBody
 */
export interface StatusPageViewCountBody {
    /**
     * The event that was triggered
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    event: string;
    /**
     * The IP address of the client
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    forwarded_for?: string;
    /**
     * The meta data of the event
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    meta?: string;
    /**
     * The type of page that was viewed
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    page_type: StatusPageViewCountBodyPageTypeEnum;
    /**
     * The path of the page that was viewed
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    path?: string;
    /**
     * The context of the request
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    request_context: StatusPageViewCountBodyRequestContextEnum;
    /**
     * The title of the page that was viewed
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    title?: string;
    /**
     * The user agent of the client
     * @type {string}
     * @memberof StatusPageViewCountBody
     */
    user_agent?: string;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageViewCountBodyPageTypeEnum {
    RootPage = 'root_page',
    IncidentPage = 'incident_page'
}/**
* @export
* @enum {string}
*/
export enum StatusPageViewCountBodyRequestContextEnum {
    Server = 'server',
    Client = 'client'
}

export function StatusPageViewCountBodyFromJSON(json: any): StatusPageViewCountBody {
    return StatusPageViewCountBodyFromJSONTyped(json, false);
}

export function StatusPageViewCountBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageViewCountBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event': json['event'],
        'forwarded_for': !exists(json, 'forwarded_for') ? undefined : json['forwarded_for'],
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
        'page_type': json['page_type'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'request_context': json['request_context'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'user_agent': !exists(json, 'user_agent') ? undefined : json['user_agent'],
    };
}

export function StatusPageViewCountBodyToJSON(value?: StatusPageViewCountBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event': value.event,
        'forwarded_for': value.forwarded_for,
        'meta': value.meta,
        'page_type': value.page_type,
        'path': value.path,
        'request_context': value.request_context,
        'title': value.title,
        'user_agent': value.user_agent,
    };
}

