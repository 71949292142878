import type { SVGProps } from "react";
import * as React from "react";
const SvgTextItalic = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.117 3h3.132a.75.75 0 0 1 0 1.5h-2.61l-3.696 11h2.106a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1 0-1.5h2.61l3.696-11H8.95a.75.75 0 1 1 0-1.5H12.117Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTextItalic;
