import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ButtonTheme, ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StatusPageIncident,
  StatusPageSetWriteUpRequestBody,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { hasWriteUp } from "../utils/utils";
import { PublicWriteUpDeleteModal } from "./PublicWriteUpDeleteModal";

export const PublicWriteUpEditModal = ({
  onClose,
  incident,
}: {
  onClose: () => void;
  incident: StatusPageIncident;
}) => {
  const showToast = useToast();

  const [currentTab, setCurrentTab] = useState<"link" | "embed">(
    incident.write_up_url ? "link" : "embed",
  );
  const formMethods = useForm<StatusPageSetWriteUpRequestBody>({
    defaultValues: {
      write_up_url: incident.write_up_url,
      write_up_contents: incident.write_up_contents,
    },
  });
  const {
    setError,
    formState: { isDirty },
    watch,
  } = formMethods;

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient, data: StatusPageSetWriteUpRequestBody) => {
      const payload: StatusPageSetWriteUpRequestBody = {};
      if (currentTab === "link") {
        payload.write_up_url = data.write_up_url;
      } else {
        payload.write_up_contents = data.write_up_contents;
      }

      return await apiClient.statusPageSetWriteUp({
        id: incident.id,
        setWriteUpRequestBody: payload,
      });
    },
    {
      onSuccess: ({ status_page_incident }) => {
        const addedOrRemoved =
          status_page_incident.write_up_url ||
          status_page_incident.write_up_contents
            ? "added"
            : "removed";
        showToast({
          title: `Write-up successfully ${addedOrRemoved}`,
          description: `This write-up will ${
            addedOrRemoved === "added" ? "now" : "no longer"
          } appear on your status page`,
          theme: ToastTheme.Success,
        });
        onClose();
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: "Please try again",
          theme: ToastTheme.Error,
        });
        setError;
      },
    },
  );

  const formIsClearing = !watch(
    currentTab === "link" ? "write_up_url" : "write_up_contents",
  );
  const isClearing =
    hasWriteUp(incident) && formIsClearing && formMethods.formState.isDirty;

  const [confirmingDelete, setConfirmingDelete] = useState(false);

  if (confirmingDelete) {
    return (
      <PublicWriteUpDeleteModal
        incident={incident}
        onSuccess={onClose}
        onClose={() => setConfirmingDelete(false)}
      />
    );
  }

  return (
    <Form.TabModal
      formMethods={formMethods}
      onSubmit={isClearing ? () => setConfirmingDelete(true) : trigger}
      onClose={onClose}
      disableQuickClose={false}
      genericError={genericError}
      title={hasWriteUp(incident) ? "Manage write-up" : "Add a write-up"}
      analyticsTrackingId="add-write-up-modal"
      footer={
        <ModalFooter
          onClose={onClose}
          saving={isMutating}
          disabled={!isDirty}
          confirmButtonType="submit"
          confirmButtonText={isClearing ? "Remove" : "Save"}
          confirmButtonTheme={
            isClearing ? ButtonTheme.Destroy : ButtonTheme.Primary
          }
        />
      }
      contentClassName="text-sm mt-2 space-y-2"
      tabs={[
        {
          label: "Hosted ",
          id: "embed",
        },
        {
          label: "Link",
          id: "link",
        },
      ]}
      defaultTab={currentTab}
      onTabChange={(newTab: string) =>
        setCurrentTab(newTab === "link" ? "link" : "embed")
      }
    >
      <>
        <p>
          Public post-incident write-ups can provide your users with
          transparency and clarity around what happened in an incident.
        </p>
        {!hasWriteUp(incident) ? (
          <p>We will notify your subscribers when you publish this document.</p>
        ) : null}
        {currentTab === "link" ? (
          <InputV2
            formMethods={formMethods}
            name="write_up_url"
            label="External link"
            placeholder="https://blog.example.com/incident"
            helptext={"Make sure this document is publicly accessible"}
          />
        ) : (
          <TemplatedTextInputV2
            formMethods={formMethods}
            includeExpressions={false}
            includeVariables={false}
            multiLine
            name="write_up_contents"
            format="mrkdwn" // only basic formatting
          />
        )}
      </>
    </Form.TabModal>
  );
};
