import type { SVGProps } from "react";
import * as React from "react";
const SvgIncomingPhoneCall = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M11.787 8.988H15.4a.774.774 0 0 0 0-1.549h-1.744l3.324-3.323a.774.774 0 1 0-1.095-1.096L12.56 6.344V4.601a.774.774 0 0 0-1.548 0v3.612c0 .428.346.775.774.775Z" />
    <path d="m16.668 12.324-3.02-1.341a1.777 1.777 0 0 0-2.106.51l-1.41 1.756a11.646 11.646 0 0 1-3.383-3.386l1.76-1.402a1.78 1.78 0 0 0 .513-2.106L7.68 3.333a1.771 1.771 0 0 0-2.069-.998l-2.556.664a1.78 1.78 0 0 0-1.314 1.968c.964 6.863 6.43 12.33 13.295 13.294a1.783 1.783 0 0 0 1.966-1.315l.663-2.556a1.77 1.77 0 0 0-.997-2.066Z" />
  </svg>
);
export default SvgIncomingPhoneCall;
