/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinearIssue
 */
export interface LinearIssue {
    /**
     * Linear user ID to assign to the issue
     * @type {string}
     * @memberof LinearIssue
     */
    assignee_id?: string;
    /**
     * Description body of the Linear issue
     * @type {string}
     * @memberof LinearIssue
     */
    description?: string;
    /**
     * ID of Linear label(s) to assign to the issue
     * @type {Array<string>}
     * @memberof LinearIssue
     */
    labels?: Array<string>;
    /**
     * The Linear project to create the issue to
     * @type {string}
     * @memberof LinearIssue
     */
    project_id?: string;
    /**
     * The Linear team to create the issue to
     * @type {string}
     * @memberof LinearIssue
     */
    team_id?: string;
    /**
     * Title of the Linear issue
     * @type {string}
     * @memberof LinearIssue
     */
    title?: string;
}

export function LinearIssueFromJSON(json: any): LinearIssue {
    return LinearIssueFromJSONTyped(json, false);
}

export function LinearIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinearIssue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'project_id': !exists(json, 'project_id') ? undefined : json['project_id'],
        'team_id': !exists(json, 'team_id') ? undefined : json['team_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function LinearIssueToJSON(value?: LinearIssue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': value.assignee_id,
        'description': value.description,
        'labels': value.labels,
        'project_id': value.project_id,
        'team_id': value.team_id,
        'title': value.title,
    };
}

