/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerPageContentListComponentImpactsResponseBody,
    CustomerPageContentListComponentImpactsResponseBodyFromJSON,
    CustomerPageContentListComponentImpactsResponseBodyToJSON,
    CustomerPageContentListIncidentsResponseBody,
    CustomerPageContentListIncidentsResponseBodyFromJSON,
    CustomerPageContentListIncidentsResponseBodyToJSON,
    CustomerPageContentRefreshAuthTokenResponseBody,
    CustomerPageContentRefreshAuthTokenResponseBodyFromJSON,
    CustomerPageContentRefreshAuthTokenResponseBodyToJSON,
    CustomerPageContentShowIncidentResponseBody,
    CustomerPageContentShowIncidentResponseBodyFromJSON,
    CustomerPageContentShowIncidentResponseBodyToJSON,
    CustomerPageContentShowPageResponseBody,
    CustomerPageContentShowPageResponseBodyFromJSON,
    CustomerPageContentShowPageResponseBodyToJSON,
    CustomerPageContentSubscribeRequestBody,
    CustomerPageContentSubscribeRequestBodyFromJSON,
    CustomerPageContentSubscribeRequestBodyToJSON,
    CustomerPageContentTrackCustomerPageViewRequestBody,
    CustomerPageContentTrackCustomerPageViewRequestBodyFromJSON,
    CustomerPageContentTrackCustomerPageViewRequestBodyToJSON,
    CustomerPageContentUnsubscribeRequestBody,
    CustomerPageContentUnsubscribeRequestBodyFromJSON,
    CustomerPageContentUnsubscribeRequestBodyToJSON,
    CustomerPageContentVerifySubscriptionRequestBody,
    CustomerPageContentVerifySubscriptionRequestBodyFromJSON,
    CustomerPageContentVerifySubscriptionRequestBodyToJSON,
    CustomerPageContentVerifySubscriptionResponseBody,
    CustomerPageContentVerifySubscriptionResponseBodyFromJSON,
    CustomerPageContentVerifySubscriptionResponseBodyToJSON,
} from '../models';

export interface CustomerPageContentListComponentImpactsRequest {
    startAt: Date;
    endAt: Date;
    mainSlug: string;
    subpageSlug: string;
    authorization?: string;
}

export interface CustomerPageContentListIncidentsRequest {
    mainSlug: string;
    subpageSlug: string;
    startAt?: string;
    endAt?: string;
    authorization?: string;
}

export interface CustomerPageContentRefreshAuthTokenRequest {
    mainSlug: string;
    subpageSlug: string;
    authorization?: string;
}

export interface CustomerPageContentShowIncidentRequest {
    mainSlug: string;
    subpageSlug: string;
    incidentId: string;
    authorization?: string;
}

export interface CustomerPageContentShowPageRequest {
    mainSlug: string;
    subpageSlug: string;
    authorization?: string;
}

export interface CustomerPageContentSubscribeRequest {
    mainSlug: string;
    subpageSlug: string;
    subscribeRequestBody: CustomerPageContentSubscribeRequestBody;
    authorization?: string;
}

export interface CustomerPageContentTrackCustomerPageViewRequest {
    mainSlug: string;
    subpageSlug: string;
    trackCustomerPageViewRequestBody: CustomerPageContentTrackCustomerPageViewRequestBody;
    authorization?: string;
}

export interface CustomerPageContentUnsubscribeRequest {
    mainSlug: string;
    subpageSlug: string;
    unsubscribeRequestBody: CustomerPageContentUnsubscribeRequestBody;
    authorization?: string;
}

export interface CustomerPageContentVerifySubscriptionRequest {
    mainSlug: string;
    subpageSlug: string;
    verifySubscriptionRequestBody: CustomerPageContentVerifySubscriptionRequestBody;
    authorization?: string;
}

/**
 * 
 */
export class CustomerPageContentApi extends runtime.BaseAPI {

    /**
     * ListComponentImpacts Customer Page Content
     */
    async customerPageContentListComponentImpactsRaw(requestParameters: CustomerPageContentListComponentImpactsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPageContentListComponentImpactsResponseBody>> {
        if (requestParameters.startAt === null || requestParameters.startAt === undefined) {
            throw new runtime.RequiredError('startAt','Required parameter requestParameters.startAt was null or undefined when calling customerPageContentListComponentImpacts.');
        }

        if (requestParameters.endAt === null || requestParameters.endAt === undefined) {
            throw new runtime.RequiredError('endAt','Required parameter requestParameters.endAt was null or undefined when calling customerPageContentListComponentImpacts.');
        }

        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentListComponentImpacts.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentListComponentImpacts.');
        }

        const queryParameters: any = {};

        if (requestParameters.startAt !== undefined) {
            queryParameters['start_at'] = (requestParameters.startAt as any).toISOString();
        }

        if (requestParameters.endAt !== undefined) {
            queryParameters['end_at'] = (requestParameters.endAt as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/component_impacts`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageContentListComponentImpactsResponseBodyFromJSON(jsonValue));
    }

    /**
     * ListComponentImpacts Customer Page Content
     */
    async customerPageContentListComponentImpacts(requestParameters: CustomerPageContentListComponentImpactsRequest, initOverrides?: RequestInit): Promise<CustomerPageContentListComponentImpactsResponseBody> {
        const response = await this.customerPageContentListComponentImpactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ListIncidents Customer Page Content
     */
    async customerPageContentListIncidentsRaw(requestParameters: CustomerPageContentListIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPageContentListIncidentsResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentListIncidents.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentListIncidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.startAt !== undefined) {
            queryParameters['start_at'] = requestParameters.startAt;
        }

        if (requestParameters.endAt !== undefined) {
            queryParameters['end_at'] = requestParameters.endAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/incidents`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageContentListIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * ListIncidents Customer Page Content
     */
    async customerPageContentListIncidents(requestParameters: CustomerPageContentListIncidentsRequest, initOverrides?: RequestInit): Promise<CustomerPageContentListIncidentsResponseBody> {
        const response = await this.customerPageContentListIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * RefreshAuthToken Customer Page Content
     */
    async customerPageContentRefreshAuthTokenRaw(requestParameters: CustomerPageContentRefreshAuthTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPageContentRefreshAuthTokenResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentRefreshAuthToken.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentRefreshAuthToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/refresh_auth_token`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageContentRefreshAuthTokenResponseBodyFromJSON(jsonValue));
    }

    /**
     * RefreshAuthToken Customer Page Content
     */
    async customerPageContentRefreshAuthToken(requestParameters: CustomerPageContentRefreshAuthTokenRequest, initOverrides?: RequestInit): Promise<CustomerPageContentRefreshAuthTokenResponseBody> {
        const response = await this.customerPageContentRefreshAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ShowIncident Customer Page Content
     */
    async customerPageContentShowIncidentRaw(requestParameters: CustomerPageContentShowIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPageContentShowIncidentResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentShowIncident.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentShowIncident.');
        }

        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling customerPageContentShowIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/incidents/{incident_id}`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))).replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageContentShowIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * ShowIncident Customer Page Content
     */
    async customerPageContentShowIncident(requestParameters: CustomerPageContentShowIncidentRequest, initOverrides?: RequestInit): Promise<CustomerPageContentShowIncidentResponseBody> {
        const response = await this.customerPageContentShowIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ShowPage Customer Page Content
     */
    async customerPageContentShowPageRaw(requestParameters: CustomerPageContentShowPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPageContentShowPageResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentShowPage.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentShowPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageContentShowPageResponseBodyFromJSON(jsonValue));
    }

    /**
     * ShowPage Customer Page Content
     */
    async customerPageContentShowPage(requestParameters: CustomerPageContentShowPageRequest, initOverrides?: RequestInit): Promise<CustomerPageContentShowPageResponseBody> {
        const response = await this.customerPageContentShowPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Subscribe Customer Page Content
     */
    async customerPageContentSubscribeRaw(requestParameters: CustomerPageContentSubscribeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentSubscribe.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentSubscribe.');
        }

        if (requestParameters.subscribeRequestBody === null || requestParameters.subscribeRequestBody === undefined) {
            throw new runtime.RequiredError('subscribeRequestBody','Required parameter requestParameters.subscribeRequestBody was null or undefined when calling customerPageContentSubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/actions/subscribe`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerPageContentSubscribeRequestBodyToJSON(requestParameters.subscribeRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Subscribe Customer Page Content
     */
    async customerPageContentSubscribe(requestParameters: CustomerPageContentSubscribeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerPageContentSubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * Tracks a view of a customer page
     * TrackCustomerPageView Customer Page Content
     */
    async customerPageContentTrackCustomerPageViewRaw(requestParameters: CustomerPageContentTrackCustomerPageViewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentTrackCustomerPageView.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentTrackCustomerPageView.');
        }

        if (requestParameters.trackCustomerPageViewRequestBody === null || requestParameters.trackCustomerPageViewRequestBody === undefined) {
            throw new runtime.RequiredError('trackCustomerPageViewRequestBody','Required parameter requestParameters.trackCustomerPageViewRequestBody was null or undefined when calling customerPageContentTrackCustomerPageView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/track_status_page_view`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerPageContentTrackCustomerPageViewRequestBodyToJSON(requestParameters.trackCustomerPageViewRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Tracks a view of a customer page
     * TrackCustomerPageView Customer Page Content
     */
    async customerPageContentTrackCustomerPageView(requestParameters: CustomerPageContentTrackCustomerPageViewRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerPageContentTrackCustomerPageViewRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribe Customer Page Content
     */
    async customerPageContentUnsubscribeRaw(requestParameters: CustomerPageContentUnsubscribeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentUnsubscribe.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentUnsubscribe.');
        }

        if (requestParameters.unsubscribeRequestBody === null || requestParameters.unsubscribeRequestBody === undefined) {
            throw new runtime.RequiredError('unsubscribeRequestBody','Required parameter requestParameters.unsubscribeRequestBody was null or undefined when calling customerPageContentUnsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/actions/unsubscribe`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerPageContentUnsubscribeRequestBodyToJSON(requestParameters.unsubscribeRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe Customer Page Content
     */
    async customerPageContentUnsubscribe(requestParameters: CustomerPageContentUnsubscribeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerPageContentUnsubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * VerifySubscription Customer Page Content
     */
    async customerPageContentVerifySubscriptionRaw(requestParameters: CustomerPageContentVerifySubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPageContentVerifySubscriptionResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentVerifySubscription.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentVerifySubscription.');
        }

        if (requestParameters.verifySubscriptionRequestBody === null || requestParameters.verifySubscriptionRequestBody === undefined) {
            throw new runtime.RequiredError('verifySubscriptionRequestBody','Required parameter requestParameters.verifySubscriptionRequestBody was null or undefined when calling customerPageContentVerifySubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/customer_status_page_content/{main_slug}/{subpage_slug}/actions/verify_subscription`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerPageContentVerifySubscriptionRequestBodyToJSON(requestParameters.verifySubscriptionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageContentVerifySubscriptionResponseBodyFromJSON(jsonValue));
    }

    /**
     * VerifySubscription Customer Page Content
     */
    async customerPageContentVerifySubscription(requestParameters: CustomerPageContentVerifySubscriptionRequest, initOverrides?: RequestInit): Promise<CustomerPageContentVerifySubscriptionResponseBody> {
        const response = await this.customerPageContentVerifySubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
