/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TwilioVoiceStatusPayload
 */
export interface TwilioVoiceStatusPayload {
    /**
     * The duration of the call in seconds
     * @type {string}
     * @memberof TwilioVoiceStatusPayload
     */
    CallDuration?: string;
    /**
     * Twilio's unique ID of the call
     * @type {string}
     * @memberof TwilioVoiceStatusPayload
     */
    CallSid: string;
    /**
     * The status of the call
     * @type {string}
     * @memberof TwilioVoiceStatusPayload
     */
    CallStatus: TwilioVoiceStatusPayloadCallStatusEnum;
    /**
     * The sequence number of the call
     * @type {string}
     * @memberof TwilioVoiceStatusPayload
     */
    SequenceNumber?: string;
    /**
     * The SIP response code of the call
     * @type {string}
     * @memberof TwilioVoiceStatusPayload
     */
    SipResponseCode?: string;
    /**
     * The timestamp of the call status, as UTC in RFC 2822 format
     * @type {string}
     * @memberof TwilioVoiceStatusPayload
     */
    Timestamp: string;
}

/**
* @export
* @enum {string}
*/
export enum TwilioVoiceStatusPayloadCallStatusEnum {
    Queued = 'queued',
    Ringing = 'ringing',
    Initiated = 'initiated',
    InProgress = 'in-progress',
    Completed = 'completed',
    Busy = 'busy',
    Failed = 'failed',
    NoAnswer = 'no-answer'
}

export function TwilioVoiceStatusPayloadFromJSON(json: any): TwilioVoiceStatusPayload {
    return TwilioVoiceStatusPayloadFromJSONTyped(json, false);
}

export function TwilioVoiceStatusPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): TwilioVoiceStatusPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CallDuration': !exists(json, 'CallDuration') ? undefined : json['CallDuration'],
        'CallSid': json['CallSid'],
        'CallStatus': json['CallStatus'],
        'SequenceNumber': !exists(json, 'SequenceNumber') ? undefined : json['SequenceNumber'],
        'SipResponseCode': !exists(json, 'SipResponseCode') ? undefined : json['SipResponseCode'],
        'Timestamp': json['Timestamp'],
    };
}

export function TwilioVoiceStatusPayloadToJSON(value?: TwilioVoiceStatusPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CallDuration': value.CallDuration,
        'CallSid': value.CallSid,
        'CallStatus': value.CallStatus,
        'SequenceNumber': value.SequenceNumber,
        'SipResponseCode': value.SipResponseCode,
        'Timestamp': value.Timestamp,
    };
}

