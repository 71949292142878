/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalUser,
    ExternalUserFromJSON,
    ExternalUserFromJSONTyped,
    ExternalUserToJSON,
} from './ExternalUser';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UserLink
 */
export interface UserLink {
    /**
     * The ID of the catalog entry associated to this link
     * @type {string}
     * @memberof UserLink
     */
    catalog_entry_id?: string;
    /**
     * The type of catalog entry that this user link is for
     * @type {string}
     * @memberof UserLink
     */
    catalog_type?: string;
    /**
     * The status of the connection
     * @type {string}
     * @memberof UserLink
     */
    confirmation_status: UserLinkConfirmationStatusEnum;
    /**
     * Whether this link can be manually configured. This will be false only if the link could be used to log someone in (e.g. a Slack human account or a SAML/SCIM account).
     * @type {boolean}
     * @memberof UserLink
     */
    editable: boolean;
    /**
     * 
     * @type {ExternalUser}
     * @memberof UserLink
     */
    external_user: ExternalUser;
    /**
     * Unique ID for this connection
     * @type {string}
     * @memberof UserLink
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof UserLink
     */
    user: User;
}

/**
* @export
* @enum {string}
*/
export enum UserLinkConfirmationStatusEnum {
    NotLinked = 'not_linked',
    Linked = 'linked'
}

export function UserLinkFromJSON(json: any): UserLink {
    return UserLinkFromJSONTyped(json, false);
}

export function UserLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry_id': !exists(json, 'catalog_entry_id') ? undefined : json['catalog_entry_id'],
        'catalog_type': !exists(json, 'catalog_type') ? undefined : json['catalog_type'],
        'confirmation_status': json['confirmation_status'],
        'editable': json['editable'],
        'external_user': ExternalUserFromJSON(json['external_user']),
        'id': json['id'],
        'user': UserFromJSON(json['user']),
    };
}

export function UserLinkToJSON(value?: UserLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry_id': value.catalog_entry_id,
        'catalog_type': value.catalog_type,
        'confirmation_status': value.confirmation_status,
        'editable': value.editable,
        'external_user': ExternalUserToJSON(value.external_user),
        'id': value.id,
        'user': UserToJSON(value.user),
    };
}

