import { EscalationStatusEnum } from "@incident-io/api";
import { Badge, BadgeTheme } from "@incident-ui";
import _ from "lodash";

export const EscalationStatusBadge = ({
  status,
}: {
  status: EscalationStatusEnum;
}) => {
  switch (status) {
    case EscalationStatusEnum.Pending:
      return <Badge theme={BadgeTheme.Info}>Pending</Badge>;
    case EscalationStatusEnum.Triggered:
      return (
        <Badge
          theme={BadgeTheme.Warning}
          className="!bg-red-surface !text-red-content"
        >
          Triggered
        </Badge>
      );
    case EscalationStatusEnum.Acked:
    case EscalationStatusEnum.Resolved:
      return <Badge theme={BadgeTheme.Success}>Acknowledged</Badge>;
    case EscalationStatusEnum.Expired:
      return <Badge theme={BadgeTheme.Tertiary}>Expired</Badge>;
    case EscalationStatusEnum.Cancelled:
      return <Badge theme={BadgeTheme.Tertiary}>Cancelled</Badge>;
  }

  return <Badge theme={BadgeTheme.Tertiary}>{_.startCase(status)}</Badge>;
};

export const LiveCallEscalationStatusBadge = ({
  status,
}: {
  status: EscalationStatusEnum;
}) => {
  switch (status) {
    case EscalationStatusEnum.Pending:
      return <Badge theme={BadgeTheme.Info}>Pending</Badge>;
    case EscalationStatusEnum.Triggered:
      return (
        <Badge
          theme={BadgeTheme.Warning}
          className="!bg-red-surface !text-red-content"
        >
          Ringing
        </Badge>
      );
    case EscalationStatusEnum.Acked:
    case EscalationStatusEnum.Resolved:
      return <Badge theme={BadgeTheme.Success}>Answered</Badge>;
    case EscalationStatusEnum.Expired:
      return <Badge theme={BadgeTheme.Tertiary}>Unanswered</Badge>;
    case EscalationStatusEnum.Cancelled:
      return <Badge theme={BadgeTheme.Tertiary}>Cancelled</Badge>;
  }

  return <Badge theme={BadgeTheme.Tertiary}>{_.startCase(status)}</Badge>;
};
