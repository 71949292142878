import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { formatLoginWithRedirectURL } from "@incident-shared/utils/loginWithRedirect";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import React from "react";
import { Navigate, useLocation } from "react-router";
import {
  TrialStatusPlanNameEnum as PlanNameEnum,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";

import { SetupLogin } from "../SetupLogin";

// SetupLogin is the explanation and CTA for someone hitting the setup flow to
// log in (i.e. oauth using Slack to tell us who they are). If they log in, and
// we recognise that the org connected to that slack team is setup already,
// we'll send them to the dashboard.
export const SetupLoginSlack = (): React.ReactElement => {
  const isAuthenticated = useIsAuthenticated();
  const { search } = useLocation();

  const queryParams = useQueryParams();
  const queryPlan = queryParams.get("plan_name");
  const plan =
    queryPlan && Object.values(PlanNameEnum).includes(queryPlan as PlanNameEnum)
      ? (queryPlan as PlanNameEnum)
      : undefined;

  const loginURL = formatLoginWithRedirectURL({
    returnPath: "/setup",
    plan,
  });

  if (isAuthenticated) {
    return <OrgAwareNavigate to={"/setup"} />;
  }

  if (queryParams.get("comms_platform") === "msteams") {
    return <Navigate to={"/setup-msteams/login" + search} />;
  }

  return (
    <SetupLogin
      commsPlatform="slack"
      loginButton={
        <Button
          analyticsTrackingId="login-with-slack"
          href={loginURL}
          className="inline-flex items-center cursor-pointer hover:bg-surface-tertiary"
          icon={IconEnum.Slack}
          theme={ButtonTheme.Secondary}
        >
          Sign in with Slack
        </Button>
      }
    />
  );
};
