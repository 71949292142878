import { useRevalidate } from "src/utils/use-revalidate";

export const useRefetchFollowups: () => () => void = () => {
  /* When editing, deleting, or completing a followup, there's lots of things
   * that can change, E.g. policy violations, followup statistics, and
   * followups by both user and incident.
   */
  return useRevalidate([
    "followUpsStatistics",
    "followUpsList",
    "followUpsListSuggestedFollowUps",
    "policiesListViolations",
    "policiesListViolationsForUser",
  ]);
};
