/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionAssigneeChange,
    ActionAssigneeChangeFromJSON,
    ActionAssigneeChangeFromJSONTyped,
    ActionAssigneeChangeToJSON,
} from './ActionAssigneeChange';
import {
    ActionDescriptionChange,
    ActionDescriptionChangeFromJSON,
    ActionDescriptionChangeFromJSONTyped,
    ActionDescriptionChangeToJSON,
} from './ActionDescriptionChange';
import {
    ActionStatusChange,
    ActionStatusChangeFromJSON,
    ActionStatusChangeFromJSONTyped,
    ActionStatusChangeToJSON,
} from './ActionStatusChange';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    EnrichedAction,
    EnrichedActionFromJSON,
    EnrichedActionFromJSONTyped,
    EnrichedActionToJSON,
} from './EnrichedAction';

/**
 * 
 * @export
 * @interface ActionUpdated
 */
export interface ActionUpdated {
    /**
     * 
     * @type {EnrichedAction}
     * @memberof ActionUpdated
     */
    action: EnrichedAction;
    /**
     * 
     * @type {ActionAssigneeChange}
     * @memberof ActionUpdated
     */
    assignee_change?: ActionAssigneeChange;
    /**
     * 
     * @type {ActionDescriptionChange}
     * @memberof ActionUpdated
     */
    description_change?: ActionDescriptionChange;
    /**
     * 
     * @type {ActionStatusChange}
     * @memberof ActionUpdated
     */
    status_change?: ActionStatusChange;
    /**
     * 
     * @type {Actor}
     * @memberof ActionUpdated
     */
    updater: Actor;
}

export function ActionUpdatedFromJSON(json: any): ActionUpdated {
    return ActionUpdatedFromJSONTyped(json, false);
}

export function ActionUpdatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionUpdated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': EnrichedActionFromJSON(json['action']),
        'assignee_change': !exists(json, 'assignee_change') ? undefined : ActionAssigneeChangeFromJSON(json['assignee_change']),
        'description_change': !exists(json, 'description_change') ? undefined : ActionDescriptionChangeFromJSON(json['description_change']),
        'status_change': !exists(json, 'status_change') ? undefined : ActionStatusChangeFromJSON(json['status_change']),
        'updater': ActorFromJSON(json['updater']),
    };
}

export function ActionUpdatedToJSON(value?: ActionUpdated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': EnrichedActionToJSON(value.action),
        'assignee_change': ActionAssigneeChangeToJSON(value.assignee_change),
        'description_change': ActionDescriptionChangeToJSON(value.description_change),
        'status_change': ActionStatusChangeToJSON(value.status_change),
        'updater': ActorToJSON(value.updater),
    };
}

