import { CatalogConfig, CatalogType } from "@incident-io/api";
import { CatalogSetupWidgetProps } from "@incident-shared/catalog/CatalogSetupWidget";
import { captureException } from "@sentry/react";
import { CatalogSetupRoutes } from "src/components/catalog/CatalogRoute";
import { useAPI } from "src/utils/swr";

import { useProductAccess } from "../../../../hooks/useProductAccess";

export const useCatalogSetup = (): {
  props?: CatalogSetupWidgetProps;
  catalogTypes: CatalogType[];
  catalogConfig: CatalogConfig;
} | null => {
  const {
    data: catalogConfigData,
    isLoading: catalogConfigLoading,
    error: catalogConfigError,
  } = useAPI(
    "catalogGetCatalogConfig",
    {},
    {
      revalidateOnMount: true,
    },
  );

  const {
    data: catalogTypesData,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {});

  const { hasOnCall } = useProductAccess();

  if (
    !catalogConfigData ||
    catalogConfigLoading ||
    !catalogTypesData ||
    catalogTypesLoading
  ) {
    return null;
  }

  if (catalogTypesError || catalogConfigError) {
    captureException(catalogTypesError || catalogConfigError, {
      tags: { component: "CatalogSetupWidget" },
    });
    return null;
  }

  const hasCustomCatalogTypes = catalogTypesData.catalog_types.some((type) =>
    type.type_name.startsWith("Custom[\\"),
  );

  if (hasCustomCatalogTypes) {
    return {
      props: {
        title: "Add teams to route alerts",
        description:
          "Add your teams to Catalog so alerts can be tagged with their owner. Configure the way your alerts are routed to teams, by linking teams to services or features.",
        fixButton: {
          href: `/catalog/${CatalogSetupRoutes.TeamWizard}`,
          title: "Set up teams",
        },
      },
      catalogTypes: catalogTypesData.catalog_types,
      catalogConfig: catalogConfigData.config,
    };
  }

  if (catalogConfigData.config.team_types.length === 0 && hasOnCall) {
    return {
      props: {
        title: "Add teams to route alerts",
        description:
          "It looks like you don’t have teams in your Catalog. We suggest adding teams so you can route alerts to the right escalation path.",
        fixButton: {
          href: `/catalog/${CatalogSetupRoutes.TeamWizard}`,
          title: "Set up teams",
        },
      },
      catalogTypes: catalogTypesData.catalog_types,
      catalogConfig: catalogConfigData.config,
    };
  }

  if (catalogConfigData.config.escalation_types.length === 0 && hasOnCall) {
    return {
      props: {
        title: "Connect teams to escalation paths",
        description:
          "Your Catalog has teams but they don’t link to escalation paths. We recommend tagging each team with an escalation path so that alert routes know where to send alerts.",
        fixButton: {
          href: `/catalog/${catalogConfigData.config.team_types[0].catalog_type_id}/edit`,
          title: "Link escalation paths",
        },
      },
      catalogTypes: catalogTypesData.catalog_types,
      catalogConfig: catalogConfigData.config,
    };
  }

  return {
    catalogTypes: catalogTypesData.catalog_types,
    catalogConfig: catalogConfigData.config,
  };
};
