import { Product } from "@incident-shared/billing";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
  Loader,
  Markdown,
  ModalFooter,
} from "@incident-ui";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentType,
  IncidentTypesDestroyRequest,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSortableList } from "../../SettingsSortableList";

export const IncidentTypesList = ({
  types,
  loading,
}: {
  types: IncidentType[];
  loading: boolean;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  const canEdit = hasScope(ScopeNameEnum.IncidentTypesEdit);

  const { trigger: updateRanks, isMutating: updatingRanks } = useAPIMutation(
    "incidentTypesList",
    undefined,
    async (apiClient, updatedTypes: IncidentType[]) => {
      const rank_updates = types.map((type) => {
        let rank = type.rank;
        const updated = updatedTypes.find((x) => x.id === type.id);
        if (updated) {
          rank = updated.rank;
        }
        return {
          resource_id: type.id,
          rank,
        };
      });

      return await apiClient.incidentTypesUpdateRanks({
        updateRanksRequestBody: { rank_updates },
      });
    },
  );

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "incidentTypesList",
    undefined,
    async (apiClient, data: IncidentTypesDestroyRequest) => {
      await apiClient.incidentTypesDestroy(data);
    },
  );

  const saving = updatingRanks || deleting;

  if (loading) {
    return <Loader />;
  }

  return (
    <SettingsSortableList
      canEdit={canEdit}
      updateItemRanks={updateRanks}
      saving={saving}
      items={_.sortBy(types, (type) => type.rank)}
      renderItem={(incidentType) => (
        <IncidentTypeRow
          types={types}
          type={incidentType}
          onDelete={() => onDelete({ id: incidentType.id })}
        />
      )}
    />
  );
};

const IncidentTypeRow = ({
  types,
  type,
  onDelete,
}: {
  types: IncidentType[];
  type: IncidentType;
  onDelete: () => void;
}) => {
  return (
    <SettingsListItem
      icon={IconEnum.IncidentType}
      title={type.name}
      badgeNode={type.is_default ? <DefaultBadge types={types} /> : null}
      description={
        <Markdown className="text-content-tertiary text-xs">
          {type.description}
        </Markdown>
      }
      buttons={{
        requiredProduct: Product.Response,
        requiredScope: ScopeNameEnum.IncidentTypesEdit,
        edit: {
          editHref: `/settings/incident-types/${type.id}/edit`,
        },
        delete: {
          resourceTitle: type.name,
          onDelete,
          deleteConfirmationTitle: "Delete incident type",
          isGatedText: type.is_default
            ? "You cannot delete the default Incident Type"
            : undefined,
          deleteConfirmationContent: (
            <>
              Are you sure you want to delete the{" "}
              <span className="font-bold">{type.name}</span> Incident Type?
            </>
          ),
          fetchDependentResources: [
            {
              resource_type: "IncidentType",
              id: type.id,
            },
          ],
        },
      }}
    />
  );
};

type DefaultFormData = {
  id: string;
};

const SetDefaultModal = ({
  onClose,
  types,
}: {
  onClose: () => void;
  types: IncidentType[];
}): React.ReactElement => {
  const formMethods = useForm<DefaultFormData>({
    defaultValues: { id: types.find((type) => type.is_default)?.id },
  });

  const { setError } = formMethods;

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "incidentTypesList",
    undefined,
    async (apiClient, { id }: DefaultFormData) => {
      await apiClient.incidentTypesSetDefault({ id });
    },
    {
      setError,
      onSuccess: onClose,
    },
  );

  const options: RadioButtonGroupOption[] = types.map((type) => ({
    id: type.id,
    label: type.name,
    value: type.id,
  }));

  return (
    <Form.Modal
      analyticsTrackingId={"incident-types-set-default-modal"}
      title={"Change default incident type"}
      className="!my-48"
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          cancelButtonText={"Cancel"}
          onClose={onClose}
          saving={saving}
        />
      }
    >
      <Form.InputWrapper
        label={"Please select your default type"}
        name="id"
        hideErrors
        helptext={
          <>
            The default incident type will be pre-selected in the{" "}
            <code>/incident</code> form.
          </>
        }
      >
        <RadioButtonGroupV2
          name="id"
          options={options}
          srLabel="incident type"
          formMethods={formMethods}
        />
      </Form.InputWrapper>
    </Form.Modal>
  );
};

const DefaultBadge = ({
  types,
}: {
  types: IncidentType[];
}): React.ReactElement | null => {
  const [defaultModalOpen, setDefaultModalOpen] = useState(false);

  // If there's only one type, you can't change the default, and there's not a
  // lot of point in showing that it's the default.
  if (types.length < 2) {
    return null;
  }

  return (
    <>
      {defaultModalOpen && (
        <SetDefaultModal
          onClose={() => setDefaultModalOpen(false)}
          types={types}
        />
      )}
      <Badge className="ml-2" theme={BadgeTheme.Tertiary}>
        Default{" "}
        <Button
          title="Change default"
          analyticsTrackingId={"incident-types-change-default"}
          theme={ButtonTheme.Naked}
          icon={IconEnum.Cog}
          onClick={() => setDefaultModalOpen(true)}
        />
      </Badge>
    </>
  );
};
