import { GitLabConfig } from "@incident-io/api";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Heading,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import React from "react";
import { useIntercom } from "react-use-intercom";
import { useIdentity } from "src/contexts/IdentityContext";
import { DurationOptions, formatDurationShort } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";
import { useNow } from "src/utils/use-now";

import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const GitLabConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { data, isLoading, error } = useAPI(
    "integrationsGitLabShow",
    undefined,
  );

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (!data || isLoading) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      {data.git_lab_config && <ConfigInner config={data.git_lab_config} />}
    </GenericConfigureDrawerContents>
  );
};

const ConfigInner = ({ config }: { config: GitLabConfig }) => {
  const { showArticle } = useIntercom();
  const { identity } = useIdentity();

  return (
    <>
      <div className="space-y-2">
        <Heading level={3}>Configure webhooks</Heading>
        <p>
          Sync changes in GitLab back to incident.io faster using webhooks.
          Follow{" "}
          <Button
            theme={ButtonTheme.Naked}
            analyticsTrackingId="gitlab-webhook-guide"
            onClick={() => showArticle(9809180)}
          >
            our guide to configuring a GitLab Group Hook
          </Button>
          . Your secret token is:
        </p>
        <CodeBlock
          hideHeader
          hideLineNumbers
          code={identity.organisation_id}
          className="max-w-72"
        />

        {config.last_webhook_received_at ? (
          <Callout theme={CalloutTheme.Success}>
            We last received a webhook from GitLab around{" "}
            <RelativeTime
              time={config.last_webhook_received_at}
              opts={{
                significantFigures: 1,
                suffix: " ago",
              }}
            />
            .
          </Callout>
        ) : (
          <Callout theme={CalloutTheme.Warning}>
            We haven&rsquo;t received any webhooks from GitLab yet. Changes to
            exported follow-ups and attached merge requests won&rsquo;t be
            reflected in incident.io.
          </Callout>
        )}
      </div>
    </>
  );
};

const RelativeTime = ({
  time,
  opts,
}: {
  time: Date;
  opts: DurationOptions;
}) => {
  const now = useNow();

  return <>{formatDurationShort(time, now.toJSDate(), opts)}</>;
};
