/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DecisionFlow,
    DecisionFlowFromJSON,
    DecisionFlowFromJSONTyped,
    DecisionFlowToJSON,
} from './DecisionFlow';

/**
 * 
 * @export
 * @interface DecisionFlowsUpdateResponseBody
 */
export interface DecisionFlowsUpdateResponseBody {
    /**
     * 
     * @type {DecisionFlow}
     * @memberof DecisionFlowsUpdateResponseBody
     */
    decision_flow: DecisionFlow;
}

export function DecisionFlowsUpdateResponseBodyFromJSON(json: any): DecisionFlowsUpdateResponseBody {
    return DecisionFlowsUpdateResponseBodyFromJSONTyped(json, false);
}

export function DecisionFlowsUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionFlowsUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decision_flow': DecisionFlowFromJSON(json['decision_flow']),
    };
}

export function DecisionFlowsUpdateResponseBodyToJSON(value?: DecisionFlowsUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decision_flow': DecisionFlowToJSON(value.decision_flow),
    };
}

