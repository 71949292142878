import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, IconEnum, Input, ToastTheme } from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/react";
import { useIntercom } from "react-use-intercom";
import { IntegrationFeature } from "src/components/settings/integrations/common/AboutThisIntegration";
import { IntegrationCategory } from "src/components/settings/integrations/common/categories";
import { AlertSourceDrawer } from "src/components/settings/integrations/list/AlertSourceConfigDrawer";
import {
  SCIMAndSamlIntegrationDrawer,
  SCIMAndSamlIntegrationDrawerContents,
} from "src/components/settings/integrations/list/scim-and-saml/SCIMAndSamlIntegrationDrawer";
import { useIdentity } from "src/contexts/IdentityContext";

import {
  ALERT_SOURCE_TYPE_CONFIGS,
  INTEGRATION_CONFIGS,
  IntegrationConfig,
  IntegrationListProvider,
  SCIM_PROVIDER_CONFIGS,
  SCIMProviderEnum,
} from "./IntegrationConfig";

export const IntegrationConfigFor = (
  provider: IntegrationListProvider,
  forTeam = false,
): IntegrationConfig => {
  const integrationConfig = INTEGRATION_CONFIGS[provider];
  const alertSourceConfig = ALERT_SOURCE_TYPE_CONFIGS[provider];
  const scimProviderConfig = SCIM_PROVIDER_CONFIGS[provider];

  if (integrationConfig) {
    return integrationConfig;
  }

  // Now try an alert source
  if (alertSourceConfig) {
    return {
      label: alertSourceConfig.label,
      categories: [IntegrationCategory.AlertSource],
      description: `Receive alerts from ${alertSourceConfig.label} to create escalations and incidents.`,
      helpcenterArticleID: 5948111,
      icon: alertSourceConfig.icon,
      hexColor: alertSourceConfig.hexColor,
      features: [
        IntegrationFeature.AlertSource,
        IntegrationFeature.AlertAttributes,
      ],
      CustomConnectCTAs: () => (
        <GatedButton
          icon={IconEnum.ExternalLink}
          className={"px-3 py-2 w-fit"}
          requiredScope={ScopeNameEnum.AlertSourceCreate}
          href={`/alerts/sources/create?source_type=${provider}`}
          analyticsTrackingId="alert-sources-create-from-integration"
          openInNewTab
        >
          Connect
        </GatedButton>
      ),
      CustomConfigureDrawer: AlertSourceDrawer,
    };
  }

  // Finally, maybe it's a scim provider.
  // This config should render differently in the context of setting up teams, vs
  // setting up the integration more generaly in settings.
  if (scimProviderConfig) {
    const articleID = 6991102;
    return {
      label: scimProviderConfig.label,
      categories: [IntegrationCategory.Compliance],
      description: forTeam
        ? `Bring your ${scimProviderConfig.teamName}s into incident.io Catalog`
        : `Connect ${scimProviderConfig.label} to incident.io with SCIM and/or SAML to manage your users and teams.`,
      CustomConfigureDrawer: SCIMAndSamlIntegrationDrawer,
      FeatureTeaser: forTeam
        ? undefined
        : () => (
            <SCIMAndSamlIntegrationDrawerContents
              provider={provider as SCIMProviderEnum}
            />
          ),
      CopyLink: forTeam
        ? (link: string) => (
            <CopyLinkSection
              providerLabel={scimProviderConfig.label}
              link={link}
              articleID={articleID}
            />
          )
        : undefined,
      helpcenterArticleID: articleID,
      disconnectWarning: <></>,
      onDisconnect: async () => {
        // noop
      },
      icon: scimProviderConfig.icon,
      hexColor: scimProviderConfig.hexColor,
      features: forTeam
        ? [
            IntegrationFeature.ManageTeamMembership,
            IntegrationFeature.ControlPermissions,
          ]
        : [
            IntegrationFeature.ControlPermissions,
            IntegrationFeature.ManageTeamMembership,
          ],
      hideDefaultCTAs: !forTeam,
    };
  }

  // If we get here, we have no idea what this provider is
  captureException(new Error(`No integration config for ${provider}`));
  return {
    label: "Unknown",
    categories: [],
    description: "Unknown",
    disconnectWarning: <></>,
    onDisconnect: async () => void 0,
    helpcenterArticleID: 0,
    icon: IconEnum.QuestionMark,
    features: [],
  };
};

export const drawerUrlFor = (provider: IntegrationListProvider): string => {
  const config = IntegrationConfigFor(provider);

  return `${config.urlAlias || provider}`;
};

export const reconnectModalUrlFor = (
  provider: IntegrationListProvider,
): string => {
  const modalUrl = drawerUrlFor(provider);

  return `${modalUrl}/reconnect`;
};

const CopyLinkSection = ({
  providerLabel,
  link,
  articleID,
}: {
  providerLabel: string;
  link: string;
  articleID: number;
}) => {
  const { identity } = useIdentity();
  const showToast = useToast();
  const { showArticle } = useIntercom();

  if (!identity.feature_gates.scim) {
    return <></>;
  }

  return (
    <div className="flex flex-col rounded-lg bg-surface-secondary p-4 gap-3">
      <span>
        To connect {providerLabel} with incident.io, send this URL to your
        system admin. They will need to select the relevant groups to sync with
        Catalog.{" "}
        <Button
          analyticsTrackingId="link-section-learn-more"
          onClick={() => showArticle(articleID)}
          theme={ButtonTheme.Naked}
          className="text-primary-500 text-sm-bold"
        >
          Learn more
        </Button>
      </span>
      <div className="flex gap-2">
        <Input
          id="workos-scim-link"
          value={link}
          readOnly
          className="flex-grow"
        />
        <Button
          analyticsTrackingId={"copy-workos-scim-link"}
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Copy}
          onClick={() => {
            navigator.clipboard.writeText(link);
            showToast({
              theme: ToastTheme.Info,
              title: "Copied to clipboard.",
              toastSide: ToastSideEnum.TopRight,
            });
          }}
        >
          <></>
        </Button>
      </div>
    </div>
  );
};
