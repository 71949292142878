import type { SVGProps } from "react";
import * as React from "react";
const SvgCheckmark = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm3.947 5.641c-1.859 1.382-3.435 3.29-4.683 5.669a.75.75 0 0 1-1.331-.005c-.575-1.117-1.218-2.025-1.965-2.776a.75.75 0 1 1 1.064-1.058 11.58 11.58 0 0 1 1.563 1.972c1.239-2.045 2.734-3.726 4.458-5.007a.75.75 0 0 1 .894 1.205Z"
    />
  </svg>
);
export default SvgCheckmark;
