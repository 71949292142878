import { GenericErrorMessage } from "@incident-ui/GenericErrorMessage/GenericErrorMessage";
import { ErrorBoundary } from "@sentry/react";
import { ElementType } from "react";
import { tcx } from "src/utils/tailwind-classes";

type Props = {
  children: React.ReactNode;
  className?: string;
  backgroundColor?: "white" | "grey";
  is?: ElementType;
};
export function ModalContent({
  children,
  className,
  backgroundColor = "white",
  is = "div",
}: Props): React.ReactElement {
  const Tag = is;
  return (
    <Tag
      className={tcx(
        "p-4 overflow-y-auto text-sm",
        {
          "bg-white": backgroundColor === "white",
          "bg-surface-secondary": backgroundColor === "grey",
        },
        className,
      )}
    >
      <ErrorBoundary fallback={<GenericErrorMessage />}>
        {children}
      </ErrorBoundary>
    </Tag>
  );
}
