/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraIssueTemplate,
    JiraIssueTemplateFromJSON,
    JiraIssueTemplateFromJSONTyped,
    JiraIssueTemplateToJSON,
} from './JiraIssueTemplate';

/**
 * 
 * @export
 * @interface IssueTrackersJiraGetIssueTemplateResponseBody
 */
export interface IssueTrackersJiraGetIssueTemplateResponseBody {
    /**
     * 
     * @type {JiraIssueTemplate}
     * @memberof IssueTrackersJiraGetIssueTemplateResponseBody
     */
    issue_template?: JiraIssueTemplate;
}

export function IssueTrackersJiraGetIssueTemplateResponseBodyFromJSON(json: any): IssueTrackersJiraGetIssueTemplateResponseBody {
    return IssueTrackersJiraGetIssueTemplateResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersJiraGetIssueTemplateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersJiraGetIssueTemplateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_template': !exists(json, 'issue_template') ? undefined : JiraIssueTemplateFromJSON(json['issue_template']),
    };
}

export function IssueTrackersJiraGetIssueTemplateResponseBodyToJSON(value?: IssueTrackersJiraGetIssueTemplateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_template': JiraIssueTemplateToJSON(value.issue_template),
    };
}

