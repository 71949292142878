import { Loader } from "src/components/@ui/Loader/Loader";

import { Modal } from "./Modal";

export function LoadingModal({
  isOpen = true,
  isExtraLarge,
  isXXL,
  title,
  onClose,
  suppressInitialAnimation,
}: {
  isOpen?: boolean;
  isExtraLarge?: boolean;
  isXXL?: boolean;
  title?: string;
  onClose: () => void;
  suppressInitialAnimation?: boolean;
}): React.ReactElement {
  return (
    <Modal
      analyticsTrackingId=""
      title={title || ""}
      onClose={onClose}
      disableQuickClose
      isOpen={isOpen}
      isExtraLarge={isExtraLarge}
      isXXL={isXXL}
      suppressInitialAnimation={suppressInitialAnimation}
    >
      <Loader />
    </Modal>
  );
}
