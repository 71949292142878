/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogEntryMatchPayload
 */
export interface CatalogEntryMatchPayload {
    /**
     * ID of the catalog entry to connect to
     * @type {string}
     * @memberof CatalogEntryMatchPayload
     */
    catalog_entry_id: string;
    /**
     * ID of the custom field option to connect to
     * @type {string}
     * @memberof CatalogEntryMatchPayload
     */
    custom_field_option_id: string;
}

export function CatalogEntryMatchPayloadFromJSON(json: any): CatalogEntryMatchPayload {
    return CatalogEntryMatchPayloadFromJSONTyped(json, false);
}

export function CatalogEntryMatchPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogEntryMatchPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry_id': json['catalog_entry_id'],
        'custom_field_option_id': json['custom_field_option_id'],
    };
}

export function CatalogEntryMatchPayloadToJSON(value?: CatalogEntryMatchPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry_id': value.catalog_entry_id,
        'custom_field_option_id': value.custom_field_option_id,
    };
}

