import {
  Incident,
  PostmortemTemplate,
  PostmortemTemplateWritingModeEnum,
} from "@incident-io/api";
import { GenericErrorMessage, LoadingBar } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useAPI } from "src/utils/swr";

import { useIncident } from "../hooks";
import { PostIncidentSection } from "../postincidentflow/PostIncidentSection";
import { usePostmortem } from "../postincidentflow/usePostmortem";
import { Postmortem } from "./Postmortem";
import { PostmortemFollowups } from "./PostmortemFollowups";
import { PostmortemHeader } from "./PostmortemHeader";
import { PostmortemTimeline } from "./PostmortemTimeline";

export const PostIncidentTab = ({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement => {
  const { incident, isLoading: incidentLoading } = useIncident(incidentId);

  const {
    data: { postmortem_templates: templates },
    isLoading: loadingTemplates,
    error: errorTemplates,
  } = useAPI(
    "postmortemsListTemplates",
    {
      incidentId: incidentId ?? undefined,
    },
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  // Be extra sure we don't show this content unless the feature flag is enabled.
  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();
  if (!featurePostmortemsInHouse) {
    return <></>;
  }

  if (errorTemplates) {
    return <GenericErrorMessage error={errorTemplates} />;
  }

  if (
    incidentId == null ||
    incident == null ||
    incidentLoading ||
    loadingTemplates
  ) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  return <PostIncidentTabInner incident={incident} templates={templates} />;
};

const PostIncidentTabInner = ({
  incident,
  templates,
}: {
  incident: Incident;
  templates: PostmortemTemplate[];
}): React.ReactElement => {
  const { availableDocument: exportedDocument } = usePostmortem(incident);
  const template = templates.find(
    (t) => t.id === incident.postmortem_template_id,
  );

  const onlyShowBasics =
    // if the template is external we hide everything
    template?.writing_mode === PostmortemTemplateWritingModeEnum.External ||
    // if the user hasn't made a decision we hide everything
    !incident.postmortem_template_id ||
    // if they've exported it already, we hide everything
    exportedDocument;

  return (
    <div className="mt-4 flex flex-col gap-4 box-border">
      <PostIncidentSection incident={incident} />
      <PostmortemHeader incident={incident} templates={templates} />
      {onlyShowBasics ? (
        <>
          <PostIncidentDivider />
          <PostmortemFollowups
            id="follow-ups"
            incident={incident}
            topLevelHeader
          />
          <PostIncidentDivider />
          <PostmortemTimeline
            id="timeline"
            incident={incident}
            topLevelHeader
          />
        </>
      ) : (
        <Postmortem
          incident={incident}
          // @ts-expect-error wehen there is no template id, we'll show the basics so this can never be undefined
          templateId={incident.postmortem_template_id}
        />
      )}
    </div>
  );
};

export const PostIncidentDivider = () => {
  return <hr className="w-full" />;
};
