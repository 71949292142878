/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SAMLDomain,
    SAMLDomainFromJSON,
    SAMLDomainFromJSONTyped,
    SAMLDomainToJSON,
} from './SAMLDomain';

/**
 * 
 * @export
 * @interface SAMLSetDomainsResponseBody
 */
export interface SAMLSetDomainsResponseBody {
    /**
     * 
     * @type {Array<SAMLDomain>}
     * @memberof SAMLSetDomainsResponseBody
     */
    saml_domains: Array<SAMLDomain>;
}

export function SAMLSetDomainsResponseBodyFromJSON(json: any): SAMLSetDomainsResponseBody {
    return SAMLSetDomainsResponseBodyFromJSONTyped(json, false);
}

export function SAMLSetDomainsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLSetDomainsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saml_domains': ((json['saml_domains'] as Array<any>).map(SAMLDomainFromJSON)),
    };
}

export function SAMLSetDomainsResponseBodyToJSON(value?: SAMLSetDomainsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saml_domains': ((value.saml_domains as Array<any>).map(SAMLDomainToJSON)),
    };
}

