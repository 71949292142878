import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Card } from "@incident-ui";
import React from "react";
import { IncidentFormFormTypeEnum as FormTypeEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { FORM_TYPE_CONFIG } from "../common/config";

export const IncidentFormTypeCard = ({
  formType,
  isSelected,
  onClick,
}: {
  formType: FormTypeEnum;
  isSelected: boolean;
  onClick: () => void;
}): React.ReactElement => {
  const config = FORM_TYPE_CONFIG[formType];
  return (
    <button onClick={onClick} type="button">
      <Card
        className={tcx(isSelected ? "border-stroke-hover shadow-md" : "")}
        icon={config.icon}
        title={config.label}
        description={config.description}
        color={ColorPaletteEnum.Blue}
      />
    </button>
  );
};
