/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormLifecycleElementBinding
 */
export interface FormLifecycleElementBinding {
    /**
     * If the binding is an array param, an array of strings representing the resources that are bound to it
     * @type {Array<string>}
     * @memberof FormLifecycleElementBinding
     */
    array_value?: Array<string>;
    /**
     * If the binding is a scalar param, a string representing the resource that is bound to it
     * @type {string}
     * @memberof FormLifecycleElementBinding
     */
    value?: string;
}

export function FormLifecycleElementBindingFromJSON(json: any): FormLifecycleElementBinding {
    return FormLifecycleElementBindingFromJSONTyped(json, false);
}

export function FormLifecycleElementBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormLifecycleElementBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array_value': !exists(json, 'array_value') ? undefined : json['array_value'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function FormLifecycleElementBindingToJSON(value?: FormLifecycleElementBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array_value': value.array_value,
        'value': value.value,
    };
}

