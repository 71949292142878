/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DecisionFlowsCreateRequestBody,
    DecisionFlowsCreateRequestBodyFromJSON,
    DecisionFlowsCreateRequestBodyToJSON,
    DecisionFlowsCreateResponseBody,
    DecisionFlowsCreateResponseBodyFromJSON,
    DecisionFlowsCreateResponseBodyToJSON,
    DecisionFlowsListResponseBody,
    DecisionFlowsListResponseBodyFromJSON,
    DecisionFlowsListResponseBodyToJSON,
    DecisionFlowsShowResponseBody,
    DecisionFlowsShowResponseBodyFromJSON,
    DecisionFlowsShowResponseBodyToJSON,
    DecisionFlowsUpdateRequestBody,
    DecisionFlowsUpdateRequestBodyFromJSON,
    DecisionFlowsUpdateRequestBodyToJSON,
    DecisionFlowsUpdateResponseBody,
    DecisionFlowsUpdateResponseBodyFromJSON,
    DecisionFlowsUpdateResponseBodyToJSON,
} from '../models';

export interface DecisionFlowsCreateRequest {
    createRequestBody: DecisionFlowsCreateRequestBody;
}

export interface DecisionFlowsDestroyRequest {
    id: string;
}

export interface DecisionFlowsShowRequest {
    id: string;
}

export interface DecisionFlowsUpdateRequest {
    id: string;
    updateRequestBody: DecisionFlowsUpdateRequestBody;
}

/**
 * 
 */
export class DecisionFlowsApi extends runtime.BaseAPI {

    /**
     * Create a Decision Flow
     * Create Decision Flows
     */
    async decisionFlowsCreateRaw(requestParameters: DecisionFlowsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DecisionFlowsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling decisionFlowsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/decision_flows`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DecisionFlowsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DecisionFlowsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Decision Flow
     * Create Decision Flows
     */
    async decisionFlowsCreate(requestParameters: DecisionFlowsCreateRequest, initOverrides?: RequestInit): Promise<DecisionFlowsCreateResponseBody> {
        const response = await this.decisionFlowsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing decision flow
     * Destroy Decision Flows
     */
    async decisionFlowsDestroyRaw(requestParameters: DecisionFlowsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling decisionFlowsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/decision_flows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing decision flow
     * Destroy Decision Flows
     */
    async decisionFlowsDestroy(requestParameters: DecisionFlowsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.decisionFlowsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Return list of decision flows
     * List Decision Flows
     */
    async decisionFlowsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<DecisionFlowsListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/decision_flows`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DecisionFlowsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * Return list of decision flows
     * List Decision Flows
     */
    async decisionFlowsList(initOverrides?: RequestInit): Promise<DecisionFlowsListResponseBody> {
        const response = await this.decisionFlowsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show an individual decision flow
     * Show Decision Flows
     */
    async decisionFlowsShowRaw(requestParameters: DecisionFlowsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DecisionFlowsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling decisionFlowsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/decision_flows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DecisionFlowsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an individual decision flow
     * Show Decision Flows
     */
    async decisionFlowsShow(requestParameters: DecisionFlowsShowRequest, initOverrides?: RequestInit): Promise<DecisionFlowsShowResponseBody> {
        const response = await this.decisionFlowsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing decision flow
     * Update Decision Flows
     */
    async decisionFlowsUpdateRaw(requestParameters: DecisionFlowsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DecisionFlowsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling decisionFlowsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling decisionFlowsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/decision_flows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DecisionFlowsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DecisionFlowsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing decision flow
     * Update Decision Flows
     */
    async decisionFlowsUpdate(requestParameters: DecisionFlowsUpdateRequest, initOverrides?: RequestInit): Promise<DecisionFlowsUpdateResponseBody> {
        const response = await this.decisionFlowsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
