import { UseFormReturn } from "react-hook-form";
import {
  EngineScope,
  ExpressionOperation,
  Reference,
  Resource,
} from "src/contexts/ClientContext";
import { lookupInScope } from "src/utils/scope";

import { CreateEditExpressionFormData } from "../AddEditExpressionModal";

export type ReferenceWithResource = {
  type: string;
  label: string;
  resultTypeLabel?: string;
  array: boolean;
  resource: Resource;
};

export const useGetPreviousReference = ({
  scope,
  resources,
  formMethods,
}: {
  scope: EngineScope;
  resources: Resource[];
  formMethods: UseFormReturn<CreateEditExpressionFormData>;
}) => {
  const [rootReference, operations] = formMethods.watch([
    "root_reference",
    "operations",
  ]);

  if (!rootReference) {
    return () => undefined;
  }

  return (operationIdx?: number) => {
    return getPreviousReference({
      scope,
      resources,
      rootReference,
      operations: operations || [],
      operationIdx,
    });
  };
};

export const getPreviousReference = ({
  scope,
  resources,
  rootReference,
  operations,
  operationIdx,
}: {
  scope: EngineScope;
  resources: Resource[];
  rootReference: string;
  operations: ExpressionOperation[];
  operationIdx?: number;
}): ReferenceWithResource => {
  let selectedRootReferenceObj: Reference | undefined = undefined;
  // Start with the root reference
  selectedRootReferenceObj = lookupInScope(scope, rootReference);

  if (!selectedRootReferenceObj) {
    throw new Error(
      `unreachable: failed to find root reference ${rootReference} in scope`,
    );
  }
  // Figure out what resource we are navigating *from*.
  // Start from the top (i.e. the root), then walk down the list
  // of operations, taking the results that we find, until we
  // get to our one.
  let { type: resourceType, array, label } = selectedRootReferenceObj;
  if (operations && operations.length > 0) {
    const maxOperations =
      operationIdx === undefined ? operations.length : operationIdx;
    // Loop through and continue to overwrite the result type until we get
    // stopped by our for loop (i.e. until we hit the one just before our idx)
    for (let i = 0; i < maxOperations; i++) {
      const operation = operations[i];
      if (operation.returns?.type) {
        resourceType = operation.returns.type;
        array = operation.returns.array;
      }
      // Operations don't have labels associated with them - for now we are
      // updating the label on a case-by-case basis when we use an operation that
      // changes the type.
      // In future, if we add more operations, the nice way to do this would be to
      // include a result_type_label on every operation instead.

      // When we navigate, the resource label changes (as the type changes).
      if (operation.navigate?.reference_label) {
        label = operation.navigate.reference_label;
      }
      // A count is always going to return a number
      if (operation.operation_type === "count") {
        label = "Number";
      }
    }
  }
  const resourceConfig = resources.find((x) => x.type === resourceType);

  return {
    type: resourceType,
    // If this happens we came from a Parse operation so we have no way of knowing whether our object will be an array.
    // This is decided by the toggle, but we should have a sensible default.
    array: array === undefined ? false : array,
    label,
    resource: resourceConfig as Resource,
    resultTypeLabel: resourceConfig?.type_label,
  };
};
