/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentTypeApplicableFields
 */
export interface IncidentTypeApplicableFields {
    /**
     * List of applicable custom field IDs
     * @type {Array<string>}
     * @memberof IncidentTypeApplicableFields
     */
    custom_fields: Array<string>;
    /**
     * a list of custom field IDs that are exclusive to this incident type.
     * @type {Array<string>}
     * @memberof IncidentTypeApplicableFields
     */
    exclusive_custom_fields?: Array<string>;
    /**
     * a list of role IDs that are exclusive to this incident type.
     * @type {Array<string>}
     * @memberof IncidentTypeApplicableFields
     */
    exclusive_roles?: Array<string>;
    /**
     * List of applicable role IDs
     * @type {Array<string>}
     * @memberof IncidentTypeApplicableFields
     */
    roles: Array<string>;
}

export function IncidentTypeApplicableFieldsFromJSON(json: any): IncidentTypeApplicableFields {
    return IncidentTypeApplicableFieldsFromJSONTyped(json, false);
}

export function IncidentTypeApplicableFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTypeApplicableFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_fields': json['custom_fields'],
        'exclusive_custom_fields': !exists(json, 'exclusive_custom_fields') ? undefined : json['exclusive_custom_fields'],
        'exclusive_roles': !exists(json, 'exclusive_roles') ? undefined : json['exclusive_roles'],
        'roles': json['roles'],
    };
}

export function IncidentTypeApplicableFieldsToJSON(value?: IncidentTypeApplicableFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_fields': value.custom_fields,
        'exclusive_custom_fields': value.exclusive_custom_fields,
        'exclusive_roles': value.exclusive_roles,
        'roles': value.roles,
    };
}

