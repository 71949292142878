/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageUpdateEmailBrandingRequestBody
 */
export interface StatusPageUpdateEmailBrandingRequestBody {
    /**
     * The GCS key where the email logo for this status page is stored
     * @type {string}
     * @memberof StatusPageUpdateEmailBrandingRequestBody
     */
    email_logo_key?: string;
    /**
     * Whether to use Light mode or Dark mode
     * @type {string}
     * @memberof StatusPageUpdateEmailBrandingRequestBody
     */
    theme: StatusPageUpdateEmailBrandingRequestBodyThemeEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageUpdateEmailBrandingRequestBodyThemeEnum {
    Light = 'light',
    Dark = 'dark'
}

export function StatusPageUpdateEmailBrandingRequestBodyFromJSON(json: any): StatusPageUpdateEmailBrandingRequestBody {
    return StatusPageUpdateEmailBrandingRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateEmailBrandingRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateEmailBrandingRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_logo_key': !exists(json, 'email_logo_key') ? undefined : json['email_logo_key'],
        'theme': json['theme'],
    };
}

export function StatusPageUpdateEmailBrandingRequestBodyToJSON(value?: StatusPageUpdateEmailBrandingRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_logo_key': value.email_logo_key,
        'theme': value.theme,
    };
}

