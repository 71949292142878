/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPath,
    EscalationPathFromJSON,
    EscalationPathFromJSONTyped,
    EscalationPathToJSON,
} from './EscalationPath';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';
import {
    ScheduleEntry,
    ScheduleEntryFromJSON,
    ScheduleEntryFromJSONTyped,
    ScheduleEntryToJSON,
} from './ScheduleEntry';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EscalationPathsListForUserResponseBody
 */
export interface EscalationPathsListForUserResponseBody {
    /**
     * 
     * @type {Array<EscalationPath>}
     * @memberof EscalationPathsListForUserResponseBody
     */
    escalation_paths: Array<EscalationPath>;
    /**
     * Where they are scheduled, these are the closest shifts for this user on each schedule relevant to the paths
     * @type {Array<ScheduleEntry>}
     * @memberof EscalationPathsListForUserResponseBody
     */
    next_shifts: Array<ScheduleEntry>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof EscalationPathsListForUserResponseBody
     */
    schedules: Array<Schedule>;
    /**
     * Users that are part of these escalation paths, either always or via a schedule
     * @type {Array<User>}
     * @memberof EscalationPathsListForUserResponseBody
     */
    users: Array<User>;
}

export function EscalationPathsListForUserResponseBodyFromJSON(json: any): EscalationPathsListForUserResponseBody {
    return EscalationPathsListForUserResponseBodyFromJSONTyped(json, false);
}

export function EscalationPathsListForUserResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsListForUserResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_paths': ((json['escalation_paths'] as Array<any>).map(EscalationPathFromJSON)),
        'next_shifts': ((json['next_shifts'] as Array<any>).map(ScheduleEntryFromJSON)),
        'schedules': ((json['schedules'] as Array<any>).map(ScheduleFromJSON)),
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function EscalationPathsListForUserResponseBodyToJSON(value?: EscalationPathsListForUserResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_paths': ((value.escalation_paths as Array<any>).map(EscalationPathToJSON)),
        'next_shifts': ((value.next_shifts as Array<any>).map(ScheduleEntryToJSON)),
        'schedules': ((value.schedules as Array<any>).map(ScheduleToJSON)),
        'users': ((value.users as Array<any>).map(UserToJSON)),
    };
}

