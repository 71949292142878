import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceChecklySetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>Log in to Checkly and navigate to Alert Settings.</p>
      <p>
        Click the Add more channels button, find Incident.io on the list, and
        click <em>Add channel</em>.
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Enter a name of your choosing for the alert channel, together with
            the URL, and the API key below.
          </>
        }
        blocks={[
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/checkly/${
              alertSourceConfig.id
            }`,
          },
          {
            title: "API key",
            code: `${alertSourceConfig.secret_token}`,
          },
        ]}
      />
      <p>
        Click <em>Test Incident.io alert</em>. If it&rsquo;s setup correctly,
        the alert will appear on the right hand side.
      </p>
      <p>
        Make sure that the right checks are subscribing to the channel and that
        the Send when rules are correctly set, then hit Save Incident.io
        Webhook.
      </p>
    </SetupInfoNumberedList>
  );
};
