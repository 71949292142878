/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowsCreateWorkflowRunRequestBody
 */
export interface WorkflowsCreateWorkflowRunRequestBody {
    /**
     * Map of top-level scope variables to their literal value
     * @type {{ [key: string]: string; }}
     * @memberof WorkflowsCreateWorkflowRunRequestBody
     */
    values?: { [key: string]: string; };
    /**
     * Unique identifier for the underlying workflow
     * @type {string}
     * @memberof WorkflowsCreateWorkflowRunRequestBody
     */
    workflow_id: string;
}

export function WorkflowsCreateWorkflowRunRequestBodyFromJSON(json: any): WorkflowsCreateWorkflowRunRequestBody {
    return WorkflowsCreateWorkflowRunRequestBodyFromJSONTyped(json, false);
}

export function WorkflowsCreateWorkflowRunRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsCreateWorkflowRunRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': !exists(json, 'values') ? undefined : json['values'],
        'workflow_id': json['workflow_id'],
    };
}

export function WorkflowsCreateWorkflowRunRequestBodyToJSON(value?: WorkflowsCreateWorkflowRunRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': value.values,
        'workflow_id': value.workflow_id,
    };
}

