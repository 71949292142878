import { FormErrorMessage } from "./ErrorMessage";
import {
  FormContextText,
  FormHelpText,
  FormInputWrapper,
  FormLabel,
} from "./v2/helpers";
import { FormModal, FormTabModal } from "./v2/Modal";
import { FormRoot } from "./v2/Root";

export const Form = {
  // Core
  Root: FormRoot,
  Modal: FormModal,
  TabModal: FormTabModal,
  // Helpers
  Label: FormLabel,
  Helptext: FormHelpText,
  Context: FormContextText,
  InputWrapper: FormInputWrapper,
  ErrorMessage: FormErrorMessage,
};
