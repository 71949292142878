/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface MessageTemplateConfigUpdatePayload
 */
export interface MessageTemplateConfigUpdatePayload {
    /**
     * 
     * @type {TextNode}
     * @memberof MessageTemplateConfigUpdatePayload
     */
    message_content: TextNode;
}

export function MessageTemplateConfigUpdatePayloadFromJSON(json: any): MessageTemplateConfigUpdatePayload {
    return MessageTemplateConfigUpdatePayloadFromJSONTyped(json, false);
}

export function MessageTemplateConfigUpdatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageTemplateConfigUpdatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message_content': TextNodeFromJSON(json['message_content']),
    };
}

export function MessageTemplateConfigUpdatePayloadToJSON(value?: MessageTemplateConfigUpdatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message_content': TextNodeToJSON(value.message_content),
    };
}

