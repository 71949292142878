/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentSubscribeRequestBody
 */
export interface StatusPageContentSubscribeRequestBody {
    /**
     * If not empty, list of component IDs to receive subscription updates to
     * @type {Array<string>}
     * @memberof StatusPageContentSubscribeRequestBody
     */
    component_ids?: Array<string>;
    /**
     * If present, email to receieve subscription updates through
     * @type {string}
     * @memberof StatusPageContentSubscribeRequestBody
     */
    email: string;
    /**
     * If present, incident ID for the specific status page incident to subscribe to
     * @type {string}
     * @memberof StatusPageContentSubscribeRequestBody
     */
    status_page_incident_id?: string;
}

export function StatusPageContentSubscribeRequestBodyFromJSON(json: any): StatusPageContentSubscribeRequestBody {
    return StatusPageContentSubscribeRequestBodyFromJSONTyped(json, false);
}

export function StatusPageContentSubscribeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentSubscribeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_ids': !exists(json, 'component_ids') ? undefined : json['component_ids'],
        'email': json['email'],
        'status_page_incident_id': !exists(json, 'status_page_incident_id') ? undefined : json['status_page_incident_id'],
    };
}

export function StatusPageContentSubscribeRequestBodyToJSON(value?: StatusPageContentSubscribeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_ids': value.component_ids,
        'email': value.email,
        'status_page_incident_id': value.status_page_incident_id,
    };
}

