/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Resource,
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './Resource';

/**
 * 
 * @export
 * @interface CatalogResource
 */
export interface CatalogResource {
    /**
     * Which category of resource
     * @type {string}
     * @memberof CatalogResource
     */
    category: CatalogResourceCategoryEnum;
    /**
     * Sets the display color of this resource in the dashboard
     * @type {string}
     * @memberof CatalogResource
     */
    color?: CatalogResourceColorEnum;
    /**
     * 
     * @type {Resource}
     * @memberof CatalogResource
     */
    config: Resource;
    /**
     * Human readable description for this resource
     * @type {string}
     * @memberof CatalogResource
     */
    description: string;
    /**
     * Sets the display icon of this resource in the dashboard
     * @type {string}
     * @memberof CatalogResource
     */
    icon?: CatalogResourceIconEnum;
    /**
     * Is this resource a special-cased 'user link' type
     * @type {boolean}
     * @memberof CatalogResource
     */
    is_user_link: boolean;
    /**
     * Label for this catalog resource type
     * @type {string}
     * @memberof CatalogResource
     */
    label: string;
    /**
     * If populated, the integration required for this resource
     * @type {string}
     * @memberof CatalogResource
     */
    requires_integration?: string;
    /**
     * Catalog type name for this resource
     * @type {string}
     * @memberof CatalogResource
     */
    type: string;
    /**
     * Documentation for the literal string value of this resource
     * @type {string}
     * @memberof CatalogResource
     */
    value_docstring: string;
}

/**
* @export
* @enum {string}
*/
export enum CatalogResourceCategoryEnum {
    Primitive = 'primitive',
    Custom = 'custom',
    External = 'external'
}/**
* @export
* @enum {string}
*/
export enum CatalogResourceColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum CatalogResourceIconEnum {
    Alert = 'alert',
    Bolt = 'bolt',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Clock = 'clock',
    Cog = 'cog',
    Components = 'components',
    Database = 'database',
    Doc = 'doc',
    Email = 'email',
    EscalationPath = 'escalation-path',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    Globe = 'globe',
    Money = 'money',
    Server = 'server',
    Severity = 'severity',
    StatusPage = 'status-page',
    Store = 'store',
    Star = 'star',
    Tag = 'tag',
    User = 'user',
    Users = 'users'
}

export function CatalogResourceFromJSON(json: any): CatalogResource {
    return CatalogResourceFromJSONTyped(json, false);
}

export function CatalogResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': json['category'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'config': ResourceFromJSON(json['config']),
        'description': json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'is_user_link': json['is_user_link'],
        'label': json['label'],
        'requires_integration': !exists(json, 'requires_integration') ? undefined : json['requires_integration'],
        'type': json['type'],
        'value_docstring': json['value_docstring'],
    };
}

export function CatalogResourceToJSON(value?: CatalogResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'color': value.color,
        'config': ResourceToJSON(value.config),
        'description': value.description,
        'icon': value.icon,
        'is_user_link': value.is_user_link,
        'label': value.label,
        'requires_integration': value.requires_integration,
        'type': value.type,
        'value_docstring': value.value_docstring,
    };
}

