/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DebriefInviteRule,
    DebriefInviteRuleFromJSON,
    DebriefInviteRuleFromJSONTyped,
    DebriefInviteRuleToJSON,
} from './DebriefInviteRule';

/**
 * 
 * @export
 * @interface DebriefsUpdateInviteRuleRanksResponseBody
 */
export interface DebriefsUpdateInviteRuleRanksResponseBody {
    /**
     * 
     * @type {Array<DebriefInviteRule>}
     * @memberof DebriefsUpdateInviteRuleRanksResponseBody
     */
    invite_rules: Array<DebriefInviteRule>;
}

export function DebriefsUpdateInviteRuleRanksResponseBodyFromJSON(json: any): DebriefsUpdateInviteRuleRanksResponseBody {
    return DebriefsUpdateInviteRuleRanksResponseBodyFromJSONTyped(json, false);
}

export function DebriefsUpdateInviteRuleRanksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsUpdateInviteRuleRanksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invite_rules': ((json['invite_rules'] as Array<any>).map(DebriefInviteRuleFromJSON)),
    };
}

export function DebriefsUpdateInviteRuleRanksResponseBodyToJSON(value?: DebriefsUpdateInviteRuleRanksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invite_rules': ((value.invite_rules as Array<any>).map(DebriefInviteRuleToJSON)),
    };
}

