import type { SVGProps } from "react";
import * as React from "react";
const SvgMergedArrow = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 12"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.764 5.2H9.024L6.936 1.596A2.943 2.943 0 0 0 4.396.131H1.359a.8.8 0 0 0 0 1.6h3.038c.475 0 .917.256 1.155.667L7.638 6 5.552 9.602c-.238.411-.68.666-1.155.666H1.359a.8.8 0 0 0 0 1.6h3.038a2.944 2.944 0 0 0 2.54-1.463L9.024 6.8h6.742l-.002-1.6Z"
    />
    <path
      fill="#212121"
      d="M12.823 9.735a.8.8 0 0 1-.566-1.367l2.37-2.37-2.37-2.368a.8.8 0 0 1 1.133-1.132l2.934 2.934a.8.8 0 0 1 0 1.132L13.39 9.5a.799.799 0 0 1-.566.235Z"
    />
  </svg>
);
export default SvgMergedArrow;
