/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIKey,
    APIKeyFromJSON,
    APIKeyFromJSONTyped,
    APIKeyToJSON,
} from './APIKey';
import {
    AlertActor,
    AlertActorFromJSON,
    AlertActorFromJSONTyped,
    AlertActorToJSON,
} from './AlertActor';
import {
    ExternalResource,
    ExternalResourceFromJSON,
    ExternalResourceFromJSONTyped,
    ExternalResourceToJSON,
} from './ExternalResource';
import {
    IntegrationActor,
    IntegrationActorFromJSON,
    IntegrationActorFromJSONTyped,
    IntegrationActorToJSON,
} from './IntegrationActor';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import {
    WorkflowActor,
    WorkflowActorFromJSON,
    WorkflowActorFromJSONTyped,
    WorkflowActorToJSON,
} from './WorkflowActor';

/**
 * 
 * @export
 * @interface Actor
 */
export interface Actor {
    /**
     * 
     * @type {AlertActor}
     * @memberof Actor
     */
    alert?: AlertActor;
    /**
     * 
     * @type {APIKey}
     * @memberof Actor
     */
    api_key?: APIKey;
    /**
     * 
     * @type {ExternalResource}
     * @memberof Actor
     */
    external_resource?: ExternalResource;
    /**
     * 
     * @type {IntegrationActor}
     * @memberof Actor
     */
    integration?: IntegrationActor;
    /**
     * 
     * @type {User}
     * @memberof Actor
     */
    user?: User;
    /**
     * 
     * @type {WorkflowActor}
     * @memberof Actor
     */
    workflow?: WorkflowActor;
}

export function ActorFromJSON(json: any): Actor {
    return ActorFromJSONTyped(json, false);
}

export function ActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Actor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert': !exists(json, 'alert') ? undefined : AlertActorFromJSON(json['alert']),
        'api_key': !exists(json, 'api_key') ? undefined : APIKeyFromJSON(json['api_key']),
        'external_resource': !exists(json, 'external_resource') ? undefined : ExternalResourceFromJSON(json['external_resource']),
        'integration': !exists(json, 'integration') ? undefined : IntegrationActorFromJSON(json['integration']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowActorFromJSON(json['workflow']),
    };
}

export function ActorToJSON(value?: Actor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert': AlertActorToJSON(value.alert),
        'api_key': APIKeyToJSON(value.api_key),
        'external_resource': ExternalResourceToJSON(value.external_resource),
        'integration': IntegrationActorToJSON(value.integration),
        'user': UserToJSON(value.user),
        'workflow': WorkflowActorToJSON(value.workflow),
    };
}

