import {
  StatusPage,
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageIncident,
  StatusPagePageTypeEnum,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import { Callout, CalloutTheme, LoadingBar, Tooltip } from "@incident-ui";
import { difference } from "lodash";
import { useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { AffectedComponentsFormData } from "../incidents/view/AffectedComponentsEditor";
import { ManualSubPageFormData } from "./modals/PublishLiveIncidentModal";

export const SubPageReviewInfo = ({
  page,
  incident,
}: {
  page: StatusPage;
  incident: StatusPageIncident | null;
}) => {
  const isParentPage = !!page?.split_by_catalog_type_id;
  const formMethods = useFormContext<
    AffectedComponentsFormData & ManualSubPageFormData
  >();
  const [componentStatuses, manualSubPageIDs, manualSubPages] =
    formMethods.watch([
      "component_statuses",
      "manual_sub_page_ids",
      "manual_sub_pages_enabled",
    ]);

  const affectedComponentIds = Object.entries(componentStatuses)
    .filter(([_, status]) => status !== ComponentStatusEnum.Operational)
    .map(([id]) => id);

  const { data: allSubPagesData } = useAPI(
    page.page_type !== StatusPagePageTypeEnum.Standalone
      ? "statusPageListSubPages"
      : null,
    {
      parentStatusPageId: page.id,
    },
  );
  const { data: newlyAffectedSubPageData, isLoading } = useAPI(
    isParentPage && !manualSubPages
      ? "statusPageListNewlyAffectedSubPages"
      : null,
    {
      listNewlyAffectedSubPagesRequestBody: {
        parent_status_page_id: page.id,
        status_page_incident_id: incident?.id ?? undefined,
        affected_component_ids: affectedComponentIds,
      },
    },
  );

  const { data: catalogListTypes } = useAPI(
    isParentPage && page.split_by_catalog_type_id ? "catalogListTypes" : null,
    {},
  );

  // Show nothing for non-parent pages
  if (!isParentPage) return null;
  const existingSubPages = incident?.sub_pages || [];

  // New sub-pages is a list of the pages this incident will be added
  // to. There are a few different cases:
  //
  // 1. No incident, manual pages: new sub-pages == the selected pages. We get this
  //    by filtering down the list of all sub-pages to the ones that are selected, by ID.
  // 2. No incident, automatic pages: new sub-pages == the newly affected pages. We get this
  //    directly from the API.
  // 3. Incident, manual pages: new sub-pages == the diff between the selected pages and the
  //    existing pages. We get this by filtering down the list of all sub-pages to the ones
  //    that are the diff between the selected pages and the existing pages.
  // 4. Incident, automatic pages: new sub-pages == the newly affected pages. We get this
  //    directly from the API.
  const newSubPages = manualSubPages
    ? allSubPagesData?.status_page_sub_pages.filter((page) => {
        // If there's an incident, and we're in manual mode, we'll take the diff
        // between the current sub pages and the new sub pages
        if (incident) {
          const newSubPageIDs = difference(
            manualSubPageIDs,
            existingSubPages.map((page) => page.id),
          );

          return newSubPageIDs.includes(page.id);
        }

        return manualSubPageIDs.includes(page.id);
      }) ?? []
    : newlyAffectedSubPageData?.status_page_sub_pages || [];

  // If the list of manual sub-page IDs does not contain the ID of a
  // given existing sub-page, we know it has been removed.
  const removedPages = existingSubPages.filter((page) => {
    return !manualSubPageIDs.some((id) => id === page.id);
  });

  const catalogType = catalogListTypes?.catalog_types.find(
    (type) => type.id === page.split_by_catalog_type_id,
  );

  const isCustomerPage = page.page_type === StatusPagePageTypeEnum.Customer;
  if (incident == null) {
    return (
      <>
        <div className="text-content-tertiary">
          {isCustomerPage ? "Customer pages" : "Sub-pages"}
        </div>
        <div className="col-span-2 space-y-2">
          {isLoading ? (
            <LoadingBar />
          ) : newSubPages.length === 0 ? (
            <Callout theme={CalloutTheme.Warning}>
              This incident will not be visible on any{" "}
              {isCustomerPage ? "customer pages" : "sub-pages"}. Change the
              affected components to ensure that it is visible to your users.
            </Callout>
          ) : (
            <div className="flex items-center flex-wrap gap-2">
              {newSubPages.map((page) => (
                <CatalogEntryBadge
                  key={page.id}
                  color={catalogType?.color}
                  icon={catalogType?.icon}
                  label={page.name}
                />
              ))}
            </div>
          )}
        </div>
      </>
    );
  }

  const theseOrThis = isCustomerPage
    ? newSubPages.length === 0
      ? "this customer page"
      : "these customer pages"
    : newSubPages.length === 0
    ? "this sub-page"
    : "these sub-pages";

  return (
    <>
      <div className="text-content-tertiary">
        Current
        {isCustomerPage ? " customer pages" : " sub-pages"}
      </div>
      <div className="col-span-2 space-y-2">
        <div className="flex items-center flex-wrap gap-2">
          {existingSubPages.map((page) => {
            return (
              <CatalogEntryBadge
                key={page.id}
                color={catalogType?.color}
                icon={catalogType?.icon}
                label={page.name}
              />
            );
          })}
        </div>
      </div>
      {removedPages.length > 0 && (
        <>
          <div className="text-content-tertiary flex items-center">
            Removed
            {isCustomerPage ? " customer pages " : " sub-pages "}
            {newSubPages.length > 0 && (
              <Tooltip
                content={`This incident will automatically be removed from ${theseOrThis}, and will no longer appear in the history.`}
              />
            )}
          </div>
          <div className="col-span-2 space-y-2">
            <div className="flex items-center flex-wrap gap-2">
              {removedPages.map((page) => {
                return (
                  <CatalogEntryBadge
                    key={page.id}
                    color={catalogType?.color}
                    icon={catalogType?.icon}
                    label={page.name}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
      {newSubPages.length > 0 && (
        <>
          <div className="text-content-tertiary flex items-center">
            New
            {isCustomerPage ? " customer pages " : " sub-pages "}
            {newSubPages.length > 0 && (
              <Tooltip
                content={`This incident will automatically be added to ${theseOrThis}, because it now affects a component on ${theseOrThis}.`}
              />
            )}
          </div>
          <div className="col-span-2 space-y-2">
            <div className="flex items-center flex-wrap gap-2">
              {newSubPages.map((page) => (
                <CatalogEntryBadge
                  key={page.id}
                  color={catalogType?.color}
                  icon={catalogType?.icon}
                  label={page.name}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
