/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomFieldOptionGroupPayload
 */
export interface CustomFieldOptionGroupPayload {
    /**
     * ID of the option group
     * @type {string}
     * @memberof CustomFieldOptionGroupPayload
     */
    id?: string;
    /**
     * Human readable name for the group. If this is different from label on the group, the group label will be updated.
     * @type {string}
     * @memberof CustomFieldOptionGroupPayload
     */
    label: string;
}

export function CustomFieldOptionGroupPayloadFromJSON(json: any): CustomFieldOptionGroupPayload {
    return CustomFieldOptionGroupPayloadFromJSONTyped(json, false);
}

export function CustomFieldOptionGroupPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldOptionGroupPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': json['label'],
    };
}

export function CustomFieldOptionGroupPayloadToJSON(value?: CustomFieldOptionGroupPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
    };
}

