import { CatalogResource, CatalogType } from "@incident-io/api";
import _ from "lodash";
import { useState } from "react";

import { useAPI } from "../utils/swr";

// Given a catalog type name, this hook returns the type if it's 'ready';
// defined as existing, having been synced at least once and having a
// corresponding resource.
//
// N.B. It'll poll every 2 seconds until the type is ready, so think before use!
export const useIsCatalogTypeReady = (
  catalogTypeName: string,
): CatalogType | undefined => {
  const nonReadyRefreshIntervalMs = 2000;

  // State
  const [catalogTypeIsReady, setCatalogTypeIsReady] = useState<boolean>(false);
  const [resourceIsReady, setResourceIsReady] = useState<boolean>(false);

  // SWR hooks
  const {
    data: catalogTypes,
    isLoading: catalogTypesIsLoading,
    error: catalogTypesError,
  } = useAPI(
    "catalogListTypes",
    {},
    {
      refreshInterval: catalogTypeIsReady
        ? undefined
        : nonReadyRefreshIntervalMs,
    },
  );

  const {
    data: resources,
    isLoading: resourcesIsLoading,
    error: resourcesError,
  } = useAPI(
    "catalogListResources",
    {},
    {
      refreshInterval: resourceIsReady ? undefined : nonReadyRefreshIntervalMs,
    },
  );

  // Early return if things are loading or errored
  if (
    catalogTypesIsLoading ||
    catalogTypesError ||
    resourcesIsLoading ||
    resourcesError
  ) {
    return undefined;
  }

  // Check the responses for the things we're interested in
  const maybeResource = _.find(
    resources?.resources ?? [],
    (resource: CatalogResource): boolean => {
      return resource.type === catalogTypeName;
    },
  );

  const maybeReadyType = _.find(
    catalogTypes?.catalog_types ?? [],
    (catalogType: CatalogType): boolean => {
      return (
        catalogType.type_name === catalogTypeName &&
        catalogType.last_synced_at !== undefined
      );
    },
  );

  // Update the state if we've found what we're looking for
  if (maybeReadyType !== undefined && !catalogTypeIsReady) {
    setCatalogTypeIsReady(true);
  }

  if (maybeResource !== undefined && !resourceIsReady) {
    setResourceIsReady(true);
  }

  // Return the type when we're sure the type and resource are available
  if (catalogTypeIsReady && resourceIsReady) {
    return maybeReadyType;
  }

  return undefined;
};
