import { first, padStart, sortBy, uniq } from "lodash";
import {
  IncidentLifecycle,
  IncidentStatus,
  IncidentStatusCategoryEnum as CategoryEnum,
  IncidentTimestamp,
  IncidentTimestampSetByRule,
  IncidentTimestampSetByRuleSetOnTransitionEnum as TransitionEnum,
} from "src/contexts/ClientContext";

export type TimestampWithRule = {
  timestamp: IncidentTimestamp;
  rule?: IncidentTimestampSetByRule;
};

export type PreparedStatusWithTimestamp = {
  status: IncidentStatus;
  onLeaveThisStatusTimestamps: TimestampWithRule[];
  onEnteringNextStatusTimestamps: TimestampWithRule[];
  timestamps: TimestampWithRule[];
};

export type PreparedLifecycle = {
  live: PreparedStatusWithTimestamp[];
  postIncident: PreparedStatusWithTimestamp[];
  allTimestampIds: string[];
};

export const prepareLifecycle = ({
  statuses,
  timestamps,
  lifecycle,
}: {
  lifecycle: IncidentLifecycle;
  statuses: IncidentStatus[];
  timestamps: IncidentTimestamp[];
}): PreparedLifecycle => {
  // First, filter to only the statuses that are relevant for this lifecycle
  const activeStatuses = sortStatuses(lifecycle.active_statuses);
  const postIncidentStatuses = statuses.filter(
    (s) => s.category === CategoryEnum.PostIncident,
  );
  const closedStatus = statuses.filter(
    (s) => s.category === CategoryEnum.Closed,
  )[0];

  const prepared: PreparedLifecycle = {
    live: prepareStatusSet(
      activeStatuses,
      timestamps,
      first(postIncidentStatuses),
    ),
    postIncident: prepareStatusSet(
      postIncidentStatuses,
      timestamps,
      closedStatus,
    ),
    allTimestampIds: [],
  };

  // Manually add resolved timestamps to the last active status
  const resolvedTimestamps = timestamps.filter(
    (timestamp) => timestamp.set_on_resolution,
  );
  resolvedTimestamps.forEach((resolved) => {
    prepared.live[prepared.live.length - 1].onEnteringNextStatusTimestamps.push(
      { timestamp: resolved },
    );
    prepared.live[prepared.live.length - 1].timestamps.push({
      timestamp: resolved,
    });
  });

  prepared.allTimestampIds = uniq([
    ...flattenTimestampIds(prepared.live),
    ...flattenTimestampIds(prepared.postIncident),
  ]);

  return prepared;
};

const prepareStatusSet = (
  statuses: IncidentStatus[],
  timestamps: IncidentTimestamp[],
  firstStatusInNextCategory?: IncidentStatus,
): PreparedStatusWithTimestamp[] => {
  const prepared: PreparedStatusWithTimestamp[] = [];

  statuses.forEach((status, idx) => {
    const isLastStatus = statuses.length === idx + 1;
    const nextStatus = isLastStatus
      ? firstStatusInNextCategory
      : statuses[idx + 1];

    const onLeaveThisStatusTimestamps: TimestampWithRule[] = [];
    const onEnteringNextStatusTimestamps: TimestampWithRule[] = [];

    timestamps.forEach((timestamp) => {
      for (const rule of timestamp.set_by_rules) {
        switch (rule.set_on_transition) {
          case TransitionEnum.Enter:
            if (rule.set_on_status.id === nextStatus?.id) {
              onEnteringNextStatusTimestamps.push({ timestamp, rule });
              // We only want each timestamp to be added once
              return;
            }
            break;
          case TransitionEnum.Leave:
            if (rule.set_on_status.id === status.id) {
              onLeaveThisStatusTimestamps.push({ timestamp, rule });
              // We only want each timestamp to be added once
              return;
            }
            break;
        }
      }
    });

    prepared.push({
      status,
      onLeaveThisStatusTimestamps,
      onEnteringNextStatusTimestamps,
      timestamps: [
        ...onLeaveThisStatusTimestamps,
        ...onEnteringNextStatusTimestamps,
      ],
    });
  });

  return prepared;
};

const flattenTimestampIds = (
  statuses: PreparedStatusWithTimestamp[],
): string[] => {
  return statuses.flatMap((status) =>
    status.timestamps.map(({ timestamp }) => timestamp.id),
  );
};

const sortStatuses = (statuses: IncidentStatus[]): IncidentStatus[] => {
  return sortBy(statuses, (s) => padStart(s.rank.toString(), 3, "0"));
};
