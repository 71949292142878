import type { SVGProps } from "react";
import * as React from "react";
const SvgHighAlertPriority = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g filter="url(#high-alert-priority_svg__a)">
      <g filter="url(#high-alert-priority_svg__b)">
        <path
          fill="#EF4444"
          fillRule="evenodd"
          d="M4.222 1.777h7.556a2.446 2.446 0 0 1 2.444 2.445v7.555a2.447 2.447 0 0 1-2.444 2.445H4.222a2.446 2.446 0 0 1-2.444-2.445V4.222a2.446 2.446 0 0 1 2.444-2.445Zm3.2 2.89a.667.667 0 0 1 1.334 0v3.679a.667.667 0 0 1-1.334 0v-3.68Zm.667 7.098a.89.89 0 0 1 0-1.777.89.89 0 0 1 0 1.777ZM5.289 4a.667.667 0 0 0-.667.667v3.679a.667.667 0 0 0 1.334 0v-3.68A.667.667 0 0 0 5.289 4ZM4.4 10.876a.89.89 0 0 0 1.778 0 .89.89 0 0 0-1.778 0Zm5.822-6.21a.667.667 0 0 1 1.334 0v3.68a.667.667 0 0 1-1.334 0v-3.68Zm.667 7.1a.89.89 0 1 1 .002-1.78.89.89 0 0 1-.002 1.78Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <filter
        id="high-alert-priority_svg__a"
        width={16}
        height={16}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_4024_280" />
      </filter>
      <filter
        id="high-alert-priority_svg__b"
        width={12.444}
        height={12.444}
        x={1.778}
        y={1.777}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_4024_280" />
      </filter>
    </defs>
  </svg>
);
export default SvgHighAlertPriority;
