import React from "react";

export type InterposeProps = {
  separator: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
};

// Interpose allows you to put a separator component inbetween
// any set of React components. E.g.:
// <Interpose separator={<hr/>}>{items.map(i => <Item i={i}/>)}</Interpose> */
export const Interpose: React.FC<InterposeProps> = ({
  children,
  separator,
}: InterposeProps) => {
  const filteredElements: React.ReactNode[] = React.Children.toArray(
    children,
  ).filter((c) => React.isValidElement(c));

  return (
    <>
      {filteredElements.map((child, i) => {
        if (i === filteredElements.length - 1) {
          return <React.Fragment key={i}>{child}</React.Fragment>;
        }

        return (
          <React.Fragment key={i}>
            {child}
            {separator}
          </React.Fragment>
        );
      })}
    </>
  );
};
