import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAllStatuses } from "src/components/legacy/incident/useIncidentCrudResources";
import {
  IncidentTimestamp,
  IncidentTimestampsUpdateRequestBody,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import {
  marshallRequestPayloadSetByConfig,
  marshallResponseBodyPayloadSetByConfig,
  SetByEnum,
  TimestampSetSection,
} from "../TimestampSetSection";

type FormType = IncidentTimestampsUpdateRequestBody & {
  set_by_mode: SetByEnum;
  // Typescript can't deal with the self-referencing type for templated text
  description: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const IncidentTimestampEditModal = ({
  onClose,
  existingTimestamp,
}: {
  onClose: () => void;
  existingTimestamp: IncidentTimestamp;
}): React.ReactElement | null => {
  const formMethods = useForm<FormType>({
    defaultValues: marshallResponseBodyPayloadSetByConfig(existingTimestamp),
  });

  const { allStatuses, allStatusesLoading } = useAllStatuses();

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "incidentTimestampsList",
    undefined,
    async (apiClient, data: FormType) => {
      await apiClient.incidentTimestampsUpdate({
        id: existingTimestamp.id,
        updateRequestBody: marshallRequestPayloadSetByConfig(data),
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal<FormType>
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      onClose={onClose}
      loading={allStatusesLoading}
      analyticsTrackingId="edit-timestamp"
      title="Edit timestamp"
      isExtraLarge
      disableQuickClose
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText="Save"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required="Please provide a name"
        placeholder="e.g. Fixed at"
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="description"
        label="Description"
        placeholder="e.g. When we implemented the fix for this incident."
        helptext="This will be displayed to help a user understand what this timestamp means."
        format="mrkdwn"
        required="Please provide a description"
        includeVariables={false}
        includeExpressions={false}
      />

      <TimestampSetSection
        statuses={allStatuses}
        timestampType={existingTimestamp.timestamp_type}
      />
    </Form.Modal>
  );
};
