/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallNotificationsTestContactMethodRequestBody
 */
export interface OnCallNotificationsTestContactMethodRequestBody {
    /**
     * The mobile key ID to test, if not provided, all mobile keys will be tested
     * @type {string}
     * @memberof OnCallNotificationsTestContactMethodRequestBody
     */
    mobile_key_id?: string;
    /**
     * The ID of the on-call notification method to test
     * @type {string}
     * @memberof OnCallNotificationsTestContactMethodRequestBody
     */
    on_call_notification_method_id?: string;
    /**
     * The phone contact method to test
     * @type {string}
     * @memberof OnCallNotificationsTestContactMethodRequestBody
     */
    phone_contact_method?: OnCallNotificationsTestContactMethodRequestBodyPhoneContactMethodEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationsTestContactMethodRequestBodyPhoneContactMethodEnum {
    Call = 'call',
    Sms = 'sms'
}

export function OnCallNotificationsTestContactMethodRequestBodyFromJSON(json: any): OnCallNotificationsTestContactMethodRequestBody {
    return OnCallNotificationsTestContactMethodRequestBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsTestContactMethodRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsTestContactMethodRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobile_key_id': !exists(json, 'mobile_key_id') ? undefined : json['mobile_key_id'],
        'on_call_notification_method_id': !exists(json, 'on_call_notification_method_id') ? undefined : json['on_call_notification_method_id'],
        'phone_contact_method': !exists(json, 'phone_contact_method') ? undefined : json['phone_contact_method'],
    };
}

export function OnCallNotificationsTestContactMethodRequestBodyToJSON(value?: OnCallNotificationsTestContactMethodRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile_key_id': value.mobile_key_id,
        'on_call_notification_method_id': value.on_call_notification_method_id,
        'phone_contact_method': value.phone_contact_method,
    };
}

