/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GroupedEscalationTimelineElement,
    GroupedEscalationTimelineElementFromJSON,
    GroupedEscalationTimelineElementFromJSONTyped,
    GroupedEscalationTimelineElementToJSON,
} from './GroupedEscalationTimelineElement';

/**
 * 
 * @export
 * @interface EscalationsTimelineResponseBody
 */
export interface EscalationsTimelineResponseBody {
    /**
     * 
     * @type {Array<GroupedEscalationTimelineElement>}
     * @memberof EscalationsTimelineResponseBody
     */
    timeline_elements: Array<GroupedEscalationTimelineElement>;
}

export function EscalationsTimelineResponseBodyFromJSON(json: any): EscalationsTimelineResponseBody {
    return EscalationsTimelineResponseBodyFromJSONTyped(json, false);
}

export function EscalationsTimelineResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsTimelineResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_elements': ((json['timeline_elements'] as Array<any>).map(GroupedEscalationTimelineElementFromJSON)),
    };
}

export function EscalationsTimelineResponseBodyToJSON(value?: EscalationsTimelineResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_elements': ((value.timeline_elements as Array<any>).map(GroupedEscalationTimelineElementToJSON)),
    };
}

