/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalResource,
    ExternalResourceFromJSON,
    ExternalResourceFromJSONTyped,
    ExternalResourceToJSON,
} from './ExternalResource';

/**
 * 
 * @export
 * @interface IncidentDebrief
 */
export interface IncidentDebrief {
    /**
     * 
     * @type {ExternalResource}
     * @memberof IncidentDebrief
     */
    external_resource: ExternalResource;
    /**
     * Unique internal ID of the incident debrief
     * @type {string}
     * @memberof IncidentDebrief
     */
    id: string;
    /**
     * Unique internal ID of the incident that this debrief is associated with
     * @type {string}
     * @memberof IncidentDebrief
     */
    incident_id: string;
}

export function IncidentDebriefFromJSON(json: any): IncidentDebrief {
    return IncidentDebriefFromJSONTyped(json, false);
}

export function IncidentDebriefFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentDebrief {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_resource': ExternalResourceFromJSON(json['external_resource']),
        'id': json['id'],
        'incident_id': json['incident_id'],
    };
}

export function IncidentDebriefToJSON(value?: IncidentDebrief | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_resource': ExternalResourceToJSON(value.external_resource),
        'id': value.id,
        'incident_id': value.incident_id,
    };
}

