import type { SVGProps } from "react";
import * as React from "react";
const SvgHourglass = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 16h-.261c-.056-2.751-.457-4.593-1.989-6 1.532-1.407 1.932-3.249 1.989-6h.261a.75.75 0 0 0 0-1.5H4.75a.75.75 0 0 0 0 1.5h.261c.056 2.751.457 4.593 1.989 6-1.532 1.407-1.932 3.249-1.989 6H4.75a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5Zm-6.619-5.38a.751.751 0 0 0 0-1.24C7.101 8.339 6.583 7.06 6.514 4h6.972c-.069 3.06-.587 4.339-2.117 5.38a.751.751 0 0 0 0 1.24c1.53 1.041 2.048 2.32 2.117 5.38H6.514c.069-3.06.587-4.339 2.117-5.38Z"
    />
    <path
      fill="currentColor"
      d="M12.11 5.571a.463.463 0 0 0-.118-.398.5.5 0 0 0-.378-.173H8.385a.496.496 0 0 0-.378.173.463.463 0 0 0-.117.398C8.21 7.965 9.258 8.494 10 8.494c.74 0 1.79-.529 2.108-2.923h.001Z"
    />
  </svg>
);
export default SvgHourglass;
