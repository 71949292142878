/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlaceholderTimeslot
 */
export interface PlaceholderTimeslot {
    /**
     * When the timeslot ends
     * @type {Date}
     * @memberof PlaceholderTimeslot
     */
    end: Date;
    /**
     * ID of the placeholder this timeslot is available in
     * @type {string}
     * @memberof PlaceholderTimeslot
     */
    placeholder_id: string;
    /**
     * When the timeslot starts
     * @type {Date}
     * @memberof PlaceholderTimeslot
     */
    start: Date;
}

export function PlaceholderTimeslotFromJSON(json: any): PlaceholderTimeslot {
    return PlaceholderTimeslotFromJSONTyped(json, false);
}

export function PlaceholderTimeslotFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceholderTimeslot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end': (new Date(json['end'])),
        'placeholder_id': json['placeholder_id'],
        'start': (new Date(json['start'])),
    };
}

export function PlaceholderTimeslotToJSON(value?: PlaceholderTimeslot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end': (value.end.toISOString()),
        'placeholder_id': value.placeholder_id,
        'start': (value.start.toISOString()),
    };
}

