/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StepConfigPayload,
    StepConfigPayloadFromJSON,
    StepConfigPayloadFromJSONTyped,
    StepConfigPayloadToJSON,
} from './StepConfigPayload';

/**
 * 
 * @export
 * @interface WorkflowsValidateStepRequestBody
 */
export interface WorkflowsValidateStepRequestBody {
    /**
     * 
     * @type {StepConfigPayload}
     * @memberof WorkflowsValidateStepRequestBody
     */
    step: StepConfigPayload;
}

export function WorkflowsValidateStepRequestBodyFromJSON(json: any): WorkflowsValidateStepRequestBody {
    return WorkflowsValidateStepRequestBodyFromJSONTyped(json, false);
}

export function WorkflowsValidateStepRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsValidateStepRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': StepConfigPayloadFromJSON(json['step']),
    };
}

export function WorkflowsValidateStepRequestBodyToJSON(value?: WorkflowsValidateStepRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': StepConfigPayloadToJSON(value.step),
    };
}

