/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OnCallOnboardingState,
    OnCallOnboardingStateFromJSON,
    OnCallOnboardingStateToJSON,
} from '../models';

export interface OnCallOnboardingMarkTaskAsCompletedRequest {
    task: string;
}

/**
 * 
 */
export class OnCallOnboardingApi extends runtime.BaseAPI {

    /**
     * Get the current on-call onboarding state.
     * CurrentOnboardingState On-call onboarding
     */
    async onCallOnboardingCurrentOnboardingStateRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallOnboardingState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_onboarding_tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallOnboardingStateFromJSON(jsonValue));
    }

    /**
     * Get the current on-call onboarding state.
     * CurrentOnboardingState On-call onboarding
     */
    async onCallOnboardingCurrentOnboardingState(initOverrides?: RequestInit): Promise<OnCallOnboardingState> {
        const response = await this.onCallOnboardingCurrentOnboardingStateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Mark an on-call onboarding task as completed.
     * MarkTaskAsCompleted On-call onboarding
     */
    async onCallOnboardingMarkTaskAsCompletedRaw(requestParameters: OnCallOnboardingMarkTaskAsCompletedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.task === null || requestParameters.task === undefined) {
            throw new runtime.RequiredError('task','Required parameter requestParameters.task was null or undefined when calling onCallOnboardingMarkTaskAsCompleted.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_onboarding_tasks/{task}`.replace(`{${"task"}}`, encodeURIComponent(String(requestParameters.task))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark an on-call onboarding task as completed.
     * MarkTaskAsCompleted On-call onboarding
     */
    async onCallOnboardingMarkTaskAsCompleted(requestParameters: OnCallOnboardingMarkTaskAsCompletedRequest, initOverrides?: RequestInit): Promise<void> {
        await this.onCallOnboardingMarkTaskAsCompletedRaw(requestParameters, initOverrides);
    }

}
