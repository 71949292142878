/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageIncident,
    StatusPageIncidentFromJSON,
    StatusPageIncidentFromJSONTyped,
    StatusPageIncidentToJSON,
} from './StatusPageIncident';

/**
 * 
 * @export
 * @interface StatusPageCreateRetrospectiveIncidentResponseBody
 */
export interface StatusPageCreateRetrospectiveIncidentResponseBody {
    /**
     * 
     * @type {StatusPageIncident}
     * @memberof StatusPageCreateRetrospectiveIncidentResponseBody
     */
    status_page_incident: StatusPageIncident;
}

export function StatusPageCreateRetrospectiveIncidentResponseBodyFromJSON(json: any): StatusPageCreateRetrospectiveIncidentResponseBody {
    return StatusPageCreateRetrospectiveIncidentResponseBodyFromJSONTyped(json, false);
}

export function StatusPageCreateRetrospectiveIncidentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateRetrospectiveIncidentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status_page_incident': StatusPageIncidentFromJSON(json['status_page_incident']),
    };
}

export function StatusPageCreateRetrospectiveIncidentResponseBodyToJSON(value?: StatusPageCreateRetrospectiveIncidentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_page_incident': StatusPageIncidentToJSON(value.status_page_incident),
    };
}

