import {
  CallParticipant,
  Incident,
  IncidentCallExternalProviderEnum,
  IncidentCallsGetForLatestForIncidentResponseBody,
  IncidentVisibilityEnum,
  Stream,
} from "@incident-io/api";
import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useHowLongAgoFromDate } from "src/components/incident-calls/hooks";
import { IncidentCallsDeleteModal } from "src/components/incident-calls/IncidentCallsDeleteModal";
import { stringToHash } from "src/utils/utils";

import { useIdentity } from "../../../../contexts/IdentityContext";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "../../../../routes/legacy/IncidentRoute";
import { useNavigateToModal } from "../../../../utils/query-params";
import { useClipboard } from "../../../../utils/useClipboard";
import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../sidebar/AvatarList";
import { IncidentStackedList } from "./Components";

export const CallsStackedList = ({
  callData,
  isLoading,
  incident,
}: {
  callData?: IncidentCallsGetForLatestForIncidentResponseBody;
  isLoading: boolean;
  incident: Incident | Stream;
}) => {
  const { copyTextToClipboard } = useClipboard();
  const { featureLiveCallTranscription } = useFlags();
  const { identity } = useIdentity();

  const navigateToModal = useNavigateToModal();

  const incidentCall = callData?.incident_call;
  const activeCallSession = callData?.active_call_session;

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const updatedAgo = useHowLongAgoFromDate(
    activeCallSession?.current_topic_last_updated_at ?? new Date(),
  );

  const isPrivateInc = incident?.visibility === IncidentVisibilityEnum.Private;

  let participantText = "Join";
  if (
    activeCallSession?.participants &&
    activeCallSession.participants.length > 0
  ) {
    participantText = `${activeCallSession.participants.length} participant${
      activeCallSession.participants.length > 1 ? "s" : ""
    }`;
  }

  if (!incidentCall) {
    return null;
  }

  return (
    <>
      <IncidentStackedList.Section title="Call" loading={isLoading}>
        <IncidentStackedList.Item
          iconNode={
            <Icon id={IconForCallProvider(incidentCall.external_provider)} />
          }
          href={incidentCall.call_url}
          title={
            <div className="flex gap-2 items-center">
              <div>{participantText}</div>
              {!!activeCallSession?.participants &&
                activeCallSession.participants.length > 0 && (
                  <dl className="flex-center-y justify-between">
                    <AvatarList
                      users={activeCallSession.participants.map((p) =>
                        convertToMaybeUser(p),
                      )}
                      modalTitle={"Call participants"}
                      maxToShow={5}
                      clickableType={AvatarListClickableType.OnlyOnSeeMore}
                    />
                  </dl>
                )}
            </div>
          }
          accessoryOne={
            !isPrivateInc &&
            featureLiveCallTranscription && (
              <Button
                theme={ButtonTheme.UnstyledPill}
                size={BadgeSize.ExtraSmall}
                icon={IconEnum.Scribe}
                title="View notes"
                onClick={() => navigateToModal(IncidentDrawer.CallNotes)}
                analyticsTrackingId="view-incident-notes"
                className="bg-alarmalade-50 hover:bg-alarmalade-100"
                // TODO: RESP-7354 - Add scribe gradient version of icon instead of this.
                iconProps={{ className: "text-alarmalade-500" }}
              >
                View notes
              </Button>
            )
          }
          accessoryTwo={
            <DropdownMenu
              align="end"
              screenReaderText="More options"
              analyticsTrackingId="call-more-options"
              triggerButtonTheme={ButtonTheme.Naked}
              triggerIconSize={IconSize.Small}
              triggerIcon={IconEnum.DotsVerticalNopad}
            >
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onSelect={() =>
                    navigateToModal(IncidentHeaderModal.UpdateCall)
                  }
                  analyticsTrackingId={"edit-incident-call"}
                  label="Edit"
                  icon={IconEnum.Edit}
                />
                <DropdownMenuItem
                  onSelect={() => copyTextToClipboard(incidentCall.call_url)}
                  analyticsTrackingId={"copy-incident-call"}
                  label="Copy link"
                  icon={IconEnum.Copy}
                />
                {identity.organisation_is_demo && (
                  <DropdownMenuItem
                    icon={IconEnum.Sparkles}
                    analyticsTrackingId={null}
                    onSelect={() =>
                      navigateToModal(IncidentHeaderModal.DemoMagicCall)
                    }
                    label="Scribe demo"
                  />
                )}
              </DropdownMenuGroup>
              <DropdownMenuItem
                onSelect={() => setConfirmDeleteModalOpen(true)}
                analyticsTrackingId={"delete-incident-call"}
                label="Remove"
                icon={IconEnum.Archive}
                destructive
              />
            </DropdownMenu>
          }
          footerAccessory={
            activeCallSession?.current_topic &&
            activeCallSession?.current_topic_last_updated_at &&
            featureLiveCallTranscription && (
              <div className="flex flex-col gap-2">
                <div
                  className={scribeStyles.textGradientAngled}
                  key={stringToHash(activeCallSession.current_topic)}
                >
                  {activeCallSession.current_topic}
                </div>
                <div className="flex align-center gap-1 text-content-tertiary text-xs-med hover:!no-underline">
                  <span>{updatedAgo}</span>
                  <Tooltip
                    content={
                      "Calculated from recent conversations and updated every 2 minutes"
                    }
                  />
                </div>
              </div>
            )
          }
        />
      </IncidentStackedList.Section>
      <IncidentCallsDeleteModal
        incidentCall={incidentCall}
        isOpen={confirmDeleteModalOpen}
        onClose={() => setConfirmDeleteModalOpen(false)}
      />
    </>
  );
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  return maybeUser;
};

export const IconForCallProvider = (
  provider: IncidentCallExternalProviderEnum,
): IconEnum => {
  switch (provider) {
    case IncidentCallExternalProviderEnum.Zoom:
      return IconEnum.Zoom;
    case IncidentCallExternalProviderEnum.GoogleMeet:
      return IconEnum.GoogleMeet;
    case IncidentCallExternalProviderEnum.Other:
      return IconEnum.Phone;
    default:
      return IconEnum.Phone;
  }
};
