import { StatusPageContentStatusSummaryWorstComponentStatusEnum as ComponentStatusEnum } from "@incident-io/api";
import {
  Calendar,
  daysBetween,
  STATUS_SEVERITY,
  useNow,
  useParseTime,
  useTimeMaths,
  withSentryErrorBoundary,
} from "@incident-io/status-page-ui";
import { Bucket } from "@incident-ui/Badge/SeverityBadge";
import { maxBy } from "lodash";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useAPI } from "src/utils/swr";

type CalendarEvent = {
  date: DateTime;
  name: string;
  incident_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: any;
};

const InternalCalendarReal = ({
  pageId,
}: {
  pageId: string;
}): React.ReactElement => {
  const now = useNow(1000, "minute");
  const [calendarDate, setCalendarDate] = useState(now.startOf("month"));
  const endAt = useTimeMaths(calendarDate.plus({ month: 1 }));

  const {
    data: { internal_status_page_incidents: incidents },
    isLoading,
  } = useAPI(
    "internalStatusPageContentListIncidents",
    {
      internalStatusPageId: pageId,
      startAt: calendarDate.toJSDate(),
      endAt: endAt.toJSDate(),
    },
    { fallbackData: { internal_status_page_incidents: [] } },
  );

  const { parse } = useParseTime();

  const events = useMemo(
    () =>
      incidents.flatMap((incident): CalendarEvent[] => {
        // For each window that the incident was live, generate an event per day
        // in that window.
        return incident.live_windows.flatMap(({ start_at, end_at }) => {
          return daysBetween(parse(start_at), end_at ? parse(end_at) : now).map(
            (date) => ({
              date: date,
              name: incident.name,
              incident_id: incident.external_id.toString(),
              status: incident.severity?.bucketed_rank
                ? severityToStatus[incident.severity?.bucketed_rank]
                : ComponentStatusEnum.DegradedPerformance,
            }),
          );
        });
      }),
    [parse, incidents, now],
  );

  const calculateWorstImpact = (
    dayEvents: CalendarEvent[],
  ): ComponentStatusEnum | undefined =>
    maxBy(
      dayEvents.map(({ status }) => status),
      (status) => STATUS_SEVERITY[status],
    );

  return (
    <Calendar
      events={events}
      calendarDate={calendarDate}
      setCalendarDate={setCalendarDate}
      loading={isLoading}
      // Internal statuspages don't really need a 'data available since', so we can
      // just use 1/1/2000
      dataAvailableSince={DateTime.fromISO("2000-01-01")}
      worstImpactForDay={calculateWorstImpact}
    />
  );
};

export const InternalCalendar = withSentryErrorBoundary(
  InternalCalendarReal,
  "InternalCalendar",
);

const severityToStatus = {
  [Bucket.Low]: ComponentStatusEnum.DegradedPerformance,
  [Bucket.Medium]: ComponentStatusEnum.DegradedPerformance,
  [Bucket.High]: ComponentStatusEnum.PartialOutage,
  [Bucket.Critical]: ComponentStatusEnum.FullOutage,
};
