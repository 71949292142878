import { ContentBox } from "@incident-ui";
import {
  addMinutes,
  isAfter,
  isBefore,
  isEqual,
  setHours,
  setMinutes,
} from "date-fns";

import { weekdaySelectOptions } from "../../../../utils/frequencies";
import {
  WeeklyCalendar,
  WeeklyCalendarData,
  WeeklyCalendarShift,
} from "../../../on-call-legacy/common/calendar/WeeklyCalendar";
import { PayRuleFormType } from "./PayConfigCreateEditForm";
// Terminology: a 'slot' is a unit of 5 minutes, which is what our Calendar
// works in (anything less than 5 mins is lost).
// There are (12*24) = 288 slots in a day, 288*7 = 2016 in a week.
// A 'shift' is a group of slots, with a pay rate associated. It's like a block
// in the resulting calendar.
// All times are represented as dates, relative to our EXAMPLE_WEEK_START

// This is a random Monday, and is going to the be start of our fake week.
export const EXAMPLE_WEEK_START = new Date(2022, 5, 6, 0, 0);

// This is an alias to make it easier to read the code. A slot index is a
// number between 0 and 2015, indicating which of the 2016 slots in a week
// it represents.
type SlotIndex = number;

type Slot = {
  index: SlotIndex;
  payRuleKey: string;
};

const BASE_RULE_KEY = "base_rule";

type RuleWithIsBase = Omit<PayRuleFormType, "weekdays" | "sort_key" | "key"> & {
  isBase: boolean;
};

// Note that this end_time is only used by this file (as part of the maths), and
// doesn't get used by the calendar component, which relies purely on start times.
// This is important - the base shift has an end time of '00:00' which won't be
// right if there are any overrides that day.
type ExtendedShift = WeeklyCalendarShift & { end_time: string };

export const PayConfigCalendar = ({
  currency,
  payRules,
  baseRateStr,
}: {
  currency: string;
  payRules: PayRuleFormType[];
  baseRateStr: string;
}): React.ReactElement => {
  const weekdaysWithShifts: WeeklyCalendarData = [];

  // Create a lookup to get from a payRuleKey => the baseRate and shortID.
  const ruleLookup: { [key: string]: RuleWithIsBase } = {
    [BASE_RULE_KEY]: {
      short_id: "Base Rate",
      rate_pounds: baseRateStr,
      isBase: true,
      start_time: "00:00",
      end_time: "00:00",
    },
  };
  payRules.forEach((rule) => {
    ruleLookup[rule.key] = {
      ...rule,
      isBase: false,
    };
  });

  weekdaySelectOptions.forEach((weekday) => {
    const slots: Slot[] = [];
    let slotIndex = 0;
    // Take each 5 minute slot in the day
    for (let i = 0; i < 288; i++) {
      let thisRule = { key: BASE_RULE_KEY };
      const slotStartTime = addMinutes(EXAMPLE_WEEK_START, i * 5);
      // Find the first rule which covers the slot start. We don't work out
      // which has the maximum of the slot, there's no need to be that accurate.
      payRules.forEach((rule) => {
        // Step 1: does this rule apply on this day
        if (rule.weekdays.includes(weekday.value)) {
          // Step 2: does this rule apply to this time
          if (timeIsInsideRule(slotStartTime, rule)) {
            thisRule = rule;
            return;
          }
        }
      });

      slots.push({
        index: slotIndex,
        payRuleKey: thisRule.key,
      });
      slotIndex++;
    }

    // Now reduce these slots into shifts that can be rendered on the calendar
    const shifts: ExtendedShift[] = [];
    for (const slot of slots) {
      // if we're the first slot, there won't be any shifts, so we need to
      // create one
      if (shifts.length === 0) {
        shifts.push({
          startSlotIndex: slot.index,
          durationInSlots: 1,
          payRuleKey: slot.payRuleKey,
          ...ruleLookup[slot.payRuleKey],
        });
        continue;
      }
      // Look at the last shift. If it matches our payRuleKey, lengthen it.
      // Otherwise, create a new shift.
      const lastShift = shifts[shifts.length - 1];
      if (lastShift.payRuleKey === slot.payRuleKey) {
        lastShift.durationInSlots = lastShift.durationInSlots + 1;
        continue;
      } else {
        let rateInfo = ruleLookup[slot.payRuleKey];
        // if it's a base rate, and it's not the first of the day, we need to find the
        // startTime based on the previous shift's rule.
        if (rateInfo.isBase) {
          rateInfo = { ...rateInfo, start_time: lastShift.end_time };
        }

        shifts.push({
          startSlotIndex: slot.index,
          durationInSlots: 1,
          payRuleKey: slot.payRuleKey,
          ...rateInfo,
        });
        continue;
      }
    }
    weekdaysWithShifts.push({ weekday: weekday.value, shifts });
  });

  return (
    <ContentBox>
      <WeeklyCalendar currency={currency} data={weekdaysWithShifts} />
    </ContentBox>
  );
};

// timeIsInsideRule ignores the weekdays, and just checks if the time
// provided is between the time range specified in the rule.
const timeIsInsideRule = (
  timeToCompare: Date,
  rule: PayRuleFormType,
): boolean => {
  const ruleStartTime = setTimeOnDate(rule.start_time, EXAMPLE_WEEK_START);
  const ruleEndTime = setTimeOnDate(rule.end_time, EXAMPLE_WEEK_START);

  const timeIsAfterOrEqualStart =
    isAfter(timeToCompare, ruleStartTime) ||
    isEqual(timeToCompare, ruleStartTime);
  const timeIsBeforeEnd =
    rule.end_time === "00:00" || isBefore(timeToCompare, ruleEndTime);
  return timeIsAfterOrEqualStart && timeIsBeforeEnd;
};

const setTimeOnDate = (timeStr: string, date: Date): Date => {
  const [hours, mins] = timeStr.split(":");
  const dateWithHours = setHours(date, parseInt(hours));
  return setMinutes(dateWithHours, parseInt(mins));
};
