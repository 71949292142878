import { Button, ButtonTheme, IconEnum, Tooltip } from "@incident-ui";
import { IconProps } from "@incident-ui/Icon/Icon";

export const TooltipButton = ({
  title,
  iconName,
  iconProps,
  onClick,
  disabled = false,
}: {
  title: string;
  iconName: IconEnum;
  iconProps?: Omit<IconProps, "id">;
  onClick: () => void;
  disabled?: boolean;
}): React.ReactElement => {
  return (
    <Tooltip content={title} side="bottom">
      <Button
        analyticsTrackingId={null}
        icon={iconName}
        iconProps={iconProps}
        title={title}
        theme={ButtonTheme.Naked}
        onClick={onClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};
