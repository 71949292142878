/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOption
 */
export interface UserOption {
    /**
     * Gives a description of the option to the user
     * @type {string}
     * @memberof UserOption
     */
    helptext?: string;
    /**
     * If appropriate, URL to an image that can be displayed alongside the option
     * @type {string}
     * @memberof UserOption
     */
    image_url?: string;
    /**
     * If true, the image_url is a Slack icon and should be displayed as such
     * @type {boolean}
     * @memberof UserOption
     */
    is_image_slack_icon?: boolean;
    /**
     * Human readable label to be displayed for user to select
     * @type {string}
     * @memberof UserOption
     */
    label: string;
    /**
     * Gives an indication of how to sort the options when displayed to the user
     * @type {string}
     * @memberof UserOption
     */
    sort_key: string;
    /**
     * 
     * @type {string}
     * @memberof UserOption
     */
    state: UserOptionStateEnum;
    /**
     * Unique identifier for this option
     * @type {string}
     * @memberof UserOption
     */
    value: string;
}

/**
* @export
* @enum {string}
*/
export enum UserOptionStateEnum {
    Deactivated = 'deactivated',
    Viewer = 'viewer',
    Responder = 'responder',
    OnCall = 'on_call',
    OnCallResponder = 'on_call_responder',
    NotYetActive = 'not_yet_active'
}

export function UserOptionFromJSON(json: any): UserOption {
    return UserOptionFromJSONTyped(json, false);
}

export function UserOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'helptext': !exists(json, 'helptext') ? undefined : json['helptext'],
        'image_url': !exists(json, 'image_url') ? undefined : json['image_url'],
        'is_image_slack_icon': !exists(json, 'is_image_slack_icon') ? undefined : json['is_image_slack_icon'],
        'label': json['label'],
        'sort_key': json['sort_key'],
        'state': json['state'],
        'value': json['value'],
    };
}

export function UserOptionToJSON(value?: UserOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'helptext': value.helptext,
        'image_url': value.image_url,
        'is_image_slack_icon': value.is_image_slack_icon,
        'label': value.label,
        'sort_key': value.sort_key,
        'state': value.state,
        'value': value.value,
    };
}

