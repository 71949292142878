/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSummarySuggestion,
    IncidentSummarySuggestionFromJSON,
    IncidentSummarySuggestionFromJSONTyped,
    IncidentSummarySuggestionToJSON,
} from './IncidentSummarySuggestion';

/**
 * 
 * @export
 * @interface IncidentsRejectSummarySuggestionResponseBody
 */
export interface IncidentsRejectSummarySuggestionResponseBody {
    /**
     * 
     * @type {IncidentSummarySuggestion}
     * @memberof IncidentsRejectSummarySuggestionResponseBody
     */
    suggestion: IncidentSummarySuggestion;
}

export function IncidentsRejectSummarySuggestionResponseBodyFromJSON(json: any): IncidentsRejectSummarySuggestionResponseBody {
    return IncidentsRejectSummarySuggestionResponseBodyFromJSONTyped(json, false);
}

export function IncidentsRejectSummarySuggestionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsRejectSummarySuggestionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suggestion': IncidentSummarySuggestionFromJSON(json['suggestion']),
    };
}

export function IncidentsRejectSummarySuggestionResponseBodyToJSON(value?: IncidentsRejectSummarySuggestionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suggestion': IncidentSummarySuggestionToJSON(value.suggestion),
    };
}

