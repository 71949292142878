import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  LoadingWrapper,
  RadioButtonGroup,
  Txt,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import {
  ErrorResponse,
  ManagedResourceResourceTypeEnum as ResourceTypeEnum,
  ManagementMeta,
} from "src/contexts/ClientContext";

import { DisconnectTerraformModal } from "./DisconnectTerraformModal";
import { SourceURLForm } from "./SourceURLForm";
import { SourceURLLink } from "./SourceURLLink";
import { isTerraform } from "./utils";

export const CopyToTerraformDrawer = ({
  isOpen,
  onClose,
  terraform,
  terraformIsLoading,
  terraformError,
  resourceType,
  resourceID,
  resourceName,
  managementMeta,
}: {
  isOpen: boolean;
  onClose: () => void;
  terraform: string | undefined;
  terraformIsLoading: boolean;
  terraformError?: ErrorResponse;
  resourceType: ResourceTypeEnum;
  resourceID?: string;
  resourceName: string;
  managementMeta: ManagementMeta;
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Drawer onClose={onClose} className="overflow-y-hidden" width="large">
          <DrawerContents className="font-normal">
            <DrawerTitle
              icon={IconEnum.Terraform}
              color={ColorPaletteEnum.Purple}
              onClose={onClose}
              title={`Copy this ${resourceType} to Terraform`}
            />
            <DrawerBody className="overflow-y-auto">
              <CopyToTerraformDrawerContents
                resourceType={resourceType}
                resourceID={resourceID}
                resourceName={resourceName}
                managementMeta={managementMeta}
                terraform={terraform}
                terraformIsLoading={terraformIsLoading}
                terraformError={terraformError}
                onCloseDrawer={onClose}
              />
            </DrawerBody>
            <DrawerFooter className="flex justify-end">
              <Button
                analyticsTrackingId={null}
                onClick={onClose}
                theme={ButtonTheme.Secondary}
              >
                Close
              </Button>
            </DrawerFooter>
          </DrawerContents>
        </Drawer>
      )}
    </AnimatePresence>
  );
};

const CopyToTerraformDrawerContents = ({
  terraform,
  terraformIsLoading,
  terraformError,
  resourceType,
  resourceID,
  resourceName,
  managementMeta,
  onCloseDrawer,
}: {
  terraform?: string;
  terraformIsLoading: boolean;
  terraformError?: ErrorResponse;
  resourceType: ResourceTypeEnum;
  resourceID?: string;
  resourceName: string;
  managementMeta: ManagementMeta;
  onCloseDrawer: () => void;
}) => {
  const isCurrentlyManagedInTF = isTerraform(managementMeta);

  const FakeCodeBlock = ({ children }: { children: React.ReactNode }) => (
    <div className="h-32 w-full flex grow items-center justify-center bg-surface-invert rounded-2 p-10">
      {children}
    </div>
  );

  const TerraformErrorBlock = () => {
    if (!terraformError) {
      return null;
    }
    // If you can parse the error

    if (terraformError?.errors && terraformError.errors[0]?.message) {
      return (
        <FakeCodeBlock>
          <div className="flex flex-col gap-2 items-center justify-center">
            <Icon
              id={IconEnum.CodeBlock}
              className="text-content-tertiary w-12 h-12"
            />
            <Txt className="text-white">
              {terraformError.errors[0]?.message}
            </Txt>
          </div>
        </FakeCodeBlock>
      );
    }
    // if not
    return (
      <FakeCodeBlock>
        <GenericErrorMessage error={terraformError} />
      </FakeCodeBlock>
    );
  };

  const TerraformCodeBlock = () => {
    return (
      <>
        <div className="flex flex-col gap-2">
          <Txt>
            {isCurrentlyManagedInTF
              ? "To apply your changes, copy the updated resource below to your Terraform configuration"
              : `Add the below resource to your configuration to manage the ${resourceType} in Terraform.`}
          </Txt>
          {!!resourceID && isCurrentlyManagedInTF && (
            <SourceURLViewOrEdit
              resourceType={resourceType}
              resourceID={resourceID}
              management={managementMeta}
            />
          )}
        </div>
        <LoadingWrapper loading={terraformIsLoading} className="rounded-2">
          {terraformError ? (
            <TerraformErrorBlock />
          ) : (
            <CodeBlock
              theme="dark"
              code={terraform || ""}
              showCopyButtonText
              hideHeader
            />
          )}
        </LoadingWrapper>
      </>
    );
  };

  return (
    <>
      {!isCurrentlyManagedInTF && (
        <Callout theme={CalloutTheme.Info}>
          Once a {resourceType} is managed in Terraform, you will not be able to
          save changes via the dashboard UI.
        </Callout>
      )}
      {!!resourceID && !isCurrentlyManagedInTF && (
        <SourceURLViewOrEdit
          resourceType={resourceType}
          resourceID={resourceID}
          management={managementMeta}
        />
      )}
      {!!resourceID && !isCurrentlyManagedInTF && (
        <ImportOrCreateRadio
          resourceType={resourceType}
          resourceID={resourceID}
          managementMeta={managementMeta}
          terraform={terraform || ""}
        />
      )}
      <TerraformCodeBlock />
      <div className="bg-surface-secondary text-slate-700 rounded-2 p-4">
        {isCurrentlyManagedInTF && resourceID ? (
          <ManagedInTFCallout
            onCloseDrawer={onCloseDrawer}
            resourceType={resourceType}
            resourceID={resourceID}
            resourceName={resourceName}
          />
        ) : (
          <div className="flex items-center justify-between">
            <Txt>Learn more about incident.io&rsquo;s Terraform Provider</Txt>
            <Button
              analyticsTrackingId="terraform-provider-docs"
              href={
                "https://registry.terraform.io/providers/incident-io/incident/latest/docs"
              }
              openInNewTab
            >
              View Provider
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const SourceURLViewOrEdit = ({
  resourceType,
  resourceID,
  management,
}: {
  resourceType: ResourceTypeEnum;
  resourceID: string;
  management: ManagementMeta;
}) => {
  const [isEditing, setIsEditing] = useState(!management.source_url);

  if (isEditing || !management.source_url) {
    return (
      <SourceURLForm
        resourceType={resourceType}
        resourceID={resourceID}
        managementMeta={management}
        onSourceURLChange={() => setIsEditing(false)}
      />
    );
  }

  return (
    <SourceURLLink
      sourceURL={management.source_url}
      beginEdit={() => setIsEditing(true)}
      textClassName="!text-sm"
    />
  );
};

const ManagedInTFCallout = ({
  resourceType,
  resourceID,
  resourceName,
  onCloseDrawer,
}: {
  resourceType: ResourceTypeEnum;
  resourceID: string;
  resourceName: string;
  onCloseDrawer: () => void;
}) => {
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  return (
    <>
      <div>
        <Txt inline>
          This {resourceType} is currently managed in Terraform, so cannot be
          edited via the web UI. If you&rsquo;d like to make a change here, you
          can manage this {resourceType}{" "}
        </Txt>
        <span onClick={() => setShowDisconnectModal(true)}>
          <Txt className="underline hover:cursor-pointer" inline>
            in the dashboard
          </Txt>
        </span>
        <Txt inline> instead.</Txt>
      </div>
      {showDisconnectModal && (
        <DisconnectTerraformModal
          onClose={() => {
            setShowDisconnectModal(false);
            onCloseDrawer();
          }}
          resourceType={resourceType}
          resourceID={resourceID}
          resourceName={resourceName}
        />
      )}
    </>
  );
};

const ImportOrCreateRadio = ({
  resourceType,
  resourceID,
  terraform,
}: {
  resourceType: ResourceTypeEnum;
  resourceID: string;
  managementMeta: ManagementMeta;
  terraform: string;
}) => {
  const [importOrCreate, setImportOrCreate] = useState<"import" | "create">(
    "create",
  );

  const firstLineTFRegex = new RegExp(`resource "(.*)" "(.*)" {`);
  const parsedTF = firstLineTFRegex.exec(terraform);
  const parseSuccess = parsedTF && parsedTF.length === 3;

  return (
    <div className="flex flex-col gap-4 w-full">
      <RadioButtonGroup
        name="import_or_create"
        srLabel="import or create"
        value={importOrCreate}
        boxed
        onChange={(value) => setImportOrCreate(value as "import" | "create")}
        options={[
          {
            label: `Create a new ${resourceType} from Terraform`,
            value: "create",
          },
          {
            label: `Import this ${resourceType} into Terraform`,
            value: "import",
          },
        ]}
      />
      {importOrCreate === "import" && parseSuccess && (
        <>
          <Txt>
            To import this existing {resourceType} into Terraform state, first
            run the below command:
          </Txt>
          <CodeBlock
            theme="dark"
            hideHeader
            // terraform import incident_workflow.my workflow 01HEV9W32FA5QDJJ5BP59XS9ZN
            code={`terraform import ${parsedTF[1]}.${parsedTF[2]} ${resourceID}`}
          />
        </>
      )}
    </div>
  );
};
