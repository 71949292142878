import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemEscalate = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_escalate) {
    throw new Error(
      "malformed timeline item: incident_escalate was missing incident_escalate field",
    );
  }

  let title = "Incident escalated";
  if (item.content.incident_escalate.assignees !== "") {
    title = "Escalated to " + item.content.incident_escalate.assignees;
  }

  return {
    title: title,
    icon: IconEnum.Escalate,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_escalate.escalator,
  };
};
