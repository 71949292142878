/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageTemplate,
    StatusPageTemplateFromJSON,
    StatusPageTemplateFromJSONTyped,
    StatusPageTemplateToJSON,
} from './StatusPageTemplate';

/**
 * 
 * @export
 * @interface StatusPageUpdateTemplateResponseBody
 */
export interface StatusPageUpdateTemplateResponseBody {
    /**
     * 
     * @type {StatusPageTemplate}
     * @memberof StatusPageUpdateTemplateResponseBody
     */
    status_page_template: StatusPageTemplate;
}

export function StatusPageUpdateTemplateResponseBodyFromJSON(json: any): StatusPageUpdateTemplateResponseBody {
    return StatusPageUpdateTemplateResponseBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateTemplateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateTemplateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status_page_template': StatusPageTemplateFromJSON(json['status_page_template']),
    };
}

export function StatusPageUpdateTemplateResponseBodyToJSON(value?: StatusPageUpdateTemplateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_page_template': StatusPageTemplateToJSON(value.status_page_template),
    };
}

