import { DynamicMultiSelect, DynamicSingleSelect } from "@incident-ui";
import { DynamicMultiSelectProps } from "@incident-ui/Select/DynamicMultiSelect";
import { DynamicSingleSelectProps } from "@incident-ui/Select/DynamicSingleSelect";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const DynamicMultiSelectV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, DynamicMultiSelectProps>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    DynamicMultiSelectProps
  >(rest);
  const {
    // We don't want to pass the ref onwards here: react-select and our wrappers
    // have their own refs, so we always use a controlled input here.
    field: { ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <DynamicMultiSelect
        id={name}
        {...field}
        {...inputProps}
        onChange={(val: string[]) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

export const DynamicSingleSelectV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, DynamicSingleSelectProps>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    DynamicSingleSelectProps
  >(rest);
  const {
    // We don't want to pass the ref onwards here: react-select and our wrappers
    // have their own refs, so we always use a controlled input here.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <DynamicSingleSelect
        id={name}
        {...field}
        {...inputProps}
        onChange={(val: string | null) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};
