import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type ExpandableContentBoxProps = {
  titleNode: React.ReactNode;
  isExpanded: boolean;
  children: React.ReactNode;
};

export const ExpandableContentBox = ({
  titleNode,
  isExpanded,
  children,
}: ExpandableContentBoxProps) => {
  return (
    <div
      className={tcx(
        "rounded-2 border border-transparent",
        isExpanded && "bg-surface-secondary border-stroke",
      )}
    >
      <div className="bg-white py-2 px-3 rounded-2 border border-stroke-hover m-[-0.5px]">
        {titleNode}
      </div>
      {isExpanded && <div className="p-4">{children}</div>}
    </div>
  );
};
