import { useAPI } from "src/utils/swr";

export const useCatalogScope = ({
  allowAllOfACatalogType,
}: {
  allowAllOfACatalogType: boolean;
}) => {
  const {
    data: { scope: catalogScope },
    isLoading: catalogScopeLoading,
    error: catalogScopeError,
  } = useAPI(
    // Only fetch the catalog scope if we're allowing the user to start from a catalog type
    allowAllOfACatalogType ? "engineBuildScope" : null,
    {
      buildScopeRequestBody: {
        scope: [
          {
            type: "Catalog",
            label: "Catalog",
            key: "catalog",
            array: false,
          },
        ],
      },
    },
    { fallbackData: { scope: { references: [], aliases: {} } } },
  );

  return {
    catalogScope,
    catalogScopeLoading,
    catalogScopeError,
  };
};
