import type { SVGProps } from "react";
import * as React from "react";
const SvgSumoLogic = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#009"
      d="M4.307 5.138c.172.04.476.121.901.212 1.245.284 1.923.587 2.206 1.164v1.548c-.334.76-1.214 1.235-2.459 1.235-1.164 0-1.983-.293-2.955-1.154l.9-1.001c.446.364.8.597 1.104.718.314.132.647.202.992.192.475 0 .84-.131.991-.374v-.536c-.111-.162-.313-.253-.536-.324-.172-.03-.466-.102-.9-.203-1.215-.263-1.822-.556-2.085-1.072V4.004C2.799 3.296 3.639 2.8 4.854 2.8c1.052 0 1.781.253 2.631.961l-.84 1.012c-.718-.566-1.174-.718-1.832-.718-.475 0-.82.142-.961.364v.466c.081.111.243.192.455.253ZM17.615 9.115V3.002h-1.558v3.684c0 .82-.476 1.265-1.255 1.265-.668 0-1.012-.374-1.012-1.012V3.002h-1.559v4.352c0 1.194.719 1.943 2.166 1.943.87 0 1.468-.303 1.791-.83v.648h1.427Z"
    />
    <path
      fill="#009"
      fillRule="evenodd"
      d="M15.055 10.684c-1.407 0-2.51.759-2.966 1.973v2.57c.446 1.215 1.559 1.974 2.966 1.974 1.406 0 2.5-.759 2.945-1.963v-2.591c-.456-1.204-1.559-1.963-2.945-1.963Zm-4.089 2.054v4.27H9.408v-3.804c0-.73-.294-1.174-.972-1.174-.678 0-1.073.506-1.073 1.214v3.765H5.805v-3.806c0-.779-.324-1.173-.972-1.173-.688 0-1.072.506-1.072 1.214v3.765H2.202v-6.113h1.447v.678c.354-.587.941-.88 1.74-.88.78 0 1.367.324 1.701.9.415-.597 1.032-.9 1.832-.9 1.305 0 2.044.779 2.044 2.044Zm5.525 2.105c-.243.648-.759 1.032-1.447 1.032-.678 0-1.214-.384-1.457-1.032v-1.801c.243-.648.759-1.043 1.457-1.043.688 0 1.194.395 1.437 1.043l.01 1.801Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSumoLogic;
