/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamInstall,
    TeamInstallFromJSON,
    TeamInstallFromJSONTyped,
    TeamInstallToJSON,
} from './TeamInstall';

/**
 * 
 * @export
 * @interface IntegrationsMicrosoftGetConfigResponseBody
 */
export interface IntegrationsMicrosoftGetConfigResponseBody {
    /**
     * The latest bot version
     * @type {string}
     * @memberof IntegrationsMicrosoftGetConfigResponseBody
     */
    latest_bot_version: string;
    /**
     * Missing Graph scopes from the management Graph app
     * @type {Array<string>}
     * @memberof IntegrationsMicrosoftGetConfigResponseBody
     */
    missing_graph_scopes: Array<string>;
    /**
     * List of team installs
     * @type {Array<TeamInstall>}
     * @memberof IntegrationsMicrosoftGetConfigResponseBody
     */
    team_installs: Array<TeamInstall>;
}

export function IntegrationsMicrosoftGetConfigResponseBodyFromJSON(json: any): IntegrationsMicrosoftGetConfigResponseBody {
    return IntegrationsMicrosoftGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsMicrosoftGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsMicrosoftGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latest_bot_version': json['latest_bot_version'],
        'missing_graph_scopes': json['missing_graph_scopes'],
        'team_installs': ((json['team_installs'] as Array<any>).map(TeamInstallFromJSON)),
    };
}

export function IntegrationsMicrosoftGetConfigResponseBodyToJSON(value?: IntegrationsMicrosoftGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latest_bot_version': value.latest_bot_version,
        'missing_graph_scopes': value.missing_graph_scopes,
        'team_installs': ((value.team_installs as Array<any>).map(TeamInstallToJSON)),
    };
}

