import { Product } from "@incident-shared/billing";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Loader, SharedToasts } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import {
  DefaultCallURLSettings,
  IncidentCallSettings,
  ScopeNameEnum,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../SettingsSection";

export const AutomationDefaultCallURLEditForm = () => {
  const isAuthenticated = useIsAuthenticated();
  const { data, isLoading } = useAPI(
    isAuthenticated ? "incidentCallSettingsShow" : null,
    undefined,
  );
  if (isLoading) {
    return <Loader />;
  }

  if (!data?.settings) {
    return null;
  }

  return <AutomationDefaultCallURLEditFormInner settings={data.settings} />;
};
const toForm = (settings: IncidentCallSettings): DefaultCallURLSettings =>
  settings.default_call_url;

const AutomationDefaultCallURLEditFormInner = ({
  settings,
}: {
  settings: IncidentCallSettings;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const showToast = useToast();
  const formMethods = useForm<DefaultCallURLSettings>({
    defaultValues: toForm(settings),
  });
  const { setError, reset } = formMethods;

  const mutation = useAPIMutation(
    "incidentCallSettingsShow",
    undefined,
    async (apiClient, data: DefaultCallURLSettings) =>
      await apiClient.incidentCallSettingsUpdateDefaultCallURL({
        updateDefaultCallURLRequestBody: {
          default_call_url: data,
        },
      }),
    {
      setError,
      onSuccess: async ({ settings }) => {
        reset(toForm(settings));
        showToast(SharedToasts.SETTINGS_SAVED);
      },
    },
  );

  return (
    <SettingsSection
      requiredProduct={Product.Response}
      mutation={mutation}
      formMethods={formMethods}
      enabledPath="enabled"
      title="Default Call URL"
      explanation={`Call URLs can be set and changed on a per-incident basis. If
  you would rather start every incident with the same persistent
  call URL, you can set one here.`}
    >
      <InputV2
        formMethods={formMethods}
        name="config.url"
        type={InputType.Text}
        disabled={!canEditSettings}
        placeholder={"e.g., https://meet.google.com/yhs-hphm-wbx"}
      />
    </SettingsSection>
  );
};
