/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelecomTwilioMessageStatusRequestBody
 */
export interface TelecomTwilioMessageStatusRequestBody {
    /**
     * Twilio's unique ID of the message
     * @type {string}
     * @memberof TelecomTwilioMessageStatusRequestBody
     */
    MessageSid: string;
    /**
     * The status of the message
     * @type {string}
     * @memberof TelecomTwilioMessageStatusRequestBody
     */
    MessageStatus: TelecomTwilioMessageStatusRequestBodyMessageStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum TelecomTwilioMessageStatusRequestBodyMessageStatusEnum {
    Queued = 'queued',
    Failed = 'failed',
    Sent = 'sent',
    Delivered = 'delivered',
    Undelivered = 'undelivered'
}

export function TelecomTwilioMessageStatusRequestBodyFromJSON(json: any): TelecomTwilioMessageStatusRequestBody {
    return TelecomTwilioMessageStatusRequestBodyFromJSONTyped(json, false);
}

export function TelecomTwilioMessageStatusRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelecomTwilioMessageStatusRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'MessageSid': json['MessageSid'],
        'MessageStatus': json['MessageStatus'],
    };
}

export function TelecomTwilioMessageStatusRequestBodyToJSON(value?: TelecomTwilioMessageStatusRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MessageSid': value.MessageSid,
        'MessageStatus': value.MessageStatus,
    };
}

