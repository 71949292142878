import { CatalogConfigType } from "@incident-io/api";
import { CatalogTypeBadge } from "@incident-shared/attribute";
import { BadgeSize, Link } from "@incident-ui";
import {
  CodeSnippet,
  CodeSnippetTheme,
} from "@incident-ui/CodeSnippet/CodeSnippet";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";

export const AlertSourceAlertManagerTaggingInfo = ({
  catalogType,
}: {
  catalogType: CatalogConfigType;
}): React.ReactElement => {
  const suggestedTagKey = catalogType.name.toLowerCase().split(" ").join("-");

  return (
    <div className="p-4 border-stroke-primary border rounded-2">
      <NumberedList>
        <div>
          Add a{" "}
          <CodeSnippet theme={CodeSnippetTheme.White}>
            {suggestedTagKey}
          </CodeSnippet>{" "}
          label to each of your{" "}
          <Link
            href="https://prometheus.io/docs/prometheus/latest/configuration/alerting_rules/"
            openInNewTab
            analyticsTrackingId="alertmanager-tagging-instructions-view-docs"
          >
            Alert Manager alert rules
          </Link>
          , referencing the relevant{" "}
          <CatalogTypeBadge
            type={catalogType.engine_type_name}
            size={BadgeSize.Small}
            className="align-middle"
          />{" "}
          using one of the identifiers below
        </div>
        <div>
          Parse your{" "}
          <CodeSnippet theme={CodeSnippetTheme.White}>
            {suggestedTagKey}
          </CodeSnippet>{" "}
          label into an alert attribute within this Alert Manager alert source
        </div>
      </NumberedList>
    </div>
  );
};
