import {
  PostIncidentTaskConfig,
  PostIncidentTaskConfigTaskTypeEnum,
  PostIncidentTaskOptionTaskTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui";
import React from "react";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../@shared/settings/SettingsList/SettingsListItem";

export const PostIncidentTaskRow = ({
  task,
}: {
  task: PostIncidentTaskConfig;
}): React.ReactElement => {
  const { trigger: deleteItem } = useAPIMutation(
    "postIncidentFlowList",
    undefined,
    async (apiClient, { id }) => {
      await apiClient.postIncidentFlowDestroyTaskConfig({ id });
    },
  );

  const isCustom = task.task_type === PostIncidentTaskConfigTaskTypeEnum.Custom;

  return (
    <SettingsListItem
      icon={TaskTypeToIcon(
        // task_type is a PostIncidentTaskConfigTaskTypeEnum which is
        // identical to PostIncidentTaskOptionTaskTypeEnum but
        // unfortunately we have 2 separate types due to the way we are
        // generating the types from our api.
        task.task_type as unknown as PostIncidentTaskOptionTaskTypeEnum,
      )}
      title={task.title}
      badgeNode={
        <div className="flex gap-2 items-center">
          {task.unskippable && (
            <Badge theme={BadgeTheme.Info} size={BadgeSize.ExtraSmall}>
              Required
            </Badge>
          )}
          {isCustom && (
            <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
              Custom task
            </Badge>
          )}
        </div>
      }
      descriptionPosition="inline"
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        edit: {
          editHref: `/settings/post-incident-flow/${task.incident_status_id}/${task.id}/edit`,
        },
        delete: {
          resourceTitle: task.title,
          onDelete: () => deleteItem({ id: task.id }),
          deleteConfirmationTitle: "Delete post-incident task",
          deleteConfirmationContent: (
            <>
              Are you sure you want to delete the{" "}
              <span className="font-bold">{task.title}</span> post-incident
              task?
            </>
          ),
        },
      }}
    />
  );
};
