import { CommaSeparatedConditions } from "@incident-shared/engine/conditions/CommaSeparatedConditions";
import {
  conditionGroupsToConditions,
  conditionsToGroupPayload,
} from "@incident-shared/engine/conditions/marshall";
import { ConditionsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionsEditorV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { omit } from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Condition,
  ConditionSubjectIconEnum,
  EngineScope,
  InternalStatusPageAutomationRule,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { v4 as uuidv4 } from "uuid";

type Rule = InternalStatusPageAutomationRule;

const PLACEHOLDER_ID = "placeholder";

export const AutomationRulesForm = ({
  incidentScope,
  statusPageId,
}: {
  incidentScope: EngineScope;
  statusPageId: string;
}): React.ReactElement => {
  const [deleteRuleId, setDeleteRuleId] = useState<string | null>(null);
  const [ruleModalState, setRuleModalState] = useState<{
    showModal: boolean;
    rule?: Rule;
  }>({ showModal: false });
  const [showResyncModal, setShowResyncModal] = useState<boolean>(false);

  const {
    data: { automation_rules: rules },
    isLoading: loading,
  } = useAPI(
    "internalStatusPageListAutomationRules",
    { id: statusPageId },
    {
      fallbackData: { automation_rules: [] },
    },
  );

  const { trigger: onDelete, isMutating: isDeleting } = useAPIMutation(
    "internalStatusPageListAutomationRules",
    { id: statusPageId },
    async (apiClient, { id }: { id: string }) =>
      await apiClient.internalStatusPageDestroyAutomationRule({ id }),
    {
      onSuccess: () => setDeleteRuleId(null),
    },
  );

  const placeholderRule = createPlaceholderRule(statusPageId);

  const showToast = useToast();
  const { isMutating: placeholderRuleSaving, trigger: savePlaceholderRule } =
    useAPIMutation(
      "internalStatusPageListAutomationRules",
      { id: statusPageId },
      async (apiClient, data) => {
        await apiClient.internalStatusPageCreateAutomationRule({
          createAutomationRuleRequestBody: {
            internal_status_page_id: statusPageId,
            only_if_affects_component: data.only_if_affects_component,
            condition_groups: conditionsToGroupPayload(data.conditions),
          },
        });
      },
      {
        onError: () =>
          showToast({
            theme: ToastTheme.Error,
            title: "Something went wrong",
          }),
      },
    );

  return (
    <>
      {ruleModalState.showModal && incidentScope && (
        <AutomationRuleCreateOrEditModal
          statusPageId={statusPageId}
          scope={incidentScope}
          existingRule={ruleModalState.rule}
          onClose={() => setRuleModalState({ showModal: false })}
          onComplete={() => {
            setRuleModalState({ showModal: false });
          }}
        />
      )}
      {showResyncModal && (
        <ResyncIncidentsModal
          statusPageId={statusPageId}
          onClose={() => setShowResyncModal(false)}
        />
      )}
      <div className="space-y-4">
        <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke">
          <h3 className="font-medium mb-2">Automation rules</h3>
          <LoadingWrapper loading={loading}>
            <Form.Helptext>
              Set up rules for which incidents that you&apos;d like to
              automatically push to your internal status page, such as &apos;all
              critical incidents&apos;.
            </Form.Helptext>
            <Form.Helptext>
              You can also manually push incidents to your internal status page
              from Slack, with <code>/incident statuspage</code>. Only active
              incidents will be automatically added.
            </Form.Helptext>
            <Form.Helptext className="mb-4">
              Updating these rules will only affect future incidents - it
              won&apos;t remove existing ones that no longer match your
              conditions. If you&apos;d like the incidents on your page to
              reflect your new rules, press{" "}
              <span className="font-semibold">resync incidents</span> below.
            </Form.Helptext>
            {rules.length === 0 ? (
              <>
                <h3 className="font-medium text-sm mb-2">
                  Here&apos;s an example rule to get you started, would you like
                  to add it?
                </h3>
                <StackedList className="mb-4">
                  <AutomationRuleRow
                    isFirst
                    isPlaceholder
                    isSaving={placeholderRuleSaving}
                    rule={placeholderRule}
                    onEdit={() => {
                      savePlaceholderRule(placeholderRule);
                    }}
                  />
                </StackedList>
              </>
            ) : (
              <StackedList className={"mb-4"}>
                {rules.map((rule, i) => (
                  <AutomationRuleRow
                    isFirst={i === 0}
                    key={rule.id}
                    rule={rule}
                    onDelete={() => setDeleteRuleId(rule.id)}
                    onEdit={() => {
                      setRuleModalState({ showModal: true, rule });
                    }}
                  />
                ))}
              </StackedList>
            )}
            <GatedButton
              theme={ButtonTheme.Secondary}
              analyticsTrackingId="add-automation-rule"
              onClick={() => {
                setRuleModalState({ showModal: true });
              }}
              requiredScope={ScopeNameEnum.StatusPagesConfigure}
            >
              Add automation rule
            </GatedButton>
            <ConfirmationDialog
              title="Delete automation rule"
              isOpen={deleteRuleId != null}
              analyticsTrackingId="delete-automation-rule"
              onCancel={() => setDeleteRuleId(null)}
              onConfirm={() => onDelete({ id: deleteRuleId ?? "" })}
              saving={isDeleting}
            >
              Are you sure you want to delete this automation rule?
            </ConfirmationDialog>
          </LoadingWrapper>
        </div>
        <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke">
          <h3 className="font-medium mb-2">Resync incidents</h3>
          <Form.Helptext>
            Refresh your internal status page, removing all incidents that no
            longer match your current automation rules, and adding new incidents
            that now fit your criteria.
          </Form.Helptext>
          <GatedButton
            theme={ButtonTheme.Secondary}
            analyticsTrackingId="resync-internal-status-page-incidents"
            onClick={() => setShowResyncModal(true)}
            requiredScope={ScopeNameEnum.StatusPagesConfigure}
          >
            Resync incidents
          </GatedButton>
        </div>
      </div>
    </>
  );
};

type AutomationRuleRowProps = {
  rule: Rule;
  onEdit: () => void;
} & (
  | {
      isFirst: boolean;
      isPlaceholder?: never;
      isSaving?: never;
      onDelete: () => void;
    }
  | {
      isFirst: true;
      isPlaceholder: true;
      isSaving: boolean;
      onDelete?: never;
    }
);

function AutomationRuleRow({
  rule,
  isPlaceholder,
  isSaving,
  isFirst,
  onEdit,
  onDelete,
}: AutomationRuleRowProps): React.ReactElement {
  let conditions = conditionGroupsToConditions(rule.condition_groups);

  if (rule.only_if_affects_component) {
    conditions = [
      {
        subject: {
          label: "A component on this status page",
          icon: ConditionSubjectIconEnum.Briefcase,
          reference: "",
        },
        param_bindings: [],
        params: [],
        operation: { label: "is affected", value: "" },
      },
      ...conditions,
    ];
  }

  return (
    <li
      className={
        "px-4 pt-3 pb-2 flex flex-row content-between bg-white text-sm"
      }
    >
      <div
        className={tcx("grow flex flex-row", {
          "opacity-50": isPlaceholder,
        })}
      >
        <div className={"font-medium flex-shrink-0 w-16 my-1.5 text-slate-700"}>
          {isFirst ? "When" : "Or"}...
        </div>
        <div className={"grow flex items-center gap-1 flex-wrap"}>
          <CommaSeparatedConditions conditions={conditions} theme="naked" />
        </div>
      </div>
      {isPlaceholder ? (
        <Button
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={"default-rule-confirm"}
          onClick={onEdit}
          loading={isSaving}
        >
          Add
        </Button>
      ) : (
        <div
          className={"shrink-0 mt-1.5 text-slate-600 flex gap-1 items-start"}
        >
          <Button
            analyticsTrackingId="expression-condition-edit-rule"
            theme={ButtonTheme.Naked}
            onClick={onEdit}
            icon={IconEnum.Edit}
            title="Edit"
          />
          <Button
            analyticsTrackingId="expression-condition-remove-rule"
            theme={ButtonTheme.Naked}
            onClick={onDelete}
            icon={IconEnum.Delete}
            title="Remove rule"
          />
        </div>
      )}
    </li>
  );
}

type automationRuleFormType = Omit<
  InternalStatusPageAutomationRule,
  "condition_groups"
> & {
  conditions: Condition[];
};

export const AutomationRuleCreateOrEditModal = ({
  existingRule,
  scope,
  onClose,
  onComplete,
  statusPageId,
}: {
  scope: EngineScope;
  existingRule: Rule | undefined;
  onComplete: () => void;
  onClose: () => void;
  statusPageId: string;
}): React.ReactElement | null => {
  const defaultValues: automationRuleFormType = existingRule
    ? parseExistingRule(existingRule)
    : {
        id: uuidv4(),
        only_if_affects_component: true,
        conditions: [],
        created_at: new Date(),
        updated_at: new Date(),
        internal_status_page_id: statusPageId,
      };

  const formMethods = useForm<automationRuleFormType>({
    defaultValues,
  });

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "internalStatusPageListAutomationRules",
    { id: statusPageId },
    async (apiClient, data: automationRuleFormType) => {
      if (existingRule && existingRule.id !== "placeholder") {
        await apiClient.internalStatusPageUpdateAutomationRule({
          id: existingRule.id,
          updateAutomationRuleRequestBody: {
            only_if_affects_component: data.only_if_affects_component,
            condition_groups: conditionsToGroupPayload(data.conditions),
          },
        });
      } else {
        await apiClient.internalStatusPageCreateAutomationRule({
          createAutomationRuleRequestBody: {
            internal_status_page_id: statusPageId,
            only_if_affects_component: data.only_if_affects_component,
            condition_groups: conditionsToGroupPayload(data.conditions),
          },
        });
      }
    },
    {
      setError: formMethods.setError,
      onSuccess: onComplete,
    },
  );

  const { data: pageData, isLoading: pageDataLoading } = useAPI(
    "internalStatusPageShowPage",
    { id: statusPageId },
  );
  const componentFieldName =
    pageData?.internal_status_page.component_custom_field.name ?? "selected";

  const onlyIfAffectsComponent = formMethods.watch("only_if_affects_component");

  return (
    <Form.Modal
      onSubmit={onSubmit}
      formMethods={formMethods}
      onClose={onClose}
      analyticsTrackingId={
        existingRule ? "create-announcement-rule" : "edit-announcement-rule"
      }
      title={
        existingRule ? "Edit automation rule" : "Create new automation rule"
      }
      disableQuickClose
      loading={saving || pageDataLoading}
      footer={
        <ModalFooter
          confirmButtonText={existingRule ? "Save" : "Create"}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <ToggleV2
        formMethods={formMethods}
        name="only_if_affects_component"
        label="Only add incidents that impact a component on this status page"
      />
      <Form.Helptext className="!mt-2">
        If enabled, only active incidents that affect one of the visible
        components from the &lsquo;{componentFieldName}&rsquo; field, and match
        the other conditions below, will be automatically added to your internal
        status page.
      </Form.Helptext>
      <ConditionsEditorV2
        formMethods={formMethods}
        wrapperClassName="!bg-white"
        name="conditions"
        scope={scope}
        entityNameLabel={"announcement rule"}
        subjectsLabel={"incidents"}
        emptyIntroSentence={
          <>
            {onlyIfAffectsComponent ? (
              <>
                Are there other conditions an incident must match to be
                automatically pushed to your internal status page?
              </>
            ) : (
              <>
                Under what conditions would you like to automatically push to
                your internal status page?
              </>
            )}
          </>
        }
        populatedIntroSentence="Incidents will be automatically added to this page when..."
      />
    </Form.Modal>
  );
};

export const ResyncIncidentsModal = ({
  statusPageId,
  onClose,
}: {
  statusPageId: string;
  onClose: () => void;
}): React.ReactElement | null => {
  const showToast = useToast();

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "internalStatusPageResyncIncidents",
    { id: statusPageId },
    async () => {
      return;
    },
    {
      onError: () =>
        showToast({
          theme: ToastTheme.Error,
          title: `Could not resync incidents. Please try again, or contact us if the problem persists.`,
        }),
      onSuccess: () => {
        onClose();
        showToast({
          theme: ToastTheme.Success,
          title: `Resync requested successfully. This will be completed in the next 5 minutes.`,
        });
      },
    },
  );

  return (
    <Modal
      isOpen
      title={"Resync internal status page incidents"}
      analyticsTrackingId="resolve-incident"
      onClose={onClose}
      as="div"
    >
      <ModalContent className="space-y-4">
        <div className="space-y-1">
          <p className="text-sm">
            Incidents that do not match your current automation rules will be
            removed from your page.
          </p>
          <p className="text-sm">
            Manually added incidents will remain on your page.
          </p>
          <p className="text-sm">
            Incidents that match your new criteria will be added to your page.
          </p>
        </div>
        <Callout theme={CalloutTheme.Info}>
          This action will process in the background and be completed in the
          next 5 minutes.
        </Callout>
      </ModalContent>
      <ModalFooter
        confirmButtonText="Resync incidents"
        saving={saving}
        onClose={onClose}
        onConfirm={() => onSubmit({})}
        confirmButtonType="button"
      />
    </Modal>
  );
};

function createPlaceholderRule(statusPageId: string) {
  return {
    only_if_affects_component: true,
    condition_groups: [],
    created_at: new Date(),
    updated_at: new Date(),
    id: PLACEHOLDER_ID,
    internal_status_page_id: statusPageId,
  };
}

function parseExistingRule(
  rule: InternalStatusPageAutomationRule,
): automationRuleFormType {
  return {
    ...omit(rule, "condition_groups"),
    conditions: conditionGroupsToConditions(rule.condition_groups),
  };
}
