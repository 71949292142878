/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageComponent,
    StatusPageComponentFromJSON,
    StatusPageComponentFromJSONTyped,
    StatusPageComponentToJSON,
} from './StatusPageComponent';

/**
 * 
 * @export
 * @interface StatusPageUpdateComponentResponseBody
 */
export interface StatusPageUpdateComponentResponseBody {
    /**
     * 
     * @type {StatusPageComponent}
     * @memberof StatusPageUpdateComponentResponseBody
     */
    component: StatusPageComponent;
}

export function StatusPageUpdateComponentResponseBodyFromJSON(json: any): StatusPageUpdateComponentResponseBody {
    return StatusPageUpdateComponentResponseBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateComponentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateComponentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': StatusPageComponentFromJSON(json['component']),
    };
}

export function StatusPageUpdateComponentResponseBodyToJSON(value?: StatusPageUpdateComponentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': StatusPageComponentToJSON(value.component),
    };
}

