import type { SVGProps } from "react";
import * as React from "react";
const SvgExitIcon = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.25 10a.75.75 0 0 0-.75.75v1.5a.25.25 0 0 1-.25.25H9V7.259c0-.558-.269-1.087-.721-1.415L5.056 3.5h5.194a.25.25 0 0 1 .25.25v2a.75.75 0 0 0 1.5 0v-2C12 2.785 11.215 2 10.25 2h-6.5C2.785 2 2 2.785 2 3.75v8.991c0 .558.269 1.087.721 1.415l4.294 3.124A1.248 1.248 0 0 0 9 16.269v-2.268h1.25c.965 0 1.75-.785 1.75-1.75v-1.5a.75.75 0 0 0-.75-.75V10Z"
    />
    <path
      fill="currentColor"
      d="M15.03 4.97a.75.75 0 1 0-1.061 1.061l1.47 1.47H11.25a.75.75 0 0 0 0 1.5h4.189l-1.47 1.47a.75.75 0 0 0 1.06 1.061l2.75-2.75a.75.75 0 0 0 0-1.061l-2.75-2.75.001-.001Z"
    />
  </svg>
);
export default SvgExitIcon;
