/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntry,
    CatalogEntryFromJSON,
    CatalogEntryFromJSONTyped,
    CatalogEntryToJSON,
} from './CatalogEntry';
import {
    EscalationPathTargetPreview,
    EscalationPathTargetPreviewFromJSON,
    EscalationPathTargetPreviewFromJSONTyped,
    EscalationPathTargetPreviewToJSON,
} from './EscalationPathTargetPreview';

/**
 * 
 * @export
 * @interface IncidentFormsPreviewEscalateFormResponseBody
 */
export interface IncidentFormsPreviewEscalateFormResponseBody {
    /**
     * 
     * @type {Array<CatalogEntry>}
     * @memberof IncidentFormsPreviewEscalateFormResponseBody
     */
    catalog_entries?: Array<CatalogEntry>;
    /**
     * 
     * @type {{ [key: string]: Array<CatalogEntry>; }}
     * @memberof IncidentFormsPreviewEscalateFormResponseBody
     */
    for_opsgenie_team?: { [key: string]: Array<CatalogEntry>; };
    /**
     * 
     * @type {{ [key: string]: Array<CatalogEntry>; }}
     * @memberof IncidentFormsPreviewEscalateFormResponseBody
     */
    for_pagerduty_service?: { [key: string]: Array<CatalogEntry>; };
    /**
     * 
     * @type {{ [key: string]: Array<EscalationPathTargetPreview>; }}
     * @memberof IncidentFormsPreviewEscalateFormResponseBody
     */
    for_paths?: { [key: string]: Array<EscalationPathTargetPreview>; };
    /**
     * 
     * @type {{ [key: string]: Array<EscalationPathTargetPreview>; }}
     * @memberof IncidentFormsPreviewEscalateFormResponseBody
     */
    for_users?: { [key: string]: Array<EscalationPathTargetPreview>; };
}

export function IncidentFormsPreviewEscalateFormResponseBodyFromJSON(json: any): IncidentFormsPreviewEscalateFormResponseBody {
    return IncidentFormsPreviewEscalateFormResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsPreviewEscalateFormResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsPreviewEscalateFormResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entries': !exists(json, 'catalog_entries') ? undefined : ((json['catalog_entries'] as Array<any>).map(CatalogEntryFromJSON)),
        'for_opsgenie_team': !exists(json, 'for_opsgenie_team') ? undefined : json['for_opsgenie_team'],
        'for_pagerduty_service': !exists(json, 'for_pagerduty_service') ? undefined : json['for_pagerduty_service'],
        'for_paths': !exists(json, 'for_paths') ? undefined : json['for_paths'],
        'for_users': !exists(json, 'for_users') ? undefined : json['for_users'],
    };
}

export function IncidentFormsPreviewEscalateFormResponseBodyToJSON(value?: IncidentFormsPreviewEscalateFormResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entries': value.catalog_entries === undefined ? undefined : ((value.catalog_entries as Array<any>).map(CatalogEntryToJSON)),
        'for_opsgenie_team': value.for_opsgenie_team,
        'for_pagerduty_service': value.for_pagerduty_service,
        'for_paths': value.for_paths,
        'for_users': value.for_users,
    };
}

