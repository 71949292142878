import { ResourceFieldConfigColorEnum } from "@incident-io/api";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Badge, BadgeSize, BadgeTheme, IconEnum, Tooltip } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { TruncatingReferenceLabel } from "./TruncatingReferenceLabel";

export type EngineLiteralBadgeProps = {
  icon?: IconEnum;
  color?: ColorPaletteEnum | ResourceFieldConfigColorEnum;
  label: string;
  className?: string;
  onDelete?: () => void;
  noTooltip?: boolean;
  clickable?: boolean;
  mini?: boolean;
  suffixNode?: React.ReactNode;
};

export const EngineLiteralBadge = ({
  icon,
  color = ColorPaletteEnum.Blue,
  label,
  mini,
  className,
  onDelete,
  noTooltip = false,
  clickable,
  suffixNode,
}: EngineLiteralBadgeProps): React.ReactElement => {
  const palette = getColorPalette(color as unknown as ColorPaletteEnum);

  return (
    <Tooltip
      content={<div className="text-xs break-words font-medium">{label}</div>}
      side="top"
      disabled={noTooltip}
    >
      <Badge
        className={tcx(
          "bg-surface-secondary max-w-[300px] truncate w-fit",
          clickable && "cursor-pointer hover:bg-surface-tertiary",
          className,
        )}
        theme={BadgeTheme.Unstyled}
        icon={icon}
        iconClassName={palette.icon}
        size={mini ? BadgeSize.Small : BadgeSize.Medium}
        onClose={onDelete}
      >
        <div className="flex items-center flex-nowrap truncate min-w-0">
          <TruncatingReferenceLabel label={label} separatorStyle="chevrons" />
        </div>
        {suffixNode}
      </Badge>
    </Tooltip>
  );
};
