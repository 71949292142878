/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface DebriefsUpdateDebriefTitleAndDescriptionRequestBody
 */
export interface DebriefsUpdateDebriefTitleAndDescriptionRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof DebriefsUpdateDebriefTitleAndDescriptionRequestBody
     */
    event_description?: TextNode;
    /**
     * 
     * @type {TextNode}
     * @memberof DebriefsUpdateDebriefTitleAndDescriptionRequestBody
     */
    event_title?: TextNode;
}

export function DebriefsUpdateDebriefTitleAndDescriptionRequestBodyFromJSON(json: any): DebriefsUpdateDebriefTitleAndDescriptionRequestBody {
    return DebriefsUpdateDebriefTitleAndDescriptionRequestBodyFromJSONTyped(json, false);
}

export function DebriefsUpdateDebriefTitleAndDescriptionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsUpdateDebriefTitleAndDescriptionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event_description': !exists(json, 'event_description') ? undefined : TextNodeFromJSON(json['event_description']),
        'event_title': !exists(json, 'event_title') ? undefined : TextNodeFromJSON(json['event_title']),
    };
}

export function DebriefsUpdateDebriefTitleAndDescriptionRequestBodyToJSON(value?: DebriefsUpdateDebriefTitleAndDescriptionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_description': TextNodeToJSON(value.event_description),
        'event_title': TextNodeToJSON(value.event_title),
    };
}

