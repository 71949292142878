import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { AnimatePresence } from "framer-motion";
import { sortBy } from "lodash";
import { useState } from "react";
import {
  Action,
  ActionStatusEnum,
  Incident,
  IncidentVisibilityEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { BodyTabs } from "../body/IncidentBody";
import { isIncidentClosedOrResolved } from "../body/Overview";
import { ActionDeleteModal } from "./actions/ActionDeleteModal";
import { ActionEditModal } from "./actions/ActionEditModal";
import { IncidentStackedList } from "./Components";

export const ActionsStackedList = ({
  actions,
  incident,
  onCreateAction,
  onTabChange,
  isLoading,
}: {
  actions: Action[];
  incident: Incident | null;
  onTabChange: (tab: BodyTabs) => void;
  isLoading: boolean;
  onCreateAction: () => void;
}): React.ReactElement => {
  const [editingAction, setEditingAction] = useState<Action | null>(null);
  const [deletingAction, setDeletingAction] = useState<Action | null>(null);

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  const open = actions.filter(
    (action) => action.status === ActionStatusEnum.Outstanding,
  );

  const refetchActions = useAPIRefetch("actionsList", {
    incidentId: incident?.id ?? "",
  });

  const isClosed = incident ? isIncidentClosedOrResolved(incident) : false;

  const {
    trigger: onClickConvertToFollowUps,
    isMutating: savingConvertToFollowups,
  } = useAPIMutation(
    "followUpsList",
    { incidentId: incident?.id ?? "" },
    async (apiClient, { actions }: { actions: Action[] }) => {
      await Promise.all(
        actions.map((action) => {
          if (action.status === ActionStatusEnum.Outstanding) {
            return apiClient.actionsConvertToFollowUp({
              id: action.id,
            });
          }
          return Promise.resolve();
        }),
      );
    },
    {
      onSuccess: async () => {
        await refetchActions();
        onTabChange(BodyTabs.FollowUps);
      },
    },
  );

  if (actions.length === 0) {
    // When there are no actions, we add a button to the bottom instead.
    return <></>;
  }

  return (
    <>
      <IncidentStackedList.Section
        title="Actions"
        loading={isLoading}
        titleAccessory={
          <Tooltip content="Create action">
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId="create-action"
              icon={IconEnum.Add}
              title=""
              onClick={onCreateAction}
            />
          </Tooltip>
        }
      >
        {isClosed && open.length > 0 ? (
          <div className="flex gap-2 items-center h-11 justify-between text-xs-med px-4">
            <div className="flex gap-2 items-center">
              <Icon
                id={IconEnum.Info}
                className="text-red-content"
                size={IconSize.Small}
              />
              <div className="truncate grow text-red-content">
                Closed incidents should not have open actions, should these be
                follow-ups?
              </div>
            </div>
            <Button
              analyticsTrackingId={`convert-all-to-follow-up`}
              type="button"
              onClick={() => onClickConvertToFollowUps({ actions })}
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.Small}
              icon={IconEnum.FastForward}
              loading={savingConvertToFollowups}
            >
              <span className="mobile-hidden">Convert to follow-ups</span>
            </Button>
          </div>
        ) : null}
        {sortActions(actions)
          // These should get filtered at the API layer, but just in case
          .filter((a) => a.status !== ActionStatusEnum.Deleted)
          .map((action) => {
            return (
              <ActionItem
                key={action.id}
                action={action}
                onEdit={(action) => setEditingAction(action)}
                onDelete={(action) => setDeletingAction(action)}
                onConvertToFollowUp={(action) =>
                  onClickConvertToFollowUps({ actions: [action] })
                }
              />
            );
          })}
      </IncidentStackedList.Section>
      <AnimatePresence>
        {editingAction && (
          <ActionEditModal
            isPrivateIncident={isPrivate}
            action={editingAction}
            onClose={() => setEditingAction(null)}
          />
        )}
        {deletingAction && (
          <ActionDeleteModal
            action={deletingAction}
            onClose={() => setDeletingAction(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const ActionItem = ({
  action,
  onEdit,
  onDelete,
  onConvertToFollowUp,
}: {
  action: Action;
  onEdit: (action: Action) => void;
  onDelete: (action: Action) => void;
  onConvertToFollowUp: (action: Action) => void;
}) => {
  return (
    <IncidentStackedList.Item
      key={action.id}
      iconNode={<ActionsCheckmark action={action} />}
      onClick={() => onEdit(action)}
      title={
        <div
          className={
            action.status === ActionStatusEnum.NotDoing
              ? "text-content-tertiary"
              : ""
          }
        >
          {action.description}
        </div>
      }
      accessoryOne={
        action.assignee ? (
          <Tooltip content={action.assignee.name}>
            <Avatar
              size={IconSize.Small}
              url={action.assignee.avatar_url}
              name={action.assignee.name}
              noTitle
            />
          </Tooltip>
        ) : (
          <Button
            icon={IconEnum.UserAdd}
            theme={ButtonTheme.Naked}
            onClick={() => onEdit(action)}
            title="Assign"
            analyticsTrackingId={null}
          />
        )
      }
      accessoryTwo={
        <DropdownMenu
          triggerButtonTheme={ButtonTheme.Naked}
          triggerIconSize={IconSize.Small}
          triggerIcon={IconEnum.DotsVerticalNopad}
          analyticsTrackingId="action-more-options"
          screenReaderText="More options"
        >
          <DropdownMenuGroup>
            <DropdownMenuItem
              analyticsTrackingId="actions-list-edit"
              label="Edit"
              onSelect={() => onEdit(action)}
            />
            <DropdownMenuItem
              analyticsTrackingId="actions-list-convert-to-follow-up"
              label="Mark as follow-up"
              onSelect={() => onConvertToFollowUp(action)}
            />
          </DropdownMenuGroup>
          <DropdownMenuItem
            analyticsTrackingId="actions-list-delete-modal-open"
            className="text-red-600"
            label="Delete"
            onSelect={() => onDelete(action)}
          />
        </DropdownMenu>
      }
    />
  );
};

const ActionsCheckmark = ({ action }: { action: Action }) => {
  switch (action.status) {
    case ActionStatusEnum.Outstanding:
      return <Icon id={IconEnum.FakeCheckbox} size={IconSize.Small} />;
    case ActionStatusEnum.Completed:
      return (
        <Icon
          id={IconEnum.FakeCheckboxChecked}
          className="text-green-500"
          size={IconSize.Small}
        />
      );
    case ActionStatusEnum.NotDoing:
      return (
        <Icon
          id={IconEnum.FakeCheckboxCross}
          className="text-red-content"
          size={IconSize.Small}
        />
      );
    case ActionStatusEnum.Deleted:
      // Doesn't matter, we filter these out
      return <></>;
    default:
      return <Icon id={IconEnum.FakeCheckbox} size={IconSize.Small} />;
  }
};

const orderedActionStatuses = [
  ActionStatusEnum.Outstanding,
  ActionStatusEnum.Completed,
  ActionStatusEnum.NotDoing,
  ActionStatusEnum.Deleted,
];

const sortActions = (actions: Action[]): Action[] => {
  return sortBy(actions, (action) =>
    orderedActionStatuses.findIndex((x) => x === action.status),
  );
};
