/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOption,
    SelectOptionFromJSON,
    SelectOptionFromJSONTyped,
    SelectOptionToJSON,
} from './SelectOption';

/**
 * 
 * @export
 * @interface StaffTypeaheadOrganisationsResponseBody
 */
export interface StaffTypeaheadOrganisationsResponseBody {
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof StaffTypeaheadOrganisationsResponseBody
     */
    options: Array<SelectOption>;
}

export function StaffTypeaheadOrganisationsResponseBodyFromJSON(json: any): StaffTypeaheadOrganisationsResponseBody {
    return StaffTypeaheadOrganisationsResponseBodyFromJSONTyped(json, false);
}

export function StaffTypeaheadOrganisationsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffTypeaheadOrganisationsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': ((json['options'] as Array<any>).map(SelectOptionFromJSON)),
    };
}

export function StaffTypeaheadOrganisationsResponseBodyToJSON(value?: StaffTypeaheadOrganisationsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': ((value.options as Array<any>).map(SelectOptionToJSON)),
    };
}

