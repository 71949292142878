import { DependentResourcePayload } from "@incident-io/api";
import { BadgeSize } from "@incident-ui/Badge/Badge";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@incident-ui/DropdownMenu/DropdownMenu";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { StackedList } from "@incident-ui/StackedList/StackedList";
import React, { useEffect, useState } from "react";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { tcx } from "src/utils/tailwind-classes";

export type AccordionStackedListProps<T> = {
  items: T[];
  className?: string;
  expandAll?: boolean;
  initialExpandedItems?: string[];
  overflowActions?: OverflowAction<T>[];
  renderAccordion: (item: T) => React.ReactNode;
  renderRow: (item: T) => React.ReactNode;
  // Use hideChevron to conditionally hide the "view details" chevron from specific rows
  hideChevron?: (item: T) => boolean;
  renderEmptyState?: () => React.ReactNode;
  rowOnClick?: (item: T) => void;
  getRowClassName?: (item: T) => string;
  isDeleting?: (item: T) => boolean;
  isDisabling?: (item: T) => boolean;
  isDisabled?: (item: T) => boolean;
  accordionClassName?: string;
  // Use state to make the accordion table controlled
  state?: { [id: string]: boolean };
  whiteBg?: boolean;
  deleteAction?: DeleteAction<T>;
};

export type OverflowAction<T> = {
  id: string;
  disabled?: boolean;
  onSelect: (itemId: string) => void;
  label: string;
  icon: IconEnum;
  className?: string;
  tooltipContent?: React.ReactNode;
  shouldHide: (item: T) => boolean;
};

export type DeleteAction<T> = {
  id: string;
  disabled?: boolean;
  onDelete: (itemId: string) => void;
  onDisable?: (itemId: string) => void;
  label: string;
  icon: IconEnum;
  className?: string;
  tooltipContent?: (item: T) => React.ReactNode;
  deleteModalTitle: (item: T) => string;
  renderDeleteModalContent: (item: T) => React.ReactNode;
  fetchDependentResources?: (item: T) => DependentResourcePayload[];
  isDeleting: (item: T) => boolean;
};

export function AccordionStackedList<T extends { id: string; name: string }>({
  items,
  className,
  expandAll,
  initialExpandedItems,
  state,
  overflowActions = [],
  renderAccordion,
  renderRow,
  hideChevron,
  renderEmptyState,
  rowOnClick,
  getRowClassName,
  isDisabling,
  accordionClassName,
  isDisabled,
  deleteAction,
  whiteBg = false,
}: AccordionStackedListProps<T>): React.ReactElement {
  return (
    <StackedList className={className}>
      {items.length === 0 && renderEmptyState?.()}
      {items.map((item, _) => (
        <AccordionStackedListItem
          key={item.id}
          item={item}
          state={state ? state[item.id] : undefined}
          overrideIsExpanded={
            expandAll || initialExpandedItems?.includes(item.id) || false
          }
          overflowActions={overflowActions}
          renderAccordion={renderAccordion}
          hideChevron={hideChevron}
          renderRow={renderRow}
          rowOnClick={rowOnClick}
          getRowClassName={getRowClassName}
          isDisabling={isDisabling}
          isDisabled={isDisabled}
          accordionClassName={accordionClassName}
          whiteBg={whiteBg}
          deleteAction={deleteAction}
        />
      ))}
    </StackedList>
  );
}

type AccordionStackedListItemProps<T> = {
  item: T;
  overrideIsExpanded: boolean;
  overflowActions: OverflowAction<T>[];
  renderAccordion: (item: T) => React.ReactNode;
  renderRow: (item: T) => React.ReactNode;
  rowOnClick?: (item: T) => void;
  getRowClassName?: (item: T) => string;
  isDisabling?: (item: T) => boolean;
  isDisabled?: (item: T) => boolean;
  accordionClassName?: string;
  state?: boolean;
  hideChevron?: (item: T) => boolean;
  whiteBg?: boolean;
  deleteAction?: DeleteAction<T>;
};

export function AccordionStackedListItem<
  T extends { id: string; name: string },
>({
  item,
  state,
  overrideIsExpanded,
  overflowActions,
  deleteAction,
  renderAccordion,
  renderRow,
  rowOnClick,
  getRowClassName,
  isDisabling,
  accordionClassName,
  isDisabled,
  hideChevron,
  whiteBg,
}: AccordionStackedListItemProps<T>): React.ReactElement {
  const [internalIsExpanded, setInternalIsExpanded] =
    useState(overrideIsExpanded);
  useEffect(() => {
    setInternalIsExpanded(overrideIsExpanded);
  }, [overrideIsExpanded, setInternalIsExpanded]);

  // The controlled state should always override the internal state
  const isExpanded = state ?? internalIsExpanded;

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const onClick = () => {
    // If you've set an onClick, do the thing
    if (rowOnClick) {
      rowOnClick(item);
    } else {
      // Otherwise, just allow you to toggle the accordion
      setInternalIsExpanded(!isExpanded);
    }
  };

  return (
    <div key={item.id}>
      <div
        className={tcx(
          "flex flex-row items-stretch px-4 py-3 gap-2",
          getRowClassName ? getRowClassName(item) : "",
        )}
      >
        {/* Main row content (clickable) */}
        <div className="grow hover:cursor-pointer" onClick={onClick}>
          {renderRow(item)}
        </div>
        {/* View details button */}
        {!(hideChevron && hideChevron(item)) && (
          <div className="flex flex-row items-center">
            <Button
              analyticsTrackingId="accordion-table-item-toggle-details"
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.Medium}
              onClick={(e) => {
                // Prevent the row click from firing
                e.preventDefault();
                e.stopPropagation();
                // If we're controlled, we probably want this
                // to do the same thing as clicking the row
                // since we're ignoring the internal state.
                // If we ever need to  navigate from a controlled accordion table,
                // we'll need to add a "onChevronClick" prop.
                if (state !== undefined && rowOnClick) {
                  rowOnClick(item);
                } else {
                  // Otherwise, just toggle the internal state
                  setInternalIsExpanded(!isExpanded);
                }
              }}
              className="text-sm"
              icon={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
              title={isExpanded ? "Collapse" : "Expand"}
            />
          </div>
        )}
        {/* Overflow menu */}
        {(overflowActions.length > 0 || deleteAction) && (
          <div className="-my-2 flex items-center -mr-2">
            <DropdownMenu
              triggerButtonTheme={ButtonTheme.Tertiary}
              triggerBadgeSize={BadgeSize.Medium}
              analyticsTrackingId={"accordion-table-item-more-options"}
              screenReaderText="More options"
              triggerIcon={IconEnum.DotsVertical}
              align="end"
              menuClassName="w-[250px]"
            >
              {overflowActions
                // Filter out enable/disable when not applicable
                .filter((action) => !action.shouldHide(item))
                .map((action) => (
                  <DropdownMenuItem
                    key={action.id}
                    disabled={action.disabled}
                    onSelect={() => action.onSelect(item.id)}
                    className={tcx(
                      "text-sm font-normal text-content-primary",
                      action.className,
                    )}
                    tooltipContent={action.tooltipContent}
                    label={action.label}
                    analyticsTrackingId={"accordion-table-item-overflow-item"}
                    analyticsTrackingMetadata={{
                      overflowActionId: action.id,
                    }}
                    icon={action.icon}
                    iconProps={{ className: "!w-6 !h-6" }}
                  />
                ))}
              {/* Delete action - handled separately to other actions as it needs confirmation */}
              {deleteAction && (
                <DropdownMenuItem
                  key={deleteAction.id}
                  disabled={
                    deleteAction.disabled ||
                    (deleteAction.tooltipContent &&
                      !!deleteAction.tooltipContent(item))
                  }
                  onSelect={() => {
                    setShowDeleteConfirmationModal(true);
                  }}
                  analyticsTrackingId={"action-table-item-delete"}
                  analyticsTrackingMetadata={{
                    overflowActionId: deleteAction.id,
                  }}
                  className={tcx(
                    "text-sm font-normal text-content-primary",
                    deleteAction.className,
                  )}
                  tooltipContent={
                    deleteAction.tooltipContent
                      ? deleteAction.tooltipContent(item)
                      : undefined
                  }
                  label={deleteAction.label}
                  icon={deleteAction.icon}
                  iconProps={{ className: "!w-6 !h-6" }}
                />
              )}
            </DropdownMenu>
          </div>
        )}
      </div>
      {/* Accordion content */}
      {isExpanded && (
        <div
          className={tcx(
            "text-content-primary p-4 rounded-b-lg shadow-none bg-surface-secondary border-t border-stroke",
            {
              "bg-surface-primary border-t": whiteBg,
            },
            accordionClassName,
          )}
        >
          {renderAccordion(item)}
        </div>
      )}
      {/* Deletion confirmation */}
      {deleteAction && showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          onClose={() => setShowDeleteConfirmationModal(false)}
          onConfirm={() => {
            deleteAction?.onDelete(item.id);
            setShowDeleteConfirmationModal(false);
          }}
          onDisable={() => {
            if (deleteAction?.onDisable) {
              deleteAction.onDisable(item.id);
              setShowDeleteConfirmationModal(false);
            }
          }}
          item={item}
          isDeleting={deleteAction.isDeleting(item)}
          isDisabling={isDisabling ? isDisabling(item) : false}
          isDisabled={isDisabled ? isDisabled(item) : true}
          renderDeleteModalContent={deleteAction.renderDeleteModalContent}
          fetchDependentResources={deleteAction.fetchDependentResources}
          deleteModalTitle={deleteAction.deleteModalTitle}
        />
      )}
    </div>
  );
}

function DeleteConfirmationModal<T extends { id: string; name: string }>({
  onClose,
  onConfirm,
  onDisable,
  item,
  isDeleting,
  isDisabling,
  isDisabled,
  deleteModalTitle,
  renderDeleteModalContent,
  fetchDependentResources,
}: {
  onClose: () => void;
  onConfirm: () => void;
  onDisable?: () => void;
  item: T;
  isDeleting: boolean;
  isDisabling?: boolean;
  isDisabled?: boolean;
  deleteModalTitle?: (item: T) => string;
  renderDeleteModalContent: (item: T) => React.ReactNode;
  fetchDependentResources?: (item: T) => DependentResourcePayload[];
}): React.ReactElement {
  const shouldShowDisable = !!onDisable && !isDisabled;
  return (
    <DeletionConfirmationModal
      title={deleteModalTitle?.(item) ?? ""}
      isOpen={true}
      onClose={onClose}
      fetchDependentResources={
        fetchDependentResources ? fetchDependentResources(item) : undefined
      }
      onDelete={onConfirm}
      isDeleting={isDeleting}
      resourceTitle={item.name}
      analyticsTrackingId="accordion-table-delete-confirmation-modal"
      deleteConfirmationContent={renderDeleteModalContent(item)}
      confirmButtonText="Delete"
      gadget={
        shouldShowDisable ? (
          <Button
            theme={ButtonTheme.Secondary}
            onClick={onDisable}
            analyticsTrackingId="accordion-table-delete-confirmation-modal-disable"
            loading={isDisabling}
            className="!ml-0"
          >
            Disable
          </Button>
        ) : undefined
      }
      gadgetPosition="middle"
    />
  );
}
