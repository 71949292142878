import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { UserPreferencesModal } from "./header/UserPreferencesModal";

function SubscriptionButton({
  isSubscribed,
  isLoadingSubscription,
  subscribe,
  unsubscribe,
  theme,
  size,
  className,
}: {
  subscribe: () => void;
  className?: string;
  unsubscribe: () => void;
  isSubscribed: boolean | undefined;
  isLoadingSubscription: boolean;
  theme: ButtonTheme;
  size: BadgeSize;
}): React.ReactElement {
  const icon = isSubscribed ? IconEnum.Mute : IconEnum.BellRinging;

  return (
    <Button
      analyticsTrackingId="incident-subscribe"
      className={tcx(className)}
      type="button"
      theme={theme}
      size={size}
      icon={icon}
      loading={isLoadingSubscription}
      onClick={() => {
        if (isSubscribed) {
          unsubscribe();
        } else {
          subscribe();
        }
      }}
      title=""
    >
      {isSubscribed ? "Unsubscribe" : "Subscribe"}
    </Button>
  );
}

export const useIncidentHasSubscription = ({
  incidentId,
  internalStatusPageId,
}: {
  incidentId: string;
  internalStatusPageId?: string;
}) => {
  const { data, isLoading } = useAPI("incidentSubscriptionsGet", {
    incidentId,
  });
  const isSubscribed = data
    ? data.incident_subscriptions.length > 0
    : undefined;

  const { trigger: subscribe, isMutating: isSubscribing } = useAPIMutation(
    "incidentSubscriptionsGet",
    { incidentId },
    async (apiClient) => {
      await apiClient.incidentSubscriptionsCreate({
        createRequestBody: {
          incident_id: incidentId,
          internal_status_page_id: internalStatusPageId,
        },
      });
    },
  );

  const { trigger: unsubscribe, isMutating: isUnsubscribing } = useAPIMutation(
    "incidentSubscriptionsGet",
    { incidentId },
    async (apiClient) => {
      await apiClient.incidentSubscriptionsUnsubscribe({
        incidentId,
      });
    },
  );

  return {
    subscribe: () => subscribe({}),
    unsubscribe: () => unsubscribe({}),
    isSubscribed,
    loading: isLoading || isSubscribing || isUnsubscribing,
  };
};

export const ToggleSubscriptionButton = ({
  incidentId,
  theme = ButtonTheme.Secondary,
  size = BadgeSize.Large,
  className,
}: {
  incidentId: string;
  theme?: ButtonTheme;
  size?: BadgeSize;
  className?: string;
}): React.ReactElement => {
  const userPreferences = useAPI("userPreferencesShow", undefined).data
    ?.user_preferences;

  const [modalIsOpen, setModalOpen] = useState(false);

  const userPreferencesRequireSetup =
    !userPreferences ||
    !(
      userPreferences.receives_subscriptions_via_email ||
      userPreferences.receives_subscriptions_via_slack_dm ||
      userPreferences.receives_subscriptions_via_sms
    );

  const { isSubscribed, subscribe, loading, unsubscribe } =
    useIncidentHasSubscription({ incidentId });

  return (
    <>
      <AnimatePresence>
        {modalIsOpen && (
          <UserPreferencesModal
            incidentId={incidentId}
            onClose={() => setModalOpen(false)}
          />
        )}
      </AnimatePresence>
      <SubscriptionButton
        subscribe={() =>
          userPreferencesRequireSetup ? setModalOpen(true) : subscribe()
        }
        unsubscribe={unsubscribe}
        isSubscribed={isSubscribed ?? undefined}
        isLoadingSubscription={loading}
        theme={theme}
        className={className}
        size={size}
      />
    </>
  );
};
