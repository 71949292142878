/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraIssueField,
    JiraIssueFieldFromJSON,
    JiraIssueFieldFromJSONTyped,
    JiraIssueFieldToJSON,
} from './JiraIssueField';

/**
 * 
 * @export
 * @interface IssueTrackersJiraServerGetCreateIssueFieldsResponseBody
 */
export interface IssueTrackersJiraServerGetCreateIssueFieldsResponseBody {
    /**
     * Does this issue type have a description field?
     * @type {boolean}
     * @memberof IssueTrackersJiraServerGetCreateIssueFieldsResponseBody
     */
    has_description: boolean;
    /**
     * 
     * @type {Array<JiraIssueField>}
     * @memberof IssueTrackersJiraServerGetCreateIssueFieldsResponseBody
     */
    issue_fields: Array<JiraIssueField>;
    /**
     * Fields which are required to create an issue, but which we can't handle input for
     * @type {Array<string>}
     * @memberof IssueTrackersJiraServerGetCreateIssueFieldsResponseBody
     */
    unsupported_required_fields: Array<string>;
}

export function IssueTrackersJiraServerGetCreateIssueFieldsResponseBodyFromJSON(json: any): IssueTrackersJiraServerGetCreateIssueFieldsResponseBody {
    return IssueTrackersJiraServerGetCreateIssueFieldsResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersJiraServerGetCreateIssueFieldsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersJiraServerGetCreateIssueFieldsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'has_description': json['has_description'],
        'issue_fields': ((json['issue_fields'] as Array<any>).map(JiraIssueFieldFromJSON)),
        'unsupported_required_fields': json['unsupported_required_fields'],
    };
}

export function IssueTrackersJiraServerGetCreateIssueFieldsResponseBodyToJSON(value?: IssueTrackersJiraServerGetCreateIssueFieldsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'has_description': value.has_description,
        'issue_fields': ((value.issue_fields as Array<any>).map(JiraIssueFieldToJSON)),
        'unsupported_required_fields': value.unsupported_required_fields,
    };
}

