import type { SVGProps } from "react";
import * as React from "react";
const SvgJumpcloud = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#jumpcloud_svg__a)">
      <path
        fill="#062B48"
        fillRule="evenodd"
        d="M10.44 5.532c-.582-.05-1.193-.07-1.747.157-1.008.397-1.753 1.285-1.952 2.321-.966-.028-1.967.497-2.364 1.399-.448-.093-.916-.156-1.378-.093-1.214.206-2.144 1.449-1.98 2.67a2.394 2.394 0 0 0 1.512 1.98c.412.195.872.18 1.32.164.09-.003.178-.006.266-.007.044-.129.104-.246.176-.353.321-.503.917-.785 1.467-1.012-.232-.393-.474-.822-.446-1.291 0-.364.127-.711.337-1 .349-.504.944-.84 1.566-.796.944.029 1.817.91 1.718 1.875.019.403-.187.754-.384 1.09l-.064.11c.256.127.519.241.788.34.453-.299.933-.55 1.433-.761l-.026-.045c-.246-.422-.504-.865-.49-1.367-.023-.36.062-.714.227-1.028.338-.662 1.028-1.15 1.789-1.15 1.065-.093 2.087.773 2.2 1.831.1.597-.13 1.176-.472 1.649a3.708 3.708 0 0 1-.073.098l.213.091c.49.208.99.421 1.355.817.228.235.342.54.453.836l.03.08c.125-.003.254 0 .382.002.46.008.93.017 1.336-.222a2.462 2.462 0 0 0 1.363-2.364c-.05-.944-.739-1.796-1.619-2.116-.337-.15-.71-.132-1.072-.115l-.192.009c-.181.05-.21-.123-.232-.266a.856.856 0 0 0-.023-.11c-.156-.881-.838-1.62-1.676-1.903-.417-.174-.873-.14-1.32-.106-.1.008-.2.015-.298.02-.504-.71-1.236-1.306-2.123-1.434Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="jumpcloud_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgJumpcloud;
