import type { SVGProps } from "react";
import * as React from "react";
const SvgDotsHorizontalNopad = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 12.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM4.25 12.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM15.75 12.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
    />
  </svg>
);
export default SvgDotsHorizontalNopad;
