import { AvailableCatalogType } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  IconEnum,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export type IntegrationCatalogTypeListProps = {
  types: AvailableCatalogType[];
  integrationInstalled?: boolean;
};

export const IntegrationCatalogTypeList = ({
  types,
  integrationInstalled,
}: IntegrationCatalogTypeListProps) => {
  if (types.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-col gap-3 ">
      <span
        className={tcx(
          "text-base-bold",
          integrationInstalled
            ? "text-content-primary"
            : "text-content-tertiary",
        )}
      >
        Catalog Types
      </span>
      <StackedList>
        {types.map((type) => (
          <StackedListItem
            key={type.type_name}
            title={type.name}
            description={type.description}
            icon={type.icon as unknown as IconEnum}
            iconColor={type.color as unknown as ColorPaletteEnum}
            badgeNode={
              !!type.estimated_count && (
                <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
                  {type.estimated_count}
                </Badge>
              )
            }
            disabled={!integrationInstalled}
            rowHref={type.id ? `/catalog/${type.id}` : undefined}
          />
        ))}
      </StackedList>
    </div>
  );
};
