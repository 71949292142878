import { UpgradeRequiredModal } from "@incident-shared/billing";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  IconEnum,
  Modal,
  ModalContent,
  StackedList,
  StackedListButton,
} from "@incident-ui";
import { PlanBadge } from "src/components/settings/PlanBadge";

import { useIdentity } from "../../../contexts/IdentityContext";
import { CreateModalProps } from "../view/StatusPagesHomepage";

export const ChoosePublicPageTypeModal = (props: CreateModalProps) => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/status-pages");

  const { identity } = useIdentity();
  if (!props.canCreatePublicPages) {
    return (
      <UpgradeRequiredModal
        title="Create status page"
        gate={{
          type: "boolean",
        }}
        featureName="creating more public status pages"
        onClose={onClose}
        analyticsTrackingId="status-pages-create-upgrade-required"
      />
    );
  }
  return (
    <Modal
      isOpen
      title={"Create status page"}
      analyticsTrackingId="create-status-page-choose"
      onClose={onClose}
    >
      <ModalContent className="p-4">
        <StackedList>
          <StackedListButton
            key="standalone"
            analyticsTrackingId={"status-page-select-public-single"}
            icon={IconEnum.File}
            title="Create single page"
            description="Create a unified public status page to serve all your users."
            href={"/status-pages/public/create"}
          />
          <StackedListButton
            disabled={!identity?.feature_gates.status_pages_sub_pages}
            key="parent"
            analyticsTrackingId={"status-page-select-public-parent"}
            icon={IconEnum.Files}
            title="Create with sub-pages"
            badgeNode={
              identity?.feature_gates.status_pages_sub_pages ? undefined : (
                <PlanBadge planName="enterprise" label="Enterprise only" />
              )
            }
            description="Create multiple connected public status pages, e.g. a global page, and individual pages for each region in which you operate."
            href={"/status-pages/public/sub-pages/create"}
          />
        </StackedList>
      </ModalContent>
    </Modal>
  );
};
