import type { SVGProps } from "react";
import * as React from "react";
const SvgCircle = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="currentColor" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
  </svg>
);
export default SvgCircle;
