import { Icon, IconEnum, Tooltip } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const RetrospectiveBadge = () => (
  <Tooltip content="This incident was declared retrospectively">
    <div
      className={tcx(
        "px-2 py-1.5 items-center rounded-full text-sm flex truncate",
        "text-content-tertiary border border-slate-400",
      )}
    >
      <Icon id={IconEnum.Retrospective} className="mr-0.5" />
      Retrospective
    </div>
  </Tooltip>
);
