/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathTarget,
    EscalationPathTargetFromJSON,
    EscalationPathTargetFromJSONTyped,
    EscalationPathTargetToJSON,
} from './EscalationPathTarget';

/**
 * 
 * @export
 * @interface EscalationPathLevel
 */
export interface EscalationPathLevel {
    /**
     * How long should we wait for this level to acknowledge before escalating?
     * @type {number}
     * @memberof EscalationPathLevel
     */
    delay_seconds: number;
    /**
     * 
     * @type {Array<EscalationPathTarget>}
     * @memberof EscalationPathLevel
     */
    targets: Array<EscalationPathTarget>;
}

export function EscalationPathLevelFromJSON(json: any): EscalationPathLevel {
    return EscalationPathLevelFromJSONTyped(json, false);
}

export function EscalationPathLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delay_seconds': json['delay_seconds'],
        'targets': ((json['targets'] as Array<any>).map(EscalationPathTargetFromJSON)),
    };
}

export function EscalationPathLevelToJSON(value?: EscalationPathLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delay_seconds': value.delay_seconds,
        'targets': ((value.targets as Array<any>).map(EscalationPathTargetToJSON)),
    };
}

