/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRoleAssignment,
    IncidentRoleAssignmentFromJSON,
    IncidentRoleAssignmentFromJSONTyped,
    IncidentRoleAssignmentToJSON,
} from './IncidentRoleAssignment';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface Stream
 */
export interface Stream {
    /**
     * URL for the stream call
     * @type {string}
     * @memberof Stream
     */
    call_url?: string;
    /**
     * External identifier for the stream
     * @type {number}
     * @memberof Stream
     */
    external_id: number;
    /**
     * UUID for the stream
     * @type {string}
     * @memberof Stream
     */
    id: string;
    /**
     * A list of who is assigned to each role for this stream
     * @type {Array<IncidentRoleAssignment>}
     * @memberof Stream
     */
    incident_role_assignments: Array<IncidentRoleAssignment>;
    /**
     * Name of the stream
     * @type {string}
     * @memberof Stream
     */
    name: string;
    /**
     * URL of the slack channel
     * @type {string}
     * @memberof Stream
     */
    slack_channel_url?: string;
    /**
     * ID of the slack team
     * @type {string}
     * @memberof Stream
     */
    slack_team_id: string;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof Stream
     */
    status: IncidentStatus;
    /**
     * Whether the user can access the stream
     * @type {boolean}
     * @memberof Stream
     */
    user_can_access: boolean;
    /**
     * Whether the stream should be open to anyone in your Slack workspace (public), or invite-only (private).
     * @type {string}
     * @memberof Stream
     */
    visibility?: StreamVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum StreamVisibilityEnum {
    Public = 'public',
    Private = 'private'
}

export function StreamFromJSON(json: any): Stream {
    return StreamFromJSONTyped(json, false);
}

export function StreamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stream {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_url': !exists(json, 'call_url') ? undefined : json['call_url'],
        'external_id': json['external_id'],
        'id': json['id'],
        'incident_role_assignments': ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentFromJSON)),
        'name': json['name'],
        'slack_channel_url': !exists(json, 'slack_channel_url') ? undefined : json['slack_channel_url'],
        'slack_team_id': json['slack_team_id'],
        'status': IncidentStatusFromJSON(json['status']),
        'user_can_access': json['user_can_access'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
    };
}

export function StreamToJSON(value?: Stream | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_url': value.call_url,
        'external_id': value.external_id,
        'id': value.id,
        'incident_role_assignments': ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentToJSON)),
        'name': value.name,
        'slack_channel_url': value.slack_channel_url,
        'slack_team_id': value.slack_team_id,
        'status': IncidentStatusToJSON(value.status),
        'user_can_access': value.user_can_access,
        'visibility': value.visibility,
    };
}

