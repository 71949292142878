/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalDatadogMonitorAlert
 */
export interface ExternalDatadogMonitorAlert {
    /**
     * Priority of the alert
     * @type {string}
     * @memberof ExternalDatadogMonitorAlert
     */
    alert_priority: string;
    /**
     * Body of the alert
     * @type {string}
     * @memberof ExternalDatadogMonitorAlert
     */
    body: string;
    /**
     * URL of the snapshot image
     * @type {string}
     * @memberof ExternalDatadogMonitorAlert
     */
    snapshot_image_url: string;
    /**
     * Tags associated with the alert
     * @type {Array<string>}
     * @memberof ExternalDatadogMonitorAlert
     */
    tags: Array<string>;
    /**
     * Title of the alert
     * @type {string}
     * @memberof ExternalDatadogMonitorAlert
     */
    title: string;
}

export function ExternalDatadogMonitorAlertFromJSON(json: any): ExternalDatadogMonitorAlert {
    return ExternalDatadogMonitorAlertFromJSONTyped(json, false);
}

export function ExternalDatadogMonitorAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalDatadogMonitorAlert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_priority': json['alert_priority'],
        'body': json['body'],
        'snapshot_image_url': json['snapshot_image_url'],
        'tags': json['tags'],
        'title': json['title'],
    };
}

export function ExternalDatadogMonitorAlertToJSON(value?: ExternalDatadogMonitorAlert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_priority': value.alert_priority,
        'body': value.body,
        'snapshot_image_url': value.snapshot_image_url,
        'tags': value.tags,
        'title': value.title,
    };
}

