/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationOpsgenieOptions,
    EscalationOpsgenieOptionsFromJSON,
    EscalationOpsgenieOptionsFromJSONTyped,
    EscalationOpsgenieOptionsToJSON,
} from './EscalationOpsgenieOptions';
import {
    EscalationPagerDutyOptions,
    EscalationPagerDutyOptionsFromJSON,
    EscalationPagerDutyOptionsFromJSONTyped,
    EscalationPagerDutyOptionsToJSON,
} from './EscalationPagerDutyOptions';
import {
    ExternalEscalationTarget,
    ExternalEscalationTargetFromJSON,
    ExternalEscalationTargetFromJSONTyped,
    ExternalEscalationTargetToJSON,
} from './ExternalEscalationTarget';

/**
 * 
 * @export
 * @interface EscalationsCreateExternalRequestBody
 */
export interface EscalationsCreateExternalRequestBody {
    /**
     * More extensive message that is provided with this escalation, default to incident summary
     * @type {string}
     * @memberof EscalationsCreateExternalRequestBody
     */
    description?: string;
    /**
     * Unique key that prevents creating duplicate escalations
     * @type {string}
     * @memberof EscalationsCreateExternalRequestBody
     */
    idempotency_key?: string;
    /**
     * Unique identifier of the incident the escalation is connected to
     * @type {string}
     * @memberof EscalationsCreateExternalRequestBody
     */
    incident_id?: string;
    /**
     * If true, do not send channel updates for this escalation
     * @type {boolean}
     * @memberof EscalationsCreateExternalRequestBody
     */
    mute_channel_updates?: boolean;
    /**
     * 
     * @type {EscalationOpsgenieOptions}
     * @memberof EscalationsCreateExternalRequestBody
     */
    opsgenie_options?: EscalationOpsgenieOptions;
    /**
     * 
     * @type {EscalationPagerDutyOptions}
     * @memberof EscalationsCreateExternalRequestBody
     */
    pager_duty_options?: EscalationPagerDutyOptions;
    /**
     * Name of escalation provider, e.g. splunk_on_call
     * @type {string}
     * @memberof EscalationsCreateExternalRequestBody
     */
    provider: EscalationsCreateExternalRequestBodyProviderEnum;
    /**
     * The targets to escalate to
     * @type {Array<ExternalEscalationTarget>}
     * @memberof EscalationsCreateExternalRequestBody
     */
    targets: Array<ExternalEscalationTarget>;
    /**
     * Human readable title of this escalation, defaulting to incident name
     * @type {string}
     * @memberof EscalationsCreateExternalRequestBody
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum EscalationsCreateExternalRequestBodyProviderEnum {
    Native = 'native',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty',
    SplunkOnCall = 'splunk_on_call'
}

export function EscalationsCreateExternalRequestBodyFromJSON(json: any): EscalationsCreateExternalRequestBody {
    return EscalationsCreateExternalRequestBodyFromJSONTyped(json, false);
}

export function EscalationsCreateExternalRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsCreateExternalRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'idempotency_key': !exists(json, 'idempotency_key') ? undefined : json['idempotency_key'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'mute_channel_updates': !exists(json, 'mute_channel_updates') ? undefined : json['mute_channel_updates'],
        'opsgenie_options': !exists(json, 'opsgenie_options') ? undefined : EscalationOpsgenieOptionsFromJSON(json['opsgenie_options']),
        'pager_duty_options': !exists(json, 'pager_duty_options') ? undefined : EscalationPagerDutyOptionsFromJSON(json['pager_duty_options']),
        'provider': json['provider'],
        'targets': ((json['targets'] as Array<any>).map(ExternalEscalationTargetFromJSON)),
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function EscalationsCreateExternalRequestBodyToJSON(value?: EscalationsCreateExternalRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'idempotency_key': value.idempotency_key,
        'incident_id': value.incident_id,
        'mute_channel_updates': value.mute_channel_updates,
        'opsgenie_options': EscalationOpsgenieOptionsToJSON(value.opsgenie_options),
        'pager_duty_options': EscalationPagerDutyOptionsToJSON(value.pager_duty_options),
        'provider': value.provider,
        'targets': ((value.targets as Array<any>).map(ExternalEscalationTargetToJSON)),
        'title': value.title,
    };
}

