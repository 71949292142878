/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthShowPublicInformationForSlugResponseBody,
    AuthShowPublicInformationForSlugResponseBodyFromJSON,
    AuthShowPublicInformationForSlugResponseBodyToJSON,
    AuthShowSlackUserTokenConfigResponseBody,
    AuthShowSlackUserTokenConfigResponseBodyFromJSON,
    AuthShowSlackUserTokenConfigResponseBodyToJSON,
    AuthShowWorkOSAuthURIResponseBody,
    AuthShowWorkOSAuthURIResponseBodyFromJSON,
    AuthShowWorkOSAuthURIResponseBodyToJSON,
} from '../models';

export interface AuthHandleSentryRedirectRequest {
    code?: string;
    installationId?: string;
}

export interface AuthHandleSentryUninstallRequest {
    organisationId: string;
}

export interface AuthHandleSlackRedirectRequest {
    code?: string;
    state?: string;
    useOpenid?: boolean;
    isUserToken?: boolean;
    error?: string;
}

export interface AuthHandleWorkOSRedirectRequest {
    code?: string;
    state?: string;
    error?: string;
    errorDescription?: string;
}

export interface AuthRedirectToSlackLoginRequest {
    returnPath?: string;
    returnHost?: string;
    product?: string;
    planName?: string;
    organisationSlug?: string;
    viaMobile?: boolean;
    teamId?: string;
}

export interface AuthRedirectToWorkOSPortalRequest {
    intent: string;
    organisationId: string;
    returnPath?: string;
    successPath?: string;
}

export interface AuthShowPublicInformationForSlugRequest {
    slug: string;
}

export interface AuthShowWorkOSAuthURIRequest {
    email?: string;
    slug?: string;
    returnPath?: string;
    viaMobile?: boolean;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Handle Sentry installation by redirecting to the Sentry install endpoint
     * HandleSentryInstall Auth
     */
    async authHandleSentryInstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/sentry_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Sentry installation by redirecting to the Sentry install endpoint
     * HandleSentryInstall Auth
     */
    async authHandleSentryInstall(initOverrides?: RequestInit): Promise<void> {
        await this.authHandleSentryInstallRaw(initOverrides);
    }

    /**
     * Handle Sentry redirect, where we exchange the code for an access token
     * HandleSentryRedirect Auth
     */
    async authHandleSentryRedirectRaw(requestParameters: AuthHandleSentryRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.installationId !== undefined) {
            queryParameters['installationId'] = requestParameters.installationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/sentry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Sentry redirect, where we exchange the code for an access token
     * HandleSentryRedirect Auth
     */
    async authHandleSentryRedirect(requestParameters: AuthHandleSentryRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authHandleSentryRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Sentry uninstallation request by forwarding the user to Sentry
     * HandleSentryUninstall Auth
     */
    async authHandleSentryUninstallRaw(requestParameters: AuthHandleSentryUninstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling authHandleSentryUninstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/sentry_uninstall`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Sentry uninstallation request by forwarding the user to Sentry
     * HandleSentryUninstall Auth
     */
    async authHandleSentryUninstall(requestParameters: AuthHandleSentryUninstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authHandleSentryUninstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Slack redirect
     * HandleSlackRedirect Auth
     */
    async authHandleSlackRedirectRaw(requestParameters: AuthHandleSlackRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.useOpenid !== undefined) {
            queryParameters['use_openid'] = requestParameters.useOpenid;
        }

        if (requestParameters.isUserToken !== undefined) {
            queryParameters['is_user_token'] = requestParameters.isUserToken;
        }

        if (requestParameters.error !== undefined) {
            queryParameters['error'] = requestParameters.error;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/slack`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Slack redirect
     * HandleSlackRedirect Auth
     */
    async authHandleSlackRedirect(requestParameters: AuthHandleSlackRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authHandleSlackRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle WorkOS redirect
     * HandleWorkOSRedirect Auth
     */
    async authHandleWorkOSRedirectRaw(requestParameters: AuthHandleWorkOSRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.error !== undefined) {
            queryParameters['error'] = requestParameters.error;
        }

        if (requestParameters.errorDescription !== undefined) {
            queryParameters['error_description'] = requestParameters.errorDescription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/workos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle WorkOS redirect
     * HandleWorkOSRedirect Auth
     */
    async authHandleWorkOSRedirect(requestParameters: AuthHandleWorkOSRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authHandleWorkOSRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Logout and clear dashboard session
     * LogoutAll Auth
     */
    async authLogoutAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/logout/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout and clear dashboard session
     * LogoutAll Auth
     */
    async authLogoutAll(initOverrides?: RequestInit): Promise<void> {
        await this.authLogoutAllRaw(initOverrides);
    }

    /**
     * Logout and clear dashboard session
     * RedirectToSlackLogin Auth
     */
    async authRedirectToSlackLoginRaw(requestParameters: AuthRedirectToSlackLoginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        if (requestParameters.returnHost !== undefined) {
            queryParameters['return_host'] = requestParameters.returnHost;
        }

        if (requestParameters.product !== undefined) {
            queryParameters['product'] = requestParameters.product;
        }

        if (requestParameters.planName !== undefined) {
            queryParameters['plan_name'] = requestParameters.planName;
        }

        if (requestParameters.organisationSlug !== undefined) {
            queryParameters['organisation_slug'] = requestParameters.organisationSlug;
        }

        if (requestParameters.viaMobile !== undefined) {
            queryParameters['via_mobile'] = requestParameters.viaMobile;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/slack_login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout and clear dashboard session
     * RedirectToSlackLogin Auth
     */
    async authRedirectToSlackLogin(requestParameters: AuthRedirectToSlackLoginRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authRedirectToSlackLoginRaw(requestParameters, initOverrides);
    }

    /**
     * Redirect to WorkOS portal
     * RedirectToWorkOSPortal Auth
     */
    async authRedirectToWorkOSPortalRaw(requestParameters: AuthRedirectToWorkOSPortalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.intent === null || requestParameters.intent === undefined) {
            throw new runtime.RequiredError('intent','Required parameter requestParameters.intent was null or undefined when calling authRedirectToWorkOSPortal.');
        }

        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling authRedirectToWorkOSPortal.');
        }

        const queryParameters: any = {};

        if (requestParameters.intent !== undefined) {
            queryParameters['intent'] = requestParameters.intent;
        }

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        if (requestParameters.successPath !== undefined) {
            queryParameters['success_path'] = requestParameters.successPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/workos_portal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect to WorkOS portal
     * RedirectToWorkOSPortal Auth
     */
    async authRedirectToWorkOSPortal(requestParameters: AuthRedirectToWorkOSPortalRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authRedirectToWorkOSPortalRaw(requestParameters, initOverrides);
    }

    /**
     * Remove the Slack user token for the organisation
     * RemoveSlackUserToken Auth
     */
    async authRemoveSlackUserTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/slack_user_token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the Slack user token for the organisation
     * RemoveSlackUserToken Auth
     */
    async authRemoveSlackUserToken(initOverrides?: RequestInit): Promise<void> {
        await this.authRemoveSlackUserTokenRaw(initOverrides);
    }

    /**
     * If we\'ve not been able to create the incident announcement channels for an organisation in each enabled workspace, this will try to complete the installation
     * RetryAnnouncementChannelsCreate Auth
     */
    async authRetryAnnouncementChannelsCreateRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/slack/retry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * If we\'ve not been able to create the incident announcement channels for an organisation in each enabled workspace, this will try to complete the installation
     * RetryAnnouncementChannelsCreate Auth
     */
    async authRetryAnnouncementChannelsCreate(initOverrides?: RequestInit): Promise<void> {
        await this.authRetryAnnouncementChannelsCreateRaw(initOverrides);
    }

    /**
     * Show publicly available information for a given slug
     * ShowPublicInformationForSlug Auth
     */
    async authShowPublicInformationForSlugRaw(requestParameters: AuthShowPublicInformationForSlugRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthShowPublicInformationForSlugResponseBody>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling authShowPublicInformationForSlug.');
        }

        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/public_information_for_slug`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthShowPublicInformationForSlugResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show publicly available information for a given slug
     * ShowPublicInformationForSlug Auth
     */
    async authShowPublicInformationForSlug(requestParameters: AuthShowPublicInformationForSlugRequest, initOverrides?: RequestInit): Promise<AuthShowPublicInformationForSlugResponseBody> {
        const response = await this.authShowPublicInformationForSlugRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Build the URL to send a Slack admin or owner to if they want to give us a user token we can use to do user-only or admin-only things.
     * ShowSlackUserTokenConfig Auth
     */
    async authShowSlackUserTokenConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthShowSlackUserTokenConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/slack_user_token_config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthShowSlackUserTokenConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build the URL to send a Slack admin or owner to if they want to give us a user token we can use to do user-only or admin-only things.
     * ShowSlackUserTokenConfig Auth
     */
    async authShowSlackUserTokenConfig(initOverrides?: RequestInit): Promise<AuthShowSlackUserTokenConfigResponseBody> {
        const response = await this.authShowSlackUserTokenConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle a request to view the auth URI for a SAML user
     * ShowWorkOSAuthURI Auth
     */
    async authShowWorkOSAuthURIRaw(requestParameters: AuthShowWorkOSAuthURIRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthShowWorkOSAuthURIResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        if (requestParameters.viaMobile !== undefined) {
            queryParameters['via_mobile'] = requestParameters.viaMobile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/workos_auth_uri`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthShowWorkOSAuthURIResponseBodyFromJSON(jsonValue));
    }

    /**
     * Handle a request to view the auth URI for a SAML user
     * ShowWorkOSAuthURI Auth
     */
    async authShowWorkOSAuthURI(requestParameters: AuthShowWorkOSAuthURIRequest, initOverrides?: RequestInit): Promise<AuthShowWorkOSAuthURIResponseBody> {
        const response = await this.authShowWorkOSAuthURIRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
