import { Loader } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";

import { useIdentity } from "../../contexts/IdentityContext";
import { CatalogEntryListPageRoute } from "./entry-list/CatalogEntryListPage";
import { CatalogTypeDependentResourcesPage } from "./entry-list/CatalogTypeDependentResourcesPage";
import { CatalogTypeEditPage } from "./entry-list/CatalogTypeEditPage";
import { ManageInGithubRoute } from "./manage-in-github/ManageInGithubRoute";
import { CatalogTypeCreatePage } from "./type-list/CatalogTypeCreatePage";
import { CatalogTypeListPage } from "./type-list/CatalogTypeListPage";
import { ServiceWizardRoutes } from "./wizards/service-wizard/ServiceWizardRoutes";
import { TeamWizardRoutes } from "./wizards/team-wizard/TeamWizardRoutes";

// We export these so that other places in the app can use them to navigate to
// these routes. Lots of onboarding and setup flows use these routes, so be
// careful when changing them.
export enum CatalogSetupRoutes {
  ManageInGithub = "manage-in-github",
  TeamWizard = "team-wizard",
  ServiceWizard = "service-wizard",
}

export const CatalogRoute = (): React.ReactElement => {
  const { identity } = useIdentity();

  // Really important to wait for loading to finish before proceeding, to ensure
  // the URL parameters are set correctly.
  if (!identity) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="" element={<CatalogTypeListPage />} />
      <Route path="create" element={<CatalogTypeCreatePage />} />
      <Route path=":id/edit" element={<CatalogTypeEditPage />} />
      <Route path=":id/*" element={<CatalogEntryListPageRoute />} />
      <Route
        path=":id/dependent_resources"
        element={<CatalogTypeDependentResourcesPage />}
      />
      <Route
        path={`${CatalogSetupRoutes.ManageInGithub}/*`}
        element={<ManageInGithubRoute />}
      />
      <Route
        path={`${CatalogSetupRoutes.TeamWizard}/*`}
        element={<TeamWizardRoutes />}
      />
      <Route
        path={`${CatalogSetupRoutes.ServiceWizard}/*`}
        element={<ServiceWizardRoutes />}
      />
    </Routes>
  );
};
