import { Tooltip } from "@incident-ui";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { motion } from "framer-motion";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

export const Collapsible = forwardRef(
  (
    {
      title,
      initialOpen,
      onToggle,
      tooltipContent,
      className,
      children,
    }: {
      title: string;
      initialOpen: boolean;
      onToggle?: (showContent: boolean) => void;
      tooltipContent?: React.ReactNode;
      className?: string;
      children: React.ReactNode;
    },
    ref: React.Ref<{
      open: () => void;
      getIsOpen: () => boolean;
    }>,
  ) => {
    const [showContent, setShowContent] = useState(initialOpen);

    useImperativeHandle(ref, () => ({
      open: () => setShowContent(true),
      getIsOpen: () => showContent,
    }));

    return (
      <div>
        <div className="flex flex-col w-full">
          <div
            className={tcx(
              "flex items-center justify-between w-full p-3 bg-white shadow-sm rounded-2 border transition cursor-pointer",
              showContent ? "border-stroke" : "border-stroke",
              className,
            )}
            onClick={() => {
              setShowContent(!showContent);
              if (onToggle) {
                onToggle(showContent);
              }
            }}
          >
            <div className="font-medium text-sm inline-flex text-content-primary items-center gap-1">
              {title}
              {tooltipContent && (
                <Tooltip
                  side={"bottom"}
                  content={tooltipContent}
                  bubbleProps={{
                    className: "w-[260px] text-center",
                  }}
                >
                  <div>
                    <Icon
                      id={IconEnum.Info}
                      className={tcx(
                        "ml-0.5 mr-0 text-opacity-50 fill-current text-content-secondary hover:text-content-primary",
                      )}
                      size={IconSize.Small}
                    />
                  </div>
                </Tooltip>
              )}
            </div>

            {
              <Icon
                id={showContent ? IconEnum.Collapse : IconEnum.Expand}
                size={IconSize.Small}
                className="text-content-tertiary animate"
              />
            }
          </div>
        </div>

        <motion.div
          key="collapsable-content"
          className="bg-slate-50 -mt-2 border border-slate-100 rounded-b-lg overflow-hidden"
          variants={{
            open: {
              height: "auto",
              visibility: "visible",
            },
            closed: {
              height: 0,
              transitionEnd: { visibility: "hidden" },
            },
          }}
          animate={showContent ? "open" : "closed"}
          transition={{ duration: 0.25, ease: "easeInOut" }}
          initial={showContent ? "open" : "closed"}
        >
          <div className="bottom-0 mt-2 px-3 py-4">{children}</div>
        </motion.div>
      </div>
    );
  },
);

Collapsible.displayName = "Collapsible";
