import { CatalogResource } from "@incident-io/api";
import { isEmpty as isEmptyString } from "lodash";
import { tcx } from "src/utils/tailwind-classes";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { BindingLabel } from "../engine";
import { NonPrimitiveEntry } from "./NonPrimitiveEntry";
import { NonPrimitiveEntryList } from "./NonPrimitiveEntryList";
import { PrimitiveEntry } from "./PrimitiveEntry";
import { PrimitiveEntryList } from "./PrimitiveEntryList";
import { AttributeBinding } from "./types";
import { hasArrayValue, hasSingleValue, isEmpty, isPrimitive } from "./utils";

type AttributeEntriesProps = {
  // The type of this attribute, e.g. "String"
  typeName: string;
  // mode configures whether to expect catalog types or engine types. If you're rendering this from the catalog,
  // you probably are providing `typeName` in the catalog type format e.g. `Custom[\"01H5MT7M48QGX9TPMEZGB90HHA\"]`.
  // If you're calling it from anywhere else, it's likely in engine type format e.g. `CatalogEntry["01H5MT7M48QGX9TPMEZGB90HHA"]`
  mode: "engine" | "catalog";
  attributeBinding: AttributeBinding;
  catalogResources: CatalogResource[];
  className?: string;
  // When truncate is true, we'll only render the first entry with a (+x) label, others can be viewed by hovering
  truncate?: boolean;
  // When preview is true, if we can't find an associated catalog entry we'll
  // just render a greyed out catalog entry badge.
  preview?: boolean;
  // When jsonPreview is true, we'll render the value in a monospace font
  jsonPreview?: boolean;
  // When clickable is true, we'll render the value as a link to the entry
  clickable?: boolean;
  // If basePath is set, we'll link to the entry with the basePath as the prefix, so you can open a drawer
  // above an existing page.
  basePath?: string;
};

// AttributeEntries is most likely the way you want to render a catalog/alert attribute's values.
// It will handle checking if the binding is set, if it's a scalar or an array, and rendering the appropriate
// primitive or catalog type.
export const AttributeEntries = ({
  typeName,
  mode,
  attributeBinding,
  className,
  truncate = false,
  catalogResources,
  preview,
  jsonPreview = false,
  clickable = true,
  basePath,
}: AttributeEntriesProps) => {
  if (!attributeBinding || isEmpty(attributeBinding)) {
    return <div className="text-slate-300">&ndash;</div>;
  }

  const catalogResource = catalogResources.find((r) => {
    if (mode === "catalog") {
      return r.type === typeName;
    }
    return r.config.type === typeName;
  });

  if (!catalogResource) {
    // If there's no CatalogResource, this is probably just a boring engine only resource
    // just rendering the boring textual version of it
    if (attributeBinding.value) {
      return <BindingLabel paramBinding={attributeBinding.value} />;
    } else {
      attributeBinding.array_value = attributeBinding.array_value || [];
      return joinSpansWithCommasAndConnectorWord(
        attributeBinding.array_value.map((o) => (
          <BindingLabel paramBinding={o} key={o.label} />
        )),
      );
    }
  }

  if (hasArrayValue(attributeBinding)) {
    if (isPrimitive(typeName)) {
      return (
        <PrimitiveEntryList
          attributeBinding={attributeBinding}
          // If we're truncating, we shouldn't wrap, as things should be short
          // and single line. But if we're not truncating we're likely
          // presenting the whole value, and no wrap would extend sideways off
          // the screen.
          className={tcx(className, truncate ? "" : "flex-wrap")}
        />
      );
    }

    return (
      // NonPrimitiveEntryList takes care of rendering individual broken references correctly.
      <NonPrimitiveEntryList
        collapseList={truncate}
        arrayValue={attributeBinding.array_value}
        catalogResource={catalogResource}
        innerClassName={className}
        outerClassName="flex flex-wrap gap-2 w-fit"
        clickable={clickable}
        basePath={basePath}
      />
    );
  }

  // --- Single-value types ---
  if (hasSingleValue(attributeBinding)) {
    const label = isEmptyString(attributeBinding.value.label)
      ? attributeBinding.value.literal
      : attributeBinding.value.label;

    // References to other entries
    if (!isPrimitive(typeName)) {
      return (
        <NonPrimitiveEntry
          icon={catalogResource.icon}
          color={catalogResource.color}
          className={className}
          value={attributeBinding.value}
          preview={preview}
          clickable={clickable}
          basePath={basePath}
        />
      );
    }

    return (
      <PrimitiveEntry
        label={label}
        attributeBinding={attributeBinding}
        className={className}
        typeName={typeName}
        jsonPreview={jsonPreview}
      />
    );
  }

  // We can't ever hit this (isEmpty, hasSingleValue, hasArrayValue cover all cases)
  // but it makes typescript happy
  return <></>;
};
export type { AttributeBinding };
