import React from "react";
import { Route, Routes } from "react-router-dom";

import { IncidentRoleCreateEditModal } from "./IncidentRoleCreateEditModal";
import { IncidentRoleListPage } from "./IncidentRoleListPage";

export const IncidentRolesRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route path=":id/edit" element={<IncidentRoleCreateEditModal />} />
        <Route path="create" element={<IncidentRoleCreateEditModal />} />
      </Routes>
      <IncidentRoleListPage />
    </>
  );
};
