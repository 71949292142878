import {
  CustomFieldFormElement,
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { ErrorMessage } from "@incident-ui";
import { ModalFooter, RadioButton, StaticSingleSelect } from "@incident-ui";
import { compact } from "lodash";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import {
  CustomField,
  CustomFieldFieldTypeEnum,
  CustomFieldRequiredV2Enum,
  IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { BulkNoTriggersNotice } from "./BulkNoTriggersNotice";

type UpdateMode =
  | IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Delete
  | IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Set
  | IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Merge
  | "clear";

export type BulkUpdateCustomFieldsFormData = {
  update: FormCustomFieldEntries;
  updateMode: UpdateMode;
};

export function BulkUpdateCustomFieldsForm({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const analytics = useAnalytics();
  const formMethods = useForm<BulkUpdateCustomFieldsFormData>({
    defaultValues: {
      updateMode: IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Set,
    },
  });
  const { watch, setValue } = formMethods;

  const [selectedCustomField, setSelectedCustomField] =
    useState<CustomField | null>(null);
  const [bulkErrors, setBulkErrors] = useState<string[] | null>(null);

  const {
    data: { custom_fields: customFields },
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  if (customFieldsError) {
    throw customFieldsError;
  }
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, data: BulkUpdateCustomFieldsFormData) => {
      if (!selectedCustomField) {
        return;
      }

      analytics?.track("bulkActionApplied", {
        action: "update custom field",
        numIncidents: incidentIDs.length,
      });

      const { results } = await apiClient.incidentsBulkUpdateCustomFieldEntries(
        {
          bulkUpdateCustomFieldEntriesRequestBody: {
            incident_ids: incidentIDs,
            // if we're deleting a select or multi-select field, we want to send a 'set' with an empty array instead.
            // as normally the delete operation is used to remove a value from a multi-select field, not to clear it.
            mode:
              data.updateMode === "clear" // if we're clearing the field
                ? IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Set // send a 'set' instead
                : data.updateMode, // otherwise use the selected updateMode
            custom_field_entries: marshallCustomFieldEntriesToRequestPayload(
              [selectedCustomField],
              data.updateMode === "clear"
                ? {
                    [selectedCustomField.id]: {
                      values: [],
                      hasBeenManuallyEdited: false,
                    },
                  } // if we're clearing make sure to send an empty payload
                : data.update, // otherwise, send the payload as normal
              data.update,
            ),
          },
        },
      );
      const errors = compact(results.map((result) => result.error));

      if (errors && errors.length !== 0) {
        setBulkErrors(errors);
      } else {
        onSubmit();
      }
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={submit}
      title={"Update custom field"}
      analyticsTrackingId="bulk-assign-custom-field"
      onClose={onClose}
      genericError={genericError}
      loading={!customFields}
      footer={
        <ModalFooter
          confirmButtonText={"Apply"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      {customFields && (
        <>
          <Form.Label htmlFor="">
            Which Custom Field would you like to update?
          </Form.Label>
          <StaticSingleSelect
            placeholder="Select a custom field"
            value={selectedCustomField?.id}
            onChange={(selected) => {
              if (!selected) {
                setSelectedCustomField(null);
                return;
              }
              const selectedField = customFields.find((f) => f.id === selected);
              if (selectedField) {
                setSelectedCustomField(selectedField);
              }
            }}
            options={customFields.map((field) => ({
              label: field.name,
              value: field.id,
            }))}
          />
          {selectedCustomField && (
            <UpdateFieldEntry
              selectedCustomField={selectedCustomField}
              formMethods={formMethods}
              updateMode={watch("updateMode")}
              setUpdateMode={(mode) => setValue("updateMode", mode)}
            />
          )}
        </>
      )}
      {bulkErrors && (
        <ErrorMessage
          message={`We encountered a problem updating ${bulkErrors.length} of your incidents. If you keep encountering errors, please contact support.`}
        />
      )}
    </Form.Modal>
  );
}

const UpdateFieldEntry = ({
  selectedCustomField,
  formMethods,
  updateMode,
  setUpdateMode,
}: {
  selectedCustomField: CustomField;
  formMethods: UseFormReturn<BulkUpdateCustomFieldsFormData>;
  updateMode: UpdateMode;
  setUpdateMode: (mode: UpdateMode) => void;
}): React.ReactElement => {
  const isMulti =
    selectedCustomField?.field_type === CustomFieldFieldTypeEnum.MultiSelect;

  let updateVerb = "update";
  switch (updateMode) {
    case "clear":
      updateVerb = "update";
      break;
    case IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Delete:
      updateVerb = "remove";
      break;
    case IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Merge:
      updateVerb = "add";
      break;
    case IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Set:
      updateVerb = "set";
      break;
  }

  return (
    <div className="mt-4 text-sm flex gap-2 flex-col">
      <Form.Label htmlFor="updateMode">
        What would you like to change?
      </Form.Label>
      <RadioButton
        id="replace"
        label={isMulti ? "Replace all values" : "Replace value"}
        onChange={() => {
          setUpdateMode(
            IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Set,
          );
        }}
        checked={
          updateMode ===
          IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Set
        }
        value={IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Set}
      />
      {isMulti && (
        <RadioButton
          id="merge"
          label="Append values to existing ones"
          onChange={() => {
            setUpdateMode(
              IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Merge,
            );
          }}
          checked={
            updateMode ===
            IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Merge
          }
          value={IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Merge}
        />
      )}
      {selectedCustomField.required_v2 !== CustomFieldRequiredV2Enum.Always && (
        <RadioButton
          label={isMulti ? "Remove selected values" : "Delete value"}
          id="deleting"
          value={
            IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Delete
          }
          onChange={() => {
            setUpdateMode(
              IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Delete,
            );
          }}
          checked={
            updateMode ===
            IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum.Delete
          }
        />
      )}
      {isMulti &&
        selectedCustomField.required_v2 !==
          CustomFieldRequiredV2Enum.Always && (
          <RadioButton
            id="merge"
            label="Clear all values"
            onChange={() => {
              setUpdateMode("clear");
            }}
            checked={updateMode === "clear"}
            value={"clear"}
          />
        )}
      <div className="mt-4">
        <CustomFieldFormElement<BulkUpdateCustomFieldsFormData>
          fieldKeyPrefix={`update.[${selectedCustomField.id}].values`}
          key={selectedCustomField.id}
          customField={selectedCustomField}
          label={`Which value${
            isMulti ? "s" : ""
          } would you like to ${updateVerb}?`}
          formMethods={formMethods}
          autoFocus={true}
          required={
            selectedCustomField.required_v2 ===
              CustomFieldRequiredV2Enum.Always && updateMode !== "clear"
          }
          disabled={updateMode === "clear"}
          includeNoValue={false}
          // We can't sensibly filter custom field options here as we have multiple incidents, so pass
          // in an empty array as if the incident has no custom field values at all (which will mean
          // no filtering happens)
          entryPayloads={[]}
        />
      </div>
      <BulkNoTriggersNotice className="mt-4" />
    </div>
  );
};
