import { useEffect } from "react";
import { useLocation } from "react-router";

// React router doesn't return you to the top of the page when the nav changes
// which is a bit jarring if you're navigating between settings sub-pages.
// Because of the way our app is structured we can't use scrollTo(0, 0)
// so instead we scroll a dummy component into view just above the header.
export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const root = document.getElementById("top");
    root && root.scrollIntoView();
  }, [pathname]);
  return null;
};
