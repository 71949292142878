/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface IncidentFormsCreateExpressionRequestBody
 */
export interface IncidentFormsCreateExpressionRequestBody {
    /**
     * 
     * @type {ExpressionPayload}
     * @memberof IncidentFormsCreateExpressionRequestBody
     */
    expression?: ExpressionPayload;
}

export function IncidentFormsCreateExpressionRequestBodyFromJSON(json: any): IncidentFormsCreateExpressionRequestBody {
    return IncidentFormsCreateExpressionRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsCreateExpressionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsCreateExpressionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expression': !exists(json, 'expression') ? undefined : ExpressionPayloadFromJSON(json['expression']),
    };
}

export function IncidentFormsCreateExpressionRequestBodyToJSON(value?: IncidentFormsCreateExpressionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expression': ExpressionPayloadToJSON(value.expression),
    };
}

