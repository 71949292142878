import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { formatTimestampLocale } from "src/utils/datetime";
import { tcx } from "src/utils/tailwind-classes";

const determineSyncIcon = (externalRepoUrl: string | undefined) => {
  if (!externalRepoUrl) return IconEnum.Cloud;
  if (externalRepoUrl.includes("github")) return IconEnum.Github;
  else if (externalRepoUrl.includes("gitlab")) return IconEnum.Gitlab;
  return IconEnum.GitBranch;
};

export const SourceRepoBadge = ({
  sourceRepoURL,
  lastSyncedAt,
  showTooltip,
}: {
  sourceRepoURL: string | undefined;
  lastSyncedAt?: Date;
  showTooltip?: boolean;
}): React.ReactElement | null => {
  if (!sourceRepoURL) {
    return null;
  }

  if (!showTooltip) {
    return <SourceRepoBadgeUI sourceRepoURL={sourceRepoURL} />;
  }

  return (
    <Tooltip
      content={
        <ExternallyManagedTooltipContent
          sourceRepoURL={sourceRepoURL}
          lastSyncedAt={lastSyncedAt}
        />
      }
      delayDuration={0}
    >
      <span className={tcx("w-fit cursor-not-allowed flex items-center")}>
        <Button analyticsTrackingId="" theme={ButtonTheme.Unstyled}>
          <SourceRepoBadgeUI sourceRepoURL={sourceRepoURL} />
        </Button>
      </span>
    </Tooltip>
  );
};

export const ExternallyManagedTooltipContent = ({
  sourceRepoURL,
  lastSyncedAt,
}: {
  sourceRepoURL: string;
  lastSyncedAt?: Date;
}) => (
  <>
    <div className="text-xs text-slate-300 mb-2">
      Last sync{" "}
      {lastSyncedAt
        ? formatTimestampLocale({
            timestamp: lastSyncedAt,
            dateStyle: "medium",
            timeStyle: "short",
          })
        : "unknown"}
    </div>
    <div className="">
      <a href={sourceRepoURL} target={"_blank"} rel="noreferrer">
        {`${
          sourceRepoURL.includes("github")
            ? "Edit in GitHub"
            : sourceRepoURL.includes("gitlab")
            ? "Edit in Gitlab"
            : "Edit externally"
        } `}
        <Icon
          id={IconEnum.ExternalLink}
          size={IconSize.Large}
          className="inline text-slate-200"
        />
      </a>
    </div>
  </>
);

const SourceRepoBadgeUI = ({
  sourceRepoURL,
}: {
  sourceRepoURL: string | undefined;
}): React.ReactElement | null => {
  return (
    <Badge
      icon={determineSyncIcon(sourceRepoURL)}
      iconClassName="text-content-tertiary"
      theme={BadgeTheme.Tertiary}
      size={BadgeSize.Small}
    >
      Synced
    </Badge>
  );
};
