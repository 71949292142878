import { Badge, BadgeTheme } from "@incident-ui/Badge/Badge";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Txt } from "@incident-ui/Txt/Txt";
import React from "react";
import { GetUnicodeForSlackEmoji } from "src/utils/slack";
import { tcx } from "src/utils/tailwind-classes";

import IncidentLogo from "../images/incident_logo.png";
import styles from "./SlackPreviews.module.scss";

export type SlackMessagePreviewProps = {
  children: React.ReactNode;
  showTitle?: boolean;
  className?: string;
};

export const SlackMessagePreview = ({
  children,
  className,
  showTitle,
}: SlackMessagePreviewProps): React.ReactElement => {
  return (
    <div>
      {showTitle && (
        <p className="font-semibold text-content-tertiary text-xs mb-2 tracking-widest uppercase">
          Preview
        </p>
      )}
      <div
        className={tcx(
          "px-4 py-4 rounded-[6px] border border-stroke shadow bg-white",
          className,
        )}
      >
        <SlackMessagePreviewContent>{children}</SlackMessagePreviewContent>
      </div>
    </div>
  );
};

export const SlackChannelMessagePreviewEmptyState = ({
  content,
}: {
  content: React.ReactNode;
}) => {
  return (
    <div>
      <div className="bg-white border border-stroke rounded-2">
        <div className="flex w-full mb-3">
          <div
            className={tcx(
              "rounded-full border border-stroke bg-white py-1 px-3 mt-[-14px] mx-auto",
            )}
          >
            <Txt className="!text-xs font-medium text-slate-600">Preview</Txt>
          </div>
        </div>
        <div className="flex items-center text-sm justify-center pb-6 pt-1">
          {content}
        </div>
      </div>
    </div>
  );
};

export const SlackChannelMessagePreview = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "py-4 border-x [border-image-slice:1]",
        "[border-image-source:linear-gradient(0deg,_rgba(2,0,36,0)_0%,_rgba(226,232,240,1)_10%,_rgba(226,232,240,1)_90%,_rgba(5,91,175,0)_100%)]",
        "[background:linear-gradient(0deg,_rgba(2,0,36,0)_0%,_white_10%,_white_90%,_rgba(5,91,175,0)_100%)]",
      )}
    >
      <div className="bg-white border-y border-stroke">
        <div className="p-4 mb-6">
          <SlackMessagePreviewContent>{children}</SlackMessagePreviewContent>
        </div>
      </div>
    </div>
  );
};

export const SlackMessagePreviewContent = ({
  children,
}: SlackMessagePreviewProps) => {
  return (
    <div className="flex">
      <SlackAppIcon />
      <div className="flex-grow -mt-1">
        <div className={tcx("flex", styles.text)}>
          <p className={"font-bold !mb-0 mr-1"}>incident.io</p>
          <Badge
            theme={BadgeTheme.Tertiary}
            className={"!rounded-[6px] !text-[9px] !leading-3 !py-0.5 !px-1"}
          >
            APP
          </Badge>
        </div>
        <div className={tcx(styles.text)}>{children}</div>
      </div>
    </div>
  );
};

function renderIcon(icon: IconEnum) {
  return <Icon id={icon} className="mx-0" size={IconSize.Small} />;
}

export const SlackButtonPreview = ({
  emoji,
  text,
  className,
  buttonClassName,
  icon,
}: {
  emoji?: string;
  text: string;
  className?: string;
  buttonClassName?: string;
  // Provide an icon if you want to override the emoji with an icon (ie. the Slack icon)
  icon?: IconEnum;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        `text-slate-700 text-sm rounded-2 border border-stroke flex-center-y text-left px-2 py-1.5 shrink-0`,
        className,
      )}
    >
      {(!!icon && renderIcon(icon)) ||
        (!!emoji && GetUnicodeForSlackEmoji(emoji))}
      <span className={tcx("ml-1 !text-sm text-black", buttonClassName)}>
        {text}
      </span>
    </div>
  );
};

export const SlackAppIcon = ({ className }: { className?: string }) => {
  return (
    <img
      className={tcx(
        "mr-3 max-h-[2.5rem] max-w-[2.5rem] rounded-[6px]",
        className,
      )}
      src={IncidentLogo}
    />
  );
};

/**
 * SlackButton is just a visual representation of a Slack button,
 * with no onClick.
 */
export const SlackModalFooterButton = ({
  className,
  primary,
  children,
}: {
  className?: string;
  primary?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={tcx(
        "px-4 py-2 font['lato'] font-medium rounded-[6px] border border-stroke",
        { "bg-[#007a5a] text-white": primary },
        className,
      )}
    >
      {children}
    </div>
  );
};
