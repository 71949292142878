/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegacyPostmortemTemplate,
    LegacyPostmortemTemplateFromJSON,
    LegacyPostmortemTemplateFromJSONTyped,
    LegacyPostmortemTemplateToJSON,
} from './LegacyPostmortemTemplate';

/**
 * 
 * @export
 * @interface PostmortemsShowLegacyTemplateResponseBody
 */
export interface PostmortemsShowLegacyTemplateResponseBody {
    /**
     * 
     * @type {LegacyPostmortemTemplate}
     * @memberof PostmortemsShowLegacyTemplateResponseBody
     */
    postmortem_template: LegacyPostmortemTemplate;
}

export function PostmortemsShowLegacyTemplateResponseBodyFromJSON(json: any): PostmortemsShowLegacyTemplateResponseBody {
    return PostmortemsShowLegacyTemplateResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsShowLegacyTemplateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsShowLegacyTemplateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postmortem_template': LegacyPostmortemTemplateFromJSON(json['postmortem_template']),
    };
}

export function PostmortemsShowLegacyTemplateResponseBodyToJSON(value?: PostmortemsShowLegacyTemplateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postmortem_template': LegacyPostmortemTemplateToJSON(value.postmortem_template),
    };
}

