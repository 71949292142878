import type { SVGProps } from "react";
import * as React from "react";
const SvgTerraformStrikethrough = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.525 7.563v4.78l4.142-2.388V5.169l-4.142 2.394ZM7.93 5.17l4.14 2.393v4.78L7.93 9.952V5.169ZM3.332 2.5v4.783l4.142 2.392V4.89L3.333 2.5ZM7.93 15.26l4.142 2.392v-4.784l-4.142-2.39v4.782Z"
    />
    <path
      fill="currentColor"
      stroke="#F8F8F8"
      strokeWidth={2}
      d="M2.586 14.5 15.314 1.772l2.475 2.475L5.06 16.975z"
    />
  </svg>
);
export default SvgTerraformStrikethrough;
