/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIKeysCreateRequestBody,
    APIKeysCreateRequestBodyFromJSON,
    APIKeysCreateRequestBodyToJSON,
    APIKeysCreateResponseBody,
    APIKeysCreateResponseBodyFromJSON,
    APIKeysCreateResponseBodyToJSON,
    APIKeysListResponseBody,
    APIKeysListResponseBodyFromJSON,
    APIKeysListResponseBodyToJSON,
    APIKeysListRolesAndAvailabilityResponseBody,
    APIKeysListRolesAndAvailabilityResponseBodyFromJSON,
    APIKeysListRolesAndAvailabilityResponseBodyToJSON,
} from '../models';

export interface APIKeysCreateRequest {
    createRequestBody: APIKeysCreateRequestBody;
}

export interface APIKeysDeleteRequest {
    id: string;
}

/**
 * 
 */
export class APIKeysApi extends runtime.BaseAPI {

    /**
     * Create a new  API key
     * Create API Keys
     */
    async aPIKeysCreateRaw(requestParameters: APIKeysCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<APIKeysCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling aPIKeysCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/api_keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIKeysCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIKeysCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new  API key
     * Create API Keys
     */
    async aPIKeysCreate(requestParameters: APIKeysCreateRequest, initOverrides?: RequestInit): Promise<APIKeysCreateResponseBody> {
        const response = await this.aPIKeysCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing API key
     * Delete API Keys
     */
    async aPIKeysDeleteRaw(requestParameters: APIKeysDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aPIKeysDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/api_keys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing API key
     * Delete API Keys
     */
    async aPIKeysDelete(requestParameters: APIKeysDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aPIKeysDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * List all API keys for an organisation
     * List API Keys
     */
    async aPIKeysListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<APIKeysListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/api_keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIKeysListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all API keys for an organisation
     * List API Keys
     */
    async aPIKeysList(initOverrides?: RequestInit): Promise<APIKeysListResponseBody> {
        const response = await this.aPIKeysListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all roles for API keys and whether it is available to be requested by a user.
     * ListRolesAndAvailability API Keys
     */
    async aPIKeysListRolesAndAvailabilityRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<APIKeysListRolesAndAvailabilityResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/api_key_roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIKeysListRolesAndAvailabilityResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all roles for API keys and whether it is available to be requested by a user.
     * ListRolesAndAvailability API Keys
     */
    async aPIKeysListRolesAndAvailability(initOverrides?: RequestInit): Promise<APIKeysListRolesAndAvailabilityResponseBody> {
        const response = await this.aPIKeysListRolesAndAvailabilityRaw(initOverrides);
        return await response.value();
    }

}
