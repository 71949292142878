/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImagesUpdateStatusRequestBody
 */
export interface ImagesUpdateStatusRequestBody {
    /**
     * Upload status of the image
     * @type {string}
     * @memberof ImagesUpdateStatusRequestBody
     */
    status: ImagesUpdateStatusRequestBodyStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum ImagesUpdateStatusRequestBodyStatusEnum {
    Created = 'created',
    Uploaded = 'uploaded',
    UploadFailed = 'upload_failed',
    Deleted = 'deleted'
}

export function ImagesUpdateStatusRequestBodyFromJSON(json: any): ImagesUpdateStatusRequestBody {
    return ImagesUpdateStatusRequestBodyFromJSONTyped(json, false);
}

export function ImagesUpdateStatusRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImagesUpdateStatusRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function ImagesUpdateStatusRequestBodyToJSON(value?: ImagesUpdateStatusRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

