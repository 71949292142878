/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnresolvedExternalUser
 */
export interface UnresolvedExternalUser {
    /**
     * The external ID of the user
     * @type {string}
     * @memberof UnresolvedExternalUser
     */
    external_id: string;
    /**
     * The name of the user
     * @type {string}
     * @memberof UnresolvedExternalUser
     */
    name: string;
    /**
     * URL to the external resource user, useful to redirect the user to somewhere they can manually link this user
     * @type {string}
     * @memberof UnresolvedExternalUser
     */
    url?: string;
}

export function UnresolvedExternalUserFromJSON(json: any): UnresolvedExternalUser {
    return UnresolvedExternalUserFromJSONTyped(json, false);
}

export function UnresolvedExternalUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnresolvedExternalUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': json['external_id'],
        'name': json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function UnresolvedExternalUserToJSON(value?: UnresolvedExternalUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'name': value.name,
        'url': value.url,
    };
}

