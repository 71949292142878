import { SecondaryNavPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { SecondaryNavHorizontalItemType } from "@incident-shared/layout/SecondaryNavHorizontal";
import { IconEnum } from "@incident-ui";
import React from "react";
import { Outlet } from "react-router";

export const UserPreferencesPage = (): React.ReactElement => {
  const userPreferencesPages: SecondaryNavHorizontalItemType[] = [
    {
      label: "On-call notifications",
      slug: "on-call-notifications",
    },
    {
      label: "Incident subscriptions",
      slug: "incident-subscriptions",
    },
    {
      label: "Notifications",
      slug: "notifications",
    },
    {
      label: "Connected accounts",
      slug: "connected-accounts",
    },
  ];

  return (
    <SecondaryNavPageWrapper
      title={"Preferences"}
      icon={IconEnum.User}
      navItemGroups={[
        {
          items: userPreferencesPages,
        },
      ]}
      pathPrefix={"user-preferences"}
    >
      <Outlet />
    </SecondaryNavPageWrapper>
  );
};
