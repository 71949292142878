import {
  ClientType,
  FollowUp,
  FollowUpsConnectExternalIssueRequestBodyProviderEnum,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  IssueTrackersJiraCreateIssueRequestBody,
  IssueTrackersJiraServerTypeaheadOptionsFieldEnum,
  IssueTrackersJiraServerTypeaheadOptionsRequest,
  IssueTrackersJiraTypeaheadOptionsFieldEnum,
  IssueTrackersJiraTypeaheadOptionsRequest,
  JiraIssueField as JiraIssueField,
  SelectOption,
  useClient,
} from "src/contexts/ClientContext";

// These two request bodies are essentially the same, and this interface wraps
// the two so that TypeScript understands that they're actually the same thing
export type JiraCreateData = IssueTrackersJiraCreateIssueRequestBody;

// This interface adds on the two (identical) request bodies for the "connect
// issue", which means it contains _all_ the possible form fields in either tab.
export type JiraFormData = JiraCreateData & { url: string };

export enum JiraStep {
  // Step0 is when you select site. Most users will not see this, since they
  // have one site connected.
  Step0 = "step_0",
  // Step1 is when you select the project
  Step1 = "step_1",
  // Step2 is when you fill in the issue type
  Step2 = "step_2",
  // Step3 is when you fill in everything else
  Step3 = "step_3",
}

export interface JiraTypeaheadFieldEnum<TFieldEnum> {
  Project: TFieldEnum;
  Issuetype: TFieldEnum;
  Label: TFieldEnum;
  Epic: TFieldEnum;
  User: TFieldEnum;
}

export interface IssueFieldResponse {
  issue_fields: JiraIssueField[];
  has_description: boolean;
  unsupported_required_fields: string[];
}

// This is some slightly confusing generics to work around the
// different-but-actually-the-same typeahead field enums. In this context,
// `TFieldEnum` is the _type_ of the enum member, and `JiraTypeaheadFieldEnum` is an
// interface that is satisfied by either of the enums generated in the client
// library.
//
// Each of the other functions here wraps a Jira Cloud/Server-specific
// implementation of the same functionality, and returns out the
// non-implemenation specific type (e.g. a generic typeahead element, or a
// generic linked-issue struct).
export type JiraFieldEnums =
  | IssueTrackersJiraTypeaheadOptionsFieldEnum
  | IssueTrackersJiraServerTypeaheadOptionsFieldEnum;

// This is a hack to convince typescript that yes, the two request types are
// compatible I promise.
type GetOptionsRequest<TFieldEnum extends JiraFieldEnums> = Omit<
  IssueTrackersJiraTypeaheadOptionsRequest,
  "field"
> &
  Omit<IssueTrackersJiraServerTypeaheadOptionsRequest, "field"> & {
    field: TFieldEnum;
  };
export type JiraOptionFetcher<TFieldEnum extends JiraFieldEnums> = (
  req: GetOptionsRequest<TFieldEnum>,
) => Promise<{ typeahead_options: SelectOption[] }>;

export type JiraConfig<
  TFieldEnum extends JiraFieldEnums,
  TIssueFields = TFieldEnum extends IssueTrackersJiraTypeaheadOptionsFieldEnum
    ? "issueTrackersJiraGetCreateIssueFields"
    : "issueTrackersJiraServerGetCreateIssueFields",
  TOptions = TFieldEnum extends IssueTrackersJiraTypeaheadOptionsFieldEnum
    ? "issueTrackersJiraTypeaheadOptions"
    : "issueTrackersJiraServerTypeaheadOptions",
  TDefaultsForFollowUp = TFieldEnum extends IssueTrackersJiraTypeaheadOptionsFieldEnum
    ? "issueTrackersJiraGetIssueTemplateForFollowUp"
    : null,
> = {
  name: string;
  issueUrlTemplate: string;
  integrationProvider: IntegrationProvider;

  getIssueFieldsAPI: TIssueFields;
  getTypeaheadOptionsAPI: TOptions;
  getDefaultsForFollowUp: TDefaultsForFollowUp;

  fieldEnum: JiraTypeaheadFieldEnum<TFieldEnum>;

  supportsIssueTemplates: boolean;

  connectIssue: (
    apiClient: ClientType,
    args: {
      url: string;
      follow_up_id: string;
    },
  ) => Promise<FollowUp>;
};

export const useGetJiraOptions = <TFieldEnum extends JiraFieldEnums>({
  getTypeaheadOptionsAPI,
}: JiraConfig<TFieldEnum>) => {
  const apiClient = useClient();

  const fetcher = apiClient[getTypeaheadOptionsAPI].bind(
    apiClient,
  ) as JiraOptionFetcher<TFieldEnum>;

  return async (req: GetOptionsRequest<TFieldEnum>) =>
    (await fetcher(req)).typeahead_options;
};

export const JIRA_CLOUD_CONFIG: JiraConfig<IssueTrackersJiraTypeaheadOptionsFieldEnum> =
  {
    name: "Jira",
    issueUrlTemplate: "https://example.atlassian.net/browse/TEST-123",
    integrationProvider: IntegrationProvider.Jira,

    fieldEnum: IssueTrackersJiraTypeaheadOptionsFieldEnum,

    supportsIssueTemplates: true,

    getIssueFieldsAPI: "issueTrackersJiraGetCreateIssueFields",
    getTypeaheadOptionsAPI: "issueTrackersJiraTypeaheadOptions",
    getDefaultsForFollowUp: "issueTrackersJiraGetIssueTemplateForFollowUp",

    connectIssue: async (
      apiClient,
      args: { url: string; follow_up_id: string },
    ) => {
      const resp = await apiClient.followUpsConnectExternalIssue({
        id: args.follow_up_id,
        connectExternalIssueRequestBody: {
          provider: FollowUpsConnectExternalIssueRequestBodyProviderEnum.Jira,
          url: args.url,
        },
      });
      return resp.follow_up;
    },
  };

export const JIRA_SERVER_CONFIG: JiraConfig<IssueTrackersJiraServerTypeaheadOptionsFieldEnum> =
  {
    name: "Jira Server",
    issueUrlTemplate: "https://jira.example.com/browse/TEST-123",
    integrationProvider: IntegrationProvider.JiraServer,

    fieldEnum: IssueTrackersJiraServerTypeaheadOptionsFieldEnum,

    supportsIssueTemplates: false,

    getIssueFieldsAPI: "issueTrackersJiraServerGetCreateIssueFields",
    getTypeaheadOptionsAPI: "issueTrackersJiraServerTypeaheadOptions",
    getDefaultsForFollowUp: null,

    connectIssue: async (apiClient, { url, follow_up_id }) => {
      const resp = await apiClient.followUpsConnectExternalIssue({
        id: follow_up_id,
        connectExternalIssueRequestBody: {
          url,
          provider:
            FollowUpsConnectExternalIssueRequestBodyProviderEnum.JiraServer,
        },
      });
      return resp.follow_up;
    },
  };
