import {
  EmptyStateAllFiltered,
  useFiltersContext,
} from "@incident-shared/filters";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useGetIncidentsWithSyntheticFilters } from "@incident-shared/incidents";
import {
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  Heading,
  IconEnum,
  Loader,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { FIRST_ACCESS_MODAL_RELEASE_DATE } from "src/components/catalog/type-list/CatalogFirstAccessModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  ActionStatusEnum as ActionStatus,
  IncidentsListSortByEnum,
  PostIncidentTaskSlim,
  SavedView,
  SavedViewsListContextEnum,
} from "../../../contexts/ClientContext";
import {
  SavedViewsProvider,
  useSavedViews,
} from "../../saved-views/SavedViewContext";
import postIncidentFlowGraphic from "../../settings/images/post_incident_flow.png";
import { PostIncidentFiltersProvider } from "./PostIncidentFiltersProvider";
import {
  PINC_FIRST_ACCESS_MODAL,
  PostIncidentFirstAccessModal,
} from "./PostIncidentFirstAccessModal";
import { PostIncidentList } from "./PostIncidentList";
import { PostIncidentListHeader } from "./PostIncidentListHeader";

export enum PostIncidentTasksParam {
  SortBy = "sortBy",
}

export type PostIncidentTasksParams = {
  [PostIncidentTasksParam.SortBy]: IncidentsListSortByEnum;
};

const makeOutstandingTasksView = (user_id: string): SavedView => {
  const defaultQueryParams = new URLSearchParams();
  defaultQueryParams.set("post_incident_task_owner[one_of]", user_id);
  defaultQueryParams.set(
    "post_incident_task_status[one_of]",
    ActionStatus.Outstanding,
  );
  return {
    id: "my_outstanding_tasks",
    name: "My outstanding tasks",
    url_params: defaultQueryParams.toString(),
  };
};

const makeAllOutstandingTasksView = (): SavedView => {
  const defaultQueryParams = new URLSearchParams();
  defaultQueryParams.set(
    "post_incident_task_status[one_of]",
    ActionStatus.Outstanding,
  );
  return {
    id: "all_outstanding_tasks",
    name: "All outstanding tasks",
    url_params: defaultQueryParams.toString(),
  };
};

export const PostIncidentPage = () => {
  const { identity } = useIdentity();

  const defaultQueryParams = new URLSearchParams();
  const defaultSortByOption = IncidentsListSortByEnum.OldestPostIncidentFirst;
  defaultQueryParams.set(PostIncidentTasksParam.SortBy, defaultSortByOption);

  const defaultView = identity && makeOutstandingTasksView(identity.user_id);
  const allTasksView = makeAllOutstandingTasksView();

  return (
    <SavedViewsProvider
      context={SavedViewsListContextEnum.PostIncidentTasks}
      defaultQueryParams={defaultQueryParams}
      presetViews={defaultView ? [defaultView, allTasksView] : [allTasksView]}
      defaultViewID={defaultView?.id}
    >
      <PostIncidentFiltersProvider>
        <PostIncidentFilteredPage defaultView={defaultView} />
      </PostIncidentFiltersProvider>
    </SavedViewsProvider>
  );
};

export const PostIncidentFilteredPage = ({
  defaultView,
}: {
  defaultView: SavedView | null;
}) => {
  const [selectedTasks, setSelectedTasks] = useState<PostIncidentTaskSlim[]>(
    [],
  );
  const { filters } = useFiltersContext();
  const { incidents, isLoading, refetchIncidents } =
    useGetIncidentsWithSyntheticFilters({
      filters,
      fixedFilters: {
        includePostIncidentTasks: true,
        sortBy: IncidentsListSortByEnum.OldestPostIncidentFirst,
        pageSize: 50,
      },
      eagerLoad: true,
    });

  const {
    data: { post_incident_flows: flows },
    isLoading: flowsLoading,
    error: flowsError,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  const {
    data: { incident_statuses: statuses },
    isLoading: statusesLoading,
    error: statusError,
  } = useAPI("incidentLifecyclesListAllStatuses", undefined, {
    fallbackData: { incident_statuses: [] },
  });

  const { settings, settingsLoading } = useSettings();

  const { identity, hasDismissedCTA } = useIdentity();

  const [showModal, setShowModal] = useState(
    // Don't show the first access modal to users that already existed before we introduced it
    !hasDismissedCTA(PINC_FIRST_ACCESS_MODAL) &&
      identity.first_login_at > FIRST_ACCESS_MODAL_RELEASE_DATE,
  );
  const { trigger: dismissModal, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: PINC_FIRST_ACCESS_MODAL,
          for_whole_organisation: false,
        },
      });
    },
  );
  const closeAndDismissModal = () => {
    setShowModal(false);
    dismissModal({});
  };

  const loading =
    flowsLoading || isLoading || settingsLoading || statusesLoading;

  if (flowsError) {
    return <GenericErrorMessage error={flowsError} />;
  }

  if (statusError) {
    return <GenericErrorMessage error={statusError} />;
  }

  // If there aren't any flows, just show a section to set one up
  if (flows.length === 0 && !loading) {
    return <PostIncidentFlowNotConfiguredSection />;
  }

  // Filter out incidents which don't have any pinc tasks
  const filteredIncidents = incidents.filter((inc) => inc.post_incident_tasks);
  const totalNumberOfFilteredIncidents = filteredIncidents.length;

  return (
    <>
      <PostIncidentListHeader
        incidents={filteredIncidents}
        totalNumberOfIncidents={totalNumberOfFilteredIncidents ?? null}
        selectedTasks={selectedTasks}
        setSelectedTasks={setSelectedTasks}
        refetchIncidents={refetchIncidents}
      />
      {loading ? (
        <div className={"bg-white pb-24"}>
          <Loader />
        </div>
      ) : filteredIncidents.length === 0 ? (
        <div className="mt-4">
          <EmptyPostIncidentList defaultView={defaultView} />
        </div>
      ) : (
        <PostIncidentList
          incidents={filteredIncidents}
          statuses={statuses}
          flows={flows}
          refetchIncidents={refetchIncidents}
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
          // @ts-expect-error settings will never be null here because we'll be showing a loader instead
          settings={settings}
        />
      )}
      <AnimatePresence>
        {showModal && (
          <PostIncidentFirstAccessModal
            onClose={closeAndDismissModal}
            loading={dismissing}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const EmptyPostIncidentList = ({
  defaultView,
}: {
  defaultView: SavedView | null;
}): React.ReactElement => {
  const { filters } = useFiltersContext();
  const { selectedSavedView, viewIsDirty } = useSavedViews();

  if (filters.length > 0) {
    if (
      selectedSavedView &&
      selectedSavedView.id === defaultView?.id &&
      !viewIsDirty
    ) {
      return (
        <EmptyState
          icon={IconEnum.Success}
          content="You don't have any tasks to do, nice!"
        />
      );
    }
    return <EmptyStateAllFiltered isKanban={false} />;
  }

  return (
    <EmptyState
      icon={IconEnum.Action}
      content="There are no incidents in the post-incident flow at the moment, so no tasks to complete."
    />
  );
};

const PostIncidentFlowNotConfiguredSection = () => {
  return (
    <div className="bg-surface-secondary rounded-2 border border-stroke flex flex-col md:flex-row justify-between md:items-end px-8 mx-auto">
      <div className="grow md:max-w-[50%] w-full my-auto pt-8 pb-4 md:py-0 space-y-5">
        <Heading level={1} size="medium" className="font-medium">
          Bounce back stronger after incidents
        </Heading>
        <p className="text-sm">
          With our post-incident flow, it&apos;s never been easier to ensure you
          are learning and improving after critical incidents.
        </p>
        <p className="text-sm">
          Just define the tasks you want to be done after an incident is
          resolved and before it is closed, and we&apos;ll make sure they get
          done.
        </p>
        <GatedButton
          analyticsTrackingId="set-up-post-inc-flow"
          theme={ButtonTheme.Primary}
          href="/settings/post-incident-flow"
        >
          Get set up
        </GatedButton>
      </div>
      <div
        className={
          "flex w-full h-[400px] overflow-hidden relative md:max-w-[40%] mt-8"
        }
      >
        <img
          className="absolute md:top-0 md:left-0 w-full"
          src={postIncidentFlowGraphic}
        />
      </div>
    </div>
  );
};
