/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SeatCount,
    SeatCountFromJSON,
    SeatCountFromJSONTyped,
    SeatCountToJSON,
} from './SeatCount';

/**
 * 
 * @export
 * @interface BillingListSeatCountsResponseBody
 */
export interface BillingListSeatCountsResponseBody {
    /**
     * 
     * @type {Array<SeatCount>}
     * @memberof BillingListSeatCountsResponseBody
     */
    seat_counts: Array<SeatCount>;
}

export function BillingListSeatCountsResponseBodyFromJSON(json: any): BillingListSeatCountsResponseBody {
    return BillingListSeatCountsResponseBodyFromJSONTyped(json, false);
}

export function BillingListSeatCountsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingListSeatCountsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seat_counts': ((json['seat_counts'] as Array<any>).map(SeatCountFromJSON)),
    };
}

export function BillingListSeatCountsResponseBodyToJSON(value?: BillingListSeatCountsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seat_counts': ((value.seat_counts as Array<any>).map(SeatCountToJSON)),
    };
}

