/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackQuickActionOption,
    SlackQuickActionOptionFromJSON,
    SlackQuickActionOptionFromJSONTyped,
    SlackQuickActionOptionToJSON,
} from './SlackQuickActionOption';

/**
 * 
 * @export
 * @interface IncidentChannelConfigsListQuickActionOptionsResponseBody
 */
export interface IncidentChannelConfigsListQuickActionOptionsResponseBody {
    /**
     * 
     * @type {Array<SlackQuickActionOption>}
     * @memberof IncidentChannelConfigsListQuickActionOptionsResponseBody
     */
    quick_action_options: Array<SlackQuickActionOption>;
}

export function IncidentChannelConfigsListQuickActionOptionsResponseBodyFromJSON(json: any): IncidentChannelConfigsListQuickActionOptionsResponseBody {
    return IncidentChannelConfigsListQuickActionOptionsResponseBodyFromJSONTyped(json, false);
}

export function IncidentChannelConfigsListQuickActionOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelConfigsListQuickActionOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quick_action_options': ((json['quick_action_options'] as Array<any>).map(SlackQuickActionOptionFromJSON)),
    };
}

export function IncidentChannelConfigsListQuickActionOptionsResponseBodyToJSON(value?: IncidentChannelConfigsListQuickActionOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quick_action_options': ((value.quick_action_options as Array<any>).map(SlackQuickActionOptionToJSON)),
    };
}

