/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Alert,
    AlertFromJSON,
    AlertFromJSONTyped,
    AlertToJSON,
} from './Alert';

/**
 * 
 * @export
 * @interface AlertsShowAlertResponseBody
 */
export interface AlertsShowAlertResponseBody {
    /**
     * 
     * @type {Alert}
     * @memberof AlertsShowAlertResponseBody
     */
    alert: Alert;
}

export function AlertsShowAlertResponseBodyFromJSON(json: any): AlertsShowAlertResponseBody {
    return AlertsShowAlertResponseBodyFromJSONTyped(json, false);
}

export function AlertsShowAlertResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsShowAlertResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert': AlertFromJSON(json['alert']),
    };
}

export function AlertsShowAlertResponseBodyToJSON(value?: AlertsShowAlertResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert': AlertToJSON(value.alert),
    };
}

