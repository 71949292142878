/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionDescriptionChange
 */
export interface ActionDescriptionChange {
    /**
     * the description that the action had after the change
     * @type {string}
     * @memberof ActionDescriptionChange
     */
    next_description: string;
    /**
     * the description that the action had before the change
     * @type {string}
     * @memberof ActionDescriptionChange
     */
    previous_description: string;
}

export function ActionDescriptionChangeFromJSON(json: any): ActionDescriptionChange {
    return ActionDescriptionChangeFromJSONTyped(json, false);
}

export function ActionDescriptionChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionDescriptionChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_description': json['next_description'],
        'previous_description': json['previous_description'],
    };
}

export function ActionDescriptionChangeToJSON(value?: ActionDescriptionChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_description': value.next_description,
        'previous_description': value.previous_description,
    };
}

