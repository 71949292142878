import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceHoneycombSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>Triggers</strong> section in Honeycomb
      </p>
      <p>
        Click <strong>New Trigger</strong>, and create a new trigger
      </p>
      <p>
        Scroll to the bottom, go to the <strong>Integration Centre</strong>
      </p>
      <p>
        When there, click <strong>Add Integration</strong> with the provider{" "}
        <strong>Webhook</strong>
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then copy and paste in the <strong>Name</strong>,{" "}
            <strong>Webhook URL</strong> and <strong>Shared Secret</strong>{" "}
            below
          </>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/honeycomb/${
              alertSourceConfig.id
            }`,
          },
          {
            title: "Shared Secret",
            code: alertSourceConfig.secret_token ?? "",
          },
        ]}
      />
      <p>
        Save your recepient, click <strong>Text</strong> to send a test alert
      </p>
      <p>
        Finally, you can use this webhook as a recpient on any trigger that you
        have created in Honeycomb
      </p>
    </SetupInfoNumberedList>
  );
};
