import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ErrorMessage } from "@incident-ui";
import {
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
} from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ScopeNameEnum,
  StatusPage,
  StatusPagePageTypeEnum,
  StatusPageStructure,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { emailRegex } from "src/utils/utils";

import { SubscriptionsList } from "../list/SubscriptionsList";

type SubscriptionSettingsFormType = {
  enable_subscriptions: boolean;
  reply_to_email: string;
};

export const SubscriptionSettingsEditPage = ({
  page,
  structure,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement | null => {
  const formMethods = useForm<SubscriptionSettingsFormType>({
    defaultValues: {
      enable_subscriptions: !page.subscriptions_disabled,
      reply_to_email: page.subscriptions_reply_to,
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: SubscriptionSettingsFormType) => {
      await apiClient.statusPageSetSubscriptionSettings({
        id: page.id,
        setSubscriptionSettingsRequestBody: {
          subscriptions_disabled: !data.enable_subscriptions,
          subscriptions_reply_to: data.reply_to_email,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (res) =>
        formMethods.reset({
          enable_subscriptions: !res.status_page.subscriptions_disabled,
          reply_to_email: res.status_page.subscriptions_reply_to,
        }),
    },
  );
  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const subscriptionsEnabled = formMethods.watch("enable_subscriptions");
  const isParentPage = !!page?.split_by_catalog_type_id;
  const isCustomerPage = page.page_type === StatusPagePageTypeEnum.Customer;

  return (
    <div className="space-y-8">
      <Form.Root
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
        innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
      >
        <h3 className="font-medium !-mb-2">Subscriptions</h3>
        <ErrorMessage message={genericError} />
        <Form.Helptext>
          Subscriptions allow your users to stay in the loop with live updates
          from incidents and maintenance windows on your status page. You can
          choose to not notify subscribers when declaring or updating an
          incident.{" "}
          {isParentPage &&
            `These settings will apply to all ${
              isCustomerPage ? "customer pages" : "sub-pages"
            }.`}
        </Form.Helptext>
        <ToggleV2
          name="enable_subscriptions"
          label="Enable subscriptions"
          formMethods={formMethods}
        />
        {subscriptionsEnabled ? (
          <InputV2
            formMethods={formMethods}
            name="reply_to_email"
            label="Reply-to email address"
            helptext={
              "The support email address that customers can reply to when they receive an email update on an incident or maintenance"
            }
            rules={{
              validate: (value) =>
                emailRegex.test(value)
                  ? undefined
                  : "Please enter a valid email address",
            }}
          />
        ) : (
          <Callout
            theme={CalloutTheme.Warning}
            showIcon={false}
            className="!p-4 text-slate-700 !block max-w-xl"
          >
            <div className="flex items-start">
              <Icon id={IconEnum.Subscription} />
              <div className="space-y-1">
                <p>
                  Subscriptions are disabled, meaning your customers are not
                  able to subscribe to your public status page.{" "}
                </p>
                {page.active_subscriber_count > 0 && (
                  <p>
                    Your{" "}
                    <span className="font-medium">
                      {page.active_subscriber_count} existing subscriber
                      {page.active_subscriber_count === 1 ? "" : "s"}
                    </span>{" "}
                    will no longer recieve updates about this status page.
                  </p>
                )}
              </div>
            </div>
          </Callout>
        )}

        <GatedButton
          type="submit"
          theme={ButtonTheme.Primary}
          analyticsTrackingId={"status-page-edit-subscriptions"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          loading={isMutating}
          disabled={missingPermission || !formMethods.formState.isDirty}
          disabledTooltipContent={
            missingPermission
              ? "You do not have permission to configure this public status page"
              : undefined
          }
          className="block"
        >
          Save
        </GatedButton>
      </Form.Root>
      <SubscriptionsList page={page} structure={structure} />
    </div>
  );
};
