/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchedulePayRule,
    SchedulePayRuleFromJSON,
    SchedulePayRuleFromJSONTyped,
    SchedulePayRuleToJSON,
} from './SchedulePayRule';
import {
    ScheduleReportOverride,
    ScheduleReportOverrideFromJSON,
    ScheduleReportOverrideFromJSONTyped,
    ScheduleReportOverrideToJSON,
} from './ScheduleReportOverride';

/**
 * 
 * @export
 * @interface SchedulePayConfig
 */
export interface SchedulePayConfig {
    /**
     * Base rate of pay per hour, in lowest denomination of specified currency
     * @type {number}
     * @memberof SchedulePayConfig
     */
    base_rate_cents: number;
    /**
     * 
     * @type {Date}
     * @memberof SchedulePayConfig
     */
    created_at: Date;
    /**
     * Currency used to send schedule, in ISO 4217 format
     * @type {string}
     * @memberof SchedulePayConfig
     */
    currency: string;
    /**
     * Unique internal ID of the pay config
     * @type {string}
     * @memberof SchedulePayConfig
     */
    id: string;
    /**
     * Human readable name of the pay config
     * @type {string}
     * @memberof SchedulePayConfig
     */
    name: string;
    /**
     * Time-bounded overrides for special pay rates
     * @type {Array<ScheduleReportOverride>}
     * @memberof SchedulePayConfig
     */
    overrides: Array<ScheduleReportOverride>;
    /**
     * Configures how we pay for time slots within a schedule
     * @type {Array<SchedulePayRule>}
     * @memberof SchedulePayConfig
     */
    pay_rules: Array<SchedulePayRule>;
    /**
     * When the pay config was published (if at all)
     * @type {Date}
     * @memberof SchedulePayConfig
     */
    published_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchedulePayConfig
     */
    updated_at: Date;
}

export function SchedulePayConfigFromJSON(json: any): SchedulePayConfig {
    return SchedulePayConfigFromJSONTyped(json, false);
}

export function SchedulePayConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulePayConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_rate_cents': json['base_rate_cents'],
        'created_at': (new Date(json['created_at'])),
        'currency': json['currency'],
        'id': json['id'],
        'name': json['name'],
        'overrides': ((json['overrides'] as Array<any>).map(ScheduleReportOverrideFromJSON)),
        'pay_rules': ((json['pay_rules'] as Array<any>).map(SchedulePayRuleFromJSON)),
        'published_at': !exists(json, 'published_at') ? undefined : (new Date(json['published_at'])),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function SchedulePayConfigToJSON(value?: SchedulePayConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_rate_cents': value.base_rate_cents,
        'created_at': (value.created_at.toISOString()),
        'currency': value.currency,
        'id': value.id,
        'name': value.name,
        'overrides': ((value.overrides as Array<any>).map(ScheduleReportOverrideToJSON)),
        'pay_rules': ((value.pay_rules as Array<any>).map(SchedulePayRuleToJSON)),
        'published_at': value.published_at === undefined ? undefined : (value.published_at.toISOString()),
        'updated_at': (value.updated_at.toISOString()),
    };
}

