/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsZendeskGenerateAPIKeyResponseBody,
    IntegrationsZendeskGenerateAPIKeyResponseBodyFromJSON,
    IntegrationsZendeskGenerateAPIKeyResponseBodyToJSON,
    IntegrationsZendeskGetConfigResponseBody,
    IntegrationsZendeskGetConfigResponseBodyFromJSON,
    IntegrationsZendeskGetConfigResponseBodyToJSON,
    IntegrationsZendeskUpdateConfigRequestBody,
    IntegrationsZendeskUpdateConfigRequestBodyFromJSON,
    IntegrationsZendeskUpdateConfigRequestBodyToJSON,
    IntegrationsZendeskUpdateConfigResponseBody,
    IntegrationsZendeskUpdateConfigResponseBodyFromJSON,
    IntegrationsZendeskUpdateConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsZendeskHandleInstallRequest {
    subdomain: string;
    organisationId: string;
}

export interface IntegrationsZendeskHandleRedirectRequest {
    code?: string;
    state?: string;
}

export interface IntegrationsZendeskUpdateConfigRequest {
    updateConfigRequestBody: IntegrationsZendeskUpdateConfigRequestBody;
}

/**
 * 
 */
export class IntegrationsZendeskApi extends runtime.BaseAPI {

    /**
     * Handle a request to generate an api key for usage in the incident.io Zendesk app
     * GenerateAPIKey Integrations - Zendesk
     */
    async integrationsZendeskGenerateAPIKeyRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsZendeskGenerateAPIKeyResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/zendesk/actions/generate_api_key`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsZendeskGenerateAPIKeyResponseBodyFromJSON(jsonValue));
    }

    /**
     * Handle a request to generate an api key for usage in the incident.io Zendesk app
     * GenerateAPIKey Integrations - Zendesk
     */
    async integrationsZendeskGenerateAPIKey(initOverrides?: RequestInit): Promise<IntegrationsZendeskGenerateAPIKeyResponseBody> {
        const response = await this.integrationsZendeskGenerateAPIKeyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle a request for an organisation\'s Zendesk config
     * GetConfig Integrations - Zendesk
     */
    async integrationsZendeskGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsZendeskGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/zendesk/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsZendeskGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Handle a request for an organisation\'s Zendesk config
     * GetConfig Integrations - Zendesk
     */
    async integrationsZendeskGetConfig(initOverrides?: RequestInit): Promise<IntegrationsZendeskGetConfigResponseBody> {
        const response = await this.integrationsZendeskGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Zendesk installation by redirecting to the Zendesk install endpoint
     * HandleInstall Integrations - Zendesk
     */
    async integrationsZendeskHandleInstallRaw(requestParameters: IntegrationsZendeskHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subdomain === null || requestParameters.subdomain === undefined) {
            throw new runtime.RequiredError('subdomain','Required parameter requestParameters.subdomain was null or undefined when calling integrationsZendeskHandleInstall.');
        }

        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsZendeskHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.subdomain !== undefined) {
            queryParameters['subdomain'] = requestParameters.subdomain;
        }

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/zendesk_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Zendesk installation by redirecting to the Zendesk install endpoint
     * HandleInstall Integrations - Zendesk
     */
    async integrationsZendeskHandleInstall(requestParameters: IntegrationsZendeskHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsZendeskHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Zendesk redirect, where we exchange the code for an access token
     * HandleRedirect Integrations - Zendesk
     */
    async integrationsZendeskHandleRedirectRaw(requestParameters: IntegrationsZendeskHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/zendesk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Zendesk redirect, where we exchange the code for an access token
     * HandleRedirect Integrations - Zendesk
     */
    async integrationsZendeskHandleRedirect(requestParameters: IntegrationsZendeskHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsZendeskHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Zendesk uninstallation request by forwarding the user to Zendesk
     * HandleUninstall Integrations - Zendesk
     */
    async integrationsZendeskHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/zendesk_uninstall`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Zendesk uninstallation request by forwarding the user to Zendesk
     * HandleUninstall Integrations - Zendesk
     */
    async integrationsZendeskHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsZendeskHandleUninstallRaw(initOverrides);
    }

    /**
     * Update the Zendesk config
     * UpdateConfig Integrations - Zendesk
     */
    async integrationsZendeskUpdateConfigRaw(requestParameters: IntegrationsZendeskUpdateConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsZendeskUpdateConfigResponseBody>> {
        if (requestParameters.updateConfigRequestBody === null || requestParameters.updateConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateConfigRequestBody','Required parameter requestParameters.updateConfigRequestBody was null or undefined when calling integrationsZendeskUpdateConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/zendesk/config`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsZendeskUpdateConfigRequestBodyToJSON(requestParameters.updateConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsZendeskUpdateConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the Zendesk config
     * UpdateConfig Integrations - Zendesk
     */
    async integrationsZendeskUpdateConfig(requestParameters: IntegrationsZendeskUpdateConfigRequest, initOverrides?: RequestInit): Promise<IntegrationsZendeskUpdateConfigResponseBody> {
        const response = await this.integrationsZendeskUpdateConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
