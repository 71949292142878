import {
  Alert,
  AlertAttribute,
  AlertSchema,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  AlertStatusEnum,
  CatalogResource,
  ErrorResponse,
} from "@incident-io/api";
import {
  prependSlugToPathIfNeeded,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import { SelectableTable } from "@incident-ui/Table/SelectableTable";
import {
  TableCell,
  TableCellStacked,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import { useParams } from "react-router";
import { AlertFiringIndicator } from "src/components/@shared/alerts/AlertFiringIndicator";
import { AttributeEntries } from "src/components/@shared/attribute";
import { tcx } from "src/utils/tailwind-classes";

import { AlertOverviewColumn } from "./AlertOverviewColumn";
import { AlertPriorityBadge } from "./AlertPriorityBadge";
import { AlertSourceTypeIcon } from "./AlertSourceTypeConfigs";

export const COLUMN_MAX_WIDTH = "max-w-[230px] truncate";

export type AlertsTableSelectionProps =
  | {
      enableSelection: false;
      selectedAlerts?: never;
      setSelectedAlerts?: never;
      selectAlert?: never;
      deselectAlert?: never;
    }
  | {
      enableSelection: true;
      selectedAlerts: Set<string>;
      selectAlert: (alert: Alert) => void;
      deselectAlert: (alert: Alert) => void;
      setSelectedAlerts: React.Dispatch<React.SetStateAction<Set<string>>>;
    };

export type AlertTableColumn =
  | {
      type: "attribute";
      attribute: AlertAttribute;
    }
  | {
      type: "priority";
      attribute?: never;
    }
  | {
      type: "alert_source";
      attribute?: never;
    };

export const makeColumns = (schema: AlertSchema): AlertTableColumn[] => [
  {
    type: "alert_source",
  },
  {
    type: "priority",
  },
  ...schema.attributes.map(
    (attribute): AlertTableColumn => ({
      type: "attribute",
      attribute,
    }),
  ),
];

export const AlertsTable = ({
  enableSelection,
  selectedAlerts,
  setSelectedAlerts,
  selectAlert,
  deselectAlert,
  visibleColumns,
  schema,
  resources,
  alerts,
  error,
  infiniteScrollRef,
  allEntriesLoaded,
  setSelectedAlert,
  isLoading,
  wrappedInBox,
  // By default we apply a max width of 50% but you may want the name (leftmost)
  // column to expand to fit the space, depending on where we're using the table.
  maxNameWidth = "50%",
  // By default we apply a min width of 230px to the columns, but you may want
  // to override this in some cases.
  minColumnWidth,
}: {
  visibleColumns?: AlertTableColumn[] | null;
  schema: AlertSchema;
  resources: CatalogResource[];
  alertSourceConfigs: AlertSourceConfig[];
  alerts: Alert[];
  error?: ErrorResponse;
  allEntriesLoaded: boolean;
  infiniteScrollRef?: UseInfiniteScrollHookRefCallback;
  setSelectedAlert?: (alert: Alert) => void;
  isLoading?: boolean;
  wrappedInBox?: boolean;
  maxNameWidth?: string;
  minColumnWidth?: string;
} & AlertsTableSelectionProps) => {
  const navigate = useOrgAwareNavigate();
  const { slug } = useParams();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (alerts.length === 0 && !isLoading) {
    return (
      <div className="h-full min-h-full">
        <AlertsEmptyState />
      </div>
    );
  }

  const columns = visibleColumns ?? makeColumns(schema);

  const headerTitle = (col: AlertTableColumn) => {
    if (col.type === "attribute") {
      return col.attribute.name;
    } else if (col.type === "priority") {
      return "Priority";
    } else {
      return "Alert Source";
    }
  };

  return (
    <SelectableTable
      className="overflow-x-auto"
      gridTemplateColumns={`minmax(400px, ${maxNameWidth}) ${columns
        .map((col) =>
          col.type === "alert_source"
            ? "2fr"
            : minColumnWidth
            ? "minmax(230px, 1fr)"
            : "fit-content(230px)",
        )
        .join(" ")}`}
      header={
        <>
          {/* Title */}
          <TableHeaderCell title="Alert" />
          {columns.map((col) => (
            <TableHeaderCell
              key={`${col.type}-${col.attribute?.id}-header`}
              title={headerTitle(col)}
            />
          ))}
        </>
      }
      data={alerts}
      renderRow={(alert, index, checkbox) => {
        return (
          <TableRow
            key={alert.id}
            className={tcx(
              enableSelection ? "cursor-pointer" : "!cursor-default",
            )}
            isLastRow={index === alerts.length - 1}
            onClick={(e) => {
              if (setSelectedAlert) {
                setSelectedAlert(alert);
              }
              if (!enableSelection) {
                return;
              }
              let path = `/alerts/${alert.id}/details`;
              if (slug) {
                path = prependSlugToPathIfNeeded(path, slug);
              }
              if (e.metaKey || e.ctrlKey) {
                const win = window.open(path, "_blank");
                win?.focus();
              } else {
                navigate(path);
              }
            }}
          >
            {checkbox}
            <TableCellStacked
              prefix={
                <AlertFiringIndicator
                  firing={alert.status === AlertStatusEnum.Firing}
                />
              }
              primary={<AlertOverviewColumn alert={alert} />}
              secondary={<></>}
            />
            {columns.map((col) => {
              switch (col.type) {
                case "attribute":
                  const attr = col.attribute;
                  const binding = alert.attribute_values[attr.id];
                  return (
                    <TableCell key={`attribute-${attr.id}-${alert.id}`}>
                      <AttributeEntries
                        mode={"engine"}
                        typeName={attr.type}
                        catalogResources={resources}
                        className={COLUMN_MAX_WIDTH}
                        attributeBinding={binding}
                        truncate
                      />
                    </TableCell>
                  );
                  break;
                case "priority":
                  return alert.priority ? (
                    <TableCell key={`priority-${alert.id}`}>
                      <AlertPriorityBadge
                        priority={alert.priority}
                        key="priority"
                      />
                    </TableCell>
                  ) : (
                    <TableCell className="text-slate-300">–</TableCell>
                  );
                  break;
                case "alert_source":
                  return (
                    <TableCell
                      key={`${alert.alert_source_config.id}-${alert.id}`}
                    >
                      <OrgAwareLink
                        to={`/alerts/sources/${alert.alert_source_config.id}/edit`}
                        className={
                          "flex hover:text-content-primary hover:underline transition text-ellipsis"
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        <AlertSourceTypeIcon
                          sourceType={
                            alert.alert_source_config
                              .source_type as unknown as AlertSourceSourceTypeEnum
                          }
                        />
                        <Txt className="font-medium ml-1">{`${
                          alert.alert_source_config.name
                        }${
                          alert.alert_source_config.archived_at
                            ? " (Deleted)"
                            : ""
                        }`}</Txt>
                      </OrgAwareLink>
                    </TableCell>
                  );
                  break;
                default:
                  return <TableCell className="text-slate-300">–</TableCell>;
              }
            })}
          </TableRow>
        );
      }}
      infiniteScroll={
        infiniteScrollRef && {
          isFullyLoaded: allEntriesLoaded,
          ref: infiniteScrollRef,
          isLoading: isLoading ?? false,
        }
      }
      selectAll={selectedAlerts?.size === alerts.length}
      onSelectAllChanged={
        setSelectedAlerts &&
        ((checked) => {
          if (checked) {
            setSelectedAlerts(new Set(alerts.map((a) => a.id)));
          } else {
            setSelectedAlerts(new Set());
          }
        })
      }
      selected={Array.from(selectedAlerts ?? [])}
      onSelectChanged={
        enableSelection
          ? (id, newValue) => {
              const targetAlert = alerts.find((a) => a.id === id);
              if (!targetAlert) {
                return;
              }
              if (newValue) {
                selectAlert && selectAlert(targetAlert);
              } else {
                deselectAlert && deselectAlert(targetAlert);
              }
            }
          : undefined
      }
      wrappedInBox={wrappedInBox}
    />
  );
};

export const AlertsEmptyState = () => {
  return (
    <EmptyState
      className="!h-full min-h-full grow"
      icon={IconEnum.Alert}
      title="No matching alerts"
      content="Alerts you have received will be listed here. If you are expecting to see alerts here, check your alert sources."
    />
  );
};
