/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';
import {
    UserWithRoles,
    UserWithRolesFromJSON,
    UserWithRolesFromJSONTyped,
    UserWithRolesToJSON,
} from './UserWithRoles';

/**
 * 
 * @export
 * @interface UsersListResponseBody
 */
export interface UsersListResponseBody {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof UsersListResponseBody
     */
    pagination_meta?: PaginationMeta;
    /**
     * 
     * @type {Array<UserWithRoles>}
     * @memberof UsersListResponseBody
     */
    users: Array<UserWithRoles>;
}

export function UsersListResponseBodyFromJSON(json: any): UsersListResponseBody {
    return UsersListResponseBodyFromJSONTyped(json, false);
}

export function UsersListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination_meta': !exists(json, 'pagination_meta') ? undefined : PaginationMetaFromJSON(json['pagination_meta']),
        'users': ((json['users'] as Array<any>).map(UserWithRolesFromJSON)),
    };
}

export function UsersListResponseBodyToJSON(value?: UsersListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
        'users': ((value.users as Array<any>).map(UserWithRolesToJSON)),
    };
}

