/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReportSchedule,
    ScheduleReportScheduleFromJSON,
    ScheduleReportScheduleFromJSONTyped,
    ScheduleReportScheduleToJSON,
} from './ScheduleReportSchedule';
import {
    ScheduleReportUser,
    ScheduleReportUserFromJSON,
    ScheduleReportUserFromJSONTyped,
    ScheduleReportUserToJSON,
} from './ScheduleReportUser';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ScheduleReport
 */
export interface ScheduleReport {
    /**
     * When the action was created
     * @type {Date}
     * @memberof ScheduleReport
     */
    created_at: Date;
    /**
     * 
     * @type {User}
     * @memberof ScheduleReport
     */
    creator: User;
    /**
     * Last date (YYYY-MM-DD form) this report should include shifts
     * @type {string}
     * @memberof ScheduleReport
     */
    end_date: string;
    /**
     * Unique internal ID of the schedule report
     * @type {string}
     * @memberof ScheduleReport
     */
    id: string;
    /**
     * Name of the report, given when published
     * @type {string}
     * @memberof ScheduleReport
     */
    name: string;
    /**
     * When the report was published
     * @type {Date}
     * @memberof ScheduleReport
     */
    published_at?: Date;
    /**
     * Shifts calculated for each of the schedules
     * @type {Array<ScheduleReportSchedule>}
     * @memberof ScheduleReport
     */
    schedules: Array<ScheduleReportSchedule>;
    /**
     * First date (YYYY-MM-DD form) this report will include shifts from
     * @type {string}
     * @memberof ScheduleReport
     */
    start_date: string;
    /**
     * If unpublished, the reason why
     * @type {string}
     * @memberof ScheduleReport
     */
    unpublish_reason?: string;
    /**
     * If unpublished, when that happened
     * @type {Date}
     * @memberof ScheduleReport
     */
    unpublished_at?: Date;
    /**
     * 
     * @type {User}
     * @memberof ScheduleReport
     */
    unpublisher?: User;
    /**
     * When the action was last updated
     * @type {Date}
     * @memberof ScheduleReport
     */
    updated_at: Date;
    /**
     * All users referenced in the schedules
     * @type {Array<ScheduleReportUser>}
     * @memberof ScheduleReport
     */
    users: Array<ScheduleReportUser>;
}

export function ScheduleReportFromJSON(json: any): ScheduleReport {
    return ScheduleReportFromJSONTyped(json, false);
}

export function ScheduleReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'creator': UserFromJSON(json['creator']),
        'end_date': json['end_date'],
        'id': json['id'],
        'name': json['name'],
        'published_at': !exists(json, 'published_at') ? undefined : (new Date(json['published_at'])),
        'schedules': ((json['schedules'] as Array<any>).map(ScheduleReportScheduleFromJSON)),
        'start_date': json['start_date'],
        'unpublish_reason': !exists(json, 'unpublish_reason') ? undefined : json['unpublish_reason'],
        'unpublished_at': !exists(json, 'unpublished_at') ? undefined : (new Date(json['unpublished_at'])),
        'unpublisher': !exists(json, 'unpublisher') ? undefined : UserFromJSON(json['unpublisher']),
        'updated_at': (new Date(json['updated_at'])),
        'users': ((json['users'] as Array<any>).map(ScheduleReportUserFromJSON)),
    };
}

export function ScheduleReportToJSON(value?: ScheduleReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'creator': UserToJSON(value.creator),
        'end_date': value.end_date,
        'id': value.id,
        'name': value.name,
        'published_at': value.published_at === undefined ? undefined : (value.published_at.toISOString()),
        'schedules': ((value.schedules as Array<any>).map(ScheduleReportScheduleToJSON)),
        'start_date': value.start_date,
        'unpublish_reason': value.unpublish_reason,
        'unpublished_at': value.unpublished_at === undefined ? undefined : (value.unpublished_at.toISOString()),
        'unpublisher': UserToJSON(value.unpublisher),
        'updated_at': (value.updated_at.toISOString()),
        'users': ((value.users as Array<any>).map(ScheduleReportUserToJSON)),
    };
}

