/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageSetWriteUpRequestBody
 */
export interface StatusPageSetWriteUpRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageSetWriteUpRequestBody
     */
    write_up_contents?: TextNode;
    /**
     * The URL of a public write-up for the incident
     * @type {string}
     * @memberof StatusPageSetWriteUpRequestBody
     */
    write_up_url?: string;
}

export function StatusPageSetWriteUpRequestBodyFromJSON(json: any): StatusPageSetWriteUpRequestBody {
    return StatusPageSetWriteUpRequestBodyFromJSONTyped(json, false);
}

export function StatusPageSetWriteUpRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageSetWriteUpRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'write_up_contents': !exists(json, 'write_up_contents') ? undefined : TextNodeFromJSON(json['write_up_contents']),
        'write_up_url': !exists(json, 'write_up_url') ? undefined : json['write_up_url'],
    };
}

export function StatusPageSetWriteUpRequestBodyToJSON(value?: StatusPageSetWriteUpRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'write_up_contents': TextNodeToJSON(value.write_up_contents),
        'write_up_url': value.write_up_url,
    };
}

