/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedSlackMessage,
    EnrichedSlackMessageFromJSON,
    EnrichedSlackMessageFromJSONTyped,
    EnrichedSlackMessageToJSON,
} from './EnrichedSlackMessage';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SlackInferSentry
 */
export interface SlackInferSentry {
    /**
     * 
     * @type {EnrichedSlackMessage}
     * @memberof SlackInferSentry
     */
    slack_message: EnrichedSlackMessage;
    /**
     * 
     * @type {User}
     * @memberof SlackInferSentry
     */
    user: User;
}

export function SlackInferSentryFromJSON(json: any): SlackInferSentry {
    return SlackInferSentryFromJSONTyped(json, false);
}

export function SlackInferSentryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackInferSentry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slack_message': EnrichedSlackMessageFromJSON(json['slack_message']),
        'user': UserFromJSON(json['user']),
    };
}

export function SlackInferSentryToJSON(value?: SlackInferSentry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slack_message': EnrichedSlackMessageToJSON(value.slack_message),
        'user': UserToJSON(value.user),
    };
}

