/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertAttributePayload,
    AlertAttributePayloadFromJSON,
    AlertAttributePayloadFromJSONTyped,
    AlertAttributePayloadToJSON,
} from './AlertAttributePayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    EngineParamBindingValue,
    EngineParamBindingValueFromJSON,
    EngineParamBindingValueFromJSONTyped,
    EngineParamBindingValueToJSON,
} from './EngineParamBindingValue';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface AlertSourceAttributeSuggestion
 */
export interface AlertSourceAttributeSuggestion {
    /**
     * When this suggestion was accepted
     * @type {Date}
     * @memberof AlertSourceAttributeSuggestion
     */
    accepted_at?: Date;
    /**
     * Whether an attribute of this type was already bound on creation
     * @type {boolean}
     * @memberof AlertSourceAttributeSuggestion
     */
    already_bound: boolean;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertSourceAttributeSuggestion
     */
    attribute_binding: EngineParamBindingPayload;
    /**
     * 
     * @type {Expression}
     * @memberof AlertSourceAttributeSuggestion
     */
    attribute_expression?: Expression;
    /**
     * 
     * @type {AlertAttributePayload}
     * @memberof AlertSourceAttributeSuggestion
     */
    attribute_payload: AlertAttributePayload;
    /**
     * Attribute values that were found with this suggestion, if any were found
     * @type {Array<EngineParamBindingValue>}
     * @memberof AlertSourceAttributeSuggestion
     */
    attribute_values?: Array<EngineParamBindingValue>;
    /**
     * The ID of the catalog type that this suggestion is for
     * @type {string}
     * @memberof AlertSourceAttributeSuggestion
     */
    catalog_type_id: string;
    /**
     * When this suggestion was created
     * @type {Date}
     * @memberof AlertSourceAttributeSuggestion
     */
    created_at: Date;
    /**
     * When this suggestion was declined
     * @type {Date}
     * @memberof AlertSourceAttributeSuggestion
     */
    declined_at?: Date;
    /**
     * The ID of this suggested attribute
     * @type {string}
     * @memberof AlertSourceAttributeSuggestion
     */
    id: string;
    /**
     * Ranking of this suggestion
     * @type {number}
     * @memberof AlertSourceAttributeSuggestion
     */
    preference_rank: number;
    /**
     * When this suggestion was last updated
     * @type {Date}
     * @memberof AlertSourceAttributeSuggestion
     */
    updated_at: Date;
}

export function AlertSourceAttributeSuggestionFromJSON(json: any): AlertSourceAttributeSuggestion {
    return AlertSourceAttributeSuggestionFromJSONTyped(json, false);
}

export function AlertSourceAttributeSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceAttributeSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accepted_at': !exists(json, 'accepted_at') ? undefined : (new Date(json['accepted_at'])),
        'already_bound': json['already_bound'],
        'attribute_binding': EngineParamBindingPayloadFromJSON(json['attribute_binding']),
        'attribute_expression': !exists(json, 'attribute_expression') ? undefined : ExpressionFromJSON(json['attribute_expression']),
        'attribute_payload': AlertAttributePayloadFromJSON(json['attribute_payload']),
        'attribute_values': !exists(json, 'attribute_values') ? undefined : ((json['attribute_values'] as Array<any>).map(EngineParamBindingValueFromJSON)),
        'catalog_type_id': json['catalog_type_id'],
        'created_at': (new Date(json['created_at'])),
        'declined_at': !exists(json, 'declined_at') ? undefined : (new Date(json['declined_at'])),
        'id': json['id'],
        'preference_rank': json['preference_rank'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AlertSourceAttributeSuggestionToJSON(value?: AlertSourceAttributeSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accepted_at': value.accepted_at === undefined ? undefined : (value.accepted_at.toISOString()),
        'already_bound': value.already_bound,
        'attribute_binding': EngineParamBindingPayloadToJSON(value.attribute_binding),
        'attribute_expression': ExpressionToJSON(value.attribute_expression),
        'attribute_payload': AlertAttributePayloadToJSON(value.attribute_payload),
        'attribute_values': value.attribute_values === undefined ? undefined : ((value.attribute_values as Array<any>).map(EngineParamBindingValueToJSON)),
        'catalog_type_id': value.catalog_type_id,
        'created_at': (value.created_at.toISOString()),
        'declined_at': value.declined_at === undefined ? undefined : (value.declined_at.toISOString()),
        'id': value.id,
        'preference_rank': value.preference_rank,
        'updated_at': (value.updated_at.toISOString()),
    };
}

