import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Card,
  IconEnum,
  Modal,
  ModalContent,
} from "@incident-ui";

import { ExpressionType } from "./AddEditExpressionModal";

export const ExpressionCreateChooseType = ({
  onClose,
  chooseType,
}: {
  onClose: () => void;
  chooseType: (type: ExpressionType) => void;
}) => {
  return (
    <Modal
      isOpen
      analyticsTrackingId="expression-create-choose-type"
      title="Create expression"
      onClose={onClose}
      isExtraLarge
    >
      <ModalContent className={"p-4 font-normal text-sm"}>
        <div className="flex flex-col gap-2">
          <span className="mb-2">
            Instead of manually entering a single value, you can use an{" "}
            <span className="font-semibold">expression</span> that changes based
            on other factors. There are two types of expression to choose from:
          </span>
          <div className="grid grid-cols-2 gap-2">
            <ExpressionTypeButton
              typeName="If... else..."
              description="Return a value that changes based on certain conditions."
              iconEnum={IconEnum.DecisionFlows}
              chooseType={chooseType}
              expressionTypeEnum="if_else"
            />
            <ExpressionTypeButton
              typeName="Query"
              description="Return a value that relates to another value & perform other operations."
              iconEnum={IconEnum.Database}
              chooseType={chooseType}
              expressionTypeEnum="query"
            />
            <ExpressionTypeExample
              label="Team Slack Channel"
              description="The exact channel returned by this value will depend on the value of a Team custom field."
            />
            <ExpressionTypeExample
              label="Escalation Policies"
              description="For an incident's affected services, find the teams who own them and then find their respective PagerDuty Escalation Policies."
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const ExpressionTypeButton = ({
  typeName,
  description,
  iconEnum,
  chooseType,
  expressionTypeEnum,
}: {
  typeName: string;
  description: string;
  iconEnum: IconEnum;
  chooseType: (type: ExpressionType) => void;
  expressionTypeEnum: ExpressionType;
}) => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId="expression-create-choose-type"
      analyticsTrackingMetadata={{ type: typeName }}
      onClick={() => chooseType(expressionTypeEnum)}
    >
      <Card icon={iconEnum} title={typeName} description={description} />
    </Button>
  );
};

const ExpressionTypeExample = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => {
  return (
    <div className="!items-start flex flex-col bg-surface-secondary rounded-2 transition p-3 space-y-2.5">
      <span className="text-content-tertiary font-medium tracking-widest text-xs">
        EXAMPLE
      </span>
      <div className="flex">
        <Badge
          theme={BadgeTheme.Unstyled}
          className="bg-amber-surface text-amber-content"
          icon={IconEnum.Expression}
        >
          {label}
        </Badge>
      </div>
      <span className="text-content-tertiary">{description}</span>
    </div>
  );
};
