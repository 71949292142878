import { useContext } from "react";
import { Incident } from "src/contexts/ClientContext";

import { IncidentCardContext } from "./IncidentCard";

export function useShouldHighlightStateDiff(
  diff: (lhs: Incident, rhs: Incident) => boolean,
) {
  const context = useContext(IncidentCardContext);
  if (context == null) {
    throw new Error("context can only be used within its provider");
  }
  const { isGridFirstUpdate, showNewness, incident, previousIncidentState } =
    context;

  const isDifferent = diff(previousIncidentState, incident);
  return isGridFirstUpdate || showNewness ? false : isDifferent;
}
