"use client";

import {
  InternalStatusPageIncident,
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentComponentImpact,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentIncidentLink,
  StatusPageContentListComponentImpactsResponseBody,
} from "@incident-io/api";
import {
  ANIMATION_MODIFIER,
  useParseTime,
  useTimeMaths,
  useUIContext,
} from "@incident-io/status-page-ui";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";

import { STATUS_SEVERITY } from "../../helpers";
import { useMemoCompare } from "../../use-memo-compare";
import { useTranslations } from "../../use-translations";
import { ChevronIcon } from "../Icons/ChevronIcon";
import { ComponentStatusIcon } from "../Icons/ComponentStatusIcon";
import { LoadingBar } from "../LoadingBar/LoadingBar";
import { Spinner } from "../Spinner/Spinner";
import { calculateDays, getDataAvailableSince, getUptime } from "./helpers";
import { InfoBadge } from "./InfoBadge";
import { GenericStructureItem, GroupStructureItem } from "./SystemStatus";
import { UptimeChart, UptimeChartDefaults } from "./UptimeChart";

// Renders a row in the system status, showing either a group of components or a
// single ungrouped component.
export const ItemStatus = ({
  currentComponentStatuses,
  item,
  data,
  isLoading,
  startAt,
  endAt,
  withoutTooltips = false,
  autoExpandGroups = false,
  incidents,
  showChart,
}: {
  currentComponentStatuses: Record<
    string,
    StatusPageAffectedComponentStatusEnum | undefined
  >;
  item: GenericStructureItem;
  data: StatusPageContentListComponentImpactsResponseBody;
  isLoading: boolean;
  startAt: DateTime;
  endAt: DateTime;
  withoutTooltips?: boolean;
  autoExpandGroups?: boolean;
  incidents: {
    [componentId: string]: InternalStatusPageIncident[];
  } | null;
  showChart: boolean;
}): React.ReactElement => {
  const { parse } = useParseTime();
  const {
    component_impacts: impacts,
    component_uptimes: uptimes,
    incident_links: incidentLinks,
  } = data;

  if (item.group) {
    return (
      <GroupStatus
        group={item.group}
        currentComponentStatuses={currentComponentStatuses}
        data={data}
        isLoading={isLoading}
        startAt={startAt}
        endAt={endAt}
        showStatusTooltip={!withoutTooltips}
        autoExpandGroups={autoExpandGroups}
        showChart={showChart}
      />
    );
  }

  const dataAvailableSince = getDataAvailableSince(parse)(
    uptimes,
    item.component.id,
  );

  return (
    <ComponentStatus
      componentIds={[item.component.id]}
      name={item.component.name}
      description={item.component.description}
      currentStatus={currentComponentStatuses[item.component.id] || "ok"}
      uptime={getUptime(uptimes, item.component.id)}
      dataAvailableSince={dataAvailableSince}
      isLoading={isLoading}
      isOpen={true}
      isGroup={false}
      startAt={startAt}
      endAt={endAt}
      impacts={impacts}
      incidentLinks={incidentLinks}
      showChart={showChart && item.component.displayUptime}
      showStatusTooltip={!withoutTooltips}
      ongoingIncidents={incidents ? incidents[item.component.id] : null}
    />
  );

  // throw new Error("not a group or a single component");
};

// Renders an uptime chart for a group, with a dropdown that expands the
// individual components.
const GroupStatus = ({
  group,
  currentComponentStatuses,
  data: {
    component_impacts: impacts,
    component_uptimes: uptimes,
    incident_links: incidentLinks,
  },
  isLoading,
  startAt,
  endAt,
  showStatusTooltip,
  autoExpandGroups = false,
  showChart,
}: {
  group: GroupStructureItem;
  currentComponentStatuses: Record<
    string,
    StatusPageAffectedComponentStatusEnum | undefined
  >;
  data: StatusPageContentListComponentImpactsResponseBody;
  isLoading: boolean;
  startAt: DateTime;
  endAt: DateTime;
  showStatusTooltip: boolean;
  autoExpandGroups?: boolean;
  showChart: boolean;
}): React.ReactElement => {
  const [showChildren, setShowChildren] = useState(autoExpandGroups);
  const componentIds = group.components.map((component) => component.id);
  const showingUptimeComponentIds = group.components
    .filter((comp) => comp.displayUptime)
    .map((comp) => comp.id);

  const groupStatus = _.maxBy(
    _.compact(group.components.map(({ id }) => currentComponentStatuses[id])),
    (status) => STATUS_SEVERITY[status],
  );

  const { parse } = useParseTime();
  const dataAvailableSince = getDataAvailableSince(parse)(uptimes, group.id);

  const t = useTranslations("SystemStatus");

  return (
    <ComponentStatus
      name={group.name}
      uptime={getUptime(uptimes, group.id)}
      currentStatus={groupStatus || "ok"}
      isLoading={isLoading}
      isOpen={!showChildren} // Collapse the summary when the children are visible
      isGroup
      dataAvailableSince={dataAvailableSince}
      componentIds={componentIds}
      showingUptimeComponentIds={showingUptimeComponentIds}
      startAt={startAt}
      endAt={endAt}
      impacts={impacts}
      incidentLinks={incidentLinks}
      showChart={showChart && group.displayUptime}
      showStatusIcon={!showChildren}
      showStatusTooltip={showStatusTooltip}
      onClick={() => setShowChildren(!showChildren)}
      description={group.description}
      accessory={
        <>
          <span
            className={cx(
              "flex items-center cursor-pointer group transition",
              "text-slate-500 hover:text-content-primary",
              "dark:hover:text-slate-200",
            )}
            onClick={() => setShowChildren(!showChildren)}
          >
            <span className={"hidden md:inline"}>
              {t("components_in_group", { count: componentIds.length })}
            </span>
            <span className="flex items-center justify-center w-3 h-6 mt-[2px] ml-1">
              <ChevronIcon
                className={cx(
                  "transition",
                  "text-slate-300 group-hover:text-content-primary",
                  "dark:text-slate-500 dark:group-hover:text-slate-300",
                )}
                flavour={showChildren ? "up" : "down"}
              />
            </span>
          </span>
        </>
      }
    >
      <AnimatePresence initial={false}>
        {showChildren && (
          <motion.div
            key={componentIds.join("-")}
            initial={{
              opacity: 0,
              height: 0,
              overflowY: "hidden",
            }}
            exit={{
              opacity: 0,
              height: 0,
              overflowY: "hidden",
            }}
            animate={{
              opacity: 1,
              height: "auto",
              overflowY: "hidden",
            }}
            transition={{
              type: "linear",
              duration: 0.4 * ANIMATION_MODIFIER,
            }}
            className="w-full"
          >
            <div className="flex flex-col space-y-2 pt-2">
              {group.components.map((component) => (
                <ComponentStatus
                  key={component.id}
                  name={component.name}
                  isOpen={true} // These are always 'open', with the animation being managed by the container
                  isGroup={false}
                  description={component.description}
                  currentStatus={currentComponentStatuses[component.id] || "ok"}
                  dataAvailableSince={getDataAvailableSince(parse)(
                    uptimes,
                    component.id,
                  )}
                  uptime={getUptime(uptimes, component.id)}
                  isLoading={isLoading}
                  componentIds={[component.id]}
                  startAt={startAt}
                  endAt={endAt}
                  impacts={impacts}
                  incidentLinks={incidentLinks}
                  showChart={showChart && component.displayUptime}
                  showStatusTooltip={showStatusTooltip}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ComponentStatus>
  );
};

const compareSets = <T,>(s1: Set<T>, s2: Set<T>): boolean =>
  s1.size === s2.size && Array.from(s1).every((e) => s2.has(e));

// Renders an uptime chart with a header.
const ComponentStatus = ({
  componentIds: rawComponentIds,
  showingUptimeComponentIds: rawShowingUptimeComponentIds = rawComponentIds,
  name,
  description,
  currentStatus,
  uptime,
  dataAvailableSince,
  isLoading,
  isOpen,
  isGroup,
  startAt: rawStartAt,
  endAt: rawEndAt,
  impacts,
  incidentLinks,
  accessory,
  showChart = true,
  showStatusIcon = true,
  showStatusTooltip = true,
  ongoingIncidents,
  children,
  onClick,
}: {
  componentIds: string[];
  showingUptimeComponentIds?: string[];
  name: string;
  description?: string;
  currentStatus: StatusPageAffectedComponentStatusEnum | "ok";
  uptime?: string;
  isLoading: boolean;
  isOpen: boolean;
  isGroup: boolean;
  startAt: DateTime;
  endAt: DateTime;
  dataAvailableSince: DateTime | null;
  impacts: StatusPageContentComponentImpact[];
  incidentLinks: Array<StatusPageContentIncidentLink>;
  accessory?: React.ReactElement;
  showChart?: boolean;
  showStatusIcon?: boolean;
  showStatusTooltip: boolean;
  ongoingIncidents?: InternalStatusPageIncident[] | null;
  children?: React.ReactNode;
  onClick?: () => void;
}): React.ReactElement => {
  // Do not re-calculate if the order changes
  const componentIds = useMemoCompare(new Set(rawComponentIds), compareSets);
  const showingUptimeComponentIds = useMemoCompare(
    new Set(rawShowingUptimeComponentIds),
    compareSets,
  );

  // Memoise the impacts, again, to avoid an expensive recalculation
  const relevantImpacts = useMemo(
    () =>
      impacts.filter((impact) =>
        showingUptimeComponentIds.has(impact.component_id),
      ),
    [impacts, showingUptimeComponentIds],
  );

  // Ensure that we don't re-calculate for non-meaningful changes
  const startAt = useTimeMaths(rawStartAt);
  const endAt = useTimeMaths(rawEndAt);
  const { parse } = useParseTime();
  const days = useMemo(
    () => calculateDays(startAt, endAt, relevantImpacts, parse),
    [startAt, endAt, parse, relevantImpacts],
  );

  // If data only becomes available after the end of this time period, don't
  // show the uptime percentage because it won't make any sense. We also hide it
  // when someone configures to show only the chart. We can tell if we should hide
  // it as uptime will be undefined.
  const showUptimePercentage = uptime !== undefined;

  const t = useTranslations("SystemStatus");

  const [expandedIncidentList, setExpandedIncidentList] = useState(false);

  const hasIncidents = ongoingIncidents && ongoingIncidents?.length > 0;

  const showStatusTooltipForGroup = showStatusIcon && isGroup;

  const showStatusTooltipForComponent =
    showStatusIcon &&
    !isGroup &&
    (ongoingIncidents == null || expandedIncidentList);

  const showStatusIconInline = isGroup
    ? showStatusTooltipForGroup
    : showStatusTooltipForComponent;

  const showParentStatusIconInline = isGroup
    ? showStatusTooltipForGroup
    : !expandedIncidentList;

  return (
    <div>
      <div
        className={"h-7 flex flex-grow items-center text-medium"}
        onClick={onClick || undefined}
      >
        <AnimatePresence initial={false}>
          {showParentStatusIconInline && (
            <motion.div
              key={Array.from(componentIds).join("+")}
              initial={{
                opacity: 0,
                width: 0,
                marginRight: 0,
              }}
              animate={{ opacity: 1, width: "auto", marginRight: 8 }}
              exit={{ opacity: 0, width: 0, marginRight: 0 }}
              transition={{
                type: "linear",
                duration: 0.4 * ANIMATION_MODIFIER,
              }}
            >
              <ComponentStatusIcon
                tooltip={showStatusTooltip}
                componentStatus={currentStatus}
                className={cx("h-4 -mr-0.5")}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* This is the medium viewport version, with more detail and interactive controls */}
        <div className="hidden md:flex space-x-2 flex-grow items-center text-md">
          <div className="flex space-x-1.5 items-center">
            <h3
              className={cx(
                "font-medium",
                "text-content-primary",
                "dark:text-slate-100",
              )}
            >
              {name}
            </h3>
            {description ? (
              <InfoBadge
                className="mt-[1px] hidden md:block"
                description={description}
              />
            ) : null}
            <div
              className="flex flex-row items-center cursor-pointer group transition"
              onClick={() => setExpandedIncidentList(!expandedIncidentList)}
            >
              {hasIncidents && ongoingIncidents && (
                <span className="text-content-tertiary group-hover:text-content-primary dark:group-hover:text-slate-200">
                  {`${ongoingIncidents.length} ${
                    ongoingIncidents.length === 1 ? "incident" : "incidents"
                  }`}
                </span>
              )}
              {hasIncidents && (
                <span className="flex items-center justify-center w-3 h-6 mt-[2px] ml-1">
                  <ChevronIcon
                    className={cx(
                      "transition",
                      "text-content-tertiary group-hover:text-content-primary dark:group-hover:text-slate-200",
                    )}
                    flavour={expandedIncidentList ? "up" : "down"}
                  />
                </span>
              )}
            </div>
          </div>
          {accessory || null}

          <div className={"flex-grow"}></div>

          {showChart && showUptimePercentage ? (
            <div
              className={cx(
                "ml-2 font-normal flex flex-row items-center gap-1",
                "text-content-tertiary",
              )}
            >
              {isLoading ? (
                <Spinner className="inline-block w-[30px]" />
              ) : (
                isOpen && (
                  <motion.div
                    key={Array.from(componentIds).join("+")}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{ opacity: 0 }}
                    transition={{
                      type: "linear",
                      duration: 0.4 * ANIMATION_MODIFIER,
                    }}
                  >
                    <span
                      className="whitespace-nowrap"
                      dangerouslySetInnerHTML={{
                        __html: t.markup("uptime_percent", {
                          uptime,
                          notranslate: (chunks) =>
                            `<var percentage>${chunks}</var>`,
                        }),
                      }}
                    ></span>
                  </motion.div>
                )
              )}
            </div>
          ) : null}
        </div>

        {/* This is the small viewport for mobile, with many details removed */}
        <div className="flex md:hidden items-center text-md min-w-[0px]">
          <h3 className={"font-medium"}>{name}</h3>
          {accessory || null}
        </div>
      </div>

      {children}

      <AnimatePresence initial={false}>
        {showStatusIconInline && expandedIncidentList && ongoingIncidents && (
          <motion.div
            initial={{
              opacity: 0,
              height: 0,
              overflowY: "hidden",
            }}
            exit={{
              opacity: 0,
              height: 0,
              overflowY: "hidden",
            }}
            animate={{
              opacity: 1,
              height: "auto",
              overflowY: "hidden",
            }}
            transition={{
              type: "linear",
              duration: 0.4 * ANIMATION_MODIFIER,
            }}
            className="w-full"
          >
            <div className="flex flex-col space-y-2 pt-2">
              <OngoingIncidentsOnComponent
                ongoingIncidents={ongoingIncidents}
                expandedIncidentList={expandedIncidentList}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="hidden md:flex">
        {showChart ? (
          <motion.div
            initial={false}
            variants={{
              closed: {
                height: 0,
                opacity: 0,
                marginTop: 0,
                overflow: "hidden",
                transitionEnd: { visibility: "hidden" },
              },
              open: {
                height: "auto",
                opacity: 1,
                marginTop: 4,
                visibility: "visible",
                overflow: "hidden",
                transitionEnd: { overflow: "visible" },
                transition: {
                  duration: 0.2 * ANIMATION_MODIFIER,
                  delay: 0.1 * ANIMATION_MODIFIER,
                },
              },
            }}
            animate={isOpen ? "open" : "closed"}
            transition={{
              type: "linear",
              duration: 0.2 * ANIMATION_MODIFIER,
            }}
            className="w-full"
          >
            {isLoading ? (
              <LoadingBar className="h-[15px] mb-1" />
            ) : (
              <UptimeChart
                {...UptimeChartDefaults}
                dataAvailableSince={dataAvailableSince}
                days={days}
                incidentLinks={incidentLinks}
              />
            )}
          </motion.div>
        ) : null}
      </div>
    </div>
  );
};

const OngoingIncidentsOnComponent = ({
  ongoingIncidents,
  expandedIncidentList,
}: {
  ongoingIncidents: InternalStatusPageIncident[] | null;
  expandedIncidentList?: boolean;
}): React.ReactElement | null => {
  if (expandedIncidentList) {
    return (
      <div className="flex flex-col items-center gap-1">
        {ongoingIncidents &&
          ongoingIncidents.map((ongoingIncident) => (
            <OngoingIncidentOnComponent
              key={ongoingIncident.id}
              incident={ongoingIncident}
            />
          ))}
      </div>
    );
  } else {
    return null;
  }
};

const OngoingIncidentOnComponent = ({
  incident,
}: {
  incident: InternalStatusPageIncident;
}): React.ReactElement => {
  const hasLeadImage = incident.lead_assignment?.assignee != null;
  const { IncidentLink } = useUIContext();

  return (
    <div className="flex justify-between flex-row items-center bg-surface-secondary rounded-lg px-3 py-2 w-full dark:bg-default">
      <IncidentLink
        incident={{
          incident_id: incident.external_id.toString(),
          name: incident.name,
        }}
        className=""
        analyticsTrackingId="internal-status-page--ongoing-incident-clicked"
      >
        <div className="flex items-center">
          <ComponentStatusIcon
            componentStatus={
              StatusPageContentComponentImpactStatusEnum.FullOutage
            }
            className={cx("h-7 py-[6.5px] pl-0.5 pr-1.5 flex-none")}
          />
          <h3 className="font-medium">{incident.name}</h3>
        </div>
      </IncidentLink>
    </div>
  );
};
