/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffExtendTrialRequestBody
 */
export interface StaffExtendTrialRequestBody {
    /**
     * When the trial should end.
     * @type {Date}
     * @memberof StaffExtendTrialRequestBody
     */
    trial_end_at: Date;
}

export function StaffExtendTrialRequestBodyFromJSON(json: any): StaffExtendTrialRequestBody {
    return StaffExtendTrialRequestBodyFromJSONTyped(json, false);
}

export function StaffExtendTrialRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffExtendTrialRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trial_end_at': (new Date(json['trial_end_at'])),
    };
}

export function StaffExtendTrialRequestBodyToJSON(value?: StaffExtendTrialRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trial_end_at': (value.trial_end_at.toISOString()),
    };
}

