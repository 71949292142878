import { assertUnreachable } from "src/utils/utils";

export enum Environment {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

export function getEnvironment(): Environment {
  if (window.location.origin.indexOf("app.incident.io") > -1) {
    return Environment.Production;
  }
  if (window.location.origin.indexOf("app.staging.incident.io") > -1) {
    return Environment.Staging;
  }
  return Environment.Development;
}

export const isDevelopment = () => getEnvironment() === Environment.Development;

export function publicApiUrl(): string {
  const env = getEnvironment();
  switch (env) {
    case Environment.Development:
      return `${window.location.origin}/api/public`;
    case Environment.Staging:
      return "https://app.staging.incident.io/api/public";
    case Environment.Production:
      return "https://api.incident.io";
    default:
      assertUnreachable(env);
      return "";
  }
}
