/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserPreferencesShowResponseBody,
    UserPreferencesShowResponseBodyFromJSON,
    UserPreferencesShowResponseBodyToJSON,
    UserPreferencesUpdateAppNotificationPreferencesRequestBody,
    UserPreferencesUpdateAppNotificationPreferencesRequestBodyFromJSON,
    UserPreferencesUpdateAppNotificationPreferencesRequestBodyToJSON,
    UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody,
    UserPreferencesUpdateNotificationDeliveryPreferencesRequestBodyFromJSON,
    UserPreferencesUpdateNotificationDeliveryPreferencesRequestBodyToJSON,
    UserPreferencesUpdatePhoneNumberRequestBody,
    UserPreferencesUpdatePhoneNumberRequestBodyFromJSON,
    UserPreferencesUpdatePhoneNumberRequestBodyToJSON,
    UserPreferencesUpdateScheduledEmailPreferencesRequestBody,
    UserPreferencesUpdateScheduledEmailPreferencesRequestBodyFromJSON,
    UserPreferencesUpdateScheduledEmailPreferencesRequestBodyToJSON,
    UserPreferencesUpdateSubscriptionPreferencesRequestBody,
    UserPreferencesUpdateSubscriptionPreferencesRequestBodyFromJSON,
    UserPreferencesUpdateSubscriptionPreferencesRequestBodyToJSON,
    UserPreferencesVerifyPhoneNumberRequestBody,
    UserPreferencesVerifyPhoneNumberRequestBodyFromJSON,
    UserPreferencesVerifyPhoneNumberRequestBodyToJSON,
} from '../models';

export interface UserPreferencesUpdateAppNotificationPreferencesRequest {
    updateAppNotificationPreferencesRequestBody: UserPreferencesUpdateAppNotificationPreferencesRequestBody;
}

export interface UserPreferencesUpdateNotificationDeliveryPreferencesRequest {
    updateNotificationDeliveryPreferencesRequestBody: UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody;
}

export interface UserPreferencesUpdatePhoneNumberRequest {
    updatePhoneNumberRequestBody: UserPreferencesUpdatePhoneNumberRequestBody;
}

export interface UserPreferencesUpdateScheduledEmailPreferencesRequest {
    updateScheduledEmailPreferencesRequestBody: UserPreferencesUpdateScheduledEmailPreferencesRequestBody;
}

export interface UserPreferencesUpdateSubscriptionPreferencesRequest {
    updateSubscriptionPreferencesRequestBody: UserPreferencesUpdateSubscriptionPreferencesRequestBody;
}

export interface UserPreferencesVerifyPhoneNumberRequest {
    verifyPhoneNumberRequestBody: UserPreferencesVerifyPhoneNumberRequestBody;
}

/**
 * 
 */
export class UserPreferencesApi extends runtime.BaseAPI {

    /**
     * Get preferences for the authenticated user
     * Show User Preferences
     */
    async userPreferencesShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPreferencesShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_preferences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferencesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get preferences for the authenticated user
     * Show User Preferences
     */
    async userPreferencesShow(initOverrides?: RequestInit): Promise<UserPreferencesShowResponseBody> {
        const response = await this.userPreferencesShowRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update app notification preferences for things that persist across devices, e.g. pager sounds
     * UpdateAppNotificationPreferences User Preferences
     */
    async userPreferencesUpdateAppNotificationPreferencesRaw(requestParameters: UserPreferencesUpdateAppNotificationPreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateAppNotificationPreferencesRequestBody === null || requestParameters.updateAppNotificationPreferencesRequestBody === undefined) {
            throw new runtime.RequiredError('updateAppNotificationPreferencesRequestBody','Required parameter requestParameters.updateAppNotificationPreferencesRequestBody was null or undefined when calling userPreferencesUpdateAppNotificationPreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_preferences/update-app-notification-preferences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferencesUpdateAppNotificationPreferencesRequestBodyToJSON(requestParameters.updateAppNotificationPreferencesRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update app notification preferences for things that persist across devices, e.g. pager sounds
     * UpdateAppNotificationPreferences User Preferences
     */
    async userPreferencesUpdateAppNotificationPreferences(requestParameters: UserPreferencesUpdateAppNotificationPreferencesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userPreferencesUpdateAppNotificationPreferencesRaw(requestParameters, initOverrides);
    }

    /**
     * Update notification delivery preferences
     * UpdateNotificationDeliveryPreferences User Preferences
     */
    async userPreferencesUpdateNotificationDeliveryPreferencesRaw(requestParameters: UserPreferencesUpdateNotificationDeliveryPreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateNotificationDeliveryPreferencesRequestBody === null || requestParameters.updateNotificationDeliveryPreferencesRequestBody === undefined) {
            throw new runtime.RequiredError('updateNotificationDeliveryPreferencesRequestBody','Required parameter requestParameters.updateNotificationDeliveryPreferencesRequestBody was null or undefined when calling userPreferencesUpdateNotificationDeliveryPreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_preferences/update-notification-preferences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferencesUpdateNotificationDeliveryPreferencesRequestBodyToJSON(requestParameters.updateNotificationDeliveryPreferencesRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update notification delivery preferences
     * UpdateNotificationDeliveryPreferences User Preferences
     */
    async userPreferencesUpdateNotificationDeliveryPreferences(requestParameters: UserPreferencesUpdateNotificationDeliveryPreferencesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userPreferencesUpdateNotificationDeliveryPreferencesRaw(requestParameters, initOverrides);
    }

    /**
     * Update user phone number
     * UpdatePhoneNumber User Preferences
     */
    async userPreferencesUpdatePhoneNumberRaw(requestParameters: UserPreferencesUpdatePhoneNumberRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updatePhoneNumberRequestBody === null || requestParameters.updatePhoneNumberRequestBody === undefined) {
            throw new runtime.RequiredError('updatePhoneNumberRequestBody','Required parameter requestParameters.updatePhoneNumberRequestBody was null or undefined when calling userPreferencesUpdatePhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_preferences/update-phone-number`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferencesUpdatePhoneNumberRequestBodyToJSON(requestParameters.updatePhoneNumberRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update user phone number
     * UpdatePhoneNumber User Preferences
     */
    async userPreferencesUpdatePhoneNumber(requestParameters: UserPreferencesUpdatePhoneNumberRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userPreferencesUpdatePhoneNumberRaw(requestParameters, initOverrides);
    }

    /**
     * Update preferences for scheduled emails, e.g. the monthly insights email
     * UpdateScheduledEmailPreferences User Preferences
     */
    async userPreferencesUpdateScheduledEmailPreferencesRaw(requestParameters: UserPreferencesUpdateScheduledEmailPreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateScheduledEmailPreferencesRequestBody === null || requestParameters.updateScheduledEmailPreferencesRequestBody === undefined) {
            throw new runtime.RequiredError('updateScheduledEmailPreferencesRequestBody','Required parameter requestParameters.updateScheduledEmailPreferencesRequestBody was null or undefined when calling userPreferencesUpdateScheduledEmailPreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_preferences/update-scheduled-email-preferences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferencesUpdateScheduledEmailPreferencesRequestBodyToJSON(requestParameters.updateScheduledEmailPreferencesRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update preferences for scheduled emails, e.g. the monthly insights email
     * UpdateScheduledEmailPreferences User Preferences
     */
    async userPreferencesUpdateScheduledEmailPreferences(requestParameters: UserPreferencesUpdateScheduledEmailPreferencesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userPreferencesUpdateScheduledEmailPreferencesRaw(requestParameters, initOverrides);
    }

    /**
     * Update subscription preferences
     * UpdateSubscriptionPreferences User Preferences
     */
    async userPreferencesUpdateSubscriptionPreferencesRaw(requestParameters: UserPreferencesUpdateSubscriptionPreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateSubscriptionPreferencesRequestBody === null || requestParameters.updateSubscriptionPreferencesRequestBody === undefined) {
            throw new runtime.RequiredError('updateSubscriptionPreferencesRequestBody','Required parameter requestParameters.updateSubscriptionPreferencesRequestBody was null or undefined when calling userPreferencesUpdateSubscriptionPreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_preferences/update-subscription-preferences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferencesUpdateSubscriptionPreferencesRequestBodyToJSON(requestParameters.updateSubscriptionPreferencesRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update subscription preferences
     * UpdateSubscriptionPreferences User Preferences
     */
    async userPreferencesUpdateSubscriptionPreferences(requestParameters: UserPreferencesUpdateSubscriptionPreferencesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userPreferencesUpdateSubscriptionPreferencesRaw(requestParameters, initOverrides);
    }

    /**
     * Verify phone number
     * VerifyPhoneNumber User Preferences
     */
    async userPreferencesVerifyPhoneNumberRaw(requestParameters: UserPreferencesVerifyPhoneNumberRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.verifyPhoneNumberRequestBody === null || requestParameters.verifyPhoneNumberRequestBody === undefined) {
            throw new runtime.RequiredError('verifyPhoneNumberRequestBody','Required parameter requestParameters.verifyPhoneNumberRequestBody was null or undefined when calling userPreferencesVerifyPhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_preferences/verify-phone-number`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferencesVerifyPhoneNumberRequestBodyToJSON(requestParameters.verifyPhoneNumberRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify phone number
     * VerifyPhoneNumber User Preferences
     */
    async userPreferencesVerifyPhoneNumber(requestParameters: UserPreferencesVerifyPhoneNumberRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userPreferencesVerifyPhoneNumberRaw(requestParameters, initOverrides);
    }

}
