import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  CatalogType,
  ExpressionOperationPayload,
  IncidentFormEscalationElement,
  IncidentFormsCreateEscalationElementRequestBody,
} from "@incident-io/api";

import { CreateEditElementForm } from "./IncidentEscalateFormElementCreateEditModal";

export const marshallFormData = (
  formData: CreateEditElementForm,
  elementType,
  formId,
): IncidentFormsCreateEscalationElementRequestBody => {
  const req = {
    catalog_type_id: formData.catalogTypeId,
    description: formData.description,
    element_type: elementType,
    incident_form_id: formId,
  } as IncidentFormsCreateEscalationElementRequestBody;
  if (formData.defaultValue) {
    req.default_value = {
      value: {
        literal: formData.defaultValue,
      },
    };
  }
  if (
    elementType ===
    AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType
  ) {
    const navigateExpressionData = formData?.navigation_expression ?? formData;
    req.navigation_expression = {
      reference: `escalationElementExpression-${formId}-${formData.catalogTypeId}`,
      root_reference: "subject",
      operations:
        navigateExpressionData.operations as unknown as ExpressionOperationPayload[],
      label: `escalationElementExpression-${formId}-${formData.catalogTypeId}`,
      else_branch: navigateExpressionData.else_branch,
    };

    if (formData.pagerduty_service_navigation_expression) {
      req.pagerduty_service_navigation_expression = {
        reference: `escalationElementExpression-${formId}-${formData.catalogTypeId}-pagerduty`,
        root_reference: "subject",
        operations: formData.pagerduty_service_navigation_expression
          .operations as unknown as ExpressionOperationPayload[],
        label: `escalationElementExpression-${formId}-${formData.catalogTypeId}-pagerduty`,
        else_branch:
          formData.pagerduty_service_navigation_expression.else_branch,
      };
    }
    if (formData.opsgenie_team_navigation_expression) {
      req.opsgenie_team_navigation_expression = {
        reference: `escalationElementExpression-${formId}-${formData.catalogTypeId}-opsgenie`,
        root_reference: "subject",
        operations: formData.opsgenie_team_navigation_expression
          .operations as unknown as ExpressionOperationPayload[],
        label: `escalationElementExpression-${formId}-${formData.catalogTypeId}-opsgenie`,
        else_branch: formData.opsgenie_team_navigation_expression.else_branch,
      };
    }
  }
  return req;
};

export const formElementToDefaults = (
  initialData: IncidentFormEscalationElement,
  catalogType?: CatalogType,
): CreateEditElementForm => {
  let defaults = {
    description: initialData?.description,
    defaultValue:
      initialData.default_value?.array_value &&
      initialData.default_value?.array_value.length > 0
        ? initialData.default_value?.array_value[0].literal
        : initialData.default_value?.value?.literal ?? "",
  } as CreateEditElementForm;

  if (
    initialData?.available_element.element_type ===
    AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType
  ) {
    defaults = {
      ...defaults,
      ...initialData.navigation_expression,
      root_reference: "subject",
      catalogTypeId: catalogType?.id,
      navigation_expression: initialData.navigation_expression,
      opsgenie_team_navigation_expression:
        initialData.opsgenie_team_navigation_expression,
      pagerduty_service_navigation_expression:
        initialData.pagerduty_service_navigation_expression,
    };
  }
  return defaults;
};
