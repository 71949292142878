/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SAMLDomain
 */
export interface SAMLDomain {
    /**
     * 
     * @type {string}
     * @memberof SAMLDomain
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof SAMLDomain
     */
    id: string;
}

export function SAMLDomainFromJSON(json: any): SAMLDomain {
    return SAMLDomainFromJSONTyped(json, false);
}

export function SAMLDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLDomain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
        'id': json['id'],
    };
}

export function SAMLDomainToJSON(value?: SAMLDomain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'id': value.id,
    };
}

