/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageAvailableMigration
 */
export interface StatusPageAvailableMigration {
    /**
     * The ID of the Atlassian Statuspage page that can be imported
     * @type {string}
     * @memberof StatusPageAvailableMigration
     */
    atlassian_page_id: string;
    /**
     * The name of the page that can be imported
     * @type {string}
     * @memberof StatusPageAvailableMigration
     */
    name: string;
    /**
     * The URL that this page is currently hosted at
     * @type {string}
     * @memberof StatusPageAvailableMigration
     */
    public_url: string;
}

export function StatusPageAvailableMigrationFromJSON(json: any): StatusPageAvailableMigration {
    return StatusPageAvailableMigrationFromJSONTyped(json, false);
}

export function StatusPageAvailableMigrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageAvailableMigration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atlassian_page_id': json['atlassian_page_id'],
        'name': json['name'],
        'public_url': json['public_url'],
    };
}

export function StatusPageAvailableMigrationToJSON(value?: StatusPageAvailableMigration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atlassian_page_id': value.atlassian_page_id,
        'name': value.name,
        'public_url': value.public_url,
    };
}

