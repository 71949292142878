import { BadgeSize, Button, ButtonTheme } from "@incident-ui";
import { ProductMarketingBanner } from "@incident-ui/ProductMarketingBanner/ProductMarketingBanner";
import { useIntercom } from "react-use-intercom";
import { tcx } from "src/utils/tailwind-classes";

import { PlanBadge } from "./PlanBadge";

export type SettingsHeadingProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  learnMoreURL?: string;
  articleId?: number;
  planNameMustUpgradeTo?: string;
  requiresUpgrade?: boolean;
  graphic: React.ReactNode;
};

export const SettingsHeading = ({
  title,
  subtitle,
  className,
  learnMoreURL,
  articleId,
  planNameMustUpgradeTo,
  requiresUpgrade,
  graphic,
}: SettingsHeadingProps): React.ReactElement => {
  return (
    <ProductMarketingBanner
      graphic={graphic}
      title={title}
      subtitle={subtitle}
      badge={
        requiresUpgrade && planNameMustUpgradeTo ? (
          <PlanBadge size={BadgeSize.Small} planName={planNameMustUpgradeTo} />
        ) : undefined
      }
      className={tcx("bg-alarmalade-50 pr-0 h-40", className)}
      cta={
        <SettingsHeadingButtons
          planNameMustUpgradeTo={
            requiresUpgrade ? planNameMustUpgradeTo : undefined
          }
          learnMoreURL={learnMoreURL}
          articleId={articleId}
        />
      }
      ctaPosition="bottom"
    />
  );
};

type SettingsHeadingButtonProps = {
  planNameMustUpgradeTo?: string;
  learnMoreURL?: string;
  articleId?: number;
};

const SettingsHeadingButtons = ({
  planNameMustUpgradeTo,
  learnMoreURL,
  articleId,
}: SettingsHeadingButtonProps): React.ReactElement => {
  const { showArticle } = useIntercom();

  return (
    <>
      {planNameMustUpgradeTo ? (
        <Button
          href={"/settings/billing"}
          theme={ButtonTheme.Primary}
          analyticsTrackingId={`settings-heading-upgrade`}
        >
          Upgrade
        </Button>
      ) : undefined}
      {learnMoreURL ? (
        <Button
          analyticsTrackingId="settings-learn-more"
          theme={ButtonTheme.Secondary}
          href={learnMoreURL}
          openInNewTab={true}
        >
          Learn more
        </Button>
      ) : articleId ? (
        <Button
          onClick={() => showArticle(articleId)}
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={"settings-learn-more"}
        >
          Learn more
        </Button>
      ) : null}
    </>
  );
};
