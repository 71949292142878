/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BranchesOnlyExpressionPayload,
    BranchesOnlyExpressionPayloadFromJSON,
    BranchesOnlyExpressionPayloadFromJSONTyped,
    BranchesOnlyExpressionPayloadToJSON,
} from './BranchesOnlyExpressionPayload';

/**
 * 
 * @export
 * @interface IncidentLifecyclesUpdatePostIncidentFlowRequestBody
 */
export interface IncidentLifecyclesUpdatePostIncidentFlowRequestBody {
    /**
     * 
     * @type {BranchesOnlyExpressionPayload}
     * @memberof IncidentLifecyclesUpdatePostIncidentFlowRequestBody
     */
    automatic_post_incident_flow_expression?: BranchesOnlyExpressionPayload;
    /**
     * Is the post-incident flow enabled for this lifecycle?
     * @type {boolean}
     * @memberof IncidentLifecyclesUpdatePostIncidentFlowRequestBody
     */
    post_incident_flow_enabled: boolean;
}

export function IncidentLifecyclesUpdatePostIncidentFlowRequestBodyFromJSON(json: any): IncidentLifecyclesUpdatePostIncidentFlowRequestBody {
    return IncidentLifecyclesUpdatePostIncidentFlowRequestBodyFromJSONTyped(json, false);
}

export function IncidentLifecyclesUpdatePostIncidentFlowRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecyclesUpdatePostIncidentFlowRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'automatic_post_incident_flow_expression': !exists(json, 'automatic_post_incident_flow_expression') ? undefined : BranchesOnlyExpressionPayloadFromJSON(json['automatic_post_incident_flow_expression']),
        'post_incident_flow_enabled': json['post_incident_flow_enabled'],
    };
}

export function IncidentLifecyclesUpdatePostIncidentFlowRequestBodyToJSON(value?: IncidentLifecyclesUpdatePostIncidentFlowRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automatic_post_incident_flow_expression': BranchesOnlyExpressionPayloadToJSON(value.automatic_post_incident_flow_expression),
        'post_incident_flow_enabled': value.post_incident_flow_enabled,
    };
}

