/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageComponent
 */
export interface StatusPageComponent {
    /**
     * A short description of this component
     * @type {string}
     * @memberof StatusPageComponent
     */
    description?: string;
    /**
     * A unique ID for this component
     * @type {string}
     * @memberof StatusPageComponent
     */
    id: string;
    /**
     * A human readable name for the component
     * @type {string}
     * @memberof StatusPageComponent
     */
    name: string;
    /**
     * The ID of the status page this component belongs to
     * @type {string}
     * @memberof StatusPageComponent
     */
    status_page_id: string;
}

export function StatusPageComponentFromJSON(json: any): StatusPageComponent {
    return StatusPageComponentFromJSONTyped(json, false);
}

export function StatusPageComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'name': json['name'],
        'status_page_id': json['status_page_id'],
    };
}

export function StatusPageComponentToJSON(value?: StatusPageComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'status_page_id': value.status_page_id,
    };
}

