import type { SVGProps } from "react";
import * as React from "react";
const SvgHoneycomb = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#FFB000"
      d="m10.236 12.32 1.39 2.398-1.39 2.43H7.491l-1.389-2.43 1.389-2.399h2.745Z"
    />
    <path
      fill="#64BA00"
      d="m10.236 6.481 1.39 2.399-1.39 2.43H7.491L6.102 8.91l1.389-2.398h2.745V6.48Z"
    />
    <path
      fill="#F96E10"
      d="m5.377 9.826 1.136 1.989-1.136 1.988H3.136L2 11.815l1.136-1.989h2.24Z"
    />
    <path
      fill="#0298EC"
      d="M16.201 2 18 5.156 16.2 8.312h-3.598l-1.767-3.156L12.635 2h3.566Z"
    />
  </svg>
);
export default SvgHoneycomb;
