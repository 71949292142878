/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BootstrapTeamManualEntry
 */
export interface BootstrapTeamManualEntry {
    /**
     * An identifier per User to be added to the new team. Any of ID, External ID or Alias.
     * @type {Array<string>}
     * @memberof BootstrapTeamManualEntry
     */
    members?: Array<string>;
    /**
     * The name of the new team
     * @type {string}
     * @memberof BootstrapTeamManualEntry
     */
    name: string;
}

export function BootstrapTeamManualEntryFromJSON(json: any): BootstrapTeamManualEntry {
    return BootstrapTeamManualEntryFromJSONTyped(json, false);
}

export function BootstrapTeamManualEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BootstrapTeamManualEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'members': !exists(json, 'members') ? undefined : json['members'],
        'name': json['name'],
    };
}

export function BootstrapTeamManualEntryToJSON(value?: BootstrapTeamManualEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'members': value.members,
        'name': value.name,
    };
}

