import { CustomDashboard, InsightsReportSchedule } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";

import { InsightsScheduledReportCreateEditForm } from "./InsightsScheduledReportCreateEditForm";

export const InsightsScheduledReportDrawer = ({
  dashboard,
  existingSchedule,
  onClose,
  loading,
}: {
  dashboard: CustomDashboard;
  existingSchedule?: InsightsReportSchedule;
  onClose: () => void;
  loading?: boolean;
}) => {
  return (
    <Drawer onClose={onClose} width="medium" warnWhenDirty>
      {loading ? (
        <DrawerContentsLoading />
      ) : (
        <DrawerContents>
          <DrawerTitle
            icon={IconEnum.EnvelopeClock}
            title={
              existingSchedule ? "Update scheduled report" : "Schedule a report"
            }
            onClose={onClose}
            color={ColorPaletteEnum.Blue}
            hexColor="#F7F7F8"
            compact
          />
          <InsightsScheduledReportCreateEditForm
            dashboardID={dashboard.id}
            dashboardName={dashboard.name}
            existingSchedule={existingSchedule}
            onClose={onClose}
          />
        </DrawerContents>
      )}
    </Drawer>
  );
};
