/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraCloudConfig,
    JiraCloudConfigFromJSON,
    JiraCloudConfigFromJSONTyped,
    JiraCloudConfigToJSON,
} from './JiraCloudConfig';

/**
 * 
 * @export
 * @interface IntegrationsJiraCloudGetConfigResponseBody
 */
export interface IntegrationsJiraCloudGetConfigResponseBody {
    /**
     * 
     * @type {JiraCloudConfig}
     * @memberof IntegrationsJiraCloudGetConfigResponseBody
     */
    config: JiraCloudConfig;
}

export function IntegrationsJiraCloudGetConfigResponseBodyFromJSON(json: any): IntegrationsJiraCloudGetConfigResponseBody {
    return IntegrationsJiraCloudGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsJiraCloudGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsJiraCloudGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': JiraCloudConfigFromJSON(json['config']),
    };
}

export function IntegrationsJiraCloudGetConfigResponseBodyToJSON(value?: IntegrationsJiraCloudGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': JiraCloudConfigToJSON(value.config),
    };
}

