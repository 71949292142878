/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Nudge,
    NudgeFromJSON,
    NudgeFromJSONTyped,
    NudgeToJSON,
} from './Nudge';

/**
 * 
 * @export
 * @interface NudgesListNudgesResponseBody
 */
export interface NudgesListNudgesResponseBody {
    /**
     * 
     * @type {Array<Nudge>}
     * @memberof NudgesListNudgesResponseBody
     */
    nudges: Array<Nudge>;
}

export function NudgesListNudgesResponseBodyFromJSON(json: any): NudgesListNudgesResponseBody {
    return NudgesListNudgesResponseBodyFromJSONTyped(json, false);
}

export function NudgesListNudgesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgesListNudgesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nudges': ((json['nudges'] as Array<any>).map(NudgeFromJSON)),
    };
}

export function NudgesListNudgesResponseBodyToJSON(value?: NudgesListNudgesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nudges': ((value.nudges as Array<any>).map(NudgeToJSON)),
    };
}

