import {
  OnCallNotificationRule,
  OnCallNotificationRuleRuleTypeEnum,
} from "@incident-io/api";
import { StaticSingleSelectWithObjV2 } from "@incident-shared/forms/v2/inputs/StaticSelectWithObjV2";
import { ModalFooter } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";

import { useAPI, useAPIMutation } from "../../../utils/swr";
import {
  escalationNotificationText,
  shiftChangeNotificationText,
} from "./helpers";
import {
  OnCallNotificationConfigurationInvalidCallout,
  useShortestEscalationPathForUser,
} from "./OnCallNotificationConfigurationInvalidCallout";

export const getNotificationIntervals = (
  type?: OnCallNotificationRuleRuleTypeEnum,
) =>
  type === OnCallNotificationRuleRuleTypeEnum.ShiftChanges
    ? [0, 1800, 3600, 10800, 21600, 43200, 86400]
    : [0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600];

export type EditRuleData = {
  delay_seconds: SelectOption;
  notice_period_seconds?: SelectOption;
  method: SelectOption;
};
export const EditRuleModal = ({
  onClose,
  currentSelectedRule,
}: {
  onClose: () => void;
  currentSelectedRule: OnCallNotificationRule;
}) => {
  const { identity } = useIdentity();
  const formMethods = useForm<EditRuleData>({
    defaultValues: {
      delay_seconds: {
        value: String(currentSelectedRule.delay_seconds),
        label: escalationNotificationText(currentSelectedRule.delay_seconds),
      },
      notice_period_seconds: currentSelectedRule?.notice_period_seconds
        ? {
            value: String(currentSelectedRule?.notice_period_seconds),
            label: shiftChangeNotificationText(
              currentSelectedRule?.notice_period_seconds,
            ),
          }
        : undefined,
    },
  });

  const {
    trigger: onCreate,
    isMutating,
    genericError,
  } = useAPIMutation(
    "onCallNotificationsListRules",
    undefined,
    async (apiClient, formData: EditRuleData) => {
      if (!currentSelectedRule) {
        return;
      }
      await apiClient.onCallNotificationsUpdateRule({
        id: currentSelectedRule.id,
        updateRuleRequestBody: {
          delay_seconds: parseInt(formData.delay_seconds.value),
          notice_period_seconds: formData.notice_period_seconds
            ? parseInt(formData.notice_period_seconds.value)
            : undefined,
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  const { data: currentRules, isLoading: currentRulesLoading } = useAPI(
    "onCallNotificationsListRules",
    undefined,
  );

  const addRuleOptions = getNotificationIntervals(
    currentSelectedRule?.rule_type,
  ).map((seconds) => ({
    value: String(seconds),
    label:
      currentSelectedRule?.rule_type ===
      OnCallNotificationRuleRuleTypeEnum.ShiftChanges
        ? shiftChangeNotificationText(seconds)
        : escalationNotificationText(seconds),
  }));

  const selectedDelaySeconds = formMethods.watch("delay_seconds");

  const currentHighUrgencyRuleLowestNotificationTime = _.chain(
    currentRules?.rules ?? [],
  )
    .filter(
      (r) => r.rule_type === OnCallNotificationRuleRuleTypeEnum.HighUrgency,
    )
    .map((r) => ({
      ...r,
      delay_seconds: currentSelectedRule?.id
        ? parseInt(selectedDelaySeconds.value)
        : r.delay_seconds,
    }))
    .map((r) => r.delay_seconds)
    .min()
    .value();

  // Find the escalation path that this user is on with the shortest time to
  // respond.
  const shortestEscalationPath = useShortestEscalationPathForUser(
    identity?.user_id ?? "",
  );

  const shouldShowInvalidConfigBanner =
    !currentRulesLoading &&
    shortestEscalationPath &&
    selectedDelaySeconds &&
    currentHighUrgencyRuleLowestNotificationTime >
      shortestEscalationPath.seconds &&
    parseInt(selectedDelaySeconds.value) > shortestEscalationPath.seconds;

  return (
    <Form.Modal<EditRuleData>
      formMethods={formMethods}
      onSubmit={onCreate}
      title={"Edit rule"}
      analyticsTrackingId={"edit-rule-modal"}
      onClose={onClose}
      saving={isMutating}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonType={"submit"}
          saving={isMutating}
          onClose={onClose}
          confirmButtonText={"Save"}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <div className={"flex flex-row space-x-2"}>
          <StaticSingleSelectWithObjV2
            required
            className={"flex-[1_1_0%]"}
            options={addRuleOptions}
            name={
              OnCallNotificationRuleRuleTypeEnum.ShiftChanges ===
              currentSelectedRule?.rule_type
                ? "notice_period_seconds"
                : "delay_seconds"
            }
            label={"Notify me"}
            formMethods={formMethods}
          />
        </div>
        {shouldShowInvalidConfigBanner && (
          <OnCallNotificationConfigurationInvalidCallout
            shortestEscalationPath={shortestEscalationPath}
            mode="modal"
            currentLowestNotificationTime={
              currentHighUrgencyRuleLowestNotificationTime <
              parseInt(selectedDelaySeconds.value)
                ? currentHighUrgencyRuleLowestNotificationTime
                : parseInt(selectedDelaySeconds.value)
            }
          />
        )}
      </div>
    </Form.Modal>
  );
};
