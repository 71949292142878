/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomDashboard,
    CustomDashboardFromJSON,
    CustomDashboardFromJSONTyped,
    CustomDashboardToJSON,
} from './CustomDashboard';

/**
 * 
 * @export
 * @interface InsightsShowCustomDashboardResponseBody
 */
export interface InsightsShowCustomDashboardResponseBody {
    /**
     * 
     * @type {CustomDashboard}
     * @memberof InsightsShowCustomDashboardResponseBody
     */
    dashboard: CustomDashboard;
}

export function InsightsShowCustomDashboardResponseBodyFromJSON(json: any): InsightsShowCustomDashboardResponseBody {
    return InsightsShowCustomDashboardResponseBodyFromJSONTyped(json, false);
}

export function InsightsShowCustomDashboardResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsShowCustomDashboardResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard': CustomDashboardFromJSON(json['dashboard']),
    };
}

export function InsightsShowCustomDashboardResponseBodyToJSON(value?: InsightsShowCustomDashboardResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard': CustomDashboardToJSON(value.dashboard),
    };
}

