import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Heading,
  IconEnum,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { IntegrationsSetIntegrationAPITokenRequestBodyProviderEnum as ProviderEnum } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import apiKeysNav from "./01-api-keys-nav.jpg";
import createKeyButton from "./02-create-key-button.jpg";
import createKeyModal from "./03-create-key-modal.jpg";
import newKeyModal from "./04-new-key-modal.jpg";

type TokenFormData = { api_token: string };

export const OnCallConnectPagerDuty = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<TokenFormData>();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, { api_token }: TokenFormData) =>
      await apiClient.integrationsSetIntegrationApiToken({
        setIntegrationApiTokenRequestBody: {
          provider: ProviderEnum.Pagerduty,
          api_token,
        },
      }),
    {
      setError: formMethods.setError,
      onSuccess: () => {
        navigate("/on-call/pay-calculator/reports/create");
      },
    },
  );

  return (
    <PageWrapper
      width={PageWidth.Narrow}
      title="On-call pay calculator"
      backHref="/on-call"
      icon={IconEnum.OnCall}
    >
      <ContentBox className="p-8 max-w-6xl">
        <div className="flex flex-col md:flex-row">
          <div className="pb-6 pr-8 w-full md:w-1/2 border-b md:border-b-0 md:border-r border-stroke space-y-4">
            <Form.Root
              onSubmit={onSubmit}
              genericError={genericError}
              formMethods={formMethods}
            >
              <div className="space-y-2 mb-6">
                <Heading level={2} size="medium">
                  Connect PagerDuty
                </Heading>
                <p className="text-sm text-slate-700">
                  Add your PagerDuty API key here to allow us to view your
                  schedules and calculate on-call pay.
                </p>
              </div>
              <InputV2
                type={InputType.Text}
                name="api_token"
                label="PagerDuty API Access Key"
                formMethods={formMethods}
                required="API token is required"
                autoComplete="none"
                placeholder={"xxxxxxxxxxxx"}
              />

              <div className="text-right">
                <Button
                  analyticsTrackingId="on-call-connect-pagerduty-submit"
                  type="submit"
                  loading={saving}
                  disabled={saving}
                  theme={ButtonTheme.Primary}
                >
                  Create report
                </Button>
              </div>
            </Form.Root>
          </div>

          <div className="w-full md:w-1/2 pt-6 md:pt-0 md:pl-8">
            <h3 className="font-semibold mb-2">
              How to generate a PagerDuty API Access Key
            </h3>
            <ol className="text-sm space-y-4 list-decimal list-outside ml-4">
              <li>
                <Button
                  analyticsTrackingId="on-call-login-to-pagerduty"
                  href="https://app.pagerduty.com"
                  openInNewTab
                  theme={ButtonTheme.Link}
                >
                  Log in
                </Button>{" "}
                to your PagerDuty account
              </li>
              <li className="space-y-2">
                Under <strong>Integrations</strong>, select{" "}
                <strong>API Access Keys</strong>
                <img
                  src={apiKeysNav}
                  alt="API Access Keys selected within the Integrations navigation section"
                />
                <Callout theme={CalloutTheme.Info}>
                  If you can&apos;t see the API Access Keys menu item, you need
                  to{" "}
                  <Button
                    openInNewTab
                    analyticsTrackingId="on-call-pagerduty-api-permissions"
                    href="https://support.pagerduty.com/docs/api-access-keys#generate-a-general-access-rest-api-key"
                    theme={ButtonTheme.Link}
                  >
                    ask an Admin or Account Owner
                  </Button>
                  .
                </Callout>
              </li>
              <li className="space-y-2">
                Click <strong>Create New API Key</strong>
                <img
                  src={createKeyButton}
                  className="drop-shadow"
                  alt="Create API Key modal"
                />
              </li>
              <li className="space-y-2">
                Call it &quot;<strong>incident.io</strong>&quot;, then click{" "}
                <strong>Create Key</strong>.
                <img
                  src={createKeyModal}
                  className="drop-shadow"
                  alt="API Access Keys heading, with Create New API Key button"
                />
              </li>
              <li className="space-y-2">
                <span>
                  Copy the new key and paste it in here, and click{" "}
                  <strong>Create report</strong>.
                </span>
                <img
                  src={newKeyModal}
                  className="drop-shadow"
                  alt="New API Key modal"
                />
              </li>
            </ol>
          </div>
        </div>
      </ContentBox>
    </PageWrapper>
  );
};
