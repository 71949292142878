import { ScopeNameEnum, StatusPageIncident } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, Heading, Icon, IconEnum, IconSize } from "@incident-ui";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { hasWriteUp } from "../utils/utils";
import { PublicWriteUpEditModal } from "./PublicWriteUpEditModal";

export const PublicWriteupCreateModal = ({
  incident,
}: {
  incident: StatusPageIncident;
}) => {
  const [showWriteUpModal, setShowWriteUpModal] = useState(false);
  const { hasScope } = useIdentity();

  return (
    <>
      {showWriteUpModal ? (
        <PublicWriteUpEditModal
          incident={incident}
          onClose={() => setShowWriteUpModal(false)}
        />
      ) : null}

      <div className="space-y-2">
        <Heading
          level={3}
          size="small"
          className="group font-medium flex items-center space-x-1"
        >
          <span>Write-up </span>

          {hasWriteUp(incident) && (
            <Icon
              name={IconEnum.Edit}
              size={IconSize.Small}
              className="w-5 h-5 text-slate-400 transition group-hover:text-content-primary cursor-pointer"
              id={IconEnum.Edit}
              onClick={() => setShowWriteUpModal(true)}
            />
          )}
        </Heading>

        {hasWriteUp(incident) ? (
          <div
            className={tcx(
              "inline-flex",
              "rounded-full border !border-stroke",
              "pl-2 pr-3 py-1.5",
              "hover:shadow !no-underline",
            )}
          >
            <a
              href={incident.write_up_url ?? `${incident.public_url}/write-up`}
              target="_blank"
              rel="noopener noreferrer"
              title={"Public write-up"}
            >
              <span className="flex items-center truncate max-w-[200px]">
                <Icon
                  name={IconEnum.ExternalLink}
                  size={IconSize.Small}
                  className="mr-1"
                  id={IconEnum.ExternalLink}
                />
                Public write-up
              </span>
            </a>
          </div>
        ) : null}

        {!hasWriteUp(incident) && (
          <div>
            <GatedButton
              analyticsTrackingId="status-page-related-incident-link-new"
              onClick={() => setShowWriteUpModal(true)}
              theme={ButtonTheme.Naked}
              title={"Add public write-up"}
              icon={
                hasWriteUp(incident) ? IconEnum.FileMinus : IconEnum.FilePlus
              }
              iconProps={{ size: IconSize.Medium }}
              disabled={!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)}
              disabledTooltipContent={
                "You do not have permission to link write-ups to public status page incidents"
              }
            >
              Add public write-up
            </GatedButton>
          </div>
        )}
      </div>
    </>
  );
};
