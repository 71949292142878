/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureComponentPayload,
    StatusPageStructureComponentPayloadFromJSON,
    StatusPageStructureComponentPayloadFromJSONTyped,
    StatusPageStructureComponentPayloadToJSON,
} from './StatusPageStructureComponentPayload';

/**
 * 
 * @export
 * @interface StatusPageStructureGroupPayload
 */
export interface StatusPageStructureGroupPayload {
    /**
     * If set, this will track this group against an Atlassian Statuspage component
     * @type {string}
     * @memberof StatusPageStructureGroupPayload
     */
    atlassian_statuspage_component_id?: string;
    /**
     * Array of components belonging to this group
     * @type {Array<StatusPageStructureComponentPayload>}
     * @memberof StatusPageStructureGroupPayload
     */
    components: Array<StatusPageStructureComponentPayload>;
    /**
     * The description of this component group
     * @type {string}
     * @memberof StatusPageStructureGroupPayload
     */
    description?: string;
    /**
     * Whether to display an aggregated uptime for all components of this group
     * @type {boolean}
     * @memberof StatusPageStructureGroupPayload
     */
    display_aggregated_uptime: boolean;
    /**
     * Whether this group is hidden from display on the status page
     * @type {boolean}
     * @memberof StatusPageStructureGroupPayload
     */
    hidden: boolean;
    /**
     * The name of this component group
     * @type {string}
     * @memberof StatusPageStructureGroupPayload
     */
    name: string;
}

export function StatusPageStructureGroupPayloadFromJSON(json: any): StatusPageStructureGroupPayload {
    return StatusPageStructureGroupPayloadFromJSONTyped(json, false);
}

export function StatusPageStructureGroupPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureGroupPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atlassian_statuspage_component_id': !exists(json, 'atlassian_statuspage_component_id') ? undefined : json['atlassian_statuspage_component_id'],
        'components': ((json['components'] as Array<any>).map(StatusPageStructureComponentPayloadFromJSON)),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'display_aggregated_uptime': json['display_aggregated_uptime'],
        'hidden': json['hidden'],
        'name': json['name'],
    };
}

export function StatusPageStructureGroupPayloadToJSON(value?: StatusPageStructureGroupPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atlassian_statuspage_component_id': value.atlassian_statuspage_component_id,
        'components': ((value.components as Array<any>).map(StatusPageStructureComponentPayloadToJSON)),
        'description': value.description,
        'display_aggregated_uptime': value.display_aggregated_uptime,
        'hidden': value.hidden,
        'name': value.name,
    };
}

