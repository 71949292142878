import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Callout,
  CalloutTheme,
  CopiableText,
  IconEnum,
  ModalFooter,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import userAvatars from "src/components/setup/common/user-avatars.json";
import { TrialStatusPlanNameEnum as PlanNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";

export const GenerateSetupLink = () => {
  const [isOpen, setIsOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.ManageTrial,
  );

  return (
    <>
      <div className="p-3 text-sm rounded-2 bg-surface-secondary space-y-3">
        <span>Sales</span>
        <div className="flex gap-2">
          <GatedButton
            onClick={() => setIsOpen(true)}
            analyticsTrackingId={null}
            {...disabledProps}
          >
            🔗 Generate setup link
          </GatedButton>
        </div>
      </div>
      {isOpen && <GenerateSetupLinkModal onClose={() => setIsOpen(false)} />}
    </>
  );
};

type FormData = {
  plan: string;
  customer: string;
  comms_platform: string;
  products: string[];
  inviter: string;
};

const GenerateSetupLinkModal = ({ onClose }: { onClose: () => void }) => {
  const { identity } = useIdentity();

  const thisUser = userAvatars.find(
    (av) => av.id + "@incident.io" === identity.user_email,
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      plan: PlanNameEnum.ProV2,
      comms_platform: "slack",
      products: ["response", "on-call"],
      inviter: thisUser?.id,
      customer: "",
    },
  });

  const plan = formMethods.watch("plan");

  const data = formMethods.watch();
  const urlValues = new URLSearchParams();
  urlValues.set("plan_name", data.plan);
  if (data.customer) {
    urlValues.set("customer", data.customer);
  }
  urlValues.set("comms_platform", data.comms_platform);
  urlValues.set("inviter", data.inviter);
  data.products.forEach((product) => urlValues.append("products", product));
  const url = `${window.location.origin}/setup/login?${urlValues.toString()}`;

  const isValid = formMethods.formState.isValid;

  const planOptions = [
    { label: "🏖️ Sandbox", value: PlanNameEnum.BasicV2 },
    { label: "🥉 Team", value: PlanNameEnum.TeamV2 },
    { label: "🥈 Pro", value: PlanNameEnum.ProV2 },
    { label: "🥇 Enterprise", value: PlanNameEnum.EnterpriseV2 },
  ];

  const commsOptions = [
    { label: "Slack", value: "slack", icon: IconEnum.Slack },
    { label: "MS Teams", value: "msteams", icon: IconEnum.MicrosoftTeams },
  ];

  const inviterOptions = userAvatars.map((av) => ({
    value: av.id,
    label: av.firstName,
    image_url: av.avatarURL,
  }));

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onClose}
      title="Generate setup link"
      onClose={onClose}
      analyticsTrackingId={null}
      footer={<ModalFooter onClose={onClose} hideConfirmButton />}
    >
      <InputV2<FormData>
        formMethods={formMethods}
        name="customer"
        label="Organisation name"
        required
      />
      <StaticSingleSelectV2<FormData>
        formMethods={formMethods}
        label="Slack or MS Teams?"
        name="comms_platform"
        required
        options={commsOptions}
      />
      <StaticSingleSelectV2<FormData>
        formMethods={formMethods}
        label="Plan"
        name="plan"
        required
        options={planOptions}
      />
      {plan === PlanNameEnum.BasicV2 && (
        <Callout
          theme={CalloutTheme.Info}
          title="Creating a sandbox"
          subtitle="This will let the customer set up an account on our Basic plan (as a trial), and you'll then need to mark it as a Sandbox in Staff Room."
        />
      )}
      <StaticSingleSelectV2<FormData>
        formMethods={formMethods}
        label="Invited by"
        name="inviter"
        required
        options={inviterOptions}
      />

      <div className="bg-surface-secondary p-3 rounded-2">
        <CopiableText
          label="Copy bespoke link"
          value={url}
          lineCount={1}
          disabled={!isValid}
        />
      </div>
    </Form.Modal>
  );
};
