import type { SVGProps } from "react";
import * as React from "react";
const SvgScribe = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.4 4A2.4 2.4 0 0 0 6 6.4h9.6A2.4 2.4 0 0 0 18 4H8.4Zm7.2 4.8H6a2.4 2.4 0 0 0 2.4 2.4H18a2.4 2.4 0 0 0-2.4-2.4ZM6 16a2.4 2.4 0 0 1 2.4-2.4H18a2.4 2.4 0 0 1-2.4 2.4H6Zm1.2-3.6a3.6 3.6 0 0 1-3.6-3.6A3.6 3.6 0 0 1 0 12.4 3.6 3.6 0 0 1 3.6 16a3.6 3.6 0 0 1 3.6-3.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScribe;
