import {
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentIncidentStatusEnum,
} from "@incident-io/api";

const component_statuses = {
  [StatusPageAffectedComponentStatusEnum.Operational]: "Operational",
  [StatusPageAffectedComponentStatusEnum.UnderMaintenance]: "Under maintenance",
  [StatusPageAffectedComponentStatusEnum.DegradedPerformance]:
    "Degraded performance",
  [StatusPageAffectedComponentStatusEnum.PartialOutage]: "Partial outage",
  [StatusPageAffectedComponentStatusEnum.FullOutage]: "Full outage",
};

const incident_statuses = {
  [StatusPageContentIncidentStatusEnum.Identified]: "Identified",
  [StatusPageContentIncidentStatusEnum.Investigating]: "Investigating",
  [StatusPageContentIncidentStatusEnum.Monitoring]: "Monitoring",
  [StatusPageContentIncidentStatusEnum.Resolved]: "Resolved",
  [StatusPageContentIncidentStatusEnum.MaintenanceScheduled]: "Scheduled",
  [StatusPageContentIncidentStatusEnum.MaintenanceInProgress]: "In progress",
  [StatusPageContentIncidentStatusEnum.MaintenanceComplete]: "Complete",
};

const durations = {
  just_now: "Just now",
  years: `{count, plural,
          =1 {<notranslate>1</notranslate> year}
          other {<notranslate>#</notranslate> years}
         }`,
  months: `{count, plural,
           =1 {<notranslate>1</notranslate> month}
           other {<notranslate>#</notranslate> months}
          }`,
  weeks: `{count, plural,
           =1 {<notranslate>1</notranslate> week}
           other {<notranslate>#</notranslate> weeks}
          }`,
  days: `<notranslate>{count}</notranslate>d`,
  hours: `<notranslate>{count}</notranslate>h`,
  minutes: `<notranslate>{count}</notranslate>m`,
  seconds: `<notranslate>{count}</notranslate>s`,
};

const conjuctions = {
  and: "and",
};

const legalPolicies = {
  privacy_policy: "Privacy policy",
  terms_of_service: "Terms of service",
};

// eslint-disable-next-line import/no-default-export
export default {
  component_statuses,
  incident_statuses,
  durations,
  page_title: "{pageName} Status",
  incident_page_title: "{incidentName} - {pageName} Status",
  page_description: "Latest service status for {pageName}",
  feeds: {
    status: "Status: {status}",
    affected_components: "Affected components",
    whole_page: {
      title: "{pageName} status",
      description: "{pageName} status page updates",
    },
    one_incident: {
      title: "{pageName} status: {incidentName}",
      description: "{incidentName} incident updates",
    },
    maintenance_title: "Maintenance: {incidentName}",
    slack: {
      maintenance_scheduled: "{pageName} - Maintenance scheduled",
      maintenance_in_progress: "{pageName} - Maintenance in progress",
      maintenance_complete: "{pageName} - Maintenance complete",
      new_incident: "{pageName} - New incident",
      incident_update: "{pageName} - Incident update",
      incident_resolved: "{pageName} - Incident resolved",
    },
  },
  AffectedComponents: {
    affected_components: "Affected components",
    no_impacts: "No components marked as affected",
    toggle_group: "Toggle group",
    number_affected_components: `{count, plural,
                                  =1 {1 affected component}
                                  other {# affected components}
                                 }`,
    now: "Now",
    since_time: "Since <notranslate>{time}</notranslate>",
    component_statuses,
    incident_statuses,
  },
  Header: {
    subscribe_to_updates: "Subscribe to updates",
    history_breadcrumb: "History",
  },
  Footer: {
    powered_by: "Powered by",
    ...legalPolicies,
  },
  HeadsUp: {
    related_incidents: "Related incidents",
    related_maintenances: "Related maintenances",
    under_maintenance: "We’re currently undergoing maintenance",
    fully_operational: "We’re fully operational",
    experiencing_issues: "We’re currently experiencing issues",
    not_aware_of_issues: "We’re not aware of any issues affecting our systems.",
    currently_affected: "Currently affected",
    live_incidents: "Live incidents",
    live_maintenance: "Live maintenance",
    since_time: "Since <notranslate>{time}</notranslate>",
    ongoing_for: "Ongoing for {duration}",
    affects_components: "Affects {components}",
    incident_statuses,
    durations,
  },
  Icons: {
    alarmalade: "Full outage",
    orange: "Partial outage",
    yellow: "Degraded performance",
    blue: "Under maintenance",
    ok: "Operational",
  },
  IncidentCalendar: {
    title: "Calendar",
    no_incidents: "No incidents happened in this month",
    weekdays: {
      monday: "M",
      tuesday: "T",
      wednesday: "W",
      thursday: "T",
      friday: "F",
      saturday: "S",
      sunday: "S",
    },
  },
  IncidentHeader: {
    time_ago: "{duration} ago",
    view_all_updates: "View all updates",
    weve_published_a_writeup: "We’ve published a write-up of this incident",
    read_the_writeup: "Read the write-up",
    read_writeup: "Read it here",
    from_time: "From <notranslate>{time}</notranslate>",
    until_time: "until <notranslate>{time}</notranslate>",
    since_time: "Since <notranslate>{time}</notranslate>",
    ongoing_for: "Ongoing for {duration}",
    incident_statuses,
    component_statuses,
    durations,
  },
  IncidentHistory: {
    view_history: "View history",
    title: "History",
    upcoming_maintenance_scheduled: "Upcoming maintenance scheduled",
    no_incidents: "No incidents reported",
    loading: "Loading incidents for this period",
  },
  IncidentUpdates: {
    updates_heading: "Updates",
    writeup_published: "Write-up published",
    read_our_writeup_link: "Read our write-up of this incident at",
    read_our_writeup_embed: "Read it here",
    further_updates: "Further updates will appear here",
    duration_earlier: "{duration} earlier...",
    incident_statuses,
    durations,
  },
  WriteUp: {
    write_up: "Write-up",
    view_the_incident: "View the incident",
    component_statuses,
  },
  Modal: {
    close: "Close",
    confirm: "Confirm",
  },
  Spinner: {
    loading: "Loading...",
  },
  SubscriptionVerification: {
    confirmed: "Subscription confirmed",
    incident_thank_you:
      "Thank you for confirming your subscription, you’ll now receive notifications for updates to the ‘{incidentName}’ incident.",
    page_thank_you:
      "Thank you for confirming your subscription. You’ll receive notifications for the {pageName} status page.",
    incorrect_code: "Incorrect verification code",
    view_incident: "View incident",
    view_page: "View status page",
  },
  SubscriptionSignupModal: {
    email: "Email",
    rss: "RSS",
    slack: "Slack",
    subscribe_title: "Subscribe to updates",
    button_close: "Close",
    button_subscribe: "Subscribe",
    something_went_wrong: "Something went wrong",
    confirm_your_subscription_title: "Confirm your subscription",
    sent_a_confirmation_link_1:
      "We’ve sent an email to {email} with a confirmation link.",
    sent_a_confirmation_link_2:
      "Your subscription will become active once you’ve confirmed your address using the link in your email.",
    sent_a_confirmation_link_3:
      "If you're already subscribed, you won't receive a confirmation email, but your susbcription preferences have been updated.",
    sent_a_confirmation_link: `We’ve sent an email to {email} with a confirmation link. Your subscription will become active once you’ve confirmed your address using the link in your email.

      If you're already subscribed, you won't receive a confirmation email, but your susbcription preferences have been updated.`,
    email_modal: {
      whole_page_notice:
        "You’ll receive emails for new incidents, as well as updates to existing incidents.",
      incident_notice: "You’ll receive emails for updates to {incident}.",
      specific_components_notice:
        "You’ll receive emails for incidents affecting your selected components",
      email_label: "Enter your email address",
      email_example: "e.g. hello@example.com",
      email_required: "Please enter your email address",
      email_invalid: "Please enter a valid email address",
      components_header: "Components",
      group_components_count: `{count, plural,
                                =1 {1 component}
                                other {# components}
                               }`,
      subscribe_to_components_label: "Subscribe to specific components",
      select_at_least_one_component:
        "Select at least one component that you would like to receive email updates for",
      legal_policies_notice: "By subscribing, you agree to our",
      ...conjuctions,
      ...legalPolicies,
    },
    rss_modal: {
      rss: "Get the RSS feed",
      atom: "Get the Atom feed",
    },
    slack_modal: {
      instructions:
        "To receive live status updates in Slack, copy and paste the text below into the Slack channel of your choice.",
    },
  },
  SystemStatus: {
    title: "System status",
    upcoming_maintenance_scheduled: "Upcoming maintenance scheduled",
    components_in_group: `{count, plural,
                           =1 {1 component}
                           other {# components}
                          }`,
    uptime_percent: "<notranslate>{uptime}</notranslate>% uptime",
  },
  Tooltip: {
    no_incidents: "No incidents",
    no_data_available: "No data available",
  },
  Unsubscribe: {
    youve_been_unsubscribed: "You’ve been unsubscribed",
    no_longer_receive_notifications:
      "You will no longer receive email notifications for the {pageName} status page.",
    incorrect_code: "Incorrect verification code",
    view_page: "View status page",
  },
  Login: {
    description: "Status page sign in for ",
    email_placeholder: "Your work email",
    send_magic_link: "Continue",
    magic_link_confirmation: "We just sent an email to ",
    confirm_email_address:
      "It has a magic link that'll sign you in to the {pageName} status page",
    not_received_email:
      "Not received an email? Please check your spam folder, or ",
    re_enter_email: "re-enter your address",
  },
};
