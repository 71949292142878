import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Checkbox,
  IconBadge,
  IconEnum,
  IconSize,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { isEmpty } from "lodash";
import { useRef, useState } from "react";
import {
  PostIncidentActionModals,
  PostIncidentModalProvider,
} from "src/components/legacy/incident/postincidentflow/PostIncidentTaskActionItem";
import {
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  PostIncidentFlow,
  PostIncidentTask,
  PostIncidentTaskSlim,
  Settings,
} from "src/contexts/ClientContext";

import { PostIncidentAssigneeSection } from "./PostIncidentAssigneeSection";
import {
  PostIncidentStatusHeader,
  PostIncidentTableHeader,
} from "./PostIncidentTableHeader";
import { PostIncidentTaskOverflow } from "./PostIncidentTaskOverflow";
import { TaskDueDateBadge } from "./TaskDueDateBadge";

export const PostIncidentList = ({
  incidents,
  refetchIncidents,
  settings,
  flows,
  selectedTasks,
  setSelectedTasks,
}: {
  incidents: Incident[];
  refetchIncidents: () => Promise<void>;
  settings: Settings;
  flows: PostIncidentFlow[];
  selectedTasks: PostIncidentTaskSlim[];
  setSelectedTasks: (taskIDs: PostIncidentTaskSlim[]) => void;
}): React.ReactElement => {
  return (
    <PostIncidentModalProvider>
      <div className="space-y-6 divide-slate-200 mt-4 mb-10">
        {incidents.map((inc: Incident, idx) => {
          if (!inc.post_incident_tasks || isEmpty(inc.post_incident_tasks)) {
            return null;
          }
          return (
            <PostIncidentTable
              incident={inc}
              tasks={inc.post_incident_tasks}
              refetchIncidents={refetchIncidents}
              flows={flows}
              key={idx}
              settings={settings}
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
            />
          );
        })}
      </div>
      <PostIncidentActionModals />
    </PostIncidentModalProvider>
  );
};

export const PostIncidentTable = ({
  incident,
  tasks,
  refetchIncidents,
  flows,
  settings,
  selectedTasks = [],
  setSelectedTasks,
}: {
  incident: Incident;
  tasks: PostIncidentTaskSlim[];
  refetchIncidents: () => Promise<void>;
  flows: PostIncidentFlow[];
  settings: Settings;
  selectedTasks?: PostIncidentTaskSlim[];
  setSelectedTasks: (taskIDs: PostIncidentTaskSlim[]) => void;
}) => {
  // Find the flow associated with this incident. Note that `incident.post_incident_flow_id` doesn't
  // always appear to be set so we have to check the statuses directly.
  const flow = flows.find((f) => {
    return f.incident_statuses
      .map((status) => status.id)
      .includes(incident.incident_status.id);
  });

  const statuses =
    flow?.incident_statuses.filter(
      (status) => status.category === IncidentStatusCategoryEnum.PostIncident,
    ) || [];

  // Sort the tasks by status and then by due date
  tasks.sort((a, b) => {
    const aRank = taskToRank(a);
    const bRank = taskToRank(b);
    if (
      aRank === bRank &&
      getTaskStatus(a) === "outstanding" &&
      a.due_at &&
      b.due_at
    ) {
      return new Date(a.due_at).getTime() - new Date(b.due_at).getTime();
    }
    return aRank - bRank;
  });

  const tasksByStatus = {};

  tasks.forEach((task) => {
    const status = task.config.incident_status_id;
    if (!tasksByStatus[status]) {
      tasksByStatus[status] = [];
    }
    tasksByStatus[status].push(task);
  });

  const pincStatuses = statuses.filter(
    (s) => s.category === IncidentStatusCategoryEnum.PostIncident,
  );

  return (
    <StackedList>
      {/* Header */}
      <PostIncidentTableHeader
        incident={incident}
        settings={settings}
        selectedTasks={selectedTasks}
        setSelectedTasks={setSelectedTasks}
      />
      {pincStatuses.map((status) => {
        const tasks = tasksByStatus[status.id];
        return (
          <>
            <PostIncidentStatusHeader status={status} />
            {tasks ? (
              tasks.map((task, idx) => (
                <PostIncidentItemTask
                  task={task}
                  refetchIncidents={refetchIncidents}
                  statuses={statuses}
                  key={idx}
                  isSelected={selectedTasks.some(
                    (selected) => selected.id === task.id,
                  )}
                  setSelected={(isSelected: boolean) => {
                    if (isSelected) {
                      setSelectedTasks([...selectedTasks, task]);
                    } else {
                      setSelectedTasks(
                        selectedTasks.filter(({ id }) => id !== task.id),
                      );
                    }
                  }}
                />
              ))
            ) : (
              <div className="text-content-tertiary text-sm min-h-[52px] flex items-center pl-[48px]">
                There are no tasks for this status
              </div>
            )}
          </>
        );
      })}
    </StackedList>
  );
};

const PostIncidentItemTask = ({
  task,
  refetchIncidents,
  statuses,
  isSelected,
  setSelected,
}: {
  task: PostIncidentTaskSlim;
  refetchIncidents: () => Promise<void>;
  statuses: IncidentStatus[];
  isSelected: boolean;
  setSelected: (isSelected: boolean) => void;
}) => {
  const status = getTaskStatus(task);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const taskConfig = TASK_STATUS_CONFIG[getTaskStatus(task)];

  return (
    <div className="flex items-center justify-between w-full px-4 bg-white truncate h-[52px]">
      {/* Key details: task status, name and due date */}
      <div className="flex items-center gap-3 grow truncate">
        <Checkbox
          id="select_all"
          checked={isSelected}
          onChange={() => setSelected(!isSelected)}
          disabled={status !== "outstanding"}
        />
        <Tooltip content={taskConfig.label}>
          <IconBadge
            size={IconSize.Small}
            icon={taskConfig.icon}
            color={taskConfig.color}
          />
        </Tooltip>
        <Button
          analyticsTrackingId={null}
          onClick={() => setShowDetailsModal(true)}
          theme={ButtonTheme.Link}
          className="text-sm-bold"
        >
          {task.config.title}
        </Button>
        <TaskDueDateBadge task={task} statuses={statuses} />
      </div>
      <div className="flex items-center gap-2">
        <PostIncidentAssigneeSection
          task={task}
          refetchIncidents={refetchIncidents}
          BadgeSize={BadgeSize.Medium}
        />
        <PostIncidentTaskOverflow
          task={task}
          refetchIncidents={refetchIncidents}
          disabled={status !== "outstanding"}
        />
      </div>
      {showDetailsModal && (
        <TaskDetailsModal
          task={task}
          onClose={() => setShowDetailsModal(false)}
        />
      )}
    </div>
  );
};

type TaskStatus = "outstanding" | "completed" | "skipped";

export const TASK_STATUS_CONFIG = {
  outstanding: {
    color: ColorPaletteEnum.Slate,
    icon: IconEnum.DottedCircle,
    compactIcon: IconEnum.DottedCircle,
    label: "Outstanding",
    compactIconClassName: "",
  },
  completed: {
    color: ColorPaletteEnum.Green,
    icon: IconEnum.Tick,
    compactIcon: IconEnum.TickCircle,
    label: "Completed",
    compactIconClassName: "text-green-content",
  },
  skipped: {
    color: ColorPaletteEnum.Red,
    icon: IconEnum.Close,
    label: "Skipped",
    compactIcon: IconEnum.CloseCircle,
    compactIconClassName: "text-red-content",
  },
};

export const getTaskStatus = (
  task: PostIncidentTaskSlim | PostIncidentTask,
): TaskStatus => {
  if (task.completed_at) {
    return "completed";
  } else if (task.rejected_at) {
    return "skipped";
  } else {
    return "outstanding";
  }
};

const taskToRank = (task: PostIncidentTaskSlim): number => {
  switch (getTaskStatus(task)) {
    case "outstanding":
      // If the due date is set, it should be at the top
      if (task.due_at) {
        return 0;
      }
      return 1;
    case "completed":
      return 2;
    case "skipped":
      return 3;
    default:
      throw new Error("unreachable: unexpected status");
  }
};
const TaskDetailsModal = ({
  task,
  onClose,
}: {
  task: PostIncidentTaskSlim;
  onClose: () => void;
}): React.ReactElement => {
  const textRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      isOpen
      title={task.config.title}
      onClose={onClose}
      analyticsTrackingId={"post-incident-list-view-task"}
    >
      <ModalContent className="mb-4">
        <label className={"font-medium text-content-primary text-sm"}>
          {"Instructions"}
        </label>
        <TemplatedTextDisplay
          ref={textRef}
          style={TemplatedTextDisplayStyle.Compact}
          value={task.config.description}
          className="mt-1 text-sm text-slate-700 overflow-hidden"
        />
      </ModalContent>
      <ModalFooter
        confirmButtonText={"View in incident"}
        confirmButtonType={"button"}
        onConfirm={() =>
          window.open(`/incidents/${task.incident_id}/post-incident`, "_blank")
        }
        onClose={onClose}
        hideConfirmButton={false}
        cancelButtonText="Close"
      />
    </Modal>
  );
};
