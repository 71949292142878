import {
  ContentBox,
  GenericErrorMessage,
  IconSize,
  Loader,
  SlackTeamAvatar,
} from "@incident-ui";
import React from "react";
import { useAPI } from "src/utils/swr";

import { SettingsSubHeading } from "../SettingsSubHeading";

export const SlackWorkspaceViewSection = (): React.ReactElement | null => {
  const {
    data: { slack_team_configs: slackTeamConfigs },
    isLoading,
    error,
  } = useAPI("slackTeamConfigsList", undefined, {
    fallbackData: { slack_team_configs: [] },
  });

  if (!slackTeamConfigs || isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ContentBox className="p-4">
        <GenericErrorMessage error={error} />
      </ContentBox>
    );
  }

  // This content is only for non-Grid installs, so we can just take the first
  const slackTeamConfig = slackTeamConfigs[0];

  return (
    <>
      <SettingsSubHeading
        title="Slack workspace"
        titleHeadingLevel={2}
        className="!mb-2"
        explanation={`The slack workspace you have installed incident.io on.`}
      />
      <ContentBox className="p-4 space-y-4 mb-6">
        <div className="flex shrink-0 font-semibold text-sm items-center gap-1">
          <SlackTeamAvatar
            url={slackTeamConfig.icon_url}
            name={slackTeamConfig.name}
            size={IconSize.Large}
            className="mr-1"
          />
          <span>{slackTeamConfig.name}</span>
        </div>
      </ContentBox>
    </>
  );
};
