import type { SVGProps } from "react";
import * as React from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 5.5h-7A2.75 2.75 0 0 0 5.5 8.25v7A2.75 2.75 0 0 0 8.25 18h7A2.75 2.75 0 0 0 18 15.25v-7a2.75 2.75 0 0 0-2.75-2.75Z"
    />
    <path
      fill="currentColor"
      d="M4 14.354a.751.751 0 0 1-.281-.055 2.74 2.74 0 0 1-1.718-2.55V4.75A2.754 2.754 0 0 1 4.751 2h7a2.74 2.74 0 0 1 2.55 1.718.751.751 0 0 1-1.391.563 1.245 1.245 0 0 0-1.159-.782H4.75c-.689 0-1.25.561-1.25 1.25v7c0 .512.307.967.782 1.159A.751.751 0 0 1 4 14.353v.001Z"
    />
  </svg>
);
export default SvgCopy;
