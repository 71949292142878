/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentVerifySubscriptionRequestBody
 */
export interface StatusPageContentVerifySubscriptionRequestBody {
    /**
     * Unique ID of the subscription to verify
     * @type {string}
     * @memberof StatusPageContentVerifySubscriptionRequestBody
     */
    subscription_id: string;
    /**
     * Verification code sent along with verification message to subscription channel
     * @type {string}
     * @memberof StatusPageContentVerifySubscriptionRequestBody
     */
    verification_code: string;
}

export function StatusPageContentVerifySubscriptionRequestBodyFromJSON(json: any): StatusPageContentVerifySubscriptionRequestBody {
    return StatusPageContentVerifySubscriptionRequestBodyFromJSONTyped(json, false);
}

export function StatusPageContentVerifySubscriptionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentVerifySubscriptionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscription_id': json['subscription_id'],
        'verification_code': json['verification_code'],
    };
}

export function StatusPageContentVerifySubscriptionRequestBodyToJSON(value?: StatusPageContentVerifySubscriptionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscription_id': value.subscription_id,
        'verification_code': value.verification_code,
    };
}

