/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';

/**
 * 
 * @export
 * @interface CustomFieldsListOptionsRequestBody
 */
export interface CustomFieldsListOptionsRequestBody {
    /**
     * Current state of the incident form, to help filter catalog entry options
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof CustomFieldsListOptionsRequestBody
     */
    custom_field_entries?: Array<CustomFieldEntryPayload>;
    /**
     * An array of option IDs to list
     * @type {Array<string>}
     * @memberof CustomFieldsListOptionsRequestBody
     */
    option_ids?: Array<string>;
    /**
     * Query string to filter results
     * @type {string}
     * @memberof CustomFieldsListOptionsRequestBody
     */
    query?: string;
}

export function CustomFieldsListOptionsRequestBodyFromJSON(json: any): CustomFieldsListOptionsRequestBody {
    return CustomFieldsListOptionsRequestBodyFromJSONTyped(json, false);
}

export function CustomFieldsListOptionsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldsListOptionsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': !exists(json, 'custom_field_entries') ? undefined : ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'option_ids': !exists(json, 'option_ids') ? undefined : json['option_ids'],
        'query': !exists(json, 'query') ? undefined : json['query'],
    };
}

export function CustomFieldsListOptionsRequestBodyToJSON(value?: CustomFieldsListOptionsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': value.custom_field_entries === undefined ? undefined : ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'option_ids': value.option_ids,
        'query': value.query,
    };
}

