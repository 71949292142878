/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SAMLSettings,
    SAMLSettingsFromJSON,
    SAMLSettingsFromJSONTyped,
    SAMLSettingsToJSON,
} from './SAMLSettings';

/**
 * 
 * @export
 * @interface SAMLShowResponseBody
 */
export interface SAMLShowResponseBody {
    /**
     * Is SAML authentication enabled?
     * @type {boolean}
     * @memberof SAMLShowResponseBody
     */
    enabled: boolean;
    /**
     * 
     * @type {SAMLSettings}
     * @memberof SAMLShowResponseBody
     */
    saml_settings?: SAMLSettings;
}

export function SAMLShowResponseBodyFromJSON(json: any): SAMLShowResponseBody {
    return SAMLShowResponseBodyFromJSONTyped(json, false);
}

export function SAMLShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'saml_settings': !exists(json, 'saml_settings') ? undefined : SAMLSettingsFromJSON(json['saml_settings']),
    };
}

export function SAMLShowResponseBodyToJSON(value?: SAMLShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'saml_settings': SAMLSettingsToJSON(value.saml_settings),
    };
}

