/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsVantaSetSettingsRequestBody,
    IntegrationsVantaSetSettingsRequestBodyFromJSON,
    IntegrationsVantaSetSettingsRequestBodyToJSON,
    IntegrationsVantaShowSettingsResponseBody,
    IntegrationsVantaShowSettingsResponseBodyFromJSON,
    IntegrationsVantaShowSettingsResponseBodyToJSON,
} from '../models';

export interface IntegrationsVantaHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsVantaHandleRedirectRequest {
    code?: string;
    state?: string;
}

export interface IntegrationsVantaSetSettingsRequest {
    setSettingsRequestBody: IntegrationsVantaSetSettingsRequestBody;
}

/**
 * 
 */
export class IntegrationsVantaApi extends runtime.BaseAPI {

    /**
     * Handle Vanta installation by redirecting to the Vanta install endpoint
     * HandleInstall Integrations - Vanta
     */
    async integrationsVantaHandleInstallRaw(requestParameters: IntegrationsVantaHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsVantaHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/vanta_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Vanta installation by redirecting to the Vanta install endpoint
     * HandleInstall Integrations - Vanta
     */
    async integrationsVantaHandleInstall(requestParameters: IntegrationsVantaHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsVantaHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Vanta redirect, where we exchange the code for an access token
     * HandleRedirect Integrations - Vanta
     */
    async integrationsVantaHandleRedirectRaw(requestParameters: IntegrationsVantaHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/vanta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Vanta redirect, where we exchange the code for an access token
     * HandleRedirect Integrations - Vanta
     */
    async integrationsVantaHandleRedirect(requestParameters: IntegrationsVantaHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsVantaHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Vanta uninstallation request by deactivating the token
     * HandleUninstall Integrations - Vanta
     */
    async integrationsVantaHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/vanta_uninstall`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Vanta uninstallation request by deactivating the token
     * HandleUninstall Integrations - Vanta
     */
    async integrationsVantaHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsVantaHandleUninstallRaw(initOverrides);
    }

    /**
     * Set Vanta connector settings
     * SetSettings Integrations - Vanta
     */
    async integrationsVantaSetSettingsRaw(requestParameters: IntegrationsVantaSetSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setSettingsRequestBody === null || requestParameters.setSettingsRequestBody === undefined) {
            throw new runtime.RequiredError('setSettingsRequestBody','Required parameter requestParameters.setSettingsRequestBody was null or undefined when calling integrationsVantaSetSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/vanta/settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsVantaSetSettingsRequestBodyToJSON(requestParameters.setSettingsRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Vanta connector settings
     * SetSettings Integrations - Vanta
     */
    async integrationsVantaSetSettings(requestParameters: IntegrationsVantaSetSettingsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsVantaSetSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Show Vanta connector settings
     * ShowSettings Integrations - Vanta
     */
    async integrationsVantaShowSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsVantaShowSettingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/vanta/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsVantaShowSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show Vanta connector settings
     * ShowSettings Integrations - Vanta
     */
    async integrationsVantaShowSettings(initOverrides?: RequestInit): Promise<IntegrationsVantaShowSettingsResponseBody> {
        const response = await this.integrationsVantaShowSettingsRaw(initOverrides);
        return await response.value();
    }

}
