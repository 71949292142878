import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Heading } from "@incident-ui/Heading/Heading";
import { DialogContent } from "@reach/dialog";
import { tcx } from "src/utils/tailwind-classes";

import { MotionDialogOverlay } from "./Modal";
import styles from "./Modal.module.scss";
import { ModalContent } from "./ModalContent";

export const ModalConfirmationDialog = ({
  onConfirm,
  onCancel,
  suppressInitialAnimation = false,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  suppressInitialAnimation?: boolean;
}): React.ReactElement => {
  return (
    <MotionDialogOverlay
      as="div"
      initial={suppressInitialAnimation ? false : { opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      className="z-[100] bg-none !bg-surface-invert !bg-opacity-25"
      onDismiss={onCancel}
    >
      <DialogContent
        className={tcx(styles.dialogContent)}
        aria-label="close-modal-confirmation"
      >
        <div
          className={tcx(styles.modalHeader, "p-4", "border-b border-stroke")}
        >
          <Heading level={3}>Close modal</Heading>
        </div>
        <ModalContent>
          <p>
            Are you sure you want to close this modal? You will lose any unsaved
            changes.
          </p>
          <div className="flex flex-row-reverse w-full items-center space-x-2 gap-2">
            <Button
              analyticsTrackingId="confirm-close-modal"
              theme={ButtonTheme.Primary}
              onClick={onConfirm}
            >
              Confirm
            </Button>
            <Button analyticsTrackingId="cancel-close-modal" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </ModalContent>
      </DialogContent>
    </MotionDialogOverlay>
  );
};
