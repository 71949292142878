/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface RemindersCreateRequestBody
 */
export interface RemindersCreateRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof RemindersCreateRequestBody
     */
    message?: TextNode;
    /**
     * What type of resource are we reminding users about?
     * @type {string}
     * @memberof RemindersCreateRequestBody
     */
    reminder_type: RemindersCreateRequestBodyReminderTypeEnum;
    /**
     * Which resources should we remind people about?
     * @type {Array<string>}
     * @memberof RemindersCreateRequestBody
     */
    resource_ids: Array<string>;
    /**
     * A list of engine references who should be reminded.
     * @type {Array<string>}
     * @memberof RemindersCreateRequestBody
     */
    targets: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum RemindersCreateRequestBodyReminderTypeEnum {
    FollowUp = 'follow-up',
    PostIncidentTask = 'post-incident-task'
}

export function RemindersCreateRequestBodyFromJSON(json: any): RemindersCreateRequestBody {
    return RemindersCreateRequestBodyFromJSONTyped(json, false);
}

export function RemindersCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemindersCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : TextNodeFromJSON(json['message']),
        'reminder_type': json['reminder_type'],
        'resource_ids': json['resource_ids'],
        'targets': json['targets'],
    };
}

export function RemindersCreateRequestBodyToJSON(value?: RemindersCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': TextNodeToJSON(value.message),
        'reminder_type': value.reminder_type,
        'resource_ids': value.resource_ids,
        'targets': value.targets,
    };
}

