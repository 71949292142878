import TurndownService, { Node } from "turndown";

const indexOf = Array.prototype.indexOf;
const every = Array.prototype.every;

const rules = {
  tableCell: {
    filter: ["th", "td"],
    replacement: function (content: string, node: Node) {
      return cell(content, node);
    },
  },

  tableRow: {
    filter: "tr",
    replacement: function (content: string, node: Node) {
      let borderCells = "";
      const alignMap = { left: ":--", right: "--:", center: ":-:" };

      if (isHeadingRow(node)) {
        for (let i = 0; i < node.childNodes.length; i++) {
          let border = "---";
          const align = (
            node.children[i].getAttribute("align") || ""
          ).toLowerCase();

          if (align) border = alignMap[align] || border;

          borderCells += cell(border, node.children[i] as Node);
        }
      }
      return "\n" + content + (borderCells ? "\n" + borderCells : "");
    },
  },
  table: {
    // Only convert tables with a heading row.
    // Tables with no heading row are kept using `keep` (see below).
    filter: function (node: Node) {
      return (
        node.nodeName === "TABLE" &&
        isHeadingRow((node as HTMLTableElement).rows[0])
      );
    },

    replacement: function (content: string) {
      // Ensure there are no blank lines
      content = content.replace("\n\n", "\n");
      return "\n\n" + content + "\n\n";
    },
  },
  tableSection: {
    filter: ["thead", "tbody", "tfoot"],
    replacement: function (content: string) {
      return content;
    },
  },
};

// A tr is a heading row if:
// - the parent is a THEAD
// - or if it's the first child of the TABLE or the first TBODY (possibly
//   following a blank THEAD)
// - and every cell is a TH
const isHeadingRow = (tr: Node) => {
  const parentNode = tr.parentNode;
  if (parentNode == null) return false;
  return (
    parentNode.nodeName === "THEAD" ||
    (parentNode.firstChild === tr &&
      (parentNode.nodeName === "TABLE" ||
        (isFirstTbody(parentNode as HTMLElement) &&
          every.call(tr.childNodes, function (n) {
            return n.nodeName === "TH";
          }))))
  );
};

const isFirstTbody = (element: HTMLElement) => {
  const previousSibling = element.previousSibling;
  return (
    element.nodeName === "TBODY" &&
    (!previousSibling ||
      (previousSibling.nodeName === "THEAD" &&
        /^\s*$/i.test(previousSibling.textContent || "")))
  );
};

const cell = (content: string, node: Node) => {
  const index = indexOf.call(node?.parentNode?.childNodes, node);
  // The markdown spec says to use <br> for linebreaks, but that doesn't work in
  // Notion and looks ugly, so we just use spaces.
  const contentWithSafeNewlines = content.replace(/\s+\n+/g, " ");
  const contentWithPipeEscaped = contentWithSafeNewlines.replaceAll(
    "|",
    "&#124;",
  );
  let prefix = " ";
  if (index === 0) prefix = "| ";
  return prefix + contentWithPipeEscaped + " |";
};

export const tablesPlugin = (turndownService: TurndownService): void => {
  turndownService.keep(function (node) {
    return (
      node.nodeName === "TABLE" &&
      !isHeadingRow((node as HTMLTableElement).rows[0])
    );
  });
  Object.keys(rules).forEach((key) => {
    turndownService.addRule(key, rules[key]);
  });
};

export const linkPlugin = (turndownService: TurndownService): void => {
  turndownService.addRule("inlineLink", {
    filter: function (node, options) {
      return (
        options.linkStyle === "inlined" &&
        node.nodeName === "A" &&
        node.getAttribute("href") != null
      );
    },

    replacement: function (content, _node: Node) {
      const node = _node as HTMLElement;
      const href = node.getAttribute("href");
      let title = (node.getAttribute("title") || "").replace(
        /(\n+\s*)+/g,
        "\n",
      );
      if (title) title = ' "' + title + '"';
      return "[" + content.replace(/#/g, "\\#") + "](" + href + title + ")";
    },
  });
};
