/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIKeyRoleAndAvailability,
    APIKeyRoleAndAvailabilityFromJSON,
    APIKeyRoleAndAvailabilityFromJSONTyped,
    APIKeyRoleAndAvailabilityToJSON,
} from './APIKeyRoleAndAvailability';

/**
 * 
 * @export
 * @interface APIKeysListRolesAndAvailabilityResponseBody
 */
export interface APIKeysListRolesAndAvailabilityResponseBody {
    /**
     * 
     * @type {Array<APIKeyRoleAndAvailability>}
     * @memberof APIKeysListRolesAndAvailabilityResponseBody
     */
    roles: Array<APIKeyRoleAndAvailability>;
}

export function APIKeysListRolesAndAvailabilityResponseBodyFromJSON(json: any): APIKeysListRolesAndAvailabilityResponseBody {
    return APIKeysListRolesAndAvailabilityResponseBodyFromJSONTyped(json, false);
}

export function APIKeysListRolesAndAvailabilityResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIKeysListRolesAndAvailabilityResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': ((json['roles'] as Array<any>).map(APIKeyRoleAndAvailabilityFromJSON)),
    };
}

export function APIKeysListRolesAndAvailabilityResponseBodyToJSON(value?: APIKeysListRolesAndAvailabilityResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': ((value.roles as Array<any>).map(APIKeyRoleAndAvailabilityToJSON)),
    };
}

