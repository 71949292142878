/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JiraWebhookUser
 */
export interface JiraWebhookUser {
    /**
     * Unique ID of the user in Jira
     * @type {string}
     * @memberof JiraWebhookUser
     */
    AccountID?: string;
    /**
     * Is this user still active?
     * @type {boolean}
     * @memberof JiraWebhookUser
     */
    active?: boolean;
    /**
     * Name of the user
     * @type {string}
     * @memberof JiraWebhookUser
     */
    displayName?: string;
    /**
     * Email address of the user
     * @type {string}
     * @memberof JiraWebhookUser
     */
    emailAddress?: string;
}

export function JiraWebhookUserFromJSON(json: any): JiraWebhookUser {
    return JiraWebhookUserFromJSONTyped(json, false);
}

export function JiraWebhookUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraWebhookUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AccountID': !exists(json, 'AccountID') ? undefined : json['AccountID'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
    };
}

export function JiraWebhookUserToJSON(value?: JiraWebhookUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AccountID': value.AccountID,
        'active': value.active,
        'displayName': value.displayName,
        'emailAddress': value.emailAddress,
    };
}

