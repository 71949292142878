/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentFormEscalateConfig,
    IncidentFormEscalateConfigFromJSON,
    IncidentFormEscalateConfigFromJSONTyped,
    IncidentFormEscalateConfigToJSON,
} from './IncidentFormEscalateConfig';

/**
 * 
 * @export
 * @interface IncidentFormsUpdateFormRequestBody
 */
export interface IncidentFormsUpdateFormRequestBody {
    /**
     * 
     * @type {IncidentFormEscalateConfig}
     * @memberof IncidentFormsUpdateFormRequestBody
     */
    escalate_config?: IncidentFormEscalateConfig;
}

export function IncidentFormsUpdateFormRequestBodyFromJSON(json: any): IncidentFormsUpdateFormRequestBody {
    return IncidentFormsUpdateFormRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsUpdateFormRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsUpdateFormRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalate_config': !exists(json, 'escalate_config') ? undefined : IncidentFormEscalateConfigFromJSON(json['escalate_config']),
    };
}

export function IncidentFormsUpdateFormRequestBodyToJSON(value?: IncidentFormsUpdateFormRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalate_config': IncidentFormEscalateConfigToJSON(value.escalate_config),
    };
}

