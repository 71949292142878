import type { SVGProps } from "react";
import * as React from "react";
const SvgEscalationPath = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 2h-8.5A2.752 2.752 0 0 0 3 4.75v10.5A2.752 2.752 0 0 0 5.75 18h8.5A2.752 2.752 0 0 0 17 15.25V4.75A2.752 2.752 0 0 0 14.25 2Zm1.25 9v2h-7v-2h7ZM12 9.5v-2h3.5v2H12Zm2.25 7h-8.5c-.689 0-1.25-.561-1.25-1.25v-.75h11v.75c0 .689-.561 1.25-1.25 1.25Z"
    />
  </svg>
);
export default SvgEscalationPath;
