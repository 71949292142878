import {
  CatalogType,
  CatalogTypeAttribute,
  CatalogTypeModeEnum,
  CatalogUpdateTypeRequestBodyColorEnum,
  CatalogUpdateTypeRequestBodyIconEnum,
  DependentResource,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { IntegrationConfig } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  FloatingFooter,
  Txt,
} from "@incident-ui";
import { find } from "lodash";
import { useState } from "react";
import { SubmitHandler, UseFormReturn, useFormState } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { CatalogTypeCategoryPicker } from "./CatalogTypeCategoryPicker";
import { CatalogTypeIconPicker } from "./CatalogTypeIconPicker";
import { CatalogTypeSchemaSection } from "./CatalogTypeSchemaSection";
import { WarningModal } from "./CatalogTypeWarningModal";
import {
  AttributeFormState,
  CatalogTypeCreateEditFormState,
  isDerivedAttribute,
} from "./types";

type CatalogTypeCreateEditFormProps = {
  handleSubmit: SubmitHandler<CatalogTypeCreateEditFormState>;
  formMethods: UseFormReturn<CatalogTypeCreateEditFormState>;
  saving: boolean;
  genericError?: string;
  catalogTypeMode: CatalogTypeModeEnum;
  attributeDependencies?: { [key: string]: DependentResource[] };
  requiredIntegration?: IntegrationConfig;
  initialData?: CatalogType;
  onAddAttributeCallback?: (attributeOption: AttributeFormState) => void;
  mode: Mode;
};

export const CatalogTypeCreateEditForm = ({
  handleSubmit,
  formMethods,
  saving,
  genericError,
  catalogTypeMode,
  attributeDependencies,
  initialData,
  mode,
  requiredIntegration,
  onAddAttributeCallback,
}: CatalogTypeCreateEditFormProps) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [removedAttributes, setRemovedAttributes] = useState<
    CatalogTypeAttribute[]
  >([]);
  const isManualType = catalogTypeMode === CatalogTypeModeEnum.Manual;
  const isInternalType = catalogTypeMode === CatalogTypeModeEnum.Internal;

  const currentColor = formMethods.watch("color");
  const currentIcon = formMethods.watch("icon");
  const currentName = formMethods.watch("name");

  const handleWarning = (deletedAttributes: CatalogTypeAttribute[]) => {
    setRemovedAttributes(deletedAttributes);
    setShowWarningModal(true);
  };

  const handleCancelWarningModal = () => {
    setShowWarningModal(false);
    setRemovedAttributes([]);
  };

  const getDeletedDataAttributes = (data: CatalogTypeCreateEditFormState) => {
    if (mode === Mode.Edit) {
      return (
        initialData?.schema.attributes
          // 1. Exclude derived attributes
          .filter((attr) => !isDerivedAttribute(attr.mode))
          // 2. Exclude anything that's in our new schema
          .filter(
            (oldAttr) =>
              !find(
                data.schema?.data_attributes,
                (newAttr) => newAttr.id === oldAttr.id,
              ),
          ) ?? []
      );
    }
    return [];
  };

  const additionalTypes = formMethods.watch("parameterised_type_schemas") ?? [];
  const formState = useFormState({ control: formMethods.control });
  const versionMismatchError = formState?.errors?.schema?.version !== undefined;

  return (
    <>
      <Form.Root
        onSubmit={(data) => {
          const deletedDataAttributes = getDeletedDataAttributes(data);
          if (deletedDataAttributes.length) {
            handleWarning(deletedDataAttributes);
          } else {
            handleSubmit(data);
          }
        }}
        formMethods={formMethods}
        saving={saving}
        genericError={genericError}
      >
        <div className="flex flex-col gap-6">
          {versionMismatchError && (
            <Callout theme={CalloutTheme.Danger} className="p-4 border-none">
              Somone else has changed this type since you started editing it.
              Please refresh the page and try again.
            </Callout>
          )}
          <InputV2
            name="name"
            label="Name"
            formMethods={formMethods}
            required
            autoFocus
            placeholder='E.g. "Service"'
            disabled={!isManualType}
          />
          <TextareaV2
            name="description"
            label="Description"
            formMethods={formMethods}
            disabled={!isManualType}
            required
          />
          {isInternalType ? null : (
            <>
              <CatalogTypeCategoryPicker name="categories" />
              <CatalogTypeIconPicker
                onColorPick={(newColor) =>
                  formMethods.setValue("color", newColor)
                }
                currentColor={
                  currentColor as CatalogUpdateTypeRequestBodyColorEnum
                }
                onIconPick={(newIcon) => formMethods.setValue("icon", newIcon)}
                currentIcon={
                  currentIcon as CatalogUpdateTypeRequestBodyIconEnum
                }
              />
            </>
          )}
          <CatalogTypeSchemaSection
            attributeDependencies={attributeDependencies}
            requiredIntegration={requiredIntegration}
            formMethods={formMethods}
            onAddAttributeCallback={onAddAttributeCallback}
            catalogTypeInfo={{
              id: initialData?.id,
              parameter: initialData?.dynamic_resource_parameter,
              registryType: initialData?.registry_type,
              name: currentName,
              typeName: initialData?.type_name,
            }}
          />
          {!isManualType ? null : (
            <ContentBox className="p-4 flex flex-col bg-white">
              <CheckboxV2
                formMethods={formMethods}
                name="ranked"
                label={
                  <Txt className="font-semibold">
                    Are entries of this type ranked?
                  </Txt>
                }
              />
              <Txt grey className="ml-6 max-w-[85ch]">
                Entries will have a rank that allows them to be compared (e.g.
                &quot;If Service Tier is greater than Tier 2&hellip;&quot;).
              </Txt>
            </ContentBox>
          )}
          <ContentBox className="p-4 flex flex-col bg-white">
            <CheckboxV2
              formMethods={formMethods}
              name="use_name_as_identifier"
              label={
                <Txt className="font-semibold">Reference entries by name</Txt>
              }
            />
            <Txt grey className="ml-6 max-w-[75ch]">
              When enabled, you can reference entries by their name in addition
              to their external ID and aliases.
            </Txt>
          </ContentBox>
          {additionalTypes.length > 0 ? (
            // If additionalTypes is populated, its payloads will alaays have
            // registry_type/parameter/name - ts just doesn't know that yet.
            <Callout
              theme={CalloutTheme.Plain}
              className="p-4 border-none text-slate-700"
            >
              We&apos;ll create{" "}
              {joinSpansWithCommasAndConnectorWord(
                additionalTypes.map((t) => (
                  <Txt
                    inline
                    grey
                    key={
                      ((t.parameterised_resource_arguments
                        ?.registry_type as string) +
                        t.parameterised_resource_arguments?.parameter) as string
                    }
                  >
                    {t.parameterised_resource_arguments?.name}
                  </Txt>
                )),
              )}{" "}
              automatically when you save this type.
            </Callout>
          ) : null}
          <FloatingFooter analyticsTrackingId="edit-custom-field">
            <Button
              analyticsTrackingId="catalog-create-type"
              href={
                mode === Mode.Create
                  ? "/catalog"
                  : `/catalog/${
                      initialData ? slugForCatalogType(initialData) : ""
                    }`
              }
              theme={ButtonTheme.Secondary}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={saving}
              analyticsTrackingId="catalog-create-type-cancel"
              theme={ButtonTheme.Primary}
            >
              Save
            </Button>
          </FloatingFooter>
        </div>
        {showWarningModal && mode === Mode.Edit && (
          <WarningModal
            onClose={handleCancelWarningModal}
            onSubmit={() => {
              handleSubmit(formMethods.getValues());
            }}
            removedAttributes={removedAttributes}
            // We're only ever going to show the warning modal when initial data is defined
            // since we'll be in edit mode
            catalogType={initialData as unknown as CatalogType}
          />
        )}
      </Form.Root>
    </>
  );
};
