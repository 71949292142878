import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Input } from "@incident-ui/Input/Input";
import { Textarea } from "@incident-ui/Textarea/Textarea";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

type Props = {
  // A label to show above the input box
  label?: string;
  value: string;
  className?: string;
  lineCount?: number;
  disabled?: boolean;
};

/**
 * A component which provides an input box with a button that allows the
 * value to be copied to the clipboard. The button provides visual feedback
 * that the copy was successful.
 */
export const CopiableText = ({
  value,
  label,
  className,
  lineCount,
  disabled = false,
}: Props): React.ReactElement => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <div className={className}>
      <label
        htmlFor="copyable-input"
        className={tcx({
          "sr-only": !label,
          "font-medium text-content-primary text-sm mb-1": label,
        })}
      >
        {label ?? ""}
      </label>
      <div className={tcx("flex-between", { "mt-1": !!label })}>
        {lineCount === 1 ? (
          <Input id="copyable-input" readOnly value={value} className="mr-2" />
        ) : (
          <Textarea
            id="copyable-input"
            className={"mr-2 resize-none font-mono whitespace-pre"}
            rows={lineCount || value.split("\n").length}
            readOnly
            value={value}
          />
        )}
        <Button
          theme={ButtonTheme.Secondary}
          icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
          disabled={disabled}
          analyticsTrackingId="copy-token"
          type="button"
          title="Copy to clipboard"
          iconProps={{
            className: hasCopied ? "text-green-content" : "text-slate-700",
            size: IconSize.Large,
          }}
          onClick={() => copyTextToClipboard(value)}
          className="!p-2 rounded-2 hover:bg-slate-300 hover:bg-opacity-40"
        />
      </div>
    </div>
  );
};
