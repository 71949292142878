/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementRule,
    AnnouncementRuleFromJSON,
    AnnouncementRuleFromJSONTyped,
    AnnouncementRuleToJSON,
} from './AnnouncementRule';

/**
 * 
 * @export
 * @interface AnnouncementRulesUpdateResponseBody
 */
export interface AnnouncementRulesUpdateResponseBody {
    /**
     * 
     * @type {AnnouncementRule}
     * @memberof AnnouncementRulesUpdateResponseBody
     */
    rule: AnnouncementRule;
}

export function AnnouncementRulesUpdateResponseBodyFromJSON(json: any): AnnouncementRulesUpdateResponseBody {
    return AnnouncementRulesUpdateResponseBodyFromJSONTyped(json, false);
}

export function AnnouncementRulesUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementRulesUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rule': AnnouncementRuleFromJSON(json['rule']),
    };
}

export function AnnouncementRulesUpdateResponseBodyToJSON(value?: AnnouncementRulesUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rule': AnnouncementRuleToJSON(value.rule),
    };
}

