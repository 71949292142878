/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackTokenScope,
    SlackTokenScopeFromJSON,
    SlackTokenScopeFromJSONTyped,
    SlackTokenScopeToJSON,
} from './SlackTokenScope';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SlackInfo
 */
export interface SlackInfo {
    /**
     * URL for adding the app to the Slack Enterprise Grid.
     * @type {string}
     * @memberof SlackInfo
     */
    enterprise_install_url?: string;
    /**
     * URL for adding the app to the Slack workspace
     * @type {string}
     * @memberof SlackInfo
     */
    install_url: string;
    /**
     * Slack token scopes that are missing, requiring a reinstall
     * @type {Array<SlackTokenScope>}
     * @memberof SlackInfo
     */
    missing_token_scopes: Array<SlackTokenScope>;
    /**
     * A reason why we couldn't install our Slack app
     * @type {string}
     * @memberof SlackInfo
     */
    reconnection_reason: SlackInfoReconnectionReasonEnum;
    /**
     * IDs of Slack teams that this user is a member of (only populated if org is Slack Enterprise Grid installed)
     * @type {Array<string>}
     * @memberof SlackInfo
     */
    team_ids?: Array<string>;
    /**
     * A reason why the Slack user token we have isn't happy
     * @type {string}
     * @memberof SlackInfo
     */
    user_reconnection_reason: SlackInfoUserReconnectionReasonEnum;
    /**
     * What role does the current user have within Slack
     * @type {string}
     * @memberof SlackInfo
     */
    user_slack_role: SlackInfoUserSlackRoleEnum;
    /**
     * Unique identifier for user in their Slack workspace
     * @type {string}
     * @memberof SlackInfo
     */
    user_slack_user_id?: string;
    /**
     * A Slack interaction that requires a user token
     * @type {Array<string>}
     * @memberof SlackInfo
     */
    user_token_behaviours: Array<SlackInfoUserTokenBehavioursEnum>;
    /**
     * 
     * @type {User}
     * @memberof SlackInfo
     */
    user_token_owner?: User;
}

/**
* @export
* @enum {string}
*/
export enum SlackInfoReconnectionReasonEnum {
    Empty = '',
    TokenExpired = 'token_expired',
    MissingChannelCreatePermissions = 'missing_channel_create_permissions',
    AnnouncementChannelsAlreadyExist = 'announcement_channels_already_exist',
    InsufficientRole = 'insufficient_role',
    NoWorkspaces = 'no_workspaces'
}/**
* @export
* @enum {string}
*/
export enum SlackInfoUserReconnectionReasonEnum {
    Empty = '',
    TokenExpired = 'token_expired',
    MissingChannelCreatePermissions = 'missing_channel_create_permissions',
    AnnouncementChannelsAlreadyExist = 'announcement_channels_already_exist',
    InsufficientRole = 'insufficient_role',
    NoWorkspaces = 'no_workspaces'
}/**
* @export
* @enum {string}
*/
export enum SlackInfoUserSlackRoleEnum {
    Empty = '',
    Owner = 'owner',
    Admin = 'admin',
    Regular = 'regular'
}/**
* @export
* @enum {string}
*/
export enum SlackInfoUserTokenBehavioursEnum {
    CreatePublicChannel = 'create_public_channel',
    CreatePrivateChannel = 'create_private_channel',
    ArchivePublicChannel = 'archive_public_channel',
    ArchivePrivateChannel = 'archive_private_channel',
    UnarchivePublicChannel = 'unarchive_public_channel',
    UnarchivePrivateChannel = 'unarchive_private_channel',
    RemovePrivateChannelMember = 'remove_private_channel_member',
    AddChannelToMultipleWorkspaces = 'add_channel_to_multiple_workspaces',
    ManageUserGroup = 'manage_user_group'
}

export function SlackInfoFromJSON(json: any): SlackInfo {
    return SlackInfoFromJSONTyped(json, false);
}

export function SlackInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enterprise_install_url': !exists(json, 'enterprise_install_url') ? undefined : json['enterprise_install_url'],
        'install_url': json['install_url'],
        'missing_token_scopes': ((json['missing_token_scopes'] as Array<any>).map(SlackTokenScopeFromJSON)),
        'reconnection_reason': json['reconnection_reason'],
        'team_ids': !exists(json, 'team_ids') ? undefined : json['team_ids'],
        'user_reconnection_reason': json['user_reconnection_reason'],
        'user_slack_role': json['user_slack_role'],
        'user_slack_user_id': !exists(json, 'user_slack_user_id') ? undefined : json['user_slack_user_id'],
        'user_token_behaviours': json['user_token_behaviours'],
        'user_token_owner': !exists(json, 'user_token_owner') ? undefined : UserFromJSON(json['user_token_owner']),
    };
}

export function SlackInfoToJSON(value?: SlackInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enterprise_install_url': value.enterprise_install_url,
        'install_url': value.install_url,
        'missing_token_scopes': ((value.missing_token_scopes as Array<any>).map(SlackTokenScopeToJSON)),
        'reconnection_reason': value.reconnection_reason,
        'team_ids': value.team_ids,
        'user_reconnection_reason': value.user_reconnection_reason,
        'user_slack_role': value.user_slack_role,
        'user_slack_user_id': value.user_slack_user_id,
        'user_token_behaviours': value.user_token_behaviours,
        'user_token_owner': UserToJSON(value.user_token_owner),
    };
}

