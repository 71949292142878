/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {Actor}
     * @memberof Notification
     */
    actor: Actor;
    /**
     * 
     * @type {TextNode}
     * @memberof Notification
     */
    content?: TextNode;
    /**
     * 
     * @type {TextNode}
     * @memberof Notification
     */
    description: TextNode;
    /**
     * Unique identifier for this notification
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * ID of the incident this notification affects, if it is incident specific
     * @type {string}
     * @memberof Notification
     */
    incident_id?: string;
    /**
     * Whether the incident that this notification relates to has been muted by the user
     * @type {boolean}
     * @memberof Notification
     */
    incident_is_muted?: boolean;
    /**
     * Whether the notification was read by the user
     * @type {boolean}
     * @memberof Notification
     */
    is_read: boolean;
    /**
     * When the action that triggered this notification occurred at
     * @type {Date}
     * @memberof Notification
     */
    occurred_at: Date;
    /**
     * Link to where to view the notification content
     * @type {string}
     * @memberof Notification
     */
    url: string;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'content': !exists(json, 'content') ? undefined : TextNodeFromJSON(json['content']),
        'description': TextNodeFromJSON(json['description']),
        'id': json['id'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'incident_is_muted': !exists(json, 'incident_is_muted') ? undefined : json['incident_is_muted'],
        'is_read': json['is_read'],
        'occurred_at': (new Date(json['occurred_at'])),
        'url': json['url'],
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'content': TextNodeToJSON(value.content),
        'description': TextNodeToJSON(value.description),
        'id': value.id,
        'incident_id': value.incident_id,
        'incident_is_muted': value.incident_is_muted,
        'is_read': value.is_read,
        'occurred_at': (value.occurred_at.toISOString()),
        'url': value.url,
    };
}

