import type { SVGProps } from "react";
import * as React from "react";
const SvgFilter = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.75 3a.748.748 0 0 0-.598 1.203L8 10.602v6.648a.751.751 0 0 0 1.111.657l2.5-1.375a.75.75 0 0 0 .39-.657v-5.273l4.846-6.399A.75.75 0 0 0 16.25 3H3.75Z"
    />
  </svg>
);
export default SvgFilter;
