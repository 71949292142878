/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoCallSettings,
    AutoCallSettingsFromJSON,
    AutoCallSettingsFromJSONTyped,
    AutoCallSettingsToJSON,
} from './AutoCallSettings';

/**
 * 
 * @export
 * @interface IncidentCallSettingsUpdateAutoCallRequestBody
 */
export interface IncidentCallSettingsUpdateAutoCallRequestBody {
    /**
     * 
     * @type {AutoCallSettings}
     * @memberof IncidentCallSettingsUpdateAutoCallRequestBody
     */
    auto_call?: AutoCallSettings;
}

export function IncidentCallSettingsUpdateAutoCallRequestBodyFromJSON(json: any): IncidentCallSettingsUpdateAutoCallRequestBody {
    return IncidentCallSettingsUpdateAutoCallRequestBodyFromJSONTyped(json, false);
}

export function IncidentCallSettingsUpdateAutoCallRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallSettingsUpdateAutoCallRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_call': !exists(json, 'auto_call') ? undefined : AutoCallSettingsFromJSON(json['auto_call']),
    };
}

export function IncidentCallSettingsUpdateAutoCallRequestBodyToJSON(value?: IncidentCallSettingsUpdateAutoCallRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_call': AutoCallSettingsToJSON(value.auto_call),
    };
}

