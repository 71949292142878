/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';
import {
    PlaceholderTimeslot,
    PlaceholderTimeslotFromJSON,
    PlaceholderTimeslotFromJSONTyped,
    PlaceholderTimeslotToJSON,
} from './PlaceholderTimeslot';

/**
 * 
 * @export
 * @interface DebriefsSuggestTimeslotsResponseBody
 */
export interface DebriefsSuggestTimeslotsResponseBody {
    /**
     * ID of the incident we're creating the debrief for
     * @type {string}
     * @memberof DebriefsSuggestTimeslotsResponseBody
     */
    incident_id: string;
    /**
     * 
     * @type {PaginationMeta}
     * @memberof DebriefsSuggestTimeslotsResponseBody
     */
    pagination_meta?: PaginationMeta;
    /**
     * 
     * @type {Array<PlaceholderTimeslot>}
     * @memberof DebriefsSuggestTimeslotsResponseBody
     */
    timeslots: Array<PlaceholderTimeslot>;
}

export function DebriefsSuggestTimeslotsResponseBodyFromJSON(json: any): DebriefsSuggestTimeslotsResponseBody {
    return DebriefsSuggestTimeslotsResponseBodyFromJSONTyped(json, false);
}

export function DebriefsSuggestTimeslotsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsSuggestTimeslotsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'pagination_meta': !exists(json, 'pagination_meta') ? undefined : PaginationMetaFromJSON(json['pagination_meta']),
        'timeslots': ((json['timeslots'] as Array<any>).map(PlaceholderTimeslotFromJSON)),
    };
}

export function DebriefsSuggestTimeslotsResponseBodyToJSON(value?: DebriefsSuggestTimeslotsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
        'timeslots': ((value.timeslots as Array<any>).map(PlaceholderTimeslotToJSON)),
    };
}

