/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalGoogleCalendarEventAttendee,
    ExternalGoogleCalendarEventAttendeeFromJSON,
    ExternalGoogleCalendarEventAttendeeFromJSONTyped,
    ExternalGoogleCalendarEventAttendeeToJSON,
} from './ExternalGoogleCalendarEventAttendee';

/**
 * 
 * @export
 * @interface ExternalGoogleCalendarEvent
 */
export interface ExternalGoogleCalendarEvent {
    /**
     * Attendees of the event
     * @type {Array<ExternalGoogleCalendarEventAttendee>}
     * @memberof ExternalGoogleCalendarEvent
     */
    attendees?: Array<ExternalGoogleCalendarEventAttendee>;
    /**
     * Description on the event
     * @type {string}
     * @memberof ExternalGoogleCalendarEvent
     */
    description: string;
    /**
     * End of the event
     * @type {Date}
     * @memberof ExternalGoogleCalendarEvent
     */
    end: Date;
    /**
     * Unique identifier for this resource
     * @type {string}
     * @memberof ExternalGoogleCalendarEvent
     */
    id: string;
    /**
     * ID of the meeting in the meeting provider
     * @type {string}
     * @memberof ExternalGoogleCalendarEvent
     */
    meeting_id: string;
    /**
     * Which meeting provider is used for this event
     * @type {string}
     * @memberof ExternalGoogleCalendarEvent
     */
    meeting_provider: ExternalGoogleCalendarEventMeetingProviderEnum;
    /**
     * Email address of the person that created the event.
     * @type {string}
     * @memberof ExternalGoogleCalendarEvent
     */
    organiser_email: string;
    /**
     * Start of the event
     * @type {Date}
     * @memberof ExternalGoogleCalendarEvent
     */
    start: Date;
    /**
     * Status of the event
     * @type {string}
     * @memberof ExternalGoogleCalendarEvent
     */
    status: ExternalGoogleCalendarEventStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum ExternalGoogleCalendarEventMeetingProviderEnum {
    GoogleMeet = 'google_meet',
    Other = 'other'
}/**
* @export
* @enum {string}
*/
export enum ExternalGoogleCalendarEventStatusEnum {
    Confirmed = 'confirmed',
    Cancelled = 'cancelled'
}

export function ExternalGoogleCalendarEventFromJSON(json: any): ExternalGoogleCalendarEvent {
    return ExternalGoogleCalendarEventFromJSONTyped(json, false);
}

export function ExternalGoogleCalendarEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalGoogleCalendarEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attendees': !exists(json, 'attendees') ? undefined : ((json['attendees'] as Array<any>).map(ExternalGoogleCalendarEventAttendeeFromJSON)),
        'description': json['description'],
        'end': (new Date(json['end'])),
        'id': json['id'],
        'meeting_id': json['meeting_id'],
        'meeting_provider': json['meeting_provider'],
        'organiser_email': json['organiser_email'],
        'start': (new Date(json['start'])),
        'status': json['status'],
    };
}

export function ExternalGoogleCalendarEventToJSON(value?: ExternalGoogleCalendarEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attendees': value.attendees === undefined ? undefined : ((value.attendees as Array<any>).map(ExternalGoogleCalendarEventAttendeeToJSON)),
        'description': value.description,
        'end': (value.end.toISOString()),
        'id': value.id,
        'meeting_id': value.meeting_id,
        'meeting_provider': value.meeting_provider,
        'organiser_email': value.organiser_email,
        'start': (value.start.toISOString()),
        'status': value.status,
    };
}

