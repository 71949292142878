import { AddNewButton, Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { useCallback, useEffect } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import {
  ArrayPath,
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { FormLabel } from "src/components/@shared/forms/v2/helpers";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { DragHandle } from "src/components/@shared/settings";
import { v4 as uuidv4 } from "uuid";

export type TextEditorRow = { value: string; key: string };

export const MultiTextInput = <
  FormType extends FieldValues,
  TPath extends ArrayPath<FormType> & Path<FormType>,
>({
  name,
  label,
  placeholder,
  disabled,
  required,
  canReorderItems = true,
}: {
  name: TPath;
  label: React.ReactNode;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  canReorderItems?: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext<FormType>();

  // We do something a bit weird here: we fake the type that we
  // pass to useFieldArray so that it knows that fields is an array
  // of TextEditorRow objects.
  type dummyFormType = {
    bindings: TextEditorRow[];
  };
  const { append, fields, remove, move } = useFieldArray<
    dummyFormType,
    "bindings",
    "key"
  >({
    // @ts-expect-error this doesn't match our fake type, of course
    control: formMethods.control,
    // @ts-expect-error this doesn't match our fake type, of course
    name: name,
    keyName: "key",
  });

  const onAdd = useCallback(() => {
    append({ key: uuidv4(), value: "" });
  }, [append]);

  useEffect(() => {
    // If you've ever got an empty list, add a new row (this stops the list looking
    // really broken at the start). Ideally I think we'd handle this by defining a 'default'
    // binding depending on the param type and injecting that into the form state, but
    // that would take a bit of thinking to figure out I think.
    //
    // NOTE: It is important to wrap this in a useEffect, otherwise we'll reliably add more
    // than one row, which we don't want to do.
    if (fields.length === 0) {
      onAdd();
    }
  }, [fields, onAdd]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      move(fromIndex, toIndex);
    },
    [move],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="customFields">
        {(provided) => {
          return (
            <>
              <FormLabel htmlFor={name} className={""} required={required}>
                {label}
              </FormLabel>
              <ul
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-2 mb-4"
              >
                {fields.map((bindingValue, index) => {
                  return (
                    <Draggable
                      key={bindingValue.key}
                      draggableId={bindingValue.key}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided, snapshot) => {
                        const result = (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="flex flex-row items-center">
                              {canReorderItems && (
                                <DragHandle
                                  className="mr-2 flex-0"
                                  {...provided.dragHandleProps}
                                  disabled={disabled}
                                />
                              )}

                              <Row
                                name={`${name}.${index}`}
                                placeholder={placeholder}
                                disabled={disabled}
                                onDelete={() => {
                                  remove(index);
                                }}
                              />
                            </div>
                          </div>
                        );

                        if (snapshot.isDragging) {
                          return ReactDOM.createPortal(result, document.body);
                        }

                        return result;
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
              <div className="space-x-4">
                <AddNewButton
                  analyticsTrackingId="engine-multi-text-input-add-new-button"
                  onClick={onAdd}
                  title="Add another"
                  disabled={disabled}
                />
              </div>
            </>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

const Row = ({
  name,
  placeholder,
  onDelete,
  disabled,
}: {
  name: string;
  placeholder: string;
  onDelete: () => void;
  disabled?: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext();
  return (
    <div className="flex space-x-2 min-w-0 grow text-sm">
      <InputV2
        name={`${name}.value`}
        placeholder={placeholder}
        disabled={disabled}
        formMethods={formMethods}
        className="grow"
      />
      <Button
        analyticsTrackingId={null}
        title="Remove"
        onClick={onDelete}
        icon={IconEnum.Delete}
        theme={ButtonTheme.Naked}
        disabled={disabled}
        className="ml-1 shrink-0 grow-0"
      />
    </div>
  );
};
