import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { TutorialPrompt } from "@incident-shared/tutorial";
import { ButtonTheme, ContentBox, IconEnum } from "@incident-ui";
import React from "react";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";

export function StartTutorialRoute(): React.ReactElement {
  const navigate = useOrgAwareNavigate();
  const commsPlatform = usePrimaryCommsPlatform();

  if (commsPlatform && commsPlatform !== CommsPlatform.Slack) {
    // Tutorials are only supported in Slack
    navigate("/dashboard");
  }

  return (
    <PageWrapper
      title="Get started with a tutorial"
      width={PageWidth.Narrow}
      icon={IconEnum.MortarBoard}
    >
      <ContentBox className="p-4 gap-4 rounded-2 bg-surface-secondary flex flex-col items-center text-center">
        {"You can try out incident.io with a tutorial. The channel will be " +
          "public so you can invite anyone else in, but we won't announce it anywhere, " +
          "and we'll disable most automations to keep things simple."}
        <TutorialPrompt alwaysShowButton buttonTheme={ButtonTheme.Primary} />
      </ContentBox>
    </PageWrapper>
  );
}
