/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentsChangeVisibilityRequestBody
 */
export interface IncidentsChangeVisibilityRequestBody {
    /**
     * Whether to make the incident private or not
     * @type {string}
     * @memberof IncidentsChangeVisibilityRequestBody
     */
    visibility: IncidentsChangeVisibilityRequestBodyVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsChangeVisibilityRequestBodyVisibilityEnum {
    Public = 'public',
    Private = 'private'
}

export function IncidentsChangeVisibilityRequestBodyFromJSON(json: any): IncidentsChangeVisibilityRequestBody {
    return IncidentsChangeVisibilityRequestBodyFromJSONTyped(json, false);
}

export function IncidentsChangeVisibilityRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsChangeVisibilityRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visibility': json['visibility'],
    };
}

export function IncidentsChangeVisibilityRequestBodyToJSON(value?: IncidentsChangeVisibilityRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visibility': value.visibility,
    };
}

