/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallRoute,
    CallRouteFromJSON,
    CallRouteFromJSONTyped,
    CallRouteToJSON,
} from './CallRoute';

/**
 * 
 * @export
 * @interface CallRoutingListResponseBody
 */
export interface CallRoutingListResponseBody {
    /**
     * 
     * @type {Array<CallRoute>}
     * @memberof CallRoutingListResponseBody
     */
    call_routes: Array<CallRoute>;
}

export function CallRoutingListResponseBodyFromJSON(json: any): CallRoutingListResponseBody {
    return CallRoutingListResponseBodyFromJSONTyped(json, false);
}

export function CallRoutingListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallRoutingListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_routes': ((json['call_routes'] as Array<any>).map(CallRouteFromJSON)),
    };
}

export function CallRoutingListResponseBodyToJSON(value?: CallRoutingListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_routes': ((value.call_routes as Array<any>).map(CallRouteToJSON)),
    };
}

