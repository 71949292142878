import { useInitialiseFilters } from "@incident-shared/filters";
import { Loader } from "@incident-ui";
import React from "react";

import { IncidentsListContextProvider } from "../../components/@shared/incidents/IncidentsListContext";
import { IncidentListPage } from "../../components/legacy/incidents-list/IncidentListPage";
import {
  SavedViewsContext,
  SavedViewsProvider,
  useSavedViews,
} from "../../components/saved-views/SavedViewContext";
import {
  IncidentFilterFieldsListContextEnum,
  SavedViewsListContextEnum,
} from "../../contexts/ClientContext";
import { useQueryParams } from "../../utils/query-params";

export const IncidentsListRoute = (): React.ReactElement => {
  return (
    <SavedViewsProvider context={SavedViewsListContextEnum.Incidents}>
      <SavedViewsContext.Consumer>
        {({ savedViews }) =>
          savedViews ? <IncidentListPageWrapper /> : <Loader />
        }
      </SavedViewsContext.Consumer>
    </SavedViewsProvider>
  );
};

const IncidentListPageWrapper = (): React.ReactElement => {
  const { selectedSavedView } = useSavedViews();
  const urlParams = useQueryParams();
  const {
    loading,
    availableIncidentFilterFields: availableFilterFields,
    error,
  } = useInitialiseFilters(
    urlParams.toString(),
    IncidentFilterFieldsListContextEnum.Incidents,
  );

  if (error) {
    throw error;
  }

  // We should _always_ have at least the full text search filter field
  // available, so if we're at zero fields must still be loading since if we've
  // errored we have already returned above.
  if (loading || availableFilterFields.length === 0) {
    return <Loader />;
  }

  return (
    <IncidentsListContextProvider>
      <IncidentListPage
        key={selectedSavedView?.id ?? "no-saved-view"}
        availableFilterFields={availableFilterFields}
      />
    </IncidentsListContextProvider>
  );
};
