/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsGrafanaInstallRequestBody
 */
export interface IntegrationsGrafanaInstallRequestBody {
    /**
     * The API key to use for the Grafana integration
     * @type {string}
     * @memberof IntegrationsGrafanaInstallRequestBody
     */
    api_key: string;
    /**
     * The URL of the Grafana instance
     * @type {string}
     * @memberof IntegrationsGrafanaInstallRequestBody
     */
    api_url: string;
}

export function IntegrationsGrafanaInstallRequestBodyFromJSON(json: any): IntegrationsGrafanaInstallRequestBody {
    return IntegrationsGrafanaInstallRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsGrafanaInstallRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGrafanaInstallRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_key': json['api_key'],
        'api_url': json['api_url'],
    };
}

export function IntegrationsGrafanaInstallRequestBodyToJSON(value?: IntegrationsGrafanaInstallRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_key': value.api_key,
        'api_url': value.api_url,
    };
}

