import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceChronosphereSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <div className="text-content-primary text-sm-normal">
        Create a Notifier in your Chronosphere console by clicking the
        &apos;Create notifier&apos; button on the Notifiers page, which can be
        found by clicking the Alerts tab on the left-hand side of the
        Chronosphere console.
      </div>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Select &apos;Webhook&apos; and paste the following URL in the field
            provided
          </>
        }
        blocks={[
          {
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/chronosphere/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <div className="text-content-primary text-sm-normal">
        Next, go to the Notification Policies page which can be found in the
        Alerts tab.
      </div>
      <div className="text-content-primary text-sm-normal">
        Create a new Notification Policy and add your new Notifier as critical
        or warning alert notifiers.
      </div>
      <div className="text-content-primary text-sm-normal">
        Finally, head over to the Monitors page (also inside the Alerts tab) and
        choose the monitor you&rsquo;d like to connect to this alert source.
      </div>
      <div className="text-content-primary text-sm-normal">
        Click the 3 dots in the right hand corner and select &apos;Edit
        Monitor&apos;.
      </div>
      <div className="text-content-primary text-sm-normal">
        Under the &apos;Notification Policy&apos; section, select the policy
        created in the previous step and save your changes.
      </div>
    </SetupInfoNumberedList>
  );
};
