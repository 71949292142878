import { DateTime } from "luxon";

export const calculateEntryStyles = ({
  xScale,
  end_at,
  start_at,
  timelineStartPoint,
  timelineEndpoint,
}: {
  start_at: Date;
  end_at: Date;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  xScale: d3.ScaleTime<number, number>;
}) => {
  const startAt = DateTime.fromJSDate(start_at);
  const endAt = DateTime.fromJSDate(end_at);

  if (endAt < timelineStartPoint || startAt > timelineEndpoint) {
    return null;
  }

  let left = xScale(start_at);
  let width = xScale(end_at) - left;
  let hasClippedStart = false;
  let hasClippedEnd = false;
  if (startAt < timelineStartPoint) {
    left = 0;
    width = xScale(end_at) - xScale(timelineStartPoint.toJSDate());
    hasClippedStart = true;
  }
  if (endAt > timelineEndpoint) {
    width = xScale(timelineEndpoint.toJSDate()) - left;
    hasClippedEnd = true;
  }

  // Don't render entries that are so tiny they're just border
  if (width < 1) {
    return null;
  }

  // Don't overlap the border
  left = Math.max(left, 1);

  return {
    width,
    left,
    hasClippedStart,
    hasClippedEnd,
  };
};
