import { SelectOption } from "@incident-ui/Select/types";
import { useClient } from "src/contexts/ClientContext";

export const useOrganisationTypeahead = () => {
  const apiClient = useClient();

  const loadOptions = async (query: string): Promise<SelectOption[]> => {
    const { options } = await apiClient.staffTypeaheadOrganisations({ query });
    return options;
  };

  const hydrateOptions = async (
    idList: string | string[],
  ): Promise<SelectOption[]> => {
    const idListArray = typeof idList === "string" ? [idList] : idList;

    const { options } = await apiClient.staffTypeaheadOrganisations({
      idList: idListArray,
    });
    return options;
  };

  return { loadOptions, hydrateOptions };
};
