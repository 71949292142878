import type { SVGProps } from "react";
import * as React from "react";
const SvgPause = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.518 3.5H5.125c-.897 0-1.625.728-1.625 1.625v9.75c0 .898.728 1.625 1.625 1.625h1.393c.897 0 1.625-.727 1.625-1.625v-9.75c0-.897-.728-1.625-1.625-1.625ZM14.875 3.5h-1.393c-.897 0-1.625.728-1.625 1.625v9.75c0 .898.728 1.625 1.625 1.625h1.393c.898 0 1.625-.727 1.625-1.625v-9.75c0-.897-.727-1.625-1.625-1.625Z"
    />
  </svg>
);
export default SvgPause;
