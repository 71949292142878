import { Button, ButtonTheme } from "@incident-ui";
import { SpinnerTheme } from "@incident-ui/Spinner/Spinner";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import PhonesImage from "./on-call-phones.png";

export const BillingOnCallUpsellCallout = ({
  canRequestWithoutUpgrade,
}: {
  canRequestWithoutUpgrade: boolean;
}) => {
  const { trigger: requestAccess, isMutating: isRequestingAccess } =
    useAPIMutation("identitySelf", undefined, (apiClient) =>
      apiClient.onboardingRequestOnCall(),
    );

  const { identity } = useIdentity();
  const { hasOnCall } = useProductAccess();

  if (identity && hasOnCall) {
    // If the billing gate isn't zero, you already have access to on-call
    return null;
  }

  const isInWaitList = identity?.onboarding_information.requested_on_call;
  const canRequestAccess = !isInWaitList && canRequestWithoutUpgrade;

  return (
    <div className="p-8 gap-8 bg-slate-950 rounded-2 flex flex-col xl:flex-row xl:items-stretch">
      <Button
        analyticsTrackingId={"billing-settings-on-call-cta"}
        theme={ButtonTheme.Unstyled}
        href="https://incident.io/on-call?ref=video"
        openInNewTab
        className="flex-none max-h-[160px]"
      >
        <img src={PhonesImage} />
      </Button>
      <div className="flex flex-col justify-between grow xl:max-h-[160px] gap-4">
        <div className="space-y-1">
          {isInWaitList ? (
            <>
              <h2 className="text-white text-2xl leading-8 font-semibold">
                On-call will be enabled for your account very soon
              </h2>
              <p className="text-slate-400 text-sm leading-6 2xl:w-2/3">
                One of our team will be in touch once it&lsquo;s ready for you
                to take for a spin. We can&lsquo;t wait to hear what you think!
              </p>
            </>
          ) : canRequestWithoutUpgrade ? (
            <>
              <h2 className="text-white text-2xl leading-8 font-semibold">
                Ready to get started with{" "}
                <span className="text-alarmalade-600">On-call</span>?
              </h2>
              <p className="text-slate-400 text-sm leading-6 2xl:w-2/3">
                We&rsquo;re delighted to have launched incident.io On-call and
                are currently rolling it out to customers. Click &ldquo;Get
                On-call&rdquo; and we&lsquo;ll be in touch once it&lsquo;s been
                enabled on your account. We can&lsquo;t wait to hear what you
                think!
              </p>
            </>
          ) : (
            <>
              <h2 className="text-white text-2xl leading-8 font-semibold">
                Upgrade to enable{" "}
                <span className="text-alarmalade-600">On-call</span>
              </h2>
              <p className="text-slate-400 text-sm leading-6 2xl:w-2/3">
                On-call is only available on our latest pricing plans. Please
                upgrade to any of the plans below and we’d be happy to get you
                set up right away!
              </p>
            </>
          )}
        </div>
        <div className="flex gap-2">
          {canRequestAccess && (
            <Button
              analyticsTrackingId="billing-settings-on-call-cta"
              onClick={() => requestAccess({})}
              theme={ButtonTheme.Unstyled}
              className="bg-alarmalade-600 text-white hover:brightness-95 transition rounded-[6px] text-sm px-4 py-2 inline max-h-[160px]"
              loading={isRequestingAccess}
              spinnerTheme={SpinnerTheme.White}
            >
              Get On-call
            </Button>
          )}
          <Button
            theme={ButtonTheme.Unstyled}
            href="https://incident.io/on-call"
            analyticsTrackingId="billing-settings-on-call-learn-more"
            className={tcx(
              "transition rounded-[6px] text-white text-sm px-4 py-2",
              canRequestAccess
                ? "hover:bg-surface-invert"
                : "bg-surface-invert hover:bg-surface-invert",
            )}
            openInNewTab
          >
            Learn more
          </Button>
        </div>
      </div>
    </div>
  );
};
