import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { Badge, BadgeSize, BadgeTheme } from "./Badge";

export enum GenericStatusBadgeEnum {
  Outstanding = "Outstanding",
  Completed = "Completed",
  NotDoing = "Not doing",
  Violation = "Violation",
  Error = "Error",
  Warning = "Warning",
}

const ThemeFor: {
  [key in GenericStatusBadgeEnum]: {
    badge: string;
    icon: IconEnum;
    smallIcon?: boolean;
  };
} = {
  [GenericStatusBadgeEnum.Outstanding]: {
    badge: "text-slate-900",
    icon: IconEnum.DottedCircle,
  },
  [GenericStatusBadgeEnum.Completed]: {
    badge: "text-green-content",
    icon: IconEnum.Success,
  },
  [GenericStatusBadgeEnum.NotDoing]: {
    badge: "bg-surface-secondary text-slate-600",
    icon: IconEnum.Close,
    smallIcon: true,
  },
  [GenericStatusBadgeEnum.Violation]: {
    badge: "text-white bg-brand",
    icon: IconEnum.ExclamationMark,
    smallIcon: false,
  },
  [GenericStatusBadgeEnum.Warning]: {
    badge: "bg-amber-300 text-content-primary",
    icon: IconEnum.WarningCircle,
    smallIcon: true,
  },
  [GenericStatusBadgeEnum.Error]: {
    badge: "bg-alarmalade-100 text-alarmalade-600",
    icon: IconEnum.Close,
    smallIcon: true,
  },
};

export type GenericStatusBadgeProps = {
  className?: string;
  status: GenericStatusBadgeEnum;
  withLabel?: boolean;
  label?: string;
  tooltipSide?: "left" | "right" | "top" | "bottom";
};

export const GenericStatusBadge = React.forwardRef<
  HTMLDivElement,
  GenericStatusBadgeProps
>(
  (
    {
      className,
      status,
      withLabel,
      label,
      tooltipSide,
      ...rest
    }: GenericStatusBadgeProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const theme = ThemeFor[status];

    const showTooltip = withLabel || !!label;
    return (
      <Tooltip
        side={tooltipSide}
        content={showTooltip ? label || statusEnumToLabel(status) : undefined}
      >
        <Badge
          ref={ref}
          size={BadgeSize.ExtraSmall}
          theme={BadgeTheme.Unstyled}
          className={tcx(
            "rounded-full h-5 w-5 shrink-0 p-0 justify-center items-center",
            theme.badge,
            className,
          )}
          {...rest}
        >
          <Icon
            id={theme.icon}
            size={theme.smallIcon ? IconSize.Small : IconSize.Medium}
          />
        </Badge>
      </Tooltip>
    );
  },
);

GenericStatusBadge.displayName = "GenericStatusBadge";

export const statusEnumToLabel = (value: GenericStatusBadgeEnum): string => {
  switch (value) {
    case GenericStatusBadgeEnum.Outstanding:
      return "Open";
    case GenericStatusBadgeEnum.Completed:
      return "Completed";
    case GenericStatusBadgeEnum.NotDoing:
      return "Not doing";
    default:
      throw new Error("unreachable: unexpected status");
  }
};
