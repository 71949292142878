import type { SVGProps } from "react";
import * as React from "react";
const SvgVanta = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#vanta_svg__a)">
      <mask
        id="vanta_svg__b"
        width={16}
        height={16}
        x={2}
        y={2}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M18 2H2v16h16V2Z" />
      </mask>
      <g mask="url(#vanta_svg__b)">
        <path fill="#AC55FF" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
        <mask
          id="vanta_svg__c"
          width={16}
          height={16}
          x={2}
          y={2}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path fill="#AC55FF" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
        </mask>
        <g mask="url(#vanta_svg__c)">
          <path
            fill="#240642"
            d="M10.428 18.577c2.831 0 4.295-.878 4.737-1.758.025-.05-.023-.108-.077-.09-.498.165-.87-.141-.912-.495 0 0-.14-1.499-.638-1.645.03-.06.15-.54-.222-.982a.821.821 0 0 0-.058-.675.989.989 0 0 0 .195-.402c.007-.034.007-.064.035-.096.175-.145.368-.266.485-.479.212-.384.07-.824-.187-1.148-.025-.036-.046-.053-.048-.145a.833.833 0 0 0-.112-.452.915.915 0 0 0-.159-.18c-.247-.23-.542-.4-.825-.583-.039-.025-.084-.059-.101-.104-.09-.332-.32-.589-.607-.659-.006-.222-.24-.56-.595-.56-.109 0-.198.06-.254-.042-.13-.246-.388-.292-.523-.298-.023-.001-.03-.032-.009-.041.043-.021.109-.05.206-.087.487-.233.286-1.562.086-2.263-.093-.262-.33-1.006-.91-1.055a.33.33 0 0 0-.119.008c-.111.032-.194.122-.252.246-.192.385-.24 1.426-.31 1.87-.1.635-.19 1.315-.507 1.594-.134-.355-.33-.52-.435-.513-.093.022.032.202-.021.468-.037.153-.122.295-.266.295-.423 0-.803-2.113-.917-3.383a1.094 1.094 0 0 0-.012-.099c-.056-.307-.217-.488-.388-.488-.086 0-.397-.031-.752.528-.245.436-.4.892-.415 1.74 0 .856.382 1.428.536 1.71.632 1.038.716 1.397.597 2.03-.122.614.249.96.249.96-.052.08-.122.298-.138.537-.01.152.021.424.094.593-.16.344-.156.617-.156.789 0 .399-.174.561-.4.632.061.217.467.334.702.102-.07.683-.198 1.298-.376 2.06-.112.521-.093.814-.009 1.061.038.112.098.214.17.307.866 1.105 2.49 1.192 3.618 1.192Zm-.001-1.491a1.41 1.41 0 0 1-.817-.442c-.568.188-1.158-.29-.979-.92.029-.083.152-.053.14.033a.546.546 0 0 0 .05.323c.127.29.505.368.737.166.086-.076.21-.047.268.047.507.688 1.56.559 1.913-.214a1.26 1.26 0 0 0 .105-.341c.014-.084.14-.076.144.009.028.832-.75 1.469-1.561 1.339Z"
          />
          <path
            fill="#fff"
            d="M13.61 11.144c-.054 0-.112.076-.127.194-.033.166-.022.473-.17.473-.102 0-.111-.206-.284-.396-.178-.184-.434-.202-.599-.335a.29.29 0 0 1-.14-.275s.02-.214.308-.175c.137.015.359.048.496.064.245.027.305-.155.312-.217.016-.145-.138-.266-.236-.35a3.073 3.073 0 0 0-.474-.34c-.349-.222-.97-.595-1.319-.17-.124.168-.179.36-.218.52-.072.293-.224.405-.332.584a1.929 1.929 0 0 0-.298.934c-.001.478.294.945.765 1.237.39.205.59.205.747.164.029-.01.092-.054.049-.126-.008-.016-.03-.05-.043-.072-.045-.082.016-.157.107-.086.06.046.12.093.19.121a.301.301 0 0 0 .187.018c.071-.024.108-.07.112-.13a.16.16 0 0 0-.082-.156 3.002 3.002 0 0 1-.337-.195 1.6 1.6 0 0 1-.21-.183c-.103-.11-.133-.218-.197-.218a.043.043 0 0 0-.029.014c-.04.044-.04.115-.043.156-.001.03.004.075-.038.075-.105 0-.104-.217-.104-.276 0-.08.03-.29.154-.29.039 0 .091.052.112.072.274.305.594.542.999.542.31 0 .568-.175.731-.339a.692.692 0 0 0 .217-.454c.003-.104-.047-.385-.206-.385Zm-1.775.284c-.038.02-.083-.005-.124-.018a.268.268 0 0 0-.243.054.454.454 0 0 0-.112.138c-.031.057-.037.133-.105.133-.056 0-.084-.077-.09-.11a.428.428 0 0 1 .206-.447.375.375 0 0 1 .47.105.152.152 0 0 1 .031.075c.002.027-.01.057-.033.07ZM9.793 9.45a.57.57 0 0 0-.396.172.78.78 0 0 0-.178.558c-.002.044-.06.06-.084.022a.635.635 0 0 1-.063-.476c.082-.264.312-.483.623-.483.407 0 .51.267.53.35.01.034-.032.06-.059.035-.067-.063-.171-.179-.373-.179Z"
          />
          <path
            fill="#fff"
            d="M9.827 10.046a.162.162 0 0 1-.183-.152.165.165 0 0 1 .153-.183.166.166 0 0 1 .185.153.163.163 0 0 1-.155.182ZM6.633 8.224c-.144 0-.615-.765-.615-1.845 0-.484.125-1.001.35-1.001.14 0 .171.296.178.384.073.928-.047 1.557.128 2.398.012.049-.018.064-.04.064Z"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="vanta_svg__a">
        <path fill="#fff" d="M2 2h16v16H2z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgVanta;
