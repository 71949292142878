import type { SVGProps } from "react";
import * as React from "react";
const SvgDatabase = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2C6.521 2 3 3.03 3 5v9.5c0 1.97 3.521 3 7 3s7-1.03 7-3V5c0-1.97-3.521-3-7-3Zm5.5 7.75c0 .436-1.927 1.5-5.5 1.5s-5.5-1.064-5.5-1.5V6.921C5.849 7.632 7.929 8 10 8s4.151-.368 5.5-1.079V9.75ZM10 16c-3.573 0-5.5-1.064-5.5-1.5v-2.829c1.349.711 3.429 1.079 5.5 1.079s4.151-.368 5.5-1.079V14.5c0 .436-1.927 1.5-5.5 1.5Z"
    />
  </svg>
);
export default SvgDatabase;
