import { PostIncidentTask, PostIncidentTaskSlim } from "@incident-io/api";
import { useAPIMutation } from "src/utils/swr";

export const usePostIncidentTaskMutations = ({
  refetchIncidents,
  task,
}: {
  refetchIncidents: () => void;
  task: PostIncidentTask | PostIncidentTaskSlim;
}) => {
  const { trigger: rejectTask, isMutating: rejectingTask } = useAPIMutation(
    "postIncidentFlowListTasks",
    { incidentId: task.incident_id },
    async (apiClient) => {
      await apiClient.postIncidentFlowRejectTask({
        id: task.id,
      });
    },
    {
      onSuccess: () => {
        refetchIncidents();
      },
      showErrorToast: "Could not skip task",
    },
  );

  const { trigger: completeTask, isMutating: completingTask } = useAPIMutation(
    "postIncidentFlowListTasks",
    { incidentId: task.incident_id },
    async (apiClient) => {
      await apiClient.postIncidentFlowCompleteTask({
        id: task.id,
      });

      await refetchIncidents();
    },
    {
      onSuccess: () => {
        refetchIncidents();
      },
      showErrorToast: "Could not complete task",
    },
  );

  const { trigger: unresolveTask, isMutating: unresolvingTask } =
    useAPIMutation(
      "postIncidentFlowListTasks",
      { incidentId: task.incident_id },
      async (apiClient) => {
        await apiClient.postIncidentFlowUnresolveTask({
          id: task.id,
        });
      },
      {
        showErrorToast: "Could not update task",
      },
    );

  return {
    rejectTask,
    completeTask,
    unresolveTask,
    unresolvingTask,
    completingTask,
    rejectingTask,
  };
};
