import { IconEnum } from "@incident-ui";
import { PopoverMultiSelect } from "@incident-ui";
import _ from "lodash";
import { DateTime, IANAZone } from "luxon";

export const MultiTimezoneSelector = ({
  scheduleDefaultTimezone,
  currentTimezones,
  onChange,
}: {
  scheduleDefaultTimezone: string;
  currentTimezones: string[];
  onChange: (value: string[]) => void;
}): React.ReactElement => {
  const timezones = Intl.supportedValuesOf("timeZone");

  const prettyTz = _.chain(timezones)
    .filter((t) => t.includes("/"))
    .map((tz) => ({
      zone: tz,
      offset: IANAZone.create(tz).offsetName(DateTime.now().toMillis(), {
        format: "short",
      }),
      cityComponent: tz.split("/")[1].replaceAll("_", " "),
      isDefaultTz: scheduleDefaultTimezone === tz,
    }))
    .uniqBy("cityComponent")
    .sort((a, b) => {
      if (a.isDefaultTz) return -1;
      if (b.isDefaultTz) return 1;
      return a.cityComponent.localeCompare(b.cityComponent);
    })
    .value();

  const prettyTzOptions = [
    ...prettyTz.map((tz) => ({
      value: tz.zone,
      label: `${tz.cityComponent} (${tz.offset})`,
    })),
    {
      value: "Etc/UTC",
      label: "Coordinated Universal Time (UTC)",
    },
  ];

  const popularTimezones = {
    label: "Popular Timezones",
    options: [
      { value: "America/Los_Angeles", label: "Los Angeles (PST)" },
      { value: "America/New_York", label: "New York (EST)" },
      { value: "Europe/London", label: "London (GMT)" },
      { value: "Asia/Tokyo", label: "Tokyo (JST)" },
      { value: "Australia/Sydney", label: "Sydney (AEDT)" },
      { value: "Europe/Berlin", label: "Berlin (CET)" },
    ],
  };

  const allTimezones = {
    label: "All Timezones",
    options: prettyTzOptions.filter(
      (tz) => !popularTimezones.options.some((ptz) => ptz.value === tz.value),
    ),
  };

  const groupedOptions = [
    {
      label: "Popular Timezones",
      options: popularTimezones.options.sort((a, b) => {
        if (a.value === scheduleDefaultTimezone) return -1;
        if (b.value === scheduleDefaultTimezone) return 1;
        return a.label.localeCompare(b.label);
      }),
    },
  ].concat(allTimezones);

  return (
    <PopoverMultiSelect
      align="end"
      triggerClassName="min-h-8 h-8 rounded-1"
      options={groupedOptions}
      value={currentTimezones}
      isSearchable
      renderSelected={(values) => {
        if (values.length === 1) {
          return <p className="text-sm">{values[0] ? values[0].label : ""} </p>;
        } else {
          return (
            <p className="text-sm">{Object.keys(values).length} timezones</p>
          );
        }
      }}
      icon={IconEnum.Globe}
      onChange={(value) => onChange(value)}
    />
  );
};
