import type { SVGProps } from "react";
import * as React from "react";
const SvgBuildings = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.75 19h-2C3.785 19 3 18.215 3 17.25v-5.5c0-.965.785-1.75 1.75-1.75h2a.75.75 0 0 1 0 1.5h-2a.25.25 0 0 0-.25.25v5.5c0 .138.112.25.25.25h2a.75.75 0 0 1 0 1.5ZM13.25 19a.75.75 0 0 1 0-1.5h2.5a.25.25 0 0 0 .25-.25V6.767a.253.253 0 0 0-.105-.204l-4-2.834a.24.24 0 0 0-.259-.018.24.24 0 0 0-.135.222v4.312a.75.75 0 0 1-1.5 0V3.934c0-.657.362-1.252.946-1.554a1.748 1.748 0 0 1 1.816.126l4 2.834c.462.327.738.86.738 1.428V17.25c0 .965-.785 1.75-1.75 1.75H13.25Z"
    />
    <path
      fill="currentColor"
      d="M12.25 7.5h-4.5C6.785 7.5 6 8.285 6 9.25v9c0 .414.336.75.75.75h6.5a.75.75 0 0 0 .75-.75v-9c0-.965-.785-1.75-1.75-1.75Zm-1.5 9h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5Zm0-2.5h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5Zm0-2.5h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgBuildings;
