/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {User}
     * @memberof Action
     */
    assignee?: User;
    /**
     * When the action was completed
     * @type {Date}
     * @memberof Action
     */
    completed_at?: Date;
    /**
     * When the action was created
     * @type {Date}
     * @memberof Action
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof Action
     */
    creator?: Actor;
    /**
     * Description of the action
     * @type {string}
     * @memberof Action
     */
    description: string;
    /**
     * Unique identifier for the action
     * @type {string}
     * @memberof Action
     */
    id: string;
    /**
     * Unique identifier of the incident the action belongs to
     * @type {string}
     * @memberof Action
     */
    incident_id: string;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof Action
     */
    organisation_id: string;
    /**
     * Status of the action
     * @type {string}
     * @memberof Action
     */
    status: ActionStatusEnum;
    /**
     * When the action was last updated
     * @type {Date}
     * @memberof Action
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum ActionStatusEnum {
    Outstanding = 'outstanding',
    Completed = 'completed',
    Deleted = 'deleted',
    NotDoing = 'not_doing'
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': !exists(json, 'assignee') ? undefined : UserFromJSON(json['assignee']),
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'created_at': (new Date(json['created_at'])),
        'creator': !exists(json, 'creator') ? undefined : ActorFromJSON(json['creator']),
        'description': json['description'],
        'id': json['id'],
        'incident_id': json['incident_id'],
        'organisation_id': json['organisation_id'],
        'status': json['status'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': UserToJSON(value.assignee),
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'description': value.description,
        'id': value.id,
        'incident_id': value.incident_id,
        'organisation_id': value.organisation_id,
        'status': value.status,
        'updated_at': (value.updated_at.toISOString()),
    };
}

