/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSourceConfig,
    AlertSourceConfigFromJSON,
    AlertSourceConfigFromJSONTyped,
    AlertSourceConfigToJSON,
} from './AlertSourceConfig';

/**
 * 
 * @export
 * @interface AlertsListSourceConfigsResponseBody
 */
export interface AlertsListSourceConfigsResponseBody {
    /**
     * 
     * @type {Array<AlertSourceConfig>}
     * @memberof AlertsListSourceConfigsResponseBody
     */
    alert_source_configs: Array<AlertSourceConfig>;
}

export function AlertsListSourceConfigsResponseBodyFromJSON(json: any): AlertsListSourceConfigsResponseBody {
    return AlertsListSourceConfigsResponseBodyFromJSONTyped(json, false);
}

export function AlertsListSourceConfigsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsListSourceConfigsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_configs': ((json['alert_source_configs'] as Array<any>).map(AlertSourceConfigFromJSON)),
    };
}

export function AlertsListSourceConfigsResponseBodyToJSON(value?: AlertsListSourceConfigsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_configs': ((value.alert_source_configs as Array<any>).map(AlertSourceConfigToJSON)),
    };
}

