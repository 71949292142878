import type { SVGProps } from "react";
import * as React from "react";
const SvgNotification = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 13.5c-.689 0-1.25-.561-1.25-1.25V7.5C15 4.467 12.533 2 9.5 2A5.506 5.506 0 0 0 4 7.5v4.75c0 .689-.561 1.25-1.25 1.25a.75.75 0 0 0 0 1.5h13.5a.75.75 0 0 0 0-1.5ZM8.301 16a.5.5 0 0 0-.489.603C7.985 17.425 8.68 18 9.501 18c.821 0 1.516-.575 1.689-1.397a.5.5 0 0 0-.489-.603h-2.4Z"
    />
  </svg>
);
export default SvgNotification;
