/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIShowAIAssistantResponseBody
 */
export interface AIShowAIAssistantResponseBody {
    /**
     * If the organisation has an OpenAI Assistant.
     * @type {boolean}
     * @memberof AIShowAIAssistantResponseBody
     */
    _exists: boolean;
    /**
     * ID of the record.
     * @type {string}
     * @memberof AIShowAIAssistantResponseBody
     */
    id: string;
    /**
     * ID of the OpenAI Assistant.
     * @type {string}
     * @memberof AIShowAIAssistantResponseBody
     */
    openai_id: string;
}

export function AIShowAIAssistantResponseBodyFromJSON(json: any): AIShowAIAssistantResponseBody {
    return AIShowAIAssistantResponseBodyFromJSONTyped(json, false);
}

export function AIShowAIAssistantResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIShowAIAssistantResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': json['exists'],
        'id': json['id'],
        'openai_id': json['openai_id'],
    };
}

export function AIShowAIAssistantResponseBodyToJSON(value?: AIShowAIAssistantResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
        'id': value.id,
        'openai_id': value.openai_id,
    };
}

