/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImpersonationSession
 */
export interface ImpersonationSession {
    /**
     * When the impersonation session will expire.
     * @type {Date}
     * @memberof ImpersonationSession
     */
    expires_at: Date;
    /**
     * The ID of the impersonation session.
     * @type {string}
     * @memberof ImpersonationSession
     */
    id: string;
    /**
     * The ID of the organisation being impersonated.
     * @type {string}
     * @memberof ImpersonationSession
     */
    target_organisation_id: string;
    /**
     * The slug of the organisation being impersonated.
     * @type {string}
     * @memberof ImpersonationSession
     */
    target_organisation_slug: string;
}

export function ImpersonationSessionFromJSON(json: any): ImpersonationSession {
    return ImpersonationSessionFromJSONTyped(json, false);
}

export function ImpersonationSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImpersonationSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expires_at': (new Date(json['expires_at'])),
        'id': json['id'],
        'target_organisation_id': json['target_organisation_id'],
        'target_organisation_slug': json['target_organisation_slug'],
    };
}

export function ImpersonationSessionToJSON(value?: ImpersonationSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expires_at': (value.expires_at.toISOString()),
        'id': value.id,
        'target_organisation_id': value.target_organisation_id,
        'target_organisation_slug': value.target_organisation_slug,
    };
}

