import { SelectOption, SelectOptionOrGroup } from "@incident-ui/Select/types";
import { captureException } from "@sentry/react";
import { ClientType } from "src/contexts/ClientContext";

const catalogTypeaheadOptions = async ({
  apiClient,
  catalogTypeID,
  query,
  forInsights,
}: {
  apiClient: ClientType;
  catalogTypeID: string;
  query: string;
  forInsights: boolean;
}): Promise<SelectOptionOrGroup[]> => {
  try {
    if (forInsights) {
      const { options } = await apiClient.insightsListCatalogEntryOptions({
        catalogTypeId: catalogTypeID,
        query,
      });
      return options;
    } else {
      const { options } = await apiClient.catalogListOptions({
        id: catalogTypeID,
        query,
      });
      return options;
    }
  } catch (error) {
    captureException(error);
    console.error(error);
  }
  // @ts-expect-error this expects a promise, but this is an error case anyway so it's probably OK?
  return undefined;
};

export const getCatalogTypeaheadOptions = ({
  apiClient,
  catalogTypeID,
  forInsights = false,
}: {
  apiClient: ClientType;
  catalogTypeID: string;
  forInsights?: boolean;
}) => {
  return async function (inputValue: string): Promise<SelectOptionOrGroup[]> {
    try {
      const typeaheadOptions = await catalogTypeaheadOptions({
        apiClient,
        catalogTypeID,
        query: inputValue,
        forInsights,
      });

      return typeaheadOptions || [];
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
};

export const hydrateInitialCatalogOptions = ({
  apiClient,
  catalogTypeID,
  forInsights = false,
}: {
  apiClient: ClientType;
  catalogTypeID: string;
  forInsights?: boolean;
}) => {
  return async (initialValue: string | string[]): Promise<SelectOption[]> => {
    try {
      if (forInsights) {
        const { options } = await apiClient.insightsListCatalogEntryOptions({
          catalogTypeId: catalogTypeID,
          entryIds: Array.isArray(initialValue) ? initialValue : [initialValue],
        });
        return options;
      } else {
        const { options } = await apiClient.catalogHydrateOptions({
          id: catalogTypeID,
          idList: Array.isArray(initialValue) ? initialValue : [initialValue],
        });
        return options;
      }
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
};
