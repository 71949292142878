import { CatalogTypeModeEnum } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { GenericErrorMessage, IconEnum, Loader } from "@incident-ui";
import { useAPI } from "src/utils/swr";

import { CatalogTypeCreateEditFormWrapper } from "../catalog-type-form/CatalogTypeCreateEditFormWrapper";

export const CatalogTypeCreatePage = () => {
  // Catalog resources are used to type our attributes. To avoid a flash of the form,
  // we load these here before mounting the page.
  const { isLoading: resourcesLoading, error: resourcesError } = useAPI(
    "catalogListResources",
    undefined,
  );

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (resourcesLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      width={PageWidth.Narrow}
      title="Create catalog type"
      backHref="/catalog"
      icon={IconEnum.Book}
      crumbs={[{ title: "Catalog", to: "/catalog" }]}
    >
      <CatalogTypeCreateEditFormWrapper
        mode={Mode.Create}
        // Any catalog type being created here is a custom type, meaning it's not synced.
        // Therefore it cannot support dynamic attributes, so the below will always be false.
        supportsDynamicAttributes={false}
        dependentResources={[]}
        attributeDependencies={{}}
        catalogTypeMode={CatalogTypeModeEnum.Manual}
      />
    </PageWrapper>
  );
};
