import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  PopoverSingleSelect,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import React, { Dispatch } from "react";
import { tcx } from "src/utils/tailwind-classes";

// FieldSelector is a component which displays all selected fields as badges, alongside a button for
// adding new options. We use it in settings, when users need to indicate which fields are
// applicable to something.
export const FieldSelector = ({
  options,
  buttonLabel,
  resourceIcon,
  selectedIDs,
  setSelectedItems,
  noOptionsMessage,
  clearErrors,
}: {
  options: SelectOption[];
  buttonLabel: string;
  resourceIcon: IconEnum;
  selectedIDs: string[];
  setSelectedItems: Dispatch<React.SetStateAction<string[]>>;
  noOptionsMessage?: string;
  clearErrors?: () => void;
}): React.ReactElement => {
  const addItem = (id: string) => {
    setSelectedItems((selectedIDs) => [...selectedIDs, id]);
    if (clearErrors) {
      clearErrors();
    }
  };
  const removeItem = (id: string) => {
    setSelectedItems((selectedIDs) =>
      selectedIDs.filter((selectedID) => selectedID !== id),
    );
    if (clearErrors) {
      clearErrors();
    }
  };

  const selectedOptions = options.filter((option) =>
    selectedIDs.includes(option.value),
  );
  const remainingOptions = options.filter(
    (option) => !selectedIDs.includes(option.value),
  );

  return (
    <div className="gap-2 flex-center-y flex-wrap">
      {selectedOptions.map((option) => (
        <Badge
          theme={BadgeTheme.Tertiary}
          key={option.value}
          icon={resourceIcon}
          iconClassName={"text-blue-content"}
          className="text-sm-med text-content-primary"
          size={BadgeSize.Medium}
        >
          {option.label}
          {!option.disabled && (
            <Button
              analyticsTrackingId={"postmortem-section-remove-field"}
              analyticsTrackingMetadata={{ customFieldId: option.value }}
              theme={ButtonTheme.Naked}
              onClick={() => removeItem(option.value)}
            >
              <Icon id={IconEnum.Close} />
            </Button>
          )}
        </Badge>
      ))}
      <PopoverSingleSelect
        options={remainingOptions}
        value={undefined}
        noOptionsMessage={noOptionsMessage}
        onChange={(val) => {
          if (typeof val !== "string") return;
          addItem(val);
        }}
        renderTriggerNode={({ onClick }) => (
          <Button
            onClick={onClick}
            analyticsTrackingId={"postmortem-section-add-field"}
            theme={ButtonTheme.Secondary}
            className={tcx("flex-center-y", selectedIDs.length > 0 && "p-1")}
            size={BadgeSize.Medium}
            icon={IconEnum.Add}
          >
            {selectedIDs.length > 0 ? <></> : <>{buttonLabel}</>}
          </Button>
        )}
      />
    </div>
  );
};
