import { useContext } from "react";
import { createContext } from "react";
import React from "react";
import { ArrayPath, FieldValues, UseFieldArrayReturn } from "react-hook-form";
import { AvailableExpressionOperationOperationTypeEnum as AvailableOperationTypeEnum } from "src/contexts/ClientContext";

import { sendToSentry } from "../../../../utils/utils";
import { ExpressionFormData } from "./expressionToPayload";

export type FormDataWithExpressions = {
  expressions?: ExpressionFormData[];
};

// ExpressionsFormMethods is a subset of the methods from react-hook-form's useFieldArray
// hook which we use to mutate the expressions array.
export type ExpressionsFormMethods<
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData>,
> = Pick<
  UseFieldArrayReturn<TFormData, TPath, "key">,
  "append" | "fields" | "update" | "remove"
>;

export type ExpressionsMethodProviderContextT<
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData>,
> = {
  expressionsMethods?: ExpressionsFormMethods<TFormData, TPath>;
  allowAllOfACatalogType: boolean;
  showExpressionNames: boolean;
  displayMini: boolean;
  allowedOperations?: AvailableOperationTypeEnum[]; // if left empty, all operations are valid
};

const defaultValues = {
  methods: undefined,
  allowAllOfACatalogType: false,
  showExpressionNames: false,
  displayMini: false,
  allowedOperations: undefined,
};

const ExpressionsMethodProviderContext =
  // I can't get the types to work here as the type parameter here
  // will depend on the parent provider. This is fine though as the
  // types elsewhere in this file should ensure that it is safe.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<ExpressionsMethodProviderContextT<any, any>>(defaultValues);

type ExpressionsMethodsProviderProps<
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData>,
> = {
  children: React.ReactNode;
  expressionsMethods?: ExpressionsFormMethods<TFormData, TPath>;
  allowAllOfACatalogType: boolean;
  showExpressionNames?: boolean;
  displayMini?: boolean;
  allowedOperations?: AvailableOperationTypeEnum[]; // if left empty, all operations are valid
};

// ExpressionsMethodsProvider allows us to pass the methods from react-hook-form's useFieldArray
// hook to avoid prop drilling to the InputOrVariable/ReferenceSelectorPopover components.
export const ExpressionsMethodsProvider = <
  TFormData extends FieldValues,
  TPath extends ArrayPath<TFormData>,
>(
  props: ExpressionsMethodsProviderProps<TFormData, TPath>,
): React.ReactElement => {
  const {
    children,
    expressionsMethods,
    allowAllOfACatalogType,
    showExpressionNames = false,
    displayMini = false,
    allowedOperations,
  } = props;

  return (
    <ExpressionsMethodProviderContext.Provider
      value={{
        expressionsMethods,
        allowAllOfACatalogType,
        showExpressionNames,
        displayMini,
        allowedOperations,
      }}
    >
      {children}
    </ExpressionsMethodProviderContext.Provider>
  );
};

// ExpressionsMethodsOverrideProvider allows you to override some expressions methods
// for a specific subtree of the component tree.
export const ExpressionsMethodsOverrideProvider = <
  TFormData extends FieldValues,
  TPath extends ArrayPath<TFormData>,
>(
  props: Partial<ExpressionsMethodsProviderProps<TFormData, TPath>>,
): React.ReactElement => {
  const existingContext = useContext(ExpressionsMethodProviderContext);
  if (!existingContext) {
    throw new Error(
      "ExpressionsMethodsOverrideProvider: No existing context found, nothing to override",
    );
  }

  const { children, ...overrides } = props;

  const newContext = {
    ...existingContext,
    ...overrides,
  };

  return (
    <ExpressionsMethodProviderContext.Provider value={newContext}>
      {children}
    </ExpressionsMethodProviderContext.Provider>
  );
};

export const useExpressionsMethods = <
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData> & "expressions",
>(): ExpressionsMethodProviderContextT<TFormData, TPath> => {
  return useContext(ExpressionsMethodProviderContext);
};

// validateExpressionMethods will return true if your component is appropriately wrapped in
// an ExpressionMethodsProvider.
//
// We don't force you to use a typesafe provider like we do for other contexts, as we have
// components like InputOrVariable which only sometimes needs to access expression context,
// and other times it's perfectly legit to _not_ wrap it in a context (e.g. maybe your form
// doesn't support expressions).
export const validateExpressionsMethods = <
  TFormData extends FormDataWithExpressions,
  TPath extends ArrayPath<TFormData> & "expressions",
>(
  expressionMethods: ExpressionsMethodProviderContextT<TFormData, TPath>,
): expressionMethods is Required<
  ExpressionsMethodProviderContextT<TFormData, TPath>
> => {
  if (!expressionMethods) {
    const errorMessage = `useExpressionMethods: Expressions methods not available, but the reference is an expression!
    Did you forget to wrap the InputOrVariable component in an ExpressionsProvider?`;
    if (process.env.APP_ENV === "development") {
      throw new Error(errorMessage);
    } else {
      sendToSentry(errorMessage);
    }
  }

  return !!expressionMethods.expressionsMethods;
};
