import { useAPI } from "src/utils/swr";

export const useFollowUpScope = (
  { skip }: { skip?: boolean } = { skip: false },
) => {
  const { data, isLoading, mutate, error } = useAPI(
    skip ? null : "followUpsBuildScope",
    {},
    {
      fallbackData: { scope: { references: [], aliases: {} } },
    },
  );

  return {
    scope: data.scope,
    scopeLoading: isLoading,
    refetchScope: async () => {
      await mutate();
    },
    scopeError: error,
  };
};
