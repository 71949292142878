import { ToggleUsePrivateChannelButton } from "@incident-shared/forms/v2/editors/SlackChannelEditorV2";
import { Callout, CalloutTheme } from "@incident-ui";
import { useState } from "react";
import { Path, UseFormReturn } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import {
  getEngineTypeaheadOptions,
  selectOptionsToParamBindingOptions,
} from "src/components/@shared/engine";
import { DynamicSingleSelectWithObjV2 } from "src/components/@shared/forms/v2/inputs/DynamicSelectWithObjV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { Resource, useClient } from "src/contexts/ClientContext";

type SlackChannelEditorProps<TFormType extends FieldValues> = {
  name: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  resource: Resource;
  insetSuffixNode?: React.ReactNode;
};

export const EngineSlackChannelEditor = <TFormType extends FieldValues>({
  name,
  formMethods,
  resource,
  insetSuffixNode,
}: SlackChannelEditorProps<TFormType>): React.ReactElement => {
  const [usePrivateChannel, setUsePrivateChannel] = useState(false);
  const apiClient = useClient();

  const loadTypeAheadOptions = getEngineTypeaheadOptions(
    apiClient,
    "SlackChannel",
  );
  const loadOptions = async (inputValue: string) => {
    const opts = await loadTypeAheadOptions(inputValue);
    return selectOptionsToParamBindingOptions(resource, opts);
  };

  return (
    <div className="flex flex-col w-full">
      {usePrivateChannel ? (
        <InputV2
          formMethods={formMethods}
          name={`${name}.literal` as Path<TFormType>}
          placeholder={"Private Channel ID e.g. C1234567"}
          insetSuffixNode={insetSuffixNode}
        />
      ) : (
        <DynamicSingleSelectWithObjV2
          formMethods={formMethods}
          name={name}
          loadOptions={loadOptions}
          placeholder="Select channel"
          hideErrors
          insetSuffixNode={insetSuffixNode}
        />
      )}

      <ToggleUsePrivateChannelButton
        usePrivateChannel={usePrivateChannel}
        onClick={() => {
          // Clear the value since we're switching between private and public channels
          // @ts-expect-error the type-check fails but this is what we want
          formMethods.setValue(name, null);
          setUsePrivateChannel(!usePrivateChannel);
        }}
      />

      {usePrivateChannel && (
        <div>
          <div className="mt-2 text-slate-600 text-sm">
            You can find the ID of a channel by opening the channel settings in
            Slack, it&apos;s written at the bottom of the screen in light grey.
          </div>
          <Callout theme={CalloutTheme.Warning} className="mt-4">
            Anyone viewing this workflow will be able to see the name of this
            private channel
          </Callout>
        </div>
      )}
    </div>
  );
};
