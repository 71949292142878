/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsPagerDutyGetConfigResponseBody,
    IntegrationsPagerDutyGetConfigResponseBodyFromJSON,
    IntegrationsPagerDutyGetConfigResponseBodyToJSON,
    IntegrationsPagerDutyUpdateConfigRequestBody,
    IntegrationsPagerDutyUpdateConfigRequestBodyFromJSON,
    IntegrationsPagerDutyUpdateConfigRequestBodyToJSON,
    IntegrationsPagerDutyUpdateConfigResponseBody,
    IntegrationsPagerDutyUpdateConfigResponseBodyFromJSON,
    IntegrationsPagerDutyUpdateConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsPagerDutyUpdateConfigRequest {
    updateConfigRequestBody: IntegrationsPagerDutyUpdateConfigRequestBody;
}

/**
 * 
 */
export class IntegrationsPagerDutyApi extends runtime.BaseAPI {

    /**
     * Get the PagerDuty configuration associated with this organisation
     * GetConfig Integrations - PagerDuty
     */
    async integrationsPagerDutyGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsPagerDutyGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/pagerduty/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsPagerDutyGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the PagerDuty configuration associated with this organisation
     * GetConfig Integrations - PagerDuty
     */
    async integrationsPagerDutyGetConfig(initOverrides?: RequestInit): Promise<IntegrationsPagerDutyGetConfigResponseBody> {
        const response = await this.integrationsPagerDutyGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Remove PagerDuty configuration and tokens
     * Uninstall Integrations - PagerDuty
     */
    async integrationsPagerDutyUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/pagerduty/config`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove PagerDuty configuration and tokens
     * Uninstall Integrations - PagerDuty
     */
    async integrationsPagerDutyUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsPagerDutyUninstallRaw(initOverrides);
    }

    /**
     * Set the bot user, which will \'create\' all PD incidents
     * UpdateConfig Integrations - PagerDuty
     */
    async integrationsPagerDutyUpdateConfigRaw(requestParameters: IntegrationsPagerDutyUpdateConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsPagerDutyUpdateConfigResponseBody>> {
        if (requestParameters.updateConfigRequestBody === null || requestParameters.updateConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateConfigRequestBody','Required parameter requestParameters.updateConfigRequestBody was null or undefined when calling integrationsPagerDutyUpdateConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/pagerduty/config`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsPagerDutyUpdateConfigRequestBodyToJSON(requestParameters.updateConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsPagerDutyUpdateConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set the bot user, which will \'create\' all PD incidents
     * UpdateConfig Integrations - PagerDuty
     */
    async integrationsPagerDutyUpdateConfig(requestParameters: IntegrationsPagerDutyUpdateConfigRequest, initOverrides?: RequestInit): Promise<IntegrationsPagerDutyUpdateConfigResponseBody> {
        const response = await this.integrationsPagerDutyUpdateConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
