/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostIncidentFlow,
    PostIncidentFlowFromJSON,
    PostIncidentFlowFromJSONTyped,
    PostIncidentFlowToJSON,
} from './PostIncidentFlow';

/**
 * 
 * @export
 * @interface PostIncidentFlowEvaluateConditionsResponseBody
 */
export interface PostIncidentFlowEvaluateConditionsResponseBody {
    /**
     * Whether or not the incident should automatically be opted in to the post-incident flow
     * @type {boolean}
     * @memberof PostIncidentFlowEvaluateConditionsResponseBody
     */
    automatic_opt_in: boolean;
    /**
     * 
     * @type {PostIncidentFlow}
     * @memberof PostIncidentFlowEvaluateConditionsResponseBody
     */
    post_incident_flow?: PostIncidentFlow;
}

export function PostIncidentFlowEvaluateConditionsResponseBodyFromJSON(json: any): PostIncidentFlowEvaluateConditionsResponseBody {
    return PostIncidentFlowEvaluateConditionsResponseBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowEvaluateConditionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowEvaluateConditionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'automatic_opt_in': json['automatic_opt_in'],
        'post_incident_flow': !exists(json, 'post_incident_flow') ? undefined : PostIncidentFlowFromJSON(json['post_incident_flow']),
    };
}

export function PostIncidentFlowEvaluateConditionsResponseBodyToJSON(value?: PostIncidentFlowEvaluateConditionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automatic_opt_in': value.automatic_opt_in,
        'post_incident_flow': PostIncidentFlowToJSON(value.post_incident_flow),
    };
}

