/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentCallSessionWithTranscript,
    IncidentCallSessionWithTranscriptFromJSON,
    IncidentCallSessionWithTranscriptFromJSONTyped,
    IncidentCallSessionWithTranscriptToJSON,
} from './IncidentCallSessionWithTranscript';

/**
 * 
 * @export
 * @interface IncidentCallsListCallSessionsWithSummariesResponseBody
 */
export interface IncidentCallsListCallSessionsWithSummariesResponseBody {
    /**
     * 
     * @type {Array<IncidentCallSessionWithTranscript>}
     * @memberof IncidentCallsListCallSessionsWithSummariesResponseBody
     */
    call_sessions_with_transcripts: Array<IncidentCallSessionWithTranscript>;
}

export function IncidentCallsListCallSessionsWithSummariesResponseBodyFromJSON(json: any): IncidentCallsListCallSessionsWithSummariesResponseBody {
    return IncidentCallsListCallSessionsWithSummariesResponseBodyFromJSONTyped(json, false);
}

export function IncidentCallsListCallSessionsWithSummariesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallsListCallSessionsWithSummariesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_sessions_with_transcripts': ((json['call_sessions_with_transcripts'] as Array<any>).map(IncidentCallSessionWithTranscriptFromJSON)),
    };
}

export function IncidentCallsListCallSessionsWithSummariesResponseBodyToJSON(value?: IncidentCallsListCallSessionsWithSummariesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_sessions_with_transcripts': ((value.call_sessions_with_transcripts as Array<any>).map(IncidentCallSessionWithTranscriptToJSON)),
    };
}

