/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DemoRunCommandResponseBody
 */
export interface DemoRunCommandResponseBody {
    /**
     * Command logs
     * @type {Array<string>}
     * @memberof DemoRunCommandResponseBody
     */
    logs?: Array<string>;
}

export function DemoRunCommandResponseBodyFromJSON(json: any): DemoRunCommandResponseBody {
    return DemoRunCommandResponseBodyFromJSONTyped(json, false);
}

export function DemoRunCommandResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemoRunCommandResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logs': !exists(json, 'logs') ? undefined : json['logs'],
    };
}

export function DemoRunCommandResponseBodyToJSON(value?: DemoRunCommandResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logs': value.logs,
    };
}

