import { ScopeNameEnum } from "@incident-io/api";
import {
  Gate,
  Product,
  UpgradeRequiredMessage,
} from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import { Button, ButtonProps } from "@incident-ui/Button/Button";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import React, { ForwardedRef } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePrimaryCommsPlatform } from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";
import { tcx } from "src/utils/tailwind-classes";

// UpgradeRequiredProps are used to form a sensible message on the tooltip.
export type UpgradeRequiredProps = {
  /** The feature name should be lower-case, as it's used in the middle of a sentence */
  featureName: string;
  gate: Gate;
};

export type GatedButtonSpecificProps = {
  disabledTooltipContent?: React.ReactNode;
  alwaysShownTooltipContent?: React.ReactNode;
  spanClassName?: string;
  requiredScope?: ScopeNameEnum;
  requiredProduct?: Product;
};

export type GatedButtonProps = GatedButtonSpecificProps &
  UpgradeRequiredButtonProps &
  ButtonProps;

export type UpgradeRequiredButtonProps =
  | { upgradeRequired?: boolean; upgradeRequiredProps?: UpgradeRequiredProps }
  | { upgradeRequired: true; upgradeRequiredProps: UpgradeRequiredProps };

export const NoPermissionMessage = "You don't have permission to do this";

// GatedButton is a wrapper for Button which handle the various reasons a button might be disabled.
// * If the feature requires a particular user scope, pass the `scopeRequiredToAdd`
// * If the feature requires the organisation to upgrade, pass the `upgradeRequired` bool and `upgradeRequiredProps`
// * If the feature is disabled for some other reason (e.g. isDirty), pass the `disabled` bool and optionally `disabledTooltipContent`

// GatedButton is a wrapper for Button which handle the various reasons a button might be disabled.
// * If the feature requires a particular user scope, pass the `scopeRequiredToAdd`
// * If the feature requires the organisation to upgrade, pass the `upgradeRequired` bool and `upgradeRequiredProps`
// * If the feature is disabled for some other reason (e.g. isDirty), pass the `disabled` bool and optionally `disabledTooltipContent`
export const GatedButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  GatedButtonProps
>(
  (
    {
      disabledTooltipContent,
      disabled,
      alwaysShownTooltipContent,
      spanClassName,
      requiredScope,
      requiredProduct,
      upgradeRequired,
      upgradeRequiredProps,
      children,
      ...props
    }: GatedButtonProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    const commsPlatform = usePrimaryCommsPlatform();
    const { hasScope } = useIdentity();
    const { hasProduct } = useProductAccess();
    const hasRequiredScope = requiredScope ? hasScope(requiredScope) : true;
    const hasRequiredProduct = requiredProduct
      ? hasProduct(requiredProduct)
      : true;
    const buttonIsDisabled = disabled || !hasRequiredScope;

    // 1. Is the feature only avaliable to certain products?
    if (requiredProduct && !hasRequiredProduct) {
      return (
        <GatedButtonInner
          tooltipContent={
            <ProductRequiredMessage
              requiredProduct={requiredProduct}
              commsPlatform={commsPlatform}
            />
          }
          spanClassName={spanClassName}
          ref={ref}
          {...props}
          disabled
        >
          {children}
        </GatedButtonInner>
      );
    }

    // 2. Is the feature upgrade required? Only show if the button would otherwise work.
    if (upgradeRequired && !buttonIsDisabled) {
      return (
        <GatedButtonInner
          tooltipContent={
            <UpgradeRequiredMessage
              {...(upgradeRequiredProps as UpgradeRequiredProps)}
            />
          }
          spanClassName={spanClassName}
          ref={ref}
          {...props}
          disabled
        >
          {children}
        </GatedButtonInner>
      );
    }

    // 3. Do you have the required scope?
    if (!hasRequiredScope) {
      return (
        <GatedButtonInner
          tooltipContent={NoPermissionMessage}
          spanClassName={spanClassName}
          ref={ref}
          {...props}
          disabled
        >
          {children}
        </GatedButtonInner>
      );
    }

    if (disabledTooltipContent && buttonIsDisabled) {
      return (
        <GatedButtonInner
          tooltipContent={disabledTooltipContent}
          spanClassName={spanClassName}
          ref={ref}
          {...props}
          disabled
        >
          {children}
        </GatedButtonInner>
      );
    }

    if (alwaysShownTooltipContent) {
      return (
        <GatedButtonInner
          tooltipContent={alwaysShownTooltipContent}
          spanClassName={spanClassName}
          ref={ref}
          {...props}
          disabled={buttonIsDisabled}
        >
          {children}
        </GatedButtonInner>
      );
    }

    return (
      <Button ref={ref} {...props} disabled={buttonIsDisabled}>
        {children}
      </Button>
    );
  },
);

GatedButton.displayName = "GatedButton";

type GatedButtonInnerProps = ButtonProps & {
  tooltipContent: React.ReactNode;
  spanClassName?: string;
};
const GatedButtonInner = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  GatedButtonInnerProps
>(
  (
    {
      tooltipContent,
      spanClassName,
      disabled,
      className,
      onClick,
      href,
      title,
      children,
      ...buttonProps
    }: GatedButtonInnerProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (disabled) {
      className = tcx(className, "pointer-events-none");
      onClick = undefined;
      href = undefined;
      title = undefined;
    }

    return (
      <Tooltip
        analyticsTrackingId={null}
        content={tooltipContent}
        delayDuration={0}
      >
        <span
          className={tcx(
            "w-fit cursor-not-allowed flex items-center",
            spanClassName,
          )}
        >
          <Button
            {...buttonProps}
            ref={ref}
            className={className}
            disabled={disabled}
            // clear out any other behaviour (as disabled:true won't actually do anything)
            onClick={onClick}
            href={href}
            title={title}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    );
  },
);
GatedButtonInner.displayName = "GatedButtonInner";
