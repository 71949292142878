import {
  EngineParamBinding,
  Reference,
  Resource,
  ResourceFieldConfigTypeEnum,
  StepProgressStatusEnum as StatusEnum,
  Workflow,
  WorkflowRun,
} from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { GenericErrorMessage, Loader, ModalFooter, Txt } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { filterScope } from "src/utils/scope";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { WorkflowsRunResult } from "../activity/WorkflowsRunResult";
import { ClonedWorkflow } from "../common/types";

type FormData = {
  param_bindings: EngineParamBinding[];
};

export const WorkflowsConfigureTestModal = ({
  onClose,
  workflow,
  resources,
}: {
  onClose: () => void;
  workflow: Workflow | ClonedWorkflow;
  resources: Resource[];
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: {},
  });

  const [result, setResult] = useState<WorkflowRun | null>(null);

  const {
    data: triggerData,
    isLoading: triggerIsLoading,
    error: triggerError,
  } = useAPI("workflowsShowTrigger", {
    name: workflow.trigger?.name ?? "",
  });
  const trigger = triggerData?.trigger;

  const {
    data: stepsData,
    isLoading: stepsIsLoading,
    error: stepsError,
  } = useAPI("workflowsListSteps", undefined, { fallbackData: { steps: [] } });
  const steps = stepsData?.steps;

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "workflowsListWorkflowRuns",
    {
      workflowId: workflow.id,
    },
    async (apiClient, data: FormData) => {
      const vals = {};
      (data.param_bindings ?? []).forEach((binding, i) => {
        vals[relevantReferences[i].key] = binding.value?.literal;
      });

      const resp = await apiClient.workflowsCreateWorkflowRun({
        createWorkflowRunRequestBody: {
          workflow_id: workflow.id,
          values: vals,
        },
      });

      setResult(resp.workflow_run);
    },
  );

  if (triggerError || stepsError) {
    return <GenericErrorMessage error={triggerError || stepsError} />;
  }

  if (triggerIsLoading || !trigger || stepsIsLoading || !stepsData) {
    return <Loader />;
  }

  // We only need to configure the top level references - i.e. those without a . (and also
  // ignore any expressions)
  const relevantReferences = filterScope(
    trigger.scope,
    (x) => !x.key.includes(".") && !x.key.includes("expressions["),
  ).references;

  const overallSuccess = result?.progress.every(
    (x) => x.status === StatusEnum.Complete,
  );

  const canTest = canRenderForm(relevantReferences, resources);

  return (
    <Form.Modal
      analyticsTrackingId="workflows-v2-configure-test"
      title={`Test workflow: ${workflow.name}`}
      onClose={onClose}
      disableQuickClose
      formMethods={formMethods}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Test"
          analyticsTrackingId="workflows-v2-configure-test-confirm"
          hideConfirmButton={result != null && overallSuccess}
          disabled={!canTest}
          cancelButtonText={overallSuccess ? "Close" : "Cancel"}
          saving={saving}
        />
      }
    >
      <div>
        {result ? (
          <WorkflowsRunResult showHelpText workflowRun={result} steps={steps} />
        ) : (
          <div className="flex flex-col gap-2">
            <div className="text-sm">
              Choose what we should use to test this workflow. We will then run
              your configured workflow steps against that information. Note that
              we won&apos;t check that any conditions apply.
            </div>
            {canTest ? (
              relevantReferences.map((reference, i) => (
                <EngineFormElement<FormData>
                  key={reference.key}
                  name={`param_bindings.${i}`}
                  resources={resources}
                  resourceType={reference.type}
                  label={reference.label}
                  array={false}
                  showPlaceholder
                  // We don't want to allow variables here, as the point of this form
                  // is to populate the trigger scope
                  mode="plain_input"
                  required={true}
                />
              ))
            ) : (
              <Txt grey className="border border-stroke rounded-[6px] p-4">
                Sorry, we don&apos;t support testing this kind of workflow.
              </Txt>
            )}
          </div>
        )}
      </div>
    </Form.Modal>
  );
};

// canRenderForm checks if we have the relevant form field config to render this form.
// this will return false for the 'slack.message_posted' trigger, as we don't (and don't
// want to) support selecting a Slack message from a dropdown.
function canRenderForm(references: Reference[], resources: Resource[]) {
  let canRender = true;
  references.forEach((ref) => {
    const resource = resources.find((res) => res.type === ref.type);
    if (!resource) {
      canRender = false;
      return;
    }
    if (resource.field_config.type === ResourceFieldConfigTypeEnum.None) {
      canRender = false;
      return;
    }
  });
  return canRender;
}
