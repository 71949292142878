import { RBACRole, SCIMShowSettingsResponseBody } from "@incident-io/api";
import {
  Modal,
  ModalContent,
  ModalFooter,
  Steps,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { noop } from "lodash";
import { default as React, useState } from "react";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { ScimGroupRoleMappingSection } from "./ScimGroupRoleMappingSection";

type Step = "connect" | "mapping" | "confirming";

interface StepDetails {
  name: string;
  modalContent: React.ReactElement | null;
  onConfirm: () => void;
  confirmText: string;
  onCancel: () => void;
  disabledHelpText?: string;
  cancelText: string;
}

export const ScimSetupModal = ({
  roles,
  onComplete,
  scimConfigState,
  setShowCompleteSetupModal,
}: {
  roles: RBACRole[];
  scimConfigState: SCIMShowSettingsResponseBody;
  setShowCompleteSetupModal: (v: boolean) => void;
  onComplete: () => void;
}) => {
  const [hasSetOwnerMapping, setHasSetOwnerMapping] = useState(false);
  const [step, setStep] = useState<Step>("mapping");
  const showToast = useToast();

  const refetchUsers = useRevalidate(["usersList"]);

  const { trigger: onSubmitCompletion, isMutating: saving } = useAPIMutation(
    "sCIMShowSettings",
    undefined,
    async (apiClient, _): Promise<SCIMShowSettingsResponseBody> => {
      const res = await apiClient.sCIMConfirmRoleMappings();
      // Map this to a config response type
      return { enabled: true, scim_config: res.scim_config };
    },
    {
      onSuccess: () => {
        onComplete();
        // We also need to update the user list, as well as the SCIM config
        refetchUsers();
      },
      onError: (err, fieldErrors) => {
        if ((fieldErrors ?? [])?.length === 0) {
          throw err;
        } else {
          showToast({
            title: "Error",
            description: fieldErrors?.message,
            theme: ToastTheme.Error,
          });
        }
      },
    },
  );

  const steps: Record<Step, StepDetails> = {
    connect: {
      name: "Connect SCIM",
      modalContent: null,
      cancelText: "",
      confirmText: "",
      onCancel: noop,
      onConfirm: noop,
    },
    mapping: {
      name: "Map groups to roles",
      modalContent: (
        <Txt>
          <p className={"mt-4"}>
            Now that you&apos;ve connected SCIM, you need to define which roles
            users should be assigned to based on their group membership.
          </p>
          <p className={"mt-4"}>
            By default, all users receive the Viewer/Responder role, but
            you&apos;ll need to assign at least one group the Owner role to
            continue.
          </p>
          <p className={"mt-4"}>
            Please note that if you&apos;re not in the group that is assigned
            the owner role, you will no longer be able to edit your SCIM
            settings.
          </p>
          <ScimGroupRoleMappingSection
            showHeading={false}
            roles={roles}
            scimConfigState={scimConfigState}
            setHasSetOwnerMapping={setHasSetOwnerMapping}
          />
        </Txt>
      ),
      cancelText: "Cancel",
      confirmText: "Continue",
      disabledHelpText: !hasSetOwnerMapping
        ? "You cannot complete setup until you have mapped at least one group to the owner role."
        : undefined,
      onCancel: () => setShowCompleteSetupModal(false),
      onConfirm: () => setStep("confirming"),
    },
    confirming: {
      name: "Confirm",
      modalContent: (
        <Txt className={"mt-2 p-2"}>
          <p>
            Once you enable SCIM users can no longer be assigned from within
            incident.io. You will instead need to manage their roles via your
            identity provider.
          </p>
          <br />
          <p>
            Any users who currently have admin, owner or custom roles{" "}
            <strong>will lose these permissions</strong> if their groups have
            not been assigned those roles.
          </p>
          <br />
          <p>
            Please note that once enabled, it may take a few minutes for your
            user&apos;s roles to update.
          </p>
        </Txt>
      ),
      onCancel: () => setStep("mapping"),
      confirmText: "Enable SCIM and assign roles",
      cancelText: "Back",
      onConfirm: () => {
        // NOTE: We don't need to pass any data here as the mutation will
        // automatically use the data from the context.
        onSubmitCompletion({ data: null });
      },
    },
  };

  return (
    <Modal
      isOpen
      title={"Complete SCIM setup"}
      analyticsTrackingId={"complete-scim-setup"}
      onClose={() => setShowCompleteSetupModal(false)}
    >
      <ModalContent>
        <Steps
          steps={Object.entries(steps).map(([id, val]) => ({ ...val, id }))}
          currentStep={step}
        />
        {steps[step].modalContent}
      </ModalContent>
      <ModalFooter
        disabledTooltipContent={steps[step].disabledHelpText}
        disabled={!!steps[step].disabledHelpText}
        confirmButtonType="button"
        confirmButtonText={steps[step].confirmText}
        onConfirm={steps[step].onConfirm}
        cancelButtonText={steps[step].cancelText}
        onClose={steps[step].onCancel}
        saving={saving}
      />
    </Modal>
  );
};
