import { TimestampDescription } from "@incident-shared/timestamps/TimestampDescription";
import { IconEnum, Loader, StackedList } from "@incident-ui";
import { IncidentTimestamp } from "src/contexts/ClientContext";
import { getAnchorId, timestampAnchorPrefix } from "src/utils/anchor";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { uneditableTimestampTypes } from "./IncidentTimestampsSection";

export const DefaultTimestampsList = ({
  className,
}: {
  className?: string;
}) => {
  const {
    data: { incident_timestamps: allTimestamps },
    isLoading: timestampsLoading,
  } = useAPI("incidentTimestampsList", undefined, {
    fallbackData: { incident_timestamps: [] },
  });

  const visibleTimestamps = allTimestamps.filter((timestamp) =>
    uneditableTimestampTypes.includes(timestamp.timestamp_type),
  );

  if (timestampsLoading) {
    return <Loader />;
  }

  return (
    <StackedList className={className}>
      {visibleTimestamps.map((timestamp) => (
        <DefaultIncidentTimestampRow key={timestamp.id} timestamp={timestamp} />
      ))}
    </StackedList>
  );
};

const DefaultIncidentTimestampRow = ({
  timestamp,
}: {
  timestamp: IncidentTimestamp;
}) => {
  const anchorId = `${timestampAnchorPrefix}/${timestamp.id}`;

  return (
    <div
      id={anchorId}
      className={tcx("w-full", getAnchorId() === anchorId ? "anchored" : "")}
    >
      <SettingsListItem
        title={timestamp.name}
        icon={IconEnum.Clock}
        description={
          <div className="text-slate-600 text-sm flex items-center gap-2">
            <TimestampDescription timestamp={timestamp} />
          </div>
        }
        descriptionPosition="inline"
      />
    </div>
  );
};
