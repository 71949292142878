import type { SVGProps } from "react";
import * as React from "react";
const SvgLoop = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.898 16.438A6.508 6.508 0 0 1 3.5 10c0-3.584 2.916-6.5 6.5-6.5a6.496 6.496 0 0 1 5.329 2.801l-1.458-.202a.75.75 0 1 0-.205 1.487l2.944.407a.75.75 0 0 0 .846-.64l.408-2.945a.75.75 0 0 0-.64-.846.746.746 0 0 0-.846.64l-.116.84A7.994 7.994 0 0 0 10.001 2C5.589 2 2 5.589 2 10s3.589 8 8 8c.368 0 .739-.025 1.102-.075a.75.75 0 0 0 .641-.845.755.755 0 0 0-.845-.641v-.001Z"
    />
    <path
      fill="currentColor"
      d="M15.25 17.5a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5Z"
    />
  </svg>
);
export default SvgLoop;
