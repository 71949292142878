import { Component } from "react";

type LockedCellProps = {
  className?: string;
  isDragging: boolean;
  children: React.ReactNode;
};

type LockedCellState = {
  snapshot: {
    width: number;
    height: number;
  };
};

// The getSnapshotBeforeUpdate lifecycle is not possible when using function
// components, so we'll write this as a class instead.
//
// We need this special cell component to support drag-and-drop with tables that
// flexibly adjust column width for their content. That's because when you
// remove the <tr> from the table, you lose the grid sizing that you get from
// being inside the <table>, so we must:
//
// 1. Store our current size just before we begin dragging
// 2. Apply that size to the cells via inline styles so they retain their size
// while being moved
// 3. Remove the inline styles when we detect dragging is finished
export class LockedCell extends Component<LockedCellProps, LockedCellState> {
  ref;

  getSnapshotBeforeUpdate(prevProps: LockedCellProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting = this.props.isDragging && !prevProps.isDragging;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height,
    };

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragging) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty("height");
    ref.style.removeProperty("width");
  }

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    return (
      <td className={this.props.className} ref={this.setRef}>
        {this.props.children}
      </td>
    );
  }
}
