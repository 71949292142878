/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AlertsBulkPreviewSourceConfigAlertsRequestBody,
    AlertsBulkPreviewSourceConfigAlertsRequestBodyFromJSON,
    AlertsBulkPreviewSourceConfigAlertsRequestBodyToJSON,
    AlertsBulkPreviewSourceConfigAlertsResponseBody,
    AlertsBulkPreviewSourceConfigAlertsResponseBodyFromJSON,
    AlertsBulkPreviewSourceConfigAlertsResponseBodyToJSON,
    AlertsCreateDraftSourceConfigRequestBody,
    AlertsCreateDraftSourceConfigRequestBodyFromJSON,
    AlertsCreateDraftSourceConfigRequestBodyToJSON,
    AlertsCreateDraftSourceConfigResponseBody,
    AlertsCreateDraftSourceConfigResponseBodyFromJSON,
    AlertsCreateDraftSourceConfigResponseBodyToJSON,
    AlertsCreatePriorityRequestBody,
    AlertsCreatePriorityRequestBodyFromJSON,
    AlertsCreatePriorityRequestBodyToJSON,
    AlertsCreatePriorityResponseBody,
    AlertsCreatePriorityResponseBodyFromJSON,
    AlertsCreatePriorityResponseBodyToJSON,
    AlertsEvaluateResponseBody,
    AlertsEvaluateResponseBodyFromJSON,
    AlertsEvaluateResponseBodyToJSON,
    AlertsGenerateIncidentDetailsFromAlertResponseBody,
    AlertsGenerateIncidentDetailsFromAlertResponseBodyFromJSON,
    AlertsGenerateIncidentDetailsFromAlertResponseBodyToJSON,
    AlertsGetInsightsResponseBody,
    AlertsGetInsightsResponseBodyFromJSON,
    AlertsGetInsightsResponseBodyToJSON,
    AlertsListAlertsResponseBody,
    AlertsListAlertsResponseBodyFromJSON,
    AlertsListAlertsResponseBodyToJSON,
    AlertsListFilterFieldsResponseBody,
    AlertsListFilterFieldsResponseBodyFromJSON,
    AlertsListFilterFieldsResponseBodyToJSON,
    AlertsListIncidentAlertsResponseBody,
    AlertsListIncidentAlertsResponseBodyFromJSON,
    AlertsListIncidentAlertsResponseBodyToJSON,
    AlertsListPrioritiesResponseBody,
    AlertsListPrioritiesResponseBodyFromJSON,
    AlertsListPrioritiesResponseBodyToJSON,
    AlertsListSourceConfigsResponseBody,
    AlertsListSourceConfigsResponseBodyFromJSON,
    AlertsListSourceConfigsResponseBodyToJSON,
    AlertsListSourcesResponseBody,
    AlertsListSourcesResponseBodyFromJSON,
    AlertsListSourcesResponseBodyToJSON,
    AlertsListTimelineItemsResponseBody,
    AlertsListTimelineItemsResponseBodyFromJSON,
    AlertsListTimelineItemsResponseBodyToJSON,
    AlertsMarkSuggestionRequestBody,
    AlertsMarkSuggestionRequestBodyFromJSON,
    AlertsMarkSuggestionRequestBodyToJSON,
    AlertsMarkSuggestionResponseBody,
    AlertsMarkSuggestionResponseBodyFromJSON,
    AlertsMarkSuggestionResponseBodyToJSON,
    AlertsPreviewSourceConfigAlertsRequestBody,
    AlertsPreviewSourceConfigAlertsRequestBodyFromJSON,
    AlertsPreviewSourceConfigAlertsRequestBodyToJSON,
    AlertsPreviewSourceConfigAlertsResponseBody,
    AlertsPreviewSourceConfigAlertsResponseBodyFromJSON,
    AlertsPreviewSourceConfigAlertsResponseBodyToJSON,
    AlertsResolveResponseBody,
    AlertsResolveResponseBodyFromJSON,
    AlertsResolveResponseBodyToJSON,
    AlertsSetDefaultPriorityResponseBody,
    AlertsSetDefaultPriorityResponseBodyFromJSON,
    AlertsSetDefaultPriorityResponseBodyToJSON,
    AlertsShowAlertResponseBody,
    AlertsShowAlertResponseBodyFromJSON,
    AlertsShowAlertResponseBodyToJSON,
    AlertsShowIncidentAlertResponseBody,
    AlertsShowIncidentAlertResponseBodyFromJSON,
    AlertsShowIncidentAlertResponseBodyToJSON,
    AlertsShowSchemaResponseBody,
    AlertsShowSchemaResponseBodyFromJSON,
    AlertsShowSchemaResponseBodyToJSON,
    AlertsShowSourceConfigResponseBody,
    AlertsShowSourceConfigResponseBodyFromJSON,
    AlertsShowSourceConfigResponseBodyToJSON,
    AlertsSuggestAttributesRequestBody,
    AlertsSuggestAttributesRequestBodyFromJSON,
    AlertsSuggestAttributesRequestBodyToJSON,
    AlertsSuggestAttributesResponseBody,
    AlertsSuggestAttributesResponseBodyFromJSON,
    AlertsSuggestAttributesResponseBodyToJSON,
    AlertsTransitionIncidentAlertRequestBody,
    AlertsTransitionIncidentAlertRequestBodyFromJSON,
    AlertsTransitionIncidentAlertRequestBodyToJSON,
    AlertsUpdatePriorityRanksRequestBody,
    AlertsUpdatePriorityRanksRequestBodyFromJSON,
    AlertsUpdatePriorityRanksRequestBodyToJSON,
    AlertsUpdatePriorityRanksResponseBody,
    AlertsUpdatePriorityRanksResponseBodyFromJSON,
    AlertsUpdatePriorityRanksResponseBodyToJSON,
    AlertsUpdatePriorityRequestBody,
    AlertsUpdatePriorityRequestBodyFromJSON,
    AlertsUpdatePriorityRequestBodyToJSON,
    AlertsUpdatePriorityResponseBody,
    AlertsUpdatePriorityResponseBodyFromJSON,
    AlertsUpdatePriorityResponseBodyToJSON,
    AlertsUpdateSchemaRequestBody,
    AlertsUpdateSchemaRequestBodyFromJSON,
    AlertsUpdateSchemaRequestBodyToJSON,
    AlertsUpdateSchemaResponseBody,
    AlertsUpdateSchemaResponseBodyFromJSON,
    AlertsUpdateSchemaResponseBodyToJSON,
    AlertsUpdateSourceConfigRequestBody,
    AlertsUpdateSourceConfigRequestBodyFromJSON,
    AlertsUpdateSourceConfigRequestBodyToJSON,
    AlertsUpdateSourceConfigResponseBody,
    AlertsUpdateSourceConfigResponseBodyFromJSON,
    AlertsUpdateSourceConfigResponseBodyToJSON,
} from '../models';

export interface AlertsBulkPreviewSourceConfigAlertsRequest {
    bulkPreviewSourceConfigAlertsRequestBody: AlertsBulkPreviewSourceConfigAlertsRequestBody;
}

export interface AlertsCreateDraftSourceConfigRequest {
    createDraftSourceConfigRequestBody: AlertsCreateDraftSourceConfigRequestBody;
}

export interface AlertsCreatePriorityRequest {
    createPriorityRequestBody: AlertsCreatePriorityRequestBody;
}

export interface AlertsDeletePriorityRequest {
    id: string;
}

export interface AlertsDestroySourceConfigRequest {
    id: string;
}

export interface AlertsEvaluateRequest {
    id: string;
}

export interface AlertsGenerateIncidentDetailsFromAlertRequest {
    alertId: string;
}

export interface AlertsGetInsightsRequest {
    startDate: Date;
    endDate: Date;
    timezone: string;
    title?: string;
    deduplicationKey?: string;
}

export interface AlertsListAlertsRequest {
    pageSize?: number;
    after?: string;
    id?: any;
    fullTextSearch?: any;
    themes?: any;
    status?: any;
    alertSource?: any;
    createdAt?: any;
    attributes?: any;
    priority?: any;
    hasRelatedIncidents?: any;
    hasRelatedEscalations?: any;
}

export interface AlertsListIncidentAlertsRequest {
    incidentId?: string;
    alertId?: string;
    alertRouteId?: string;
}

export interface AlertsListPrioritiesRequest {
    includeArchived?: boolean;
}

export interface AlertsListTimelineItemsRequest {
    timezone: string;
    alertId: string;
}

export interface AlertsMarkSuggestionRequest {
    id: string;
    markSuggestionRequestBody: AlertsMarkSuggestionRequestBody;
}

export interface AlertsPreviewSourceConfigAlertsRequest {
    id: string;
    previewSourceConfigAlertsRequestBody: AlertsPreviewSourceConfigAlertsRequestBody;
}

export interface AlertsResolveRequest {
    id: string;
}

export interface AlertsSetDefaultPriorityRequest {
    id: string;
}

export interface AlertsShowAlertRequest {
    id: string;
    includePayload?: boolean;
}

export interface AlertsShowIncidentAlertRequest {
    id: string;
}

export interface AlertsShowSourceConfigRequest {
    id: string;
}

export interface AlertsSuggestAttributesRequest {
    id: string;
    suggestAttributesRequestBody: AlertsSuggestAttributesRequestBody;
}

export interface AlertsTransitionIncidentAlertRequest {
    id: string;
    transitionIncidentAlertRequestBody: AlertsTransitionIncidentAlertRequestBody;
}

export interface AlertsUpdatePriorityRequest {
    id: string;
    updatePriorityRequestBody: AlertsUpdatePriorityRequestBody;
}

export interface AlertsUpdatePriorityRanksRequest {
    updatePriorityRanksRequestBody: AlertsUpdatePriorityRanksRequestBody;
}

export interface AlertsUpdateSchemaRequest {
    updateSchemaRequestBody: AlertsUpdateSchemaRequestBody;
}

export interface AlertsUpdateSourceConfigRequest {
    id: string;
    updateSourceConfigRequestBody: AlertsUpdateSourceConfigRequestBody;
}

/**
 * 
 */
export class AlertsApi extends runtime.BaseAPI {

    /**
     * Preview the alerts for multiple alert source configs
     * BulkPreviewSourceConfigAlerts Alerts
     */
    async alertsBulkPreviewSourceConfigAlertsRaw(requestParameters: AlertsBulkPreviewSourceConfigAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsBulkPreviewSourceConfigAlertsResponseBody>> {
        if (requestParameters.bulkPreviewSourceConfigAlertsRequestBody === null || requestParameters.bulkPreviewSourceConfigAlertsRequestBody === undefined) {
            throw new runtime.RequiredError('bulkPreviewSourceConfigAlertsRequestBody','Required parameter requestParameters.bulkPreviewSourceConfigAlertsRequestBody was null or undefined when calling alertsBulkPreviewSourceConfigAlerts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_source_configs/actions/preview_alerts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsBulkPreviewSourceConfigAlertsRequestBodyToJSON(requestParameters.bulkPreviewSourceConfigAlertsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsBulkPreviewSourceConfigAlertsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Preview the alerts for multiple alert source configs
     * BulkPreviewSourceConfigAlerts Alerts
     */
    async alertsBulkPreviewSourceConfigAlerts(requestParameters: AlertsBulkPreviewSourceConfigAlertsRequest, initOverrides?: RequestInit): Promise<AlertsBulkPreviewSourceConfigAlertsResponseBody> {
        const response = await this.alertsBulkPreviewSourceConfigAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a draft alert source
     * CreateDraftSourceConfig Alerts
     */
    async alertsCreateDraftSourceConfigRaw(requestParameters: AlertsCreateDraftSourceConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsCreateDraftSourceConfigResponseBody>> {
        if (requestParameters.createDraftSourceConfigRequestBody === null || requestParameters.createDraftSourceConfigRequestBody === undefined) {
            throw new runtime.RequiredError('createDraftSourceConfigRequestBody','Required parameter requestParameters.createDraftSourceConfigRequestBody was null or undefined when calling alertsCreateDraftSourceConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_source_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsCreateDraftSourceConfigRequestBodyToJSON(requestParameters.createDraftSourceConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsCreateDraftSourceConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a draft alert source
     * CreateDraftSourceConfig Alerts
     */
    async alertsCreateDraftSourceConfig(requestParameters: AlertsCreateDraftSourceConfigRequest, initOverrides?: RequestInit): Promise<AlertsCreateDraftSourceConfigResponseBody> {
        const response = await this.alertsCreateDraftSourceConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a priority
     * CreatePriority Alerts
     */
    async alertsCreatePriorityRaw(requestParameters: AlertsCreatePriorityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsCreatePriorityResponseBody>> {
        if (requestParameters.createPriorityRequestBody === null || requestParameters.createPriorityRequestBody === undefined) {
            throw new runtime.RequiredError('createPriorityRequestBody','Required parameter requestParameters.createPriorityRequestBody was null or undefined when calling alertsCreatePriority.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alerts/priorities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsCreatePriorityRequestBodyToJSON(requestParameters.createPriorityRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsCreatePriorityResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a priority
     * CreatePriority Alerts
     */
    async alertsCreatePriority(requestParameters: AlertsCreatePriorityRequest, initOverrides?: RequestInit): Promise<AlertsCreatePriorityResponseBody> {
        const response = await this.alertsCreatePriorityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a priority
     * DeletePriority Alerts
     */
    async alertsDeletePriorityRaw(requestParameters: AlertsDeletePriorityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsDeletePriority.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/priorities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a priority
     * DeletePriority Alerts
     */
    async alertsDeletePriority(requestParameters: AlertsDeletePriorityRequest, initOverrides?: RequestInit): Promise<void> {
        await this.alertsDeletePriorityRaw(requestParameters, initOverrides);
    }

    /**
     * Archives an alert source configuration
     * DestroySourceConfig Alerts
     */
    async alertsDestroySourceConfigRaw(requestParameters: AlertsDestroySourceConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsDestroySourceConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_source_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an alert source configuration
     * DestroySourceConfig Alerts
     */
    async alertsDestroySourceConfig(requestParameters: AlertsDestroySourceConfigRequest, initOverrides?: RequestInit): Promise<void> {
        await this.alertsDestroySourceConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Re-evaluate an alert\'s attributes from its payload
     * Evaluate Alerts
     */
    async alertsEvaluateRaw(requestParameters: AlertsEvaluateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsEvaluateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsEvaluate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/{id}/actions/evaluate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsEvaluateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Re-evaluate an alert\'s attributes from its payload
     * Evaluate Alerts
     */
    async alertsEvaluate(requestParameters: AlertsEvaluateRequest, initOverrides?: RequestInit): Promise<AlertsEvaluateResponseBody> {
        const response = await this.alertsEvaluateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate incident details from an alert
     * GenerateIncidentDetailsFromAlert Alerts
     */
    async alertsGenerateIncidentDetailsFromAlertRaw(requestParameters: AlertsGenerateIncidentDetailsFromAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsGenerateIncidentDetailsFromAlertResponseBody>> {
        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling alertsGenerateIncidentDetailsFromAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/{alert_id}/actions/generate_incident_details`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsGenerateIncidentDetailsFromAlertResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generate incident details from an alert
     * GenerateIncidentDetailsFromAlert Alerts
     */
    async alertsGenerateIncidentDetailsFromAlert(requestParameters: AlertsGenerateIncidentDetailsFromAlertRequest, initOverrides?: RequestInit): Promise<AlertsGenerateIncidentDetailsFromAlertResponseBody> {
        const response = await this.alertsGenerateIncidentDetailsFromAlertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get insights for an alert
     * GetInsights Alerts
     */
    async alertsGetInsightsRaw(requestParameters: AlertsGetInsightsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsGetInsightsResponseBody>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling alertsGetInsights.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling alertsGetInsights.');
        }

        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling alertsGetInsights.');
        }

        const queryParameters: any = {};

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.deduplicationKey !== undefined) {
            queryParameters['deduplication_key'] = requestParameters.deduplicationKey;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/insights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsGetInsightsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get insights for an alert
     * GetInsights Alerts
     */
    async alertsGetInsights(requestParameters: AlertsGetInsightsRequest, initOverrides?: RequestInit): Promise<AlertsGetInsightsResponseBody> {
        const response = await this.alertsGetInsightsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List alerts
     * ListAlerts Alerts
     */
    async alertsListAlertsRaw(requestParameters: AlertsListAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsListAlertsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.themes !== undefined) {
            queryParameters['themes'] = requestParameters.themes;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.alertSource !== undefined) {
            queryParameters['alert_source'] = requestParameters.alertSource;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.attributes !== undefined) {
            queryParameters['attributes'] = requestParameters.attributes;
        }

        if (requestParameters.priority !== undefined) {
            queryParameters['priority'] = requestParameters.priority;
        }

        if (requestParameters.hasRelatedIncidents !== undefined) {
            queryParameters['has_related_incidents'] = requestParameters.hasRelatedIncidents;
        }

        if (requestParameters.hasRelatedEscalations !== undefined) {
            queryParameters['has_related_escalations'] = requestParameters.hasRelatedEscalations;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsListAlertsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List alerts
     * ListAlerts Alerts
     */
    async alertsListAlerts(requestParameters: AlertsListAlertsRequest, initOverrides?: RequestInit): Promise<AlertsListAlertsResponseBody> {
        const response = await this.alertsListAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List alert filter fields
     * ListFilterFields Alerts
     */
    async alertsListFilterFieldsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsListFilterFieldsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_filter_fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsListFilterFieldsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List alert filter fields
     * ListFilterFields Alerts
     */
    async alertsListFilterFields(initOverrides?: RequestInit): Promise<AlertsListFilterFieldsResponseBody> {
        const response = await this.alertsListFilterFieldsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incident alerts
     * ListIncidentAlerts Alerts
     */
    async alertsListIncidentAlertsRaw(requestParameters: AlertsListIncidentAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsListIncidentAlertsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.alertId !== undefined) {
            queryParameters['alert_id'] = requestParameters.alertId;
        }

        if (requestParameters.alertRouteId !== undefined) {
            queryParameters['alert_route_id'] = requestParameters.alertRouteId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_alerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsListIncidentAlertsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident alerts
     * ListIncidentAlerts Alerts
     */
    async alertsListIncidentAlerts(requestParameters: AlertsListIncidentAlertsRequest, initOverrides?: RequestInit): Promise<AlertsListIncidentAlertsResponseBody> {
        const response = await this.alertsListIncidentAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the organisation\'s escalation priorities
     * ListPriorities Alerts
     */
    async alertsListPrioritiesRaw(requestParameters: AlertsListPrioritiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsListPrioritiesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['include_archived'] = requestParameters.includeArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/priorities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsListPrioritiesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the organisation\'s escalation priorities
     * ListPriorities Alerts
     */
    async alertsListPriorities(requestParameters: AlertsListPrioritiesRequest, initOverrides?: RequestInit): Promise<AlertsListPrioritiesResponseBody> {
        const response = await this.alertsListPrioritiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List active alert sources
     * ListSourceConfigs Alerts
     */
    async alertsListSourceConfigsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsListSourceConfigsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_source_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsListSourceConfigsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List active alert sources
     * ListSourceConfigs Alerts
     */
    async alertsListSourceConfigs(initOverrides?: RequestInit): Promise<AlertsListSourceConfigsResponseBody> {
        const response = await this.alertsListSourceConfigsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List available alert sources
     * ListSources Alerts
     */
    async alertsListSourcesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsListSourcesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_sources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsListSourcesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List available alert sources
     * ListSources Alerts
     */
    async alertsListSources(initOverrides?: RequestInit): Promise<AlertsListSourcesResponseBody> {
        const response = await this.alertsListSourcesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List timeline items for an alert
     * ListTimelineItems Alerts
     */
    async alertsListTimelineItemsRaw(requestParameters: AlertsListTimelineItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsListTimelineItemsResponseBody>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling alertsListTimelineItems.');
        }

        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling alertsListTimelineItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/{alert_id}/timeline`.replace(`{${"alert_id"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsListTimelineItemsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List timeline items for an alert
     * ListTimelineItems Alerts
     */
    async alertsListTimelineItems(requestParameters: AlertsListTimelineItemsRequest, initOverrides?: RequestInit): Promise<AlertsListTimelineItemsResponseBody> {
        const response = await this.alertsListTimelineItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark a suggestion as accepted or declined
     * MarkSuggestion Alerts
     */
    async alertsMarkSuggestionRaw(requestParameters: AlertsMarkSuggestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsMarkSuggestionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsMarkSuggestion.');
        }

        if (requestParameters.markSuggestionRequestBody === null || requestParameters.markSuggestionRequestBody === undefined) {
            throw new runtime.RequiredError('markSuggestionRequestBody','Required parameter requestParameters.markSuggestionRequestBody was null or undefined when calling alertsMarkSuggestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_source_configs/{id}/actions/mark_suggestion`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsMarkSuggestionRequestBodyToJSON(requestParameters.markSuggestionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsMarkSuggestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Mark a suggestion as accepted or declined
     * MarkSuggestion Alerts
     */
    async alertsMarkSuggestion(requestParameters: AlertsMarkSuggestionRequest, initOverrides?: RequestInit): Promise<AlertsMarkSuggestionResponseBody> {
        const response = await this.alertsMarkSuggestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview the alerts that an alert source config might generate
     * PreviewSourceConfigAlerts Alerts
     */
    async alertsPreviewSourceConfigAlertsRaw(requestParameters: AlertsPreviewSourceConfigAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsPreviewSourceConfigAlertsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsPreviewSourceConfigAlerts.');
        }

        if (requestParameters.previewSourceConfigAlertsRequestBody === null || requestParameters.previewSourceConfigAlertsRequestBody === undefined) {
            throw new runtime.RequiredError('previewSourceConfigAlertsRequestBody','Required parameter requestParameters.previewSourceConfigAlertsRequestBody was null or undefined when calling alertsPreviewSourceConfigAlerts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_source_configs/{id}/actions/preview_alerts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsPreviewSourceConfigAlertsRequestBodyToJSON(requestParameters.previewSourceConfigAlertsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsPreviewSourceConfigAlertsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Preview the alerts that an alert source config might generate
     * PreviewSourceConfigAlerts Alerts
     */
    async alertsPreviewSourceConfigAlerts(requestParameters: AlertsPreviewSourceConfigAlertsRequest, initOverrides?: RequestInit): Promise<AlertsPreviewSourceConfigAlertsResponseBody> {
        const response = await this.alertsPreviewSourceConfigAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resolve an alert
     * Resolve Alerts
     */
    async alertsResolveRaw(requestParameters: AlertsResolveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsResolveResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsResolve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/{id}/actions/resolve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsResolveResponseBodyFromJSON(jsonValue));
    }

    /**
     * Resolve an alert
     * Resolve Alerts
     */
    async alertsResolve(requestParameters: AlertsResolveRequest, initOverrides?: RequestInit): Promise<AlertsResolveResponseBody> {
        const response = await this.alertsResolveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set an alert priority as the default
     * SetDefaultPriority Alerts
     */
    async alertsSetDefaultPriorityRaw(requestParameters: AlertsSetDefaultPriorityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsSetDefaultPriorityResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsSetDefaultPriority.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alerts/priorities/{id}/actions/make_default`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsSetDefaultPriorityResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set an alert priority as the default
     * SetDefaultPriority Alerts
     */
    async alertsSetDefaultPriority(requestParameters: AlertsSetDefaultPriorityRequest, initOverrides?: RequestInit): Promise<AlertsSetDefaultPriorityResponseBody> {
        const response = await this.alertsSetDefaultPriorityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show alert
     * ShowAlert Alerts
     */
    async alertsShowAlertRaw(requestParameters: AlertsShowAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsShowAlertResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsShowAlert.');
        }

        const queryParameters: any = {};

        if (requestParameters.includePayload !== undefined) {
            queryParameters['include_payload'] = requestParameters.includePayload;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsShowAlertResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show alert
     * ShowAlert Alerts
     */
    async alertsShowAlert(requestParameters: AlertsShowAlertRequest, initOverrides?: RequestInit): Promise<AlertsShowAlertResponseBody> {
        const response = await this.alertsShowAlertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an incident alert
     * ShowIncidentAlert Alerts
     */
    async alertsShowIncidentAlertRaw(requestParameters: AlertsShowIncidentAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsShowIncidentAlertResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsShowIncidentAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_alerts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsShowIncidentAlertResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an incident alert
     * ShowIncidentAlert Alerts
     */
    async alertsShowIncidentAlert(requestParameters: AlertsShowIncidentAlertRequest, initOverrides?: RequestInit): Promise<AlertsShowIncidentAlertResponseBody> {
        const response = await this.alertsShowIncidentAlertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the organisation\'s alert schema
     * ShowSchema Alerts
     */
    async alertsShowSchemaRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsShowSchemaResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_schema`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsShowSchemaResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the organisation\'s alert schema
     * ShowSchema Alerts
     */
    async alertsShowSchema(initOverrides?: RequestInit): Promise<AlertsShowSchemaResponseBody> {
        const response = await this.alertsShowSchemaRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the specified source config
     * ShowSourceConfig Alerts
     */
    async alertsShowSourceConfigRaw(requestParameters: AlertsShowSourceConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsShowSourceConfigResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsShowSourceConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alert_source_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsShowSourceConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the specified source config
     * ShowSourceConfig Alerts
     */
    async alertsShowSourceConfig(requestParameters: AlertsShowSourceConfigRequest, initOverrides?: RequestInit): Promise<AlertsShowSourceConfigResponseBody> {
        const response = await this.alertsShowSourceConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Suggest attributes for an alert source config
     * SuggestAttributes Alerts
     */
    async alertsSuggestAttributesRaw(requestParameters: AlertsSuggestAttributesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsSuggestAttributesResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsSuggestAttributes.');
        }

        if (requestParameters.suggestAttributesRequestBody === null || requestParameters.suggestAttributesRequestBody === undefined) {
            throw new runtime.RequiredError('suggestAttributesRequestBody','Required parameter requestParameters.suggestAttributesRequestBody was null or undefined when calling alertsSuggestAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_source_configs/{id}/actions/suggest_attributes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsSuggestAttributesRequestBodyToJSON(requestParameters.suggestAttributesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsSuggestAttributesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Suggest attributes for an alert source config
     * SuggestAttributes Alerts
     */
    async alertsSuggestAttributes(requestParameters: AlertsSuggestAttributesRequest, initOverrides?: RequestInit): Promise<AlertsSuggestAttributesResponseBody> {
        const response = await this.alertsSuggestAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition an incident alert by marking it as related or unrelated
     * TransitionIncidentAlert Alerts
     */
    async alertsTransitionIncidentAlertRaw(requestParameters: AlertsTransitionIncidentAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsTransitionIncidentAlert.');
        }

        if (requestParameters.transitionIncidentAlertRequestBody === null || requestParameters.transitionIncidentAlertRequestBody === undefined) {
            throw new runtime.RequiredError('transitionIncidentAlertRequestBody','Required parameter requestParameters.transitionIncidentAlertRequestBody was null or undefined when calling alertsTransitionIncidentAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_alerts/{id}/actions/transition`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsTransitionIncidentAlertRequestBodyToJSON(requestParameters.transitionIncidentAlertRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Transition an incident alert by marking it as related or unrelated
     * TransitionIncidentAlert Alerts
     */
    async alertsTransitionIncidentAlert(requestParameters: AlertsTransitionIncidentAlertRequest, initOverrides?: RequestInit): Promise<void> {
        await this.alertsTransitionIncidentAlertRaw(requestParameters, initOverrides);
    }

    /**
     * Update a priority
     * UpdatePriority Alerts
     */
    async alertsUpdatePriorityRaw(requestParameters: AlertsUpdatePriorityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsUpdatePriorityResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsUpdatePriority.');
        }

        if (requestParameters.updatePriorityRequestBody === null || requestParameters.updatePriorityRequestBody === undefined) {
            throw new runtime.RequiredError('updatePriorityRequestBody','Required parameter requestParameters.updatePriorityRequestBody was null or undefined when calling alertsUpdatePriority.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alerts/priorities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsUpdatePriorityRequestBodyToJSON(requestParameters.updatePriorityRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsUpdatePriorityResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a priority
     * UpdatePriority Alerts
     */
    async alertsUpdatePriority(requestParameters: AlertsUpdatePriorityRequest, initOverrides?: RequestInit): Promise<AlertsUpdatePriorityResponseBody> {
        const response = await this.alertsUpdatePriorityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of priorities
     * UpdatePriorityRanks Alerts
     */
    async alertsUpdatePriorityRanksRaw(requestParameters: AlertsUpdatePriorityRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsUpdatePriorityRanksResponseBody>> {
        if (requestParameters.updatePriorityRanksRequestBody === null || requestParameters.updatePriorityRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updatePriorityRanksRequestBody','Required parameter requestParameters.updatePriorityRanksRequestBody was null or undefined when calling alertsUpdatePriorityRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alerts/priorities/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsUpdatePriorityRanksRequestBodyToJSON(requestParameters.updatePriorityRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsUpdatePriorityRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of priorities
     * UpdatePriorityRanks Alerts
     */
    async alertsUpdatePriorityRanks(requestParameters: AlertsUpdatePriorityRanksRequest, initOverrides?: RequestInit): Promise<AlertsUpdatePriorityRanksResponseBody> {
        const response = await this.alertsUpdatePriorityRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the organisation\'s alert schema
     * UpdateSchema Alerts
     */
    async alertsUpdateSchemaRaw(requestParameters: AlertsUpdateSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsUpdateSchemaResponseBody>> {
        if (requestParameters.updateSchemaRequestBody === null || requestParameters.updateSchemaRequestBody === undefined) {
            throw new runtime.RequiredError('updateSchemaRequestBody','Required parameter requestParameters.updateSchemaRequestBody was null or undefined when calling alertsUpdateSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_schema`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsUpdateSchemaRequestBodyToJSON(requestParameters.updateSchemaRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsUpdateSchemaResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the organisation\'s alert schema
     * UpdateSchema Alerts
     */
    async alertsUpdateSchema(requestParameters: AlertsUpdateSchemaRequest, initOverrides?: RequestInit): Promise<AlertsUpdateSchemaResponseBody> {
        const response = await this.alertsUpdateSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an alert source configuration
     * UpdateSourceConfig Alerts
     */
    async alertsUpdateSourceConfigRaw(requestParameters: AlertsUpdateSourceConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertsUpdateSourceConfigResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alertsUpdateSourceConfig.');
        }

        if (requestParameters.updateSourceConfigRequestBody === null || requestParameters.updateSourceConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateSourceConfigRequestBody','Required parameter requestParameters.updateSourceConfigRequestBody was null or undefined when calling alertsUpdateSourceConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/alert_source_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlertsUpdateSourceConfigRequestBodyToJSON(requestParameters.updateSourceConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertsUpdateSourceConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an alert source configuration
     * UpdateSourceConfig Alerts
     */
    async alertsUpdateSourceConfig(requestParameters: AlertsUpdateSourceConfigRequest, initOverrides?: RequestInit): Promise<AlertsUpdateSourceConfigResponseBody> {
        const response = await this.alertsUpdateSourceConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
