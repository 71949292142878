import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum } from "@incident-ui";
import React, { useState } from "react";
import graphic from "src/components/settings/banners/banner-api-keys.svg";
import { useIdentity } from "src/contexts/IdentityContext";

import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { APIKeyList } from "./APIKeyList";
import { APIKeysCreateModal } from "./APIKeysCreateModal";

export const APIKeysPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const canUseAPI = identity?.feature_gates.api_access;

  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <SettingsSubPageWrapper
      accessory={
        <GatedButton
          onClick={() => setShowCreateModal(true)}
          analyticsTrackingId="create-api-key"
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.ApiKeysCreate}
          upgradeRequired={!canUseAPI}
          upgradeRequiredProps={{
            gate: { type: "boolean" },
            featureName: "API Access",
          }}
          icon={IconEnum.Add}
        >
          Add new
        </GatedButton>
      }
    >
      <SettingsHeading
        title="Leverage our APIs"
        subtitle="Use our APIs to create custom automations and streamline your processes."
        graphic={<img src={graphic} className="h-40" />}
        planNameMustUpgradeTo={"Team"}
        requiresUpgrade={!identity?.feature_gates.api_access}
        learnMoreURL="https://api-docs.incident.io/"
      />
      {canUseAPI ? <APIKeyList /> : <></>}
      {showCreateModal && (
        <APIKeysCreateModal onClose={() => setShowCreateModal(false)} />
      )}
    </SettingsSubPageWrapper>
  );
};
