import type { SVGProps } from "react";
import * as React from "react";
const SvgTimer = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.75 5.042V4h1.5a.75.75 0 0 0 0-1.5h-4.5a.75.75 0 0 0 0 1.5h1.5v1.042C4.742 5.418 2 8.394 2 12c0 3.86 3.14 7 7 7s7-3.14 7-7c0-3.606-2.742-6.583-6.25-6.958Zm-.22 7.489a.748.748 0 0 1-1.06 0l-2.298-2.298a.75.75 0 1 1 1.061-1.061l2.298 2.298a.75.75 0 0 1 0 1.061H9.53ZM16.25 7.5a.744.744 0 0 1-.53-.22l-2-2a.75.75 0 1 1 1.061-1.061l2 2a.75.75 0 0 1-.531 1.28Z"
    />
  </svg>
);
export default SvgTimer;
