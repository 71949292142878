import {
  Incident,
  IncidentModeEnum,
  IncidentRelationship,
  IncidentRelationshipStatusEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  DropdownMenu,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
  StackedListItem,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import React, { useState } from "react";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "src/routes/legacy/IncidentRoute";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { Sidebar } from "../Components";
import { LinkBuilder, LinkBuilderProps } from "./builders";

const RelatedIncidents = (props: LinkBuilderProps): React.ReactElement => {
  return (
    <Sidebar.Entry
      label="Related incidents"
      value={<RelatedIncidentsValue {...props} />}
    />
  );
};

const RelatedIncidentsValue = ({
  incident,
  relationships,
}: LinkBuilderProps): React.ReactElement => {
  const [deletingRelationship, setDeletingRelationship] =
    useState<IncidentRelationship | null>(null);
  const navigateToModal = useNavigateToModal();

  const { trigger: onRemove } = useAPIMutation(
    "incidentRelationshipsList",
    { incidentId: incident.id ?? "" },
    async (apiClient, relationship: IncidentRelationship) => {
      await apiClient.incidentRelationshipsDestroy({ id: relationship.id });
    },
    {
      onSuccess: () => setDeletingRelationship(null),
    },
  );

  const confirmed = relationships.filter(
    (relationship) =>
      relationship.status === IncidentRelationshipStatusEnum.Confirmed,
  );

  if (confirmed.length === 0) {
    return (
      <Sidebar.EmptyValueButton
        onClick={() => {
          navigateToModal(IncidentHeaderModal.AddRelatedIncident);
        }}
        analyticsTrackingId="link-incident"
      >
        Add
      </Sidebar.EmptyValueButton>
    );
  }

  if (confirmed.length === 1) {
    const relationship = confirmed[0];
    const relatedInc =
      incident.id === relationship.left_incident_details.id
        ? relationship.right_incident_details
        : relationship.left_incident_details;
    return (
      <>
        <DropdownMenu
          triggerButton={
            <Sidebar.ValueButton
              icon={IconEnum.SeverityLow}
              analyticsTrackingId="view-related-incident"
              loading={deletingRelationship != null}
              className="truncate w-full"
            >
              INC-{relatedInc.externalId}: {relatedInc.name}
            </Sidebar.ValueButton>
          }
        >
          <PopoverItem
            openInNewTab
            to={`/incidents/${relatedInc.externalId}`}
            suffix={<Icon id={IconEnum.ExternalLink} size={IconSize.Medium} />}
          >
            View incident
          </PopoverItem>
          <PopoverItem
            onClick={() => setDeletingRelationship(relationship)}
            suffix={<Icon id={IconEnum.LinkBreak} size={IconSize.Medium} />}
          >
            Unlink
          </PopoverItem>
        </DropdownMenu>
        {deletingRelationship != null && (
          <ConfirmationDialog
            title="Remove incident relationship"
            analyticsTrackingId="remove-relationship"
            isOpen={!!deletingRelationship}
            onCancel={() => setDeletingRelationship(null)}
            onConfirm={() => onRemove(deletingRelationship)}
            confirmButtonText="Unlink"
          >
            <p>
              Remove the link between{" "}
              <span className="font-semibold">
                INC-
                {deletingRelationship.left_incident_details.externalId}
              </span>
              {" and "}
              <span className="font-semibold">
                INC-{deletingRelationship.right_incident_details.externalId}?
              </span>
            </p>
          </ConfirmationDialog>
        )}
      </>
    );
  }

  return (
    <Sidebar.ValueButton
      icon={IconEnum.SeverityLow}
      onClick={() => navigateToModal(IncidentDrawer.RelatedIncidents)}
      analyticsTrackingId={"view-related-incident"}
    >
      {confirmed.length}
    </Sidebar.ValueButton>
  );
};

export const RelatedIncidentsDrawer = ({
  incident,
  onClose,
}: {
  incident: Incident | null;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: { incident_relationships: relationships },
    isLoading: relationshipsLoading,
  } = useAPI(
    incident ? "incidentRelationshipsList" : null,
    { incidentId: incident?.id ?? "" },
    { fallbackData: { incident_relationships: [] } },
  );

  const [deletingRelationship, setDeletingRelationship] =
    useState<IncidentRelationship | null>(null);

  const { trigger: onRemove } = useAPIMutation(
    "incidentRelationshipsList",
    { incidentId: incident?.id ?? "" },
    async (apiClient, relationship: IncidentRelationship) => {
      await apiClient.incidentRelationshipsDestroy({ id: relationship.id });
    },
    {
      onSuccess: () => setDeletingRelationship(null),
    },
  );

  return (
    <Drawer width="medium" onClose={onClose}>
      {relationshipsLoading ? (
        <DrawerContentsLoading />
      ) : (
        <DrawerContents>
          <DrawerTitle
            icon={IconEnum.Link}
            title="Related incidents"
            onClose={onClose}
          />
          <DrawerBody>
            {relationships.length === 0 ? (
              <EmptyState content="No related incidents" />
            ) : (
              <StackedList>
                {relationships.map((relationship) => {
                  const relatedInc =
                    incident?.id === relationship.left_incident_details.id
                      ? relationship.right_incident_details
                      : relationship.left_incident_details;

                  return (
                    <StackedListItem
                      key={relationship.id}
                      title={`INC-${relatedInc.externalId}: ${relatedInc.name}`}
                      icon={IconEnum.Incident}
                      accessory={
                        <>
                          <Tooltip content="View incident">
                            <Button
                              theme={ButtonTheme.Tertiary}
                              openInNewTab
                              icon={IconEnum.ExternalLink}
                              title=""
                              href={`/incidents/${relatedInc.externalId}`}
                              analyticsTrackingId={"view-related-incident"}
                            />
                          </Tooltip>
                          <Tooltip content="Unlink incident">
                            <Button
                              theme={ButtonTheme.Tertiary}
                              onClick={() =>
                                setDeletingRelationship(relationship)
                              }
                              icon={IconEnum.LinkBreak}
                              title=""
                              analyticsTrackingId={"remove-related-incident"}
                            />
                          </Tooltip>
                        </>
                      }
                    />
                  );
                })}
              </StackedList>
            )}
          </DrawerBody>
        </DrawerContents>
      )}
      {deletingRelationship != null && (
        <ConfirmationDialog
          title="Remove incident relationship"
          analyticsTrackingId="remove-relationship"
          isOpen={!!deletingRelationship}
          onCancel={() => setDeletingRelationship(null)}
          onConfirm={() => onRemove(deletingRelationship)}
          confirmButtonText="Unlink"
        >
          <p>
            Remove the link between{" "}
            <span className="font-semibold">
              INC-
              {deletingRelationship.left_incident_details.externalId}
            </span>
            {" and "}
            <span className="font-semibold">
              INC-{deletingRelationship.right_incident_details.externalId}?
            </span>
          </p>
        </ConfirmationDialog>
      )}
    </Drawer>
  );
};

const addRelatedIncidentItem = ({ navigateToModal }: LinkBuilderProps) => {
  return [
    {
      icon: IconEnum.Incident,
      onSelect: () => {
        navigateToModal(IncidentHeaderModal.AddRelatedIncident);
      },
      analyticsTrackingId: "link-incident",
      label: "Add related incident",
    },
  ];
};

export const RelatedIncidentsBuilder: LinkBuilder = {
  Render: RelatedIncidents,
  visible: (props: LinkBuilderProps) =>
    props.incident.mode !== IncidentModeEnum.Tutorial,
  requiredProduct: Product.Response,
  getDropdownItems: addRelatedIncidentItem,
};
