/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSummarySuggestion,
    IncidentSummarySuggestionFromJSON,
    IncidentSummarySuggestionFromJSONTyped,
    IncidentSummarySuggestionToJSON,
} from './IncidentSummarySuggestion';

/**
 * 
 * @export
 * @interface IncidentsGetSummarySuggestionResponseBody
 */
export interface IncidentsGetSummarySuggestionResponseBody {
    /**
     * 
     * @type {boolean}
     * @memberof IncidentsGetSummarySuggestionResponseBody
     */
    has_feature: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentsGetSummarySuggestionResponseBody
     */
    has_suggestion: boolean;
    /**
     * 
     * @type {IncidentSummarySuggestion}
     * @memberof IncidentsGetSummarySuggestionResponseBody
     */
    suggestion?: IncidentSummarySuggestion;
}

export function IncidentsGetSummarySuggestionResponseBodyFromJSON(json: any): IncidentsGetSummarySuggestionResponseBody {
    return IncidentsGetSummarySuggestionResponseBodyFromJSONTyped(json, false);
}

export function IncidentsGetSummarySuggestionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsGetSummarySuggestionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'has_feature': json['has_feature'],
        'has_suggestion': json['has_suggestion'],
        'suggestion': !exists(json, 'suggestion') ? undefined : IncidentSummarySuggestionFromJSON(json['suggestion']),
    };
}

export function IncidentsGetSummarySuggestionResponseBodyToJSON(value?: IncidentsGetSummarySuggestionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'has_feature': value.has_feature,
        'has_suggestion': value.has_suggestion,
        'suggestion': IncidentSummarySuggestionToJSON(value.suggestion),
    };
}

