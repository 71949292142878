/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostIncidentTaskOption,
    PostIncidentTaskOptionFromJSON,
    PostIncidentTaskOptionFromJSONTyped,
    PostIncidentTaskOptionToJSON,
} from './PostIncidentTaskOption';

/**
 * 
 * @export
 * @interface PostIncidentFlowListTaskOptionsResponseBody
 */
export interface PostIncidentFlowListTaskOptionsResponseBody {
    /**
     * 
     * @type {Array<PostIncidentTaskOption>}
     * @memberof PostIncidentFlowListTaskOptionsResponseBody
     */
    task_options: Array<PostIncidentTaskOption>;
}

export function PostIncidentFlowListTaskOptionsResponseBodyFromJSON(json: any): PostIncidentFlowListTaskOptionsResponseBody {
    return PostIncidentFlowListTaskOptionsResponseBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowListTaskOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowListTaskOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'task_options': ((json['task_options'] as Array<any>).map(PostIncidentTaskOptionFromJSON)),
    };
}

export function PostIncidentFlowListTaskOptionsResponseBodyToJSON(value?: PostIncidentFlowListTaskOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_options': ((value.task_options as Array<any>).map(PostIncidentTaskOptionToJSON)),
    };
}

