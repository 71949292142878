import Dagre, { graphlib } from "@dagrejs/dagre";
import {
  EscalationPathNodeTypeEnum as NodeTypes,
  Priority,
} from "@incident-io/api";
import { compact, groupBy, mapValues, max } from "lodash";
import type { Edge, Node } from "reactflow";
import { assertUnreachable } from "src/utils/utils";

import {
  EscalationPathTargetFormData,
  PathEdge,
  PathNode,
  ReactFlowDataType,
  ReactFlowNodeCustomType,
  ReactFlowNodeType,
} from "../../common/types";
import { conditionToLabel } from "../../nodes/IfElseConditionPopover";

const NODE_WIDTH = 400;

export const getLayoutElements = ({
  nodes,
  priorities,
}: {
  nodes: Record<string, PathNode>;
  priorities: Priority[];
}) => {
  const g = new Dagre.graphlib.Graph<ReactFlowDataType>();
  // We don't care for edge/node labels in Dagre-land, this is just to do
  // layouts for us. Therefore the labels are the IDs of the nodes/edges.
  g.setDefaultEdgeLabel(() => ({}));
  g.setGraph({
    rankdir: "TB",
    ranker: "network-simplex",
    nodesep: 75,
    ranksep: 75,
  });

  const terminusNodeIds: string[] = [];

  for (const [id, node] of Object.entries(nodes)) {
    setNode(g, id, {
      width: NODE_WIDTH,
      height: nodeHeight({ node }), // height based on node type
      ...node.data,
    } as Dagre.Node<ReactFlowDataType>);

    switch (node.data.nodeType) {
      case NodeTypes.Repeat:
        // No children to add!
        terminusNodeIds.push(addTerminus(g, node));
        break;

      case NodeTypes.Level:
        // If there's a level after, use that
        if (node.data.level.nextNodeId) {
          g.setEdge(id, node.data.level.nextNodeId);
        } else {
          terminusNodeIds.push(addTerminus(g, node));
        }
        break;

      case NodeTypes.NotifyChannel:
        // If there's a notify channel after, use that
        if (node.data.notifyChannel.nextNodeId) {
          g.setEdge(id, node.data.notifyChannel.nextNodeId);
        } else {
          terminusNodeIds.push(addTerminus(g, node));
        }
        break;

      case NodeTypes.IfElse:
        // Edge to each branch
        const [thenLabel, elseLabel] = node.data.ifElse.conditionType
          ? [
              conditionToLabel(
                node.data.ifElse.conditionType,
                node.data.ifElse.priorityIds ?? [],
                priorities,
                false,
              ),
              conditionToLabel(
                node.data.ifElse.conditionType,
                node.data.ifElse.priorityIds ?? [],
                priorities,
                true,
              ),
            ]
          : ["Then", "Else"];

        g.setEdge(id, node.data.ifElse.thenNodeId, {
          label: thenLabel,
          conditionResult: "left",
        });
        g.setEdge(id, node.data.ifElse.elseNodeId, {
          label: elseLabel,
          conditionResult: "right",
        });
        break;

      default:
        assertUnreachable(node.data);
    }
  }

  // Run layout once, with just one set of terminus nodes. This calculates the
  // ranks of each node for us.
  Dagre.layout(g);

  // Add extra terminus nodes to ensure the layout totally separates each branch
  fillBranches(g, terminusNodeIds);

  // Run the layout again: this will ensure that each branch doesn't overlap with other branches
  Dagre.layout(g);

  const nodesByRank = groupBy(g.nodes(), (node) => g.node(node).rank);
  const maxHeightByRank = mapValues(nodesByRank, (nodeIds) => {
    return Math.max(...nodeIds.map((nodeId) => g.node(nodeId).height));
  });

  const flowNodes: Node<ReactFlowDataType>[] = g.nodes().map((nodeId) => {
    const node = g.node(nodeId);
    // We want to align to the top of the tallest node in the rank, rather
    // than aligning the centers of nodes
    const maxHeightInRank = node.rank
      ? maxHeightByRank[node.rank]
      : node.height;

    return {
      id: nodeId,
      position: {
        // Dagre is center-of-node positioning, ReactFlow is top-left
        x: node.x - NODE_WIDTH / 2,
        y: node.y - maxHeightInRank / 2,
      },
      type: "customNode",
      data: node,
    };
  });

  const flowEdges: Edge<PathEdge>[] = compact(
    g.edges().map((edge) => {
      const data = g.edge(edge);

      // Strip out terminus-to-terminus edges: these are layout only so just make React Flow mildly unhappy
      if (data.skipRender) {
        return undefined;
      }

      return {
        id: `${edge.v}-to-${edge.w}`,
        source: edge.v,
        target: edge.w,
        type: "customEdge",
        data: {
          label: data.label as string,
          conditionResult: data.conditionResult as "left" | "right" | undefined,
        } as PathEdge,
      };
    }),
  );

  return {
    flowNodes,
    flowEdges,
  };
};
const TERMINUS_NODE_HEIGHT = 200;

// addTerminus adds a terminus node to the graph, which is a special node which
// isn't visible produces a `-> End` edge, and also forces the layout to behave
// better
const addTerminus = (
  g: Dagre.graphlib.Graph<ReactFlowDataType>,
  node: ReactFlowNodeType,
) => {
  const sourceNodeId = node.id;

  const terminusNodeId = `terminus-${sourceNodeId}`;
  setNode(g, terminusNodeId, {
    width: NODE_WIDTH,
    height: TERMINUS_NODE_HEIGHT,
    nodeType: ReactFlowNodeCustomType.Terminus,
  });
  g.setEdge(sourceNodeId, terminusNodeId, { label: "End" });
  return terminusNodeId;
};

// fillBranches ensures that every branch has the same length, by adding in fake
// terminus nodes.
//
// This ensures that when the layout runs, every branch is separated
// horizontally, and we don't have (e.g.) a long branch rendering underneath a
// shorter one
const fillBranches = (
  g: graphlib.Graph<ReactFlowDataType>,
  terminusNodeIds: string[],
) => {
  // Now go through and add terminus nodes to ensure that every branch has the same length.
  const maxRank = max(g.nodes().map((nodeId) => g.node(nodeId).rank)) ?? 1;
  const existingRanks = new Set(g.nodes().map((nodeId) => g.node(nodeId).rank));

  for (const terminusNodeId of terminusNodeIds) {
    const terminusNode = g.node(terminusNodeId);

    let rank = terminusNode.rank ?? 0;
    let prevNodeId = terminusNodeId;

    while (rank <= maxRank) {
      // If no node exists at this rank, skip it. We don't want to increase the
      // overall number of ranks during this process: that would change the
      // shape of the tree, rather than just balancing it out.
      if (!existingRanks.has(rank)) {
        rank++;
        continue;
      }

      const nextNodeId = `${terminusNodeId}-${rank}`;
      setNode(g, nextNodeId, {
        width: NODE_WIDTH,
        height: TERMINUS_NODE_HEIGHT,
        nodeType: ReactFlowNodeCustomType.Terminus,
      });
      g.setEdge(prevNodeId, nextNodeId, { label: "", skipRender: true });

      rank++;
      prevNodeId = nextNodeId;
    }
  }
};

// g.setNode isn't typed strictly, so this helps us not get it wrong
const setNode = (
  g: Dagre.graphlib.Graph<ReactFlowDataType>,
  id: string,
  node: Omit<Dagre.Node<ReactFlowDataType>, "x" | "y">, // x and y seem to not matter
) => {
  g.setNode(id, node);
};

// Ideally, we'd be using a ResizeObserver on the nodes to be able to directly
// get their exact heights, but this works fine for now and is a bit simpler. We
// have to position the nodes before we actually render them, which is why we
// make this estimation here.
const nodeHeight = ({ node }: { node: PathNode }) => {
  switch (node.data.nodeType) {
    case NodeTypes.Level:
      return heightFromTargets({ targets: node.data.level.targets });
    case NodeTypes.NotifyChannel:
      return heightFromTargets({ targets: node.data.notifyChannel.targets });
    case NodeTypes.IfElse:
      return 122;
    case NodeTypes.Repeat:
      return 118;
    default:
      return 118;
  }
};

// Available width for target badges is 300px. Each badge consists of
// [33px <-> Label <-> 31px]. There is a 4px spacing between badges. So to
// calculate the number of lines that the target badges take up, we calculate
// a "total width" that the targets would take up if they were all on a single
// line (each target is (33+31+4=72px) + (numCharacters * 6px)). We then divide
// this by 300 and round up to get the number of lines over which the targets
// will be spread. Each line has a height of ~30px.
const heightFromTargets = ({
  targets,
}: {
  targets: EscalationPathTargetFormData[];
}) => {
  let totalWidth = 0;
  targets.forEach((target) => {
    totalWidth += 72;
    totalWidth += target.label.length * 10; // characters are up to ~10px wide
  });

  const numLines = Math.ceil(totalWidth / 300);

  return 166 + 30 * numLines;
};
