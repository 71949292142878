import { FiltersContextProvider } from "@incident-shared/filters";
import { GenericErrorMessage } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useFormContext } from "react-hook-form";

import { useGetPanelFilterFields } from "./useGetPanelFilterFields";
import { EditDashboardFormData } from "./useInsightsContext";

export const PanelFiltersContextProvider = ({
  panelIdx,
  children,
}: React.PropsWithChildren<{
  panelIdx: number;
}>) => {
  const { featureInsightsPanelFiltering } = useFlags();

  const formMethods = useFormContext<EditDashboardFormData>();
  const panel = formMethods.watch(`panels.${panelIdx}`);

  // Populate what filters we can choose for this panel
  const { filterFields, filtersLoading, filtersError } =
    useGetPanelFilterFields({
      panels: [panel],
    });

  if (!featureInsightsPanelFiltering) {
    // if the feature is not enabled, lets not override the filters
    return <>{children}</>;
  }

  if (filtersError) {
    return <GenericErrorMessage />;
  }

  return (
    <FiltersContextProvider
      availableFilterFields={filterFields}
      filtersLoading={filtersLoading}
      filters={panel.filters}
      kind="insights"
      setFilters={(newFields) => {
        formMethods.setValue(`panels.${panelIdx}.filters`, newFields);
      }}
    >
      {children}
    </FiltersContextProvider>
  );
};
