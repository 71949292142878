import _ from "lodash";
import { useEffect, useState } from "react";
import { FieldValues, FormState } from "react-hook-form";

// useIsDirtyWithTemplatedText is a custom hook that determines if a form is dirty, taking into
// account templated text fields (which aren't correctly compared using the react isDirty check). It
// can be used like so:
//
//   const isDirty = useIsDirtyWithTemplatedText({
//    values: formMethods.getValues(),
//    formState: formMethods.formState,
//    textFieldNames: ["event_title", "event_description"],
//  });
export function useIsDirtyWithTemplatedText<TFormType extends FieldValues>({
  values,
  formState,
  textFieldNames,
}: {
  values: TFormType;
  formState: FormState<TFormType>;
  textFieldNames: string[];
}): boolean {
  const { isDirty: originalIsDirty, defaultValues, dirtyFields } = formState;

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    // If there's no default values then we'll fall back to the behaviour of the original isDirty
    // check.
    if (!defaultValues) {
      setIsDirty(originalIsDirty);
      return;
    }

    // Otherwise, the form should be dirty if any of the non-text fields are dirty,
    // or if any of the text fields do not match the default values.
    setIsDirty(
      Object.keys(dirtyFields).some((key) => !textFieldNames.includes(key)) ||
        textFieldNames.some((key) => {
          // We cannot simply use isEqual because it fails to identify when objects like this are
          // the same:
          // A: { type: "text", marks: undefined }
          // B: { type: "text" }
          // Therefore we convert into JSON and back to drop the undefined fields.
          const defaultValue = JSON.parse(
            JSON.stringify(defaultValues[key] ?? ""),
          );
          const actualValue = JSON.parse(JSON.stringify(values[key] ?? ""));
          return !_.isEqual(defaultValue, actualValue);
        }),
    );
  }, [defaultValues, dirtyFields, originalIsDirty, values, textFieldNames]);

  return isDirty;
}
