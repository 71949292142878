/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlackTeamConfig
 */
export interface SlackTeamConfig {
    /**
     * If this Slack Team has been enabled for the organisation (relevant for Enterprise Grid)
     * @type {boolean}
     * @memberof SlackTeamConfig
     */
    enabled: boolean;
    /**
     * URL to the team's icon
     * @type {string}
     * @memberof SlackTeamConfig
     */
    icon_url: string;
    /**
     * Name of the team
     * @type {string}
     * @memberof SlackTeamConfig
     */
    name: string;
    /**
     * Slack's ID for the team
     * @type {string}
     * @memberof SlackTeamConfig
     */
    slack_team_id: string;
}

export function SlackTeamConfigFromJSON(json: any): SlackTeamConfig {
    return SlackTeamConfigFromJSONTyped(json, false);
}

export function SlackTeamConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackTeamConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'icon_url': json['icon_url'],
        'name': json['name'],
        'slack_team_id': json['slack_team_id'],
    };
}

export function SlackTeamConfigToJSON(value?: SlackTeamConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'icon_url': value.icon_url,
        'name': value.name,
        'slack_team_id': value.slack_team_id,
    };
}

