import type { SVGProps } from "react";
import * as React from "react";
const SvgBriefcase = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.75 7.5a.75.75 0 0 1-.75-.75v-2.5a.25.25 0 0 0-.25-.25h-3.5a.25.25 0 0 0-.25.25v2.5a.75.75 0 0 1-1.5 0v-2.5c0-.965.785-1.75 1.75-1.75h3.5c.965 0 1.75.785 1.75 1.75v2.5a.75.75 0 0 1-.75.75Z"
    />
    <path
      fill="currentColor"
      d="M18 9.519V8.75A2.752 2.752 0 0 0 15.25 6H4.75A2.752 2.752 0 0 0 2 8.75v.769a17.136 17.136 0 0 0 16 0Z"
    />
    <path
      fill="currentColor"
      d="M10 13c-2.864 0-5.57-.665-8-1.818v4.068A2.752 2.752 0 0 0 4.75 18h10.5A2.752 2.752 0 0 0 18 15.25v-4.068A18.606 18.606 0 0 1 10 13Z"
    />
  </svg>
);
export default SvgBriefcase;
