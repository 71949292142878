/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Handover
 */
export interface Handover {
    /**
     * 
     * @type {User}
     * @memberof Handover
     */
    from_user: User;
    /**
     * the summary of the handover
     * @type {string}
     * @memberof Handover
     */
    summary?: string;
    /**
     * 
     * @type {User}
     * @memberof Handover
     */
    to_user: User;
    /**
     * 
     * @type {Actor}
     * @memberof Handover
     */
    updater: Actor;
}

export function HandoverFromJSON(json: any): Handover {
    return HandoverFromJSONTyped(json, false);
}

export function HandoverFromJSONTyped(json: any, ignoreDiscriminator: boolean): Handover {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from_user': UserFromJSON(json['from_user']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'to_user': UserFromJSON(json['to_user']),
        'updater': ActorFromJSON(json['updater']),
    };
}

export function HandoverToJSON(value?: Handover | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_user': UserToJSON(value.from_user),
        'summary': value.summary,
        'to_user': UserToJSON(value.to_user),
        'updater': ActorToJSON(value.updater),
    };
}

