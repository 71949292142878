/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RankUpdate,
    RankUpdateFromJSON,
    RankUpdateFromJSONTyped,
    RankUpdateToJSON,
} from './RankUpdate';

/**
 * 
 * @export
 * @interface PostmortemsUpdateTemplateSectionRanksRequestBody
 */
export interface PostmortemsUpdateTemplateSectionRanksRequestBody {
    /**
     * 
     * @type {Array<RankUpdate>}
     * @memberof PostmortemsUpdateTemplateSectionRanksRequestBody
     */
    rank_updates: Array<RankUpdate>;
}

export function PostmortemsUpdateTemplateSectionRanksRequestBodyFromJSON(json: any): PostmortemsUpdateTemplateSectionRanksRequestBody {
    return PostmortemsUpdateTemplateSectionRanksRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsUpdateTemplateSectionRanksRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsUpdateTemplateSectionRanksRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rank_updates': ((json['rank_updates'] as Array<any>).map(RankUpdateFromJSON)),
    };
}

export function PostmortemsUpdateTemplateSectionRanksRequestBodyToJSON(value?: PostmortemsUpdateTemplateSectionRanksRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rank_updates': ((value.rank_updates as Array<any>).map(RankUpdateToJSON)),
    };
}

