import { Link } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const AttributeExternalLink = ({
  label,
  className,
}: {
  label: string;
  className?: string;
}) => {
  const href = /^https?:\/\//.test(label) ? label : `https://${label}`;

  return (
    <Link
      href={href}
      className={tcx("text-slate-600 underline block", className)}
      onClick={(e) => e.stopPropagation()}
      openInNewTab
      analyticsTrackingId="catalog-entry-external-link"
    >
      {label}
    </Link>
  );
};
