/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScimConfig,
    ScimConfigFromJSON,
    ScimConfigFromJSONTyped,
    ScimConfigToJSON,
} from './ScimConfig';

/**
 * 
 * @export
 * @interface SCIMShowSettingsResponseBody
 */
export interface SCIMShowSettingsResponseBody {
    /**
     * Is SCIM enabled?
     * @type {boolean}
     * @memberof SCIMShowSettingsResponseBody
     */
    enabled: boolean;
    /**
     * 
     * @type {ScimConfig}
     * @memberof SCIMShowSettingsResponseBody
     */
    scim_config?: ScimConfig;
}

export function SCIMShowSettingsResponseBodyFromJSON(json: any): SCIMShowSettingsResponseBody {
    return SCIMShowSettingsResponseBodyFromJSONTyped(json, false);
}

export function SCIMShowSettingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SCIMShowSettingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'scim_config': !exists(json, 'scim_config') ? undefined : ScimConfigFromJSON(json['scim_config']),
    };
}

export function SCIMShowSettingsResponseBodyToJSON(value?: SCIMShowSettingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'scim_config': ScimConfigToJSON(value.scim_config),
    };
}

