/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssociatedApplication
 */
export interface AssociatedApplication {
    /**
     * The Azure application ID
     * @type {string}
     * @memberof AssociatedApplication
     */
    applicationId: string;
}

export function AssociatedApplicationFromJSON(json: any): AssociatedApplication {
    return AssociatedApplicationFromJSONTyped(json, false);
}

export function AssociatedApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssociatedApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': json['applicationId'],
    };
}

export function AssociatedApplicationToJSON(value?: AssociatedApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
    };
}

