import {
  AnnouncementPostActionParam,
  AnnouncementPostFieldParam,
  AnnouncementPostsCreateActionRequestBodyActionTypeEnum as ActionTypeEnum,
  AnnouncementPostsCreateFieldRequestBodyFieldTypeEnum as FieldTypeEnum,
  AnnouncementPostsShowResponseBody,
  CustomField,
  IncidentRole,
  IncidentTimestamp,
} from "@incident-io/api";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  ToastTheme,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { sortBy } from "lodash";
import React, { useState } from "react";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { prepareRankUpdate } from "src/utils/utils";

import { GetUnicodeForSlackEmoji } from "../../../../../utils/slack";
import { SettingsSortableList } from "../../../SettingsSortableList";
import { AnnouncementPostPreview } from "../../common/AnnouncementPostPreview";
import { AnnouncementPostActionCreateModal } from "../create/AnnouncementPostActionCreateModal";
import { AnnouncementPostFieldCreateModal } from "../create/AnnouncementPostFieldCreateModal";
import { AnnouncementItemEditModal } from "./AnnouncementPostUpdateModal";

export const AnnouncementPostEditPage = (): React.ReactElement => {
  const {
    data: postData,
    isLoading: loadingPostData,
    error: errorPostData,
  } = useAPI("announcementPostsShow", undefined);

  const {
    data: { post_fields: unsortedPostFields },
    error: postFieldsError,
    isLoading: postFieldsLoading,
  } = useAPI("announcementPostsListFields", undefined, {
    fallbackData: { post_fields: [] },
  });
  const postFields = sortBy(unsortedPostFields, "rank");

  const {
    data: { post_actions: unsortedPostActions },
    error: postActionsError,
    isLoading: postActionsLoading,
  } = useAPI("announcementPostsListActions", undefined, {
    fallbackData: { post_actions: [] },
  });
  const postActions = sortBy(unsortedPostActions, "rank");

  const {
    data: { custom_fields: unsortedCustomFields },
    error: customFieldError,
    isLoading: customFieldsLoading,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  const customFields = sortBy(unsortedCustomFields, "rank");

  const {
    data: { incident_timestamps: timestamps },
    isLoading: timestampsLoading,
    error: timestampsError,
  } = useAPI("incidentTimestampsList", undefined, {
    fallbackData: { incident_timestamps: [] },
  });
  const {
    data: { incident_roles: roles },
    error: rolesError,
    isLoading: rolesLoading,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  if (
    loadingPostData ||
    postFieldsLoading ||
    postActionsLoading ||
    customFieldsLoading ||
    timestampsLoading ||
    rolesLoading
  ) {
    return <FullPageLoader />;
  }

  const error =
    errorPostData ||
    postFieldsError ||
    postActionsError ||
    customFieldError ||
    timestampsError ||
    rolesError;

  if (error || !postData) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <AnnouncementPostUpdateForm
      postData={postData}
      postFields={postFields}
      postActions={postActions}
      customFields={customFields}
      timestamps={timestamps}
      roles={roles}
    />
  );
};

export const AnnouncementPostUpdateForm = ({
  postData,
  postFields,
  postActions,
  customFields,
  timestamps,
  roles,
}: {
  postData: AnnouncementPostsShowResponseBody;
  postFields: AnnouncementPostFieldParam[];
  postActions: AnnouncementPostActionParam[];
  customFields: CustomField[];
  timestamps: IncidentTimestamp[];
  roles: IncidentRole[];
}): React.ReactElement => {
  const showToast = useToast();

  const refetchPreview = useAPIRefetch("announcementPostsShow", undefined);

  const { trigger: updateFieldRanks, isMutating: isFieldUpdating } =
    useAPIMutation(
      "announcementPostsListFields",
      undefined,
      async (apiClient, data: AnnouncementPostFieldParam[]) => {
        // Do not include the fake title in the data
        const trimmedData = data.filter(
          (item) =>
            (item.field_type as string) !== "announcement_post_fields_title",
        );
        await apiClient.announcementPostsUpdateFieldRanks({
          updateFieldRanksRequestBody: prepareRankUpdate(trimmedData),
        });
      },
      {
        onSuccess: () => {
          refetchPreview();
        },
        onError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: "Error updating announcement post configuration",
          });
        },
      },
    );

  const { trigger: updateActionRanks, isMutating: isActionUpdating } =
    useAPIMutation(
      "announcementPostsListActions",
      undefined,
      async (apiClient, data: AnnouncementPostActionParam[]) => {
        await apiClient.announcementPostsUpdateActionRanks({
          updateActionRanksRequestBody: prepareRankUpdate(data),
        });
      },
      {
        onSuccess: () => {
          refetchPreview();
        },
        onError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: "Error updating announcement post configuration",
          });
        },
      },
    );

  const { trigger: onDeleteField, isMutating: isFieldDeleting } =
    useAPIMutation(
      "announcementPostsListFields",
      undefined,
      async (apiClient, { id }: { id: string }) => {
        await apiClient.announcementPostsDeleteField({ id });
      },
      {
        onSuccess: () => {
          refetchPreview();
        },
        onError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: "Error deleting field",
          });
        },
      },
    );

  const { trigger: onDeleteAction, isMutating: isActionDeleting } =
    useAPIMutation(
      "announcementPostsListActions",
      undefined,
      async (apiClient, { id }: { id: string }) => {
        await apiClient.announcementPostsDeleteAction({ id });
      },
      {
        onSuccess: () => {
          refetchPreview();
        },
        onError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: "Error deleting action",
          });
        },
      },
    );

  const maxFieldRank = Math.max(...postFields.map((field) => field.rank), 0);
  const maxActionRank = Math.max(
    ...postActions.map((action) => action.rank),
    0,
  );
  const isFieldMutating = isFieldUpdating || isFieldDeleting;
  const isActionMutating = isActionUpdating || isActionDeleting;

  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [showAddActionModal, setShowAddActionModal] = useState(false);

  return (
    <SecondaryNavSubPageWrapper
      icon={IconEnum.Announcement}
      title={"Update post configuration"}
      backHref={"/settings/announcements"}
      crumbs={[
        {
          title: "Announcements",
          to: "/settings/announcements",
        },
      ]}
    >
      <div className="space-y-4">
        <div className="flex flex-row gap-4">
          <div className="w-1/2">
            <AnnouncementPostSortableList<AnnouncementPostFieldParam>
              title="Configure fields"
              items={postFields}
              onReorderItems={updateFieldRanks}
              onClickAddMore={() => setShowAddFieldModal(true)}
              onDelete={onDeleteField}
              saving={isFieldMutating}
              refetchPreview={refetchPreview}
            />
          </div>
          <div className="w-1/2">
            <AnnouncementPostSortableList<AnnouncementPostActionParam>
              title="Configure actions"
              items={postActions}
              onReorderItems={updateActionRanks}
              onClickAddMore={() => setShowAddActionModal(true)}
              onDelete={onDeleteAction}
              saving={isActionMutating}
              refetchPreview={refetchPreview}
            />
          </div>
        </div>

        <AnnouncementPostPreview
          postFields={postData.post.post_fields}
          postActions={postData.post.post_actions}
        />
      </div>
      {showAddFieldModal && (
        <AnnouncementPostFieldCreateModal
          onClose={() => setShowAddFieldModal(false)}
          customFields={customFields}
          timestamps={timestamps}
          roles={roles}
          maxRank={maxFieldRank}
          refetchPreview={refetchPreview}
          postFields={postFields}
        />
      )}
      {showAddActionModal && (
        <AnnouncementPostActionCreateModal
          onClose={() => setShowAddActionModal(false)}
          maxRank={maxActionRank}
          refetchPreview={refetchPreview}
        />
      )}
    </SecondaryNavSubPageWrapper>
  );
};

export type SortableItem = {
  id: string;
  rank: number;
  emoji?: string;
  title?: string;
  action_type?: string;
  field_type?: string;
};

type AnnouncementPostSortableListProps<T> = {
  title: string;
  items: T[];
  onReorderItems: (fields: T[]) => void;
  onDelete: (req: { id: string }) => Promise<unknown>;
  onClickAddMore: () => void;
  saving: boolean;
  refetchPreview: () => void;
};

function isDragDisabled(item) {
  return (
    item.field_type === FieldTypeEnum.Description ||
    item.field_type === "announcement_post_fields_title"
  );
}

function AnnouncementPostSortableList<T extends SortableItem>({
  title,
  items,
  onReorderItems,
  onClickAddMore,
  onDelete,
  saving,
  refetchPreview,
}: AnnouncementPostSortableListProps<T>): React.ReactElement {
  const [editingItem, setEditingItem] = useState<SortableItem | null>(null);
  return (
    <div className="space-y-2">
      <h3 className="text-xs font-semibold tracking-[1.5px] text-content-tertiary uppercase">
        {title}
      </h3>
      <SettingsSortableList
        items={items}
        canEdit={true}
        updateItemRanks={onReorderItems}
        saving={saving}
        isItemDragDisabled={isDragDisabled}
        renderItem={(item) => {
          return (
            <AnnouncementItemRow
              item={item}
              onDelete={() => onDelete({ id: item.id })}
              onEdit={() => setEditingItem(item)}
            />
          );
        }}
      />
      <Button
        analyticsTrackingId={"add-announcement-post-field"}
        theme={ButtonTheme.Secondary}
        onClick={onClickAddMore}
      >
        Add more
      </Button>
      {editingItem && (
        <AnnouncementItemEditModal
          onClose={() => setEditingItem(null)}
          item={editingItem}
          refetchPreview={refetchPreview}
        />
      )}
    </div>
  );
}

type DraggableItemProps<T> = {
  item: T;
  onDelete: () => Promise<unknown>;
  onEdit: () => void;
};

function AnnouncementItemRow<T extends SortableItem>({
  item,
  onDelete,
  onEdit,
}: DraggableItemProps<T>): React.ReactElement {
  const editDisabled =
    item.field_type === FieldTypeEnum.Description ||
    item.field_type === "announcement_post_fields_title";

  const deleteDisabled =
    item.action_type === ActionTypeEnum.Homepage ||
    item.field_type === "announcement_post_fields_title";

  const renderEmoji = (emoji: string) => {
    return (
      <>
        {emoji === "slack" ? (
          <Icon id={IconEnum.Slack} className="mx-0" size={IconSize.Small} />
        ) : (
          <div>{emoji && GetUnicodeForSlackEmoji(emoji)}</div>
        )}
      </>
    );
  };

  return (
    <>
      <SettingsListItem
        title={
          <div className="flex items-center gap-2">
            {!!item.emoji && (
              <div className="text-content-primary">
                {renderEmoji(item.emoji)}
              </div>
            )}
            <div className="font-medium text-sm text-content-primary">
              {item.title}
            </div>
          </div>
        }
        buttons={{
          edit: {
            onEdit,
            isGatedText: editDisabled ? "You cannot edit this item" : undefined,
          },
          delete: {
            resourceTitle: item.title,
            onDelete,
            isGatedText: deleteDisabled
              ? "You cannot delete this item"
              : undefined,
            deleteConfirmationTitle: item.field_type
              ? "Delete field"
              : "Delete action",
            deleteConfirmationContent: (
              <>
                <div className="text-sm gap-1">
                  <div className="inline">Are you sure you want to delete </div>
                  <div className="inline-flex gap-1">
                    {!!item.emoji && renderEmoji(item.emoji)}
                    <div className="font-bold inline">{item.title}</div>
                  </div>
                  <div className="inline"> from your announcement post?</div>
                </div>
              </>
            ),
          },
        }}
      />
    </>
  );
}
