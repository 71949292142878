import { Loader } from "@incident-ui/Loader/Loader";
import React from "react";
import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Table.module.scss";

export interface TableProps<T> {
  header?: React.ReactNode;
  gridTemplateColumns: string; // https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
  className?: string;
  data: Array<T>;
  renderRow: (row: T, idx: number) => React.ReactNode;
  infiniteScroll?: {
    ref: UseInfiniteScrollHookRefCallback;
    isLoading: boolean;
    isFullyLoaded: boolean;
  };
  wrappedInBox?: boolean;
  emptyStateRow?: React.ReactNode;
  loading?: boolean;
}

// Used to display data in a table format (columns and rows).
// The table is responsive and supports infinite scrolling.
// Under the hood, it uses CSS Grid to layout the table, this has a few implications:
// 1. To control the sizing logic for the table
//    columns, you can use the `gridTemplateColumns` prop.
//    See https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns for more information.
// 2. If you mismatch the number of columns in the header and the number of cells in the rows,
//    your cells will wrap at the wrong column.
//    example: header=[1,2,3] rows=[[1.1, 1.2, 1.3, 1.4], [2.1, 2.2, 2.3]] will render as:
//    Header 1 | Header 2 | Header 3
//    Cell 1.1 | Cell 1.2 | Cell 1.3
//    Cell 1.4 | Cell 2.1 | Cell 2.2
//    Cell 2.3
export const Table = <T,>({
  header,
  className,
  gridTemplateColumns,
  data,
  renderRow,
  infiniteScroll,
  wrappedInBox,
  emptyStateRow,
  loading,
}: TableProps<T>) => {
  return (
    <div
      className={tcx("flex flex-col bg-surface-primary", {
        ["pt-3 border rounded-lg"]: wrappedInBox,
      })}
    >
      <div
        style={{ gridTemplateColumns }}
        className={tcx(
          "grid",
          {
            [styles.withBoxWrapper]: wrappedInBox,
          },

          className,
        )}
      >
        {header}
        {data.map(renderRow)}
      </div>
      {(infiniteScroll?.isFullyLoaded === false || loading) && (
        <div ref={infiniteScroll?.ref}>
          <Loader />
        </div>
      )}
      {!!emptyStateRow &&
      data.length === 0 &&
      (infiniteScroll === undefined || infiniteScroll.isFullyLoaded)
        ? emptyStateRow
        : null}
    </div>
  );
};

export type TableHeaderCellProps = {
  id?: string;
  title?: React.ReactNode;
  sortable?: boolean;
  draggable?: boolean;
  className?: string;
  children?: React.ReactNode;
};
export const TableHeaderCell = React.forwardRef<
  HTMLDivElement,
  TableHeaderCellProps
>(({ title, className, children, id }, ref) => {
  return (
    <div
      ref={ref}
      id={id}
      className={tcx(
        "border-b text-content-secondary pr-6 pb-3 text-xs-med",
        "first:pl-1 last:pr-1", // give some horizontal breathing room
        styles.cell,
        className,
      )}
    >
      <div className="line-clamp-1">
        {title}
        {children}
      </div>
    </div>
  );
});
TableHeaderCell.displayName = "TableHeaderCell";

export type TableCellProps = {
  className?: string;
  children: React.ReactNode;
};
export const TableCell = ({ className, children }: TableCellProps) => {
  return (
    <div
      className={tcx(
        "border-b",
        "py-3 pr-4 border-surface-secondary flex items-center gap-4",
        "group-hover/tableRow:bg-surface-secondary",
        "text-sm-normal",
        "first:pl-1 last:pr-1", // give some horizontal breathing room
        styles.cell,
        className,
      )}
    >
      {children}
    </div>
  );
};

// This is a wrapper around TableCell that gives you
// a way to stack content vertically for free that is inline with our design system.
// Prefix and suffix are optional and will be rendered before and after the primary content respectively.
export const TableCellStacked = (
  props: Omit<TableCellProps, "children"> & {
    prefix?: React.ReactNode; // Shows on the left of the primary content
    primary: React.ReactNode; // Main content
    secondary: React.ReactNode; // Secondary content below the primary content
    suffix?: React.ReactNode; // Shows on the right of the primary content
  },
) => {
  return (
    <TableCell {...props}>
      {props.prefix}
      <div className="flex flex-col items-start truncate">
        {props.primary}
        <div className="text-content-tertiary text-xs-med">
          {props.secondary}
        </div>
      </div>
      {props.suffix}
    </TableCell>
  );
};

export const TableRow = ({
  children,
  isLastRow,
  onClick,
  className,
}: {
  children: React.ReactNode;
  isLastRow: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}) => {
  return (
    <div
      className={tcx(
        "contents ",
        {
          ["group/tableRow cursor-pointer"]: !!onClick,
          [styles.lastRow]: isLastRow,
        },
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
