import { useAPI } from "src/utils/swr";

import { taskTotals } from "./utils";

export const usePostIncidentFlow = (incidentId: string | null) => {
  const {
    data: { incident_tasks: incidentTasks },
  } = useAPI(
    "postIncidentFlowListTasks",
    { incidentId: incidentId ?? "" },
    { fallbackData: { incident_tasks: [] } },
  );

  const { numCompleted, numTotal, perc } = taskTotals(incidentTasks);

  return {
    incidentTasks,
    numCompleted,
    numTotal,
    perc,
  };
};
