import { SlackChannelFormValue } from "@incident-shared/forms/v2/editors";
import { SlackChannelEditorV2 } from "@incident-shared/forms/v2/editors/SlackChannelEditorV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useClient } from "src/contexts/ClientContext";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useMutation } from "src/utils/fetchData";

export const BootstrapCustomerChannel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.BootstrapCustomerChannel,
  );

  return (
    <>
      <div className="p-3 text-sm rounded-2 bg-surface-secondary space-y-3">
        <span>Slack automations</span>
        <div className="flex gap-2">
          <GatedButton
            onClick={() => setIsOpen(true)}
            analyticsTrackingId={null}
            {...disabledProps}
          >
            🫶 Run CS Bot on channel
          </GatedButton>
        </div>
      </div>
      {isOpen && (
        <BootstrapCustomerChannelModal onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

type FormData = {
  slack_channel_id: SlackChannelFormValue;
};

const BootstrapCustomerChannelModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const formMethods = useForm<FormData>();

  const apiClient = useClient();
  const showToast = useToast();

  let invalidSlackChannelSelected = false;
  const selectedSlackChannel = formMethods.getValues("slack_channel_id");
  if (selectedSlackChannel) {
    const validChannel =
      selectedSlackChannel.label.startsWith("#prosp-") ||
      selectedSlackChannel.label.startsWith("#cust-");
    const isPrivateChannel = selectedSlackChannel.is_private;
    invalidSlackChannelSelected = !validChannel && !isPrivateChannel;
  }

  useEffect(() => {
    // Set error if the selected channel is not a customer or prospect channel
    if (invalidSlackChannelSelected) {
      formMethods.setError("slack_channel_id", {
        type: "manual",
        message: "Please select a channel that starts with #prosp- or #cust-",
      });
    } else {
      formMethods.clearErrors("slack_channel_id");
    }
  }, [formMethods, invalidSlackChannelSelected]);

  const [trigger, { saving, genericError }] = useMutation(
    async (data: FormData) => {
      if (invalidSlackChannelSelected) {
        return;
      }

      await apiClient.staffBootstrapCustomerChannel({
        bootstrapCustomerChannelRequestBody: {
          slack_channel_id: data.slack_channel_id.value,
        },
      });
      showToast({
        title: "Customer channel bootstrapped",
        theme: ToastTheme.Success,
      });
    },
    { setError: formMethods.setError, onSuccess: onClose },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={trigger}
      saving={saving}
      genericError={genericError}
      title="Run CS Bot on channel"
      onClose={onClose}
      analyticsTrackingId={null}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Bootstrap"
          confirmButtonType="submit"
          saving={saving}
          disabled={invalidSlackChannelSelected}
        />
      }
    >
      <SlackChannelEditorV2<FormData>
        formMethods={formMethods}
        name="slack_channel_id"
        hideWarning
      />
      <Form.Context>
        Running this will:
        <ul className="list-disc list-inside">
          <li>Add the incident.io Customer Success and Productboard bots</li>
          <li>Invite @customer-successz, @engineerz and @founderz</li>
          <li>Set the channel topic</li>
          <li>Add helpful bookmarks</li>
        </ul>
      </Form.Context>
    </Form.Modal>
  );
};
