import { IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum } from "@incident-io/api";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  LoadingBar,
  Tooltip,
} from "@incident-ui";

import { useNavigateToModal } from "../../../utils/query-params";
import { useAPI } from "../../../utils/swr";

export const StreamDetails = ({ streamId }: { streamId: string }) => {
  const {
    data: { stream },
    isLoading,
    error,
  } = useAPI(
    "streamsShow",
    {
      id: streamId,
    },
    { fallbackData: { stream: undefined } },
  );
  const incidentLead = stream?.incident_role_assignments?.find(
    (r) => r.role.role_type === RoleTypeEnum.Lead,
  );
  const navigateToDrawer = useNavigateToModal();

  if (isLoading) {
    return <LoadingBar />;
  }

  if (error || !stream) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <div className="flex-center-y gap-2 flex-wrap">
      <div className="flex min-w-0">
        <Button
          theme={ButtonTheme.Unstyled}
          className="leading-5 line-clamp-1 min-w-0 shrink"
          onClick={() => navigateToDrawer(`streams/${stream.external_id}`)}
          analyticsTrackingId="view-stream"
        >
          <div className="text-content-primary text-sm-normal truncate">
            {stream.name}
          </div>
        </Button>
      </div>
      <Tooltip
        key={incidentLead?.assignee?.id}
        content={incidentLead?.assignee?.name}
        delayDuration={0}
      >
        <Button
          theme={ButtonTheme.Unstyled}
          analyticsTrackingId={"stream-lead-avatar"}
        >
          <Avatar
            size={IconSize.Medium}
            url={incidentLead?.assignee?.avatar_url}
            className={"inline mx-0.5"}
          />
        </Button>
      </Tooltip>
    </div>
  );
};

export const StreamButton = ({ streamId }: { streamId: string }) => {
  const {
    data: { stream },
    isLoading,
    error,
  } = useAPI(
    "streamsShow",
    {
      id: streamId,
    },
    { fallbackData: { stream: undefined } },
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error || !stream) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <Button
      analyticsTrackingId="view-stream-slack-from-timeline-item"
      href={stream.slack_channel_url}
      icon={IconEnum.ExternalLink}
      theme={ButtonTheme.Naked}
      size={BadgeSize.Medium}
      className={"text-sm-med"}
    >
      View stream in Slack
    </Button>
  );
};
