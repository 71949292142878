/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MSTeamsPreviewVariant
 */
export interface MSTeamsPreviewVariant {
    /**
     * Adaptive Card JSON content of the template
     * @type {string}
     * @memberof MSTeamsPreviewVariant
     */
    adaptiveCardJSON?: string;
    /**
     * Markdown content of the variant
     * @type {string}
     * @memberof MSTeamsPreviewVariant
     */
    markdown?: string;
    /**
     * Name of the variant
     * @type {string}
     * @memberof MSTeamsPreviewVariant
     */
    name?: string;
}

export function MSTeamsPreviewVariantFromJSON(json: any): MSTeamsPreviewVariant {
    return MSTeamsPreviewVariantFromJSONTyped(json, false);
}

export function MSTeamsPreviewVariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): MSTeamsPreviewVariant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adaptiveCardJSON': !exists(json, 'adaptiveCardJSON') ? undefined : json['adaptiveCardJSON'],
        'markdown': !exists(json, 'markdown') ? undefined : json['markdown'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function MSTeamsPreviewVariantToJSON(value?: MSTeamsPreviewVariant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adaptiveCardJSON': value.adaptiveCardJSON,
        'markdown': value.markdown,
        'name': value.name,
    };
}

