/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Incident,
    IncidentFromJSON,
    IncidentFromJSONTyped,
    IncidentToJSON,
} from './Incident';
import {
    IncidentApplicableFields,
    IncidentApplicableFieldsFromJSON,
    IncidentApplicableFieldsFromJSONTyped,
    IncidentApplicableFieldsToJSON,
} from './IncidentApplicableFields';

/**
 * 
 * @export
 * @interface IncidentsShowResponseBody
 */
export interface IncidentsShowResponseBody {
    /**
     * 
     * @type {IncidentApplicableFields}
     * @memberof IncidentsShowResponseBody
     */
    applicable_fields: IncidentApplicableFields;
    /**
     * 
     * @type {Incident}
     * @memberof IncidentsShowResponseBody
     */
    incident: Incident;
}

export function IncidentsShowResponseBodyFromJSON(json: any): IncidentsShowResponseBody {
    return IncidentsShowResponseBodyFromJSONTyped(json, false);
}

export function IncidentsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicable_fields': IncidentApplicableFieldsFromJSON(json['applicable_fields']),
        'incident': IncidentFromJSON(json['incident']),
    };
}

export function IncidentsShowResponseBodyToJSON(value?: IncidentsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicable_fields': IncidentApplicableFieldsToJSON(value.applicable_fields),
        'incident': IncidentToJSON(value.incident),
    };
}

