/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinearIssueTemplate,
    LinearIssueTemplateFromJSON,
    LinearIssueTemplateFromJSONTyped,
    LinearIssueTemplateToJSON,
} from './LinearIssueTemplate';

/**
 * 
 * @export
 * @interface IssueTrackersLinearCreateIssueTemplateResponseBody
 */
export interface IssueTrackersLinearCreateIssueTemplateResponseBody {
    /**
     * 
     * @type {LinearIssueTemplate}
     * @memberof IssueTrackersLinearCreateIssueTemplateResponseBody
     */
    issue_template?: LinearIssueTemplate;
}

export function IssueTrackersLinearCreateIssueTemplateResponseBodyFromJSON(json: any): IssueTrackersLinearCreateIssueTemplateResponseBody {
    return IssueTrackersLinearCreateIssueTemplateResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersLinearCreateIssueTemplateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersLinearCreateIssueTemplateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_template': !exists(json, 'issue_template') ? undefined : LinearIssueTemplateFromJSON(json['issue_template']),
    };
}

export function IssueTrackersLinearCreateIssueTemplateResponseBodyToJSON(value?: IssueTrackersLinearCreateIssueTemplateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_template': LinearIssueTemplateToJSON(value.issue_template),
    };
}

