type CountryCode = { id: string };

// timezoneToCountries is a hardcoded mapping of timezone to countries, based on both our most used timezones
// according to our customers and whatever suggestions Copilot threw at me at the time of writing.
// It's the best option out of moment-timezone which has some rogue suggestions (like Berlin being part of Denmark),
// and a 10Mb extensive library of country data.
export const timezoneToCountries: { [timezone: string]: CountryCode[] } = {
  "Africa/Johannesburg": [{ id: "ZA" }],
  "America/Chicago": [{ id: "US" }],
  "America/Denver": [{ id: "US" }],
  "America/Los_Angeles": [{ id: "US" }],
  "America/Mexico_City": [{ id: "MX" }],
  "America/New_York": [{ id: "US" }],
  "America/Sao_Paulo": [{ id: "BR" }],
  "America/Toronto": [{ id: "CA" }],
  "America/Vancouver": [{ id: "CA" }],
  "America/Winnipeg": [{ id: "CA" }],
  "Asia/Bangkok": [{ id: "TH" }],
  "Asia/Dubai": [{ id: "AE" }],
  "Asia/Hong_Kong": [{ id: "HK" }],
  "Asia/Jerusalem": [{ id: "IL" }],
  "Asia/Karachi": [{ id: "PK" }],
  "Asia/Kolkata": [{ id: "IN" }],
  "Asia/Kuwait": [{ id: "KW" }],
  "Asia/Riyadh": [{ id: "SA" }],
  "Asia/Seoul": [{ id: "KR" }],
  "Asia/Shanghai": [{ id: "CN" }],
  "Asia/Singapore": [{ id: "SG" }],
  "Asia/Tokyo": [{ id: "JP" }],
  "Australia/Melbourne": [{ id: "AU" }],
  "Australia/Sydney": [{ id: "AU" }],
  "Europe/Amsterdam": [{ id: "NL" }],
  "Europe/Athens": [{ id: "GR" }],
  "Europe/Berlin": [{ id: "DE" }],
  "Europe/Bratislava": [{ id: "SK" }],
  "Europe/Brussels": [{ id: "BE" }],
  "Europe/Bucharest": [{ id: "RO" }],
  "Europe/Budapest": [{ id: "HU" }],
  "Europe/Copenhagen": [{ id: "DK" }],
  "Europe/Dublin": [{ id: "IE" }],
  "Europe/Helsinki": [{ id: "FI" }],
  "Europe/Istanbul": [{ id: "TR" }],
  "Europe/Kyiv": [{ id: "UA" }],
  "Europe/Lisbon": [{ id: "PT" }],
  "Europe/London": [{ id: "GB" }],
  "Europe/Luxembourg": [{ id: "LU" }],
  "Europe/Madrid": [{ id: "ES" }],
  "Europe/Moscow": [{ id: "RU" }],
  "Europe/Oslo": [{ id: "NO" }],
  "Europe/Paris": [{ id: "FR" }],
  "Europe/Prague": [{ id: "CZ" }],
  "Europe/Rome": [{ id: "IT" }],
  "Europe/Sarajevo": [{ id: "BA" }],
  "Europe/Sofia": [{ id: "BG" }],
  "Europe/Stockholm": [{ id: "SE" }],
  "Europe/Tallinn": [{ id: "EE" }],
  "Europe/Vienna": [{ id: "AT" }],
  "Europe/Warsaw": [{ id: "PL" }],
  "Europe/Zurich": [{ id: "CH" }],
  "Pacific/Auckland": [{ id: "NZ" }],
};
