/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DependentResource,
    DependentResourceFromJSON,
    DependentResourceFromJSONTyped,
    DependentResourceToJSON,
} from './DependentResource';

/**
 * 
 * @export
 * @interface EngineFindDependentResourcesForMultipleResponseBody
 */
export interface EngineFindDependentResourcesForMultipleResponseBody {
    /**
     * 
     * @type {Array<DependentResource>}
     * @memberof EngineFindDependentResourcesForMultipleResponseBody
     */
    dependent_resources: Array<DependentResource>;
}

export function EngineFindDependentResourcesForMultipleResponseBodyFromJSON(json: any): EngineFindDependentResourcesForMultipleResponseBody {
    return EngineFindDependentResourcesForMultipleResponseBodyFromJSONTyped(json, false);
}

export function EngineFindDependentResourcesForMultipleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineFindDependentResourcesForMultipleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dependent_resources': ((json['dependent_resources'] as Array<any>).map(DependentResourceFromJSON)),
    };
}

export function EngineFindDependentResourcesForMultipleResponseBodyToJSON(value?: EngineFindDependentResourcesForMultipleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dependent_resources': ((value.dependent_resources as Array<any>).map(DependentResourceToJSON)),
    };
}

