import { HeaderBarTitle } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWrapper } from "@incident-shared/layout/PageWrapper";
import { PageWidth } from "@incident-shared/layout/PageWrapper/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, IconEnum, IconSize } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useRef, useState } from "react";
import { useQueryParams } from "src/utils/query-params";

import { AlertSourceChooseSourcePage } from "./AlertSourceChooseSourcePage";
import { AlertSourceConnectPage } from "./AlertSourceConnectPage";
import {
  AlertSourceStep,
  AlertSourceStepEnum,
  stepConfig,
} from "./AlertSourceCreateWizardSteps";
import { AlertSourceConfigurePage } from "./configure/AlertSourceConfigurePage";
import { useAlertSourceConfigDeps } from "./useAlertSourceConfigDeps";

const getStep = (
  stepId: string,
  stepConfig: AlertSourceStep[],
): AlertSourceStep => {
  const step = stepConfig.find((s) => s.id === stepId);
  if (step === undefined) {
    throw new Error(`Invalid step: ${stepId}`);
  }
  return step;
};

// This page is the entry point for creating a new alert source. It is a wizard
// that guides the user through the process of choosing a source, connecting it,
// then configuring it.
export const AlertSourceCreatePage = () => {
  const queryParams = useQueryParams();
  const alertSourceConfigId = queryParams.get("id");
  const titleInputRef = useRef<HTMLDivElement>(null);

  let stepId = queryParams.get("step");
  if (!stepId) {
    stepId = AlertSourceStepEnum.Choose;
  }
  if (stepId !== AlertSourceStepEnum.Choose && !alertSourceConfigId) {
    throw new Error("Unreachable: alertSourceConfigId should be set ");
  }

  const step = getStep(stepId, stepConfig as unknown as AlertSourceStep[]);

  // We need to know what the source type is so that we can adjust the title and
  // iconography on the page wrapper.
  const [selectedSourceType, setSelectedSourceType] = useState<
    string | undefined
  >();

  const {
    loading,
    error,
    alertSource,
    alertSourceConfig,
    sourceTypeConfig,
    invalidSourceConfigError,
  } = useAlertSourceConfigDeps({
    alertSourceConfigId,
    sourceType: selectedSourceType,
  });

  if (error) {
    if (invalidSourceConfigError) {
      return (
        <GenericErrorMessage
          error={error}
          suggestRefresh={false}
          description={invalidSourceConfigError.message}
        />
      );
    }
    return <GenericErrorMessage error={error} />;
  }

  const crumbs = [
    {
      title: "Alerts",
      to: "/alerts",
    },
    {
      title: "Configuration",
      to: "/alerts/configuration",
    },
  ];

  return (
    <PageWrapper
      width={PageWidth.Full}
      noPadding
      title={"Create alert source"}
      titleNode={
        <HeaderBarTitle
          crumbs={crumbs}
          title="Edit alert source"
          titleNode={
            step.id === AlertSourceStepEnum.Configure ? (
              <div
                className="flex flex-row items-baseline grow"
                ref={titleInputRef}
              />
            ) : (
              <div>
                {alertSourceConfig
                  ? alertSourceConfig.name
                  : "Create Alert Source"}{" "}
              </div>
            )
          }
          isEditable
        />
      }
      icon={sourceTypeConfig?.icon || IconEnum.Alert}
      iconSize={sourceTypeConfig?.icon ? IconSize.XL : undefined}
      color={ColorPaletteEnum.SlateOnWhite}
      backHref={"/alerts/configuration"}
      overflowY={false}
    >
      <div className="flex flex-col grow overflow-y-auto">
        {
          {
            [AlertSourceStepEnum.Choose]: (
              <AlertSourceChooseSourcePage
                setSourceType={setSelectedSourceType}
              />
            ),
            [AlertSourceStepEnum.Connect]: (
              <AlertSourceConnectPage
                alertSourceConfigId={alertSourceConfig?.id as string}
                mode={"wizard"}
              />
            ),
            [AlertSourceStepEnum.Configure]:
              loading || !alertSource || !alertSourceConfig ? (
                <FullPageLoader />
              ) : (
                <AlertSourceConfigurePage
                  mode={"wizard"}
                  titleInputRef={titleInputRef}
                  {...{ alertSource, alertSourceConfig }}
                />
              ),
          }[step.id]
        }
      </div>
    </PageWrapper>
  );
};
