import { Banner, BannerTheme, OrgAwareLink } from "@incident-ui";
import { ReactElement } from "react";
import { useLocation } from "react-router";

export const ReinstallBanner = (): ReactElement | null => {
  const { pathname } = useLocation();
  const baseReinstallPagePath = "/reinstall-slack";
  const reinstallPagePathWithReturnUrl = `${baseReinstallPagePath}?return_to=${pathname.replace(
    "/",
    "",
  )}`;
  // Don't show this banner on the reinstall page
  if (pathname === baseReinstallPagePath) {
    return null;
  }
  return (
    <Banner theme={BannerTheme.Warning}>
      <p>
        We&apos;ve updated our app - you&apos;ll need to{" "}
        <OrgAwareLink to={reinstallPagePathWithReturnUrl}>
          reinstall it to your workspace
        </OrgAwareLink>{" "}
        to get the full incident.io experience.{" "}
        <OrgAwareLink
          to={reinstallPagePathWithReturnUrl}
          className="underline font-medium"
        >
          Find out more
        </OrgAwareLink>
      </p>
    </Banner>
  );
};
