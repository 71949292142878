/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableIncidentFormEscalationElement,
    AvailableIncidentFormEscalationElementFromJSON,
    AvailableIncidentFormEscalationElementFromJSONTyped,
    AvailableIncidentFormEscalationElementToJSON,
} from './AvailableIncidentFormEscalationElement';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface IncidentFormEscalationElement
 */
export interface IncidentFormEscalationElement {
    /**
     * 
     * @type {AvailableIncidentFormEscalationElement}
     * @memberof IncidentFormEscalationElement
     */
    available_element: AvailableIncidentFormEscalationElement;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof IncidentFormEscalationElement
     */
    default_value?: EngineParamBinding;
    /**
     * Gives a description of the option to the user
     * @type {string}
     * @memberof IncidentFormEscalationElement
     */
    description?: string;
    /**
     * Unique ID per incident form element
     * @type {string}
     * @memberof IncidentFormEscalationElement
     */
    id: string;
    /**
     * ID of the associated incident form
     * @type {string}
     * @memberof IncidentFormEscalationElement
     */
    incident_form_id: string;
    /**
     * 
     * @type {Expression}
     * @memberof IncidentFormEscalationElement
     */
    navigation_expression?: Expression;
    /**
     * 
     * @type {Expression}
     * @memberof IncidentFormEscalationElement
     */
    opsgenie_team_navigation_expression?: Expression;
    /**
     * 
     * @type {Expression}
     * @memberof IncidentFormEscalationElement
     */
    pagerduty_service_navigation_expression?: Expression;
    /**
     * The position this element takes within the form it belongs to
     * @type {number}
     * @memberof IncidentFormEscalationElement
     */
    rank: number;
}

export function IncidentFormEscalationElementFromJSON(json: any): IncidentFormEscalationElement {
    return IncidentFormEscalationElementFromJSONTyped(json, false);
}

export function IncidentFormEscalationElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormEscalationElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available_element': AvailableIncidentFormEscalationElementFromJSON(json['available_element']),
        'default_value': !exists(json, 'default_value') ? undefined : EngineParamBindingFromJSON(json['default_value']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'incident_form_id': json['incident_form_id'],
        'navigation_expression': !exists(json, 'navigation_expression') ? undefined : ExpressionFromJSON(json['navigation_expression']),
        'opsgenie_team_navigation_expression': !exists(json, 'opsgenie_team_navigation_expression') ? undefined : ExpressionFromJSON(json['opsgenie_team_navigation_expression']),
        'pagerduty_service_navigation_expression': !exists(json, 'pagerduty_service_navigation_expression') ? undefined : ExpressionFromJSON(json['pagerduty_service_navigation_expression']),
        'rank': json['rank'],
    };
}

export function IncidentFormEscalationElementToJSON(value?: IncidentFormEscalationElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_element': AvailableIncidentFormEscalationElementToJSON(value.available_element),
        'default_value': EngineParamBindingToJSON(value.default_value),
        'description': value.description,
        'id': value.id,
        'incident_form_id': value.incident_form_id,
        'navigation_expression': ExpressionToJSON(value.navigation_expression),
        'opsgenie_team_navigation_expression': ExpressionToJSON(value.opsgenie_team_navigation_expression),
        'pagerduty_service_navigation_expression': ExpressionToJSON(value.pagerduty_service_navigation_expression),
        'rank': value.rank,
    };
}

