import React from "react";
import { tcx } from "src/utils/tailwind-classes";

// SelectWrapper exists as a 'hack' to get a button into the far right of a select, inside the border
// but not in the react-select component. Putting it inside the react-select component causes a variety
// of focus issues, where the dropdown keeps closing or the popover keeps closing, or the dropdown input
// keeps being unfocussed meaning you can't type things in. I think there probably is a way to make
// this work inside react-select, but I couldn't find it.
//
// Instead, we put it outside the select, but position it absolutely so it's visually inside the select,
// and add a load of padding via customStyles.
export const SelectWrapper = ({
  suffixNode,
  children,
  className,
  disabled,
}: {
  suffixNode?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}): React.ReactElement => {
  return (
    // Only trigger the group-hover effects of our icons if the select is not disabled.
    <div className={tcx("relative w-full", !disabled && "group", className)}>
      {children}
      <div className="absolute right-0 top-0 bottom-0 flex items-center pr-3">
        {suffixNode}
      </div>
    </div>
  );
};
