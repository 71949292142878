/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeSchema,
    CatalogTypeSchemaFromJSON,
    CatalogTypeSchemaFromJSONTyped,
    CatalogTypeSchemaToJSON,
} from './CatalogTypeSchema';

/**
 * 
 * @export
 * @interface PotentialCatalogTypePayload
 */
export interface PotentialCatalogTypePayload {
    /**
     * Sets the display color of this type in the dashboard
     * @type {string}
     * @memberof PotentialCatalogTypePayload
     */
    color: PotentialCatalogTypePayloadColorEnum;
    /**
     * Human readble description of this type
     * @type {string}
     * @memberof PotentialCatalogTypePayload
     */
    description: string;
    /**
     * If this is a dynamic catalog type, this will be the unique parameter for identitfying this resource externally.
     * @type {string}
     * @memberof PotentialCatalogTypePayload
     */
    dynamic_resource_parameter: string;
    /**
     * Sets the display icon of this type in the dashboard
     * @type {string}
     * @memberof PotentialCatalogTypePayload
     */
    icon: PotentialCatalogTypePayloadIconEnum;
    /**
     * Name is the human readable name of this type
     * @type {string}
     * @memberof PotentialCatalogTypePayload
     */
    name: string;
    /**
     * If this type should be ranked
     * @type {boolean}
     * @memberof PotentialCatalogTypePayload
     */
    ranked: boolean;
    /**
     * The registry resource this type is synced from, if any
     * @type {string}
     * @memberof PotentialCatalogTypePayload
     */
    registry_type: string;
    /**
     * 
     * @type {CatalogTypeSchema}
     * @memberof PotentialCatalogTypePayload
     */
    schema: CatalogTypeSchema;
    /**
     * The type name of this catalog type, to be used when defining attributes. This is immutable once a CatalogType has been created. For non-externally sync types, it must follow the pattern Custom["SomeName "]
     * @type {string}
     * @memberof PotentialCatalogTypePayload
     */
    type_name: string;
}

/**
* @export
* @enum {string}
*/
export enum PotentialCatalogTypePayloadColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum PotentialCatalogTypePayloadIconEnum {
    Alert = 'alert',
    Bolt = 'bolt',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Clock = 'clock',
    Cog = 'cog',
    Components = 'components',
    Database = 'database',
    Doc = 'doc',
    Email = 'email',
    EscalationPath = 'escalation-path',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    Globe = 'globe',
    Money = 'money',
    Server = 'server',
    Severity = 'severity',
    StatusPage = 'status-page',
    Store = 'store',
    Star = 'star',
    Tag = 'tag',
    User = 'user',
    Users = 'users'
}

export function PotentialCatalogTypePayloadFromJSON(json: any): PotentialCatalogTypePayload {
    return PotentialCatalogTypePayloadFromJSONTyped(json, false);
}

export function PotentialCatalogTypePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PotentialCatalogTypePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'color': json['color'],
        'description': json['description'],
        'dynamic_resource_parameter': json['dynamic_resource_parameter'],
        'icon': json['icon'],
        'name': json['name'],
        'ranked': json['ranked'],
        'registry_type': json['registry_type'],
        'schema': CatalogTypeSchemaFromJSON(json['schema']),
        'type_name': json['type_name'],
    };
}

export function PotentialCatalogTypePayloadToJSON(value?: PotentialCatalogTypePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'color': value.color,
        'description': value.description,
        'dynamic_resource_parameter': value.dynamic_resource_parameter,
        'icon': value.icon,
        'name': value.name,
        'ranked': value.ranked,
        'registry_type': value.registry_type,
        'schema': CatalogTypeSchemaToJSON(value.schema),
        'type_name': value.type_name,
    };
}

