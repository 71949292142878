/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SCIMConfirmRoleMappingsResponseBody,
    SCIMConfirmRoleMappingsResponseBodyFromJSON,
    SCIMConfirmRoleMappingsResponseBodyToJSON,
    SCIMShowRoleMappingsResponseBody,
    SCIMShowRoleMappingsResponseBodyFromJSON,
    SCIMShowRoleMappingsResponseBodyToJSON,
    SCIMShowSettingsResponseBody,
    SCIMShowSettingsResponseBodyFromJSON,
    SCIMShowSettingsResponseBodyToJSON,
    SCIMUpdateRoleMappingsRequestBody,
    SCIMUpdateRoleMappingsRequestBodyFromJSON,
    SCIMUpdateRoleMappingsRequestBodyToJSON,
    SCIMUpdateRoleMappingsResponseBody,
    SCIMUpdateRoleMappingsResponseBodyFromJSON,
    SCIMUpdateRoleMappingsResponseBodyToJSON,
} from '../models';

export interface SCIMUpdateRoleMappingsRequest {
    updateRoleMappingsRequestBody: SCIMUpdateRoleMappingsRequestBody;
}

/**
 * 
 */
export class SCIMApi extends runtime.BaseAPI {

    /**
     * Confirm the mappings for an organisation\'s SCIM group role mappings. Once a user has sent this request, we will start rewriting the user\'s roles based on the mappings.
     * ConfirmRoleMappings SCIM
     */
    async sCIMConfirmRoleMappingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SCIMConfirmRoleMappingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scim/actions/confirm_role_mappings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SCIMConfirmRoleMappingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Confirm the mappings for an organisation\'s SCIM group role mappings. Once a user has sent this request, we will start rewriting the user\'s roles based on the mappings.
     * ConfirmRoleMappings SCIM
     */
    async sCIMConfirmRoleMappings(initOverrides?: RequestInit): Promise<SCIMConfirmRoleMappingsResponseBody> {
        const response = await this.sCIMConfirmRoleMappingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show all the mappings for an organisation\'s SCIM group role mappings
     * ShowRoleMappings SCIM
     */
    async sCIMShowRoleMappingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SCIMShowRoleMappingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scim/group_role_mappings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SCIMShowRoleMappingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show all the mappings for an organisation\'s SCIM group role mappings
     * ShowRoleMappings SCIM
     */
    async sCIMShowRoleMappings(initOverrides?: RequestInit): Promise<SCIMShowRoleMappingsResponseBody> {
        const response = await this.sCIMShowRoleMappingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show SCIM settings
     * ShowSettings SCIM
     */
    async sCIMShowSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SCIMShowSettingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scim`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SCIMShowSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show SCIM settings
     * ShowSettings SCIM
     */
    async sCIMShowSettings(initOverrides?: RequestInit): Promise<SCIMShowSettingsResponseBody> {
        const response = await this.sCIMShowSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Uninstall SCIM config
     * Uninstall SCIM
     */
    async sCIMUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scim`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uninstall SCIM config
     * Uninstall SCIM
     */
    async sCIMUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.sCIMUninstallRaw(initOverrides);
    }

    /**
     * Set all the mappings for an organisation\'s SCIM group role mappings
     * UpdateRoleMappings SCIM
     */
    async sCIMUpdateRoleMappingsRaw(requestParameters: SCIMUpdateRoleMappingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SCIMUpdateRoleMappingsResponseBody>> {
        if (requestParameters.updateRoleMappingsRequestBody === null || requestParameters.updateRoleMappingsRequestBody === undefined) {
            throw new runtime.RequiredError('updateRoleMappingsRequestBody','Required parameter requestParameters.updateRoleMappingsRequestBody was null or undefined when calling sCIMUpdateRoleMappings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/scim/group_role_mappings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SCIMUpdateRoleMappingsRequestBodyToJSON(requestParameters.updateRoleMappingsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SCIMUpdateRoleMappingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set all the mappings for an organisation\'s SCIM group role mappings
     * UpdateRoleMappings SCIM
     */
    async sCIMUpdateRoleMappings(requestParameters: SCIMUpdateRoleMappingsRequest, initOverrides?: RequestInit): Promise<SCIMUpdateRoleMappingsResponseBody> {
        const response = await this.sCIMUpdateRoleMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
