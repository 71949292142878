/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnnouncementPostsCreateActionRequestBody,
    AnnouncementPostsCreateActionRequestBodyFromJSON,
    AnnouncementPostsCreateActionRequestBodyToJSON,
    AnnouncementPostsCreateActionResponseBody,
    AnnouncementPostsCreateActionResponseBodyFromJSON,
    AnnouncementPostsCreateActionResponseBodyToJSON,
    AnnouncementPostsCreateFieldRequestBody,
    AnnouncementPostsCreateFieldRequestBodyFromJSON,
    AnnouncementPostsCreateFieldRequestBodyToJSON,
    AnnouncementPostsCreateFieldResponseBody,
    AnnouncementPostsCreateFieldResponseBodyFromJSON,
    AnnouncementPostsCreateFieldResponseBodyToJSON,
    AnnouncementPostsListActionOptionsResponseBody,
    AnnouncementPostsListActionOptionsResponseBodyFromJSON,
    AnnouncementPostsListActionOptionsResponseBodyToJSON,
    AnnouncementPostsListActionsResponseBody,
    AnnouncementPostsListActionsResponseBodyFromJSON,
    AnnouncementPostsListActionsResponseBodyToJSON,
    AnnouncementPostsListFieldOptionsResponseBody,
    AnnouncementPostsListFieldOptionsResponseBodyFromJSON,
    AnnouncementPostsListFieldOptionsResponseBodyToJSON,
    AnnouncementPostsListFieldsResponseBody,
    AnnouncementPostsListFieldsResponseBodyFromJSON,
    AnnouncementPostsListFieldsResponseBodyToJSON,
    AnnouncementPostsShowResponseBody,
    AnnouncementPostsShowResponseBodyFromJSON,
    AnnouncementPostsShowResponseBodyToJSON,
    AnnouncementPostsUpdateActionRanksRequestBody,
    AnnouncementPostsUpdateActionRanksRequestBodyFromJSON,
    AnnouncementPostsUpdateActionRanksRequestBodyToJSON,
    AnnouncementPostsUpdateActionRanksResponseBody,
    AnnouncementPostsUpdateActionRanksResponseBodyFromJSON,
    AnnouncementPostsUpdateActionRanksResponseBodyToJSON,
    AnnouncementPostsUpdateActionRequestBody,
    AnnouncementPostsUpdateActionRequestBodyFromJSON,
    AnnouncementPostsUpdateActionRequestBodyToJSON,
    AnnouncementPostsUpdateActionResponseBody,
    AnnouncementPostsUpdateActionResponseBodyFromJSON,
    AnnouncementPostsUpdateActionResponseBodyToJSON,
    AnnouncementPostsUpdateFieldRanksRequestBody,
    AnnouncementPostsUpdateFieldRanksRequestBodyFromJSON,
    AnnouncementPostsUpdateFieldRanksRequestBodyToJSON,
    AnnouncementPostsUpdateFieldRanksResponseBody,
    AnnouncementPostsUpdateFieldRanksResponseBodyFromJSON,
    AnnouncementPostsUpdateFieldRanksResponseBodyToJSON,
    AnnouncementPostsUpdateFieldRequestBody,
    AnnouncementPostsUpdateFieldRequestBodyFromJSON,
    AnnouncementPostsUpdateFieldRequestBodyToJSON,
    AnnouncementPostsUpdateFieldResponseBody,
    AnnouncementPostsUpdateFieldResponseBodyFromJSON,
    AnnouncementPostsUpdateFieldResponseBodyToJSON,
} from '../models';

export interface AnnouncementPostsCreateActionRequest {
    createActionRequestBody: AnnouncementPostsCreateActionRequestBody;
}

export interface AnnouncementPostsCreateFieldRequest {
    createFieldRequestBody: AnnouncementPostsCreateFieldRequestBody;
}

export interface AnnouncementPostsDeleteActionRequest {
    id: string;
}

export interface AnnouncementPostsDeleteFieldRequest {
    id: string;
}

export interface AnnouncementPostsUpdateActionRequest {
    id: string;
    updateActionRequestBody: AnnouncementPostsUpdateActionRequestBody;
}

export interface AnnouncementPostsUpdateActionRanksRequest {
    updateActionRanksRequestBody: AnnouncementPostsUpdateActionRanksRequestBody;
}

export interface AnnouncementPostsUpdateFieldRequest {
    id: string;
    updateFieldRequestBody: AnnouncementPostsUpdateFieldRequestBody;
}

export interface AnnouncementPostsUpdateFieldRanksRequest {
    updateFieldRanksRequestBody: AnnouncementPostsUpdateFieldRanksRequestBody;
}

/**
 * 
 */
export class AnnouncementPostsApi extends runtime.BaseAPI {

    /**
     * Create an announcement post action
     * CreateAction Announcement Posts
     */
    async announcementPostsCreateActionRaw(requestParameters: AnnouncementPostsCreateActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsCreateActionResponseBody>> {
        if (requestParameters.createActionRequestBody === null || requestParameters.createActionRequestBody === undefined) {
            throw new runtime.RequiredError('createActionRequestBody','Required parameter requestParameters.createActionRequestBody was null or undefined when calling announcementPostsCreateAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_posts/actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementPostsCreateActionRequestBodyToJSON(requestParameters.createActionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsCreateActionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an announcement post action
     * CreateAction Announcement Posts
     */
    async announcementPostsCreateAction(requestParameters: AnnouncementPostsCreateActionRequest, initOverrides?: RequestInit): Promise<AnnouncementPostsCreateActionResponseBody> {
        const response = await this.announcementPostsCreateActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an announcement post field
     * CreateField Announcement Posts
     */
    async announcementPostsCreateFieldRaw(requestParameters: AnnouncementPostsCreateFieldRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsCreateFieldResponseBody>> {
        if (requestParameters.createFieldRequestBody === null || requestParameters.createFieldRequestBody === undefined) {
            throw new runtime.RequiredError('createFieldRequestBody','Required parameter requestParameters.createFieldRequestBody was null or undefined when calling announcementPostsCreateField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_posts/fields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementPostsCreateFieldRequestBodyToJSON(requestParameters.createFieldRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsCreateFieldResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an announcement post field
     * CreateField Announcement Posts
     */
    async announcementPostsCreateField(requestParameters: AnnouncementPostsCreateFieldRequest, initOverrides?: RequestInit): Promise<AnnouncementPostsCreateFieldResponseBody> {
        const response = await this.announcementPostsCreateFieldRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an announcement post action
     * DeleteAction Announcement Posts
     */
    async announcementPostsDeleteActionRaw(requestParameters: AnnouncementPostsDeleteActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling announcementPostsDeleteAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_posts/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an announcement post action
     * DeleteAction Announcement Posts
     */
    async announcementPostsDeleteAction(requestParameters: AnnouncementPostsDeleteActionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.announcementPostsDeleteActionRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an announcement post field
     * DeleteField Announcement Posts
     */
    async announcementPostsDeleteFieldRaw(requestParameters: AnnouncementPostsDeleteFieldRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling announcementPostsDeleteField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_posts/fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an announcement post field
     * DeleteField Announcement Posts
     */
    async announcementPostsDeleteField(requestParameters: AnnouncementPostsDeleteFieldRequest, initOverrides?: RequestInit): Promise<void> {
        await this.announcementPostsDeleteFieldRaw(requestParameters, initOverrides);
    }

    /**
     * List announcement post action options available
     * ListActionOptions Announcement Posts
     */
    async announcementPostsListActionOptionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsListActionOptionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_posts/action_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsListActionOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List announcement post action options available
     * ListActionOptions Announcement Posts
     */
    async announcementPostsListActionOptions(initOverrides?: RequestInit): Promise<AnnouncementPostsListActionOptionsResponseBody> {
        const response = await this.announcementPostsListActionOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all announcement post actions
     * ListActions Announcement Posts
     */
    async announcementPostsListActionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsListActionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_posts/actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsListActionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all announcement post actions
     * ListActions Announcement Posts
     */
    async announcementPostsListActions(initOverrides?: RequestInit): Promise<AnnouncementPostsListActionsResponseBody> {
        const response = await this.announcementPostsListActionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List announcement post field options available
     * ListFieldOptions Announcement Posts
     */
    async announcementPostsListFieldOptionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsListFieldOptionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_posts/field_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsListFieldOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List announcement post field options available
     * ListFieldOptions Announcement Posts
     */
    async announcementPostsListFieldOptions(initOverrides?: RequestInit): Promise<AnnouncementPostsListFieldOptionsResponseBody> {
        const response = await this.announcementPostsListFieldOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all announcement post fields
     * ListFields Announcement Posts
     */
    async announcementPostsListFieldsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsListFieldsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_posts/fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsListFieldsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all announcement post fields
     * ListFields Announcement Posts
     */
    async announcementPostsListFields(initOverrides?: RequestInit): Promise<AnnouncementPostsListFieldsResponseBody> {
        const response = await this.announcementPostsListFieldsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the announcement post
     * Show Announcement Posts
     */
    async announcementPostsShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the announcement post
     * Show Announcement Posts
     */
    async announcementPostsShow(initOverrides?: RequestInit): Promise<AnnouncementPostsShowResponseBody> {
        const response = await this.announcementPostsShowRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an announcement post action
     * UpdateAction Announcement Posts
     */
    async announcementPostsUpdateActionRaw(requestParameters: AnnouncementPostsUpdateActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsUpdateActionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling announcementPostsUpdateAction.');
        }

        if (requestParameters.updateActionRequestBody === null || requestParameters.updateActionRequestBody === undefined) {
            throw new runtime.RequiredError('updateActionRequestBody','Required parameter requestParameters.updateActionRequestBody was null or undefined when calling announcementPostsUpdateAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_posts/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementPostsUpdateActionRequestBodyToJSON(requestParameters.updateActionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsUpdateActionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an announcement post action
     * UpdateAction Announcement Posts
     */
    async announcementPostsUpdateAction(requestParameters: AnnouncementPostsUpdateActionRequest, initOverrides?: RequestInit): Promise<AnnouncementPostsUpdateActionResponseBody> {
        const response = await this.announcementPostsUpdateActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing announcement post actions
     * UpdateActionRanks Announcement Posts
     */
    async announcementPostsUpdateActionRanksRaw(requestParameters: AnnouncementPostsUpdateActionRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsUpdateActionRanksResponseBody>> {
        if (requestParameters.updateActionRanksRequestBody === null || requestParameters.updateActionRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateActionRanksRequestBody','Required parameter requestParameters.updateActionRanksRequestBody was null or undefined when calling announcementPostsUpdateActionRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_posts/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementPostsUpdateActionRanksRequestBodyToJSON(requestParameters.updateActionRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsUpdateActionRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing announcement post actions
     * UpdateActionRanks Announcement Posts
     */
    async announcementPostsUpdateActionRanks(requestParameters: AnnouncementPostsUpdateActionRanksRequest, initOverrides?: RequestInit): Promise<AnnouncementPostsUpdateActionRanksResponseBody> {
        const response = await this.announcementPostsUpdateActionRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an announcement post field
     * UpdateField Announcement Posts
     */
    async announcementPostsUpdateFieldRaw(requestParameters: AnnouncementPostsUpdateFieldRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsUpdateFieldResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling announcementPostsUpdateField.');
        }

        if (requestParameters.updateFieldRequestBody === null || requestParameters.updateFieldRequestBody === undefined) {
            throw new runtime.RequiredError('updateFieldRequestBody','Required parameter requestParameters.updateFieldRequestBody was null or undefined when calling announcementPostsUpdateField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_posts/fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementPostsUpdateFieldRequestBodyToJSON(requestParameters.updateFieldRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsUpdateFieldResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an announcement post field
     * UpdateField Announcement Posts
     */
    async announcementPostsUpdateField(requestParameters: AnnouncementPostsUpdateFieldRequest, initOverrides?: RequestInit): Promise<AnnouncementPostsUpdateFieldResponseBody> {
        const response = await this.announcementPostsUpdateFieldRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing announcement post fields
     * UpdateFieldRanks Announcement Posts
     */
    async announcementPostsUpdateFieldRanksRaw(requestParameters: AnnouncementPostsUpdateFieldRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementPostsUpdateFieldRanksResponseBody>> {
        if (requestParameters.updateFieldRanksRequestBody === null || requestParameters.updateFieldRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateFieldRanksRequestBody','Required parameter requestParameters.updateFieldRanksRequestBody was null or undefined when calling announcementPostsUpdateFieldRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_posts/fields/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementPostsUpdateFieldRanksRequestBodyToJSON(requestParameters.updateFieldRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementPostsUpdateFieldRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing announcement post fields
     * UpdateFieldRanks Announcement Posts
     */
    async announcementPostsUpdateFieldRanks(requestParameters: AnnouncementPostsUpdateFieldRanksRequest, initOverrides?: RequestInit): Promise<AnnouncementPostsUpdateFieldRanksResponseBody> {
        const response = await this.announcementPostsUpdateFieldRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
