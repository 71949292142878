import {
  ExpressionBranch,
  ExpressionElseBranch,
  IncidentsBuildScopeContextEnum,
  PostmortemTemplate,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  GenericErrorMessage,
  IconEnum,
  Loader,
  ModalFooter,
} from "@incident-ui";
import { useForm, useFormContext } from "react-hook-form";
import { useIncidentScope } from "src/hooks/useIncidentScope";

import { PostmortemTemplateExpressionFormData } from "./types";

export const EditBranchModal = ({
  templates,
  branch,
  onEditBranch,
  onClose,
}: {
  templates: PostmortemTemplate[];
  branch?: ExpressionBranch;
  onEditBranch: (branch: ExpressionBranch) => void;
  onClose: () => void;
}) => {
  const formMethods = useForm<ExpressionBranch>({
    defaultValues:
      branch ??
      (templates.length === 1 // default the template select to the first template if there is only one template
        ? {
            result: {
              value: { literal: templates[0].id },
            },
          }
        : undefined),
  });

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.PostIncidentFlow,
  );

  if (scopeError) {
    return <GenericErrorMessage error={scopeError} />;
  }
  if (scopeLoading) {
    return <Loader />;
  }

  const options = templates.map((template) => ({
    label: template.name,
    value: template.id,
    icon: IconEnum.Doc,
  }));
  return (
    <Form.Modal
      analyticsTrackingId={null}
      onClose={onClose}
      title={branch ? "Edit conditions" : "Add new conditions"}
      formMethods={formMethods}
      onSubmit={(data) => {
        onEditBranch(data);
      }}
      footer={
        <ModalFooter
          confirmButtonText="Update"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <ConditionGroupsEditorV2
        formMethods={formMethods}
        name="condition_groups"
        scope={scope}
        subjectsLabel="incidents"
        entityNameLabel="rule"
        populatedIntroSentence="We will use this template when..."
        emptyIntroSentence="When the following conditions are met"
        required
        rules={{ minLength: 1 }}
      />
      <StaticSingleSelectV2
        label="Use this template"
        formMethods={formMethods}
        name="result.value.literal"
        options={options}
        required
      />
    </Form.Modal>
  );
};

export const EditElseBranchModal = ({
  templates,
  onEditBranch,
  onClose,
  isOnlyBranch,
}: {
  templates: PostmortemTemplate[];
  branch?: ExpressionBranch;
  onEditBranch: (branch: ExpressionElseBranch) => void;
  onClose: () => void;
  isOnlyBranch?: boolean;
}) => {
  const topLevelFormMethods =
    useFormContext<PostmortemTemplateExpressionFormData>();
  const elseBranch = topLevelFormMethods.watch(
    "postmortem_template_expression.else_branch",
  );
  const localFormMethods = useForm<ExpressionElseBranch & { is_null: boolean }>(
    {
      defaultValues: {
        ...elseBranch,
      },
    },
  );

  let title = "Edit fallback behaviour";
  let inputLabel = "If incident does not match previous conditions";

  if (isOnlyBranch) {
    title = "Default template";
    inputLabel = "Which template should we use by default?";
  }

  const options = templates.map((template) => ({
    label: template.name,
    value: template.id,
    icon: IconEnum.Doc,
  }));
  return (
    <Form.Modal
      analyticsTrackingId={null}
      onClose={onClose}
      title={title}
      formMethods={localFormMethods}
      onSubmit={(data) => {
        onEditBranch(data);
      }}
      footer={
        <ModalFooter
          confirmButtonText="Update"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <StaticSingleSelectV2
        label={inputLabel}
        className="mt-2"
        formMethods={localFormMethods}
        name="result.value.literal"
        options={options}
      />
    </Form.Modal>
  );
};
