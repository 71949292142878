/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeekdayInterval
 */
export interface WeekdayInterval {
    /**
     * End time of the interval, in 24hr format
     * @type {string}
     * @memberof WeekdayInterval
     */
    end_time: string;
    /**
     * Start time of the interval, in 24hr format
     * @type {string}
     * @memberof WeekdayInterval
     */
    start_time: string;
    /**
     * Weekday this interval applies to
     * @type {string}
     * @memberof WeekdayInterval
     */
    weekday: WeekdayIntervalWeekdayEnum;
}

/**
* @export
* @enum {string}
*/
export enum WeekdayIntervalWeekdayEnum {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday'
}

export function WeekdayIntervalFromJSON(json: any): WeekdayInterval {
    return WeekdayIntervalFromJSONTyped(json, false);
}

export function WeekdayIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekdayInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_time': json['end_time'],
        'start_time': json['start_time'],
        'weekday': json['weekday'],
    };
}

export function WeekdayIntervalToJSON(value?: WeekdayInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_time': value.end_time,
        'start_time': value.start_time,
        'weekday': value.weekday,
    };
}

