/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntryReference,
    CatalogEntryReferenceFromJSON,
    CatalogEntryReferenceFromJSONTyped,
    CatalogEntryReferenceToJSON,
} from './CatalogEntryReference';

/**
 * 
 * @export
 * @interface CatalogRelationAttribute
 */
export interface CatalogRelationAttribute {
    /**
     * The attribute these entries are stored against
     * @type {string}
     * @memberof CatalogRelationAttribute
     */
    attribute: string;
    /**
     * Catalog entries that point at the target through this attribute
     * @type {Array<CatalogEntryReference>}
     * @memberof CatalogRelationAttribute
     */
    entries: Array<CatalogEntryReference>;
}

export function CatalogRelationAttributeFromJSON(json: any): CatalogRelationAttribute {
    return CatalogRelationAttributeFromJSONTyped(json, false);
}

export function CatalogRelationAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogRelationAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': json['attribute'],
        'entries': ((json['entries'] as Array<any>).map(CatalogEntryReferenceFromJSON)),
    };
}

export function CatalogRelationAttributeToJSON(value?: CatalogRelationAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'entries': ((value.entries as Array<any>).map(CatalogEntryReferenceToJSON)),
    };
}

