/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    IssueTrackerFieldValuePayload,
    IssueTrackerFieldValuePayloadFromJSON,
    IssueTrackerFieldValuePayloadFromJSONTyped,
    IssueTrackerFieldValuePayloadToJSON,
} from './IssueTrackerFieldValuePayload';

/**
 * 
 * @export
 * @interface IssueTrackersV2CreateIssueTemplateRequestBody
 */
export interface IssueTrackersV2CreateIssueTemplateRequestBody {
    /**
     * The context of the issue template
     * @type {string}
     * @memberof IssueTrackersV2CreateIssueTemplateRequestBody
     */
    context: IssueTrackersV2CreateIssueTemplateRequestBodyContextEnum;
    /**
     * Expressions available for use in templates
     * @type {Array<ExpressionPayload>}
     * @memberof IssueTrackersV2CreateIssueTemplateRequestBody
     */
    expressions: Array<ExpressionPayload>;
    /**
     * The values to use for each field
     * @type {Array<IssueTrackerFieldValuePayload>}
     * @memberof IssueTrackersV2CreateIssueTemplateRequestBody
     */
    field_values: Array<IssueTrackerFieldValuePayload>;
    /**
     * The name of the issue template
     * @type {string}
     * @memberof IssueTrackersV2CreateIssueTemplateRequestBody
     */
    name: string;
    /**
     * The issue tracker provider to create the template for
     * @type {string}
     * @memberof IssueTrackersV2CreateIssueTemplateRequestBody
     */
    provider: IssueTrackersV2CreateIssueTemplateRequestBodyProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum IssueTrackersV2CreateIssueTemplateRequestBodyContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}/**
* @export
* @enum {string}
*/
export enum IssueTrackersV2CreateIssueTemplateRequestBodyProviderEnum {
    Asana = 'asana',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function IssueTrackersV2CreateIssueTemplateRequestBodyFromJSON(json: any): IssueTrackersV2CreateIssueTemplateRequestBody {
    return IssueTrackersV2CreateIssueTemplateRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersV2CreateIssueTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersV2CreateIssueTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'field_values': ((json['field_values'] as Array<any>).map(IssueTrackerFieldValuePayloadFromJSON)),
        'name': json['name'],
        'provider': json['provider'],
    };
}

export function IssueTrackersV2CreateIssueTemplateRequestBodyToJSON(value?: IssueTrackersV2CreateIssueTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'field_values': ((value.field_values as Array<any>).map(IssueTrackerFieldValuePayloadToJSON)),
        'name': value.name,
        'provider': value.provider,
    };
}

