import { UseFormSetError } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

// If we're submitting to an API that is slightly different shape from the form we're editing
// we can prefix all error pointers with a string to make them match up.
// e.g. our form might just have `conditions` as an outer level field in FormData
// but the API might expect this as some nested value like `incident_trigger_config.conditions`
export function prefixErrors<DataType extends FieldValues>(
  setError: UseFormSetError<DataType>,
  prefix: string,
): UseFormSetError<DataType> {
  return (err, opts) => {
    // @ts-expect-error - we're purposefully fudging the types here
    return setError(`${prefix}${err}`, opts);
  };
}

// Our condition errors + react-hook-form don't play nicely with us pointing to nested fields
// e.g. conditions.0.value.literal
// So this function just strips out the latter part and points to `conditions`, which our <ConditionsEditor /> supports
export function stripConditionErrorDetails<DataType extends FieldValues>(
  setError: UseFormSetError<DataType>,
): UseFormSetError<DataType> {
  return (err, opts) => {
    if (err.includes("conditions.")) {
      const [firstPart] = err.split("conditions.");
      // @ts-expect-error - setError is not typed correctly
      return setError(`${firstPart}conditions`, opts);
    }

    return setError(err, opts);
  };
}
