import { Input, InputType } from "@incident-ui/Input/Input";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import { orderBy } from "lodash";
import React, { useState } from "react";
import { ChangeEvent } from "react";
import { tcx } from "src/utils/tailwind-classes";

const durationMultiplierOptions = (includeSeconds: boolean): SelectOption[] => {
  const options = includeSeconds
    ? [
        {
          label: "Seconds",
          value: "1",
          sort_key: "1",
        },
      ]
    : [];

  options.push(
    ...[
      {
        label: "Minutes",
        value: "60",
        sort_key: "2",
      },
      {
        label: "Hours",
        value: "3600",
        sort_key: "3",
      },
      {
        label: "Days",
        value: "86400",
        sort_key: "4",
      },
    ],
  );

  return options;
};

// This returns a string because the select requires the 'value' to
// be a string.
const getDefaultMultiplier = (
  initialValue: number,
  includeSeconds: boolean,
): number => {
  if (initialValue === 0) {
    // I think hours is a sensible default
    return 3600;
  }

  // If nothing divides perfectly, seconds seems sensible, unless those have
  // been excluded.
  let result = includeSeconds ? 1 : 60;

  // This is a hacky way of picking something sensible as a default - we pick the lowest
  // multipler which gives us a round number (or default to seconds)
  const orderedOpts = orderBy(
    durationMultiplierOptions(includeSeconds),
    "sort_key",
    "desc",
  );
  for (const opt of orderedOpts) {
    const numericValue = parseInt(opt.value);
    if (initialValue % numericValue === 0) {
      result = numericValue;
      break;
    }
  }

  return result;
};

export type DurationInputProps = {
  value: number;
  onChange: (val: number) => void;
  className?: string;
  includeSeconds?: boolean;
  disabled?: boolean;
  onValueChange?: (val: number) => void;
};

// DurationInput outputs a duration in seconds.
export const DurationInput = React.forwardRef(
  (
    props: DurationInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): React.ReactElement => {
    const {
      value,
      onChange,
      className,
      disabled = false,
      includeSeconds = true,
    } = props;
    const [durationMultiplier, setDurationMultiplier] = useState<number>(
      getDefaultMultiplier(value || 1, includeSeconds),
    );
    const displayValue = value ? value / durationMultiplier : 0;

    return (
      <div className={tcx("flex-between text-slate-700 space-x-2", className)}>
        <div className="grow">
          <Input
            type={InputType.Number}
            id="duration"
            placeholder=""
            value={displayValue.toString()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange(parseFloat(e.target.value) * durationMultiplier);
            }}
            ref={ref}
            disabled={disabled}
          />
        </div>
        <div className="w-[150px]">
          <StaticSingleSelect
            options={durationMultiplierOptions(includeSeconds)}
            value={durationMultiplier.toString()}
            onChange={(val) => {
              if (!val) {
                return;
              }
              const multiplier = parseInt(val);
              onChange(displayValue * multiplier);
              setDurationMultiplier(multiplier);
            }}
            placeholder={""}
            isClearable={false}
            disabled={disabled}
          />
        </div>
      </div>
    );
  },
);

DurationInput.displayName = "DurationInput";
