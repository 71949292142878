/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertRoutesMarkAllUnrelatedRequestBody
 */
export interface AlertRoutesMarkAllUnrelatedRequestBody {
    /**
     * The ID of the alert route config this alert was routed to
     * @type {string}
     * @memberof AlertRoutesMarkAllUnrelatedRequestBody
     */
    alert_route_id: string;
    /**
     * Ensure even if the alert route does not match this alert, that we create an incident for it
     * @type {boolean}
     * @memberof AlertRoutesMarkAllUnrelatedRequestBody
     */
    always_create_incident?: boolean;
}

export function AlertRoutesMarkAllUnrelatedRequestBodyFromJSON(json: any): AlertRoutesMarkAllUnrelatedRequestBody {
    return AlertRoutesMarkAllUnrelatedRequestBodyFromJSONTyped(json, false);
}

export function AlertRoutesMarkAllUnrelatedRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesMarkAllUnrelatedRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_route_id': json['alert_route_id'],
        'always_create_incident': !exists(json, 'always_create_incident') ? undefined : json['always_create_incident'],
    };
}

export function AlertRoutesMarkAllUnrelatedRequestBodyToJSON(value?: AlertRoutesMarkAllUnrelatedRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_route_id': value.alert_route_id,
        'always_create_incident': value.always_create_incident,
    };
}

