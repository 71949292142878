import { bindingToPayload } from "@incident-shared/engine";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AvailableIncidentFormLifecycleElement,
  ConditionGroup,
  EngineScope,
  IncidentFormLifecycleElement,
  IncidentFormLifecycleElementRequiredIfEnum as RequiredIfEnum,
  IncidentFormsCreateLifecycleElementRequestBody,
  IncidentFormsCreateLifecycleElementRequestBodyElementTypeEnum,
  IncidentFormsCreateLifecycleElementRequestBodyRequiredIfEnum,
  IncidentFormsUpdateLifecycleElementRequestBody,
  IncidentFormsUpdateLifecycleElementRequestBodyRequiredIfEnum,
  IncidentType,
} from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";

import { useAPIMutation } from "../../../../../utils/swr";
import {
  DefaultValueFormSection,
  DescriptionFormSection,
  PlaceholderFormSection,
  RequiredIfFormSection,
  ShowIfFormSection,
} from "../IncidentFormElementModalSections";

export type FormElementCreateEditModalProps = {
  mode: Mode;
  formID: string;
  incidentType?: IncidentType;
  onClose: () => void;
  availableElement: AvailableIncidentFormLifecycleElement;
  scope: EngineScope;
} & CreateEditFormProps<IncidentFormLifecycleElement>;

export type IncidentFormElementFormData = IncidentFormLifecycleElement & {
  show_if: "always" | "sometimes";
};

export const IncidentFormElementCreateEditModal = ({
  mode,
  initialData: element,
  availableElement,
  formID,
  incidentType,
  onClose,
  scope,
}: FormElementCreateEditModalProps): React.ReactElement => {
  const formMethods = useForm<IncidentFormElementFormData>({
    defaultValues: getDefaultValues({ element, availableElement, formID }),
  });
  const { setError } = formMethods;

  const { resources, resourcesLoading } = useAllResources();

  const {
    trigger: onSubmit,
    isMutating,
    genericError,
  } = useAPIMutation(
    "incidentFormsListLifecycleElements",
    { incidentFormId: formID },
    async (apiClient, data: IncidentFormElementFormData) => {
      if (mode === Mode.Edit) {
        await apiClient.incidentFormsUpdateLifecycleElement({
          id: element.id,
          updateLifecycleElementRequestBody:
            transformFormStateToUpdateRequestBody(data),
        });
        return;
      } else {
        await apiClient.incidentFormsCreateLifecycleElement({
          createLifecycleElementRequestBody:
            transformFormStateToCreateRequestBody(data, availableElement),
        });
        return;
      }
    },
    { onSuccess: () => onClose(), setError },
  );

  const modalTitlePrefix = mode === Mode.Edit ? "Edit field" : "Add field";
  return (
    <Form.Modal
      isExtraLarge
      analyticsTrackingId="incident-form-element-create-edit"
      onSubmit={onSubmit}
      genericError={genericError}
      saving={isMutating}
      formMethods={formMethods}
      innerClassName="p-4 space-y-4"
      onClose={onClose}
      loading={resourcesLoading}
      title={`${modalTitlePrefix}: ${availableElement.label}`}
      footer={
        <ModalFooter
          onClose={onClose}
          saving={isMutating}
          confirmButtonType="submit"
          confirmButtonText={mode === Mode.Create ? "Add" : "Save"}
        />
      }
    >
      <ShowIfFormSection
        scope={scope}
        fixedConditions={availableElement.show_if_fixed_condition_groups || []}
        availableElement={availableElement}
      />
      <RequiredIfFormSection
        scope={scope}
        availableElement={availableElement}
      />
      <DefaultValueFormSection
        scope={scope}
        availableElement={availableElement}
        resources={resources}
        incidentType={incidentType}
      />
      <DescriptionFormSection
        availableElement={availableElement}
        formMethods={formMethods}
      />
      <PlaceholderFormSection
        availableElement={availableElement}
        formMethods={formMethods}
      />
    </Form.Modal>
  );
};

const showIfConditions = (
  formData: Omit<IncidentFormElementFormData, "id" | "rank">,
): ConditionGroup[] => {
  return formData.show_if === "always"
    ? []
    : formData.show_if_condition_groups || [];
};

export const transformFormStateToCreateRequestBody = (
  formData: Omit<IncidentFormElementFormData, "id" | "rank">,
  availableElement: Pick<
    AvailableIncidentFormLifecycleElement,
    "element_type" | "custom_field" | "incident_role" | "incident_timestamp"
  >,
): IncidentFormsCreateLifecycleElementRequestBody => {
  return {
    ...formData,
    show_if_condition_groups: conditionGroupsToGroupPayloads(
      showIfConditions(formData),
    ),
    required_if:
      formData.required_if as unknown as IncidentFormsCreateLifecycleElementRequestBodyRequiredIfEnum,
    required_if_condition_groups: conditionGroupsToGroupPayloads(
      formData.required_if_condition_groups || [],
    ),
    element_type:
      availableElement.element_type as unknown as IncidentFormsCreateLifecycleElementRequestBodyElementTypeEnum,
    default_value: formData.default_value
      ? bindingToPayload(formData.default_value)
      : undefined,
    placeholder: formData.placeholder,
    description: formData.description,
    incident_role_id: availableElement.incident_role?.id,
    custom_field_id: availableElement.custom_field?.id,
    incident_timestamp_id: availableElement.incident_timestamp?.id,
  };
};

export const transformFormStateToUpdateRequestBody = (
  formData: IncidentFormElementFormData,
): IncidentFormsUpdateLifecycleElementRequestBody => {
  return {
    ...formData,
    required_if:
      formData.required_if as unknown as IncidentFormsUpdateLifecycleElementRequestBodyRequiredIfEnum,
    show_if_condition_groups: conditionGroupsToGroupPayloads(
      showIfConditions(formData),
    ),
    required_if_condition_groups: conditionGroupsToGroupPayloads(
      formData.required_if_condition_groups || [],
    ),
    default_value: formData.default_value
      ? bindingToPayload(formData.default_value)
      : undefined,
    placeholder: formData.placeholder,
    description: formData.description,
  };
};

export const getDefaultValues = ({
  element,
  availableElement,
  formID,
}: {
  element?: IncidentFormLifecycleElement;
  availableElement: AvailableIncidentFormLifecycleElement;
  formID: string;
}): Partial<IncidentFormElementFormData> => {
  const conditions: Partial<IncidentFormElementFormData> = {
    show_if_condition_groups: element?.show_if_condition_groups || [],
    required_if_condition_groups: element?.required_if_condition_groups || [],
  };

  if (element) {
    const showIfConditions = element.show_if_condition_groups || [];
    const showIfFixedConditions =
      availableElement.show_if_fixed_condition_groups || [];

    const hasConditions =
      showIfConditions.length + showIfFixedConditions.length > 0;
    return {
      ...element,
      ...conditions,
      show_if: hasConditions ? "sometimes" : "always",
    };
  }

  const hasConditions =
    availableElement.show_if_fixed_condition_groups[0]?.conditions?.length > 0;
  return {
    ...conditions,
    incident_form_id: formID,
    show_if: hasConditions ? "sometimes" : "always",
    required_if: RequiredIfEnum.NeverRequire,
    can_select_no_value: false,
  };
};
