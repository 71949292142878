/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface HolidayUserFeed
 */
export interface HolidayUserFeed {
    /**
     * 
     * @type {Actor}
     * @memberof HolidayUserFeed
     */
    creator: Actor;
    /**
     * Unique identifier for this feed
     * @type {string}
     * @memberof HolidayUserFeed
     */
    id: string;
    /**
     * When the last error was
     * @type {Date}
     * @memberof HolidayUserFeed
     */
    last_error_at?: Date;
    /**
     * When we last synced this feed
     * @type {Date}
     * @memberof HolidayUserFeed
     */
    last_synced_at?: Date;
    /**
     * The name of this feed
     * @type {string}
     * @memberof HolidayUserFeed
     */
    name: string;
    /**
     * The URL of the calendar feed
     * @type {string}
     * @memberof HolidayUserFeed
     */
    url: string;
}

export function HolidayUserFeedFromJSON(json: any): HolidayUserFeed {
    return HolidayUserFeedFromJSONTyped(json, false);
}

export function HolidayUserFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidayUserFeed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': ActorFromJSON(json['creator']),
        'id': json['id'],
        'last_error_at': !exists(json, 'last_error_at') ? undefined : (new Date(json['last_error_at'])),
        'last_synced_at': !exists(json, 'last_synced_at') ? undefined : (new Date(json['last_synced_at'])),
        'name': json['name'],
        'url': json['url'],
    };
}

export function HolidayUserFeedToJSON(value?: HolidayUserFeed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': ActorToJSON(value.creator),
        'id': value.id,
        'last_error_at': value.last_error_at === undefined ? undefined : (value.last_error_at.toISOString()),
        'last_synced_at': value.last_synced_at === undefined ? undefined : (value.last_synced_at.toISOString()),
        'name': value.name,
        'url': value.url,
    };
}

