/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureComponent,
    StatusPageStructureComponentFromJSON,
    StatusPageStructureComponentFromJSONTyped,
    StatusPageStructureComponentToJSON,
} from './StatusPageStructureComponent';

/**
 * 
 * @export
 * @interface StatusPageStructureGroup
 */
export interface StatusPageStructureGroup {
    /**
     * Array of components belonging to this group
     * @type {Array<StatusPageStructureComponent>}
     * @memberof StatusPageStructureGroup
     */
    components: Array<StatusPageStructureComponent>;
    /**
     * The description of this component group
     * @type {string}
     * @memberof StatusPageStructureGroup
     */
    description?: string;
    /**
     * Whether to display an aggregated uptime for all components of this group
     * @type {boolean}
     * @memberof StatusPageStructureGroup
     */
    display_aggregated_uptime: boolean;
    /**
     * Whether this group is hidden from display on the status page
     * @type {boolean}
     * @memberof StatusPageStructureGroup
     */
    hidden: boolean;
    /**
     * Unique ID of this component group
     * @type {string}
     * @memberof StatusPageStructureGroup
     */
    id: string;
    /**
     * The name of this component group
     * @type {string}
     * @memberof StatusPageStructureGroup
     */
    name: string;
}

export function StatusPageStructureGroupFromJSON(json: any): StatusPageStructureGroup {
    return StatusPageStructureGroupFromJSONTyped(json, false);
}

export function StatusPageStructureGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'components': ((json['components'] as Array<any>).map(StatusPageStructureComponentFromJSON)),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'display_aggregated_uptime': json['display_aggregated_uptime'],
        'hidden': json['hidden'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function StatusPageStructureGroupToJSON(value?: StatusPageStructureGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'components': ((value.components as Array<any>).map(StatusPageStructureComponentToJSON)),
        'description': value.description,
        'display_aggregated_uptime': value.display_aggregated_uptime,
        'hidden': value.hidden,
        'id': value.id,
        'name': value.name,
    };
}

