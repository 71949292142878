import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  CustomFieldFieldModeEnum,
  CustomFieldRequiredV2Enum as RequiredEnum,
  Expression,
} from "../../../contexts/ClientContext";
import { useQueryParams } from "../../../utils/query-params";
import {
  CustomFieldCreateEditDrawer,
  CustomFieldFormData,
} from "./create-edit/CustomFieldCreateEditDrawer";
import { CustomFieldOptionData } from "./create-edit/marshall";
import { customFieldTemplates } from "./templates";

export const getDefaultCustomFieldOption = (): CustomFieldOptionData => ({
  value: "",
  sort_key: 10000,
});

export const CustomFieldCreateRoute = ({
  expression,
}: {
  expression?: Expression;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/custom-fields");

  // Optional template parameter.
  const templateName = useQueryParams().get("template");

  return (
    <CustomFieldCreateForm
      onClose={onClose}
      templateName={templateName}
      expression={expression}
    />
  );
};

export const CustomFieldCreateForm = ({
  onClose,
  templateName,
  expression,
}: {
  onClose: () => void;
  templateName: string | null;
  expression?: Expression;
}): React.ReactElement => {
  // Optional template parameter.
  const template: Partial<CustomFieldFormData> = templateName
    ? customFieldTemplates[templateName]
    : {
        required_v2: RequiredEnum.Never,
        // If we begin with no options, we'll want to add one so the user knows the
        // field is there.
        options: [
          {
            itemType: "option",
            option: getDefaultCustomFieldOption(),
            key: uuidv4(),
          },
        ],
      };

  if (expression) {
    template.field_mode = CustomFieldFieldModeEnum.SensibleDefault;
    template.engine_expression = expression;
  }

  const onSuccess = async () => {
    onClose();
  };

  return (
    <CustomFieldCreateEditDrawer
      template={template}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  );
};
