import type { SVGProps } from "react";
import * as React from "react";
const SvgFolderOpen = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.5 16.5h-.75A2.752 2.752 0 0 1 2 13.75v-8A2.752 2.752 0 0 1 4.75 3h1.825c.809 0 1.572.354 2.096.97l1.3 1.53h4.279A2.752 2.752 0 0 1 17 8.25v1h-1.5v-1c0-.689-.561-1.25-1.25-1.25H9.624a.748.748 0 0 1-.571-.265L7.529 4.94a1.248 1.248 0 0 0-.953-.44H4.751c-.689 0-1.25.561-1.25 1.25v8c0 .689.561 1.25 1.25 1.25h.75L5.5 16.5Z"
    />
    <path
      fill="currentColor"
      d="M17.829 9.382a2.233 2.233 0 0 0-1.786-.882H6.649a2.256 2.256 0 0 0-2.173 1.665l-.942 3.5a2.233 2.233 0 0 0 .387 1.954c.43.561 1.08.882 1.786.882h9.01a2.756 2.756 0 0 0 2.656-2.035l.843-3.13a2.233 2.233 0 0 0-.387-1.954Z"
    />
  </svg>
);
export default SvgFolderOpen;
