/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingValuePayload,
    EngineParamBindingValuePayloadFromJSON,
    EngineParamBindingValuePayloadFromJSONTyped,
    EngineParamBindingValuePayloadToJSON,
} from './EngineParamBindingValuePayload';

/**
 * 
 * @export
 * @interface EngineParamBindingPayload
 */
export interface EngineParamBindingPayload {
    /**
     * If set, this is the array value of the step parameter
     * @type {Array<EngineParamBindingValuePayload>}
     * @memberof EngineParamBindingPayload
     */
    array_value?: Array<EngineParamBindingValuePayload>;
    /**
     * 
     * @type {EngineParamBindingValuePayload}
     * @memberof EngineParamBindingPayload
     */
    value?: EngineParamBindingValuePayload;
}

export function EngineParamBindingPayloadFromJSON(json: any): EngineParamBindingPayload {
    return EngineParamBindingPayloadFromJSONTyped(json, false);
}

export function EngineParamBindingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineParamBindingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array_value': !exists(json, 'array_value') ? undefined : ((json['array_value'] as Array<any>).map(EngineParamBindingValuePayloadFromJSON)),
        'value': !exists(json, 'value') ? undefined : EngineParamBindingValuePayloadFromJSON(json['value']),
    };
}

export function EngineParamBindingPayloadToJSON(value?: EngineParamBindingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array_value': value.array_value === undefined ? undefined : ((value.array_value as Array<any>).map(EngineParamBindingValuePayloadToJSON)),
        'value': EngineParamBindingValuePayloadToJSON(value.value),
    };
}

