/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogTypeGeneratedRepository
 */
export interface CatalogTypeGeneratedRepository {
    /**
     * The ID of the CatalogTypeGeneratedRepository
     * @type {string}
     * @memberof CatalogTypeGeneratedRepository
     */
    id: string;
    /**
     * The IDs of the catalog types included in the generated repository
     * @type {Array<string>}
     * @memberof CatalogTypeGeneratedRepository
     */
    selected_catalog_type_ids: Array<string>;
}

export function CatalogTypeGeneratedRepositoryFromJSON(json: any): CatalogTypeGeneratedRepository {
    return CatalogTypeGeneratedRepositoryFromJSONTyped(json, false);
}

export function CatalogTypeGeneratedRepositoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogTypeGeneratedRepository {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'selected_catalog_type_ids': json['selected_catalog_type_ids'],
    };
}

export function CatalogTypeGeneratedRepositoryToJSON(value?: CatalogTypeGeneratedRepository | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'selected_catalog_type_ids': value.selected_catalog_type_ids,
    };
}

