import { IncidentStatusCategoryEnum } from "@incident-io/api";
import { ErrorMessage } from "@incident-ui";
import { ModalFooter } from "@incident-ui";
import { compact } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { useAllStatuses } from "../../incident/useIncidentCrudResources";
import { BulkNoTriggersNotice } from "./BulkNoTriggersNotice";

export type BulkSetIncidentStatusFormData = {
  to_incident_status_id: string;
};

export function BulkDeclineForm({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const formMethods = useForm<BulkSetIncidentStatusFormData>({});

  const [bulkErrors, setBulkErrors] = useState<string[] | null>(null);
  const analytics = useAnalytics();

  const { allStatuses, allStatusesLoading } = useAllStatuses();
  const declineStatus = allStatuses.find(
    (status) => status.category === IncidentStatusCategoryEnum.Declined,
  );

  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, _: BulkSetIncidentStatusFormData) => {
      if (!declineStatus) {
        throw new Error("Decline status not found");
      }
      analytics?.track("bulkActionApplied", {
        action: "decline",
        numIncidents: incidentIDs.length,
      });

      const { results } = await apiClient.incidentsBulkCreateUpdate({
        bulkCreateUpdateRequestBody: {
          incident_ids: incidentIDs,
          incident_update: {
            to_incident_status_id: declineStatus.id,
          },
        },
      });

      const errors = compact(results.map((result) => result.error));
      if (errors && errors.length !== 0) {
        setBulkErrors(errors);
      } else {
        onSubmit();
      }
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={submit}
      title={"Decline incidents"}
      genericError={genericError}
      loading={allStatusesLoading}
      analyticsTrackingId="bulk-set-status"
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonText={"Decline all"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <BulkNoTriggersNotice />
      {bulkErrors && (
        <ErrorMessage
          message={`We encountered a problem updating ${bulkErrors.length} of your incidents. If you keep encountering errors, please contact support.`}
        />
      )}
    </Form.Modal>
  );
}
