/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssociatedApplication,
    AssociatedApplicationFromJSON,
    AssociatedApplicationFromJSONTyped,
    AssociatedApplicationToJSON,
} from './AssociatedApplication';

/**
 * 
 * @export
 * @interface IntegrationsMicrosoftDomainVerificationResponseBody
 */
export interface IntegrationsMicrosoftDomainVerificationResponseBody {
    /**
     * Associated Azure application IDs
     * @type {Array<AssociatedApplication>}
     * @memberof IntegrationsMicrosoftDomainVerificationResponseBody
     */
    associatedApplications: Array<AssociatedApplication>;
}

export function IntegrationsMicrosoftDomainVerificationResponseBodyFromJSON(json: any): IntegrationsMicrosoftDomainVerificationResponseBody {
    return IntegrationsMicrosoftDomainVerificationResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsMicrosoftDomainVerificationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsMicrosoftDomainVerificationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'associatedApplications': ((json['associatedApplications'] as Array<any>).map(AssociatedApplicationFromJSON)),
    };
}

export function IntegrationsMicrosoftDomainVerificationResponseBodyToJSON(value?: IntegrationsMicrosoftDomainVerificationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'associatedApplications': ((value.associatedApplications as Array<any>).map(AssociatedApplicationToJSON)),
    };
}

