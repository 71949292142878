/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchedulePayConfig,
    SchedulePayConfigFromJSON,
    SchedulePayConfigFromJSONTyped,
    SchedulePayConfigToJSON,
} from './SchedulePayConfig';

/**
 * 
 * @export
 * @interface SchedulesShowPayConfigResponseBody
 */
export interface SchedulesShowPayConfigResponseBody {
    /**
     * 
     * @type {SchedulePayConfig}
     * @memberof SchedulesShowPayConfigResponseBody
     */
    schedule_pay_config: SchedulePayConfig;
}

export function SchedulesShowPayConfigResponseBodyFromJSON(json: any): SchedulesShowPayConfigResponseBody {
    return SchedulesShowPayConfigResponseBodyFromJSONTyped(json, false);
}

export function SchedulesShowPayConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesShowPayConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_pay_config': SchedulePayConfigFromJSON(json['schedule_pay_config']),
    };
}

export function SchedulesShowPayConfigResponseBodyToJSON(value?: SchedulesShowPayConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_pay_config': SchedulePayConfigToJSON(value.schedule_pay_config),
    };
}

