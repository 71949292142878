import { captureException } from "@sentry/react";
import React from "react";
import { Condition } from "src/contexts/ClientContext";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { BindingLabel } from "..";

export const ConditionValue = ({
  condition,
}: {
  condition: Condition;
}): React.ReactElement | null => {
  if (
    condition.param_bindings == null ||
    condition.param_bindings.length === 0
  ) {
    return null;
  }
  if (condition.param_bindings.length > 1) {
    captureException(
      new Error(
        "I don't know how to handle multiple params. UI will ignore all except the first.",
      ),
    );
  }

  const param = condition.param_bindings[0];
  if (param.value) {
    return <BindingLabel paramBinding={param.value} hideAvatar />;
  } else {
    // There's a weird interaction between Goa and encoding/json which means
    // that if an array field is marked as optional in the OpenAPI spec, the
    // response struct gets a `json:"thing,omitempty"` tag, and json.Marshal
    // decides that either a nil or empty array should therefore be omitted from
    // the response.
    //
    // That means that even if the API code returns `{ array_value: [] }`, when
    // that gets serialized to JSON it will come out as just `{}`, leaving
    // `params.array_value` undefined here. We fix that by defaulting it to an
    // empty array here.
    param.array_value = param.array_value || [];
    return joinSpansWithCommasAndConnectorWord(
      param.array_value.map((o) => (
        <BindingLabel paramBinding={o} key={o.label} hideAvatar />
      )),
      "or",
    );
  }
};
