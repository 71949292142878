import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { PopoverTitleBar } from "@incident-ui/Popover/Popover";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import {
  EscalationPathFormData,
  EscalationPathTimeToAckOption,
} from "../common/types";

export const TimeToAckForm = ({
  id,
  nodeType,
  formMethods,
  shouldAllowWorkingHoursTimeToAck,
}: {
  id: string;
  nodeType: "level" | "notifyChannel";
  formMethods: UseFormReturn<EscalationPathFormData>;
  shouldAllowWorkingHoursTimeToAck: boolean;
}) => {
  const timeToAckMinutes = formMethods.watch(
    `nodes.${id}.data.notifyChannel.time_to_ack_custom_minutes`,
  );

  const timeToAckEntries = Object.entries(timeToAckOptions)
    .filter(
      ([_, option]) => shouldAllowWorkingHoursTimeToAck || option.minutesBased,
    )
    .filter(
      ([key]) =>
        key !== EscalationPathTimeToAckOption.MinutesZero ||
        nodeType === "notifyChannel",
    )
    .map(([value, option]) => {
      let onSelectRender;

      if (value === EscalationPathTimeToAckOption.MinutesCustom) {
        onSelectRender = ({ onBack, onClose, onChange }) => {
          return (
            <CustomTimeToAckForm
              onBack={onBack}
              onClose={onClose}
              onSubmit={(customTime: number) => {
                onChange(EscalationPathTimeToAckOption.MinutesCustom);
                formMethods.setValue(
                  `nodes.${id}.data.${nodeType}.time_to_ack_custom_minutes`,
                  customTime,
                );
              }}
              defaultValue={timeToAckMinutes}
            />
          );
        };
      }

      return {
        ...option,
        value,
        onSelectRender,
      };
    });

  return (
    <PopoverSingleSelectV2
      options={timeToAckEntries}
      sideOffset={1}
      name={`nodes.${id}.data.${nodeType}.time_to_ack_option`}
      formMethods={formMethods}
      tooltipContent="How long should we wait before moving on to the next level?"
      tooltipSide="bottom"
      renderTriggerNode={({ onClick, selectedOption }) => {
        let label = "Select time to ack value";
        if (selectedOption?.value !== undefined) {
          if (
            selectedOption.value === EscalationPathTimeToAckOption.MinutesCustom
          ) {
            const customMinutes = formMethods.getValues(
              `nodes.${id}.data.${nodeType}.time_to_ack_custom_minutes`,
            );
            label = `${customMinutes}m`;
          } else if (
            selectedOption.value === EscalationPathTimeToAckOption.MinutesZero
          ) {
            label = selectedOption.label;
          } else if (selectedOption.minutesBased) {
            label = `${selectedOption.numMinutes}m`;
          } else {
            label = selectedOption.label;
          }
        }

        return (
          <Button
            onClick={onClick}
            theme={ButtonTheme.Tertiary}
            analyticsTrackingId={null}
            title={selectedOption?.label || "Select time to ack value"}
            size={BadgeSize.Medium}
          >
            {selectedOption?.icon ? (
              <Icon
                id={selectedOption?.icon}
                size={IconSize.Small}
                className="fill-slate-500"
              />
            ) : null}
            {label}
          </Button>
        );
      }}
    />
  );
};

const CustomTimeToAckForm = ({
  onBack,
  onClose,
  onSubmit,
  defaultValue,
}: {
  onBack: () => void;
  onClose: () => void;
  onSubmit: (value: number) => void;
  defaultValue?: number;
}) => {
  const formMethods = useForm<{ customMinutes: number }>({
    defaultValues: { customMinutes: defaultValue },
  });

  const minutes = formMethods.watch("customMinutes");
  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={(data) => {
        onSubmit(data.customMinutes);
        onClose();
      }}
      id="custom-time-to-ack-form"
    >
      <PopoverTitleBar title="Custom time (minutes)" handleBack={onBack} />
      <div className="p-3 flex flex-col gap-y-2 !m-0">
        <InputV2
          type={InputType.Number}
          formMethods={formMethods}
          name="customMinutes"
          placeholder="25"
          required
        />
        <Button
          form="custom-time-to-ack-form"
          type="submit"
          theme={ButtonTheme.Primary}
          analyticsTrackingId={null}
          title="Save"
          disabled={!minutes}
        >
          Save
        </Button>
      </div>
    </Form.Root>
  );
};

export const timeToAckOptions: {
  [key in EscalationPathTimeToAckOption]: key extends
    | EscalationPathTimeToAckOption.MinutesZero
    | EscalationPathTimeToAckOption.MinutesFive
    | EscalationPathTimeToAckOption.MinutesTen
    | EscalationPathTimeToAckOption.MinutesFifteen
    ? timeToAckOptionWithNumMinutes
    : timeToAckOption;
} = {
  [EscalationPathTimeToAckOption.MinutesZero]: {
    label: "Don’t wait",
    numMinutes: 0,
    icon: IconEnum.Quickstart,
    minutesBased: true,
  },
  [EscalationPathTimeToAckOption.MinutesFive]: {
    label: "5 minutes",
    numMinutes: 5,
    icon: IconEnum.Quickstart,
    minutesBased: true,
  },
  [EscalationPathTimeToAckOption.MinutesTen]: {
    label: "10 minutes",
    numMinutes: 10,
    icon: IconEnum.Quickstart,
    minutesBased: true,
  },
  [EscalationPathTimeToAckOption.MinutesFifteen]: {
    label: "15 minutes",
    numMinutes: 15,
    icon: IconEnum.Quickstart,
    minutesBased: true,
  },
  [EscalationPathTimeToAckOption.MinutesCustom]: {
    label: "Custom time",
    icon: IconEnum.Quickstart,
    minutesBased: true,
  },
  [EscalationPathTimeToAckOption.WorkingHoursActive]: {
    label: "Working hours begin",
    icon: IconEnum.WorkingHours,
  },
  [EscalationPathTimeToAckOption.WorkingHoursInactive]: {
    label: "Working hours end",
    icon: IconEnum.WorkingHours,
  },
};

type timeToAckOption = {
  icon: IconEnum;
  label: string;
  minutesBased?: boolean;
  numMinutes?: number;
};

type timeToAckOptionWithNumMinutes = timeToAckOption & {
  numMinutes: number;
  minutesBased: true;
};
