import {
  HumanizeDuration,
  HumanizeDurationLanguage,
} from "humanize-duration-ts";

// FriendlyDuration formats a duration in seconds to a human readable format e.g. 2 days, 1 hour.
export const FriendlyDuration = ({ seconds }: { seconds: number }) => {
  const humanizer = new HumanizeDuration(new HumanizeDurationLanguage());
  return <span>{humanizer.humanize(seconds * 1000)}</span>;
};
