import {
  IncidentStatus,
  PostIncidentTask,
  PostIncidentTaskConfig,
  PostIncidentTaskConfigSlim,
  PostIncidentTaskConfigSlimTaskTypeEnum,
  PostIncidentTaskSlim,
} from "@incident-io/api";
import { isEmpty } from "lodash";

// What a horrible way of convincing typescript that yes, indeed, the enums are the same.
export const taskToSlim = (task: PostIncidentTask): PostIncidentTaskSlim => ({
  ...task,
  config: taskConfigToSlim(task.config),
});

export const taskConfigToSlim = (
  config: PostIncidentTaskConfig,
): PostIncidentTaskConfigSlim => ({
  ...config,
  task_type:
    config.task_type as unknown as PostIncidentTaskConfigSlimTaskTypeEnum,
  uses_auto_assign:
    !!config.assignee?.value?.literal || !!config.assignee?.value?.reference,
});

export const findCurrentTask = ({
  status,
  tasks,
}: {
  status: IncidentStatus;
  tasks: PostIncidentTask[];
}): PostIncidentTask | undefined => {
  // First, filter to just the tasks of the current status
  const tasksForCurrentStatus = tasks.filter(
    (t) => t.config.incident_status_id === status.id,
  );

  // sort them by their rank, just in case the API didn't
  tasksForCurrentStatus.sort((a, b) =>
    a.config.rank > b.config.rank ? 1 : -1,
  );

  if (isEmpty(tasksForCurrentStatus)) {
    return undefined;
  }

  // Find the first task that isn't completed or rejected.
  for (const task of tasksForCurrentStatus) {
    if (!task.completed_at && !task.rejected_at) {
      return task;
    }
  }

  return undefined;
};

export const taskTotals = (incidentTasks: PostIncidentTask[]) => {
  const numCompleted = incidentTasks.filter(
    (task) => task.completed_at || task.rejected_at,
  ).length;
  const numTotal = incidentTasks.length;
  const perc = Math.round((numCompleted / numTotal) * 100);

  return {
    numCompleted,
    numTotal,
    perc,
  };
};
