import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusTriage = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#2463EB"
      fillRule="evenodd"
      d="M13.41 9A5.502 5.502 0 0 1 2.5 8c0-2.696 1.94-4.939 4.5-5.41V1.07A7.002 7.002 0 0 0 8 15a7.001 7.001 0 0 0 6.93-6h-1.52Z"
      clipRule="evenodd"
      opacity={0.3}
    />
    <path
      fill="#2463EB"
      fillRule="evenodd"
      d="M13.5 8H15a7 7 0 0 0-7-7v1.5A5.5 5.5 0 0 1 13.5 8Z"
      clipRule="evenodd"
    />
    <path
      fill="#2463EB"
      d="M8 5.778A2.224 2.224 0 0 0 5.778 8c0 1.226.996 2.222 2.222 2.222A2.224 2.224 0 0 0 10.222 8 2.224 2.224 0 0 0 8 5.778Z"
    />
  </svg>
);
export default SvgIncidentStatusTriage;
