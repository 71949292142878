/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleSlim
 */
export interface ScheduleSlim {
    /**
     * 
     * @type {Date}
     * @memberof ScheduleSlim
     */
    created_at: Date;
    /**
     * If set, the schedule description synced from the external provider
     * @type {string}
     * @memberof ScheduleSlim
     */
    description?: string;
    /**
     * Unique internal ID of the schedule
     * @type {string}
     * @memberof ScheduleSlim
     */
    id: string;
    /**
     * Human readable name synced from external provider
     * @type {string}
     * @memberof ScheduleSlim
     */
    name: string;
    /**
     * Timezone of the schedule, as interpreted at the point of generating the report
     * @type {string}
     * @memberof ScheduleSlim
     */
    timezone: string;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleSlim
     */
    updated_at: Date;
}

export function ScheduleSlimFromJSON(json: any): ScheduleSlim {
    return ScheduleSlimFromJSONTyped(json, false);
}

export function ScheduleSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'name': json['name'],
        'timezone': json['timezone'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function ScheduleSlimToJSON(value?: ScheduleSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'timezone': value.timezone,
        'updated_at': (value.updated_at.toISOString()),
    };
}

