import type { SVGProps } from "react";
import * as React from "react";
const SvgClipboard = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 3.5h-.275A1.747 1.747 0 0 0 12.25 2h-3.5c-.879 0-1.602.654-1.725 1.5H6.75A2.752 2.752 0 0 0 4 6.25v9.5a2.752 2.752 0 0 0 2.75 2.75h7.5A2.752 2.752 0 0 0 17 15.75v-9.5a2.752 2.752 0 0 0-2.75-2.75Zm-5.75.25a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v1a.25.25 0 0 1-.25.25h-3.5a.25.25 0 0 1-.25-.25v-1ZM13.25 14h-5.5a.75.75 0 0 1 0-1.5h5.5a.75.75 0 0 1 0 1.5Zm0-3h-5.5a.75.75 0 0 1 0-1.5h5.5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgClipboard;
