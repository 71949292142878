/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageCheckSubpathAvailableRequestBody
 */
export interface StatusPageCheckSubpathAvailableRequestBody {
    /**
     * If this is editing an existing status page, supply its ID here
     * @type {string}
     * @memberof StatusPageCheckSubpathAvailableRequestBody
     */
    status_page_id?: string;
    /**
     * The subpath of the status page for our standard domain
     * @type {string}
     * @memberof StatusPageCheckSubpathAvailableRequestBody
     */
    subpath: string;
}

export function StatusPageCheckSubpathAvailableRequestBodyFromJSON(json: any): StatusPageCheckSubpathAvailableRequestBody {
    return StatusPageCheckSubpathAvailableRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCheckSubpathAvailableRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCheckSubpathAvailableRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status_page_id': !exists(json, 'status_page_id') ? undefined : json['status_page_id'],
        'subpath': json['subpath'],
    };
}

export function StatusPageCheckSubpathAvailableRequestBodyToJSON(value?: StatusPageCheckSubpathAvailableRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_page_id': value.status_page_id,
        'subpath': value.subpath,
    };
}

