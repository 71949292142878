/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemsCreateDestinationRequestBody
 */
export interface PostmortemsCreateDestinationRequestBody {
    /**
     * The document provider for the destination
     * @type {string}
     * @memberof PostmortemsCreateDestinationRequestBody
     */
    document_provider: PostmortemsCreateDestinationRequestBodyDocumentProviderEnum;
    /**
     * If this destination will be used as the default destination
     * @type {boolean}
     * @memberof PostmortemsCreateDestinationRequestBody
     */
    is_default: boolean;
    /**
     * The user-friendly name of the destination
     * @type {string}
     * @memberof PostmortemsCreateDestinationRequestBody
     */
    name: string;
    /**
     * The location within a document provider
     * @type {string}
     * @memberof PostmortemsCreateDestinationRequestBody
     */
    provider_location: string;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemsCreateDestinationRequestBodyDocumentProviderEnum {
    Empty = '',
    Confluence = 'confluence',
    GoogleDocs = 'google_docs',
    Notion = 'notion',
    CopyPasteBasecamp = 'copy_paste_basecamp',
    CopyPasteConfluence = 'copy_paste_confluence',
    CopyPasteGithubWiki = 'copy_paste_github_wiki',
    CopyPasteGoogleDocs = 'copy_paste_google_docs',
    CopyPasteNotion = 'copy_paste_notion',
    CopyPasteQuip = 'copy_paste_quip'
}

export function PostmortemsCreateDestinationRequestBodyFromJSON(json: any): PostmortemsCreateDestinationRequestBody {
    return PostmortemsCreateDestinationRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsCreateDestinationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsCreateDestinationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document_provider': json['document_provider'],
        'is_default': json['is_default'],
        'name': json['name'],
        'provider_location': json['provider_location'],
    };
}

export function PostmortemsCreateDestinationRequestBodyToJSON(value?: PostmortemsCreateDestinationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document_provider': value.document_provider,
        'is_default': value.is_default,
        'name': value.name,
        'provider_location': value.provider_location,
    };
}

