/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReturnsMeta,
    ReturnsMetaFromJSON,
    ReturnsMetaFromJSONTyped,
    ReturnsMetaToJSON,
} from './ReturnsMeta';

/**
 * 
 * @export
 * @interface ExpressionParseOptsPayload
 */
export interface ExpressionParseOptsPayload {
    /**
     * 
     * @type {ReturnsMeta}
     * @memberof ExpressionParseOptsPayload
     */
    returns: ReturnsMeta;
    /**
     * Source expression that is evaluated to a result
     * @type {string}
     * @memberof ExpressionParseOptsPayload
     */
    source: string;
}

export function ExpressionParseOptsPayloadFromJSON(json: any): ExpressionParseOptsPayload {
    return ExpressionParseOptsPayloadFromJSONTyped(json, false);
}

export function ExpressionParseOptsPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionParseOptsPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'returns': ReturnsMetaFromJSON(json['returns']),
        'source': json['source'],
    };
}

export function ExpressionParseOptsPayloadToJSON(value?: ExpressionParseOptsPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'returns': ReturnsMetaToJSON(value.returns),
        'source': value.source,
    };
}

