/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsightsTestInsightsReportRequestBody
 */
export interface InsightsTestInsightsReportRequestBody {
    /**
     * The channel to send the report to (only email is supported for now)
     * @type {string}
     * @memberof InsightsTestInsightsReportRequestBody
     */
    channel: InsightsTestInsightsReportRequestBodyChannelEnum;
    /**
     * ID of the custom dashboard
     * @type {string}
     * @memberof InsightsTestInsightsReportRequestBody
     */
    custom_dashboard_id: string;
}

/**
* @export
* @enum {string}
*/
export enum InsightsTestInsightsReportRequestBodyChannelEnum {
    Email = 'email'
}

export function InsightsTestInsightsReportRequestBodyFromJSON(json: any): InsightsTestInsightsReportRequestBody {
    return InsightsTestInsightsReportRequestBodyFromJSONTyped(json, false);
}

export function InsightsTestInsightsReportRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsTestInsightsReportRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel': json['channel'],
        'custom_dashboard_id': json['custom_dashboard_id'],
    };
}

export function InsightsTestInsightsReportRequestBodyToJSON(value?: InsightsTestInsightsReportRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel': value.channel,
        'custom_dashboard_id': value.custom_dashboard_id,
    };
}

