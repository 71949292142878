import { Alert } from "@incident-io/api";
import { PopoverSingleSelectWithObjV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { captureException } from "@sentry/react";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { HeadlineMetrics } from "./insights/HeadlineMetrics";
import { WorkloadChart } from "./insights/WorkloadChart";

export type AlertInsightsFormData = {
  matchBy: { label: string; value: "title" | "deduplicationKey" };
};

export const AlertInsights = ({ alert }: { alert: Alert }) => {
  const formMethods = useForm<AlertInsightsFormData>({
    defaultValues: {
      matchBy: { label: "title", value: "title" },
    },
  });

  const matchBy = formMethods.watch("matchBy");

  const dates = useMemo(() => {
    // We send UTC times to the backend alongside with a timezone.
    // Our database query will take care of converting those times to the correct timezone,
    // so that we return activity data grouped to a day that makes sense to the user.
    const now = new Date();
    const thirtyDaysAgo = new Date(now);
    thirtyDaysAgo.setDate(now.getDate() - 30);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      now,
      thirtyDaysAgo,
      timezone,
    };
  }, []); // Empty dependency array means this will only calculate once

  const { data: insightsData, error: insightsError } = useAPI(
    "alertsGetInsights",
    {
      [matchBy.value]:
        matchBy.value === "title" ? alert.title : alert.deduplication_key,
      startDate: dates.thirtyDaysAgo,
      endDate: dates.now,
      timezone: dates.timezone,
    },
  );

  const { data: lastSyncData } = useAPI(
    "insightsGetLastSync",
    {},
    {
      refreshInterval: 1000 * 30, //
    },
  );

  if (insightsError) {
    // If we fail to render the insights, we should still render the rest of the page
    // as it is quite informational.
    captureException(insightsError);
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <div className={"flex flex-col gap-2"}>
        <div className={"justify-between items-center inline-flex"}>
          <div className={"justify-start items-baseline gap-2 inline-flex"}>
            <span className={"font-semibold"}>Alert insights</span>
            <span className={"text-xs-med text-content-secondary"}>
              Last 30 days
            </span>
          </div>
          <div className={"inline-flex items-baseline gap-2"}>
            <span className={"text-sm-med text-content-secondary"}>
              Group by
            </span>
            <PopoverSingleSelectWithObjV2
              name={"matchBy"}
              formMethods={formMethods}
              renderTriggerNode={({ selectedOption, onClick }) => (
                <Button
                  size={BadgeSize.Medium}
                  analyticsTrackingId={null}
                  theme={ButtonTheme.Tertiary}
                  onClick={onClick}
                >
                  {selectedOption?.label || "Choose..."}
                  <Icon size={IconSize.Small} id={IconEnum.ChevronDown} />
                </Button>
              )}
              options={[
                { label: "Title", value: "title" },
                { label: "Deduplication key", value: "deduplicationKey" },
              ]}
            />
          </div>
        </div>
        <div className={"flex flex-col gap-3"}>
          <HeadlineMetrics
            data={insightsData}
            groupBy={matchBy}
            lastSyncAt={lastSyncData?.last_sync_at}
          />
          <WorkloadChart
            data={insightsData}
            alert={alert}
            lastSyncAt={lastSyncData?.last_sync_at}
          />
        </div>
      </div>
    </FormProvider>
  );
};
