"use client";

import {
  createContext,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useResizeObserver from "use-resize-observer";

export type BoundingContainer = {
  width: number | null;
  windowWidth: number | null;
  height: number | null;
  left: number | null;
  top: number | null;
};

const defaults = {
  width: null,
  windowWidth: null,
  height: null,
  left: null,
  top: null,
};

const OuterContainerContext = createContext<{
  outerContainer: HTMLElement | null;
}>({
  outerContainer: null,
});

// By default, the useResize hook assumes that the `<body>` element is the base
// of the app it's rendering in, and will change its size if the contents of the
// app move around (e.g. due to expanding/collapsing, changing contents, etc.).
//
// If that's not true, for example because the app is wrapped in an
// absolute-height container from which the content overflows, use this to wrap
// the part of the DOM which will get resized and is effectively 'full screen'.
export const OuterContainerProvider = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <div ref={ref} className={className}>
      <OuterContainerContext.Provider value={{ outerContainer: ref.current }}>
        {children}
      </OuterContainerContext.Provider>
    </div>
  );
};

const useOuterContainerRef = () => {
  const bodyRef = useRef<HTMLElement | null>(null);
  const { outerContainer } = useContext(OuterContainerContext);

  useEffect(() => {
    bodyRef.current = document.body;
  }, []);

  return outerContainer ?? bodyRef.current;
};

export const useResize = <T extends HTMLElement>(
  targetRef: RefObject<T>,
): BoundingContainer => {
  const [position, setPosition] = useState<BoundingContainer>(defaults);

  const bodyRef = useOuterContainerRef();

  const getPosition = () => {
    const elem = targetRef.current;
    if (elem == null) return defaults;

    // We want the position relative to the top of the page, so we can ignore
    // scrolling effects. Therefore we add the current scroll offset to the
    // offset relative to the viewport to get absolute coordinates.
    const boundingRect = elem.getBoundingClientRect();
    return {
      top: boundingRect.top + window.scrollY,
      left: boundingRect.left + window.scrollX,
    };
  };

  // When the target size changes, update the size and positioning
  useResizeObserver({
    ref: targetRef,
    box: "border-box",
    onResize: ({ width, height }) => {
      setPosition((prev) => ({
        ...prev,
        ...getPosition(),
        width: width ?? null,
        height: height ?? null,
      }));
    },
  });

  // When the body element's size changes, recalculate the offsets of the target
  useResizeObserver({
    ref: bodyRef,
    onResize: ({ width }) => {
      setPosition((prev) => ({
        ...prev,
        ...getPosition(),
        windowWidth: width ?? null,
      }));
    },
  });

  return position;
};
