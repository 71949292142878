import _ from "lodash";
import { useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { StatusPage } from "src/contexts/ClientContext";

import { FormType as ComponentsFormType } from "../../common/ComponentsEditor/utils";
import {
  LogoData,
  StatusPagesLogoUploader,
} from "../../edit/StatusPagesLogoUploader";

export type EmailFormData = {
  email_logo: LogoData;
};

export const buildEmailFormData = (page: StatusPage): EmailFormData => {
  if (page.email_logo_key && page.email_logo_url) {
    return {
      email_logo: {
        key: page.email_logo_key,
        preview_url: page.email_logo_url,
      },
    };
  }

  return { email_logo: null };
};

export const EmailForm = ({
  name,
  subpath,
  lightmode_logo_url: initialLightmodeLogoURL,
}: ComponentsFormType &
  Pick<
    StatusPage,
    "name" | "subpath" | "email_logo_url" | "lightmode_logo_url"
  >): React.ReactElement => {
  const { watch } = useFormContext<EmailFormData>();

  const emailLogoURL = watch("email_logo")?.preview_url;

  const previewURL = emailLogoURL ?? initialLightmodeLogoURL ?? null;

  return (
    <>
      <div>
        <h3 className="text-base font-medium pt-2 !mb-2">Emails</h3>
        <Form.Helptext className="text-sm text-slate-600">
          Your customers will be able to subscribe to email updates on your
          status page. If you&apos;d like them to include your company logo, you
          can add that here. We suggest adding a logo that looks good on light
          backgrounds.
        </Form.Helptext>
      </div>
      <StatusPagesLogoUploader
        name="email_logo"
        subpath={subpath}
        label="Company logo"
        displayFilename={(previewURL) =>
          `logo.${_.last(previewURL.split(".")) || "png"}`
        }
        dropText="Drop your logo here in PNG or JPEG format"
      />
      <EmailPreview name={name} previewURL={previewURL} />
    </>
  );
};

const EmailPreview = ({
  name,
  previewURL,
}: {
  name: string;
  previewURL: string | null;
}) => {
  return (
    <div>
      <p className="font-medium text-sm mb-1">Preview</p>
      <div className="pt-8 px-10 bg-surface-tertiary rounded overflow-hidden">
        <div className="rounded-t-md drop-shadow-lg bg-white">
          <div className="space-y-2 pt-4 relative">
            <div className="p-4 flex justify-center items-center">
              <div className="h-6 relative flex grow justify-center">
                {previewURL ? (
                  <img
                    src={previewURL}
                    alt={name}
                    className="object-scale-down absolute h-full w-full"
                  />
                ) : (
                  <h1 className="text-2xl text-content-primary">{name}</h1>
                )}
              </div>
            </div>
            <div className="rounded-2 border border-stroke p-8 !pb-2 mx-8 text-slate-700">
              <p className="text-sm font-medium !mb-2">New incident </p>
              <p className="text-base font-medium text-content-primary !mb-2">
                Degraded API Service
              </p>
              <div className="flex items-center">
                <div className="rounded-full bg-alarmalade-600 w-[9px] h-[9px] mr-1" />
                <div className="text-xs text-content-primary mr-2">
                  Identified{" "}
                </div>

                <div className="text-xs text-slate-700">
                  Started March 06, 2023 14:56 GMT
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-t from-[#E1E6EB] to-transparent absolute bottom-0 left-0 w-full h-20" />
          </div>
        </div>
      </div>
    </div>
  );
};
