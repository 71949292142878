/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageIncidentLink,
    InternalStatusPageIncidentLinkFromJSON,
    InternalStatusPageIncidentLinkFromJSONTyped,
    InternalStatusPageIncidentLinkToJSON,
} from './InternalStatusPageIncidentLink';

/**
 * 
 * @export
 * @interface InternalStatusPageListIncidentLinksResponseBody
 */
export interface InternalStatusPageListIncidentLinksResponseBody {
    /**
     * The incidents linked to the given response incident.
     * @type {Array<InternalStatusPageIncidentLink>}
     * @memberof InternalStatusPageListIncidentLinksResponseBody
     */
    internal_status_page_incident_links: Array<InternalStatusPageIncidentLink>;
}

export function InternalStatusPageListIncidentLinksResponseBodyFromJSON(json: any): InternalStatusPageListIncidentLinksResponseBody {
    return InternalStatusPageListIncidentLinksResponseBodyFromJSONTyped(json, false);
}

export function InternalStatusPageListIncidentLinksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageListIncidentLinksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'internal_status_page_incident_links': ((json['internal_status_page_incident_links'] as Array<any>).map(InternalStatusPageIncidentLinkFromJSON)),
    };
}

export function InternalStatusPageListIncidentLinksResponseBodyToJSON(value?: InternalStatusPageListIncidentLinksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internal_status_page_incident_links': ((value.internal_status_page_incident_links as Array<any>).map(InternalStatusPageIncidentLinkToJSON)),
    };
}

