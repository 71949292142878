import { Form } from "@incident-shared/forms";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { CurrencyInputV2 } from "@incident-shared/forms/v2/inputs/CurrencyInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ModalContent, ModalFooter } from "@incident-ui";
import {
  currencyCentsToStr,
  currencyStrToCents,
  currencyValidationRules,
} from "@incident-ui/Input/CurrencyInput";
import { Controller, useForm } from "react-hook-form";
import {
  SchedulePayConfig,
  SchedulePayRule,
  SchedulePayRulePayloadWeekdaysEnum,
  SchedulePayRuleWeekdaysEnum,
  ScheduleReportOverride,
  ScheduleReportOverridePayload,
  SchedulesCreatePayConfigRequestBody,
  SchedulesShowPayConfigResponseBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { v4 as uuidv4 } from "uuid";

import { getCurrencySelectOptions } from "../../../../utils/currency";
import { fromISOUTCDateStr, toUTCISODateStr } from "./date-time-helpers";
import { DateTimeFormType } from "./DateTimeInput";
import { OverridesEditor } from "./OnCallPayOverridesEditor";
import { PayConfigCalendar } from "./PayConfigCalendar";
import { PayRulesEditor } from "./PayRulesEditor";

export type PayRuleFormType = Omit<SchedulePayRule, "rate_cents"> & {
  key: string;
  rate_pounds: string;
};

export type OverrideFormType = Omit<
  ScheduleReportOverride,
  "rate_cents" | "start_at" | "end_at" | "id"
> & {
  key: string;
  rate_pounds: string;
  start_at: DateTimeFormType;
  end_at: DateTimeFormType;
};

export type PayRuleConfigFormType = Omit<
  SchedulePayConfig,
  "pay_rules" | "overrides" | "base_rate_cents"
> & {
  pay_rules: PayRuleFormType[];
  overrides: OverrideFormType[];
  base_rate_pounds: string;
};

export const marshallOverrideFormTypeToConfig = (
  overrides: OverrideFormType[],
): ScheduleReportOverridePayload[] => {
  return overrides.map((override) => ({
    ...override,
    rate_cents: currencyStrToCents(override.rate_pounds),
    start_at: toUTCISODateStr(override.start_at),
    end_at: toUTCISODateStr(override.end_at),
  }));
};

export const marshallFormTypeToConfig = (
  config: PayRuleConfigFormType,
): SchedulesCreatePayConfigRequestBody => {
  return {
    name: config.name,
    currency: config.currency,
    base_rate_cents: currencyStrToCents(config.base_rate_pounds),
    overrides: marshallOverrideFormTypeToConfig(config.overrides || []),
    pay_rules: config.pay_rules
      ? config.pay_rules.map((rule) => ({
          ...rule,
          rate_cents: currencyStrToCents(rule.rate_pounds),
          weekdays:
            rule.weekdays as unknown as SchedulePayRulePayloadWeekdaysEnum[],
        }))
      : [],
  };
};

export const marshallConfigToOverrideFormType = (
  overrides: ScheduleReportOverride[],
): OverrideFormType[] => {
  return overrides.map((override) => ({
    ...override,
    rate_pounds: currencyCentsToStr(override.rate_cents),
    key: uuidv4(),
    start_at: fromISOUTCDateStr(override.start_at, false),
    end_at: fromISOUTCDateStr(override.end_at, true),
  }));
};

export const marshallConfigToFormType = (
  config: SchedulePayConfig,
): PayRuleConfigFormType => {
  return {
    ...config,
    base_rate_pounds: currencyCentsToStr(config.base_rate_cents),
    overrides: marshallConfigToOverrideFormType(config.overrides || []),
    pay_rules: config.pay_rules
      ? config.pay_rules.map((rule) => ({
          ...rule,
          rate_pounds: currencyCentsToStr(rule.rate_cents),
          weekdays: rule.weekdays as unknown as SchedulePayRuleWeekdaysEnum[],
          key: uuidv4(),
        }))
      : [],
  };
};

type Props = {
  onSubmit: (data: SchedulesCreatePayConfigRequestBody) => void;
  onClose: () => void;
  continueButtonText?: string;
  inModal?: boolean;
  saving?: boolean;
} & (
  | {
      mode: Mode.Create;
      initialConfig?: never;
    }
  | {
      mode: Mode.Edit;
      initialConfig: SchedulesShowPayConfigResponseBody;
    }
);

export function PayConfigCreateEditForm({
  mode,
  initialConfig,
  onSubmit: onSubmitCallback,
  onClose,
  continueButtonText = "Continue",
  saving,
}: Props): React.ReactElement {
  const defaultValues: Partial<PayRuleConfigFormType> =
    mode === Mode.Create
      ? { pay_rules: [], overrides: [] }
      : marshallConfigToFormType(initialConfig.schedule_pay_config);

  const requiredScope =
    mode === Mode.Create
      ? ScopeNameEnum.SchedulePayConfigsCreate
      : ScopeNameEnum.SchedulePayConfigsUpdate;

  const formMethods = useForm<PayRuleConfigFormType>({
    defaultValues,
  });
  const { watch, reset, control } = formMethods;

  const onSubmit = (data: PayRuleConfigFormType) => {
    onSubmitCallback(marshallFormTypeToConfig(data));
    reset();
  };

  const existingPayRules = watch("pay_rules");
  const selectedCurrency = watch("currency");
  const baseRate = watch("base_rate_pounds");

  return (
    <>
      <ModalContent>
        <Form.Root
          id="pay-config-form"
          formMethods={formMethods}
          onSubmit={onSubmit}
          saving={saving}
        >
          <p className="text-content-primary text-sm mb-2 max-w-xl">
            Use the form below to let us know what you pay for on-call e.g. your
            base rate, different rates for certain days or public holidays, and
            so on. Each configuration can be applied to one or more schedules.
          </p>
          <InputV2
            formMethods={formMethods}
            label="Pay configuration name"
            name="name"
            required="Please name this pay configuration"
            placeholder="e.g. Default rate"
          />
          <StaticSingleSelectV2
            formMethods={formMethods}
            isClearable={false}
            required
            label="What currency are you paying in?"
            name={"currency"}
            options={getCurrencySelectOptions()}
            placeholder="Select a currency"
          />
          <CurrencyInputV2
            formMethods={formMethods}
            name="base_rate_pounds"
            label={"How much do you pay per hour?"}
            currency={selectedCurrency}
            defaultValue={defaultValues.base_rate_pounds}
            required="Please provide a rate"
            rules={{ validate: currencyValidationRules }}
          />
          <Form.InputWrapper
            label="Weekly rules"
            name="pay_rules"
            helptext="These are rules that recur weekly that will override your base pay rate, for example you may pay more than your base rate on weekends."
          >
            <Controller
              control={control}
              name={"pay_rules"}
              render={({ field: { onChange, value } }) => (
                <PayRulesEditor
                  value={value}
                  onChange={onChange}
                  currency={selectedCurrency}
                  baseRate={baseRate}
                />
              )}
            />
          </Form.InputWrapper>
          {existingPayRules.length > 0 && (
            <PayConfigCalendar
              currency={selectedCurrency}
              payRules={existingPayRules}
              baseRateStr={baseRate}
            />
          )}

          <Form.InputWrapper
            label="Holiday rules"
            name="overrides"
            helptext="These are rules specific to particular days of each year that will override your base pay rate, for example you pay more on public holidays or at Christmas."
          >
            <Controller
              control={control}
              name={"overrides"}
              render={({ field: { onChange, value } }) => (
                <OverridesEditor
                  value={value}
                  onChange={onChange}
                  currency={selectedCurrency}
                  baseRateInCents={currencyStrToCents(baseRate)}
                />
              )}
            />
          </Form.InputWrapper>
        </Form.Root>
      </ModalContent>
      <ModalFooter
        saving={saving}
        formId="pay-config-form"
        confirmButtonText={continueButtonText}
        confirmButtonType="submit"
        onClose={onClose}
        requiredScope={requiredScope}
      />
    </>
  );
}
