/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogResource,
    CatalogResourceFromJSON,
    CatalogResourceFromJSONTyped,
    CatalogResourceToJSON,
} from './CatalogResource';

/**
 * 
 * @export
 * @interface CatalogListResourcesResponseBody
 */
export interface CatalogListResourcesResponseBody {
    /**
     * 
     * @type {Array<CatalogResource>}
     * @memberof CatalogListResourcesResponseBody
     */
    resources: Array<CatalogResource>;
}

export function CatalogListResourcesResponseBodyFromJSON(json: any): CatalogListResourcesResponseBody {
    return CatalogListResourcesResponseBodyFromJSONTyped(json, false);
}

export function CatalogListResourcesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListResourcesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(CatalogResourceFromJSON)),
    };
}

export function CatalogListResourcesResponseBodyToJSON(value?: CatalogListResourcesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(CatalogResourceToJSON)),
    };
}

