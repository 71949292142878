import { tcx } from "src/utils/tailwind-classes";

export const OnCallNotificationBox = ({
  children,
  description,
  title,
  className,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className="w-full bg-slate-50 rounded-2 border border-stroke flex-col inline-flex">
      <div className="w-full px-6 py-5 flex flex-col gap-2">
        <div className="text-content-primary text-base font-semibold">
          {title}
        </div>
        <div className="text-slate-600 text-sm font-normal">{description}</div>
      </div>
      <div
        className={tcx(
          "w-full px-6 pb-6 pt-2 rounded-b-lg flex-col flex",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
