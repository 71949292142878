/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageContentIncident,
    StatusPageContentIncidentFromJSON,
    StatusPageContentIncidentFromJSONTyped,
    StatusPageContentIncidentToJSON,
} from './StatusPageContentIncident';

/**
 * 
 * @export
 * @interface StatusPageContentListIncidentsResponseBody
 */
export interface StatusPageContentListIncidentsResponseBody {
    /**
     * 
     * @type {Array<StatusPageContentIncident>}
     * @memberof StatusPageContentListIncidentsResponseBody
     */
    incidents: Array<StatusPageContentIncident>;
}

export function StatusPageContentListIncidentsResponseBodyFromJSON(json: any): StatusPageContentListIncidentsResponseBody {
    return StatusPageContentListIncidentsResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentListIncidentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentListIncidentsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incidents': ((json['incidents'] as Array<any>).map(StatusPageContentIncidentFromJSON)),
    };
}

export function StatusPageContentListIncidentsResponseBodyToJSON(value?: StatusPageContentListIncidentsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incidents': ((value.incidents as Array<any>).map(StatusPageContentIncidentToJSON)),
    };
}

