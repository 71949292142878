/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DecisionTree,
    DecisionTreeFromJSON,
    DecisionTreeFromJSONTyped,
    DecisionTreeToJSON,
} from './DecisionTree';

/**
 * 
 * @export
 * @interface DecisionFlowsUpdateRequestBody
 */
export interface DecisionFlowsUpdateRequestBody {
    /**
     * A short description for this decision flow
     * @type {string}
     * @memberof DecisionFlowsUpdateRequestBody
     */
    description: string;
    /**
     * Whether this decision flow can be used in active incidents
     * @type {boolean}
     * @memberof DecisionFlowsUpdateRequestBody
     */
    is_live: boolean;
    /**
     * A human readable name for the decision flow (displayed at the top of the modal)
     * @type {string}
     * @memberof DecisionFlowsUpdateRequestBody
     */
    name: string;
    /**
     * 
     * @type {DecisionTree}
     * @memberof DecisionFlowsUpdateRequestBody
     */
    tree: DecisionTree;
}

export function DecisionFlowsUpdateRequestBodyFromJSON(json: any): DecisionFlowsUpdateRequestBody {
    return DecisionFlowsUpdateRequestBodyFromJSONTyped(json, false);
}

export function DecisionFlowsUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionFlowsUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'is_live': json['is_live'],
        'name': json['name'],
        'tree': DecisionTreeFromJSON(json['tree']),
    };
}

export function DecisionFlowsUpdateRequestBodyToJSON(value?: DecisionFlowsUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'is_live': value.is_live,
        'name': value.name,
        'tree': DecisionTreeToJSON(value.tree),
    };
}

