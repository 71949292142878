import { Incident, Stream } from "@incident-io/api";
import {
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import { StreamSummaryRow } from "../stacked-lists/StreamsStackedList";

export const OpenStreamsBlockResolvingModal = ({
  incident,
  openStreams,
  onClose,
  title = "Resolve incident",
  verb = "resolving",
}: {
  incident: Incident;
  openStreams: Stream[];
  onClose: () => void;
  title?: string;
  verb?: string;
}) => {
  return (
    <Modal
      isOpen
      title={title}
      onClose={onClose}
      analyticsTrackingId="resolve-incident-with-open-streams"
    >
      <ModalContent className="space-y-2">
        <OpenStreamsWarning
          openStreams={openStreams}
          incident={incident}
          verb={verb}
        />
      </ModalContent>
      <ModalFooter onClose={onClose} hideConfirmButton />
    </Modal>
  );
};

export const OpenStreamsWarning = ({
  incident,
  openStreams,
  verb = "resolving",
}: {
  incident: Incident;
  openStreams: Stream[];
  verb?: string;
}) => {
  return (
    <>
      <Callout theme={CalloutTheme.Warning}>
        <>
          This incident has open streams. Close them before {verb} the incident.
        </>
      </Callout>
      <div className="font-medium">Open streams</div>
      <div className="flex-col border border-stroke rounded-2 divide-y">
        {openStreams.map((stream) => (
          <StreamSummaryRow
            key={stream.id}
            stream={stream}
            parentIncident={incident}
            useFullNavigate
          />
        ))}
      </div>
    </>
  );
};
