import type { SVGProps } from "react";
import * as React from "react";
const SvgChevronDoubleDown = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.72 9.97 10 13.69 6.28 9.97a.75.75 0 1 0-1.06 1.06l4.25 4.25a.748.748 0 0 0 1.06 0l4.25-4.25a.75.75 0 1 0-1.061-1.06h.002Z"
    />
    <path
      fill="currentColor"
      d="M13.72 5.22 10 8.94 6.28 5.22a.75.75 0 1 0-1.06 1.06l4.25 4.25a.748.748 0 0 0 1.06 0l4.25-4.25a.75.75 0 1 0-1.061-1.06h.002Z"
    />
  </svg>
);
export default SvgChevronDoubleDown;
