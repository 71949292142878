/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnCallNotificationMethod,
    OnCallNotificationMethodFromJSON,
    OnCallNotificationMethodFromJSONTyped,
    OnCallNotificationMethodToJSON,
} from './OnCallNotificationMethod';

/**
 * 
 * @export
 * @interface OnCallNotificationsResetMethodVerificationResponseBody
 */
export interface OnCallNotificationsResetMethodVerificationResponseBody {
    /**
     * 
     * @type {OnCallNotificationMethod}
     * @memberof OnCallNotificationsResetMethodVerificationResponseBody
     */
    method: OnCallNotificationMethod;
}

export function OnCallNotificationsResetMethodVerificationResponseBodyFromJSON(json: any): OnCallNotificationsResetMethodVerificationResponseBody {
    return OnCallNotificationsResetMethodVerificationResponseBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsResetMethodVerificationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsResetMethodVerificationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': OnCallNotificationMethodFromJSON(json['method']),
    };
}

export function OnCallNotificationsResetMethodVerificationResponseBodyToJSON(value?: OnCallNotificationsResetMethodVerificationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': OnCallNotificationMethodToJSON(value.method),
    };
}

