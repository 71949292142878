/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttributePathItemPayload,
    CatalogTypeAttributePathItemPayloadFromJSON,
    CatalogTypeAttributePathItemPayloadFromJSONTyped,
    CatalogTypeAttributePathItemPayloadToJSON,
} from './CatalogTypeAttributePathItemPayload';
import {
    ParameterisedResourceArguments,
    ParameterisedResourceArgumentsFromJSON,
    ParameterisedResourceArgumentsFromJSONTyped,
    ParameterisedResourceArgumentsToJSON,
} from './ParameterisedResourceArguments';

/**
 * 
 * @export
 * @interface CatalogTypeAttributePayload
 */
export interface CatalogTypeAttributePayload {
    /**
     * Whether this attribute is an array
     * @type {boolean}
     * @memberof CatalogTypeAttributePayload
     */
    array: boolean;
    /**
     * The attribute to use (if this is a backlink)
     * @type {string}
     * @memberof CatalogTypeAttributePayload
     */
    backlink_attribute?: string;
    /**
     * The ID of this attribute
     * @type {string}
     * @memberof CatalogTypeAttributePayload
     */
    id?: string;
    /**
     * Metadata about this attribute
     * @type {{ [key: string]: string; }}
     * @memberof CatalogTypeAttributePayload
     */
    metadata?: { [key: string]: string; };
    /**
     * Controls how this attribute is modified
     * @type {string}
     * @memberof CatalogTypeAttributePayload
     */
    mode?: CatalogTypeAttributePayloadModeEnum;
    /**
     * Unique name of this attribute
     * @type {string}
     * @memberof CatalogTypeAttributePayload
     */
    name: string;
    /**
     * 
     * @type {ParameterisedResourceArguments}
     * @memberof CatalogTypeAttributePayload
     */
    parameterised_resource_arguments?: ParameterisedResourceArguments;
    /**
     * The path to use (if this is an path)
     * @type {Array<CatalogTypeAttributePathItemPayload>}
     * @memberof CatalogTypeAttributePayload
     */
    path?: Array<CatalogTypeAttributePathItemPayload>;
    /**
     * Catalog type name for this attribute
     * @type {string}
     * @memberof CatalogTypeAttributePayload
     */
    type: string;
    /**
     * Set to true if this attribute was previously synced from an external source
     * @type {boolean}
     * @memberof CatalogTypeAttributePayload
     */
    was_previously_synced?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CatalogTypeAttributePayloadModeEnum {
    Empty = '',
    Manual = 'manual',
    External = 'external',
    Internal = 'internal',
    Dynamic = 'dynamic',
    Backlink = 'backlink',
    Path = 'path'
}

export function CatalogTypeAttributePayloadFromJSON(json: any): CatalogTypeAttributePayload {
    return CatalogTypeAttributePayloadFromJSONTyped(json, false);
}

export function CatalogTypeAttributePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogTypeAttributePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array': json['array'],
        'backlink_attribute': !exists(json, 'backlink_attribute') ? undefined : json['backlink_attribute'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'name': json['name'],
        'parameterised_resource_arguments': !exists(json, 'parameterised_resource_arguments') ? undefined : ParameterisedResourceArgumentsFromJSON(json['parameterised_resource_arguments']),
        'path': !exists(json, 'path') ? undefined : ((json['path'] as Array<any>).map(CatalogTypeAttributePathItemPayloadFromJSON)),
        'type': json['type'],
        'was_previously_synced': !exists(json, 'was_previously_synced') ? undefined : json['was_previously_synced'],
    };
}

export function CatalogTypeAttributePayloadToJSON(value?: CatalogTypeAttributePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array': value.array,
        'backlink_attribute': value.backlink_attribute,
        'id': value.id,
        'metadata': value.metadata,
        'mode': value.mode,
        'name': value.name,
        'parameterised_resource_arguments': ParameterisedResourceArgumentsToJSON(value.parameterised_resource_arguments),
        'path': value.path === undefined ? undefined : ((value.path as Array<any>).map(CatalogTypeAttributePathItemPayloadToJSON)),
        'type': value.type,
        'was_previously_synced': value.was_previously_synced,
    };
}

