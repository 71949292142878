/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Trend
 */
export interface Trend {
    /**
     * The absolute change this trend represents
     * @type {number}
     * @memberof Trend
     */
    absolute_change: number;
    /**
     * The value of this trend in the current time period
     * @type {number}
     * @memberof Trend
     */
    current_value: number;
    /**
     * The percentage change this trend represents
     * @type {number}
     * @memberof Trend
     */
    percentage_change: number;
    /**
     * The value of this trend in the previous time period
     * @type {number}
     * @memberof Trend
     */
    previous_value: number;
    /**
     * The sentiment of this trend
     * @type {string}
     * @memberof Trend
     */
    sentiment: TrendSentimentEnum;
    /**
     * Subtitle of the trend
     * @type {string}
     * @memberof Trend
     */
    subtitle?: string;
    /**
     * Title of the trend
     * @type {string}
     * @memberof Trend
     */
    title: string;
    /**
     * ID of the trend type
     * @type {string}
     * @memberof Trend
     */
    trend_id: string;
    /**
     * The unit of this trend
     * @type {string}
     * @memberof Trend
     */
    unit: string;
}

/**
* @export
* @enum {string}
*/
export enum TrendSentimentEnum {
    Good = 'good',
    Neutral = 'neutral',
    Bad = 'bad'
}

export function TrendFromJSON(json: any): Trend {
    return TrendFromJSONTyped(json, false);
}

export function TrendFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trend {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'absolute_change': json['absolute_change'],
        'current_value': json['current_value'],
        'percentage_change': json['percentage_change'],
        'previous_value': json['previous_value'],
        'sentiment': json['sentiment'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'title': json['title'],
        'trend_id': json['trend_id'],
        'unit': json['unit'],
    };
}

export function TrendToJSON(value?: Trend | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'absolute_change': value.absolute_change,
        'current_value': value.current_value,
        'percentage_change': value.percentage_change,
        'previous_value': value.previous_value,
        'sentiment': value.sentiment,
        'subtitle': value.subtitle,
        'title': value.title,
        'trend_id': value.trend_id,
        'unit': value.unit,
    };
}

