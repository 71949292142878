/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SlackTeamConfigsGetBySlackTeamIDResponseBody,
    SlackTeamConfigsGetBySlackTeamIDResponseBodyFromJSON,
    SlackTeamConfigsGetBySlackTeamIDResponseBodyToJSON,
    SlackTeamConfigsListResponseBody,
    SlackTeamConfigsListResponseBodyFromJSON,
    SlackTeamConfigsListResponseBodyToJSON,
} from '../models';

export interface SlackTeamConfigsGetBySlackTeamIDRequest {
    slackTeamId: string;
}

/**
 * 
 */
export class SlackTeamConfigsApi extends runtime.BaseAPI {

    /**
     * Show slack team config by slack team ID
     * GetBySlackTeamID SlackTeamConfigs
     */
    async slackTeamConfigsGetBySlackTeamIDRaw(requestParameters: SlackTeamConfigsGetBySlackTeamIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SlackTeamConfigsGetBySlackTeamIDResponseBody>> {
        if (requestParameters.slackTeamId === null || requestParameters.slackTeamId === undefined) {
            throw new runtime.RequiredError('slackTeamId','Required parameter requestParameters.slackTeamId was null or undefined when calling slackTeamConfigsGetBySlackTeamID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/slack_team_configs/{slack_team_id}`.replace(`{${"slack_team_id"}}`, encodeURIComponent(String(requestParameters.slackTeamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SlackTeamConfigsGetBySlackTeamIDResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show slack team config by slack team ID
     * GetBySlackTeamID SlackTeamConfigs
     */
    async slackTeamConfigsGetBySlackTeamID(requestParameters: SlackTeamConfigsGetBySlackTeamIDRequest, initOverrides?: RequestInit): Promise<SlackTeamConfigsGetBySlackTeamIDResponseBody> {
        const response = await this.slackTeamConfigsGetBySlackTeamIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List slack team configs
     * List SlackTeamConfigs
     */
    async slackTeamConfigsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SlackTeamConfigsListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/slack_team_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SlackTeamConfigsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List slack team configs
     * List SlackTeamConfigs
     */
    async slackTeamConfigsList(initOverrides?: RequestInit): Promise<SlackTeamConfigsListResponseBody> {
        const response = await this.slackTeamConfigsListRaw(initOverrides);
        return await response.value();
    }

}
