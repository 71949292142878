import {
  EngineScope,
  ExpressionOperationOperationTypeEnum,
  ReferenceColorEnum,
} from "@incident-io/api";
import { MenuPathItem } from "@incident-shared/engine";
import {
  ExpressionsMethodProviderContextT,
  FormDataWithExpressions,
  useExpressionsMethods,
  validateExpressionsMethods,
} from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { ReferenceSource } from "../referenceSource";
import { TruncatingReferenceLabel } from "./TruncatingReferenceLabel";

export type EngineReferenceBadgeProps = {
  label: string;
  referenceSource?: ReferenceSource;
  className?: string;
  editable?: boolean;
  // Overrides can be used to force a certain icon or color palette, for
  // example when rendering query expression root references that are from
  // the catalog.
  iconOverride?: IconEnum;
  colorOverride?: ColorPaletteEnum | ReferenceColorEnum;
  mini?: boolean;
} & (
  | {
      reference: string;
      scope: EngineScope;
    }
  | { reference?: undefined; scope?: undefined }
);

export const EngineReferenceBadge = ({
  label: labelProp,
  referenceSource,
  className,
  editable = false,
  iconOverride,
  colorOverride,
  mini = false,
  reference,
  scope,
}: EngineReferenceBadgeProps): React.ReactElement => {
  const expressionMethods = useExpressionsMethods<
    FormDataWithExpressions,
    "expressions"
  >();

  const referenceLabelProps =
    referenceSource === "expression"
      ? getExpressionName({
          expressionMethods,
          reference,
          label: labelProp,
          scope: scope,
        })
      : { label: labelProp };

  const theme = ThemeForReferenceSource[referenceSource || "reference"];

  if (iconOverride) {
    theme.icon = iconOverride;
  }

  if (colorOverride) {
    const colorPalette = getColorPalette(colorOverride as ColorPaletteEnum);
    theme.iconClassName = colorPalette.icon;
    theme.separator = colorPalette.paleText;
    theme.editIcon = "group-hover:text-content-primary";
  }

  return (
    <Tooltip content={<div className="text-left text-xs">{labelProp}</div>}>
      <Badge
        className={tcx(
          "select-text w-fit max-w-full cursor-default",
          "flex items-center justify-between gap-2 group",
          { "cursor-pointer": editable },
          theme.background,
          theme.content,
          !editable ? "" : "flex",
          className,
        )}
        theme={BadgeTheme.Unstyled}
        size={mini ? BadgeSize.Small : BadgeSize.Medium}
      >
        <div
          className={tcx(
            "flex flex-row min-w-0 overflow-hidden text-sm font-medium items-center gap-1",
          )}
        >
          {referenceSource && (
            <Icon
              id={theme.icon}
              size={IconSize.Small}
              className={theme.iconClassName}
            />
          )}
          <TruncatingReferenceLabel
            {...referenceLabelProps}
            separatorClassName={theme.separator}
          />
        </div>
        {editable && (
          <Icon
            id={IconEnum.Edit}
            size={IconSize.Small}
            className={tcx("transition", theme.editIcon)}
          />
        )}
      </Badge>
    </Tooltip>
  );
};

export const getExpressionName = ({
  expressionMethods,
  reference,
  label,
  scope,
}: {
  reference: string | undefined;
  label: string;
  expressionMethods: ExpressionsMethodProviderContextT<
    FormDataWithExpressions,
    "expressions"
  >;
  scope?: EngineScope;
}): { path: MenuPathItem[] } | { label: string } => {
  if (!validateExpressionsMethods(expressionMethods)) {
    return { label };
  }

  const expression = expressionMethods.expressionsMethods?.fields.find(
    (expression) => reference?.includes(expression.reference),
  );

  // This can happen briefly between renders, just fallback to the label
  if (!expression) {
    return { label };
  }

  // Store a tuple of the operation label and its separator
  const operationLabels: MenuPathItem[] = [];

  for (const [index, operation] of expression.operations.entries()) {
    let label: React.ReactNode | undefined;
    switch (operation.operation_type) {
      case ExpressionOperationOperationTypeEnum.Navigate:
        label = operation.navigate?.reference_label;
        break;
      case ExpressionOperationOperationTypeEnum.Branches:
        // We explicitly return here if it's a branches operation,
        // as we don't have a minimal way of displaying these
        return { label: expression.label };
      case ExpressionOperationOperationTypeEnum.Max:
        label = "Max";
        break;
      case ExpressionOperationOperationTypeEnum.Sum:
        label = "Sum";
        break;
      case ExpressionOperationOperationTypeEnum.Min:
        label = "Min";
        break;
      case ExpressionOperationOperationTypeEnum.Count:
        label = "Count";
        break;
      case ExpressionOperationOperationTypeEnum.Filter:
        label = <Icon id={IconEnum.Filter} />;
        break;
      case ExpressionOperationOperationTypeEnum.First:
        label = "First";
        break;
      case ExpressionOperationOperationTypeEnum.Parse:
        label = <Icon id={IconEnum.CodeBlock} />;
        break;
      case ExpressionOperationOperationTypeEnum.Random:
        label = <Icon id={IconEnum.Dice} />;
        break;
    }

    if (label) {
      operationLabels.push({
        key: `${operation.operation_type}-${index}`,
        label,
      });
    }
  }

  const rootResource = scope?.references.find(
    (r) => r.key === expression.root_reference,
  );

  return {
    path: [rootResource, ...operationLabels].filter(Boolean) as MenuPathItem[],
  };
};

export const ThemeForReferenceSource: {
  [key in ReferenceSource]: {
    icon: IconEnum;
    background: string;
    content: string;
    separator: string;
    editIcon: string;
    iconClassName?: string;
  };
} = {
  expression: {
    icon: IconEnum.Expression,
    background: "bg-amber-surface",
    content: "text-amber-content",
    separator: "text-amber-400",
    editIcon: "group-hover:text-amber-900",
  },
  loop: {
    icon: IconEnum.Refresh1,
    background: "bg-purple-surface",
    content: "text-purple-content",
    separator: "text-purple-400",
    editIcon: "group-hover:text-purple-900",
  },
  reference: {
    icon: IconEnum.Bolt,
    background: "bg-surface-secondary",
    content: "text-content-primary",
    separator: "text-content-tertiary",
    editIcon: "group-hover:text-content-secondary",
  },
};
