import { ToastProps } from "@incident-ui/Toast/Toast";
import { Provider } from "@radix-ui/react-toast";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

type ToastContextT = {
  setToastProps: (props: ToastProps) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  toastProps: ToastProps;
};

const defaultValues = {
  setToastProps: () => {
    return;
  },
  setOpen: () => {
    return;
  },
  open: false,
  toastProps: {
    title: "",
  },
};
export const ToastContext = createContext<ToastContextT>(defaultValues);

export function ToastProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [toastProps, setToastProps] = useState<ToastProps>();
  const [open, setOpen] = useState(false);
  return (
    // @ts-expect-error I don't understand how to make contexts and types play ball.
    <ToastContext.Provider value={{ setOpen, setToastProps, open, toastProps }}>
      <Provider>{children}</Provider>
    </ToastContext.Provider>
  );
}

/**
 * A hook which allows you to easily display a Toast in a component after doing something.
 * It returns a function that you can call to show the Toast.
 */
export function useToast(): (props: ToastProps) => void {
  const { setOpen, setToastProps } = useContext(ToastContext);

  return (props: ToastProps) => {
    setToastProps(props);
    setOpen(true);
  };
}
