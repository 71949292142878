/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    EscalationPathLevelSlim,
    EscalationPathLevelSlimFromJSON,
    EscalationPathLevelSlimFromJSONTyped,
    EscalationPathLevelSlimToJSON,
} from './EscalationPathLevelSlim';
import {
    EscalationPathNode,
    EscalationPathNodeFromJSON,
    EscalationPathNodeFromJSONTyped,
    EscalationPathNodeToJSON,
} from './EscalationPathNode';
import {
    EscalationPathNodeLevel,
    EscalationPathNodeLevelFromJSON,
    EscalationPathNodeLevelFromJSONTyped,
    EscalationPathNodeLevelToJSON,
} from './EscalationPathNodeLevel';
import {
    EscalationTarget,
    EscalationTargetFromJSON,
    EscalationTargetFromJSONTyped,
    EscalationTargetToJSON,
} from './EscalationTarget';
import {
    EscalationTransition,
    EscalationTransitionFromJSON,
    EscalationTransitionFromJSONTyped,
    EscalationTransitionToJSON,
} from './EscalationTransition';
import {
    IncidentSlim,
    IncidentSlimFromJSON,
    IncidentSlimFromJSONTyped,
    IncidentSlimToJSON,
} from './IncidentSlim';

/**
 * 
 * @export
 * @interface Escalation
 */
export interface Escalation {
    /**
     * 
     * @type {EscalationPathNodeLevel}
     * @memberof Escalation
     */
    active_level_config?: EscalationPathNodeLevel;
    /**
     * Unique identifier of the alert that the escalation is connected to
     * @type {string}
     * @memberof Escalation
     */
    alert_id?: string;
    /**
     * Unique identifier of the alert route that the escalation is connected to
     * @type {string}
     * @memberof Escalation
     */
    alert_route_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Escalation
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof Escalation
     */
    creator: Actor;
    /**
     * More extensive message that is provided with this escalation, default to incident summary
     * @type {string}
     * @memberof Escalation
     */
    description: string;
    /**
     * Unique identifier of the escalation path that the escalation was created from
     * @type {string}
     * @memberof Escalation
     */
    escalation_path_id?: string;
    /**
     * The delay between an escalation being created and notifications being sent
     * @type {number}
     * @memberof Escalation
     */
    grace_period_seconds: number;
    /**
     * Unique internal ID of the escalation
     * @type {string}
     * @memberof Escalation
     */
    id: string;
    /**
     * Unique key that prevents creating duplicate escalations
     * @type {string}
     * @memberof Escalation
     */
    idempotency_key: string;
    /**
     * 
     * @type {IncidentSlim}
     * @memberof Escalation
     */
    incident?: IncidentSlim;
    /**
     * Unique identifier of the incident the escalation is connected to
     * @type {string}
     * @memberof Escalation
     */
    incident_id?: string;
    /**
     * The path that this escalation is following
     * @type {Array<EscalationPathNode>}
     * @memberof Escalation
     */
    path: Array<EscalationPathNode>;
    /**
     * The levels of escalation for the associated path, including predicted future levels based on current conditions
     * @type {Array<EscalationPathLevelSlim>}
     * @memberof Escalation
     */
    predicted_path_levels: Array<EscalationPathLevelSlim>;
    /**
     * The priority of this escalation
     * @type {string}
     * @memberof Escalation
     */
    priority_id: string;
    /**
     * Name of escalation provider, e.g. splunk_on_call
     * @type {string}
     * @memberof Escalation
     */
    provider: EscalationProviderEnum;
    /**
     * ID of escalation in external system
     * @type {string}
     * @memberof Escalation
     */
    provider_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Escalation
     */
    source_type: EscalationSourceTypeEnum;
    /**
     * Status of the escalation as it was last polled
     * @type {string}
     * @memberof Escalation
     */
    status: EscalationStatusEnum;
    /**
     * 
     * @type {Array<EscalationTarget>}
     * @memberof Escalation
     */
    targets?: Array<EscalationTarget>;
    /**
     * Which ticker version did this escalation execute with
     * @type {string}
     * @memberof Escalation
     */
    ticker_version: string;
    /**
     * Human readable title of this escalation, defaulting to incident name
     * @type {string}
     * @memberof Escalation
     */
    title: string;
    /**
     * 
     * @type {Array<EscalationTransition>}
     * @memberof Escalation
     */
    transitions?: Array<EscalationTransition>;
    /**
     * 
     * @type {Date}
     * @memberof Escalation
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EscalationProviderEnum {
    Native = 'native',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty',
    SplunkOnCall = 'splunk_on_call'
}/**
* @export
* @enum {string}
*/
export enum EscalationSourceTypeEnum {
    Manual = 'manual',
    AlertRoute = 'alert_route',
    LiveCall = 'live_call',
    Workflow = 'workflow'
}/**
* @export
* @enum {string}
*/
export enum EscalationStatusEnum {
    Pending = 'pending',
    Triggered = 'triggered',
    Acked = 'acked',
    Resolved = 'resolved',
    Expired = 'expired',
    Cancelled = 'cancelled'
}

export function EscalationFromJSON(json: any): Escalation {
    return EscalationFromJSONTyped(json, false);
}

export function EscalationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Escalation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active_level_config': !exists(json, 'active_level_config') ? undefined : EscalationPathNodeLevelFromJSON(json['active_level_config']),
        'alert_id': !exists(json, 'alert_id') ? undefined : json['alert_id'],
        'alert_route_id': !exists(json, 'alert_route_id') ? undefined : json['alert_route_id'],
        'created_at': (new Date(json['created_at'])),
        'creator': ActorFromJSON(json['creator']),
        'description': json['description'],
        'escalation_path_id': !exists(json, 'escalation_path_id') ? undefined : json['escalation_path_id'],
        'grace_period_seconds': json['grace_period_seconds'],
        'id': json['id'],
        'idempotency_key': json['idempotency_key'],
        'incident': !exists(json, 'incident') ? undefined : IncidentSlimFromJSON(json['incident']),
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'path': ((json['path'] as Array<any>).map(EscalationPathNodeFromJSON)),
        'predicted_path_levels': ((json['predicted_path_levels'] as Array<any>).map(EscalationPathLevelSlimFromJSON)),
        'priority_id': json['priority_id'],
        'provider': json['provider'],
        'provider_id': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'source_type': json['source_type'],
        'status': json['status'],
        'targets': !exists(json, 'targets') ? undefined : ((json['targets'] as Array<any>).map(EscalationTargetFromJSON)),
        'ticker_version': json['ticker_version'],
        'title': json['title'],
        'transitions': !exists(json, 'transitions') ? undefined : ((json['transitions'] as Array<any>).map(EscalationTransitionFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EscalationToJSON(value?: Escalation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_level_config': EscalationPathNodeLevelToJSON(value.active_level_config),
        'alert_id': value.alert_id,
        'alert_route_id': value.alert_route_id,
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'description': value.description,
        'escalation_path_id': value.escalation_path_id,
        'grace_period_seconds': value.grace_period_seconds,
        'id': value.id,
        'idempotency_key': value.idempotency_key,
        'incident': IncidentSlimToJSON(value.incident),
        'incident_id': value.incident_id,
        'path': ((value.path as Array<any>).map(EscalationPathNodeToJSON)),
        'predicted_path_levels': ((value.predicted_path_levels as Array<any>).map(EscalationPathLevelSlimToJSON)),
        'priority_id': value.priority_id,
        'provider': value.provider,
        'provider_id': value.provider_id,
        'source_type': value.source_type,
        'status': value.status,
        'targets': value.targets === undefined ? undefined : ((value.targets as Array<any>).map(EscalationTargetToJSON)),
        'ticker_version': value.ticker_version,
        'title': value.title,
        'transitions': value.transitions === undefined ? undefined : ((value.transitions as Array<any>).map(EscalationTransitionToJSON)),
        'updated_at': (value.updated_at.toISOString()),
    };
}

