/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionOperation,
    ConditionOperationFromJSON,
    ConditionOperationFromJSONTyped,
    ConditionOperationToJSON,
} from './ConditionOperation';
import {
    ConditionSubject,
    ConditionSubjectFromJSON,
    ConditionSubjectFromJSONTyped,
    ConditionSubjectToJSON,
} from './ConditionSubject';
import {
    EngineParam,
    EngineParamFromJSON,
    EngineParamFromJSONTyped,
    EngineParamToJSON,
} from './EngineParam';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {ConditionOperation}
     * @memberof Condition
     */
    operation: ConditionOperation;
    /**
     * Bindings for the operation parameters
     * @type {Array<EngineParamBinding>}
     * @memberof Condition
     */
    param_bindings: Array<EngineParamBinding>;
    /**
     * Type information for the operation parameters
     * @type {Array<EngineParam>}
     * @memberof Condition
     */
    params: Array<EngineParam>;
    /**
     * 
     * @type {ConditionSubject}
     * @memberof Condition
     */
    subject: ConditionSubject;
}

export function ConditionFromJSON(json: any): Condition {
    return ConditionFromJSONTyped(json, false);
}

export function ConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Condition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operation': ConditionOperationFromJSON(json['operation']),
        'param_bindings': ((json['param_bindings'] as Array<any>).map(EngineParamBindingFromJSON)),
        'params': ((json['params'] as Array<any>).map(EngineParamFromJSON)),
        'subject': ConditionSubjectFromJSON(json['subject']),
    };
}

export function ConditionToJSON(value?: Condition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operation': ConditionOperationToJSON(value.operation),
        'param_bindings': ((value.param_bindings as Array<any>).map(EngineParamBindingToJSON)),
        'params': ((value.params as Array<any>).map(EngineParamToJSON)),
        'subject': ConditionSubjectToJSON(value.subject),
    };
}

