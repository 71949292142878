import type { SVGProps } from "react";
import * as React from "react";
const SvgSend = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.123 2.654a1.283 1.283 0 0 0-1.305-.315L2.895 6.493a1.274 1.274 0 0 0-.887 1.087c-.056.512.187.992.632 1.25l4.576 2.67 3.953-3.954a.75.75 0 1 1 1.06 1.06l-3.953 3.955 2.669 4.576a1.272 1.272 0 0 0 1.253.63 1.273 1.273 0 0 0 1.086-.885L17.439 3.96a1.285 1.285 0 0 0-.316-1.306Z"
    />
  </svg>
);
export default SvgSend;
