/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalUser,
    ExternalUserFromJSON,
    ExternalUserFromJSONTyped,
    ExternalUserToJSON,
} from './ExternalUser';

/**
 * 
 * @export
 * @interface UserLinksListConnectableUsersResponseBody
 */
export interface UserLinksListConnectableUsersResponseBody {
    /**
     * 
     * @type {Array<ExternalUser>}
     * @memberof UserLinksListConnectableUsersResponseBody
     */
    connectable_users: Array<ExternalUser>;
}

export function UserLinksListConnectableUsersResponseBodyFromJSON(json: any): UserLinksListConnectableUsersResponseBody {
    return UserLinksListConnectableUsersResponseBodyFromJSONTyped(json, false);
}

export function UserLinksListConnectableUsersResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLinksListConnectableUsersResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectable_users': ((json['connectable_users'] as Array<any>).map(ExternalUserFromJSON)),
    };
}

export function UserLinksListConnectableUsersResponseBodyToJSON(value?: UserLinksListConnectableUsersResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectable_users': ((value.connectable_users as Array<any>).map(ExternalUserToJSON)),
    };
}

