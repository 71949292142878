import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { isSelectOption, SelectOption } from "@incident-ui/Select/types";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  DynamicMultiSelectWithObjV2,
  DynamicSingleSelectWithObjV2,
} from "src/components/@shared/forms/v2/inputs/DynamicSelectWithObjV2";
import { StaticMultiSelectWithObjV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectWithObjV2";
import {
  EngineScope,
  Resource,
  ResourceFieldConfigArrayTypeEnum as ArrayFormFieldType,
} from "src/contexts/ClientContext";
import { EnrichedScope } from "src/utils/scope";
import { assertUnreachable, sendToSentry } from "src/utils/utils";

import {
  BoundParamTypeaheadFn,
  isParamBindingOptionGroup,
  ParamBindingOption,
  ParamBindingOptionOrGroup,
} from "../helpers";
import { EngineMultiInput } from "./EngineMultiInput";
import { InputOrVariable } from "./InputOrVariable";
import { selectOptionsToParamBindingOptions } from "./SingleValueEngineFormElement";

// We're bundling these together on the props as typescript struggles with
// derived props being passed down to children, and passing them as a single
// object makes it happy. These two props stay separate on the outer
// EngineFormElement, so we haven't changed the "public" component's API at all.
export type ScopeAndIsAlert =
  | { isAlertElement: true; scope: EnrichedScope<ReferenceWithExample> }
  | { isAlertElement?: false; scope: EngineScope };

export type ParamFormElementProps<FormType> = {
  name: Path<FormType>;
  label: string;
  labelNode?: React.ReactNode;
  labelAccessory?: React.ReactNode;
  helptext?: string;
  placeholder: string;
  resources: Resource[];
  resource: Resource;
  required: boolean;
  loadTypeaheadOptions: BoundParamTypeaheadFn;
  disabled?: boolean;
  disabledTooltipContent?: React.ReactNode;
  suffixNode?: React.ReactNode;
  headerNode?: React.ReactNode;
  includeExpressions: boolean;
  includeVariables: boolean;
  includeStatic: boolean;
  className?: string;
  onEditExpression?: (expression: ExpressionFormData) => void;
  onDeleteExpression?: (expression: ExpressionFormData) => void;
  onClickVariableButton?: () => void;
  expressionLabelOverride?: string;
  optionIconOverride?: IconEnum;
  scopeAndIsAlert: ScopeAndIsAlert;
};

export const MultiValueEngineFormElement = <FormType extends FieldValues>({
  name,
  formFieldType,
  label,
  labelNode,
  labelAccessory,
  helptext,
  placeholder,
  scopeAndIsAlert,
  resources,
  resource,
  required,
  loadTypeaheadOptions,
  disabled,
  disabledTooltipContent,
  includeVariables,
  includeExpressions,
  includeStatic,
  className,
  suffixNode,
  onDeleteExpression,
  onEditExpression,
  onClickVariableButton,
  expressionLabelOverride,
  optionIconOverride,
}: ParamFormElementProps<FormType> & {
  formFieldType: ArrayFormFieldType;
}): React.ReactElement | null => {
  return (
    <Form.InputWrapper
      label={labelNode || label}
      labelAccessory={labelAccessory}
      helptext={helptext}
      name={name}
      className={className}
      suffixNode={suffixNode}
      required={required}
      disabled={disabled}
      disabledTooltipContent={disabledTooltipContent}
    >
      <MultiValueElement
        formFieldType={formFieldType}
        name={name}
        label={label}
        placeholder={placeholder}
        loadTypeaheadOptions={loadTypeaheadOptions}
        disabled={disabled}
        scopeAndIsAlert={scopeAndIsAlert}
        resources={resources}
        required={required}
        includeExpressions={includeExpressions}
        includeVariables={includeVariables}
        includeStatic={includeStatic}
        resource={resource}
        onDeleteExpression={onDeleteExpression}
        onEditExpression={onEditExpression}
        onClickVariableButton={onClickVariableButton}
        expressionLabelOverride={expressionLabelOverride}
        optionIconOverride={optionIconOverride}
      />
    </Form.InputWrapper>
  );
};

const MultiValueElement = <FormType extends FieldValues>({
  name,
  label,
  formFieldType,
  placeholder,
  scopeAndIsAlert,
  resources,
  resource,
  required,
  loadTypeaheadOptions,
  disabled = false,
  includeVariables,
  includeExpressions,
  includeStatic,
  onDeleteExpression,
  onEditExpression,
  onClickVariableButton,
  expressionLabelOverride,
  optionIconOverride,
}: ParamFormElementProps<FormType> & {
  formFieldType: ArrayFormFieldType;
}): React.ReactElement | null => {
  const formMethods = useFormContext<FormType>();
  const sharedProps = {
    placeholder,
    disabled,
    formMethods,
    className: "grow",
  };

  switch (formFieldType) {
    case ArrayFormFieldType.MultiTextInput:
      return (
        <EngineMultiInput
          {...sharedProps}
          name={name}
          label={label}
          scopeAndIsAlert={scopeAndIsAlert}
          resources={resources}
          resource={resource}
          includeExpressions={includeExpressions}
          includeVariables={includeVariables}
          renderLiteralInput={({ name, renderLightningButton }) => (
            <InputV2
              name={`${name}.literal` as Path<FormType>}
              onValueChange={(val) => {
                formMethods.setValue(
                  `${name}.label` as Path<FormType>,
                  val as PathValue<FormType, Path<FormType>>,
                );
              }}
              placeholder={placeholder}
              disabled={disabled}
              formMethods={formMethods}
              className="grow"
              insetSuffixNode={renderLightningButton()}
            />
          )}
        />
      );
    case ArrayFormFieldType.MultiTextInputWithAutocomplete:
      return (
        <EngineMultiInput
          {...sharedProps}
          name={name}
          label={label}
          scopeAndIsAlert={scopeAndIsAlert}
          resources={resources}
          resource={resource}
          includeExpressions={includeExpressions}
          includeVariables={includeVariables}
          renderLiteralInput={({ name, renderLightningButton }) => (
            <DynamicSingleSelectWithObjV2
              name={name as Path<FormType>}
              placeholder={placeholder}
              disabled={disabled}
              formMethods={formMethods}
              className="grow"
              insetSuffixNode={renderLightningButton()}
              loadOptions={async (inputValue: string) =>
                selectOptionsToParamBindingOptions(
                  resource,
                  await loadTypeaheadOptions(inputValue),
                )
              }
            />
          )}
        />
      );
    case ArrayFormFieldType.MultiStaticSelect:
      return (
        <InputOrVariable
          name={name}
          label={label}
          resource={resource}
          required={required}
          disabled={disabled}
          includeExpressions={includeExpressions}
          includeVariables={includeVariables}
          includeStatic={includeStatic}
          expressionLabelOverride={expressionLabelOverride}
          array={true}
          onDeleteExpression={onDeleteExpression}
          onEditExpression={onEditExpression}
          onClickVariableButton={onClickVariableButton}
          scopeAndIsAlert={scopeAndIsAlert}
          renderChildren={(renderLightningButton) => (
            <StaticMultiSelectWithObjV2
              name={name}
              {...sharedProps}
              optionColor={
                resource.field_config.color as unknown as ColorPaletteEnum
              }
              optionIcon={optionIconOverride || resource.field_config.icon}
              options={selectOptionsToParamBindingOptions(resource)}
              // Our top level wrapper will show the errors
              hideErrors
              insetSuffixNode={renderLightningButton()}
            />
          )}
        />
      );
    case ArrayFormFieldType.MultiDynamicSelect: {
      const loadOptions = async (inputValue: string) => {
        const opts = await loadTypeaheadOptions(inputValue);
        return selectOptionsToParamBindingOptions(resource, opts);
      };

      return (
        <InputOrVariable
          name={name}
          label={label}
          resource={resource}
          required={required}
          disabled={disabled}
          includeExpressions={includeExpressions}
          includeVariables={includeVariables}
          includeStatic={includeStatic}
          onClickVariableButton={onClickVariableButton}
          expressionLabelOverride={expressionLabelOverride}
          array={true}
          scopeAndIsAlert={scopeAndIsAlert}
          renderChildren={(renderLightningButton) => (
            <DynamicMultiSelectWithObjV2
              name={name}
              {...sharedProps}
              optionColor={
                resource.field_config.color as unknown as ColorPaletteEnum
              }
              optionIcon={optionIconOverride || resource.field_config.icon}
              loadOptions={loadOptions}
              hydrateValue={paramBindingOptionsToSelectOptions}
              // Our top level wrapper will show the errors
              hideErrors
              insetSuffixNode={renderLightningButton()}
            />
          )}
        />
      );
    }
    case ArrayFormFieldType.None:
      sendToSentry("unreachable: cannot render form field type none.", {
        resource,
      });
      return null;
    default:
      assertUnreachable(formFieldType);
      return null;
  }
};

export const paramBindingOptionsToSelectOptions = (
  values: ParamBindingOptionOrGroup[],
): SelectOption[] => {
  const toSelectOption = (value: ParamBindingOption) => ({
    ...value,
    value: value.literal || value.value,
  });

  return values.flatMap((value: ParamBindingOptionOrGroup) => {
    if (isParamBindingOption(value)) {
      return toSelectOption(value);
    }
    if (isParamBindingOptionGroup(value)) {
      return value.options.map(toSelectOption);
    }

    return [];
  });
};

const isParamBindingOption = (
  option: ParamBindingOptionOrGroup,
): option is ParamBindingOption => {
  return "literal" in option || isSelectOption(option);
};
