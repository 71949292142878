/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageMigration,
    StatusPageMigrationFromJSON,
    StatusPageMigrationFromJSONTyped,
    StatusPageMigrationToJSON,
} from './StatusPageMigration';

/**
 * 
 * @export
 * @interface StatusPageShowMigrationResponseBody
 */
export interface StatusPageShowMigrationResponseBody {
    /**
     * 
     * @type {StatusPageMigration}
     * @memberof StatusPageShowMigrationResponseBody
     */
    migration: StatusPageMigration;
}

export function StatusPageShowMigrationResponseBodyFromJSON(json: any): StatusPageShowMigrationResponseBody {
    return StatusPageShowMigrationResponseBodyFromJSONTyped(json, false);
}

export function StatusPageShowMigrationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageShowMigrationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'migration': StatusPageMigrationFromJSON(json['migration']),
    };
}

export function StatusPageShowMigrationResponseBodyToJSON(value?: StatusPageShowMigrationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'migration': StatusPageMigrationToJSON(value.migration),
    };
}

