import { differenceInDays } from "date-fns";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Identity } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

interface Survicate {
  setVisitorTraits(traits: {
    email: string;
    first_name: string;
    // custom attributes - you can send whatever you like
    user_id: string;
    organisation_id: string;
    organisation_name: string;
  }): void;

  invokeEvent(eventName: string, props?: Record<string, unknown>): void;
}

declare global {
  interface Window {
    _sva?: Survicate;
  }
}

const SurvicateContext = createContext<{
  sva: Survicate | null;
  _setNpsPopupIsEnabled: (enabled: boolean) => void;
}>({ sva: null, _setNpsPopupIsEnabled: () => null });

export const SurvicateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [sva, setSva] = useState<Survicate | null>(window._sva ?? null);
  const [npsPopupIsEnabled, setNpsPopupIsEnabled] = useState(true);

  if (sva == null) {
    window.addEventListener("SurvicateReady", () => {
      if (window._sva) {
        setSva(window._sva);
      }
    });
  }

  // Tell Survicate who the current user is.
  useSetSurvicateVisitorTraits(sva);

  // Trigger the NPS survey if the user is eligible
  const { pathname } = useLocation();
  useTriggerNps(sva, npsPopupIsEnabled, pathname);

  return (
    <SurvicateContext.Provider
      value={{
        sva,
        _setNpsPopupIsEnabled: setNpsPopupIsEnabled,
      }}
    >
      {children}
    </SurvicateContext.Provider>
  );
};

export const useSurvicate = () => useContext(SurvicateContext);

const useSetSurvicateVisitorTraits = (sva: Survicate | null) => {
  const { identity, isImpersonating } = useIdentity();

  useEffect(() => {
    if (isImpersonating) return;
    if (!identity) return;
    if (!sva) return;

    const traits = {
      organisation_id: identity.organisation_id,
      organisation_name: identity.organisation_name,
      user_id: identity.user_id,
      email: identity.user_email,
      first_name: identity.user_name,
    };

    sva.setVisitorTraits(traits);
  }, [sva, identity, isImpersonating]);
};

const NPS_DISPLAY_DELAY = 5000; // 5s
const useTriggerNps = (
  sva: Survicate | null,
  npsPopupIsEnabled: boolean,
  pathname: string,
) => {
  const { identity } = useIdentity();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  if (!sva) return;

  // This clears any pending event trigger
  const cancelTimeout = () => {
    if (timeoutRef.current == null) {
      return;
    }

    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  };

  // This clears any pending trigger and then starts a fresh 5s countdown
  const trigger = () => {
    cancelTimeout();

    timeoutRef.current = setTimeout(() => {
      sva.invokeEvent("npsEligible", { pathname });
    }, NPS_DISPLAY_DELAY);
  };

  if (!npsPopupIsEnabled || !identity || !shouldShowNps(identity)) {
    // If any of the conditions _stop_ matching, cancel any pending trigger
    cancelTimeout();
    return;
  }

  // Start a fresh timeout
  trigger();
};

const shouldShowNps = (identity: Identity) => {
  let res = true;

  // If the org hasn't even had an incident yet, don't ask.
  if (!identity.organisation_has_reported_first_incident) {
    res = false;
  }

  // Never ask in demo/sandboxes
  if (identity.organisation_is_demo || identity.organisation_is_sandbox) {
    res = false;
  }

  // Only ask if the user has been a user of our dashboard for at least 2 weeks.
  if (differenceInDays(new Date(), identity.first_login_at) < 14) {
    res = false;
  }

  return res;
};

export const useDisableNPS = () => {
  const { _setNpsPopupIsEnabled } = useContext(SurvicateContext);

  useEffect(() => {
    // Disable NPS from this point onward
    _setNpsPopupIsEnabled(false);

    // Re-enable the NPS popup when this component unmounts
    return () => _setNpsPopupIsEnabled(true);
  }, [_setNpsPopupIsEnabled]);
};
