/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackBookmarkOption,
    SlackBookmarkOptionFromJSON,
    SlackBookmarkOptionFromJSONTyped,
    SlackBookmarkOptionToJSON,
} from './SlackBookmarkOption';

/**
 * 
 * @export
 * @interface IncidentChannelConfigsListBookmarkOptionsResponseBody
 */
export interface IncidentChannelConfigsListBookmarkOptionsResponseBody {
    /**
     * 
     * @type {Array<SlackBookmarkOption>}
     * @memberof IncidentChannelConfigsListBookmarkOptionsResponseBody
     */
    bookmark_options: Array<SlackBookmarkOption>;
}

export function IncidentChannelConfigsListBookmarkOptionsResponseBodyFromJSON(json: any): IncidentChannelConfigsListBookmarkOptionsResponseBody {
    return IncidentChannelConfigsListBookmarkOptionsResponseBodyFromJSONTyped(json, false);
}

export function IncidentChannelConfigsListBookmarkOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelConfigsListBookmarkOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookmark_options': ((json['bookmark_options'] as Array<any>).map(SlackBookmarkOptionFromJSON)),
    };
}

export function IncidentChannelConfigsListBookmarkOptionsResponseBodyToJSON(value?: IncidentChannelConfigsListBookmarkOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookmark_options': ((value.bookmark_options as Array<any>).map(SlackBookmarkOptionToJSON)),
    };
}

