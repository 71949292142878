/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleReportShift
 */
export interface ScheduleReportShift {
    /**
     * Timestamp of when this shift ends
     * @type {Date}
     * @memberof ScheduleReportShift
     */
    end_at: Date;
    /**
     * ID of user, as stored against the report
     * @type {string}
     * @memberof ScheduleReportShift
     */
    external_user_id: string;
    /**
     * Unique identifier for the override, if one applies
     * @type {string}
     * @memberof ScheduleReportShift
     */
    override_id?: string;
    /**
     * Unique identifier for the pay rule within schedule
     * @type {string}
     * @memberof ScheduleReportShift
     */
    pay_rule_short_id?: string;
    /**
     * Timestamp of when this shift starts
     * @type {Date}
     * @memberof ScheduleReportShift
     */
    start_at: Date;
    /**
     * Total value in cents owed to this user for working this shift
     * @type {number}
     * @memberof ScheduleReportShift
     */
    value_cents: number;
}

export function ScheduleReportShiftFromJSON(json: any): ScheduleReportShift {
    return ScheduleReportShiftFromJSONTyped(json, false);
}

export function ScheduleReportShiftFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReportShift {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': (new Date(json['end_at'])),
        'external_user_id': json['external_user_id'],
        'override_id': !exists(json, 'override_id') ? undefined : json['override_id'],
        'pay_rule_short_id': !exists(json, 'pay_rule_short_id') ? undefined : json['pay_rule_short_id'],
        'start_at': (new Date(json['start_at'])),
        'value_cents': json['value_cents'],
    };
}

export function ScheduleReportShiftToJSON(value?: ScheduleReportShift | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': (value.end_at.toISOString()),
        'external_user_id': value.external_user_id,
        'override_id': value.override_id,
        'pay_rule_short_id': value.pay_rule_short_id,
        'start_at': (value.start_at.toISOString()),
        'value_cents': value.value_cents,
    };
}

