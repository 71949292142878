import { Toggle, ToggleProps } from "@incident-ui/Toggle/Toggle";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { tcx } from "src/utils/tailwind-classes";

export type GatedToggleProps = {
  tooltipContent?: React.ReactElement;
  spanClassName?: string;
} & ToggleProps;

/**
 * A special wrapped toggle that displays a tooltip when disabled.
 * Pass in `tooltipContent` to tell it what to show in the tooltip.
 * If there's no tooltip content, it's just a normal toggle.
 */
export function GatedToggle({
  tooltipContent,
  disabled,
  spanClassName,
  ...props
}: GatedToggleProps): React.ReactElement {
  if (tooltipContent && disabled) {
    return (
      <Tooltip
        analyticsTrackingId={null}
        content={tooltipContent}
        delayDuration={0}
      >
        <span
          className={tcx("cursor-not-allowed flex items-center", spanClassName)}
        >
          <Toggle
            {...props}
            toggleClassName={tcx("pointer-events-none", props.toggleClassName)}
            disabled={true}
          />
        </span>
      </Tooltip>
    );
  }

  return <Toggle {...props} disabled={disabled} />;
}
