/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentLifecycle,
    IncidentLifecycleFromJSON,
    IncidentLifecycleFromJSONTyped,
    IncidentLifecycleToJSON,
} from './IncidentLifecycle';

/**
 * 
 * @export
 * @interface IncidentLifecyclesUpdatePostIncidentFlowResponseBody
 */
export interface IncidentLifecyclesUpdatePostIncidentFlowResponseBody {
    /**
     * 
     * @type {IncidentLifecycle}
     * @memberof IncidentLifecyclesUpdatePostIncidentFlowResponseBody
     */
    incident_lifecycle: IncidentLifecycle;
}

export function IncidentLifecyclesUpdatePostIncidentFlowResponseBodyFromJSON(json: any): IncidentLifecyclesUpdatePostIncidentFlowResponseBody {
    return IncidentLifecyclesUpdatePostIncidentFlowResponseBodyFromJSONTyped(json, false);
}

export function IncidentLifecyclesUpdatePostIncidentFlowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecyclesUpdatePostIncidentFlowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_lifecycle': IncidentLifecycleFromJSON(json['incident_lifecycle']),
    };
}

export function IncidentLifecyclesUpdatePostIncidentFlowResponseBodyToJSON(value?: IncidentLifecyclesUpdatePostIncidentFlowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_lifecycle': IncidentLifecycleToJSON(value.incident_lifecycle),
    };
}

