import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import {
  DynamicMultiSelectV2,
  DynamicSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { PopoverMultiSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Input } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { SelectOption, SelectOptionOrGroup } from "@incident-ui/Select/types";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "src/components/@shared/catalog/CatalogTypeDynamicSelect";
import {
  AvailableFilter,
  ExtendedFormFieldValue,
} from "src/components/@shared/filters";
import { Form } from "src/components/@shared/forms";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "src/components/@shared/forms/Typeahead";
import { DateRangePicker } from "src/components/@shared/forms/v1/DateRangePicker/DateRangePicker";
import {
  deserializeDateRange,
  serializeDateRange,
} from "src/components/@shared/forms/v1/DateRangePicker/marshall";
import {
  DateRangePickerMode,
  QuickSelectInterval,
} from "src/components/@shared/forms/v1/DateRangePicker/types";
import {
  getCustomFieldTypeaheadOptions,
  hydrateInitialCustomFieldOptions,
} from "src/components/@shared/forms/v2/CustomFieldDynamicSelect";
import { useIsInInsightsContext } from "src/components/insights/useIsInInsightsContext";
import {
  ClientType,
  FormFieldOperator as Operator,
  FormFieldOperatorFieldTypeEnum as FormFieldType,
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export const FilterFormElement = ({
  formField,
  operator,
  className,
  overrideLabel,
}: {
  formField: AvailableFilter;
  operator: Operator;
  id?: string;
  className?: string;
  overrideLabel?: React.ReactNode;
}): React.ReactElement | null => {
  const apiClient = useClient();
  const formMethods = useFormContext<ExtendedFormFieldValue>();
  const { register, control, watch } = formMethods;

  const forInsights = useIsInInsightsContext();

  const label =
    overrideLabel || operator.select_config?.placeholder || "Enter a value";
  switch (operator.field_type) {
    case FormFieldType.TextInput:
      return (
        <Form.InputWrapper
          className={className}
          label={label}
          name="string_value"
        >
          <Input
            id="string_value"
            type={InputType.Text}
            {...register("string_value")}
          />
        </Form.InputWrapper>
      );
    case FormFieldType.NumberInput:
      return (
        <Form.InputWrapper
          className={className}
          label={label}
          name="string_value"
        >
          <Input
            id="string_value"
            type={InputType.Number}
            {...register("string_value")}
          />
        </Form.InputWrapper>
      );
    case FormFieldType.SingleStaticSelect:
      if (!operator.select_config) {
        throw new Error("filter field config invalid");
      }
      return (
        <StaticSingleSelectV2<ExtendedFormFieldValue>
          formMethods={formMethods}
          className={className}
          label={label}
          name="single_option_value"
          key={formField.key}
          options={[
            ...(operator.select_config.options || []),
            ...(operator.select_config.option_groups || []),
          ]}
          placeholder={operator.select_config.placeholder}
        />
      );
    case FormFieldType.MultiStaticSelect:
      if (!operator.select_config) {
        throw new Error("filter field config invalid");
      }

      return (
        <PopoverMultiSelectV2<ExtendedFormFieldValue>
          formMethods={formMethods}
          className={className}
          label={label}
          fullWidth
          name="multiple_options_value"
          options={[
            ...(operator.select_config.options || []),
            ...(operator.select_config.option_groups || []),
          ]}
          placeholder={operator.select_config.placeholder}
        />
      );

    case FormFieldType.SingleExternalSelect: {
      if (!operator.select_config) {
        throw new Error("filter field config invalid");
      }
      const { loadOptions, hydrateOptions } = getFilterFieldTypeaheadHelpers({
        apiClient,
        typeaheadType: operator.select_config
          .typeahead_type as unknown as TypeaheadTypeEnum,
        typeaheadLookupId: formField.typeahead_lookup_id,
        forInsights,
      });
      return (
        <DynamicSingleSelectV2
          formMethods={formMethods}
          className={className}
          label={label}
          name="single_option_value"
          loadOptions={loadOptions}
          hydrateOptions={hydrateOptions}
          placeholder={operator.select_config.placeholder}
        />
      );
    }
    case FormFieldType.MultiExternalSelect:
    case FormFieldType.MultiExternalUserSelect: {
      if (!operator.select_config) {
        throw new Error("filter field config invalid");
      }
      const { loadOptions, hydrateOptions } = getFilterFieldTypeaheadHelpers({
        apiClient,
        typeaheadType: operator.select_config
          .typeahead_type as unknown as TypeaheadTypeEnum,
        typeaheadLookupId: formField.typeahead_lookup_id,
        forInsights,
      });

      return (
        <DynamicMultiSelectV2
          formMethods={formMethods}
          className={className}
          label={label}
          name="multiple_options_value"
          placeholder={operator.select_config.placeholder}
          loadOptions={loadOptions}
          hydrateOptions={hydrateOptions}
        />
      );
    }
    case FormFieldType.BooleanInput:
      return (
        <BooleanRadioButtonGroupV2
          srLabel="Choose an option"
          className={className}
          formMethods={formMethods}
          name="bool_value"
          boxed
          horizontal
          trueFirst
          trueOption={{
            label: "Yes",
          }}
          falseOption={{
            label: "No",
          }}
        />
      );
    case FormFieldType.DateInput:
      return (
        <Form.InputWrapper
          className={className}
          label={label}
          name="string_value"
        >
          <Input
            type={InputType.Date}
            id="string_value"
            {...register("string_value")}
          />
        </Form.InputWrapper>
      );
    case FormFieldType.DateRangeInput: {
      const value = watch("string_value");
      const dateRangeState: QuickSelectType = {
        mode: DateRangePickerMode.QuickSelect,
        quick_select: QuickSelectInterval.LastWeek,
      };
      const state = value ? deserializeDateRange(value) : dateRangeState;
      return (
        <Form.InputWrapper className={className} name="string_value">
          <Controller
            control={control}
            name={"string_value"}
            defaultValue={serializeDateRange(state)}
            render={({ field: { onChange } }) => (
              <DateRangePicker
                state={state}
                setState={(s) => {
                  onChange(serializeDateRange(s));
                }}
                allowInvalid
              />
            )}
          />
        </Form.InputWrapper>
      );
    }
    case FormFieldType.None:
      return null;
    default:
      throw new Error(`unsupported FormFieldType ${operator.field_type}`);
  }
};

type QuickSelectType = {
  mode: DateRangePickerMode.QuickSelect;
  quick_select: QuickSelectInterval;
};

export const getFilterFieldTypeaheadHelpers = ({
  apiClient,
  typeaheadType,
  typeaheadLookupId,
  forInsights,
}: {
  apiClient: ClientType;
  typeaheadType: TypeaheadsListTypeaheadTypeEnum;
  typeaheadLookupId?: string | null;
  forInsights: boolean;
}): {
  loadOptions: (inputValue: string) => Promise<SelectOptionOrGroup[]>;
  hydrateOptions: (initialValue: string | string[]) => Promise<SelectOption[]>;
} => {
  // I'd like to replace this with a better abstraction around dependent typeaheads (e.g.
  // a typeahead which has parameters like the custom field ID, or the incident ID etc.) but
  // for now, this will work ok.
  if (
    [
      TypeaheadsListTypeaheadTypeEnum.CustomField,
      TypeaheadsListTypeaheadTypeEnum.CustomFieldInsights,
    ].includes(typeaheadType)
  ) {
    if (!typeaheadLookupId) {
      throw new Error(
        "typeaheadLookupId is required for custom field typeahead",
      );
    }

    const loadOptions = getCustomFieldTypeaheadOptions({
      apiClient,
      customFieldID: typeaheadLookupId,
      includeDynamicOptions: false,
      includeNoValue: false,
      // We want to show all options for the custom field, so act as if no other custom fields
      // are set.
      entryPayloads: [],
      viaInsightsAPI:
        typeaheadType === TypeaheadsListTypeaheadTypeEnum.CustomFieldInsights,
    });

    const hydrateOptions = hydrateInitialCustomFieldOptions({
      apiClient,
      customFieldID: typeaheadLookupId,
      viaInsightsAPI:
        typeaheadType === TypeaheadsListTypeaheadTypeEnum.CustomFieldInsights,
    });

    return { loadOptions, hydrateOptions };
  }

  if (typeaheadType === TypeaheadsListTypeaheadTypeEnum.CatalogEntry) {
    if (!typeaheadLookupId) {
      throw new Error("typeaheadLookupId is required for catalog typeahead");
    }
    const loadOptions = getCatalogTypeaheadOptions({
      apiClient,
      catalogTypeID: typeaheadLookupId,
      forInsights: forInsights,
    });

    const hydrateOptions = hydrateInitialCatalogOptions({
      apiClient,
      catalogTypeID: typeaheadLookupId,
      forInsights: forInsights,
    });

    return { loadOptions, hydrateOptions };
  }

  // User filters are funky, in that we want to show an extra 'current_user' option
  if (typeaheadType === TypeaheadsListTypeaheadTypeEnum.User) {
    return getFilterFieldTypeaheadHelpersForUser({ apiClient, forInsights });
  }

  const loadOptions = getTypeaheadOptions(
    apiClient,
    typeaheadType as unknown as TypeaheadTypeEnum,
    { forInsights },
  );

  const hydrateOptions = hydrateInitialSelectOptions(
    apiClient,
    typeaheadType as unknown as TypeaheadTypeEnum,
    forInsights,
  );

  return { loadOptions, hydrateOptions };
};

const CURRENT_USER_OPTION: SelectOption = {
  label: "Current user",
  // This must match domain.FormFieldCurrentUserMagicID
  value: "current_user",
};

// User filters are funky, in that we want to show an extra 'current_user'
// option. We apply this here rather than in the typeaheads backend because this
// option should only show up _in filters_, and not in other places we use
// typeaheads (e.g. assigning roles/actions).
const getFilterFieldTypeaheadHelpersForUser = ({
  apiClient,
  forInsights,
}: {
  apiClient: ClientType;
  forInsights: boolean;
}) => {
  const loadOptions = async (inputValue: string) => {
    const realOptions = await getTypeaheadOptions(
      apiClient,
      TypeaheadTypeEnum.User,
      { forInsights },
    )(inputValue);

    if ("current user self me".includes(inputValue.toLowerCase())) {
      return [CURRENT_USER_OPTION, ...realOptions];
    }

    return realOptions;
  };

  const hydrateOptions = async (idsOrId: string | string[]) => {
    const ids: string[] = typeof idsOrId === "string" ? [idsOrId] : idsOrId;
    const idsToFetch = ids.filter(
      (id) => id !== CURRENT_USER_OPTION.value && id !== "",
    );

    const realOptions =
      idsToFetch.length > 0
        ? await hydrateInitialSelectOptions(
            apiClient,
            TypeaheadTypeEnum.User,
            forInsights,
          )(idsToFetch)
        : [];

    if (ids.includes(CURRENT_USER_OPTION.value)) {
      return [CURRENT_USER_OPTION, ...realOptions];
    }

    return realOptions;
  };

  return { loadOptions, hydrateOptions };
};

// This replaces the magic 'current_user' filter value with the actual current
// user ID. It's only necessary when passing filters to an Insights dashboard.
export const useAppliedCurrentUserFilters = (
  filters: ExtendedFormFieldValue[],
): ExtendedFormFieldValue[] => {
  const { identity } = useIdentity();

  return (
    filters
      // 'disabled' filters are ones we don't want to pass to Explo (as our queries
      // don't support them), so we filter them out here. We don't _apply_ them,
      // but we show them in the UI to indicate to users that something is
      // included/excluded from the query.
      .filter((f) => !f.is_disabled)
      .map((filter) => ({
        ...filter,
        multiple_options_value: filter.multiple_options_value?.map((value) =>
          value === CURRENT_USER_OPTION.value ? identity.user_id : value,
        ),
      }))
  );
};
