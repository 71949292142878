import { Link } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceCrowdstrikeFalconSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p className="content-primary">
        Go to th§e{" "}
        <Link
          href="https://marketplace.crowdstrike.com/listings/webhook"
          analyticsTrackingId="crowdstrike-marketplace-clicked"
        >
          Crowdstrike Falcon marketplace
        </Link>{" "}
        , and create a new webhook integration
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then copy and paste in the <strong>Name</strong> and
            <strong>Webhook URL</strong> below
          </>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/crowdstrike_falcon/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <p>Create a new Fusion SOAR workflow in the Crowdstrike Falcon console</p>
      <p>Set the trigger to be an alert.</p>
      <p>
        From that alert, call the webhook you created in the marketplace, set
        the payload to be <strong>Custom JSON</strong> and include the following
        fields:
        <div className={"my-4 flex flex-col gap-4"}>
          <CodeBlock
            title={"Payload"}
            code={`{
    "alert_id": "$\{Alert ID}",
    "alert_description": "$\{Description}",
    "alert_name": "$\{Name}",
    "alert_product": "$\{Product}",
    "alert_severity": "$\{Severity}",
    "alert_status": "$\{Status}",
    "source_event_id": "$\{Source event ID}",
    "source_event_url": "$\{Source event URL}",
    "workflow_execution_id": "$\{Workflow execution ID}",
    "workflow_name": "$\{Workflow name}",
    "workflow_description": "$\{Workflow description}",
    "workflow_execution_timestamp": "$\{Workflow execution timestamp}"
}`}
          />
          <CodeBlock
            title={"HMAC Secret Key"}
            // We don't actually validate this on the backend for crowdstrike, but people
            // have gotten confused in the past, so give them a token like our other sources.
            code={alertSourceConfig.secret_token ?? ""}
          />
        </div>
      </p>
      <p className="content-primary">
        After this, any alerts from Crowdstrike Falcon will be sent to
        incident.io
      </p>
    </SetupInfoNumberedList>
  );
};
