/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertAttributePayload,
    AlertAttributePayloadFromJSON,
    AlertAttributePayloadFromJSONTyped,
    AlertAttributePayloadToJSON,
} from './AlertAttributePayload';

/**
 * 
 * @export
 * @interface AlertSchemaPayload
 */
export interface AlertSchemaPayload {
    /**
     * Attributes of the alert schema
     * @type {Array<AlertAttributePayload>}
     * @memberof AlertSchemaPayload
     */
    attributes: Array<AlertAttributePayload>;
    /**
     * The current version of the alert schema
     * @type {number}
     * @memberof AlertSchemaPayload
     */
    version: number;
}

export function AlertSchemaPayloadFromJSON(json: any): AlertSchemaPayload {
    return AlertSchemaPayloadFromJSONTyped(json, false);
}

export function AlertSchemaPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSchemaPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AlertAttributePayloadFromJSON)),
        'version': json['version'],
    };
}

export function AlertSchemaPayloadToJSON(value?: AlertSchemaPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(AlertAttributePayloadToJSON)),
        'version': value.version,
    };
}

