import { GenericErrorMessage, Loader } from "@incident-ui";
import { captureException } from "@sentry/react";
import React from "react";
import {
  DecisionFlowsCreateRequestBody as CreateReqBody,
  DecisionFlowsShowResponseBody,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { EditDecisionFlowController, Modes } from "./Controller";
import { NoDecisionFlowSelected, NoDecisionFlowsExist } from "./EmptyStates";

const emptyDecisionFlow: DecisionFlowsShowResponseBody = {
  decision_flow: {
    id: "",
    created_at: new Date(),
    updated_at: new Date(),
    is_live: false,
    name: "",
    description: "",
    organisation_id: "",
    tree: { root: { id: 1, name: "", prompt: "" }, nodes: [] },
  },
  dependent_resources: [],
};

// Here, we work out what is happening (have they selected a flow, are we in create or edit mode, etc)
// and render either an appropriate empty state, or the EditDecisionTree component.
export const SettingsContent = ({
  mode,
  isDirty,
  setIsDirty,
  selectedFlowID,
  createRequest,
  onClickCreate,
  anyExistingDecisionFlows,
  onClear,
  onCreated,
}: {
  mode: Modes | null;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFlowID: string | null;
  createRequest: CreateReqBody | null;
  onClickCreate: () => void;
  anyExistingDecisionFlows: boolean;
  onClear: () => void;
  onCreated: (flowId: string) => void;
}): React.ReactElement => {
  const {
    data: currentFlow,
    error,
    isLoading: loading,
  } = useAPI(
    selectedFlowID == null ? null : "decisionFlowsShow",
    { id: selectedFlowID ?? "" },
    { fallbackData: emptyDecisionFlow },
  );

  if (error) {
    console.error(error);
    captureException(error);
    return <GenericErrorMessage error={error} />;
  }

  if (mode == null) {
    if (anyExistingDecisionFlows) {
      return <NoDecisionFlowSelected />;
    }
    return <NoDecisionFlowsExist onClickCreate={onClickCreate} />;
  }

  if (createRequest) {
    return (
      <EditDecisionFlowController<Modes.Create>
        decisionFlow={createRequest}
        decisionFlowId={null}
        dependentResources={[]}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        onCreated={onCreated}
      />
    );
  }

  if (loading || !currentFlow || !currentFlow.decision_flow) {
    return <Loader />;
  }

  return (
    <EditDecisionFlowController<Modes.Edit>
      isDirty={isDirty}
      setIsDirty={setIsDirty}
      decisionFlow={currentFlow.decision_flow}
      dependentResources={currentFlow.dependent_resources}
      decisionFlowId={currentFlow.decision_flow.id}
      onClear={onClear}
    />
  );
};
