/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RBACRole,
    RBACRoleFromJSON,
    RBACRoleFromJSONTyped,
    RBACRoleToJSON,
} from './RBACRole';

/**
 * 
 * @export
 * @interface RBACRolesListResponseBody
 */
export interface RBACRolesListResponseBody {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RBACRolesListResponseBody
     */
    rbac_base_role_counts: { [key: string]: number; };
    /**
     * 
     * @type {Array<RBACRole>}
     * @memberof RBACRolesListResponseBody
     */
    rbac_roles: Array<RBACRole>;
}

export function RBACRolesListResponseBodyFromJSON(json: any): RBACRolesListResponseBody {
    return RBACRolesListResponseBodyFromJSONTyped(json, false);
}

export function RBACRolesListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RBACRolesListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rbac_base_role_counts': json['rbac_base_role_counts'],
        'rbac_roles': ((json['rbac_roles'] as Array<any>).map(RBACRoleFromJSON)),
    };
}

export function RBACRolesListResponseBodyToJSON(value?: RBACRolesListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rbac_base_role_counts': value.rbac_base_role_counts,
        'rbac_roles': ((value.rbac_roles as Array<any>).map(RBACRoleToJSON)),
    };
}

