import { ConfigureDrawerProps } from "@incident-shared/integrations";
import { Callout, CalloutTheme } from "@incident-ui";
import React from "react";
import { useAPI } from "src/utils/swr";

import { IntegrationConnectedUser } from "../../common/IntegrationConnectedUser";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const LinearConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { data: configData, isLoading: configLoading } = useAPI(
    "integrationsLinearGetConfig",
    undefined,
  );

  const user = configData?.config?.connecting_user;

  if (configLoading) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      {user ? (
        <IntegrationConnectedUser
          {...user}
          provider={props.integration.provider}
        />
      ) : (
        <Callout theme={CalloutTheme.Warning}>
          <p className="font-semibold">
            Unable to fetch connected Linear user.
          </p>
        </Callout>
      )}
    </GenericConfigureDrawerContents>
  );
};
