import { AlertRoute, AlertSourceConfig } from "@incident-io/api";
import _ from "lodash";
import { Node } from "reactflow";

import {
  AlertsConfigurationNodeData,
  AlertsConfigurationNodeType,
} from "../AlertsConfigurationNode";
import { groupSourcesAndRoutes } from "./groupSourcesAndRoutes";

export const drawNodes = ({
  alertSources,
  alertRoutes,
  containerWidth,
  sourceHeaderData,
  routeHeaderData,
  hasNoAlertRoutes,
  hasComplexSetup,
}: {
  alertSources: AlertSourceConfig[];
  alertRoutes: AlertRoute[];
  containerWidth: number;
  sourceHeaderData: AlertsConfigurationNodeData;
  routeHeaderData: AlertsConfigurationNodeData;
  hasNoAlertRoutes: boolean;
  hasComplexSetup: boolean;
}) => {
  const NODE_WIDTH = containerWidth / 3;
  const NODE_HEIGHT = 72;
  const PADDING = 24;
  const SPACING = 12;
  const GROUP_SPACING = 24;

  const nodes: Node[] = [];

  // Add the sources header
  nodes.push({
    id: "sources-header",
    type: "alertsConfigurationNode",
    data: sourceHeaderData,
    position: { x: PADDING, y: PADDING },
    style: {
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
    },
  });

  // Add the routes header
  nodes.push({
    id: "routes-header",
    type: "alertsConfigurationNode",
    data: routeHeaderData,
    position: { x: NODE_WIDTH * 2 - PADDING, y: PADDING },
    style: {
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
    },
  });

  const { groupedAlertSources, groupedAlertRoutes } = groupSourcesAndRoutes(
    alertSources,
    alertRoutes,
  );

  // Align the sources on the left hand side of the screen
  let sourceY = PADDING + NODE_HEIGHT;

  for (const group of groupedAlertSources) {
    for (const source of group) {
      nodes.push({
        id: `${source.id}`,
        type: "alertsConfigurationNode",
        data: {
          label: source.name,
          nodeType: AlertsConfigurationNodeType.AlertSource,
          alertSource: source,
          allAlertRoutes: alertRoutes,
          allAlertSources: alertSources,
        },
        position: { x: PADDING, y: sourceY },
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        },
      });
      let additionalHeight = NODE_HEIGHT + SPACING;

      // If we have over one reference, then we should offset by our number of references
      // on the other side.
      const filteredReferenceCount = _.intersection(
        source.alert_route_ids,
        alertRoutes.map((route) => route.id),
      ).length;
      if (!hasComplexSetup && filteredReferenceCount > 1) {
        additionalHeight +=
          NODE_HEIGHT * (filteredReferenceCount - 1) +
          SPACING * (filteredReferenceCount - 1);
      }

      sourceY += additionalHeight;
    }

    sourceY += GROUP_SPACING;
  }

  // Align the routes on the right hand side of the screen
  let routeY = PADDING + NODE_HEIGHT;

  for (const group of groupedAlertRoutes) {
    for (const route of group) {
      nodes.push({
        id: `${route.id}`,
        type: "alertsConfigurationNode",
        data: {
          label: route.name,
          nodeType: AlertsConfigurationNodeType.AlertRoute,
          alertRoute: route,
        },
        position: { x: NODE_WIDTH * 2 - PADDING, y: routeY },
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        },
      });
      let additionalHeight = NODE_HEIGHT + SPACING;

      // If we have over one reference, then we should offset by our number of references
      // on the other side.
      const filteredReferenceCount = _.intersection(
        route.alert_sources.map((source) => source.alert_source_id),
        alertSources.map((source) => source.id),
      ).length;
      if (!hasComplexSetup && filteredReferenceCount > 1) {
        additionalHeight +=
          NODE_HEIGHT * (filteredReferenceCount - 1) +
          SPACING * (filteredReferenceCount - 1);
      }

      routeY += additionalHeight;
    }

    routeY += GROUP_SPACING;
  }

  if (hasNoAlertRoutes) {
    // Add an empty state node
    nodes.push({
      id: `no-alert-routes`,
      type: "alertsConfigurationNode",
      data: {
        label: "No alert routes",
        nodeType: AlertsConfigurationNodeType.NoAlertRoutes,
      },
      position: { x: NODE_WIDTH * 2 - PADDING, y: routeY },
      style: {
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
      },
    });
  }

  return { nodes, height: Math.max(sourceY, routeY, window.innerHeight) };
};
