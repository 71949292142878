/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserReferencePayload,
    UserReferencePayloadFromJSON,
    UserReferencePayloadFromJSONTyped,
    UserReferencePayloadToJSON,
} from './UserReferencePayload';

/**
 * 
 * @export
 * @interface IncidentRoleAssignmentPayload
 */
export interface IncidentRoleAssignmentPayload {
    /**
     * 
     * @type {UserReferencePayload}
     * @memberof IncidentRoleAssignmentPayload
     */
    assignee?: UserReferencePayload;
    /**
     * Unique ID of an incident role
     * @type {string}
     * @memberof IncidentRoleAssignmentPayload
     */
    incident_role_id: string;
}

export function IncidentRoleAssignmentPayloadFromJSON(json: any): IncidentRoleAssignmentPayload {
    return IncidentRoleAssignmentPayloadFromJSONTyped(json, false);
}

export function IncidentRoleAssignmentPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRoleAssignmentPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': !exists(json, 'assignee') ? undefined : UserReferencePayloadFromJSON(json['assignee']),
        'incident_role_id': json['incident_role_id'],
    };
}

export function IncidentRoleAssignmentPayloadToJSON(value?: IncidentRoleAssignmentPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': UserReferencePayloadToJSON(value.assignee),
        'incident_role_id': value.incident_role_id,
    };
}

