/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BulkIncidentUpdatePayload,
    BulkIncidentUpdatePayloadFromJSON,
    BulkIncidentUpdatePayloadFromJSONTyped,
    BulkIncidentUpdatePayloadToJSON,
} from './BulkIncidentUpdatePayload';

/**
 * 
 * @export
 * @interface IncidentsBulkCreateUpdateRequestBody
 */
export interface IncidentsBulkCreateUpdateRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentsBulkCreateUpdateRequestBody
     */
    incident_ids: Array<string>;
    /**
     * 
     * @type {BulkIncidentUpdatePayload}
     * @memberof IncidentsBulkCreateUpdateRequestBody
     */
    incident_update: BulkIncidentUpdatePayload;
}

export function IncidentsBulkCreateUpdateRequestBodyFromJSON(json: any): IncidentsBulkCreateUpdateRequestBody {
    return IncidentsBulkCreateUpdateRequestBodyFromJSONTyped(json, false);
}

export function IncidentsBulkCreateUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsBulkCreateUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_ids': json['incident_ids'],
        'incident_update': BulkIncidentUpdatePayloadFromJSON(json['incident_update']),
    };
}

export function IncidentsBulkCreateUpdateRequestBodyToJSON(value?: IncidentsBulkCreateUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_ids': value.incident_ids,
        'incident_update': BulkIncidentUpdatePayloadToJSON(value.incident_update),
    };
}

