import { DateTime } from "luxon";

export const formatAsMinutes = (seconds: number) =>
  DateTime.fromSeconds(seconds, { zone: "utc" }).toFormat("m");

const formatAsHours = (seconds: number) =>
  DateTime.fromSeconds(seconds, { zone: "utc" }).toFormat("h");

export const shiftChangeNotificationText = (seconds: number) => {
  if (seconds === 0) {
    return "Immediately before";
  }
  if (seconds === 3600) {
    return "1 hour before";
  }
  if (seconds === 86400) {
    return "24 hours before";
  }
  if (seconds < 3600) {
    return `${formatAsMinutes(seconds)} minutes before`;
  }
  return `${formatAsHours(seconds)} hours before`;
};

export const escalationNotificationText = (seconds: number) => {
  if (seconds === 0) {
    return "Immediately";
  }
  if (seconds < 3600) {
    return `After ${formatAsMinutes(seconds)} minute${
      seconds === 60 ? "" : "s"
    }`;
  }
  return `After ${formatAsHours(seconds)} hour${seconds === 3600 ? "" : "s"}`;
};
