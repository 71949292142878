import type { SVGProps } from "react";
import * as React from "react";
const SvgRippling = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <rect width={16} height={16} x={2} y={2} fill="#FEB71E" rx={3} />
    <path
      fill="#4F2F3F"
      d="M4 5.5c.94.687 1.443 1.505 1.443 2.495 0 .99-.502 1.808-1.442 2.496.849.377 1.319 1.064 1.319 2.045V14.5h1.978v-1.964c0-1.03-.403-1.75-1.113-2.045a3.132 3.132 0 0 0 0-4.991H4ZM8.288 5.5c.94.687 1.443 1.505 1.443 2.495 0 .99-.502 1.808-1.443 2.496.85.377 1.32 1.064 1.32 2.045V14.5h1.98v-1.964c0-1.03-.405-1.75-1.114-2.045a3.133 3.133 0 0 0 0-4.991H8.288ZM12.578 5.5c.94.687 1.443 1.505 1.443 2.495 0 .99-.503 1.808-1.443 2.496.85.377 1.319 1.064 1.319 2.045V14.5h1.98v-1.964c0-1.03-.405-1.75-1.114-2.045a3.132 3.132 0 0 0 0-4.991h-2.185Z"
    />
  </svg>
);
export default SvgRippling;
