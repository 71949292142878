import { AlertSourceSourceTypeEnum } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import { tcx } from "src/utils/tailwind-classes";

// These are the steps for the alert source creation wizard.
export enum AlertSourceStepEnum {
  Choose = "choose",
  Connect = "connect",
  Configure = "configure",
}

// Provide the three-step wizard progress bar that is used for alert source
// creation.
export const AlertSourceCreateWizardSteps = ({
  className,
  step,
  sourceType: _sourceType,
  title,
  description,
}: {
  className?: string;
  step: AlertSourceStepEnum;
  sourceType?: AlertSourceSourceTypeEnum;
  title: string;
  description: string;
}) => {
  const currentStep = stepConfig.find((sc) => sc.id === step);
  if (!currentStep) {
    return null;
  }

  return (
    <div
      className={tcx(
        "flex flex-col py-10 gap-10 w-full max-w-[640px] mx-auto px-2",
        className,
      )}
    >
      <div className={"max-w-[640px]"}>
        <NakedSteps
          steps={stepConfig}
          currentStepID={step}
          color={
            ColorPaletteEnum.Slate // TODO: Color by source
          }
        />
      </div>
      <AlertSourceTitle title={title} description={description} />
    </div>
  );
};

// Provides the title and subheading we place below the wizard steps for the
// create pages.
const AlertSourceTitle = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className={"flex flex-col text-sm items-center h-full mx-auto w-full"}>
      <div className="text-content-primary text-center mb-2 text-2xl leading-8 font-bold">
        {title}
      </div>
      <div className="text-content-secondary text-sm leading-5 w-3/4 text-center text-balance">
        {description}
      </div>
    </div>
  );
};

export type AlertSourceStep = {
  id: AlertSourceStepEnum;
  name: string;
  label: string;
  submitType: "submit" | "button";
};

export const stepConfig: AlertSourceStep[] = [
  {
    id: AlertSourceStepEnum.Choose,
    name: "Select",
    label: "Select your source",
    submitType: "submit",
  },
  {
    id: AlertSourceStepEnum.Connect,
    name: "Connect",
    label: "Connect to incident.io",
    submitType: "button",
  },
  {
    id: AlertSourceStepEnum.Configure,
    name: "Configure",
    label: "Configure your setup",
    submitType: "submit",
  },
];
