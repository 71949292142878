/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    IncidentRoleWithoutConditions,
    IncidentRoleWithoutConditionsFromJSON,
    IncidentRoleWithoutConditionsFromJSONTyped,
    IncidentRoleWithoutConditionsToJSON,
} from './IncidentRoleWithoutConditions';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface RoleUpdate
 */
export interface RoleUpdate {
    /**
     * 
     * @type {User}
     * @memberof RoleUpdate
     */
    from_user?: User;
    /**
     * 
     * @type {IncidentRoleWithoutConditions}
     * @memberof RoleUpdate
     */
    role: IncidentRoleWithoutConditions;
    /**
     * 
     * @type {User}
     * @memberof RoleUpdate
     */
    to_user?: User;
    /**
     * 
     * @type {Actor}
     * @memberof RoleUpdate
     */
    updater: Actor;
}

export function RoleUpdateFromJSON(json: any): RoleUpdate {
    return RoleUpdateFromJSONTyped(json, false);
}

export function RoleUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from_user': !exists(json, 'from_user') ? undefined : UserFromJSON(json['from_user']),
        'role': IncidentRoleWithoutConditionsFromJSON(json['role']),
        'to_user': !exists(json, 'to_user') ? undefined : UserFromJSON(json['to_user']),
        'updater': ActorFromJSON(json['updater']),
    };
}

export function RoleUpdateToJSON(value?: RoleUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_user': UserToJSON(value.from_user),
        'role': IncidentRoleWithoutConditionsToJSON(value.role),
        'to_user': UserToJSON(value.to_user),
        'updater': ActorToJSON(value.updater),
    };
}

