import { TrialStatusPlanNameEnum as PlanNameEnum } from "src/contexts/ClientContext";

// formatLoginWithRedirectURL returns a URL that can be used to login the user
// and redirect them to a return path after. It is also possible to specify the
// loginURL.
export function formatLoginWithRedirectURL({
  returnPath,
  returnHost,
  teamId,
  organisationSlug,
  plan,
  isSigningUp = false,
  loginURL = "/auth/slack_login",
}: {
  returnPath?: string;
  returnHost?: string;
  teamId?: string;
  organisationSlug?: string;
  plan?: PlanNameEnum;
  loginURL?: string;
  isSigningUp?: boolean;
}): string {
  const searchParams = new URLSearchParams();

  if (teamId != null) {
    searchParams.append("team_id", teamId);
  }
  if (organisationSlug != null) {
    searchParams.append("organisation_slug", organisationSlug);
  }
  if (returnPath != null) {
    searchParams.append("return_path", returnPath);
  }
  if (returnHost != null) {
    searchParams.append("return_host", returnHost);
  }
  if (plan) {
    searchParams.append("plan_name", plan);
  }
  if (searchParams.toString() === "") {
    return loginURL;
  }

  // We use this flag to gate Microsoft sign ups, so that we can differentiate people who
  // came to log in via our (secret) setup page from those who are just clicking log in
  // on the homepage (who we would like to deny, for now).
  if (isSigningUp) {
    searchParams.append("is_signing_up", "true");
  }

  return `${loginURL}?${searchParams.toString()}`;
}
