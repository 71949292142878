import type { SVGProps } from "react";
import * as React from "react";
const SvgJira = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#2684FF"
      d="m17.476 9.558-6.895-6.895L9.918 2 4.747 7.171 2.382 9.536a.655.655 0 0 0 0 .906l4.73 4.73L9.917 18l5.171-5.171.089-.089 2.298-2.298a.627.627 0 0 0 0-.884Zm-7.558 2.807L7.553 10l2.365-2.365L12.283 10l-2.365 2.365Z"
    />
    <path
      fill="url(#jira_svg__a)"
      d="M9.917 7.635a3.996 3.996 0 0 1-.022-5.613l-5.17 5.171L7.53 10l2.386-2.365Z"
    />
    <path
      fill="url(#jira_svg__b)"
      d="m12.282 10-2.365 2.365c1.547 1.546 1.547 4.066 0 5.635l5.194-5.193L12.282 10Z"
    />
    <defs>
      <linearGradient
        id="jira_svg__a"
        x1={9.496}
        x2={6.193}
        y1={5.24}
        y2={8.542}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.176} stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="jira_svg__b"
        x1={10.37}
        x2={13.666}
        y1={14.726}
        y2={11.431}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.176} stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgJira;
