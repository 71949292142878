import { RefObject } from "react";
import { TemplatedTextDisplay } from "src/components/@shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { TextNode } from "src/contexts/ClientContext";

export const PostMortemHTMLTemplateForClipboard = ({
  postMortemDocRef,
  renderedDoc,
  copyAsMarkdown,
}: {
  postMortemDocRef: RefObject<HTMLDivElement>;
  renderedDoc: TextNode | string;
  copyAsMarkdown: boolean;
}): React.ReactElement | null => {
  //  We hide this in here so it doesn't alter the UI, but is there when
  //  we push it to the clipboard
  return (
    <div
      aria-hidden="true"
      ref={postMortemDocRef}
      style={{
        background: "#FFF",
        height: "1px",
        overflow: "hidden",
        width: "1px",
        textAlign: "start",
      }}
    >
      {/* Provide another div that expands its size, as the way the browser
                 compresses the layout will be preserved in the copy */}
      <div
        style={{
          minWidth: "640px",
        }}
      >
        {copyAsMarkdown ? (
          <div className="whitespace-pre">{renderedDoc}</div>
        ) : (
          <TemplatedTextDisplay value={renderedDoc} />
        )}
      </div>
    </div>
  );
};
