import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useFormContext } from "react-hook-form";
import { PostIncidentFlowCreateTaskConfigRequestBodyTaskTypeEnum as TaskType } from "src/contexts/ClientContext";

import { FormDataType } from "./FormDataType";

export const taskTypesThatSupportCustomTitles = [
  TaskType.Custom,
  TaskType.SetCustomFields,
  TaskType.SetTimestamps,
];

export const TaskTitleInput = () => {
  const formMethods = useFormContext<FormDataType>();
  const selectedTaskType = formMethods.watch("task_type");

  // We only support custom titles for the following task types.
  if (!taskTypesThatSupportCustomTitles.includes(selectedTaskType)) {
    return null;
  }
  return (
    <InputV2
      formMethods={formMethods}
      label="Title"
      name="title"
      placeholder={
        {
          [TaskType.Custom]: "E.g. Share a public post-mortem",
          [TaskType.SetCustomFields]: "E.g. Set learnings custom fields",
          [TaskType.SetTimestamps]:
            "E.g. Set 'Identified at' and 'Fixed at' timestamps",
        }[selectedTaskType]
      }
      rules={{
        required: "Please choose a title",
        maxLength: {
          value: 35,
          message: "Title must be 35 characters or less",
        },
      }}
    />
  );
};
