/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotThread,
    CopilotThreadFromJSON,
    CopilotThreadFromJSONTyped,
    CopilotThreadToJSON,
} from './CopilotThread';

/**
 * 
 * @export
 * @interface AIStaffListCopilotThreadsResponseBody
 */
export interface AIStaffListCopilotThreadsResponseBody {
    /**
     * 
     * @type {Array<CopilotThread>}
     * @memberof AIStaffListCopilotThreadsResponseBody
     */
    threads: Array<CopilotThread>;
}

export function AIStaffListCopilotThreadsResponseBodyFromJSON(json: any): AIStaffListCopilotThreadsResponseBody {
    return AIStaffListCopilotThreadsResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListCopilotThreadsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListCopilotThreadsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'threads': ((json['threads'] as Array<any>).map(CopilotThreadFromJSON)),
    };
}

export function AIStaffListCopilotThreadsResponseBodyToJSON(value?: AIStaffListCopilotThreadsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'threads': ((value.threads as Array<any>).map(CopilotThreadToJSON)),
    };
}

