/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface IntegrationsVantaShowSettingsResponseBody
 */
export interface IntegrationsVantaShowSettingsResponseBody {
    /**
     * Which incidents should follow-ups be tracked for?
     * @type {Array<ConditionGroup>}
     * @memberof IntegrationsVantaShowSettingsResponseBody
     */
    followup_condition_groups?: Array<ConditionGroup>;
    /**
     * Should follow-ups be tracked in Vanta?
     * @type {boolean}
     * @memberof IntegrationsVantaShowSettingsResponseBody
     */
    track_followups: boolean;
}

export function IntegrationsVantaShowSettingsResponseBodyFromJSON(json: any): IntegrationsVantaShowSettingsResponseBody {
    return IntegrationsVantaShowSettingsResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsVantaShowSettingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsVantaShowSettingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'followup_condition_groups': !exists(json, 'followup_condition_groups') ? undefined : ((json['followup_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'track_followups': json['track_followups'],
    };
}

export function IntegrationsVantaShowSettingsResponseBodyToJSON(value?: IntegrationsVantaShowSettingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'followup_condition_groups': value.followup_condition_groups === undefined ? undefined : ((value.followup_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'track_followups': value.track_followups,
    };
}

