/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPage,
    StatusPageFromJSON,
    StatusPageFromJSONTyped,
    StatusPageToJSON,
} from './StatusPage';
import {
    StatusPageStructure,
    StatusPageStructureFromJSON,
    StatusPageStructureFromJSONTyped,
    StatusPageStructureToJSON,
} from './StatusPageStructure';

/**
 * 
 * @export
 * @interface StatusPageGoLiveResponseBody
 */
export interface StatusPageGoLiveResponseBody {
    /**
     * 
     * @type {StatusPageStructure}
     * @memberof StatusPageGoLiveResponseBody
     */
    current_structure: StatusPageStructure;
    /**
     * 
     * @type {StatusPage}
     * @memberof StatusPageGoLiveResponseBody
     */
    status_page: StatusPage;
}

export function StatusPageGoLiveResponseBodyFromJSON(json: any): StatusPageGoLiveResponseBody {
    return StatusPageGoLiveResponseBodyFromJSONTyped(json, false);
}

export function StatusPageGoLiveResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageGoLiveResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_structure': StatusPageStructureFromJSON(json['current_structure']),
        'status_page': StatusPageFromJSON(json['status_page']),
    };
}

export function StatusPageGoLiveResponseBodyToJSON(value?: StatusPageGoLiveResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_structure': StatusPageStructureToJSON(value.current_structure),
        'status_page': StatusPageToJSON(value.status_page),
    };
}

