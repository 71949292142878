import { StatusPageMigrationTypeEnum } from "@incident-io/api";
import {
  GenericErrorMessage,
  Modal,
  ModalContent,
  ProgressBar,
} from "@incident-ui";
import { useEffect, useState } from "react";
import { StatusPage, useClient } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { usePrevious } from "use-hooks";

export const PollMigrationModal = ({
  migrationId,
  verb,
  onSuccess,
  onClose,
}: {
  migrationId: string;
  verb: string;
  onSuccess: (
    page: StatusPage,
    prevComplete: number,
    prevTotal: number,
  ) => void;
  onClose: () => void;
}) => {
  const [hasFailed, setHasFailed] = useState(false);
  const {
    data: { migration },
  } = useAPI(
    hasFailed ? null : "statusPageShowMigration",
    { id: migrationId },
    {
      fallbackData: {
        migration: {
          id: migrationId,
          // This really doesn't matter
          type: StatusPageMigrationTypeEnum.Import,
        },
      },
      refreshInterval: 1000,
    },
  );

  const apiClient = useClient();

  const prevMigration = usePrevious(migration);
  useEffect(() => {
    // Avoid running more than once
    if (prevMigration?.completed_at !== undefined) return;

    if (migration?.completed_at !== undefined) {
      const pageId = migration.status_page_id;
      if (!pageId) {
        throw new Error("Migration complete but no status page ID!");
      }

      apiClient
        .statusPageShow({ id: pageId })
        .then(({ status_page }) =>
          onSuccess(
            status_page,
            prevMigration.progress_complete || 0,
            prevMigration.progress_total || 100,
          ),
        );
    }
  }, [
    apiClient,
    onSuccess,
    migration.completed_at,
    migration.status_page_id,
    prevMigration,
  ]);

  // On failure, stop polling and show an Error
  useEffect(() => {
    if (migration.failed_at !== undefined) {
      setHasFailed(true);
    }
  }, [migration.failed_at]);

  return (
    <Modal
      isOpen
      title="Import from Statuspage"
      analyticsTrackingId={"status-page-import"}
      onClose={onClose}
    >
      <ModalContent className="space-y-4">
        {hasFailed ? (
          <GenericErrorMessage />
        ) : (
          <>
            <p className="text-sm">
              We&apos;re {verb}. This might take a few minutes!
            </p>
            <ProgressBar
              transitionCompletedFrom={migration.progress_complete || 0}
              numCompleted={migration.progress_complete || 0}
              numTotal={migration.progress_total || 100}
              animateEmptyBar
            />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
