import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import IncidentLogo from "../images/incident_logo.png";
import styles from "./MicrosoftTeamsPreviews.module.scss";

export type MicrosoftTeamsMessagePreviewProps = {
  children: React.ReactNode;
  showTitle?: boolean;
  className?: string;
};

export const MicrosoftTeamsMessagePreview = ({
  children,
  className,
  showTitle,
}: MicrosoftTeamsMessagePreviewProps): React.ReactElement => {
  return (
    <div>
      {showTitle && (
        <p className="font-semibold text-content-tertiary text-xs mb-2 tracking-widest uppercase">
          Preview
        </p>
      )}
      <div
        className={tcx(
          "px-4 py-4 rounded-[6px] border border-stroke shadow bg-white",
          className,
        )}
      >
        <MicrosoftTeamsMessagePreviewContent>
          {children}
        </MicrosoftTeamsMessagePreviewContent>
      </div>
    </div>
  );
};

export const MicrosoftTeamsMessagePreviewContent = ({
  children,
}: MicrosoftTeamsMessagePreviewProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex">
        <MicrosoftTeamsAppIcon />
        <div className="flex-grow mt-2">
          <div className={tcx("flex", styles.text)}>
            <p className={"font-bold !mb-0 mr-1"}>incident.io</p>
          </div>
        </div>
      </div>
      <div className={tcx(styles.text, "mt-4")}>{children}</div>
    </div>
  );
};

export const MicrosoftTeamsAppIcon = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <img
      className={tcx(
        "mr-3 max-h-[2.5rem] max-w-[2.5rem] rounded-[6px]",
        className,
      )}
      src={IncidentLogo}
    />
  );
};
