import { CatalogConfigType } from "@incident-io/api";
import { CatalogTypeBadge } from "@incident-shared/catalog/CatalogEntryBadge";
import { AccordionStackedList, BadgeSize, StackedListItem } from "@incident-ui";
import {
  CodeSnippet,
  CodeSnippetTheme,
} from "@incident-ui/CodeSnippet/CodeSnippet";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";

export const AlertSourceEmailTaggingInfo = ({
  catalogType,
}: {
  catalogType: CatalogConfigType;
}): React.ReactElement => {
  const suggestedTagKey = catalogType.name.toLowerCase().split(" ").join("-");

  const items = [
    {
      id: "option-1",
      name: `Option 1: One email address per ${catalogType.name}`,
      description: `Each email alert source is specific to a ${catalogType.name}`,
      instructions: (
        <NumberedList>
          <div>
            Create an email alert source in incident.io for each{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            that you want to be reachable by email.
          </div>
          <div>
            In the alert source, select the{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            you want to be notified for those emails.
          </div>
        </NumberedList>
      ),
    },
    {
      id: "option-2",
      name: "Option 2: Single email",
      description: `Parse ${catalogType.name} from email content`,
      instructions: (
        <NumberedList>
          <div>
            If you control where the emails are sent from, you can add a{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              {suggestedTagKey}
            </CodeSnippet>{" "}
            in the email subject or body which is set to one of the identifiers
            below for each{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            that you want to receive emails.
          </div>
          <div>
            You can then populate a{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            alert attribute by parsing it from the email subject or body in your
            alert source.
          </div>
        </NumberedList>
      ),
    },
  ];

  return (
    <div>
      <AccordionStackedList
        items={items}
        initialExpandedItems={["option-1"]}
        renderRow={(item) => (
          <StackedListItem
            title={item.name}
            noPadding
            // We're not adding a recommended badge here, because both options are quite plausible.
            description={item.description}
            descriptionClassName="text-xs-med"
          />
        )}
        renderAccordion={(item) => item.instructions}
      />
    </div>
  );
};
