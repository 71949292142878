/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntryCreatePayload,
    CatalogEntryCreatePayloadFromJSON,
    CatalogEntryCreatePayloadFromJSONTyped,
    CatalogEntryCreatePayloadToJSON,
} from './CatalogEntryCreatePayload';

/**
 * 
 * @export
 * @interface CatalogBulkCreateEntriesRequestBody
 */
export interface CatalogBulkCreateEntriesRequestBody {
    /**
     * 
     * @type {Array<CatalogEntryCreatePayload>}
     * @memberof CatalogBulkCreateEntriesRequestBody
     */
    catalog_entries: Array<CatalogEntryCreatePayload>;
    /**
     * ID of this catalog type
     * @type {string}
     * @memberof CatalogBulkCreateEntriesRequestBody
     */
    catalog_type_id: string;
}

export function CatalogBulkCreateEntriesRequestBodyFromJSON(json: any): CatalogBulkCreateEntriesRequestBody {
    return CatalogBulkCreateEntriesRequestBodyFromJSONTyped(json, false);
}

export function CatalogBulkCreateEntriesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogBulkCreateEntriesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entries': ((json['catalog_entries'] as Array<any>).map(CatalogEntryCreatePayloadFromJSON)),
        'catalog_type_id': json['catalog_type_id'],
    };
}

export function CatalogBulkCreateEntriesRequestBodyToJSON(value?: CatalogBulkCreateEntriesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entries': ((value.catalog_entries as Array<any>).map(CatalogEntryCreatePayloadToJSON)),
        'catalog_type_id': value.catalog_type_id,
    };
}

