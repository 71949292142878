/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParam,
    EngineParamFromJSON,
    EngineParamFromJSONTyped,
    EngineParamToJSON,
} from './EngineParam';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface StepConfig
 */
export interface StepConfig {
    /**
     * Human readable description of what this step does
     * @type {string}
     * @memberof StepConfig
     */
    description: string;
    /**
     * Reference to an expression that returns resources to run this step over
     * @type {string}
     * @memberof StepConfig
     */
    for_each?: string;
    /**
     * Unique ID of this step in a workflow
     * @type {string}
     * @memberof StepConfig
     */
    id: string;
    /**
     * Human readable identifier for this step
     * @type {string}
     * @memberof StepConfig
     */
    label: string;
    /**
     * Unique name of the step in the engine
     * @type {string}
     * @memberof StepConfig
     */
    name: string;
    /**
     * Bindings for the step parameters
     * @type {Array<EngineParamBinding>}
     * @memberof StepConfig
     */
    param_bindings: Array<EngineParamBinding>;
    /**
     * Type information for the step parameters
     * @type {Array<EngineParam>}
     * @memberof StepConfig
     */
    params: Array<EngineParam>;
}

export function StepConfigFromJSON(json: any): StepConfig {
    return StepConfigFromJSONTyped(json, false);
}

export function StepConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'for_each': !exists(json, 'for_each') ? undefined : json['for_each'],
        'id': json['id'],
        'label': json['label'],
        'name': json['name'],
        'param_bindings': ((json['param_bindings'] as Array<any>).map(EngineParamBindingFromJSON)),
        'params': ((json['params'] as Array<any>).map(EngineParamFromJSON)),
    };
}

export function StepConfigToJSON(value?: StepConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'for_each': value.for_each,
        'id': value.id,
        'label': value.label,
        'name': value.name,
        'param_bindings': ((value.param_bindings as Array<any>).map(EngineParamBindingToJSON)),
        'params': ((value.params as Array<any>).map(EngineParamToJSON)),
    };
}

