import {
  StatusPageAffectedComponentStatusEnum,
  StatusPageComponentImpactStatusEnum,
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
  StatusPageStructureItem,
  StatusPageSubPageSlimWorstComponentStatusEnum,
} from "@incident-io/api";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import { Icon, IconSize } from "@incident-ui";
import { groupBy, mapValues, maxBy } from "lodash";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { worstStatusForGroup } from "../utils/utils";

export const StatusPageStatusIcon = ({
  hasOngoingIncidents,
  className,
}: {
  hasOngoingIncidents: boolean;
  className?: string;
}) => {
  const status = hasOngoingIncidents
    ? StatusPageAffectedComponentStatusEnum.PartialOutage
    : StatusPageAffectedComponentStatusEnum.Operational;

  const config = COMPONENT_STATUS_CONFIG[status];
  return <Icon id={config.icon} className={tcx(config.colour, className)} />;
};

export const StatusPageComponentStatusIcon = ({
  status,
  size,
  className,
}: {
  status:
    | StatusPageAffectedComponentStatusEnum
    | StatusPageSubPageSlimWorstComponentStatusEnum;
  size?: IconSize;
  className?: string;
}): React.ReactElement => {
  const config = COMPONENT_STATUS_CONFIG[status];
  return (
    <Icon
      id={config.icon}
      size={size}
      className={tcx(config.colour, className)}
    />
  );
};

export const StatusPageAffectedItemBadge = ({
  structureItem,
  incident,
  className,
}: {
  structureItem: StatusPageStructureItem;
  incident: StatusPageIncident;
  className?: string;
}): React.ReactElement => {
  const maintenanceOrResolvedStatuses = [
    StatusPageIncidentStatusEnum.Resolved,
    StatusPageIncidentStatusEnum.MaintenanceScheduled,
    StatusPageIncidentStatusEnum.MaintenanceInProgress,
    StatusPageIncidentStatusEnum.MaintenanceComplete,
  ];
  let impacts = incident.component_impacts;
  // If the incident is non-resolved and non-maintenance, we only care about ongoing impacts
  if (!maintenanceOrResolvedStatuses.includes(incident.status)) {
    impacts = impacts.filter((impact) => impact.end_at === undefined);
  }
  const worstImpacts = mapValues(
    groupBy(impacts, (i) => i.component_id),
    (impacts) =>
      maxBy(
        impacts.map((i) => i.status),
        (status) => COMPONENT_STATUS_CONFIG[status].rank,
      ),
  );

  if (structureItem.component) {
    const status = worstImpacts[structureItem.component.component_id];
    if (!status) return <></>;

    return (
      <StatusPageComponentStatusBadge
        className={className}
        status={status}
        name={structureItem.component.name}
      />
    );
  } else if (structureItem.group) {
    const worstStatus = worstStatusForGroup(structureItem.group, worstImpacts);

    if (!worstStatus) return <></>;

    return (
      <StatusPageComponentStatusBadge
        name={structureItem.group.name}
        status={worstStatus}
        className={className}
      />
    );
  }

  return <></>;
};

const StatusPageComponentStatusBadge = ({
  name,
  status,
  className,
}: {
  name: string;
  status: StatusPageComponentImpactStatusEnum;
  className?: string;
}): React.ReactElement => {
  const config = COMPONENT_STATUS_CONFIG[status];

  return (
    <div
      className={tcx(
        // remove py-1 if className overrides it, otherwise they fight and the
        // className loses.
        className?.includes("py-") ? undefined : "py-1",
        "px-2 rounded-full text-sm flex border truncate items-center",
        config.border,
        config.background,
        config.colour,
        className,
      )}
    >
      <Icon id={config.icon} className="flex-none mr-0.5" />
      <span className={config.textColour}>{name}</span>
    </div>
  );
};
