import { Product } from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import { Button, ButtonTheme, Tooltip } from "@incident-ui";
import { usePrimaryCommsPlatform } from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";

export const ProductGateTooltip = ({
  requiredProduct,
  children,
  className,
  onClick,
}: {
  requiredProduct?: Product;
  children: React.ReactElement;
  className?: string;
  onClick?: () => void;
}): React.ReactElement => {
  const commsPlatform = usePrimaryCommsPlatform();
  const { hasProduct } = useProductAccess();

  if (!requiredProduct) {
    return <>{children}</>;
  }

  if (hasProduct(requiredProduct)) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      side="right"
      content={
        <ProductRequiredMessage
          requiredProduct={requiredProduct}
          commsPlatform={commsPlatform}
        />
      }
    >
      <Button
        onClick={onClick}
        className={className}
        theme={ButtonTheme.Naked}
        analyticsTrackingId={null}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
