import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusPaused = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <circle
      cx={8.003}
      cy={8.003}
      r={6.25}
      stroke="#2463EB"
      strokeWidth={1.5}
      opacity={0.3}
    />
    <rect width={2} height={6} x={5.25} y={5} fill="#2463EB" rx={1} />
    <rect width={2} height={6} x={8.75} y={5} fill="#2463EB" rx={1} />
  </svg>
);
export default SvgIncidentStatusPaused;
