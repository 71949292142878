import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  Callout,
  CalloutTheme,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";

import { ServiceWizardPasteServicesForm } from "./ServiceWizardPasteServicesForm";

export const ServiceWizardManualDrawer = ({
  mode,
}: {
  mode: "custom-catalog" | "manual";
}) => {
  const navigate = useOrgAwareNavigate();

  const onClose = () =>
    navigate("/catalog/service-wizard/choose-source-of-truth");

  const config =
    mode === "custom-catalog"
      ? {
          title: "Custom catalog",
          icon: IconEnum.Server,
          hexColor: "#187F65",
          calloutTitle: "Sync your data from a custom catalog",
          calloutContent:
            "Our catalog importer lets you pull data from a variety of sources, including static files but also from arbitrary APIs or databases like BigQuery",
        }
      : {
          title: "Manage in incident.io",
          icon: IconEnum.IncidentFlame,
          hexColor: "#F25533",
        };

  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents>
        <DrawerTitle
          title={<div className="flex items-center gap-2">{config.title}</div>}
          titleAccessory={
            <IconBadge
              icon={config.icon}
              size={IconSize.Small}
              hexColor={config.hexColor}
              color={ColorPaletteEnum.Slate}
            />
          }
          onClose={onClose}
          className="text-white"
          hexColor={config.hexColor}
        />
        <DrawerBody>
          {config.calloutTitle && (
            <Callout
              iconOverride={IconEnum.Github}
              theme={CalloutTheme.Plain}
              title={config.calloutTitle}
              subtitle={config.calloutContent}
              cta={
                <Button
                  analyticsTrackingId="backstage-catalog-importer"
                  href="https://github.com/incident-io/catalog-importer/tree/master/docs"
                  openInNewTab
                >
                  Learn more
                </Button>
              }
            />
          )}
          <ServiceWizardPasteServicesForm />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};
