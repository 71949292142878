import {
  IncidentLifecycle,
  IncidentLifecyclesCreateStatusRequestBodyCategoryEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
} from "src/contexts/ClientContext";

import { IncidentStatusList } from "../../statuses/status-list/IncidentStatusList";
import { useLifecycleOverview } from "../LifecycleOverviewContext";
import { PillAndTimestamps } from "../LifecycleUIElements";
import { PreparedStatusWithTimestamp } from "../prepareLifecycle";
import { prefillLeavingStatus } from "../TimestampSetByRuleAddPopover";
import { TimestampsOnStatusChange } from "../TimestampsOnStatusChange";
import { LifecycleOverviewSection } from "./LifecycleOverviewSection";

export const ActiveSection = ({
  lifecycle,
  preparedStatuses,
  onCreate,
  onEdit,
}: {
  lifecycle: IncidentLifecycle;
  preparedStatuses: PreparedStatusWithTimestamp[];
  onEdit: (status: IncidentStatus) => void;
  onCreate: () => void;
}): React.ReactElement => {
  const { getConfigurationState } = useLifecycleOverview();
  const state = getConfigurationState(StatusCategoryEnum.Active);
  const isBackground = state === "background";

  const finalStatus = preparedStatuses[preparedStatuses.length - 1];

  return (
    <LifecycleOverviewSection
      title="Active"
      description="An incident is active when it has an ongoing impact and responders are working towards a resolution."
      category={StatusCategoryEnum.Active}
      diagram={
        <div className="mb-1">
          {preparedStatuses.map(({ status, timestamps }, idx) => {
            const nextStatus =
              preparedStatuses.length > idx
                ? preparedStatuses[idx + 1]?.status
                : undefined;
            return (
              <div key={status.id}>
                <PillAndTimestamps
                  key={status.id}
                  status={status}
                  timestamps={timestamps}
                  setByRulePrefill={prefillLeavingStatus(status.id)}
                  betweenStatuses={[status, nextStatus]}
                  hideTimestamp={idx === preparedStatuses.length - 1}
                />
              </div>
            );
          })}
        </div>
      }
      bottomBorderNode={
        <TimestampsOnStatusChange
          disabled={isBackground}
          timestamps={finalStatus.timestamps}
          setByRulePrefill={prefillLeavingStatus(finalStatus.status.id)}
          betweenStatuses={[finalStatus.status, undefined]}
        />
      }
      configurationNodeClassName="pl-4 pb-3"
      configurationNode={
        <IncidentStatusList
          category={
            IncidentLifecyclesCreateStatusRequestBodyCategoryEnum.Active
          }
          lifecycle={lifecycle}
          onEdit={onEdit}
          onCreate={onCreate}
        />
      }
    />
  );
};
