/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleMappingPayload,
    RoleMappingPayloadFromJSON,
    RoleMappingPayloadFromJSONTyped,
    RoleMappingPayloadToJSON,
} from './RoleMappingPayload';

/**
 * 
 * @export
 * @interface SCIMUpdateRoleMappingsRequestBody
 */
export interface SCIMUpdateRoleMappingsRequestBody {
    /**
     * What ScimGroup to RbacRole mappings should apply for this organisation?
     * @type {Array<RoleMappingPayload>}
     * @memberof SCIMUpdateRoleMappingsRequestBody
     */
    role_mappings?: Array<RoleMappingPayload>;
}

export function SCIMUpdateRoleMappingsRequestBodyFromJSON(json: any): SCIMUpdateRoleMappingsRequestBody {
    return SCIMUpdateRoleMappingsRequestBodyFromJSONTyped(json, false);
}

export function SCIMUpdateRoleMappingsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SCIMUpdateRoleMappingsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role_mappings': !exists(json, 'role_mappings') ? undefined : ((json['role_mappings'] as Array<any>).map(RoleMappingPayloadFromJSON)),
    };
}

export function SCIMUpdateRoleMappingsRequestBodyToJSON(value?: SCIMUpdateRoleMappingsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role_mappings': value.role_mappings === undefined ? undefined : ((value.role_mappings as Array<any>).map(RoleMappingPayloadToJSON)),
    };
}

