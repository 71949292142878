import { IncidentModeEnum, IncidentTimestamp } from "@incident-io/api";
import { Path, UseFormReturn } from "react-hook-form";
import { validateTimestampInputForDurationsFunc } from "src/components/legacy/incident/header/EditTimestampsModal";
import { IncidentTimestampFormElementV2 } from "src/components/legacy/incident/IncidentTimestampFormElementV2";
import { Incident } from "src/contexts/ClientContext";

export type FormTimestampValues = {
  [key: string]: Date | undefined;
};

export type TimestampFormData = {
  incident_timestamp_values: FormTimestampValues;
};

export const TimestampElement = <TFormType extends TimestampFormData>({
  timestamp,
  formMethods,
  incident,
  required,
  disabled = false,
}: {
  timestamp: IncidentTimestamp;
  formMethods: UseFormReturn<TFormType>;
  incident?: Incident;
  required: boolean;
  disabled?: boolean;
}) => {
  const durationsToValidate = incident?.duration_metrics?.filter((d) => {
    // We'll only bother validating if this is a standard incident --
    // we don't want to constrain timestamps in test or restrospective modes.
    return (
      d.duration_metric.validate &&
      incident.mode !== IncidentModeEnum.Retrospective
    );
  });

  // All timestamps in the incident
  const incidentTimestampValues = incident?.incident_timestamps ?? [];

  // Map to the relevant durations for each timestamp (may be none)
  const timestampsToDurations = {};

  // Map with values and timestamps for all timestamps in this incident
  const mappedIncidentTimestampValues = {};

  // Set both of the above in one loop so we only have to go through incident
  // timestamps once
  incidentTimestampValues.forEach((timestampValue) => {
    mappedIncidentTimestampValues[timestampValue.timestamp.id] = timestampValue;

    const associatedDurations = durationsToValidate?.filter((d) => {
      return (
        d.duration_metric.from_timestamp_id === timestampValue.timestamp.id ||
        d.duration_metric.to_timestamp_id === timestampValue.timestamp.id
      );
    });

    timestampsToDurations[timestampValue.timestamp.id] = associatedDurations;
  });

  const associatedDurations = timestampsToDurations[timestamp.id];

  return (
    <div key={timestamp.id}>
      <IncidentTimestampFormElementV2
        formMethods={formMethods}
        key={timestamp.id}
        name={`incident_timestamp_values.${timestamp.id}` as Path<TFormType>}
        timestamp={timestamp}
        required={required}
        validateOnChange={false}
        customValidate={validateTimestampInputForDurationsFunc(
          timestamp,
          mappedIncidentTimestampValues,
          associatedDurations,
          formMethods.getValues,
        )}
        disabled={disabled}
      />
    </div>
  );
};
