/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface PolicyViolation
 */
export interface PolicyViolation {
    /**
     * Number of days outside of the policy SLA
     * @type {number}
     * @memberof PolicyViolation
     */
    days: number;
    /**
     * When the policy violation was dismissed.
     * @type {Date}
     * @memberof PolicyViolation
     */
    dismissed_at?: Date;
    /**
     * 
     * @type {Actor}
     * @memberof PolicyViolation
     */
    dismissed_by?: Actor;
    /**
     * Why the policy violation was dismissed.
     * @type {string}
     * @memberof PolicyViolation
     */
    dismissed_reason?: string;
    /**
     * Unique ID of the policy violation
     * @type {string}
     * @memberof PolicyViolation
     */
    id: string;
    /**
     * Unique identifier of the incident that is in violation
     * @type {string}
     * @memberof PolicyViolation
     */
    incident_id: string;
    /**
     * Whether the violation is an error or a warning.
     * @type {string}
     * @memberof PolicyViolation
     */
    level: PolicyViolationLevelEnum;
    /**
     * Explanation of what the violation is
     * @type {string}
     * @memberof PolicyViolation
     */
    message: string;
    /**
     * Unique ID of the policy
     * @type {string}
     * @memberof PolicyViolation
     */
    policy_id: string;
    /**
     * Type of the policy, specifying what this applies to
     * @type {string}
     * @memberof PolicyViolation
     */
    policy_type: PolicyViolationPolicyTypeEnum;
    /**
     * Identifies the resource in violation of the policy
     * @type {string}
     * @memberof PolicyViolation
     */
    resource_id: string;
}

/**
* @export
* @enum {string}
*/
export enum PolicyViolationLevelEnum {
    Warning = 'warning',
    Error = 'error'
}/**
* @export
* @enum {string}
*/
export enum PolicyViolationPolicyTypeEnum {
    Debrief = 'debrief',
    FollowUp = 'follow_up',
    PostMortem = 'post_mortem'
}

export function PolicyViolationFromJSON(json: any): PolicyViolation {
    return PolicyViolationFromJSONTyped(json, false);
}

export function PolicyViolationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyViolation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'days': json['days'],
        'dismissed_at': !exists(json, 'dismissed_at') ? undefined : (new Date(json['dismissed_at'])),
        'dismissed_by': !exists(json, 'dismissed_by') ? undefined : ActorFromJSON(json['dismissed_by']),
        'dismissed_reason': !exists(json, 'dismissed_reason') ? undefined : json['dismissed_reason'],
        'id': json['id'],
        'incident_id': json['incident_id'],
        'level': json['level'],
        'message': json['message'],
        'policy_id': json['policy_id'],
        'policy_type': json['policy_type'],
        'resource_id': json['resource_id'],
    };
}

export function PolicyViolationToJSON(value?: PolicyViolation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'days': value.days,
        'dismissed_at': value.dismissed_at === undefined ? undefined : (value.dismissed_at.toISOString()),
        'dismissed_by': ActorToJSON(value.dismissed_by),
        'dismissed_reason': value.dismissed_reason,
        'id': value.id,
        'incident_id': value.incident_id,
        'level': value.level,
        'message': value.message,
        'policy_id': value.policy_id,
        'policy_type': value.policy_type,
        'resource_id': value.resource_id,
    };
}

