/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimelineItem,
    IncidentTimelineItemFromJSON,
    IncidentTimelineItemFromJSONTyped,
    IncidentTimelineItemToJSON,
} from './IncidentTimelineItem';

/**
 * 
 * @export
 * @interface IncidentTimelineCreateTimelineItemResponseBody
 */
export interface IncidentTimelineCreateTimelineItemResponseBody {
    /**
     * 
     * @type {IncidentTimelineItem}
     * @memberof IncidentTimelineCreateTimelineItemResponseBody
     */
    timeline_item: IncidentTimelineItem;
}

export function IncidentTimelineCreateTimelineItemResponseBodyFromJSON(json: any): IncidentTimelineCreateTimelineItemResponseBody {
    return IncidentTimelineCreateTimelineItemResponseBodyFromJSONTyped(json, false);
}

export function IncidentTimelineCreateTimelineItemResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimelineCreateTimelineItemResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_item': IncidentTimelineItemFromJSON(json['timeline_item']),
    };
}

export function IncidentTimelineCreateTimelineItemResponseBodyToJSON(value?: IncidentTimelineCreateTimelineItemResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_item': IncidentTimelineItemToJSON(value.timeline_item),
    };
}

