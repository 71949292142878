/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsJiraCloudGetConfigResponseBody,
    IntegrationsJiraCloudGetConfigResponseBodyFromJSON,
    IntegrationsJiraCloudGetConfigResponseBodyToJSON,
    IntegrationsJiraCloudGetSitesResponseBody,
    IntegrationsJiraCloudGetSitesResponseBodyFromJSON,
    IntegrationsJiraCloudGetSitesResponseBodyToJSON,
    IntegrationsJiraCloudReceiveWebhookRequestBody,
    IntegrationsJiraCloudReceiveWebhookRequestBodyFromJSON,
    IntegrationsJiraCloudReceiveWebhookRequestBodyToJSON,
    IntegrationsJiraCloudSetEnabledSitesRequestBody,
    IntegrationsJiraCloudSetEnabledSitesRequestBodyFromJSON,
    IntegrationsJiraCloudSetEnabledSitesRequestBodyToJSON,
    IntegrationsJiraCloudSetEnabledSitesResponseBody,
    IntegrationsJiraCloudSetEnabledSitesResponseBodyFromJSON,
    IntegrationsJiraCloudSetEnabledSitesResponseBodyToJSON,
} from '../models';

export interface IntegrationsJiraCloudReceiveWebhookRequest {
    receiveWebhookRequestBody: IntegrationsJiraCloudReceiveWebhookRequestBody;
    siteId?: string;
}

export interface IntegrationsJiraCloudSetEnabledSitesRequest {
    setEnabledSitesRequestBody: IntegrationsJiraCloudSetEnabledSitesRequestBody;
}

/**
 * 
 */
export class IntegrationsJiraCloudApi extends runtime.BaseAPI {

    /**
     * Get organisation\'s Jira Cloud config. There is only one active config per org.
     * GetConfig Integrations - Jira Cloud
     */
    async integrationsJiraCloudGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsJiraCloudGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/jira_cloud/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsJiraCloudGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s Jira Cloud config. There is only one active config per org.
     * GetConfig Integrations - Jira Cloud
     */
    async integrationsJiraCloudGetConfig(initOverrides?: RequestInit): Promise<IntegrationsJiraCloudGetConfigResponseBody> {
        const response = await this.integrationsJiraCloudGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Atlassian sites connected user has access to
     * GetSites Integrations - Jira Cloud
     */
    async integrationsJiraCloudGetSitesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsJiraCloudGetSitesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/jira_cloud/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsJiraCloudGetSitesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Atlassian sites connected user has access to
     * GetSites Integrations - Jira Cloud
     */
    async integrationsJiraCloudGetSites(initOverrides?: RequestInit): Promise<IntegrationsJiraCloudGetSitesResponseBody> {
        const response = await this.integrationsJiraCloudGetSitesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Receive a webhook from a Jira Cloud instance
     * ReceiveWebhook Integrations - Jira Cloud
     */
    async integrationsJiraCloudReceiveWebhookRaw(requestParameters: IntegrationsJiraCloudReceiveWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.receiveWebhookRequestBody === null || requestParameters.receiveWebhookRequestBody === undefined) {
            throw new runtime.RequiredError('receiveWebhookRequestBody','Required parameter requestParameters.receiveWebhookRequestBody was null or undefined when calling integrationsJiraCloudReceiveWebhook.');
        }

        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['site_id'] = requestParameters.siteId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/webhooks/jiracloud`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsJiraCloudReceiveWebhookRequestBodyToJSON(requestParameters.receiveWebhookRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Receive a webhook from a Jira Cloud instance
     * ReceiveWebhook Integrations - Jira Cloud
     */
    async integrationsJiraCloudReceiveWebhook(requestParameters: IntegrationsJiraCloudReceiveWebhookRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.integrationsJiraCloudReceiveWebhookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set enabled sites
     * SetEnabledSites Integrations - Jira Cloud
     */
    async integrationsJiraCloudSetEnabledSitesRaw(requestParameters: IntegrationsJiraCloudSetEnabledSitesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsJiraCloudSetEnabledSitesResponseBody>> {
        if (requestParameters.setEnabledSitesRequestBody === null || requestParameters.setEnabledSitesRequestBody === undefined) {
            throw new runtime.RequiredError('setEnabledSitesRequestBody','Required parameter requestParameters.setEnabledSitesRequestBody was null or undefined when calling integrationsJiraCloudSetEnabledSites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/jira_cloud/sites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsJiraCloudSetEnabledSitesRequestBodyToJSON(requestParameters.setEnabledSitesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsJiraCloudSetEnabledSitesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set enabled sites
     * SetEnabledSites Integrations - Jira Cloud
     */
    async integrationsJiraCloudSetEnabledSites(requestParameters: IntegrationsJiraCloudSetEnabledSitesRequest, initOverrides?: RequestInit): Promise<IntegrationsJiraCloudSetEnabledSitesResponseBody> {
        const response = await this.integrationsJiraCloudSetEnabledSitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
