/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementPostActionOptionParam,
    AnnouncementPostActionOptionParamFromJSON,
    AnnouncementPostActionOptionParamFromJSONTyped,
    AnnouncementPostActionOptionParamToJSON,
} from './AnnouncementPostActionOptionParam';

/**
 * 
 * @export
 * @interface AnnouncementPostsListActionOptionsResponseBody
 */
export interface AnnouncementPostsListActionOptionsResponseBody {
    /**
     * Actions that can be added to this announcement post
     * @type {Array<AnnouncementPostActionOptionParam>}
     * @memberof AnnouncementPostsListActionOptionsResponseBody
     */
    post_actions: Array<AnnouncementPostActionOptionParam>;
}

export function AnnouncementPostsListActionOptionsResponseBodyFromJSON(json: any): AnnouncementPostsListActionOptionsResponseBody {
    return AnnouncementPostsListActionOptionsResponseBodyFromJSONTyped(json, false);
}

export function AnnouncementPostsListActionOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostsListActionOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'post_actions': ((json['post_actions'] as Array<any>).map(AnnouncementPostActionOptionParamFromJSON)),
    };
}

export function AnnouncementPostsListActionOptionsResponseBodyToJSON(value?: AnnouncementPostsListActionOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'post_actions': ((value.post_actions as Array<any>).map(AnnouncementPostActionOptionParamToJSON)),
    };
}

