import type { SVGProps } from "react";
import * as React from "react";
const SvgPingFederate = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="#B3282D" d="M2 2h16v16H2z" />
    <path
      fill="#FEFEFE"
      d="M4.759 14.227V5.5h3.443c.662 0 1.226.126 1.691.38.466.25.821.597 1.066 1.043.247.443.37.955.37 1.534 0 .58-.124 1.091-.374 1.534-.25.444-.613.789-1.087 1.036-.472.247-1.043.37-1.713.37H5.96V9.92h1.897c.355 0 .647-.061.877-.183.233-.125.407-.297.52-.516.117-.221.175-.476.175-.763 0-.29-.058-.542-.175-.758a1.167 1.167 0 0 0-.52-.507c-.233-.122-.528-.183-.886-.183H6.604v7.218H4.759Z"
    />
  </svg>
);
export default SvgPingFederate;
