import { useEffect, useState } from "react";

/* useClipboard allows you to copy text to the user's clipboard.
 * Send text to the clipboard using `copyTextToClipboard('text-you-want-to-copy')`
 * and react to copy events using `hasCopied`.
 *
 * `hasCopied` automatically resets back to false after 3 seconds, you can disable this by setting `autoReset` to false.
 * */
export function useClipboard({
  autoReset = true,
}: { autoReset?: boolean } = {}): {
  hasCopied: boolean;
  copyTextToClipboard: (text: string) => void;
} {
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    if (!hasCopied || !autoReset) return undefined;

    const timeout = setTimeout(() => setHasCopied(false), 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [hasCopied, autoReset]);

  return {
    hasCopied: hasCopied,
    copyTextToClipboard: (text) =>
      navigator.clipboard?.writeText(text).then(() => setHasCopied(true)),
  };
}
