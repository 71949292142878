/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleCoverRequestCandidate
 */
export interface ScheduleCoverRequestCandidate {
    /**
     * State of the candidate for the cover request
     * @type {string}
     * @memberof ScheduleCoverRequestCandidate
     */
    state: ScheduleCoverRequestCandidateStateEnum;
    /**
     * ID of the user that is a candidate for this cover request
     * @type {string}
     * @memberof ScheduleCoverRequestCandidate
     */
    user_id: string;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleCoverRequestCandidateStateEnum {
    Asked = 'asked',
    Declined = 'declined',
    Accepted = 'accepted'
}

export function ScheduleCoverRequestCandidateFromJSON(json: any): ScheduleCoverRequestCandidate {
    return ScheduleCoverRequestCandidateFromJSONTyped(json, false);
}

export function ScheduleCoverRequestCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleCoverRequestCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'user_id': json['user_id'],
    };
}

export function ScheduleCoverRequestCandidateToJSON(value?: ScheduleCoverRequestCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'user_id': value.user_id,
    };
}

