import type { SVGProps } from "react";
import * as React from "react";
const SvgGoogle = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#FFC107"
      d="M17.354 8.531h-.604V8.5H10v3h4.239A4.498 4.498 0 0 1 5.5 10 4.5 4.5 0 0 1 10 5.5c1.147 0 2.19.433 2.985 1.14l2.122-2.122A7.465 7.465 0 0 0 10 2.5a7.5 7.5 0 1 0 7.354 6.031Z"
    />
    <path
      fill="#FF3D00"
      d="m3.365 6.51 2.464 1.806A4.498 4.498 0 0 1 10 5.5c1.147 0 2.19.433 2.985 1.14l2.122-2.122A7.465 7.465 0 0 0 10 2.5a7.496 7.496 0 0 0-6.635 4.01Z"
    />
    <path
      fill="#4CAF50"
      d="M10 17.5a7.465 7.465 0 0 0 5.028-1.947l-2.32-1.964A4.467 4.467 0 0 1 10 14.5a4.498 4.498 0 0 1-4.231-2.98l-2.446 1.885A7.494 7.494 0 0 0 10 17.5Z"
    />
    <path
      fill="#1976D2"
      d="M17.354 8.531h-.604V8.5H10v3h4.239a4.515 4.515 0 0 1-1.533 2.09l.001-.002 2.321 1.965C14.864 15.702 17.5 13.75 17.5 10c0-.503-.052-.994-.146-1.469Z"
    />
  </svg>
);
export default SvgGoogle;
