import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { captureException } from "@sentry/react";
import { useParams } from "react-router";

import { useAPI } from "../../../../utils/swr";
import { LegacyPostmortemTemplateCreateEditForm } from "./LegacyPostmortemTemplateCreateEditForm";

export const LegacyPostmortemTemplateEditPage = (): React.ReactElement => {
  const { id } = useParams();
  const {
    data: templateData,
    isLoading,
    error,
  } = useAPI("postmortemsShowLegacyTemplate", { id: id as string });
  const template = templateData?.postmortem_template;

  if (error) {
    captureException(error);
    return (
      <GenericErrorMessage description="Unable to fetch post-mortem document template" />
    );
  }

  if (isLoading || !template) {
    return <Loader />;
  }

  return (
    <LegacyPostmortemTemplateCreateEditForm
      mode={Mode.Edit}
      initialData={template}
    />
  );
};
