/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathSlim,
    EscalationPathSlimFromJSON,
    EscalationPathSlimFromJSONTyped,
    EscalationPathSlimToJSON,
} from './EscalationPathSlim';

/**
 * 
 * @export
 * @interface TriggeredTimelineData
 */
export interface TriggeredTimelineData {
    /**
     * 
     * @type {EscalationPathSlim}
     * @memberof TriggeredTimelineData
     */
    escalation_path: EscalationPathSlim;
}

export function TriggeredTimelineDataFromJSON(json: any): TriggeredTimelineData {
    return TriggeredTimelineDataFromJSONTyped(json, false);
}

export function TriggeredTimelineDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TriggeredTimelineData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_path': EscalationPathSlimFromJSON(json['escalation_path']),
    };
}

export function TriggeredTimelineDataToJSON(value?: TriggeredTimelineData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_path': EscalationPathSlimToJSON(value.escalation_path),
    };
}

