import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceMonteCarloSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Log into Monte Carlo and navigate to the <strong>Settings</strong> page,
        then click on <strong>Notifications</strong> and create an audience.
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Set the <strong>Audience name</strong> to your preference, and the{" "}
            <strong>Recepient channel</strong> to:
          </>
        }
        blocks={[
          {
            title: "incident.io",
            code: "Webhook",
          },
        ]}
      />

      <div className="overflow-hidden flex flex-col gap-2">
        <p>
          Then, set the <strong>incident.io URL</strong> to:
        </p>
        <SingleLineCodeBlock
          title="Webhook URL"
          code={`${publicApiUrl()}/v2/alert_events/monte_carlo/${
            alertSourceConfig.id
          }`}
        />
        {alertSourceConfig.secret_token && (
          <>
            <p>
              Set the <strong>Token</strong> to:
            </p>
            <SingleLineCodeBlock
              title={"Token"}
              code={alertSourceConfig.secret_token}
            />
          </>
        )}
        <p>
          And the <strong>Display name</strong> to:
        </p>
        <SingleLineCodeBlock
          title="Display name"
          code={`${alertSourceConfig.name}`}
        />
      </div>
      <p>
        You can optionally add query parameters to the Webhook URL and those
        will be available in the payload in the next step. For example, you
        might add a parameter like{" "}
        <strong className={"font-mono whitespace-pre"}>?team=my-team</strong> if
        you&apos;d like alerts for this audience to be attributed to a specific
        team.
      </p>
      <p>
        Ensure your connection is successful by clicking on the{" "}
        <strong>Test notification</strong> button.
      </p>
      <p>
        Save your audience and you&rsquo;ll start receiving alerts from Monte
        Carlo in incident.io.
      </p>
    </SetupInfoNumberedList>
  );
};
