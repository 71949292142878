/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentsBulkUpdateIncidentTypesRequestBody
 */
export interface IncidentsBulkUpdateIncidentTypesRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentsBulkUpdateIncidentTypesRequestBody
     */
    incident_ids: Array<string>;
    /**
     * An incident type ID
     * @type {string}
     * @memberof IncidentsBulkUpdateIncidentTypesRequestBody
     */
    incident_type_id: string;
}

export function IncidentsBulkUpdateIncidentTypesRequestBodyFromJSON(json: any): IncidentsBulkUpdateIncidentTypesRequestBody {
    return IncidentsBulkUpdateIncidentTypesRequestBodyFromJSONTyped(json, false);
}

export function IncidentsBulkUpdateIncidentTypesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsBulkUpdateIncidentTypesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_ids': json['incident_ids'],
        'incident_type_id': json['incident_type_id'],
    };
}

export function IncidentsBulkUpdateIncidentTypesRequestBodyToJSON(value?: IncidentsBulkUpdateIncidentTypesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_ids': value.incident_ids,
        'incident_type_id': value.incident_type_id,
    };
}

