export const Page = () => (
  <svg
    width="25"
    height="29"
    viewBox="0 0 25 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.3125 5.3125C0.3125 2.68915 2.43915 0.5625 5.0625 0.5625H13.8125C14.144 0.5625 14.462 0.694196 14.6964 0.928617L24.3214 10.5536C24.6789 10.9111 24.7858 11.4488 24.5924 11.9159C24.3989 12.3829 23.9431 12.6875 23.4375 12.6875H17.3125C14.6891 12.6875 12.5625 10.5608 12.5625 7.9375V3.0625H5.0625C3.81986 3.0625 2.8125 4.06986 2.8125 5.3125V23.6875C2.8125 24.9302 3.81985 25.9375 5.0625 25.9375H12.9375C13.6279 25.9375 14.1875 26.4971 14.1875 27.1875C14.1875 27.8779 13.6279 28.4375 12.9375 28.4375H5.0625C2.43916 28.4375 0.3125 26.3109 0.3125 23.6875V5.3125ZM15.0625 4.83027L20.4197 10.1875H17.3125C16.0698 10.1875 15.0625 9.18015 15.0625 7.9375V4.83027ZM23 18C23 17.0335 22.2165 16.25 21.25 16.25C20.2835 16.25 19.5 17.0335 19.5 18V21.5C19.5 22.4665 20.2835 23.25 21.25 23.25C22.2165 23.25 23 22.4665 23 21.5V18ZM21.25 25C22.2165 25 23 25.7835 23 26.75V26.7675C23 27.734 22.2165 28.5175 21.25 28.5175C20.2835 28.5175 19.5 27.734 19.5 26.7675V26.75C19.5 25.7835 20.2835 25 21.25 25Z"
      fill="url(#paint0_linear_1885_65)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1885_65"
        x1="12.5"
        y1="0.5625"
        x2="12.5"
        y2="28.5175"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3AEBB" />
        <stop offset="1" stopColor="#718295" />
      </linearGradient>
    </defs>
  </svg>
);
