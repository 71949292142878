import {
  EscalationPathTargetScheduleModeEnum,
  EscalationPathTargetTypeEnum,
} from "@incident-io/api";
import { Badge, BadgeTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import {
  Popover,
  PopoverBody,
  PopoverTitleBar,
} from "@incident-ui/Popover/Popover";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import { PopoverSearch } from "@incident-ui/Popover/PopoverSearch";
import { getAvatar } from "@incident-ui/PopoverSelect/PopoverSelectOptions";
import { useManageSelectState } from "@incident-ui/PopoverSelect/utils";
import { SelectOption, SelectOptionGroup } from "@incident-ui/Select/types";
import React, { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useClient } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-hooks";

import { loadTargets } from "../common/helpers";
import { EscalationPathTargetFormData } from "../common/types";

type TargetOption = SelectOption & EscalationPathTargetFormData;

export const EscalationPathTargetSelect = <TFormType extends FieldValues>({
  formMethods,
  name,
  required,
}: {
  formMethods: UseFormReturn<TFormType>;
  name: FieldPath<TFormType>;
  required?: boolean | string;
}) => {
  // Reuse PopoverSelect state management for opening/closing the popover
  const { openControl, search, setSearch, handleClose } =
    useManageSelectState();

  // Create a custom state to store which (if any) schedule has been selected,
  // so that we can render the schedule sub-options
  const [showScheduleOptions, setShowScheduleOptions] =
    useState<TargetOption | null>(null);

  // Load in the options for the popover: schedules and users
  const [options, setOptions] = useState<SelectOptionGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSearch = useDebounce(search, 250);

  const apiClient = useClient();

  const { data: catalogTypes } = useAPI(
    "catalogListTypes",
    {},
    {
      fallbackData: { catalog_types: [] },
    },
  );

  useEffect(() => {
    setLoading(true);
    loadTargets(
      apiClient,
      catalogTypes,
    )(debouncedSearch)
      .then((loadedOptions) => setOptions(loadedOptions))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const { field } = useController({
    control: formMethods.control,
    name,
    rules: { required },
  });

  // Updates the form state when a user is toggled in the popover (removes /
  // adds them as necessary).
  const updateUserTargetOption = (newValue: SelectOption) => {
    if (!field.value) {
      field.onChange([newValue]);
    } else if (field.value.map((opt) => opt.value).includes(newValue.value)) {
      field.onChange(field.value.filter((v) => v.value !== newValue.value));
    } else {
      field.onChange([...field.value, newValue]);
    }
    handleClose();
  };

  // Updates the form state when a schedule is toggled in the popover (removes /
  // adds it as necessary, taking into account the schedule mode).
  const updateScheduleTargetOption = (
    mode: EscalationPathTargetScheduleModeEnum,
  ) => {
    if (
      !showScheduleOptions ||
      showScheduleOptions.type !== EscalationPathTargetTypeEnum.Schedule
    ) {
      throw new Error(
        "Unreachable: showSecondaryForm must be defined when selecting schedule mode",
      );
    }

    const newValue = {
      ...showScheduleOptions,
      schedule_mode: mode,
    };

    if (!field.value) {
      field.onChange([newValue]);
    } else if (field.value.map((opt) => opt.value).includes(newValue.value)) {
      const current = field.value.find((opt) => opt.value === newValue.value);

      // If we're toggling the same value and the schedule mode is the same
      // then we want to remove it. Otherwise, the schedule mode is different
      // and we want to update the target with the new schedule mode.
      if (current.schedule_mode === newValue.schedule_mode) {
        field.onChange(
          field.value.filter((v: TargetOption) => v.value !== newValue.value),
        );
      } else {
        field.onChange([
          ...field.value.filter(
            (v: TargetOption) => v.value !== newValue.value,
          ),
          newValue,
        ]);
      }
    } else {
      field.onChange([...field.value, newValue]);
    }

    setShowScheduleOptions(null);
    handleClose();
  };

  const scheduleOptions = options[0]?.options ?? [];
  const userOptions = options[1]?.options ?? [];

  return (
    <Form.InputWrapper<TFormType> name={name} required={required}>
      <Popover
        {...openControl}
        align="start"
        className="min-w-[368px] max-w-[368px]"
        trigger={
          <TargetSelectTriggerButton
            isLoading={loading}
            onClick={() => {
              openControl.setIsOpen(true);
            }}
            selectedValues={field.value}
            onRemoveTarget={field.onChange}
          />
        }
      >
        {!showScheduleOptions ? (
          <PopoverSearch value={search} onChange={setSearch} />
        ) : (
          <></>
        )}
        <PopoverBody scroll>
          {showScheduleOptions ? (
            <ScheduleOptionsPopover
              showScheduleOptions={showScheduleOptions}
              setShowScheduleOptions={setShowScheduleOptions}
              updateScheduleTargetOption={updateScheduleTargetOption}
            />
          ) : (
            <TargetSelectPopover
              scheduleOptions={scheduleOptions}
              userOptions={userOptions}
              updateUserTargetOption={updateUserTargetOption}
              setShowScheduleOptions={setShowScheduleOptions}
            />
          )}
        </PopoverBody>
      </Popover>
    </Form.InputWrapper>
  );
};

const ScheduleOptionsPopover = ({
  showScheduleOptions,
  setShowScheduleOptions,
  updateScheduleTargetOption,
}: {
  showScheduleOptions: TargetOption;
  setShowScheduleOptions: (value: TargetOption | null) => void;
  updateScheduleTargetOption: (
    mode: EscalationPathTargetScheduleModeEnum,
  ) => void;
}) => {
  return (
    <div>
      <PopoverTitleBar
        handleBack={() => {
          setShowScheduleOptions(null);
        }}
        title={showScheduleOptions.label}
      />
      <PopoverItem
        icon={IconEnum.Calendar}
        onClick={() =>
          updateScheduleTargetOption(
            EscalationPathTargetScheduleModeEnum.CurrentlyOnCall,
          )
        }
      >
        Currently On-call
      </PopoverItem>
      <PopoverItem
        icon={IconEnum.Calendar}
        onClick={() =>
          updateScheduleTargetOption(
            EscalationPathTargetScheduleModeEnum.AllUsers,
          )
        }
      >
        Everyone
      </PopoverItem>
    </div>
  );
};

const TargetSelectPopover = ({
  scheduleOptions,
  userOptions,
  updateUserTargetOption,
  setShowScheduleOptions,
}: {
  scheduleOptions: SelectOption<string>[];
  userOptions: SelectOption<string>[];
  updateUserTargetOption: (value: SelectOption) => void;
  setShowScheduleOptions: (value: TargetOption | null) => void;
}) => {
  if (scheduleOptions.length === 0 && userOptions.length === 0) {
    return (
      <PopoverItem noHover className="text-content-secondary">
        No items found
      </PopoverItem>
    );
  }

  return (
    <div>
      {scheduleOptions.length > 0 ? (
        <PopoverItemGroup key={"schedules"} label={"Schedules"}>
          {scheduleOptions.map((option) => (
            <PopoverItem
              key={option.value}
              icon={IconEnum.Calendar}
              showContinueChevron
              onClick={() => {
                setShowScheduleOptions({
                  ...option,
                  type: EscalationPathTargetTypeEnum.Schedule,
                });
              }}
            >
              {option.label}
            </PopoverItem>
          ))}
        </PopoverItemGroup>
      ) : (
        <></>
      )}
      {userOptions.length > 0 ? (
        <PopoverItemGroup key={"users"} label={"Users"}>
          {userOptions.map((option) => (
            <PopoverItem
              key={option.value}
              onClick={() => updateUserTargetOption(option)}
              prefix={
                option.image_url &&
                getAvatar({
                  imageUrl: option.image_url,
                  label: option.label,
                })
              }
            >
              {option.label}
            </PopoverItem>
          ))}
        </PopoverItemGroup>
      ) : (
        <></>
      )}
    </div>
  );
};

type SelectTargetButtonProps = {
  isLoading?: boolean;
  onClick: () => void;
  selectedValues: TargetOption[];
  onRemoveTarget: (value: TargetOption[]) => void;
};

export const TargetSelectTriggerButton = React.forwardRef<
  HTMLButtonElement,
  SelectTargetButtonProps
>(
  (
    {
      isLoading,
      selectedValues,
      onRemoveTarget,
      ...rest
    }: SelectTargetButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        {...rest}
        ref={ref}
        className={tcx(
          "min-h-10",
          "rounded-2 border bg-surface-primary border-border hover:border-border-hover shadow-sm",
          "transition-all group",
          "px-2 py-2 flex items-center gap-2 text-content-secondary",
          "w-full",
          "cursor-pointer",
        )}
      >
        <div className="flex items-center grow gap-1 flex-wrap">
          {selectedValues.length > 0 ? (
            selectedValues.map((v: TargetOption) => (
              <TargetBadge
                key={v.label}
                target={v}
                onRemove={(e) => {
                  e?.stopPropagation();
                  onRemoveTarget(
                    selectedValues.filter(
                      (opt: SelectOption) => opt.value !== v.value,
                    ),
                  );
                }}
              />
            ))
          ) : (
            <p className="text-sm text-content-tertiary">
              {"Choose a schedule or user..."}
            </p>
          )}
        </div>
        <div className="flex-center-y gap-1 ml-auto">
          {isLoading && (
            <Icon
              id={IconEnum.Loader}
              className="text-content-tertiary animate-spin"
            />
          )}
          <Icon
            id={IconEnum.ChevronDown}
            className={tcx("text-content-tertiary transition-colors")}
          />
        </div>
      </button>
    );
  },
);

TargetSelectTriggerButton.displayName = "TargetSelectTriggerButton";

const TargetBadge = ({
  target,
  onRemove,
}: {
  target: TargetOption;
  onRemove: (e?: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}) => {
  const icon =
    target.type === EscalationPathTargetTypeEnum.User
      ? IconEnum.User
      : IconEnum.Calendar;

  const suffix: ReactNode[] = [];
  if (target.type === EscalationPathTargetTypeEnum.Schedule) {
    suffix.push(
      <Icon
        key="chevron-right-icon"
        id={IconEnum.ChevronRight}
        size={IconSize.Small}
        className="text-slate-400"
      />,
    );
    switch (target.schedule_mode) {
      case EscalationPathTargetScheduleModeEnum.Empty:
      case EscalationPathTargetScheduleModeEnum.CurrentlyOnCall:
        suffix.push(
          <div key="on-call" className="text-white text-md">
            On-call
          </div>,
        );
        break;
      case EscalationPathTargetScheduleModeEnum.AllUsers:
        suffix.push(
          <div key="all" className="text-white text-md">
            Everyone
          </div>,
        );
        break;
      case EscalationPathTargetScheduleModeEnum.AllUsersForRota:
        throw new Error("Unsupported schedule mode referenced");
      default:
        throw new Error("Unknown schedule mode referenced");
    }
  }

  return (
    <Badge
      key={target.label}
      icon={icon}
      iconClassName="text-surface-secondary"
      theme={BadgeTheme.Primary}
      onClose={onRemove}
    >
      <div className="text-white text-md pl-1 max-w-[200px] truncate">
        {target.label}
      </div>
      {suffix}
    </Badge>
  );
};
