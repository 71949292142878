import type { SVGProps } from "react";
import * as React from "react";
const SvgCortex = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7650E9"
      d="M13.415 5.087 14.57 3.93a.334.334 0 0 0 0-.472l-.36-.36a.333.333 0 0 0-.473 0l-1.156 1.156a2.925 2.925 0 0 1-2.082.86 2.925 2.925 0 0 1-2.082-.86L7.262 3.098a.334.334 0 0 0-.472 0l-.362.36a.334.334 0 0 0 0 .472l1.157 1.157A4.094 4.094 0 0 0 10.5 6.292a4.094 4.094 0 0 0 2.915-1.205Zm1.156 12.455a.333.333 0 0 0 0-.473l-1.156-1.156a4.094 4.094 0 0 0-2.915-1.206 4.096 4.096 0 0 0-2.914 1.206L6.428 17.07a.334.334 0 0 0 0 .472l.361.36c.13.13.342.13.472 0l1.157-1.156a2.925 2.925 0 0 1 2.082-.86c.787 0 1.527.305 2.082.86l1.156 1.156c.13.13.342.13.472 0l.36-.36h.001Zm1.342-4.128 1.157 1.157c.13.13.341.13.472 0l.36-.36a.334.334 0 0 0 0-.473l-1.156-1.156a2.926 2.926 0 0 1-.86-2.082c0-.788.305-1.527.86-2.082l1.156-1.157a.334.334 0 0 0 0-.472l-.36-.36a.333.333 0 0 0-.472 0l-1.157 1.156a4.096 4.096 0 0 0-1.205 2.915 4.093 4.093 0 0 0 1.205 2.914ZM3.5 17.861l-.264-.265-.097-.095a.329.329 0 0 1-.079-.13.332.332 0 0 1 .08-.343l.582-.582 2.026-2.026.172-.173a5.307 5.307 0 0 0 .001-7.495l-.379-.378-.093-.093-2.31-2.31a.334.334 0 0 1 0-.472l.36-.36a.334.334 0 0 1 .473 0l2.068 2.067.16.16.38.38.173.173A5.267 5.267 0 0 0 10.5 7.472a5.265 5.265 0 0 0 3.747-1.553l2.782-2.78c.13-.13.341-.13.472 0l.36.36c.13.13.13.342 0 .472l-2.78 2.781h-.001a5.265 5.265 0 0 0-1.552 3.748 5.264 5.264 0 0 0 1.552 3.747l1.39 1.39 1.391 1.391a.334.334 0 0 1 0 .472l-.36.361a.332.332 0 0 1-.472 0l-2.782-2.781a5.307 5.307 0 0 0-7.494 0l-.391.39-.081.081-2.31 2.31a.332.332 0 0 1-.472 0Zm4.93-5.7a.334.334 0 0 0 .41.41 6.525 6.525 0 0 1 3.323 0c.25.065.474-.16.408-.41a6.494 6.494 0 0 1 0-3.322.334.334 0 0 0-.409-.41 6.495 6.495 0 0 1-3.322 0 .334.334 0 0 0-.409.41 6.529 6.529 0 0 1 0 3.322Zm-4.97 2.41c.13.13.341.13.471 0l1.157-1.157A4.093 4.093 0 0 0 6.293 10.5a4.095 4.095 0 0 0-1.206-2.914L3.93 6.43a.334.334 0 0 0-.472 0l-.36.36a.334.334 0 0 0 0 .473l1.156 1.156a2.925 2.925 0 0 1 .861 2.082 2.925 2.925 0 0 1-.861 2.082l-1.156 1.156a.334.334 0 0 0 0 .472l.36.36Z"
    />
  </svg>
);
export default SvgCortex;
