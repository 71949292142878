import { TutorialPrompt } from "@incident-shared/tutorial";
import { EmptyState, IconEnum } from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";

// EmptyStateAllFiltered should be used when filters have removed all the elements.
export const EmptyStateAllFiltered = ({
  isKanban,
}: {
  isKanban: boolean;
}): React.ReactElement => {
  return (
    <EmptyState
      icon={IconEnum.Filter}
      title="No matching incidents"
      content={
        isKanban
          ? "There aren't any active incidents matching your filters. Switch to the list view to see closed incidents."
          : "We couldn't find any incidents that match your filters."
      }
      className="h-full"
    />
  );
};

// EmptyStateKanbanView should be used when there are no elements because all incidents are closed.
export const EmptyStateKanbanView = (): React.ReactElement => {
  return (
    <EmptyState
      icon={IconEnum.Incident}
      title="All your incidents are closed"
      content="You don't have any active incidents right now. Switch to the list view to see all incidents."
      className="h-full"
    />
  );
};

// EmptyStateNoIncidentsYet should be used when there are no elements because there aren't any
// incidents yet.
export const EmptyStateNoIncidentsYet = (): React.ReactElement => {
  const { identity } = useIdentity();
  const commsPlatform = usePrimaryCommsPlatform();

  if (identity.has_created_tutorial || commsPlatform !== CommsPlatform.Slack) {
    return (
      <EmptyState
        icon={IconEnum.Incident}
        content="You don't have any incidents yet"
      />
    );
  }

  return (
    <EmptyState
      icon={IconEnum.Incident}
      title="You don't have any incidents yet"
      content="Get started with the incident.io tutorial"
      cta={<TutorialPrompt />}
      className="h-full"
    />
  );
};
