import {
  TimeZonePopoverSelect,
  TimeZonePopoverSelectProps,
  TimeZoneSelect,
  TimeZoneSelectProps,
} from "@incident-ui/TimeZoneSelect/TimeZoneSelect";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const TimeZoneSelectV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TimeZoneSelectProps>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const {
    // We don't want to pass the ref onwards here: react-select and our wrappers
    // have their own refs, so we always use a controlled input here.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <TimeZoneSelect
        {...field}
        {...inputProps}
        onChange={(val) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

export const TimeZonePopoverSelectV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TimeZonePopoverSelectProps>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const {
    // We don't want to pass the ref onwards here: react-select and our wrappers
    // have their own refs, so we always use a controlled input here.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <TimeZonePopoverSelect
        {...field}
        {...inputProps}
        onChange={(val) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};
