import { UsersCreateRequestBodyBaseRoleSlugEnum } from "@incident-io/api";
import {
  StaticMultiSelectV2,
  StaticSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { LoadingBar } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { partition } from "lodash";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

export interface RolesSelectFormData {
  base_role_slug: UsersCreateRequestBodyBaseRoleSlugEnum;
  custom_role_ids: string[];
}

export const RolesSelect = ({
  selectedState,
  formMethods,
  userId,
  forScim = false,
}: {
  selectedState: string;
  formMethods: UseFormReturn<RolesSelectFormData>;
  userId: string | null;
  forScim?: boolean;
}): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { data } = useAPI("usersListAvailableSeatsAndRoles", {
    userId: userId ?? undefined,
    forScim,
  });

  const { onCallOnlyBillingSeats } = useFlags();

  if (!identity || !data) {
    return <LoadingBar className="h-12" />;
  }

  const [baseRoles, customRoles] = partition(
    data.roles,
    ({ role }) => role.is_base_role,
  );

  const customRoleOptions = customRoles.map(({ role, can_assign }) => ({
    label: role.name,
    value: role.id,
    description: role.description,
    disabled: !can_assign,
  }));

  let upgradeIsDisabledDueToNoPaidSeat: boolean;
  if (onCallOnlyBillingSeats) {
    upgradeIsDisabledDueToNoPaidSeat = false;
  } else {
    upgradeIsDisabledDueToNoPaidSeat = selectedState === "viewer";
  }

  const baseRoleOptions = baseRoles.map(({ role, can_assign }) => ({
    label: role.name,
    value: role.slug,
    description: role.description,
    disabled: !can_assign,
  }));

  // If only one option is available, that's quite a pointless dropdown.
  const allBaseRolesDisabled =
    baseRoleOptions.filter((option) => !option.disabled).length <= 1;

  // If there are some custom roles but they're all disabled, disable the whole
  // dropdown.
  //
  // If there are no options, leave it enabled so that is visible to the user.
  const allCustomRolesDisabled =
    customRoleOptions.length > 0 &&
    customRoleOptions.every((option) => option.disabled);

  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="base_role_slug"
        label={"Role"}
        options={baseRoleOptions}
        disabled={upgradeIsDisabledDueToNoPaidSeat || allBaseRolesDisabled}
        disabledTooltipContent={
          upgradeIsDisabledDueToNoPaidSeat ? (
            <>
              To grant this user additional permissions, you&apos;ll need to
              upgrade their seat from{" "}
              <span className="font-semibold">viewer</span> to{" "}
              <span className="font-semibold">responder</span>.
            </>
          ) : forScim ? (
            "You don't have permission to change SCIM mappings."
          ) : (
            "You don't have permission to change this user's roles."
          )
        }
      />
      {identity.feature_gates.rbac_custom_roles && (
        <div className="pt-5">
          <StaticMultiSelectV2
            formMethods={formMethods}
            name="custom_role_ids"
            placeholder="Select roles"
            label="Custom roles"
            required={false}
            helptext={
              <div className="text-sm-normal text-content-secondary">
                Grant additional permissions with custom roles.
              </div>
            }
            disabled={
              upgradeIsDisabledDueToNoPaidSeat || allCustomRolesDisabled
            }
            disabledTooltipContent={
              upgradeIsDisabledDueToNoPaidSeat ? (
                <>
                  To give this user a custom role, you&apos;ll need to upgrade
                  their seat from <span className="font-semibold">viewer</span>{" "}
                  to <span className="font-semibold">responder</span>.
                </>
              ) : forScim ? (
                "You don't have permission to change SCIM mappings."
              ) : (
                "You don't have permission to change this user's roles."
              )
            }
            isClearable={true}
            options={customRoleOptions}
          />
        </div>
      )}
    </>
  );
};
