import { Route, Routes } from "react-router";

import { MSTeamsPreviewMessagePage } from "./MSTeamsPreviewMessagePage";
import { MSTeamsPreviewPage } from "./MSTeamsPreviewPage";

export const MSTeamsPreviewRoute = () => {
  return (
    <Routes>
      <Route path="/:targetName" element={<MSTeamsPreviewMessagePage />} />
      <Route path="/" element={<MSTeamsPreviewPage />} />
    </Routes>
  );
};
