import { UserOption } from "@incident-io/api";
import { useState } from "react";
import { useAPI } from "src/utils/swr";

export type UserCacheReturn = {
  hydrating: boolean;
  getHydratedUser: (value?: string | undefined) => UserOption | undefined;
  cacheHydratedUsers: (users: UserOption[]) => void;
  removeFromCache: (users: string[]) => void;
};

export const useHydratedUserCache = (idList: string[]): UserCacheReturn => {
  const [hydratedValuesCache, setHydratedValuesCache] = useState<
    Map<string, UserOption>
  >(new Map());

  const idsTofetch = idList.filter((id) => !hydratedValuesCache.has(id)).sort();

  const { data: users, isLoading: hydrating } = useAPI(
    idsTofetch.length === 0 ? null : "usersTypeahead",
    {
      idList: idsTofetch,
    },
  );

  const cacheHydratedUsers = (users: UserOption[]) => {
    const newlyFoundValues = users.reduce((map, user) => {
      map.set(user.value, user);
      return map;
    }, hydratedValuesCache);

    if (newlyFoundValues.size === hydratedValuesCache.size) {
      return;
    }

    setHydratedValuesCache(
      (current) => new Map([...current, ...newlyFoundValues]),
    );
  };

  if (users) {
    cacheHydratedUsers(users.options);
  }

  const getHydratedUser = (
    value?: string | undefined,
  ): UserOption | undefined => {
    if (!value) {
      return undefined;
    }

    return hydratedValuesCache.get(value);
  };

  const removeFromCache = (ids: string[]) => {
    setHydratedValuesCache((current) => {
      ids.forEach((id) => current.delete(id));
      return current;
    });
  };

  return {
    hydrating,
    getHydratedUser,
    cacheHydratedUsers,
    removeFromCache,
  };
};
