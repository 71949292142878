import type { SVGProps } from "react";
import * as React from "react";
const SvgMug = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.91 4.25a.749.749 0 0 1-.222-1.466.69.69 0 0 0 .307-.198.706.706 0 0 0 .168-.399.76.76 0 0 1 .81-.685.75.75 0 0 1 .686.811 2.22 2.22 0 0 1-.54 1.268 2.19 2.19 0 0 1-.986.636.749.749 0 0 1-.222.034H6.91ZM10.16 4.25a.749.749 0 0 1-.222-1.466.69.69 0 0 0 .307-.198.706.706 0 0 0 .168-.399.757.757 0 0 1 .81-.685.75.75 0 0 1 .686.811 2.22 2.22 0 0 1-.54 1.268 2.19 2.19 0 0 1-.986.636.749.749 0 0 1-.222.034h-.001ZM15.66 8h-.9l.052-.598A1.75 1.75 0 0 0 13.068 5.5H4.75a1.75 1.75 0 0 0-1.744 1.901l.747 8.587A2.735 2.735 0 0 0 6.493 18.5h4.833a2.734 2.734 0 0 0 2.739-2.512L14.238 14h.42a2.753 2.753 0 0 0 2.75-2.75v-1.5c0-.965-.784-1.75-1.75-1.75h.002Zm-6.75 6.5a2.5 2.5 0 1 1-.001-5 2.5 2.5 0 0 1 0 5Zm7-3.25c0 .69-.561 1.25-1.25 1.25h-.291l.26-3h1.03a.25.25 0 0 1 .25.25v1.5Z"
    />
  </svg>
);
export default SvgMug;
