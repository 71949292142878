import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Callout, CalloutTheme, LoadingModal, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentsUpdateIncidentTypeRequestBody,
  IncidentType,
  IncidentVisibilityEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { buildIncidentTypeOptions } from "../buildIncidentTypeOptions";

const MODAL_TITLE = "Edit incident type";
export const UpdateIncidentTypeModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: { incident_types: incidentTypes },
    isLoading,
    error,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });
  if (error) {
    return <ErrorModal onClose={onClose} title={MODAL_TITLE} error={error} />;
  }

  if (isLoading) {
    return <LoadingModal isOpen title={MODAL_TITLE} onClose={onClose} />;
  }

  return (
    <IncidentTypeModalContents
      incident={incident}
      incidentTypes={incidentTypes}
      onClose={onClose}
    />
  );
};

const IncidentTypeModalContents = ({
  onClose,
  incident,
  incidentTypes,
}: {
  onClose: () => void;
  incident: Incident;
  incidentTypes: IncidentType[];
}): React.ReactElement => {
  const formMethods = useForm<IncidentsUpdateIncidentTypeRequestBody>({
    defaultValues: { incident_type_id: incident.incident_type?.id },
  });
  const { setError, watch } = formMethods;

  const selectedIncidentTypeID = watch("incident_type_id");

  const { incidentTypeOptions, selectedIncidentType } =
    buildIncidentTypeOptions({
      selectedIncidentTypeID,
      incidentTypes,
      includeNotYetKnownOption: false,
    });

  const refetchStatuses = useAPIRefetch(
    "incidentLifecyclesListStatusesForIncident",
    {
      incidentId: incident.id,
    },
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, data: IncidentsUpdateIncidentTypeRequestBody) => {
      await apiClient.incidentsUpdateIncidentType({
        updateIncidentTypeRequestBody: data,
        id: incident.id,
      });

      // The lifecycle might have changed!
      refetchStatuses();
    },
    { setError, onSuccess: onClose },
  );

  const mustBePrivate =
    selectedIncidentType?.private_incidents_only &&
    incident.visibility !== IncidentVisibilityEnum.Private;

  return (
    <Form.Modal
      formMethods={formMethods}
      onClose={onClose}
      genericError={genericError}
      saving={saving}
      title={MODAL_TITLE}
      analyticsTrackingId="update-incident-type"
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Save"
          saving={saving}
          disabled={mustBePrivate}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <Callout theme={CalloutTheme.Warning}>
        Changing the incident type may trigger automations such as:
        <ul className="list-disc list-inside">
          <li>New workflows running</li>
          <li>New announcement posts being created</li>
          <li>Different custom fields and roles becoming applicable</li>
        </ul>
      </Callout>
      <div>
        <StaticSingleSelectV2
          formMethods={formMethods}
          name={"incident_type_id"}
          options={incidentTypeOptions}
          placeholder="Choose an incident type"
          label="Incident Type"
          isClearable={false}
        />
        <p className="mt-1 text-xs text-slate-700">
          {selectedIncidentType?.description}
        </p>
      </div>
      {mustBePrivate ? (
        <Callout theme={CalloutTheme.Danger}>
          Incidents of this type must be private. Please convert the incident
          Slack channel to be private before changing the incident type.
        </Callout>
      ) : null}
    </Form.Modal>
  );
};
