import {
  RBACRole,
  RoleMappingPayload,
  SCIMShowSettingsResponseBody,
  ScopeNameEnum,
  UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum as BaseRoleSlugEnum,
} from "@incident-io/api";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { CreateEditFormProps } from "@incident-shared/forms/v2/formsv2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { Button, ButtonTheme, ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { isEmpty } from "lodash";
import { useForm, UseFormReturn } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { Form } from "src/components/@shared/forms";
import { useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { RolesSelect, RolesSelectFormData } from "../common/RolesSelect";
import {
  GroupMapping,
  mappingsToFlattenedPayload,
} from "./ScimGroupRoleMappingSection";
import { cleanDirectoryName } from "./ScimShowPageInner";

interface FormData {
  groupId: string;
  base_role_slug: BaseRoleSlugEnum; // the slug of the base role
  custom_role_ids: string[]; // the IDs of the custom roles
}

const roleSlugToId = (roles: RBACRole[], slug: string) => {
  const id = roles.find((r) => r.slug === slug)?.id;
  if (!id) {
    throw new Error(`Role not found for slug ${slug}`);
  }

  return id;
};

const initialFormState = (groupMapping: GroupMapping | undefined): FormData => {
  if (!groupMapping) {
    return {
      groupId: "",
      base_role_slug: BaseRoleSlugEnum.User,
      custom_role_ids: [],
    };
  }

  const selectedBaseRole = groupMapping.roles.find((r) => r.is_base_role);
  if (!selectedBaseRole) {
    throw new Error("group mapping has no base role");
  }
  const baseSlug = selectedBaseRole.slug as BaseRoleSlugEnum;

  const customRoles = groupMapping.roles
    .filter((role) => !role.is_base_role)
    .map((role) => role.id);

  return {
    groupId: groupMapping.groupId,
    base_role_slug: baseSlug,
    custom_role_ids: customRoles,
  };
};

type Props = {
  currentMappings: GroupMapping[];
  onClose: () => void;
  roles: RBACRole[];
  scimConfigState: SCIMShowSettingsResponseBody;
  refetchUsers: () => void;
} & CreateEditFormProps<GroupMapping>;

export const ScimGroupRoleMappingCreateEditModal = ({
  currentMappings,
  initialData: groupMapping,
  onClose,
  scimConfigState,
  roles,
  refetchUsers,
}: Props) => {
  const showToast = useToast();
  const { showArticle } = useIntercom();
  const formMethods = useForm<FormData>({
    defaultValues: initialFormState(groupMapping),
  });

  // Only users with the required scope should be able to edit these settings.
  // But we still want to show the button to other users.
  const { hasScope } = useIdentity();
  const canEditScimSettings = hasScope(ScopeNameEnum.ScimUpdate);
  const submitDisabledHelpText = canEditScimSettings
    ? undefined
    : "You don't have permission to manage SCIM settings.";

  const flattenedExistingMappings = mappingsToFlattenedPayload(currentMappings);

  const [groupId, baseRoleSlug] = formMethods.watch([
    "groupId",
    "base_role_slug",
  ]);

  const { trigger: onSubmit, isMutating: isSubmitting } = useAPIMutation(
    "sCIMShowRoleMappings",
    undefined,
    async (apiClient, data: FormData) => {
      let requestPayload = flattenedExistingMappings;

      // If we're editing a group, remove it's exising entries, before adding them back in with the new values
      if (groupMapping) {
        requestPayload = requestPayload.filter(
          (r) => r.scim_group_id !== groupMapping.groupId,
        );
      }

      requestPayload = [
        ...requestPayload,
        {
          scim_group_id: data.groupId,
          rbac_role_id: roleSlugToId(roles, data.base_role_slug),
        },
      ];

      if (data.custom_role_ids) {
        const customRoleIds = data.custom_role_ids.map(
          (customRoleId): RoleMappingPayload => ({
            scim_group_id: data.groupId,
            rbac_role_id: customRoleId,
          }),
        );
        requestPayload = [...requestPayload, ...customRoleIds];
      }

      return await apiClient.sCIMUpdateRoleMappings({
        updateRoleMappingsRequestBody: {
          role_mappings: requestPayload,
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
        // We should also refetch users at this point
        refetchUsers();
      },
      onError: (err, fieldErrors) => {
        if (isEmpty(fieldErrors ?? {})) {
          throw err;
        } else {
          showToast({
            title: "Error",
            description: fieldErrors?.[0]?.message,
            theme: ToastTheme.Error,
          });
        }
      },
    },
  );

  const directoryName = cleanDirectoryName(
    scimConfigState.scim_config?.directory_type,
  );

  const apiClient = useClient();

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      disableQuickClose={false}
      onClose={onClose}
      title={groupMapping ? `Edit assigned roles` : `Assign roles to group`}
      analyticsTrackingId={
        groupMapping
          ? `edit_scim_group_role_mapping`
          : `create_scim_group_role_mapping`
      }
      footer={
        <ModalFooter
          saving={isSubmitting}
          disabled={!canEditScimSettings || !groupId || !baseRoleSlug}
          disabledTooltipContent={
            canEditScimSettings ? undefined : submitDisabledHelpText
          }
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <DynamicSingleSelectV2
        label={`${directoryName} group`}
        isClearable={false}
        isDisabled={!!groupMapping}
        helptext={
          !groupMapping
            ? `Choose a group that you'd like to assign additional incident.io roles to.`
            : undefined
        }
        filterOption={
          (o) =>
            flattenedExistingMappings
              .map((m) => m.scim_group_id)
              .indexOf(o.value) === -1 // filter out groups that already have a mapping
        }
        loadOptions={getTypeaheadOptions(
          apiClient,
          TypeaheadTypeEnum.ScimGroup,
        )}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.ScimGroup,
        )}
        name={"groupId"}
        formMethods={formMethods}
      />
      {!groupMapping && (
        <p className={"text-xs text-slate-700 mt-3"}>
          Can&apos;t find the group you&apos;re looking for? You may need to
          push that group in {directoryName} - see{" "}
          <Button
            className="!text-xs"
            theme={ButtonTheme.Link}
            onClick={() => showArticle(7182944)}
            analyticsTrackingId="scim-get-help-push-groups"
          >
            our documentation
          </Button>{" "}
          for more details.
        </p>
      )}

      <div className="w-[calc(100%+2rem)] h-px bg-surface-tertiary -mx-4" />
      <RolesSelect
        selectedState={
          // Responder/viewer is not controlled directly by SCIM. However, SCIM
          // can assign base or custom roles which would make someone a
          // responder implicitly. Therefore, this form should pretend that the
          // seat type is responder to unlock all the roles
          "responder"
        }
        userId={null}
        forScim
        formMethods={
          formMethods as unknown as UseFormReturn<RolesSelectFormData>
        }
      />
    </Form.Modal>
  );
};
