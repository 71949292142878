/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentFormEscalationElementBinding,
    IncidentFormEscalationElementBindingFromJSON,
    IncidentFormEscalationElementBindingFromJSONTyped,
    IncidentFormEscalationElementBindingToJSON,
} from './IncidentFormEscalationElementBinding';

/**
 * 
 * @export
 * @interface IncidentFormsGetEscalationElementBindingsResponseBody
 */
export interface IncidentFormsGetEscalationElementBindingsResponseBody {
    /**
     * Elements and their bindings for this incident type
     * @type {Array<IncidentFormEscalationElementBinding>}
     * @memberof IncidentFormsGetEscalationElementBindingsResponseBody
     */
    element_bindings: Array<IncidentFormEscalationElementBinding>;
}

export function IncidentFormsGetEscalationElementBindingsResponseBodyFromJSON(json: any): IncidentFormsGetEscalationElementBindingsResponseBody {
    return IncidentFormsGetEscalationElementBindingsResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsGetEscalationElementBindingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsGetEscalationElementBindingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'element_bindings': ((json['element_bindings'] as Array<any>).map(IncidentFormEscalationElementBindingFromJSON)),
    };
}

export function IncidentFormsGetEscalationElementBindingsResponseBodyToJSON(value?: IncidentFormsGetEscalationElementBindingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'element_bindings': ((value.element_bindings as Array<any>).map(IncidentFormEscalationElementBindingToJSON)),
    };
}

