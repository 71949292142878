/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlackAPISlashRequestBody
 */
export interface SlackAPISlashRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    api_app_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    channel_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    channel_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    command?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    enterprise_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    enterprise_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    is_enterprise_install?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    response_url?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    team_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    team_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    trigger_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackAPISlashRequestBody
     */
    user_name?: string;
}

export function SlackAPISlashRequestBodyFromJSON(json: any): SlackAPISlashRequestBody {
    return SlackAPISlashRequestBodyFromJSONTyped(json, false);
}

export function SlackAPISlashRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackAPISlashRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_app_id': !exists(json, 'api_app_id') ? undefined : json['api_app_id'],
        'channel_id': !exists(json, 'channel_id') ? undefined : json['channel_id'],
        'channel_name': !exists(json, 'channel_name') ? undefined : json['channel_name'],
        'command': !exists(json, 'command') ? undefined : json['command'],
        'enterprise_id': !exists(json, 'enterprise_id') ? undefined : json['enterprise_id'],
        'enterprise_name': !exists(json, 'enterprise_name') ? undefined : json['enterprise_name'],
        'is_enterprise_install': !exists(json, 'is_enterprise_install') ? undefined : json['is_enterprise_install'],
        'response_url': !exists(json, 'response_url') ? undefined : json['response_url'],
        'team_domain': !exists(json, 'team_domain') ? undefined : json['team_domain'],
        'team_id': !exists(json, 'team_id') ? undefined : json['team_id'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'trigger_id': !exists(json, 'trigger_id') ? undefined : json['trigger_id'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
    };
}

export function SlackAPISlashRequestBodyToJSON(value?: SlackAPISlashRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_app_id': value.api_app_id,
        'channel_id': value.channel_id,
        'channel_name': value.channel_name,
        'command': value.command,
        'enterprise_id': value.enterprise_id,
        'enterprise_name': value.enterprise_name,
        'is_enterprise_install': value.is_enterprise_install,
        'response_url': value.response_url,
        'team_domain': value.team_domain,
        'team_id': value.team_id,
        'text': value.text,
        'token': value.token,
        'trigger_id': value.trigger_id,
        'user_id': value.user_id,
        'user_name': value.user_name,
    };
}

