/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemShareMessage
 */
export interface PostmortemShareMessage {
    /**
     * Unique ID for the share message
     * @type {string}
     * @memberof PostmortemShareMessage
     */
    id: string;
    /**
     * ID of the slack channel
     * @type {string}
     * @memberof PostmortemShareMessage
     */
    slack_channel_id: string;
    /**
     * Name of the slack channel
     * @type {string}
     * @memberof PostmortemShareMessage
     */
    slack_channel_name: string;
    /**
     * URLs to the exact slack message sent
     * @type {string}
     * @memberof PostmortemShareMessage
     */
    slack_message_url: string;
}

export function PostmortemShareMessageFromJSON(json: any): PostmortemShareMessage {
    return PostmortemShareMessageFromJSONTyped(json, false);
}

export function PostmortemShareMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemShareMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slack_channel_id': json['slack_channel_id'],
        'slack_channel_name': json['slack_channel_name'],
        'slack_message_url': json['slack_message_url'],
    };
}

export function PostmortemShareMessageToJSON(value?: PostmortemShareMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slack_channel_id': value.slack_channel_id,
        'slack_channel_name': value.slack_channel_name,
        'slack_message_url': value.slack_message_url,
    };
}

