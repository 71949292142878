import { OrgAwareNavigate } from "@incident-shared/org-aware";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPI } from "src/utils/swr";

import { InternalCalendar } from "./Calendar";
import { HeadsUp } from "./HeadsUp/HeadsUp";
import { PageWrapper } from "./PageWrapper";
import { InternalSystemStatus } from "./SystemStatus";

export const InternalStatusPageHomepage = (): React.ReactElement => {
  const { subpath } = useParams() as { subpath?: string };

  const potentialPath = subpath ?? "default";

  // The error and loading states are handled in PageWrapper
  const { data, isLoading } = useAPI("internalStatusPageContentShowPage", {
    subpath: potentialPath,
  });

  const { data: pages } = useAPI("internalStatusPageListPages", {});

  const analytics = useAnalytics();
  useEffect(() => {
    if (!data || !analytics?.track) {
      return;
    }
    analytics.track("internal-status-page--homepage-viewed", {
      ongoingIncidentCount: data?.summary.ongoing_incidents.length,
      subpath,
    });
  }, [analytics, subpath, data]);

  // The customer may no longer have an internal status page with the "default" subpath, in this
  // case we should redirect to one of their other internal status pages.
  if (
    !isLoading &&
    !data &&
    potentialPath === "default" &&
    pages?.internal_status_pages &&
    pages.internal_status_pages.length > 0
  ) {
    return (
      <OrgAwareNavigate
        to={`/status/${pages.internal_status_pages[0].subpath}`}
        replace
      />
    );
  }

  if (isLoading || !data) {
    // The PageWrapper renders the loading state for the summary, so we don't
    // need to inject any children
    return <PageWrapper subpath={subpath}>{null}</PageWrapper>;
  }

  return (
    <PageWrapper subpath={subpath}>
      <Helmet title={`${data.summary.name}`} />

      <HeadsUp summary={data.summary} />
      <InternalSystemStatus summary={data.summary} />
      <InternalCalendar pageId={data.summary.id} />
    </PageWrapper>
  );
};
