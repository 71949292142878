/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertActor
 */
export interface AlertActor {
    /**
     * The description of the alert
     * @type {string}
     * @memberof AlertActor
     */
    description?: string;
    /**
     * Unique ID of the alert
     * @type {string}
     * @memberof AlertActor
     */
    id: string;
    /**
     * The source type of the alert
     * @type {string}
     * @memberof AlertActor
     */
    source_type?: AlertActorSourceTypeEnum;
    /**
     * The originating source of the alert
     * @type {string}
     * @memberof AlertActor
     */
    source_url?: string;
    /**
     * The title of the alert
     * @type {string}
     * @memberof AlertActor
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum AlertActorSourceTypeEnum {
    Alertmanager = 'alertmanager',
    AppOptics = 'app_optics',
    AzureMonitor = 'azure_monitor',
    Bugsnag = 'bugsnag',
    Checkly = 'checkly',
    Chronosphere = 'chronosphere',
    Cloudwatch = 'cloudwatch',
    Cloudflare = 'cloudflare',
    Cronitor = 'cronitor',
    CrowdstrikeFalcon = 'crowdstrike_falcon',
    Datadog = 'datadog',
    Dynatrace = 'dynatrace',
    Elasticsearch = 'elasticsearch',
    Email = 'email',
    Expel = 'expel',
    GithubIssue = 'github_issue',
    GoogleCloud = 'google_cloud',
    Grafana = 'grafana',
    Http = 'http',
    Honeycomb = 'honeycomb',
    IncomingCalls = 'incoming_calls',
    Jira = 'jira',
    MonteCarlo = 'monte_carlo',
    Nagios = 'nagios',
    NewRelic = 'new_relic',
    Opsgenie = 'opsgenie',
    Prtg = 'prtg',
    PagerDuty = 'pager_duty',
    Panther = 'panther',
    Pingdom = 'pingdom',
    Runscope = 'runscope',
    Sns = 'sns',
    Sentry = 'sentry',
    Splunk = 'splunk',
    StatusCake = 'status_cake',
    StatusPageViews = 'status_page_views',
    SumoLogic = 'sumo_logic',
    Uptime = 'uptime',
    Zendesk = 'zendesk'
}

export function AlertActorFromJSON(json: any): AlertActor {
    return AlertActorFromJSONTyped(json, false);
}

export function AlertActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertActor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'source_type': !exists(json, 'source_type') ? undefined : json['source_type'],
        'source_url': !exists(json, 'source_url') ? undefined : json['source_url'],
        'title': json['title'],
    };
}

export function AlertActorToJSON(value?: AlertActor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'id': value.id,
        'source_type': value.source_type,
        'source_url': value.source_url,
        'title': value.title,
    };
}

