<></>;
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ButtonTheme,
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { StatusPage, StatusPageIncident } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const StatusPageIndcidentDeleteModal = ({
  onClose,
  page,
  incident,
}: {
  onClose: () => void;
  page: StatusPage;
  incident: StatusPageIncident;
}) => {
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "statusPageListIncidents",
    { statusPageId: incident.status_page_id },
    async (apiClient) =>
      await apiClient.statusPageDeleteIncident({
        id: incident.id,
      }),
    {
      onSuccess: () => {
        navigate(`/status-pages/${incident.status_page_id}`);
        showToast({
          title: "Incident successfully deleted",
          description:
            "This incident will no longer appear on your public status page",
          theme: ToastTheme.Success,
        });
        onClose();
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: `Could not delete incident`,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const isParent = !!page.split_by_catalog_type_id;

  return (
    <Modal
      analyticsTrackingId={"delete-status-page-incident-modal"}
      as="div"
      onClose={onClose}
      isOpen
      title={"Delete status page incident"}
    >
      <ModalContent className="text-sm space-y-2">
        <>
          {isParent ? (
            incident.sub_pages.length > 1 ? (
              <>
                <p>
                  Are you sure you want to delete{" "}
                  <span className="font-semibold">{incident.name}</span>? It
                  will not be visible on any of the following sub-pages, and any
                  links will no longer work.
                </p>
                <ul className="list-disc ml-4 font-semibold">
                  {incident.sub_pages.map((sp) => (
                    <li key={sp.id}>{sp.name}</li>
                  ))}
                </ul>
                <p>
                  This may be confusing for any subscribers who have received
                  updates for this incident.
                </p>
              </>
            ) : incident.sub_pages.length > 0 ? (
              <>
                <p>
                  Are you sure you want to delete{" "}
                  <span className="font-semibold">{incident.name}</span>? It
                  will not be visible on the{" "}
                  <span className="font-semibold">
                    {incident.sub_pages[0].name}
                  </span>{" "}
                  sub-page, and any links will no longer work.
                </p>
                <p>
                  This may be confusing for any subscribers who have received
                  updates for this incident.
                </p>
              </>
            ) : (
              <p>
                Are you sure you want to delete{" "}
                <span className="font-semibold">{incident.name}</span>?
              </p>
            )
          ) : (
            <>
              <p>
                Are you sure you want to delete{" "}
                <span className="font-semibold">{incident.name}</span>? It will
                not be visible on your public status page, and any links to it
                will no longer work.
              </p>
              <p>
                This may be confusing for any subscribers who have received
                updates for this incident.
              </p>
            </>
          )}
          <Callout theme={CalloutTheme.Warning}>
            You won&apos;t be able to restore this incident once it&apos;s been
            deleted.
          </Callout>
        </>
      </ModalContent>
      <ModalFooter
        confirmButtonType="button"
        confirmButtonTheme={ButtonTheme.Destroy}
        confirmButtonText={"Delete incident"}
        onConfirm={() => onSubmit({})}
        onClose={onClose}
        saving={saving}
      />
    </Modal>
  );
};
