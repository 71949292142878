/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActionsCreateRequestBody,
    ActionsCreateRequestBodyFromJSON,
    ActionsCreateRequestBodyToJSON,
    ActionsCreateResponseBody,
    ActionsCreateResponseBodyFromJSON,
    ActionsCreateResponseBodyToJSON,
    ActionsListResponseBody,
    ActionsListResponseBodyFromJSON,
    ActionsListResponseBodyToJSON,
    ActionsShowResponseBody,
    ActionsShowResponseBodyFromJSON,
    ActionsShowResponseBodyToJSON,
    ActionsUpdateRequestBody,
    ActionsUpdateRequestBodyFromJSON,
    ActionsUpdateRequestBodyToJSON,
    ActionsUpdateResponseBody,
    ActionsUpdateResponseBodyFromJSON,
    ActionsUpdateResponseBodyToJSON,
} from '../models';

export interface ActionsConvertToFollowUpRequest {
    id: string;
}

export interface ActionsCreateRequest {
    createRequestBody: ActionsCreateRequestBody;
}

export interface ActionsDestroyRequest {
    id: string;
}

export interface ActionsListRequest {
    incidentId?: string;
    incidentMode?: ActionsListIncidentModeEnum;
    assigneeId?: string;
}

export interface ActionsShowRequest {
    id: string;
}

export interface ActionsUpdateRequest {
    id: string;
    updateRequestBody: ActionsUpdateRequestBody;
}

/**
 * 
 */
export class ActionsApi extends runtime.BaseAPI {

    /**
     * Convert an action to a follow-up
     * ConvertToFollowUp Actions
     */
    async actionsConvertToFollowUpRaw(requestParameters: ActionsConvertToFollowUpRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling actionsConvertToFollowUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/actions/{id}/actions/convert_to_follow_up`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Convert an action to a follow-up
     * ConvertToFollowUp Actions
     */
    async actionsConvertToFollowUp(requestParameters: ActionsConvertToFollowUpRequest, initOverrides?: RequestInit): Promise<void> {
        await this.actionsConvertToFollowUpRaw(requestParameters, initOverrides);
    }

    /**
     * Creates an incident action
     * Create Actions
     */
    async actionsCreateRaw(requestParameters: ActionsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling actionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActionsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates an incident action
     * Create Actions
     */
    async actionsCreate(requestParameters: ActionsCreateRequest, initOverrides?: RequestInit): Promise<ActionsCreateResponseBody> {
        const response = await this.actionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an incident action
     * Destroy Actions
     */
    async actionsDestroyRaw(requestParameters: ActionsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling actionsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an incident action
     * Destroy Actions
     */
    async actionsDestroy(requestParameters: ActionsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.actionsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List incident actions
     * List Actions
     */
    async actionsListRaw(requestParameters: ActionsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.incidentMode !== undefined) {
            queryParameters['incident_mode'] = requestParameters.incidentMode;
        }

        if (requestParameters.assigneeId !== undefined) {
            queryParameters['assignee_id'] = requestParameters.assigneeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident actions
     * List Actions
     */
    async actionsList(requestParameters: ActionsListRequest, initOverrides?: RequestInit): Promise<ActionsListResponseBody> {
        const response = await this.actionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an action
     * Show Actions
     */
    async actionsShowRaw(requestParameters: ActionsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling actionsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an action
     * Show Actions
     */
    async actionsShow(requestParameters: ActionsShowRequest, initOverrides?: RequestInit): Promise<ActionsShowResponseBody> {
        const response = await this.actionsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an action
     * Update Actions
     */
    async actionsUpdateRaw(requestParameters: ActionsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling actionsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling actionsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ActionsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an action
     * Update Actions
     */
    async actionsUpdate(requestParameters: ActionsUpdateRequest, initOverrides?: RequestInit): Promise<ActionsUpdateResponseBody> {
        const response = await this.actionsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ActionsListIncidentModeEnum {
    Standard = 'standard',
    Retrospective = 'retrospective',
    Test = 'test',
    Tutorial = 'tutorial'
}
