/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostIncidentTask,
    PostIncidentTaskFromJSON,
    PostIncidentTaskFromJSONTyped,
    PostIncidentTaskToJSON,
} from './PostIncidentTask';

/**
 * 
 * @export
 * @interface PostIncidentFlowListTasksResponseBody
 */
export interface PostIncidentFlowListTasksResponseBody {
    /**
     * 
     * @type {Array<PostIncidentTask>}
     * @memberof PostIncidentFlowListTasksResponseBody
     */
    incident_tasks: Array<PostIncidentTask>;
}

export function PostIncidentFlowListTasksResponseBodyFromJSON(json: any): PostIncidentFlowListTasksResponseBody {
    return PostIncidentFlowListTasksResponseBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowListTasksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowListTasksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_tasks': ((json['incident_tasks'] as Array<any>).map(PostIncidentTaskFromJSON)),
    };
}

export function PostIncidentFlowListTasksResponseBodyToJSON(value?: PostIncidentFlowListTasksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_tasks': ((value.incident_tasks as Array<any>).map(PostIncidentTaskToJSON)),
    };
}

