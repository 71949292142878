import { Button, ButtonTheme } from "@incident-ui";
import React from "react";
import { PlanNameEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

export const BillingPlanUpgradeButton = ({
  planName,
  onSelectPlan,
  highlight,
  buttonLabel,
  loading = false,
}: {
  planName: PlanNameEnum;
  onSelectPlan: () => void;
  highlight?: boolean;
  buttonLabel: string;
  loading?: boolean;
}): React.ReactElement => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={"choose-plan"}
      loading={loading}
      analyticsTrackingMetadata={{ plan_name: planName }}
      className={tcx(
        "flex w-full rounded-2 font-sm justify-center py-2 px-6 border border-alarmalade-600 transition",
        highlight
          ? "bg-alarmalade-600 text-white hover:brightness-95"
          : "text-alarmalade-600 bg-white hover:bg-alarmalade-600 hover:text-white",
        "hover:cursor-pointer",
      )}
      onClick={() => onSelectPlan()}
    >
      {buttonLabel}
    </Button>
  );
};
