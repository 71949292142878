import type { SVGProps } from "react";
import * as React from "react";
const SvgIndentationRight = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.25 5.5H3.75a.75.75 0 0 1 0-1.5h12.5a.75.75 0 0 1 0 1.5Zm0 10.5H3.75a.75.75 0 0 1 0-1.5h12.5a.75.75 0 0 1 0 1.5Zm-6.5-5.25h6.5a.75.75 0 0 0 0-1.5h-6.5a.75.75 0 0 0 0 1.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="m6.723 9.565-2.92-1.501a.573.573 0 0 0-.539.006A.487.487 0 0 0 3 8.5V11.5c0 .178.099.338.264.428a.573.573 0 0 0 .538.007l2.92-1.501A.493.493 0 0 0 7 10a.494.494 0 0 0-.277-.435Z"
    />
  </svg>
);
export default SvgIndentationRight;
