/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffStartTrialRequestBody
 */
export interface StaffStartTrialRequestBody {
    /**
     * Which plan the trial is of
     * @type {string}
     * @memberof StaffStartTrialRequestBody
     */
    plan_name: StaffStartTrialRequestBodyPlanNameEnum;
    /**
     * When the trial should end.
     * @type {Date}
     * @memberof StaffStartTrialRequestBody
     */
    trial_end_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum StaffStartTrialRequestBodyPlanNameEnum {
    ProV2Trial = 'pro_v2_trial',
    EnterpriseV2Trial = 'enterprise_v2_trial'
}

export function StaffStartTrialRequestBodyFromJSON(json: any): StaffStartTrialRequestBody {
    return StaffStartTrialRequestBodyFromJSONTyped(json, false);
}

export function StaffStartTrialRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffStartTrialRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plan_name': json['plan_name'],
        'trial_end_at': (new Date(json['trial_end_at'])),
    };
}

export function StaffStartTrialRequestBodyToJSON(value?: StaffStartTrialRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plan_name': value.plan_name,
        'trial_end_at': (value.trial_end_at.toISOString()),
    };
}

