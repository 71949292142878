/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FollowUp,
    FollowUpFromJSON,
    FollowUpFromJSONTyped,
    FollowUpToJSON,
} from './FollowUp';

/**
 * 
 * @export
 * @interface FollowUpsListResponseBody
 */
export interface FollowUpsListResponseBody {
    /**
     * 
     * @type {Array<FollowUp>}
     * @memberof FollowUpsListResponseBody
     */
    follow_ups: Array<FollowUp>;
}

export function FollowUpsListResponseBodyFromJSON(json: any): FollowUpsListResponseBody {
    return FollowUpsListResponseBodyFromJSONTyped(json, false);
}

export function FollowUpsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'follow_ups': ((json['follow_ups'] as Array<any>).map(FollowUpFromJSON)),
    };
}

export function FollowUpsListResponseBodyToJSON(value?: FollowUpsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'follow_ups': ((value.follow_ups as Array<any>).map(FollowUpToJSON)),
    };
}

