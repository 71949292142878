/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedImage,
    EnrichedImageFromJSON,
    EnrichedImageFromJSONTyped,
    EnrichedImageToJSON,
} from './EnrichedImage';
import {
    PostmortemTemplateSection,
    PostmortemTemplateSectionFromJSON,
    PostmortemTemplateSectionFromJSONTyped,
    PostmortemTemplateSectionToJSON,
} from './PostmortemTemplateSection';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemTemplateSectionResponse
 */
export interface PostmortemTemplateSectionResponse {
    /**
     * ID of the postmortem section response
     * @type {string}
     * @memberof PostmortemTemplateSectionResponse
     */
    id: string;
    /**
     * Images that have been uploaded as part of this response, enriched with a URL for viewing them
     * @type {Array<EnrichedImage>}
     * @memberof PostmortemTemplateSectionResponse
     */
    images: Array<EnrichedImage>;
    /**
     * Incident ID
     * @type {string}
     * @memberof PostmortemTemplateSectionResponse
     */
    incident_id: string;
    /**
     * ID of the postmortem template
     * @type {string}
     * @memberof PostmortemTemplateSectionResponse
     */
    postmortem_template_id: string;
    /**
     * ID of the section on the postmortem template
     * @type {string}
     * @memberof PostmortemTemplateSectionResponse
     */
    postmortem_template_section_id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemTemplateSectionResponse
     */
    response: TextNode;
    /**
     * 
     * @type {PostmortemTemplateSection}
     * @memberof PostmortemTemplateSectionResponse
     */
    section: PostmortemTemplateSection;
}

export function PostmortemTemplateSectionResponseFromJSON(json: any): PostmortemTemplateSectionResponse {
    return PostmortemTemplateSectionResponseFromJSONTyped(json, false);
}

export function PostmortemTemplateSectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemTemplateSectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'images': ((json['images'] as Array<any>).map(EnrichedImageFromJSON)),
        'incident_id': json['incident_id'],
        'postmortem_template_id': json['postmortem_template_id'],
        'postmortem_template_section_id': json['postmortem_template_section_id'],
        'response': TextNodeFromJSON(json['response']),
        'section': PostmortemTemplateSectionFromJSON(json['section']),
    };
}

export function PostmortemTemplateSectionResponseToJSON(value?: PostmortemTemplateSectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'images': ((value.images as Array<any>).map(EnrichedImageToJSON)),
        'incident_id': value.incident_id,
        'postmortem_template_id': value.postmortem_template_id,
        'postmortem_template_section_id': value.postmortem_template_section_id,
        'response': TextNodeToJSON(value.response),
        'section': PostmortemTemplateSectionToJSON(value.section),
    };
}

