/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleWorkspaceCalendar,
    GoogleWorkspaceCalendarFromJSON,
    GoogleWorkspaceCalendarFromJSONTyped,
    GoogleWorkspaceCalendarToJSON,
} from './GoogleWorkspaceCalendar';

/**
 * 
 * @export
 * @interface IntegrationsGoogleWorkspaceGetCalendarsResponseBody
 */
export interface IntegrationsGoogleWorkspaceGetCalendarsResponseBody {
    /**
     * 
     * @type {Array<GoogleWorkspaceCalendar>}
     * @memberof IntegrationsGoogleWorkspaceGetCalendarsResponseBody
     */
    calendars: Array<GoogleWorkspaceCalendar>;
}

export function IntegrationsGoogleWorkspaceGetCalendarsResponseBodyFromJSON(json: any): IntegrationsGoogleWorkspaceGetCalendarsResponseBody {
    return IntegrationsGoogleWorkspaceGetCalendarsResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsGoogleWorkspaceGetCalendarsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGoogleWorkspaceGetCalendarsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendars': ((json['calendars'] as Array<any>).map(GoogleWorkspaceCalendarFromJSON)),
    };
}

export function IntegrationsGoogleWorkspaceGetCalendarsResponseBodyToJSON(value?: IntegrationsGoogleWorkspaceGetCalendarsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendars': ((value.calendars as Array<any>).map(GoogleWorkspaceCalendarToJSON)),
    };
}

