import { StatusPageContentSummaryLocaleEnum as Locale } from "@incident-io/api";
export { StatusPageContentSummaryLocaleEnum as Locale } from "@incident-io/api";

export const dictionaries: {
  [key in Locale]: () => Promise<{ default: IntlMessages }>;
} = {
  [Locale.EnUs]: () => import("./dictionaries/en-US"),
  [Locale.PtBr]: () => import("./dictionaries/pt-BR"),
  [Locale.JaJp]: () => import("./dictionaries/ja-JP"),
};
