import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { IconEnum } from "@incident-ui";
import { Outlet, Route, Routes, useParams } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { BillingStaffOrgPage } from "src/components/staff-room/BillingStaffOrgPage";
import { OrganisationSearch } from "src/components/staff-room/OrganisationSearch";
import { StaffLandingPage } from "src/components/staff-room/StaffLandingPage";
import { useIdentity } from "src/contexts/IdentityContext";

export const StaffRoomRoute = () => {
  const { identity } = useIdentity();
  const perms = identity?.staff_permissions ?? [];

  if (perms.length === 0) {
    return <NotFoundPage />;
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/:targetSlug" element={<BillingStaffOrgPage />} />
        <Route path="/" element={<StaffLandingPage />} />
      </Route>
    </Routes>
  );
};

const Layout = () => {
  const { targetSlug } = useParams();

  return (
    <PageWrapper
      icon={IconEnum.Billing}
      width={PageWidth.Narrow}
      title="Staff room"
      accessory={<OrganisationSearch />}
      backHref={targetSlug ? "/staff-room" : undefined}
      subtitle={targetSlug}
    >
      <div className="space-y-6">
        <Outlet />
      </div>
    </PageWrapper>
  );
};
