/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RemindersAvailableTargetsResponseBody,
    RemindersAvailableTargetsResponseBodyFromJSON,
    RemindersAvailableTargetsResponseBodyToJSON,
    RemindersCreateRequestBody,
    RemindersCreateRequestBodyFromJSON,
    RemindersCreateRequestBodyToJSON,
} from '../models';

export interface RemindersAvailableTargetsRequest {
    reminderType: RemindersAvailableTargetsReminderTypeEnum;
    resourceIds: Array<string>;
}

export interface RemindersCreateRequest {
    createRequestBody: RemindersCreateRequestBody;
}

/**
 * 
 */
export class RemindersApi extends runtime.BaseAPI {

    /**
     * Get a list of available targets that reminders can use.
     * AvailableTargets Reminders
     */
    async remindersAvailableTargetsRaw(requestParameters: RemindersAvailableTargetsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RemindersAvailableTargetsResponseBody>> {
        if (requestParameters.reminderType === null || requestParameters.reminderType === undefined) {
            throw new runtime.RequiredError('reminderType','Required parameter requestParameters.reminderType was null or undefined when calling remindersAvailableTargets.');
        }

        if (requestParameters.resourceIds === null || requestParameters.resourceIds === undefined) {
            throw new runtime.RequiredError('resourceIds','Required parameter requestParameters.resourceIds was null or undefined when calling remindersAvailableTargets.');
        }

        const queryParameters: any = {};

        if (requestParameters.reminderType !== undefined) {
            queryParameters['reminder_type'] = requestParameters.reminderType;
        }

        if (requestParameters.resourceIds) {
            queryParameters['resource_ids'] = requestParameters.resourceIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/reminders/available_reminder_targets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemindersAvailableTargetsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a list of available targets that reminders can use.
     * AvailableTargets Reminders
     */
    async remindersAvailableTargets(requestParameters: RemindersAvailableTargetsRequest, initOverrides?: RequestInit): Promise<RemindersAvailableTargetsResponseBody> {
        const response = await this.remindersAvailableTargetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates reminders for users to complete follow-ups and other tasks.
     * Create Reminders
     */
    async remindersCreateRaw(requestParameters: RemindersCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling remindersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/reminders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemindersCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates reminders for users to complete follow-ups and other tasks.
     * Create Reminders
     */
    async remindersCreate(requestParameters: RemindersCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.remindersCreateRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum RemindersAvailableTargetsReminderTypeEnum {
    FollowUp = 'follow-up',
    PostIncidentTask = 'post-incident-task'
}
