import type { SVGProps } from "react";
import * as React from "react";
const SvgStar = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.25 7.25h-5.029l-1.506-4.728a.75.75 0 0 0-1.43 0L7.78 7.25H2.75a.75.75 0 0 0-.457 1.345l4.011 3.08-1.577 4.843a.747.747 0 0 0 1.152.839L10 14.371l4.12 2.986a.75.75 0 0 0 1.152-.84l-1.577-4.843 4.011-3.08a.75.75 0 0 0-.457-1.345l.002.001Z"
    />
  </svg>
);
export default SvgStar;
