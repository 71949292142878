import { Modal, ModalContent } from "@incident-ui/Modal";
import {
  FooterContents,
  FooterProps,
  FooterWithConfirmButtonProps,
} from "@incident-ui/Modal/ModalFooter";
import { tcx } from "src/utils/tailwind-classes";

export type ConfirmationDialogFooterProps = Omit<
  FooterProps,
  "onClose" | "children" | "analyticsTrackingId"
> &
  Omit<FooterWithConfirmButtonProps, "confirmButtonType" | "children">;

export type ConfirmationDialogProps = {
  onCancel: () => void;
  title: string;
  confirmButtonText?: string;
  analyticsTrackingId?: string | null;
  isOpen: boolean;
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
} & ConfirmationDialogFooterProps;

export const ConfirmationDialog = ({
  onCancel,
  analyticsTrackingId,
  confirmButtonText = "Confirm",
  title,
  isOpen,
  children,
  className,
  loading,
  ...footerProps
}: ConfirmationDialogProps): React.ReactElement => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      analyticsTrackingId={analyticsTrackingId || null}
      title={title}
      loading={loading}
      hideHeader
      className={tcx("!my-48 !max-w-[400px]", className)}
    >
      <ModalContent className="p-5">
        <div className="pb-5 flex flex-col gap-2">
          <div className="text-content-primary text-base-bold">{title}</div>
          <div className="text-content-secondary text-sm-normal">
            {children}
          </div>
        </div>
        <div className="flex items-center gap-3">
          <FooterContents
            analyticsTrackingId={analyticsTrackingId || undefined}
            onClose={onCancel}
            confirmButtonText={confirmButtonText}
            {...footerProps}
            confirmButtonType="button"
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
