import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { HeaderBar, HeaderBarProps } from "../HeaderBar/HeaderBar";

export type SecondaryNavSubPageWrapperProps = {
  headerClassName?: string;
  wrapperClassName?: string;
  children: React.ReactNode;
} & Omit<HeaderBarProps, "className">;

export const SecondaryNavSubPageWrapper = ({
  children,
  headerClassName,
  wrapperClassName,
  ...props
}: SecondaryNavSubPageWrapperProps): React.ReactElement => {
  return (
    <div className="overflow-auto">
      <HeaderBar
        {...props}
        className={tcx("px-4 md:px-8 max-h-[105px]", headerClassName)}
      />
      <div
        className={tcx(
          "px-8 py-6 max-w-full flex flex-col gap-6",
          wrapperClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
