import {
  AnnouncementPostActionParam,
  AnnouncementPostFieldParamWithPreview,
  AnnouncementPostFieldParamWithPreviewFieldTypeEnum as FieldTypeEnum,
} from "@incident-io/api";
import {
  Icon,
  IconEnum,
  IconSize,
  SlackButtonPreview,
  SlackMessagePreview,
  Txt,
} from "@incident-ui";
import { parseISO } from "date-fns";
import _ from "lodash";
import React from "react";

import { GetUnicodeForSlackEmoji } from "../../../../utils/slack";

export const AnnouncementPostPreview = ({
  postFields,
  postActions,
}: {
  postFields: AnnouncementPostFieldParamWithPreview[];
  postActions: AnnouncementPostActionParam[];
}): React.ReactElement => {
  const sortedPostFields = _.sortBy(postFields, "rank");
  let sortedPostActions = _.sortBy(postActions, "rank");

  let haveTruncated = 0;
  if (sortedPostActions.length > 5) {
    haveTruncated = sortedPostActions.length - 5;
    sortedPostActions = sortedPostActions.slice(0, 4);
  }

  return (
    <SlackMessagePreview className="min-h-[116px]">
      <Txt className="flex items-center space-x-1 !my-0.5">
        <span className="font-['lato'] font-bold"> </span>
      </Txt>
      <div>
        <Txt className="flex items-center space-x-1 !my-0.5">
          <span className="font-['lato'] !text-xl">🚨</span>
          <span className="font-['lato'] font-bold !text-xl">
            We&apos;ve lost the office dog
          </span>
        </Txt>
      </div>
      {sortedPostFields &&
        sortedPostFields.map((postField) => {
          return (
            <AnnouncementPreviewField key={postField.id} field={postField} />
          );
        })}
      <HorizontalDivider />
      <div className="flex flex-row justify-items-start gap-2">
        {sortedPostActions &&
          sortedPostActions.map((postAction) => {
            return (
              <AnnouncementPreviewButton
                key={postAction.id}
                button={postAction}
              />
            );
          })}
        {haveTruncated > 0 && (
          <SlackButtonPreview key="and_more" text={`+ ${haveTruncated} more`} />
        )}
      </div>
    </SlackMessagePreview>
  );
};

export const AnnouncementPreviewField = ({
  field,
}: {
  field: AnnouncementPostFieldParamWithPreview;
}): React.ReactElement => {
  const renderFieldValue = () => {
    switch (field.field_type) {
      case FieldTypeEnum.Slack:
        return <FakeSlackChannelPill />;
      case FieldTypeEnum.Timestamp:
        return parseISO(field.preview_example).toLocaleString();
      case FieldTypeEnum.CustomField:
        return FakeMarkdownPill(field.preview_example);
      default:
        return field.preview_example;
    }
  };
  if (field.field_type === FieldTypeEnum.Description) {
    return (
      <div>
        <p className="flex items-center space-x-1 !my-0.5 text-sm">
          <span className="font-['lato']">{field.preview_example} </span>
        </p>
        <HorizontalDivider />
      </div>
    );
  }
  return (
    <Txt className="flex items-center space-x-1 !my-0.5 text-sm">
      {field.emoji === "slack" ? (
        <Icon id={IconEnum.Slack} className="mx-0" size={IconSize.Small} />
      ) : (
        <span className="font-['lato'] text-2xl">
          {field.emoji && GetUnicodeForSlackEmoji(field.emoji)}
        </span>
      )}
      <span className="font-['lato'] font-bold">{field.title}: </span>
      <span className="font-['lato']">{renderFieldValue()}</span>
    </Txt>
  );
};

const AnnouncementPreviewButton = ({
  button,
}: {
  button: AnnouncementPostActionParam;
}): React.ReactElement => {
  return (
    <>
      {button.emoji === "slack" ? (
        <SlackButtonPreview
          key={button.id}
          icon={IconEnum.Slack}
          text={button.title || button.action_type}
        />
      ) : (
        <SlackButtonPreview
          key={button.id}
          emoji={button.emoji}
          text={button.title || button.action_type}
        />
      )}
    </>
  );
};

const HorizontalDivider = () => (
  <div className="flex-row bg-slate-300 h-[1px] !my-4"></div>
);

const FakeSlackChannelPill = () => {
  return (
    <span className="bg-[#1d9bd11a] text-[#1264a3] p-0.5">
      #inc-123-we-lost-the-office-dog
    </span>
  );
};

const FakeMarkdownPill = (preview: string) => {
  return (
    <>
      {preview.charAt(0) === "`" ? (
        <span className="bg-[#EFEFEF] text-[#e03165] border-stroke border-[0.01rem] rounded p-0.5">
          {preview.slice(1, -1)}
        </span>
      ) : (
        preview
      )}
    </>
  );
};
