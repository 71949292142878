import { IconEnum, Tooltip } from "@incident-ui";
import { IssueTrackerIssueSyncFailure } from "src/contexts/ClientContext";

export const SyncFailureTooltip = ({
  syncFailures,
  children,
}: {
  syncFailures: IssueTrackerIssueSyncFailure[];
  children: React.ReactElement;
}): React.ReactElement => {
  const latestFailure = syncFailures[0];

  return (
    <Tooltip
      content={
        <div>
          {latestFailure.is_transient ? (
            <p className="!mb-0">
              We failed to create this{" "}
              <span className="capitalize">{latestFailure.provider}</span>{" "}
              ticket the first time around, but we&apos;ll keep retrying.
            </p>
          ) : (
            <>
              <p className="!mb-2">
                Click to view the issue template, and fix any issues.
              </p>
              <p className="!mb-1">
                Latest error returned from{" "}
                <span className="capitalize">{latestFailure.provider}</span>:
              </p>
              <p className="!mb-0">
                <code>{latestFailure.raw_error_json}</code>
              </p>
            </>
          )}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export const ProviderIconLookup: Record<string, IconEnum> = {
  ["jira"]: IconEnum.Jira,
};
