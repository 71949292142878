import type { SVGProps } from "react";
import * as React from "react";
const SvgArrowRight = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16 10.75H3.75a.75.75 0 0 1 0-1.5H16a.75.75 0 0 1 0 1.5Z"
    />
    <path
      fill="currentColor"
      d="M12 15a.75.75 0 0 1-.704-1.009c.782-2.12 2.12-3.328 3.181-3.99-1.061-.662-2.398-1.87-3.181-3.99a.749.749 0 1 1 1.407-.519c1.129 3.061 3.629 3.756 3.734 3.784a.752.752 0 0 1-.004 1.453c-.102.027-2.601.722-3.73 3.783a.75.75 0 0 1-.704.49L12 15Z"
    />
  </svg>
);
export default SvgArrowRight;
