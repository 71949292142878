/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogRelation,
    CatalogRelationFromJSON,
    CatalogRelationFromJSONTyped,
    CatalogRelationToJSON,
} from './CatalogRelation';

/**
 * 
 * @export
 * @interface CatalogListRelationsResponseBody
 */
export interface CatalogListRelationsResponseBody {
    /**
     * 
     * @type {Array<CatalogRelation>}
     * @memberof CatalogListRelationsResponseBody
     */
    catalog_relations: Array<CatalogRelation>;
}

export function CatalogListRelationsResponseBodyFromJSON(json: any): CatalogListRelationsResponseBody {
    return CatalogListRelationsResponseBodyFromJSONTyped(json, false);
}

export function CatalogListRelationsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListRelationsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_relations': ((json['catalog_relations'] as Array<any>).map(CatalogRelationFromJSON)),
    };
}

export function CatalogListRelationsResponseBodyToJSON(value?: CatalogListRelationsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_relations': ((value.catalog_relations as Array<any>).map(CatalogRelationToJSON)),
    };
}

