/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimestampValuePayload,
    IncidentTimestampValuePayloadFromJSON,
    IncidentTimestampValuePayloadFromJSONTyped,
    IncidentTimestampValuePayloadToJSON,
} from './IncidentTimestampValuePayload';

/**
 * 
 * @export
 * @interface IncidentsUpdateTimestampsRequestBody
 */
export interface IncidentsUpdateTimestampsRequestBody {
    /**
     * A list of timestamps for this incident
     * @type {Array<IncidentTimestampValuePayload>}
     * @memberof IncidentsUpdateTimestampsRequestBody
     */
    incident_timestamps: Array<IncidentTimestampValuePayload>;
}

export function IncidentsUpdateTimestampsRequestBodyFromJSON(json: any): IncidentsUpdateTimestampsRequestBody {
    return IncidentsUpdateTimestampsRequestBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateTimestampsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateTimestampsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_timestamps': ((json['incident_timestamps'] as Array<any>).map(IncidentTimestampValuePayloadFromJSON)),
    };
}

export function IncidentsUpdateTimestampsRequestBodyToJSON(value?: IncidentsUpdateTimestampsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_timestamps': ((value.incident_timestamps as Array<any>).map(IncidentTimestampValuePayloadToJSON)),
    };
}

