/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntry,
    CustomFieldEntryFromJSON,
    CustomFieldEntryFromJSONTyped,
    CustomFieldEntryToJSON,
} from './CustomFieldEntry';

/**
 * 
 * @export
 * @interface IncidentsUpdateCustomFieldEntriesResponseBody
 */
export interface IncidentsUpdateCustomFieldEntriesResponseBody {
    /**
     * 
     * @type {Array<CustomFieldEntry>}
     * @memberof IncidentsUpdateCustomFieldEntriesResponseBody
     */
    custom_field_entries: Array<CustomFieldEntry>;
}

export function IncidentsUpdateCustomFieldEntriesResponseBodyFromJSON(json: any): IncidentsUpdateCustomFieldEntriesResponseBody {
    return IncidentsUpdateCustomFieldEntriesResponseBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateCustomFieldEntriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateCustomFieldEntriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryFromJSON)),
    };
}

export function IncidentsUpdateCustomFieldEntriesResponseBodyToJSON(value?: IncidentsUpdateCustomFieldEntriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryToJSON)),
    };
}

