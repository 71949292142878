import type { SVGProps } from "react";
import * as React from "react";
const SvgBookmark = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.844 3H7.156A2.408 2.408 0 0 0 4.75 5.406v10.938a.656.656 0 0 0 1.02.546L10 14.07l4.23 2.82a.66.66 0 0 0 .673.032.656.656 0 0 0 .347-.578V5.406A2.408 2.408 0 0 0 12.844 3Z"
    />
  </svg>
);
export default SvgBookmark;
