import {
  IncidentsShowResponseBody,
  IncidentVisibilityEnum,
} from "@incident-io/api";
import { differenceInSeconds } from "date-fns";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { useIncident } from "../hooks";

const refreshEagerlyForTenSeconds =
  (intervalMs: number) => (inc: IncidentsShowResponseBody | undefined) => {
    if (inc?.incident?.created_at == null) {
      return intervalMs;
    }

    // first arg is the later date, second is the earlier
    const diff = differenceInSeconds(new Date(), inc.incident.created_at);
    if (diff < 10) {
      return 1000;
    } else {
      return intervalMs;
    }
  };

// This hooks wraps around core API calls that
// we want to keep up to date while the user
// is on the incident page.
//
// Some calls will ignore `intervalMs` for the first
// 10 seconds of an incidents life.
export const useIncidentKeyDetailsRefresh = ({
  incidentId,
  intervalMs = 10000,
}: {
  incidentId: string | null;
  intervalMs?: number;
}) => {
  const { incident } = useIncident(incidentId);
  const { hasResponse } = useProductAccess();
  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  const { mutate: revalidateIncident, isValidating: incidentValidating } =
    useAPI(
      incidentId == null ? null : "incidentsShow",
      { id: incidentId ?? "" },
      {
        refreshInterval: refreshEagerlyForTenSeconds(intervalMs),
      },
    );
  const { mutate: revalidateStatuses, isValidating: statusesValidating } =
    useAPI(
      incidentId == null ? null : "incidentLifecyclesListStatusesForIncident",
      { incidentId: incidentId ?? "" },
      { refreshInterval: intervalMs },
    );
  const { mutate: revalidateMemberships, isValidating: membershipsValidating } =
    useAPI(
      isPrivate ? "incidentMembershipsList" : null,
      {
        incidentId: incidentId ?? "",
      },
      { refreshInterval: intervalMs },
    );
  const {
    mutate: revalidatePolicyViolations,
    isValidating: policyViolationsValidating,
  } = useAPI(
    incidentId == null ? null : "policiesListViolations",
    { incidentIds: [incidentId ?? ""] },
    { refreshInterval: intervalMs },
  );
  const { mutate: revalidateDebriefs, isValidating: debriefsValidating } =
    useAPI(
      incidentId == null || !hasResponse
        ? null
        : "debriefsListIncidentDebriefs",
      { incidentId: incidentId ?? "" },
      { refreshInterval: intervalMs },
    );

  const revalidateTabData = useRevalidate(
    hasResponse
      ? [
          "incidentAttachmentsList",
          "incidentTimelineListActivityLog",
          "incidentTimelineListTimelineItems",
          "incidentUpdatesListForIncident",
          "alertsListIncidentAlerts",
          "actionsList",
          "followUpsList",
          "postIncidentFlowListTasks",
        ]
      : [
          "incidentAttachmentsList",
          "incidentTimelineListActivityLog",
          "incidentTimelineListTimelineItems",
          "incidentUpdatesListForIncident",
          "alertsListIncidentAlerts",
        ],
  );

  return {
    isValidating:
      incidentValidating ||
      statusesValidating ||
      membershipsValidating ||
      policyViolationsValidating ||
      debriefsValidating,
    revalidate: async () => {
      await Promise.all([
        revalidateIncident(),
        revalidateStatuses(),
        revalidateMemberships(),
        revalidatePolicyViolations(),
        revalidateDebriefs(),
        revalidateTabData(),
      ]);
    },
  };
};
