/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsJiraServerGetConfigResponseBody,
    IntegrationsJiraServerGetConfigResponseBodyFromJSON,
    IntegrationsJiraServerGetConfigResponseBodyToJSON,
    IntegrationsJiraServerReceiveWebhookRequestBody,
    IntegrationsJiraServerReceiveWebhookRequestBodyFromJSON,
    IntegrationsJiraServerReceiveWebhookRequestBodyToJSON,
    IntegrationsJiraServerSetBasicRequestBody,
    IntegrationsJiraServerSetBasicRequestBodyFromJSON,
    IntegrationsJiraServerSetBasicRequestBodyToJSON,
    IntegrationsJiraServerSetHostRequestBody,
    IntegrationsJiraServerSetHostRequestBodyFromJSON,
    IntegrationsJiraServerSetHostRequestBodyToJSON,
    IntegrationsJiraServerUpdateAutoExportSettingsRequestBody,
    IntegrationsJiraServerUpdateAutoExportSettingsRequestBodyFromJSON,
    IntegrationsJiraServerUpdateAutoExportSettingsRequestBodyToJSON,
    IntegrationsJiraServerUpdateAutoExportSettingsResponseBody,
    IntegrationsJiraServerUpdateAutoExportSettingsResponseBodyFromJSON,
    IntegrationsJiraServerUpdateAutoExportSettingsResponseBodyToJSON,
} from '../models';

export interface IntegrationsJiraServerHandleRedirectRequest {
    organisationId: string;
    oauthToken?: string;
    oauthVerifier?: string;
}

export interface IntegrationsJiraServerReceiveWebhookRequest {
    organisationId: string;
    receiveWebhookRequestBody: IntegrationsJiraServerReceiveWebhookRequestBody;
    userId?: string;
    userKey?: string;
}

export interface IntegrationsJiraServerSetBasicRequest {
    setBasicRequestBody: IntegrationsJiraServerSetBasicRequestBody;
}

export interface IntegrationsJiraServerSetHostRequest {
    setHostRequestBody: IntegrationsJiraServerSetHostRequestBody;
}

export interface IntegrationsJiraServerUpdateAutoExportSettingsRequest {
    updateAutoExportSettingsRequestBody: IntegrationsJiraServerUpdateAutoExportSettingsRequestBody;
}

/**
 * 
 */
export class IntegrationsJiraServerApi extends runtime.BaseAPI {

    /**
     * Show settings for Jira Server to configure the Application Link in Jira
     * GetConfig Integrations - Jira Server
     */
    async integrationsJiraServerGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsJiraServerGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/jira_server/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsJiraServerGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show settings for Jira Server to configure the Application Link in Jira
     * GetConfig Integrations - Jira Server
     */
    async integrationsJiraServerGetConfig(initOverrides?: RequestInit): Promise<IntegrationsJiraServerGetConfigResponseBody> {
        const response = await this.integrationsJiraServerGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Jira Server redirect
     * HandleRedirect Integrations - Jira Server
     */
    async integrationsJiraServerHandleRedirectRaw(requestParameters: IntegrationsJiraServerHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsJiraServerHandleRedirect.');
        }

        const queryParameters: any = {};

        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }

        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/jira_server`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Jira Server redirect
     * HandleRedirect Integrations - Jira Server
     */
    async integrationsJiraServerHandleRedirect(requestParameters: IntegrationsJiraServerHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsJiraServerHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Receive a webhook from Jira Server
     * ReceiveWebhook Integrations - Jira Server
     */
    async integrationsJiraServerReceiveWebhookRaw(requestParameters: IntegrationsJiraServerReceiveWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsJiraServerReceiveWebhook.');
        }

        if (requestParameters.receiveWebhookRequestBody === null || requestParameters.receiveWebhookRequestBody === undefined) {
            throw new runtime.RequiredError('receiveWebhookRequestBody','Required parameter requestParameters.receiveWebhookRequestBody was null or undefined when calling integrationsJiraServerReceiveWebhook.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.userKey !== undefined) {
            queryParameters['user_key'] = requestParameters.userKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/webhooks/jira_server/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsJiraServerReceiveWebhookRequestBodyToJSON(requestParameters.receiveWebhookRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Receive a webhook from Jira Server
     * ReceiveWebhook Integrations - Jira Server
     */
    async integrationsJiraServerReceiveWebhook(requestParameters: IntegrationsJiraServerReceiveWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsJiraServerReceiveWebhookRaw(requestParameters, initOverrides);
    }

    /**
     * Set Jira Server basic auth credentials
     * SetBasic Integrations - Jira Server
     */
    async integrationsJiraServerSetBasicRaw(requestParameters: IntegrationsJiraServerSetBasicRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setBasicRequestBody === null || requestParameters.setBasicRequestBody === undefined) {
            throw new runtime.RequiredError('setBasicRequestBody','Required parameter requestParameters.setBasicRequestBody was null or undefined when calling integrationsJiraServerSetBasic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/jira_server/config/actions/set_basic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsJiraServerSetBasicRequestBodyToJSON(requestParameters.setBasicRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Jira Server basic auth credentials
     * SetBasic Integrations - Jira Server
     */
    async integrationsJiraServerSetBasic(requestParameters: IntegrationsJiraServerSetBasicRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsJiraServerSetBasicRaw(requestParameters, initOverrides);
    }

    /**
     * Set Jira Server host
     * SetHost Integrations - Jira Server
     */
    async integrationsJiraServerSetHostRaw(requestParameters: IntegrationsJiraServerSetHostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setHostRequestBody === null || requestParameters.setHostRequestBody === undefined) {
            throw new runtime.RequiredError('setHostRequestBody','Required parameter requestParameters.setHostRequestBody was null or undefined when calling integrationsJiraServerSetHost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/jira_server/config/actions/set_host`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsJiraServerSetHostRequestBodyToJSON(requestParameters.setHostRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Jira Server host
     * SetHost Integrations - Jira Server
     */
    async integrationsJiraServerSetHost(requestParameters: IntegrationsJiraServerSetHostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsJiraServerSetHostRaw(requestParameters, initOverrides);
    }

    /**
     * Update the auto-export config for Jira Server
     * UpdateAutoExportSettings Integrations - Jira Server
     */
    async integrationsJiraServerUpdateAutoExportSettingsRaw(requestParameters: IntegrationsJiraServerUpdateAutoExportSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsJiraServerUpdateAutoExportSettingsResponseBody>> {
        if (requestParameters.updateAutoExportSettingsRequestBody === null || requestParameters.updateAutoExportSettingsRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutoExportSettingsRequestBody','Required parameter requestParameters.updateAutoExportSettingsRequestBody was null or undefined when calling integrationsJiraServerUpdateAutoExportSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/jira_server/config/actions/update_auto_export`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsJiraServerUpdateAutoExportSettingsRequestBodyToJSON(requestParameters.updateAutoExportSettingsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsJiraServerUpdateAutoExportSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the auto-export config for Jira Server
     * UpdateAutoExportSettings Integrations - Jira Server
     */
    async integrationsJiraServerUpdateAutoExportSettings(requestParameters: IntegrationsJiraServerUpdateAutoExportSettingsRequest, initOverrides?: RequestInit): Promise<IntegrationsJiraServerUpdateAutoExportSettingsResponseBody> {
        const response = await this.integrationsJiraServerUpdateAutoExportSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
