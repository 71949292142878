import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  BadgeTheme,
  EmptyState,
  Heading,
  IconEnum,
  Loader,
  ToastTheme,
} from "@incident-ui";
import { CollapsibleStackedList } from "@incident-ui/StackedList/CollapsibleStackedList";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { compareDesc, isAfter } from "date-fns";
import _ from "lodash";
import React from "react";
import { SubmitHandler } from "react-hook-form";
import { useIntegrations } from "src/hooks/useIntegrations";

import { SettingsListItem } from "../../../components/@shared/settings/SettingsList/SettingsListItem";
import { parseSimpleDate } from "../../../components/legacy/on-call-legacy/ReportGenerator";
import { SettingsSubHeading } from "../../../components/settings/SettingsSubHeading";
import {
  Identity,
  IntegrationSettingsProviderEnum,
  ScheduleReport,
  ScopeNameEnum,
} from "../../../contexts/ClientContext";
import { useIdentity } from "../../../contexts/IdentityContext";
import { useAPI, useAPIMutation } from "../../../utils/swr";

export const OnCallReportsListRoute = (): React.ReactElement => {
  const { identity } = useIdentity();
  const toast = useToast();

  const { integrations } = useIntegrations();

  const { data, error: reportsError } = useAPI(
    "schedulesListReport",
    undefined,
  );
  const reports = data?.schedule_reports;
  if (reportsError) {
    throw reportsError;
  }

  const { trigger: onDelete } = useAPIMutation(
    "schedulesListReport",
    undefined,
    async (apiClient, report: ScheduleReport) => {
      await apiClient.schedulesDestroyReport({ id: report.id });
    },
    {
      onSuccess: () => {
        toast({
          title: "Deleted report",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        toast({
          title: "Failed to delete report, please try again",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  if (!reports || !integrations) {
    return <Loader />;
  }

  const publishedReports = reports.filter((r) => !!r.published_at);
  const draftReports = getDraftReports(reports, identity);

  const pagerDutyIntegration = integrations.find(
    (x) => x.provider === IntegrationSettingsProviderEnum.Pagerduty,
  );
  const opsgenieIntegration = integrations.find(
    (x) => x.provider === IntegrationSettingsProviderEnum.Opsgenie,
  );
  const PDRequiresReconnect = !!pagerDutyIntegration?.reconnection_reason;
  const OGRequiresReconnect = !!opsgenieIntegration?.reconnection_reason;

  const accessory = (
    <GatedButton
      analyticsTrackingId="generate-report"
      href="/on-call/pay-calculator/reports/create"
      requiredScope={ScopeNameEnum.ScheduleReportsCreate}
      disabled={
        PDRequiresReconnect ||
        OGRequiresReconnect ||
        !identity.feature_gates.on_call_calculator
      }
      disabledTooltipContent={
        !identity.feature_gates.on_call_calculator
          ? "You do not have access to create on-call pay reports"
          : `You must reconnect your ${
              PDRequiresReconnect ? "PagerDuty" : "Opsgenie"
            } integration before creating new reports.`
      }
    >
      New report
    </GatedButton>
  );

  return (
    <>
      <SettingsSubHeading
        title="Reports"
        explanation={
          <span className="text-content-tertiary">
            Reports enable you to see who is owed what depending on their time
            spent on-call.
          </span>
        }
        accessory={accessory}
      />
      <div className="space-y-6">
        {draftReports.length !== 0 && (
          <div>
            <Heading level={2}>Drafts</Heading>
            {/* You can always delete your own reports */}
            <ReportList reports={draftReports} onDelete={onDelete} />
          </div>
        )}
        <div>
          {publishedReports.length === 0 && draftReports.length === 0 ? (
            <EmptyState
              icon={IconEnum.Doc}
              content="You haven't created any reports yet."
            />
          ) : (
            <>
              <Heading level={2} className="mb-4">
                Published reports
              </Heading>
              {publishedReports.length === 0 ? (
                <EmptyState
                  icon={IconEnum.Doc}
                  content="You haven't published any reports yet."
                />
              ) : (
                <ReportList
                  reports={publishedReports}
                  onDelete={() => {
                    throw new Error("cannot delete published reports");
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const getDraftReports = (
  reports: ScheduleReport[],
  identity: Identity | null,
): ScheduleReport[] => {
  let draftReports = reports.filter(
    (r) =>
      r.creator.id === identity?.user_id && // created by the same user
      !r.published_at, // not published
  );

  draftReports.sort((a, b) => (isAfter(b.updated_at, a.updated_at) ? 1 : -1)); // sort by most recently updated (descending)
  draftReports = _.uniqBy(draftReports, (report) => report.name);

  return draftReports;
};

const ReportList = ({
  reports,
  onDelete,
}: {
  reports: ScheduleReport[];
  onDelete: SubmitHandler<ScheduleReport>;
}) => {
  const sortedReports = reports.sort((a, b) =>
    compareDesc(a.created_at, b.created_at),
  );
  return (
    <CollapsibleStackedList<ScheduleReport>
      analyticsTrackingId="on-call-reports-list"
      className="mt-4"
      defaultNumberToShow={5}
      items={sortedReports}
      renderItem={(report: ScheduleReport) => {
        return (
          <ReportListItem
            key={report.id}
            report={report}
            onDelete={() => onDelete(report)}
          />
        );
      }}
    />
  );
};

const ReportListItem = ({
  report,
  onDelete,
}: {
  report: ScheduleReport;
  onDelete: () => Promise<unknown> | unknown;
}) => {
  // We'll probably set names whenever we 'publish' a report, which would make
  // it appear in the list results.
  const name =
    report.name ||
    [
      parseSimpleDate(report.start_date).toLocaleDateString(),
      parseSimpleDate(report.end_date).toLocaleDateString(),
    ].join("-");

  const description = [
    `${report.schedules.length} schedules`,
    `${report.users.length} users`,
    `Created by ${report.creator.name}`,
  ].join(", ");

  return (
    <SettingsListItem
      title={name}
      badgeProps={
        report.published_at
          ? undefined
          : { label: "Draft", theme: BadgeTheme.Info }
      }
      description={description}
      buttons={{
        view: {
          viewHref: `/on-call/pay-calculator/reports/${report.id}`,
        },
        delete: {
          resourceTitle: name,
          requiredScope: ScopeNameEnum.ScheduleReportsDestroy,
          onDelete,
          isGatedText: report.published_at
            ? "Cannot delete published reports"
            : undefined,
          deleteConfirmationTitle: `Delete report "${name}"`,
          deleteConfirmationContent: (
            <>
              Are you sure you want to delete the{" "}
              <span className="font-bold">{name}</span> report? It will continue
              to be accessible by old links, but won&apos;t be listed in the
              dashboard.
            </>
          ),
        },
      }}
    />
  );
};
