import {
  ConfigureDrawerProps,
  IntegrationConfigFor,
  IntegrationListProvider,
  SCIM_PROVIDER_CONFIGS,
  SCIMProviderEnum,
} from "@incident-shared/integrations";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  LoadingBar,
} from "@incident-ui";
import { useIntercom } from "react-use-intercom";
import {
  resolveScimSetupStep,
  ScimSetupStep,
} from "src/components/settings/users/scim/ScimShowPage";
import { useAPI } from "src/utils/swr";

import { GenericConfigureDrawerContents } from "../IntegrationDrawer";

type InstallState = "connected" | "partial" | "not-connected";

export const SCIMAndSamlIntegrationDrawer = (props: ConfigureDrawerProps) => {
  return (
    <GenericConfigureDrawerContents {...props}>
      <SCIMAndSamlIntegrationDrawerContents
        provider={props.integration.provider as SCIMProviderEnum}
      />
    </GenericConfigureDrawerContents>
  );
};

export const SCIMAndSamlIntegrationDrawerContents = ({
  provider,
}: {
  provider: SCIMProviderEnum;
}) => {
  return (
    <div className="flex flex-col gap-6">
      <SCIMAndSAMLCards provider={provider} />
    </div>
  );
};

const scimSetupStepToInstallStates: {
  [key in ScimSetupStep]: { scimState: InstallState; teamsState: InstallState };
} = {
  [ScimSetupStep.Installed]: {
    scimState: "connected",
    teamsState: "connected",
  },
  [ScimSetupStep.PartiallyInstalled]: {
    scimState: "partial",
    teamsState: "connected",
  },
  [ScimSetupStep.NeedsOwnerAssignment]: {
    scimState: "partial",
    teamsState: "connected",
  },
  [ScimSetupStep.Uninstalled]: {
    scimState: "not-connected",
    teamsState: "not-connected",
  },
};

const SCIMAndSAMLCards = ({
  provider,
}: {
  provider: IntegrationListProvider;
}) => {
  const config = IntegrationConfigFor(provider);

  const {
    loading,
    error,
    scimState,
    samlState,
    teamsState,
    scimProvider,
    samlProvider,
  } = useScimAndSamlProviders();

  if (loading) {
    return <LoadingBar />;
  }
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <div className="flex flex-col gap-4">
      <SCIMOrSAMLIntegrationCard
        title="Single sign-on (SAML)"
        description={`Allow users to login to incident.io via ${config.label}`}
        featureName="SAML"
        installState={samlState}
        provider={samlProvider}
        providerMatches={samlProvider === provider}
        learnMoreArticle={6991102}
        configurePath="/settings/security"
      />

      <SCIMOrSAMLIntegrationCard
        title="Automatic user provisioning (SCIM)"
        description={`Automatically provision users and control their permissions from ${config.label}`}
        featureName="SCIM"
        configurePath="/settings/users/scim"
        learnMoreArticle={7182944}
        provider={scimProvider}
        providerMatches={scimProvider === provider}
        installState={scimState}
      />
      <SCIMOrSAMLIntegrationCard
        title="Manage teams in Catalog (SCIM)"
        description={`Power automations across incident.io using teams managed in ${config.label}`}
        featureName="Teams"
        configurePath="/catalog?category=team"
        learnMoreArticle={9627083}
        provider={scimProvider}
        providerMatches={scimProvider === provider}
        installState={teamsState}
      />
    </div>
  );
};

const SCIMOrSAMLIntegrationCard = ({
  learnMoreArticle,
  configurePath,
  installState,
  title,
  description,
  featureName,
  provider,
  providerMatches,
}: {
  learnMoreArticle: number;
  configurePath: string;
  installState: InstallState;
  title: string;
  description: string;
  featureName: string;
  provider: SCIMProviderEnum | undefined;
  providerMatches: boolean;
}) => {
  return (
    <ContentBox className="flex flex-col gap-4 p-4">
      <div className="flex flex-col gap-2">
        <div className="text-sm-bold">{title}</div>
        <div className="text-sm-normal">{description}</div>
      </div>
      <div className="flex items-center gap-4">
        <SCIMOrSAMLCardFooter
          {...{
            learnMoreArticle,
            configurePath,
            installState,
            featureName,
            provider,
            providerMatches,
          }}
        />
      </div>
    </ContentBox>
  );
};

const SCIMOrSAMLCardFooter = ({
  learnMoreArticle,
  configurePath,
  installState,
  featureName,
  provider,
  providerMatches,
}: {
  learnMoreArticle: number;
  configurePath: string;
  installState: InstallState;
  featureName: string;
  provider: SCIMProviderEnum | undefined;
  providerMatches: boolean;
}) => {
  const { showArticle } = useIntercom();

  if (installState === "not-connected") {
    return (
      <>
        <Button
          analyticsTrackingId={`connect-${featureName.toLocaleLowerCase()}`}
          href={configurePath}
        >
          Connect {featureName}
        </Button>
        <Button
          analyticsTrackingId={`learn-more-${featureName.toLocaleLowerCase()}`}
          theme={ButtonTheme.Naked}
          onClick={() => showArticle(learnMoreArticle)}
        >
          Learn more
        </Button>
      </>
    );
  }

  if (!providerMatches) {
    const providerLabel = provider
      ? IntegrationConfigFor(provider)?.label
      : null;

    return (
      <>
        <Badge
          theme={BadgeTheme.Tertiary}
          icon={IconEnum.Tick}
          size={BadgeSize.Large}
        >
          {providerLabel
            ? `Connected via ${providerLabel}`
            : "Connected in another provider"}
        </Badge>
        <Button
          analyticsTrackingId={`manage-${featureName.toLocaleLowerCase()}`}
          theme={ButtonTheme.Naked}
          href={configurePath}
        >
          Manage {featureName}
        </Button>
      </>
    );
  }

  return (
    <>
      <ConnectedBadge installState={installState} />
      <Button
        analyticsTrackingId={`manage-${featureName.toLocaleLowerCase()}`}
        theme={ButtonTheme.Naked}
        href={configurePath}
      >
        Manage {featureName}
      </Button>
    </>
  );
};

// eslint-disable-next-line consistent-return
const ConnectedBadge = ({ installState }: { installState: InstallState }) => {
  switch (installState) {
    case "connected":
      return (
        <Badge
          theme={BadgeTheme.Success}
          icon={IconEnum.Tick}
          size={BadgeSize.Large}
        >
          Connected
        </Badge>
      );
    case "partial":
      return (
        <Badge
          theme={BadgeTheme.Warning}
          icon={IconEnum.DottedCircle}
          size={BadgeSize.Large}
        >
          Part connected
        </Badge>
      );
  }
  return null;
};

export const useScimAndSamlProviders = () => {
  const {
    data: samlConfigState,
    isLoading: samlLoading,
    error: samlError,
  } = useAPI("sAMLShow", undefined);

  const {
    data: scimConfigState,
    isLoading: scimLoading,
    error: scimError,
  } = useAPI("sCIMShowSettings", undefined);

  const hasCompleteSaml =
    samlConfigState?.saml_settings !== undefined &&
    samlConfigState?.saml_settings.has_connection &&
    samlConfigState?.saml_settings.connection_state === "active";

  let samlState: InstallState = "not-connected";
  if (samlConfigState?.enabled) {
    samlState = hasCompleteSaml ? "connected" : "partial";
  }

  const scimSetupStep = scimConfigState
    ? resolveScimSetupStep(scimConfigState)
    : ScimSetupStep.Uninstalled;

  const { scimState, teamsState } = scimSetupStepToInstallStates[scimSetupStep];

  let scimProvider,
    samlProvider: SCIMProviderEnum | undefined = undefined;
  Object.entries(SCIM_PROVIDER_CONFIGS).forEach(([provider, scimConfig]) => {
    const { samlWorkOSProvider, scimWorkOSProvider } = scimConfig;
    if (
      samlWorkOSProvider === samlConfigState?.saml_settings?.connection_type
    ) {
      samlProvider = provider as SCIMProviderEnum;
    }
    if (scimWorkOSProvider === scimConfigState?.scim_config?.directory_type) {
      scimProvider = provider as SCIMProviderEnum;
    }
  });

  return {
    loading: samlLoading || scimLoading,
    error: samlError || scimError,
    samlState,
    scimState,
    teamsState,
    scimProvider,
    samlProvider,
  };
};
