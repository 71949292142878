import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Loader, Modal, ModalContent, ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useParams } from "react-router-dom";
import { useAPI, useAPIMutation } from "src/utils/swr";

export const UnsubscribeFromIncidentModal = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const { incidentId: incidentId } = useParams() as { incidentId: string };

  const { data: incident, isLoading: loading } = useAPI("incidentsShow", {
    id: incidentId,
  });

  const { trigger: onConfirm, isMutating: saving } = useAPIMutation(
    "incidentSubscriptionsGet",
    { incidentId: incidentId },
    async (apiClient) =>
      await apiClient.incidentSubscriptionsUnsubscribe({
        incidentId: incidentId,
      }),
    {
      onSuccess: () => {
        showToast({
          title: "Successfully unsubscribed",
          theme: ToastTheme.Success,
        });
        navigate("/user-preferences");
      },
    },
  );

  if (loading || !incident) {
    return <Loader />;
  }

  return (
    <Modal
      isOpen={true}
      title={`Unsubscribe from incident`}
      analyticsTrackingId="unsubscribe-from-incident"
      onClose={() => {
        navigate("/user-preferences");
      }}
      disableQuickClose={false}
    >
      <ModalContent>
        <p className="pb-4">
          Unsubscribe from{" "}
          <span className="font-medium">
            {incident.incident.reference} {incident.incident.name}
          </span>
          ?
        </p>
        <p>
          You won&apos;t receive any more notifications about this incident.
        </p>
        <p>
          You can further configure your notification settings in your user
          preferences.
        </p>
      </ModalContent>
      <ModalFooter
        analyticsTrackingId={"unsubscribe-from-incident"}
        onConfirm={() => onConfirm({})}
        onClose={() => {
          navigate("/user-preferences");
        }}
        confirmButtonType="button"
        confirmButtonText={"Unsubscribe"}
        saving={saving}
      />
    </Modal>
  );
};
