/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface ConditionsEvaluatedTimelineData
 */
export interface ConditionsEvaluatedTimelineData {
    /**
     * The conditions that were evaluated
     * @type {Array<ConditionGroup>}
     * @memberof ConditionsEvaluatedTimelineData
     */
    conditions: Array<ConditionGroup>;
    /**
     * Whether the escalation conditions were matched
     * @type {string}
     * @memberof ConditionsEvaluatedTimelineData
     */
    outcome: ConditionsEvaluatedTimelineDataOutcomeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ConditionsEvaluatedTimelineDataOutcomeEnum {
    False = 'condition_false',
    True = 'condition_true',
    Failed = 'condition_failed'
}

export function ConditionsEvaluatedTimelineDataFromJSON(json: any): ConditionsEvaluatedTimelineData {
    return ConditionsEvaluatedTimelineDataFromJSONTyped(json, false);
}

export function ConditionsEvaluatedTimelineDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionsEvaluatedTimelineData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': ((json['conditions'] as Array<any>).map(ConditionGroupFromJSON)),
        'outcome': json['outcome'],
    };
}

export function ConditionsEvaluatedTimelineDataToJSON(value?: ConditionsEvaluatedTimelineData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': ((value.conditions as Array<any>).map(ConditionGroupToJSON)),
        'outcome': value.outcome,
    };
}

