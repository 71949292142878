/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoJoinSlackSettings,
    AutoJoinSlackSettingsFromJSON,
    AutoJoinSlackSettingsFromJSONTyped,
    AutoJoinSlackSettingsToJSON,
} from './AutoJoinSlackSettings';

/**
 * 
 * @export
 * @interface SettingsUpdateAutoJoinSlackRequestBody
 */
export interface SettingsUpdateAutoJoinSlackRequestBody {
    /**
     * 
     * @type {AutoJoinSlackSettings}
     * @memberof SettingsUpdateAutoJoinSlackRequestBody
     */
    auto_join_slack?: AutoJoinSlackSettings;
}

export function SettingsUpdateAutoJoinSlackRequestBodyFromJSON(json: any): SettingsUpdateAutoJoinSlackRequestBody {
    return SettingsUpdateAutoJoinSlackRequestBodyFromJSONTyped(json, false);
}

export function SettingsUpdateAutoJoinSlackRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdateAutoJoinSlackRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_join_slack': !exists(json, 'auto_join_slack') ? undefined : AutoJoinSlackSettingsFromJSON(json['auto_join_slack']),
    };
}

export function SettingsUpdateAutoJoinSlackRequestBodyToJSON(value?: SettingsUpdateAutoJoinSlackRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_join_slack': AutoJoinSlackSettingsToJSON(value.auto_join_slack),
    };
}

