/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ReportsListRequest {
    successOnId?: string;
}

export interface ReportsTriggerRequest {
    id: string;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * List enabled reports
     * List Reports
     */
    async reportsListRaw(requestParameters: ReportsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.successOnId !== undefined) {
            queryParameters['success_on_id'] = requestParameters.successOnId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * List enabled reports
     * List Reports
     */
    async reportsList(requestParameters: ReportsListRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.reportsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger a report to be sent
     * Trigger Reports
     */
    async reportsTriggerRaw(requestParameters: ReportsTriggerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reportsTrigger.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/reports/trigger`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Trigger a report to be sent
     * Trigger Reports
     */
    async reportsTrigger(requestParameters: ReportsTriggerRequest, initOverrides?: RequestInit): Promise<void> {
        await this.reportsTriggerRaw(requestParameters, initOverrides);
    }

}
