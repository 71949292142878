import { Heading } from "@incident-ui";
import { HeadingLevel, HeadingProps } from "@incident-ui/Heading/Heading";
import styles from "src/components/settings/SettingsPage.module.scss";
import { tcx } from "src/utils/tailwind-classes";

export const SettingsSubHeading = ({
  title,
  titleHeadingLevel,
  titleHeadingSize,
  titleDataIntercomTarget,
  explanation,
  explanationClassName = "mr-4 mt-1 max-w-2xl",
  accessory,
  className,
  id,
}: {
  title?: React.ReactNode;
  titleHeadingLevel?: HeadingLevel;
  titleHeadingSize?: HeadingProps["size"];
  titleDataIntercomTarget?: string;
  explanation?: React.ReactNode;
  explanationClassName?: string;
  accessory?: React.ReactNode;
  className?: string;
  id?: string;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "mb-4 px-2 sm:px-0",
        // if there's no explanation, we want to put the button in-line with the heading, rather than underneath it.
        { "flex-between": !explanation },
        className,
      )}
      id={id}
    >
      <Heading
        level={titleHeadingLevel ?? 2}
        size={titleHeadingSize ?? "medium"}
        data-intercom-target={titleDataIntercomTarget}
        className="shrink-0"
      >
        {title}
      </Heading>
      <div
        className={`${styles.headingExplanationWrapper} text-sm text-slate-700 leading-6 flex-between w-full`}
      >
        <div className={explanationClassName}>{explanation}</div>
        {accessory && <div className={`shrink-0`}>{accessory}</div>}
      </div>
    </div>
  );
};
