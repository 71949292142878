/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageListNewlyAffectedSubPagesRequestBody
 */
export interface StatusPageListNewlyAffectedSubPagesRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof StatusPageListNewlyAffectedSubPagesRequestBody
     */
    affected_component_ids: Array<string>;
    /**
     * The parent status page which incidents are posted to.
     * @type {string}
     * @memberof StatusPageListNewlyAffectedSubPagesRequestBody
     */
    parent_status_page_id: string;
    /**
     * If provided, this endpoint will only return sub-pages which will be added by the update. That excludes sub-pages which are already linked, or which were previously linked and have been unlinked.
     * @type {string}
     * @memberof StatusPageListNewlyAffectedSubPagesRequestBody
     */
    status_page_incident_id?: string;
}

export function StatusPageListNewlyAffectedSubPagesRequestBodyFromJSON(json: any): StatusPageListNewlyAffectedSubPagesRequestBody {
    return StatusPageListNewlyAffectedSubPagesRequestBodyFromJSONTyped(json, false);
}

export function StatusPageListNewlyAffectedSubPagesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageListNewlyAffectedSubPagesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affected_component_ids': json['affected_component_ids'],
        'parent_status_page_id': json['parent_status_page_id'],
        'status_page_incident_id': !exists(json, 'status_page_incident_id') ? undefined : json['status_page_incident_id'],
    };
}

export function StatusPageListNewlyAffectedSubPagesRequestBodyToJSON(value?: StatusPageListNewlyAffectedSubPagesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affected_component_ids': value.affected_component_ids,
        'parent_status_page_id': value.parent_status_page_id,
        'status_page_incident_id': value.status_page_incident_id,
    };
}

