import { Icon, IconEnum, IconSize } from "@incident-ui";
import { IncidentStatus, PostIncidentFlow } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

export const PostIncidentFlowPreview = ({
  flow,
  truncateAfter = 5,
  className,
}: {
  flow: PostIncidentFlow;
  truncateAfter?: number;
  className?: string;
}) => {
  let statuses = flow.incident_statuses;

  if (statuses.length > truncateAfter) {
    statuses = [
      statuses[0],
      { ...statuses[1], name: "..." },
      statuses[statuses.length - 1],
    ];
  }
  return (
    <div
      className={tcx(
        "flex border border-stroke bg-surface-secondary rounded-full relative w-fit font-medium text-slate-700",
        className,
      )}
    >
      {statuses.map((status, idx) => (
        <IncidentStatusItem
          key={status.id}
          status={status}
          index={idx}
          length={statuses.length}
        />
      ))}
    </div>
  );
};

const IncidentStatusItem = ({
  status,
  index,
  length,
}: {
  status: IncidentStatus;
  index: number;
  length: number;
}): React.ReactElement => {
  const isLast = index === length - 1;
  const isFirst = index === 0;

  const showChevron = !isLast;

  return (
    <div className="flex items-center text-xs max-w-[150px] h-6">
      <div
        className={tcx("flex-center-y truncate", {
          "ml-3": isFirst,
          "mr-3": isLast,
        })}
      >
        <p>{status.name}</p>
      </div>
      {showChevron && (
        <Icon
          id={IconEnum.StatusChevron}
          className="text-slate-300"
          size={IconSize.Large}
        />
      )}
    </div>
  );
};
