/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PostmortemsAttachDocumentRequestBody,
    PostmortemsAttachDocumentRequestBodyFromJSON,
    PostmortemsAttachDocumentRequestBodyToJSON,
    PostmortemsAttachDocumentResponseBody,
    PostmortemsAttachDocumentResponseBodyFromJSON,
    PostmortemsAttachDocumentResponseBodyToJSON,
    PostmortemsCreateDestinationRequestBody,
    PostmortemsCreateDestinationRequestBodyFromJSON,
    PostmortemsCreateDestinationRequestBodyToJSON,
    PostmortemsCreateDestinationResponseBody,
    PostmortemsCreateDestinationResponseBodyFromJSON,
    PostmortemsCreateDestinationResponseBodyToJSON,
    PostmortemsCreateLegacyTemplateRequestBody,
    PostmortemsCreateLegacyTemplateRequestBodyFromJSON,
    PostmortemsCreateLegacyTemplateRequestBodyToJSON,
    PostmortemsCreateLegacyTemplateResponseBody,
    PostmortemsCreateLegacyTemplateResponseBodyFromJSON,
    PostmortemsCreateLegacyTemplateResponseBodyToJSON,
    PostmortemsCreateTemplateRequestBody,
    PostmortemsCreateTemplateRequestBodyFromJSON,
    PostmortemsCreateTemplateRequestBodyToJSON,
    PostmortemsCreateTemplateResponseBody,
    PostmortemsCreateTemplateResponseBodyFromJSON,
    PostmortemsCreateTemplateResponseBodyToJSON,
    PostmortemsCreateTemplateResponseRequestBody,
    PostmortemsCreateTemplateResponseRequestBodyFromJSON,
    PostmortemsCreateTemplateResponseRequestBodyToJSON,
    PostmortemsCreateTemplateResponseResponseBody,
    PostmortemsCreateTemplateResponseResponseBodyFromJSON,
    PostmortemsCreateTemplateResponseResponseBodyToJSON,
    PostmortemsCreateTemplateSectionRequestBody,
    PostmortemsCreateTemplateSectionRequestBodyFromJSON,
    PostmortemsCreateTemplateSectionRequestBodyToJSON,
    PostmortemsCreateTemplateSectionResponseBody,
    PostmortemsCreateTemplateSectionResponseBodyFromJSON,
    PostmortemsCreateTemplateSectionResponseBodyToJSON,
    PostmortemsExportDocumentRequestBody,
    PostmortemsExportDocumentRequestBodyFromJSON,
    PostmortemsExportDocumentRequestBodyToJSON,
    PostmortemsExportDocumentResponseBody,
    PostmortemsExportDocumentResponseBodyFromJSON,
    PostmortemsExportDocumentResponseBodyToJSON,
    PostmortemsListDestinationsResponseBody,
    PostmortemsListDestinationsResponseBodyFromJSON,
    PostmortemsListDestinationsResponseBodyToJSON,
    PostmortemsListDocumentsResponseBody,
    PostmortemsListDocumentsResponseBodyFromJSON,
    PostmortemsListDocumentsResponseBodyToJSON,
    PostmortemsListLegacyTemplateStandardContentBlocksResponseBody,
    PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyFromJSON,
    PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyToJSON,
    PostmortemsListLegacyTemplatesResponseBody,
    PostmortemsListLegacyTemplatesResponseBodyFromJSON,
    PostmortemsListLegacyTemplatesResponseBodyToJSON,
    PostmortemsListSuggestedTemplatesResponseBody,
    PostmortemsListSuggestedTemplatesResponseBodyFromJSON,
    PostmortemsListSuggestedTemplatesResponseBodyToJSON,
    PostmortemsListTemplateResponsesResponseBody,
    PostmortemsListTemplateResponsesResponseBodyFromJSON,
    PostmortemsListTemplateResponsesResponseBodyToJSON,
    PostmortemsListTemplatesResponseBody,
    PostmortemsListTemplatesResponseBodyFromJSON,
    PostmortemsListTemplatesResponseBodyToJSON,
    PostmortemsRenderMarkdownRequestBody,
    PostmortemsRenderMarkdownRequestBodyFromJSON,
    PostmortemsRenderMarkdownRequestBodyToJSON,
    PostmortemsRenderMarkdownResponseBody,
    PostmortemsRenderMarkdownResponseBodyFromJSON,
    PostmortemsRenderMarkdownResponseBodyToJSON,
    PostmortemsRenderRequestBody,
    PostmortemsRenderRequestBodyFromJSON,
    PostmortemsRenderRequestBodyToJSON,
    PostmortemsRenderResponseBody,
    PostmortemsRenderResponseBodyFromJSON,
    PostmortemsRenderResponseBodyToJSON,
    PostmortemsSetDocumentStatusRequestBody,
    PostmortemsSetDocumentStatusRequestBodyFromJSON,
    PostmortemsSetDocumentStatusRequestBodyToJSON,
    PostmortemsSetDocumentStatusResponseBody,
    PostmortemsSetDocumentStatusResponseBodyFromJSON,
    PostmortemsSetDocumentStatusResponseBodyToJSON,
    PostmortemsShowDocumentResponseBody,
    PostmortemsShowDocumentResponseBodyFromJSON,
    PostmortemsShowDocumentResponseBodyToJSON,
    PostmortemsShowLegacyTemplateResponseBody,
    PostmortemsShowLegacyTemplateResponseBodyFromJSON,
    PostmortemsShowLegacyTemplateResponseBodyToJSON,
    PostmortemsShowTemplateResponseBody,
    PostmortemsShowTemplateResponseBodyFromJSON,
    PostmortemsShowTemplateResponseBodyToJSON,
    PostmortemsUpdateDestinationRequestBody,
    PostmortemsUpdateDestinationRequestBodyFromJSON,
    PostmortemsUpdateDestinationRequestBodyToJSON,
    PostmortemsUpdateDestinationResponseBody,
    PostmortemsUpdateDestinationResponseBodyFromJSON,
    PostmortemsUpdateDestinationResponseBodyToJSON,
    PostmortemsUpdateLegacyTemplateRequestBody,
    PostmortemsUpdateLegacyTemplateRequestBodyFromJSON,
    PostmortemsUpdateLegacyTemplateRequestBodyToJSON,
    PostmortemsUpdateLegacyTemplateResponseBody,
    PostmortemsUpdateLegacyTemplateResponseBodyFromJSON,
    PostmortemsUpdateLegacyTemplateResponseBodyToJSON,
    PostmortemsUpdateStatusRequestBody,
    PostmortemsUpdateStatusRequestBodyFromJSON,
    PostmortemsUpdateStatusRequestBodyToJSON,
    PostmortemsUpdateTemplateRequestBody,
    PostmortemsUpdateTemplateRequestBodyFromJSON,
    PostmortemsUpdateTemplateRequestBodyToJSON,
    PostmortemsUpdateTemplateResponseBody,
    PostmortemsUpdateTemplateResponseBodyFromJSON,
    PostmortemsUpdateTemplateResponseBodyToJSON,
    PostmortemsUpdateTemplateResponseRequestBody,
    PostmortemsUpdateTemplateResponseRequestBodyFromJSON,
    PostmortemsUpdateTemplateResponseRequestBodyToJSON,
    PostmortemsUpdateTemplateResponseResponseBody,
    PostmortemsUpdateTemplateResponseResponseBodyFromJSON,
    PostmortemsUpdateTemplateResponseResponseBodyToJSON,
    PostmortemsUpdateTemplateSectionRanksRequestBody,
    PostmortemsUpdateTemplateSectionRanksRequestBodyFromJSON,
    PostmortemsUpdateTemplateSectionRanksRequestBodyToJSON,
    PostmortemsUpdateTemplateSectionRanksResponseBody,
    PostmortemsUpdateTemplateSectionRanksResponseBodyFromJSON,
    PostmortemsUpdateTemplateSectionRanksResponseBodyToJSON,
    PostmortemsUpdateTemplateSectionRequestBody,
    PostmortemsUpdateTemplateSectionRequestBodyFromJSON,
    PostmortemsUpdateTemplateSectionRequestBodyToJSON,
    PostmortemsUpdateTemplateSectionResponseBody,
    PostmortemsUpdateTemplateSectionResponseBodyFromJSON,
    PostmortemsUpdateTemplateSectionResponseBodyToJSON,
} from '../models';

export interface PostmortemsAttachDocumentRequest {
    attachDocumentRequestBody: PostmortemsAttachDocumentRequestBody;
}

export interface PostmortemsCreateDestinationRequest {
    createDestinationRequestBody: PostmortemsCreateDestinationRequestBody;
}

export interface PostmortemsCreateLegacyTemplateRequest {
    createLegacyTemplateRequestBody: PostmortemsCreateLegacyTemplateRequestBody;
}

export interface PostmortemsCreateTemplateRequest {
    createTemplateRequestBody: PostmortemsCreateTemplateRequestBody;
}

export interface PostmortemsCreateTemplateResponseRequest {
    incidentId: string;
    postmortemTemplateId: string;
    createTemplateResponseRequestBody: PostmortemsCreateTemplateResponseRequestBody;
}

export interface PostmortemsCreateTemplateSectionRequest {
    createTemplateSectionRequestBody: PostmortemsCreateTemplateSectionRequestBody;
}

export interface PostmortemsDestroyDestinationRequest {
    id: string;
}

export interface PostmortemsDestroyDocumentRequest {
    id: string;
}

export interface PostmortemsDestroyLegacyTemplateRequest {
    id: string;
}

export interface PostmortemsDestroyTemplateRequest {
    id: string;
}

export interface PostmortemsDestroyTemplateSectionRequest {
    id: string;
}

export interface PostmortemsExportDocumentRequest {
    exportDocumentRequestBody: PostmortemsExportDocumentRequestBody;
}

export interface PostmortemsListDocumentsRequest {
    incidentId: string;
}

export interface PostmortemsListLegacyTemplatesRequest {
    incidentId?: string;
}

export interface PostmortemsListTemplateResponsesRequest {
    incidentId: string;
    postmortemTemplateId: string;
}

export interface PostmortemsListTemplatesRequest {
    incidentId?: string;
}

export interface PostmortemsRenderRequest {
    renderRequestBody: PostmortemsRenderRequestBody;
}

export interface PostmortemsRenderMarkdownRequest {
    renderMarkdownRequestBody: PostmortemsRenderMarkdownRequestBody;
}

export interface PostmortemsSetDocumentStatusRequest {
    id: string;
    setDocumentStatusRequestBody: PostmortemsSetDocumentStatusRequestBody;
}

export interface PostmortemsShowDocumentRequest {
    id: string;
}

export interface PostmortemsShowLegacyTemplateRequest {
    id: string;
}

export interface PostmortemsShowTemplateRequest {
    id: string;
    incidentId?: string;
}

export interface PostmortemsUpdateDestinationRequest {
    id: string;
    updateDestinationRequestBody: PostmortemsUpdateDestinationRequestBody;
}

export interface PostmortemsUpdateLegacyTemplateRequest {
    id: string;
    updateLegacyTemplateRequestBody: PostmortemsUpdateLegacyTemplateRequestBody;
}

export interface PostmortemsUpdateStatusRequest {
    updateStatusRequestBody: PostmortemsUpdateStatusRequestBody;
}

export interface PostmortemsUpdateTemplateRequest {
    id: string;
    updateTemplateRequestBody: PostmortemsUpdateTemplateRequestBody;
}

export interface PostmortemsUpdateTemplateForIncidentRequest {
    incidentId: string;
    postmortemTemplateId: string;
}

export interface PostmortemsUpdateTemplateResponseRequest {
    responseId: string;
    updateTemplateResponseRequestBody: PostmortemsUpdateTemplateResponseRequestBody;
}

export interface PostmortemsUpdateTemplateSectionRequest {
    id: string;
    updateTemplateSectionRequestBody: PostmortemsUpdateTemplateSectionRequestBody;
}

export interface PostmortemsUpdateTemplateSectionRanksRequest {
    id: string;
    updateTemplateSectionRanksRequestBody: PostmortemsUpdateTemplateSectionRanksRequestBody;
}

/**
 * 
 */
export class PostmortemsApi extends runtime.BaseAPI {

    /**
     * Attaches a user-created post-mortem document to an incident
     * AttachDocument Postmortems
     */
    async postmortemsAttachDocumentRaw(requestParameters: PostmortemsAttachDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsAttachDocumentResponseBody>> {
        if (requestParameters.attachDocumentRequestBody === null || requestParameters.attachDocumentRequestBody === undefined) {
            throw new runtime.RequiredError('attachDocumentRequestBody','Required parameter requestParameters.attachDocumentRequestBody was null or undefined when calling postmortemsAttachDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/documents/actions/attach`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsAttachDocumentRequestBodyToJSON(requestParameters.attachDocumentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsAttachDocumentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Attaches a user-created post-mortem document to an incident
     * AttachDocument Postmortems
     */
    async postmortemsAttachDocument(requestParameters: PostmortemsAttachDocumentRequest, initOverrides?: RequestInit): Promise<PostmortemsAttachDocumentResponseBody> {
        const response = await this.postmortemsAttachDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a postmortem destination
     * CreateDestination Postmortems
     */
    async postmortemsCreateDestinationRaw(requestParameters: PostmortemsCreateDestinationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateDestinationResponseBody>> {
        if (requestParameters.createDestinationRequestBody === null || requestParameters.createDestinationRequestBody === undefined) {
            throw new runtime.RequiredError('createDestinationRequestBody','Required parameter requestParameters.createDestinationRequestBody was null or undefined when calling postmortemsCreateDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/destinations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateDestinationRequestBodyToJSON(requestParameters.createDestinationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateDestinationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a postmortem destination
     * CreateDestination Postmortems
     */
    async postmortemsCreateDestination(requestParameters: PostmortemsCreateDestinationRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateDestinationResponseBody> {
        const response = await this.postmortemsCreateDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create postmortem template
     * CreateLegacyTemplate Postmortems
     */
    async postmortemsCreateLegacyTemplateRaw(requestParameters: PostmortemsCreateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateLegacyTemplateResponseBody>> {
        if (requestParameters.createLegacyTemplateRequestBody === null || requestParameters.createLegacyTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('createLegacyTemplateRequestBody','Required parameter requestParameters.createLegacyTemplateRequestBody was null or undefined when calling postmortemsCreateLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateLegacyTemplateRequestBodyToJSON(requestParameters.createLegacyTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateLegacyTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create postmortem template
     * CreateLegacyTemplate Postmortems
     */
    async postmortemsCreateLegacyTemplate(requestParameters: PostmortemsCreateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateLegacyTemplateResponseBody> {
        const response = await this.postmortemsCreateLegacyTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a postmortem template
     * CreateTemplate Postmortems
     */
    async postmortemsCreateTemplateRaw(requestParameters: PostmortemsCreateTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateTemplateResponseBody>> {
        if (requestParameters.createTemplateRequestBody === null || requestParameters.createTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('createTemplateRequestBody','Required parameter requestParameters.createTemplateRequestBody was null or undefined when calling postmortemsCreateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateTemplateRequestBodyToJSON(requestParameters.createTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a postmortem template
     * CreateTemplate Postmortems
     */
    async postmortemsCreateTemplate(requestParameters: PostmortemsCreateTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateTemplateResponseBody> {
        const response = await this.postmortemsCreateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a response to a section on the postmortem template
     * CreateTemplateResponse Postmortems
     */
    async postmortemsCreateTemplateResponseRaw(requestParameters: PostmortemsCreateTemplateResponseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateTemplateResponseResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling postmortemsCreateTemplateResponse.');
        }

        if (requestParameters.postmortemTemplateId === null || requestParameters.postmortemTemplateId === undefined) {
            throw new runtime.RequiredError('postmortemTemplateId','Required parameter requestParameters.postmortemTemplateId was null or undefined when calling postmortemsCreateTemplateResponse.');
        }

        if (requestParameters.createTemplateResponseRequestBody === null || requestParameters.createTemplateResponseRequestBody === undefined) {
            throw new runtime.RequiredError('createTemplateResponseRequestBody','Required parameter requestParameters.createTemplateResponseRequestBody was null or undefined when calling postmortemsCreateTemplateResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/{postmortem_template_id}/incidents/{incident_id}/response`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))).replace(`{${"postmortem_template_id"}}`, encodeURIComponent(String(requestParameters.postmortemTemplateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateTemplateResponseRequestBodyToJSON(requestParameters.createTemplateResponseRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateTemplateResponseResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a response to a section on the postmortem template
     * CreateTemplateResponse Postmortems
     */
    async postmortemsCreateTemplateResponse(requestParameters: PostmortemsCreateTemplateResponseRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateTemplateResponseResponseBody> {
        const response = await this.postmortemsCreateTemplateResponseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a section on a postmortem template configuration
     * CreateTemplateSection Postmortems
     */
    async postmortemsCreateTemplateSectionRaw(requestParameters: PostmortemsCreateTemplateSectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateTemplateSectionResponseBody>> {
        if (requestParameters.createTemplateSectionRequestBody === null || requestParameters.createTemplateSectionRequestBody === undefined) {
            throw new runtime.RequiredError('createTemplateSectionRequestBody','Required parameter requestParameters.createTemplateSectionRequestBody was null or undefined when calling postmortemsCreateTemplateSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/sections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateTemplateSectionRequestBodyToJSON(requestParameters.createTemplateSectionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateTemplateSectionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a section on a postmortem template configuration
     * CreateTemplateSection Postmortems
     */
    async postmortemsCreateTemplateSection(requestParameters: PostmortemsCreateTemplateSectionRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateTemplateSectionResponseBody> {
        const response = await this.postmortemsCreateTemplateSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a postmortem destination
     * DestroyDestination Postmortems
     */
    async postmortemsDestroyDestinationRaw(requestParameters: PostmortemsDestroyDestinationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/destinations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a postmortem destination
     * DestroyDestination Postmortems
     */
    async postmortemsDestroyDestination(requestParameters: PostmortemsDestroyDestinationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyDestinationRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a postmortem document
     * DestroyDocument Postmortems
     */
    async postmortemsDestroyDocumentRaw(requestParameters: PostmortemsDestroyDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a postmortem document
     * DestroyDocument Postmortems
     */
    async postmortemsDestroyDocument(requestParameters: PostmortemsDestroyDocumentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Removes an existing template
     * DestroyLegacyTemplate Postmortems
     */
    async postmortemsDestroyLegacyTemplateRaw(requestParameters: PostmortemsDestroyLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing template
     * DestroyLegacyTemplate Postmortems
     */
    async postmortemsDestroyLegacyTemplate(requestParameters: PostmortemsDestroyLegacyTemplateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyLegacyTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Destroys a postmortem template
     * DestroyTemplate Postmortems
     */
    async postmortemsDestroyTemplateRaw(requestParameters: PostmortemsDestroyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroys a postmortem template
     * DestroyTemplate Postmortems
     */
    async postmortemsDestroyTemplate(requestParameters: PostmortemsDestroyTemplateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a section on a postmortem template
     * DestroyTemplateSection Postmortems
     */
    async postmortemsDestroyTemplateSectionRaw(requestParameters: PostmortemsDestroyTemplateSectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyTemplateSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates/sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a section on a postmortem template
     * DestroyTemplateSection Postmortems
     */
    async postmortemsDestroyTemplateSection(requestParameters: PostmortemsDestroyTemplateSectionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyTemplateSectionRaw(requestParameters, initOverrides);
    }

    /**
     * Enqueue a postmortem to be created by some external provider, will retry to create a postmortem if a failed postmortem is provided
     * ExportDocument Postmortems
     */
    async postmortemsExportDocumentRaw(requestParameters: PostmortemsExportDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsExportDocumentResponseBody>> {
        if (requestParameters.exportDocumentRequestBody === null || requestParameters.exportDocumentRequestBody === undefined) {
            throw new runtime.RequiredError('exportDocumentRequestBody','Required parameter requestParameters.exportDocumentRequestBody was null or undefined when calling postmortemsExportDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/documents/actions/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsExportDocumentRequestBodyToJSON(requestParameters.exportDocumentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsExportDocumentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enqueue a postmortem to be created by some external provider, will retry to create a postmortem if a failed postmortem is provided
     * ExportDocument Postmortems
     */
    async postmortemsExportDocument(requestParameters: PostmortemsExportDocumentRequest, initOverrides?: RequestInit): Promise<PostmortemsExportDocumentResponseBody> {
        const response = await this.postmortemsExportDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all postmortem destinations
     * ListDestinations Postmortems
     */
    async postmortemsListDestinationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListDestinationsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/destinations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListDestinationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all postmortem destinations
     * ListDestinations Postmortems
     */
    async postmortemsListDestinations(initOverrides?: RequestInit): Promise<PostmortemsListDestinationsResponseBody> {
        const response = await this.postmortemsListDestinationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List postmortem documents
     * ListDocuments Postmortems
     */
    async postmortemsListDocumentsRaw(requestParameters: PostmortemsListDocumentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListDocumentsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling postmortemsListDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/documents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListDocumentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem documents
     * ListDocuments Postmortems
     */
    async postmortemsListDocuments(requestParameters: PostmortemsListDocumentsRequest, initOverrides?: RequestInit): Promise<PostmortemsListDocumentsResponseBody> {
        const response = await this.postmortemsListDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List standard content blocks that are included when creating a new postmortem template
     * ListLegacyTemplateStandardContentBlocks Postmortems
     */
    async postmortemsListLegacyTemplateStandardContentBlocksRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListLegacyTemplateStandardContentBlocksResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/content_blocks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyFromJSON(jsonValue));
    }

    /**
     * List standard content blocks that are included when creating a new postmortem template
     * ListLegacyTemplateStandardContentBlocks Postmortems
     */
    async postmortemsListLegacyTemplateStandardContentBlocks(initOverrides?: RequestInit): Promise<PostmortemsListLegacyTemplateStandardContentBlocksResponseBody> {
        const response = await this.postmortemsListLegacyTemplateStandardContentBlocksRaw(initOverrides);
        return await response.value();
    }

    /**
     * List postmortem templates
     * ListLegacyTemplates Postmortems
     */
    async postmortemsListLegacyTemplatesRaw(requestParameters: PostmortemsListLegacyTemplatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListLegacyTemplatesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListLegacyTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem templates
     * ListLegacyTemplates Postmortems
     */
    async postmortemsListLegacyTemplates(requestParameters: PostmortemsListLegacyTemplatesRequest, initOverrides?: RequestInit): Promise<PostmortemsListLegacyTemplatesResponseBody> {
        const response = await this.postmortemsListLegacyTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List suggested postmortem templates
     * ListSuggestedTemplates Postmortems
     */
    async postmortemsListSuggestedTemplatesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListSuggestedTemplatesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/suggested_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListSuggestedTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List suggested postmortem templates
     * ListSuggestedTemplates Postmortems
     */
    async postmortemsListSuggestedTemplates(initOverrides?: RequestInit): Promise<PostmortemsListSuggestedTemplatesResponseBody> {
        const response = await this.postmortemsListSuggestedTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the responses for a postmortem template and incident
     * ListTemplateResponses Postmortems
     */
    async postmortemsListTemplateResponsesRaw(requestParameters: PostmortemsListTemplateResponsesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListTemplateResponsesResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling postmortemsListTemplateResponses.');
        }

        if (requestParameters.postmortemTemplateId === null || requestParameters.postmortemTemplateId === undefined) {
            throw new runtime.RequiredError('postmortemTemplateId','Required parameter requestParameters.postmortemTemplateId was null or undefined when calling postmortemsListTemplateResponses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates/{postmortem_template_id}/incidents/{incident_id}/responses`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))).replace(`{${"postmortem_template_id"}}`, encodeURIComponent(String(requestParameters.postmortemTemplateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListTemplateResponsesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the responses for a postmortem template and incident
     * ListTemplateResponses Postmortems
     */
    async postmortemsListTemplateResponses(requestParameters: PostmortemsListTemplateResponsesRequest, initOverrides?: RequestInit): Promise<PostmortemsListTemplateResponsesResponseBody> {
        const response = await this.postmortemsListTemplateResponsesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List postmortem templates
     * ListTemplates Postmortems
     */
    async postmortemsListTemplatesRaw(requestParameters: PostmortemsListTemplatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListTemplatesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem templates
     * ListTemplates Postmortems
     */
    async postmortemsListTemplates(requestParameters: PostmortemsListTemplatesRequest, initOverrides?: RequestInit): Promise<PostmortemsListTemplatesResponseBody> {
        const response = await this.postmortemsListTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Renders a postmortem for an incident, using the given template or blocks
     * Render Postmortems
     */
    async postmortemsRenderRaw(requestParameters: PostmortemsRenderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsRenderResponseBody>> {
        if (requestParameters.renderRequestBody === null || requestParameters.renderRequestBody === undefined) {
            throw new runtime.RequiredError('renderRequestBody','Required parameter requestParameters.renderRequestBody was null or undefined when calling postmortemsRender.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/render`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsRenderRequestBodyToJSON(requestParameters.renderRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsRenderResponseBodyFromJSON(jsonValue));
    }

    /**
     * Renders a postmortem for an incident, using the given template or blocks
     * Render Postmortems
     */
    async postmortemsRender(requestParameters: PostmortemsRenderRequest, initOverrides?: RequestInit): Promise<PostmortemsRenderResponseBody> {
        const response = await this.postmortemsRenderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Renders markdown of the postmortem for an incident, using the given template or blocks
     * RenderMarkdown Postmortems
     */
    async postmortemsRenderMarkdownRaw(requestParameters: PostmortemsRenderMarkdownRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsRenderMarkdownResponseBody>> {
        if (requestParameters.renderMarkdownRequestBody === null || requestParameters.renderMarkdownRequestBody === undefined) {
            throw new runtime.RequiredError('renderMarkdownRequestBody','Required parameter requestParameters.renderMarkdownRequestBody was null or undefined when calling postmortemsRenderMarkdown.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/render_markdown`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsRenderMarkdownRequestBodyToJSON(requestParameters.renderMarkdownRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsRenderMarkdownResponseBodyFromJSON(jsonValue));
    }

    /**
     * Renders markdown of the postmortem for an incident, using the given template or blocks
     * RenderMarkdown Postmortems
     */
    async postmortemsRenderMarkdown(requestParameters: PostmortemsRenderMarkdownRequest, initOverrides?: RequestInit): Promise<PostmortemsRenderMarkdownResponseBody> {
        const response = await this.postmortemsRenderMarkdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change the status of a postmortem document
     * SetDocumentStatus Postmortems
     */
    async postmortemsSetDocumentStatusRaw(requestParameters: PostmortemsSetDocumentStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSetDocumentStatusResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsSetDocumentStatus.');
        }

        if (requestParameters.setDocumentStatusRequestBody === null || requestParameters.setDocumentStatusRequestBody === undefined) {
            throw new runtime.RequiredError('setDocumentStatusRequestBody','Required parameter requestParameters.setDocumentStatusRequestBody was null or undefined when calling postmortemsSetDocumentStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/documents/{id}/actions/set_status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSetDocumentStatusRequestBodyToJSON(requestParameters.setDocumentStatusRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSetDocumentStatusResponseBodyFromJSON(jsonValue));
    }

    /**
     * Change the status of a postmortem document
     * SetDocumentStatus Postmortems
     */
    async postmortemsSetDocumentStatus(requestParameters: PostmortemsSetDocumentStatusRequest, initOverrides?: RequestInit): Promise<PostmortemsSetDocumentStatusResponseBody> {
        const response = await this.postmortemsSetDocumentStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a postmortem document
     * ShowDocument Postmortems
     */
    async postmortemsShowDocumentRaw(requestParameters: PostmortemsShowDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsShowDocumentResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsShowDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsShowDocumentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a postmortem document
     * ShowDocument Postmortems
     */
    async postmortemsShowDocument(requestParameters: PostmortemsShowDocumentRequest, initOverrides?: RequestInit): Promise<PostmortemsShowDocumentResponseBody> {
        const response = await this.postmortemsShowDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual template by ID
     * ShowLegacyTemplate Postmortems
     */
    async postmortemsShowLegacyTemplateRaw(requestParameters: PostmortemsShowLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsShowLegacyTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsShowLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsShowLegacyTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get an individual template by ID
     * ShowLegacyTemplate Postmortems
     */
    async postmortemsShowLegacyTemplate(requestParameters: PostmortemsShowLegacyTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsShowLegacyTemplateResponseBody> {
        const response = await this.postmortemsShowLegacyTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a postmortem template with sections
     * ShowTemplate Postmortems
     */
    async postmortemsShowTemplateRaw(requestParameters: PostmortemsShowTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsShowTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsShowTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsShowTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a postmortem template with sections
     * ShowTemplate Postmortems
     */
    async postmortemsShowTemplate(requestParameters: PostmortemsShowTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsShowTemplateResponseBody> {
        const response = await this.postmortemsShowTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a postmortem destination
     * UpdateDestination Postmortems
     */
    async postmortemsUpdateDestinationRaw(requestParameters: PostmortemsUpdateDestinationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateDestinationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsUpdateDestination.');
        }

        if (requestParameters.updateDestinationRequestBody === null || requestParameters.updateDestinationRequestBody === undefined) {
            throw new runtime.RequiredError('updateDestinationRequestBody','Required parameter requestParameters.updateDestinationRequestBody was null or undefined when calling postmortemsUpdateDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/destinations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateDestinationRequestBodyToJSON(requestParameters.updateDestinationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateDestinationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates a postmortem destination
     * UpdateDestination Postmortems
     */
    async postmortemsUpdateDestination(requestParameters: PostmortemsUpdateDestinationRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateDestinationResponseBody> {
        const response = await this.postmortemsUpdateDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update postmortem template
     * UpdateLegacyTemplate Postmortems
     */
    async postmortemsUpdateLegacyTemplateRaw(requestParameters: PostmortemsUpdateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateLegacyTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsUpdateLegacyTemplate.');
        }

        if (requestParameters.updateLegacyTemplateRequestBody === null || requestParameters.updateLegacyTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('updateLegacyTemplateRequestBody','Required parameter requestParameters.updateLegacyTemplateRequestBody was null or undefined when calling postmortemsUpdateLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateLegacyTemplateRequestBodyToJSON(requestParameters.updateLegacyTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateLegacyTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update postmortem template
     * UpdateLegacyTemplate Postmortems
     */
    async postmortemsUpdateLegacyTemplate(requestParameters: PostmortemsUpdateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateLegacyTemplateResponseBody> {
        const response = await this.postmortemsUpdateLegacyTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the status of a postmortem for an incident
     * UpdateStatus Postmortems
     */
    async postmortemsUpdateStatusRaw(requestParameters: PostmortemsUpdateStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateStatusRequestBody === null || requestParameters.updateStatusRequestBody === undefined) {
            throw new runtime.RequiredError('updateStatusRequestBody','Required parameter requestParameters.updateStatusRequestBody was null or undefined when calling postmortemsUpdateStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/actions/update_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateStatusRequestBodyToJSON(requestParameters.updateStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the status of a postmortem for an incident
     * UpdateStatus Postmortems
     */
    async postmortemsUpdateStatus(requestParameters: PostmortemsUpdateStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsUpdateStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Update a postmortem template
     * UpdateTemplate Postmortems
     */
    async postmortemsUpdateTemplateRaw(requestParameters: PostmortemsUpdateTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsUpdateTemplate.');
        }

        if (requestParameters.updateTemplateRequestBody === null || requestParameters.updateTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateRequestBody','Required parameter requestParameters.updateTemplateRequestBody was null or undefined when calling postmortemsUpdateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateTemplateRequestBodyToJSON(requestParameters.updateTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a postmortem template
     * UpdateTemplate Postmortems
     */
    async postmortemsUpdateTemplate(requestParameters: PostmortemsUpdateTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateTemplateResponseBody> {
        const response = await this.postmortemsUpdateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the template used for a given incident
     * UpdateTemplateForIncident Postmortems
     */
    async postmortemsUpdateTemplateForIncidentRaw(requestParameters: PostmortemsUpdateTemplateForIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling postmortemsUpdateTemplateForIncident.');
        }

        if (requestParameters.postmortemTemplateId === null || requestParameters.postmortemTemplateId === undefined) {
            throw new runtime.RequiredError('postmortemTemplateId','Required parameter requestParameters.postmortemTemplateId was null or undefined when calling postmortemsUpdateTemplateForIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates/{postmortem_template_id}/incidents/{incident_id}`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))).replace(`{${"postmortem_template_id"}}`, encodeURIComponent(String(requestParameters.postmortemTemplateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the template used for a given incident
     * UpdateTemplateForIncident Postmortems
     */
    async postmortemsUpdateTemplateForIncident(requestParameters: PostmortemsUpdateTemplateForIncidentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsUpdateTemplateForIncidentRaw(requestParameters, initOverrides);
    }

    /**
     * Updates a response to a section on the postmortem template
     * UpdateTemplateResponse Postmortems
     */
    async postmortemsUpdateTemplateResponseRaw(requestParameters: PostmortemsUpdateTemplateResponseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateTemplateResponseResponseBody>> {
        if (requestParameters.responseId === null || requestParameters.responseId === undefined) {
            throw new runtime.RequiredError('responseId','Required parameter requestParameters.responseId was null or undefined when calling postmortemsUpdateTemplateResponse.');
        }

        if (requestParameters.updateTemplateResponseRequestBody === null || requestParameters.updateTemplateResponseRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateResponseRequestBody','Required parameter requestParameters.updateTemplateResponseRequestBody was null or undefined when calling postmortemsUpdateTemplateResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/responses/{response_id}`.replace(`{${"response_id"}}`, encodeURIComponent(String(requestParameters.responseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateTemplateResponseRequestBodyToJSON(requestParameters.updateTemplateResponseRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateTemplateResponseResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates a response to a section on the postmortem template
     * UpdateTemplateResponse Postmortems
     */
    async postmortemsUpdateTemplateResponse(requestParameters: PostmortemsUpdateTemplateResponseRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateTemplateResponseResponseBody> {
        const response = await this.postmortemsUpdateTemplateResponseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a section on a postmortem template configuration
     * UpdateTemplateSection Postmortems
     */
    async postmortemsUpdateTemplateSectionRaw(requestParameters: PostmortemsUpdateTemplateSectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateTemplateSectionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsUpdateTemplateSection.');
        }

        if (requestParameters.updateTemplateSectionRequestBody === null || requestParameters.updateTemplateSectionRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateSectionRequestBody','Required parameter requestParameters.updateTemplateSectionRequestBody was null or undefined when calling postmortemsUpdateTemplateSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateTemplateSectionRequestBodyToJSON(requestParameters.updateTemplateSectionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateTemplateSectionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates a section on a postmortem template configuration
     * UpdateTemplateSection Postmortems
     */
    async postmortemsUpdateTemplateSection(requestParameters: PostmortemsUpdateTemplateSectionRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateTemplateSectionResponseBody> {
        const response = await this.postmortemsUpdateTemplateSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the ranks for each section on a postmortem template
     * UpdateTemplateSectionRanks Postmortems
     */
    async postmortemsUpdateTemplateSectionRanksRaw(requestParameters: PostmortemsUpdateTemplateSectionRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateTemplateSectionRanksResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsUpdateTemplateSectionRanks.');
        }

        if (requestParameters.updateTemplateSectionRanksRequestBody === null || requestParameters.updateTemplateSectionRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateSectionRanksRequestBody','Required parameter requestParameters.updateTemplateSectionRanksRequestBody was null or undefined when calling postmortemsUpdateTemplateSectionRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/{id}/actions/update_ranks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateTemplateSectionRanksRequestBodyToJSON(requestParameters.updateTemplateSectionRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateTemplateSectionRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates the ranks for each section on a postmortem template
     * UpdateTemplateSectionRanks Postmortems
     */
    async postmortemsUpdateTemplateSectionRanks(requestParameters: PostmortemsUpdateTemplateSectionRanksRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateTemplateSectionRanksResponseBody> {
        const response = await this.postmortemsUpdateTemplateSectionRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
