import type { SVGProps } from "react";
import * as React from "react";
const SvgLink = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.672 18.484a4.261 4.261 0 0 1-3.026-1.252l-2.174-2.175a4.29 4.29 0 0 1 0-6.061 4.293 4.293 0 0 1 1.19-.846.75.75 0 1 1 .645 1.354 2.766 2.766 0 0 0-.765.543 2.793 2.793 0 0 0-.01 3.95l2.175 2.175a2.79 2.79 0 0 0 3.938 0 2.792 2.792 0 0 0 .01-3.95l-.93-.93a.75.75 0 1 1 1.06-1.061l.931.93a4.292 4.292 0 0 1-3.044 7.322Z"
    />
    <path
      fill="currentColor"
      d="M10.985 13.923a.75.75 0 0 1-.323-1.427c.283-.135.541-.318.766-.543a2.793 2.793 0 0 0 .01-3.95L9.263 5.828a2.79 2.79 0 0 0-3.939 0 2.791 2.791 0 0 0-.01 3.949l.931.931a.75.75 0 1 1-1.06 1.061l-.932-.931a4.29 4.29 0 0 1 0-6.06c1.682-1.681 4.4-1.682 6.07-.01l2.175 2.175a4.29 4.29 0 0 1 0 6.061 4.293 4.293 0 0 1-1.19.846.742.742 0 0 1-.323.073Z"
    />
  </svg>
);
export default SvgLink;
