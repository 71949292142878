/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    DebriefInvitees,
    DebriefInviteesFromJSON,
    DebriefInviteesFromJSONTyped,
    DebriefInviteesToJSON,
} from './DebriefInvitees';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface DebriefInviteRule
 */
export interface DebriefInviteRule {
    /**
     * 
     * @type {Array<ConditionGroup>}
     * @memberof DebriefInviteRule
     */
    condition_groups?: Array<ConditionGroup>;
    /**
     * 
     * @type {Array<Expression>}
     * @memberof DebriefInviteRule
     */
    expressions?: Array<Expression>;
    /**
     * The id of the debrief invite rule
     * @type {string}
     * @memberof DebriefInviteRule
     */
    id: string;
    /**
     * 
     * @type {DebriefInvitees}
     * @memberof DebriefInviteRule
     */
    invitees: DebriefInvitees;
    /**
     * Policy ID that matches this resource
     * @type {string}
     * @memberof DebriefInviteRule
     */
    linked_policy_id?: string;
    /**
     * Rank is used to order the debrief invite rules in the UI
     * @type {number}
     * @memberof DebriefInviteRule
     */
    rank: number;
}

export function DebriefInviteRuleFromJSON(json: any): DebriefInviteRule {
    return DebriefInviteRuleFromJSONTyped(json, false);
}

export function DebriefInviteRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefInviteRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': !exists(json, 'condition_groups') ? undefined : ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'id': json['id'],
        'invitees': DebriefInviteesFromJSON(json['invitees']),
        'linked_policy_id': !exists(json, 'linked_policy_id') ? undefined : json['linked_policy_id'],
        'rank': json['rank'],
    };
}

export function DebriefInviteRuleToJSON(value?: DebriefInviteRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': value.condition_groups === undefined ? undefined : ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'id': value.id,
        'invitees': DebriefInviteesToJSON(value.invitees),
        'linked_policy_id': value.linked_policy_id,
        'rank': value.rank,
    };
}

