import type { SVGProps } from "react";
import * as React from "react";
const SvgDashboard = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="currentColor" d="M8 8.5H6.5v7.75H8V8.5Z" />
    <path
      fill="currentColor"
      d="M15.25 3H4.75A2.752 2.752 0 0 0 2 5.75v8.5A2.752 2.752 0 0 0 4.75 17h10.5A2.752 2.752 0 0 0 18 14.25v-8.5A2.752 2.752 0 0 0 15.25 3ZM8 5a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM5 5a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm11.5 9.25c0 .689-.561 1.25-1.25 1.25H4.75c-.689 0-1.25-.561-1.25-1.25V9h13v5.25Z"
    />
  </svg>
);
export default SvgDashboard;
