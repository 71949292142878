import type { SVGProps } from "react";
import * as React from "react";
const SvgDecisionFlow = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.75 12.5a.75.75 0 0 1-.75-.75v-2.5a.75.75 0 0 1 1.5 0v2.5a.75.75 0 0 1-.75.75ZM14.25 9a.75.75 0 0 1-.75-.75v-2a.25.25 0 0 0-.25-.25h-2a.75.75 0 0 1 0-1.5h2c.965 0 1.75.785 1.75 1.75v2a.75.75 0 0 1-.75.75ZM13.25 16.5h-2a.75.75 0 0 1 0-1.5h2a.25.25 0 0 0 .25-.25v-2a.75.75 0 0 1 1.5 0v2c0 .965-.785 1.75-1.75 1.75ZM7.75 3h-4A1.75 1.75 0 0 0 2 4.75v1c0 .966.784 1.75 1.75 1.75h4A1.75 1.75 0 0 0 9.5 5.75v-1A1.75 1.75 0 0 0 7.75 3ZM7.75 13.5h-4A1.75 1.75 0 0 0 2 15.25v1c0 .966.784 1.75 1.75 1.75h4a1.75 1.75 0 0 0 1.75-1.75v-1a1.75 1.75 0 0 0-1.75-1.75Z"
    />
    <path
      fill="currentColor"
      d="m18.386 9.857-3.75-2.25a.746.746 0 0 0-.771 0l-3.75 2.25a.748.748 0 0 0 0 1.286l3.75 2.25a.752.752 0 0 0 .772 0l3.75-2.25a.748.748 0 0 0-.001-1.286Z"
    />
  </svg>
);
export default SvgDecisionFlow;
