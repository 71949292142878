/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentUpdateOrStatusPageIncidentUpdate,
    IncidentUpdateOrStatusPageIncidentUpdateFromJSON,
    IncidentUpdateOrStatusPageIncidentUpdateFromJSONTyped,
    IncidentUpdateOrStatusPageIncidentUpdateToJSON,
} from './IncidentUpdateOrStatusPageIncidentUpdate';

/**
 * 
 * @export
 * @interface IncidentUpdatesListForIncidentResponseBody
 */
export interface IncidentUpdatesListForIncidentResponseBody {
    /**
     * 
     * @type {Array<IncidentUpdateOrStatusPageIncidentUpdate>}
     * @memberof IncidentUpdatesListForIncidentResponseBody
     */
    updates: Array<IncidentUpdateOrStatusPageIncidentUpdate>;
}

export function IncidentUpdatesListForIncidentResponseBodyFromJSON(json: any): IncidentUpdatesListForIncidentResponseBody {
    return IncidentUpdatesListForIncidentResponseBodyFromJSONTyped(json, false);
}

export function IncidentUpdatesListForIncidentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentUpdatesListForIncidentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updates': ((json['updates'] as Array<any>).map(IncidentUpdateOrStatusPageIncidentUpdateFromJSON)),
    };
}

export function IncidentUpdatesListForIncidentResponseBodyToJSON(value?: IncidentUpdatesListForIncidentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updates': ((value.updates as Array<any>).map(IncidentUpdateOrStatusPageIncidentUpdateToJSON)),
    };
}

