import {
  EngineParamBindingValue,
  EngineScope,
  Resource,
} from "@incident-io/api";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { EngineBindingValueBadge } from "@incident-shared/engine/labels";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { DragHandle } from "@incident-shared/settings";
import { Button, ButtonTheme, IconEnum, IconSize } from "@incident-ui";
import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { CreateEditExpressionFormData } from "../AddEditExpressionModal";
import { Branch, EditingBranchState } from "./ExpressionBranchesList";

export const ExpressionBranchRow = ({
  branch,
  index,
  isDisabled,
  draggableProvided,
  setEditingState,
  onDelete,
  isLastBranch,
  selectedExpressionTypeResource,
  renderResultBinding,
  scope,
}: {
  index: number;
  branch: Branch;
  isDisabled: boolean;
  setEditingState: (e: EditingBranchState) => void;
  draggableProvided: DraggableProvided;
  onDelete: (branch: Branch) => void;
  isLastBranch: boolean;
  selectedExpressionTypeResource: Resource | undefined;
  renderResultBinding?: (
    binding: EngineParamBindingValue,
  ) => React.ReactElement;
  scope: EngineScope;
}): React.ReactElement => {
  const expressionFormMethods = useFormContext<CreateEditExpressionFormData>();
  const [resultArray] = expressionFormMethods.watch(["returns.array"]);
  const results = resultArray
    ? branch.result.array_value ?? []
    : [branch.result.value];

  const isFirstItem = index === 0;
  return (
    <div
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      className={tcx(
        "flex flex-col gap-4 px-3 py-2 text-content-primary",
        isDisabled ? "opacity-50" : "",
      )}
    >
      <div className={"flex items-start"}>
        <DragHandle
          className="mr-2 flex-0 flex py-2.5"
          {...draggableProvided.dragHandleProps}
        />
        <span className={"text-sm font-medium w-16 flex-shrink-0 py-2"}>
          {isFirstItem ? "If" : "Else if"}...
        </span>
        <ConditionGroupsList
          className="w-full"
          groups={branch.condition_groups}
          boxless
        />
        <div
          className={
            "shrink-0 mt-6 md:mt-0 md:ml-6 text-slate-600 flex items-center py-1.5"
          }
        >
          <Button
            analyticsTrackingId="expression-condition-edit-rule"
            theme={ButtonTheme.Naked}
            onClick={() =>
              setEditingState({ isEditing: true, editingItem: index })
            }
            icon={IconEnum.Edit}
            iconProps={{ size: IconSize.Medium }}
            title="Edit"
          />
          <GatedButton
            analyticsTrackingId="expression-condition-remove-rule"
            className="ml-1"
            theme={ButtonTheme.Naked}
            onClick={() => onDelete(branch)}
            icon={IconEnum.Delete}
            iconProps={{ size: IconSize.Medium }}
            title="Remove rule"
            disabled={isLastBranch}
            disabledTooltipContent={
              isLastBranch ? "You must have at least one rule" : undefined
            }
          />
        </div>
      </div>
      <div className={"flex text-sm ml-6 items-center"}>
        <span className={"font-medium w-16"}>Return</span>
        <ul className={"flex items-center flex-wrap px-1 flex-grow"}>
          {results.map((resultBinding, index) => {
            if (!resultBinding) {
              return null;
            }
            return (
              <li
                key={`${resultBinding.label ?? resultBinding.literal}-${index}`}
              >
                {renderResultBinding ? (
                  renderResultBinding(resultBinding)
                ) : (
                  <EngineBindingValueBadge
                    value={resultBinding}
                    resourceType={selectedExpressionTypeResource?.type || ""}
                    scope={scope}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
