import type { SVGProps } from "react";
import * as React from "react";
const SvgStore = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.476 8.75c.82 0 1.613-.275 2.263-.773 1.297.993 3.226.996 4.522 0 .648.497 1.44.772 2.261.772a3.76 3.76 0 0 0 3.401-2.187.75.75 0 0 0-.029-.684L16.19 2.881a1.76 1.76 0 0 0-1.521-.883H5.331c-.626 0-1.208.338-1.521.884L2.104 5.877a.75.75 0 0 0-.03.685A3.76 3.76 0 0 0 5.475 8.75l.001.001ZM17.25 16.5H16v-6a.75.75 0 0 0-1.5 0v6H12V14a2 2 0 0 0-4 0v2.5H5.5v-6a.75.75 0 0 0-1.5 0v6H2.75a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5Z"
    />
  </svg>
);
export default SvgStore;
