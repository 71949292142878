import type { SVGProps } from "react";
import * as React from "react";
const SvgPiggyBank = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path d="M13.75 9.5A5.256 5.256 0 0 1 8.5 4.25a5.2 5.2 0 0 1 .387-1.956 8.246 8.246 0 0 0-1.674.28 4.197 4.197 0 0 0-.832-.606c-1.243-.681-2.443-.571-3.046-.447a.748.748 0 0 0-.497 1.11l1.083 1.875a6.72 6.72 0 0 0-1.302 1.818l-1.461.183a.75.75 0 0 0-.657.744v3.5a.75.75 0 0 0 .657.744l1.461.183a6.851 6.851 0 0 0 1.862 2.324l.275 2.336a.75.75 0 0 0 .745.663h1.75A.75.75 0 0 0 8 16.295l.04-.675a8.256 8.256 0 0 0 2.924 0l.04.675a.75.75 0 0 0 .749.706h1.75c.38 0 .7-.285.745-.663l.275-2.336c1.519-1.236 2.481-3.017 2.481-5.001a6.25 6.25 0 0 0-.035-.63 5.198 5.198 0 0 1-3.215 1.13ZM6.25 8a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
    <path
      d="M13.75.5C11.682.5 10 2.182 10 4.25S11.682 8 13.75 8s3.75-1.682 3.75-3.75S15.818.5 13.75.5ZM14.5 5A.75.75 0 0 1 13 5V3.5a.75.75 0 0 1 1.5 0V5Z"
      data-color="color-2"
    />
  </svg>
);
export default SvgPiggyBank;
