/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentFormLifecycleElement,
    IncidentFormLifecycleElementFromJSON,
    IncidentFormLifecycleElementFromJSONTyped,
    IncidentFormLifecycleElementToJSON,
} from './IncidentFormLifecycleElement';

/**
 * 
 * @export
 * @interface IncidentFormsCreateLifecycleElementResponseBody
 */
export interface IncidentFormsCreateLifecycleElementResponseBody {
    /**
     * 
     * @type {IncidentFormLifecycleElement}
     * @memberof IncidentFormsCreateLifecycleElementResponseBody
     */
    element: IncidentFormLifecycleElement;
}

export function IncidentFormsCreateLifecycleElementResponseBodyFromJSON(json: any): IncidentFormsCreateLifecycleElementResponseBody {
    return IncidentFormsCreateLifecycleElementResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsCreateLifecycleElementResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsCreateLifecycleElementResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'element': IncidentFormLifecycleElementFromJSON(json['element']),
    };
}

export function IncidentFormsCreateLifecycleElementResponseBodyToJSON(value?: IncidentFormsCreateLifecycleElementResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'element': IncidentFormLifecycleElementToJSON(value.element),
    };
}

