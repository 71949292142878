import { ConditionBadge } from "@incident-shared/engine/conditions";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  AvailableFilter,
  ExtendedFormFieldValue,
  FilterPopover,
  isViewableFor,
  useFiltersContext,
} from "src/components/@shared/filters";
import { useSavedViews } from "src/components/saved-views/SavedViewContext";
import { tcx } from "src/utils/tailwind-classes";

import { AppliedFilter } from "./AppliedFilter";
import { ALERT_ID, INCIDENT_ID } from "./constants";
import styles from "./Filters.module.scss";

interface AppliedFiltersBaseProps {
  availableFilterFields: AvailableFilter[];
  appliedFilters: ExtendedFormFieldValue[];
  readonly?: boolean;
  badgeTheme?: "white" | "slate";
}

interface EditableAppliedFiltersProps extends AppliedFiltersBaseProps {
  onDeleteFilter: (key: string) => void;
  readonly?: false;
}

interface ReadonlyAppliedFiltersProps extends AppliedFiltersBaseProps {
  readonly: true;
  onDeleteFilter?: never;
}

export const AppliedFilters = ({
  availableFilterFields,
  appliedFilters,
  readonly,
  onDeleteFilter,
  badgeTheme,
}:
  | EditableAppliedFiltersProps
  | ReadonlyAppliedFiltersProps): React.ReactElement | null => {
  return (
    <>
      {/*  On small screens we render the applied filters as a dropdown to be more compact  */}
      <FilterPopover
        renderTriggerButton={({ onClick }) => {
          return (
            <div className={tcx(styles.compactAppliedFilters)}>
              {appliedFilters.length > 0 ? (
                <DropdownMenu
                  side="bottom"
                  triggerButton={
                    <Button
                      icon={IconEnum.Filter}
                      analyticsTrackingId="null"
                      theme={ButtonTheme.Secondary}
                    >
                      {appliedFilters.length} selected
                    </Button>
                  }
                >
                  {appliedFilters.map((filterObj) => {
                    return (
                      <AppliedFilter
                        filterObj={filterObj}
                        key={filterObj.key}
                        availableFilterFields={availableFilterFields}
                        onSelectDropdownItem={(item) => onClick(item)}
                        badgeTheme={badgeTheme}
                        asDropdownItem
                        {...(readonly
                          ? { readonly: true }
                          : {
                              onDeleteFilter: onDeleteFilter,
                            })}
                      />
                    );
                  })}
                </DropdownMenu>
              ) : null}
            </div>
          );
        }}
      />
      <div
        className={tcx(
          styles.normalAppliedFilters,
          "flex-wrap gap-y-2 gap-x-2 min-w-0",
        )}
      >
        {appliedFilters.map((filterObj) => {
          return (
            <AppliedFilter
              filterObj={filterObj}
              key={filterObj.key}
              availableFilterFields={availableFilterFields}
              badgeTheme={badgeTheme}
              {...(readonly
                ? { readonly: true }
                : {
                    onDeleteFilter: onDeleteFilter,
                  })}
            />
          );
        })}
      </div>
    </>
  );
};

export const AppliedFiltersBanner = ({
  totalNumberOfItems,
  itemsLabel,
  style = "wholeOfPage",
  badgeTheme = "white",
  className,
}: {
  totalNumberOfItems: number | null;
  itemsLabel: string | null;
  badgeTheme?: "white" | "slate";
  className?: string;
  // partOfPage is the style used in the Alerts page - it doesn't reach the full width of the page
  // wholeOfPage is the style used in the Incidents page - it reaches the full width of the page
  style?: "partOfPage" | "wholeOfPage";
}) => {
  const {
    filters,
    deleteFilter,
    clearFilters,
    availableFilterFields,
    filtersLoading,
    kind,
  } = useFiltersContext();
  const { viewIsPreset, setSelectedSavedViewID } = useSavedViews();

  const filterBarFilters = filters.filter(isViewableFor(kind));

  const idFilter = filters.find((f) =>
    [ALERT_ID, INCIDENT_ID].includes(f.field_key),
  );
  const filteredIDs = idFilter
    ? idFilter.multiple_options_value ?? [idFilter.single_option_value]
    : [];

  const hasViewableFilters = filterBarFilters.length > 0;
  return hasViewableFilters || idFilter ? (
    <HeaderBanner
      className={tcx(
        "justify-between items-center",
        style === "partOfPage" && "px-3 rounded-2 border border-stroke",
        className,
      )}
    >
      <div className="flex-auto items-center min-w-0">
        {!filtersLoading && (
          <AppliedFilters
            availableFilterFields={availableFilterFields}
            appliedFilters={filterBarFilters}
            onDeleteFilter={deleteFilter}
            badgeTheme={badgeTheme}
          />
        )}
        {idFilter && (
          <ConditionBadge
            subject={"Filtered to"}
            operation={`${filteredIDs.length}`}
            criteria={`${kind}${filteredIDs.length === 1 ? "" : "s"}`}
            theme={badgeTheme}
            onDelete={() => {
              deleteFilter(idFilter.field_key);
            }}
          />
        )}
      </div>
      <div className="flex-none min-w-0 pl-4">
        {totalNumberOfItems != null && (
          <span className="text-sm mr-2 text-slate-700">
            Found {totalNumberOfItems} {itemsLabel}
            {totalNumberOfItems === 1 ? "" : "s"}
          </span>
        )}
        <Button
          theme={ButtonTheme.Naked}
          onClick={() => {
            // If the view is a preset, just exit the view rather than updating it
            if (viewIsPreset) {
              setSelectedSavedViewID(null);
            } else {
              clearFilters();
            }
          }}
          analyticsTrackingId={null}
          className="justify-self-stretch"
        >
          Clear all filters
          <Icon id={IconEnum.Close} size={IconSize.Small} className="ml-1" />
        </Button>
      </div>
    </HeaderBanner>
  ) : null;
};

export const AppliedFiltersBannerReadOnly = ({
  totalNumberOfItems,
  itemsLabel,
  style = "wholeOfPage",
  badgeTheme = "white",
  className,
}: {
  totalNumberOfItems: number | null;
  itemsLabel: string | null;
  badgeTheme?: "white" | "slate";
  className?: string;
  // partOfPage is the style used in the Alerts page - it doesn't reach the full width of the page
  // wholeOfPage is the style used in the Incidents page - it reaches the full width of the page
  style?: "partOfPage" | "wholeOfPage";
}) => {
  const { filters, availableFilterFields, filtersLoading, kind } =
    useFiltersContext();

  const filterBarFilters = filters.filter(isViewableFor(kind));

  const idFilter = filters.find((f) =>
    [ALERT_ID, INCIDENT_ID].includes(f.field_key),
  );
  const filteredIDs = idFilter
    ? idFilter.multiple_options_value ?? [idFilter.single_option_value]
    : [];

  const hasViewableFilters = filterBarFilters.length > 0;
  return hasViewableFilters || idFilter ? (
    <HeaderBanner
      className={tcx(
        "justify-between",
        style === "partOfPage" && "px-3 rounded-2 border border-stroke",
        className,
      )}
    >
      <div className="flex items-center">
        {!filtersLoading && (
          <AppliedFilters
            availableFilterFields={availableFilterFields}
            appliedFilters={filterBarFilters}
            badgeTheme={badgeTheme}
            readonly={true}
          />
        )}
        {idFilter && (
          <ConditionBadge
            subject={"Filtered to"}
            operation={`${filteredIDs.length}`}
            criteria={`${kind}${filteredIDs.length === 1 ? "" : "s"}`}
            theme={badgeTheme}
          />
        )}
      </div>
      <div className="whitespace-nowrap">
        {totalNumberOfItems != null && (
          <span className="text-sm mr-2 text-slate-700">
            Found {totalNumberOfItems} {itemsLabel}
            {totalNumberOfItems === 1 ? "" : "s"}
          </span>
        )}
      </div>
    </HeaderBanner>
  ) : null;
};
