import type { SVGProps } from "react";
import * as React from "react";
const SvgAdd = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.75 11.25H4.25a.75.75 0 0 1 0-1.5h11.5a.75.75 0 0 1 0 1.5Z"
    />
    <path
      fill="currentColor"
      d="M10 17a.75.75 0 0 1-.75-.75V4.75a.75.75 0 0 1 1.5 0v11.5A.75.75 0 0 1 10 17Z"
    />
  </svg>
);
export default SvgAdd;
