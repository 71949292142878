import {
  BaseAPI,
  ManagedResource,
  ManagedResourceResourceTypeEnum as ResourceTypeEnum,
  ManagementMeta,
  ManagementMetaManagedByEnum as ResponseBodyManagedByEnum,
} from "@incident-io/api";
import { APITypes } from "src/contexts/ClientContext";

export const isTerraform = (
  info?: ManagementMeta | ManagedResource,
): boolean => {
  if (!info) {
    return false;
  }
  return info.managed_by === ResponseBodyManagedByEnum.Terraform;
};

export const isDashboard = (info?: ManagementMeta): boolean => {
  if (!info) {
    // Everything defaults to being managed in the dashboard
    return true;
  }
  return info.managed_by === ResponseBodyManagedByEnum.Dashboard;
};

export const defaultManagementMeta = (): ManagementMeta => ({
  managed_by: ResponseBodyManagedByEnum.Dashboard,
  annotations: {},
});

export const resourceToShowEndpoint: {
  [key in ResourceTypeEnum]: Exclude<keyof APITypes, keyof BaseAPI>;
} = {
  [ResourceTypeEnum.Schedule]: "schedulesShow",
  [ResourceTypeEnum.Workflow]: "workflowsShowWorkflow",
};
