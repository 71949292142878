import {
  Incident,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
} from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useNavigateToModal } from "src/utils/query-params";

import { PostIncidentSection } from "../postincidentflow/PostIncidentSection";
import { IncidentKeyDetails } from "../sidebar/IncidentKeyDetails";
import { ProductAccessConditionalComponent } from "../sidebar/IncidentSidebar";
import { IncidentStackedLists } from "../stacked-lists/IncidentStackedLists";
import { ToggleSubscriptionButton } from "../ToggleSubscriptionButton";
import { ActivityLogMode, IncidentActivity } from "./IncidentActivity";
import { BodyTabs } from "./IncidentBody";
import { SectionWrapper } from "./SectionWrapper";
import { OverviewSummary } from "./Summary";

type OverviewProps = {
  incident: Incident | null;
  onTabChange: (tab: BodyTabs) => void;
};

export const Overview = ({ incident, onTabChange }: OverviewProps) => {
  const navigateToModal = useNavigateToModal();
  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();

  const [activityLogMode, setActivityLogMode] =
    useState<ActivityLogMode>("highlights");

  if (!incident) {
    return null;
  }

  const isClosed = isIncidentClosed(incident);

  return (
    <div className="pb-4 flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col">
          <IncidentKeyDetails
            horizontal
            incident={incident}
            setModalOpen={navigateToModal}
          />
          <div className="-ml-3 truncate">
            <OverviewSummary incident={incident} />
          </div>
        </div>
        {/* When the flag is on, we only want to show the progress bar on the post-inc tab. */}
        {!featurePostmortemsInHouse && (
          <ProductAccessConditionalComponent requiredProduct={Product.Response}>
            <PostIncidentSection incident={incident} />
          </ProductAccessConditionalComponent>
        )}
        <ProductAccessConditionalComponent requiredProduct={Product.Response}>
          <IncidentStackedLists
            incidentId={incident.id}
            onTabChange={onTabChange}
          />
        </ProductAccessConditionalComponent>
      </div>
      <hr />
      <SectionWrapper
        className="p-0 -ml-0"
        sectionName="Activity"
        headerAccessory={
          <div className="flex gap-2 items-center pr-6">
            <DropdownMenu
              triggerButton={
                <Button
                  size={BadgeSize.Medium}
                  analyticsTrackingId={"activity-log-mode"}
                  icon={IconEnum.ChevronDown}
                  theme={ButtonTheme.Tertiary}
                  iconPosition="right"
                >
                  {activityLogMode === "highlights"
                    ? "Highlights"
                    : "All activity"}
                </Button>
              }
            >
              <DropdownMenuItem
                analyticsTrackingId={"activity-log-mode-highlights"}
                label="Highlights"
                onSelect={() => setActivityLogMode("highlights")}
              />
              <DropdownMenuItem
                analyticsTrackingId={"activity-log-mode-all"}
                label="All activity"
                onSelect={() => setActivityLogMode("all")}
              />
            </DropdownMenu>
            <ToggleSubscriptionButton
              size={BadgeSize.Medium}
              incidentId={incident.id}
            />
            <Button
              size={BadgeSize.Medium}
              icon={IconEnum.Add}
              analyticsTrackingId={"share-update"}
              onClick={() =>
                navigateToModal(IncidentHeaderModal.UpdateIncident)
              }
            >
              Share update
            </Button>
          </div>
        }
      >
        <IncidentActivity
          incidentId={incident.id}
          mode={activityLogMode}
          isClosed={isClosed}
        />
      </SectionWrapper>
    </div>
  );
};

export const isIncidentClosed = (incident: Incident) => {
  return (
    [
      IncidentStatusCategoryEnum.Declined,
      IncidentStatusCategoryEnum.Merged,
      IncidentStatusCategoryEnum.Closed,
      IncidentStatusCategoryEnum.Canceled,
    ].includes(incident.incident_status.category) ||
    incident.mode === IncidentModeEnum.Retrospective
  );
};

export const isIncidentClosedOrResolved = (incident: Incident) => {
  return (
    incident.incident_status.category ===
      IncidentStatusCategoryEnum.PostIncident || isIncidentClosed(incident)
  );
};
