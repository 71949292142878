import type { SVGProps } from "react";
import * as React from "react";
const SvgTrendUp = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.25 6a.75.75 0 0 0 0 1.5h3.19l-4.69 4.69-3.117-3.116a1.25 1.25 0 0 0-1.767 0L2.22 12.72a.75.75 0 0 0 1.06 1.06l3.47-3.469 3.116 3.116a1.25 1.25 0 0 0 1.767 0L16.5 8.561v3.189a.75.75 0 0 0 1.5 0v-5a.75.75 0 0 0-.75-.75h-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrendUp;
