import { Form } from "@incident-shared/forms";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { Timeline } from "@incident-shared/timeline/Timeline";
import { TimelineItem } from "@incident-shared/timeline/TimelineItem";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme, Icon, IconEnum, ModalFooter } from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { add, differenceInCalendarDays, format, isSameDay } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ActivityItemTypeToComponentMap } from "src/components/incident-timeline/activity-items/ActivityItem";
import { ActivityActorBadge } from "src/components/incident-timeline/ActivityLogItemUI";
import {
  GroupedIncidentActivityLog,
  IncidentActivityLogEntry,
  IncidentActivityLogEntryTypeEnum,
  IncidentTimelineListActivityLogTypesEnum,
  IncidentUpdate,
  StatusPageIncidentSlim,
  StatusPageIncidentUpdate,
  StatusPageSlim,
  TextDocumentPayload,
} from "src/contexts/ClientContext";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import { useUpdateRequested } from "../header/RequestUpdateModal";

export type Update = { occurredAt: Date; id: string } & (
  | {
      incident_update: IncidentUpdate;
      status_page_incident?: never;
      status_page_incident_update?: never;
      status_page?: never;
    }
  | {
      incident_update?: never;
      status_page: StatusPageSlim;
      status_page_incident: StatusPageIncidentSlim;
      status_page_incident_update: StatusPageIncidentUpdate;
    }
);

export type ActivityLogMode = "highlights" | "all";

export const IncidentActivity = ({
  incidentId,
  mode,
  isClosed,
}: {
  incidentId: string | null;
  mode: ActivityLogMode;
  isClosed: boolean;
}) => {
  const navigateToModal = useNavigateToModal();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    data: { activities },
    isLoading,
  } = useAPI(
    incidentId ? "incidentTimelineListActivityLog" : null,
    {
      incidentId: incidentId ?? "",
      timezone,
      types:
        mode === "highlights"
          ? [
              IncidentTimelineListActivityLogTypesEnum.IncidentUpdate,
              IncidentTimelineListActivityLogTypesEnum.StatusPageIncidentUpdated,
              IncidentTimelineListActivityLogTypesEnum.StatusChange, // The old version of incident updates
            ]
          : undefined,
    },
    {
      fallbackData: { activities: [] },
    },
  );

  if (incidentId == null || isLoading) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  return (
    <div>
      <div className="flex items-center justify-end"></div>
      <IncidentActivityInner
        incidentId={incidentId}
        activities={activities}
        isClosed={isClosed}
        onUpdateRequested={() =>
          navigateToModal(IncidentHeaderModal.RequestUpdate)
        }
        mode={mode}
      />
    </div>
  );
};

export const IncidentActivityInner = ({
  incidentId,
  activities,
  isClosed,
  onUpdateRequested,
  mode,
}: {
  incidentId: string;
  activities: GroupedIncidentActivityLog[];
  isClosed: boolean;
  onUpdateRequested: () => void;
  mode: ActivityLogMode;
}) => {
  const [editUpdate, setEditUpdate] =
    useState<IncidentActivityLogEntry["content"]["incident_update"]>();
  const { updateRequestedSinceMostRecentUpdate, updateRequestedForIDs } =
    useUpdateRequested();

  // find the last group that has an incident update in it
  const lastDateGroupWithUpdate = activities.findLast((group) =>
    group.items.some(
      (item) =>
        item.activity_log?.type ===
        IncidentActivityLogEntryTypeEnum.IncidentUpdate,
    ),
  );
  const mostRecentUpdate = lastDateGroupWithUpdate?.items.findLast(
    (entry) =>
      entry.activity_log?.type ===
      IncidentActivityLogEntryTypeEnum.IncidentUpdate,
  );

  const nextUpdateInMinutes =
    mostRecentUpdate?.activity_log?.content.incident_update
      ?.next_update_in_minutes;

  const nextUpdateTime = nextUpdateInMinutes
    ? add(mostRecentUpdate.timestamp, {
        minutes: nextUpdateInMinutes,
      })
    : new Date();
  const nextUpdateDays = getNextUpdateDays(nextUpdateTime);

  const nextUpdateInMessage = nextUpdateInMinutes
    ? `Next update expected at ${format(
        nextUpdateTime,
        "HH:mm",
      )}${nextUpdateDays}`
    : "No update scheduled";

  const updateRequestedThisSession = updateRequestedForIDs?.has(incidentId);

  const showUpdateRequestedMessage =
    updateRequestedThisSession &&
    mostRecentUpdate &&
    updateRequestedSinceMostRecentUpdate(mostRecentUpdate.timestamp);

  const renderItem = ({
    item,
    hideSpacer,
    compact,
  }: {
    item: IncidentActivityLogEntry;
    hideSpacer: boolean;
    compact?: boolean;
  }) => {
    const activityProps = ActivityItemTypeToComponentMap[item.type]?.(item);

    const icon = activityProps?.icon ?? IconEnum.Bookmark;
    const color = activityProps?.colour ?? ColorPaletteEnum.Pink;
    const quotedContent = activityProps?.quotedContent;
    const unquotedContent = activityProps?.unquotedContent;

    const title = (
      <div className="flex-center-y gap-2">
        {item.default_title}
        <ActivityActorBadge actor={activityProps?.actor} />
      </div>
    );

    return (
      <TimelineItem
        id={item.id}
        timestamp={item.occurred_at}
        onEdit={
          item.type === IncidentActivityLogEntryTypeEnum.IncidentUpdate &&
          item.content.incident_update?.update_text
            ? () => setEditUpdate(item.content.incident_update)
            : undefined
        }
        hasChildren
        title={title}
        icon={icon}
        color={color}
        allowCommenting={false}
        hideSpacer={hideSpacer && isClosed}
        spacerStyle={hideSpacer ? "dashed" : "stroke"}
      >
        <div className="max-w-[600px] space-y-2">
          {quotedContent && (
            <div className={tcx("border-l-2 pl-3 text-content-secondary")}>
              {quotedContent}
            </div>
          )}
          {unquotedContent && !compact && (
            <div className={tcx("text-content-secondary")}>
              {unquotedContent}
            </div>
          )}
        </div>
      </TimelineItem>
    );
  };

  return (
    <>
      <Timeline<IncidentActivityLogEntry, "activity_log">
        accessorKey={"activity_log"}
        items={activities}
        minimizedItems={[]}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setMinimizedItems={() => {}}
        supportsMinimizing={false}
        renderItem={renderItem}
        renderCompactItems={mode === "highlights"}
        hideHeader={true}
        trailingItem={
          !isClosed ? (
            <TimelineItem
              title={
                <div className="flex items-center font-normal gap-2">
                  <div>{nextUpdateInMessage}</div>
                  {showUpdateRequestedMessage ? (
                    <span className="text-green-600 flex items-center space-x-2">
                      <Icon id={IconEnum.Checkmark} /> Update requested
                    </span>
                  ) : (
                    <Button
                      analyticsTrackingId={"request-update"}
                      theme={ButtonTheme.Naked}
                      onClick={onUpdateRequested}
                    >
                      Request update
                    </Button>
                  )}
                </div>
              }
              spacerStyle="dashed"
              hideSpacer
              allowCommenting={false}
              color={ColorPaletteEnum.SlateOnWhite}
              iconClassName="border-dashed"
              icon={IconEnum.Clock}
              id=""
              timestamp={null}
            />
          ) : undefined
        }
      />
      {editUpdate && editUpdate.update_id && editUpdate.update_text && (
        <EditUpdate
          incidentId={incidentId}
          message={editUpdate.update_text}
          onClose={() => setEditUpdate(undefined)}
          updateId={editUpdate.update_id}
        />
      )}
    </>
  );
};

const getNextUpdateDays = (nextUpdateTime: Date): string => {
  if (isSameDay(Date.now(), nextUpdateTime)) {
    return " today";
  }
  const diff = differenceInCalendarDays(nextUpdateTime, Date.now());
  if (diff > 0) {
    return `, in ${diff} day${diff > 1 ? "s" : ""}`;
  }
  return `, ${-diff} day${-diff > 1 ? "s" : ""} ago`;
};

type EditUpdateFormData = {
  message: TextDocumentPayload;
};

type EditUpdateProps = {
  incidentId: string;
  updateId: string;
  message: TextDocumentPayload;
  onClose: () => void;
};

const EditUpdate = ({
  incidentId,
  updateId,
  message,
  onClose,
}: EditUpdateProps) => {
  const formMethods = useForm<EditUpdateFormData>({
    defaultValues: { message: message },
  });

  const refetchTimeline = useRevalidate(["incidentTimelineListActivityLog"]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentUpdatesListForIncident",
    { incidentId: incidentId },
    async (apiClient, data: EditUpdateFormData) => {
      await apiClient.incidentUpdatesUpdate({
        id: updateId,
        updateRequestBody: {
          message: data.message,
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
        refetchTimeline();
      },
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal
      onClose={onClose}
      analyticsTrackingId={null}
      title="Edit update"
      formMethods={formMethods}
      genericError={genericError}
      saving={saving}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          confirmButtonText="Save"
          onClose={onClose}
          saving={saving}
        />
      }
    >
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="message.text_node"
        required={false}
        format="slack_rich_text"
        includeExpressions={false}
        includeVariables={false}
      />
    </Form.Modal>
  );
};
