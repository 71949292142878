/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageContentComponentImpact,
    StatusPageContentComponentImpactFromJSON,
    StatusPageContentComponentImpactFromJSONTyped,
    StatusPageContentComponentImpactToJSON,
} from './StatusPageContentComponentImpact';
import {
    StatusPageContentComponentUptime,
    StatusPageContentComponentUptimeFromJSON,
    StatusPageContentComponentUptimeFromJSONTyped,
    StatusPageContentComponentUptimeToJSON,
} from './StatusPageContentComponentUptime';
import {
    StatusPageContentIncidentLink,
    StatusPageContentIncidentLinkFromJSON,
    StatusPageContentIncidentLinkFromJSONTyped,
    StatusPageContentIncidentLinkToJSON,
} from './StatusPageContentIncidentLink';

/**
 * 
 * @export
 * @interface StatusPageContentListComponentImpactsResponseBody
 */
export interface StatusPageContentListComponentImpactsResponseBody {
    /**
     * Component impacts that exist within this time range
     * @type {Array<StatusPageContentComponentImpact>}
     * @memberof StatusPageContentListComponentImpactsResponseBody
     */
    component_impacts: Array<StatusPageContentComponentImpact>;
    /**
     * Calculated over the given window, the uptime for each component
     * @type {Array<StatusPageContentComponentUptime>}
     * @memberof StatusPageContentListComponentImpactsResponseBody
     */
    component_uptimes: Array<StatusPageContentComponentUptime>;
    /**
     * Incidents that are referenced by the impacts
     * @type {Array<StatusPageContentIncidentLink>}
     * @memberof StatusPageContentListComponentImpactsResponseBody
     */
    incident_links: Array<StatusPageContentIncidentLink>;
}

export function StatusPageContentListComponentImpactsResponseBodyFromJSON(json: any): StatusPageContentListComponentImpactsResponseBody {
    return StatusPageContentListComponentImpactsResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentListComponentImpactsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentListComponentImpactsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_impacts': ((json['component_impacts'] as Array<any>).map(StatusPageContentComponentImpactFromJSON)),
        'component_uptimes': ((json['component_uptimes'] as Array<any>).map(StatusPageContentComponentUptimeFromJSON)),
        'incident_links': ((json['incident_links'] as Array<any>).map(StatusPageContentIncidentLinkFromJSON)),
    };
}

export function StatusPageContentListComponentImpactsResponseBodyToJSON(value?: StatusPageContentListComponentImpactsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_impacts': ((value.component_impacts as Array<any>).map(StatusPageContentComponentImpactToJSON)),
        'component_uptimes': ((value.component_uptimes as Array<any>).map(StatusPageContentComponentUptimeToJSON)),
        'incident_links': ((value.incident_links as Array<any>).map(StatusPageContentIncidentLinkToJSON)),
    };
}

