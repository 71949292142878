import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  Checkbox,
  ContentBox,
  StaticSingleSelect,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useState } from "react";
import {
  Schedule,
  ScheduleExternalProviderEnum,
  SchedulePayConfig,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { descriptionForPayConfig } from "src/routes/legacy/on-call-pay/OnCallPayConfigsListRoute";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

export const SelectableSchedule = ({
  schedule,
  allPayConfigs,
  payConfig,
  isSelected,
  onChangeSelected,
  onSelectPayConfig,
  onAddPayConfig,
  hasMultipleScheduleProviders,
}: {
  hasMultipleScheduleProviders: boolean;
  schedule: Schedule;
  allPayConfigs: SchedulePayConfig[];
  payConfig?: SchedulePayConfig;
  isSelected: boolean;
  onChangeSelected: () => void;
  onSelectPayConfig: (config: SchedulePayConfig) => void;
  onAddPayConfig: () => void;
}): React.ReactElement => {
  const [showSelectPayConfig, setShowSelectPayConfig] = useState(false);

  const selectPayConfigComponent = (
    <div className="text-content-tertiary text-sm">
      <div className="flex-center-y mb-2">
        <StaticSingleSelect
          className="w-full mr-2"
          // @ts-expect-error TS is a bit stressed, but not for any good reason.
          onChange={(configID: string) => {
            const selectedConfig = allPayConfigs.find((x) => x.id === configID);
            if (!selectedConfig) {
              return;
            }
            onSelectPayConfig(selectedConfig);
            setShowSelectPayConfig(false);
          }}
          placeholder="Select existing pay configuration"
          isClearable={false}
          options={allPayConfigs.map((config) => ({
            value: config.id,
            label: config.name,
            sort_key: config.name.toLowerCase(),
          }))}
          value={payConfig?.id}
        />
        or{" "}
        <GatedButton
          analyticsTrackingId="report-generator-add-pay-config"
          className="ml-2"
          onClick={onAddPayConfig}
          requiredScope={ScopeNameEnum.SchedulePayConfigsCreate}
        >
          Add new configuration
        </GatedButton>
      </div>
    </div>
  );

  let providerName = "";
  switch (schedule.external_provider) {
    case ScheduleExternalProviderEnum.Native:
      providerName = "incident.io";
      break;
    case ScheduleExternalProviderEnum.Pagerduty:
      providerName = "PagerDuty";
      break;
    case ScheduleExternalProviderEnum.Opsgenie:
      providerName = "Opsgenie";
      break;
    default:
      assertUnreachable(schedule.external_provider);
  }

  return (
    <li
      className={tcx("p-6 w-full", {
        "!bg-surface-secondary border-stroke": isSelected,
      })}
    >
      <div
        className="md:flex-align-start cursor-pointer"
        onClick={onChangeSelected}
      >
        <Checkbox
          id={schedule.id}
          value={schedule.id}
          checked={isSelected}
          onChange={onChangeSelected}
          className="mt-1 mr-2"
        />
        <div className={"grow"}>
          <p className={"flex flex-row items-center font-medium text-md"}>
            {schedule.name}{" "}
            {hasMultipleScheduleProviders && (
              <Txt grey className={"ml-2"}>
                ({providerName})
              </Txt>
            )}
          </p>
        </div>
      </div>
      {isSelected && (
        <ContentBox className="p-4 mt-4 border-stroke">
          {payConfig ? (
            showSelectPayConfig ? (
              selectPayConfigComponent
            ) : (
              <div className="flex-center-y">
                <div className="grow">
                  <p className="!mb-1">{payConfig.name}</p>
                  <p className="text-content-tertiary text-sm">
                    {descriptionForPayConfig(payConfig)}
                  </p>
                </div>
                <Button
                  analyticsTrackingId="report-generator-change-pay-config"
                  onClick={() => setShowSelectPayConfig(true)}
                >
                  Use another configuration
                </Button>
              </div>
            )
          ) : allPayConfigs.length === 0 ? (
            <div className="flex-center-y">
              <div className="text-content-tertiary text-sm grow">
                You don&apos;t have any pay configurations yet.{" "}
                <Tooltip
                  content={
                    <div className="p-2">
                      A pay configuration is where you tell us what you pay for
                      on-call e.g. your base rate, different rates for certain
                      days or public holidays, and so on.
                    </div>
                  }
                >
                  <span className="text-sm underline">What&apos;s this?</span>
                </Tooltip>
              </div>
              <Button
                analyticsTrackingId="report-generator-add-pay-config"
                onClick={onAddPayConfig}
              >
                Add new configuration
              </Button>
            </div>
          ) : (
            selectPayConfigComponent
          )}
        </ContentBox>
      )}
    </li>
  );
};
