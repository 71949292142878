import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItemButtonsProps } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import {
  BadgeTheme,
  ButtonTheme,
  IconEnum,
  StackedList,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import {
  PostmortemSharesDestroyTemplateRequest,
  PostmortemShareTemplate,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../SettingsSubHeading";

export const PostmortemShareTemplatesList = ({
  shareTemplates,
}: {
  shareTemplates: PostmortemShareTemplate[];
}): React.ReactElement => {
  return (
    <div>
      <SettingsSubHeading
        title="Post-mortem sharing"
        titleHeadingLevel={2}
        explanation={
          <>
            Define the structure of the messages that are sent to your team when
            a post-mortem is shared. You&apos;ll be able to choose which
            template to use when you go to share a post-mortem.
          </>
        }
        className="!mb-4"
        accessory={
          <GatedButton
            href="/settings/post-mortem/shares/templates/create"
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            analyticsTrackingId="create-postmortem-template"
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
          >
            Add template
          </GatedButton>
        }
      />
      <StackedList>
        {_.orderBy(shareTemplates, ["is_default", "name"], ["desc", "asc"]).map(
          (template, index) => (
            <PostmortemShareTemplateRow key={index} shareTemplate={template} />
          ),
        )}
      </StackedList>
    </div>
  );
};

const PostmortemShareTemplateRow = ({
  shareTemplate,
}: {
  shareTemplate: PostmortemShareTemplate;
}): React.ReactElement => {
  const { trigger: destroyTemplate } = useAPIMutation(
    "postmortemSharesListTemplates",
    undefined,
    async (apiClient, data: PostmortemSharesDestroyTemplateRequest) => {
      await apiClient.postmortemSharesDestroyTemplate(data);
    },
  );

  const { hasScope } = useIdentity();

  const buttons: SettingsListItemButtonsProps = {
    requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
    delete: {
      resourceTitle: shareTemplate.name,
      deleteConfirmationTitle: "Delete post-mortem template",
      deleteConfirmationContent: (
        <Txt>
          Are you sure you want to delete the{" "}
          <Txt inline bold>
            {shareTemplate.name}
          </Txt>{" "}
          template?
        </Txt>
      ),
      onDelete: () => destroyTemplate({ id: shareTemplate.id }),
      isGatedText: shareTemplate.is_default
        ? "You can't delete the default template."
        : undefined,
    },
    edit: {
      editHref: `/settings/post-mortem/shares/templates/${shareTemplate.id}/edit`,
    },
    view: {
      viewHref: `/settings/post-mortem/shares/templates/${shareTemplate.id}/edit`,
    },
  };

  if (hasScope(ScopeNameEnum.OrganisationSettingsUpdate)) {
    delete buttons.view;
  } else {
    delete buttons.edit;
  }

  return (
    <SettingsListItem
      title={shareTemplate.name}
      badgeProps={
        shareTemplate.is_default
          ? { label: "Default", theme: BadgeTheme.Tertiary }
          : undefined
      }
      buttons={buttons}
    />
  );
};
