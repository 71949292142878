import type { SVGProps } from "react";
import * as React from "react";
const SvgFile = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 2h-8.5A2.752 2.752 0 0 0 3 4.75v10.5A2.752 2.752 0 0 0 5.75 18h8.5A2.752 2.752 0 0 0 17 15.25V4.75A2.752 2.752 0 0 0 14.25 2ZM6 5.75A.75.75 0 0 1 6.75 5h1.5a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-.75.75h-1.5A.75.75 0 0 1 6 7.25v-1.5ZM13.25 14h-6.5a.75.75 0 0 1 0-1.5h6.5a.75.75 0 0 1 0 1.5Zm0-3h-6.5a.75.75 0 0 1 0-1.5h6.5a.75.75 0 0 1 0 1.5Zm0-3h-2a.75.75 0 0 1 0-1.5h2a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgFile;
