import { Route, Routes } from "react-router";

import { SetupDeepLinkToSlackSignInRedirect } from "./slack/SetupDeepLinkToSlackSignInRedirect";
import { SetupLoginSlack } from "./slack/SetupLoginSlack";

export const SetupLoginUnauthenticatedRoute = () => {
  return (
    <Routes>
      <Route path="" element={<SetupLoginSlack />} />
      <Route
        path="deep-link"
        element={<SetupDeepLinkToSlackSignInRedirect />}
      />
    </Routes>
  );
};
