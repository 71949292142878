import { Route, Routes } from "react-router";

import { DebriefsViewPage } from "./DebriefsViewPage";

export const SettingsDebriefsRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<DebriefsViewPage />} />
    </Routes>
  );
};
