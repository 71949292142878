import type { SVGProps } from "react";
import * as React from "react";
const SvgStatusChevron = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 12 32"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m1 0 9.846 16L1 32"
    />
  </svg>
);
export default SvgStatusChevron;
