/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionBranch,
    ExpressionBranchFromJSON,
    ExpressionBranchFromJSONTyped,
    ExpressionBranchToJSON,
} from './ExpressionBranch';
import {
    ReturnsMeta,
    ReturnsMetaFromJSON,
    ReturnsMetaFromJSONTyped,
    ReturnsMetaToJSON,
} from './ReturnsMeta';

/**
 * 
 * @export
 * @interface ExpressionBranchesOpts
 */
export interface ExpressionBranchesOpts {
    /**
     * The branches to apply for this operation
     * @type {Array<ExpressionBranch>}
     * @memberof ExpressionBranchesOpts
     */
    branches: Array<ExpressionBranch>;
    /**
     * 
     * @type {ReturnsMeta}
     * @memberof ExpressionBranchesOpts
     */
    returns: ReturnsMeta;
}

export function ExpressionBranchesOptsFromJSON(json: any): ExpressionBranchesOpts {
    return ExpressionBranchesOptsFromJSONTyped(json, false);
}

export function ExpressionBranchesOptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionBranchesOpts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branches': ((json['branches'] as Array<any>).map(ExpressionBranchFromJSON)),
        'returns': ReturnsMetaFromJSON(json['returns']),
    };
}

export function ExpressionBranchesOptsToJSON(value?: ExpressionBranchesOpts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branches': ((value.branches as Array<any>).map(ExpressionBranchToJSON)),
        'returns': ReturnsMetaToJSON(value.returns),
    };
}

