/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomFieldCatalogInfo
 */
export interface CustomFieldCatalogInfo {
    /**
     * Color of the catalog type this custom field is synced from
     * @type {string}
     * @memberof CustomFieldCatalogInfo
     */
    CatalogTypeColor: CustomFieldCatalogInfoCatalogTypeColorEnum;
    /**
     * ID of the catalog type this custom field is synced from
     * @type {string}
     * @memberof CustomFieldCatalogInfo
     */
    CatalogTypeID: string;
    /**
     * Icon of the catalog type this custom field is synced from
     * @type {string}
     * @memberof CustomFieldCatalogInfo
     */
    CatalogTypeIcon: CustomFieldCatalogInfoCatalogTypeIconEnum;
}

/**
* @export
* @enum {string}
*/
export enum CustomFieldCatalogInfoCatalogTypeColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum CustomFieldCatalogInfoCatalogTypeIconEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}

export function CustomFieldCatalogInfoFromJSON(json: any): CustomFieldCatalogInfo {
    return CustomFieldCatalogInfoFromJSONTyped(json, false);
}

export function CustomFieldCatalogInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldCatalogInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CatalogTypeColor': json['CatalogTypeColor'],
        'CatalogTypeID': json['CatalogTypeID'],
        'CatalogTypeIcon': json['CatalogTypeIcon'],
    };
}

export function CustomFieldCatalogInfoToJSON(value?: CustomFieldCatalogInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CatalogTypeColor': value.CatalogTypeColor,
        'CatalogTypeID': value.CatalogTypeID,
        'CatalogTypeIcon': value.CatalogTypeIcon,
    };
}

