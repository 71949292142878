/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentIncidentLink
 */
export interface StatusPageContentIncidentLink {
    /**
     * A unique ID for this status page incident
     * @type {string}
     * @memberof StatusPageContentIncidentLink
     */
    id: string;
    /**
     * A title for the incident
     * @type {string}
     * @memberof StatusPageContentIncidentLink
     */
    name: string;
    /**
     * The permalink to this incident
     * @type {string}
     * @memberof StatusPageContentIncidentLink
     */
    permalink?: string;
    /**
     * When the incident was published
     * @type {string}
     * @memberof StatusPageContentIncidentLink
     */
    published_at: string;
    /**
     * Current status for this incident
     * @type {string}
     * @memberof StatusPageContentIncidentLink
     */
    status: StatusPageContentIncidentLinkStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageContentIncidentLinkStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function StatusPageContentIncidentLinkFromJSON(json: any): StatusPageContentIncidentLink {
    return StatusPageContentIncidentLinkFromJSONTyped(json, false);
}

export function StatusPageContentIncidentLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentIncidentLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'permalink': !exists(json, 'permalink') ? undefined : json['permalink'],
        'published_at': json['published_at'],
        'status': json['status'],
    };
}

export function StatusPageContentIncidentLinkToJSON(value?: StatusPageContentIncidentLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'permalink': value.permalink,
        'published_at': value.published_at,
        'status': value.status,
    };
}

