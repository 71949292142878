import { Mode } from "@incident-shared/forms/v2/formsv2";
import { HeaderBarTitle } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useRef, useState } from "react";
import {
  Workflow,
  WorkflowsShowWorkflowResponseBody,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { useQueryParams } from "../../../../utils/query-params";
// TODO: RESP-345, Move the import of ClonedWorkflow to workflowsv2
import { ClonedWorkflow } from "../common/types";
import { WorkflowsCreateEditForm } from "../create-edit-form/WorkflowsCreateEditForm";

type AddEditProps<M> = M extends Mode.Create
  ? {
      workflow?: Workflow | ClonedWorkflow;
      templateName?: string;
      backHref?: string;
    }
  : {
      workflow: Workflow;
    };

type WorkflowsCreatePageProps<M extends Mode> = {
  mode: Mode;
} & AddEditProps<M>;

export function WorkflowsCreatePage<M extends Mode>(
  props: WorkflowsCreatePageProps<M>,
): React.ReactElement {
  const { workflow, mode } = props;

  // We are using a portal to render an input in the page header
  // We need to wait til the page has loaded before trying to portal
  // to the input
  const titleInputRef = useRef<HTMLDivElement>(null);
  const submitButtonsRef = useRef<HTMLDivElement>(null);
  const [domReady, setDomReady] = useState(false);
  // Pull out the trigger from query params.
  const queryParams = useQueryParams();
  const cloneID = queryParams.get("clone");
  let templateName: string | undefined;
  let triggerName: string | undefined;
  let backHref: string | undefined;

  if (mode === Mode.Create && !workflow) {
    const createProps: AddEditProps<Mode.Create> =
      props as AddEditProps<Mode.Create>;
    templateName =
      createProps.templateName || queryParams.get("template") || undefined;
    triggerName = queryParams.get("trigger") || undefined;

    backHref = createProps.backHref;
  }

  // If we're in template mode, load the template
  const {
    data: template,
    isLoading: templateLoading,
    error: templateError,
  } = useAPI(templateName ? "workflowsShowWorkflowTemplate" : null, {
    name: templateName ?? "",
  });

  // If we're in trigger mode, load the trigger
  const {
    data: trigger,
    isLoading: triggerLoading,
    error: triggerError,
  } = useAPI(triggerName ? "workflowsShowTrigger" : null, {
    name: triggerName ?? "",
  });

  // If we're in clone mode, load the workflow to be cloned
  const {
    data: workflowToClone,
    isLoading: cloneLoading,
    error: cloneError,
  } = useAPI(cloneID ? "workflowsShowWorkflow" : null, {
    id: cloneID ?? "",
  });

  useEffect(() => {
    if (templateLoading || cloneLoading || triggerLoading) {
      setDomReady(false);
    } else {
      setDomReady(true);
    }
  }, [templateLoading, cloneLoading, triggerLoading]);

  if (templateError || cloneError || triggerError) {
    return (
      <GenericErrorMessage
        error={templateError || cloneError || triggerError}
      />
    );
  }

  // We can't mount the form until we've loaded the trigger
  if (triggerName && !trigger) {
    return <FullPageLoader />;
  }

  return (
    <PageWrapper
      width={PageWidth.Full}
      icon={IconEnum.Workflows}
      loading={templateLoading || cloneLoading}
      title="Create workflow"
      overflowX
      dottedBackground
      titleNode={
        <HeaderBarTitle
          crumbs={[
            {
              title: "Workflows",
              to: "/workflows",
            },
          ]}
          title="Create workflow"
          titleNode={
            <div
              className="flex flex-row items-baseline grow"
              ref={titleInputRef}
            />
          }
          isEditable
        />
      }
      backHref="/workflows"
      accessory={
        <div className="flex flex-row flex-center-y space-x-2">
          <div ref={submitButtonsRef} />
        </div>
      }
    >
      <WorkflowsCreateEditForm
        mode={Mode.Create}
        template={
          workflowToClone ? createClone(workflowToClone) : template?.workflow
        }
        templateName={templateName}
        backHref={backHref}
        workflow={workflow}
        defaultTrigger={trigger?.trigger}
        titleInputRef={titleInputRef}
        buttonsRef={submitButtonsRef}
        domReady={domReady}
      />
    </PageWrapper>
  );
}

// This is a hacky function used to generate references in the cloned workflow
// This tries as best as possible to replicate the output you'd get from a git short sha
const clientSideRandomString = (): string => {
  const random = Math.random().toString(36).substring(2, 6);
  const date = new Date().getTime().toString(36).substring(2, 6);
  return `${random}${date}`;
};

const createClone = (
  workflowResp: WorkflowsShowWorkflowResponseBody,
): ClonedWorkflow => {
  const workflowData = cloneDeep(workflowResp.workflow);
  workflowData.id = "";
  workflowData.name = `Copy of ${workflowData.name}`;
  workflowData.folder = workflowResp.workflow.folder;
  const newExpressionReferences: { oldRef: string; newRef: string }[] = [];
  workflowData.expressions = workflowData.expressions.map((expr) => {
    const newReference = clientSideRandomString();
    newExpressionReferences.push({
      oldRef: expr.reference,
      newRef: newReference,
    });
    const newExpression = expr;
    newExpression.reference = newReference;
    return newExpression;
  });
  let workflowDataString = JSON.stringify(workflowData);
  newExpressionReferences.forEach((ref) => {
    workflowDataString = workflowDataString.replaceAll(ref.oldRef, ref.newRef);
  });
  return JSON.parse(workflowDataString);
};
