import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import { IconEnum } from "@incident-ui";
import _ from "lodash";
import {
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
  StatusPageComponentImpact,
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
  StatusPageStructure,
  StatusPageStructureComponent,
} from "src/contexts/ClientContext";
import { Environment, getEnvironment } from "src/utils/environment";

export const statusPageDefaultDomain = (): string => {
  const env = getEnvironment();
  return env === Environment.Production
    ? "statuspage.incident.io"
    : env === Environment.Staging
    ? "statuspage.staging.incident.io"
    : "localhost:3010";
};

export const statusIsWorseThan = (
  a: ComponentStatusEnum,
  b: ComponentStatusEnum,
): boolean => {
  return COMPONENT_STATUS_CONFIG[a].rank < COMPONENT_STATUS_CONFIG[b].rank;
};

export const currentWorstImpactsForPage = (
  incidents: StatusPageIncident[],
): StatusPageComponentImpact[] => {
  const ongoingImpacts = incidents
    .filter(
      (incident) => incident.status !== StatusPageIncidentStatusEnum.Resolved,
    )
    .flatMap((incident) => incident.component_impacts)
    .filter((impact) => impact.end_at === undefined);

  return Object.values(
    _.groupBy(ongoingImpacts, "status_page_component_id"),
  ).map((impacts) =>
    impacts.reduce(
      (worstImpact, impact) =>
        statusIsWorseThan(
          worstImpact.status as unknown as ComponentStatusEnum,
          impact.status as unknown as ComponentStatusEnum,
        )
          ? impact
          : worstImpact,
      impacts[0],
    ),
  );
};

export type ComponentStatusConfig<StatusEnum extends string | number> = {
  [key in StatusEnum]: {
    rank: number;
    label: string;
    icon: IconEnum;
    colour: string;
    barColour: string;
    textColour?: string;
    border: string;
    background: string;
  };
};

export const forEachStructureItem = <T>(
  structure: StatusPageStructure,
  render: (item: StatusPageStructureComponent) => T,
): T[] => {
  return structure.items.flatMap(({ component, group }) => {
    if (component) {
      return [render(component)];
    }

    if (group) {
      return group.components.map((component) => {
        return render(component);
      });
    }

    return [];
  });
};
