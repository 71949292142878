import type { SVGProps } from "react";
import * as React from "react";
const SvgDoc = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16.487 6.427-3.914-3.914A1.738 1.738 0 0 0 11.336 2H5.75A2.752 2.752 0 0 0 3 4.75v10.5A2.752 2.752 0 0 0 5.75 18h8.5A2.752 2.752 0 0 0 17 15.25V7.664c0-.467-.182-.907-.513-1.237ZM6.75 7h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1 0-1.5Zm6.5 7.5h-6.5a.75.75 0 0 1 0-1.5h6.5a.75.75 0 0 1 0 1.5Zm0-3h-6.5a.75.75 0 0 1 0-1.5h6.5a.75.75 0 0 1 0 1.5Zm2.182-4H12.5c-.55 0-1-.45-1-1V3.579l.013-.005 3.922 3.921-.003.005Z"
    />
  </svg>
);
export default SvgDoc;
