/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FollowUpStatisticsRow,
    FollowUpStatisticsRowFromJSON,
    FollowUpStatisticsRowFromJSONTyped,
    FollowUpStatisticsRowToJSON,
} from './FollowUpStatisticsRow';

/**
 * 
 * @export
 * @interface FollowUpsStatisticsResponseBody
 */
export interface FollowUpsStatisticsResponseBody {
    /**
     * 
     * @type {Array<FollowUpStatisticsRow>}
     * @memberof FollowUpsStatisticsResponseBody
     */
    statistics: Array<FollowUpStatisticsRow>;
    /**
     * Total number of follow-ups in the dataset
     * @type {number}
     * @memberof FollowUpsStatisticsResponseBody
     */
    total_follow_up_count: number;
}

export function FollowUpsStatisticsResponseBodyFromJSON(json: any): FollowUpsStatisticsResponseBody {
    return FollowUpsStatisticsResponseBodyFromJSONTyped(json, false);
}

export function FollowUpsStatisticsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsStatisticsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statistics': ((json['statistics'] as Array<any>).map(FollowUpStatisticsRowFromJSON)),
        'total_follow_up_count': json['total_follow_up_count'],
    };
}

export function FollowUpsStatisticsResponseBodyToJSON(value?: FollowUpsStatisticsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statistics': ((value.statistics as Array<any>).map(FollowUpStatisticsRowToJSON)),
        'total_follow_up_count': value.total_follow_up_count,
    };
}

