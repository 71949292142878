/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InitialMessageConfig,
    InitialMessageConfigFromJSON,
    InitialMessageConfigFromJSONTyped,
    InitialMessageConfigToJSON,
} from './InitialMessageConfig';

/**
 * 
 * @export
 * @interface InitialMessageSettings
 */
export interface InitialMessageSettings {
    /**
     * 
     * @type {InitialMessageConfig}
     * @memberof InitialMessageSettings
     */
    config?: InitialMessageConfig;
    /**
     * Should an initial message be sent to each new incident channel
     * @type {boolean}
     * @memberof InitialMessageSettings
     */
    enabled: boolean;
}

export function InitialMessageSettingsFromJSON(json: any): InitialMessageSettings {
    return InitialMessageSettingsFromJSONTyped(json, false);
}

export function InitialMessageSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitialMessageSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : InitialMessageConfigFromJSON(json['config']),
        'enabled': json['enabled'],
    };
}

export function InitialMessageSettingsToJSON(value?: InitialMessageSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': InitialMessageConfigToJSON(value.config),
        'enabled': value.enabled,
    };
}

