import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useClipboard } from "src/utils/useClipboard";

export const YAMLViewer = ({
  title = "YAML",
  yaml,
}: {
  title?: string;
  yaml: string;
}) => {
  // Process the YAML content, replacing escaped newlines with actual newlines
  const processedYaml = yaml.replace(/\\n/g, "\n").replace(/\\t/g, "\t");

  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <div className="w-full bg-white rounded-2 shadow-sm border font-mono">
      <div className="flex justify-between items-center p-2 border-b">
        <span className="text-sm font-medium text-gray-700">{title}</span>
        <Button
          theme={ButtonTheme.Naked}
          onClick={() => copyTextToClipboard(processedYaml)}
          icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
          analyticsTrackingId={null}
        >
          Copy
        </Button>
      </div>
      <div className="p-4 bg-surface-secondary rounded-b-2 font-mono text-sm overflow-y-hidden">
        <pre className="whitespace-pre-wrap">
          {processedYaml.split("\n").map((line, index) => (
            <div
              key={index}
              // style={{
              //   paddingLeft: `${(line.match(/^\s*/)?.[0]?.length ?? 0) * 3}px`,
              // }}
              className="flex"
            >
              {line}
            </div>
          ))}
        </pre>
      </div>
    </div>
  );
};
