/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailableBacklinkV2
 */
export interface AvailableBacklinkV2 {
    /**
     * ID of the attribute that can be linked to
     * @type {string}
     * @memberof AvailableBacklinkV2
     */
    attribute_id: string;
    /**
     * What name should we give this attribute by default?
     * @type {string}
     * @memberof AvailableBacklinkV2
     */
    default_name: string;
    /**
     * Path to render for this virtual resource
     * @type {Array<string>}
     * @memberof AvailableBacklinkV2
     */
    path: Array<string>;
    /**
     * What catalog type would this virtual resource resolve to
     * @type {string}
     * @memberof AvailableBacklinkV2
     */
    type: string;
}

export function AvailableBacklinkV2FromJSON(json: any): AvailableBacklinkV2 {
    return AvailableBacklinkV2FromJSONTyped(json, false);
}

export function AvailableBacklinkV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableBacklinkV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute_id': json['attribute_id'],
        'default_name': json['default_name'],
        'path': json['path'],
        'type': json['type'],
    };
}

export function AvailableBacklinkV2ToJSON(value?: AvailableBacklinkV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute_id': value.attribute_id,
        'default_name': value.default_name,
        'path': value.path,
        'type': value.type,
    };
}

