/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOption,
    SelectOptionFromJSON,
    SelectOptionFromJSONTyped,
    SelectOptionToJSON,
} from './SelectOption';

/**
 * 
 * @export
 * @interface IssueTrackersJiraServerTypeaheadOptionsResponseBody
 */
export interface IssueTrackersJiraServerTypeaheadOptionsResponseBody {
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof IssueTrackersJiraServerTypeaheadOptionsResponseBody
     */
    typeahead_options?: Array<SelectOption>;
}

export function IssueTrackersJiraServerTypeaheadOptionsResponseBodyFromJSON(json: any): IssueTrackersJiraServerTypeaheadOptionsResponseBody {
    return IssueTrackersJiraServerTypeaheadOptionsResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersJiraServerTypeaheadOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersJiraServerTypeaheadOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeahead_options': !exists(json, 'typeahead_options') ? undefined : ((json['typeahead_options'] as Array<any>).map(SelectOptionFromJSON)),
    };
}

export function IssueTrackersJiraServerTypeaheadOptionsResponseBodyToJSON(value?: IssueTrackersJiraServerTypeaheadOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeahead_options': value.typeahead_options === undefined ? undefined : ((value.typeahead_options as Array<any>).map(SelectOptionToJSON)),
    };
}

