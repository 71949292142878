/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSourceConfigPreviewPayload,
    AlertSourceConfigPreviewPayloadFromJSON,
    AlertSourceConfigPreviewPayloadFromJSONTyped,
    AlertSourceConfigPreviewPayloadToJSON,
} from './AlertSourceConfigPreviewPayload';

/**
 * 
 * @export
 * @interface AlertsBulkPreviewSourceConfigAlertsRequestBody
 */
export interface AlertsBulkPreviewSourceConfigAlertsRequestBody {
    /**
     * 
     * @type {Array<AlertSourceConfigPreviewPayload>}
     * @memberof AlertsBulkPreviewSourceConfigAlertsRequestBody
     */
    alert_source_configs?: Array<AlertSourceConfigPreviewPayload>;
}

export function AlertsBulkPreviewSourceConfigAlertsRequestBodyFromJSON(json: any): AlertsBulkPreviewSourceConfigAlertsRequestBody {
    return AlertsBulkPreviewSourceConfigAlertsRequestBodyFromJSONTyped(json, false);
}

export function AlertsBulkPreviewSourceConfigAlertsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsBulkPreviewSourceConfigAlertsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_configs': !exists(json, 'alert_source_configs') ? undefined : ((json['alert_source_configs'] as Array<any>).map(AlertSourceConfigPreviewPayloadFromJSON)),
    };
}

export function AlertsBulkPreviewSourceConfigAlertsRequestBodyToJSON(value?: AlertsBulkPreviewSourceConfigAlertsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_configs': value.alert_source_configs === undefined ? undefined : ((value.alert_source_configs as Array<any>).map(AlertSourceConfigPreviewPayloadToJSON)),
    };
}

