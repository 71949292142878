import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import * as RadixToast from "@radix-ui/react-toast";
import { isEmpty } from "lodash";
import styles from "src/components/@ui/Toast/Toast.module.scss";
import { tcx } from "src/utils/tailwind-classes";

export type ToastProps = {
  /** The main message of the toast */
  title: string | React.ReactNode;
  /** Optional additional message */
  description?: string | React.ReactNode;
  /** Optional: pass in a theme to render it in a particular colourway/with an icon */
  theme?: ToastTheme;
  /** Optional: choose which side of the screen the toast should show up */
  toastSide?: ToastSideEnum;
  /** Optional: move the toast down so it displays below the app header bar. Redundant with toastSide. */
  toastBelowHeader?: boolean;
  /** Optional: override the default icon for this toast theme */
  iconOverride?: IconEnum;
  className?: string;
};

export enum ToastSideEnum {
  TopRight = "top-right",
  Bottom = "bottom-middle",
}

export enum ToastTheme {
  Info = "info",
  Success = "success",
  Error = "error",
  Warning = "warning",
}

type ThemeConfig = {
  iconClassName: string;
  icon: IconEnum;
};

const ThemeFor: { [key in ToastTheme]: ThemeConfig } = {
  [ToastTheme.Warning]: {
    icon: IconEnum.Warning,
    iconClassName: "text-amber-500",
  },
  [ToastTheme.Error]: {
    icon: IconEnum.Exclamation,
    iconClassName: "text-red-500",
  },
  [ToastTheme.Success]: {
    icon: IconEnum.Success,
    iconClassName: "text-green-500",
  },
  [ToastTheme.Info]: {
    icon: IconEnum.Info,
    iconClassName: "text-white",
  },
};

/**
 * This is the Toast message component that pops up and disappears after a set time.
 * You shouldn't need to use the actual `Toast` anywhere in your component -
 * there's one Toast to rule them all which we render in `App.tsx`. Instead, use the `useToast` hook:
 *
 * ```javascript
 * export function MyComponent() {
 *   const showToast = useToast()
 *
 *   function doThing() {
 *      doAThing();
 *      showToast({
 *        title: "A thing happened",
 *        description: "It was a very important thing",
 *        type: ToastTheme.Info,
 *      });
 *   }
 *```
 *
 * It disappears on its own after a few seconds.
 *
 * This component is useful for success/error messages. If your message requires user input, use a modal or confirmation dialog instead,
 * as those don't have time limits.
 */
export function Toast({
  open,
  className,
  setOpen,
  title,
  description,
  theme: themeKey,
  toastSide = ToastSideEnum.TopRight,
  toastBelowHeader,
  iconOverride,
}: ToastProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
}): React.ReactElement {
  const theme = themeKey ? ThemeFor[themeKey] : undefined;

  return (
    <>
      <RadixToast.Root
        open={open}
        onOpenChange={setOpen}
        className={tcx(
          styles.toast,
          "bg-surface-invert",
          {
            "flex-center": isEmpty(description),
          },
          "p-4 border rounded-2 shadow",
          {
            "mt-auto": ToastSideEnum.Bottom === toastSide,
          },
          className,
        )}
      >
        {theme && (
          <Icon
            id={iconOverride || theme.icon}
            size={IconSize.Medium}
            className={tcx("mr-2", theme.iconClassName)}
          />
        )}
        <div className="flex-1">
          {title && (
            <RadixToast.Title className="text-sm font-semibold text-white">
              {title}
            </RadixToast.Title>
          )}
          {!isEmpty(description) && (
            <RadixToast.Description className="text-sm text-white">
              {description}
            </RadixToast.Description>
          )}
        </div>
        <RadixToast.Close asChild>
          <Button
            theme={ButtonTheme.Unstyled}
            title="dismiss"
            icon={IconEnum.Close}
            iconProps={{ size: IconSize.Medium }}
            className="my-auto text-content-primary rounded-full bg-slate-600 hover:bg-white"
            type="button"
            analyticsTrackingId={null}
            onClick={() => setOpen(false)}
          />
        </RadixToast.Close>
      </RadixToast.Root>
      <RadixToast.Viewport
        className={tcx(styles.viewport, {
          "!mt-[60px]": toastBelowHeader,
          // TR is the default and doesn't need additional styles
          "!bottom-0 !top-[unset] inset-x-1/2 translate-x-[-50%]":
            ToastSideEnum.Bottom === toastSide,
        })}
      />
    </>
  );
}

export const SharedToasts = {
  SETTINGS_SAVED: { title: "Settings saved", theme: ToastTheme.Success },
  SETTINGS_SAVE_ERROR: {
    title: "Could not save settings",
    theme: ToastTheme.Error,
  },
};
