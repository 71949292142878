import type { SVGProps } from "react";
import * as React from "react";
const SvgTest = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.25 4.75h-8.5l.99 1.113a4 4 0 0 1 1.01 2.658v2.729l-5 8h14.5l-5-8V8.52a4 4 0 0 1 1.01-2.657l.99-1.113ZM7 15.75h10"
    />
  </svg>
);
export default SvgTest;
