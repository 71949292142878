/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteAlertSourcePayload,
    AlertRouteAlertSourcePayloadFromJSON,
    AlertRouteAlertSourcePayloadFromJSONTyped,
    AlertRouteAlertSourcePayloadToJSON,
} from './AlertRouteAlertSourcePayload';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    GroupingKey,
    GroupingKeyFromJSON,
    GroupingKeyFromJSONTyped,
    GroupingKeyToJSON,
} from './GroupingKey';

/**
 * 
 * @export
 * @interface AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody
 */
export interface AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody {
    /**
     * The alert sources to select, including any specific filters per source
     * @type {Array<AlertRouteAlertSourcePayload>}
     * @memberof AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody
     */
    alert_sources: Array<AlertRouteAlertSourcePayload>;
    /**
     * The conditions the alerts should meet
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * The expressions to use for the conditions
     * @type {Array<ExpressionPayload>}
     * @memberof AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody
     */
    expressions: Array<ExpressionPayload>;
    /**
     * The grouping keys these alerts should be grouped by
     * @type {Array<GroupingKey>}
     * @memberof AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody
     */
    grouping_keys: Array<GroupingKey>;
}

export function AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBodyFromJSON(json: any): AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody {
    return AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBodyFromJSONTyped(json, false);
}

export function AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertRouteAlertSourcePayloadFromJSON)),
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'grouping_keys': ((json['grouping_keys'] as Array<any>).map(GroupingKeyFromJSON)),
    };
}

export function AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBodyToJSON(value?: AlertRoutesPreviewFindAndGroupMatchingAlertsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertRouteAlertSourcePayloadToJSON)),
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'grouping_keys': ((value.grouping_keys as Array<any>).map(GroupingKeyToJSON)),
    };
}

