import { Identity } from "@incident-io/api";
import {
  IssueTemplateContextEnum,
  IssueTrackerProviderEnum,
  UnifiedIssueTemplate,
} from "@incident-shared/issue-trackers";
import { IconEnum } from "@incident-ui";

type Config = {
  createTemplateUrl: (provider: IssueTrackerProviderEnum) => string;
  gatedCap: (identity: Identity) => number | undefined;
  nounSingular: string;
  nounPlural: string;
  icon: IconEnum;
  editHref: (template: UnifiedIssueTemplate) => string;
};

export const ISSUE_TEMPLATE_CONTEXTS: Record<IssueTemplateContextEnum, Config> =
  {
    [IssueTemplateContextEnum.FollowUp]: {
      icon: IconEnum.FollowUps,
      createTemplateUrl: (provider) =>
        `/settings/follow-ups/templates/${provider}/create`,
      gatedCap: (identity) =>
        identity.feature_gates.follow_up_issue_templates_count,
      nounPlural: "Follow-up export templates",
      nounSingular: "Follow-up export template",
      editHref: ({ provider, id }) =>
        `/settings/follow-ups/templates/${provider}/${id}`,
    },
    [IssueTemplateContextEnum.IncidentTicket]: {
      icon: IconEnum.Incident,
      createTemplateUrl: (provider) =>
        `/settings/incident-tickets/templates/${provider}/create`,
      gatedCap: (identity) =>
        identity.feature_gates.incident_ticket_issue_templates_count,
      nounPlural: "Incident ticket templates",
      nounSingular: "Incident ticket template",
      editHref: ({ provider, id }) =>
        `/settings/incident-tickets/templates/${provider}/${id}`,
    },
  };
