import type { SVGProps } from "react";
import * as React from "react";
const SvgChronosphere = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#chronosphere_svg__a)"
      fillRule="evenodd"
      d="M10.123 2.973c3.314 0 6.1 2.292 6.849 5.377a2.689 2.689 0 0 0-4.884 1.552c0 1.49 1.199 2.689 2.689 2.689.96 0 1.798-.502 2.274-1.252a7.05 7.05 0 0 1-6.93 5.747 7.05 7.05 0 0 1-7.051-7.052c0-3.895 3.166-7.061 7.053-7.061Z"
      clipRule="evenodd"
    />
    <defs>
      <radialGradient
        id="chronosphere_svg__a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="translate(10.062 9.949) scale(7.024)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#60E684" />
        <stop offset={0.25} stopColor="#5CE382" />
        <stop offset={0.47} stopColor="#51DA7E" />
        <stop offset={0.68} stopColor="#3FCC77" />
        <stop offset={0.82} stopColor="#2FBF71" />
      </radialGradient>
    </defs>
  </svg>
);
export default SvgChronosphere;
