/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface APIKeyRole
 */
export interface APIKeyRole {
    /**
     * Human readable description of the role
     * @type {string}
     * @memberof APIKeyRole
     */
    description: string;
    /**
     * Unique name for the API key role
     * @type {string}
     * @memberof APIKeyRole
     */
    name: APIKeyRoleNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum APIKeyRoleNameEnum {
    Viewer = 'viewer',
    IncidentCreator = 'incident_creator',
    IncidentEditor = 'incident_editor',
    GlobalAccess = 'global_access',
    ManageSettings = 'manage_settings',
    CatalogViewer = 'catalog_viewer',
    CatalogEditor = 'catalog_editor',
    IncidentMembershipsEditor = 'incident_memberships_editor',
    SchedulesEditor = 'schedules_editor',
    ScheduleOverridesEditor = 'schedule_overrides_editor',
    SchedulesReader = 'schedules_reader',
    WorkflowsEditor = 'workflows_editor',
    PrivateWorkflowsEditor = 'private_workflows_editor',
    OnCallEditor = 'on_call_editor'
}

export function APIKeyRoleFromJSON(json: any): APIKeyRole {
    return APIKeyRoleFromJSONTyped(json, false);
}

export function APIKeyRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIKeyRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
    };
}

export function APIKeyRoleToJSON(value?: APIKeyRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
    };
}

