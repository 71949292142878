/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExpressionNavigateOpts
 */
export interface ExpressionNavigateOpts {
    /**
     * The reference within the scope to navigate to
     * @type {string}
     * @memberof ExpressionNavigateOpts
     */
    reference: string;
    /**
     * The name of the reference to navigate to
     * @type {string}
     * @memberof ExpressionNavigateOpts
     */
    reference_label: string;
}

export function ExpressionNavigateOptsFromJSON(json: any): ExpressionNavigateOpts {
    return ExpressionNavigateOptsFromJSONTyped(json, false);
}

export function ExpressionNavigateOptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionNavigateOpts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': json['reference'],
        'reference_label': json['reference_label'],
    };
}

export function ExpressionNavigateOptsToJSON(value?: ExpressionNavigateOpts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'reference_label': value.reference_label,
    };
}

