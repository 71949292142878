import { PolicyViolationNotification } from "@incident-shared/policy/PolicyViolationNotification";
import {
  GenericStatusBadge,
  GenericStatusBadgeEnum,
} from "@incident-ui/Badge/GenericStatusBadge";
import {
  FollowUp,
  FollowUpStatusEnum,
  Policy,
  PolicyViolation,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

export const FollowUpStatusBadge = ({
  followUp,
  violatedPolicy,
  policyViolation,
}: {
  followUp: FollowUp;
  violatedPolicy?: Policy;
  policyViolation?: PolicyViolation;
}): React.ReactElement => {
  if (violatedPolicy && policyViolation) {
    return (
      <PolicyViolationNotification
        iconOnly
        policy={violatedPolicy}
        resourceName="follow-up"
        level={policyViolation.level}
        daysUntil={policyViolation.days}
        violationID={policyViolation.id}
      />
    );
  }

  let status: GenericStatusBadgeEnum;
  switch (followUp.status) {
    case FollowUpStatusEnum.Outstanding:
      status = GenericStatusBadgeEnum.Outstanding;
      break;
    case FollowUpStatusEnum.Completed:
      status = GenericStatusBadgeEnum.Completed;
      break;
    case FollowUpStatusEnum.NotDoing:
      status = GenericStatusBadgeEnum.NotDoing;
      break;
    case FollowUpStatusEnum.Deleted:
      throw new Error("unreachable: unexpected deleted followup");
    default:
      assertUnreachable(followUp.status);
      throw new Error("unreachable: unexpected followup status");
  }

  return <GenericStatusBadge status={status} withLabel />;
};
