import type { SVGProps } from "react";
import * as React from "react";
const SvgUrgency = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#212121"
      d="M14.722 2.222H5.278a3.058 3.058 0 0 0-3.056 3.056v9.444a3.058 3.058 0 0 0 3.056 3.056h9.444a3.058 3.058 0 0 0 3.056-3.056V5.278a3.058 3.058 0 0 0-3.056-3.056ZM9.167 6.034a.834.834 0 0 1 1.666 0v4.6a.834.834 0 0 1-1.666 0v-4.6ZM10 14.908a1.112 1.112 0 0 1 0-2.223 1.112 1.112 0 0 1 0 2.223Z"
    />
  </svg>
);
export default SvgUrgency;
