import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ButtonTheme,
  ConfirmationDialog,
  IconEnum,
  LoadingModal,
} from "@incident-ui";
import React, { useState } from "react";
import { IncidentLifecycle, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  AutoDeletingDependentResourceList,
  groupDependentResources,
} from "../../DeletionConfirmationModal";

export const LifecycleDeleteButton = ({
  lifecycle,
}: {
  lifecycle: IncidentLifecycle;
}): React.ReactElement | null => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { hasScope, isImpersonating } = useIdentity();
  const canDelete =
    hasScope(ScopeNameEnum.OrganisationSettingsUpdate) && !isImpersonating;

  return (
    <>
      {showDeleteDialog && (
        <LifecycleDeleteModal
          lifecycle={lifecycle}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
      <GatedButton
        analyticsTrackingId="delete-incident-lifecycle"
        theme={ButtonTheme.DestroySecondary}
        icon={IconEnum.Delete}
        onClick={() => setShowDeleteDialog(true)}
        disabled={!canDelete}
        disabledTooltipContent={
          <>You do not have permissions to delete incident lifecycles</>
        }
      >
        Delete lifecycle
      </GatedButton>
    </>
  );
};

const LifecycleDeleteModal = ({
  lifecycle,
  onClose,
}: {
  lifecycle: IncidentLifecycle;
  onClose: () => void;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const resources = lifecycle.active_statuses.map((status) => ({
    resource_type: "IncidentStatus",
    id: status.id,
  }));
  // We need to check dependent resources, as usual. We show a loading spinner
  // even if we have the data, but it's a bit out of date. It's quite important
  // that this info is very fresh.
  const {
    data: { dependent_resources: dependentResources },
    isValidating: loadingDependentResources,
  } = useAPI(
    "engineFindDependentResourcesForMultiple",
    { findDependentResourcesForMultipleRequestBody: { resources } },
    { fallbackData: { dependent_resources: [] } },
  );

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "incidentLifecyclesList",
    undefined,
    async (apiClient) => {
      await apiClient.incidentLifecyclesDestroy({ id: lifecycle.id });
    },
    {
      onSuccess: () => navigate(`/settings/lifecycle`),
    },
  );

  const { requiresDeletionResources, autoDeletingResources } =
    groupDependentResources(dependentResources ?? []);

  if (loadingDependentResources) {
    return <LoadingModal title="Delete lifecycle" isOpen onClose={onClose} />;
  }

  if (dependentResources && dependentResources.length > 0) {
    return (
      <ConfirmationDialog
        title="Delete lifecycle"
        analyticsTrackingId="delete-lifecycle-dependent-resources"
        isOpen
        onCancel={onClose}
        hideConfirmButton // so all confirm configuration is moot
        onConfirm={() => {
          return;
        }} // do nothing!
        cancelButtonText="OK"
      >
        <DependentResourceList
          requiresDeletionResources={requiresDeletionResources}
          overrideTitle={
            <>
              We can&apos;t delete the <b>{lifecycle.name}</b> lifecycle, as the
              following resources depend on one of the statuses in the
              lifecycle:
            </>
          }
        />
      </ConfirmationDialog>
    );
  }

  return (
    <ConfirmationDialog
      title="Delete lifecycle"
      analyticsTrackingId="delete-lifecycle-confirm"
      isOpen
      onCancel={onClose}
      onConfirm={() => onDelete({ id: lifecycle.id })}
      saving={deleting}
    >
      <>
        Are you sure you want to delete the{" "}
        <span className="font-bold">{lifecycle.name}</span> lifecycle?
        <AutoDeletingDependentResourceList
          autoDeletingResources={autoDeletingResources}
        />
      </>
    </ConfirmationDialog>
  );
};
