/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutlookCalendarConfig,
    OutlookCalendarConfigFromJSON,
    OutlookCalendarConfigFromJSONTyped,
    OutlookCalendarConfigToJSON,
} from './OutlookCalendarConfig';

/**
 * 
 * @export
 * @interface IntegrationsOutlookCalendarGetConfigResponseBody
 */
export interface IntegrationsOutlookCalendarGetConfigResponseBody {
    /**
     * 
     * @type {OutlookCalendarConfig}
     * @memberof IntegrationsOutlookCalendarGetConfigResponseBody
     */
    config: OutlookCalendarConfig;
}

export function IntegrationsOutlookCalendarGetConfigResponseBodyFromJSON(json: any): IntegrationsOutlookCalendarGetConfigResponseBody {
    return IntegrationsOutlookCalendarGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsOutlookCalendarGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsOutlookCalendarGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': OutlookCalendarConfigFromJSON(json['config']),
    };
}

export function IntegrationsOutlookCalendarGetConfigResponseBodyToJSON(value?: IntegrationsOutlookCalendarGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': OutlookCalendarConfigToJSON(value.config),
    };
}

