/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentLifecycle,
    IncidentLifecycleFromJSON,
    IncidentLifecycleFromJSONTyped,
    IncidentLifecycleToJSON,
} from './IncidentLifecycle';

/**
 * 
 * @export
 * @interface IncidentLifecyclesListResponseBody
 */
export interface IncidentLifecyclesListResponseBody {
    /**
     * 
     * @type {Array<IncidentLifecycle>}
     * @memberof IncidentLifecyclesListResponseBody
     */
    incident_lifecycles: Array<IncidentLifecycle>;
}

export function IncidentLifecyclesListResponseBodyFromJSON(json: any): IncidentLifecyclesListResponseBody {
    return IncidentLifecyclesListResponseBodyFromJSONTyped(json, false);
}

export function IncidentLifecyclesListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecyclesListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_lifecycles': ((json['incident_lifecycles'] as Array<any>).map(IncidentLifecycleFromJSON)),
    };
}

export function IncidentLifecyclesListResponseBodyToJSON(value?: IncidentLifecyclesListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_lifecycles': ((value.incident_lifecycles as Array<any>).map(IncidentLifecycleToJSON)),
    };
}

