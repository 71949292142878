/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GroupedAlertTimelineElement,
    GroupedAlertTimelineElementFromJSON,
    GroupedAlertTimelineElementFromJSONTyped,
    GroupedAlertTimelineElementToJSON,
} from './GroupedAlertTimelineElement';

/**
 * 
 * @export
 * @interface AlertsListTimelineItemsResponseBody
 */
export interface AlertsListTimelineItemsResponseBody {
    /**
     * 
     * @type {Array<GroupedAlertTimelineElement>}
     * @memberof AlertsListTimelineItemsResponseBody
     */
    timeline_items: Array<GroupedAlertTimelineElement>;
}

export function AlertsListTimelineItemsResponseBodyFromJSON(json: any): AlertsListTimelineItemsResponseBody {
    return AlertsListTimelineItemsResponseBodyFromJSONTyped(json, false);
}

export function AlertsListTimelineItemsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsListTimelineItemsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_items': ((json['timeline_items'] as Array<any>).map(GroupedAlertTimelineElementFromJSON)),
    };
}

export function AlertsListTimelineItemsResponseBodyToJSON(value?: AlertsListTimelineItemsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_items': ((value.timeline_items as Array<any>).map(GroupedAlertTimelineElementToJSON)),
    };
}

