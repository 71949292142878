import type { SVGProps } from "react";
import * as React from "react";
const SvgFastForward = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.898 8.932 9.887 5.304a1.246 1.246 0 0 0-1.888 1.073v2.012L2.888 5.304A1.246 1.246 0 0 0 1 6.377v7.246a1.25 1.25 0 0 0 1.247 1.25c.224 0 .446-.06.645-.179L8 11.611v2.012a1.25 1.25 0 0 0 1.247 1.25c.224 0 .446-.06.645-.179l6.003-3.623c.379-.229.605-.63.605-1.072 0-.442-.225-.839-.602-1.067ZM18.25 4.5a.75.75 0 0 0-.75.75v9.5a.75.75 0 0 0 1.5 0v-9.5a.75.75 0 0 0-.75-.75Z"
    />
  </svg>
);
export default SvgFastForward;
