/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingCreateCheckoutSessionRequestBody
 */
export interface BillingCreateCheckoutSessionRequestBody {
    /**
     * Whether this is a monthly or annual purchase
     * @type {string}
     * @memberof BillingCreateCheckoutSessionRequestBody
     */
    billing_interval: BillingCreateCheckoutSessionRequestBodyBillingIntervalEnum;
    /**
     * The unique name of the plan to purchase
     * @type {string}
     * @memberof BillingCreateCheckoutSessionRequestBody
     */
    plan_name: string;
}

/**
* @export
* @enum {string}
*/
export enum BillingCreateCheckoutSessionRequestBodyBillingIntervalEnum {
    Monthly = 'monthly',
    Annual = 'annual'
}

export function BillingCreateCheckoutSessionRequestBodyFromJSON(json: any): BillingCreateCheckoutSessionRequestBody {
    return BillingCreateCheckoutSessionRequestBodyFromJSONTyped(json, false);
}

export function BillingCreateCheckoutSessionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingCreateCheckoutSessionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billing_interval': json['billing_interval'],
        'plan_name': json['plan_name'],
    };
}

export function BillingCreateCheckoutSessionRequestBodyToJSON(value?: BillingCreateCheckoutSessionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_interval': value.billing_interval,
        'plan_name': value.plan_name,
    };
}

