/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HolidayUserFeed,
    HolidayUserFeedFromJSON,
    HolidayUserFeedFromJSONTyped,
    HolidayUserFeedToJSON,
} from './HolidayUserFeed';

/**
 * 
 * @export
 * @interface HolidaysListFeedsResponseBody
 */
export interface HolidaysListFeedsResponseBody {
    /**
     * 
     * @type {Array<HolidayUserFeed>}
     * @memberof HolidaysListFeedsResponseBody
     */
    holiday_user_feeds: Array<HolidayUserFeed>;
}

export function HolidaysListFeedsResponseBodyFromJSON(json: any): HolidaysListFeedsResponseBody {
    return HolidaysListFeedsResponseBodyFromJSONTyped(json, false);
}

export function HolidaysListFeedsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidaysListFeedsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holiday_user_feeds': ((json['holiday_user_feeds'] as Array<any>).map(HolidayUserFeedFromJSON)),
    };
}

export function HolidaysListFeedsResponseBodyToJSON(value?: HolidaysListFeedsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'holiday_user_feeds': ((value.holiday_user_feeds as Array<any>).map(HolidayUserFeedToJSON)),
    };
}

