import {
  Expression,
  ExpressionPayload,
  Reference,
} from "src/contexts/ClientContext";
import { EnrichedScope, mergeScopes, scopeFrom } from "src/utils/scope";

import { ExpressionFormData } from "./expressionToPayload";

export const makeExpressionReference = (
  e: Pick<Expression | ExpressionFormData | ExpressionPayload, "reference">,
): string => {
  return `expressions["${e.reference}"]`;
};

export const expressionIDfromReference = (reference: string): string => {
  return reference.replace('expressions["', "").replace('"]', "");
};

// This enriches a trigger's scope with the available expressions.
// It does this so that we can reference expressions in relevant places
// e.g. in the edit step form, we can reference expressions in the dropdown.
export const addExpressionsToScope = <T extends Reference = Reference>(
  scope: EnrichedScope<T>,
  expressions: ExpressionFormData[],
): EnrichedScope<T> => {
  return mergeScopes<T>(
    scope,
    scopeFrom(
      expressions.map(
        (e): Reference => ({
          hide_filter: false,
          key: makeExpressionReference(e),
          label: e.label,
          node_label: e.label,
          type: e.returns.type,
          // If you've not checked/unchecked the returns.array, it defaults to undefined which we should treat as false
          array: e.returns.array ?? false,
        }),
        // Occasionally we'll be adding expressions to a ReferenceWithExample scope,
        // we don't care that the expressions don't have examples here
      ) as T[],
    ),
  );
};
