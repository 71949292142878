/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartialEntryPayload,
    PartialEntryPayloadFromJSON,
    PartialEntryPayloadFromJSONTyped,
    PartialEntryPayloadToJSON,
} from './PartialEntryPayload';

/**
 * 
 * @export
 * @interface CatalogBulkUpdateAttributeValuesRequestBody
 */
export interface CatalogBulkUpdateAttributeValuesRequestBody {
    /**
     * ID of the catalog type we want to update at
     * @type {string}
     * @memberof CatalogBulkUpdateAttributeValuesRequestBody
     */
    catalog_type_id: string;
    /**
     * A list of entries that we should update, with the updated attribute values.
     * @type {Array<PartialEntryPayload>}
     * @memberof CatalogBulkUpdateAttributeValuesRequestBody
     */
    partial_entry_payloads: Array<PartialEntryPayload>;
}

export function CatalogBulkUpdateAttributeValuesRequestBodyFromJSON(json: any): CatalogBulkUpdateAttributeValuesRequestBody {
    return CatalogBulkUpdateAttributeValuesRequestBodyFromJSONTyped(json, false);
}

export function CatalogBulkUpdateAttributeValuesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogBulkUpdateAttributeValuesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_id': json['catalog_type_id'],
        'partial_entry_payloads': ((json['partial_entry_payloads'] as Array<any>).map(PartialEntryPayloadFromJSON)),
    };
}

export function CatalogBulkUpdateAttributeValuesRequestBodyToJSON(value?: CatalogBulkUpdateAttributeValuesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_id': value.catalog_type_id,
        'partial_entry_payloads': ((value.partial_entry_payloads as Array<any>).map(PartialEntryPayloadToJSON)),
    };
}

