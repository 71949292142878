/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomField,
    CustomFieldFromJSON,
    CustomFieldFromJSONTyped,
    CustomFieldToJSON,
} from './CustomField';

/**
 * 
 * @export
 * @interface CustomFieldsConvertToExistingCatalogTypeResponseBody
 */
export interface CustomFieldsConvertToExistingCatalogTypeResponseBody {
    /**
     * 
     * @type {CustomField}
     * @memberof CustomFieldsConvertToExistingCatalogTypeResponseBody
     */
    custom_field: CustomField;
}

export function CustomFieldsConvertToExistingCatalogTypeResponseBodyFromJSON(json: any): CustomFieldsConvertToExistingCatalogTypeResponseBody {
    return CustomFieldsConvertToExistingCatalogTypeResponseBodyFromJSONTyped(json, false);
}

export function CustomFieldsConvertToExistingCatalogTypeResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldsConvertToExistingCatalogTypeResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field': CustomFieldFromJSON(json['custom_field']),
    };
}

export function CustomFieldsConvertToExistingCatalogTypeResponseBodyToJSON(value?: CustomFieldsConvertToExistingCatalogTypeResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field': CustomFieldToJSON(value.custom_field),
    };
}

