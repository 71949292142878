/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleEntry,
    ScheduleEntryFromJSON,
    ScheduleEntryFromJSONTyped,
    ScheduleEntryToJSON,
} from './ScheduleEntry';
import {
    ScheduleOverride,
    ScheduleOverrideFromJSON,
    ScheduleOverrideFromJSONTyped,
    ScheduleOverrideToJSON,
} from './ScheduleOverride';

/**
 * 
 * @export
 * @interface SchedulesPreviewEntriesResponseBody
 */
export interface SchedulesPreviewEntriesResponseBody {
    /**
     * The final schedule, after applying overrides
     * @type {Array<ScheduleEntry>}
     * @memberof SchedulesPreviewEntriesResponseBody
     */
    _final: Array<ScheduleEntry>;
    /**
     * Overrides that are relevant to the given time period
     * @type {Array<ScheduleOverride>}
     * @memberof SchedulesPreviewEntriesResponseBody
     */
    overrides: Array<ScheduleOverride>;
    /**
     * According to each rotation, who should be scheduled
     * @type {Array<ScheduleEntry>}
     * @memberof SchedulesPreviewEntriesResponseBody
     */
    scheduled: Array<ScheduleEntry>;
}

export function SchedulesPreviewEntriesResponseBodyFromJSON(json: any): SchedulesPreviewEntriesResponseBody {
    return SchedulesPreviewEntriesResponseBodyFromJSONTyped(json, false);
}

export function SchedulesPreviewEntriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesPreviewEntriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_final': ((json['final'] as Array<any>).map(ScheduleEntryFromJSON)),
        'overrides': ((json['overrides'] as Array<any>).map(ScheduleOverrideFromJSON)),
        'scheduled': ((json['scheduled'] as Array<any>).map(ScheduleEntryFromJSON)),
    };
}

export function SchedulesPreviewEntriesResponseBodyToJSON(value?: SchedulesPreviewEntriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'final': ((value._final as Array<any>).map(ScheduleEntryToJSON)),
        'overrides': ((value.overrides as Array<any>).map(ScheduleOverrideToJSON)),
        'scheduled': ((value.scheduled as Array<any>).map(ScheduleEntryToJSON)),
    };
}

