import type { SVGProps } from "react";
import * as React from "react";
const SvgActivity = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 3h-8.5A2.752 2.752 0 0 0 3 5.75v8.5A2.752 2.752 0 0 0 5.75 17h8.5A2.752 2.752 0 0 0 17 14.25v-8.5A2.752 2.752 0 0 0 14.25 3ZM14 10.75h-.985l-1.064 2.769a.75.75 0 0 1-1.4 0l-1.8-4.68-.55 1.43a.75.75 0 0 1-.7.481h-1.5a.75.75 0 0 1 0-1.5h.985L8.05 6.481c.223-.58 1.178-.58 1.4 0l1.8 4.68.55-1.43a.75.75 0 0 1 .7-.481H14a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgActivity;
