import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";

import { DashboardDetailsFormData } from "./CreateCustomDashboardModal";
import { DashboardColorIconPicker } from "./DashboardColorIconPicker";

export const EditCustomDashboardDetailsModal = ({
  onClose,
  onSubmit,
  initialData,
}: {
  onClose: () => void;
  onSubmit: (data: DashboardDetailsFormData) => void;
  initialData: DashboardDetailsFormData;
}): React.ReactElement => {
  const formMethods = useForm<DashboardDetailsFormData>({
    defaultValues: initialData,
  });

  return (
    <Form.Modal<DashboardDetailsFormData>
      analyticsTrackingId="edit-custom-dashboard-details"
      formMethods={formMethods}
      title="Edit dashboard details"
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          confirmButtonText="Continue"
          onClose={onClose}
        />
      }
    >
      <InputV2
        name="name"
        formMethods={formMethods}
        label="Name"
        placeholder="Executive summary"
      />
      <Form.InputWrapper name="icon" label="Choose an icon">
        <DashboardColorIconPicker />
      </Form.InputWrapper>
    </Form.Modal>
  );
};
