import {
  Badge,
  BadgeTheme,
  ContentBox,
  GenericErrorMessage,
  IncidentStatusBadge,
  Loader,
  Toggle,
  Tooltip,
} from "@incident-ui";
import { UpsellNotice } from "src/components/settings/UpsellNotice";
import {
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
  IncidentTimestamp,
  IncidentTimestampTimestampTypeEnum,
  ScopeNameEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useSettings } from "src/hooks/useSettings";
import { useIncidentTriageIncidentsFeatureGateEnabled } from "src/utils/incident-manual-triage";
import { useAPIMutation } from "src/utils/swr";

import { useLifecycleOverview } from "../LifecycleOverviewContext";
import { HorizontalLine, Pill } from "../LifecycleUIElements";
import { TimestampsOnStatusChange } from "../TimestampsOnStatusChange";
import { LifecycleOverviewSection } from "./LifecycleOverviewSection";

export const TriageSection = ({
  isOnlyLifecycle,
  statuses,
  timestamps,
}: {
  isOnlyLifecycle: boolean;
  statuses: IncidentStatus[];
  timestamps: IncidentTimestamp[];
}) => {
  const { settings } = useSettings();

  const featureGateTriageIncidents =
    useIncidentTriageIncidentsFeatureGateEnabled();

  const { getConfigurationState } = useLifecycleOverview();
  const state = getConfigurationState(StatusCategoryEnum.Triage);
  const isBackground = state === "background";

  const manualTriageDisabled =
    settings && settings.misc.manual_triage_incidents_enabled === false
      ? true
      : false;

  const showDisabled = isBackground || manualTriageDisabled;

  const acceptedAt = timestamps.find(
    (timestamp) =>
      timestamp.timestamp_type ===
      IncidentTimestampTimestampTypeEnum.AcceptedAt,
  );

  return (
    <LifecycleOverviewSection
      title="Triage"
      description="Triage incidents give you a space to investigate potential issues before either accepting them as active incidents, or declining them as false positives."
      category={StatusCategoryEnum.Triage}
      diagram={
        featureGateTriageIncidents ? (
          <TriageDiagram
            statuses={statuses}
            manualTriageDisabled={manualTriageDisabled}
            showDisabled={showDisabled}
          />
        ) : (
          // Hack so that the upsell notice doesn't overflow into the next section
          <div>
            <UpsellNotice
              title={"Triage"}
              planName={"Pro"}
              analyticsId={"incident-triage-statuses"}
              description={
                "Spend time investigating potential incidents before they’re declared as active incidents. Incidents in a Triage status can be accepted, declined or merged with existing incidents."
              }
              className="-ml-5 -mr-4 !bg-surface-secondary !border-0 !rounded-none !shadow-none"
              articleId={6878776}
            />
          </div>
        )
      }
      configurationNode={
        // Only show this if triage is available for this org AND it's the only lifecycle. When there's multiple
        // lifecycles we pull this configuration up into the list page.
        featureGateTriageIncidents && isOnlyLifecycle ? (
          // This magic number is the height of the static 'triage' section.
          <div className="h-[168px]">
            {settings ? (
              <TriageIncidentsInlineForm settings={settings} />
            ) : (
              <Loader />
            )}
          </div>
        ) : undefined
      }
      bottomBorderNode={
        featureGateTriageIncidents ? (
          <TimestampsOnStatusChange
            timestamps={timestamps
              .filter((timestamp) => timestamp.id === acceptedAt?.id)
              .map((timestamp) => ({ timestamp }))}
            disabled={showDisabled}
          />
        ) : (
          <div className="h-[44px]" />
        )
      }
    />
  );
};

const TriageDiagram = ({
  statuses,
  manualTriageDisabled,
  showDisabled,
}: {
  statuses: IncidentStatus[];
  showDisabled: boolean;
  manualTriageDisabled: boolean;
}): React.ReactElement => {
  // We could hardcode the statuses and timestamps because they'll be the same for everyone, but
  // let's grab their real names so we're resilient to name changes.
  const triage = statuses.find(
    (status) => status.category === IncidentStatusCategoryEnum.Triage,
  );
  const merged = statuses.find(
    (status) => status.category === IncidentStatusCategoryEnum.Merged,
  );
  const declined = statuses.find(
    (status) => status.category === IncidentStatusCategoryEnum.Declined,
  );

  if (!triage) {
    return <GenericErrorMessage description={"Triage status not found"} />;
  }
  if (!merged) {
    return <GenericErrorMessage description={"Merged status not found"} />;
  }
  if (!declined) {
    return <GenericErrorMessage description={"Declined status not found"} />;
  }

  return (
    <div>
      <Pill disabled={showDisabled}>
        <IncidentStatusBadge naked status={triage} iconOnly />
        Triage
        {manualTriageDisabled && (
          <Tooltip
            delayDuration={50}
            bubbleProps={{ align: "start" }}
            side="bottom"
            content={
              <div>
                When declaring an incident, users will not be able to choose the
                triage option. Triage incidents can still be created via the
                API, or when incidents are triggered from external systems such
                as PagerDuty.
              </div>
            }
          >
            <div>
              <Badge
                className="border border-stroke"
                theme={BadgeTheme.Tertiary}
              >
                Automated only
              </Badge>
            </div>
          </Tooltip>
        )}
      </Pill>
      <div className="ml-[22px] space-y-4 mt-4">
        <TriageStatus status={merged} showDisabled={showDisabled} />
        <TriageStatus status={declined} showDisabled={showDisabled} />
      </div>
    </div>
  );
};

const TriageStatus = ({
  status,
  showDisabled,
}: {
  status: IncidentStatus;
  showDisabled: boolean;
}): React.ReactElement => {
  return (
    <div className="flex items-center -ml-[1px]">
      <HorizontalLine className="w-11" />
      <Pill disabled={showDisabled}>
        <IncidentStatusBadge naked status={status} iconOnly />
        {status.name}
      </Pill>
    </div>
  );
};

const TriageIncidentsInlineForm = ({ settings }: { settings: Settings }) => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { trigger: saveState } = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data) =>
      await apiClient.settingsUpdateManualTriageIncidentsEnabled({
        updateManualTriageIncidentsEnabledRequestBody: {
          manual_triage_incidents_enabled:
            data.misc.manual_triage_incidents_enabled,
        },
      }),
  );

  const { state, setState, hasSaved, saving } = useOptimisticAutoSave({
    saveState: async (data) => {
      await saveState(data);
    },
    initialState: settings,
  });

  return (
    <ContentBox className="bg-white p-4 mt-3 flex justify-between items-center gap-4">
      <Toggle
        id="manual_triage_incidents_enabled"
        disabled={!canEditSettings}
        align="left"
        label="Allow users to manually create triage incidents"
        on={state.misc.manual_triage_incidents_enabled}
        onToggle={() =>
          setState({
            ...state,
            misc: {
              ...state.misc,
              manual_triage_incidents_enabled:
                !state.misc.manual_triage_incidents_enabled,
            },
          })
        }
      />
      <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
    </ContentBox>
  );
};
