import { Button, ButtonTheme, Icon, IconEnum, Link } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { useClipboard } from "../../../../../utils/useClipboard";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceHTTPSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  const onCopyCurl = () => {
    copyTextToClipboard(
      [
        "curl \\",
        "  -X POST \\",
        `  -H 'Authorization: Bearer ${alertSourceConfig.secret_token}' \\`,
        `  -H 'Content-Type: application/json' \\`,
        `  ${publicApiUrl()}/v2/alert_events/http/${alertSourceConfig.id} \\`,
        `  -d '{
"title": "My first alert",
"description": "Some additional context",
"deduplication_key": "first-alert",
"status": "firing",
"metadata": {
"team": "core",
"service": "api"
}
}'`,
      ].join("\n"),
    );
  };

  return (
    <div>
      <p>
        To use this source, send a <strong>POST</strong> request using the
        details below, or{" "}
        <Button
          theme={ButtonTheme.Naked}
          className="!text-slate-700 hover:!text-content-primary font-medium"
          onClick={onCopyCurl}
          analyticsTrackingId={"copy-curl"}
        >
          copy the cURL command{" "}
          {hasCopied ? null : <Icon id={IconEnum.Copy} className="inline" />}
        </Button>
        {hasCopied ? (
          <div className={"inline text-green-content"}>
            <code> Copied </code>
            <Icon id={IconEnum.Tick} className="inline" />
          </div>
        ) : null}
        .
      </p>
      <div className={"my-4 flex flex-col gap-2 overflow-hidden"}>
        <SingleLineCodeBlock
          title={"URL"}
          code={`${publicApiUrl()}/v2/alert_events/http/${
            alertSourceConfig.id
          }`}
        />
        <SingleLineCodeBlock
          title={"Headers"}
          code={`{ "Authorization": "Bearer ${alertSourceConfig.secret_token}" }`}
        />
        <CodeBlock
          title={"Body"}
          code={`{
  "title": "My first alert",
  "description": "Some additional context",
  "deduplication_key": "first-alert",
  "status": "firing",
  "metadata": {
    "team": "core",
    "service": "api"
  }
}`}
        />
      </div>
      <div className="bg-slate-50 border border-slate-100 rounded-2 p-5 flex flex-col gap-2">
        <p>
          You can optionally send additional information in the{" "}
          <strong>metadata</strong> block, such as the affected service, feature
          or environment. These can be used when defining attributes for your
          alerts.
        </p>
        <p>
          You can find more information about each of the fields in our{" "}
          <Link
            analyticsTrackingId={"http-alerts-docs"}
            href={"https://api-docs.incident.io/tag/Alert-Events-V2/"}
          >
            API documentation
          </Link>
          .
        </p>
      </div>
    </div>
  );
};
