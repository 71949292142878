/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FollowUp,
    FollowUpFromJSON,
    FollowUpFromJSONTyped,
    FollowUpToJSON,
} from './FollowUp';

/**
 * 
 * @export
 * @interface FollowUpsMarkAsCompleteResponseBody
 */
export interface FollowUpsMarkAsCompleteResponseBody {
    /**
     * 
     * @type {FollowUp}
     * @memberof FollowUpsMarkAsCompleteResponseBody
     */
    follow_up: FollowUp;
}

export function FollowUpsMarkAsCompleteResponseBodyFromJSON(json: any): FollowUpsMarkAsCompleteResponseBody {
    return FollowUpsMarkAsCompleteResponseBodyFromJSONTyped(json, false);
}

export function FollowUpsMarkAsCompleteResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsMarkAsCompleteResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'follow_up': FollowUpFromJSON(json['follow_up']),
    };
}

export function FollowUpsMarkAsCompleteResponseBodyToJSON(value?: FollowUpsMarkAsCompleteResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'follow_up': FollowUpToJSON(value.follow_up),
    };
}

