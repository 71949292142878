import { tcx } from "src/utils/tailwind-classes";

export enum CodeSnippetTheme {
  Default = "default",
  White = "white",
}

export const CodeSnippet = ({
  children,
  className,
  theme,
}: {
  children: React.ReactNode;
  className?: string;
  theme?: CodeSnippetTheme;
}) => {
  return (
    <code
      className={tcx(
        "px-1 py-[1px] border-stroke-primary border text-pink-content rounded",
        theme === CodeSnippetTheme.White ? "bg-white" : "bg-surface-secondary",
        className,
      )}
    >
      {children}
    </code>
  );
};
