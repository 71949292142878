import {
  Identity,
  IncidentLifecycle,
  PostIncidentFlow,
  ScopeNameEnum,
  Settings,
  SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBody,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { NoPermissionMessage } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import {
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { ErrorBoundary } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import graphic from "src/components/settings/banners/banner-post-incident.svg";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { SettingsSubHeading } from "../SettingsSubHeading";
import { UpsellNotice } from "../UpsellNotice";
import { PostIncidentFlowComponent } from "./PostIncidentFlowComponent";
import { PostIncidentFlowEmptyState } from "./PostIncidentFlowEmptyState";
import { PostIncidentFlowsList } from "./PostIncidentFlowsList";

export const PostIncidentFlowWrapper = (): React.ReactElement => {
  const { featureAutoRemind } = useFlags();
  const { hasScope, identity } = useIdentity();
  const { settings } = useSettings();

  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const {
    data: { post_incident_flows: flows },
    isLoading: flowsLoading,
    error: flowsError,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  const {
    data: { incident_lifecycles: lifecycles },
    isLoading: lifecyclesLoading,
    error: lifecyclesError,
  } = useAPI("incidentLifecyclesList", undefined, {
    fallbackData: { incident_lifecycles: [] },
  });

  if (flowsLoading || lifecyclesLoading || !identity || !settings) {
    return <FullPageLoader />;
  }

  const error = flowsError || lifecyclesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (featureAutoRemind) {
    return (
      <PostIncidentSettingsSections
        flows={flows}
        lifecycles={lifecycles}
        identity={identity}
        settings={settings}
        canEditSettings={canEditSettings}
      />
    );
  } else {
    return <ToBeDeprecated flows={flows} lifecycles={lifecycles} />;
  }
};

const PostIncidentSettingsSections = ({
  flows,
  lifecycles,
  identity,
  settings,
  canEditSettings,
}: {
  lifecycles: IncidentLifecycle[];
  flows: PostIncidentFlow[];
  identity: Identity;
  settings: Settings;
  canEditSettings: boolean;
}) => {
  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <SettingsSubPageWrapper
        accessory={
          flows.length > 0 ? (
            <GatedButton
              theme={ButtonTheme.Primary}
              href="/settings/post-incident-flow/create"
              requiredProduct={Product.Response}
              analyticsTrackingId="configure-another-post-incident-flow"
              icon={IconEnum.Add}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
              // We don't feature gate this currently, because each lifecycle
              // can only have 1 post-incident flow attached and therefore by
              // gating lifecycles we're effectively gating post-incident flows
              // too
            >
              Add flow
            </GatedButton>
          ) : undefined
        }
      >
        <SettingsHeading
          title="Plan post-incident tasks"
          subtitle="Define a process to learn effectively from your incidents."
          graphic={<img src={graphic} className="h-40" />}
          articleId={8031305}
        />
        <FlowsSection flows={flows} lifecycles={lifecycles} />
        <AutomationSection
          identity={identity}
          settings={settings}
          canEditSettings={canEditSettings}
        />
      </SettingsSubPageWrapper>
    </ErrorBoundary>
  );
};

const FlowsSection = ({
  flows,
  lifecycles,
}: {
  lifecycles: IncidentLifecycle[];
  flows: PostIncidentFlow[];
}): React.ReactElement => {
  const title = flows.length > 1 ? "Flows" : "Flow";
  const explanation =
    flows.length > 1 ? (
      <span>
        {`Configure multiple post-incident flows each with their own statuses and unique tasks to be completed.`}
      </span>
    ) : (
      <span>
        {`Configure your post-incident flow with different statuses and tasks within each status to be completed.`}
      </span>
    );

  return (
    <>
      <SettingsSubHeading
        className="mt-8"
        title={title}
        explanation={explanation}
        accessory={
          <GatedButton
            theme={ButtonTheme.Secondary}
            href="/settings/post-incident-flow/create"
            analyticsTrackingId="configure-another-post-incident-flow"
            icon={IconEnum.Add}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            // We don't feature gate this currently, because each lifecycle
            // can only have 1 post-incident flow attached and therefore by
            // gating lifecycles we're effectively gating post-incident flows
            // too
          >
            Configure another flow
          </GatedButton>
        }
      />
      <Inner flows={flows} lifecycles={lifecycles} />
    </>
  );
};

const Inner = ({
  flows,
  lifecycles,
}: {
  lifecycles: IncidentLifecycle[];
  flows: PostIncidentFlow[];
}) => {
  if (flows.length === 0) {
    return <PostIncidentFlowEmptyState />;
  }
  if (flows.length > 1) {
    return <PostIncidentFlowsList flows={flows} lifecycles={lifecycles} />;
  }

  return <PostIncidentFlowComponent flow={flows[0]} isOnlyFlow />;
};

const AutomationSection = ({
  identity,
  settings,
  canEditSettings,
}: {
  identity: Identity;
  settings: Settings;
  canEditSettings: boolean;
}) => {
  return (
    <>
      <SettingsSubHeading
        className="mt-8"
        title={"Automation"}
        explanation={
          <span>
            {`Configure settings to help automate your post-incident process and flows.`}
          </span>
        }
      />
      <AutoRemindToggle
        identity={identity}
        settings={settings}
        canEditSettings={canEditSettings}
      />
    </>
  );
};

const AutoRemindToggle = ({
  identity,
  settings,
  canEditSettings,
}: {
  identity: Identity;
  settings: Settings;
  canEditSettings: boolean;
}) => {
  const { trigger: saveState } = useAPIMutation(
    "settingsShow",
    undefined,
    async (
      apiClient,
      data: SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBody,
    ) => {
      await apiClient.settingsUpdatePostIncidentNotifyOnTaskOverdue({
        updatePostIncidentNotifyOnTaskOverdueRequestBody: data,
      });
    },
  );

  const { setState, state, hasSaved, saving } =
    useOptimisticAutoSave<SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBody>(
      {
        initialState: settings.misc,
        saveState: async (
          data: SettingsUpdatePostIncidentNotifyOnTaskOverdueRequestBody,
        ) => {
          await saveState(data);
        },
      },
    );

  if (!identity.feature_gates.post_incident_task_auto_remind) {
    return (
      <UpsellNotice
        analyticsId={"pinc-task-auto-remind-upsell"}
        title={"Task reminders"}
        planName={"Pro"}
        description={
          "It's possible to automatically send reminders to users when a task they have been assigned becomes overdue. This helps ensure tasks are completed and not forgotten about."
        }
        articleId={5947963} // TODO: update the article ID - see ticket https://linear.app/incident-io/issue/PINC-2109/add-help-article-link-for-automatically-sending-reminds
      />
    );
  }

  return (
    <>
      <ContentBox className={"p-6 space-y-2"}>
        <div className="flex flex-row justify-between">
          <span className="flex items-center max-w-3xl">
            <GatedToggle
              id="notify_on_task_overdue"
              disabled={!canEditSettings}
              tooltipContent={
                !canEditSettings ? <>{NoPermissionMessage}</> : undefined
              }
              align="left"
              label=""
              on={state.post_incident_notify_on_task_overdue}
              onToggle={() =>
                setState({
                  ...state,
                  post_incident_notify_on_task_overdue:
                    !state.post_incident_notify_on_task_overdue,
                })
              }
            />
            <span className={"text-sm flex flex-col ml-2.5"}>
              <span className="text-content-primary font-medium ">
                Task reminders
              </span>
              <span className="text-slate-700 !font-normal mt-1">
                When an assigned post-incident task becomes overdue, we&apos;ll
                send the assignee an automatic reminder via Slack DM.
              </span>
            </span>
          </span>
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
      </ContentBox>
    </>
  );
};

const ToBeDeprecated = ({
  flows,
  lifecycles,
}: {
  lifecycles: IncidentLifecycle[];
  flows: PostIncidentFlow[];
}) => {
  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <SettingsSubPageWrapper
        accessory={
          flows.length > 0 ? (
            <GatedButton
              theme={ButtonTheme.Primary}
              href="/settings/post-incident-flow/create"
              analyticsTrackingId="configure-another-post-incident-flow"
              icon={IconEnum.Add}
              requiredProduct={Product.Response}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
              // We don't feature gate this currently, because each lifecycle
              // can only have 1 post-incident flow attached and therefore by
              // gating lifecycles we're effectively gating post-incident flows
              // too
            >
              Add flow
            </GatedButton>
          ) : undefined
        }
      >
        <SettingsHeading
          title="Plan post-incident tasks"
          subtitle="Define a process to learn effectively from your incidents."
          graphic={<img src={graphic} className="h-40" />}
          articleId={8031305}
        />
        <Inner flows={flows} lifecycles={lifecycles} />
      </SettingsSubPageWrapper>
    </ErrorBoundary>
  );
};
