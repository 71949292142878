import { OrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { Route, Routes } from "react-router";

import { RoleCreateEditModal } from "./roles/RoleCreateEditModal";
import { RoleListPage } from "./roles/RoleListPage";
import { ScimShowPage } from "./scim/ScimShowPage";
import { SettingsUsersPageWrapper } from "./SettingsUsersPageWrapper";
import { UserListPage } from "./users/UserListPage";

export const SettingsUsersRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route element={<SettingsUsersPageWrapper />}>
        <Route path="" element={<OrgAwareNavigate to="users" />} />
        <Route path="users" element={<UserListPage />} />
        <Route path="roles" element={<RoleListPage />} />
        <Route path="scim" element={<ScimShowPage />} />
      </Route>
      <Route path="roles/:id/edit" element={<RoleCreateEditModal />} />
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};
