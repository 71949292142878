/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CallRoutingCreateRequestBody
 */
export interface CallRoutingCreateRequestBody {
    /**
     * The country code for the phone number
     * @type {string}
     * @memberof CallRoutingCreateRequestBody
     */
    country_code: string;
    /**
     * Any other info about the request for a number
     * @type {string}
     * @memberof CallRoutingCreateRequestBody
     */
    message?: string;
    /**
     * The name of the call routing configuration
     * @type {string}
     * @memberof CallRoutingCreateRequestBody
     */
    name: string;
    /**
     * IDs of users that should be promoted to on-call responders before creating this path
     * @type {Array<string>}
     * @memberof CallRoutingCreateRequestBody
     */
    user_ids_to_promote?: Array<string>;
}

export function CallRoutingCreateRequestBodyFromJSON(json: any): CallRoutingCreateRequestBody {
    return CallRoutingCreateRequestBodyFromJSONTyped(json, false);
}

export function CallRoutingCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallRoutingCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country_code': json['country_code'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'name': json['name'],
        'user_ids_to_promote': !exists(json, 'user_ids_to_promote') ? undefined : json['user_ids_to_promote'],
    };
}

export function CallRoutingCreateRequestBodyToJSON(value?: CallRoutingCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_code': value.country_code,
        'message': value.message,
        'name': value.name,
        'user_ids_to_promote': value.user_ids_to_promote,
    };
}

