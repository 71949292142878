import type { SVGProps } from "react";
import * as React from "react";
const SvgGlobe = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18 10c0-1.712-2.039-2.867-4.596-3.404C12.867 4.039 11.712 2 10 2S7.133 4.039 6.596 6.596C4.039 7.133 2 8.288 2 10s2.039 2.867 4.596 3.404C7.133 15.961 8.288 18 10 18s2.867-2.039 3.404-4.596C15.961 12.867 18 11.712 18 10Zm-8-6.5c.589 0 1.348 1.05 1.817 2.849A17.482 17.482 0 0 0 10 6.25c-.606 0-1.216.036-1.817.099C8.652 4.549 9.411 3.5 10 3.5Zm2.25 6.5c0 .761-.05 1.467-.135 2.115-.647.085-1.354.135-2.115.135-.761 0-1.467-.05-2.115-.135A16.077 16.077 0 0 1 7.75 10c0-.761.05-1.467.135-2.115A16.251 16.251 0 0 1 10 7.75c.761 0 1.467.05 2.115.135.086.647.135 1.354.135 2.115ZM3.5 10c0-.589 1.049-1.349 2.849-1.817A17.482 17.482 0 0 0 6.25 10c0 .606.036 1.216.099 1.817C4.549 11.348 3.5 10.589 3.5 10Zm6.5 6.5c-.589 0-1.348-1.05-1.817-2.849.601.063 1.211.099 1.817.099.606 0 1.216-.036 1.817-.099-.469 1.8-1.228 2.849-1.817 2.849Zm3.651-4.683c.063-.601.099-1.211.099-1.817 0-.606-.036-1.216-.099-1.817 1.8.469 2.849 1.228 2.849 1.817s-1.049 1.349-2.849 1.817Z"
    />
    <path
      fill="currentColor"
      d="M10 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-14.5A6.508 6.508 0 0 0 3.5 10c0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5Z"
    />
  </svg>
);
export default SvgGlobe;
