import { ErrorMessage } from "@incident-ui";
import React from "react";

export const UnsupportedJiraFieldsErrorMessage = ({
  unsupportedRequiredFields,
}: {
  unsupportedRequiredFields: string[];
}): React.ReactElement => {
  return (
    <ErrorMessage
      message={`This Jira issue type contains required fields that we don't yet support: ${unsupportedRequiredFields.join(
        ", ",
      )}`}
    />
  );
};
