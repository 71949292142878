import type { SVGProps } from "react";
import * as React from "react";
const SvgSpeechImportant = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 2c-4.411 0-8 3.589-8 8 0 1.396.371 2.776 1.062 3.971.238.446-.095 2.002-.842 2.749a.75.75 0 0 0 .49 1.279c.079.004.16.006.241.006 1.145 0 2.535-.407 3.44-.871A7.94 7.94 0 0 0 10 18c4.411 0 8-3.589 8-8s-3.589-8-8-8Zm-.75 5.5a.75.75 0 0 1 1.5 0V10a.75.75 0 0 1-1.5 0V7.5Zm.75 6.069a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpeechImportant;
