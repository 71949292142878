import type { SVGProps } from "react";
import * as React from "react";
const SvgNewRelic = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#00AC69"
      d="M14.165 7.54 16.825 6v8l-6.913 4v-3.077l4.253-2.46V7.538Z"
    />
    <path
      fill="#1CE783"
      d="M9.912 5.077 5.66 7.54 3 6 9.913 2l6.912 4-2.659 1.54-4.253-2.463Z"
    />
    <path
      fill="#1D252C"
      d="M7.253 11.54 3 9.078V6L9.912 10v8l-2.66-1.537V11.54Z"
    />
  </svg>
);
export default SvgNewRelic;
