/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemShare,
    PostmortemShareFromJSON,
    PostmortemShareFromJSONTyped,
    PostmortemShareToJSON,
} from './PostmortemShare';

/**
 * 
 * @export
 * @interface PostmortemSharesCreateShareResponseBody
 */
export interface PostmortemSharesCreateShareResponseBody {
    /**
     * 
     * @type {PostmortemShare}
     * @memberof PostmortemSharesCreateShareResponseBody
     */
    share: PostmortemShare;
}

export function PostmortemSharesCreateShareResponseBodyFromJSON(json: any): PostmortemSharesCreateShareResponseBody {
    return PostmortemSharesCreateShareResponseBodyFromJSONTyped(json, false);
}

export function PostmortemSharesCreateShareResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSharesCreateShareResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'share': PostmortemShareFromJSON(json['share']),
    };
}

export function PostmortemSharesCreateShareResponseBodyToJSON(value?: PostmortemSharesCreateShareResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'share': PostmortemShareToJSON(value.share),
    };
}

