import cx from "classnames";
import React from "react";

export type TextareaProps = {
  className?: string;
  autoFocus?: boolean;
  value?: string;
  disabled?: boolean;
  invalid?: boolean;
  id: string;
  ref?: React.ForwardedRef<HTMLTextAreaElement>;
} & React.HTMLProps<HTMLTextAreaElement> & {
    className?: string;
    title?: string;
  } & (
    | { label?: never; id?: never }
    | { label?: never; id: string }
    // If you have a label, we require an ID to link the two up
    | { label: React.ReactNode; id: string }
  );

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      label,
      title,
      id, // children breaks since our react versions aren't the same, but inputs shouldn't have children anyway
      children: _children,
      ...props
    }: TextareaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
  ) => {
    return (
      <div className="flex flex-col space-y-3">
        {label && (
          <label
            className="text-content-primary dark:text-slate-50 text-sm"
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <textarea
          ref={ref}
          {...props}
          id={id}
          title={title ? title : typeof label === "string" ? label : undefined}
          className={cx(
            "px-3 py-2",
            "rounded-lg border text-sm w-full",
            // Suppress the default highlights when pressed on mobile
            "!ring-0 focus:!ring-0 focus:!ring-border focus:!border-stroke focus:!outline-none",
            "bg-white border-stroke text-content-primary placeholder:text-slate-300",
            "dark:bg-slate-900 dark:border-slate-700 dark:text-slate-200 dark:placeholder:text-slate-600",
            className,
          )}
        />
      </div>
    );
  },
);

Textarea.displayName = "Textarea";
