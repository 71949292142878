/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';

/**
 * 
 * @export
 * @interface StreamsCreateRequestBody
 */
export interface StreamsCreateRequestBody {
    /**
     * Idempotency key
     * @type {string}
     * @memberof StreamsCreateRequestBody
     */
    idempotency_key: string;
    /**
     * Assign incident roles to these people
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof StreamsCreateRequestBody
     */
    incident_role_assignments?: Array<IncidentRoleAssignmentPayload>;
    /**
     * Name of the stream
     * @type {string}
     * @memberof StreamsCreateRequestBody
     */
    name: string;
    /**
     * ID of the incident that this stream belongs to
     * @type {string}
     * @memberof StreamsCreateRequestBody
     */
    parent_id: string;
    /**
     * A list of user IDs to invite to the stream
     * @type {Array<string>}
     * @memberof StreamsCreateRequestBody
     */
    users_to_invite?: Array<string>;
    /**
     * Whether the stream should be open to anyone in your Slack workspace (public), or invite-only (private)
     * @type {string}
     * @memberof StreamsCreateRequestBody
     */
    visibility?: StreamsCreateRequestBodyVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum StreamsCreateRequestBodyVisibilityEnum {
    Public = 'public',
    Private = 'private'
}

export function StreamsCreateRequestBodyFromJSON(json: any): StreamsCreateRequestBody {
    return StreamsCreateRequestBodyFromJSONTyped(json, false);
}

export function StreamsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idempotency_key': json['idempotency_key'],
        'incident_role_assignments': !exists(json, 'incident_role_assignments') ? undefined : ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
        'name': json['name'],
        'parent_id': json['parent_id'],
        'users_to_invite': !exists(json, 'users_to_invite') ? undefined : json['users_to_invite'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
    };
}

export function StreamsCreateRequestBodyToJSON(value?: StreamsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idempotency_key': value.idempotency_key,
        'incident_role_assignments': value.incident_role_assignments === undefined ? undefined : ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
        'name': value.name,
        'parent_id': value.parent_id,
        'users_to_invite': value.users_to_invite,
        'visibility': value.visibility,
    };
}

