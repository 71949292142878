// we special case this filter field in a bunch of places.
export const FULL_TEXT_SEARCH = "full_text_search";
export const ALERT_ID = "id";
export const INCIDENT_ID = "id";

// make sure full_text_search, although present in the config, is hidden
// from the add and edit modals. We do this because the full text search is special-cased
// in the UI, and is always present. include_private is the same.
export const SPECIAL_INCIDENT_FILTERS = [FULL_TEXT_SEARCH, INCIDENT_ID];
export const SPECIAL_ALERT_FILTERS = [FULL_TEXT_SEARCH, ALERT_ID];

export const SPECIAL_ESCALATION_FILTERS = [FULL_TEXT_SEARCH];

// SPECIAL_BOOL_FILTERS are filters we special case to put at the top of the filters popover
// and don't show within the filters bar.
//
// They're things that we filter _out_ by default, so we want to make obvious to people when
// they look at filters that they need to opt in.
export const SPECIAL_BOOL_FILTERS = [
  "include_declined_canceled",
  "include_merged",
  "include_private",
] as const;

export const isSpecialBoolFilter = (field_key: string): boolean => {
  return SPECIAL_BOOL_FILTERS.includes(
    field_key as (typeof SPECIAL_BOOL_FILTERS)[number],
  );
};
