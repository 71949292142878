import { OrgAwareLink } from "@incident-shared/org-aware";
import { BadgeSize, badgeSizeToIconSize } from "@incident-ui/Badge/Badge";
import { Icon, IconEnum, IconProps } from "@incident-ui/Icon/Icon";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { Spinner, SpinnerTheme } from "@incident-ui/Spinner/Spinner";
import React, { ForwardedRef, SyntheticEvent } from "react";
import { LinkProps } from "react-router-dom";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { tcx } from "src/utils/tailwind-classes";

export enum ButtonTheme {
  Primary = "primary",
  Secondary = "secondary",
  Destroy = "destroy",
  DestroySecondary = "destroy-secondary",
  Dashed = "dashed",
  Tertiary = "tertiary",
  Naked = "naked",
  Link = "link",
  Ghost = "ghost",
  Unstyled = "unstyled",
  UnstyledPill = "unstyled-pill",
}

export type AnchorProps = {
  href?: string;
  target?: string;
  openInNewTab?: boolean;
};

type StyleProps = {
  theme?: ButtonTheme;
  size?: BadgeSize;
  className?: string;
  loading?: boolean;
  spinnerTheme?: SpinnerTheme;
  disabled?: boolean;
  icon?: IconEnum;
};

export type SharedButtonProps = {
  onClick?: (e?: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  type?: "button" | "reset" | "submit";
  analyticsTrackingId: string | null;
  analyticsTrackingMetadata?: { [key: string]: string | boolean | number };
  iconPosition?: "left" | "right";
  iconProps?: Omit<IconProps, "id">;
  loading?: boolean;
  disabled?: boolean;
  // This appears unused, but is passed to the html button (assuming you render a button) and
  // will 'wire up' this button as a submit for the given form ID.
  form?: string;
} & (
  | {
      title: string;
    }
  | {
      children: React.ReactNode | string | Element;
      title?: string;
    }
) &
  AnchorProps &
  StyleProps;

export type ButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  SharedButtonProps;

/**
 * This button can be either a `<button>` or an `<a>` depending on the props you pass in.
 * If you give it an `onClick` it'll be a `button`. `href` will make it an `<a>`.
 * If you give it an `href` that's internal (i.e. a relative path) it'll be a react-router
 * `Link` (i.e. a link to another part of our app).
 *
 * A button can optionally have an icon, and it can even be just an icon (but in that case you need to pass in a `title` so users know what it does).
 *
 * There are a few different themes:
 * - `ButtonTheme.Primary` for main CTAs like 'confirm'. When a user is presented with a set of buttons, only one should be primary.
 * - `ButtonTheme.Secondary` for every other CTA, like 'cancel'.
 * - `ButtonTheme.Tertiary` for the light grey button without a border that we use in various designs.
 * - `ButtonTheme.Destructive` for a 'dangerous' action that will result in something being destroyed.
 * - `ButtonTheme.Unstyled` for when you want to completely control the styling.
 */
export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    props: ButtonProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    const {
      children: childrenRaw,
      className,
      type = "button",
      theme: themeKey = ButtonTheme.Secondary,
      title,
      size = BadgeSize.Large,
      analyticsTrackingId,
      analyticsTrackingMetadata,
      onClick,
      href,
      openInNewTab = false,
      icon,
      iconPosition = "left",
      iconProps,
      loading,
      spinnerTheme,
      ...restProps
    } = props;

    const theme = ThemeFor[themeKey];

    // Loading buttons are always disabled to prevent double-submits.
    const disabled = !!props.disabled || !!loading;

    // Wrap the children in a span IF it's a string, so that it can be made
    // invisible while the parent is still visible
    let children = childrenRaw;
    if (typeof children === "string") {
      children = <span>{childrenRaw}</span>;
    }

    // Special case: the plus icon looks weird when it's too large, so we shrink it down and change
    // the gap to make it look more 'connected' to the content.
    const isPlusIcon = icon === IconEnum.Add;

    const classes = getButtonClasses({
      theme,
      size,
      className,
      loading,
      disabled,
      iconOnly: icon != null && children == null,
      isPlusIcon,
    });

    const analytics = useAnalytics();

    let Component: string | React.ForwardRefExoticComponent<LinkProps> =
      "button";
    if (href && !disabled) {
      if (
        href.startsWith("http") ||
        href.startsWith("app") ||
        href.startsWith("/api") ||
        href.startsWith("/auth")
      ) {
        Component = "a";
      } else {
        Component = OrgAwareLink;
      }
    }

    const wrappedOnClick = (
      e: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
      if (analyticsTrackingId) {
        analytics?.track(
          `${analyticsTrackingId}.clicked`,
          analyticsTrackingMetadata,
        );
      }

      if (onClick) {
        onClick(e);
      }
    };

    let specificComponentProps = {};

    if (Component === "button") {
      specificComponentProps = {
        type,
        onClick: wrappedOnClick,
        disabled,
      };
    } else if (Component === "a") {
      specificComponentProps = {
        href,
        rel: /https/gi.test(href ?? "") ? "noopener noreferrer" : undefined,
        target: openInNewTab ? "_blank" : undefined,
        onClick: wrappedOnClick,
      };
    } else {
      specificComponentProps = {
        to: href,
        onClick: wrappedOnClick,
        target: openInNewTab ? "_blank" : undefined,
      };
    }
    return (
      <Component
        className={classes}
        // @ts-expect-error not sure how to fix this
        ref={ref}
        title={title}
        {...specificComponentProps}
        data-testid={analyticsTrackingId}
        data-intercom-target={analyticsTrackingId}
        {...restProps}
      >
        <>
          {loading && (
            <ButtonSpinner theme={theme} spinnerTheme={spinnerTheme} />
          )}
          {icon != null && iconPosition === "left" && (
            <ButtonIcon
              icon={icon}
              theme={theme}
              iconProps={iconProps || {}}
              size={isPlusIcon ? BadgeSize.Medium : size}
            />
          )}
          {children}
          {icon != null && iconPosition === "right" && (
            <ButtonIcon
              icon={icon}
              theme={theme}
              iconProps={iconProps || {}}
              size={isPlusIcon ? BadgeSize.Medium : size}
            />
          )}
        </>
      </Component>
    );
  },
);

Button.displayName = "Button";

type ThemeConfig = {
  button: string;
  hover: string;
  icon: string;
  // This controls whether or not the button looks like a button (with a border
  // and maybe a background), or more like an inline link.
  textOnly: boolean;
  spinner: SpinnerTheme;
};

export const ThemeFor: { [key in ButtonTheme]: ThemeConfig } = {
  [ButtonTheme.Primary]: {
    button: tcx(
      "text-content-invert bg-surface-invert border-transparent shadow-button",
      "disabled:bg-slate-400 disabled:text-content-invert",
    ),
    hover: "hover:bg-slate-900 active:bg-slate-950 active:shadow-none",
    icon: "", // Don't need any specific styles
    textOnly: false,
    spinner: SpinnerTheme.White,
  },
  [ButtonTheme.Secondary]: {
    button: tcx(
      "text-content-primary bg-white border border-stroke shadow-button",
      "disabled:bg-surface-secondary disabled:text-content-tertiary",
    ),
    hover:
      "hover:border-stroke-hover active:border-stroke-hover active:shadow-none",
    icon: "", // Don't need any specific styles
    textOnly: false,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.Tertiary]: {
    button: tcx(
      "bg-surface-secondary border-surface-secondary text-content-primary",
      "disabled:text-slate-400",
    ),
    hover:
      "hover:bg-surface-tertiary hover:border-surface-tertiary active:bg-slate-200 active:border-slate-200",
    icon: "", // Don't need any specific styles
    textOnly: false,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.Dashed]: {
    button: tcx(
      "bg-transparent text-content-tertiary",
      "border border-dashed border-slate-200",
    ),
    hover: "hover:border-slate-300 hover:text-content-secondary",
    icon: "text-blue-content",
    textOnly: false,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.Ghost]: {
    button: tcx(
      "text-content-primary",
      "disabled:text-content-tertiary",
      "border border-transparent",
    ),
    hover: "hover:bg-surface-secondary active:bg-surface-tertiary",
    icon: "",
    textOnly: false,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.Naked]: {
    button: tcx("text-sm", "text-content-secondary", "disabled:text-slate-300"),
    hover: "hover:text-content-primary active:text-content-primary",
    icon: "", // Don't need any specific styles
    textOnly: true,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.Link]: {
    button: "text-sm",
    hover: "hover:underline active:text-content-secondary",
    icon: "", // Don't need any specific styles
    textOnly: true,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.Destroy]: {
    button: tcx(
      "bg-surface-destroy text-content-invert border border-stroke-destroy shadow-button",
      "disabled:bg-red-300 disabled:border-red-300",
    ),
    hover:
      "hover:bg-red-700 hover:border-red-700 active:bg-red-800 active:border-red-800 active:shadow-none",
    icon: "", // Don't need any specific styles
    textOnly: false,
    spinner: SpinnerTheme.White,
  },
  [ButtonTheme.DestroySecondary]: {
    button: tcx(
      "text-content-destroy bg-white border border-red-200 shadow-button",
      "disabled:bg-red-50 disabled:border-red-200 disabled:text-red-400",
    ),
    hover:
      "hover:border-red-300 hover:text-red-700 active:border-red-300 active:text-red-700 active:shadow-none",
    icon: "", // Don't need any specific styles
    textOnly: false,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.Unstyled]: {
    button: "",
    hover: "",
    icon: "", // Don't need any specific styles
    textOnly: true,
    spinner: SpinnerTheme.Slate,
  },
  [ButtonTheme.UnstyledPill]: {
    button: "border-transparent",
    hover: "",
    icon: "",
    textOnly: false,
    spinner: SpinnerTheme.Slate,
  },
};

const sizeStyles: {
  [key in BadgeSize]: { all?: string; iconOnly?: string };
} = {
  [BadgeSize.ExtraSmall]: {
    all: "h-5 rounded px-1 text-xs",
    iconOnly: "w-5 px-0",
  },
  [BadgeSize.Small]: {
    all: "h-6 rounded px-2 text-xs",
    iconOnly: "w-6 px-0",
  },
  [BadgeSize.Medium]: { all: "h-7 rounded px-2", iconOnly: "w-7 px-0" },
  [BadgeSize.Large]: {
    all: "h-10 rounded-2 px-3",
    iconOnly: "w-10 px-0",
  },
};

export const getButtonClasses = ({
  theme,
  size,
  className,
  loading,
  disabled,
  iconOnly,
  isPlusIcon,
}: {
  theme: ThemeConfig;
  size: BadgeSize;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  isPlusIcon: boolean;
}): string | undefined => {
  const baseStyles = tcx(
    // Make the cursor look clickable
    "cursor-pointer disabled:cursor-not-allowed",
    // Position the overlay spinner correctly against the parent container
    loading && "relative",
    // A button should hover 'as one', you can't click little bits of it.
    "group",
    // When our button is loading, everything in it is invisible (except the
    // overlay which opts itself out)
    { "[&>*]:invisible": loading },
    // By default, buttons shouldn't be 'squashed' in flex boxes
    "shrink-0",
  );

  const styleConfig = theme.textOnly
    ? tcx("inline-flex items-center", isPlusIcon ? "gap-0.5" : "gap-1")
    : tcx(
        // Always have a 1px border so we can add and remove it without layout shift
        "border",
        // Buttons should display as block by default, as that's what we want most of the time.
        // We expect an icon and some text next door, so we by default have a gap
        "flex items-center justify-center gap-1",
        // All buttons have medium (500) small text, by default
        "font-medium text-sm",
        // We shouldn't wrap a button over two lines (it won't fit in our fixed height)
        "whitespace-nowrap",
        // Depending on the size, we specify a fixed height and an amount of rounding and padding
        sizeStyles[size].all,
        // If it's just an icon, make sure it's a square!
        iconOnly && sizeStyles[size].iconOnly,
      );

  return tcx(
    baseStyles,
    styleConfig,
    theme.button,
    // Don't apply any hover styles if the button can't be interacted with!
    !loading && !disabled ? theme.hover : "",
    className,
  );
};

export const ButtonSpinner = ({
  theme,
  spinnerTheme,
}: {
  theme: ThemeConfig;
  spinnerTheme?: SpinnerTheme;
}) => {
  return (
    <div
      className={tcx(
        // Position it in the right place against the button
        "absolute top-0 bottom-0 left-0 right-0",
        // Center the loader inside the button
        "flex justify-center items-center",
        // Make it visible, as the parent is 'invisible' so the
        // contents are all hidden
        "!visible",
      )}
    >
      {theme.textOnly ? (
        <LoadingBar />
      ) : (
        <Spinner theme={spinnerTheme ? spinnerTheme : theme.spinner} />
      )}
    </div>
  );
};

const ButtonIcon = ({
  icon,
  theme,
  size,
  iconProps,
}: {
  icon: IconEnum;
  theme: ThemeConfig;
  size: BadgeSize;
  iconProps: Omit<IconProps, "id">;
}) => {
  return (
    <Icon
      id={icon}
      size={badgeSizeToIconSize[size]}
      {...iconProps}
      className={tcx("shrink-0", theme.icon, iconProps.className)}
    />
  );
};
