/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageCreateSubPageRequestBody
 */
export interface StatusPageCreateSubPageRequestBody {
    /**
     * The catalog entry of the parent page's split-by type, which defines which incidents are relevant to this subpage.
     * @type {string}
     * @memberof StatusPageCreateSubPageRequestBody
     */
    defined_by_catalog_entry_id: string;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPageCreateSubPageRequestBody
     */
    name: string;
    /**
     * The parent status page which incidents are posted to.
     * @type {string}
     * @memberof StatusPageCreateSubPageRequestBody
     */
    parent_status_page_id: string;
    /**
     * The subpath of within the parent status page
     * @type {string}
     * @memberof StatusPageCreateSubPageRequestBody
     */
    subpath: string;
}

export function StatusPageCreateSubPageRequestBodyFromJSON(json: any): StatusPageCreateSubPageRequestBody {
    return StatusPageCreateSubPageRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateSubPageRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateSubPageRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defined_by_catalog_entry_id': json['defined_by_catalog_entry_id'],
        'name': json['name'],
        'parent_status_page_id': json['parent_status_page_id'],
        'subpath': json['subpath'],
    };
}

export function StatusPageCreateSubPageRequestBodyToJSON(value?: StatusPageCreateSubPageRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defined_by_catalog_entry_id': value.defined_by_catalog_entry_id,
        'name': value.name,
        'parent_status_page_id': value.parent_status_page_id,
        'subpath': value.subpath,
    };
}

