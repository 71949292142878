import { SearchBar as SearchBarUI } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { ExtendedFormFieldValue } from "src/components/@shared/filters";
import { FormField } from "src/contexts/ClientContext";
import { v4 as uuid } from "uuid";

import { FULL_TEXT_SEARCH } from "./constants";

export const SearchBar = ({
  id,
  placeholder,
  availableFilterFields,
  appliedFilters,
  onEditFilter,
  onAddFilter,
  onDeleteFilter,
  className,
}: {
  id: string;
  placeholder: string;
  availableFilterFields: FormField[];
  onEditFilter: (f: ExtendedFormFieldValue) => void;
  onDeleteFilter: (id: string) => void;
  onAddFilter: (f: ExtendedFormFieldValue) => void;
  appliedFilters: ExtendedFormFieldValue[];
  className?: string | undefined;
}): React.ReactElement | null => {
  // TODO: debounce new inputs here?
  const fullTextSearchConfig = availableFilterFields.find(
    (x) => x.key === FULL_TEXT_SEARCH,
  );
  if (!fullTextSearchConfig) {
    return null;
  }
  const currentFilterObj = appliedFilters.find(
    (x) => x.field_key === fullTextSearchConfig.key,
  );

  const onChange = (newVal: string) => {
    if (newVal === "") {
      // if we have a filter obj, we should delete it
      if (currentFilterObj) {
        onDeleteFilter(currentFilterObj.field_id);
      }
      return;
    }

    // check if there's already a filter that we need to edit
    if (currentFilterObj) {
      onEditFilter({
        ...currentFilterObj,
        string_value: newVal,
      });
    } else {
      onAddFilter({
        key: uuid(),
        field_key: fullTextSearchConfig.key,
        field_id: fullTextSearchConfig.key,
        operator: fullTextSearchConfig.operators[0].key,
        string_value: newVal,
        filter_id: fullTextSearchConfig.key,
      });
    }
  };

  return (
    <SearchBarUI
      id={id}
      placeholder={placeholder}
      type={InputType.Search}
      onChange={onChange}
      value={currentFilterObj?.string_value || ""}
      autoFocus
      className={className}
    />
  );
};
