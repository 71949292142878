import { CatalogTypeSuggestion } from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Callout, CalloutTheme, Link } from "@incident-ui";
import React from "react";
import { UseFormReturn } from "react-hook-form";

import { ConvertToCatalogFormType } from "./ConvertCustomFieldToCatalogModal";

export const StepChooseCatalogType = ({
  allCatalogTypes,
  suggestions,
  formMethods,
}: {
  allCatalogTypes: CatalogTypeSuggestion[];
  suggestions: CatalogTypeSuggestion[];
  formMethods: UseFormReturn<ConvertToCatalogFormType>;
}): React.ReactElement => {
  const hasSuggestions = suggestions.length > 0;
  const hasOneSuggestion = suggestions.length === 1;

  const suggestedCatalogTypeOptions = suggestions.map((suggestion) => ({
    label: suggestion.catalog_type.name,
    value: suggestion.catalog_type.id,
    description: suggestion.catalog_type.description,
  }));

  const allCatalogTypeOptions = allCatalogTypes.map((suggestion) => ({
    label: suggestion.catalog_type.name,
    value: suggestion.catalog_type.id,
  }));

  return (
    <>
      {hasSuggestions ? (
        <>
          {hasOneSuggestion ? (
            <div className="flex flex-col space-y-4">
              <Callout theme={CalloutTheme.Plain}>
                It looks like this field matches the{" "}
                <span className="font-semibold">
                  {suggestions[0].catalog_type.name}
                </span>{" "}
                catalog type. If that&apos;s correct, click Continue to connect
                this field to the catalog. If not, select any catalog type from
                the dropdown below.
              </Callout>
              <StaticSingleSelectV2
                formMethods={formMethods}
                name="catalog_type_id"
                label="All catalog types"
                options={allCatalogTypeOptions}
              />
            </div>
          ) : (
            <div className="flex flex-col space-y-4">
              <p className="text-sm">
                We&apos;ve found a number of catalog types that this custom
                field might be a good match for. Please select the catalog type
                you want to convert to from either our suggestions, or the
                dropdown of all catalog types below.
              </p>
              <RadioButtonGroupV2
                formMethods={formMethods}
                name="catalog_type_id"
                label="Suggested catalog types"
                srLabel="Suggested catalog types"
                options={suggestedCatalogTypeOptions}
              />
              <StaticSingleSelectV2
                formMethods={formMethods}
                name="catalog_type_id"
                label="All catalog types"
                options={allCatalogTypeOptions}
              />
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col space-y-4">
          <Callout theme={CalloutTheme.Plain}>
            We haven&apos;t found any catalog types matching this custom field,{" "}
            <Link
              analyticsTrackingId="migrate-to-catalog-redirect-to-type-create"
              to={"/catalog/create"}
            >
              please create one first
            </Link>
            , or select an existing catalog type from the dropdown below.
          </Callout>
          <StaticSingleSelectV2
            formMethods={formMethods}
            name="catalog_type_id"
            label="All catalog types"
            options={allCatalogTypeOptions}
          />
        </div>
      )}
    </>
  );
};
