import type { SVGProps } from "react";
import * as React from "react";
const SvgExpel = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#1AAE51"
      fillRule="evenodd"
      d="M3 17.422c0 .32.266.578.593.578h12.814a.586.586 0 0 0 .593-.578V2.579A.586.586 0 0 0 16.407 2H3.593A.586.586 0 0 0 3 2.578v14.845Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m6.235 5.465 7.76 9.39-7.76-9.39ZM6 14.855l7.717-9.39L6 14.855Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.67 4.306a1.5 1.5 0 0 1 .206 2.112l-2.946 3.584 3.222 3.898a1.5 1.5 0 1 1-2.313 1.911l-2.85-3.448-2.83 3.445a1.5 1.5 0 0 1-2.318-1.905l3.202-3.896-2.964-3.586A1.5 1.5 0 0 1 7.39 4.51l2.592 3.136 2.576-3.133a1.5 1.5 0 0 1 2.11-.207Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgExpel;
