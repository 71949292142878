import { IssueTemplateCreateEditModal } from "@incident-shared/issue-templates/IssueTemplateCreateEditModal";
import { JiraIssueTemplateCreateEditModal } from "@incident-shared/issue-templates/JiraIssueTemplateCreateEditModal";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers/useAllTemplates";
import React from "react";
import { Route, Routes } from "react-router";

import { SettingsIncidentTicketsPage } from "./SettingsIncidentTicketsPage";

export const IncidentTicketsRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route path="templates">
          <Route
            path={`${IssueTrackerProviderEnum.Jira}/create`}
            element={
              <JiraIssueTemplateCreateEditModal
                context={IssueTemplateContextEnum.IncidentTicket}
              />
            }
          />
          <Route
            path={`${IssueTrackerProviderEnum.Jira}/:id`}
            element={
              <JiraIssueTemplateCreateEditModal
                context={IssueTemplateContextEnum.IncidentTicket}
              />
            }
          />

          {Object.values(IssueTrackerProviderEnum).map((provider) => (
            // These go _below_ Jira Cloud, since it has custom modals that
            // need migrating to issuetrackerv2 in the future
            <>
              <Route
                path={`${provider}/create`}
                element={
                  <IssueTemplateCreateEditModal
                    provider={provider}
                    context={IssueTemplateContextEnum.IncidentTicket}
                  />
                }
              />
              <Route
                path={`${provider}/:id`}
                element={
                  <IssueTemplateCreateEditModal
                    provider={provider}
                    context={IssueTemplateContextEnum.IncidentTicket}
                  />
                }
              />
            </>
          ))}
        </Route>
      </Routes>

      <SettingsIncidentTicketsPage />
    </>
  );
};
