import type { SVGProps } from "react";
import * as React from "react";
const SvgCustomField = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.916 11.163c-.021 0-.041 0-.062-.002a.75.75 0 0 1-.687-.808 1.255 1.255 0 0 0-.362-.986l-5.792-5.794a.255.255 0 0 0-.177-.073H4.957A.75.75 0 0 1 3.5 3.25v-.5A.75.75 0 0 1 4.25 2h5.586c.461 0 .911.187 1.237.513l5.793 5.793c.571.572.862 1.362.796 2.167a.748.748 0 0 1-.746.689v.001Z"
    />
    <path
      fill="currentColor"
      d="M15.366 11.306 9.573 5.513A1.76 1.76 0 0 0 8.336 5H2.75a.75.75 0 0 0-.75.75v5.586c0 .467.182.907.513 1.237l5.793 5.793a2.74 2.74 0 0 0 1.944.804 2.74 2.74 0 0 0 1.944-.804l3.172-3.172c.52-.519.806-1.209.806-1.944 0-.735-.286-1.425-.806-1.944ZM6.25 10.5C5.561 10.5 5 9.939 5 9.25S5.561 8 6.25 8s1.25.561 1.25 1.25-.561 1.25-1.25 1.25Z"
    />
  </svg>
);
export default SvgCustomField;
