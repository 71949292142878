import {
  EnrichedSlackMessage,
  IncidentActivityLogEntry,
  IncidentActivityLogEntryTypeEnum,
} from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Link } from "@incident-ui";
import * as Sentry from "@sentry/react";
import React from "react";

import { ActivityLogItemUIInner } from "./ActivityLogItemUI";

export const activityItemGenericErrorTitle =
  "Uh-oh! We couldn't render this item";
export const activityItemGenericErrorContent = (
  <>{"We've already notified our engineers, and we'll get it fixed."}</>
);

// ActivityItemError is the component that renders an error message, either when:
// - We failed to enrich a slack message in the backend
// - We hit the error boundary when trying to render the item (i.e. there is a front end error)
export const ActivityItemError = ({
  title,
  content,
  item,
  onShow,
  error,
  componentStack,
}: {
  title: string;
  content: React.ReactElement;
  item: IncidentActivityLogEntry;
  error?: Error;
  componentStack?: string;
  onShow?: (props: { incidentId: string; itemId: string }) => void;
}): React.ReactElement => {
  if (error) {
    // If we're here because the activity item rendered due to a React error, let's send that to Sentry!
    Sentry.captureException(
      new Error(`Failed to render activity log item`, { cause: error }),
      {
        extra: {
          activity_item: item.id,
          component_stack: componentStack,
        },
      },
    );
  }

  return (
    <ActivityLogItemUIInner
      icon={IconEnum.Warning}
      iconColour={ColorPaletteEnum.Yellow}
      title={title}
      timestamp={item.occurred_at}
      itemId={item.id}
      itemIncidentId={item.incident_id}
      onShow={onShow}
      unquotedContent={content}
    />
  );
};

type EnrichmentErrorResult = {
  title: string;
  content: React.ReactElement;
};

// getEnrichmentError figures out if there was an error whilst trying to enrich
// a timeline item in the backend, and returns an explanation if so.
export const getEnrichmentError = (
  item?: IncidentActivityLogEntry,
): EnrichmentErrorResult | undefined => {
  if (!item) {
    return undefined;
  }
  const { type, content } = item;

  let msg: EnrichedSlackMessage | undefined;
  switch (type) {
    case IncidentActivityLogEntryTypeEnum.SlackImage:
      msg = content.slack_image?.slack_message;
      break;
    case IncidentActivityLogEntryTypeEnum.SlackPin:
      msg = content.slack_pin?.message;
      break;
    case IncidentActivityLogEntryTypeEnum.SlackInferSentry:
      msg = content.slack_infer_sentry?.slack_message;
      break;
  }

  if (msg?.enrichment_error) {
    switch (msg.enrichment_error) {
      case "not-in-channel":
        return {
          title: "Pinned message in archived channel",
          content: (
            <>
              Unarchive the incident Slack channel to see this message.
              {msg.permalink && (
                <>
                  {" "}
                  You can still{" "}
                  <Link
                    openInNewTab
                    href={msg.permalink}
                    analyticsTrackingId={null}
                  >
                    view the message in Slack
                  </Link>
                  .
                </>
              )}
            </>
          ),
        };
      case "channel-is-private-or-deleted":
      case "thread-not-found":
        return {
          title: "Pinned message in private channel",
          content: (
            <>
              {"For security reasons, we can't show the message content."}
              {msg.permalink && (
                <>
                  {" "}
                  You may be able to{" "}
                  <Link
                    openInNewTab
                    href={msg.permalink}
                    analyticsTrackingId={null}
                  >
                    view the message in Slack
                  </Link>
                  .
                </>
              )}
            </>
          ),
        };
      case "slack-pin-unknown":
        // We don't want to create loads of alert noise here, as we're logging the warning on the back end (which is much more useful!).
        // So we just return a generic error.
        return {
          title: activityItemGenericErrorTitle,
          content: activityItemGenericErrorContent,
        };

      case "unknown":
      default:
        // We're not handling this one nicely on the frontend yet. Someone has
        // probably added a new error code without adding its frontend equivalent.
        //
        // Report the error so we know to handle it, but for the customer, it's
        // the same as the unknown case - generic error message.
        Sentry.captureException(
          new Error(
            `Failed to render timeline enrichment error for code '${msg.enrichment_error}' - have we written a renderer for it?`,
          ),
          {
            extra: {
              timeline_item_id: item.id,
              enrichment_error_code: msg.enrichment_error,
            },
          },
        );
        return {
          title: activityItemGenericErrorTitle,
          content: activityItemGenericErrorContent,
        };
    }
  }

  return undefined;
};
