import type { SVGProps } from "react";
import * as React from "react";
const SvgAddIncidentType = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.75 2.5h-3A1.75 1.75 0 0 0 2 4.25v3C2 8.216 2.784 9 3.75 9h3A1.75 1.75 0 0 0 8.5 7.25v-3A1.75 1.75 0 0 0 6.75 2.5ZM6.75 11h-3A1.75 1.75 0 0 0 2 12.75v3c0 .966.784 1.75 1.75 1.75h3a1.75 1.75 0 0 0 1.75-1.75v-3A1.75 1.75 0 0 0 6.75 11ZM15.25 2.5h-3a1.75 1.75 0 0 0-1.75 1.75v3c0 .966.784 1.75 1.75 1.75h3A1.75 1.75 0 0 0 17 7.25v-3a1.75 1.75 0 0 0-1.75-1.75ZM16.25 13.5H14.5v-1.75a.75.75 0 0 0-1.5 0v1.75h-1.75a.75.75 0 0 0 0 1.5H13v1.75a.75.75 0 0 0 1.5 0V15h1.75a.75.75 0 0 0 0-1.5Z"
    />
  </svg>
);
export default SvgAddIncidentType;
