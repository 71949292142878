import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  ContentBox,
} from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { SettingsSubHeading } from "src/components/settings/SettingsSubHeading";
import { useAPIMutation } from "src/utils/swr";

export const UnsubscribeFromAllIncidentsForm = () => {
  const [unsubscribeConfirmModalOpen, setUnsubscribeConfirmModalOpen] =
    useState(false);

  const showToast = useToast();

  const { trigger: unsubscribeFromAll, isMutating: unsubscribing } =
    useAPIMutation(
      "userPreferencesShow",
      undefined,
      async (apiClient) =>
        await apiClient.incidentSubscriptionsUnsubscribeFromAll(),
      {
        onSuccess: () => {
          setUnsubscribeConfirmModalOpen(false);
          showToast({
            title: "You've been unsubscribed from all incidents",
            theme: ToastTheme.Success,
          });
        },
      },
    );

  return (
    <ContentBox className="p-6">
      <SettingsSubHeading
        title="Unsubscribe from all incidents"
        explanation={`Here you can unsubscribe from all of the incidents you are subscribed to at once.`}
      />
      <LoadingWrapper className="w-fit" large={false} loading={unsubscribing}>
        <Button
          theme={ButtonTheme.DestroySecondary}
          analyticsTrackingId="unsubscribe-from-all"
          onClick={() => setUnsubscribeConfirmModalOpen(true)}
        >
          Unsubscribe from all incidents
        </Button>
      </LoadingWrapper>
      {unsubscribeConfirmModalOpen && (
        <ConfirmationDialog
          title={"Are you sure?"}
          analyticsTrackingId="unsubscribe-from-all-modal"
          isOpen={unsubscribeConfirmModalOpen}
          onCancel={() => setUnsubscribeConfirmModalOpen(false)}
          onConfirm={() => unsubscribeFromAll({})}
          confirmButtonTheme={ButtonTheme.Destroy}
        >
          <div>
            This action will unsubscribe you from all incidents.
            <br />
            Note that if you have active auto-subscribe rules, this will not
            prevent new subscriptions being created in future.
          </div>
        </ConfirmationDialog>
      )}
    </ContentBox>
  );
};
