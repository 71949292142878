import {
  InternalStatusPageBuildStructureRequestBody,
  InternalStatusPageBuildStructureResponseBody,
  InternalStatusPageComponent,
  InternalStatusPageComponentGroup,
} from "@incident-io/api";
import { useMemo } from "react";
import { PreviewItem } from "src/components/status-pages/common/ComponentsEditor/utils";

import { useAPI } from "./swr";

export const groupIsHidden = (
  group: InternalStatusPageComponentGroup,
  structure: InternalStatusPageBuildStructureResponseBody,
  hiddenCatalogGroupValues: string[],
  hiddenCustomFieldGroupIDs: string[],
): boolean => {
  if (structure.structure.from_catalog) {
    return hiddenCatalogGroupValues.includes(group.label);
  } else {
    if (!group.custom_field_group_id) {
      throw new Error("Unreachable: expected group ID for non-catalog field");
    }
    return hiddenCustomFieldGroupIDs.includes(group.custom_field_group_id);
  }
};

export const useInternalStatusPageStructure = ({
  component_custom_field_id: customFieldID,
  hidden_catalog_group_values: hiddenCatalogGroupValues,
  hidden_custom_field_group_ids: hiddenCustomFieldGroupIDs,
  hidden_catalog_entry_ids,
  hidden_custom_field_option_ids,
  component_source_expression,
}: Pick<
  InternalStatusPageBuildStructureRequestBody,
  | "component_custom_field_id"
  | "hidden_catalog_group_values"
  | "hidden_custom_field_group_ids"
  | "hidden_catalog_entry_ids"
  | "hidden_custom_field_option_ids"
  | "component_source_expression"
>) => {
  const {
    data: structure,
    error,
    isLoading,
  } = useAPI("internalStatusPageBuildStructure", {
    buildStructureRequestBody: {
      component_custom_field_id: customFieldID,
      hidden_catalog_entry_ids: [],
      hidden_custom_field_group_ids: [],
      hidden_catalog_group_values: [],
      hidden_custom_field_option_ids: [],
      component_source_expression,
    },
  });

  const previewItems = useMemo(() => {
    if (!structure) {
      return [];
    }

    const componentIsHidden = (component: InternalStatusPageComponent) =>
      component.catalog_entry_id
        ? hidden_catalog_entry_ids.includes(component.catalog_entry_id)
        : hidden_custom_field_option_ids.includes(
            component.custom_field_option_id as string,
          );

    const options = structure.structure.components;
    const items: PreviewItem[] = options.map((item) => {
      if (item.group) {
        return {
          id: item.group.label,
          displayUptimeChart: false,
          displayUptimePercentage: false,
          hidden: groupIsHidden(
            item.group,
            structure,
            hiddenCatalogGroupValues,
            hiddenCustomFieldGroupIDs,
          ),
          name: item.group.label,
          children: item.group.components.map((component) => {
            return {
              id: component.custom_field_option_id || "",
              displayUptimeChart: false,
              displayUptimePercentage: false,
              hidden: component.hidden || componentIsHidden(component),
              name: component.label,
            };
          }),
        };
      } else {
        return {
          id: item.component?.custom_field_option_id || "",
          displayUptimeChart: false,
          displayUptimePercentage: false,
          hidden:
            item.component?.hidden ||
            (item.component ? componentIsHidden(item.component) : false),
          name: item.component?.label || "",
        };
      }
    });

    return items;
  }, [
    structure,
    hiddenCatalogGroupValues,
    hiddenCustomFieldGroupIDs,
    hidden_catalog_entry_ids,
    hidden_custom_field_option_ids,
  ]);

  return {
    rawStructure: structure,
    isLoading,
    error,
    previewItems,
  };
};
