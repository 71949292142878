import { EngineScope, Resource } from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { ExpressionsMethodsOverrideProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";

// Provides title and description inputs for the alert source: these are
// templated text templates, allowing use of variables taken from the alert
// scope to build the title and description of the alert.
export const AlertSourceTitleDescription = ({
  scopeWithExpressions,
  resources,
}: {
  scopeWithExpressions: EngineScope;
  resources: Resource[];
}) => {
  const sharedFormElementProps = {
    resources,
    scope: scopeWithExpressions,
    array: false,
    showPlaceholder: true,
  };

  return (
    <ExpressionsMethodsOverrideProvider showExpressionNames>
      <EngineFormElement
        name={`template.title`}
        resourceType={`TemplatedText["plain_single_line"]`}
        labelNode={<div className="text-sm-bold">Alert title</div>}
        {...sharedFormElementProps}
        mode="variables_and_expressions"
        required
      />
      <EngineFormElement
        name={`template.description`}
        resourceType={`TemplatedText["plain_multi_line"]`}
        labelNode={<div className="text-sm-bold">Description</div>}
        {...sharedFormElementProps}
        mode="variables_and_expressions"
        required
      />
    </ExpressionsMethodsOverrideProvider>
  );
};
