/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinearUser,
    LinearUserFromJSON,
    LinearUserFromJSONTyped,
    LinearUserToJSON,
} from './LinearUser';

/**
 * 
 * @export
 * @interface LinearConfig
 */
export interface LinearConfig {
    /**
     * 
     * @type {LinearUser}
     * @memberof LinearConfig
     */
    connecting_user: LinearUser;
}

export function LinearConfigFromJSON(json: any): LinearConfig {
    return LinearConfigFromJSONTyped(json, false);
}

export function LinearConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinearConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connecting_user': LinearUserFromJSON(json['connecting_user']),
    };
}

export function LinearConfigToJSON(value?: LinearConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connecting_user': LinearUserToJSON(value.connecting_user),
    };
}

