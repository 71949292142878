/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRoute,
    AlertRouteFromJSON,
    AlertRouteFromJSONTyped,
    AlertRouteToJSON,
} from './AlertRoute';

/**
 * 
 * @export
 * @interface AlertRoutesCreateAlertRouteResponseBody
 */
export interface AlertRoutesCreateAlertRouteResponseBody {
    /**
     * 
     * @type {AlertRoute}
     * @memberof AlertRoutesCreateAlertRouteResponseBody
     */
    alert_route: AlertRoute;
}

export function AlertRoutesCreateAlertRouteResponseBodyFromJSON(json: any): AlertRoutesCreateAlertRouteResponseBody {
    return AlertRoutesCreateAlertRouteResponseBodyFromJSONTyped(json, false);
}

export function AlertRoutesCreateAlertRouteResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesCreateAlertRouteResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_route': AlertRouteFromJSON(json['alert_route']),
    };
}

export function AlertRoutesCreateAlertRouteResponseBodyToJSON(value?: AlertRoutesCreateAlertRouteResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_route': AlertRouteToJSON(value.alert_route),
    };
}

