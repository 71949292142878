import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { ButtonTheme, ConfirmationDialog, Heading } from "@incident-ui";
import { ConfirmationDialogFooterProps } from "@incident-ui/ConfirmationDialog/ConfirmationDialog";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { isEmpty } from "lodash";
import _ from "lodash";
import { useMemo } from "react";
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  DependentResource as DependentResource,
  DependentResourcePayload,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

type ModalProps<
  T extends "form" | "confirm" = "confirm",
  FormData extends FieldValues = never,
> = {
  onClose: () => void;
  onDelete: T extends "confirm"
    ? () => Promise<void> | void
    : SubmitHandler<FormData>;
  dependentResources?: DependentResource[];
  extraDependentResources?: DependentResource[];
  resourceTitle: React.ReactNode;
  isOpen: boolean;
  title: string;
  analyticsTrackingId: string | null;
  deleteConfirmationContent?: T extends "confirm" ? React.ReactNode : never;
  isDeleting?: boolean;
  // types specific to the Form modal
  children: T extends "form" ? React.ReactNode : never;
  formMethods: T extends "form" ? UseFormReturn<FormData> : never;
  footer?: T extends "form" ? React.ReactNode : never;
};

// We need custom types because React doesn't interpret a `never` type as optional, even though it is
type FormModalProps<FormData extends FieldValues> = Omit<
  ModalProps<"form", FormData>,
  "deleteConfirmationContent" | "isDeleting"
>;
type ConfirmModalProps = Omit<
  ModalProps<"confirm">,
  "footer" | "formMethods" | "children"
> & {
  fetchDependentResources?: DependentResourcePayload[];
  confirmButtonText?: string;
} & Omit<ConfirmationDialogFooterProps, "onConfirm">;

// Use the DeletionConfirmationModal when you have dependent resources and only require confirmation, no input
export const DeletionConfirmationModal = ({
  dependentResources: _dependentResources = [],
  fetchDependentResources,
  extraDependentResources,
  resourceTitle,
  onDelete,
  isDeleting,
  isOpen,
  onClose,
  title,
  analyticsTrackingId,
  deleteConfirmationContent,
  confirmButtonTheme = ButtonTheme.Destroy,
  ...footerProps
}: ConfirmModalProps): React.ReactElement | null => {
  const { data, isValidating, error } = useAPI(
    fetchDependentResources && isOpen
      ? "engineFindDependentResourcesForMultiple"
      : null,
    fetchDependentResources
      ? {
          findDependentResourcesForMultipleRequestBody: {
            resources: fetchDependentResources as DependentResourcePayload[],
          },
        }
      : {
          findDependentResourcesForMultipleRequestBody: { resources: [] },
        },
  );

  const { requiresDeletionResources, autoDeletingResources } = useMemo(() => {
    let dependentResources = _dependentResources;
    if (fetchDependentResources) {
      dependentResources = data?.dependent_resources ?? [];
    }

    if (extraDependentResources) {
      dependentResources.push(...extraDependentResources);
    }

    return groupDependentResources(dependentResources ?? []);
  }, [
    _dependentResources,
    fetchDependentResources,
    data?.dependent_resources,
    extraDependentResources,
  ]);

  if (error) {
    return <ErrorModal onClose={onClose} title={title} error={error} />;
  }

  // We use `isValidating` to make sure we don't ever show stale data here.
  if (fetchDependentResources && isValidating) {
    return (
      <ConfirmationDialog
        title={title}
        analyticsTrackingId={analyticsTrackingId}
        isOpen={isOpen}
        onCancel={onClose}
        saving={isDeleting}
        confirmButtonTheme={confirmButtonTheme}
        {...footerProps}
        onConfirm={onDelete}
        loading
      />
    );
  }

  if (!isEmpty(requiresDeletionResources)) {
    return (
      <ConfirmationDialog
        title={title}
        analyticsTrackingId={analyticsTrackingId}
        isOpen={isOpen}
        onCancel={onClose}
        hideConfirmButton // so all confirm configuration is moot
        onConfirm={() => {
          return;
        }} // do nothing!
        {...footerProps}
        confirmButtonText={"OK"}
        cancelButtonText={"OK"}
      >
        <DependentResourceList
          title={resourceTitle}
          requiresDeletionResources={requiresDeletionResources}
        />
      </ConfirmationDialog>
    );
  }

  if (!isEmpty(autoDeletingResources)) {
    return (
      <ConfirmationDialog
        title={title}
        analyticsTrackingId={analyticsTrackingId}
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={onDelete}
        confirmButtonTheme={confirmButtonTheme}
        {...footerProps}
      >
        {deleteConfirmationContent}
        <AutoDeletingDependentResourceList
          autoDeletingResources={autoDeletingResources}
        />
      </ConfirmationDialog>
    );
  }

  return (
    <ConfirmationDialog
      title={title}
      analyticsTrackingId={analyticsTrackingId}
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onDelete}
      saving={isDeleting}
      confirmButtonTheme={confirmButtonTheme}
      {...footerProps}
    >
      {deleteConfirmationContent}
    </ConfirmationDialog>
  );
};

// Use the DeletionConfirmationFormModal when you have dependent resources and require some form input before confirmation
export const DeletionConfirmationFormModal = <FormData extends FieldValues>({
  dependentResources,
  resourceTitle,
  onDelete,
  isOpen,
  onClose,
  title,
  analyticsTrackingId,
  children,
  formMethods,
  footer,
}: FormModalProps<FormData>): React.ReactElement | null => {
  const { requiresDeletionResources } = groupDependentResources(
    dependentResources ?? [],
  );

  if (!isEmpty(requiresDeletionResources)) {
    return (
      <ConfirmationDialog
        title={title}
        analyticsTrackingId={analyticsTrackingId}
        isOpen={isOpen}
        onCancel={onClose}
        hideConfirmButton
        onConfirm={() => {
          return;
        }}
        confirmButtonText={"OK"}
        cancelButtonText={"OK"}
      >
        <DependentResourceList
          title={resourceTitle}
          requiresDeletionResources={requiresDeletionResources}
        />
      </ConfirmationDialog>
    );
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onDelete}
      title={title}
      analyticsTrackingId={analyticsTrackingId}
      onClose={onClose}
      footer={footer}
    >
      {children}
    </Form.Modal>
  );
};

export const groupDependentResources = (
  dependentResources: DependentResource[],
): {
  autoDeletingResources: DependentResource[][];
  requiresDeletionResources: DependentResource[][];
} => {
  const resourcesByType = _.groupBy(
    dependentResources,
    (res) => res.resource_type,
  );
  const autoDeletingResources = _.filter(resourcesByType, (resources) => {
    return resources[0].can_be_auto_deleted;
  });
  const requiresDeletionResources = _.filter(resourcesByType, (resources) => {
    return !resources[0].can_be_auto_deleted;
  });

  return {
    requiresDeletionResources,
    autoDeletingResources,
  };
};

export const AutoDeletingDependentResourceList = ({
  autoDeletingResources,
}: {
  autoDeletingResources: DependentResource[][];
}): React.ReactElement | null => {
  if (autoDeletingResources.length === 0) {
    return null;
  }

  // If we have no things that _must_ be manually deleted, just show a warning
  // about the things that we'll auto-delete.
  return (
    <>
      <hr className="my-4" />
      <p className={"mb-2"}>
        By deleting this, we&apos;ll also delete the following:
      </p>
      <ul className={"mb-2"}>
        {_.map(autoDeletingResources, (resources) => {
          return (
            <li>
              <Heading className={"mt-2"} level={2}>
                {resources[0].resource_type_label}
              </Heading>
              <li className={"ml-4"}>
                {resources.length} of these will be automatically deleted
              </li>
            </li>
          );
        })}
      </ul>
    </>
  );
};
