/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRelationship,
    IncidentRelationshipFromJSON,
    IncidentRelationshipFromJSONTyped,
    IncidentRelationshipToJSON,
} from './IncidentRelationship';

/**
 * 
 * @export
 * @interface IncidentRelationshipsListResponseBody
 */
export interface IncidentRelationshipsListResponseBody {
    /**
     * 
     * @type {Array<IncidentRelationship>}
     * @memberof IncidentRelationshipsListResponseBody
     */
    incident_relationships: Array<IncidentRelationship>;
}

export function IncidentRelationshipsListResponseBodyFromJSON(json: any): IncidentRelationshipsListResponseBody {
    return IncidentRelationshipsListResponseBodyFromJSONTyped(json, false);
}

export function IncidentRelationshipsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRelationshipsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_relationships': ((json['incident_relationships'] as Array<any>).map(IncidentRelationshipFromJSON)),
    };
}

export function IncidentRelationshipsListResponseBodyToJSON(value?: IncidentRelationshipsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_relationships': ((value.incident_relationships as Array<any>).map(IncidentRelationshipToJSON)),
    };
}

