import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusEmpty = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.87 9.37 13.4 9.077a5.538 5.538 0 0 0 0-2.15l1.471-.291a7.035 7.035 0 0 1 0 2.732Zm-1.046-5.256-1.246.834a5.538 5.538 0 0 0-1.52-1.52l.835-1.246a7.039 7.039 0 0 1 1.931 1.932ZM9.37 1.136l-.292 1.472a5.535 5.535 0 0 0-2.15 0l-.29-1.472a7.034 7.034 0 0 1 2.732 0ZM4.114 2.182 4.95 3.43c-.6.402-1.118.919-1.52 1.52l-1.247-.835a7.038 7.038 0 0 1 1.932-1.932ZM1.137 6.637a7.034 7.034 0 0 0 0 2.732l1.471-.29a5.534 5.534 0 0 1 0-2.15l-1.471-.292Zm1.045 5.256 1.247-.835c.402.6.92 1.118 1.52 1.52l-.835 1.246a7.038 7.038 0 0 1-1.932-1.931Zm4.455 2.977.291-1.471a5.538 5.538 0 0 0 2.15 0l.292 1.47a7.035 7.035 0 0 1-2.733 0Zm5.256-1.046-.835-1.246c.6-.402 1.118-.92 1.52-1.52l1.246.835a7.039 7.039 0 0 1-1.931 1.931Z"
      clipRule="evenodd"
      opacity={0.3}
    />
  </svg>
);
export default SvgIncidentStatusEmpty;
