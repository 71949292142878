import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GenericErrorMessage } from "@incident-ui";
import { sortBy } from "lodash";
import { useFormContext } from "react-hook-form";
import { PostIncidentFlowCreateTaskConfigRequestBodyTaskTypeEnum as TaskType } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { FormDataType } from "./FormDataType";

export const SetCustomFieldsTaskInputs = () => {
  const formMethods = useFormContext<FormDataType>();
  const selectedTaskType = formMethods.watch("task_type");

  const { data: fieldsData, error: customFieldsError } = useAPI(
    selectedTaskType === TaskType.SetCustomFields ? "customFieldsList" : null,
    undefined,
  );

  if (customFieldsError) {
    return (
      <GenericErrorMessage
        error={customFieldsError}
        description="Failed to load custom fields"
      />
    );
  }

  if (selectedTaskType !== TaskType.SetCustomFields) {
    return null;
  }

  const customFields = fieldsData
    ? sortBy(fieldsData.custom_fields, (field) => field.rank)
    : [];

  return (
    <StaticMultiSelectV2
      formMethods={formMethods}
      label="Custom fields"
      required
      name="custom_field_ids"
      options={customFields.map((f) => ({
        value: f.id,
        label: f.name,
      }))}
      placeholder="Select custom fields..."
      helptext={"We'll require that these fields are set to complete the task."}
    />
  );
};
