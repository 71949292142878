/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PostmortemsSettingsShowResponseBody,
    PostmortemsSettingsShowResponseBodyFromJSON,
    PostmortemsSettingsShowResponseBodyToJSON,
    PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody,
    PostmortemsSettingsTogglePostmortemFollowupSyncRequestBodyFromJSON,
    PostmortemsSettingsTogglePostmortemFollowupSyncRequestBodyToJSON,
    PostmortemsSettingsTogglePostmortemFollowupSyncResponseBody,
    PostmortemsSettingsTogglePostmortemFollowupSyncResponseBodyFromJSON,
    PostmortemsSettingsTogglePostmortemFollowupSyncResponseBodyToJSON,
    PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody,
    PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyFromJSON,
    PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyToJSON,
    PostmortemsSettingsUpdateDefaultCopyPasteProviderResponseBody,
    PostmortemsSettingsUpdateDefaultCopyPasteProviderResponseBodyFromJSON,
    PostmortemsSettingsUpdateDefaultCopyPasteProviderResponseBodyToJSON,
    PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequestBody,
    PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequestBodyFromJSON,
    PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequestBodyToJSON,
    PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationResponseBody,
    PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationResponseBodyFromJSON,
    PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationResponseBodyToJSON,
    PostmortemsSettingsUpdateDefaultTimezoneRequestBody,
    PostmortemsSettingsUpdateDefaultTimezoneRequestBodyFromJSON,
    PostmortemsSettingsUpdateDefaultTimezoneRequestBodyToJSON,
    PostmortemsSettingsUpdateDefaultTimezoneResponseBody,
    PostmortemsSettingsUpdateDefaultTimezoneResponseBodyFromJSON,
    PostmortemsSettingsUpdateDefaultTimezoneResponseBodyToJSON,
    PostmortemsSettingsUpdatePostmortemNameRequestBody,
    PostmortemsSettingsUpdatePostmortemNameRequestBodyFromJSON,
    PostmortemsSettingsUpdatePostmortemNameRequestBodyToJSON,
    PostmortemsSettingsUpdatePostmortemNameResponseBody,
    PostmortemsSettingsUpdatePostmortemNameResponseBodyFromJSON,
    PostmortemsSettingsUpdatePostmortemNameResponseBodyToJSON,
    PostmortemsSettingsUpdateTemplateExpressionRequestBody,
    PostmortemsSettingsUpdateTemplateExpressionRequestBodyFromJSON,
    PostmortemsSettingsUpdateTemplateExpressionRequestBodyToJSON,
    PostmortemsSettingsUpdateTemplateExpressionResponseBody,
    PostmortemsSettingsUpdateTemplateExpressionResponseBodyFromJSON,
    PostmortemsSettingsUpdateTemplateExpressionResponseBodyToJSON,
} from '../models';

export interface PostmortemsSettingsTogglePostmortemFollowupSyncRequest {
    togglePostmortemFollowupSyncRequestBody: PostmortemsSettingsTogglePostmortemFollowupSyncRequestBody;
}

export interface PostmortemsSettingsUpdateDefaultCopyPasteProviderRequest {
    updateDefaultCopyPasteProviderRequestBody: PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody;
}

export interface PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequest {
    updateDefaultTestOrTutorialDestinationRequestBody: PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequestBody;
}

export interface PostmortemsSettingsUpdateDefaultTimezoneRequest {
    updateDefaultTimezoneRequestBody: PostmortemsSettingsUpdateDefaultTimezoneRequestBody;
}

export interface PostmortemsSettingsUpdatePostmortemNameRequest {
    updatePostmortemNameRequestBody: PostmortemsSettingsUpdatePostmortemNameRequestBody;
}

export interface PostmortemsSettingsUpdateTemplateExpressionRequest {
    updateTemplateExpressionRequestBody: PostmortemsSettingsUpdateTemplateExpressionRequestBody;
}

/**
 * 
 */
export class PostmortemsSettingsApi extends runtime.BaseAPI {

    /**
     * Show the postmortem settings
     * Show PostmortemsSettings
     */
    async postmortemsSettingsShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSettingsShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSettingsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the postmortem settings
     * Show PostmortemsSettings
     */
    async postmortemsSettingsShow(initOverrides?: RequestInit): Promise<PostmortemsSettingsShowResponseBody> {
        const response = await this.postmortemsSettingsShowRaw(initOverrides);
        return await response.value();
    }

    /**
     * Toggle the setting for syncing followups from external postmortems
     * TogglePostmortemFollowupSync PostmortemsSettings
     */
    async postmortemsSettingsTogglePostmortemFollowupSyncRaw(requestParameters: PostmortemsSettingsTogglePostmortemFollowupSyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSettingsTogglePostmortemFollowupSyncResponseBody>> {
        if (requestParameters.togglePostmortemFollowupSyncRequestBody === null || requestParameters.togglePostmortemFollowupSyncRequestBody === undefined) {
            throw new runtime.RequiredError('togglePostmortemFollowupSyncRequestBody','Required parameter requestParameters.togglePostmortemFollowupSyncRequestBody was null or undefined when calling postmortemsSettingsTogglePostmortemFollowupSync.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/settings/toggle_postmortem_followup_sync`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSettingsTogglePostmortemFollowupSyncRequestBodyToJSON(requestParameters.togglePostmortemFollowupSyncRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSettingsTogglePostmortemFollowupSyncResponseBodyFromJSON(jsonValue));
    }

    /**
     * Toggle the setting for syncing followups from external postmortems
     * TogglePostmortemFollowupSync PostmortemsSettings
     */
    async postmortemsSettingsTogglePostmortemFollowupSync(requestParameters: PostmortemsSettingsTogglePostmortemFollowupSyncRequest, initOverrides?: RequestInit): Promise<PostmortemsSettingsTogglePostmortemFollowupSyncResponseBody> {
        const response = await this.postmortemsSettingsTogglePostmortemFollowupSyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the default copy-paste provider.
     * UpdateDefaultCopyPasteProvider PostmortemsSettings
     */
    async postmortemsSettingsUpdateDefaultCopyPasteProviderRaw(requestParameters: PostmortemsSettingsUpdateDefaultCopyPasteProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSettingsUpdateDefaultCopyPasteProviderResponseBody>> {
        if (requestParameters.updateDefaultCopyPasteProviderRequestBody === null || requestParameters.updateDefaultCopyPasteProviderRequestBody === undefined) {
            throw new runtime.RequiredError('updateDefaultCopyPasteProviderRequestBody','Required parameter requestParameters.updateDefaultCopyPasteProviderRequestBody was null or undefined when calling postmortemsSettingsUpdateDefaultCopyPasteProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/settings/default_document_provider_copy_paste`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyToJSON(requestParameters.updateDefaultCopyPasteProviderRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSettingsUpdateDefaultCopyPasteProviderResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the default copy-paste provider.
     * UpdateDefaultCopyPasteProvider PostmortemsSettings
     */
    async postmortemsSettingsUpdateDefaultCopyPasteProvider(requestParameters: PostmortemsSettingsUpdateDefaultCopyPasteProviderRequest, initOverrides?: RequestInit): Promise<PostmortemsSettingsUpdateDefaultCopyPasteProviderResponseBody> {
        const response = await this.postmortemsSettingsUpdateDefaultCopyPasteProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the default test or tutorial destination.
     * UpdateDefaultTestOrTutorialDestination PostmortemsSettings
     */
    async postmortemsSettingsUpdateDefaultTestOrTutorialDestinationRaw(requestParameters: PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationResponseBody>> {
        if (requestParameters.updateDefaultTestOrTutorialDestinationRequestBody === null || requestParameters.updateDefaultTestOrTutorialDestinationRequestBody === undefined) {
            throw new runtime.RequiredError('updateDefaultTestOrTutorialDestinationRequestBody','Required parameter requestParameters.updateDefaultTestOrTutorialDestinationRequestBody was null or undefined when calling postmortemsSettingsUpdateDefaultTestOrTutorialDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/settings/test_or_tutorial_destination_id`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequestBodyToJSON(requestParameters.updateDefaultTestOrTutorialDestinationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the default test or tutorial destination.
     * UpdateDefaultTestOrTutorialDestination PostmortemsSettings
     */
    async postmortemsSettingsUpdateDefaultTestOrTutorialDestination(requestParameters: PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationRequest, initOverrides?: RequestInit): Promise<PostmortemsSettingsUpdateDefaultTestOrTutorialDestinationResponseBody> {
        const response = await this.postmortemsSettingsUpdateDefaultTestOrTutorialDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update default timezone for postmortem exports.
     * UpdateDefaultTimezone PostmortemsSettings
     */
    async postmortemsSettingsUpdateDefaultTimezoneRaw(requestParameters: PostmortemsSettingsUpdateDefaultTimezoneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSettingsUpdateDefaultTimezoneResponseBody>> {
        if (requestParameters.updateDefaultTimezoneRequestBody === null || requestParameters.updateDefaultTimezoneRequestBody === undefined) {
            throw new runtime.RequiredError('updateDefaultTimezoneRequestBody','Required parameter requestParameters.updateDefaultTimezoneRequestBody was null or undefined when calling postmortemsSettingsUpdateDefaultTimezone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/settings/default_timezone`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSettingsUpdateDefaultTimezoneRequestBodyToJSON(requestParameters.updateDefaultTimezoneRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSettingsUpdateDefaultTimezoneResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update default timezone for postmortem exports.
     * UpdateDefaultTimezone PostmortemsSettings
     */
    async postmortemsSettingsUpdateDefaultTimezone(requestParameters: PostmortemsSettingsUpdateDefaultTimezoneRequest, initOverrides?: RequestInit): Promise<PostmortemsSettingsUpdateDefaultTimezoneResponseBody> {
        const response = await this.postmortemsSettingsUpdateDefaultTimezoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the name we use for post-mortems.
     * UpdatePostmortemName PostmortemsSettings
     */
    async postmortemsSettingsUpdatePostmortemNameRaw(requestParameters: PostmortemsSettingsUpdatePostmortemNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSettingsUpdatePostmortemNameResponseBody>> {
        if (requestParameters.updatePostmortemNameRequestBody === null || requestParameters.updatePostmortemNameRequestBody === undefined) {
            throw new runtime.RequiredError('updatePostmortemNameRequestBody','Required parameter requestParameters.updatePostmortemNameRequestBody was null or undefined when calling postmortemsSettingsUpdatePostmortemName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/settings/postmortem_rename`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSettingsUpdatePostmortemNameRequestBodyToJSON(requestParameters.updatePostmortemNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSettingsUpdatePostmortemNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the name we use for post-mortems.
     * UpdatePostmortemName PostmortemsSettings
     */
    async postmortemsSettingsUpdatePostmortemName(requestParameters: PostmortemsSettingsUpdatePostmortemNameRequest, initOverrides?: RequestInit): Promise<PostmortemsSettingsUpdatePostmortemNameResponseBody> {
        const response = await this.postmortemsSettingsUpdatePostmortemNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the default postmortem template expression
     * UpdateTemplateExpression PostmortemsSettings
     */
    async postmortemsSettingsUpdateTemplateExpressionRaw(requestParameters: PostmortemsSettingsUpdateTemplateExpressionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSettingsUpdateTemplateExpressionResponseBody>> {
        if (requestParameters.updateTemplateExpressionRequestBody === null || requestParameters.updateTemplateExpressionRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateExpressionRequestBody','Required parameter requestParameters.updateTemplateExpressionRequestBody was null or undefined when calling postmortemsSettingsUpdateTemplateExpression.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/settings/template_expression`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSettingsUpdateTemplateExpressionRequestBodyToJSON(requestParameters.updateTemplateExpressionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSettingsUpdateTemplateExpressionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the default postmortem template expression
     * UpdateTemplateExpression PostmortemsSettings
     */
    async postmortemsSettingsUpdateTemplateExpression(requestParameters: PostmortemsSettingsUpdateTemplateExpressionRequest, initOverrides?: RequestInit): Promise<PostmortemsSettingsUpdateTemplateExpressionResponseBody> {
        const response = await this.postmortemsSettingsUpdateTemplateExpressionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
