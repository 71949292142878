import { UserOption } from "@incident-io/api";
import { IconEnum } from "@incident-ui";
import {
  SelectOption,
  SelectOptionGroup,
  SelectOptions,
} from "@incident-ui/Select/types";
import { compact } from "lodash";
import {
  EscalationPathNode,
  EscalationPathTargetTypeEnum,
  TypeaheadsListTypeaheadTypeEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { UserOptionSection } from "../../legacy/on-call/common/UserOptionSection";
import { EscalationPathTargetFormData } from "./types";

// useHydratedTargets hydrates the users and schedules that are referenced in the
// existing escalation path.
export const useHydratedTargets = (path?: EscalationPathNode[]) => {
  const {
    data: { catalog_types: catalogTypes },
    isLoading: typesLoading,
  } = useAPI(
    "catalogListTypes",
    {},
    {
      fallbackData: { catalog_types: [] },
    },
  );
  const scheduleCatalogType = catalogTypes.find(
    (x) => x.type_name === "Schedule",
  );

  const levelNodes: EscalationPathNode[] = [];
  const notifyChannelNodes: EscalationPathNode[] = [];

  // Traverse the tree to find all level nodes
  const getNodes = (path: EscalationPathNode[]) => {
    path.forEach((node: EscalationPathNode) => {
      if (node.type === "level") {
        levelNodes.push(node);
      } else if (node.type === "notify_channel") {
        notifyChannelNodes.push(node);
      } else if (node.type === "if_else") {
        getNodes(node.if_else?.then_path || []);
        getNodes(node.if_else?.else_path || []);
      }
    });
  };

  getNodes(path || []);

  const userIds =
    (levelNodes?.flatMap(
      (node) =>
        node.level?.targets
          .filter(({ type }) => type === "user")
          .map(({ id }) => id)
          .filter((id) => id !== undefined),
    ) as string[]) || [];

  const scheduleIds =
    (levelNodes?.flatMap(
      (node) =>
        node.level?.targets
          .filter(({ type }) => type === "schedule")
          .map(({ id }) => id)
          .filter((id) => id !== undefined),
    ) as string[]) || [];

  const slackChannelIds =
    (notifyChannelNodes?.flatMap(
      (node) =>
        node.notify_channel?.targets
          .filter(({ type }) => type === "slack_channel")
          .map(({ id }) => id)
          .filter((id) => id !== undefined),
    ) as string[]) || [];

  const {
    data: usersData,
    isLoading: usersLoading,
    error: usersError,
    mutate: mutateUsers,
  } = useAPI(userIds.length > 0 ? "usersTypeahead" : null, {
    idList: userIds || [],
  });

  const {
    data: schedulesData,
    isLoading: schedulesLoading,
    error: schedulesError,
    mutate: mutateSchedules,
  } = useAPI(scheduleIds.length > 0 ? "catalogHydrateOptions" : null, {
    id: scheduleCatalogType?.id || "",
    idList: scheduleIds || [],
  });

  const {
    data: slackChannelsData,
    isLoading: slackChannelsLoading,
    error: slackChannelsError,
    mutate: mutateSlackChannels,
  } = useAPI(slackChannelIds.length > 0 ? "typeaheadsList" : null, {
    typeaheadType: TypeaheadsListTypeaheadTypeEnum.SlackChannel,
    idList: slackChannelIds || [],
  });

  const hydratedOptions = buildUserAndScheduleOptions(
    usersData?.options,
    schedulesData?.options,
  );

  return {
    targets: [
      ...hydratedOptions,
      ...buildChannelOptions(slackChannelsData?.options),
    ],
    targetsLoading:
      usersLoading || schedulesLoading || typesLoading || slackChannelsLoading,
    targetsError: usersError || schedulesError || slackChannelsError,
    revalidateTargets: async () => {
      await Promise.all([
        mutateUsers(),
        mutateSchedules(),
        mutateSlackChannels(),
      ]);
    },
  };
};

export const buildUserAndScheduleOptions = (
  userOptions: UserOption[] = [],
  scheduleOptions: SelectOptions = [],
): EscalationPathTargetFormData[] => {
  const patchedOptionsUsers = userOptions.map((option: UserOption) => {
    return {
      ...option,
      type: EscalationPathTargetTypeEnum.User,
    };
  });

  const patchedOptionsSchedules = scheduleOptions.map((optOrGroup) => {
    const option = optOrGroup as SelectOption;

    return {
      ...option,
      type: EscalationPathTargetTypeEnum.Schedule,
    };
  });

  return [
    ...patchedOptionsUsers,
    ...patchedOptionsSchedules,
  ] as EscalationPathTargetFormData[];
};

export const buildChannelOptions = (
  slackChannelOptions: SelectOptions = [],
): EscalationPathTargetFormData[] => {
  const patchedOptionsSlackChannels = slackChannelOptions.map((optOrGroup) => {
    const option = optOrGroup as SelectOption;

    return {
      ...option,
      type: EscalationPathTargetTypeEnum.SlackChannel,
    };
  });

  return patchedOptionsSlackChannels as EscalationPathTargetFormData[];
};

export const buildTargets = (
  userOptions: UserOption[] = [],
  scheduleOptions: SelectOptions = [],
): EscalationPathTargetFormData[] => {
  const patchedOptionsUsers = userOptions.map((option: UserOption) => {
    return {
      ...option,
      type: EscalationPathTargetTypeEnum.User,
    };
  });

  const patchedOptionsSchedules = scheduleOptions.map((optOrGroup) => {
    return {
      ...optOrGroup,
      type: EscalationPathTargetTypeEnum.Schedule,
    };
  });

  return [
    ...patchedOptionsUsers,
    ...patchedOptionsSchedules,
  ] as EscalationPathTargetFormData[];
};

export const groupOptions = (
  options: EscalationPathTargetFormData[],
): Array<SelectOptionGroup> => {
  return [
    {
      label: "Schedules",
      options: options
        .filter((opt) => opt.type === "schedule")
        .map((opt) => ({ ...opt, icon: IconEnum.Calendar })),
    },
    {
      label: "Users",
      options: compact(
        options.map((opt) =>
          opt.type === "user"
            ? {
                ...opt,
                renderFn: () => <UserOptionSection user={opt} />,
              }
            : null,
        ),
      ),
    },
  ];
};
