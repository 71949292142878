import { CatalogShowEntryResponseBody, CatalogType } from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/catalog/CatalogEntryBadge";
import {
  Button,
  ButtonTheme,
  IconEnum,
  Modal,
  ModalFooter,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { Mode } from "@incident-ui/Drawer/Drawer";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";
import _ from "lodash";
import pluralize from "pluralize";
import useSWRImmutable from "swr/immutable";

import {
  CatalogTypeColorEnum,
  CatalogTypeIconEnum,
  useClient,
} from "../../../contexts/ClientContext";
import { EscalationPathCatalogBindingData } from "../common/types";
import { isExternallyManagedManualCatalogType } from "./helpers";

export const EscalationPathExternalCatalogCalloutModal = ({
  mode,
  onClose,
  escalationPathId,
  escalationCatalogTypes,
  escalationPathCatalogBindings,
}: {
  mode: Mode;
  onClose: () => void;
  escalationPathId: string;
  escalationCatalogTypes: CatalogType[];
  escalationPathCatalogBindings: EscalationPathCatalogBindingData;
}) => {
  const apiClient = useClient();

  const externalEscalationCatalogTypeIds = new Set(
    escalationCatalogTypes
      ?.filter((type) => isExternallyManagedManualCatalogType(type))
      .map((type) => type.id),
  );

  // Get all catalog entry IDs for bindings which correspond to an external catalog type
  const catalogEntryIds = Object.entries(escalationPathCatalogBindings)
    .filter(([key]) => externalEscalationCatalogTypeIds.has(key))
    .flatMap(([, value]) => value);

  const { data: catalogEntriesData } = useSWRImmutable(
    `catalog-entries-${escalationPathId}-${catalogEntryIds.join("-")}`,
    async (): Promise<CatalogShowEntryResponseBody[]> => {
      const entryResponses = await Promise.all(
        (catalogEntryIds ?? []).map((id) => apiClient.catalogShowEntry({ id })),
      );

      return entryResponses || [];
    },
  );

  const groupedCatalogEntries = _.groupBy(
    catalogEntriesData,
    (entry) => entry.catalog_type.id,
  );

  const title = {
    create: "Escalation path created",
    edit: "Escalation path updated",
  };

  return (
    <Modal
      isOpen={true} // We manage open state above
      title={title[mode]}
      analyticsTrackingId={"escalation-path-external-catalog-type"}
      onClose={onClose}
      className={"min-w-[600px]"}
    >
      <div className={"p-6 flex flex-col gap-6"}>
        <div className={"text-content-secondary"}>
          You&rsquo;ve selected to link this escalation path to some catalog
          types you&rsquo;re managing externally. Please update them to map to
          this escalation path.
        </div>
        <NumberedList>
          <div>
            Copy your escalation path ID
            <SingleLineCodeBlock
              code={escalationPathId}
              className={"w-fit p-2 align-middle flex-none"}
            />
          </div>
          {Object.values(groupedCatalogEntries).map((entryResponse) => {
            const catalogType = entryResponse[0].catalog_type;
            const escalationPathAttribute = catalogType.schema.attributes.find(
              (attr) => attr.type === "EscalationPath",
            );
            const externalSource = getWebsiteFromSourceRepoURL(
              catalogType.source_repo_url || "",
            );
            return (
              <div key={catalogType.id} className={"align-middle"}>
                Add this ID to the{" "}
                {escalationPathAttribute
                  ? escalationPathAttribute.name
                  : "escalation path"}{" "}
                attribute of{" "}
                {entryResponse.length > 1
                  ? pluralize(catalogType.name)
                  : catalogType.name}{" "}
                {entryResponse.map((entry) => {
                  const { catalog_entry } = entry;
                  return (
                    <CatalogEntryBadge
                      key={catalog_entry.id}
                      icon={catalogType.icon as CatalogTypeIconEnum}
                      color={catalogType.color as CatalogTypeColorEnum}
                      label={catalog_entry.name}
                      className={"align-middle"}
                    />
                  );
                })}
                {externalSource && (
                  <span className={"align-middle"}>
                    {" "}
                    in <span className={"font-medium"}>{externalSource}</span>
                  </span>
                )}
                <Button
                  href={catalogType.source_repo_url}
                  analyticsTrackingId={
                    "escalation-path-external-catalog-type-source-repo"
                  }
                  icon={IconEnum.ExternalLink}
                  theme={ButtonTheme.Unstyled}
                  title={""}
                  className={"align-middle"}
                />
              </div>
            );
          })}
        </NumberedList>
      </div>
      <ModalFooter
        confirmButtonText={"Completed"}
        confirmButtonType="button"
        cancelButtonText={"Do it later"}
        onConfirm={onClose}
        onClose={onClose}
      />
    </Modal>
  );
};

const getWebsiteFromSourceRepoURL = (sourceRepoURL: string) => {
  const normalizedURL = sourceRepoURL.toLowerCase();
  if (normalizedURL.includes("github")) {
    return "GitHub";
  }
  if (normalizedURL.includes("gitlab")) {
    return "GitLab";
  }
  if (normalizedURL.includes("backstage")) {
    return "Backstage";
  }
  return "";
};
