import { NoPermissionMessage } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { EmptyState, IconEnum } from "@incident-ui";
import {
  AccordionStackedList,
  DeleteAction,
  OverflowAction,
} from "@incident-ui/StackedList/AccordionStackedList";
import _ from "lodash";
import React from "react";
import {
  CatalogType,
  CustomField,
  CustomFieldFieldTypeEnum,
  DependentResourcePayload,
  IncidentsBuildScopeContextEnum,
} from "src/contexts/ClientContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CustomFieldDetails } from "./CustomFieldDetails";
import { CustomFieldRow } from "./CustomFieldRow";

export const CustomFieldList = ({
  customFields,
  canEditSettings,
  catalogTypes,
}: {
  customFields: CustomField[];
  canEditSettings: boolean;
  catalogTypes: CatalogType[];
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const { trigger: deleteFieldTrigger, isMutating: isDeleting } =
    useAPIMutation("customFieldsList", undefined, (apiClient, data) =>
      apiClient.customFieldsDestroy({ id: data.id }),
    );

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.ApplicableFields,
  );

  const saving = isDeleting || scopeLoading;

  const overflowActions: OverflowAction<CustomField>[] = [
    {
      id: "edit",
      label: "Edit field",
      icon: IconEnum.Edit,
      onSelect: (id) => {
        navigate(`/settings/custom-fields/${id}/edit`);
      },
      tooltipContent: canEditSettings ? null : <>{NoPermissionMessage}</>,
      shouldHide: (_) => false,
    },
  ];

  const fetchDependentResources = (
    item: CustomField,
  ): DependentResourcePayload[] => {
    const dependentResources: DependentResourcePayload[] = [
      {
        resource_type: "CustomField",
        id: item.id,
      },
    ];

    if (
      [
        CustomFieldFieldTypeEnum.MultiSelect,
        CustomFieldFieldTypeEnum.SingleSelect,
      ].includes(item.field_type) &&
      !item.catalog_type_id
    ) {
      dependentResources.push({
        resource_type: `IncidentCustomFieldOption["${item.id}"]`,
      });
    }

    return dependentResources;
  };

  const deleteAction: DeleteAction<CustomField> = {
    id: "delete",
    label: "Delete field",
    icon: IconEnum.Delete2,
    onDelete: (id) => {
      deleteFieldTrigger({ id: id });
    },
    tooltipContent: () =>
      canEditSettings ? undefined : <>{NoPermissionMessage}</>,
    deleteModalTitle: (item) => `Delete custom field: ${item.name}`,
    isDeleting: (_) => saving,
    fetchDependentResources,
    renderDeleteModalContent: (item) => {
      return (
        <div>
          Are you sure you want to delete{" "}
          <span className="text-sm-bold">{item.name}</span>
          ?
          <br />
          <br />
          If the field is used by any other resources, such as in incidents,
          references to it will be automatically removed.
        </div>
      );
    },
  };

  if (scopeError) throw scopeError;

  if (customFields.length === 0) {
    return (
      <EmptyState
        icon={IconEnum.CustomField}
        content="You haven't added any custom fields yet."
      />
    );
  }

  return (
    <AccordionStackedList
      items={_.sortBy(customFields, (field) => field.rank)}
      overflowActions={canEditSettings ? overflowActions : []}
      deleteAction={deleteAction}
      renderEmptyState={() => (
        <EmptyState
          icon={IconEnum.CustomField}
          content="You don't have any custom fields yet"
        />
      )}
      renderRow={(item) => (
        <CustomFieldRow field={item} catalogTypes={catalogTypes} />
      )}
      renderAccordion={(item) => (
        <CustomFieldDetails field={item} scope={scope} />
      )}
      getRowClassName={() =>
        tcx({ "hover:bg-surface-secondary": canEditSettings })
      }
      rowOnClick={(item) =>
        canEditSettings
          ? navigate(`/settings/custom-fields/${item.id}/edit`)
          : void 0
      }
    />
  );
};
