/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallNotificationsResetMethodVerificationRequestBody
 */
export interface OnCallNotificationsResetMethodVerificationRequestBody {
    /**
     * How we verify the phone number, either sms or call
     * @type {string}
     * @memberof OnCallNotificationsResetMethodVerificationRequestBody
     */
    phone_verification_type: OnCallNotificationsResetMethodVerificationRequestBodyPhoneVerificationTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationsResetMethodVerificationRequestBodyPhoneVerificationTypeEnum {
    Call = 'call',
    Sms = 'sms'
}

export function OnCallNotificationsResetMethodVerificationRequestBodyFromJSON(json: any): OnCallNotificationsResetMethodVerificationRequestBody {
    return OnCallNotificationsResetMethodVerificationRequestBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsResetMethodVerificationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsResetMethodVerificationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone_verification_type': json['phone_verification_type'],
    };
}

export function OnCallNotificationsResetMethodVerificationRequestBodyToJSON(value?: OnCallNotificationsResetMethodVerificationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone_verification_type': value.phone_verification_type,
    };
}

