import { tcx } from "src/utils/tailwind-classes";

import { TrendTheme, TrendThemeLookup } from "./TrendTile";

export const LoadingTiles = ({
  numTiles: numTrends,
  smaller,
  overrideTheme,
}: {
  numTiles: number;
  smaller?: boolean;
  overrideTheme?: TrendTheme;
}) => {
  const colours = [TrendTheme.Neutral, TrendTheme.Bad, TrendTheme.Good];
  const minSize = smaller ? "min-h-24" : "min-h-28";
  return (
    <>
      {Array.from({ length: numTrends }, (_, i) => {
        const theme = overrideTheme || colours[i % colours.length];
        const styles = TrendThemeLookup[theme];

        return (
          <div
            key={i}
            className={tcx(
              `flex flex-col rounded-lg p-4 gap-1 animate-pulse`,
              styles.background,
              minSize,
            )}
          >
            <GradientBar theme={theme} />
            {!smaller && <GradientBar theme={theme} stopPoint="to-50%" />}
            <GradientBar theme={theme} taller stopPoint="to-80%" />
            <GradientBar theme={theme} stopPoint="to-70%" />
          </div>
        );
      })}
    </>
  );
};

export const GradientBar = ({
  theme,
  stopPoint,
  taller,
}: {
  theme: TrendTheme;
  stopPoint?: string;
  taller?: boolean;
}) => {
  const styles = TrendThemeLookup[theme];
  return (
    <div
      className={tcx(
        "w-full rounded-1 bg-gradient-to-r",
        styles.background,
        styles.gradient,
        stopPoint,
        taller ? "h-8" : "h-4",
      )}
    />
  );
};
