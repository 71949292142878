import type { SVGProps } from "react";
import * as React from "react";
const SvgPlayOverlay = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm2.157 8.621L9.17 12.643A.75.75 0 0 1 8 12.022V7.978a.75.75 0 0 1 1.17-.621l2.987 2.022a.75.75 0 0 1 0 1.242Z"
    />
  </svg>
);
export default SvgPlayOverlay;
