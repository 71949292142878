import type { SVGProps } from "react";
import * as React from "react";
const SvgInfo = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm.75 11.819a.75.75 0 0 1-1.5 0V9.25a.75.75 0 0 1 1.5 0v4.569ZM10 7.75a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2Z"
    />
  </svg>
);
export default SvgInfo;
