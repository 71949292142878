/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface DebriefInviteesPayload
 */
export interface DebriefInviteesPayload {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof DebriefInviteesPayload
     */
    emails?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof DebriefInviteesPayload
     */
    users?: EngineParamBindingPayload;
}

export function DebriefInviteesPayloadFromJSON(json: any): DebriefInviteesPayload {
    return DebriefInviteesPayloadFromJSONTyped(json, false);
}

export function DebriefInviteesPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefInviteesPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': !exists(json, 'emails') ? undefined : EngineParamBindingPayloadFromJSON(json['emails']),
        'users': !exists(json, 'users') ? undefined : EngineParamBindingPayloadFromJSON(json['users']),
    };
}

export function DebriefInviteesPayloadToJSON(value?: DebriefInviteesPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': EngineParamBindingPayloadToJSON(value.emails),
        'users': EngineParamBindingPayloadToJSON(value.users),
    };
}

