/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementPostFieldOptionParam,
    AnnouncementPostFieldOptionParamFromJSON,
    AnnouncementPostFieldOptionParamFromJSONTyped,
    AnnouncementPostFieldOptionParamToJSON,
} from './AnnouncementPostFieldOptionParam';

/**
 * 
 * @export
 * @interface AnnouncementPostsListFieldOptionsResponseBody
 */
export interface AnnouncementPostsListFieldOptionsResponseBody {
    /**
     * Fields that can be added to this announcement post
     * @type {Array<AnnouncementPostFieldOptionParam>}
     * @memberof AnnouncementPostsListFieldOptionsResponseBody
     */
    post_fields: Array<AnnouncementPostFieldOptionParam>;
}

export function AnnouncementPostsListFieldOptionsResponseBodyFromJSON(json: any): AnnouncementPostsListFieldOptionsResponseBody {
    return AnnouncementPostsListFieldOptionsResponseBodyFromJSONTyped(json, false);
}

export function AnnouncementPostsListFieldOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostsListFieldOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'post_fields': ((json['post_fields'] as Array<any>).map(AnnouncementPostFieldOptionParamFromJSON)),
    };
}

export function AnnouncementPostsListFieldOptionsResponseBodyToJSON(value?: AnnouncementPostsListFieldOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'post_fields': ((value.post_fields as Array<any>).map(AnnouncementPostFieldOptionParamToJSON)),
    };
}

