import type { SVGProps } from "react";
import * as React from "react";
const SvgDropbox = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#007EE5"
      d="M6.717 2.5 2 5.557l3.283 2.641L10 5.255 6.717 2.5ZM2 10.764l4.717 3.095L10 11.104 5.283 8.198 2 10.764Zm8 .34 3.32 2.755L18 10.801l-3.245-2.604L10 11.104Zm8-5.547L13.32 2.5 10 5.255l4.755 2.943L18 5.557Zm-7.962 6.15-3.321 2.755-1.396-.943v1.056l4.717 2.83 4.717-2.83V13.52l-1.434.943-3.283-2.755Z"
    />
  </svg>
);
export default SvgDropbox;
