import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
} from "@incident-ui";
import { useCommandPaletteContext } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { Identity, Session } from "src/contexts/ClientContext";
import {
  permissionsCanImpersonate,
  sessionCanSeeStaffRoom,
} from "src/utils/sessions";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { useShowDebugID } from "../../../../utils/ShowDebugIDProvider";

export const SidebarDropdownMenu = ({
  identity,
  logoutAll,
  renderTriggerButton,
  inSetupFlow = false,
  offset,
}: {
  identity: Identity;
  logoutAll: () => void;
  inSetupFlow?: boolean;
  renderTriggerButton: () => React.ReactNode;
  offset: number;
}) => {
  const { sessions } = useIdentity();
  const orgAwareNavigate = useOrgAwareNavigate();
  const navigate = useNavigate();
  const { openImpersonateModal } = useCommandPaletteContext();
  const { show } = useIntercom();

  const { showDebugID, setShowDebugID } = useShowDebugID();

  const isLoggedInToStaff = sessions.some(
    (session) => session.organisation_is_staff,
  );

  // Show the impersonate link if:
  const canImpersonate =
    // 1. You're looking at the staff org right now
    !!identity.organisation_is_staff &&
    // 2. It's not also a demo org (for paranoia only)
    !identity.organisation_is_demo &&
    // 3. You have the impersonate permission
    permissionsCanImpersonate(identity.staff_permissions || []);

  // Show the staff room link if:
  const staffRoomSession = sessions.find(sessionCanSeeStaffRoom);
  let staffRoomPath = `/${staffRoomSession?.organisation_slug}/staff-room`;
  if (!identity.organisation_is_staff) {
    // If you're looking at a different org, take you direct to the controls for
    // that org.
    staffRoomPath += `/${identity.organisation_slug}`;
  }

  return (
    <>
      <DropdownMenu
        sideOffset={offset}
        menuClassName="w-56"
        triggerButton={renderTriggerButton()}
      >
        {sessions.length > 0 && (
          <DropdownMenuGroup>
            {sessions
              .sort((a, b) => {
                return a.organisation_name.localeCompare(b.organisation_name);
              })
              .map((session) => {
                const isCurrentOrg =
                  session.organisation_id === identity.organisation_id;
                return (
                  <DropdownMenuItem
                    analyticsTrackingId={"sidebar-switch-org"}
                    onSelect={() => {
                      if (isCurrentOrg) return;
                      navigate(`/${session.organisation_slug}/dashboard`);
                    }}
                    key={session.organisation_id}
                    label={session.organisation_name}
                    prefix={<SessionAvatar session={session} />}
                  >
                    <div className="flex justify-between w-full items-center">
                      <span className="truncate max-w-[10rem]">
                        {session.organisation_name}
                      </span>
                      {isCurrentOrg && (
                        <Icon
                          className={
                            "!w-4 !h-4 !ml-2 text-content-tertiary rounded-full"
                          }
                          id={IconEnum.Tick}
                        />
                      )}
                    </div>
                  </DropdownMenuItem>
                );
              })}
            <DropdownMenuItem
              icon={IconEnum.Add}
              analyticsTrackingId={null}
              onSelect={() => navigate(`/login/additional-organisation`)}
              label="Add organization"
              key="add-org"
            />
          </DropdownMenuGroup>
        )}
        <DropdownMenuGroup label={identity.user_email}>
          <DropdownMenuItem
            icon={IconEnum.Book}
            analyticsTrackingId={"sidebar-help-center"}
            onSelect={() => show()}
            label="Help center"
          />

          <DropdownMenuItem
            icon={IconEnum.Email}
            analyticsTrackingId={"sidebar-contact-support"}
            onSelect={() => {
              window.open("mailto:support@incident.io", "_blank");
            }}
            label="Contact support"
          />

          {identity.organisation_is_demo && (
            <DropdownMenuItem
              icon={IconEnum.Sparkles}
              analyticsTrackingId={null}
              onSelect={() => orgAwareNavigate("/demo-magic")}
              label="Demo magic"
            />
          )}

          {!inSetupFlow && (
            <DropdownMenuItem
              icon={IconEnum.User}
              analyticsTrackingId={null}
              onSelect={() => orgAwareNavigate("/user-preferences")}
              label="Preferences"
            />
          )}

          {
            // If you're currently in a demo environment, we hide all the
            // impersonate/staff room/debugging stuff.
            !identity.organisation_is_demo && (
              <>
                {canImpersonate && (
                  <DropdownMenuItem
                    icon={IconEnum.Warning}
                    analyticsTrackingId={null}
                    onSelect={openImpersonateModal}
                    label="Impersonate"
                  />
                )}

                {staffRoomSession && (
                  <DropdownMenuItem
                    icon={IconEnum.Keyboard}
                    analyticsTrackingId={null}
                    onSelect={() => navigate(staffRoomPath)}
                    label="Staff room"
                  />
                )}
                {isLoggedInToStaff && (
                  <>
                    <DropdownMenuItem
                      icon={IconEnum.Copy}
                      analyticsTrackingId={null}
                      label="Copy user ID"
                      onSelect={() =>
                        navigator.clipboard.writeText(identity.user_id)
                      }
                    />
                    <DropdownMenuItem
                      icon={IconEnum.Copy}
                      analyticsTrackingId={null}
                      label="Copy org ID"
                      onSelect={() =>
                        navigator.clipboard.writeText(identity.organisation_id)
                      }
                    />
                    <DropdownMenuItem
                      icon={IconEnum.Bug}
                      onSelect={() => {
                        setShowDebugID(!showDebugID);
                      }}
                      analyticsTrackingId={"show-debug-id"}
                      label={showDebugID ? "Hide debug IDs" : "Show debug IDs"}
                    />
                  </>
                )}
              </>
            )
          }
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuItem
            icon={IconEnum.Exit}
            analyticsTrackingId={null}
            onSelect={() => logoutAll()}
            label="Sign out"
          />
        </DropdownMenuGroup>
      </DropdownMenu>
    </>
  );
};

const SessionAvatar = ({ session }: { session: Session }) => {
  // The active session just gets a green tick
  if (session.organisation_icon_url) {
    // If we have an avatar for this org, display that
    return (
      <SlackTeamAvatar
        size={IconSize.Small}
        name={session.organisation_name}
        url={session.organisation_icon_url}
      />
    );
  } else {
    // Fall back to generic icon
    return <Icon id={IconEnum.Briefcase} size={IconSize.Small} />;
  }
};
