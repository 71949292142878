import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { compact } from "lodash";
import { useProductAccess } from "src/hooks/useProductAccess";

export type SidebarItem = {
  slug: string;
  label: string;
  icon: IconEnum;
  isNew?: boolean;
  fadedOut?: boolean;
  requiredProduct?: Product;
};

export const useSidebarItems = (): SidebarItem[] => {
  const { hasProduct } = useProductAccess();

  const items = compact([
    {
      slug: "dashboard",
      icon: IconEnum.Home,
      label: "Home",
    },
    {
      slug: "incidents",
      icon: IconEnum.Incident,
      label: "Incidents",
    },
    {
      slug: "alerts",
      icon: IconEnum.Alert,
      label: "Alerts",
    },
    {
      slug: "on-call",
      icon: IconEnum.OnCall,
      label: "On-call",
    },
    {
      slug: "status-pages",
      icon: IconEnum.StatusPage,
      label: "Status pages",
    },
    {
      slug: "post-incident",
      icon: IconEnum.Checklist,
      label: "Post-incident",
      requiredProduct: Product.Response,
    },
    {
      slug: "insights",
      icon: IconEnum.Chart,
      label: "Insights",
    },
    {
      slug: "catalog",
      icon: IconEnum.Book,
      label: "Catalog",
    },
    {
      slug: "workflows",
      icon: IconEnum.Workflows,
      label: "Workflows",
      requiredProduct: Product.Response,
    },
    {
      slug: "settings",
      icon: IconEnum.Cog,
      label: "Settings",
    },
  ]);

  return items.slice().sort((a, b) => {
    const hasProductA = a.requiredProduct
      ? hasProduct(a.requiredProduct)
      : true;
    const hasProductB = b.requiredProduct
      ? hasProduct(b.requiredProduct)
      : true;

    if (a.slug === "settings") {
      return 1;
    } else if (b.slug === "settings") {
      return -1;
    } else if (hasProductA && !hasProductB) {
      return -1; // a should come before b
    } else if (!hasProductA && hasProductB) {
      return 1; // b should come before a
    } else {
      return 0; // Maintain original order
    }
  });
};
