import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ErrorMessage } from "@incident-ui";
import { ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import pluralize from "pluralize";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import {
  ConditionsApplyOverDelayEnum,
  convertDelayToSeconds,
  DelayConfig,
  delayUnitOptions,
} from "./WorkflowDelaySection";

const SECONDS_IN_A_YEAR = 31536000;

export type WorkflowDelayModalProps = {
  initialValue: DelayConfig;
  onClose: () => void;
  onSubmit: (newVal: DelayConfig) => void;
};

// WorkflowDelayModal renders a modal to allow users to edit the delay
// config on a workflow.
export const WorkflowDelayModal = ({
  initialValue,
  onClose,
  onSubmit,
}: WorkflowDelayModalProps): React.ReactElement => {
  const formMethods = useForm<DelayConfig>({
    defaultValues: initialValue,
  });
  const [delay, delayUnit, conditionsApplyOverDelay] = formMethods.watch([
    "delay",
    "delayUnit",
    "conditionsApplyOverDelay",
  ]);
  const delayInSeconds = convertDelayToSeconds({ delay, delayUnit });

  const disableSave = !delay || delayInSeconds > SECONDS_IN_A_YEAR;

  const onConfirm = () => {
    onSubmit({ delay, delayUnit, conditionsApplyOverDelay });
  };

  const formattedDelay = delay
    ? `${delay} ${pluralize(delayUnit as string, parseInt(`${delay}`))}`
    : "the delay";

  const conditionsApplyOverDelayOptions = [
    {
      label: "Yes",
      value: ConditionsApplyOverDelayEnum.Yes,
      description: `The workflow will only run if the conditions match for ${formattedDelay}`,
    },
    {
      label: "No",
      value: ConditionsApplyOverDelayEnum.No,
      description: `The workflow will run after ${formattedDelay}, even if the conditions no longer match`,
    },
  ];

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onConfirm}
      title="When should this workflow run?"
      analyticsTrackingId="workflows-v2-delay-modal"
      onClose={onClose}
      footer={
        <ModalFooter
          confirmButtonText="Confirm"
          confirmButtonType="button"
          onConfirm={onConfirm}
          onClose={onClose}
          disabled={disableSave}
        />
      }
    >
      <RadioButtonGroupV2
        options={conditionsApplyOverDelayOptions}
        name="conditionsApplyOverDelay"
        srLabel="Should the workflow be cancelled if the conditions stop matching?"
        label="Should the workflow be cancelled if the conditions stop matching?"
        formMethods={formMethods}
        boxed
      />
      <div className="space-y-1">
        <span className="font-medium text-content-primary text-sm">
          How long after the trigger fires should the workflow be run?
        </span>
        <div className="flex flex-row items-center gap-2">
          <InputV2
            formMethods={formMethods}
            name="delay"
            type={InputType.Number}
          />
          <StaticSingleSelectV2
            options={delayUnitOptions}
            name="delayUnit"
            formMethods={formMethods}
          />
        </div>
        {delayInSeconds > SECONDS_IN_A_YEAR && (
          <ErrorMessage message="The maximum delay is 1 year. Please enter a smaller value." />
        )}
      </div>
    </Form.Modal>
  );
};
