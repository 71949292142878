/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementPostFieldParam,
    AnnouncementPostFieldParamFromJSON,
    AnnouncementPostFieldParamFromJSONTyped,
    AnnouncementPostFieldParamToJSON,
} from './AnnouncementPostFieldParam';

/**
 * 
 * @export
 * @interface AnnouncementPostsCreateFieldResponseBody
 */
export interface AnnouncementPostsCreateFieldResponseBody {
    /**
     * 
     * @type {AnnouncementPostFieldParam}
     * @memberof AnnouncementPostsCreateFieldResponseBody
     */
    field: AnnouncementPostFieldParam;
}

export function AnnouncementPostsCreateFieldResponseBodyFromJSON(json: any): AnnouncementPostsCreateFieldResponseBody {
    return AnnouncementPostsCreateFieldResponseBodyFromJSONTyped(json, false);
}

export function AnnouncementPostsCreateFieldResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostsCreateFieldResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': AnnouncementPostFieldParamFromJSON(json['field']),
    };
}

export function AnnouncementPostsCreateFieldResponseBodyToJSON(value?: AnnouncementPostsCreateFieldResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': AnnouncementPostFieldParamToJSON(value.field),
    };
}

