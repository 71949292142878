import {
  DateRangeRelativeStateIntervalEnum as Interval,
  InsightsCreateDashboardRequestBodyColorEnum,
  InsightsCreateDashboardRequestBodyIconEnum,
  InsightsDateRangeAggregationEnum,
  InsightsDateRangeModeEnum,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

import { DashboardColorIconPicker } from "./DashboardColorIconPicker";

export type DashboardDetailsFormData = {
  name: string;
  icon: string;
  color: string;
};
export const CreateCustomDashboardModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<DashboardDetailsFormData>();
  const navigate = useOrgAwareNavigate();

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "insightsListCustomDashboards",
    undefined,
    async (apiClient, data: DashboardDetailsFormData) => {
      const { dashboard } = await apiClient.insightsCreateDashboard({
        createDashboardRequestBody: {
          name: data.name,
          icon: data.icon as unknown as InsightsCreateDashboardRequestBodyIconEnum,
          color:
            data.color as unknown as InsightsCreateDashboardRequestBodyColorEnum,
          date_range: {
            mode: InsightsDateRangeModeEnum.Relative,
            relative: {
              number_of_intervals: 3,
              interval: Interval.Months,
              include_this_interval: true,
            },
            aggregation: InsightsDateRangeAggregationEnum.Weeks,
            compare_previous_period: false,
          },
          panels: [],
          filters: [],
        },
      });

      navigate(`/insights/dashboards/custom/${dashboard.id}/edit`);
    },
    {
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal<DashboardDetailsFormData>
      analyticsTrackingId="create-custom-dashboard"
      formMethods={formMethods}
      title="Create dashboard"
      onClose={onClose}
      genericError={genericError}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          confirmButtonText="Create"
          onClose={onClose}
          saving={saving}
        />
      }
    >
      <InputV2
        name="name"
        formMethods={formMethods}
        label="Name"
        placeholder="E.g. Executive summary"
        required
      />
      <Form.InputWrapper name="icon" label="Choose an icon">
        <DashboardColorIconPicker />
      </Form.InputWrapper>
    </Form.Modal>
  );
};
