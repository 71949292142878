import { MenuEntry } from "./ReferenceSelectorPopover/ReferenceSelectorPopover";

export type ReferenceSource = "reference" | "expression" | "loop";

export type ReferencesBySource = {
  references: MenuEntry[];
  expressions: MenuEntry[];
  loopVariables: MenuEntry[];
};

export const splitReferencesBySource = (
  entries: MenuEntry[],
): ReferencesBySource => {
  const bySource: ReferencesBySource = {
    references: [],
    expressions: [],
    loopVariables: [],
  };
  for (const entry of entries) {
    switch (referenceSource(entry.key)) {
      case "reference":
        bySource.references.push(entry);
        break;
      case "expression":
        bySource.expressions.push(entry);
        break;
      case "loop":
        bySource.loopVariables.push(entry);
        break;
    }
  }

  return bySource;
};

export const referenceSource = (referenceKey: string): ReferenceSource => {
  if (isLoopVar(referenceKey ?? "")) {
    return "loop";
  } else if (isExpression(referenceKey ?? "")) {
    return "expression";
  } else {
    return "reference";
  }
};

const expressionReferenceRegex = /expressions\["[a-zA-Z0-9-]+"]$/;

export const isExpression = (ref?: string): boolean => {
  return !!ref && !!expressionReferenceRegex.exec(ref);
};

export const isLoopVar = (ref?: string): boolean => {
  if (!ref) {
    return false;
  }
  return ref === "loop_variable";
};
