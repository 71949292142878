import { SelectOption, SelectOptionOrGroup } from "@incident-ui/Select/types";
import { captureException } from "@sentry/react";
import { sortBy, trimStart } from "lodash";
import {
  ClientType,
  TypeaheadsListResponseBody,
  TypeaheadsListTypeaheadTypeEnum,
} from "src/contexts/ClientContext";

export type TypeaheadTypeEnum = TypeaheadsListTypeaheadTypeEnum;
export const TypeaheadTypeEnum = TypeaheadsListTypeaheadTypeEnum;

export const fetchTypeaheadOptions = async (
  apiClient: ClientType,
  typeaheadType: TypeaheadTypeEnum,
  query: string,
  incidentId?: string,
  forInsights?: boolean,
): Promise<TypeaheadsListResponseBody | void> => {
  try {
    return await apiClient.typeaheadsList({
      typeaheadType: typeaheadType,
      query: query,
      incidentId: incidentId,
      forInsights: forInsights,
    });
  } catch (error) {
    captureException(error);
    console.error(error);
  }
  return undefined;
};

export const getTypeaheadOptions = (
  apiClient: ClientType,
  typeaheadType: TypeaheadTypeEnum,
  options: {
    incidentId?: string;
    sortKey?: string;
    forInsights?: boolean;
  } = {},
) => {
  return async function (inputValue: string): Promise<SelectOptionOrGroup[]> {
    try {
      const typeaheadOptions = await fetchTypeaheadOptions(
        apiClient,
        typeaheadType,
        inputValue,
        options.incidentId,
        options.forInsights,
      );

      if (!typeaheadOptions) return [];

      if (typeaheadOptions.options?.length > 0) {
        return sortBy(
          typeaheadOptions.options,
          options.sortKey ?? "sort_key",
        ).sort(
          (a) =>
            trimStart(a.label, "#") === trimStart(inputValue, "#") ? -1 : 0, // ensure exact matches always get to the top
        );
      }

      if (typeaheadOptions.option_groups?.length > 0) {
        return typeaheadOptions.option_groups;
      }

      return [];
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
};

export const hydrateInitialSelectOptions = (
  apiClient: ClientType,
  typeaheadType: TypeaheadTypeEnum,
  forInsights?: boolean,
) => {
  return async (initialValue: string | string[]): Promise<SelectOption[]> => {
    try {
      const { options } = await apiClient.typeaheadsList({
        typeaheadType: typeaheadType,
        idList: Array.isArray(initialValue) ? initialValue : [initialValue],
        forInsights,
      });
      if (options?.length > 0) {
        return options;
      }
      return [];
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
};
