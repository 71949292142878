import { AlertSchema } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Checkbox,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
} from "@incident-ui";
import _ from "lodash";

import { AlertTableColumn, makeColumns } from "./AlertsTable";

export const CustomiseShownAttributesDropdown = ({
  schema,
  visibleColumns: visibleColumnsOptional,
  setVisibleColumns,
}: {
  schema: AlertSchema;
  visibleColumns: AlertTableColumn[] | null;
  setVisibleColumns: (shownAttributes: AlertTableColumn[]) => void;
}) => {
  const allOptions = makeColumns(schema);

  // We don't want to default to all alert attributes, only the attributes that
  // we think are likely to look sensible/be set by most sources.
  const visibleColumns =
    visibleColumnsOptional ??
    allOptions.filter((col) => {
      // Show the alert source column by default always
      if (col.type === "alert_source") {
        return true;
      }

      // Show the priority column by default always
      if (col.type === "priority") {
        return true;
      }

      // Otherwise show only the catalog powered alert attributes
      if (col.attribute?.type.startsWith("CatalogEntry")) {
        return true;
      }

      return false;
    });

  // Otherwise we don't trigger a render in the parent, causing the filtering to
  // go unapplied.
  setVisibleColumns(visibleColumns);

  const onClickField = (toggledColumn: AlertTableColumn) => {
    // Always use the ordering from allOptions, rather than refiltering visibleColumns
    // as otherwise the ordering gets messed up
    const isToggledColCurrentlyShown = _.some(visibleColumns, toggledColumn);
    setVisibleColumns(
      allOptions.filter((currentColumn) =>
        _.isEqual(currentColumn, toggledColumn)
          ? !isToggledColCurrentlyShown
          : _.some(visibleColumns, currentColumn),
      ),
    );
  };

  const columnName = (column: AlertTableColumn) => {
    if (column.type === "alert_source") {
      return "Alert source";
    } else if (column.type === "priority") {
      return "Priority";
    }
    return column.attribute?.name ?? "";
  };

  return (
    <DropdownMenu
      menuClassName={"!overflow-auto"}
      triggerButton={
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={"alerts-list-customise-info"}
          icon={IconEnum.Sliders}
          iconProps={{
            size: IconSize.Medium,
          }}
        >
          Customise view
        </Button>
      }
      scroll
    >
      {allOptions.map((column) => {
        const key = `${column.type}-${column.attribute?.id}`;
        const name = columnName(column);
        return (
          <DropdownMenuItem
            key={key}
            onSelect={(e) => {
              onClickField(column);
              e.preventDefault();
            }}
            className="group/check"
            analyticsTrackingId={null}
            label={name}
          >
            <Checkbox
              id={key}
              onChange={() => {
                onClickField(column);
              }}
              label={name}
              checked={_.some(visibleColumns, column)}
              className="!cursor-pointer"
            />
          </DropdownMenuItem>
        );
      })}
    </DropdownMenu>
  );
};
