import { Button, ButtonTheme, IconSize } from "@incident-ui";
import { IconEnum, Tooltip } from "@incident-ui";
import React, { ForwardedRef } from "react";
import { tcx } from "src/utils/tailwind-classes";

export const LightningButton = React.forwardRef(function LightningButton(
  {
    onClick,
    disabled,
    className,
    lightTooltip,
  }: {
    onClick: () => void;
    disabled?: boolean;
    className?: string;
    lightTooltip?: boolean;
  },
  ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
) {
  return (
    <Tooltip content="Use a variable" delayDuration={350} light={lightTooltip}>
      <Button
        className={tcx(
          "ml-auto text-slate-400 hover:text-content-primary shrink-0 !p-0 !w-7 !h-7 flex-center",
          className,
        )}
        iconProps={{
          size: IconSize.Medium,
        }}
        ref={ref}
        analyticsTrackingId={null}
        icon={IconEnum.Bolt}
        onClick={onClick}
        disabled={disabled}
        title="Use a variable"
        theme={ButtonTheme.UnstyledPill}
      />
    </Tooltip>
  );
});
