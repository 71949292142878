import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Loader,
} from "@incident-ui";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Path, useFormContext } from "react-hook-form";
import {
  IntegrationsJiraServerUpdateAutoExportSettingsRequestBody as FormData,
  IssueTrackersJiraServerTypeaheadOptionsFieldEnum as JiraFieldEnum,
  JiraIssueField as JiraIssueField,
  ScopeNameEnum as ScopeEnum,
  Severity as Severity,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import {
  JIRA_SERVER_CONFIG,
  useGetJiraOptions,
} from "../../../../@shared/integrations/jira/jiraConfig";
import { JiraIssueFieldInput, JiraSelect } from "../jira/JiraHelpers";

enum Step {
  // Step1 is when you select the project
  Step1 = "step_1",
  // Step2 is when you fill in the issue type
  Step2 = "step_2",
  // Step3 is when you fill in everything else
  Step3 = "step_3",
}

// This shares a lot of code with ActionExportToJiraModal. The main differences are:
// 1. Helptext & context is different
// 2. We hit a different endpoint (the settings endpoint, not the issue tracker create endpoint)
// 3. We hide summary and description, as we auto populate these from the incident
// 4. We add an extra 'minimum severity' dropdown
// 5. We show optional jira fields (not just the required ones)

export const ConfigureJiraServerAutoExport = ({
  severities,
}: {
  severities: Severity[];
}): React.ReactElement | null => {
  const formMethods = useFormContext<FormData>();

  const {
    watch,
    clearErrors,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = formMethods;

  const [step, setStep] = useState<Step>(Step.Step1);

  const [projectId, issueTypeId] = [
    watch("auto_export_config.project_id"),
    watch("auto_export_config.issue_type_id"),
  ];

  const { data: unsortedIssueFields, isLoading: issueFieldsLoading } = useAPI(
    isEmpty(projectId) || isEmpty(issueTypeId)
      ? null
      : "issueTrackersJiraServerGetCreateIssueFields",
    { projectId, issueTypeId },
    {
      onError: async (jsonErr) => {
        // If we get a validation error, apply that to the form
        jsonErr.errors.forEach((e) => {
          if (e?.source?.pointer) {
            const pointer =
              `auto_export_config.${e.source.pointer}` as unknown as Path<FormData>;
            if (!_.get(errors, pointer)) {
              setError(pointer, {
                type: "manual",
                message: e.message,
              });
            }
          } else {
            console.error(e);
            throw e;
          }
        });
      },
    },
  );

  const issueFields = _.sortBy(unsortedIssueFields?.issue_fields || [], "type");

  useEffect(() => {
    // Push us to the next step if needed
    if (projectId) {
      // clear errors so that we don't hold onto old errors that don't mean much any more
      clearErrors();
      setStep(issueTypeId ? Step.Step3 : Step.Step2);
    }
  }, [projectId, issueTypeId, clearErrors]);

  const clearDynamicFields = () => {
    // here, we want to clear everything in our form that isn't project or issue type ID.
    const currentValues = getValues();

    for (const key in currentValues.auto_export_config.dynamic_fields) {
      setValue(
        `auto_export_config.dynamic_fields.${key}` as keyof FormData,
        // @ts-expect-error I don't know why react-hook-form is sad about this
        null,
      );
    }
  };

  const onChangeProject = () => {
    setValue<"auto_export_config.issue_type_id">(
      "auto_export_config.issue_type_id",
      // @ts-expect-error I don't know why react-hook-form is sad about this
      null,
    );
    setValue<"auto_export_config.project_id">(
      "auto_export_config.project_id",
      // @ts-expect-error I don't know why react-hook-form is sad about this
      null,
    );
    clearDynamicFields();
    setStep(Step.Step1);
    clearErrors();
  };

  const onChangeIssueType = () => {
    setValue<"auto_export_config.issue_type_id">(
      "auto_export_config.issue_type_id",
      // @ts-expect-error I don't know why react-hook-form is sad about this
      null,
    );
    clearDynamicFields();
    setStep(Step.Step2);
    clearErrors();
  };

  return (
    <div className="space-y-4 mt-4">
      <>
        <FormSelectIncludePrivateIncidents />
        <FormSelectMinSeverity severities={severities} />
        <hr className="my-3" />
      </>
      <div className="text-sm font-medium">
        What properties should we create a ticket with?
      </div>
      <FormSelectProject step={step} onChangeProject={onChangeProject} />
      <FormSelectIssueType
        projectId={projectId}
        step={step}
        onChangeIssueType={onChangeIssueType}
      />
      <FormEverythingElse
        projectId={projectId}
        step={step}
        issueTypeId={issueTypeId}
        issueFields={issueFields || undefined}
        loading={issueFieldsLoading}
      />
    </div>
  );
};

const FormSelectProject = ({
  step,
  onChangeProject,
}: {
  step: Step;
  onChangeProject: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);
  const getJiraOptions = useGetJiraOptions(JIRA_SERVER_CONFIG);

  return (
    <>
      <div className="mb-4">
        <JiraSelect
          label="Jira Project"
          disabled={!canEditSettings || step !== Step.Step1}
          fieldKey={"auto_export_config.project_id"}
          getJiraOptions={() =>
            getJiraOptions({
              field: JiraFieldEnum.Project,
              siteId: "", // ?? why is this required?!
            })
          }
          dependencies={[]}
          required
          accessory={
            step === Step.Step3 &&
            canEditSettings && (
              <Button
                onClick={onChangeProject}
                analyticsTrackingId="jira-change-project"
                theme={ButtonTheme.Naked}
                className="float-right text-xs !font-medium"
              >
                Change Project
              </Button>
            )
          }
        />
      </div>
      <div className="my-6" />
    </>
  );
};

const FormSelectIssueType = ({
  projectId,
  step,
  onChangeIssueType,
}: {
  projectId: string;
  step: Step;
  onChangeIssueType: () => void;
}): React.ReactElement | null => {
  const getJiraOptions = useGetJiraOptions(JIRA_SERVER_CONFIG);

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);

  if (step === Step.Step1) {
    return null;
  }

  return (
    <>
      <div className="mb-2">
        <JiraSelect
          label="Issue Type"
          accessory={
            step === Step.Step3 &&
            canEditSettings && (
              <Button
                onClick={onChangeIssueType}
                analyticsTrackingId="jira-change-issue-type"
                theme={ButtonTheme.Naked}
                className="float-right text-xs !font-medium"
              >
                Change issue type
              </Button>
            )
          }
          disabled={!canEditSettings || step === Step.Step3}
          required
          fieldKey={"auto_export_config.issue_type_id"}
          getJiraOptions={() =>
            getJiraOptions({
              field: JiraFieldEnum.Issuetype,
              siteId: "", // ?? why is this required?!
              projectId: projectId,
            })
          }
          dependencies={[projectId]}
        />
      </div>
    </>
  );
};

const FormEverythingElse = ({
  projectId,
  issueTypeId,
  issueFields,
  step,
  loading,
}: {
  step: Step;
  projectId: string;
  issueTypeId: string;
  issueFields?: JiraIssueField[];
  loading: boolean;
}): React.ReactElement | null => {
  const { getValues } = useFormContext<FormData>();
  const getJiraOptions = useGetJiraOptions(JIRA_SERVER_CONFIG);

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);

  // before they've moved to our part of the form, we shouldn't show these extra fields
  if (step !== Step.Step3) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  if (!issueFields) {
    return null;
  }

  // TODO: what if there are no issueFields?
  const fields = issueFields.map((field) => {
    const fieldKey = `auto_export_config.dynamic_fields.${field.key}`;
    return (
      <JiraIssueFieldInput
        key={fieldKey}
        field={field}
        fieldKey={fieldKey}
        siteId={""} // hack, but fine
        projectId={projectId}
        issueTypeId={issueTypeId}
        loadOptions={({ field, projectId, issueTypeId, query }) => {
          return getJiraOptions({
            field: field as unknown as JiraFieldEnum,
            siteId: "", // ?? why is this required?!
            projectId,
            issueTypeId,
            query,
          });
        }}
        disabled={!canEditSettings}
        initialValue={_.get(getValues(), fieldKey)}
        loading={false}
      />
    );
  });

  return <>{fields}</>;
};

const FormSelectMinSeverity = ({
  severities,
}: {
  severities: Severity[];
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);

  const formMethods = useFormContext<FormData>();
  const selectOptions = _.sortBy(severities, "rank").map((sev) => ({
    value: sev.id,
    label: sev.name,
    sort_key: sev.rank.toString(),
  }));

  return (
    <StaticSingleSelectV2
      label="Minimum Severity"
      formMethods={formMethods}
      name="auto_export_config.min_severity_id"
      required
      isClearable={false}
      options={selectOptions}
      placeholder="Select..."
      disabled={!canEditSettings}
      helptext="We'll only create tickets in Jira when they reach your chosen minimum severity."
    />
  );
};

const FormSelectIncludePrivateIncidents = (): React.ReactElement => {
  const formMethods = useFormContext<FormData>();
  const includePrivateIncidents = formMethods.watch(
    "auto_export_config.include_private_incidents",
  );

  return (
    <ToggleV2
      formMethods={formMethods}
      name="auto_export_config.include_private_incidents"
      label="Include private incidents"
      toggleLabelClassName="w-full"
      toggleClassName="!items-start"
      description={
        includePrivateIncidents ? (
          <Callout theme={CalloutTheme.Warning}>
            Tickets created for private incidents will be visible to anyone with
            access to your Jira project.
            <br />
            Only incident.io owners will be able to change these settings.
          </Callout>
        ) : null
      }
      className="w-full"
    />
  );
};
