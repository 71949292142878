import { AnnouncementRule } from "@incident-io/api";
import { Callout, CalloutTheme, ContentBox } from "@incident-ui";

import { AnnouncementRuleDetails } from "../../common/AnnouncementRuleDetails";

export const AnnouncementRuleDeleteModalInner = ({
  rule,
  isLastRule,
}: {
  rule: AnnouncementRule;
  isLastRule: boolean;
}) => {
  return (
    <>
      {isLastRule && (
        <Callout theme={CalloutTheme.Warning} className="mb-2">
          You are deleting the last announcement rule. Incidents will not be
          announced anywhere. Are you sure?
        </Callout>
      )}
      <div className="text-sm mb-4">
        Are you sure you want to delete this announcement rule?
      </div>
      <ContentBox className="p-4">
        <AnnouncementRuleDetails rule={rule} displayIcon={false} />
      </ContentBox>
    </>
  );
};
