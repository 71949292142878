import { NonPrimitiveEntry } from "@incident-shared/attribute";
import { getPrimitiveIcon } from "@incident-shared/catalog/CatalogTypeSelector";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { OrgAwareLink } from "@incident-shared/org-aware";
import { getColorPalette } from "@incident-shared/utils/ColorPalettes";
import { Badge, BadgeTheme } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { isDerivedAttribute } from "src/components/catalog/catalog-type-form/types";
import { CatalogType, CustomField } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { getCatalogTypeOptionGroups } from "../common/getCatalogTypeOptionGroups";
import { CustomFieldFormData } from "./CustomFieldCreateEditDrawer";

export const CustomFieldOptionsFromCatalogTypeSection = ({
  customField,
  hasFixedReturnType,
}: {
  customField: CustomField | undefined;
  hasFixedReturnType: boolean;
}) => {
  const formMethods = useFormContext<CustomFieldFormData>();
  const {
    data: { catalog_types: catalogTypes },
    isLoading: catalogTypesLoading,
    error,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const {
    data: { custom_fields: customFields },
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  if (error || customFieldsError) {
    console.error(error || customFieldsError);
    return null;
  }

  const isLoading = catalogTypesLoading || customFieldsLoading;

  const selectedCatalogTypeId = formMethods.watch("catalog_type_id");
  const selectedCatalogType = selectedCatalogTypeId
    ? catalogTypes.find((x) => x.id === selectedCatalogTypeId)
    : undefined;

  const catalogTypeOptionGroups = getCatalogTypeOptionGroups(catalogTypes);

  let attributeOptions: SelectOption[] = [];
  let helptextOptions: SelectOption[] = [];
  let filterByAttributeOptions: SelectOption[] = [];
  if (selectedCatalogType) {
    attributeOptions = selectedCatalogType?.schema.attributes
      // Filter out array attributes (we can't use them) as we need to be able to resolve
      // a single group for each option.
      // Also Text attributes (they're a bad idea as they're rich text, likely to be
      // descriptions)
      .filter(
        (a) =>
          !a.array &&
          !["Text", "Bool", "Number"].includes(a.type) &&
          // https://linear.app/incident-io/issue/RESP-3979/support-grouping-custom-field-options-by-catalog-attribute-that-is-a
          // we currently don't support derived attributes for group by
          !isDerivedAttribute(a.mode),
      )
      .map((a, idx) => {
        const entryType = catalogTypes.find((t) => t.type_name === a.type);
        return {
          value: a.id,
          label: a.name,
          sort_key: idx.toString().padStart(4),
          icon: entryType ? entryType.icon : getPrimitiveIcon(a.type),
          iconProps: entryType
            ? {
                className: getColorPalette(entryType.color).icon,
              }
            : {},
        };
      });
    helptextOptions = selectedCatalogType?.schema.attributes
      // Filter out all attributes that aren't type string, along with array attributes
      .filter((a) => !a.array && a.type === "String")
      .map((a, idx) => {
        const entryType = catalogTypes.find((t) => t.type_name === a.type);
        return {
          value: a.id,
          label: a.name,
          sort_key: idx.toString().padStart(4),
          icon: entryType ? entryType.icon : getPrimitiveIcon(a.type),
        };
      });

    // Find all catalog types that are in use by other custom fields
    const customFieldToCatalogTypeIDLookup: Record<string, string> = {};
    const catalogTypeIDsToCustomFieldsLookup: Record<string, CustomField[]> =
      {};
    customFields.forEach((field) => {
      if (field.catalog_type_id) {
        customFieldToCatalogTypeIDLookup[field.id] = field.catalog_type_id;
        if (!catalogTypeIDsToCustomFieldsLookup[field.catalog_type_id]) {
          catalogTypeIDsToCustomFieldsLookup[field.catalog_type_id] = [];
        }
        catalogTypeIDsToCustomFieldsLookup[field.catalog_type_id].push(field);
      }
    });

    // Catalog type names that have a custom field pointing at them
    const choosableCatalogTypeNames = catalogTypes
      .filter((ct) =>
        Object.keys(catalogTypeIDsToCustomFieldsLookup).includes(ct.id),
      )
      .map((ct) => ct.type_name);

    filterByAttributeOptions = selectedCatalogType?.schema.attributes
      // Look for just attributes that match type with an existing custom field
      .filter((attr) => choosableCatalogTypeNames.includes(attr.type))
      .flatMap((attr, idx) => {
        const attributeType = catalogTypes.find(
          (t) => t.type_name === attr.type,
        );
        if (attributeType === undefined) {
          throw new Error(
            "unreachable: could not find attribute type in catalog types list",
          );
        }

        // Now we want to get any custom fields that match this catalog type
        const fields = catalogTypeIDsToCustomFieldsLookup[attributeType.id];
        return fields.map((field) => {
          return {
            // we use a combined ID, so that we can keep it in a single dropdown nice and easily
            value: `${field.id}/${attr.id}`,
            label: `${field.name} (${selectedCatalogType.name} → ${attr.name})`, // e.g. Affected Product (Team -> Product)
            sort_key: idx.toString().padStart(4),
            icon: attributeType.icon,
            iconProps: {
              className: getColorPalette(attributeType.color).icon,
            },
          };
        });
      });
  }

  return (
    <>
      {!customField?.catalog_type_id && !hasFixedReturnType ? (
        <div>
          <StaticSingleSelectV2
            formMethods={formMethods}
            label={"Which catalog type will the values come from?"}
            helptext={
              "We'll use the type's entries to populate the custom field"
            }
            placeholder={"Select a type"}
            isLoading={isLoading}
            options={catalogTypeOptionGroups}
            onValueChange={() => {
              // undefined here as that will get ignored.
              formMethods.setValue<"group_by_catalog_attribute_id">(
                "group_by_catalog_attribute_id",
                // @ts-expect-error this is react-hook-form's type system not being quite right. You can't use
                null,
              );
              // undefined here as that will get ignored.
              formMethods.setValue<"helptext_attribute_id">(
                "helptext_attribute_id",
                // @ts-expect-error this is react-hook-form's type system not being quite right. You can't use
                null,
              );
            }}
            name="catalog_type_id"
            required={"You must select a catalog type"}
          />
          {selectedCatalogType && (
            <div className="py-2">
              <CatalogTypePreviewBar catalogType={selectedCatalogType} />
            </div>
          )}
        </div>
      ) : customField?.catalog_type_id ? ( // TS doesn't seem to realise that we definitely have a type ID here
        <div>
          <p className="font-medium text-sm">Catalog type</p>
          <OrgAwareLink
            target="_blank"
            className="mt-2 inline-block"
            to={`/catalog/${customField.catalog_type_id}`}
          >
            <NonPrimitiveEntry
              value={{
                catalog_entry: {
                  catalog_type_id: customField.catalog_type_id,
                  catalog_entry_id: "",
                  catalog_entry_name: customField.name, // we don't have an entry so we fake it
                },
                label: selectedCatalogType?.name ?? customField.name,
                sort_key: "", // this won't be used for our purposes
              }}
              icon={selectedCatalogType?.icon}
              color={selectedCatalogType?.color}
            />
          </OrgAwareLink>
          <Form.Helptext>
            Once using a catalog type, it is not possible to change it.
          </Form.Helptext>
        </div>
      ) : undefined}

      <div className="pl-4 border-l-[3px] border-stroke">
        <div className="flex flex-col grow gap-6">
          <StaticSingleSelectV2
            formMethods={formMethods}
            label={"Group by catalog attribute"}
            required={false}
            disabled={attributeOptions.length === 0}
            disabledTooltipContent="This catalog type doesn't have any attributes to group by."
            helptext={
              "If selected, we'll group your options by an attribute in your catalog."
            }
            placeholder={"Select an attribute"}
            isLoading={isLoading}
            options={attributeOptions}
            name="group_by_catalog_attribute_id"
            isClearable={true}
          />
          {helptextOptions.length > 0 ? (
            <StaticSingleSelectV2
              formMethods={formMethods}
              label={"Use a catalog attribute as helptext"}
              required={false}
              helptext="If selected, we'll display the contents of this attribute as helptext to make it easier for users to pick the correct option."
              placeholder={"Select an attribute"}
              isLoading={isLoading}
              options={helptextOptions}
              name="helptext_attribute_id"
              isClearable={true}
            />
          ) : null}
          {filterByAttributeOptions.length > 0 ? (
            <StaticSingleSelectV2
              formMethods={formMethods}
              label={
                "Filter options based on the value of another custom field"
              }
              required={false}
              helptext="Choose to filter the list of available options based on the value of the selected field."
              placeholder={"Select a field"}
              isLoading={isLoading}
              options={filterByAttributeOptions}
              name="filtering_by_composite_id"
              isClearable={true}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const CatalogTypePreviewBar = ({
  catalogType,
}: {
  catalogType: CatalogType;
}) => {
  const { data, isLoading, error } = useAPI("catalogListEntries", {
    catalogTypeId: catalogType.id,
    pageSize: 5,
  });

  if (isLoading || error || !data) {
    // Don't render anything if we're loading or there's an error
    return null;
  }

  const palette = getColorPalette(catalogType.color);

  let moreEntries = <></>;
  if (
    data.pagination_meta.total_record_count &&
    data.pagination_meta.total_record_count > data.catalog_entries.length
  ) {
    moreEntries = (
      <Badge
        theme={BadgeTheme.Primary}
        className={tcx(palette.background, palette.text)}
      >
        +{data.pagination_meta.total_record_count - data.catalog_entries.length}
      </Badge>
    );
  }

  return (
    <div className="flex gap-2">
      {data.catalog_entries.map((entry) => {
        return (
          <Badge
            key={entry.id}
            theme={BadgeTheme.Primary}
            className={tcx(palette.background, palette.text)}
            icon={catalogType.icon}
          >
            {entry.name}
          </Badge>
        );
      })}
      {moreEntries}
    </div>
  );
};
