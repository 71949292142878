import { formatLoginWithRedirectURL } from "@incident-shared/utils/loginWithRedirect";
import { Loader } from "@incident-ui";
import { useEffect } from "react";

// This will redirect user to Slack to sign-in
export const SetupDeepLinkToSlackSignInRedirect = () => {
  const loginURL = formatLoginWithRedirectURL({
    returnPath: "/setup",
  });

  useEffect(() => {
    window.location.href = loginURL;
  }, [loginURL]);

  return <Loader />;
};
