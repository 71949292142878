/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteSlim,
    AlertRouteSlimFromJSON,
    AlertRouteSlimFromJSONTyped,
    AlertRouteSlimToJSON,
} from './AlertRouteSlim';
import {
    IncidentAlertSlim,
    IncidentAlertSlimFromJSON,
    IncidentAlertSlimFromJSONTyped,
    IncidentAlertSlimToJSON,
} from './IncidentAlertSlim';

/**
 * 
 * @export
 * @interface PendingInIncidents
 */
export interface PendingInIncidents {
    /**
     * 
     * @type {AlertRouteSlim}
     * @memberof PendingInIncidents
     */
    alert_route: AlertRouteSlim;
    /**
     * The incident alerts that this alert was grouped with
     * @type {Array<IncidentAlertSlim>}
     * @memberof PendingInIncidents
     */
    incident_alerts: Array<IncidentAlertSlim>;
}

export function PendingInIncidentsFromJSON(json: any): PendingInIncidents {
    return PendingInIncidentsFromJSONTyped(json, false);
}

export function PendingInIncidentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingInIncidents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_route': AlertRouteSlimFromJSON(json['alert_route']),
        'incident_alerts': ((json['incident_alerts'] as Array<any>).map(IncidentAlertSlimFromJSON)),
    };
}

export function PendingInIncidentsToJSON(value?: PendingInIncidents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_route': AlertRouteSlimToJSON(value.alert_route),
        'incident_alerts': ((value.incident_alerts as Array<any>).map(IncidentAlertSlimToJSON)),
    };
}

