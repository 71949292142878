import { IncidentsBuildScopeContextEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { GenericErrorMessage, Loader } from "@incident-ui";
import graphic from "src/components/settings/banners/banner-debriefs.svg";
import { IntegrationSettingsProviderEnum as IntegrationProvider } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";

import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { SettingsSubHeading } from "../SettingsSubHeading";
import { UpsellNotice } from "../UpsellNotice";
import { DebriefCalendarSettings } from "./DebriefCalendarSettings";
import { DebriefDurationSection } from "./DebriefDurationSection";
import { DebriefEventDetailsSection } from "./DebriefEventDetailsSection";
import { DebriefInviteesSection } from "./DebriefInviteesSection";
import { DebriefPlaceholdersSection } from "./DebriefPlaceholdersSection";
import { DebriefRenameSection } from "./DebriefRenameSection";
import { ExcludeTestOrTutorialInvitesToggle } from "./ExcludeTestOrTutorialInvitesToggle";

export const DebriefsViewPage = (): React.ReactElement => {
  const { integrations, integrationsLoading } = useIntegrations();
  const { identity } = useIdentity();
  const { hasProduct } = useProductAccess();
  const {
    data: { invite_rules: inviteRules },
    isLoading: loadingInviteRules,
    error: errorInviteRules,
  } = useAPI("debriefsListInviteRules", undefined, {
    fallbackData: { invite_rules: [] },
  });

  const {
    data: settingsResp,
    isLoading: loadingSettings,
    error: errorSettings,
  } = useAPI("debriefsShowSettings", undefined);

  const {
    data: placeholdersResp,
    isLoading: loadingPlaceholders,
    error: errorPlaceholders,
  } = useAPI("debriefsListPlaceholders", undefined, {
    fallbackData: { placeholders: [] },
  });

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.FullScope,
  );

  if (
    loadingInviteRules ||
    loadingSettings ||
    scopeLoading ||
    loadingPlaceholders ||
    integrationsLoading ||
    !identity
  ) {
    return <Loader />;
  }

  if (errorInviteRules || !inviteRules) {
    return (
      <GenericErrorMessage description="We couldn't load your debrief invite rules." />
    );
  }

  if (errorSettings || !settingsResp || scopeError) {
    return (
      <GenericErrorMessage description="We couldn't load your debrief settings." />
    );
  }

  if (errorPlaceholders || !placeholdersResp) {
    return (
      <GenericErrorMessage description="We couldn't load your debrief placeholders." />
    );
  }

  const GCalIntegration = integrations?.find(
    (integration) =>
      integration.provider === IntegrationProvider.GoogleCalendar,
  );
  if (!GCalIntegration) {
    throw new Error("unreachable: GCal integration not found");
  }
  const googleCalendarIsInstalled = GCalIntegration.installed;

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Schedule debriefs"
        subtitle="Hold debrief meetings to review incidents and gather valuable insights."
        graphic={<img src={graphic} className="h-40" />}
        articleId={8263585}
      />
      {hasProduct(Product.Response) ? (
        <div className="space-y-8">
          <DebriefInviteesSection inviteRules={inviteRules} />
          <DebriefEventDetailsSection
            incidentScope={scope}
            settings={settingsResp.settings}
          />
          <DebriefDurationSection settings={settingsResp.settings} />
          <DebriefRenameSection settings={settingsResp.settings} />
          <div className="space-y-4">
            <SettingsSubHeading
              title="Sensible defaults"
              titleHeadingLevel={2}
              explanation={`Help responders schedule debriefs correctly by configuring
                        sensible defaults.`}
            />
            <DebriefCalendarSettings
              settings={settingsResp.settings}
              googleCalendarIsInstalled={googleCalendarIsInstalled}
            />
            <ExcludeTestOrTutorialInvitesToggle
              settings={settingsResp.settings}
            />
            {identity.feature_gates.debrief_placeholders ? (
              <DebriefPlaceholdersSection
                placeholders={placeholdersResp.placeholders}
              />
            ) : (
              <UpsellNotice
                analyticsId={"debrief-placeholders-upsell-banner"}
                title={"Placeholders"}
                planName={"Enterprise"}
                description={
                  "If you'd prefer, we can schedule the debrief in a placeholder for you. Blocking out time using placeholders is helpful if you struggle to find a time for users to meet, which can be caused by timezones or busy calendars."
                }
                articleId={8348168}
              />
            )}
          </div>
        </div>
      ) : (
        <ProductUpsellNotice
          featureName="Debriefs"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
    </SettingsSubPageWrapper>
  );
};
