import { Callout, CalloutTheme } from "@incident-ui";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourcePRTGSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Log into PRTG and navigate to <strong>Setup</strong>,{" "}
        <strong>Account settings</strong>, and then{" "}
        <strong>Notification Templates</strong>.
      </p>
      <p>
        Hover over the plus icon and choose{" "}
        <strong>Add Notification Template</strong>
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Enter a template name of your choosing, e.g. &apos;incident.io&apos;
            and then scroll down and enable <strong>Execute HTTP Action</strong>{" "}
            and copy in the following fields.
          </>
        }
        blocks={[
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/prtg/${
              alertSourceConfig.id
            }`,
          },
          {
            title: "SNI Handling",
            code: "Do not send SNI",
          },
          {
            title: "HTTP Method",
            code: "POST",
          },
          {
            title: "Payload",
            code: "colorofstate=%colorofstate&company=%company&comments=%comments&commentssensor=%commentssensor&commentsdevice=%commentsdevice&commentsgroup=%commentsgroup&commentsprobe=%commentsprobe&coverage=%coverage&cumsince=%cumsince&date=%date&datetime=%datetime&device=%device&deviceid=%deviceid&down=%down&downtime=%downtime&elapsedlastcheck=%elapsedlastcheck&elapsedlastdown=%elapsedlastdown&elapsedlastup=%elapsedlastup&group=%group&groupid=%groupid&history=%history&home=%home&host=%host&iconofstate=%iconofstate&lastcheck=%lastcheck&lastdown=%lastdown&lastmessage=%lastmessage&laststatus=%laststatus&lastup=%lastup&lastvalue=%lastvalue&linkprobe=%linkprobe&linkgroup=%linkgroup&linkdevice=%linkdevice&linksensor=%linksensor&location=%location&name=%name&nodename=%nodename&objecttags=%objecttags&parenttags=%parenttags&prio=%prio&priority=%priority&probe=%probe&probeid=%probeid&programname=%programname&programversion=%programversion&sensor=%sensor&sensorid=%sensorid&server=%server&serviceurl=%serviceurl&settings=%settings&shortname=%shortname&since=%since&sitename=%sitename&statesince=%statesince&status=%status&summarycount=%summarycount&syslogerrors=%syslogerrors&syslogmessages=%syslogmessages&syslogwarnings=%syslogwarnings&systemdatetime=%systemdatetime&tags=%tags&time=%time&timezone=%timezone&toaddress=%toaddress&traperrors=%traperrors&trapmessages=%trapmessages&trapwarnings=%trapwarnings&uptime=%uptime&",
          },
          {
            title: "HTTP Version",
            code: "1.1",
          },
        ]}
      />

      <p>
        After saving, you&apos;ll be able to send notifications from your
        sensors to incident.io.
        <Callout theme={CalloutTheme.Info} className={"mt-4"}>
          Please note that the &apos;Test&apos; button in PRTG does not work
          with HTTP actions containing a payload, so you&apos;ll need to trigger
          an actual alert using the notification template to test the
          integration.
        </Callout>
      </p>
    </SetupInfoNumberedList>
  );
};
