import { Button, ButtonTheme, Heading } from "@incident-ui";
import React from "react";

export const MicrosoftTeamsTabNotFoundPage = (): React.ReactElement => {
  return (
    <div className="flex flex-col h-full min-h-full justify-center text-center gap-6 bg-white">
      <div className="flex flex-col gap-2">
        <Heading level={2} size="medium">
          Sorry, we couldn&rsquo;t find that page.
        </Heading>

        <div className="flex flex-col gap-1 text-content-secondary">
          <p>
            Our Microsoft Teams tab doesn&rsquo;t support all of our
            functionality yet.
          </p>
          <p>You can try opening this page in your browser.</p>
        </div>
      </div>

      <div className="flex shrink justify-center gap-2">
        <Button
          analyticsTrackingId={"back-button"}
          theme={ButtonTheme.Primary}
          onClick={() => window.history.back()}
          className="shrink"
        >
          Go back
        </Button>
        <Button
          analyticsTrackingId={"open-in-browser"}
          href={window.location.href.replace("/ms-teams-tab", "")}
          className="shrink"
          openInNewTab
        >
          Open in browser
        </Button>
      </div>
    </div>
  );
};
