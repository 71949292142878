/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostIncidentTaskConfig,
    PostIncidentTaskConfigFromJSON,
    PostIncidentTaskConfigFromJSONTyped,
    PostIncidentTaskConfigToJSON,
} from './PostIncidentTaskConfig';

/**
 * 
 * @export
 * @interface PostIncidentFlowUpdateTaskConfigResponseBody
 */
export interface PostIncidentFlowUpdateTaskConfigResponseBody {
    /**
     * 
     * @type {PostIncidentTaskConfig}
     * @memberof PostIncidentFlowUpdateTaskConfigResponseBody
     */
    task: PostIncidentTaskConfig;
}

export function PostIncidentFlowUpdateTaskConfigResponseBodyFromJSON(json: any): PostIncidentFlowUpdateTaskConfigResponseBody {
    return PostIncidentFlowUpdateTaskConfigResponseBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowUpdateTaskConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowUpdateTaskConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'task': PostIncidentTaskConfigFromJSON(json['task']),
    };
}

export function PostIncidentFlowUpdateTaskConfigResponseBodyToJSON(value?: PostIncidentFlowUpdateTaskConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task': PostIncidentTaskConfigToJSON(value.task),
    };
}

