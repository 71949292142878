import type { SVGProps } from "react";
import * as React from "react";
const SvgMessage = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 3c-3.86 0-7 3.14-7 7 0 1.222.325 2.429.93 3.475.208.39-.084 1.751-.738 2.405a.656.656 0 0 0 .43 1.12c.068.003.14.004.21.004 1.002 0 2.218-.356 3.01-.762A6.994 6.994 0 0 0 10 17c3.86 0 7-3.14 7-7s-3.14-7-7-7Z"
    />
  </svg>
);
export default SvgMessage;
