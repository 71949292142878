import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemIncidentTypeChanged = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  const typeChange = item.content.incident_type_changed;
  if (!typeChange) {
    throw new Error(
      "malformed timeline item: incident_type_changed was missing incident_type_changed field",
    );
  }

  const toName = typeChange.to.name;

  let title = `Incident type changed to ${toName}`;
  if (typeChange.from) {
    const fromName = typeChange.from.name;
    title = `Incident type changed from ${fromName} to ${toName}`;
  }

  return {
    title,
    icon: IconEnum.IncidentType,
    colour: ColorPaletteEnum.Purple,
    actor: typeChange.actor,
  };
};
