import type { SVGProps } from "react";
import * as React from "react";
const SvgSeverityCritical = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g filter="url(#severity-critical_svg__a)">
      <g filter="url(#severity-critical_svg__b)">
        <path
          fill="currentColor"
          d="M11.778 1.778H4.222a2.446 2.446 0 0 0-2.444 2.444v7.556a2.446 2.446 0 0 0 2.444 2.444h7.556a2.446 2.446 0 0 0 2.444-2.444V4.222a2.446 2.446 0 0 0-2.444-2.444Zm-4.445 3.05a.667.667 0 0 1 1.334 0v3.679a.667.667 0 0 1-1.334 0v-3.68ZM8 11.926a.89.89 0 0 1 0-1.777.89.89 0 0 1 0 1.777Z"
        />
      </g>
    </g>
    <defs>
      <filter
        id="severity-critical_svg__a"
        width={16}
        height={16}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_2233_6891" />
      </filter>
      <filter
        id="severity-critical_svg__b"
        width={12.444}
        height={12.444}
        x={1.778}
        y={1.778}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_2233_6891" />
      </filter>
    </defs>
  </svg>
);
export default SvgSeverityCritical;
