/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InboundCall
 */
export interface InboundCall {
    /**
     * The phone number of the caller
     * @type {string}
     * @memberof InboundCall
     */
    caller_phone_number: string;
    /**
     * The time the call was received
     * @type {Date}
     * @memberof InboundCall
     */
    created_at: Date;
    /**
     * Unique ID for this inbound call
     * @type {string}
     * @memberof InboundCall
     */
    id: string;
}

export function InboundCallFromJSON(json: any): InboundCall {
    return InboundCallFromJSONTyped(json, false);
}

export function InboundCallFromJSONTyped(json: any, ignoreDiscriminator: boolean): InboundCall {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caller_phone_number': json['caller_phone_number'],
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
    };
}

export function InboundCallToJSON(value?: InboundCall | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caller_phone_number': value.caller_phone_number,
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
    };
}

