import { DependentResource } from "@incident-io/api";
import { Link, Txt } from "@incident-ui";
import React from "react";
import { UseFormReturn } from "react-hook-form";

import { RolesSelect, RolesSelectFormData } from "../common/RolesSelect";
import { SeatSelect, SeatSelectFormData } from "../users/SeatSelect";

export interface SeatAndRolesSelectFormData
  extends SeatSelectFormData,
    RolesSelectFormData {}

export const SeatAndRolesSelect = ({
  formMethods,
  userId,
  dependentResources,
}: {
  formMethods: UseFormReturn<SeatAndRolesSelectFormData>;
  userId: string | null;
  dependentResources: DependentResource[];
}): React.ReactElement => {
  const selectedState = formMethods.watch("state");

  return (
    <>
      <div>
        <Txt className="px-2 mb-1 text-base font-medium">Seat</Txt>
        <div className="px-2">
          <SeatSelect
            formMethods={
              formMethods as unknown as UseFormReturn<SeatSelectFormData>
            }
            userId={userId}
            dependentResources={dependentResources}
          />
        </div>
      </div>
      <div className="w-[calc(100%)] h-px bg-surface-tertiary" />
      <div>
        <Txt className="px-2 mb-1 text-base font-medium">Permissions</Txt>
        <div className="px-2 pb-4 text-sm-normal text-content-secondary">
          What permissions should this user have? You can customise roles and
          permissions via the{" "}
          <Link
            to="/settings/users/roles"
            analyticsTrackingId={null}
            openInNewTab
          >
            Roles tab
          </Link>
          .
        </div>
        <div className="px-2 pb-2">
          <RolesSelect
            selectedState={selectedState}
            userId={userId}
            formMethods={
              formMethods as unknown as UseFormReturn<RolesSelectFormData>
            }
          />
        </div>
      </div>
    </>
  );
};
