import {
  DropdownMenu,
  DropdownMenuItem,
} from "@incident-ui/DropdownMenu/DropdownMenu";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { PopoverEmptyState } from "@incident-ui/Popover/PopoverSearch";
import { Searcher, sortKind } from "fast-fuzzy";
import { useState } from "react";

export type PopoverDropdownMenuItem = {
  label: string;
  value: string;
  icon: IconEnum;
};

export const PopoverDropdownMenu = ({
  options,
  elementName,
  onSelect,
  triggerButton,
}: {
  options: PopoverDropdownMenuItem[];
  elementName: string;
  onSelect: (selection: PopoverDropdownMenuItem) => void;
  triggerButton: React.ReactElement;
}) => {
  const [searchString, setSearchString] = useState("");
  const optionsSearcher = new Searcher(options, {
    keySelector: (s) => s.label,
    threshold: 0.8,
    sortBy: sortKind.bestMatch,
  });
  const handleClose = () => {
    setSearchString("");
  };
  const filteredOptions = searchString
    ? optionsSearcher.search(searchString)
    : options;

  return (
    <DropdownMenu
      triggerButton={triggerButton}
      align="start"
      sideOffset={8}
      search={searchString}
      setSearch={setSearchString}
    >
      {filteredOptions.map((option) => {
        return (
          <DropdownMenuItem
            analyticsTrackingId={option.value}
            key={option.value}
            label={option.label}
            icon={option.icon}
            onSelect={() => {
              onSelect(option);
              handleClose();
            }}
          />
        );
      })}
      {filteredOptions.length === 0 && (
        <PopoverEmptyState
          message={
            searchString
              ? `No matching ${elementName}`
              : `You have assigned all ${elementName}`
          }
        />
      )}
    </DropdownMenu>
  );
};
