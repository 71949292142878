/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthShowPublicInformationForSlugResponseBody
 */
export interface AuthShowPublicInformationForSlugResponseBody {
    /**
     * Can this organisation sign in with Microsoft?
     * @type {boolean}
     * @memberof AuthShowPublicInformationForSlugResponseBody
     */
    microsoft_signin_enabled: boolean;
    /**
     * The name of the organisation that was in the context
     * @type {string}
     * @memberof AuthShowPublicInformationForSlugResponseBody
     */
    organisation_name: string;
    /**
     * Whether SAML is enabled for this organisation
     * @type {boolean}
     * @memberof AuthShowPublicInformationForSlugResponseBody
     */
    saml_enabled: boolean;
    /**
     * Whether SAML is optional for this organisation, i.e.: whether users can sign in with Slack
     * @type {boolean}
     * @memberof AuthShowPublicInformationForSlugResponseBody
     */
    saml_is_optional: boolean;
}

export function AuthShowPublicInformationForSlugResponseBodyFromJSON(json: any): AuthShowPublicInformationForSlugResponseBody {
    return AuthShowPublicInformationForSlugResponseBodyFromJSONTyped(json, false);
}

export function AuthShowPublicInformationForSlugResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthShowPublicInformationForSlugResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'microsoft_signin_enabled': json['microsoft_signin_enabled'],
        'organisation_name': json['organisation_name'],
        'saml_enabled': json['saml_enabled'],
        'saml_is_optional': json['saml_is_optional'],
    };
}

export function AuthShowPublicInformationForSlugResponseBodyToJSON(value?: AuthShowPublicInformationForSlugResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'microsoft_signin_enabled': value.microsoft_signin_enabled,
        'organisation_name': value.organisation_name,
        'saml_enabled': value.saml_enabled,
        'saml_is_optional': value.saml_is_optional,
    };
}

