import { Icon, IconEnum, IconSize, StackedList, Txt } from "@incident-ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  IncidentLifecycle,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
  PostIncidentFlow,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { PostIncidentFlowPreview } from "../../post-incident-flow/PostIncidentFlowPreview";
import { useLifecycleOverview } from "../overview/LifecycleOverviewContext";
import { HorizontalLine, Pill } from "../overview/LifecycleUIElements";
import {
  PostIncidentExpressionFormData,
  usePostIncidentBranchResult,
} from "./formHooks";
import { PostIncidentFlowBranch } from "./PostIncidentFlowExpressionBranch";

export const PostIncidentFlowExpressionPreview = ({
  lifecycle,
  flows,
}: {
  lifecycle: IncidentLifecycle;
  flows: PostIncidentFlow[];
}) => {
  const { getConfigurationState } = useLifecycleOverview();
  const state = getConfigurationState(StatusCategoryEnum.PostIncident);
  const isBackground = state === "background";

  const formMethods = useForm<PostIncidentExpressionFormData>({
    defaultValues: {
      expression: lifecycle.automatic_post_incident_flow_expression,
    },
  });

  const { flowIDs, isRequired } = usePostIncidentBranchResult(
    formMethods,
    "expression.else_branch.result",
  );
  const elseFlow = flows.find((flow) => flowIDs[0] === flow.id);

  useEffect(() => {
    formMethods.reset({
      expression: lifecycle.automatic_post_incident_flow_expression,
    });
  }, [formMethods, lifecycle]);
  return (formMethods.watch("expression.branches") ?? []).length > 0 ? (
    <StackedList>
      {formMethods.watch("expression.branches")?.map((_, branchIndex) => (
        <div key={branchIndex} className="px-4 py-2 font-normal">
          <PostIncidentFlowBranch
            branchType="if_branch"
            formMethods={formMethods}
            branchIndex={branchIndex}
            flows={flows}
            disabled
          />
        </div>
      ))}
      {formMethods.watch("expression.else_branch") && (
        <div className="px-4 py-2 font-normal">
          <PostIncidentFlowBranch
            branchType="else_branch"
            formMethods={formMethods}
            flows={flows}
            disabled
          />
        </div>
      )}
    </StackedList>
  ) : (
    <>
      <Pill className="!block !w-auto max-w-lg" disabled={isBackground}>
        <Txt bold lightGrey={isBackground} className="mb-1">
          Enter post-incident flow
        </Txt>
        <Txt lightGrey={isBackground} grey={!isBackground} xs>
          {(formMethods.watch("expression.branches") ?? []).length === 0 &&
          isRequired ? (
            <>{"Automatically for all incidents"}</>
          ) : (
            <div className="inline-flex flex-wrap py-1 gap-1.5 text-slate-700 text-xs items-center">
              <span className="font-medium shrink-0">If</span>
              {" a user opts in"}
            </div>
          )}
        </Txt>
      </Pill>
      {elseFlow && (
        <div className="ml-[22px] mt-4">
          <div className="flex items-center -ml-[1px] mb-2">
            <HorizontalLine className="w-11 shrink-0" />
            <Pill
              className="!w-fit !max-w-xl"
              disabled={isBackground}
              onClickLink={`/settings/post-incident-flow/${elseFlow.id}`}
            >
              <div className="flex w-full items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <Icon
                    id={IconEnum.Clipboard}
                    className={tcx("text-content-tertiary shrink-0")}
                  />
                  {elseFlow.name}
                  <PostIncidentFlowPreview
                    flow={elseFlow}
                    truncateAfter={3}
                    className={tcx(
                      "!hidden xl:!flex",
                      isBackground ? "!text-content-tertiary" : "",
                    )}
                  />
                </div>
                <Icon
                  id={IconEnum.ChevronRight}
                  className={tcx("text-content-tertiary")}
                  size={IconSize.Medium}
                />
              </div>
            </Pill>
          </div>
        </div>
      )}
    </>
  );
};
