import {
  ExternalIssueLink,
  FollowUpCompleteModal,
  FollowUpDeleteModal,
  FollowUpEditModal,
} from "@incident-shared/follow-ups";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  ExportToProviderModal,
  IssueTrackerProviderEnabled,
  IssueTrackerProviderEnum,
} from "@incident-shared/issue-trackers";
import { FollowUpExportFailureSection } from "@incident-shared/issue-trackers/FollowUpExportFailureSection";
import {
  Avatar,
  ButtonTheme,
  Checkbox,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { DropdownMenu, DropdownMenuItem } from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import {
  FollowUp,
  FollowUpStatusEnum,
  Incident,
  Policy,
  PolicyViolation,
  RemindersCreateRequestBodyReminderTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePrimaryCommsPlatformFeatures } from "src/hooks/usePrimaryCommsPlatform";
import { tcx } from "src/utils/tailwind-classes";

import { SendRemindersModal } from "../../common/SendRemindersModal";
import styles from "../FollowUps.module.scss";
import { FollowUpStatusBadge } from "./FollowUpStatusBadge";

interface FollowUpRowsProps {
  incident: Incident;
  followUps: FollowUp[];
  policies: Policy[] | undefined;
  policyViolations: PolicyViolation[] | undefined;
  isPrivateIncident: boolean;
  onHomepage: boolean;

  // Selectable
  selectedFollowUpIDs?: string[];
  setSelectedFollowUpIDs?: (ids: string[]) => void;

  installedIssueTrackers: IssueTrackerProviderEnabled[];

  refetchIncidents: () => Promise<void>;
}

const getViolatedPolicyForActionItem = (
  policies: Policy[],
  policyViolations: PolicyViolation[],
  followUp: FollowUp,
): Policy | undefined => {
  const policyViolation = policyViolations.find(
    (violation) => violation.resource_id === followUp.id,
  );

  return policyViolation
    ? policies?.find((policy) => policy.id === policyViolation.policy_id)
    : undefined;
};

export const FollowUpRows = ({
  incident,
  followUps,
  isPrivateIncident,
  setSelectedFollowUpIDs,
  selectedFollowUpIDs,
  installedIssueTrackers,
  policies,
  policyViolations,
  onHomepage = false,
  refetchIncidents,
}: FollowUpRowsProps): React.ReactElement => {
  const [editingFollowUp, setEditingFollowUp] = useState<FollowUp | null>(null);
  const [completingFollowUp, setCompletingFollowUp] = useState<FollowUp | null>(
    null,
  );
  const [deletingFollowUp, setDeletingFollowUp] = useState<FollowUp | null>(
    null,
  );
  const [sendFollowUpReminder, setSendFollowUpReminder] =
    useState<FollowUp | null>(null);
  const [exportToProvider, setExportToProvider] = useState<{
    provider: IssueTrackerProviderEnum;
    followUp: FollowUp;
  } | null>(null);

  const updateLocalFollowupIssue = async (newFollowUp: FollowUp) => {
    if (!followUps) {
      return;
    }
    const followUpToUpdate = followUps.find(
      (candidate) => candidate.id === newFollowUp.id,
    );
    if (!followUpToUpdate) {
      return;
    }
    followUpToUpdate.external_issue_reference =
      newFollowUp.external_issue_reference;
  };

  return (
    <tbody className={styles.followUpTable}>
      {followUps.map((followUp) => {
        const setIsSelected =
          selectedFollowUpIDs && setSelectedFollowUpIDs
            ? (newIsSelected: boolean) => {
                if (newIsSelected) {
                  // We've just checked it
                  setSelectedFollowUpIDs([...selectedFollowUpIDs, followUp.id]);
                } else {
                  // We've just unchecked it
                  const updatedFollowUpIDs = selectedFollowUpIDs.filter(
                    (x) => x !== followUp.id,
                  );
                  setSelectedFollowUpIDs(updatedFollowUpIDs);
                }
              }
            : undefined;
        return (
          <FollowUpRow
            key={followUp.id}
            followUp={followUp}
            violatedPolicy={getViolatedPolicyForActionItem(
              policies ?? [],
              policyViolations ?? [],
              followUp,
            )}
            isSelected={selectedFollowUpIDs?.includes(followUp.id)}
            setIsSelected={setIsSelected}
            setEditingFollowUp={setEditingFollowUp}
            setDeletingFollowUp={setDeletingFollowUp}
            setSendFollowUpReminder={setSendFollowUpReminder}
            setCompletingFollowUp={setCompletingFollowUp}
            installedIssueTrackers={installedIssueTrackers}
            setExportToProvider={setExportToProvider}
            onHomepage={onHomepage}
            policyViolation={
              policyViolations
                ? policyViolations.find(
                    (violation) => violation.resource_id === followUp.id,
                  )
                : undefined
            }
          />
        );
      })}
      {sendFollowUpReminder && (
        <SendRemindersModal
          selectedResourceIDs={[sendFollowUpReminder.id]}
          // You'll only be able to click "send reminder" if the follow-up is open
          numResourcesToIgnore={0}
          resourceType={RemindersCreateRequestBodyReminderTypeEnum.FollowUp}
          onClose={() => {
            setSendFollowUpReminder(null);
          }}
          onCancel={() => setSendFollowUpReminder(null)}
        />
      )}
      {editingFollowUp && (
        <FollowUpEditModal
          isPrivateIncident={isPrivateIncident}
          followUp={editingFollowUp}
          onClose={() => setEditingFollowUp(null)}
          refetchIncidents={refetchIncidents}
        />
      )}
      {completingFollowUp && (
        <FollowUpCompleteModal
          followUp={completingFollowUp}
          onClose={() => setCompletingFollowUp(null)}
          refetchIncidents={refetchIncidents}
        />
      )}
      {deletingFollowUp && (
        <FollowUpDeleteModal
          followUp={deletingFollowUp}
          onClose={() => setDeletingFollowUp(null)}
          refetchIncidents={refetchIncidents}
        />
      )}
      {exportToProvider?.provider && (
        <ExportToProviderModal
          incident={incident}
          provider={exportToProvider.provider}
          followUp={exportToProvider.followUp}
          onClose={() => setExportToProvider(null)}
          isPrivate={isPrivateIncident}
          updateCallback={updateLocalFollowupIssue}
        />
      )}
    </tbody>
  );
};

interface FollowUpRowProps {
  followUp: FollowUp;

  // pull out into separate ActionItemRowSelectableProps?
  isSelected?: boolean;
  setIsSelected?: (newIsSelected: boolean) => void;

  setEditingFollowUp: (action: FollowUp | null) => void;
  setDeletingFollowUp: (action: FollowUp | null) => void;
  setSendFollowUpReminder: (action: FollowUp | null) => void;
  setCompletingFollowUp: (action: FollowUp | null) => void;

  // Issue Tracker related Props
  installedIssueTrackers: IssueTrackerProviderEnabled[];
  setExportToProvider: (
    exportToProvider: {
      provider: IssueTrackerProviderEnum;
      followUp: FollowUp;
    } | null,
  ) => void;

  violatedPolicy: Policy | undefined;
  policyViolation?: PolicyViolation;

  onHomepage: boolean;
}

const FollowUpRow = ({
  followUp,
  isSelected,
  setIsSelected,
  installedIssueTrackers,
  setExportToProvider,
  setEditingFollowUp,
  setDeletingFollowUp,
  setSendFollowUpReminder,
  setCompletingFollowUp,
  violatedPolicy,
  policyViolation,
  onHomepage = false,
}: FollowUpRowProps): React.ReactElement => {
  const titleWidth = installedIssueTrackers.length > 0 ? "w-3/6" : "w-4/6";
  const { identity } = useIdentity();
  const canUseFollowUpPriorities = identity?.feature_gates.follow_up_priorities;
  const { platformHasReminders } = usePrimaryCommsPlatformFeatures();

  return (
    <tr key={followUp.id} className={isSelected ? "bg-surface-secondary" : ""}>
      {setIsSelected && (
        <td className="w-1">
          <div className="flex items-center">
            <Checkbox
              id={followUp.id}
              onChange={(boolval) => setIsSelected(boolval.target.checked)}
              // Note that users can click "deselect all" which will clear the
              // selectedFollowUpIDs array.
              checked={isSelected}
            />
          </div>
        </td>
      )}
      <td className={`w-1 ${setIsSelected && "!pl-0"}`}>
        <div className="flex w-full h-full items-center justify-center">
          <FollowUpStatusBadge
            followUp={followUp}
            violatedPolicy={violatedPolicy}
            policyViolation={policyViolation}
          />
        </div>
      </td>
      <td className={tcx(titleWidth, "!pl-0", styles.description)}>
        {followUp.title}
      </td>
      <td className="w-1/6">
        {followUp.assignee ? (
          <div className="flex items-center gap-2">
            {!onHomepage && (
              <>
                <Avatar
                  url={followUp.assignee.avatar_url}
                  name={followUp.assignee.name}
                  size={IconSize.Large}
                />
                <span>{followUp.assignee.name}</span>
              </>
            )}
          </div>
        ) : (
          <p className="text-slate-400">No owner</p>
        )}
      </td>

      {/* Priority column. */}
      {canUseFollowUpPriorities && (
        <td className="w-1/6">
          {followUp.priority ? (
            <p>{followUp.priority.name}</p>
          ) : (
            <p className="text-slate-400">No priority</p>
          )}
        </td>
      )}

      {installedIssueTrackers.length > 0 && (
        <td className="w-1/6">
          {followUp.external_issue_reference ? (
            <ExternalIssueLink reference={followUp.external_issue_reference} />
          ) : followUp.sync_failures && followUp.sync_failures.length > 0 ? (
            <FollowUpExportFailureSection followUp={followUp} />
          ) : (
            <p className="text-slate-400">No link</p>
          )}
        </td>
      )}

      <td className="w-1">
        <DropdownMenu
          triggerButtonTheme={ButtonTheme.Unstyled}
          analyticsTrackingId="action-more-options"
          screenReaderText="More options"
          triggerIcon={IconEnum.DotsVertical}
          menuClassName="mr-2"
        >
          <DropdownMenuItem
            analyticsTrackingId="actions-list-edit"
            label="Edit"
            onSelect={() => setEditingFollowUp(followUp)}
            icon={IconEnum.Edit}
            iconProps={{ className: "!w-5 !h-5 -ml-1 mr-1" }}
          />
          {followUp.status !== FollowUpStatusEnum.Completed &&
            !followUp.external_issue_reference && (
              <DropdownMenuItem
                analyticsTrackingId="follow-ups-list-mark-as-complete"
                label="Complete"
                icon={IconEnum.Tick}
                iconProps={{ className: "!w-5 !h-5 -ml-1 mr-1" }}
                onSelect={() => setCompletingFollowUp(followUp)}
              />
            )}
          <DropdownMenuItem
            analyticsTrackingId="actions-list-delete-modal-open"
            label="Delete"
            onSelect={() => setDeletingFollowUp(followUp)}
            icon={IconEnum.Delete}
            iconProps={{ className: "!w-5 !h-5 -ml-1 mr-1" }}
          />
          {followUp.status !== FollowUpStatusEnum.Completed &&
            !onHomepage &&
            platformHasReminders && (
              <DropdownMenuItem
                analyticsTrackingId="actions-list-remind"
                icon={IconEnum.SpeechImportant}
                iconProps={{ className: "!w-5 !h-5 -ml-1 mr-1" }}
                onSelect={() => setSendFollowUpReminder(followUp)}
                label={"Send a reminder"}
              />
            )}
          {!followUp.external_issue_reference &&
            _.sortBy(installedIssueTrackers, (i) => i.provider).map(
              (integration) => {
                return (
                  <DropdownMenuItem
                    analyticsTrackingId="actions-list-export-modal-open"
                    analyticsTrackingMetadata={{
                      provider: integration.provider,
                    }}
                    key={integration.provider}
                    onSelect={() =>
                      setExportToProvider({
                        provider: integration.provider,
                        followUp: followUp,
                      })
                    }
                    icon={IntegrationConfigFor(integration.provider).icon}
                    label={`${
                      integration.provider === IssueTrackerProviderEnum.Shortcut
                        ? `Create`
                        : `Create or link`
                    } a ${
                      IntegrationConfigFor(integration.provider).label
                    } issue`}
                    disabled={integration.disabled}
                    tooltipContent={
                      integration.disabled
                        ? `There is a problem with your connection to ${
                            IntegrationConfigFor(integration.provider).label
                          }`
                        : undefined
                    }
                  />
                );
              },
            )}
        </DropdownMenu>
      </td>
    </tr>
  );
};
