/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleRotation,
    ScheduleRotationFromJSON,
    ScheduleRotationFromJSONTyped,
    ScheduleRotationToJSON,
} from './ScheduleRotation';

/**
 * 
 * @export
 * @interface ScheduleConfig
 */
export interface ScheduleConfig {
    /**
     * Rotas in this schedule
     * @type {Array<ScheduleRotation>}
     * @memberof ScheduleConfig
     */
    rotations: Array<ScheduleRotation>;
    /**
     * The version number of this config
     * @type {number}
     * @memberof ScheduleConfig
     */
    version: number;
}

export function ScheduleConfigFromJSON(json: any): ScheduleConfig {
    return ScheduleConfigFromJSONTyped(json, false);
}

export function ScheduleConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rotations': ((json['rotations'] as Array<any>).map(ScheduleRotationFromJSON)),
        'version': json['version'],
    };
}

export function ScheduleConfigToJSON(value?: ScheduleConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rotations': ((value.rotations as Array<any>).map(ScheduleRotationToJSON)),
        'version': value.version,
    };
}

