/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    StatusPageIncidentSlim,
    StatusPageIncidentSlimFromJSON,
    StatusPageIncidentSlimFromJSONTyped,
    StatusPageIncidentSlimToJSON,
} from './StatusPageIncidentSlim';
import {
    StatusPageSlim,
    StatusPageSlimFromJSON,
    StatusPageSlimFromJSONTyped,
    StatusPageSlimToJSON,
} from './StatusPageSlim';

/**
 * 
 * @export
 * @interface StatusPageIncidentLinked
 */
export interface StatusPageIncidentLinked {
    /**
     * 
     * @type {Actor}
     * @memberof StatusPageIncidentLinked
     */
    linker: Actor;
    /**
     * 
     * @type {StatusPageSlim}
     * @memberof StatusPageIncidentLinked
     */
    status_page: StatusPageSlim;
    /**
     * 
     * @type {StatusPageIncidentSlim}
     * @memberof StatusPageIncidentLinked
     */
    status_page_incident: StatusPageIncidentSlim;
}

export function StatusPageIncidentLinkedFromJSON(json: any): StatusPageIncidentLinked {
    return StatusPageIncidentLinkedFromJSONTyped(json, false);
}

export function StatusPageIncidentLinkedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageIncidentLinked {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'linker': ActorFromJSON(json['linker']),
        'status_page': StatusPageSlimFromJSON(json['status_page']),
        'status_page_incident': StatusPageIncidentSlimFromJSON(json['status_page_incident']),
    };
}

export function StatusPageIncidentLinkedToJSON(value?: StatusPageIncidentLinked | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'linker': ActorToJSON(value.linker),
        'status_page': StatusPageSlimToJSON(value.status_page),
        'status_page_incident': StatusPageIncidentSlimToJSON(value.status_page_incident),
    };
}

