import type { SVGProps } from "react";
import * as React from "react";
const SvgUptime = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#0A6"
      d="m3.604 15.073 3.607-.923 1.417.832.956-1.479 3.705-.892 2.427 1.909-3.562 1.246-.722 1.435L9.8 16.09 6.287 17.11l-2.683-2.036Zm10.13-12.095L16.28 4.22v10.067l-2.545-1.968"
    />
    <path
      fill="#18252D"
      d="M16.336 15.016a.388.388 0 0 1-.233-.078l-2.837-2.13a.388.388 0 0 1 .466-.62l2.216 1.665V4.71l-2.682-2.012a.388.388 0 0 1 .466-.62l2.836 2.129a.388.388 0 0 1 .155.31v10.11a.388.388 0 0 1-.387.389Zm-4.872 2.743a.389.389 0 0 1-.233-.078l-2.837-2.13a.387.387 0 1 1 .465-.62l2.477 1.86.575-1.073-2.571-1.93a.388.388 0 0 1 .465-.62l2.837 2.13c.153.115.2.324.109.493l-.946 1.763a.387.387 0 0 1-.341.205Z"
    />
    <path
      fill="#18252D"
      d="M6.225 17.61a.387.387 0 0 1-.233-.078l-2.837-2.13a.388.388 0 0 1 .465-.62L6.31 16.8l3.384-.861a.388.388 0 0 1 .191.75l-3.564.907a.378.378 0 0 1-.095.013Zm6.182-1.615a.387.387 0 0 1-.093-.763l3.928-.98a.388.388 0 1 1 .187.752l-3.927.98a.406.406 0 0 1-.095.011Z"
    />
    <path
      fill="#fff"
      d="m3.33 4.955 10.012-2.299.164 9.686-3.94.984-1.148 1.642-1.313-.985-3.448.657-.328-9.685Z"
    />
    <path
      fill="#18252D"
      d="M8.48 15.515a.387.387 0 0 1-.243-.085l-1.16-.931-3.596.897A.387.387 0 0 1 3 15.02V4.908c0-.177.121-.332.294-.376l10.111-2.52a.387.387 0 0 1 .481.376v10.11a.388.388 0 0 1-.294.377l-3.781.942-1.008 1.524a.387.387 0 0 1-.323.174ZM7.17 13.69c.088 0 .173.03.243.085l.977.786.858-1.296a.384.384 0 0 1 .229-.162l3.634-.906V2.884L3.775 5.21v9.313l3.3-.823a.391.391 0 0 1 .095-.012Z"
    />
    <path
      fill="#18252D"
      d="M9.458 10.354c-.436.534-.883.823-1.327.862a1.225 1.225 0 0 1-.85-.252.413.413 0 0 0-.547.62 2.074 2.074 0 0 0 1.45.458c.68-.053 1.325-.444 1.913-1.163a.413.413 0 0 0-.64-.525ZM6.454 8.017a.206.206 0 0 1 .103-.056l.412-.087 1.065-.972v-.33l.596-.156v1.228c0 .03.002.15.03.306l.767-.761a.206.206 0 0 1 .103-.056l.412-.088 1.114-1.016v-.514l-.001-.013a.336.336 0 0 0-.01-.072v-.003l-.002-.005a.35.35 0 0 0-.022-.059l-.003-.004a.347.347 0 0 0-.03-.048l-.006-.01a.34.34 0 0 0-.048-.049l-.012-.01a.335.335 0 0 0-.035-.023l-.005-.003a.344.344 0 0 0-.05-.023l-.009-.004a.343.343 0 0 0-.125-.017h-.01a.347.347 0 0 0-.062.01L5.863 6.434a.343.343 0 0 0-.256.332v1.672c0 .034.003.187.044.376l.803-.797Zm4.602-1.43-.878.8a.206.206 0 0 1-.096.05l-.408.087-.873.867c.066.134.158.262.287.361.162.125.443.25.865.14.485-.127.83-.48.998-1.02.072-.235.107-.479.105-.723v-.562Z"
    />
    <path
      fill="#18252D"
      d="m8.035 7.46-.829.756a.206.206 0 0 1-.096.05l-.408.086-.894.888a1 1 0 0 0 .259.307c.118.091.3.183.552.183.094 0 .198-.013.312-.044.486-.127.831-.48 1-1.02.071-.235.106-.478.104-.723V7.46Z"
    />
  </svg>
);
export default SvgUptime;
