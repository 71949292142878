/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WebhookConfigGetConfigResponseBody,
    WebhookConfigGetConfigResponseBodyFromJSON,
    WebhookConfigGetConfigResponseBodyToJSON,
} from '../models';

/**
 * 
 */
export class WebhookConfigApi extends runtime.BaseAPI {

    /**
     * Get organisation\'s Webhook config. There is only one non-archived config per org.
     * GetConfig WebhookConfig
     */
    async webhookConfigGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WebhookConfigGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/webhook_config/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebhookConfigGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s Webhook config. There is only one non-archived config per org.
     * GetConfig WebhookConfig
     */
    async webhookConfigGetConfig(initOverrides?: RequestInit): Promise<WebhookConfigGetConfigResponseBody> {
        const response = await this.webhookConfigGetConfigRaw(initOverrides);
        return await response.value();
    }

}
