import { Button, ButtonTheme } from "@incident-ui";
import { AnchorProps } from "@incident-ui/Button/Button";

export type WorkflowCardButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  analyticsTrackingId: string | null;
} & AnchorProps;

// WorkflowCardButton wraps its children with a button
// that can be clicked to trigger an action.
export const WorkflowCardButton = ({
  onClick,
  analyticsTrackingId,
  children,
  target,
  href,
}: WorkflowCardButtonProps): React.ReactElement => {
  return (
    <Button
      analyticsTrackingId={analyticsTrackingId}
      onClick={onClick}
      href={href}
      target={target}
      theme={ButtonTheme.Secondary}
      // While buttons usually have a fixed height, we want out button to grow to fit the content
      className="!h-auto !py-3 !whitespace-normal text-left !justify-start"
    >
      {children}
    </Button>
  );
};
