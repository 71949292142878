import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  Callout,
  CalloutTheme,
  Checkbox,
  Heading,
  Loader,
  Modal,
  StackedList,
  StepsPageContent,
  StepsPageFooter,
} from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import {
  Schedule,
  SchedulePayConfig,
  SchedulesCreatePayConfigRequestBody,
  User,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { PayConfigCreateEditForm } from "./pay-configurations/PayConfigCreateEditForm";
import {
  ScheduleWithPayConfig,
  SerialisedScheduleWithPayConfig,
} from "./ReportGenerator";
import { SelectableSchedule } from "./SelectableSchedule";

// ReportGeneratorChooseSchedules allows you to select which
// schedules you want to include. From here, you can also add
// pay configs.
export const ReportGeneratorChooseSchedules = ({
  onAddSchedule,
  onRemoveSchedule,
  onDeSelectAllSchedules,
  onSelectAllSchedules,
  onUpdatePayConfigOnSchedule,
  onContinue: onContinueCallback,
  onBack,
  selectedSchedules,
  payConfigs,
}: {
  onAddSchedule: (schedule: Schedule) => void;
  onRemoveSchedule: (scheduleID: string) => void;
  onDeSelectAllSchedules: () => void;
  onSelectAllSchedules: (schedules: ScheduleWithPayConfig[]) => void;
  onUpdatePayConfigOnSchedule: (
    schedule: Schedule,
    pay_config: SchedulePayConfig,
  ) => void;
  onContinue: () => void;
  onBack: () => void;
  selectedSchedules: SerialisedScheduleWithPayConfig[];
  payConfigs: SchedulePayConfig[];
}): React.ReactElement => {
  const { schedules, schedulesLoading } = useGetSchedules();

  const [missingPayConfigErr, setMissingPayConfigErr] = useState(false);

  // This stores the schedule that we came from when we
  // decided to add a pay config, so we can set it
  const [showAddPayConfigModalSchedule, setShowAddPayConfigModalSchedule] =
    useState<Schedule | null>(null);

  const onContinue = () => {
    // check that all schedules have a config
    if (selectedSchedules.some((x) => x.pay_config == null)) {
      setMissingPayConfigErr(true);
    } else {
      onContinueCallback();
    }
  };

  const {
    trigger: createPayConfig,
    isMutating: savingPayConfig,
    genericError: payConfigError,
  } = useAPIMutation(
    "schedulesListPayConfig",
    undefined,
    async (apiClient, data: SchedulesCreatePayConfigRequestBody) => {
      const { schedule_pay_config: config } =
        await apiClient.schedulesCreatePayConfig({
          createPayConfigRequestBody: data,
        });

      if (showAddPayConfigModalSchedule == null) {
        throw new Error(
          "unreachable: creating pay config without a chosen external schedule",
        );
      }

      onUpdatePayConfigOnSchedule(showAddPayConfigModalSchedule, config);
      setShowAddPayConfigModalSchedule(null);
    },
  );
  if (payConfigError) {
    throw payConfigError;
  }

  if (!schedules || schedulesLoading) {
    return <Loader />;
  }

  return (
    <>
      {showAddPayConfigModalSchedule ? (
        <Modal
          analyticsTrackingId="create-pay-configuration"
          title="Add pay configuration"
          onClose={() => setShowAddPayConfigModalSchedule(null)}
          disableQuickClose
          isOpen
          isExtraLarge
        >
          <PayConfigCreateEditForm
            mode={Mode.Create}
            continueButtonText="Add pay configuration"
            onSubmit={createPayConfig}
            onClose={() => setShowAddPayConfigModalSchedule(null)}
            saving={savingPayConfig}
          />
        </Modal>
      ) : undefined}
      <ChooseSchedulesScreen
        payConfigs={payConfigs}
        schedules={schedules}
        selectedSchedules={selectedSchedules}
        onRemoveSchedule={onRemoveSchedule}
        onAddSchedule={onAddSchedule}
        onDeSelectAllSchedules={onDeSelectAllSchedules}
        onSelectAllSchedules={onSelectAllSchedules}
        onUpdatePayConfigOnSchedule={onUpdatePayConfigOnSchedule}
        onAddPayConfig={(schedule) =>
          setShowAddPayConfigModalSchedule(schedule)
        }
      />
      <StepsPageFooter
        onBack={onBack}
        onContinue={onContinue}
        continueDisabled={selectedSchedules.length === 0}
        genericError={
          missingPayConfigErr
            ? "Please choose a pay configuration for each schedule"
            : null
        }
      />
    </>
  );
};

const ChooseSchedulesScreen = ({
  schedules,
  payConfigs,
  selectedSchedules,
  onRemoveSchedule,
  onAddSchedule,
  onDeSelectAllSchedules,
  onSelectAllSchedules,
  onUpdatePayConfigOnSchedule,
  onAddPayConfig,
}: {
  schedules: Schedule[];
  payConfigs: SchedulePayConfig[];
  selectedSchedules: SerialisedScheduleWithPayConfig[];
  onAddSchedule: (schedule: Schedule) => void;
  onRemoveSchedule: (scheduleID: string) => void;
  onDeSelectAllSchedules: () => void;
  onSelectAllSchedules: (schedules: ScheduleWithPayConfig[]) => void;
  onUpdatePayConfigOnSchedule: (
    schedule: Schedule,
    pay_config: SchedulePayConfig,
  ) => void;
  onAddPayConfig: (schedule: Schedule) => void;
}): React.ReactElement => {
  const allItemsSelected = schedules.length === selectedSchedules.length;

  const hasMultipleScheduleProviders =
    _.uniqBy(schedules, "external_provider").length > 1;

  return (
    <StepsPageContent className="flex justify-center">
      <div className="max-w-4xl w-full">
        <div className="w-full">
          <div className="flex w-full">
            <Heading level={2} size="medium" className="mb-4 grow">
              {`Which schedules should be included in the report?`}
            </Heading>
            {schedules.length > 1 ? (
              <Checkbox
                className="mb-4 font-md"
                id="select_all"
                onChange={
                  allItemsSelected
                    ? onDeSelectAllSchedules
                    : () =>
                        onSelectAllSchedules(
                          schedules.map((schedule) => ({
                            schedule,
                            pay_config: payConfigs.find(
                              (conf) =>
                                conf.id === schedule.default_pay_config_id,
                            ),
                          })),
                        )
                }
                checked={allItemsSelected}
                label="Select All"
              />
            ) : null}
          </div>
          {/* List of schedules */}
          {schedules.length === 0 ? (
            <div className="text-slate-600 text-sm">
              <p>We haven&apos;t imported any of your schedules yet.</p>
              <p>
                We synchronise schedules on a daily basis, as well as
                immediately after you connect PagerDuty or Opsgenie.
              </p>
            </div>
          ) : (
            <>
              <StackedList>
                {schedules.map((schedule) => {
                  const selectedConfig = selectedSchedules.find(
                    (sched) => sched.schedule.id === schedule.id,
                  );

                  const isSelected = selectedConfig != null;
                  return (
                    <SelectableSchedule
                      hasMultipleScheduleProviders={
                        hasMultipleScheduleProviders
                      }
                      allPayConfigs={payConfigs}
                      key={schedule.id}
                      schedule={schedule}
                      payConfig={selectedConfig?.pay_config}
                      isSelected={isSelected}
                      onChangeSelected={() =>
                        isSelected
                          ? onRemoveSchedule(schedule.id)
                          : onAddSchedule(schedule)
                      }
                      onSelectPayConfig={(config) =>
                        onUpdatePayConfigOnSchedule(schedule, config)
                      }
                      onAddPayConfig={() => onAddPayConfig(schedule)}
                    />
                  );
                })}
              </StackedList>
              <Callout className="mt-4" theme={CalloutTheme.Plain}>
                <p className="!m-0 font-semibold">Schedule missing?</p>
                <p>
                  Opsgenie and PagerDuty schedules are synced on a daily basis.
                </p>
              </Callout>
            </>
          )}
        </div>
      </div>
    </StepsPageContent>
  );
};

export const useGetSchedules = (): {
  schedules: Schedule[] | null;
  users: User[];
  schedulesLoading: boolean;
} => {
  // Pull all the schedules,
  const {
    data: { schedules, users },
    error: schedulesError,
    isLoading: schedulesLoading,
  } = useAPI("schedulesList", undefined, {
    fallbackData: { schedules: [], users: [] },
  });
  if (schedulesError) {
    throw schedulesError;
  }
  return { schedules, users, schedulesLoading };
};
