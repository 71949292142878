import { IntegrationConfigFor } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  Callout,
  CalloutTheme,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";

import { ServiceWizardPasteServicesForm } from "./ServiceWizardPasteServicesForm";

export const ServiceWizardBackstageDrawer = () => {
  const navigate = useOrgAwareNavigate();

  const config = IntegrationConfigFor("backstage");

  const onClose = () =>
    navigate("/catalog/service-wizard/choose-source-of-truth");

  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents>
        <DrawerTitle
          title={<div className="flex items-center gap-2">Backstage</div>}
          titleAccessory={
            <IconBadge
              icon={config.icon}
              size={IconSize.Small}
              hexColor={config.hexColor}
              color={ColorPaletteEnum.SlateOnWhite}
            />
          }
          subtitle={config.description}
          onClose={onClose}
          className="text-white"
          hexColor={config.hexColor}
        />
        <DrawerBody>
          <Callout
            iconOverride={IconEnum.Github}
            theme={CalloutTheme.Plain}
            title="Sync your services from Backstage"
            subtitle="Our catalog importer template lets your push your data directly from Backstage"
            cta={
              <Button
                analyticsTrackingId="backstage-catalog-importer"
                href="https://github.com/incident-io/catalog-importer/tree/master/docs/backstage"
                openInNewTab
              >
                Learn more
              </Button>
            }
          />
          <ServiceWizardPasteServicesForm />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};
