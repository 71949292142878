import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { SlackChannelsEditorV2 } from "@incident-shared/forms/v2/editors";
import { ErrorMessage } from "@incident-ui";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { useNodeFormErrors } from "src/components/escalation-paths/nodes/useNodeFormErrors";

import { EscalationPathFormData, PathNode } from "../common/types";
import { deleteNode } from "../node-editor/helpers/deleteNode";
import { shouldDisplayWorkingHoursTimeToAck } from "../node-editor/helpers/displayWorkingHoursTimeToAck";
import { insertIfElseAboveNode } from "../node-editor/helpers/insertNode";
import {
  makeLevelNode,
  makeRepeatNode,
} from "../node-editor/helpers/makeNodes";
import { replaceNode } from "../node-editor/helpers/replaceNode";
import { NodeCard, NodeCardTitleDropdown } from "./NodeCard";
import { showDeleteButton } from "./NodeLevel";
import { TimeToAckForm } from "./TimeToAckForm";

// NodeNotifyChannel is used for all NotifyChannel nodes in an escalation path, and is a wrapper around the box element.
export const NodeNotifyChannel = ({ id }: { id: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");

  const shouldAllowWorkingHoursTimeToAck = shouldDisplayWorkingHoursTimeToAck(
    nodes,
    nodes[id],
    firstNodeId,
  );

  const relatedErrors = useNodeFormErrors({
    formMethods,
    id,
    nodeType: NodeTypes.NotifyChannel,
  });

  const previousNotifiableNodeId = Object.values(nodes).find(
    (node) =>
      (node.data.nodeType === NodeTypes.Level &&
        node.data.level?.nextNodeId === id) ||
      (node.data.nodeType === NodeTypes.NotifyChannel &&
        node.data.notifyChannel?.nextNodeId === id),
  )?.id;

  // Mini-dropdown menu for the title
  const title = (
    <NodeCardTitleDropdown
      title={`Channel`}
      onSelectCondition={() => {
        insertIfElseAboveNode({
          node: formMethods.getValues().nodes[id],
          nodes: formMethods.getValues().nodes,
          firstNodeId: formMethods.getValues().firstNodeId,
          updateNodes: (nodes: Record<string, PathNode>) => {
            formMethods.setValue<"nodes">("nodes", nodes);
          },
          updateFirstNodeId: (firstNodeId: string) => {
            formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
          },
        });
      }}
      onSelectLevel={() => {
        replaceNode({
          oldNode: formMethods.getValues().nodes[id],
          nodes: formMethods.getValues().nodes,
          firstNodeId: formMethods.getValues().firstNodeId,
          makeNewNode: (oldNodeId) => makeLevelNode({ nextNodeId: oldNodeId }),
          updateNodes: (nodes: Record<string, PathNode>) => {
            formMethods.setValue<"nodes">("nodes", nodes);
          },
          updateFirstNodeId: (firstNodeId: string) => {
            formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
          },
        });
      }}
      onSelectRepeat={
        // A notify node can only be converted to a repeat if it's the last node in the path,
        // and it's also preceded by a level or notify channel node.
        nodes[id]?.data?.level?.nextNodeId === undefined &&
        previousNotifiableNodeId !== undefined
          ? () => {
              replaceNode({
                oldNode: formMethods.getValues().nodes[id],
                nodes: formMethods.getValues().nodes,
                firstNodeId: formMethods.getValues().firstNodeId,
                makeNewNode: () => makeRepeatNode({ toNodeId: firstNodeId }),
                updateNodes: (nodes: Record<string, PathNode>) => {
                  formMethods.setValue<"nodes">("nodes", nodes);
                },
                updateFirstNodeId: (firstNodeId: string) => {
                  formMethods.setValue<"firstNodeId">(
                    "firstNodeId",
                    firstNodeId,
                  );
                },
              });
            }
          : undefined
      }
    />
  );

  const onDeleteNode = () => {
    deleteNode({
      nodeId: id,
      nodes: formMethods.getValues().nodes,
      firstNodeId: formMethods.getValues().firstNodeId,
      updateNodes: (nodes: Record<string, PathNode>) => {
        formMethods.setValue<"nodes">("nodes", nodes);
      },
      updateFirstNodeId: (firstNodeId: string) => {
        formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
      },
    });
  };

  return (
    <NodeCard
      nodeId={id}
      title={title}
      subtitle="Notify"
      icon={
        <div
          className={
            "rounded-2 flex items-center justify-center w-10 h-10 border border-stroke-secondary"
          }
        >
          <Icon id={IconEnum.Slack} size={IconSize.Medium} />
        </div>
      }
      onDeleteNode={
        showDeleteButton(id, firstNodeId, nodes) ? onDeleteNode : undefined
      }
    >
      <div className="flex flex-col gap-4 mt-4">
        <SlackChannelsEditorV2
          formMethods={formMethods}
          name={`nodes.${id}.data.notifyChannel.targets`}
          placeholder="Choose a channel..."
          disallowPrivateChannels={true}
          required
        />

        <TimeToAckForm
          id={id}
          formMethods={formMethods}
          nodeType={"notifyChannel"}
          shouldAllowWorkingHoursTimeToAck={shouldAllowWorkingHoursTimeToAck}
        />
        {relatedErrors.length > 0 ? (
          <ErrorMessage message={relatedErrors[0]} className={"text-xs"} />
        ) : null}
      </div>
    </NodeCard>
  );
};
