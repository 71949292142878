/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    ExternalResource,
    ExternalResourceFromJSON,
    ExternalResourceFromJSONTyped,
    ExternalResourceToJSON,
} from './ExternalResource';

/**
 * 
 * @export
 * @interface PagerdutyIncidentResolved
 */
export interface PagerdutyIncidentResolved {
    /**
     * 
     * @type {ExternalResource}
     * @memberof PagerdutyIncidentResolved
     */
    external_resource: ExternalResource;
    /**
     * 
     * @type {Actor}
     * @memberof PagerdutyIncidentResolved
     */
    resolver: Actor;
}

export function PagerdutyIncidentResolvedFromJSON(json: any): PagerdutyIncidentResolved {
    return PagerdutyIncidentResolvedFromJSONTyped(json, false);
}

export function PagerdutyIncidentResolvedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagerdutyIncidentResolved {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_resource': ExternalResourceFromJSON(json['external_resource']),
        'resolver': ActorFromJSON(json['resolver']),
    };
}

export function PagerdutyIncidentResolvedToJSON(value?: PagerdutyIncidentResolved | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_resource': ExternalResourceToJSON(value.external_resource),
        'resolver': ActorToJSON(value.resolver),
    };
}

