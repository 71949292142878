/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertRouteEscalationBindingPayload
 */
export interface AlertRouteEscalationBindingPayload {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteEscalationBindingPayload
     */
    binding: EngineParamBindingPayload;
}

export function AlertRouteEscalationBindingPayloadFromJSON(json: any): AlertRouteEscalationBindingPayload {
    return AlertRouteEscalationBindingPayloadFromJSONTyped(json, false);
}

export function AlertRouteEscalationBindingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteEscalationBindingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': EngineParamBindingPayloadFromJSON(json['binding']),
    };
}

export function AlertRouteEscalationBindingPayloadToJSON(value?: AlertRouteEscalationBindingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingPayloadToJSON(value.binding),
    };
}

