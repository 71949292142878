import { OrgAwareLink } from "@incident-shared/org-aware";
import React from "react";
import { AnalyticsMetadata, useAnalytics } from "src/contexts/AnalyticsContext";
import { tcx } from "src/utils/tailwind-classes";

export type LinkProps = {
  children: React.ReactNode;
  className?: string;
  openInNewTab?: boolean;
  analyticsTrackingId: string | null;
  analyticsTrackingMetadata?: AnalyticsMetadata;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  noUnderline?: boolean;
  noHoverColor?: boolean;
} & React.HTMLAttributes<HTMLAnchorElement> &
  (
    | {
        href?: never;
        to: string;
      }
    | {
        href: string;
        to?: never;
      }
  );

/**
 * The Link component can be either a HTML `<a>` element, or a react-router `Link`. It should usually be used to link
 * to external content (e.g. a call URL or a URL custom field) or to our own support content.
 *
 * If it's passed a `href`, it'll be an `<a>`; with a `to` prop (internal navigation only) it'll be a `Link`.
 *
 * This component will inherit whatever size its parent component is, so you should use within a `<Text>` or control the size using classes.
 **/
export function Link({
  to,
  href,
  children,
  className,
  openInNewTab,
  analyticsTrackingId,
  analyticsTrackingMetadata,
  onClick,
  noUnderline,
  noHoverColor,
}: LinkProps): React.ReactElement {
  const analytics = useAnalytics();

  const onClickWithTracking = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (analyticsTrackingId) {
      analytics?.track(
        `${analyticsTrackingId}.clicked-link`,
        analyticsTrackingMetadata,
      );
    }
    onClick?.(e);
  };

  const Tag = to ? OrgAwareLink : "a";
  const props = to
    ? {
        to,
        target: openInNewTab ? "_blank" : undefined,
        rel: openInNewTab ? "noopener noreferrer" : undefined,
      }
    : {
        href,
        target: openInNewTab ? "_blank" : undefined,
        rel: openInNewTab ? "noopener noreferrer" : undefined,
      };

  return (
    // @ts-expect-error it doesn't like the dynamic tag thing
    <Tag
      {...props}
      onClick={onClickWithTracking}
      className={tcx(
        "link", // this will show up for debugging
        !noUnderline && "underline",
        !noHoverColor && "hover:text-alarmalade-600",
        className,
      )}
    >
      {children}
    </Tag>
  );
}
