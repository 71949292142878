/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianStatuspageConfig,
    AtlassianStatuspageConfigFromJSON,
    AtlassianStatuspageConfigFromJSONTyped,
    AtlassianStatuspageConfigToJSON,
} from './AtlassianStatuspageConfig';

/**
 * 
 * @export
 * @interface IntegrationsAtlassianStatuspageUpdateConfigResponseBody
 */
export interface IntegrationsAtlassianStatuspageUpdateConfigResponseBody {
    /**
     * 
     * @type {AtlassianStatuspageConfig}
     * @memberof IntegrationsAtlassianStatuspageUpdateConfigResponseBody
     */
    config: AtlassianStatuspageConfig;
}

export function IntegrationsAtlassianStatuspageUpdateConfigResponseBodyFromJSON(json: any): IntegrationsAtlassianStatuspageUpdateConfigResponseBody {
    return IntegrationsAtlassianStatuspageUpdateConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsAtlassianStatuspageUpdateConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsAtlassianStatuspageUpdateConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': AtlassianStatuspageConfigFromJSON(json['config']),
    };
}

export function IntegrationsAtlassianStatuspageUpdateConfigResponseBodyToJSON(value?: IntegrationsAtlassianStatuspageUpdateConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': AtlassianStatuspageConfigToJSON(value.config),
    };
}

