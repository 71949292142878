import {
  CatalogResource,
  CatalogResourceCategoryEnum,
  CatalogType,
  IntegrationSettingsProviderEnum,
} from "@incident-io/api";
import {
  IntegrationConfig,
  IntegrationConfigFor,
} from "@incident-shared/integrations";
import { IconEnum } from "@incident-ui";

export const customGroupConfig: Partial<IntegrationConfig> = {
  label: "Your types",
};

const incidentIOGroupConfig: Partial<IntegrationConfig> = {
  label: "incident.io",
  icon: IconEnum.IncidentFlame,
};

export const primitiveConfig: Partial<IntegrationConfig> = {
  label: "Primitives",
};

export const getIntegrationConfig = (group: CatalogGroupType) => {
  switch (group) {
    case "custom":
      return customGroupConfig;
    case "incident_io":
      return incidentIOGroupConfig;
    case "primitive":
      return primitiveConfig;
    default:
      return IntegrationConfigFor(group as IntegrationSettingsProviderEnum);
  }
};

export type CatalogGroupType =
  | IntegrationSettingsProviderEnum
  | "incident_io"
  | "custom"
  | "primitive";

const baseTypes = ["User", "IncidentSeverity"];
export const BASE_TYPE_GROUP = "incident_io";

export const integrationGroup = (
  v: CatalogType | CatalogResource,
): CatalogGroupType => {
  const typeName = "type_name" in v ? v.type_name : v.type;
  const requiredIntegration =
    "required_integration" in v
      ? v.required_integration
      : "requires_integration" in v
      ? v.requires_integration
      : undefined;

  if (baseTypes.includes(typeName)) {
    return BASE_TYPE_GROUP;
  }
  if (requiredIntegration) {
    return requiredIntegration as unknown as IntegrationSettingsProviderEnum;
  }
  if ("category" in v && v.category === CatalogResourceCategoryEnum.Primitive) {
    return "primitive";
  }
  return "custom";
};
