/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalatedTimelineUserNotification,
    EscalatedTimelineUserNotificationFromJSON,
    EscalatedTimelineUserNotificationFromJSONTyped,
    EscalatedTimelineUserNotificationToJSON,
} from './EscalatedTimelineUserNotification';

/**
 * 
 * @export
 * @interface EscalatedTimelineLevelData
 */
export interface EscalatedTimelineLevelData {
    /**
     * The level that this was escalated to
     * @type {number}
     * @memberof EscalatedTimelineLevelData
     */
    level: number;
    /**
     * 
     * @type {Array<EscalatedTimelineUserNotification>}
     * @memberof EscalatedTimelineLevelData
     */
    notifications: Array<EscalatedTimelineUserNotification>;
    /**
     * The reason this was escalated
     * @type {string}
     * @memberof EscalatedTimelineLevelData
     */
    reason: EscalatedTimelineLevelDataReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalatedTimelineLevelDataReasonEnum {
    InitialPending = 'initial_pending',
    UserAcked = 'user_acked',
    UserAckedExternally = 'user_acked_externally',
    IncidentDeclined = 'incident_declined',
    AutoAcked = 'auto_acked',
    GracePeriodEnded = 'grace_period_ended',
    NoRemainingRepeats = 'no_remaining_repeats',
    MaxErrors = 'max_errors',
    IncidentTriaged = 'incident_triaged',
    LevelActive = 'level_active',
    LevelTimeout = 'level_timeout',
    NoTargets = 'no_targets',
    AllNacked = 'all_nacked',
    ConditionTrue = 'condition_true',
    ConditionFalse = 'condition_false',
    ConditionFailed = 'condition_failed',
    RepeatTriggered = 'repeat_triggered',
    AlertMarkedRelated = 'alert_marked_related',
    AlertResolved = 'alert_resolved',
    MarkedUnrelatedToAllIncidents = 'marked_unrelated_to_all_incidents',
    InboundCallEnded = 'inbound_call_ended'
}

export function EscalatedTimelineLevelDataFromJSON(json: any): EscalatedTimelineLevelData {
    return EscalatedTimelineLevelDataFromJSONTyped(json, false);
}

export function EscalatedTimelineLevelDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalatedTimelineLevelData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
        'notifications': ((json['notifications'] as Array<any>).map(EscalatedTimelineUserNotificationFromJSON)),
        'reason': json['reason'],
    };
}

export function EscalatedTimelineLevelDataToJSON(value?: EscalatedTimelineLevelData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'notifications': ((value.notifications as Array<any>).map(EscalatedTimelineUserNotificationToJSON)),
        'reason': value.reason,
    };
}

