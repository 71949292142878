import type { SVGProps } from "react";
import * as React from "react";
const SvgAlertRoute = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16.28 15.97-3.75-3.75a.75.75 0 1 0-1.061 1.061l3.75 3.75a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.061ZM12.97 6.53a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.061l-3.25-3.25a.75.75 0 0 0-1.061 0l-3.25 3.25a.75.75 0 1 0 1.06 1.061L9.5 4.56v5.611c0 .334-.13.648-.365.884L4.22 15.97a.75.75 0 0 0 1.06 1.061l4.914-4.914c.52-.52.806-1.21.806-1.945V4.561l1.97 1.969Z"
    />
  </svg>
);
export default SvgAlertRoute;
