import { Product } from "@incident-shared/billing";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import {
  ScopeNameEnum,
  Settings,
  ShoutoutChannelSettings,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../SettingsSection";

type ShoutoutChannelFormState = ShoutoutChannelSettings;

export const AutomationShoutoutsEditForm = (): React.ReactElement => {
  const { settings } = useSettings();

  if (!settings) {
    return <></>;
  }

  return <AutomationShoutoutsEditFormInner settings={settings} />;
};

const AutomationShoutoutsEditFormInner = ({
  settings,
}: {
  settings: Settings;
}): React.ReactElement => {
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const formMethods = useForm<ShoutoutChannelFormState>({
    defaultValues: settings.misc.shoutout_channel,
  });

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: ShoutoutChannelFormState) => {
      if (!data.enabled) {
        data.config = undefined;
      }

      return await apiClient.settingsUpdateShoutoutChannel({
        updateShoutoutChannelRequestBody: {
          shoutout_channel: data,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: async ({ settings }) => {
        formMethods.reset(settings.misc.shoutout_channel);
        showToast(SharedToasts.SETTINGS_SAVED);
      },
    },
  );

  const shoutoutChannelEnabled = formMethods.watch("enabled");

  const apiClient = useClient();
  return (
    <>
      <SettingsSection
        requiredProduct={Product.Response}
        formMethods={formMethods}
        mutation={mutation}
        enabledPath="enabled"
        title="Send shoutouts to another channel"
        explanation={`Shoutouts can be sent to recognise people's contributions to an incident.
        These can be sent to a channel you choose, such as #shoutouts, or #gratitude`}
      >
        <DynamicSingleSelectV2
          formMethods={formMethods}
          label="Shoutouts Slack channel"
          name="config.shoutout_slack_channel_id"
          placeholder="Select a Slack channel"
          loadOptions={getTypeaheadOptions(
            apiClient,
            TypeaheadTypeEnum.SlackChannel,
            {},
          )}
          hydrateOptions={hydrateInitialSelectOptions(
            apiClient,
            TypeaheadTypeEnum.SlackChannel,
          )}
          isDisabled={!canEditSettings}
          required={shoutoutChannelEnabled}
          isClearable={false}
        />
      </SettingsSection>
    </>
  );
};
