import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusPostIncident = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#D97706"
      fillRule="evenodd"
      d="M2.59 9A5.502 5.502 0 0 0 13.5 8c0-2.696-1.94-4.939-4.5-5.41V1.07A7.002 7.002 0 0 1 8 15a7.002 7.002 0 0 1-6.93-6h1.52Z"
      clipRule="evenodd"
    />
    <path
      fill="#D97706"
      fillRule="evenodd"
      d="M2.5 8H1a7 7 0 0 1 7-7v1.5A5.5 5.5 0 0 0 2.5 8Z"
      clipRule="evenodd"
      opacity={0.3}
    />
    <path
      fill="#D97706"
      d="M8 5.778A2.224 2.224 0 0 0 5.778 8c0 1.226.996 2.222 2.222 2.222A2.224 2.224 0 0 0 10.222 8 2.224 2.224 0 0 0 8 5.778Z"
    />
  </svg>
);
export default SvgIncidentStatusPostIncident;
