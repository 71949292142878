/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface EscalationNotificationTransitions
 */
export interface EscalationNotificationTransitions {
    /**
     * 
     * @type {Actor}
     * @memberof EscalationNotificationTransitions
     */
    actor: Actor;
    /**
     * 
     * @type {Date}
     * @memberof EscalationNotificationTransitions
     */
    created_at: Date;
    /**
     * Unique identifier of the parent escalation notification
     * @type {string}
     * @memberof EscalationNotificationTransitions
     */
    escalation_notification_id: string;
    /**
     * Unique internal ID of the notification transition
     * @type {string}
     * @memberof EscalationNotificationTransitions
     */
    id: string;
    /**
     * The type of this notification transition
     * @type {string}
     * @memberof EscalationNotificationTransitions
     */
    to_state: EscalationNotificationTransitionsToStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof EscalationNotificationTransitions
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EscalationNotificationTransitionsToStateEnum {
    Pending = 'pending',
    Sent = 'sent',
    Failed = 'failed',
    Acked = 'acked',
    AckedElsewhere = 'acked_elsewhere',
    Nacked = 'nacked',
    NackedElsewhere = 'nacked_elsewhere',
    Cancelled = 'cancelled',
    RateLimited = 'rate_limited'
}

export function EscalationNotificationTransitionsFromJSON(json: any): EscalationNotificationTransitions {
    return EscalationNotificationTransitionsFromJSONTyped(json, false);
}

export function EscalationNotificationTransitionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationNotificationTransitions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'created_at': (new Date(json['created_at'])),
        'escalation_notification_id': json['escalation_notification_id'],
        'id': json['id'],
        'to_state': json['to_state'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EscalationNotificationTransitionsToJSON(value?: EscalationNotificationTransitions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'created_at': (value.created_at.toISOString()),
        'escalation_notification_id': value.escalation_notification_id,
        'id': value.id,
        'to_state': value.to_state,
        'updated_at': (value.updated_at.toISOString()),
    };
}

