/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnnouncementPostsCreateFieldRequestBody
 */
export interface AnnouncementPostsCreateFieldRequestBody {
    /**
     * ID of the custom field that is associated to this field
     * @type {string}
     * @memberof AnnouncementPostsCreateFieldRequestBody
     */
    custom_field_id?: string;
    /**
     * Emoji for this field
     * @type {string}
     * @memberof AnnouncementPostsCreateFieldRequestBody
     */
    emoji?: string;
    /**
     * Type of this field
     * @type {string}
     * @memberof AnnouncementPostsCreateFieldRequestBody
     */
    field_type: AnnouncementPostsCreateFieldRequestBodyFieldTypeEnum;
    /**
     * ID of the incident role that is associated to this field
     * @type {string}
     * @memberof AnnouncementPostsCreateFieldRequestBody
     */
    incident_role_id?: string;
    /**
     * ID of the incident timestamp that is associated to this field
     * @type {string}
     * @memberof AnnouncementPostsCreateFieldRequestBody
     */
    incident_timestamp_id?: string;
    /**
     * Order of this field
     * @type {number}
     * @memberof AnnouncementPostsCreateFieldRequestBody
     */
    rank: number;
    /**
     * Title of this field
     * @type {string}
     * @memberof AnnouncementPostsCreateFieldRequestBody
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum AnnouncementPostsCreateFieldRequestBodyFieldTypeEnum {
    Status = 'announcement_post_fields_status',
    IncidentType = 'announcement_post_fields_incident_type',
    Severity = 'announcement_post_fields_severity',
    Role = 'announcement_post_fields_role',
    Description = 'announcement_post_fields_description',
    CustomField = 'announcement_post_fields_custom_field',
    Timestamp = 'announcement_post_fields_timestamp',
    Creator = 'announcement_post_fields_creator',
    Slack = 'announcement_post_fields_slack'
}

export function AnnouncementPostsCreateFieldRequestBodyFromJSON(json: any): AnnouncementPostsCreateFieldRequestBody {
    return AnnouncementPostsCreateFieldRequestBodyFromJSONTyped(json, false);
}

export function AnnouncementPostsCreateFieldRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostsCreateFieldRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'emoji': !exists(json, 'emoji') ? undefined : json['emoji'],
        'field_type': json['field_type'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'incident_timestamp_id': !exists(json, 'incident_timestamp_id') ? undefined : json['incident_timestamp_id'],
        'rank': json['rank'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function AnnouncementPostsCreateFieldRequestBodyToJSON(value?: AnnouncementPostsCreateFieldRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_id': value.custom_field_id,
        'emoji': value.emoji,
        'field_type': value.field_type,
        'incident_role_id': value.incident_role_id,
        'incident_timestamp_id': value.incident_timestamp_id,
        'rank': value.rank,
        'title': value.title,
    };
}

