import {
  WorkflowPayloadRunsOnIncidentsEnum as RunsOnIncidentsEnum,
  WorkflowsUpdateWorkflowRequestBodyPendingRunStrategyEnum as PendingRunStrategyEnum,
} from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { Txt } from "@incident-ui";
import { UseFormReturn } from "react-hook-form";

import { WorkflowFormData } from "../common/types";

export const WorkflowsDeploySettingsForm = ({
  showPendingRunStrategies,
  formMethods,
  triggerLabel,
  triggerName,
  showRunsOnIncidents,
}: {
  showPendingRunStrategies: boolean;
  pendingRunStrategy?: PendingRunStrategyEnum;
  formMethods: UseFormReturn<WorkflowFormData>;
  triggerLabel?: string;
  triggerName?: string;
  showRunsOnIncidents: boolean;
}) => {
  const triggerLabelHelpText =
    triggerName === "incident.updated" ? (
      "If one of the above incidents changes,"
    ) : (
      <>For these incidents, if {triggerLabel}</>
    );

  return (
    <>
      {triggerLabel && showRunsOnIncidents && (
        <Txt>
          {triggerLabelHelpText} and your conditions match,{" "}
          <span className="font-semibold">should we run this workflow?</span>
        </Txt>
      )}
      {showRunsOnIncidents && (
        <RadioButtonGroupV2
          name="runs_on_incidents"
          srLabel="Should this apply for existing incidents?"
          formMethods={formMethods}
          options={availableRunsOnIncidents}
          horizontal
          boxed
        />
      )}
      {showPendingRunStrategies && (
        <RadioButtonGroupV2
          options={availablePendingRunStrategies}
          name="pending_run_strategy"
          label="Triggered but not yet executed workflow runs should be..."
          srLabel="Triggered but not yet executed workflow runs should be..."
          formMethods={formMethods}
        />
      )}
    </>
  );
};

const availableRunsOnIncidents = [
  {
    value: RunsOnIncidentsEnum.CreatedAndActive,
    label: "Yes",
  },
  {
    value: RunsOnIncidentsEnum.Created,
    label: "No",
  },
];

const availablePendingRunStrategies = [
  {
    value: PendingRunStrategyEnum.Leave,
    label: "Left to run",
    description:
      "The previous conditions and steps will be used for these runs.",
  },
  {
    value: PendingRunStrategyEnum.Update,
    label: "Updated",
    description:
      "The new conditions and steps will be used. The delay will not be changed.",
  },
  {
    value: PendingRunStrategyEnum.Cancel,
    label: "Cancelled",
    description: "Pending runs of this workflow will not run.",
  },
];
