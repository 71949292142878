/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageUpdateIntroTextRequestBody
 */
export interface StatusPageUpdateIntroTextRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageUpdateIntroTextRequestBody
     */
    intro_text: TextNode;
}

export function StatusPageUpdateIntroTextRequestBodyFromJSON(json: any): StatusPageUpdateIntroTextRequestBody {
    return StatusPageUpdateIntroTextRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateIntroTextRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateIntroTextRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intro_text': TextNodeFromJSON(json['intro_text']),
    };
}

export function StatusPageUpdateIntroTextRequestBodyToJSON(value?: StatusPageUpdateIntroTextRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'intro_text': TextNodeToJSON(value.intro_text),
    };
}

