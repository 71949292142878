import { StepConfig } from "@incident-ui/Steps/Steps";

export type ServiceWizardStepID = "choose-source-of-truth" | "add-attributes";

export const ServiceWizardStepConfig: StepConfig<ServiceWizardStepID>[] = [
  {
    id: "choose-source-of-truth",
    name: "Select your services",
  },
  {
    id: "add-attributes",
    name: "Configure your setup",
  },
];
