/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttributePathItem,
    CatalogTypeAttributePathItemFromJSON,
    CatalogTypeAttributePathItemFromJSONTyped,
    CatalogTypeAttributePathItemToJSON,
} from './CatalogTypeAttributePathItem';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface ExpressionSuggestion
 */
export interface ExpressionSuggestion {
    /**
     * 
     * @type {Expression}
     * @memberof ExpressionSuggestion
     */
    expression: Expression;
    /**
     * The path that this navigation expression traverses
     * @type {Array<CatalogTypeAttributePathItem>}
     * @memberof ExpressionSuggestion
     */
    path: Array<CatalogTypeAttributePathItem>;
}

export function ExpressionSuggestionFromJSON(json: any): ExpressionSuggestion {
    return ExpressionSuggestionFromJSONTyped(json, false);
}

export function ExpressionSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expression': ExpressionFromJSON(json['expression']),
        'path': ((json['path'] as Array<any>).map(CatalogTypeAttributePathItemFromJSON)),
    };
}

export function ExpressionSuggestionToJSON(value?: ExpressionSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expression': ExpressionToJSON(value.expression),
        'path': ((value.path as Array<any>).map(CatalogTypeAttributePathItemToJSON)),
    };
}

