/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyViolation,
    PolicyViolationFromJSON,
    PolicyViolationFromJSONTyped,
    PolicyViolationToJSON,
} from './PolicyViolation';

/**
 * 
 * @export
 * @interface PoliciesDismissViolationResponseBody
 */
export interface PoliciesDismissViolationResponseBody {
    /**
     * 
     * @type {PolicyViolation}
     * @memberof PoliciesDismissViolationResponseBody
     */
    policy_violation: PolicyViolation;
}

export function PoliciesDismissViolationResponseBodyFromJSON(json: any): PoliciesDismissViolationResponseBody {
    return PoliciesDismissViolationResponseBodyFromJSONTyped(json, false);
}

export function PoliciesDismissViolationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesDismissViolationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy_violation': PolicyViolationFromJSON(json['policy_violation']),
    };
}

export function PoliciesDismissViolationResponseBodyToJSON(value?: PoliciesDismissViolationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy_violation': PolicyViolationToJSON(value.policy_violation),
    };
}

