/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WebhookConfig,
    WebhookConfigFromJSON,
    WebhookConfigFromJSONTyped,
    WebhookConfigToJSON,
} from './WebhookConfig';

/**
 * 
 * @export
 * @interface WebhookConfigGetConfigResponseBody
 */
export interface WebhookConfigGetConfigResponseBody {
    /**
     * 
     * @type {WebhookConfig}
     * @memberof WebhookConfigGetConfigResponseBody
     */
    config?: WebhookConfig;
}

export function WebhookConfigGetConfigResponseBodyFromJSON(json: any): WebhookConfigGetConfigResponseBody {
    return WebhookConfigGetConfigResponseBodyFromJSONTyped(json, false);
}

export function WebhookConfigGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookConfigGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : WebhookConfigFromJSON(json['config']),
    };
}

export function WebhookConfigGetConfigResponseBodyToJSON(value?: WebhookConfigGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': WebhookConfigToJSON(value.config),
    };
}

