import cx from "classnames";
import React, { MouseEventHandler, SyntheticEvent } from "react";

import { useUIContext } from "../../UIContext";
import { Spinner } from "../Spinner/Spinner";
import styles from "./Button.module.scss";

export enum ButtonTheme {
  Default = "default",
  Modal = "modal",
  Invisible = "invisble",
}

export type ButtonProps = {
  title: string;
  onClick?: (e?: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  children?: React.ReactNode;
  theme?: ButtonTheme;
  className?: string;
  padded?: boolean;
  disabled?: boolean;
  analyticsTrackingId: string | null;
  analyticsTrackingMetadata?: { [key: string]: string | boolean | number };
  onMouseOver?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  type?: "button" | "submit";
} & (
  | { href: string; to?: never; openInNewTab?: boolean }
  | { href?: never; to: string; openInNewTab?: never }
  | {
      href?: never;
      to?: never;
      openInNewTab?: never;
    }
);

export const Button = ({
  to,
  href,
  title,
  onClick,
  openInNewTab = true,
  children,
  theme = ButtonTheme.Default,
  className,
  padded = true,
  disabled = false,
  analyticsTrackingId,
  analyticsTrackingMetadata,
  onMouseOver,
  onMouseLeave,
  loading,
  type,
}: ButtonProps) => {
  const { InternalLink, analytics } = useUIContext();

  const ButtonContent = () => {
    const wrappedOnClick = (
      e: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
      if (analyticsTrackingId) {
        analytics?.track(
          `${analyticsTrackingId}.clicked`,
          analyticsTrackingMetadata,
        );
      }

      if (onClick) {
        onClick(e);
      }
    };

    return (
      <button
        onClick={wrappedOnClick}
        title={title}
        type={type || "button"}
        disabled={loading || disabled}
        className={cx(
          "transition text-sm focus:outline-none",
          padded && "px-2.5 py-2",
          theme === ButtonTheme.Default && styles.default,
          theme === ButtonTheme.Modal && styles.modal,
          theme === ButtonTheme.Invisible && styles.invisible,
          className,
        )}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {/* We don't know why this fixes the TS but it does, so we're leaving
            it */}
        <>
          {loading ? (
            <div className="flex items-center justify-center">
              {" "}
              <Spinner />
            </div>
          ) : (
            children
          )}
        </>
      </button>
    );
  };

  return href ? (
    <a
      href={href}
      target={openInNewTab ? "_blank" : undefined}
      rel="noreferrer"
    >
      <ButtonContent />
    </a>
  ) : to ? (
    <InternalLink to={to} analyticsTrackingId={analyticsTrackingId}>
      <ButtonContent />
    </InternalLink>
  ) : (
    <ButtonContent />
  );
};
