/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchedulePayRule,
    SchedulePayRuleFromJSON,
    SchedulePayRuleFromJSONTyped,
    SchedulePayRuleToJSON,
} from './SchedulePayRule';
import {
    ScheduleReportOverride,
    ScheduleReportOverrideFromJSON,
    ScheduleReportOverrideFromJSONTyped,
    ScheduleReportOverrideToJSON,
} from './ScheduleReportOverride';
import {
    ScheduleReportShift,
    ScheduleReportShiftFromJSON,
    ScheduleReportShiftFromJSONTyped,
    ScheduleReportShiftToJSON,
} from './ScheduleReportShift';

/**
 * 
 * @export
 * @interface ScheduleReportSchedule
 */
export interface ScheduleReportSchedule {
    /**
     * Base rate of pay per hour, in lowest denomination of specified currency
     * @type {number}
     * @memberof ScheduleReportSchedule
     */
    base_rate_cents: number;
    /**
     * Currency used to send schedule, in ISO 4217 format
     * @type {string}
     * @memberof ScheduleReportSchedule
     */
    currency: string;
    /**
     * Name of external provider, e.g. pagerduty
     * @type {string}
     * @memberof ScheduleReportSchedule
     */
    external_provider: ScheduleReportScheduleExternalProviderEnum;
    /**
     * ID of the schedule in incident.io
     * @type {string}
     * @memberof ScheduleReportSchedule
     */
    id: string;
    /**
     * Human readable name synced from external provider
     * @type {string}
     * @memberof ScheduleReportSchedule
     */
    name: string;
    /**
     * Time-bounded overrides for special pay rates
     * @type {Array<ScheduleReportOverride>}
     * @memberof ScheduleReportSchedule
     */
    overrides: Array<ScheduleReportOverride>;
    /**
     * ID of the pay config used in this report
     * @type {string}
     * @memberof ScheduleReportSchedule
     */
    pay_config_id: string;
    /**
     * Configures how we pay for time slots within a schedule
     * @type {Array<SchedulePayRule>}
     * @memberof ScheduleReportSchedule
     */
    pay_rules: Array<SchedulePayRule>;
    /**
     * List of shifts with calculated pay for this schedule
     * @type {Array<ScheduleReportShift>}
     * @memberof ScheduleReportSchedule
     */
    shifts: Array<ScheduleReportShift>;
    /**
     * Timezone of the schedule, as interpreted at the point of generating the report
     * @type {string}
     * @memberof ScheduleReportSchedule
     */
    timezone: string;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleReportScheduleExternalProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}

export function ScheduleReportScheduleFromJSON(json: any): ScheduleReportSchedule {
    return ScheduleReportScheduleFromJSONTyped(json, false);
}

export function ScheduleReportScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReportSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_rate_cents': json['base_rate_cents'],
        'currency': json['currency'],
        'external_provider': json['external_provider'],
        'id': json['id'],
        'name': json['name'],
        'overrides': ((json['overrides'] as Array<any>).map(ScheduleReportOverrideFromJSON)),
        'pay_config_id': json['pay_config_id'],
        'pay_rules': ((json['pay_rules'] as Array<any>).map(SchedulePayRuleFromJSON)),
        'shifts': ((json['shifts'] as Array<any>).map(ScheduleReportShiftFromJSON)),
        'timezone': json['timezone'],
    };
}

export function ScheduleReportScheduleToJSON(value?: ScheduleReportSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_rate_cents': value.base_rate_cents,
        'currency': value.currency,
        'external_provider': value.external_provider,
        'id': value.id,
        'name': value.name,
        'overrides': ((value.overrides as Array<any>).map(ScheduleReportOverrideToJSON)),
        'pay_config_id': value.pay_config_id,
        'pay_rules': ((value.pay_rules as Array<any>).map(SchedulePayRuleToJSON)),
        'shifts': ((value.shifts as Array<any>).map(ScheduleReportShiftToJSON)),
        'timezone': value.timezone,
    };
}

