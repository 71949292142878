import type { SVGProps } from "react";
import * as React from "react";
const SvgLayer = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m11.213 6.113-6.5-1.368a2.237 2.237 0 0 0-1.88.455A2.24 2.24 0 0 0 2 6.947v7.434c0 1.058.752 1.984 1.787 2.202l6.5 1.369A2.245 2.245 0 0 0 13 15.75V8.315a2.26 2.26 0 0 0-1.787-2.202Z"
    />
    <path
      fill="currentColor"
      d="m16.213 3.417-6.5-1.37a2.243 2.243 0 0 0-2.505 1.264.75.75 0 0 0 1.363.627.74.74 0 0 1 .833-.422l6.5 1.369a.754.754 0 0 1 .596.734v7.434a.75.75 0 0 1-.904.734l-.691-.146a.75.75 0 0 0-.309 1.468l.691.146A2.245 2.245 0 0 0 18 13.054V5.619a2.261 2.261 0 0 0-1.787-2.202Z"
    />
  </svg>
);
export default SvgLayer;
