/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemsSetDocumentStatusRequestBody
 */
export interface PostmortemsSetDocumentStatusRequestBody {
    /**
     * 
     * @type {string}
     * @memberof PostmortemsSetDocumentStatusRequestBody
     */
    status: PostmortemsSetDocumentStatusRequestBodyStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemsSetDocumentStatusRequestBodyStatusEnum {
    Enqueued = 'enqueued',
    Failed = 'failed',
    Created = 'created',
    Review = 'review',
    Complete = 'complete'
}

export function PostmortemsSetDocumentStatusRequestBodyFromJSON(json: any): PostmortemsSetDocumentStatusRequestBody {
    return PostmortemsSetDocumentStatusRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsSetDocumentStatusRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsSetDocumentStatusRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function PostmortemsSetDocumentStatusRequestBodyToJSON(value?: PostmortemsSetDocumentStatusRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

