/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BranchesOnlyExpressionPayload,
    BranchesOnlyExpressionPayloadFromJSON,
    BranchesOnlyExpressionPayloadFromJSONTyped,
    BranchesOnlyExpressionPayloadToJSON,
} from './BranchesOnlyExpressionPayload';

/**
 * 
 * @export
 * @interface PostmortemsSettingsUpdateTemplateExpressionRequestBody
 */
export interface PostmortemsSettingsUpdateTemplateExpressionRequestBody {
    /**
     * 
     * @type {BranchesOnlyExpressionPayload}
     * @memberof PostmortemsSettingsUpdateTemplateExpressionRequestBody
     */
    postmortem_template_expression?: BranchesOnlyExpressionPayload;
}

export function PostmortemsSettingsUpdateTemplateExpressionRequestBodyFromJSON(json: any): PostmortemsSettingsUpdateTemplateExpressionRequestBody {
    return PostmortemsSettingsUpdateTemplateExpressionRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsSettingsUpdateTemplateExpressionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsSettingsUpdateTemplateExpressionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postmortem_template_expression': !exists(json, 'postmortem_template_expression') ? undefined : BranchesOnlyExpressionPayloadFromJSON(json['postmortem_template_expression']),
    };
}

export function PostmortemsSettingsUpdateTemplateExpressionRequestBodyToJSON(value?: PostmortemsSettingsUpdateTemplateExpressionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postmortem_template_expression': BranchesOnlyExpressionPayloadToJSON(value.postmortem_template_expression),
    };
}

