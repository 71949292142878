import { ConfigureDrawerProps } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Link,
} from "@incident-ui";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPI } from "src/utils/swr";

import { IntegrationConnectedUser } from "../../common/IntegrationConnectedUser";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";
import NotionPageScreenshot from "./notion_page_screenshot.png";

export const NotionConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { postmortemNameFormatted } = usePostmortemName(null);
  const { data, error, isLoading } = useAPI(
    "integrationsNotionGetConfig",
    undefined,
  );
  const user = data?.config.connecting_user;

  const {
    data: { destinations },
    isLoading: loadingDestinations,
    error: destinationsError,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });
  const hasNotionDestination = destinations?.some(
    (d) => d.document_provider === "notion",
  );

  if (error || destinationsError) {
    return <GenericErrorMessage error={error || destinationsError} />;
  }

  const rawPageId = data?.config?.page_id;
  // #inc-1921 Notion used to give us these IDs without hyphens, then at some point started
  // giving us them with hyphens. If you use the hyphenated link, you get an error when
  // you open the link to Notion, so we need to strip the hyphen out.
  const pageId = rawPageId?.replaceAll("-", "");

  if (isLoading || loadingDestinations) {
    return <IntegrationDrawerContentLoader />;
  }

  const installedWithTemplate = !!data?.config.page_id;

  return (
    <GenericConfigureDrawerContents {...props}>
      <div className="flex flex-col gap-4">
        {user && (!!user.name || !!user.email) && (
          <IntegrationConnectedUser
            {...user}
            provider={props.integration.provider}
          />
        )}

        <ContentBox className="flex flex-row p-4">
          {installedWithTemplate ? (
            <>
              <div>
                <p className="text-sm">
                  We&apos;ve created a page for you in your private Notion
                  space. You should move this page to a location accessible to
                  all of your team.
                  <br />
                  <br />
                  This database will store all of your {postmortemNameFormatted}
                  s exported from incident.io.
                  <br />
                  <br />
                  If you want to have different sets of{" "}
                  {postmortemNameFormatted}s for different people, or in
                  different places, you can use{" "}
                  <Link
                    href="https://www.notion.so/help/guides/using-database-views"
                    analyticsTrackingId={null}
                  >
                    Notion views
                  </Link>
                  .
                </p>
                <br />
                <Link
                  href={`https://notion.so/${pageId}`}
                  openInNewTab
                  analyticsTrackingId={null}
                  className="text-sm"
                >
                  Open your {postmortemNameFormatted} database in Notion
                </Link>
              </div>
              <img
                src={NotionPageScreenshot}
                className={"shadow-lg w-4/12 my-auto h-auto"}
              />
            </>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center">
                <Icon
                  className="text-green-content"
                  size={IconSize.Large}
                  id={IconEnum.Checkmark}
                />
                <p className="text-sm-med ml-1 text-content-primary">
                  1. Install Notion
                </p>
              </div>
              <p className="text-sm ml-8 text-content-secondary">
                Notion has been installed without using the templated page.
                <br />
                If you didn&apos;t mean to do this, you can disconnect the
                integration and reinstall.
              </p>

              {hasNotionDestination ? (
                <div className="space-y-1">
                  <div className="flex flex-row items-center">
                    <Icon
                      className="text-green-content"
                      size={IconSize.Large}
                      id={IconEnum.Checkmark}
                    />
                    <p className="text-sm-med ml-1 text-content-primary">
                      2. Create a post-mortem destination
                    </p>
                  </div>
                  <p className="text-sm text-content-secondary ml-8">
                    You have a post-mortem destination for Notion.
                  </p>
                </div>
              ) : (
                <div className="space-y-1">
                  <div className="flex flex-row space-x-2 items-center">
                    <p className="text-sm-med ml-1 text-content-primary">
                      2. Create a post-mortem destination
                    </p>
                  </div>
                  <p className="text-sm text-content-secondary ml-8">
                    You&apos;ll need to define at least one post-mortem
                    destination connected to Notion to export your documents to.
                  </p>
                  <div className="flex flex-row pt-2">
                    <Button
                      href="/settings/post-mortem/destinations/create"
                      analyticsTrackingId="configure-notion-create-destination"
                      theme={ButtonTheme.Primary}
                    >
                      Create a post-mortem destination
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </ContentBox>
      </div>
    </GenericConfigureDrawerContents>
  );
};
