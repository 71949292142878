import { containerClasses, InputSize } from "@incident-ui/Input/Input";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type TextareaProps = {
  className?: string;
  autoFocus?: boolean;
  value?: string;
  disabled?: boolean;
  invalid?: boolean;
  id: string;
  ref?: React.ForwardedRef<HTMLTextAreaElement>;
  onValueChange?: (val: string) => void;
} & React.HTMLProps<HTMLTextAreaElement>;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { className, value, invalid, disabled, id, ...props }: TextareaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
  ) => {
    return (
      <textarea
        ref={ref}
        // Inputs get priority over things with tabIndex=0, since inputs are
        // generally more important than other things on screen.
        tabIndex={1}
        {...props}
        id={id}
        value={value}
        disabled={disabled}
        className={tcx(
          containerClasses({ invalid, size: InputSize.Large }),
          "h-auto min-h-10",
          className,
        )}
      />
    );
  },
);

Textarea.displayName = "Textarea";
