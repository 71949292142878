/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SavedView,
    SavedViewFromJSON,
    SavedViewFromJSONTyped,
    SavedViewToJSON,
} from './SavedView';

/**
 * 
 * @export
 * @interface SavedViewsUpdateResponseBody
 */
export interface SavedViewsUpdateResponseBody {
    /**
     * 
     * @type {SavedView}
     * @memberof SavedViewsUpdateResponseBody
     */
    saved_view: SavedView;
}

export function SavedViewsUpdateResponseBodyFromJSON(json: any): SavedViewsUpdateResponseBody {
    return SavedViewsUpdateResponseBodyFromJSONTyped(json, false);
}

export function SavedViewsUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedViewsUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saved_view': SavedViewFromJSON(json['saved_view']),
    };
}

export function SavedViewsUpdateResponseBodyToJSON(value?: SavedViewsUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saved_view': SavedViewToJSON(value.saved_view),
    };
}

