import type { SVGProps } from "react";
import * as React from "react";
const SvgSliders = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.25 5.5h-2.357A2.997 2.997 0 0 0 12 3.25c-1.393 0-2.558.96-2.893 2.25H2.75a.75.75 0 0 0 0 1.5h6.357A2.997 2.997 0 0 0 12 9.25c1.393 0 2.558-.96 2.893-2.25h2.357a.75.75 0 0 0 0-1.5ZM12 7.75c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5ZM17.25 13h-6.357A2.997 2.997 0 0 0 8 10.75c-1.393 0-2.558.96-2.893 2.25H2.75a.75.75 0 0 0 0 1.5h2.357A2.997 2.997 0 0 0 8 16.75c1.393 0 2.558-.96 2.893-2.25h6.357a.75.75 0 0 0 0-1.5Z"
    />
  </svg>
);
export default SvgSliders;
