import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ErrorMessage } from "@incident-ui/ErrorMessage/ErrorMessage";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Path, UseFormReturn } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { AutoSavingIndicator } from "src/hooks/useOptimisticAutoSave";
import { tcx } from "src/utils/tailwind-classes";

type titleInputProps<TFormType extends FieldValues> = {
  placeholder: string;
  name: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  prefixNode?: React.ReactNode;
  suffixNode?: React.ReactNode;
  className?: string;
  inputRef: React.RefObject<HTMLDivElement>;
  showEditable?: boolean;
  textCursor?: boolean;
  onSubmit?: (_) => Promise<TFormType>;
  saving?: boolean;
  editing?: boolean;
  setEditing?: (editing: boolean) => void;
  disabled?: boolean;
  textClassName?: string;
};

/**
 * TitleInput is a wrapper around a portal and input element. You can use it to
 * render an input in the page header, when you have a form where you want the
 * name to be editable inline.
 *
 * To use it, create a ref in your page component and pass it both to the PageWrapper
 * using the "titleInputRef" prop, and to the TitleInput component in your form
 * using the "inputRef" prop. Everything should then JustWork™, and you can use
 * this component as if it's a regular input.
 *
 * For an example, see the WorkflowsCreatePage.
 */

export const TitleInputV2 = <TFormType extends FieldValues>({
  placeholder,
  name,
  formMethods,
  prefixNode,
  suffixNode,
  className,
  textCursor,
  inputRef,
  showEditable,
  onSubmit,
  saving = false,
  editing,
  setEditing,
  disabled = false,
  textClassName = "",
}: titleInputProps<TFormType>) => {
  const [isReady, setIsReady] = useState(false);
  const [ownIsEditing, setOwnIsEditing] = useState(false);
  const title = formMethods.watch(name);
  const error = get(formMethods.formState.errors, name.split("."));
  const { handleSubmit } = formMethods;
  const [hasSaved, setHasSaved] = useState(false);
  const hasPassedEditingConsts = editing !== undefined && setEditing;
  const isEditing = hasPassedEditingConsts ? editing : ownIsEditing;
  const setIsEditing = hasPassedEditingConsts ? setEditing : setOwnIsEditing;

  // We need to force this to re-render when the inputRef gets set
  useEffect(() => {
    if (inputRef.current) {
      setIsReady(true);
    }
  }, [inputRef, setIsReady]);

  useEffect(() => {
    if (!hasSaved) return undefined;

    const timeout = setTimeout(() => setHasSaved(false), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [hasSaved]);

  if (!isReady || !inputRef.current) {
    return null;
  }

  return createPortal(
    <div className={tcx(className, "flex grow items-center gap-1.5")}>
      {prefixNode}
      {isEditing ? (
        <InputV2
          placeholder={placeholder}
          inputClassName="!border-none !shadow-none !p-0 !m-0 !text-base !text-content-primary !placeholder-slate-400 h-auto"
          className="grow !p-0 !m-0"
          formMethods={formMethods}
          name={name}
          onBlurCallback={() => {
            setIsEditing(false);
            if (onSubmit) {
              handleSubmit(onSubmit)();
            }
          }}
          autoFocus
        />
      ) : (
        <>
          <div className="flex flex-col">
            <div
              className={tcx("group flex flex-row gap-1 items-center", {
                "!cursor-not-allowed": disabled,
                "cursor-pointer": !textCursor,
                "cursor-text border-[1px] border-white hover:!border-slate-400 transition":
                  textCursor,
              })}
              onClick={disabled ? undefined : () => setIsEditing(true)}
            >
              <span
                className={tcx(
                  "truncate !text-base",
                  {
                    "!text-content-primary": title,
                    "text-slate-400": !title,
                  },
                  textClassName,
                )}
              >
                {title || placeholder}
              </span>
              {!textCursor && (
                <Icon
                  id={IconEnum.Edit}
                  className={tcx(
                    "text-white group-hover:text-slate-400 hover:!text-content-primary transition",
                    { "!text-slate-400": showEditable },
                  )}
                />
              )}
              <div className={""}>
                <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
              </div>
            </div>
            {error?.message && (
              <ErrorMessage className="mt-1" message={error.message} />
            )}
          </div>
          {suffixNode}
        </>
      )}
    </div>,
    inputRef.current,
  );
};
