import type { SVGProps } from "react";
import * as React from "react";
const SvgSiren = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#siren_svg__a)">
      <path d="M6.444 8.666A.667.667 0 0 1 5.777 8c0-1.225.998-2.222 2.223-2.222A.667.667 0 0 1 8 7.11.89.89 0 0 0 7.11 8a.667.667 0 0 1-.666.666ZM7.999 2.666A.667.667 0 0 1 7.332 2V.666a.667.667 0 0 1 1.333 0V2A.667.667 0 0 1 8 2.666ZM12.245 4.424a.667.667 0 0 1-.471-1.139l.943-.943a.667.667 0 1 1 .943.943l-.943.943c-.13.13-.3.196-.472.196ZM15.332 8.666h-1.333a.667.667 0 0 1 0-1.333h1.333a.667.667 0 0 1 0 1.333ZM3.756 4.424a.661.661 0 0 1-.471-.196l-.943-.943a.667.667 0 1 1 .943-.943l.943.943a.667.667 0 0 1-.472 1.139ZM2 8.666H.667a.667.667 0 0 1 0-1.333H2a.667.667 0 0 1 0 1.333ZM12.667 11.555H3.334c-.614 0-1.111.498-1.111 1.111V14c0 .613.497 1.11 1.11 1.11h9.334c.614 0 1.111-.497 1.111-1.11v-1.334c0-.613-.497-1.11-1.11-1.11Z" />
      <path d="M11.777 12.889a.667.667 0 0 1-.667-.667V8A3.114 3.114 0 0 0 8 4.889a3.115 3.115 0 0 0-3.112 3.11v4.223a.667.667 0 0 1-1.333 0V8a4.45 4.45 0 0 1 4.444-4.445A4.45 4.45 0 0 1 12.444 8v4.222a.667.667 0 0 1-.667.667Z" />
    </g>
    <defs>
      <clipPath id="siren_svg__a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSiren;
