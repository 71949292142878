/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DebriefInviteRule,
    DebriefInviteRuleFromJSON,
    DebriefInviteRuleFromJSONTyped,
    DebriefInviteRuleToJSON,
} from './DebriefInviteRule';

/**
 * 
 * @export
 * @interface DebriefsShowInviteRuleResponseBody
 */
export interface DebriefsShowInviteRuleResponseBody {
    /**
     * 
     * @type {DebriefInviteRule}
     * @memberof DebriefsShowInviteRuleResponseBody
     */
    invite_rule: DebriefInviteRule;
}

export function DebriefsShowInviteRuleResponseBodyFromJSON(json: any): DebriefsShowInviteRuleResponseBody {
    return DebriefsShowInviteRuleResponseBodyFromJSONTyped(json, false);
}

export function DebriefsShowInviteRuleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsShowInviteRuleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invite_rule': DebriefInviteRuleFromJSON(json['invite_rule']),
    };
}

export function DebriefsShowInviteRuleResponseBodyToJSON(value?: DebriefsShowInviteRuleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invite_rule': DebriefInviteRuleToJSON(value.invite_rule),
    };
}

