import { ScaleTime } from "d3";
import { addMinutes, differenceInMinutes, subMinutes } from "date-fns";
import _ from "lodash";

import { ComponentStatusConfig } from "../utils/utils";

export type Impact<StatusEnum extends string | number> = {
  id: string;
  status: StatusEnum;
  start_at: Date;
  end_at?: Date;
};

export type Impacts<StatusEnum extends string | number> =
  | {
      group: Group<StatusEnum>;
      component?: never;
    }
  | { group?: never; component: Component<StatusEnum> };

export type AnnotationType = {
  timestamp: Date;
  bubbleContent?: React.ReactNode;
  onClick?: () => void;
};

export type Group<StatusEnum extends string | number> = {
  name: string;
  description?: React.ReactNode;
  components: Component<StatusEnum>[];
};

export type Component<StatusEnum extends string | number> = {
  id: string;
  label: string;
  description?: React.ReactNode;
  impacts: Impact<StatusEnum>[];
  error?: React.ReactNode;
};

export function calculateDomain<StatusEnum extends string | number>(
  now: Date,
  annotations: AnnotationType[],
  impacts: Impacts<StatusEnum>[],
  isOngoing: boolean,
): [Date, Date] {
  const allImpacts = impacts.flatMap(({ group, component }) =>
    component
      ? component.impacts
      : group.components.flatMap((component) => component.impacts),
  );

  // Calculate the window to present over
  const windowStart = _.min(
    _.compact(
      annotations
        .map(({ timestamp }) => timestamp)
        .concat(allImpacts.map((impact) => impact.start_at)),
    ),
  );
  let windowEnd = _.max(
    _.compact(
      annotations
        .map(({ timestamp }) => timestamp)
        .concat(allImpacts.map((impact) => impact.end_at || now)),
    ),
  );
  if (!windowStart || !windowEnd) {
    throw new Error(
      "Status page incident had no updates and no impacts: very odd",
    );
  }

  if (isOngoing) {
    windowEnd = now;
  }

  return [windowStart, windowEnd];
}

export function calculateBufferedDomain(start: Date, end: Date): [Date, Date] {
  // Add a 10% buffer at the start & end of the window (or 2 mins if the window
  // is <20m wide), to show where the incident started, and what the current
  // impact(s) are.
  const buffer = _.max([differenceInMinutes(end, start) / 10, 1]) as number;
  start = subMinutes(start, buffer);
  end = addMinutes(end, buffer);

  return [start, end];
}

export function defaultStatus<StatusEnum extends string | number>(
  config: ComponentStatusConfig<StatusEnum>,
): StatusEnum {
  return _.minBy(
    Object.keys(config),
    (status) => config[status].rank,
  ) as StatusEnum;
}

export const defaultConfig = <StatusEnum extends string | number>(
  config: ComponentStatusConfig<StatusEnum>,
) => config[defaultStatus(config)];

export type ImpactWindow<StatusEnum extends string | number> = {
  id: string;
  x: number;
  width: number;
  start_at: Date;
  end_at?: Date;
  colour: string;
  status: StatusEnum;
};

const MIN_IMPACT_WIDTH = 10;

export function fillIn<StatusEnum extends string | number>(
  scaleX: ScaleTime<number, number>,
  impacts: Impact<StatusEnum>[],
  config: ComponentStatusConfig<StatusEnum>,
): ImpactWindow<StatusEnum>[] {
  const endOfRange = scaleX.range()[1];
  const [startOfDomain, endOfDomain] = scaleX.domain();

  const impactedWindows: ImpactWindow<StatusEnum>[] = impacts.map(
    ({ id, start_at, end_at, status }) => {
      const x = scaleX(start_at);
      const end = scaleX(end_at || endOfDomain);
      const width = end - x;

      return {
        id,
        status,
        colour: config[status].barColour,
        x,
        width,
        start_at,
        end_at,
      };
    },
  );
  const gapStatus = defaultStatus(config);

  const extraWindows: ImpactWindow<StatusEnum>[] = [];
  let lastWindow: Omit<ImpactWindow<StatusEnum>, "colour"> = {
    id: "SPECIAL::initial",
    status: gapStatus,
    x: 0,
    width: 0,
    start_at: startOfDomain,
    end_at: startOfDomain,
  };

  // We fake a window that sits on the end of the range, so that empty space at
  // the end gets filled in the same way as gaps.
  [
    ..._.sortBy(impactedWindows, "x"),
    {
      id: "SPECIAL::endOfRange",
      x: endOfRange,
      width: 0,
      start_at: endOfDomain,
      end_at: endOfDomain,
      status: gapStatus,
    },
  ].forEach((currentWindow) => {
    const lastWindowEnd = lastWindow.x + lastWindow.width;
    if (lastWindowEnd < currentWindow.x && lastWindow.end_at) {
      extraWindows.push({
        id: `SPECIAL::extra::${currentWindow.id}`,
        x: lastWindowEnd,
        width: currentWindow.x - lastWindowEnd,
        status: gapStatus,
        colour: config[gapStatus].barColour,
        start_at: lastWindow.end_at,
        end_at: currentWindow.start_at,
      });
    }

    lastWindow = currentWindow;
  });

  const allWindows = _.sortBy(impactedWindows.concat(extraWindows), "x");

  // Trim a couple pixels off the right of anything except at the very end.
  let bumpNextBy = 0;
  return allWindows.map((window) => {
    // Apply any previous bumping first
    window = {
      ...window,
      x: window.x + bumpNextBy,
      width: window.width - bumpNextBy,
    };
    bumpNextBy = 0;

    // Now cut the width to allow space between things
    if (window.x + window.width < endOfRange) {
      window = { ...window, width: window.width - 2 };
    }

    // Now check if this needs stretching to make it visible.
    if (window.width < MIN_IMPACT_WIDTH) {
      bumpNextBy = MIN_IMPACT_WIDTH - window.width;
      window = {
        ...window,
        width: MIN_IMPACT_WIDTH,
      };
    }

    return window;
  });
}

export function flatten<StatusEnum extends string | number>(
  config: ComponentStatusConfig<StatusEnum>,
  scale: ScaleTime<number, number>,
  impacts: Impact<StatusEnum>[],
): Impact<StatusEnum>[] {
  const now = scale.domain()[1];
  const boundaries = _.uniqBy(
    _.compact([
      ...impacts.flatMap(({ start_at, end_at }) => [start_at, end_at]),
      ...scale.domain(),
    ]).sort(),
    (d: Date) => d.getTime(),
  );

  const splitImpacts = impacts.flatMap((impact) => {
    // Find all the boundaries that are _inside_ this impact
    const splitPoints = boundaries.filter(
      (boundary) =>
        impact.start_at < boundary && boundary < (impact.end_at || now),
    );

    // each split point becomes the start of a new impact and the end of the impact before it.
    // if there are no split points, zipping [start_at] and [end_at] together gives [[start_at, end_at]]
    // if there is one, zip [start_at, split] and [split, end_at] to give [[start_at, split], [split, end_at]]
    // Then add the status back
    const startEnds = _.zip(
      [impact.start_at, ...splitPoints],
      [...splitPoints, impact.end_at],
    ) as [Date, Date | undefined][];
    return startEnds.map(([start_at, end_at], idx) => ({
      id: `${impact.id}::${idx}`,
      status: impact.status,
      start_at,
      end_at,
    }));
  });

  // Now we have uniform impacts that all start and end at the same points.
  // We can therefore group by the start_at, and summarise to the highest-ranking status.
  const nextSet = Object.values(_.groupBy(splitImpacts, "start_at")).map(
    (impacts) => {
      // We have many impacts that have the same start/end, so we now need to aggregate up to the highest-ranking status.
      const { id, start_at, end_at, status: defaultStatus } = impacts[0];
      const status =
        _.maxBy(
          impacts.map((impact) => impact.status),
          (status) => config[status].rank,
        ) || defaultStatus;
      return { id, start_at, end_at, status };
    },
  );

  // Finally we merge any adjacent impacts that have the same status
  const flattened = Object.values(_.groupBy(nextSet, "status")).flatMap(
    (sameStatusImpacts) => {
      const res: Impact<StatusEnum>[] = [];

      const ordered = _.sortBy(sameStatusImpacts, "start_at");
      let currentImpact: Impact<StatusEnum> | undefined;
      ordered.forEach((impact) => {
        if (!currentImpact) {
          currentImpact = { ...impact };
        } else if (
          currentImpact?.end_at?.getTime() === impact.start_at.getTime()
        ) {
          currentImpact.end_at = impact.end_at;
        } else {
          res.push(currentImpact);
          currentImpact = { ...impact };
        }
      });

      if (currentImpact) res.push(currentImpact);

      return res;
    },
  );

  return flattened;
}
