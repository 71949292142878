/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureItemPayload,
    StatusPageStructureItemPayloadFromJSON,
    StatusPageStructureItemPayloadFromJSONTyped,
    StatusPageStructureItemPayloadToJSON,
} from './StatusPageStructureItemPayload';

/**
 * 
 * @export
 * @interface StatusPageCreateStructureRequestBody
 */
export interface StatusPageCreateStructureRequestBody {
    /**
     * Array of components and groups to display in the status page
     * @type {Array<StatusPageStructureItemPayload>}
     * @memberof StatusPageCreateStructureRequestBody
     */
    items: Array<StatusPageStructureItemPayload>;
    /**
     * The ID of the status page this structure is for
     * @type {string}
     * @memberof StatusPageCreateStructureRequestBody
     */
    status_page_id: string;
}

export function StatusPageCreateStructureRequestBodyFromJSON(json: any): StatusPageCreateStructureRequestBody {
    return StatusPageCreateStructureRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateStructureRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateStructureRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(StatusPageStructureItemPayloadFromJSON)),
        'status_page_id': json['status_page_id'],
    };
}

export function StatusPageCreateStructureRequestBodyToJSON(value?: StatusPageCreateStructureRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(StatusPageStructureItemPayloadToJSON)),
        'status_page_id': value.status_page_id,
    };
}

