import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  SelectOption,
  SelectOptionGroup,
  SelectOptionOrGroup,
} from "@incident-ui/Select/types";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import { IncidentStatus } from "src/contexts/ClientContext";

import { useStatusesForIncident } from "../useIncidentCrudResources";
import { Category, CategoryType, isOneOfCategories } from "./status-utils";

export const StatusSelect = <TFormType extends FieldValues>({
  formMethods,
  name,
  filterByCategories,
  hideLabel,
  // This is a little ick, but persuades TypeScript that we're passing the right
  // thing to useStatusesForIncident.
  ...statusParameters
}: {
  formMethods: UseFormReturn<TFormType>;
  name: Path<TFormType>;
  filterByCategories?: Array<CategoryType>;
  hideLabel?: boolean;
} & Parameters<typeof useStatusesForIncident>[0]) => {
  const { statuses, statusesLoading } =
    useStatusesForIncident(statusParameters);

  const selectedStatusID = formMethods.watch(name);
  const selectedStatus = statuses.find(({ id }) => id === selectedStatusID);
  const statusDescription = selectedStatus?.description;
  // Special case: if you choose 'leave in triage' then we don't want to show the status
  // select at all
  if (selectedStatus?.category === Category.Triage) {
    return null;
  }

  // We need to check if the current selected status is in the categories we're filtering by
  if (selectedStatus && filterByCategories) {
    if (!isOneOfCategories(filterByCategories)(selectedStatus)) {
      // If it's not, we need to clear the selected status
      formMethods.setValue(name, "" as PathValue<TFormType, Path<TFormType>>);
    }
  }

  return (
    <div>
      <StaticSingleSelectV2
        formMethods={formMethods}
        name={name}
        label={hideLabel ? undefined : "Status"}
        options={getStatusOptions(
          filterByCategories
            ? statuses.filter(isOneOfCategories(filterByCategories))
            : statuses,
        )}
        isLoading={statusesLoading}
        required
      />
      {statusDescription && (
        <TemplatedTextDisplay
          style={TemplatedTextDisplayStyle.Compact}
          value={statusDescription.text_node}
          className="mt-2 text-xs text-slate-700"
        />
      )}
    </div>
  );
};

function getStatusOptions(
  allStatuses: IncidentStatus[],
): SelectOptionOrGroup[] {
  const activeStatuses = allStatuses.filter(
    isOneOfCategories([Category.Active]),
  );
  const resolvedStatuses = allStatuses.filter(
    isOneOfCategories([Category.PostIncident, Category.Closed]),
  );

  if (activeStatuses.length === 0 || resolvedStatuses.length === 0) {
    // We don't need to group them
    const allOptions: SelectOption[] = [];
    activeStatuses.forEach(({ id, name }) => {
      allOptions.push({
        value: id,
        label: name,
      });
    });
    resolvedStatuses.forEach(({ id, name }) => {
      allOptions.push({
        value: id,
        label: name,
      });
    });
    return allOptions;
  }

  const groupedStatuses: SelectOptionGroup[] = [
    {
      label: Category.Active,
      options: activeStatuses.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    },
  ];
  const resolvedStatusOptions: SelectOption[] = [];

  let postIncidentStatusIncluded = false;
  resolvedStatuses.forEach((status) => {
    const { id, name, category } = status;
    if (category === Category.PostIncident) {
      // We only want to include the first post-incident status as users should only
      // be able to navigate through them in order.
      if (!postIncidentStatusIncluded) {
        resolvedStatusOptions.push({
          value: id,
          label: "Post-incident",
        });
        postIncidentStatusIncluded = true;
      }
    } else {
      resolvedStatusOptions.push({
        value: id,
        label: name,
      });
    }
  });

  groupedStatuses.push({
    label: postIncidentStatusIncluded ? "Resolved" : "Closed",
    options: resolvedStatusOptions,
  });

  return groupedStatuses;
}
