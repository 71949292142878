import { IconEnum } from "@incident-ui";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuItemProps,
} from "@incident-ui/DropdownMenu/DropdownMenu";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useDebriefName } from "src/utils/postmortem-name";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { useAttachments, useStatusPageIncidents } from "../hooks";
import { Sidebar } from "./Components";
import { ProductAccessConditionalComponent } from "./IncidentSidebar";
import { LinkBuilder, LinkBuilderProps } from "./links/builders";
import { DebriefsBuilder } from "./links/Debriefs";
import { AlertsBuilder } from "./links/IncidentAlerts";
import { IncidentAttachmentBuilder } from "./links/IncidentAttachments";
import { EscalationsBuilder } from "./links/IncidentEscalations";
import { IncidentTicketBuilder } from "./links/IncidentTicket";
import { RelatedIncidentsBuilder } from "./links/RelatedIncidents";
import { StatusPageBuilder } from "./links/StatusPageLinks";

const SIDEBAR_LINK_BUILDERS: LinkBuilder[] = [
  StatusPageBuilder,
  IncidentTicketBuilder,
  RelatedIncidentsBuilder,
  EscalationsBuilder,
  AlertsBuilder,
  IncidentAttachmentBuilder,
  DebriefsBuilder,
];

export const Links = (props: LinkBuilderProps) => {
  const dropdownItems = useSidebarLinkActions(props);
  return (
    <Sidebar.Section
      title="Links"
      accessory={
        <DropdownMenu
          align="end"
          triggerButton={
            <Sidebar.TitleAccessoryButton
              icon={IconEnum.Add}
              analyticsTrackingId={null}
              title=""
            />
          }
        >
          {dropdownItems.map((item, i) => (
            <DropdownMenuItem {...item} key={i} />
          ))}
        </DropdownMenu>
      }
    >
      {SIDEBAR_LINK_BUILDERS.map((row, i) => {
        if (row.visible !== undefined && !row.visible(props)) {
          return null;
        }

        return (
          <ProductAccessConditionalComponent
            key={`link-${i}`}
            requiredProduct={row.requiredProduct}
          >
            <row.Render {...props} />
          </ProductAccessConditionalComponent>
        );
      })}
    </Sidebar.Section>
  );
};

export const useSidebarLinkActions = (props: LinkBuilderProps) => {
  const { hasProduct } = useProductAccess();

  const items: DropdownMenuItemProps[] = [];

  SIDEBAR_LINK_BUILDERS.forEach((row) => {
    if (row.requiredProduct) {
      if (!hasProduct(row.requiredProduct)) {
        // move onto the next builder
        return;
      }
    }
    if (row.getDropdownItems) {
      items.push(...row.getDropdownItems(props));
    }
  });

  return items;
};

export const useIncidentSidebarLinkProps = ({
  incidentId,
}: {
  incidentId: string;
}): Omit<LinkBuilderProps, "incident"> => {
  const { attachments } = useAttachments(incidentId);

  const { debriefNameLower } = useDebriefName();
  const { disableDebriefAddToGoogleCalendar } = useFlags();
  const navigateToModal = useNavigateToModal();

  const { statusPageIncidents } = useStatusPageIncidents(incidentId);

  const {
    data: { escalation_paths: escalationPaths },
  } = useAPI("escalationPathsList", undefined, {
    fallbackData: { escalation_paths: [], first_level_users: {} },
  });

  const { data: internalStatusPageData } = useAPI(
    incidentId == null ? null : "internalStatusPageListIncidentLinks",
    { responseIncidentId: incidentId ?? "" },
    { fallbackData: { internal_status_page_incident_links: [] } },
  );

  const {
    data: { incident_debriefs: debriefs },
  } = useAPI(
    incidentId ? "debriefsListIncidentDebriefs" : null,
    { incidentId: incidentId },
    { fallbackData: { incident_debriefs: [] } },
  );

  const {
    data: { incident_relationships: relationships },
  } = useAPI(
    incidentId ? "incidentRelationshipsList" : null,
    { incidentId: incidentId },
    { fallbackData: { incident_relationships: [] } },
  );

  const {
    data: { alert_routes: alertRoutes },
  } = useAPI("alertRoutesListAlertRoutes", undefined, {
    fallbackData: {
      alert_routes: [],
    },
  });

  const {
    data: { incident_alerts: alerts },
  } = useAPI(
    incidentId ? "alertsListIncidentAlerts" : null,
    { incidentId: incidentId },
    { fallbackData: { incident_alerts: [] } },
  );

  const { data: incidentSyncFailures } = useAPI(
    incidentId ? "issueTrackersGetIncidentIssueTrackerIssueSyncFailures" : null,
    {
      incidentId: incidentId || "",
    },
  );

  const {
    data: { providers: escalationProviders },
  } = useAPI("escalationsListProviders", undefined, {
    fallbackData: {
      providers: [],
    },
  });

  const {
    data: { escalations },
  } = useAPI(
    "escalationsList",
    {
      incident: {
        one_of: [incidentId],
      },
    },
    {
      fallbackData: {
        escalations: [],
        pagination_meta: {
          page_size: 0,
          total_count: 0,
        },
      },
    },
  );

  const syncFailures = incidentSyncFailures?.sync_failures
    ? incidentSyncFailures.sync_failures
    : [];

  return {
    syncFailures,
    internalStatusPageData,
    attachments,
    statusPageIncidents,
    relationships,
    escalationProviders,
    escalations,
    escalationPaths,
    alertRoutes,
    alerts,
    debriefs,
    debriefNameLower,
    disableDebriefAddToGoogleCalendar,
    navigateToModal,
  };
};
