import {
  CustomFieldValue,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  InternalStatusPageContentSummary,
  InternalStatusPageIncident,
} from "@incident-io/api";
import {
  ContentBox,
  withSentryErrorBoundary,
} from "@incident-io/status-page-ui";
import { Avatar, Icon, IconEnum, IconSize } from "@incident-ui";
import { compact } from "lodash";
import { tcx } from "src/utils/tailwind-classes";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { AffectedComponents } from "./AffectedComponents";

const isActiveOrTriage = (status: IncidentStatus) =>
  status.category === IncidentStatusCategoryEnum.Active ||
  status.category === IncidentStatusCategoryEnum.Triage;

const KeyInformationReal = ({
  summary,
  incident,
}: {
  // We will need this to show the structure properly
  summary: InternalStatusPageContentSummary;
  incident: InternalStatusPageIncident;
}) => {
  const affectedComponentIds = new Set(
    incident.affected_components.map(({ id }) => id),
  );

  const leadRole = incident.lead_assignment.role;
  const incidentLead = incident.lead_assignment.assignee;

  const roleItems = compact([
    incidentLead
      ? {
          name: leadRole.name,
          value: (
            <>
              <div className="flex flex-row gap-1">
                <Avatar
                  size={IconSize.Medium}
                  url={incidentLead.avatar_url}
                  name={incidentLead.name}
                  className="!ml-0.5"
                />
                {incidentLead.name}
              </div>
            </>
          ),
        }
      : null,
  ]);

  const fieldItems = compact(
    incident.custom_field_entries.map((entry) =>
      entry.values.length > 0
        ? {
            name: entry.custom_field.name,
            value: (
              <div>
                {joinSpansWithCommasAndConnectorWord(
                  compact(
                    entry.values.map((value, idx) => (
                      <CustomFieldValueRender key={idx} {...value} />
                    )),
                  ),
                )}
              </div>
            ),
          }
        : null,
    ),
  );

  const items = [...roleItems, ...fieldItems];

  if (affectedComponentIds.size === 0 && items.length === 0) {
    return null;
  }

  return (
    <ContentBox padded={false}>
      <div className="divide-y divide-slate-300 dark:divide-slate-700 text-sm">
        <Row
          icon={IconEnum.Warning}
          heading="Affected components"
          redHeading={isActiveOrTriage(incident.incident_status)}
        >
          <AffectedComponents
            summary={summary}
            affectedComponentIds={affectedComponentIds}
          />
        </Row>

        <Row icon={IconEnum.Info} heading="Other information" items={items} />
      </div>
    </ContentBox>
  );
};

export const KeyInformation = withSentryErrorBoundary(
  KeyInformationReal,
  "KeyInformation",
);

type RowProps = {
  icon: IconEnum;
  heading: string;
  redHeading?: boolean;
} & (
  | { items: ItemProps[]; children?: never }
  | { items?: never; children: React.ReactNode }
);

const Row = ({
  icon,
  heading,
  redHeading = false,
  items,
  children,
}: RowProps) => {
  if (!children && (items ?? []).length === 0) return null;

  return (
    <div className="p-4 space-y-2.5">
      <div
        className={tcx(
          "flex items-center uppercase font-medium text-xs tracking-widest whitespace-nowrap",
          redHeading ? "text-alarmalade-600" : "text-content-tertiary",
        )}
      >
        <Icon id={icon} className="mr-1" />
        {heading}
      </div>

      {items?.map((item, idx) => <Item key={idx} {...item} />)}
      {children}
    </div>
  );
};

type ItemProps = {
  name: string;
  value: React.ReactNode;
};

const Item = ({ name, value }: ItemProps) => {
  return (
    <div className="flex items-start gap-2">
      <span className="flex-none font-medium">{name}</span>
      <span className="flex-wrap">{value}</span>
    </div>
  );
};

export const CustomFieldValueRender = ({
  value_text,
  value_link,
  value_numeric,
  value_catalog_entry,
  value_option,
}: CustomFieldValue) => {
  if (value_link) {
    return (
      <a
        href={value_link}
        rel="noopener noreferrer text-content-primary dark:text-slate-100"
      >
        {value_link.replace(/http[s]?:\/\//gi, "")}
      </a>
    );
  }

  return (
    <span className="text-content-primary dark:text-slate-100">
      {value_text ||
        value_numeric ||
        value_catalog_entry?.name ||
        value_option?.value}
    </span>
  );
};
