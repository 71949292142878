import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, Loader } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { InsightsCustomDashboardEditRoute } from "./dashboards/create-edit/InsightsCustomDashboardEditRoute";
import { InsightsCoreDashboardRoute } from "./dashboards/show/InsightsCoreDashboardRoute";
import { InsightsCustomDashboardRoute } from "./dashboards/show/InsightsCustomDashboardRoute";
import { InsightsV3ListPage } from "./list/InsightsV3ListPage";

export const InsightsV3Route = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<InsightsV3ListPage />} />
      <Route
        path="dashboards/core/:id"
        element={<InsightsCoreDashboardRoute />}
      />
      <Route
        path="dashboards/custom/:id"
        element={<InsightsCustomDashboardRoute />}
      />
      <Route
        path="dashboards/custom/:id/edit"
        element={<InsightsCustomDashboardEditRoute />}
      />
      <Route path="pager-load" element={<SavedViewRoute />} />
      <Route path="external-pager-load" element={<SavedViewRoute />} />
      <Route path="overview" element={<SavedViewRoute />} />
      <Route path="teams" element={<SavedViewRoute />} />
      <Route path="post-incident-flow" element={<SavedViewRoute />} />
      <Route path="follow-ups" element={<SavedViewRoute />} />
      <Route path="time-spent-on-incidents" element={<SavedViewRoute />} />
      <Route path="mttx" element={<SavedViewRoute />} />
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};

const SavedViewRoute = () => {
  const queryParams = useQueryParams();
  const savedViewId = queryParams.get("view");

  if (!savedViewId) {
    return <OrgAwareNavigate to="/insights" replace />;
  }

  return <NavigateToCustomDashboard savedViewId={savedViewId} />;
};

const NavigateToCustomDashboard = ({
  savedViewId,
}: {
  savedViewId: string;
}) => {
  const { data, isLoading, error } = useAPI(
    "insightsGetCustomDashboardIdOfSavedView",
    { savedViewId },
  );

  if (isLoading || !data) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <OrgAwareNavigate to={`/insights/dashboards/custom/${data.dashboard_id}`} />
  );
};
