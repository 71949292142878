/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DefaultCallURLSettings,
    DefaultCallURLSettingsFromJSON,
    DefaultCallURLSettingsFromJSONTyped,
    DefaultCallURLSettingsToJSON,
} from './DefaultCallURLSettings';

/**
 * 
 * @export
 * @interface IncidentCallSettingsUpdateDefaultCallURLRequestBody
 */
export interface IncidentCallSettingsUpdateDefaultCallURLRequestBody {
    /**
     * 
     * @type {DefaultCallURLSettings}
     * @memberof IncidentCallSettingsUpdateDefaultCallURLRequestBody
     */
    default_call_url?: DefaultCallURLSettings;
}

export function IncidentCallSettingsUpdateDefaultCallURLRequestBodyFromJSON(json: any): IncidentCallSettingsUpdateDefaultCallURLRequestBody {
    return IncidentCallSettingsUpdateDefaultCallURLRequestBodyFromJSONTyped(json, false);
}

export function IncidentCallSettingsUpdateDefaultCallURLRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallSettingsUpdateDefaultCallURLRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_call_url': !exists(json, 'default_call_url') ? undefined : DefaultCallURLSettingsFromJSON(json['default_call_url']),
    };
}

export function IncidentCallSettingsUpdateDefaultCallURLRequestBodyToJSON(value?: IncidentCallSettingsUpdateDefaultCallURLRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_call_url': DefaultCallURLSettingsToJSON(value.default_call_url),
    };
}

