import {
  EngineFormElement,
  EngineLiteralBadge,
  getVariableScope,
} from "@incident-shared/engine";
import { InterpolatedRef } from "@incident-shared/forms/v1/TemplatedText";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import {
  Branch,
  ExpressionBranchesList,
} from "src/components/@shared/engine/expressions/ifelse/ExpressionBranchesList";
import { rehydrateBinding } from "src/components/legacy/workflows/common/utils";
import {
  EngineScope,
  ExpressionElseBranch,
  Resource,
} from "src/contexts/ClientContext";
import { EnrichedScope } from "src/utils/scope";

import { PolicyCapsTxt } from "./PolicyEditableSection";
import { SLABinding } from "./PolicySLAExpressionView";
import { PolicySLAData } from "./PolicySLASection";

export const PolicySLAExpressionEditor = ({
  formMethods,
  scope,
  resources,
  slaTimestampName,
}: {
  formMethods: UseFormReturn<PolicySLAData>;
  scope: EngineScope;
  resources: Resource[];
  slaTimestampName: string;
}): React.ReactElement | null => {
  const { control } = formMethods;
  const {
    move: onMoveBranch,
    append: onAddBranchRaw,
    remove: onRemoveBranch,
    update: onEditBranchRaw,
    fields: branches,
  } = useFieldArray({
    control,
    name: "expressions.0.operations.0.branches.branches",
  });

  const SLADaysResource = resources.find((x) => x.type === "SLADays");

  if (!SLADaysResource) {
    return null;
  }

  const elseBranch = formMethods.watch("expressions.0.else_branch");

  const rehydrateBranchBindings = (branch: Branch): Branch => {
    if (!SLADaysResource) {
      return branch;
    }
    return {
      ...branch,
      result: rehydrateBinding({
        array: false,
        resource: SLADaysResource,
        binding: branch.result,
      }),
    };
  };

  const onAddBranch = (branch: Branch): void => {
    return onAddBranchRaw(rehydrateBranchBindings(branch));
  };
  const onEditBranch = (idx: number, branch: Branch): void => {
    return onEditBranchRaw(idx, rehydrateBranchBindings(branch));
  };

  const variableScope = getVariableScope(scope, resources);

  return (
    <>
      <ExpressionBranchesList
        branches={branches}
        selectedExpressionTypeResource={SLADaysResource}
        scope={scope}
        availableReturnTypeResources={[SLADaysResource]}
        onMoveBranch={onMoveBranch}
        onAddBranch={onAddBranch}
        onRemoveBranch={onRemoveBranch}
        onEditBranch={onEditBranch}
        renderResultBinding={(binding) => (
          <SLABinding
            binding={{ value: binding }}
            scope={variableScope}
            slaTimestampName={slaTimestampName}
          />
        )}
        renderResultInput={({ key }) => (
          <ResultInput
            slaTimestampName={slaTimestampName}
            key={key}
            resources={resources}
            name="result"
          />
        )}
        extraRow={
          <ElseBranchInput
            branch={elseBranch}
            scope={variableScope}
            slaTimestampName={slaTimestampName}
            resources={resources}
            formMethods={formMethods}
          />
        }
      />
    </>
  );
};

const ResultInput = ({
  slaTimestampName,
  resources,
  name,
}: {
  slaTimestampName: string;
  resources: Resource[];
  name: string;
}) => {
  return (
    <div className="flex items-center gap-1">
      <PolicyCapsTxt>within</PolicyCapsTxt>
      <EngineFormElement
        name={name}
        resources={resources}
        array={false}
        resourceType="SLADays"
        mode="plain_input"
        className="w-[70px]"
        required={true}
      />
      <PolicyCapsTxt>days OF</PolicyCapsTxt>
      <EngineLiteralBadge noTooltip label={slaTimestampName} />
    </div>
  );
};

const ElseBranchInput = ({
  branch,
  scope,
  slaTimestampName,
  resources,
  formMethods,
}: {
  branch?: ExpressionElseBranch;
  scope: EnrichedScope<InterpolatedRef>;
  resources: Resource[];
  slaTimestampName: string;
  formMethods: UseFormReturn<PolicySLAData>;
}) => {
  const hasElseBranch = branch?.result && branch?.result.value?.literal;
  const [editing, setEditing] = React.useState(false);

  const onDelete = () => {
    formMethods.setValue<"expressions.0.else_branch.result">(
      "expressions.0.else_branch.result",
      // @ts-expect-error - TS doesn't like setValue with null
      null,
    );
  };

  return (
    <div className="pl-9 flex items-center px-3 py-2 justify-between">
      <div className="flex items-center gap-1">
        <div className="text-sm-med w-16">Else</div>
        {editing ? (
          <ResultInput
            slaTimestampName={slaTimestampName}
            resources={resources}
            name="expressions.0.else_branch.result"
          />
        ) : hasElseBranch ? (
          <SLABinding
            binding={branch.result}
            scope={scope}
            slaTimestampName={slaTimestampName}
          />
        ) : (
          <EngineLiteralBadge noTooltip label="Don’t apply policy" />
        )}
      </div>
      <div className="flex items-center gap-1">
        {editing ? (
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Naked}
            icon={IconEnum.Tick}
            title=""
            onClick={() => setEditing(false)}
          />
        ) : (
          <>
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              icon={IconEnum.Edit}
              title=""
              onClick={() => setEditing(true)}
            />
            {hasElseBranch && (
              <Button
                analyticsTrackingId={null}
                theme={ButtonTheme.Naked}
                icon={IconEnum.Delete}
                title=""
                onClick={onDelete}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
