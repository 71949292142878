import { SelectOption } from "@incident-ui/Select/types";
import { GroupBase, StylesConfig } from "react-select";
import { getColor } from "src/utils/twConfig";

export const customStyles = (
  invalid: boolean,
  hasSuffixNode: boolean,
  isDisplayingPill = false,
): StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> => ({
  container: (base, state) => ({
    ...base,
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
    border: state.isFocused
      ? `1px solid ${getColor("slate", "900")}`
      : invalid
      ? `1px solid ${getColor("red", "500")}`
      : `1px solid ${getColor("slate", "100")}`,
    ":hover": {
      border: state.isFocused
        ? `1px solid ${getColor("slate", "900")}`
        : `1px solid ${getColor("slate", "100")}`,
      boxShadow: "0px 1px 4px 0px #0000001A",
    },
    ":disabled": {
      border: `1px solid ${getColor("slate", "200")}`,
    },
    borderRadius: "0.5rem",
    backgroundColor: state.isDisabled ? getColor("slate", "50") : "white",
    // If there's a suffixNode, we apply a big right padding to make space for it.
    // We currently assume that's always the lightning button - in future we may
    // need to make this vary depending on the size of the suffix node.
    // We can't put the node inside the Select as it inteferes with the focus
    // of the select, and randomly opens/closes the dropdown menu which is
    // rather sad.
    paddingRight: hasSuffixNode ? "36px" : base.paddingRight,
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    fontSize: "0.875rem",
    lineHeight: "1rem",
    flexGrow: 1,
    minHeight: "38px",
  }),
  input: (base) => ({
    ...base,
    color: getColor("slate", "900"),
    ":disabled": {
      color: getColor("slate", "500"),
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: isDisplayingPill ? "6px" : "6px 0 6px 10px",
    maxHeight: "150px",
    overflow: "auto",
    gap: "2px",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "8px",
    paddingRight: "12px",
    gap: "4px",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  menuPortal: (base) => ({ ...base, zIndex: 9995 }),
  menu: (base) => ({ ...base, borderRadius: "8px", padding: "4px" }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: 220,
  }),
  singleValue: (base) => ({
    ...base,
    margin: "0",
  }),
  option: (base, state) => ({
    ...base,
    display: "flex",
    gap: "8px",
    borderRadius: "4px",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    alignItems: "center",
    color: state.isDisabled
      ? getColor("slate", "500")
      : getColor("slate", "900"),
    backgroundColor: state.isDisabled
      ? getColor("slate", "50")
      : state.isFocused
      ? getColor("slate", "50")
      : "",
    ":hover": {
      backgroundColor: !state.isSelected ? getColor("slate", "50") : "",
    },
    ":focus": {
      backgroundColor: getColor("slate", "50"),
    },
    ":active": {
      backgroundColor: getColor("slate", "50"),
    },
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  }),
  placeholder: (base) => ({
    ...base,
    color: getColor("slate", "400"),
  }),
});
