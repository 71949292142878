/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface IncidentStatus
 */
export interface IncidentStatus {
    /**
     * 
     * @type {Date}
     * @memberof IncidentStatus
     */
    archived_at?: Date;
    /**
     * What category of status it is. All statuses apart from live (renamed in the app to Active) and learning (renamed in the app to Post-incident) are managed by incident.io and cannot be configured
     * @type {string}
     * @memberof IncidentStatus
     */
    category: IncidentStatusCategoryEnum;
    /**
     * 
     * @type {Date}
     * @memberof IncidentStatus
     */
    created_at: Date;
    /**
     * 
     * @type {TextDocument}
     * @memberof IncidentStatus
     */
    description: TextDocument;
    /**
     * Unique ID of this incident status
     * @type {string}
     * @memberof IncidentStatus
     */
    id: string;
    /**
     * ID of the incident lifecycle this status belongs to (active statuses only)
     * @type {string}
     * @memberof IncidentStatus
     */
    incident_lifecycle_id?: string;
    /**
     * Unique name of this status
     * @type {string}
     * @memberof IncidentStatus
     */
    name: string;
    /**
     * The ID of the post-incident flow this status is part of. This is only set for post-incident statuses.
     * @type {string}
     * @memberof IncidentStatus
     */
    post_incident_flow_id?: string;
    /**
     * Order of this incident status
     * @type {number}
     * @memberof IncidentStatus
     */
    rank: number;
    /**
     * 
     * @type {Date}
     * @memberof IncidentStatus
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum IncidentStatusCategoryEnum {
    Triage = 'triage',
    Declined = 'declined',
    Merged = 'merged',
    Canceled = 'canceled',
    Active = 'active',
    PostIncident = 'post-incident',
    Closed = 'closed',
    Paused = 'paused'
}

export function IncidentStatusFromJSON(json: any): IncidentStatus {
    return IncidentStatusFromJSONTyped(json, false);
}

export function IncidentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archived_at': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'category': json['category'],
        'created_at': (new Date(json['created_at'])),
        'description': TextDocumentFromJSON(json['description']),
        'id': json['id'],
        'incident_lifecycle_id': !exists(json, 'incident_lifecycle_id') ? undefined : json['incident_lifecycle_id'],
        'name': json['name'],
        'post_incident_flow_id': !exists(json, 'post_incident_flow_id') ? undefined : json['post_incident_flow_id'],
        'rank': json['rank'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentStatusToJSON(value?: IncidentStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archived_at': value.archived_at === undefined ? undefined : (value.archived_at.toISOString()),
        'category': value.category,
        'created_at': (value.created_at.toISOString()),
        'description': TextDocumentToJSON(value.description),
        'id': value.id,
        'incident_lifecycle_id': value.incident_lifecycle_id,
        'name': value.name,
        'post_incident_flow_id': value.post_incident_flow_id,
        'rank': value.rank,
        'updated_at': (value.updated_at.toISOString()),
    };
}

