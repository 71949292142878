/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlackQuickActionOption
 */
export interface SlackQuickActionOption {
    /**
     * Type of quick action
     * @type {string}
     * @memberof SlackQuickActionOption
     */
    action_type: SlackQuickActionOptionActionTypeEnum;
    /**
     * ID of the custom field when the quick action involves a custom field
     * @type {string}
     * @memberof SlackQuickActionOption
     */
    custom_field_id?: string;
    /**
     * Emoji on the quick action button
     * @type {string}
     * @memberof SlackQuickActionOption
     */
    emoji: string;
    /**
     * ID of the incident role when the quick action involves a role
     * @type {string}
     * @memberof SlackQuickActionOption
     */
    incident_role_id?: string;
    /**
     * Text on the quick action button
     * @type {string}
     * @memberof SlackQuickActionOption
     */
    text: string;
    /**
     * ID of the workflow when the quick action involves a workflow
     * @type {string}
     * @memberof SlackQuickActionOption
     */
    workflow_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum SlackQuickActionOptionActionTypeEnum {
    ViewRecap = 'quick_actions_view_recap',
    UpdateSummary = 'quick_actions_update_summary',
    ShareUpdate = 'quick_actions_share_update',
    ResolveIncident = 'quick_actions_resolve_incident',
    Escalate = 'quick_actions_escalate',
    TakeRole = 'quick_actions_take_role',
    JoinCall = 'quick_actions_join_call',
    UpdateStatusPage = 'quick_actions_update_status_page',
    SetCustomField = 'quick_actions_set_custom_field',
    SetFields = 'quick_actions_set_fields',
    ViewAllCommands = 'quick_actions_view_all_commands',
    CreateStream = 'quick_actions_create_stream',
    RunWorkflow = 'quick_actions_run_workflow'
}

export function SlackQuickActionOptionFromJSON(json: any): SlackQuickActionOption {
    return SlackQuickActionOptionFromJSONTyped(json, false);
}

export function SlackQuickActionOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackQuickActionOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_type': json['action_type'],
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'emoji': json['emoji'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'text': json['text'],
        'workflow_id': !exists(json, 'workflow_id') ? undefined : json['workflow_id'],
    };
}

export function SlackQuickActionOptionToJSON(value?: SlackQuickActionOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': value.action_type,
        'custom_field_id': value.custom_field_id,
        'emoji': value.emoji,
        'incident_role_id': value.incident_role_id,
        'text': value.text,
        'workflow_id': value.workflow_id,
    };
}

