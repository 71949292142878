import {
  ErrorResponse,
  IntegrationSettingsProviderEnum,
  IntegrationSettingsProviderEnum as ProviderEnum,
  IntegrationSettingsReconnectionReasonEnum,
  ProviderAndContexts,
  ProviderAndContextsContextsEnum,
  ProviderAndContextsProviderEnum,
} from "@incident-io/api";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import { IconEnum } from "@incident-ui";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";

import { IssueTemplateContextEnum } from "./useAllTemplates";

export type ExportIssueTracker = {
  displayName: string;
  icon: IconEnum;
  provider: ProviderEnum;
};

export const useEnabledExportIssueTrackers = (
  context: IssueTemplateContextEnum,
): {
  installedTrackers: IssueTrackerProviderEnum[];
  allTrackers: IssueTrackerProviderEnum[];
  isLoading: boolean;
  error?: ErrorResponse;
} => {
  // For V1, we hard-code the two supported issue trackers, and figure out if
  // they're available based on the integration status
  const { integrations } = useIntegrations();
  const isAvailable = (provider: IntegrationSettingsProviderEnum) => {
    const integration = integrations?.find(
      (integration) => integration.provider === provider,
    );
    if (!integration) return false;

    return (
      integration.installed &&
      integration.reconnection_reason ===
        IntegrationSettingsReconnectionReasonEnum.Empty
    );
  };

  // We fake the V1 response from the API here, as a little bridge to the V2 world.
  const v1Response: ProviderAndContexts[] = [
    {
      provider: ProviderAndContextsProviderEnum.Linear,
      contexts: [ProviderAndContextsContextsEnum.FollowUp],
      is_available: isAvailable(IntegrationSettingsProviderEnum.Linear),
    },
    {
      provider: ProviderAndContextsProviderEnum.Jira,
      contexts: [
        ProviderAndContextsContextsEnum.FollowUp,
        ProviderAndContextsContextsEnum.IncidentTicket,
      ],
      is_available: isAvailable(IntegrationSettingsProviderEnum.Jira),
    },
  ];

  // V2 trackers return their config dynamically from the API, allowing fancy things like feature-flagging them on the backend.
  const { data, isLoading, error } = useAPI(
    "issueTrackersV2ListSupportedProvidersAndContexts",
    undefined,
  );

  // Merge the v1 and v2 responses together, and filter to the relevant context
  const response = v1Response.concat(data?.providers_and_contexts ?? []);
  const relevant = response.filter(({ contexts }) =>
    contexts.includes(context as unknown as ProviderAndContextsContextsEnum),
  );

  const allTrackers = relevant.map(
    ({ provider }) => provider as unknown as IssueTrackerProviderEnum,
  );

  const installedTrackers = relevant
    .filter(({ is_available }) => is_available)
    .map(({ provider }) => provider as unknown as IssueTrackerProviderEnum);

  return {
    allTrackers,
    installedTrackers,
    isLoading,
    error,
  };
};
