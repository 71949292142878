import {
  InternalStatusPage,
  InternalStatusPageAutomationRule,
  InternalStatusPageStructure,
} from "@incident-io/api";
import { SecondaryNavDesktop } from "@incident-shared/layout/SecondaryNav";
import { GenericErrorMessage, IconEnum, Loader } from "@incident-ui";
import { captureException } from "@sentry/react";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { EmptyState } from "./EmptyState";
import { ComponentsList } from "./InternalStatusPageComponentsList";
import { IncidentsList } from "./InternalStatusPageIncidentsList";
import { OverviewTabIds } from "./tabs";

const OVERVIEW_TABS = [
  {
    label: "Happening now",
    slug: OverviewTabIds.Now,
    icon: IconEnum.Bolt,
  },
  {
    label: "Past events",
    slug: OverviewTabIds.Past,
    icon: IconEnum.Clock,
  },
];

export const OverviewTab = ({
  page,
  structure,
  overviewTab,
}: {
  page: InternalStatusPage;
  structure: InternalStatusPageStructure;
  overviewTab: OverviewTabIds | undefined;
}) => {
  const {
    data: { automation_rules: automationRules },
    isLoading: automationRulesLoading,
  } = useAPI(
    "internalStatusPageListAutomationRules",
    { id: page.id },
    {
      fallbackData: { automation_rules: [] },
    },
  );

  const {
    data: { internal_status_page_incidents: incidents },
    error,
    isLoading: incidentsLoading,
  } = useAPI(
    "internalStatusPageListIncidents",
    { internalStatusPageId: page.id },
    { fallbackData: { internal_status_page_incidents: [] } },
  );

  if (incidentsLoading || automationRulesLoading) {
    return <Loader />;
  }
  if (error) {
    console.error(error);
    captureException(error);
    return <GenericErrorMessage error={error} />;
  }

  if (incidents.length === 0 && isOnlyDefault(automationRules)) {
    return (
      <div
        className={tcx(
          "flex flex-col gap-8 w-full",
          // The left-padding is to balance out the component list on the right
          "2xl:flex-row 2xl:pl-[250px]",
        )}
      >
        <EmptyState page={page} />
        <ComponentsList page={page} structure={structure} />
      </div>
    );
  }

  return (
    <div className="flex items-start gap-10 h-full">
      <SecondaryNavDesktop
        applyPadding={false}
        pathPrefix={`status-pages/internal/${page.id}/overview`}
        itemGroups={[{ items: OVERVIEW_TABS }]}
      />
      <div className="flex flex-col 2xl:flex-row gap-8 w-full 2xl:w-[calc(100%-12rem)] h-full pb-24 overflow-y-auto">
        <IncidentsList
          overviewTab={overviewTab ?? OverviewTabIds.Now}
          page={page}
          structure={structure}
        />
        <ComponentsList page={page} structure={structure} />
      </div>
    </div>
  );
};

const isOnlyDefault = (rules: InternalStatusPageAutomationRule[]) => {
  if (rules.length > 1) return false;

  if (rules.length === 0) return true;

  const rule = rules[0];
  return (
    rule.only_if_affects_component &&
    rule.condition_groups.length > 0 &&
    rule.condition_groups[0].conditions.length === 0
  );
};
