import {
  Incident,
  PostmortemTemplate,
  PostmortemTemplateSection,
  PostmortemTemplateSectionResponse,
  PostmortemTemplateSectionTypeEnum,
} from "@incident-io/api";
import { GenericErrorMessage, LoadingBar } from "@incident-ui";
import React from "react";
import { useAPI } from "src/utils/swr";

import { PostmortemSummary } from "../body/Summary";
import { PostmortemCustomSection } from "./PostmortemCustomSection";
import { PostmortemFollowups } from "./PostmortemFollowups";
import { PostmortemTimeline } from "./PostmortemTimeline";

export const Postmortem = ({
  incident,
  templateId,
}: {
  incident: Incident;
  templateId: string;
}): React.ReactElement => {
  const {
    data: templateData,
    isLoading: loadingTemplate,
    error: errorTemplate,
  } = useAPI("postmortemsShowTemplate", {
    id: templateId,
    incidentId: incident.id, // We pass the incident here to resolve the variables in the template text.
  });

  // Also get responses for this template and incident.
  const {
    data: responseData,
    isLoading: loadingResponses,
    error: errorResponses,
  } = useAPI("postmortemsListTemplateResponses", {
    incidentId: incident.id,
    postmortemTemplateId: templateId,
  });

  // Get all custom fields.
  const { isLoading: loadingCustomFields } = useAPI(
    "customFieldsList",
    undefined,
    {
      fallbackData: { custom_fields: [] },
    },
  );

  if (loadingTemplate || loadingResponses || loadingCustomFields) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  if (errorTemplate) {
    return <GenericErrorMessage error={errorTemplate} />;
  }

  if (errorResponses) {
    return <GenericErrorMessage error={errorResponses} />;
  }

  if (!templateData) {
    return (
      <GenericErrorMessage
        description={"Could not find post-mortem template"}
      />
    );
  }

  if (!responseData) {
    return (
      <GenericErrorMessage
        description={"Could not fetch responses to the post-mortem"}
      />
    );
  }

  const template = templateData.postmortem_template;

  const orderedSections = template.sections
    .filter(
      (section) =>
        // we need to filter out key information as that is never rendered in the frontend!
        section.type !== PostmortemTemplateSectionTypeEnum.KeyInformation,
    )
    .sort((a, b) => a.rank - b.rank);
  // We also want to continue showing any existing responses for sections that have been deleted from
  // the template.
  const responsesForDeletedSections = responseData.responses.filter(
    (r) =>
      !orderedSections.some((s) => s.id === r.postmortem_template_section_id),
  );

  return (
    <>
      {orderedSections.map((section) => (
        <Section
          key={section.id}
          section={section}
          incident={incident}
          template={template}
          response={responseData?.responses.find(
            (r) => r.postmortem_template_section_id === section.id,
          )}
        />
      ))}
      {/* We'll show responses for deleted sections at the bottom, because we can no longer trust the rank
       associated with their section config. */}
      {responsesForDeletedSections &&
        responsesForDeletedSections.map((response) => {
          return (
            <Section
              key={response.id}
              incident={incident}
              template={template}
              section={response.section}
              response={response}
            />
          );
        })}
    </>
  );
};

type SectionProps = {
  section: PostmortemTemplateSection;
  incident: Incident;
  template: PostmortemTemplate;
  response?: PostmortemTemplateSectionResponse;
};

const Section = ({ section, incident, response, template }: SectionProps) => {
  const { data: customFieldData } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  if (section.type === "summary") {
    return (
      <PostmortemSummary id={section.id} key={section.id} incident={incident} />
    );
  }

  if (section.type === "timeline") {
    return (
      <PostmortemTimeline
        id={section.id}
        key={section.id}
        incident={incident}
      />
    );
  }

  if (section.type === "follow_ups") {
    return (
      <PostmortemFollowups
        id={section.id}
        key={section.id}
        incident={incident}
      />
    );
  }

  return (
    <PostmortemCustomSection
      key={section.id}
      incident={incident}
      templateId={template.id}
      section={section}
      existingResponse={response}
      allCustomFields={customFieldData.custom_fields}
    />
  );
};
