import { IncidentStatus, IncidentStatusCategoryEnum } from "@incident-io/api";

export type CategoryType = IncidentStatusCategoryEnum;
export const Category = IncidentStatusCategoryEnum;

// isCategory gives you a curried way to check if a status is of a category, which is
// super useful for things like:
// const closedStatuses = statuses.filter(isCategory(Category.Closed))
// const firstPostIncidentStatus = statuses.find(isCategory(Category.PostIncident))
export const isCategory =
  (category: CategoryType): ((s: IncidentStatus | undefined) => boolean) =>
  (s) =>
    s?.category === category;

export const isOneOfCategories =
  (categories: CategoryType[]): ((s: IncidentStatus) => boolean) =>
  (s) =>
    categories.includes(s.category);

export const firstStatusOfCategory = (
  statuses: IncidentStatus[],
  category: CategoryType,
): IncidentStatus => {
  const status = statuses.find((x) => x.category === category);
  if (!status) {
    throw new Error(`unreachable: found no status in category ${category}`);
  }
  return status;
};
