/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RBACRolesCreateRequestBody,
    RBACRolesCreateRequestBodyFromJSON,
    RBACRolesCreateRequestBodyToJSON,
    RBACRolesCreateResponseBody,
    RBACRolesCreateResponseBodyFromJSON,
    RBACRolesCreateResponseBodyToJSON,
    RBACRolesListPrivilegesResponseBody,
    RBACRolesListPrivilegesResponseBodyFromJSON,
    RBACRolesListPrivilegesResponseBodyToJSON,
    RBACRolesListResponseBody,
    RBACRolesListResponseBodyFromJSON,
    RBACRolesListResponseBodyToJSON,
    RBACRolesUpdateRequestBody,
    RBACRolesUpdateRequestBodyFromJSON,
    RBACRolesUpdateRequestBodyToJSON,
    RBACRolesUpdateResponseBody,
    RBACRolesUpdateResponseBodyFromJSON,
    RBACRolesUpdateResponseBodyToJSON,
} from '../models';

export interface RBACRolesCreateRequest {
    createRequestBody: RBACRolesCreateRequestBody;
}

export interface RBACRolesDestroyRequest {
    id: string;
}

export interface RBACRolesUpdateRequest {
    id: string;
    updateRequestBody: RBACRolesUpdateRequestBody;
}

/**
 * 
 */
export class RBACRolesApi extends runtime.BaseAPI {

    /**
     * Create a new custom RBAC role for assigning permissions to users
     * Create RBACRoles
     */
    async rBACRolesCreateRaw(requestParameters: RBACRolesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RBACRolesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling rBACRolesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/rbac_roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RBACRolesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RBACRolesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new custom RBAC role for assigning permissions to users
     * Create RBACRoles
     */
    async rBACRolesCreate(requestParameters: RBACRolesCreateRequest, initOverrides?: RequestInit): Promise<RBACRolesCreateResponseBody> {
        const response = await this.rBACRolesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a RBAC role
     * Destroy RBACRoles
     */
    async rBACRolesDestroyRaw(requestParameters: RBACRolesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rBACRolesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rbac_roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a RBAC role
     * Destroy RBACRoles
     */
    async rBACRolesDestroy(requestParameters: RBACRolesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.rBACRolesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List all RBAC roles for an organisation, with counts for base roles
     * List RBACRoles
     */
    async rBACRolesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RBACRolesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rbac_roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RBACRolesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all RBAC roles for an organisation, with counts for base roles
     * List RBACRoles
     */
    async rBACRolesList(initOverrides?: RequestInit): Promise<RBACRolesListResponseBody> {
        const response = await this.rBACRolesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all possible RBAC privileges
     * ListPrivileges RBACRoles
     */
    async rBACRolesListPrivilegesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RBACRolesListPrivilegesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rbac_roles/rbac_privileges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RBACRolesListPrivilegesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all possible RBAC privileges
     * ListPrivileges RBACRoles
     */
    async rBACRolesListPrivileges(initOverrides?: RequestInit): Promise<RBACRolesListPrivilegesResponseBody> {
        const response = await this.rBACRolesListPrivilegesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an existing RBAC role
     * Update RBACRoles
     */
    async rBACRolesUpdateRaw(requestParameters: RBACRolesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RBACRolesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rBACRolesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling rBACRolesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/rbac_roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RBACRolesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RBACRolesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing RBAC role
     * Update RBACRoles
     */
    async rBACRolesUpdate(requestParameters: RBACRolesUpdateRequest, initOverrides?: RequestInit): Promise<RBACRolesUpdateResponseBody> {
        const response = await this.rBACRolesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
