import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { ActorAvatar } from "@incident-ui/Avatar/ActorAvatar";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNotifications } from "src/components/notifications/useNotifications";
import { Notification } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Notifications.module.scss";

export const NotificationList = ({
  notifications,
  fetchMoreNotifications,
  loadingMoreNotifications,
  hasReturnedAllNotifications,
  closeSidebar,
}: {
  notifications: Notification[];
  fetchMoreNotifications: () => void;
  loadingMoreNotifications: boolean;
  hasReturnedAllNotifications: boolean;
  closeSidebar: () => void;
}): React.ReactElement => {
  if (!notifications.length) {
    return <NotificationsEmptyState />;
  }

  const unreadNotifications = notifications.filter((n) => !n.is_read);
  const readNotifications = notifications.filter((n) => n.is_read);

  return (
    <div>
      <NotificationGroup
        notifications={unreadNotifications}
        closeSidebar={closeSidebar}
        unread
      />
      <NotificationGroup
        notifications={readNotifications}
        closeSidebar={closeSidebar}
      />
      <div className="flex-center mt-2 mb-10">
        <LoadMoreNotificationsButton
          loadingMoreNotifications={loadingMoreNotifications}
          hasReturnedAllNotifications={hasReturnedAllNotifications}
          notifications={notifications}
          fetchMoreNotifications={fetchMoreNotifications}
        />
      </div>
    </div>
  );
};

const LoadMoreNotificationsButton = ({
  loadingMoreNotifications,
  hasReturnedAllNotifications,
  notifications,
  fetchMoreNotifications,
}: {
  notifications: Notification[];
  fetchMoreNotifications: () => void;
  loadingMoreNotifications: boolean;
  hasReturnedAllNotifications: boolean;
}) => {
  if (loadingMoreNotifications) {
    return <Loader />;
  }

  if (notifications.length < 20) {
    return <></>;
  }

  if (hasReturnedAllNotifications) {
    return (
      <div className="flex text-content-tertiary text-sm mt-1">
        No more notifications to show
      </div>
    );
  }

  return (
    <Button
      theme={ButtonTheme.Naked}
      onClick={() => fetchMoreNotifications()}
      analyticsTrackingId={"notifications-show-more"}
    >
      Show more notifications
      <Icon id={IconEnum.ChevronDown} />
    </Button>
  );
};

const NotificationGroup = ({
  notifications,
  closeSidebar,
  unread,
}: {
  notifications: Notification[];
  closeSidebar: () => void;
  unread?: boolean;
}): React.ReactElement => {
  if (!notifications.length) {
    return <></>;
  }
  return (
    <div
      className={tcx(
        unread && "bg-surface-secondary !pt-3 !mb-3 rounded-2 px-2 -mx-2",
      )}
    >
      {unread && (
        <div className="flex items-center pb-2">
          <Icon
            id={IconEnum.Sparkles}
            className="text-content-tertiary mr-1"
            size={IconSize.Large}
          />
          <p className="!text-xs font-semibold text-content-tertiary !tracking-widest">
            NEW
          </p>
        </div>
      )}
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          closeSidebar={closeSidebar}
        />
      ))}
    </div>
  );
};

export const NotificationItem = ({
  notification,
  closeSidebar,
}: {
  notification: Notification;
  closeSidebar: () => void;
}): React.ReactElement => {
  const [showMuteModal, setShowMuteModal] = useState<boolean>(false);

  return (
    <>
      {showMuteModal && notification.incident_id && (
        <MuteIncidentModal
          incidentId={notification.incident_id}
          onClose={() => setShowMuteModal(false)}
        />
      )}
      <div
        key={notification.id}
        className={tcx("px-1 py-1 flex", styles.notificationWrapper)}
      >
        <div className="flex-none">
          <ActorAvatar
            actor={notification.actor}
            size={IconSize.Large}
            className={tcx(styles.avatar, "!mr-2")}
          />
        </div>
        <div className="mt-0.5 !ml-1">
          <div className={tcx("pb-2", styles.notificationTitle)}>
            <Link
              to={notification.url}
              onClick={closeSidebar}
              analyticsTrackingId="notification"
              analyticsTrackingMetadata={{ notificationId: notification.id }}
              className="no-underline !text-content-primary"
            >
              <TemplatedTextDisplay
                value={notification.description}
                style={TemplatedTextDisplayStyle.Compact}
              />
            </Link>
          </div>
          <div>
            {notification.content && (
              <>
                <TemplatedTextDisplay
                  style={TemplatedTextDisplayStyle.Compact}
                  value={notification.content}
                  truncatedText={true}
                  className={tcx(
                    "text-slate-600 border-l-2  border-stroke px-2",
                    styles.notificationContent,
                  )}
                />
              </>
            )}
            <div className="flex items-center">
              <p className="text-content-tertiary py-2">
                {formatOccurredAt(notification.occurred_at)}
              </p>
              {notification.incident_id ? (
                !notification.incident_is_muted ? (
                  <Button
                    icon={IconEnum.Mute}
                    theme={ButtonTheme.Naked}
                    className={tcx("ml-2", styles.muteSection)}
                    analyticsTrackingId="mute-incident-notifications"
                    onClick={() => setShowMuteModal(true)}
                  >
                    Mute incident
                  </Button>
                ) : (
                  <div
                    className={tcx(
                      "text-content-tertiary ml-2 flex",
                      styles.muteSection,
                    )}
                  >
                    <Icon id={IconEnum.Mute} size={IconSize.Medium} />
                    <span className="ml-1">Incident muted</span>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MuteIncidentModal = ({
  incidentId,
  onClose,
}: {
  incidentId: string;
  onClose: () => void;
}) => {
  const { data: incidentData, isLoading: loadingIncident } = useAPI(
    "incidentsShow",
    { id: incidentId },
  );
  const incident = incidentData?.incident;

  const [muting, setMuting] = useState(false);
  const showToast = useToast();

  const { muteIncident: doMute } = useNotifications();

  const muteIncident = async () => {
    if (muting) {
      return;
    }
    setMuting(true);
    await doMute(incidentId);
    setMuting(false);
    showToast({
      theme: ToastTheme.Success,
      title: (
        <div>
          <p>Successfully muted incident</p>
          <p className="font-semibold">
            {incident?.reference} {incident?.name}
          </p>
        </div>
      ),
    });
    onClose();
  };

  return (
    <Modal
      isOpen
      title="Mute incident"
      analyticsTrackingId="mute-incident-notifications"
      disableQuickClose={false}
      onClose={onClose}
      loading={loadingIncident}
    >
      <ModalContent className="space-y-4 !p-6">
        <p>
          Please confirm you&apos;d like to mute notifications for{" "}
          <span className="font-semibold">
            {incident?.reference}: {incident?.name}
          </span>
          .
        </p>
        <p>
          You&apos;ll no longer receive notifications relating to this incident.
        </p>
      </ModalContent>

      <ModalFooter
        analyticsTrackingId="mute-incident-notifications"
        onConfirm={muteIncident}
        onClose={onClose}
        confirmButtonType="button"
        confirmButtonText={"Confirm"}
        cancelButtonText={"Cancel"}
        saving={muting}
      />
    </Modal>
  );
};

const NotificationsEmptyState = (): React.ReactElement => {
  return (
    <div className="bg-surface-secondary rounded-2 p-8 m-4 flex flex-col items-center ">
      <Icon id={IconEnum.Sparkles} className="text-slate-600 mb-2" />
      <p className="font-medium text-slate-600 mb-2">No notifications</p>
      <p className="text-slate-600 text-center">
        {`Next time you are an incident responder we'll keep you up to date about your peers' activity in that incident, such as responding to comments on the timeline.`}
      </p>
    </div>
  );
};

const formatOccurredAt = (occurredAt: Date) => {
  return format(occurredAt, "dd MMM h:mmaaa");
};
