import cx from "classnames";

import styles from "./AppWrapper.module.scss";

export const AppWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cx(
        className,
        /* The designs specify a max-width of 700px. We need to add 2px to that for
         * borders, and 16px for the x-padding (8px each side) */
        "container px-4 py-2 space-y-4 mx-auto md:px-2 md:max-w-[718px] md:py-4 md:space-y-6 flex-1",
        styles.withSelection,
      )}
    >
      {children}
    </div>
  );
};
