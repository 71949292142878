/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderAndContexts,
    ProviderAndContextsFromJSON,
    ProviderAndContextsFromJSONTyped,
    ProviderAndContextsToJSON,
} from './ProviderAndContexts';

/**
 * 
 * @export
 * @interface IssueTrackersV2ListSupportedProvidersAndContextsResponseBody
 */
export interface IssueTrackersV2ListSupportedProvidersAndContextsResponseBody {
    /**
     * List of the available providers and contexts for the current organisation
     * @type {Array<ProviderAndContexts>}
     * @memberof IssueTrackersV2ListSupportedProvidersAndContextsResponseBody
     */
    providers_and_contexts: Array<ProviderAndContexts>;
}

export function IssueTrackersV2ListSupportedProvidersAndContextsResponseBodyFromJSON(json: any): IssueTrackersV2ListSupportedProvidersAndContextsResponseBody {
    return IssueTrackersV2ListSupportedProvidersAndContextsResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersV2ListSupportedProvidersAndContextsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersV2ListSupportedProvidersAndContextsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providers_and_contexts': ((json['providers_and_contexts'] as Array<any>).map(ProviderAndContextsFromJSON)),
    };
}

export function IssueTrackersV2ListSupportedProvidersAndContextsResponseBodyToJSON(value?: IssueTrackersV2ListSupportedProvidersAndContextsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providers_and_contexts': ((value.providers_and_contexts as Array<any>).map(ProviderAndContextsToJSON)),
    };
}

