import { differenceInSeconds } from "date-fns";
import React, { useState } from "react";
import { DurationEnum, formatDurationInSeconds } from "src/utils/datetime";
import { useInterval } from "src/utils/utils";

import { LocalDateTime } from "./LocalDateTime";

export type LocalRelativeDateTimeProps = {
  /** Whether to show the little clock icon next to the timestamp */
  showIcon?: boolean;
  date: Date;
  className?: string;
};

export const LocalRelativeDateTime = ({
  showIcon,
  date,
  className,
}: LocalRelativeDateTimeProps): React.ReactElement => {
  const [now, setNow] = useState(new Date());
  useInterval(() => setNow(new Date()), 15000);
  const diff = differenceInSeconds(now, date);
  const durationString = formatDurationInSeconds(diff, 1, DurationEnum.minutes);
  return (
    <LocalDateTime showIcon={showIcon} timestamp={date}>
      <span className={className}>
        {diff === 0
          ? "now"
          : durationString
          ? `${durationString} ago`
          : "less than a minute ago"}
      </span>
    </LocalDateTime>
  );
};
