/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EscalatedTimelineUserNotification
 */
export interface EscalatedTimelineUserNotification {
    /**
     * Whether the escalation was made during a live call
     * @type {boolean}
     * @memberof EscalatedTimelineUserNotification
     */
    is_live_call: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EscalatedTimelineUserNotification
     */
    methods: Array<EscalatedTimelineUserNotificationMethodsEnum>;
    /**
     * 
     * @type {User}
     * @memberof EscalatedTimelineUserNotification
     */
    user: User;
}

/**
* @export
* @enum {string}
*/
export enum EscalatedTimelineUserNotificationMethodsEnum {
    App = 'app',
    Email = 'email',
    MicrosoftTeams = 'microsoft_teams',
    Phone = 'phone',
    Slack = 'slack'
}

export function EscalatedTimelineUserNotificationFromJSON(json: any): EscalatedTimelineUserNotification {
    return EscalatedTimelineUserNotificationFromJSONTyped(json, false);
}

export function EscalatedTimelineUserNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalatedTimelineUserNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_live_call': json['is_live_call'],
        'methods': json['methods'],
        'user': UserFromJSON(json['user']),
    };
}

export function EscalatedTimelineUserNotificationToJSON(value?: EscalatedTimelineUserNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_live_call': value.is_live_call,
        'methods': value.methods,
        'user': UserToJSON(value.user),
    };
}

