import type { SVGProps } from "react";
import * as React from "react";
const SvgSlack = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#E01E5A"
      d="M5.373 12.108c0 .93-.75 1.68-1.68 1.68-.93 0-1.68-.75-1.68-1.68 0-.93.75-1.68 1.68-1.68h1.68v1.68Zm.84 0c0-.93.751-1.68 1.68-1.68.93 0 1.68.75 1.68 1.68v4.2c0 .93-.75 1.68-1.68 1.68-.929 0-1.68-.75-1.68-1.68v-4.2Z"
    />
    <path
      fill="#36C5F0"
      d="M7.894 5.36c-.93 0-1.68-.75-1.68-1.68 0-.929.75-1.68 1.68-1.68.929 0 1.68.751 1.68 1.68v1.68h-1.68Zm0 .853c.929 0 1.68.751 1.68 1.68 0 .93-.751 1.681-1.68 1.681H3.68c-.929 0-1.68-.751-1.68-1.68 0-.93.751-1.68 1.68-1.68h4.214Z"
    />
    <path
      fill="#2EB67D"
      d="M14.627 7.894c0-.93.75-1.68 1.68-1.68.93 0 1.68.75 1.68 1.68 0 .929-.75 1.68-1.68 1.68h-1.68v-1.68Zm-.84 0c0 .929-.751 1.68-1.68 1.68-.93 0-1.681-.751-1.681-1.68V3.68c0-.929.751-1.68 1.68-1.68.93 0 1.68.751 1.68 1.68v4.214Z"
    />
    <path
      fill="#ECB22E"
      d="M12.106 14.628c.93 0 1.68.751 1.68 1.68 0 .93-.75 1.68-1.68 1.68-.929 0-1.68-.75-1.68-1.68v-1.68h1.68Zm0-.84c-.929 0-1.68-.75-1.68-1.68 0-.93.751-1.68 1.68-1.68h4.214c.929 0 1.68.75 1.68 1.68 0 .93-.751 1.68-1.68 1.68h-4.214Z"
    />
  </svg>
);
export default SvgSlack;
