/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFormsPreviewEscalateFormRequestBody
 */
export interface IncidentFormsPreviewEscalateFormRequestBody {
    /**
     * The bindings for this escalation element
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IncidentFormsPreviewEscalateFormRequestBody
     */
    element_bindings: { [key: string]: Array<string>; };
}

export function IncidentFormsPreviewEscalateFormRequestBodyFromJSON(json: any): IncidentFormsPreviewEscalateFormRequestBody {
    return IncidentFormsPreviewEscalateFormRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsPreviewEscalateFormRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsPreviewEscalateFormRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'element_bindings': json['element_bindings'],
    };
}

export function IncidentFormsPreviewEscalateFormRequestBodyToJSON(value?: IncidentFormsPreviewEscalateFormRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'element_bindings': value.element_bindings,
    };
}

