export enum IntegrationCategory {
  AlertSource = "alert-source",
  Escalation = "escalation",
  IssueTracker = "issuetracker",
  Document = "document",
  Collaboration = "collaborate",
  Communication = "communication",
  Compliance = "compliance",
  CustomerSupport = "customersupport",
}

type CategoryConfig = {
  label: string;
  category: IntegrationCategory;
};

export const CATEGORY_CONFIG: { [key in IntegrationCategory]: CategoryConfig } =
  {
    [IntegrationCategory.AlertSource]: {
      category: IntegrationCategory.AlertSource,
      label: "Alert Source",
    },
    [IntegrationCategory.Escalation]: {
      category: IntegrationCategory.Escalation,
      label: "Escalate",
    },
    [IntegrationCategory.IssueTracker]: {
      label: "Issue tracker",
      category: IntegrationCategory.IssueTracker,
    },
    [IntegrationCategory.Collaboration]: {
      label: "Collaborate",
      category: IntegrationCategory.Collaboration,
    },
    [IntegrationCategory.Document]: {
      label: "Document",
      category: IntegrationCategory.Document,
    },
    [IntegrationCategory.Communication]: {
      label: "Communicate",
      category: IntegrationCategory.Communication,
    },
    [IntegrationCategory.Compliance]: {
      label: "Compliance & Security",
      category: IntegrationCategory.Compliance,
    },
    [IntegrationCategory.CustomerSupport]: {
      label: "Customer Support",
      category: IntegrationCategory.CustomerSupport,
    },
  };
