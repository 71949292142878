import type { SVGProps } from "react";
import * as React from "react";
const SvgFolderXMark = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.716 16.692.403-.404-.403-.403a2.2 2.2 0 0 1-.65-1.569c0-.592.23-1.15.65-1.569.419-.419.976-.65 1.569-.65.592 0 1.15.231 1.569.65l.403.404.403-.404a2.21 2.21 0 0 1 .83-.515V7.413a2.715 2.715 0 0 0-2.712-2.712H9.82l-.372-.464a2.7 2.7 0 0 0-2.116-1.015H5.41a2.715 2.715 0 0 0-2.711 2.712v8.382a2.714 2.714 0 0 0 2.711 2.712h7.03c.08-.12.173-.232.277-.336Zm-8.54-9.216V5.934c0-.68.554-1.233 1.233-1.233h1.924c.376 0 .727.168.962.461l.594.742a.74.74 0 0 0 .577.277h5.312c.68 0 1.232.553 1.232 1.232v.063a2.683 2.683 0 0 0-1.232-.31H5.409a2.7 2.7 0 0 0-1.232.31Z"
    />
    <path
      fill="currentColor"
      d="M18.752 13.793a.74.74 0 0 0-1.047 0l-1.45 1.45-1.449-1.45a.74.74 0 0 0-1.046 1.047l1.45 1.45-1.45 1.449a.74.74 0 0 0 1.045 1.046l1.45-1.45 1.45 1.45a.738.738 0 0 0 1.045 0 .74.74 0 0 0 0-1.046l-1.45-1.45 1.45-1.45a.74.74 0 0 0 0-1.046h.002Z"
    />
  </svg>
);
export default SvgFolderXMark;
