/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingsUpdatePrivateStreamsSettingsRequestBody
 */
export interface SettingsUpdatePrivateStreamsSettingsRequestBody {
    /**
     * 
     * @type {boolean}
     * @memberof SettingsUpdatePrivateStreamsSettingsRequestBody
     */
    private_streams_enabled: boolean;
}

export function SettingsUpdatePrivateStreamsSettingsRequestBodyFromJSON(json: any): SettingsUpdatePrivateStreamsSettingsRequestBody {
    return SettingsUpdatePrivateStreamsSettingsRequestBodyFromJSONTyped(json, false);
}

export function SettingsUpdatePrivateStreamsSettingsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdatePrivateStreamsSettingsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'private_streams_enabled': json['private_streams_enabled'],
    };
}

export function SettingsUpdatePrivateStreamsSettingsRequestBodyToJSON(value?: SettingsUpdatePrivateStreamsSettingsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'private_streams_enabled': value.private_streams_enabled,
    };
}

