/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationTimelineItemContent,
    EscalationTimelineItemContentFromJSON,
    EscalationTimelineItemContentFromJSONTyped,
    EscalationTimelineItemContentToJSON,
} from './EscalationTimelineItemContent';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface EscalationTimelineItem
 */
export interface EscalationTimelineItem {
    /**
     * 
     * @type {EscalationTimelineItemContent}
     * @memberof EscalationTimelineItem
     */
    content: EscalationTimelineItemContent;
    /**
     * 
     * @type {TextDocument}
     * @memberof EscalationTimelineItem
     */
    description?: TextDocument;
    /**
     * ID of the Escalation
     * @type {string}
     * @memberof EscalationTimelineItem
     */
    escalation_id: string;
    /**
     * Unique identifier of the timeline item
     * @type {string}
     * @memberof EscalationTimelineItem
     */
    id: string;
    /**
     * Timestamp of when this timeline item occurred
     * @type {Date}
     * @memberof EscalationTimelineItem
     */
    timestamp: Date;
    /**
     * Title of the timeline item
     * @type {string}
     * @memberof EscalationTimelineItem
     */
    title: string;
    /**
     * Type of the timeline item
     * @type {string}
     * @memberof EscalationTimelineItem
     */
    type: EscalationTimelineItemTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationTimelineItemTypeEnum {
    Created = 'created',
    Triggered = 'triggered',
    ConditionsEvaluated = 'conditions_evaluated',
    EscalatedLevel = 'escalated_level',
    EscalatedChannel = 'escalated_channel',
    Repeated = 'repeated',
    Acknowledged = 'acknowledged',
    Cancelled = 'cancelled',
    Expired = 'expired',
    Nacked = 'nacked',
    IncomingCall = 'incoming_call',
    IncomingCallEnded = 'incoming_call_ended',
    CalledUser = 'called_user',
    FailedToCallUser = 'failed_to_call_user'
}

export function EscalationTimelineItemFromJSON(json: any): EscalationTimelineItem {
    return EscalationTimelineItemFromJSONTyped(json, false);
}

export function EscalationTimelineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationTimelineItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': EscalationTimelineItemContentFromJSON(json['content']),
        'description': !exists(json, 'description') ? undefined : TextDocumentFromJSON(json['description']),
        'escalation_id': json['escalation_id'],
        'id': json['id'],
        'timestamp': (new Date(json['timestamp'])),
        'title': json['title'],
        'type': json['type'],
    };
}

export function EscalationTimelineItemToJSON(value?: EscalationTimelineItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': EscalationTimelineItemContentToJSON(value.content),
        'description': TextDocumentToJSON(value.description),
        'escalation_id': value.escalation_id,
        'id': value.id,
        'timestamp': (value.timestamp.toISOString()),
        'title': value.title,
        'type': value.type,
    };
}

