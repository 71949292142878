import type { SVGProps } from "react";
import * as React from "react";
const SvgCloudflare = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#F38020"
      d="M13.006 14.014c.097-.42.06-.804-.103-1.092a.854.854 0 0 0-.706-.431l-5.776-.093c-.034 0-.068-.024-.087-.059a.195.195 0 0 1-.013-.128c.019-.07.075-.124.135-.128l5.828-.093c.691-.04 1.442-.735 1.704-1.586l.332-1.08a.266.266 0 0 0 .01-.14c-.376-2.11-1.893-3.684-3.703-3.684-1.67 0-3.086 1.34-3.593 3.202-.328-.303-.747-.466-1.197-.412-.804.097-1.445.898-1.526 1.897-.019.256-.003.509.043.742C3.044 10.975 2 12.304 2 13.944c0 .144.01.292.025.435.01.07.056.121.113.121h10.659c.06 0 .119-.054.134-.128l.075-.358Zm1.839-4.612c-.05 0-.106 0-.16.007-.037 0-.068.035-.084.082l-.225.971c-.097.42-.063.805.103 1.092.15.26.404.412.71.432l1.229.093c.037 0 .072.023.087.058.02.035.022.09.016.128-.019.07-.075.125-.138.129l-1.282.093c-.694.039-1.438.735-1.704 1.585l-.094.296c-.018.058.016.117.066.117h4.403c.05 0 .097-.04.112-.105.075-.339.116-.696.116-1.061 0-2.157-1.416-3.917-3.158-3.917"
    />
  </svg>
);
export default SvgCloudflare;
