import { Priority } from "@incident-io/api";
import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useAPI } from "src/utils/swr";

enum AlertPriorityMode {
  Highest = "highest",
  Medium = "medium",
  Lowest = "lowest",
}

function getPriorityMode(priority: Priority, count: number) {
  if (priority.rank === 0) {
    return AlertPriorityMode.Highest;
  }
  if (priority.rank === count - 1) {
    return AlertPriorityMode.Lowest;
  }
  return AlertPriorityMode.Medium;
}

function getIconForPriorityMode(mode: AlertPriorityMode): IconEnum {
  switch (mode) {
    case AlertPriorityMode.Highest:
      return IconEnum.HighAlertPriority;
    case AlertPriorityMode.Medium:
      return IconEnum.MediumAlertPriority;
    case AlertPriorityMode.Lowest:
      return IconEnum.LowAlertPriority;
    default:
      throw new Error(`Unknown priority mode: ${mode}`);
  }
}

export const AlertPriorityBadge = ({
  priority,
  size,
}: {
  priority: Priority;
  size?: BadgeSize;
}) => {
  const { data, isLoading, error } = useAPI("alertsListPriorities", {
    includeArchived: true,
  });

  if (isLoading || error || !data) {
    return null;
  }

  const totalCount = data.priorities.filter((p) => !p.archived_at).length;

  const mode = getPriorityMode(priority, totalCount);

  return (
    <Button
      theme={ButtonTheme.Tertiary}
      href={"/alerts/configuration/priorities"}
      icon={getIconForPriorityMode(mode)}
      size={size || BadgeSize.Medium}
      analyticsTrackingId="alerts-view-priority"
    >
      {priority.name}
    </Button>
  );
};
