import {
  ImpersonationSession,
  ImpersonationSessionFromJSON,
  ImpersonationSessionToJSON,
} from "@incident-io/api";
import { isBefore } from "date-fns";
import { useCallback, useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

export const useStoredSession = ({ orgSlug }: { orgSlug: string }) => {
  const key = `inc-imp-session-${orgSlug}`;
  const [session, setSession_] = useLocalStorage<
    ImpersonationSession | undefined
  >(key, undefined, { serializer, deserializer });

  const setSession = useCallback(
    (newSession: ImpersonationSession | undefined) => {
      setSession_(newSession);
      if (newSession === undefined) {
        // The hook doesn't remove the entire key if the value is undefined, but I
        // want that!
        window.localStorage.removeItem(key);
      }
    },
    [setSession_, key],
  );

  useEffect(() => {
    if (session && isBefore(session.expires_at, new Date())) {
      // If the session has expired, clear it
      setSession(undefined);
    }
  }, [session, setSession, key]);

  return {
    session,
    setSession,
  };
};

const serializer = (value: ImpersonationSession | undefined) =>
  value ? JSON.stringify(ImpersonationSessionToJSON(value)) : "";

const deserializer = (value: string | undefined) =>
  value ? ImpersonationSessionFromJSON(JSON.parse(value)) : undefined;
