/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleEntry
 */
export interface ScheduleEntry {
    /**
     * 
     * @type {Date}
     * @memberof ScheduleEntry
     */
    end_at: Date;
    /**
     * Unique internal ID of the schedule entry
     * @type {string}
     * @memberof ScheduleEntry
     */
    entry_id?: string;
    /**
     * The user this entry is assigned to, with a special value of NOBODY for native schedules
     * @type {string}
     * @memberof ScheduleEntry
     */
    external_user_id: string;
    /**
     * If present, the layer this entry applies to on the rota
     * @type {string}
     * @memberof ScheduleEntry
     */
    layer_id?: string;
    /**
     * If present, the ID of the override entry
     * @type {string}
     * @memberof ScheduleEntry
     */
    override_id?: string;
    /**
     * If present, the rotation this entry applies to on the schedule
     * @type {string}
     * @memberof ScheduleEntry
     */
    rotation_id?: string;
    /**
     * The ID of the schedule this entry is part of
     * @type {string}
     * @memberof ScheduleEntry
     */
    schedule_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleEntry
     */
    start_at: Date;
}

export function ScheduleEntryFromJSON(json: any): ScheduleEntry {
    return ScheduleEntryFromJSONTyped(json, false);
}

export function ScheduleEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': (new Date(json['end_at'])),
        'entry_id': !exists(json, 'entry_id') ? undefined : json['entry_id'],
        'external_user_id': json['external_user_id'],
        'layer_id': !exists(json, 'layer_id') ? undefined : json['layer_id'],
        'override_id': !exists(json, 'override_id') ? undefined : json['override_id'],
        'rotation_id': !exists(json, 'rotation_id') ? undefined : json['rotation_id'],
        'schedule_id': !exists(json, 'schedule_id') ? undefined : json['schedule_id'],
        'start_at': (new Date(json['start_at'])),
    };
}

export function ScheduleEntryToJSON(value?: ScheduleEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': (value.end_at.toISOString()),
        'entry_id': value.entry_id,
        'external_user_id': value.external_user_id,
        'layer_id': value.layer_id,
        'override_id': value.override_id,
        'rotation_id': value.rotation_id,
        'schedule_id': value.schedule_id,
        'start_at': (value.start_at.toISOString()),
    };
}

