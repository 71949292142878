import type { SVGProps } from "react";
import * as React from "react";
const SvgRhombus = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.195 6.033a27.571 27.571 0 0 1-2.546-3.66c-.268-.463-1.029-.463-1.297 0a27.656 27.656 0 0 1-2.546 3.66 29.424 29.424 0 0 1-3.301 3.403.752.752 0 0 0 0 1.126 29.404 29.404 0 0 1 3.3 3.403 27.571 27.571 0 0 1 2.546 3.66.75.75 0 0 0 1.296 0c.737-1.27 1.594-2.5 2.546-3.659a29.424 29.424 0 0 1 3.301-3.404.751.751 0 0 0 0-1.126 29.395 29.395 0 0 1-3.299-3.403Z"
    />
  </svg>
);
export default SvgRhombus;
