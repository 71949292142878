import { RefObject } from "react";
import TurndownService from "turndown";

import { linkPlugin, tablesPlugin } from "./turndown-plugins";

export const copyDocAsHTML = async (
  docRef: RefObject<HTMLDivElement>,
  buttonRef: RefObject<HTMLButtonElement>,
): Promise<boolean> => {
  let didSucceed = false;

  try {
    try {
      await copyHTMLDirect(docRef);
      didSucceed = true;
    } catch (err) {
      console.error("Error using the navigator.clipboard API", err);
    }

    // Although it's deprecated, the document.execCommand API is a bit more
    // useful: it copies the styling better. At this point, the clipboard should
    // have _something useful_ on it, so this is best-effort.
    if (docRef.current == null) {
      throw new Error(
        "Unable to copy post-mortem as couldn't find post-mortem element",
      );
    }
    if (copyBySelection(docRef)) {
      didSucceed = true;
    } else {
      console.warn(
        "Could not copy using execCommand: post-mortem formatting won't be as nice as in Chrome",
      );
    }
  } finally {
    // Bring the focus back to the button that was clicked, in case we've
    // screwed with the focus/selection!
    if (buttonRef.current) buttonRef.current.focus();
  }
  return didSucceed;
};

export const copyDocAsMarkdown = async (
  docRef: RefObject<HTMLDivElement>,
  buttonRef: RefObject<HTMLButtonElement>,
): Promise<boolean> => {
  let didSucceed = false;
  try {
    try {
      await copyMarkdownDirect(docRef);
      didSucceed = true;
    } catch (err) {
      console.error("Error using the navigator.clipboard API", err);
    }
    // We can't fall back to the execCommand API here, because we're copying
    // text, not parts of the document.
  } finally {
    // Bring the focus back to the button that was clicked, in case we've
    // screwed with the focus/selection!
    buttonRef.current?.focus();
  }
  return didSucceed;
};

// The navigator.clipboard.write API must be called from within a click
// handler, otherwise Safari and Firefox will not work. This is fine, but
// means this code has to be really really careful.
const copyHTMLDirect = async (
  docRef: RefObject<HTMLDivElement>,
): Promise<void> => {
  const blobPromise = Promise.resolve().then(() => {
    const doc = docRef.current;
    if (doc == null) {
      throw new Error(
        "Unable to copy post-mortem as couldn't find post-mortem element",
      );
    }

    return new Blob([doc.innerHTML], { type: "text/html" });
  });

  const item = new ClipboardItem({
    "text/html": blobPromise,
  });

  // This will raise an error if something goes wrong.
  await navigator.clipboard.write([item]);
};

const copyMarkdownDirect = async (
  docRef: RefObject<HTMLDivElement>,
): Promise<void> => {
  const blobPromise = Promise.resolve().then(() => {
    const doc = docRef.current;
    if (doc == null) {
      throw new Error(
        "Unable to copy post-mortem as couldn't find post-mortem element",
      );
    }

    const turndown = new TurndownService();
    turndown.use([tablesPlugin, linkPlugin]);
    turndown.addRule("strikethrough", {
      filter: ["del"],
      replacement: function (content) {
        return "~~" + content + "~~";
      },
    });
    const markdown = turndown.turndown(doc);

    return new Blob([markdown], { type: "text/plain" });
  });

  // hack for firefox clipboard API weirdness
  // taken from https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
  if (navigator.clipboard.write) {
    // NOTE: Safari locks down the clipboard API to only work when triggered
    //   by a direct user interaction. You can't use it async in a promise.
    //   But! You can wrap the promise in a ClipboardItem, and give that to
    //   the clipboard API.
    //   Found this on https://developer.apple.com/forums/thread/691873
    const item = new ClipboardItem({
      "text/plain": blobPromise,
    });
    await navigator.clipboard.write([item]);
  } else {
    // this is potentially slow but we need to resolve the promise in await
    // so our document ref is correct
    const text = await Promise.resolve(blobPromise);
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    //   Good news is that other than Safari, Firefox does not care about
    //   Clipboard API being used async in a Promise.
    Promise.resolve(text).then((blob) =>
      blob.text().then((text) => {
        navigator.clipboard.writeText(text);
      }),
    );
  }
};

const copyBySelection = (docRef: RefObject<HTMLDivElement>): boolean => {
  const doc = docRef.current;
  if (doc == null) {
    throw new Error("Unable to copy contents as couldn't find HTML element");
  }

  const sel = window.getSelection();
  if (!sel) {
    console.error("Oops, unable to get current window selection");
    return false;
  }

  // Clear all ranges, ensuring we don't fight previously selected content
  sel.removeAllRanges();

  const range = document.createRange();
  try {
    range.selectNodeContents(doc);
    sel.addRange(range);
  } catch (e) {
    range.selectNode(doc);
    sel.addRange(range);
  }

  return document.execCommand("copy");
};
