import { Identity } from "@incident-io/api";
import { Button, ButtonProps, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { StackedList } from "@incident-ui/StackedList/StackedList";
import { Txt } from "@incident-ui/Txt/Txt";
import * as ReactTooltip from "@radix-ui/react-tooltip";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { format as formatDate, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useRef } from "react";
import styles from "src/components/@ui/LocalDateTime/LocalDateTime.module.scss";
import { formatTimestampLocale } from "src/utils/datetime";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

import { useIdentity } from "../../../contexts/IdentityContext";

export type LocalDateTimeProps = {
  /** The time to display */
  timestamp: string | Date;
  /** The format to display the date/time in on the page (for the tooltip trigger). Defaults to "dd/mm/yyyy HH:mm" e.g. 01/01/2020 23:49 */
  format?: string;
  className?: string;
  /** Whether or not to show the little clock icon next to the timestamp */
  showIcon?: boolean;
  /** optionally, pass in children for the text of the button */
  children?: React.ReactNode;
  // Whether or not to add the weekday to the date string
  addWeekday?: boolean;
};

/**
 * A handy tooltip that displays a timestamp in both UTC and local time.
 * We show timestamps in UTC around the app, so this provides it in local time too.
 */
export function LocalDateTime({
  timestamp,
  format,
  className,
  showIcon,
  children,
  addWeekday,
}: LocalDateTimeProps): React.ReactElement {
  const { sessions, identity } = useIdentity();
  const isLoggedInToStaff = sessions?.some(
    (session) => session.organisation_is_staff,
  );

  // Parse the timestamp as the local date, in UTC.
  const utcDate =
    typeof timestamp === "string" ? parseISO(timestamp) : timestamp;

  // The timezone offset tells us how many minutes UTC is ahead or behind the time in the local timezone.
  // It should take into account any daylight savings that were in effect at the time
  const timezoneOffset = utcDate.getTimezoneOffset();

  const hoursDifference = timezoneOffset / 60;

  // Show the time difference in hrs between the user's local time and UTC.
  // This will be different depending on whether the timestamp is during daylight savings or not
  // e.g. for events that happen in BST, it'll say UTC+1.
  const timezoneDifferenceString =
    hoursDifference < 0
      ? `UTC+${hoursDifference.toString().substring(1)}`
      : hoursDifference === 0
      ? "UTC+0"
      : `UTC-${hoursDifference}`;

  const localTimeLocaleString = formatTimestampLocale({
    timestamp: utcDate,
    dateStyle: "short",
    timeStyle: "short",
    addWeekday,
  });
  const utcLocaleString = formatInTimeZone(
    utcDate,
    "UTC",
    "yyyy-MM-dd'T'HH:mm:ss'Z'",
  );

  const iconProps: Pick<ButtonProps, "icon" | "iconProps"> = showIcon
    ? {
        icon: IconEnum.Clock,
        iconProps: {
          className: "mobile-hidden",
        },
      }
    : {};

  const displayTimestamp =
    format != null
      ? formatDate(new Date(timestamp), format)
      : localTimeLocaleString;

  const { hasCopied, copyTextToClipboard } = useClipboard();

  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <ReactTooltip.Provider delayDuration={200}>
      <ReactTooltip.Root>
        <ReactTooltip.Trigger
          asChild
          ref={triggerRef}
          onClick={(e) => {
            e.preventDefault();
            copyTextToClipboard(utcLocaleString);
          }}
        >
          <Button
            className={tcx("hover:underline text-sm", className)}
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId={`tooltip-timestamp`}
            {...iconProps}
          >
            {children == null ? displayTimestamp : children}
          </Button>
        </ReactTooltip.Trigger>
        <ReactTooltip.Portal>
          <ReactTooltip.Content
            className={styles.animatedTooltipContent}
            avoidCollisions={true}
            collisionPadding={16}
            side={"top"}
            onPointerDownOutside={(event) => {
              if (event.target == null) return;
              // This prevents the tooltip from closing when you click the trigger
              const targetNode = event.target as Node;
              if (triggerRef.current?.contains(targetNode)) {
                event.preventDefault();
              }
            }}
          >
            <StackedList className="divide-y text-sm text-slate-700">
              <li className="py-2 px-3 flex-between gap-4">
                <Txt inline bold>
                  UTC
                </Txt>{" "}
                <span
                  className="relative flex gap-1 cursor-pointer hover:text-content-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    copyTextToClipboard(utcLocaleString);
                  }}
                >
                  <Icon id={IconEnum.Copy} /> {utcLocaleString}
                  <span
                    className={tcx(
                      "flex gap-1 justify-end absolute right-0 top-0 h-full w-full transition bg-white text-green-content",
                      hasCopied ? "opacity-100" : "opacity-0",
                    )}
                  >
                    <Icon id={hasCopied ? IconEnum.Success : IconEnum.Copy} />{" "}
                    Copied
                  </span>
                </span>
              </li>
              <li className="py-2 px-3 flex-between gap-4">
                <span className="font-semibold">
                  Local ({timezoneDifferenceString})
                </span>{" "}
                {localTimeLocaleString}
              </li>
              {/* Show a link to open the current timestamp in grafana logs */}
              {isLoggedInToStaff && (
                <li>
                  <a
                    className={"py-2 px-3 flex-between gap-4"}
                    href={buildLogsUrl({ date: utcDate, identity: identity })}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <span className="font-semibold">Grafana</span>
                    <span
                      className={
                        "inline-flex items-center gap-1 hover:text-slate-900 hover:underline"
                      }
                    >
                      <Icon id={IconEnum.Grafana} size={IconSize.Small} />
                      Open logs
                    </span>
                  </a>
                </li>
              )}
            </StackedList>
            <TooltipPrimitive.Arrow
              width={11}
              height={5}
              className="fill-white"
            />
          </ReactTooltip.Content>
        </ReactTooltip.Portal>
      </ReactTooltip.Root>
    </ReactTooltip.Provider>
  );
}

const buildLogsUrl = ({
  date,
  identity,
}: {
  date: Date;
  identity: Identity;
}) => {
  const oneMinuteBefore = new Date((date.getTime() ?? 0) - 60 * 1000);
  const oneMinuteAfter = new Date((date.getTime() ?? 0) + 60 * 1000);
  const from = oneMinuteBefore.getTime().toString();
  const to = oneMinuteAfter.getTime().toString();

  const pane = {
    syu: {
      datasource: "c60e9289-5b3c-488a-a87f-c7bf961fad3a",
      queries: [
        {
          datasource: {
            type: "loki",
            uid: "c60e9289-5b3c-488a-a87f-c7bf961fad3a",
          },
          editorMode: "code",
          expr: `{project="incident-io-production"}
             | json
             | organisation_id="${identity.organisation_id}"
             | label_format level="{{ if .httpRequest_status }}{{ if lt (int .httpRequest_status) 400 }}info{{ else if lt (int .httpRequest_status) 500 }}warn{{ else }}error{{ end }}{{ else }}{{ .level }}{{ end }}"
             | line_format "\u001b[1m{{.message}}\u001b[0m\\n{{ if .endpoint_method }} {{ printf \\"%-35s %-35s\\" .endpoint_method .endpoint_service }}\\t {{ else }} {{ printf \\"%-35s %-35s\\" .topic .subscriber }}\\t {{ end }} {{ if .organisation_id }} {{ .organisation_id}} ({{.organisation_name}}) {{ else }} \u001b[3mNo org\u001b[0m {{ end }}"`,
          queryType: "range",
          refId: "A",
        },
      ],
      range: {
        from,
        to,
      },
    },
  };

  const query = encodeURIComponent(JSON.stringify(pane));

  return `https://pineapples.grafana.net/explore?schemaVersion=1&panes=${query}&orgId=1`;
};
