/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackCanvasPreviewVariant,
    SlackCanvasPreviewVariantFromJSON,
    SlackCanvasPreviewVariantFromJSONTyped,
    SlackCanvasPreviewVariantToJSON,
} from './SlackCanvasPreviewVariant';

/**
 * 
 * @export
 * @interface SlackCanvasPreviewTemplate
 */
export interface SlackCanvasPreviewTemplate {
    /**
     * Category of the template
     * @type {string}
     * @memberof SlackCanvasPreviewTemplate
     */
    category?: string;
    /**
     * Description of the template
     * @type {string}
     * @memberof SlackCanvasPreviewTemplate
     */
    description?: string;
    /**
     * Name of the template
     * @type {string}
     * @memberof SlackCanvasPreviewTemplate
     */
    name?: string;
    /**
     * Readable name of the template
     * @type {string}
     * @memberof SlackCanvasPreviewTemplate
     */
    readableName?: string;
    /**
     * Theme of the template
     * @type {string}
     * @memberof SlackCanvasPreviewTemplate
     */
    theme?: string;
    /**
     * 
     * @type {Array<SlackCanvasPreviewVariant>}
     * @memberof SlackCanvasPreviewTemplate
     */
    variants?: Array<SlackCanvasPreviewVariant>;
}

export function SlackCanvasPreviewTemplateFromJSON(json: any): SlackCanvasPreviewTemplate {
    return SlackCanvasPreviewTemplateFromJSONTyped(json, false);
}

export function SlackCanvasPreviewTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackCanvasPreviewTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'readableName': !exists(json, 'readableName') ? undefined : json['readableName'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'variants': !exists(json, 'variants') ? undefined : ((json['variants'] as Array<any>).map(SlackCanvasPreviewVariantFromJSON)),
    };
}

export function SlackCanvasPreviewTemplateToJSON(value?: SlackCanvasPreviewTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'description': value.description,
        'name': value.name,
        'readableName': value.readableName,
        'theme': value.theme,
        'variants': value.variants === undefined ? undefined : ((value.variants as Array<any>).map(SlackCanvasPreviewVariantToJSON)),
    };
}

