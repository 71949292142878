import type { SVGProps } from "react";
import * as React from "react";
const SvgTickCircle = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 1.6A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4Zm3.158 4.513C9.67 7.218 8.41 8.745 7.41 10.648a.6.6 0 0 1-1.064-.004c-.46-.893-.975-1.62-1.573-2.22a.6.6 0 1 1 .852-.847c.458.46.872.982 1.25 1.578.991-1.636 2.187-2.981 3.567-4.006a.6.6 0 0 1 .715.964Z"
    />
  </svg>
);
export default SvgTickCircle;
