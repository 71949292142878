import type { SVGProps } from "react";
import * as React from "react";
const SvgConfluence = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#confluence_svg__a)"
      d="M2.581 13.954c-.169.266-.362.58-.507.822a.51.51 0 0 0 .169.701l3.31 2.03a.51.51 0 0 0 .7-.169c.122-.217.29-.507.484-.822 1.305-2.15 2.633-1.91 5.002-.773l3.286 1.547a.513.513 0 0 0 .676-.242l1.57-3.553c.122-.242 0-.556-.241-.678-1.105-.504-2.2-1.028-3.286-1.571-4.47-2.152-8.263-2.007-11.163 2.708Z"
    />
    <path
      fill="url(#confluence_svg__b)"
      d="M17.416 5.878c.173-.272.342-.546.508-.822a.509.509 0 0 0-.17-.7l-3.31-2.031a.51.51 0 0 0-.7.169c-.12.217-.29.507-.483.822-1.305 2.152-2.634 1.91-5.002.773L4.973 2.543a.513.513 0 0 0-.677.242l-1.57 3.553c-.12.242 0 .556.242.677.7.314 2.078.967 3.286 1.571 4.47 2.128 8.263 2.007 11.162-2.708Z"
    />
    <defs>
      <linearGradient
        id="confluence_svg__a"
        x1={17.193}
        x2={7.194}
        y1={18.56}
        y2={12.819}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset={0.918} stopColor="#2380FB" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="confluence_svg__b"
        x1={2.805}
        x2={12.804}
        y1={1.265}
        y2={7.007}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset={0.918} stopColor="#2380FB" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgConfluence;
