import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

type PopoverSearchProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

export const PopoverSearch = ({
  value,
  onChange,
  placeholder = "Search",
}: PopoverSearchProps) => {
  return (
    <div className="py-1 px-2 border-b border-stroke">
      <div className="flex items-center gap-2 p-2">
        <Icon
          id={IconEnum.Search}
          className="text-content-tertiary"
          size={IconSize.Small}
        />
        <input
          id="search"
          className="text-sm-normal text-content-primary placeholder-content-tertiary"
          onChange={(v) => onChange(v.target.value)}
          value={value}
          placeholder={placeholder}
        />

        <Button
          analyticsTrackingId={null}
          icon={IconEnum.Close}
          iconProps={{
            size: IconSize.Small,
          }}
          theme={ButtonTheme.Naked}
          className={tcx(
            "ml-auto",
            // Make it invisible so you don't get layout shift.
            value ? "" : "invisible",
          )}
          title="Close search"
          onClick={() => onChange("")}
        />
      </div>
    </div>
  );
};

type PopoverNoResultsProps = {
  message?: string;
};

export const PopoverEmptyState = ({
  message = "No results found",
}: PopoverNoResultsProps) => {
  return (
    <div className="w-full text-xs text-content-tertiary p-3">{message}</div>
  );
};
