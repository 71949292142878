import { Product } from "@incident-shared/billing";
import { GenericErrorMessage } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import graphic from "src/components/settings/banners/banner-policies.svg";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";

import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { PolicyReportList } from "./policy-report/view/PolicyReportList";
import { PolicyList } from "./view/PolicyList";

export const SettingsPoliciesHome = (): React.ReactElement => {
  const { hasProduct } = useProductAccess();
  const {
    data: { policies },
    error: policiesError,
    isLoading: policiesLoading,
  } = useAPI("policiesList", undefined, { fallbackData: { policies: [] } });

  const {
    data: { report_schedules: reportSchedules },
    isLoading: scheduleLoading,
    error: scheduleError,
  } = useAPI("policiesListReportSchedule", undefined, {
    fallbackData: { report_schedules: [] },
  });

  const {
    data: { requirements_templates: requirementsTemplates },
    isLoading: requirementsTemplatesLoading,
    error: requirementsTemplatesError,
  } = useAPI("policiesListRequirementsTemplates", undefined, {
    fallbackData: { requirements_templates: [] },
  });

  if (policiesLoading || scheduleLoading || requirementsTemplatesLoading) {
    return <FullPageLoader />;
  }

  const error = policiesError || scheduleError || requirementsTemplatesError;
  if (error) {
    return (
      <GenericErrorMessage
        description="We couldn't load incident policies."
        error={policiesError || scheduleError}
      />
    );
  }

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Set clear policies"
        subtitle="Define guidelines to ensure follow-ups and important tasks are not forgotten."
        graphic={<img src={graphic} className="h-40" />}
        articleId={6496355}
      />

      {hasProduct(Product.Response) ? (
        <>
          <PolicyList
            policies={policies}
            reportSchedules={reportSchedules}
            requirementsTemplates={requirementsTemplates}
          />
          <PolicyReportList
            policiesCount={policies.length}
            reportSchedules={reportSchedules}
          />
        </>
      ) : (
        <ProductUpsellNotice
          plurality={Plurality.Plural}
          featureName="Policies"
          requiredProduct={Product.Response}
        />
      )}
    </SettingsSubPageWrapper>
  );
};
