import { Incident, Stream } from "@incident-io/api";
import {
  Heading,
  Icon,
  IconEnum,
  IconSize,
  LoadingBar,
  Tooltip,
} from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";

import { useAPI } from "../../../../utils/swr";
import { ExternalLink } from "../sidebar/ExternalLink";

export const StreamExternalLinksSection = ({ stream }: { stream: Stream }) => {
  return (
    <div className="space-y-2 pb-1 text-xs">
      <div className="flex gap-2 items-center mb-2">
        <Heading level={3} className="text-xs">
          Links
        </Heading>
      </div>
      <SlackLink incident={stream} />
    </div>
  );
};

const SlackLink = ({ incident }: { incident: Incident | Stream }) => {
  const { identity } = useIdentity();

  const { data: slackTeamConfigData, isLoading } = useAPI(
    incident.slack_team_id ? "slackTeamConfigsGetBySlackTeamID" : null,
    {
      slackTeamId: incident.slack_team_id ?? "",
    },
  );
  const slackTeamName = slackTeamConfigData?.slack_team_config?.name ?? "";

  // User can access Slack channel if no team ids set (not Grid install)
  // or if the incident's slack team id is in the user's slack team ids
  const slackTeamIDs = identity.slack_info?.team_ids || [];
  const userCanAccessSlackChannel =
    slackTeamIDs.length === 0 || slackTeamIDs.includes(incident.slack_team_id);

  if (isLoading) return <LoadingBar />;

  return incident.slack_channel_url ? (
    <>
      {userCanAccessSlackChannel ? (
        <ExternalLink
          analyticsTrackingId={"incident-sidebar-slack"}
          href={incident.slack_channel_url}
          label={"View Slack channel"}
          icon={IconEnum.Slack}
          iconProps={{ size: IconSize.Small }}
        />
      ) : (
        <div className="flex items-center">
          <Icon id={IconEnum.Slack} className={"opacity-50"} />
          <Tooltip
            content={`You do not have access to this incident's Slack channel as you are not a member of the ${slackTeamName}Slack workspace.`}
          >
            <span className="text-content-tertiary">View Slack channel</span>
          </Tooltip>
        </div>
      )}
    </>
  ) : (
    <MissingCommsPlatformWarning commsPlatform="Slack" />
  );
};

const MissingCommsPlatformWarning = ({
  commsPlatform,
}: {
  commsPlatform: string;
}) => {
  return (
    <div className="inline-flex items-center">
      <Icon id={IconEnum.Warning} className="mr-2 text-alarmalade-content" />
      <span className="text-content-tertiary">No {commsPlatform} channel</span>
    </div>
  );
};
