/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DependentResource,
    DependentResourceFromJSON,
    DependentResourceFromJSONTyped,
    DependentResourceToJSON,
} from './DependentResource';
import {
    StatusPage,
    StatusPageFromJSON,
    StatusPageFromJSONTyped,
    StatusPageToJSON,
} from './StatusPage';
import {
    StatusPageComponentDependentResource,
    StatusPageComponentDependentResourceFromJSON,
    StatusPageComponentDependentResourceFromJSONTyped,
    StatusPageComponentDependentResourceToJSON,
} from './StatusPageComponentDependentResource';
import {
    StatusPageStructure,
    StatusPageStructureFromJSON,
    StatusPageStructureFromJSONTyped,
    StatusPageStructureToJSON,
} from './StatusPageStructure';
import {
    StatusPageSubPageSlim,
    StatusPageSubPageSlimFromJSON,
    StatusPageSubPageSlimFromJSONTyped,
    StatusPageSubPageSlimToJSON,
} from './StatusPageSubPageSlim';

/**
 * 
 * @export
 * @interface StatusPageShowResponseBody
 */
export interface StatusPageShowResponseBody {
    /**
     * 
     * @type {Array<StatusPageComponentDependentResource>}
     * @memberof StatusPageShowResponseBody
     */
    component_dependent_resources?: Array<StatusPageComponentDependentResource>;
    /**
     * 
     * @type {StatusPageStructure}
     * @memberof StatusPageShowResponseBody
     */
    current_structure: StatusPageStructure;
    /**
     * 
     * @type {Array<DependentResource>}
     * @memberof StatusPageShowResponseBody
     */
    dependent_resources: Array<DependentResource>;
    /**
     * 
     * @type {StatusPage}
     * @memberof StatusPageShowResponseBody
     */
    status_page: StatusPage;
    /**
     * List of slim sub-pages, if this is a parent page
     * @type {Array<StatusPageSubPageSlim>}
     * @memberof StatusPageShowResponseBody
     */
    subpages?: Array<StatusPageSubPageSlim>;
}

export function StatusPageShowResponseBodyFromJSON(json: any): StatusPageShowResponseBody {
    return StatusPageShowResponseBodyFromJSONTyped(json, false);
}

export function StatusPageShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_dependent_resources': !exists(json, 'component_dependent_resources') ? undefined : ((json['component_dependent_resources'] as Array<any>).map(StatusPageComponentDependentResourceFromJSON)),
        'current_structure': StatusPageStructureFromJSON(json['current_structure']),
        'dependent_resources': ((json['dependent_resources'] as Array<any>).map(DependentResourceFromJSON)),
        'status_page': StatusPageFromJSON(json['status_page']),
        'subpages': !exists(json, 'subpages') ? undefined : ((json['subpages'] as Array<any>).map(StatusPageSubPageSlimFromJSON)),
    };
}

export function StatusPageShowResponseBodyToJSON(value?: StatusPageShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_dependent_resources': value.component_dependent_resources === undefined ? undefined : ((value.component_dependent_resources as Array<any>).map(StatusPageComponentDependentResourceToJSON)),
        'current_structure': StatusPageStructureToJSON(value.current_structure),
        'dependent_resources': ((value.dependent_resources as Array<any>).map(DependentResourceToJSON)),
        'status_page': StatusPageToJSON(value.status_page),
        'subpages': value.subpages === undefined ? undefined : ((value.subpages as Array<any>).map(StatusPageSubPageSlimToJSON)),
    };
}

