import { CatalogEntry, CatalogType, RankUpdate } from "@incident-io/api";
import {
  GenericErrorMessage,
  Icon,
  IconEnum,
  Loader,
  StackedListItem,
} from "@incident-ui";
import _ from "lodash";
import { useEffect, useState } from "react";
import { SettingsSortableList } from "src/components/settings/SettingsSortableList";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CATALOG_ENTRY_BULK_EDIT_PAGE_SIZE } from "./constants";

export const CATALOG_MAX_WIDTH = "max-w-[230px] truncate";

type EntryWithRankingInfo = CatalogEntry & {
  new_rank: number;
};

export const CatalogEntryRankingList = ({
  catalogType,
  setNewRankings,
}: {
  catalogType: CatalogType;
  setNewRankings(rankings: RankUpdate[], numberOfRanksUpdated: number): void;
}) => {
  const [entriesWithRanking, setEntriesWithRanking] = useState<
    EntryWithRankingInfo[]
  >([]);

  const { data, error, isLoading } = useAPI(
    "catalogListEntries",
    {
      catalogTypeId: catalogType.id,
      includeReferences: false,
      pageSize: CATALOG_ENTRY_BULK_EDIT_PAGE_SIZE,
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    // order the catalog entries by rank
    const orderedCatalogEntries = _.orderBy(
      data?.catalog_entries,
      (x) => x.rank,
      "asc",
    );

    orderedCatalogEntries.forEach((entry, index) => {
      entry.rank = index;
    });

    // normalise the rankings to be 1,2, 3...X and set initial new ranks
    const entriesWithInitialRanking = orderedCatalogEntries.map(
      (entry, index) => {
        return { ...entry, rank: index + 1, new_rank: index + 1 };
      },
    );

    setEntriesWithRanking(entriesWithInitialRanking);
  }, [data]);

  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (isLoading || !data) {
    return <Loader />;
  }

  const onRankingChange = (updatedEntries) => {
    // Update new_rank property for each entry
    const updatedEntriesWithRanking = entriesWithRanking.map((entry) => {
      const updatedEntry = updatedEntries.find(
        (updated) => updated.id === entry.id,
      );
      return {
        ...entry,
        // SettingsSortableList returns us 0-indexed rankings, so we need to add 1 here
        new_rank: updatedEntry.rank + 1,
      };
    });

    // Update state with the new entries
    setEntriesWithRanking(updatedEntriesWithRanking);

    // Create reranked entries for the API call
    const rankedEntriesRequestData = updatedEntriesWithRanking.map((entry) => {
      return {
        resource_id: entry.id,
        rank: entry.new_rank,
      };
    });

    // Calculate the number of entries with updated ranks
    const numUpdated = updatedEntriesWithRanking.reduce(
      (count, entry) => (entry.rank !== entry.new_rank ? count + 1 : count),
      0,
    );

    setNewRankings(rankedEntriesRequestData, numUpdated);
  };

  // override the sort order for sortable list, we want to sort by the updated rank, not the current rank
  const orderByUpdatedRank = (items) => {
    return _.orderBy(items, (x) => x.new_rank, "asc");
  };

  return (
    <SettingsSortableList
      headerRow={
        <StackedListItem
          title="Name"
          accessory={<div className="w-28 shrink-0 text-sm-bold">Rank</div>}
          className="w-full bg-surface-secondary"
        />
      }
      canEdit={true}
      dragHandleClassName="pl-2"
      className="table w-full"
      updateItemRanks={onRankingChange}
      saving={isLoading}
      items={entriesWithRanking}
      sortItemsOverride={orderByUpdatedRank}
      renderItem={(item) => <CatalogEntryRankedRow item={item} />}
    />
  );
};

const CatalogEntryRankedRow = ({
  item,
}: {
  item: CatalogEntry & {
    new_rank: number;
  };
}) => {
  return (
    <StackedListItem
      title={<div className="font-normal">{item.name}</div>}
      accessory={<RankIndicator item={item} />}
    />
  );
};

const RankIndicator = ({ item }: { item: EntryWithRankingInfo }) => {
  const rankUpdated = item.rank !== item.new_rank;
  if (rankUpdated) {
    return (
      <div className="w-28 shrink-0 flex">
        <div className={tcx("text-slate-300")}>{item.rank}</div>
        <Icon id={IconEnum.ArrowRight} className={tcx("text-slate-300")} />
        <div>{item.new_rank}</div>
      </div>
    );
  }
  return (
    <div className="w-28 shrink-0 flex">
      <div>{item.rank}</div>
    </div>
  );
};
