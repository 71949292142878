/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertRouteSlim
 */
export interface AlertRouteSlim {
    /**
     * Whether this alert route is enabled or not
     * @type {boolean}
     * @memberof AlertRouteSlim
     */
    enabled: boolean;
    /**
     * Unique identifier for this alert route config
     * @type {string}
     * @memberof AlertRouteSlim
     */
    id: string;
    /**
     * The name of this alert route config, for the user's reference
     * @type {string}
     * @memberof AlertRouteSlim
     */
    name: string;
}

export function AlertRouteSlimFromJSON(json: any): AlertRouteSlim {
    return AlertRouteSlimFromJSONTyped(json, false);
}

export function AlertRouteSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function AlertRouteSlimToJSON(value?: AlertRouteSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'id': value.id,
        'name': value.name,
    };
}

