/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SAMLDomain,
    SAMLDomainFromJSON,
    SAMLDomainFromJSONTyped,
    SAMLDomainToJSON,
} from './SAMLDomain';

/**
 * 
 * @export
 * @interface SAMLSettings
 */
export interface SAMLSettings {
    /**
     * The state of the connection, if any. Possible values are: draft, active and inactive
     * @type {string}
     * @memberof SAMLSettings
     */
    connection_state?: string;
    /**
     * The type of connection, if any. This can be all sorts of IdP providers
     * @type {string}
     * @memberof SAMLSettings
     */
    connection_type?: string;
    /**
     * 
     * @type {Array<SAMLDomain>}
     * @memberof SAMLSettings
     */
    domains: Array<SAMLDomain>;
    /**
     * Whether the organisation has a SAML IdP connection
     * @type {boolean}
     * @memberof SAMLSettings
     */
    has_connection: boolean;
    /**
     * 
     * @type {string}
     * @memberof SAMLSettings
     */
    id: string;
    /**
     * Whether SAML login is optional or mandatory. i.e.: if its' optional, users  will be allowed to sign in with Slack as well.
     * @type {boolean}
     * @memberof SAMLSettings
     */
    is_optional: boolean;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof SAMLSettings
     */
    organisation_id: string;
    /**
     * The URL of the self-serve portal to finish setting up the connection. Only present if one is not active.
     * @type {string}
     * @memberof SAMLSettings
     */
    self_serve_portal_url?: string;
}

export function SAMLSettingsFromJSON(json: any): SAMLSettings {
    return SAMLSettingsFromJSONTyped(json, false);
}

export function SAMLSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connection_state': !exists(json, 'connection_state') ? undefined : json['connection_state'],
        'connection_type': !exists(json, 'connection_type') ? undefined : json['connection_type'],
        'domains': ((json['domains'] as Array<any>).map(SAMLDomainFromJSON)),
        'has_connection': json['has_connection'],
        'id': json['id'],
        'is_optional': json['is_optional'],
        'organisation_id': json['organisation_id'],
        'self_serve_portal_url': !exists(json, 'self_serve_portal_url') ? undefined : json['self_serve_portal_url'],
    };
}

export function SAMLSettingsToJSON(value?: SAMLSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connection_state': value.connection_state,
        'connection_type': value.connection_type,
        'domains': ((value.domains as Array<any>).map(SAMLDomainToJSON)),
        'has_connection': value.has_connection,
        'id': value.id,
        'is_optional': value.is_optional,
        'organisation_id': value.organisation_id,
        'self_serve_portal_url': value.self_serve_portal_url,
    };
}

