/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimestamp,
    IncidentTimestampFromJSON,
    IncidentTimestampFromJSONTyped,
    IncidentTimestampToJSON,
} from './IncidentTimestamp';
import {
    IncidentTimestampValue,
    IncidentTimestampValueFromJSON,
    IncidentTimestampValueFromJSONTyped,
    IncidentTimestampValueToJSON,
} from './IncidentTimestampValue';

/**
 * 
 * @export
 * @interface IncidentTimestampWithValue
 */
export interface IncidentTimestampWithValue {
    /**
     * 
     * @type {IncidentTimestamp}
     * @memberof IncidentTimestampWithValue
     */
    timestamp: IncidentTimestamp;
    /**
     * 
     * @type {IncidentTimestampValue}
     * @memberof IncidentTimestampWithValue
     */
    value?: IncidentTimestampValue;
}

export function IncidentTimestampWithValueFromJSON(json: any): IncidentTimestampWithValue {
    return IncidentTimestampWithValueFromJSONTyped(json, false);
}

export function IncidentTimestampWithValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampWithValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': IncidentTimestampFromJSON(json['timestamp']),
        'value': !exists(json, 'value') ? undefined : IncidentTimestampValueFromJSON(json['value']),
    };
}

export function IncidentTimestampWithValueToJSON(value?: IncidentTimestampWithValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': IncidentTimestampToJSON(value.timestamp),
        'value': IncidentTimestampValueToJSON(value.value),
    };
}

