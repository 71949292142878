/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleMapping,
    RoleMappingFromJSON,
    RoleMappingFromJSONTyped,
    RoleMappingToJSON,
} from './RoleMapping';

/**
 * 
 * @export
 * @interface SCIMUpdateRoleMappingsResponseBody
 */
export interface SCIMUpdateRoleMappingsResponseBody {
    /**
     * 
     * @type {Array<RoleMapping>}
     * @memberof SCIMUpdateRoleMappingsResponseBody
     */
    role_mappings: Array<RoleMapping>;
}

export function SCIMUpdateRoleMappingsResponseBodyFromJSON(json: any): SCIMUpdateRoleMappingsResponseBody {
    return SCIMUpdateRoleMappingsResponseBodyFromJSONTyped(json, false);
}

export function SCIMUpdateRoleMappingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SCIMUpdateRoleMappingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role_mappings': ((json['role_mappings'] as Array<any>).map(RoleMappingFromJSON)),
    };
}

export function SCIMUpdateRoleMappingsResponseBodyToJSON(value?: SCIMUpdateRoleMappingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role_mappings': ((value.role_mappings as Array<any>).map(RoleMappingToJSON)),
    };
}

