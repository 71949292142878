/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    EnrichedFollowUp,
    EnrichedFollowUpFromJSON,
    EnrichedFollowUpFromJSONTyped,
    EnrichedFollowUpToJSON,
} from './EnrichedFollowUp';
import {
    FollowUpAssigneeChange,
    FollowUpAssigneeChangeFromJSON,
    FollowUpAssigneeChangeFromJSONTyped,
    FollowUpAssigneeChangeToJSON,
} from './FollowUpAssigneeChange';
import {
    FollowUpDescriptionChange,
    FollowUpDescriptionChangeFromJSON,
    FollowUpDescriptionChangeFromJSONTyped,
    FollowUpDescriptionChangeToJSON,
} from './FollowUpDescriptionChange';
import {
    FollowUpPriorityChange,
    FollowUpPriorityChangeFromJSON,
    FollowUpPriorityChangeFromJSONTyped,
    FollowUpPriorityChangeToJSON,
} from './FollowUpPriorityChange';
import {
    FollowUpStatusChange,
    FollowUpStatusChangeFromJSON,
    FollowUpStatusChangeFromJSONTyped,
    FollowUpStatusChangeToJSON,
} from './FollowUpStatusChange';
import {
    FollowUpTitleChange,
    FollowUpTitleChangeFromJSON,
    FollowUpTitleChangeFromJSONTyped,
    FollowUpTitleChangeToJSON,
} from './FollowUpTitleChange';

/**
 * 
 * @export
 * @interface FollowUpUpdated
 */
export interface FollowUpUpdated {
    /**
     * 
     * @type {FollowUpAssigneeChange}
     * @memberof FollowUpUpdated
     */
    assignee_change?: FollowUpAssigneeChange;
    /**
     * 
     * @type {FollowUpDescriptionChange}
     * @memberof FollowUpUpdated
     */
    description_change?: FollowUpDescriptionChange;
    /**
     * 
     * @type {EnrichedFollowUp}
     * @memberof FollowUpUpdated
     */
    follow_up: EnrichedFollowUp;
    /**
     * 
     * @type {FollowUpPriorityChange}
     * @memberof FollowUpUpdated
     */
    follow_up_priority_option_change?: FollowUpPriorityChange;
    /**
     * 
     * @type {FollowUpStatusChange}
     * @memberof FollowUpUpdated
     */
    status_change?: FollowUpStatusChange;
    /**
     * 
     * @type {FollowUpTitleChange}
     * @memberof FollowUpUpdated
     */
    title_change?: FollowUpTitleChange;
    /**
     * 
     * @type {Actor}
     * @memberof FollowUpUpdated
     */
    updater: Actor;
}

export function FollowUpUpdatedFromJSON(json: any): FollowUpUpdated {
    return FollowUpUpdatedFromJSONTyped(json, false);
}

export function FollowUpUpdatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpUpdated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_change': !exists(json, 'assignee_change') ? undefined : FollowUpAssigneeChangeFromJSON(json['assignee_change']),
        'description_change': !exists(json, 'description_change') ? undefined : FollowUpDescriptionChangeFromJSON(json['description_change']),
        'follow_up': EnrichedFollowUpFromJSON(json['follow_up']),
        'follow_up_priority_option_change': !exists(json, 'follow_up_priority_option_change') ? undefined : FollowUpPriorityChangeFromJSON(json['follow_up_priority_option_change']),
        'status_change': !exists(json, 'status_change') ? undefined : FollowUpStatusChangeFromJSON(json['status_change']),
        'title_change': !exists(json, 'title_change') ? undefined : FollowUpTitleChangeFromJSON(json['title_change']),
        'updater': ActorFromJSON(json['updater']),
    };
}

export function FollowUpUpdatedToJSON(value?: FollowUpUpdated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_change': FollowUpAssigneeChangeToJSON(value.assignee_change),
        'description_change': FollowUpDescriptionChangeToJSON(value.description_change),
        'follow_up': EnrichedFollowUpToJSON(value.follow_up),
        'follow_up_priority_option_change': FollowUpPriorityChangeToJSON(value.follow_up_priority_option_change),
        'status_change': FollowUpStatusChangeToJSON(value.status_change),
        'title_change': FollowUpTitleChangeToJSON(value.title_change),
        'updater': ActorToJSON(value.updater),
    };
}

