import { CatalogTypeCategoriesEnum } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";

import {
  CatalogWizardAddAttributesStep,
  ExtraAddAttributeButton,
} from "../CatalogWizardAddAttributesStep";
import { useHasTypeOfCategory } from "../useHasTypeOfCategory";

export const ServiceWizardAddAttributesStep = () => {
  const { matchingType: teamType } = useHasTypeOfCategory(
    CatalogTypeCategoriesEnum.Team,
  );

  const additionalButtons: ExtraAddAttributeButton[] = [];
  if (teamType) {
    additionalButtons.push({
      label: "Owning team",
      icon: teamType.icon,
      color: teamType.color as unknown as ColorPaletteEnum,
      type: teamType.type_name,
    });
  }

  return (
    <CatalogWizardAddAttributesStep
      category={CatalogTypeCategoriesEnum.Service}
      resourceTypesToDisplayAsButtons={[
        "GitHubRepository",
        "EscalationPath",
        "SentryProject",
        "JiraCloudProject",
      ]}
      additionalButtons={additionalButtons}
    />
  );
};
