/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';

/**
 * 
 * @export
 * @interface IncidentRolesUpdateRequestBody
 */
export interface IncidentRolesUpdateRequestBody {
    /**
     * What conditions must be true for this role to be available?
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentRolesUpdateRequestBody
     */
    condition_groups?: Array<ConditionGroupPayload>;
    /**
     * Describes the purpose of the role
     * @type {string}
     * @memberof IncidentRolesUpdateRequestBody
     */
    description: string;
    /**
     * Provided to whoever is nominated for the role. Note that this will be empty for the 'reporter' role.
     * @type {string}
     * @memberof IncidentRolesUpdateRequestBody
     */
    instructions: string;
    /**
     * Human readable name of the incident role
     * @type {string}
     * @memberof IncidentRolesUpdateRequestBody
     */
    name: string;
    /**
     * Short human readable name for Slack. Note that this will be empty for the 'reporter' role.
     * @type {string}
     * @memberof IncidentRolesUpdateRequestBody
     */
    shortform?: string;
}

export function IncidentRolesUpdateRequestBodyFromJSON(json: any): IncidentRolesUpdateRequestBody {
    return IncidentRolesUpdateRequestBodyFromJSONTyped(json, false);
}

export function IncidentRolesUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRolesUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': !exists(json, 'condition_groups') ? undefined : ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'description': json['description'],
        'instructions': json['instructions'],
        'name': json['name'],
        'shortform': !exists(json, 'shortform') ? undefined : json['shortform'],
    };
}

export function IncidentRolesUpdateRequestBodyToJSON(value?: IncidentRolesUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': value.condition_groups === undefined ? undefined : ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'description': value.description,
        'instructions': value.instructions,
        'name': value.name,
        'shortform': value.shortform,
    };
}

