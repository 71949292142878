import { format } from "date-fns";
import _ from "lodash";
import {
  PolicyReportScheduleIntervalEnum,
  SchedulePayRuleWeekdaysEnum,
} from "src/contexts/ClientContext";

export const Weekdays: SchedulePayRuleWeekdaysEnum[] = [
  SchedulePayRuleWeekdaysEnum.Monday,
  SchedulePayRuleWeekdaysEnum.Tuesday,
  SchedulePayRuleWeekdaysEnum.Wednesday,
  SchedulePayRuleWeekdaysEnum.Thursday,
  SchedulePayRuleWeekdaysEnum.Friday,
  SchedulePayRuleWeekdaysEnum.Saturday,
  SchedulePayRuleWeekdaysEnum.Sunday,
];
export const weekdaySelectOptions = Weekdays.map((day, index) => ({
  value: day,
  label: _.capitalize(day),
  sort_key: String(index),
}));
export const sortWeekdays = (
  weekdays: SchedulePayRuleWeekdaysEnum[],
): SchedulePayRuleWeekdaysEnum[] => {
  return _.sortBy(weekdays, (ourDay) =>
    Weekdays.findIndex((candidate) => candidate === ourDay),
  );
};

export const policyReportLabelLookup = {
  [PolicyReportScheduleIntervalEnum.Daily]: "day",
  [PolicyReportScheduleIntervalEnum.DailyOnWeekdays]: "weekday",
  [PolicyReportScheduleIntervalEnum.Weekly]: "week",
  [PolicyReportScheduleIntervalEnum.Monthly]: "month",
};

export const policyReportFrequencyOptions = [
  PolicyReportScheduleIntervalEnum.DailyOnWeekdays,
  PolicyReportScheduleIntervalEnum.Daily,
  PolicyReportScheduleIntervalEnum.Weekly,
  PolicyReportScheduleIntervalEnum.Monthly,
].map((frequency) => {
  return {
    value: frequency,
    label: policyReportLabelLookup[frequency],
  };
});

export const formatDayOfMonth = (day: number | undefined): string => {
  if (!day || day < 1 || day > 31) {
    day = 1;
  }
  return format(
    new Date(`2000-01-${String(day).padStart(2, "0")}T01:00:00`),
    "do",
  );
};

export const monthDaySelectOptions = _.range(1, 32).map((day) => ({
  label: `${day}`,
  value: `${day}`,
}));
