/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    IncidentType,
    IncidentTypeFromJSON,
    IncidentTypeFromJSONTyped,
    IncidentTypeToJSON,
} from './IncidentType';

/**
 * 
 * @export
 * @interface IncidentTypeChanged
 */
export interface IncidentTypeChanged {
    /**
     * 
     * @type {Actor}
     * @memberof IncidentTypeChanged
     */
    actor: Actor;
    /**
     * 
     * @type {IncidentType}
     * @memberof IncidentTypeChanged
     */
    from: IncidentType;
    /**
     * 
     * @type {IncidentType}
     * @memberof IncidentTypeChanged
     */
    to: IncidentType;
}

export function IncidentTypeChangedFromJSON(json: any): IncidentTypeChanged {
    return IncidentTypeChangedFromJSONTyped(json, false);
}

export function IncidentTypeChangedFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTypeChanged {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'from': IncidentTypeFromJSON(json['from']),
        'to': IncidentTypeFromJSON(json['to']),
    };
}

export function IncidentTypeChangedToJSON(value?: IncidentTypeChanged | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'from': IncidentTypeToJSON(value.from),
        'to': IncidentTypeToJSON(value.to),
    };
}

