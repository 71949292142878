import {
  DependentResource,
  DependentResourceResourceTypeEnum,
  UserWithRoles,
} from "@incident-io/api";

import { useAPI } from "../../../../utils/swr";
import { isOnCallUser } from "./utils";

export const useOnCallSeatDependentResources = ({
  user,
}: {
  user: UserWithRoles;
}) => {
  const {
    data: oncResources,
    isLoading: oncResourcesLoading,
    error: oncResourcesError,
  } = useAPI(isOnCallUser(user.state) ? "usersListOnCallResources" : null, {
    id: user.id,
  });

  const dependentResources: DependentResource[] = [];
  oncResources?.schedules?.forEach((sched) => {
    const resource: DependentResource = {
      can_be_auto_deleted: false,
      id: sched.id,
      label: sched.name,
      resource_type: DependentResourceResourceTypeEnum.Schedule,
      resource_type_label: "Schedule",
      settings_url: `/on-call/schedules/${sched.id}`,
    };

    dependentResources.push(resource);
  });

  oncResources?.escalation_paths?.forEach((path) => {
    const resource: DependentResource = {
      can_be_auto_deleted: false,
      id: path.id,
      label: path.name,
      resource_type: DependentResourceResourceTypeEnum.EscalationPath,
      resource_type_label: "Escalation Path",
      settings_url: `/on-call/escalation-paths/${path.id}`,
    };

    dependentResources.push(resource);
  });

  return {
    dependentResources,
    oncResourcesLoading,
    oncResourcesError,
  };
};
