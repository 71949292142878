/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface InternalStatusPageIncidentUpdate
 */
export interface InternalStatusPageIncidentUpdate {
    /**
     * When the update was created
     * @type {Date}
     * @memberof InternalStatusPageIncidentUpdate
     */
    created_at: Date;
    /**
     * Unique identifier for this incident update
     * @type {string}
     * @memberof InternalStatusPageIncidentUpdate
     */
    id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof InternalStatusPageIncidentUpdate
     */
    message?: TextNode;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof InternalStatusPageIncidentUpdate
     */
    new_incident_status: IncidentStatus;
    /**
     * 
     * @type {Severity}
     * @memberof InternalStatusPageIncidentUpdate
     */
    new_severity?: Severity;
    /**
     * When the incident is paused until
     * @type {Date}
     * @memberof InternalStatusPageIncidentUpdate
     */
    paused_until?: Date;
}

export function InternalStatusPageIncidentUpdateFromJSON(json: any): InternalStatusPageIncidentUpdate {
    return InternalStatusPageIncidentUpdateFromJSONTyped(json, false);
}

export function InternalStatusPageIncidentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageIncidentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'message': !exists(json, 'message') ? undefined : TextNodeFromJSON(json['message']),
        'new_incident_status': IncidentStatusFromJSON(json['new_incident_status']),
        'new_severity': !exists(json, 'new_severity') ? undefined : SeverityFromJSON(json['new_severity']),
        'paused_until': !exists(json, 'paused_until') ? undefined : (new Date(json['paused_until'])),
    };
}

export function InternalStatusPageIncidentUpdateToJSON(value?: InternalStatusPageIncidentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'message': TextNodeToJSON(value.message),
        'new_incident_status': IncidentStatusToJSON(value.new_incident_status),
        'new_severity': SeverityToJSON(value.new_severity),
        'paused_until': value.paused_until === undefined ? undefined : (value.paused_until.toISOString()),
    };
}

