import type { SVGProps } from "react";
import * as React from "react";
const SvgUndo = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 11a.75.75 0 0 0-.75.75v2.5c0 .689-.56 1.25-1.25 1.25H3.75a.75.75 0 0 0 0 1.5h10.5A2.752 2.752 0 0 0 17 14.25v-2.5a.75.75 0 0 0-.75-.75ZM6.47 10.78a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.061l-1.97-1.97H8c1.93 0 3.5 1.57 3.5 3.5a.75.75 0 0 0 1.5 0c0-2.757-2.243-5-5-5H5.56l1.97-1.97a.75.75 0 1 0-1.06-1.061l-3.25 3.25a.75.75 0 0 0 0 1.06l3.25 3.252Z"
    />
  </svg>
);
export default SvgUndo;
