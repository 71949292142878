/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageContentIncident,
    StatusPageContentIncidentFromJSON,
    StatusPageContentIncidentFromJSONTyped,
    StatusPageContentIncidentToJSON,
} from './StatusPageContentIncident';
import {
    StatusPageContentIncidentLink,
    StatusPageContentIncidentLinkFromJSON,
    StatusPageContentIncidentLinkFromJSONTyped,
    StatusPageContentIncidentLinkToJSON,
} from './StatusPageContentIncidentLink';

/**
 * 
 * @export
 * @interface StatusPageContentShowIncidentResponseBody
 */
export interface StatusPageContentShowIncidentResponseBody {
    /**
     * 
     * @type {StatusPageContentIncident}
     * @memberof StatusPageContentShowIncidentResponseBody
     */
    incident?: StatusPageContentIncident;
    /**
     * If the incident was requested for a parent page, this will contain links to the incident on each sub-page where it exists
     * @type {Array<StatusPageContentIncidentLink>}
     * @memberof StatusPageContentShowIncidentResponseBody
     */
    incident_links?: Array<StatusPageContentIncidentLink>;
}

export function StatusPageContentShowIncidentResponseBodyFromJSON(json: any): StatusPageContentShowIncidentResponseBody {
    return StatusPageContentShowIncidentResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentShowIncidentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentShowIncidentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident': !exists(json, 'incident') ? undefined : StatusPageContentIncidentFromJSON(json['incident']),
        'incident_links': !exists(json, 'incident_links') ? undefined : ((json['incident_links'] as Array<any>).map(StatusPageContentIncidentLinkFromJSON)),
    };
}

export function StatusPageContentShowIncidentResponseBodyToJSON(value?: StatusPageContentShowIncidentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident': StatusPageContentIncidentToJSON(value.incident),
        'incident_links': value.incident_links === undefined ? undefined : ((value.incident_links as Array<any>).map(StatusPageContentIncidentLinkToJSON)),
    };
}

