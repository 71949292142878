import {
  EscalationPathNodeTypeEnum as NodeTypes,
  EscalationPathTargetUrgencyEnum,
} from "@incident-io/api";
import { ulid } from "ulid";

import {
  EscalationPathTargetSelectionMode,
  EscalationPathTimeToAckOption,
  PathNode,
} from "../../common/types";

// Each of these helpers returns a PathNode, with an extra type annotation which
// ensures TypeScript understands which type of node it is - this helps avoid
// annoying checks for undefined!

export const makeIfElseNode = ({
  thenNodeId,
  elseNodeId,
}: {
  thenNodeId: string;
  elseNodeId: string;
}): PathNode & { data: { nodeType: NodeTypes.IfElse } } => {
  return {
    id: ulid(),
    data: {
      nodeType: NodeTypes.IfElse,
      ifElse: {
        thenNodeId: thenNodeId,
        elseNodeId: elseNodeId,
      },
    },
  };
};

export const makeLevelNode = ({
  nextNodeId,
}: {
  nextNodeId?: string;
}): PathNode & { data: { nodeType: NodeTypes.Level } } => {
  return {
    id: ulid(),
    data: {
      nodeType: NodeTypes.Level,
      level: {
        nextNodeId: nextNodeId,
        targets: [],
        targetSelectionMode: EscalationPathTargetSelectionMode.AllAtOnce,
        urgency: EscalationPathTargetUrgencyEnum.High,
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
      },
    },
  };
};

export const makeNotifyChannelNode = ({
  nextNodeId,
}: {
  nextNodeId: string;
}): PathNode & { data: { nodeType: NodeTypes.NotifyChannel } } => {
  return {
    id: ulid(),
    data: {
      nodeType: NodeTypes.NotifyChannel,
      notifyChannel: {
        nextNodeId: nextNodeId,
        targets: [],
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
      },
    },
  };
};

export const makeRepeatNode = ({
  toNodeId,
}: {
  toNodeId: string;
}): PathNode & { data: { nodeType: NodeTypes.Repeat } } => {
  return {
    id: ulid(),
    data: {
      nodeType: NodeTypes.Repeat,
      repeat: {
        repeat_times: "3",
        to_node: toNodeId,
      },
    },
  };
};
