import {
  IncidentStatus,
  PostIncidentFlow,
  PostIncidentStatusRank,
  PostIncidentTaskConfig,
  PostIncidentTaskConfigTaskTypeEnum,
  PostIncidentTaskOption,
  PostIncidentTaskOptionTaskTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  EmptyState,
  IconEnum,
  Link,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../@shared/settings/SettingsList/SettingsListItem";
import { DeleteStatusModal } from "../lifecycle/statuses/status-list/IncidentStatusRow";
import { SettingsSortableList } from "../SettingsSortableList";
import { PostIncidentFlowDeleteModal } from "./PostIncidentFlowDeleteModal";
import { PostIncidentTaskRow } from "./PostIncidentTaskRow";

export const PostIncidentStatusRow = ({
  flow,
  isOnlyFlow,
  status,
  taskOptions,
  onEdit,
}: {
  flow: PostIncidentFlow;
  isOnlyFlow: boolean;
  status: IncidentStatus;
  taskOptions: PostIncidentTaskOption[];
  onEdit: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const showToast = useToast();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { trigger: updateTaskRanks, isMutating: savingTaskRanks } =
    useAPIMutation(
      "postIncidentFlowList",
      undefined,
      async (
        apiClient,
        data: {
          status: IncidentStatus;
          taskRanks: { id: string; rank: number }[];
        },
      ) => {
        // First we build the full set of statuses and their tasks, with the new
        // orders within this status changed.
        const statusRanks = flow.incident_statuses.map((status, idx) => {
          const res: PostIncidentStatusRank = {
            incident_status_id: status.id,
            rank: idx,
            task_ranks: [],
          };
          if (status.id === data.status.id) {
            res.task_ranks = data.taskRanks.map((t) => ({
              resource_id: t.id,
              rank: t.rank,
            }));
          } else {
            res.task_ranks = flow.task_configs
              .filter((t) => t.incident_status_id === status.id)
              .map((t) => ({
                resource_id: t.id,
                rank: t.rank,
              }));
          }
          return res;
        });

        await apiClient.postIncidentFlowUpdateStatusTaskConfigRanks({
          id: flow.id,
          updateStatusTaskConfigRanksRequestBody: {
            status_ranks: statusRanks,
          },
        });
      },
      {
        onError: (_, fieldError) => {
          const errorMessage = (fieldError as { message?: string })?.message;
          showToast({
            title: "Invalid order",
            description: errorMessage ?? "You cannot place tasks in that order",
            theme: ToastTheme.Error,
          });
        },
      },
    );

  const tasksForStatus = flow.task_configs.filter(
    (x) => x.incident_status_id === status.id,
  );

  const isLastStatus = flow.incident_statuses.length === 1;

  return (
    <div className="flex-col flex-grow">
      <SettingsListItem
        title={status.name}
        icon={IconEnum.Status}
        description={
          <TemplatedTextDisplay
            value={status.description.text_node}
            style={TemplatedTextDisplayStyle.Compact}
            className="text-content-tertiary"
          />
        }
        buttons={{
          requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
          edit: { onEdit },
          delete: {
            onDelete: () => setShowConfirmModal(true),
            // We're handling our own deleteConfirmation, sadly.
            noDeleteConfirmation: true,
            isGatedText:
              isLastStatus && !isOnlyFlow
                ? "A post-incident flow must have at least one status."
                : undefined,
          },
        }}
      />
      {showConfirmModal &&
        (isLastStatus ? (
          <PostIncidentFlowDeleteModal
            onClose={() => setShowConfirmModal(false)}
            flow={flow}
          />
        ) : (
          <DeleteStatusModal
            status={status}
            onDelete={async (client) => {
              await client.incidentLifecyclesDestroyStatus({
                id: status.id,
              });
            }}
            onClose={() => setShowConfirmModal(false)}
          />
        ))}
      <div className={"p-4 pt-0 flex flex-col items-start"}>
        {tasksForStatus.length > 0 ? (
          <>
            <SettingsSortableList
              containerClassName="w-full grow"
              className="shadow-none"
              items={tasksForStatus}
              updateItemRanks={(taskRanks) =>
                updateTaskRanks({ status, taskRanks })
              }
              canEdit={canEditSettings}
              renderItem={(task) => <PostIncidentTaskRow task={task} />}
              offset={0}
              saving={savingTaskRanks}
            />
            <AddTaskButton
              incidentStatus={status}
              className={"mt-4"}
              tasks={flow.task_configs}
              taskOptions={taskOptions}
            />
          </>
        ) : (
          <EmptyState
            icon={IconEnum.Action}
            content="You haven't added any tasks to this status yet."
            cta={
              <AddTaskButton
                incidentStatus={status}
                className={"mt-2"}
                tasks={flow.task_configs}
                taskOptions={taskOptions}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export const AddTaskButton = ({
  className,
  tasks,
  taskOptions,
  incidentStatus,
}: {
  className?: string;
  tasks: PostIncidentTaskConfig[];
  taskOptions: PostIncidentTaskOption[];
  incidentStatus: IncidentStatus;
}) => {
  const { identity } = useIdentity();
  const customTasksEnabled =
    !!identity?.feature_gates?.post_incident_flow_custom_tasks;
  const isDisabled =
    !customTasksEnabled &&
    findAvailableSuggestedTasks(tasks, taskOptions).length === 0;
  const isDisabledTooltipContent = (
    <div>
      <Txt>
        You&apos;ve already added all suggested tasks, and custom tasks are not
        supported by your plan.
      </Txt>{" "}
      <Link
        to={"/settings/billing"}
        analyticsTrackingId={"upgrade-required-message"}
        className="text-white text-sm"
      >
        Upgrade
      </Link>
    </div>
  );

  return (
    <GatedButton
      analyticsTrackingId="create-post-incident-task"
      icon={IconEnum.Add}
      href={`/settings/post-incident-flow/${incidentStatus.id}/create`}
      className={className}
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      theme={ButtonTheme.Secondary}
      disabled={isDisabled}
      disabledTooltipContent={isDisabledTooltipContent}
    >
      Add task
    </GatedButton>
  );
};

// findAvailableTaskOptions finds the suggested tasks which haven't already been added
export function findAvailableSuggestedTasks(
  tasks: PostIncidentTaskConfig[],
  taskOptions: PostIncidentTaskOption[],
): PostIncidentTaskOption[] {
  return taskOptions.filter((option) => {
    // Remove the custom option
    if (option.task_type === PostIncidentTaskOptionTaskTypeEnum.Custom) {
      return false;
    }

    // Remove the Update timestamp option - we're deprecating it in favour of SetTimestamps
    if (
      option.task_type === PostIncidentTaskOptionTaskTypeEnum.UpdateTimestamps
    ) {
      return false;
    }

    // Always return the SetCustomFields option or SetTimestamps as these are always available
    // to add and can appear multiple times at different steps.
    if (
      option.task_type === PostIncidentTaskOptionTaskTypeEnum.SetCustomFields ||
      option.task_type === PostIncidentTaskOptionTaskTypeEnum.SetTimestamps
    ) {
      return true;
    }

    // If the option is already enabled, remove it
    return (
      tasks.filter(
        (x) =>
          x.task_type ===
            (option.task_type as unknown as PostIncidentTaskConfigTaskTypeEnum) &&
          x.incident_role_id === option.incident_role_id,
      ).length === 0
    );
  });
}
