import { Settings } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export function useIncidentTriageIncidentsFeatureGateEnabled(): boolean {
  const { identity } = useIdentity();
  return identity?.feature_gates?.triage_incidents || false;
}

export function useIncidentManualTriageIncidentsEnabled(
  settings: Settings | null,
): boolean {
  // Make sure the feature is available first
  return (
    (useIncidentTriageIncidentsFeatureGateEnabled() &&
      settings?.misc?.manual_triage_incidents_enabled) ||
    false
  );
}
