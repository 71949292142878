/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface IncidentLifecyclesListStatusesForIncidentResponseBody
 */
export interface IncidentLifecyclesListStatusesForIncidentResponseBody {
    /**
     * 
     * @type {Array<IncidentStatus>}
     * @memberof IncidentLifecyclesListStatusesForIncidentResponseBody
     */
    incident_statuses: Array<IncidentStatus>;
}

export function IncidentLifecyclesListStatusesForIncidentResponseBodyFromJSON(json: any): IncidentLifecyclesListStatusesForIncidentResponseBody {
    return IncidentLifecyclesListStatusesForIncidentResponseBodyFromJSONTyped(json, false);
}

export function IncidentLifecyclesListStatusesForIncidentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecyclesListStatusesForIncidentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_statuses': ((json['incident_statuses'] as Array<any>).map(IncidentStatusFromJSON)),
    };
}

export function IncidentLifecyclesListStatusesForIncidentResponseBodyToJSON(value?: IncidentLifecyclesListStatusesForIncidentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_statuses': ((value.incident_statuses as Array<any>).map(IncidentStatusToJSON)),
    };
}

