import type { SVGProps } from "react";
import * as React from "react";
const SvgRightSidebar = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.111 13.422h-8.4c-1.213 0-2.2-.986-2.2-2.2v-6.8c0-1.213.987-2.2 2.2-2.2h8.4c1.214 0 2.2.987 2.2 2.2v6.8c0 1.214-.986 2.2-2.2 2.2Zm-8.4-10c-.551 0-1 .449-1 1v6.8c0 .551.449 1 1 1h8.4c.551 0 1-.449 1-1v-6.8c0-.551-.449-1-1-1h-8.4Zm5.6.797h2.4a.6.6 0 0 1 .6.6v6a.6.6 0 0 1-.6.6h-2.4a.6.6 0 0 1-.6-.6v-6a.6.6 0 0 1 .6-.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRightSidebar;
