import {
  StaticMultiSelectWithObj,
  StaticMultiSelectWithObjProps,
} from "@incident-ui/Select/StaticMultiSelect";
import {
  StaticSingleSelectWithObj,
  StaticSingleSelectWithObjProps,
} from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

type TSingleInputType = Omit<StaticSingleSelectWithObjProps, "isDisabled"> & {
  disabled?: boolean;
};

export const StaticSingleSelectWithObjV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TSingleInputType>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    TSingleInputType
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <StaticSingleSelectWithObj
        id={name}
        {...field}
        {...(inputProps as StaticSingleSelectWithObjProps)}
        disabled={inputProps.disabled}
        onChange={(val: SelectOption) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

export const StaticMultiSelectWithObjV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, StaticMultiSelectWithObjProps>,
): React.ReactElement => {
  const { onValueChange } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const {
    // We use useAutoAdjustingMenuPlacement, so don't pass in a ref
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <StaticMultiSelectWithObj
        id={name}
        {...field}
        {...(inputProps as StaticMultiSelectWithObjProps)}
        onChange={(val) => {
          field.onChange(val);
          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};
