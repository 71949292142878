/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemDocumentRenderOptions,
    PostmortemDocumentRenderOptionsFromJSON,
    PostmortemDocumentRenderOptionsFromJSONTyped,
    PostmortemDocumentRenderOptionsToJSON,
} from './PostmortemDocumentRenderOptions';

/**
 * 
 * @export
 * @interface PostmortemsRenderMarkdownRequestBody
 */
export interface PostmortemsRenderMarkdownRequestBody {
    /**
     * 
     * @type {string}
     * @memberof PostmortemsRenderMarkdownRequestBody
     */
    incident_id: string;
    /**
     * The legacy template we should use when rendering the postmortem
     * @type {string}
     * @memberof PostmortemsRenderMarkdownRequestBody
     */
    legacy_template_id?: string;
    /**
     * 
     * @type {PostmortemDocumentRenderOptions}
     * @memberof PostmortemsRenderMarkdownRequestBody
     */
    postmortem_document_options: PostmortemDocumentRenderOptions;
    /**
     * The template we should use when rendering the postmortem
     * @type {string}
     * @memberof PostmortemsRenderMarkdownRequestBody
     */
    postmortem_template_id?: string;
}

export function PostmortemsRenderMarkdownRequestBodyFromJSON(json: any): PostmortemsRenderMarkdownRequestBody {
    return PostmortemsRenderMarkdownRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsRenderMarkdownRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsRenderMarkdownRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'legacy_template_id': !exists(json, 'legacy_template_id') ? undefined : json['legacy_template_id'],
        'postmortem_document_options': PostmortemDocumentRenderOptionsFromJSON(json['postmortem_document_options']),
        'postmortem_template_id': !exists(json, 'postmortem_template_id') ? undefined : json['postmortem_template_id'],
    };
}

export function PostmortemsRenderMarkdownRequestBodyToJSON(value?: PostmortemsRenderMarkdownRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'legacy_template_id': value.legacy_template_id,
        'postmortem_document_options': PostmortemDocumentRenderOptionsToJSON(value.postmortem_document_options),
        'postmortem_template_id': value.postmortem_template_id,
    };
}

