import { Callout, CalloutTheme } from "@incident-ui";

export const BulkNoTriggersNotice = ({
  className,
}: {
  className?: string;
}): React.ReactElement => {
  return (
    <Callout theme={CalloutTheme.Plain} className={className}>
      Your workflows will not be triggered by this change. To trigger them, you
      will need to edit the incidents individually.
    </Callout>
  );
};
