import {
  DateTimeInputV2,
  EditAsISOSuffix,
} from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { TimestampDescription } from "@incident-shared/timestamps/TimestampDescription";
import { Tooltip } from "@incident-ui";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { IncidentStatus, IncidentTimestamp } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

export const IncidentTimestampFormElementV2 = <TFormType extends FieldValues>({
  name,
  formMethods,
  timestamp,
  statuses,
  customValidate,
  required,
  validateOnChange,
  disabled = false,
}: {
  name: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  timestamp: IncidentTimestamp;
  statuses?: IncidentStatus[];
  customValidate?: (v: Date) => string | undefined;
  required: boolean;
  validateOnChange: boolean;
  disabled?: boolean;
}) => {
  const label = timestamp.name;

  const val = formMethods.watch(name);
  const isEmpty = !val;

  formMethods.register(name, {
    validate: (v) => {
      if (!v && required) {
        return "This value is required";
      }

      if (customValidate) {
        return customValidate(v);
      }

      return undefined;
    },
  });

  return (
    <>
      <div className="flex gap-1">
        <DateTimeInputV2
          name={name}
          label={label}
          labelAccessory={
            statuses ? (
              <Tooltip
                analyticsTrackingId={null}
                content={
                  <div className="flex flex-row items-center gap-1 text-sm flex-wrap">
                    <TimestampDescription
                      timestamp={timestamp}
                      compactAndDarkMode
                    />
                  </div>
                }
                side="top"
              />
            ) : undefined
          }
          required={required}
          formMethods={formMethods}
          className="grow"
          inputClassName={tcx("tabular-nums", {
            "!text-slate-300": isEmpty,
          })}
          onValueChange={(_) => {
            validateOnChange && formMethods.trigger();
          }}
          suffixNode={<EditAsISOSuffix id={name} formMethods={formMethods} />}
          disabled={disabled}
        />
      </div>
    </>
  );
};
