/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';

/**
 * 
 * @export
 * @interface SchedulesRestoreResponseBody
 */
export interface SchedulesRestoreResponseBody {
    /**
     * 
     * @type {Schedule}
     * @memberof SchedulesRestoreResponseBody
     */
    schedule: Schedule;
}

export function SchedulesRestoreResponseBodyFromJSON(json: any): SchedulesRestoreResponseBody {
    return SchedulesRestoreResponseBodyFromJSONTyped(json, false);
}

export function SchedulesRestoreResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesRestoreResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule': ScheduleFromJSON(json['schedule']),
    };
}

export function SchedulesRestoreResponseBodyToJSON(value?: SchedulesRestoreResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule': ScheduleToJSON(value.schedule),
    };
}

