import {
  EngineEvaluateExpressionRequestBody,
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineScope,
  ExpressionOperation,
  ExpressionOperationOperationTypeEnum,
  Reference,
  Resource,
  ResourceFieldConfigArrayTypeEnum,
  ResourceFieldConfigTypeEnum,
} from "@incident-io/api/models";
import { StaticSingleSelect, Tooltip } from "@incident-ui";
import { isEmpty, takeWhile, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { filterScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { EngineFormElement } from "../..";
import {
  ReferenceExampleOption,
  ReferenceWithExample,
} from "../ExpressionsEditor";
import { queryOperationToPayload } from "../expressionToPayload";
import { UNKNOWN_TYPE } from "./QueryExpressionEditModal";

// Given an example input, this hook returns the intermediate results of
// evaluating an expression with the given operations.
//
// The returned result bindings include the original input, then one result for
// each of the operations.
export const usePreviewResults = ({
  rootReference,
  scope,
  operations = [],
}: {
  rootReference?: Reference;
  scope: EngineScope;
  operations?: ExpressionOperation[];
}): {
  setExampleInput: (exampleInput?: EngineParamBindingPayload) => void;
  results: EngineParamBinding[];
} => {
  // Allow switching between examples.
  const [exampleInput, setExampleInput] = useState<EngineParamBindingPayload>();

  // Filter the scope to just top level references, and the root reference that we
  // want to override
  const topLevelReferences = filterScope(
    scope,
    (ref) => ref.key === rootReference?.key || !ref.key.includes("."),
  ).references.map((ref) =>
    ref.key === rootReference?.key ? { ...ref, binding: exampleInput } : ref,
  );

  // For each top-level reference, we want to also recursively include its parent
  // references
  const parentReferences = topLevelReferences.flatMap((ref: Reference) => {
    const parentRefs: Reference[] = [];
    let parentRef = ref;
    while (parentRef.parent) {
      const foundParent = scope.references.find(
        (r) => r.key === parentRef.parent,
      );
      if (!foundParent) {
        throw new Error("Parent reference not found in scope");
      }
      parentRef = foundParent;
      parentRefs.push(parentRef);
    }
    return parentRefs;
  });

  const allApplicableReferences = uniqBy(
    [...topLevelReferences, ...parentReferences],
    "key",
  );

  const payloadOperations = takeWhile(
    operations,
    (op) =>
      op.returns?.type &&
      op.returns.type !== UNKNOWN_TYPE &&
      !isEmptyFilter(op),
  ).map(queryOperationToPayload);

  const payload: EngineEvaluateExpressionRequestBody = {
    root_reference: rootReference?.key || "",
    operations: payloadOperations,
    scope: allApplicableReferences,
  };

  // Only evaluate if an example is available or if we are previewing a global catalog type.
  // This prevents us from re-requesting when we've just added another operation
  // and the form is temporarily invalid.
  const shouldEvaluate =
    rootReference ||
    (exampleInput &&
      (exampleInput.value || exampleInput.array_value) &&
      !isEmpty(payloadOperations));

  // We try to evaluate our query as we build it, so we can provide real-time
  // hypothetical results to each operation as we configure them.
  //
  // If evaluation fails, it's not a big deal. Everything needs to handle a
  // situation where no examples to perform valuation are provided, so failure
  // to get results should be treated similarly.
  const { data: evaluateData } = useAPI(
    // Only evaluate if an example is available or if we are previewing a global catalog type.
    // This prevents us from re-requesting when we've just added another operation
    // and the form is temporarily invalid.
    shouldEvaluate ? "engineEvaluateExpression" : null,
    {
      evaluateExpressionRequestBody: payload,
    },
    {
      // Keep the previous results around so when you add new operation, we
      // don't lose what we've previously computed.
      keepPreviousData: true,
    },
  );

  // Having now built our expression, we have example values that we can thread
  // into our components.
  //
  // Memoise this to avoid re-rendering things based on no change here
  const results: EngineParamBinding[] = useMemo(
    () =>
      evaluateData
        ? [evaluateData.input, ...evaluateData.operation_results]
        : [],
    [evaluateData],
  );

  return {
    setExampleInput,
    results,
  };
};

const isEmptyFilter = (op: ExpressionOperation): boolean => {
  if (
    op.operation_type === ExpressionOperationOperationTypeEnum.Filter &&
    isEmpty(op.filter?.condition_groups?.[0]?.conditions)
  ) {
    return true;
  }
  return false;
};

const refHasExample = (
  ref: ReferenceWithExample | Reference,
): ref is ReferenceWithExample => {
  return Object.hasOwn(ref, "example");
};

// Depending on the type of reference selected, this will power the
// right-hand-side of the top query expression row that will either:
//
// 1. If the reference provides examples, render a static input that allows
// selecting from them.
// 2. Otherwise if a supported type, will render an appropriate engine value
// input that can be used to seed the expression with values for testing.
export const QueryPreviewSelector = ({
  rootReference,
  resources,
  setExampleInput,
}: {
  rootReference?: ReferenceWithExample | Reference;
  resources: Resource[];
  setExampleInput: (exampleInput?: EngineParamBindingPayload) => void;
}) => {
  if (rootReference) {
    return refHasExample(rootReference) ? (
      <QueryPreviewSelectorWithStaticExamples
        rootReference={rootReference}
        setExampleInput={setExampleInput}
      />
    ) : (
      <QueryPreviewSelectorWithDynamicExample
        rootReference={rootReference}
        resources={resources}
        setExampleInput={setExampleInput}
      />
    );
  }

  return null;
};

// When we have static examples, we allow users to select from a list of those
// examples to seed the expression editor.
const QueryPreviewSelectorWithStaticExamples = ({
  rootReference,
  setExampleInput,
}: {
  rootReference?: ReferenceWithExample;
  setExampleInput: (exampleInput?: EngineParamBindingPayload) => void;
}) => {
  // Allow choosing between the examples if we've been provided with them.
  const [exampleID, setExampleID] = useState<string>(
    rootReference?.example?.initial || "",
  );

  // Whenever the key changes value, reset the chosen example ID to be the
  // initial example for this new reference.
  useEffect(() => {
    if (rootReference?.example) {
      setExampleID(rootReference.example.initial);
    }
  }, [rootReference?.key]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set the chosen example upward.
  useEffect(() => {
    let exampleOption: ReferenceExampleOption | undefined;
    if (rootReference?.example) {
      for (const group of rootReference.example.groups) {
        for (const option of group.options) {
          if (option.value === exampleID) {
            exampleOption = option;
          }
        }
      }
    }

    setExampleInput(exampleOption?.binding);
  }, [rootReference, exampleID, setExampleInput]);

  return (
    <QueryPreviewWindow
      label={"Choose preview"}
      overflow={"overflow-hidden"}
      tooltipMessage={rootReference?.example?.message}
    >
      <StaticSingleSelect
        className={"w-220px"}
        value={exampleID}
        onChange={(value) => {
          if (value && typeof value === "string") {
            setExampleID(value);
          }
        }}
        options={rootReference?.example?.groups || []}
        isClearable={false}
        renderDescription="below"
      />
    </QueryPreviewWindow>
  );
};

const QueryPreviewSelectorWithDynamicExample = ({
  rootReference,
  resources,
  setExampleInput,
}: {
  rootReference?: Reference;
  resources: Resource[];
  setExampleInput: (exampleInput?: EngineParamBindingPayload) => void;
}) => {
  type formData = {
    input: EngineParamBindingPayload;
  };

  // We create a sub-form as the engine components only know how to interact
  // with forms, and this component will already be mounted in an existing form.
  // For this to work, we use the FormProvider to ensure our components connect
  // to the right form: don't forget this, or we won't have access to any of our
  // input values!
  const formMethods = useForm<formData>();

  const input = formMethods.watch("input");
  const inputJSON = JSON.stringify(input);
  useEffect(() => {
    setExampleInput({ ...input }); // create a new reference otherwise the set won't fire
  }, [setExampleInput, input, inputJSON]);

  const resource = resources.find((res) => res.type === rootReference?.type);
  if (rootReference) {
    const isArrayType = rootReference.array;
    const isScalarFormFieldNone =
      resource?.field_config.type === ResourceFieldConfigTypeEnum.None;
    const isArrayFormFieldNone =
      resource?.field_config.array_type ===
      ResourceFieldConfigArrayTypeEnum.None;
    if (
      (!isArrayType && isScalarFormFieldNone) ||
      (isArrayType && isArrayFormFieldNone)
    ) {
      return null;
    }
  }

  return rootReference ? (
    <QueryPreviewWindow
      label={"Choose sample values"}
      overflow={"overflow-hidden"}
      tooltipMessage={
        "Pick an example input for this reference to power preview of each step."
      }
    >
      <FormProvider<formData> {...formMethods}>
        <EngineFormElement<formData>
          name={`input`}
          resources={resources}
          resourceType={rootReference.type}
          array={rootReference.array}
          showPlaceholder
          mode="plain_input"
          required
        />
      </FormProvider>
    </QueryPreviewWindow>
  ) : null;
};

// Provides the right-hand-side panel to the query expression editor in which we
// show intermediate step results.
export const QueryPreviewWindow = ({
  className,
  label = "PREVIEW",
  tooltipMessage,
  overflow = "overflow-auto",
  children,
}: {
  className?: string;
  label?: string;
  tooltipMessage?: string;
  overflow?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={tcx(
        "grow space-y-2 py-4 pl-4 bg-surface-secondary text-sm invisible xl:visible",
        "shadow-[inset_6px_0px_10px_-11px_rgba(0,0,0,0.5)]",
        className,
      )}
    >
      {/* Apply a 20px height so regardless of whether we have a tooltip, we're consistently sized */}
      <div className={"flex items-center h-[20px]"}>
        <span
          className={
            "text-xs text-content-tertiary font-semibold tracking-widest uppercase"
          }
        >
          {label}
        </span>
        {tooltipMessage ? (
          <Tooltip
            content={tooltipMessage}
            buttonClassName={"ml-1 flex-none"}
          />
        ) : null}
      </div>
      <div className={tcx("max-w-[280px] max-h-[380px]", overflow)}>
        {children}
      </div>
    </div>
  );
};
