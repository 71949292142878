import { Product } from "@incident-shared/billing";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { Loader, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useForm } from "react-hook-form";
import {
  AutoJoinSlackSettings as FormType,
  ScopeNameEnum,
  Settings,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../SettingsSection";

export const AutomationAutoJoinSlackEditForm = (): React.ReactElement => {
  const { settings } = useSettings();

  if (!settings) {
    return <Loader />;
  }

  return <AutomationAutoJoinSlackEditFormInner settings={settings} />;
};

const toForm = (settings: Settings): FormType => ({
  ...settings.workflows_alpha.auto_join_slack,
});

const AutomationAutoJoinSlackEditFormInner = ({
  settings,
}: {
  settings: Settings;
}) => {
  const apiClient = useClient();

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const { featureDeprecatedAutomationOptions } = useFlags();

  const formMethods = useForm<FormType>({
    defaultValues: toForm(settings),
  });

  const {
    watch,
    reset,
    setError,
    formState: { isDirty },
  } = formMethods;

  const enabled = watch("enabled");
  const showToast = useToast();
  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data) =>
      await apiClient.settingsUpdateAutoJoinSlack({
        updateAutoJoinSlackRequestBody: { auto_join_slack: data },
      }),
    {
      onSuccess: ({ settings }) => {
        reset(toForm(settings));
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      setError,
    },
  );

  // If featureDeprecatedAutomationOptions is enabled, we always show this settings section
  // even if it's unset
  if (!featureDeprecatedAutomationOptions) {
    if (!enabled && !isDirty) {
      // We're looking to deprecate Workflows Alpha
      // If someone wants this feature, they should instead do it via a workflow
      return <></>;
    }
  }

  return (
    <SettingsSection
      requiredProduct={Product.Response}
      mutation={mutation}
      formMethods={formMethods}
      enabledPath="enabled"
      title="Incident Watchers"
      explanation={`When enabled, we’ll automatically invite all the users you select below to new incident channels.`}
    >
      <DynamicMultiSelectV2
        formMethods={formMethods}
        name={"config.user_ids"}
        label="Select users"
        placeholder="Please choose..."
        isDisabled={!canEditSettings}
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {})}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
      />
    </SettingsSection>
  );
};
