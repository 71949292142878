/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EscalationPathShift
 */
export interface EscalationPathShift {
    /**
     * Time that this shift ends
     * @type {Date}
     * @memberof EscalationPathShift
     */
    end_at?: Date;
    /**
     * If this shift targets a user via a schedule, the ID of that schedule.
     * @type {string}
     * @memberof EscalationPathShift
     */
    schedule_id?: string;
    /**
     * Time that this shift starts
     * @type {Date}
     * @memberof EscalationPathShift
     */
    start_at?: Date;
    /**
     * The urgency of any escalations for this shift
     * @type {string}
     * @memberof EscalationPathShift
     */
    urgency: EscalationPathShiftUrgencyEnum;
    /**
     * 
     * @type {User}
     * @memberof EscalationPathShift
     */
    user: User;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathShiftUrgencyEnum {
    High = 'high',
    Low = 'low'
}

export function EscalationPathShiftFromJSON(json: any): EscalationPathShift {
    return EscalationPathShiftFromJSONTyped(json, false);
}

export function EscalationPathShiftFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathShift {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'schedule_id': !exists(json, 'schedule_id') ? undefined : json['schedule_id'],
        'start_at': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'urgency': json['urgency'],
        'user': UserFromJSON(json['user']),
    };
}

export function EscalationPathShiftToJSON(value?: EscalationPathShift | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': value.end_at === undefined ? undefined : (value.end_at.toISOString()),
        'schedule_id': value.schedule_id,
        'start_at': value.start_at === undefined ? undefined : (value.start_at.toISOString()),
        'urgency': value.urgency,
        'user': UserToJSON(value.user),
    };
}

