"use client";

import {
  InternalStatusPageIncident,
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentIncident,
  StatusPageContentListComponentImpactsResponseBody,
  StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum,
} from "@incident-io/api";
import {
  STATUS_SEVERITY,
  useNow,
  useTimeMaths,
} from "@incident-io/status-page-ui";
import cx from "classnames";
import _ from "lodash";
import { DateTime } from "luxon";

import { ContentBox } from "../ContentBox";
import { TimePicker } from "../TimePicker/TimePicker";
import { startAtFrom } from "./helpers";
import { ItemStatus } from "./ItemStatus";

export type ComponentStructureItem = {
  id: string;
  displayUptime: boolean;
  hidden: boolean;
  name: string;
  description?: string;
};

export type GroupStructureItem = {
  id: string;
  displayUptime: boolean;
  hidden: boolean;
  name: string;
  description?: string;
  components: ComponentStructureItem[];
};

export type GenericStructure = {
  items: GenericStructureItem[];
  display_uptime_mode: DisplayUptimeModeEnum;
};

export type GenericStructureItem =
  | {
      component: ComponentStructureItem;
      group?: never;
    }
  | {
      component?: never;
      group: GroupStructureItem;
    };

export type GenericAffectedComponent = {
  component_id: string;
  status: StatusPageAffectedComponentStatusEnum;
};

export const SystemStatus = ({
  componentImpacts,
  affectedComponents,
  structure,
  isLoading,
  endAt,
  setEndAt,
  dataAvailableSince,
  maintenances,
  withoutTooltips = false,
  autoExpandGroups = false,
  incidents,
}: {
  componentImpacts: StatusPageContentListComponentImpactsResponseBody;
  affectedComponents: GenericAffectedComponent[];
  structure: GenericStructure;
  isLoading: boolean;
  endAt: DateTime;
  setEndAt: (endAt: DateTime) => void;
  dataAvailableSince: DateTime;
  maintenances?: Array<StatusPageContentIncident>;
  withoutTooltips?: boolean;
  autoExpandGroups?: boolean;
  incidents: InternalStatusPageIncident[] | null;
}): React.ReactElement | null => {
  if (!structure) {
    throw new Error("structure is undefined");
  }
  if (!componentImpacts) {
    throw new Error("impact data is undefined");
  }
  // Don't render without any components, it's not really useful.
  if (structure.items.length === 0) {
    return null;
  }

  return (
    <SystemStatusRender
      affectedComponents={affectedComponents}
      data={componentImpacts}
      isLoading={isLoading}
      endAt={endAt}
      setEndAt={setEndAt}
      dataAvailableSince={dataAvailableSince}
      structure={structure}
      maintenances={maintenances}
      withoutTooltips={withoutTooltips}
      autoExpandGroups={autoExpandGroups}
      incidents={incidents}
    />
  );
};

export const SystemStatusRender = ({
  data,
  isLoading,
  endAt,
  setEndAt,
  dataAvailableSince,
  structure,
  affectedComponents,
  maintenances,
  withoutTooltips = false,
  autoExpandGroups = false,
  incidents,
}: {
  data: StatusPageContentListComponentImpactsResponseBody;
  affectedComponents: GenericAffectedComponent[];
  isLoading: boolean;
  endAt: DateTime;
  setEndAt: (endAt: DateTime) => void;
  dataAvailableSince: DateTime;
  structure: GenericStructure;
  maintenances?: Array<StatusPageContentIncident>;
  withoutTooltips?: boolean;
  autoExpandGroups?: boolean;
  incidents: InternalStatusPageIncident[] | null;
}): React.ReactElement | null => {
  // Avoid re-rendering due to a not-real change
  const startAt = useTimeMaths(startAtFrom(endAt));
  const now = useNow(10 * 1000, "minute");

  const currentComponentStatuses: Record<
    string,
    StatusPageAffectedComponentStatusEnum | undefined
  > = _.mapValues(
    _.groupBy(affectedComponents, ({ component_id }) => component_id),
    (affectedComponents) =>
      _.maxBy(
        affectedComponents.map(({ status }) => status),
        (status) => STATUS_SEVERITY[status],
      ),
  );

  const visibleStructure = getVisibleInternalStructure(structure);
  if (visibleStructure.length === 0) return null;

  const showingSomeUptime = visibleStructure.some((item) => {
    if (item.group) {
      // Aggreggated uptime is not relevant here: if it's on, it only takes
      // effect if there is data to aggregate; if it's off, you should still
      // be able to browse through data on individual components.
      return item.group.components.some((component) => component.displayUptime);
    } else {
      return item.component.displayUptime;
    }
  });

  const componentIncidents: {
    [componentId: string]: InternalStatusPageIncident[];
  } = {};

  if (incidents) {
    incidents.forEach((incident) => {
      incident.affected_components.forEach((component) => {
        if (!componentIncidents[component.id]) {
          componentIncidents[component.id] = [];
        }
        componentIncidents[component.id].push(incident);
      });
    });
  }

  return (
    <ContentBox
      title={
        <TimePicker
          startAt={startAt}
          endAt={endAt}
          setEndAt={setEndAt}
          now={now}
          dataAvailableSince={dataAvailableSince}
          maintenances={maintenances}
          showPicker={
            structure.display_uptime_mode !== DisplayUptimeModeEnum.Nothing &&
            showingSomeUptime
          }
          translationsNamespace={"SystemStatus"}
        />
      }
      padded={false}
    >
      <div
        className={cx(
          "divide-y divide-solid text-sm",
          "divide-slate-100",
          "dark:divide-slate-700",
        )}
      >
        {visibleStructure.map((item) => {
          const id = item.group ? item.group.id : item.component.id;

          return (
            <div key={id} className={"p-4 md:pt-3 md:pb-3 text-sm"}>
              <ItemStatus
                showChart={
                  structure.display_uptime_mode !==
                  DisplayUptimeModeEnum.Nothing
                }
                currentComponentStatuses={currentComponentStatuses}
                item={item}
                data={data}
                isLoading={isLoading}
                startAt={startAt}
                endAt={endAt}
                withoutTooltips={withoutTooltips}
                autoExpandGroups={autoExpandGroups}
                incidents={componentIncidents}
              />
            </div>
          );
        })}
      </div>
    </ContentBox>
  );
};

export const getVisibleInternalStructure = (
  structure: GenericStructure,
): GenericStructureItem[] =>
  _.compact(
    structure.items.map((item) => {
      if (item.group) {
        const visibleComponents = item.group.components.filter(
          (component) => !component.hidden,
        );

        return item.group.hidden || visibleComponents.length === 0
          ? undefined
          : {
              ...item,
              group: {
                ...item.group,
                components: visibleComponents,
              },
            };
      } else {
        return item.component.hidden ? undefined : item;
      }
    }),
  ) as GenericStructureItem[];
