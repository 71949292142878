import { tcx } from "src/utils/tailwind-classes";

export type NumberedListProps = {
  children: React.ReactNode[];
  className?: string;
};

export const NumberedList = ({ children, className }: NumberedListProps) => {
  return (
    <ol className={tcx("flex flex-col relative", className)}>
      {children.map((child, i) => (
        <li key={i} className={"flex items-stretch pb-2 last:pb-0"}>
          <div className={"flex flex-col"}>
            {/* Show the index in a rounded circle */}
            <div
              className={
                "bg-surface-invert rounded-full w-[21px] h-[21px] shrink-0 text-white flex items-center justify-center font-semibold border-2 border-white mr-4 text-[10px]"
              }
            >
              {i + 1}
            </div>
            {/* Show the grey line between heach numbered item */}
            {i !== children.length - 1 && (
              <div
                className={tcx(
                  "ml-[10px] w-[1px] bg-surface-tertiary flex-1 min-h-[16px] -mb-2",
                )}
              />
            )}
          </div>
          {/* Actually render the item's content */}
          {child}
        </li>
      ))}
    </ol>
  );
};
