/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertSourceSlim
 */
export interface AlertSourceSlim {
    /**
     * Explanation of what this source is
     * @type {string}
     * @memberof AlertSourceSlim
     */
    docstring: string;
    /**
     * Whether this alert source is exclusively resolved externally
     * @type {boolean}
     * @memberof AlertSourceSlim
     */
    externally_resolved: boolean;
    /**
     * Maximum number of instances of this alert source that can be created
     * @type {number}
     * @memberof AlertSourceSlim
     */
    max_instances: number;
    /**
     * Human readable name of this source
     * @type {string}
     * @memberof AlertSourceSlim
     */
    name: string;
}

export function AlertSourceSlimFromJSON(json: any): AlertSourceSlim {
    return AlertSourceSlimFromJSONTyped(json, false);
}

export function AlertSourceSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'docstring': json['docstring'],
        'externally_resolved': json['externally_resolved'],
        'max_instances': json['max_instances'],
        'name': json['name'],
    };
}

export function AlertSourceSlimToJSON(value?: AlertSourceSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'docstring': value.docstring,
        'externally_resolved': value.externally_resolved,
        'max_instances': value.max_instances,
        'name': value.name,
    };
}

