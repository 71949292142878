/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageStructureComponentPayload
 */
export interface StatusPageStructureComponentPayload {
    /**
     * The ID of the component this references
     * @type {string}
     * @memberof StatusPageStructureComponentPayload
     */
    component_id: string;
    /**
     * Whether to display historical uptime for this component
     * @type {boolean}
     * @memberof StatusPageStructureComponentPayload
     */
    display_uptime: boolean;
    /**
     * Whether this component is hidden from display on the status page
     * @type {boolean}
     * @memberof StatusPageStructureComponentPayload
     */
    hidden: boolean;
}

export function StatusPageStructureComponentPayloadFromJSON(json: any): StatusPageStructureComponentPayload {
    return StatusPageStructureComponentPayloadFromJSONTyped(json, false);
}

export function StatusPageStructureComponentPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureComponentPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_id': json['component_id'],
        'display_uptime': json['display_uptime'],
        'hidden': json['hidden'],
    };
}

export function StatusPageStructureComponentPayloadToJSON(value?: StatusPageStructureComponentPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.component_id,
        'display_uptime': value.display_uptime,
        'hidden': value.hidden,
    };
}

