import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  StackedList,
} from "@incident-ui";
import { captureMessage } from "@sentry/react";
import _ from "lodash";
import React, { useState } from "react";
import { StatusPageComponentStatusIcon } from "src/components/status-pages/incidents/view/StatusIcons";
import {
  IncidentStatusCategoryEnum,
  InternalStatusPage,
  InternalStatusPageComponent,
  InternalStatusPageComponentGroup,
  InternalStatusPageComponentOrGroup,
  InternalStatusPageStructure,
  ScopeNameEnum,
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

export const ComponentsList = ({
  page,
  structure,
}: {
  page: InternalStatusPage;
  structure: InternalStatusPageStructure;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  const {
    data: { internal_status_page_incidents: incidents },
    error,
    isLoading,
  } = useAPI(
    "internalStatusPageListIncidents",
    { internalStatusPageId: page.id },
    { fallbackData: { internal_status_page_incidents: [] } },
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const ongoingIncidents = incidents.filter(
    (inc) => inc.incident_status.category === IncidentStatusCategoryEnum.Active,
  );

  const affectedComponentIds = _.uniq(
    ongoingIncidents.flatMap((inc) =>
      inc.affected_components.map((comp) => comp.id),
    ),
  );

  const visibleStructure = getVisibleInternalStructure(structure.components);

  return (
    <div className="flex-initial min-w-[250px] group/edit-components 2xl:sticky 2xl:self-start">
      <div className="flex items-center mb-2">
        <GatedButton
          href={`/status-pages/internal/${page.id}/settings/components`}
          theme={ButtonTheme.Naked}
          analyticsTrackingId={"internal-status-page-edit-components"}
          analyticsTrackingMetadata={{ internal_status_page_id: page.id }}
          title="edit"
          disabled={!hasScope(ScopeNameEnum.StatusPagesConfigure)}
          disabledTooltipContent={
            "You do not have permission to configure this internal status page"
          }
          className="!gap-1"
        >
          <Heading level={3} size="small" className="!font-medium">
            Components
          </Heading>
          <Icon
            id={IconEnum.Edit}
            size={IconSize.Medium}
            className="opacity-0 group-hover/edit-components:opacity-100"
          />
        </GatedButton>
      </div>

      {visibleStructure.length > 0 ? (
        <StackedList className="text-sm">
          {visibleStructure.map((item, idx) => {
            if (item.component) {
              return (
                <ComponentRow
                  key={idx}
                  component={item.component}
                  affectedComponentIds={affectedComponentIds}
                />
              );
            } else if (item.group) {
              return (
                <GroupRow
                  key={idx}
                  group={item.group}
                  affectedComponentIds={affectedComponentIds}
                />
              );
            } else {
              captureMessage(
                "status page structure item with neither component nor group",
                { extra: { item, status_page_id: page.id } },
              );
              return null;
            }
          })}
        </StackedList>
      ) : (
        <div className="text-sm text-slate-600">All components are hidden </div>
      )}
    </div>
  );
};

const GroupRow = ({
  group,
  affectedComponentIds,
}: {
  group: InternalStatusPageComponentGroup;
  affectedComponentIds: string[];
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const groupIsAffected = group.components.some((component) =>
    affectedComponentIds.includes(component.id),
  );

  return (
    <li className={tcx("pr-2", isOpen ? "pl-3" : "pl-2")}>
      <Button
        className={tcx("mt-3 mb-0 !transition-none w-full")}
        onClick={() => setIsOpen(!isOpen)}
        analyticsTrackingId={null}
        theme={ButtonTheme.Naked}
      >
        {isOpen ? null : (
          <StatusPageComponentStatusIcon
            status={
              groupIsAffected
                ? ComponentStatusEnum.FullOutage
                : ComponentStatusEnum.Operational
            }
            className="flex-none"
          />
        )}
        <div
          className={tcx(
            "shrink truncate",
            isOpen ? "text-slate-600" : "text-content-primary",
          )}
        >
          {group.label}
        </div>
        <Icon
          id={isOpen ? IconEnum.ChevronUp : IconEnum.ChevronDown}
          size={IconSize.Small}
          className="ml-1 group-hover:text-content-primary transition flex-none"
        />
      </Button>
      <ul className={tcx("pb-2", isOpen && "pt-1")}>
        {isOpen
          ? (group.components || []).map((component) => (
              <ComponentRow
                key={component.id}
                component={component}
                affectedComponentIds={affectedComponentIds}
                isSmall
              />
            ))
          : null}
      </ul>
    </li>
  );
};

const ComponentRow = ({
  component,
  affectedComponentIds,
  isSmall = false,
}: {
  component: InternalStatusPageComponent;
  affectedComponentIds: string[];
  isSmall?: boolean;
}): React.ReactElement => {
  return (
    <li
      className={tcx(
        "text-slate-900 flex items-center px-2 space-x-1 w-full",
        isSmall ? "py-1" : "py-3",
      )}
    >
      <StatusPageComponentStatusIcon
        status={
          affectedComponentIds.includes(component.id)
            ? ComponentStatusEnum.FullOutage
            : ComponentStatusEnum.Operational
        }
        className="flex-none"
      />
      <div className="shrink truncate">{component.label}</div>
    </li>
  );
};

const getVisibleInternalStructure = (
  structure: InternalStatusPageComponentOrGroup[],
): InternalStatusPageComponentOrGroup[] =>
  _.compact(
    structure.map((item) => {
      if (item.group) {
        const visibleComponents = item.group.components.filter(
          (component) => !component.hidden,
        );

        return item.group.hidden || visibleComponents.length === 0
          ? undefined
          : {
              ...item,
              group: {
                ...item.group,
                components: visibleComponents,
              },
            };
      } else {
        return item.component?.hidden ? undefined : item;
      }
    }),
  ) as InternalStatusPageComponentOrGroup[];
