import type { SVGProps } from "react";
import * as React from "react";
const SvgBug = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15 12.5h2.25a.75.75 0 0 0 0-1.5H15V9.915A3.503 3.503 0 0 0 17.75 6.5a.75.75 0 0 0-1.5 0c0 .962-.683 1.766-1.588 1.956A2.737 2.737 0 0 0 13 7.117V6.5c0-1.654-1.346-3-3-3s-3 1.346-3 3v.617a2.743 2.743 0 0 0-1.662 1.339A2.002 2.002 0 0 1 3.75 6.5a.75.75 0 0 0-1.5 0c0 1.672 1.18 3.07 2.75 3.415V11H2.75a.75.75 0 0 0 0 1.5H5v.5c0 .195.031.382.052.571A3.505 3.505 0 0 0 2.25 17a.75.75 0 0 0 1.5 0c0-1.001.742-1.825 1.703-1.97a5.005 5.005 0 0 0 3.797 2.894V12.75a.75.75 0 0 1 1.5 0v5.174a5.004 5.004 0 0 0 3.797-2.894A1.998 1.998 0 0 1 16.25 17a.75.75 0 0 0 1.5 0 3.505 3.505 0 0 0-2.802-3.429c.022-.189.052-.375.052-.571v-.5ZM8.5 7v-.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5V7h-3Z"
    />
  </svg>
);
export default SvgBug;
