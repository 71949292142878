import {
  drawerUrlFor,
  INTEGRATION_CONFIGS,
  IntegrationListProvider,
  IntegrationsListObject,
  SCIMProviderEnum,
} from "@incident-shared/integrations";
import { GenericErrorMessage } from "@incident-ui";
import { addHours } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Route, Routes, useOutlet } from "react-router";
import { INTEGRATION_REDIRECT_LOCAL_STORAGE_KEY_PREFIX } from "src/components/settings/integrations/IntegrationsRedirectRoutes";
import { useIntegrationsList } from "src/components/settings/integrations/IntegrationsRoute";
import { IntegrationDrawer } from "src/components/settings/integrations/list/IntegrationDrawer";
import { useQueryParams } from "src/utils/query-params";

export const CatalogWizardIntegrationDrawerRoutes = ({
  backHref,
}: {
  backHref: string;
}) => {
  const outlet = useOutlet();
  const { integrations, error } = useIntegrationsList();
  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  return (
    <>
      <AnimatePresence>{outlet}</AnimatePresence>
      <Routes>
        {Object.keys(INTEGRATION_CONFIGS).map((provider) => (
          <Route
            key={provider}
            path={`/integrate/${drawerUrlFor(
              provider as IntegrationListProvider,
            )}`}
            element={
              <IntegrationDrawerWithLocalStorage
                provider={provider as IntegrationListProvider}
                integrations={integrations}
                backHref={backHref}
              />
            }
          />
        ))}
        {Object.values(SCIMProviderEnum).map((provider) => (
          <Route
            key={provider}
            path={`/integrate/${drawerUrlFor(
              provider as IntegrationListProvider,
            )}`}
            element={
              <IntegrationDrawer
                activeIntegration={provider as IntegrationListProvider}
                integrations={integrations}
                backHref={backHref}
                forTeam={true}
              />
            }
          />
        ))}
      </Routes>
    </>
  );
};

const IntegrationDrawerWithLocalStorage = ({
  provider,
  integrations,
  backHref,
}: {
  provider: IntegrationListProvider;
  integrations: IntegrationsListObject[];
  backHref: string;
}) => {
  const localStorageKey =
    INTEGRATION_REDIRECT_LOCAL_STORAGE_KEY_PREFIX + provider;

  const queryParams = useQueryParams();
  const returnParam = queryParams.get("return");

  // If we have a `return` in the query params, we should set that in localstorage so we know
  // we want to come back here
  useEffect(() => {
    const twoHoursTime = addHours(new Date(), 2);
    if (returnParam) {
      localStorage.setItem(
        localStorageKey,
        returnParam + "|" + twoHoursTime.toISOString(),
      );
    }
  }, [returnParam, localStorageKey]);

  return (
    <IntegrationDrawer
      activeIntegration={provider}
      integrations={integrations}
      backHref={backHref}
    />
  );
};
