import { Button, ButtonTheme, IconEnum, IconSize } from "@incident-ui";
import React, { useEffect, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { usePrevious } from "use-hooks";

import styles from "./AssistantAvatar.module.scss";
import { useAssistantOverlay } from "./AssistantOverlayContext";

// AssistantInput is the input field that appears at the bottom of Assistant's chat overlay.
export const AssistantInput = ({
  assistantId,
  threadId,
  canSubmit,
  submitQuestion,
}: {
  assistantId: string | null;
  threadId: string | null;
  canSubmit: boolean;
  submitQuestion: (question: string) => void;
}) => {
  const { isOverlayOpen } = useAssistantOverlay();
  const [inputQuestion, setInputQuestion] = useState("");

  const ref = React.createRef<HTMLTextAreaElement>();

  const adjustHeight = () => {
    if (ref?.current != null) {
      const offset = ref.current.offsetHeight - ref.current.clientHeight;
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + offset + "px";
    }
  };

  const resetHeight = () => {
    if (ref && "current" in ref && ref.current != null) {
      ref.current.style.height = "auto";
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (!event.shiftKey && event.key === "Enter") {
      event.preventDefault();
      if (!canSubmit || inputQuestion.trim() === "") {
        return;
      }
      handleQuerySubmit();
    }
  };

  const handleQuerySubmit = () => {
    // We're duplicating this logic because we sometimes
    // call this method directly
    if (!canSubmit || inputQuestion.trim() === "") {
      return;
    }
    if (!assistantId || !threadId) {
      return;
    }
    submitQuestion(inputQuestion);
    setInputQuestion("");
    resetHeight();
  };

  // when the overlay transitions from closed to opened, lets re-focus the input
  const previousIsOpen = usePrevious(isOverlayOpen);
  useEffect(() => {
    if (previousIsOpen === false && isOverlayOpen === true) {
      ref.current?.focus();
    }
  }, [previousIsOpen, isOverlayOpen, ref]);

  // when we finish loading a question, lets re-focus the input
  const previousCanSubmit = usePrevious(canSubmit);
  useEffect(() => {
    if (!previousCanSubmit && canSubmit && isOverlayOpen) {
      ref.current?.focus();
    }
  }, [previousCanSubmit, canSubmit, isOverlayOpen, ref]);

  return (
    <div
      className={
        canSubmit
          ? tcx(
              styles.rainbowAnimation,
              styles.rainbowAnimationPaused,
              "border-stroke w-full rounded-xl relative",
            )
          : tcx(
              styles.rainbowAnimation,
              "border-stroke w-full rounded-xl relative",
            )
      }
    >
      <textarea
        ref={ref}
        autoFocus={true}
        id="assistant_question_input"
        rows={1}
        className={`relative w-full overflow-y-hidden box-border whitespace-pre-wrap p-4
             bg-transparent border-none focus:border-none focus:ring-none z-10 text-content-primary
             focus:outline-none focus:shadow-none focus:ring-0 resize-none text-sm`}
        onChange={(e) => {
          setInputQuestion((e.target as HTMLTextAreaElement).value);
          adjustHeight();
        }}
        value={inputQuestion}
        onKeyDown={handleKeyDown}
      />
      <Button
        title="send"
        analyticsTrackingId={null}
        icon={IconEnum.ArrowCircleUp}
        onClick={handleQuerySubmit}
        theme={ButtonTheme.Naked}
        iconProps={{
          size: IconSize.XL,
          className: "child:stroke-[1.5px]",
        }}
        className={`rounded self-stretch z-10 px-1
            !no-underline opacity-50 my-2 mr-2.5 pointer-events-none ${
              canSubmit &&
              inputQuestion.trim() !== "" &&
              "opacity-90 pointer-events-auto"
            }`}
      />
    </div>
  );
};
