import type { SVGProps } from "react";
import * as React from "react";
const SvgCloseCircle = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm3.28 10.22a.75.75 0 0 1-1.06 1.061L10 11.061l-2.22 2.22a.748.748 0 0 1-1.06 0 .75.75 0 0 1 0-1.061L8.94 10 6.72 7.78a.75.75 0 1 1 1.061-1.061l2.22 2.22 2.22-2.22a.75.75 0 1 1 1.061 1.061L11.062 10l2.22 2.22h-.002Z"
    />
  </svg>
);
export default SvgCloseCircle;
