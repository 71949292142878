import type { SVGProps } from "react";
import * as React from "react";
const SvgSplunk = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <rect width={16} height={16} x={2} y={2} fill="#64A525" rx={2} />
    <path
      fill="#F5F5F5"
      stroke="#F5F5F5"
      strokeWidth={0.794}
      d="M14 9.165v1.698L6 15v-1.826L12.2 10 6 6.868V5l8 4.165Z"
    />
  </svg>
);
export default SvgSplunk;
