import { RegisterOptions } from "react-hook-form";

export type Rules = Omit<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs"
>;

export type FormRules = {
  // Use `rules` if you want to provide custom rules (using react-hook-form's docs)
  rules?: Rules;
  //   Return 'true' to return a standard error message, or if it's a string we'll use that as the error message
  required?: boolean | string;
};

export const buildRules = (
  required?: boolean | string,
  rules: Rules = {},
): Rules => {
  const res = rules;
  if (required === true) {
    res.required = "This field is required";
  } else if (typeof required === "string") {
    res.required = required;
  } else {
    // Set this explicitly, so that if we go from required to non-required, we
    // actively overwrite the value in react-hook-form.
    res.required = false;
  }
  return res;
};
