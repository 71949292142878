import { CopyToTerraformDrawer } from "@incident-shared/management-meta/CopyToTerraformDrawer";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ManagedResourceResourceTypeEnum as ResourceTypeEnum } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { formStateToPayloadRequestBody } from "../../common/marshall";
import { WorkflowFormData } from "../../common/types";
import { useWorkflowsDeps } from "../WorkflowsFormContext";

export const WorkflowsCopyToTerraformDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): React.ReactElement => {
  const { workflow, management, trigger } = useWorkflowsDeps();

  const formMethods = useFormContext<WorkflowFormData>();
  const workflowName = formMethods.watch("name");

  // Get scope and resources to power an upcoming filter operation.
  const { data, isLoading, error } = useAPI(
    isOpen ? "workflowsGenerateTerraformForWorkflow" : null,
    {
      generateTerraformForWorkflowRequestBody: {
        id: workflow?.id,
        trigger: trigger?.name || "",
        workflow: formStateToPayloadRequestBody(formMethods.getValues()),
      },
    },
    { keepPreviousData: true },
  );

  return (
    <CopyToTerraformDrawer
      isOpen={isOpen}
      onClose={onClose}
      terraform={data?.terraform}
      terraformIsLoading={isLoading}
      terraformError={error}
      resourceType={ResourceTypeEnum.Workflow}
      resourceID={workflow?.id}
      resourceName={workflowName}
      managementMeta={management}
    />
  );
};
