import {
  CustomField,
  CustomFieldTypeInfo,
  CustomFieldTypeInfoFieldTypeEnum,
  EnrichedImage,
  Incident,
  PostmortemTemplateSection,
  PostmortemTemplateSectionConfig,
  PostmortemTemplateSectionResponse,
} from "@incident-io/api";
import { renderCustomFieldValues } from "@incident-shared/custom-fields/renderCustomFieldValues";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Modal,
  ModalContent,
  ModalFooter,
  Tooltip,
} from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { SectionWrapper } from "../body/SectionWrapper";
import { PostmortemSectionEditor } from "./PostmortemSectionEditor";

export const PostmortemCustomSection = ({
  incident,
  templateId,
  section,
  existingResponse,
  allCustomFields,
}: {
  incident: Incident;
  templateId: string;
  section: PostmortemTemplateSection;
  existingResponse?: PostmortemTemplateSectionResponse;
  allCustomFields: CustomField[];
}): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false);

  if (!section.config) {
    // There should always be a config for a custom section.
    return <></>;
  }

  const { config } = section;

  const customFieldsToShow = allCustomFields.filter(
    ({ id }) => config.custom_field_ids?.includes(id),
  );

  if (isEditing) {
    return (
      <PostmortemSectionEditor
        incident={incident}
        templateId={templateId}
        section={section}
        config={config}
        existingResponse={existingResponse}
        onSave={() => {
          setIsEditing(false);
        }}
        customFieldsToShow={customFieldsToShow}
      />
    );
  }

  return (
    <PostmortemSectionDisplay
      id={section.id}
      existingResponse={existingResponse}
      setIsEditing={() => setIsEditing(true)}
      config={config}
      incident={incident}
    />
  );
};

const PostmortemSectionDisplay = ({
  id,
  existingResponse,
  setIsEditing,
  config,
  incident,
}: {
  id: string;
  existingResponse?: PostmortemTemplateSectionResponse;
  setIsEditing: () => void;
  config: PostmortemTemplateSectionConfig;
  incident: Incident;
}): React.ReactElement => {
  return (
    <SectionWrapper
      id={id}
      sectionName={config.name}
      onClick={() => setIsEditing()}
    >
      <PostmortemSectionCustomFields incident={incident} config={config} />
      {existingResponse ? (
        <>
          <TemplatedTextDisplay
            className="grow"
            value={existingResponse.response}
            style={TemplatedTextDisplayStyle.Compact}
          />
          <PostmortemSectionImages images={existingResponse.images} />
        </>
      ) : (
        <div className="text-content-tertiary">Click to start writing...</div>
      )}
    </SectionWrapper>
  );
};

const PostmortemSectionImages = ({ images }: { images: EnrichedImage[] }) => {
  const [zoomImageSource, setZoomImageSource] = useState<string | undefined>(
    undefined,
  );

  return (
    <>
      <Modal
        title="No title shown"
        isOpen={!!zoomImageSource}
        analyticsTrackingId={null}
        onClose={() => setZoomImageSource(undefined)}
        hideHeader
        maximised
      >
        <ModalContent>
          <img src={zoomImageSource} alt="" />
        </ModalContent>
        <ModalFooter
          hideConfirmButton={true}
          confirmButtonType="button"
          onConfirm={() => setZoomImageSource(undefined)}
          onClose={() => setZoomImageSource(undefined)}
          cancelButtonText={"Close"}
        />
      </Modal>
      {images.map((image) => (
        <PostmortemSectionImage
          key={image.id}
          caption={image.caption}
          url={image.url}
          onClick={(e) => {
            // Prevent the click from bubbling up to the parent
            // and triggering the edit section behaviour
            e.stopPropagation();
            e.preventDefault();

            setZoomImageSource(image.url);
          }}
        />
      ))}
    </>
  );
};

const PostmortemSectionImage = ({
  caption,
  url,
  onClick,
}: {
  caption?: string;
  url: string;
  onClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <div
      className="rounded-2 p-4 bg-surface-secondary flex flex-col items-center gap-3 cursor-pointer group-hover/section:bg-surface-tertiary transition-colors"
      onClick={(e) => onClick(e)}
    >
      <div className="flex justify-center">
        <img
          src={url}
          className="rounded-1 shadow max-w-[80%] max-h-[300px]"
          alt={caption ?? "Image attached to the section response"}
        />
      </div>
      {caption && (
        <div className="text-xs-med text-content-tertiary">{caption}</div>
      )}
    </div>
  );
};

const PostmortemSectionCustomFields = ({
  incident,
  config,
}: {
  incident: Incident;
  config: PostmortemTemplateSectionConfig;
}): React.ReactElement => {
  const customFieldEntriesToShow = incident.custom_field_entries.filter(
    ({ custom_field }) => {
      return (config.custom_field_ids || []).includes(custom_field.id);
    },
  );

  if (customFieldEntriesToShow.length === 0) {
    return <></>;
  }

  return (
    <div className="grid grid-cols-[auto_1fr] gap-y-2">
      {customFieldEntriesToShow.map(({ custom_field: customField, values }) => {
        const value = renderCustomFieldValues(
          customField,
          values,
          BadgeSize.Small,
        );
        const allowWrap =
          customField.field_type === CustomFieldTypeInfoFieldTypeEnum.Text;

        return (
          <PostmortemSectionCustomFieldWrapper
            customField={customField}
            key={customField.id}
            allowWrap={allowWrap}
            isEditing={false}
          >
            <div className="flex">{value}</div>
          </PostmortemSectionCustomFieldWrapper>
        );
      })}
    </div>
  );
};

export const PostmortemSectionCustomFieldWrapper = ({
  customField,
  tooltipContent,
  children,
  allowWrap,
  isEditing,
}: {
  customField: CustomFieldTypeInfo | CustomField;
  tooltipContent?: React.ReactNode;
  children: React.ReactNode;
  allowWrap?: boolean;
  isEditing: boolean;
}): React.ReactElement => {
  const tooltip = tooltipContent ? (
    <Tooltip light content={tooltipContent}>
      <Badge theme={BadgeTheme.Naked}>
        <Icon
          size={IconSize.Small}
          className={tcx("ml-1")}
          id={IconEnum.Info}
        />
      </Badge>
    </Tooltip>
  ) : null;

  return (
    <>
      <div
        className={tcx(
          "text-content-secondary mr-2 min-w-[155px] flex-none flex-center-y min-h-6",
          !isEditing && "text-xs-med",
        )}
      >
        {customField.name}
        {tooltip}
      </div>
      <div className="flex-center-y ">
        <div
          className={tcx(
            "w-full",
            allowWrap && "truncate",
            !isEditing && "text-xs-med",
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};
