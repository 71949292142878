import type { SVGProps } from "react";
import * as React from "react";
const SvgCyberark = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#cyberark_svg__a)">
      <path
        fill="#039BE5"
        d="M2.5 5.833 10 1.667v4.166L6.25 7.917 2.5 5.833Z"
      />
      <path
        fill="#0277BD"
        d="m10 14.167 3.64-2.042L17.5 10v4.167L10 18.333v-4.166Zm0-8.334V1.667l7.5 4.166-3.75 2.084L10 5.833Z"
      />
      <path
        fill="#039BE5"
        d="m2.5 13.75 3.75-1.667L10 14.167v4.166L2.5 13.75Z"
      />
      <path fill="#0277BD" d="m2.5 5.833 3.75 2.084v4.166L2.5 13.75V5.833Z" />
      <path fill="#BDBDBD" d="M10 10V5.833l3.75 2.084v4.166L10 10Z" />
      <path fill="#757575" d="M6.25 7.917 10 5.833V10l-3.75 2.083V7.917Z" />
    </g>
    <defs>
      <clipPath id="cyberark_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCyberark;
