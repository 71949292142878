import type { SVGProps } from "react";
import * as React from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.75 5.5A.75.75 0 0 1 6 4.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75ZM13.25 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75Z"
    />
    <path
      fill="currentColor"
      d="M14.75 4h-9.5A2.752 2.752 0 0 0 2.5 6.75v8.5A2.752 2.752 0 0 0 5.25 18h9.5a2.752 2.752 0 0 0 2.75-2.75v-8.5A2.752 2.752 0 0 0 14.75 4Zm0 12.5h-9.5c-.689 0-1.25-.561-1.25-1.25V9h12v6.25c0 .689-.561 1.25-1.25 1.25Z"
    />
  </svg>
);
export default SvgCalendar;
