/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingCreateCheckoutSessionResponseBody
 */
export interface BillingCreateCheckoutSessionResponseBody {
    /**
     * Stripe publishable API key
     * @type {string}
     * @memberof BillingCreateCheckoutSessionResponseBody
     */
    stripe_publishable_api_key: string;
    /**
     * Stripe session ID to use
     * @type {string}
     * @memberof BillingCreateCheckoutSessionResponseBody
     */
    stripe_session_id: string;
}

export function BillingCreateCheckoutSessionResponseBodyFromJSON(json: any): BillingCreateCheckoutSessionResponseBody {
    return BillingCreateCheckoutSessionResponseBodyFromJSONTyped(json, false);
}

export function BillingCreateCheckoutSessionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingCreateCheckoutSessionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stripe_publishable_api_key': json['stripe_publishable_api_key'],
        'stripe_session_id': json['stripe_session_id'],
    };
}

export function BillingCreateCheckoutSessionResponseBodyToJSON(value?: BillingCreateCheckoutSessionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stripe_publishable_api_key': value.stripe_publishable_api_key,
        'stripe_session_id': value.stripe_session_id,
    };
}

