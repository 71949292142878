/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageAffectedComponent
 */
export interface StatusPageAffectedComponent {
    /**
     * The structure-ID of the affected component. Either a status page component ID (for standalone pages) or a component catalog entry ID (for a parent/sub-page).
     * @type {string}
     * @memberof StatusPageAffectedComponent
     */
    component_id: string;
    /**
     * The impact on the component
     * @type {string}
     * @memberof StatusPageAffectedComponent
     */
    status: StatusPageAffectedComponentStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageAffectedComponentStatusEnum {
    Operational = 'operational',
    UnderMaintenance = 'under_maintenance',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    FullOutage = 'full_outage'
}

export function StatusPageAffectedComponentFromJSON(json: any): StatusPageAffectedComponent {
    return StatusPageAffectedComponentFromJSONTyped(json, false);
}

export function StatusPageAffectedComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageAffectedComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_id': json['component_id'],
        'status': json['status'],
    };
}

export function StatusPageAffectedComponentToJSON(value?: StatusPageAffectedComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.component_id,
        'status': value.status,
    };
}

