import { PostmortemTemplate, ScopeNameEnum } from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { Form } from "@incident-shared/forms";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { Dispatch, SetStateAction } from "react";

import { PostmortemTemplateExpressionFormData } from "./types";

export const FallbackDefaultPostmortemTemplate = ({
  elseBranchTemplate,
  setEditElseBranch,
}: {
  elseBranchTemplate: PostmortemTemplate | undefined;
  setEditElseBranch: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <SettingsListItem
      title={
        <Form.InputWrapper<PostmortemTemplateExpressionFormData>
          name="postmortem_template_expression.else_branch"
          className="font-normal"
        >
          <div
            className={
              "flex items-center flex-wrap text-sm space-x-2 font-normal"
            }
          >
            <div className="-ml-2 mr-2 h-[16px] invisible">
              <Icon id={IconEnum.Draggable} size={IconSize.Small} />
            </div>
            <span className="font-medium">Otherwise</span>,{" "}
            {elseBranchTemplate ? (
              <>
                use{" "}
                <EngineLiteralBadge
                  icon={IconEnum.Doc}
                  label={elseBranchTemplate.name}
                />{" "}
                <span className="text-sm flex-shrink-0">
                  for writing the post-mortem
                </span>
              </>
            ) : (
              "the first template will be used."
            )}
          </div>
        </Form.InputWrapper>
      }
      buttons={{
        edit: {
          onEdit: () => setEditElseBranch(true),
        },
        delete: {
          isGatedText: "Must specify fallback behaviour",
          noDeleteConfirmation: true,
        },
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
      }}
    />
  );
};
