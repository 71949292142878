import {
  Incident,
  IncidentsListSortByEnum,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { UseFormReturn } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { useIncident } from "../hooks";
import { Category } from "./status-utils";
import { IncidentStatusFormData } from "./StatusFormElement";

export const ActiveIncidentInput = ({
  incidentId,
  formMethods,
}: {
  incidentId: string;
  formMethods: UseFormReturn<IncidentStatusFormData>;
}): React.ReactElement | null => {
  const { incident, isLoading: incidentLoading } = useIncident(incidentId);

  const {
    data: { incidents },
    isLoading,
    error: incidentsError,
  } = useAPI(
    incident ? "incidentsList" : null,
    {
      pageSize: 100,
      sortBy: IncidentsListSortByEnum.NewestFirst,
      statusCategory: {
        one_of: categoriesFor(incident),
      },
    },
    { fallbackData: { incidents: [], available_statuses: [] } },
  );

  if (incidentsError) throw incidentsError;

  const options = incidents
    .filter((i) => i.id !== incidentId)
    .map(({ id, external_id, name, created_at }) => ({
      sort_key: created_at.toISOString(),
      value: id,
      label: `INC-${external_id}: ${name}`,
    }));

  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="merged_into_incident_id"
        placeholder="Search for an incident"
        isLoading={isLoading || incidentLoading}
        isSearchable={true}
        disabled={options.length === 0}
        options={options}
        isClearable={false}
      />
      <div className="text-content-tertiary !text-xs-med mt-2">
        {options.length === 0 ? (
          <>
            There are currently no incidents that you can merge this incident
            into.
          </>
        ) : (
          <>
            We&apos;ll redirect everyone to the new incident channel, and then
            archive this one to keep things neat and tidy.
          </>
        )}
      </div>
    </>
  );
};

const categoriesFor = (
  incident: Incident | null,
): IncidentStatusCategoryEnum[] => {
  // If the current incident is active, don't show triage incidents as merge options.
  if (
    incident?.incident_status &&
    incident?.incident_status.category === Category.Active
  ) {
    return [Category.Active, Category.Paused];
  }

  return [Category.Triage, Category.Active, Category.Paused];
};
