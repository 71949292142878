/* This example requires Tailwind CSS v2.0+ */

import { currencyStrToCents } from "@incident-ui/Input/CurrencyInput";
import { tcx } from "src/utils/tailwind-classes";

import { SchedulePayRuleWeekdaysEnum as WeekdaysEnum } from "../../../../contexts/ClientContext";
import { formatCurrency } from "../../../../utils/currency";
import { weekdaySelectOptions } from "../../../../utils/frequencies";

export type WeeklyCalendarShift = {
  startSlotIndex: number;
  durationInSlots: number;
  rate_pounds: string;
  short_id: string;
  payRuleKey: string;
  start_time: string;
  isBase: boolean;
};

export type WeeklyCalendarData = {
  weekday: WeekdaysEnum;
  shifts: WeeklyCalendarShift[];
}[];

export const WeeklyCalendar = ({
  currency,
  data,
}: {
  currency: string;
  data: WeeklyCalendarData;
}): React.ReactElement => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-auto flex-col">
        <div
          style={{ width: "155%" }}
          className="flex max-w-full flex-none flex-col"
        >
          <div className="flex-none border-b border-stroke">
            <div className="-mr-px grid grid-cols-7 divide-x divide-slate-200 text-sm leading-6 text-content-tertiary">
              {weekdaySelectOptions.map((opt) => (
                <div
                  key={opt.value}
                  className="flex items-center justify-center py-3"
                >
                  {opt.label.substring(0, 3)}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-auto">
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 grid-rows-1 divide-x divide-slate-200">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full w-8" />
              </div>

              {/* Events */}
              <ol
                className="grid grid-cols-7"
                style={{
                  display: "grid",
                  gridColumnStart: 1,
                  gridColumnEnd: 2,
                  gridRowStart: 1,
                  // one column for each weekday
                  gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
                  // 288 is 24*12 - each grid row represents 5 mins of time.
                  gridTemplateRows:
                    "1.75rem repeat(288, minmax(0.1rem, 1fr)) auto",
                }}
              >
                {data.map((weekday, weekdayIdx) => {
                  return weekday.shifts.map((shift, shiftIdx) => {
                    const nextShift = weekday.shifts[shiftIdx + 1];
                    return (
                      <li
                        key={shiftIdx}
                        className={tcx("relative mt-px flex")}
                        style={{
                          gridColumnStart: weekdayIdx + 1,
                          gridRow: `${shift.startSlotIndex + 1} / span ${
                            shift.durationInSlots - 1
                          }`,
                        }}
                      >
                        <div
                          className={tcx(
                            "absolute inset-1 flex flex-col overflow-y-auto rounded-2 p-2 text-xs leading-5",
                            shift.isBase
                              ? "bg-blue-surface"
                              : "bg-alarmalade-100",
                          )}
                        >
                          <p
                            className={tcx(
                              "order-1 font-semibold",
                              shift.isBase
                                ? "text-blue-content"
                                : "text-alarmalade-600",
                            )}
                          >
                            {shift.short_id}

                            {shift.rate_pounds ? (
                              <>
                                {": "}{" "}
                                {formatCurrency(
                                  currency,
                                  currencyStrToCents(shift.rate_pounds),
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </p>
                          <p
                            className={
                              shift.isBase ? "text-blue-500" : "text-brand"
                            }
                          >
                            {shift.start_time}
                            {" - "}
                            {/* endTime = startTime of the next shift (or 00:00) */}
                            {nextShift ? nextShift.start_time : "00:00"}
                          </p>
                        </div>
                      </li>
                    );
                  });
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
