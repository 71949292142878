/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtlassianConnectAppInstallationInfo
 */
export interface AtlassianConnectAppInstallationInfo {
    /**
     * Whether the app is already installed in the organisation
     * @type {boolean}
     * @memberof AtlassianConnectAppInstallationInfo
     */
    alreadyInstalled: boolean;
    /**
     * The display URL of the Atlassian product instance
     * @type {string}
     * @memberof AtlassianConnectAppInstallationInfo
     */
    displayURL: string;
    /**
     * The type of Atlassian product that the app was installed into
     * @type {string}
     * @memberof AtlassianConnectAppInstallationInfo
     */
    productType: AtlassianConnectAppInstallationInfoProductTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AtlassianConnectAppInstallationInfoProductTypeEnum {
    Jira = 'jira',
    Confluence = 'confluence'
}

export function AtlassianConnectAppInstallationInfoFromJSON(json: any): AtlassianConnectAppInstallationInfo {
    return AtlassianConnectAppInstallationInfoFromJSONTyped(json, false);
}

export function AtlassianConnectAppInstallationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianConnectAppInstallationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alreadyInstalled': json['alreadyInstalled'],
        'displayURL': json['displayURL'],
        'productType': json['productType'],
    };
}

export function AtlassianConnectAppInstallationInfoToJSON(value?: AtlassianConnectAppInstallationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alreadyInstalled': value.alreadyInstalled,
        'displayURL': value.displayURL,
        'productType': value.productType,
    };
}

