import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

import { StackedListItem, StackedListItemProps } from "./StackedListItem";

export type StackedListButtonProps = {
  analyticsTrackingId: string | null;
  disabled?: boolean;
} & (
  | { onSelect: () => void; to?: never; openInNewTab?: never; href?: never }
  | { onSelect?: never; openInNewTab?: boolean; href: string }
) &
  Pick<
    StackedListItemProps,
    | "icon"
    | "iconNode"
    | "badgeNode"
    | "badgeProps"
    | "title"
    | "description"
    | "accessory"
    | "iconColor"
  >;

export function StackedListButton({
  analyticsTrackingId,
  disabled = false,
  onSelect,
  openInNewTab,
  href,
  icon,
  iconNode,
  iconColor,
  badgeNode,
  badgeProps,
  title,
  description,
  accessory,
  ...rest
}: StackedListButtonProps): React.ReactElement {
  return (
    <li
      className={tcx(
        "w-full",
        disabled
          ? "bg-surface-secondary"
          : "bg-white hover:bg-surface-secondary",
      )}
      {...rest}
    >
      <Button
        analyticsTrackingId={analyticsTrackingId}
        className="w-full"
        disabled={disabled}
        theme={ButtonTheme.Unstyled}
        onClick={onSelect}
        href={href}
        openInNewTab={openInNewTab}
      >
        <StackedListItem
          icon={icon}
          iconColor={iconColor || ColorPaletteEnum.Blue}
          iconNode={iconNode}
          title={title}
          badgeNode={badgeNode}
          badgeProps={badgeProps}
          description={description}
          accessory={
            accessory || (
              <div
                className={tcx(
                  "shrink-0 flex items-center",
                  disabled && "invisible",
                )}
              >
                <Icon
                  className="text-slate-300"
                  size={IconSize.Large}
                  id={IconEnum.ChevronRight}
                  altText="Select this option"
                />
              </div>
            )
          }
        />
      </Button>
    </li>
  );
}
