/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnnouncementPostFieldParam
 */
export interface AnnouncementPostFieldParam {
    /**
     * ID of the custom field that is associated to this field
     * @type {string}
     * @memberof AnnouncementPostFieldParam
     */
    custom_field_id?: string;
    /**
     * Emoji for this field
     * @type {string}
     * @memberof AnnouncementPostFieldParam
     */
    emoji: string;
    /**
     * Type of this field
     * @type {string}
     * @memberof AnnouncementPostFieldParam
     */
    field_type: AnnouncementPostFieldParamFieldTypeEnum;
    /**
     * Unique identifier for this announcement post field
     * @type {string}
     * @memberof AnnouncementPostFieldParam
     */
    id: string;
    /**
     * ID of the incident role that is associated to this field
     * @type {string}
     * @memberof AnnouncementPostFieldParam
     */
    incident_role_id?: string;
    /**
     * ID of the incident timestamp that is associated to this field
     * @type {string}
     * @memberof AnnouncementPostFieldParam
     */
    incident_timestamp_id?: string;
    /**
     * Order of this field
     * @type {number}
     * @memberof AnnouncementPostFieldParam
     */
    rank: number;
    /**
     * Title of this field
     * @type {string}
     * @memberof AnnouncementPostFieldParam
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum AnnouncementPostFieldParamFieldTypeEnum {
    Status = 'announcement_post_fields_status',
    IncidentType = 'announcement_post_fields_incident_type',
    Severity = 'announcement_post_fields_severity',
    Role = 'announcement_post_fields_role',
    Description = 'announcement_post_fields_description',
    CustomField = 'announcement_post_fields_custom_field',
    Timestamp = 'announcement_post_fields_timestamp',
    Creator = 'announcement_post_fields_creator',
    Slack = 'announcement_post_fields_slack'
}

export function AnnouncementPostFieldParamFromJSON(json: any): AnnouncementPostFieldParam {
    return AnnouncementPostFieldParamFromJSONTyped(json, false);
}

export function AnnouncementPostFieldParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostFieldParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'emoji': json['emoji'],
        'field_type': json['field_type'],
        'id': json['id'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'incident_timestamp_id': !exists(json, 'incident_timestamp_id') ? undefined : json['incident_timestamp_id'],
        'rank': json['rank'],
        'title': json['title'],
    };
}

export function AnnouncementPostFieldParamToJSON(value?: AnnouncementPostFieldParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_id': value.custom_field_id,
        'emoji': value.emoji,
        'field_type': value.field_type,
        'id': value.id,
        'incident_role_id': value.incident_role_id,
        'incident_timestamp_id': value.incident_timestamp_id,
        'rank': value.rank,
        'title': value.title,
    };
}

