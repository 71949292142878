import { CatalogType, EngineScope, Resource } from "@incident-io/api";
import { MaybeRef } from "@incident-shared/engine";
import { AddEditExpressionModal } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import {
  InputElementProps,
  parseProps,
} from "@incident-shared/forms/v2/formsv2";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { useState } from "react";
import { Path, useController } from "react-hook-form";
import { FieldValues } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { omitScopeReference } from "src/utils/scope";

type Props = {
  name: string;
  value: ExpressionFormData | undefined;
  onChange: (value: ExpressionFormData | null) => void;
  scope: EngineScope;
  resources: Resource[];
  disabled?: boolean;
  customFieldId?: string;
  resultType?: string;
  resultArray: boolean;
  catalogTypes: CatalogType[];
  setEngineExpressionEdited?: (value: boolean) => void;
};

export const DerivedCustomFieldExpressionEditSection = <
  TFormType extends FieldValues,
>(
  props: InputElementProps<TFormType, Props>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <Form.InputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      showErrorAboveComponent={true}
    >
      <ExpressionsMethodsProvider allowAllOfACatalogType={false}>
        <DerivedExpressionCreateEditSectionInner
          {...inputProps}
          {...field}
          value={field.value || undefined}
        />
      </ExpressionsMethodsProvider>
    </Form.InputWrapper>
  );
};

export const DerivedExpressionCreateEditSectionInner = ({
  disabled,
  scope,
  value,
  onChange,
  resources,
  resultType,
  resultArray,
  customFieldId,
  catalogTypes,
  setEngineExpressionEdited,
}: Props): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);

  const setExpression = (expression: ExpressionFormData | null) => {
    onChange(expression);
    setEngineExpressionEdited && setEngineExpressionEdited(true);
    setShowModal(false);
  };

  // Remove the current field from the scope so we don't get a circular reference
  const filteredScope = omitScopeReference(
    scope,
    `incident.custom_field["${customFieldId}"]`,
  );

  // Set the types of expression results that a custom field is able to return.
  // We have to include both the ID and the type name form as it could be either
  // depending on the type (external or otherwise).
  const catalogTypeNames = catalogTypes.flatMap((type) => [
    `CatalogEntry["${type.id}"]`,
    `CatalogEntry["${type.type_name}"]`,
  ]);

  const staticTypeNames = ["String", "Number", "Link"];

  const validateReturnType = ({ resource, array }: MaybeRef) => {
    // Catalog types can be single- or multi-select custom fields
    if (catalogTypeNames.includes(resource.type)) {
      return true;
    }

    // Static types can't be multi-valued, so ban arrays.
    if (staticTypeNames.includes(resource.type)) {
      return !array;
    }

    return "Custom fields can only contain catalog values, numbers, strings, or links.";
  };

  return (
    <>
      {value ? (
        <div className="space-y-2">
          <ViewExpression
            scope={scope}
            expression={value}
            disabled={disabled}
            onEdit={() => setShowModal(true)}
            onDelete={() => setExpression(null)}
          />
        </div>
      ) : (
        <Button
          icon={IconEnum.Expression}
          analyticsTrackingId={"add-expression"}
          onClick={() => setShowModal(true)}
          disabled={disabled}
          theme={ButtonTheme.Secondary}
        >
          Add an expression
        </Button>
      )}
      {showModal && (
        <AddEditExpressionModal
          onClose={() => setShowModal(false)}
          onEditExpression={setExpression}
          onAddExpression={setExpression}
          initialExpression={value}
          scope={filteredScope}
          resources={resources}
          validateReturnType={validateReturnType}
          analyticsTrackingContext={"add-edit-expression-modal"}
          existingExpressions={value ? [value] : []}
          // Only add the helptext if we haven't already 'locked in' our result type
          returnMultipleItemsCheckboxHelptext={
            resultType
              ? undefined
              : "Check this box if the custom field should be a multi-select."
          }
          fixedResult={
            resultType
              ? {
                  type: resultType,
                  typeIsAutocompletable:
                    resources.find((r) => r.type === resultType)
                      ?.autocompletable ?? false,
                  typeLabel:
                    resources.find((r) => r.type === resultType)?.type_label ||
                    resultType,
                  label: "Derived custom field expression",
                  array: resultArray,
                }
              : undefined
          }
        />
      )}
    </>
  );
};
