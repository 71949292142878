/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PostmortemSharesCreateShareRequestBody,
    PostmortemSharesCreateShareRequestBodyFromJSON,
    PostmortemSharesCreateShareRequestBodyToJSON,
    PostmortemSharesCreateShareResponseBody,
    PostmortemSharesCreateShareResponseBodyFromJSON,
    PostmortemSharesCreateShareResponseBodyToJSON,
    PostmortemSharesCreateTemplateRequestBody,
    PostmortemSharesCreateTemplateRequestBodyFromJSON,
    PostmortemSharesCreateTemplateRequestBodyToJSON,
    PostmortemSharesCreateTemplateResponseBody,
    PostmortemSharesCreateTemplateResponseBodyFromJSON,
    PostmortemSharesCreateTemplateResponseBodyToJSON,
    PostmortemSharesListSharesResponseBody,
    PostmortemSharesListSharesResponseBodyFromJSON,
    PostmortemSharesListSharesResponseBodyToJSON,
    PostmortemSharesListTemplatesResponseBody,
    PostmortemSharesListTemplatesResponseBodyFromJSON,
    PostmortemSharesListTemplatesResponseBodyToJSON,
    PostmortemSharesRenderPreviewRequestBody,
    PostmortemSharesRenderPreviewRequestBodyFromJSON,
    PostmortemSharesRenderPreviewRequestBodyToJSON,
    PostmortemSharesRenderPreviewResponseBody,
    PostmortemSharesRenderPreviewResponseBodyFromJSON,
    PostmortemSharesRenderPreviewResponseBodyToJSON,
    PostmortemSharesShowTemplateResponseBody,
    PostmortemSharesShowTemplateResponseBodyFromJSON,
    PostmortemSharesShowTemplateResponseBodyToJSON,
    PostmortemSharesUpdateTemplateRequestBody,
    PostmortemSharesUpdateTemplateRequestBodyFromJSON,
    PostmortemSharesUpdateTemplateRequestBodyToJSON,
    PostmortemSharesUpdateTemplateResponseBody,
    PostmortemSharesUpdateTemplateResponseBodyFromJSON,
    PostmortemSharesUpdateTemplateResponseBodyToJSON,
} from '../models';

export interface PostmortemSharesCreateShareRequest {
    createShareRequestBody: PostmortemSharesCreateShareRequestBody;
}

export interface PostmortemSharesCreateTemplateRequest {
    createTemplateRequestBody: PostmortemSharesCreateTemplateRequestBody;
}

export interface PostmortemSharesDestroyTemplateRequest {
    id: string;
}

export interface PostmortemSharesListSharesRequest {
    incidentId: string;
}

export interface PostmortemSharesRenderPreviewRequest {
    renderPreviewRequestBody: PostmortemSharesRenderPreviewRequestBody;
}

export interface PostmortemSharesShowTemplateRequest {
    id: string;
}

export interface PostmortemSharesUpdateTemplateRequest {
    id: string;
    updateTemplateRequestBody: PostmortemSharesUpdateTemplateRequestBody;
}

/**
 * 
 */
export class PostmortemSharesApi extends runtime.BaseAPI {

    /**
     * Share a postmortem
     * CreateShare Postmortem Shares
     */
    async postmortemSharesCreateShareRaw(requestParameters: PostmortemSharesCreateShareRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemSharesCreateShareResponseBody>> {
        if (requestParameters.createShareRequestBody === null || requestParameters.createShareRequestBody === undefined) {
            throw new runtime.RequiredError('createShareRequestBody','Required parameter requestParameters.createShareRequestBody was null or undefined when calling postmortemSharesCreateShare.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/shares`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemSharesCreateShareRequestBodyToJSON(requestParameters.createShareRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemSharesCreateShareResponseBodyFromJSON(jsonValue));
    }

    /**
     * Share a postmortem
     * CreateShare Postmortem Shares
     */
    async postmortemSharesCreateShare(requestParameters: PostmortemSharesCreateShareRequest, initOverrides?: RequestInit): Promise<PostmortemSharesCreateShareResponseBody> {
        const response = await this.postmortemSharesCreateShareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a postmortem share template
     * CreateTemplate Postmortem Shares
     */
    async postmortemSharesCreateTemplateRaw(requestParameters: PostmortemSharesCreateTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemSharesCreateTemplateResponseBody>> {
        if (requestParameters.createTemplateRequestBody === null || requestParameters.createTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('createTemplateRequestBody','Required parameter requestParameters.createTemplateRequestBody was null or undefined when calling postmortemSharesCreateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/shares/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemSharesCreateTemplateRequestBodyToJSON(requestParameters.createTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemSharesCreateTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a postmortem share template
     * CreateTemplate Postmortem Shares
     */
    async postmortemSharesCreateTemplate(requestParameters: PostmortemSharesCreateTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemSharesCreateTemplateResponseBody> {
        const response = await this.postmortemSharesCreateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing postmortem share template
     * DestroyTemplate Postmortem Shares
     */
    async postmortemSharesDestroyTemplateRaw(requestParameters: PostmortemSharesDestroyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemSharesDestroyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/shares/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing postmortem share template
     * DestroyTemplate Postmortem Shares
     */
    async postmortemSharesDestroyTemplate(requestParameters: PostmortemSharesDestroyTemplateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemSharesDestroyTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * List postmortem shares for an incident
     * ListShares Postmortem Shares
     */
    async postmortemSharesListSharesRaw(requestParameters: PostmortemSharesListSharesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemSharesListSharesResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling postmortemSharesListShares.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/shares`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemSharesListSharesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem shares for an incident
     * ListShares Postmortem Shares
     */
    async postmortemSharesListShares(requestParameters: PostmortemSharesListSharesRequest, initOverrides?: RequestInit): Promise<PostmortemSharesListSharesResponseBody> {
        const response = await this.postmortemSharesListSharesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List postmortem share templates
     * ListTemplates Postmortem Shares
     */
    async postmortemSharesListTemplatesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemSharesListTemplatesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/shares/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemSharesListTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem share templates
     * ListTemplates Postmortem Shares
     */
    async postmortemSharesListTemplates(initOverrides?: RequestInit): Promise<PostmortemSharesListTemplatesResponseBody> {
        const response = await this.postmortemSharesListTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Render a previw of a postmortem share
     * RenderPreview Postmortem Shares
     */
    async postmortemSharesRenderPreviewRaw(requestParameters: PostmortemSharesRenderPreviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemSharesRenderPreviewResponseBody>> {
        if (requestParameters.renderPreviewRequestBody === null || requestParameters.renderPreviewRequestBody === undefined) {
            throw new runtime.RequiredError('renderPreviewRequestBody','Required parameter requestParameters.renderPreviewRequestBody was null or undefined when calling postmortemSharesRenderPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/shares/render_preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemSharesRenderPreviewRequestBodyToJSON(requestParameters.renderPreviewRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemSharesRenderPreviewResponseBodyFromJSON(jsonValue));
    }

    /**
     * Render a previw of a postmortem share
     * RenderPreview Postmortem Shares
     */
    async postmortemSharesRenderPreview(requestParameters: PostmortemSharesRenderPreviewRequest, initOverrides?: RequestInit): Promise<PostmortemSharesRenderPreviewResponseBody> {
        const response = await this.postmortemSharesRenderPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual postmortem share template by ID
     * ShowTemplate Postmortem Shares
     */
    async postmortemSharesShowTemplateRaw(requestParameters: PostmortemSharesShowTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemSharesShowTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemSharesShowTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/shares/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemSharesShowTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get an individual postmortem share template by ID
     * ShowTemplate Postmortem Shares
     */
    async postmortemSharesShowTemplate(requestParameters: PostmortemSharesShowTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemSharesShowTemplateResponseBody> {
        const response = await this.postmortemSharesShowTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a postmortem share template
     * UpdateTemplate Postmortem Shares
     */
    async postmortemSharesUpdateTemplateRaw(requestParameters: PostmortemSharesUpdateTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemSharesUpdateTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemSharesUpdateTemplate.');
        }

        if (requestParameters.updateTemplateRequestBody === null || requestParameters.updateTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateRequestBody','Required parameter requestParameters.updateTemplateRequestBody was null or undefined when calling postmortemSharesUpdateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/shares/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemSharesUpdateTemplateRequestBodyToJSON(requestParameters.updateTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemSharesUpdateTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a postmortem share template
     * UpdateTemplate Postmortem Shares
     */
    async postmortemSharesUpdateTemplate(requestParameters: PostmortemSharesUpdateTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemSharesUpdateTemplateResponseBody> {
        const response = await this.postmortemSharesUpdateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
