/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RBACPrivilegeGroup,
    RBACPrivilegeGroupFromJSON,
    RBACPrivilegeGroupFromJSONTyped,
    RBACPrivilegeGroupToJSON,
} from './RBACPrivilegeGroup';

/**
 * 
 * @export
 * @interface RBACPrivilege
 */
export interface RBACPrivilege {
    /**
     * Description of the purpose for the RBAC privilege
     * @type {string}
     * @memberof RBACPrivilege
     */
    description?: string;
    /**
     * 
     * @type {RBACPrivilegeGroup}
     * @memberof RBACPrivilege
     */
    group: RBACPrivilegeGroup;
    /**
     * Label of the RBAC privilege which is human readable
     * @type {string}
     * @memberof RBACPrivilege
     */
    label: string;
    /**
     * Unique identifier of the RBAC privilege
     * @type {string}
     * @memberof RBACPrivilege
     */
    name: string;
}

export function RBACPrivilegeFromJSON(json: any): RBACPrivilege {
    return RBACPrivilegeFromJSONTyped(json, false);
}

export function RBACPrivilegeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RBACPrivilege {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'group': RBACPrivilegeGroupFromJSON(json['group']),
        'label': json['label'],
        'name': json['name'],
    };
}

export function RBACPrivilegeToJSON(value?: RBACPrivilege | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'group': RBACPrivilegeGroupToJSON(value.group),
        'label': value.label,
        'name': value.name,
    };
}

