import { EngineParamBindingValue } from "@incident-io/api";

import { AttributeBinding } from "./AttributeEntries";

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

const primitiveTypes = ["String", "Text", "Number", "Bool"];
export type PrimitiveType = ArrayElement<typeof primitiveTypes>;

export const isPrimitive = (t: string): t is PrimitiveType =>
  primitiveTypes.includes(t);

export type singleValueAttribute = AttributeBinding & {
  value: EngineParamBindingValue & { literal: string };
};
export type arrayValueAttribute = AttributeBinding & {
  array_value: EngineParamBindingValue[];
};

export const hasSingleValue = (
  attribute: AttributeBinding,
): attribute is singleValueAttribute => {
  return attribute?.value !== undefined;
};
export const hasArrayValue = (
  attribute: AttributeBinding,
): attribute is arrayValueAttribute => {
  return attribute?.array_value !== undefined;
};

export const isEmpty = (x: AttributeBinding) =>
  (!hasArrayValue(x) && !hasSingleValue(x)) ||
  (hasArrayValue(x) && x.array_value.length === 0) ||
  (hasSingleValue(x) && !x.value.label && !x.value.literal);
