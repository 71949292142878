import { APIKeysCreateRequestBodyRolesEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  CopiableText,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Txt,
} from "@incident-ui";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import { useState } from "react";
import { useParams } from "react-router";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { CatalogFixedFooter } from "../common/CatalogFixedFooter";
import {
  ManageInGithubStepConfig,
  ManageInGitHubStepEnum,
} from "./ManageInGithubRoute";

export const ConfigureAPIKeyStep = () => {
  const { repoId } = useParams<{ repoId: string }>();
  const navigate = useOrgAwareNavigate();

  const [apiKeyGenerated, setAPIKeyGenerated] = useState(false);

  return (
    <>
      <div className="w-full h-full flex flex-col lg:flex-row">
        <div className="lg:flex-1 lg:h-full p-4 lg:overflow-y-auto lg:pb-[71px]">
          <NakedSteps
            steps={ManageInGithubStepConfig}
            currentStepID={ManageInGitHubStepEnum.CheckSetup}
            color={ColorPaletteEnum.Slate}
          />
          <div className="flex flex-col pt-6 mx-auto">
            <Txt bold className="text-[24px] font-semibold leading-10">
              Configure Repository
            </Txt>
            <Txt className="text-slate-600 mb-6">
              Follow the steps below to test your new repository
            </Txt>
            <NumberedList>
              <Txt grey className="mb-4 w-full">
                Generate a <strong>new API Key</strong>
                <APIKey onCreated={() => setAPIKeyGenerated(true)} />
              </Txt>

              <Txt grey className="mb-4">
                In GitHub, go to the settings page of the repository you&apos;re
                storing the catalog data in. In the left sidebar, click{" "}
                <strong>Secrets and variables</strong>, then{" "}
                <strong>Actions</strong>, then{" "}
                <strong>New repository secret</strong>
              </Txt>

              <Txt grey className="mb-4">
                Give the secret the name <code>INCIDENT_API_KEY</code> and paste
                in the API key above as the secret. Then click{" "}
                <strong>Add secret</strong>
              </Txt>
            </NumberedList>
          </div>
        </div>
      </div>
      <CatalogFixedFooter
        onGoBack={() => {
          navigate(`/catalog/manage-in-github/${repoId}/upload`);
        }}
      >
        {!apiKeyGenerated && <Txt grey>Configure your API key to continue</Txt>}
        <Button
          analyticsTrackingId={null}
          onClick={() => {
            navigate(`/catalog/manage-in-github/${repoId}/sync`);
          }}
          theme={ButtonTheme.Primary}
          disabled={!apiKeyGenerated}
        >
          Done
        </Button>
      </CatalogFixedFooter>
    </>
  );
};

const apiKeyCreatePayload = {
  name: "Manage catalog types in GitHub",
  roles: [
    APIKeysCreateRequestBodyRolesEnum.CatalogViewer,
    APIKeysCreateRequestBodyRolesEnum.CatalogEditor,
  ],
};

const APIKey = ({ onCreated }: { onCreated: () => void }) => {
  return (
    <div className="flex flex-col rounded-2 bg-slate-50 mt-4 p-6">
      <CreateAPIKey onCreated={onCreated} />
      <APIKeyCapabilities />
    </div>
  );
};

const CreateAPIKey = ({ onCreated }: { onCreated: () => void }) => {
  const [generatedToken, setToken] = useState<string | null>(null);

  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation("aPIKeysList", undefined, async (apiClient, _) => {
    const { token } = await apiClient.aPIKeysCreate({
      createRequestBody: apiKeyCreatePayload,
    });
    setToken(token);
  });

  if (genericError) {
    return <GenericErrorMessage description={genericError} />;
  }

  if (generatedToken) {
    onCreated();
    return (
      <div className="space-y-2 md:min-w-[512px]">
        <Txt bold className="text-sm">
          API Key
        </Txt>
        <CopiableText value={generatedToken} lineCount={1} />
      </div>
    );
  } else {
    return (
      <div>
        <Button
          analyticsTrackingId={null}
          onClick={() => {
            submit(apiKeyCreatePayload);
          }}
          theme={ButtonTheme.Primary}
          loading={saving}
        >
          Generate API Key
        </Button>
      </div>
    );
  }
};

const APIKeyCapabilities = () => {
  const {
    data: { roles: allRoles },
  } = useAPI("aPIKeysListRolesAndAvailability", undefined, {
    fallbackData: { roles: [] },
  });
  const roles = allRoles.filter((role) =>
    apiKeyCreatePayload.roles.includes(
      role.name as unknown as APIKeysCreateRequestBodyRolesEnum,
    ),
  );

  return (
    <div className="flex gap-1 mt-4">
      <Icon id={IconEnum.LockClosed} className="text-content-tertiary" />
      <div>
        <Txt bold>What this key will be able to do</Txt>
        <ul className="list-disc pl-4">
          {roles.map((role) => (
            <li key={role.name}>
              <Txt className="text-sm" grey>
                {role.description}
              </Txt>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
