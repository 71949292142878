import { Button, ButtonTheme } from "@incident-ui";
import { ProductMarketingBanner } from "@incident-ui/ProductMarketingBanner/ProductMarketingBanner";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";

import levelUpResponseGraphic from "./level-up-response-graphic.svg";

export const LevelUpIncidentResponseBanner = () => {
  const { hasResponse } = useProductAccess();
  const primaryCommsPlatform = usePrimaryCommsPlatform();

  if (hasResponse) {
    return null;
  }

  return (
    <ProductMarketingBanner
      className="bg-gradient-to-t to-alarmalade-100 from-alarmalade-50 mt-6 border border-alarmalade-100"
      title={
        <div className="text-alarmalade-800 text-base-bold">
          Level up your incident response
        </div>
      }
      subtitle={
        <div className="text-alarmalade-700 text-sm-normal">
          Focus on fixing the problem with powerful
          <br /> automations and insights, supercharged by AI.
        </div>
      }
      graphic={<img src={levelUpResponseGraphic} className="my-6" />}
      cta={
        <Button
          analyticsTrackingId="request-access-response"
          theme={ButtonTheme.Primary}
          href="/settings/billing"
        >
          Request access
        </Button>
      }
      secondaryCta={
        <Button
          analyticsTrackingId="learn-more-response"
          theme={ButtonTheme.Naked}
          href={
            primaryCommsPlatform === CommsPlatform.Slack
              ? "https://incident.io/respond-slack"
              : "https://incident.io/respond-teams"
          }
        >
          <div className="text-alarmalade-700 text-sm-med">Learn more</div>
        </Button>
      }
    />
  );
};
