/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentDurationMetric,
    IncidentDurationMetricFromJSON,
    IncidentDurationMetricFromJSONTyped,
    IncidentDurationMetricToJSON,
} from './IncidentDurationMetric';

/**
 * 
 * @export
 * @interface IncidentDurationMetricWithValue
 */
export interface IncidentDurationMetricWithValue {
    /**
     * 
     * @type {IncidentDurationMetric}
     * @memberof IncidentDurationMetricWithValue
     */
    duration_metric: IncidentDurationMetric;
    /**
     * The status of the calculation
     * @type {string}
     * @memberof IncidentDurationMetricWithValue
     */
    status: IncidentDurationMetricWithValueStatusEnum;
    /**
     * The value of this incident metric in seconds
     * @type {number}
     * @memberof IncidentDurationMetricWithValue
     */
    value_seconds?: number;
}

/**
* @export
* @enum {string}
*/
export enum IncidentDurationMetricWithValueStatusEnum {
    Success = 'success',
    TimestampsMissing = 'timestamps_missing',
    Calculating = 'calculating',
    InvalidTimestamps = 'invalid_timestamps'
}

export function IncidentDurationMetricWithValueFromJSON(json: any): IncidentDurationMetricWithValue {
    return IncidentDurationMetricWithValueFromJSONTyped(json, false);
}

export function IncidentDurationMetricWithValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentDurationMetricWithValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration_metric': IncidentDurationMetricFromJSON(json['duration_metric']),
        'status': json['status'],
        'value_seconds': !exists(json, 'value_seconds') ? undefined : json['value_seconds'],
    };
}

export function IncidentDurationMetricWithValueToJSON(value?: IncidentDurationMetricWithValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration_metric': IncidentDurationMetricToJSON(value.duration_metric),
        'status': value.status,
        'value_seconds': value.value_seconds,
    };
}

