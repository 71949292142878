import type { SVGProps } from "react";
import * as React from "react";
const SvgFire = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.244 12.048c0 3.384-2.808 6.127-6.272 6.127-3.464 0-6.272-2.743-6.272-6.127 0-1.66.701-2.978 1.774-4.27.89-1.071 3.087-3.91 3.778-5.475a.474.474 0 0 1 .78-.161c.591.653 1.319 2.08 1.557 3.699.048.33.068.6.084.813.034.46.05.663.287.663.373 0 .618-.567.671-1.152.035-.375.406-.5.662-.375 1.232.604 2.49 3.477 2.794 4.888.095.442.157.9.157 1.37Zm-6.272 2.745c.964 0 1.745-.74 1.745-1.654 0-1.457-1.073-2.498-1.607-2.814-.036-.022-.054-.033-.103-.031a.201.201 0 0 0-.115.048c-.035.035-.045.074-.063.152-.095.4-.393.684-.707.983-.432.413-.894.854-.894 1.662 0 .913.78 1.654 1.744 1.654Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFire;
