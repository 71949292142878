import { StepConfig } from "@incident-ui/Steps/Steps";
import _ from "lodash";
import { useState } from "react";
import { FieldValues, SubmitHandler } from "react-hook-form";

export function useSteps<StepID extends string, TFormType extends FieldValues>({
  steps,
  onSubmit,
  onClose,
  submitButtonText,
}: {
  steps: StepConfig<StepID>[];
  onSubmit: SubmitHandler<TFormType>;
  onClose: () => void;
  submitButtonText: string;
}): {
  currentStep: StepID;
  goToNextStep: (() => void) | SubmitHandler<TFormType>;
  goToPreviousStep: () => void;
  confirmButtonText: string;
  cancelButtonText: string;
} {
  if (!(_.uniqBy(steps, "id").length === steps.length)) {
    throw new Error("Duplicate step IDs are not allowed");
  }
  const [currentStep, setCurrentStep] = useState<StepID>(steps[0].id);
  const currentStepIdx = steps.findIndex((step) => step.id === currentStep);

  const isFirstStep = currentStepIdx === 0;
  const isLastStep = currentStepIdx === steps.length - 1;
  return {
    currentStep,
    confirmButtonText: isLastStep ? submitButtonText : "Continue",
    cancelButtonText: isFirstStep ? "Cancel" : "Back",
    goToNextStep: isLastStep
      ? onSubmit
      : () => {
          if (currentStepIdx === -1) {
            throw new Error(`Unreachable: Can't find current step`);
          }

          setCurrentStep(steps[currentStepIdx + 1].id);
          return;
        },
    goToPreviousStep: isFirstStep
      ? onClose
      : () => {
          if (currentStepIdx === -1) {
            throw new Error(`Unreachable: Can't find current step`);
          }

          setCurrentStep(steps[currentStepIdx - 1].id);
        },
  };
}
