import type { SVGProps } from "react";
import * as React from "react";
const SvgDice = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 3h-8.5A2.752 2.752 0 0 0 3 5.75v8.5A2.752 2.752 0 0 0 5.75 17h8.5A2.752 2.752 0 0 0 17 14.25v-8.5A2.752 2.752 0 0 0 14.25 3ZM7 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0-6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm3 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm3 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0-6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
    />
  </svg>
);
export default SvgDice;
