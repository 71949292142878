/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IncidentChannelConfig
 */
export interface IncidentChannelConfig {
    /**
     * 
     * @type {TextNode}
     * @memberof IncidentChannelConfig
     */
    custom_format?: TextNode;
    /**
     * unique key describing the format to use when creating incident channels
     * @type {string}
     * @memberof IncidentChannelConfig
     */
    format: IncidentChannelConfigFormatEnum;
    /**
     * a tiemzone to use when making dates that are included in the channel name
     * @type {string}
     * @memberof IncidentChannelConfig
     */
    timezone?: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentChannelConfigFormatEnum {
    IncidentYyyyMmDd = 'incident-yyyy-mm-dd',
    IncYyyyMmDd = 'inc-yyyy-mm-dd',
    IncId = 'inc-id',
    Inc = 'inc',
    Custom = 'custom'
}

export function IncidentChannelConfigFromJSON(json: any): IncidentChannelConfig {
    return IncidentChannelConfigFromJSONTyped(json, false);
}

export function IncidentChannelConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_format': !exists(json, 'custom_format') ? undefined : TextNodeFromJSON(json['custom_format']),
        'format': json['format'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
    };
}

export function IncidentChannelConfigToJSON(value?: IncidentChannelConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_format': TextNodeToJSON(value.custom_format),
        'format': value.format,
        'timezone': value.timezone,
    };
}

