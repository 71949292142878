import type { SVGProps } from "react";
import * as React from "react";
const SvgMonteCarlo = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#0073B4"
      fillRule="evenodd"
      d="M18 2.413A.413.413 0 0 0 17.587 2H2.413A.413.413 0 0 0 2 2.413v15.174c0 .228.185.413.413.413h15.174a.413.413 0 0 0 .413-.413V2.413ZM9.322 9.546l-1.37 3.47a.106.106 0 0 1-.102.071h-.815a.111.111 0 0 1-.103-.07L5.569 9.59v3.389c0 .06-.05.108-.109.108h-.907a.109.109 0 0 1-.109-.108V7.857c0-.06.05-.108.109-.108h1.369c.043 0 .081.027.103.065l1.44 3.54L8.832 7.82a.106.106 0 0 1 .103-.07h1.45c.06 0 .109.049.109.108v5.122c0 .06-.05.108-.109.108h-.95a.109.109 0 0 1-.109-.108l-.005-3.433Zm6.951 3.047c.207-.19.375-.396.483-.619a3.012 3.012 0 0 0 .207-.505.104.104 0 0 0-.07-.136l-.87-.25c-.054-.016-.114.011-.13.066a1.928 1.928 0 0 1-.114.25 1.274 1.274 0 0 1-.277.358 1.482 1.482 0 0 1-.445.277 1.697 1.697 0 0 1-.647.108 1.69 1.69 0 0 1-1.157-.445 1.65 1.65 0 0 1-.363-.538 1.741 1.741 0 0 1-.136-.716c0-.304.043-.56.141-.788.098-.228.223-.407.38-.548a1.65 1.65 0 0 1 .532-.332c.19-.076.386-.103.582-.103.26 0 .472.038.646.114.18.07.326.163.44.277.12.109.2.223.26.348.044.087.077.163.104.233a.112.112 0 0 0 .136.065l.863-.277c.054-.021.087-.081.07-.135a2.327 2.327 0 0 0-.217-.505 2.126 2.126 0 0 0-.478-.598 2.604 2.604 0 0 0-.765-.456 2.904 2.904 0 0 0-1.07-.18c-.375 0-.739.071-1.087.207a2.847 2.847 0 0 0-.896.587c-.255.25-.456.548-.613.907-.114.255-.18.532-.207.83-.021.11-.021.223-.021.337 0 .104.005.207.016.31.027.304.103.597.212.858a2.656 2.656 0 0 0 1.504 1.477c.342.13.712.201 1.108.201h.01c.425 0 .788-.07 1.098-.195.31-.136.56-.294.771-.484Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMonteCarlo;
