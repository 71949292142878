import type { SVGProps } from "react";
import * as React from "react";
const SvgDelete2 = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 4.5H13v-.75C13 2.785 12.215 2 11.25 2h-2.5C7.785 2 7 2.785 7 3.75v.75H3.75a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5ZM8.5 3.75a.25.25 0 0 1 .25-.25h2.5a.25.25 0 0 1 .25.25v.75h-3v-.75ZM14.25 7h-8.5a.75.75 0 0 0-.75.75v7.5A2.752 2.752 0 0 0 7.75 18h4.5A2.752 2.752 0 0 0 15 15.25v-7.5a.75.75 0 0 0-.75-.75ZM9 14.25a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 1.5 0v4Zm3.5 0a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 1.5 0v4Z"
    />
  </svg>
);
export default SvgDelete2;
