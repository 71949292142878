import type { SVGProps } from "react";
import * as React from "react";
const SvgPhone = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.5 9.25c0 .414.336.75.75.75.965 0 1.75-.785 1.75-1.75v-.962a2.73 2.73 0 0 0-1.272-2.322C15.309 4.069 12.991 3 10 3 7.009 3 4.691 4.069 3.272 4.966A2.735 2.735 0 0 0 2 7.288v.962C2 9.215 2.785 10 3.75 10a.75.75 0 0 0 0-1.5.25.25 0 0 1-.25-.25v-.962c0-.432.215-.826.574-1.054C5.325 5.444 7.368 4.501 10 4.501s4.675.942 5.925 1.733c.36.227.575.621.575 1.054v.962a.25.25 0 0 1-.25.25.75.75 0 0 0-.75.75Zm-2.091-1.517 3.265 4.57c.213.298.326.65.326 1.017v1.93c0 .965-.785 1.75-1.75 1.75H4.748c-.965 0-1.75-.785-1.75-1.75v-1.93c0-.367.113-.719.326-1.017l3.265-4.57c.113-.158.256-.284.41-.395V6.25a.75.75 0 0 1 1.5 0V7h3v-.75a.75.75 0 0 1 1.5 0v1.088c.154.111.297.237.41.395ZM8 12a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPhone;
