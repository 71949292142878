/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamSourceOfTruthResource
 */
export interface TeamSourceOfTruthResource {
    /**
     * Whether most entries in this resource are teams
     * @type {boolean}
     * @memberof TeamSourceOfTruthResource
     */
    most_entries_are_teams: boolean;
    /**
     * The integration required to use this resource
     * @type {string}
     * @memberof TeamSourceOfTruthResource
     */
    required_integration: string;
    /**
     * The type of the resource
     * @type {string}
     * @memberof TeamSourceOfTruthResource
     */
    resource_type: string;
    /**
     * The human-readable name of the resource
     * @type {string}
     * @memberof TeamSourceOfTruthResource
     */
    resource_type_label: string;
    /**
     * The path to the user in the resource
     * @type {Array<string>}
     * @memberof TeamSourceOfTruthResource
     */
    user_path: Array<string>;
}

export function TeamSourceOfTruthResourceFromJSON(json: any): TeamSourceOfTruthResource {
    return TeamSourceOfTruthResourceFromJSONTyped(json, false);
}

export function TeamSourceOfTruthResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamSourceOfTruthResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'most_entries_are_teams': json['most_entries_are_teams'],
        'required_integration': json['required_integration'],
        'resource_type': json['resource_type'],
        'resource_type_label': json['resource_type_label'],
        'user_path': json['user_path'],
    };
}

export function TeamSourceOfTruthResourceToJSON(value?: TeamSourceOfTruthResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'most_entries_are_teams': value.most_entries_are_teams,
        'required_integration': value.required_integration,
        'resource_type': value.resource_type,
        'resource_type_label': value.resource_type_label,
        'user_path': value.user_path,
    };
}

