/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';
import {
    ScheduleCoverRequest,
    ScheduleCoverRequestFromJSON,
    ScheduleCoverRequestFromJSONTyped,
    ScheduleCoverRequestToJSON,
} from './ScheduleCoverRequest';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SchedulesListCoverRequestResponseBody
 */
export interface SchedulesListCoverRequestResponseBody {
    /**
     * 
     * @type {Array<ScheduleCoverRequest>}
     * @memberof SchedulesListCoverRequestResponseBody
     */
    schedule_cover_requests: Array<ScheduleCoverRequest>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof SchedulesListCoverRequestResponseBody
     */
    schedules: Array<Schedule>;
    /**
     * 
     * @type {Array<User>}
     * @memberof SchedulesListCoverRequestResponseBody
     */
    users: Array<User>;
}

export function SchedulesListCoverRequestResponseBodyFromJSON(json: any): SchedulesListCoverRequestResponseBody {
    return SchedulesListCoverRequestResponseBodyFromJSONTyped(json, false);
}

export function SchedulesListCoverRequestResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesListCoverRequestResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_cover_requests': ((json['schedule_cover_requests'] as Array<any>).map(ScheduleCoverRequestFromJSON)),
        'schedules': ((json['schedules'] as Array<any>).map(ScheduleFromJSON)),
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function SchedulesListCoverRequestResponseBodyToJSON(value?: SchedulesListCoverRequestResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_cover_requests': ((value.schedule_cover_requests as Array<any>).map(ScheduleCoverRequestToJSON)),
        'schedules': ((value.schedules as Array<any>).map(ScheduleToJSON)),
        'users': ((value.users as Array<any>).map(UserToJSON)),
    };
}

