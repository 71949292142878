/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParam,
    EngineParamFromJSON,
    EngineParamFromJSONTyped,
    EngineParamToJSON,
} from './EngineParam';

/**
 * 
 * @export
 * @interface ResourceOperation
 */
export interface ResourceOperation {
    /**
     * Human readable label for the operation
     * @type {string}
     * @memberof ResourceOperation
     */
    label: string;
    /**
     * Unique identifier for this operation
     * @type {string}
     * @memberof ResourceOperation
     */
    name: string;
    /**
     * List of parameters for this operation
     * @type {Array<EngineParam>}
     * @memberof ResourceOperation
     */
    params: Array<EngineParam>;
}

export function ResourceOperationFromJSON(json: any): ResourceOperation {
    return ResourceOperationFromJSONTyped(json, false);
}

export function ResourceOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'name': json['name'],
        'params': ((json['params'] as Array<any>).map(EngineParamFromJSON)),
    };
}

export function ResourceOperationToJSON(value?: ResourceOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'name': value.name,
        'params': ((value.params as Array<any>).map(EngineParamToJSON)),
    };
}

