/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface DebriefsUpdateDurationRequestBody
 */
export interface DebriefsUpdateDurationRequestBody {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof DebriefsUpdateDurationRequestBody
     */
    duration?: EngineParamBindingPayload;
    /**
     * Expressions available for use in debrief settings
     * @type {Array<ExpressionPayload>}
     * @memberof DebriefsUpdateDurationRequestBody
     */
    expressions?: Array<ExpressionPayload>;
}

export function DebriefsUpdateDurationRequestBodyFromJSON(json: any): DebriefsUpdateDurationRequestBody {
    return DebriefsUpdateDurationRequestBodyFromJSONTyped(json, false);
}

export function DebriefsUpdateDurationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsUpdateDurationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': !exists(json, 'duration') ? undefined : EngineParamBindingPayloadFromJSON(json['duration']),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
    };
}

export function DebriefsUpdateDurationRequestBodyToJSON(value?: DebriefsUpdateDurationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': EngineParamBindingPayloadToJSON(value.duration),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
    };
}

