import type { SVGProps } from "react";
import * as React from "react";
const SvgBellRinging = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.751 7.75a.75.75 0 0 0 .733-.595 6.465 6.465 0 0 1 1.877-3.357.75.75 0 1 0-1.036-1.086 7.97 7.97 0 0 0-2.309 4.133.751.751 0 0 0 .735.905ZM17.672 6.845a7.968 7.968 0 0 0-2.31-4.134.751.751 0 0 0-1.036 1.086 6.473 6.473 0 0 1 1.877 3.357.75.75 0 0 0 1.468-.31l.001.001ZM16.594 14.5c-.689 0-1.25-.561-1.25-1.25V8.5c0-3.033-2.467-5.5-5.5-5.5a5.506 5.506 0 0 0-5.5 5.5v4.75c0 .689-.56 1.25-1.25 1.25a.75.75 0 0 0 0 1.5h13.5a.75.75 0 0 0 0-1.5ZM8.645 17a.5.5 0 0 0-.489.603c.173.822.868 1.397 1.69 1.397.82 0 1.515-.575 1.688-1.397a.5.5 0 0 0-.489-.603h-2.4Z"
    />
  </svg>
);
export default SvgBellRinging;
