/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsightsFilter
 */
export interface InsightsFilter {
    /**
     * Reason why this filter is disabled
     * @type {string}
     * @memberof InsightsFilter
     */
    disabled_reason?: string;
    /**
     * Usually the same as the field_key, unless it needs an extra ID like a custom field or role
     * @type {string}
     * @memberof InsightsFilter
     */
    field_id: string;
    /**
     * The type of thing you're filtering on - e.g. 'custom_field' or 'severity'
     * @type {string}
     * @memberof InsightsFilter
     */
    field_key: string;
    /**
     * Whether this filter is disabled
     * @type {boolean}
     * @memberof InsightsFilter
     */
    is_disabled: boolean;
    /**
     * Operator of the filter
     * @type {string}
     * @memberof InsightsFilter
     */
    operator: InsightsFilterOperatorEnum;
    /**
     * Usually the same as field_id, unless this filter is based on a user backlink
     * @type {string}
     * @memberof InsightsFilter
     */
    typeahead_lookup_id?: string;
    /**
     * Values that you're filtering on
     * @type {Array<string>}
     * @memberof InsightsFilter
     */
    values: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum InsightsFilterOperatorEnum {
    Is = 'is',
    IsNot = 'is_not',
    OneOf = 'one_of',
    AllOf = 'all_of',
    Lte = 'lte',
    Lt = 'lt',
    Gte = 'gte',
    Gt = 'gt',
    NotIn = 'not_in',
    IsBlank = 'is_blank',
    IsSet = 'is_set',
    Contains = 'contains',
    Within = 'within',
    Between = 'between',
    DateRange = 'date_range'
}

export function InsightsFilterFromJSON(json: any): InsightsFilter {
    return InsightsFilterFromJSONTyped(json, false);
}

export function InsightsFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled_reason': !exists(json, 'disabled_reason') ? undefined : json['disabled_reason'],
        'field_id': json['field_id'],
        'field_key': json['field_key'],
        'is_disabled': json['is_disabled'],
        'operator': json['operator'],
        'typeahead_lookup_id': !exists(json, 'typeahead_lookup_id') ? undefined : json['typeahead_lookup_id'],
        'values': json['values'],
    };
}

export function InsightsFilterToJSON(value?: InsightsFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled_reason': value.disabled_reason,
        'field_id': value.field_id,
        'field_key': value.field_key,
        'is_disabled': value.is_disabled,
        'operator': value.operator,
        'typeahead_lookup_id': value.typeahead_lookup_id,
        'values': value.values,
    };
}

