/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface SeveritiesCreateRequestBody
 */
export interface SeveritiesCreateRequestBody {
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof SeveritiesCreateRequestBody
     */
    description: TextDocumentPayload;
    /**
     * Human readable name of the severity
     * @type {string}
     * @memberof SeveritiesCreateRequestBody
     */
    name: string;
    /**
     * Rank to help sort severities (lower numbers are less severe)
     * @type {number}
     * @memberof SeveritiesCreateRequestBody
     */
    rank?: number;
}

export function SeveritiesCreateRequestBodyFromJSON(json: any): SeveritiesCreateRequestBody {
    return SeveritiesCreateRequestBodyFromJSONTyped(json, false);
}

export function SeveritiesCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeveritiesCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': TextDocumentPayloadFromJSON(json['description']),
        'name': json['name'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
    };
}

export function SeveritiesCreateRequestBodyToJSON(value?: SeveritiesCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': TextDocumentPayloadToJSON(value.description),
        'name': value.name,
        'rank': value.rank,
    };
}

