/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SavedView,
    SavedViewFromJSON,
    SavedViewFromJSONTyped,
    SavedViewToJSON,
} from './SavedView';

/**
 * 
 * @export
 * @interface SavedViewsListResponseBody
 */
export interface SavedViewsListResponseBody {
    /**
     * 
     * @type {Array<SavedView>}
     * @memberof SavedViewsListResponseBody
     */
    saved_views: Array<SavedView>;
}

export function SavedViewsListResponseBodyFromJSON(json: any): SavedViewsListResponseBody {
    return SavedViewsListResponseBodyFromJSONTyped(json, false);
}

export function SavedViewsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedViewsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saved_views': ((json['saved_views'] as Array<any>).map(SavedViewFromJSON)),
    };
}

export function SavedViewsListResponseBodyToJSON(value?: SavedViewsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saved_views': ((value.saved_views as Array<any>).map(SavedViewToJSON)),
    };
}

