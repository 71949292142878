import {
  AlertSchema,
  AlertSource,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  AlertsShowSchemaResponseBody,
  AlertsShowSourceConfigResponseBody,
  AlertsUpdateSourceConfigRequestBody,
  AlertTemplatePayload,
  CatalogResource,
  EngineParamBinding,
  EngineParamBindingPayload,
  Expression,
  Priority,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { isEmptyBinding } from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { TitleInputV2 } from "@incident-shared/forms/v2/inputs/TitleInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ButtonTheme, GenericErrorMessage, Loader } from "@incident-ui";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { Prompt } from "src/components/@shared/utils/Prompt";
import { containsExpressionReference } from "src/components/legacy/workflows/common/utils";
import { useAllResources } from "src/hooks/useResources";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { KeyedMutator } from "swr";
import { useDebounce } from "use-hooks";

import { PrioritiesCreateEditDrawer } from "../../priorities/PrioritiesCreateEditDrawer";
import {
  AlertSourceCreateWizardSteps,
  AlertSourceStepEnum,
} from "../AlertSourceCreateWizardSteps";
import {
  AlertSourceFooter,
  AlertSourceGradientBox,
  AlertSourceSplitLayout,
  useAlertSourceSplitLayout,
} from "../AlertSourceLayout";
import { stripInvalidBindings } from "../stripInvalidBindings";
import { useScopeWithAlertPreviews } from "../useScopeWithAlertPreviews";
import { AlertsCatalogSetupWidget } from "./AlertsCatalogSetupWidget";
import { AlertSourceAttributes } from "./AlertSourceAttributes";
import { AlertSourcePriority } from "./AlertSourcePriority";
import { AlertSourceTitleDescription } from "./AlertSourceTitleDescription";
import { AlertsPreviewSplit } from "./AlertsPreviewSplit";

// This page drives the configuration of alert source configs. It is a vertical
// split pane with config fields (e.g. title, description) on the left and a
// preview of the parsed alerts on the right.
export const AlertSourceConfigurePage = ({
  titleInputRef,
  alertSource,
  alertSourceConfig,
  mode,
}: {
  titleInputRef: React.RefObject<HTMLDivElement>;
  alertSourceConfig: AlertSourceConfig;
  alertSource: AlertSource;
  mode: "edit" | "wizard";
}) => {
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const {
    data: schemaResponse,
    mutate: mutateSchema,
    isLoading: schemaLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);

  const {
    data: catalogResourcesResponse,
    isLoading: catalogResourcesLoading,
    error: catalogResourcesError,
  } = useAPI("catalogListResources", undefined);

  const {
    data: prioritiesResponse,
    isLoading: prioritiesLoading,
    error: prioritiesError,
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  if (
    resourcesError ||
    schemaError ||
    catalogResourcesError ||
    prioritiesError
  ) {
    return (
      <GenericErrorMessage
        error={
          resourcesError ||
          schemaError ||
          catalogResourcesError ||
          prioritiesError
        }
      />
    );
  }

  if (
    schemaLoading ||
    !schemaResponse ||
    catalogResourcesLoading ||
    !catalogResourcesResponse ||
    resourcesLoading ||
    !resources ||
    prioritiesLoading ||
    !prioritiesResponse
  ) {
    return <Loader className={"h-full"} />;
  }

  return (
    <AlertSourceConfigureForm
      existingSource={alertSourceConfig}
      alertSource={alertSource}
      initialSchema={schemaResponse.alert_schema}
      resources={resources}
      mutateSchema={mutateSchema}
      catalogResources={catalogResourcesResponse.resources}
      defaultPriority={_.find(
        prioritiesResponse.priorities,
        (p) => p.is_default,
      )}
      titleInputRef={titleInputRef}
      mode={mode}
    />
  );
};

export const AlertSourceConfigureForm = ({
  existingSource,
  // We should only be reading the schema's version/attributes from form state,
  // we only want to use this here to set default form values.
  initialSchema: _initialSchema,
  resources,
  catalogResources,
  defaultPriority,
  titleInputRef,
  mutateSchema,
  alertSource,
  mode,
}: {
  existingSource: AlertSourceConfig;
  alertSource: AlertSource;
  initialSchema: AlertSchema;
  mutateSchema: KeyedMutator<AlertsShowSchemaResponseBody>;
  resources: Resource[];
  catalogResources: CatalogResource[];
  defaultPriority: Priority | undefined;
  titleInputRef: React.RefObject<HTMLDivElement>;
  mode: "edit" | "wizard";
}) => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const formMethods = useForm<AlertSourceConfigureFormData>({
    defaultValues: makeDefaultValues(
      existingSource,
      _initialSchema,
      defaultPriority,
    ),
  });

  const refetchSourceConfigs = useAPIRefetch(
    "alertsListSourceConfigs",
    undefined,
  );

  const queryParams = useQueryParams();

  const { trigger: onSubmit, genericError } = useAPIMutation(
    "alertsShowSourceConfig",
    {
      id: existingSource?.id ?? "",
    },
    async (apiClient, data: AlertSourceConfigureFormData) => {
      const newSchema = await apiClient.alertsUpdateSchema({
        updateSchemaRequestBody: {
          alert_schema: {
            attributes: Array.from(attributes),
            version: data.version,
          },
        },
      });
      formMethods.setValue("version", newSchema.alert_schema.version);
      formMethods.setValue("attributes", newSchema.alert_schema.attributes);
      mutateSchema(newSchema);

      const newConfig = await apiClient.alertsUpdateSourceConfig({
        id: existingSource?.id ?? "",
        updateSourceConfigRequestBody: {
          name: data.name,
          template: stripInvalidBindings({
            expressions: template.expressions
              .filter((e) => getExpressionUsages(e, data)[0].usages.length > 0)
              .map((e) => expressionToPayload(e as unknown as Expression)),
            title: data.template.title,
            description: data.template.description,
            priority: data.template.priority,
            bindings: data.template.bindings,
          }),
          routing: existingSource.routing,
        },
      });

      formMethods.reset({
        ...(newConfig.alert_source_config as unknown as AlertSourceConfigureFormData),
        attributes: newSchema.alert_schema.attributes,
      }); // to ensure isDirty=false

      refetchSourceConfigs();
      return newConfig;
    },
    {
      onSuccess: async (data: AlertsShowSourceConfigResponseBody) => {
        showToast({
          theme: ToastTheme.Success,
          title: `Alert source saved`,
          toastSide: ToastSideEnum.TopRight,
        });

        // If we came from alert routes then we should go back. This is very
        // fragile, we should find a better way of wiring this stuff up.
        if (queryParams.get("from") === "alert-routes") {
          return navigate(
            `/alerts/routes/create?initial_source_id=${data.alert_source_config.id}`,
          );
        }

        return navigate(`/alerts/configuration`);
      },
      setError: (name, error) => {
        const automaticallyDisplayedErrors = [
          "name",
          "template.title",
          "template.description",
        ];
        if (automaticallyDisplayedErrors.includes(name)) {
          formMethods.setError(name, error);
        } else if (name === "version") {
          showToast({
            theme: ToastTheme.Error,
            title: "Unable to save",
            description:
              "Someone else has edited your alert attributes, please refresh the page and try again.",
            toastSide: ToastSideEnum.TopRight,
          });
        } else {
          showToast({
            theme: ToastTheme.Error,
            title: "Unable to save",
            description: error.message,
            toastSide: ToastSideEnum.TopRight,
          });
        }
      },
    },
  );

  const [title, description] = formMethods.watch([
    "template.title",
    "template.description",
  ]);
  const emptyTitleOrDescription =
    isEmptyBinding(title) || isEmptyBinding(description);

  const [template, attributes, version] = formMethods.watch([
    "template",
    "attributes",
    "version",
  ]);
  const [isEditingAttributes, setIsEditingAttributes] = useState(false);

  const {
    data: previewAlerts,
    isLoading: previewAlertsLoading,
    error: previewAlertsError,
    mutate: previewAlertsMutate,
  } = useAPI(
    "alertsPreviewSourceConfigAlerts",
    {
      id: existingSource?.id,
      previewSourceConfigAlertsRequestBody: {
        template: stripInvalidBindings({
          expressions: template.expressions.map((e) =>
            expressionToPayload(e as unknown as Expression),
          ),
          title: title,
          description: description,
          bindings: template.bindings,
        }),
        attributes: attributes,
        version: version,
      },
    },
    {
      // This allows us to show a nicer loader, instead of having layout shift
      // whenever something changes.
      keepPreviousData: true,
    },
  );

  const expressionsMethods = useFieldArray({
    control: formMethods.control,
    name: "template.expressions",
    keyName: "key",
  });

  // Get a scope with the alert JSON previews patched in
  const scope = useScopeWithAlertPreviews({
    alerts: previewAlerts?.alerts ?? [],
    alertSource,
  });

  const scopeWithExpressions = addExpressionsToScope<ReferenceWithExample>(
    scope,
    expressionsMethods.fields as unknown as ExpressionFormData[],
  );

  // We JSON.stringify this so that we can trigger the use effect whenever the
  // template changes, as otherwise swr does not refire.
  const templateChecksum = useDebounce(JSON.stringify(template), 1000);
  useEffect(() => {
    if (!emptyTitleOrDescription) {
      previewAlertsMutate();
    }
  }, [emptyTitleOrDescription, templateChecksum, previewAlertsMutate]);

  const assignedAttributeIds = Object.entries(template.bindings).reduce(
    (lookup, [key, binding]) => {
      if (!isEmptyBinding(binding)) {
        lookup.add(key);
      }
      return lookup;
    },
    new Set<string>(),
  );
  const assignedAttributes = attributes.filter((attr) =>
    assignedAttributeIds.has(attr.id || ""),
  );

  // TODO: Should be URL driven so we can link and navigation works.
  const [showPrioritiesDrawer, setShowPrioritiesDrawer] = useState(false);

  const alertSourceAttributesRef = useRef<HTMLDivElement>(null);

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionsMethods}
      allowAllOfACatalogType={false}
    >
      <AlertSourceSplitLayout
        bordered={mode === "wizard"}
        header={
          mode === "wizard" ? (
            <AlertSourceGradientBox
              className={"grow gap-8 p-6"}
              sourceTypeConfig={
                ALERT_SOURCE_TYPE_CONFIGS[existingSource.source_type]
              }
            >
              <AlertSourceCreateWizardSteps
                step={AlertSourceStepEnum.Configure}
                sourceType={
                  existingSource.source_type as AlertSourceSourceTypeEnum
                }
                title={`Configure your setup`}
                description={`Enrich your alerts by bringing in attributes like team, features or priority that will help you route and understand your alerts.`}
              />
            </AlertSourceGradientBox>
          ) : null
        }
        left={
          <div className={"py-5 px-6"}>
            <Form.Root
              id={"alert-source-configure"}
              formMethods={formMethods}
              onSubmit={onSubmit}
              warnWhenDirty
              genericError={genericError}
            >
              <Prompt
                when={formMethods.formState.isDirty}
                message={
                  "Your changes have not been saved. Are you sure you want to navigate away?"
                }
              />
              <TitleInputV2
                name={"name"}
                formMethods={formMethods}
                placeholder="Alert source name"
                inputRef={titleInputRef}
                disabled={existingSource.alert_source.max_instances === 1}
                textCursor
              />
              <div className={"flex flex-col gap-6"}>
                <AlertSourceTitleDescription
                  scopeWithExpressions={scopeWithExpressions}
                  resources={resources}
                />

                <AlertsCatalogSetupWidget
                  openAttributesDrawer={() => setIsEditingAttributes(true)}
                  populatedAttributes={assignedAttributes.map((a) => a.id)}
                  recentAlerts={previewAlerts?.alerts ?? []}
                />

                <div ref={alertSourceAttributesRef}>
                  <AlertSourceAttributes
                    scopeWithExpressions={scopeWithExpressions}
                    resources={resources}
                    alertSourceConfig={existingSource}
                    previewAlerts={previewAlerts?.alerts ?? []}
                    alertSource={alertSource}
                    isEditing={isEditingAttributes}
                    setIsEditing={setIsEditingAttributes}
                  />
                </div>
                <AlertSourcePriority
                  scope={scope}
                  scopeWithExpressions={scopeWithExpressions}
                  resources={resources}
                />
              </div>
            </Form.Root>
          </div>
        }
        right={
          <FormProvider<AlertSourceConfigureFormData> {...formMethods}>
            <AlertsPreviewSplit
              alerts={previewAlerts?.alerts ?? []}
              existingSource={existingSource}
              resources={catalogResources}
              schema={{
                version,
                attributes,
              }}
              isLoading={previewAlertsLoading}
              error={previewAlertsError}
              assignedAttributes={assignedAttributes}
            />
          </FormProvider>
        }
        footer={
          <ConfigureFooter
            mode={mode}
            alertSourceAttributesRef={alertSourceAttributesRef}
          />
        }
      />

      <Prompt
        when={formMethods.formState.isDirty}
        message={
          "Your changes have not been saved. Are you sure you want to navigate away?"
        }
      />
      <AnimatePresence>
        {showPrioritiesDrawer && (
          <PrioritiesCreateEditDrawer
            onClose={() => setShowPrioritiesDrawer(false)}
          />
        )}
      </AnimatePresence>
    </ExpressionsMethodsProvider>
  );
};

// This is the footer that we either display as a sticky footer in the wizard
// wrapper, or as a standalone footer in the split layout.
const ConfigureFooter = ({
  mode,
  alertSourceAttributesRef,
}: {
  mode: "edit" | "wizard";
  alertSourceAttributesRef: React.RefObject<HTMLDivElement>;
}) => {
  const [needsToScrollToAttributes, setNeedsToScrollToAttributes] = useState(
    mode === "wizard",
  );
  const { leftPanelRef } = useAlertSourceSplitLayout();

  // If the user scrolls down the page by a reasonable amount, we assume they
  // have seen the attributes and we don't need to scroll to them.
  useEffect(() => {
    const handleScroll = () => {
      const topOffset =
        alertSourceAttributesRef?.current?.getBoundingClientRect().top ?? 0;

      if (topOffset > 200) {
        setNeedsToScrollToAttributes(false);
      }
    };

    const currentLeftPanelRef = leftPanelRef.current;
    currentLeftPanelRef?.addEventListener("scroll", handleScroll);

    return () => {
      currentLeftPanelRef?.removeEventListener("scroll", handleScroll);
    };
  }, [alertSourceAttributesRef, leftPanelRef]);

  return (
    <AlertSourceFooter
      continueButton={
        !needsToScrollToAttributes ? (
          <GatedButton
            form={"alert-source-configure"}
            type={"submit"}
            analyticsTrackingId="alert-source-configure-save-and-finish"
            className="pt-2.5 pb-2.5"
            theme={ButtonTheme.Primary}
            requiredScope={ScopeNameEnum.AlertSourceCreate}
            disabledTooltipContent={"You do not have permission to do this"}
          >
            {mode === "wizard" ? "Save and finish" : "Save"}
          </GatedButton>
        ) : (
          <GatedButton
            onClick={(e) => {
              alertSourceAttributesRef.current?.scrollIntoView({
                behavior: "smooth",
              });
              setNeedsToScrollToAttributes(false);
              e?.preventDefault();
            }}
            analyticsTrackingId="alert-source-configure-continue"
            className="pt-2.5 pb-2.5"
            theme={ButtonTheme.Primary}
            requiredScope={ScopeNameEnum.AlertSourceCreate}
            disabledTooltipContent={"You do not have permission to do this"}
          >
            Continue
          </GatedButton>
        )
      }
    />
  );
};

export const makeDefaultValues = (
  config: AlertSourceConfig,
  alertSchema: AlertSchema,
  priority: Priority | undefined,
): AlertSourceConfigureFormData => {
  const defaults = {
    ...config,
    attributes: alertSchema.attributes,
  } as unknown as AlertSourceConfigureFormData;

  if (config.template.priority) {
    defaults.template.priority = config.template.priority;
  } else if (priority) {
    defaults.template.priority = priorityToParamBinding(priority);
  }

  defaults.version = alertSchema.version;

  return defaults;
};

// FormData is both an alert source and alert schema payload, as we let you dynamically edit your schema
// by adding attributes here
export type TemplateFormData = Omit<
  AlertTemplatePayload,
  "expressions" | "bindings"
> & {
  expressions: ExpressionFormData[];
  bindings: {
    [key: string]: EngineParamBindingPayload & {
      // If the user hasn't set a value yet, but has indicated they want to
      // set it, we enable this.
      isLocallyEditing?: boolean;
    };
  };
};
type SourceConfigFormData = Omit<
  AlertsUpdateSourceConfigRequestBody,
  "template"
> & {
  template: TemplateFormData;
};
export type AlertSourceConfigureFormData = SourceConfigFormData & AlertSchema;

export const getExpressionUsages = (
  expression: ExpressionFormData,
  formData: AlertSourceConfigureFormData,
) => {
  const containsExpressionRef = (serialisable: unknown) =>
    containsExpressionReference(expression, serialisable);

  const usedInTitle =
    formData.template.title && containsExpressionRef(formData.template.title);
  const usedInDescription =
    formData.template.description &&
    containsExpressionRef(formData.template.description);
  const usedInPriority =
    formData.template.priority &&
    containsExpressionRef(formData.template.priority);

  const attributeUsages = formData.attributes.filter((attr) => {
    const binding = attr.id && formData.template.bindings[attr.id];
    if (binding) {
      if (binding.value) {
        return containsExpressionRef(binding.value);
      } else if (binding.array_value) {
        return binding.array_value.some((v) => containsExpressionRef(v));
      }
    }
    return false;
  });

  const usages: string[] = [];
  if (usedInTitle) {
    usages.push("Title");
  }
  if (usedInDescription) {
    usages.push("Description");
  }
  if (usedInPriority) {
    usages.push("Priority");
  }

  attributeUsages.forEach((attr) => usages.push(attr.name));

  return [{ label: "Alert attributes", usages: usages }];
};

export const priorityToParamBinding = (
  priority: Priority,
): EngineParamBinding => ({
  value: {
    label: priority.name,
    literal: priority.id,
    value: priority.id,
    sort_key: priority.rank.toString(),
  },
});
