import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { Callout, CalloutTheme, ModalFooter, Txt } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { taskCanBeManuallyCompleted } from "src/components/legacy/incident/postincidentflow/taskCanBeManuallyCompleted";
import {
  Incident,
  IncidentVisibilityEnum,
  PostIncidentTaskSlim,
  useClient,
} from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";

export type PostIncidentBulkAction =
  | "mark-as-completed"
  | "mark-as-skipped"
  | "assign";

const ActionCopy: Record<
  PostIncidentBulkAction,
  { title: string; body: string; reason_missing?: (count: number) => string }
> = {
  "mark-as-completed": {
    title: "Mark as completed",
    body: "complete",
    reason_missing: (count) =>
      `You've selected ${count} tasks which cannot be marked as complete manually (they must be completed automatically). We won't do anything for these tasks.`,
  },
  "mark-as-skipped": {
    title: "Mark as skipped",
    body: "skip",
    reason_missing: (count) =>
      `You have selected ${count} tasks which cannot be skipped as they are required. We won't do anything for these tasks.`,
  },
  assign: {
    title: "Assign",
    body: "assign",
  },
};

type BulkFormData = {
  assignee_id?: Record<string, string>;
};

type BulkUpdateModalProps = {
  action: PostIncidentBulkAction;
  incidents: Incident[];
  selectedTasks: PostIncidentTaskSlim[];
  setSelectedTasks: (tasks: PostIncidentTaskSlim[]) => void;
  refetchIncidents: () => Promise<void>;
  onClose: () => void;
};

export const BulkUpdateModal = ({
  incidents,
  action,
  selectedTasks,
  setSelectedTasks,
  refetchIncidents,
  onClose,
}: BulkUpdateModalProps) => {
  const formMethods = useForm<BulkFormData>();
  const client = useClient();

  let filteredTasks = selectedTasks;
  // We want to ensure we're only performing actions on the valid tasks,
  // as otherwise the UI state will get unhappy as various consolidation
  // operations happen async in the background
  switch (action) {
    case "mark-as-completed":
      filteredTasks = selectedTasks.filter((t) =>
        taskCanBeManuallyCompleted(t.config),
      );
      break;
    case "mark-as-skipped":
      filteredTasks = selectedTasks.filter((t) => !t.config.unskippable);
      break;
  }

  const [skipTasks, { saving: isSkippingTasks }] = useMutation(
    async (payload: { tasks: PostIncidentTaskSlim[] }) => {
      await Promise.all(
        payload.tasks.map(({ id }) =>
          client.postIncidentFlowRejectTask({
            id,
          }),
        ),
      );
    },
    {
      onSuccess: () => {
        refetchIncidents();
        setSelectedTasks([]);
        onClose();
      },
    },
  );

  const [completeTasks, { saving: isCompletingTasks }] = useMutation(
    async (payload: { tasks: PostIncidentTaskSlim[] }) => {
      await Promise.all(
        payload.tasks.map(({ id }) =>
          client.postIncidentFlowCompleteTask({
            id,
          }),
        ),
      );
    },
    {
      onSuccess: () => {
        refetchIncidents();
        setSelectedTasks([]);
        onClose();
      },
    },
  );

  const [assignTasks, { saving: isAssigningTasks }] = useMutation(
    async (payload: { tasks: PostIncidentTaskSlim[]; assignee_id: string }) => {
      await Promise.all(
        payload.tasks.map(({ id }) =>
          client.postIncidentFlowAssignTask({
            id,
            assignTaskRequestBody: {
              assignee_id: payload.assignee_id,
            },
          }),
        ),
      );
    },
    {
      onSuccess: () => {
        refetchIncidents();
        setSelectedTasks([]);
        onClose();
      },
    },
  );

  const onSubmit = async () => {
    switch (action) {
      case "mark-as-completed":
        await completeTasks({ tasks: filteredTasks });
        break;
      case "mark-as-skipped":
        await skipTasks({ tasks: filteredTasks });
        break;
      case "assign":
        const assignee = formMethods.getValues().assignee_id;
        if (!assignee) {
          return;
        }

        // Get the value of the assignee_id object
        const assigneeId = Object.values(assignee)[0];

        await assignTasks({
          tasks: filteredTasks,
          assignee_id: assigneeId,
        });
        break;
    }
  };

  if (!action) {
    return null;
  }

  const saving = isSkippingTasks || isCompletingTasks || isAssigningTasks;

  const unactionableTaskCount = selectedTasks.length - filteredTasks.length;

  const actionOptions = ActionCopy[action];

  const incidentIDs = selectedTasks.reduce<string[]>((acc, task) => {
    if (!acc.includes(task.incident_id)) {
      acc.push(task.incident_id);
    }
    return acc;
  }, []);

  const getIncidentName = (incidentId: string) => {
    const inc = incidents.find((i) => i.id === incidentId);

    if (!inc) return "Unknown incident";

    const countOfTasks = selectedTasks.filter(
      (task) => task.incident_id === incidentId,
    ).length;

    const plural = countOfTasks === 1 ? "" : "s";

    return `INC-${inc?.external_id}: ${inc?.name} (${countOfTasks} task${plural})`;
  };

  const getHelpText = (incidentId: string) => {
    const inc = incidents.find((i) => i.id === incidentId);

    if (!inc) return undefined;

    return inc.visibility === IncidentVisibilityEnum.Private
      ? "As this is a private incident, you can only assign post-incident tasks to people with access."
      : undefined;
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      analyticsTrackingId={`post-incident-tasks-${action}`}
      title={actionOptions.title}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          hideConfirmButton={filteredTasks.length === 0}
          confirmButtonText="Save"
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <div className="flex flex-col gap-4">
        {filteredTasks.length > 0 && (
          <Txt>
            {`You are about to ${actionOptions.body} ${
              filteredTasks.length
            } task${filteredTasks.length === 1 ? "" : "s"}.`}
          </Txt>
        )}
        {action === "assign" && (
          <>
            <Txt>
              Choose a user to assign the selected tasks to, based on the
              individual incident.
            </Txt>
            {incidentIDs.map((incidentId) => (
              <DynamicSingleSelectV2
                key={incidentId}
                formMethods={formMethods}
                label={getIncidentName(incidentId)}
                required={true}
                isClearable={false}
                helptext={getHelpText(incidentId)}
                name={`assignee_id.${incidentId}`}
                placeholder="Select user"
                loadOptions={getTypeaheadOptions(
                  client,
                  TypeaheadTypeEnum.User,
                  {
                    incidentId,
                  },
                )}
                hydrateOptions={hydrateInitialSelectOptions(
                  client,
                  TypeaheadTypeEnum.User,
                )}
              />
            ))}
          </>
        )}
        {unactionableTaskCount > 0 && actionOptions.reason_missing && (
          <Callout theme={CalloutTheme.Info}>
            {actionOptions.reason_missing(unactionableTaskCount)}
          </Callout>
        )}
      </div>
    </Form.Modal>
  );
};
