/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentDemoResponseBody
 */
export interface StatusPageContentDemoResponseBody {
    /**
     * The demo status page
     * @type {string}
     * @memberof StatusPageContentDemoResponseBody
     */
    demo_url: string;
}

export function StatusPageContentDemoResponseBodyFromJSON(json: any): StatusPageContentDemoResponseBody {
    return StatusPageContentDemoResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentDemoResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentDemoResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'demo_url': json['demo_url'],
    };
}

export function StatusPageContentDemoResponseBodyToJSON(value?: StatusPageContentDemoResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'demo_url': value.demo_url,
    };
}

