import type { SVGProps } from "react";
import * as React from "react";
const SvgSplitArrow = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.872 5.265h5.775v-1.6H9.872c-1 0-1.92.5-2.464 1.34L5.341 8.199h-4.1a.8.8 0 0 0 0 1.601h4.1l2.067 3.195a2.922 2.922 0 0 0 2.464 1.34h5.775v-1.6H9.872c-.454 0-.873-.228-1.12-.61L6.732 9l2.022-3.126a1.33 1.33 0 0 1 1.12-.61Z"
    />
    <path
      fill="currentColor"
      d="M12.706 17.27a.8.8 0 0 1-.566-1.367l2.37-2.37-2.37-2.368a.8.8 0 1 1 1.132-1.132l2.935 2.934a.8.8 0 0 1 0 1.132l-2.935 2.935a.798.798 0 0 1-.565.235h-.001ZM12.706 8.2a.8.8 0 0 1-.566-1.367l2.37-2.37-2.37-2.368A.8.8 0 1 1 13.272.963l2.935 2.934a.8.8 0 0 1 0 1.132l-2.935 2.935a.798.798 0 0 1-.565.234l-.001.002Z"
    />
  </svg>
);
export default SvgSplitArrow;
