import { Input, InputType } from "@incident-ui/Input/Input";
import React, { ChangeEvent } from "react";
import { tcx } from "src/utils/tailwind-classes";

export type DateTimeFormType = {
  date: string;
  time: string;
  isEnd: boolean;
};

// DateTimeInput allows us to give a date time input that doesn't
// make the user want to cry (the native one is spectacularly bad).
// Luckily for us, the native Date and Time inputs are fine, so we
// can just put them side by side.
export const DateTimeInput = ({
  id,
  value,
  onChange,
  onBlur,
  className,
  hideTime,
}: {
  id: string;
  value: DateTimeFormType;
  onChange: (val: DateTimeFormType) => void;
  onBlur: () => void;
  className?: string;
  hideTime?: boolean;
}): React.ReactElement => {
  const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, date: e.target.value });
  };

  const onChangeTime = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, time: e.target.value });
  };

  return (
    <div className={tcx("flex space-x-2 text-slate-700", className)}>
      <div>
        <Input
          type={InputType.Date}
          id={`${id}-date`}
          placeholder=""
          value={value.date}
          onChange={onChangeDate}
          onBlur={onBlur}
        />
      </div>
      {!hideTime && (
        <div>
          <Input
            type={InputType.Time}
            id={`${id}-time`}
            placeholder=""
            value={value.time}
            onChange={onChangeTime}
            onBlur={onBlur}
          />
        </div>
      )}
    </div>
  );
};
