import {
  StepProgressStatusEnum as StatusEnum,
  StepSlim,
  WorkflowRun,
} from "@incident-io/api";
import { Icon, IconEnum, Txt } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const WorkflowsRunResult = ({
  steps,
  workflowRun,
  showHelpText,
}: {
  steps: StepSlim[];
  workflowRun: WorkflowRun;
  showHelpText?: boolean;
}) => {
  const overallSuccess = workflowRun.progress.every(
    (step) => step.status === StatusEnum.Complete,
  );
  const stepLabelLookup: { [key: string]: string } = steps.reduce(
    (lookupObj, currentStep) => {
      lookupObj[currentStep.name] = currentStep.label;
      return lookupObj;
    },
    {},
  );

  return (
    <div className="flex flex-col gap-2">
      {showHelpText && (
        <Txt>
          {overallSuccess
            ? "The workflow completed successfully! The following steps were run:"
            : "The workflow didn't complete successfully. The following steps were run:"}
        </Txt>
      )}
      <ul className="text-sm">
        {workflowRun.progress.map((progressItem, i) => {
          return (
            <li className="pb-4 relative" key={i}>
              {i !== workflowRun.progress.length - 1 ? (
                <span
                  className="absolute top-1 left-[9px] bg-slate-500 w-0.5 h-full"
                  aria-hidden="true"
                />
              ) : null}
              <div className={tcx("flex relative items-center bg-white")}>
                <Icon
                  id={iconForStatus[progressItem.status]}
                  className={tcx(
                    " mr-0.5",
                    classNameForStatus[progressItem.status],
                  )}
                />
                <div>
                  {stepLabelLookup[progressItem.step]}
                  {progressItem.status === StatusEnum.Error && (
                    <div className="text-red-500 text-xs">
                      {progressItem.error}
                    </div>
                  )}
                  {progressItem.status === StatusEnum.Pending && (
                    <div className="text-slate-700 text-xs">
                      Did not run as a previous step failed
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const iconForStatus: {
  [key in StatusEnum]: IconEnum;
} = {
  [StatusEnum.Complete]: IconEnum.Success,
  [StatusEnum.Error]: IconEnum.Close,
  [StatusEnum.Pending]: IconEnum.Warning,
};
const classNameForStatus: {
  [key in StatusEnum]: string;
} = {
  [StatusEnum.Complete]: "text-green-content",
  [StatusEnum.Error]: "text-red-content",
  [StatusEnum.Pending]: "text-amber-content",
};
