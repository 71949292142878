import { UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody } from "@incident-io/api";
import { ContentBox, Heading, Toggle } from "@incident-ui";
import { UserPreferences } from "src/contexts/ClientContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPIMutation } from "src/utils/swr";

type InsightsEmailFormData =
  UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody;

export const NotificationPreferencesForm = ({
  userPreferences,
}: {
  userPreferences: UserPreferences | null;
}) => {
  return (
    <ContentBox className={"p-6"}>
      {userPreferences && (
        <NotificationPreferencesFormContent userPreferences={userPreferences} />
      )}
    </ContentBox>
  );
};

export const NotificationPreferencesFormContent = ({
  userPreferences,
}: {
  userPreferences: UserPreferences;
}) => {
  const { trigger: saveState } = useAPIMutation(
    "userPreferencesShow",
    undefined,
    async (
      apiClient,
      data: UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody,
    ) =>
      await apiClient.userPreferencesUpdateNotificationDeliveryPreferences({
        updateNotificationDeliveryPreferencesRequestBody: data,
      }),
  );

  const { state, setState, hasSaved, saving } =
    useOptimisticAutoSave<InsightsEmailFormData>({
      initialState: userPreferences,
      saveState,
    });

  return (
    <>
      <div className="flex justify-between items-start space-x-4">
        <div className="grow max-w-2xl">
          <Heading level={2} id={"unread-notifications"}>
            Activity notifications
          </Heading>
          <p className={"mt-1 text-sm text-slate-700 mb-2"}>
            We can deliver your activity notifications to you if you miss them
            in the dashboard. This&apos;ll include activity in incidents you
            were involved in, for example a postmortem being added.
          </p>
        </div>
        <div className={""}>
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
      </div>
      <div className="mt-2">
        <Toggle
          id="receives_notifications_via_slack"
          disabled={false}
          align="left"
          label="Slack"
          description="Receive notifications via Slack direct message."
          on={state.receives_notifications_via_slack}
          onToggle={() =>
            setState({
              ...state,
              receives_notifications_via_slack:
                !state.receives_notifications_via_slack,
            })
          }
        />
      </div>
      <div className="mt-2">
        <Toggle
          id="receives_notifications_via_email"
          disabled={false}
          align="left"
          label="Emails"
          description="Receive notifications via Email."
          on={state.receives_notifications_via_email}
          onToggle={() =>
            setState({
              ...state,
              receives_notifications_via_email:
                !state.receives_notifications_via_email,
            })
          }
        />
      </div>
    </>
  );
};
