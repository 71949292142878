/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulesCreateFeedResponseBody
 */
export interface SchedulesCreateFeedResponseBody {
    /**
     * The URL that this feed can be accessed at
     * @type {string}
     * @memberof SchedulesCreateFeedResponseBody
     */
    feed_url: string;
}

export function SchedulesCreateFeedResponseBodyFromJSON(json: any): SchedulesCreateFeedResponseBody {
    return SchedulesCreateFeedResponseBodyFromJSONTyped(json, false);
}

export function SchedulesCreateFeedResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateFeedResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feed_url': json['feed_url'],
    };
}

export function SchedulesCreateFeedResponseBodyToJSON(value?: SchedulesCreateFeedResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feed_url': value.feed_url,
    };
}

