/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentTimestampValuePayload
 */
export interface IncidentTimestampValuePayload {
    /**
     * The id of the incident timestamp that this incident timestamp value is associated with.
     * @type {string}
     * @memberof IncidentTimestampValuePayload
     */
    incident_timestamp_id: string;
    /**
     * The current value of this timestamp, for this incident
     * @type {Date}
     * @memberof IncidentTimestampValuePayload
     */
    value?: Date;
}

export function IncidentTimestampValuePayloadFromJSON(json: any): IncidentTimestampValuePayload {
    return IncidentTimestampValuePayloadFromJSONTyped(json, false);
}

export function IncidentTimestampValuePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampValuePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_timestamp_id': json['incident_timestamp_id'],
        'value': !exists(json, 'value') ? undefined : (new Date(json['value'])),
    };
}

export function IncidentTimestampValuePayloadToJSON(value?: IncidentTimestampValuePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_timestamp_id': value.incident_timestamp_id,
        'value': value.value === undefined ? undefined : (value.value.toISOString()),
    };
}

