import { SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { DebriefSettings } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../SettingsSection";

type FormData = {
  exclude_test_or_tutorial_from_invites: boolean;
};

export const ExcludeTestOrTutorialInvitesToggle = ({
  settings,
}: {
  settings: DebriefSettings;
}): React.ReactElement => {
  const showToast = useToast();

  const formMethods = useForm<FormData>({
    defaultValues: {
      exclude_test_or_tutorial_from_invites:
        settings.exclude_test_or_tutorial_from_invites,
    },
  });

  const { reset, setError } = formMethods;

  const mutation = useAPIMutation(
    "debriefsShowSettings",
    undefined,
    async (apiClient, formData: FormData) =>
      await apiClient.debriefsUpdateExcludeTestOrTutorialFromInvites({
        updateExcludeTestOrTutorialFromInvitesRequestBody: {
          exclude_test_or_tutorial_from_invites:
            formData.exclude_test_or_tutorial_from_invites,
        },
      }),
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(settings);
      },
      setError,
    },
  );

  return (
    <SettingsSection
      formMethods={formMethods}
      mutation={mutation}
      enabledPath="exclude_test_or_tutorial_from_invites"
      title="Exclude invitees from debriefs for test and tutorial incidents"
      titleHeadingLevel={3}
      explanation={
        <>
          This is useful if you don&apos;t want the users configured above to be
          added to debrief calendar invites when using test or tutorial
          incidents.
        </>
      }
    />
  );
};
