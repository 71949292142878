/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EngineListResourcesRequestBody
 */
export interface EngineListResourcesRequestBody {
    /**
     * Filter for enumerable resources only
     * @type {boolean}
     * @memberof EngineListResourcesRequestBody
     */
    enumerable?: boolean;
    /**
     * The resources to fetch
     * @type {Array<string>}
     * @memberof EngineListResourcesRequestBody
     */
    resource_types: Array<string>;
}

export function EngineListResourcesRequestBodyFromJSON(json: any): EngineListResourcesRequestBody {
    return EngineListResourcesRequestBodyFromJSONTyped(json, false);
}

export function EngineListResourcesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineListResourcesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enumerable': !exists(json, 'enumerable') ? undefined : json['enumerable'],
        'resource_types': json['resource_types'],
    };
}

export function EngineListResourcesRequestBodyToJSON(value?: EngineListResourcesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enumerable': value.enumerable,
        'resource_types': value.resource_types,
    };
}

