import { Button, IconEnum } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRef, useState } from "react";
import {
  Incident,
  PostmortemSettingsDefaultDocumentProviderCopyPasteEnum,
  PostmortemsRenderRequestBodyDocumentProviderEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { copyDocAsHTML } from "./clipboard";
import { PostMortemHTMLTemplateForClipboard } from "./html/PostMortemHTMLTemplateForClipboard";

export const CopyPostMortemButton = ({
  incident,
  onCopied,
  legacyTemplateID, // Soon to be deprecated
  postmortemTemplateID, // V2 template
  timezone,
  defaultDocumentProviderCopyPaste,
}: {
  incident: Incident;
  onCopied: (didSucceed: boolean) => void;
  legacyTemplateID?: string;
  postmortemTemplateID?: string;
  timezone: string;
  defaultDocumentProviderCopyPaste?: PostmortemSettingsDefaultDocumentProviderCopyPasteEnum;
}): React.ReactElement => {
  const postMortemDocRef = useRef<HTMLDivElement>(null);
  const shouldCopyAsMarkdown =
    defaultDocumentProviderCopyPaste ===
    PostmortemSettingsDefaultDocumentProviderCopyPasteEnum.CopyPasteGithubWiki;

  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();

  let hasTemplate = false;
  if (featurePostmortemsInHouse) {
    hasTemplate = postmortemTemplateID != null;
  } else {
    hasTemplate = legacyTemplateID != null;
  }

  const { data, isLoading } = useAPI(
    !hasTemplate
      ? null
      : shouldCopyAsMarkdown
      ? "postmortemsRenderMarkdown"
      : "postmortemsRender",
    shouldCopyAsMarkdown
      ? {
          renderMarkdownRequestBody: {
            incident_id: incident.id,
            legacy_template_id: legacyTemplateID,
            postmortem_template_id: postmortemTemplateID,
            postmortem_document_options: { timezone },
          },
        }
      : {
          renderRequestBody: {
            incident_id: incident.id,
            legacy_template_id: legacyTemplateID,
            postmortem_template_id: postmortemTemplateID,
            postmortem_document_options: { timezone },
            document_provider:
              defaultDocumentProviderCopyPaste as unknown as PostmortemsRenderRequestBodyDocumentProviderEnum,
          },
        },
  );
  const doc = data?.doc;

  const [copying, setCopying] = useState(false);
  const [copied, setCopied] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      {doc && (
        <PostMortemHTMLTemplateForClipboard
          postMortemDocRef={postMortemDocRef}
          renderedDoc={doc}
          copyAsMarkdown={shouldCopyAsMarkdown}
        />
      )}
      <Button
        ref={buttonRef}
        analyticsTrackingId={null}
        icon={copied ? IconEnum.Tick : IconEnum.Copy}
        disabled={!doc}
        loading={isLoading || copying}
        onClick={async () => {
          setCopying(true);

          const didSuceed = await copyDocAsHTML(postMortemDocRef, buttonRef);
          onCopied(didSuceed);

          setCopying(false);
          setCopied(true);
        }}
      >
        Copy to clipboard
      </Button>
    </>
  );
};
