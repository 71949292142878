/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DebriefPlaceholder,
    DebriefPlaceholderFromJSON,
    DebriefPlaceholderFromJSONTyped,
    DebriefPlaceholderToJSON,
} from './DebriefPlaceholder';

/**
 * 
 * @export
 * @interface DebriefsListPlaceholdersResponseBody
 */
export interface DebriefsListPlaceholdersResponseBody {
    /**
     * 
     * @type {Array<DebriefPlaceholder>}
     * @memberof DebriefsListPlaceholdersResponseBody
     */
    placeholders: Array<DebriefPlaceholder>;
}

export function DebriefsListPlaceholdersResponseBodyFromJSON(json: any): DebriefsListPlaceholdersResponseBody {
    return DebriefsListPlaceholdersResponseBodyFromJSONTyped(json, false);
}

export function DebriefsListPlaceholdersResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsListPlaceholdersResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placeholders': ((json['placeholders'] as Array<any>).map(DebriefPlaceholderFromJSON)),
    };
}

export function DebriefsListPlaceholdersResponseBodyToJSON(value?: DebriefsListPlaceholdersResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'placeholders': ((value.placeholders as Array<any>).map(DebriefPlaceholderToJSON)),
    };
}

