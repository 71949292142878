/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ImagesCreateUploadURLRequestBody,
    ImagesCreateUploadURLRequestBodyFromJSON,
    ImagesCreateUploadURLRequestBodyToJSON,
    ImagesCreateUploadURLResponseBody,
    ImagesCreateUploadURLResponseBodyFromJSON,
    ImagesCreateUploadURLResponseBodyToJSON,
    ImagesUpdateStatusRequestBody,
    ImagesUpdateStatusRequestBodyFromJSON,
    ImagesUpdateStatusRequestBodyToJSON,
} from '../models';

export interface ImagesCreateUploadURLRequest {
    createUploadURLRequestBody: ImagesCreateUploadURLRequestBody;
}

export interface ImagesUpdateStatusRequest {
    imageId: string;
    updateStatusRequestBody: ImagesUpdateStatusRequestBody;
}

/**
 * 
 */
export class ImagesApi extends runtime.BaseAPI {

    /**
     * Create a signed URL for uploading an image
     * CreateUploadURL Images
     */
    async imagesCreateUploadURLRaw(requestParameters: ImagesCreateUploadURLRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImagesCreateUploadURLResponseBody>> {
        if (requestParameters.createUploadURLRequestBody === null || requestParameters.createUploadURLRequestBody === undefined) {
            throw new runtime.RequiredError('createUploadURLRequestBody','Required parameter requestParameters.createUploadURLRequestBody was null or undefined when calling imagesCreateUploadURL.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/images/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImagesCreateUploadURLRequestBodyToJSON(requestParameters.createUploadURLRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImagesCreateUploadURLResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a signed URL for uploading an image
     * CreateUploadURL Images
     */
    async imagesCreateUploadURL(requestParameters: ImagesCreateUploadURLRequest, initOverrides?: RequestInit): Promise<ImagesCreateUploadURLResponseBody> {
        const response = await this.imagesCreateUploadURLRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the upload status of an image
     * UpdateStatus Images
     */
    async imagesUpdateStatusRaw(requestParameters: ImagesUpdateStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling imagesUpdateStatus.');
        }

        if (requestParameters.updateStatusRequestBody === null || requestParameters.updateStatusRequestBody === undefined) {
            throw new runtime.RequiredError('updateStatusRequestBody','Required parameter requestParameters.updateStatusRequestBody was null or undefined when calling imagesUpdateStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/images/{image_id}/status`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImagesUpdateStatusRequestBodyToJSON(requestParameters.updateStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the upload status of an image
     * UpdateStatus Images
     */
    async imagesUpdateStatus(requestParameters: ImagesUpdateStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.imagesUpdateStatusRaw(requestParameters, initOverrides);
    }

}
