/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface CloudTasksRunRequest {
    authorization: string;
    xCloudTasksQueueName: string;
    xCloudTasksTaskName?: string;
    xCloudTasksTaskRetryCount?: number;
    xCloudTasksTaskExecutionCount?: number;
    xCloudTasksTaskETA?: number;
    xCloudTasksTaskPreviousResponse?: number;
    xCloudTasksTaskRetryReason?: string;
}

/**
 * 
 */
export class CloudTasksApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving Cloud Task invocations
     * Run CloudTasks
     */
    async cloudTasksRunRaw(requestParameters: CloudTasksRunRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling cloudTasksRun.');
        }

        if (requestParameters.xCloudTasksQueueName === null || requestParameters.xCloudTasksQueueName === undefined) {
            throw new runtime.RequiredError('xCloudTasksQueueName','Required parameter requestParameters.xCloudTasksQueueName was null or undefined when calling cloudTasksRun.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xCloudTasksQueueName !== undefined && requestParameters.xCloudTasksQueueName !== null) {
            headerParameters['X-CloudTasks-QueueName'] = String(requestParameters.xCloudTasksQueueName);
        }

        if (requestParameters.xCloudTasksTaskName !== undefined && requestParameters.xCloudTasksTaskName !== null) {
            headerParameters['X-CloudTasks-TaskName'] = String(requestParameters.xCloudTasksTaskName);
        }

        if (requestParameters.xCloudTasksTaskRetryCount !== undefined && requestParameters.xCloudTasksTaskRetryCount !== null) {
            headerParameters['X-CloudTasks-TaskRetryCount'] = String(requestParameters.xCloudTasksTaskRetryCount);
        }

        if (requestParameters.xCloudTasksTaskExecutionCount !== undefined && requestParameters.xCloudTasksTaskExecutionCount !== null) {
            headerParameters['X-CloudTasks-TaskExecutionCount'] = String(requestParameters.xCloudTasksTaskExecutionCount);
        }

        if (requestParameters.xCloudTasksTaskETA !== undefined && requestParameters.xCloudTasksTaskETA !== null) {
            headerParameters['X-CloudTasks-TaskETA'] = String(requestParameters.xCloudTasksTaskETA);
        }

        if (requestParameters.xCloudTasksTaskPreviousResponse !== undefined && requestParameters.xCloudTasksTaskPreviousResponse !== null) {
            headerParameters['X-CloudTasks-TaskPreviousResponse'] = String(requestParameters.xCloudTasksTaskPreviousResponse);
        }

        if (requestParameters.xCloudTasksTaskRetryReason !== undefined && requestParameters.xCloudTasksTaskRetryReason !== null) {
            headerParameters['X-CloudTasks-TaskRetryReason'] = String(requestParameters.xCloudTasksTaskRetryReason);
        }

        const response = await this.request({
            path: `/cloud_tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for receiving Cloud Task invocations
     * Run CloudTasks
     */
    async cloudTasksRun(requestParameters: CloudTasksRunRequest, initOverrides?: RequestInit): Promise<void> {
        await this.cloudTasksRunRaw(requestParameters, initOverrides);
    }

}
