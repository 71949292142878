import { Button, ButtonTheme, ContentBox, Icon, IconEnum } from "@incident-ui";
import React from "react";
import Tree from "react-d3-tree";
import {
  DecisionTree,
  DecisionTreeNode,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { renderSlackEmojis } from "../../../../utils/slack";

type Node = {
  name: string;
  children: Node[];
  attributes: DecisionTreeNode;
};

const parseDecisionTree = (tree: DecisionTree): Node => {
  // first, mount the root
  const parsed = {
    name: tree.root.name,
    attributes: tree.root as unknown as DecisionTreeNode,
    children: [],
  };

  // enrich each node recursively
  enrichNode(parsed, tree.nodes || []);

  return parsed;
};

const enrichNode = (node: Node, allNodes: DecisionTreeNode[]) => {
  node.children = allNodes
    .filter((x) => x.parent_id === node.attributes.id)
    .sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1))
    .map(
      (raw: DecisionTreeNode): Node => ({
        name: raw.name || "",
        attributes: raw,
        children: [],
      }),
    );

  node.children.forEach((child: Node) => {
    enrichNode(child, allNodes);
  });
};

export const EditButton = ({
  onEdit,
}: {
  onEdit: () => void;
}): React.ReactElement => {
  return (
    <div className="absolute top-0 right-0">
      <Button
        analyticsTrackingId={null}
        icon={IconEnum.Edit}
        theme={ButtonTheme.Secondary}
        onClick={onEdit}
        title="Edit"
        className="stroke-0"
      />
    </div>
  );
};

const NodeCard = ({
  editable,
  children,
  onEdit,
}: {
  editable: boolean;
  children: React.ReactNode;
  onEdit: () => void;
}): React.ReactElement => (
  <div className="mr-5 mt-5 h-32" onClick={editable ? onEdit : undefined}>
    {editable && <EditButton onEdit={onEdit} />}
    <ContentBox className="">{children}</ContentBox>
  </div>
);

const EmptyNode = ({ onEdit }: { onEdit: () => void }): React.ReactElement => (
  <button
    type="button"
    className="w-full h-16 flex justify-between p-5 space-x-4 border-2 border-slate-500 border-dashed rounded-2 text-center hover:border-slate-700 focus:outline-none text-slate-700 hover:text-slate-700"
    onClick={onEdit}
  >
    <div className="grow truncate">
      <div className="flex space-x-3">
        <Icon id={IconEnum.Add} className="mx-auto h-5 w-5" />
        <span className="text-sm font-medium truncate text-center w-full">
          Configure this node
        </span>
      </div>
    </div>
  </button>
);

const NodeContent = ({ content }: { content: string }): React.ReactElement => (
  <div className="w-full flex items-center justify-between p-6 space-x-6 h-16">
    <div className="grow truncate">
      <div className="flex items-center space-x-3">
        <h3 className="text-content-primary text-sm font-medium truncate text-center w-full">
          {content}
        </h3>
      </div>
    </div>
  </div>
);

const NodeChildrenOptions = ({
  nodeChildren,
}: {
  nodeChildren: Node[];
}): React.ReactElement | null => {
  if (nodeChildren.length === 0) {
    return null;
  }

  return (
    <div className="static -mt-px flex divide-x divide-slate-300 max-h-16 overflow-hidden border-t border-stroke">
      {nodeChildren.map((child: Node) => (
        <div
          key={child.attributes.id}
          className="w-0 grow flex items-start mb-1 overflow-hidden"
        >
          <div className="-mr-px w-0 grow inline-flex justify-center p-2 text-xs text-slate-700 font-medium border border-transparent rounded-bl-lg line-clamp-3">
            {renderSlackEmojis(child.attributes.option_label)}
          </div>
        </div>
      ))}
    </div>
  );
};

const renderNode =
  (onEdit: (nodeId: number) => void, canEditSettings: boolean) =>
  // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
  ({ nodeDatum }: { nodeDatum: any }): React.ReactElement => {
    // this gives us type hinting without upsetting the main 'tree' caller
    const data = nodeDatum as Node;
    const foreignObjectProps = { width: 250, height: 208, x: -125, y: -50 };

    return (
      <g>
        <foreignObject {...foreignObjectProps}>
          <NodeCard
            editable={!!data.name && canEditSettings}
            onEdit={() => onEdit(data.attributes.id)}
          >
            {data.name !== "" ? (
              <>
                <NodeContent content={data.name} />
                <NodeChildrenOptions nodeChildren={data.children} />
              </>
            ) : (
              canEditSettings && (
                <EmptyNode onEdit={() => onEdit(data.attributes.id)} />
              )
            )}
          </NodeCard>
        </foreignObject>
      </g>
    );
  };

// This renders the tree, with edit buttons all over it
export const DecisionTreeComponent = ({
  tree,
  onEdit,
}: {
  tree: DecisionTree;
  onEdit: (nodeId: number) => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  return (
    <Tree
      // @ts-expect-error It doesn't know that our attributes type is valid, but it definitely is.
      data={parseDecisionTree(tree)}
      orientation={"vertical"}
      collapsible={false}
      zoomable={true}
      pathFunc={"straight"}
      renderCustomNodeElement={renderNode(onEdit, canEditSettings)}
      nodeSize={{ x: 300, y: 180 }}
      // I came to these values by pure trial and error - trying to get something that sometimes renders the
      // root in a sensible place. It's basically impossible to know as it massively depends on the shape of
      // the tree.
      translate={{ x: 420, y: 80 }}
    />
  );
};
