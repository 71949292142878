/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallSession,
    CallSessionFromJSON,
    CallSessionFromJSONTyped,
    CallSessionToJSON,
} from './CallSession';
import {
    IncidentCall,
    IncidentCallFromJSON,
    IncidentCallFromJSONTyped,
    IncidentCallToJSON,
} from './IncidentCall';

/**
 * 
 * @export
 * @interface IncidentCallsGetForLatestForIncidentResponseBody
 */
export interface IncidentCallsGetForLatestForIncidentResponseBody {
    /**
     * 
     * @type {CallSession}
     * @memberof IncidentCallsGetForLatestForIncidentResponseBody
     */
    active_call_session?: CallSession;
    /**
     * 
     * @type {IncidentCall}
     * @memberof IncidentCallsGetForLatestForIncidentResponseBody
     */
    incident_call: IncidentCall;
}

export function IncidentCallsGetForLatestForIncidentResponseBodyFromJSON(json: any): IncidentCallsGetForLatestForIncidentResponseBody {
    return IncidentCallsGetForLatestForIncidentResponseBodyFromJSONTyped(json, false);
}

export function IncidentCallsGetForLatestForIncidentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallsGetForLatestForIncidentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active_call_session': !exists(json, 'active_call_session') ? undefined : CallSessionFromJSON(json['active_call_session']),
        'incident_call': IncidentCallFromJSON(json['incident_call']),
    };
}

export function IncidentCallsGetForLatestForIncidentResponseBodyToJSON(value?: IncidentCallsGetForLatestForIncidentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_call_session': CallSessionToJSON(value.active_call_session),
        'incident_call': IncidentCallToJSON(value.incident_call),
    };
}

