import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Heading,
  IconEnum,
  Link,
  Loader,
  LocalDateTime,
  TabPane,
  TabSection,
} from "@incident-ui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ScheduleReport, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { paramsAsObj, useQueryParams } from "../../../../utils/query-params";
import { dateStrToDisplayStr } from "../pay-configurations/date-time-helpers";
import { TimezoneHelperToolTip } from "../TimezoneHelperToolTip";
import { OverviewTab } from "./tabs/OverviewTab";
import { ScheduleTab } from "./tabs/ScheduleTab";
import { UserTab } from "./tabs/UserTab";
import { UnpublishModal } from "./UnpublishModal";

export const OnCallReportView = ({
  reportId,
}: {
  reportId: string;
}): React.ReactElement => {
  const {
    data,
    isLoading: loading,
    error: getError,
  } = useAPI("schedulesShowReport", { id: reportId });
  const report = data?.schedule_report;
  if (getError) {
    throw getError;
  }
  if (!report || loading) {
    return <Loader />;
  }

  if (!report.published_at) {
    return (
      <OrgAwareNavigate
        to={{
          pathname: "/on-call/pay-calculator/reports/create",
          search: `reportId=${reportId}`,
        }}
        replace
      />
    );
  }

  return <PublishedScheduleReportViewPage report={report} />;
};

export const PublishedScheduleReportViewPage = ({
  report,
}: {
  report: ScheduleReport;
}): React.ReactElement => {
  const [showUnpublishModal, setShowUnpublishModal] = useState<boolean>(false);
  const { hasScope, identity } = useIdentity();

  if (!report.name) {
    throw new Error("unreachable: published reports should always have a name");
  }
  return (
    <>
      {showUnpublishModal ? (
        <UnpublishModal
          report={report}
          onClose={() => {
            setShowUnpublishModal(false);
          }}
        />
      ) : null}
      <PageWrapper
        width={PageWidth.Wide}
        title={report.name}
        className="!bg-white"
        backHref="/on-call/pay-calculator"
        icon={IconEnum.Doc}
        accessory={
          <div className={`ml-auto space-x-2 flex-center-y`}>
            <p className="text-sm font-normal	text-content-tertiary mr-2">
              Published by {report.creator.name} at{" "}
              <LocalDateTime timestamp={report.created_at} />
            </p>
            {report.unpublished_at ? null : (
              <GatedButton
                theme={ButtonTheme.Tertiary}
                disabled={!hasScope(ScopeNameEnum.ScheduleReportsUnpublish)}
                disabledTooltipContent={
                  "Only admins may delete schedule reports"
                }
                onClick={() => {
                  setShowUnpublishModal(true);
                }}
                analyticsTrackingId="unpublish-report"
                icon={IconEnum.Delete}
              >
                Delete
              </GatedButton>
            )}
            <Button
              theme={ButtonTheme.Primary}
              onClick={() => {
                window.open(
                  `/api/schedule_reports/${report.id}/download?organisation_id=${identity?.organisation_id}`,
                );
              }}
              analyticsTrackingId="download-report"
              icon={IconEnum.Download}
            >
              Download
            </Button>
          </div>
        }
      >
        <div className="space-y-4">
          {report.unpublished_at ? (
            <UnpublishedCallout report={report} />
          ) : null}
          <ContentBox className={"p-4"}>
            <div className={"py-2 text-sm"}>
              <p>
                This report includes shifts between{" "}
                {dateStrToDisplayStr(report.start_date)} and{" "}
                {dateStrToDisplayStr(report.end_date)} for{" "}
                {report.schedules.length} schedules and {report.users.length}{" "}
                user, using the timezone set on each respective schedule.{" "}
                <TimezoneHelperToolTip />
              </p>
            </div>
          </ContentBox>
          <ContentBox className={"p-8 pt-4"}>
            <OnCallReport report={report} />
          </ContentBox>
        </div>
      </PageWrapper>
    </>
  );
};

export const UnpublishedCallout = ({
  report,
}: {
  report: ScheduleReport;
}): React.ReactElement => {
  if (
    !report.unpublisher ||
    !report.unpublished_at ||
    !report.unpublish_reason
  ) {
    throw new Error("unreachable: report is incompletely unpublished");
  }

  return (
    <Callout className={"w-full"} theme={CalloutTheme.Warning}>
      <Heading level={2}>This report has been unpublished.</Heading>
      <div className={"text-sm"}>
        <p>
          This report was unpublished by {report.unpublisher.name} at{" "}
          <LocalDateTime timestamp={report.unpublished_at} /> with the following
          reason:
          <blockquote className={"italic"}>
            &quot;{report.unpublish_reason}&quot;
          </blockquote>
        </p>
        <p>
          Now that it has been unpublished, this report won&apos;t appear in the{" "}
          <Link
            href="/on-call/pay-calculator/reports"
            openInNewTab={true}
            analyticsTrackingId={null}
          >
            reports page
          </Link>
          , but is still accessible by URL.
        </p>
      </div>
    </Callout>
  );
};

export const OnCallReport = ({
  report,
}: {
  report: ScheduleReport;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const query = useQueryParams();

  // Sort schedules by name, as this helps present reports with many schedules
  // in a more comprehensible format.
  //
  // An example would be if you have shadow schedules, so:
  // - Core-Infrastructure
  // - Core-Infrastructure shadow
  //
  // Instead of random order, you want the shadow to appear just below the
  // primary.
  report.schedules = _.sortBy(report.schedules, (schedule) => schedule.name);

  // Sort for users too, so the dropdown appears in alphabetical order.
  report.users = _.sortBy(report.users, (user) => user.name);

  const params = useQueryParams();

  const defaultTabID = query.get("tab") || "overview";
  useEffect(() => {
    if (!_.includes(["overview", "schedule", "user"], defaultTabID)) {
      navigate(
        {
          search: new URLSearchParams({
            ...paramsAsObj(params),
            tab: "overview",
          }).toString(),
        },
        { replace: true },
      );
    }
  }, [navigate, query, defaultTabID, params]);

  const [tabID, setTabID] = useState<string>(defaultTabID);

  const changeTab = (
    tabID: string,
    additionalParams: Record<string, string> = {},
  ) => {
    navigate(
      {
        search: new URLSearchParams({
          ...paramsAsObj(params),
          ...additionalParams,
          tab: tabID,
        }).toString(),
      },
      { replace: true },
    );
    setTabID(tabID);
  };

  return (
    <>
      <TabSection
        value={tabID}
        withIndicator
        tabBarClassName="border-b border-stroke pb-2"
        onTabChange={(tabID) => {
          changeTab(tabID);
        }}
        tabs={[
          {
            id: "overview",
            label: "Overview",
          },
          {
            id: "schedule",
            label: "Schedule",
          },
          {
            id: "user",
            label: "User",
          },
        ]}
      >
        {[
          <TabPane key={"overview"} tabId={"overview"}>
            <OverviewTab report={report} changeTab={changeTab} />
          </TabPane>,
          <TabPane key={"schedule"} tabId={"schedule"}>
            <ScheduleTab report={report} changeTab={changeTab} />
          </TabPane>,
          <TabPane key={"user"} tabId={"user"}>
            <UserTab report={report} changeTab={changeTab} />
          </TabPane>,
        ]}
      </TabSection>
    </>
  );
};
