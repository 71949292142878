/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackCanvasPreviewTemplate,
    SlackCanvasPreviewTemplateFromJSON,
    SlackCanvasPreviewTemplateFromJSONTyped,
    SlackCanvasPreviewTemplateToJSON,
} from './SlackCanvasPreviewTemplate';

/**
 * 
 * @export
 * @interface SlackCanvasPreviewsShowResponseBody
 */
export interface SlackCanvasPreviewsShowResponseBody {
    /**
     * 
     * @type {SlackCanvasPreviewTemplate}
     * @memberof SlackCanvasPreviewsShowResponseBody
     */
    template?: SlackCanvasPreviewTemplate;
}

export function SlackCanvasPreviewsShowResponseBodyFromJSON(json: any): SlackCanvasPreviewsShowResponseBody {
    return SlackCanvasPreviewsShowResponseBodyFromJSONTyped(json, false);
}

export function SlackCanvasPreviewsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackCanvasPreviewsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template': !exists(json, 'template') ? undefined : SlackCanvasPreviewTemplateFromJSON(json['template']),
    };
}

export function SlackCanvasPreviewsShowResponseBodyToJSON(value?: SlackCanvasPreviewsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template': SlackCanvasPreviewTemplateToJSON(value.template),
    };
}

