import {
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  EmptyState,
  Heading,
  IconEnum,
  StaticSingleSelect,
} from "@incident-ui";
import {
  SortableColumn,
  SortableTable,
} from "@incident-ui/Table/SortableTable";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  ScheduleReport,
  ScheduleReportSchedule,
  ScheduleReportShift,
  ScheduleReportUser,
} from "src/contexts/ClientContext";

import { formatCurrency } from "../../../../../utils/currency";
import {
  formatDurationFromHours,
  formatDurationShort,
} from "../../../../../utils/datetime";
import { useQueryParams } from "../../../../../utils/query-params";
import {
  displayDateObj,
  fromLocalDateObj,
} from "../../pay-configurations/date-time-helpers";
import { buildAggregate, getRate } from "../helpers";
import { PayRuleBadge } from "../PayRule";
import { RuleAccordion, ScheduleLink } from "./ScheduleTab";

// UserLink allows linking to a user within the report, changing the tab to the
// appropriate location.
export const UserLink = ({
  user,
  changeTab,
}: {
  user: ScheduleReportUser;
  changeTab: (tabID: string, params: Record<string, string>) => void;
}): React.ReactElement => {
  return (
    <a
      className={
        "link underline text-content-primary hover:text-alarmalade-600 cursor-pointer"
      }
      onClick={() => {
        changeTab("user", {
          user: user.external_id,
        });
      }}
    >
      {user.name}
    </a>
  );
};

export const UserTab = ({
  report,
  changeTab,
}: {
  report: ScheduleReport;
  changeTab: (tabID: string, params: Record<string, string>) => void;
}): React.ReactElement => {
  if (report.users.length === 0) {
    return (
      <EmptyState
        icon={IconEnum.User}
        content="There are no users included in this report"
        className="mt-2"
      />
    );
  }

  return <UserTabContent report={report} changeTab={changeTab} />;
};

const UserTabContent = ({
  report,
  changeTab,
}: {
  report: ScheduleReport;
  changeTab: (tabID: string, params: Record<string, string>) => void;
}): React.ReactElement => {
  const query = useQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userIDs = report.users.map((user) => user.external_id);

  const [userID, setUserID] = useState<string>(
    query.get("user") || report.users[0].external_id || "invalid",
  );

  useEffect(() => {
    if (query.get("user") === userID) {
      return;
    }

    if (!_.includes(userIDs, userID)) {
      const bestGuess = userIDs[0];

      setUserID(bestGuess);
      changeTab("user", { user: bestGuess });
    } else {
      changeTab("user", { user: userID });
    }
  }, [userID, userIDs, changeTab, query]);

  const user = report.users.find((user) => user.external_id === userID);
  if (!user) {
    throw new Error(`user with id ${userID} could not be found`);
  }

  return (
    <div className={"space-y-6 pt-2"}>
      {/* Selector */}
      <div className="max-w-md">
        <StaticSingleSelect
          onChange={(value) => {
            if (value && typeof value === "string") {
              setUserID(value);
            }
          }}
          value={userID}
          isClearable={false}
          options={report.users.map((user) => {
            return {
              label: user.name,
              value: user.external_id,
              image_url: user.avatar_url,
            };
          })}
          placeholder="Choose user"
        />
      </div>
      <UserOverviewTable report={report} user={user} changeTab={changeTab} />
      <UserShiftTable report={report} user={user} />
    </div>
  );
};

const UserOverviewTable = ({
  report,
  user,
  changeTab,
}: {
  report: ScheduleReport;
  user: ScheduleReportUser;
  changeTab: (tabID: string, params: Record<string, string>) => void;
}): React.ReactElement => {
  // TODO: actually make this aggregate by schedule, not by schedule and base rule!!
  const unsortedShiftTotals = buildAggregate(
    report,
    report.schedules,
  ).totalsByUserBySchedule.filter(
    (row) => row.user.external_id === user.external_id,
  );

  const shiftTotals = _.sortBy(unsortedShiftTotals, (shift) => {
    return [shift.schedule.id];
  });

  const currencies = _.uniq(
    shiftTotals.map((shift) => shift.schedule.currency),
  );

  return (
    <div>
      <div className={"pb-2"}>
        <Heading level={3} className="mb-2" size="small">
          Pay by schedule
        </Heading>
      </div>
      <DeprecatedTable>
        <DeprecatedTableHeaderRow>
          <DeprecatedTableHeaderCell>Schedule</DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell className={"text-right"}>
            Duration
          </DeprecatedTableHeaderCell>
          {currencies.map((currency: string) => {
            return (
              <DeprecatedTableHeaderCell
                key={currency}
                className={"text-right"}
              >
                Total ({currency})
              </DeprecatedTableHeaderCell>
            );
          })}
        </DeprecatedTableHeaderRow>
        <tbody>
          {shiftTotals.map((shift, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <ScheduleLink
                    schedule={shift.schedule}
                    changeTab={changeTab}
                  />
                </td>
                <td className={"text-right"}>
                  {formatDurationFromHours(shift.hours)}
                </td>
                {currencies.map((currency: string) => {
                  return (
                    <td key={currency} className={"text-right"}>
                      {currency === shift.schedule.currency
                        ? formatCurrency(currency, shift.totals[currency])
                        : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            {currencies.map((currency: string, idx: number) => {
              return (
                <td
                  key={currency}
                  colSpan={idx === 0 ? 3 : undefined}
                  className="text-right text-sm font-semibold text-content-primary"
                >
                  {formatCurrency(
                    currency,
                    _.sum(shiftTotals.map((shift) => shift.totals[currency])),
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      </DeprecatedTable>
    </div>
  );
};

const UserShiftTable = ({
  report,
  user,
}: {
  report: ScheduleReport;
  user: ScheduleReportUser;
}): React.ReactElement => {
  return (
    <>
      <div>
        <div>
          <div>
            <Heading level={3} className="mb-2" size="small">
              Pay by shift
            </Heading>
          </div>
        </div>
        <div className="space-y-4">
          {report.schedules.map((sched) => {
            const userShifts = sched.shifts.filter(
              (shift) => shift.external_user_id === user.external_id,
            );
            if (userShifts.length === 0) {
              return null;
            }
            const columns: SortableColumn<ScheduleReportShift>[] = [
              {
                title: "Time range",
                key: "time_range",
                dataIndex: "time_range",
                sortFn: (shiftA, shiftB) => {
                  return shiftA.start_at < shiftB.start_at ? 1 : -1;
                },
              },
              {
                title: "Duration",
                key: "duration",
                dataIndex: "duration",
                sortFn: (shiftA, shiftB) => {
                  const durationA =
                    shiftA.end_at.getTime() - shiftA.start_at.getTime();
                  const durationB =
                    shiftB.end_at.getTime() - shiftB.start_at.getTime();
                  return durationA < durationB ? 1 : -1;
                },
              },
              {
                title: "Pay Rule",
                key: "pay_rule",
                dataIndex: "pay_rule",
                sortFn: (shiftA, shiftB) => {
                  const rateA = getShiftRate(sched, shiftA);
                  const rateB = getShiftRate(sched, shiftB);
                  return rateA.short_id < rateB.short_id ? 1 : -1;
                },
              },
              {
                title: `Rate (${sched.currency}/hour)`,
                key: `rate_${sched.currency}`,
                dataIndex: `rate_${sched.currency}`,
                alignRight: true,
                sortFn: (shiftA, shiftB) => {
                  const rateA = getShiftRate(sched, shiftA);
                  const rateB = getShiftRate(sched, shiftB);
                  return rateA.rate_cents < rateB.rate_cents ? 1 : -1;
                },
              },
              {
                title: `Total (${sched.currency})`,
                key: "total",
                dataIndex: "total",
                alignRight: true,
                sortFn: (shiftA, shiftB) => {
                  return shiftA.value_cents < shiftB.value_cents ? 1 : -1;
                },
              },
            ];

            return (
              <div key={sched.id} className="space-y-4">
                <Heading
                  level={3}
                  size="small"
                  className="text-slate-600 !font-medium pt-2"
                >
                  {sched.name}
                </Heading>
                <RuleAccordion schedule={sched} />
                <SortableTable
                  data={userShifts}
                  columns={columns}
                  render={(shift) => {
                    const rate = getShiftRate(sched, shift);

                    return (
                      <tr key={shift.start_at.getTime()}>
                        <td>{getShiftTimes(shift.start_at, shift.end_at)}</td>
                        <td>
                          {formatDurationShort(shift.start_at, shift.end_at)}
                        </td>
                        <td>
                          <PayRuleBadge shortID={rate.short_id} />{" "}
                        </td>
                        <td className={"text-right"}>
                          {formatCurrency(sched.currency, rate.rate_cents)}
                        </td>
                        <td className={"text-right"}>
                          {formatCurrency(sched.currency, shift.value_cents)}
                        </td>
                      </tr>
                    );
                  }}
                  finalRows={
                    <tr>
                      {
                        <td
                          colSpan={5}
                          className="text-right text-sm font-semibold text-content-primary"
                        >
                          {formatCurrency(
                            sched.currency,
                            _.sum(userShifts.map((shift) => shift.value_cents)),
                          )}
                        </td>
                      }
                    </tr>
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const getShiftRate = (
  sched: ScheduleReportSchedule,
  shift: ScheduleReportShift,
) => {
  const payRuleId = shift.pay_rule_short_id || shift.override_id || "unknown";
  return getRate(sched, payRuleId);
};

const getShiftTimes = (start: Date, end: Date): React.ReactNode => {
  const startObj = fromLocalDateObj(start, false);
  const endObj = fromLocalDateObj(end, true);

  return (
    <span>
      {displayDateObj(startObj, false)}{" "}
      <span className="text-slate-600">{startObj.time}</span> -{" "}
      {displayDateObj(endObj, false)}{" "}
      <span className="text-slate-600">{endObj.time}</span>
    </span>
  );
};
