/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsSplunkOnCallGetBotUsernameResponseBody,
    IntegrationsSplunkOnCallGetBotUsernameResponseBodyFromJSON,
    IntegrationsSplunkOnCallGetBotUsernameResponseBodyToJSON,
    IntegrationsSplunkOnCallSetBotUsernameRequestBody,
    IntegrationsSplunkOnCallSetBotUsernameRequestBodyFromJSON,
    IntegrationsSplunkOnCallSetBotUsernameRequestBodyToJSON,
    IntegrationsSplunkOnCallSetTokenRequestBody,
    IntegrationsSplunkOnCallSetTokenRequestBodyFromJSON,
    IntegrationsSplunkOnCallSetTokenRequestBodyToJSON,
} from '../models';

export interface IntegrationsSplunkOnCallSetBotUsernameRequest {
    setBotUsernameRequestBody: IntegrationsSplunkOnCallSetBotUsernameRequestBody;
}

export interface IntegrationsSplunkOnCallSetTokenRequest {
    setTokenRequestBody: IntegrationsSplunkOnCallSetTokenRequestBody;
}

/**
 * 
 */
export class IntegrationsSplunkOnCallApi extends runtime.BaseAPI {

    /**
     * Remove Splunk on-call integration
     * DestroyToken Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallDestroyTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/splunk_on_call`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Splunk on-call integration
     * DestroyToken Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallDestroyToken(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsSplunkOnCallDestroyTokenRaw(initOverrides);
    }

    /**
     * Get the Splunk on-call bot username
     * GetBotUsername Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallGetBotUsernameRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsSplunkOnCallGetBotUsernameResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/splunk_on_call/bot_username`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsSplunkOnCallGetBotUsernameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the Splunk on-call bot username
     * GetBotUsername Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallGetBotUsername(initOverrides?: RequestInit): Promise<IntegrationsSplunkOnCallGetBotUsernameResponseBody> {
        const response = await this.integrationsSplunkOnCallGetBotUsernameRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the Splunk on-call bot username
     * SetBotUsername Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallSetBotUsernameRaw(requestParameters: IntegrationsSplunkOnCallSetBotUsernameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setBotUsernameRequestBody === null || requestParameters.setBotUsernameRequestBody === undefined) {
            throw new runtime.RequiredError('setBotUsernameRequestBody','Required parameter requestParameters.setBotUsernameRequestBody was null or undefined when calling integrationsSplunkOnCallSetBotUsername.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/splunk_on_call/bot_username`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsSplunkOnCallSetBotUsernameRequestBodyToJSON(requestParameters.setBotUsernameRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the Splunk on-call bot username
     * SetBotUsername Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallSetBotUsername(requestParameters: IntegrationsSplunkOnCallSetBotUsernameRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsSplunkOnCallSetBotUsernameRaw(requestParameters, initOverrides);
    }

    /**
     * Set Splunk on-call API token
     * SetToken Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallSetTokenRaw(requestParameters: IntegrationsSplunkOnCallSetTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setTokenRequestBody === null || requestParameters.setTokenRequestBody === undefined) {
            throw new runtime.RequiredError('setTokenRequestBody','Required parameter requestParameters.setTokenRequestBody was null or undefined when calling integrationsSplunkOnCallSetToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/splunk_on_call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsSplunkOnCallSetTokenRequestBodyToJSON(requestParameters.setTokenRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Splunk on-call API token
     * SetToken Integrations - Splunk on-call
     */
    async integrationsSplunkOnCallSetToken(requestParameters: IntegrationsSplunkOnCallSetTokenRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsSplunkOnCallSetTokenRaw(requestParameters, initOverrides);
    }

}
