import { ExploDashboardID, PanelID } from "../common/types";

export enum TrendID {
  IncidentCreationBySeverity = "incident_creation_by_severity",
  TimeSpentOnIncidentsByTimeOfDay = "time_spent_on_incidents_by_time_of_day",
  OnCallPagerLoadByUser = "on_call_pager_load_by_user",
  OnCallPagerLoadByTimeOfDay = "on_call_pager_load_by_time_of_day",
  FollowUpsCreated = "follow_ups_created",
}

export const TREND_TO_PRIMARY_PANEL: {
  [key in TrendID]: PanelID;
} = {
  [TrendID.IncidentCreationBySeverity]:
    ExploDashboardID.OverviewIncidentCreation,
  [TrendID.TimeSpentOnIncidentsByTimeOfDay]:
    ExploDashboardID.ResponseTimeSpentInIncidents,
  [TrendID.OnCallPagerLoadByUser]: ExploDashboardID.OnCallPagerImpactNative,
  [TrendID.OnCallPagerLoadByTimeOfDay]:
    ExploDashboardID.OnCallPagerImpactNative,
  [TrendID.FollowUpsCreated]: ExploDashboardID.PincFollowUpsCreated,
};

export const PANEL_TO_DASHBOARD_V3_PAGE = {
  [ExploDashboardID.OverviewIncidentCreation]: "dashboards/core/at_a_glance",
  [ExploDashboardID.ResponseTimeSpentInIncidents]:
    "dashboards/core/time_spent_on_incidents",
  [ExploDashboardID.OnCallPagerImpactNative]:
    "dashboards/core/pager_load_native",
  [ExploDashboardID.PincFollowUpsCreated]: "dashboards/core/follow_ups",
};
