import React from "react";
import { Route, Routes } from "react-router";

import { LifecyclePageWrapper } from "./LifecyclePageWrapper";
import { LifecycleOverviewPage } from "./overview/LifecycleOverviewPage";

export const SettingsLifecycleRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<LifecyclePageWrapper />} />
      <Route path=":id" element={<LifecycleOverviewPage />} />
    </Routes>
  );
};
