import { StatusPageIncidentUpdate } from "@incident-io/api";
import { ButtonTheme, Modal, ModalContent, ModalFooter } from "@incident-ui";
import { useAPIMutation } from "src/utils/swr";

export const StatusPageIncidentUpdateDeleteModal = ({
  update,
  onClose,
}: {
  update: StatusPageIncidentUpdate;
  onClose: () => void;
}): React.ReactElement => {
  const { trigger: doDelete, isMutating: saving } = useAPIMutation(
    "statusPageShowIncident",
    {
      id: update.status_page_incident_id,
    },
    async (apiClient) => {
      await apiClient.statusPageDeleteIncidentUpdate({ id: update.id });
      // Revalidate to remove this update from the list
      return;
    },
    { onSuccess: onClose },
  );

  return (
    <Modal
      isOpen
      analyticsTrackingId="status-page-delete-incident-update"
      title="Are you sure?"
      disableQuickClose={false}
      onClose={onClose}
    >
      <ModalContent className="space-y-2 text-sm">
        <p>Deleting this update will remove it from your public status page.</p>
        <p>
          We recommend checking the timeline of affected component statuses to
          ensure it is accurate. If you marked components as affected in this
          update, the status timeline will not be re-calculated when you delete
          this update.
        </p>
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        confirmButtonType="button"
        onConfirm={() => doDelete({})}
        confirmButtonTheme={ButtonTheme.Destroy}
        confirmButtonText="Delete update"
        saving={saving}
      />
    </Modal>
  );
};
