import {
  Backlink,
  useUserBacklinks,
} from "@incident-shared/catalog/useUserBacklinks";
import {
  ExtendedFormFieldValue,
  filtersToListParams,
  useFiltersContext,
} from "@incident-shared/filters";
import {
  isValidForEnum,
  useStatefulQueryParamFilters,
} from "@incident-shared/filters/useStatefulQueryParamFilters";
import { useSyntheticCatalogFilters } from "@incident-shared/filters/useSyntheticCatalogFilters";
import { GetInstalledIssueTrackers } from "@incident-shared/issue-trackers";
import { DeprecatedTable, Heading, IconEnum, Txt } from "@incident-ui";
import { PopoverSingleSelect } from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import React from "react";
import {
  CustomField,
  CustomFieldFieldTypeEnum,
  FollowUpsStatisticsSplitTypeEnum,
  FollowUpStatisticsRow,
  Identity,
  PolicyPolicyTypeEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useSettings } from "src/hooks/useSettings";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./FollowUps.module.scss";
import { FollowUpParam, FollowUpParams } from "./FollowUpsPage";

const useSplitByOptions = (): {
  splitByOptions: SplitByOption[];
  isLoading: boolean;
} => {
  const { settings } = useSettings();
  const { identity } = useIdentity();

  // We can use custom fields to split the followups when calculating statistics.
  const {
    data: { custom_fields },
    isLoading: cflLoading,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  // We can use a catalog backlink to split the followups too
  const { data: backlinks, isLoading: blLoading } = useUserBacklinks();

  if (blLoading || cflLoading || !settings || !identity) {
    return { splitByOptions: [], isLoading: true };
  }

  return {
    splitByOptions: BuildSplitByOptions(
      custom_fields,
      backlinks,
      settings,
      identity,
    ),
    isLoading: false,
  };
};

type FollowUpStatisticsDeps = {
  statistics: FollowUpStatisticsRow[] | undefined;
  totalFollowupsCount: number;
  statisticsLoading: boolean;
  splitByOptions: SplitByOption[];
  splitByOptionsLoading: boolean;
  selectedSplitByOptionId: string;
  setSelectedSplitByOptionId: (value: string) => void;
};

const useFetchFollowupStatistics = ({
  staticConfigLoading,
  filters,
  selectedSplitByOption,
}: {
  staticConfigLoading: boolean;
  filters: ExtendedFormFieldValue[];
  selectedSplitByOption: SplitByOption | undefined;
}) => {
  const { data, isLoading } = useAPI(
    staticConfigLoading && selectedSplitByOption ? null : "followUpsStatistics",
    {
      splitType:
        selectedSplitByOption?.type ??
        FollowUpsStatisticsSplitTypeEnum.Severity,
      splitValue: selectedSplitByOption?.value,
      ...filtersToListParams(filters),
    },
    {
      fallbackData: { statistics: [], total_follow_up_count: 0 },
    },
  );

  return {
    statistics: data.statistics,
    statisticsLoading: isLoading,
    totalFollowupsCount: data.total_follow_up_count,
  };
};

export const useFollowupStatistics = (): FollowUpStatisticsDeps => {
  const { splitByOptions, isLoading: splitByOptionsLoading } =
    useSplitByOptions();

  const { availableFilterFields } = useFiltersContext();

  const { getSelectedFilters, useQueryParam } =
    useStatefulQueryParamFilters<FollowUpParams>({
      availableFilterFields,
      availableParams: Object.values(FollowUpParam),
    });
  const filters = getSelectedFilters();
  const { data: apiFilters, isLoading } = useSyntheticCatalogFilters(filters);

  const [selectedSplitByOptionId, setSelectedSplitByOptionId] = useQueryParam({
    param: FollowUpParam.SplitBy,
    isValid: isValidForEnum(splitByOptions.map((o) => o.value)),
    defaultValue: (
      splitByOptions.find(
        (o) => o.value === FollowUpsStatisticsSplitTypeEnum.Severity,
      ) ?? splitByOptions[0]
    )?.value,
  });

  const selectedSplitByOption = splitByOptions.find(
    (option) => option.value === selectedSplitByOptionId,
  );

  const { statistics, totalFollowupsCount, statisticsLoading } =
    useFetchFollowupStatistics({
      staticConfigLoading: splitByOptionsLoading || isLoading,
      filters: apiFilters,
      selectedSplitByOption,
    });

  return {
    statistics,
    totalFollowupsCount,
    statisticsLoading,
    splitByOptions,
    splitByOptionsLoading: splitByOptionsLoading || isLoading,
    selectedSplitByOptionId,
    setSelectedSplitByOptionId,
  };
};

export const FollowUpsStatisticsTable = ({
  statistics,
  statisticsLoading,
  splitByOptions,
  splitByOptionsLoading,
  selectedSplitByOptionId,
  setSelectedSplitByOptionId,
}: FollowUpStatisticsDeps): React.ReactElement => {
  const {
    data: { policies },
    isLoading: policiesLoading,
  } = useAPI("policiesList", undefined, { fallbackData: { policies: [] } });

  const { integrations } = useIntegrations();

  const installedIssueTrackers = GetInstalledIssueTrackers(integrations || []);

  const numFollowUpPolicies =
    policies?.filter(
      (policy) => policy.policy_type === PolicyPolicyTypeEnum.FollowUp,
    ).length || 0;

  const loading = statisticsLoading || splitByOptionsLoading || policiesLoading;

  const hasInstalledIssueTracker = installedIssueTrackers.length > 0;

  return (
    <>
      <div className="flex flex-col md:flex-row md:flex-between w-full">
        <Heading level={2} size="medium">
          At a glance
        </Heading>
        <div className={tcx("flex items-center", styles.statisticsDropdown)}>
          <Txt inline grey className="mr-2">
            Split by
          </Txt>
          <div className="flex-grow">
            <PopoverSingleSelect
              isSearchable
              align="end"
              placeholder="Split by"
              options={splitByOptions.map(({ label, value, icon }) => ({
                sort_key: label,
                value: value,
                label: label,
                icon,
              }))}
              value={selectedSplitByOptionId}
              onChange={(value) =>
                setSelectedSplitByOptionId(value ? value : "")
              }
              isClearable={false}
              triggerClassName={"min-w-[175px] max-w-[300px]"}
              noOptionsMessage={
                <div className="flex flex-col gap-2">
                  <div className="text-content-primary">
                    Can&apos;t find the field you&apos;re looking for?
                  </div>
                  <p>
                    Only single-select custom fields are available for
                    splitting.
                  </p>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <LoadingWrapper loading={loading}>
        <DeprecatedTable
          className={tcx("mt-4 mb-8", styles.followUpStatistics)}
          roundedTop
        >
          <thead>
            <tr className="text-left align-top">
              <th className="w-1/5 font-normal text-content-tertiary">
                {
                  splitByOptions.find(
                    (option) => option.value === selectedSplitByOptionId,
                  )?.label
                }
              </th>
              <th className="w-1/5 font-normal text-content-tertiary">
                Open follow-ups
              </th>
              <th className="w-1/5 font-normal text-content-tertiary">
                Completed follow-ups
              </th>
              {numFollowUpPolicies > 0 && (
                <th className="w-1/5 font-normal text-content-tertiary">
                  Follow-ups with policy violations
                </th>
              )}
              {hasInstalledIssueTracker && (
                <th className="w-1/5 font-normal text-content-tertiary">
                  Exported follow-ups
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {statistics?.map((row) => (
              <StatisticsRow
                key={row.split_label}
                row={row}
                hasInstalledIssueTracker={hasInstalledIssueTracker}
                numFollowUpPolicies={numFollowUpPolicies}
              />
            ))}
          </tbody>
        </DeprecatedTable>
      </LoadingWrapper>
    </>
  );
};

const StatisticsRow = ({
  row,
  hasInstalledIssueTracker,
  numFollowUpPolicies,
}: {
  row: FollowUpStatisticsRow;
  hasInstalledIssueTracker: boolean;
  numFollowUpPolicies: number;
}): React.ReactElement => {
  return (
    <tr>
      {/* For some strange reason we see a tiny border at the bottom of the first column, so must remove it with border-none */}
      <th className="py-3 px-2 pr-4 text-left border-none">
        <Txt>{row.split_label}</Txt>
      </th>
      <td className="py-3 px-2 pr-4 text-left">
        <Txt>
          {row.open_follow_ups}{" "}
          <span className="text-content-tertiary hidden md:inline">
            {numIncidentsString(
              row.open_follow_ups,
              row.incidents_with_open_follow_ups,
            )}
          </span>
        </Txt>
      </td>
      <td>
        <FormattedPercentage
          numerator={row.completed_follow_ups}
          total_follow_ups={row.total_follow_ups}
        />
      </td>
      {numFollowUpPolicies > 0 && (
        <td className="py-3 px-2 pr-4 text-left ">
          <Txt>
            {row.follow_ups_with_policy_violations > 0 ? (
              <>
                <span className="text-alarmalade-600">
                  {row.follow_ups_with_policy_violations}{" "}
                </span>
                <span className="hidden md:inline text-content-tertiary">
                  {numIncidentsString(
                    row.follow_ups_with_policy_violations,
                    row.incidents_with_follow_ups_with_policy_violations,
                  )}
                </span>
              </>
            ) : (
              row.follow_ups_with_policy_violations
            )}
          </Txt>
        </td>
      )}
      {hasInstalledIssueTracker && (
        <td>
          <FormattedPercentage
            numerator={row.exported_follow_ups}
            total_follow_ups={row.total_follow_ups}
          />
        </td>
      )}
    </tr>
  );
};

const FormattedPercentage = ({
  numerator,
  total_follow_ups,
}: {
  numerator: number;
  total_follow_ups: number;
}): React.ReactElement => {
  if (total_follow_ups === 0) {
    return <>-</>;
  } else {
    return <>{calculatePercentage(numerator, total_follow_ups)}%</>;
  }
};

const calculatePercentage = (numerator: number, denominator: number) => {
  if (denominator === 0) {
    return 0;
  } else {
    return Math.round((numerator / denominator) * 100);
  }
};

const numIncidentsString = (
  openFollowUps: number,
  incidentsWithOpenFollowUps: number,
) => {
  if (openFollowUps === 0) {
    return "";
  } else if (incidentsWithOpenFollowUps === 1) {
    return "(across 1 incident)";
  } else {
    return `(across ${incidentsWithOpenFollowUps} incidents)`;
  }
};

export type SplitByOption = {
  label: string;
  type: FollowUpsStatisticsSplitTypeEnum;
  value: string;
  icon?: IconEnum;
};

// Build options for the drop down. We can split by severity, status, incident type or any single
// select custom field.
export const BuildSplitByOptions = (
  customFields: CustomField[] | null,
  backlinks: Backlink[] | undefined,
  settings: Settings,
  identity: Identity,
): SplitByOption[] => {
  const options: SplitByOption[] = [
    {
      label: "Severity",
      type: FollowUpsStatisticsSplitTypeEnum.Severity,
      value: FollowUpsStatisticsSplitTypeEnum.Severity,
      icon: IconEnum.Severity,
    },
  ];

  if (incidentTypesEnabled(settings)) {
    options.push({
      label: "Incident Type",
      type: FollowUpsStatisticsSplitTypeEnum.IncidentType,
      value: FollowUpsStatisticsSplitTypeEnum.IncidentType,
      icon: IconEnum.IncidentType,
    });
  }

  if (identity.feature_gates.follow_up_priorities) {
    options.push({
      label: "Follow-up priority",
      type: FollowUpsStatisticsSplitTypeEnum.FollowUpPriority,
      value: FollowUpsStatisticsSplitTypeEnum.FollowUpPriority,
      icon: IconEnum.Warning,
    });
  }

  for (const backlink of backlinks ?? []) {
    if (backlink.array === false) {
      options.push({
        label: `Follow-up owner → ${backlink.backlink_name}`,
        type: FollowUpsStatisticsSplitTypeEnum.AssigneeCatalog,
        value: backlink.id,
        icon: IconEnum.Users,
      });
    }
  }

  if (customFields) {
    customFields.forEach((customField) => {
      if (customField.field_type === CustomFieldFieldTypeEnum.SingleSelect) {
        options.push({
          label: customField.name,
          type: FollowUpsStatisticsSplitTypeEnum.CustomField,
          value: customField.id,
          icon: IconEnum.CustomField,
        });
      }
    });
  }

  return options;
};
