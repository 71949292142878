import { EngineScope, Resource } from "@incident-io/api";
import { getVariableScope } from "@incident-shared/engine";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import { SortableList } from "@incident-ui/SortableList/SortableList";
import React from "react";
import { useFormContext } from "react-hook-form";

import {
  listItemsToSteps,
  listItemToStepGroup,
  stepGroupToListItem,
} from "../common/marshall";
import {
  WorkflowStep,
  WorkflowStepGroup,
  WorkflowStepListItem,
} from "../common/types";
import { WorkflowFormData } from "../common/types";
import { WorkflowLoopingStepCard } from "./WorkflowLoopingStepCard";
import { WorkflowStepCard } from "./WorkflowStepCard";

export type WorkflowStepsSectionProps = {
  scope: EngineScope;
  resources: Resource[];
  onClickAddStep: (groupIndex: number, stepIndex: number | null) => void;
  onEditStep: (groupIndex: number, stepIndex: number) => void;
  onRemoveStep: (groupIndex: number, stepIndex: number) => void;
  onRemoveStepGroup: (groupIndex: number) => void;
  onReorderGroupSteps: (groups: WorkflowStepGroup[]) => void;
  onReorderStepsWithinGroup: (
    groupIndex: number,
    steps: WorkflowStep[],
  ) => void;
  onSetForEach: (groupIndex: number, forEach: string) => void;
};

export const WorkflowStepsSection = (
  props: WorkflowStepsSectionProps,
): React.ReactElement | null => {
  const {
    scope,
    resources,
    onClickAddStep,
    onEditStep,
    onRemoveStep,
    onRemoveStepGroup,
    onReorderGroupSteps,
    onReorderStepsWithinGroup,
    onSetForEach,
  } = props;

  const { watch } = useFormContext<WorkflowFormData>();
  const stepGroupFields = watch("step_groups");

  const stepGroupsAsListItems = stepGroupFields.map(stepGroupToListItem);

  const variables = getVariableScope(scope, resources);

  return (
    <>
      <SortableList
        items={stepGroupsAsListItems}
        containerTag="div"
        containerProps={{
          className: "flex flex-col items-center w-full",
        }}
        sortOrder="asc"
        updateItemRanks={(listItems) => {
          // At this level, any reordering should affect the WorkflowStepGroups
          onReorderGroupSteps(listItems.map(listItemToStepGroup));
        }}
        droppableID="groupsteps"
        renderItem={(renderItemProps) => {
          const {
            item: groupStep,
            index: groupStepIndex,
            draggableProvidedProps,
          } = renderItemProps;

          // Take common draggable props, which are needed to make
          // the child components draggable.
          const commonDraggableProps = {
            innerRef: draggableProvidedProps.innerRef,
            draggableProps: draggableProvidedProps.draggableProps,
            dragHandleProps: draggableProvidedProps.dragHandleProps,
          };

          return (
            <React.Fragment key={groupStepIndex}>
              {groupStepIndex > 0 && <FlowCards.Divider compact />}
              {groupStep.isLoop ? (
                <WorkflowLoopingStepCard
                  {...commonDraggableProps}
                  key={groupStepIndex}
                  onReorderStepsWithinGroup={(
                    listItems: WorkflowStepListItem[],
                  ) => {
                    onReorderStepsWithinGroup(
                      groupStepIndex,
                      listItemsToSteps(listItems),
                    );
                  }}
                  forEachValue={groupStep.forEach}
                  steps={groupStep.steps as WorkflowStepListItem[]}
                  scope={scope}
                  resources={resources}
                  onSetForEach={(forEach: string) => {
                    onSetForEach(groupStepIndex, forEach);
                  }}
                  onClickAddStep={() => {
                    onClickAddStep(groupStepIndex, null);
                  }}
                  onEditStep={(stepIndex: number) => {
                    onEditStep(groupStepIndex, stepIndex);
                  }}
                  onRemoveStep={(stepIndex: number) => {
                    onRemoveStep(groupStepIndex, stepIndex);
                  }}
                  onRemoveStepGroup={() => {
                    onRemoveStepGroup(groupStepIndex);
                  }}
                />
              ) : (
                <WorkflowStepCard
                  {...commonDraggableProps}
                  key={groupStepIndex}
                  step={groupStep.steps[0] as WorkflowStepListItem}
                  variableScope={variables}
                  stepNumber={groupStepIndex + 1}
                  onEditStep={() => {
                    // Here we hardcode the step index to 0, because we know that
                    // there is only one step in the group.
                    const stepIndex = 0;
                    onEditStep(groupStepIndex, stepIndex);
                  }}
                  onRemoveStep={() => {
                    onRemoveStepGroup(groupStepIndex);
                  }}
                />
              )}
            </React.Fragment>
          );
        }}
      />
    </>
  );
};
