import { ErrorResponse, PolicyReportSchedule } from "@incident-io/api";
import { CreateEditFormProps } from "@incident-shared/forms/v2/formsv2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";

import { PolicyReportCreateEditForm } from "./PolicyReportCreateEditForm";

export const PolicyReportCreateEditDrawer = ({
  title,
  onClose,
  initialData,
  hidePolicySelector = false,
  loading,
  error,
  fullWidth = true,
  onCreateCallback,
}: {
  title: string;
  onClose: () => void;
  initialData: CreateEditFormProps<PolicyReportSchedule>;
  loading?: boolean;
  hidePolicySelector?: boolean;
  error?: ErrorResponse;
  fullWidth?: boolean;
  onCreateCallback?: (data: PolicyReportSchedule) => void;
}) => {
  const drawerWidth = fullWidth ? "full" : "medium";
  if (error) {
    return (
      <Drawer onClose={onClose} width={drawerWidth}>
        <DrawerTitle
          icon={IconEnum.Shield}
          title={title}
          onClose={onClose}
          closeIcon={IconEnum.Close}
          sticky
          compact
          color={ColorPaletteEnum.Red}
        />
        <GenericErrorMessage description="Unable to fetch policy report" />
      </Drawer>
    );
  }

  return (
    <Drawer onClose={onClose} width={drawerWidth} warnWhenDirty>
      {loading ? (
        <DrawerContentsLoading />
      ) : (
        <div className="flex flex-col h-full !overflow-hidden">
          <DrawerTitle
            icon={IconEnum.Shield}
            title={title}
            onClose={onClose}
            closeIcon={IconEnum.Close}
            sticky
            compact
            color={ColorPaletteEnum.Red}
            theme={DrawerTitleTheme.Bordered}
          />
          <DrawerContents>
            <PolicyReportCreateEditForm
              onClose={onClose}
              formProps={initialData}
              hidePolicySelector={hidePolicySelector}
              fullWidth={fullWidth}
              onCreateCallback={onCreateCallback}
            />
          </DrawerContents>
        </div>
      )}
    </Drawer>
  );
};
