/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemTemplateSectionConfig
 */
export interface PostmortemTemplateSectionConfig {
    /**
     * IDs of custom fields to be included in the section
     * @type {Array<string>}
     * @memberof PostmortemTemplateSectionConfig
     */
    custom_field_ids: Array<string>;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemTemplateSectionConfig
     */
    help_text?: TextNode;
    /**
     * Name of the section
     * @type {string}
     * @memberof PostmortemTemplateSectionConfig
     */
    name: string;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemTemplateSectionConfig
     */
    template?: TextNode;
}

export function PostmortemTemplateSectionConfigFromJSON(json: any): PostmortemTemplateSectionConfig {
    return PostmortemTemplateSectionConfigFromJSONTyped(json, false);
}

export function PostmortemTemplateSectionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemTemplateSectionConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_ids': json['custom_field_ids'],
        'help_text': !exists(json, 'help_text') ? undefined : TextNodeFromJSON(json['help_text']),
        'name': json['name'],
        'template': !exists(json, 'template') ? undefined : TextNodeFromJSON(json['template']),
    };
}

export function PostmortemTemplateSectionConfigToJSON(value?: PostmortemTemplateSectionConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_ids': value.custom_field_ids,
        'help_text': TextNodeToJSON(value.help_text),
        'name': value.name,
        'template': TextNodeToJSON(value.template),
    };
}

