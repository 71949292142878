import type { SVGProps } from "react";
import * as React from "react";
const SvgGoogleCalendar = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="#fff" d="M5.111 5.111h9.778v9.778H5.11V5.11Z" />
    <path
      fill="#1E88E5"
      d="m10.747 8.631.448.64.704-.512v3.712h.768V7.607h-.64l-1.28 1.024ZM9.53 9.887c.278-.255.45-.61.45-1 0-.776-.68-1.408-1.518-1.408-.712 0-1.321.449-1.48 1.09l.736.187c.074-.295.386-.509.744-.509.418 0 .76.287.76.64 0 .353-.342.64-.76.64h-.443v.768h.443c.48 0 .886.334.886.729 0 .402-.385.729-.859.729-.427 0-.793-.271-.85-.63l-.75.122c.116.728.804 1.276 1.6 1.276.892 0 1.618-.672 1.618-1.497 0-.455-.224-.863-.577-1.137Z"
    />
    <path
      fill="#FBC02D"
      d="M14.444 18H5.556l-.445-1.778.445-1.778h8.888l.445 1.778L14.444 18Z"
    />
    <path
      fill="#4CAF50"
      d="M16.222 14.889 18 14.444V5.556l-1.778-.445-1.778.445v8.888l1.778.445Z"
    />
    <path
      fill="#1E88E5"
      d="m14.444 5.556.445-1.778L14.444 2H3.334A1.333 1.333 0 0 0 2 3.333v11.111l1.778.445 1.778-.445V5.556h8.888Z"
    />
    <path fill="#E53935" d="M14.444 14.444V18L18 14.444h-3.556Z" />
    <path
      fill="#1565C0"
      d="M16.667 2h-2.223v3.556H18V3.333A1.334 1.334 0 0 0 16.667 2ZM3.333 18h2.223v-3.556H2v2.223A1.333 1.333 0 0 0 3.333 18Z"
    />
  </svg>
);
export default SvgGoogleCalendar;
