// JS thinks the week starts on Sunday :( so we can't index our Monday-indexed enum directly
import { ScheduleRotation } from "@incident-io/api";
import { WeekdayIntervalWeekdayEnum } from "@incident-io/api/models/WeekdayInterval";
import { UpcomingRotaChange } from "@incident-shared/schedules/ScheduleOverview/types";
import _ from "lodash";
import { DateTime } from "luxon";

import { RotaFormData } from "./RotaCreateEditForm";

export const getWeekdayFromDayOfWeek = (
  dayOfWeek: WeekdayIntervalWeekdayEnum,
): number => {
  switch (dayOfWeek) {
    case WeekdayIntervalWeekdayEnum.Sunday:
      return 0;
    case WeekdayIntervalWeekdayEnum.Monday:
      return 1;
    case WeekdayIntervalWeekdayEnum.Tuesday:
      return 2;
    case WeekdayIntervalWeekdayEnum.Wednesday:
      return 3;
    case WeekdayIntervalWeekdayEnum.Thursday:
      return 4;
    case WeekdayIntervalWeekdayEnum.Friday:
      return 5;
    case WeekdayIntervalWeekdayEnum.Saturday:
      return 6;
    default:
      throw new Error("Invalid day of week");
  }
};

export const dateAndTimestringToUtcInstant = (
  date: DateTime,
  timeString: string,
  timezone: string,
): DateTime => {
  const [h, m] = timeString.split(":").map(Number);
  return date.setZone(timezone).set({ hour: h, minute: m });
};

// For an array of rota configs (that may include multiple versions for the same rota id), return rota from the original
// array that represent currently active rota i.e. not deleted, superseded, or taking effect in the future.
export const getCurrentlyActiveRotaConfigs = ({
  rotas,
  now,
}: {
  rotas: ScheduleRotation[];
  now: DateTime;
}): ScheduleRotation[] => {
  return _.chain(rotas)
    .groupBy((r) => r.id)
    .mapValues((rotaVersions) => {
      const activeVersions = rotaVersions.filter(
        (rv) =>
          !rv.effective_from || DateTime.fromJSDate(rv.effective_from) <= now,
      );
      return _.maxBy(
        activeVersions,
        (rv) =>
          rv.effective_from ? rv.effective_from : new Date(-8640000000000000), // If effective_from is unset, make this lowest priority
      );
    })
    .values()
    .compact()
    .value();
};

export const getUpcomingRotaConfigChanges = (
  rotas: ScheduleRotation[] | RotaFormData[],
  now: DateTime,
): UpcomingRotaChange[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore // TypeScript is being really annoying, both forms of rota have the correct fields
  const upcomingRotas = rotas.filter(
    (r) => r.effective_from && DateTime.fromJSDate(r.effective_from) > now,
  );

  return upcomingRotas.map((r) => ({
    at: DateTime.fromJSDate(r.effective_from as Date), // We've filtered out rotas without effective_from above
    rotaId: r.id ?? "",
  }));
};
