/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SeverityAlias,
    SeverityAliasFromJSON,
    SeverityAliasFromJSONTyped,
    SeverityAliasToJSON,
} from './SeverityAlias';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface Severity
 */
export interface Severity {
    /**
     * Potential aliases for this severity
     * @type {Array<SeverityAlias>}
     * @memberof Severity
     */
    aliases: Array<SeverityAlias>;
    /**
     * Bucketed rank to help the UI render appropriately. Will always be between 1 and 4, where 1 is the least severe group.
     * @type {number}
     * @memberof Severity
     */
    bucketed_rank: number;
    /**
     * When the action was created
     * @type {Date}
     * @memberof Severity
     */
    created_at: Date;
    /**
     * 
     * @type {TextDocument}
     * @memberof Severity
     */
    description: TextDocument;
    /**
     * Unique identifier of the severity
     * @type {string}
     * @memberof Severity
     */
    id: string;
    /**
     * Human readable name of the severity
     * @type {string}
     * @memberof Severity
     */
    name: string;
    /**
     * ID of the org that owns the severity
     * @type {string}
     * @memberof Severity
     */
    organisation_id: string;
    /**
     * Rank to help sort severities (lower numbers are less severe)
     * @type {number}
     * @memberof Severity
     */
    rank: number;
    /**
     * When the action was last updated
     * @type {Date}
     * @memberof Severity
     */
    updated_at: Date;
}

export function SeverityFromJSON(json: any): Severity {
    return SeverityFromJSONTyped(json, false);
}

export function SeverityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Severity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aliases': ((json['aliases'] as Array<any>).map(SeverityAliasFromJSON)),
        'bucketed_rank': json['bucketed_rank'],
        'created_at': (new Date(json['created_at'])),
        'description': TextDocumentFromJSON(json['description']),
        'id': json['id'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'rank': json['rank'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function SeverityToJSON(value?: Severity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aliases': ((value.aliases as Array<any>).map(SeverityAliasToJSON)),
        'bucketed_rank': value.bucketed_rank,
        'created_at': (value.created_at.toISOString()),
        'description': TextDocumentToJSON(value.description),
        'id': value.id,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'rank': value.rank,
        'updated_at': (value.updated_at.toISOString()),
    };
}

