import {
  CatalogTypeAttributePayload,
  CatalogTypeAttributePayloadModeEnum as AttributePayloadModeEnum,
} from "@incident-io/api";
import { captureException } from "@sentry/react";
import { useAPI } from "src/utils/swr";

export const useBacklinkAttributeInfo = ({
  attribute,
}: {
  attribute: CatalogTypeAttributePayload;
}) => {
  const {
    data: { catalog_types: catalogTypes },
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  if (attribute.mode !== AttributePayloadModeEnum.Backlink) {
    return { catalogTypesLoading: false };
  }

  const referencedType = catalogTypes.find(
    (ct) => ct.type_name === attribute.type,
  );

  const referencedAttribute = referencedType?.schema?.attributes?.find(
    (attr) => attr.id === attribute.backlink_attribute,
  );

  if (!referencedType || !referencedAttribute) {
    captureException(
      new Error("Referenced type or attribute not found for backlink", {}),
      {
        tags: {
          type: attribute.type,
          backlink_attribute_id: attribute.backlink_attribute,
        },
      },
    );
  }

  return {
    referencedType,
    referencedAttribute,
    catalogTypesLoading,
    catalogTypesError,
  };
};
