/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalZendeskTicket
 */
export interface ExternalZendeskTicket {
    /**
     * Human readable label for ticket
     * @type {string}
     * @memberof ExternalZendeskTicket
     */
    label: string;
    /**
     * Ticket status
     * @type {string}
     * @memberof ExternalZendeskTicket
     */
    status: string;
    /**
     * Subject of ticket
     * @type {string}
     * @memberof ExternalZendeskTicket
     */
    subject: string;
}

export function ExternalZendeskTicketFromJSON(json: any): ExternalZendeskTicket {
    return ExternalZendeskTicketFromJSONTyped(json, false);
}

export function ExternalZendeskTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalZendeskTicket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'status': json['status'],
        'subject': json['subject'],
    };
}

export function ExternalZendeskTicketToJSON(value?: ExternalZendeskTicket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'status': value.status,
        'subject': value.subject,
    };
}

