/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFormsGetEscalationElementBindingsRequestBody
 */
export interface IncidentFormsGetEscalationElementBindingsRequestBody {
    /**
     * The type of this incident form
     * @type {string}
     * @memberof IncidentFormsGetEscalationElementBindingsRequestBody
     */
    incident_form_type: IncidentFormsGetEscalationElementBindingsRequestBodyIncidentFormTypeEnum;
    /**
     * The ID of this incident type this incident is configured to use
     * @type {string}
     * @memberof IncidentFormsGetEscalationElementBindingsRequestBody
     */
    incident_type_id?: string;
    /**
     * The mode of this incident. Not currently used, but it helps avoid contention when rendering the live and test incident modals from the web by having unique swr keys
     * @type {string}
     * @memberof IncidentFormsGetEscalationElementBindingsRequestBody
     */
    mode?: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormsGetEscalationElementBindingsRequestBodyIncidentFormTypeEnum {
    Declare = 'declare',
    Accept = 'accept',
    Update = 'update',
    Resolve = 'resolve',
    CustomFields = 'custom-fields',
    Escalate = 'escalate'
}

export function IncidentFormsGetEscalationElementBindingsRequestBodyFromJSON(json: any): IncidentFormsGetEscalationElementBindingsRequestBody {
    return IncidentFormsGetEscalationElementBindingsRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsGetEscalationElementBindingsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsGetEscalationElementBindingsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_form_type': json['incident_form_type'],
        'incident_type_id': !exists(json, 'incident_type_id') ? undefined : json['incident_type_id'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
    };
}

export function IncidentFormsGetEscalationElementBindingsRequestBodyToJSON(value?: IncidentFormsGetEscalationElementBindingsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_form_type': value.incident_form_type,
        'incident_type_id': value.incident_type_id,
        'mode': value.mode,
    };
}

