import {
  EngineScope,
  PolicyPolicyTypeEnum,
  RequirementsTemplate,
} from "@incident-io/api";
import { useFormContext } from "react-hook-form";

import { useGetPolicyTypeConfig } from "../common/config";
import { PolicyCreateEditFormData } from "../common/PolicyCreateEditForm";
import { PolicyEditableSection } from "./PolicyEditableSection";
import { PolicyRequirementsEditor } from "./PolicyRequirementsEditor";

export const PolicyRequirementsSection = ({
  templates,
  scope,
  policyType,
}: {
  templates: RequirementsTemplate[];
  scope: EngineScope;
  policyType: PolicyPolicyTypeEnum;
}) => {
  const formMethods = useFormContext<PolicyCreateEditFormData>();

  const config = useGetPolicyTypeConfig()(policyType);

  return (
    <>
      <PolicyEditableSection
        icon={config.icon}
        title={
          <div className="flex flex-wrap gap-1">
            What requirements must{" "}
            <span className="text-blue-content">{config.label_plural}</span>{" "}
            fulfill?
          </div>
        }
      >
        <PolicyRequirementsEditor
          formMethods={formMethods}
          templates={templates}
          policyType={policyType}
          name="type_condition_groups"
          rules={{
            required: "You must select at least one requirement",
            minLength: 1,
          }}
          conditionLabel="requirement"
          scope={scope}
          hideIntroSentence
        />
      </PolicyEditableSection>
    </>
  );
};
