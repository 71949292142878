import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  ToastTheme,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCanBeginImpersonating } from "src/utils/sessions";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const GithubConfigDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { data, isLoading, error } = useAPI(
    "integrationsGithubShow",
    undefined,
  );

  const canImpersonate = useCanBeginImpersonating();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (!data || isLoading) {
    return <IntegrationDrawerContentLoader />;
  }

  if (!canImpersonate) {
    return <GenericConfigureDrawerContents {...props} />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      {data.git_hub_config && <ConfigInner />}
    </GenericConfigureDrawerContents>
  );
};

type GithubPATFormData = {
  token: string;
};

const ConfigInner = () => {
  const formMethods = useForm<GithubPATFormData>();
  const showToast = useToast();

  const { trigger, isMutating: saving } = useAPIMutation(
    "integrationsGithubShow",
    undefined,
    async (apiClient, patBody: GithubPATFormData) => {
      await apiClient.integrationsGithubSetPersonalAccessToken({
        setPersonalAccessTokenRequestBody: {
          token: patBody.token,
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          title: "GitHub Personal Access Token updated",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: "We weren't able to delete this internal status page",

          theme: ToastTheme.Error,
        });
      },
    },
  );
  return (
    <div className="space-y-4">
      <FormProvider<GithubPATFormData> {...formMethods}>
        <Callout theme={CalloutTheme.Warning}>
          This is only visible to incident.io staff!
        </Callout>
        <InputV2
          formMethods={formMethods}
          name="token"
          label="GitHub Personal Access Token"
          placeholder="Enter your GitHub Personal Access Token"
          type={InputType.Password}
          required
        />
        <Button
          theme={ButtonTheme.Primary}
          className="ml-auto"
          onClick={() => trigger(formMethods.getValues())}
          disabled={saving}
          analyticsTrackingId={"integrations-github-save-pat"}
        >
          Save
        </Button>
      </FormProvider>
    </div>
  );
};
