import { Icon, IconEnum, IconSize, Txt } from "@incident-ui";

import { AssistantAvatar, AssistantAvatarBackground } from "./AssistantAvatar";
import { useAssistantOverlay } from "./AssistantOverlayContext";
import { useAssistantThreadContext } from "./AssistantThreadContext";

// AssistantHeader is the top header of Assistant's chat overlay.
export const AssistantHeader = () => {
  const { toggleOverlay, shouldShowIntro } = useAssistantOverlay();

  const assistantThreadContext = useAssistantThreadContext();

  if (!assistantThreadContext) {
    return null;
  }

  return (
    <div className="flex justify-between items-center p-4 border-b-2 border-stroke">
      <div className="flex items-center">
        <div className="flex items-center gap-2">
          <AssistantAvatar
            size={IconSize.Large}
            background={AssistantAvatarBackground.Purple}
            className="p-1 !rounded-[6px]"
          />
          <Txt className="pr-1 font-medium">Assistant</Txt>
        </div>
      </div>
      <div className="flex flex-row space-x-1.5">
        {!shouldShowIntro && (
          // Only show the reset button if the user has already asked a question (i.e. they're not on the intro page).
          <button
            onClick={assistantThreadContext.resetThread}
            className="bg-surface-tertiary p-1 rounded-[6px] text-slate-700 flex flex-row space-x-1"
          >
            <Icon
              id={IconEnum.Loop}
              size={IconSize.Medium}
              className="text-content-secondary"
            />
            <span>
              <Txt>Reset</Txt>
            </span>
          </button>
        )}
        <button
          onClick={() => toggleOverlay()}
          className="bg-surface-tertiary p-1 rounded-[6px]"
        >
          <Icon
            id={IconEnum.Close}
            size={IconSize.Medium}
            className="text-content-secondary"
          />
        </button>
      </div>
    </div>
  );
};
