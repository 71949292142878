import { useAPI } from "src/utils/swr";

import {
  ChattyProductMarketingBanner,
  ChattyProductMarketingBannerProps,
} from "./ChattyProductMarketingBanner";

export function CSMChattyProductMarketingBanner(
  props: ChattyProductMarketingBannerProps,
): React.ReactElement {
  const { data } = useAPI("settingsSupport", undefined);

  return ChattyProductMarketingBanner({
    ...props,
    // If there's an error, or we're still loading, we just pass in no CSM,
    // and the banner will render without a chat bubble.
    csm: data?.csm,
  });
}
