import {
  Identity,
  Incident,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
  IncidentVisibilityEnum,
  IntegrationSettingsProviderEnum,
  Stream,
} from "@incident-io/api";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { useNavigateToModal } from "../../../../utils/query-params";
import { isIncidentClosedOrResolved } from "../body/Overview";
import { IncidentStackedList } from "./Components";

export const StreamsStackedList = ({
  streams,
  isLoading,
  parentIncident,
}: {
  streams: Array<Stream>;
  parentIncident: Incident;
  isLoading: boolean;
}) => {
  const navigateToModal = useNavigateToModal();

  if (streams.length === 0) {
    return null;
  }

  return (
    <IncidentStackedList.Section
      title="Streams"
      loading={isLoading}
      titleAccessory={
        isIncidentClosedOrResolved(parentIncident) ? undefined : (
          <Tooltip content="Create stream">
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId="create-stream"
              icon={IconEnum.Add}
              title=""
              onClick={() => navigateToModal("streams-create")}
            />
          </Tooltip>
        )
      }
    >
      {streams.map((stream) => (
        <StreamSummaryRow
          key={stream.id}
          stream={stream}
          parentIncident={parentIncident}
        />
      ))}
    </IncidentStackedList.Section>
  );
};

export const StreamSummaryRow = ({
  stream,
  parentIncident,
  useFullNavigate = false,
}: {
  stream: Stream;
  parentIncident: Incident;
  useFullNavigate?: boolean;
}) => {
  const incidentLead = stream.incident_role_assignments?.find(
    (r) => r.role.role_type === "lead",
  );
  // If we're in a modal, we can't use navigate to modal because the url is already a modal
  const navigate = useOrgAwareNavigate();
  const navigateToDrawer = useNavigateToModal();
  const navigateToStream = () => {
    if (useFullNavigate) {
      return navigate(
        `/incidents/${parentIncident.external_id}/streams/${stream.external_id}`,
      );
    } else {
      return navigateToDrawer(`streams/${stream.external_id}`);
    }
  };

  return (
    <Tooltip
      key={stream.id}
      content={
        stream.user_can_access
          ? undefined
          : "This stream is private, click to request access."
      }
    >
      <IncidentStackedList.Item
        title={stream.name}
        onClick={() => navigateToStream()}
        iconNode={
          stream.user_can_access ? (
            <StreamStatus
              className="mx-2 shrink-0"
              active={
                stream.status.category === IncidentStatusCategoryEnum.Active
              }
            />
          ) : (
            <Icon
              id={IconEnum.LockClosed}
              size={IconSize.Small}
              className="mx-1 shrink-0 self-center text-content-tertiary"
            />
          )
        }
        accessoryOne={
          <Tooltip
            key={incidentLead?.assignee?.id}
            content={incidentLead?.assignee?.name}
            delayDuration={0}
          >
            <Avatar
              size={IconSize.Medium}
              url={incidentLead?.assignee?.avatar_url}
            />
          </Tooltip>
        }
        accessoryTwo={
          <Button
            theme={ButtonTheme.Naked}
            title=""
            analyticsTrackingId={`${stream.id}-slack-button`}
            href={stream.slack_channel_url}
            openInNewTab
            className="h-auto w-auto px-0 p-1 rounded-[6px] shrink-0"
            icon={IconEnum.Slack}
          />
        }
      />
    </Tooltip>
  );
};

const StreamStatus = ({
  active,
  className,
  dotClassName,
}: {
  active?: boolean;
  className?: string;
  dotClassName?: string;
}) => {
  return (
    <Tooltip content={active ? "Stream open" : "Stream closed"}>
      <Button theme={ButtonTheme.Unstyled} analyticsTrackingId={null}>
        <div className={tcx(className, "flex relative w-[6px] h-[6px]")}>
          {/* If we're in the active state, this creates the animated ping behind the solid dot */}
          {active ? (
            <div className="absolute inline-flex w-full h-full rounded-full bg-alarmalade-600 animate-ping-slow" />
          ) : null}
          <div
            className={tcx(
              dotClassName,
              "relative inline-flex w-full h-full rounded-full bg-alarmalade-600",
              {
                "bg-alarmalade-600": active,
                "bg-slate-500": !active,
              },
            )}
          />
        </div>
      </Button>
    </Tooltip>
  );
};

export const canCreateStream = ({
  streams,
  parentIncident,
  identity,
}: {
  streams: Array<Stream>;
  parentIncident: Incident;
  identity: Identity;
}) => {
  const commsPlatform = identity.slack_info
    ? IntegrationSettingsProviderEnum.Slack
    : IntegrationSettingsProviderEnum.MicrosoftTeams;

  const canAddStreams = !numericGateLimitReached(
    identity.feature_gates.streams_per_incident_count,
    streams.length,
  );

  // Only show streams if the incident can have streams and the feature flag is enabled
  const incIsStreamFriendly =
    parentIncident?.visibility === IncidentVisibilityEnum.Public &&
    (parentIncident?.mode === IncidentModeEnum.Standard ||
      parentIncident?.mode === IncidentModeEnum.Test) &&
    commsPlatform === IntegrationSettingsProviderEnum.Slack;

  const showStreams =
    incIsStreamFriendly &&
    !(
      identity.feature_gates?.streams_per_incident_count === 0 &&
      streams.length === 0
    );

  return showStreams && canAddStreams;
};
