import type { SVGProps } from "react";
import * as React from "react";
const SvgUserAdd = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 9a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm3.5 8.25V17h-.25A2.253 2.253 0 0 1 11 14.75c0-1.241 1.01-2.25 2.25-2.25h.25v-.25c0-.409.118-.787.309-1.118A6.976 6.976 0 0 0 10 10a7 7 0 0 0-6.395 4.167 2.237 2.237 0 0 0 .008 1.825 2.291 2.291 0 0 0 1.399 1.239c1.618.51 3.296.769 4.987.769 1.191 0 2.375-.135 3.536-.39a2.252 2.252 0 0 1-.036-.36h.001Zm3-3.25h1.75a.75.75 0 0 1 0 1.5H16.5v1.75a.75.75 0 0 1-1.5 0V15.5h-1.75a.75.75 0 0 1 0-1.5H15v-1.75a.75.75 0 0 1 1.5 0V14Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserAdd;
