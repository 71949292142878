import { EngineFormElement } from "@incident-shared/engine";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  Loader,
  SharedToasts,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  DebriefSettings,
  IncidentsBuildScopeContextEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAllResources } from "src/hooks/useResources";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSubHeading } from "../SettingsSubHeading";

type FormData = Omit<DebriefSettings, "expressions"> & {
  expressions: ExpressionFormData[];
};

export const DebriefDurationSection = ({
  settings,
}: {
  settings: DebriefSettings;
}): React.ReactElement => {
  const showToast = useToast();

  const formMethods = useForm<FormData>({
    defaultValues: {
      duration: settings.duration ?? {
        value: {
          literal: "60",
        },
      },
      expressions: settings?.expressions || [],
    },
  });

  const {
    setError,
    reset,
    formState: { isDirty },
  } = formMethods;
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "debriefsShowSettings",
    undefined,
    async (apiClient, formData: FormData) => {
      await apiClient.debriefsUpdateDuration({
        updateDurationRequestBody: {
          duration: formData.duration,
          expressions: (formData.expressions || []).map(expressionToPayload),
        },
      });
    },
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(settings);
      },
      setError,
    },
  );

  const expressionsMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const { resources, resourcesLoading } = useAllResources();
  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.DebriefSettings,
  );

  if (scopeError) {
    return <GenericErrorMessage error={scopeError} />;
  }
  if (resourcesLoading || scopeLoading) {
    return <Loader />;
  }

  return (
    <Form.Root
      onSubmit={onSubmit}
      genericError={genericError}
      saving={saving}
      formMethods={formMethods}
    >
      <SettingsSubHeading
        title="Event duration"
        titleHeadingLevel={2}
        explanation={`You can customize the default duration of your debriefs. It will be used when creating a debrief calendar event for an incident.`}
      />
      <ContentBox className="p-6 flex-between">
        <div className="flex items-center space-x-2">
          <ExpressionsMethodsProvider
            expressionsMethods={expressionsMethods}
            allowAllOfACatalogType={false}
          >
            <EngineFormElement
              name="duration"
              resourceType="Number"
              label="Duration"
              description={`Duration of the debrief event in minutes.`}
              array={false}
              required
              scope={scope}
              showPlaceholder
              mode="variables_and_expressions"
              resources={resources}
            />
          </ExpressionsMethodsProvider>
        </div>
        <GatedButton
          type="submit"
          analyticsTrackingId={null}
          className="mb-6 md:mb-0 mt-4 md:mt-0"
          disabled={!isDirty}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          theme={ButtonTheme.Primary}
        >
          Save
        </GatedButton>
      </ContentBox>
    </Form.Root>
  );
};
