/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface AlertTemplate
 */
export interface AlertTemplate {
    /**
     * Alert schema attributes mapped to values
     * @type {{ [key: string]: EngineParamBinding; }}
     * @memberof AlertTemplate
     */
    bindings: { [key: string]: EngineParamBinding; };
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof AlertTemplate
     */
    description: EngineParamBinding;
    /**
     * Expressions available for use in bindings
     * @type {Array<Expression>}
     * @memberof AlertTemplate
     */
    expressions: Array<Expression>;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof AlertTemplate
     */
    priority?: EngineParamBinding;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof AlertTemplate
     */
    title: EngineParamBinding;
}

export function AlertTemplateFromJSON(json: any): AlertTemplate {
    return AlertTemplateFromJSONTyped(json, false);
}

export function AlertTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bindings': (mapValues(json['bindings'], EngineParamBindingFromJSON)),
        'description': EngineParamBindingFromJSON(json['description']),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'priority': !exists(json, 'priority') ? undefined : EngineParamBindingFromJSON(json['priority']),
        'title': EngineParamBindingFromJSON(json['title']),
    };
}

export function AlertTemplateToJSON(value?: AlertTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bindings': (mapValues(value.bindings, EngineParamBindingToJSON)),
        'description': EngineParamBindingToJSON(value.description),
        'expressions': ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'priority': EngineParamBindingToJSON(value.priority),
        'title': EngineParamBindingToJSON(value.title),
    };
}

