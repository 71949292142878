import { TextDocumentPayload, TimelineItemComment } from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  LocalDateTime,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { TimelineSpacer } from "./TimelineSpacer";

type TimelineItemProps = {
  id: string;
  title: React.ReactNode;
  timestamp: Date | null;
  description?: TextDocumentPayload;
  hasChildren?: boolean;
  hideSpacer?: boolean;
  spacerStyle?: "stroke" | "dashed";
  minimized?: boolean;
  setMinimized?: (minimized: boolean) => void;

  forceShowButtons?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;

  // All props for handling comments
  allowCommenting: boolean;
  comments?: TimelineItemComment[];
  commentBoxOpen?: boolean;
  setCommentBoxOpen?: (open: boolean) => void;
  renderCommentBox?: (
    itemId: string,
    comments: TimelineItemComment[],
  ) => React.ReactNode;

  icon: IconEnum;
  color: ColorPaletteEnum;
  iconClassName?: string;

  // Use children to render the actual activity log content
  children?: React.ReactNode;
};

export const TimelineItem = ({
  id,
  title,
  description,
  timestamp,
  hasChildren,
  hideSpacer = false,
  spacerStyle = "stroke",
  minimized = false,
  setMinimized,
  onEdit,
  onDelete,
  forceShowButtons,
  allowCommenting = false,
  comments = [],
  commentBoxOpen,
  setCommentBoxOpen,
  renderCommentBox,
  children,
  icon,
  color,
  iconClassName,
}: TimelineItemProps): React.ReactElement => {
  const expandable =
    (hasChildren || description || allowCommenting) && !!setMinimized;

  return (
    <div className="group/timelineitem">
      <div className="h-7 flex items-center space-x-5 relative">
        <div className="w-9 text-content-tertiary">
          {timestamp != null && (
            <LocalDateTime
              // LocalDateTime internally sets text-sm
              // so we haev to !important this bad boy
              className="!text-xs-med"
              timestamp={timestamp}
              format="HH:mm"
            />
          )}
        </div>
        <IconBadge
          icon={icon}
          iconProps={{ size: IconSize.Small }}
          size={IconSize.Small}
          color={color}
          className={iconClassName}
        />
        <div
          className={tcx(
            "flex items-center space-x-1 flex-grow",
            expandable && "cursor-pointer",
          )}
          onClick={() => (expandable ? setMinimized(!minimized) : undefined)}
        >
          <div className="text-sm-bold text-content-primary">{title}</div>
          <>
            <IconicNumberDisplay
              count={comments.length}
              icon={IconEnum.Message}
            />
          </>
          {expandable && (
            <Icon
              id={minimized ? IconEnum.ChevronDown : IconEnum.ChevronUp}
              size={IconSize.Small}
              className="text-content-secondary"
            />
          )}
        </div>
        <div className="ml-auto flex items-center space-x-2">
          {!onEdit && allowCommenting && (
            <Button
              theme={ButtonTheme.Naked}
              size={BadgeSize.Medium}
              iconPosition="left"
              icon={IconEnum.Message}
              analyticsTrackingId={null}
              title="Leave a comment"
              className="invisible group-hover/timelineitem:visible transition-opacity duration-300 ease-in-out opacity-0 group-hover/timelineitem:opacity-100"
              onClick={() => {
                if (setMinimized) {
                  setMinimized(false);
                }
                setCommentBoxOpen && setCommentBoxOpen(true);
                // Focus the comment box after a short delay to ensure it's rendered
                setTimeout(() => {
                  const commentBox = document.querySelector(
                    `[data-timeline-item-id="${id}"]`,
                  );
                  if (commentBox instanceof HTMLTextAreaElement) {
                    commentBox.focus();
                  }
                }, 100);
              }}
            >
              Leave comment
            </Button>
          )}
          {onEdit && (
            <Button
              className={tcx(
                !forceShowButtons &&
                  "invisible group-hover/timelineitem:visible",
              )}
              theme={ButtonTheme.Naked}
              size={BadgeSize.Medium}
              icon={IconEnum.Edit}
              onClick={onEdit}
              analyticsTrackingId={null}
              title="Edit"
            />
          )}
          {onDelete && (
            <Button
              className={tcx(
                !forceShowButtons &&
                  "invisible group-hover/timelineitem:visible",
              )}
              theme={ButtonTheme.Naked}
              size={BadgeSize.Medium}
              icon={IconEnum.Delete}
              onClick={onDelete}
              analyticsTrackingId={null}
              title="Delete"
            />
          )}
        </div>
      </div>
      <TimelineSpacer
        expanded={!minimized}
        hideLine={hideSpacer}
        lineStyle={spacerStyle}
      >
        {description && (
          <TemplatedTextDisplay
            className="grow text-content-secondary"
            value={description.text_node ?? ""}
            style={TemplatedTextDisplayStyle.Compact}
          />
        )}

        {children}
        {allowCommenting &&
          (comments.length > 0 || commentBoxOpen) &&
          renderCommentBox &&
          renderCommentBox(id, comments)}
      </TimelineSpacer>
    </div>
  );
};

type IconicNumberDisplayProps = {
  count: number;
  icon: IconEnum;
};

const IconicNumberDisplay = ({ count, icon }: IconicNumberDisplayProps) => {
  if (count === 0) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Icon
        id={icon}
        size={IconSize.Small}
        className="text-content-secondary"
      />
      <span className="text-xs text-content-secondary">{count}</span>
    </div>
  );
};
