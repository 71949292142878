/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Condition,
    ConditionFromJSON,
    ConditionFromJSONTyped,
    ConditionToJSON,
} from './Condition';

/**
 * 
 * @export
 * @interface NudgesFixedConditionsResponseBody
 */
export interface NudgesFixedConditionsResponseBody {
    /**
     * What conditions must be met for this nudge to run?
     * @type {Array<Condition>}
     * @memberof NudgesFixedConditionsResponseBody
     */
    conditions: Array<Condition>;
}

export function NudgesFixedConditionsResponseBodyFromJSON(json: any): NudgesFixedConditionsResponseBody {
    return NudgesFixedConditionsResponseBodyFromJSONTyped(json, false);
}

export function NudgesFixedConditionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgesFixedConditionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': ((json['conditions'] as Array<any>).map(ConditionFromJSON)),
    };
}

export function NudgesFixedConditionsResponseBodyToJSON(value?: NudgesFixedConditionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': ((value.conditions as Array<any>).map(ConditionToJSON)),
    };
}

