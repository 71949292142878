import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

/**
 * SkeletonIndicator requires that a parent has the `group` class applied to it.
 */
export const SelectionIndicator = ({
  selected,
  className,
}: {
  selected: boolean;
  className?: string;
}) => {
  return selected ? (
    // Selected state
    <Icon
      id={IconEnum.Success}
      size={IconSize.XL}
      className={tcx("child:stroke-1 text-content-primary bg-white", className)}
    />
  ) : (
    // Unselected state with hover
    <>
      <Icon
        id={IconEnum.Circle}
        size={IconSize.XL}
        className={tcx(
          "child:stroke-1 text-slate-200 group-hover:hidden",
          className,
        )}
      />
      <Icon
        id={IconEnum.Success}
        size={IconSize.XL}
        className={tcx(
          "child:stroke-1 text-content-tertiary bg-white hidden group-hover:inline-block",
          className,
        )}
      />
    </>
  );
};
