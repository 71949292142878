import { Link } from "@incident-ui";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceNagiosSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            This alert source requires you to download and install the
            incident.io Nagios plugin, which you can find on our{" "}
            <Link
              analyticsTrackingId={"nagios-plugin"}
              href={"https://github.com/incident-io/nagios-plugin"}
              openInNewTab
            >
              GitHub
            </Link>
            . Please follow the instructions in the README and use the URL and
            token provided below when configuring your command.
          </>
        }
        blocks={[
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/nagios/${
              alertSourceConfig.id
            }`,
          },
          {
            title: "Token",
            code: alertSourceConfig.secret_token ?? "",
          },
        ]}
      />
      <p>
        Once you&apos;ve installed the plugin and configured your command, you
        should see alerts come through on the right hand side of this page.
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            If you&apos;re having trouble receiving alerts, try invoking the
            plugin directly as your nagios user, e.g.
          </>
        }
        blocks={[
          {
            title: "Test command",
            code: `notify_incident_io-darwin-arm64 --api_url=${publicApiUrl()}/v2/alert_events/nagios/${
              alertSourceConfig.id
            } --token=${
              alertSourceConfig.secret_token ?? ""
            } --host_name="localhost" --host_address="127.0.0.1" --host_alias="Localhost" --service_desc="Test HTTP on localhost:3000" --notification_type="PROBLEM" --host_state="UP" --service_state="OK"`,
          },
        ]}
      />
      <p>
        By default, the plugin will deduplicate alerts based on the host name
        and service description it receives, but you can set a specific
        deduplication key with a <code>--deduplication_key</code> argument in
        your command. You can also customise the title, description and link
        using the <code>title</code>, <code>description</code> and{" "}
        <code>source_url</code> arguments.
      </p>
    </SetupInfoNumberedList>
  );
};
