import { IconEnum } from "@incident-ui/Icon/Icon";
import { Popover, PopoverBody } from "@incident-ui/Popover/Popover";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import { PopoverSearch } from "@incident-ui/Popover/PopoverSearch";
import { Searcher, sortKind } from "fast-fuzzy";
import { partition, sortBy } from "lodash";
import { groupBy } from "lodash";
import React, { useState } from "react";

export type SearchableDropdownEntryGroup = {
  // The unique identifier for this group
  name: string;
  // The label we show for this group
  label: React.ReactNode;
};

export type SearchableDropdownEntry<TItemType> = {
  item: TItemType;
  label: string;
  icon?: IconEnum;
  group?: SearchableDropdownEntryGroup;
  sortKey?: string;
  renderFn?: () => React.ReactNode;
  onSelectRender?: OnSelectRender;
};

type OnSelectRender = (props: {
  onBack: () => void;
  onClose: () => void;
  onChange: (val: string) => void;
}) => React.ReactElement;

export const SearchableDropdown = <TItemType,>({
  onSelectItem,
  entries,
  renderTriggerButton,
  emptyState,
  extraItems,
  hideSearchBar = false,
}: {
  onSelectItem: (item: TItemType) => void;
  entries: SearchableDropdownEntry<TItemType>[];
  renderTriggerButton: (props: { onClick: () => void }) => React.ReactElement;
  emptyState: React.ReactNode;
  extraItems?: React.ReactNode;
  hideSearchBar?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const searcher = new Searcher(entries, {
    keySelector: (s) => s.label,
    threshold: 0.8,
    sortBy: sortKind.insertOrder,
  });

  const handleClose = () => {
    setSearch("");
    setIsOpen(false);
  };

  const handleSelect = (item: TItemType) => {
    setSearch("");
    onSelectItem(item);
    setIsOpen(false);
  };

  return (
    <Popover
      onInteractOutside={() => {
        setIsOpen(false);
      }}
      trigger={renderTriggerButton({ onClick: () => setIsOpen(true) })}
      className="max-h-[400px] w-[350px]"
      sideOffset={-38}
      align="start"
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        }
      }}
      open={isOpen}
    >
      <>
        {/* Search */}
        {hideSearchBar ? undefined : (
          <PopoverSearch value={search} onChange={setSearch} />
        )}
        {/* List of groups */}
        <PopoverBody scroll>
          <SearchableDropdownEntries
            entries={search ? searcher.search(search) : entries}
            isSearching={!!search}
            handleSelect={handleSelect}
            emptyState={emptyState}
          />
        </PopoverBody>
        {extraItems}
      </>
    </Popover>
  );
};

export const SearchableDropdownEntries = <TInputType,>({
  entries,
  handleSelect,
  isSearching,
  emptyState,
}: {
  entries: SearchableDropdownEntry<TInputType>[];
  handleSelect: (item: TInputType) => void;
  isSearching: boolean;
  emptyState: React.ReactNode;
}): React.ReactElement => {
  if (entries.length === 0) {
    return (
      <div className="text-content-tertiary p-2 pl-4 text-sm">
        {isSearching ? "No results found" : emptyState}
      </div>
    );
  }

  // Special case: if the parentLabel is undefined, stick those at the top
  const [entriesWithGroup, entriesWithNoGroup] = partition(
    entries,
    (x) => x.group,
  );
  const groupedEntries = groupBy(entriesWithGroup, (x) => x.group?.name);
  const sortedGroupedEntries = sortBy(
    Object.entries(groupedEntries),
    ([_, entries]) => entries[0].sortKey,
  );

  return (
    <>
      <PopoverItemGroup>
        {entriesWithNoGroup.map((entry, idx) => (
          <SearchableDropdownItem
            key={idx}
            {...entry}
            onClick={() => handleSelect(entry.item)}
          />
        ))}
      </PopoverItemGroup>
      {sortedGroupedEntries.map(([groupName, entries]) => {
        return (
          <PopoverItemGroup label={entries[0].group?.label} key={groupName}>
            {entries.map((entry, idx) => (
              <SearchableDropdownItem
                key={idx}
                {...entry}
                onClick={() => handleSelect(entry.item)}
              />
            ))}
          </PopoverItemGroup>
        );
      })}
    </>
  );
};

const SearchableDropdownItem = ({
  icon,
  label,
  renderFn,
  onSelectRender,
  onClick,
}: {
  icon?: IconEnum;
  label: string;
  renderFn?: () => React.ReactNode;
  onSelectRender?: OnSelectRender;
  onClick: () => void;
}) => {
  return (
    <PopoverItem
      icon={renderFn ? undefined : icon}
      onClick={onClick}
      showContinueChevron={!!onSelectRender}
      // If we have a render function, we want to have less padding as
      // we expect the render function to return a badge (or similar)
      className={renderFn ? "p-1" : ""}
    >
      {renderFn ? renderFn() : <span className="truncate">{label}</span>}
    </PopoverItem>
  );
};
