/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IssueTrackersJiraCreateIssueTemplateRequestBody
 */
export interface IssueTrackersJiraCreateIssueTemplateRequestBody {
    /**
     * Context in which this issue template should be used
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    context: IssueTrackersJiraCreateIssueTemplateRequestBodyContextEnum;
    /**
     * 
     * @type {TextNode}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    description?: TextNode;
    /**
     * Dynamic field keys mapped to values
     * @type {{ [key: string]: EngineParamBindingPayload; }}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    dynamic_fields?: { [key: string]: EngineParamBindingPayload; };
    /**
     * If this issue template should be enabled
     * @type {boolean}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    enabled: boolean;
    /**
     * The expressions to use in this config
     * @type {Array<ExpressionPayload>}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * The Jira issue type id
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    issue_type_id: string;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    labels?: EngineParamBindingPayload;
    /**
     * The name of the issue template
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    name: string;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    project_id: EngineParamBindingPayload;
    /**
     * The ID of the Jira Cloud instance to use
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueTemplateRequestBody
     */
    site_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum IssueTrackersJiraCreateIssueTemplateRequestBodyContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}

export function IssueTrackersJiraCreateIssueTemplateRequestBodyFromJSON(json: any): IssueTrackersJiraCreateIssueTemplateRequestBody {
    return IssueTrackersJiraCreateIssueTemplateRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersJiraCreateIssueTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersJiraCreateIssueTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'dynamic_fields': !exists(json, 'dynamic_fields') ? undefined : (mapValues(json['dynamic_fields'], EngineParamBindingPayloadFromJSON)),
        'enabled': json['enabled'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'issue_type_id': json['issue_type_id'],
        'labels': !exists(json, 'labels') ? undefined : EngineParamBindingPayloadFromJSON(json['labels']),
        'name': json['name'],
        'project_id': EngineParamBindingPayloadFromJSON(json['project_id']),
        'site_id': !exists(json, 'site_id') ? undefined : json['site_id'],
    };
}

export function IssueTrackersJiraCreateIssueTemplateRequestBodyToJSON(value?: IssueTrackersJiraCreateIssueTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'description': TextNodeToJSON(value.description),
        'dynamic_fields': value.dynamic_fields === undefined ? undefined : (mapValues(value.dynamic_fields, EngineParamBindingPayloadToJSON)),
        'enabled': value.enabled,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'issue_type_id': value.issue_type_id,
        'labels': EngineParamBindingPayloadToJSON(value.labels),
        'name': value.name,
        'project_id': EngineParamBindingPayloadToJSON(value.project_id),
        'site_id': value.site_id,
    };
}

