/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';

/**
 * 
 * @export
 * @interface AIUpdateIncidentSummariesConfigRequestBody
 */
export interface AIUpdateIncidentSummariesConfigRequestBody {
    /**
     * Label of the causes section
     * @type {string}
     * @memberof AIUpdateIncidentSummariesConfigRequestBody
     */
    causes_label: string;
    /**
     * What condition groups must be met for the incident to have summaries suggested by AI?
     * @type {Array<ConditionGroupPayload>}
     * @memberof AIUpdateIncidentSummariesConfigRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * Label of the impact section
     * @type {string}
     * @memberof AIUpdateIncidentSummariesConfigRequestBody
     */
    impact_label: string;
    /**
     * Label of the problem section
     * @type {string}
     * @memberof AIUpdateIncidentSummariesConfigRequestBody
     */
    problem_label: string;
    /**
     * Label of the steps section
     * @type {string}
     * @memberof AIUpdateIncidentSummariesConfigRequestBody
     */
    steps_label: string;
    /**
     * Label of the unknown section
     * @type {string}
     * @memberof AIUpdateIncidentSummariesConfigRequestBody
     */
    unknown_label: string;
}

export function AIUpdateIncidentSummariesConfigRequestBodyFromJSON(json: any): AIUpdateIncidentSummariesConfigRequestBody {
    return AIUpdateIncidentSummariesConfigRequestBodyFromJSONTyped(json, false);
}

export function AIUpdateIncidentSummariesConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIUpdateIncidentSummariesConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causes_label': json['causes_label'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'impact_label': json['impact_label'],
        'problem_label': json['problem_label'],
        'steps_label': json['steps_label'],
        'unknown_label': json['unknown_label'],
    };
}

export function AIUpdateIncidentSummariesConfigRequestBodyToJSON(value?: AIUpdateIncidentSummariesConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causes_label': value.causes_label,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'impact_label': value.impact_label,
        'problem_label': value.problem_label,
        'steps_label': value.steps_label,
        'unknown_label': value.unknown_label,
    };
}

