import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import {
  Button,
  ButtonTheme,
  ModalFooter,
  StaticSingleSelect,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import React, { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { DecisionFlowsUpdateRequestBody as UpdateReqBody } from "src/contexts/ClientContext";

import { DecisionFlowTemplate, flowTemplates } from "../templates";

// TODO:add validation
export const CreateMetadataModal = ({
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: (data: UpdateReqBody) => void;
}): React.ReactElement => {
  const [template, setTemplate] = useState<DecisionFlowTemplate | null>(null);

  const formMethods = useForm<UpdateReqBody>();
  const {
    setValue,
    reset,
    formState: { isDirty },
  } = formMethods;

  const templateOptions: SelectOption[] = flowTemplates.map((x, i) => ({
    label: x.template_label,
    value: x.template_label,
    sort_key: i.toString(),
  }));

  const onSelectTemplate = (templateLabel: string) => {
    const selectedTemplate = flowTemplates.find(
      (x) => x.template_label === templateLabel,
    );
    if (!selectedTemplate) {
      throw new Error("unreachable: selected template which doesn't exist");
    }
    setTemplate(selectedTemplate);
    setValue<"name">("name", selectedTemplate.name);
    setValue<"description">("description", selectedTemplate.description);
    setValue<"is_live">("is_live", selectedTemplate.is_live);
    setValue<"tree">("tree", selectedTemplate.tree);
  };

  const onClearTemplate = () => {
    reset();
    setTemplate(null);
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSave}
      analyticsTrackingId="create-decision-flow"
      title="Create Decision Flow"
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          disabled={!isDirty && !template}
          confirmButtonText="Continue"
          confirmButtonType="submit"
        />
      }
    >
      {/* Template */}
      <Form.InputWrapper name="template" label="Template">
        <StaticSingleSelect
          placeholder="Select a template"
          isClearable={false}
          isSearchable={false}
          disabled={!!template}
          options={templateOptions}
          value={template?.template_label || null}
          // @ts-expect-error it doesn't know this is a single select. We should fix this using typescript conditional type magic.
          onChange={onSelectTemplate}
        />
      </Form.InputWrapper>
      {template && (
        <Button
          onClick={onClearTemplate}
          analyticsTrackingId="choose-another-template"
          theme={ButtonTheme.Naked}
          className="float-right text-xs"
        >
          Choose another template
        </Button>
      )}

      {template && <EditMetadataForm />}
    </Form.Modal>
  );
};

// TODO:add validation
export const EditMetadataModal = ({
  decisionFlow,
  onClose,
  onSave,
}: {
  decisionFlow?: UpdateReqBody;
  onClose: () => void;
  onSave: (data: UpdateReqBody) => void;
}): React.ReactElement => {
  const formMethods = useForm<UpdateReqBody>({ defaultValues: decisionFlow });
  const {
    formState: { isDirty },
  } = formMethods;

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSave}
      analyticsTrackingId="edit-decision-flow"
      title="Edit Decision Flow"
      onClose={onClose}
      footer={
        <ModalFooter
          disabled={!isDirty}
          onClose={onClose}
          confirmButtonText="Continue"
          confirmButtonType="submit"
        />
      }
    >
      <EditMetadataForm />
    </Form.Modal>
  );
};

const EditMetadataForm = (): React.ReactElement => {
  const formMethods = useFormContext<UpdateReqBody>();
  return (
    <>
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        helptext="A short name to describe the decision flow"
        required="Please provide a name"
      />
      <TextareaV2
        formMethods={formMethods}
        name="description"
        label="Description"
        helptext="When should someone use this decision flow?"
        required="Please provide a description"
        rows={8}
      />
      <BooleanRadioButtonGroupV2
        formMethods={formMethods}
        name="is_live"
        srLabel="Availability"
        label="Availability"
        helptext="Which incidents should this decision flow be available in?"
        boxed
        trueOption={{
          label: "All incidents",
        }}
        falseOption={{
          label: "Test incidents only",
        }}
        trueFirst
      />
    </>
  );
};
