import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Icon, IconEnum } from "@incident-ui";
import { useState } from "react";
import { useAPI } from "src/utils/swr";

import { ImportModal } from "./ImportModal";

export const ImportCTA = () => {
  const {
    data: { available_migrations: availablePages },
  } = useAPI("statusPageListAvailableMigrations", undefined, {
    fallbackData: { available_migrations: [] },
  });

  const [isOpen, setIsOpen] = useState(false);

  if (availablePages.length === 0) {
    return null;
  }

  return (
    <>
      <div className="bg-surface-secondary rounded-2 flex gap-6 items-center p-6 max-w-4xl mx-auto">
        <div className="space-y-2">
          <h3 className="font-medium">
            <Icon id={IconEnum.AtlassianStatuspage} className="inline" />{" "}
            Migrate from Atlassian Statuspage
          </h3>
          <p className="text-sm">
            See how your page can look in two clicks. We&apos;ll bring your
            branding, components and incident history from Atlassian, and keep
            it in sync until you&apos;re ready to switch over.
          </p>
        </div>
        <GatedButton
          analyticsTrackingId={"status-page-import"}
          className="whitespace-nowrap"
          onClick={() => setIsOpen(true)}
          requiredScope={ScopeNameEnum.StatusPagesConfigure}
        >
          Let&apos;s go
        </GatedButton>
      </div>
      {isOpen && <ImportModal onClose={() => setIsOpen(false)} />}
    </>
  );
};
