import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusActive = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#DC2625"
      fillRule="evenodd"
      d="M7 2.59a5.502 5.502 0 0 0 0 10.82v1.52A7.001 7.001 0 0 1 7 1.07v1.52Z"
      clipRule="evenodd"
      opacity={0.3}
    />
    <path
      fill="#DC2625"
      fillRule="evenodd"
      d="M13.5 8H15a7 7 0 0 0-7-7v1.5A5.5 5.5 0 0 1 13.5 8ZM13.5 8H15a7 7 0 0 1-7 7v-1.5A5.5 5.5 0 0 0 13.5 8Z"
      clipRule="evenodd"
    />
    <path
      fill="#DC2625"
      d="M8 5.778A2.224 2.224 0 0 0 5.778 8c0 1.226.996 2.222 2.222 2.222A2.224 2.224 0 0 0 10.222 8 2.224 2.224 0 0 0 8 5.778Z"
    />
  </svg>
);
export default SvgIncidentStatusActive;
