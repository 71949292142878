import { Icon, IconEnum } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export const DragHandle = (
  props: JSX.IntrinsicElements["div"] & { disabled?: boolean },
): React.ReactElement => (
  <div {...props}>
    <Icon
      id={IconEnum.Draggable}
      className={tcx(
        "text-slate-300 cursor-grab !h-4 !w-4",
        props.disabled && "cursor-not-allowed",
      )}
    />
  </div>
);
