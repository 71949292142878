/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchaseInfo
 */
export interface PurchaseInfo {
    /**
     * Can this plan be bought via a checkout flow?
     * @type {boolean}
     * @memberof PurchaseInfo
     */
    can_self_serve: boolean;
    /**
     * The price in cents for on-call addons this purchase
     * @type {number}
     * @memberof PurchaseInfo
     */
    oncall_addon_price_in_cents?: number;
    /**
     * The price in cents for responders this purchase
     * @type {number}
     * @memberof PurchaseInfo
     */
    responder_price_in_cents?: number;
}

export function PurchaseInfoFromJSON(json: any): PurchaseInfo {
    return PurchaseInfoFromJSONTyped(json, false);
}

export function PurchaseInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_self_serve': json['can_self_serve'],
        'oncall_addon_price_in_cents': !exists(json, 'oncall_addon_price_in_cents') ? undefined : json['oncall_addon_price_in_cents'],
        'responder_price_in_cents': !exists(json, 'responder_price_in_cents') ? undefined : json['responder_price_in_cents'],
    };
}

export function PurchaseInfoToJSON(value?: PurchaseInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_self_serve': value.can_self_serve,
        'oncall_addon_price_in_cents': value.oncall_addon_price_in_cents,
        'responder_price_in_cents': value.responder_price_in_cents,
    };
}

