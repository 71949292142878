/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldCatalogInfo,
    CustomFieldCatalogInfoFromJSON,
    CustomFieldCatalogInfoFromJSONTyped,
    CustomFieldCatalogInfoToJSON,
} from './CustomFieldCatalogInfo';
import {
    CustomFieldOption,
    CustomFieldOptionFromJSON,
    CustomFieldOptionFromJSONTyped,
    CustomFieldOptionToJSON,
} from './CustomFieldOption';

/**
 * 
 * @export
 * @interface CustomFieldTypeInfo
 */
export interface CustomFieldTypeInfo {
    /**
     * 
     * @type {CustomFieldCatalogInfo}
     * @memberof CustomFieldTypeInfo
     */
    catalog_info?: CustomFieldCatalogInfo;
    /**
     * Description of the custom field
     * @type {string}
     * @memberof CustomFieldTypeInfo
     */
    description: string;
    /**
     * Type of custom field
     * @type {string}
     * @memberof CustomFieldTypeInfo
     */
    field_type: CustomFieldTypeInfoFieldTypeEnum;
    /**
     * Unique identifier for the custom field
     * @type {string}
     * @memberof CustomFieldTypeInfo
     */
    id: string;
    /**
     * Human readable name for the custom field
     * @type {string}
     * @memberof CustomFieldTypeInfo
     */
    name: string;
    /**
     * What options are available for this custom field, if this field has options
     * @type {Array<CustomFieldOption>}
     * @memberof CustomFieldTypeInfo
     */
    options: Array<CustomFieldOption>;
    /**
     * Rank to help sort custom fields
     * @type {number}
     * @memberof CustomFieldTypeInfo
     */
    rank: number;
}

/**
* @export
* @enum {string}
*/
export enum CustomFieldTypeInfoFieldTypeEnum {
    SingleSelect = 'single_select',
    MultiSelect = 'multi_select',
    Text = 'text',
    Link = 'link',
    Numeric = 'numeric'
}

export function CustomFieldTypeInfoFromJSON(json: any): CustomFieldTypeInfo {
    return CustomFieldTypeInfoFromJSONTyped(json, false);
}

export function CustomFieldTypeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldTypeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_info': !exists(json, 'catalog_info') ? undefined : CustomFieldCatalogInfoFromJSON(json['catalog_info']),
        'description': json['description'],
        'field_type': json['field_type'],
        'id': json['id'],
        'name': json['name'],
        'options': ((json['options'] as Array<any>).map(CustomFieldOptionFromJSON)),
        'rank': json['rank'],
    };
}

export function CustomFieldTypeInfoToJSON(value?: CustomFieldTypeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_info': CustomFieldCatalogInfoToJSON(value.catalog_info),
        'description': value.description,
        'field_type': value.field_type,
        'id': value.id,
        'name': value.name,
        'options': ((value.options as Array<any>).map(CustomFieldOptionToJSON)),
        'rank': value.rank,
    };
}

