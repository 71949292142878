import { Incident, IncidentModeEnum } from "@incident-io/api";
import { TimestampFormData } from "@incident-shared/incident-forms";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { LifecycleTabs } from "src/components/settings/lifecycle/LifecyclePage";
import { getLocalTimeZone } from "src/utils/datetime";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { useRevalidate } from "../../../../utils/use-revalidate";
import { IncidentTimestampFormElementV2 } from "../IncidentTimestampFormElementV2";
import { useStatusesForIncident } from "../useIncidentCrudResources";
import { validateTimestampInputForDurationsFunc } from "./EditTimestampsModal";

export function EditDurationTimestampsModal({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { statuses } = useStatusesForIncident({ incident });
  const refetchTimeline = useAPIRefetch("incidentTimelineListTimelineItems", {
    incidentId: incident.id,
    timezone: localTimezone,
  });
  const refreshIncidentList = useRevalidate(["incidentsList"]);

  const [isReseted, setReset] = useState(false);

  const incidentTimestamps = incident?.incident_timestamps || [];
  const defaultValues = {};
  incidentTimestamps.forEach((ts) => {
    defaultValues[ts.timestamp.id] = ts.value?.value;
  });

  const formMethods = useForm<TimestampFormData>({
    mode: "onChange",
    defaultValues: { incident_timestamp_values: defaultValues },
  });

  const { setError, trigger, reset, getValues } = formMethods;

  // try to find the given duration
  const { duration_id: durationId } = useParams<{ duration_id: string }>();
  const duration = incident?.duration_metrics?.find(
    (d) => d.duration_metric.id === durationId,
  );

  // Find the timestamps we wish to edit for this duration
  const fromTs = incident?.incident_timestamps?.find((t) => {
    return t.timestamp.id === duration?.duration_metric.from_timestamp_id;
  });
  const toTs = incident?.incident_timestamps?.find((t) => {
    return t.timestamp.id === duration?.duration_metric.to_timestamp_id;
  });

  // Only run validations if the duration has validation enabled and the incident is not retrospective
  const isRetrospective = incident.mode === IncidentModeEnum.Retrospective;
  const shouldRunValidations =
    duration?.duration_metric.validate && !isRetrospective;

  // The two following effects are to enable us to run validations on load
  // The combination of resetting and triggering when the form first loads allows us to have the validations run on all
  // fields (which does not happen on load annoyingly)
  useEffect(() => {
    reset();
    setReset(true);
  }, [reset]);

  useEffect(() => {
    isReseted && trigger();
  }, [trigger, isReseted]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: TimestampFormData) => {
      const payload = Object.entries(formData.incident_timestamp_values).map(
        ([timestamp_id, value]) => {
          return {
            incident_timestamp_id: timestamp_id,
            value: value,
          };
        },
      );
      await apiClient.incidentsUpdateTimestamps({
        id: incident.id,
        updateTimestampsRequestBody: {
          incident_timestamps: payload,
        },
      });

      await refetchTimeline();
    },
    {
      onSuccess: () => {
        refreshIncidentList();
        onClose();
      },
      setError,
    },
  );

  if (!duration) {
    return <OrgAwareNavigate to="/404" replace />;
  }

  if (!fromTs || !toTs) {
    const err = new Error("unable to find matching timestamps");
    return <ErrorModal error={err} onClose={onClose} />;
  }

  const mappedTimestamps = {
    [fromTs.timestamp.id]: fromTs,
    [toTs.timestamp.id]: toTs,
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      analyticsTrackingId="update-duration-timestamps"
      title={`Update timestamps for '${duration.duration_metric.name}'`}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Save"
          saving={saving}
          confirmButtonType="submit"
        />
      }
      genericError={genericError}
    >
      {duration.duration_metric.validate && (
        <Callout className="mb-2" theme={CalloutTheme.Warning}>
          <div>
            {isRetrospective ? (
              <>
                You have enabled validation for this duration metric, however as
                this is a retrospective incident, validation will not be
                applied.
              </>
            ) : (
              <>
                You have enabled validation for this duration metric. This means
                the timestamp <strong>{fromTs.timestamp.name}</strong> must be
                before <strong>{toTs.timestamp.name}</strong>.
              </>
            )}
          </div>
          <br />
          <div>
            Validation can be enabled or disabled in the{" "}
            <a
              href={`/settings/lifecycle?tab=${LifecycleTabs.Timestamps}`}
              className="underline"
            >
              duration metric settings.
            </a>
          </div>
        </Callout>
      )}
      <Form.Helptext>{`Update the timestamps that form this duration metric. These may have been automatically set when changing incident status, feel free to edit them.`}</Form.Helptext>
      <Form.Helptext>
        <>
          {`All times are in your local time zone: `}
          <strong>{getLocalTimeZone(new Date())}.</strong>
        </>
      </Form.Helptext>
      <hr className="my-3" />
      <IncidentTimestampFormElementV2<TimestampFormData>
        validateOnChange
        required={false}
        formMethods={formMethods}
        key={fromTs.timestamp.id}
        name={`incident_timestamp_values.${fromTs.timestamp.id}`}
        timestamp={fromTs.timestamp}
        statuses={statuses}
        customValidate={
          shouldRunValidations
            ? validateTimestampInputForDurationsFunc(
                fromTs.timestamp,
                mappedTimestamps,
                [duration],
                getValues,
              )
            : undefined
        }
      />
      <IncidentTimestampFormElementV2<TimestampFormData>
        formMethods={formMethods}
        required={false}
        validateOnChange
        key={toTs.timestamp.id}
        name={`incident_timestamp_values.${toTs.timestamp.id}`}
        timestamp={toTs.timestamp}
        statuses={statuses}
        customValidate={
          shouldRunValidations
            ? validateTimestampInputForDurationsFunc(
                fromTs.timestamp,
                mappedTimestamps,
                [duration],
                getValues,
              )
            : undefined
        }
      />
    </Form.Modal>
  );
}
