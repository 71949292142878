import { CommaSeparatedConditionGroups } from "@incident-shared/engine/conditions";
import { ContentBox, Icon, IconEnum, PopoverSingleSelect } from "@incident-ui";
import { UseFormReturn } from "react-hook-form";
import { PostIncidentFlow, ScopeNameEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { PostIncidentFlowPreview } from "../../post-incident-flow/PostIncidentFlowPreview";
import {
  PostIncidentExpressionFormData,
  usePostIncidentBranchResult,
} from "./formHooks";

export const PostIncidentFlowBranchEditable = (
  props: {
    flows: PostIncidentFlow[];
    formMethods: UseFormReturn<PostIncidentExpressionFormData>;
    onEdit?: () => void;
  } & (
    | {
        branchType: "if_branch";
        branchIndex: number; // if index is defined, it's an if branch
      }
    | {
        branchType: "else_branch";
        branchIndex?: never; // if index is not defined, it's the else branch
      }
  ),
) => {
  const { onEdit, branchType, formMethods, branchIndex } = props;

  const onDelete = () => {
    formMethods.setValue<`expression.branches`>(
      `expression.branches`,
      formMethods
        .watch<`expression.branches`>(`expression.branches`)
        ?.filter((_, i) => i !== branchIndex),
    );
  };

  return (
    <SettingsListItem
      title={<PostIncidentFlowBranch {...props} />}
      allowShrink
      buttons={
        branchType === "if_branch"
          ? {
              requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
              edit: onEdit ? { onEdit } : undefined,
              delete: {
                onDelete,
                resourceTitle: "Rule",
                deleteConfirmationTitle: "Delete rule?",
                deleteConfirmationContent:
                  "Are you sure you want to delete this rule?",
              },
            }
          : undefined
      }
    />
  );
};

export const PostIncidentFlowBranch = ({
  flows,
  formMethods,
  disabled,
  branchIndex,
  branchType,
}: {
  flows: PostIncidentFlow[];
  formMethods: UseFormReturn<PostIncidentExpressionFormData>;
  disabled?: boolean;
} & (
  | {
      branchType: "if_branch";
      branchIndex: number; // if index is defined, it's an if branch
    }
  | {
      branchType: "else_branch";
      branchIndex?: never; // if index is not defined, it's the else branch
    }
)) => {
  const { isRequired, setIsRequired, flowIDs, setFlowIDs } =
    usePostIncidentBranchResult(
      formMethods,
      branchType === "if_branch"
        ? `expression.branches.${branchIndex}.result`
        : "expression.else_branch.result",
    );

  // only if branches have conditions
  const conditions =
    branchType === "if_branch"
      ? formMethods.watch(`expression.branches.${branchIndex}.condition_groups`)
      : null;

  const prefix =
    branchType === "else_branch"
      ? "Else"
      : branchIndex === 0
      ? "If"
      : "Else if";

  const selectedFlow =
    flowIDs.length > 0
      ? flows.find((flow) => flow.id === flowIDs[0])
      : undefined;
  return (
    <div className="flex flex-col flex-wrap">
      <div
        className={tcx("flex items-center gap-2", {
          "my-2": branchType === "if_branch",
        })}
        key={branchIndex}
      >
        <span className={tcx("align-top text-sm font-medium")}>{prefix}</span>
        {conditions && <CommaSeparatedConditionGroups groups={conditions} />}
      </div>
      <div className={tcx("flex-wrap mb-2")}>
        responders will{" "}
        <div className="inline-flex py-1">
          <PopoverSingleSelect
            options={[
              {
                value: "required",
                label: "be required",
              },
              {
                value: "not_required",
                label: "have the choice",
              },
            ]}
            value={isRequired ? "required" : "not_required"}
            onChange={(val) => setIsRequired(val === "required")}
            disabled={disabled}
            triggerStyle="inline-button"
          />
        </div>{" "}
        to enter the{" "}
        <div className="inline-flex py-1">
          {
            <PopoverSingleSelect
              onChange={(val) => setFlowIDs(val ? [val] : [])}
              options={flows.map((flow) => ({
                value: flow.id,
                label: flow.name,
              }))}
              value={flowIDs[0]}
              disabled={disabled}
              triggerStyle="inline-button"
            />
          }
        </div>{" "}
        post-incident flow, which have the following statuses:
      </div>
      {selectedFlow && (
        <ContentBox className="p-2 my-2 bg-surface-secondary !w-fit">
          <div className="flex items-center gap-2 text-sm">
            <Icon id={IconEnum.Clipboard} />
            {selectedFlow.name}
            <PostIncidentFlowPreview flow={selectedFlow} truncateAfter={3} />
          </div>
        </ContentBox>
      )}
    </div>
  );
};
