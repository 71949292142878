import { IconEnum } from "@incident-ui";
import { CustomFieldFieldTypeEnum as FieldType } from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

const CUSTOM_FIELD_ICONS: { [key in FieldType]: IconEnum } = {
  [FieldType.SingleSelect]: IconEnum.SingleSelect,
  [FieldType.MultiSelect]: IconEnum.MultiSelect,
  [FieldType.Text]: IconEnum.TextInput,
  [FieldType.Link]: IconEnum.Link,
  [FieldType.Numeric]: IconEnum.NumberInput,
};
export const customFieldTypeToIcon = (fieldType: FieldType): IconEnum => {
  return CUSTOM_FIELD_ICONS[fieldType];
};

export const customFieldTypeToTypeName = (fieldType: FieldType): string => {
  switch (fieldType) {
    case FieldType.SingleSelect:
      return "Single-select";
    case FieldType.MultiSelect:
      return "Multi-select";
    case FieldType.Text:
      return "Text";
    case FieldType.Link:
      return "Link";
    case FieldType.Numeric:
      return "Numeric";
    default:
      assertUnreachable(fieldType);
      return "";
  }
};
