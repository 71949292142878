import { useContext } from "react";
import { EscalationPreview } from "src/contexts/ClientContext";

import { EscalationCardContext } from "./EscalationCard";

export function useShouldHighlightStateDiff(
  diff: (lhs: EscalationPreview, rhs: EscalationPreview) => boolean,
) {
  const context = useContext(EscalationCardContext);
  if (context == null) {
    throw new Error("context can only be used within its provider");
  }
  const {
    isGridFirstUpdate,
    showNewness,
    escalation,
    previousEscalationState: previousIncidentState,
  } = context;

  const isDifferent = diff(previousIncidentState, escalation);
  return isGridFirstUpdate || showNewness ? false : isDifferent;
}
