"use client";

import { DateObjectUnits, DateTime, DateTimeUnit } from "luxon";
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useMemoCompare } from "../use-memo-compare";

export type Timestamp = {
  isoDate: string;
  zone: string;
  locale: string;
};

const TimeContext = createContext({
  initialNow: DateTime.utc(),
  zone: "UTC",
  locale: "en-US",
});

export const TimeProvider = ({
  initialNow,
  children,
}: {
  initialNow: Timestamp;
  children: ReactNode;
}): ReactElement => {
  const [{ zone, locale }, setZoneAndLocale] = useState({
    zone: initialNow.zone,
    locale: initialNow.locale,
  });

  useEffect(() => {
    const dtOpts = Intl.DateTimeFormat().resolvedOptions();
    const { locale } = dtOpts;
    // If the browser doesn't supply us a time zone, default to UTC.
    const zone =
      dtOpts.timeZone === "Etc/Unknown" ? "Etc/UTC" : dtOpts.timeZone;

    setZoneAndLocale({ zone, locale });
  }, []);

  return (
    <TimeContext.Provider
      value={{ initialNow: fromServer(initialNow), zone, locale }}
    >
      {children}
    </TimeContext.Provider>
  );
};

// useTimeMaths takes a calculated DateTime object (e.g. `now.startOf("minute")`)
// and memoises it such that the object identity will only change when the time
// value _actually changes_.
//
// Note that the value will still be calculated on each render of this hook, but
// this prevents the re-render of child components if the calculated value
// doesn't _really_ change.
export const useTimeMaths = (t: DateTime): DateTime => {
  return useMemoCompare(t, (prev, next) => prev.equals(next));
};

export const useParseTime = () => {
  const { zone, locale } = useContext(TimeContext);

  const parse = useCallback(
    (date: string) => parseTimestamp({ zone, locale }, date),
    [zone, locale],
  );

  const dateFromObject = useCallback(
    (obj: DateObjectUnits) =>
      DateTime.fromObject(obj, { zone }).setLocale(locale),
    [zone, locale],
  );

  return { parse, dateFromObject };
};

const fromServer = ({ isoDate, zone, locale }: Timestamp) =>
  DateTime.fromISO(isoDate, { zone }).setLocale(locale);

function parseTimestamp(
  { zone, locale }: { zone: string; locale: string },
  date: string,
): DateTime {
  const res =
    typeof date === "string"
      ? DateTime.fromISO(date, { zone })
      : DateTime.fromJSDate(date, { zone });

  return res.setLocale(locale);
}

// Whenever a marker is required for 'now', this is the hook that should be
// used. The initial value should be provided by the server to ensure hydration
// is successful, and useInterval will keep the now value up-to-date every 1s.
export const useNow = (
  intervalMs: number | null,
  roundTo: DateTimeUnit = "second",
) => {
  const { initialNow } = useContext(TimeContext);
  const [now, setNow] = useState(initialNow);
  // This is a hack to avoid causing extra effects
  const nowRef = useRef(initialNow);

  const tick = () => {
    // We don't tick in Storybook, to get consistent renders
    if (process.env.IN_STORYBOOK) return;

    // eslint-disable-next-line no-restricted-syntax
    const newVal = DateTime.now().startOf(roundTo);

    if (newVal.equals(nowRef.current)) return;

    nowRef.current = newVal;
    setNow(newVal);
  };

  // This switches things to the browser's timezone
  useEffect(tick, [roundTo]);

  // And this keeps things fresh
  useInterval(tick, intervalMs);

  return now;
};

const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  savedCallback.current = callback;

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (delay == null) {
      return undefined;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};
