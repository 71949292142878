import type { SVGProps } from "react";
import * as React from "react";
const SvgWarningCircle = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <rect width={14} height={14} fill="#FCD34D" rx={7} />
    <path fill="#040C16" d="M6 2.5h2V8H6V2.5Zm0 9v-2h2v2H6Z" />
  </svg>
);
export default SvgWarningCircle;
