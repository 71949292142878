/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * 
 * @export
 * @interface IncidentTimestampSetByRule
 */
export interface IncidentTimestampSetByRule {
    /**
     * Unique ID of this incident timestamp set by rule
     * @type {string}
     * @memberof IncidentTimestampSetByRule
     */
    id: string;
    /**
     * Unique ID of this incident timestamp that this rule relates to
     * @type {string}
     * @memberof IncidentTimestampSetByRule
     */
    incident_timestamp_id: string;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof IncidentTimestampSetByRule
     */
    set_on_status: IncidentStatus;
    /**
     * The transition that this incident timestamp should be set on.
     * @type {string}
     * @memberof IncidentTimestampSetByRule
     */
    set_on_transition: IncidentTimestampSetByRuleSetOnTransitionEnum;
    /**
     * The visit that this incident timestamp should be set on.
     * @type {string}
     * @memberof IncidentTimestampSetByRule
     */
    set_on_visit: IncidentTimestampSetByRuleSetOnVisitEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentTimestampSetByRuleSetOnTransitionEnum {
    Enter = 'enter',
    Leave = 'leave'
}/**
* @export
* @enum {string}
*/
export enum IncidentTimestampSetByRuleSetOnVisitEnum {
    First = 'first',
    Last = 'last'
}

export function IncidentTimestampSetByRuleFromJSON(json: any): IncidentTimestampSetByRule {
    return IncidentTimestampSetByRuleFromJSONTyped(json, false);
}

export function IncidentTimestampSetByRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampSetByRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_timestamp_id': json['incident_timestamp_id'],
        'set_on_status': IncidentStatusFromJSON(json['set_on_status']),
        'set_on_transition': json['set_on_transition'],
        'set_on_visit': json['set_on_visit'],
    };
}

export function IncidentTimestampSetByRuleToJSON(value?: IncidentTimestampSetByRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_timestamp_id': value.incident_timestamp_id,
        'set_on_status': IncidentStatusToJSON(value.set_on_status),
        'set_on_transition': value.set_on_transition,
        'set_on_visit': value.set_on_visit,
    };
}

