import { Identity, TrialStatusPlanNameEnum } from "@incident-io/api";
import { Badge, BadgeTheme } from "@incident-ui";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { PlanBadge } from "src/components/settings/PlanBadge";
import { useIdentity } from "src/contexts/IdentityContext";

export const UpsellBadge = ({
  className,
  isTeam = false,
  isPro = false,
  isEnterprise = false,
  newUntil,
}: {
  className?: string;
  isTeam?: boolean;
  isPro?: boolean;
  isEnterprise?: boolean;
  newUntil?: Date;
}): React.ReactElement | null => {
  const { identity } = useIdentity();

  if (!identity) {
    return null;
  }

  if (shouldUpsell(identity, isTeam, isPro, isEnterprise)) {
    // This is a pro or enterprise feature, and we're in a trial: show the relevant badge.
    return (
      <PlanBadge
        className={className}
        planName={isTeam ? "Team" : isPro ? "Pro" : "Enterprise"}
      />
    );
  }

  // If there is an expiry time and that date is in the past, don't show the new badge
  if (newUntil && newUntil < new Date()) {
    return null;
  }

  if (newUntil) {
    return (
      <Badge theme={BadgeTheme.Info} className={className}>
        New <Icon size={IconSize.Small} id={IconEnum.New} />
      </Badge>
    );
  }

  return null;
};

const shouldUpsell = (
  identity: Identity,
  isTeam: boolean,
  isPro: boolean,
  isEnterprise: boolean,
): boolean => {
  if (identity.organisation_is_demo || identity.organisation_is_sandbox) {
    return false;
  }

  const planName = identity.trial_status.plan_name;

  // If it's a team feature, only show an upsell if you're on basic or a legacy trial
  if (isTeam) {
    return [
      TrialStatusPlanNameEnum.BasicV2,
      TrialStatusPlanNameEnum.Trial,
    ].includes(planName);
  } else if (isPro) {
    // If it's a pro feature, show an upsell unless you're on Pro, Enterprise or Legacy
    return ![
      TrialStatusPlanNameEnum.ProV1,
      TrialStatusPlanNameEnum.ProV2,
      TrialStatusPlanNameEnum.EnterpriseV1,
      TrialStatusPlanNameEnum.EnterpriseV2,
      TrialStatusPlanNameEnum.Legacy,
    ].includes(planName);
  } else if (isEnterprise) {
    return ![
      TrialStatusPlanNameEnum.EnterpriseV1,
      TrialStatusPlanNameEnum.EnterpriseV2,
      TrialStatusPlanNameEnum.Legacy,
    ].includes(planName);
  }

  return false;
};
