/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIAssistantThreadMessage,
    AIAssistantThreadMessageFromJSON,
    AIAssistantThreadMessageFromJSONTyped,
    AIAssistantThreadMessageToJSON,
} from './AIAssistantThreadMessage';

/**
 * 
 * @export
 * @interface AIListAssistantThreadMessagesResponseBody
 */
export interface AIListAssistantThreadMessagesResponseBody {
    /**
     * 
     * @type {Array<AIAssistantThreadMessage>}
     * @memberof AIListAssistantThreadMessagesResponseBody
     */
    messages: Array<AIAssistantThreadMessage>;
}

export function AIListAssistantThreadMessagesResponseBodyFromJSON(json: any): AIListAssistantThreadMessagesResponseBody {
    return AIListAssistantThreadMessagesResponseBodyFromJSONTyped(json, false);
}

export function AIListAssistantThreadMessagesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIListAssistantThreadMessagesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messages': ((json['messages'] as Array<any>).map(AIAssistantThreadMessageFromJSON)),
    };
}

export function AIListAssistantThreadMessagesResponseBodyToJSON(value?: AIListAssistantThreadMessagesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messages': ((value.messages as Array<any>).map(AIAssistantThreadMessageToJSON)),
    };
}

