import { UpcomingRotaChange } from "@incident-shared/schedules/ScheduleOverview/types";
import { DateTime } from "luxon";
import { tcx } from "src/utils/tailwind-classes";

export type TimelineMarkerContainerProps = {
  upcomingChanges: UpcomingRotaChange[];
  timelineStartAt: DateTime;
  timelineEndAt: DateTime;
  xScale: (d: DateTime) => number;
};

export const TimelineMarkerContainer = ({
  upcomingChanges,
  timelineStartAt,
  timelineEndAt,
  xScale,
}: TimelineMarkerContainerProps) => {
  const multipleFutureChangse =
    upcomingChanges.filter((c) => c.at > timelineEndAt).length > 1;

  return (
    <div className="relative w-full h-full">
      {upcomingChanges.map((c) => {
        if (c.at < timelineStartAt) {
          // This prevents markers just before timelineStartAt from being rendered at the beginning of the timeline
          return null;
        }
        if (c.at > timelineEndAt) {
          return (
            <TimelineMarker
              style={{
                position: "absolute",
                right: 16,
                top: 0,
                bottom: 0,
              }}
              key={c.rotaId + c.at.toISO()}
              withArrow
              multipleChanges={multipleFutureChangse}
            />
          );
        }

        return (
          <>
            {/* A small line to connect the marker and the rest of the TimelineMarkerLine */}
            <div
              style={{
                position: "absolute",
                left: xScale(c.at),
                top: "50%",
                bottom: 0,
              }}
              className={tcx("border-r border-slate-900")}
            />
            <TimelineMarker
              style={{
                position: "absolute",
                left: xScale(c.at),
                transform: "translateX(-50%)",
                top: 0,
                bottom: 0,
              }}
              key={c.rotaId + c.at.toISO()}
            />
          </>
        );
      })}
    </div>
  );
};

type TimelineMarkerProps = {
  withArrow?: boolean;
  multipleChanges?: boolean;
  style?: React.CSSProperties;
};

const TimelineMarker = ({
  style,
  withArrow,
  multipleChanges,
}: TimelineMarkerProps) => {
  return (
    <div style={style} className={"flex items-center whitespace-nowrap z-10"}>
      <span
        className={
          "px-2 py-0.5 bg-surface-invert rounded-xl text-white border-2 border-white font-semibold text-xs"
        }
      >
        Upcoming change{multipleChanges && "s"} {withArrow && "→"}
      </span>
    </div>
  );
};
