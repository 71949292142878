import { CommaSeparatedConditionGroups } from "@incident-shared/engine/conditions";
import { EngineBindingValueBadge } from "@incident-shared/engine/labels";
import {
  ConditionGroup,
  EngineParamBinding,
  EngineScope,
  ReturnsMeta,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { ExpressionFormData } from "../expressionToPayload";

export const ViewIfElseExpression = ({
  expression,
  miniBadges = false,
  scope,
}: {
  expression: ExpressionFormData;
  miniBadges?: boolean;
  scope: EngineScope;
}): React.ReactElement => {
  if (!expression.operations[0].branches?.branches) {
    throw new Error(
      "unreachable: cannot view expressions branches if branches are null",
    );
  }

  return (
    <>
      {expression.operations[0].branches?.branches.map((branch, idx) => {
        return (
          <ViewExpressionBranch
            key={idx}
            conditionGroups={branch.condition_groups}
            result={branch.result}
            prefix={idx === 0 ? "If" : "Else if"}
            returns={expression.returns}
            isElse={false}
            miniBadges={miniBadges}
            scope={scope}
          />
        );
      })}
      {expression.else_branch && (
        <ViewExpressionBranch
          conditionGroups={[]}
          result={expression.else_branch.result}
          returns={expression.returns}
          miniBadges={miniBadges}
          isElse
          scope={scope}
        />
      )}
    </>
  );
};

export const ViewExpressionBranch = ({
  conditionGroups,
  result,
  prefix,
  isElse,
  returns,
  miniBadges,
  className,
  scope,
}: {
  conditionGroups: ConditionGroup[];
  result: EngineParamBinding;
  prefix?: string;
  isElse: boolean;
  returns: ReturnsMeta;
  miniBadges: boolean;
  className?: string;
  scope: EngineScope;
}): React.ReactElement | null => {
  let results = returns?.array ? result.array_value ?? [] : [result.value];
  // Strip empty results
  results = results.filter((result) => {
    if (result == null) return false;
    if (result.literal == null && result.reference == null) {
      return false;
    }
    return true;
  });

  if (results.length === 0) {
    return null;
  }

  return (
    <div className={tcx("px-4 py-3 space-y-2", className)}>
      {!isElse && (
        <div className="flex items-start gap-2">
          {prefix && (
            <div className="text-xs uppercase font-semibold text-content-tertiary tracking-widest w-[85px] text-right flex-shrink-0 mr-2 pt-2">
              {prefix}
            </div>
          )}
          <div className="flex flex-wrap gap-2 text-sm text-slate-700 items-center overflow-hidden">
            <CommaSeparatedConditionGroups
              mini={miniBadges}
              groups={conditionGroups}
            />
          </div>
        </div>
      )}
      <div className="flex items-center gap-2 overflow-hidden">
        <div
          className={tcx(
            "text-xs uppercase font-semibold text-content-tertiary tracking-widest text-right flex-shrink-0 mr-2 w-[85px]",
          )}
        >
          {isElse ? "Else" : "return"}
        </div>
        <ul className={"flex items-center flex-wrap gap-1"}>
          {results.map((r) => {
            if (!r) {
              return null;
            }
            return (
              <EngineBindingValueBadge
                key={r.label}
                value={r}
                resourceType={returns.type}
                mini={miniBadges}
                scope={scope}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};
