/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateRangeRelativeState
 */
export interface DateRangeRelativeState {
    /**
     * Include this interval
     * @type {boolean}
     * @memberof DateRangeRelativeState
     */
    include_this_interval: boolean;
    /**
     * Interval of the range
     * @type {string}
     * @memberof DateRangeRelativeState
     */
    interval: DateRangeRelativeStateIntervalEnum;
    /**
     * Number of intervals
     * @type {number}
     * @memberof DateRangeRelativeState
     */
    number_of_intervals: number;
}

/**
* @export
* @enum {string}
*/
export enum DateRangeRelativeStateIntervalEnum {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
    Quarters = 'quarters'
}

export function DateRangeRelativeStateFromJSON(json: any): DateRangeRelativeState {
    return DateRangeRelativeStateFromJSONTyped(json, false);
}

export function DateRangeRelativeStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRangeRelativeState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'include_this_interval': json['include_this_interval'],
        'interval': json['interval'],
        'number_of_intervals': json['number_of_intervals'],
    };
}

export function DateRangeRelativeStateToJSON(value?: DateRangeRelativeState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'include_this_interval': value.include_this_interval,
        'interval': value.interval,
        'number_of_intervals': value.number_of_intervals,
    };
}

