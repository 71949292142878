/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssueTrackerIssueSyncFailure
 */
export interface IssueTrackerIssueSyncFailure {
    /**
     * When the action was created
     * @type {Date}
     * @memberof IssueTrackerIssueSyncFailure
     */
    created_at: Date;
    /**
     * The ID of the follow-up that the sync failure relates to, if relevant.
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    followup_id?: string;
    /**
     * If this run was against a specific follow-up, this is the title of that follow-up
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    followup_title?: string;
    /**
     * The ID of the sync failure
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    id: string;
    /**
     * Unique internal ID of the incident that this debrief is associated with
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    incident_id?: string;
    /**
     * If this run was against a specific incident, this is the reference of that incident
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    incident_reference?: string;
    /**
     * Whether this sync failure is transient and will be retried by us
     * @type {boolean}
     * @memberof IssueTrackerIssueSyncFailure
     */
    is_transient: boolean;
    /**
     * The issue tracker provider that this sync failure relates to.
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    provider: string;
    /**
     * The raw error response body that is returned from when the sync fails.
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    raw_error_json: string;
    /**
     * The ID of the issue template which was used to build the issue
     * @type {string}
     * @memberof IssueTrackerIssueSyncFailure
     */
    template_id?: string;
}

export function IssueTrackerIssueSyncFailureFromJSON(json: any): IssueTrackerIssueSyncFailure {
    return IssueTrackerIssueSyncFailureFromJSONTyped(json, false);
}

export function IssueTrackerIssueSyncFailureFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackerIssueSyncFailure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'followup_id': !exists(json, 'followup_id') ? undefined : json['followup_id'],
        'followup_title': !exists(json, 'followup_title') ? undefined : json['followup_title'],
        'id': json['id'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'incident_reference': !exists(json, 'incident_reference') ? undefined : json['incident_reference'],
        'is_transient': json['is_transient'],
        'provider': json['provider'],
        'raw_error_json': json['raw_error_json'],
        'template_id': !exists(json, 'template_id') ? undefined : json['template_id'],
    };
}

export function IssueTrackerIssueSyncFailureToJSON(value?: IssueTrackerIssueSyncFailure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'followup_id': value.followup_id,
        'followup_title': value.followup_title,
        'id': value.id,
        'incident_id': value.incident_id,
        'incident_reference': value.incident_reference,
        'is_transient': value.is_transient,
        'provider': value.provider,
        'raw_error_json': value.raw_error_json,
        'template_id': value.template_id,
    };
}

