import { SelectOption } from "@incident-ui/Select/types";
import { IncidentType } from "src/contexts/ClientContext";

const IncidentTypeNotYetKnownValue = "not_yet_known_incident_type";
const IncidentTypeNotYetKnownDisplayText = "I don't know yet";
export const IncidentTypeNotYetKnownDescription =
  "We'll create a triage incident where you can investigate the " +
  "issue and pull in other team members to help. When you've confirmed the problem, " +
  "you can accept the incident and continue investigating, or if not you can decline it.";

export function buildIncidentTypeOptions({
  incidentTypes,
  selectedIncidentTypeID,
  includeNotYetKnownOption,
}: {
  incidentTypes: IncidentType[];
  selectedIncidentTypeID: string | undefined;
  includeNotYetKnownOption: boolean;
}): {
  incidentTypeOptions: SelectOption[];
  selectedIncidentType: IncidentType | undefined;
  selectedIncidentTypeDescription: string | undefined;
  hasSelectedIncidentTypeDontKnow: boolean;
} {
  // Build the options
  const incidentTypeOptions: SelectOption[] = incidentTypes.map((it) => ({
    value: it.id,
    label: it.name,
    sort_key: it.rank.toString().padStart(4),
  }));
  if (includeNotYetKnownOption) {
    incidentTypeOptions.push({
      value: IncidentTypeNotYetKnownValue,
      label: IncidentTypeNotYetKnownDisplayText,
      sort_key: "0000",
    });
  }

  // Find the selected option
  const selectedIncidentType = incidentTypes.find(
    (it) => it.id === selectedIncidentTypeID,
  );

  let hasSelectedIncidentTypeDontKnow = false;
  if (selectedIncidentTypeID === IncidentTypeNotYetKnownValue) {
    hasSelectedIncidentTypeDontKnow = true;
  }

  let selectedIncidentTypeDescription = selectedIncidentType?.description;
  if (hasSelectedIncidentTypeDontKnow) {
    selectedIncidentTypeDescription = IncidentTypeNotYetKnownDescription;
  }

  return {
    incidentTypeOptions,
    selectedIncidentType,
    hasSelectedIncidentTypeDontKnow,
    selectedIncidentTypeDescription,
  };
}
