/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsGitLabInstallRequestBody
 */
export interface IntegrationsGitLabInstallRequestBody {
    /**
     * The URL of the GitLab instance
     * @type {string}
     * @memberof IntegrationsGitLabInstallRequestBody
     */
    base_url: string;
    /**
     * The personal access token to use for the GitLab integration
     * @type {string}
     * @memberof IntegrationsGitLabInstallRequestBody
     */
    token: string;
}

export function IntegrationsGitLabInstallRequestBodyFromJSON(json: any): IntegrationsGitLabInstallRequestBody {
    return IntegrationsGitLabInstallRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsGitLabInstallRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGitLabInstallRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_url': json['base_url'],
        'token': json['token'],
    };
}

export function IntegrationsGitLabInstallRequestBodyToJSON(value?: IntegrationsGitLabInstallRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_url': value.base_url,
        'token': value.token,
    };
}

