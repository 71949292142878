import { customComponents } from "@incident-ui/Select/customComponents";
import { customStyles } from "@incident-ui/Select/customStyles";
import {
  HydratedValuesCache,
  SelectOption,
  SelectOptionGroup,
  SelectOptions,
  SelectRefType,
  SharedDynamicSelectProps,
} from "@incident-ui/Select/types";
import { useAutoAdjustingMenuPlacement } from "@incident-ui/Select/useAutoAdjustingMenuPlacement";
import { captureException } from "@sentry/react";
import React, { ForwardedRef, useState } from "react";
import { SingleValue } from "react-select";
import ReactAsyncSelect from "react-select/async";

import { SelectWrapper } from "./SelectWrapper";

export type DynamicSingleSelectProps = SharedDynamicSelectProps & {
  value: string;
  onChange: (val: string | null) => void;
  hydrateOptions: (val: string) => Promise<SelectOptions>;
  onValueChange?: (val: string | null) => void;
};

const useHydratedValueCacheSingle = (
  hydrateOptions: (val: string) => Promise<SelectOptions>,
): {
  hydrating: boolean;
  cacheHydratedValues: (hydratedValues: SelectOptions) => void;
  getHydratedValue: (value: string) => SelectOption | undefined;
} => {
  const [hydrating, setHydrating] = useState<boolean>(false);
  // this is a hack so we don't have to hit the API to hydrate a value which we already
  // know about
  const [hydratedValuesCache, setHydratedValuesCache] =
    useState<HydratedValuesCache>({});

  const cacheHydratedValues = (hydratedValues: SelectOptions) => {
    const newlyFoundValues: HydratedValuesCache = {};
    hydratedValues.forEach((opt: SelectOption | SelectOptionGroup) => {
      if ("options" in opt) {
        opt.options.forEach((opt: SelectOption) => {
          newlyFoundValues[opt.value] = opt;
        });
      } else {
        newlyFoundValues[opt.value] = opt;
      }
    });

    setHydratedValuesCache((current) => ({
      ...current,
      ...newlyFoundValues,
    }));
  };

  // Try to hydrate the values from our cache. If they're not there, set loading
  // to true and hydrate the options. This should normally only happen when the
  // component is initially loaded, if it's given values that it didn't happen
  // to get back from its initial loadOptions call.
  const getHydratedValue = (value?: string) => {
    // It's possible our value may not be set. If so, we don't want to try
    // hydrating it- that would be sad, as we'll repeatedly hydrate nothing.
    if (!value) {
      return undefined;
    }

    if (hydratedValuesCache[value]) {
      return hydratedValuesCache[value];
    } else {
      // if we're not already hydrating values, then lets hydrate the missing values.
      if (!hydrating) {
        setHydrating(true);
        hydrateOptions(value).then((hydrated) => {
          cacheHydratedValues(hydrated);
          setHydrating(false);
        });
      }
    }
    return undefined;
  };

  return {
    hydrating,
    cacheHydratedValues,
    getHydratedValue,
  };
};

export const DynamicSingleSelect = React.forwardRef<
  SelectRefType<false>,
  DynamicSingleSelectProps
>(
  (
    {
      loadOptions: loadOptionsCallback,
      hydrateOptions,
      icon,
      id,
      value,
      isLoading,
      onChange: onValueChange,
      ...rest
    }: DynamicSingleSelectProps,
    ref: ForwardedRef<SelectRefType<false>>,
  ): React.ReactElement => {
    // the outside world wants to deals with values that are strings, but react select expects
    // SelectOptions as values. So we have to do some hacking to make the outside world not
    // know about this sadness.
    const { hydrating, cacheHydratedValues, getHydratedValue } =
      useHydratedValueCacheSingle(hydrateOptions);
    async function loadOptions(inputValue: string) {
      try {
        const loadedOptions = await loadOptionsCallback(inputValue);
        cacheHydratedValues(loadedOptions);
        return loadedOptions;
      } catch (e) {
        // The default here is for react-select to ignore this error, which is
        // quite bad.
        captureException(e);
        console.error(e);
        throw e;
      }
    }

    const onChange = (selectedOption: SelectOption) => {
      onValueChange(selectedOption ? selectedOption.value : null);
    };

    return (
      <DynamicSingleSelectWithObj
        value={getHydratedValue(value)}
        // @ts-expect-error I think this is probably ok?
        onChange={onChange}
        icon={icon}
        id={id}
        innerRef={ref}
        loadOptions={loadOptions}
        isLoading={isLoading || hydrating}
        {...rest}
      />
    );
  },
);

DynamicSingleSelect.displayName = "DynamicSingleSelect";

export type DynamicSingleSelectWithObjProps = SharedDynamicSelectProps & {
  value?: SelectOption;
  onChange: (val: SingleValue<SelectOption | null>) => void;
  autoFocus?: boolean;
  innerRef?: ForwardedRef<SelectRefType<false>>;
};

export const DynamicSingleSelectWithObj = ({
  loadOptions,
  icon,
  optionIcon,
  optionColor,
  id,
  value,
  insetSuffixNode,
  onChange,
  invalid = false,
  autoFocus,
  innerRef: _innerRef,
  className,
  isDisabled,
  ...rest
}: DynamicSingleSelectWithObjProps) => {
  // We need to do this so that we can render the options appropriately
  // on the screen e.g. when we have no space below the input, we
  // render the options above the input.
  //
  // internalRef ends up being the same as _innerRef - we take innerRef
  // as a prop to avoid an error being raised by React.
  const [menuPlacement, internalRef] = useAutoAdjustingMenuPlacement();
  const isDisplayingPill = !!optionIcon && !!optionColor;
  return (
    <SelectWrapper
      suffixNode={insetSuffixNode}
      className={className}
      disabled={isDisabled}
    >
      <ReactAsyncSelect<SelectOption, false>
        value={value}
        inputId={id}
        onChange={onChange}
        loadOptions={loadOptions}
        isMulti={false}
        defaultOptions
        isClearable
        closeMenuOnSelect
        styles={customStyles(invalid, !!insetSuffixNode, isDisplayingPill)}
        components={customComponents({ icon, optionIcon, optionColor })}
        menuPortalTarget={document.body}
        // @ts-expect-error this is fine, just about the react-select types
        ref={internalRef}
        menuPlacement={menuPlacement}
        autoFocus={autoFocus}
        // Inputs get priority over things with tabIndex=0, since inputs are
        // generally more important than other things on screen.
        tabIndex={1}
        isDisabled={isDisabled}
        {...rest}
      />
    </SelectWrapper>
  );
};

DynamicSingleSelectWithObj.displayName = "DynamicSingleSelectWithObj";
