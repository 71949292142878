/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeatCount
 */
export interface SeatCount {
    /**
     * A human-friendly name for this seat type
     * @type {string}
     * @memberof SeatCount
     */
    label: string;
    /**
     * The number of committed seats on this plan. Undefined means 'unlimited'
     * @type {number}
     * @memberof SeatCount
     */
    limit?: number;
    /**
     * The order of this seat type to display in the UI
     * @type {number}
     * @memberof SeatCount
     */
    rank: number;
    /**
     * Extra information about this seat type
     * @type {string}
     * @memberof SeatCount
     */
    seat_description: string;
    /**
     * The type of seat
     * @type {string}
     * @memberof SeatCount
     */
    seat_type: SeatCountSeatTypeEnum;
    /**
     * The number of seats of this type
     * @type {number}
     * @memberof SeatCount
     */
    used?: number;
}

/**
* @export
* @enum {string}
*/
export enum SeatCountSeatTypeEnum {
    OnCall = 'on_call',
    Responder = 'responder',
    Viewer = 'viewer',
    OnCallResponder = 'on_call_responder'
}

export function SeatCountFromJSON(json: any): SeatCount {
    return SeatCountFromJSONTyped(json, false);
}

export function SeatCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeatCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'rank': json['rank'],
        'seat_description': json['seat_description'],
        'seat_type': json['seat_type'],
        'used': !exists(json, 'used') ? undefined : json['used'],
    };
}

export function SeatCountToJSON(value?: SeatCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'limit': value.limit,
        'rank': value.rank,
        'seat_description': value.seat_description,
        'seat_type': value.seat_type,
        'used': value.used,
    };
}

