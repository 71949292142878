import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { captureMessage } from "@sentry/react";
import React, { useState } from "react";
import {
  ScopeNameEnum,
  StatusPage,
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
  StatusPageComponentImpact,
  StatusPageIncident,
  StatusPageStructure,
  StatusPageStructureComponent,
  StatusPageStructureGroup,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import {
  currentWorstImpactsForPage,
  statusIsWorseThan,
} from "../../common/utils/utils";
import { StatusPageComponentStatusIcon } from "../../incidents/view/StatusIcons";

export const ComponentsList = ({
  page,
  structure,
  incidents,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  incidents: StatusPageIncident[];
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  const worstImpacts = currentWorstImpactsForPage(incidents);

  return (
    <>
      <div className="flex items-center mb-2">
        <GatedButton
          href={`/status-pages/${page.id}/settings/components`}
          theme={ButtonTheme.Naked}
          analyticsTrackingId={"status-page-edit-components"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          title="edit"
          disabled={!hasScope(ScopeNameEnum.StatusPagesConfigure)}
          disabledTooltipContent={
            "You do not have permission to configure this public status page"
          }
        >
          <Heading level={3} size="small" className="mr-2 !font-medium">
            Components
          </Heading>
          <Icon
            id={IconEnum.Edit}
            size={IconSize.Medium}
            className="-ml-1 opacity-0 group-hover/edit-components:opacity-100 transition"
          />
        </GatedButton>
      </div>

      <StackedList className="text-sm">
        {(structure?.items || []).map((item, idx) => {
          if (item.component) {
            return (
              <ComponentRow
                key={idx}
                component={item.component}
                worstImpacts={worstImpacts}
              />
            );
          } else if (item.group) {
            return (
              <GroupRow
                key={idx}
                group={item.group}
                worstImpacts={worstImpacts}
              />
            );
          } else {
            captureMessage(
              "status page structure item with neither component nor group",
              { extra: { item, status_page_id: page.id } },
            );
            return null;
          }
        })}
      </StackedList>
    </>
  );
};

const GroupRow = ({
  group,
  worstImpacts,
}: {
  group: StatusPageStructureGroup;
  worstImpacts: StatusPageComponentImpact[];
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const worstStatus = group.components.reduce((status, component) => {
    const impact = worstImpacts.find(
      (impact) => impact.component_id === component.component_id,
    );

    if (!impact) {
      return status;
    }

    return statusIsWorseThan(
      status,
      impact.status as unknown as ComponentStatusEnum,
    )
      ? (impact.status as unknown as ComponentStatusEnum)
      : status;
  }, ComponentStatusEnum.Operational);

  return (
    <li className={tcx("pr-2", isOpen ? "pl-3" : "pl-2")}>
      <Button
        className="w-full items-center !transition-none mt-3 mb-0"
        onClick={() => setIsOpen(!isOpen)}
        analyticsTrackingId={null}
        theme={ButtonTheme.Naked}
      >
        {isOpen ? null : (
          <StatusPageComponentStatusIcon
            status={worstStatus}
            className="flex-none"
          />
        )}
        <div
          className={tcx(
            "shrink truncate",
            isOpen ? "text-slate-600" : "text-content-primary",
          )}
        >
          {group.name}
        </div>
        {group.description && (
          <Tooltip content={group.description} buttonClassName="flex-none" />
        )}
        <Icon
          id={isOpen ? IconEnum.Collapse : IconEnum.Expand}
          size={IconSize.Medium}
          className="ml-1 group-hover:text-content-primary transition flex-none"
        />
      </Button>
      <ul className={tcx("pb-2", isOpen && "pt-1")}>
        {isOpen
          ? (group.components || []).map((component) => (
              <ComponentRow
                key={component.component_id}
                component={component}
                worstImpacts={worstImpacts}
                isSmall
              />
            ))
          : null}
      </ul>
    </li>
  );
};

const ComponentRow = ({
  component,
  worstImpacts,
  isSmall = false,
}: {
  component: StatusPageStructureComponent;
  worstImpacts: StatusPageComponentImpact[];
  isSmall?: boolean;
}): React.ReactElement => {
  const currentStatus = worstImpacts.find(
    (impact) => impact.component_id === component.component_id,
  )?.status as ComponentStatusEnum | undefined;

  return (
    <li
      className={tcx(
        "text-slate-900 flex items-center px-2 space-x-1 w-full",
        isSmall ? "py-1" : "py-3",
      )}
    >
      <StatusPageComponentStatusIcon
        status={currentStatus || ComponentStatusEnum.Operational}
        className="flex-none"
      />
      <div className="shrink truncate">{component.name}</div>
      {component.description && (
        <Tooltip content={component.description} buttonClassName="flex-none" />
      )}
    </li>
  );
};
