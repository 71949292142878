import { Input } from "@incident-ui";
import { InputProps } from "@incident-ui/Input/Input";
import { ChangeEvent } from "react";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const InputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, InputProps> & {
    onBlurCallback?: () => void;
    onValueChange?: (val: string) => void;
    highlightErrors?: boolean;
  },
): React.ReactElement => {
  const { highlightErrors, onBlurCallback, onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const { field, fieldState } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <Input
        id={name}
        {...field}
        {...inputProps}
        invalid={highlightErrors && fieldState.error !== undefined}
        onBlur={() => {
          onBlurCallback && onBlurCallback();

          return field.onBlur();
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          field.onChange(e);

          onValueChange && onValueChange(e.target.value);
        }}
      />
    </FormInputWrapper>
  );
};
