import type { SVGProps } from "react";
import * as React from "react";
const SvgArrowUpCircle = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm3.03 7.78a.748.748 0 0 1-1.06 0l-1.22-1.22v4.689a.75.75 0 0 1-1.5 0V8.561l-1.22 1.22A.75.75 0 1 1 6.969 8.72l2.5-2.5a.75.75 0 0 1 1.061 0l2.5 2.5a.75.75 0 0 1 0 1.061V9.78Z"
    />
  </svg>
);
export default SvgArrowUpCircle;
