/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemTemplate,
    PostmortemTemplateFromJSON,
    PostmortemTemplateFromJSONTyped,
    PostmortemTemplateToJSON,
} from './PostmortemTemplate';

/**
 * 
 * @export
 * @interface PostmortemsUpdateTemplateResponseBody
 */
export interface PostmortemsUpdateTemplateResponseBody {
    /**
     * 
     * @type {PostmortemTemplate}
     * @memberof PostmortemsUpdateTemplateResponseBody
     */
    postmortem_template: PostmortemTemplate;
}

export function PostmortemsUpdateTemplateResponseBodyFromJSON(json: any): PostmortemsUpdateTemplateResponseBody {
    return PostmortemsUpdateTemplateResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsUpdateTemplateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsUpdateTemplateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postmortem_template': PostmortemTemplateFromJSON(json['postmortem_template']),
    };
}

export function PostmortemsUpdateTemplateResponseBodyToJSON(value?: PostmortemsUpdateTemplateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postmortem_template': PostmortemTemplateToJSON(value.postmortem_template),
    };
}

