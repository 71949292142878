import type { SVGProps } from "react";
import * as React from "react";
const SvgTrendDown = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.5 11.438V8.25a.75.75 0 0 1 1.5 0v5a.75.75 0 0 1-.75.75h-5a.75.75 0 0 1 0-1.5h3.19l-4.69-4.69-3.116 3.116a1.252 1.252 0 0 1-1.768 0L2.22 7.28a.75.75 0 1 1 1.061-1.061l3.47 3.47 3.116-3.116a1.252 1.252 0 0 1 1.768 0l4.865 4.865Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrendDown;
