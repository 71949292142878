import type { SVGProps } from "react";
import * as React from "react";
const SvgNumberBox1Filled = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#212121"
      fillRule="evenodd"
      d="M5.278 2.222h9.444a3.058 3.058 0 0 1 3.056 3.056v9.444a3.058 3.058 0 0 1-3.056 3.056H5.278a3.058 3.058 0 0 1-3.056-3.056V5.278a3.058 3.058 0 0 1 3.056-3.056Zm4.576 10.643c0 .444.297.737.732.737.434 0 .727-.293.727-.737V7.172c0-.508-.312-.825-.835-.825-.302 0-.537.044-.878.278l-1.397.967c-.23.16-.317.327-.317.551 0 .313.22.528.517.528a.6.6 0 0 0 .391-.132l1.03-.718h.03v5.044Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNumberBox1Filled;
