/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkflowRun,
    WorkflowRunFromJSON,
    WorkflowRunFromJSONTyped,
    WorkflowRunToJSON,
} from './WorkflowRun';

/**
 * 
 * @export
 * @interface WorkflowsListWorkflowRunsResponseBody
 */
export interface WorkflowsListWorkflowRunsResponseBody {
    /**
     * 
     * @type {Array<WorkflowRun>}
     * @memberof WorkflowsListWorkflowRunsResponseBody
     */
    workflow_runs: Array<WorkflowRun>;
}

export function WorkflowsListWorkflowRunsResponseBodyFromJSON(json: any): WorkflowsListWorkflowRunsResponseBody {
    return WorkflowsListWorkflowRunsResponseBodyFromJSONTyped(json, false);
}

export function WorkflowsListWorkflowRunsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsListWorkflowRunsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflow_runs': ((json['workflow_runs'] as Array<any>).map(WorkflowRunFromJSON)),
    };
}

export function WorkflowsListWorkflowRunsResponseBodyToJSON(value?: WorkflowsListWorkflowRunsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflow_runs': ((value.workflow_runs as Array<any>).map(WorkflowRunToJSON)),
    };
}

