import { Icon, IconEnum, Loader, StackedList, Tooltip } from "@incident-ui";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  FieldArrayPath,
  FieldArrayWithId,
  FieldPath,
  FieldPathValue,
  FieldValues,
  Path,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const DragHandle = (
  props: JSX.IntrinsicElements["div"],
): React.ReactElement => (
  <div {...props}>
    <Icon id={IconEnum.Draggable} className="text-slate-300 h-4 w-4" />
  </div>
);

export type SortableItemProps<
  TFormType extends FieldValues,
  TArrayPath extends FieldArrayPath<TFormType>,
> = {
  id: string;
  key: string;
  item: FieldPathValue<
    TFormType,
    `${TArrayPath}.0` extends FieldPath<TFormType> ? `${TArrayPath}.0` : never
  >;
  index: number;
  deleteItem: () => void;
  dragHandle: React.ReactNode;
};

type SortableListProps<
  TFormType extends FieldValues,
  TArrayPath extends FieldArrayPath<TFormType>,
> = {
  canEdit: boolean;
  renderItem: (
    props: SortableItemProps<TFormType, TArrayPath>,
  ) => React.ReactElement;
  headerRow?: React.ReactElement;
  className?: string;
  saving?: boolean;
  offset?: number;
  extraWide?: boolean;
  isDisabled?: (
    item: FieldPathValue<
      TFormType,
      `${TArrayPath}.0` extends FieldPath<TFormType> ? `${TArrayPath}.0` : never
    >,
  ) => string | undefined;
  onValueChange?: (
    fields: FieldArrayWithId<TFormType, TArrayPath, "id">[],
  ) => void;
};

export const SortableListV2 = <
  TFormType extends FieldValues,
  TArrayPath extends FieldArrayPath<TFormType>,
>(
  props: InputElementProps<
    TFormType,
    SortableListProps<TFormType, TArrayPath>
  > & {
    name: TArrayPath;
    arrayMethods: UseFieldArrayReturn<TFormType, TArrayPath>;
  },
): React.ReactElement => {
  const {
    canEdit,
    renderItem,
    headerRow,
    className,
    saving,
    extraWide,
    name,
    formMethods,
    isDisabled,
    arrayMethods,
  } = props;

  const { watch } = useFormContext<TFormType>();
  const values = watch(name);
  const { onValueChange, ...rest } = props;
  const { wrapperProps } = parseProps(rest);

  // Note source and destination exist so we _could_ drag between lists at some
  // point in future, but for now they're the same thing.
  const onDragEnd = (result: DropResult) => {
    // Only listen for drop events (ignore things like 'CANCEL' events, where
    // the user just cancelled/aborted)
    if (result.reason !== "DROP") {
      return;
    }

    // If we dropped it outside the list, no-op
    if (!result.destination) {
      return;
    }

    arrayMethods.move(result.source.index, result.destination.index);

    onValueChange && onValueChange(arrayMethods.fields);
  };

  const itemsToRender = arrayMethods.fields;

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <div className={tcx("relative", extraWide && "w-3/4")}>
        {/* Show a loader overlay while we're making changes */}
        {saving && (
          <div className="absolute flex items-center justify-center w-full h-full z-10 bg-surface-tertiary/50">
            <Loader />
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="items">
            {(provided) => {
              return (
                <StackedList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={tcx(className, extraWide && "!w-full")}
                >
                  {headerRow ? headerRow : null}
                  {itemsToRender.map((item, index) => {
                    const disabledText = isDisabled
                      ? isDisabled(values[index])
                      : null;

                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                        isDragDisabled={!canEdit || disabledText != null}
                      >
                        {(provided) => {
                          return (
                            <Tooltip
                              delayDuration={50}
                              content={disabledText ? disabledText : undefined}
                            >
                              <li
                                className={tcx({
                                  "bg-surface-secondary cursor-not-allowed":
                                    !!disabledText,
                                })}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                {renderItem({
                                  key: item.id,
                                  id: item.id,
                                  item: values[index],
                                  index,
                                  deleteItem: () => {
                                    arrayMethods.remove(index);
                                    formMethods.clearErrors(name);
                                  },
                                  dragHandle:
                                    canEdit && disabledText == null ? (
                                      <DragHandle
                                        className={"px-2"}
                                        {...provided.dragHandleProps}
                                      />
                                    ) : (
                                      <div className="w-6" />
                                    ),
                                })}
                              </li>
                            </Tooltip>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </StackedList>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
    </FormInputWrapper>
  );
};
