import type { SVGProps } from "react";
import * as React from "react";
const SvgGift = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 10.5v4.75A2.752 2.752 0 0 0 6.75 18h2.5v-7.5H4ZM10.75 10.5V18h2.5A2.752 2.752 0 0 0 16 15.25V10.5h-5.25ZM16.25 5.5h-.462c.135-.307.212-.644.212-1C16 3.122 14.879 2 13.5 2c-1.761 0-2.864 1.231-3.5 2.339C9.364 3.232 8.261 2 6.5 2A2.503 2.503 0 0 0 4 4.5c0 .356.077.693.212 1H3.75C2.785 5.5 2 6.276 2 7.25S2.785 9 3.75 9h12.5a1.75 1.75 0 1 0 0-3.5Zm-2.75-2a1.001 1.001 0 0 1 0 2h-2.419c.405-.86 1.176-2 2.419-2Zm-8 1c0-.551.448-1 1-1 1.234 0 2.007 1.14 2.415 2H6.5c-.552 0-1-.449-1-1Z"
    />
  </svg>
);
export default SvgGift;
