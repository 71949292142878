/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DecisionTree,
    DecisionTreeFromJSON,
    DecisionTreeFromJSONTyped,
    DecisionTreeToJSON,
} from './DecisionTree';

/**
 * 
 * @export
 * @interface DecisionFlow
 */
export interface DecisionFlow {
    /**
     * When the decision flow was created
     * @type {Date}
     * @memberof DecisionFlow
     */
    created_at: Date;
    /**
     * A short description for this decision flow
     * @type {string}
     * @memberof DecisionFlow
     */
    description: string;
    /**
     * A unique ID for this decision flow
     * @type {string}
     * @memberof DecisionFlow
     */
    id: string;
    /**
     * Whether this decision flow can be used in active incidents
     * @type {boolean}
     * @memberof DecisionFlow
     */
    is_live: boolean;
    /**
     * A human readable name for the decision flow (displayed at the top of the modal)
     * @type {string}
     * @memberof DecisionFlow
     */
    name: string;
    /**
     * ID of the organisation this belongs to
     * @type {string}
     * @memberof DecisionFlow
     */
    organisation_id: string;
    /**
     * 
     * @type {DecisionTree}
     * @memberof DecisionFlow
     */
    tree: DecisionTree;
    /**
     * When the decision flow was last updated
     * @type {Date}
     * @memberof DecisionFlow
     */
    updated_at: Date;
}

export function DecisionFlowFromJSON(json: any): DecisionFlow {
    return DecisionFlowFromJSONTyped(json, false);
}

export function DecisionFlowFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionFlow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'description': json['description'],
        'id': json['id'],
        'is_live': json['is_live'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'tree': DecisionTreeFromJSON(json['tree']),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function DecisionFlowToJSON(value?: DecisionFlow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'is_live': value.is_live,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'tree': DecisionTreeToJSON(value.tree),
        'updated_at': (value.updated_at.toISOString()),
    };
}

