import { tcx } from "src/utils/tailwind-classes";

export type TimelineMarkerLineProps = {
  dashed: boolean;
  style?: React.CSSProperties;
};

export const TimelineMarkerLine = ({
  dashed,
  style,
}: TimelineMarkerLineProps) => {
  return (
    <div
      style={style}
      className={tcx(
        "absolute w-[1px] h-full border-r-[1px]",
        // We set the z-index to 9 for dashed lines to ensure they are behind the solid lines.
        // This is easier than only showing the line for the relevant rota rows.
        { "border-dashed border-slate-300 z-10": dashed },
        { "border-slate-900 z-20": !dashed },
      )}
    />
  );
};
