import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  SearchBar,
} from "@incident-ui";

import { useAttributesDrawer as useAlertAttributesDrawer } from "../attributes/AlertAttributesCreateEditDrawer";
import { usePrioritiesDrawer } from "../priorities/PrioritiesCreateEditDrawer";
import {
  AlertsConfigurationNodeData,
  AlertsConfigurationNodeType,
} from "./AlertsConfigurationNode";

export const AlertSourcesHeader = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (string) => void;
}) => {
  const { setOpen: setShowPrioritiesDrawer } = usePrioritiesDrawer();
  const { setOpen: setShowAttributesDrawer } = useAlertAttributesDrawer();

  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Icon id={IconEnum.MergedArrow} size={IconSize.Small} />
          <div className="pl-2 text-base-bold">Sources</div>
        </div>
        <div className="flex flex-row space-x-2">
          <SearchBar
            id="search"
            value={search}
            onChange={setSearch}
            className="w-full"
            inputClassName={"h-7 rounded-md bg-surface-primary shadow-sm"}
            placeholder="Search"
            iconProps={{ size: IconSize.Small }}
          />
          <DropdownMenu
            menuClassName={"w-[140px]"}
            side={"bottom"}
            align={"end"}
            scroll={false}
            triggerButton={
              <Button
                theme={ButtonTheme.Secondary}
                analyticsTrackingId={"alert-source-config"}
                size={BadgeSize.Medium}
                icon={IconEnum.Cog}
                iconProps={{ size: IconSize.Small }}
                title=""
              />
            }
          >
            <DropdownMenuItem
              tooltipContent={""}
              onSelect={() => {
                setShowAttributesDrawer(true);
              }}
              analyticsTrackingId={"alert-source-config-attributes"}
              icon={IconEnum.Tag}
              label={"Attributes"}
            >
              Attributes
            </DropdownMenuItem>
            <DropdownMenuItem
              tooltipContent={""}
              analyticsTrackingId={"alert-source-config-priorities"}
              onSelect={() => {
                setShowPrioritiesDrawer(true);
              }}
              label="Priorities"
              icon={IconEnum.Priority}
            >
              Priorities
            </DropdownMenuItem>
          </DropdownMenu>
          <GatedButton
            theme={ButtonTheme.Secondary}
            href={"/alerts/sources/create"}
            analyticsTrackingId={"add-alert-source"}
            requiredScope={ScopeNameEnum.AlertSourceCreate}
            size={BadgeSize.Medium}
            icon={IconEnum.Add}
            iconProps={{ size: IconSize.Small }}
            title=""
          />
        </div>
      </div>
      <div className="border-t border-stroke-primary w-full mt-4" />
    </div>
  );
};

export const makeSourceHeaderNodeData = (
  searchSources: string,
  setSearchSources: (string) => void,
): AlertsConfigurationNodeData => {
  return {
    label: "Sources",
    nodeType: AlertsConfigurationNodeType.SourcesHeader,
    searchValue: searchSources,
    setSearchValue: setSearchSources,
  };
};
