import { CommsPlatform } from "src/hooks/usePrimaryCommsPlatform";

import { MicrosoftTeamsMessagePreview } from "./MicrosoftTeamsPreviews/MicrosoftTeamsPreviews";
import { SlackMessagePreview } from "./SlackPreviews/SlackPreviews";

export type MessagePreviewProps = {
  commsPlatform: CommsPlatform;
  children: React.ReactNode;
  showTitle?: boolean;
  className?: string;
};

export const MessagePreview = ({
  commsPlatform,
  children,
  className,
  showTitle,
}: MessagePreviewProps): React.ReactElement => {
  if (commsPlatform === CommsPlatform.MSTeams) {
    return MicrosoftTeamsMessagePreview({ children, className, showTitle });
  } else {
    return SlackMessagePreview({ children, className, showTitle });
  }
};
