import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions/marshall";
import {
  addExpressionsToScope,
  makeExpressionReference,
} from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  EmptyState,
  Heading,
  IconEnum,
  Loader,
  StackedList,
} from "@incident-ui";
import { DrawerBody, DrawerFooter } from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { startOfToday } from "date-fns";
import _ from "lodash";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import styles from "src/components/insights/assistant/AssistantOverlay.module.scss";
import {
  EngineParamBinding,
  Expression,
  IncidentTimestampTimestampTypeEnum,
  PoliciesCreateRequestBody,
  PoliciesCreateRequestBodyPolicyTypeEnum,
  PoliciesCreateRequestBodySlaDaysCalculationTypeEnum,
  PoliciesPreviewViolationsForPolicyRequestBodyPolicyTypeEnum,
  PoliciesPreviewViolationsForPolicyRequestBodySlaDaysCalculationTypeEnum,
  PoliciesPreviewViolationsForPolicyResponseBody,
  PoliciesUpdateRequestBody,
  PoliciesUpdateRequestBodyPolicyTypeEnum,
  PoliciesUpdateRequestBodySlaDaysCalculationTypeEnum,
  Policy,
  PolicyPolicyTypeEnum,
  PolicySlaDaysCalculationTypeEnum,
  PolicyViolationLevelEnum,
  RequirementsTemplate,
} from "src/contexts/ClientContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useQueryParams } from "src/utils/query-params";
import { getEmptyScope, mergeScopes } from "src/utils/scope";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import { expressionToPayload } from "../../../@shared/engine/expressions/expressionToPayload";
import { PolicyIncidentsSection } from "../create-edit/PolicyIncidentsSection";
import { PolicyNameAndDescSection } from "../create-edit/PolicyNameAndDescSection";
import { PolicyReportSection } from "../create-edit/PolicyReportSection";
import { PolicyRequirementsSection } from "../create-edit/PolicyRequirementsSection";
import { PolicyResponsibleUserSection } from "../create-edit/PolicyResponsibleUserSection";
import { PolicySLASection } from "../create-edit/PolicySLASection";
import { ViolatingIncidentRow } from "../view/PolicyView";
import { PolicyTypeConfig, useGetPolicyTypeConfig } from "./config";
import { PolicyCreateEditSubDrawer } from "./PolicyCreateEditDrawer";
import { PolicyCreateEditDeps } from "./useGetPolicyCreateEditDeps";

export type PolicyCreateEditFormData = Omit<
  Policy,
  | "sla_incident_timestamp"
  | "responsible_user_references"
  | "warning_days"
  | "sla_days_calculation_type"
> & {
  sla_incident_timestamp_id: string;
  responsible_user_references: string[];
  warning_days_str?: string;
  warning_days_enabled: boolean;
  applies_to_all: boolean;
  exclude_weekends: boolean;
  policy_report_ids: Set<string>;
};

const transformFormStateToRequestBody = ({
  existingPolicy,
  data: formData,
}: {
  existingPolicy?: Policy;
  data: PolicyCreateEditFormData;
}):
  | { id: string; updateRequestBody: PoliciesUpdateRequestBody }
  | { createRequestBody: PoliciesCreateRequestBody } => {
  const slaDays = formData.sla_days;
  if (slaDays && slaDays.value && slaDays.value?.literal === "") {
    slaDays.value.literal = undefined;
  }
  const body = {
    ...formData,
    sla_days: slaDays,
    sla_incident_timestamp_id: formData.sla_incident_timestamp_id,
    // react-hook-form refuses to track data where there is no form field
    // watching it, so we fall back to the existing conditions here, without
    // edits
    incident_condition_groups: conditionGroupsToGroupPayloads(
      formData.incident_condition_groups ||
        existingPolicy?.incident_condition_groups ||
        [],
    ),
    type_condition_groups: conditionGroupsToGroupPayloads(
      formData.type_condition_groups ||
        existingPolicy?.type_condition_groups ||
        [],
    ),
    applies_from: formData.applies_to_all ? undefined : formData.applies_from,
  };

  delete body.warning_days_str;
  const warningDays = formData.warning_days_str
    ? parseInt(formData.warning_days_str)
    : undefined;

  if (existingPolicy) {
    return {
      id: existingPolicy.id,
      updateRequestBody: {
        ...body,
        warning_days: warningDays,
        expressions: (formData.expressions || []).map(expressionToPayload),
        policy_type:
          formData.policy_type as unknown as PoliciesUpdateRequestBodyPolicyTypeEnum,
        sla_days_calculation_type: formData.exclude_weekends
          ? PoliciesUpdateRequestBodySlaDaysCalculationTypeEnum.Weekdays
          : PoliciesUpdateRequestBodySlaDaysCalculationTypeEnum.SevenDays,
        report_schedule_ids: Array.from(formData.policy_report_ids),
      },
    };
  } else {
    return {
      createRequestBody: {
        ...body,
        warning_days: warningDays,
        expressions: (formData.expressions || []).map(expressionToPayload),
        policy_type:
          formData.policy_type as unknown as PoliciesCreateRequestBodyPolicyTypeEnum,
        sla_days_calculation_type: formData.exclude_weekends
          ? PoliciesCreateRequestBodySlaDaysCalculationTypeEnum.Weekdays
          : PoliciesCreateRequestBodySlaDaysCalculationTypeEnum.SevenDays,
        report_schedule_ids: Array.from(formData.policy_report_ids),
      },
    };
  }
};

export const PolicyCreateEditFormV2 = ({
  onClose,
  formProps,
  showDrawer,
  setShowDrawer,
  deps,
}: {
  onClose: () => void;
  formProps: CreateEditFormProps<Policy>;
  showDrawer: PolicyCreateEditSubDrawer | null;
  setShowDrawer: (drawer: PolicyCreateEditSubDrawer | null) => void;
  deps: PolicyCreateEditDeps;
}): React.ReactElement => {
  const initialData = formProps.initialData;
  const navigate = useOrgAwareNavigate();
  const params = useQueryParams();
  const initialType = params.get("type") as PolicyPolicyTypeEnum;
  const initialTemplateLabel = params.get("template") as string;
  const getPolicyConfig = useGetPolicyTypeConfig();
  const commsPlatform = usePrimaryCommsPlatform();

  const initialTemplate = deps.requirementsTemplates
    .find(
      (t) => (t.policy_type as unknown as PolicyPolicyTypeEnum) === initialType,
    )
    ?.templates.find((t) => t.label === initialTemplateLabel);

  const formMethods = useForm<PolicyCreateEditFormData>({
    defaultValues: policyToFormState({
      initialData,
      deps,
      defaultPolicyTypeConfig: getPolicyConfig(
        initialType ?? PolicyPolicyTypeEnum.FollowUp,
      ),
      initialTemplate,
      commsPlatform,
    }),
  });

  const { handleSubmit, setError, watch } = formMethods;

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const expressionsMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const [
    policyType,
    slaTimestampID,
    incidentConditionGroups,
    typeConditionGroups,
    slaDays,
    responsibleUserReferences,
    excludeWeekends,
    appliesToAll,
    appliesFrom,
    warningDays,
    expressions,
  ] = watch([
    "policy_type",
    "sla_incident_timestamp_id",
    "incident_condition_groups",
    "type_condition_groups",
    "sla_days",
    "responsible_user_references",
    "exclude_weekends",
    "applies_to_all",
    "applies_from",
    "warning_days_str",
    "expressions",
  ]);

  const slaTimestamp = deps.allowedTimestamps.find(
    (ts) => ts.id === slaTimestampID,
  );

  const revalidatePolicyShow = useRevalidate(["policiesShow"]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "policiesList",
    undefined,
    async (apiClient, data: PolicyCreateEditFormData) => {
      const body = transformFormStateToRequestBody({
        existingPolicy: initialData,
        data: data,
      });

      const resp =
        "id" in body
          ? await apiClient.policiesUpdate(body)
          : await apiClient.policiesCreate(body);

      revalidatePolicyShow();
      navigate(`/settings/policies/${resp.policy.id}`);
    },
    { setError },
  );

  const { data: previewViolationsResp, isLoading: previewViolationsLoading } =
    useAPI(
      "policiesPreviewViolationsForPolicy",
      {
        previewViolationsForPolicyRequestBody: {
          incident_condition_groups: conditionGroupsToGroupPayloads(
            incidentConditionGroups || [],
          ),
          // We are passing in a random name here so that the API call will still work, because the name is not used in this endpoint.
          name: "placeholder name",
          policy_type:
            policyType as unknown as PoliciesPreviewViolationsForPolicyRequestBodyPolicyTypeEnum,
          responsible_user_references: responsibleUserReferences,
          sla_days: slaDays,
          sla_days_calculation_type: excludeWeekends
            ? PoliciesPreviewViolationsForPolicyRequestBodySlaDaysCalculationTypeEnum.Weekdays
            : PoliciesPreviewViolationsForPolicyRequestBodySlaDaysCalculationTypeEnum.SevenDays,
          sla_incident_timestamp_id: slaTimestampID,
          type_condition_groups: conditionGroupsToGroupPayloads(
            typeConditionGroups || [],
          ),
          warning_days: parseInt(warningDays || "0"),
          description: "",
          expressions: (expressions || []).map(expressionToPayload),
          applies_from: appliesToAll ? undefined : appliesFrom,
        },
      },
      {},
    );

  const incidentAndPolicyScope = mergeScopes(
    deps.scopeAndResources.policy_scopes[policyType],
    deps.scopeAndResources.incident_scope,
  );

  const SLADaysScope = addExpressionsToScope(
    incidentAndPolicyScope,
    expressionsMethods.fields || [],
  );
  formMethods.register("applies_from", {
    validate: (v) => {
      if (appliesToAll) {
        return undefined;
      }

      if (!v) {
        return "Please select a date";
      }

      return undefined;
    },
  });

  formMethods.register(`type_condition_groups`, {
    validate: (v) => {
      if (v?.length > 0) {
        return undefined;
      }

      return "Please select some requirements";
    },
  });

  const drawerProps = (drawer: PolicyCreateEditSubDrawer) => ({
    showDrawer: showDrawer === drawer,
    setShowDrawer: (show: boolean) => setShowDrawer(show ? drawer : null),
  });

  const templates =
    deps.requirementsTemplates.find(
      (ts) =>
        (ts.policy_type as unknown as PolicyPolicyTypeEnum) === policyType,
    )?.templates || [];

  return (
    <>
      {/* DrawerBody */}
      <DrawerBody
        className={tcx(
          "flex flex-row min-h-0 overflow-y-hidden h-full !p-0 !gap-0",
        )}
      >
        {/* Left hand, input side of the form */}
        <div
          className={tcx(
            "!h-full overflow-y-hidden flex flex-col flex-[1] border-r border-stroke px-6 pt-6",
            styles.hideScrollbar,
          )}
        >
          <Form.Root
            genericError={genericError}
            onSubmit={() => handleSubmit(onSubmit)()}
            formMethods={formMethods}
            saving={saving}
            outerClassName={tcx("h-full overflow-auto", styles.hideScrollbar)}
            id="policy-create-edit"
          >
            <ExpressionsMethodsProvider
              expressionsMethods={expressionsMethods}
              allowAllOfACatalogType={false}
            >
              <div className="flex flex-col gap-6 pb-6">
                <PolicyNameAndDescSection
                  {...drawerProps("name_and_description")}
                  mode={formProps.mode}
                />
                <PolicyRequirementsSection
                  templates={templates}
                  policyType={policyType}
                  scope={
                    deps.scopeAndResources.policy_scopes[policyType] ||
                    getEmptyScope()
                  }
                />

                <PolicySLASection
                  {...drawerProps("sla")}
                  scope={SLADaysScope}
                  resources={deps.resources}
                  policyType={policyType}
                  automaticTimestamps={deps.allowedTimestamps}
                  defaultExpression={deps.defaultSLAExpression as Expression}
                  slaTimestampName={slaTimestamp?.name || ""}
                  templates={templates}
                />
                <PolicyIncidentsSection
                  {...drawerProps("incidents")}
                  scope={
                    deps.scopeAndResources.incident_scope || getEmptyScope()
                  }
                  slaTimestampName={slaTimestamp?.name || ""}
                />
                {commsPlatform === CommsPlatform.Slack && (
                  <PolicyResponsibleUserSection
                    {...drawerProps("responsible_user")}
                    scope={incidentAndPolicyScope}
                  />
                )}

                <PolicyReportSection
                  {...drawerProps("policy_report_ids")}
                  drawerProps={drawerProps}
                  allReportSchedules={deps.reportSchedules}
                />
              </div>
            </ExpressionsMethodsProvider>
          </Form.Root>
        </div>

        {/* Right hand, preview side of the form */}
        <div
          className={
            "flex flex-col items-stretch flex-[1] p-6 bg-surface-secondary overflow-auto gap-6"
          }
        >
          <div className="flex flex-col gap-3">
            <Heading level={2}>Current violations</Heading>
            <ViolationList
              resource={previewViolationsResp}
              loading={previewViolationsLoading}
              violationLevel={PolicyViolationLevelEnum.Error}
            />
          </div>
          <div className="flex flex-col gap-3">
            <Heading level={2}>Current warnings</Heading>
            <ViolationList
              resource={previewViolationsResp}
              loading={previewViolationsLoading}
              violationLevel={PolicyViolationLevelEnum.Warning}
            />
          </div>
        </div>
      </DrawerBody>

      <DrawerFooter className="flex justify-end gap-2">
        <Button
          analyticsTrackingId={null}
          onClick={() => onCloseWithWarn(isDirty)}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        <Button
          type={"submit"}
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          disabled={saving}
          loading={saving}
          form="policy-create-edit"
        >
          {formProps.mode === Mode.Edit ? "Save" : "Create policy"}
        </Button>
      </DrawerFooter>
    </>
  );
};

const ViolationList = ({
  resource,
  loading,
  violationLevel,
}: {
  resource?: PoliciesPreviewViolationsForPolicyResponseBody | null;
  loading?: boolean;
  violationLevel: PolicyViolationLevelEnum;
}): React.ReactElement => {
  if (loading) {
    return <Loader />;
  }

  if (!resource) {
    return (
      <EmptyState
        className="bg-surface-secondary !grow-0"
        content={"Finish configuring policy to view current violations"}
      />
    );
  }

  // Filter violations based on the violation level
  const violations =
    resource?.policy_violations.filter((v) => v.level === violationLevel) || [];

  // Get the incidents for the violations we are displaying
  const incidentsToDisplay = resource?.incidents.filter((incident) =>
    violations.some((v) => v.incident_id === incident.id),
  );

  if (incidentsToDisplay.length === 0) {
    return (
      <EmptyState
        className="bg-surface-secondary !grow-0"
        icon={
          violationLevel === PolicyViolationLevelEnum.Error
            ? IconEnum.Warning
            : IconEnum.Timer
        }
        title={`No ${violationLevel}s`}
        content={
          violationLevel === PolicyViolationLevelEnum.Error
            ? "All of your incidents comply with this policy."
            : "All of your incidents comply with this policy's warning thresholds."
        }
      />
    );
  }

  const countExtraIncidents =
    resource.total_incident_count - resource.incidents.length;

  return (
    <>
      <StackedList>
        {incidentsToDisplay.map((incident) => (
          <ViolatingIncidentRow
            key={incident.id}
            incident={incident}
            violations={(resource?.policy_violations ?? []).filter(
              (v) => v.incident_id === incident.id,
            )}
            violationType={violationLevel}
          />
        ))}
      </StackedList>
      {countExtraIncidents > 0 && (
        <div className="text-xs-med text-content-secondary">
          + {countExtraIncidents} more
        </div>
      )}
    </>
  );
};

const policyToFormState = ({
  initialData,
  defaultPolicyTypeConfig,
  initialTemplate,
  deps,
  commsPlatform,
}: {
  initialData?: Policy;
  defaultPolicyTypeConfig: PolicyTypeConfig;
  initialTemplate?: RequirementsTemplate;
  deps: PolicyCreateEditDeps;
  commsPlatform?: CommsPlatform;
}): Partial<PolicyCreateEditFormData> => {
  // If there's a resolved at timestamp (i.e. the default one we ship with), then
  // default to that.
  const resolvedAtTimestamp = deps.allowedTimestamps.find(
    (t) => t.timestamp_type === IncidentTimestampTimestampTypeEnum.ResolvedAt,
  );

  const leadRoleID = deps.leadRole?.id;
  const defaultTimestampID =
    resolvedAtTimestamp?.id || deps.allowedTimestamps[0]?.id;

  if (!initialData) {
    let defaultName: string | undefined = undefined;
    let defaultDescription: string | undefined = undefined;
    if (initialTemplate) {
      defaultName = `${
        defaultPolicyTypeConfig.label_plural
      } must be ${initialTemplate.label.toLowerCase()}`;

      defaultDescription = `Ensures that ${
        defaultPolicyTypeConfig.subject_plural
      } are ${initialTemplate.label.toLowerCase()} in a timely manner.`;
    }

    // This means we're creating a new policy, so we want the default values
    return {
      name: defaultName,
      description: defaultDescription,
      applies_from: startOfToday(),
      expressions: deps.defaultSLAExpression ? [deps.defaultSLAExpression] : [],
      warning_days_enabled: true,
      warning_days_str: "2",
      incident_condition_groups: [],
      sla_incident_timestamp_id: defaultTimestampID,
      policy_type: defaultPolicyTypeConfig.value,
      sla_days: {
        value: deps.defaultSLAExpression
          ? {
              reference: makeExpressionReference(deps.defaultSLAExpression),
            }
          : { literal: "3" },
      } as EngineParamBinding,
      type_condition_groups: initialTemplate
        ? initialTemplate.condition_groups
        : [],
      // we don't want to set a default responsible user if we're an MS teams org.
      responsible_user_references:
        leadRoleID && commsPlatform !== CommsPlatform.MSTeams
          ? [`incident.role["${leadRoleID}"]`]
          : [],
      exclude_weekends: true,
      applies_to_all: false,
      policy_report_ids: new Set(),
    };
  }

  // Work out which reports are currently associated with this policy
  const reports = deps.reportSchedules.filter((report) =>
    report.policy_ids.includes(initialData.id),
  );
  const initialSelectedReportIds = reports.map((report) => report.id);

  return {
    ..._.omit(initialData, [
      "type_condition_groups",
      "sla_incident_timestamp",
      "responsible_user_references",
      "warning_days",
    ]),
    expressions: initialData.expressions || [],
    warning_days_enabled:
      !!initialData?.warning_days && initialData.warning_days > 0,
    warning_days_str: initialData.warning_days?.toString() || undefined,
    incident_condition_groups: initialData.incident_condition_groups,
    sla_incident_timestamp_id: initialData.sla_incident_timestamp.id,
    type_condition_groups: initialData ? initialData.type_condition_groups : [],
    responsible_user_references: _.compact(
      initialData?.responsible_user_references?.map((r) => r.key),
    ),
    applies_to_all: !initialData?.applies_from,
    exclude_weekends:
      initialData?.sla_days_calculation_type ===
      PolicySlaDaysCalculationTypeEnum.Weekdays,
    policy_report_ids: new Set(initialSelectedReportIds),
  };
};
