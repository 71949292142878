import type { SVGProps } from "react";
import * as React from "react";
const SvgMortarBoard = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.488 13.39a3.27 3.27 0 0 1-1.488.36 3.272 3.272 0 0 1-1.489-.361L4.5 11.325V15c0 1.805 2.767 2.75 5.5 2.75s5.5-.945 5.5-2.75v-3.676l-4.012 2.065Z"
    />
    <path
      fill="currentColor"
      d="M17.719 10.226c-.026-.806.056-1.611.216-2.402.018-.13.065-.191.065-.45 0-.6-.332-1.145-.866-1.42l-6.332-3.26a1.756 1.756 0 0 0-1.604 0l-6.332 3.26A1.591 1.591 0 0 0 2 7.375c0 .602.332 1.147.866 1.422l6.332 3.259a1.754 1.754 0 0 0 1.604 0l5.451-2.806c-.019.34-.045.682-.034 1.024.024.772.126 1.546.301 2.3a.75.75 0 1 0 1.461-.337 10.413 10.413 0 0 1-.262-2.01Z"
    />
  </svg>
);
export default SvgMortarBoard;
