/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostIncidentStatusRank,
    PostIncidentStatusRankFromJSON,
    PostIncidentStatusRankFromJSONTyped,
    PostIncidentStatusRankToJSON,
} from './PostIncidentStatusRank';

/**
 * 
 * @export
 * @interface PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody
 */
export interface PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody {
    /**
     * 
     * @type {Array<PostIncidentStatusRank>}
     * @memberof PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody
     */
    status_ranks: Array<PostIncidentStatusRank>;
}

export function PostIncidentFlowUpdateStatusTaskConfigRanksRequestBodyFromJSON(json: any): PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody {
    return PostIncidentFlowUpdateStatusTaskConfigRanksRequestBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowUpdateStatusTaskConfigRanksRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status_ranks': ((json['status_ranks'] as Array<any>).map(PostIncidentStatusRankFromJSON)),
    };
}

export function PostIncidentFlowUpdateStatusTaskConfigRanksRequestBodyToJSON(value?: PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_ranks': ((value.status_ranks as Array<any>).map(PostIncidentStatusRankToJSON)),
    };
}

