import { ManagementMeta } from "@incident-io/api";
import {
  Badge,
  BadgeTheme,
  Icon,
  IconEnum,
  Link,
  Tooltip,
  Txt,
} from "@incident-ui";

import { isTerraform } from "./utils";

export const ManagementMetaBadge = ({
  management,
  resourceName,
}: {
  management: ManagementMeta | undefined;
  resourceName: string;
}) => {
  if (!isTerraform(management)) {
    return null;
  }

  return (
    <Tooltip
      content={
        <div className="flex flex-col divide-1">
          <div className="flex flex-row items-center gap-1">
            <Txt className="text-white">
              This {resourceName} is managed by Terraform.{" "}
            </Txt>
            {management?.source_url && (
              <Link
                to={management?.source_url}
                openInNewTab
                analyticsTrackingId="terraform-source-link-click"
                className="text-white whitespace-nowrap"
              >
                <Icon id={IconEnum.ExternalLink} className="text-white" />
              </Link>
            )}
          </div>
        </div>
      }
      side="top"
    >
      <div className="cursor-pointer">
        <Badge
          icon={IconEnum.Terraform}
          className="bg-violet-100 text-indigo-600 !flex"
          theme={BadgeTheme.Unstyled}
        >
          Synced
        </Badge>
      </div>
    </Tooltip>
  );
};
