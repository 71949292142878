/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeatDescription
 */
export interface SeatDescription {
    /**
     * Whether the current user has permission to assign this seat to the subject user
     * @type {boolean}
     * @memberof SeatDescription
     */
    can_assign: boolean;
    /**
     * A description of the seat type, for display purposes
     * @type {string}
     * @memberof SeatDescription
     */
    description: string;
    /**
     * The name of the seat type, for display purposes
     * @type {string}
     * @memberof SeatDescription
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SeatDescription
     */
    type: SeatDescriptionTypeEnum;
    /**
     * Whether or not this seat type requires an upgrade to use
     * @type {boolean}
     * @memberof SeatDescription
     */
    upgrade_required: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SeatDescriptionTypeEnum {
    OnCall = 'on_call',
    Responder = 'responder',
    Viewer = 'viewer',
    OnCallResponder = 'on_call_responder'
}

export function SeatDescriptionFromJSON(json: any): SeatDescription {
    return SeatDescriptionFromJSONTyped(json, false);
}

export function SeatDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeatDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_assign': json['can_assign'],
        'description': json['description'],
        'name': json['name'],
        'type': json['type'],
        'upgrade_required': json['upgrade_required'],
    };
}

export function SeatDescriptionToJSON(value?: SeatDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_assign': value.can_assign,
        'description': value.description,
        'name': value.name,
        'type': value.type,
        'upgrade_required': value.upgrade_required,
    };
}

