import {
  ScopeNameEnum,
  StatusPageCreateRequestBody,
  StatusPageCreateRequestBodyDateViewEnum,
  StatusPageCreateRequestBodyThemeEnum,
  StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum,
  StatusPageSetStructureAndComponentsRequestBodyDisplayUptimeModeEnum as DisplayUptimeModeRequestEnum,
  StatusPageThemeEnum,
} from "@incident-io/api";
import { UpgradeRequiredModal } from "@incident-shared/billing";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter, Steps } from "@incident-ui";
import { StepConfig } from "@incident-ui/Steps/Steps";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useSteps } from "src/components/legacy/status-page-config/shared/useSteps";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { v4 as uuidv4 } from "uuid";

import { ComponentsEditor } from "../common/ComponentsEditor";
import {
  Component,
  PreviewItem,
  StructureItem,
} from "../common/ComponentsEditor/utils";
import {
  buildStructureAndComponentsItems,
  EditablePath,
  FormType as ComponentEditorFormType,
} from "../common/ComponentsEditor/utils";
import { BasicSettingsFormContent } from "../settings/edit/BasicSettings";
import {
  buildPageSetupFormData,
  PageSetupForm,
  PageSetupFormData,
} from "../settings/edit/PageSetupForm";
import { CreateModalProps } from "../view/StatusPagesHomepage";

export enum steps {
  BasicSettings = "basic-settings",
  PageSetup = "page-setup",
  Components = "components",
}

export const stepConfig: StepConfig<steps>[] = [
  {
    id: steps.BasicSettings,
    name: "Basic settings",
  },
  {
    id: steps.PageSetup,
    name: "Page setup",
  },
  {
    id: steps.Components,
    name: "Components",
  },
];

export type FormType = Pick<StatusPageCreateRequestBody, "name" | "subpath"> &
  PageSetupFormData &
  ComponentEditorFormType;

const defaultValues = (): Partial<FormType> => {
  const websiteKey = uuidv4();
  const appKey = uuidv4();

  const pageSetupDefaults = buildPageSetupFormData();

  return {
    ...pageSetupDefaults,
    components: {
      [websiteKey]: {
        componentId: undefined,
        name: "Website",
        description: null,
      },
      [appKey]: {
        componentId: undefined,
        name: "App",
        description: null,
      },
    },
    structureItems: [
      {
        id: websiteKey,
        componentKey: websiteKey,
        displayUptime: true,
        hidden: false,
      },
      {
        id: appKey,
        componentKey: appKey,
        displayUptime: false,
        hidden: false,
      },
    ],
    displayUptimeMode: DisplayUptimeModeEnum.ChartAndPercentage,
  };
};

export const CreateStandalonePublicStatusPageModal = (
  props: CreateModalProps,
): React.ReactElement => {
  const { hasScope } = useIdentity();
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<FormType>({
    defaultValues: defaultValues(),
  });

  const [componentEditing, setComponentEditing] = useState<EditablePath>(null);

  const showToast = useToast();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageList",
    undefined,
    async (apiClient, data: FormType) => {
      // If we're editing a component, end that rather than submitting the whole form
      if (componentEditing) {
        setComponentEditing(null);
        return;
      }

      // Create the page
      const { status_page: page } = await apiClient.statusPageCreate({
        createRequestBody: {
          ...data,
          theme: data.theme as unknown as StatusPageCreateRequestBodyThemeEnum,
          date_view:
            data.date_view as unknown as StatusPageCreateRequestBodyDateViewEnum,
          darkmode_logo_key:
            data.theme === StatusPageThemeEnum.Dark
              ? data.logo?.key
              : undefined,
          lightmode_logo_key:
            data.theme === StatusPageThemeEnum.Light
              ? data.logo?.key
              : undefined,
          favicon_key: data.favicon?.key,
        },
      });

      // At this point the page exists, so any errors here should be toasted,
      // rather than keeping the modal open.
      try {
        await apiClient.statusPageSetStructureAndComponents({
          statusPageId: page.id,
          setStructureAndComponentsRequestBody: {
            display_uptime_mode:
              data.displayUptimeMode as unknown as DisplayUptimeModeRequestEnum,
            items: buildStructureAndComponentsItems(data),
          },
        });
      } catch (e) {
        captureException(e, { extra: { status_page_id: page.id } });

        showToast({
          theme: ToastTheme.Error,
          title: "Something went wrong",
          description:
            "We couldn't create all the components for your status page. Our engineers have been notified and we'll get on fixing that.",
        });
      }

      navigate(`/status-pages/${page.id}`);
    },
  );

  const onClose = () => navigate(`/status-pages`);

  const [name, subpath, components, items, displayUptimeMode] =
    formMethods.watch([
      "name",
      "subpath",
      "components",
      "structureItems",
      "displayUptimeMode",
    ]);

  const {
    currentStep,
    goToNextStep,
    goToPreviousStep,
    cancelButtonText,
    confirmButtonText,
  } = useSteps({
    steps: stepConfig,
    onSubmit,
    onClose,
    submitButtonText: "Create status page",
  });

  if (!props.canCreatePublicPages) {
    return (
      <UpgradeRequiredModal
        title="Create status page"
        gate={{
          type: "boolean",
        }}
        featureName="creating more public status pages"
        onClose={onClose}
        analyticsTrackingId="status-pages-create-upgrade-required"
      />
    );
  }

  return (
    <Form.Modal
      isExtraLarge
      title="Create status page"
      analyticsTrackingId="create-status-page"
      disableQuickClose
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={goToNextStep}
      loading={saving}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={goToPreviousStep}
          cancelButtonText={cancelButtonText}
          confirmButtonType="submit"
          confirmButtonText={confirmButtonText}
          disabled={!hasScope(ScopeNameEnum.StatusPagesConfigure)}
          disabledTooltipContent={
            "You do not have permission to create a public status page"
          }
        />
      }
    >
      <Steps steps={stepConfig} currentStep={currentStep} />
      {
        {
          [steps.BasicSettings]: <BasicSettingsFormContent />,
          [steps.PageSetup]: (
            <PageSetupForm
              name={name}
              subpath={subpath}
              previewItems={getPreviewItems(
                items,
                components,
                displayUptimeMode,
              )}
            />
          ),
          [steps.Components]: (
            <ComponentsEditor
              editing={componentEditing}
              setEditing={setComponentEditing}
              // The components don't exist yet so there can't be dependencies!
              dependentsForComponent={{}}
            />
          ),
        }[currentStep]
      }
    </Form.Modal>
  );
};

export const getPreviewItems = (
  structureItems: StructureItem[],
  components: Record<string, Component>,
  uptimeMode: DisplayUptimeModeEnum,
): PreviewItem[] => {
  const sharedProps = (
    item: StructureItem,
  ): Pick<
    PreviewItem,
    "hidden" | "displayUptimeChart" | "displayUptimePercentage"
  > => {
    return {
      hidden: item.hidden,
      displayUptimePercentage:
        uptimeMode === DisplayUptimeModeEnum.ChartAndPercentage &&
        item.displayUptime,
      displayUptimeChart:
        uptimeMode !== DisplayUptimeModeEnum.Nothing && item.displayUptime,
    };
  };

  return structureItems.map((item) => {
    if (item.groupId) {
      return {
        id: item.groupId,
        description: item.description || "",
        name: item.name,
        ...sharedProps(item),
        children: item.contents.map((item) => {
          return {
            id: item.id,
            name: components[item.id].name,
            ...sharedProps(item),
          };
        }),
      };
    }
    return {
      id: item.id,
      name: components[item.id].name,
      description: item.description || "",
      ...sharedProps(item),
    };
  });
};
