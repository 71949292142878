/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentAlert,
    IncidentAlertFromJSON,
    IncidentAlertFromJSONTyped,
    IncidentAlertToJSON,
} from './IncidentAlert';

/**
 * 
 * @export
 * @interface AlertsShowIncidentAlertResponseBody
 */
export interface AlertsShowIncidentAlertResponseBody {
    /**
     * 
     * @type {IncidentAlert}
     * @memberof AlertsShowIncidentAlertResponseBody
     */
    incident_alert: IncidentAlert;
}

export function AlertsShowIncidentAlertResponseBodyFromJSON(json: any): AlertsShowIncidentAlertResponseBody {
    return AlertsShowIncidentAlertResponseBodyFromJSONTyped(json, false);
}

export function AlertsShowIncidentAlertResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsShowIncidentAlertResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_alert': IncidentAlertFromJSON(json['incident_alert']),
    };
}

export function AlertsShowIncidentAlertResponseBodyToJSON(value?: AlertsShowIncidentAlertResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_alert': IncidentAlertToJSON(value.incident_alert),
    };
}

