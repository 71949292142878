/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Alert,
    AlertFromJSON,
    AlertFromJSONTyped,
    AlertToJSON,
} from './Alert';
import {
    AlertRouteCandidateIncident,
    AlertRouteCandidateIncidentFromJSON,
    AlertRouteCandidateIncidentFromJSONTyped,
    AlertRouteCandidateIncidentToJSON,
} from './AlertRouteCandidateIncident';

/**
 * 
 * @export
 * @interface AlertRoutesApplyGroupingResponseBody
 */
export interface AlertRoutesApplyGroupingResponseBody {
    /**
     * 
     * @type {Alert}
     * @memberof AlertRoutesApplyGroupingResponseBody
     */
    alert: Alert;
    /**
     * The grouping key IDs from the alert routes
     * @type {Array<string>}
     * @memberof AlertRoutesApplyGroupingResponseBody
     */
    grouping_key_ids: Array<string>;
    /**
     * Incidents that this alert is grouped into
     * @type {Array<AlertRouteCandidateIncident>}
     * @memberof AlertRoutesApplyGroupingResponseBody
     */
    incidents: Array<AlertRouteCandidateIncident>;
}

export function AlertRoutesApplyGroupingResponseBodyFromJSON(json: any): AlertRoutesApplyGroupingResponseBody {
    return AlertRoutesApplyGroupingResponseBodyFromJSONTyped(json, false);
}

export function AlertRoutesApplyGroupingResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesApplyGroupingResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert': AlertFromJSON(json['alert']),
        'grouping_key_ids': json['grouping_key_ids'],
        'incidents': ((json['incidents'] as Array<any>).map(AlertRouteCandidateIncidentFromJSON)),
    };
}

export function AlertRoutesApplyGroupingResponseBodyToJSON(value?: AlertRoutesApplyGroupingResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert': AlertToJSON(value.alert),
        'grouping_key_ids': value.grouping_key_ids,
        'incidents': ((value.incidents as Array<any>).map(AlertRouteCandidateIncidentToJSON)),
    };
}

