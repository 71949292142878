/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentAttachmentBookmarkConfig,
    IncidentAttachmentBookmarkConfigFromJSON,
    IncidentAttachmentBookmarkConfigFromJSONTyped,
    IncidentAttachmentBookmarkConfigToJSON,
} from './IncidentAttachmentBookmarkConfig';

/**
 * 
 * @export
 * @interface SlackBookmarkOption
 */
export interface SlackBookmarkOption {
    /**
     * 
     * @type {IncidentAttachmentBookmarkConfig}
     * @memberof SlackBookmarkOption
     */
    attachment_config?: IncidentAttachmentBookmarkConfig;
    /**
     * Type of bookmark
     * @type {string}
     * @memberof SlackBookmarkOption
     */
    bookmark_type: SlackBookmarkOptionBookmarkTypeEnum;
    /**
     * ID of the custom field when the bookmark involves a custom field
     * @type {string}
     * @memberof SlackBookmarkOption
     */
    custom_field_id?: string;
    /**
     * Emoji on the bookmark
     * @type {string}
     * @memberof SlackBookmarkOption
     */
    emoji: string;
    /**
     * ID of the incident role when the bookmark involves a role
     * @type {string}
     * @memberof SlackBookmarkOption
     */
    incident_role_id?: string;
    /**
     * Is this bookmark a link?
     * @type {boolean}
     * @memberof SlackBookmarkOption
     */
    is_link: boolean;
    /**
     * Text on the bookmak
     * @type {string}
     * @memberof SlackBookmarkOption
     */
    text: string;
}

/**
* @export
* @enum {string}
*/
export enum SlackBookmarkOptionBookmarkTypeEnum {
    Status = 'bookmarks_status',
    Severity = 'bookmarks_severity',
    Role = 'bookmarks_role',
    CustomField = 'bookmarks_custom_field',
    IncidentType = 'bookmarks_incident_type',
    JiraTicket = 'bookmarks_jira_ticket',
    StatusPage = 'bookmarks_status_page',
    Call = 'bookmarks_call',
    IncidentAttachment = 'bookmarks_incident_attachment',
    Postmortem = 'bookmarks_postmortem'
}

export function SlackBookmarkOptionFromJSON(json: any): SlackBookmarkOption {
    return SlackBookmarkOptionFromJSONTyped(json, false);
}

export function SlackBookmarkOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackBookmarkOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachment_config': !exists(json, 'attachment_config') ? undefined : IncidentAttachmentBookmarkConfigFromJSON(json['attachment_config']),
        'bookmark_type': json['bookmark_type'],
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'emoji': json['emoji'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'is_link': json['is_link'],
        'text': json['text'],
    };
}

export function SlackBookmarkOptionToJSON(value?: SlackBookmarkOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachment_config': IncidentAttachmentBookmarkConfigToJSON(value.attachment_config),
        'bookmark_type': value.bookmark_type,
        'custom_field_id': value.custom_field_id,
        'emoji': value.emoji,
        'incident_role_id': value.incident_role_id,
        'is_link': value.is_link,
        'text': value.text,
    };
}

