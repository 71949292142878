import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentFlameWithFill = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 224 224"
    {...props}
  >
    <path fill="#F25533" d="M0 0h224v224H0z" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M96.341 173.353V198C66.87 190.955 45 164.984 45 134.024c0-17.85 7.541-32.011 19.074-45.903 9.564-11.52 33.179-42.027 40.612-58.86 1.805-4.088 6.406-3.93 8.39-1.734 6.348 7.025 14.171 22.367 16.73 39.769.521 3.546.736 6.44.906 8.74.366 4.936.529 7.13 3.084 7.13 4.014 0 6.64-6.096 7.217-12.388.37-4.032 4.363-5.382 7.117-4.032 13.244 6.49 26.759 37.385 30.027 52.546 1.024 4.754 1.693 9.667 1.693 14.732 0 30.891-21.772 56.815-51.144 63.929v-24.6H96.342Zm16.084-9.824c10.358 0 18.754-7.96 18.754-17.78 0-15.662-11.529-26.854-17.274-30.256-.388-.229-.582-.344-1.11-.33-.387.01-.957.253-1.233.523-.377.37-.477.791-.677 1.633-1.018 4.29-4.224 7.349-7.597 10.568-4.65 4.436-9.617 9.177-9.617 17.862 0 9.82 8.397 17.78 18.754 17.78Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIncidentFlameWithFill;
