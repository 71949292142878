import type { SVGProps } from "react";
import * as React from "react";
const SvgPencil = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.414 3.848-7.809 7.809c-.863.864-1.4 3.406-1.593 4.459a.751.751 0 0 0 .872.873c1.053-.191 3.595-.73 4.46-1.593l7.808-7.81a2.647 2.647 0 0 0 0-3.737c-.998-.998-2.74-.998-3.738-.001Zm2.677 2.677-.94.94-1.617-1.617.94-.94c.216-.216.503-.334.81-.334.305 0 .591.119.807.334a1.146 1.146 0 0 1 0 1.617Z"
    />
  </svg>
);
export default SvgPencil;
