import { Button, ButtonTheme, Callout, CalloutTheme, Txt } from "@incident-ui";

import AppInstallQr from "../../alerts/images/app-install-qr-code.svg";
import AppStoreIcon from "../../alerts/images/app-store.png";
import AppIcon from "../../alerts/images/orange-app-icon.png";
import PlayStoreIcon from "../../alerts/images/play-store.png";

export const OnCallNotificationsAppOnboardingCallout = ({
  userAppOnboarded,
}: {
  userAppOnboarded: boolean;
}) => {
  if (userAppOnboarded) {
    return null;
  }

  return (
    <Callout
      theme={CalloutTheme.Unstyled}
      showIcon={false}
      className="mb-8 p-0 overflow-hidden bg-alarmalade-100 border-alarmalade-600"
    >
      <div className="m-4 flex flex-row flex-wrap sm:flex-nowrap gap-2">
        <div className="w-2/3">
          <div className="flex flex-col justify-between h-full gap-2">
            <div>
              <img
                className="max-h-[72px] max-w-[72px]"
                src={AppIcon}
                alt="The icon of incident.io's On-call app"
              />
            </div>
            <div>
              <h2 className="font-medium text-content-primary text-lg mt-2">
                Set up On-call
              </h2>
              <Txt grey className="mt-2">
                Download the mobile app to receive and acknowledge alerts
                natively on your device
              </Txt>
            </div>
            <div className="mt-4 flex flex-row">
              <Button
                analyticsTrackingId="on-call-onboarding-user-download-ios"
                className="truncate"
                theme={ButtonTheme.Primary}
                href="https://apps.apple.com/us/app/incident-io/id6471268530"
              >
                <img
                  className="max-h-[20px] max-w-[20px] mr-1"
                  src={AppStoreIcon}
                  alt="The Apple App Store icon"
                />
                App Store
              </Button>
              <Button
                analyticsTrackingId="on-call-onboarding-user-download-android"
                className="ml-4 truncate"
                theme={ButtonTheme.Primary}
                href="https://play.google.com/store/apps/details?id=com.incidentio.incidentio"
              >
                <img
                  className="max-h-[20px] max-w-[20px] mr-1"
                  src={PlayStoreIcon}
                  alt="The Google Play Store icon"
                />
                Google Play
              </Button>
            </div>
          </div>
        </div>
        <img
          className="max-h-[210px] max-w-[210px]"
          src={AppInstallQr}
          alt="The QR code to install the incident.io On-Call app"
        />
      </div>
    </Callout>
  );
};
