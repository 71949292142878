import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Link,
  SharedToasts,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  DebriefSettings,
  DebriefsUpdateCalendarIDRequestBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../SettingsSection";

type FormData = DebriefsUpdateCalendarIDRequestBody;
const toForm = (settings: DebriefSettings): FormData => ({
  calendar_id: settings.calendar_id,
  should_use_calendar_id: settings.should_use_calendar_id,
});

export const DebriefCalendarSettings = ({
  settings,
  googleCalendarIsInstalled,
}: {
  settings: DebriefSettings;
  googleCalendarIsInstalled: boolean;
}): React.ReactElement => {
  const showToast = useToast();
  const formMethods = useForm<FormData>({
    defaultValues: toForm(settings),
  });
  const { setError, reset } = formMethods;

  const mutation = useAPIMutation(
    "debriefsShowSettings",
    undefined,
    async (apiClient, formData: FormData) =>
      await apiClient.debriefsUpdateCalendarID({
        updateCalendarIDRequestBody: {
          calendar_id: formData.calendar_id,
          should_use_calendar_id: formData.should_use_calendar_id,
        },
      }),
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(toForm(settings));
      },
      setError,
    },
  );

  return (
    <SettingsSection
      formMethods={formMethods}
      mutation={mutation}
      enabledPath="should_use_calendar_id"
      title="Schedule in specific calendar"
      titleHeadingLevel={3}
      isDirty={
        googleCalendarIsInstalled ? formMethods.formState.isDirty : false // If GCal is not installed, we'll disable the 'save' button
      }
      explanation={
        <>
          <span>
            {
              "This is useful if you want your debriefs to be created in a shared calendar such as 'Incident debriefs'. If you don't see your calendar here, you'll need to "
            }
          </span>
          <Link
            to={"/settings/integrations/google_calendar"}
            analyticsTrackingId="add-calendar-id-google-calendar-config-link"
          >
            add it as a subscribed calendar
          </Link>
          .
        </>
      }
    >
      <div className="space-y-3">
        {!googleCalendarIsInstalled ? (
          <Callout theme={CalloutTheme.Info}>
            To do this you&apos;ll need to install the{" "}
            <Link
              to={"/settings/integrations/google_calendar"}
              analyticsTrackingId="update-calendar-id-google-calendar-integration-link"
            >
              Google Calendar integration
            </Link>
            .
          </Callout>
        ) : (
          <DebriefCalendarSettingsInner formMethods={formMethods} />
        )}
      </div>
    </SettingsSection>
  );
};

const DebriefCalendarSettingsInner = ({
  formMethods,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<DebriefsUpdateCalendarIDRequestBody, any>;
}): React.ReactElement => {
  const { data: calendarsResp } = useAPI(
    "integrationsGoogleWorkspaceGetCalendars",
    undefined,
  );

  const { showArticle } = useIntercom();

  const { hasScope } = useIdentity();
  const canEdit = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const calendarOptions: SelectOption[] = calendarsResp
    ? calendarsResp.calendars.map((calendar) => {
        return {
          label: calendar.name ?? calendar.calendar_id,
          value: calendar.calendar_id,
        };
      })
    : [];

  return (
    <>
      <Callout theme={CalloutTheme.Info}>
        If the user scheduling the debrief doesn&apos;t have the correct
        permissions, we won&apos;t be able to choose this calendar.{" "}
        <Button
          onClick={() => showArticle(8287305)}
          theme={ButtonTheme.Link}
          analyticsTrackingId={"settings-learn-more"}
        >
          Learn more
        </Button>
      </Callout>
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Calendar"
        name="calendar_id"
        required="Please select a calendar"
        options={calendarOptions || []}
        placeholder="Select calendar"
        disabled={!canEdit}
      />
    </>
  );
};
