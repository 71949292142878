/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScimConfig
 */
export interface ScimConfig {
    /**
     * The state of the connection, if any. Possible values are: draft, active and inactive
     * @type {string}
     * @memberof ScimConfig
     */
    directory_state?: string;
    /**
     * Describes whether the Directory has been successfully connected to an external provider.
     * @type {string}
     * @memberof ScimConfig
     */
    directory_type?: string;
    /**
     * Whether the organisation has set up their initial group to role mappings
     * @type {boolean}
     * @memberof ScimConfig
     */
    has_completed_initial_role_mapping: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScimConfig
     */
    id: string;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof ScimConfig
     */
    organisation_id: string;
}

export function ScimConfigFromJSON(json: any): ScimConfig {
    return ScimConfigFromJSONTyped(json, false);
}

export function ScimConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScimConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directory_state': !exists(json, 'directory_state') ? undefined : json['directory_state'],
        'directory_type': !exists(json, 'directory_type') ? undefined : json['directory_type'],
        'has_completed_initial_role_mapping': json['has_completed_initial_role_mapping'],
        'id': json['id'],
        'organisation_id': json['organisation_id'],
    };
}

export function ScimConfigToJSON(value?: ScimConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'directory_state': value.directory_state,
        'directory_type': value.directory_type,
        'has_completed_initial_role_mapping': value.has_completed_initial_role_mapping,
        'id': value.id,
        'organisation_id': value.organisation_id,
    };
}

