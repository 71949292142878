import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentLifecyclesUpdateStatusRequestBody,
  IncidentStatus,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

type FormState = IncidentLifecyclesUpdateStatusRequestBody & {
  // Typescript can't deal with the self-referencing type for templated text
  description: { text_node: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const IncidentStatusEditModal = ({
  onClose,
  existingStatus,
}: {
  onClose: () => void;
  existingStatus: IncidentStatus;
}): React.ReactElement | null => {
  const formMethods = useForm<FormState>({
    defaultValues: existingStatus,
  });

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    existingStatus.incident_lifecycle_id
      ? "incidentLifecyclesList"
      : existingStatus.post_incident_flow_id
      ? "postIncidentFlowList"
      : "incidentLifecyclesListAllStatuses",
    undefined,
    async (apiClient, data: FormState) => {
      await apiClient.incidentLifecyclesUpdateStatus({
        id: existingStatus.id,
        updateStatusRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal<FormState>
      onSubmit={onSubmit}
      formMethods={formMethods}
      onClose={onClose}
      analyticsTrackingId="edit-incident-status"
      title="Edit status"
      disableQuickClose
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText="Save"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        required="Please provide a name"
        label="Name"
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        includeVariables={false}
        includeExpressions={false}
        name="description.text_node"
        label="Description"
        placeholder="e.g. We've identified what's wrong, and we're in the process of fixing it."
        helptext="This will be displayed to help a user choose the correct status."
        format="mrkdwn"
        required="Please provide a description"
        multiLine
      />
    </Form.Modal>
  );
};
