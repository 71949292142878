/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoArchiveIncidentsSettings,
    AutoArchiveIncidentsSettingsFromJSON,
    AutoArchiveIncidentsSettingsFromJSONTyped,
    AutoArchiveIncidentsSettingsToJSON,
} from './AutoArchiveIncidentsSettings';
import {
    AutoCloseIncidentsSettings,
    AutoCloseIncidentsSettingsFromJSON,
    AutoCloseIncidentsSettingsFromJSONTyped,
    AutoCloseIncidentsSettingsToJSON,
} from './AutoCloseIncidentsSettings';
import {
    EngineParamBindingValue,
    EngineParamBindingValueFromJSON,
    EngineParamBindingValueFromJSONTyped,
    EngineParamBindingValueToJSON,
} from './EngineParamBindingValue';
import {
    IncidentChannelSettings,
    IncidentChannelSettingsFromJSON,
    IncidentChannelSettingsFromJSONTyped,
    IncidentChannelSettingsToJSON,
} from './IncidentChannelSettings';
import {
    ShoutoutChannelSettings,
    ShoutoutChannelSettingsFromJSON,
    ShoutoutChannelSettingsFromJSONTyped,
    ShoutoutChannelSettingsToJSON,
} from './ShoutoutChannelSettings';

/**
 * 
 * @export
 * @interface MiscSettings
 */
export interface MiscSettings {
    /**
     * 
     * @type {AutoArchiveIncidentsSettings}
     * @memberof MiscSettings
     */
    auto_archive_incidents: AutoArchiveIncidentsSettings;
    /**
     * 
     * @type {AutoCloseIncidentsSettings}
     * @memberof MiscSettings
     */
    auto_close_incidents: AutoCloseIncidentsSettings;
    /**
     * 
     * @type {EngineParamBindingValue}
     * @memberof MiscSettings
     */
    error_notifications_channel?: EngineParamBindingValue;
    /**
     * 
     * @type {boolean}
     * @memberof MiscSettings
     */
    follow_up_owner_required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiscSettings
     */
    follow_up_priority_required: boolean;
    /**
     * 
     * @type {IncidentChannelSettings}
     * @memberof MiscSettings
     */
    incident_channels: IncidentChannelSettings;
    /**
     * This will be set to true if the organisation has configured linking a whole incident to an external ticket, which will then appear on the incidents response as external_issue_reference
     * @type {boolean}
     * @memberof MiscSettings
     */
    incident_external_tickets_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiscSettings
     */
    incident_types_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiscSettings
     */
    manual_triage_incidents_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiscSettings
     */
    post_incident_notify_on_task_overdue: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiscSettings
     */
    private_incidents_enabled: boolean;
    /**
     * Whether an organisation can create private streams
     * @type {boolean}
     * @memberof MiscSettings
     */
    private_streams_enabled: boolean;
    /**
     * 
     * @type {ShoutoutChannelSettings}
     * @memberof MiscSettings
     */
    shoutout_channel: ShoutoutChannelSettings;
    /**
     * Which messages in incident channels should be stored in incident.io?
     * @type {string}
     * @memberof MiscSettings
     */
    store_incident_channel_messages: MiscSettingsStoreIncidentChannelMessagesEnum;
}

/**
* @export
* @enum {string}
*/
export enum MiscSettingsStoreIncidentChannelMessagesEnum {
    Never = 'never',
    TimelineOnly = 'timeline_only',
    All = 'all'
}

export function MiscSettingsFromJSON(json: any): MiscSettings {
    return MiscSettingsFromJSONTyped(json, false);
}

export function MiscSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MiscSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_archive_incidents': AutoArchiveIncidentsSettingsFromJSON(json['auto_archive_incidents']),
        'auto_close_incidents': AutoCloseIncidentsSettingsFromJSON(json['auto_close_incidents']),
        'error_notifications_channel': !exists(json, 'error_notifications_channel') ? undefined : EngineParamBindingValueFromJSON(json['error_notifications_channel']),
        'follow_up_owner_required': json['follow_up_owner_required'],
        'follow_up_priority_required': json['follow_up_priority_required'],
        'incident_channels': IncidentChannelSettingsFromJSON(json['incident_channels']),
        'incident_external_tickets_enabled': json['incident_external_tickets_enabled'],
        'incident_types_enabled': json['incident_types_enabled'],
        'manual_triage_incidents_enabled': json['manual_triage_incidents_enabled'],
        'post_incident_notify_on_task_overdue': json['post_incident_notify_on_task_overdue'],
        'private_incidents_enabled': json['private_incidents_enabled'],
        'private_streams_enabled': json['private_streams_enabled'],
        'shoutout_channel': ShoutoutChannelSettingsFromJSON(json['shoutout_channel']),
        'store_incident_channel_messages': json['store_incident_channel_messages'],
    };
}

export function MiscSettingsToJSON(value?: MiscSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_archive_incidents': AutoArchiveIncidentsSettingsToJSON(value.auto_archive_incidents),
        'auto_close_incidents': AutoCloseIncidentsSettingsToJSON(value.auto_close_incidents),
        'error_notifications_channel': EngineParamBindingValueToJSON(value.error_notifications_channel),
        'follow_up_owner_required': value.follow_up_owner_required,
        'follow_up_priority_required': value.follow_up_priority_required,
        'incident_channels': IncidentChannelSettingsToJSON(value.incident_channels),
        'incident_external_tickets_enabled': value.incident_external_tickets_enabled,
        'incident_types_enabled': value.incident_types_enabled,
        'manual_triage_incidents_enabled': value.manual_triage_incidents_enabled,
        'post_incident_notify_on_task_overdue': value.post_incident_notify_on_task_overdue,
        'private_incidents_enabled': value.private_incidents_enabled,
        'private_streams_enabled': value.private_streams_enabled,
        'shoutout_channel': ShoutoutChannelSettingsToJSON(value.shoutout_channel),
        'store_incident_channel_messages': value.store_incident_channel_messages,
    };
}

