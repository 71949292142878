/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleHolidaysPublicConfig
 */
export interface ScheduleHolidaysPublicConfig {
    /**
     * ISO 3166-1 alpha-2 country codes for the countries that this schedule is configured to view holidays for
     * @type {Array<string>}
     * @memberof ScheduleHolidaysPublicConfig
     */
    country_codes: Array<string>;
}

export function ScheduleHolidaysPublicConfigFromJSON(json: any): ScheduleHolidaysPublicConfig {
    return ScheduleHolidaysPublicConfigFromJSONTyped(json, false);
}

export function ScheduleHolidaysPublicConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleHolidaysPublicConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country_codes': json['country_codes'],
    };
}

export function ScheduleHolidaysPublicConfigToJSON(value?: ScheduleHolidaysPublicConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_codes': value.country_codes,
    };
}

