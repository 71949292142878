/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfluenceSite,
    ConfluenceSiteFromJSON,
    ConfluenceSiteFromJSONTyped,
    ConfluenceSiteToJSON,
} from './ConfluenceSite';

/**
 * 
 * @export
 * @interface IntegrationsConfluenceGetSitesResponseBody
 */
export interface IntegrationsConfluenceGetSitesResponseBody {
    /**
     * 
     * @type {Array<ConfluenceSite>}
     * @memberof IntegrationsConfluenceGetSitesResponseBody
     */
    sites: Array<ConfluenceSite>;
}

export function IntegrationsConfluenceGetSitesResponseBodyFromJSON(json: any): IntegrationsConfluenceGetSitesResponseBody {
    return IntegrationsConfluenceGetSitesResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsConfluenceGetSitesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsConfluenceGetSitesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sites': ((json['sites'] as Array<any>).map(ConfluenceSiteFromJSON)),
    };
}

export function IntegrationsConfluenceGetSitesResponseBodyToJSON(value?: IntegrationsConfluenceGetSitesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sites': ((value.sites as Array<any>).map(ConfluenceSiteToJSON)),
    };
}

