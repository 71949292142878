import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { FollowUp } from "src/contexts/ClientContext";

import { ProviderIconLookup, SyncFailureTooltip } from "./SyncFailureTooltip";

// This section is used as the "cell" in two different follow-up tables.
export const FollowUpExportFailureSection = ({
  followUp,
}: {
  followUp: FollowUp;
}): React.ReactElement => {
  if (!followUp.sync_failures || followUp.sync_failures.length === 0) {
    return <></>;
  }
  const latestFailure = followUp.sync_failures[0];

  // If we know the ID of the issue template, we'll link to the edit page for that template.
  let href = "settings/follow-ups";
  if (latestFailure.template_id) {
    href = `/settings/follow-ups/templates/${latestFailure.provider}/${latestFailure.template_id}`;
  }

  return (
    <SyncFailureTooltip syncFailures={followUp.sync_failures}>
      <Button
        href={href}
        className="!decoration-red-500"
        analyticsTrackingId="recent-jira-sync-failure"
        theme={ButtonTheme.Naked}
        icon={ProviderIconLookup[latestFailure.provider]}
      >
        <div className="text-red-500">
          Failed to export
          <Icon
            id={IconEnum.ExclamationMark}
            className="inline pl-2 pb-0.5"
            size={IconSize.Large}
          />
        </div>
      </Button>
    </SyncFailureTooltip>
  );
};
