/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalGitLabMergeRequest
 */
export interface ExternalGitLabMergeRequest {
    /**
     * GitLab username of the MR author
     * @type {string}
     * @memberof ExternalGitLabMergeRequest
     */
    author: string;
    /**
     * MR Body
     * @type {string}
     * @memberof ExternalGitLabMergeRequest
     */
    body: string;
    /**
     * Whether the MR has been merged
     * @type {boolean}
     * @memberof ExternalGitLabMergeRequest
     */
    merged: boolean;
    /**
     * MR number
     * @type {string}
     * @memberof ExternalGitLabMergeRequest
     */
    number: string;
    /**
     * Current status of the merge request
     * @type {string}
     * @memberof ExternalGitLabMergeRequest
     */
    status: ExternalGitLabMergeRequestStatusEnum;
    /**
     * Title of the merge request
     * @type {string}
     * @memberof ExternalGitLabMergeRequest
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum ExternalGitLabMergeRequestStatusEnum {
    Closed = 'closed',
    Open = 'open'
}

export function ExternalGitLabMergeRequestFromJSON(json: any): ExternalGitLabMergeRequest {
    return ExternalGitLabMergeRequestFromJSONTyped(json, false);
}

export function ExternalGitLabMergeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalGitLabMergeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': json['author'],
        'body': json['body'],
        'merged': json['merged'],
        'number': json['number'],
        'status': json['status'],
        'title': json['title'],
    };
}

export function ExternalGitLabMergeRequestToJSON(value?: ExternalGitLabMergeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'body': value.body,
        'merged': value.merged,
        'number': value.number,
        'status': value.status,
        'title': value.title,
    };
}

