import { assertUnreachable } from "@incident-io/status-page-ui";
import { LoadingWrapper } from "@incident-ui";
import {
  RadioButtonGroup,
  RadioButtonGroupOption,
} from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import React from "react";
import { Form } from "src/components/@shared/forms";
import {
  MessageTemplateConfigIncidentStatusEnum as IncidentStatusEnum,
  StatusPage,
  StatusPageCreateTemplateRequestBodyStatusPageIncidentTypeEnum as IncidentTypeEnum,
  StatusPageTemplateModeEnum as TemplateModeEnum,
  StatusPageTemplateTemplateTypeEnum as TemplateTypeEnum,
  StatusPageUpdateTemplateModeRequestBodyTemplateModeEnum,
} from "src/contexts/ClientContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import {
  MessageTemplate,
  MessageTemplatesSection,
} from "./MessageTemplatesSection";
import {
  PreDefinedUpdate,
  PreDefinedUpdatesSection,
} from "./PreDefinedUpdatesSection";

export const INCIDENT_TYPE_TO_CONFIG: {
  [key in IncidentTypeEnum]: {
    statuses: IncidentStatusEnum[];
    label: string;
    messageTemplateHelptext: string;
    preDefinedUpdateHelpText: string;
    exampleIncidentName: string;
  };
} = {
  [IncidentTypeEnum.Incident]: {
    statuses: [
      IncidentStatusEnum.Investigating,
      IncidentStatusEnum.Identified,
      IncidentStatusEnum.Monitoring,
      IncidentStatusEnum.Resolved,
    ],
    label: "Incident",
    exampleIncidentName: "Elevated API errors",
    messageTemplateHelptext: `Templates provide default guidelines for external facing communications, removing friction during an incident. Your templates will be used as default text when writing a status page update.`,
    preDefinedUpdateHelpText: `Pre-defined updates provide ready-to-go examples for external facing communications, removing friction during an incident. Responders will be offered a list of templates to choose from before writing an update.`,
  },
  [IncidentTypeEnum.Maintenance]: {
    statuses: [
      IncidentStatusEnum.MaintenanceInProgress,
      IncidentStatusEnum.MaintenanceScheduled,
      IncidentStatusEnum.MaintenanceComplete,
    ],
    label: "Maintenance",
    exampleIncidentName: "Planned database maintenance",
    messageTemplateHelptext: `Maintenance templates will be used as the default text for updates to scheduled maintenance windows.`,
    preDefinedUpdateHelpText: `Maintenance templates can be chosen when declaring or updating a maintenance window.`,
  },
};

export const TemplateSettingsPage = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement | null => {
  const refetchTemplates = useAPIRefetch("statusPageListTemplates", {
    statusPageId: page.id,
  });

  const { trigger: onUpdateMode, genericError } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: { mode: string }) => {
      await apiClient.statusPageUpdateTemplateMode({
        id: page.id,
        updateTemplateModeRequestBody: {
          template_mode:
            data.mode as unknown as StatusPageUpdateTemplateModeRequestBodyTemplateModeEnum,
        },
      });
      await refetchTemplates();
    },
  );

  const {
    setState,
    hasSaved,
    saving,
    state: templateMode,
  } = useOptimisticAutoSave<string>({
    initialState: page.template_mode,
    saveState: async (mode: string) => {
      await onUpdateMode({ mode });
    },
  });

  const modeOptions: RadioButtonGroupOption[] = [
    {
      value: TemplateModeEnum.MessageTemplate,
      label: "Message templates",
      description:
        "Automatically pre-fill message text based on the incident's status so that updates have a consistent structure and tone.",
    },
    {
      value: TemplateModeEnum.PreDefinedUpdate,
      label: "Pre-defined updates",
      description:
        "Allow responders to choose from a list of pre-defined templates for situations you can predict, which include the name, status and message text.",
    },
  ];

  return (
    <LoadingWrapper loading={saving} className="max-w-3xl flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="w-full flex justify-between items-center">
          <div className="font-medium text-content-primary text-sm">
            How do you want to manage your templates?
          </div>
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
        <RadioButtonGroup
          name="template_mode"
          srLabel="template mode"
          value={templateMode}
          options={modeOptions}
          onChange={(value) => {
            setState(value);
          }}
          horizontal
          boxed
        />
        {genericError && <Form.ErrorMessage message={genericError} />}
      </div>
      <TemplatesSection mode={page.template_mode} page={page} />
    </LoadingWrapper>
  );
};

const TemplatesSection = ({
  mode,
  page,
}: {
  mode: TemplateModeEnum;
  page: StatusPage;
}): React.ReactElement => {
  const {
    data: { status_page_templates: templates },
  } = useAPI(
    "statusPageListTemplates",
    { statusPageId: page.id },
    {
      fallbackData: { status_page_templates: [] },
    },
  );

  const messageTemplates = templates.filter(
    (t) => t.template_type === TemplateTypeEnum.MessageTemplate,
  ) as MessageTemplate[];

  const preDefinedUpdates = templates.filter(
    (t) => t.template_type === TemplateTypeEnum.PreDefinedUpdate,
  ) as PreDefinedUpdate[];

  switch (mode) {
    case TemplateModeEnum.MessageTemplate:
      return (
        <>
          <div className="space-y-4 bg-surface-secondary rounded-[6px] p-4 border border-stroke">
            <MessageTemplatesSection
              incidentType={IncidentTypeEnum.Incident}
              page={page}
              templates={messageTemplates}
            />
          </div>
          <div className="space-y-4 bg-surface-secondary rounded-[6px] p-4 border border-stroke">
            <MessageTemplatesSection
              incidentType={IncidentTypeEnum.Maintenance}
              page={page}
              templates={messageTemplates}
            />
          </div>
        </>
      );
    case TemplateModeEnum.PreDefinedUpdate:
      return (
        <>
          <div className="space-y-4 bg-surface-secondary rounded-[6px] p-4 border border-stroke">
            <PreDefinedUpdatesSection
              incidentType={IncidentTypeEnum.Incident}
              page={page}
              templates={preDefinedUpdates}
            />
          </div>
          <div className="space-y-4 bg-surface-secondary rounded-[6px] p-4 border border-stroke">
            <PreDefinedUpdatesSection
              incidentType={IncidentTypeEnum.Maintenance}
              page={page}
              templates={preDefinedUpdates}
            />
          </div>
        </>
      );

    default:
      assertUnreachable(mode);
  }
  return <></>;
};
