import {
  AlertAttribute,
  AlertSourceAttributeSuggestion,
  AlertSourceConfig,
  AlertsSuggestAttributesResponseBody,
  EngineParamBindingPayload,
  ErrorResponse,
} from "@incident-io/api";
import { isEmptyBinding } from "@incident-shared/engine";
import _ from "lodash";
import { useAPI } from "src/utils/swr";

// Get outstanding suggestions for an alert source. This looks at the current
// alert source state and the suggestions returned from the API to identify the
// top suggestions that still require actioning.
//
// We try to avoid refreshing this too much as suggestions do eventually
// timeout, so we don't want to refreshing and reshuffle randomly under the
// user.
//
export const useOutstandingSuggestions = ({
  alertSourceConfig,
  attributeBindings,
  attributes,
}: {
  alertSourceConfig: AlertSourceConfig;
  attributes: AlertAttribute[];
  attributeBindings: { [key: string]: EngineParamBindingPayload };
}): {
  data: AlertsSuggestAttributesResponseBody | undefined;
  suggestions: AlertSourceAttributeSuggestion[];
  isLoading: boolean;
  error: ErrorResponse | undefined;
} => {
  // Fetch suggestions so we can display them. These return all suggestions from
  // the latest batch, including those that have been accepted/declined.
  const result = useAPI(
    "alertsSuggestAttributes",
    {
      id: alertSourceConfig.id,
      suggestAttributesRequestBody: {
        reset: false,
      },
    },
    {
      // Don't refresh suggestions unless we actively ask for them.
      revalidateOnFocus: false,
      // Don't keep previous data as when we say "Retry" we want to clear the
      // existing suggestions.
      keepPreviousData: false,
    },
  );

  const populatedAttributes = getPopulatedAttributes({
    attributes,
    attributeBindings,
  });

  // We must now filter our suggestions.
  const suggestions = _.chain(result.data?.suggestions || [])
    // Remove all suggestions that have already been actioned
    .filter((suggestion) => {
      return !suggestion.declined_at && !suggestion.accepted_at;
    })
    // Now hide any suggestions where there is already an attribute of this type
    // bound to the alert source. This isn't always going to be what the user
    // wants, but helps avoid us suggesting things we think people already have.
    .filter((suggestion) => {
      return !populatedAttributes.some(
        (attribute: AlertAttribute) =>
          attribute.type === suggestion.attribute_payload.type &&
          attribute.array === suggestion.attribute_payload.array,
      );
    })
    // We check each suggestion and assign it an existing attribute if we can
    // find one that is type compatible.
    .map((suggestion) => {
      const existingAttribute = attributes.find(
        (attribute) =>
          attribute.type === suggestion.attribute_payload.type &&
          attribute.array === suggestion.attribute_payload.array,
      );

      if (!existingAttribute) {
        return suggestion;
      }

      // Adjust the suggestion so it matches against the existing endpoint.
      return {
        ...suggestion,
        attribute_payload: {
          ...suggestion.attribute_payload,
          name: existingAttribute.name,
          attribute_id: existingAttribute.id,
        },
      };
    })
    // Remove any suggestions where we already have an attribute with that name,
    // as otherwise we'll fail.
    .filter((suggestion) => {
      // If this suggestion has an attribute ID then we don't need to worry
      // about collisions.
      if (suggestion.attribute_payload.id) {
        return true;
      }

      // Otherwise keep only the suggestions that wouldn't have a duplicate
      // attribute name.
      return !attributes.some(
        (attribute) => attribute.name === suggestion.attribute_payload.name,
      );
    })
    .value();

  return {
    suggestions,
    ...result,
  };
};

export const getPopulatedAttributes = ({
  attributes,
  attributeBindings,
  includeLocallyEditing,
}: {
  attributes: AlertAttribute[];
  attributeBindings: {
    [key: string]: EngineParamBindingPayload & {
      isLocallyEditing?: boolean;
    };
  };
  includeLocallyEditing?: boolean;
}): AlertAttribute[] => {
  return attributes.filter((attr) => {
    if (!attr.id) return false;
    const binding = attributeBindings[attr.id];

    if (includeLocallyEditing && binding?.isLocallyEditing) {
      return true;
    }

    return !isEmptyBinding(binding);
  });
};
