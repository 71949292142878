/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentUpdate,
    IncidentUpdateFromJSON,
    IncidentUpdateFromJSONTyped,
    IncidentUpdateToJSON,
} from './IncidentUpdate';
import {
    StatusPageIncidentSlim,
    StatusPageIncidentSlimFromJSON,
    StatusPageIncidentSlimFromJSONTyped,
    StatusPageIncidentSlimToJSON,
} from './StatusPageIncidentSlim';
import {
    StatusPageIncidentUpdate,
    StatusPageIncidentUpdateFromJSON,
    StatusPageIncidentUpdateFromJSONTyped,
    StatusPageIncidentUpdateToJSON,
} from './StatusPageIncidentUpdate';
import {
    StatusPageSlim,
    StatusPageSlimFromJSON,
    StatusPageSlimFromJSONTyped,
    StatusPageSlimToJSON,
} from './StatusPageSlim';

/**
 * 
 * @export
 * @interface IncidentUpdateOrStatusPageIncidentUpdate
 */
export interface IncidentUpdateOrStatusPageIncidentUpdate {
    /**
     * 
     * @type {IncidentUpdate}
     * @memberof IncidentUpdateOrStatusPageIncidentUpdate
     */
    incident_update?: IncidentUpdate;
    /**
     * 
     * @type {StatusPageSlim}
     * @memberof IncidentUpdateOrStatusPageIncidentUpdate
     */
    status_page?: StatusPageSlim;
    /**
     * 
     * @type {StatusPageIncidentSlim}
     * @memberof IncidentUpdateOrStatusPageIncidentUpdate
     */
    status_page_incident?: StatusPageIncidentSlim;
    /**
     * 
     * @type {StatusPageIncidentUpdate}
     * @memberof IncidentUpdateOrStatusPageIncidentUpdate
     */
    status_page_incident_update?: StatusPageIncidentUpdate;
}

export function IncidentUpdateOrStatusPageIncidentUpdateFromJSON(json: any): IncidentUpdateOrStatusPageIncidentUpdate {
    return IncidentUpdateOrStatusPageIncidentUpdateFromJSONTyped(json, false);
}

export function IncidentUpdateOrStatusPageIncidentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentUpdateOrStatusPageIncidentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_update': !exists(json, 'incident_update') ? undefined : IncidentUpdateFromJSON(json['incident_update']),
        'status_page': !exists(json, 'status_page') ? undefined : StatusPageSlimFromJSON(json['status_page']),
        'status_page_incident': !exists(json, 'status_page_incident') ? undefined : StatusPageIncidentSlimFromJSON(json['status_page_incident']),
        'status_page_incident_update': !exists(json, 'status_page_incident_update') ? undefined : StatusPageIncidentUpdateFromJSON(json['status_page_incident_update']),
    };
}

export function IncidentUpdateOrStatusPageIncidentUpdateToJSON(value?: IncidentUpdateOrStatusPageIncidentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_update': IncidentUpdateToJSON(value.incident_update),
        'status_page': StatusPageSlimToJSON(value.status_page),
        'status_page_incident': StatusPageIncidentSlimToJSON(value.status_page_incident),
        'status_page_incident_update': StatusPageIncidentUpdateToJSON(value.status_page_incident_update),
    };
}

