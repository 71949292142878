/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';

/**
 * 
 * @export
 * @interface AnnouncementRulesCreateRequestBody
 */
export interface AnnouncementRulesCreateRequestBody {
    /**
     * list of channel IDs
     * @type {Array<string>}
     * @memberof AnnouncementRulesCreateRequestBody
     */
    channels: Array<string>;
    /**
     * Condition groups that apply to this announcement rule
     * @type {Array<ConditionGroupPayload>}
     * @memberof AnnouncementRulesCreateRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * Mode specifies how triage incidents should be announced
     * @type {string}
     * @memberof AnnouncementRulesCreateRequestBody
     */
    mode: AnnouncementRulesCreateRequestBodyModeEnum;
    /**
     * Human readable name for the rule
     * @type {string}
     * @memberof AnnouncementRulesCreateRequestBody
     */
    name: string;
    /**
     * Should incident updates be shared in the channel
     * @type {boolean}
     * @memberof AnnouncementRulesCreateRequestBody
     */
    share_updates_to_channel: boolean;
}

/**
* @export
* @enum {string}
*/
export enum AnnouncementRulesCreateRequestBodyModeEnum {
    LiveAndClosed = 'live_and_closed',
    IncludeTriage = 'include_triage',
    IncludeDeclinedAndMerged = 'include_declined_and_merged'
}

export function AnnouncementRulesCreateRequestBodyFromJSON(json: any): AnnouncementRulesCreateRequestBody {
    return AnnouncementRulesCreateRequestBodyFromJSONTyped(json, false);
}

export function AnnouncementRulesCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementRulesCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': json['channels'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'mode': json['mode'],
        'name': json['name'],
        'share_updates_to_channel': json['share_updates_to_channel'],
    };
}

export function AnnouncementRulesCreateRequestBodyToJSON(value?: AnnouncementRulesCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': value.channels,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'mode': value.mode,
        'name': value.name,
        'share_updates_to_channel': value.share_updates_to_channel,
    };
}

