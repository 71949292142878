import { Mode } from "@incident-shared/forms/v2/formsv2";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useParams } from "react-router";

import { useAPI } from "../../../../utils/swr";
import { NudgesCreateEditForm } from "../common/NudgesCreateEditForm";

export const NudgesEditPage = (): React.ReactElement => {
  const { id } = useParams();

  if (!id) {
    throw new Error("Missing nudge id");
  }

  const { data, isLoading } = useAPI("nudgesShow", { id: id });

  return (
    <>
      {isLoading || !data ? (
        <FullPageLoader />
      ) : (
        <NudgesCreateEditForm
          initialData={data.nudge}
          mode={Mode.Edit}
          nudgeType={data.nudge.nudge_type}
        />
      )}
    </>
  );
};
