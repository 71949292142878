import {
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentIncident,
  StatusPageContentIncidentStatusEnum,
  StatusPageContentIncidentUpdateToStatusEnum,
} from "@incident-io/api";
import _ from "lodash";

import { ContentBoxTheme } from "./components/ContentBox";

// Returns an ordinal for component status that ranks them, such that the worse status has
// the highest rank.
export const STATUS_SEVERITY: {
  [key in
    | StatusPageContentComponentImpactStatusEnum
    | StatusPageAffectedComponentStatusEnum]: number;
} = {
  [StatusPageContentComponentImpactStatusEnum.UnderMaintenance]: 2,
  [StatusPageContentComponentImpactStatusEnum.DegradedPerformance]: 3,
  [StatusPageContentComponentImpactStatusEnum.PartialOutage]: 4,
  [StatusPageContentComponentImpactStatusEnum.FullOutage]: 5,
  [StatusPageAffectedComponentStatusEnum.Operational]: 1,
};

// Returns the status for the component given the severity rank
export const STATUS_FROM_SEVERITY: {
  [key: number]: StatusPageContentComponentImpactStatusEnum;
} = {
  [2]: StatusPageContentComponentImpactStatusEnum.UnderMaintenance,
  [3]: StatusPageContentComponentImpactStatusEnum.DegradedPerformance,
  [4]: StatusPageContentComponentImpactStatusEnum.PartialOutage,
  [5]: StatusPageContentComponentImpactStatusEnum.FullOutage,
};

export const THEME_FOR_STATUS: {
  [key in StatusPageAffectedComponentStatusEnum]: ContentBoxTheme;
} = {
  [StatusPageAffectedComponentStatusEnum.Operational]:
    ContentBoxTheme.Operational,
  [StatusPageAffectedComponentStatusEnum.UnderMaintenance]:
    ContentBoxTheme.UnderMaintenance,
  [StatusPageAffectedComponentStatusEnum.DegradedPerformance]:
    ContentBoxTheme.DegradedPerformance,
  [StatusPageAffectedComponentStatusEnum.PartialOutage]:
    ContentBoxTheme.PartialOutage,
  [StatusPageAffectedComponentStatusEnum.FullOutage]:
    ContentBoxTheme.FullOutage,
};

export const RESOLVED_STATUSES = [
  StatusPageContentIncidentStatusEnum.Resolved,
  StatusPageContentIncidentStatusEnum.MaintenanceComplete,
  StatusPageContentIncidentUpdateToStatusEnum.Resolved,
  StatusPageContentIncidentUpdateToStatusEnum.MaintenanceComplete,
];

export const isResolved = (
  status:
    | StatusPageContentIncidentStatusEnum
    | StatusPageContentIncidentUpdateToStatusEnum,
) => RESOLVED_STATUSES.includes(status);

export function assertUnreachable(x: never) {
  console.error(`Unreachable value: ${x}`);
  return null;
}

// This is helpful for debugging animations: slow them all down by increasing
// this value.
export const ANIMATION_MODIFIER = 1;

// This is only here for maintenance windows
// It works around the fact that maintenances are weird and don't have a start and an end timestamp
// We just have to calculate it from the component impacts
export const getMaintenanceImpactWindow = (
  impacts: StatusPageContentIncident["component_impacts"],
): [string | undefined, string | undefined] => {
  // get the min start of component impacts and max end.
  const start = _.minBy(impacts, (impact) => impact.start_at)?.start_at;

  const end = _.maxBy(impacts, (impact) => impact.end_at)?.end_at;

  return [start, end];
};

export type ComponentStatus<StatusEnum extends string | number> = {
  [key in StatusEnum]: {
    rank: number;
    background: string;
  };
};
export const COMPONENT_STATUSES: ComponentStatus<ComponentStatusEnum> = {
  [ComponentStatusEnum.Operational]: {
    rank: 0,
    background: "bg-[#84da83]",
  },
  [ComponentStatusEnum.UnderMaintenance]: {
    rank: 1,
    background: "bg-[#438dbd]",
  },
  [ComponentStatusEnum.DegradedPerformance]: {
    rank: 2,
    background: "bg-[#efd13d]",
  },
  [ComponentStatusEnum.PartialOutage]: {
    rank: 3,
    background: "bg-[#f9a764]",
  },
  [ComponentStatusEnum.FullOutage]: {
    rank: 4,
    background: "bg-[#f57a62]",
  },
};
