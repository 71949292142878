import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";

export const AISuggestionsNotificationsBadge = (): React.ReactElement => {
  return (
    <div className="absolute mt-0.5 -right-2 text-xs h-4 w-4 flex rounded-full justify-center">
      <Icon size={IconSize.Small} id={IconEnum.SparklesColoured} />
      <svg
        className="width:0;height:0;position:absolute;"
        aria-hidden="true"
        focusable="false"
      ></svg>
    </div>
  );
};
