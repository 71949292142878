import { StaffShowOrganisationResponseBody } from "@incident-io/api";

import { AttachSubscriptionButton } from "./AttachSubscriptionForm";
import { EnableOnCallButton } from "./EnableOnCallButton";
import { EnableResponseButton } from "./EnableResponseButton";
import { FeatureGatesButton } from "./FeatureGatesForm";
import { GrantOwnerButton } from "./GrantOwnerButton";
import { MakeDemoButton } from "./MakeDemoButton";
import { MakeSandboxButton } from "./MakeSandboxButton";
import { PlanSettingsButton } from "./PlanSettingsForm";
import { TrialManagementButton } from "./TrialManagement";

export const Controls = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <>
      <Section title="View and change feature access">
        <FeatureGatesButton data={data} />
        <EnableOnCallButton data={data} />
        <EnableResponseButton data={data} />
      </Section>

      <Section title="Manage subscription and billing">
        <PlanSettingsButton data={data} />
        <AttachSubscriptionButton data={data} />
      </Section>

      <Section title="Trials">
        <TrialManagementButton data={data} />
      </Section>

      <Section title="Sandbox/Demo">
        <MakeSandboxButton data={data} />
        <MakeDemoButton data={data} />
      </Section>

      <Section title="Assign an owner">
        <GrantOwnerButton data={data} />
      </Section>
    </>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <div className="p-3 text-sm rounded-2 bg-surface-secondary space-y-3">
    <span>{title}</span>
    <div className="flex gap-2">{children}</div>
  </div>
);
