/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody,
    AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBodyFromJSON,
    AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBodyToJSON,
    AtlassianConnectAppReceiveJiraCloudWebhookRequestBody,
    AtlassianConnectAppReceiveJiraCloudWebhookRequestBodyFromJSON,
    AtlassianConnectAppReceiveJiraCloudWebhookRequestBodyToJSON,
    AtlassianConnectAppShowInstallationInfoResponseBody,
    AtlassianConnectAppShowInstallationInfoResponseBodyFromJSON,
    AtlassianConnectAppShowInstallationInfoResponseBodyToJSON,
    AtlassianConnectAppShowOrganisationsWithInstallationResponseBody,
    AtlassianConnectAppShowOrganisationsWithInstallationResponseBodyFromJSON,
    AtlassianConnectAppShowOrganisationsWithInstallationResponseBodyToJSON,
} from '../models';

export interface AtlassianConnectAppConnectInstallationToOrganisationRequest {
    authorization: string;
}

export interface AtlassianConnectAppReceiveConfluenceCloudWebhookRequest {
    authorization: string;
    receiveConfluenceCloudWebhookRequestBody: AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody;
}

export interface AtlassianConnectAppReceiveJiraCloudWebhookRequest {
    authorization: string;
    receiveJiraCloudWebhookRequestBody: AtlassianConnectAppReceiveJiraCloudWebhookRequestBody;
}

export interface AtlassianConnectAppShowInstallationInfoRequest {
    authorization: string;
}

export interface AtlassianConnectAppShowOrganisationsWithInstallationRequest {
    authorization: string;
}

/**
 * 
 */
export class AtlassianConnectAppApi extends runtime.BaseAPI {

    /**
     * Connect an Atlassian Connect app installation to an organisation
     * ConnectInstallationToOrganisation Atlassian Connect app
     */
    async atlassianConnectAppConnectInstallationToOrganisationRaw(requestParameters: AtlassianConnectAppConnectInstallationToOrganisationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling atlassianConnectAppConnectInstallationToOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/auth/atlassian_connect_app/installation/organisation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connect an Atlassian Connect app installation to an organisation
     * ConnectInstallationToOrganisation Atlassian Connect app
     */
    async atlassianConnectAppConnectInstallationToOrganisation(requestParameters: AtlassianConnectAppConnectInstallationToOrganisationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianConnectAppConnectInstallationToOrganisationRaw(requestParameters, initOverrides);
    }

    /**
     * Receive a webhook from a Confluence Cloud instance where the Atlassian Connect app is installed. This is used to receive document updates and other events from Confluence Cloud.
     * ReceiveConfluenceCloudWebhook Atlassian Connect app
     */
    async atlassianConnectAppReceiveConfluenceCloudWebhookRaw(requestParameters: AtlassianConnectAppReceiveConfluenceCloudWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling atlassianConnectAppReceiveConfluenceCloudWebhook.');
        }

        if (requestParameters.receiveConfluenceCloudWebhookRequestBody === null || requestParameters.receiveConfluenceCloudWebhookRequestBody === undefined) {
            throw new runtime.RequiredError('receiveConfluenceCloudWebhookRequestBody','Required parameter requestParameters.receiveConfluenceCloudWebhookRequestBody was null or undefined when calling atlassianConnectAppReceiveConfluenceCloudWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/atlassian_connect_app/webhooks/confluencecloud`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBodyToJSON(requestParameters.receiveConfluenceCloudWebhookRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Receive a webhook from a Confluence Cloud instance where the Atlassian Connect app is installed. This is used to receive document updates and other events from Confluence Cloud.
     * ReceiveConfluenceCloudWebhook Atlassian Connect app
     */
    async atlassianConnectAppReceiveConfluenceCloudWebhook(requestParameters: AtlassianConnectAppReceiveConfluenceCloudWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianConnectAppReceiveConfluenceCloudWebhookRaw(requestParameters, initOverrides);
    }

    /**
     * Receive a webhook from a Jira Cloud instance where the Atlassian Connect app is installed. This is used to receive issue updates and other events from Jira Cloud.
     * ReceiveJiraCloudWebhook Atlassian Connect app
     */
    async atlassianConnectAppReceiveJiraCloudWebhookRaw(requestParameters: AtlassianConnectAppReceiveJiraCloudWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling atlassianConnectAppReceiveJiraCloudWebhook.');
        }

        if (requestParameters.receiveJiraCloudWebhookRequestBody === null || requestParameters.receiveJiraCloudWebhookRequestBody === undefined) {
            throw new runtime.RequiredError('receiveJiraCloudWebhookRequestBody','Required parameter requestParameters.receiveJiraCloudWebhookRequestBody was null or undefined when calling atlassianConnectAppReceiveJiraCloudWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/atlassian_connect_app/webhooks/jiracloud`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AtlassianConnectAppReceiveJiraCloudWebhookRequestBodyToJSON(requestParameters.receiveJiraCloudWebhookRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Receive a webhook from a Jira Cloud instance where the Atlassian Connect app is installed. This is used to receive issue updates and other events from Jira Cloud.
     * ReceiveJiraCloudWebhook Atlassian Connect app
     */
    async atlassianConnectAppReceiveJiraCloudWebhook(requestParameters: AtlassianConnectAppReceiveJiraCloudWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianConnectAppReceiveJiraCloudWebhookRaw(requestParameters, initOverrides);
    }

    /**
     * Get Atlassian Connect app installation info for the provided JWT. This is used prior to installing the app to an organisation to get the product type and display URL.
     * ShowInstallationInfo Atlassian Connect app
     */
    async atlassianConnectAppShowInstallationInfoRaw(requestParameters: AtlassianConnectAppShowInstallationInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AtlassianConnectAppShowInstallationInfoResponseBody>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling atlassianConnectAppShowInstallationInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/auth/atlassian_connect_app/installation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AtlassianConnectAppShowInstallationInfoResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Atlassian Connect app installation info for the provided JWT. This is used prior to installing the app to an organisation to get the product type and display URL.
     * ShowInstallationInfo Atlassian Connect app
     */
    async atlassianConnectAppShowInstallationInfo(requestParameters: AtlassianConnectAppShowInstallationInfoRequest, initOverrides?: RequestInit): Promise<AtlassianConnectAppShowInstallationInfoResponseBody> {
        const response = await this.atlassianConnectAppShowInstallationInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all organisations with this client key that are installed with Atlassian Connect
     * ShowOrganisationsWithInstallation Atlassian Connect app
     */
    async atlassianConnectAppShowOrganisationsWithInstallationRaw(requestParameters: AtlassianConnectAppShowOrganisationsWithInstallationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AtlassianConnectAppShowOrganisationsWithInstallationResponseBody>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling atlassianConnectAppShowOrganisationsWithInstallation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/auth/atlassian_connect_app/organisations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AtlassianConnectAppShowOrganisationsWithInstallationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get all organisations with this client key that are installed with Atlassian Connect
     * ShowOrganisationsWithInstallation Atlassian Connect app
     */
    async atlassianConnectAppShowOrganisationsWithInstallation(requestParameters: AtlassianConnectAppShowOrganisationsWithInstallationRequest, initOverrides?: RequestInit): Promise<AtlassianConnectAppShowOrganisationsWithInstallationResponseBody> {
        const response = await this.atlassianConnectAppShowOrganisationsWithInstallationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
