/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DecisionTree,
    DecisionTreeFromJSON,
    DecisionTreeFromJSONTyped,
    DecisionTreeToJSON,
} from './DecisionTree';

/**
 * 
 * @export
 * @interface DecisionFlowsCreateRequestBody
 */
export interface DecisionFlowsCreateRequestBody {
    /**
     * A short description for this decision flow
     * @type {string}
     * @memberof DecisionFlowsCreateRequestBody
     */
    description: string;
    /**
     * Whether this decision flow can be used in active incidents
     * @type {boolean}
     * @memberof DecisionFlowsCreateRequestBody
     */
    is_live: boolean;
    /**
     * A human readable name for the decision flow (displayed at the top of the modal)
     * @type {string}
     * @memberof DecisionFlowsCreateRequestBody
     */
    name: string;
    /**
     * 
     * @type {DecisionTree}
     * @memberof DecisionFlowsCreateRequestBody
     */
    tree: DecisionTree;
}

export function DecisionFlowsCreateRequestBodyFromJSON(json: any): DecisionFlowsCreateRequestBody {
    return DecisionFlowsCreateRequestBodyFromJSONTyped(json, false);
}

export function DecisionFlowsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionFlowsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'is_live': json['is_live'],
        'name': json['name'],
        'tree': DecisionTreeFromJSON(json['tree']),
    };
}

export function DecisionFlowsCreateRequestBodyToJSON(value?: DecisionFlowsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'is_live': value.is_live,
        'name': value.name,
        'tree': DecisionTreeToJSON(value.tree),
    };
}

