/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertSourceRouting
 */
export interface AlertSourceRouting {
    /**
     * Email address this alert source receives alerts to
     * @type {string}
     * @memberof AlertSourceRouting
     */
    email_address?: string;
    /**
     * Catalog entry IDs to Jira projects this alert source creates alerts for
     * @type {Array<string>}
     * @memberof AlertSourceRouting
     */
    jira_project_ids?: Array<string>;
}

export function AlertSourceRoutingFromJSON(json: any): AlertSourceRouting {
    return AlertSourceRoutingFromJSONTyped(json, false);
}

export function AlertSourceRoutingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceRouting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'jira_project_ids': !exists(json, 'jira_project_ids') ? undefined : json['jira_project_ids'],
    };
}

export function AlertSourceRoutingToJSON(value?: AlertSourceRouting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_address': value.email_address,
        'jira_project_ids': value.jira_project_ids,
    };
}

