/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegacyPostmortemTemplateBlock,
    LegacyPostmortemTemplateBlockFromJSON,
    LegacyPostmortemTemplateBlockFromJSONTyped,
    LegacyPostmortemTemplateBlockToJSON,
} from './LegacyPostmortemTemplateBlock';

/**
 * 
 * @export
 * @interface PostmortemsListLegacyTemplateStandardContentBlocksResponseBody
 */
export interface PostmortemsListLegacyTemplateStandardContentBlocksResponseBody {
    /**
     * 
     * @type {Array<LegacyPostmortemTemplateBlock>}
     * @memberof PostmortemsListLegacyTemplateStandardContentBlocksResponseBody
     */
    blocks: Array<LegacyPostmortemTemplateBlock>;
}

export function PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyFromJSON(json: any): PostmortemsListLegacyTemplateStandardContentBlocksResponseBody {
    return PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsListLegacyTemplateStandardContentBlocksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocks': ((json['blocks'] as Array<any>).map(LegacyPostmortemTemplateBlockFromJSON)),
    };
}

export function PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyToJSON(value?: PostmortemsListLegacyTemplateStandardContentBlocksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocks': ((value.blocks as Array<any>).map(LegacyPostmortemTemplateBlockToJSON)),
    };
}

