/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertHeadlineMetric,
    AlertHeadlineMetricFromJSON,
    AlertHeadlineMetricFromJSONTyped,
    AlertHeadlineMetricToJSON,
} from './AlertHeadlineMetric';
import {
    AlertWorkload,
    AlertWorkloadFromJSON,
    AlertWorkloadFromJSONTyped,
    AlertWorkloadToJSON,
} from './AlertWorkload';

/**
 * 
 * @export
 * @interface AlertsGetInsightsResponseBody
 */
export interface AlertsGetInsightsResponseBody {
    /**
     * 
     * @type {Array<AlertHeadlineMetric>}
     * @memberof AlertsGetInsightsResponseBody
     */
    headline_metrics: Array<AlertHeadlineMetric>;
    /**
     * 
     * @type {AlertWorkload}
     * @memberof AlertsGetInsightsResponseBody
     */
    workload: AlertWorkload;
}

export function AlertsGetInsightsResponseBodyFromJSON(json: any): AlertsGetInsightsResponseBody {
    return AlertsGetInsightsResponseBodyFromJSONTyped(json, false);
}

export function AlertsGetInsightsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGetInsightsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headline_metrics': ((json['headline_metrics'] as Array<any>).map(AlertHeadlineMetricFromJSON)),
        'workload': AlertWorkloadFromJSON(json['workload']),
    };
}

export function AlertsGetInsightsResponseBodyToJSON(value?: AlertsGetInsightsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headline_metrics': ((value.headline_metrics as Array<any>).map(AlertHeadlineMetricToJSON)),
        'workload': AlertWorkloadToJSON(value.workload),
    };
}

