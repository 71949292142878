/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserLinksCreateSelfRequestBody,
    UserLinksCreateSelfRequestBodyFromJSON,
    UserLinksCreateSelfRequestBodyToJSON,
    UserLinksCreateUserLinksRequestBody,
    UserLinksCreateUserLinksRequestBodyFromJSON,
    UserLinksCreateUserLinksRequestBodyToJSON,
    UserLinksListConnectableUsersResponseBody,
    UserLinksListConnectableUsersResponseBodyFromJSON,
    UserLinksListConnectableUsersResponseBodyToJSON,
    UserLinksListSelfResponseBody,
    UserLinksListSelfResponseBodyFromJSON,
    UserLinksListSelfResponseBodyToJSON,
    UserLinksListUserLinksResponseBody,
    UserLinksListUserLinksResponseBodyFromJSON,
    UserLinksListUserLinksResponseBodyToJSON,
} from '../models';

export interface UserLinksCreateSelfRequest {
    createSelfRequestBody: UserLinksCreateSelfRequestBody;
}

export interface UserLinksCreateUserLinksRequest {
    userId: string;
    createUserLinksRequestBody: UserLinksCreateUserLinksRequestBody;
}

export interface UserLinksDeleteSelfRequest {
    userLinkId: string;
}

export interface UserLinksDeleteUserLinkRequest {
    userLinkId: string;
}

export interface UserLinksListConnectableUsersRequest {
    query?: string;
    userIdToLink?: string;
}

export interface UserLinksListUserLinksRequest {
    userId: string;
}

/**
 * 
 */
export class UserLinksApi extends runtime.BaseAPI {

    /**
     * Create a new user link for the current user
     * CreateSelf User Links
     */
    async userLinksCreateSelfRaw(requestParameters: UserLinksCreateSelfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createSelfRequestBody === null || requestParameters.createSelfRequestBody === undefined) {
            throw new runtime.RequiredError('createSelfRequestBody','Required parameter requestParameters.createSelfRequestBody was null or undefined when calling userLinksCreateSelf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_links/self`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLinksCreateSelfRequestBodyToJSON(requestParameters.createSelfRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new user link for the current user
     * CreateSelf User Links
     */
    async userLinksCreateSelf(requestParameters: UserLinksCreateSelfRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userLinksCreateSelfRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new user link for a given user
     * CreateUserLinks User Links
     */
    async userLinksCreateUserLinksRaw(requestParameters: UserLinksCreateUserLinksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userLinksCreateUserLinks.');
        }

        if (requestParameters.createUserLinksRequestBody === null || requestParameters.createUserLinksRequestBody === undefined) {
            throw new runtime.RequiredError('createUserLinksRequestBody','Required parameter requestParameters.createUserLinksRequestBody was null or undefined when calling userLinksCreateUserLinks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_links/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLinksCreateUserLinksRequestBodyToJSON(requestParameters.createUserLinksRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new user link for a given user
     * CreateUserLinks User Links
     */
    async userLinksCreateUserLinks(requestParameters: UserLinksCreateUserLinksRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userLinksCreateUserLinksRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an existing user link for the current user
     * DeleteSelf User Links
     */
    async userLinksDeleteSelfRaw(requestParameters: UserLinksDeleteSelfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userLinkId === null || requestParameters.userLinkId === undefined) {
            throw new runtime.RequiredError('userLinkId','Required parameter requestParameters.userLinkId was null or undefined when calling userLinksDeleteSelf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_links/self/{user_link_id}`.replace(`{${"user_link_id"}}`, encodeURIComponent(String(requestParameters.userLinkId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing user link for the current user
     * DeleteSelf User Links
     */
    async userLinksDeleteSelf(requestParameters: UserLinksDeleteSelfRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userLinksDeleteSelfRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an existing user link for a given user
     * DeleteUserLink User Links
     */
    async userLinksDeleteUserLinkRaw(requestParameters: UserLinksDeleteUserLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userLinkId === null || requestParameters.userLinkId === undefined) {
            throw new runtime.RequiredError('userLinkId','Required parameter requestParameters.userLinkId was null or undefined when calling userLinksDeleteUserLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_links/{user_link_id}`.replace(`{${"user_link_id"}}`, encodeURIComponent(String(requestParameters.userLinkId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing user link for a given user
     * DeleteUserLink User Links
     */
    async userLinksDeleteUserLink(requestParameters: UserLinksDeleteUserLinkRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userLinksDeleteUserLinkRaw(requestParameters, initOverrides);
    }

    /**
     * Lists external users that aren\'t currently connected to an incident.io user
     * ListConnectableUsers User Links
     */
    async userLinksListConnectableUsersRaw(requestParameters: UserLinksListConnectableUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserLinksListConnectableUsersResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.userIdToLink !== undefined) {
            queryParameters['user_id_to_link'] = requestParameters.userIdToLink;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_links/connectable_users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLinksListConnectableUsersResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists external users that aren\'t currently connected to an incident.io user
     * ListConnectableUsers User Links
     */
    async userLinksListConnectableUsers(requestParameters: UserLinksListConnectableUsersRequest, initOverrides?: RequestInit): Promise<UserLinksListConnectableUsersResponseBody> {
        const response = await this.userLinksListConnectableUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all user links for the current user
     * ListSelf User Links
     */
    async userLinksListSelfRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserLinksListSelfResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_links/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLinksListSelfResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a list of all user links for the current user
     * ListSelf User Links
     */
    async userLinksListSelf(initOverrides?: RequestInit): Promise<UserLinksListSelfResponseBody> {
        const response = await this.userLinksListSelfRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all user links for a given user
     * ListUserLinks User Links
     */
    async userLinksListUserLinksRaw(requestParameters: UserLinksListUserLinksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserLinksListUserLinksResponseBody>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userLinksListUserLinks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_links/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLinksListUserLinksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a list of all user links for a given user
     * ListUserLinks User Links
     */
    async userLinksListUserLinks(requestParameters: UserLinksListUserLinksRequest, initOverrides?: RequestInit): Promise<UserLinksListUserLinksResponseBody> {
        const response = await this.userLinksListUserLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
