/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleLayerPayload,
    ScheduleLayerPayloadFromJSON,
    ScheduleLayerPayloadFromJSONTyped,
    ScheduleLayerPayloadToJSON,
} from './ScheduleLayerPayload';
import {
    ScheduleRotationHandover,
    ScheduleRotationHandoverFromJSON,
    ScheduleRotationHandoverFromJSONTyped,
    ScheduleRotationHandoverToJSON,
} from './ScheduleRotationHandover';
import {
    WeekdayInterval,
    WeekdayIntervalFromJSON,
    WeekdayIntervalFromJSONTyped,
    WeekdayIntervalToJSON,
} from './WeekdayInterval';

/**
 * 
 * @export
 * @interface ScheduleRotationPayload
 */
export interface ScheduleRotationPayload {
    /**
     * When this rotation config will be effective from
     * @type {Date}
     * @memberof ScheduleRotationPayload
     */
    effective_from?: Date;
    /**
     * Defines the next moment we'll trigger a handover
     * @type {Date}
     * @memberof ScheduleRotationPayload
     */
    handover_start_at: Date;
    /**
     * Defines the handover intervals for this rota, in order they should apply
     * @type {Array<ScheduleRotationHandover>}
     * @memberof ScheduleRotationPayload
     */
    handovers: Array<ScheduleRotationHandover>;
    /**
     * Unique internal ID of the rotation
     * @type {string}
     * @memberof ScheduleRotationPayload
     */
    id?: string;
    /**
     * Controls how many people are on-call concurrently
     * @type {Array<ScheduleLayerPayload>}
     * @memberof ScheduleRotationPayload
     */
    layers?: Array<ScheduleLayerPayload>;
    /**
     * Human readable name synced from external provider
     * @type {string}
     * @memberof ScheduleRotationPayload
     */
    name: string;
    /**
     * Users who are available to be scheduled on this rota
     * @type {Array<string>}
     * @memberof ScheduleRotationPayload
     */
    user_ids: Array<string>;
    /**
     * Optional restrictions that define when to schedule people for this rota
     * @type {Array<WeekdayInterval>}
     * @memberof ScheduleRotationPayload
     */
    working_intervals: Array<WeekdayInterval>;
}

export function ScheduleRotationPayloadFromJSON(json: any): ScheduleRotationPayload {
    return ScheduleRotationPayloadFromJSONTyped(json, false);
}

export function ScheduleRotationPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleRotationPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'effective_from': !exists(json, 'effective_from') ? undefined : (new Date(json['effective_from'])),
        'handover_start_at': (new Date(json['handover_start_at'])),
        'handovers': ((json['handovers'] as Array<any>).map(ScheduleRotationHandoverFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'layers': !exists(json, 'layers') ? undefined : ((json['layers'] as Array<any>).map(ScheduleLayerPayloadFromJSON)),
        'name': json['name'],
        'user_ids': json['user_ids'],
        'working_intervals': ((json['working_intervals'] as Array<any>).map(WeekdayIntervalFromJSON)),
    };
}

export function ScheduleRotationPayloadToJSON(value?: ScheduleRotationPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'effective_from': value.effective_from === undefined ? undefined : (value.effective_from.toISOString()),
        'handover_start_at': (value.handover_start_at.toISOString()),
        'handovers': ((value.handovers as Array<any>).map(ScheduleRotationHandoverToJSON)),
        'id': value.id,
        'layers': value.layers === undefined ? undefined : ((value.layers as Array<any>).map(ScheduleLayerPayloadToJSON)),
        'name': value.name,
        'user_ids': value.user_ids,
        'working_intervals': ((value.working_intervals as Array<any>).map(WeekdayIntervalToJSON)),
    };
}

