/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueTrackerIssueSyncFailure,
    IssueTrackerIssueSyncFailureFromJSON,
    IssueTrackerIssueSyncFailureFromJSONTyped,
    IssueTrackerIssueSyncFailureToJSON,
} from './IssueTrackerIssueSyncFailure';

/**
 * 
 * @export
 * @interface IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody
 */
export interface IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody {
    /**
     * 
     * @type {Array<IssueTrackerIssueSyncFailure>}
     * @memberof IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody
     */
    sync_failures?: Array<IssueTrackerIssueSyncFailure>;
}

export function IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBodyFromJSON(json: any): IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody {
    return IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sync_failures': !exists(json, 'sync_failures') ? undefined : ((json['sync_failures'] as Array<any>).map(IssueTrackerIssueSyncFailureFromJSON)),
    };
}

export function IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBodyToJSON(value?: IssueTrackersGetIncidentIssueTrackerIssueSyncFailuresResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sync_failures': value.sync_failures === undefined ? undefined : ((value.sync_failures as Array<any>).map(IssueTrackerIssueSyncFailureToJSON)),
    };
}

