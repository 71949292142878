import type { SVGProps } from "react";
import * as React from "react";
const SvgExpression = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5 17v-2.333l4.314-5-4.138-4.47V3h10.06v2.431h-4.609c-.261 0-.555-.006-.882-.02-.314-.012-.653-.038-1.02-.078l3.51 3.883v.745l-4.098 4.706c.458-.04.876-.066 1.255-.079.392-.026.81-.039 1.255-.039h4.765V17H5Z"
    />
  </svg>
);
export default SvgExpression;
