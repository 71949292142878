import type { SVGProps } from "react";
import * as React from "react";
const SvgTextBold = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.495 9.187a4.074 4.074 0 0 0 .93-2.699C14.372 4.239 12.41 2.5 10.16 2.5H4.882a.682.682 0 0 0-.682.682v13.636c0 .377.305.682.682.682h6.32c2.31 0 4.35-1.7 4.569-4a4.433 4.433 0 0 0-2.276-4.313Zm-7.25-4.642h4.091c1.128 0 2.046.918 2.046 2.046a2.048 2.048 0 0 1-2.046 2.045h-4.09v-4.09Zm5.114 10.91H6.245v-4.773h5.114a2.39 2.39 0 0 1 2.386 2.386 2.389 2.389 0 0 1-2.386 2.386Z"
    />
  </svg>
);
export default SvgTextBold;
