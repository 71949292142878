/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelecomTwilioInboundCallRequestBody
 */
export interface TelecomTwilioInboundCallRequestBody {
    /**
     * Twilio's unique ID of the call
     * @type {string}
     * @memberof TelecomTwilioInboundCallRequestBody
     */
    CallSid: string;
    /**
     * The status of the call
     * @type {string}
     * @memberof TelecomTwilioInboundCallRequestBody
     */
    CallStatus?: TelecomTwilioInboundCallRequestBodyCallStatusEnum;
    /**
     * The phone number that made the call
     * @type {string}
     * @memberof TelecomTwilioInboundCallRequestBody
     */
    From: string;
    /**
     * The phone number that the call was made to. Should be one of our Twilio active numbers.
     * @type {string}
     * @memberof TelecomTwilioInboundCallRequestBody
     */
    To: string;
}

/**
* @export
* @enum {string}
*/
export enum TelecomTwilioInboundCallRequestBodyCallStatusEnum {
    Queued = 'queued',
    Ringing = 'ringing',
    Initiated = 'initiated',
    InProgress = 'in-progress',
    Completed = 'completed',
    Busy = 'busy',
    Failed = 'failed',
    NoAnswer = 'no-answer'
}

export function TelecomTwilioInboundCallRequestBodyFromJSON(json: any): TelecomTwilioInboundCallRequestBody {
    return TelecomTwilioInboundCallRequestBodyFromJSONTyped(json, false);
}

export function TelecomTwilioInboundCallRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelecomTwilioInboundCallRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CallSid': json['CallSid'],
        'CallStatus': !exists(json, 'CallStatus') ? undefined : json['CallStatus'],
        'From': json['From'],
        'To': json['To'],
    };
}

export function TelecomTwilioInboundCallRequestBodyToJSON(value?: TelecomTwilioInboundCallRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CallSid': value.CallSid,
        'CallStatus': value.CallStatus,
        'From': value.From,
        'To': value.To,
    };
}

