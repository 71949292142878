/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    IncidentTimestamp,
    IncidentTimestampFromJSON,
    IncidentTimestampFromJSONTyped,
    IncidentTimestampToJSON,
} from './IncidentTimestamp';
import {
    IncidentTimestampValue,
    IncidentTimestampValueFromJSON,
    IncidentTimestampValueFromJSONTyped,
    IncidentTimestampValueToJSON,
} from './IncidentTimestampValue';

/**
 * 
 * @export
 * @interface IncidentTimestampSet
 */
export interface IncidentTimestampSet {
    /**
     * 
     * @type {Actor}
     * @memberof IncidentTimestampSet
     */
    creator: Actor;
    /**
     * 
     * @type {IncidentTimestamp}
     * @memberof IncidentTimestampSet
     */
    incident_timestamp: IncidentTimestamp;
    /**
     * 
     * @type {IncidentTimestampValue}
     * @memberof IncidentTimestampSet
     */
    incident_timestamp_new_value: IncidentTimestampValue;
    /**
     * 
     * @type {IncidentTimestampValue}
     * @memberof IncidentTimestampSet
     */
    incident_timestamp_old_value: IncidentTimestampValue;
}

export function IncidentTimestampSetFromJSON(json: any): IncidentTimestampSet {
    return IncidentTimestampSetFromJSONTyped(json, false);
}

export function IncidentTimestampSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': ActorFromJSON(json['creator']),
        'incident_timestamp': IncidentTimestampFromJSON(json['incident_timestamp']),
        'incident_timestamp_new_value': IncidentTimestampValueFromJSON(json['incident_timestamp_new_value']),
        'incident_timestamp_old_value': IncidentTimestampValueFromJSON(json['incident_timestamp_old_value']),
    };
}

export function IncidentTimestampSetToJSON(value?: IncidentTimestampSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': ActorToJSON(value.creator),
        'incident_timestamp': IncidentTimestampToJSON(value.incident_timestamp),
        'incident_timestamp_new_value': IncidentTimestampValueToJSON(value.incident_timestamp_new_value),
        'incident_timestamp_old_value': IncidentTimestampValueToJSON(value.incident_timestamp_old_value),
    };
}

