/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BecomeEligibleCTA,
    BecomeEligibleCTAFromJSON,
    BecomeEligibleCTAFromJSONTyped,
    BecomeEligibleCTAToJSON,
} from './BecomeEligibleCTA';
import {
    EngineParam,
    EngineParamFromJSON,
    EngineParamFromJSONTyped,
    EngineParamToJSON,
} from './EngineParam';

/**
 * 
 * @export
 * @interface Step
 */
export interface Step {
    /**
     * 
     * @type {BecomeEligibleCTA}
     * @memberof Step
     */
    become_eligible_cta?: BecomeEligibleCTA;
    /**
     * Human readable description of what this step does
     * @type {string}
     * @memberof Step
     */
    description: string;
    /**
     * Human readable identifier for the group this step belongs to
     * @type {string}
     * @memberof Step
     */
    group_label: StepGroupLabelEnum;
    /**
     * Human readable identifier for this step
     * @type {string}
     * @memberof Step
     */
    label: string;
    /**
     * Unique name of the step in the engine
     * @type {string}
     * @memberof Step
     */
    name: string;
    /**
     * Whether the current organisation can use this step
     * @type {boolean}
     * @memberof Step
     */
    organisation_is_eligible: boolean;
    /**
     * Type information for the step parameters
     * @type {Array<EngineParam>}
     * @memberof Step
     */
    params: Array<EngineParam>;
    /**
     * Which release channel this step is on
     * @type {string}
     * @memberof Step
     */
    release_channel: StepReleaseChannelEnum;
    /**
     * Human readable warning applicable to the step
     * @type {string}
     * @memberof Step
     */
    warning?: string;
}

/**
* @export
* @enum {string}
*/
export enum StepGroupLabelEnum {
    General = 'general',
    Communicate = 'communicate',
    Escalate = 'escalate',
    Announce = 'announce',
    Assign = 'assign'
}/**
* @export
* @enum {string}
*/
export enum StepReleaseChannelEnum {
    Deprecated = 'deprecated',
    Internal = 'internal',
    Rapid = 'rapid',
    Stable = 'stable'
}

export function StepFromJSON(json: any): Step {
    return StepFromJSONTyped(json, false);
}

export function StepFromJSONTyped(json: any, ignoreDiscriminator: boolean): Step {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'become_eligible_cta': !exists(json, 'become_eligible_cta') ? undefined : BecomeEligibleCTAFromJSON(json['become_eligible_cta']),
        'description': json['description'],
        'group_label': json['group_label'],
        'label': json['label'],
        'name': json['name'],
        'organisation_is_eligible': json['organisation_is_eligible'],
        'params': ((json['params'] as Array<any>).map(EngineParamFromJSON)),
        'release_channel': json['release_channel'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
    };
}

export function StepToJSON(value?: Step | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'become_eligible_cta': BecomeEligibleCTAToJSON(value.become_eligible_cta),
        'description': value.description,
        'group_label': value.group_label,
        'label': value.label,
        'name': value.name,
        'organisation_is_eligible': value.organisation_is_eligible,
        'params': ((value.params as Array<any>).map(EngineParamToJSON)),
        'release_channel': value.release_channel,
        'warning': value.warning,
    };
}

