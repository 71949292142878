import "react-phone-number-input/style.css";

import {
  OnCallNotificationMethod,
  OnCallNotificationMethodPhoneNumberStatusEnum,
  OnCallNotificationMethodV2MethodTypeEnum,
  OnCallNotificationMethodV2StatusEnum,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  LocalRelativeDateTime,
  Tooltip,
  Txt,
} from "@incident-ui";
import { parsePhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { Identity } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { useRevalidate } from "../../../utils/use-revalidate";
import { AddContactMethodModal } from "./AddContactMethodModal";
import { OnCallNotificationBox } from "./OnCallNotificationsBox";
import { Method } from "./OnCallNotificationsPage";
import { RemoveMobileAppModal } from "./OnCallNotificationsRemoveMobileAppModal";
import { TestContactMethodModal } from "./OnCallTestNotificationRuleModal";
import { VerifyNotificationMethodModal } from "./OnCallVerifyNotificationRuleModal";

export type MethodDisplayItem = {
  methodId: string | undefined;
  type: OnCallNotificationMethodV2MethodTypeEnum;
  title: string;
  value: string;
  icon: IconEnum;
  operational: boolean;
  lastUsedAt?: Date;
  mobileKeyID?: string;
};

const makeMethodDisplayItem = (
  identity: Identity | null,
  method: Method,
): MethodDisplayItem => {
  switch (method.method_type) {
    case OnCallNotificationMethodV2MethodTypeEnum.App:
      const mobileKey = method.app;
      return {
        methodId: undefined, // You can't delete the "app" method, but you can delete individual mobile keys
        type: OnCallNotificationMethodV2MethodTypeEnum.App,
        title: "Push",
        value: mobileKey?.device_name ?? "Unknown device",
        operational: mobileKey?.push_notifications_enabled ?? false,
        icon: IconEnum.Vibrate,
        lastUsedAt: mobileKey?.last_used_at,
        mobileKeyID: mobileKey?.id,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.Email:
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.Email,
        methodId: undefined, // You can't delete the "email" method, we make it for you and use your email from your user object
        title: "Email",
        value: identity?.user_email ?? "",
        operational: identity?.user_email != null,
        icon: IconEnum.Email,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.Phone:
      const phoneData: OnCallNotificationMethod | undefined = method.phone;
      if (!phoneData) {
        throw new Error("Phone method is missing phone data");
      }
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.Phone,
        methodId: phoneData.id,
        title: "Phone",
        value:
          parsePhoneNumber(
            phoneData.phone_number ?? "",
          ).formatInternational() || "",
        operational:
          phoneData.phone_number != null &&
          phoneData.phone_number_status !==
            OnCallNotificationMethodPhoneNumberStatusEnum.Blocked,
        icon: IconEnum.Call,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.Slack:
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.Slack,
        methodId: undefined, // You can't remove Slack manually
        title: "Slack",
        value: identity?.user_name ?? "",
        operational: identity?.user_name != null,
        icon: IconEnum.Slack,
      };
    case OnCallNotificationMethodV2MethodTypeEnum.MicrosoftTeams:
      return {
        type: OnCallNotificationMethodV2MethodTypeEnum.MicrosoftTeams,
        methodId: undefined, // You can't remove Teams manually
        title: "Teams",
        value: identity?.user_name ?? "",
        operational: identity?.user_name != null,
        icon: IconEnum.MicrosoftTeams,
      };
    default:
      assertUnreachable(method.method_type);
      return {
        methodId: undefined,
        type: method.method_type,
        title: "",
        value: "",
        operational: false,
        icon: IconEnum.Email,
      };
  }
};

export const ContactMethods = ({
  methods,
  showAddMethodModal,
  setShowAddMethodModal,
}: {
  methods: Method[];
  showAddMethodModal: { show: boolean; allowEmail: boolean };
  setShowAddMethodModal: ({
    show,
    allowEmail,
  }: {
    show: boolean;
    allowEmail: boolean;
  }) => void;
}) => {
  const { identity } = useIdentity();
  const [showVerifyMethodModal, setShowVerifyMethodModal] =
    useState<OnCallNotificationMethod | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<
    false | string
  >(false);
  const [showRemoveMobileAppModal, setShowRemoveMobileAppModal] = useState<
    false | string
  >(false);

  const [testModalMethod, setTestModalMethod] = useState<
    MethodDisplayItem | undefined
  >(undefined);

  const revalidateNotificationMethods = useRevalidate([
    "onCallNotificationsListMethodsV2",
    "onCallOnboardingCurrentOnboardingState",
  ]);

  const { trigger: onDelete, isMutating: isDestroying } = useAPIMutation(
    "onCallNotificationsListMethodsV2",
    undefined,
    async (apiClient, req: { id: string }) => {
      await apiClient.onCallNotificationsDestroyMethod(req);
    },
    {
      onSuccess: () => setShowConfirmDeleteModal(false),
    },
  );

  return (
    <OnCallNotificationBox
      title={"Contact methods"}
      description={"Tell us how you’d like to be reached."}
      className={"items-start"}
    >
      <div
        className={tcx(
          "w-full flex bg-white flex-col border border-stroke rounded-2",
          {
            "mb-4": methods.length > 0,
            "border-0": methods.length === 0,
          },
        )}
      >
        {methods.map((m) => {
          if (
            m.method_type === OnCallNotificationMethodV2MethodTypeEnum.App &&
            !m.app
          ) {
            return null;
          }
          const methodDisplayItem = makeMethodDisplayItem(identity, m);

          const numberIsPendingVerification =
            m.status ===
              OnCallNotificationMethodV2StatusEnum.PendingVerification &&
            m.phone &&
            m.phone.phone_number_status !==
              OnCallNotificationMethodPhoneNumberStatusEnum.Blocked;

          const phone = m.phone;

          return (
            <div
              key={methodDisplayItem.methodId ?? methodDisplayItem.type}
              className="w-full p-4 border-b last border-stroke justify-between items-center inline-flex last:border-b-0 !max-h-12"
            >
              <div className="justify-start items-center gap-3 flex">
                <div className="h-5 justify-start gap-2 flex w-[100px]">
                  <Icon
                    id={methodDisplayItem.icon}
                    size={IconSize.Medium}
                    className={"text-slate-600"}
                  />
                  <Txt bold>{methodDisplayItem.title}</Txt>
                </div>
                <Txt>{methodDisplayItem.value}</Txt>
                {methodDisplayItem.lastUsedAt && (
                  <Txt lightGrey>
                    Last used{" "}
                    <LocalRelativeDateTime
                      className={"hover:!no-underline"}
                      date={methodDisplayItem.lastUsedAt}
                    />
                  </Txt>
                )}
                {numberIsPendingVerification && (
                  <Badge theme={BadgeTheme.Warning}>Unverified</Badge>
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                {numberIsPendingVerification && phone && (
                  <Button
                    title={"Verify"}
                    analyticsTrackingId={"verify-on-call-notification-method"}
                    theme={ButtonTheme.Secondary}
                    size={BadgeSize.Medium}
                    onClick={() => setShowVerifyMethodModal(phone)}
                  >
                    Verify
                  </Button>
                )}
                {m.phone &&
                  m.phone.phone_number_status ===
                    OnCallNotificationMethodPhoneNumberStatusEnum.Blocked && (
                    <Tooltip
                      content={`This number has been unsubscribed. To reactivate this number, send START to ${m.phone.expected_switchboard_sender} or any other number you have received incident.io on-call notifications from.`}
                    >
                      <div>
                        <Badge theme={BadgeTheme.Error}>Unsubscribed</Badge>
                      </div>
                    </Tooltip>
                  )}
                {methodDisplayItem.methodId || methodDisplayItem.mobileKeyID ? (
                  <DropdownMenu
                    menuClassName={"w-[140px] !py-1"}
                    side={"bottom"}
                    triggerButton={
                      <Button
                        theme={ButtonTheme.Naked}
                        type="button"
                        className="-ml-2"
                        analyticsTrackingId="on-call-notification-method-options"
                        icon={IconEnum.DotsVerticalNopad}
                        iconProps={{ size: IconSize.Medium }}
                        title="On call notification method options"
                      />
                    }
                  >
                    {methodDisplayItem.methodId ? (
                      <DropdownMenuItem
                        onSelect={() => {
                          if (methodDisplayItem.methodId) {
                            setShowConfirmDeleteModal(
                              methodDisplayItem.methodId,
                            );
                          }
                        }}
                        analyticsTrackingId={
                          "on-call-notification-method-delete"
                        }
                        label="Delete"
                        destructive
                      >
                        Delete
                      </DropdownMenuItem>
                    ) : null}
                    {methodDisplayItem.mobileKeyID ? (
                      <DropdownMenuItem
                        onSelect={() => {
                          setShowRemoveMobileAppModal(
                            methodDisplayItem.mobileKeyID as string,
                          );
                        }}
                        analyticsTrackingId={
                          "on-call-notification-method-delete"
                        }
                        label="Delete"
                        destructive
                      >
                        Delete
                      </DropdownMenuItem>
                    ) : null}
                  </DropdownMenu>
                ) : null}
                {methodDisplayItem.operational &&
                  m.status ===
                    OnCallNotificationMethodV2StatusEnum.Verified && (
                    <Tooltip content={"Send a test notification"}>
                      <Button
                        analyticsTrackingId={"test-on-call-notification-method"}
                        theme={ButtonTheme.Naked}
                        onClick={() => {
                          setTestModalMethod(methodDisplayItem);
                        }}
                        icon={IconEnum.BellRinging}
                        iconProps={{ size: IconSize.Medium }}
                        title={"Test notification method"}
                      />
                    </Tooltip>
                  )}
              </div>
            </div>
          );
        })}
      </div>
      <Button
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Add}
        analyticsTrackingId={"add-on-call-notification-method"}
        onClick={() =>
          setShowAddMethodModal({
            show: true,
            allowEmail: false,
          })
        }
      >
        Add contact method
      </Button>
      {showAddMethodModal.show && (
        <AddContactMethodModal
          onClose={() =>
            setShowAddMethodModal({
              show: false,
              allowEmail: false,
            })
          }
          allowEmail={showAddMethodModal.allowEmail}
          showVerificationModal={(method) => {
            setShowAddMethodModal({
              show: false,
              allowEmail: false,
            });
            setShowVerifyMethodModal(method);
          }}
        />
      )}
      {showVerifyMethodModal && showVerifyMethodModal.id && (
        <VerifyNotificationMethodModal
          onClose={() => setShowVerifyMethodModal(null)}
          method={showVerifyMethodModal as unknown as OnCallNotificationMethod}
        />
      )}
      {showConfirmDeleteModal && (
        <DeletionConfirmationModal
          resourceTitle={"Notification method"}
          onDelete={async () => {
            await onDelete({ id: showConfirmDeleteModal });
            revalidateNotificationMethods();
          }}
          isDeleting={isDestroying}
          isOpen={true}
          onClose={() => {
            revalidateNotificationMethods();
            setShowConfirmDeleteModal(false);
          }}
          title="Remove method"
          deleteConfirmationContent={
            "Are you sure you want to remove this notification method?"
          }
          analyticsTrackingId="delete-notification-rule"
        />
      )}
      {testModalMethod && (
        <TestContactMethodModal
          onClose={() => setTestModalMethod(undefined)}
          selectedMethod={testModalMethod}
        />
      )}
      {showRemoveMobileAppModal && (
        <RemoveMobileAppModal
          onClose={() => {
            revalidateNotificationMethods();
            setShowRemoveMobileAppModal(false);
          }}
          mobileKeyID={showRemoveMobileAppModal}
        />
      )}
    </OnCallNotificationBox>
  );
};
