import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { ErrorMessage } from "@incident-ui";
import { Link, ModalFooter } from "@incident-ui";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentsResolveRequestBody,
  IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum,
  PostIncidentFlow,
} from "src/contexts/ClientContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { PostIncidentDecision } from "./ResolveIncidentModal";

export type FormData = {
  post_incident_flow_decision: IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum;
};

const buildOptions = (postIncStatus: string): RadioButtonGroupOption[] => {
  return [
    {
      label: "Close the incident",
      value: IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum.OptOut,
    },
    {
      label: (
        <span>
          Start the post-incident flow (enter the{" "}
          <strong>{postIncStatus}</strong> status)
        </span>
      ),
      value: IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum.OptIn,
    },
  ];
};

export const PostIncidentFlowOptInModal = ({
  resolveRequestBody,
  flow,
  incident,
  onClose,
}: {
  resolveRequestBody: IncidentsResolveRequestBody;
  flow: PostIncidentFlow;
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      post_incident_flow_decision:
        IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum.OptOut,
    },
  });

  const selectedPostIncidentFlowDecision = formMethods.watch(
    "post_incident_flow_decision",
  );

  const isOptingInToPostIncidentFlow =
    selectedPostIncidentFlowDecision ===
    IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum.OptIn;

  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });
  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });

  // If changing this, replicate changes in UpdateIncidentModal
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },

    async (apiClient) => {
      await apiClient.incidentsResolve({
        incidentId: incident.id,
        resolveRequestBody: {
          ...resolveRequestBody,
          post_incident_flow_decision: isOptingInToPostIncidentFlow
            ? PostIncidentDecision.OptIn
            : PostIncidentDecision.None,
        },
      });

      await Promise.all([refetchIncident(), refetchPostIncidentTasks()]);
    },
    {
      onSuccess: () => {
        onClose();
      },
      setError: formMethods.setError,
    },
  );

  const firstPostIncidentStatus = flow.incident_statuses[0];

  return (
    <Form.Modal
      onSubmit={onSubmit}
      formMethods={formMethods}
      title="Resolve incident"
      analyticsTrackingId="post-incident-flow-opt-in"
      disableQuickClose
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={"Submit"}
          analyticsTrackingId="post-incident-flow-opt-in-submit"
          saving={saving}
        />
      }
    >
      <p className={"font-normal text-sm"}>
        This incident is not required to enter the{" "}
        <Link
          analyticsTrackingId={null}
          to={`/settings/post-incident-flow/${flow.id}`}
        >
          post-incident flow
        </Link>{" "}
        before closing, but you can opt in if you like.
      </p>
      <hr className="my-4" />
      <RadioButtonGroupV2
        label="After marking the incident as resolved, what should we do?"
        srLabel="After marking the incident as resolved, what should we do?"
        options={buildOptions(firstPostIncidentStatus.name)}
        boxed
        name="post_incident_flow_decision"
        formMethods={formMethods}
      />
      <ErrorMessage message={genericError} />
    </Form.Modal>
  );
};
