import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ErrorMessage } from "@incident-ui";
import {
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Loader,
} from "@incident-ui";
import React from "react";
import {
  AtlassianConnectAppInstallationInfoProductTypeEnum as AtlassianProductType,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { OrganisationSwitcher } from "./OrganisationSwitcher";

const PRODUCT_NAME: { [key in AtlassianProductType]: string } = {
  [AtlassianProductType.Jira]: "Jira",
  [AtlassianProductType.Confluence]: "Confluence",
};

export const ConfigureAtlassianConnectPage = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const queryParams = useQueryParams();
  const jwtToken = queryParams.get("jwtToken");

  const {
    data,
    error: installationInfoError,
    isLoading: installationInfoLoading,
  } = useAPI(
    jwtToken ? "atlassianConnectAppShowInstallationInfo" : null,
    {
      authorization: `JWT ${jwtToken ?? ""}`,
    },
    undefined,
  );

  const installationInfo = data?.installation_info;

  const { trigger, isMutating, genericError } = useAPIMutation(
    "atlassianConnectAppShowInstallationInfo",
    { authorization: `JWT ${jwtToken ?? ""}` },
    async (apiClient, { jwt }: { jwt: string | null }) => {
      if (!jwt) {
        throw new Error("No JWT token provided");
      }
      await apiClient.atlassianConnectAppConnectInstallationToOrganisation({
        authorization: jwt,
      });
    },
    {
      onSuccess: () => {
        switch (installationInfo?.productType) {
          case AtlassianProductType.Jira:
            navigate("/settings/integrations/jira");
            break;
          case AtlassianProductType.Confluence:
            navigate("/settings/integrations/confluence");
            break;
        }
      },
    },
  );

  if (installationInfoLoading) {
    return <Loader />;
  }
  // TODO: we should have specific error handling for if the user does not have the right permissions

  if (!installationInfo || installationInfoError) {
    return (
      // TODO: we should have specific error message here for JWT expired and asking them to go back to atlassian to do it again
      <GenericErrorMessage
        error={installationInfoError}
        description="Failed to fetch Atlassian Connect App installation info"
      />
    );
  }

  return (
    <PageWrapper width={PageWidth.Narrow} title="" noHeader>
      <div className="h-full p-6">
        <div
          className={
            "flex-center flex-col items-center rounded-2xl h-full p-10"
          }
        >
          <div className="flex flex-col items-center max-w-[450px]">
            <OrganisationSwitcher
              productInstalled={installationInfo?.productType}
              switcherURL={"atlassian-connect/configure?jwtToken=" + jwtToken}
            />
            <div className="mb-6 text-center text-content-secondary">
              {installationInfo.productType === AtlassianProductType.Jira && (
                <div>
                  Use our Jira integration to export follow-ups directly from
                  incident.io to{" "}
                  <Link
                    href={installationInfo.displayURL}
                    openInNewTab
                    analyticsTrackingId={null}
                    className={
                      "font-medium !no-underline transition group items-center flex-wrap inline-block"
                    }
                  >
                    <div className="flex">
                      {installationInfo.displayURL}
                      <Icon
                        id={IconEnum.ExternalLink}
                        size={IconSize.Small}
                        className="ml-1 text-slate-400 transition group-hover:text-alarmalade-600"
                      />
                    </div>
                  </Link>
                </div>
              )}
              {installationInfo.productType ===
                AtlassianProductType.Confluence && (
                <>Use Confluence to export post-mortem documents.</>
              )}
            </div>
            <ErrorMessage message={genericError} />
            <MigrateToConnectAppCallout
              productType={installationInfo.productType}
            />
            <GatedButton
              analyticsTrackingId="link-atlassian-connect"
              theme={ButtonTheme.Primary}
              onClick={() => trigger({ jwt: jwtToken })}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
              disabled={isMutating || installationInfo.alreadyInstalled}
              disabledTooltipContent={
                <>
                  This organisation is already connected. Connect to another
                  organisation above.
                </>
              }
            >
              Connect
            </GatedButton>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

const MigrateToConnectAppCallout = ({
  productType,
}: {
  productType: AtlassianProductType;
}): React.ReactElement => {
  const currentIntegration = PRODUCT_NAME[productType];

  let otherIntegration: string;
  if (productType === AtlassianProductType.Jira) {
    otherIntegration = PRODUCT_NAME[AtlassianProductType.Confluence];
  } else {
    otherIntegration = PRODUCT_NAME[AtlassianProductType.Jira];
  }

  return (
    <Callout
      theme={CalloutTheme.Info}
      className="mb-4"
      title={
        <p>
          Installing {currentIntegration} using the Atlassian Connect App will
          disconnect your {otherIntegration} integration if it was installed
          using OAuth.
        </p>
      }
      subtitle={
        <p>
          We recommend reinstalling {otherIntegration} using the Atlassian
          Connect App
        </p>
      }
    />
  );
};
