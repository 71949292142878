/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSourceConfig,
    AlertSourceConfigFromJSON,
    AlertSourceConfigFromJSONTyped,
    AlertSourceConfigToJSON,
} from './AlertSourceConfig';

/**
 * 
 * @export
 * @interface AlertsUpdateSourceConfigResponseBody
 */
export interface AlertsUpdateSourceConfigResponseBody {
    /**
     * 
     * @type {AlertSourceConfig}
     * @memberof AlertsUpdateSourceConfigResponseBody
     */
    alert_source_config: AlertSourceConfig;
}

export function AlertsUpdateSourceConfigResponseBodyFromJSON(json: any): AlertsUpdateSourceConfigResponseBody {
    return AlertsUpdateSourceConfigResponseBodyFromJSONTyped(json, false);
}

export function AlertsUpdateSourceConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsUpdateSourceConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_config': AlertSourceConfigFromJSON(json['alert_source_config']),
    };
}

export function AlertsUpdateSourceConfigResponseBodyToJSON(value?: AlertsUpdateSourceConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_config': AlertSourceConfigToJSON(value.alert_source_config),
    };
}

