import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import {
  PostmortemSettings,
  PostmortemSettingsDefaultDocumentProviderCopyPasteEnum as CopyPasteEnum,
  PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody,
  PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyDefaultDocumentProviderCopyPasteEnum as RequestCopyPasteEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../../SettingsSection";

type FormData = {
  provider?: CopyPasteEnum;
  enabled: boolean;
};

const toForm = (settings: PostmortemSettings): FormData => ({
  provider: settings.default_document_provider_copy_paste,
  enabled: !!settings.default_document_provider_copy_paste,
});

const toRequest = (
  formData: FormData,
): PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody => {
  if (!formData.enabled) {
    return {};
  }
  return {
    default_document_provider_copy_paste:
      formData.provider as unknown as RequestCopyPasteEnum,
  };
};

export const PostmortemCopyPasteProviderForm = ({
  postmortemSettings,
}: {
  postmortemSettings: PostmortemSettings;
}): React.ReactElement => {
  const showToast = useToast();
  const formMethods = useForm<FormData>({
    defaultValues: toForm(postmortemSettings),
  });
  const { setError, reset } = formMethods;

  const mutation = useAPIMutation(
    "postmortemsSettingsShow",
    undefined,
    async (apiClient, formData: FormData) =>
      await apiClient.postmortemsSettingsUpdateDefaultCopyPasteProvider({
        updateDefaultCopyPasteProviderRequestBody: toRequest(formData),
      }),
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(toForm(settings));
      },
      setError,
    },
  );

  const documentProviderOptions = Object.values(CopyPasteEnum)
    .filter((documentProvider) => {
      return documentProvider.startsWith("copy_paste");
    })
    .map((documentProvider) => {
      return {
        label: _.startCase(documentProvider.replace("copy_paste", "")), // E.g. "copy_paste_google_docs" -> "Google Docs"
        value: documentProvider,
      };
    });

  const canEdit = useIdentity().hasScope(
    ScopeNameEnum.OrganisationSettingsUpdate,
  );

  return (
    <SettingsSection
      formMethods={formMethods}
      mutation={mutation}
      enabledPath="enabled"
      title="Specify a default copy-and-paste provider"
      titleHeadingLevel={3}
      explanation={
        <>
          If you regularly copy-and-paste post-mortems into another tool, use
          this to optimise the flow for your specific provider.
        </>
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="provider"
        isClearable={false}
        disabled={!canEdit}
        placeholder="Select a provider"
        options={documentProviderOptions}
        required
      />
    </SettingsSection>
  );
};
