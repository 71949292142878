export const Logo = ({
  className,
}: {
  className: string;
}): React.ReactElement => {
  return (
    <>
      <svg
        width="92"
        height="24"
        viewBox="0 0 92 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.76067 15.8543V13.7116H7.56422V15.8721C10.1567 15.2526 12.0851 12.9079 12.0851 10.1101C12.0851 7.83274 10.4688 4.62879 8.59533 3.63788C8.84761 4.52621 8.4692 5.68527 7.96466 5.58375C7.77454 5.54549 7.75549 5.2451 7.72474 4.7604C7.66028 3.74445 7.54445 1.91875 5.69424 0C5.54626 1.33991 3.42216 4.06909 2.03915 5.84605C1.85039 6.08857 1.67544 6.31336 1.5212 6.51447C0.774994 7.48738 0.3125 8.75783 0.3125 10.1101C0.3125 12.8817 2.20492 15.2086 4.76067 15.8543ZM7.75682 11.3037C7.75682 12.1723 7.05695 12.8765 6.1936 12.8765C5.33025 12.8765 4.63037 12.1723 4.63037 11.3037C4.63037 10.9445 4.7532 10.6072 4.95136 10.3488C4.99233 10.2954 5.03879 10.2357 5.08892 10.1713C5.45621 9.69934 6.02031 8.97456 6.05961 8.61871C7.22368 9.40509 7.75682 10.6989 7.75682 11.3037Z"
          fill="currentColor"
        />
        <path
          d="M14.7182 13.4483C13.4846 13.4483 12.5501 13.8996 12.0455 14.5954V13.7116H9.24194V23.1138H12.0455V17.9802C12.0455 16.6451 12.7557 16.0433 13.7837 16.0433C14.6808 16.0433 15.4097 16.5887 15.4097 17.7545V23.1138H18.2133V17.3408C18.2133 14.8022 16.6059 13.4483 14.7182 13.4483Z"
          fill="currentColor"
        />
        <path
          d="M24.2503 23.3771C26.082 23.3771 27.6706 22.418 28.4743 20.9513L26.0259 19.541C25.7268 20.1991 25.0353 20.594 24.2129 20.594C22.9981 20.594 22.1009 19.7102 22.1009 18.4127C22.1009 17.1152 22.9981 16.2314 24.2129 16.2314C25.0353 16.2314 25.7082 16.6263 26.0259 17.2844L28.4743 15.8553C27.6706 14.4073 26.0633 13.4483 24.2503 13.4483C21.4094 13.4483 19.2974 15.6108 19.2974 18.4127C19.2974 21.2145 21.4094 23.3771 24.2503 23.3771Z"
          fill="currentColor"
        />
        <path
          d="M30.6306 12.8842C31.5464 12.8842 32.3127 12.1132 32.3127 11.1918C32.3127 10.2704 31.5464 9.49939 30.6306 9.49939C29.7148 9.49939 28.9485 10.2704 28.9485 11.1918C28.9485 12.1132 29.7148 12.8842 30.6306 12.8842ZM29.2288 23.1138H32.0324V13.7116H29.2288V23.1138Z"
          fill="currentColor"
        />
        <path
          d="M40.4948 9.95068V14.5954C39.878 13.8808 38.9622 13.4483 37.7099 13.4483C35.2615 13.4483 33.2429 15.6108 33.2429 18.4127C33.2429 21.2146 35.2615 23.3771 37.7099 23.3771C38.9622 23.3771 39.878 22.9446 40.4948 22.23V23.1138H43.2983V9.95068H40.4948ZM38.2706 20.7069C36.981 20.7069 36.0465 19.823 36.0465 18.4127C36.0465 17.0024 36.981 16.1186 38.2706 16.1186C39.5603 16.1186 40.4948 17.0024 40.4948 18.4127C40.4948 19.823 39.5603 20.7069 38.2706 20.7069Z"
          fill="currentColor"
        />
        <path
          d="M47.4859 19.541H54.121C54.1958 19.1837 54.2331 18.8076 54.2331 18.4127C54.2331 15.5732 52.2146 13.4483 49.4858 13.4483C46.5327 13.4483 44.5142 15.6108 44.5142 18.4127C44.5142 21.2145 46.4953 23.3771 49.6914 23.3771C51.467 23.3771 52.8501 22.7189 53.7659 21.4402L51.523 20.1427C51.1492 20.5564 50.4951 20.8573 49.7288 20.8573C48.7008 20.8573 47.841 20.5188 47.4859 19.541ZM47.4299 17.4348C47.6915 16.4758 48.4018 15.9493 49.4671 15.9493C50.3082 15.9493 51.1492 16.3442 51.4483 17.4348H47.4299Z"
          fill="currentColor"
        />
        <path
          d="M60.9257 13.4483C59.6922 13.4483 58.7577 13.8996 58.253 14.5954V13.7116H55.4495V23.1138H58.253V17.9802C58.253 16.6451 58.9633 16.0433 59.9912 16.0433C60.8884 16.0433 61.6173 16.5887 61.6173 17.7545V23.1138H64.4208V17.3408C64.4208 14.8022 62.8135 13.4483 60.9257 13.4483Z"
          fill="currentColor"
        />
        <path
          d="M71.4486 16.4195V13.7116H69.5234V11.079L66.72 11.9252V13.7116H65.2246V16.4195H66.72V19.7479C66.72 22.3805 67.7853 23.4899 71.4486 23.1139V20.5564C70.2151 20.6317 69.5234 20.5564 69.5234 19.7479V16.4195H71.4486Z"
          fill="currentColor"
        />
        <path
          d="M74.4333 23.3771C75.4052 23.3771 76.2089 22.5685 76.2089 21.5907C76.2089 20.6129 75.4052 19.8043 74.4333 19.8043C73.4614 19.8043 72.6577 20.6129 72.6577 21.5907C72.6577 22.5685 73.4614 23.3771 74.4333 23.3771Z"
          fill="currentColor"
        />
        <path
          d="M79.1111 12.8842C80.0269 12.8842 80.7932 12.1132 80.7932 11.1918C80.7932 10.2704 80.0269 9.49939 79.1111 9.49939C78.1953 9.49939 77.429 10.2704 77.429 11.1918C77.429 12.1132 78.1953 12.8842 79.1111 12.8842ZM77.7093 23.1138H80.5129V13.7116H77.7093V23.1138Z"
          fill="currentColor"
        />
        <path
          d="M86.6761 23.3771C89.4236 23.3771 91.629 21.2145 91.629 18.4127C91.629 15.6108 89.4236 13.4483 86.6761 13.4483C83.9286 13.4483 81.7231 15.6108 81.7231 18.4127C81.7231 21.2145 83.9286 23.3771 86.6761 23.3771ZM86.6761 20.6316C85.4612 20.6316 84.5267 19.7478 84.5267 18.4127C84.5267 17.0776 85.4612 16.1938 86.6761 16.1938C87.891 16.1938 88.8255 17.0776 88.8255 18.4127C88.8255 19.7478 87.891 20.6316 86.6761 20.6316Z"
          fill="currentColor"
        />
        <path
          d="M4.76074 23.1142H7.56429V15.8937C7.56429 15.8937 4.76074 16.4006 4.76074 19.1044V23.1142Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
