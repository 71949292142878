/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRoleAssignment,
    IncidentRoleAssignmentFromJSON,
    IncidentRoleAssignmentFromJSONTyped,
    IncidentRoleAssignmentToJSON,
} from './IncidentRoleAssignment';

/**
 * 
 * @export
 * @interface IncidentsUpdateRoleAssignmentsResponseBody
 */
export interface IncidentsUpdateRoleAssignmentsResponseBody {
    /**
     * 
     * @type {Array<IncidentRoleAssignment>}
     * @memberof IncidentsUpdateRoleAssignmentsResponseBody
     */
    role_assignments: Array<IncidentRoleAssignment>;
}

export function IncidentsUpdateRoleAssignmentsResponseBodyFromJSON(json: any): IncidentsUpdateRoleAssignmentsResponseBody {
    return IncidentsUpdateRoleAssignmentsResponseBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateRoleAssignmentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateRoleAssignmentsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role_assignments': ((json['role_assignments'] as Array<any>).map(IncidentRoleAssignmentFromJSON)),
    };
}

export function IncidentsUpdateRoleAssignmentsResponseBodyToJSON(value?: IncidentsUpdateRoleAssignmentsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role_assignments': ((value.role_assignments as Array<any>).map(IncidentRoleAssignmentToJSON)),
    };
}

