import { Priority, ScopeNameEnum } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Loader,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";

import { useIdentity } from "../../../contexts/IdentityContext";
import { useAPI, useAPIMutation } from "../../../utils/swr";
import { prepareRankUpdate } from "../../../utils/utils";
import { SettingsSortableList } from "../../settings/SettingsSortableList";
import { PriorityCreateEditModal } from "./PrioritiesCreateEditModal";
import { PriorityListItem } from "./PriorityListItem";

export const PrioritiesCreateEditForm = () => {
  const [editPriority, setEditPriority] = useState<Priority | null>();
  const [showCreatePriorityModal, setShowCreatePriorityModal] = useState(false);

  const {
    data: { priorities: originalPriorities },
    isLoading,
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );
  const priorities = _.sortBy(originalPriorities, (prio) => -prio.rank);
  const { hasScope } = useIdentity();
  const canEditSchema = hasScope(ScopeNameEnum.AlertSchemaUpdate);

  const { trigger: updatePriorityRanks, isMutating: savingRanks } =
    useAPIMutation(
      "alertsListPriorities",
      {},
      async (apiClient, updatedPriorities: Priority[]) =>
        await apiClient.alertsUpdatePriorityRanks({
          updatePriorityRanksRequestBody: prepareRankUpdate(updatedPriorities),
        }),
    );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <SettingsSortableList
          items={priorities}
          updateItemRanks={updatePriorityRanks}
          canEdit={canEditSchema}
          saving={savingRanks}
          dragHandleClassName={"text-slate-300"}
          className={"divide-slate-100"}
          renderItem={(item) => (
            <PriorityListItem
              priority={item}
              priorities={priorities}
              onEdit={() => setEditPriority(item)}
            />
          )}
        />
        <div className="flex justify-end space-x-3 items-center mt-4">
          <GatedButton
            requiredScope={ScopeNameEnum.AlertSchemaUpdate}
            analyticsTrackingId="alert-schema-add-priority"
            onClick={() => setShowCreatePriorityModal(true)}
            className="!ml-3 !my-3"
          >
            Add priority
          </GatedButton>
        </div>
      </div>

      {editPriority ? (
        <PriorityCreateEditModal
          mode={Mode.Edit}
          initialData={editPriority}
          onClose={() => setEditPriority(null)}
        />
      ) : null}

      {showCreatePriorityModal ? (
        <PriorityCreateEditModal
          mode={Mode.Create}
          onClose={() => setShowCreatePriorityModal(false)}
        />
      ) : null}
    </>
  );
};

export const PrioritiesDescription = () => {
  const { showArticle } = useIntercom();

  return (
    <div className={"flex flex-col gap-6"}>
      <div className={"text-content-secondary"}>
        Priorities can be set on alerts to change how users are notified in
        escalation paths. For example, you can choose to only send a
        high-urgency notification during working hours for lower priority
        alerts.{" "}
        <Button
          theme={ButtonTheme.Link}
          analyticsTrackingId={"alert-priorities-learn-more"}
          className={"no-underline font-medium"}
          onClick={() => showArticle(9310668)}
        >
          Learn more
        </Button>
      </div>
      <Callout theme={CalloutTheme.Plain} iconOverride={IconEnum.Announcement}>
        You can assign priorities from your{" "}
        <span className={"font-semibold"}>alert sources</span> and configure
        notification behaviour from your{" "}
        <span className={"font-semibold"}>escalation paths</span>.
      </Callout>
    </div>
  );
};
