/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody,
    IncidentSubscriptionsCreateAutoSubscribeRuleRequestBodyFromJSON,
    IncidentSubscriptionsCreateAutoSubscribeRuleRequestBodyToJSON,
    IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody,
    IncidentSubscriptionsCreateAutoSubscribeRuleResponseBodyFromJSON,
    IncidentSubscriptionsCreateAutoSubscribeRuleResponseBodyToJSON,
    IncidentSubscriptionsCreateRequestBody,
    IncidentSubscriptionsCreateRequestBodyFromJSON,
    IncidentSubscriptionsCreateRequestBodyToJSON,
    IncidentSubscriptionsCreateResponseBody,
    IncidentSubscriptionsCreateResponseBodyFromJSON,
    IncidentSubscriptionsCreateResponseBodyToJSON,
    IncidentSubscriptionsGetResponseBody,
    IncidentSubscriptionsGetResponseBodyFromJSON,
    IncidentSubscriptionsGetResponseBodyToJSON,
    IncidentSubscriptionsListAutoSubscribeRulesResponseBody,
    IncidentSubscriptionsListAutoSubscribeRulesResponseBodyFromJSON,
    IncidentSubscriptionsListAutoSubscribeRulesResponseBodyToJSON,
    IncidentSubscriptionsUpdateAutoSubscribeRuleRequestBody,
    IncidentSubscriptionsUpdateAutoSubscribeRuleRequestBodyFromJSON,
    IncidentSubscriptionsUpdateAutoSubscribeRuleRequestBodyToJSON,
    IncidentSubscriptionsUpdateAutoSubscribeRuleResponseBody,
    IncidentSubscriptionsUpdateAutoSubscribeRuleResponseBodyFromJSON,
    IncidentSubscriptionsUpdateAutoSubscribeRuleResponseBodyToJSON,
} from '../models';

export interface IncidentSubscriptionsCreateRequest {
    createRequestBody: IncidentSubscriptionsCreateRequestBody;
}

export interface IncidentSubscriptionsCreateAutoSubscribeRuleRequest {
    createAutoSubscribeRuleRequestBody: IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody;
}

export interface IncidentSubscriptionsDestroyAutoSubscribeRuleRequest {
    id: string;
}

export interface IncidentSubscriptionsGetRequest {
    incidentId: string;
}

export interface IncidentSubscriptionsUnsubscribeRequest {
    incidentId: string;
}

export interface IncidentSubscriptionsUpdateAutoSubscribeRuleRequest {
    id: string;
    updateAutoSubscribeRuleRequestBody: IncidentSubscriptionsUpdateAutoSubscribeRuleRequestBody;
}

/**
 * 
 */
export class IncidentSubscriptionsApi extends runtime.BaseAPI {

    /**
     * Create a new incident subscription
     * Create IncidentSubscriptions
     */
    async incidentSubscriptionsCreateRaw(requestParameters: IncidentSubscriptionsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSubscriptionsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentSubscriptionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_subscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSubscriptionsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSubscriptionsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident subscription
     * Create IncidentSubscriptions
     */
    async incidentSubscriptionsCreate(requestParameters: IncidentSubscriptionsCreateRequest, initOverrides?: RequestInit): Promise<IncidentSubscriptionsCreateResponseBody> {
        const response = await this.incidentSubscriptionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new incident auto subscribe rule
     * CreateAutoSubscribeRule IncidentSubscriptions
     */
    async incidentSubscriptionsCreateAutoSubscribeRuleRaw(requestParameters: IncidentSubscriptionsCreateAutoSubscribeRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody>> {
        if (requestParameters.createAutoSubscribeRuleRequestBody === null || requestParameters.createAutoSubscribeRuleRequestBody === undefined) {
            throw new runtime.RequiredError('createAutoSubscribeRuleRequestBody','Required parameter requestParameters.createAutoSubscribeRuleRequestBody was null or undefined when calling incidentSubscriptionsCreateAutoSubscribeRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_subscriptions/auto_subscribe_rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSubscriptionsCreateAutoSubscribeRuleRequestBodyToJSON(requestParameters.createAutoSubscribeRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSubscriptionsCreateAutoSubscribeRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident auto subscribe rule
     * CreateAutoSubscribeRule IncidentSubscriptions
     */
    async incidentSubscriptionsCreateAutoSubscribeRule(requestParameters: IncidentSubscriptionsCreateAutoSubscribeRuleRequest, initOverrides?: RequestInit): Promise<IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody> {
        const response = await this.incidentSubscriptionsCreateAutoSubscribeRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives an incident auto subscribe rule
     * DestroyAutoSubscribeRule IncidentSubscriptions
     */
    async incidentSubscriptionsDestroyAutoSubscribeRuleRaw(requestParameters: IncidentSubscriptionsDestroyAutoSubscribeRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentSubscriptionsDestroyAutoSubscribeRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_subscriptions/auto_subscribe_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an incident auto subscribe rule
     * DestroyAutoSubscribeRule IncidentSubscriptions
     */
    async incidentSubscriptionsDestroyAutoSubscribeRule(requestParameters: IncidentSubscriptionsDestroyAutoSubscribeRuleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentSubscriptionsDestroyAutoSubscribeRuleRaw(requestParameters, initOverrides);
    }

    /**
     * Returns the subscription for a given incident, if it exists.
     * Get IncidentSubscriptions
     */
    async incidentSubscriptionsGetRaw(requestParameters: IncidentSubscriptionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSubscriptionsGetResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentSubscriptionsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSubscriptionsGetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Returns the subscription for a given incident, if it exists.
     * Get IncidentSubscriptions
     */
    async incidentSubscriptionsGet(requestParameters: IncidentSubscriptionsGetRequest, initOverrides?: RequestInit): Promise<IncidentSubscriptionsGetResponseBody> {
        const response = await this.incidentSubscriptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List incident auto subscribe rules
     * ListAutoSubscribeRules IncidentSubscriptions
     */
    async incidentSubscriptionsListAutoSubscribeRulesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSubscriptionsListAutoSubscribeRulesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_subscriptions/auto_subscribe_rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSubscriptionsListAutoSubscribeRulesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident auto subscribe rules
     * ListAutoSubscribeRules IncidentSubscriptions
     */
    async incidentSubscriptionsListAutoSubscribeRules(initOverrides?: RequestInit): Promise<IncidentSubscriptionsListAutoSubscribeRulesResponseBody> {
        const response = await this.incidentSubscriptionsListAutoSubscribeRulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Unsubscribe from an incident
     * Unsubscribe IncidentSubscriptions
     */
    async incidentSubscriptionsUnsubscribeRaw(requestParameters: IncidentSubscriptionsUnsubscribeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentSubscriptionsUnsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_subscriptions/unsubscribe/{incident_id}`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe from an incident
     * Unsubscribe IncidentSubscriptions
     */
    async incidentSubscriptionsUnsubscribe(requestParameters: IncidentSubscriptionsUnsubscribeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentSubscriptionsUnsubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribe from all incidents
     * UnsubscribeFromAll IncidentSubscriptions
     */
    async incidentSubscriptionsUnsubscribeFromAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_subscriptions/unsubscribe_from_all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe from all incidents
     * UnsubscribeFromAll IncidentSubscriptions
     */
    async incidentSubscriptionsUnsubscribeFromAll(initOverrides?: RequestInit): Promise<void> {
        await this.incidentSubscriptionsUnsubscribeFromAllRaw(initOverrides);
    }

    /**
     * Update an incident auto subscribe rule
     * UpdateAutoSubscribeRule IncidentSubscriptions
     */
    async incidentSubscriptionsUpdateAutoSubscribeRuleRaw(requestParameters: IncidentSubscriptionsUpdateAutoSubscribeRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSubscriptionsUpdateAutoSubscribeRuleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentSubscriptionsUpdateAutoSubscribeRule.');
        }

        if (requestParameters.updateAutoSubscribeRuleRequestBody === null || requestParameters.updateAutoSubscribeRuleRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutoSubscribeRuleRequestBody','Required parameter requestParameters.updateAutoSubscribeRuleRequestBody was null or undefined when calling incidentSubscriptionsUpdateAutoSubscribeRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_subscriptions/auto_subscribe_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSubscriptionsUpdateAutoSubscribeRuleRequestBodyToJSON(requestParameters.updateAutoSubscribeRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSubscriptionsUpdateAutoSubscribeRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident auto subscribe rule
     * UpdateAutoSubscribeRule IncidentSubscriptions
     */
    async incidentSubscriptionsUpdateAutoSubscribeRule(requestParameters: IncidentSubscriptionsUpdateAutoSubscribeRuleRequest, initOverrides?: RequestInit): Promise<IncidentSubscriptionsUpdateAutoSubscribeRuleResponseBody> {
        const response = await this.incidentSubscriptionsUpdateAutoSubscribeRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
