/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertPulseConfig,
    AlertPulseConfigFromJSON,
    AlertPulseConfigFromJSONTyped,
    AlertPulseConfigToJSON,
} from './AlertPulseConfig';
import {
    AlertRouteAlertSource,
    AlertRouteAlertSourceFromJSON,
    AlertRouteAlertSourceFromJSONTyped,
    AlertRouteAlertSourceToJSON,
} from './AlertRouteAlertSource';
import {
    AlertRouteEscalationBinding,
    AlertRouteEscalationBindingFromJSON,
    AlertRouteEscalationBindingFromJSONTyped,
    AlertRouteEscalationBindingToJSON,
} from './AlertRouteEscalationBinding';
import {
    AlertRouteIncidentTemplate,
    AlertRouteIncidentTemplateFromJSON,
    AlertRouteIncidentTemplateFromJSONTyped,
    AlertRouteIncidentTemplateToJSON,
} from './AlertRouteIncidentTemplate';
import {
    AlertRouteMostRecentResourcesCreated,
    AlertRouteMostRecentResourcesCreatedFromJSON,
    AlertRouteMostRecentResourcesCreatedFromJSONTyped,
    AlertRouteMostRecentResourcesCreatedToJSON,
} from './AlertRouteMostRecentResourcesCreated';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    GroupingKey,
    GroupingKeyFromJSON,
    GroupingKeyFromJSONTyped,
    GroupingKeyToJSON,
} from './GroupingKey';

/**
 * 
 * @export
 * @interface AlertRoute
 */
export interface AlertRoute {
    /**
     * 
     * @type {AlertPulseConfig}
     * @memberof AlertRoute
     */
    alert_pulse_config?: AlertPulseConfig;
    /**
     * Which alert sources should this alert route match?
     * @type {Array<AlertRouteAlertSource>}
     * @memberof AlertRoute
     */
    alert_sources: Array<AlertRouteAlertSource>;
    /**
     * Should we auto cancel escalations when all alerts are resolved?
     * @type {boolean}
     * @memberof AlertRoute
     */
    auto_cancel_escalations: boolean;
    /**
     * Should triage incidents be declined when alerts are resolved?
     * @type {boolean}
     * @memberof AlertRoute
     */
    auto_decline_enabled: boolean;
    /**
     * What condition groups must be true for this alert route to fire?
     * @type {Array<ConditionGroup>}
     * @memberof AlertRoute
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * How long should the escalation defer time be?
     * @type {number}
     * @memberof AlertRoute
     */
    defer_time_seconds: number;
    /**
     * Whether this alert route is enabled or not
     * @type {boolean}
     * @memberof AlertRoute
     */
    enabled: boolean;
    /**
     * Which escalation paths should this alert route escalate to?
     * @type {Array<AlertRouteEscalationBinding>}
     * @memberof AlertRoute
     */
    escalation_bindings: Array<AlertRouteEscalationBinding>;
    /**
     * The expressions used in this template
     * @type {Array<Expression>}
     * @memberof AlertRoute
     */
    expressions?: Array<Expression>;
    /**
     * Which attributes should this alert route use to group alerts?
     * @type {Array<GroupingKey>}
     * @memberof AlertRoute
     */
    grouping_keys: Array<GroupingKey>;
    /**
     * How large should the grouping window be?
     * @type {number}
     * @memberof AlertRoute
     */
    grouping_window_seconds: number;
    /**
     * Unique identifier for this alert route config
     * @type {string}
     * @memberof AlertRoute
     */
    id: string;
    /**
     * What condition groups must be true for this alert route to create an incident?
     * @type {Array<ConditionGroup>}
     * @memberof AlertRoute
     */
    incident_condition_groups: Array<ConditionGroup>;
    /**
     * Whether this alert route will create incidents or not
     * @type {boolean}
     * @memberof AlertRoute
     */
    incident_enabled: boolean;
    /**
     * Whether this alert route is private. Private alert routes will only create private incidents from alerts.
     * @type {boolean}
     * @memberof AlertRoute
     */
    is_private: boolean;
    /**
     * 
     * @type {AlertRouteMostRecentResourcesCreated}
     * @memberof AlertRoute
     */
    most_recent_resources_created?: AlertRouteMostRecentResourcesCreated;
    /**
     * The name of this alert route config, for the user's reference
     * @type {string}
     * @memberof AlertRoute
     */
    name: string;
    /**
     * 
     * @type {AlertRouteIncidentTemplate}
     * @memberof AlertRoute
     */
    template?: AlertRouteIncidentTemplate;
}

export function AlertRouteFromJSON(json: any): AlertRoute {
    return AlertRouteFromJSONTyped(json, false);
}

export function AlertRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_pulse_config': !exists(json, 'alert_pulse_config') ? undefined : AlertPulseConfigFromJSON(json['alert_pulse_config']),
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertRouteAlertSourceFromJSON)),
        'auto_cancel_escalations': json['auto_cancel_escalations'],
        'auto_decline_enabled': json['auto_decline_enabled'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'defer_time_seconds': json['defer_time_seconds'],
        'enabled': json['enabled'],
        'escalation_bindings': ((json['escalation_bindings'] as Array<any>).map(AlertRouteEscalationBindingFromJSON)),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'grouping_keys': ((json['grouping_keys'] as Array<any>).map(GroupingKeyFromJSON)),
        'grouping_window_seconds': json['grouping_window_seconds'],
        'id': json['id'],
        'incident_condition_groups': ((json['incident_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'incident_enabled': json['incident_enabled'],
        'is_private': json['is_private'],
        'most_recent_resources_created': !exists(json, 'most_recent_resources_created') ? undefined : AlertRouteMostRecentResourcesCreatedFromJSON(json['most_recent_resources_created']),
        'name': json['name'],
        'template': !exists(json, 'template') ? undefined : AlertRouteIncidentTemplateFromJSON(json['template']),
    };
}

export function AlertRouteToJSON(value?: AlertRoute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_pulse_config': AlertPulseConfigToJSON(value.alert_pulse_config),
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertRouteAlertSourceToJSON)),
        'auto_cancel_escalations': value.auto_cancel_escalations,
        'auto_decline_enabled': value.auto_decline_enabled,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'defer_time_seconds': value.defer_time_seconds,
        'enabled': value.enabled,
        'escalation_bindings': ((value.escalation_bindings as Array<any>).map(AlertRouteEscalationBindingToJSON)),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'grouping_keys': ((value.grouping_keys as Array<any>).map(GroupingKeyToJSON)),
        'grouping_window_seconds': value.grouping_window_seconds,
        'id': value.id,
        'incident_condition_groups': ((value.incident_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'incident_enabled': value.incident_enabled,
        'is_private': value.is_private,
        'most_recent_resources_created': AlertRouteMostRecentResourcesCreatedToJSON(value.most_recent_resources_created),
        'name': value.name,
        'template': AlertRouteIncidentTemplateToJSON(value.template),
    };
}

