/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentType,
    IncidentTypeFromJSON,
    IncidentTypeFromJSONTyped,
    IncidentTypeToJSON,
} from './IncidentType';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface AlertRoutesPreviewIncidentResponseBody
 */
export interface AlertRoutesPreviewIncidentResponseBody {
    /**
     * What mode should we create the incident in? Will default to standard
     * @type {string}
     * @memberof AlertRoutesPreviewIncidentResponseBody
     */
    incident_mode?: string;
    /**
     * 
     * @type {IncidentType}
     * @memberof AlertRoutesPreviewIncidentResponseBody
     */
    incident_type?: IncidentType;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof AlertRoutesPreviewIncidentResponseBody
     */
    name: string;
    /**
     * 
     * @type {Severity}
     * @memberof AlertRoutesPreviewIncidentResponseBody
     */
    severity?: Severity;
    /**
     * 
     * @type {TextDocument}
     * @memberof AlertRoutesPreviewIncidentResponseBody
     */
    summary?: TextDocument;
}

export function AlertRoutesPreviewIncidentResponseBodyFromJSON(json: any): AlertRoutesPreviewIncidentResponseBody {
    return AlertRoutesPreviewIncidentResponseBodyFromJSONTyped(json, false);
}

export function AlertRoutesPreviewIncidentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesPreviewIncidentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_mode': !exists(json, 'incident_mode') ? undefined : json['incident_mode'],
        'incident_type': !exists(json, 'incident_type') ? undefined : IncidentTypeFromJSON(json['incident_type']),
        'name': json['name'],
        'severity': !exists(json, 'severity') ? undefined : SeverityFromJSON(json['severity']),
        'summary': !exists(json, 'summary') ? undefined : TextDocumentFromJSON(json['summary']),
    };
}

export function AlertRoutesPreviewIncidentResponseBodyToJSON(value?: AlertRoutesPreviewIncidentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_mode': value.incident_mode,
        'incident_type': IncidentTypeToJSON(value.incident_type),
        'name': value.name,
        'severity': SeverityToJSON(value.severity),
        'summary': TextDocumentToJSON(value.summary),
    };
}

