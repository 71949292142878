export enum FormModeEnum {
  Create = "create",
  Edit = "edit",
}

export type CreateEditFormProps<T> =
  | {
      mode: FormModeEnum.Create;
      initialData?: never;
    }
  | {
      mode: FormModeEnum.Edit;
      initialData: T;
    };
