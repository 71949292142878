import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { upperFirst } from "lodash";
import {
  AtlassianStatuspageIncidentStatusEnum,
  IncidentActivityLogEntry,
} from "src/contexts/ClientContext";

import { ActivityBadge } from "./ActivityBadge";
import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemAtlassianStatuspageUpdate = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.atlassian_statuspage_update) {
    throw new Error(
      "malformed timeline item: statuspage_update was missing statuspage_update field",
    );
  }

  let title = "Status page incident updated";
  if (item.content.atlassian_statuspage_update.is_link_from_existing) {
    title = "Incident linked to status page";
  } else if (!item.content.atlassian_statuspage_update.previous_statuspage) {
    title = "Status page incident created";
  } else if (
    item.content.atlassian_statuspage_update.statuspage?.status === "resolved"
  ) {
    title = "Status page incident resolved";
  }

  return {
    title: title,
    icon: IconEnum.StatusPage,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.atlassian_statuspage_update.updater,
    quotedContent: (
      <div className="space-y-2">
        <div className="font-semibold">
          {item.content.atlassian_statuspage_update.statuspage?.name}
        </div>
        <div>
          {
            item.content.atlassian_statuspage_update.statuspage
              ?.incident_updates[0]?.body
          }
        </div>
        <ActivityBadge
          icon={
            item.content.atlassian_statuspage_update.statuspage?.status ===
            AtlassianStatuspageIncidentStatusEnum.Resolved
              ? IconEnum.Checkmark
              : IconEnum.Status
          }
          value={upperFirst(
            item.content.atlassian_statuspage_update.statuspage?.status,
          )}
        />
      </div>
    ),
  };
};
