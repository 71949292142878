/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RBACPrivilege,
    RBACPrivilegeFromJSON,
    RBACPrivilegeFromJSONTyped,
    RBACPrivilegeToJSON,
} from './RBACPrivilege';

/**
 * 
 * @export
 * @interface RBACRolesListPrivilegesResponseBody
 */
export interface RBACRolesListPrivilegesResponseBody {
    /**
     * 
     * @type {Array<RBACPrivilege>}
     * @memberof RBACRolesListPrivilegesResponseBody
     */
    rbac_privileges: Array<RBACPrivilege>;
}

export function RBACRolesListPrivilegesResponseBodyFromJSON(json: any): RBACRolesListPrivilegesResponseBody {
    return RBACRolesListPrivilegesResponseBodyFromJSONTyped(json, false);
}

export function RBACRolesListPrivilegesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RBACRolesListPrivilegesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rbac_privileges': ((json['rbac_privileges'] as Array<any>).map(RBACPrivilegeFromJSON)),
    };
}

export function RBACRolesListPrivilegesResponseBodyToJSON(value?: RBACRolesListPrivilegesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rbac_privileges': ((value.rbac_privileges as Array<any>).map(RBACPrivilegeToJSON)),
    };
}

