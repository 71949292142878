import { useIdentity } from "src/contexts/IdentityContext";

export const usePrimaryCommsPlatform = () => {
  const { identity } = useIdentity();

  return identity.slack_info !== undefined
    ? CommsPlatform.Slack
    : identity.ms_teams_info !== undefined
    ? CommsPlatform.MSTeams
    : undefined;
};

export const usePrimaryCommsPlatformFeatures = (): {
  platformHasReminders: boolean;
} => {
  const platform = usePrimaryCommsPlatform();

  return {
    platformHasReminders: platform === CommsPlatform.Slack,
  };
};

export enum CommsPlatform {
  Slack = "slack",
  MSTeams = "msteams",
}
