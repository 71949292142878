/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentTypesBuildApplicableFieldsRequestBody
 */
export interface IncidentTypesBuildApplicableFieldsRequestBody {
    /**
     * If provided, will generate applicable fields for this type. Otherwise return fields applicable to all types.
     * @type {string}
     * @memberof IncidentTypesBuildApplicableFieldsRequestBody
     */
    incident_type_id?: string;
}

export function IncidentTypesBuildApplicableFieldsRequestBodyFromJSON(json: any): IncidentTypesBuildApplicableFieldsRequestBody {
    return IncidentTypesBuildApplicableFieldsRequestBodyFromJSONTyped(json, false);
}

export function IncidentTypesBuildApplicableFieldsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTypesBuildApplicableFieldsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_type_id': !exists(json, 'incident_type_id') ? undefined : json['incident_type_id'],
    };
}

export function IncidentTypesBuildApplicableFieldsRequestBodyToJSON(value?: IncidentTypesBuildApplicableFieldsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_type_id': value.incident_type_id,
    };
}

