/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InternalStatusPageContentListIncidentsResponseBody,
    InternalStatusPageContentListIncidentsResponseBodyFromJSON,
    InternalStatusPageContentListIncidentsResponseBodyToJSON,
    InternalStatusPageContentShowIncidentResponseBody,
    InternalStatusPageContentShowIncidentResponseBodyFromJSON,
    InternalStatusPageContentShowIncidentResponseBodyToJSON,
    InternalStatusPageContentShowPageResponseBody,
    InternalStatusPageContentShowPageResponseBodyFromJSON,
    InternalStatusPageContentShowPageResponseBodyToJSON,
} from '../models';

export interface InternalStatusPageContentListIncidentsRequest {
    startAt: Date;
    endAt: Date;
    internalStatusPageId: string;
}

export interface InternalStatusPageContentShowIncidentRequest {
    subpath: string;
    externalId: number;
}

export interface InternalStatusPageContentShowPageRequest {
    subpath: string;
}

/**
 * 
 */
export class InternalStatusPageContentApi extends runtime.BaseAPI {

    /**
     * Lists incidents between two timestamps, for the incident calendar
     * ListIncidents Internal Status Page Content
     */
    async internalStatusPageContentListIncidentsRaw(requestParameters: InternalStatusPageContentListIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageContentListIncidentsResponseBody>> {
        if (requestParameters.startAt === null || requestParameters.startAt === undefined) {
            throw new runtime.RequiredError('startAt','Required parameter requestParameters.startAt was null or undefined when calling internalStatusPageContentListIncidents.');
        }

        if (requestParameters.endAt === null || requestParameters.endAt === undefined) {
            throw new runtime.RequiredError('endAt','Required parameter requestParameters.endAt was null or undefined when calling internalStatusPageContentListIncidents.');
        }

        if (requestParameters.internalStatusPageId === null || requestParameters.internalStatusPageId === undefined) {
            throw new runtime.RequiredError('internalStatusPageId','Required parameter requestParameters.internalStatusPageId was null or undefined when calling internalStatusPageContentListIncidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.startAt !== undefined) {
            queryParameters['start_at'] = (requestParameters.startAt as any).toISOString();
        }

        if (requestParameters.endAt !== undefined) {
            queryParameters['end_at'] = (requestParameters.endAt as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_page_content/{internal_status_page_id}/incidents`.replace(`{${"internal_status_page_id"}}`, encodeURIComponent(String(requestParameters.internalStatusPageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageContentListIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists incidents between two timestamps, for the incident calendar
     * ListIncidents Internal Status Page Content
     */
    async internalStatusPageContentListIncidents(requestParameters: InternalStatusPageContentListIncidentsRequest, initOverrides?: RequestInit): Promise<InternalStatusPageContentListIncidentsResponseBody> {
        const response = await this.internalStatusPageContentListIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ShowIncident Internal Status Page Content
     */
    async internalStatusPageContentShowIncidentRaw(requestParameters: InternalStatusPageContentShowIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageContentShowIncidentResponseBody>> {
        if (requestParameters.subpath === null || requestParameters.subpath === undefined) {
            throw new runtime.RequiredError('subpath','Required parameter requestParameters.subpath was null or undefined when calling internalStatusPageContentShowIncident.');
        }

        if (requestParameters.externalId === null || requestParameters.externalId === undefined) {
            throw new runtime.RequiredError('externalId','Required parameter requestParameters.externalId was null or undefined when calling internalStatusPageContentShowIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_page_content/{subpath}/incidents/{external_id}`.replace(`{${"subpath"}}`, encodeURIComponent(String(requestParameters.subpath))).replace(`{${"external_id"}}`, encodeURIComponent(String(requestParameters.externalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageContentShowIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * ShowIncident Internal Status Page Content
     */
    async internalStatusPageContentShowIncident(requestParameters: InternalStatusPageContentShowIncidentRequest, initOverrides?: RequestInit): Promise<InternalStatusPageContentShowIncidentResponseBody> {
        const response = await this.internalStatusPageContentShowIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ShowPage Internal Status Page Content
     */
    async internalStatusPageContentShowPageRaw(requestParameters: InternalStatusPageContentShowPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageContentShowPageResponseBody>> {
        if (requestParameters.subpath === null || requestParameters.subpath === undefined) {
            throw new runtime.RequiredError('subpath','Required parameter requestParameters.subpath was null or undefined when calling internalStatusPageContentShowPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_page_content/{subpath}`.replace(`{${"subpath"}}`, encodeURIComponent(String(requestParameters.subpath))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageContentShowPageResponseBodyFromJSON(jsonValue));
    }

    /**
     * ShowPage Internal Status Page Content
     */
    async internalStatusPageContentShowPage(requestParameters: InternalStatusPageContentShowPageRequest, initOverrides?: RequestInit): Promise<InternalStatusPageContentShowPageResponseBody> {
        const response = await this.internalStatusPageContentShowPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
