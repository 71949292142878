import _ from "lodash";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceCronitorSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Configure a <strong>Notification List</strong> in the{" "}
        <strong>Alerts</strong> section of the settings page
      </p>
      <p>
        Then, create an integration with the <strong>Webhook</strong> provider
        in the <strong>Integrations</strong> section of the settings page
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then copy and paste in the <strong>Name</strong>,{" "}
            <strong>Unqiue Key</strong> and <strong>Webhook URL</strong> below
          </>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "Unique Key",
            code: _.kebabCase(alertSourceConfig.name),
          },
          {
            title: "Webhook URL",
            code: `${publicApiUrl()}/v2/alert_events/cronitor/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <p>
        After creating your integration, add it to your{" "}
        <strong>Notification List</strong> you created earlier
      </p>
      <p className="mt-2">
        Configure your Cronitor checks to use this{" "}
        <strong>Notification List</strong> in the{" "}
        <strong>Alert Settings</strong> section of your check
      </p>
    </SetupInfoNumberedList>
  );
};
