import { Tooltip } from "@incident-ui";
import React from "react";

export type AttributeListItem = {
  title: React.ReactNode;
  tooltipContent?: React.ReactNode;
  renderValues: () => React.ReactElement;
};

export const AttributeList = ({ items }: { items: AttributeListItem[] }) => {
  if (!items.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-5">
      {items.map((item, idx) => (
        <div className="text-sm flex flex-col gap-1" key={idx}>
          <div className="flex items-center gap-1 text-content-secondary text-xs">
            {item.title}
            {item.tooltipContent && <Tooltip content={item.tooltipContent} />}
          </div>
          {item.renderValues()}
        </div>
      ))}
    </div>
  );
};
