/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StepProgress,
    StepProgressFromJSON,
    StepProgressFromJSONTyped,
    StepProgressToJSON,
} from './StepProgress';

/**
 * 
 * @export
 * @interface WorkflowRun
 */
export interface WorkflowRun {
    /**
     * If the run was cancelled, this is when
     * @type {Date}
     * @memberof WorkflowRun
     */
    cancelled_at?: Date;
    /**
     * When the resource was created
     * @type {Date}
     * @memberof WorkflowRun
     */
    created_at: Date;
    /**
     * When the run was enqueued for execution
     * @type {Date}
     * @memberof WorkflowRun
     */
    enqueued_at?: Date;
    /**
     * Error produced by the workflow, if it failed
     * @type {string}
     * @memberof WorkflowRun
     */
    error?: string;
    /**
     * Unique identifier for the workflow run
     * @type {string}
     * @memberof WorkflowRun
     */
    id: string;
    /**
     * If this run was against a specific incident, this is the ID of that incident
     * @type {string}
     * @memberof WorkflowRun
     */
    incident_id?: string;
    /**
     * If this run was against a specific incident, this is the reference of that incident
     * @type {string}
     * @memberof WorkflowRun
     */
    incident_reference?: string;
    /**
     * Once for configuration for this workflow
     * @type {Array<string>}
     * @memberof WorkflowRun
     */
    once_for?: Array<string>;
    /**
     * Unique identifier for the organisation that owns this workflow run
     * @type {string}
     * @memberof WorkflowRun
     */
    organisation_id: string;
    /**
     * Status of each step as it is worked
     * @type {Array<StepProgress>}
     * @memberof WorkflowRun
     */
    progress: Array<StepProgress>;
    /**
     * When the run was scheduled for
     * @type {Date}
     * @memberof WorkflowRun
     */
    scheduled_at: Date;
    /**
     * When the resource was last updated
     * @type {Date}
     * @memberof WorkflowRun
     */
    updated_at: Date;
    /**
     * Unique identifier for the underlying workflow
     * @type {string}
     * @memberof WorkflowRun
     */
    workflow_id: string;
    /**
     * Unique identifier of the workflow version
     * @type {string}
     * @memberof WorkflowRun
     */
    workflow_version_id: string;
}

export function WorkflowRunFromJSON(json: any): WorkflowRun {
    return WorkflowRunFromJSONTyped(json, false);
}

export function WorkflowRunFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowRun {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancelled_at': !exists(json, 'cancelled_at') ? undefined : (new Date(json['cancelled_at'])),
        'created_at': (new Date(json['created_at'])),
        'enqueued_at': !exists(json, 'enqueued_at') ? undefined : (new Date(json['enqueued_at'])),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'id': json['id'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'incident_reference': !exists(json, 'incident_reference') ? undefined : json['incident_reference'],
        'once_for': !exists(json, 'once_for') ? undefined : json['once_for'],
        'organisation_id': json['organisation_id'],
        'progress': ((json['progress'] as Array<any>).map(StepProgressFromJSON)),
        'scheduled_at': (new Date(json['scheduled_at'])),
        'updated_at': (new Date(json['updated_at'])),
        'workflow_id': json['workflow_id'],
        'workflow_version_id': json['workflow_version_id'],
    };
}

export function WorkflowRunToJSON(value?: WorkflowRun | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancelled_at': value.cancelled_at === undefined ? undefined : (value.cancelled_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'enqueued_at': value.enqueued_at === undefined ? undefined : (value.enqueued_at.toISOString()),
        'error': value.error,
        'id': value.id,
        'incident_id': value.incident_id,
        'incident_reference': value.incident_reference,
        'once_for': value.once_for,
        'organisation_id': value.organisation_id,
        'progress': ((value.progress as Array<any>).map(StepProgressToJSON)),
        'scheduled_at': (value.scheduled_at.toISOString()),
        'updated_at': (value.updated_at.toISOString()),
        'workflow_id': value.workflow_id,
        'workflow_version_id': value.workflow_version_id,
    };
}

