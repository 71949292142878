import { CatalogConfigType, EngineParamBinding } from "@incident-io/api";
import { CatalogTypeBadge } from "@incident-shared/attribute";
import { CatalogEntryIdentifiers } from "@incident-shared/catalog/CatalogEntryIdentifiers";
import { EngineFormElement } from "@incident-shared/engine";
import { Form } from "@incident-shared/forms";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { SourceTypeConfig } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  EmptyState,
  IconBadge,
  IconSize,
} from "@incident-ui";
import {
  CodeSnippet,
  CodeSnippetTheme,
} from "@incident-ui/CodeSnippet/CodeSnippet";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CatalogEntryEditDrawer } from "src/components/catalog/entry-view/CatalogEntryEditDrawer";
import { useAllResources } from "src/hooks/useResources";
import { useAPI } from "src/utils/swr";

export const AlertSourceTaggingInfoDrawer = ({
  onClose,
  catalogTypeOptions,
  sourceTypeConfig,
}: {
  onClose: () => void;
  catalogTypeOptions: CatalogConfigType[];
  sourceTypeConfig: SourceTypeConfig;
}) => {
  const formMethods = useForm<FormType>({
    defaultValues: {
      catalogTypeId: catalogTypeOptions[0].catalog_type_id,
    },
  });
  const { setValue } = formMethods;
  const [editEntryDrawerOpen, setEditEntryDrawerOpen] = useState(false);

  const selectedTypeId = formMethods.watch("catalogTypeId");
  const selectedEntryId = formMethods.watch("catalogEntry")?.value?.value;

  const selectedType = catalogTypeOptions.find(
    (opt) => opt.catalog_type_id === selectedTypeId,
  );

  const { resources, resourcesLoading } = useAllResources();

  const {
    data,
    mutate,
    isLoading: entriesLoading,
  } = useAPI("catalogListEntries", {
    catalogTypeId: selectedTypeId,
    pageSize: 1,
  });

  const { data: entryData, mutate: refetchEntry } = useAPI(
    selectedEntryId ? "catalogFindEntries" : null,
    {
      findEntriesRequestBody: {
        lookups: [
          {
            catalog_type_id: selectedTypeId,
            lookup_terms: [selectedEntryId || ""],
          },
        ],
      },
    },
  );

  const { data: typeData } = useAPI("catalogShowType", {
    id: selectedTypeId,
  });

  const selectedEntries = selectedEntryId
    ? entryData?.results[selectedTypeId]
    : undefined;

  const selectedEntry = selectedEntries?.length
    ? selectedEntries[0]
    : undefined;
  const selectedEntryType = typeData?.catalog_type;

  const refetchEntries = () => {
    mutate();
    refetchEntry();
  };

  useEffect(() => {
    const initialEntry = data?.catalog_entries[0];

    if (initialEntry) {
      setValue("catalogEntry", {
        value: {
          label: initialEntry.name,
          value: initialEntry.id,
          literal: initialEntry.id,
          sort_key: "0",
        },
      });
    } else {
      setValue("catalogEntry", { value: undefined });
    }
  }, [data, setValue]);

  const TaggingInstructionsComponent =
    sourceTypeConfig.taggingInstructionsComponent || DefaultTaggingInstructions;

  if (!selectedType || resourcesLoading || !resources) {
    return (
      <Drawer width="medium" onClose={() => onClose()}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  const resourceType = `CatalogEntry["${selectedType.catalog_type_id}"]`;

  return (
    <Drawer
      width="medium"
      onClose={() => onClose()}
      isInBackground={editEntryDrawerOpen}
    >
      {editEntryDrawerOpen && selectedEntryId && (
        <CatalogEntryEditDrawer
          onClose={() => setEditEntryDrawerOpen(false)}
          refetchEntries={refetchEntries}
          entryReference={selectedEntryId}
        />
      )}

      <DrawerContents>
        <DrawerTitle
          title={`Tagging alerts in ${sourceTypeConfig.label}`}
          onClose={() => onClose()}
          hexColor={`${sourceTypeConfig.hexColor}10`}
          compact
          titleAccessory={
            <IconBadge
              icon={sourceTypeConfig.icon}
              size={IconSize.Small}
              hexColor={sourceTypeConfig.hexColor}
              // In integrations, icons are either coloured logos or we should let people
              // configure it and align it to the rest of the drawer with hexColor.
              color={ColorPaletteEnum.SlateOnWhite}
            />
          }
        />
        <DrawerBody>
          <Form.Root
            onSubmit={() => {
              return;
            }}
            formMethods={formMethods}
          >
            <div className="flex flex-col gap-6">
              {catalogTypeOptions.length > 1 ? (
                <div className="flex flex-col gap-1">
                  <StaticSingleSelectV2
                    formMethods={formMethods}
                    labelClassName="!text-sm-bold"
                    label="What are your alerts routed by?"
                    options={catalogTypeOptions.map((opt) => ({
                      label: opt.name,
                      value: opt.catalog_type_id,
                      renderFn: (opt) => {
                        const type = catalogTypeOptions.find(
                          (ct) => ct.catalog_type_id === opt.data.value,
                        );

                        return (
                          <CatalogTypeBadge
                            size={BadgeSize.Small}
                            type={type?.engine_type_name || ""}
                          />
                        );
                      },
                    }))}
                    name="catalogTypeId"
                    isClearable={false}
                  />
                  <div className="text-xs-med text-content-tertiary">
                    Choose from types that are linked to an escalation path
                  </div>
                </div>
              ) : (
                <div className="text-sm-normal text-content-secondary">
                  Follow the steps below to tag your alerts in{" "}
                  {sourceTypeConfig.label} with a {selectedType.name}:
                </div>
              )}
              {selectedType ? (
                <>
                  <div className="flex flex-col gap-2">
                    <div className="text-sm-bold">
                      {sourceTypeConfig.label} setup
                    </div>
                    <TaggingInstructionsComponent catalogType={selectedType} />
                  </div>

                  {selectedEntryId ? (
                    <EngineFormElement<FormType>
                      name="catalogEntry"
                      labelNode={
                        <div className="text-sm-bold">
                          Reference your {selectedType.name}:
                        </div>
                      }
                      resourceType={resourceType}
                      array={false}
                      resources={resources}
                      mode="plain_input"
                      required={true}
                      className="w-full"
                    />
                  ) : null}

                  {selectedEntry && selectedEntryType && (
                    <CatalogEntryIdentifiers
                      entry={selectedEntry}
                      type={selectedEntryType}
                      onEditEntry={() => setEditEntryDrawerOpen(true)}
                      description={
                        <>
                          To dynamically route your alert to the right place,
                          you need to reference the right {selectedType.name}.
                          You can reference {pluralize(selectedType.name)} by
                          including any of the below identifiers in your alert
                          payload.
                        </>
                      }
                    />
                  )}
                  {!selectedEntryId && !entriesLoading ? (
                    <EmptyState
                      title="Add entries to route alerts"
                      content={
                        <div className="flex flex-col items-center gap-4">
                          Your Catalog type {selectedType.name} has no entries.
                          In order to dynamically route alerts, you should
                          create {pluralize(selectedType.name)} to route your
                          alerts to.
                          <Button
                            href={`/catalog/${selectedType.catalog_type_id}/create`}
                            openInNewTab={true}
                            analyticsTrackingId={null}
                          >
                            Add {selectedType.name}
                          </Button>
                        </div>
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </Form.Root>
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

type FormType = {
  catalogTypeId: string;
  catalogEntry: EngineParamBinding;
};

const DefaultTaggingInstructions = ({
  catalogType,
}: {
  catalogType: CatalogConfigType;
}) => {
  const suggestedTagKey = catalogType.name.toLowerCase().split(" ").join("-");

  return (
    <div className="p-4 border-stroke-primary border rounded-2">
      <NumberedList>
        <div>
          Send a unique{" "}
          <CodeSnippet theme={CodeSnippetTheme.White}>
            {suggestedTagKey}
          </CodeSnippet>{" "}
          value as part of your alert payload, as custom tags or metadata
        </div>
        <div>
          {/* <div className="flex flex-wrap items-center gap-1"> */}
          Set this value to reference your{" "}
          <CatalogTypeBadge
            type={catalogType.engine_type_name}
            size={BadgeSize.Small}
            className="align-middle"
          />{" "}
          type, by using one of the identifiers below
        </div>
        <div>
          Parse your{" "}
          <CodeSnippet theme={CodeSnippetTheme.White}>
            {suggestedTagKey}
          </CodeSnippet>{" "}
          payload value into an alert attribute within this alert source
        </div>
      </NumberedList>
    </div>
  );
};
