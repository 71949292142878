import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum, StackedListItem, Tooltip } from "@incident-ui";
import { CatalogType, CustomField } from "src/contexts/ClientContext";

import { customFieldTypeToIcon } from "./common/utils";

export const CustomFieldRow = ({
  field,
  catalogTypes,
}: {
  field: CustomField;
  catalogTypes: CatalogType[];
}): React.ReactElement => {
  const poweredByCatalog = field.catalog_type_id ?? false;
  const selectedCatalogType = poweredByCatalog
    ? catalogTypes.find((x) => x.id === field.catalog_type_id)
    : undefined;

  const icon =
    selectedCatalogType?.icon || customFieldTypeToIcon(field.field_type);
  const color = selectedCatalogType?.color || ColorPaletteEnum.SlateOnWhite;

  return (
    <StackedListItem
      noPadding
      icon={icon}
      iconColor={color as unknown as ColorPaletteEnum}
      title={field.name}
      badgeNode={
        field.engine_expression && (
          <Tooltip content={<>This field is set automatically</>}>
            <span>
              <Icon id={IconEnum.Expression} className="text-slate-400" />
            </span>
          </Tooltip>
        )
      }
    />
  );
};
