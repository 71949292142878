import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useRevalidate } from "src/utils/use-revalidate";

export const DeleteOverrideModal = ({
  onClose,
  overrideID,
}: {
  overrideID: string;
  onClose: () => void;
}) => {
  const apiClient = useClient();
  const showToast = useToast();
  const refetchSchedules = useRevalidate([
    "schedulesList",
    "schedulesListEntries",
    "schedulesListForUser",
    "schedulesShow",
  ]);

  const [deleteOverride, { saving: isDeleting }] = useMutation(
    async ({ overrideID }: { overrideID: string }) => {
      await apiClient.schedulesArchiveOverride({
        id: overrideID,
      });
      await refetchSchedules();
      return;
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Override deleted",
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  return (
    <DeletionConfirmationModal
      onClose={onClose}
      onDelete={async () => {
        await deleteOverride({
          overrideID,
        });
        onClose();
      }}
      resourceTitle="Override"
      isOpen={true}
      title="Delete override"
      analyticsTrackingId="delete-override-modal"
      deleteConfirmationContent="Are you sure you want to delete this override?"
      isDeleting={isDeleting}
    />
  );
};
