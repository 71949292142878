import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  IntegrationConfigFor,
  IntegrationListProvider,
} from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ButtonTheme,
  ConfirmationDialog,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const DisconnectButton = ({
  provider,
}: {
  provider: IntegrationListProvider;
}) => {
  const config = IntegrationConfigFor(provider);
  const navigate = useOrgAwareNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const onClose = () => navigate("/settings/integrations");
  const showToast = useToast();

  const { trigger: onDisconnect, isMutating: disconnecting } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient) => {
      if (config.onDisconnect) {
        await config.onDisconnect(apiClient);
      }
    },
    {
      onSuccess: onClose,
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Could not disconnect integration",
        });
      },
    },
  );

  const canDisconnectHook = config.canDisconnectHook || noOpDisconnectHook;
  const { loading, error, canDisconnect, cannotDisconnectExplanation } =
    canDisconnectHook();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (!config.onDisconnect) {
    return null;
  }

  return (
    <>
      <ConfirmationDialog
        title={`Disconnect ${config.label} integration`}
        analyticsTrackingId="disconnect-integration"
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={() => onDisconnect({})}
        saving={disconnecting}
        confirmButtonText="Disconnect"
        confirmButtonTheme={ButtonTheme.Destroy}
      >
        {config.disconnectWarning}
      </ConfirmationDialog>
      <GatedButton
        analyticsTrackingId="disconnect-integration"
        analyticsTrackingMetadata={{ integration: provider }}
        theme={ButtonTheme.DestroySecondary}
        icon={IconEnum.Delete}
        onClick={() => setShowDeleteDialog(true)}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        loading={loading}
        disabled={!canDisconnect}
        disabledTooltipContent={cannotDisconnectExplanation}
      >
        Disconnect
      </GatedButton>
    </>
  );
};

const noOpDisconnectHook = () => ({
  loading: false,
  error: undefined,
  canDisconnect: true,
  cannotDisconnectExplanation: undefined,
});
