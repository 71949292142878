import {
  ScopeNameEnum,
  WorkflowPayloadRunsOnIncidentsEnum as RunsOnIncidentsEnum,
} from "@incident-io/api";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
} from "@incident-ui";
import { DrawerBody } from "@incident-ui/Drawer/Drawer";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { formatTimestampLocale } from "../../../../utils/datetime";
import { WorkflowFormData } from "../common/types";
import { useWorkflowsTrigger } from "../create-edit-form/hooks/useTriggerController";
import { useWorkflowsDeps } from "../create-edit-form/WorkflowsFormContext";
import { WorkflowsFrequencySettingsSection } from "./WorkflowsFrequencySettingsSection";

export const WorkflowsAdvancedSettingsDrawer = () => {
  const { hasScope } = useIdentity();
  const isAllowedToEnableForPrivateIncidents = hasScope(
    ScopeNameEnum.WorkflowsApprovePrivate,
  );

  const formMethods = useFormContext<WorkflowFormData>();
  const { trigger, scope, resources, workflow } = useWorkflowsDeps();
  const { isManualTrigger } = useWorkflowsTrigger();

  const includePrivateIncidents = formMethods.watch(
    "include_private_incidents",
  );
  const continueOnStepError = formMethods.watch("continue_on_step_error");

  const { watch } = formMethods;
  const runsOnIncidents = watch("runs_on_incidents");

  return (
    <DrawerBody>
      <WorkflowsAdvancedSettingsSection>
        <StackedList className="[&>*]:p-4">
          {/* Test incidents settings */}
          {!isManualTrigger && (
            <>
              <ToggleV2
                formMethods={formMethods}
                name="include_test_incidents"
                label="Run on test incidents"
                toggleClassName="justify-between"
                toggleLabelClassName="!mr-2"
              />
              {/* Retrospective incidents settings */}
              <ToggleV2
                formMethods={formMethods}
                name="include_retrospective_incidents"
                label="Run on retrospective incidents"
                toggleClassName="justify-between"
                toggleLabelClassName="!mr-2"
              />
            </>
          )}

          {/* Private incidents settings */}
          <ToggleV2
            formMethods={formMethods}
            name="include_private_incidents"
            label="Run on private incidents"
            toggleLabelClassName="!mr-2"
            toggleClassName="justify-between"
            disabled={!isAllowedToEnableForPrivateIncidents}
            isDisabledTooltipContent={
              "You do not have permissions to enable a workflow for a private incident. Only account owners can enable this."
            }
          />
        </StackedList>
        {includePrivateIncidents && (
          <Callout
            theme={CalloutTheme.Warning}
            showIcon={true}
            className="mt-2"
          >
            <div className="text-slate-700">
              If this workflow includes steps to invite users, they will be
              immediately granted access to the private incident channel.
            </div>
          </Callout>
        )}
      </WorkflowsAdvancedSettingsSection>
      {/* Frequency settings */}
      {!isManualTrigger && (
        <WorkflowsAdvancedSettingsSection
          title={"How often should this workflow run?"}
        >
          {trigger ? (
            <WorkflowsFrequencySettingsSection
              trigger={trigger}
              formMethods={formMethods}
              scope={scope}
              resources={resources}
            />
          ) : (
            <div className="flex flex-row text-slate-400 items-center">
              <Icon size={IconSize.Large} id={IconEnum.Info} />
              Choose a trigger to enable this setting
            </div>
          )}
        </WorkflowsAdvancedSettingsSection>
      )}

      <WorkflowsAdvancedSettingsSection
        title={"How should we manage workflow steps in the event of an error?"}
      >
        <div className="text-slate-700 space-y-3">
          <StackedList className="[&>*]:p-4">
            <ToggleV2
              formMethods={formMethods}
              name="continue_on_step_error"
              label="Continue executing upon failed step"
              toggleClassName="justify-between"
              toggleLabelClassName="!mr-2"
            />
          </StackedList>
          <Callout theme={CalloutTheme.Info} className="my-3">
            {continueOnStepError
              ? "If a step in this workflow encounters an error, all subsequent steps or loop iterations will continue to be executed."
              : "If a step in this workflow encounters an error, no subsequent steps or loop iterations will be executed."}
          </Callout>
        </div>
      </WorkflowsAdvancedSettingsSection>

      {/* Deploy settings - we'll only show this when editing an existing workflow */}
      {workflow && !isManualTrigger && (
        <WorkflowsAdvancedSettingsSection title="Deployment">
          <div className="text-slate-700 space-y-3">
            {workflow.runs_from ? (
              <div>
                This workflow will run on incidents{" "}
                {runsOnExplanation(runsOnIncidents)}{" "}
                {formatTimestampLocale({
                  timestamp: workflow.runs_from,
                  dateStyle: "short",
                  timeStyle: "short",
                })}{" "}
                that match the conditions.
              </div>
            ) : (
              <div>
                When you set this workflow to live, you can configure which
                incidents you&apos;d like it to run on.
              </div>
            )}
          </div>
        </WorkflowsAdvancedSettingsSection>
      )}
    </DrawerBody>
  );
};

const WorkflowsAdvancedSettingsSection = ({
  title,
  children,
  className,
}: {
  title?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("text-sm flex flex-col gap-2", className)}>
      {title && <div className="flex flex-row gap-1 font-medium">{title}</div>}
      {children}
    </div>
  );
};

const runsOnExplanation = (runsOnIncidents: RunsOnIncidentsEnum): string => {
  switch (runsOnIncidents) {
    case RunsOnIncidentsEnum.CreatedAndActive:
      return "created after or active on";
    case RunsOnIncidentsEnum.Created:
      return "created after";
    default:
      return "created after or active on";
  }
};
