/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntry,
    CatalogEntryFromJSON,
    CatalogEntryFromJSONTyped,
    CatalogEntryToJSON,
} from './CatalogEntry';

/**
 * 
 * @export
 * @interface CatalogBulkCreateEntriesResponseBody
 */
export interface CatalogBulkCreateEntriesResponseBody {
    /**
     * 
     * @type {Array<CatalogEntry>}
     * @memberof CatalogBulkCreateEntriesResponseBody
     */
    catalog_entries: Array<CatalogEntry>;
}

export function CatalogBulkCreateEntriesResponseBodyFromJSON(json: any): CatalogBulkCreateEntriesResponseBody {
    return CatalogBulkCreateEntriesResponseBodyFromJSONTyped(json, false);
}

export function CatalogBulkCreateEntriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogBulkCreateEntriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entries': ((json['catalog_entries'] as Array<any>).map(CatalogEntryFromJSON)),
    };
}

export function CatalogBulkCreateEntriesResponseBodyToJSON(value?: CatalogBulkCreateEntriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entries': ((value.catalog_entries as Array<any>).map(CatalogEntryToJSON)),
    };
}

