/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueTrackerSyncConfigPayload,
    IssueTrackerSyncConfigPayloadFromJSON,
    IssueTrackerSyncConfigPayloadFromJSONTyped,
    IssueTrackerSyncConfigPayloadToJSON,
} from './IssueTrackerSyncConfigPayload';

/**
 * 
 * @export
 * @interface IssueTrackersUpdateIssueTrackerSyncConfigRequestBody
 */
export interface IssueTrackersUpdateIssueTrackerSyncConfigRequestBody {
    /**
     * 
     * @type {IssueTrackerSyncConfigPayload}
     * @memberof IssueTrackersUpdateIssueTrackerSyncConfigRequestBody
     */
    sync_config: IssueTrackerSyncConfigPayload;
}

export function IssueTrackersUpdateIssueTrackerSyncConfigRequestBodyFromJSON(json: any): IssueTrackersUpdateIssueTrackerSyncConfigRequestBody {
    return IssueTrackersUpdateIssueTrackerSyncConfigRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersUpdateIssueTrackerSyncConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersUpdateIssueTrackerSyncConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sync_config': IssueTrackerSyncConfigPayloadFromJSON(json['sync_config']),
    };
}

export function IssueTrackersUpdateIssueTrackerSyncConfigRequestBodyToJSON(value?: IssueTrackersUpdateIssueTrackerSyncConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sync_config': IssueTrackerSyncConfigPayloadToJSON(value.sync_config),
    };
}

