/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';
import {
    StepConfigSlim,
    StepConfigSlimFromJSON,
    StepConfigSlimFromJSONTyped,
    StepConfigSlimToJSON,
} from './StepConfigSlim';
import {
    TriggerSlim,
    TriggerSlimFromJSON,
    TriggerSlimFromJSONTyped,
    TriggerSlimToJSON,
} from './TriggerSlim';

/**
 * 
 * @export
 * @interface WorkflowSlim
 */
export interface WorkflowSlim {
    /**
     * Conditions that apply to the workflow trigger
     * @type {Array<ConditionGroup>}
     * @memberof WorkflowSlim
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * If this workflow is delayed, whether the conditions should be rechecked between trigger firing and execution
     * @type {boolean}
     * @memberof WorkflowSlim
     */
    conditions_apply_over_delay?: boolean;
    /**
     * Whether to continue executing the workflow if a step fails
     * @type {boolean}
     * @memberof WorkflowSlim
     */
    continue_on_step_error: boolean;
    /**
     * Delay in seconds between trigger firing and running the workflow
     * @type {number}
     * @memberof WorkflowSlim
     */
    delay_for_seconds?: number;
    /**
     * If present, this includes an error that was encountered trying to serialize the workflow
     * @type {string}
     * @memberof WorkflowSlim
     */
    error_string?: string;
    /**
     * Expressions that make variables available in the scope
     * @type {Array<Expression>}
     * @memberof WorkflowSlim
     */
    expressions: Array<Expression>;
    /**
     * Folder to display the workflow in
     * @type {string}
     * @memberof WorkflowSlim
     */
    folder?: string;
    /**
     * Unique identifier for the workflow
     * @type {string}
     * @memberof WorkflowSlim
     */
    id: string;
    /**
     * Whether to include private incidents
     * @type {boolean}
     * @memberof WorkflowSlim
     */
    include_private_incidents: boolean;
    /**
     * Whether to include retrospective incidents
     * @type {boolean}
     * @memberof WorkflowSlim
     */
    include_retrospective_incidents: boolean;
    /**
     * Whether to include test incidents
     * @type {boolean}
     * @memberof WorkflowSlim
     */
    include_test_incidents: boolean;
    /**
     * The url of the external repository where this workflow is managed
     * @type {string}
     * @memberof WorkflowSlim
     */
    managed_source_url?: string;
    /**
     * Name provided by the user when creating the workflow
     * @type {string}
     * @memberof WorkflowSlim
     */
    name: string;
    /**
     * This workflow will run 'once for' a list of references
     * @type {Array<Reference>}
     * @memberof WorkflowSlim
     */
    once_for: Array<Reference>;
    /**
     * Unique identifier for the organisation that owns this workflow
     * @type {string}
     * @memberof WorkflowSlim
     */
    organisation_id: string;
    /**
     * The time from which this workflow will run on incidents
     * @type {Date}
     * @memberof WorkflowSlim
     */
    runs_from?: Date;
    /**
     * Which incidents should the workflow be applied to?
     * @type {string}
     * @memberof WorkflowSlim
     */
    runs_on_incidents: WorkflowSlimRunsOnIncidentsEnum;
    /**
     * The shortform used to trigger this workflow (only applicable for manual triggers)
     * @type {string}
     * @memberof WorkflowSlim
     */
    shortform?: string;
    /**
     * Steps that are executed as part of the workflow
     * @type {Array<StepConfigSlim>}
     * @memberof WorkflowSlim
     */
    steps: Array<StepConfigSlim>;
    /**
     * 
     * @type {TriggerSlim}
     * @memberof WorkflowSlim
     */
    trigger: TriggerSlim;
    /**
     * Revision of the workflow, uniquely identifying it's version
     * @type {number}
     * @memberof WorkflowSlim
     */
    version: number;
    /**
     * The state of the workflow
     * @type {string}
     * @memberof WorkflowSlim
     */
    workflow_state: WorkflowSlimWorkflowStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum WorkflowSlimRunsOnIncidentsEnum {
    Created = 'newly_created',
    CreatedAndActive = 'newly_created_and_active'
}/**
* @export
* @enum {string}
*/
export enum WorkflowSlimWorkflowStateEnum {
    Active = 'active',
    Disabled = 'disabled',
    Draft = 'draft',
    Error = 'error'
}

export function WorkflowSlimFromJSON(json: any): WorkflowSlim {
    return WorkflowSlimFromJSONTyped(json, false);
}

export function WorkflowSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'conditions_apply_over_delay': !exists(json, 'conditions_apply_over_delay') ? undefined : json['conditions_apply_over_delay'],
        'continue_on_step_error': json['continue_on_step_error'],
        'delay_for_seconds': !exists(json, 'delay_for_seconds') ? undefined : json['delay_for_seconds'],
        'error_string': !exists(json, 'error_string') ? undefined : json['error_string'],
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'id': json['id'],
        'include_private_incidents': json['include_private_incidents'],
        'include_retrospective_incidents': json['include_retrospective_incidents'],
        'include_test_incidents': json['include_test_incidents'],
        'managed_source_url': !exists(json, 'managed_source_url') ? undefined : json['managed_source_url'],
        'name': json['name'],
        'once_for': ((json['once_for'] as Array<any>).map(ReferenceFromJSON)),
        'organisation_id': json['organisation_id'],
        'runs_from': !exists(json, 'runs_from') ? undefined : (new Date(json['runs_from'])),
        'runs_on_incidents': json['runs_on_incidents'],
        'shortform': !exists(json, 'shortform') ? undefined : json['shortform'],
        'steps': ((json['steps'] as Array<any>).map(StepConfigSlimFromJSON)),
        'trigger': TriggerSlimFromJSON(json['trigger']),
        'version': json['version'],
        'workflow_state': json['workflow_state'],
    };
}

export function WorkflowSlimToJSON(value?: WorkflowSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'conditions_apply_over_delay': value.conditions_apply_over_delay,
        'continue_on_step_error': value.continue_on_step_error,
        'delay_for_seconds': value.delay_for_seconds,
        'error_string': value.error_string,
        'expressions': ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'folder': value.folder,
        'id': value.id,
        'include_private_incidents': value.include_private_incidents,
        'include_retrospective_incidents': value.include_retrospective_incidents,
        'include_test_incidents': value.include_test_incidents,
        'managed_source_url': value.managed_source_url,
        'name': value.name,
        'once_for': ((value.once_for as Array<any>).map(ReferenceToJSON)),
        'organisation_id': value.organisation_id,
        'runs_from': value.runs_from === undefined ? undefined : (value.runs_from.toISOString()),
        'runs_on_incidents': value.runs_on_incidents,
        'shortform': value.shortform,
        'steps': ((value.steps as Array<any>).map(StepConfigSlimToJSON)),
        'trigger': TriggerSlimToJSON(value.trigger),
        'version': value.version,
        'workflow_state': value.workflow_state,
    };
}

