import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { SortableListV2 } from "@incident-shared/forms/v2/inputs/SortableListV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Icon,
  IconEnum,
  Input,
  Link,
} from "@incident-ui";
import React, { useCallback, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CustomField } from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";
import { tcx } from "src/utils/tailwind-classes";
import { v4 as uuidv4 } from "uuid";

import { ConvertCustomFieldToCatalogModal } from "../convert-to-catalog/ConvertCustomFieldToCatalogModal";
import { getDefaultCustomFieldOption } from "../SettingsCustomFieldCreateRoute";
import { CustomFieldFormData } from "./CustomFieldCreateEditDrawer";
import { CustomFieldOptionsFromCatalogTypeSection } from "./CustomFieldOptionsFromCatalogTypeSection";
import { CustomFieldOptionOrGroup, sortOptionsOrGroups } from "./marshall";
import { managementOptions, ManagementType } from "./types";

export const CustomFieldOptionsEditSection = ({
  customField,
  hasFixedReturnType,
}: {
  customField?: CustomField;
  hasFixedReturnType: boolean;
}): React.ReactElement | null => {
  const formMethods = useFormContext<CustomFieldFormData>();
  const [showMigrateToCatalogModal, setShowMigrateToCatalogModal] =
    useState(false);

  const management = formMethods.watch("management");

  const onCloseConvertToCatalogModal = useCallback(() => {
    setShowMigrateToCatalogModal(false);
  }, []);

  const configuringInternalStatusPage = useQueryParams().has("for-internal-sp");

  return (
    <>
      {/* Once something is a catalog field type, you can't change it back to a non-catalog
      type as all existing values would be broken. */}
      {hasFixedReturnType ? null : (
        <RadioButtonGroupV2
          formMethods={formMethods}
          name="management"
          boxed
          label="How should options be managed?"
          srLabel="Manage options by:"
          // You can only use catalog types the first time you create a custom field.
          options={managementOptions(customField == null)}
          onValueChange={(value) => {
            if (value !== ManagementType.Catalog) {
              formMethods.setValue<"catalog_type_id">(
                "catalog_type_id",
                // @ts-expect-error if you use undefined, which ts wants, it does nothing :rage:
                null,
              );
            }
          }}
        />
      )}
      {management === ManagementType.Catalog ? (
        <CustomFieldOptionsFromCatalogTypeSection
          customField={customField}
          hasFixedReturnType={hasFixedReturnType}
        />
      ) : (
        <>
          {configuringInternalStatusPage &&
            management === ManagementType.Dynamic && (
              <Callout theme={CalloutTheme.Warning}>
                When a custom field is used as the source for internal status
                page components, we recommend using a fixed list. Options added
                by users will appear on your internal status page straight away.
              </Callout>
            )}
          {customField != null && (
            <Callout theme={CalloutTheme.Plain} className="py-3">
              <div className="mb-2">
                You can use the{" "}
                <Link
                  openInNewTab
                  analyticsTrackingId={"custom-fields-catalog-link"}
                  to={"/catalog"}
                >
                  Catalog
                </Link>{" "}
                to power custom fields, keeping your data in-sync and allowing
                you to query the catalog from inside workflows and automations.
              </div>
              <Button
                analyticsTrackingId="migrate-to-catalog"
                onClick={() => setShowMigrateToCatalogModal(true)}
              >
                Convert to catalog type
              </Button>
            </Callout>
          )}
          <CustomFieldOptionsList />
        </>
      )}
      {showMigrateToCatalogModal && customField && (
        <ConvertCustomFieldToCatalogModal
          onClose={onCloseConvertToCatalogModal}
          customField={customField}
        />
      )}
      <ContentBox className="p-4">
        <ToggleV2
          formMethods={formMethods}
          name="cannot_be_unset"
          label="After this field has been set, don't allow a user to unset it"
          description="This means that if a field is required on a form (e.g. the declare form) it cannot later be accidentally unset."
          toggleClassName="grow justify-between"
          labelWrapperClassName="flex flex-row"
        />
      </ContentBox>
    </>
  );
};

export const CustomFieldOptionsList = ({
  helptext: helptextOverride,
}: {
  helptext?: React.ReactElement | string;
}): React.ReactElement => {
  const formMethods = useFormContext<CustomFieldFormData>();
  const management = formMethods.watch("management");

  const arrayMethods = useFieldArray({
    control: formMethods.control,
    name: "options",
  });

  const hasAnyGroups = arrayMethods.fields.some((f) => f.itemType === "group");

  const onSort = () => {
    // We ignore groups, and we sort all the values within each group.
    const sorted = sortOptionsOrGroups(arrayMethods.fields);
    arrayMethods.replace(sorted);
  };

  // If we do have groups, but the first item isn't a group, include a fake group
  // at the top
  const hasFakeGroup =
    hasAnyGroups && arrayMethods.fields[0].itemType !== "group";

  return (
    <>
      <SortableListV2
        canEdit
        formMethods={formMethods}
        label={"Options"}
        name={"options"}
        showErrorAboveComponent
        className={"!border-0"}
        helptext={
          helptextOverride !== undefined
            ? helptextOverride
            : management === ManagementType.Manual
            ? "Add options here, picking the order they will be displayed by drag-and-dropping."
            : management === ManagementType.Dynamic
            ? "As well as options here, responders can add extra options during an incident. Those can be edited and re-ordered here."
            : "Options are synced from an external resource, but you can still pick the order they will be displayed."
        }
        arrayMethods={arrayMethods}
        headerRow={
          hasFakeGroup ? (
            <div className="flex-center-y w-full p-2 space-x-2 pl-10 pr-10">
              <Icon
                id={IconEnum.FolderNoPadding}
                className="text-content-tertiary"
              />
              <Input
                id="fake"
                className="flex-1 text-content-tertiary"
                placeholder="Type the label for this group"
                disabled
                value="No group"
              />
            </div>
          ) : undefined
        }
        renderItem={({ item, index: itemIndex, deleteItem, dragHandle }) => {
          switch (item.itemType) {
            case "group":
              return (
                <CustomFieldOptionGroup
                  dragHandle={null}
                  item={item}
                  itemIndex={itemIndex}
                  options={arrayMethods.fields}
                  deleteItem={deleteItem}
                />
              );
            case "option":
              return (
                <CustomFieldOptionItem
                  dragHandle={dragHandle}
                  item={item}
                  hasAnyGroups={hasAnyGroups}
                  itemIndex={itemIndex}
                  options={arrayMethods.fields}
                  deleteItem={deleteItem}
                />
              );
            default:
              return <></>;
          }
        }}
      />
      <div className="space-x-4">
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Naked}
          onClick={() =>
            arrayMethods.append({
              itemType: "option",
              option: getDefaultCustomFieldOption(),
              key: uuidv4(),
            })
          }
          icon={IconEnum.Add}
        >
          Add new option
        </Button>
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Naked}
          onClick={() => {
            const newGroup: CustomFieldOptionOrGroup = {
              itemType: "group",
              key: uuidv4(),
            };

            if (hasAnyGroups) {
              // If they already have groups, append it to the end
              arrayMethods.append(newGroup);
            } else {
              // Otherwise, insert it at the beginning
              arrayMethods.insert(0, newGroup);
            }
          }}
          icon={IconEnum.Add}
        >
          Add new group
        </Button>
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Naked}
          onClick={onSort}
          icon={IconEnum.Sort}
        >
          Sort options
        </Button>
      </div>
    </>
  );
};

const CustomFieldOptionGroup = ({
  dragHandle,
  itemIndex,
  deleteItem,
  options,
  item,
}: {
  dragHandle: React.ReactNode;
  itemIndex: number;
  deleteItem: () => void;
  options: CustomFieldOptionOrGroup[];
  item: CustomFieldOptionOrGroup;
}): React.ReactElement => {
  const formMethods = useFormContext<CustomFieldFormData>();

  return (
    <div className="flex-center-y flex-1 p-2 mt-2">
      {dragHandle}
      <InputV2
        prefixNode={
          <Icon id={IconEnum.FolderNoPadding} className="text-slate-700" />
        }
        className="flex-1"
        inputClassName="font-semibold"
        formMethods={formMethods}
        name={`options.${itemIndex}.group.label`}
        placeholder="e.g. Integrations"
        suffixNode={
          <Button
            analyticsTrackingId={null}
            title="Remove"
            onClick={deleteItem}
            icon={IconEnum.Delete}
            theme={ButtonTheme.Naked}
            className="ml-1"
          />
        }
        required
        rules={{
          maxLength: {
            value: 100,
            message: "Options must be less than 100 characters",
          },
          validate: () => {
            // 1. check that no other group has the same name
            for (const option of options) {
              if (
                option.itemType === "group" &&
                option.key !== item.key &&
                option.group === item.group
              ) {
                return "Groups must be unique";
              }
            }
            // 2. check that there is at least one option in this group,
            // i.e. the next thing isn't another group, or the end of the row
            const next = options[itemIndex + 1];
            if (!next || next.itemType === "group") {
              return "Each group must have at least one item";
            }

            return true;
          },
        }}
      />
    </div>
  );
};

const CustomFieldOptionItem = ({
  dragHandle,
  itemIndex,
  deleteItem,
  options,
  hasAnyGroups,
  item,
}: {
  dragHandle: React.ReactNode;
  itemIndex: number;
  deleteItem: () => void;
  options: CustomFieldOptionOrGroup[];
  hasAnyGroups: boolean;
  item: CustomFieldOptionOrGroup;
}): React.ReactElement => {
  const formMethods = useFormContext<CustomFieldFormData>();

  return (
    <div
      className={tcx("flex-center-y flex-1 p-2", {
        ["ml-14"]: hasAnyGroups,
      })}
    >
      {dragHandle}
      <InputV2
        prefixNode={
          hasAnyGroups ? (
            <Icon
              id={IconEnum.Undo}
              className="text-slate-700 rotate-180 mb-1"
            />
          ) : undefined
        }
        className="flex-1"
        formMethods={formMethods}
        name={`options.${itemIndex}.option.value`}
        placeholder="Type the label for this option"
        suffixNode={
          <Button
            analyticsTrackingId={null}
            title="Remove"
            onClick={deleteItem}
            icon={IconEnum.Delete}
            theme={ButtonTheme.Naked}
            className="ml-1"
          />
        }
        rules={{
          maxLength: {
            value: 100,
            message: "Options must be less than 100 characters",
          },
          required: "Please provide a value",
          validate: () => {
            for (const option of options) {
              if (
                option.itemType === "option" &&
                option.key !== item.key &&
                option.option?.value === item.option?.value
              ) {
                return "Options must be unique";
              }
            }
            return true;
          },
        }}
      />
    </div>
  );
};
