/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImagesCreateUploadURLRequestBody
 */
export interface ImagesCreateUploadURLRequestBody {
    /**
     * Content type of the image to upload
     * @type {string}
     * @memberof ImagesCreateUploadURLRequestBody
     */
    content_type: ImagesCreateUploadURLRequestBodyContentTypeEnum;
    /**
     * Optional ID to associate this image to an incident
     * @type {string}
     * @memberof ImagesCreateUploadURLRequestBody
     */
    incident_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum ImagesCreateUploadURLRequestBodyContentTypeEnum {
    Png = 'image/png',
    Jpeg = 'image/jpeg',
    Svgxml = 'image/svg+xml'
}

export function ImagesCreateUploadURLRequestBodyFromJSON(json: any): ImagesCreateUploadURLRequestBody {
    return ImagesCreateUploadURLRequestBodyFromJSONTyped(json, false);
}

export function ImagesCreateUploadURLRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImagesCreateUploadURLRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content_type': json['content_type'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
    };
}

export function ImagesCreateUploadURLRequestBodyToJSON(value?: ImagesCreateUploadURLRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content_type': value.content_type,
        'incident_id': value.incident_id,
    };
}

