/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PagerDutyConfig,
    PagerDutyConfigFromJSON,
    PagerDutyConfigFromJSONTyped,
    PagerDutyConfigToJSON,
} from './PagerDutyConfig';

/**
 * 
 * @export
 * @interface IntegrationsPagerDutyUpdateConfigResponseBody
 */
export interface IntegrationsPagerDutyUpdateConfigResponseBody {
    /**
     * 
     * @type {PagerDutyConfig}
     * @memberof IntegrationsPagerDutyUpdateConfigResponseBody
     */
    pager_duty_config?: PagerDutyConfig;
}

export function IntegrationsPagerDutyUpdateConfigResponseBodyFromJSON(json: any): IntegrationsPagerDutyUpdateConfigResponseBody {
    return IntegrationsPagerDutyUpdateConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsPagerDutyUpdateConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsPagerDutyUpdateConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pager_duty_config': !exists(json, 'pager_duty_config') ? undefined : PagerDutyConfigFromJSON(json['pager_duty_config']),
    };
}

export function IntegrationsPagerDutyUpdateConfigResponseBodyToJSON(value?: IntegrationsPagerDutyUpdateConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pager_duty_config': PagerDutyConfigToJSON(value.pager_duty_config),
    };
}

