import { Country } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  IconEnum,
  Loader,
  StackedList,
  StackedListItem,
  Tooltip,
} from "@incident-ui";
import { useMemo, useState } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { AddPublicHolidaysModal } from "src/components/legacy/on-call/schedules/schedule-create-edit-form/AddPublicHolidaysModal";
import { ScheduleFormData } from "src/components/legacy/on-call/schedules/schedule-create-edit-form/ScheduleCreateEditForm";
import { useAPI } from "src/utils/swr";
import { useLocalStorage } from "usehooks-ts";

export const HolidaysPublicConfigurationInput = ({
  formMethods,
  openCalendarFeedDrawer,
  disabled,
  disabledTooltipContent,
}: {
  formMethods: UseFormReturn<ScheduleFormData>;
  openCalendarFeedDrawer: () => void;
  disabled?: boolean;
  disabledTooltipContent?: string;
}) => {
  const localStorageKey = "connectToCalendarFeedsDismissed";
  const [calendarSuggestionDismissed, setCalendarSuggestionDismissed] =
    useLocalStorage(localStorageKey, false);
  const handleDismiss = () => setCalendarSuggestionDismissed(true);

  const [isShowingAddPublicHolidayModal, setIsShowingAddPublicHolidayModal] =
    useState(false);

  const { data: countriesData, isLoading: isLoadingCountries } = useAPI(
    "holidaysListCountries",
    {},
  );

  const { data: feedsData, isLoading: isLoadingFeeds } = useAPI(
    "holidaysListFeeds",
    {},
  );

  const countryCodeMethods = useFieldArray({
    name: "holidays_public_config.country_codes",
    control: formMethods.control,
  });
  const selectedCountryCodes = formMethods.watch(
    "holidays_public_config.country_codes",
  );

  const selectedCountries = useMemo(
    () =>
      (selectedCountryCodes ?? [])
        .map(({ id }) => {
          if (!countriesData) {
            return [];
          }

          return countriesData.countries.find((country) => country.code === id);
        })
        .filter(Boolean) as Country[],
    [selectedCountryCodes, countriesData],
  );

  return (
    <div>
      <div className={"mt-6 w-full"}>
        <div className="w-full flex flex-row justify-between items-center">
          <span className={"font-medium"}>Holidays shown on schedule</span>

          <GatedButton
            theme={ButtonTheme.Naked}
            analyticsTrackingId={"schedules-add-public-holiday"}
            icon={IconEnum.Add}
            onClick={() => {
              setIsShowingAddPublicHolidayModal(true);
            }}
            disabled={disabled}
            disabledTooltipContent={disabledTooltipContent}
          >
            Add public holidays
          </GatedButton>
        </div>
      </div>
      {isLoadingCountries || (isLoadingFeeds && <Loader />)}
      <div className={"flex flex-col gap-2"}>
        {selectedCountries.length === 0 &&
        feedsData?.holiday_user_feeds.length === 0 ? (
          <EmptyState className={"mt-2 !p-4"} content={"No holidays added"} />
        ) : (
          <StackedList className={"!w-full mt-2"}>
            {selectedCountries.map((country: Country, i: number) => {
              return (
                <StackedListItem
                  key={country.code}
                  title={country.name}
                  accessory={
                    <Button
                      analyticsTrackingId={"remove-public-holiday"}
                      size={BadgeSize.Medium}
                      icon={IconEnum.Delete}
                      theme={ButtonTheme.Tertiary}
                      title="Delete"
                      onClick={() => {
                        countryCodeMethods.remove(i);
                      }}
                    />
                  }
                />
              );
            })}
            {feedsData?.holiday_user_feeds.map((feed) => {
              return (
                <StackedListItem
                  key={feed.id}
                  title={feed.name}
                  badgeNode={
                    <Tooltip
                      content={
                        <div>
                          You can manage calendar feeds from{" "}
                          <span
                            className={
                              "underline hover:text-content-secondary hover:cursor-pointer"
                            }
                            onClick={openCalendarFeedDrawer}
                          >
                            integrations
                          </span>
                          .
                        </div>
                      }
                    >
                      <Badge
                        theme={BadgeTheme.Tertiary}
                        icon={IconEnum.Info}
                        className="text-xs items-center text-content-secondary"
                      >
                        <div onClick={openCalendarFeedDrawer}>
                          From calendar feeds
                        </div>
                      </Badge>
                    </Tooltip>
                  }
                  accessory={
                    <Button
                      analyticsTrackingId={"remove-public-holiday"}
                      size={BadgeSize.Medium}
                      icon={IconEnum.Delete}
                      disabled // We don't actually want to let people delete
                      theme={ButtonTheme.Tertiary}
                      title="Delete"
                    />
                  }
                />
              );
            })}
          </StackedList>
        )}
        {feedsData?.holiday_user_feeds.length === 0 &&
          !calendarSuggestionDismissed && (
            <Callout
              theme={CalloutTheme.Plain}
              iconOverride={IconEnum.Integrations}
              showIcon
              className={"leading-tight"}
              cta={
                <Button
                  icon={IconEnum.Close}
                  onClick={handleDismiss}
                  analyticsTrackingId={"dismiss-calendar-feed-suggestion"}
                  title={"Dismiss"}
                  className={"text-content-tertiary hover:text-content-primary"}
                  theme={ButtonTheme.Unstyled}
                />
              }
            >
              <span
                onClick={openCalendarFeedDrawer}
                className={
                  "underline hover:cursor-pointer hover:text-alarmalade-600"
                }
              >
                Connect your HR software
              </span>{" "}
              to show employee holidays
            </Callout>
          )}
      </div>

      {isShowingAddPublicHolidayModal && (
        <AddPublicHolidaysModal
          selectedCountryCodes={selectedCountryCodes}
          onAddCountry={(countryCode) => {
            countryCodeMethods.append({
              id: countryCode,
            });
            setIsShowingAddPublicHolidayModal(false);
          }}
          onClose={() => setIsShowingAddPublicHolidayModal(false)}
        />
      )}
    </div>
  );
};
