/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageStructureAndComponentsComponentPayload
 */
export interface StatusPageStructureAndComponentsComponentPayload {
    /**
     * If set, this will track this against an Atlassian Statuspage component
     * @type {string}
     * @memberof StatusPageStructureAndComponentsComponentPayload
     */
    atlassian_statuspage_component_id?: string;
    /**
     * A short description of this component
     * @type {string}
     * @memberof StatusPageStructureAndComponentsComponentPayload
     */
    description?: string;
    /**
     * Whether to display historical uptime for this component
     * @type {boolean}
     * @memberof StatusPageStructureAndComponentsComponentPayload
     */
    display_uptime: boolean;
    /**
     * If set, this will reuse an existing component. Otherwise, a new component will be created.
     * @type {string}
     * @memberof StatusPageStructureAndComponentsComponentPayload
     */
    existing_component_id?: string;
    /**
     * Whether this component is hidden from display on the status page
     * @type {boolean}
     * @memberof StatusPageStructureAndComponentsComponentPayload
     */
    hidden: boolean;
    /**
     * A human readable name for the component
     * @type {string}
     * @memberof StatusPageStructureAndComponentsComponentPayload
     */
    name: string;
}

export function StatusPageStructureAndComponentsComponentPayloadFromJSON(json: any): StatusPageStructureAndComponentsComponentPayload {
    return StatusPageStructureAndComponentsComponentPayloadFromJSONTyped(json, false);
}

export function StatusPageStructureAndComponentsComponentPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureAndComponentsComponentPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atlassian_statuspage_component_id': !exists(json, 'atlassian_statuspage_component_id') ? undefined : json['atlassian_statuspage_component_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'display_uptime': json['display_uptime'],
        'existing_component_id': !exists(json, 'existing_component_id') ? undefined : json['existing_component_id'],
        'hidden': json['hidden'],
        'name': json['name'],
    };
}

export function StatusPageStructureAndComponentsComponentPayloadToJSON(value?: StatusPageStructureAndComponentsComponentPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atlassian_statuspage_component_id': value.atlassian_statuspage_component_id,
        'description': value.description,
        'display_uptime': value.display_uptime,
        'existing_component_id': value.existing_component_id,
        'hidden': value.hidden,
        'name': value.name,
    };
}

