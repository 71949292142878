import { ErrorMessage } from "@incident-ui";
import { ConfirmationDialog } from "@incident-ui/ConfirmationDialog/ConfirmationDialog";
import {
  Incident,
  IncidentModeEnum,
  IncidentsUpdateModeRequestBodyModeEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { useRevalidate } from "../../../../utils/use-revalidate";

export function UpdateIsTestConfirmationModal({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement {
  const refreshIncidentList = useRevalidate(["incidentsList"]);

  const {
    trigger: updateMode,
    genericError,
    isMutating,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (
      apiClient,
      { mode }: { mode: IncidentsUpdateModeRequestBodyModeEnum },
    ) => {
      await apiClient.incidentsUpdateMode({
        id: incident.id,
        updateModeRequestBody: {
          mode,
        },
      });
    },
    {
      onSuccess: () => {
        refreshIncidentList();
        onClose();
      },
    },
  );

  return (
    <ConfirmationDialog
      isOpen
      saving={isMutating}
      analyticsTrackingId={
        incident.mode === IncidentModeEnum.Test
          ? "mark-as-live"
          : "mark-as-test"
      }
      title={
        incident.mode === IncidentModeEnum.Test
          ? "Mark as real incident"
          : "Mark as test incident"
      }
      onConfirm={() => {
        if (incident.mode === IncidentModeEnum.Test) {
          // We don't let users change from test to retrospective or tutorial, just assume standard
          updateMode({
            mode: IncidentsUpdateModeRequestBodyModeEnum.Standard,
          });
        } else {
          updateMode({ mode: IncidentsUpdateModeRequestBodyModeEnum.Test });
        }
      }}
      onCancel={onClose}
    >
      <>
        {incident.mode === IncidentModeEnum.Test
          ? "By marking this as a real incident, it will appear on the incidents page and be included in CSV exports. It will not be announced in the incidents channel."
          : "By marking this as a test incident, it will no longer appear on the incidents page or in CSV exports. Workflows will no longer run on the incident, and we will delete the relevant announcement posts."}
        {genericError && <ErrorMessage message={genericError} />}
      </>
    </ConfirmationDialog>
  );
}
