/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpStatusChange
 */
export interface FollowUpStatusChange {
    /**
     * the status that the follow-up was in after the change
     * @type {string}
     * @memberof FollowUpStatusChange
     */
    next_status: FollowUpStatusChangeNextStatusEnum;
    /**
     * the status that the follow-up was in before the change
     * @type {string}
     * @memberof FollowUpStatusChange
     */
    previous_status: FollowUpStatusChangePreviousStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum FollowUpStatusChangeNextStatusEnum {
    Outstanding = 'outstanding',
    Completed = 'completed',
    Deleted = 'deleted',
    NotDoing = 'not_doing'
}/**
* @export
* @enum {string}
*/
export enum FollowUpStatusChangePreviousStatusEnum {
    Outstanding = 'outstanding',
    Completed = 'completed',
    Deleted = 'deleted',
    NotDoing = 'not_doing'
}

export function FollowUpStatusChangeFromJSON(json: any): FollowUpStatusChange {
    return FollowUpStatusChangeFromJSONTyped(json, false);
}

export function FollowUpStatusChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpStatusChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_status': json['next_status'],
        'previous_status': json['previous_status'],
    };
}

export function FollowUpStatusChangeToJSON(value?: FollowUpStatusChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_status': value.next_status,
        'previous_status': value.previous_status,
    };
}

