import { StepConfig } from "@incident-ui/Steps/Steps";

export type TeamWizardStepID =
  | "choose-source-of-truth"
  | "choose-teams"
  | "add-attributes";

export const TeamWizardStepConfig: StepConfig<TeamWizardStepID>[] = [
  {
    id: "choose-source-of-truth",
    name: "Connect your source of truth",
  },
  {
    id: "choose-teams",
    name: "Select your teams",
  },
  {
    id: "add-attributes",
    name: "Configure your setup",
  },
];
