/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoCloseIncidentsSettings,
    AutoCloseIncidentsSettingsFromJSON,
    AutoCloseIncidentsSettingsFromJSONTyped,
    AutoCloseIncidentsSettingsToJSON,
} from './AutoCloseIncidentsSettings';

/**
 * 
 * @export
 * @interface SettingsUpdateAutoCloseIncidentsRequestBody
 */
export interface SettingsUpdateAutoCloseIncidentsRequestBody {
    /**
     * 
     * @type {AutoCloseIncidentsSettings}
     * @memberof SettingsUpdateAutoCloseIncidentsRequestBody
     */
    auto_close_incidents?: AutoCloseIncidentsSettings;
}

export function SettingsUpdateAutoCloseIncidentsRequestBodyFromJSON(json: any): SettingsUpdateAutoCloseIncidentsRequestBody {
    return SettingsUpdateAutoCloseIncidentsRequestBodyFromJSONTyped(json, false);
}

export function SettingsUpdateAutoCloseIncidentsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdateAutoCloseIncidentsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_close_incidents': !exists(json, 'auto_close_incidents') ? undefined : AutoCloseIncidentsSettingsFromJSON(json['auto_close_incidents']),
    };
}

export function SettingsUpdateAutoCloseIncidentsRequestBodyToJSON(value?: SettingsUpdateAutoCloseIncidentsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_close_incidents': AutoCloseIncidentsSettingsToJSON(value.auto_close_incidents),
    };
}

