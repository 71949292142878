import {
  StatusPageContentIncidentLinkStatusEnum,
  StatusPageContentIncidentUpdateToStatusEnum,
} from "@incident-io/api";
import cx from "classnames";

type IncidentStatusIconProps = {
  className?: string;
  flavour?: "ok" | "alarmalade";
  status?:
    | StatusPageContentIncidentUpdateToStatusEnum
    | StatusPageContentIncidentLinkStatusEnum
    | "ok";
};

// Contextual icon depending on the state:
// - ok: green glowy dot
// - alarmalade: alarmalade glowy dot
export const IncidentStatusIcon = ({
  flavour,
  status,
  className,
}: IncidentStatusIconProps): React.ReactElement => {
  if (status) {
    switch (status) {
      case "ok":
      case StatusPageContentIncidentUpdateToStatusEnum.Resolved:
      case StatusPageContentIncidentLinkStatusEnum.Resolved:
      case StatusPageContentIncidentUpdateToStatusEnum.MaintenanceComplete:
      case StatusPageContentIncidentLinkStatusEnum.MaintenanceComplete:
        flavour = "ok";
        break;
      default:
        flavour = "alarmalade";
        break;
    }
  }

  if (flavour === "ok") {
    return (
      <svg
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g filter="url(#filter0_d_856_12719)">
          <circle cx="8.5" cy="8" r="3" fill="#3DB65E" />
        </g>
        <defs>
          <filter
            id="filter0_d_856_12719"
            x="0.5"
            y="0"
            width="16"
            height="16"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.268767 0 0 0 0 0.570833 0 0 0 0 0.28085 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_856_12719"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_856_12719"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  }
  if (flavour === "alarmalade") {
    return (
      <svg
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx("w-6 h-6 -mb-2", className)}
      >
        <g filter="url(#filter0_d_1_2)">
          <circle cx="8.5" cy="8" r="3" fill="#F25533" />
        </g>
        <defs>
          <filter
            id="filter0_d_1_2"
            x="0.5"
            y="0"
            width="16"
            height="16"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.94902 0 0 0 0 0.333333 0 0 0 0 0.2 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1_2"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1_2"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  }

  throw new Error("unreachable");
};
