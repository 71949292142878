import { SelectOption } from "@incident-ui/Select/types";
import React, { useEffect, useRef, useState } from "react";
import { GroupBase } from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { useWindowSize } from "use-hooks";

type MenuPlacement = "top" | "bottom" | "auto";

// This is a hack to work around an issue where react-select 'auto' is showing a menu
// at the bottom, even when there's not enough room for it.
// I suspect it's happening when we display the select in modals mainly, as it's then a portal within a portal.
// I've reproduced this on react-select's own example by forcing the modal to be big.
// We can remove this if we think it's fixed in future versions of react-select.
// https://www.loom.com/share/47f4e67c23c44979a0263ac14e47f2e7
export function useAutoAdjustingMenuPlacement(): [
  MenuPlacement,
  React.MutableRefObject<unknown>,
] {
  const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>("auto");
  const { height: windowHeight } = useWindowSize();
  const internalRef =
    useRef<Select<SelectOption, false, GroupBase<SelectOption>>>(null);

  useEffect(() => {
    if (internalRef.current && internalRef.current.controlRef) {
      const absolutePosition =
        internalRef.current.controlRef.getBoundingClientRect();
      if (absolutePosition.bottom > windowHeight - 300) {
        setMenuPlacement("top");
        return;
      }
    }
    setMenuPlacement("auto");
  }, [internalRef, setMenuPlacement, windowHeight]);

  return [menuPlacement, internalRef];
}
