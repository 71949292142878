/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentCallsCreateRequestBody
 */
export interface IncidentCallsCreateRequestBody {
    /**
     * External provider for the call
     * @type {string}
     * @memberof IncidentCallsCreateRequestBody
     */
    external_provider: IncidentCallsCreateRequestBodyExternalProviderEnum;
    /**
     * ID of the incident to create a call for
     * @type {string}
     * @memberof IncidentCallsCreateRequestBody
     */
    incident_id: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentCallsCreateRequestBodyExternalProviderEnum {
    Zoom = 'zoom',
    GoogleMeet = 'google_meet',
    Other = 'other'
}

export function IncidentCallsCreateRequestBodyFromJSON(json: any): IncidentCallsCreateRequestBody {
    return IncidentCallsCreateRequestBodyFromJSONTyped(json, false);
}

export function IncidentCallsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_provider': json['external_provider'],
        'incident_id': json['incident_id'],
    };
}

export function IncidentCallsCreateRequestBodyToJSON(value?: IncidentCallsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_provider': value.external_provider,
        'incident_id': value.incident_id,
    };
}

