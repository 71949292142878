import { tcx } from "src/utils/tailwind-classes";

export const LoadingBar = ({ className }: { className?: string }) => (
  <div
    className={tcx(
      "animate-pulse bg-surface-secondary rounded-[6px] h-[32px] w-full",
      className,
    )}
  >
    &nbsp;
  </div>
);
