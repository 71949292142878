import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";
import { AttachmentContent } from "./AttachmentContent";

export const ActivityItemPagerdutyIncidentResolved = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.pagerduty_incident_resolved) {
    throw new Error(
      "malformed timeline item: pagerduty_incident_resolved was missing pagerduty_incident_resolved field",
    );
  }

  return {
    title:
      item.content.pagerduty_incident_resolved.external_resource
        .resource_type_label + " resolved",
    icon: IconEnum.Checkmark,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.pagerduty_incident_resolved?.resolver,
    quotedContent: (
      <AttachmentContent
        resource={item.content.pagerduty_incident_resolved.external_resource}
      />
    ),
  };
};
