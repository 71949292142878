import { AIAssistantThreadMessage } from "@incident-io/api";
import { Avatar, IconSize } from "@incident-ui";
import React, { useEffect, useRef, useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { AssistantAvatar, AssistantAvatarBackground } from "./AssistantAvatar";
import { FeedbackButtons } from "./AssistantFeedbackButtons";
import styles from "./AssistantQuery.module.scss";

export interface Query {
  question: AIAssistantThreadMessage | null;
  responses: AIAssistantThreadMessage[];
}

// AssistantQuery is a block that includes the question from the user, and any responses from the
// Assistant, including charts and feedback buttons.
export const AssistantQuery = ({
  value,
  isLast,
  scrollToEnd,
  setLastRef,
}: {
  value: Query;
  isLast: boolean;
  scrollToEnd: () => void;
  setLastRef: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLDivElement>>
  >;
}) => {
  return (
    <div className="group text-sm last:mb-0 mb-4">
      {value.question && <Question value={value.question} />}
      <ResponseGroup
        questionId={value.question?.id}
        isLast={isLast}
        responses={value.responses}
        scrollToEnd={scrollToEnd}
        setLastRef={setLastRef}
      ></ResponseGroup>
    </div>
  );
};

const Question: React.FC<{
  value: AIAssistantThreadMessage | null;
}> = ({ value }) => {
  const { identity } = useIdentity();

  if (!value) {
    return null;
  }

  return (
    <div className="flex gap-4 mb-4 w-full">
      <div className="text-slate-600 mb-2 bg-surface-secondary p-4 rounded-xl max-w-xl w-full ml-auto">
        {value.content.map((c, index) => (
          <div
            key={index}
            dangerouslySetInnerHTML={{
              __html: c,
            }}
          />
        ))}
      </div>
      <div className="flex-none">
        <Avatar
          size={IconSize.Large}
          className="!w-8 !h-8 !bg-surface-tertiary text-content-invert z-10"
          name={identity?.user_name}
          url={identity?.user_avatar_url}
        />
      </div>
    </div>
  );
};

const ResponseGroup = ({
  responses,
  questionId,
  isLast,
  scrollToEnd,
  setLastRef,
}: {
  responses: AIAssistantThreadMessage[];
  questionId?: string;
  isLast: boolean;
  scrollToEnd: () => void;
  setLastRef: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLDivElement>>
  >;
}) => {
  // Only last response group should render if there are no responses
  // since it can be busy pooling for new messages.
  if (!isLast && responses.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-4 mb-4 w-full">
      <div className="flex-none">
        <AssistantAvatar
          size={IconSize.XS}
          background={AssistantAvatarBackground.Purple}
        />
      </div>
      <div className="flex-grow">
        {responses.length === 0 ? (
          <LoadingMessage mode="loading" />
        ) : (
          Object.values(responses).map((response, index) => (
            <Response
              key={index}
              value={response}
              scrollToEnd={scrollToEnd}
              setLastRef={setLastRef}
              isLast={isLast}
              questionId={questionId}
            />
          ))
        )}
        {responses.length > 0 && questionId && isLast && (
          <FeedbackButtons questionId={questionId} isLast={isLast} />
        )}
      </div>
    </div>
  );
};

const Response: React.FC<{
  value: AIAssistantThreadMessage;
  scrollToEnd: () => void;
  setLastRef: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLDivElement>>
  >;
  isLast: boolean;
  questionId?: string;
}> = ({ value, scrollToEnd, setLastRef, isLast, questionId }) => {
  const [content, setContent] = useState<string[]>([]);
  const [isTextDisplayed, setTextDisplayed] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  if (isLast) {
    // This means we can detect whether or not we're at the bottom of the thread.
    setLastRef(ref);
  }

  // Stream the text of the response.
  useEffect(() => {
    const streamingList: NodeJS.Timeout[] = [];

    const initiateStreaming = (c: string, i: number) => {
      // skip streaming if the content is of type "error_message"
      if (value.content_type === "error_message") {
        const newContent = [...content];
        newContent[i] = c;
        setContent(newContent);
        return;
      }

      const words = c.split(" ");
      let buffer = "";
      const streaming = setInterval(() => {
        if (words.length > 0) {
          buffer += " " + words.shift();
          const newContent = [...content];
          newContent[i] = buffer;
          setContent(newContent);
          scrollToEnd();
        } else {
          clearInterval(streaming);
          setTextDisplayed(true);
        }
      }, 100);
      streamingList.push(streaming);
    };

    if (!isTextDisplayed) {
      for (let i = 0; i < value.content.length; i++) {
        initiateStreaming(value.content[i], i);
      }
    }

    return () => {
      streamingList.forEach((streaming) => clearInterval(streaming));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTextDisplayed, value.id]);
  // You'd think we should listen on changes to value.content as well
  // but it seems that properties triggers a change without the value
  // actually changing

  return (
    <div ref={ref}>
      {/* Text */}
      {(value.content_type === "text_message" ||
        value.content_type === "error_message") &&
        value.content.length > 0 && (
          <TextBubble
            text={content}
            isLast={isLast}
            questionId={questionId}
            isError={value.content_type === "error_message"}
          />
        )}

      {/* Code message */}
      {value.content_type === "code_message" && (
        <LoadingMessage mode="runningCode" />
      )}

      {/* Images */}
      {value.image_presigned_urls &&
        value.image_presigned_urls.map((url, index) => (
          <Chart key={index} url={url} />
        ))}
    </div>
  );
};

const TextBubble = ({
  text,
  isLast,
  isError,
  questionId,
}: {
  text: string[];
  isLast: boolean;
  isError: boolean;
  questionId?: string;
}) => {
  return (
    <div className="flex flex-row gap-2">
      <Bubble className={isError ? "bg-red-200" : ""}>
        {text.map((c, index) => (
          <div
            key={index}
            className={styles.query}
            dangerouslySetInnerHTML={{
              __html: c,
            }}
          />
        ))}
      </Bubble>
      {!isLast && questionId && (
        <FeedbackButtons questionId={questionId} isLast={isLast} />
      )}
    </div>
  );
};

const Chart: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div className="rounded-xl border border-stroke p-4 mb-2">
      <img src={url} className="rounded-2xl w-full" />
    </div>
  );
};

const Bubble: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={tcx(
        "rounded-xl bg-surface-tertiary text-content-primary p-4 mb-2 line-height[1.5em] max-w-[47rem]",
        className,
      )}
    >
      {children}
    </div>
  );
};

const LoadingMessage: React.FC<{
  mode: "loading" | "runningCode";
}> = ({ mode }) => {
  // We'll cycle through these messages, based on the mode.
  const messageOptionsMapping = {
    loading: [
      "Thinking",
      "Working on it",
      "Just a moment",
      "Hang tight",
      "Almost there",
      "Still working on it",
      "Just a few more seconds",
      "Almost done",
    ],
    runningCode: [
      "Evaluating code",
      "Checking sources",
      "Running analysis",
      "Processing data",
      "Fetching results",
      "Crunching numbers",
      "Herding cats",
    ],
  };

  const [ellipsisStage, setEllipsisStage] = useState(1);
  const [messageStage, setMessageStage] = useState(0);

  useEffect(() => {
    const ellipsisInterval = setInterval(() => {
      setEllipsisStage((prevStage) => {
        return (prevStage % 3) + 1;
      });
    }, 1000); // We want to do a full cycle of ellipsis every 3 seconds because this is the interval for the pulse animation.

    const messageInterval = setInterval(() => {
      setMessageStage((prevStage) => {
        return prevStage + 1;
      });
    }, 6000); // This means that we do two complete ellipsis cycles before changing the message.

    return () => {
      clearInterval(ellipsisInterval);
      clearInterval(messageInterval);
    };
  }, []);

  // Will dynamically generate '.', '..' or '...' based on the stage.
  const ellipsis = ".".repeat(ellipsisStage);

  // Choose a message.
  const messageOptions = messageOptionsMapping[mode];
  const message = messageOptions[messageStage % messageOptions.length];

  return (
    <Bubble className={styles.animatePulse}>
      {message}
      {ellipsis}
    </Bubble>
  );
};
