import { Modal } from "@incident-io/status-page-ui";
import { useState } from "react";

export const UnsubscribeModal = ({
  onClose,
  unsubscribe,
}: {
  onClose: () => void;
  unsubscribe: () => Promise<unknown>;
}): React.ReactElement => {
  const [unsubscribing, setUnsubscribing] = useState(false);
  const handleUnsubscribe = async () => {
    setUnsubscribing(true);
    await unsubscribe();
    setUnsubscribing(false);
    onClose();
  };

  return (
    <Modal
      as="form"
      isOpen
      title={"Unsubscribe"}
      onClose={onClose}
      analyticsTrackingId="internal-status-page-unsubscribe-modal"
      submitButtonText="Unsubscribe"
      onSubmit={handleUnsubscribe}
      loading={unsubscribing}
    >
      <div className="space-y-2">
        <p className="text-sm">
          Are you sure you would like to unsubscribe from new incidents on this
          status page?
        </p>
        <p className="text-sm">
          You will remain subscribed to existing incidents.
        </p>
      </div>
    </Modal>
  );
};
