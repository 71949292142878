/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageAffectedComponentPayload,
    StatusPageAffectedComponentPayloadFromJSON,
    StatusPageAffectedComponentPayloadFromJSONTyped,
    StatusPageAffectedComponentPayloadToJSON,
} from './StatusPageAffectedComponentPayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageIncidentUpdatePayload
 */
export interface StatusPageIncidentUpdatePayload {
    /**
     * The updated statuses of affected components
     * @type {Array<StatusPageAffectedComponentPayload>}
     * @memberof StatusPageIncidentUpdatePayload
     */
    component_statuses: Array<StatusPageAffectedComponentPayload>;
    /**
     * The ID of the template that was applied when sending this update
     * @type {string}
     * @memberof StatusPageIncidentUpdatePayload
     */
    created_from_template_id?: string;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageIncidentUpdatePayload
     */
    message: TextNode;
    /**
     * When this incident update was published to the status page
     * @type {Date}
     * @memberof StatusPageIncidentUpdatePayload
     */
    published_at: Date;
    /**
     * Set to true if the update's timestamp has been edited
     * @type {boolean}
     * @memberof StatusPageIncidentUpdatePayload
     */
    timestamp_updated?: boolean;
    /**
     * The new status of this incident
     * @type {string}
     * @memberof StatusPageIncidentUpdatePayload
     */
    to_status: StatusPageIncidentUpdatePayloadToStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageIncidentUpdatePayloadToStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function StatusPageIncidentUpdatePayloadFromJSON(json: any): StatusPageIncidentUpdatePayload {
    return StatusPageIncidentUpdatePayloadFromJSONTyped(json, false);
}

export function StatusPageIncidentUpdatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageIncidentUpdatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_statuses': ((json['component_statuses'] as Array<any>).map(StatusPageAffectedComponentPayloadFromJSON)),
        'created_from_template_id': !exists(json, 'created_from_template_id') ? undefined : json['created_from_template_id'],
        'message': TextNodeFromJSON(json['message']),
        'published_at': (new Date(json['published_at'])),
        'timestamp_updated': !exists(json, 'timestamp_updated') ? undefined : json['timestamp_updated'],
        'to_status': json['to_status'],
    };
}

export function StatusPageIncidentUpdatePayloadToJSON(value?: StatusPageIncidentUpdatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_statuses': ((value.component_statuses as Array<any>).map(StatusPageAffectedComponentPayloadToJSON)),
        'created_from_template_id': value.created_from_template_id,
        'message': TextNodeToJSON(value.message),
        'published_at': (value.published_at.toISOString()),
        'timestamp_updated': value.timestamp_updated,
        'to_status': value.to_status,
    };
}

