import { PoliciesDismissViolationRequestBody } from "@incident-io/api";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ButtonTheme, ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

export const DismissViolationConfirmationModal = ({
  violationId,
  onClose,
}: {
  violationId: string;
  onClose: () => void;
}) => {
  const formMethods = useForm<PoliciesDismissViolationRequestBody>();

  // This makes sure that we automatically re-render the dismissed violations after dismissing a violation.
  const revalidate = useRevalidate([
    "policiesListViolations",
    "policiesListViolationsForUser",
  ]);

  const { trigger, isMutating, genericError } = useAPIMutation(
    "policiesListViolations",
    {},
    async (apiClient) => {
      await apiClient.policiesDismissViolation({
        id: violationId,
        dismissViolationRequestBody: {
          dismissed_reason: formMethods.getValues().dismissed_reason,
        },
      });
    },
    {
      onSuccess: () => {
        revalidate();
        onClose();
      },
    },
  );

  return (
    <Form.Modal
      title="Dismiss policy violation"
      genericError={genericError}
      formMethods={formMethods}
      onSubmit={(data) => {
        trigger(data);
        onClose();
      }}
      onClose={onClose}
      className="text-sm"
      analyticsTrackingId={null}
      footer={
        <ModalFooter
          confirmButtonText="Dismiss"
          confirmButtonType="submit"
          confirmButtonTheme={ButtonTheme.Destroy}
          cancelButtonText="Cancel"
          onClose={onClose}
          saving={isMutating}
        />
      }
    >
      <p className="text-slate-700">
        Dismissing a policy violation will remove it from the list of active
        violations. However, you can still view it in the list of dismissed
        violations, where you have the option to restore it if needed.
      </p>
      <TextareaV2
        formMethods={formMethods}
        placeholder={
          "Please provide a reason for dismissing this policy violation…"
        }
        name={"dismissed_reason"}
        required={"Please specify a reason"}
        rows={4}
        autoFocus
      />
    </Form.Modal>
  );
};
