import type { SVGProps } from "react";
import * as React from "react";
const SvgNumeric = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 8H4.75a.75.75 0 0 1 0-1.5h11.5a.75.75 0 0 1 0 1.5ZM15.25 13.5H3.75a.75.75 0 0 1 0-1.5h11.5a.75.75 0 0 1 0 1.5Z"
    />
    <path
      fill="currentColor"
      d="M6.29 17a.751.751 0 0 1-.739-.888l2.345-12.5a.751.751 0 0 1 1.475.276l-2.344 12.5A.752.752 0 0 1 6.29 17ZM11.368 17a.751.751 0 0 1-.739-.888l2.345-12.5a.751.751 0 0 1 1.475.276l-2.344 12.5a.75.75 0 0 1-.736.612h-.001Z"
    />
  </svg>
);
export default SvgNumeric;
