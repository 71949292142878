/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentSubPagesParentSummary
 */
export interface StatusPageContentSubPagesParentSummary {
    /**
     * The ID of the status page
     * @type {string}
     * @memberof StatusPageContentSubPagesParentSummary
     */
    id: string;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPageContentSubPagesParentSummary
     */
    name: string;
    /**
     * The primary URL where this status page can be accessed.
     * @type {string}
     * @memberof StatusPageContentSubPagesParentSummary
     */
    public_url: string;
}

export function StatusPageContentSubPagesParentSummaryFromJSON(json: any): StatusPageContentSubPagesParentSummary {
    return StatusPageContentSubPagesParentSummaryFromJSONTyped(json, false);
}

export function StatusPageContentSubPagesParentSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentSubPagesParentSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'public_url': json['public_url'],
    };
}

export function StatusPageContentSubPagesParentSummaryToJSON(value?: StatusPageContentSubPagesParentSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'public_url': value.public_url,
    };
}

