/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormLifecycleElementBinding,
    FormLifecycleElementBindingFromJSON,
    FormLifecycleElementBindingFromJSONTyped,
    FormLifecycleElementBindingToJSON,
} from './FormLifecycleElementBinding';
import {
    IncidentFormLifecycleElement,
    IncidentFormLifecycleElementFromJSON,
    IncidentFormLifecycleElementFromJSONTyped,
    IncidentFormLifecycleElementToJSON,
} from './IncidentFormLifecycleElement';

/**
 * 
 * @export
 * @interface IncidentFormLifecycleElementBinding
 */
export interface IncidentFormLifecycleElementBinding {
    /**
     * 
     * @type {FormLifecycleElementBinding}
     * @memberof IncidentFormLifecycleElementBinding
     */
    default_value?: FormLifecycleElementBinding;
    /**
     * 
     * @type {IncidentFormLifecycleElement}
     * @memberof IncidentFormLifecycleElementBinding
     */
    element: IncidentFormLifecycleElement;
    /**
     * Whether this field is required
     * @type {boolean}
     * @memberof IncidentFormLifecycleElementBinding
     */
    required: boolean;
}

export function IncidentFormLifecycleElementBindingFromJSON(json: any): IncidentFormLifecycleElementBinding {
    return IncidentFormLifecycleElementBindingFromJSONTyped(json, false);
}

export function IncidentFormLifecycleElementBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormLifecycleElementBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_value': !exists(json, 'default_value') ? undefined : FormLifecycleElementBindingFromJSON(json['default_value']),
        'element': IncidentFormLifecycleElementFromJSON(json['element']),
        'required': json['required'],
    };
}

export function IncidentFormLifecycleElementBindingToJSON(value?: IncidentFormLifecycleElementBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_value': FormLifecycleElementBindingToJSON(value.default_value),
        'element': IncidentFormLifecycleElementToJSON(value.element),
        'required': value.required,
    };
}

