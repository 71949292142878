import {
  CatalogTypeCategoriesEnum,
  IntegrationSettings,
  TeamSourceOfTruthResource,
} from "@incident-io/api";
import {
  drawerUrlFor,
  IntegrationConfigFor,
  IntegrationListProvider,
  SCIM_PROVIDER_CONFIGS,
  SCIMProviderEnum,
} from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  Loader,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { Navigate } from "react-router";
import { useScimAndSamlProviders } from "src/components/settings/integrations/list/scim-and-saml/SCIMAndSamlIntegrationDrawer";

import {
  IdentityContextType,
  useIdentity,
} from "../../../../contexts/IdentityContext";
import { useIntegrations } from "../../../../hooks/useIntegrations";
import { useAPI } from "../../../../utils/swr";
import { CatalogWizardIntegrationDrawerRoutes } from "../CatalogWizardIntegrationDrawerRoutes";
import { useHasTypeOfCategory } from "../useHasTypeOfCategory";
import { WizardLayout } from "../WizardLayout";

type SourceOfTruth = {
  resource_type_label: string;
  resource_type: string;
  integration_provider: IntegrationListProvider;
  integration_installed: boolean;
  disabled?: boolean;
};

export const TeamWizardChooseSourceOfTruthStep = () => {
  const identity = useIdentity();

  const { integrations } = useIntegrations();
  const { data: resourcesResponse, error: resourcesError } = useAPI(
    "catalogListTeamSourceOfTruthResources",
    undefined,
  );

  const { hasMatchingType, matchingType, typesLoading, typesError } =
    useHasTypeOfCategory(CatalogTypeCategoriesEnum.Team);

  const {
    loading: scimSamlLoading,
    error: scimSamlError,
    teamsState,
    scimProvider: installedScimProvider,
  } = useScimAndSamlProviders();

  const error = resourcesError || scimSamlError || typesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const dataReady: boolean =
    resourcesResponse?.resources !== undefined &&
    integrations != null &&
    !scimSamlLoading &&
    !typesLoading;

  let resources: SourceOfTruth[] = [];

  if (dataReady) {
    resources = buildSourceOfTruthList(
      resourcesResponse?.resources || [],
      identity,
      integrations,
      installedScimProvider,
      teamsState,
    );
  }

  if (hasMatchingType && matchingType) {
    return (
      <Navigate to={`/catalog/team-wizard/${matchingType.id}/add-attributes`} />
    );
  }

  return (
    <WizardLayout
      category={CatalogTypeCategoriesEnum.Team}
      title="Where do your teams live?"
      subtitle="Catalog lets you connect and manage everything that exists in your organization — from customers, to teams, software services, payment providers, and everything in between."
      stepID="choose-source-of-truth"
      footer={
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Secondary}
          loading={false}
          disabled={false}
          href="/catalog"
        >
          Exit
        </Button>
      }
    >
      <CatalogWizardIntegrationDrawerRoutes
        backHref={`/catalog/team-wizard/choose-source-of-truth`}
      />
      {dataReady ? (
        <div className="flex flex-col gap-6">
          <StackedList>
            {resources.map((r) => {
              const integrationConfig = IntegrationConfigFor(
                r.integration_provider,
              );
              const chooseTeamsPath = `/catalog/team-wizard/choose-teams?resource_type=${r.resource_type}`;
              return (
                <StackedListItem
                  key={r.resource_type_label}
                  title={r.resource_type_label}
                  iconNode={
                    <IconBadge
                      icon={integrationConfig.icon}
                      color={ColorPaletteEnum.Slate}
                      size={IconSize.Medium}
                    />
                  }
                  rowHref={
                    r.disabled
                      ? undefined
                      : r.integration_installed
                      ? chooseTeamsPath
                      : `integrate/${drawerUrlFor(
                          r.integration_provider,
                        )}?return=${chooseTeamsPath}`
                  }
                  accessory={<ChevronRight />}
                  badgeNode={
                    r.integration_installed ? (
                      <Badge theme={BadgeTheme.Success} className="h-5 px-1">
                        Installed
                      </Badge>
                    ) : undefined
                  }
                  className={r.disabled ? "" : "cursor-pointer"}
                  disabled={r.disabled}
                />
              );
            })}
          </StackedList>
          <StackedList>
            <StackedListItem
              title="Create teams manually"
              iconNode={
                <IconBadge
                  icon={IconEnum.Users}
                  color={ColorPaletteEnum.Slate}
                  size={IconSize.Medium}
                />
              }
              rowHref="/catalog/team-wizard/manual-teams"
              accessory={<ChevronRight />}
              className={"cursor-pointer"}
            />
          </StackedList>
        </div>
      ) : (
        <Loader />
      )}
    </WizardLayout>
  );
};

const ChevronRight = () => {
  return (
    <Icon
      className={"text-gray-400"}
      id={IconEnum.ChevronRight}
      size={IconSize.Medium}
    />
  );
};

function buildSourceOfTruthList(
  resources: Array<TeamSourceOfTruthResource>,
  identity: IdentityContextType,
  integrations: IntegrationSettings[] | null,
  installedScimProvider: SCIMProviderEnum | undefined,
  teamsState: string,
) {
  const nonSCIMResources: SourceOfTruth[] =
    resources
      .filter(
        (r) =>
          // Filter out Slack-based resources for MS Teams org
          !(
            identity.identity.ms_teams_info !== undefined &&
            r.required_integration === "slack"
          ),
      )
      .filter(
        // Filter out SCIM as we'll add each provider separately
        (r) => r.required_integration !== "scim",
      )
      .sort((a, b): number =>
        a.resource_type_label > b.resource_type_label ? 1 : -1,
      )
      .map((r) => {
        const integrationName = r.required_integration;
        const integration = integrations?.find(
          (i) => i.provider === integrationName,
        );
        const integrationProvider = integration?.provider;
        if (!integrationProvider) {
          throw new Error(
            `No provider found for integrationName ${integrationName}`,
          );
        }
        return {
          resource_type_label: r.resource_type_label,
          resource_type: r.resource_type,
          integration_provider: integrationProvider,
          integration_installed: integration.installed,
        };
      }) || [];

  const aProviderIsInstalled = Object.values(SCIMProviderEnum).some(
    (scimProvider) =>
      installedScimProvider === scimProvider && teamsState === "connected",
  );

  const scimResources = Object.values(SCIMProviderEnum).map((scimProvider) => {
    const installed =
      installedScimProvider === scimProvider && teamsState === "connected";
    return {
      resource_type_label: SCIM_PROVIDER_CONFIGS[scimProvider].teamName,
      resource_type: "SCIMGroup",
      integration_provider: scimProvider,
      integration_installed: installed,
      disabled: aProviderIsInstalled && !installed,
    };
  });

  return [...nonSCIMResources, ...scimResources].sort((a, b): number => {
    // Show integrated resources first
    if (a.integration_installed && !b.integration_installed) {
      return -1; // a first
    }
    if (b.integration_installed && !a.integration_installed) {
      return 1;
    }
    return 0;
  });
}
