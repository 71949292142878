/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleReplica
 */
export interface ScheduleReplica {
    /**
     * 
     * @type {Date}
     * @memberof ScheduleReplica
     */
    created_at: Date;
    /**
     * Unique internal ID of the schedule replica
     * @type {string}
     * @memberof ScheduleReplica
     */
    id: string;
    /**
     * Source schedule rotation layer
     * @type {string}
     * @memberof ScheduleReplica
     */
    layer_id: string;
    /**
     * The user that will be used in the replica system whenever NOBODY is scheduled
     * @type {string}
     * @memberof ScheduleReplica
     */
    replica_fallback_user_id: string;
    /**
     * Name of replica external provider, e.g. pagerduty
     * @type {string}
     * @memberof ScheduleReplica
     */
    replica_provider: ScheduleReplicaReplicaProviderEnum;
    /**
     * ID of replica schedule in external system
     * @type {string}
     * @memberof ScheduleReplica
     */
    replica_provider_id: string;
    /**
     * Source schedule rotation
     * @type {string}
     * @memberof ScheduleReplica
     */
    rotation_id: string;
    /**
     * Source schedule
     * @type {string}
     * @memberof ScheduleReplica
     */
    schedule_id: string;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleReplica
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleReplicaReplicaProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}

export function ScheduleReplicaFromJSON(json: any): ScheduleReplica {
    return ScheduleReplicaFromJSONTyped(json, false);
}

export function ScheduleReplicaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReplica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'layer_id': json['layer_id'],
        'replica_fallback_user_id': json['replica_fallback_user_id'],
        'replica_provider': json['replica_provider'],
        'replica_provider_id': json['replica_provider_id'],
        'rotation_id': json['rotation_id'],
        'schedule_id': json['schedule_id'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function ScheduleReplicaToJSON(value?: ScheduleReplica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'layer_id': value.layer_id,
        'replica_fallback_user_id': value.replica_fallback_user_id,
        'replica_provider': value.replica_provider,
        'replica_provider_id': value.replica_provider_id,
        'rotation_id': value.rotation_id,
        'schedule_id': value.schedule_id,
        'updated_at': (value.updated_at.toISOString()),
    };
}

