import type { SVGProps } from "react";
import * as React from "react";
const SvgToggleRight = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7 4.5h6a.75.75 0 0 1 .75.75v.076A4.754 4.754 0 0 0 13 5.25a4.75 4.75 0 0 0 0 9.5c.257 0 .504-.036.75-.076v.076a.75.75 0 0 1-.75.75H7A5.506 5.506 0 0 1 1.5 10c0-3.033 2.467-5.5 5.5-5.5Z"
    />
    <path
      fill="currentColor"
      d="M13 15.5c3.033 0 5.5-2.467 5.5-5.5S16.033 4.5 13 4.5A5.506 5.506 0 0 0 7.5 10c0 3.033 2.467 5.5 5.5 5.5ZM13 6c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4Z"
    />
  </svg>
);
export default SvgToggleRight;
