/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageUpdateSummaryAPIRequestBody
 */
export interface StatusPageUpdateSummaryAPIRequestBody {
    /**
     * Whether this public page exposes a JSON endpoint with its current status
     * @type {boolean}
     * @memberof StatusPageUpdateSummaryAPIRequestBody
     */
    expose_status_summary_api: boolean;
}

export function StatusPageUpdateSummaryAPIRequestBodyFromJSON(json: any): StatusPageUpdateSummaryAPIRequestBody {
    return StatusPageUpdateSummaryAPIRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateSummaryAPIRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateSummaryAPIRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expose_status_summary_api': json['expose_status_summary_api'],
    };
}

export function StatusPageUpdateSummaryAPIRequestBodyToJSON(value?: StatusPageUpdateSummaryAPIRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expose_status_summary_api': value.expose_status_summary_api,
    };
}

