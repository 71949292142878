import { IncidentStatus, IncidentType, Severity } from "@incident-io/api";
import {
  BadgeSize,
  IconEnum,
  IncidentStatusBadge,
  SeverityBadge,
} from "@incident-ui";

import { Sidebar } from "./Components";

export const EditableSeverityBadge = ({
  severity,
  onEdit,
}: {
  severity?: Severity;
  onEdit?: () => void;
}) => {
  return (
    <Sidebar.ValueButton
      analyticsTrackingId="incident-sidebar-edit-severity"
      onClick={onEdit}
      disabled={!onEdit}
    >
      <SeverityBadge
        naked
        severity={severity}
        size={BadgeSize.Medium}
        className="text-content-primary flex cursor-pointer"
      />
    </Sidebar.ValueButton>
  );
};

export const EditableStatusBadge = ({
  status,
  onEdit,
}: {
  status: IncidentStatus;
  onEdit?: () => void;
}) => {
  return (
    <Sidebar.ValueButton
      analyticsTrackingId="incident-sidebar-edit-status"
      onClick={onEdit}
      disabled={!onEdit}
    >
      <IncidentStatusBadge
        status={status}
        size={BadgeSize.Medium}
        naked
        className="text-content-primary flex cursor-pointer"
      />
    </Sidebar.ValueButton>
  );
};

export const EditableIncidentTypesBadge = ({
  incidentType,
  onEdit,
}: {
  incidentType?: IncidentType;
  onEdit?: () => void;
}) => {
  let label = incidentType?.name;
  if (!incidentType) {
    label = "Add type";
  }

  return (
    <Sidebar.ValueButton
      icon={incidentType ? IconEnum.IncidentType : IconEnum.AddIncidentType}
      onClick={onEdit}
      disabled={!onEdit}
      analyticsTrackingId="incident-sidebar-edit-type"
    >
      {label}
    </Sidebar.ValueButton>
  );
};
