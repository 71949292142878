/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SavedViewsCreateRequestBody,
    SavedViewsCreateRequestBodyFromJSON,
    SavedViewsCreateRequestBodyToJSON,
    SavedViewsCreateResponseBody,
    SavedViewsCreateResponseBodyFromJSON,
    SavedViewsCreateResponseBodyToJSON,
    SavedViewsListResponseBody,
    SavedViewsListResponseBodyFromJSON,
    SavedViewsListResponseBodyToJSON,
    SavedViewsUpdateRequestBody,
    SavedViewsUpdateRequestBodyFromJSON,
    SavedViewsUpdateRequestBodyToJSON,
    SavedViewsUpdateResponseBody,
    SavedViewsUpdateResponseBodyFromJSON,
    SavedViewsUpdateResponseBodyToJSON,
} from '../models';

export interface SavedViewsCreateRequest {
    createRequestBody: SavedViewsCreateRequestBody;
}

export interface SavedViewsDestroyRequest {
    id: string;
}

export interface SavedViewsListRequest {
    context: SavedViewsListContextEnum;
}

export interface SavedViewsUpdateRequest {
    id: string;
    updateRequestBody: SavedViewsUpdateRequestBody;
}

/**
 * 
 */
export class SavedViewsApi extends runtime.BaseAPI {

    /**
     * Create a new saved view
     * Create SavedViews
     */
    async savedViewsCreateRaw(requestParameters: SavedViewsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SavedViewsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling savedViewsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/saved_views`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SavedViewsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SavedViewsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new saved view
     * Create SavedViews
     */
    async savedViewsCreate(requestParameters: SavedViewsCreateRequest, initOverrides?: RequestInit): Promise<SavedViewsCreateResponseBody> {
        const response = await this.savedViewsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a saved view
     * Destroy SavedViews
     */
    async savedViewsDestroyRaw(requestParameters: SavedViewsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling savedViewsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/saved_views/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a saved view
     * Destroy SavedViews
     */
    async savedViewsDestroy(requestParameters: SavedViewsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.savedViewsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List SavedViews for context
     * List SavedViews
     */
    async savedViewsListRaw(requestParameters: SavedViewsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SavedViewsListResponseBody>> {
        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling savedViewsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/saved_views`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SavedViewsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List SavedViews for context
     * List SavedViews
     */
    async savedViewsList(requestParameters: SavedViewsListRequest, initOverrides?: RequestInit): Promise<SavedViewsListResponseBody> {
        const response = await this.savedViewsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a saved view
     * Update SavedViews
     */
    async savedViewsUpdateRaw(requestParameters: SavedViewsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SavedViewsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling savedViewsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling savedViewsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/saved_views/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SavedViewsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SavedViewsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates a saved view
     * Update SavedViews
     */
    async savedViewsUpdate(requestParameters: SavedViewsUpdateRequest, initOverrides?: RequestInit): Promise<SavedViewsUpdateResponseBody> {
        const response = await this.savedViewsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SavedViewsListContextEnum {
    FollowUps = 'follow_ups',
    Incidents = 'incidents',
    PostIncidentTasks = 'post_incident_tasks',
    InsightsV2 = 'insights.v2'
}
