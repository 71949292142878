import { Condition, EngineScope, Reference, Resource } from "@incident-io/api";
import React from "react";

import { EngineRefIsSelectable, MaybeRef, ReferenceSelectorPopover } from "..";
import { ConditionOperationSelect } from "./ConditionOperationSelect";
import { hasOperations } from "./EditableConditionGroupsList";

export type ConditionMenuEntry = Reference & {
  resource: Resource;
};

export const AddConditionPopover = ({
  renderTriggerButton,
  onAddCondition,
  selectedConditions,
  resources,
  allowExpressions,
  scope,
  isSelectable: isSelectableCallback,
}: {
  renderTriggerButton: (props: { onClick: () => void }) => React.ReactElement;
  resources: Resource[];
  scope: EngineScope;
  onAddCondition: (condition: Condition) => void;
  selectedConditions: Condition[];
  allowExpressions?: boolean;
  isSelectable?: EngineRefIsSelectable;
}) => {
  const selectedConditionKeys = selectedConditions.map(
    ({ subject }) => subject.reference,
  );

  const isSelectable: EngineRefIsSelectable = (entry: MaybeRef): boolean => {
    if (!hasOperations(entry)) {
      return false;
    }

    if (entry.hide_filter) {
      return false;
    }

    if (!!entry.key && selectedConditionKeys.includes(entry.key)) {
      return false;
    }

    if (isSelectableCallback !== undefined) {
      return isSelectableCallback(entry) != null;
    }

    return true;
  };

  return (
    <ReferenceSelectorPopover
      scope={scope}
      renderTriggerButton={renderTriggerButton}
      allowExpressions={allowExpressions}
      isSelectable={isSelectable}
      // Let people close the popover by clicking outside of it. This is so we don't have to show a
      // close button in the popover itself. Note that if you're in the expression modal, we'll stop you
      // from closing the modal by accident anyway!
      stopPreventClose={true}
      renderOnSelectedForm={({ selectedEntry, onClose }) => {
        const handleSubmit = (newCondition: Condition) => {
          onAddCondition(newCondition);
          onClose();
        };

        return (
          <ConditionOperationSelect
            handleSubmit={handleSubmit}
            resources={resources}
            scope={scope}
            selectedEntry={selectedEntry}
            allowExpressions={allowExpressions}
          />
        );
      }}
    />
  );
};
