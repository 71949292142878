/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIToggleFeatureRequestBody
 */
export interface AIToggleFeatureRequestBody {
    /**
     * Whether or not to enable this feature
     * @type {boolean}
     * @memberof AIToggleFeatureRequestBody
     */
    enabled: boolean;
    /**
     * Which feature to enable/disable
     * @type {string}
     * @memberof AIToggleFeatureRequestBody
     */
    feature: AIToggleFeatureRequestBodyFeatureEnum;
}

/**
* @export
* @enum {string}
*/
export enum AIToggleFeatureRequestBodyFeatureEnum {
    SummariseIncident = 'summarise_incident',
    IncidentSimilarity = 'incident_similarity',
    Assistant = 'assistant',
    SuggestFollowups = 'suggest_followups',
    CatalogSuggestions = 'catalog_suggestions',
    AlertAttributeSuggestions = 'alert_attribute_suggestions',
    PromptTester = 'prompt_tester',
    Copilot = 'copilot',
    Investigations = 'investigations',
    CallTranscription = 'call_transcription'
}

export function AIToggleFeatureRequestBodyFromJSON(json: any): AIToggleFeatureRequestBody {
    return AIToggleFeatureRequestBodyFromJSONTyped(json, false);
}

export function AIToggleFeatureRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIToggleFeatureRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'feature': json['feature'],
    };
}

export function AIToggleFeatureRequestBodyToJSON(value?: AIToggleFeatureRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'feature': value.feature,
    };
}

