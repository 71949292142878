import type { SVGProps } from "react";
import * as React from "react";
const SvgUser = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.586 9a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM9.586 10a7.002 7.002 0 0 0-6.395 4.167 2.237 2.237 0 0 0 .008 1.825 2.294 2.294 0 0 0 1.399 1.239 16.571 16.571 0 0 0 9.974 0 2.292 2.292 0 0 0 1.399-1.239c.262-.58.265-1.245.008-1.825A7.002 7.002 0 0 0 9.584 10h.002Z"
    />
  </svg>
);
export default SvgUser;
