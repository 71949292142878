/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchedulePayRulePayload,
    SchedulePayRulePayloadFromJSON,
    SchedulePayRulePayloadFromJSONTyped,
    SchedulePayRulePayloadToJSON,
} from './SchedulePayRulePayload';
import {
    ScheduleReportOverridePayload,
    ScheduleReportOverridePayloadFromJSON,
    ScheduleReportOverridePayloadFromJSONTyped,
    ScheduleReportOverridePayloadToJSON,
} from './ScheduleReportOverridePayload';

/**
 * 
 * @export
 * @interface SchedulesCreatePayConfigRequestBody
 */
export interface SchedulesCreatePayConfigRequestBody {
    /**
     * Base rate of pay per hour, in lowest denomination of specified currency
     * @type {number}
     * @memberof SchedulesCreatePayConfigRequestBody
     */
    base_rate_cents: number;
    /**
     * Currency used to send schedule, in ISO 4217 format
     * @type {string}
     * @memberof SchedulesCreatePayConfigRequestBody
     */
    currency: string;
    /**
     * Human readable name of the pay config
     * @type {string}
     * @memberof SchedulesCreatePayConfigRequestBody
     */
    name: string;
    /**
     * Overrides for the pay config
     * @type {Array<ScheduleReportOverridePayload>}
     * @memberof SchedulesCreatePayConfigRequestBody
     */
    overrides: Array<ScheduleReportOverridePayload>;
    /**
     * Configuration for pay rules
     * @type {Array<SchedulePayRulePayload>}
     * @memberof SchedulesCreatePayConfigRequestBody
     */
    pay_rules: Array<SchedulePayRulePayload>;
}

export function SchedulesCreatePayConfigRequestBodyFromJSON(json: any): SchedulesCreatePayConfigRequestBody {
    return SchedulesCreatePayConfigRequestBodyFromJSONTyped(json, false);
}

export function SchedulesCreatePayConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreatePayConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_rate_cents': json['base_rate_cents'],
        'currency': json['currency'],
        'name': json['name'],
        'overrides': ((json['overrides'] as Array<any>).map(ScheduleReportOverridePayloadFromJSON)),
        'pay_rules': ((json['pay_rules'] as Array<any>).map(SchedulePayRulePayloadFromJSON)),
    };
}

export function SchedulesCreatePayConfigRequestBodyToJSON(value?: SchedulesCreatePayConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_rate_cents': value.base_rate_cents,
        'currency': value.currency,
        'name': value.name,
        'overrides': ((value.overrides as Array<any>).map(ScheduleReportOverridePayloadToJSON)),
        'pay_rules': ((value.pay_rules as Array<any>).map(SchedulePayRulePayloadToJSON)),
    };
}

