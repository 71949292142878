import { ErrorResponse, Policy } from "@incident-io/api";
import { CreateEditFormProps } from "@incident-shared/forms/v2/formsv2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { useState } from "react";

import { PolicyCreateEditFormV2 } from "./PolicyCreateEditForm";
import { useGetPolicyCreateEditDeps } from "./useGetPolicyCreateEditDeps";

export type PolicyCreateEditSubDrawer =
  | "name_and_description"
  | "incidents"
  | "sla"
  | "responsible_user"
  | "policy_report_ids"
  | "new_report";

export const PolicyCreateEditDrawer = ({
  title,
  onClose,
  initialData,
  loading,
  error,
}: {
  title: string;
  onClose: () => void;
  initialData: CreateEditFormProps<Policy>;
  loading?: boolean;
  error?: ErrorResponse;
}) => {
  const [showDrawer, setShowDrawer] =
    useState<PolicyCreateEditSubDrawer | null>(null);

  const {
    isLoading: depsLoading,
    error: depsError,
    deps,
  } = useGetPolicyCreateEditDeps();

  if (error || depsError) {
    return (
      <Drawer onClose={() => onClose()} width={"full"}>
        <DrawerTitle
          icon={IconEnum.Shield}
          title={title}
          onClose={onClose}
          closeIcon={IconEnum.Close}
          sticky
          compact
          color={ColorPaletteEnum.Red}
        />
        <GenericErrorMessage description="Unable to fetch policy" />
      </Drawer>
    );
  }

  return (
    <Drawer
      onClose={() => onClose()}
      width={"full"}
      warnWhenDirty
      isInBackground={showDrawer != null}
    >
      {loading || depsLoading ? (
        <DrawerContentsLoading />
      ) : (
        <div className="flex flex-col h-full !overflow-hidden">
          <DrawerTitle
            icon={IconEnum.Shield}
            title={title}
            onClose={onClose}
            closeIcon={IconEnum.Close}
            sticky
            compact
            color={ColorPaletteEnum.Red}
            theme={DrawerTitleTheme.Bordered}
          />
          <DrawerContents>
            <PolicyCreateEditFormV2
              onClose={onClose}
              formProps={initialData}
              showDrawer={showDrawer}
              setShowDrawer={setShowDrawer}
              deps={deps}
            />
          </DrawerContents>
        </div>
      )}
    </Drawer>
  );
};
