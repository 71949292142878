import type { SVGProps } from "react";
import * as React from "react";
const SvgIndentationLeft = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 5.5h12.5a.75.75 0 0 0 0-1.5H3.75a.75.75 0 0 0 0 1.5Zm0 10.5h12.5a.75.75 0 0 0 0-1.5H3.75a.75.75 0 0 0 0 1.5Zm6.5-5.25h-6.5a.75.75 0 0 1 0-1.5h6.5a.75.75 0 0 1 0 1.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="m13.277 9.565 2.92-1.501a.574.574 0 0 1 .539.006c.165.09.264.25.264.429V11.5a.487.487 0 0 1-.264.428.574.574 0 0 1-.538.007l-2.92-1.501A.493.493 0 0 1 13 10c0-.18.106-.347.277-.435Z"
    />
  </svg>
);
export default SvgIndentationLeft;
