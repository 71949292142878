import type { SVGProps } from "react";
import * as React from "react";
const SvgTextInput = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 3h-8.5A2.752 2.752 0 0 0 3 5.75v8.5A2.752 2.752 0 0 0 5.75 17h8.5A2.752 2.752 0 0 0 17 14.25v-8.5A2.752 2.752 0 0 0 14.25 3Zm-2.5 10a.75.75 0 0 1 0 1.5 2.488 2.488 0 0 1-1.75-.719 2.488 2.488 0 0 1-1.75.719.75.75 0 0 1 0-1.5c.551 0 1-.449 1-1V8c0-.551-.449-1-1-1a.75.75 0 0 1 0-1.5c.681 0 1.299.275 1.75.719a2.488 2.488 0 0 1 1.75-.719.75.75 0 0 1 0 1.5c-.551 0-1 .449-1 1v4c0 .551.449 1 1 1Z"
    />
  </svg>
);
export default SvgTextInput;
