import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

export const CopiableElement = ({ content }: { content: string }) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <Tooltip content={hasCopied ? "Copied" : "Copy"}>
      <div
        className={
          "flex gap-2 truncate bg-surface-secondary items-center px-2 py-1 rounded-2 text-content-primary w-full"
        }
      >
        <span className="truncate grow">{content}</span>
        <Button
          theme={ButtonTheme.Unstyled}
          icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
          analyticsTrackingId="copy-text"
          type="button"
          title="Copy to clipboard"
          iconProps={{
            className: tcx(
              "text-content-tertiary group-hover:text-content-primary",
              {
                "!text-green-content": hasCopied,
              },
            ),
            size: IconSize.Medium,
          }}
          onClick={() => copyTextToClipboard(content)}
        />
      </div>
    </Tooltip>
  );
};
