/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimelineItemComment,
    TimelineItemCommentFromJSON,
    TimelineItemCommentFromJSONTyped,
    TimelineItemCommentToJSON,
} from './TimelineItemComment';

/**
 * 
 * @export
 * @interface TimelineItemCommentsCreateResponseBody
 */
export interface TimelineItemCommentsCreateResponseBody {
    /**
     * 
     * @type {TimelineItemComment}
     * @memberof TimelineItemCommentsCreateResponseBody
     */
    timeline_item_comment: TimelineItemComment;
}

export function TimelineItemCommentsCreateResponseBodyFromJSON(json: any): TimelineItemCommentsCreateResponseBody {
    return TimelineItemCommentsCreateResponseBodyFromJSONTyped(json, false);
}

export function TimelineItemCommentsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemCommentsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_item_comment': TimelineItemCommentFromJSON(json['timeline_item_comment']),
    };
}

export function TimelineItemCommentsCreateResponseBodyToJSON(value?: TimelineItemCommentsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_item_comment': TimelineItemCommentToJSON(value.timeline_item_comment),
    };
}

