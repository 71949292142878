import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum, SearchableDropdown } from "@incident-ui";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import React from "react";
import {
  IncidentTimestampsCreateSetByRuleRequestBody,
  IncidentTimestampsCreateSetByRuleRequestBodySetOnTransitionEnum as TransitionEnum,
  IncidentTimestampsCreateSetByRuleRequestBodySetOnVisitEnum as VisitEnum,
  IncidentTimestampSetByRulePayloadSetOnTransitionEnum as CreateTimestampTransitionEnum,
  IncidentTimestampSetByRulePayloadSetOnVisitEnum as CreateTimestampVisitEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { IncidentTimestampCreateModal } from "../timestamps-metrics/timestamp-create/IncidentTimestampCreateModal";
import { useLifecycleOverview } from "./LifecycleOverviewContext";

export type SetByRulePrefill = Omit<
  IncidentTimestampsCreateSetByRuleRequestBody,
  "incident_timestamp_id"
>;

export const TimestampSetByRuleAddPopover = ({
  rule,
  disabled,
}: {
  rule: SetByRulePrefill;
  disabled?: boolean;
}): React.ReactElement | null => {
  const [showAddTimestampModal, setShowAddTimestampModal] =
    React.useState(false);
  const { availableTimestamps } = useLifecycleOverview();

  const { trigger: onSelectTimestamp } = useAPIMutation(
    "incidentTimestampsList",
    undefined,
    async (apiClient, timestamp) => {
      await apiClient.incidentTimestampsCreateSetByRule({
        createSetByRuleRequestBody: {
          ...rule,
          incident_timestamp_id: timestamp.id,
        },
      });
    },
  );

  const timestampOptions = availableTimestamps.map((ts) => ({
    item: ts,
    label: ts.name,
    sortKey: ts.name,
  }));

  return (
    <>
      <SearchableDropdown
        onSelectItem={onSelectTimestamp}
        emptyState="There are no more timestamps to choose from"
        entries={timestampOptions}
        extraItems={
          <PopoverItem
            analyticsTrackingId={null}
            icon={IconEnum.Add}
            onClick={() => setShowAddTimestampModal(true)}
          >
            Add new timestamp
          </PopoverItem>
        }
        renderTriggerButton={({ onClick }) => (
          <GatedButton
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId="add-missing-timestamp"
            onClick={onClick}
            disabled={disabled}
            requiredProduct={Product.Response}
            className={tcx(
              "hover:underline",
              "font-medium !border-0",
              disabled
                ? "!text-slate-400"
                : "text-content-primary hover:text-content-primary",
            )}
          >
            Add missing timestamp
          </GatedButton>
        )}
      />
      {showAddTimestampModal && (
        <IncidentTimestampCreateModal
          onClose={() => setShowAddTimestampModal(false)}
          rulePrefill={{
            ...rule,
            // Shakes fist at the enum gods
            set_on_visit:
              rule.set_on_visit as unknown as CreateTimestampVisitEnum,
            set_on_transition:
              rule.set_on_transition as unknown as CreateTimestampTransitionEnum,
          }}
        />
      )}
    </>
  );
};

export const prefillEnteringStatus = (statusId: string): SetByRulePrefill => {
  return {
    set_on_visit: VisitEnum.Last,
    set_on_transition: TransitionEnum.Enter,
    set_on_status_id: statusId,
  };
};

export const prefillLeavingStatus = (statusId: string): SetByRulePrefill => {
  return {
    set_on_visit: VisitEnum.First,
    set_on_transition: TransitionEnum.Leave,
    set_on_status_id: statusId,
  };
};
