/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableIncidentFormEscalationElement,
    AvailableIncidentFormEscalationElementFromJSON,
    AvailableIncidentFormEscalationElementFromJSONTyped,
    AvailableIncidentFormEscalationElementToJSON,
} from './AvailableIncidentFormEscalationElement';

/**
 * 
 * @export
 * @interface IncidentFormsListAvailableEscalationElementsResponseBody
 */
export interface IncidentFormsListAvailableEscalationElementsResponseBody {
    /**
     * 
     * @type {Array<AvailableIncidentFormEscalationElement>}
     * @memberof IncidentFormsListAvailableEscalationElementsResponseBody
     */
    available_elements: Array<AvailableIncidentFormEscalationElement>;
}

export function IncidentFormsListAvailableEscalationElementsResponseBodyFromJSON(json: any): IncidentFormsListAvailableEscalationElementsResponseBody {
    return IncidentFormsListAvailableEscalationElementsResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsListAvailableEscalationElementsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsListAvailableEscalationElementsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available_elements': ((json['available_elements'] as Array<any>).map(AvailableIncidentFormEscalationElementFromJSON)),
    };
}

export function IncidentFormsListAvailableEscalationElementsResponseBodyToJSON(value?: IncidentFormsListAvailableEscalationElementsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_elements': ((value.available_elements as Array<any>).map(AvailableIncidentFormEscalationElementToJSON)),
    };
}

