/* eslint-disable-next-line */
const defaultTheme = require("tailwindcss/defaultTheme");
const colors = require("tailwindcss/colors");

// Our official grey color palette.
const slatePalette = {
  50: "#F7F7F8",
  100: "#E4E4E7",
  200: "#CACACE",
  300: "#AFAFB6",
  400: "#95959D",
  500: "#787882",
  600: "#5F5F68",
  700: "#39393F",
  800: "#29292F",
  900: "#161618",
  950: "#0C0C0D",
};

const alarmaladePalette = {
  50: "#FEEFEC",
  100: "#FDDFD9",
  200: "#FABBAE",
  300: "#F79C87",
  400: "#F5785C",
  500: "#F25533",
  600: "#DD340D",
  700: "#A8280B",
  800: "#6E1A07",
  900: "#3A0E04",
  950: "#1D0702",
};

const bluePalette = {
  50: "#F5F6FF",
  100: "#DBE9FE",
  200: "#D1D8FF",
  300: "#B3BEFF",
  400: "#5770FF",
  500: "#2E4DFF",
  600: "#0229FF",
  700: "#001DC2",
  800: "#001BB3",
  900: "#001380",
  950: "#000D57",
};

const greenPalette = {
  50: "#EEFCF8",
  100: "#D8F8F0",
  200: "#A9EFDD",
  300: "#76E5C9",
  400: "#2DD7AD",
  500: "#24C19A",
  600: "#1FA382",
  700: "#187F65",
  800: "#146B55",
  900: "#0E493A",
  950: "#0B382C",
};

function withSemantics(palette, bgTint, contentTint) {
  return {
    ...palette,
    surface: palette[bgTint],
    content: palette[contentTint],
  };
}

const colorPalette = {
  white: colors.white,
  black: colors.black,
  red: withSemantics(colors.red, "100", "700"),
  amber: withSemantics(colors.amber, "100", "700"),
  green: withSemantics(greenPalette, "100", "700"),
  blue: withSemantics(bluePalette, "100", "600"),
  pink: withSemantics(colors.pink, "100", "700"),
  purple: withSemantics(colors.purple, "100", "700"),
  slate: withSemantics(slatePalette, "50", "700"),
  alarmalade: withSemantics(alarmaladePalette, "100", "700"),
  zinc: colors.zinc,
  neutral: colors.neutral,
  stone: colors.stone,
  orange: colors.orange,
  yellow: colors.yellow,
  lime: colors.lime,
  emerald: colors.emerald,
  teal: colors.teal,
  cyan: colors.cyan,
  sky: colors.sky,
  indigo: colors.indigo,
  violet: colors.violet,
  fuchsia: colors.fuchsia,
  rose: colors.rose,
  "cal-maintenance": {
    DEFAULT: "#DCEEF9",
    dark: "#438dbd",
    bold: "#438DBD",
    hover: "#a1c6de",
  },
  "cal-degraded": {
    DEFAULT: "#FFF4BE",
    dark: "#E0B035",
    bold: "#AC910A",
    hover: "#D5C23E",
  },
  "cal-partial-outage": {
    DEFAULT: "#FFE4CE",
    dark: "#DE7B2A",
    bold: "#DB7420",
    hover: "#E08E37",
  },
  "cal-full-outage": {
    DEFAULT: "#FFE3DD",
    dark: "#F25533",
    bold: "#CB6450",
    hover: "#E56947",
  },
  "cal-empty": "var(--color-cal-empty)",
  "icon-operational": "var(--color-icon-operational)",
  "icon-degraded": "var(--color-icon-degraded)",
  "icon-partial-outage": "var(--color-icon-partial-outage)",
  "icon-full-outage": "var(--color-icon-full-outage)",
  "icon-maintenance": "var(--color-icon-maintenance)",
  modal: "var(--color-modal-bg)",
  "terraform-purple": { 500: "#5c4ee5" },
  default: "var(--color-default)",
  transparent: "transparent",
  current: "currentColor",
};

// Enhance our color palette with tokenised colors that we can replace
// based on the semantics
colorPalette.brand = {
  DEFAULT: colorPalette.alarmalade["500"], // Primary brand color
  blue: colorPalette.blue["500"], // Brand accent color
};

colorPalette.utility = {
  success: colorPalette.green["600"],
};

// Colouring text and icons
colorPalette.content = {
  primary: colorPalette.slate["900"],
  secondary: colorPalette.slate["600"],
  tertiary: colorPalette.slate["400"],
  link: colorPalette.brand.blue,
  invert: colorPalette.slate["50"],
  destroy: colorPalette.red["600"],
};

// Use for all backgrounds and containers
colorPalette.surface = {
  primary: colorPalette.white,
  secondary: colorPalette.slate["50"],
  tertiary: colorPalette.slate["100"],
  invert: colorPalette.slate["800"],
  destroy: colorPalette.red["600"],
};

colorPalette.stroke = {
  DEFAULT: colorPalette.slate["100"],
  primary: colorPalette.slate["100"], // Same as DEFAULT, just an alias to make it easier with Figma
  hover: colorPalette.slate["200"],
  secondary: colorPalette.slate["50"],
  "secondary-hover": colorPalette.slate["100"],
  invert: colorPalette.slate["800"],
  "invert-hover": colorPalette.slate["700"],
  destroy: colorPalette.red["600"],
  "destroy-hover": colorPalette.red["700"],
};

// Raw sizes, which we shouldn't really use, but are here for backwards-compatability (and to avoid being
// super confusing if you're looking at the docs)
const fontSizes = {
  xs: [
    "12px",
    {
      lineHeight: "1rem",
      letterSpacing: "normal",
    },
  ],
  sm: [
    "14px",
    {
      lineHeight: "1.25rem",
      letterSpacing: "-0.07px",
    },
  ],
  base: [
    "16px",
    {
      lineHeight: "1.5rem",
      letterSpacing: "-0.2px",
    },
  ],
  "2xl": [
    "24px",
    {
      lineHeight: "2rem",
      letterSpacing: "-0.36px",
    },
  ],
  "5xl": [
    "48px",
    {
      lineHeight: "3rem",
      letterSpacing: "-1.44px",
    },
  ],
};

const fontSizePlusWeight = (base, weight) => {
  return [base[0], { ...base[1], fontWeight: weight }];
};

const typography = {
  // Our blessed typography styles
  "xs-med": fontSizePlusWeight(fontSizes.xs, "500"),
  "xs-bold": fontSizePlusWeight(fontSizes.xs, "600"),
  "sm-normal": fontSizePlusWeight(fontSizes.sm, "400"),
  "sm-med": fontSizePlusWeight(fontSizes.sm, "500"),
  "sm-bold": fontSizePlusWeight(fontSizes.sm, "600"),
  "base-bold": fontSizePlusWeight(fontSizes.base, "600"),
  "2xl-bold": fontSizePlusWeight(fontSizes["2xl"], "600"),
  "5xl-bold": fontSizePlusWeight(fontSizes["5xl"], "600"),
};

/** @type {import("@types/tailwindcss/tailwind-config").TailwindConfig } */
module.exports = {
  darkMode: "class",
  content: [`${__dirname}/src/**/*.{js,ts,jsx,tsx}`],
  theme: {
    extend: {
      animation: {
        "status-page-update-pop": "statusPageUpdatePop 0.5s ease-out 1",
        "ping-slow": "ping 2s cubic-bezier(0, 0, 0.2, 1) infinite",
      },
      keyframes: {
        statusPageUpdatePop: {
          "33%": { transform: "scale(1.01)", borderColor: "#a3aebb" },
          "0%, 100%": { transform: "scale(1)" },
        },
      },
      transitionProperty: {
        height: "height",
        "max-height": "max-height",
        "grid-template-rows": "grid-template-rows",
      },
      fontFamily: {
        display: ["Inter", ...defaultTheme.fontFamily.sans],
        body: ["Inter", ...defaultTheme.fontFamily.sans],
        mono: ["Source Code Pro", ...defaultTheme.fontFamily.mono],
      },
      fontSize: {
        ...fontSizes,
        ...typography,
      },
      borderRadius: {
        0.5: "2px",
        1: "4px",
        2: "8px",
        3: "12px",
        4: "16px",
      },
      boxShadow: {
        xs: "0px 1px 2px rgba(22, 22, 24, 0.1)",
        sm: "0px 1px 2px 0px rgba(22, 22, 24, 0.06)",
        DEFAULT:
          "0px 1px 3px 0px rgba(22, 22, 24, 0.06), 0px 1px 2px 0px rgba(22, 22, 24, 0.04)",
        md: "0px 4px 6px -1px rgba(22, 22, 24, 0.06), 0px 2px 4px -1px rgba(22, 22, 24, 0.04)",
        lg: "0px 8px 15px -3px rgba(22, 22, 24, 0.06), 0px 4px 6px -2px rgba(22, 22, 24, 0.04)",
        xl: "0px 16px 25px -5px rgba(22, 22, 24, 0.06), 0px 10px 10px -5px rgba(22, 22, 24, 0.04)",
        "2xl":
          "0px 4px 6px 0px rgba(0, 0, 0, 0.06), 0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
        inner: "0px 0.4px 0px 0px rgba(0, 0, 0, 0.25) inset", // Used on content in buttons
        button:
          "0px -1px 0px 0px rgba(0, 0, 0, 0.04) inset, 0px 1px 2px 0px rgba(22, 22, 24, 0.06), 0px 1px 0px 0px rgba(255, 255, 255, 0.24) inset", // Applied to buttons
        "button-dark":
          "0px -2px 0px 0px rgba(0, 0, 0, 0.04) inset, 0px 1px 2px 0px rgba(22, 22, 24, 0.06), 0px 1px 0px 0px rgba(255, 255, 255, 0.08) inset",
        "button-light":
          "0px -2px 0px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(22, 22, 24, 0.06), 0px 2px 0px 0px rgba(255, 255, 255, 0.24)",
        emboss:
          "0px -1px 0px 0px rgba(0, 0, 0, 0.04) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.14) inset", // Applied to UI elements that aren't buttons
        none: "none",
      },
      scale: {
        "-1": "-1",
      },
      colors: colorPalette,
    },
  },
  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
    "3xl": "1920px",
  },
  variants: {
    extend: {
      tableLayout: ["hover", "focus"],
      display: ["group-hover"],
    },
  },
  plugins: [require("@tailwindcss/forms"), require("@tailwindcss/typography")],
};
