import type { SVGProps } from "react";
import * as React from "react";
const SvgAlignArrowLeft = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.412 10.75H3.834c-.323 0-.586-.336-.586-.75s.263-.75.586-.75h9.578c.323 0 .586.336.586.75s-.263.75-.586.75Z"
    />
    <path
      fill="currentColor"
      d="M7.998 15a.75.75 0 0 1-.703-.49c-1.131-3.061-3.629-3.756-3.735-3.784A.753.753 0 0 1 3 9.997a.749.749 0 0 1 .563-.724c.103-.027 2.601-.722 3.731-3.783a.75.75 0 1 1 1.406.519c-.782 2.12-2.12 3.328-3.181 3.99 1.061.662 2.398 1.87 3.181 3.99a.749.749 0 0 1-.703 1.009l.001.002ZM16.248 3a.75.75 0 0 0-.75.75v12.5a.75.75 0 0 0 1.5 0V3.75a.75.75 0 0 0-.75-.75Z"
    />
  </svg>
);
export default SvgAlignArrowLeft;
