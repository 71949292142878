import { CatalogConfigType } from "@incident-io/api";
import { CatalogTypeBadge } from "@incident-shared/attribute";
import {
  AccordionStackedList,
  Badge,
  BadgeSize,
  BadgeTheme,
  Link,
  StackedListItem,
} from "@incident-ui";
import {
  CodeSnippet,
  CodeSnippetTheme,
} from "@incident-ui/CodeSnippet/CodeSnippet";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";

export const AlertSourceDatadogTaggingInfo = ({
  catalogType,
}: {
  catalogType: CatalogConfigType;
}): React.ReactElement => {
  const suggestedTagKey = catalogType.name.toLowerCase().split(" ").join("-");

  const items = [
    {
      id: "option-1",
      name: "Option 1: Single webhook",
      description: "Dynamically set team using Datadog tags",
      instructions: (
        <NumberedList>
          <div>
            Create a single webhook in Datadog, hooked up to all your chosen
            monitors by referencing{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              @webhook-incident-io
            </CodeSnippet>{" "}
            in each
          </div>
          <div>
            <Link
              href="https://docs.datadoghq.com/getting_started/tagging/"
              openInNewTab
              analyticsTrackingId={"datadog-view-tagging-docs"}
            >
              Tag all monitors
            </Link>{" "}
            with a{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              {suggestedTagKey}
            </CodeSnippet>{" "}
            tag, referencing your{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            using one of the identifiers below
          </div>
          <div>
            Parse your{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              {suggestedTagKey}
            </CodeSnippet>{" "}
            tag into an alert attribute within this Datadog alert source
          </div>
        </NumberedList>
      ),
    },
    {
      id: "option-2",
      name: "Option 2: Multiple webhooks",
      description: `Hardcode a ${catalogType.name} in each webhook payload`,
      instructions: (
        <NumberedList>
          <div>
            Create a webhook in Datadog per{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />
          </div>
          <div>
            Send a hardcoded{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              {suggestedTagKey}
            </CodeSnippet>{" "}
            value in the payload of each of your webhooks
          </div>
          <div>
            Apply each individual monitor monitors to the webhook for the
            relevant service by referencing{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              @webhook-incident-io-[MY-SERVICE]
            </CodeSnippet>
          </div>
          <div>
            Parse your{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              {suggestedTagKey}
            </CodeSnippet>{" "}
            payload value into an alert attribute within this Datadog alert
            source
          </div>
        </NumberedList>
      ),
    },
  ];

  return (
    <div>
      <AccordionStackedList
        items={items}
        initialExpandedItems={["option-1"]}
        renderRow={(item) => (
          <StackedListItem
            title={item.name}
            noPadding
            badgeNode={
              item.id === "option-1" ? (
                <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Info}>
                  Recommended
                </Badge>
              ) : null
            }
            description={item.description}
            descriptionClassName="text-xs-med"
          />
        )}
        renderAccordion={(item) => item.instructions}
      />
    </div>
  );
};
