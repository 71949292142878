import {
  EscalationStatusEnum,
  EscalationTransitionToStateEnum,
} from "@incident-io/api";

export const ESCALATION_LABEL: {
  [key in EscalationTransitionToStateEnum | EscalationStatusEnum];
} = {
  [EscalationTransitionToStateEnum.Pending || EscalationStatusEnum.Pending]:
    "pending",
  [EscalationTransitionToStateEnum.Expired || EscalationStatusEnum.Expired]:
    "expired",
  [EscalationTransitionToStateEnum.Cancelled || EscalationStatusEnum.Cancelled]:
    "cancelled",
  [EscalationTransitionToStateEnum.Resolved || EscalationStatusEnum.Resolved]:
    "acknowledged",
  [EscalationTransitionToStateEnum.Acked || EscalationStatusEnum.Acked]:
    "acknowledged",
  [EscalationTransitionToStateEnum.Triggered || EscalationStatusEnum.Triggered]:
    "triggered",
};
