import { AlertHeadlineMetricUnitEnum } from "@incident-io/api";
import pluralize from "pluralize";
import { formatDurationFromHours } from "src/utils/datetime";

export const formatValueWithUnit = (
  value: number,
  unit: AlertHeadlineMetricUnitEnum,
) => {
  if (unit === "%") {
    // 50%
    return value.toFixed(0) + "%";
  }
  if (unit === "hour") {
    // 2h30m or 2 hours
    if (Number.isInteger(value)) {
      return `${value} hours`;
    }

    return value === 0 ? "0 hours" : formatDurationFromHours(value);
  }
  const formattedUnit = `${pluralize(unit, value)}`;
  return `${value} ${formattedUnit}`;
};
