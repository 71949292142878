import type { SVGProps } from "react";
import * as React from "react";
const SvgFakeRadioUnchecked = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#212121"
      d="M10 18.889c-4.901 0-8.889-3.988-8.889-8.889S5.1 1.111 10 1.111 18.889 5.1 18.889 10 14.9 18.889 10 18.889Zm0-16.111c-3.982 0-7.222 3.24-7.222 7.222s3.24 7.222 7.222 7.222 7.222-3.24 7.222-7.222S13.982 2.778 10 2.778Z"
    />
  </svg>
);
export default SvgFakeRadioUnchecked;
