import type { SVGProps } from "react";
import * as React from "react";
const SvgBulb = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.373 3.286c-1.35-1.098-3.12-1.519-4.856-1.158C6.29 2.593 4.515 4.414 4.1 6.659c-.444 2.399.612 4.75 2.65 5.992v2.599A2.753 2.753 0 0 0 9.5 18h.5a2.752 2.752 0 0 0 2.75-2.75v-2.599A5.717 5.717 0 0 0 15.5 7.75a5.732 5.732 0 0 0-2.126-4.464ZM9.498 16.5c-.6 0-1.08-.434-1.199-1h2.9c-.12.566-.6 1-1.2 1h-.5Zm1.75-2.5h-3v-1h3v1Zm1.03-6.22-1.78 1.78v1.189a.75.75 0 0 1-1.5 0V9.56l-1.78-1.78A.75.75 0 1 1 8.28 6.719l1.47 1.47 1.47-1.47A.75.75 0 1 1 12.28 7.78h-.002Z"
    />
  </svg>
);
export default SvgBulb;
