import type { SVGProps } from "react";
import * as React from "react";
const SvgGoogleDocs = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <mask
      id="google-docs_svg__a"
      width={12}
      height={16}
      x={4}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364L11.431 2Z"
      />
    </mask>
    <g mask="url(#google-docs_svg__a)">
      <path
        fill="#4285F4"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364l-2.53-1.819L11.43 2Z"
      />
    </g>
    <mask
      id="google-docs_svg__b"
      width={12}
      height={16}
      x={4}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364L11.431 2Z"
      />
    </mask>
    <g mask="url(#google-docs_svg__b)">
      <path
        fill="url(#google-docs_svg__c)"
        d="m11.749 6.045 4.02 4.043V6.364l-4.02-.32Z"
      />
    </g>
    <mask
      id="google-docs_svg__d"
      width={12}
      height={16}
      x={4}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364L11.431 2Z"
      />
    </mask>
    <g mask="url(#google-docs_svg__d)">
      <path
        fill="#F1F1F1"
        d="M7.092 13.636h5.785v-.727H7.092v.727Zm0 1.455h4.339v-.727H7.092v.727Zm0-5.091v.727h5.785V10H7.092Zm0 2.182h5.785v-.728H7.092v.728Z"
      />
    </g>
    <mask
      id="google-docs_svg__e"
      width={12}
      height={16}
      x={4}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364L11.431 2Z"
      />
    </mask>
    <g mask="url(#google-docs_svg__e)">
      <path
        fill="#A1C2FA"
        d="M11.431 2v3.273c0 .602.486 1.09 1.085 1.09h3.254L11.43 2Z"
      />
    </g>
    <mask
      id="google-docs_svg__f"
      width={12}
      height={16}
      x={4}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364L11.431 2Z"
      />
    </mask>
    <g mask="url(#google-docs_svg__f)">
      <path
        fill="#fff"
        fillOpacity={0.2}
        d="M5.285 2A1.09 1.09 0 0 0 4.2 3.09v.092c0-.6.488-1.091 1.085-1.091h6.146V2H5.285Z"
      />
    </g>
    <mask
      id="google-docs_svg__g"
      width={12}
      height={16}
      x={4}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364L11.431 2Z"
      />
    </mask>
    <g mask="url(#google-docs_svg__g)">
      <path
        fill="#1A237E"
        fillOpacity={0.2}
        d="M14.685 17.909h-9.4a1.09 1.09 0 0 1-1.085-1.09v.09c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.09v-.092c0 .6-.488 1.09-1.084 1.09Z"
      />
    </g>
    <mask
      id="google-docs_svg__h"
      width={12}
      height={16}
      x={4}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M11.43 2H5.286A1.09 1.09 0 0 0 4.2 3.09V16.91c0 .6.488 1.091 1.085 1.091h9.4a1.09 1.09 0 0 0 1.084-1.091V6.364L11.431 2Z"
      />
    </mask>
    <g mask="url(#google-docs_svg__h)">
      <path
        fill="#1A237E"
        fillOpacity={0.1}
        d="M12.516 6.364c-.6 0-1.085-.489-1.085-1.091v.09c0 .603.486 1.092 1.085 1.092h3.254v-.091h-3.254Z"
      />
    </g>
    <path
      fill="url(#google-docs_svg__i)"
      d="M11.432 2H5.285a1.09 1.09 0 0 0-1.084 1.09V16.91c0 .6.488 1.091 1.084 1.091h9.4c.597 0 1.085-.49 1.085-1.091V6.364L11.432 2Z"
    />
    <defs>
      <radialGradient
        id="google-docs_svg__i"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="translate(40.852 33.436) scale(1865.53)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.1} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="google-docs_svg__c"
        x1={212.827}
        x2={212.827}
        y1={40.763}
        y2={410.458}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A237E" stopOpacity={0.2} />
        <stop offset={1} stopColor="#1A237E" stopOpacity={0.02} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgGoogleDocs;
