/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallProviders,
    CallProvidersFromJSON,
    CallProvidersFromJSONTyped,
    CallProvidersToJSON,
} from './CallProviders';

/**
 * 
 * @export
 * @interface IncidentCallSettingsGetCallProvidersResponseBody
 */
export interface IncidentCallSettingsGetCallProvidersResponseBody {
    /**
     * 
     * @type {CallProviders}
     * @memberof IncidentCallSettingsGetCallProvidersResponseBody
     */
    available_providers: CallProviders;
    /**
     * 
     * @type {CallProviders}
     * @memberof IncidentCallSettingsGetCallProvidersResponseBody
     */
    available_providers_with_events_enabled?: CallProviders;
}

export function IncidentCallSettingsGetCallProvidersResponseBodyFromJSON(json: any): IncidentCallSettingsGetCallProvidersResponseBody {
    return IncidentCallSettingsGetCallProvidersResponseBodyFromJSONTyped(json, false);
}

export function IncidentCallSettingsGetCallProvidersResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallSettingsGetCallProvidersResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available_providers': CallProvidersFromJSON(json['available_providers']),
        'available_providers_with_events_enabled': !exists(json, 'available_providers_with_events_enabled') ? undefined : CallProvidersFromJSON(json['available_providers_with_events_enabled']),
    };
}

export function IncidentCallSettingsGetCallProvidersResponseBodyToJSON(value?: IncidentCallSettingsGetCallProvidersResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_providers': CallProvidersToJSON(value.available_providers),
        'available_providers_with_events_enabled': CallProvidersToJSON(value.available_providers_with_events_enabled),
    };
}

