/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraChangelog,
    JiraChangelogFromJSON,
    JiraChangelogFromJSONTyped,
    JiraChangelogToJSON,
} from './JiraChangelog';
import {
    JiraIssue,
    JiraIssueFromJSON,
    JiraIssueFromJSONTyped,
    JiraIssueToJSON,
} from './JiraIssue';
import {
    JiraWebhookUser,
    JiraWebhookUserFromJSON,
    JiraWebhookUserFromJSONTyped,
    JiraWebhookUserToJSON,
} from './JiraWebhookUser';

/**
 * 
 * @export
 * @interface IntegrationsJiraCloudReceiveWebhookRequestBody
 */
export interface IntegrationsJiraCloudReceiveWebhookRequestBody {
    /**
     * 
     * @type {JiraChangelog}
     * @memberof IntegrationsJiraCloudReceiveWebhookRequestBody
     */
    changelog?: JiraChangelog;
    /**
     * 
     * @type {JiraIssue}
     * @memberof IntegrationsJiraCloudReceiveWebhookRequestBody
     */
    issue?: JiraIssue;
    /**
     * 
     * @type {JiraWebhookUser}
     * @memberof IntegrationsJiraCloudReceiveWebhookRequestBody
     */
    user?: JiraWebhookUser;
    /**
     * The event type
     * @type {string}
     * @memberof IntegrationsJiraCloudReceiveWebhookRequestBody
     */
    webhookEvent?: string;
}

export function IntegrationsJiraCloudReceiveWebhookRequestBodyFromJSON(json: any): IntegrationsJiraCloudReceiveWebhookRequestBody {
    return IntegrationsJiraCloudReceiveWebhookRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsJiraCloudReceiveWebhookRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsJiraCloudReceiveWebhookRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changelog': !exists(json, 'changelog') ? undefined : JiraChangelogFromJSON(json['changelog']),
        'issue': !exists(json, 'issue') ? undefined : JiraIssueFromJSON(json['issue']),
        'user': !exists(json, 'user') ? undefined : JiraWebhookUserFromJSON(json['user']),
        'webhookEvent': !exists(json, 'webhookEvent') ? undefined : json['webhookEvent'],
    };
}

export function IntegrationsJiraCloudReceiveWebhookRequestBodyToJSON(value?: IntegrationsJiraCloudReceiveWebhookRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changelog': JiraChangelogToJSON(value.changelog),
        'issue': JiraIssueToJSON(value.issue),
        'user': JiraWebhookUserToJSON(value.user),
        'webhookEvent': value.webhookEvent,
    };
}

