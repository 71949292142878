/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttributePathItem,
    CatalogTypeAttributePathItemFromJSON,
    CatalogTypeAttributePathItemFromJSONTyped,
    CatalogTypeAttributePathItemToJSON,
} from './CatalogTypeAttributePathItem';

/**
 * 
 * @export
 * @interface AvailablePathAttribute
 */
export interface AvailablePathAttribute {
    /**
     * Should this attribute be an array attribute (i.e. are any of the attributes in the path arrays?)
     * @type {boolean}
     * @memberof AvailablePathAttribute
     */
    array: boolean;
    /**
     * What name should we give this attribute by default?
     * @type {string}
     * @memberof AvailablePathAttribute
     */
    default_name: string;
    /**
     * The path that will be used to derive this attribute
     * @type {Array<CatalogTypeAttributePathItem>}
     * @memberof AvailablePathAttribute
     */
    path: Array<CatalogTypeAttributePathItem>;
    /**
     * What catalog type would this virtual resource resolve to
     * @type {string}
     * @memberof AvailablePathAttribute
     */
    type: string;
}

export function AvailablePathAttributeFromJSON(json: any): AvailablePathAttribute {
    return AvailablePathAttributeFromJSONTyped(json, false);
}

export function AvailablePathAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailablePathAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array': json['array'],
        'default_name': json['default_name'],
        'path': ((json['path'] as Array<any>).map(CatalogTypeAttributePathItemFromJSON)),
        'type': json['type'],
    };
}

export function AvailablePathAttributeToJSON(value?: AvailablePathAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array': value.array,
        'default_name': value.default_name,
        'path': ((value.path as Array<any>).map(CatalogTypeAttributePathItemToJSON)),
        'type': value.type,
    };
}

