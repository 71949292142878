import {
  ExtendedFormFieldValue,
  useFiltersContext,
} from "@incident-shared/filters";
import { useAppliedCurrentUserFilters } from "@incident-shared/filters/FilterFormElement";
import { useFormContext } from "react-hook-form";

import { EditDashboardFormData } from "./useInsightsContext";

// This hook gets the dashboard level filters, panel level filters, and combines them
export const useDashboardAndPanelFilters = (
  panelIdx: number,
): ExtendedFormFieldValue[] => {
  // We get the dashboard level filters from the filters context
  const { filters: dashboardFilters } = useFiltersContext();

  // We get the panel and its filters from the form context
  const formMethods = useFormContext<EditDashboardFormData>();
  const panel = formMethods.watch(`panels.${panelIdx}`);

  // Replaces 'current_user' with the actual ID of the current user.
  return useAppliedCurrentUserFilters(dashboardFilters.concat(panel.filters));
};
