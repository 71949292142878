import type { SVGProps } from "react";
import * as React from "react";
const SvgMinus = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M14.423 10.75H5.577C5.258 10.75 5 10.414 5 10s.258-.75.577-.75h8.846c.319 0 .577.336.577.75s-.258.75-.577.75Z" />
  </svg>
);
export default SvgMinus;
