import { customComponents } from "@incident-ui/Select/customComponents";
import { customStyles } from "@incident-ui/Select/customStyles";
import {
  HydratedValuesCache,
  SelectOption,
  SelectOptionGroup,
  SelectOptions,
  SharedDynamicSelectProps,
} from "@incident-ui/Select/types";
import { useAutoAdjustingMenuPlacement } from "@incident-ui/Select/useAutoAdjustingMenuPlacement";
import { captureException } from "@sentry/react";
import { useEffect, useState } from "react";
import { MultiValue } from "react-select";
import ReactAsyncSelect from "react-select/async";
import { sendWarningToSentry } from "src/utils/utils";

import { SelectWrapper } from "./SelectWrapper";

export type DynamicMultiSelectProps = SharedDynamicSelectProps & {
  value: string[];
  onChange: (val: string[]) => void;
  hydrateOptions: (val: string[]) => Promise<SelectOptions>;
  onValueChange?: (val: string[]) => void;
};

const useHydratedValueCacheMulti = ({
  hydrateOptions,
  isLoading = false,
  values = [],
}: {
  hydrateOptions: (val: string[]) => Promise<SelectOptions>;
  isLoading?: boolean;
  values: string[];
}): {
  cachedValues: SelectOption[];
  hydrating: boolean;
  cacheHydratedValues: (hydratedValues: SelectOptions) => void;
} => {
  const [hydrating, setHydrating] = useState<boolean>(false);
  // this is a hack so we don't have to hit the API to hydrate a value which we already
  // know about
  const [hydratedValuesCache, setHydratedValuesCache] =
    useState<HydratedValuesCache>({});

  const MAX_HYDRATE_ATTEMPTS = 10;

  // When the values change, we want to trigger a re-hydration of the values.
  useEffect(() => {
    const attempts = {};
    let maxHit = false;

    const loader = async (attemptCount = MAX_HYDRATE_ATTEMPTS) => {
      if (values.length === 0) {
        // there is nothing to hydrate, lets return early
        return;
      }
      if (attemptCount <= 0) {
        // we've tried too many times, lets return early
        sendWarningToSentry("Failed to hydrate values after max attempts", {
          maxAttempts: MAX_HYDRATE_ATTEMPTS,
          values,
        });
        return;
      }
      setHydrating(true);
      const hydrated = await hydrateOptions(values);
      const missing =
        values.length !== hydrated.length
          ? values.filter(
              (val) =>
                !hydrated.find((optOrGroup) =>
                  "options" in optOrGroup
                    ? optOrGroup.options.find((opt) => opt.value === val)
                    : optOrGroup.value === val,
                ),
            )
          : [];

      if (missing.length > 0 && !isLoading) {
        missing.forEach((val) => {
          attempts[val] = (attempts[val] ?? 0) + 1;

          if (attempts[val] >= MAX_HYDRATE_ATTEMPTS) {
            console.warn(
              `Failed to hydrate value ${val} after ${MAX_HYDRATE_ATTEMPTS} attempts`,
            );
            maxHit = true;
          }
        });

        if (!maxHit) {
          await loader(attemptCount - 1);
          return;
        }
      }

      cacheHydratedValues(hydrated);
      setHydrating(false);
    };

    loader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const cacheHydratedValues = (hydratedValues: SelectOptions) => {
    const newlyFoundValues: HydratedValuesCache = {};
    hydratedValues.forEach((opt: SelectOption | SelectOptionGroup) => {
      if ("options" in opt) {
        opt.options.forEach((opt) => {
          newlyFoundValues[opt.value] = opt;
        });
      } else {
        newlyFoundValues[opt.value] = opt;
      }
    });

    setHydratedValuesCache((current) => ({
      ...current,
      ...newlyFoundValues,
    }));
  };

  return {
    cachedValues: Object.values(hydratedValuesCache),
    hydrating,
    cacheHydratedValues,
  };
};

export const DynamicMultiSelect = ({
  loadOptions: loadOptionsCallback,
  hydrateOptions,
  icon,
  id,
  value: values,
  onChange: onValueChange,
  isLoading,
  ...rest
}: DynamicMultiSelectProps): React.ReactElement => {
  // the outside world wants to deals with values that are strings, but react select expects
  // SelectOptions as values. So we have to do some hacking to make the outside world not
  // know about this sadness.
  const { hydrating, cacheHydratedValues, cachedValues } =
    useHydratedValueCacheMulti({ hydrateOptions, isLoading, values });

  async function loadOptions(inputValue: string) {
    try {
      const loadedOptions = await loadOptionsCallback(inputValue);
      cacheHydratedValues(loadedOptions);
      return loadedOptions;
    } catch (e) {
      // The default here is for react-select to ignore this error, which is
      // quite bad.
      captureException(e);
      console.error(e);
      throw e;
    }
  }

  const onChange = (selectedOptions: MultiValue<SelectOption>) => {
    onValueChange(selectedOptions.map((opt) => opt.value));
  };

  const initialValues = values
    ? cachedValues.filter((val) => values.includes(val.value))
    : [];

  return (
    <DynamicMultiSelectWithObj
      value={initialValues}
      onChange={onChange}
      icon={icon}
      id={id}
      loadOptions={loadOptions}
      isLoading={isLoading || hydrating}
      {...rest}
    />
  );
};

DynamicMultiSelect.displayName = "DynamicMultiSelect";

export type DynamicMultiSelectWithObjProps = SharedDynamicSelectProps & {
  value?: SelectOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hydrateValue?: (val: any[]) => SelectOption[];
  onChange: (val: MultiValue<SelectOption>) => void;
};

export const DynamicMultiSelectWithObj = ({
  loadOptions,
  icon,
  optionIcon,
  optionColor,
  ignoreDisabledStyling,
  id,
  value: values,
  hydrateValue,
  insetSuffixNode,
  onChange,
  invalid = false,
  isDisabled,
  className,
  ...rest
}: DynamicMultiSelectWithObjProps): React.ReactElement => {
  const [menuPlacement, internalRef] = useAutoAdjustingMenuPlacement();

  const hydratedValue = hydrateValue ? hydrateValue(values || []) : values;
  const isDisplayingPill = !!optionIcon && !!optionColor;

  return (
    <SelectWrapper
      suffixNode={insetSuffixNode}
      className={className}
      disabled={isDisabled}
    >
      <ReactAsyncSelect<SelectOption, true>
        value={hydratedValue}
        inputId={id}
        onChange={onChange}
        loadOptions={loadOptions}
        isOptionDisabled={(option) => option.disabled ?? false}
        closeMenuOnSelect={false}
        isMulti={true}
        isClearable={false}
        defaultOptions
        styles={customStyles(invalid, !!insetSuffixNode, isDisplayingPill)}
        components={customComponents({
          icon,
          optionIcon,
          optionColor,
          ignoreDisabledStyling,
        })}
        menuPortalTarget={document.body}
        // @ts-expect-error this is fine, just about the react-select types
        ref={internalRef}
        menuPlacement={menuPlacement}
        // Inputs get priority over things with tabIndex=0, since inputs are
        // generally more important than other things on screen.
        tabIndex={1}
        isDisabled={isDisabled}
        {...rest}
      />
    </SelectWrapper>
  );
};

DynamicMultiSelectWithObj.displayName = "DynamicMultiSelectWithObj";
