import type { SVGProps } from "react";
import * as React from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M9.146 14.144c1.468-2.799 3.322-5.044 5.509-6.67a.883.883 0 0 0-1.052-1.417c-2.028 1.507-3.787 3.484-5.244 5.89a13.622 13.622 0 0 0-1.839-2.32.882.882 0 1 0-1.252 1.245c.88.883 1.636 1.952 2.312 3.266a.882.882 0 0 0 1.566.006Z" />
  </svg>
);
export default SvgCheck;
