/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertsTransitionIncidentAlertRequestBody
 */
export interface AlertsTransitionIncidentAlertRequestBody {
    /**
     * What state to transition the alert to
     * @type {string}
     * @memberof AlertsTransitionIncidentAlertRequestBody
     */
    state: AlertsTransitionIncidentAlertRequestBodyStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum AlertsTransitionIncidentAlertRequestBodyStateEnum {
    Pending = 'pending',
    Related = 'related',
    Unrelated = 'unrelated'
}

export function AlertsTransitionIncidentAlertRequestBodyFromJSON(json: any): AlertsTransitionIncidentAlertRequestBody {
    return AlertsTransitionIncidentAlertRequestBodyFromJSONTyped(json, false);
}

export function AlertsTransitionIncidentAlertRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsTransitionIncidentAlertRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
    };
}

export function AlertsTransitionIncidentAlertRequestBodyToJSON(value?: AlertsTransitionIncidentAlertRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
    };
}

