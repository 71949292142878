import { Trigger } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconBadge, IconEnum, IconSize } from "@incident-ui";

import { toSentenceCase } from "../../../../utils/formatting";
import { groupToColorPalette } from "./WorkflowChooseTriggerForm";

type WorkflowActiveTriggerCard = {
  trigger: Trigger;
  iconName: IconEnum;
  deleteButton?: React.ReactElement;
};

export function WorkflowActiveTriggerCard(
  props: WorkflowActiveTriggerCard,
): React.ReactElement {
  const { trigger, iconName, deleteButton } = props;

  const colorPalette = groupToColorPalette(
    trigger?.group_label || ColorPaletteEnum.Blue,
  );

  return (
    <div className="flex flex-row items-center p-3 border rounded-[6px] bg-surface-secondary border-stroke w-full gap-2">
      <IconBadge icon={iconName} color={colorPalette} size={IconSize.Large} />
      <div className="text-sm text-content-primary font-medium">
        {toSentenceCase(trigger.label)}
      </div>
      {deleteButton && (
        <div className="hover:cursor-pointer ml-auto flex items-center">
          {deleteButton}
        </div>
      )}
    </div>
  );
}
