/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompetitorImportPayload,
    CompetitorImportPayloadFromJSON,
    CompetitorImportPayloadToJSON,
    CompetitorImportsCreateResponseBody,
    CompetitorImportsCreateResponseBodyFromJSON,
    CompetitorImportsCreateResponseBodyToJSON,
    CompetitorImportsShowResponseBody,
    CompetitorImportsShowResponseBodyFromJSON,
    CompetitorImportsShowResponseBodyToJSON,
} from '../models';

export interface CompetitorImportsCreateRequest {
    createRequestBody: CompetitorImportPayload;
}

export interface CompetitorImportsShowRequest {
    id: string;
}

/**
 * 
 */
export class CompetitorImportsApi extends runtime.BaseAPI {

    /**
     * Create an import of incidents from a competitor account
     * Create CompetitorImports
     */
    async competitorImportsCreateRaw(requestParameters: CompetitorImportsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompetitorImportsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling competitorImportsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/competitor_imports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompetitorImportPayloadToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompetitorImportsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an import of incidents from a competitor account
     * Create CompetitorImports
     */
    async competitorImportsCreate(requestParameters: CompetitorImportsCreateRequest, initOverrides?: RequestInit): Promise<CompetitorImportsCreateResponseBody> {
        const response = await this.competitorImportsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check the status of an import from Blameless
     * Show CompetitorImports
     */
    async competitorImportsShowRaw(requestParameters: CompetitorImportsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompetitorImportsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling competitorImportsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitor_imports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompetitorImportsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Check the status of an import from Blameless
     * Show CompetitorImports
     */
    async competitorImportsShow(requestParameters: CompetitorImportsShowRequest, initOverrides?: RequestInit): Promise<CompetitorImportsShowResponseBody> {
        const response = await this.competitorImportsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
