import {
  IdentityOrganisationAvailableProductsEnum,
  StaffShowOrganisationResponseBody,
  StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing/types";
import { useIdentity } from "src/contexts/IdentityContext";

export const useProductAccess = (): {
  hasProduct: (product: Product) => boolean;
  hasOnCall: boolean;
  hasResponse: boolean;
} => {
  const { identity } = useIdentity();

  const hasProduct = (product: Product) =>
    (identity.organisation_available_products || []).includes(
      product as unknown as IdentityOrganisationAvailableProductsEnum,
    );

  return {
    hasProduct,
    hasOnCall: hasProduct(Product.OnCall),
    hasResponse: hasProduct(Product.Response),
  };
};

// getProductAccess returns product access for a specific organisations data, it's used in
// staffroom when modifying orgs which aren't the current identity.
export const getProductAccess = ({
  organisation_available_products,
}: StaffShowOrganisationResponseBody): {
  hasOnCall: boolean;
  hasResponse: boolean;
} => ({
  hasOnCall: organisation_available_products.includes(
    StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum.OnCall,
  ),
  hasResponse: organisation_available_products.includes(
    StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum.Response,
  ),
});
