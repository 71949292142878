import {
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageStructure,
} from "@incident-io/api";

import { forEachStructureItem } from "./utils";

export const defaultAllComponentsOperational = (
  structure: StatusPageStructure,
) => {
  return forEachStructureItem(structure, (comp) => comp.component_id).reduce(
    (res, compId) =>
      Object.assign(res, {
        [compId]: ComponentStatusEnum.Operational,
      }),
    {},
  );
};
