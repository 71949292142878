/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BulkIncidentUpdatePayload,
    BulkIncidentUpdatePayloadFromJSON,
    BulkIncidentUpdatePayloadFromJSONTyped,
    BulkIncidentUpdatePayloadToJSON,
} from './BulkIncidentUpdatePayload';
import {
    BulkIncidentUpdateResult,
    BulkIncidentUpdateResultFromJSON,
    BulkIncidentUpdateResultFromJSONTyped,
    BulkIncidentUpdateResultToJSON,
} from './BulkIncidentUpdateResult';

/**
 * 
 * @export
 * @interface IncidentsBulkCreateUpdateResponseBody
 */
export interface IncidentsBulkCreateUpdateResponseBody {
    /**
     * 
     * @type {BulkIncidentUpdatePayload}
     * @memberof IncidentsBulkCreateUpdateResponseBody
     */
    incident_update: BulkIncidentUpdatePayload;
    /**
     * 
     * @type {Array<BulkIncidentUpdateResult>}
     * @memberof IncidentsBulkCreateUpdateResponseBody
     */
    results: Array<BulkIncidentUpdateResult>;
}

export function IncidentsBulkCreateUpdateResponseBodyFromJSON(json: any): IncidentsBulkCreateUpdateResponseBody {
    return IncidentsBulkCreateUpdateResponseBodyFromJSONTyped(json, false);
}

export function IncidentsBulkCreateUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsBulkCreateUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_update': BulkIncidentUpdatePayloadFromJSON(json['incident_update']),
        'results': ((json['results'] as Array<any>).map(BulkIncidentUpdateResultFromJSON)),
    };
}

export function IncidentsBulkCreateUpdateResponseBodyToJSON(value?: IncidentsBulkCreateUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_update': BulkIncidentUpdatePayloadToJSON(value.incident_update),
        'results': ((value.results as Array<any>).map(BulkIncidentUpdateResultToJSON)),
    };
}

