import { Incident } from "@incident-io/api";

import { toSentenceCase } from "./formatting";
import { useAPI } from "./swr";

const formatDocumentName = (name: string) => {
  if (name.toLowerCase() === name) {
    // If the name is all lowercase, return it as is
    return name;
  } else if (name.toUpperCase() === name) {
    // If the name is all uppercase, return it as is
    return name;
  } else if (toSentenceCase(name) === name) {
    // If the name is title case, return it in lowercase
    return name.toLowerCase();
  }
  return name;
};

export const usePostmortemName = (
  incident: Pick<Incident, "incident_type"> | null,
) => {
  const postmortemNameOverrideByIncidentType =
    incident?.incident_type?.postmortem_name_override;

  const { data, isLoading: loading } = useAPI(
    "postmortemsSettingsShow",
    undefined,
  );
  const postmortemSettings = data?.settings;

  const postmortemName =
    postmortemNameOverrideByIncidentType ??
    postmortemSettings?.postmortem_rename ??
    "Post-mortem";
  const postmortemNameFormatted = formatDocumentName(postmortemName);
  const postmortemNameWithArticle = ["a", "e", "i", "o", "u"].includes(
    postmortemName.toLowerCase()[0],
  )
    ? `an ${postmortemNameFormatted}`
    : `a ${postmortemNameFormatted}`;

  return {
    postmortemName,
    postmortemNameFormatted,
    postmortemNameWithArticle,
    loading,
  };
};

export const useDebriefName = () => {
  const { data, isLoading: loading } = useAPI(
    "debriefsShowSettings",
    undefined,
  );
  const debriefSettings = data?.settings;

  const debriefName = debriefSettings?.debrief_rename || "Debrief";
  const debriefNameLower = debriefName.toLowerCase();
  const debriefNameWithArticle = ["a", "e", "i", "o", "u"].includes(
    debriefNameLower[0],
  )
    ? `An ${debriefNameLower}`
    : `A ${debriefNameLower}`;
  const debriefNameWithArticleLower = debriefNameWithArticle.toLowerCase();
  return {
    debriefName,
    debriefNameWithArticle,
    debriefNameLower,
    debriefNameWithArticleLower,
    loading,
  };
};
