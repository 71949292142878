/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationCheck,
    InvestigationCheckFromJSON,
    InvestigationCheckFromJSONTyped,
    InvestigationCheckToJSON,
} from './InvestigationCheck';

/**
 * 
 * @export
 * @interface Investigation
 */
export interface Investigation {
    /**
     * The checks that have been run
     * @type {Array<InvestigationCheck>}
     * @memberof Investigation
     */
    checks: Array<InvestigationCheck>;
    /**
     * When the investigation was created.
     * @type {Date}
     * @memberof Investigation
     */
    created_at: Date;
    /**
     * Findings from this investigation
     * @type {string}
     * @memberof Investigation
     */
    findings: string;
    /**
     * Unique ID of the investigation
     * @type {string}
     * @memberof Investigation
     */
    id: string;
    /**
     * External ID of the associated incident
     * @type {number}
     * @memberof Investigation
     */
    incident_external_id: number;
    /**
     * Name of the associated incident
     * @type {string}
     * @memberof Investigation
     */
    incident_name: string;
    /**
     * Current state of the investigation
     * @type {string}
     * @memberof Investigation
     */
    state: string;
    /**
     * Total cost of all AI interactions in this investigation
     * @type {number}
     * @memberof Investigation
     */
    total_cost_in_cents: number;
}

export function InvestigationFromJSON(json: any): Investigation {
    return InvestigationFromJSONTyped(json, false);
}

export function InvestigationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Investigation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checks': ((json['checks'] as Array<any>).map(InvestigationCheckFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'findings': json['findings'],
        'id': json['id'],
        'incident_external_id': json['incident_external_id'],
        'incident_name': json['incident_name'],
        'state': json['state'],
        'total_cost_in_cents': json['total_cost_in_cents'],
    };
}

export function InvestigationToJSON(value?: Investigation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checks': ((value.checks as Array<any>).map(InvestigationCheckToJSON)),
        'created_at': (value.created_at.toISOString()),
        'findings': value.findings,
        'id': value.id,
        'incident_external_id': value.incident_external_id,
        'incident_name': value.incident_name,
        'state': value.state,
        'total_cost_in_cents': value.total_cost_in_cents,
    };
}

