import { BadgeSize, Button, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { useState } from "react";
import { ClientType, useClient } from "src/contexts/ClientContext";

import {
  NegativeFeedbackModal,
  sendPositiveFeedback,
} from "./AssistantFeedbackModal";

export const FeedbackButtons = ({
  questionId,
  isLast,
}: {
  questionId: string;
  isLast: boolean;
}) => {
  const apiClient = useClient();

  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [showFeedbackGiven, setShowFeedbackGiven] = useState(false);
  const [showNegativeFeedbackModal, setShowNegativeFeedbackModal] =
    useState(false);

  const handleFeedbackGiven = () => {
    setFeedbackGiven(true);
    setShowFeedbackGiven(true);
    // Hide the feedback given message after 3 seconds.
    setTimeout(() => {
      setShowFeedbackGiven(false);
    }, 3000);
  };

  // We briefly show a "thank you for giving feedback" message after submitting feedback.
  if (showFeedbackGiven) {
    if (isLast) {
      return (
        <div className="text-slate-700 font-normal p-1 pl-2 pr-2 flex gap-1">
          <Icon id={IconEnum.Heart} /> <span>Thanks for your feedback</span>
        </div>
      );
    } else {
      return (
        <div className="text-slate-600 font-normal pt-2 p-1 animate-pulse">
          <Icon id={IconEnum.Heart} />
        </div>
      );
    }
  }

  // If feedback has been given and we've thanked them, don't show any buttons.
  if (feedbackGiven) {
    return <></>;
  }

  return (
    <div>
      {isLast ? (
        <FeedbackButtonsLatestResponse
          apiClient={apiClient}
          questionId={questionId}
          handleFeedbackGiven={handleFeedbackGiven}
          setShowNegativeFeedbackModal={setShowNegativeFeedbackModal}
        />
      ) : (
        <FeedbackButtonsNonLatestResponse
          apiClient={apiClient}
          questionId={questionId}
          handleFeedbackGiven={handleFeedbackGiven}
          setShowNegativeFeedbackModal={setShowNegativeFeedbackModal}
        />
      )}
      {showNegativeFeedbackModal && (
        <NegativeFeedbackModal
          questionId={questionId}
          onClose={() => setShowNegativeFeedbackModal(false)}
          onFeedbackGiven={() => {
            handleFeedbackGiven();
          }}
        />
      )}
    </div>
  );
};

const FeedbackButtonsLatestResponse = ({
  apiClient,
  questionId,
  handleFeedbackGiven,
  setShowNegativeFeedbackModal,
}: {
  apiClient: ClientType;
  questionId: string;
  handleFeedbackGiven: () => void;
  setShowNegativeFeedbackModal: (value: boolean) => void;
}) => {
  return (
    <div className={"flex items-start gap-1"}>
      <Button
        className="rounded-[6px] border border-slate-900 border-transparent hover:!border-stroke hover:bg-surface-tertiary !no-underline p-1 pl-2 pr-2"
        analyticsTrackingId="assistant-positive-feedback"
        size={BadgeSize.Medium}
        theme={ButtonTheme.Naked}
        icon={IconEnum.ThumbsUp}
        onClick={() => {
          sendPositiveFeedback(apiClient, questionId);
          handleFeedbackGiven();
        }}
      >
        This was helpful
      </Button>
      <Button
        className="rounded-[6px] border border-slate-900 border-transparent hover:!border-stroke hover:bg-surface-tertiary !no-underline p-1 pl-2 pr-2"
        analyticsTrackingId="assistant-negative-feedback"
        size={BadgeSize.Medium}
        theme={ButtonTheme.Naked}
        icon={IconEnum.ThumbsDown}
        onClick={() => setShowNegativeFeedbackModal(true)}
      >
        Provide feedback
      </Button>
    </div>
  );
};

const FeedbackButtonsNonLatestResponse = ({
  apiClient,
  questionId,
  handleFeedbackGiven,
  setShowNegativeFeedbackModal,
}: {
  apiClient: ClientType;
  questionId: string;
  handleFeedbackGiven: () => void;
  setShowNegativeFeedbackModal: (value: boolean) => void;
}) => {
  return (
    <div className="flex flex-col opacity-0 group-hover:opacity-100 shadow rounded-full p-0.5">
      <Button
        title={""}
        className="!rounded-full hover:bg-surface-tertiary !w-6 !h-6 justify-center text-slate-600 hover:text-content-primary"
        analyticsTrackingId="assistant-positive-feedback"
        size={BadgeSize.Medium}
        theme={ButtonTheme.Unstyled}
        onClick={() => {
          sendPositiveFeedback(apiClient, questionId);
          handleFeedbackGiven();
        }}
        icon={IconEnum.ThumbsUp}
      />
      <Button
        title={""}
        className="!rounded-full hover:bg-surface-tertiary !w-6 !h-6 justify-center text-slate-600 hover:text-content-primary"
        analyticsTrackingId="assistant-negative-feedback"
        size={BadgeSize.Medium}
        theme={ButtonTheme.Unstyled}
        onClick={() => setShowNegativeFeedbackModal(true)}
        icon={IconEnum.ThumbsDown}
        iconProps={{ className: "transform scale-x-[-1]" }}
      />
    </div>
  );
};
