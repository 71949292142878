import {
  EngineScope,
  ManagementMeta,
  Resource,
  StepSlim,
  Trigger,
  Workflow,
} from "@incident-io/api";
import React, { createContext, useContext } from "react";

import { ClonedWorkflow } from "../common/types";
import { WorkflowMutations } from "./hooks/useMutationController";
import { WorkflowStepCallbacks } from "./hooks/useStepsController";
import { TriggerCallbacks } from "./hooks/useTriggerController";
import { WorkflowViewState } from "./hooks/useViewController";

type WorkflowsFormContextType = {
  viewState: WorkflowViewState;
  mutations: WorkflowMutations;
  deps: WorkflowsDeps;
  stepCallbacks: WorkflowStepCallbacks;
  triggerCallbacks: TriggerCallbacks;
};

// WorkflowsFormInfo is a grab-bag of other information about the form which doesn't
// neatly fit anywhere
type WorkflowsDeps = {
  scope: EngineScope;
  // trigger is repro'd from the TriggerCallbacks, but it's so useful it makes sense to include here.
  trigger: Trigger | undefined;
  steps: StepSlim[];
  resources: Resource[];
  isMainFormDirty: boolean;
  canEdit: boolean;
  isDraft: boolean;
  workflow: Workflow | ClonedWorkflow | undefined;
  management: ManagementMeta;
};

export const WorkflowsFormContext = createContext<
  Partial<WorkflowsFormContextType>
>({});

// WorkflowsFormProvider gives the contents of a workflow form access to the various mutations
// and view state that it needs to avoid prop-drilling hell.
export const WorkflowsFormProvider = ({
  content,
  children,
}: {
  content: WorkflowsFormContextType;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <WorkflowsFormContext.Provider value={content}>
      {children}
    </WorkflowsFormContext.Provider>
  );
};

export const useWorkflowsDeps = (): WorkflowsDeps => {
  const ctx = useContext(WorkflowsFormContext);
  if (ctx && ctx.deps) {
    return ctx.deps;
  }
  throw new Error(
    "useWorkflowsDeps must be used within a WorkflowsFormProvider",
  );
};
