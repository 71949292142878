/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BootstrapTeamManualEntry,
    BootstrapTeamManualEntryFromJSON,
    BootstrapTeamManualEntryFromJSONTyped,
    BootstrapTeamManualEntryToJSON,
} from './BootstrapTeamManualEntry';

/**
 * 
 * @export
 * @interface CatalogBootstrapTeamTypeRequestBody
 */
export interface CatalogBootstrapTeamTypeRequestBody {
    /**
     * The IDs of the catalog entries to create corresponding entries for in the new type
     * @type {Array<string>}
     * @memberof CatalogBootstrapTeamTypeRequestBody
     */
    catalog_entry_ids?: Array<string>;
    /**
     * The ID of the "source of truth" team catalog type that we are building the new type to reference
     * @type {string}
     * @memberof CatalogBootstrapTeamTypeRequestBody
     */
    catalog_type_id?: string;
    /**
     * The manual entries to create for the new team type
     * @type {Array<BootstrapTeamManualEntry>}
     * @memberof CatalogBootstrapTeamTypeRequestBody
     */
    manual_entries?: Array<BootstrapTeamManualEntry>;
}

export function CatalogBootstrapTeamTypeRequestBodyFromJSON(json: any): CatalogBootstrapTeamTypeRequestBody {
    return CatalogBootstrapTeamTypeRequestBodyFromJSONTyped(json, false);
}

export function CatalogBootstrapTeamTypeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogBootstrapTeamTypeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry_ids': !exists(json, 'catalog_entry_ids') ? undefined : json['catalog_entry_ids'],
        'catalog_type_id': !exists(json, 'catalog_type_id') ? undefined : json['catalog_type_id'],
        'manual_entries': !exists(json, 'manual_entries') ? undefined : ((json['manual_entries'] as Array<any>).map(BootstrapTeamManualEntryFromJSON)),
    };
}

export function CatalogBootstrapTeamTypeRequestBodyToJSON(value?: CatalogBootstrapTeamTypeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry_ids': value.catalog_entry_ids,
        'catalog_type_id': value.catalog_type_id,
        'manual_entries': value.manual_entries === undefined ? undefined : ((value.manual_entries as Array<any>).map(BootstrapTeamManualEntryToJSON)),
    };
}

