import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./RainbowContainer.module.scss";

export const RainbowContainer = ({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}): React.ReactElement => {
  // Don't put borders or rounding here, bring your own: this is just the nice background
  return <div className={tcx(styles.background, className)}>{children}</div>;
};
