/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthShowSlackUserTokenConfigResponseBody
 */
export interface AuthShowSlackUserTokenConfigResponseBody {
    /**
     * Slack authentication URI
     * @type {string}
     * @memberof AuthShowSlackUserTokenConfigResponseBody
     */
    slack_auth_uri: string;
}

export function AuthShowSlackUserTokenConfigResponseBodyFromJSON(json: any): AuthShowSlackUserTokenConfigResponseBody {
    return AuthShowSlackUserTokenConfigResponseBodyFromJSONTyped(json, false);
}

export function AuthShowSlackUserTokenConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthShowSlackUserTokenConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slack_auth_uri': json['slack_auth_uri'],
    };
}

export function AuthShowSlackUserTokenConfigResponseBodyToJSON(value?: AuthShowSlackUserTokenConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slack_auth_uri': value.slack_auth_uri,
    };
}

