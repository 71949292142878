import {
  EscalationPathNodePayload,
  EscalationPathNodeTypeEnum,
} from "@incident-io/api";
import _ from "lodash";
import { useContext, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { escalationPathFormDataNodesToPath } from "src/components/escalation-paths/common/marshall";
import { EscalationPathFormData } from "src/components/escalation-paths/common/types";
import { EscalationPathErrorContext } from "src/components/escalation-paths/create-edit/EscalationPathCreateEditDrawer";

export const useNodeFormErrors = ({
  formMethods,
  id,
  nodeType,
}: {
  formMethods: UseFormReturn<EscalationPathFormData>;
  id: string;
  nodeType: EscalationPathNodeTypeEnum;
}): string[] => {
  const formData = formMethods.watch();
  const fieldErrors = useContext(EscalationPathErrorContext);

  return useMemo(
    () => {
      const errorPaths = Object.keys(fieldErrors ?? {});

      let pathsPayload: EscalationPathNodePayload[];
      try {
        pathsPayload = escalationPathFormDataNodesToPath(formData);
      } catch (e) {
        // If we can't parse the payload, you've probably not finished editing the form yet,
        // we'll ignore those and assume that the onSubmit is waiting for them to be valid
        return [];
      }

      return errorPaths
        .map((errorPath): string | undefined => {
          // Find the node that this error points to
          const lastIndexOfNode = _.chain(EscalationPathNodeTypeEnum)
            .values()
            .map((nodeType) => errorPath.lastIndexOf(`.${nodeType}`))
            .max()
            .value();

          // If the error isn't relevant for the current node type, ignore it
          const lastNode = errorPath.slice(lastIndexOfNode + 1);
          if (!lastNode.startsWith(nodeType)) {
            return undefined;
          }

          // Also remove '.path' from the start, as we just have the payload for paths in particular
          let path = errorPath.slice(0, lastIndexOfNode);
          path = path.replace("path.", "");

          // Get node in the payload we'll have sent
          const node = _.get(pathsPayload, path) as
            | EscalationPathNodePayload
            | undefined;

          // If this node's ID matches the one we're looking for, return the error
          if (node && node.id === id) {
            return fieldErrors?.[errorPath] as string;
          }

          return undefined;
        })
        .filter(Boolean) as string[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldErrors, id, nodeType, formData],
  );
};
