import type { SVGProps } from "react";
import * as React from "react";
const SvgMagicWand = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16.478 4.358-.835-.835c-.66-.662-1.813-.661-2.475 0l-9.655 9.654c-.331.331-.513.77-.513 1.237 0 .467.182.907.513 1.237l.835.835c.331.331.77.513 1.238.513.468 0 .907-.182 1.237-.513l9.655-9.653c.331-.331.513-.77.513-1.237 0-.467-.182-.908-.513-1.238Zm-1.061 1.414-1.78 1.78-1.189-1.189 1.78-1.78a.252.252 0 0 1 .354 0l.836.836a.25.25 0 0 1 0 .353h-.001ZM8.242 4.492l-.946-.315-.316-.947c-.102-.306-.609-.306-.711 0l-.316.947-.946.315a.375.375 0 0 0 0 .712l.946.315.316.947a.374.374 0 0 0 .71 0l.316-.947.946-.315a.375.375 0 0 0 .001-.712ZM17.658 12.99l-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.421a.5.5 0 0 0 0 .948l1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0-.001-.948ZM10.25 3.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
    />
  </svg>
);
export default SvgMagicWand;
