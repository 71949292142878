/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogGenerateZipForTypesRequestBody
 */
export interface CatalogGenerateZipForTypesRequestBody {
    /**
     * The IDs of the catalog types to include in the zip
     * @type {Array<string>}
     * @memberof CatalogGenerateZipForTypesRequestBody
     */
    catalog_type_ids?: Array<string>;
}

export function CatalogGenerateZipForTypesRequestBodyFromJSON(json: any): CatalogGenerateZipForTypesRequestBody {
    return CatalogGenerateZipForTypesRequestBodyFromJSONTyped(json, false);
}

export function CatalogGenerateZipForTypesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogGenerateZipForTypesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_ids': !exists(json, 'catalog_type_ids') ? undefined : json['catalog_type_ids'],
    };
}

export function CatalogGenerateZipForTypesRequestBodyToJSON(value?: CatalogGenerateZipForTypesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_ids': value.catalog_type_ids,
    };
}

