import { IntegrationsListObject } from "@incident-shared/integrations";
import {
  Button,
  Callout,
  CalloutTheme,
  IconEnum,
  Link,
  Modal,
  ModalContent,
} from "@incident-ui";
import React from "react";

export const ConnectOutlookCalendarModal = ({
  integration,
  onClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}): React.ReactElement | null => {
  return (
    <Modal
      title={`Connect to ${integration.provider_name}`}
      isOpen
      analyticsTrackingId="connect-outlook-calendar-modal"
      onClose={onClose}
    >
      <ModalContent>
        <div className="flex flex-col space-y-3">
          <p className="text-sm text-slate-700">
            We&apos;re about to send you to Microsoft authentication where (if
            you have permission) you can set up the incident.io integration.
          </p>
          {/* TODO: If we redirect back here with an error, we'll want to handle that nicely */}
          <Callout theme={CalloutTheme.Warning}>
            You&apos;ll need to use a service account for this, as we&apos;ll be
            subscribing to all calendar events that this user is invited to.{" "}
            {/* TODO: RESP-7340 Update this link with more relevant documentation once we have it */}
            <Link
              to={`https://help.incident.io/en/articles/9399424-permissions-in-microsoft-teams`}
              className={"inline-block"}
              analyticsTrackingId={"connect-outlook-calendar-help-link"}
            >
              <p className="text-slate-800 font-normal mr-1 hover:underline">
                {`Learn more.`}
              </p>
            </Link>
          </Callout>
        </div>
        <div className="flex flex-row w-full justify-center pt-2">
          <Button
            analyticsTrackingId="login-with-micr"
            href={integration.add_integration_url}
            className="inline-flex items-center cursor-pointer"
            icon={IconEnum.Microsoft}
          >
            Sign in with Microsoft
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
