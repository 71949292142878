/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NudgesCreateRequestBody,
    NudgesCreateRequestBodyFromJSON,
    NudgesCreateRequestBodyToJSON,
    NudgesCreateResponseBody,
    NudgesCreateResponseBodyFromJSON,
    NudgesCreateResponseBodyToJSON,
    NudgesDisableResponseBody,
    NudgesDisableResponseBodyFromJSON,
    NudgesDisableResponseBodyToJSON,
    NudgesEnableResponseBody,
    NudgesEnableResponseBodyFromJSON,
    NudgesEnableResponseBodyToJSON,
    NudgesFixedConditionsRequestBody,
    NudgesFixedConditionsRequestBodyFromJSON,
    NudgesFixedConditionsRequestBodyToJSON,
    NudgesFixedConditionsResponseBody,
    NudgesFixedConditionsResponseBodyFromJSON,
    NudgesFixedConditionsResponseBodyToJSON,
    NudgesListAvailableNudgesResponseBody,
    NudgesListAvailableNudgesResponseBodyFromJSON,
    NudgesListAvailableNudgesResponseBodyToJSON,
    NudgesListNudgesResponseBody,
    NudgesListNudgesResponseBodyFromJSON,
    NudgesListNudgesResponseBodyToJSON,
    NudgesRenderPreviewRequestBody,
    NudgesRenderPreviewRequestBodyFromJSON,
    NudgesRenderPreviewRequestBodyToJSON,
    NudgesRenderPreviewResponseBody,
    NudgesRenderPreviewResponseBodyFromJSON,
    NudgesRenderPreviewResponseBodyToJSON,
    NudgesShowResponseBody,
    NudgesShowResponseBodyFromJSON,
    NudgesShowResponseBodyToJSON,
    NudgesUpdateRequestBody,
    NudgesUpdateRequestBodyFromJSON,
    NudgesUpdateRequestBodyToJSON,
    NudgesUpdateResponseBody,
    NudgesUpdateResponseBodyFromJSON,
    NudgesUpdateResponseBodyToJSON,
} from '../models';

export interface NudgesCreateRequest {
    createRequestBody: NudgesCreateRequestBody;
}

export interface NudgesDestroyRequest {
    id: string;
}

export interface NudgesDisableRequest {
    id: string;
}

export interface NudgesEnableRequest {
    id: string;
}

export interface NudgesFixedConditionsRequest {
    fixedConditionsRequestBody: NudgesFixedConditionsRequestBody;
}

export interface NudgesRenderPreviewRequest {
    renderPreviewRequestBody: NudgesRenderPreviewRequestBody;
}

export interface NudgesShowRequest {
    id: string;
}

export interface NudgesUpdateRequest {
    id: string;
    updateRequestBody: NudgesUpdateRequestBody;
}

/**
 * 
 */
export class NudgesApi extends runtime.BaseAPI {

    /**
     * Creates an incident nudge
     * Create Nudges
     */
    async nudgesCreateRaw(requestParameters: NudgesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling nudgesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/nudges`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NudgesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates an incident nudge
     * Create Nudges
     */
    async nudgesCreate(requestParameters: NudgesCreateRequest, initOverrides?: RequestInit): Promise<NudgesCreateResponseBody> {
        const response = await this.nudgesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing nudge
     * Destroy Nudges
     */
    async nudgesDestroyRaw(requestParameters: NudgesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling nudgesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/nudges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing nudge
     * Destroy Nudges
     */
    async nudgesDestroy(requestParameters: NudgesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.nudgesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Disable a nudge
     * Disable Nudges
     */
    async nudgesDisableRaw(requestParameters: NudgesDisableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesDisableResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling nudgesDisable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/nudges/{id}/actions/disable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesDisableResponseBodyFromJSON(jsonValue));
    }

    /**
     * Disable a nudge
     * Disable Nudges
     */
    async nudgesDisable(requestParameters: NudgesDisableRequest, initOverrides?: RequestInit): Promise<NudgesDisableResponseBody> {
        const response = await this.nudgesDisableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable a disabled nudge
     * Enable Nudges
     */
    async nudgesEnableRaw(requestParameters: NudgesEnableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesEnableResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling nudgesEnable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/nudges/{id}/actions/enable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesEnableResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enable a disabled nudge
     * Enable Nudges
     */
    async nudgesEnable(requestParameters: NudgesEnableRequest, initOverrides?: RequestInit): Promise<NudgesEnableResponseBody> {
        const response = await this.nudgesEnableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Render the fixed conditions for a nudge based on provided configuration
     * FixedConditions Nudges
     */
    async nudgesFixedConditionsRaw(requestParameters: NudgesFixedConditionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesFixedConditionsResponseBody>> {
        if (requestParameters.fixedConditionsRequestBody === null || requestParameters.fixedConditionsRequestBody === undefined) {
            throw new runtime.RequiredError('fixedConditionsRequestBody','Required parameter requestParameters.fixedConditionsRequestBody was null or undefined when calling nudgesFixedConditions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/nudges/fixed_conditions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NudgesFixedConditionsRequestBodyToJSON(requestParameters.fixedConditionsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesFixedConditionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Render the fixed conditions for a nudge based on provided configuration
     * FixedConditions Nudges
     */
    async nudgesFixedConditions(requestParameters: NudgesFixedConditionsRequest, initOverrides?: RequestInit): Promise<NudgesFixedConditionsResponseBody> {
        const response = await this.nudgesFixedConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all available nudges from the registry
     * ListAvailableNudges Nudges
     */
    async nudgesListAvailableNudgesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesListAvailableNudgesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/nudges/available_nudges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesListAvailableNudgesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available nudges from the registry
     * ListAvailableNudges Nudges
     */
    async nudgesListAvailableNudges(initOverrides?: RequestInit): Promise<NudgesListAvailableNudgesResponseBody> {
        const response = await this.nudgesListAvailableNudgesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all nudges
     * ListNudges Nudges
     */
    async nudgesListNudgesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesListNudgesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/nudges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesListNudgesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all nudges
     * ListNudges Nudges
     */
    async nudgesListNudges(initOverrides?: RequestInit): Promise<NudgesListNudgesResponseBody> {
        const response = await this.nudgesListNudgesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Preview a nudge based on provided configuration
     * RenderPreview Nudges
     */
    async nudgesRenderPreviewRaw(requestParameters: NudgesRenderPreviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesRenderPreviewResponseBody>> {
        if (requestParameters.renderPreviewRequestBody === null || requestParameters.renderPreviewRequestBody === undefined) {
            throw new runtime.RequiredError('renderPreviewRequestBody','Required parameter requestParameters.renderPreviewRequestBody was null or undefined when calling nudgesRenderPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/nudges/render_preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NudgesRenderPreviewRequestBodyToJSON(requestParameters.renderPreviewRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesRenderPreviewResponseBodyFromJSON(jsonValue));
    }

    /**
     * Preview a nudge based on provided configuration
     * RenderPreview Nudges
     */
    async nudgesRenderPreview(requestParameters: NudgesRenderPreviewRequest, initOverrides?: RequestInit): Promise<NudgesRenderPreviewResponseBody> {
        const response = await this.nudgesRenderPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single nudge
     * Show Nudges
     */
    async nudgesShowRaw(requestParameters: NudgesShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling nudgesShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/nudges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single nudge
     * Show Nudges
     */
    async nudgesShow(requestParameters: NudgesShowRequest, initOverrides?: RequestInit): Promise<NudgesShowResponseBody> {
        const response = await this.nudgesShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing nudge
     * Update Nudges
     */
    async nudgesUpdateRaw(requestParameters: NudgesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NudgesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling nudgesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling nudgesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/nudges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NudgesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NudgesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing nudge
     * Update Nudges
     */
    async nudgesUpdate(requestParameters: NudgesUpdateRequest, initOverrides?: RequestInit): Promise<NudgesUpdateResponseBody> {
        const response = await this.nudgesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
