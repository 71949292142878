import type { SVGProps } from "react";
import * as React from "react";
const SvgPrtg = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 166.446 130.297"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M106.062 69.168a83.196 83.196 0 0 0-56.58 22.273l14.058 15.972a58.038 58.038 0 0 1 31.928-9.572c18.163.001 34.757 8.441 45.517 22.037 8.307-9.191 16.743-18.52 23.854-26.386a83.196 83.196 0 0 0-58.777-24.324Z"
        style={{
          display: "inline",
          fill: "#f99e1c",
          fillOpacity: 1,
          strokeWidth: 0.579967,
          strokeLinecap: "round",
        }}
        transform="translate(-22.812 -69.168)"
      />
      <path
        d="m51.266 122.989 75.49 45.426-11.581 10.248z"
        style={{
          display: "inline",
          fill: "#00397e",
          fillOpacity: 1,
          strokeWidth: 0.529167,
          strokeLinecap: "round",
        }}
        transform="translate(-22.812 -69.168)"
      />
      <path
        d="m41.29 184.952-35.564.02c-2.142 12.892-8.613 24.66-18.29 33.441a58.043 58.043 0 0 1-23.945 13.03l6.203 31.806a83.128 83.128 0 0 0 44.338-20.686 83.185 83.185 0 0 0 27.262-57.61z"
        style={{
          display: "inline",
          opacity: 1,
          fill: "#ee0f6a",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.529,
          strokeLinecap: "round",
          strokeDasharray: "none",
        }}
        transform="rotate(-47.853 -89.352 -8.877)"
      />
      <path
        d="M49.493 91.451c-16.976 15.728-26.66 37.78-26.681 60.922.004 16.815 5.165 33.233 14.686 47.092l12.026-8.161c-7.83-10.147-12.138-22.6-12.147-35.417a58.045 58.045 0 0 1 26.173-48.465z"
        style={{
          display: "inline",
          fill: "#b4cc38",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.529,
          strokeLinecap: "round",
          strokeDasharray: "none",
        }}
        transform="translate(-22.812 -69.168)"
      />
    </g>
  </svg>
);
export default SvgPrtg;
