import type { SVGProps } from "react";
import * as React from "react";
const SvgSidebarLeftHide = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 3H4.75A2.752 2.752 0 0 0 2 5.75v8.5A2.752 2.752 0 0 0 4.75 17h10.5A2.752 2.752 0 0 0 18 14.25v-8.5A2.752 2.752 0 0 0 15.25 3ZM6.5 13.25a.75.75 0 0 1-1.5 0v-6.5a.75.75 0 0 1 1.5 0v6.5Zm7.75-2.5h-4.189l.97.97a.75.75 0 0 1-1.06 1.061l-2.25-2.25a.75.75 0 0 1 0-1.061l2.25-2.25a.75.75 0 1 1 1.061 1.061l-.97.97h4.189a.75.75 0 0 1 0 1.5l-.001-.001Z"
    />
  </svg>
);
export default SvgSidebarLeftHide;
