/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportChannel,
    ReportChannelFromJSON,
    ReportChannelFromJSONTyped,
    ReportChannelToJSON,
} from './ReportChannel';

/**
 * 
 * @export
 * @interface InsightsCreateReportScheduleRequestBody
 */
export interface InsightsCreateReportScheduleRequestBody {
    /**
     * The channels of communication that the report will be sent to
     * @type {Array<ReportChannel>}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    channels: Array<ReportChannel>;
    /**
     * The country code of the timezone the report is scheduled in
     * @type {string}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    country_code: string;
    /**
     * ID of the insights dashboard to include in the report
     * @type {string}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    dashboard_id: string;
    /**
     * The day of the month the report should be sent
     * @type {number}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    day_of_month?: number;
    /**
     * The day of the week the report should be sent
     * @type {string}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    day_of_week?: InsightsCreateReportScheduleRequestBodyDayOfWeekEnum;
    /**
     * The hour of the day the report should be sent
     * @type {number}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    hour: number;
    /**
     * How often the report should be sent
     * @type {string}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    interval: InsightsCreateReportScheduleRequestBodyIntervalEnum;
    /**
     * The timezone the report is scheduled in
     * @type {string}
     * @memberof InsightsCreateReportScheduleRequestBody
     */
    time_zone: string;
}

/**
* @export
* @enum {string}
*/
export enum InsightsCreateReportScheduleRequestBodyDayOfWeekEnum {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday'
}/**
* @export
* @enum {string}
*/
export enum InsightsCreateReportScheduleRequestBodyIntervalEnum {
    Daily = 'daily',
    DailyOnWeekdays = 'daily_on_weekdays',
    Weekly = 'weekly',
    Monthly = 'monthly',
    MonthlyOnWeekdays = 'monthly_on_weekdays'
}

export function InsightsCreateReportScheduleRequestBodyFromJSON(json: any): InsightsCreateReportScheduleRequestBody {
    return InsightsCreateReportScheduleRequestBodyFromJSONTyped(json, false);
}

export function InsightsCreateReportScheduleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsCreateReportScheduleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': ((json['channels'] as Array<any>).map(ReportChannelFromJSON)),
        'country_code': json['country_code'],
        'dashboard_id': json['dashboard_id'],
        'day_of_month': !exists(json, 'day_of_month') ? undefined : json['day_of_month'],
        'day_of_week': !exists(json, 'day_of_week') ? undefined : json['day_of_week'],
        'hour': json['hour'],
        'interval': json['interval'],
        'time_zone': json['time_zone'],
    };
}

export function InsightsCreateReportScheduleRequestBodyToJSON(value?: InsightsCreateReportScheduleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': ((value.channels as Array<any>).map(ReportChannelToJSON)),
        'country_code': value.country_code,
        'dashboard_id': value.dashboard_id,
        'day_of_month': value.day_of_month,
        'day_of_week': value.day_of_week,
        'hour': value.hour,
        'interval': value.interval,
        'time_zone': value.time_zone,
    };
}

