import type { SVGProps } from "react";
import * as React from "react";
const SvgFingerPoint = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.738 3.456v-.001l-.004-.005a1.938 1.938 0 0 0-.706-.707l-2.55-1.48a1.955 1.955 0 0 0-1.957 0l-2.55 1.479a1.945 1.945 0 0 0-.706.707l-.004.005A1.957 1.957 0 0 0 1 4.429v2.967c0 .693.372 1.339.972 1.687l2.55 1.479c.298.172.636.263.979.263a.75.75 0 0 0 .75-.75V6.344l2.25-1.305v.21a.75.75 0 0 0 1.5 0v-.821c0-.347-.093-.682-.261-.974l-.002.002Zm-7.014 4.33a.451.451 0 0 1-.225-.39V5.039l2.25 1.305V8.96L2.724 7.786ZM17.553 11.604c-.989-.756-3.222-.922-5.323-.423l-1.111-2.363c-.371-.789-1.252-1.271-2.091-1.035a1.752 1.752 0 0 0-1.122 2.43l2.147 4.576-1.67-.307c-.729-.134-1.512.149-1.888.788a1.723 1.723 0 0 0 .897 2.512l2.109.743a8.392 8.392 0 0 0 2.786.476h2.617c2.635 0 3.922-2.359 3.791-4.28-.111-1.638-.495-2.621-1.144-3.116l.002-.001Z"
    />
  </svg>
);
export default SvgFingerPoint;
