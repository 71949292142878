import type { SVGProps } from "react";
import * as React from "react";
const SvgBolt = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.994 8.657a1.248 1.248 0 0 0-1.1-.657h-3.97l.753-5.83a.993.993 0 0 0-.643-1.065.996.996 0 0 0-1.184.387l-5.644 8.57A1.249 1.249 0 0 0 5.25 12h3.969l-.752 5.83a.993.993 0 0 0 .643 1.065.995.995 0 0 0 1.184-.386l5.645-8.572c.253-.384.274-.875.056-1.28h-.001Z"
    />
  </svg>
);
export default SvgBolt;
