import { differenceInSeconds } from "date-fns";
import { sortBy } from "lodash";
import { reporterMessage as sourceMessage } from "src/components/legacy/incident/helpers";
import {
  CustomFieldEntry,
  CustomFieldTypeInfoFieldTypeEnum,
  FollowUp,
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  Settings,
  Severity,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

import { SerializableObject, SerializableValue } from "./csv";

// takes an Incident object and returns an object ready to be serialized into a CSV
export const presentIncidentObject = (
  settings: Settings,
  incident: Incident,
): SerializableObject => {
  const output: SerializableObject = {
    ID: incident.external_id,
    Name: incident.name,
  };

  const addToOutput = (originalKey: string, value: SerializableValue) => {
    let finalKey = originalKey;
    for (let i = 1; i <= 9; i++) {
      if (!(finalKey in output)) {
        break;
      }
      finalKey = `${originalKey} (${i})`;
    }

    output[finalKey] = value;
  };

  if (settings.misc.incident_types_enabled) {
    addToOutput("Incident Type", incident.incident_type?.name);
  }

  addToOutput("Status", incident.incident_status.name);
  addToOutput("Severity", severityNameOrFallback(incident.severity));
  addToOutput("Summary", incident.summary?.markdown);

  incident.incident_role_assignments.forEach(({ role, assignee }) => {
    addToOutput(role.name, assignee ? assignee.name : "");
  });

  sortBy(
    incident.custom_field_entries,
    ({ custom_field }) => custom_field.rank,
  ).forEach((entry: CustomFieldEntry) => {
    addToOutput(entry.custom_field.name, presentCustomFieldEntry(entry));
  });

  // put these fields after the custom roles.
  addToOutput("Post-Mortem Document", incident.postmortem_document_url);
  addToOutput("Created At", incident.created_at);

  sortBy(
    incident.incident_timestamps,
    ({ timestamp }) => timestamp.rank,
  ).forEach((entry) => {
    addToOutput(entry.timestamp.name, entry.value?.value || "");
  });

  sortBy(
    incident.duration_metrics,
    ({ duration_metric }) => duration_metric.rank,
  ).forEach((entry) => {
    addToOutput(entry.duration_metric.name, entry.value_seconds || "");
  });

  addToOutput("Updated At", incident.updated_at);

  // If the org has set up incident external issue references, include them
  if (settings.misc.incident_external_tickets_enabled) {
    addToOutput(
      "Jira ticket",
      incident.external_issue_reference?.issue_permalink,
    );
  }

  addToOutput("Creator Alert ID", incident.creator_alert_id || "");
  addToOutput("Visibility", incident.visibility);

  addToOutput("Workload Total (minutes)", incident.workload_minutes_total);
  addToOutput(
    "Workload In Working Hours (minutes)",
    incident.workload_minutes_working,
  );
  addToOutput(
    "Workload In Late Hours (minutes)",
    incident.workload_minutes_late,
  );
  addToOutput(
    "Workload In Sleeping Hours (minutes)",
    incident.workload_minutes_sleeping,
  );

  addToOutput(
    "Slack channel name",
    incident.slack_channel_name ? `#${incident.slack_channel_name}` : "",
  );
  addToOutput("Slack channel link", incident.slack_channel_url ?? "");
  addToOutput("Source", sourceMessage(incident));

  return output;
};

const presentStatus = (status: string) => {
  if (status === "outstanding") {
    return "open";
  }
  return status;
};

// takes an IncidentObject and FollowUp and returns an object ready to be serialized into a CSV
export const presentFollowUp = (
  followup: FollowUp,
  incident?: Incident,
): SerializableObject | null => {
  if (!incident) {
    console.error(
      "not exporting action as we can't find the incident",
      followup,
    );
    return null;
  }
  let output: SerializableObject = {
    ID: followup.id,
    Title: followup.title,
    Description: followup.description,
    Status: presentStatus(followup.status),
    Priority: followup.priority?.name ? followup.priority.name : "No priority",
    "Created By": followup.creator?.user?.name,
    "Assigned To": followup.assignee?.name,
    "External Issue Tracker": followup.external_issue_reference?.provider,
    "External Issue Tracker ID": followup.external_issue_reference?.issue_name,
    "External Issue Tracker URL":
      followup.external_issue_reference?.issue_permalink,
    "Created At": followup.created_at,
    "Completed At": followup.completed_at,
    "Updated At": followup.updated_at,
  };

  // now add all the incident data on the end
  output = {
    ...output,
    "Incident Name": incident.name,
    "Incident ID": incident.external_id,
    "Incident Status": incident.incident_status.name,
    "Incident Summary": incident.summary?.markdown,
    "Incident Severity": severityNameOrFallback(incident.severity),
  };

  const addToOutput = (originalKey: string, value: SerializableValue) => {
    let finalKey = originalKey;
    for (let i = 1; i <= 9; i++) {
      if (!(finalKey in output)) {
        break;
      }
      finalKey = `${originalKey} (${i})`;
    }

    output[finalKey] = value;
  };

  sortBy(
    incident.custom_field_entries,
    ({ custom_field }) => custom_field.rank,
  ).forEach((entry: CustomFieldEntry) => {
    addToOutput(entry.custom_field.name, presentCustomFieldEntry(entry));
  });

  addToOutput(
    "Incident Post-Mortem Document",
    incident.postmortem_document_url,
  );
  addToOutput("Incident Created At", incident.created_at);

  incident.incident_timestamps?.forEach(({ timestamp, value }) => {
    addToOutput(`Incident ${timestamp.name}`, value?.value || "");
  });

  incident.duration_metrics?.forEach((entry) => {
    addToOutput(
      `Incident ${entry.duration_metric.name} (seconds)`,
      entry.value_seconds || "",
    );
  });

  return output;
};

const presentCustomFieldEntry = (entry: CustomFieldEntry): string => {
  switch (entry.custom_field.field_type) {
    case CustomFieldTypeInfoFieldTypeEnum.SingleSelect:
    case CustomFieldTypeInfoFieldTypeEnum.MultiSelect:
      if (entry.custom_field.catalog_info) {
        return entry.values
          .map(({ value_catalog_entry }) => value_catalog_entry?.name)
          .join(", ");
      }
      return entry.values
        .map(({ value_option }) => value_option?.value)
        .join(", ");

    case CustomFieldTypeInfoFieldTypeEnum.Text:
      return entry.values.map(({ value_text }) => value_text).join(", ");

    case CustomFieldTypeInfoFieldTypeEnum.Link:
      return entry.values.map(({ value_link }) => value_link).join(", ");

    case CustomFieldTypeInfoFieldTypeEnum.Numeric:
      return entry.values.map(({ value_numeric }) => value_numeric).join(", ");

    default:
      assertUnreachable(entry.custom_field.field_type);
      return "";
  }
};

const ONGOING_STATUS_CATEGORIES = [
  IncidentStatusCategoryEnum.Triage,
  IncidentStatusCategoryEnum.Active,
  IncidentStatusCategoryEnum.Paused,
];

export const isOngoing = (
  incident: Pick<Incident, "incident_status">,
): boolean =>
  ONGOING_STATUS_CATEGORIES.includes(incident.incident_status.category);

export const isTriage = (incidentStatus: IncidentStatus): boolean =>
  incidentStatus.category === IncidentStatusCategoryEnum.Triage;

export const isActive = (incidentStatus: IncidentStatus): boolean =>
  incidentStatus.category === IncidentStatusCategoryEnum.Active;

// This is kinda a bit vile and maybe should be rethought. It defines "duration"
// as:
// - if the incident is ongoing (triage/active), the time since creation
// - if the incident is not ongoing (canceled/merged/declined/post-inc/closed),
//   it uses the configured 'incident duration' metric returned from the API
//
// This means that occasionally, if a resolved incident is missing some
// timestamps, we won't return a duration at all here. This is a little awkward,
// and ideally we'd show a little CTA saying something like "timestamps missing!
// click to add!", but it's better to show nothing than something misleading.
export const getDurationInSeconds = (
  incident: Pick<
    Incident,
    "incident_status" | "duration_seconds" | "created_at"
  >,
): number | undefined => {
  if (incident.duration_seconds !== undefined) {
    return incident.duration_seconds;
  }

  if (isOngoing(incident)) {
    return differenceInSeconds(new Date(), incident.created_at);
  }

  return undefined;
};

export function severityNameOrFallback(severity: Severity | undefined) {
  return severity?.name ?? "Unset";
}
