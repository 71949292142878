import { EngineFormElement } from "@incident-shared/engine";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import { ErrorMessage } from "@incident-ui";
import {
  Callout,
  CalloutTheme,
  LoadingModal,
  ModalFooter,
  Spinner,
  TabModalPane,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { compact, uniqBy } from "lodash";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  EngineParamBindingPayload,
  FieldValue,
  FollowUp,
  FollowUpsConnectExternalIssueRequestBodyProviderEnum,
  FollowUpsExportRequestBodyProviderEnum,
  Incident,
} from "src/contexts/ClientContext";
import {
  AdditionalResourcesProvider,
  useAllResources,
} from "src/hooks/useResources";
import { useAPIMutation } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import {
  HasFieldValues,
  useClearDependencies,
  useIssueCreationFields,
} from "./hooks";
import {
  IssueTemplateContextEnum,
  useAllIssueTemplates,
} from "./useAllTemplates";
import { ISSUE_TRACKER_PROVIDERS, IssueTrackerProviderEnum } from "./utils";

type FormType = {
  url: string;
  issue_template_id?: string;
  field_values: Record<string, EngineParamBindingPayload>;
};

export const ExportToIssueTrackerModal = ({
  provider,
  followUp,
  isPrivateIncident,
  updateCallback,
  onClose,
}: {
  provider: IssueTrackerProviderEnum;
  followUp: FollowUp;
  onClose: () => void;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
  incident: Incident;
  isPrivateIncident: boolean;
  allowReferences?: boolean;
}) => {
  const {
    allTemplates,
    isLoading: templatesLoading,
    error: templatesError,
  } = useAllIssueTemplates(IssueTemplateContextEnum.FollowUp);

  const formMethods = useForm<FormType>({
    defaultValues: {
      url: "",
      field_values: {},
    },
  });

  const issueTemplateId = formMethods.watch("issue_template_id");

  const {
    data,
    isValidating,
    error: listFieldsError,
    reactKeyForField,
  } = useIssueCreationFields({
    provider,
    context: IssueTemplateContextEnum.FollowUp,
    issueTemplateId,
    followUpId: followUp.id,
    formMethods: formMethods as unknown as UseFormReturn<HasFieldValues>,
  });
  // When a field's value changes, we need to clear the value in any fields that
  // depend on it.
  useClearDependencies(
    formMethods as unknown as UseFormReturn<HasFieldValues>,
    data.fields,
  );

  const {
    trigger: onSubmit,
    isMutating,
    genericError: genericErrorFromSubmit,
  } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, request) => {
      const { follow_up: newFollowUp } =
        tab === "connect"
          ? await apiClient.followUpsConnectExternalIssue({
              id: followUp.id,
              connectExternalIssueRequestBody: {
                provider:
                  provider as unknown as FollowUpsConnectExternalIssueRequestBodyProviderEnum,
                url: request.url,
              },
            })
          : await apiClient.followUpsExport({
              id: followUp.id,
              exportRequestBody: {
                provider:
                  provider as unknown as FollowUpsExportRequestBodyProviderEnum,
                field_values: fieldValuesToPayload(request.field_values),
              },
            });

      updateCallback(newFollowUp);
    },
    {
      onSuccess: () => onClose(),
      setError: formMethods.setError,
    },
  );

  const unsupportedRequiredFields = data.unsupported_required_fields ?? [];

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const allResources = useMemo(
    () => uniqBy([...resources, ...data.resources], (res) => res.type),
    [resources, data.resources],
  );

  const providerName = INTEGRATION_CONFIGS[provider].label;
  const { issueNoun, issueUrlExample } = ISSUE_TRACKER_PROVIDERS[provider];
  const title = `${providerName}: Create or link to existing issue`;

  const [tab, setTab] = useState<"add" | "connect">("add");

  if (templatesLoading || resourcesLoading || data.fields.length === 0) {
    return <LoadingModal title={title} onClose={onClose} />;
  }

  const error = templatesError || resourcesError || listFieldsError;
  if (error) {
    return <ErrorModal error={error} onClose={onClose} />;
  }

  const canSubmit =
    tab === "connect"
      ? true
      : data.fields_complete && unsupportedRequiredFields.length === 0;

  const disabledTooltipContent = !data.fields_complete
    ? `This ${issueNoun} isn't complete yet: please fill in all required fields`
    : unsupportedRequiredFields.length > 0
    ? `This ${issueNoun} requires fields that we don't support yet`
    : undefined;

  const unsupportedFieldNames = joinSpansWithCommasAndConnectorWord(
    unsupportedRequiredFields,
  );

  const genericError =
    genericErrorFromSubmit ??
    (unsupportedRequiredFields.length > 0 ? (
      <>
        {providerName} {pluralize(issueNoun)} of this type required fields that
        we don&rsquo;t yet support: {unsupportedFieldNames}
      </>
    ) : undefined);

  const usableTemplates = allTemplates.filter(
    (template) => template.provider === provider,
  );

  const fieldsToShow = data.fields.filter((field) => {
    if (field.is_hidden) return false;

    // In general, we only want to ask about fields that are required, to avoid
    // a really long form. Some fields are tagged as important/useful enough to
    // show even when optional though!
    if (field.param.optional) return field.rendered_when_optional;

    return true;
  });

  return (
    <AdditionalResourcesProvider additionalResources={data.resources}>
      <Form.TabModal
        tabs={[
          { id: "add", label: "Create new" },
          { id: "connect", label: "Connect to existing" },
        ]}
        onTabChange={(tabId: string) => setTab(tabId as "add" | "connect")}
        formMethods={formMethods}
        onSubmit={onSubmit}
        title={title}
        analyticsTrackingId={null}
        onClose={onClose}
        footer={
          <ModalFooter
            onClose={onClose}
            confirmButtonType="submit"
            confirmButtonText={tab === "connect" ? "Connect" : "Create"}
            disabled={!canSubmit}
            disabledTooltipContent={disabledTooltipContent}
            saving={isMutating}
          />
        }
        saving={isMutating}
        contentClassName="relative"
      >
        {isPrivateIncident && (
          <Callout theme={CalloutTheme.Warning}>
            This is a private incident. This issue will be visible to anyone
            with access to your {providerName} project.
          </Callout>
        )}
        <TabModalPane tabId="add" className="space-y-4 p-2">
          <Callout theme={CalloutTheme.Info} className="text-sm">
            Once created, we&apos;ll automatically sync any changes in{" "}
            {providerName} back to this follow-up.
          </Callout>
          {isValidating && <Spinner className="absolute right-4" />}
          <ErrorMessage message={genericError} />
          {usableTemplates.length > 0 && (
            <StaticSingleSelectV2
              name="issue_template_id"
              label="Use a template"
              formMethods={formMethods}
              isClearable
              required={false}
              options={usableTemplates.map((template) => ({
                value: template.id,
                label: template.name,
              }))}
              // When the template changes, clear all the field values from the
              // form, so that the template is used for all fields (including
              // those touched by the user!)
              onValueChange={() =>
                formMethods.resetField("field_values", { defaultValue: {} })
              }
            />
          )}
          {fieldsToShow.map((field) => (
            <EngineFormElement
              // We use a key here to force the component to un- and re-mount when
              // either its name changes (which means it's a totally different
              // field), or when its _type_ changes.
              //
              // The type is necessary because `react-select` (which powers our
              // dropdowns) caches typeahead options for as long as it remains
              // mounted. When the type changes, the typeahead options will likely
              // change, meaning that we need to remount the dropdown to bust that
              // cache.
              //
              // The default value is necessary because our TemplatedText input
              // component doesn't look at changes in its `value` in the form
              // after it mounts, so when the default value changes we need to
              // remount the component to force it to re-check the value in the
              // form state.
              key={reactKeyForField(field)}
              name={`field_values.${field.param.name}`}
              label={field.param.label}
              showPlaceholder={true}
              resourceType={field.param.type}
              required={
                // Only require fields when we're showing the connect modal,
                // otherwise they also block you from connecting to an existing
                // issue in a very confusing way that you can't see!
                tab === "add" && !field.param.optional
              }
              resources={allResources}
              mode="plain_input"
              array={field.param.array}
            />
          ))}
        </TabModalPane>

        <TabModalPane tabId="connect" className="space-y-4 p-2 overflow-y-auto">
          <p className="text-sm text-content-primary">
            Connect this follow-up to an existing {providerName} {issueNoun} by
            pasting in the {issueNoun} URL.
          </p>
          <InputV2
            formMethods={formMethods}
            name="url"
            label={`Existing ${issueNoun} URL`}
            required={tab === "connect" ? "Please enter a URL" : undefined}
            placeholder={issueUrlExample}
          />
        </TabModalPane>
      </Form.TabModal>
    </AdditionalResourcesProvider>
  );
};

// fieldValuesToPayload converts the field_values Record to an array of FieldValue
function fieldValuesToPayload(
  record: FormType["field_values"],
): Array<FieldValue> {
  const result: Array<FieldValue> = [];

  for (const [key, value] of Object.entries(record)) {
    const item: FieldValue = {
      key,
      // We don't allow references in this form because we only ever show resolved values. For example,
      // if a field has a default reference of `followup.title`, that will get resolved to the actual
      // followup title when the form is rendered. Why use engine param bindings in this form then?
      // Because we want all the free stuff that `EngineFormElement` gets us!
      value: value?.value?.literal,
      array_value: value?.array_value
        ? compact(value.array_value.map((v) => v?.literal))
        : undefined,
    };
    result.push(item);
  }

  return result;
}
