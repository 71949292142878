/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HolidayPublicEntry,
    HolidayPublicEntryFromJSON,
    HolidayPublicEntryFromJSONTyped,
    HolidayPublicEntryToJSON,
} from './HolidayPublicEntry';
import {
    HolidayUserEntry,
    HolidayUserEntryFromJSON,
    HolidayUserEntryFromJSONTyped,
    HolidayUserEntryToJSON,
} from './HolidayUserEntry';

/**
 * 
 * @export
 * @interface SchedulesListHolidayEntriesResponseBody
 */
export interface SchedulesListHolidayEntriesResponseBody {
    /**
     * 
     * @type {Array<HolidayPublicEntry>}
     * @memberof SchedulesListHolidayEntriesResponseBody
     */
    holiday_public_entries: Array<HolidayPublicEntry>;
    /**
     * 
     * @type {Array<HolidayUserEntry>}
     * @memberof SchedulesListHolidayEntriesResponseBody
     */
    holiday_user_entries: Array<HolidayUserEntry>;
}

export function SchedulesListHolidayEntriesResponseBodyFromJSON(json: any): SchedulesListHolidayEntriesResponseBody {
    return SchedulesListHolidayEntriesResponseBodyFromJSONTyped(json, false);
}

export function SchedulesListHolidayEntriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesListHolidayEntriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holiday_public_entries': ((json['holiday_public_entries'] as Array<any>).map(HolidayPublicEntryFromJSON)),
        'holiday_user_entries': ((json['holiday_user_entries'] as Array<any>).map(HolidayUserEntryFromJSON)),
    };
}

export function SchedulesListHolidayEntriesResponseBodyToJSON(value?: SchedulesListHolidayEntriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'holiday_public_entries': ((value.holiday_public_entries as Array<any>).map(HolidayPublicEntryToJSON)),
        'holiday_user_entries': ((value.holiday_user_entries as Array<any>).map(HolidayUserEntryToJSON)),
    };
}

