import type { SVGProps } from "react";
import * as React from "react";
const SvgFiles = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.75 16h-1A2.752 2.752 0 0 1 3 13.25v-8A2.752 2.752 0 0 1 5.75 2.5h5a2.752 2.752 0 0 1 2.75 2.75v1.052a.75.75 0 0 1-1.5 0V5.25C12 4.561 11.439 4 10.75 4h-5c-.689 0-1.25.561-1.25 1.25v8c0 .689.561 1.25 1.25 1.25h1a.75.75 0 0 1 0 1.5Z"
    />
    <path
      fill="currentColor"
      d="m16.987 8.927-2.914-2.914a1.738 1.738 0 0 0-1.237-.513H8.75A2.752 2.752 0 0 0 6 8.25v8A2.752 2.752 0 0 0 8.75 19h6a2.752 2.752 0 0 0 2.75-2.75v-6.086c0-.467-.182-.907-.513-1.237Zm-1.053 1.068-.002.005H13.5a.501.501 0 0 1-.5-.5V7.068l.005-.002 2.929 2.929Z"
    />
  </svg>
);
export default SvgFiles;
