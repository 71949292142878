/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PagerDutyConfig
 */
export interface PagerDutyConfig {
    /**
     * The ID of the bot-user account that we use to create incidents in PD.
     * @type {string}
     * @memberof PagerDutyConfig
     */
    bot_user_id?: string;
    /**
     * Whether or not this config has an access token
     * @type {boolean}
     * @memberof PagerDutyConfig
     */
    has_access_token: boolean;
    /**
     * False for OAuth connections, where the token has an email connected.
     * @type {boolean}
     * @memberof PagerDutyConfig
     */
    need_bot_user_id: boolean;
    /**
     * Unique identifier of the corresponding organisation
     * @type {string}
     * @memberof PagerDutyConfig
     */
    organisation_id: string;
}

export function PagerDutyConfigFromJSON(json: any): PagerDutyConfig {
    return PagerDutyConfigFromJSONTyped(json, false);
}

export function PagerDutyConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagerDutyConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bot_user_id': !exists(json, 'bot_user_id') ? undefined : json['bot_user_id'],
        'has_access_token': json['has_access_token'],
        'need_bot_user_id': json['need_bot_user_id'],
        'organisation_id': json['organisation_id'],
    };
}

export function PagerDutyConfigToJSON(value?: PagerDutyConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bot_user_id': value.bot_user_id,
        'has_access_token': value.has_access_token,
        'need_bot_user_id': value.need_bot_user_id,
        'organisation_id': value.organisation_id,
    };
}

