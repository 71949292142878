import { Product } from "@incident-shared/billing";
import { Button, ButtonTheme } from "@incident-ui";

import levelUpResponse from "./level-up-response-graphic.svg";
const formattedProduct = (product: Product): string => {
  switch (product) {
    case Product.Response:
      return "Response";
    case Product.OnCall:
      return "On-call";
    default:
      throw new Error(`Unknown product: ${product}`);
  }
};

export enum Plurality {
  Singlular,
  Plural,
}

export const ProductUpsellNotice = ({
  featureName,
  plurality,
  requiredProduct,
}: {
  featureName: string;
  plurality: Plurality;
  requiredProduct: Product;
}): React.ReactElement => {
  return (
    <div className="bg-gradient-to-b from-[#FDDFD9] to-[#FEEFEC] rounded-xl flex items-center justify-between p-4">
      <div className="flex flex-col items-start justify-between p-4">
        <p className="text-base-bold text-alarmalade-800 text-pretty max-w-[500px]">
          {featureName} {plurality === Plurality.Plural ? "are" : "is"} only
          available with {formattedProduct(requiredProduct)}.
        </p>
        <p className="text-sm-normal text-alarmalade-700 max-w-[420px]">
          Focus on the fixing the problem with powerful automations and
          insights, supercharged by AI.{" "}
        </p>
        <div className="flex gap-2 mt-4">
          <Button
            analyticsTrackingId="request-response-access"
            theme={ButtonTheme.Primary}
          >
            Request access
          </Button>
          <Button
            analyticsTrackingId="learn-more-response"
            className="text-alarmalade-700 hover:text-alarmalade-800 text-sm-med"
            theme={ButtonTheme.Naked}
          >
            Learn more
          </Button>
        </div>
      </div>
      <div>
        <img
          src={levelUpResponse}
          alt="Level up your incident management with Response"
          className="h-[97px] w-[289px]"
        />
      </div>
    </div>
  );
};
