/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleConfigPayload,
    ScheduleConfigPayloadFromJSON,
    ScheduleConfigPayloadFromJSONTyped,
    ScheduleConfigPayloadToJSON,
} from './ScheduleConfigPayload';
import {
    ScheduleHolidaysPublicConfig,
    ScheduleHolidaysPublicConfigFromJSON,
    ScheduleHolidaysPublicConfigFromJSONTyped,
    ScheduleHolidaysPublicConfigToJSON,
} from './ScheduleHolidaysPublicConfig';

/**
 * 
 * @export
 * @interface SchedulesUpdateRequestBody
 */
export interface SchedulesUpdateRequestBody {
    /**
     * Annotations that can track metadata about this type
     * @type {{ [key: string]: string; }}
     * @memberof SchedulesUpdateRequestBody
     */
    annotations?: { [key: string]: string; };
    /**
     * 
     * @type {ScheduleConfigPayload}
     * @memberof SchedulesUpdateRequestBody
     */
    config?: ScheduleConfigPayload;
    /**
     * ID of the pay config connected with this schedule
     * @type {string}
     * @memberof SchedulesUpdateRequestBody
     */
    default_pay_config_id?: string;
    /**
     * If set, the schedule description synced from the external provider
     * @type {string}
     * @memberof SchedulesUpdateRequestBody
     */
    description?: string;
    /**
     * 
     * @type {ScheduleHolidaysPublicConfig}
     * @memberof SchedulesUpdateRequestBody
     */
    holidays_public_config?: ScheduleHolidaysPublicConfig;
    /**
     * Human readable name synced from external provider
     * @type {string}
     * @memberof SchedulesUpdateRequestBody
     */
    name?: string;
    /**
     * Users that have been promoted to be part of this schedule
     * @type {Array<string>}
     * @memberof SchedulesUpdateRequestBody
     */
    user_ids_to_promote?: Array<string>;
}

export function SchedulesUpdateRequestBodyFromJSON(json: any): SchedulesUpdateRequestBody {
    return SchedulesUpdateRequestBodyFromJSONTyped(json, false);
}

export function SchedulesUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'config': !exists(json, 'config') ? undefined : ScheduleConfigPayloadFromJSON(json['config']),
        'default_pay_config_id': !exists(json, 'default_pay_config_id') ? undefined : json['default_pay_config_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'holidays_public_config': !exists(json, 'holidays_public_config') ? undefined : ScheduleHolidaysPublicConfigFromJSON(json['holidays_public_config']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'user_ids_to_promote': !exists(json, 'user_ids_to_promote') ? undefined : json['user_ids_to_promote'],
    };
}

export function SchedulesUpdateRequestBodyToJSON(value?: SchedulesUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'config': ScheduleConfigPayloadToJSON(value.config),
        'default_pay_config_id': value.default_pay_config_id,
        'description': value.description,
        'holidays_public_config': ScheduleHolidaysPublicConfigToJSON(value.holidays_public_config),
        'name': value.name,
        'user_ids_to_promote': value.user_ids_to_promote,
    };
}

