/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Nudge,
    NudgeFromJSON,
    NudgeFromJSONTyped,
    NudgeToJSON,
} from './Nudge';

/**
 * 
 * @export
 * @interface NudgesShowResponseBody
 */
export interface NudgesShowResponseBody {
    /**
     * 
     * @type {Nudge}
     * @memberof NudgesShowResponseBody
     */
    nudge: Nudge;
}

export function NudgesShowResponseBodyFromJSON(json: any): NudgesShowResponseBody {
    return NudgesShowResponseBodyFromJSONTyped(json, false);
}

export function NudgesShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgesShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nudge': NudgeFromJSON(json['nudge']),
    };
}

export function NudgesShowResponseBodyToJSON(value?: NudgesShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nudge': NudgeToJSON(value.nudge),
    };
}

