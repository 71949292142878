import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { IconEnum } from "@incident-ui";
import React from "react";

import { CustomerPageCreateForm } from "./CustomerPageCreateForm";

export const CustomerPageCreate = (): React.ReactElement => {
  return (
    <PageWrapper
      width={PageWidth.Narrow}
      icon={IconEnum.Users}
      title="Create customer page"
      backHref="/status-pages"
      crumbs={[{ title: "Status pages", to: "/status-pages" }]}
    >
      <CustomerPageCreateForm />
    </PageWrapper>
  );
};
