import {
  CatalogTypeAttributeModeEnum as AttributeResponseModeEnum,
  CatalogTypeAttributePathItem,
  CatalogTypeAttributePayload,
  CatalogTypeSchemaPayload,
  CatalogUpdateTypeRequestBody,
} from "@incident-io/api";
import { Path, UseFieldArrayReturn } from "react-hook-form";

export type SchemaAttributesPath = (
  | "schema.data_attributes"
  | "schema.derived_attributes"
) &
  Path<CatalogTypeCreateEditFormState>;

export type SchemaAttributePath = `${SchemaAttributesPath}.${number}`;

export type CatalogSchemaFieldMethods = UseFieldArrayReturn<
  CatalogTypeCreateEditFormState,
  SchemaAttributesPath,
  "key"
>;

export type CatalogTypeCreateEditSchemaFormState = Omit<
  CatalogTypeSchemaPayload,
  "attributes"
> & {
  data_attributes: Array<AttributeFormState>;
  derived_attributes: Array<AttributeFormState>;
};

export type CatalogTypeCreateEditFormState = Omit<
  CatalogUpdateTypeRequestBody,
  "schema"
> & {
  schema: CatalogTypeCreateEditSchemaFormState;
};

// This is mostly just a normal attribute payload, but if the option is a
// reference to a parameterised type, we also include its arguments so we can
// show the schema drawer
export type AttributeFormState = Omit<
  CatalogTypeAttributePayload,
  "parameterised_resource_arguments" | "path"
> & {
  // Include some information about any extra catalog types we might want to create
  catalogTypeInfo?: CatalogTypeInfo;
  // Make id mandatory so we can use it when re-ordering attributes
  id: string;
  // Include the whole response for `path` so we can show the labels easily
  path?: CatalogTypeAttributePathItem[];
};

export type CatalogTypeInfo = {
  id?: string;
  name: string;
  registryType?: string;
  parameter?: string;
  exists?: boolean;
  typeName?: string;
};

const derivedModes = [
  AttributeResponseModeEnum.Path,
  AttributeResponseModeEnum.Backlink,
];
export const isDerivedAttribute = (mode: AttributeResponseModeEnum) => {
  return derivedModes.includes(mode);
};
