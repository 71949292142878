import { EngineScope, Identity, Resource } from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ContentBox } from "@incident-ui";
import { UseFieldArrayReturn } from "react-hook-form";

import { UpsellNotice } from "../../UpsellNotice";
import { FormDataType } from "./FormDataType";

export const TaskAssigneeSection = ({
  expressionsMethods,
  scope,
  resources,
  identity,
}: {
  expressionsMethods: UseFieldArrayReturn<FormDataType, "expressions", "key">;
  scope: EngineScope;
  resources: Resource[];
  identity: Identity;
}): React.ReactElement => {
  if (!identity.feature_gates.post_incident_task_auto_assign) {
    return (
      <UpsellNotice
        analyticsId={"pinc-task-auto-assign-upsell"}
        title={"Default assignee"}
        planName={"Pro"}
        description={
          "It's possible to automatically assign this task to a user who can delegate, or a specific role associated with completing the task. This helps ensures that tasks get completed quickly, by the right person."
        }
        articleId={9013953}
      />
    );
  }

  return (
    <ContentBox className="py-3 px-4 space-y-3">
      <ExpressionsMethodsProvider
        expressionsMethods={expressionsMethods}
        allowAllOfACatalogType={false}
      >
        <EngineFormElement
          name="assignee"
          resourceType="User"
          label="Default assignee"
          description={`Who should be assigned to this task by default? This might be a user who can delegate, or a specific role associated with completing the task.`}
          array={false}
          scope={scope}
          required={false}
          showPlaceholder
          mode="variables_and_expressions"
          resources={resources}
        />
      </ExpressionsMethodsProvider>
    </ContentBox>
  );
};
