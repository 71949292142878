/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemSettings,
    PostmortemSettingsFromJSON,
    PostmortemSettingsFromJSONTyped,
    PostmortemSettingsToJSON,
} from './PostmortemSettings';

/**
 * 
 * @export
 * @interface PostmortemsSettingsShowResponseBody
 */
export interface PostmortemsSettingsShowResponseBody {
    /**
     * 
     * @type {PostmortemSettings}
     * @memberof PostmortemsSettingsShowResponseBody
     */
    settings: PostmortemSettings;
}

export function PostmortemsSettingsShowResponseBodyFromJSON(json: any): PostmortemsSettingsShowResponseBody {
    return PostmortemsSettingsShowResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsSettingsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsSettingsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': PostmortemSettingsFromJSON(json['settings']),
    };
}

export function PostmortemsSettingsShowResponseBodyToJSON(value?: PostmortemsSettingsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': PostmortemSettingsToJSON(value.settings),
    };
}

