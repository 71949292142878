/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemSharesCreateTemplateRequestBody
 */
export interface PostmortemSharesCreateTemplateRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemSharesCreateTemplateRequestBody
     */
    content: TextNode;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemSharesCreateTemplateRequestBody
     */
    description?: TextNode;
    /**
     * Whether this template is the default postmortem share template for this org, if sent as true will override the current default
     * @type {boolean}
     * @memberof PostmortemSharesCreateTemplateRequestBody
     */
    is_default: boolean;
    /**
     * Name of the postmortem share template
     * @type {string}
     * @memberof PostmortemSharesCreateTemplateRequestBody
     */
    name: string;
    /**
     * Whether to add the incident channel into the slack channels automatically
     * @type {boolean}
     * @memberof PostmortemSharesCreateTemplateRequestBody
     */
    share_to_incident_channel: boolean;
    /**
     * List of the slack channel IDs in which we will share the postmortem
     * @type {Array<string>}
     * @memberof PostmortemSharesCreateTemplateRequestBody
     */
    slack_channel_ids: Array<string>;
}

export function PostmortemSharesCreateTemplateRequestBodyFromJSON(json: any): PostmortemSharesCreateTemplateRequestBody {
    return PostmortemSharesCreateTemplateRequestBodyFromJSONTyped(json, false);
}

export function PostmortemSharesCreateTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSharesCreateTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': TextNodeFromJSON(json['content']),
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'is_default': json['is_default'],
        'name': json['name'],
        'share_to_incident_channel': json['share_to_incident_channel'],
        'slack_channel_ids': json['slack_channel_ids'],
    };
}

export function PostmortemSharesCreateTemplateRequestBodyToJSON(value?: PostmortemSharesCreateTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': TextNodeToJSON(value.content),
        'description': TextNodeToJSON(value.description),
        'is_default': value.is_default,
        'name': value.name,
        'share_to_incident_channel': value.share_to_incident_channel,
        'slack_channel_ids': value.slack_channel_ids,
    };
}

