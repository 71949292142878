/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationNotification,
    EscalationNotificationFromJSON,
    EscalationNotificationFromJSONTyped,
    EscalationNotificationToJSON,
} from './EscalationNotification';

/**
 * 
 * @export
 * @interface EscalationTarget
 */
export interface EscalationTarget {
    /**
     * 
     * @type {Date}
     * @memberof EscalationTarget
     */
    created_at: Date;
    /**
     * Unique internal ID of the parent escalation
     * @type {string}
     * @memberof EscalationTarget
     */
    escalation_id: string;
    /**
     * Unique internal ID of this target
     * @type {string}
     * @memberof EscalationTarget
     */
    id: string;
    /**
     * The URL of the image for the user or channel this target is associated with
     * @type {string}
     * @memberof EscalationTarget
     */
    image_url?: string;
    /**
     * (Legacy field for mobile backwards compatability) level of the escalation this target is on
     * @type {number}
     * @memberof EscalationTarget
     */
    level?: number;
    /**
     * The ID of the node that this target was created for, null for manual user escalations
     * @type {string}
     * @memberof EscalationTarget
     */
    node_id?: string;
    /**
     * 
     * @type {Array<EscalationNotification>}
     * @memberof EscalationTarget
     */
    notifications: Array<EscalationNotification>;
    /**
     * Which iteration of path repetition this target was for, null if we haven't repeated yet
     * @type {number}
     * @memberof EscalationTarget
     */
    repeat_count?: number;
    /**
     * The name of the Slack channel this target is associated with
     * @type {string}
     * @memberof EscalationTarget
     */
    slack_channel_name?: string;
    /**
     * The URL of the Slack channel this target is associated with
     * @type {string}
     * @memberof EscalationTarget
     */
    slack_channel_url?: string;
    /**
     * 
     * @type {Date}
     * @memberof EscalationTarget
     */
    started_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof EscalationTarget
     */
    updated_at: Date;
    /**
     * Unique internal ID of the user
     * @type {string}
     * @memberof EscalationTarget
     */
    user_id?: string;
    /**
     * The name of the user this target is associated with
     * @type {string}
     * @memberof EscalationTarget
     */
    user_name?: string;
}

export function EscalationTargetFromJSON(json: any): EscalationTarget {
    return EscalationTargetFromJSONTyped(json, false);
}

export function EscalationTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'escalation_id': json['escalation_id'],
        'id': json['id'],
        'image_url': !exists(json, 'image_url') ? undefined : json['image_url'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'node_id': !exists(json, 'node_id') ? undefined : json['node_id'],
        'notifications': ((json['notifications'] as Array<any>).map(EscalationNotificationFromJSON)),
        'repeat_count': !exists(json, 'repeat_count') ? undefined : json['repeat_count'],
        'slack_channel_name': !exists(json, 'slack_channel_name') ? undefined : json['slack_channel_name'],
        'slack_channel_url': !exists(json, 'slack_channel_url') ? undefined : json['slack_channel_url'],
        'started_at': (new Date(json['started_at'])),
        'updated_at': (new Date(json['updated_at'])),
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
    };
}

export function EscalationTargetToJSON(value?: EscalationTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'escalation_id': value.escalation_id,
        'id': value.id,
        'image_url': value.image_url,
        'level': value.level,
        'node_id': value.node_id,
        'notifications': ((value.notifications as Array<any>).map(EscalationNotificationToJSON)),
        'repeat_count': value.repeat_count,
        'slack_channel_name': value.slack_channel_name,
        'slack_channel_url': value.slack_channel_url,
        'started_at': (value.started_at.toISOString()),
        'updated_at': (value.updated_at.toISOString()),
        'user_id': value.user_id,
        'user_name': value.user_name,
    };
}

