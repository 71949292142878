import type { SVGProps } from "react";
import * as React from "react";
const SvgList = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 14.25v-8.5A2.752 2.752 0 0 1 5.75 3h8.5A2.752 2.752 0 0 1 17 5.75v8.5A2.752 2.752 0 0 1 14.25 17h-8.5A2.752 2.752 0 0 1 3 14.25ZM6 7a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 6 7Zm0 3a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 6 10Zm0 3a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 6 13Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgList;
