import type { SVGProps } from "react";
import * as React from "react";
const SvgQuickstart = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.11 12H2.445a.667.667 0 0 1 0-1.333h2.667a.667.667 0 0 1 0 1.333Z"
    />
    <path
      fill="currentColor"
      d="M8.002 2.667c-2.648 0-4.908 1.665-5.805 4h1.138c1.102 0 2 .897 2 2 0 .238-.049.464-.126.677a1.998 1.998 0 0 1 1.904 1.99c0 1.103-.898 2-2 2H3.656a6.2 6.2 0 0 0 4.346 1.778 6.23 6.23 0 0 0 6.222-6.223 6.23 6.23 0 0 0-6.222-6.222Zm2.47 4.693-2 2a.665.665 0 0 1-.942 0 .667.667 0 0 1 0-.943l2-2a.667.667 0 1 1 .944.943h-.001Z"
    />
    <path
      fill="currentColor"
      d="M3.333 9.333H.667A.667.667 0 0 1 .667 8h2.666a.667.667 0 0 1 0 1.333ZM9.999.444h-4a.667.667 0 0 0 0 1.334h1.333v1.555a.667.667 0 0 0 1.333 0V1.778H10a.667.667 0 0 0 0-1.334ZM14.444 4.89a.661.661 0 0 1-.471-.196l-1.778-1.778a.667.667 0 1 1 .943-.943l1.778 1.777a.667.667 0 0 1-.472 1.14Z"
    />
  </svg>
);
export default SvgQuickstart;
