import _ from "lodash";
import { useAPI } from "src/utils/swr";
import { v4 as uuid } from "uuid";

import { ExtendedFormFieldValue, useFiltersContext } from "./FiltersContext";

type SynthesizedTypeIds = {
  catalog_type_id: string;
  operator: string;
  lookup_terms: string[];
  backlink_attribute_id: string;

  field_id: string;
  field_key: string;
};

export const useSyntheticCatalogFilters = (
  filters: ExtendedFormFieldValue[],
) => {
  const { availableFilterFields } = useFiltersContext();
  const catalogTypeIdsToSynthesize: SynthesizedTypeIds[] = [];

  // Loop over all our filters to try and find any that were synthesized from a catalog backlink
  for (const filter of filters) {
    const filterConfig = availableFilterFields.find(
      (f) => f.filter_id === filter.filter_id,
    );

    if (!filterConfig || !filterConfig.catalog_backed_opts) {
      continue;
    }

    catalogTypeIdsToSynthesize.push({
      catalog_type_id: filterConfig.catalog_backed_opts.catalog_type_id,
      operator: filter.operator,
      lookup_terms: filter.multiple_options_value ?? [],
      backlink_attribute_id:
        filterConfig.catalog_backed_opts.backlink_attribute_id,
      field_id: filter.field_id,
      field_key: filter.field_key,
    });
  }

  // find all the entries that match the synthesized filters
  // for example, if the filter is "Reporters -> Team", we want all members of that team (all the values of the attribute specified by backlink_attribute_id)
  const { data, isLoading, error } = useAPI(
    catalogTypeIdsToSynthesize.length === 0 ? null : "catalogFindEntries",
    {
      findEntriesRequestBody: {
        lookups: catalogTypeIdsToSynthesize.map((ct) => ({
          catalog_type_id: ct.catalog_type_id,
          lookup_terms: ct.lookup_terms,
        })),
      },
    },
  );

  // we clone here so it does not add any new filters into the UI because its a shallow copy
  const actualFilters = _.cloneDeep(filters);

  // for each value we find, we change our synthetic filter into a real filter that gets sent into the payload
  if (!isLoading && !error) {
    for (const typeToSynthesize of catalogTypeIdsToSynthesize) {
      const matchedData = data?.results[typeToSynthesize.catalog_type_id];

      const multipleOptionsValues: string[] = [];

      for (const md of matchedData ?? []) {
        const attributeValues =
          md.attribute_values[typeToSynthesize.backlink_attribute_id];
        const valueEntryIds = (attributeValues?.array_value ?? []).map(
          (av) => av.catalog_entry?.catalog_entry_id ?? "",
        );

        multipleOptionsValues.push(...valueEntryIds);
      }

      actualFilters.push({
        key: uuid(),
        filter_id: typeToSynthesize.field_id ?? typeToSynthesize.field_key,
        field_key: typeToSynthesize.field_key,
        field_id: typeToSynthesize.field_id ?? "",
        operator: typeToSynthesize.operator,
        multiple_options_value: multipleOptionsValues,
      });
    }
  }

  const apiFilters = actualFilters.filter((f) => !f.catalog_type_id);

  return {
    data: apiFilters,
    isLoading,
    error,
  };
};
