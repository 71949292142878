/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnnouncementPostsCreateActionRequestBody
 */
export interface AnnouncementPostsCreateActionRequestBody {
    /**
     * Type of this action
     * @type {string}
     * @memberof AnnouncementPostsCreateActionRequestBody
     */
    action_type: AnnouncementPostsCreateActionRequestBodyActionTypeEnum;
    /**
     * Emoji for this action
     * @type {string}
     * @memberof AnnouncementPostsCreateActionRequestBody
     */
    emoji?: string;
    /**
     * Order of this action
     * @type {number}
     * @memberof AnnouncementPostsCreateActionRequestBody
     */
    rank: number;
    /**
     * Title of this action
     * @type {string}
     * @memberof AnnouncementPostsCreateActionRequestBody
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum AnnouncementPostsCreateActionRequestBodyActionTypeEnum {
    Homepage = 'announcement_post_actions_homepage',
    Subscribe = 'announcement_post_actions_subscribe',
    JoinCall = 'announcement_post_actions_join_call',
    JiraTicket = 'announcement_post_actions_jira_ticket',
    InternalStatusPage = 'announcement_post_actions_internal_status_page',
    PublicStatusPage = 'announcement_post_actions_public_status_page',
    Postmortem = 'announcement_post_actions_postmortem'
}

export function AnnouncementPostsCreateActionRequestBodyFromJSON(json: any): AnnouncementPostsCreateActionRequestBody {
    return AnnouncementPostsCreateActionRequestBodyFromJSONTyped(json, false);
}

export function AnnouncementPostsCreateActionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostsCreateActionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_type': json['action_type'],
        'emoji': !exists(json, 'emoji') ? undefined : json['emoji'],
        'rank': json['rank'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function AnnouncementPostsCreateActionRequestBodyToJSON(value?: AnnouncementPostsCreateActionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': value.action_type,
        'emoji': value.emoji,
        'rank': value.rank,
        'title': value.title,
    };
}

