/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableIncidentFormLifecycleElement,
    AvailableIncidentFormLifecycleElementFromJSON,
    AvailableIncidentFormLifecycleElementFromJSONTyped,
    AvailableIncidentFormLifecycleElementToJSON,
} from './AvailableIncidentFormLifecycleElement';

/**
 * 
 * @export
 * @interface IncidentFormsListAvailableLifecycleElementsResponseBody
 */
export interface IncidentFormsListAvailableLifecycleElementsResponseBody {
    /**
     * 
     * @type {Array<AvailableIncidentFormLifecycleElement>}
     * @memberof IncidentFormsListAvailableLifecycleElementsResponseBody
     */
    available_elements: Array<AvailableIncidentFormLifecycleElement>;
}

export function IncidentFormsListAvailableLifecycleElementsResponseBodyFromJSON(json: any): IncidentFormsListAvailableLifecycleElementsResponseBody {
    return IncidentFormsListAvailableLifecycleElementsResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsListAvailableLifecycleElementsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsListAvailableLifecycleElementsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available_elements': ((json['available_elements'] as Array<any>).map(AvailableIncidentFormLifecycleElementFromJSON)),
    };
}

export function IncidentFormsListAvailableLifecycleElementsResponseBodyToJSON(value?: IncidentFormsListAvailableLifecycleElementsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_elements': ((value.available_elements as Array<any>).map(AvailableIncidentFormLifecycleElementToJSON)),
    };
}

