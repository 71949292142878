import { SlackEnterpriseSettings } from "@incident-io/api";
import { ContentBox, IconSize, SlackTeamAvatar } from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";

import { SettingsSubHeading } from "../SettingsSubHeading";

export const SlackEnterpriseGridViewSection = ({
  settings,
}: {
  settings: SlackEnterpriseSettings;
}): React.ReactElement => {
  const { identity } = useIdentity();

  return (
    <>
      <SettingsSubHeading
        title="Grid configuration"
        titleHeadingLevel={2}
        className="!mb-2"
        explanation={`The Slack Enterprise Grid organization that has incident.io installed.`}
      />
      <ContentBox className="p-4 space-y-4 mb-6">
        <div className="flex shrink-0 font-semibold text-sm items-center gap-1">
          <SlackTeamAvatar
            url={settings.slack_enterprise_icon_url}
            size={IconSize.Large}
            className="mr-1"
          />
          {identity?.organisation_name}
        </div>
      </ContentBox>
    </>
  );
};
