import { Product } from "@incident-shared/billing";
import { GenericErrorMessage } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import graphic from "src/components/settings/banners/banner-post-mortem.svg";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";

import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { SettingsSubHeading } from "../SettingsSubHeading";
import { PostmortemDestinationList } from "./destination/PostmortemDestinationList";
import { PostmortemDestinationTestOrTutorialForm } from "./destination/PostmortemDestinationTestOrTutorialForm";
import { LegacyPostmortemTemplateList } from "./legacy-template/LegacyPostmortemTemplateList";
import { PostmortemAutoSyncFollowup } from "./sensible-defaults/PostmortemAutoSyncFollowup";
import { PostmortemCopyPasteProviderForm } from "./sensible-defaults/PostmortemCopyPasteProviderForm";
import { PostmortemNameForm } from "./sensible-defaults/PostmortemNameForm";
import { PostmortemTimezoneForm } from "./sensible-defaults/PostmortemTimezoneForm";
import { PostmortemShareTemplatesList } from "./share/PostmortemShareTemplatesList";
import { PostmortemExpressionForm } from "./templates/expression/PostmortemTemplateExpression";
import { PostmortemTemplateList } from "./templates/PostmortemTemplateList";

export const PostmortemsPage = (): React.ReactElement => {
  const { hasProduct } = useProductAccess();
  const commsPlatform = usePrimaryCommsPlatform();
  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();

  const {
    data: { postmortem_templates: legacyTemplates },
    isLoading: loadingLegacyTemplates,
    error: errorLegacyTemplates,
  } = useAPI(
    "postmortemsListLegacyTemplates",
    {},
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  const {
    data: { postmortem_templates: templates },
    isLoading: loadingTemplates,
    error: errorTemplates,
  } = useAPI(
    "postmortemsListTemplates",
    {},
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  const {
    data: settingsData,
    isLoading: loadingSettings,
    error: errorSettings,
  } = useAPI("postmortemsSettingsShow", undefined);
  const postmortemSettings = settingsData?.settings;

  const {
    data: { destinations },
    isLoading: loadingDestinations,
    error: errorDestinations,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });

  const {
    data: { postmortem_share_templates: shareTemplates },
    isLoading: loadingShareTemplates,
    error: errorShareTemplates,
  } = useAPI("postmortemSharesListTemplates", undefined, {
    fallbackData: { postmortem_share_templates: [] },
  });

  if (
    loadingLegacyTemplates ||
    loadingTemplates ||
    loadingDestinations ||
    loadingSettings ||
    loadingShareTemplates
  ) {
    return <FullPageLoader />;
  }

  if (
    errorLegacyTemplates ||
    !legacyTemplates ||
    errorTemplates ||
    !templates
  ) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem document templates." />
    );
  }

  if (errorShareTemplates || !shareTemplates) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem sharing templates." />
    );
  }

  if (errorSettings || !postmortemSettings) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem settings." />
    );
  }

  if (errorDestinations || !destinations) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem destinations." />
    );
  }

  return (
    <SettingsSubPageWrapper wrapperClassName="gap-8">
      <SettingsHeading
        graphic={<img src={graphic} className="h-40" />}
        title="Simplify post-mortems"
        subtitle="Streamline the creation of post-mortems to help your team learn from incidents."
      />
      {hasProduct(Product.Response) ? (
        <>
          {featurePostmortemsInHouse ? (
            <>
              <PostmortemTemplateList
                settings={postmortemSettings}
                templates={templates}
              />
              {templates.length > 0 && (
                <PostmortemExpressionForm
                  settings={postmortemSettings}
                  templates={templates}
                />
              )}
            </>
          ) : (
            <LegacyPostmortemTemplateList
              postMortemTemplates={legacyTemplates}
            />
          )}
          <PostmortemDestinationList destinations={destinations} />
          {commsPlatform === CommsPlatform.Slack && (
            <PostmortemShareTemplatesList shareTemplates={shareTemplates} />
          )}
          <PostmortemNameForm postmortemSettings={postmortemSettings} />
          <PostmortemAutoSyncFollowup postmortemSettings={postmortemSettings} />
          <div>
            <SettingsSubHeading
              title="Sensible defaults"
              titleHeadingLevel={2}
              explanation={
                <>
                  Help responders export post-mortems correctly by configuring
                  sensible defaults.
                </>
              }
            />
            <div className="space-y-4">
              <PostmortemDestinationTestOrTutorialForm
                postmortemSettings={postmortemSettings}
              />
              <PostmortemTimezoneForm postmortemSettings={postmortemSettings} />
              <PostmortemCopyPasteProviderForm
                postmortemSettings={postmortemSettings}
              />
            </div>
          </div>
        </>
      ) : (
        <ProductUpsellNotice
          featureName="Post-mortems"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
    </SettingsSubPageWrapper>
  );
};
