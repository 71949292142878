import type { SVGProps } from "react";
import * as React from "react";
const SvgShield = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <g clipPath="url(#shield_svg__a)">
      <path
        fill="currentColor"
        d="m15.783 3.813-5.25-1.68a1.748 1.748 0 0 0-1.066 0l-5.251 1.68A1.745 1.745 0 0 0 3 5.48V12c0 3.507 4.945 5.38 6.461 5.87a1.75 1.75 0 0 0 1.077 0C12.055 17.38 17 15.506 17 12V5.48c0-.764-.489-1.434-1.217-1.667ZM15.5 12c0 2.56-4.518 4.15-5.424 4.442-.026.008-.051 0-.076 0V3.55c.025 0 .051.004.076.012l5.249 1.68a.25.25 0 0 1 .175.238V12Z"
      />
    </g>
    <defs>
      <clipPath id="shield_svg__a">
        <path fill="#fff" d="M0 .05h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgShield;
