/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraAutoExportConfig,
    JiraAutoExportConfigFromJSON,
    JiraAutoExportConfigFromJSONTyped,
    JiraAutoExportConfigToJSON,
} from './JiraAutoExportConfig';

/**
 * 
 * @export
 * @interface JiraServerConfig
 */
export interface JiraServerConfig {
    /**
     * URL to trigger an auth flow (present if the Jira Server host is known)
     * @type {string}
     * @memberof JiraServerConfig
     */
    auth_url?: string;
    /**
     * 
     * @type {JiraAutoExportConfig}
     * @memberof JiraServerConfig
     */
    auto_export_config: JiraAutoExportConfig;
    /**
     * Should the jira auto-export feature be enabled
     * @type {boolean}
     * @memberof JiraServerConfig
     */
    auto_export_enabled: boolean;
    /**
     * Consumer Key to configure in Jira
     * @type {string}
     * @memberof JiraServerConfig
     */
    consumer_key?: string;
    /**
     * Whether the org is fully connected to Jira Server
     * @type {boolean}
     * @memberof JiraServerConfig
     */
    has_access_token: boolean;
    /**
     * The base URL of the Jira Server installation
     * @type {string}
     * @memberof JiraServerConfig
     */
    host?: string;
    /**
     * Public Key to configure in Jira
     * @type {string}
     * @memberof JiraServerConfig
     */
    public_key_pem?: string;
}

export function JiraServerConfigFromJSON(json: any): JiraServerConfig {
    return JiraServerConfigFromJSONTyped(json, false);
}

export function JiraServerConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraServerConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth_url': !exists(json, 'auth_url') ? undefined : json['auth_url'],
        'auto_export_config': JiraAutoExportConfigFromJSON(json['auto_export_config']),
        'auto_export_enabled': json['auto_export_enabled'],
        'consumer_key': !exists(json, 'consumer_key') ? undefined : json['consumer_key'],
        'has_access_token': json['has_access_token'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'public_key_pem': !exists(json, 'public_key_pem') ? undefined : json['public_key_pem'],
    };
}

export function JiraServerConfigToJSON(value?: JiraServerConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth_url': value.auth_url,
        'auto_export_config': JiraAutoExportConfigToJSON(value.auto_export_config),
        'auto_export_enabled': value.auto_export_enabled,
        'consumer_key': value.consumer_key,
        'has_access_token': value.has_access_token,
        'host': value.host,
        'public_key_pem': value.public_key_pem,
    };
}

