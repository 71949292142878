/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpsUpdateRequestBody
 */
export interface FollowUpsUpdateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof FollowUpsUpdateRequestBody
     */
    assignee_id?: string;
    /**
     * Description of the follow-up
     * @type {string}
     * @memberof FollowUpsUpdateRequestBody
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FollowUpsUpdateRequestBody
     */
    follow_up_priority_option_id?: string;
    /**
     * Status of the follow-up
     * @type {string}
     * @memberof FollowUpsUpdateRequestBody
     */
    status: FollowUpsUpdateRequestBodyStatusEnum;
    /**
     * Title of the follow-up
     * @type {string}
     * @memberof FollowUpsUpdateRequestBody
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum FollowUpsUpdateRequestBodyStatusEnum {
    Outstanding = 'outstanding',
    Completed = 'completed',
    Deleted = 'deleted',
    NotDoing = 'not_doing'
}

export function FollowUpsUpdateRequestBodyFromJSON(json: any): FollowUpsUpdateRequestBody {
    return FollowUpsUpdateRequestBodyFromJSONTyped(json, false);
}

export function FollowUpsUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'follow_up_priority_option_id': !exists(json, 'follow_up_priority_option_id') ? undefined : json['follow_up_priority_option_id'],
        'status': json['status'],
        'title': json['title'],
    };
}

export function FollowUpsUpdateRequestBodyToJSON(value?: FollowUpsUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': value.assignee_id,
        'description': value.description,
        'follow_up_priority_option_id': value.follow_up_priority_option_id,
        'status': value.status,
        'title': value.title,
    };
}

