/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentFormsCreateEscalationElementRequestBody,
    IncidentFormsCreateEscalationElementRequestBodyFromJSON,
    IncidentFormsCreateEscalationElementRequestBodyToJSON,
    IncidentFormsCreateEscalationElementResponseBody,
    IncidentFormsCreateEscalationElementResponseBodyFromJSON,
    IncidentFormsCreateEscalationElementResponseBodyToJSON,
    IncidentFormsCreateExpressionRequestBody,
    IncidentFormsCreateExpressionRequestBodyFromJSON,
    IncidentFormsCreateExpressionRequestBodyToJSON,
    IncidentFormsCreateExpressionResponseBody,
    IncidentFormsCreateExpressionResponseBodyFromJSON,
    IncidentFormsCreateExpressionResponseBodyToJSON,
    IncidentFormsCreateFormRequestBody,
    IncidentFormsCreateFormRequestBodyFromJSON,
    IncidentFormsCreateFormRequestBodyToJSON,
    IncidentFormsCreateFormResponseBody,
    IncidentFormsCreateFormResponseBodyFromJSON,
    IncidentFormsCreateFormResponseBodyToJSON,
    IncidentFormsCreateLifecycleElementRequestBody,
    IncidentFormsCreateLifecycleElementRequestBodyFromJSON,
    IncidentFormsCreateLifecycleElementRequestBodyToJSON,
    IncidentFormsCreateLifecycleElementResponseBody,
    IncidentFormsCreateLifecycleElementResponseBodyFromJSON,
    IncidentFormsCreateLifecycleElementResponseBodyToJSON,
    IncidentFormsGetEscalationElementBindingsRequestBody,
    IncidentFormsGetEscalationElementBindingsRequestBodyFromJSON,
    IncidentFormsGetEscalationElementBindingsRequestBodyToJSON,
    IncidentFormsGetEscalationElementBindingsResponseBody,
    IncidentFormsGetEscalationElementBindingsResponseBodyFromJSON,
    IncidentFormsGetEscalationElementBindingsResponseBodyToJSON,
    IncidentFormsGetLifecycleElementBindingsRequestBody,
    IncidentFormsGetLifecycleElementBindingsRequestBodyFromJSON,
    IncidentFormsGetLifecycleElementBindingsRequestBodyToJSON,
    IncidentFormsGetLifecycleElementBindingsResponseBody,
    IncidentFormsGetLifecycleElementBindingsResponseBodyFromJSON,
    IncidentFormsGetLifecycleElementBindingsResponseBodyToJSON,
    IncidentFormsListAvailableEscalationElementsResponseBody,
    IncidentFormsListAvailableEscalationElementsResponseBodyFromJSON,
    IncidentFormsListAvailableEscalationElementsResponseBodyToJSON,
    IncidentFormsListAvailableLifecycleElementsResponseBody,
    IncidentFormsListAvailableLifecycleElementsResponseBodyFromJSON,
    IncidentFormsListAvailableLifecycleElementsResponseBodyToJSON,
    IncidentFormsListEscalationElementsResponseBody,
    IncidentFormsListEscalationElementsResponseBodyFromJSON,
    IncidentFormsListEscalationElementsResponseBodyToJSON,
    IncidentFormsListFormsResponseBody,
    IncidentFormsListFormsResponseBodyFromJSON,
    IncidentFormsListFormsResponseBodyToJSON,
    IncidentFormsListLifecycleElementsResponseBody,
    IncidentFormsListLifecycleElementsResponseBodyFromJSON,
    IncidentFormsListLifecycleElementsResponseBodyToJSON,
    IncidentFormsPreviewEscalateFormRequestBody,
    IncidentFormsPreviewEscalateFormRequestBodyFromJSON,
    IncidentFormsPreviewEscalateFormRequestBodyToJSON,
    IncidentFormsPreviewEscalateFormResponseBody,
    IncidentFormsPreviewEscalateFormResponseBodyFromJSON,
    IncidentFormsPreviewEscalateFormResponseBodyToJSON,
    IncidentFormsResetEscalationElementsResponseBody,
    IncidentFormsResetEscalationElementsResponseBodyFromJSON,
    IncidentFormsResetEscalationElementsResponseBodyToJSON,
    IncidentFormsShowFormResponseBody,
    IncidentFormsShowFormResponseBodyFromJSON,
    IncidentFormsShowFormResponseBodyToJSON,
    IncidentFormsSubmitEscalateFormRequestBody,
    IncidentFormsSubmitEscalateFormRequestBodyFromJSON,
    IncidentFormsSubmitEscalateFormRequestBodyToJSON,
    IncidentFormsSubmitEscalateFormResponseBody,
    IncidentFormsSubmitEscalateFormResponseBodyFromJSON,
    IncidentFormsSubmitEscalateFormResponseBodyToJSON,
    IncidentFormsUpdateEscalationElementRequestBody,
    IncidentFormsUpdateEscalationElementRequestBodyFromJSON,
    IncidentFormsUpdateEscalationElementRequestBodyToJSON,
    IncidentFormsUpdateEscalationElementResponseBody,
    IncidentFormsUpdateEscalationElementResponseBodyFromJSON,
    IncidentFormsUpdateEscalationElementResponseBodyToJSON,
    IncidentFormsUpdateEscalationRanksRequestBody,
    IncidentFormsUpdateEscalationRanksRequestBodyFromJSON,
    IncidentFormsUpdateEscalationRanksRequestBodyToJSON,
    IncidentFormsUpdateEscalationRanksResponseBody,
    IncidentFormsUpdateEscalationRanksResponseBodyFromJSON,
    IncidentFormsUpdateEscalationRanksResponseBodyToJSON,
    IncidentFormsUpdateExpressionRequestBody,
    IncidentFormsUpdateExpressionRequestBodyFromJSON,
    IncidentFormsUpdateExpressionRequestBodyToJSON,
    IncidentFormsUpdateExpressionResponseBody,
    IncidentFormsUpdateExpressionResponseBodyFromJSON,
    IncidentFormsUpdateExpressionResponseBodyToJSON,
    IncidentFormsUpdateFormRequestBody,
    IncidentFormsUpdateFormRequestBodyFromJSON,
    IncidentFormsUpdateFormRequestBodyToJSON,
    IncidentFormsUpdateFormResponseBody,
    IncidentFormsUpdateFormResponseBodyFromJSON,
    IncidentFormsUpdateFormResponseBodyToJSON,
    IncidentFormsUpdateLifecycleElementRequestBody,
    IncidentFormsUpdateLifecycleElementRequestBodyFromJSON,
    IncidentFormsUpdateLifecycleElementRequestBodyToJSON,
    IncidentFormsUpdateLifecycleElementResponseBody,
    IncidentFormsUpdateLifecycleElementResponseBodyFromJSON,
    IncidentFormsUpdateLifecycleElementResponseBodyToJSON,
    IncidentFormsUpdateLifecycleRanksRequestBody,
    IncidentFormsUpdateLifecycleRanksRequestBodyFromJSON,
    IncidentFormsUpdateLifecycleRanksRequestBodyToJSON,
    IncidentFormsUpdateLifecycleRanksResponseBody,
    IncidentFormsUpdateLifecycleRanksResponseBodyFromJSON,
    IncidentFormsUpdateLifecycleRanksResponseBodyToJSON,
} from '../models';

export interface IncidentFormsCreateEscalationElementRequest {
    createEscalationElementRequestBody: IncidentFormsCreateEscalationElementRequestBody;
}

export interface IncidentFormsCreateExpressionRequest {
    incidentFormId: string;
    createExpressionRequestBody: IncidentFormsCreateExpressionRequestBody;
}

export interface IncidentFormsCreateFormRequest {
    createFormRequestBody: IncidentFormsCreateFormRequestBody;
}

export interface IncidentFormsCreateLifecycleElementRequest {
    createLifecycleElementRequestBody: IncidentFormsCreateLifecycleElementRequestBody;
}

export interface IncidentFormsDestroyEscalationElementRequest {
    id: string;
}

export interface IncidentFormsDestroyExpressionRequest {
    incidentFormId: string;
    expressionId: string;
}

export interface IncidentFormsDestroyFormRequest {
    id: string;
}

export interface IncidentFormsDestroyLifecycleElementRequest {
    id: string;
}

export interface IncidentFormsGetEscalationElementBindingsRequest {
    getEscalationElementBindingsRequestBody: IncidentFormsGetEscalationElementBindingsRequestBody;
}

export interface IncidentFormsGetLifecycleElementBindingsRequest {
    getLifecycleElementBindingsRequestBody: IncidentFormsGetLifecycleElementBindingsRequestBody;
}

export interface IncidentFormsListAvailableEscalationElementsRequest {
    incidentFormId: string;
}

export interface IncidentFormsListAvailableLifecycleElementsRequest {
    incidentFormId: string;
}

export interface IncidentFormsListEscalationElementsRequest {
    incidentFormId: string;
}

export interface IncidentFormsListLifecycleElementsRequest {
    incidentFormId: string;
}

export interface IncidentFormsPreviewEscalateFormRequest {
    id: string;
    previewEscalateFormRequestBody: IncidentFormsPreviewEscalateFormRequestBody;
}

export interface IncidentFormsResetEscalationElementsRequest {
    incidentFormId: string;
}

export interface IncidentFormsShowFormRequest {
    id: string;
}

export interface IncidentFormsSubmitEscalateFormRequest {
    id: string;
    submitEscalateFormRequestBody: IncidentFormsSubmitEscalateFormRequestBody;
}

export interface IncidentFormsUpdateEscalationElementRequest {
    id: string;
    updateEscalationElementRequestBody: IncidentFormsUpdateEscalationElementRequestBody;
}

export interface IncidentFormsUpdateEscalationRanksRequest {
    id: string;
    updateEscalationRanksRequestBody: IncidentFormsUpdateEscalationRanksRequestBody;
}

export interface IncidentFormsUpdateExpressionRequest {
    incidentFormId: string;
    expressionId: string;
    updateExpressionRequestBody: IncidentFormsUpdateExpressionRequestBody;
}

export interface IncidentFormsUpdateFormRequest {
    id: string;
    updateFormRequestBody: IncidentFormsUpdateFormRequestBody;
}

export interface IncidentFormsUpdateLifecycleElementRequest {
    id: string;
    updateLifecycleElementRequestBody: IncidentFormsUpdateLifecycleElementRequestBody;
}

export interface IncidentFormsUpdateLifecycleRanksRequest {
    id: string;
    updateLifecycleRanksRequestBody: IncidentFormsUpdateLifecycleRanksRequestBody;
}

/**
 * 
 */
export class IncidentFormsApi extends runtime.BaseAPI {

    /**
     * Create incident form element
     * CreateEscalationElement IncidentForms
     */
    async incidentFormsCreateEscalationElementRaw(requestParameters: IncidentFormsCreateEscalationElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsCreateEscalationElementResponseBody>> {
        if (requestParameters.createEscalationElementRequestBody === null || requestParameters.createEscalationElementRequestBody === undefined) {
            throw new runtime.RequiredError('createEscalationElementRequestBody','Required parameter requestParameters.createEscalationElementRequestBody was null or undefined when calling incidentFormsCreateEscalationElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/escalation/elements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsCreateEscalationElementRequestBodyToJSON(requestParameters.createEscalationElementRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsCreateEscalationElementResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create incident form element
     * CreateEscalationElement IncidentForms
     */
    async incidentFormsCreateEscalationElement(requestParameters: IncidentFormsCreateEscalationElementRequest, initOverrides?: RequestInit): Promise<IncidentFormsCreateEscalationElementResponseBody> {
        const response = await this.incidentFormsCreateEscalationElementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an expression for use in this incident form
     * CreateExpression IncidentForms
     */
    async incidentFormsCreateExpressionRaw(requestParameters: IncidentFormsCreateExpressionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsCreateExpressionResponseBody>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsCreateExpression.');
        }

        if (requestParameters.createExpressionRequestBody === null || requestParameters.createExpressionRequestBody === undefined) {
            throw new runtime.RequiredError('createExpressionRequestBody','Required parameter requestParameters.createExpressionRequestBody was null or undefined when calling incidentFormsCreateExpression.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/{incident_form_id}/expressions`.replace(`{${"incident_form_id"}}`, encodeURIComponent(String(requestParameters.incidentFormId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsCreateExpressionRequestBodyToJSON(requestParameters.createExpressionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsCreateExpressionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates an expression for use in this incident form
     * CreateExpression IncidentForms
     */
    async incidentFormsCreateExpression(requestParameters: IncidentFormsCreateExpressionRequest, initOverrides?: RequestInit): Promise<IncidentFormsCreateExpressionResponseBody> {
        const response = await this.incidentFormsCreateExpressionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create incident form
     * CreateForm IncidentForms
     */
    async incidentFormsCreateFormRaw(requestParameters: IncidentFormsCreateFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsCreateFormResponseBody>> {
        if (requestParameters.createFormRequestBody === null || requestParameters.createFormRequestBody === undefined) {
            throw new runtime.RequiredError('createFormRequestBody','Required parameter requestParameters.createFormRequestBody was null or undefined when calling incidentFormsCreateForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsCreateFormRequestBodyToJSON(requestParameters.createFormRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsCreateFormResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create incident form
     * CreateForm IncidentForms
     */
    async incidentFormsCreateForm(requestParameters: IncidentFormsCreateFormRequest, initOverrides?: RequestInit): Promise<IncidentFormsCreateFormResponseBody> {
        const response = await this.incidentFormsCreateFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create incident form element
     * CreateLifecycleElement IncidentForms
     */
    async incidentFormsCreateLifecycleElementRaw(requestParameters: IncidentFormsCreateLifecycleElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsCreateLifecycleElementResponseBody>> {
        if (requestParameters.createLifecycleElementRequestBody === null || requestParameters.createLifecycleElementRequestBody === undefined) {
            throw new runtime.RequiredError('createLifecycleElementRequestBody','Required parameter requestParameters.createLifecycleElementRequestBody was null or undefined when calling incidentFormsCreateLifecycleElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/lifecycle/elements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsCreateLifecycleElementRequestBodyToJSON(requestParameters.createLifecycleElementRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsCreateLifecycleElementResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create incident form element
     * CreateLifecycleElement IncidentForms
     */
    async incidentFormsCreateLifecycleElement(requestParameters: IncidentFormsCreateLifecycleElementRequest, initOverrides?: RequestInit): Promise<IncidentFormsCreateLifecycleElementResponseBody> {
        const response = await this.incidentFormsCreateLifecycleElementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive incident form element
     * DestroyEscalationElement IncidentForms
     */
    async incidentFormsDestroyEscalationElementRaw(requestParameters: IncidentFormsDestroyEscalationElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsDestroyEscalationElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/escalation/elements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive incident form element
     * DestroyEscalationElement IncidentForms
     */
    async incidentFormsDestroyEscalationElement(requestParameters: IncidentFormsDestroyEscalationElementRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentFormsDestroyEscalationElementRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes an expression for use in this incident form
     * DestroyExpression IncidentForms
     */
    async incidentFormsDestroyExpressionRaw(requestParameters: IncidentFormsDestroyExpressionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsDestroyExpression.');
        }

        if (requestParameters.expressionId === null || requestParameters.expressionId === undefined) {
            throw new runtime.RequiredError('expressionId','Required parameter requestParameters.expressionId was null or undefined when calling incidentFormsDestroyExpression.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/{incident_form_id}/expressions/{expression_id}`.replace(`{${"incident_form_id"}}`, encodeURIComponent(String(requestParameters.incidentFormId))).replace(`{${"expression_id"}}`, encodeURIComponent(String(requestParameters.expressionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an expression for use in this incident form
     * DestroyExpression IncidentForms
     */
    async incidentFormsDestroyExpression(requestParameters: IncidentFormsDestroyExpressionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentFormsDestroyExpressionRaw(requestParameters, initOverrides);
    }

    /**
     * Archive incident form
     * DestroyForm IncidentForms
     */
    async incidentFormsDestroyFormRaw(requestParameters: IncidentFormsDestroyFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsDestroyForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive incident form
     * DestroyForm IncidentForms
     */
    async incidentFormsDestroyForm(requestParameters: IncidentFormsDestroyFormRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentFormsDestroyFormRaw(requestParameters, initOverrides);
    }

    /**
     * Archive incident form element
     * DestroyLifecycleElement IncidentForms
     */
    async incidentFormsDestroyLifecycleElementRaw(requestParameters: IncidentFormsDestroyLifecycleElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsDestroyLifecycleElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/lifecycle/elements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive incident form element
     * DestroyLifecycleElement IncidentForms
     */
    async incidentFormsDestroyLifecycleElement(requestParameters: IncidentFormsDestroyLifecycleElementRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentFormsDestroyLifecycleElementRaw(requestParameters, initOverrides);
    }

    /**
     * GetEscalationElementBindings IncidentForms
     */
    async incidentFormsGetEscalationElementBindingsRaw(requestParameters: IncidentFormsGetEscalationElementBindingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsGetEscalationElementBindingsResponseBody>> {
        if (requestParameters.getEscalationElementBindingsRequestBody === null || requestParameters.getEscalationElementBindingsRequestBody === undefined) {
            throw new runtime.RequiredError('getEscalationElementBindingsRequestBody','Required parameter requestParameters.getEscalationElementBindingsRequestBody was null or undefined when calling incidentFormsGetEscalationElementBindings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/escalation/element_bindings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsGetEscalationElementBindingsRequestBodyToJSON(requestParameters.getEscalationElementBindingsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsGetEscalationElementBindingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * GetEscalationElementBindings IncidentForms
     */
    async incidentFormsGetEscalationElementBindings(requestParameters: IncidentFormsGetEscalationElementBindingsRequest, initOverrides?: RequestInit): Promise<IncidentFormsGetEscalationElementBindingsResponseBody> {
        const response = await this.incidentFormsGetEscalationElementBindingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * GetLifecycleElementBindings IncidentForms
     */
    async incidentFormsGetLifecycleElementBindingsRaw(requestParameters: IncidentFormsGetLifecycleElementBindingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsGetLifecycleElementBindingsResponseBody>> {
        if (requestParameters.getLifecycleElementBindingsRequestBody === null || requestParameters.getLifecycleElementBindingsRequestBody === undefined) {
            throw new runtime.RequiredError('getLifecycleElementBindingsRequestBody','Required parameter requestParameters.getLifecycleElementBindingsRequestBody was null or undefined when calling incidentFormsGetLifecycleElementBindings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/lifecycle/element_bindings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsGetLifecycleElementBindingsRequestBodyToJSON(requestParameters.getLifecycleElementBindingsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsGetLifecycleElementBindingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * GetLifecycleElementBindings IncidentForms
     */
    async incidentFormsGetLifecycleElementBindings(requestParameters: IncidentFormsGetLifecycleElementBindingsRequest, initOverrides?: RequestInit): Promise<IncidentFormsGetLifecycleElementBindingsResponseBody> {
        const response = await this.incidentFormsGetLifecycleElementBindingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available incident form elements
     * ListAvailableEscalationElements IncidentForms
     */
    async incidentFormsListAvailableEscalationElementsRaw(requestParameters: IncidentFormsListAvailableEscalationElementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsListAvailableEscalationElementsResponseBody>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsListAvailableEscalationElements.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentFormId !== undefined) {
            queryParameters['incident_form_id'] = requestParameters.incidentFormId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/escalation/available_elements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsListAvailableEscalationElementsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List available incident form elements
     * ListAvailableEscalationElements IncidentForms
     */
    async incidentFormsListAvailableEscalationElements(requestParameters: IncidentFormsListAvailableEscalationElementsRequest, initOverrides?: RequestInit): Promise<IncidentFormsListAvailableEscalationElementsResponseBody> {
        const response = await this.incidentFormsListAvailableEscalationElementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available incident form elements
     * ListAvailableLifecycleElements IncidentForms
     */
    async incidentFormsListAvailableLifecycleElementsRaw(requestParameters: IncidentFormsListAvailableLifecycleElementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsListAvailableLifecycleElementsResponseBody>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsListAvailableLifecycleElements.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentFormId !== undefined) {
            queryParameters['incident_form_id'] = requestParameters.incidentFormId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/lifecycle/available_elements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsListAvailableLifecycleElementsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List available incident form elements
     * ListAvailableLifecycleElements IncidentForms
     */
    async incidentFormsListAvailableLifecycleElements(requestParameters: IncidentFormsListAvailableLifecycleElementsRequest, initOverrides?: RequestInit): Promise<IncidentFormsListAvailableLifecycleElementsResponseBody> {
        const response = await this.incidentFormsListAvailableLifecycleElementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List incident form escalation elements
     * ListEscalationElements IncidentForms
     */
    async incidentFormsListEscalationElementsRaw(requestParameters: IncidentFormsListEscalationElementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsListEscalationElementsResponseBody>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsListEscalationElements.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentFormId !== undefined) {
            queryParameters['incident_form_id'] = requestParameters.incidentFormId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/escalation/elements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsListEscalationElementsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident form escalation elements
     * ListEscalationElements IncidentForms
     */
    async incidentFormsListEscalationElements(requestParameters: IncidentFormsListEscalationElementsRequest, initOverrides?: RequestInit): Promise<IncidentFormsListEscalationElementsResponseBody> {
        const response = await this.incidentFormsListEscalationElementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List incident forms
     * ListForms IncidentForms
     */
    async incidentFormsListFormsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsListFormsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsListFormsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident forms
     * ListForms IncidentForms
     */
    async incidentFormsListForms(initOverrides?: RequestInit): Promise<IncidentFormsListFormsResponseBody> {
        const response = await this.incidentFormsListFormsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incident form lifecycle elements
     * ListLifecycleElements IncidentForms
     */
    async incidentFormsListLifecycleElementsRaw(requestParameters: IncidentFormsListLifecycleElementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsListLifecycleElementsResponseBody>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsListLifecycleElements.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentFormId !== undefined) {
            queryParameters['incident_form_id'] = requestParameters.incidentFormId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/lifecycle/elements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsListLifecycleElementsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident form lifecycle elements
     * ListLifecycleElements IncidentForms
     */
    async incidentFormsListLifecycleElements(requestParameters: IncidentFormsListLifecycleElementsRequest, initOverrides?: RequestInit): Promise<IncidentFormsListLifecycleElementsResponseBody> {
        const response = await this.incidentFormsListLifecycleElementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * PreviewEscalateForm IncidentForms
     */
    async incidentFormsPreviewEscalateFormRaw(requestParameters: IncidentFormsPreviewEscalateFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsPreviewEscalateFormResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsPreviewEscalateForm.');
        }

        if (requestParameters.previewEscalateFormRequestBody === null || requestParameters.previewEscalateFormRequestBody === undefined) {
            throw new runtime.RequiredError('previewEscalateFormRequestBody','Required parameter requestParameters.previewEscalateFormRequestBody was null or undefined when calling incidentFormsPreviewEscalateForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/escalate_form/{id}/actions/preview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsPreviewEscalateFormRequestBodyToJSON(requestParameters.previewEscalateFormRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsPreviewEscalateFormResponseBodyFromJSON(jsonValue));
    }

    /**
     * PreviewEscalateForm IncidentForms
     */
    async incidentFormsPreviewEscalateForm(requestParameters: IncidentFormsPreviewEscalateFormRequest, initOverrides?: RequestInit): Promise<IncidentFormsPreviewEscalateFormResponseBody> {
        const response = await this.incidentFormsPreviewEscalateFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset to the default escalation elements for this form
     * ResetEscalationElements IncidentForms
     */
    async incidentFormsResetEscalationElementsRaw(requestParameters: IncidentFormsResetEscalationElementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsResetEscalationElementsResponseBody>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsResetEscalationElements.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentFormId !== undefined) {
            queryParameters['incident_form_id'] = requestParameters.incidentFormId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/escalation/elements/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsResetEscalationElementsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Reset to the default escalation elements for this form
     * ResetEscalationElements IncidentForms
     */
    async incidentFormsResetEscalationElements(requestParameters: IncidentFormsResetEscalationElementsRequest, initOverrides?: RequestInit): Promise<IncidentFormsResetEscalationElementsResponseBody> {
        const response = await this.incidentFormsResetEscalationElementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show incident forms
     * ShowForm IncidentForms
     */
    async incidentFormsShowFormRaw(requestParameters: IncidentFormsShowFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsShowFormResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsShowForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsShowFormResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show incident forms
     * ShowForm IncidentForms
     */
    async incidentFormsShowForm(requestParameters: IncidentFormsShowFormRequest, initOverrides?: RequestInit): Promise<IncidentFormsShowFormResponseBody> {
        const response = await this.incidentFormsShowFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * SubmitEscalateForm IncidentForms
     */
    async incidentFormsSubmitEscalateFormRaw(requestParameters: IncidentFormsSubmitEscalateFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsSubmitEscalateFormResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsSubmitEscalateForm.');
        }

        if (requestParameters.submitEscalateFormRequestBody === null || requestParameters.submitEscalateFormRequestBody === undefined) {
            throw new runtime.RequiredError('submitEscalateFormRequestBody','Required parameter requestParameters.submitEscalateFormRequestBody was null or undefined when calling incidentFormsSubmitEscalateForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/escalate_form/{id}/actions/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsSubmitEscalateFormRequestBodyToJSON(requestParameters.submitEscalateFormRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsSubmitEscalateFormResponseBodyFromJSON(jsonValue));
    }

    /**
     * SubmitEscalateForm IncidentForms
     */
    async incidentFormsSubmitEscalateForm(requestParameters: IncidentFormsSubmitEscalateFormRequest, initOverrides?: RequestInit): Promise<IncidentFormsSubmitEscalateFormResponseBody> {
        const response = await this.incidentFormsSubmitEscalateFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update incident form element
     * UpdateEscalationElement IncidentForms
     */
    async incidentFormsUpdateEscalationElementRaw(requestParameters: IncidentFormsUpdateEscalationElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsUpdateEscalationElementResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsUpdateEscalationElement.');
        }

        if (requestParameters.updateEscalationElementRequestBody === null || requestParameters.updateEscalationElementRequestBody === undefined) {
            throw new runtime.RequiredError('updateEscalationElementRequestBody','Required parameter requestParameters.updateEscalationElementRequestBody was null or undefined when calling incidentFormsUpdateEscalationElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/escalation/elements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsUpdateEscalationElementRequestBodyToJSON(requestParameters.updateEscalationElementRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsUpdateEscalationElementResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update incident form element
     * UpdateEscalationElement IncidentForms
     */
    async incidentFormsUpdateEscalationElement(requestParameters: IncidentFormsUpdateEscalationElementRequest, initOverrides?: RequestInit): Promise<IncidentFormsUpdateEscalationElementResponseBody> {
        const response = await this.incidentFormsUpdateEscalationElementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of the elements within a form
     * UpdateEscalationRanks IncidentForms
     */
    async incidentFormsUpdateEscalationRanksRaw(requestParameters: IncidentFormsUpdateEscalationRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsUpdateEscalationRanksResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsUpdateEscalationRanks.');
        }

        if (requestParameters.updateEscalationRanksRequestBody === null || requestParameters.updateEscalationRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateEscalationRanksRequestBody','Required parameter requestParameters.updateEscalationRanksRequestBody was null or undefined when calling incidentFormsUpdateEscalationRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/escalation/{id}/actions/update_ranks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsUpdateEscalationRanksRequestBodyToJSON(requestParameters.updateEscalationRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsUpdateEscalationRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of the elements within a form
     * UpdateEscalationRanks IncidentForms
     */
    async incidentFormsUpdateEscalationRanks(requestParameters: IncidentFormsUpdateEscalationRanksRequest, initOverrides?: RequestInit): Promise<IncidentFormsUpdateEscalationRanksResponseBody> {
        const response = await this.incidentFormsUpdateEscalationRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an expression for use in this incident form
     * UpdateExpression IncidentForms
     */
    async incidentFormsUpdateExpressionRaw(requestParameters: IncidentFormsUpdateExpressionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsUpdateExpressionResponseBody>> {
        if (requestParameters.incidentFormId === null || requestParameters.incidentFormId === undefined) {
            throw new runtime.RequiredError('incidentFormId','Required parameter requestParameters.incidentFormId was null or undefined when calling incidentFormsUpdateExpression.');
        }

        if (requestParameters.expressionId === null || requestParameters.expressionId === undefined) {
            throw new runtime.RequiredError('expressionId','Required parameter requestParameters.expressionId was null or undefined when calling incidentFormsUpdateExpression.');
        }

        if (requestParameters.updateExpressionRequestBody === null || requestParameters.updateExpressionRequestBody === undefined) {
            throw new runtime.RequiredError('updateExpressionRequestBody','Required parameter requestParameters.updateExpressionRequestBody was null or undefined when calling incidentFormsUpdateExpression.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/{incident_form_id}/expressions/{expression_id}`.replace(`{${"incident_form_id"}}`, encodeURIComponent(String(requestParameters.incidentFormId))).replace(`{${"expression_id"}}`, encodeURIComponent(String(requestParameters.expressionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsUpdateExpressionRequestBodyToJSON(requestParameters.updateExpressionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsUpdateExpressionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates an expression for use in this incident form
     * UpdateExpression IncidentForms
     */
    async incidentFormsUpdateExpression(requestParameters: IncidentFormsUpdateExpressionRequest, initOverrides?: RequestInit): Promise<IncidentFormsUpdateExpressionResponseBody> {
        const response = await this.incidentFormsUpdateExpressionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident form
     * UpdateForm IncidentForms
     */
    async incidentFormsUpdateFormRaw(requestParameters: IncidentFormsUpdateFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsUpdateFormResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsUpdateForm.');
        }

        if (requestParameters.updateFormRequestBody === null || requestParameters.updateFormRequestBody === undefined) {
            throw new runtime.RequiredError('updateFormRequestBody','Required parameter requestParameters.updateFormRequestBody was null or undefined when calling incidentFormsUpdateForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsUpdateFormRequestBodyToJSON(requestParameters.updateFormRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsUpdateFormResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident form
     * UpdateForm IncidentForms
     */
    async incidentFormsUpdateForm(requestParameters: IncidentFormsUpdateFormRequest, initOverrides?: RequestInit): Promise<IncidentFormsUpdateFormResponseBody> {
        const response = await this.incidentFormsUpdateFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update incident form element
     * UpdateLifecycleElement IncidentForms
     */
    async incidentFormsUpdateLifecycleElementRaw(requestParameters: IncidentFormsUpdateLifecycleElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsUpdateLifecycleElementResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsUpdateLifecycleElement.');
        }

        if (requestParameters.updateLifecycleElementRequestBody === null || requestParameters.updateLifecycleElementRequestBody === undefined) {
            throw new runtime.RequiredError('updateLifecycleElementRequestBody','Required parameter requestParameters.updateLifecycleElementRequestBody was null or undefined when calling incidentFormsUpdateLifecycleElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/lifecycle/elements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsUpdateLifecycleElementRequestBodyToJSON(requestParameters.updateLifecycleElementRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsUpdateLifecycleElementResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update incident form element
     * UpdateLifecycleElement IncidentForms
     */
    async incidentFormsUpdateLifecycleElement(requestParameters: IncidentFormsUpdateLifecycleElementRequest, initOverrides?: RequestInit): Promise<IncidentFormsUpdateLifecycleElementResponseBody> {
        const response = await this.incidentFormsUpdateLifecycleElementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of the elements within a form
     * UpdateLifecycleRanks IncidentForms
     */
    async incidentFormsUpdateLifecycleRanksRaw(requestParameters: IncidentFormsUpdateLifecycleRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentFormsUpdateLifecycleRanksResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentFormsUpdateLifecycleRanks.');
        }

        if (requestParameters.updateLifecycleRanksRequestBody === null || requestParameters.updateLifecycleRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateLifecycleRanksRequestBody','Required parameter requestParameters.updateLifecycleRanksRequestBody was null or undefined when calling incidentFormsUpdateLifecycleRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_forms/lifecycle/{id}/actions/update_ranks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormsUpdateLifecycleRanksRequestBodyToJSON(requestParameters.updateLifecycleRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFormsUpdateLifecycleRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of the elements within a form
     * UpdateLifecycleRanks IncidentForms
     */
    async incidentFormsUpdateLifecycleRanks(requestParameters: IncidentFormsUpdateLifecycleRanksRequest, initOverrides?: RequestInit): Promise<IncidentFormsUpdateLifecycleRanksResponseBody> {
        const response = await this.incidentFormsUpdateLifecycleRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
