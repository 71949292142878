/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentRelationshipsCreateRequestBody
 */
export interface IncidentRelationshipsCreateRequestBody {
    /**
     * ID of the left (or source) incident to add a relationship for
     * @type {string}
     * @memberof IncidentRelationshipsCreateRequestBody
     */
    left_incident_id: string;
    /**
     * ID of the right (or related) incident
     * @type {string}
     * @memberof IncidentRelationshipsCreateRequestBody
     */
    right_incident_id: string;
    /**
     * Status of the relationship
     * @type {string}
     * @memberof IncidentRelationshipsCreateRequestBody
     */
    status: string;
}

export function IncidentRelationshipsCreateRequestBodyFromJSON(json: any): IncidentRelationshipsCreateRequestBody {
    return IncidentRelationshipsCreateRequestBodyFromJSONTyped(json, false);
}

export function IncidentRelationshipsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRelationshipsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'left_incident_id': json['left_incident_id'],
        'right_incident_id': json['right_incident_id'],
        'status': json['status'],
    };
}

export function IncidentRelationshipsCreateRequestBodyToJSON(value?: IncidentRelationshipsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'left_incident_id': value.left_incident_id,
        'right_incident_id': value.right_incident_id,
        'status': value.status,
    };
}

