/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface InternalStatusPageCreatePageRequestBody
 */
export interface InternalStatusPageCreatePageRequestBody {
    /**
     * Whether or not groups should be expanded by default
     * @type {boolean}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    auto_expand_groups: boolean;
    /**
     * The ID of the custom field that this internal status page uses for components
     * @type {string}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    component_custom_field_id: string;
    /**
     * 
     * @type {ExpressionPayload}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    component_source_expression?: ExpressionPayload;
    /**
     * The GCS key where the logo for this status page is stored
     * @type {string}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    darkmode_logo_key?: string;
    /**
     * Which custom fields should be displayed on the page
     * @type {Array<string>}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    display_custom_field_ids: Array<string>;
    /**
     * IDs of the catalog custom field entries that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    hidden_catalog_entry_ids: Array<string>;
    /**
     * Values of the catalog custom field groups that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    hidden_catalog_group_values: Array<string>;
    /**
     * IDs of the custom field option groups that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    hidden_custom_field_group_ids: Array<string>;
    /**
     * IDs of the custom field options that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    hidden_custom_field_option_ids: Array<string>;
    /**
     * The GCS key where the lightmode logo for this status page is stored
     * @type {string}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    lightmode_logo_key?: string;
    /**
     * The name of the internal status page
     * @type {string}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    name: string;
    /**
     * Configure whether the summary is shown on the status page
     * @type {boolean}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    setting_display_summary: boolean;
    /**
     * The subpath of the internal status page
     * @type {string}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    subpath: string;
    /**
     * Whether to use Light mode or Dark mode
     * @type {string}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    theme: InternalStatusPageCreatePageRequestBodyThemeEnum;
    /**
     * In the headsup bar at the top of an internal status page, configure if groups or components are shown
     * @type {boolean}
     * @memberof InternalStatusPageCreatePageRequestBody
     */
    use_currently_affected_components_over_groups: boolean;
}

/**
* @export
* @enum {string}
*/
export enum InternalStatusPageCreatePageRequestBodyThemeEnum {
    Light = 'light',
    Dark = 'dark'
}

export function InternalStatusPageCreatePageRequestBodyFromJSON(json: any): InternalStatusPageCreatePageRequestBody {
    return InternalStatusPageCreatePageRequestBodyFromJSONTyped(json, false);
}

export function InternalStatusPageCreatePageRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageCreatePageRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_expand_groups': json['auto_expand_groups'],
        'component_custom_field_id': json['component_custom_field_id'],
        'component_source_expression': !exists(json, 'component_source_expression') ? undefined : ExpressionPayloadFromJSON(json['component_source_expression']),
        'darkmode_logo_key': !exists(json, 'darkmode_logo_key') ? undefined : json['darkmode_logo_key'],
        'display_custom_field_ids': json['display_custom_field_ids'],
        'hidden_catalog_entry_ids': json['hidden_catalog_entry_ids'],
        'hidden_catalog_group_values': json['hidden_catalog_group_values'],
        'hidden_custom_field_group_ids': json['hidden_custom_field_group_ids'],
        'hidden_custom_field_option_ids': json['hidden_custom_field_option_ids'],
        'lightmode_logo_key': !exists(json, 'lightmode_logo_key') ? undefined : json['lightmode_logo_key'],
        'name': json['name'],
        'setting_display_summary': json['setting_display_summary'],
        'subpath': json['subpath'],
        'theme': json['theme'],
        'use_currently_affected_components_over_groups': json['use_currently_affected_components_over_groups'],
    };
}

export function InternalStatusPageCreatePageRequestBodyToJSON(value?: InternalStatusPageCreatePageRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_expand_groups': value.auto_expand_groups,
        'component_custom_field_id': value.component_custom_field_id,
        'component_source_expression': ExpressionPayloadToJSON(value.component_source_expression),
        'darkmode_logo_key': value.darkmode_logo_key,
        'display_custom_field_ids': value.display_custom_field_ids,
        'hidden_catalog_entry_ids': value.hidden_catalog_entry_ids,
        'hidden_catalog_group_values': value.hidden_catalog_group_values,
        'hidden_custom_field_group_ids': value.hidden_custom_field_group_ids,
        'hidden_custom_field_option_ids': value.hidden_custom_field_option_ids,
        'lightmode_logo_key': value.lightmode_logo_key,
        'name': value.name,
        'setting_display_summary': value.setting_display_summary,
        'subpath': value.subpath,
        'theme': value.theme,
        'use_currently_affected_components_over_groups': value.use_currently_affected_components_over_groups,
    };
}

