/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface ExpressionBranchPayload
 */
export interface ExpressionBranchPayload {
    /**
     * When one of these condition groups are satisfied, this branch will be evaluated
     * @type {Array<ConditionGroupPayload>}
     * @memberof ExpressionBranchPayload
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof ExpressionBranchPayload
     */
    result: EngineParamBindingPayload;
}

export function ExpressionBranchPayloadFromJSON(json: any): ExpressionBranchPayload {
    return ExpressionBranchPayloadFromJSONTyped(json, false);
}

export function ExpressionBranchPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionBranchPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'result': EngineParamBindingPayloadFromJSON(json['result']),
    };
}

export function ExpressionBranchPayloadToJSON(value?: ExpressionBranchPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'result': EngineParamBindingPayloadToJSON(value.result),
    };
}

