/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JiraAutoExportConfig
 */
export interface JiraAutoExportConfig {
    /**
     * Dynamic field keys mapped to values
     * @type {object}
     * @memberof JiraAutoExportConfig
     */
    dynamic_fields?: object;
    /**
     * Whether private incidents will be exported
     * @type {boolean}
     * @memberof JiraAutoExportConfig
     */
    include_private_incidents: boolean;
    /**
     * The Jira issue type that should be used to create incident tickets
     * @type {string}
     * @memberof JiraAutoExportConfig
     */
    issue_type_id: string;
    /**
     * The minimum severity ID which should result in a jira ticket
     * @type {string}
     * @memberof JiraAutoExportConfig
     */
    min_severity_id: string;
    /**
     * The Jira Project ID that incident tickets should be created in
     * @type {string}
     * @memberof JiraAutoExportConfig
     */
    project_id: string;
}

export function JiraAutoExportConfigFromJSON(json: any): JiraAutoExportConfig {
    return JiraAutoExportConfigFromJSONTyped(json, false);
}

export function JiraAutoExportConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraAutoExportConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamic_fields': !exists(json, 'dynamic_fields') ? undefined : json['dynamic_fields'],
        'include_private_incidents': json['include_private_incidents'],
        'issue_type_id': json['issue_type_id'],
        'min_severity_id': json['min_severity_id'],
        'project_id': json['project_id'],
    };
}

export function JiraAutoExportConfigToJSON(value?: JiraAutoExportConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamic_fields': value.dynamic_fields,
        'include_private_incidents': value.include_private_incidents,
        'issue_type_id': value.issue_type_id,
        'min_severity_id': value.min_severity_id,
        'project_id': value.project_id,
    };
}

