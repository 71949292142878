import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Card, Tooltip } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { useHowLongAgoFromDate } from "./hooks";

export const CurrentTopicFancy = ({
  currentTopic,
  lastUpdatedAt,
  animateOnMount,
}: {
  currentTopic: string;
  lastUpdatedAt: Date;
  animateOnMount: boolean;
}) => {
  const updatedAgo = useHowLongAgoFromDate(lastUpdatedAt);

  return (
    <Card
      key={lastUpdatedAt.toISOString()}
      title="Current topic"
      className={animateOnMount ? scribeStyles.backgroundGradient : undefined}
      color={ColorPaletteEnum.Scribe}
      description={currentTopic}
      clickable={false}
      compact={true}
      iconOverride={<></>}
      headerAccessory={
        <div
          className={tcx(
            "text-content-tertiary text-xs-med hover:!no-underline flex gap-1",
          )}
        >
          <span>{updatedAgo}</span>
          <Tooltip
            content={
              "Updated based on recent conversations and updates every 2 minutes"
            }
          />
        </div>
      }
    />
  );
};
