/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemHealthCheckResponseBody
 */
export interface SystemHealthCheckResponseBody {
    /**
     * Status of the API
     * @type {string}
     * @memberof SystemHealthCheckResponseBody
     */
    status: SystemHealthCheckResponseBodyStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum SystemHealthCheckResponseBodyStatusEnum {
    Healthy = 'healthy'
}

export function SystemHealthCheckResponseBodyFromJSON(json: any): SystemHealthCheckResponseBody {
    return SystemHealthCheckResponseBodyFromJSONTyped(json, false);
}

export function SystemHealthCheckResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemHealthCheckResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function SystemHealthCheckResponseBodyToJSON(value?: SystemHealthCheckResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

