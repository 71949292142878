import type { SVGProps } from "react";
import * as React from "react";
const SvgMedal1 = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="m13.52 8.249-3.229-5.39a2.23 2.23 0 0 0-1.905-1.081H2.903A2.221 2.221 0 0 0 .997 5.142l4.854 8.127a12.469 12.469 0 0 1 7.669-5.02ZM31.031 2.905a2.229 2.229 0 0 0-1.934-1.127h-5.483c-.778 0-1.51.414-1.905 1.079L18.48 8.249c3.16.642 5.884 2.49 7.684 5.036l4.839-8.142a2.23 2.23 0 0 0 .028-2.238Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.222 20.444c0-5.392 4.388-9.777 9.778-9.777 5.39 0 9.778 4.385 9.778 9.777 0 5.392-4.388 9.778-9.778 9.778-5.39 0-9.778-4.386-9.778-9.778Zm9.74-1.024V25h1.704v-8.28H16.13c-.336.912-1.008 1.32-2.16 1.32v1.38h1.992Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMedal1;
