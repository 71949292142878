import type { SVGProps } from "react";
import * as React from "react";
const SvgMedal2 = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="m13.52 8.249-3.229-5.39a2.23 2.23 0 0 0-1.905-1.081H2.903A2.221 2.221 0 0 0 .997 5.142l4.854 8.127a12.469 12.469 0 0 1 7.669-5.02ZM31.031 2.905a2.23 2.23 0 0 0-1.934-1.127h-5.483c-.779 0-1.51.414-1.906 1.079L18.48 8.249c3.16.642 5.884 2.49 7.683 5.036l4.84-8.142c.41-.686.421-1.543.028-2.238Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.222 20.444c0-5.392 4.388-9.777 9.778-9.777 5.39 0 9.778 4.385 9.778 9.777 0 5.392-4.388 9.778-9.778 9.778-5.39 0-9.778-4.386-9.778-9.778Zm9.057.893c-1.112.788-2.315 1.64-2.315 3.135V25h5.952v-1.512h-3.66c.348-.521.917-.926 1.502-1.342 1.03-.733 2.11-1.501 2.11-3.002 0-1.56-1.104-2.568-2.82-2.568-1.776 0-2.94 1.068-2.976 2.724h1.752c.036-.828.468-1.308 1.188-1.308.708 0 1.116.396 1.116 1.08 0 .955-.891 1.586-1.849 2.265Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMedal2;
