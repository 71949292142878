import { BadgeSize } from "@incident-ui/Badge/Badge";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

export type ProductMarketingBannerProps = {
  className?: string;
  graphic?: React.ReactNode;
  cta?: React.ReactNode;
  secondaryCta?: React.ReactNode;
  ctaPosition?: "right" | "bottom";
  title: React.ReactNode;
  badge?: React.ReactNode;
  subtitle?: React.ReactNode;
  closeAction?: () => void;
};

export function ProductMarketingBanner({
  title,
  badge,
  subtitle,
  className,
  cta,
  secondaryCta,
  graphic,
  closeAction,
}: ProductMarketingBannerProps): React.ReactElement {
  return (
    <div
      className={tcx(
        "w-full text-sm rounded-2",
        "flex items-center gap-6 px-6",
        "bg-surface-secondary",
        className,
      )}
    >
      <div className="flex flex-col gap-4 items-start justify-center py-6">
        <div className="flex flex-col gap-0.5 text-sm">
          <div className="text-base-bold flex items-center gap-2">
            {title}
            {badge}
          </div>
          {subtitle && <div className="text-sm-normal">{subtitle}</div>}
        </div>
        {(cta || secondaryCta) && (
          <div className="flex gap-4 items-center">
            {cta}
            {secondaryCta}
          </div>
        )}
      </div>
      <div className="grow" />
      {graphic && <div className="hidden xl:block shrink-0">{graphic}</div>}
      {closeAction && (
        <div className="flex flex-col self-start py-6">
          <Button
            analyticsTrackingId={null}
            icon={IconEnum.Close}
            size={BadgeSize.Large}
            theme={ButtonTheme.Naked}
            title=""
            onClick={closeAction}
          />
        </div>
      )}
    </div>
  );
}
