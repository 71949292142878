/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsGrafanaInstallRequestBody,
    IntegrationsGrafanaInstallRequestBodyFromJSON,
    IntegrationsGrafanaInstallRequestBodyToJSON,
    IntegrationsGrafanaShowResponseBody,
    IntegrationsGrafanaShowResponseBodyFromJSON,
    IntegrationsGrafanaShowResponseBodyToJSON,
} from '../models';

export interface IntegrationsGrafanaInstallRequest {
    installRequestBody: IntegrationsGrafanaInstallRequestBody;
}

/**
 * 
 */
export class IntegrationsGrafanaApi extends runtime.BaseAPI {

    /**
     * Install Grafana using an API key
     * Install IntegrationsGrafana
     */
    async integrationsGrafanaInstallRaw(requestParameters: IntegrationsGrafanaInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installRequestBody === null || requestParameters.installRequestBody === undefined) {
            throw new runtime.RequiredError('installRequestBody','Required parameter requestParameters.installRequestBody was null or undefined when calling integrationsGrafanaInstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/grafana`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsGrafanaInstallRequestBodyToJSON(requestParameters.installRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Install Grafana using an API key
     * Install IntegrationsGrafana
     */
    async integrationsGrafanaInstall(requestParameters: IntegrationsGrafanaInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGrafanaInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Show Grafana integration information
     * Show IntegrationsGrafana
     */
    async integrationsGrafanaShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsGrafanaShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/grafana`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsGrafanaShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show Grafana integration information
     * Show IntegrationsGrafana
     */
    async integrationsGrafanaShow(initOverrides?: RequestInit): Promise<IntegrationsGrafanaShowResponseBody> {
        const response = await this.integrationsGrafanaShowRaw(initOverrides);
        return await response.value();
    }

    /**
     * Remove Grafana integration
     * Uninstall IntegrationsGrafana
     */
    async integrationsGrafanaUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/grafana`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Grafana integration
     * Uninstall IntegrationsGrafana
     */
    async integrationsGrafanaUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGrafanaUninstallRaw(initOverrides);
    }

}
