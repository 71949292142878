import { Product } from "@incident-shared/billing";
import { conditionGroupsToConditions } from "@incident-shared/engine/conditions";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import {
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Loader,
  SlackButtonPreview,
  Txt,
} from "@incident-ui";
import { captureMessage } from "@sentry/react";
import _ from "lodash";
import React, { useState } from "react";
import { SettingsSortableList } from "src/components/settings/SettingsSortableList";
import { SettingsSubHeading } from "src/components/settings/SettingsSubHeading";
import { SlackChannelQuickActionsCreateEditModal } from "src/components/settings/slack-channel/SlackChannelQuickActionsCreateEditModal";
import {
  EnabledSlackQuickAction,
  EnabledSlackQuickActionActionTypeEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIMutation } from "src/utils/swr";

export const SlackChannelQuickActionsEditSection = ({
  canEditSettings,
}: {
  canEditSettings: boolean;
}): React.ReactElement => {
  const [showQuickActionModal, setShowAddQuickActionModal] =
    useState<boolean>(false);
  const [editingQuickAction, setEditingQuickAction] =
    useState<EnabledSlackQuickAction | null>(null);

  const { hasResponse } = useProductAccess();

  const {
    data: { quick_actions: enabledQuickActions },
    isLoading: loading,
    error: fetchEnabledQuickActionsError,
  } = useAPI("incidentChannelConfigsListEnabledQuickActions", undefined, {
    fallbackData: { quick_actions: [] },
  });

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "incidentChannelConfigsListEnabledQuickActions",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.incidentChannelConfigsDestroyQuickAction({ id });
    },
  );

  const { trigger: updateRanks, isMutating: updatingRanks } = useAPIMutation(
    "incidentChannelConfigsListEnabledQuickActions",
    undefined,
    async (apiClient, updatedQuickActions: EnabledSlackQuickAction[]) => {
      if (!enabledQuickActions) {
        // This can't happen: we can only update ranks of existing quick actions.
        // But typescript needs to be told they'll exist when running the below code.
        return undefined;
      }
      const rank_updates = enabledQuickActions.map((quickAction) => {
        let rank = quickAction.rank;
        const updated = updatedQuickActions.find(
          (x) => x.id === quickAction.id,
        );
        if (updated) {
          rank = updated.rank;
        }
        return {
          resource_id: quickAction.id,
          rank,
        };
      });
      return await apiClient.incidentChannelConfigsUpdateQuickActionRanks({
        updateQuickActionRanksRequestBody: { rank_updates },
      });
    },
  );
  const saving = deleting || updatingRanks;

  if (loading) {
    return <Loader />;
  }

  if (fetchEnabledQuickActionsError) {
    console.error(fetchEnabledQuickActionsError);
    captureMessage("unable to fetch quick actions", {
      extra: { error: fetchEnabledQuickActionsError },
    });
    return <GenericErrorMessage error={fetchEnabledQuickActionsError} />;
  }

  return (
    <div>
      <SettingsSubHeading
        title="Welcome message quick actions"
        titleHeadingLevel={2}
        className="!mb-2"
        explanation={
          `
         When you create an incident, we send a welcome message to the channel
         which includes some quick actions. You can select these from our
         pre-defined list` + (hasResponse ? " or create your own." : ".")
        }
        accessory={
          <GatedButton
            onClick={() => setShowAddQuickActionModal(true)}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            requiredProduct={Product.Response}
            analyticsTrackingId="add-quick-action"
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
          >
            Add new
          </GatedButton>
        }
      />
      {enabledQuickActions.length === 0 ? (
        <EmptyState
          icon={IconEnum.SlackGreyscale}
          content="All quick actions have been disabled"
        />
      ) : (
        <SettingsSortableList
          canEdit={canEditSettings}
          updateItemRanks={updateRanks}
          saving={saving}
          items={_.sortBy(enabledQuickActions, (qa) => qa.rank)}
          renderItem={(quickAction) => (
            <QuickActionRow
              key={quickAction.id}
              quickAction={quickAction}
              onEdit={() => setEditingQuickAction(quickAction)}
              onDelete={() => onDelete(quickAction)}
            />
          )}
        />
      )}
      {editingQuickAction && (
        <SlackChannelQuickActionsCreateEditModal
          quickAction={editingQuickAction}
          enabledQuickActions={enabledQuickActions}
          onClose={() => setEditingQuickAction(null)}
        />
      )}
      {showQuickActionModal && (
        <SlackChannelQuickActionsCreateEditModal
          enabledQuickActions={enabledQuickActions}
          onClose={() => setShowAddQuickActionModal(false)}
        />
      )}
    </div>
  );
};

const QuickActionRow = ({
  quickAction,
  onDelete,
  onEdit,
}: {
  quickAction: EnabledSlackQuickAction;
  onDelete: () => Promise<unknown>;
  onEdit: () => void;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const badge = (
    <SlackButtonPreview emoji={quickAction.emoji} text={quickAction.text} />
  );

  return (
    <SettingsListItem
      title={
        <div className="flex items-center gap-2">
          {badge}
          <Txt inline className="text-content-tertiary text-xs truncate">
            {quickAction.custom_url || quickAction.eligibility_reason}
          </Txt>
        </div>
      }
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        requiredProduct: Product.Response,
        edit: {
          onEdit,
          isGatedText:
            identity?.feature_gates.incident_types ||
            quickAction.action_type ===
              EnabledSlackQuickActionActionTypeEnum.Custom
              ? undefined
              : "To customise pre-defined quick actions, you need to upgrade to a Pro plan",
        },
        delete: {
          resourceTitle: quickAction.text,
          deleteConfirmationTitle: "Delete quick action",
          deleteConfirmationContent: (
            <Txt>
              <p>
                Are you sure you want to delete the{" "}
                <Txt inline bold>
                  {quickAction.text}
                </Txt>{" "}
                quick action?
              </p>
            </Txt>
          ),
          onDelete,
        },
      }}
      conditions={conditionGroupsToConditions(quickAction.condition_groups)}
    />
  );
};
