/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DecisionFlow,
    DecisionFlowFromJSON,
    DecisionFlowFromJSONTyped,
    DecisionFlowToJSON,
} from './DecisionFlow';
import {
    DependentResource,
    DependentResourceFromJSON,
    DependentResourceFromJSONTyped,
    DependentResourceToJSON,
} from './DependentResource';

/**
 * 
 * @export
 * @interface DecisionFlowsShowResponseBody
 */
export interface DecisionFlowsShowResponseBody {
    /**
     * 
     * @type {DecisionFlow}
     * @memberof DecisionFlowsShowResponseBody
     */
    decision_flow: DecisionFlow;
    /**
     * 
     * @type {Array<DependentResource>}
     * @memberof DecisionFlowsShowResponseBody
     */
    dependent_resources: Array<DependentResource>;
}

export function DecisionFlowsShowResponseBodyFromJSON(json: any): DecisionFlowsShowResponseBody {
    return DecisionFlowsShowResponseBodyFromJSONTyped(json, false);
}

export function DecisionFlowsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionFlowsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decision_flow': DecisionFlowFromJSON(json['decision_flow']),
        'dependent_resources': ((json['dependent_resources'] as Array<any>).map(DependentResourceFromJSON)),
    };
}

export function DecisionFlowsShowResponseBodyToJSON(value?: DecisionFlowsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decision_flow': DecisionFlowToJSON(value.decision_flow),
        'dependent_resources': ((value.dependent_resources as Array<any>).map(DependentResourceToJSON)),
    };
}

