/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentFormLifecycleElement,
    IncidentFormLifecycleElementFromJSON,
    IncidentFormLifecycleElementFromJSONTyped,
    IncidentFormLifecycleElementToJSON,
} from './IncidentFormLifecycleElement';

/**
 * 
 * @export
 * @interface IncidentFormsUpdateLifecycleRanksResponseBody
 */
export interface IncidentFormsUpdateLifecycleRanksResponseBody {
    /**
     * 
     * @type {Array<IncidentFormLifecycleElement>}
     * @memberof IncidentFormsUpdateLifecycleRanksResponseBody
     */
    incident_form_elements: Array<IncidentFormLifecycleElement>;
}

export function IncidentFormsUpdateLifecycleRanksResponseBodyFromJSON(json: any): IncidentFormsUpdateLifecycleRanksResponseBody {
    return IncidentFormsUpdateLifecycleRanksResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsUpdateLifecycleRanksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsUpdateLifecycleRanksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_form_elements': ((json['incident_form_elements'] as Array<any>).map(IncidentFormLifecycleElementFromJSON)),
    };
}

export function IncidentFormsUpdateLifecycleRanksResponseBodyToJSON(value?: IncidentFormsUpdateLifecycleRanksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_form_elements': ((value.incident_form_elements as Array<any>).map(IncidentFormLifecycleElementToJSON)),
    };
}

