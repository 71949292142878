/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DemoListCommandsResponseBody,
    DemoListCommandsResponseBodyFromJSON,
    DemoListCommandsResponseBodyToJSON,
    DemoRunCommandRequestBody,
    DemoRunCommandRequestBodyFromJSON,
    DemoRunCommandRequestBodyToJSON,
    DemoRunCommandResponseBody,
    DemoRunCommandResponseBodyFromJSON,
    DemoRunCommandResponseBodyToJSON,
} from '../models';

export interface DemoRunCommandRequest {
    id: string;
    runCommandRequestBody: DemoRunCommandRequestBody;
}

/**
 * 
 */
export class DemoApi extends runtime.BaseAPI {

    /**
     * List available demo magic commands, used from the /demo-magic page
     * ListCommands Demo
     */
    async demoListCommandsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<DemoListCommandsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/demo/commands/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemoListCommandsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List available demo magic commands, used from the /demo-magic page
     * ListCommands Demo
     */
    async demoListCommands(initOverrides?: RequestInit): Promise<DemoListCommandsResponseBody> {
        const response = await this.demoListCommandsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Run a demo magic command
     * RunCommand Demo
     */
    async demoRunCommandRaw(requestParameters: DemoRunCommandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DemoRunCommandResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling demoRunCommand.');
        }

        if (requestParameters.runCommandRequestBody === null || requestParameters.runCommandRequestBody === undefined) {
            throw new runtime.RequiredError('runCommandRequestBody','Required parameter requestParameters.runCommandRequestBody was null or undefined when calling demoRunCommand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/demo/commands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemoRunCommandRequestBodyToJSON(requestParameters.runCommandRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemoRunCommandResponseBodyFromJSON(jsonValue));
    }

    /**
     * Run a demo magic command
     * RunCommand Demo
     */
    async demoRunCommand(requestParameters: DemoRunCommandRequest, initOverrides?: RequestInit): Promise<DemoRunCommandResponseBody> {
        const response = await this.demoRunCommandRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
