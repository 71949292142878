import { GatedButtonSpecificProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { UpgradeRequiredButtonProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, Heading, Txt } from "@incident-ui";
import { ButtonProps } from "@incident-ui/Button/Button";
import { ImgWithLoadingSkeleton } from "@incident-ui/ImgLoadingSkeleton/ImgWithLoadingSkeleton";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

type OptionalGating =
  | {
      isGated?: true;
      gatingProps?: GatedButtonSpecificProps & UpgradeRequiredButtonProps;
    }
  | { isGated?: false; gatingProps?: never };

export type EmptyStateProps = {
  copy: React.ReactNode;
  imageSrc: string;
  title: string;
  buttons: (ButtonProps & OptionalGating)[];
  imageClassName?: string;
  imageOverlay?: React.ReactNode;
} & React.PropsWithChildren<unknown>;

export const EmptyState = ({
  buttons,
  copy,
  imageSrc,
  imageClassName,
  imageOverlay,
  title,
  children,
}: EmptyStateProps): React.ReactElement => {
  return (
    <div className="h-full rounded-2xl p-10 pt-12 flex flex-1 justify-around overflow-hidden bg-gradient-to-b from-surface-secondary to-surface-primary">
      <AnimatePresence>
        <motion.div
          className="flex-center flex-col items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="relative">
            <ImgWithLoadingSkeleton
              className={tcx("max-h-[330px] mb-5", imageClassName)}
              src={imageSrc}
              alt="Empty state hero"
            />
            {imageOverlay}
          </div>
          <div className="max-w-[500px] flex flex-col items-center justify-center text-center">
            <Heading
              level={1}
              size={"2xl"}
              className="mb-2 font-bold tracking-tight"
            >
              {title}
            </Heading>
            <Txt className="mb-10 max-w-[80%]" grey>
              {copy}
            </Txt>
            <div className={"flex flex-row items-center space-x-2"}>
              {buttons.map((ctaButton) => {
                const Component = ctaButton.isGated ? GatedButton : Button;
                return (
                  <Component
                    key={ctaButton.analyticsTrackingId}
                    theme={ButtonTheme.Primary}
                    {...ctaButton}
                    {...ctaButton.gatingProps}
                    className={tcx("px-4", ctaButton.className)}
                  />
                );
              })}
            </div>
          </div>
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
