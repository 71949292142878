/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AIStaffListCopilotRequestsResponseBody,
    AIStaffListCopilotRequestsResponseBodyFromJSON,
    AIStaffListCopilotRequestsResponseBodyToJSON,
    AIStaffListCopilotThreadsResponseBody,
    AIStaffListCopilotThreadsResponseBodyFromJSON,
    AIStaffListCopilotThreadsResponseBodyToJSON,
    AIStaffListInvestigationsResponseBody,
    AIStaffListInvestigationsResponseBodyFromJSON,
    AIStaffListInvestigationsResponseBodyToJSON,
    AIStaffShowInvestigationResponseBody,
    AIStaffShowInvestigationResponseBodyFromJSON,
    AIStaffShowInvestigationResponseBodyToJSON,
    AIStaffTestPromptRequestBody,
    AIStaffTestPromptRequestBodyFromJSON,
    AIStaffTestPromptRequestBodyToJSON,
    AIStaffTestPromptResponseBody,
    AIStaffTestPromptResponseBodyFromJSON,
    AIStaffTestPromptResponseBodyToJSON,
} from '../models';

export interface AIStaffListCopilotRequestsRequest {
    traceId?: string;
    prompt?: string;
    copilotInteractionId?: string;
    threadId?: string;
}

export interface AIStaffListCopilotThreadsRequest {
    prompt?: string;
}

export interface AIStaffListInvestigationsRequest {
    pageSize?: number;
    after?: string;
    incidentId?: string;
}

export interface AIStaffShowInvestigationRequest {
    id: string;
}

export interface AIStaffTestPromptRequest {
    testPromptRequestBody: AIStaffTestPromptRequestBody;
}

/**
 * 
 */
export class AIStaffApi extends runtime.BaseAPI {

    /**
     * Show YAML evals for given AI requests
     * ListCopilotRequests AI Staff
     */
    async aIStaffListCopilotRequestsRaw(requestParameters: AIStaffListCopilotRequestsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListCopilotRequestsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.traceId !== undefined) {
            queryParameters['trace_id'] = requestParameters.traceId;
        }

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        if (requestParameters.copilotInteractionId !== undefined) {
            queryParameters['copilot_interaction_id'] = requestParameters.copilotInteractionId;
        }

        if (requestParameters.threadId !== undefined) {
            queryParameters['thread_id'] = requestParameters.threadId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListCopilotRequestsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show YAML evals for given AI requests
     * ListCopilotRequests AI Staff
     */
    async aIStaffListCopilotRequests(requestParameters: AIStaffListCopilotRequestsRequest, initOverrides?: RequestInit): Promise<AIStaffListCopilotRequestsResponseBody> {
        const response = await this.aIStaffListCopilotRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists recent threads between Copilot and users
     * ListCopilotThreads AI Staff
     */
    async aIStaffListCopilotThreadsRaw(requestParameters: AIStaffListCopilotThreadsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListCopilotThreadsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_threads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListCopilotThreadsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists recent threads between Copilot and users
     * ListCopilotThreads AI Staff
     */
    async aIStaffListCopilotThreads(requestParameters: AIStaffListCopilotThreadsRequest, initOverrides?: RequestInit): Promise<AIStaffListCopilotThreadsResponseBody> {
        const response = await this.aIStaffListCopilotThreadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the most recent 50 investigations, optionally filtered by parameters.
     * ListInvestigations AI Staff
     */
    async aIStaffListInvestigationsRaw(requestParameters: AIStaffListInvestigationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListInvestigationsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/investigations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListInvestigationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the most recent 50 investigations, optionally filtered by parameters.
     * ListInvestigations AI Staff
     */
    async aIStaffListInvestigations(requestParameters: AIStaffListInvestigationsRequest, initOverrides?: RequestInit): Promise<AIStaffListInvestigationsResponseBody> {
        const response = await this.aIStaffListInvestigationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all the details for an investigation
     * ShowInvestigation AI Staff
     */
    async aIStaffShowInvestigationRaw(requestParameters: AIStaffShowInvestigationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowInvestigationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowInvestigation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/investigations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowInvestigationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get all the details for an investigation
     * ShowInvestigation AI Staff
     */
    async aIStaffShowInvestigation(requestParameters: AIStaffShowInvestigationRequest, initOverrides?: RequestInit): Promise<AIStaffShowInvestigationResponseBody> {
        const response = await this.aIStaffShowInvestigationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Test a prompt against a set list of incidents.
     * TestPrompt AI Staff
     */
    async aIStaffTestPromptRaw(requestParameters: AIStaffTestPromptRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffTestPromptResponseBody>> {
        if (requestParameters.testPromptRequestBody === null || requestParameters.testPromptRequestBody === undefined) {
            throw new runtime.RequiredError('testPromptRequestBody','Required parameter requestParameters.testPromptRequestBody was null or undefined when calling aIStaffTestPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/test-prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffTestPromptRequestBodyToJSON(requestParameters.testPromptRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffTestPromptResponseBodyFromJSON(jsonValue));
    }

    /**
     * Test a prompt against a set list of incidents.
     * TestPrompt AI Staff
     */
    async aIStaffTestPrompt(requestParameters: AIStaffTestPromptRequest, initOverrides?: RequestInit): Promise<AIStaffTestPromptResponseBody> {
        const response = await this.aIStaffTestPromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
