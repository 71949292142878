import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { ScheduleReport } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const UnpublishModal = ({
  onClose,
  report,
}: {
  onClose: () => void;
  report: ScheduleReport;
}): React.ReactElement | null => {
  const toast = useToast();
  const formMethods = useForm<{
    unpublish_reason: string;
  }>();
  const { reset, setError } = formMethods;

  const doClose = () => {
    reset();
    onClose();
  };

  const { trigger: unpublishReport } = useAPIMutation(
    "schedulesShowReport",
    { id: report.id },
    async (
      apiClient,
      {
        unpublish_reason,
      }: {
        unpublish_reason: string;
      },
    ) =>
      await apiClient.schedulesUnpublishReport({
        id: report.id,
        unpublishReportRequestBody: {
          unpublish_reason,
        },
      }),
    {
      setError,
      onSuccess: () => {
        toast({
          title: "Report has been unpublished",
          theme: ToastTheme.Success,
        });
        doClose();
      },
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      title={`Delete '${report.name}'`}
      disableQuickClose
      onClose={doClose}
      onSubmit={unpublishReport}
      analyticsTrackingId={"unpublish-report"}
      footer={
        <ModalFooter
          onClose={doClose}
          confirmButtonType="submit"
          confirmButtonText={"Delete"}
        />
      }
    >
      <div className="text-sm text-slate-700">
        <p>
          You can delete reports when there was a mistake when generating the
          report, and you want to mark the report as being invalid.
          <br />
          <br />
          Deleting will:
          <ul className={"list-disc list-outside"}>
            <li className={"ml-4"}>Remove the report from the reports list</li>
            <li className={"ml-4"}>
              Display the reason for deletion whenever anyone views the report
              from an existing permalink
            </li>
          </ul>
        </p>
      </div>
      <TextareaV2
        formMethods={formMethods}
        label="Reason"
        helptext="Why are you deleting this report?"
        name="unpublish_reason"
        placeholder="e.g. Some holidays were not included, and we have since..."
        required="Please provide a reason for deletion"
      />
    </Form.Modal>
  );
};
