import { IconEnum } from "@incident-ui";
import { parseISO } from "date-fns";
import { ExternalResource } from "src/contexts/ClientContext";

import {
  formatDurationShort,
  formatRelativeTimestamp,
  formatTimestampLocale,
} from "../../../../utils/datetime";
import { AttachmentBadge, AttachmentRenderProps } from "./IncidentAttachment";

export const GoogleCalendarEvent = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.google_calendar_event) {
    throw new Error("Google calendar event resource without expected content");
  }

  const isFutureEvent = new Date() < resource.google_calendar_event.start;
  const timeToEventStart = formatRelativeTimestamp(
    resource.google_calendar_event.start ?? new Date(),
  );
  const prefix = isFutureEvent ? `In ${timeToEventStart}` : timeToEventStart;

  const renderProps: AttachmentRenderProps = {
    icon: IconEnum.GoogleCalendar,
    title: resource.title,
  };

  const timestamp = resource.google_calendar_event.start;

  const utcDate =
    typeof timestamp === "string" ? parseISO(timestamp) : timestamp;

  const localTimeLocaleString = formatTimestampLocale({
    timestamp: utcDate,
    dateStyle: "short",
    timeStyle: "short",
  });

  const safeBadges: AttachmentBadge[] = [
    {
      icon: IconEnum.Calendar,
      text: `${prefix} on ${localTimeLocaleString} (${formatDurationShort(
        resource.google_calendar_event.start,
        resource.google_calendar_event.end,
      )})`,
    },
  ];

  if (resource.google_calendar_event.organiser_email !== "") {
    safeBadges.push({
      icon: IconEnum.User,
      text: resource.google_calendar_event.organiser_email,
      tooltipContent: "The user who organized the Google Calendar event",
    });
  }

  renderProps.safeBadges = safeBadges;

  return renderProps;
};
