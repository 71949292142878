import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useState } from "react";

import { CalendarFeedCreateEditModal } from "./CalendarFeedCreateEditModal";

export const CalendarFeedCustomCTAs = () => {
  const [showAddCalendar, setShowAddCalendar] = useState(false);

  return (
    <div className={"flex flex-row gap-4"}>
      <GatedButton
        icon={IconEnum.Add}
        className={"px-3 py-2"}
        requiredScope={ScopeNameEnum.HolidayUserFeedsCreate}
        onClick={() => setShowAddCalendar(true)}
        analyticsTrackingId="add-calendar-feed"
      >
        Add
      </GatedButton>
      <Button
        openInNewTab
        analyticsTrackingId="calendar-feeds-learn-more"
        theme={ButtonTheme.Naked}
        href="https://help.incident.io/en/articles/9596297-seeing-holidays-in-on-call-schedules"
        className={"text-content-invert hover:text-content-secondary"}
      >
        Learn more
      </Button>
      {showAddCalendar && (
        <CalendarFeedCreateEditModal
          onClose={() => setShowAddCalendar(false)}
        />
      )}
    </div>
  );
};
