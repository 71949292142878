import { Edge, Node } from "reactflow";

import {
  AlertsConfigurationNodeData,
  AlertsConfigurationNodeType,
} from "../AlertsConfigurationNode";

export const handleEdgeOpacityOnHover =
  (isComplexSetup: boolean, hoveredNode?: Node, hoveredEdge?: Edge) =>
  (edge: Edge) => {
    // Our default opacity state for edges is:
    // No reduced opacity if you don't have a complex set up, because the lines won't be overwhelming.
    // Reduced opacity if you have a complex set up, because the lines will be overwhelming.
    const defaultAction = isComplexSetup;

    // If we're hovering over an edge and this edge we're looking at is that edge, do not reduce the opacity.
    if (hoveredEdge && edge.id === hoveredEdge.id) {
      return reduceEdgeOpacity(false)(edge);
    }

    // If we're hovering over a node and this edge we're looking at is connected to that node, do not reduce the opacity.
    if (
      hoveredNode &&
      (edge.source === hoveredNode.id || edge.target === hoveredNode.id)
    ) {
      return reduceEdgeOpacity(false)(edge);
    }

    // If we're hovering over a node and this edge we're looking at is not connected to that node, reduce the opacity.
    if (
      hoveredNode &&
      edge.source !== hoveredNode.id &&
      edge.target !== hoveredNode.id
    ) {
      return reduceEdgeOpacity(true)(edge);
    }

    // If we're hovering over an edge and this edge we're looking at is not that edge, reduce the opacity.
    if (hoveredEdge && edge.id !== hoveredEdge.id) {
      return reduceEdgeOpacity(true)(edge);
    }

    // Otherwise we're not doing anything so just apply the default.
    return reduceEdgeOpacity(defaultAction)(edge);
  };

export const handleNodeOpacityOnHover =
  (hoveredNode?: Node, hoveredEdge?: Edge) => (node: Node) => {
    // If we're not hovering over anything, don't do anything (which is different from edges because we always
    // want all sources and routes to be visible).
    if (!hoveredEdge && !hoveredNode) {
      return reduceNodeOpacity(false)(node);
    }

    // If we're hovering over an edge, and this node is this edge's source or target, i.e. the source or route connected,
    // don't reduce the opacity.
    if (
      hoveredEdge &&
      (node.id === hoveredEdge.source || node.id === hoveredEdge.target)
    ) {
      return reduceNodeOpacity(false)(node);
    }

    // If we're hovering over a node, and this node is the hovered node, don't reduce the opacity.
    if (hoveredNode && hoveredNode.id === node.id) {
      return reduceNodeOpacity(false)(node);
    }

    // If we're hovering over an alert source node, check all the routes this source is connected to. If the current node
    // we're looking at is one of them, then don't reduce the opacity.
    if (
      hoveredNode?.type === "alertsConfigurationNode" &&
      hoveredNode?.data &&
      hoveredNode.data.nodeType === AlertsConfigurationNodeType.AlertSource
    ) {
      const alertSourceData = hoveredNode?.data as AlertsConfigurationNodeData;
      if (
        (alertSourceData.alertSource?.alert_route_ids || []).includes(node.id)
      ) {
        return reduceNodeOpacity(false)(node);
      }
    }

    // If we're hovering over an alert route node, check all the sources this route is connected to. If the current node
    // we're looking at is one of them, then don't reduce the opacity.
    if (
      hoveredNode?.type === "alertsConfigurationNode" &&
      hoveredNode?.data &&
      hoveredNode.data.nodeType === AlertsConfigurationNodeType.AlertRoute
    ) {
      const alertRouteData = hoveredNode?.data as AlertsConfigurationNodeData;
      if (
        (alertRouteData.alertRoute?.alert_sources || [])
          .map((source) => source.alert_source_id)
          .includes(node.id)
      ) {
        return reduceNodeOpacity(false)(node);
      }
    }

    // We've exhausted all our cases where we should not reduce the opacity, so reduce the opacity.
    return reduceNodeOpacity(true)(node);
  };

const reduceEdgeOpacity = (opacity: boolean) => (edge: Edge) => {
  return {
    ...edge,
    data: {
      ...edge.data,
      reduceOpacity: opacity,
    },
  };
};

const reduceNodeOpacity = (opacity: boolean) => (node: Node) => {
  return {
    ...node,
    data: {
      ...node.data,
      reduceOpacity: opacity,
    },
  };
};
