import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import _ from "lodash";
import { sortBy } from "lodash";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ClientType, SavedView } from "src/contexts/ClientContext";
import {
  SavedViewsCreateRequestBodyContextEnum,
  SavedViewsListContextEnum,
} from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { useLocalStorage } from "usehooks-ts";

type SubpathRedirect = { param: string; mapping: Record<string, string> };
export type SavedViewsContextType = {
  savedViews: {
    isLoading: boolean;
    userDefined: SavedView[] | undefined;
    presets: SavedView[] | undefined;
  };
  selectedSavedView: SavedView | undefined;
  setSelectedSavedViewID: (newViewID: string | null) => void;
  context: SavedViewsListContextEnum;
  createSavedView: (
    apiClient: ClientType,
    data: { name: string },
  ) => Promise<void>;
  updateURLParamsOfSavedView: (
    apiClient: ClientType,
    view: SavedView,
  ) => Promise<void>;
  renameSavedView: (
    apiClient: ClientType,
    data: { id: string; name: string },
  ) => Promise<void>;
  destroySavedView: (
    apiClient: ClientType,
    data: { id: string },
  ) => Promise<void>;
  viewIsDirty: boolean;
  viewIsPreset: boolean;
  subpathRedirect?: SubpathRedirect;
};

const unimplemented = () => {
  throw new Error("unimplemented");
};

export const SavedViewsContext = createContext<SavedViewsContextType>({
  savedViews: { isLoading: false, userDefined: undefined, presets: undefined },
  selectedSavedView: undefined,
  setSelectedSavedViewID: unimplemented,
  context: SavedViewsListContextEnum.FollowUps,
  createSavedView: unimplemented,
  updateURLParamsOfSavedView: unimplemented,
  destroySavedView: unimplemented,
  renameSavedView: unimplemented,
  viewIsDirty: false,
  viewIsPreset: false,
});

const viewIdStorageKey = (context: SavedViewsListContextEnum): string => {
  return `previous-${context}-view`;
};

// createNewSavedViewParams takes the current saved view (if present)
// and the query params currently in use and builds a new queryParam to save for the view.
export function createNewSavedViewParams({
  currentPath,
  selectedSavedView,
  currentURLParams,
  defaultQueryParams,
  subpathRedirect,
}: {
  currentPath: string;
  selectedSavedView: SavedView | undefined;
  currentURLParams: URLSearchParams;
  defaultQueryParams: URLSearchParams;
  subpathRedirect: SubpathRedirect | undefined;
}): string {
  const existingViewSearchParams = new URLSearchParams(
    selectedSavedView?.url_params ?? "",
  );

  // First get all the params, before we've tidied them up by removing 'to be removed' items,
  // and excluding the view query param
  const candidateSearchParams = new URLSearchParams([
    ...existingViewSearchParams,
    ...currentURLParams,
  ]);

  const queryParamsToUpdateCreate = new URLSearchParams();
  for (const [key, value] of candidateSearchParams) {
    // we don't store the viewID in its saved params, ignore it
    if (key === "view") {
      continue;
    }

    // if this is a 'to-remove' from existing view item, don't include it - just remove it's relating key later
    if (key[0] === "-") {
      continue;
    }

    // if there's an override to _remove_ a value from params, then don't add the value
    if (candidateSearchParams.getAll(`-${key}`).includes(value)) {
      continue;
    }

    // if we already have the item in the params, don't add it again
    if (queryParamsToUpdateCreate.getAll(key).includes(value)) {
      continue;
    }
    queryParamsToUpdateCreate.append(key, value);
  }

  for (const [key, value] of defaultQueryParams) {
    if (!queryParamsToUpdateCreate.has(key)) {
      queryParamsToUpdateCreate.append(key, value);
    }
  }

  if (subpathRedirect) {
    const paramValue = _.findKey(
      subpathRedirect.mapping,
      (value) => value === currentPath,
    );
    if (paramValue) {
      queryParamsToUpdateCreate.set(subpathRedirect.param, paramValue);
    }
  }

  return queryParamsToUpdateCreate.toString();
}

export const SavedViewsProvider = ({
  children,
  context,
  defaultQueryParams = new URLSearchParams(),
  defaultViewID = null,
  presetViews = [],
  subpathRedirect,
}: {
  children: React.ReactNode;
  context: SavedViewsListContextEnum;
  defaultQueryParams?: URLSearchParams;
  defaultViewID?: string | null;
  presetViews?: SavedView[];
  subpathRedirect?: SubpathRedirect;
}): JSX.Element => {
  const location = useLocation();

  const currentURLParams = useQueryParams();

  const { data, isLoading, mutate } = useAPI("savedViewsList", { context });
  const savedViews = useMemo(
    () => sortBy(data?.saved_views || [], (savedView) => savedView.name),
    [data],
  );
  const [selectedSavedView, setSelectedSavedView, setSelectedSavedViewID] =
    useSelectedSavedViewState(
      context,
      savedViews.concat(presetViews),
      isLoading,
      defaultViewID,
      subpathRedirect,
    );

  const createSavedView = async (
    apiClient: ClientType,
    { name }: { name: string },
  ) => {
    const { saved_view } = await apiClient.savedViewsCreate({
      createRequestBody: {
        name,
        context: context as string as SavedViewsCreateRequestBodyContextEnum,
        url_params: createNewSavedViewParams({
          currentPath: location.pathname,
          selectedSavedView: selectedSavedView, // this is defined when we do a 'save-as'
          currentURLParams: currentURLParams,
          defaultQueryParams: defaultQueryParams,
          subpathRedirect,
        }),
      },
    });

    await mutate({
      saved_views: [...savedViews, saved_view],
    });

    // Always use the just-created view
    setSelectedSavedView(saved_view);
  };

  const updateURLParamsOfSavedView = async (
    apiClient: ClientType,
    selectedSavedView: SavedView,
  ) => {
    const { saved_view } = await apiClient.savedViewsUpdate({
      id: selectedSavedView.id,
      updateRequestBody: {
        url_params: createNewSavedViewParams({
          currentPath: location.pathname,
          selectedSavedView: selectedSavedView,
          currentURLParams: currentURLParams,
          defaultQueryParams: defaultQueryParams,
          subpathRedirect,
        }),
      },
    });

    // Always use the just-updated view
    setSelectedSavedView(saved_view);
  };

  const destroySavedView = async (
    apiClient: ClientType,
    { id }: { id: string },
  ) => {
    await apiClient.savedViewsDestroy({ id });

    // Remove the ID from the URL
    setSelectedSavedView(null);
  };

  const renameSavedView = async (
    apiClient: ClientType,
    { id, name }: { id: string; name: string },
  ) => {
    await apiClient.savedViewsUpdate({
      id,
      updateRequestBody: { name },
    });
  };

  return (
    <SavedViewsContext.Provider
      value={{
        savedViews: {
          isLoading,
          userDefined: savedViews,
          presets: presetViews,
        },
        selectedSavedView,
        setSelectedSavedViewID,
        context,
        createSavedView,
        updateURLParamsOfSavedView,
        destroySavedView,
        renameSavedView,
        viewIsDirty:
          Array.from(currentURLParams.entries()).filter(
            ([key]) => key !== "view",
          ).length > 0,
        viewIsPreset:
          Array.from(
            presetViews.filter(({ id }) => selectedSavedView?.id === id),
          ).length > 0,
      }}
    >
      {children}
    </SavedViewsContext.Provider>
  );
};

export const useSavedViews = (): SavedViewsContextType =>
  useContext(SavedViewsContext);

// This hook is used to manage the selected saved view state.
// The state is managed in the URL. We also store the selected view in local storage
const useSelectedSavedViewState = (
  context: SavedViewsListContextEnum,
  availableViews: SavedView[] | undefined,
  isLoading: boolean,
  defaultViewID: string | null,
  subpathRedirect: SubpathRedirect | undefined,
) => {
  const location = useLocation();
  const pathname = location.pathname;

  const urlParams = useQueryParams();

  const [localStorageSavedViewID, setLocalStorageSavedViewID] = useLocalStorage<
    string | null
  >(
    viewIdStorageKey(context) + (subpathRedirect ? pathname : ""),
    defaultViewID,
  );
  // this is to patch the fact that useLocalStorage state initialization only does this once
  // if its set to null in the browser storage, then the initialisation wont happen again.
  // but for our case we want this to happen every time the page is loaded and the value is null
  useEffect(() => {
    if (!localStorageSavedViewID) {
      setLocalStorageSavedViewID(defaultViewID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // we only want this to run on-mount

  // This is our state
  const selectedSavedViewID = urlParams.get("view") ?? localStorageSavedViewID;
  const selectedSavedView = availableViews?.find(
    ({ id }) => id === selectedSavedViewID,
  );
  // The setter function
  const setSelectedSavedView = (
    selectedSavedView: SavedView | null | undefined,
  ) => {
    const pathname = getPathname(selectedSavedView, subpathRedirect);
    if (
      // Exiting a view
      pathname === location.pathname &&
      !selectedSavedView
    ) {
      setLocalStorageSavedViewID(null);
    }

    urlParams.delete("view");
    navigate(
      selectedSavedView
        ? `${pathname}?view=${selectedSavedView.id}`
        : `${pathname}?${urlParams.toString()}`, // preserve the url params
    );
  };

  // make sure we update local storage with the currently selected view
  useEffect(() => {
    if (!isLoading) {
      if (selectedSavedView) {
        const pathname = getPathname(selectedSavedView, subpathRedirect);
        if (pathname === location.pathname) {
          // user could switch between paths but want to keep the same view
          // we should not update the local storage in this case
          setLocalStorageSavedViewID(selectedSavedView.id);
        }
      } else {
        setLocalStorageSavedViewID(null);
      }
    }
  }, [
    isLoading,
    selectedSavedView,
    subpathRedirect,
    location.pathname,
    setLocalStorageSavedViewID,
  ]);

  const navigate = useOrgAwareNavigate();
  // to ensure the URL is always up to date with the selected view
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (selectedSavedView?.id !== urlParams.get("view")) {
      setSelectedSavedView(selectedSavedView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedSavedView, urlParams.get("view")]);

  return [
    selectedSavedView,
    setSelectedSavedView,
    // helper function for setting the selected view by id
    (newID) => {
      const newSelectedSavedView = availableViews?.find(
        ({ id }) => id === newID,
      );
      setSelectedSavedView(newSelectedSavedView);
    },
  ] as const;
};

const getPathname = (
  selectedSavedView: SavedView | null | undefined,
  subpathRedirect: SubpathRedirect | undefined,
) => {
  if (!selectedSavedView || !subpathRedirect) {
    return location.pathname;
  }
  const paramValue = new URLSearchParams(selectedSavedView.url_params).get(
    subpathRedirect.param,
  );
  if (!paramValue) {
    return location.pathname;
  }
  return subpathRedirect.mapping[paramValue] ?? location.pathname;
};
