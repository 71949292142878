/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnrichedSlackMessage
 */
export interface EnrichedSlackMessage {
    /**
     * The channel ID of the pinned item
     * @type {string}
     * @memberof EnrichedSlackMessage
     */
    channel_id: string;
    /**
     * Whether the message has been deleted
     * @type {boolean}
     * @memberof EnrichedSlackMessage
     */
    deleted: boolean;
    /**
     * Why the item couldn't be enriched
     * @type {string}
     * @memberof EnrichedSlackMessage
     */
    enrichment_error?: string;
    /**
     * The number of files attached to the message
     * @type {number}
     * @memberof EnrichedSlackMessage
     */
    num_files: number;
    /**
     * A link to the pinned item
     * @type {string}
     * @memberof EnrichedSlackMessage
     */
    permalink: string;
    /**
     * The user ID who posted the pinned item
     * @type {string}
     * @memberof EnrichedSlackMessage
     */
    slack_user_id: string;
    /**
     * The content of the pinned item
     * @type {string}
     * @memberof EnrichedSlackMessage
     */
    text: string;
    /**
     * The message timestamp of the pinned item
     * @type {string}
     * @memberof EnrichedSlackMessage
     */
    timestamp: string;
}

export function EnrichedSlackMessageFromJSON(json: any): EnrichedSlackMessage {
    return EnrichedSlackMessageFromJSONTyped(json, false);
}

export function EnrichedSlackMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnrichedSlackMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel_id': json['channel_id'],
        'deleted': json['deleted'],
        'enrichment_error': !exists(json, 'enrichment_error') ? undefined : json['enrichment_error'],
        'num_files': json['num_files'],
        'permalink': json['permalink'],
        'slack_user_id': json['slack_user_id'],
        'text': json['text'],
        'timestamp': json['timestamp'],
    };
}

export function EnrichedSlackMessageToJSON(value?: EnrichedSlackMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_id': value.channel_id,
        'deleted': value.deleted,
        'enrichment_error': value.enrichment_error,
        'num_files': value.num_files,
        'permalink': value.permalink,
        'slack_user_id': value.slack_user_id,
        'text': value.text,
        'timestamp': value.timestamp,
    };
}

