import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  ConfirmationDialog,
  IconEnum,
  StaticSingleSelect,
} from "@incident-ui";
import React, { useState } from "react";
import {
  DecisionFlow as DecisionFlow,
  DecisionFlowsCreateRequestBody as CreateReqBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { Modes } from "./Controller";
import { CreateMetadataModal } from "./modals/MetadataModals";
import { SettingsContent } from "./SettingsContent";

// This returns the select and 'create new' buttons at the top of the settings page.
const DecisionFlowSettingsHeader = ({
  decisionFlows,
  onClickCreate,
  onSelectFlow,
  selectedFlowId,
  enabled,
  onClear,
}: {
  decisionFlows: DecisionFlow[];
  onClickCreate: () => void;
  onSelectFlow: (nodeId: string) => void;
  selectedFlowId: string | null;
  enabled: boolean;
  onClear: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const onConfirmClear = () => {
    setShowConfirmationModal(false);
    onClear();
  };

  const dropdownOptions = decisionFlows.map((flow, i) => ({
    value: flow.id,
    label: flow.name,
    sort_key: i.toString().padStart(4),
  }));

  return (
    <>
      <ConfirmationDialog
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={onConfirmClear}
        analyticsTrackingId="decision-flow-abandon-changes"
        isOpen={showConfirmationModal}
        title="Abandon changes"
      >
        <div>{`You have unsaved changes. By changing decision flow, you will lose those changes.`}</div>
      </ConfirmationDialog>
      <div className="mr-auto">
        {enabled ? (
          <StaticSingleSelect
            placeholder={
              canEditSettings
                ? "Select a flow to configure"
                : "Select a flow to view"
            }
            isClearable={false}
            isSearchable={false}
            disabled={!enabled}
            options={dropdownOptions}
            value={selectedFlowId}
            // @ts-expect-error it doesn't know this is a single select. We should fix this using typescript conditional type magic.
            onChange={onSelectFlow}
          />
        ) : (
          <div className="flex h-full">
            <button
              onClick={() => setShowConfirmationModal(true)}
              className="text-xs font-medium text-content-primary"
            >
              Change decision flow
            </button>
          </div>
        )}
      </div>
      <div className="text-right">
        <GatedButton
          analyticsTrackingId="decision-flow-create"
          theme={ButtonTheme.Primary}
          icon={IconEnum.Add}
          onClick={onClickCreate}
          disabled={!enabled}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        >
          New Decision Flow
        </GatedButton>
      </div>
    </>
  );
};

export const DecisionFlowSettings = ({
  decisionFlows,
}: {
  decisionFlows: DecisionFlow[];
}): React.ReactElement => {
  const [mode, setMode] = useState<Modes | null>(null);
  const [createRequest, setCreateRequest] = useState<CreateReqBody | null>(
    null,
  );
  const [selectedFlowID, setSelectedFlowID] = useState<string | null>(null);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const onCreateOk = (formData: CreateReqBody) => {
    setMode(Modes.Create);
    setCreateRequest(formData);
    // you've created something new, so your state is definitely dirty.
    setIsDirty(true);
    setShowCreateModal(false);
  };

  const onSelectFlow = (id: string) => {
    setSelectedFlowID(id);
    setMode(Modes.Edit);
  };

  const onClickCreate = () => setShowCreateModal(true);

  // when someone chooses to change flows, we clear the decisionFlow to avoid confusion.
  const onClear = () => {
    setSelectedFlowID(null);
    setCreateRequest(null);
    setMode(null);
    setIsDirty(false);
  };

  return (
    <div>
      <div className="flex mb-4">
        {showCreateModal && (
          <CreateMetadataModal
            onClose={() => setShowCreateModal(false)}
            onSave={onCreateOk}
          />
        )}
        {decisionFlows.length > 0 && (
          <DecisionFlowSettingsHeader
            decisionFlows={decisionFlows}
            onClickCreate={onClickCreate}
            onSelectFlow={onSelectFlow}
            selectedFlowId={selectedFlowID}
            enabled={!isDirty}
            onClear={onClear}
          />
        )}
      </div>
      <SettingsContent
        mode={mode}
        createRequest={createRequest}
        selectedFlowID={selectedFlowID}
        onClickCreate={onClickCreate}
        anyExistingDecisionFlows={decisionFlows.length > 0}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        onClear={onClear}
        onCreated={(flowId) => {
          setCreateRequest(null);
          setSelectedFlowID(flowId);
          setMode(Modes.Edit);
          setIsDirty(false);
        }}
      />
    </div>
  );
};
