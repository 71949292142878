import {
  OutlookCalendarConfig,
  useIntegrationsOutlookCalendarServiceIntegrationsOutlookCalendarGetConfig as useGetConfig,
} from "@incident-io/query-api";
import {
  ConfigureDrawerProps,
  IntegrationListProvider,
} from "@incident-shared/integrations";
import { GenericErrorMessage, Spinner } from "@incident-ui";

import { IntegrationConnectedUser } from "../../common/IntegrationConnectedUser";
import { GenericConfigureDrawerContents } from "../IntegrationDrawer";

export const OutlookCalendarConfigureDrawer = (props: ConfigureDrawerProps) => {
  const { data, isLoading, error } = useGetConfig();

  if (error) {
    return (
      <GenericConfigureDrawerContents {...props}>
        <GenericErrorMessage error={error as Error | undefined} />
      </GenericConfigureDrawerContents>
    );
  }
  return (
    <GenericConfigureDrawerContents {...props}>
      {isLoading || !data ? (
        <Spinner />
      ) : (
        <DrawerContents
          provider={props.integration.provider}
          config={data.config}
        />
      )}
    </GenericConfigureDrawerContents>
  );
};

const DrawerContents = ({
  provider,
  config,
}: {
  provider: IntegrationListProvider;
  config: OutlookCalendarConfig;
}) => {
  return (
    <IntegrationConnectedUser {...config.connected_user} provider={provider} />
  );
};
