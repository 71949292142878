/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    IncidentFormEscalateConfig,
    IncidentFormEscalateConfigFromJSON,
    IncidentFormEscalateConfigFromJSONTyped,
    IncidentFormEscalateConfigToJSON,
} from './IncidentFormEscalateConfig';

/**
 * 
 * @export
 * @interface IncidentForm
 */
export interface IncidentForm {
    /**
     * 
     * @type {IncidentFormEscalateConfig}
     * @memberof IncidentForm
     */
    escalate_config?: IncidentFormEscalateConfig;
    /**
     * The expressions that are available to this form
     * @type {Array<Expression>}
     * @memberof IncidentForm
     */
    expressions: Array<Expression>;
    /**
     * The type of the incident form
     * @type {string}
     * @memberof IncidentForm
     */
    form_type: IncidentFormFormTypeEnum;
    /**
     * Unique ID per incident form
     * @type {string}
     * @memberof IncidentForm
     */
    id: string;
    /**
     * The incident ID associated with this form
     * @type {string}
     * @memberof IncidentForm
     */
    incident_type_id?: string;
    /**
     * The product associated with this form
     * @type {string}
     * @memberof IncidentForm
     */
    required_product: IncidentFormRequiredProductEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormFormTypeEnum {
    Declare = 'declare',
    Accept = 'accept',
    Update = 'update',
    Resolve = 'resolve',
    CustomFields = 'custom-fields',
    Escalate = 'escalate'
}/**
* @export
* @enum {string}
*/
export enum IncidentFormRequiredProductEnum {
    Response = 'response',
    OnCall = 'on_call'
}

export function IncidentFormFromJSON(json: any): IncidentForm {
    return IncidentFormFromJSONTyped(json, false);
}

export function IncidentFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalate_config': !exists(json, 'escalate_config') ? undefined : IncidentFormEscalateConfigFromJSON(json['escalate_config']),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'form_type': json['form_type'],
        'id': json['id'],
        'incident_type_id': !exists(json, 'incident_type_id') ? undefined : json['incident_type_id'],
        'required_product': json['required_product'],
    };
}

export function IncidentFormToJSON(value?: IncidentForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalate_config': IncidentFormEscalateConfigToJSON(value.escalate_config),
        'expressions': ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'form_type': value.form_type,
        'id': value.id,
        'incident_type_id': value.incident_type_id,
        'required_product': value.required_product,
    };
}

