/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface IncidentRename
 */
export interface IncidentRename {
    /**
     * 
     * @type {Actor}
     * @memberof IncidentRename
     */
    actor: Actor;
    /**
     * Previous name of the incident
     * @type {string}
     * @memberof IncidentRename
     */
    from: string;
    /**
     * New name of the incident
     * @type {string}
     * @memberof IncidentRename
     */
    to: string;
}

export function IncidentRenameFromJSON(json: any): IncidentRename {
    return IncidentRenameFromJSONTyped(json, false);
}

export function IncidentRenameFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRename {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'from': json['from'],
        'to': json['to'],
    };
}

export function IncidentRenameToJSON(value?: IncidentRename | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'from': value.from,
        'to': value.to,
    };
}

