/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingValue,
    EngineParamBindingValueFromJSON,
    EngineParamBindingValueFromJSONTyped,
    EngineParamBindingValueToJSON,
} from './EngineParamBindingValue';

/**
 * 
 * @export
 * @interface EngineParamBinding
 */
export interface EngineParamBinding {
    /**
     * If array_value is set, this helps render the values
     * @type {Array<EngineParamBindingValue>}
     * @memberof EngineParamBinding
     */
    array_value?: Array<EngineParamBindingValue>;
    /**
     * 
     * @type {EngineParamBindingValue}
     * @memberof EngineParamBinding
     */
    value?: EngineParamBindingValue;
}

export function EngineParamBindingFromJSON(json: any): EngineParamBinding {
    return EngineParamBindingFromJSONTyped(json, false);
}

export function EngineParamBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineParamBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array_value': !exists(json, 'array_value') ? undefined : ((json['array_value'] as Array<any>).map(EngineParamBindingValueFromJSON)),
        'value': !exists(json, 'value') ? undefined : EngineParamBindingValueFromJSON(json['value']),
    };
}

export function EngineParamBindingToJSON(value?: EngineParamBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array_value': value.array_value === undefined ? undefined : ((value.array_value as Array<any>).map(EngineParamBindingValueToJSON)),
        'value': EngineParamBindingValueToJSON(value.value),
    };
}

