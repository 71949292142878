/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraCloudSite,
    JiraCloudSiteFromJSON,
    JiraCloudSiteFromJSONTyped,
    JiraCloudSiteToJSON,
} from './JiraCloudSite';

/**
 * 
 * @export
 * @interface IntegrationsJiraCloudGetSitesResponseBody
 */
export interface IntegrationsJiraCloudGetSitesResponseBody {
    /**
     * 
     * @type {Array<JiraCloudSite>}
     * @memberof IntegrationsJiraCloudGetSitesResponseBody
     */
    sites: Array<JiraCloudSite>;
}

export function IntegrationsJiraCloudGetSitesResponseBodyFromJSON(json: any): IntegrationsJiraCloudGetSitesResponseBody {
    return IntegrationsJiraCloudGetSitesResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsJiraCloudGetSitesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsJiraCloudGetSitesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sites': ((json['sites'] as Array<any>).map(JiraCloudSiteFromJSON)),
    };
}

export function IntegrationsJiraCloudGetSitesResponseBodyToJSON(value?: IntegrationsJiraCloudGetSitesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sites': ((value.sites as Array<any>).map(JiraCloudSiteToJSON)),
    };
}

