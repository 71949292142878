/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FollowUpPriority,
    FollowUpPriorityFromJSON,
    FollowUpPriorityFromJSONTyped,
    FollowUpPriorityToJSON,
} from './FollowUpPriority';

/**
 * 
 * @export
 * @interface FollowUpPriorityChange
 */
export interface FollowUpPriorityChange {
    /**
     * 
     * @type {FollowUpPriority}
     * @memberof FollowUpPriorityChange
     */
    next_follow_up_priority_option?: FollowUpPriority;
    /**
     * 
     * @type {FollowUpPriority}
     * @memberof FollowUpPriorityChange
     */
    previous_follow_up_priority_option?: FollowUpPriority;
}

export function FollowUpPriorityChangeFromJSON(json: any): FollowUpPriorityChange {
    return FollowUpPriorityChangeFromJSONTyped(json, false);
}

export function FollowUpPriorityChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpPriorityChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_follow_up_priority_option': !exists(json, 'next_follow_up_priority_option') ? undefined : FollowUpPriorityFromJSON(json['next_follow_up_priority_option']),
        'previous_follow_up_priority_option': !exists(json, 'previous_follow_up_priority_option') ? undefined : FollowUpPriorityFromJSON(json['previous_follow_up_priority_option']),
    };
}

export function FollowUpPriorityChangeToJSON(value?: FollowUpPriorityChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_follow_up_priority_option': FollowUpPriorityToJSON(value.next_follow_up_priority_option),
        'previous_follow_up_priority_option': FollowUpPriorityToJSON(value.previous_follow_up_priority_option),
    };
}

