/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionBranch,
    ExpressionBranchFromJSON,
    ExpressionBranchFromJSONTyped,
    ExpressionBranchToJSON,
} from './ExpressionBranch';
import {
    ExpressionElseBranch,
    ExpressionElseBranchFromJSON,
    ExpressionElseBranchFromJSONTyped,
    ExpressionElseBranchToJSON,
} from './ExpressionElseBranch';

/**
 * 
 * @export
 * @interface BranchesOnlyExpression
 */
export interface BranchesOnlyExpression {
    /**
     * The branches of the expression
     * @type {Array<ExpressionBranch>}
     * @memberof BranchesOnlyExpression
     */
    branches: Array<ExpressionBranch>;
    /**
     * 
     * @type {ExpressionElseBranch}
     * @memberof BranchesOnlyExpression
     */
    else_branch?: ExpressionElseBranch;
}

export function BranchesOnlyExpressionFromJSON(json: any): BranchesOnlyExpression {
    return BranchesOnlyExpressionFromJSONTyped(json, false);
}

export function BranchesOnlyExpressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchesOnlyExpression {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branches': ((json['branches'] as Array<any>).map(ExpressionBranchFromJSON)),
        'else_branch': !exists(json, 'else_branch') ? undefined : ExpressionElseBranchFromJSON(json['else_branch']),
    };
}

export function BranchesOnlyExpressionToJSON(value?: BranchesOnlyExpression | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branches': ((value.branches as Array<any>).map(ExpressionBranchToJSON)),
        'else_branch': ExpressionElseBranchToJSON(value.else_branch),
    };
}

