import type { SVGProps } from "react";
import * as React from "react";
const SvgStop = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.778 1.778H4.223a2.444 2.444 0 0 0-2.445 2.444v7.556a2.445 2.445 0 0 0 2.445 2.444h7.555a2.445 2.445 0 0 0 2.445-2.444V4.222a2.444 2.444 0 0 0-2.445-2.444Z"
    />
  </svg>
);
export default SvgStop;
