import { Form } from "@incident-shared/forms";
import { CheckboxGroupV2 } from "@incident-shared/forms/v2/inputs/CheckboxGroupV2";
import { CurrencyInputV2 } from "@incident-shared/forms/v2/inputs/CurrencyInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import {
  currencyStrToCents,
  currencyValidationRules,
} from "@incident-ui/Input/CurrencyInput";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { formatCurrency } from "../../../../utils/currency";
import {
  sortWeekdays,
  weekdaySelectOptions,
} from "../../../../utils/frequencies";
import { TimezoneHelperText } from "../TimezoneHelperToolTip";
import { PayRuleFormType } from "./PayConfigCreateEditForm";

export const PayRuleModal = ({
  onClose,
  onAdd,
  onEdit,
  editingRule,
  selectedCurrency,
  baseRate,
}: {
  onClose: () => void;
  onAdd: (payRule: PayRuleFormType) => void;
  onEdit: (payRule: PayRuleFormType) => void;
  editingRule: PayRuleFormType | null;
  selectedCurrency: string;
  baseRate: string;
}): React.ReactElement | null => {
  const formMethods = useForm<PayRuleFormType>({
    defaultValues: editingRule || {
      key: uuidv4(),
      start_time: "00:00",
      end_time: "00:00",
    },
  });

  const { setError, reset } = formMethods;

  const onSubmit = (rule: PayRuleFormType) => {
    if (!rule.weekdays || rule.weekdays.length === 0) {
      setError("weekdays", {
        type: "manual",
        message: "Please select at least one weekday",
      });

      return undefined;
    }

    // sort the weekdays! (I don't think this is actually required,
    // but definitely doesn't hurt)
    rule.weekdays = sortWeekdays(rule.weekdays);

    const startTimeBeforeEndTime =
      new Date("01/01/2022 " + rule.start_time) <
      new Date("01/01/2022 " + rule.end_time);
    if (rule.end_time !== "00:00" && !startTimeBeforeEndTime) {
      setError("start_time", {
        type: "manual",
        message: "Start time should be before end time",
      });

      return undefined;
    }

    reset();

    if (rule.rate_pounds === "") {
      rule.rate_pounds = "0";
    }

    if (editingRule) {
      return onEdit(rule);
    }

    return onAdd(rule);
  };

  const wrappedOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Configure a weekly rule"}
      disableQuickClose
      onClose={wrappedOnClose}
      onSubmit={onSubmit}
      analyticsTrackingId={"schedule-pay-rule-modal"}
    >
      <CheckboxGroupV2
        formMethods={formMethods}
        label="Which days should this rule apply to?"
        showSelectAll
        options={weekdaySelectOptions}
        name="weekdays"
      />
      <InputV2
        formMethods={formMethods}
        label="Start time"
        name="start_time"
        helptext="When should this rule apply from? (use 00:00 for the start of the day)"
        required="Please enter an start time"
        type={InputType.Time}
      />
      <InputV2
        formMethods={formMethods}
        label="End time"
        name="end_time"
        helptext="When should this rule stop applying? (use 00:00 for the end of the day)"
        required="Please enter an end time"
        type={InputType.Time}
      />
      <Callout theme={CalloutTheme.Plain}>
        <TimezoneHelperText />
      </Callout>

      <CurrencyInputV2
        label="How much do you pay per hour during this time range?"
        formMethods={formMethods}
        currency={selectedCurrency}
        name="rate_pounds"
        defaultValue={editingRule?.rate_pounds}
        rules={{ validate: currencyValidationRules }}
        contextText={`As a reminder, your base rate for this pay configuration is ${formatCurrency(
          selectedCurrency,
          currencyStrToCents(baseRate),
        )} per hour.`}
      />
      <ModalFooter onClose={wrappedOnClose} confirmButtonType="submit" />
    </Form.Modal>
  );
};
