/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackTeamConfig,
    SlackTeamConfigFromJSON,
    SlackTeamConfigFromJSONTyped,
    SlackTeamConfigToJSON,
} from './SlackTeamConfig';

/**
 * 
 * @export
 * @interface SlackTeamConfigsGetBySlackTeamIDResponseBody
 */
export interface SlackTeamConfigsGetBySlackTeamIDResponseBody {
    /**
     * 
     * @type {SlackTeamConfig}
     * @memberof SlackTeamConfigsGetBySlackTeamIDResponseBody
     */
    slack_team_config: SlackTeamConfig;
}

export function SlackTeamConfigsGetBySlackTeamIDResponseBodyFromJSON(json: any): SlackTeamConfigsGetBySlackTeamIDResponseBody {
    return SlackTeamConfigsGetBySlackTeamIDResponseBodyFromJSONTyped(json, false);
}

export function SlackTeamConfigsGetBySlackTeamIDResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackTeamConfigsGetBySlackTeamIDResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slack_team_config': SlackTeamConfigFromJSON(json['slack_team_config']),
    };
}

export function SlackTeamConfigsGetBySlackTeamIDResponseBodyToJSON(value?: SlackTeamConfigsGetBySlackTeamIDResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slack_team_config': SlackTeamConfigToJSON(value.slack_team_config),
    };
}

