import { DragHandle } from "@incident-shared/settings";
import { Button, ButtonTheme, IconEnum, IconSize, Input } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import _ from "lodash";
import React, { useCallback } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";

export type ListEditorValue<IDType extends string | number> = {
  sort_key: number;
  // computer readable (should probably be called value)
  id: IDType;
  // human readable (should probably be called label :facepalm)
  value: string;
  // is this a new item?
  is_new?: boolean;
};

// the decision flow option ids have to be numbers (sadly), so we need to do rubbish like this to make TS happy.
const idToString = (id: string | number): string => {
  if (typeof id === "number") {
    return id.toString();
  } else {
    return id;
  }
};

const Row = <IDType extends string | number>({
  row,
  onUpdate,
  onDelete,
  rowPlaceholder,
  allowDelete,
  allowEdit,
  inputType = InputType.Text,
}: {
  row: ListEditorValue<IDType>;
  rowPlaceholder: string;
  onUpdate: (row: ListEditorValue<IDType>, newValue: string) => void;
  onDelete: (row: ListEditorValue<IDType>) => void;
  allowDelete: boolean;
  allowEdit: boolean;
  inputType?: InputType;
}): React.ReactElement => {
  return (
    <div className="flex items-center grow">
      <Input
        id={`row-${row.id}`}
        type={inputType}
        placeholder={rowPlaceholder}
        disabled={!allowEdit}
        onChange={(e) => {
          // @ts-expect-error I don't know why but TS doesn't know this has a value
          onUpdate(row, e.target.value);
        }}
        value={row.value}
      />
      {allowDelete ? (
        <Button
          analyticsTrackingId={null}
          title="Remove"
          onClick={() => onDelete(row)}
          icon={IconEnum.Delete}
          iconProps={{ size: IconSize.Large }}
          theme={ButtonTheme.Naked}
          className="!text-slate-700 ml-1"
        />
      ) : null}
    </div>
  );
};

export type ListEditorProps<TIDType extends string | number> = {
  value: ListEditorValue<TIDType>[];
  onChange: (value: ListEditorValue<TIDType>[]) => void;
  rowPlaceholder: string;
  onClearErrors: () => void;
  getDefaultRow: () => ListEditorValue<TIDType>;
  allowNew?: boolean;
  addNewText: string;
  allowSort?: boolean;
  allowReordering?: boolean;
  allowDelete?: boolean;
  allowEdit?: boolean;
  inputType?: InputType;
  onAddRow?: () => void;
};

export const ListEditor = <TIDType extends string | number>({
  value,
  onChange,
  rowPlaceholder,
  onClearErrors,
  getDefaultRow,
  addNewText,
  allowNew = true,
  allowSort,
  allowDelete = true,
  allowEdit = true,
  allowReordering = true,
  inputType = InputType.Text,
}: ListEditorProps<TIDType>): React.ReactElement => {
  // takes an array of ListEditorValues and stamps a sort_key onto them
  const regenerateSortKeys = (
    vals: ListEditorValue<TIDType>[],
  ): ListEditorValue<TIDType>[] => {
    return [...vals].map((option, idx: number) =>
      _.extend({}, option, { sort_key: (idx + 1) * 10 }),
    );
  };

  const onAdd = () => {
    const newRow = getDefaultRow();
    onChange(regenerateSortKeys([...value, newRow]));
  };

  const onSort = () => {
    onChange(regenerateSortKeys(_.sortBy(value, (x) => x.value.toLowerCase())));
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;

      let valuesClone = _.clone(value);

      // Snip out the element we moved
      const [removed] = valuesClone.splice(fromIndex, 1);

      // Insert it back into the list wherever we dragged it to
      valuesClone.splice(toIndex, 0, removed);

      // Iterate over the list, updating the sort keys
      valuesClone = valuesClone.map((option, idx) =>
        _.extend({}, option, { sort_key: idx * 10 }),
      );

      //   tell the caller so it updates the value, which will
      // flow back into our controlled form.
      onChange(regenerateSortKeys(valuesClone));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="customFields">
        {(provided) => {
          return (
            <>
              <ul
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-4 mb-4"
              >
                {_.sortBy(value, (opt) => opt.sort_key).map((row, index) => {
                  return (
                    <Draggable
                      key={row.id}
                      draggableId={idToString(row.id)}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const result = (
                          <div
                            className="mb-4"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="flex flex-row items-center">
                              {allowReordering && (
                                <DragHandle
                                  className="mr-2 flex-0"
                                  {...provided.dragHandleProps}
                                />
                              )}
                              <Row
                                row={row}
                                rowPlaceholder={rowPlaceholder}
                                allowDelete={allowDelete}
                                allowEdit={allowEdit}
                                inputType={inputType}
                                onDelete={(deletedOption) => {
                                  onClearErrors();
                                  onChange(
                                    value.filter(
                                      (opt) => opt.id !== deletedOption.id,
                                    ),
                                  );
                                }}
                                onUpdate={(updatedOption, newValue) => {
                                  onClearErrors();
                                  onChange(
                                    value.map((opt) => {
                                      if (opt.id === updatedOption.id) {
                                        return _.extend({}, updatedOption, {
                                          value: newValue,
                                        });
                                      }
                                      return opt;
                                    }),
                                  );
                                }}
                              />
                            </div>
                          </div>
                        );

                        if (snapshot.isDragging) {
                          return ReactDOM.createPortal(result, document.body);
                        }

                        return result;
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
              <div className="flex items-center gap-3">
                {allowNew ? (
                  <Button
                    analyticsTrackingId={null}
                    theme={ButtonTheme.Naked}
                    onClick={onAdd}
                    icon={IconEnum.Add}
                  >
                    {addNewText}
                  </Button>
                ) : null}
                {allowSort && (
                  <Button
                    analyticsTrackingId={null}
                    theme={ButtonTheme.Naked}
                    onClick={onSort}
                    icon={IconEnum.Sort}
                  >
                    Sort options
                  </Button>
                )}
              </div>
            </>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
