/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallNotificationsConfirmMethodVerificationRequestBody
 */
export interface OnCallNotificationsConfirmMethodVerificationRequestBody {
    /**
     * The verification code that was last sent to this method.
     * @type {string}
     * @memberof OnCallNotificationsConfirmMethodVerificationRequestBody
     */
    code: string;
}

export function OnCallNotificationsConfirmMethodVerificationRequestBodyFromJSON(json: any): OnCallNotificationsConfirmMethodVerificationRequestBody {
    return OnCallNotificationsConfirmMethodVerificationRequestBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsConfirmMethodVerificationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsConfirmMethodVerificationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
    };
}

export function OnCallNotificationsConfirmMethodVerificationRequestBodyToJSON(value?: OnCallNotificationsConfirmMethodVerificationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
    };
}

