/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentFormEscalationElement,
    IncidentFormEscalationElementFromJSON,
    IncidentFormEscalationElementFromJSONTyped,
    IncidentFormEscalationElementToJSON,
} from './IncidentFormEscalationElement';

/**
 * 
 * @export
 * @interface IncidentFormsUpdateEscalationRanksResponseBody
 */
export interface IncidentFormsUpdateEscalationRanksResponseBody {
    /**
     * 
     * @type {Array<IncidentFormEscalationElement>}
     * @memberof IncidentFormsUpdateEscalationRanksResponseBody
     */
    incident_form_elements: Array<IncidentFormEscalationElement>;
}

export function IncidentFormsUpdateEscalationRanksResponseBodyFromJSON(json: any): IncidentFormsUpdateEscalationRanksResponseBody {
    return IncidentFormsUpdateEscalationRanksResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsUpdateEscalationRanksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsUpdateEscalationRanksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_form_elements': ((json['incident_form_elements'] as Array<any>).map(IncidentFormEscalationElementFromJSON)),
    };
}

export function IncidentFormsUpdateEscalationRanksResponseBodyToJSON(value?: IncidentFormsUpdateEscalationRanksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_form_elements': ((value.incident_form_elements as Array<any>).map(IncidentFormEscalationElementToJSON)),
    };
}

