/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsNotionGetConfigResponseBody,
    IntegrationsNotionGetConfigResponseBodyFromJSON,
    IntegrationsNotionGetConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsNotionHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsNotionHandleRedirectRequest {
    code?: string;
    state?: string;
}

/**
 * 
 */
export class IntegrationsNotionApi extends runtime.BaseAPI {

    /**
     * Get organisation\'s Notion config. There is only one non-archived config per org.
     * GetConfig Integrations - Notion
     */
    async integrationsNotionGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsNotionGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/notion/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsNotionGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s Notion config. There is only one non-archived config per org.
     * GetConfig Integrations - Notion
     */
    async integrationsNotionGetConfig(initOverrides?: RequestInit): Promise<IntegrationsNotionGetConfigResponseBody> {
        const response = await this.integrationsNotionGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Notion installation by redirecting to the Notion install endpoint
     * HandleInstall Integrations - Notion
     */
    async integrationsNotionHandleInstallRaw(requestParameters: IntegrationsNotionHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsNotionHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/notion_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Notion installation by redirecting to the Notion install endpoint
     * HandleInstall Integrations - Notion
     */
    async integrationsNotionHandleInstall(requestParameters: IntegrationsNotionHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsNotionHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Notion redirect, where we exchange the code for an access token
     * HandleRedirect Integrations - Notion
     */
    async integrationsNotionHandleRedirectRaw(requestParameters: IntegrationsNotionHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/notion`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Notion redirect, where we exchange the code for an access token
     * HandleRedirect Integrations - Notion
     */
    async integrationsNotionHandleRedirect(requestParameters: IntegrationsNotionHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsNotionHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Notion OAuth token
     * HandleUninstall Integrations - Notion
     */
    async integrationsNotionHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/notion`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Notion OAuth token
     * HandleUninstall Integrations - Notion
     */
    async integrationsNotionHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsNotionHandleUninstallRaw(initOverrides);
    }

}
