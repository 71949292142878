import { useEffect } from "react";
import { useIdentity } from "src/contexts/IdentityContext";

import { useStopImpersonating } from "./useStopImpersonating";
import { useStoredSession } from "./useStoredSession";

export const WatchForImpersonationExpiring = () => {
  const { identity } = useIdentity();
  const { session } = useStoredSession({ orgSlug: identity.organisation_slug });
  const stopImpersonating = useStopImpersonating();

  // If the session is ended elsewhere, exit impersonation
  useEffect(() => {
    if (!session) {
      stopImpersonating();
    }
  }, [session, stopImpersonating]);

  return <></>;
};
