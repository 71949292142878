import { Icon, IconEnum } from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";

export const InternalStatusPageBanner = (): React.ReactElement => {
  const { identity } = useIdentity();

  return (
    <div className="bg-surface-secondary dark:bg-surface-invert text-slate-700 dark:text-white px-2 py-3 text-sm">
      <div className="container px-4 py-2 space-y-1 mx-auto md:px-2 md:max-w-[718px]">
        <div className="flex font-medium">
          <Icon id={IconEnum.LockClosed} className="mr-1 text-slate-600" />
          <div>You&apos;re viewing an internal status page</div>
        </div>
        <div>
          This page is for internal use only and cannot be viewed by anyone
          outside of {identity?.organisation_name ?? "your organisation"}.
        </div>
      </div>
    </div>
  );
};
