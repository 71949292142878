/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SuggestedFollowUp,
    SuggestedFollowUpFromJSON,
    SuggestedFollowUpFromJSONTyped,
    SuggestedFollowUpToJSON,
} from './SuggestedFollowUp';

/**
 * 
 * @export
 * @interface FollowUpsListSuggestedFollowUpsResponseBody
 */
export interface FollowUpsListSuggestedFollowUpsResponseBody {
    /**
     * 
     * @type {Array<SuggestedFollowUp>}
     * @memberof FollowUpsListSuggestedFollowUpsResponseBody
     */
    suggested_follow_ups: Array<SuggestedFollowUp>;
}

export function FollowUpsListSuggestedFollowUpsResponseBodyFromJSON(json: any): FollowUpsListSuggestedFollowUpsResponseBody {
    return FollowUpsListSuggestedFollowUpsResponseBodyFromJSONTyped(json, false);
}

export function FollowUpsListSuggestedFollowUpsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsListSuggestedFollowUpsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suggested_follow_ups': ((json['suggested_follow_ups'] as Array<any>).map(SuggestedFollowUpFromJSON)),
    };
}

export function FollowUpsListSuggestedFollowUpsResponseBodyToJSON(value?: FollowUpsListSuggestedFollowUpsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suggested_follow_ups': ((value.suggested_follow_ups as Array<any>).map(SuggestedFollowUpToJSON)),
    };
}

