/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentDurationMetric
 */
export interface IncidentDurationMetric {
    /**
     * How this metric should be calculated
     * @type {string}
     * @memberof IncidentDurationMetric
     */
    calculation_mode: IncidentDurationMetricCalculationModeEnum;
    /**
     * 
     * @type {Date}
     * @memberof IncidentDurationMetric
     */
    created_at: Date;
    /**
     * The id of the incident timestamp where this metric starts.
     * @type {string}
     * @memberof IncidentDurationMetric
     */
    from_timestamp_id: string;
    /**
     * Unique ID of this incident duration metric
     * @type {string}
     * @memberof IncidentDurationMetric
     */
    id: string;
    /**
     * What type of metric this is
     * @type {string}
     * @memberof IncidentDurationMetric
     */
    metric_type: IncidentDurationMetricMetricTypeEnum;
    /**
     * Unique name of this duration metric
     * @type {string}
     * @memberof IncidentDurationMetric
     */
    name: string;
    /**
     * Order in which this timestamp should be shown
     * @type {number}
     * @memberof IncidentDurationMetric
     */
    rank: number;
    /**
     * The id of the incident timestamp where this metric starts.
     * @type {string}
     * @memberof IncidentDurationMetric
     */
    to_timestamp_id: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentDurationMetric
     */
    updated_at: Date;
    /**
     * Whether this metrics timestamps should be validated
     * @type {boolean}
     * @memberof IncidentDurationMetric
     */
    validate: boolean;
}

/**
* @export
* @enum {string}
*/
export enum IncidentDurationMetricCalculationModeEnum {
    ExcludePaused = 'exclude_paused',
    IncludePaused = 'include_paused'
}/**
* @export
* @enum {string}
*/
export enum IncidentDurationMetricMetricTypeEnum {
    Lasted = 'lasted',
    Custom = 'custom'
}

export function IncidentDurationMetricFromJSON(json: any): IncidentDurationMetric {
    return IncidentDurationMetricFromJSONTyped(json, false);
}

export function IncidentDurationMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentDurationMetric {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calculation_mode': json['calculation_mode'],
        'created_at': (new Date(json['created_at'])),
        'from_timestamp_id': json['from_timestamp_id'],
        'id': json['id'],
        'metric_type': json['metric_type'],
        'name': json['name'],
        'rank': json['rank'],
        'to_timestamp_id': json['to_timestamp_id'],
        'updated_at': (new Date(json['updated_at'])),
        'validate': json['validate'],
    };
}

export function IncidentDurationMetricToJSON(value?: IncidentDurationMetric | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calculation_mode': value.calculation_mode,
        'created_at': (value.created_at.toISOString()),
        'from_timestamp_id': value.from_timestamp_id,
        'id': value.id,
        'metric_type': value.metric_type,
        'name': value.name,
        'rank': value.rank,
        'to_timestamp_id': value.to_timestamp_id,
        'updated_at': (value.updated_at.toISOString()),
        'validate': value.validate,
    };
}

