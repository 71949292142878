import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  LoadingBar,
} from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import React, { useState } from "react";
import { useParams } from "react-router";
import {
  IncidentLifecyclesCreateStatusRequestBodyCategoryEnum as StatusCategoryEnum,
  IncidentStatus,
  PostIncidentFlow,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { IncidentStatusCreateModal } from "../lifecycle/statuses/status-create/IncidentStatusCreateModal";
import { IncidentStatusEditModal } from "../lifecycle/statuses/status-edit/IncidentStatusEditModal";
import { PostIncidentFlowDeleteModal } from "./PostIncidentFlowDeleteModal";
import { PostIncidentFlowRenameModal } from "./PostIncidentFlowRenameModal";
import { PostIncidentStatusList } from "./PostIncidentStatusList";

export const PostIncidentFlowViewPage = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const {
    data: { post_incident_flows: flows },
    isLoading,
    error,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  const { hasScope, isImpersonating } = useIdentity();
  const canDelete =
    hasScope(ScopeNameEnum.OrganisationSettingsUpdate) && !isImpersonating;
  const flow = flows.find((flow) => flow.id === id);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (isLoading) {
    return <LoadingBar className="h-36" />;
  }

  if (!flow) {
    // This is a 404 effectively
    return <OrgAwareNavigate to="/settings/post-incident-flows" />;
  }

  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <SecondaryNavSubPageWrapper
        icon={IconEnum.Checklist}
        crumbs={[
          {
            title: "Post-incident flow",
            to: "/settings/post-incident-flow",
          },
        ]}
        title={`Configure post-incident flow: ${flow.name}`}
        onEditTitle={() => setShowRenameModal(true)}
        backHref={"/settings/post-incident-flow"}
        accessory={
          <Button
            analyticsTrackingId="post-incident-flow-delete"
            theme={ButtonTheme.DestroySecondary}
            icon={IconEnum.Delete}
            disabled={!canDelete}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete flow
          </Button>
        }
      >
        <PostIncidentFlowComponent flow={flow} isOnlyFlow={false} />
        {showRenameModal && (
          <PostIncidentFlowRenameModal
            flow={flow}
            onClose={() => setShowRenameModal(false)}
          />
        )}
        {showDeleteModal && (
          <PostIncidentFlowDeleteModal
            flow={flow}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
      </SecondaryNavSubPageWrapper>
    </ErrorBoundary>
  );
};

export const PostIncidentFlowComponent = ({
  flow,
  isOnlyFlow,
}: {
  flow: PostIncidentFlow;
  isOnlyFlow: boolean;
}) => {
  const [showAddStatusModal, setShowAddStatusModal] = useState(false);
  const [editingStatus, setEditingStatus] = useState<IncidentStatus | null>(
    null,
  );

  return (
    <>
      <div className="space-y-8">
        <PostIncidentStatusList
          flow={flow}
          isOnlyFlow={isOnlyFlow}
          onEdit={(status) => setEditingStatus(status)}
          onCreate={() => setShowAddStatusModal(true)}
        />
      </div>
      {/* Modals */}
      {showAddStatusModal && (
        <IncidentStatusCreateModal
          onClose={() => setShowAddStatusModal(false)}
          category={StatusCategoryEnum.PostIncident}
          postIncidentFlow={flow}
        />
      )}
      {editingStatus && (
        <IncidentStatusEditModal
          onClose={() => setEditingStatus(null)}
          existingStatus={editingStatus}
        />
      )}
    </>
  );
};
