import type { SVGProps } from "react";
import * as React from "react";
const SvgOutlook = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <mask
      id="Outlook_svg__a"
      width={16}
      height={16}
      x={2}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M2 2.5h16v14.884H2V2.5Z" />
    </mask>
    <g mask="url(#Outlook_svg__a)">
      <path
        fill="#0A2767"
        d="M18 10.314a.359.359 0 0 0-.17-.307h-.001l-.008-.003-5.544-3.282a.744.744 0 0 0-.832 0l-5.544 3.282-.006.003a.362.362 0 0 0 .009.62l5.544 3.281a.744.744 0 0 0 .831 0l5.544-3.282a.357.357 0 0 0 .178-.312Z"
      />
      <path
        fill="#0364B8"
        d="M6.548 8.123h3.639v3.335H6.548V8.123Zm10.708-3.39V3.207a.691.691 0 0 0-.674-.707H7.14a.691.691 0 0 0-.675.707v1.526l5.582 1.488 5.21-1.488Z"
      />
      <path fill="#0078D4" d="M6.465 4.733h3.721v3.349H6.464v-3.35Z" />
      <path
        fill="#28A8EA"
        d="M13.907 4.733h-3.722v3.349l3.722 3.348h3.349V8.082l-3.349-3.35Z"
      />
      <path fill="#0078D4" d="M10.185 8.082h3.722v3.348h-3.722V8.082Z" />
      <path fill="#0364B8" d="M10.185 11.43h3.722v3.35h-3.722v-3.35Z" />
      <path fill="#14447D" d="M6.548 11.458h3.638v3.032H6.548v-3.032Z" />
      <path fill="#0078D4" d="M13.907 11.43h3.349v3.35h-3.349v-3.35Z" />
      <path
        fill="url(#Outlook_svg__b)"
        d="m17.83 10.606-.008.004-5.544 3.117a.773.773 0 0 1-.375.116l-.303-.176a.766.766 0 0 1-.075-.043l-5.618-3.207h-.002l-.184-.103v6.312c.003.421.346.76.768.758h10.755l.018-.003a.935.935 0 0 0 .261-.055.751.751 0 0 0 .104-.053.751.751 0 0 0 .372-.646v-6.313c0 .12-.064.232-.17.292Z"
      />
      <path
        fill="#0A2767"
        d="M17.702 10.29v.386l-5.798 3.992-6.001-4.248-.001-.003-.003-.001-.55-.331v-.28l.227-.003.48.275.01.004.04.026s5.642 3.219 5.657 3.226l.216.126.06-.022 5.6-3.152.063.004Z"
        opacity={0.5}
      />
      <path
        fill="#1490DF"
        d="m17.83 10.606-.008.004-5.544 3.118a.778.778 0 0 1-.83 0L5.902 10.61l-.007-.004a.335.335 0 0 1-.175-.292v6.312c.002.421.346.76.768.758h10.744a.763.763 0 0 0 .767-.758v-6.312c0 .12-.066.232-.17.292Z"
      />
      <path
        fill="#000"
        d="m12.359 13.682-.084.046a.771.771 0 0 1-.367.12l2.11 2.494 3.68.887a.76.76 0 0 0 .233-.291l-5.572-3.256Z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="m12.735 13.47-.46.258a.771.771 0 0 1-.367.12l.989 2.725 4.802.655a.752.752 0 0 0 .3-.602v-.081l-5.264-3.075Z"
        opacity={0.05}
      />
      <path
        fill="#28A8EA"
        d="M6.498 17.384h10.734a.771.771 0 0 0 .46-.149L11.6 13.667a.766.766 0 0 1-.075-.043l-5.618-3.206h-.003l-.183-.104v6.29c0 .431.348.78.777.78Z"
      />
      <path
        fill="#000"
        d="M10.93 6.158v7.938a.685.685 0 0 1-.68.684H5.722V5.849h.744v-.372h3.784c.376.001.68.306.68.682Z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="M10.558 6.531v7.937a.605.605 0 0 1-.056.26.678.678 0 0 1-.625.424H5.72V5.85h4.156a.624.624 0 0 1 .308.078.676.676 0 0 1 .373.603Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M10.558 6.531v7.193a.687.687 0 0 1-.681.683H5.72V5.85h4.156a.624.624 0 0 1 .308.079.677.677 0 0 1 .373.602Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M10.186 6.531v7.193a.683.683 0 0 1-.68.683H5.722V5.85h3.784a.68.68 0 0 1 .68.681Z"
        opacity={0.2}
      />
      <path
        fill="url(#Outlook_svg__c)"
        d="M2.682 5.85h6.822c.376 0 .682.304.682.681v6.823a.682.682 0 0 1-.682.681H2.682A.682.682 0 0 1 2 13.354V6.53c0-.376.306-.681.682-.681Z"
      />
      <path
        fill="#fff"
        d="M4.132 8.71c.168-.359.439-.658.779-.861a2.355 2.355 0 0 1 1.238-.31c.401-.01.797.093 1.145.294.327.195.59.48.759.822.182.376.273.791.265 1.21a2.8 2.8 0 0 1-.273 1.265c-.172.354-.444.65-.782.85a2.28 2.28 0 0 1-1.189.303 2.24 2.24 0 0 1-1.17-.298 1.996 1.996 0 0 1-.768-.824 2.591 2.591 0 0 1-.27-1.195 2.878 2.878 0 0 1 .266-1.256Zm.83 2.02c.09.227.242.423.439.567.2.14.44.212.684.205.26.01.516-.064.73-.212a1.23 1.23 0 0 0 .426-.567c.094-.253.14-.52.136-.79a2.407 2.407 0 0 0-.128-.8 1.265 1.265 0 0 0-.412-.59 1.132 1.132 0 0 0-.724-.223 1.182 1.182 0 0 0-.701.208c-.2.144-.355.342-.447.571a2.27 2.27 0 0 0-.003 1.632Z"
      />
      <path fill="#50D9FF" d="M13.907 4.733h3.349v3.349h-3.349v-3.35Z" />
    </g>
    <defs>
      <linearGradient
        id="Outlook_svg__b"
        x1={11.86}
        x2={11.86}
        y1={10.314}
        y2={17.384}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35B8F1" />
        <stop offset={1} stopColor="#28A8EA" />
      </linearGradient>
      <linearGradient
        id="Outlook_svg__c"
        x1={3.422}
        x2={8.764}
        y1={5.317}
        y2={14.569}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1784D9" />
        <stop offset={0.5} stopColor="#107AD5" />
        <stop offset={1} stopColor="#0A63C9" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgOutlook;
