import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
  SearchBar,
} from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { CATALOG_ENTRY_BULK_EDIT_PAGE_SIZE } from "./constants";

export const CatalogEntryListOptionsBar = ({
  isRankedType: isRankedType,
  toggleRankingView,
  setSearch,
  search,
  isRanking,
  isManagedExternally,
  hasPermissions,
  hasOverMaxEntriesForBulkEdit,
  numberOfRanksUpdated,
  onRankingSave,
  numEntries,
}: {
  isRankedType: boolean;
  toggleRankingView(): void;
  setSearch(search: string): void;
  search: string;
  isRanking: boolean;
  isManagedExternally: boolean;
  hasPermissions: boolean;
  hasOverMaxEntriesForBulkEdit: boolean;
  numberOfRanksUpdated: number;
  onRankingSave(): Promise<void>;
  numEntries: number | undefined;
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const hasEntries = !!(numEntries && numEntries > 0);

  const disabledTooltipContent = (
    isManagedExternally,
    hasPermissions,
    hasOverMaxEntriesForBulkEdit,
  ) => {
    if (isManagedExternally)
      return "Action unavailable for externally managed types.";
    if (hasOverMaxEntriesForBulkEdit)
      return `Action unavailable for types with over ${CATALOG_ENTRY_BULK_EDIT_PAGE_SIZE} entries`;
    if (!hasPermissions)
      return "You do not have permission to re-rank catalog types.";
    return null;
  };

  return (
    <div
      className={tcx("flex justify-between gap-2", isRanking && "justify-end")}
    >
      {isRanking ? (
        <div className="flex items-center gap-2 shrink-0">
          <div className="text-sm pr-2 shrink-0">
            <span className="text-black">{numberOfRanksUpdated}</span>
            <span className="text-slate-600"> entries re-ranked</span>
          </div>
          <Button
            analyticsTrackingId="catalog-rank-cancel"
            onClick={toggleRankingView}
          >
            Cancel
          </Button>
          <GatedButton
            analyticsTrackingId="catalog-rank-save"
            theme={ButtonTheme.Primary}
            onClick={async () => {
              setIsSaving(true);
              onRankingSave().finally(() => setIsSaving(false));
            }}
            loading={isSaving}
            disabled={isSaving}
          >
            Save
          </GatedButton>
        </div>
      ) : (
        <>
          <SearchBar
            id="search"
            value={search}
            onChange={setSearch}
            className="w-full"
            inputClassName={
              "bg-surface-secondary [&>svg]:text-slate-400 grow border-none"
            }
            placeholder="Search entries"
            disabled={isRanking}
            autoFocus={true}
          />

          {isRankedType && hasEntries && (
            <>
              <GatedButton
                analyticsTrackingId="custom-fields-reorder"
                onClick={toggleRankingView}
                disabledTooltipContent={disabledTooltipContent(
                  isManagedExternally,
                  hasPermissions,
                  hasOverMaxEntriesForBulkEdit,
                )}
                spanClassName="ml-auto"
                disabled={
                  !hasPermissions ||
                  isManagedExternally ||
                  hasOverMaxEntriesForBulkEdit
                }
                theme={ButtonTheme.Naked}
                icon={IconEnum.Rank}
                iconProps={
                  isManagedExternally
                    ? {
                        size: IconSize.Medium,
                        className: "!mr-0.5 text-slate-300",
                      }
                    : {
                        size: IconSize.Medium,
                        className: "!mr-0.5",
                      }
                }
              >
                Edit rank
              </GatedButton>
            </>
          )}
        </>
      )}
    </div>
  );
};
