import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { INTEGRATION_CONFIGS } from "@incident-shared/integrations";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import {
  IssueTemplateContextEnum,
  useAllIssueTemplates,
} from "@incident-shared/issue-trackers/useAllTemplates";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ButtonTheme, DropdownMenuItem, Icon, IconEnum } from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import React, { ForwardedRef, useState } from "react";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { useEnabledExportIssueTrackers } from "../issue-trackers/useEnabledExportIssueTrackers";
import { ISSUE_TEMPLATE_CONTEXTS } from "./config";

export const AddIssueTemplateButton = ({
  context,
}: {
  context: IssueTemplateContextEnum;
}) => {
  const { createTemplateUrl } = ISSUE_TEMPLATE_CONTEXTS[context];
  const { allTrackers, installedTrackers, isLoading } =
    useEnabledExportIssueTrackers(context);

  const navigate = useOrgAwareNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onAdd =
    installedTrackers.length === 1
      ? () => navigate(createTemplateUrl(installedTrackers[0]))
      : () => setIsOpen(true);

  return (
    // Using a DropdownMenu here means that we can't control the open state
    // manually, and for some reason the way GatedButton works means that the
    // dropdown menu doesn't manage its own open state properly. Sad.
    <Popover
      trigger={
        <GatedAddIssueTemplateButton
          allTrackers={allTrackers}
          installedTrackers={installedTrackers}
          isLoading={isLoading}
          onAdd={onAdd}
          context={context}
        />
      }
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {installedTrackers.map((provider) => {
        const config = INTEGRATION_CONFIGS[provider];

        return (
          <DropdownMenuItem
            key={provider}
            label={config.label}
            icon={config.icon}
            analyticsTrackingId={null}
            onSelect={() => navigate(createTemplateUrl(provider))}
          />
        );
      })}
    </Popover>
  );
};

const GatedAddIssueTemplateButton = React.forwardRef(
  (
    {
      allTrackers,
      installedTrackers,
      isLoading,
      onAdd,
      context,
    }: {
      allTrackers: IssueTrackerProviderEnum[];
      installedTrackers: IssueTrackerProviderEnum[];
      isLoading: boolean;
      onAdd: () => void;
      context: IssueTemplateContextEnum;
    },
    ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const { identity } = useIdentity();
    const { allTemplates } = useAllIssueTemplates(context);

    const { gatedCap, nounSingular, nounPlural } =
      ISSUE_TEMPLATE_CONTEXTS[context];
    const gatedCapForContext = gatedCap(identity);

    const orgCanCreateIssueTemplates = !numericGateLimitReached(
      gatedCapForContext,
      allTemplates.length,
    );

    const relevantIntegrationNames = allTrackers.map(
      (provider) => INTEGRATION_CONFIGS[provider].label,
    );

    return (
      <GatedButton
        ref={ref}
        loading={isLoading}
        disabled={installedTrackers.length === 0}
        disabledTooltipContent={
          <>
            To use this feature, you need to set up an integration with one of{" "}
            {joinSpansWithCommasAndConnectorWord(
              relevantIntegrationNames,
              "or",
            )}
            .
          </>
        }
        theme={ButtonTheme.Secondary}
        onClick={onAdd}
        analyticsTrackingId="add-issue-template"
        analyticsTrackingMetadata={{ context }}
        icon={IconEnum.Add}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        upgradeRequired={!orgCanCreateIssueTemplates}
        upgradeRequiredProps={{
          gate: {
            type: "numeric",
            value: gatedCapForContext,
            featureNameSingular: nounSingular,
          },
          featureName: nounPlural,
        }}
      >
        Add new template
        {installedTrackers.length > 1 && <Icon id={IconEnum.ChevronDown} />}
      </GatedButton>
    );
  },
);
GatedAddIssueTemplateButton.displayName = "GatedAddIssueTemplateButton";
