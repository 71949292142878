import type { SVGProps } from "react";
import * as React from "react";
const SvgRetrospective = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.385 5.767a1.24 1.24 0 0 0-1.24-.017L11 9.155V6.844c0-.447-.23-.85-.615-1.077a1.235 1.235 0 0 0-1.24-.016l-7.5 4.155a1.252 1.252 0 0 0 0 2.188l7.5 4.155a1.24 1.24 0 0 0 1.24-.016A1.24 1.24 0 0 0 11 15.156v-2.311l6.145 3.404a1.24 1.24 0 0 0 1.24-.016A1.24 1.24 0 0 0 19 15.156V6.844c0-.447-.23-.85-.615-1.077Z"
    />
  </svg>
);
export default SvgRetrospective;
