/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentsUpdateIncidentTypeRequestBody
 */
export interface IncidentsUpdateIncidentTypeRequestBody {
    /**
     * The ID of the incident type this incident should now use
     * @type {string}
     * @memberof IncidentsUpdateIncidentTypeRequestBody
     */
    incident_type_id: string;
}

export function IncidentsUpdateIncidentTypeRequestBodyFromJSON(json: any): IncidentsUpdateIncidentTypeRequestBody {
    return IncidentsUpdateIncidentTypeRequestBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateIncidentTypeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateIncidentTypeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_type_id': json['incident_type_id'],
    };
}

export function IncidentsUpdateIncidentTypeRequestBodyToJSON(value?: IncidentsUpdateIncidentTypeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_type_id': value.incident_type_id,
    };
}

