import {
  EscalationPreview,
  EscalationStatusEnum,
  EscalationTarget,
} from "@incident-io/api";
import { IconSize, StackedList, StackedListButton } from "@incident-ui";
import { uniqBy } from "lodash";
import {
  AvatarList,
  AvatarListClickableType,
} from "src/components/legacy/incident/sidebar/AvatarList";
import { EscalationStatusIcon } from "src/components/legacy/on-call/escalations/EscalationStatusIcon";

export const EscalationSection = ({
  escalations,
}: {
  escalations: EscalationPreview[];
}) => {
  const uniqueTargets = uniqBy(
    escalations.flatMap((escalation) => escalation.targets),
    (t) => (t ? t.user_id || t.slack_channel_name : ""),
  ).filter(Boolean) as EscalationTarget[];
  const targetUsers = uniqueTargets.map((t: EscalationTarget) => ({
    user: {
      id: t.id,
      name: t.slack_channel_name || t.user_name || "",
      avatar_url: t.image_url,
    },
  }));

  return (
    <div className="flex flex-col gap-3">
      <div className="text-sm-bold">Escalations </div>
      <StackedList>
        {escalations.length ? (
          escalations
            .sort((a, b) => (a.id < b.id ? -1 : 1))
            .slice(0, 10)
            .map((escalation) => {
              return (
                <StackedListButton
                  key={escalation.id}
                  iconNode={
                    <>
                      {" "}
                      <EscalationStatusIcon
                        status={
                          escalation.status as unknown as EscalationStatusEnum
                        }
                      />{" "}
                    </>
                  }
                  href={`/on-call/escalations/${escalation.id}`}
                  analyticsTrackingId="alerts-view-related-incident"
                  title={escalation.title}
                  accessory={
                    <AvatarList
                      modalTitle={"Escalated users"}
                      maxToShow={3}
                      users={targetUsers}
                      clickableType={AvatarListClickableType.OnlyOnSeeMore}
                      avatarClassName="border-surface-secondary group-hover:border-surface-tertiary"
                      avatarSize={IconSize.Large}
                    />
                  }
                />
              );
            })
        ) : (
          <div className="px-4 py-3 border border-stroke-primary rounded-2 text-content-tertiary">
            No escalations
          </div>
        )}
      </StackedList>
    </div>
  );
};
