import {
  IdentityUserStateEnum,
  UserOptionStateEnum,
  UserWithRolesStateEnum,
} from "@incident-io/api";

export const isOnCallUser = (
  s:
    | UserWithRolesStateEnum
    | UserOptionStateEnum
    | IdentityUserStateEnum
    | undefined,
) => {
  if (!s) {
    return false;
  }

  switch (s) {
    case UserWithRolesStateEnum.OnCall:
    case UserWithRolesStateEnum.OnCallResponder:
    case UserOptionStateEnum.OnCall:
    case UserOptionStateEnum.OnCallResponder:
    case IdentityUserStateEnum.OnCall:
    case IdentityUserStateEnum.OnCallResponder:
      return true;
    default:
      return false;
  }
};

export const isResponseUser = (
  s:
    | UserWithRolesStateEnum
    | UserOptionStateEnum
    | IdentityUserStateEnum
    | undefined,
) => {
  if (!s) {
    return false;
  }

  switch (s) {
    case UserWithRolesStateEnum.Responder:
    case UserWithRolesStateEnum.OnCallResponder:
    case UserOptionStateEnum.Responder:
    case UserOptionStateEnum.OnCallResponder:
    case IdentityUserStateEnum.Responder:
    case IdentityUserStateEnum.OnCallResponder:
      return true;
    default:
      return false;
  }
};
