import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { Interpose } from "@incident-ui/Interpose/Interpose";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

type CardProps = {
  title: React.ReactNode;
  color?: ColorPaletteEnum;
  className?: string;
  icon?: IconEnum;
  iconNode?: React.ReactNode;
  accessory?: React.ReactNode;
  disabled?: boolean;
  children: React.ReactNode;
  isHighlighted?: boolean;
};

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      title,
      color = ColorPaletteEnum.Blue,
      className,
      icon,
      iconNode,
      accessory,
      disabled,
      children,
      isHighlighted,
      ...rest
    }: CardProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const palette = getColorPalette(color);

    return (
      <div
        ref={ref}
        className={tcx(
          "flex flex-col items-start max-w-[560px] w-[560px] shadow-sm rounded-2 p-4 gap-4 bg-white overflow-hidden",
          isHighlighted ? "border border-blue-500" : "border border-stroke",
          className,
        )}
        {...rest}
      >
        <div className="flex items-center justify-between gap-2 w-full">
          <div className="flex items-center gap-2">
            {iconNode ? (
              iconNode
            ) : (
              <Icon
                id={icon || IconEnum.Cog}
                className={tcx(palette.icon, {
                  "text-content-tertiary": disabled,
                })}
              />
            )}
            <div
              className={tcx("text-sm-bold", {
                "text-content-tertiary": disabled,
              })}
            >
              {title}
            </div>
          </div>
          {accessory}
        </div>
        {children}
      </div>
    );
  },
);

Card.displayName = "FlowCards.Card";

const List = ({
  children,
  compact,
}: {
  children: React.ReactNode | React.ReactNode[];
  compact?: boolean;
}) => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Interpose separator={<Divider compact={compact} />}>
        {children}
      </Interpose>
    </div>
  );
};

const Divider = ({ compact }: { compact?: boolean }) => {
  return (
    <div className="flex flex-col items-center py-2">
      <div className="bg-slate-200 size-[5px] rounded-full" />
      <div className={tcx("w-[1px] bg-slate-200", compact ? "h-4" : "h-10")} />
      <div className="bg-slate-200 size-[5px] rounded-full" />
    </div>
  );
};

export const FlowCards = {
  List,
  Card,
  Divider,
};
