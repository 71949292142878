import { IconEnum } from "@incident-ui";
import _ from "lodash";
import { ExternalResource } from "src/contexts/ClientContext";

import { AttachmentBadge, AttachmentRenderProps } from "./IncidentAttachment";

export const OpsgenieAlert = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.opsgenie_alert) {
    throw new Error("Opsgenie alert resource without expected content");
  }

  const renderProps: AttachmentRenderProps = {
    icon: IconEnum.Opsgenie,
    title: `#${resource.opsgenie_alert.tiny_id}: ${resource.opsgenie_alert.message}`,
    unsafeBadges: [
      {
        icon: IconEnum.Status,
        text: _.startCase(resource.opsgenie_alert.status),
      },
    ],
  };

  const safeBadges: AttachmentBadge[] = [
    {
      icon: IconEnum.Severity,
      text: _.startCase(resource.opsgenie_alert.priority),
    },
  ];

  if (resource.opsgenie_alert.source) {
    safeBadges.push({
      icon: IconEnum.Server,
      text: resource.opsgenie_alert.source,
    });
  }

  renderProps.safeBadges = safeBadges;

  return renderProps;
};
