/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSubscription,
    IncidentSubscriptionFromJSON,
    IncidentSubscriptionFromJSONTyped,
    IncidentSubscriptionToJSON,
} from './IncidentSubscription';

/**
 * 
 * @export
 * @interface IncidentSubscriptionsGetResponseBody
 */
export interface IncidentSubscriptionsGetResponseBody {
    /**
     * 
     * @type {Array<IncidentSubscription>}
     * @memberof IncidentSubscriptionsGetResponseBody
     */
    incident_subscriptions: Array<IncidentSubscription>;
}

export function IncidentSubscriptionsGetResponseBodyFromJSON(json: any): IncidentSubscriptionsGetResponseBody {
    return IncidentSubscriptionsGetResponseBodyFromJSONTyped(json, false);
}

export function IncidentSubscriptionsGetResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSubscriptionsGetResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_subscriptions': ((json['incident_subscriptions'] as Array<any>).map(IncidentSubscriptionFromJSON)),
    };
}

export function IncidentSubscriptionsGetResponseBodyToJSON(value?: IncidentSubscriptionsGetResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_subscriptions': ((value.incident_subscriptions as Array<any>).map(IncidentSubscriptionToJSON)),
    };
}

