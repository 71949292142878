import type { SVGProps } from "react";
import * as React from "react";
const SvgAsana = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#F06A6A"
      d="M13.48 6.03c0 1.948-1.558 3.53-3.48 3.53S6.52 7.98 6.52 6.03C6.52 4.08 8.079 2.5 10 2.5s3.48 1.58 3.48 3.53Zm-8 4.41C3.559 10.44 2 12.02 2 13.97c0 1.948 1.558 3.529 3.48 3.529s3.48-1.58 3.48-3.53c0-1.948-1.558-3.528-3.48-3.528Zm9.04 0c-1.922 0-3.48 1.58-3.48 3.53 0 1.95 1.558 3.53 3.48 3.53 1.921 0 3.48-1.58 3.48-3.53 0-1.95-1.558-3.53-3.48-3.53Z"
    />
  </svg>
);
export default SvgAsana;
