/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertAttributeValue,
    AlertAttributeValueFromJSON,
    AlertAttributeValueFromJSONTyped,
    AlertAttributeValueToJSON,
} from './AlertAttributeValue';

/**
 * 
 * @export
 * @interface AlertAttributeBinding
 */
export interface AlertAttributeBinding {
    /**
     * If array_value is set, this helps render the values
     * @type {Array<AlertAttributeValue>}
     * @memberof AlertAttributeBinding
     */
    array_value?: Array<AlertAttributeValue>;
    /**
     * 
     * @type {AlertAttributeValue}
     * @memberof AlertAttributeBinding
     */
    value?: AlertAttributeValue;
}

export function AlertAttributeBindingFromJSON(json: any): AlertAttributeBinding {
    return AlertAttributeBindingFromJSONTyped(json, false);
}

export function AlertAttributeBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertAttributeBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array_value': !exists(json, 'array_value') ? undefined : ((json['array_value'] as Array<any>).map(AlertAttributeValueFromJSON)),
        'value': !exists(json, 'value') ? undefined : AlertAttributeValueFromJSON(json['value']),
    };
}

export function AlertAttributeBindingToJSON(value?: AlertAttributeBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array_value': value.array_value === undefined ? undefined : ((value.array_value as Array<any>).map(AlertAttributeValueToJSON)),
        'value': AlertAttributeValueToJSON(value.value),
    };
}

