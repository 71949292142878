import React from "react";
import { Route, Routes } from "react-router";

import { NudgesCreatePage } from "./create/NudgesCreatePage";
import { NudgesEditPage } from "./edit/NudgesEditPage";
import { NudgesListPage } from "./list/NudgesListPage";

export const NudgesRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<NudgesListPage />} />
      <Route path="/create" element={<NudgesCreatePage />} />
      <Route path="/:id/edit" element={<NudgesEditPage />} />
    </Routes>
  );
};
