import type { SVGProps } from "react";
import * as React from "react";
const SvgRefresh1 = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.72 8.97-.735.735C16.828 5.983 13.76 3 10 3c-2.056 0-4 .899-5.334 2.466a.75.75 0 1 0 1.143.972A5.498 5.498 0 0 1 9.999 4.5c3.033 0 5.5 2.467 5.5 5.5 0 .06-.01.118-.011.178L14.28 8.97a.75.75 0 1 0-1.061 1.061l2.25 2.25a.748.748 0 0 0 1.06 0l2.25-2.25a.75.75 0 0 0-1.061-1.061h.002ZM14.19 13.562A5.498 5.498 0 0 1 10 15.5 5.506 5.506 0 0 1 4.5 10c0-.06.01-.118.012-.178L5.72 11.03a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.061l-2.25-2.25a.75.75 0 0 0-1.061 0L1.219 9.97a.75.75 0 1 0 1.062 1.061l.735-.735c.157 3.722 3.225 6.705 6.985 6.705 2.056 0 4-.899 5.334-2.466a.75.75 0 1 0-1.143-.972l-.001-.001Z"
    />
  </svg>
);
export default SvgRefresh1;
