/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPreferencesUpdatePhoneNumberRequestBody
 */
export interface UserPreferencesUpdatePhoneNumberRequestBody {
    /**
     * Phone number to use for SMS communication
     * @type {string}
     * @memberof UserPreferencesUpdatePhoneNumberRequestBody
     */
    phone_number: string;
}

export function UserPreferencesUpdatePhoneNumberRequestBodyFromJSON(json: any): UserPreferencesUpdatePhoneNumberRequestBody {
    return UserPreferencesUpdatePhoneNumberRequestBodyFromJSONTyped(json, false);
}

export function UserPreferencesUpdatePhoneNumberRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferencesUpdatePhoneNumberRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone_number': json['phone_number'],
    };
}

export function UserPreferencesUpdatePhoneNumberRequestBodyToJSON(value?: UserPreferencesUpdatePhoneNumberRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone_number': value.phone_number,
    };
}

