import type { SVGProps } from "react";
import * as React from "react";
const SvgThreeArrowsUp = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.25 8.64a.667.667 0 0 0-.943 0l-2 2a.667.667 0 1 0 .943.943l.862-.862v2.835a.667.667 0 0 0 1.333 0V10.72l.863.862a.665.665 0 0 0 .942 0 .667.667 0 0 0 0-.943l-2-2ZM10 5.111a.667.667 0 0 0 .471-1.139l-1.999-2a.667.667 0 0 0-.943 0l-2 2a.667.667 0 1 0 .943.944l.862-.862v9.502a.667.667 0 0 0 1.334 0V4.054l.862.863c.13.13.3.194.47.194Z"
    />
    <path
      fill="currentColor"
      d="M12.694 5.974a.667.667 0 0 0-.943 0l-2 2a.667.667 0 1 0 .943.943l.862-.863v5.502a.667.667 0 0 0 1.334 0V8.054l.862.863a.665.665 0 0 0 .942 0 .667.667 0 0 0 0-.943l-2-2Z"
    />
  </svg>
);
export default SvgThreeArrowsUp;
