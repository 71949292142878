import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ConfigureDrawerProps as IntegrationConfigureDrawerProps } from "@incident-shared/integrations";
import { ContentBox, GenericErrorMessage, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IntegrationsSplunkOnCallSetBotUsernameRequestBody as FormData,
  ScopeNameEnum,
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const SplunkOnCallConfigureDrawer = (
  props: IntegrationConfigureDrawerProps,
): React.ReactElement | null => {
  const { data, isLoading, error } = useAPI(
    "integrationsSplunkOnCallGetBotUsername",
    undefined,
  );
  const currentBotUsername = data?.bot_username;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <DrawerInner
      {...props}
      currentBotUsername={currentBotUsername || undefined}
    />
  );
};

function DrawerInner({
  currentBotUsername,
  ...props
}: {
  currentBotUsername?: string;
} & IntegrationConfigureDrawerProps): React.ReactElement {
  const formMethods = useForm<FormData>({
    defaultValues: { bot_username: currentBotUsername },
  });

  const showToast = useToast();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsSplunkOnCallGetBotUsername",
    undefined,
    async (apiClient, { bot_username }) =>
      await apiClient.integrationsSplunkOnCallSetBotUsername({
        setBotUsernameRequestBody: { bot_username },
      }),
    {
      setError: formMethods.setError,
      onSuccess: () =>
        showToast({
          title: "Bot user updated",
          theme: ToastTheme.Success,
        }),
    },
  );

  const apiClient = useClient();
  return (
    <GenericConfigureDrawerContents {...props}>
      <Form.Root
        formMethods={formMethods}
        onSubmit={onSubmit}
        saving={saving}
        genericError={genericError}
      >
        <ContentBox className="p-4 flex items-center gap-2">
          <DynamicSingleSelectV2
            formMethods={formMethods}
            label="Bot user"
            name="bot_username"
            isClearable={false}
            helptext={
              <>
                Choose your bot user account. Splunk On-Call requires all
                incidents to be triggered by a specified user account, which
                does <strong>not</strong> receive any alerts about the incident.
                We recommend using a limited-access robot account for this.
              </>
            }
            placeholder="Please choose your bot user"
            rules={{ required: "Please choose your bot user" }}
            loadOptions={getTypeaheadOptions(
              apiClient,
              TypeaheadsListTypeaheadTypeEnum.SplunkOnCallUser,
            )}
            hydrateOptions={hydrateInitialSelectOptions(
              apiClient,
              TypeaheadsListTypeaheadTypeEnum.SplunkOnCallUser,
            )}
            suffixNode={
              <GatedButton
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                analyticsTrackingId={null}
                type="submit"
                disabled={!formMethods.formState.isDirty}
              >
                Save
              </GatedButton>
            }
          />
        </ContentBox>
      </Form.Root>
    </GenericConfigureDrawerContents>
  );
}
