import {
  StatusPage,
  StatusPageListSubscriptionsRequest,
} from "@incident-io/api";
import { useAPI } from "src/utils/swr";

type SubscriptionCountHookArgs = {
  statusPageId: StatusPage["id"];
  componentIds?: StatusPageListSubscriptionsRequest["componentIds"];
  statusPageIncidentId?: StatusPageListSubscriptionsRequest["statusPageIncidentId"];
  subPageIds?: string[];
};

export const useStatusPageSubscriptionCount = ({
  statusPageId,
  componentIds,
  statusPageIncidentId,
  subPageIds,
}: SubscriptionCountHookArgs) => {
  const { data } = useAPI("statusPageGetSubscriberCountForUpdate", {
    statusPageId,
    componentIds,
    statusPageIncidentId,
    subPageIds,
  });

  return data?.count;
};
