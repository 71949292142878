import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type ContentBoxProps = {
  children: React.ReactNode;
  /** The type of element to use for the content box */
  is?: "div" | "article" | "section";
  className?: string;
  overrideBackground?: boolean;
  analyticsTrackingId?: string;
  id?: string;
  onClick?: (...args: unknown[]) => unknown;
};

export const ContentBox = React.forwardRef(
  (
    {
      children,
      is = "div",
      id,
      className,
      overrideBackground,
      analyticsTrackingId,
      onClick,
    }: ContentBoxProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): React.ReactElement => {
    const El = is;
    return (
      <El
        onClick={onClick}
        ref={ref}
        className={tcx(
          "border shadow-sm rounded-2",
          { "bg-white border-stroke": !overrideBackground },
          className,
        )}
        id={id}
        data-intercom-target={analyticsTrackingId}
      >
        {children}
      </El>
    );
  },
);
ContentBox.displayName = "ContentBox";
