import {
  CatalogTypeCategoriesEnum,
  IntegrationSettingsProviderEnum,
} from "@incident-io/api";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { AnimatePresence } from "framer-motion";
import { Navigate, Route, Routes, useOutlet } from "react-router";

import { useHasTypeOfCategory } from "../useHasTypeOfCategory";
import { ServiceWizardAddAttributesStep } from "./ServiceWizardAddAttributesStep";
import { ServiceWizardBackstageDrawer } from "./ServiceWizardBackstageDrawer";
import { ServiceWizardChooseSourceOfTruthStep } from "./ServiceWizardChooseSourceOfTruthStep";
import { ServiceWizardIntegrationDrawer } from "./ServiceWizardIntegrationDrawer";
import { ServiceWizardManualDrawer } from "./ServiceWizardManualDrawer";

export const ServiceWizardRoutes = () => {
  return (
    <PageWrapper
      width={PageWidth.Full}
      crumbs={[{ title: "Catalog", to: "/catalog" }]}
      title="Set up your services"
      icon={IconEnum.Server}
      color={ColorPaletteEnum.Green}
      backHref="/catalog"
      overflowY={false}
    >
      <Routes>
        <Route path="/" element={<ServiceWizardHomeRoute />} />
        <Route
          path="/choose-source-of-truth/*"
          element={<ServiceWizardChooseSourceOfTruthRoute />}
        >
          <Route path="backstage" element={<ServiceWizardBackstageDrawer />} />
          <Route
            path="cortex"
            element={
              <ServiceWizardIntegrationDrawer
                provider={IntegrationSettingsProviderEnum.Cortex}
                resource="CortexService"
              />
            }
          />
          <Route
            path="opslevel"
            element={
              <ServiceWizardIntegrationDrawer
                provider={IntegrationSettingsProviderEnum.Opslevel}
                resource="OpsLevelService"
              />
            }
          />
          <Route
            path="custom-catalog"
            element={<ServiceWizardManualDrawer mode="custom-catalog" />}
          />
          <Route
            path="manual"
            element={<ServiceWizardManualDrawer mode="manual" />}
          />
        </Route>
        <Route
          path=":typeId/add-attributes/*"
          element={<ServiceWizardAddAttributesStep />}
        />
      </Routes>
    </PageWrapper>
  );
};

const ServiceWizardHomeRoute = () => {
  // TODO: if you've chosen Cortex or Opslevel, we should also go to that type.
  const { matchingType, typesLoading, typesError } = useHasTypeOfCategory(
    CatalogTypeCategoriesEnum.Service,
  );

  if (typesLoading) {
    return <FullPageLoader />;
  }
  if (typesError) {
    return <GenericErrorMessage />;
  }

  if (matchingType) {
    return <Navigate to={`${matchingType.id}/add-attributes`} />;
  }

  return <Navigate to="/catalog/service-wizard/choose-source-of-truth" />;
};

const ServiceWizardChooseSourceOfTruthRoute = () => {
  const drawer = useOutlet();

  return (
    <>
      <AnimatePresence> {drawer}</AnimatePresence>
      <ServiceWizardChooseSourceOfTruthStep />
    </>
  );
};
