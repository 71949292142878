import { SetupPageWrapper } from "@incident-shared/layout/SetupPageWrapper/SetupPageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
} from "@incident-ui";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useIdentity } from "src/contexts/IdentityContext";
import { getColor } from "src/utils/twConfig";

import { IncidentFlameAvatar } from "./common/IncidentFlameAvatar";
import { ReactComponent as InstallArrowSvg } from "./common/install-arrow.svg";
import { InstallationStepID } from "./useSetupSteps";

export const SetupSuccessPage = () => {
  const { identity } = useIdentity();

  const transitionAvatars = {
    duration: 1.4,
    delay: 1.2,
  };

  const navigate = useOrgAwareNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard");
    }, 11000);
  }, [navigate]);

  return (
    <SetupPageWrapper currentStepID={InstallationStepID.Dashboard}>
      {/* Stage 1: grey box with the avatars */}
      <motion.div
        animate={{
          opacity: [1, 0],
          y: [0, "-200px"],
        }}
        transition={{
          duration: 0.7,
          delay: 3,
        }}
      >
        <motion.div
          className="h-[200px] w-[436px] bg-slate-800 rounded-3 flex items-center justify-center"
          animate={{
            opacity: [0, 1],
            y: ["200px", 0],
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <motion.div
            className="z-10"
            animate={{
              x: [0, "71px", "71px"],
            }}
            transition={transitionAvatars}
          >
            <div className="overflow-hidden relative w-[60px] h-[60px] rounded-4">
              <motion.div
                className="absolute top-0"
                animate={{
                  left: [0, 0, 0, "70px"],
                }}
                transition={transitionAvatars}
              >
                <IncidentFlameAvatar />
              </motion.div>
              <motion.div
                className="absolute top-0"
                animate={{
                  left: ["-70px", "-70px", "-70px", 0],
                }}
                transition={transitionAvatars}
              >
                <div className="w-[60px] h-[60px] rounded-4 flex items-center justify-center bg-green-500">
                  <Icon
                    id={IconEnum.Tick}
                    size={IconSize.XL}
                    className="text-white"
                  />
                </div>
              </motion.div>
            </div>
          </motion.div>
          <motion.div
            className="w-[78px] flex justify-center"
            animate={{
              opacity: [1, 0, 0],
            }}
            transition={transitionAvatars}
          >
            <InstallArrowSvg />
          </motion.div>
          <motion.div
            className="rounded-[18px]"
            animate={{
              x: [0, "-71px", "-71px"],
              border: ["none", "4px solid white", "4px solid white"],
              backgroundColor: ["transparent", "white", "white"],
            }}
            transition={transitionAvatars}
          >
            <motion.div
              animate={{
                opacity: [1, 0],
              }}
              transition={transitionAvatars}
            >
              <SlackTeamAvatar
                url={identity.organisation_icon_url}
                className="w-[60px] h-[60px] rounded-4"
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      {/* Stage 2: success + confetti */}
      <motion.div
        className="-mt-[300px] flex flex-col items-center gap-10"
        animate={{
          opacity: [0, 1],
          y: ["200px", 0],
        }}
        transition={{
          duration: 1.5,
          delay: 3,
        }}
      >
        <div className="text-5xl-bold">
          <div>All set!</div>
          <div>{`You're good to go`}</div>
        </div>
        <Button
          analyticsTrackingId="setup-go-to-dashboard"
          theme={ButtonTheme.UnstyledPill}
          className="px-16 bg-brand relative overflow-hidden"
          href="/dashboard"
        >
          <motion.div
            className="absolute left-0 h-full bg-alarmalade-600"
            animate={{ width: ["0", "100%"] }}
            transition={{
              duration: 6,
              delay: 5.5,
            }}
          />
          <div className="text-white z-10">Continue to dashboard</div>
        </Button>
      </motion.div>
      <ThemedConfetti />
    </SetupPageWrapper>
  );
};

const ThemedConfetti = () => {
  const colors = {
    alarmalade: ["200", "300", "500", "700"],
    amber: ["200", "300"],
    blue: ["200", "300"],
  };
  const [showConfetti, setShowConfetti] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (!showConfetti) {
        setShowConfetti(true);
      }
    }, 5000);
  }, [showConfetti, setShowConfetti]);

  return (
    <Confetti
      run={showConfetti}
      numberOfPieces={100}
      colors={Object.keys(colors).flatMap((color) =>
        colors[color].map((shade) => getColor(color, shade)),
      )}
    />
  );
};
