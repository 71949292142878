import type { SVGProps } from "react";
import * as React from "react";
const SvgResume = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.1 8.478 5.608 3.222a1.746 1.746 0 0 0-1.749.023A1.725 1.725 0 0 0 3 4.744v10.512c0 .622.321 1.182.859 1.499.279.164.586.247.895.247.293 0 .586-.075.854-.223l9.491-5.256c.556-.307.901-.891.901-1.522s-.345-1.216-.9-1.523Z"
    />
  </svg>
);
export default SvgResume;
