import { Product } from "@incident-shared/billing";
import { TimestampDescription } from "@incident-shared/timestamps/TimestampDescription";
import { IconEnum } from "@incident-ui";
import _ from "lodash";
import React from "react";
import {
  DependentResource,
  DependentResourceResourceTypeEnum,
  IncidentDurationMetric,
  IncidentTimestamp,
  IncidentTimestampsDestroyRequest,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { getAnchorId, timestampAnchorPrefix } from "src/utils/anchor";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";

export const CustomTimestampRow = ({
  timestamp,
  onEdit,
  metrics,
}: {
  timestamp: IncidentTimestamp;
  onEdit: () => void;
  metrics: IncidentDurationMetric[];
}): React.ReactElement => {
  const { trigger: deleteTimestamp } = useAPIMutation(
    "incidentTimestampsList",
    undefined,
    async (apiClient, data: IncidentTimestampsDestroyRequest) => {
      await apiClient.incidentTimestampsDestroy(data);
    },
  );

  // This is a bit of a hack, but we need to know if the timestamp is referenced
  // in a metric so we don't delete it. This basically fudges the metric into the
  // dependent resources list, if it's there.
  const extraDependentResources = _.compact(
    metrics.map((metric): DependentResource | undefined => {
      if (
        metric.from_timestamp_id === timestamp.id ||
        metric.to_timestamp_id === timestamp.id
      ) {
        return {
          id: metric.id,
          resource_type:
            DependentResourceResourceTypeEnum.IncidentDurationMetric,
          label: metric.name,
          settings_url: "/settings/lifecycle",
          resource_type_label: "Incident duration metrics",
          can_be_auto_deleted: false,
        };
      }
      return undefined;
    }),
  );

  const anchorId = `${timestampAnchorPrefix}/${timestamp.id}`;

  return (
    <div
      id={anchorId}
      className={tcx("w-full", getAnchorId() === anchorId ? "anchored" : "")}
    >
      <SettingsListItem
        title={timestamp.name}
        icon={IconEnum.Clock}
        description={
          <div className="text-slate-600 text-sm flex items-center gap-2 flex-wrap">
            <TimestampDescription timestamp={timestamp} />
          </div>
        }
        descriptionPosition="inline"
        buttons={{
          requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
          requiredProduct: Product.Response,
          delete: {
            onDelete: () => deleteTimestamp({ id: timestamp.id }),
            deleteConfirmationTitle: "Delete timestamp",
            deleteConfirmationContent: (
              <>
                Are you sure you want to delete the{" "}
                <span className="font-bold">{timestamp.name}</span> timestamp?
              </>
            ),
            resourceTitle: timestamp.name,
            fetchDependentResources: [
              {
                resource_type: "IncidentTimestamp",
                id: timestamp.id,
              },
            ],
            extraDependentResources,
          },
          edit: {
            onEdit,
          },
        }}
      />
    </div>
  );
};
