/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface EngineScope
 */
export interface EngineScope {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EngineScope
     */
    aliases: { [key: string]: string; };
    /**
     * The references that are available in this scope
     * @type {Array<Reference>}
     * @memberof EngineScope
     */
    references: Array<Reference>;
}

export function EngineScopeFromJSON(json: any): EngineScope {
    return EngineScopeFromJSONTyped(json, false);
}

export function EngineScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineScope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aliases': json['aliases'],
        'references': ((json['references'] as Array<any>).map(ReferenceFromJSON)),
    };
}

export function EngineScopeToJSON(value?: EngineScope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aliases': value.aliases,
        'references': ((value.references as Array<any>).map(ReferenceToJSON)),
    };
}

