/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertPulseChannelBindingPayload,
    AlertPulseChannelBindingPayloadFromJSON,
    AlertPulseChannelBindingPayloadFromJSONTyped,
    AlertPulseChannelBindingPayloadToJSON,
} from './AlertPulseChannelBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface AlertPulseConfigPayload
 */
export interface AlertPulseConfigPayload {
    /**
     * The channels that we're going to send this pulse to
     * @type {Array<AlertPulseChannelBindingPayload>}
     * @memberof AlertPulseConfigPayload
     */
    channel_bindings: Array<AlertPulseChannelBindingPayload>;
    /**
     * The expressions used in this template
     * @type {Array<ExpressionPayload>}
     * @memberof AlertPulseConfigPayload
     */
    expressions: Array<ExpressionPayload>;
}

export function AlertPulseConfigPayloadFromJSON(json: any): AlertPulseConfigPayload {
    return AlertPulseConfigPayloadFromJSONTyped(json, false);
}

export function AlertPulseConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertPulseConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel_bindings': ((json['channel_bindings'] as Array<any>).map(AlertPulseChannelBindingPayloadFromJSON)),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
    };
}

export function AlertPulseConfigPayloadToJSON(value?: AlertPulseConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_bindings': ((value.channel_bindings as Array<any>).map(AlertPulseChannelBindingPayloadToJSON)),
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
    };
}

