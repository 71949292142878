import _ from "lodash";
import {
  ErrorResponse,
  Expression,
  IncidentRole,
  IncidentTimestamp,
  PoliciesBuildScopeResponseBody,
  PolicyReportSchedule,
  RequirementsTemplatesForType,
  Resource,
  Severity,
} from "src/contexts/ClientContext";
import { IncidentRoleRoleTypeEnum } from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { useAPI } from "src/utils/swr";

import { TimestampType } from "../../lifecycle/timestamps-metrics/IncidentTimestampsSection";

export type PolicyCreateEditDeps = {
  resources: Resource[];
  scopeAndResources: PoliciesBuildScopeResponseBody;
  allowedTimestamps: IncidentTimestamp[];
  leadRole?: IncidentRole;
  severities: Severity[];
  requirementsTemplates: RequirementsTemplatesForType[];
  defaultSLAExpression?: Expression;
  reportSchedules: PolicyReportSchedule[];
};

export const useGetPolicyCreateEditDeps = (): {
  isLoading: boolean;
  error?: ErrorResponse;
  deps: PolicyCreateEditDeps;
} => {
  const {
    data: scopeAndResources,
    isLoading: scopeLoading,
    error: scopeError,
  } = useAPI("policiesBuildScope", undefined);

  const {
    data: defaultSLAExpression,
    isLoading: defaultSLAExpressionLoading,
    error: defaultSLAExpressionError,
  } = useAPI("policiesDefaultSLAExpression", undefined);

  const { resources, resourcesLoading } = useAllResources();

  const {
    data: { incident_timestamps: incidentTimestamps },
    isLoading: incidentTimestampsLoading,
    error: incidentTimestampsError,
  } = useAPI("incidentTimestampsList", undefined, {
    fallbackData: { incident_timestamps: [] },
  });

  const {
    data: { incident_roles: incidentRoles },
    isLoading: incidentRolesLoading,
    error: incidentRolesError,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const {
    data: { severities },
    isLoading: severitiesLoading,
    error: severitiesError,
  } = useAPI("severitiesList", undefined, {
    fallbackData: { severities: [] },
  });

  const {
    data: { requirements_templates: requirementsTemplates },
    isLoading: requirementsTemplatesLoading,
    error: requirementsTemplatesError,
  } = useAPI("policiesListRequirementsTemplates", undefined, {
    fallbackData: { requirements_templates: [] },
  });

  const {
    data: { report_schedules: reportSchedules },
    isLoading: scheduleLoading,
    error: scheduleError,
  } = useAPI("policiesListReportSchedule", undefined, {
    fallbackData: { report_schedules: [] },
  });

  const error =
    scopeError ||
    incidentTimestampsError ||
    incidentRolesError ||
    requirementsTemplatesError ||
    defaultSLAExpressionError ||
    severitiesError ||
    scheduleError;

  const isLoading =
    scopeLoading ||
    defaultSLAExpressionLoading ||
    !defaultSLAExpression ||
    incidentTimestampsLoading ||
    incidentRolesLoading ||
    resourcesLoading ||
    requirementsTemplatesLoading ||
    severitiesLoading ||
    !scopeAndResources ||
    scheduleLoading ||
    !reportSchedules;

  const leadRole = incidentRoles.find(
    ({ role_type }) => role_type === IncidentRoleRoleTypeEnum.Lead,
  );

  const bannedTimestampTypes = [
    TimestampType.MergedAt,
    TimestampType.DeclinedAt,
    TimestampType.CanceledAt,
  ];
  const timestampIsIrrelevant = (timestamp: IncidentTimestamp) =>
    bannedTimestampTypes.includes(timestamp.timestamp_type);

  const timestampIsAutomated = (timestamp: IncidentTimestamp) =>
    timestamp.set_on_creation ||
    timestamp.set_on_resolution ||
    timestamp.set_by_rules.length > 0;

  const allowedTimestamps = _.sortBy(
    incidentTimestamps.filter(
      (ts) => timestampIsAutomated(ts) && !timestampIsIrrelevant(ts),
    ),
    (ts) => ts.rank,
  );

  return {
    isLoading,
    error,
    deps: {
      resources,
      scopeAndResources: scopeAndResources as PoliciesBuildScopeResponseBody,
      allowedTimestamps,
      leadRole,
      severities,
      requirementsTemplates,
      defaultSLAExpression: defaultSLAExpression?.expression,
      reportSchedules,
    },
  };
};
