import {
  DynamicMultiSelectV2,
  DynamicSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { InputType } from "@incident-ui/Input/Input";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  JiraIssueField as JiraIssueField,
  JiraIssueFieldTypeEnum as FieldTypeEnum,
  SelectOption,
} from "src/contexts/ClientContext";

type JiraSelectProps = {
  fieldKey: string; // dynamic_fields.customfield_10014
  required?: boolean; // false
  label: React.ReactNode;
  accessory?: React.ReactNode;
  isMulti?: boolean; // false
  getJiraOptions: (query?: string) => Promise<SelectOption[]>;
  dependencies: unknown[];
  disabled?: boolean;
  defaultValue?: string | string[];
  loading?: boolean;
};

// Provide a select input based on the values provided in getJiraOptions.
export const JiraSelect = ({
  fieldKey,
  required,
  label,
  accessory,
  isMulti = false,
  getJiraOptions,
  dependencies,
  disabled,
  defaultValue,
  loading,
}: JiraSelectProps): React.ReactElement => {
  const formMethods = useFormContext();

  const [options, setOptions] = useState<SelectOption[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const notReady = dependencies.some(_.isEmpty);

  // Only call getJiraOptions whenever any of our dependencies change, limiting the
  // reloads we make.
  useEffect(() => {
    if (notReady) {
      return;
    }

    async function loadJiraOptions() {
      try {
        setOptionsLoading(true);
        const typeaheadOptions = await getJiraOptions();
        if (typeaheadOptions == null) {
          return;
        }

        // If we've been passed a default, set it in form state
        if (defaultValue) {
          formMethods.setValue(fieldKey, defaultValue);
        }
        setOptions(typeaheadOptions);
      } catch (error) {
        if (error instanceof Response && error.status === 403) {
          setError(
            "The connection with Jira has expired. Please reconnect your Jira account from the Integrations page.",
          );
        } else {
          setError("Something's gone wrong. Please try again.");
        }
        console.error(error);
      } finally {
        setOptionsLoading(false);
      }
    }

    loadJiraOptions();

    // eslint-disable-next-line
  }, [notReady, ...dependencies]);

  return (
    <>
      {isMulti ? (
        <DynamicMultiSelectV2
          suffixNode={accessory}
          label={label}
          formMethods={formMethods}
          name={fieldKey}
          placeholder="Select..."
          invalid={!!error}
          isLoading={optionsLoading || loading}
          isDisabled={disabled}
          required={required ? "Please choose an option" : false}
          hydrateOptions={async (ids) => {
            if (!ids) return [];

            const results = await Promise.all(
              ids.map((id) => getJiraOptions(id)),
            );

            // It's very important that we return at least one option for each
            // of the ids we've been asked to hydrate. Therefore if the API
            // response doesn't return an option we need to fake one here.
            const existingOptions = results.flat();
            const existingOptionsSet = new Set(
              existingOptions.map(({ value }) => value),
            );
            const missingIds = ids.filter((id) => !existingOptionsSet.has(id));

            return [
              ...existingOptions,
              ...missingIds.map((id) => ({
                value: id,
                label: `${id} (archived)`,
              })),
            ];
          }}
          loadOptions={(query: string) => {
            const typeaheadOptions = getJiraOptions(query);
            return typeaheadOptions;
          }}
        />
      ) : (
        <StaticSingleSelectV2
          suffixNode={accessory}
          label={label}
          formMethods={formMethods}
          name={fieldKey}
          options={options}
          placeholder="Select..."
          invalid={!!error}
          isLoading={optionsLoading || loading}
          disabled={disabled}
          required={required ? "Please choose an option" : false}
        />
      )}
    </>
  );
};

type OptionLoader = (props: {
  field: SelectOption;
  siteId: string;
  projectId?: string;
  issueTypeId?: string;
  query?: string;
}) => Promise<SelectOption[]>;

const fieldTypeEnumToInputTypeMapping = {
  [FieldTypeEnum.Date]: InputType.Date,
  [FieldTypeEnum.DateTime]: InputType.DatetimeLocal,
  [FieldTypeEnum.Number]: InputType.Number,
  [FieldTypeEnum.Text]: InputType.Text,
};

export const JiraIssueFieldInput = ({
  field,
  fieldKey,
  siteId,
  projectId,
  issueTypeId,
  initialValue,
  disabled,
  loadOptions,
  loading,
}: {
  field: JiraIssueField;
  fieldKey: string;
  siteId: string;
  projectId: string;
  issueTypeId: string;
  initialValue?: string | string[];
  disabled?: boolean;
  loadOptions: OptionLoader;
  loading: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext();

  switch (field.type) {
    case FieldTypeEnum.Date:
    case FieldTypeEnum.DateTime:
    case FieldTypeEnum.Number:
    case FieldTypeEnum.Text:
      return (
        <InputV2
          formMethods={formMethods}
          type={fieldTypeEnumToInputTypeMapping[field.type]}
          label={field.label}
          disabled={disabled}
          defaultValue={initialValue}
          name={fieldKey}
          required={field.required ? "This field is required" : false}
        />
      );

    case FieldTypeEnum.User:
      // For users, we need to use a fully dynamic typeahead, to be able to
      // search through the full set of users.
      return (
        <DynamicSingleSelectV2
          name={fieldKey}
          formMethods={formMethods}
          label={field.label}
          isDisabled={disabled}
          loadOptions={async (query) => {
            if (!projectId || !issueTypeId) {
              return [];
            }
            return await loadOptions({
              field: field.dynamic_typeahead_field as unknown as SelectOption,
              siteId,
              projectId,
              issueTypeId,
              query,
            });
          }}
          hydrateOptions={async (val) => {
            return await loadOptions({
              field: field.dynamic_typeahead_field as unknown as SelectOption,
              siteId,
              projectId,
              issueTypeId,
              query: val,
            });
          }}
          required={field.required}
        />
      );

    default:
      return (
        <JiraSelect
          fieldKey={fieldKey}
          required={field.required}
          isMulti={
            field.type === FieldTypeEnum.MultiSelect ||
            field.type === FieldTypeEnum.Labels
          }
          disabled={disabled}
          label={field.label}
          // @ts-expect-error I think it's fine and I don't think it's worth the time to fix it
          getJiraOptions={async (query?: string) => {
            if (field.allowed_values) {
              return field.allowed_values;
            }
            if (field.dynamic_typeahead_field) {
              return loadOptions({
                field: field.dynamic_typeahead_field as unknown as SelectOption,
                siteId,
                projectId,
                issueTypeId,
                query,
              });
            }
            return undefined;
          }}
          dependencies={[projectId, issueTypeId]}
          defaultValue={initialValue}
          loading={loading}
        />
      );
  }
};
