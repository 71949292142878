/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CallParticipant
 */
export interface CallParticipant {
    /**
     * Email of the participant
     * @type {string}
     * @memberof CallParticipant
     */
    email: string;
    /**
     * When the participant first joined the call
     * @type {Date}
     * @memberof CallParticipant
     */
    last_left_at?: Date;
    /**
     * 
     * @type {User}
     * @memberof CallParticipant
     */
    user?: User;
}

export function CallParticipantFromJSON(json: any): CallParticipant {
    return CallParticipantFromJSONTyped(json, false);
}

export function CallParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallParticipant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'last_left_at': !exists(json, 'last_left_at') ? undefined : (new Date(json['last_left_at'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function CallParticipantToJSON(value?: CallParticipant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'last_left_at': value.last_left_at === undefined ? undefined : (value.last_left_at.toISOString()),
        'user': UserToJSON(value.user),
    };
}

