import {
  StatusPage,
  StatusPageUpdateIntroTextRequestBody,
} from "@incident-io/api";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ErrorMessage } from "@incident-ui";
import { ButtonTheme } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

export const IntroText = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement | null => {
  return (
    <>
      <IntroTextForm page={page} />
    </>
  );
};

const IntroTextForm = ({ page }: { page: StatusPage }): React.ReactElement => {
  const formMethods = useForm<StatusPageUpdateIntroTextRequestBody>({
    defaultValues: { intro_text: page.intro_text },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, data: StatusPageUpdateIntroTextRequestBody) => {
      await apiClient.statusPageUpdateIntroText({
        id: page.id,
        updateIntroTextRequestBody: {
          ...data,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (res) =>
        formMethods.reset({ intro_text: res.status_page.intro_text }),
    },
  );
  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
    >
      <h3 className="font-medium">Introductory text</h3>
      <ErrorMessage message={genericError} />
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="intro_text"
        includeVariables={false}
        includeExpressions={false}
        format="mrkdwn"
      />

      <GatedButton
        type="submit"
        theme={ButtonTheme.Primary}
        analyticsTrackingId={"status-page-edit-intro-text"}
        analyticsTrackingMetadata={{ status_page_id: page.id }}
        loading={isMutating}
        disabled={missingPermission || !formMethods.formState.isDirty}
        disabledTooltipContent={
          missingPermission
            ? "You do not have permission to configure this public status page"
            : undefined
        }
      >
        Save
      </GatedButton>
    </Form.Root>
  );
};
