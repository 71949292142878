/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPreferences,
    UserPreferencesFromJSON,
    UserPreferencesFromJSONTyped,
    UserPreferencesToJSON,
} from './UserPreferences';

/**
 * 
 * @export
 * @interface UserPreferencesShowResponseBody
 */
export interface UserPreferencesShowResponseBody {
    /**
     * 
     * @type {UserPreferences}
     * @memberof UserPreferencesShowResponseBody
     */
    user_preferences: UserPreferences;
}

export function UserPreferencesShowResponseBodyFromJSON(json: any): UserPreferencesShowResponseBody {
    return UserPreferencesShowResponseBodyFromJSONTyped(json, false);
}

export function UserPreferencesShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferencesShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_preferences': UserPreferencesFromJSON(json['user_preferences']),
    };
}

export function UserPreferencesShowResponseBodyToJSON(value?: UserPreferencesShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_preferences': UserPreferencesToJSON(value.user_preferences),
    };
}

