import { marshallCustomFieldsToFormData } from "@incident-shared/forms/v2/CustomFieldFormElement";
import { DeclareFormData } from "@incident-shared/incident-forms";
import { compact } from "lodash";
import {
  CustomFieldFieldTypeEnum,
  IncidentStatusCategoryEnum,
} from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";
import { textDocFromString } from "src/utils/rich-text";
import { useAPI } from "src/utils/swr";

import {
  useAllStatuses,
  useIncidentCrudResources,
} from "./useIncidentCrudResources";

// We support creating URLs that direct you into a prefilled incident form by
// extracting the values from URL query parameters and setting them as the
// form default state, provided the value looks like it is valid.
export function usePrefillDefaultValues(): {
  prefillDefaultValues: Partial<DeclareFormData>;
  prefillLoading: boolean;
} {
  const { loading, ...resources } = useIncidentCrudResources();
  const { allStatuses, allStatusesLoading } = useAllStatuses();
  const params = useQueryParams();

  // get list of catalog backed entries (name, id, or external id)
  const catalogEntriesLookups = resources.customFields
    ? compact(
        resources.customFields.map((customField) => {
          const nameQueryParamKey = `custom_field_${customField.name}`;
          const idQueryParamKey = `custom_field_${customField.id}`;

          const customFieldParamValue =
            params.get(nameQueryParamKey) || params.get(idQueryParamKey); // customFieldParam value might be the name, internal id, or external id
          if (customFieldParamValue && customField.catalog_type_id) {
            return {
              catalog_type_id: customField.catalog_type_id,
              lookup_terms: [customFieldParamValue],
            };
          }
          return undefined;
        }),
      )
    : [];

  const { data, isLoading: catalogEntriesLoading } = useAPI(
    catalogEntriesLookups.length > 0 ? "catalogFindEntries" : null,
    {
      findEntriesRequestBody: { lookups: catalogEntriesLookups },
    },
  );

  if (loading || allStatusesLoading || catalogEntriesLoading) {
    return {
      prefillDefaultValues: {},
      prefillLoading: true,
    };
  }

  const defaultValues: Partial<DeclareFormData> = {
    custom_field_entries: marshallCustomFieldsToFormData({
      customFields: resources.customFields,
      entries: [],
    }),
  };

  const nameParam = params.get("name");
  if (nameParam) {
    defaultValues.name = nameParam;
  }
  const summaryParam = params.get("summary");
  if (summaryParam) {
    defaultValues.summary = textDocFromString(summaryParam);
  }

  // Support either severity ID or name.
  const severityParam = params.get("severity");
  if (severityParam) {
    const matchingSeverity = resources.severities.find(
      (severity) =>
        severity.id === severityParam || severity.name === severityParam,
    );
    if (matchingSeverity) {
      defaultValues.severity_id = matchingSeverity.id;
    }
  }

  // Support either status ID or name.
  const statusParam = params.get("status");
  if (statusParam) {
    const matchingStatus = allStatuses.find(
      (s) => s.id === statusParam || s.name === statusParam,
    );
    // From the URL, only use the status category, ignore the specific status.
    switch (matchingStatus?.category) {
      case IncidentStatusCategoryEnum.Active:
        defaultValues.active_or_triage = "active";
        break;
      case IncidentStatusCategoryEnum.Triage:
        defaultValues.active_or_triage = "triage";
        break;
    }
  }

  // Support either incident type ID or name.
  const incidentTypeParam = params.get("incident_type");
  if (incidentTypeParam) {
    const matchingIncidentType = resources.incidentTypes.find(
      (incidentType) =>
        incidentType.id === incidentTypeParam ||
        incidentType.name === incidentTypeParam,
    );
    if (matchingIncidentType) {
      defaultValues.incident_type_id = matchingIncidentType.id;
    }
  }

  // Support custom fields - single select, multi select & catalog backed
  resources.customFields.forEach((customField) => {
    const nameQueryParamKey = `custom_field_${customField.name}`;
    const idQueryParamKey = `custom_field_${customField.id}`;
    if (customField.field_type === CustomFieldFieldTypeEnum.MultiSelect) {
      // multi-select
      const customFieldParamValues = [
        ...params.getAll(nameQueryParamKey),
        ...params.getAll(idQueryParamKey),
      ];
      customFieldParamValues.forEach((customFieldParamValue) => {
        populateCustomFieldDefaultValues(
          customField,
          customFieldParamValue,
          defaultValues,
          data,
        );
      });
    } else if (
      customField.field_type === CustomFieldFieldTypeEnum.SingleSelect
    ) {
      // single-select
      const customFieldParamValue =
        params.get(nameQueryParamKey) || params.get(idQueryParamKey);
      populateCustomFieldDefaultValues(
        customField,
        customFieldParamValue,
        defaultValues,
        data,
      );
    }
  });

  return {
    prefillDefaultValues: defaultValues,
    prefillLoading: loading || allStatusesLoading,
  };
}

const populateCustomFieldDefaultValues = (
  customField,
  customFieldParamValue,
  defaultValues,
  data,
) => {
  if (customFieldParamValue && defaultValues.custom_field_entries) {
    if (customField.catalog_type_id) {
      // catalog-backed
      const entry = data.results[customField.catalog_type_id].find(
        (entry) =>
          entry.name === customFieldParamValue ||
          entry.id === customFieldParamValue ||
          entry.external_id === customFieldParamValue,
      );
      if (entry) {
        defaultValues.custom_field_entries[customField.id].values = [
          ...defaultValues.custom_field_entries[customField.id].values,
          entry?.id,
        ];
      }
    } else {
      // non catalog-backed
      const option = customField.options.find(
        (option) =>
          option.value === customFieldParamValue ||
          option.id === customFieldParamValue,
      );
      if (option) {
        defaultValues.custom_field_entries[customField.id].values = [
          ...defaultValues.custom_field_entries[customField.id].values,
          option?.id,
        ];
      }
    }
  }
};
