import { AlertSourceConfig } from "@incident-io/api";

export const hasSourcesAndRoutesCrossover = (
  alertSources: AlertSourceConfig[],
): boolean => {
  const sourceRouteMap = new Map<string, Array<string>>();

  alertSources.forEach((source) => {
    sourceRouteMap.set(source.id, source.alert_route_ids || []);
  });

  // Count if there are more than 3 sources going to 2 alert routes or more.
  let numberOfAlertSourcesGoingToMultipleAlertRoutes = 0;
  for (const [_, routes] of sourceRouteMap.entries()) {
    if (routes.length > 1) {
      numberOfAlertSourcesGoingToMultipleAlertRoutes++;
    }
  }

  return numberOfAlertSourcesGoingToMultipleAlertRoutes > 3;
};
