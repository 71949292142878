/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesforceAccountsView
 */
export interface SalesforceAccountsView {
    /**
     * id of listview
     * @type {string}
     * @memberof SalesforceAccountsView
     */
    id: string;
    /**
     * label of the accounts view list
     * @type {string}
     * @memberof SalesforceAccountsView
     */
    label: string;
}

export function SalesforceAccountsViewFromJSON(json: any): SalesforceAccountsView {
    return SalesforceAccountsViewFromJSONTyped(json, false);
}

export function SalesforceAccountsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesforceAccountsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
    };
}

export function SalesforceAccountsViewToJSON(value?: SalesforceAccountsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
    };
}

