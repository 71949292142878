/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface ConditionPayload
 */
export interface ConditionPayload {
    /**
     * The name of the operation on the subject
     * @type {string}
     * @memberof ConditionPayload
     */
    operation: string;
    /**
     * List of parameter bindings
     * @type {Array<EngineParamBindingPayload>}
     * @memberof ConditionPayload
     */
    param_bindings: Array<EngineParamBindingPayload>;
    /**
     * The reference of the subject in the trigger scope
     * @type {string}
     * @memberof ConditionPayload
     */
    subject: string;
}

export function ConditionPayloadFromJSON(json: any): ConditionPayload {
    return ConditionPayloadFromJSONTyped(json, false);
}

export function ConditionPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'param_bindings': ((json['param_bindings'] as Array<any>).map(EngineParamBindingPayloadFromJSON)),
        'subject': json['subject'],
    };
}

export function ConditionPayloadToJSON(value?: ConditionPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operation': value.operation,
        'param_bindings': ((value.param_bindings as Array<any>).map(EngineParamBindingPayloadToJSON)),
        'subject': value.subject,
    };
}

