import { CopiableText, Modal, ModalContent } from "@incident-ui";
import React from "react";
import { SavedView } from "src/contexts/ClientContext";

type Props = {
  savedView: SavedView;
  onClose: () => void;
};

export const SavedViewShareModal = ({
  savedView,
  onClose,
}: Props): React.ReactElement => {
  const currentFullURL = window.location.href;
  const url = new URL(currentFullURL);
  {
    const searchParam = new URLSearchParams();
    searchParam.set("view", savedView.id);
    url.search = searchParam.toString();
  }
  const href = url.toString();

  return (
    <Modal
      isOpen={true}
      title={`Share link to '${savedView.name}'`}
      analyticsTrackingId="saved-view-share-modal"
      onClose={onClose}
    >
      <ModalContent>
        Copy the URL for this view to share with another user in your
        organisation.
        <CopiableText value={href} className="mt-2" />
      </ModalContent>
    </Modal>
  );
};
