import cx from "classnames";
import React from "react";

import { assertUnreachable } from "../../helpers";

export const ChevronIcon = ({
  className,
  flavour,
}: {
  className?: string;
  flavour: "up" | "down" | "left" | "right";
}): React.ReactElement => {
  switch (flavour) {
    case "up":
      return (
        <svg
          className={cx("text-slate-300 dark:text-slate-500", className)}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5.01923L5.25 0.980765L1.5 5.01923"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "down":
      return (
        <svg
          className={cx("text-slate-300 dark:text-slate-500", className)}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 0.980774L5.25 5.01924L1.5 0.980774"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "left":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          width="6"
          height="10"
          className={cx("text-slate-300 dark:text-slate-500", className)}
        >
          <path
            d="M15.75 19.5L8.25 12l7.5-7.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "right":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className={cx("text-slate-300 dark:text-slate-500", className)}
        >
          <path
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      assertUnreachable(flavour);
      return <></>;
  }
};
