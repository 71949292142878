import { EmptyState, IconEnum, Loader, LoadingBar } from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import { take } from "lodash";
import { useLayoutEffect, useRef } from "react";
import {
  EscalationPreview,
  EscalationStatusEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { HomeHeading } from "../home/HomeHeading";
import { EscalationCard } from "./card/EscalationCard";

export const EscalationGrid = () => {
  const { identity } = useIdentity();

  const POLLING_INTERVAL_IN_SECONDS = identity?.organisation_is_demo ? 5 : 10;

  if (!identity) {
    return <Loader />;
  }

  return (
    <EscalationGridInner
      pollingIntervalInSeconds={POLLING_INTERVAL_IN_SECONDS}
    />
  );
};

const MAX_ESCALATIONS = 12;

const EscalationGridInner = ({
  pollingIntervalInSeconds,
}: {
  pollingIntervalInSeconds: number;
}) => {
  const {
    data: { escalations },
    isLoading,
  } = useAPI(
    "escalationsList",
    {
      pageSize: MAX_ESCALATIONS + 1,
      status: {
        one_of: [EscalationStatusEnum.Pending, EscalationStatusEnum.Triggered],
      },
    },
    {
      refreshInterval: pollingIntervalInSeconds * 1000,
      fallbackData: { escalations: [], pagination_meta: { page_size: 0 } },
    },
  );

  if (isLoading) {
    return <LoadingBar className="h-36 py-[240px] mx-4 md:mx-8" />;
  }

  const showingMax = escalations.length > MAX_ESCALATIONS;

  return (
    <div className="space-y-2 w-full">
      <HomeHeading
        title="Active escalations"
        resourceCount={
          isLoading ? undefined : { showing: escalations.length, showingMax }
        }
        seeAllLink={{
          title: "View all escalations",
          href: "/on-call/escalations",
          analyticsTrackingId: "internal-homepage-view-all-escalations",
        }}
      />

      <EscalationGridImpl
        escalations={take(escalations, MAX_ESCALATIONS)}
        loading={isLoading}
      />
    </div>
  );
};

export const EscalationGridImpl = ({
  escalations,
  loading,
}: {
  escalations: EscalationPreview[];
  loading: boolean;
}) => {
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (!loading && firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, [escalations, loading]);

  if (loading) {
    return <LoadingBar className="h-24 mx-8" />;
  }

  return (
    <AnimatePresence presenceAffectsLayout>
      {escalations.length === 0 ? (
        <div className="px-4 md:px-8">
          <EmptyState
            icon={IconEnum.Escalate}
            title="No active escalations"
            content={
              <>
                Rest easy, there are currently no active escalations &mdash;
                when there are, they&rsquo;ll appear here.
              </>
            }
          />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 items-stretch auto-rows-fr px-4 md:px-8">
          {escalations.map((escalation) => (
            <EscalationCard
              key={escalation.id}
              escalation={escalation}
              isGridFirstUpdate={firstUpdate.current}
            />
          ))}
        </div>
      )}
    </AnimatePresence>
  );
};
