import {
  AvailableIncidentFormLifecycleElement,
  AvailableIncidentFormLifecycleElementElementTypeEnum as ElementTypeEnum,
  Condition,
  ConditionGroup,
  EngineScope,
  IncidentFormLifecycleElementRequiredIfEnum as RequiredIfEnum,
  IncidentType,
  Resource,
} from "@incident-io/api";
import { EngineFormElement, EngineModeProps } from "@incident-shared/engine";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { ContentBox, OrgAwareLink, Txt } from "@incident-ui";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { IncidentFormElementFormData } from "./lifecycle/IncidentLifecycleFormElementCreateEditModal";

export const ShowIfFormSection = ({
  scope,
  fixedConditions,
  availableElement,
}: {
  scope: EngineScope;
  fixedConditions: ConditionGroup[];
  availableElement: AvailableIncidentFormLifecycleElement;
}): React.ReactElement | null => {
  const formMethods = useFormContext<IncidentFormElementFormData>();

  if (availableElement.element_type === ElementTypeEnum.Divider) {
    return <></>;
  }

  if (!availableElement.can_be_hidden) {
    return (
      <div>
        <Form.Label htmlFor="show_if">Show this field</Form.Label>
        <Txt className="!text-slate-400">This field cannot be hidden</Txt>
      </div>
    );
  }

  let hasFixedConditionsContent: React.ReactNode | undefined = undefined;
  switch (availableElement.element_type) {
    case ElementTypeEnum.IncidentRole:
      hasFixedConditionsContent = (
        <>
          This condition is set on the incident role. You can edit this in{" "}
          <OrgAwareLink
            className="underline"
            target="_blank"
            to={`/settings/incident-roles/${availableElement.incident_role?.id}/edit`}
          >
            Settings → Incident roles
          </OrgAwareLink>
        </>
      );
      break;
    case ElementTypeEnum.CustomField:
      hasFixedConditionsContent = (
        <>
          This condition is set on the custom field. You can edit this in{" "}
          <OrgAwareLink
            className="underline"
            target="_blank"
            to={`/settings/custom-fields/${availableElement.custom_field?.id}/edit`}
          >
            Settings → Custom fields
          </OrgAwareLink>
        </>
      );
      break;
  }

  return (
    <RadioButtonGroupV2
      formMethods={formMethods}
      name="show_if"
      srLabel="show field"
      label="Show this field"
      boxed
      options={[
        {
          label: "Always",
          value: "always",
          isDisabled: fixedConditions.length > 0,
        },
        {
          label: "When the incident matches conditions",
          value: "sometimes",
          renderWhenSelectedNode: () => (
            <ConditionGroupsEditorV2
              fixedConditionGroups={fixedConditions}
              fixedConditionExplainText={hasFixedConditionsContent}
              formMethods={formMethods}
              name={"show_if_condition_groups"}
              conditionLabel={"condition"}
              allowFilteringByExpression
              scope={scope}
              hideIntroSentence
              rules={{
                validate: (conditions: Condition[], data) =>
                  conditions?.length === 0 &&
                  data.show_if === "sometimes" &&
                  fixedConditions.length === 0
                    ? "Please add at least one condition"
                    : undefined,
              }}
            />
          ),
        },
      ]}
    />
  );
};

export const RequiredIfFormSection = ({
  availableElement,
  scope,
}: {
  availableElement: AvailableIncidentFormLifecycleElement;
  scope: EngineScope;
}): React.ReactElement | null => {
  const formMethods = useFormContext<IncidentFormElementFormData>();

  const requiredIf = formMethods.watch("required_if");

  if (
    availableElement.element_type === ElementTypeEnum.Divider ||
    availableElement.element_type === ElementTypeEnum.Text
  ) {
    return <></>;
  }

  if (!availableElement.can_configure_required_if) {
    return (
      <div>
        <Form.Label htmlFor="required_if">Require this field</Form.Label>
        <Txt className="!text-slate-400">
          {requiredIf === RequiredIfEnum.AlwaysRequire
            ? "This field must always be required"
            : "This field must never be required"}
        </Txt>
      </div>
    );
  }

  return (
    <div>
      <RadioButtonGroupV2
        formMethods={formMethods}
        name="required_if"
        srLabel="required field"
        label="Require this field"
        boxed
        options={[
          {
            label: "Never",
            value: RequiredIfEnum.NeverRequire,
          },
          {
            label: "Always",
            value: RequiredIfEnum.AlwaysRequire,
          },

          {
            label: "When the incident matches conditions",
            value: RequiredIfEnum.CheckEngineConfig,
            renderWhenSelectedNode: () => (
              <ConditionGroupsEditorV2
                allowFilteringByExpression
                formMethods={formMethods}
                name={"required_if_condition_groups"}
                conditionLabel={"condition"}
                scope={scope}
                hideIntroSentence
                rules={{
                  validate: (conditions: Condition[], data) =>
                    conditions?.length === 0 &&
                    data.required_if === RequiredIfEnum.CheckEngineConfig
                      ? "Please add at least one condition"
                      : undefined,
                }}
              />
            ),
          },
        ]}
      />
      <CanChooseNoValueSection availableElement={availableElement} />
    </div>
  );
};

export const CanChooseNoValueSection = ({
  availableElement,
}: {
  availableElement: AvailableIncidentFormLifecycleElement;
}): React.ReactElement | null => {
  const formMethods = useFormContext<IncidentFormElementFormData>();

  const requiredIf = formMethods.watch("required_if");

  if (!availableElement.can_choose_select_no_value) {
    return null;
  }

  if (requiredIf === RequiredIfEnum.NeverRequire) {
    // This option doesn't make sense if the field is never required
    return null;
  }

  return (
    // Hack so that the grey 'drawer' goes up above where the rounded bottom of the
    // radio buttons starts
    <div className="-mt-5 pt-10 shadow-inner bg-surface-secondary p-4 rounded-b-lg">
      <div className="flex flex-col gap-2">
        <Form.Label htmlFor="can_select_no_value">
          {`Include a 'no value' option in the dropdown`}
        </Form.Label>
        <ContentBox className="flex gap-4 p-4 items-center">
          <Txt grey>
            {`Use this to ensure that a user makes an active choice to input 'no value' instead of just forgetting to fill out the field.`}
          </Txt>
          <ToggleV2 formMethods={formMethods} name="can_select_no_value" />
        </ContentBox>
      </div>
    </div>
  );
};

export const DefaultValueFormSection = ({
  availableElement,
  resources,
  incidentType,
  scope,
}: {
  availableElement: AvailableIncidentFormLifecycleElement;
  resources: Resource[];
  incidentType?: IncidentType;
  scope: EngineScope;
}): React.ReactElement | null => {
  switch (availableElement.element_type) {
    case ElementTypeEnum.IncidentType:
      return (
        <div>
          <Form.Label htmlFor="default">Default value</Form.Label>
          <Txt className="!text-slate-400">
            The default incident type can be set in{" "}
            <OrgAwareLink
              className="underline"
              target="_blank"
              to={`/settings/incident-types`}
            >
              Settings → Incident types
            </OrgAwareLink>
          </Txt>
        </div>
      );
    case ElementTypeEnum.Visibility:
      if (incidentType?.private_incidents_only) {
        return (
          <div>
            <Form.Label htmlFor="default">Default value</Form.Label>
            <Txt className="!text-slate-400">
              The incident type will always be private. This setting can be
              changed in{" "}
              <OrgAwareLink
                className="underline"
                target="_blank"
                to={`/settings/incident-types/${incidentType.id}/edit`}
              >
                Settings → Incident types
              </OrgAwareLink>
            </Txt>
          </div>
        );
      }
      break;
  }

  if (!availableElement.can_have_default) {
    return null;
  }

  const modeProps: EngineModeProps =
    scope.references.length === 0
      ? { mode: "plain_input" }
      : { mode: "variables_and_expressions", scope };

  return (
    <EngineFormElement
      name="default_value"
      label="Default value"
      resources={resources}
      {...modeProps}
      array={availableElement.engine_resource_array}
      resourceType={availableElement.engine_resource_type}
      required={false}
      showPlaceholder
      expressionLabelOverride={`Default ${availableElement.label}`}
    />
  );
};

export const PlaceholderFormSection = ({
  availableElement,
  formMethods,
}: {
  availableElement: AvailableIncidentFormLifecycleElement;
  formMethods: UseFormReturn<IncidentFormElementFormData>;
}): React.ReactElement | null => {
  if (!availableElement.can_have_placeholder) {
    return null;
  }

  const bigTextElement = [
    ElementTypeEnum.Summary || ElementTypeEnum.UpdateMessage,
  ].includes(availableElement.element_type);

  if (bigTextElement) {
    return (
      <TextareaV2
        formMethods={formMethods}
        name="placeholder"
        label="Placeholder"
        placeholder="This is what the placeholder will look like"
        required={false}
      />
    );
  }

  return (
    <InputV2
      formMethods={formMethods}
      name="placeholder"
      label="Placeholder"
      placeholder="This is what the placeholder will look like"
      required={false}
    />
  );
};

export const DescriptionFormSection = ({
  availableElement,
  formMethods,
}: {
  availableElement: AvailableIncidentFormLifecycleElement;
  formMethods: UseFormReturn<IncidentFormElementFormData>;
}): React.ReactElement | null => {
  // These elements have descriptions that can be set in other sections of the
  // settings page
  switch (availableElement.element_type) {
    case ElementTypeEnum.IncidentRole:
      return (
        <div>
          <Form.Label htmlFor="description">Description</Form.Label>
          <Txt className="!text-slate-400">
            A description can be set on the incident role. You can edit this in{" "}
            <OrgAwareLink
              className="underline"
              target="_blank"
              to={`/settings/incident-roles/${availableElement.incident_role?.id}/edit`}
            >
              Settings → Incident roles
            </OrgAwareLink>
          </Txt>
        </div>
      );
    case ElementTypeEnum.CustomField:
      return (
        <div>
          <Form.Label htmlFor="description">Description</Form.Label>
          <Txt className="!text-slate-400">
            A description can be set on the custom field. You can edit this in{" "}
            <OrgAwareLink
              className="underline"
              target="_blank"
              to={`/settings/custom-fields/${availableElement.custom_field?.id}/edit`}
            >
              Settings → Custom fields
            </OrgAwareLink>
          </Txt>
        </div>
      );
    case ElementTypeEnum.Timestamp:
      return (
        <div>
          <Form.Label htmlFor="description">Description</Form.Label>
          <Txt className="!text-slate-400">
            A description can be set on the timestamp. You can edit this in{" "}
            <OrgAwareLink
              className="underline"
              target="_blank"
              to="/settings/lifecycle?=tab=timestamps"
            >
              Settings → Lifecycle → Timestamps and metrics
            </OrgAwareLink>
          </Txt>
        </div>
      );
    case ElementTypeEnum.Severity:
      return (
        <div>
          <Form.Label htmlFor="description">Description</Form.Label>
          <Txt className="!text-slate-400">
            A description can be set on each severity. You can edit this in{" "}
            <OrgAwareLink
              className="underline"
              target="_blank"
              to={`/settings/severities`}
            >
              Settings → Severities
            </OrgAwareLink>
          </Txt>
        </div>
      );
    case ElementTypeEnum.IncidentType:
      return (
        <div>
          <Form.Label htmlFor="description">Description</Form.Label>
          <Txt className="!text-slate-400">
            A description can be set on each incident type. You can edit this in{" "}
            <OrgAwareLink
              className="underline"
              target="_blank"
              to={`/settings/incident-types`}
            >
              Settings → Incident types
            </OrgAwareLink>
          </Txt>
        </div>
      );
  }

  if (!availableElement.can_have_description) {
    return null;
  }

  if (availableElement.element_type === ElementTypeEnum.Text) {
    return (
      <TemplatedTextInputV2
        formMethods={formMethods}
        name="description"
        label="Text"
        helptext="Write additional text here and it will be displayed in the form."
        placeholder="Enter a brief message"
        format="mrkdwn"
        includeVariables={false}
        includeExpressions={false}
        multiLine
      />
    );
  }

  return (
    <TemplatedTextInputV2
      formMethods={formMethods}
      name="description"
      label="Description"
      helptext="This will be displayed to help a user understand what this field means."
      placeholder="Enter a brief description"
      format="mrkdwn"
      includeVariables={false}
      includeExpressions={false}
      multiLine
    />
  );
};
