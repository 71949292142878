import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StatusPageIncident,
  StatusPageSetIncidentNameRequestBody,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const IncidentTitleEditModal = ({
  onClose,
  incident,
}: {
  onClose: () => void;
  incident: StatusPageIncident;
}) => {
  const formMethods = useForm<StatusPageSetIncidentNameRequestBody>({
    defaultValues: { name: incident.name },
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient, data: StatusPageSetIncidentNameRequestBody) =>
      await apiClient.statusPageSetIncidentName({
        id: incident.id,
        setIncidentNameRequestBody: {
          name: data.name,
        },
      }),
    {
      onSuccess: onClose,
      setError,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      disableQuickClose={false}
      genericError={genericError}
      title={`Edit incident name`}
      analyticsTrackingId="edit-status-page-incident-name"
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          disabled={!isDirty}
          confirmButtonType="submit"
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        helptext={
          "This is the public name of your incident that we'll display on your status page. Make sure it's understandable for anyone visiting."
        }
      />
    </Form.Modal>
  );
};
