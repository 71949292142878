import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import React from "react";

import { WorkflowsActivityDrawer } from "../../activity/WorkflowsActivityDrawer";
import { WorkflowsAdvancedSettingsDrawer } from "../../advanced-settings/WorkflowsAdvancedSettingsDrawer";
import { WorkflowAddStepForm } from "../../steps/WorkflowAddStepForm";
import { WorkflowEditStepForm } from "../../steps/WorkflowEditStepForm";
import { WorkflowChooseTriggerDrawer } from "../../triggers/WorkflowChooseTriggerForm";
import {
  useWorkflowsViewState,
  WorkflowDrawerState as WorkflowDrawerState,
} from "../hooks/useViewController";
import { useWorkflowsDeps } from "../WorkflowsFormContext";
import { WorkflowsCopyToTerraformDrawer } from "./WorkflowsCopyToTerraform";

export const WorkflowsCreateEditDrawers = ({
  onClose,
}: {
  onClose: (opts?: { forceClose: boolean }) => void;
}): React.ReactElement => {
  const { workflow } = useWorkflowsDeps();

  const {
    onOpenDrawer: onOpenDrawer,
    drawerState: drawerState,
    hasOpenDrawer,
  } = useWorkflowsViewState();

  return (
    <>
      {!hasOpenDrawer && (
        <Button
          theme={ButtonTheme.Secondary}
          onClick={() => onOpenDrawer(WorkflowDrawerState.AdvancedSettings)}
          analyticsTrackingId={"workflows-v2-advanced-settings-button"}
          icon={IconEnum.Cog}
          className="absolute right-0 top-0"
        >
          Advanced settings
        </Button>
      )}
      <AnimatePresence>
        {![
          WorkflowDrawerState.ExportToTerraform,
          WorkflowDrawerState.None,
        ].includes(drawerState) && (
          <Drawer width="small" onClose={onClose}>
            <DrawerContents className="overflow-hidden">
              {
                {
                  // Temporary solution to render the right drawer content, for the time
                  // being switching on the content rather than on the whole drawer, but
                  // this can be refactored later if we choose to do so.
                  [WorkflowDrawerState.ChooseTrigger]: (
                    <WorkflowChooseTriggerDrawer onClose={onClose} />
                  ),
                  [WorkflowDrawerState.AddStep]: (
                    <WorkflowAddStepForm onClose={onClose} />
                  ),
                  [WorkflowDrawerState.EditStep]: (
                    <WorkflowEditStepForm onClose={onClose} />
                  ),
                  [WorkflowDrawerState.ViewActivity]: (
                    <>
                      {!!workflow && (
                        <WorkflowsActivityDrawer
                          onClose={onClose}
                          workflow={workflow}
                        />
                      )}
                    </>
                  ),
                  [WorkflowDrawerState.AdvancedSettings]: (
                    <>
                      <DrawerTitle
                        title="Advanced settings"
                        onClose={onClose}
                        icon={IconEnum.Cog}
                        compact
                      />
                      <WorkflowsAdvancedSettingsDrawer />
                    </>
                  ),
                  [WorkflowDrawerState.None]: null,
                }[drawerState]
              }
            </DrawerContents>
          </Drawer>
        )}
      </AnimatePresence>
      {/* The terraform drawer isn't rendered in page, so we have to handle it separately */}
      <WorkflowsCopyToTerraformDrawer
        onClose={onClose}
        isOpen={drawerState === WorkflowDrawerState.ExportToTerraform}
      />
    </>
  );
};
