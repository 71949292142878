import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Link,
  Loader,
} from "@incident-ui";
import { useParams } from "react-router-dom";
import {
  CatalogShowTypeResponseBody,
  IntegrationSettingsProviderEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { CatalogTypeCreateEditFormWrapper } from "../catalog-type-form/CatalogTypeCreateEditFormWrapper";

export const CatalogTypeEditPage = () => {
  const { id } = useParams() as { id: string };

  const {
    data: typeData,
    isLoading: typeLoading,
    error: typeError,
  } = useAPI("catalogShowType", {
    id: id,
    includeAttributeDependentResources: true,
  });

  // Catalog resources are used to type our attributes. To avoid a flash of the form,
  // we load these here before mounting the page.
  const { isLoading: resourcesLoading, error: resourcesError } = useAPI(
    "catalogListResources",
    undefined,
  );

  if (!typeData || typeLoading || resourcesLoading) {
    return <Loader />;
  }

  if (resourcesError || typeError) {
    return <GenericErrorMessage error={resourcesError || typeError} />;
  }

  return (
    <PageWrapper
      width={PageWidth.Narrow}
      icon={typeData.catalog_type.icon}
      color={typeData.catalog_type.color as unknown as ColorPaletteEnum}
      title={`Edit ${typeData.catalog_type.name}`}
      backHref={`/catalog/${slugForCatalogType(typeData.catalog_type)}`}
      crumbs={[{ title: "Catalog", to: "/catalog" }]}
    >
      <CatalogTypeEditPageInner catalogTypeResponse={typeData} />
    </PageWrapper>
  );
};

// We need to only call useForm once we've loaded the catalog type, so that we can set
// the default values correctly.
const CatalogTypeEditPageInner = ({
  catalogTypeResponse,
}: {
  catalogTypeResponse: CatalogShowTypeResponseBody;
}) => {
  const catalogType = catalogTypeResponse.catalog_type;
  const requiredIntegration = IntegrationConfigFor(
    catalogType.required_integration as unknown as IntegrationSettingsProviderEnum,
  );

  const isFromCatalogImporter =
    "incident.io/catalog-importer/last-sync-at" in catalogType;

  return (
    <>
      {isFromCatalogImporter && (
        <Callout theme={CalloutTheme.Warning} className="mb-4">
          This type was last synced using the{" "}
          <Link
            href="https://github.com/incident-io/catalog-importer"
            analyticsTrackingId={null}
            openInNewTab
          >
            catalog-importer
          </Link>
          . When it&apos;s synced again, you might lose any changes made here.
        </Callout>
      )}
      <CatalogTypeCreateEditFormWrapper
        mode={Mode.Edit}
        initialData={catalogType}
        requiredIntegration={requiredIntegration}
        supportsDynamicAttributes={
          catalogTypeResponse.supports_dynamic_attributes
        }
        dependentResources={catalogTypeResponse.dependent_resources}
        catalogTypeMode={catalogType.mode}
        attributeDependencies={
          catalogTypeResponse.attribute_dependent_resources
        }
      />
    </>
  );
};
