import { InsightsFilter } from "@incident-io/api";
import {
  AvailableFilter,
  ExtendedFormFieldValue,
  filterToQueryKeyValues,
  queryParamsToFilters,
} from "@incident-shared/filters";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { useSavedViews } from "src/components/saved-views/SavedViewContext";
import { useSafeUpdateQueryString } from "src/utils/query-params";

import { insightsFilterToFormFieldValue } from "../../insights/v3/dashboards/common/marshall";

export interface GetSelectedQueryOrViewParamOptions<Key, Value> {
  defaultValue: Value;
  param: Key; // Key that will be used in URL param e.g.: `/?key=value`
  isValid: (value: string | null) => boolean;
}

export interface GetSelectedQueryArrayOrViewParamOptions<Key> {
  key: Key; // Key that will be used in URL param e.g.: `/?key=value`
  isValid: (value: string) => boolean;
}

export const getQueryParamFactory =
  <OptionsType extends { [key: string]: string }>({
    queryParams,
    viewParams,
  }: {
    queryParams: URLSearchParams;
    viewParams: URLSearchParams;
  }) =>
  <T extends keyof OptionsType & string>({
    param,
    isValid,
    defaultValue,
  }: GetSelectedQueryOrViewParamOptions<T, OptionsType[T]>) => {
    const queryVal = queryParams.get(param);
    const viewQueryVal = viewParams.get(param);

    if (isValid(queryVal)) {
      return queryVal as OptionsType[T];
    }
    if (isValid(viewQueryVal)) {
      return viewQueryVal as OptionsType[T];
    }
    return defaultValue;
  };

export const getQueryParamArrayFactory =
  ({
    queryParams,
    viewParams,
  }: {
    queryParams: URLSearchParams;
    viewParams: URLSearchParams;
  }) =>
  (key: string, isValid: (value: string) => boolean): string[] => {
    const viewParamsWithOverrides = new URLSearchParams();
    for (const [key, value] of viewParams.entries()) {
      if (!queryParams.getAll(`-${key}`).includes(value)) {
        viewParamsWithOverrides.append(key, value);
      }
    }
    return new URLSearchParams([...queryParams, ...viewParamsWithOverrides])
      .getAll(key)
      .filter(isValid);
  };

// Note to future modifier, this probably needs a rethink as its getting quite difficult to make this extendable
// ideal refactor would be something along the lines of:
// `SavedViewsStateProvider<T>` where you provide
//  1. the shape of the state (T)
//  2. transformation between URLSearchParams and state (both ways)
//  3. responsible for updating the url params (negate logic as well which currently lives here)
// `useSavedViewsState<T>` where you can retrieve the state and the setter
export const useStatefulQueryParamFilters = <
  OptionsType extends { [key: string]: string },
>({
  availableFilterFields,
  availableParams,
  defaultFilters,
}: {
  availableFilterFields: AvailableFilter[];
  availableParams: string[];
  defaultFilters?: InsightsFilter[];
}): {
  setSelectedFilters: (newFilters: ExtendedFormFieldValue[]) => void;
  getSelectedFilters: () => ExtendedFormFieldValue[];
  useQueryParam: <T extends keyof OptionsType & string>(
    options: GetSelectedQueryOrViewParamOptions<T, OptionsType[T]>,
  ) => [OptionsType[T], (value: OptionsType[T]) => void];
  useQueryParamArray: (
    options: GetSelectedQueryArrayOrViewParamOptions<string>,
  ) => [string[], (values: string[]) => void];
} => {
  const { selectedSavedView } = useSavedViews();
  // TODO: we should avoid setting the url params string directly.
  // instead we should use _setQueryParam to mutate the URLSearchParams object
  // This will avoid quick successive calls to setQueryParam (from useQueryParam)
  // to override each other
  const setURLParams = useSafeUpdateQueryString();
  const [queryParams, _setQueryParam] = useSearchParams();
  const viewParams = new URLSearchParams(selectedSavedView?.url_params ?? "");

  // getSelectedFilters uses the current query parameters + selected view (if applicable)
  // and finds all the currently selected filters.
  //
  // If a user is editing a saved view, they may 'delete' a filter from the view,
  // to denote this in query parameters, we prefix the query key with `-` e.g. `-severity={severityId}`
  // If there's a query with `-` prefixed, we _don't_ add it to the in use filters, as the user has chosen to remove it
  const getSelectedFilters = (): ExtendedFormFieldValue[] => {
    const viewParamsWithOverrides = new URLSearchParams();
    for (const [key, value] of viewParams.entries()) {
      if (!queryParams.getAll(`-${key}`).includes(value)) {
        viewParamsWithOverrides.append(key, value);
      }
    }

    const viewAndQuerySpecifiedFilters = queryParamsToFilters(
      availableFilterFields || [],
      new URLSearchParams([
        ...queryParams,
        ...viewParamsWithOverrides,
      ]).toString(),
    );

    const applicableDefaultFilters = (
      defaultFilters?.map(insightsFilterToFormFieldValue) ?? []
    ).filter((f) => {
      return !viewAndQuerySpecifiedFilters.some(
        (v) => f.field_key === v.field_key,
      );
    });

    return [...viewAndQuerySpecifiedFilters, ...applicableDefaultFilters];
  };

  // setSelectedFilters takes a list of filters and updates the query parameters
  // If it sees the user has removed a param from a saved view, it prefixes it with `-`, to denote
  // that it shouldn't be used anymore.
  const setSelectedFilters = (newFilters: ExtendedFormFieldValue[]) => {
    const newParams = new URLSearchParams();

    // First, copy over any non-filter query params, we don't want to change them
    const staticParams = Object.values([
      ...availableParams,
      "view",
      "display_info",
    ]);
    queryParams.forEach((value, key) => {
      if (staticParams.includes(key)) {
        newParams.append(key, value);
      }
    });

    const appliedNewFilters = newFilters
      // Don't store anything that's just a default filter in query params
      // it'll get supplied in "getFilters" anyway
      .filter((filter) =>
        defaultFilters
          ? !defaultFilters
              .map(insightsFilterToFormFieldValue)
              .some((defaultFilter) => _.isEqual(defaultFilter, filter))
          : true,
      )
      // And just incase, don't let users set query params for anything
      // that shouldn't be editable
      .filter((filter) => !filter.is_disabled);

    // Then go through the new filters and add them to the params
    for (const filter of appliedNewFilters) {
      const keyValues = filterToQueryKeyValues(filter);
      for (const { key, value } of keyValues) {
        // Only set these if they're _not_ part of the view -
        // we don't show all the params of a view, only ones that have been overridden
        const viewParamValues = viewParams.getAll(key);
        if (!viewParamValues.includes(value)) {
          newParams.append(key, value);
        }
      }
    }

    // If there are filters in the view, that aren't in the params, then prefix them with `-` to mark them as 'to-be-removed'
    for (const [key, value] of viewParams.entries()) {
      if (staticParams.includes(key)) {
        // static params should not be removed
        continue;
      }
      const filterValues = _.flatMap(
        appliedNewFilters,
        filterToQueryKeyValues,
      ).filter(({ key: filterKey }) => filterKey === key);

      const viewParamIsBeingRemoved = !filterValues.some(
        ({ key: filterKey, value: filterValue }) =>
          key === filterKey && value === filterValue,
      );
      if (viewParamIsBeingRemoved) {
        newParams.append(`-${key}`, value);
      }
    }

    setURLParams(newParams.toString());
  };

  // useQueryParam gives you the current value of a param and a setter for it.
  //
  // Using this method allows for the setter to know what the default value of
  // the parameter is, meaning that if the parameter is set to the default value
  // it will be excluded from the URL.
  const useQueryParam = <T extends keyof OptionsType & string>(
    options: GetSelectedQueryOrViewParamOptions<T, OptionsType[T]>,
  ): [OptionsType[T], (value: OptionsType[T]) => void] => {
    const getQueryParam = getQueryParamFactory<OptionsType>({
      queryParams,
      viewParams,
    })(options);

    const setQueryParam = (key: T, value: OptionsType[T]) =>
      _setQueryParam((prev) => {
        const existingParamValue = viewParams.get(key);
        if (existingParamValue === value) {
          prev.delete(`-${key}`);
          prev.delete(key);
          return prev;
        } else {
          if (existingParamValue) {
            prev.set(`-${key}`, existingParamValue);

            if (value === options.defaultValue) {
              prev.set(key, value);
            }
          } else {
            prev.delete(key);
          }

          if (options.defaultValue !== value) {
            prev.set(key, value);
          }
          return prev;
        }
      });

    return [
      getQueryParam,
      (value: OptionsType[T]) => setQueryParam(options.param, value),
    ];
  };

  // useQueryParamArray gives you the current value of a param array and a setter
  // for it
  //
  // Has the same logic as the old getQueryParamArray and setQueryParamArray but
  // will allow for easier extensibility if someone wants to add logic for
  // default values for parameter arrays
  const useQueryParamArray = (
    options: GetSelectedQueryArrayOrViewParamOptions<string>,
  ): [string[], (values: string[]) => void] => {
    const getQueryParamArray = getQueryParamArrayFactory({
      queryParams,
      viewParams,
    })(options.key, options.isValid);

    const setQueryParamArray = (key: string, values: string[]) => {
      // TODO: consolidate this logic with filters `setSelectedFilters`
      const newParams = new URLSearchParams();

      // copy over all other params
      queryParams.forEach((urlQueryParamValue, urlQueryParamKey) => {
        if (urlQueryParamKey !== key && urlQueryParamKey !== `-${key}`) {
          newParams.append(urlQueryParamKey, urlQueryParamValue);
        }
      });

      // add new values if they dont exist in saved view
      const viewParamValues = viewParams.getAll(key);
      for (const newValue of values) {
        if (!viewParamValues.includes(newValue)) {
          newParams.append(key, newValue);
        }
      }

      // if value exist in view but aren't in the params, then prefix them with `-` to mark them as 'to-be-removed'
      for (const viewParamValue of viewParamValues) {
        const viewParamIsBeingRemoved = !values.some(
          (newValue) => viewParamValue === newValue,
        );
        if (viewParamIsBeingRemoved) {
          newParams.append(`-${key}`, viewParamValue);
        }
      }

      setURLParams(newParams.toString());
    };

    return [
      getQueryParamArray,
      (values: string[]) => setQueryParamArray(options.key, values),
    ];
  };

  return {
    getSelectedFilters,
    setSelectedFilters,
    useQueryParam,
    useQueryParamArray,
  };
};

export const isValidForEnum =
  <T extends string>(options: T[]) =>
  (value: string | null) =>
    value != null && options.includes(value as T);
