import { CallProvidersProvidersEnum } from "@incident-io/api";
import { useLocalStorage } from "use-hooks";

import { useAPI } from "../../utils/swr";

const richerCallsLocalStorageKey = "richerCallsBannerDismissed";

export const useShowRicherCallsBanner = () => {
  const [isDismissed, setDismissed] = useLocalStorage(
    richerCallsLocalStorageKey,
    false,
  );
  const { data, isLoading } = useAPI(
    "incidentCallSettingsGetCallProviders",
    {},
  );

  // For now, we're only showing this for Google Meet
  const isGoogleMeetInstalled = data?.available_providers?.providers?.some(
    (provider) => provider === CallProvidersProvidersEnum.GoogleMeet,
  );
  const isGoogleMeetWithEventsEnabled =
    data?.available_providers_with_events_enabled?.providers?.some(
      (provider) => provider === CallProvidersProvidersEnum.GoogleMeet,
    );

  // Show the banner if Google Meeting is installed but not enabled for events
  const showBanner = isGoogleMeetInstalled && !isGoogleMeetWithEventsEnabled;

  return {
    showRicherCallsBanner: !isDismissed && showBanner && !isLoading,
    dismissRicherCallsBanner: () => setDismissed(true),
  };
};
