/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EngineBuildScopeRequestBody,
    EngineBuildScopeRequestBodyFromJSON,
    EngineBuildScopeRequestBodyToJSON,
    EngineBuildScopeResponseBody,
    EngineBuildScopeResponseBodyFromJSON,
    EngineBuildScopeResponseBodyToJSON,
    EngineEvaluateExpressionRequestBody,
    EngineEvaluateExpressionRequestBodyFromJSON,
    EngineEvaluateExpressionRequestBodyToJSON,
    EngineEvaluateExpressionResponseBody,
    EngineEvaluateExpressionResponseBodyFromJSON,
    EngineEvaluateExpressionResponseBodyToJSON,
    EngineEvaluateJavascriptRequestBody,
    EngineEvaluateJavascriptRequestBodyFromJSON,
    EngineEvaluateJavascriptRequestBodyToJSON,
    EngineEvaluateJavascriptResponseBody,
    EngineEvaluateJavascriptResponseBodyFromJSON,
    EngineEvaluateJavascriptResponseBodyToJSON,
    EngineFindDependentResourcesForMultipleRequestBody,
    EngineFindDependentResourcesForMultipleRequestBodyFromJSON,
    EngineFindDependentResourcesForMultipleRequestBodyToJSON,
    EngineFindDependentResourcesForMultipleResponseBody,
    EngineFindDependentResourcesForMultipleResponseBodyFromJSON,
    EngineFindDependentResourcesForMultipleResponseBodyToJSON,
    EngineListAllResourcesResponseBody,
    EngineListAllResourcesResponseBodyFromJSON,
    EngineListAllResourcesResponseBodyToJSON,
    EngineListResourcesRequestBody,
    EngineListResourcesRequestBodyFromJSON,
    EngineListResourcesRequestBodyToJSON,
    EngineListResourcesResponseBody,
    EngineListResourcesResponseBodyFromJSON,
    EngineListResourcesResponseBodyToJSON,
    EngineTypeaheadResponseBody,
    EngineTypeaheadResponseBodyFromJSON,
    EngineTypeaheadResponseBodyToJSON,
} from '../models';

export interface EngineBuildScopeRequest {
    buildScopeRequestBody: EngineBuildScopeRequestBody;
}

export interface EngineEvaluateExpressionRequest {
    evaluateExpressionRequestBody: EngineEvaluateExpressionRequestBody;
}

export interface EngineEvaluateJavascriptRequest {
    evaluateJavascriptRequestBody: EngineEvaluateJavascriptRequestBody;
}

export interface EngineFindDependentResourcesForMultipleRequest {
    findDependentResourcesForMultipleRequestBody: EngineFindDependentResourcesForMultipleRequestBody;
}

export interface EngineListAllResourcesRequest {
    enumerable?: boolean;
}

export interface EngineListResourcesRequest {
    listResourcesRequestBody: EngineListResourcesRequestBody;
}

export interface EngineTypeaheadRequest {
    resource: string;
    query?: string;
}

/**
 * 
 */
export class EngineApi extends runtime.BaseAPI {

    /**
     * Build a scope, expanding root-level references into their child references
     * BuildScope Engine
     */
    async engineBuildScopeRaw(requestParameters: EngineBuildScopeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EngineBuildScopeResponseBody>> {
        if (requestParameters.buildScopeRequestBody === null || requestParameters.buildScopeRequestBody === undefined) {
            throw new runtime.RequiredError('buildScopeRequestBody','Required parameter requestParameters.buildScopeRequestBody was null or undefined when calling engineBuildScope.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/engine/scope`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EngineBuildScopeRequestBodyToJSON(requestParameters.buildScopeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngineBuildScopeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build a scope, expanding root-level references into their child references
     * BuildScope Engine
     */
    async engineBuildScope(requestParameters: EngineBuildScopeRequest, initOverrides?: RequestInit): Promise<EngineBuildScopeResponseBody> {
        const response = await this.engineBuildScopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Evaluate an expression, returning results for each stage
     * EvaluateExpression Engine
     */
    async engineEvaluateExpressionRaw(requestParameters: EngineEvaluateExpressionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EngineEvaluateExpressionResponseBody>> {
        if (requestParameters.evaluateExpressionRequestBody === null || requestParameters.evaluateExpressionRequestBody === undefined) {
            throw new runtime.RequiredError('evaluateExpressionRequestBody','Required parameter requestParameters.evaluateExpressionRequestBody was null or undefined when calling engineEvaluateExpression.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/engine/evaluate_expression`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EngineEvaluateExpressionRequestBodyToJSON(requestParameters.evaluateExpressionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngineEvaluateExpressionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Evaluate an expression, returning results for each stage
     * EvaluateExpression Engine
     */
    async engineEvaluateExpression(requestParameters: EngineEvaluateExpressionRequest, initOverrides?: RequestInit): Promise<EngineEvaluateExpressionResponseBody> {
        const response = await this.engineEvaluateExpressionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Evaluate a Javascript expression as the Parse operation would
     * EvaluateJavascript Engine
     */
    async engineEvaluateJavascriptRaw(requestParameters: EngineEvaluateJavascriptRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EngineEvaluateJavascriptResponseBody>> {
        if (requestParameters.evaluateJavascriptRequestBody === null || requestParameters.evaluateJavascriptRequestBody === undefined) {
            throw new runtime.RequiredError('evaluateJavascriptRequestBody','Required parameter requestParameters.evaluateJavascriptRequestBody was null or undefined when calling engineEvaluateJavascript.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/engine/evaluate_javascript`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EngineEvaluateJavascriptRequestBodyToJSON(requestParameters.evaluateJavascriptRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngineEvaluateJavascriptResponseBodyFromJSON(jsonValue));
    }

    /**
     * Evaluate a Javascript expression as the Parse operation would
     * EvaluateJavascript Engine
     */
    async engineEvaluateJavascript(requestParameters: EngineEvaluateJavascriptRequest, initOverrides?: RequestInit): Promise<EngineEvaluateJavascriptResponseBody> {
        const response = await this.engineEvaluateJavascriptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find dependents of the specified resource
     * FindDependentResourcesForMultiple Engine
     */
    async engineFindDependentResourcesForMultipleRaw(requestParameters: EngineFindDependentResourcesForMultipleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EngineFindDependentResourcesForMultipleResponseBody>> {
        if (requestParameters.findDependentResourcesForMultipleRequestBody === null || requestParameters.findDependentResourcesForMultipleRequestBody === undefined) {
            throw new runtime.RequiredError('findDependentResourcesForMultipleRequestBody','Required parameter requestParameters.findDependentResourcesForMultipleRequestBody was null or undefined when calling engineFindDependentResourcesForMultiple.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/engine/dependent_resources/multi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EngineFindDependentResourcesForMultipleRequestBodyToJSON(requestParameters.findDependentResourcesForMultipleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngineFindDependentResourcesForMultipleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Find dependents of the specified resource
     * FindDependentResourcesForMultiple Engine
     */
    async engineFindDependentResourcesForMultiple(requestParameters: EngineFindDependentResourcesForMultipleRequest, initOverrides?: RequestInit): Promise<EngineFindDependentResourcesForMultipleResponseBody> {
        const response = await this.engineFindDependentResourcesForMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all available resources
     * ListAllResources Engine
     */
    async engineListAllResourcesRaw(requestParameters: EngineListAllResourcesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EngineListAllResourcesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.enumerable !== undefined) {
            queryParameters['enumerable'] = requestParameters.enumerable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/engine/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngineListAllResourcesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available resources
     * ListAllResources Engine
     */
    async engineListAllResources(requestParameters: EngineListAllResourcesRequest, initOverrides?: RequestInit): Promise<EngineListAllResourcesResponseBody> {
        const response = await this.engineListAllResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List resources by types
     * ListResources Engine
     */
    async engineListResourcesRaw(requestParameters: EngineListResourcesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EngineListResourcesResponseBody>> {
        if (requestParameters.listResourcesRequestBody === null || requestParameters.listResourcesRequestBody === undefined) {
            throw new runtime.RequiredError('listResourcesRequestBody','Required parameter requestParameters.listResourcesRequestBody was null or undefined when calling engineListResources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/engine/resources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EngineListResourcesRequestBodyToJSON(requestParameters.listResourcesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngineListResourcesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List resources by types
     * ListResources Engine
     */
    async engineListResources(requestParameters: EngineListResourcesRequest, initOverrides?: RequestInit): Promise<EngineListResourcesResponseBody> {
        const response = await this.engineListResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List options to support a typeahead form field
     * Typeahead Engine
     */
    async engineTypeaheadRaw(requestParameters: EngineTypeaheadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EngineTypeaheadResponseBody>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling engineTypeahead.');
        }

        const queryParameters: any = {};

        if (requestParameters.resource !== undefined) {
            queryParameters['resource'] = requestParameters.resource;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/engine/typeahead`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngineTypeaheadResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options to support a typeahead form field
     * Typeahead Engine
     */
    async engineTypeahead(requestParameters: EngineTypeaheadRequest, initOverrides?: RequestInit): Promise<EngineTypeaheadResponseBody> {
        const response = await this.engineTypeaheadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
