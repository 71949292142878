import { ErrorMessage as RHErrorMessage, Props } from "@hookform/error-message";
import { ErrorMessage } from "@incident-ui/ErrorMessage/ErrorMessage";
import React from "react";
import { Field, FieldErrors } from "react-hook-form";

export const FormErrorMessage = <TFieldErrors extends FieldErrors>({
  className,
  ...props
}: {
  className?: string;
} & Props<TFieldErrors, undefined>): React.ReactElement => {
  return (
    <RHErrorMessage
      {...props}
      render={({ message }: { message: string }) => (
        <ErrorMessage className={className} message={message} />
      )}
    />
  );
};

// FormErrors handles the display of global form errors for react-hook-form
export const FormErrors = <T extends Field>({
  errors,
  fields,
  genericError,
}: {
  errors: FieldErrors;
  fields: Partial<Record<string, T>>;
  genericError?: string | null;
}): React.ReactElement => {
  // Find all the errors that don't have a corresponding field, and aren't root
  const unhandledErrors = Object.entries(errors)
    .filter(([errorName, _]) => {
      return (
        !Object.entries(fields)
          .map(([fieldName, _]) => fieldName)
          .includes(errorName) && errorName !== "root"
      );
    })
    .map(([_, error]) => error?.message)
    // Filter to only the ones we know how to display
    .filter((message) => !!message && typeof message === "string");

  // Only show generic error if we have no specific errors to display
  const showGenericError =
    unhandledErrors.length === 0 && !errors.root && !!genericError;

  return (
    <>
      {errors.root && <FormErrorMessage errors={errors} name="root" />}
      {unhandledErrors.map((message, index) => (
        // We check that message exists and is a string above, but TS doesn't know that
        <FormErrorMessage
          key={index}
          message={typeof message === "string" ? message : ""}
        />
      ))}
      {showGenericError && <ErrorMessage message={genericError} />}
    </>
  );
};
