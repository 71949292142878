import { Trend } from "@incident-io/api";
import {
  DateRangePickerMode,
  DateRangePickerState,
  dateRangePickerStateToString,
  dateRangePickerStateToTimestamps,
  QuickSelectInterval,
} from "@incident-shared/forms/v1/DateRangePicker";
import { LoadingTiles } from "@incident-shared/insights/LoadingTrendsTiles";
import { TrendTile } from "@incident-shared/insights/TrendTile";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { parseISO } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { HomeHeading } from "src/components/legacy/home/HomeHeading";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPI } from "src/utils/swr";

import { CustomPanelID } from "../common/types";
import {
  PANEL_TO_DASHBOARD_V3_PAGE,
  TREND_TO_PRIMARY_PANEL,
} from "./constants";

type TrendsTileProps = {
  dateRange: DateRangePickerState;
  panelID: string;
  filters?: string;
  panelVariables?: Record<string, string | undefined>;
  onTileClick?: (trendID: string, sentiment: string) => void;
  smaller?: boolean; // For a smaller tile
  // Render one of each trend type instead of all types in interestingness order
  firstTrendPerType?: boolean;
  numTrends?: number;
  reverse?: boolean;
};

export const TrendsTiles = ({
  dateRange,
  panelID,
  filters,
  panelVariables,
  onTileClick,
  smaller,
  firstTrendPerType,
  numTrends = 3,
  reverse = false,
}: TrendsTileProps) => {
  const { from: startDate, to: endDate } =
    dateRangePickerStateToTimestamps(dateRange);

  const { projectInsightsTrendsViewAll } = useFlags();

  const [showAllTrendsDrawer, setShowAllTrendsDrawer] = useState(false);

  const { data, error, isLoading } = useAPI("insightsGetTrends", {
    dashboardId: panelID,
    // If calculating firstTrendPerType, we'll need all of the trends so that we can group them
    allTrends: firstTrendPerType,
    numTrends,
    startDate: parseISO(startDate),
    endDate: parseISO(endDate),
    filters: filters,
    panelVariables: panelVariables,
  });

  if (error) {
    // 404 is returned when there's no panel registered for the given dashboardID
    // In this case we want to render nothing
    if (error.status === 404) {
      return null;
    }
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <LoadingTiles smaller={smaller} numTiles={numTrends} />;
  }

  if (!data || data.trends.length === 0) {
    return <EmptyTrendTile />;
  }

  let trends = firstTrendPerType
    ? pickFirstTrendPerType(data.trends)
    : data.trends;

  if (reverse) {
    trends = trends.toReversed();
  }

  return (
    <>
      {trends.map((trend, i) => {
        return (
          <TrendTile
            key={i}
            title={trend.title}
            subtitle={trend.subtitle}
            sentiment={trend.sentiment}
            percentageChange={trend.percentage_change}
            previousValue={trend.previous_value}
            currentValue={trend.current_value}
            unit={trend.unit}
            dateRange={dateRange}
            onClick={
              onTileClick
                ? () => onTileClick(trend.trend_id, trend.sentiment)
                : undefined
            }
            smaller={smaller}
          />
        );
      })}
      {projectInsightsTrendsViewAll && (
        <>
          <Button
            title="View all"
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId={null}
            className="text-xs font-medium"
            onClick={() => setShowAllTrendsDrawer(true)}
          >
            View all
          </Button>
          <AllTrendsDrawer
            isOpen={showAllTrendsDrawer}
            onClose={() => setShowAllTrendsDrawer(false)}
            dateRange={dateRange}
            filters={filters}
            panelID={panelID}
            panelVariables={panelVariables}
          />
        </>
      )}
    </>
  );
};

const EmptyTrendTile = ({ showIcon }: { showIcon?: boolean }) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg p-4 bg-green-50" dir="ltr">
      {showIcon && (
        <IconBadge
          icon={IconEnum.Box}
          size={IconSize.Small}
          color={ColorPaletteEnum.Green}
          className="mb-6"
        />
      )}
      <span className="text-xs-med text-content-primary">
        No trends available for the current period
      </span>
      <span className="text-xs-med text-content-secondary">
        Once you&apos;ve resolved more incidents, you&apos;ll unlock insightful
        trends and patterns.
      </span>
    </div>
  );
};

const AllTrendsDrawer = ({
  dateRange,
  filters,
  panelID,
  panelVariables,
  isOpen,
  onClose,
}: TrendsTileProps & {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Drawer onClose={onClose} className="overflow-y-hidden" width="large">
          <DrawerContents className="font-normal">
            <DrawerTitle
              icon={IconEnum.TrendUp}
              color={ColorPaletteEnum.Green}
              onClose={onClose}
              title="All trends"
            />
            <DrawerBody className="overflow-y-auto">
              <AllTrendsDrawerContents
                dateRange={dateRange}
                filters={filters}
                panelID={panelID}
                panelVariables={panelVariables}
              />
            </DrawerBody>
          </DrawerContents>
        </Drawer>
      )}
    </AnimatePresence>
  );
};

const AllTrendsDrawerContents = ({
  dateRange,
  filters,
  panelID,
  panelVariables,
}: TrendsTileProps) => {
  const { from: startDate, to: endDate } =
    dateRangePickerStateToTimestamps(dateRange);

  const { data, error, isLoading } = useAPI("insightsGetTrends", {
    dashboardId: panelID,
    startDate: parseISO(startDate),
    endDate: parseISO(endDate),
    filters: filters,
    panelVariables: panelVariables,
    allTrends: true,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <LoadingTiles numTiles={3} />;
  }

  if (!data || data.trends.length === 0) {
    return <EmptyTrendTile />;
  }

  return (
    <div className="flex flex-wrap gap-2">
      {data.trends.map((trend, i) => {
        return (
          <div key={i} className="w-64">
            <TrendTile
              title={trend.title}
              subtitle={trend.subtitle}
              sentiment={trend.sentiment}
              percentageChange={trend.percentage_change}
              previousValue={trend.previous_value}
              currentValue={trend.current_value}
              unit={trend.unit}
              dateRange={dateRange}
            />
          </div>
        );
      })}
    </div>
  );
};

// We are opinionated about which trends are shown in the homepage and
// insights homepage panels, and if the org does not have on-call, we replace
// the missing tile with something else interesting.
const HOMEPAGE_ONCALL_TREND = "on_call_pager_load_by_time_of_day";

// pickFirstTrendPerType groups trends by their trend_id, and then picks the first of each group.
// If specifically the on-call trend is missing, replace it with the next most interesting trend instead.
const pickFirstTrendPerType = (trends: Array<Trend>) => {
  let nextMostInteresting: Trend | undefined = undefined;
  const trendsByType = trends.reduce(
    (acc, trend) => {
      if (!acc[trend.trend_id]) {
        acc[trend.trend_id] = trend;
      } else if (nextMostInteresting === undefined) {
        nextMostInteresting = trend;
      }
      return acc;
    },
    {} as Record<string, Trend>,
  );
  const firstPerType = Object.values(trendsByType);
  if (
    trendsByType[HOMEPAGE_ONCALL_TREND] === undefined &&
    nextMostInteresting
  ) {
    firstPerType.push(nextMostInteresting);
  }
  return firstPerType;
};

export const InsightsV3HomepageTrendsTiles = () => {
  const analytics = useAnalytics();
  const navigate = useOrgAwareNavigate();

  const trendsDateRange: DateRangePickerState = {
    mode: DateRangePickerMode.QuickSelect,
    quick_select: QuickSelectInterval.Last4Weeks,
  };

  const onTrendTileClick = (trendID: string, sentiment: string) => {
    const panelID = TREND_TO_PRIMARY_PANEL[trendID];
    const dashboard = PANEL_TO_DASHBOARD_V3_PAGE[panelID];
    const newQueryParams = new URLSearchParams();
    newQueryParams.set(
      "date_range",
      dateRangePickerStateToString(trendsDateRange),
    );
    newQueryParams.set("compare_previous_period", "true");
    newQueryParams.set("scroll_to", panelID);
    navigate({
      pathname: `${dashboard}`,
      search: newQueryParams.toString(),
    });
    analytics?.track(`trend-tile.clicked`, {
      location: "insights-homepage",
      trendID,
      sentiment,
    });
  };

  const panelVariables = {
    split_by_type: "status",
  };

  const { from: startDate, to: endDate } =
    dateRangePickerStateToTimestamps(trendsDateRange);

  const { data, error, isLoading } = useAPI("insightsGetTrends", {
    dashboardId: CustomPanelID.Homepage,
    allTrends: true,
    startDate: parseISO(startDate),
    endDate: parseISO(endDate),
    panelVariables: panelVariables,
  });

  if (error) {
    return <></>;
  }

  // Calculate whether we have enough data to render before making a mess of the homepage
  if (!isLoading && !!data) {
    const firstPerType = pickFirstTrendPerType(data.trends);

    if (firstPerType.length !== 4) {
      return <></>;
    }
  }

  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
      <TrendsTiles
        dateRange={trendsDateRange}
        panelID={CustomPanelID.InsightsHomepage}
        numTrends={4}
        panelVariables={{
          split_by_type: "status",
        }}
        onTileClick={onTrendTileClick}
        firstTrendPerType
      />
    </div>
  );
};

export const V3HomepageTrendsTiles = () => {
  const analytics = useAnalytics();
  const navigate = useOrgAwareNavigate();

  const trendsDateRange: DateRangePickerState = {
    mode: DateRangePickerMode.QuickSelect,
    quick_select: QuickSelectInterval.Last4Weeks,
  };

  const onTrendTileClick = (trendID: string, sentiment: string) => {
    const panelID = TREND_TO_PRIMARY_PANEL[trendID];
    const dashboard = PANEL_TO_DASHBOARD_V3_PAGE[panelID];
    const newQueryParams = new URLSearchParams();
    newQueryParams.set(
      "date_range",
      dateRangePickerStateToString(trendsDateRange),
    );
    newQueryParams.set("compare_previous_period", "true");
    newQueryParams.set("scroll_to", panelID);
    navigate({
      pathname: `../insights/${dashboard}`,
      search: newQueryParams.toString(),
    });
    analytics?.track(`trend-tile.clicked`, {
      location: "dashboard-homepage",
      trendID,
      sentiment,
    });
  };

  const panelVariables = {
    split_by_type: "status",
  };

  const { from: startDate, to: endDate } =
    dateRangePickerStateToTimestamps(trendsDateRange);

  const { data, error, isLoading } = useAPI("insightsGetTrends", {
    dashboardId: CustomPanelID.Homepage,
    allTrends: true,
    startDate: parseISO(startDate),
    endDate: parseISO(endDate),
    panelVariables: panelVariables,
  });

  if (error) {
    return <></>;
  }

  // Calculate whether we have enough data to render before making a mess of the homepage
  if (!isLoading && !!data) {
    const firstPerType = pickFirstTrendPerType(data.trends);

    if (firstPerType.length !== 4) {
      return <></>;
    }
  }

  return (
    <div className="space-y-2 w-full">
      <HomeHeading
        title={
          <div>
            Trends{" "}
            <span className="pl-1 text-xs-med text-content-tertiary">
              vs last 4 weeks
            </span>
          </div>
        }
        seeAllLink={{
          title: "View all insights",
          href: "/insights",
          analyticsTrackingId: "internal-homepage-view-all-insights",
        }}
      />
      <div className="px-4 md:px-8 grid lg:grid-cols-4 md:grid-cols-2 gap-4">
        <TrendsTiles
          dateRange={trendsDateRange}
          panelID={CustomPanelID.Homepage}
          numTrends={4}
          panelVariables={panelVariables}
          onTileClick={onTrendTileClick}
          firstTrendPerType
          smaller
        />
      </div>
    </div>
  );
};
