import type { SVGProps } from "react";
import * as React from "react";
const SvgPagerduty = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="#06AC38" d="M2 2h16v16H2V2Z" />
    <mask
      id="pagerduty_svg__a"
      width={6}
      height={10}
      x={7}
      y={5}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M7.001 5.715H13v8.57H7.001v-8.57Z" />
    </mask>
    <g mask="url(#pagerduty_svg__a)">
      <path
        fill="#fff"
        d="M8.28 12.003H7v2.282H8.28v-2.282ZM11.8 6.136c-.681-.362-1.159-.421-2.278-.421H7.001v5.196h2.512c.995 0 1.741-.059 2.398-.49C12.627 9.956 13 9.177 13 8.276c0-.97-.458-1.748-1.199-2.14ZM9.807 9.824H8.279V6.827l1.443-.01c1.313-.01 1.97.44 1.97 1.474 0 1.111-.816 1.533-1.885 1.533Z"
      />
    </g>
  </svg>
);
export default SvgPagerduty;
