import type { SVGProps } from "react";
import * as React from "react";
const SvgGoogleMeet = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#00832D"
      d="m11.05 9.582 1.56 1.783 2.097 1.34.366-3.112-.366-3.042-2.137 1.177-1.52 1.854Z"
    />
    <path
      fill="#0066DA"
      d="M2 12.416v2.651c0 .606.491 1.097 1.097 1.097h2.651l.549-2.004-.549-1.744-1.819-.548L2 12.416Z"
    />
    <path
      fill="#E94235"
      d="M5.748 3 2 6.748l1.929.549 1.82-.549.539-1.72L5.748 3Z"
    />
    <path fill="#2684FC" d="M5.748 6.748H2v5.668h3.748V6.748Z" />
    <path
      fill="#00AC47"
      d="m17.102 4.587-2.395 1.964v6.154l2.406 1.973c.36.281.887.024.887-.434V5.011a.55.55 0 0 0-.898-.424ZM11.05 9.582v2.834H5.748v3.748h7.862c.606 0 1.097-.49 1.097-1.097v-2.362L11.05 9.582Z"
    />
    <path
      fill="#FFBA00"
      d="M13.61 3H5.748v3.748h5.302v2.834l3.657-3.03V4.098c0-.606-.49-1.097-1.097-1.097Z"
    />
  </svg>
);
export default SvgGoogleMeet;
