/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';

/**
 * 
 * @export
 * @interface HolidaysListCountriesResponseBody
 */
export interface HolidaysListCountriesResponseBody {
    /**
     * List of countries that we have holidays for
     * @type {Array<Country>}
     * @memberof HolidaysListCountriesResponseBody
     */
    countries: Array<Country>;
}

export function HolidaysListCountriesResponseBodyFromJSON(json: any): HolidaysListCountriesResponseBody {
    return HolidaysListCountriesResponseBodyFromJSONTyped(json, false);
}

export function HolidaysListCountriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidaysListCountriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countries': ((json['countries'] as Array<any>).map(CountryFromJSON)),
    };
}

export function HolidaysListCountriesResponseBodyToJSON(value?: HolidaysListCountriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countries': ((value.countries as Array<any>).map(CountryToJSON)),
    };
}

