/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationMeta
 */
export interface PaginationMeta {
    /**
     * If provided, pass this as the 'after' param to load the next page
     * @type {string}
     * @memberof PaginationMeta
     */
    after?: string;
    /**
     * What was the maximum number of results requested
     * @type {number}
     * @memberof PaginationMeta
     */
    page_size: number;
    /**
     * How many matching records were there in total, if known
     * @type {number}
     * @memberof PaginationMeta
     */
    total_record_count?: number;
}

export function PaginationMetaFromJSON(json: any): PaginationMeta {
    return PaginationMetaFromJSONTyped(json, false);
}

export function PaginationMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'after': !exists(json, 'after') ? undefined : json['after'],
        'page_size': json['page_size'],
        'total_record_count': !exists(json, 'total_record_count') ? undefined : json['total_record_count'],
    };
}

export function PaginationMetaToJSON(value?: PaginationMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'after': value.after,
        'page_size': value.page_size,
        'total_record_count': value.total_record_count,
    };
}

