/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemDocument,
    PostmortemDocumentFromJSON,
    PostmortemDocumentFromJSONTyped,
    PostmortemDocumentToJSON,
} from './PostmortemDocument';

/**
 * 
 * @export
 * @interface PostmortemsListDocumentsResponseBody
 */
export interface PostmortemsListDocumentsResponseBody {
    /**
     * 
     * @type {Array<PostmortemDocument>}
     * @memberof PostmortemsListDocumentsResponseBody
     */
    documents: Array<PostmortemDocument>;
}

export function PostmortemsListDocumentsResponseBodyFromJSON(json: any): PostmortemsListDocumentsResponseBody {
    return PostmortemsListDocumentsResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsListDocumentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsListDocumentsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(PostmortemDocumentFromJSON)),
    };
}

export function PostmortemsListDocumentsResponseBodyToJSON(value?: PostmortemsListDocumentsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documents': ((value.documents as Array<any>).map(PostmortemDocumentToJSON)),
    };
}

