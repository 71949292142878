import { Avatar, Icon, IconEnum, IconSize, Tooltip } from "@incident-ui";
import { isEmpty } from "lodash";
import { EngineParamBindingValue } from "src/contexts/ClientContext";

export const BindingLabel = ({
  paramBinding,
  hideAvatar = false,
}: {
  paramBinding: EngineParamBindingValue;
  hideAvatar?: boolean;
}) => {
  if (paramBinding.unavailable) {
    return (
      <Tooltip
        content={
          "This value is no longer available, please update it or remove it."
        }
      >
        <span className={"inline-block text-red-500"}>
          <span className="flex-center-y relative">
            <Icon id={IconEnum.Warning} className="absolute" />
            <span className={"ml-[1.25rem]"}>
              {paramBinding.label ?? paramBinding.literal}
            </span>
          </span>
        </span>
      </Tooltip>
    );
  }

  const label = isEmpty(paramBinding.label)
    ? paramBinding.literal
    : paramBinding.label;
  return (
    <span className="inline flex-center-y w-full">
      {paramBinding.image_url && !hideAvatar && (
        <Avatar
          size={IconSize.Medium}
          url={paramBinding.image_url}
          className={"inline mx-0.5"}
        />
      )}
      <span>{label}</span>
    </span>
  );
};
