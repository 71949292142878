import cx from "classnames";

import { InfoIcon } from "../Icons/InfoIcon";
import { Tooltip } from "../Tooltip";

export const InfoBadge = ({
  description,
  className,
}: {
  description?: string;
  className?: string;
}): React.ReactElement => {
  return (
    <div
      className={cx(
        "transition",
        "text-slate-300 hover:text-slate-500",
        "dark:text-slate-500 dark:hover:text-slate-300",
        className,
      )}
    >
      <Tooltip content={description}>
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
