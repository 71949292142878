/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomFieldValuePayload
 */
export interface CustomFieldValuePayload {
    /**
     * Unique identifier for the custom field value
     * @type {string}
     * @memberof CustomFieldValuePayload
     */
    id?: string;
    /**
     * ID of the catalog entry. You can also use an ExternalID or an Alias of the catalog entry.
     * @type {string}
     * @memberof CustomFieldValuePayload
     */
    value_catalog_entry_id?: string;
    /**
     * If the custom field type is 'link', this will contain the value assigned.
     * @type {string}
     * @memberof CustomFieldValuePayload
     */
    value_link?: string;
    /**
     * If the custom field type is 'numeric', this will contain the value assigned.
     * @type {string}
     * @memberof CustomFieldValuePayload
     */
    value_numeric?: string;
    /**
     * ID of the custom field option
     * @type {string}
     * @memberof CustomFieldValuePayload
     */
    value_option_id?: string;
    /**
     * The value of a new option that should be created, if the field supports dynamic options. If this conflicts with an existing option label, you must use its ID instead.
     * @type {string}
     * @memberof CustomFieldValuePayload
     */
    value_option_value?: string;
    /**
     * If the custom field type is 'text', this will contain the value assigned.
     * @type {string}
     * @memberof CustomFieldValuePayload
     */
    value_text?: string;
}

export function CustomFieldValuePayloadFromJSON(json: any): CustomFieldValuePayload {
    return CustomFieldValuePayloadFromJSONTyped(json, false);
}

export function CustomFieldValuePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldValuePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value_catalog_entry_id': !exists(json, 'value_catalog_entry_id') ? undefined : json['value_catalog_entry_id'],
        'value_link': !exists(json, 'value_link') ? undefined : json['value_link'],
        'value_numeric': !exists(json, 'value_numeric') ? undefined : json['value_numeric'],
        'value_option_id': !exists(json, 'value_option_id') ? undefined : json['value_option_id'],
        'value_option_value': !exists(json, 'value_option_value') ? undefined : json['value_option_value'],
        'value_text': !exists(json, 'value_text') ? undefined : json['value_text'],
    };
}

export function CustomFieldValuePayloadToJSON(value?: CustomFieldValuePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'value_catalog_entry_id': value.value_catalog_entry_id,
        'value_link': value.value_link,
        'value_numeric': value.value_numeric,
        'value_option_id': value.value_option_id,
        'value_option_value': value.value_option_value,
        'value_text': value.value_text,
    };
}

