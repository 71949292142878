import { IconEnum } from "@incident-ui";
import _ from "lodash";
import { ExternalResource } from "src/contexts/ClientContext";

import { AttachmentRenderProps } from "./IncidentAttachment";

export const PagerDutyIncident = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.pager_duty_incident) {
    throw new Error("Pagerduty incident resource without expected content");
  }

  return {
    icon: IconEnum.Pagerduty,
    title: `#${resource.pager_duty_incident.incident_number}: ${resource.pager_duty_incident.title}`,
    safeBadges: [
      {
        icon: IconEnum.Severity,
        text: _.startCase(resource.pager_duty_incident.urgency),
      },
      {
        icon: IconEnum.Server,
        text: resource.pager_duty_incident.service,
      },
    ],
    unsafeBadges: [
      {
        icon: IconEnum.Status,
        text: _.startCase(resource.pager_duty_incident.status),
      },
    ],
  };
};
