/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotificationsListResponseBody,
    NotificationsListResponseBodyFromJSON,
    NotificationsListResponseBodyToJSON,
    NotificationsMarkAsReadRequestBody,
    NotificationsMarkAsReadRequestBodyFromJSON,
    NotificationsMarkAsReadRequestBodyToJSON,
    NotificationsMuteIncidentRequestBody,
    NotificationsMuteIncidentRequestBodyFromJSON,
    NotificationsMuteIncidentRequestBodyToJSON,
} from '../models';

export interface NotificationsListRequest {
    pageSize?: number;
    after?: string;
}

export interface NotificationsMarkAsReadRequest {
    markAsReadRequestBody: NotificationsMarkAsReadRequestBody;
}

export interface NotificationsMuteIncidentRequest {
    muteIncidentRequestBody: NotificationsMuteIncidentRequestBody;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * List the notifications relevant to a user
     * List Notifications
     */
    async notificationsListRaw(requestParameters: NotificationsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the notifications relevant to a user
     * List Notifications
     */
    async notificationsList(requestParameters: NotificationsListRequest, initOverrides?: RequestInit): Promise<NotificationsListResponseBody> {
        const response = await this.notificationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Marks notifications as read
     * MarkAsRead Notifications
     */
    async notificationsMarkAsReadRaw(requestParameters: NotificationsMarkAsReadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.markAsReadRequestBody === null || requestParameters.markAsReadRequestBody === undefined) {
            throw new runtime.RequiredError('markAsReadRequestBody','Required parameter requestParameters.markAsReadRequestBody was null or undefined when calling notificationsMarkAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/action/mark-as-read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationsMarkAsReadRequestBodyToJSON(requestParameters.markAsReadRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Marks notifications as read
     * MarkAsRead Notifications
     */
    async notificationsMarkAsRead(requestParameters: NotificationsMarkAsReadRequest, initOverrides?: RequestInit): Promise<void> {
        await this.notificationsMarkAsReadRaw(requestParameters, initOverrides);
    }

    /**
     * Mutes all future notifications from an incident
     * MuteIncident Notifications
     */
    async notificationsMuteIncidentRaw(requestParameters: NotificationsMuteIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.muteIncidentRequestBody === null || requestParameters.muteIncidentRequestBody === undefined) {
            throw new runtime.RequiredError('muteIncidentRequestBody','Required parameter requestParameters.muteIncidentRequestBody was null or undefined when calling notificationsMuteIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notifications/action/mute-incident`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationsMuteIncidentRequestBodyToJSON(requestParameters.muteIncidentRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mutes all future notifications from an incident
     * MuteIncident Notifications
     */
    async notificationsMuteIncident(requestParameters: NotificationsMuteIncidentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.notificationsMuteIncidentRaw(requestParameters, initOverrides);
    }

}
