/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostIncidentTask,
    PostIncidentTaskFromJSON,
    PostIncidentTaskFromJSONTyped,
    PostIncidentTaskToJSON,
} from './PostIncidentTask';

/**
 * 
 * @export
 * @interface PostIncidentFlowRejectTaskResponseBody
 */
export interface PostIncidentFlowRejectTaskResponseBody {
    /**
     * 
     * @type {PostIncidentTask}
     * @memberof PostIncidentFlowRejectTaskResponseBody
     */
    incident_task: PostIncidentTask;
}

export function PostIncidentFlowRejectTaskResponseBodyFromJSON(json: any): PostIncidentFlowRejectTaskResponseBody {
    return PostIncidentFlowRejectTaskResponseBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowRejectTaskResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowRejectTaskResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_task': PostIncidentTaskFromJSON(json['incident_task']),
    };
}

export function PostIncidentFlowRejectTaskResponseBodyToJSON(value?: PostIncidentFlowRejectTaskResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_task': PostIncidentTaskToJSON(value.incident_task),
    };
}

