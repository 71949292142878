import { FollowUp, Incident } from "src/contexts/ClientContext";

import { ExportToIssueTrackerModal } from "./ExportToIssueTrackerModal";
import { ExportToLinearModal } from "./ExportToLinearModal";
import { ExportToJiraCloudModal } from "./jira/ExportToJiraCloudModal";
import { IssueTrackerProviderEnum } from "./utils";

export const ExportToProviderModal = ({
  provider,
  followUp,
  onClose,
  incident,
  isPrivate,
  updateCallback,
}: {
  provider: IssueTrackerProviderEnum;
  followUp: FollowUp;
  onClose: () => void;
  isPrivate: boolean;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
  incident: Incident;
}): React.ReactElement => {
  switch (provider) {
    case IssueTrackerProviderEnum.Jira:
      return (
        <ExportToJiraCloudModal
          incident={incident}
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.Linear:
      return (
        <ExportToLinearModal
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );

    default:
      return (
        <ExportToIssueTrackerModal
          provider={provider}
          incident={incident}
          followUp={followUp}
          isPrivateIncident={isPrivate}
          onClose={onClose}
          updateCallback={updateCallback}
        />
      );
  }
};
