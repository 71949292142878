/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleVersion,
    ScheduleVersionFromJSON,
    ScheduleVersionFromJSONTyped,
    ScheduleVersionToJSON,
} from './ScheduleVersion';

/**
 * 
 * @export
 * @interface SchedulesListVersionsResponseBody
 */
export interface SchedulesListVersionsResponseBody {
    /**
     * 
     * @type {Array<ScheduleVersion>}
     * @memberof SchedulesListVersionsResponseBody
     */
    schedule_versions: Array<ScheduleVersion>;
}

export function SchedulesListVersionsResponseBodyFromJSON(json: any): SchedulesListVersionsResponseBody {
    return SchedulesListVersionsResponseBodyFromJSONTyped(json, false);
}

export function SchedulesListVersionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesListVersionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_versions': ((json['schedule_versions'] as Array<any>).map(ScheduleVersionFromJSON)),
    };
}

export function SchedulesListVersionsResponseBodyToJSON(value?: SchedulesListVersionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_versions': ((value.schedule_versions as Array<any>).map(ScheduleVersionToJSON)),
    };
}

