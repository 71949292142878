import {
  ExpressionBranch,
  PostmortemSettings,
  PostmortemTemplate,
  ScopeNameEnum,
} from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { branchesOnlyExpressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { Form } from "@incident-shared/forms";
import { SettingsListItemButtonsProps } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import { Button, IconEnum, SharedToasts, StackedList } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFieldArray, useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSubHeading } from "../../../SettingsSubHeading";
import { FallbackDefaultPostmortemTemplate } from "./FallbackDefaultPostmortemTemplate";
import { EditBranchModal, EditElseBranchModal } from "./modals";
import { Branch } from "./PostmortemTemplateBranch";
import { PostmortemTemplateExpressionFormData } from "./types";

type PostmortemExpressionFormProps = {
  settings: PostmortemSettings;
  templates: PostmortemTemplate[];
};

export type EditBranch = {
  branch: ExpressionBranch & { id: string };
  index: number;
};

export const PostmortemExpressionForm = ({
  settings,
  templates,
}: PostmortemExpressionFormProps) => {
  const showToast = useToast();
  const formMethods = useForm<PostmortemTemplateExpressionFormData>({
    defaultValues: {
      postmortem_template_expression:
        settings?.postmortem_template_expression || {
          branches: [],
        },
    },
  });

  const [addBranch, setAddBranch] = useState(false);
  const [editElseBranch, setEditElseBranch] = useState(false);
  const [editBranch, setEditBranch] = useState<EditBranch | undefined>();

  const { setError } = formMethods;

  const { trigger: updateExpression } = useAPIMutation(
    "postmortemsSettingsShow",
    undefined,
    async (apiClient, data: PostmortemTemplateExpressionFormData) => {
      await apiClient.postmortemsSettingsUpdateTemplateExpression({
        updateTemplateExpressionRequestBody: {
          postmortem_template_expression: data.postmortem_template_expression
            ? branchesOnlyExpressionToPayload(
                data.postmortem_template_expression,
                false,
              )
            : undefined,
        },
      });
    },
    {
      onSuccess: () => {
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      setError,
    },
  );

  const onUpdateExpression = async (
    expression: PostmortemTemplateExpressionFormData,
  ): Promise<void> => {
    await updateExpression(expression);
  };

  const branchesFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "postmortem_template_expression.branches",
  });
  const branches = branchesFieldMethods.fields;

  const elseBranch = formMethods.getValues(
    "postmortem_template_expression.else_branch",
  );
  const elseBranchTemplateID = elseBranch?.result.value?.literal;
  const elseBranchTemplate = elseBranchTemplateID
    ? templates.find((template) => template.id === elseBranchTemplateID)
    : undefined;

  const onRemoveBranch = (index: number) => {
    branchesFieldMethods.remove(index);
  };

  const onDragEnd = (result) => {
    // Only listen for drop events (ignore things like 'CANCEL' events, where
    // the user just cancelled/aborted)
    if (result.reason !== "DROP") {
      return;
    }

    // If we dropped it outside the list, no-op
    if (!result.destination) {
      return;
    }

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    branchesFieldMethods.move(fromIndex, toIndex);
  };

  const firstTemplate = templates[0];

  const emptyStateButtons: SettingsListItemButtonsProps = {
    requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
    delete: {
      disabled: true,
      isGatedText: "There must always be a default template",
      noDeleteConfirmation: true,
    },
    edit: {
      onEdit: () => setEditElseBranch(true),
    },
  };

  return (
    <Form.Root onSubmit={updateExpression} formMethods={formMethods}>
      <>
        <SettingsSubHeading
          title={"Template conditions"}
          titleHeadingSize="small"
          explanation={`You can configure which template should be used under certain conditions. This can always be overridden.`}
          accessory={
            <div className={`shrink-0`}>
              <Button
                analyticsTrackingId={null}
                icon={IconEnum.Add}
                onClick={() => setAddBranch(true)}
              >
                Add condition
              </Button>
            </div>
          }
        />

        {branches.length > 0 && (
          <StackedList className="text-sm">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="main_template">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {branches.map((branch, index) => (
                      <Draggable
                        isDragDisabled={branches.length === 1}
                        key={branch.id}
                        draggableId={branch.id}
                        index={index}
                      >
                        {(provided) => (
                          <Branch
                            fieldName={`postmortem_template_expression.branches.${index}`}
                            key={branch.id}
                            isFirstItem={index === 0}
                            branch={branch}
                            prefix={index === 0 ? "If" : "Else if"}
                            templates={templates}
                            onEdit={() => setEditBranch({ branch, index })}
                            onDelete={() => {
                              onRemoveBranch(index);
                              onUpdateExpression(formMethods.getValues());
                            }}
                            draggableProvided={provided}
                          />
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <FallbackDefaultPostmortemTemplate
              elseBranchTemplate={elseBranchTemplate ?? firstTemplate}
              setEditElseBranch={setEditElseBranch}
            />
          </StackedList>
        )}

        {branches.length === 0 && (
          <StackedList>
            <SettingsListItem
              title={
                <div className="text-sm-normal text-content-secondary flex-center-y gap-2">
                  Use{" "}
                  <EngineLiteralBadge
                    icon={IconEnum.Doc}
                    label={elseBranchTemplate?.name ?? firstTemplate?.name}
                    className="text-content-primary text-sm-med"
                  />
                  as the default template
                </div>
              }
              buttons={emptyStateButtons}
            />
          </StackedList>
        )}

        {editElseBranch && (
          <EditElseBranchModal
            templates={templates}
            onEditBranch={(elseBranch) => {
              formMethods.setValue(
                "postmortem_template_expression.else_branch",
                elseBranch,
              );
              formMethods.clearErrors();
              setEditElseBranch(false);

              // Then submit the form.
              onUpdateExpression(formMethods.getValues());
            }}
            onClose={() => setEditElseBranch(false)}
            isOnlyBranch={branches.length === 0}
          />
        )}

        {editBranch && (
          <EditBranchModal
            templates={templates}
            onEditBranch={(newBranch) => {
              branchesFieldMethods.update(editBranch.index, newBranch);
              setEditBranch(undefined);

              // Then submit the form.
              onUpdateExpression(formMethods.getValues());
            }}
            branch={editBranch.branch}
            onClose={() => setEditBranch(undefined)}
          />
        )}

        {addBranch && (
          <EditBranchModal
            templates={templates}
            onEditBranch={(newBranch) => {
              branchesFieldMethods.append(newBranch);
              if (
                !formMethods.getValues<"postmortem_template_expression.else_branch">(
                  "postmortem_template_expression.else_branch",
                )
              ) {
                formMethods.setValue<"postmortem_template_expression.else_branch">(
                  "postmortem_template_expression.else_branch",
                  {
                    result: {},
                  },
                );
              }
              setAddBranch(false);

              // Then submit the form.
              onUpdateExpression(formMethods.getValues());
            }}
            onClose={() => setAddBranch(false)}
          />
        )}
      </>
    </Form.Root>
  );
};
