/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Condition,
    ConditionFromJSON,
    ConditionFromJSONTyped,
    ConditionToJSON,
} from './Condition';
import {
    EscalationPathNode,
    EscalationPathNodeFromJSON,
    EscalationPathNodeFromJSONTyped,
    EscalationPathNodeToJSON,
} from './EscalationPathNode';

/**
 * 
 * @export
 * @interface EscalationPathNodeIfElse
 */
export interface EscalationPathNodeIfElse {
    /**
     * The condition that defines which branch to take
     * @type {Array<Condition>}
     * @memberof EscalationPathNodeIfElse
     */
    conditions: Array<Condition>;
    /**
     * The nodes that form the levels if our condition is not met
     * @type {Array<EscalationPathNode>}
     * @memberof EscalationPathNodeIfElse
     */
    else_path: Array<EscalationPathNode>;
    /**
     * The nodes that form the levels if our condition is met
     * @type {Array<EscalationPathNode>}
     * @memberof EscalationPathNodeIfElse
     */
    then_path: Array<EscalationPathNode>;
}

export function EscalationPathNodeIfElseFromJSON(json: any): EscalationPathNodeIfElse {
    return EscalationPathNodeIfElseFromJSONTyped(json, false);
}

export function EscalationPathNodeIfElseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNodeIfElse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': ((json['conditions'] as Array<any>).map(ConditionFromJSON)),
        'else_path': ((json['else_path'] as Array<any>).map(EscalationPathNodeFromJSON)),
        'then_path': ((json['then_path'] as Array<any>).map(EscalationPathNodeFromJSON)),
    };
}

export function EscalationPathNodeIfElseToJSON(value?: EscalationPathNodeIfElse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': ((value.conditions as Array<any>).map(ConditionToJSON)),
        'else_path': ((value.else_path as Array<any>).map(EscalationPathNodeToJSON)),
        'then_path': ((value.then_path as Array<any>).map(EscalationPathNodeToJSON)),
    };
}

