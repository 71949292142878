export type ElementBindingValue = {
  array_value: Array<string>;
  scalar_value: string;
};

export type ElementBindings = {
  [key: string]: ElementBindingValue;
};

export const asArray = (
  a: ElementBindingValue | undefined | null,
): Array<string> => {
  if (a?.scalar_value) {
    return [a.scalar_value];
  }

  if (Array.isArray(a?.array_value)) {
    return a.array_value;
  }

  return [];
};
