/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Escalation,
    EscalationFromJSON,
    EscalationFromJSONTyped,
    EscalationToJSON,
} from './Escalation';
import {
    ExternalEscalation,
    ExternalEscalationFromJSON,
    ExternalEscalationFromJSONTyped,
    ExternalEscalationToJSON,
} from './ExternalEscalation';

/**
 * 
 * @export
 * @interface IncidentFormsSubmitEscalateFormResponseBody
 */
export interface IncidentFormsSubmitEscalateFormResponseBody {
    /**
     * 
     * @type {Array<Escalation>}
     * @memberof IncidentFormsSubmitEscalateFormResponseBody
     */
    escalations?: Array<Escalation>;
    /**
     * 
     * @type {Array<ExternalEscalation>}
     * @memberof IncidentFormsSubmitEscalateFormResponseBody
     */
    external_escalations?: Array<ExternalEscalation>;
}

export function IncidentFormsSubmitEscalateFormResponseBodyFromJSON(json: any): IncidentFormsSubmitEscalateFormResponseBody {
    return IncidentFormsSubmitEscalateFormResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsSubmitEscalateFormResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsSubmitEscalateFormResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalations': !exists(json, 'escalations') ? undefined : ((json['escalations'] as Array<any>).map(EscalationFromJSON)),
        'external_escalations': !exists(json, 'external-escalations') ? undefined : ((json['external-escalations'] as Array<any>).map(ExternalEscalationFromJSON)),
    };
}

export function IncidentFormsSubmitEscalateFormResponseBodyToJSON(value?: IncidentFormsSubmitEscalateFormResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalations': value.escalations === undefined ? undefined : ((value.escalations as Array<any>).map(EscalationToJSON)),
        'external-escalations': value.external_escalations === undefined ? undefined : ((value.external_escalations as Array<any>).map(ExternalEscalationToJSON)),
    };
}

