/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    EscalationPathSlim,
    EscalationPathSlimFromJSON,
    EscalationPathSlimFromJSONTyped,
    EscalationPathSlimToJSON,
} from './EscalationPathSlim';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EscalationCreated
 */
export interface EscalationCreated {
    /**
     * 
     * @type {Actor}
     * @memberof EscalationCreated
     */
    creator: Actor;
    /**
     * the user(s) that were escalated to
     * @type {Array<User>}
     * @memberof EscalationCreated
     */
    escalated_to_users?: Array<User>;
    /**
     * 
     * @type {EscalationPathSlim}
     * @memberof EscalationCreated
     */
    escalation_path?: EscalationPathSlim;
}

export function EscalationCreatedFromJSON(json: any): EscalationCreated {
    return EscalationCreatedFromJSONTyped(json, false);
}

export function EscalationCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationCreated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': ActorFromJSON(json['creator']),
        'escalated_to_users': !exists(json, 'escalated_to_users') ? undefined : ((json['escalated_to_users'] as Array<any>).map(UserFromJSON)),
        'escalation_path': !exists(json, 'escalation_path') ? undefined : EscalationPathSlimFromJSON(json['escalation_path']),
    };
}

export function EscalationCreatedToJSON(value?: EscalationCreated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': ActorToJSON(value.creator),
        'escalated_to_users': value.escalated_to_users === undefined ? undefined : ((value.escalated_to_users as Array<any>).map(UserToJSON)),
        'escalation_path': EscalationPathSlimToJSON(value.escalation_path),
    };
}

