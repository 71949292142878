/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JiraIssueDefaultValues
 */
export interface JiraIssueDefaultValues {
    /**
     * Dynamic field keys mapped to values
     * @type {object}
     * @memberof JiraIssueDefaultValues
     */
    dynamic_fields?: object;
    /**
     * The Jira issue type id
     * @type {string}
     * @memberof JiraIssueDefaultValues
     */
    issue_type_id?: string;
    /**
     * The Jira label(s) to assign to the issue
     * @type {Array<string>}
     * @memberof JiraIssueDefaultValues
     */
    labels?: Array<string>;
    /**
     * The Jira project to create the issue to
     * @type {string}
     * @memberof JiraIssueDefaultValues
     */
    project_id?: string;
    /**
     * The Jira site to create the issue in
     * @type {string}
     * @memberof JiraIssueDefaultValues
     */
    site_id?: string;
}

export function JiraIssueDefaultValuesFromJSON(json: any): JiraIssueDefaultValues {
    return JiraIssueDefaultValuesFromJSONTyped(json, false);
}

export function JiraIssueDefaultValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraIssueDefaultValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamic_fields': !exists(json, 'dynamic_fields') ? undefined : json['dynamic_fields'],
        'issue_type_id': !exists(json, 'issue_type_id') ? undefined : json['issue_type_id'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'project_id': !exists(json, 'project_id') ? undefined : json['project_id'],
        'site_id': !exists(json, 'site_id') ? undefined : json['site_id'],
    };
}

export function JiraIssueDefaultValuesToJSON(value?: JiraIssueDefaultValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamic_fields': value.dynamic_fields,
        'issue_type_id': value.issue_type_id,
        'labels': value.labels,
        'project_id': value.project_id,
        'site_id': value.site_id,
    };
}

