/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HolidayPublicEntry
 */
export interface HolidayPublicEntry {
    /**
     * Alpha-2 ISO country code
     * @type {string}
     * @memberof HolidayPublicEntry
     */
    country_code: string;
    /**
     * Date of the holiday
     * @type {string}
     * @memberof HolidayPublicEntry
     */
    date: string;
    /**
     * Name of the holiday
     * @type {string}
     * @memberof HolidayPublicEntry
     */
    name: string;
}

export function HolidayPublicEntryFromJSON(json: any): HolidayPublicEntry {
    return HolidayPublicEntryFromJSONTyped(json, false);
}

export function HolidayPublicEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidayPublicEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country_code': json['country_code'],
        'date': json['date'],
        'name': json['name'],
    };
}

export function HolidayPublicEntryToJSON(value?: HolidayPublicEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_code': value.country_code,
        'date': value.date,
        'name': value.name,
    };
}

