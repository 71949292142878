import { Badge, BadgeSize, BadgeTheme } from "@incident-ui";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { CustomFieldFieldTypeEnum } from "src/contexts/ClientContext";
export enum ManagementType {
  Manual = "manual",
  Dynamic = "dynamic",
  Catalog = "catalog",
}

export const managementOptions = (useFieldsFromCatalog: boolean) => {
  const options: RadioButtonGroupOption[] = [
    {
      label: "A fixed list",
      value: ManagementType.Manual,
      description:
        "Options are only configured via the settings page, or through the API.",
    },
    {
      label: "A list that any user can add to",
      value: ManagementType.Dynamic,
      description:
        "Additional options can be created during an incident, from Slack and the Incident Homepage.",
    },
  ];

  if (useFieldsFromCatalog) {
    options.unshift({
      label: "From a catalog type",
      value: ManagementType.Catalog,
      description: "Options will come from the catalog entries for that type.",
      suffixNode: (
        <Badge theme={BadgeTheme.Info} size={BadgeSize.ExtraSmall}>
          Recommended
        </Badge>
      ),
    });
  }

  return options;
};

export const availableFieldTypes = [
  {
    label: "Single-select",
    sort_key: "1",
    value: CustomFieldFieldTypeEnum.SingleSelect,
    description:
      "User will be asked to choose one value from a list of options.",
  },
  {
    label: "Multi-select",
    sort_key: "2",
    value: CustomFieldFieldTypeEnum.MultiSelect,
    description:
      "User will be asked to choose one or more from a list of options.",
  },
  {
    label: "Text",
    sort_key: "3",
    value: CustomFieldFieldTypeEnum.Text,
    description: "User will be asked to provide a free-form text value.",
  },
  {
    label: "Link",
    sort_key: "4",
    value: CustomFieldFieldTypeEnum.Link,
    description:
      "User will be asked to give a URL that will be added to the incident channel bookmarks.",
  },
  {
    label: "Number",
    sort_key: "5",
    value: CustomFieldFieldTypeEnum.Numeric,
    description: "User will be asked to provide a numeric value.",
  },
];
