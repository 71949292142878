/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteSlim,
    AlertRouteSlimFromJSON,
    AlertRouteSlimFromJSONTyped,
    AlertRouteSlimToJSON,
} from './AlertRouteSlim';
import {
    IncidentSlim,
    IncidentSlimFromJSON,
    IncidentSlimFromJSONTyped,
    IncidentSlimToJSON,
} from './IncidentSlim';

/**
 * 
 * @export
 * @interface CreatedIncident
 */
export interface CreatedIncident {
    /**
     * 
     * @type {AlertRouteSlim}
     * @memberof CreatedIncident
     */
    alert_route: AlertRouteSlim;
    /**
     * 
     * @type {IncidentSlim}
     * @memberof CreatedIncident
     */
    incident: IncidentSlim;
}

export function CreatedIncidentFromJSON(json: any): CreatedIncident {
    return CreatedIncidentFromJSONTyped(json, false);
}

export function CreatedIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatedIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_route': AlertRouteSlimFromJSON(json['alert_route']),
        'incident': IncidentSlimFromJSON(json['incident']),
    };
}

export function CreatedIncidentToJSON(value?: CreatedIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_route': AlertRouteSlimToJSON(value.alert_route),
        'incident': IncidentSlimToJSON(value.incident),
    };
}

