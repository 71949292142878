import { IncidentsBuildScopeContextEnum } from "@incident-io/api";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { IconEnum } from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { Route, Routes } from "react-router";
import { UserPreferencesHeading } from "src/components/user-preferences/common/UserPreferencesHeading";
import { AutoSubscribeForm } from "src/components/user-preferences/incident-subscriptions/AutoSubscribeForm";
import { IncidentSubscriptionsForm } from "src/components/user-preferences/incident-subscriptions/IncidentSubscriptionsForm";
import { InternalStatusPageSubscriptions } from "src/components/user-preferences/incident-subscriptions/InternalStatusPageSubscriptions";
import { UnsubscribeFromAllIncidentsForm } from "src/components/user-preferences/incident-subscriptions/UnsubscribeFromAllIncidentsForm";
import { UnsubscribeFromIncidentModal } from "src/components/user-preferences/incident-subscriptions/UnsubscribeFromIncidentModal";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAPI } from "src/utils/swr";

export const IncidentSubscriptionsPage = () => {
  const { scope, scopeLoading } = useIncidentScope(
    IncidentsBuildScopeContextEnum.AutoSubscribe,
  );

  const { data, error } = useAPI("userPreferencesShow", undefined);
  const userPreferences = data?.user_preferences ?? null;

  if (error) throw error;

  return (
    <SecondaryNavSubPageWrapper
      title="Incident subscriptions"
      icon={IconEnum.Subscription}
    >
      <UserPreferencesHeading
        title={"Incident subscriptions"}
        description={
          "Subscriptions allow you to be informed of incident updates, as they happen. You can manually subscribe to an incident from your incidents channel, or you can set up rules for automatic subscriptions below."
        }
      />
      <Routes>
        <Route
          path="unsubscribe/:incidentId"
          element={<UnsubscribeFromIncidentModal />}
        />
      </Routes>
      <LoadingWrapper loading={!userPreferences}>
        <IncidentSubscriptionsForm userPreferences={userPreferences} />
      </LoadingWrapper>
      <LoadingWrapper loading={scopeLoading} className="space-y-2">
        <InternalStatusPageSubscriptions />
        <AutoSubscribeForm scope={scope} />
      </LoadingWrapper>
      <UnsubscribeFromAllIncidentsForm />
    </SecondaryNavSubPageWrapper>
  );
};
