import type { SVGProps } from "react";
import * as React from "react";
const SvgSparkles = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m3.343 5.938 1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0 0-.948l-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.421a.5.5 0 0 0-.001.948ZM16.659 13.99l-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.421a.5.5 0 0 0 0 .948l1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0 0-.948h-.001ZM10.526 12.267 8.5 11.465l-.802-2.027c-.227-.572-1.168-.572-1.395 0l-.802 2.027-2.026.802a.75.75 0 0 0 0 1.394l2.026.802.802 2.027a.75.75 0 0 0 1.394 0l.802-2.027 2.026-.802a.751.751 0 0 0 .001-1.394ZM16.526 6.267 14.5 5.465l-.802-2.027c-.227-.572-1.168-.572-1.395 0l-.802 2.027-2.026.802a.75.75 0 0 0 0 1.394l2.026.802.802 2.027a.75.75 0 0 0 1.394 0l.802-2.027 2.026-.802a.751.751 0 0 0 .001-1.394Z"
    />
  </svg>
);
export default SvgSparkles;
