/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Alert,
    AlertFromJSON,
    AlertFromJSONTyped,
    AlertToJSON,
} from './Alert';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';

/**
 * An incident that is considered for grouping an alert into
 * @export
 * @interface AlertRouteCandidateIncident
 */
export interface AlertRouteCandidateIncident {
    /**
     * Unique identifier for the incident
     * @type {string}
     * @memberof AlertRouteCandidateIncident
     */
    id: string;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof AlertRouteCandidateIncident
     */
    incident_status: IncidentStatus;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof AlertRouteCandidateIncident
     */
    name: string;
    /**
     * All alerts that are related to this incident that we will have matched upon
     * @type {Array<Alert>}
     * @memberof AlertRouteCandidateIncident
     */
    related_alerts: Array<Alert>;
}

export function AlertRouteCandidateIncidentFromJSON(json: any): AlertRouteCandidateIncident {
    return AlertRouteCandidateIncidentFromJSONTyped(json, false);
}

export function AlertRouteCandidateIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteCandidateIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_status': IncidentStatusFromJSON(json['incident_status']),
        'name': json['name'],
        'related_alerts': ((json['related_alerts'] as Array<any>).map(AlertFromJSON)),
    };
}

export function AlertRouteCandidateIncidentToJSON(value?: AlertRouteCandidateIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_status': IncidentStatusToJSON(value.incident_status),
        'name': value.name,
        'related_alerts': ((value.related_alerts as Array<any>).map(AlertToJSON)),
    };
}

