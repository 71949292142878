/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentType,
    IncidentTypeFromJSON,
    IncidentTypeFromJSONTyped,
    IncidentTypeToJSON,
} from './IncidentType';

/**
 * 
 * @export
 * @interface IncidentTypesUpdateOverrideIncidentLifecycleResponseBody
 */
export interface IncidentTypesUpdateOverrideIncidentLifecycleResponseBody {
    /**
     * 
     * @type {IncidentType}
     * @memberof IncidentTypesUpdateOverrideIncidentLifecycleResponseBody
     */
    incident_type: IncidentType;
}

export function IncidentTypesUpdateOverrideIncidentLifecycleResponseBodyFromJSON(json: any): IncidentTypesUpdateOverrideIncidentLifecycleResponseBody {
    return IncidentTypesUpdateOverrideIncidentLifecycleResponseBodyFromJSONTyped(json, false);
}

export function IncidentTypesUpdateOverrideIncidentLifecycleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTypesUpdateOverrideIncidentLifecycleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_type': IncidentTypeFromJSON(json['incident_type']),
    };
}

export function IncidentTypesUpdateOverrideIncidentLifecycleResponseBodyToJSON(value?: IncidentTypesUpdateOverrideIncidentLifecycleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_type': IncidentTypeToJSON(value.incident_type),
    };
}

