/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertHeadlineMetric
 */
export interface AlertHeadlineMetric {
    /**
     * The type of this metric
     * @type {string}
     * @memberof AlertHeadlineMetric
     */
    type: AlertHeadlineMetricTypeEnum;
    /**
     * The unit of this metric
     * @type {string}
     * @memberof AlertHeadlineMetric
     */
    unit: AlertHeadlineMetricUnitEnum;
    /**
     * The value of this metric in the current time period
     * @type {number}
     * @memberof AlertHeadlineMetric
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum AlertHeadlineMetricTypeEnum {
    NumberAlerts = 'number_alerts',
    NumberIncidents = 'number_incidents',
    NumberIncidentsDeclined = 'number_incidents_declined',
    NumberIncidentsReal = 'number_incidents_real',
    DeclineRate = 'decline_rate',
    WorkloadTotalIncidents = 'workload_total_incidents',
    WorkloadRealIncidents = 'workload_real_incidents',
    WorkloadDeclinedIncidents = 'workload_declined_incidents'
}/**
* @export
* @enum {string}
*/
export enum AlertHeadlineMetricUnitEnum {
    Alert = 'alert',
    Incident = 'incident',
    Hour = 'hour',
    Percent = '%'
}

export function AlertHeadlineMetricFromJSON(json: any): AlertHeadlineMetric {
    return AlertHeadlineMetricFromJSONTyped(json, false);
}

export function AlertHeadlineMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertHeadlineMetric {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'unit': json['unit'],
        'value': json['value'],
    };
}

export function AlertHeadlineMetricToJSON(value?: AlertHeadlineMetric | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'unit': value.unit,
        'value': value.value,
    };
}

