/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExploVariableMapping,
    ExploVariableMappingFromJSON,
    ExploVariableMappingFromJSONTyped,
    ExploVariableMappingToJSON,
} from './ExploVariableMapping';
import {
    SelectConfig,
    SelectConfigFromJSON,
    SelectConfigFromJSONTyped,
    SelectConfigToJSON,
} from './SelectConfig';

/**
 * 
 * @export
 * @interface PanelVariableFormField
 */
export interface PanelVariableFormField {
    /**
     * 
     * @type {ExploVariableMapping}
     * @memberof PanelVariableFormField
     */
    explo_mapping?: ExploVariableMapping;
    /**
     * Unique identifier for the operator
     * @type {string}
     * @memberof PanelVariableFormField
     */
    key: string;
    /**
     * Human readable label for the operator
     * @type {string}
     * @memberof PanelVariableFormField
     */
    label: string;
    /**
     * 
     * @type {SelectConfig}
     * @memberof PanelVariableFormField
     */
    select_config: SelectConfig;
}

export function PanelVariableFormFieldFromJSON(json: any): PanelVariableFormField {
    return PanelVariableFormFieldFromJSONTyped(json, false);
}

export function PanelVariableFormFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelVariableFormField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'explo_mapping': !exists(json, 'explo_mapping') ? undefined : ExploVariableMappingFromJSON(json['explo_mapping']),
        'key': json['key'],
        'label': json['label'],
        'select_config': SelectConfigFromJSON(json['select_config']),
    };
}

export function PanelVariableFormFieldToJSON(value?: PanelVariableFormField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'explo_mapping': ExploVariableMappingToJSON(value.explo_mapping),
        'key': value.key,
        'label': value.label,
        'select_config': SelectConfigToJSON(value.select_config),
    };
}

