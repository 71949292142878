import {
  IdentityOrganisationAvailableProductsEnum,
  IncidentFormRequiredProductEnum,
} from "@incident-io/api";

export enum Product {
  Response = IdentityOrganisationAvailableProductsEnum.Response,
  OnCall = IdentityOrganisationAvailableProductsEnum.OnCall,
}

export function cooerceToProduct(
  product:
    | IdentityOrganisationAvailableProductsEnum
    | IncidentFormRequiredProductEnum,
): Product {
  if (product === IncidentFormRequiredProductEnum.Response) {
    return Product.Response;
  } else if (product === IncidentFormRequiredProductEnum.OnCall) {
    return Product.OnCall;
  }
  return Product.Response;
}
