/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface PartialEntryPayload
 */
export interface PartialEntryPayload {
    /**
     * The attribute values to apply to this entry
     * @type {{ [key: string]: EngineParamBindingPayload; }}
     * @memberof PartialEntryPayload
     */
    attribute_values: { [key: string]: EngineParamBindingPayload; };
    /**
     * ID of the relevant catalog entry
     * @type {string}
     * @memberof PartialEntryPayload
     */
    entry_id: string;
    /**
     * Whether this entry was filled in via auto-suggest
     * @type {boolean}
     * @memberof PartialEntryPayload
     */
    from_auto_suggest?: boolean;
}

export function PartialEntryPayloadFromJSON(json: any): PartialEntryPayload {
    return PartialEntryPayloadFromJSONTyped(json, false);
}

export function PartialEntryPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialEntryPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute_values': (mapValues(json['attribute_values'], EngineParamBindingPayloadFromJSON)),
        'entry_id': json['entry_id'],
        'from_auto_suggest': !exists(json, 'from_auto_suggest') ? undefined : json['from_auto_suggest'],
    };
}

export function PartialEntryPayloadToJSON(value?: PartialEntryPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute_values': (mapValues(value.attribute_values, EngineParamBindingPayloadToJSON)),
        'entry_id': value.entry_id,
        'from_auto_suggest': value.from_auto_suggest,
    };
}

