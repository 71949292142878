import _ from "lodash";
import {
  SchedulePayRule,
  ScheduleReport,
  ScheduleReportOverride,
  ScheduleReportSchedule,
  ScheduleReportUser,
} from "src/contexts/ClientContext";

import { describeRule } from "./PayRule";

type ShiftRate = {
  short_id: string;
  rate_cents: number;
  kind: "PayRule" | "Override";
  source: SchedulePayRule | ScheduleReportOverride;
};

// describeRate provides a human readable description of either a pay rule or an
// override.
export const describeRate = (rate: ShiftRate): string => {
  switch (rate.kind) {
    case "PayRule": {
      return describeRule(rate.source as unknown as SchedulePayRule);
    }
    case "Override": {
      const override = rate.source as unknown as ScheduleReportOverride;
      return override.name;
    }
    default:
      throw new Error("Unreachable: invalid shift rate kind");
  }
};

export const getRate = (
  schedule: ScheduleReportSchedule,
  id: string, // either ID of pay rule, or ID of override
): ShiftRate => {
  const rule = schedule.pay_rules.find((rule) => rule.short_id === id);
  const overrideIndex = schedule.overrides.findIndex(
    (override) => override.id === id,
  );

  if (!rule && overrideIndex < 0) {
    throw new Error(
      `could not find pay rule or override with ID '${id}' in schedule ${schedule.name}`,
    );
  }

  if (rule) {
    return {
      short_id: rule.short_id,
      kind: "PayRule",
      rate_cents: rule.rate_cents,
      source: rule,
    };
  } else {
    const override = schedule.overrides[overrideIndex];

    return {
      short_id: `Holiday ${overrideIndex + 1}`,
      kind: "Override",
      rate_cents: override.rate_cents,
      source: override,
    };
  }
};

export type ShiftAggregate = {
  user: ScheduleReportUser;
  schedule: ScheduleReportSchedule;
  rate: ShiftRate;
  hours: number;
  totals: Record<string, number>;
};

export type Aggregates = {
  totals: ShiftAggregate[];
  totalsByUserBySchedule: Omit<ShiftAggregate, "rate">[];
  totalsByUser: Omit<ShiftAggregate, "schedule" | "rate">[];
  totalsBySchedule: Omit<ShiftAggregate, "rate" | "user">[];
  totalsByCurrency: Record<string, number>;
  currencies: string[];
};

export const buildAggregate = (
  report: ScheduleReport,
  schedules: ScheduleReportSchedule[],
): Aggregates => {
  const getUser = (externalID: string) => {
    const user = report.users.find((user) => user.external_id === externalID);
    if (!user) {
      throw new Error(`no user with ID ${externalID}`);
    }

    return user;
  };

  const totals: Record<string, Aggregates["totals"][0]> = {};
  schedules.forEach((schedule) => {
    schedule.shifts.forEach((shift) => {
      const row = (totals[
        schedule.id + shift.pay_rule_short_id + shift.external_user_id
      ] ||= {
        schedule,
        user: getUser(shift.external_user_id),
        rate: getRate(
          schedule,
          shift.pay_rule_short_id || shift.override_id || "unknown",
        ),
        hours: 0,
        totals: {},
      });

      row.hours +=
        (Date.parse(shift.end_at as unknown as string) -
          Date.parse(shift.start_at as unknown as string)) /
        (3600 * 1000);

      row.totals[schedule.currency] ||= 0;
      row.totals[schedule.currency] += shift.value_cents;
    });
  });

  const totalsByUserBySchedule: Record<
    string,
    Aggregates["totalsByUserBySchedule"][0]
  > = {};
  schedules.forEach((schedule) => {
    schedule.shifts.forEach((shift) => {
      const row = (totalsByUserBySchedule[
        schedule.id + shift.external_user_id
      ] ||= {
        user: getUser(shift.external_user_id),
        schedule: schedule,
        hours: 0,
        totals: {},
      });

      row.hours +=
        (Date.parse(shift.end_at as unknown as string) -
          Date.parse(shift.start_at as unknown as string)) /
        (3600 * 1000);

      row.totals[schedule.currency] ||= 0;
      row.totals[schedule.currency] += shift.value_cents;
    });
  });

  const totalsByUser: Record<string, Aggregates["totalsByUser"][0]> = {};
  schedules.forEach((schedule) => {
    schedule.shifts.forEach((shift) => {
      const row = (totalsByUser[shift.external_user_id] ||= {
        user: getUser(shift.external_user_id),
        hours: 0,
        totals: {},
      });

      row.hours +=
        (Date.parse(shift.end_at as unknown as string) -
          Date.parse(shift.start_at as unknown as string)) /
        (3600 * 1000);

      row.totals[schedule.currency] ||= 0;
      row.totals[schedule.currency] += shift.value_cents;
    });
  });

  const totalsBySchedule: Record<string, Aggregates["totalsBySchedule"][0]> =
    {};
  schedules.forEach((schedule) => {
    const row = (totalsBySchedule[schedule.id] ||= {
      schedule,
      hours: 0,
      totals: {},
    });
    row.totals[schedule.currency] ||= 0;
    schedule.shifts.forEach((shift) => {
      row.hours +=
        (Date.parse(shift.end_at as unknown as string) -
          Date.parse(shift.start_at as unknown as string)) /
        (3600 * 1000);

      row.totals[schedule.currency] += shift.value_cents;
    });
  });

  const totalsByCurrency = {};
  _.values(totalsByUser).forEach(({ totals }) => {
    _.forEach(totals, (total: number, currency: string) => {
      totalsByCurrency[currency] ||= 0;
      totalsByCurrency[currency] += total;
    });
  });

  return {
    totals: _.values(totals),
    totalsByUserBySchedule: _.sortBy(
      _.values(totalsByUserBySchedule),
      ({ user: { name } }) => name,
    ),
    totalsByUser: _.sortBy(
      _.values(totalsByUser),
      ({ user: { name } }) => name,
    ),
    totalsBySchedule: _.sortBy(
      _.values(totalsBySchedule),
      ({ schedule: { name } }) => name,
    ),
    totalsByCurrency: totalsByCurrency,
    currencies: _.sortBy(_.keys(totalsByCurrency), _.identity),
  };
};
