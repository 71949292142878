import { Settings } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";

export function useIncidentTypesAvailable(): boolean {
  const { identity } = useIdentity();
  return identity?.feature_gates?.incident_types || false;
}

export function incidentTypesEnabled(settings: Settings | null): boolean {
  return settings?.misc?.incident_types_enabled || false;
}

export function useIncidentTypesEnabled(): boolean {
  const { settings } = useSettings();
  return settings?.misc?.incident_types_enabled || false;
}
