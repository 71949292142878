/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathNodePayload,
    EscalationPathNodePayloadFromJSON,
    EscalationPathNodePayloadFromJSONTyped,
    EscalationPathNodePayloadToJSON,
} from './EscalationPathNodePayload';

/**
 * 
 * @export
 * @interface CallRoutingUpdateRequestBody
 */
export interface CallRoutingUpdateRequestBody {
    /**
     * The name of the call routing configuration
     * @type {string}
     * @memberof CallRoutingUpdateRequestBody
     */
    name: string;
    /**
     * The path of nodes to follow when a call is received
     * @type {Array<EscalationPathNodePayload>}
     * @memberof CallRoutingUpdateRequestBody
     */
    path: Array<EscalationPathNodePayload>;
    /**
     * IDs of users that should be promoted to on-call responders before creating this path
     * @type {Array<string>}
     * @memberof CallRoutingUpdateRequestBody
     */
    user_ids_to_promote?: Array<string>;
}

export function CallRoutingUpdateRequestBodyFromJSON(json: any): CallRoutingUpdateRequestBody {
    return CallRoutingUpdateRequestBodyFromJSONTyped(json, false);
}

export function CallRoutingUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallRoutingUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'path': ((json['path'] as Array<any>).map(EscalationPathNodePayloadFromJSON)),
        'user_ids_to_promote': !exists(json, 'user_ids_to_promote') ? undefined : json['user_ids_to_promote'],
    };
}

export function CallRoutingUpdateRequestBodyToJSON(value?: CallRoutingUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'path': ((value.path as Array<any>).map(EscalationPathNodePayloadToJSON)),
        'user_ids_to_promote': value.user_ids_to_promote,
    };
}

