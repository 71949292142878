import { tcx } from "src/utils/tailwind-classes";

type TimelineSpacerProps = {
  expanded?: boolean;
  children?: React.ReactNode;
  hideLine?: boolean;
  lineStyle?: "stroke" | "dashed";
};

export const TimelineSpacer = ({
  children,
  expanded = false,
  hideLine = false,
  lineStyle = "stroke",
}: TimelineSpacerProps) => {
  return (
    <div className={tcx("flex py-[3px]", !expanded && "h-4")}>
      <div className="w-[4.3475rem] shrink-0"></div>
      <div
        className={tcx(
          "w-full h-full pl-[34px] min-w-0 flex flex-col gap-2",
          !hideLine && "border-l",
          expanded && "pb-4 pt-1",
          lineStyle === "stroke" && "border-solid",
          lineStyle === "dashed" && "border-dashed",
        )}
      >
        {expanded && children}
      </div>
    </div>
  );
};
