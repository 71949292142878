import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import React from "react";
import { Path, UseFormReturn } from "react-hook-form";
import { IncidentRole } from "src/contexts/ClientContext";
import { useClient } from "src/contexts/ClientContext";

export type FormIncidentRoleAssignments = {
  [key: string]: { assignee_id?: string };
};

export type IncidentRoleFormData = {
  incident_role_assignments: FormIncidentRoleAssignments;
};

export function IncidentRoleFormElement<T extends IncidentRoleFormData>({
  formMethods,
  role,
  incidentId,
  formKey,
  autoFocus,
  hideDescription,
  required,
  placeholder,
  disabled = false,
}: {
  formMethods: UseFormReturn<T>;
  role: IncidentRole;
  incidentId?: string;
  autoFocus?: boolean;
  formKey: Path<T>;
  hideDescription?: boolean;
  required: boolean;
  placeholder?: string;
  disabled?: boolean;
}): React.ReactElement {
  const apiClient = useClient();

  const label = role.name;
  const selectPlaceholder = placeholder ? placeholder : "Select user";

  return (
    <DynamicSingleSelectV2<T>
      formMethods={formMethods}
      name={formKey}
      key={formKey}
      autoFocus={autoFocus}
      label={label}
      placeholder={selectPlaceholder}
      helptext={!hideDescription ? role.description : ""}
      isClearable={!required}
      required={required}
      loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
        incidentId,
      })}
      hydrateOptions={hydrateInitialSelectOptions(
        apiClient,
        TypeaheadTypeEnum.User,
      )}
      disabled={disabled}
    />
  );
}
