/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsClickUpInstallRequestBody,
    IntegrationsClickUpInstallRequestBodyFromJSON,
    IntegrationsClickUpInstallRequestBodyToJSON,
} from '../models';

export interface IntegrationsClickUpHandleWebhookRequest {
    xSignature?: string;
}

export interface IntegrationsClickUpInstallRequest {
    installRequestBody: IntegrationsClickUpInstallRequestBody;
}

/**
 * 
 */
export class IntegrationsClickUpApi extends runtime.BaseAPI {

    /**
     * Handle a webhook from ClickUp
     * HandleWebhook Integrations - ClickUp
     */
    async integrationsClickUpHandleWebhookRaw(requestParameters: IntegrationsClickUpHandleWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSignature !== undefined && requestParameters.xSignature !== null) {
            headerParameters['X-Signature'] = String(requestParameters.xSignature);
        }

        const response = await this.request({
            path: `/webhooks/click_up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle a webhook from ClickUp
     * HandleWebhook Integrations - ClickUp
     */
    async integrationsClickUpHandleWebhook(requestParameters: IntegrationsClickUpHandleWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsClickUpHandleWebhookRaw(requestParameters, initOverrides);
    }

    /**
     * Add an ClickUp API key to install the integration
     * Install Integrations - ClickUp
     */
    async integrationsClickUpInstallRaw(requestParameters: IntegrationsClickUpInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installRequestBody === null || requestParameters.installRequestBody === undefined) {
            throw new runtime.RequiredError('installRequestBody','Required parameter requestParameters.installRequestBody was null or undefined when calling integrationsClickUpInstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/click_up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsClickUpInstallRequestBodyToJSON(requestParameters.installRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add an ClickUp API key to install the integration
     * Install Integrations - ClickUp
     */
    async integrationsClickUpInstall(requestParameters: IntegrationsClickUpInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsClickUpInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Remove the ClickUp integration
     * Uninstall Integrations - ClickUp
     */
    async integrationsClickUpUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/click_up`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the ClickUp integration
     * Uninstall Integrations - ClickUp
     */
    async integrationsClickUpUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsClickUpUninstallRaw(initOverrides);
    }

}
