import {
  Button,
  ContentBox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Loader,
  SearchableDropdown,
  StackedList,
  Txt,
} from "@incident-ui";
import React from "react";
import {
  IncidentLifecycle,
  IncidentType,
  ScopeNameEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { LifecycleDeleteButton } from "./LifecycleDeleteButton";
import { LIFECYCLE_GRID_CLASSNAME } from "./sections/LifecycleOverviewSection";

export const LifecycleEditIncidentTypesSection = ({
  lifecycle,
}: {
  lifecycle: IncidentLifecycle;
}): React.ReactElement | null => {
  const refreshIncidentTypesList = useRevalidate(["incidentTypesList"]);
  const apiClient = useClient();

  const {
    data: { incident_types: incidentTypes },
    isLoading,
    error,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  const onUpdateForType = async (
    incidentTypeId: string,
    lifecycleId?: string,
  ) => {
    await apiClient.incidentTypesUpdateOverrideIncidentLifecycle({
      id: incidentTypeId,
      updateOverrideIncidentLifecycleRequestBody: {
        override_incident_lifecycle_id: lifecycleId,
      },
    });
    refreshIncidentTypesList();
  };

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <div className={tcx(LIFECYCLE_GRID_CLASSNAME, "w-full")}>
      <div />
      <ContentBox className="p-4 flex justify-between items-center text-sm">
        <ListOrAddIncidentTypes
          lifecycle={lifecycle}
          incidentTypes={incidentTypes}
          onUpdateForType={onUpdateForType}
        />
      </ContentBox>
    </div>
  );
};

const ListOrAddIncidentTypes = ({
  lifecycle,
  incidentTypes,
  onUpdateForType,
}: {
  lifecycle: IncidentLifecycle;
  incidentTypes: IncidentType[];
  onUpdateForType: (incidentTypeId: string, lifecycleId?: string) => void;
}): React.ReactElement => {
  const currentIncidentTypes = incidentTypes.filter(
    (incidentType) =>
      incidentType.override_incident_lifecycle_id === lifecycle.id,
  );

  const availableTypes = incidentTypes.filter(
    (incidentType) =>
      incidentType.override_incident_lifecycle_id !== lifecycle.id,
  );
  const searchableEntries = availableTypes.map((incidentType) => ({
    item: { ...incidentType, label: incidentType.name },
    label: incidentType.name,
    sortKey: incidentType.name,
  }));
  const onAdd = (incidentType) =>
    onUpdateForType(incidentType.id, lifecycle.id);

  if (lifecycle.is_default) {
    return (
      <div className="flex items-start gap-2">
        <Icon id={IconEnum.Info} className="text-content-tertiary shrink-0" />
        <Txt>
          This is your default incident lifecycle. It applies to all incidents
          unless they have a type which is connected to a different lifecycle.
        </Txt>
      </div>
    );
  }

  if (currentIncidentTypes.length === 0) {
    return (
      <div className="flex flex-col gap-4">
        <Txt> No incident types are currently using this lifecycle.</Txt>
        <div className="flex items-center gap-2">
          <SearchableDropdown
            onSelectItem={onAdd}
            emptyState="There are no more incident types to choose"
            entries={searchableEntries}
            renderTriggerButton={({ onClick }) => (
              <Button
                onClick={onClick}
                icon={IconEnum.Add}
                analyticsTrackingId="lifecycle-add-incident-type"
              >
                Select an incident type
              </Button>
            )}
          />
          <LifecycleDeleteButton lifecycle={lifecycle} />
        </div>
      </div>
    );
  }

  let incidentTypeOrPlural = "incident type";
  if (currentIncidentTypes.length > 1) {
    incidentTypeOrPlural = "incident types";
  }

  return (
    <div className="space-y-4 w-full">
      <div className="flex items-center justify-between w-full">
        <Txt className="block">
          This lifecycle is currently being used by the following{" "}
          {incidentTypeOrPlural}:
        </Txt>
        <SearchableDropdown
          onSelectItem={onAdd}
          emptyState="There are no more incident types to choose"
          entries={searchableEntries}
          renderTriggerButton={({ onClick }) => (
            <Button
              icon={IconEnum.Add}
              onClick={onClick}
              analyticsTrackingId="lifecycle-add-incident-type"
            >
              Add another
            </Button>
          )}
        />
      </div>
      <StackedList className="">
        {currentIncidentTypes.map((incidentType) => (
          <SettingsListItem
            key={incidentType.id}
            title={incidentType.name}
            description={incidentType.description}
            descriptionPosition="inline"
            icon={IconEnum.IncidentType}
            buttons={{
              requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
              delete: {
                resourceTitle: incidentType.name,
                onDelete: () => onUpdateForType(incidentType.id, undefined),
                deleteConfirmationTitle: "Remove incident type",
                deleteConfirmationContent: (
                  <div className="text-sm">
                    <Txt>
                      This will mean that{" "}
                      <Txt inline bold>
                        {incidentType.name}
                      </Txt>{" "}
                      incidents will use the default lifecycle. <br /> <br />
                      Any existing incidents will not be affected, but when they
                      change status they&apos;ll be asked to choose from the
                      default lifecycle.
                    </Txt>
                  </div>
                ),
              },
            }}
          />
        ))}
      </StackedList>
    </div>
  );
};
