import { ScopeNameEnum } from "@incident-io/api";
import {
  ContentBox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Loader,
} from "@incident-ui";
import graphic from "src/components/settings/banners/banner-webhooks.svg";
import { tcx } from "src/utils/tailwind-classes";
import { AppPortal } from "svix-react";

import { useIdentity } from "../../../contexts/IdentityContext";
import { useAPI } from "../../../utils/swr";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";

const articleId = 6984344;

export const WebhookViewPage = (): React.ReactElement => {
  const { identity, hasScope } = useIdentity();
  const canEdit = hasScope(ScopeNameEnum.WebhooksManage);

  const {
    data: configData,
    isValidating,
    error,
  } = useAPI(canEdit ? "webhookConfigGetConfig" : null, undefined, {
    // Disable all auto-revalidation: changing the Svix portal URL will cause it
    // to reload, which we do not want.
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    // Except when we switch to this tab: switching to/from the tab should
    // generate a fresh token.
    revalidateOnMount: true,
  });
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const config = configData?.config;

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Automate notifications"
        subtitle="Set up webhooks to notify external systems about incidents automatically."
        articleId={articleId}
        graphic={<img src={graphic} className="h-40" />}
        planNameMustUpgradeTo="Team"
        requiresUpgrade={!identity?.feature_gates.webhooks}
      />
      {!!identity?.feature_gates.webhooks && (
        <>
          <ContentBox>
            {!canEdit && (
              <div className="text-slate-600 text-sm m-5 flex flex-row items-center gap-2">
                <Icon id={IconEnum.LockClosed} />
                Only admin users are allowed to manage organisation webhooks.
              </div>
            )}
            {isValidating && <Loader />}
            {config && (
              <div
                className={tcx(
                  "px-2 pt-2 flex items-center flex-col m-auto w-full min-h-[200px]",
                )}
              >
                <AppPortal
                  url={config?.svix_portal_url}
                  fullSize
                  loadingIndicator={<Loader />}
                />
              </div>
            )}
          </ContentBox>
        </>
      )}
    </SettingsSubPageWrapper>
  );
};
