/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalUser,
    ExternalUserFromJSON,
    ExternalUserFromJSONTyped,
    ExternalUserToJSON,
} from './ExternalUser';
import {
    MobileKey,
    MobileKeyFromJSON,
    MobileKeyFromJSONTyped,
    MobileKeyToJSON,
} from './MobileKey';
import {
    OnCallNotificationMethod,
    OnCallNotificationMethodFromJSON,
    OnCallNotificationMethodFromJSONTyped,
    OnCallNotificationMethodToJSON,
} from './OnCallNotificationMethod';

/**
 * 
 * @export
 * @interface OnCallNotificationMethodV2
 */
export interface OnCallNotificationMethodV2 {
    /**
     * 
     * @type {MobileKey}
     * @memberof OnCallNotificationMethodV2
     */
    app?: MobileKey;
    /**
     * The email address, if OnCallNotificationMethodType email
     * @type {string}
     * @memberof OnCallNotificationMethodV2
     */
    email?: string;
    /**
     * The type of notification method
     * @type {string}
     * @memberof OnCallNotificationMethodV2
     */
    method_type: OnCallNotificationMethodV2MethodTypeEnum;
    /**
     * 
     * @type {ExternalUser}
     * @memberof OnCallNotificationMethodV2
     */
    microsoft_teams?: ExternalUser;
    /**
     * 
     * @type {OnCallNotificationMethod}
     * @memberof OnCallNotificationMethodV2
     */
    phone?: OnCallNotificationMethod;
    /**
     * 
     * @type {ExternalUser}
     * @memberof OnCallNotificationMethodV2
     */
    slack?: ExternalUser;
    /**
     * Current state of this method
     * @type {string}
     * @memberof OnCallNotificationMethodV2
     */
    status: OnCallNotificationMethodV2StatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationMethodV2MethodTypeEnum {
    App = 'app',
    Email = 'email',
    MicrosoftTeams = 'microsoft_teams',
    Phone = 'phone',
    Slack = 'slack'
}/**
* @export
* @enum {string}
*/
export enum OnCallNotificationMethodV2StatusEnum {
    Created = 'created',
    PendingVerification = 'pending_verification',
    Verified = 'verified'
}

export function OnCallNotificationMethodV2FromJSON(json: any): OnCallNotificationMethodV2 {
    return OnCallNotificationMethodV2FromJSONTyped(json, false);
}

export function OnCallNotificationMethodV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationMethodV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'app': !exists(json, 'app') ? undefined : MobileKeyFromJSON(json['app']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'method_type': json['method_type'],
        'microsoft_teams': !exists(json, 'microsoft_teams') ? undefined : ExternalUserFromJSON(json['microsoft_teams']),
        'phone': !exists(json, 'phone') ? undefined : OnCallNotificationMethodFromJSON(json['phone']),
        'slack': !exists(json, 'slack') ? undefined : ExternalUserFromJSON(json['slack']),
        'status': json['status'],
    };
}

export function OnCallNotificationMethodV2ToJSON(value?: OnCallNotificationMethodV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'app': MobileKeyToJSON(value.app),
        'email': value.email,
        'method_type': value.method_type,
        'microsoft_teams': ExternalUserToJSON(value.microsoft_teams),
        'phone': OnCallNotificationMethodToJSON(value.phone),
        'slack': ExternalUserToJSON(value.slack),
        'status': value.status,
    };
}

