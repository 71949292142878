/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface AvailableReminderTarget
 */
export interface AvailableReminderTarget {
    /**
     * How many distinct users are there for this reference, in the provided set of follow-ups?
     * @type {number}
     * @memberof AvailableReminderTarget
     */
    distinct_user_count: number;
    /**
     * 
     * @type {Reference}
     * @memberof AvailableReminderTarget
     */
    reference: Reference;
}

export function AvailableReminderTargetFromJSON(json: any): AvailableReminderTarget {
    return AvailableReminderTargetFromJSONTyped(json, false);
}

export function AvailableReminderTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableReminderTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distinct_user_count': json['distinct_user_count'],
        'reference': ReferenceFromJSON(json['reference']),
    };
}

export function AvailableReminderTargetToJSON(value?: AvailableReminderTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'distinct_user_count': value.distinct_user_count,
        'reference': ReferenceToJSON(value.reference),
    };
}

