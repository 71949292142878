/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemsUpdateTemplateRequestBody
 */
export interface PostmortemsUpdateTemplateRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemsUpdateTemplateRequestBody
     */
    export_title: TextNode;
    /**
     * Whether follow ups are exported just as links or within a table
     * @type {boolean}
     * @memberof PostmortemsUpdateTemplateRequestBody
     */
    follow_ups_links_only: boolean;
    /**
     * Name of the postmortem template
     * @type {string}
     * @memberof PostmortemsUpdateTemplateRequestBody
     */
    name: string;
    /**
     * The writing mode for the template
     * @type {string}
     * @memberof PostmortemsUpdateTemplateRequestBody
     */
    writing_mode: PostmortemsUpdateTemplateRequestBodyWritingModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemsUpdateTemplateRequestBodyWritingModeEnum {
    InHouse = 'in_house',
    External = 'external'
}

export function PostmortemsUpdateTemplateRequestBodyFromJSON(json: any): PostmortemsUpdateTemplateRequestBody {
    return PostmortemsUpdateTemplateRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsUpdateTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsUpdateTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'export_title': TextNodeFromJSON(json['export_title']),
        'follow_ups_links_only': json['follow_ups_links_only'],
        'name': json['name'],
        'writing_mode': json['writing_mode'],
    };
}

export function PostmortemsUpdateTemplateRequestBodyToJSON(value?: PostmortemsUpdateTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'export_title': TextNodeToJSON(value.export_title),
        'follow_ups_links_only': value.follow_ups_links_only,
        'name': value.name,
        'writing_mode': value.writing_mode,
    };
}

