export const numericGateLimitReached = (
  limit: number | undefined | null,
  current: number,
) => {
  if (limit === undefined || limit == null) {
    // Explicitly do not allow `=== 0` into this branch.
    // An undefined limit is an infinite limit.
    return false;
  }

  return current >= limit;
};
