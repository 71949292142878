import { CatalogConfigType } from "@incident-io/api";
import { CatalogTypeBadge } from "@incident-shared/attribute";
import { BadgeSize } from "@incident-ui";
import {
  CodeSnippet,
  CodeSnippetTheme,
} from "@incident-ui/CodeSnippet/CodeSnippet";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";

export const AlertSourceCloudWatchTaggingInfo = ({
  catalogType,
}: {
  catalogType: CatalogConfigType;
}): React.ReactElement => {
  const suggestedTagKey = catalogType.name.toLowerCase().split(" ").join("-");

  return (
    <div className="p-4 border-stroke-primary border rounded-2">
      <NumberedList>
        <div>
          Add a{" "}
          <CodeSnippet theme={CodeSnippetTheme.White}>
            {suggestedTagKey}
          </CodeSnippet>{" "}
          query parameter to each of your SNS topic endpoints, referencing the
          relevant{" "}
          <CatalogTypeBadge
            type={catalogType.engine_type_name}
            size={BadgeSize.Small}
            className="align-middle"
          />{" "}
          using one of the identifiers below
        </div>
        <div>
          Parse your{" "}
          <CatalogTypeBadge
            type={catalogType.engine_type_name}
            size={BadgeSize.Small}
            className="align-middle"
          />{" "}
          from the query parameters in the alert payload into an alert attribute
          within this alert source
        </div>
      </NumberedList>
    </div>
  );
};
