import {
  Incident,
  IntegrationSettingsProviderEnum as IntegrationProvider,
} from "@incident-io/api";
import { BadgeSize, Button, IconEnum, IconSize, Tooltip } from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";
import { useAPI } from "src/utils/swr";

import { useIncident } from "../hooks";

const MissingCommsPlatformWarning = ({
  commsPlatform,
}: {
  commsPlatform: string;
}) => {
  return (
    <Button
      analyticsTrackingId={null}
      icon={IconEnum.Warning}
      size={BadgeSize.Large}
      disabled
    >{`No ${commsPlatform} channel`}</Button>
  );
};

const TeamsLink = ({
  url,
  channelDeleted,
}: {
  url: string | undefined;
  channelDeleted: boolean;
}) => {
  const isWithinTeamsTab = useIsWithinMicrosoftTeamsTab();

  if (!channelDeleted) {
    return url ? (
      <Button
        size={BadgeSize.Large}
        analyticsTrackingId={"incident-sidebar-teams"}
        href={isWithinTeamsTab ? url.replace("https://", "msteams://") : url}
        icon={IconEnum.MicrosoftTeams}
        openInNewTab
      >
        MS Teams channel
      </Button>
    ) : (
      <MissingCommsPlatformWarning commsPlatform="Microsoft Teams" />
    );
  }

  return (
    <Tooltip content="The Microsoft Teams channel for this incident has been deleted. You may still be able to restore it from within Microsoft Teams.">
      <Button
        size={BadgeSize.Large}
        analyticsTrackingId={"incident-sidebar-teams"}
        icon={IconEnum.MicrosoftTeams}
        disabled
      >
        Teams channel deleted
      </Button>
    </Tooltip>
  );
};

const SlackLink = ({ incident }: { incident: Incident }) => {
  const { identity } = useIdentity();

  const { data: slackTeamConfigData, isLoading } = useAPI(
    incident.slack_team_id ? "slackTeamConfigsGetBySlackTeamID" : null,
    {
      slackTeamId: incident.slack_team_id ?? "",
    },
  );
  const slackTeamName = slackTeamConfigData?.slack_team_config?.name ?? "";

  // User can access Slack channel if no team ids set (not Grid install)
  // or if the incident's slack team id is in the user's slack team ids
  const slackTeamIDs = identity.slack_info?.team_ids || [];
  const userCanAccessSlackChannel =
    slackTeamIDs.length === 0 || slackTeamIDs.includes(incident.slack_team_id);

  if (isLoading) return <LoadingBar />;

  return incident.slack_channel_url ? (
    <>
      {userCanAccessSlackChannel ? (
        <Button
          size={BadgeSize.Large}
          analyticsTrackingId={"incident-sidebar-slack"}
          href={incident.slack_channel_url}
          icon={IconEnum.Slack}
          openInNewTab
          iconProps={{ size: IconSize.Small }}
        >
          Slack channel
        </Button>
      ) : (
        <Tooltip
          content={`You do not have access to this incident's Slack channel as you are not a member of the ${slackTeamName}Slack workspace.`}
        >
          <Button
            size={BadgeSize.Large}
            disabled
            icon={IconEnum.Lock}
            analyticsTrackingId={null}
          >
            Slack Channel
          </Button>
        </Tooltip>
      )}
    </>
  ) : (
    <MissingCommsPlatformWarning commsPlatform="Slack" />
  );
};

export const CommsChannelButton = ({
  incidentId,
}: {
  incidentId: string | null;
}) => {
  const { incident } = useIncident(incidentId);
  const { identity } = useIdentity();

  const commsPlatform = identity.slack_info
    ? IntegrationProvider.Slack
    : IntegrationProvider.MicrosoftTeams;

  if (!incident) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3">
      {commsPlatform === IntegrationProvider.Slack ? (
        <SlackLink incident={incident} />
      ) : (
        <TeamsLink
          url={incident.ms_teams_channel_url}
          channelDeleted={incident.chat_channel_archived_or_deleted || false}
        />
      )}
    </div>
  );
};
