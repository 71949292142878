import {
  IncidentStatus,
  IncidentStatusCategoryEnum,
  InternalStatusPageIncident,
} from "@incident-io/api";
import {
  IncidentUpdates as IncidentUpdatesRender,
  Resolved,
  withSentryErrorBoundary,
} from "@incident-io/status-page-ui";
import { IconEnum } from "@incident-ui";
import { useState } from "react";

import { IconButton } from "./IncidentHeader/IncidentHeader";
import { RequestUpdateModal } from "./RequestUpdate/RequestUpdateModal";

const isActiveOrTriageOrPaused = (status: IncidentStatus) =>
  status.category === IncidentStatusCategoryEnum.Active ||
  status.category === IncidentStatusCategoryEnum.Triage ||
  status.category === IncidentStatusCategoryEnum.Paused;

const IncidentUpdatesReal = ({
  incident,
}: {
  incident: InternalStatusPageIncident;
}) => {
  const hasFurtherUpdates = isActiveOrTriageOrPaused(incident.incident_status);
  const updates = incident.updates.map((update) => ({
    id: update.id,
    status_colour: isActiveOrTriageOrPaused(update.new_incident_status)
      ? ("red" as const)
      : ("green" as const),
    status_name: update.new_incident_status.name,
    message: update.message !== undefined ? update.message : null,
    published_at: update.created_at.toISOString(),
  }));

  const [updateRequested, setUpdateRequested] = useState(false);
  const [showRequestUpdateModal, setShowRequestUpdateModal] = useState(false);

  return (
    <>
      {showRequestUpdateModal ? (
        <RequestUpdateModal
          onClose={() => setShowRequestUpdateModal(false)}
          incidentId={incident.response_incident_id}
          setUpdateRequested={setUpdateRequested}
        />
      ) : undefined}
      <IncidentUpdatesRender
        accessory={
          updateRequested ? (
            <div className="flex items-center text-sm text-slate-800 dark:text-slate-200">
              <Resolved /> Update requested
            </div>
          ) : (
            <IconButton
              icon={IconEnum.Hand}
              title="Request an update"
              onClick={() => setShowRequestUpdateModal(true)}
              iconClassName="mr-1"
              analyticsTrackingId="internal-sp-request-an-update"
            >
              Request an update
            </IconButton>
          )
        }
        hasFurtherUpdates={hasFurtherUpdates}
        // No write-ups for internal incidents, as of now. Should this point to the
        // post-mortem?
        topExtra={null}
        updates={updates}
      />
    </>
  );
};

export const IncidentUpdates = withSentryErrorBoundary(
  IncidentUpdatesReal,
  "IncidentUpdates",
);
