import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GenericErrorMessage } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useParams } from "react-router";
import { IncidentTypesCreateEditForm } from "src/components/settings/incident-types/common/IncidentTypesCreateEditForm";
import { useAPI } from "src/utils/swr";

export const IncidentTypesEditPage = (): React.ReactElement => {
  const { id } = useParams() as { id: string };
  const { data, isLoading, error } = useAPI("incidentTypesShow", {
    id: id,
  });

  if (isLoading) {
    return <FullPageLoader />;
  }
  if (error || !data) {
    return <GenericErrorMessage error={error} />;
  }
  return (
    <IncidentTypesCreateEditForm
      initialData={data.incident_type}
      mode={Mode.Edit}
    />
  );
};
