import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useStoredSession } from "src/components/impersonation/useStoredSession";
import { useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export const useStopImpersonating = () => {
  const { identity } = useIdentity();
  const navigate = useNavigate();
  const { session, setSession } = useStoredSession({
    orgSlug: identity.organisation_slug,
  });

  const apiClient = useClient();
  const orgHeaders = apiClient.orgHeaders;

  const sessionId = session?.id;

  return useCallback(() => {
    // Drop this from localstorage
    setSession(undefined);

    // Update the client to stop sending the impersonation headers
    orgHeaders.current = {
      orgId: identity.actor_organisation_id,
      impersonatingOrgSlug: null,
      impersonationSessionId: null,
    };

    // We need to run this after we've told the API client to stop
    // impersonating.
    if (sessionId) {
      apiClient
        .staffDestroyImpersonationSession({ id: sessionId })
        .catch((e) => {
          console.error("Failed to end impersonation session", e);
        });
    }

    // Navigate back to the main org
    navigate(`/${identity.actor_organisation_slug}`);
  }, [
    apiClient,
    identity.actor_organisation_id,
    identity.actor_organisation_slug,
    navigate,
    orgHeaders,
    sessionId,
    setSession,
  ]);
};
