import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Difference,
  Icon,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
  Steps,
  Txt,
} from "@incident-ui";
import { StepConfig } from "@incident-ui/Steps/Steps";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ScopeNameEnum,
  StatusPage,
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageCreateIncidentRequestBodyStatusEnum,
  StatusPageCreateIncidentUpdateRequestBody,
  StatusPageCreateIncidentUpdateRequestBodyToStatusEnum,
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
  StatusPageIncidentTypeEnum,
  StatusPageIncidentUpdate,
  StatusPagePageTypeEnum,
  StatusPageStructure,
  StatusPageTemplate,
  TextNode,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useStatusPageSubscriptionCount } from "../../../common/hooks/use-status-page-subscription-count";
import { ManualSubPageFormData } from "../../../common/modals/PublishLiveIncidentModal";
import { StatusInput } from "../../../common/StatusInput";
import { SubPageReviewInfo } from "../../../common/SubPageReviewInfo";
import { useTemplatedMessageInput } from "../../../common/utils/template-helpers";
import { AddComponentOrGroup } from "../../common/AddComponentOrGroup";
import {
  SubPageEditor,
  SubPageEditorWarning,
} from "../../common/SubPageEditor";
import { useSubPageData } from "../../hooks/use-sub-page-data";
import {
  currentComponentStatusesForIncident,
  INCIDENT_STATUS_CONFIG,
  MAINTENANCE_STATUS_CONFIG,
} from "../../utils/utils";
import {
  AffectedComponentsEditor,
  AffectedComponentsFormData,
  affectedComponentsFormStateToPayload,
  affectedComponentsReviewInfo,
} from "../../view/AffectedComponentsEditor";

type FormData = Pick<
  StatusPageCreateIncidentUpdateRequestBody,
  "sort_key" | "to_status" | "created_from_template_id"
> & {
  message: unknown;
  notify_subscribers: boolean;
} & AffectedComponentsFormData &
  ManualSubPageFormData;

enum steps {
  Info = "info",
  Review = "review",
}

const stepConfig: StepConfig<steps>[] = [
  {
    id: steps.Info,
    name: "Update info",
  },
  {
    id: steps.Review,
    name: "Review & publish",
  },
];

const modalProps = {
  isOpen: true,
  isExtraLarge: true,
  title: "Publish an update",
  analyticsTrackingId: "status-page-publish-incident-update",
};

export const StatusPageIncidentCreateUpdateModal = ({
  page,
  structure,
  templates,
  incident,
}: {
  page: StatusPage | null;
  structure: StatusPageStructure | null;
  templates: StatusPageTemplate[];
  incident: StatusPageIncident | null;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const onClose = () =>
    navigate(`/status-pages/${page?.id}/incident/${incident?.id}`);

  if (!page || !structure || !incident) {
    return <Modal {...modalProps} loading onClose={onClose} />;
  }

  if (!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)) {
    return (
      <Modal {...modalProps} onClose={onClose} disableQuickClose={false}>
        <ModalContent>
          Sorry, you don&apos;t have permission to update incidents to your
          organisation&apos;s public status page.
        </ModalContent>
        <ModalFooter onClose={onClose} hideConfirmButton />
      </Modal>
    );
  }

  return (
    <StatusPagePublishIncidentUpdateInner
      page={page}
      structure={structure}
      onClose={onClose}
      templates={templates}
      incident={incident}
    />
  );
};

const StatusPagePublishIncidentUpdateInner = ({
  page,
  structure,
  templates,
  onClose,
  incident,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  onClose: () => void;
  templates: StatusPageTemplate[];

  incident: StatusPageIncident;
}): React.ReactElement => {
  const lastUpdate = _.last(
    incident.updates.filter((u) => !u.creator.integration), // ignore automated status changes.
  );

  const templateContentFor = useCallback(
    (status: StatusPageIncidentStatusEnum) => {
      return templates?.find(
        (template) =>
          (template.message_template_config
            ?.message_content as unknown as StatusPageIncidentStatusEnum) ===
          status,
      )?.message_template_config?.message_content;
    },
    [templates],
  );
  const formMethods = useForm<FormData>({
    defaultValues: {
      to_status:
        incident.status as unknown as StatusPageCreateIncidentUpdateRequestBodyToStatusEnum,
      component_statuses: currentComponentStatusesForIncident(incident),
      sort_key: incident.next_update_sort_key,
      notify_subscribers:
        page.active_subscriber_count === 0
          ? false
          : lastUpdate
          ? !lastUpdate.suppress_notifications
          : true,
      message: templateContentFor(incident.status),
      manual_sub_pages_enabled: incident.sub_page_mode === "manual",
      manual_sub_page_ids: incident.sub_pages.map((page) => page.id),
    },
  });

  const navigate = useOrgAwareNavigate();

  const [currentStep, setCurrentStep] = useState(steps.Info);

  const {
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = formMethods;
  const toStatus = watch("to_status");

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient, data: FormData) => {
      const { to_status, sort_key, message, notify_subscribers } = data;

      const payload = {
        status_page_incident_id: incident.id,
        to_status,
        sort_key,
        message: message as TextNode,
        component_statuses: affectedComponentsFormStateToPayload(
          {
            component_statuses: componentStatuses(incident, data),
          },
          incident,
        ),
        suppress_notifications: !notify_subscribers,
        manual_status_update:
          incident.status.toString() !== toStatus.toString() &&
          incident.automate_maintenance_status,
        manual_sub_page_ids:
          // If the incident was in manual mode, it can't be change, so we should
          // always send along the sub-page IDs, even if they somehow managed
          // to change the mode (which should be impossible).
          incident.sub_page_mode === "manual" || data.manual_sub_pages_enabled
            ? data.manual_sub_page_ids
            : undefined,
        created_from_template_id: data.created_from_template_id,
      };

      const res = await apiClient.statusPageCreateIncidentUpdate({
        createIncidentUpdateRequestBody: payload,
      });

      navigate(
        `/status-pages/${page.id}/incident/${res.status_page_incident.id}`,
      );

      // Return nothing to revalidate the list
      return;
    },
    {
      setError: formMethods.setError,
      onError: () => setCurrentStep(steps.Info),
    },
  );

  const { mutate: refetchIncident } = useAPI("statusPageShowIncident", {
    id: incident.id,
  });

  const isRace = errors.sort_key !== undefined;
  const [showRaceModal, setShowRaceModal] = useState(false);
  useEffect(() => {
    if (isRace) {
      // Trigger a refetch so the update appears in the background
      refetchIncident(undefined, { revalidate: true });
      setShowRaceModal(true);
    }
  }, [isRace, refetchIncident]);

  return (
    <>
      <RaceModal
        isOpen={showRaceModal}
        onClose={() => {
          setValue("sort_key", getValues("sort_key") + 1);
          clearErrors("sort_key");
          setShowRaceModal(false);
        }}
        onRetry={() => {
          handleSubmit(onSubmit)();
        }}
      />

      <Form.Modal
        {...modalProps}
        disableQuickClose
        formMethods={formMethods}
        genericError={genericError}
        onSubmit={
          currentStep === steps.Review
            ? onSubmit
            : async () => {
                const isValid = await formMethods.trigger();

                if (isValid) {
                  setCurrentStep(steps.Review);
                }
              }
        }
        saving={saving}
        onClose={onClose}
        footer={
          <ModalFooter
            onClose={
              currentStep === steps.Info
                ? onClose
                : () => setCurrentStep(steps.Info)
            }
            cancelButtonText={currentStep === steps.Info ? "Cancel" : "Back"}
            confirmButtonType="submit"
            confirmButtonText={
              currentStep === steps.Info
                ? "Review update"
                : toStatus ===
                  StatusPageCreateIncidentUpdateRequestBodyToStatusEnum.Resolved
                ? "Resolve incident"
                : "Publish update"
            }
          />
        }
      >
        <Steps steps={stepConfig} currentStep={currentStep} />
        {currentStep === steps.Info ? (
          <UpdateForm
            page={page}
            structure={structure}
            incident={incident}
            templates={templates}
          />
        ) : (
          <ReviewInfo page={page} structure={structure} incident={incident} />
        )}
      </Form.Modal>
    </>
  );
};

const RaceModal = ({
  isOpen,
  onClose,
  onRetry,
}: {
  isOpen: boolean;
  onClose: () => void;
  onRetry: () => void;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      analyticsTrackingId="status-page-update-race"
      title="Are you sure?"
      disableQuickClose
      onClose={onClose}
    >
      <ModalContent>
        Someone else has posted an update to this incident whilst you&apos;ve
        been writing this. Would you still like to post this update?
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        cancelButtonText="Go back"
        onConfirm={() => {
          onClose();
          onRetry();
        }}
        confirmButtonType="button"
        confirmButtonText="Post anyway"
      />
    </Modal>
  );
};

const UpdateForm = ({
  page,
  structure,
  templates,
  incident,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  templates: StatusPageTemplate[];
  incident: StatusPageIncident;
}): React.ReactElement => {
  const formMethods = useFormContext<FormData>();
  const { setValue, watch } = formMethods;
  const [componentStatuses, toStatus, manualSubPagesEnabled] = watch([
    "component_statuses",
    "to_status",
    "manual_sub_pages_enabled",
  ]);

  const editingSubPages =
    manualSubPagesEnabled || incident.sub_page_mode === "manual";

  const { renderTemplateSelect } = useTemplatedMessageInput<
    FormData,
    "to_status",
    "to_status"
  >({
    formMethods,
    page,
    templates,
    statusPath: "to_status",
    messagePath: "message",
    templateIdPath: "created_from_template_id",
  });

  const isResolving =
    toStatus ===
      StatusPageCreateIncidentUpdateRequestBodyToStatusEnum.Resolved ||
    toStatus ===
      StatusPageCreateIncidentUpdateRequestBodyToStatusEnum.MaintenanceComplete;

  const selectedSubPageIDs = formMethods.watch("manual_sub_page_ids");

  const { catalogType, affectedPages } = useSubPageData(
    page,
    componentStatuses,
    selectedSubPageIDs,
  );

  return (
    <div className="space-y-4">
      {renderTemplateSelect()}
      <StatusInput
        formMethods={formMethods}
        name="to_status"
        label="Status"
        maintenance={incident.type === StatusPageIncidentTypeEnum.Maintenance}
      />
      {incident.status.toString() !== toStatus.toString() &&
        incident.automate_maintenance_status &&
        !incident.maintenance_status_manually_updated && (
          <Callout theme={CalloutTheme.Warning}>
            Manually updating the maintenance window status will turn off status
            automation. If you continue, you&apos;ll need to manually update the
            maintenance window&apos;s status from then on.
          </Callout>
        )}
      <TemplatedTextInputV2
        includeVariables={false}
        includeExpressions={false}
        formMethods={formMethods}
        name={"message"}
        label="Message"
        placeholder="What is the suspected impact right now?"
        required="You must set a message"
        helptext={
          page.page_type === StatusPagePageTypeEnum.Standalone
            ? "We'll show this on the status page for this incident."
            : "We’ll show this on each sub-page impacted by this incident."
        }
        format="mrkdwn"
        multiLine
      />
      {isResolving ? (
        <p className="text-sm text-content-tertiary">
          All components will be marked as no longer impacted by this incident.
          Impacts from other incidents will not be changed.
        </p>
      ) : (
        <div>
          <AffectedComponentsEditor<
            FormData,
            "component_statuses",
            "component_statuses"
          >
            structure={structure}
            incident={incident}
            maintenance={
              incident.type === StatusPageIncidentTypeEnum.Maintenance
            }
            formMethods={formMethods}
            fieldNamePrefix="component_statuses"
          />
          <AddComponentOrGroup
            className="w-1/3 mt-2"
            structure={structure}
            selectedComponentIds={new Set(Object.keys(componentStatuses))}
            onAddComponent={(componentId) => {
              if (!Object.hasOwn(componentStatuses, componentId)) {
                setValue(
                  `component_statuses.${componentId}`,
                  ComponentStatusEnum.Operational,
                );
              }
            }}
          />
        </div>
      )}
      {page.page_type !== StatusPagePageTypeEnum.Standalone && (
        <div className="space-y-2">
          <div className="flex justify-between">
            <Txt bold>Affected sub-pages</Txt>
            {incident.sub_page_mode !== "manual" && !manualSubPagesEnabled && (
              <Button
                onClick={() => {
                  formMethods.setValue<"manual_sub_pages_enabled">(
                    "manual_sub_pages_enabled",
                    true,
                  );
                  formMethods.setValue(
                    `manual_sub_page_ids`,
                    affectedPages.map((page) => page.id),
                  );
                }}
                analyticsTrackingId={"edit-sub-pages"}
                theme={ButtonTheme.Naked}
                icon={IconEnum.Edit}
              >
                Edit
              </Button>
            )}
          </div>
          <>
            {editingSubPages ? (
              // show a warning that the user is on their own and that they'll
              // be responsible for manually updating the sub-pages going forward
              // and then render a list of checkboxes to enable/disable each page,
              // defaulting to enabling the current list of affected pages.
              <>
                {incident.sub_page_mode === "manual" ? (
                  <Form.Helptext>
                    This incident is using manually managed sub-pages.
                    incident.io will not automatically infer sub-pages for this
                    incident. Removing any sub-page from the list will unpublish
                    the incident from that page.
                  </Form.Helptext>
                ) : (
                  <SubPageEditorWarning affectedPages={affectedPages} />
                )}

                <SubPageEditor page={page} />
              </>
            ) : affectedPages.length === 0 ? (
              <div className="bg-white p-4 shadow-sm rounded-2 border border-stroke text-sm">
                This incident will not be visible on any{" "}
                {page.page_type === StatusPagePageTypeEnum.Parent
                  ? "sub-pages"
                  : "customer pages"}
                . Change the affected components to ensure that it is visible to
                your users.
              </div>
            ) : (
              <div className="flex items-center flex-wrap gap-2 rounded-2 shadow p-4 bg-white">
                {affectedPages.map((page) => (
                  <CatalogEntryBadge
                    key={page.id}
                    color={catalogType?.color}
                    icon={catalogType?.icon}
                    label={page.name}
                  />
                ))}
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

const componentStatuses = (
  incident: StatusPageIncident,
  formState: Pick<FormData, "to_status" | "component_statuses">,
): Record<string, ComponentStatusEnum> => {
  const isResolving =
    formState.to_status ===
      StatusPageCreateIncidentUpdateRequestBodyToStatusEnum.Resolved ||
    formState.to_status ===
      StatusPageCreateIncidentUpdateRequestBodyToStatusEnum.MaintenanceComplete;

  const lastUpdate = _.maxBy(
    incident.updates,
    "sort_key",
  ) as StatusPageIncidentUpdate;

  return isResolving
    ? lastUpdate.component_statuses.reduce(
        (acc, { component_id }) =>
          Object.assign(acc, {
            [component_id]: ComponentStatusEnum.Operational,
          }),
        {},
      )
    : formState.component_statuses;
};

const ReviewInfo = ({
  page,
  structure,
  incident,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  incident: StatusPageIncident;
}): React.ReactElement => {
  const formMethods = useFormContext<FormData>();
  const [
    toStatus,
    message,
    formComponentStatuses,
    notifySubscribers,
    manualSubPageIds,
  ] = formMethods.watch([
    "to_status",
    "message",
    "component_statuses",
    "notify_subscribers",
    "manual_sub_page_ids",
  ]);

  const lastUpdate = _.last(
    incident.updates.filter((u) => !u.creator.integration), // ignore automated status changes.
  );

  const componentsToShow = affectedComponentsReviewInfo(
    structure,
    componentStatuses(incident, {
      to_status: toStatus,
      component_statuses: formComponentStatuses,
    }),
    incident,
  );

  const statuses =
    incident.type === StatusPageIncidentTypeEnum.Incident
      ? INCIDENT_STATUS_CONFIG
      : MAINTENANCE_STATUS_CONFIG;

  const activeSubscriberCount = useStatusPageSubscriptionCount({
    statusPageId: page.id,
    statusPageIncidentId: incident.id,
    componentIds: componentsToShow
      .filter((c) => c.status !== ComponentStatusEnum.Operational)
      .map((c) => c.componentId),
    subPageIds: manualSubPageIds,
  });

  return (
    <>
      <p className="text-sm mb-4">
        Review the information below and hit the{" "}
        <strong>
          {toStatus ===
          StatusPageCreateIncidentUpdateRequestBodyToStatusEnum.Resolved
            ? "Resolve incident"
            : "Publish update"}
        </strong>{" "}
        button when you&apos;re ready.
      </p>
      <ContentBox className="p-4 text-sm grid grid-cols-3 gap-4">
        <div className="text-content-tertiary">Status page</div>
        <div className="col-span-2">{page.name}</div>

        <div className="text-content-tertiary">Incident name</div>
        <div className="col-span-2">{incident.name}</div>

        <div className="text-content-tertiary">Status</div>
        <div className="col-span-2">
          <Difference
            prev={
              statuses[
                incident.status as unknown as StatusPageCreateIncidentRequestBodyStatusEnum
              ].label
            }
            next={statuses[toStatus].label}
          />
        </div>

        <div className="text-content-tertiary">Message</div>
        <div className="col-span-2">
          <TemplatedTextDisplay
            value={message as TextNode}
            style={TemplatedTextDisplayStyle.Compact}
          />
        </div>

        <div className="text-content-tertiary">Components</div>
        <div className="col-span-2 space-y-2">
          {componentsToShow.length > 0 ? (
            componentsToShow.map(
              ({ componentId, componentName, status, prevStatus }) => {
                const statusConfig = COMPONENT_STATUS_CONFIG[status];

                return (
                  <div
                    key={componentId}
                    className="flex flex-col lg:flex-row lg:space-x-3"
                  >
                    <div className="flex space-x-1">
                      <Icon
                        id={statusConfig.icon}
                        className={statusConfig.colour}
                      />
                      <span>{componentName}</span>
                    </div>
                    <span className="text-content-tertiary">
                      <Difference
                        prev={
                          prevStatus &&
                          COMPONENT_STATUS_CONFIG[prevStatus]?.label
                        }
                        next={COMPONENT_STATUS_CONFIG[status]?.label}
                      />
                    </span>
                  </div>
                );
              },
            )
          ) : (
            <div className="flex space-x-1">
              <Icon
                id={
                  COMPONENT_STATUS_CONFIG[ComponentStatusEnum.Operational].icon
                }
                className={
                  COMPONENT_STATUS_CONFIG[ComponentStatusEnum.Operational]
                    .colour
                }
              />
              <p>No impacted components</p>
            </div>
          )}
        </div>

        <SubPageReviewInfo page={page} incident={incident} />
      </ContentBox>
      {!page.subscriptions_disabled &&
        activeSubscriberCount !== undefined &&
        activeSubscriberCount > 0 && (
          <div className="space-y-2">
            <CheckboxV2
              label="Notify subscribers"
              name="notify_subscribers"
              formMethods={formMethods}
            />
            {!notifySubscribers ? (
              <Callout theme={CalloutTheme.Info}>
                We won&apos;t notify any of your subscribers. Future updates
                will default to not notifying subscribers too.
              </Callout>
            ) : lastUpdate?.suppress_notifications ? (
              <Callout theme={CalloutTheme.Warning}>
                <span className="font-semibold">
                  Your last update was not sent to subscribers
                </span>
                . You&apos;ve opted in to notifications for this update, so
                we&apos;ll notify {page.active_subscriber_count} subscribers.
              </Callout>
            ) : (
              <Callout theme={CalloutTheme.Info}>
                This update will notify {activeSubscriberCount.toLocaleString()}{" "}
                subscriber{activeSubscriberCount === 1 ? "" : "s"}.
              </Callout>
            )}
          </div>
        )}
    </>
  );
};
