/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FollowUp,
    FollowUpFromJSON,
    FollowUpFromJSONTyped,
    FollowUpToJSON,
} from './FollowUp';

/**
 * 
 * @export
 * @interface FollowUpsShowResponseBody
 */
export interface FollowUpsShowResponseBody {
    /**
     * 
     * @type {FollowUp}
     * @memberof FollowUpsShowResponseBody
     */
    follow_up: FollowUp;
}

export function FollowUpsShowResponseBodyFromJSON(json: any): FollowUpsShowResponseBody {
    return FollowUpsShowResponseBodyFromJSONTyped(json, false);
}

export function FollowUpsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'follow_up': FollowUpFromJSON(json['follow_up']),
    };
}

export function FollowUpsShowResponseBodyToJSON(value?: FollowUpsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'follow_up': FollowUpToJSON(value.follow_up),
    };
}

