import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  GenericErrorMessage,
  IconEnum,
  Loader,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";

import { useAPI } from "../../utils/swr";

export const MSTeamsPreviewPage = () => {
  const { data, isLoading, error } = useAPI(
    "mSTeamsPreviewsList",
    {},
    {
      fallbackData: { templates: [] },
    },
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  // We don't yet have multiple categories, so we're just grouping by theme
  const themes = uniq(data.templates.map((t) => t.theme));
  const templatesByTheme = groupBy(data.templates, (t) => t.theme);

  return (
    <PageWrapper
      title="MS Teams Message Previews"
      width={PageWidth.Narrow}
      icon={IconEnum.Message}
    >
      <div className="h-full p-6 space-y-3">
        {themes.map((theme) => (
          <div key={theme}>
            <h2>{theme}</h2>
            <ul>
              {templatesByTheme[theme || ""].map((t) => (
                <li className="ml-3" key={t.name}>
                  <OrgAwareLink
                    className="hover:underline"
                    analyticsTrackingId=""
                    to={`/msteams-previews/${t.name}`}
                  >
                    <Txt>{t.readableName}</Txt>
                  </OrgAwareLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </PageWrapper>
  );
};
