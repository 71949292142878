/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraChangelogItem,
    JiraChangelogItemFromJSON,
    JiraChangelogItemFromJSONTyped,
    JiraChangelogItemToJSON,
} from './JiraChangelogItem';

/**
 * 
 * @export
 * @interface JiraChangelog
 */
export interface JiraChangelog {
    /**
     * list of changes
     * @type {Array<JiraChangelogItem>}
     * @memberof JiraChangelog
     */
    items?: Array<JiraChangelogItem>;
}

export function JiraChangelogFromJSON(json: any): JiraChangelog {
    return JiraChangelogFromJSONTyped(json, false);
}

export function JiraChangelogFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraChangelog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JiraChangelogItemFromJSON)),
    };
}

export function JiraChangelogToJSON(value?: JiraChangelog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JiraChangelogItemToJSON)),
    };
}

