/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProviderAndContexts
 */
export interface ProviderAndContexts {
    /**
     * The contexts available for the provider
     * @type {Array<string>}
     * @memberof ProviderAndContexts
     */
    contexts: Array<ProviderAndContextsContextsEnum>;
    /**
     * Whether or not this provider is available for the current organisation
     * @type {boolean}
     * @memberof ProviderAndContexts
     */
    is_available: boolean;
    /**
     * The issue tracker provider
     * @type {string}
     * @memberof ProviderAndContexts
     */
    provider: ProviderAndContextsProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum ProviderAndContextsContextsEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}/**
* @export
* @enum {string}
*/
export enum ProviderAndContextsProviderEnum {
    Asana = 'asana',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function ProviderAndContextsFromJSON(json: any): ProviderAndContexts {
    return ProviderAndContextsFromJSONTyped(json, false);
}

export function ProviderAndContextsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderAndContexts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contexts': json['contexts'],
        'is_available': json['is_available'],
        'provider': json['provider'],
    };
}

export function ProviderAndContextsToJSON(value?: ProviderAndContexts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contexts': value.contexts,
        'is_available': value.is_available,
        'provider': value.provider,
    };
}

