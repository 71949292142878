import { WorkflowSlimWorkflowStateEnum } from "@incident-io/api";
import { ExpressionsDropdown } from "@incident-shared/engine/expressions/ExpressionsDropdown";
import { TitleInputV2 } from "@incident-shared/forms/v2/inputs/TitleInputV2";
import { isTerraform } from "@incident-shared/management-meta/utils";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { createPortal } from "react-dom";
import { useFormContext } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";

import { WorkflowFormData } from "../../common/types";
import { getExpressionUsages } from "../../common/utils";
import { WorkflowsStatePill } from "../../common/WorkflowsStatePill";
import { WorkflowsDisableDropdownItem } from "../../list/WorkflowsDisableDropdownItem";
import { useWorkflowsMutations } from "../hooks/useMutationController";
import { useWorkflowsSteps } from "../hooks/useStepsController";
import { useWorkflowsTrigger } from "../hooks/useTriggerController";
import {
  useWorkflowsViewState,
  WorkflowDrawerState,
  WorkflowModalState,
} from "../hooks/useViewController";
import { useWorkflowsDeps } from "../WorkflowsFormContext";

export const WorkflowsCreateEditHeader = ({
  buttonsRef,
  titleInputRef,
  domReady,
}: {
  buttonsRef: React.RefObject<HTMLDivElement>;
  titleInputRef: React.RefObject<HTMLDivElement>;
  domReady: boolean;
}) => {
  const formMethods = useFormContext<WorkflowFormData>();
  const { watch } = formMethods;

  const { hasOpenDrawer, onOpenDrawer: onOpenDrawer } = useWorkflowsViewState();

  const { workflow, trigger, scope, isDraft, management, isMainFormDirty } =
    useWorkflowsDeps();
  const [conditionGroups, stepGroups] = watch([
    "condition_groups",
    "step_groups",
  ]);

  const isManagedByTerraform = isTerraform(management);

  const isActivityButtonDisabled = hasOpenDrawer;

  return (
    <div>
      <TitleInputV2
        name={"name"}
        formMethods={formMethods}
        placeholder="Workflow title"
        inputRef={titleInputRef}
        textCursor
        suffixNode={
          <WorkflowsStatePill
            state={
              (workflow?.state ||
                "draft") as unknown as WorkflowSlimWorkflowStateEnum
            }
            isDisabling={false}
            isEnabling={false}
          />
        }
      />

      {domReady &&
        createPortal(
          <div className="flex flex-row gap-2">
            {/* Extra div so there's no gap between expressions and activity */}
            <div className="flex">
              <ExpressionsDropdown
                disabled={!trigger}
                disabledExplanation={
                  !trigger
                    ? "Please choose a trigger before adding expressions"
                    : undefined
                }
                scopeWithExpressions={scope || []}
                getExpressionUsages={(expression) =>
                  getExpressionUsages(expression, stepGroups, conditionGroups)
                }
                returnMultipleItemsCheckboxHelptext={
                  "Please note that not all workflow steps can receive multiple values."
                }
              />
              {workflow?.id && !isDraft && (
                <Button
                  onClick={() => onOpenDrawer(WorkflowDrawerState.ViewActivity)}
                  disabled={isActivityButtonDisabled}
                  analyticsTrackingId="workflows-v2-view-activity-button"
                  theme={ButtonTheme.Ghost}
                  icon={IconEnum.History}
                >
                  Activity
                </Button>
              )}
            </div>
            {workflow?.id && <ActionsOverflow />}
            {isManagedByTerraform && (
              <Button
                analyticsTrackingId="workflows-sync-with-terraform"
                icon={IconEnum.Terraform}
                theme={ButtonTheme.Primary}
                onClick={() =>
                  onOpenDrawer(WorkflowDrawerState.ExportToTerraform)
                }
                disabled={!isMainFormDirty}
              >
                Export Changes
              </Button>
            )}
            <SaveButton />
            {isDraft && workflow?.id && <PublishButton />}
          </div>,

          // TS doesn't know we've waited til this is definitely populated
          buttonsRef.current as unknown as Element,
        )}
    </div>
  );
};

const SaveButton = (): React.ReactElement | null => {
  const { hasOpenDrawer, onOpenModal } = useWorkflowsViewState();

  const {
    updateWorkflow,
    createWorkflow,
    state: { isUpdating, isCreating },
  } = useWorkflowsMutations();

  const { handleSubmit } = useFormContext<WorkflowFormData>();
  const { hasSteps } = useWorkflowsSteps();
  const { trigger, canEdit, isDraft, workflow, management } =
    useWorkflowsDeps();
  const { isManualTrigger } = useWorkflowsTrigger();

  let tooltipContent: string | undefined = undefined;
  let canSave = true;
  if (!trigger) {
    tooltipContent = "Please choose a trigger before saving";
    canSave = false;
  }
  if (!hasSteps && !isDraft) {
    tooltipContent = "Please add at least one step before saving";
    canSave = false;
  }
  if (!canEdit) {
    tooltipContent = "You do not have permission to set this workflow live";
    canSave = false;
  }

  const isSaveButtonDisabled = !canSave || hasOpenDrawer;

  const onSave = async () => {
    if (!workflow || !workflow.id) {
      handleSubmit(createWorkflow)();
    } else if (isDraft) {
      // If we're saving a draft, we can just save
      handleSubmit(updateWorkflow)();
    } else if (!!workflow && !workflow?.delay_for_seconds) {
      // If we're editing and there's no delay, we can save without showing options
      handleSubmit(updateWorkflow)();
    } else if (isManualTrigger) {
      // If it's a manual trigger, we can save without showing options
      handleSubmit(updateWorkflow)();
    } else {
      // Otherwise we show the deploy options
      onOpenModal(WorkflowModalState.DeploySettings);
    }
  };

  if (isTerraform(management)) {
    // If you're already managing this with terraform, you can't Save here!
    return null;
  }

  return (
    <Tooltip content={tooltipContent}>
      <Button
        theme={
          isDraft && workflow?.id ? ButtonTheme.Secondary : ButtonTheme.Primary
        }
        type="submit"
        analyticsTrackingId="workflows-v2-submit"
        onClick={onSave}
        disabled={isSaveButtonDisabled}
        loading={isUpdating || isCreating}
      >
        {isDraft ? "Save as draft" : "Save"}
      </Button>
    </Tooltip>
  );
};

const PublishButton = (): React.ReactElement | null => {
  const { hasOpenDrawer, onOpenModal } = useWorkflowsViewState();

  const {
    publishWorkflow,
    state: { isPublishing },
  } = useWorkflowsMutations();

  const { handleSubmit } = useFormContext<WorkflowFormData>();
  const { hasSteps } = useWorkflowsSteps();
  const { isManualTrigger } = useWorkflowsTrigger();
  const { trigger, canEdit, management } = useWorkflowsDeps();
  const apiClient = useClient();

  let tooltipContent: string | undefined = undefined;
  let canPublish = true;
  if (!trigger) {
    tooltipContent = "Please choose a trigger setting live";
    canPublish = false;
  }
  if (!hasSteps) {
    tooltipContent = "Please add at least one step before setting live";
    canPublish = false;
  }
  if (!canEdit) {
    tooltipContent = "You do not have permission to set this workflow live";
    canPublish = false;
  }

  const isPublishButtonDisabled = !canPublish || hasOpenDrawer;

  const onPublish = async () => {
    // If this is the first time we're publishing the workflow,
    // just publish if there are no active incidents, otherwise show options
    const resp = await apiClient.incidentsList({
      statusCategory: { one_of: ["active"] },
    });
    if (resp.incidents.length === 0) {
      handleSubmit(publishWorkflow)();
    } else if (isManualTrigger) {
      // If it's a manual trigger, we can save without showing options
      handleSubmit(publishWorkflow)();
    } else {
      onOpenModal(WorkflowModalState.DeploySettings);
    }
  };

  if (isTerraform(management)) {
    // If you're already managing this with terraform, you can't Publish here!
    return null;
  }

  return (
    <Tooltip content={tooltipContent}>
      <Button
        theme={ButtonTheme.Primary}
        onClick={onPublish}
        analyticsTrackingId="workflows-v2-publish-workflow-button"
        loading={isPublishing}
        disabled={isPublishButtonDisabled}
      >
        Save and set live
      </Button>
    </Tooltip>
  );
};

const ActionsOverflow = (): React.ReactElement | null => {
  const {
    hasOpenDrawer,
    onOpenModal,
    onOpenDrawer: onOpenDrawer,
  } = useWorkflowsViewState();
  const {
    state: { isDeleting, isEnabling },
    enableWorkflow,
    disableWorkflow,
  } = useWorkflowsMutations();

  const { workflow, management, isMainFormDirty, canEdit, isDraft } =
    useWorkflowsDeps();

  const isManagedViaTerraform = isTerraform(management);

  const isDisableButtonDisabled =
    hasOpenDrawer || isMainFormDirty || !canEdit || isManagedViaTerraform;
  const isEnableButtonDisabled =
    hasOpenDrawer || isMainFormDirty || !canEdit || isManagedViaTerraform;
  const isTestButtonDisabled = hasOpenDrawer || isMainFormDirty;
  const isDeleteButtonDisabled =
    hasOpenDrawer || isDeleting || !canEdit || isManagedViaTerraform;

  // If we have no actual workflow, none of these actions are going to work
  if (!workflow?.id) return null;

  let disabledTooltipContent: string | undefined = undefined;
  if (!canEdit) {
    disabledTooltipContent =
      "You do not have permission to perform this action";
  } else if (isMainFormDirty) {
    disabledTooltipContent = "Please save your changes";
  } else if (isManagedViaTerraform) {
    disabledTooltipContent =
      "This workflow is managed via Terraform, and cannot be changed here";
  }

  return (
    <DropdownMenu
      align="end"
      triggerButton={
        <Button
          analyticsTrackingId={"workflows-v2-more-options"}
          title={"More options"}
          icon={IconEnum.DotsVerticalNopad}
          iconProps={{ size: IconSize.Large }}
          theme={ButtonTheme.Secondary}
        />
      }
    >
      {!isManagedViaTerraform && (
        <DropdownMenuItem
          onSelect={() => onOpenDrawer(WorkflowDrawerState.ExportToTerraform)}
          analyticsTrackingId="workflows-sync-with-terraform"
          label={"Export"}
          icon={IconEnum.Terraform}
          iconProps={{ className: "!w-6 !h-6" }}
        />
      )}
      {!isDraft && (
        <>
          <DropdownMenuItem
            onSelect={() => onOpenModal(WorkflowModalState.ConfigureTest)}
            analyticsTrackingId="workflows-v2-test-workflow-button"
            label={"Test"}
            icon={IconEnum.Test}
            iconProps={{ className: "!w-6 !h-6" }}
            disabled={isTestButtonDisabled}
            tooltipContent={
              isMainFormDirty ? "Please save your changes" : undefined
            }
          />
          {workflow.disabled_at ? (
            <DropdownMenuItem
              onSelect={enableWorkflow}
              analyticsTrackingId={"workflows-v2-enable-workflow-button"}
              label={"Enable"}
              icon={IconEnum.ToggleRight}
              iconProps={{ className: "!w-6 !h-6" }}
              disabled={isEnabling || isEnableButtonDisabled}
              tooltipContent={disabledTooltipContent}
            />
          ) : (
            <WorkflowsDisableDropdownItem
              onDisable={disableWorkflow}
              workflowId={workflow.id}
              workflowName={workflow.name}
              disabled={isEnabling || isDisableButtonDisabled}
              disabledTooltipContent={disabledTooltipContent}
            />
          )}
        </>
      )}

      <DropdownMenuItem
        onSelect={() => onOpenModal(WorkflowModalState.DeleteConfirmation)}
        analyticsTrackingId={"workflows-v2-delete-workflow-button"}
        label={"Delete"}
        icon={IconEnum.Delete}
        iconProps={{ className: "!w-6 !h-6" }}
        disabled={isDeleteButtonDisabled}
        tooltipContent={
          isDeleteButtonDisabled ? disabledTooltipContent : undefined
        }
      />
    </DropdownMenu>
  );
};
