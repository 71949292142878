import type { SVGProps } from "react";
import * as React from "react";
const SvgMerge = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.5 8c0 3.032 2.467 5.5 5.5 5.5a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h5.684A6.986 6.986 0 0 1 6 8a.75.75 0 0 1 1.5 0Z"
    />
    <path
      fill="currentColor"
      d="M12.5 14.25a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM4 5.75a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z"
    />
  </svg>
);
export default SvgMerge;
