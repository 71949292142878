import { CatalogGenerateZipForTypesRequestBody } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme, Callout, CalloutTheme, Txt } from "@incident-ui";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import pluralize from "pluralize";
import { FieldValues, Path, useController, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useInsertIntoSWRCache } from "src/utils/swr";

import { CatalogFixedFooter } from "../common/CatalogFixedFooter";
import { CatalogTypeCheckboxGroup } from "./CatalogTypeCheckboxGroup";
import {
  ManageInGithubStepConfig,
  ManageInGitHubStepEnum,
} from "./ManageInGithubRoute";

export const SelectTypesStep = () => {
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<CatalogGenerateZipForTypesRequestBody>({
    defaultValues: {
      catalog_type_ids: [],
    },
  });

  const apiClient = useClient();
  const insertIntoSWRCache = useInsertIntoSWRCache(
    "catalogShowGeneratedRepository",
  );
  const [requestZip, { saving: requestingZip, genericError: requestZipError }] =
    useMutation(
      async (data: CatalogGenerateZipForTypesRequestBody) => {
        const resp = await apiClient.catalogGenerateZipForTypes({
          generateZipForTypesRequestBody: data,
        });

        const repoId = resp.catalog_type_generated_repository.id;
        insertIntoSWRCache(
          { id: repoId },
          {
            generated_repository: resp.catalog_type_generated_repository,
          },
        );
        navigate(`/catalog/manage-in-github/${repoId}/download`);
      },
      {
        setError: formMethods.setError,
      },
    );

  const selectedTypes = formMethods.watch("catalog_type_ids");

  return (
    <>
      <NakedSteps
        steps={ManageInGithubStepConfig}
        currentStepID={ManageInGitHubStepEnum.SelectTypes}
        color={ColorPaletteEnum.Slate}
      />
      <div className="flex flex-col pt-6 w-full mx-auto">
        <SelectTypesStepHeading />
        <Form.Root
          formMethods={formMethods}
          onSubmit={requestZip}
          genericError={requestZipError}
        >
          <CatalogTypeList name="catalog_type_ids" />
        </Form.Root>
      </div>
      <CatalogFixedFooter>
        <Txt className="text-slate-600">
          {selectedTypes && selectedTypes.length > 0
            ? pluralize("custom type", selectedTypes.length, true)
            : "No custom types"}{" "}
          selected
        </Txt>
        <Button
          analyticsTrackingId={null}
          onClick={() => {
            requestZip({ catalog_type_ids: selectedTypes });
          }}
          theme={ButtonTheme.Primary}
          loading={requestingZip}
          disabled={selectedTypes && selectedTypes.length === 0}
        >
          Continue
        </Button>
      </CatalogFixedFooter>
    </>
  );
};

export const SelectTypesStepHeading = () => {
  return (
    <div className="mb-6 flex flex-col gap-2">
      <div className="text-[24px] font-semibold leading-10">Select types</div>
      <Txt lightGrey>
        {`Once you've selected your types, we'll generate a zip file to
        enable you to manage your catalog from GitHub.`}
      </Txt>
      <Callout theme={CalloutTheme.Plain}>
        {`This will mean that users can no longer edit these types from the
        incident.io dashboard. Instead, they'll need to merge a change into your
        GitHub repository.`}
      </Callout>
    </div>
  );
};

const CatalogTypeList = <TFormType extends FieldValues>({
  name,
}: {
  name: Path<TFormType>;
}): React.ReactElement => {
  const {
    field: { ref: _ref, ...field },
  } = useController({ name });

  return (
    <Form.InputWrapper<TFormType> name={name}>
      <CatalogTypeCheckboxGroup
        {...field}
        onChange={(val: string[]) => {
          field.onChange(val);
        }}
      />
    </Form.InputWrapper>
  );
};
