import {
  isBlockActive,
  isMarkActive,
  isWrapped,
  toggleWrap,
} from "@aeaton/prosemirror-commands";
import { IconEnum } from "@incident-ui";
import { setBlockType, toggleMark } from "prosemirror-commands";
import { MarkType, NodeType } from "prosemirror-model";
import { liftListItem, sinkListItem } from "prosemirror-schema-list";
import { Command, EditorState, Plugin } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { assertUnreachable } from "src/utils/utils";

import {
  codeAutocompleteRules,
  listAutocompleteRules,
  orderedListAutocompleteRules,
  quoteAutocompleteRules,
} from "./autocomplete";
import { LinkPopover } from "./LinkPopover";
import { schema } from "./schema";
import { tableToolbar } from "./tables";
import { ToolbarGroup, ToolbarItem } from "./Toolbar";

export type Format =
  | "plain"
  | "mrkdwn"
  | "rich"
  | "slack_rich_text"
  | "slack_rich_text_with_headings"
  | "basic"
  | "jira";

type ToolbarConfig = {
  groups: ToolbarGroup[];
  plugins: Plugin[];
};

export const toolbarFor = (name: Format): ToolbarConfig => {
  switch (name) {
    case "plain":
      return {
        groups: [],
        plugins: [],
      };
    case "basic":
    case "mrkdwn":
      return {
        groups: [basicTextFormatting, links, lists(false)],
        plugins: [],
      };
    case "rich":
      return {
        groups: [
          basicTextFormatting,
          headings,
          links,
          lists(true),
          blocks,
          tableToolbar,
        ],
        plugins: [
          listAutocompleteRules(schema),
          orderedListAutocompleteRules(schema),
        ],
      };
    case "jira":
      return {
        groups: [basicTextFormatting, headings, links, lists(false)],
        plugins: [
          listAutocompleteRules(schema),
          orderedListAutocompleteRules(schema),
        ],
      };
    case "slack_rich_text":
      return {
        groups: [basicAndExtendedTextFormatting, links, lists(true), blocks],
        plugins: [
          listAutocompleteRules(schema),
          orderedListAutocompleteRules(schema),
          quoteAutocompleteRules(schema),
          codeAutocompleteRules(schema),
        ],
      };
    // We use this in the text panels for custom dashboards
    case "slack_rich_text_with_headings":
      return {
        groups: [
          basicAndExtendedTextFormatting,
          headings,
          links,
          lists(true),
          blocks,
        ],
        plugins: [
          listAutocompleteRules(schema),
          orderedListAutocompleteRules(schema),
          quoteAutocompleteRules(schema),
          codeAutocompleteRules(schema),
        ],
      };
    default:
      assertUnreachable(name);
  }
  throw new Error("unreachable");
};

// Helpers

export const toggleHeader: (level: number) => Command = (level) => {
  return (state, dispatch) => {
    if (isBlockActive(schema.nodes.heading, { level: level })(state)) {
      setBlockType(schema.nodes.paragraph)(state, dispatch);
      return true;
    } else {
      setBlockType(schema.nodes.heading, { level: level })(state, dispatch);
      return true;
    }
  };
};

export const toggleBlockType: (type: NodeType) => Command = (type) => {
  return (state, dispatch) => {
    if (isBlockActive(type)(state)) {
      setBlockType(schema.nodes.paragraph)(state, dispatch);
      return true;
    } else {
      setBlockType(type)(state, dispatch);
      return true;
    }
  };
};

const toggleMarkItem = (
  id: string,
  title: string,
  markType: MarkType,
  icon: IconEnum,
): ToolbarItem => {
  return {
    id,
    title,
    icon,
    action: toggleMark(markType),
    enable: toggleMark(markType),
    active: isMarkActive(markType),
  };
};

// Groups

const basicTextFormatting: ToolbarGroup = {
  id: "simple-formatting",
  items: [
    toggleMarkItem(
      "toggle-bold",
      "Toggle bold",
      schema.marks.bold,
      IconEnum.TextBold,
    ),
    toggleMarkItem(
      "toggle-italic",
      "Toggle italic",
      schema.marks.italic,
      IconEnum.TextItalic,
    ),
  ],
};

const extendedTextFormatting: ToolbarGroup = {
  id: "extended-formatting",
  items: [
    toggleMarkItem(
      "toggle-strikethrough",
      "Toggle strikethrough",
      schema.marks.strikethrough,
      IconEnum.TextStrikeThrough,
    ),
    toggleMarkItem(
      "toggle-code",
      "Toggle code",
      schema.marks.code,
      IconEnum.Code,
    ),
  ],
};

const basicAndExtendedTextFormatting: ToolbarGroup = {
  id: "basic-and-extended-formatting",
  items: [...basicTextFormatting.items, ...extendedTextFormatting.items],
};

const headings: ToolbarGroup = {
  id: "headings",
  items: [
    {
      id: "block-heading-1",
      title: "Change to heading level 1",
      content: "H1",
      action: toggleHeader(1),
      enable: toggleHeader(1),
      active: isBlockActive(schema.nodes.heading, { level: 1 }),
    },
    {
      id: "block-heading-2",
      title: "Change to heading level 2",
      content: "H2",
      action: toggleHeader(2),
      enable: toggleHeader(2),
      active: isBlockActive(schema.nodes.heading, { level: 2 }),
    },
    {
      id: "block-heading-3",
      title: "Change to heading level 3",
      content: "H3",
      action: toggleHeader(3),
      enable: toggleHeader(3),
      active: isBlockActive(schema.nodes.heading, { level: 3 }),
    },
  ],
};

const links: ToolbarGroup = {
  id: "link",
  render: (props: { view: EditorView; state: EditorState }) => {
    return <LinkPopover {...props} />;
  },
};

const lists = (includeOrdered: boolean): ToolbarGroup => {
  const items = [
    {
      id: "toggle-list-bulleted",
      title: "Toggle bulleted list",
      icon: IconEnum.UnorderedList,
      action: toggleWrap(schema.nodes.list, { type: "bulleted" }),
    },
  ];

  if (includeOrdered) {
    items.push({
      id: "toggle-list-ordered",
      title: "Toggle ordered list",
      icon: IconEnum.NumberOrderedList,
      action: toggleWrap(schema.nodes.list, { type: "ordered" }),
    });
  }

  items.push(
    {
      id: "outdent-list-item",
      title: "Outdent list item",
      icon: IconEnum.IndentationLeft,
      action: liftListItem(schema.nodes.listItem),
    },
    {
      id: "indent-list-item",
      title: "Indent list item",
      icon: IconEnum.IndentationRight,
      action: sinkListItem(schema.nodes.listItem),
    },
  );

  return {
    id: "lists",
    items,
  };
};

const blocks: ToolbarGroup = {
  id: "blocks",
  items: [
    {
      id: "toggle-blockquote",
      title: "Toggle blockquote",
      icon: IconEnum.Quote,
      action: toggleWrap(schema.nodes.blockquote),
      active: isWrapped(schema.nodes.blockquote),
    },
    {
      id: "toggle-code",
      title: "Toggle code block",
      icon: IconEnum.Code,
      action: toggleBlockType(schema.nodes.codeBlock),
      active: isBlockActive(schema.nodes.codeBlock),
    },
  ],
};
