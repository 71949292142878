import { CatalogTypeColorEnum, CatalogTypeIconEnum } from "@incident-io/api";
import { IntegrationStatus } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Card,
  IconEnum,
  IconSize,
  OrgAwareLink,
  Tooltip,
} from "@incident-ui";
import { IconBadge } from "@incident-ui/IconBadge/IconBadge";
import pluralize from "pluralize";

import { SourceRepoBadge } from "../common/SourceRepoBadge";

type catalogTypeCardProps = {
  path: string;
  name: string;
  icon: CatalogTypeIconEnum;
  color: CatalogTypeColorEnum;
  description: string;
  entries?: number;
  integrationStatus: IntegrationStatus;
  hasBeenSynced: boolean;
  sourceRepoURL?: string;
  compact?: boolean;
};

export const CatalogTypeCard = ({
  path,
  name,
  icon,
  color,
  description,
  entries,
  integrationStatus,
  hasBeenSynced,
  sourceRepoURL,
  compact,
}: catalogTypeCardProps) => {
  return (
    <OrgAwareLink to={`${path}`}>
      <CatalogCardContents
        name={name}
        icon={icon}
        color={color}
        description={description}
        entries={entries}
        integrationStatus={integrationStatus}
        hasBeenSynced={hasBeenSynced}
        sourceRepoURL={sourceRepoURL}
        compact={compact}
      />
    </OrgAwareLink>
  );
};

const CatalogCardContents = ({
  name,
  icon,
  color,
  compact,
  description,
  entries,
  integrationStatus,
  hasBeenSynced,
  sourceRepoURL,
}: Omit<catalogTypeCardProps, "path">) => {
  return (
    <Card
      className={compact ? "" : "min-h-[192px]"}
      icon={icon}
      color={color as unknown as ColorPaletteEnum}
      error={integrationStatus.broken}
      compact={compact}
      headerAccessory={
        integrationStatus.broken ? (
          <Tooltip
            content={
              integrationStatus.reason === "not_installed"
                ? "This integration isn't installed. You can install it via integration settings."
                : "We're having trouble syncing this type. Please check your integration settings."
            }
            bubbleProps={{ className: "w-full font-normal" }}
          >
            <IconBadge
              icon={IconEnum.Warning}
              color={ColorPaletteEnum.Red}
              size={IconSize.Small}
            />
          </Tooltip>
        ) : undefined
      }
      title={
        <div className="flex items-center gap-2">
          {name}
          {compact && !!entries && (
            <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
              {entries}
            </Badge>
          )}
        </div>
      }
      description={
        <div className="text-content-secondary line-clamp-3">{description}</div>
      }
      footerNode={
        compact ? undefined : (
          <div className="flex justify-between items-end">
            {entries !== undefined && (
              <Badge theme={BadgeTheme.Naked} icon={IconEnum.UnorderedList}>
                {entries} {pluralize("entry", entries)}
              </Badge>
            )}
            {hasBeenSynced && <SourceRepoBadge sourceRepoURL={sourceRepoURL} />}
          </div>
        )
      }
    />
  );
};
