import type { SVGProps } from "react";
import * as React from "react";
const SvgHide = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.003 13.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
    />
    <path
      fill="currentColor"
      d="M10.003 16a8.215 8.215 0 0 1-1.71-.18.75.75 0 0 1 .315-1.466c.45.097.919.146 1.395.146 3.301 0 5.355-2.37 6.286-3.78a1.322 1.322 0 0 0 0-1.44 10.27 10.27 0 0 0-.909-1.184.75.75 0 1 1 1.121-.997c.375.422.725.878 1.04 1.356a2.803 2.803 0 0 1 0 3.09C16.444 13.209 14.006 16 10.003 16ZM5.958 14.794a.744.744 0 0 1-.406-.12c-1.474-.952-2.493-2.227-3.087-3.13a2.8 2.8 0 0 1 0-3.09C3.562 6.792 6 4 10.003 4c1.592 0 3.09.446 4.452 1.326a.75.75 0 1 1-.814 1.26A6.605 6.605 0 0 0 10.004 5.5c-3.301 0-5.355 2.369-6.286 3.78a1.322 1.322 0 0 0 0 1.439c.516.782 1.394 1.884 2.648 2.695a.75.75 0 0 1-.407 1.38l-.001-.001Z"
    />
    <path
      fill="currentColor"
      d="M3.003 17.75a.75.75 0 0 1-.53-1.281l14-14a.75.75 0 1 1 1.061 1.062L3.533 17.53a.748.748 0 0 1-.53.22Z"
    />
  </svg>
);
export default SvgHide;
