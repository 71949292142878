/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleMappingPayload
 */
export interface RoleMappingPayload {
    /**
     * Unique identifier of the RBAC role ID that this mapping points to
     * @type {string}
     * @memberof RoleMappingPayload
     */
    rbac_role_id: string;
    /**
     * Unique identifier of the SCIM group ID that this mapping points to
     * @type {string}
     * @memberof RoleMappingPayload
     */
    scim_group_id: string;
}

export function RoleMappingPayloadFromJSON(json: any): RoleMappingPayload {
    return RoleMappingPayloadFromJSONTyped(json, false);
}

export function RoleMappingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleMappingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rbac_role_id': json['rbac_role_id'],
        'scim_group_id': json['scim_group_id'],
    };
}

export function RoleMappingPayloadToJSON(value?: RoleMappingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rbac_role_id': value.rbac_role_id,
        'scim_group_id': value.scim_group_id,
    };
}

