import { Severity as Severity } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { IconEnum } from "../Icon/Icon";
import { Badge, BadgeSize, BadgeTheme } from "./Badge";

export enum Bucket {
  Unset = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export type SeverityBadgeProps = {
  severity?: Pick<Severity, "name" | "bucketed_rank">;
  size?: BadgeSize;
  className?: string;
  iconOnly?: boolean;
  naked?: boolean;
  theme?: BadgeTheme;
};

export function SeverityBadge({
  severity,
  iconOnly,
  size = BadgeSize.Small,
  className,
  naked = false,
  theme,
}: SeverityBadgeProps): React.ReactElement {
  const hasSeverity = !!severity;
  const iconTheme = ThemeFor[severity?.bucketed_rank || Bucket.Unset];

  const sharedClasses = tcx(
    "max-w-[150px]",
    "dark:text-slate-100",
    {
      // Make it a lighter font-weight if there's no severity set
      ["font-normal"]: !severity,
      // Add a placeholder icon if it's icon only and there's no severity
      ["border-dashed"]: iconOnly && !severity,
    },
    iconOnly ? "justify-center shrink-0" : "justify-start shrink",
  );

  const label = (
    <span className="truncate">
      {hasSeverity ? severity.name : "No severity"}
    </span>
  );

  if (naked) {
    return (
      <Badge
        size={size}
        theme={BadgeTheme.Naked}
        className={tcx(sharedClasses, className)}
        icon={iconTheme.icon}
        iconClassName={iconTheme.nakedIconClassName}
      >
        {!iconOnly && label}
      </Badge>
    );
  }

  return (
    <Badge
      size={size}
      theme={theme || BadgeTheme.Secondary}
      className={tcx(
        sharedClasses,
        "dark:border-slate-700",
        iconTheme.className,
        className,
      )}
      icon={iconTheme.icon}
      iconClassName={iconTheme.iconClassName}
    >
      {!iconOnly && label}
    </Badge>
  );
}

type ThemeConfig = {
  className: string;
  iconClassName: string;
  nakedIconClassName: string;
  icon?: IconEnum;
};

const ThemeFor: { [key in Bucket]: ThemeConfig } = {
  [Bucket.Unset]: {
    className: "",
    iconClassName: "",
    nakedIconClassName: "",
  },
  [Bucket.Low]: {
    className: "",
    iconClassName: "",
    nakedIconClassName: "",
    icon: IconEnum.SeverityLow,
  },
  [Bucket.Medium]: {
    className: "",
    iconClassName: "text-amber-500",
    nakedIconClassName: "text-amber-500",
    icon: IconEnum.SeverityMedium,
  },
  [Bucket.High]: {
    className: "border-stroke-destroy-primary",
    iconClassName: "text-content-destroy",
    nakedIconClassName: "text-content-destroy",
    icon: IconEnum.SeverityHigh,
  },
  [Bucket.Critical]: {
    className:
      "bg-surface-destroy text-content-invert border-stroke-destroy-primary",
    iconClassName: "bg-surface-destroy text-content-invert",
    nakedIconClassName: "text-content-destroy",
    icon: IconEnum.SeverityCritical,
  },
};

export const severityToIconEnum = {
  [Bucket.Low]: IconEnum.SeverityLow,
  [Bucket.Medium]: IconEnum.SeverityMedium,
  [Bucket.High]: IconEnum.SeverityHigh,
  [Bucket.Critical]: IconEnum.SeverityCritical,
};
