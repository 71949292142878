/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsightsReportSchedule,
    InsightsReportScheduleFromJSON,
    InsightsReportScheduleFromJSONTyped,
    InsightsReportScheduleToJSON,
} from './InsightsReportSchedule';

/**
 * 
 * @export
 * @interface InsightsListReportScheduleResponseBody
 */
export interface InsightsListReportScheduleResponseBody {
    /**
     * 
     * @type {Array<InsightsReportSchedule>}
     * @memberof InsightsListReportScheduleResponseBody
     */
    report_schedules: Array<InsightsReportSchedule>;
}

export function InsightsListReportScheduleResponseBodyFromJSON(json: any): InsightsListReportScheduleResponseBody {
    return InsightsListReportScheduleResponseBodyFromJSONTyped(json, false);
}

export function InsightsListReportScheduleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsListReportScheduleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report_schedules': ((json['report_schedules'] as Array<any>).map(InsightsReportScheduleFromJSON)),
    };
}

export function InsightsListReportScheduleResponseBodyToJSON(value?: InsightsListReportScheduleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report_schedules': ((value.report_schedules as Array<any>).map(InsightsReportScheduleToJSON)),
    };
}

