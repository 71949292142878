import type { SVGProps } from "react";
import * as React from "react";
const SvgLockClosed = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.25 10a.75.75 0 0 1-.75-.75V6c0-1.378-1.122-2.5-2.5-2.5A2.503 2.503 0 0 0 7.5 6v3.25a.75.75 0 0 1-1.5 0V6c0-2.206 1.794-4 4-4s4 1.794 4 4v3.25a.75.75 0 0 1-.75.75Z"
    />
    <path
      fill="currentColor"
      d="M13.75 8.5h-7.5a2.752 2.752 0 0 0-2.75 2.75v4A2.752 2.752 0 0 0 6.25 18h7.5a2.752 2.752 0 0 0 2.75-2.75v-4a2.752 2.752 0 0 0-2.75-2.75Zm-3 5.25a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 1.5 0v1Z"
    />
  </svg>
);
export default SvgLockClosed;
