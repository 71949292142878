/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GitHubConfig,
    GitHubConfigFromJSON,
    GitHubConfigFromJSONTyped,
    GitHubConfigToJSON,
} from './GitHubConfig';

/**
 * 
 * @export
 * @interface IntegrationsGithubShowResponseBody
 */
export interface IntegrationsGithubShowResponseBody {
    /**
     * 
     * @type {GitHubConfig}
     * @memberof IntegrationsGithubShowResponseBody
     */
    git_hub_config?: GitHubConfig;
}

export function IntegrationsGithubShowResponseBodyFromJSON(json: any): IntegrationsGithubShowResponseBody {
    return IntegrationsGithubShowResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsGithubShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGithubShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'git_hub_config': !exists(json, 'git_hub_config') ? undefined : GitHubConfigFromJSON(json['git_hub_config']),
    };
}

export function IntegrationsGithubShowResponseBodyToJSON(value?: IntegrationsGithubShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'git_hub_config': GitHubConfigToJSON(value.git_hub_config),
    };
}

