import { LoadingBar } from "@incident-io/status-page-ui";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import {
  Button,
  ButtonTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  OrgAwareLink,
  Tooltip,
} from "@incident-ui";
import _, { orderBy } from "lodash";
import React from "react";
import { Timestamp } from "src/components/legacy/status-page-config/shared/Timestamp";
import {
  StatusPage,
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
  StatusPageIncidentTypeEnum,
  StatusPagePageTypeEnum,
  StatusPageStructure,
  StatusPageSubPageSlim,
} from "src/contexts/ClientContext";
import { formatDurationShort, formatTimestampLocale } from "src/utils/datetime";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable, customerPageVisitURL } from "src/utils/utils";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { OverviewTabIds } from "../../view/StatusPagesDetailsViewPage";
import { getImpactWindow } from "../utils/utils";
import { RetrospectiveBadge } from "../view/RetrospectiveBadge";
import { StatusPageAffectedItemBadge } from "../view/StatusIcons";

const formatDate = (incident: StatusPageIncident) => {
  const startAt = incident.published_at;
  let endAt = new Date();
  let prefix = "Ongoing for";

  if (
    incident.status === StatusPageIncidentStatusEnum.Resolved ||
    incident.status === StatusPageIncidentStatusEnum.MaintenanceComplete
  ) {
    endAt =
      _.max(
        _.compact(incident.updates.map(({ published_at }) => published_at)),
      ) || incident.updated_at;

    prefix = "Lasted";
  }
  if (incident.status === StatusPageIncidentStatusEnum.MaintenanceScheduled) {
    const [startAt, _] = getImpactWindow(incident);
    if (!startAt) {
      return "No start time scheduled";
    }
    return `Scheduled ${formatTimestampLocale({
      timestamp: startAt,
      timeStyle: "short",
      dateStyle: "short",
    })}`;
  }

  const duration = formatDurationShort(startAt, endAt);
  if (duration === "0s") {
    return undefined;
  }
  return `${prefix} ${duration}`;
};

export const StatusPageIncidentListPage = ({
  page,
  subpages,
  structure,
  allIncidents,
  incidents,
  loading,
  overviewTab,
}: {
  page: StatusPage;
  subpages?: StatusPageSubPageSlim[];
  structure: StatusPageStructure;
  allIncidents: StatusPageIncident[];
  incidents: StatusPageIncident[];
  loading: boolean;
  overviewTab: OverviewTabIds;
}): React.ReactElement | null => {
  const ongoingIncidents = incidents.filter((incident) => {
    return (
      incident.status !== StatusPageIncidentStatusEnum.Resolved &&
      incident.type === "incident"
    );
  });
  const ongoingMaintenance = orderBy(
    incidents.filter((incident) => {
      return (
        incident.status ===
          StatusPageIncidentStatusEnum.MaintenanceInProgress &&
        incident.type === "maintenance"
      );
    }),
    (inc) =>
      // Sort maintenances by their start time
      getImpactWindow(inc)[0] || inc.published_at,
    "desc",
  );

  const pastEvents = orderBy(
    incidents.filter((incident) => {
      return (
        incident.status === StatusPageIncidentStatusEnum.Resolved ||
        incident.status === StatusPageIncidentStatusEnum.MaintenanceComplete ||
        !incident.published_at
      );
    }),
    (inc) =>
      // Sort maintenances by their scheduled start time. Otherwise sort by
      // published time.
      inc.type === StatusPageIncidentTypeEnum.Maintenance
        ? getImpactWindow(inc)[0] ?? inc.published_at
        : inc.published_at,
    "desc",
  );

  const scheduledMaintenance = incidents.filter(
    (incident) =>
      incident.status === StatusPageIncidentStatusEnum.MaintenanceScheduled,
  );

  if (allIncidents.length === 0) {
    return <EmptyState subpages={subpages} page={page} />;
  }

  const renderIncidents = (tab: OverviewTabIds): React.ReactElement | null => {
    if (loading) {
      return (
        <>
          <LoadingBar className="h-36 rounded-2" />
          <LoadingBar className="h-36 rounded-2" />
          <LoadingBar className="h-36 rounded-2" />
        </>
      );
    }

    switch (tab) {
      case OverviewTabIds.Now:
        return (
          <OngoingIncidents
            ongoingIncidents={ongoingIncidents}
            ongoingMaintenance={ongoingMaintenance}
            page={page}
            structure={structure}
          />
        );
      case OverviewTabIds.Past:
        return (
          <PastIncidents
            pastEvents={pastEvents}
            page={page}
            structure={structure}
          />
        );
      case OverviewTabIds.Maintenance:
        return (
          <ScheduledMaintenance
            scheduledMaintenance={scheduledMaintenance}
            page={page}
            structure={structure}
          />
        );
      default:
        assertUnreachable(tab);
        return null;
    }
  };

  return (
    <div className={tcx("space-y-4 text-sm")}>
      {!loading &&
        ongoingIncidents.length === 0 &&
        ongoingMaintenance.length === 0 && <AllGoodBanner />}
      {renderIncidents(overviewTab)}
    </div>
  );
};

const OngoingIncidents = ({
  ongoingMaintenance,
  ongoingIncidents,
  page,
  structure,
}: {
  ongoingMaintenance: StatusPageIncident[];
  ongoingIncidents: StatusPageIncident[];
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement => {
  return (
    <>
      {ongoingIncidents.length > 0 ? (
        <>
          {_.orderBy(ongoingIncidents, (inc) => inc.published_at, "asc").map(
            (incident) => (
              <IncidentListItem
                key={incident.id}
                incident={incident}
                page={page}
                structure={structure}
              />
            ),
          )}
        </>
      ) : null}
      {ongoingMaintenance.length > 0 && (
        <>
          <h2 className="font-medium !-mb-2 mt-3">Maintenance</h2>
          {_.orderBy(ongoingMaintenance, (inc) => inc.published_at, "asc").map(
            (incident) => (
              <IncidentListItem
                key={incident.id}
                incident={incident}
                page={page}
                structure={structure}
              />
            ),
          )}
        </>
      )}
    </>
  );
};

const PastIncidents = ({
  pastEvents,
  page,
  structure,
}: {
  pastEvents: StatusPageIncident[];
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement => {
  if (pastEvents.length > 0) {
    return (
      <>
        {_.orderBy(pastEvents, (inc) => inc.published_at, "desc").map(
          (incident) => (
            <IncidentListItem
              key={incident.id}
              incident={incident}
              page={page}
              structure={structure}
            />
          ),
        )}
      </>
    );
  }

  return <p className="text-slate-600">There aren&apos;t any past incidents</p>;
};

const ScheduledMaintenance = ({
  scheduledMaintenance,
  page,
  structure,
}: {
  scheduledMaintenance: StatusPageIncident[];
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement => {
  if (scheduledMaintenance.length > 0) {
    return (
      <>
        {scheduledMaintenance.map((maintenance) => (
          <IncidentListItem
            key={maintenance.id}
            incident={maintenance}
            page={page}
            structure={structure}
          />
        ))}
      </>
    );
  }

  return (
    <p className="text-content-tertiary">
      There aren&apos;t any scheduled maintenance events
    </p>
  );
};

const IncidentListItem = ({
  incident,
  page,
  structure,
}: {
  incident: StatusPageIncident;
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement => {
  const formattedDate = formatDate(incident);
  const lastPublishedUpdate = _.maxBy(
    incident.updates,
    (update) => update.published_at,
  );

  return (
    <OrgAwareLink
      className="block !no-underline"
      title="View incident"
      analyticsTrackingId="link-to-incident"
      to={`/status-pages/${page.id}/incident/${incident.id}`}
    >
      <ContentBox
        key={incident.id}
        className="hover:border-slate-500 transition"
      >
        <div className="p-3 rounded-t-lg bg-surface-secondary grid grid-cols-[1fr_12px] min-h-[56px] items-center">
          <div className="flex gap-3 flex-wrap items-center truncate">
            <div className="text-ellipsis line-clamp-1">{incident.name}</div>
            <div className="text-content-tertiary flex items-center">
              <Icon
                id={IconEnum.Status}
                className="mr-0.5 text-content-tertiary"
              />
              {_.startCase(incident.status)}
            </div>
            {formattedDate && (
              <div className="text-content-tertiary flex items-center">
                <Icon id={IconEnum.Clock} className="mr-0.5" />
                {formattedDate}
              </div>
            )}
            {incident.automate_maintenance_status &&
            incident.status ===
              StatusPageIncidentStatusEnum.MaintenanceScheduled ? (
              <div className="text-content-tertiary flex items-center">
                <Icon id={IconEnum.Cog} className="mr-0.5" />
                Automated updates enabled
                <Tooltip
                  side={"bottom"}
                  buttonClassName={"ml-1"}
                  content={
                    <>
                      The maintenance window will be set to &apos;In
                      progress&apos; when the window starts, and
                      &apos;Complete&apos; when it ends.
                    </>
                  }
                ></Tooltip>
              </div>
            ) : null}
            {incident.is_retrospective && <RetrospectiveBadge />}
            <div className="flex gap-x-1 gap-y-2 flex-wrap">
              {structure.items.map((item, index) => (
                <StatusPageAffectedItemBadge
                  key={index}
                  structureItem={item}
                  incident={incident}
                />
              ))}
            </div>
          </div>

          <Icon
            id={IconEnum.Back}
            size={IconSize.XS}
            className="text-content-tertiary rotate-180 block flex-none"
          />
        </div>

        <div className={"p-3"}>
          <OrgAwareLink to={`/status-pages/${page.id}/incident/${incident.id}`}>
            {lastPublishedUpdate && (
              <>
                <TemplatedTextDisplay
                  style={TemplatedTextDisplayStyle.Compact}
                  value={lastPublishedUpdate.message}
                />
                <div className="text-content-tertiary mt-2">
                  <Timestamp ts={lastPublishedUpdate.published_at} />
                </div>
              </>
            )}
          </OrgAwareLink>
        </div>
      </ContentBox>
    </OrgAwareLink>
  );
};

export const AllGoodBanner = (): React.ReactElement => {
  return (
    <div
      className={tcx(
        "border-y md:border-x md:rounded-2 w-full p-4 font-medium mb-4",
        "border-green-400 bg-green-surface text-green-content",
      )}
    >
      <Icon
        id={IconEnum.Checkmark}
        size={IconSize.Large}
        className={"mr-1 inline  -my-1"}
      />
      You&apos;re not reporting any issues on your status page
    </div>
  );
};

const EmptyState = ({
  page,
  subpages,
}: {
  page: StatusPage;
  subpages?: StatusPageSubPageSlim[];
}): React.ReactElement => {
  const { identity } = useIdentity();
  return (
    <div className="bg-surface-secondary md:rounded-2 border-y md:border-x border-stroke flex flex-col items-center justify-center grow px-8 py-20 gap-2">
      <Icon
        id={IconEnum.Sparkles}
        size={IconSize.Large}
        className="text-content-tertiary"
      />
      <p className="text-slate-600 font-medium mb-4">
        New incidents will appear here
      </p>
      <div className="flex flex-col items-center gap-2">
        <Button
          openInNewTab
          href={
            page.page_type === StatusPagePageTypeEnum.Customer &&
            (subpages?.length ?? 0) > 0 &&
            subpages?.[0] &&
            identity
              ? customerPageVisitURL(
                  page.id,
                  subpages[0].id,
                  identity.organisation_id,
                )
              : page.public_url
          }
          theme={ButtonTheme.Primary}
          analyticsTrackingId={"status-page-see-new-page"}
          className="w-full justify-center"
        >
          See how it looks
        </Button>
        <Button
          href={`/status-pages/${page.id}/overview/now?action=publish-incident`}
          analyticsTrackingId={"status-page-publish-first-incident"}
          className="w-full justify-center"
        >
          Publish your first incident
        </Button>
      </div>
    </div>
  );
};
