/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsAtlassianStatuspageSetAPITokenRequestBody
 */
export interface IntegrationsAtlassianStatuspageSetAPITokenRequestBody {
    /**
     * api token for this integration
     * @type {string}
     * @memberof IntegrationsAtlassianStatuspageSetAPITokenRequestBody
     */
    api_token: string;
}

export function IntegrationsAtlassianStatuspageSetAPITokenRequestBodyFromJSON(json: any): IntegrationsAtlassianStatuspageSetAPITokenRequestBody {
    return IntegrationsAtlassianStatuspageSetAPITokenRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsAtlassianStatuspageSetAPITokenRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsAtlassianStatuspageSetAPITokenRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_token': json['api_token'],
    };
}

export function IntegrationsAtlassianStatuspageSetAPITokenRequestBodyToJSON(value?: IntegrationsAtlassianStatuspageSetAPITokenRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_token': value.api_token,
    };
}

