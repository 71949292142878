/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GitLabAttributes
 */
export interface GitLabAttributes {
    /**
     * 
     * @type {string}
     * @memberof GitLabAttributes
     */
    action?: GitLabAttributesActionEnum;
    /**
     * 
     * @type {string}
     * @memberof GitLabAttributes
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof GitLabAttributes
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GitLabAttributes
     */
    iid?: number;
    /**
     * 
     * @type {string}
     * @memberof GitLabAttributes
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof GitLabAttributes
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum GitLabAttributesActionEnum {
    Open = 'open',
    Close = 'close',
    Reopen = 'reopen',
    Approved = 'approved',
    Unapproved = 'unapproved',
    Update = 'update',
    Merge = 'merge',
    Approval = 'approval',
    Unapproval = 'unapproval'
}

export function GitLabAttributesFromJSON(json: any): GitLabAttributes {
    return GitLabAttributesFromJSONTyped(json, false);
}

export function GitLabAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GitLabAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'iid': !exists(json, 'iid') ? undefined : json['iid'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function GitLabAttributesToJSON(value?: GitLabAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'description': value.description,
        'id': value.id,
        'iid': value.iid,
        'state': value.state,
        'title': value.title,
    };
}

