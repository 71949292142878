import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Button, ButtonTheme, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { Form } from "src/components/@shared/forms";
import { IntegrationsAsanaInstallRequestBody as FormData } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const ConnectToAsanaModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const formMethods = useForm<FormData>();

  const {
    trigger: setAsanaApiToken,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, data: FormData) => {
      await apiClient.integrationsAsanaInstall({
        installRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const { showArticle } = useIntercom();

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={setAsanaApiToken}
      analyticsTrackingId="connect-asana-integration"
      title="Connect to Asana"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add an Asana personal access token here to allow us to export your
        actions to Asana. If you need help finding your API token, you can
        follow our{" "}
        <Button
          analyticsTrackingId={"connect-asana-integration-help"}
          theme={ButtonTheme.Link}
          onClick={() => showArticle(6788488)}
        >
          instructions
        </Button>
        .
      </p>
      <InputV2
        formMethods={formMethods}
        label="API Token"
        type={InputType.Password}
        required="Personal access token is required"
        name="token"
        autoComplete="none"
        placeholder={"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
      />
    </Form.Modal>
  );
};
