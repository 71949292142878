/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageIncident,
    InternalStatusPageIncidentFromJSON,
    InternalStatusPageIncidentFromJSONTyped,
    InternalStatusPageIncidentToJSON,
} from './InternalStatusPageIncident';

/**
 * 
 * @export
 * @interface InternalStatusPageContentShowIncidentResponseBody
 */
export interface InternalStatusPageContentShowIncidentResponseBody {
    /**
     * 
     * @type {InternalStatusPageIncident}
     * @memberof InternalStatusPageContentShowIncidentResponseBody
     */
    incident: InternalStatusPageIncident;
}

export function InternalStatusPageContentShowIncidentResponseBodyFromJSON(json: any): InternalStatusPageContentShowIncidentResponseBody {
    return InternalStatusPageContentShowIncidentResponseBodyFromJSONTyped(json, false);
}

export function InternalStatusPageContentShowIncidentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageContentShowIncidentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident': InternalStatusPageIncidentFromJSON(json['incident']),
    };
}

export function InternalStatusPageContentShowIncidentResponseBodyToJSON(value?: InternalStatusPageContentShowIncidentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident': InternalStatusPageIncidentToJSON(value.incident),
    };
}

