/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageIncident,
    InternalStatusPageIncidentFromJSON,
    InternalStatusPageIncidentFromJSONTyped,
    InternalStatusPageIncidentToJSON,
} from './InternalStatusPageIncident';

/**
 * 
 * @export
 * @interface InternalStatusPageListIncidentsResponseBody
 */
export interface InternalStatusPageListIncidentsResponseBody {
    /**
     * The incidents attached to this internal status page
     * @type {Array<InternalStatusPageIncident>}
     * @memberof InternalStatusPageListIncidentsResponseBody
     */
    internal_status_page_incidents: Array<InternalStatusPageIncident>;
}

export function InternalStatusPageListIncidentsResponseBodyFromJSON(json: any): InternalStatusPageListIncidentsResponseBody {
    return InternalStatusPageListIncidentsResponseBodyFromJSONTyped(json, false);
}

export function InternalStatusPageListIncidentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageListIncidentsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'internal_status_page_incidents': ((json['internal_status_page_incidents'] as Array<any>).map(InternalStatusPageIncidentFromJSON)),
    };
}

export function InternalStatusPageListIncidentsResponseBodyToJSON(value?: InternalStatusPageListIncidentsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internal_status_page_incidents': ((value.internal_status_page_incidents as Array<any>).map(InternalStatusPageIncidentToJSON)),
    };
}

