import { AIConfig } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { Button, IconEnum } from "@incident-ui";
import { useState } from "react";
import { AIFeatureEnum } from "src/hooks/useAI";

import { AIFeatureToggle } from "./AIFeatureToggle";
import { SummariseIncidentsSettingsEditModal } from "./SummariseIncidentsSettingsEditModal";

export const SummariseIncidentsToggle = ({ config }: { config: AIConfig }) => {
  const [
    showSummariseIncidentsSettingsModal,
    setShowSummariseIncidentsSettingsModal,
  ] = useState<boolean>(false);

  const enabled =
    config.enabled &&
    config.enabled_features.includes(AIFeatureEnum.SummariseIncident);

  return (
    <>
      <AIFeatureToggle
        config={config}
        requiredProduct={Product.Response}
        feature={AIFeatureEnum.SummariseIncident}
        title="Suggested summaries"
        description={
          <>
            When you provide an update to an incident, we&rsquo;ll suggest a new
            summary of the incident. Keeping the summary fresh ensures that
            newcomers can get up to speed quickly.
          </>
        }
      >
        {enabled && (
          <div className="pl-9">
            <Button
              analyticsTrackingId={null}
              icon={IconEnum.Cog}
              onClick={() => setShowSummariseIncidentsSettingsModal(true)}
            >
              Configure
            </Button>
          </div>
        )}
      </AIFeatureToggle>
      {showSummariseIncidentsSettingsModal && (
        <SummariseIncidentsSettingsEditModal
          onClose={() => setShowSummariseIncidentsSettingsModal(false)}
          config={config}
        />
      )}
    </>
  );
};
