import {
  Alert,
  IncidentAlertStateEnum,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { IncidentsListContextProvider } from "@incident-shared/incidents";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  SeverityBadge,
  StackedList,
  StackedListButton,
} from "@incident-ui";
import { statusToIconEnum } from "@incident-ui/Badge/IncidentStatusBadge";
import { captureException } from "@sentry/react";
import { useSettings } from "src/hooks/useSettings";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { useAPI } from "src/utils/swr";

export const IncidentSection = ({ alert }: { alert: Alert }) => {
  const {
    data: { incident_alerts: incidentAlerts },
    isLoading: incidentAlertsIsLoading,
    error: incidentAlertsError,
  } = useAPI(
    "alertsListIncidentAlerts",
    {
      alertId: alert.id,
    },
    {
      fallbackData: {
        incident_alerts: [],
      },
    },
  );

  const { settings } = useSettings();
  const incidentTypesAreEnabled = incidentTypesEnabled(settings);

  const {
    data: { incidents },
    error: incidentsError,
  } = useAPI(
    incidentAlertsIsLoading ? null : "incidentsList",
    {
      id: {
        one_of: incidentAlerts.map((i) => i.incident_id),
      },
      statusCategory: {
        // The reason we have a statusCategory filter is that without it, we wouldn't show declined incidents.
        // Really, we want to show all incidents we've seen: so let's just map from the enum.
        one_of: Object.values(IncidentStatusCategoryEnum),
      },
      pageSize: incidentAlerts.length,
      // We include private incidents here so that people who have access to them can see them: if you do not have
      // access, we will _not_ show them.
      includePrivate: { is: true },
      // We need to query for incidents in every mode, since we allow to create test incidents.
      mode: {
        one_of: Object.values(IncidentModeEnum),
      },
    },
    { fallbackData: { incidents: [], available_statuses: [] } },
  );

  const err = incidentAlertsError ?? incidentsError;
  if (err) {
    captureException(err);
    return <GenericErrorMessage error={err} />;
  }

  const isRelated =
    incidentAlerts.length === 0 ||
    incidentAlerts.some((ia) => ia.state === IncidentAlertStateEnum.Related);

  return (
    <IncidentsListContextProvider>
      <div className="flex flex-col gap-3">
        <div className="text-sm-bold">
          {" "}
          {isRelated ? "Related incidents" : "Pending incidents"}
        </div>
        {incidents.length ? (
          <StackedList>
            {incidents
              .sort((a, b) => (a.id < b.id ? -1 : 1))
              .slice(0, 10)
              .map((incident) => {
                return (
                  <StackedListButton
                    key={incident.id}
                    iconNode={
                      <Icon
                        id={
                          statusToIconEnum[incident.incident_status.category] ||
                          IconEnum.IncidentStatusEmpty
                        }
                        size={IconSize.Small}
                      />
                    }
                    href={`/incidents/${incident.id}`}
                    analyticsTrackingId="alerts-view-related-incident"
                    title={incident.name}
                    iconColor={ColorPaletteEnum.Slate200}
                    accessory={
                      <div className="flex items-center gap-2">
                        {incident.severity ? (
                          <SeverityBadge
                            severity={incident.severity}
                            className="cursor-pointer"
                            size={BadgeSize.Small}
                            theme={BadgeTheme.Tertiary}
                          />
                        ) : null}{" "}
                        {incidentTypesAreEnabled && incident.incident_type ? (
                          <Badge
                            theme={BadgeTheme.Tertiary}
                            icon={IconEnum.IncidentType}
                            className="cursor-pointer"
                          >
                            <span className="truncate">
                              {incident.incident_type.name}
                            </span>
                          </Badge>
                        ) : null}
                      </div>
                    }
                  />
                );
              })}
          </StackedList>
        ) : (
          <div className="px-4 py-3 border border-stroke-primary rounded-2 text-content-tertiary">
            No related incidents
          </div>
        )}
      </div>
    </IncidentsListContextProvider>
  );
};
