/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffCreateImpersonationSessionRequestBody
 */
export interface StaffCreateImpersonationSessionRequestBody {
    /**
     * The reason for the impersonation.
     * @type {string}
     * @memberof StaffCreateImpersonationSessionRequestBody
     */
    reason: string;
    /**
     * The slug of the organisation to impersonate.
     * @type {string}
     * @memberof StaffCreateImpersonationSessionRequestBody
     */
    target_organisation_slug: string;
}

export function StaffCreateImpersonationSessionRequestBodyFromJSON(json: any): StaffCreateImpersonationSessionRequestBody {
    return StaffCreateImpersonationSessionRequestBodyFromJSONTyped(json, false);
}

export function StaffCreateImpersonationSessionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffCreateImpersonationSessionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': json['reason'],
        'target_organisation_slug': json['target_organisation_slug'],
    };
}

export function StaffCreateImpersonationSessionRequestBodyToJSON(value?: StaffCreateImpersonationSessionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'target_organisation_slug': value.target_organisation_slug,
    };
}

