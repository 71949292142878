/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EligibilityInformation,
    EligibilityInformationFromJSON,
    EligibilityInformationFromJSONTyped,
    EligibilityInformationToJSON,
} from './EligibilityInformation';
import {
    InsightsFilter,
    InsightsFilterFromJSON,
    InsightsFilterFromJSONTyped,
    InsightsFilterToJSON,
} from './InsightsFilter';
import {
    Panel,
    PanelFromJSON,
    PanelFromJSONTyped,
    PanelToJSON,
} from './Panel';

/**
 * 
 * @export
 * @interface CoreDashboard
 */
export interface CoreDashboard {
    /**
     * Color of the dashboard
     * @type {string}
     * @memberof CoreDashboard
     */
    color: CoreDashboardColorEnum;
    /**
     * Default filters for this dashboard
     * @type {Array<InsightsFilter>}
     * @memberof CoreDashboard
     */
    default_filters: Array<InsightsFilter>;
    /**
     * Description of the dashboard
     * @type {string}
     * @memberof CoreDashboard
     */
    description: string;
    /**
     * 
     * @type {EligibilityInformation}
     * @memberof CoreDashboard
     */
    eligibility: EligibilityInformation;
    /**
     * Filter contexts on the dashboard
     * @type {Array<string>}
     * @memberof CoreDashboard
     */
    filter_contexts: Array<CoreDashboardFilterContextsEnum>;
    /**
     * Icon of the dashboard
     * @type {string}
     * @memberof CoreDashboard
     */
    icon: CoreDashboardIconEnum;
    /**
     * ID of the dashboard (ULID for custom dashboards, names like 'mttx' for core dashboards)
     * @type {string}
     * @memberof CoreDashboard
     */
    id: string;
    /**
     * Whether this dashboard has been starred by the user
     * @type {boolean}
     * @memberof CoreDashboard
     */
    is_starred: boolean;
    /**
     * Name of the dashboard
     * @type {string}
     * @memberof CoreDashboard
     */
    name: string;
    /**
     * Panels on the dashboard
     * @type {Array<Panel>}
     * @memberof CoreDashboard
     */
    panels: Array<Panel>;
}

/**
* @export
* @enum {string}
*/
export enum CoreDashboardColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum CoreDashboardFilterContextsEnum {
    Incident = 'incident',
    User = 'user',
    PagerLoad = 'pager_load',
    Readiness = 'readiness',
    Alerts = 'alerts'
}/**
* @export
* @enum {string}
*/
export enum CoreDashboardIconEnum {
    Action = 'action',
    Alert = 'alert',
    Announcement = 'announcement',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Chart = 'chart',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentStatusDeclined = 'incident-status-declined',
    IncidentStatusMerged = 'incident-status-merged',
    IncidentType = 'incident_type',
    Key = 'key',
    Lock = 'lock',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    OnCall = 'on-call',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackGreyscale = 'slack-greyscale',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}

export function CoreDashboardFromJSON(json: any): CoreDashboard {
    return CoreDashboardFromJSONTyped(json, false);
}

export function CoreDashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoreDashboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'color': json['color'],
        'default_filters': ((json['default_filters'] as Array<any>).map(InsightsFilterFromJSON)),
        'description': json['description'],
        'eligibility': EligibilityInformationFromJSON(json['eligibility']),
        'filter_contexts': json['filter_contexts'],
        'icon': json['icon'],
        'id': json['id'],
        'is_starred': json['is_starred'],
        'name': json['name'],
        'panels': ((json['panels'] as Array<any>).map(PanelFromJSON)),
    };
}

export function CoreDashboardToJSON(value?: CoreDashboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'color': value.color,
        'default_filters': ((value.default_filters as Array<any>).map(InsightsFilterToJSON)),
        'description': value.description,
        'eligibility': EligibilityInformationToJSON(value.eligibility),
        'filter_contexts': value.filter_contexts,
        'icon': value.icon,
        'id': value.id,
        'is_starred': value.is_starred,
        'name': value.name,
        'panels': ((value.panels as Array<any>).map(PanelToJSON)),
    };
}

