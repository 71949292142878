/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentListPagesResponseBody
 */
export interface StatusPageContentListPagesResponseBody {
    /**
     * List of slugs – either subpaths or subdomains – that help Next.js precompute pages
     * @type {Array<string>}
     * @memberof StatusPageContentListPagesResponseBody
     */
    slugs: Array<string>;
}

export function StatusPageContentListPagesResponseBodyFromJSON(json: any): StatusPageContentListPagesResponseBody {
    return StatusPageContentListPagesResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentListPagesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentListPagesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slugs': json['slugs'],
    };
}

export function StatusPageContentListPagesResponseBodyToJSON(value?: StatusPageContentListPagesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slugs': value.slugs,
    };
}

