/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentRolesCreateRequestBody,
    IncidentRolesCreateRequestBodyFromJSON,
    IncidentRolesCreateRequestBodyToJSON,
    IncidentRolesCreateResponseBody,
    IncidentRolesCreateResponseBodyFromJSON,
    IncidentRolesCreateResponseBodyToJSON,
    IncidentRolesListResponseBody,
    IncidentRolesListResponseBodyFromJSON,
    IncidentRolesListResponseBodyToJSON,
    IncidentRolesShowResponseBody,
    IncidentRolesShowResponseBodyFromJSON,
    IncidentRolesShowResponseBodyToJSON,
    IncidentRolesUpdateRequestBody,
    IncidentRolesUpdateRequestBodyFromJSON,
    IncidentRolesUpdateRequestBodyToJSON,
    IncidentRolesUpdateResponseBody,
    IncidentRolesUpdateResponseBodyFromJSON,
    IncidentRolesUpdateResponseBodyToJSON,
} from '../models';

export interface IncidentRolesCreateRequest {
    createRequestBody: IncidentRolesCreateRequestBody;
}

export interface IncidentRolesDestroyRequest {
    id: string;
}

export interface IncidentRolesShowRequest {
    id: string;
}

export interface IncidentRolesUpdateRequest {
    id: string;
    updateRequestBody: IncidentRolesUpdateRequestBody;
}

/**
 * 
 */
export class IncidentRolesApi extends runtime.BaseAPI {

    /**
     * Creates an incident role
     * Create Incident Roles
     */
    async incidentRolesCreateRaw(requestParameters: IncidentRolesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentRolesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentRolesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRolesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRolesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates an incident role
     * Create Incident Roles
     */
    async incidentRolesCreate(requestParameters: IncidentRolesCreateRequest, initOverrides?: RequestInit): Promise<IncidentRolesCreateResponseBody> {
        const response = await this.incidentRolesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing role
     * Destroy Incident Roles
     */
    async incidentRolesDestroyRaw(requestParameters: IncidentRolesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentRolesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing role
     * Destroy Incident Roles
     */
    async incidentRolesDestroy(requestParameters: IncidentRolesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentRolesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List incident roles
     * List Incident Roles
     */
    async incidentRolesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentRolesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRolesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident roles
     * List Incident Roles
     */
    async incidentRolesList(initOverrides?: RequestInit): Promise<IncidentRolesListResponseBody> {
        const response = await this.incidentRolesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show a single incident role
     * Show Incident Roles
     */
    async incidentRolesShowRaw(requestParameters: IncidentRolesShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentRolesShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentRolesShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRolesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single incident role
     * Show Incident Roles
     */
    async incidentRolesShow(requestParameters: IncidentRolesShowRequest, initOverrides?: RequestInit): Promise<IncidentRolesShowResponseBody> {
        const response = await this.incidentRolesShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing incident role
     * Update Incident Roles
     */
    async incidentRolesUpdateRaw(requestParameters: IncidentRolesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentRolesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentRolesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling incidentRolesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRolesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRolesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing incident role
     * Update Incident Roles
     */
    async incidentRolesUpdate(requestParameters: IncidentRolesUpdateRequest, initOverrides?: RequestInit): Promise<IncidentRolesUpdateResponseBody> {
        const response = await this.incidentRolesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
