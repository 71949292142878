/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorTriggerTriggerErrorRequestBody
 */
export interface ErrorTriggerTriggerErrorRequestBody {
    /**
     * This is the message associated with the error
     * @type {string}
     * @memberof ErrorTriggerTriggerErrorRequestBody
     */
    message?: string;
    /**
     * What type of error reporting to trigger
     * @type {string}
     * @memberof ErrorTriggerTriggerErrorRequestBody
     */
    mode?: ErrorTriggerTriggerErrorRequestBodyModeEnum;
    /**
     * The team to associate the error with
     * @type {string}
     * @memberof ErrorTriggerTriggerErrorRequestBody
     */
    team?: string;
}

/**
* @export
* @enum {string}
*/
export enum ErrorTriggerTriggerErrorRequestBodyModeEnum {
    Logged = 'logged',
    Returned = 'returned',
    Panic = 'panic',
    SentryOnly = 'sentry_only'
}

export function ErrorTriggerTriggerErrorRequestBodyFromJSON(json: any): ErrorTriggerTriggerErrorRequestBody {
    return ErrorTriggerTriggerErrorRequestBodyFromJSONTyped(json, false);
}

export function ErrorTriggerTriggerErrorRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorTriggerTriggerErrorRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'team': !exists(json, 'team') ? undefined : json['team'],
    };
}

export function ErrorTriggerTriggerErrorRequestBodyToJSON(value?: ErrorTriggerTriggerErrorRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'mode': value.mode,
        'team': value.team,
    };
}

