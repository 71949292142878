import {
  AlertAttribute,
  AlertSourceConfig,
  EngineParamBindingPayload,
} from "@incident-io/api";
import { RainbowContainer } from "@incident-shared/aisuggestions/RainbowContainer";
import { BadgeSize } from "@incident-ui/Badge/Badge";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { AnimatePresence, motion } from "framer-motion";
import {
  AssistantAvatar,
  AssistantAvatarBackground,
} from "src/components/insights/assistant/AssistantAvatar";

import { useOutstandingSuggestions } from "./useOutstandingSuggestions";

// Shows a shiny AI banner in the alert source form under the attributes section
// if we have suggestions that need actioning for this alert source.
export const AlertSourceFoundSuggestedAttributes = ({
  alertSourceConfig,
  attributes,
  attributeBindings,
  onReview,
}: {
  alertSourceConfig: AlertSourceConfig;
  attributes: AlertAttribute[];
  attributeBindings: { [key: string]: EngineParamBindingPayload };
  onReview: () => void;
}) => {
  const {
    suggestions,
    isLoading: isLoadingSuggestion,
    error: errorSuggestion,
  } = useOutstandingSuggestions({
    alertSourceConfig,
    attributes,
    attributeBindings,
  });

  return (
    <AnimatePresence>
      {!isLoadingSuggestion && !errorSuggestion && suggestions.length > 0 && (
        <motion.div
          initial={{ opacity: 0, height: 0, overflow: "hidden" }}
          animate={{ opacity: 1, height: "auto", overflow: "visible" }}
          exit={{ opacity: 0, height: 0, overflow: "hidden" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <RainbowContainer className="border border-purple-100 rounded-lg bg-clip-border overflow-hidden">
            <motion.div
              className="flex flex-col gap-4 p-4"
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.1, duration: 0.3 }}
            >
              {/* Suggested Team attribute */}
              <div className="flex items-center gap-2">
                <AssistantAvatar
                  size={IconSize.XS}
                  background={AssistantAvatarBackground.Purple}
                  rounded={false}
                />
                <div className="flex text-content-primary text-sm-bold">
                  <span>Suggested attribute</span>
                </div>
              </div>

              <div className="text-content-secondary text-sm">
                We found{" "}
                <span className="text-content-primary">
                  {suggestions.length} potential attribute
                  {`${suggestions.length > 1 ? "s" : ""}`}
                </span>{" "}
                in your recent alerts. Use these to quickly add attributes to
                your alerts.
              </div>

              <div className="flex gap-3">
                <Button
                  analyticsTrackingId="alert-source-configure-review-suggestions"
                  onClick={onReview}
                  theme={ButtonTheme.Secondary}
                  size={BadgeSize.Medium}
                  title="Review: see suggestions that you can use to add attributes to your alerts"
                  icon={IconEnum.Eye}
                >
                  Review
                </Button>
              </div>
            </motion.div>
          </RainbowContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
