import { OrgAwareNavLink } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  OrgAwareLink,
  StackedList,
  StackedListItem,
  Txt,
} from "@incident-ui";
import {
  IncidentLifecycle,
  PostIncidentFlow,
} from "src/contexts/ClientContext";

import { PostIncidentFlowPreview } from "./PostIncidentFlowPreview";

export const PostIncidentFlowsList = ({
  flows,
  lifecycles,
}: {
  lifecycles: IncidentLifecycle[];
  flows: PostIncidentFlow[];
}) => {
  return (
    <div className="space-y-4">
      <StackedList>
        {flows.map((flow) => (
          <li
            key={flow.id}
            className="bg-white hover:bg-surface-secondary group"
          >
            <OrgAwareLink to={`/settings/post-incident-flow/${flow.id}`}>
              <StackedListItem
                title={flow.name}
                badgeNode={<FlowInUse flow={flow} lifecycles={lifecycles} />}
                accessory={
                  <Icon
                    id={IconEnum.ChevronRight}
                    className="text-content-tertiary"
                    size={IconSize.Medium}
                  />
                }
                description={
                  <PostIncidentFlowPreview flow={flow} className="mt-2" />
                }
              />
            </OrgAwareLink>
          </li>
        ))}
      </StackedList>
      <Callout theme={CalloutTheme.Info} className="mt-3 p-4">
        <Txt bold className="mb-1">
          Choose which incidents use which flow
        </Txt>
        You can choose which incidents enter which flows by going to{" "}
        <OrgAwareNavLink
          className="underline"
          to="/settings/lifecycle"
          target="_blank"
        >
          Settings → Lifecycles
        </OrgAwareNavLink>
      </Callout>
    </div>
  );
};

const FlowInUse = ({
  flow,
  lifecycles,
}: {
  flow: PostIncidentFlow;
  lifecycles: IncidentLifecycle[];
}) => {
  const lifecyclesWithThisFlow = lifecycles.filter((lifecycle) =>
    flowsInUseForLifecycle(lifecycle).includes(flow.id),
  );

  if (lifecyclesWithThisFlow.length === 0) {
    return <Txt lightGrey>Not in use</Txt>;
  }

  if (lifecyclesWithThisFlow.length === 1) {
    return (
      <Txt lightGrey>
        Used in the{" "}
        <Txt lightGrey inline bold>
          {lifecyclesWithThisFlow[0].name}
        </Txt>{" "}
        lifecycle
      </Txt>
    );
  }

  return (
    <Txt lightGrey>
      Used in{" "}
      <Txt lightGrey inline bold>
        {lifecyclesWithThisFlow.length}
      </Txt>{" "}
      lifecycles
    </Txt>
  );
};

// flowsInUseForLifcycle pulls out all the post-incident flows that are referenced in a given
// lifecycle.
export const flowsInUseForLifecycle = (
  lifecycle: IncidentLifecycle,
): string[] => {
  const flows: string[] = [];

  if (lifecycle.automatic_post_incident_flow_expression) {
    // This expression always returns an array of post incident flows, so we can
    // grab all the literals from the `array_values` of all the branches to see all
    // the possible return types.
    const expression = lifecycle.automatic_post_incident_flow_expression;
    if (expression.branches) {
      expression.branches.forEach((branch) => {
        if (branch.result && branch.result.array_value) {
          flows.push(
            ...(branch.result.array_value.map((x) => x.literal) as string[]),
          );
        }
      });
    }
    if (expression.else_branch && expression.else_branch.result.array_value) {
      flows.push(
        ...(expression.else_branch.result.array_value.map(
          (x) => x.literal,
        ) as string[]),
      );
    }
  }

  return flows;
};
