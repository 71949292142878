import type { SVGProps } from "react";
import * as React from "react";
const SvgGoogleCloud = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#EA4335"
      d="m12.64 7.051 1.391-1.39.093-.586C11.59 2.77 7.561 3.03 5.276 5.62A6.43 6.43 0 0 0 3.92 8.16l.498-.07 2.781-.458.215-.22c1.238-1.359 3.33-1.541 4.758-.385l.469.024Z"
    />
    <path
      fill="#4285F4"
      d="M16.013 8.12a6.265 6.265 0 0 0-1.889-3.045l-1.952 1.952a3.47 3.47 0 0 1 1.274 2.752v.347c.96 0 1.737.777 1.737 1.737a1.72 1.72 0 0 1-1.737 1.718h-3.48l-.341.37v2.084l.341.327h3.48A4.502 4.502 0 0 0 17.48 9.76a4.518 4.518 0 0 0-1.468-1.64Z"
    />
    <path
      fill="#34A853"
      d="M6.492 16.362h3.474v-2.781H6.492c-.246 0-.49-.054-.713-.157l-.493.152-1.4 1.39-.122.474a4.51 4.51 0 0 0 2.728.922Z"
    />
    <path
      fill="#FBBC05"
      d="M6.492 7.339a4.519 4.519 0 0 0-2.728 8.1l2.015-2.014a1.737 1.737 0 1 1 2.299-2.299l2.015-2.015a4.514 4.514 0 0 0-3.601-1.772Z"
    />
  </svg>
);
export default SvgGoogleCloud;
