/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface IncidentMembershipRevoked
 */
export interface IncidentMembershipRevoked {
    /**
     * 
     * @type {Actor}
     * @memberof IncidentMembershipRevoked
     */
    revoker: Actor;
    /**
     * 
     * @type {User}
     * @memberof IncidentMembershipRevoked
     */
    user: User;
}

export function IncidentMembershipRevokedFromJSON(json: any): IncidentMembershipRevoked {
    return IncidentMembershipRevokedFromJSONTyped(json, false);
}

export function IncidentMembershipRevokedFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentMembershipRevoked {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revoker': ActorFromJSON(json['revoker']),
        'user': UserFromJSON(json['user']),
    };
}

export function IncidentMembershipRevokedToJSON(value?: IncidentMembershipRevoked | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revoker': ActorToJSON(value.revoker),
        'user': UserToJSON(value.user),
    };
}

