import type { SVGProps } from "react";
import * as React from "react";
const SvgChevronDoubleUp = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 9.25 10.25 5l4.25 4.25M6 14l4.25-4.25L14.5 14"
    />
  </svg>
);
export default SvgChevronDoubleUp;
