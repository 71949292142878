import {
  SettingsUpdatePrivateStreamsSettingsRequestBody,
  UserSlackRoleEnum as SlackRoleEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import {
  Avatar,
  Callout,
  CalloutTheme,
  IconSize,
  Loader,
  SharedToasts,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { Identity, Settings } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";

import { ScopesNeededReinstallMessage } from "../../../routes/legacy/ReinstallSlackRoute";
import { useAPIMutation } from "../../../utils/swr";
import { AddToSlackButton } from "../../slack/AddToSlackButton";
import { SettingsSection } from "../SettingsSection";

export const PrivateStreamsForm = (): React.ReactElement | null => {
  const { settings } = useSettings();
  const { identity } = useIdentity();

  const canAccessPrivateStreams = !numericGateLimitReached(
    identity.feature_gates.streams_per_incident_count,
    0,
  );

  if (!canAccessPrivateStreams) {
    // This is gated behind a feature flag and billing gate
    return null;
  }

  if (!settings) {
    return <Loader />;
  }

  return <PrivateStreamsFormInner settings={settings} identity={identity} />;
};

const PrivateStreamsFormInner = ({
  settings,
  identity,
}: {
  settings: Settings;
  identity: Identity;
}) => {
  const showToast = useToast();

  const formMethods = useForm<SettingsUpdatePrivateStreamsSettingsRequestBody>({
    defaultValues: {
      private_streams_enabled: settings.misc.private_streams_enabled,
    },
  });
  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: SettingsUpdatePrivateStreamsSettingsRequestBody) =>
      await apiClient.settingsUpdatePrivateStreamsSettings({
        updatePrivateStreamsSettingsRequestBody: data,
      }),
    {
      onSuccess: () => {
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to save private streams setting",
        });
      },
    },
  );
  const isDirty =
    settings.misc.private_streams_enabled !==
    formMethods.watch("private_streams_enabled");

  const missingRequiredScopes =
    identity.slack_info?.missing_token_scopes?.filter(
      ({ scope }) => scope === "groups:write" || scope === "groups:history",
    );
  const slackInfo = identity.slack_info;
  if (!slackInfo) {
    // We do not support streams in MS Teams right now!
    return null;
  }

  let content: React.ReactNode;
  if (missingRequiredScopes && missingRequiredScopes.length > 0) {
    content = (
      <Callout theme={CalloutTheme.Info}>
        <div className="space-y-1">
          <ScopesNeededReinstallMessage
            feature={"Private streams"}
            missingTokenScopes={missingRequiredScopes}
            className={"ml-1"}
          />
          <AddToSlackButton
            url={slackInfo.install_url}
            returnPath={"settings/security"}
          />
        </div>
      </Callout>
    );
  } else if (slackInfo.user_token_owner?.slack_role === SlackRoleEnum.Owner) {
    const owner = slackInfo.user_token_owner;
    content = (
      <Callout theme={CalloutTheme.Info}>
        <Avatar
          size={IconSize.Medium}
          name={owner.name}
          url={owner.avatar_url}
          className="inline mr-1"
        />{" "}
        <span className="font-semibold inline">{owner.name}</span> (the
        connected Slack Owner) will be invited to private streams, to manage the
        channel.
      </Callout>
    );
  }

  return (
    <>
      <SettingsSection
        requiredProduct={Product.Response}
        mutation={mutation}
        formMethods={formMethods}
        enabledPath={"private_streams_enabled"}
        isDirty={isDirty && !missingRequiredScopes?.length}
        title={"Private streams"}
        explanation={`If enabled, when creating a stream you'll be able to mark it as private.
        For private streams, we'll create a private Slack channel (instead of a public one),
        and only the reporter and people that are specifically invited can access the stream.
        The stream name and stream lead will still be visible to everyone.`}
      >
        {content}
      </SettingsSection>
    </>
  );
};
