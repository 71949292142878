import type { SVGProps } from "react";
import * as React from "react";
const SvgMedal3 = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="m13.52 8.249-3.228-5.39a2.23 2.23 0 0 0-1.906-1.081H2.903A2.221 2.221 0 0 0 .997 5.142l4.854 8.127a12.469 12.469 0 0 1 7.669-5.02ZM31.031 2.905a2.23 2.23 0 0 0-1.934-1.127h-5.483c-.778 0-1.51.414-1.906 1.079L18.48 8.249c3.16.642 5.884 2.49 7.683 5.036l4.84-8.142a2.23 2.23 0 0 0 .028-2.238Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.222 20.444c0-5.392 4.388-9.777 9.778-9.777 5.39 0 9.778 4.385 9.778 9.777 0 5.392-4.388 9.778-9.778 9.778-5.39 0-9.778-4.386-9.778-9.778Zm6.72 1.976c.035 1.656 1.223 2.724 3.035 2.724 1.8 0 2.965-1.044 2.965-2.544 0-1.02-.529-1.776-1.416-2.112.72-.312 1.116-.912 1.116-1.704 0-1.284-1.056-2.208-2.665-2.208-1.728 0-2.856 1.068-2.892 2.724h1.752c.037-.828.444-1.308 1.129-1.308.6 0 .935.336.935.936 0 .732-.491 1.152-1.344 1.152h-.431v1.248h.492c.984 0 1.548.42 1.548 1.236 0 .72-.433 1.164-1.189 1.164-.768 0-1.223-.48-1.26-1.308h-1.776Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMedal3;
