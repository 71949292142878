import {
  RadioButtonGroup,
  RadioButtonGroupOption,
} from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { omit } from "lodash";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const BooleanRadioButtonGroupV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, BooleanRadioButtonGroupProps>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    BooleanRadioButtonGroupProps
  >(rest);
  const {
    // We render multiple inputs within this component, so use a controller
    // rather than a ref.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  const {
    trueOption: propsTrueOption,
    falseOption: propsFalseOption,
    ...restInputProps
  } = inputProps;

  const trueOption: RadioButtonGroupOption = {
    ...propsTrueOption,
    value: "true",
  };
  const falseOption: RadioButtonGroupOption = {
    ...propsFalseOption,
    value: "false",
  };

  const onChange = (valueAsString: string) => {
    const val = valueAsString === "true";
    field.onChange(val);

    onValueChange && onValueChange(val);
  };

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <div>
        <RadioButtonGroup
          value={String(field.value) || "false"}
          options={
            props.trueFirst
              ? [trueOption, falseOption]
              : [falseOption, trueOption]
          }
          {...omit(field, "value", "onChange")}
          {...restInputProps}
          onChange={onChange}
        />
      </div>
    </FormInputWrapper>
  );
};

type BooleanRadioButtonGroupOption = Omit<RadioButtonGroupOption, "value">;

type BooleanRadioButtonGroupProps = {
  trueOption: BooleanRadioButtonGroupOption;
  boxed?: boolean;
  horizontal?: boolean;
  falseOption: BooleanRadioButtonGroupOption;
  name: string;
  srLabel: string;
  value: string;
  trueFirst?: boolean;
  onValueChange?: (val: boolean) => void;
  radioClassName?: string;
};
