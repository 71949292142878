import { Callout, CalloutTheme } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceSplunkSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <>
      <Callout theme={CalloutTheme.Info} className="mb-4">
        These instructions relate to <strong>Splunk Cloud</strong> but the
        approach is similar if you&rsquo;re using{" "}
        <strong>Splunk Enterprise</strong>.
      </Callout>
      <SetupInfoNumberedList>
        <div className="space-y-4">
          <p>
            If you dont have an existing alert, create one by navigating to the{" "}
            <strong>Search</strong> page, enter a search that you want to alert
            on and click <strong>Save As &gt; Alert</strong>.
          </p>
          <p>
            If you have an existing alert, navigate to <strong>Alerts</strong>{" "}
            and click <strong>Edit</strong> on the alert you&rsquo;d like to
            connect.
          </p>
        </div>
        <SetupInfoSingleLineBlocks
          intro={
            <>
              Click <strong>Add actions +</strong> at the bottom of the alert
              configuration form, and add a <strong>Webhook</strong> action.
            </>
          }
          blocks={[
            {
              label: (
                <>
                  Enter the following in the <strong>URL</strong> input:
                </>
              ),
              title: "Webhook URL",
              code: `${publicApiUrl()}/v2/alert_events/splunk/${
                alertSourceConfig.id
              }`,
            },
          ]}
        />
        <p>
          Save the alert, trigger it, and the payload will appear on the right.
        </p>
        <div className="space-y-4">
          <p>
            If you&rsquo;re using a custom action to send a webhook,
            you&rsquo;ll need to provide an alert <code>sid</code> and an
            optional <code>metadata</code> object along with an{" "}
            <code>application/json</code> content type header. We&rsquo;ll parse
            the fields from the metadata object and make them available as alert
            attributes.
          </p>
          <CodeBlock
            title={"Custom action payload"}
            code={`{
  "sid": "<sid>",
  "metadata": {
    "custom_field": "..."
  }
}`}
          />
        </div>
      </SetupInfoNumberedList>
    </>
  );
};
