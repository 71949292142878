import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  LoadingModal,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { isEmpty } from "lodash";
import { groupDependentResources } from "src/components/settings/DeletionConfirmationModal";
import { useAPI } from "src/utils/swr";

export const WorkflowsDeletionConfirmationModal = ({
  onClose,
  onConfirm,
  onDisable,
  workflowId,
  workflowName,
  isDeleting,
  isDisabling,
  isDisabled,
}: {
  onClose: () => void;
  onConfirm: () => void;
  onDisable: () => void;
  workflowId: string;
  workflowName: string;
  isDeleting: boolean;
  isDisabling: boolean;
  isDisabled: boolean;
}) => {
  const { data, isValidating, error } = useAPI(
    "engineFindDependentResourcesForMultiple",
    {
      findDependentResourcesForMultipleRequestBody: {
        resources: [{ id: workflowId, resource_type: "Workflow" }],
      },
    },
  );

  const modalProps = {
    onClose,
    title: "Delete workflow",
  };

  if (isValidating) {
    return <LoadingModal {...modalProps} />;
  }

  if (error) {
    return (
      <ErrorModal
        error={error}
        description="Failed to load dependent resources"
        {...modalProps}
      />
    );
  }

  const { requiresDeletionResources } = groupDependentResources(
    data?.dependent_resources ?? [],
  );

  if (!isEmpty(requiresDeletionResources)) {
    return (
      <ConfirmationDialog
        analyticsTrackingId={null}
        isOpen
        onCancel={onClose}
        {...modalProps}
        hideConfirmButton
        onConfirm={() => null}
      >
        <DependentResourceList
          title={workflowName}
          requiresDeletionResources={requiresDeletionResources}
        />
      </ConfirmationDialog>
    );
  }

  return (
    <Modal
      isOpen={true}
      analyticsTrackingId="workflows-v2-delete-workflow-modal"
      {...modalProps}
    >
      <ModalContent>
        <Callout theme={CalloutTheme.Warning}>
          Are you sure you want to permanently delete{" "}
          <span className="font-semibold">{workflowName}</span>?
          {!isDisabled && (
            <> Instead you could disable it to prevent it from running.</>
          )}
        </Callout>
      </ModalContent>
      <ModalFooter>
        <div className="flex flex-row gap-1">
          <Button
            theme={ButtonTheme.Secondary}
            onClick={onClose}
            analyticsTrackingId="workflows-v2-delete-workflow-modal-cancel"
          >
            Cancel
          </Button>
          {!isDisabled && (
            <Button
              theme={ButtonTheme.Secondary}
              onClick={onDisable}
              analyticsTrackingId="workflows-v2-delete-workflow-modal-disable"
              loading={isDisabling}
              className="!ml-0"
            >
              Disable workflow
            </Button>
          )}
          <Button
            theme={ButtonTheme.Destroy}
            onClick={onConfirm}
            analyticsTrackingId="workflows-v2-delete-workflow-modal-confirm"
            loading={isDeleting}
            className="!ml-0"
          >
            Delete workflow
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
