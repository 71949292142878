import { Product } from "@incident-shared/billing";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useForm } from "react-hook-form";
import {
  InitialMessageSettings as FormType,
  ScopeNameEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../SettingsSection";

export const AutomationInitialMessageEditForm = () => {
  const { settings } = useSettings();
  if (!settings) {
    return null;
  }

  return <AutomationInitialMessageEditFormInner settings={settings} />;
};

const toForm = (settings: Settings): FormType => ({
  ...settings.workflows_alpha.initial_message,
});

const AutomationInitialMessageEditFormInner = ({
  settings,
}: {
  settings: Settings;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const { featureDeprecatedAutomationOptions } = useFlags();

  const formMethods = useForm({
    defaultValues: toForm(settings),
  });
  const {
    setError,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;
  const showToast = useToast();
  const initialMessageEnabled = watch("enabled");

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data) =>
      await apiClient.settingsUpdateInitialMessage({
        updateInitialMessageRequestBody: { initial_message: data },
      }),
    {
      setError,
      onSuccess: async ({ settings }) => {
        reset(toForm(settings));
        showToast(SharedToasts.SETTINGS_SAVED);
      },
    },
  );

  if (!featureDeprecatedAutomationOptions) {
    if (!initialMessageEnabled && !isDirty) {
      // We're looking to deprecate Workflows Alpha
      // If someone wants this feature, they should instead do it via a workflow
      return <></>;
    }
  }

  return (
    <SettingsSection
      requiredProduct={Product.Response}
      mutation={mutation}
      formMethods={formMethods}
      enabledPath="enabled"
      title="Initial message in incident channel"
      explanation={`With this feature enabled, we'll send a custom message to each incident channel when an incident begins.`}
    >
      <TextareaV2
        formMethods={formMethods}
        name="config.message"
        disabled={!canEditSettings}
        placeholder={"Good luck team! :muscle: _hugops_"}
        rows={4}
        autoComplete="none"
      />
      <p className="mt-1 text-sm text-slate-700">
        You can use Slack{" "}
        <a
          href="https://api.slack.com/reference/surfaces/formatting#basics"
          rel="noreferrer"
          target="_blank"
          className="underline"
        >
          mrkdwn
        </a>{" "}
        in this field
      </p>
    </SettingsSection>
  );
};
