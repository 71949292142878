/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentTimestampsCreateDurationMetricRequestBody,
    IncidentTimestampsCreateDurationMetricRequestBodyFromJSON,
    IncidentTimestampsCreateDurationMetricRequestBodyToJSON,
    IncidentTimestampsCreateDurationMetricResponseBody,
    IncidentTimestampsCreateDurationMetricResponseBodyFromJSON,
    IncidentTimestampsCreateDurationMetricResponseBodyToJSON,
    IncidentTimestampsCreateRequestBody,
    IncidentTimestampsCreateRequestBodyFromJSON,
    IncidentTimestampsCreateRequestBodyToJSON,
    IncidentTimestampsCreateResponseBody,
    IncidentTimestampsCreateResponseBodyFromJSON,
    IncidentTimestampsCreateResponseBodyToJSON,
    IncidentTimestampsCreateSetByRuleRequestBody,
    IncidentTimestampsCreateSetByRuleRequestBodyFromJSON,
    IncidentTimestampsCreateSetByRuleRequestBodyToJSON,
    IncidentTimestampsCreateSetByRuleResponseBody,
    IncidentTimestampsCreateSetByRuleResponseBodyFromJSON,
    IncidentTimestampsCreateSetByRuleResponseBodyToJSON,
    IncidentTimestampsListDurationMetricsResponseBody,
    IncidentTimestampsListDurationMetricsResponseBodyFromJSON,
    IncidentTimestampsListDurationMetricsResponseBodyToJSON,
    IncidentTimestampsListResponseBody,
    IncidentTimestampsListResponseBodyFromJSON,
    IncidentTimestampsListResponseBodyToJSON,
    IncidentTimestampsShowDurationMetricResponseBody,
    IncidentTimestampsShowDurationMetricResponseBodyFromJSON,
    IncidentTimestampsShowDurationMetricResponseBodyToJSON,
    IncidentTimestampsShowResponseBody,
    IncidentTimestampsShowResponseBodyFromJSON,
    IncidentTimestampsShowResponseBodyToJSON,
    IncidentTimestampsUpdateDurationMetricRequestBody,
    IncidentTimestampsUpdateDurationMetricRequestBodyFromJSON,
    IncidentTimestampsUpdateDurationMetricRequestBodyToJSON,
    IncidentTimestampsUpdateDurationMetricResponseBody,
    IncidentTimestampsUpdateDurationMetricResponseBodyFromJSON,
    IncidentTimestampsUpdateDurationMetricResponseBodyToJSON,
    IncidentTimestampsUpdateRanksDurationMetricsRequestBody,
    IncidentTimestampsUpdateRanksDurationMetricsRequestBodyFromJSON,
    IncidentTimestampsUpdateRanksDurationMetricsRequestBodyToJSON,
    IncidentTimestampsUpdateRanksDurationMetricsResponseBody,
    IncidentTimestampsUpdateRanksDurationMetricsResponseBodyFromJSON,
    IncidentTimestampsUpdateRanksDurationMetricsResponseBodyToJSON,
    IncidentTimestampsUpdateRanksRequestBody,
    IncidentTimestampsUpdateRanksRequestBodyFromJSON,
    IncidentTimestampsUpdateRanksRequestBodyToJSON,
    IncidentTimestampsUpdateRanksResponseBody,
    IncidentTimestampsUpdateRanksResponseBodyFromJSON,
    IncidentTimestampsUpdateRanksResponseBodyToJSON,
    IncidentTimestampsUpdateRequestBody,
    IncidentTimestampsUpdateRequestBodyFromJSON,
    IncidentTimestampsUpdateRequestBodyToJSON,
    IncidentTimestampsUpdateResponseBody,
    IncidentTimestampsUpdateResponseBodyFromJSON,
    IncidentTimestampsUpdateResponseBodyToJSON,
    IncidentTimestampsUpdateSetByRuleRequestBody,
    IncidentTimestampsUpdateSetByRuleRequestBodyFromJSON,
    IncidentTimestampsUpdateSetByRuleRequestBodyToJSON,
    IncidentTimestampsUpdateSetByRuleResponseBody,
    IncidentTimestampsUpdateSetByRuleResponseBodyFromJSON,
    IncidentTimestampsUpdateSetByRuleResponseBodyToJSON,
} from '../models';

export interface IncidentTimestampsCreateRequest {
    createRequestBody: IncidentTimestampsCreateRequestBody;
}

export interface IncidentTimestampsCreateDurationMetricRequest {
    createDurationMetricRequestBody: IncidentTimestampsCreateDurationMetricRequestBody;
}

export interface IncidentTimestampsCreateSetByRuleRequest {
    createSetByRuleRequestBody: IncidentTimestampsCreateSetByRuleRequestBody;
}

export interface IncidentTimestampsDestroyRequest {
    id: string;
}

export interface IncidentTimestampsDestroyDurationMetricRequest {
    id: string;
}

export interface IncidentTimestampsDestroySetByRuleRequest {
    id: string;
}

export interface IncidentTimestampsShowRequest {
    id: string;
}

export interface IncidentTimestampsShowDurationMetricRequest {
    id: string;
}

export interface IncidentTimestampsUpdateRequest {
    id: string;
    updateRequestBody: IncidentTimestampsUpdateRequestBody;
}

export interface IncidentTimestampsUpdateDurationMetricRequest {
    id: string;
    updateDurationMetricRequestBody: IncidentTimestampsUpdateDurationMetricRequestBody;
}

export interface IncidentTimestampsUpdateRanksRequest {
    updateRanksRequestBody: IncidentTimestampsUpdateRanksRequestBody;
}

export interface IncidentTimestampsUpdateRanksDurationMetricsRequest {
    updateRanksDurationMetricsRequestBody: IncidentTimestampsUpdateRanksDurationMetricsRequestBody;
}

export interface IncidentTimestampsUpdateSetByRuleRequest {
    id: string;
    updateSetByRuleRequestBody: IncidentTimestampsUpdateSetByRuleRequestBody;
}

/**
 * 
 */
export class IncidentTimestampsApi extends runtime.BaseAPI {

    /**
     * Create a new incident timestamp
     * Create IncidentTimestamps
     */
    async incidentTimestampsCreateRaw(requestParameters: IncidentTimestampsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentTimestampsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident timestamp
     * Create IncidentTimestamps
     */
    async incidentTimestampsCreate(requestParameters: IncidentTimestampsCreateRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsCreateResponseBody> {
        const response = await this.incidentTimestampsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new duration metric
     * CreateDurationMetric IncidentTimestamps
     */
    async incidentTimestampsCreateDurationMetricRaw(requestParameters: IncidentTimestampsCreateDurationMetricRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsCreateDurationMetricResponseBody>> {
        if (requestParameters.createDurationMetricRequestBody === null || requestParameters.createDurationMetricRequestBody === undefined) {
            throw new runtime.RequiredError('createDurationMetricRequestBody','Required parameter requestParameters.createDurationMetricRequestBody was null or undefined when calling incidentTimestampsCreateDurationMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps/incident_duration_metrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsCreateDurationMetricRequestBodyToJSON(requestParameters.createDurationMetricRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsCreateDurationMetricResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new duration metric
     * CreateDurationMetric IncidentTimestamps
     */
    async incidentTimestampsCreateDurationMetric(requestParameters: IncidentTimestampsCreateDurationMetricRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsCreateDurationMetricResponseBody> {
        const response = await this.incidentTimestampsCreateDurationMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new incident timestamp
     * CreateSetByRule IncidentTimestamps
     */
    async incidentTimestampsCreateSetByRuleRaw(requestParameters: IncidentTimestampsCreateSetByRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsCreateSetByRuleResponseBody>> {
        if (requestParameters.createSetByRuleRequestBody === null || requestParameters.createSetByRuleRequestBody === undefined) {
            throw new runtime.RequiredError('createSetByRuleRequestBody','Required parameter requestParameters.createSetByRuleRequestBody was null or undefined when calling incidentTimestampsCreateSetByRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps/set_by_rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsCreateSetByRuleRequestBodyToJSON(requestParameters.createSetByRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsCreateSetByRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident timestamp
     * CreateSetByRule IncidentTimestamps
     */
    async incidentTimestampsCreateSetByRule(requestParameters: IncidentTimestampsCreateSetByRuleRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsCreateSetByRuleResponseBody> {
        const response = await this.incidentTimestampsCreateSetByRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an incident timestamp
     * Destroy IncidentTimestamps
     */
    async incidentTimestampsDestroyRaw(requestParameters: IncidentTimestampsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_timestamps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an incident timestamp
     * Destroy IncidentTimestamps
     */
    async incidentTimestampsDestroy(requestParameters: IncidentTimestampsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentTimestampsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an incident duration metric
     * DestroyDurationMetric IncidentTimestamps
     */
    async incidentTimestampsDestroyDurationMetricRaw(requestParameters: IncidentTimestampsDestroyDurationMetricRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsDestroyDurationMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_timestamps/incident_duration_metrics/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an incident duration metric
     * DestroyDurationMetric IncidentTimestamps
     */
    async incidentTimestampsDestroyDurationMetric(requestParameters: IncidentTimestampsDestroyDurationMetricRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentTimestampsDestroyDurationMetricRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a set by rule
     * DestroySetByRule IncidentTimestamps
     */
    async incidentTimestampsDestroySetByRuleRaw(requestParameters: IncidentTimestampsDestroySetByRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsDestroySetByRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_timestamps/set_by_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a set by rule
     * DestroySetByRule IncidentTimestamps
     */
    async incidentTimestampsDestroySetByRule(requestParameters: IncidentTimestampsDestroySetByRuleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentTimestampsDestroySetByRuleRaw(requestParameters, initOverrides);
    }

    /**
     * List incident timestamps
     * List IncidentTimestamps
     */
    async incidentTimestampsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_timestamps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident timestamps
     * List IncidentTimestamps
     */
    async incidentTimestampsList(initOverrides?: RequestInit): Promise<IncidentTimestampsListResponseBody> {
        const response = await this.incidentTimestampsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incident duration metrics
     * ListDurationMetrics IncidentTimestamps
     */
    async incidentTimestampsListDurationMetricsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsListDurationMetricsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_timestamps/incident_duration_metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsListDurationMetricsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident duration metrics
     * ListDurationMetrics IncidentTimestamps
     */
    async incidentTimestampsListDurationMetrics(initOverrides?: RequestInit): Promise<IncidentTimestampsListDurationMetricsResponseBody> {
        const response = await this.incidentTimestampsListDurationMetricsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show a single incident timestamp
     * Show IncidentTimestamps
     */
    async incidentTimestampsShowRaw(requestParameters: IncidentTimestampsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_timestamps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single incident timestamp
     * Show IncidentTimestamps
     */
    async incidentTimestampsShow(requestParameters: IncidentTimestampsShowRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsShowResponseBody> {
        const response = await this.incidentTimestampsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single incident duration metric
     * ShowDurationMetric IncidentTimestamps
     */
    async incidentTimestampsShowDurationMetricRaw(requestParameters: IncidentTimestampsShowDurationMetricRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsShowDurationMetricResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsShowDurationMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_timestamps/incident_duration_metrics/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsShowDurationMetricResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single incident duration metric
     * ShowDurationMetric IncidentTimestamps
     */
    async incidentTimestampsShowDurationMetric(requestParameters: IncidentTimestampsShowDurationMetricRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsShowDurationMetricResponseBody> {
        const response = await this.incidentTimestampsShowDurationMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident timestamp
     * Update IncidentTimestamps
     */
    async incidentTimestampsUpdateRaw(requestParameters: IncidentTimestampsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling incidentTimestampsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident timestamp
     * Update IncidentTimestamps
     */
    async incidentTimestampsUpdate(requestParameters: IncidentTimestampsUpdateRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsUpdateResponseBody> {
        const response = await this.incidentTimestampsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident duration metric
     * UpdateDurationMetric IncidentTimestamps
     */
    async incidentTimestampsUpdateDurationMetricRaw(requestParameters: IncidentTimestampsUpdateDurationMetricRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsUpdateDurationMetricResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsUpdateDurationMetric.');
        }

        if (requestParameters.updateDurationMetricRequestBody === null || requestParameters.updateDurationMetricRequestBody === undefined) {
            throw new runtime.RequiredError('updateDurationMetricRequestBody','Required parameter requestParameters.updateDurationMetricRequestBody was null or undefined when calling incidentTimestampsUpdateDurationMetric.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps/incident_duration_metrics/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsUpdateDurationMetricRequestBodyToJSON(requestParameters.updateDurationMetricRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsUpdateDurationMetricResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident duration metric
     * UpdateDurationMetric IncidentTimestamps
     */
    async incidentTimestampsUpdateDurationMetric(requestParameters: IncidentTimestampsUpdateDurationMetricRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsUpdateDurationMetricResponseBody> {
        const response = await this.incidentTimestampsUpdateDurationMetricRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing timestamps
     * UpdateRanks IncidentTimestamps
     */
    async incidentTimestampsUpdateRanksRaw(requestParameters: IncidentTimestampsUpdateRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsUpdateRanksResponseBody>> {
        if (requestParameters.updateRanksRequestBody === null || requestParameters.updateRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateRanksRequestBody','Required parameter requestParameters.updateRanksRequestBody was null or undefined when calling incidentTimestampsUpdateRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsUpdateRanksRequestBodyToJSON(requestParameters.updateRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsUpdateRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing timestamps
     * UpdateRanks IncidentTimestamps
     */
    async incidentTimestampsUpdateRanks(requestParameters: IncidentTimestampsUpdateRanksRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsUpdateRanksResponseBody> {
        const response = await this.incidentTimestampsUpdateRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of duration metrics
     * UpdateRanksDurationMetrics IncidentTimestamps
     */
    async incidentTimestampsUpdateRanksDurationMetricsRaw(requestParameters: IncidentTimestampsUpdateRanksDurationMetricsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsUpdateRanksDurationMetricsResponseBody>> {
        if (requestParameters.updateRanksDurationMetricsRequestBody === null || requestParameters.updateRanksDurationMetricsRequestBody === undefined) {
            throw new runtime.RequiredError('updateRanksDurationMetricsRequestBody','Required parameter requestParameters.updateRanksDurationMetricsRequestBody was null or undefined when calling incidentTimestampsUpdateRanksDurationMetrics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps/incident_duration_metrics/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsUpdateRanksDurationMetricsRequestBodyToJSON(requestParameters.updateRanksDurationMetricsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsUpdateRanksDurationMetricsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of duration metrics
     * UpdateRanksDurationMetrics IncidentTimestamps
     */
    async incidentTimestampsUpdateRanksDurationMetrics(requestParameters: IncidentTimestampsUpdateRanksDurationMetricsRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsUpdateRanksDurationMetricsResponseBody> {
        const response = await this.incidentTimestampsUpdateRanksDurationMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new incident timestamp
     * UpdateSetByRule IncidentTimestamps
     */
    async incidentTimestampsUpdateSetByRuleRaw(requestParameters: IncidentTimestampsUpdateSetByRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimestampsUpdateSetByRuleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTimestampsUpdateSetByRule.');
        }

        if (requestParameters.updateSetByRuleRequestBody === null || requestParameters.updateSetByRuleRequestBody === undefined) {
            throw new runtime.RequiredError('updateSetByRuleRequestBody','Required parameter requestParameters.updateSetByRuleRequestBody was null or undefined when calling incidentTimestampsUpdateSetByRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_timestamps/set_by_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimestampsUpdateSetByRuleRequestBodyToJSON(requestParameters.updateSetByRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimestampsUpdateSetByRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident timestamp
     * UpdateSetByRule IncidentTimestamps
     */
    async incidentTimestampsUpdateSetByRule(requestParameters: IncidentTimestampsUpdateSetByRuleRequest, initOverrides?: RequestInit): Promise<IncidentTimestampsUpdateSetByRuleResponseBody> {
        const response = await this.incidentTimestampsUpdateSetByRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
