/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageContentLiveWindow,
    InternalStatusPageContentLiveWindowFromJSON,
    InternalStatusPageContentLiveWindowFromJSONTyped,
    InternalStatusPageContentLiveWindowToJSON,
} from './InternalStatusPageContentLiveWindow';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';

/**
 * 
 * @export
 * @interface InternalStatusPageContentIncident
 */
export interface InternalStatusPageContentIncident {
    /**
     * External identifier for the incident - often displayed with an INC- prefix
     * @type {number}
     * @memberof InternalStatusPageContentIncident
     */
    external_id: number;
    /**
     * Time periods that the incident was live for
     * @type {Array<InternalStatusPageContentLiveWindow>}
     * @memberof InternalStatusPageContentIncident
     */
    live_windows: Array<InternalStatusPageContentLiveWindow>;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof InternalStatusPageContentIncident
     */
    name: string;
    /**
     * 
     * @type {Severity}
     * @memberof InternalStatusPageContentIncident
     */
    severity?: Severity;
}

export function InternalStatusPageContentIncidentFromJSON(json: any): InternalStatusPageContentIncident {
    return InternalStatusPageContentIncidentFromJSONTyped(json, false);
}

export function InternalStatusPageContentIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageContentIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': json['external_id'],
        'live_windows': ((json['live_windows'] as Array<any>).map(InternalStatusPageContentLiveWindowFromJSON)),
        'name': json['name'],
        'severity': !exists(json, 'severity') ? undefined : SeverityFromJSON(json['severity']),
    };
}

export function InternalStatusPageContentIncidentToJSON(value?: InternalStatusPageContentIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'live_windows': ((value.live_windows as Array<any>).map(InternalStatusPageContentLiveWindowToJSON)),
        'name': value.name,
        'severity': SeverityToJSON(value.severity),
    };
}

