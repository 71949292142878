/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPath,
    EscalationPathFromJSON,
    EscalationPathFromJSONTyped,
    EscalationPathToJSON,
} from './EscalationPath';
import {
    EscalationPathLevelSlim,
    EscalationPathLevelSlimFromJSON,
    EscalationPathLevelSlimFromJSONTyped,
    EscalationPathLevelSlimToJSON,
} from './EscalationPathLevelSlim';
import {
    EscalationPathShift,
    EscalationPathShiftFromJSON,
    EscalationPathShiftFromJSONTyped,
    EscalationPathShiftToJSON,
} from './EscalationPathShift';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EscalationPathStatusForUser
 */
export interface EscalationPathStatusForUser {
    /**
     * 
     * @type {EscalationPathLevelSlim}
     * @memberof EscalationPathStatusForUser
     */
    current_first_usable_level?: EscalationPathLevelSlim;
    /**
     * 
     * @type {EscalationPathLevelSlim}
     * @memberof EscalationPathStatusForUser
     */
    current_level_for_user?: EscalationPathLevelSlim;
    /**
     * 
     * @type {EscalationPathShift}
     * @memberof EscalationPathStatusForUser
     */
    current_shift_for_user?: EscalationPathShift;
    /**
     * The progress of the current shift, as a percentage fraction
     * @type {number}
     * @memberof EscalationPathStatusForUser
     */
    current_shift_progress?: number;
    /**
     * All users currently on-call for this escalation path
     * @type {Array<User>}
     * @memberof EscalationPathStatusForUser
     */
    currently_on_call_users: Array<User>;
    /**
     * 
     * @type {EscalationPath}
     * @memberof EscalationPathStatusForUser
     */
    escalation_path: EscalationPath;
    /**
     * 
     * @type {EscalationPathLevelSlim}
     * @memberof EscalationPathStatusForUser
     */
    most_significant_level_for_user?: EscalationPathLevelSlim;
    /**
     * 
     * @type {EscalationPathShift}
     * @memberof EscalationPathStatusForUser
     */
    next_shift_for_user?: EscalationPathShift;
    /**
     * The status of the user on this escalation path
     * @type {string}
     * @memberof EscalationPathStatusForUser
     */
    status: EscalationPathStatusForUserStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathStatusForUserStatusEnum {
    OnCallPrimary = 'on-call-primary',
    OnCallSecondary = 'on-call-secondary',
    OnCallInFuture = 'on-call-in-future',
    NeverOnCall = 'never-on-call'
}

export function EscalationPathStatusForUserFromJSON(json: any): EscalationPathStatusForUser {
    return EscalationPathStatusForUserFromJSONTyped(json, false);
}

export function EscalationPathStatusForUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathStatusForUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_first_usable_level': !exists(json, 'current_first_usable_level') ? undefined : EscalationPathLevelSlimFromJSON(json['current_first_usable_level']),
        'current_level_for_user': !exists(json, 'current_level_for_user') ? undefined : EscalationPathLevelSlimFromJSON(json['current_level_for_user']),
        'current_shift_for_user': !exists(json, 'current_shift_for_user') ? undefined : EscalationPathShiftFromJSON(json['current_shift_for_user']),
        'current_shift_progress': !exists(json, 'current_shift_progress') ? undefined : json['current_shift_progress'],
        'currently_on_call_users': ((json['currently_on_call_users'] as Array<any>).map(UserFromJSON)),
        'escalation_path': EscalationPathFromJSON(json['escalation_path']),
        'most_significant_level_for_user': !exists(json, 'most_significant_level_for_user') ? undefined : EscalationPathLevelSlimFromJSON(json['most_significant_level_for_user']),
        'next_shift_for_user': !exists(json, 'next_shift_for_user') ? undefined : EscalationPathShiftFromJSON(json['next_shift_for_user']),
        'status': json['status'],
    };
}

export function EscalationPathStatusForUserToJSON(value?: EscalationPathStatusForUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_first_usable_level': EscalationPathLevelSlimToJSON(value.current_first_usable_level),
        'current_level_for_user': EscalationPathLevelSlimToJSON(value.current_level_for_user),
        'current_shift_for_user': EscalationPathShiftToJSON(value.current_shift_for_user),
        'current_shift_progress': value.current_shift_progress,
        'currently_on_call_users': ((value.currently_on_call_users as Array<any>).map(UserToJSON)),
        'escalation_path': EscalationPathToJSON(value.escalation_path),
        'most_significant_level_for_user': EscalationPathLevelSlimToJSON(value.most_significant_level_for_user),
        'next_shift_for_user': EscalationPathShiftToJSON(value.next_shift_for_user),
        'status': value.status,
    };
}

