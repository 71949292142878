import { Icon, IconEnum } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { CATALOG_TYPE_CATEGORY_CONFIG } from "../catalog-type-form/CatalogTypeCategoryPicker";

export const CategoryFilterBar = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (val: string) => void;
}) => {
  const options: { label: string; icon: IconEnum; value: string }[] = [
    {
      label: "All types",
      icon: IconEnum.Components,
      value: "",
    },
  ];

  Object.entries(CATALOG_TYPE_CATEGORY_CONFIG).forEach(([category, config]) => {
    options.push({
      value: category,
      ...config,
    });
  });

  return (
    <div
      className="w-full grid gap-2 border-b border-stroke"
      style={{
        gridTemplateColumns: `repeat(${options.length}, minmax(0, 1fr))`,
      }}
    >
      {options.map((option) => {
        const isSelected = value === option.value;
        return (
          <button
            key={option.value}
            className={tcx(
              "flex flex-col gap-1 items-center h-full py-4 border-b border-transparent",
              isSelected
                ? "text-content-primary border-content-primary"
                : "text-content-tertiary hover:text-content-primary",
            )}
            onClick={() => onChange(option.value)}
          >
            <Icon id={option.icon} />
            <div className="text-xs-med">{option.label}</div>
          </button>
        );
      })}
    </div>
  );
};
