import {
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { Condition, EngineScope } from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { filterScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import {
  EngineRefIsSelectable,
  MaybeRef,
} from "../ReferenceSelectorPopover/ReferenceSelectorPopover";
import { AddConditionPopover } from "./AddConditionPopover";
import { SelectedCondition } from "./SelectedCondition";

export type ExplanationStyle = "available" | "apply";

export type EditableConditionsListProps = {
  selectedConditions: Condition[];
  scope: EngineScope;
  onDeleteCondition: (idx: number) => void;
  onEditCondition: (idx: number, condition: Condition) => void;
  onAddCondition: (condition: Condition) => void;
  wrapperClassName?: string;
  listClassName?: string;
  // boxless is used for contexts where we don't want a container (e.g. inside expressions)
  boxless?: boolean;
  conditionLabel?: "condition" | "requirement" | "filter"; // The way we describe conditions to the user, e.g. `Edit {label}`. The default is `condition`.
  disabled?: boolean;
  allowFilteringByExpression?: boolean;
  collapseButtons?: boolean;
  isSelectable?: EngineRefIsSelectable;
} & ConditionsListSentenceProps &
  FixedConditionProps;

export type FixedConditionProps = {
  fixedConditions?: Condition[];
  fixedConditionExplainText?: React.ReactNode;
};

// EditableConditionsList displays a list of selected engine condition, with hooks to
// edit/update/remove the conditions.
export function EditableConditionsList({
  selectedConditions,
  scope,
  onDeleteCondition: onDelete,
  onEditCondition: onEdit,
  onAddCondition: onAdd,
  wrapperClassName,
  listClassName = "space-y-1",
  conditionLabel = "condition",
  disabled = false,
  allowFilteringByExpression = false,
  isSelectable,
  boxless = false,
  fixedConditions = [],
  fixedConditionExplainText = "These conditions are fixed and cannot be removed.",
  collapseButtons,
  ...sentenceProps
}: EditableConditionsListProps): React.ReactElement {
  const availableConditions = filterScope(
    scope,
    (c) => !selectedConditions.some((sc) => sc.subject.reference === c.key),
  ).references;

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (resourcesLoading) {
    return <Loader />;
  }

  const haveConditions =
    fixedConditions?.length > 0 || selectedConditions?.length > 0;

  const isSelectableWithFixedConditions = (entry: MaybeRef) => {
    if (fixedConditions.length > 0) {
      const unselectableReferences: string[] = fixedConditions.map(
        (condition) => {
          return condition.subject.reference;
        },
      );
      if (entry.key && unselectableReferences.includes(entry.key)) {
        return false;
      }
    }
    if (isSelectable) {
      return isSelectable(entry);
    }
    return true;
  };

  const hideButtonText =
    collapseButtons &&
    (fixedConditions.length > 0 || selectedConditions.length > 0);

  return (
    <Wrapper className={wrapperClassName} boxless={boxless}>
      <>
        <IntroSentence
          {...sentenceProps}
          isEmpty={!selectedConditions || selectedConditions.length === 0}
        />
        <div
          className={tcx(
            collapseButtons
              ? "flex justify-between items-end space-x-2"
              : "space-y-2",
          )}
        >
          {haveConditions && (
            <ul className={listClassName}>
              {fixedConditions?.map((condition, i) => (
                <Tooltip
                  analyticsTrackingId={null}
                  content={fixedConditionExplainText}
                  delayDuration={0}
                  key={i}
                >
                  {/* We need this div so that the tooltip magic can position itself correctly */}
                  <div className="w-fit">
                    <SelectedCondition
                      condition={condition}
                      className="text-content-tertiary mb-0"
                      theme="slate"
                      noTooltip
                      inline
                    />
                  </div>
                </Tooltip>
              ))}
              {selectedConditions.map((selectedCondition, i) => {
                return (
                  <SelectedCondition
                    scopeLoading={false}
                    key={selectedCondition.subject.reference}
                    allowExpressions={allowFilteringByExpression}
                    condition={selectedCondition}
                    scope={scope}
                    resources={resources}
                    onDelete={disabled ? undefined : () => onDelete(i)}
                    theme="slate"
                    handleEdit={(newCondition: Condition) =>
                      onEdit(i, newCondition)
                    }
                  />
                );
              })}
            </ul>
          )}
          <AddConditionPopover
            allowExpressions={allowFilteringByExpression}
            selectedConditions={selectedConditions}
            isSelectable={isSelectableWithFixedConditions}
            scope={scope}
            resources={resources}
            onAddCondition={onAdd as unknown as (condition: Condition) => void}
            renderTriggerButton={({ onClick }) => (
              <Button
                theme={
                  collapseButtons ? ButtonTheme.Secondary : ButtonTheme.Naked
                }
                size={BadgeSize.Medium}
                onClick={onClick}
                analyticsTrackingId="add-condition"
                disabled={availableConditions.length === 0 || disabled}
                icon={IconEnum.Add}
                title=""
              >
                {hideButtonText ? undefined : `Add ${conditionLabel}`}
              </Button>
            )}
          />
        </div>
      </>
    </Wrapper>
  );
}

const Wrapper = ({
  boxless,
  children,
  className,
}: {
  boxless: boolean;
  children: React.ReactNode;
  className?: string;
}): React.ReactElement => {
  const sharedClasses = "text-sm space-y-2";
  if (boxless) {
    return (
      <div
        data-testid="conditions-list"
        className={tcx(sharedClasses, className)}
      >
        {children}
      </div>
    );
  }

  return (
    <ContentBox
      className={tcx(
        sharedClasses,
        "bg-white p-4 flex flex-col justify-center",
        className,
      )}
      data-testid="conditions-list"
    >
      {children}
    </ContentBox>
  );
};

type PreBuiltSentenceProps = {
  // This is the name of the resource that these conditions will be associated
  // with, for example "workflow", "announcement rule" or "incident role".
  entityNameLabel: string;
  // Refers to how you built the scope, for example "incidents" will translate
  // to a "these fields will apply to all incidents".
  subjectsLabel: string;
  // Refers to the verb in between, for example 'these fields will apply to all
  // incidents vs. these fields will be available for all incidents'. Defaults
  // to 'apply'
  explanationStyle?: ExplanationStyle;

  emptyIntroSentence?: never;
  populatedIntroSentence?: never;
  hideIntroSentence?: never;
};
type CustomSentenceProps = {
  // Overrides the empty state with a specific node
  emptyIntroSentence?: React.ReactNode;
  // Overrides the non empty state with a specific node
  populatedIntroSentence?: React.ReactNode;

  entityNameLabel?: never;
  subjectsLabel?: never;
  explanationStyle?: never;
  hideIntroSentence?: never;
};
type NoSentenceProps = {
  hideIntroSentence: true;

  emptyIntroSentence?: never;
  populatedIntroSentence?: never;
  entityNameLabel?: never;
  subjectsLabel?: never;
  explanationStyle?: never;
};

export type ConditionsListSentenceProps =
  | PreBuiltSentenceProps
  | CustomSentenceProps
  | NoSentenceProps;

export const IntroSentence = ({
  entityNameLabel,
  subjectsLabel,
  explanationStyle = "apply",
  isEmpty,
  hideIntroSentence,
  emptyIntroSentence,
  populatedIntroSentence,
}: ConditionsListSentenceProps & {
  isEmpty: boolean;
}): React.ReactElement | null => {
  if (hideIntroSentence) {
    return null;
  }
  if (isEmpty) {
    return (
      <div className="text-content-primary">
        {emptyIntroSentence ?? (
          <>
            This {entityNameLabel} will{" "}
            {verbForExplanationStyle(explanationStyle)}{" "}
            <span className="font-semibold">all {subjectsLabel}</span>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="text-content-primary">
      {populatedIntroSentence ?? (
        <>
          This {entityNameLabel} will{" "}
          {verbForExplanationStyle(explanationStyle)} {subjectsLabel} where...
        </>
      )}
    </div>
  );
};

const verbForExplanationStyle = (style: ExplanationStyle): string => {
  switch (style) {
    case "available":
      return "be available for";
    case "apply":
      return "apply to";
    default:
      return "";
  }
};
