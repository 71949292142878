import type { SVGProps } from "react";
import * as React from "react";
const SvgBox = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.638 6.169s-.002-.011-.005-.016c-.003-.006-.01-.009-.013-.015a2.737 2.737 0 0 0-.989-.99L11.38 2.683a2.76 2.76 0 0 0-2.76 0L4.37 5.148a2.744 2.744 0 0 0-.989.99c-.004.006-.01.009-.014.015-.003.005-.003.011-.005.016A2.75 2.75 0 0 0 3 7.527v4.946a2.76 2.76 0 0 0 1.37 2.379l4.25 2.465a2.75 2.75 0 0 0 1.366.368c.005 0 .009.003.014.003.005 0 .009-.003.014-.003a2.748 2.748 0 0 0 1.366-.368l4.25-2.465A2.758 2.758 0 0 0 17 12.474V7.527c0-.483-.13-.95-.362-1.358ZM5.123 13.555a1.256 1.256 0 0 1-.623-1.082V7.677l4.75 2.755v5.515l-4.127-2.393v.001Zm9.754 0-4.127 2.394v-5.516l4.75-2.755v4.796c0 .444-.239.858-.623 1.081Z"
    />
  </svg>
);
export default SvgBox;
