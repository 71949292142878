/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentLifecyclesCreateRequestBody,
    IncidentLifecyclesCreateRequestBodyFromJSON,
    IncidentLifecyclesCreateRequestBodyToJSON,
    IncidentLifecyclesCreateResponseBody,
    IncidentLifecyclesCreateResponseBodyFromJSON,
    IncidentLifecyclesCreateResponseBodyToJSON,
    IncidentLifecyclesCreateStatusRequestBody,
    IncidentLifecyclesCreateStatusRequestBodyFromJSON,
    IncidentLifecyclesCreateStatusRequestBodyToJSON,
    IncidentLifecyclesCreateStatusResponseBody,
    IncidentLifecyclesCreateStatusResponseBodyFromJSON,
    IncidentLifecyclesCreateStatusResponseBodyToJSON,
    IncidentLifecyclesListAllStatusesResponseBody,
    IncidentLifecyclesListAllStatusesResponseBodyFromJSON,
    IncidentLifecyclesListAllStatusesResponseBodyToJSON,
    IncidentLifecyclesListResponseBody,
    IncidentLifecyclesListResponseBodyFromJSON,
    IncidentLifecyclesListResponseBodyToJSON,
    IncidentLifecyclesListStatusesForIncidentResponseBody,
    IncidentLifecyclesListStatusesForIncidentResponseBodyFromJSON,
    IncidentLifecyclesListStatusesForIncidentResponseBodyToJSON,
    IncidentLifecyclesListStatusesForIncidentTypeResponseBody,
    IncidentLifecyclesListStatusesForIncidentTypeResponseBodyFromJSON,
    IncidentLifecyclesListStatusesForIncidentTypeResponseBodyToJSON,
    IncidentLifecyclesShowStatusResponseBody,
    IncidentLifecyclesShowStatusResponseBodyFromJSON,
    IncidentLifecyclesShowStatusResponseBodyToJSON,
    IncidentLifecyclesUpdateActiveStatusRanksRequestBody,
    IncidentLifecyclesUpdateActiveStatusRanksRequestBodyFromJSON,
    IncidentLifecyclesUpdateActiveStatusRanksRequestBodyToJSON,
    IncidentLifecyclesUpdateActiveStatusRanksResponseBody,
    IncidentLifecyclesUpdateActiveStatusRanksResponseBodyFromJSON,
    IncidentLifecyclesUpdateActiveStatusRanksResponseBodyToJSON,
    IncidentLifecyclesUpdateClosedStatusRanksRequestBody,
    IncidentLifecyclesUpdateClosedStatusRanksRequestBodyFromJSON,
    IncidentLifecyclesUpdateClosedStatusRanksRequestBodyToJSON,
    IncidentLifecyclesUpdateClosedStatusRanksResponseBody,
    IncidentLifecyclesUpdateClosedStatusRanksResponseBodyFromJSON,
    IncidentLifecyclesUpdateClosedStatusRanksResponseBodyToJSON,
    IncidentLifecyclesUpdateNameRequestBody,
    IncidentLifecyclesUpdateNameRequestBodyFromJSON,
    IncidentLifecyclesUpdateNameRequestBodyToJSON,
    IncidentLifecyclesUpdateNameResponseBody,
    IncidentLifecyclesUpdateNameResponseBodyFromJSON,
    IncidentLifecyclesUpdateNameResponseBodyToJSON,
    IncidentLifecyclesUpdatePostIncidentFlowRequestBody,
    IncidentLifecyclesUpdatePostIncidentFlowRequestBodyFromJSON,
    IncidentLifecyclesUpdatePostIncidentFlowRequestBodyToJSON,
    IncidentLifecyclesUpdatePostIncidentFlowResponseBody,
    IncidentLifecyclesUpdatePostIncidentFlowResponseBodyFromJSON,
    IncidentLifecyclesUpdatePostIncidentFlowResponseBodyToJSON,
    IncidentLifecyclesUpdateStatusRequestBody,
    IncidentLifecyclesUpdateStatusRequestBodyFromJSON,
    IncidentLifecyclesUpdateStatusRequestBodyToJSON,
    IncidentLifecyclesUpdateStatusResponseBody,
    IncidentLifecyclesUpdateStatusResponseBodyFromJSON,
    IncidentLifecyclesUpdateStatusResponseBodyToJSON,
} from '../models';

export interface IncidentLifecyclesCreateRequest {
    createRequestBody: IncidentLifecyclesCreateRequestBody;
}

export interface IncidentLifecyclesCreateStatusRequest {
    createStatusRequestBody: IncidentLifecyclesCreateStatusRequestBody;
}

export interface IncidentLifecyclesDestroyRequest {
    id: string;
}

export interface IncidentLifecyclesDestroyStatusRequest {
    id: string;
}

export interface IncidentLifecyclesListStatusesForIncidentRequest {
    incidentId: string;
}

export interface IncidentLifecyclesListStatusesForIncidentTypeRequest {
    incidentTypeId?: string;
}

export interface IncidentLifecyclesShowStatusRequest {
    id: string;
}

export interface IncidentLifecyclesUpdateActiveStatusRanksRequest {
    id: string;
    updateActiveStatusRanksRequestBody: IncidentLifecyclesUpdateActiveStatusRanksRequestBody;
}

export interface IncidentLifecyclesUpdateClosedStatusRanksRequest {
    updateClosedStatusRanksRequestBody: IncidentLifecyclesUpdateClosedStatusRanksRequestBody;
}

export interface IncidentLifecyclesUpdateNameRequest {
    id: string;
    updateNameRequestBody: IncidentLifecyclesUpdateNameRequestBody;
}

export interface IncidentLifecyclesUpdatePostIncidentFlowRequest {
    id: string;
    updatePostIncidentFlowRequestBody: IncidentLifecyclesUpdatePostIncidentFlowRequestBody;
}

export interface IncidentLifecyclesUpdateStatusRequest {
    id: string;
    updateStatusRequestBody: IncidentLifecyclesUpdateStatusRequestBody;
}

/**
 * 
 */
export class IncidentLifecyclesApi extends runtime.BaseAPI {

    /**
     * Create a new incident status
     * Create IncidentLifecycles
     */
    async incidentLifecyclesCreateRaw(requestParameters: IncidentLifecyclesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentLifecyclesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_lifecycles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLifecyclesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident status
     * Create IncidentLifecycles
     */
    async incidentLifecyclesCreate(requestParameters: IncidentLifecyclesCreateRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesCreateResponseBody> {
        const response = await this.incidentLifecyclesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new incident status
     * CreateStatus IncidentLifecycles
     */
    async incidentLifecyclesCreateStatusRaw(requestParameters: IncidentLifecyclesCreateStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesCreateStatusResponseBody>> {
        if (requestParameters.createStatusRequestBody === null || requestParameters.createStatusRequestBody === undefined) {
            throw new runtime.RequiredError('createStatusRequestBody','Required parameter requestParameters.createStatusRequestBody was null or undefined when calling incidentLifecyclesCreateStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_statuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLifecyclesCreateStatusRequestBodyToJSON(requestParameters.createStatusRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesCreateStatusResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident status
     * CreateStatus IncidentLifecycles
     */
    async incidentLifecyclesCreateStatus(requestParameters: IncidentLifecyclesCreateStatusRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesCreateStatusResponseBody> {
        const response = await this.incidentLifecyclesCreateStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a lifecycle
     * Destroy IncidentLifecycles
     */
    async incidentLifecyclesDestroyRaw(requestParameters: IncidentLifecyclesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLifecyclesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_lifecycles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a lifecycle
     * Destroy IncidentLifecycles
     */
    async incidentLifecyclesDestroy(requestParameters: IncidentLifecyclesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentLifecyclesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an incident status
     * DestroyStatus IncidentLifecycles
     */
    async incidentLifecyclesDestroyStatusRaw(requestParameters: IncidentLifecyclesDestroyStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLifecyclesDestroyStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an incident status
     * DestroyStatus IncidentLifecycles
     */
    async incidentLifecyclesDestroyStatus(requestParameters: IncidentLifecyclesDestroyStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentLifecyclesDestroyStatusRaw(requestParameters, initOverrides);
    }

    /**
     * List incident lifecycles
     * List IncidentLifecycles
     */
    async incidentLifecyclesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_lifecycles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident lifecycles
     * List IncidentLifecycles
     */
    async incidentLifecyclesList(initOverrides?: RequestInit): Promise<IncidentLifecyclesListResponseBody> {
        const response = await this.incidentLifecyclesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incident statuses across all lifecycles
     * ListAllStatuses IncidentLifecycles
     */
    async incidentLifecyclesListAllStatusesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesListAllStatusesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesListAllStatusesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident statuses across all lifecycles
     * ListAllStatuses IncidentLifecycles
     */
    async incidentLifecyclesListAllStatuses(initOverrides?: RequestInit): Promise<IncidentLifecyclesListAllStatusesResponseBody> {
        const response = await this.incidentLifecyclesListAllStatusesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incident statuses available for a specific incident
     * ListStatusesForIncident IncidentLifecycles
     */
    async incidentLifecyclesListStatusesForIncidentRaw(requestParameters: IncidentLifecyclesListStatusesForIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesListStatusesForIncidentResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentLifecyclesListStatusesForIncident.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_statuses/for_incident`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesListStatusesForIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident statuses available for a specific incident
     * ListStatusesForIncident IncidentLifecycles
     */
    async incidentLifecyclesListStatusesForIncident(requestParameters: IncidentLifecyclesListStatusesForIncidentRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesListStatusesForIncidentResponseBody> {
        const response = await this.incidentLifecyclesListStatusesForIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List incident statuses available for an incident type
     * ListStatusesForIncidentType IncidentLifecycles
     */
    async incidentLifecyclesListStatusesForIncidentTypeRaw(requestParameters: IncidentLifecyclesListStatusesForIncidentTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesListStatusesForIncidentTypeResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentTypeId !== undefined) {
            queryParameters['incident_type_id'] = requestParameters.incidentTypeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_statuses/for_incident_type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesListStatusesForIncidentTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident statuses available for an incident type
     * ListStatusesForIncidentType IncidentLifecycles
     */
    async incidentLifecyclesListStatusesForIncidentType(requestParameters: IncidentLifecyclesListStatusesForIncidentTypeRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesListStatusesForIncidentTypeResponseBody> {
        const response = await this.incidentLifecyclesListStatusesForIncidentTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single incident status
     * ShowStatus IncidentLifecycles
     */
    async incidentLifecyclesShowStatusRaw(requestParameters: IncidentLifecyclesShowStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesShowStatusResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLifecyclesShowStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesShowStatusResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single incident status
     * ShowStatus IncidentLifecycles
     */
    async incidentLifecyclesShowStatus(requestParameters: IncidentLifecyclesShowStatusRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesShowStatusResponseBody> {
        const response = await this.incidentLifecyclesShowStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the order of the active statuses within a lifecycle
     * UpdateActiveStatusRanks IncidentLifecycles
     */
    async incidentLifecyclesUpdateActiveStatusRanksRaw(requestParameters: IncidentLifecyclesUpdateActiveStatusRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesUpdateActiveStatusRanksResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLifecyclesUpdateActiveStatusRanks.');
        }

        if (requestParameters.updateActiveStatusRanksRequestBody === null || requestParameters.updateActiveStatusRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateActiveStatusRanksRequestBody','Required parameter requestParameters.updateActiveStatusRanksRequestBody was null or undefined when calling incidentLifecyclesUpdateActiveStatusRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_lifecycles/{id}/actions/update_active_status_ranks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLifecyclesUpdateActiveStatusRanksRequestBodyToJSON(requestParameters.updateActiveStatusRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesUpdateActiveStatusRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the order of the active statuses within a lifecycle
     * UpdateActiveStatusRanks IncidentLifecycles
     */
    async incidentLifecyclesUpdateActiveStatusRanks(requestParameters: IncidentLifecyclesUpdateActiveStatusRanksRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesUpdateActiveStatusRanksResponseBody> {
        const response = await this.incidentLifecyclesUpdateActiveStatusRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of closed statuses across all lifecycles
     * UpdateClosedStatusRanks IncidentLifecycles
     */
    async incidentLifecyclesUpdateClosedStatusRanksRaw(requestParameters: IncidentLifecyclesUpdateClosedStatusRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesUpdateClosedStatusRanksResponseBody>> {
        if (requestParameters.updateClosedStatusRanksRequestBody === null || requestParameters.updateClosedStatusRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateClosedStatusRanksRequestBody','Required parameter requestParameters.updateClosedStatusRanksRequestBody was null or undefined when calling incidentLifecyclesUpdateClosedStatusRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_statuses/actions/update_closed_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLifecyclesUpdateClosedStatusRanksRequestBodyToJSON(requestParameters.updateClosedStatusRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesUpdateClosedStatusRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of closed statuses across all lifecycles
     * UpdateClosedStatusRanks IncidentLifecycles
     */
    async incidentLifecyclesUpdateClosedStatusRanks(requestParameters: IncidentLifecyclesUpdateClosedStatusRanksRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesUpdateClosedStatusRanksResponseBody> {
        const response = await this.incidentLifecyclesUpdateClosedStatusRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the name of the lifecycle
     * UpdateName IncidentLifecycles
     */
    async incidentLifecyclesUpdateNameRaw(requestParameters: IncidentLifecyclesUpdateNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesUpdateNameResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLifecyclesUpdateName.');
        }

        if (requestParameters.updateNameRequestBody === null || requestParameters.updateNameRequestBody === undefined) {
            throw new runtime.RequiredError('updateNameRequestBody','Required parameter requestParameters.updateNameRequestBody was null or undefined when calling incidentLifecyclesUpdateName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_lifecycles/{id}/actions/update_name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLifecyclesUpdateNameRequestBodyToJSON(requestParameters.updateNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesUpdateNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the name of the lifecycle
     * UpdateName IncidentLifecycles
     */
    async incidentLifecyclesUpdateName(requestParameters: IncidentLifecyclesUpdateNameRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesUpdateNameResponseBody> {
        const response = await this.incidentLifecyclesUpdateNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update how incidents leave this lifecycle into a post-incident flow (or not)
     * UpdatePostIncidentFlow IncidentLifecycles
     */
    async incidentLifecyclesUpdatePostIncidentFlowRaw(requestParameters: IncidentLifecyclesUpdatePostIncidentFlowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesUpdatePostIncidentFlowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLifecyclesUpdatePostIncidentFlow.');
        }

        if (requestParameters.updatePostIncidentFlowRequestBody === null || requestParameters.updatePostIncidentFlowRequestBody === undefined) {
            throw new runtime.RequiredError('updatePostIncidentFlowRequestBody','Required parameter requestParameters.updatePostIncidentFlowRequestBody was null or undefined when calling incidentLifecyclesUpdatePostIncidentFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_lifecycles/{id}/actions/update_post_incident_flow`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLifecyclesUpdatePostIncidentFlowRequestBodyToJSON(requestParameters.updatePostIncidentFlowRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesUpdatePostIncidentFlowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update how incidents leave this lifecycle into a post-incident flow (or not)
     * UpdatePostIncidentFlow IncidentLifecycles
     */
    async incidentLifecyclesUpdatePostIncidentFlow(requestParameters: IncidentLifecyclesUpdatePostIncidentFlowRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesUpdatePostIncidentFlowResponseBody> {
        const response = await this.incidentLifecyclesUpdatePostIncidentFlowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident status
     * UpdateStatus IncidentLifecycles
     */
    async incidentLifecyclesUpdateStatusRaw(requestParameters: IncidentLifecyclesUpdateStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentLifecyclesUpdateStatusResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentLifecyclesUpdateStatus.');
        }

        if (requestParameters.updateStatusRequestBody === null || requestParameters.updateStatusRequestBody === undefined) {
            throw new runtime.RequiredError('updateStatusRequestBody','Required parameter requestParameters.updateStatusRequestBody was null or undefined when calling incidentLifecyclesUpdateStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentLifecyclesUpdateStatusRequestBodyToJSON(requestParameters.updateStatusRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentLifecyclesUpdateStatusResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident status
     * UpdateStatus IncidentLifecycles
     */
    async incidentLifecyclesUpdateStatus(requestParameters: IncidentLifecyclesUpdateStatusRequest, initOverrides?: RequestInit): Promise<IncidentLifecyclesUpdateStatusResponseBody> {
        const response = await this.incidentLifecyclesUpdateStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
