import {
  UserOption,
  UserOptionStateEnum as SeatTypeEnum,
} from "@incident-io/api";
import { Button, ButtonTheme, IconEnum, LoadingBar } from "@incident-ui";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { OverrideFormData } from "src/components/legacy/on-call/schedules/overrides/OverrideCreateEditDrawer";
import {
  ScheduleCreateEditFormUserSelector,
  ScheduleCreateEditFormUsersList,
} from "src/components/legacy/on-call/schedules/ScheduleCreateFormUsersList";
import { useHydratedUserCache } from "src/components/legacy/on-call/schedules/useHydratedUserCache";
import { useIdentity } from "src/contexts/IdentityContext";

import { isOnCallUser } from "../../../../settings/users/users/utils";

export const OverrideUserInput = ({
  formMethods,
}: {
  formMethods: UseFormReturn<OverrideFormData>;
}) => {
  const { identity } = useIdentity();
  const overrideAssignee = formMethods.watch("user_id");
  const userIds = overrideAssignee ? [overrideAssignee] : [];

  const userCache = useHydratedUserCache(userIds);
  userCache.cacheHydratedUsers([
    {
      label: "No one",
      value: "NOBODY",
      sort_key: "",
      state: SeatTypeEnum.OnCallResponder,
    },
  ]);

  const canAssignSelf = isOnCallUser(identity.user_state);

  const onSelect = (user: UserOption) => {
    formMethods.setValue<"user_id">("user_id", user.value);
    formMethods.clearErrors("user_id");
  };

  return (
    <div className="gap-8 w-full">
      <Form.Label htmlFor={"search"} className="block w-fit mb-2">
        Who is providing cover?
      </Form.Label>
      <div>
        {userCache.hydrating ? (
          <LoadingBar />
        ) : (
          <ScheduleCreateEditFormUserSelector
            selectedUsers={
              userIds.map((u) => userCache.getHydratedUser(u)) as UserOption[]
            }
            cacheHydratedUsers={userCache.cacheHydratedUsers}
            onSelect={onSelect}
            singleSelect
            allowNobody
            removeNotOnCallResponders
          />
        )}
        <div className="pt-2">
          <div>
            <ScheduleCreateEditFormUsersList
              users={
                userIds
                  .map((u) => userCache.getHydratedUser(u))
                  .filter(Boolean) as UserOption[]
              }
              onRemoveUser={() => formMethods.setValue("user_id", "NOBODY")}
            />
          </div>
          {identity?.user_id && !overrideAssignee && canAssignSelf && (
            <Button
              analyticsTrackingId={"override-assign-myself"}
              theme={ButtonTheme.Secondary}
              icon={IconEnum.Add}
              // We're hardcoding the mb of the button to be 12px because the user pill is 42px high, and the button is 32px high.
              // That way when you remove the user pill / click the button there's no shift of margin.
              className="!text-content-tertiary !no-underline !px-3 !py-1 !mb-[2px] group-hover:text-content-primary"
              onClick={() => {
                formMethods.setValue<"user_id">("user_id", identity.user_id);
                formMethods.clearErrors("user_id");
              }}
            >
              Assign myself
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
