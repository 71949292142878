import type { SVGProps } from "react";
import * as React from "react";
const SvgHeart = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.914 3A4.623 4.623 0 0 0 9.75 4.306 4.641 4.641 0 0 0 6.572 3C4.042 3.015 1.99 5.084 2 7.609c0 5.253 5.306 8.429 6.932 9.278a1.774 1.774 0 0 0 1.635 0c1.626-.848 6.933-4.024 6.933-9.275.009-2.528-2.042-4.597-4.586-4.612Z"
    />
  </svg>
);
export default SvgHeart;
