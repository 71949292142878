/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentMembershipsCreateRequestBody,
    IncidentMembershipsCreateRequestBodyFromJSON,
    IncidentMembershipsCreateRequestBodyToJSON,
    IncidentMembershipsCreateResponseBody,
    IncidentMembershipsCreateResponseBodyFromJSON,
    IncidentMembershipsCreateResponseBodyToJSON,
    IncidentMembershipsListResponseBody,
    IncidentMembershipsListResponseBodyFromJSON,
    IncidentMembershipsListResponseBodyToJSON,
    IncidentMembershipsRevokeRequestBody,
    IncidentMembershipsRevokeRequestBodyFromJSON,
    IncidentMembershipsRevokeRequestBodyToJSON,
} from '../models';

export interface IncidentMembershipsCreateRequest {
    createRequestBody: IncidentMembershipsCreateRequestBody;
}

export interface IncidentMembershipsListRequest {
    incidentId: string;
}

export interface IncidentMembershipsRevokeRequest {
    revokeRequestBody: IncidentMembershipsRevokeRequestBody;
}

/**
 * 
 */
export class IncidentMembershipsApi extends runtime.BaseAPI {

    /**
     * Makes a user a member of an incident
     * Create IncidentMemberships
     */
    async incidentMembershipsCreateRaw(requestParameters: IncidentMembershipsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentMembershipsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentMembershipsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_memberships`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentMembershipsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentMembershipsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Makes a user a member of an incident
     * Create IncidentMemberships
     */
    async incidentMembershipsCreate(requestParameters: IncidentMembershipsCreateRequest, initOverrides?: RequestInit): Promise<IncidentMembershipsCreateResponseBody> {
        const response = await this.incidentMembershipsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List incident memberships
     * List IncidentMemberships
     */
    async incidentMembershipsListRaw(requestParameters: IncidentMembershipsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentMembershipsListResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentMembershipsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_memberships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentMembershipsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident memberships
     * List IncidentMemberships
     */
    async incidentMembershipsList(requestParameters: IncidentMembershipsListRequest, initOverrides?: RequestInit): Promise<IncidentMembershipsListResponseBody> {
        const response = await this.incidentMembershipsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke one (or more) user\'s membership of an incident
     * Revoke IncidentMemberships
     */
    async incidentMembershipsRevokeRaw(requestParameters: IncidentMembershipsRevokeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.revokeRequestBody === null || requestParameters.revokeRequestBody === undefined) {
            throw new runtime.RequiredError('revokeRequestBody','Required parameter requestParameters.revokeRequestBody was null or undefined when calling incidentMembershipsRevoke.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_memberships/actions/revoke`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentMembershipsRevokeRequestBodyToJSON(requestParameters.revokeRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke one (or more) user\'s membership of an incident
     * Revoke IncidentMemberships
     */
    async incidentMembershipsRevoke(requestParameters: IncidentMembershipsRevokeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentMembershipsRevokeRaw(requestParameters, initOverrides);
    }

}
