/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagedResource
 */
export interface ManagedResource {
    /**
     * Annotations that track metadata about this resource
     * @type {{ [key: string]: string; }}
     * @memberof ManagedResource
     */
    annotations: { [key: string]: string; };
    /**
     * How is this resource managed
     * @type {string}
     * @memberof ManagedResource
     */
    managed_by: ManagedResourceManagedByEnum;
    /**
     * The ID of the resource that this relates to
     * @type {string}
     * @memberof ManagedResource
     */
    resource_id: string;
    /**
     * The type of the related resource
     * @type {string}
     * @memberof ManagedResource
     */
    resource_type: ManagedResourceResourceTypeEnum;
    /**
     * The url of the external repository where this resource is managed (if there is one)
     * @type {string}
     * @memberof ManagedResource
     */
    source_url?: string;
}

/**
* @export
* @enum {string}
*/
export enum ManagedResourceManagedByEnum {
    Dashboard = 'dashboard',
    Terraform = 'terraform',
    External = 'external'
}/**
* @export
* @enum {string}
*/
export enum ManagedResourceResourceTypeEnum {
    Workflow = 'workflow',
    Schedule = 'schedule'
}

export function ManagedResourceFromJSON(json: any): ManagedResource {
    return ManagedResourceFromJSONTyped(json, false);
}

export function ManagedResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagedResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': json['annotations'],
        'managed_by': json['managed_by'],
        'resource_id': json['resource_id'],
        'resource_type': json['resource_type'],
        'source_url': !exists(json, 'source_url') ? undefined : json['source_url'],
    };
}

export function ManagedResourceToJSON(value?: ManagedResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'managed_by': value.managed_by,
        'resource_id': value.resource_id,
        'resource_type': value.resource_type,
        'source_url': value.source_url,
    };
}

