/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentCall,
    IncidentCallFromJSON,
    IncidentCallFromJSONTyped,
    IncidentCallToJSON,
} from './IncidentCall';

/**
 * 
 * @export
 * @interface IncidentCallsCreateResponseBody
 */
export interface IncidentCallsCreateResponseBody {
    /**
     * 
     * @type {IncidentCall}
     * @memberof IncidentCallsCreateResponseBody
     */
    incident_call: IncidentCall;
}

export function IncidentCallsCreateResponseBodyFromJSON(json: any): IncidentCallsCreateResponseBody {
    return IncidentCallsCreateResponseBodyFromJSONTyped(json, false);
}

export function IncidentCallsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_call': IncidentCallFromJSON(json['incident_call']),
    };
}

export function IncidentCallsCreateResponseBodyToJSON(value?: IncidentCallsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_call': IncidentCallToJSON(value.incident_call),
    };
}

