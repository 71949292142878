import type { SVGProps } from "react";
import * as React from "react";
const SvgCog = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.713 8.67-1.937-.276a5.983 5.983 0 0 0-.554-1.343l1.173-1.565a.334.334 0 0 0-.031-.435L14.95 3.637a.335.335 0 0 0-.435-.031L12.95 4.779a5.927 5.927 0 0 0-1.343-.554l-.276-1.938a.333.333 0 0 0-.33-.286H9a.333.333 0 0 0-.33.286l-.277 1.938a5.982 5.982 0 0 0-1.342.554L5.487 3.606a.335.335 0 0 0-.435.031L3.638 5.051a.334.334 0 0 0-.031.435L4.78 7.051a5.926 5.926 0 0 0-.554 1.343l-1.938.276a.333.333 0 0 0-.286.33v2c0 .166.121.306.286.33l1.938.276c.131.474.317.924.554 1.343l-1.173 1.565a.334.334 0 0 0 .03.435l1.415 1.414a.332.332 0 0 0 .436.03l1.565-1.173c.418.238.868.423 1.342.554l.277 1.938a.333.333 0 0 0 .33.287h2a.333.333 0 0 0 .33-.287l.276-1.938a5.982 5.982 0 0 0 1.342-.553l1.565 1.173a.335.335 0 0 0 .436-.031l1.414-1.414a.334.334 0 0 0 .031-.435l-1.173-1.565c.238-.419.423-.869.554-1.343l1.938-.276a.333.333 0 0 0 .287-.33V9a.333.333 0 0 0-.287-.33h-.002Zm-7.712 3.996a2.666 2.666 0 1 1 0-5.332 2.666 2.666 0 0 1 0 5.332Z"
    />
  </svg>
);
export default SvgCog;
