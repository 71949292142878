/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackCanvasPreviewTemplate,
    SlackCanvasPreviewTemplateFromJSON,
    SlackCanvasPreviewTemplateFromJSONTyped,
    SlackCanvasPreviewTemplateToJSON,
} from './SlackCanvasPreviewTemplate';

/**
 * 
 * @export
 * @interface SlackCanvasPreviewsListResponseBody
 */
export interface SlackCanvasPreviewsListResponseBody {
    /**
     * 
     * @type {Array<SlackCanvasPreviewTemplate>}
     * @memberof SlackCanvasPreviewsListResponseBody
     */
    templates: Array<SlackCanvasPreviewTemplate>;
}

export function SlackCanvasPreviewsListResponseBodyFromJSON(json: any): SlackCanvasPreviewsListResponseBody {
    return SlackCanvasPreviewsListResponseBodyFromJSONTyped(json, false);
}

export function SlackCanvasPreviewsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackCanvasPreviewsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templates': ((json['templates'] as Array<any>).map(SlackCanvasPreviewTemplateFromJSON)),
    };
}

export function SlackCanvasPreviewsListResponseBodyToJSON(value?: SlackCanvasPreviewsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templates': ((value.templates as Array<any>).map(SlackCanvasPreviewTemplateToJSON)),
    };
}

