import { IncidentStatusBadge, OrgAwareLink } from "@incident-ui";
import React from "react";
import { IncidentStatus } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { useLifecycleOverview } from "./LifecycleOverviewContext";
import { TimestampWithRule } from "./prepareLifecycle";
import { SetByRulePrefill } from "./TimestampSetByRuleAddPopover";
import { TimestampsOnStatusChange } from "./TimestampsOnStatusChange";

export const PillAndTimestamps = ({
  status,
  timestamps,
  pillClassName,
  hideTimestamp,
  setByRulePrefill,
  betweenStatuses,
}: {
  status: IncidentStatus;
  timestamps: TimestampWithRule[];
  pillClassName?: string;
  hideTimestamp?: boolean;
  setByRulePrefill?: SetByRulePrefill;
  betweenStatuses?: [IncidentStatus | undefined, IncidentStatus | undefined];
}): React.ReactElement => {
  const { getConfigurationState } = useLifecycleOverview();
  const state = getConfigurationState(status.category);
  const isBackground = state === "background";

  return (
    <>
      <Pill disabled={isBackground} className={pillClassName}>
        <IncidentStatusBadge naked status={status} iconOnly />
        <span className="truncate">{status.name}</span>
      </Pill>
      {!hideTimestamp && (
        <TimestampsOnStatusChange
          disabled={isBackground}
          timestamps={timestamps}
          setByRulePrefill={setByRulePrefill}
          betweenStatuses={betweenStatuses}
        />
      )}
    </>
  );
};

export const Pill = ({
  children,
  className,
  disabled = false,
  onClickLink,
}: {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClickLink?: string;
}): React.ReactElement => {
  const classes = tcx(
    "flex rounded-[6px] items-center justify-start p-3 text-sm font-semibold gap-2 border border-1 border-slate-100 !shadow w-[250px]",
    disabled ? "bg-slate-50 text-content-tertiary" : "bg-white",
    onClickLink ? "hover:bg-surface-secondary cursor-pointer" : "",
    className,
  );

  if (onClickLink) {
    return (
      <OrgAwareLink className={classes} to={onClickLink}>
        {children}
      </OrgAwareLink>
    );
  }

  return <div className={classes}>{children}</div>;
};

export const HorizontalLine = ({
  className,
}: {
  className?: string;
}): React.ReactElement => {
  return <div className={tcx("border-t border-stroke", className)} />;
};
