/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DebriefsUpdateDebriefNameRequestBody
 */
export interface DebriefsUpdateDebriefNameRequestBody {
    /**
     * The org's own name for a debrief
     * @type {string}
     * @memberof DebriefsUpdateDebriefNameRequestBody
     */
    debrief_rename?: string;
}

export function DebriefsUpdateDebriefNameRequestBodyFromJSON(json: any): DebriefsUpdateDebriefNameRequestBody {
    return DebriefsUpdateDebriefNameRequestBodyFromJSONTyped(json, false);
}

export function DebriefsUpdateDebriefNameRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsUpdateDebriefNameRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'debrief_rename': !exists(json, 'debrief_rename') ? undefined : json['debrief_rename'],
    };
}

export function DebriefsUpdateDebriefNameRequestBodyToJSON(value?: DebriefsUpdateDebriefNameRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'debrief_rename': value.debrief_rename,
    };
}

