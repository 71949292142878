import type { SVGProps } from "react";
import * as React from "react";
const SvgLinkBreak = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 8.327a3.501 3.501 0 0 0-.973.69l-.01.01a3.535 3.535 0 0 0 0 5l2.175 2.175a3.535 3.535 0 0 0 5 0l.01-.01a3.535 3.535 0 0 0 0-5l-.931-.93M11 12.672c.352-.168.682-.398.973-.69l.01-.01a3.535 3.535 0 0 0 0-5L9.808 4.798a3.535 3.535 0 0 0-5 0l-.01.01a3.535 3.535 0 0 0 0 5l.931.931M13.75 5.25 14.5 3m1.25 4.25L18 6.5M5.25 13.75 3 14.5m4.25 1.25L6.5 18"
    />
  </svg>
);
export default SvgLinkBreak;
