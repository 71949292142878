import {
  IncidentStatus,
  PostIncidentFlow,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum, Loader, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { SettingsSortableList } from "../SettingsSortableList";
import { PostIncidentStatusRow } from "./PostIncidentStatusRow";

export const PostIncidentStatusList = ({
  className,
  flow,
  isOnlyFlow,
  onCreate,
  onEdit,
}: {
  flow: PostIncidentFlow;
  isOnlyFlow: boolean;
  className?: string;
  onCreate: () => void;
  onEdit: (s: IncidentStatus) => void;
}) => {
  const { hasScope, isImpersonating } = useIdentity();
  const canEditSettings =
    hasScope(ScopeNameEnum.OrganisationSettingsUpdate) && !isImpersonating;

  const {
    data: { task_options: taskOptions },
    isLoading: taskOptionsLoading,
  } = useAPI("postIncidentFlowListTaskOptions", undefined, {
    fallbackData: { task_options: [] },
  });

  const showToast = useToast();
  const refetchFlows = useAPIRefetch("postIncidentFlowList", undefined);
  const { trigger: updateStatusRanks, isMutating: saving } = useAPIMutation(
    "incidentLifecyclesListAllStatuses",
    undefined,
    async (apiClient, data: IncidentStatus[]) => {
      await apiClient.postIncidentFlowUpdateStatusTaskConfigRanks({
        id: flow.id,
        updateStatusTaskConfigRanksRequestBody: {
          status_ranks: data.map((status, idx) => ({
            incident_status_id: status.id,
            rank: idx,
            task_ranks: flow.task_configs
              .filter((task) => task.incident_status_id === status.id)
              .map((task) => ({ resource_id: task.id, rank: task.rank })),
          })),
        },
      });
      await refetchFlows();
    },
    {
      onError: (_, fieldError) => {
        const errorMessage = (fieldError as { message?: string })?.message;
        showToast({
          title: "Invalid order",
          description:
            errorMessage ?? "You cannot place statuses in that order",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  if (taskOptionsLoading) {
    return <Loader />;
  }

  return (
    <div className="space-y-4 !mt-0">
      <SettingsSortableList
        className={className}
        items={flow.incident_statuses}
        updateItemRanks={updateStatusRanks}
        canEdit={canEditSettings}
        dragHandleAtTop={true}
        renderItem={(item) => (
          <PostIncidentStatusRow
            flow={flow}
            isOnlyFlow={isOnlyFlow}
            status={item}
            onEdit={() => onEdit(item)}
            taskOptions={taskOptions}
          />
        )}
        saving={saving}
      />
      <div className="flex gap-2">
        <GatedButton
          onClick={onCreate}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          analyticsTrackingId="add-incident-status"
          icon={IconEnum.Add}
          theme={ButtonTheme.Secondary}
        >
          Add status
        </GatedButton>
      </div>
    </div>
  );
};
