/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SAMLCreateResponseBody,
    SAMLCreateResponseBodyFromJSON,
    SAMLCreateResponseBodyToJSON,
    SAMLSetDomainsRequestBody,
    SAMLSetDomainsRequestBodyFromJSON,
    SAMLSetDomainsRequestBodyToJSON,
    SAMLSetDomainsResponseBody,
    SAMLSetDomainsResponseBodyFromJSON,
    SAMLSetDomainsResponseBodyToJSON,
    SAMLSetIsOptionalRequestBody,
    SAMLSetIsOptionalRequestBodyFromJSON,
    SAMLSetIsOptionalRequestBodyToJSON,
    SAMLSetIsOptionalResponseBody,
    SAMLSetIsOptionalResponseBodyFromJSON,
    SAMLSetIsOptionalResponseBodyToJSON,
    SAMLShowResponseBody,
    SAMLShowResponseBodyFromJSON,
    SAMLShowResponseBodyToJSON,
} from '../models';

export interface SAMLSetDomainsRequest {
    setDomainsRequestBody: SAMLSetDomainsRequestBody;
}

export interface SAMLSetIsOptionalRequest {
    setIsOptionalRequestBody: SAMLSetIsOptionalRequestBody;
}

/**
 * 
 */
export class SAMLApi extends runtime.BaseAPI {

    /**
     * Create SAML settings
     * Create SAML
     */
    async sAMLCreateRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SAMLCreateResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/saml/self`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SAMLCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create SAML settings
     * Create SAML
     */
    async sAMLCreate(initOverrides?: RequestInit): Promise<SAMLCreateResponseBody> {
        const response = await this.sAMLCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Uninstall SAML settings
     * Destroy SAML
     */
    async sAMLDestroyRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/saml/self`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uninstall SAML settings
     * Destroy SAML
     */
    async sAMLDestroy(initOverrides?: RequestInit): Promise<void> {
        await this.sAMLDestroyRaw(initOverrides);
    }

    /**
     * Sets the valid domains for this organisation\'s SAML config
     * SetDomains SAML
     */
    async sAMLSetDomainsRaw(requestParameters: SAMLSetDomainsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SAMLSetDomainsResponseBody>> {
        if (requestParameters.setDomainsRequestBody === null || requestParameters.setDomainsRequestBody === undefined) {
            throw new runtime.RequiredError('setDomainsRequestBody','Required parameter requestParameters.setDomainsRequestBody was null or undefined when calling sAMLSetDomains.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/saml/self/domains`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SAMLSetDomainsRequestBodyToJSON(requestParameters.setDomainsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SAMLSetDomainsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Sets the valid domains for this organisation\'s SAML config
     * SetDomains SAML
     */
    async sAMLSetDomains(requestParameters: SAMLSetDomainsRequest, initOverrides?: RequestInit): Promise<SAMLSetDomainsResponseBody> {
        const response = await this.sAMLSetDomainsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the valid domains for this organisation\'s SAML config
     * SetIsOptional SAML
     */
    async sAMLSetIsOptionalRaw(requestParameters: SAMLSetIsOptionalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SAMLSetIsOptionalResponseBody>> {
        if (requestParameters.setIsOptionalRequestBody === null || requestParameters.setIsOptionalRequestBody === undefined) {
            throw new runtime.RequiredError('setIsOptionalRequestBody','Required parameter requestParameters.setIsOptionalRequestBody was null or undefined when calling sAMLSetIsOptional.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/saml/self/is_optional`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SAMLSetIsOptionalRequestBodyToJSON(requestParameters.setIsOptionalRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SAMLSetIsOptionalResponseBodyFromJSON(jsonValue));
    }

    /**
     * Sets the valid domains for this organisation\'s SAML config
     * SetIsOptional SAML
     */
    async sAMLSetIsOptional(requestParameters: SAMLSetIsOptionalRequest, initOverrides?: RequestInit): Promise<SAMLSetIsOptionalResponseBody> {
        const response = await this.sAMLSetIsOptionalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show SAML settings
     * Show SAML
     */
    async sAMLShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SAMLShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/saml/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SAMLShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show SAML settings
     * Show SAML
     */
    async sAMLShow(initOverrides?: RequestInit): Promise<SAMLShowResponseBody> {
        const response = await this.sAMLShowRaw(initOverrides);
        return await response.value();
    }

}
