import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { OrgAwareNavigate, OrgAwareNavLink } from "@incident-shared/org-aware";
import { ButtonTheme, ContentBox, IconEnum, Loader } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useProductAccess } from "src/hooks/useProductAccess";
import { tcx } from "src/utils/tailwind-classes";

import { IntegrationSettingsProviderEnum } from "../../../contexts/ClientContext";
import { useIdentity } from "../../../contexts/IdentityContext";
import { useAPI } from "../../../utils/swr";
import { CreateFirstOnCallPayReportPrompt } from "./CreateFirstOnCallPayReportPrompt";
import ExampleReport from "./images/example-report.png";
import { OnCallPayConfigsCreateRoute } from "./OnCallPayConfigsCreateRoute";
import { OnCallPayConfigsEditRoute } from "./OnCallPayConfigsEditRoute";
import { OnCallPayConfigsListRoute } from "./OnCallPayConfigsListRoute";
import {
  getDraftReports,
  OnCallReportsListRoute,
} from "./OnCallReportsListRoute";

const PAGES = [
  {
    label: "Reports",
    slug: "reports",
    Component: OnCallReportsListRoute,
    icon: IconEnum.Doc,
  },
  {
    label: "Pay configurations",
    slug: "pay-configurations",
    Component: OnCallPayConfigsListRoute,
    icon: IconEnum.Billing,
  },
];

const ON_CALL_PAY_TABS = [
  {
    label: "Reports",
    slug: "reports",
    Component: OnCallReportsListRoute,
  },
  {
    label: "Configurations",
    slug: "pay-configurations",
  },
];

export const OnCallRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="*" element={<OnCallPage />} />
    </Routes>
  );
};

const OnCallPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const { integrations } = useIntegrations();

  const { data: reportsData, error: reportsError } = useAPI(
    "schedulesListReport",
    undefined,
  );
  const reports = reportsData?.schedule_reports;
  if (reportsError) {
    throw reportsError;
  }

  const { data: payConfigData, error } = useAPI(
    "schedulesListPayConfig",
    undefined,
  );

  const { hasOnCall } = useProductAccess();
  const hasOnCallPayCalculator = identity?.feature_gates.on_call_calculator;

  if (!hasOnCall && !hasOnCallPayCalculator) {
    return <OrgAwareNavigate to="/on-call" replace={true} />;
  }

  const payConfigs = payConfigData?.schedule_pay_configs;
  if (error) {
    throw error;
  }

  if (!identity || !integrations || !reports || !payConfigs) {
    return <Loader />;
  }

  // If you've got no PagerDuty installed, we render a CTA to help you configure PagerDuty.
  // TODO: we probably don't want to do this for someone who's installed PagerDuty, created
  // reports, and then disconnected. But that feels like a massive edge case that we can
  // avoid dealing with for a long time.
  const pagerDutyIntegration = integrations.find(
    (x) => x.provider === IntegrationSettingsProviderEnum.Pagerduty,
  );
  const opsgenieIntegration = integrations.find(
    (x) => x.provider === IntegrationSettingsProviderEnum.Opsgenie,
  );

  const publishedReports = reports.filter((r) => !!r.published_at);
  const draftReports = getDraftReports(reports, identity);
  const isFirstInteraction =
    publishedReports.length === 0 &&
    draftReports.length === 0 &&
    payConfigs.length === 0;

  if (
    isFirstInteraction ||
    (pagerDutyIntegration &&
      !pagerDutyIntegration?.installed &&
      opsgenieIntegration &&
      !opsgenieIntegration?.installed &&
      isFirstInteraction)
  ) {
    return <CreateFirstOnCallPayReportPrompt />;
  }

  return <OnCallPagerInner isFirstInteraction={isFirstInteraction} />;
};

const OnCallPagerInner = ({
  isFirstInteraction,
}: {
  isFirstInteraction: boolean;
}) => {
  return (
    <>
      {isFirstInteraction ? (
        <FirstInteractionHelper />
      ) : (
        <div>
          <div className={"flex flex-row items-center gap-4"}>
            {ON_CALL_PAY_TABS.map((page) => (
              <OrgAwareNavLink
                key={page.slug}
                to={page.slug}
                className={({ isActive }) =>
                  tcx("py-1 px-3 rounded-xl", {
                    "bg-surface-tertiary": isActive,
                  })
                }
              >
                <div className="font-medium text-content-primary">
                  {page.label}
                </div>
              </OrgAwareNavLink>
            ))}
          </div>
          <div className="pb-4 pt-6 bg-white mt-2">
            <Routes>
              <Route
                path="pay-configurations/create"
                element={<OnCallPayConfigsCreateRoute />}
              />
              <Route
                path="pay-configurations/:id/edit"
                element={<OnCallPayConfigsEditRoute />}
              />
              {/* Note: this needs to be at the end so the view/edit screens take
  precedence, and we fallback to the list routes */}
              {PAGES.map((page) => (
                <Route
                  path={`${page.slug}`}
                  element={<page.Component />}
                  key={page.slug}
                />
              ))}
              {/* Fallback so we default to the reports tab */}
              <Route
                path="*"
                element={<OrgAwareNavigate to={"reports"} replace />}
              />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
};

// FirstInteractionHelper should only be reached by people who currently have PD or Opsgenie installed, have made no
// reports before, but don't have the on-call feature flag.
// Eventually we'll update this so that on On-call GA, we just remove this entirely and gate the button on
// `CreateFirstOnCallPayReportPrompt` if they do not pay for on-call and need connecting to PD/OG.
const FirstInteractionHelper = (): React.ReactElement => {
  return (
    <ContentBox className="p-8  pb-0 md:flex flex-row justify-center items-center shadow-none mx-auto max-w-6xl my-8">
      <div className="mb-6 pr-8 pl-4 space-y-4">
        <p>
          Our On-call pay calculator will provide you with a simple report of
          how much your responders are owed, depending on the time spent on
          call.
        </p>
        <p>
          Let us know how much you pay for on-call, and we&apos;ll do the rest.{" "}
        </p>
        <div>
          <GatedButton
            analyticsTrackingId={"schedules-create-first-report"}
            href={"/on-call/pay-calculator/reports/create"}
            theme={ButtonTheme.Primary}
          >
            Create your first report
          </GatedButton>
        </div>
      </div>
      <div>
        <img src={ExampleReport} />
      </div>
    </ContentBox>
  );
};
