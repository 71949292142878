/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertsSuggestAttributesRequestBody
 */
export interface AlertsSuggestAttributesRequestBody {
    /**
     * Force fresh suggestions, ignoring any existing ones
     * @type {boolean}
     * @memberof AlertsSuggestAttributesRequestBody
     */
    reset: boolean;
}

export function AlertsSuggestAttributesRequestBodyFromJSON(json: any): AlertsSuggestAttributesRequestBody {
    return AlertsSuggestAttributesRequestBodyFromJSONTyped(json, false);
}

export function AlertsSuggestAttributesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsSuggestAttributesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reset': json['reset'],
    };
}

export function AlertsSuggestAttributesRequestBodyToJSON(value?: AlertsSuggestAttributesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reset': value.reset,
    };
}

