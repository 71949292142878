import type { SVGProps } from "react";
import * as React from "react";
const SvgComponents = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.75 7 12 4.75 14.25 7 12 9.25 9.75 7ZM14.75 12 17 9.75 19.25 12 17 14.25 14.75 12ZM9.75 17 12 14.75 14.25 17 12 19.25 9.75 17ZM4.75 12 7 9.75 9.25 12 7 14.25 4.75 12Z"
    />
  </svg>
);
export default SvgComponents;
