/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface AlertRouteAlertSource
 */
export interface AlertRouteAlertSource {
    /**
     * The alert source ID that will match for the route
     * @type {string}
     * @memberof AlertRouteAlertSource
     */
    alert_source_id: string;
    /**
     * What conditions should alerts from this source meet to be included in this alert route?
     * @type {Array<ConditionGroup>}
     * @memberof AlertRouteAlertSource
     */
    condition_groups: Array<ConditionGroup>;
}

export function AlertRouteAlertSourceFromJSON(json: any): AlertRouteAlertSource {
    return AlertRouteAlertSourceFromJSONTyped(json, false);
}

export function AlertRouteAlertSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteAlertSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_id': json['alert_source_id'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
    };
}

export function AlertRouteAlertSourceToJSON(value?: AlertRouteAlertSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_id': value.alert_source_id,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
    };
}

