/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathNode,
    EscalationPathNodeFromJSON,
    EscalationPathNodeFromJSONTyped,
    EscalationPathNodeToJSON,
} from './EscalationPathNode';
import {
    InboundCall,
    InboundCallFromJSON,
    InboundCallFromJSONTyped,
    InboundCallToJSON,
} from './InboundCall';

/**
 * 
 * @export
 * @interface CallRoute
 */
export interface CallRoute {
    /**
     * The current state of the call routing configuration
     * @type {string}
     * @memberof CallRoute
     */
    current_state: CallRouteCurrentStateEnum;
    /**
     * Unique ID for this phone number and attached config
     * @type {string}
     * @memberof CallRoute
     */
    id: string;
    /**
     * 
     * @type {InboundCall}
     * @memberof CallRoute
     */
    most_recent_call?: InboundCall;
    /**
     * The name of the call routing configuration
     * @type {string}
     * @memberof CallRoute
     */
    name: string;
    /**
     * The escalation path to follow when a call is received
     * @type {Array<EscalationPathNode>}
     * @memberof CallRoute
     */
    path: Array<EscalationPathNode>;
    /**
     * The phone number to route calls to
     * @type {string}
     * @memberof CallRoute
     */
    phone_number?: string;
}

/**
* @export
* @enum {string}
*/
export enum CallRouteCurrentStateEnum {
    Pending = 'pending',
    Active = 'active'
}

export function CallRouteFromJSON(json: any): CallRoute {
    return CallRouteFromJSONTyped(json, false);
}

export function CallRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallRoute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_state': json['current_state'],
        'id': json['id'],
        'most_recent_call': !exists(json, 'most_recent_call') ? undefined : InboundCallFromJSON(json['most_recent_call']),
        'name': json['name'],
        'path': ((json['path'] as Array<any>).map(EscalationPathNodeFromJSON)),
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
    };
}

export function CallRouteToJSON(value?: CallRoute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_state': value.current_state,
        'id': value.id,
        'most_recent_call': InboundCallToJSON(value.most_recent_call),
        'name': value.name,
        'path': ((value.path as Array<any>).map(EscalationPathNodeToJSON)),
        'phone_number': value.phone_number,
    };
}

