import { Product } from "@incident-shared/billing";
import { ProductGateTooltip } from "@incident-shared/settings/UpsellBadge/ProductGateTooltip";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { ContentBox } from "@incident-ui/ContentBox/ContentBox";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { IconBadge } from "@incident-ui/IconBadge/IconBadge";
import React from "react";
import { useProductAccess } from "src/hooks/useProductAccess";
import { tcx } from "src/utils/tailwind-classes";

export type CardProps = {
  icon?: IconEnum;
  iconSize?: IconSize;
  iconOverride?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
  descriptionClassName?: string;
  color?: ColorPaletteEnum;
  hexColor?: string;
  clickable?: boolean;
  compact?: boolean;
  error?: boolean;
  className?: string;
  requiredProduct?: Product;
  children?: React.ReactNode;
  headerAccessory?: React.ReactNode;
  footerNode?: React.ReactNode;
};

export const Card = ({
  color = ColorPaletteEnum.Blue,
  hexColor,
  icon,
  iconSize = IconSize.Small,
  iconOverride,
  title,
  clickable = true,
  className,
  description,
  descriptionClassName,
  error,
  headerAccessory,
  footerNode,
  compact = false,
  requiredProduct,
}: CardProps): React.ReactElement => {
  const { hasProduct } = useProductAccess();

  const CardContent = ({
    titleRowAccessory,
  }: {
    titleRowAccessory?: React.ReactNode;
  }) => (
    <>
      <div
        className={tcx(
          "flex flex-col text-left grow",
          compact ? "gap-1" : "gap-2",
        )}
      >
        <div className="text-sm text-content-primary font-semibold flex items-center justify-between">
          <div>{title}</div>
          {titleRowAccessory}
        </div>
        {description && (
          <div
            className={tcx(
              "text-sm-normal text-content-secondary",
              descriptionClassName,
            )}
          >
            {description}
          </div>
        )}
      </div>
      {footerNode && <div className="justify-self-end">{footerNode}</div>}
    </>
  );

  const hasRequiredProduct = requiredProduct
    ? hasProduct(requiredProduct)
    : true;

  const contentBoxClasses = tcx(
    "rounded-2 p-4 h-full w-full",
    clickable
      ? "hover:shadow-md hover:border-stroke-hover transition duration-150 shadow-sm border border-stroke"
      : "!cursor-auto",
    "bg-white",
    "flex flex-col gap-6",
    error && "border-red-200 hover:border-stroke-red-300",
  );

  if (compact) {
    return (
      <ContentBox
        className={tcx(
          contentBoxClasses,
          "flex-row items-start gap-3",
          className,
        )}
      >
        {iconOverride ? (
          iconOverride
        ) : (
          <IconBadge
            color={color}
            hexColor={hexColor}
            icon={icon}
            size={iconSize}
          />
        )}
        <div className="grow w-full flex flex-col gap-2">
          <CardContent titleRowAccessory={headerAccessory} />
        </div>
      </ContentBox>
    );
  }

  return (
    <ContentBox className={tcx(contentBoxClasses, className)}>
      <div className="flex justify-between items-center">
        {iconOverride ? (
          iconOverride
        ) : (
          <IconBadge
            color={color}
            hexColor={hexColor}
            icon={icon}
            size={iconSize}
          />
        )}
        {headerAccessory}
        {!hasRequiredProduct ? (
          <ProductGateTooltip requiredProduct={requiredProduct}>
            <IconBadge
              color={ColorPaletteEnum.Slate}
              icon={IconEnum.LockClosed}
              iconProps={{ className: "text-content-tertiary" }}
              size={IconSize.Small}
            />
          </ProductGateTooltip>
        ) : null}
      </div>
      <CardContent />
    </ContentBox>
  );
};
