/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentChannelSettings,
    IncidentChannelSettingsFromJSON,
    IncidentChannelSettingsFromJSONTyped,
    IncidentChannelSettingsToJSON,
} from './IncidentChannelSettings';

/**
 * 
 * @export
 * @interface SettingsUpdateIncidentChannelRequestBody
 */
export interface SettingsUpdateIncidentChannelRequestBody {
    /**
     * 
     * @type {IncidentChannelSettings}
     * @memberof SettingsUpdateIncidentChannelRequestBody
     */
    incident_channels?: IncidentChannelSettings;
}

export function SettingsUpdateIncidentChannelRequestBodyFromJSON(json: any): SettingsUpdateIncidentChannelRequestBody {
    return SettingsUpdateIncidentChannelRequestBodyFromJSONTyped(json, false);
}

export function SettingsUpdateIncidentChannelRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdateIncidentChannelRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_channels': !exists(json, 'incident_channels') ? undefined : IncidentChannelSettingsFromJSON(json['incident_channels']),
    };
}

export function SettingsUpdateIncidentChannelRequestBodyToJSON(value?: SettingsUpdateIncidentChannelRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_channels': IncidentChannelSettingsToJSON(value.incident_channels),
    };
}

