/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimestamp,
    IncidentTimestampFromJSON,
    IncidentTimestampFromJSONTyped,
    IncidentTimestampToJSON,
} from './IncidentTimestamp';

/**
 * 
 * @export
 * @interface IncidentTimestampsListResponseBody
 */
export interface IncidentTimestampsListResponseBody {
    /**
     * 
     * @type {Array<IncidentTimestamp>}
     * @memberof IncidentTimestampsListResponseBody
     */
    incident_timestamps: Array<IncidentTimestamp>;
}

export function IncidentTimestampsListResponseBodyFromJSON(json: any): IncidentTimestampsListResponseBody {
    return IncidentTimestampsListResponseBodyFromJSONTyped(json, false);
}

export function IncidentTimestampsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_timestamps': ((json['incident_timestamps'] as Array<any>).map(IncidentTimestampFromJSON)),
    };
}

export function IncidentTimestampsListResponseBodyToJSON(value?: IncidentTimestampsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_timestamps': ((value.incident_timestamps as Array<any>).map(IncidentTimestampToJSON)),
    };
}

