import {
  ErrorResponse,
  Settings,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

type Return = {
  settings: Settings | null;
  settingsLoading: boolean;
  settingsError?: ErrorResponse;
  refetchSettings: () => Promise<void>;
  // setSettings can be used if you already have a reference to updated settings
  // and don't want to refetch them from the server
  setSettings: (s: Settings) => void;
};

export const useSettings = (): Return => {
  const isAuthenticated = useIsAuthenticated();
  const { data, isLoading, mutate, error } = useAPI(
    isAuthenticated ? "settingsShow" : null,
    undefined,
  );

  return {
    settings: data === undefined ? null : data.settings,
    settingsError: error,
    settingsLoading: isLoading,
    refetchSettings: async () => {
      await mutate();
    },
    setSettings: (settings) => {
      mutate({ settings }, { revalidate: false });
    },
  };
};
