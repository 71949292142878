/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageSetSubscriptionSettingsRequestBody
 */
export interface StatusPageSetSubscriptionSettingsRequestBody {
    /**
     * Whether subscriptions have been entirely disabled for this status page
     * @type {boolean}
     * @memberof StatusPageSetSubscriptionSettingsRequestBody
     */
    subscriptions_disabled: boolean;
    /**
     * The email address that subscription updates should be sent to.
     * @type {string}
     * @memberof StatusPageSetSubscriptionSettingsRequestBody
     */
    subscriptions_reply_to?: string;
}

export function StatusPageSetSubscriptionSettingsRequestBodyFromJSON(json: any): StatusPageSetSubscriptionSettingsRequestBody {
    return StatusPageSetSubscriptionSettingsRequestBodyFromJSONTyped(json, false);
}

export function StatusPageSetSubscriptionSettingsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageSetSubscriptionSettingsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptions_disabled': json['subscriptions_disabled'],
        'subscriptions_reply_to': !exists(json, 'subscriptions_reply_to') ? undefined : json['subscriptions_reply_to'],
    };
}

export function StatusPageSetSubscriptionSettingsRequestBodyToJSON(value?: StatusPageSetSubscriptionSettingsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptions_disabled': value.subscriptions_disabled,
        'subscriptions_reply_to': value.subscriptions_reply_to,
    };
}

