import { Heading, Link } from "@incident-ui";
import _ from "lodash";
import { DependentResource as DependentResource } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

type Props = {
  requiresDeletionResources: DependentResource[][];
  whiteText?: boolean;
  headingSmall?: boolean;
} & (
  | {
      title: React.ReactNode;
      verb?: string;
      overrideTitle?: never;
    }
  | {
      title?: never;
      verb?: never;
      overrideTitle: React.ReactNode;
    }
);
export const DependentResourceList = ({
  title,
  overrideTitle,
  requiresDeletionResources,
  whiteText,
  verb = "delete",
  headingSmall = false,
}: Props): React.ReactElement => {
  return (
    <>
      <p className="mb-2">
        {overrideTitle ? (
          overrideTitle
        ) : (
          <>
            We can&apos;t {verb}
            {title == null ? null : (
              <>
                {" "}
                <b>{title}</b>
              </>
            )}
            , as the following resources depend on&nbsp;it:
          </>
        )}
      </p>
      <div className="mb-2">
        {_.map(requiresDeletionResources, (resources) => {
          return (
            <>
              <Heading
                className={tcx("mt-2", {
                  "!text-white": whiteText,
                  "!text-sm": headingSmall,
                })}
                level={2}
              >
                {resources[0].resource_type_label}
              </Heading>
              {resources.map((resource, idx) => (
                <li key={idx} className="ml-4">
                  <DependentResourceLink
                    resource={resource}
                    className={tcx({ "!text-white": whiteText })}
                  />
                </li>
              ))}
            </>
          );
        })}
      </div>
    </>
  );
};

export const DependentResourceLink = ({
  resource,
  className,
  children,
}: {
  resource: DependentResource;
  className?: string;
  children?: React.ReactNode;
}): React.ReactElement => {
  if (!resource.settings_url) {
    return <>{children ?? resource.label}</>;
  }

  return (
    <Link
      href={resource.settings_url}
      openInNewTab
      analyticsTrackingId={null}
      className={className}
    >
      {children ?? resource.label}
    </Link>
  );
};
