/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';
import {
    ScheduleCoverRequest,
    ScheduleCoverRequestFromJSON,
    ScheduleCoverRequestFromJSONTyped,
    ScheduleCoverRequestToJSON,
} from './ScheduleCoverRequest';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SchedulesShowCoverRequestResponseBody
 */
export interface SchedulesShowCoverRequestResponseBody {
    /**
     * 
     * @type {Schedule}
     * @memberof SchedulesShowCoverRequestResponseBody
     */
    schedule: Schedule;
    /**
     * 
     * @type {ScheduleCoverRequest}
     * @memberof SchedulesShowCoverRequestResponseBody
     */
    schedule_cover_request: ScheduleCoverRequest;
    /**
     * 
     * @type {Array<User>}
     * @memberof SchedulesShowCoverRequestResponseBody
     */
    users: Array<User>;
}

export function SchedulesShowCoverRequestResponseBodyFromJSON(json: any): SchedulesShowCoverRequestResponseBody {
    return SchedulesShowCoverRequestResponseBodyFromJSONTyped(json, false);
}

export function SchedulesShowCoverRequestResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesShowCoverRequestResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule': ScheduleFromJSON(json['schedule']),
        'schedule_cover_request': ScheduleCoverRequestFromJSON(json['schedule_cover_request']),
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function SchedulesShowCoverRequestResponseBodyToJSON(value?: SchedulesShowCoverRequestResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule': ScheduleToJSON(value.schedule),
        'schedule_cover_request': ScheduleCoverRequestToJSON(value.schedule_cover_request),
        'users': ((value.users as Array<any>).map(UserToJSON)),
    };
}

