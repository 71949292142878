import { Product } from "@incident-shared/billing";
import { conditionGroupsToConditions } from "@incident-shared/engine/conditions";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import {
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  SlackButtonPreview,
  Txt,
} from "@incident-ui";
import { captureMessage } from "@sentry/react";
import _ from "lodash";
import React, { useState } from "react";
import { SettingsSortableList } from "src/components/settings/SettingsSortableList";
import { SettingsSubHeading } from "src/components/settings/SettingsSubHeading";
import { SlackChannelBookmarksCreateEditModal } from "src/components/settings/slack-channel/SlackChannelBookmarksCreateEditModal";
import {
  EnabledSlackBookmark,
  EnabledSlackBookmarkBookmarkTypeEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIMutation } from "src/utils/swr";

export const SlackChannelBookmarksEditSection = ({
  canEditSettings,
}: {
  canEditSettings: boolean;
}): React.ReactElement => {
  const [showBookmarkModal, setShowAddBookmarkModal] = useState<boolean>(false);
  const [editingBookmark, setEditingBookmark] =
    useState<EnabledSlackBookmark | null>(null);

  const { hasResponse } = useProductAccess();

  const {
    data: { bookmarks: enabledBookmarks },
    error: fetchEnabledBookmarksError,
    isLoading: loadingEnabledBookmarks,
  } = useAPI("incidentChannelConfigsListEnabledBookmarks", undefined, {
    fallbackData: { bookmarks: [] },
  });

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "incidentChannelConfigsListEnabledBookmarks",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.incidentChannelConfigsDestroyBookmark({ id });
    },
  );

  const { trigger: updateRanks, isMutating: updatingRanks } = useAPIMutation(
    "incidentChannelConfigsListEnabledBookmarks",
    undefined,
    async (apiClient, updatedBookmarks: EnabledSlackBookmark[]) => {
      if (!enabledBookmarks) {
        // This can't happen: we can only update ranks of existing bookmarks.
        // But typescript needs to be told they'll exist when running the below code.
        return undefined;
      }
      const rank_updates = enabledBookmarks.map((bookmark) => {
        let rank = bookmark.rank;
        const updated = updatedBookmarks.find((x) => x.id === bookmark.id);
        if (updated) {
          rank = updated.rank;
        }
        return {
          resource_id: bookmark.id,
          rank,
        };
      });

      return await apiClient.incidentChannelConfigsUpdateBookmarkRanks({
        updateBookmarkRanksRequestBody: { rank_updates },
      });
    },
  );
  const saving = deleting || updatingRanks;

  if (loadingEnabledBookmarks) {
    return <Loader />;
  }

  if (fetchEnabledBookmarksError) {
    console.error(fetchEnabledBookmarksError);
    captureMessage("unable to fetch bookmarks", {
      extra: { error: fetchEnabledBookmarksError },
    });
    return <GenericErrorMessage error={fetchEnabledBookmarksError} />;
  }

  const sortedEnabledBookmarks = _.sortBy(enabledBookmarks, (qa) => qa.rank);

  return (
    <div>
      <SettingsSubHeading
        title="Channel bookmarks"
        titleHeadingLevel={2}
        className="!mb-2"
        explanation={
          <>
            We set channel bookmarks that help you quickly identify key
            characteristics about an incident. You can select these from our
            pre-defined list{hasResponse ? " or create your own" : ""}.
            Here&apos;s a preview of what they might look like:
          </>
        }
        accessory={
          <GatedButton
            onClick={() => setShowAddBookmarkModal(true)}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            requiredProduct={Product.Response}
            analyticsTrackingId="add-bookmark"
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
          >
            Add new
          </GatedButton>
        }
      />
      <SettingsSortableList
        canEdit={canEditSettings}
        updateItemRanks={updateRanks}
        saving={saving}
        items={sortedEnabledBookmarks}
        getNotConfigurableText={(bookmark) =>
          bookmark.bookmark_type ===
          EnabledSlackBookmarkBookmarkTypeEnum.Homepage
            ? "This bookmark is not configurable"
            : undefined
        }
        renderItem={(bookmark) => (
          <BookmarkRow
            key={bookmark.id}
            bookmark={bookmark}
            onEdit={() => setEditingBookmark(bookmark)}
            onDelete={() => onDelete(bookmark)}
          />
        )}
      />

      <Txt grey className="leading-6 my-2">
        If new bookmarks become applicable during an incident, we&apos;ll add
        them to the end of the list so they don&apos;t jump around too much.
      </Txt>

      {editingBookmark && (
        <SlackChannelBookmarksCreateEditModal
          bookmark={editingBookmark}
          enabledBookmarks={enabledBookmarks}
          onClose={() => setEditingBookmark(null)}
        />
      )}
      {showBookmarkModal && (
        <SlackChannelBookmarksCreateEditModal
          enabledBookmarks={enabledBookmarks}
          onClose={() => setShowAddBookmarkModal(false)}
        />
      )}
    </div>
  );
};

const BookmarkRow = ({
  bookmark,
  onDelete,
  onEdit,
}: {
  bookmark: EnabledSlackBookmark;
  onDelete: () => Promise<unknown>;
  onEdit: () => void;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const badge = (
    <SlackButtonPreview emoji={bookmark.emoji} text={bookmark.text} />
  );

  let editedIsGatedText: string | undefined = undefined;
  // If it's not a custom bookmark, and you don't have incident types, there's nothing
  // for you to customise
  if (
    !identity?.feature_gates.incident_types &&
    bookmark.bookmark_type !== EnabledSlackBookmarkBookmarkTypeEnum.Custom
  ) {
    editedIsGatedText =
      "To customise pre-defined bookmarks, you need to upgrade to a Pro plan";
  }

  return (
    <SettingsListItem
      disabled={
        bookmark.bookmark_type === EnabledSlackBookmarkBookmarkTypeEnum.Homepage
      }
      title={
        <div className="flex items-center gap-2">
          {badge}
          <Txt lightGrey xs>
            {bookmark.custom_url || bookmark.eligibility_reason}
          </Txt>{" "}
        </div>
      }
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        requiredProduct: Product.Response,
        edit: {
          onEdit,
          isGatedText: editedIsGatedText,
        },
        delete: {
          resourceTitle: bookmark.text,
          deleteConfirmationTitle: "Delete bookmark",
          deleteConfirmationContent: (
            <Txt>
              <p>
                Are you sure you want to delete the{" "}
                <Txt inline bold>
                  {bookmark.text}
                </Txt>{" "}
                bookmark?
              </p>
            </Txt>
          ),
          onDelete,
        },
      }}
      conditions={conditionGroupsToConditions(bookmark.condition_groups)}
    />
  );
};
