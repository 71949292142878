import { Icon, IconEnum } from "@incident-ui";
import { IncidentTimestamp } from "src/contexts/ClientContext";

import { useLifecycleOverview } from "../LifecycleOverviewContext";
import { Pill } from "../LifecycleUIElements";
import { TimestampsOnStatusChange } from "../TimestampsOnStatusChange";
import { LifecycleOverviewSection } from "./LifecycleOverviewSection";

export const DeclaredSection = ({
  timestamps,
}: {
  timestamps: IncidentTimestamp[];
}): React.ReactElement => {
  const { getConfigurationState } = useLifecycleOverview();
  const state = getConfigurationState(undefined);
  const isBackground = state === "background";

  return (
    <LifecycleOverviewSection
      diagram={
        <div className="w-full flex flex-col pt-4 pb-3">
          <Pill disabled={isBackground}>
            <Icon id={IconEnum.Incident} className="text-red-500" />
            Incident Declared
          </Pill>
        </div>
      }
      bottomBorderNode={
        <TimestampsOnStatusChange
          disabled={isBackground}
          timestamps={timestamps
            .filter((timestamp) => timestamp.set_on_creation)
            .map((timestamp) => ({ timestamp }))}
        />
      }
    />
  );
};
