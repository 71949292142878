/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionPayload,
    ConditionPayloadFromJSON,
    ConditionPayloadFromJSONTyped,
    ConditionPayloadToJSON,
} from './ConditionPayload';

/**
 * 
 * @export
 * @interface ConditionGroupPayload
 */
export interface ConditionGroupPayload {
    /**
     * All conditions in this list must be satisfied for the group to be satisfied
     * @type {Array<ConditionPayload>}
     * @memberof ConditionGroupPayload
     */
    conditions: Array<ConditionPayload>;
}

export function ConditionGroupPayloadFromJSON(json: any): ConditionGroupPayload {
    return ConditionGroupPayloadFromJSONTyped(json, false);
}

export function ConditionGroupPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionGroupPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': ((json['conditions'] as Array<any>).map(ConditionPayloadFromJSON)),
    };
}

export function ConditionGroupPayloadToJSON(value?: ConditionGroupPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': ((value.conditions as Array<any>).map(ConditionPayloadToJSON)),
    };
}

