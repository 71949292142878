import { Loader } from "@incident-ui";
import React, { useState } from "react";
import {
  IncidentLifecycle,
  IncidentLifecyclesCreateStatusRequestBodyCategoryEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
  IncidentTimestamp,
} from "src/contexts/ClientContext";
import { useSettings } from "src/hooks/useSettings";
import { tcx } from "src/utils/tailwind-classes";

import { IncidentStatusCreateModal } from "../statuses/status-create/IncidentStatusCreateModal";
import { IncidentStatusEditModal } from "../statuses/status-edit/IncidentStatusEditModal";
import { LifecycleOverviewProvider } from "./LifecycleOverviewContext";
import { prepareLifecycle } from "./prepareLifecycle";
import { ActiveSection } from "./sections/ActiveSection";
import { ClosedSection } from "./sections/ClosedSection";
import { DeclaredSection } from "./sections/DeclaredSection";
import { LIFECYCLE_GRID_CLASSNAME } from "./sections/LifecycleOverviewSection";
import { PostIncidentSection } from "./sections/PostIncidentSection";
import { TriageSection } from "./sections/TriageSection";

export const isEditableCategory = (
  category:
    | IncidentLifecyclesCreateStatusRequestBodyCategoryEnum
    | StatusCategoryEnum,
): boolean => {
  // IncidentStatusesCreateCategoryEnum is a subset of IncidentStatusCategoryEnum
  // but typescript isn't smart enough to know that
  //
  // We're using `IncidentStatusesCreateCategoryEnum`, as that's how the backend has defined
  // 'editable' statuses. So we can avoid redefining the same thing here by using that enum.
  return Object.values(
    IncidentLifecyclesCreateStatusRequestBodyCategoryEnum,
  ).includes(
    category as unknown as IncidentLifecyclesCreateStatusRequestBodyCategoryEnum,
  );
};

export const LifecycleOverview = ({
  lifecycle,
  isOnlyLifecycle,
  coreStatuses,
  timestamps,
}: {
  lifecycle: IncidentLifecycle;
  isOnlyLifecycle: boolean;
  timestamps: IncidentTimestamp[];
  coreStatuses: IncidentStatus[];
}): React.ReactElement => {
  const { settingsLoading } = useSettings();

  const [showAddStatusModal, setShowAddStatusModal] = useState(false);
  const [editingStatus, setEditingStatus] = useState<IncidentStatus | null>(
    null,
  );

  const prepared = prepareLifecycle({
    statuses: coreStatuses,
    timestamps,
    lifecycle,
  });

  if (settingsLoading) {
    return <Loader />;
  }

  return (
    <LifecycleOverviewProvider
      timestamps={timestamps}
      preparedLifecycle={prepared}
    >
      <div className="relative">
        <Underlay />
        <Overlay />
        <div className="z-10 h-full w-full relative">
          <DeclaredSection timestamps={timestamps} />
          <TriageSection
            statuses={coreStatuses}
            timestamps={timestamps}
            isOnlyLifecycle={isOnlyLifecycle}
          />
          <ActiveSection
            lifecycle={lifecycle}
            preparedStatuses={prepared.live}
            onCreate={() => setShowAddStatusModal(true)}
            onEdit={(status) => setEditingStatus(status)}
          />
          <PostIncidentSection
            lifecycle={lifecycle}
            preparedStatuses={prepared.postIncident}
            closedStatus={
              coreStatuses.filter(
                (s) => s.category === IncidentStatusCategoryEnum.Closed,
              )[0]
            }
          />
          <ClosedSection statuses={coreStatuses} />
        </div>
        {/* Modals */}
        {showAddStatusModal && (
          <IncidentStatusCreateModal
            onClose={() => setShowAddStatusModal(false)}
            category={
              IncidentLifecyclesCreateStatusRequestBodyCategoryEnum.Active
            }
            lifecycle={lifecycle}
          />
        )}
        {editingStatus && (
          <IncidentStatusEditModal
            onClose={() => setEditingStatus(null)}
            existingStatus={editingStatus}
          />
        )}
      </div>
    </LifecycleOverviewProvider>
  );
};

// Overlay draws a nice border and big shadow around the lifecycle section
const Overlay = () => {
  return (
    <div className="absolute flex items-center justify-center w-full h-full z-20 pointer-events-none">
      <div className={tcx(LIFECYCLE_GRID_CLASSNAME, "w-full h-full")}>
        <div />
        <div className="w-full h-full rounded-[6px] border border-stroke" />
      </div>
    </div>
  );
};

// Underlay creates the slate backround, and the vertical 'line' which is below the main content
const Underlay = () => {
  return (
    <div className="absolute flex items-center justify-center w-full h-full z-0 pointer-events-none">
      <div className={tcx(LIFECYCLE_GRID_CLASSNAME, "w-full h-full")}>
        <div />
        <div className="bg-surface-secondary rounded-[6px] pl-[39.5px] pt-10 pb-8">
          <div className=" border-stroke border-l h-full" />
        </div>
      </div>
    </div>
  );
};
