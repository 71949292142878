import { Searcher } from "fast-fuzzy";
import {
  FollowUp,
  Incident,
  PolicyViolation,
} from "src/contexts/ClientContext";

export const filterFollowUps = (
  incidents: Incident[],
  userId: string | undefined,
  policyViolations: PolicyViolation[],
  searchFilter: string,
): Incident[] => {
  const incidentsWithFollowUps = incidents.filter((i) => i.follow_ups?.length);

  let filtered = incidentsWithFollowUps;

  const searcher = new Searcher(
    incidentsWithFollowUps.flatMap((i) => i.follow_ups || []),
    {
      keySelector: (followUp) => followUp.title,
    },
  );

  if (searchFilter !== "") {
    const matchingFollowUpIDs = searcher.search(searchFilter).map((x) => x.id);
    filtered = filterIncidentsWithFollowUps(
      incidentsWithFollowUps,
      userId,
      policyViolations,
      MatchesList(matchingFollowUpIDs),
    );
  }

  return filtered;
};

// filterIncidentsWithFollowUps creates a list of incidents that have follow-ups
// which match the filtering function.
const filterIncidentsWithFollowUps = (
  incidents: Incident[],
  userId: string | undefined,
  policyViolations: PolicyViolation[],
  fn: (
    followUp: FollowUp,
    userId: string | undefined,
    policyViolations: PolicyViolation[],
  ) => boolean,
): Incident[] => {
  const filteredIncidents: Incident[] = [];

  incidents.forEach((inc) => {
    const filteredFollowUps = inc.follow_ups?.filter((followUp) => {
      return fn(followUp, userId, policyViolations);
    });
    if (filteredFollowUps && filteredFollowUps.length > 0) {
      const updatedInc = structuredClone(inc);
      updatedInc.follow_ups = filteredFollowUps;
      filteredIncidents.push(updatedInc);
    }
  });

  return filteredIncidents;
};

const MatchesList =
  (ids: string[]) =>
  (
    followUp: FollowUp,
    _userId: string | undefined,
    _policyViolations: PolicyViolation[],
  ): boolean => {
    return ids.includes(followUp.id);
  };
