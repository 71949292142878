import { Button, ButtonTheme, EmptyState, IconEnum } from "@incident-ui";

import { useProductAccess } from "../../../hooks/useProductAccess";
import { tcx } from "../../../utils/tailwind-classes";

export const AlertRoutesEmptyState = () => {
  const { hasOnCall } = useProductAccess();

  const resourceName = hasOnCall ? "incidents and escalations" : "incidents";

  return (
    <div className={"bg-white"}>
      <EmptyState
        icon={IconEnum.SplitArrow}
        iconColorProps={{
          className: "text-green-content",
          strokeColor: "#D8F8F0",
        }}
        className={tcx("bg-gradient-to-b from-[#EFFCF9] to-[#D8F8F000]")}
        content={
          <div className={"flex flex-col flex-center gap-6"}>
            <div className={"text-center"}>
              <div className="text-content-primary font-medium text-base">
                Create {resourceName} from your alerts
              </div>
              <div className="text-content-tertiary">
                Use alert routes to automatically create {resourceName} within
                incident.io
              </div>
            </div>
            <div className={"flex flex-row gap-4"}>
              <Button
                analyticsTrackingId="alert-routes-create-first"
                theme={ButtonTheme.Primary}
                href={"/alerts/routes/create"}
              >
                Create an alert route
              </Button>
              <Button
                analyticsTrackingId="alerts-no-alert-routes-learn-more"
                theme={ButtonTheme.Secondary}
                href={
                  "https://help.incident.io/en/articles/9565315-creating-escalations-and-incidents-from-alerts"
                }
              >
                Learn more
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};
