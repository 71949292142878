import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { useIntercom } from "react-use-intercom";

import graphic from "./first-access.svg";

export const PINC_FIRST_ACCESS_MODAL = "post-incident-first-access";

export const PostIncidentFirstAccessModal = ({
  onClose,
  loading,
}: {
  onClose: () => void;
  loading: boolean;
}): React.ReactElement => {
  const { showArticle } = useIntercom();
  return (
    <Modal
      isOpen
      title={""} // hidden
      hideHeader
      onClose={onClose}
      analyticsTrackingId={null}
      className="!w-[600px]"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        <img className="w-full" src={graphic} />
        <div className="flex flex-col items-center p-10 pb-6 gap-3 text-center">
          <div className="text-2xl-bold">Stay on top of your tasks</div>
          <div className="text-sm-normal flex flex-col gap-2">
            <div>
              Consistently learn from incidents and ensure resolution by
              tracking outstanding tasks and follow-ups in the Post-incident hub
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <div className="flex gap-2 items-center mx-auto pb-2">
          <Button
            analyticsTrackingId={null}
            onClick={() => showArticle(8031305)}
            theme={ButtonTheme.Secondary}
            loading={loading}
            disabled={loading}
          >
            Learn more
          </Button>
          <Button
            analyticsTrackingId={null}
            onClick={onClose}
            theme={ButtonTheme.Primary}
            loading={loading}
            disabled={loading}
          >
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
