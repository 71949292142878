import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  Button,
  Callout,
  CalloutTheme,
  IconEnum,
  ModalFooter,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useClient } from "src/contexts/ClientContext";
import { useClipboard } from "src/utils/useClipboard";

type FormData = {
  prompt: string;
  returns_json: boolean;
  ignore_after_timestamp?: Date;
};

type AITestPromptModalProps = {
  incidentIds: string[];
  onClose: () => void;
};

export const AITestPromptModal = ({
  incidentIds,
  onClose,
}: AITestPromptModalProps) => {
  const [text, setText] = useState<string>();
  const { hasCopied: hasCopiedResponse, copyTextToClipboard: copyResponse } =
    useClipboard();
  const { hasCopied: hasCopiedSnippet, copyTextToClipboard: copySnippet } =
    useClipboard();
  const apiClient = useClient();
  const formMethods = useForm<FormData>({
    defaultValues: {
      prompt: "",
      returns_json: false,
    },
  });

  const onSubmit = async (values: FormData) => {
    const response = await apiClient.aIStaffTestPrompt({
      testPromptRequestBody: {
        incident_ids: incidentIds,
        prompt: values.prompt,
        return_json: values.returns_json,
        ignore_after_timestamp: values.ignore_after_timestamp?.toISOString(),
      },
    });

    setText(response.response);
  };

  const onCopyResponseClick = () => {
    if (text) {
      copyResponse(text);
    }
  };

  const [isGenerating, setIsGenerating] = useState(false);
  const onCopyPromptClick = async () => {
    try {
      setIsGenerating(true);
      const res = await apiClient.incidentsIncidentSnippet({
        id: incidentIds[0],
        includeMessages: true,
      });
      copySnippet(res.snippet);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Test AI Prompt"
      onSubmit={onSubmit}
      onClose={onClose}
      analyticsTrackingId={"test-ai-prompt"}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Test prompt"
          confirmButtonType="submit"
        />
      }
    >
      <Callout
        theme={CalloutTheme.AIRainbow}
        cta={
          <Button
            onClick={onCopyPromptClick}
            analyticsTrackingId={null}
            disabled={isGenerating || hasCopiedSnippet}
            loading={isGenerating}
          >
            {hasCopiedSnippet ? "Copied" : "Copy"}
          </Button>
        }
      >
        Building a Copilot prompt? You&rsquo;ll want the incident context
        snippet to use in the OpenAI Playground.
      </Callout>
      <TextareaV2
        formMethods={formMethods}
        name="prompt"
        label={"Prompt"}
        placeholder="You are an AI responsible for testing AI prompts..."
      />
      <ToggleV2
        formMethods={formMethods}
        name="returns_json"
        label={"Returns JSON"}
        helptext={"Enforce a JSON response from the AI provider."}
      />
      <DateTimeInputV2
        formMethods={formMethods}
        name="ignore_after_timestamp"
        label="Ignore after"
        helptext={
          "If provided, slack messages an incident updates after this time will not be included in the prompt context."
        }
      />
      {text !== undefined && (
        <>
          <Form.Label htmlFor="response">Response</Form.Label>
          <div className="relative max-h-40">
            <Button
              icon={hasCopiedResponse ? IconEnum.Tick : IconEnum.Copy}
              title="Copy output"
              className="absolute top-1 right-1"
              onClick={onCopyResponseClick}
              analyticsTrackingId={"copy-ai-output"}
              disabled={hasCopiedResponse}
            />
            <pre className="bg-gray-100 p-2 rounded-md max-h-40 overflow-y-auto">
              {text}
            </pre>
          </div>
        </>
      )}
    </Form.Modal>
  );
};
