import { Icon, IconEnum, Spinner } from "@incident-ui";
import { useEffect, useState } from "react";

export function useOptimisticAutoSave<FormState>({
  initialState,
  saveState,
}: {
  initialState: FormState;
  saveState: (state: FormState) => Promise<unknown>;
}): {
  saving: boolean;
  setState: (
    newState: FormState | ((prevState: FormState) => FormState),
  ) => void;
  state: FormState;
  hasSaved: boolean;
} {
  const [state, setState] = useState<FormState>(initialState);
  const [saving, setSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const onSubmit = async (data: FormState) => {
    setSaving(true);
    await saveState(data);
    setSaving(false);
    setHasSaved(true);
  };

  // Automatically hide the 'saved' text after 2 seconds
  useEffect(() => {
    if (!hasSaved) return undefined;

    const timeout = setTimeout(() => setHasSaved(false), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [hasSaved]);

  return {
    state,
    setState: (
      newStateOrFunc: FormState | ((prevState: FormState) => FormState),
    ) => {
      let newState: FormState;
      if (newStateOrFunc instanceof Function) {
        newState = newStateOrFunc(state);
      } else {
        newState = newStateOrFunc;
      }

      onSubmit(newState);
      setState(newState);
    },
    saving,
    hasSaved,
  };
}

export const AutoSavingIndicator = ({
  saving,
  hasSaved,
}: {
  saving: boolean;
  hasSaved: boolean;
}) => {
  if (saving) {
    return <Spinner />;
  }

  if (hasSaved) {
    return (
      <span className={"flex-center-y text-green-content text-xs"}>
        <Icon id={IconEnum.Tick} className={"w-4 h-4 mr-0.5"} /> Saved
      </span>
    );
  }

  return null;
};
