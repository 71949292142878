import { GenericErrorMessage, Loader } from "@incident-ui";
import { isEqual, omit } from "lodash";
import { useCallback } from "react";
import { FieldValues, useController } from "react-hook-form";
import {
  TemplatedTextEditor,
  TemplatedTextEditorProps,
} from "src/components/@shared/forms/v1/TemplatedText/TemplatedTextEditor";
import { TextNode } from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const TemplatedTextInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<
    TFormType,
    Omit<TemplatedTextEditorProps, "resources">
  >,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    Omit<TemplatedTextEditorProps, "resources">
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  const formMethods = props.formMethods;
  const watch = formMethods.watch;
  const fieldValue: TextNode | undefined = watch(name);

  const onChange = useCallback(
    (v?: TextNode) => {
      if (!isEqual(v, field.value)) {
        field.onChange(v);
        onValueChange && onValueChange(v);
      }
    },
    [field, onValueChange],
  );

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (
    resourcesLoading &&
    (props.includeVariables || props.includeExpressions)
  ) {
    return <Loader />;
  }

  return (
    <FormInputWrapper<TFormType> {...wrapperProps}>
      <TemplatedTextEditor
        id={name}
        {...omit(field, "onChange", "ref")}
        {...inputProps}
        resources={resources}
        value={fieldValue}
        onChange={onChange}
      />
    </FormInputWrapper>
  );
};

export const TemplatedTextInputAsStringV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TemplatedTextEditorProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  // We're using the value from formMethods here because the value from
  // useController is wrong. This happens specifially when trying to set the
  // value of the field to null with setValue() - formMethods shows the field is
  // null, but useController shows its intial value, so the field is never
  // cleared. This works for now but we haven't understood why we're getting
  // conflicting values.
  const formMethods = props.formMethods;
  const watch = formMethods.watch;
  const fieldValue = watch(name);

  const valueAsJson = (v?: TextNode | string): TextNode | undefined => {
    if (v === undefined) {
      return v;
    }
    if (typeof v === "string") {
      return JSON.parse(v);
    }
    return v;
  };

  const onChange = (v?: TextNode | string) => {
    const value = valueAsJson(v);
    const fieldValueAsJson = valueAsJson(fieldValue);

    if (!isEqual(value, fieldValueAsJson)) {
      field.onChange(JSON.stringify(value));
    }
  };

  return (
    <FormInputWrapper<TFormType> {...wrapperProps}>
      <TemplatedTextEditor
        id={name}
        {...omit(field, "onChange", "ref")}
        {...inputProps}
        value={
          typeof fieldValue === "string" ? JSON.parse(fieldValue) : fieldValue
        }
        onChange={onChange}
      />
    </FormInputWrapper>
  );
};
