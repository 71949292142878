// TimelineBackground is absolutely positioned behind the 'schedule entries' on a rota.
// it displays the columns of each day/hour etc in the timeline.
import {
  calculateSegments,
  TimePeriodOption,
} from "@incident-shared/schedules/ScheduleOverview/types";
import * as d3 from "d3";
import { DateTime } from "luxon";
import { tcx } from "src/utils/tailwind-classes";

// TimelineBackground shows the vertical columns
// that sit at the back of the timeline.
export const TimelineBackground = ({
  xScale,
  timePeriod,
  timelineStartPoint,
}: {
  xScale: d3.ScaleTime<number, number>;
  timePeriod: TimePeriodOption;
  timelineStartPoint: DateTime;
}) => {
  // Calculate the title of each segment
  // These don't currently handle leap years and/or daylight savings. I'm not yet sure how much that matters.

  const segments = calculateSegments({
    timePeriod,
    timelineStartPoint,
  });

  return (
    <div className="relative w-full h-full pointer-events-none">
      {/* for each segment, display a divider*/}
      {segments.map((dateTime, i) => {
        const isWeekend: boolean =
          dateTime.weekday === 6 || dateTime.weekday === 7;
        const nextSegment = segments[i + 1];

        return (
          <div
            key={i}
            className={tcx({
              "bg-surface-secondary bg-opacity-40": isWeekend,
              "border-r-[1px] border-r-stroke-secondary": true,
              "border-l-[1px] border-l-stroke": i === 0,
            })}
            style={{
              position: "absolute",
              left: xScale(dateTime.toJSDate()),
              top: 0,
              bottom: 0,
              ...(nextSegment
                ? {
                    width:
                      xScale(nextSegment.toJSDate()) -
                      xScale(dateTime.toJSDate()),
                  }
                : {
                    right: 0,
                  }),
            }}
          />
        );
      })}
    </div>
  );
};
