import { tcx } from "src/utils/tailwind-classes";

export const AlertFiringIndicator = ({
  firing,
  className,
  dotClassName,
}: {
  firing?: boolean;
  className?: string;
  dotClassName?: string;
}) => {
  return (
    <div className="w-4 h-4 p-1">
      {/* we need 16px to account for the pulse, and a padding of 4px to put it in the middle */}
      <div className={tcx(className, "flex relative w-[8px] h-[8px] shrink-0")}>
        {/* If we're in the firing state, this creates the animated ping behind the solid dot */}
        {firing ? (
          <div className="absolute inline-flex w-full h-full rounded-full bg-alarmalade-600 animate-ping-slow" />
        ) : null}
        <div
          className={tcx(
            dotClassName,
            "relative inline-flex w-full h-full rounded-full bg-alarmalade-600",
            {
              "bg-alarmalade-600": firing,
              "bg-green-500": !firing,
            },
          )}
        />
      </div>
    </div>
  );
};
