import type { SVGProps } from "react";
import * as React from "react";
const SvgWorkflows = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.5 9H11V7.25a.75.75 0 0 0-1.5 0V9H8a2.752 2.752 0 0 0-2.75 2.75v1a.75.75 0 0 0 1.5 0v-1c0-.689.561-1.25 1.25-1.25h4.5c.689 0 1.25.561 1.25 1.25v1a.75.75 0 0 0 1.5 0v-1A2.752 2.752 0 0 0 12.5 9Z"
    />
    <path
      fill="currentColor"
      d="M6 18a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14.5 18a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM11.5 2H9a1.75 1.75 0 0 0-1.75 1.75v2.5C7.25 7.216 8.034 8 9 8h2.5a1.75 1.75 0 0 0 1.75-1.75v-2.5A1.75 1.75 0 0 0 11.5 2Z"
    />
  </svg>
);
export default SvgWorkflows;
