import { FieldValues, Path, useController } from "react-hook-form";
import {
  ListEditor,
  ListEditorProps,
} from "src/components/@shared/forms/v1/ListEditor";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const ListEditorV2 = <
  TFormType extends FieldValues,
  TIDType extends string | number,
>(
  props: InputElementProps<TFormType, ListEditorProps<TIDType>>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const {
    // We don't want to pass the ref onwards here: this is a complex component
    // which references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <ListEditor {...inputProps} {...field} />
    </FormInputWrapper>
  );
};
