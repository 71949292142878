/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsShortcutInstallRequestBody,
    IntegrationsShortcutInstallRequestBodyFromJSON,
    IntegrationsShortcutInstallRequestBodyToJSON,
} from '../models';

export interface IntegrationsShortcutInstallRequest {
    installRequestBody: IntegrationsShortcutInstallRequestBody;
}

/**
 * 
 */
export class IntegrationsShortcutApi extends runtime.BaseAPI {

    /**
     * Install the Shortcut integration
     * Install Integrations - Shortcut
     */
    async integrationsShortcutInstallRaw(requestParameters: IntegrationsShortcutInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installRequestBody === null || requestParameters.installRequestBody === undefined) {
            throw new runtime.RequiredError('installRequestBody','Required parameter requestParameters.installRequestBody was null or undefined when calling integrationsShortcutInstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/shortcut`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsShortcutInstallRequestBodyToJSON(requestParameters.installRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Install the Shortcut integration
     * Install Integrations - Shortcut
     */
    async integrationsShortcutInstall(requestParameters: IntegrationsShortcutInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsShortcutInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Uninstall the Shortcut integration
     * Uninstall Integrations - Shortcut
     */
    async integrationsShortcutUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/shortcut`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uninstall the Shortcut integration
     * Uninstall Integrations - Shortcut
     */
    async integrationsShortcutUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsShortcutUninstallRaw(initOverrides);
    }

}
