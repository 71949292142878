import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { IconEnum, Loader } from "@incident-ui";
import React from "react";
import { useIntegrations } from "src/hooks/useIntegrations";

import { ReportGeneratorWrapper } from "../../../components/legacy/on-call-legacy/ReportGenerator";
import { IntegrationSettingsProviderEnum } from "../../../contexts/ClientContext";
import { useIdentity } from "../../../contexts/IdentityContext";
import { useQueryParams } from "../../../utils/query-params";

export const OnCallReportsAddRoute = (): React.ReactElement => {
  const { identity } = useIdentity();

  const params = useQueryParams();

  const reportId = params.get("reportId");
  const { integrations } = useIntegrations();

  if (!identity || !integrations) {
    return <Loader />;
  }

  const pagerDutyIntegration = integrations.find(
    ({ provider }) => provider === IntegrationSettingsProviderEnum.Pagerduty,
  );

  const opsgenieIntegration = integrations.find(
    ({ provider }) => provider === IntegrationSettingsProviderEnum.Opsgenie,
  );

  if (
    !identity.feature_gates.on_call_calculator &&
    (!pagerDutyIntegration || !pagerDutyIntegration.installed) &&
    (!opsgenieIntegration || !opsgenieIntegration.installed)
  ) {
    return <OrgAwareNavigate to="/on-call/pay-calculator" replace />;
  }

  return (
    <PageWrapper
      icon={IconEnum.Doc}
      width={PageWidth.Medium}
      title="Create a report"
      backHref="/on-call/pay-calculator"
    >
      <ReportGeneratorWrapper reportId={reportId} />
    </PageWrapper>
  );
};
