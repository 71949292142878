import { BadgeSize, Button, ButtonTheme } from "@incident-ui";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { getShortTimeZone } from "src/utils/datetime";

import { GenericTimelineItem, GroupedTimelineElement } from "./Timeline";

type TimelineHeaderProps<
  T extends GenericTimelineItem,
  AccessorKey extends string,
> = {
  accessorKey: AccessorKey;
  minimizedItems: string[];
  setMinimizedItems: React.Dispatch<React.SetStateAction<string[]>>;
  timelineItems: GroupedTimelineElement<T, AccessorKey>[];
  headerAccessory?: React.ReactNode;
  supportsMinimizing: boolean;
  compact?: boolean;
};

export const TimelineHeader = <
  T extends GenericTimelineItem,
  AccessorKey extends string,
>({
  accessorKey,
  minimizedItems,
  setMinimizedItems,
  supportsMinimizing,
  timelineItems,
  headerAccessory,
  compact,
}: TimelineHeaderProps<T, AccessorKey>) => {
  return (
    <div className="flex-center-y flex-wrap justify-end gap-2">
      <TimelineTimezoneContext />
      {supportsMinimizing && (
        <ExpandTimelineItemsButton
          minimizedItems={minimizedItems}
          setMinimizedItems={setMinimizedItems}
          timelineItems={timelineItems}
          compact={compact}
          accessorKey={accessorKey}
        />
      )}
      {headerAccessory}
    </div>
  );
};

export const TimelineTimezoneContext = () => {
  return (
    <div className="text-xs-med text-content-tertiary pr-1 flex-shrink-0">
      Times shown in {getShortTimeZone(new Date())}
    </div>
  );
};

export const ExpandTimelineItemsButton = <
  T extends GenericTimelineItem,
  AccessorKey extends string,
>({
  accessorKey,
  minimizedItems,
  setMinimizedItems,
  timelineItems,
  compact,
}: {
  accessorKey;
  minimizedItems: string[];
  setMinimizedItems: (minimized: string[]) => void;
  timelineItems: GroupedTimelineElement<T, AccessorKey>[];
  compact?: boolean;
}) => {
  const hasMinimizedItems = minimizedItems.length > 0;

  const openAll = () => {
    setMinimizedItems([]);
  };

  const closeAll = () => {
    setMinimizedItems(
      timelineItems
        .flatMap((i) => i.items)
        .filter((item) => item?.[accessorKey])
        .map((item) => item?.[accessorKey]?.id ?? ""),
    );
  };

  if (compact) {
    // Show a naked button with just an icon on it.
    return (
      <Button
        analyticsTrackingId={"timeline-expand-all"}
        onClick={hasMinimizedItems ? openAll : closeAll}
        size={BadgeSize.Medium}
        theme={ButtonTheme.Naked}
        title={hasMinimizedItems ? "Expand all" : "Collapse all"}
      >
        <Icon id={IconEnum.Collapse} />
      </Button>
    );
  }

  return (
    <Button
      analyticsTrackingId={"timeline-expand-all"}
      onClick={hasMinimizedItems ? openAll : closeAll}
      size={BadgeSize.Medium}
      icon={hasMinimizedItems ? IconEnum.Expand : IconEnum.Collapse}
    >
      {hasMinimizedItems ? "Expand all" : "Collapse all"}
    </Button>
  );
};
