import { AddEditExpressionModal } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import {
  FormDataWithExpressions,
  useExpressionsMethods,
  validateExpressionsMethods,
} from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ExpressionFixedResultType } from "@incident-shared/engine/expressions/ifelse/createDefaultExpressionFormValues";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import { BadgeSize, Button, IconEnum, Loader } from "@incident-ui";
import React, { useState } from "react";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import {
  EngineParamBindingValue,
  EngineScope,
  Reference,
  Resource,
  ResourceFieldConfigTypeEnum,
} from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";

import { makeExpressionReference } from "../expressions/addExpressionsToScope";
import {
  canBeCastTo,
  EngineRefIsSelectable,
  MenuEntry,
  ReferenceSelectorPopover,
} from "../ReferenceSelectorPopover/ReferenceSelectorPopover";
import { isExpression } from "../referenceSource";
import { EngineFormElementReference } from "./EngineFormElementReference";
import { LightningButton } from "./LightningButton";
import { ScopeAndIsAlert } from "./MultiValueEngineFormElement";

type validateFn =
  | ((binding: EngineParamBindingValue[]) => string | boolean)
  | ((binding: EngineParamBindingValue) => string | boolean);

// InputOrVariable usually takes a 'resource' (e.g. Timestamp) which tells it what type of reference
// to allow (i.e. how to filter the scope to only relevant references, and blocking expressions that are
// the wrong type). However, in some cases (at time of writing, Jira sync weirdness) we want to be able
// to allow any resource type (and use an isSelectableOverride to define which references are allowed).
type ResourceProps =
  | {
      resource: Resource;
      allowAnyResource?: never;
      isSelectableOverride?: never;
    }
  | {
      resource?: never;
      allowAnyResource: true;
      isSelectableOverride: EngineRefIsSelectable;
    };

export type VariablePopoverComponent = React.ComponentType<{
  renderTriggerButton: ({
    onClick,
  }: {
    onClick: () => void;
  }) => React.ReactElement;
}>;

type InputOrVariableProps<FormType extends FieldValues> = {
  name: Path<FormType>;
  label: string;
  required: boolean;
  disabled?: boolean;
  array: boolean;
  renderChildren: (
    renderLightningButton: () => React.ReactNode,
  ) => React.ReactElement;
  includeExpressions: boolean;
  includeVariables: boolean;
  includeStatic: boolean;
  expressionLabelOverride?: string;
  formFieldType?: ResourceFieldConfigTypeEnum;
  onEditExpression?: (expression: ExpressionFormData) => void;
  onDeleteExpression?: (expression: ExpressionFormData) => void;
  onClickVariableButton?: () => void;
  scopeAndIsAlert: ScopeAndIsAlert;
} & ResourceProps;

export const InputOrVariable = <FormType extends FieldValues>({
  name,
  label,
  scopeAndIsAlert,
  resource,
  required,
  disabled,
  array,
  includeExpressions,
  includeVariables,
  includeStatic,
  renderChildren,
  expressionLabelOverride,
  isSelectableOverride,
  onEditExpression,
  onDeleteExpression,
  onClickVariableButton,
  formFieldType,
}: InputOrVariableProps<FormType>): React.ReactElement | null => {
  let validate: validateFn = () => true;
  const formMethods = useFormContext<FormType>();

  if (array) {
    validate = (binding: EngineParamBindingValue[]): string | boolean => {
      const populated =
        binding?.filter(
          (b) => b.literal !== undefined || b.reference !== undefined,
        ) || [];
      if (required && populated.length === 0) {
        return "Please choose at least one value";
      }
      return true;
    };
  } else {
    validate = (binding: EngineParamBindingValue): string | boolean => {
      // Special case checkbox: this is always valid as empty means false.
      if (
        resource?.field_config.type === ResourceFieldConfigTypeEnum.Checkbox
      ) {
        return true;
      }
      if (
        required &&
        (binding?.literal === undefined || binding?.literal === "") &&
        binding?.reference === undefined
      ) {
        return "This field is required";
      }
      return true;
    };
  }

  const { field } = useController<FormType>({
    name,
    rules: { validate },
  });
  let usingRef = false;
  let refValue: (EngineParamBindingValue & { icon: IconEnum }) | undefined;

  const currentValue = formMethods.watch(name);

  if (array) {
    usingRef = currentValue && currentValue[0]?.reference;
    refValue = currentValue ? currentValue[0] : undefined;
  } else {
    usingRef = !!currentValue?.reference;
    refValue = currentValue;
  }

  const onChange = (ref) => {
    if (array) {
      field.onChange([
        {
          reference: ref.key,
          label: ref.label,
          icon: ref.icon,
          literal: undefined,
        },
      ]);
    } else {
      field.onChange({
        reference: ref.key,
        label: ref.label,
        icon: ref.icon,
        literal: undefined,
      });
    }
  };

  const onClear = () => {
    array ? field.onChange([]) : field.onChange(undefined);
  };

  let isSelectable: EngineRefIsSelectable = () => true;
  if (isSelectableOverride) {
    isSelectable = isSelectableOverride;
  } else if (resource) {
    isSelectable = canBeCastTo({ resource, array });
  }

  const isRefAnExpression = isExpression(refValue?.reference);

  const expressionsMethods = useExpressionsMethods<
    FormDataWithExpressions,
    "expressions"
  >();
  if (isRefAnExpression && !validateExpressionsMethods(expressionsMethods)) {
    return null;
  }

  let expressionLabel = expressionsMethods?.showExpressionNames ? "" : label;
  if (expressionLabelOverride) {
    expressionLabel = expressionLabelOverride;
  }

  const expressionFixedResultType: ExpressionFixedResultType | undefined =
    resource
      ? {
          type: resource.type,
          typeIsAutocompletable: resource.autocompletable,
          label: expressionLabel,
          typeLabel: resource.type_label,
          array: array,
        }
      : undefined;

  // In alerts, we show a different expression modal for editing attributes (JSONAttributeExpressionEditor).
  // Eventually, we might collapse the If/Else query expressions and align designs for other query expressions, but for
  // now this is the case. We show it straight away when clicking the lightning button, but this isn't very useful if
  // you have resources in scope that could be useful, e.g. for PagerDuty services. So if we see we have something
  // relevant in the scope, we'll show you the ReferenceSelectorPopover, and override the "Add new expression" click.
  const resourceHasCandidatesInScope =
    scopeAndIsAlert.isAlertElement &&
    resource &&
    scopeAndIsAlert.scope.references.some((ref) => ref.type === resource.type);

  if (usingRef && isRefAnExpression && refValue?.reference) {
    return (
      <ViewEditableExpression
        reference={refValue.reference}
        onEdit={onEditExpression}
        onDelete={
          onDeleteExpression
            ? (e) => {
                onDeleteExpression(e);
                onClear();
              }
            : onClear
        }
        scopeAndIsAlert={scopeAndIsAlert}
        elseBranchRequired={required}
      />
    );
  }

  const shouldRenderButton =
    includeVariables || includeExpressions || onClickVariableButton;

  // If we're in 'expressions only mode', we don't want to show the form element, instead we just
  // want to show an 'add expression' button.
  if (includeStatic === false && !usingRef) {
    return (
      <AddExpressionButton
        scopeAndIsAlert={scopeAndIsAlert}
        elseBranchRequired={required}
        fixedResult={expressionFixedResultType}
        onAdd={onChange}
      />
    );
  }

  return (
    <>
      {
        // If the current param is a reference, show the reference pill.
        usingRef ? (
          <EngineFormElementReference
            label={refValue?.label || ""}
            onSelectReference={onChange}
            referenceKey={refValue?.reference || ""}
            scope={scopeAndIsAlert.scope}
            disabled={disabled}
            isSelectable={isSelectable}
            expressionFixedResultType={expressionFixedResultType}
            onUseLiteral={onClear}
            includeExpressions={includeExpressions}
            overrideOnAddExpression={
              resourceHasCandidatesInScope ? onClickVariableButton : undefined
            }
          />
        ) : (
          <div className={"flex flex-row gap-2 w-full items-start"}>
            {renderChildren(() =>
              shouldRenderButton ? (
                <LightningButtonOrDisabled
                  includeVariables={includeVariables}
                  includeExpressions={includeExpressions}
                  scope={scopeAndIsAlert.scope}
                  onChange={onChange}
                  formFieldType={formFieldType}
                  expressionFixedResultType={expressionFixedResultType}
                  isSelectable={isSelectable}
                  onClickVariableButton={
                    !resourceHasCandidatesInScope
                      ? onClickVariableButton
                      : undefined
                  }
                  overrideOnAddExpression={
                    resourceHasCandidatesInScope
                      ? onClickVariableButton
                      : undefined
                  }
                  disabled={disabled}
                  elseBranchRequired={required}
                />
              ) : null,
            )}
          </div>
        )
      }
    </>
  );
};

const LightningButtonOrDisabled = ({
  includeVariables,
  includeExpressions,
  scope,
  onChange,
  formFieldType,
  expressionFixedResultType,
  isSelectable,
  onClickVariableButton,
  overrideOnAddExpression,
  disabled,
  elseBranchRequired,
}: {
  includeVariables: boolean;
  includeExpressions: boolean;
  scope: EngineScope;
  onChange: (ref: MenuEntry) => void;
  formFieldType?: ResourceFieldConfigTypeEnum;
  expressionFixedResultType?: ExpressionFixedResultType;
  disabled?: boolean;
  isSelectable: EngineRefIsSelectable;
  onClickVariableButton?: () => void;
  overrideOnAddExpression?: () => void;
  elseBranchRequired?: boolean;
}): React.ReactElement | null => {
  if (onClickVariableButton) {
    return (
      <LightningButton onClick={onClickVariableButton} disabled={disabled} />
    );
  }

  if (!includeVariables && !includeExpressions) {
    return null;
  }

  return (
    <ReferenceSelectorPopover
      scope={scope}
      isSelectable={isSelectable}
      onSelectReference={onChange}
      allowExpressions={includeExpressions}
      expressionFixedResultType={expressionFixedResultType}
      pauseOnAddExpression={
        formFieldType === ResourceFieldConfigTypeEnum.TemplatedTextEditorInput
      }
      renderTriggerButton={({ onClick }) => (
        <LightningButton
          onClick={() => {
            onClick();
          }}
          disabled={disabled}
        />
      )}
      overrideOnAddExpression={overrideOnAddExpression}
      elseBranchRequired={elseBranchRequired}
    />
  );
};

LightningButton.displayName = "LightningButton";

// ViewEditableExpression is a wrapper around ViewExpression, but also implements the 'onEdit' and 'onDelete' props
// based on the form state path provided
export const ViewEditableExpression = ({
  reference,
  onEdit,
  onDelete,
  scopeAndIsAlert,
  elseBranchRequired,
}: {
  reference: string;
  onEdit?: (e: ExpressionFormData) => void;
  onDelete?: (() => void) | ((e: ExpressionFormData) => void);
  scopeAndIsAlert: ScopeAndIsAlert;
  elseBranchRequired?: boolean;
}): React.ReactElement | null => {
  const [showEditModal, setShowEditModal] = useState(false);
  const expressionsMethods = useExpressionsMethods<
    FormDataWithExpressions,
    "expressions"
  >();

  const { resources, resourcesLoading } = useAllResources();

  if (!validateExpressionsMethods(expressionsMethods)) {
    // validateExpressionsMethods throws in dev, but let's show a sensible fallback in prod
    return (
      <span>
        {scopeAndIsAlert.scope.references.find((r) => r.key === reference)
          ?.label ?? reference}
      </span>
    );
  }

  const {
    expressionsMethods: methods,
    showExpressionNames,
    displayMini,
  } = expressionsMethods;

  const expression = methods?.fields.find((expression) =>
    reference.includes(expression.reference),
  );
  if (!expression) {
    // Sometimes, there's no expression because we are waiting for a re-render. In this case, let's just not render
    // anything as it's so fast a human can't actually see it.
    return null;
  }

  const expressionIdx = methods.fields.findIndex(
    (e) => e.reference === expression.reference,
  );

  if (resourcesLoading) {
    return <Loader />;
  }

  const onEditExpression = (updatedExpression) => {
    methods.update(expressionIdx, updatedExpression);
    setShowEditModal(false);
  };
  return (
    <>
      <ViewExpression
        expression={expression}
        onEdit={onEdit ? onEdit : () => setShowEditModal(true)}
        scope={scopeAndIsAlert.scope}
        onDelete={onDelete}
        showExpressionName={showExpressionNames}
        displayMini={displayMini}
      />
      {showEditModal && (
        <AddEditExpressionModal
          onClose={() => setShowEditModal(false)}
          onEditExpression={onEditExpression}
          // We don't need to provide this as we'll never be adding a new expression through this path.
          onAddExpression={() => null}
          initialExpression={expression}
          scope={scopeAndIsAlert.scope}
          resources={resources}
          analyticsTrackingContext={"add-edit-expression-modal"}
          existingExpressions={methods.fields}
          fixedResult={{
            type: expression.returns?.type,
            typeIsAutocompletable:
              resources.find((r) => r.type === expression.returns?.type)
                ?.autocompletable || false,
            typeLabel:
              resources.find((r) => r.type === expression.returns?.type)
                ?.type_label || expression.returns?.type,
            label: expression.label,
            array: expression.returns?.array,
          }}
          elseBranchRequired={elseBranchRequired}
        />
      )}
    </>
  );
};

// AddExpressionButton shows a button that lets you add expression (instead of an input) when you're not
// allowed to use static values: only expressions.
export const AddExpressionButton = ({
  scopeAndIsAlert,
  elseBranchRequired,
  fixedResult,
  onAdd,
  iconOnlyButton,
  buttonIcon = IconEnum.Expression,
}: {
  iconOnlyButton?: boolean;
  buttonIcon?: IconEnum;
  scopeAndIsAlert: ScopeAndIsAlert;
  elseBranchRequired?: boolean;
  fixedResult?: ExpressionFixedResultType;
  onAdd: (binding: Pick<Reference, "key" | "label">) => void;
}): React.ReactElement | null => {
  const [showEditModal, setShowEditModal] = useState(false);

  const expressionMethods = useExpressionsMethods<
    FormDataWithExpressions,
    "expressions"
  >();

  const { resources, resourcesLoading } = useAllResources();

  const onAddExpression = (expression: ExpressionFormData) => {
    expressionMethods.expressionsMethods?.append(expression);
    const reference = makeExpressionReference(expression);
    onAdd({
      key: reference,
      label: expression.label,
    });

    setShowEditModal(false);
  };

  if (!validateExpressionsMethods(expressionMethods)) {
    return null;
  }

  const { expressionsMethods: methods } = expressionMethods;

  if (resourcesLoading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        analyticsTrackingId={null}
        onClick={(e) => {
          e.preventDefault(); // preventing default as otherwise this seems to dismiss drawers
          setShowEditModal(true);
        }}
        icon={buttonIcon}
        size={iconOnlyButton ? BadgeSize.Medium : BadgeSize.Large}
      >
        {
          // If we're in an alert, we want to show the 'Add expression' button as a tooltip
          // because we don't have the space to show the text.
          iconOnlyButton ? null : "Add expression"
        }
      </Button>
      {showEditModal && (
        <AddEditExpressionModal
          onClose={() => setShowEditModal(false)}
          onAddExpression={onAddExpression}
          onEditExpression={() => null}
          scope={scopeAndIsAlert.scope}
          resources={resources}
          analyticsTrackingContext={"add-edit-expression-modal"}
          existingExpressions={methods.fields}
          fixedResult={fixedResult}
          elseBranchRequired={elseBranchRequired}
        />
      )}
    </>
  );
};
