/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageGenerateLogoUploadURLResponseBody
 */
export interface StatusPageGenerateLogoUploadURLResponseBody {
    /**
     * The key that should be used if this URL is used.
     * @type {string}
     * @memberof StatusPageGenerateLogoUploadURLResponseBody
     */
    key: string;
    /**
     * A pre-signed URL to view any uploaded image for up to an hour
     * @type {string}
     * @memberof StatusPageGenerateLogoUploadURLResponseBody
     */
    preview_url: string;
    /**
     * The pre-signed URL to upload the logo to. Must be used within 10 minutes.
     * @type {string}
     * @memberof StatusPageGenerateLogoUploadURLResponseBody
     */
    upload_url: string;
}

export function StatusPageGenerateLogoUploadURLResponseBodyFromJSON(json: any): StatusPageGenerateLogoUploadURLResponseBody {
    return StatusPageGenerateLogoUploadURLResponseBodyFromJSONTyped(json, false);
}

export function StatusPageGenerateLogoUploadURLResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageGenerateLogoUploadURLResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'preview_url': json['preview_url'],
        'upload_url': json['upload_url'],
    };
}

export function StatusPageGenerateLogoUploadURLResponseBodyToJSON(value?: StatusPageGenerateLogoUploadURLResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'preview_url': value.preview_url,
        'upload_url': value.upload_url,
    };
}

