import { useAPI } from "src/utils/swr";

export const useAlertResources = () => {
  const {
    data: configsResp,
    isLoading: configsLoading,
    error: configsError,
  } = useAPI("alertsListSourceConfigs", undefined);

  const {
    data: schemaResponse,
    isLoading: schemaIsLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);

  const {
    data: sourcesResponse,
    isLoading: sourcesIsLoading,
    error: sourcesError,
  } = useAPI("alertsListSources", undefined);

  const {
    data: resourcesListResp,
    isLoading: resourcesListIsLoading,
    error: resourcesListErr,
  } = useAPI("catalogListResources", undefined);

  const resourcesLoading =
    configsLoading ||
    schemaIsLoading ||
    sourcesIsLoading ||
    resourcesListIsLoading;

  const resourcesError =
    configsError || schemaError || sourcesError || resourcesListErr;

  return {
    isLoading: resourcesLoading,
    error: resourcesError,
    configsResp,
    schemaResponse,
    sourcesResponse,
    resourcesListResp,
  };
};
