/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RankUpdate,
    RankUpdateFromJSON,
    RankUpdateFromJSONTyped,
    RankUpdateToJSON,
} from './RankUpdate';

/**
 * 
 * @export
 * @interface PostIncidentStatusRank
 */
export interface PostIncidentStatusRank {
    /**
     * The ID of the incident status
     * @type {string}
     * @memberof PostIncidentStatusRank
     */
    incident_status_id: string;
    /**
     * The rank of the incident status
     * @type {number}
     * @memberof PostIncidentStatusRank
     */
    rank: number;
    /**
     * The ranks of the tasks in this status
     * @type {Array<RankUpdate>}
     * @memberof PostIncidentStatusRank
     */
    task_ranks: Array<RankUpdate>;
}

export function PostIncidentStatusRankFromJSON(json: any): PostIncidentStatusRank {
    return PostIncidentStatusRankFromJSONTyped(json, false);
}

export function PostIncidentStatusRankFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentStatusRank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_status_id': json['incident_status_id'],
        'rank': json['rank'],
        'task_ranks': ((json['task_ranks'] as Array<any>).map(RankUpdateFromJSON)),
    };
}

export function PostIncidentStatusRankToJSON(value?: PostIncidentStatusRank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_status_id': value.incident_status_id,
        'rank': value.rank,
        'task_ranks': ((value.task_ranks as Array<any>).map(RankUpdateToJSON)),
    };
}

