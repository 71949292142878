export const postmortemAnchorId = "postmortem";
export const timelineCommentAnchorPrefix = "timeline_comment";
export const timelineNoteAnchorPrefix = "timeline_note";
export const followUpAnchorPrefix = "follow_up";
export const slackChannelStorageSetting = "slack_channel_storage_setting";
export const timestampAnchorPrefix = "timestamp";
// If you add a new anchor to your component and want to scroll to it, you'll also need to
// call scrollToAnchor in a useEffect hook of your component. You can additionally make the component
// flicker yellow by copying the scss from StoreIncidentChannelMessagesForm.module.scss

export function scrollToAnchor(
  loading: boolean,
  microSecondsDelay: number,
  hasScrolledTo: string | undefined,
  setHasScrolledTo: (id: string) => void,
  setNotFound?: (notFound: boolean) => void,
  scrollBehavior: ScrollBehavior = "auto",
): string | undefined {
  const anchorId = getAnchorId();
  if (!anchorId || loading) {
    return;
  }

  // If the anchor element doesn't exist, set not found to true and return early
  const anchor = document.getElementById(anchorId);
  if (!anchor) {
    if (setNotFound) {
      setNotFound(true);
    }
    return;
  }

  // If the items we are waiting for are not loaded yet, or if we've already scrolled once to this
  // anchor, do nothing.
  if (anchorId === hasScrolledTo) {
    return;
  }

  if (anchor && isScrollableAnchor(anchorId)) {
    // We might want to delay the scroll to let all the elements on the page render in time.
    setTimeout(function () {
      anchor.scrollIntoView({ block: "center", behavior: scrollBehavior });
      setHasScrolledTo(anchorId);
    }, microSecondsDelay);
  }
}

export function getAnchorId(): string | undefined {
  const href = window.location.href;
  if (href.includes("#")) {
    // The anchorId is the part after the #.
    return `${href.substring(href.indexOf("#") + 1)}`;
  }
  return undefined;
}

// isScrollableAnchor makes sure we skips scrolling to particular anchors, for example
// those that are near the top of the page already. We'll still highlight them though.
function isScrollableAnchor(id: string): boolean {
  return id !== postmortemAnchorId;
}
