import * as ReactHoverCard from "@radix-ui/react-hover-card";
import React from "react";
import { usePortal } from "src/contexts/PortalContext";

export function HoverCard({
  trigger,
  content,
}: {
  trigger: React.ReactNode;
  content: React.ReactNode;
}) {
  const portalRef = usePortal();

  return (
    <ReactHoverCard.Root openDelay={300}>
      <ReactHoverCard.Trigger asChild>{trigger}</ReactHoverCard.Trigger>
      <ReactHoverCard.Portal container={portalRef?.current}>
        <ReactHoverCard.Content
          className="HoverCardContent"
          sideOffset={5}
          side="right"
        >
          {content}
        </ReactHoverCard.Content>
      </ReactHoverCard.Portal>
    </ReactHoverCard.Root>
  );
}
