import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import _ from "lodash";
import { useFormContext, UseFormReturn } from "react-hook-form";
import {
  Incident,
  IncidentStatus,
  PostIncidentTask,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

import {
  Category,
  firstStatusOfCategory,
  isOneOfCategories,
} from "./status-utils";
import { IncidentStatusFormData } from "./StatusFormElement";
import { StatusSelect } from "./StatusSelect";

export enum UpdateIncidentInClosedDecision {
  // only provide an update but not changing statuses
  Stay = "stay",
  // re-open the incident (make it live)
  ReOpen = "re-open",
  // opt into post-incident flow
  StartPostIncidentFlow = "start-post-incident-flow",
}

export const ClosedDecisionRadio = ({
  statuses,
  incidentTasks,
  incident,
}: {
  statuses: IncidentStatus[];
  incidentTasks: PostIncidentTask[];
  incident: Incident | null;
}) => {
  const formMethods = useFormContext<IncidentStatusFormData>();

  // If there's no incident, we're in a preview and don't want to see this.
  if (!incident) return null;

  const allTasksCompleted =
    incidentTasks.length > 0 &&
    incidentTasks.every((task) => !!task.completed_at || !!task.rejected_at);

  const allowedDecisions = allTasksCompleted
    ? [
        UpdateIncidentInClosedDecision.Stay,
        UpdateIncidentInClosedDecision.ReOpen,
      ]
    : UpdateIncidentInClosedDecision;

  const onChooseDecision = (decision: string) => {
    const newStatus = calculateStatusToTransitionTo(
      decision as UpdateIncidentInClosedDecision,
      statuses,
    );
    if (newStatus) {
      formMethods.setValue<"incident_status_id">(
        "incident_status_id",
        newStatus.id,
      );
    }
  };

  const closedStatuses = statuses.filter(isOneOfCategories([Category.Closed]));

  const hasMoreThanOneClosedStatus = closedStatuses.length > 1;

  return (
    <RadioButtonGroupV2
      label="What do you want to do?"
      srLabel="Select decision"
      formMethods={formMethods}
      name="incident_status_decision"
      boxed
      options={_.map(allowedDecisions, (decision) =>
        radioButtonOption({
          decision,
          hasMoreThanOneClosedStatus,
          formMethods,
          incident,
        }),
      )}
      onValueChange={onChooseDecision}
    />
  );
};

const radioButtonOption = ({
  decision,
  hasMoreThanOneClosedStatus,
  formMethods,
  incident,
}: {
  decision: UpdateIncidentInClosedDecision;
  hasMoreThanOneClosedStatus: boolean;
  formMethods: UseFormReturn<IncidentStatusFormData>;
  incident: Incident;
}): RadioButtonGroupOption => {
  switch (decision) {
    case UpdateIncidentInClosedDecision.Stay:
      return {
        label: "Leave the incident closed",
        value: decision,
        renderWhenSelectedNode: hasMoreThanOneClosedStatus
          ? () => (
              <StatusSelect
                formMethods={formMethods}
                incident={incident}
                name="incident_status_id"
                filterByCategories={[Category.Closed]}
                hideLabel
              />
            )
          : undefined,
      };
    case UpdateIncidentInClosedDecision.ReOpen:
      return {
        label: "Re-open: move it to an active status",
        value: decision,
        renderWhenSelectedNode: () => (
          <StatusSelect
            formMethods={formMethods}
            name="incident_status_id"
            filterByCategories={[Category.Active]}
            incident={incident}
            hideLabel
          />
        ),
      };

    case UpdateIncidentInClosedDecision.StartPostIncidentFlow:
      return {
        label: "Start the post-incident flow",
        value: decision,
      };
    default:
      assertUnreachable(decision);
  }
  // Rage at typescript.
  throw new Error("unreachable");
};

const calculateStatusToTransitionTo = (
  decision: UpdateIncidentInClosedDecision,
  statuses: IncidentStatus[],
): IncidentStatus | null => {
  switch (decision) {
    case UpdateIncidentInClosedDecision.ReOpen:
    case UpdateIncidentInClosedDecision.Stay:
      // Do nothing: the user must select a status
      return null;
    case UpdateIncidentInClosedDecision.StartPostIncidentFlow:
      return firstStatusOfCategory(statuses, Category.PostIncident);
    default:
      return assertUnreachable(decision);
  }
};
