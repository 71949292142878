import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusMerged = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <circle
      cx={8.003}
      cy={8.003}
      r={6.25}
      stroke="#5F5F68"
      strokeWidth={1.5}
      opacity={0.3}
    />
    <path
      fill="#5F5F68"
      fillRule="evenodd"
      d="M4.4 8c0-1.226.996-2.222 2.222-2.222S8.844 6.774 8.844 8a2.224 2.224 0 0 1-2.222 2.222A2.224 2.224 0 0 1 4.4 8Zm5.422 2.222c-.187 0-.369-.023-.543-.067A3.407 3.407 0 0 0 10.044 8c0-.816-.287-1.567-.765-2.155A2.224 2.224 0 0 1 12.044 8a2.224 2.224 0 0 1-2.222 2.222Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIncidentStatusMerged;
