/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentUpdate,
    IncidentUpdateFromJSON,
    IncidentUpdateFromJSONTyped,
    IncidentUpdateToJSON,
} from './IncidentUpdate';

/**
 * 
 * @export
 * @interface IncidentUpdatesUpdateResponseBody
 */
export interface IncidentUpdatesUpdateResponseBody {
    /**
     * 
     * @type {IncidentUpdate}
     * @memberof IncidentUpdatesUpdateResponseBody
     */
    incident_update: IncidentUpdate;
}

export function IncidentUpdatesUpdateResponseBodyFromJSON(json: any): IncidentUpdatesUpdateResponseBody {
    return IncidentUpdatesUpdateResponseBodyFromJSONTyped(json, false);
}

export function IncidentUpdatesUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentUpdatesUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_update': IncidentUpdateFromJSON(json['incident_update']),
    };
}

export function IncidentUpdatesUpdateResponseBodyToJSON(value?: IncidentUpdatesUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_update': IncidentUpdateToJSON(value.incident_update),
    };
}

