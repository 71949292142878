/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface IncidentFormsUpdateEscalationElementRequestBody
 */
export interface IncidentFormsUpdateEscalationElementRequestBody {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IncidentFormsUpdateEscalationElementRequestBody
     */
    default_value?: EngineParamBindingPayload;
    /**
     * Gives a description of the option to the user
     * @type {string}
     * @memberof IncidentFormsUpdateEscalationElementRequestBody
     */
    description?: string;
    /**
     * 
     * @type {ExpressionPayload}
     * @memberof IncidentFormsUpdateEscalationElementRequestBody
     */
    navigation_expression?: ExpressionPayload;
    /**
     * 
     * @type {ExpressionPayload}
     * @memberof IncidentFormsUpdateEscalationElementRequestBody
     */
    opsgenie_team_navigation_expression?: ExpressionPayload;
    /**
     * 
     * @type {ExpressionPayload}
     * @memberof IncidentFormsUpdateEscalationElementRequestBody
     */
    pagerduty_service_navigation_expression?: ExpressionPayload;
}

export function IncidentFormsUpdateEscalationElementRequestBodyFromJSON(json: any): IncidentFormsUpdateEscalationElementRequestBody {
    return IncidentFormsUpdateEscalationElementRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsUpdateEscalationElementRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsUpdateEscalationElementRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_value': !exists(json, 'default_value') ? undefined : EngineParamBindingPayloadFromJSON(json['default_value']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'navigation_expression': !exists(json, 'navigation_expression') ? undefined : ExpressionPayloadFromJSON(json['navigation_expression']),
        'opsgenie_team_navigation_expression': !exists(json, 'opsgenie_team_navigation_expression') ? undefined : ExpressionPayloadFromJSON(json['opsgenie_team_navigation_expression']),
        'pagerduty_service_navigation_expression': !exists(json, 'pagerduty_service_navigation_expression') ? undefined : ExpressionPayloadFromJSON(json['pagerduty_service_navigation_expression']),
    };
}

export function IncidentFormsUpdateEscalationElementRequestBodyToJSON(value?: IncidentFormsUpdateEscalationElementRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_value': EngineParamBindingPayloadToJSON(value.default_value),
        'description': value.description,
        'navigation_expression': ExpressionPayloadToJSON(value.navigation_expression),
        'opsgenie_team_navigation_expression': ExpressionPayloadToJSON(value.opsgenie_team_navigation_expression),
        'pagerduty_service_navigation_expression': ExpressionPayloadToJSON(value.pagerduty_service_navigation_expression),
    };
}

