/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsJiraServerSetBasicRequestBody
 */
export interface IntegrationsJiraServerSetBasicRequestBody {
    /**
     * host (including protocol and port if necessary)
     * @type {string}
     * @memberof IntegrationsJiraServerSetBasicRequestBody
     */
    host: string;
    /**
     * Password for that service account
     * @type {string}
     * @memberof IntegrationsJiraServerSetBasicRequestBody
     */
    password: string;
    /**
     * Username of an account in Jira Server
     * @type {string}
     * @memberof IntegrationsJiraServerSetBasicRequestBody
     */
    username: string;
}

export function IntegrationsJiraServerSetBasicRequestBodyFromJSON(json: any): IntegrationsJiraServerSetBasicRequestBody {
    return IntegrationsJiraServerSetBasicRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsJiraServerSetBasicRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsJiraServerSetBasicRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'host': json['host'],
        'password': json['password'],
        'username': json['username'],
    };
}

export function IntegrationsJiraServerSetBasicRequestBodyToJSON(value?: IntegrationsJiraServerSetBasicRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'host': value.host,
        'password': value.password,
        'username': value.username,
    };
}

