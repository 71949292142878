/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertsGenerateIncidentDetailsFromAlertResponseBody
 */
export interface AlertsGenerateIncidentDetailsFromAlertResponseBody {
    /**
     * The name of the incident that would be created
     * @type {string}
     * @memberof AlertsGenerateIncidentDetailsFromAlertResponseBody
     */
    generated_name?: string;
    /**
     * The summary of the incident that would be created
     * @type {string}
     * @memberof AlertsGenerateIncidentDetailsFromAlertResponseBody
     */
    generated_summary?: string;
}

export function AlertsGenerateIncidentDetailsFromAlertResponseBodyFromJSON(json: any): AlertsGenerateIncidentDetailsFromAlertResponseBody {
    return AlertsGenerateIncidentDetailsFromAlertResponseBodyFromJSONTyped(json, false);
}

export function AlertsGenerateIncidentDetailsFromAlertResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGenerateIncidentDetailsFromAlertResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generated_name': !exists(json, 'generated_name') ? undefined : json['generated_name'],
        'generated_summary': !exists(json, 'generated_summary') ? undefined : json['generated_summary'],
    };
}

export function AlertsGenerateIncidentDetailsFromAlertResponseBodyToJSON(value?: AlertsGenerateIncidentDetailsFromAlertResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generated_name': value.generated_name,
        'generated_summary': value.generated_summary,
    };
}

