import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { SettingsListItemButtonsProps } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import {
  BadgeTheme,
  ButtonTheme,
  EmptyState,
  IconEnum,
  StackedList,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import React from "react";
import { PostmortemDestination } from "src/contexts/ClientContext";
import {
  IntegrationSettingsProviderEnum,
  PostmortemsDestroyDestinationRequest,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../SettingsSubHeading";

export const PostmortemDestinationList = ({
  destinations,
}: {
  destinations: PostmortemDestination[];
}): React.ReactElement => {
  return (
    <div>
      <SettingsSubHeading
        title="Document destinations"
        titleHeadingLevel={2}
        explanation={
          <>
            Define external destinations to create your post-mortems in, such as
            Notion, Confluence, or Google Docs. When generating a post-mortem,
            you&apos;ll be able to choose which destination to export to.
          </>
        }
        accessory={
          <GatedButton
            href="/settings/post-mortem/destinations/create"
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            analyticsTrackingId="create-postmortem-destination"
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
          >
            Add destination
          </GatedButton>
        }
        className="!mb-4"
      />
      {destinations.length === 0 ? (
        <EmptyState
          icon={IconEnum.Folder}
          content="You have no destinations configured yet."
        />
      ) : (
        <StackedList>
          {_.orderBy(destinations, ["is_default", "name"], ["desc", "asc"]).map(
            (destination, index) => (
              <PostmortemDestinationRow key={index} destination={destination} />
            ),
          )}
        </StackedList>
      )}
    </div>
  );
};

const PostmortemDestinationRow = ({
  destination,
}: {
  destination: PostmortemDestination;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const { trigger: destroyDestination } = useAPIMutation(
    "postmortemsListDestinations",
    undefined,
    async (apiClient, data: PostmortemsDestroyDestinationRequest) => {
      await apiClient.postmortemsDestroyDestination(data);
    },
  );
  const config = IntegrationConfigFor(
    destination.document_provider as unknown as IntegrationSettingsProviderEnum,
  );

  const buttons: SettingsListItemButtonsProps = {
    requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
    delete: {
      resourceTitle: destination.name,
      deleteConfirmationTitle: "Delete post-mortem document destination",
      deleteConfirmationContent: (
        <Txt>
          Are you sure you want to delete the{" "}
          <Txt inline bold>
            {destination.name}
          </Txt>{" "}
          destination?
        </Txt>
      ),
      onDelete: () => destroyDestination({ id: destination.id }),
    },
    edit: {
      editHref: `/settings/post-mortem/destinations/${destination.id}/edit`,
    },
    view: {
      viewHref: `/settings/post-mortem/destinations/${destination.id}/edit`,
    },
  };

  if (hasScope(ScopeNameEnum.OrganisationSettingsUpdate)) {
    delete buttons.view;
  } else {
    delete buttons.edit;
  }

  return (
    <SettingsListItem
      title={destination.name}
      badgeProps={
        destination.is_default
          ? { theme: BadgeTheme.Tertiary, label: "Default" }
          : undefined
      }
      icon={config.icon}
      buttons={buttons}
    />
  );
};
