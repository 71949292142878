import { FormContextText } from "@incident-shared/forms/v2/helpers";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
} from "@incident-ui";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";
import { ulid } from "ulid";

import { EscalationPathTargetSelect } from "../escalation-paths/nodes/EscalationPathTargetSelect";
import { NodeCardWithoutButtons } from "../escalation-paths/nodes/NodeCard";
import { UpdateConfigFormData } from "./CallRoutingEditRoute";

export const PathEditor = ({
  formMethods,
}: {
  formMethods: UseFormReturn<UpdateConfigFormData>;
}) => {
  const { fields, insert, append, remove } = useFieldArray({
    control: formMethods.control,
    name: "levels",
  });
  const newLevel = () => ({ id: ulid(), targets: [] });

  return (
    <div
      className={tcx(
        "bg-surface-secondary bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]",
        "p-6 rounded-4",
        "flex flex-col items-center",
      )}
    >
      <div className="w-[400px] flex flex-col items-center">
        <NodeCardWithoutButtons
          title={"Trigger"}
          subtitle={"When a new call comes in"}
          icon={
            <div className="bg-green-surface rounded-2 w-10 h-10 flex items-center justify-center">
              <Icon
                id={IconEnum.IncomingPhoneCall}
                className="fill-green-content text-green-content"
              />
            </div>
          }
        />
        {fields.map((field, idx) => (
          <>
            <Divider key={`divider1-${field.id}`} />
            <AddButton
              key={`add-${field.id}`}
              onAdd={() => insert(idx, newLevel())}
            />
            <Divider key={`divider2-${field.id}`} />
            <NodeCardWithoutButtons
              key={`card-${field.id}`}
              title={`Level ${idx + 1}`}
              subtitle={"Notify"}
              icon={
                <div className="bg-alarmalade-surface rounded-2 w-10 h-10 flex items-center justify-center">
                  <Icon
                    id={IconEnum.Siren}
                    className="fill-brand text-alarmalade-content"
                  />
                </div>
              }
              onDeleteNode={fields.length > 1 ? () => remove(idx) : undefined}
            >
              <div className="mt-4 mb-2">
                <EscalationPathTargetSelect
                  formMethods={formMethods}
                  key={field.id} // Otherwise the select doesn't behave well
                  name={`levels.${idx}.targets`}
                  required="Select at least one person or schedule to call"
                />
              </div>
              <FormContextText>
                {idx === fields.length - 1 ? (
                  <>If no one on this level picks up, we will end the call.</>
                ) : (
                  <>
                    We&rsquo;ll try to call each person on this level one by one
                    for 30 seconds, then proceed to the next level.
                  </>
                )}
              </FormContextText>
            </NodeCardWithoutButtons>
          </>
        ))}
        <Divider />
        <AddButton onAdd={() => append(newLevel())} />
        <Divider />
        <Badge
          size={BadgeSize.ExtraSmall}
          theme={BadgeTheme.Tertiary}
          className="bg-slate-100"
        >
          End call
        </Badge>
      </div>
    </div>
  );
};

const Divider = () => <div className="w-px h-3 bg-slate-200" />;

const AddButton = ({ onAdd }: { onAdd: () => void }) => {
  return (
    <Button
      onClick={onAdd}
      icon={IconEnum.Add}
      analyticsTrackingId={null}
      title="Add level"
      className="p-2"
      theme={ButtonTheme.Naked}
    />
  );
};
