/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsSplunkOnCallGetBotUsernameResponseBody
 */
export interface IntegrationsSplunkOnCallGetBotUsernameResponseBody {
    /**
     * Username of the user which will create all escalations
     * @type {string}
     * @memberof IntegrationsSplunkOnCallGetBotUsernameResponseBody
     */
    bot_username?: string;
}

export function IntegrationsSplunkOnCallGetBotUsernameResponseBodyFromJSON(json: any): IntegrationsSplunkOnCallGetBotUsernameResponseBody {
    return IntegrationsSplunkOnCallGetBotUsernameResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsSplunkOnCallGetBotUsernameResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsSplunkOnCallGetBotUsernameResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bot_username': !exists(json, 'bot_username') ? undefined : json['bot_username'],
    };
}

export function IntegrationsSplunkOnCallGetBotUsernameResponseBodyToJSON(value?: IntegrationsSplunkOnCallGetBotUsernameResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bot_username': value.bot_username,
    };
}

