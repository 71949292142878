import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { useQueryParams } from "src/utils/query-params";

import { useFollowUps, useIncident, useSuggestedFollowUps } from "../hooks";
import { FollowUpsTabTable } from "./FollowUpsTabTable";

export const IncidentFollowUps = ({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement => {
  const urlParams = useQueryParams();

  const { followUps, isLoading } = useFollowUps(incidentId);
  const { suggestedFollowUps, isLoading: isLoadingSuggestions } =
    useSuggestedFollowUps(incidentId);

  const { incident } = useIncident(incidentId);

  const editingFollowUpID = urlParams.get("follow_up_id") ?? null;
  const editingFollowUp = followUps.find(
    (followUp) => followUp.id === editingFollowUpID,
  );

  if (isLoading || isLoadingSuggestions || !incident) {
    return <LoadingBar className="h-18" />;
  }

  return (
    <FollowUpsTabTable
      incident={incident}
      editingFollowUp={editingFollowUp}
      followUps={followUps}
      followUpSuggestions={suggestedFollowUps}
    />
  );
};
