import {
  CatalogType,
  CatalogTypeIconEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import {
  GatedButton,
  GatedButtonProps,
} from "@incident-shared/gates/GatedButton/GatedButton";
import { PrimaryGatedButtonWithDropdown } from "@incident-shared/gates/GatedButton/PrimaryGatedButtonWithDropdown";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPalette } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GatedDropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { ExternallyManagedTooltipContent } from "../common/SourceRepoBadge";

export type catalogHeaderButtonProps = Omit<GatedButtonProps, "children"> & {
  buttonText?: string;
  action: "sync" | "delete" | "edit" | "create" | "list-native";
};

export const TitleAccessory = ({
  icon,
  iconClasses,
}: {
  icon: CatalogTypeIconEnum;
  iconClasses: Readonly<ColorPalette>;
}) => {
  return (
    <>
      <div
        className={tcx(
          iconClasses.background,
          iconClasses.border,
          iconClasses.icon,
          "mr-1.5 rounded",
        )}
      >
        <Icon id={icon} />
      </div>
    </>
  );
};

export const CatalogListHeaderAccessory = ({
  shouldCollapse,
  buttons,
  catalogType,
  isManagedExternally,
}: {
  shouldCollapse: boolean;
  buttons: catalogHeaderButtonProps[];
  catalogType: CatalogType;
  isManagedExternally: boolean;
}) => {
  const { hasScope } = useIdentity();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <div
        className={tcx(
          "space-x-2 flex-center-y",
          shouldCollapse && "hidden sm:flex",
        )}
      >
        <div className="flex items-center gap-2">
          {buttons.map((props: catalogHeaderButtonProps) =>
            props.action === "create" ? (
              <PrimaryGatedButtonWithDropdown
                key={props.action}
                theme={ButtonTheme.Primary}
                {...props}
                disabled={
                  props.disabled ||
                  !hasScope(ScopeNameEnum.CatalogEntriesCreate)
                }
                dropdownItems={
                  <>
                    <DropdownMenuItem
                      analyticsTrackingId="bulk-create"
                      onSelect={() =>
                        navigate(
                          `/catalog/${slugForCatalogType(
                            catalogType,
                          )}/bulk-create`,
                        )
                      }
                      label="Create multiple entries"
                    />
                  </>
                }
              >
                {props.buttonText}
              </PrimaryGatedButtonWithDropdown>
            ) : (
              <GatedButton
                key={props.action}
                {...props}
                disabledTooltipContent={
                  isManagedExternally ? (
                    <ExternallyManagedTooltipContent
                      sourceRepoURL={catalogType.source_repo_url || ""}
                      lastSyncedAt={catalogType.last_synced_at}
                    />
                  ) : null
                }
              >
                {props.buttonText}
              </GatedButton>
            ),
          )}
        </div>
      </div>
      {shouldCollapse && (
        <DropdownMenu
          triggerButton={
            <Button
              theme={ButtonTheme.Secondary}
              title="more options"
              className="hover:bg-surface-secondary sm:!hidden"
              analyticsTrackingId={"catalog-type-dropdown"}
              icon={IconEnum.DotsVertical}
              iconProps={{ size: IconSize.XL }}
            />
          }
        >
          {buttons.map((props: catalogHeaderButtonProps) => (
            <GatedDropdownMenuItem
              key={props.analyticsTrackingId}
              analyticsTrackingId={props.analyticsTrackingId}
              disabled={props.disabled}
              onSelect={() => props.onClick?.()}
              label={props.buttonText ?? ""}
              explanationText={props.disabledTooltipContent as string}
            >
              {props.buttonText}
            </GatedDropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </>
  );
};
