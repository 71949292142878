/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DecisionTreeNode
 */
export interface DecisionTreeNode {
    /**
     * identifier of the node
     * @type {number}
     * @memberof DecisionTreeNode
     */
    id: number;
    /**
     * name of the node - used for the configuration UI
     * @type {string}
     * @memberof DecisionTreeNode
     */
    name: string;
    /**
     * human readable string which renders on the button which leads you to this node
     * @type {string}
     * @memberof DecisionTreeNode
     */
    option_label: string;
    /**
     * identifier of the node's parent
     * @type {number}
     * @memberof DecisionTreeNode
     */
    parent_id: number;
    /**
     * prompt to display in the slack modal
     * @type {string}
     * @memberof DecisionTreeNode
     */
    prompt: string;
    /**
     * ensure nodes are sorted as expected in the UIs
     * @type {number}
     * @memberof DecisionTreeNode
     */
    sort_order: number;
}

export function DecisionTreeNodeFromJSON(json: any): DecisionTreeNode {
    return DecisionTreeNodeFromJSONTyped(json, false);
}

export function DecisionTreeNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionTreeNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'option_label': json['option_label'],
        'parent_id': json['parent_id'],
        'prompt': json['prompt'],
        'sort_order': json['sort_order'],
    };
}

export function DecisionTreeNodeToJSON(value?: DecisionTreeNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'option_label': value.option_label,
        'parent_id': value.parent_id,
        'prompt': value.prompt,
        'sort_order': value.sort_order,
    };
}

