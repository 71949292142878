import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  EmptyState,
  Heading,
  IconEnum,
  Loader,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";

import { SettingsListItem } from "../../../components/@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../../components/settings/SettingsSubHeading";
import {
  SchedulePayConfig,
  ScopeNameEnum,
} from "../../../contexts/ClientContext";
import { useIdentity } from "../../../contexts/IdentityContext";
import { formatCurrency } from "../../../utils/currency";
import { useAPI, useAPIMutation } from "../../../utils/swr";

export const OnCallPayConfigsListRoute = (): React.ReactElement => {
  const toast = useToast();
  const { identity } = useIdentity();

  const { data, error } = useAPI("schedulesListPayConfig", undefined);
  const payConfigs = data?.schedule_pay_configs;
  if (error) {
    throw error;
  }

  const { trigger: onDelete } = useAPIMutation(
    "schedulesListPayConfig",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.schedulesDestroyPayConfig({ id });
    },
    {
      onSuccess: () => {
        toast({
          title: "Deleted config",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        toast({
          title: "Failed to delete config, please try again",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  if (!payConfigs) {
    return <Loader />;
  }

  const publishedPayConfigs = payConfigs.filter(
    (config) => config.published_at != null,
  );

  // NB: the API will only return unpublished pay configs that this user created.
  const draftPayConfigs = payConfigs.filter(
    (config) => config.published_at == null,
  );

  return (
    <>
      <SettingsSubHeading
        title="Pay configurations"
        explanation={
          <span className="text-content-tertiary">
            Pay configurations control how you pay responders for their on-call
            shifts.
          </span>
        }
        accessory={
          <GatedButton
            analyticsTrackingId="create-pay-config"
            className="!font-medium"
            href="/on-call/pay-calculator/pay-configurations/create"
            requiredScope={ScopeNameEnum.SchedulePayConfigsCreate}
            disabled={!identity.feature_gates.on_call_calculator}
            disabledTooltipContent={
              "You do not have access to create on-call pay configurations"
            }
          >
            New configuration
          </GatedButton>
        }
      />
      {payConfigs.length === 0 ? (
        <EmptyState
          icon={IconEnum.Money}
          cta={
            <GatedButton
              analyticsTrackingId="create-pay-config"
              icon={IconEnum.Add}
              className="mt-2"
              href="/on-call/pay-calculator/pay-configurations/create"
              requiredScope={ScopeNameEnum.SchedulePayConfigsCreate}
            >
              Add new
            </GatedButton>
          }
          content="You haven't created any pay configurations yet."
        />
      ) : (
        <StackedList className="mt-2">
          {publishedPayConfigs.map((config) => {
            return (
              <PayConfigListItem
                key={config.id}
                config={config}
                onDelete={() => onDelete({ id: config.id })}
                isPublished
              />
            );
          })}
        </StackedList>
      )}
      {draftPayConfigs.length > 0 ? (
        <>
          <Heading level={2} className="mb-2 mt-8">
            Draft configurations
          </Heading>
          <p className="max-w-2xl text-content-tertiary text-sm">
            Once a pay configuration is used in a published report, it&apos;ll
            be available to everyone else in your organisation to use.
          </p>
          <StackedList className="mt-4">
            {draftPayConfigs.map((config) => {
              return (
                <PayConfigListItem
                  key={config.id}
                  config={config}
                  onDelete={() => onDelete({ id: config.id })}
                  isPublished={false}
                />
              );
            })}
          </StackedList>
        </>
      ) : null}
    </>
  );
};

export const descriptionForPayConfig = (config: SchedulePayConfig): string => {
  return [
    `${formatCurrency(config.currency, config.base_rate_cents)} hourly base`,
    `${config.pay_rules.length} additional rule${
      config.pay_rules.length !== 1 ? "s" : ""
    }`,
  ].join(", ");
};

const PayConfigListItem = ({
  config,
  onDelete,
  isPublished,
}: {
  config: SchedulePayConfig;
  onDelete: () => Promise<unknown> | unknown;
  isPublished: boolean;
}) => {
  return (
    <SettingsListItem
      title={config.name}
      description={descriptionForPayConfig(config)}
      buttons={{
        edit: {
          requiredScope: isPublished
            ? ScopeNameEnum.SchedulePayConfigsUpdatePublished
            : ScopeNameEnum.SchedulePayConfigsUpdate,
          editHref: `/on-call/pay-calculator/pay-configurations/${config.id}/edit`,
        },
        delete: {
          resourceTitle: config.name,
          requiredScope: ScopeNameEnum.SchedulePayConfigsDestroy,
          onDelete,
          deleteConfirmationTitle: `Delete pay config "${config.name}"`,
          deleteConfirmationContent: (
            <>
              Are you sure you want to delete the{" "}
              <span className="font-bold">{config.name}</span> pay config?
            </>
          ),
        },
      }}
    />
  );
};
