/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GrafanaConfig,
    GrafanaConfigFromJSON,
    GrafanaConfigFromJSONTyped,
    GrafanaConfigToJSON,
} from './GrafanaConfig';

/**
 * 
 * @export
 * @interface IntegrationsGrafanaShowResponseBody
 */
export interface IntegrationsGrafanaShowResponseBody {
    /**
     * 
     * @type {GrafanaConfig}
     * @memberof IntegrationsGrafanaShowResponseBody
     */
    grafana_config?: GrafanaConfig;
}

export function IntegrationsGrafanaShowResponseBodyFromJSON(json: any): IntegrationsGrafanaShowResponseBody {
    return IntegrationsGrafanaShowResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsGrafanaShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGrafanaShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grafana_config': !exists(json, 'grafana_config') ? undefined : GrafanaConfigFromJSON(json['grafana_config']),
    };
}

export function IntegrationsGrafanaShowResponseBodyToJSON(value?: IntegrationsGrafanaShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grafana_config': GrafanaConfigToJSON(value.grafana_config),
    };
}

