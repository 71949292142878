import {
  CoreDashboard,
  CustomDashboard,
  InsightsDateRangeAggregationEnum as DateAggregation,
} from "@incident-io/api";
import {
  AvailableFilter,
  FiltersContextProvider,
} from "@incident-shared/filters";
import {
  isValidForEnum,
  useStatefulQueryParamFilters,
} from "@incident-shared/filters/useStatefulQueryParamFilters";
import { Form } from "@incident-shared/forms";
import {
  DateRangePickerMode,
  DateRangePickerState,
  dateRangePickerStateFromString,
  dateRangePickerStateToString,
  isValidDateRangePickerState,
  QuickSelectInterval,
} from "@incident-shared/forms/v1/DateRangePicker";
import React from "react";
import { useForm } from "react-hook-form";

import { dateRangeToFormState, panelToFormData } from "../common/marshall";
import {
  DashboardViewMode,
  EditDashboardFormData,
  InsightsContext,
  InsightsDateRangeState,
} from "../common/useInsightsContext";

enum InsightsParam {
  DateRange = "date_range",
  DateAggregation = "date_aggregation",
  ComparePreviousPeriod = "compare_previous_period",
}

const DEFAULT_INSIGHTS_DATE_RANGE_PICKER_STATE: InsightsDateRangeState = {
  range: {
    mode: DateRangePickerMode.QuickSelect,
    quick_select: QuickSelectInterval.Last3Months,
  } as DateRangePickerState,
  aggregation: DateAggregation.Weeks,
  is_comparison: false,
};

// InsightsShowDashboardProvider gives the contents of a workflow form access to the various mutations
// and view state that it needs to avoid prop-drilling hell.
export const InsightsShowDashboardProvider = ({
  filterFields,
  dashboard,
  children,
}: {
  filterFields: AvailableFilter[];
  dashboard: CoreDashboard | CustomDashboard;
  children: React.ReactNode;
}): JSX.Element => {
  const defaultFilters =
    "default_filters" in dashboard ? dashboard.default_filters : undefined;

  const { getSelectedFilters, setSelectedFilters, useQueryParam } =
    useStatefulQueryParamFilters<{
      [InsightsParam.DateRange]: string;
      [InsightsParam.DateAggregation]: DateAggregation;
      [InsightsParam.ComparePreviousPeriod]: string;
    }>({
      availableFilterFields: filterFields,
      availableParams: [...Object.values(InsightsParam)],
      defaultFilters,
    });

  let dateRangeState = DEFAULT_INSIGHTS_DATE_RANGE_PICKER_STATE;

  if ((dashboard as CustomDashboard).date_range) {
    dateRangeState = dateRangeToFormState(
      (dashboard as CustomDashboard).date_range,
    );
  }

  const [dateRangeStr, setDateRangeStr] = useQueryParam({
    param: InsightsParam.DateRange,
    isValid: (val: string | null) =>
      val != null &&
      isValidDateRangePickerState(dateRangePickerStateFromString(val)),
    defaultValue: dateRangePickerStateToString(dateRangeState.range),
  });

  const range = dateRangePickerStateFromString(dateRangeStr);
  const setRange = (newValue: DateRangePickerState) =>
    setDateRangeStr(dateRangePickerStateToString(newValue));

  const [comparePreviousStr, setComparePreviousStr] = useQueryParam({
    param: InsightsParam.ComparePreviousPeriod,
    isValid: (val: string | null) => val === "true",
    defaultValue: dateRangeState.is_comparison ? "true" : "false",
  });

  const isComparison = comparePreviousStr === "true";
  const setComparePreviousPeriod = (newValue: boolean) =>
    setComparePreviousStr(newValue ? "true" : "");

  const [aggregation, setAggregation] = useQueryParam({
    param: InsightsParam.DateAggregation,
    isValid: isValidForEnum(Object.values(DateAggregation)),
    defaultValue: dateRangeState.aggregation,
  });

  const filters = getSelectedFilters();

  const setDateRange = (newRange: Partial<InsightsDateRangeState>) => {
    // Always set range, then aggregation, then comparison.
    if (newRange.range) {
      setRange(newRange.range);
    }
    if (newRange.aggregation) {
      setAggregation(newRange.aggregation);
    }
    if (newRange.is_comparison !== undefined) {
      setComparePreviousPeriod(newRange.is_comparison);
    }
  };

  const formMethods = useForm<Pick<EditDashboardFormData, "panels">>({
    defaultValues: {
      panels: dashboard.panels.map(panelToFormData),
    },
  });

  return (
    <InsightsContext.Provider
      value={{
        dateRange: {
          range: range,
          aggregation,
          is_comparison: isComparison,
        },
        setDateRange,
        viewMode: DashboardViewMode.EditFiltersAndVariables,
      }}
    >
      <FiltersContextProvider
        filters={filters}
        setFilters={setSelectedFilters}
        availableFilterFields={filterFields}
        kind="insights"
        filtersLoading={false}
      >
        <Form.Root fullHeight formMethods={formMethods} onSubmit={() => null}>
          {children}
        </Form.Root>
      </FiltersContextProvider>
    </InsightsContext.Provider>
  );
};
