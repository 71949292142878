/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeSchemaPayload,
    CatalogTypeSchemaPayloadFromJSON,
    CatalogTypeSchemaPayloadFromJSONTyped,
    CatalogTypeSchemaPayloadToJSON,
} from './CatalogTypeSchemaPayload';

/**
 * 
 * @export
 * @interface CatalogCreateTypeRequestBody
 */
export interface CatalogCreateTypeRequestBody {
    /**
     * Annotations that can track metadata about this type
     * @type {{ [key: string]: string; }}
     * @memberof CatalogCreateTypeRequestBody
     */
    annotations?: { [key: string]: string; };
    /**
     * What categories is this type considered part of
     * @type {Array<string>}
     * @memberof CatalogCreateTypeRequestBody
     */
    categories?: Array<CatalogCreateTypeRequestBodyCategoriesEnum>;
    /**
     * Sets the display color of this type in the dashboard
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    color?: CatalogCreateTypeRequestBodyColorEnum;
    /**
     * Human readble description of this type
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    description: string;
    /**
     * If this is a dynamic catalog type, this will be the unique parameter for identitfying this resource externally.
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    dynamic_resource_parameter?: string;
    /**
     * Sets the display icon of this type in the dashboard
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    icon?: CatalogCreateTypeRequestBodyIconEnum;
    /**
     * When this type was last synced (if it's ever been sync'd)
     * @type {Date}
     * @memberof CatalogCreateTypeRequestBody
     */
    last_synced_at?: Date;
    /**
     * Name is the human readable name of this type
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    name: string;
    /**
     * If this type should be ranked
     * @type {boolean}
     * @memberof CatalogCreateTypeRequestBody
     */
    ranked?: boolean;
    /**
     * The registry resource this type is synced from, if any
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    registry_type?: string;
    /**
     * 
     * @type {CatalogTypeSchemaPayload}
     * @memberof CatalogCreateTypeRequestBody
     */
    schema?: CatalogTypeSchemaPayload;
    /**
     * The url of the external repository where this type is managed
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    source_repo_url?: string;
    /**
     * The type name of this catalog type, to be used when defining attributes. This is immutable once a CatalogType has been created. For non-externally sync types, it must follow the pattern Custom["SomeName "]
     * @type {string}
     * @memberof CatalogCreateTypeRequestBody
     */
    type_name?: string;
    /**
     * If populated, the name of the entry will be added to the list of identifiers for this type
     * @type {boolean}
     * @memberof CatalogCreateTypeRequestBody
     */
    use_name_as_identifier: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CatalogCreateTypeRequestBodyCategoriesEnum {
    Customer = 'customer',
    IssueTracker = 'issue-tracker',
    ProductFeature = 'product-feature',
    Service = 'service',
    OnCall = 'on-call',
    Team = 'team',
    User = 'user'
}/**
* @export
* @enum {string}
*/
export enum CatalogCreateTypeRequestBodyColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum CatalogCreateTypeRequestBodyIconEnum {
    Alert = 'alert',
    Bolt = 'bolt',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Clock = 'clock',
    Cog = 'cog',
    Components = 'components',
    Database = 'database',
    Doc = 'doc',
    Email = 'email',
    EscalationPath = 'escalation-path',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    Globe = 'globe',
    Money = 'money',
    Server = 'server',
    Severity = 'severity',
    StatusPage = 'status-page',
    Store = 'store',
    Star = 'star',
    Tag = 'tag',
    User = 'user',
    Users = 'users'
}

export function CatalogCreateTypeRequestBodyFromJSON(json: any): CatalogCreateTypeRequestBody {
    return CatalogCreateTypeRequestBodyFromJSONTyped(json, false);
}

export function CatalogCreateTypeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogCreateTypeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'description': json['description'],
        'dynamic_resource_parameter': !exists(json, 'dynamic_resource_parameter') ? undefined : json['dynamic_resource_parameter'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'last_synced_at': !exists(json, 'last_synced_at') ? undefined : (new Date(json['last_synced_at'])),
        'name': json['name'],
        'ranked': !exists(json, 'ranked') ? undefined : json['ranked'],
        'registry_type': !exists(json, 'registry_type') ? undefined : json['registry_type'],
        'schema': !exists(json, 'schema') ? undefined : CatalogTypeSchemaPayloadFromJSON(json['schema']),
        'source_repo_url': !exists(json, 'source_repo_url') ? undefined : json['source_repo_url'],
        'type_name': !exists(json, 'type_name') ? undefined : json['type_name'],
        'use_name_as_identifier': json['use_name_as_identifier'],
    };
}

export function CatalogCreateTypeRequestBodyToJSON(value?: CatalogCreateTypeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'categories': value.categories,
        'color': value.color,
        'description': value.description,
        'dynamic_resource_parameter': value.dynamic_resource_parameter,
        'icon': value.icon,
        'last_synced_at': value.last_synced_at === undefined ? undefined : (value.last_synced_at.toISOString()),
        'name': value.name,
        'ranked': value.ranked,
        'registry_type': value.registry_type,
        'schema': CatalogTypeSchemaPayloadToJSON(value.schema),
        'source_repo_url': value.source_repo_url,
        'type_name': value.type_name,
        'use_name_as_identifier': value.use_name_as_identifier,
    };
}

