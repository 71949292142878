import { Loader } from "@incident-ui";
import { SCIMShowSettingsResponseBody } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { ScimShowPageInner } from "./ScimShowPageInner";

export const resolveScimSetupStep = (
  scimConfigState: SCIMShowSettingsResponseBody,
): ScimSetupStep => {
  let step: ScimSetupStep;

  if (!scimConfigState.enabled) {
    step = ScimSetupStep.Uninstalled;
  } else if (scimConfigState.scim_config?.directory_state !== "linked") {
    step = ScimSetupStep.PartiallyInstalled;
  } else if (!scimConfigState.scim_config?.has_completed_initial_role_mapping) {
    step = ScimSetupStep.NeedsOwnerAssignment;
  } else {
    step = ScimSetupStep.Installed;
  }

  return step;
};

export enum ScimSetupStep {
  // The org has set everything up, their roles have been linked to an owner at least once
  Installed = "installed",
  // The org has connected SCIM, but not yet assigned an owner group
  NeedsOwnerAssignment = "needs_owner_assignment",
  // The org has started the installation flow, but not completed it with their IdP
  PartiallyInstalled = "partially_installed",
  // The org has not started installation, or has uninstalled
  Uninstalled = "uninstalled", // The org
}

export const isScimEnabled = (
  scimConfigState: SCIMShowSettingsResponseBody,
): boolean =>
  scimConfigState.enabled &&
  scimConfigState.scim_config?.directory_state === "linked" &&
  scimConfigState.scim_config?.has_completed_initial_role_mapping;

export const ScimShowPage = () => {
  const { data: scimConfigState } = useAPI("sCIMShowSettings", undefined);

  const { data: rolesResp } = useAPI("rBACRolesList", undefined);

  if (!scimConfigState || !rolesResp) {
    return <Loader />;
  }

  const step: ScimSetupStep = resolveScimSetupStep(scimConfigState);

  return (
    <ScimShowPageInner
      step={step}
      roles={rolesResp.rbac_roles}
      scimConfigState={scimConfigState}
    />
  );
};
