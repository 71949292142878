/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentsUpdateModeRequestBody
 */
export interface IncidentsUpdateModeRequestBody {
    /**
     * Whether the incident is real, a test, or a tutorial
     * @type {string}
     * @memberof IncidentsUpdateModeRequestBody
     */
    mode: IncidentsUpdateModeRequestBodyModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsUpdateModeRequestBodyModeEnum {
    Standard = 'standard',
    Retrospective = 'retrospective',
    Test = 'test',
    Tutorial = 'tutorial'
}

export function IncidentsUpdateModeRequestBodyFromJSON(json: any): IncidentsUpdateModeRequestBody {
    return IncidentsUpdateModeRequestBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateModeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateModeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mode': json['mode'],
    };
}

export function IncidentsUpdateModeRequestBodyToJSON(value?: IncidentsUpdateModeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mode': value.mode,
    };
}

