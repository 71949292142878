import { Button, ButtonTheme } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

const CTA_ID = "year-in-review-2024-banner";

export const useShowYearInReviewBanner = () => {
  const { incidentIoWrapped } = useFlags();
  const { hasDismissedCTA } = useIdentity();

  // TODO: we should add a flag on `identity` indicating whether each user has wrapped data
  return incidentIoWrapped && !hasDismissedCTA(CTA_ID);
};

export const YearInReviewBanner = () => {
  const { trigger: dismissBanner, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: CTA_ID,
          for_whole_organisation: false,
        },
      });
    },
  );

  // Emit a tracking event that we're showing this banner
  const analytics = useAnalytics();
  useEffect(() => {
    if (analytics) {
      analytics.track("year-in-review-banner-shown");
    }
  }, [analytics]);

  if (dismissing) {
    return null;
  }

  return (
    <div
      className={tcx(
        "flex w-full items-stretch justify-between gap-4 rounded-2 shadow-sm bg-[linear-gradient(66deg,_#F25533_20%,_#C084FC_100%)]",
        "flex-col md:flex-row relative",
      )}
    >
      <div
        className={tcx(
          "flex flex-col grow justify-between p-6 gap-4",
          // On small screens this is below the animation. On larger screens it's on the left, and leaves 250px on the right for the animation
          "order-2 md:order-1 md:max-w-[calc(100%-250px)]",
        )}
      >
        <div>
          <div className="text-base-bold text-white mb-1">
            Your year in review is ready!
          </div>
          <div className="text-white">
            You&rsquo;ve had an amazing year fighting incidents with
            incident.io. Let&rsquo;s look at your highlights.
          </div>
        </div>
        <div className="flex gap-4">
          <Button
            href="/wrapped"
            theme={ButtonTheme.Tertiary}
            analyticsTrackingId={"wrapped-from-home"}
            className="transition"
          >
            Find out
          </Button>
          <Button
            onClick={() => dismissBanner({})}
            theme={ButtonTheme.Naked}
            analyticsTrackingId={"dismiss-year-in-review"}
            className="text-white transition"
          >
            See it later
          </Button>
        </div>
      </div>
      <Lottie
        animationData={require("./banner-lottie.json")}
        loop={false}
        autoplay
        className={tcx(
          // Never let this change size, it looks sad when shrunk
          // It should also always be on the right of the banner
          "order-1 flex-none h-[152px] w-[375px] self-end",
          // On small screens this is flex-positioned.
          // On larger screens it's absolutely positioned to the right, in the middle.
          // Its height is 152px, so the `top` is to center it vertically within the parent.
          "md:absolute md:right-0 md:top-[calc(50%-76px)]",
        )}
      />
    </div>
  );
};
