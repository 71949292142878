import type { SVGProps } from "react";
import * as React from "react";
const SvgExport = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.25 8.5h-3V14a.75.75 0 0 1-1.5 0V8.5h-3A2.752 2.752 0 0 0 3 11.25v5A2.752 2.752 0 0 0 5.75 19h7.5A2.752 2.752 0 0 0 16 16.25v-5a2.752 2.752 0 0 0-2.75-2.75ZM7.03 6.78l1.72-1.72V8.5h1.5V5.061l1.72 1.72a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.061l-3-3a.75.75 0 0 0-1.061 0l-3 3A.75.75 0 1 0 7.03 6.78Z"
    />
  </svg>
);
export default SvgExport;
