import { ExternalUser } from "@incident-io/api";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { TableCell, TableRow } from "@incident-ui/Table/Table";
import { useClipboard } from "src/utils/useClipboard";

import { ExternalUserNameAndEmail } from "./ExternalUserNameAndEmail";
import { RemoveConnectionButton } from "./RemoveConnectionButton";

export const ExternalUserRow = ({
  user,
  editable,
  linkID,
  isLastRow,
}: {
  user: ExternalUser;
  editable: boolean;
  linkID: string;
  isLastRow: boolean;
}) => {
  return (
    <TableRow isLastRow={isLastRow}>
      <ExternalUserRowComponents
        user={user}
        editable={editable}
        linkID={linkID}
      />
    </TableRow>
  );
};

export const ExternalUserRowComponents = ({
  user,
  editable,
  linkID,
  withoutRemoveButton,
}: {
  user: ExternalUser;
  editable: boolean;
  linkID: string;
  withoutRemoveButton?: boolean;
}) => {
  const { hasCopied, copyTextToClipboard } = useClipboard();

  const integration = IntegrationConfigFor(user.provider);

  return (
    <>
      <TableCell>
        <Icon id={integration.icon} size={IconSize.Medium} />
      </TableCell>
      <ExternalUserNameAndEmail user={user} />
      <TableCell className="overflow-hidden">
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Unstyled}
          className="flex justify-start gap-1 max-w-[150px] group"
          onClick={() => copyTextToClipboard(user.external_id)}
        >
          <Icon
            id={hasCopied ? IconEnum.Success : IconEnum.Copy}
            className="shrink-0 text-content-secondary group-hover:text-content-primary"
          />
          <div className="grow truncate font-mono">{user.external_id}</div>
        </Button>
      </TableCell>
      {!withoutRemoveButton && (
        <TableCell>
          {editable && <RemoveConnectionButton userLinkID={linkID} />}
        </TableCell>
      )}
    </>
  );
};
