import { tcx } from "src/utils/tailwind-classes";
import validator from "validator";

import { AttributeExternalLink } from "./AttributeExternalLink";
import { arrayValueAttribute } from "./utils";

export const PrimitiveEntryList = ({
  attributeBinding,
  className,
}: {
  attributeBinding: arrayValueAttribute;
  className?: string;
}) => (
  <div className={tcx("flex-center-y whitespace-pre", className)}>
    {attributeBinding.array_value
      .map<React.ReactNode>((value, i) => {
        const label = value.label || value.literal;
        if (validator.isURL(label ?? "")) {
          return (
            <AttributeExternalLink key={`label-${i}`} label={label || ""} />
          );
        } else {
          return <span key={`label-${i}`}>{label}</span>;
        }
      })
      .reduce((prev, cur) => [prev, ", ", cur])}
  </div>
);
