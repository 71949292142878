/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementPostActionParam,
    AnnouncementPostActionParamFromJSON,
    AnnouncementPostActionParamFromJSONTyped,
    AnnouncementPostActionParamToJSON,
} from './AnnouncementPostActionParam';

/**
 * 
 * @export
 * @interface AnnouncementPostsListActionsResponseBody
 */
export interface AnnouncementPostsListActionsResponseBody {
    /**
     * Actions configured on this announcement post
     * @type {Array<AnnouncementPostActionParam>}
     * @memberof AnnouncementPostsListActionsResponseBody
     */
    post_actions: Array<AnnouncementPostActionParam>;
}

export function AnnouncementPostsListActionsResponseBodyFromJSON(json: any): AnnouncementPostsListActionsResponseBody {
    return AnnouncementPostsListActionsResponseBodyFromJSONTyped(json, false);
}

export function AnnouncementPostsListActionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostsListActionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'post_actions': ((json['post_actions'] as Array<any>).map(AnnouncementPostActionParamFromJSON)),
    };
}

export function AnnouncementPostsListActionsResponseBodyToJSON(value?: AnnouncementPostsListActionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'post_actions': ((value.post_actions as Array<any>).map(AnnouncementPostActionParamToJSON)),
    };
}

