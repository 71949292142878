import { Icon, IconEnum, IconSize } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export enum AssistantAvatarBackground {
  Purple = "purple",
  None = "none",
}

// make a dict of AssistantAvatarBackground to colors
const backgroundToColor = {
  [AssistantAvatarBackground.Purple]: "bg-purple-surface",
  [AssistantAvatarBackground.None]: "",
};

// AssistantAvatar is a simple circle icon for Assistant.
export const AssistantAvatar = ({
  size,
  className,
  iconClassName,
  background = AssistantAvatarBackground.None,
  rounded = true,
}: {
  size: IconSize;
  className?: string;
  iconClassName?: string;
  background?: AssistantAvatarBackground;
  rounded?: boolean;
}) => {
  return (
    <div
      className={tcx(
        backgroundToColor[background],
        "p-2",
        rounded ? "rounded-full" : "rounded-1",
        className,
      )}
    >
      <Icon
        id={IconEnum.SparklesColoured}
        size={size}
        className={tcx(iconClassName)}
      />
    </div>
  );
};
