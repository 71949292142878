import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
} from "@incident-ui";
import { SlackTeamConfig } from "src/contexts/ClientContext";

export type PresetOption = {
  type: "all_workspaces" | "my_workspaces";
};
export type SingleWorkspaceOption = {
  type: "single_workspace";
  slack_team_id: string;
};

export const FilterByWorkspaceDropdown = ({
  disabled,
  selectedOption,
  mySlackTeamIDs,
  slackTeamConfigs,
  onSelectSlackTeam,
}: {
  disabled?: boolean;
  slackTeamConfigs: SlackTeamConfig[];
  mySlackTeamIDs: string[];
  selectedOption: PresetOption | SingleWorkspaceOption;
  onSelectSlackTeam: (
    newSelectedOption: PresetOption | SingleWorkspaceOption,
  ) => void;
}) => {
  const selectedSlackTeam =
    selectedOption.type === "single_workspace"
      ? slackTeamConfigs.find(
          (config) => config.slack_team_id === selectedOption.slack_team_id,
        )
      : undefined;
  return (
    <div className="flex flex-center-y space-x-2">
      <p className="text-sm">Showing incidents for</p>
      <DropdownMenu
        disabled={disabled}
        triggerButton={
          <Button
            theme={ButtonTheme.Secondary}
            size={BadgeSize.Medium}
            analyticsTrackingId="incident-slack-team-dropdown-trigger"
            disabled={disabled}
          >
            {selectedSlackTeam ? (
              <>
                {selectedSlackTeam.icon_url ? (
                  <img
                    src={selectedSlackTeam.icon_url}
                    className="w-4 h-4 rounded-0.5 mr-1.5 border border-stroke"
                  />
                ) : (
                  <Icon
                    id={IconEnum.SlackTeam}
                    size={IconSize.Small}
                    className="mr-1"
                  />
                )}
                <p className="text-sm">{selectedSlackTeam.name}</p>
              </>
            ) : (
              <p className="text-sm">
                {selectedOption.type === "all_workspaces" && "All workspaces"}
                {selectedOption.type === "my_workspaces" && "My workspaces"}
              </p>
            )}
            <Icon id={IconEnum.ChevronDown} />
          </Button>
        }
      >
        <DropdownMenuItem
          key="All"
          disabled={disabled}
          onSelect={() => onSelectSlackTeam({ type: "all_workspaces" })}
          analyticsTrackingId={`incident-slack-team-dropdown-all`}
          label="All workspaces"
        >
          <Icon
            id={IconEnum.SlackTeam}
            size={IconSize.Medium}
            className="mr-1.5 text-slate-600"
          />
          <span>All workspaces</span>
        </DropdownMenuItem>
        {mySlackTeamIDs.length > 0 && (
          <DropdownMenuItem
            key="My"
            disabled={disabled}
            onSelect={() => onSelectSlackTeam({ type: "my_workspaces" })}
            analyticsTrackingId={`incident-slack-team-dropdown-my`}
            label="My workspaces"
          >
            <Icon
              id={IconEnum.SlackTeam}
              size={IconSize.Medium}
              className="mr-1.5 text-slate-600"
            />
            <span>My workspaces</span>
          </DropdownMenuItem>
        )}
        {slackTeamConfigs.map((team) => (
          <DropdownMenuItem
            key={team.slack_team_id}
            disabled={disabled}
            onSelect={() =>
              onSelectSlackTeam({
                type: "single_workspace",
                slack_team_id: team.slack_team_id,
              })
            }
            analyticsTrackingId="incident-slack-team-dropdown-item"
            analyticsTrackingMetadata={{
              slackTeamID: team.slack_team_id,
            }}
            label={team.name}
          >
            <SlackTeamAvatar
              size={IconSize.Small}
              url={team.icon_url}
              name={team.name}
              className=" mr-2 ml-0.5"
            />
            <span>{team.name}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </div>
  );
};
