import { EngineScope } from "@incident-io/api";
import { Resource } from "@incident-io/api/models/Resource";
import { CreateEditExpressionFormData } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { Loader } from "@incident-ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { mergeScopes } from "src/utils/scope";

import { useGetScope } from "../ComponentForOperation";
import { ReferenceWithResource } from "../useGetPreviousReference";

export const FilterConditionEdit = ({
  previousRef,
  operationIdx,
  isEditable,
  scope: fullScope,
}: {
  previousRef: ReferenceWithResource;
  operationIdx: number;
  isEditable: boolean;
  scope: EngineScope;
  resources: Array<Resource>;
}): React.ReactElement => {
  const formMethods = useFormContext<CreateEditExpressionFormData>();

  const { scope: inputScope, isLoading } = useGetScope({
    ...previousRef,
    // Filter expressions have access to each element, even though the input
    // type is an array of things.
    array: false,
  });

  const scope = mergeScopes(fullScope, inputScope);

  return isLoading ? (
    <Loader />
  ) : (
    <ConditionGroupsEditorV2
      formMethods={formMethods}
      name={`operations.${operationIdx}.filter.condition_groups`}
      scope={scope}
      hideIntroSentence
      disabled={!isEditable}
    />
  );
};
