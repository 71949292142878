/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionOperationPayload,
    ExpressionOperationPayloadFromJSON,
    ExpressionOperationPayloadFromJSONTyped,
    ExpressionOperationPayloadToJSON,
} from './ExpressionOperationPayload';
import {
    ReferencePayload,
    ReferencePayloadFromJSON,
    ReferencePayloadFromJSONTyped,
    ReferencePayloadToJSON,
} from './ReferencePayload';

/**
 * 
 * @export
 * @interface EngineEvaluateExpressionRequestBody
 */
export interface EngineEvaluateExpressionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof EngineEvaluateExpressionRequestBody
     */
    catalog_type_id?: string;
    /**
     * 
     * @type {Array<ExpressionOperationPayload>}
     * @memberof EngineEvaluateExpressionRequestBody
     */
    operations: Array<ExpressionOperationPayload>;
    /**
     * The root reference for this expression (i.e. where the expression starts)
     * @type {string}
     * @memberof EngineEvaluateExpressionRequestBody
     */
    root_reference: string;
    /**
     * Scope values for anything used in the expression
     * @type {Array<ReferencePayload>}
     * @memberof EngineEvaluateExpressionRequestBody
     */
    scope: Array<ReferencePayload>;
}

export function EngineEvaluateExpressionRequestBodyFromJSON(json: any): EngineEvaluateExpressionRequestBody {
    return EngineEvaluateExpressionRequestBodyFromJSONTyped(json, false);
}

export function EngineEvaluateExpressionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineEvaluateExpressionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_id': !exists(json, 'catalog_type_id') ? undefined : json['catalog_type_id'],
        'operations': ((json['operations'] as Array<any>).map(ExpressionOperationPayloadFromJSON)),
        'root_reference': json['root_reference'],
        'scope': ((json['scope'] as Array<any>).map(ReferencePayloadFromJSON)),
    };
}

export function EngineEvaluateExpressionRequestBodyToJSON(value?: EngineEvaluateExpressionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_id': value.catalog_type_id,
        'operations': ((value.operations as Array<any>).map(ExpressionOperationPayloadToJSON)),
        'root_reference': value.root_reference,
        'scope': ((value.scope as Array<any>).map(ReferencePayloadToJSON)),
    };
}

