/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldTypeInfo,
    CustomFieldTypeInfoFromJSON,
    CustomFieldTypeInfoFromJSONTyped,
    CustomFieldTypeInfoToJSON,
} from './CustomFieldTypeInfo';
import {
    CustomFieldValue,
    CustomFieldValueFromJSON,
    CustomFieldValueFromJSONTyped,
    CustomFieldValueToJSON,
} from './CustomFieldValue';

/**
 * 
 * @export
 * @interface CustomFieldEntry
 */
export interface CustomFieldEntry {
    /**
     * 
     * @type {CustomFieldTypeInfo}
     * @memberof CustomFieldEntry
     */
    custom_field: CustomFieldTypeInfo;
    /**
     * List of custom field values set on this entry
     * @type {Array<CustomFieldValue>}
     * @memberof CustomFieldEntry
     */
    values: Array<CustomFieldValue>;
}

export function CustomFieldEntryFromJSON(json: any): CustomFieldEntry {
    return CustomFieldEntryFromJSONTyped(json, false);
}

export function CustomFieldEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field': CustomFieldTypeInfoFromJSON(json['custom_field']),
        'values': ((json['values'] as Array<any>).map(CustomFieldValueFromJSON)),
    };
}

export function CustomFieldEntryToJSON(value?: CustomFieldEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field': CustomFieldTypeInfoToJSON(value.custom_field),
        'values': ((value.values as Array<any>).map(CustomFieldValueToJSON)),
    };
}

