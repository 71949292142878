/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationsRespondEscalationsAllRequestBody
 */
export interface EscalationsRespondEscalationsAllRequestBody {
    /**
     * Whether we're responding with an ack or nack
     * @type {string}
     * @memberof EscalationsRespondEscalationsAllRequestBody
     */
    response: EscalationsRespondEscalationsAllRequestBodyResponseEnum;
    /**
     * Where we are responding from
     * @type {string}
     * @memberof EscalationsRespondEscalationsAllRequestBody
     */
    source: EscalationsRespondEscalationsAllRequestBodySourceEnum;
    /**
     * ID of a user to bulk update escalations for. We'll only update escalations that this user is a target of.
     * @type {string}
     * @memberof EscalationsRespondEscalationsAllRequestBody
     */
    user_id: string;
}

/**
* @export
* @enum {string}
*/
export enum EscalationsRespondEscalationsAllRequestBodyResponseEnum {
    Ack = 'ack',
    Nack = 'nack'
}/**
* @export
* @enum {string}
*/
export enum EscalationsRespondEscalationsAllRequestBodySourceEnum {
    Slack = 'slack',
    MicrosoftTeams = 'microsoft_teams',
    Email = 'email',
    App = 'app',
    Sms = 'sms',
    Phone = 'phone',
    SlackChannel = 'slack_channel',
    Dashboard = 'dashboard',
    IncidentSlackChannel = 'incident_slack_channel',
    DashboardViaEmail = 'dashboard-via-email'
}

export function EscalationsRespondEscalationsAllRequestBodyFromJSON(json: any): EscalationsRespondEscalationsAllRequestBody {
    return EscalationsRespondEscalationsAllRequestBodyFromJSONTyped(json, false);
}

export function EscalationsRespondEscalationsAllRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsRespondEscalationsAllRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': json['response'],
        'source': json['source'],
        'user_id': json['user_id'],
    };
}

export function EscalationsRespondEscalationsAllRequestBodyToJSON(value?: EscalationsRespondEscalationsAllRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': value.response,
        'source': value.source,
        'user_id': value.user_id,
    };
}

