import {
  CatalogEntryReference,
  CatalogType,
  ExternalUser,
} from "@incident-io/api";
import { NonPrimitiveEntry } from "@incident-shared/attribute";
import { TableCell, TableRow } from "@incident-ui/Table/Table";
import { ExternalUserRowComponents } from "src/components/user-preferences/connected-accounts/ExternalUserRow";
import { RemoveConnectionButton } from "src/components/user-preferences/connected-accounts/RemoveConnectionButton";

export const ConnectedAccountRow = ({
  linkID,
  externalUser,
  userID,
  catalogEntry,
  catalogType,
  editable,
  isLastRow,
}: {
  linkID: string;
  externalUser: ExternalUser;
  userID: string;
  catalogEntry: CatalogEntryReference;
  catalogType: CatalogType;
  editable: boolean;
  isLastRow: boolean;
}) => {
  const hasCatalogInfo = catalogEntry && catalogType;
  return (
    <TableRow isLastRow={isLastRow}>
      <ExternalUserRowComponents
        user={externalUser}
        editable={editable}
        linkID={linkID}
        withoutRemoveButton
      />
      <TableCell className="h-16 overflow-hidden">
        {hasCatalogInfo && (
          <NonPrimitiveEntry
            icon={catalogType.icon}
            color={catalogType.color}
            key={catalogEntry.catalog_entry_id}
            value={{
              label: catalogEntry.catalog_entry_name,
              sort_key: "",
              catalog_entry: catalogEntry,
            }}
            className="border border-stroke hover:border-stroke-hover"
          />
        )}
      </TableCell>
      <TableCell>
        {editable && (
          <RemoveConnectionButton userLinkID={linkID} userIDToLink={userID} />
        )}
      </TableCell>
    </TableRow>
  );
};
