import type { SVGProps } from "react";
import * as React from "react";
const SvgTextStrikethrough = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.76 9.25c-.223-.05-.442-.091-.648-.128-2.997-.535-3.135-1.961-3.18-2.429-.067-.697.091-1.245.485-1.679C8.146 4.212 9.478 4 10.08 4c1.455 0 2.465.645 3.003 1.918a.751.751 0 0 0 1.382-.584C13.423 2.868 11.27 2.5 10.08 2.5c-.246 0-2.443.043-3.772 1.506-.676.743-.976 1.722-.869 2.831A3.58 3.58 0 0 0 6.67 9.25H3a.75.75 0 0 0 0 1.5h14a.75.75 0 0 0 0-1.5h-6.24Zm-.68 8.25c-3.268 0-4.496-1.776-4.951-3.267a.75.75 0 1 1 1.435-.437C7.023 15.3 8.141 16 10.081 16c1.061 0 2.023-.363 2.642-.997a2.346 2.346 0 0 0 .682-1.73 2.404 2.404 0 0 0-.115-.7.75.75 0 0 1 1.423-.475c.117.352.182.735.192 1.139a3.813 3.813 0 0 1-1.108 2.813c-.899.92-2.253 1.449-3.715 1.449h-.002Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTextStrikethrough;
