import type { SVGProps } from "react";
import * as React from "react";
const SvgFolderNoPadding = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.5 8.75H2v-4C2 3.785 2.785 3 3.75 3h3.797c.505 0 .986.218 1.318.599l2.324 2.657-1.129.987-2.325-2.658a.248.248 0 0 0-.188-.085H3.75a.25.25 0 0 0-.25.25v4Z"
    />
    <path
      fill="currentColor"
      d="M15.25 17H4.75A2.752 2.752 0 0 1 2 14.25v-5.5A2.752 2.752 0 0 1 4.75 6h10.5A2.752 2.752 0 0 1 18 8.75v5.5A2.752 2.752 0 0 1 15.25 17Z"
    />
  </svg>
);
export default SvgFolderNoPadding;
