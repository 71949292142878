import type { SVGProps } from "react";
import * as React from "react";
const SvgDownload = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 8h-4v4.939l1.72-1.72a.75.75 0 1 1 1.061 1.061l-3 3a.748.748 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.061-1.061l1.72 1.72V8H4.75A2.752 2.752 0 0 0 2 10.75v5a2.752 2.752 0 0 0 2.75 2.75h9.5A2.752 2.752 0 0 0 17 15.75v-5A2.752 2.752 0 0 0 14.25 8ZM10.25 8V3.25a.75.75 0 0 0-1.5 0V8h1.5Z"
    />
  </svg>
);
export default SvgDownload;
