import {
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  CatalogTypeColorEnum,
  CatalogTypeIconEnum,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
} from "@incident-ui";
import React from "react";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "src/components/@shared/utils/ColorPalettes";
import { useAllResources } from "src/hooks/useResources";
import { tcx } from "src/utils/tailwind-classes";

// Presents a badge for a given catalog type, picking the appropriate color and
// icon by loading all resources.
export const CatalogTypeBadge = ({
  type,
  size,
  className,
}: {
  type: string;
  size?: CatalogEntryBadgeProps["size"];
  className?: string;
}) => {
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  if (resourcesLoading) {
    return <Loader />;
  }

  const matchingResource = resources.find((r) => r.type === type);
  if (!matchingResource) {
    return <GenericErrorMessage description="Cannot display type" />;
  }

  return (
    <CatalogEntryBadge
      color={
        matchingResource.field_config?.color as unknown as ColorPaletteEnum
      }
      size={size}
      icon={matchingResource.field_config.icon || IconEnum.Box}
      label={matchingResource.type_label}
      className={className}
    />
  );
};

export type CatalogEntryBadgeProps = {
  color?: CatalogResourceColorEnum | CatalogTypeColorEnum | ColorPaletteEnum;
  icon?: CatalogResourceIconEnum | CatalogTypeIconEnum | IconEnum;
  label?: string;
  labelNode?: React.ReactNode;
  className?: string;
  suffix?: React.ReactNode;
  size?: BadgeSize.Small | BadgeSize.Medium;
  groupIcon?: boolean;
  withTooltip?: boolean;
  clickable?: boolean;
  // Used when we just want to use the badge without the catalog type name
  iconOnly?: boolean;
};

export const CatalogEntryBadge = React.forwardRef<
  HTMLDivElement,
  CatalogEntryBadgeProps
>(
  (
    {
      color,
      icon,
      label,
      labelNode,
      className,
      suffix,
      size = BadgeSize.Medium,
      groupIcon,
      iconOnly,
      withTooltip,
      clickable,
    }: CatalogEntryBadgeProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { background, text, hoverBg } = getColorPalette(color);

    return (
      <Tooltip
        content={withTooltip ? <>{label}</> : undefined}
        disabled={!withTooltip}
      >
        <Badge
          ref={ref}
          className={tcx(
            background,
            text,
            clickable && hoverBg,
            clickable && "cursor-pointer",
            // We want to let the badge set a fixed width if it's icon only, so it's always a square.
            !iconOnly && "w-fit max-w-[300px]",
            "transition truncate",
            "font-medium",
            className,
          )}
          size={size}
          theme={BadgeTheme.Unstyled}
          icon={icon}
        >
          {!iconOnly && (
            <>
              {labelNode ? (
                labelNode
              ) : (
                <div className={tcx("min-w-0 truncate")}>{label}</div>
              )}
              {suffix && <span className="whitespace-pre">{suffix}</span>}
              {groupIcon && (
                <Icon
                  id={IconEnum.ChevronRight}
                  size={
                    size === BadgeSize.Medium ? IconSize.Medium : IconSize.Small
                  }
                />
              )}
            </>
          )}
        </Badge>
      </Tooltip>
    );
  },
);

CatalogEntryBadge.displayName = "CatalogEntryBadge";
