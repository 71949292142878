import { Dispatch, SetStateAction } from "react";
import {
  IncidentDurationMetric,
  IncidentTimestamp,
} from "src/contexts/ClientContext";

import { DurationMetricsPageSection } from "./DurationMetricsSection";
import { IncidentTimestampsSection } from "./IncidentTimestampsSection";

export const TimestampsAndMetricsTab = ({
  metrics,
  timestamps,
  setShowAddTimestampModal,
  showAddTimestampModal,
}: {
  metrics: IncidentDurationMetric[];
  showAddTimestampModal: string | boolean;
  setShowAddTimestampModal: Dispatch<SetStateAction<string | boolean>>;
  timestamps: IncidentTimestamp[];
}) => {
  return (
    <div className={"space-y-8"}>
      <IncidentTimestampsSection
        metrics={metrics}
        showAddTimestampModal={showAddTimestampModal}
        setShowAddTimestampModal={setShowAddTimestampModal}
      />
      {/* Duration Metrics */}
      <DurationMetricsPageSection metrics={metrics} timestamps={timestamps} />
    </div>
  );
};
