import { DateTime } from "luxon";
import { useState } from "react";
import { useInterval } from "src/utils/utils";

// Provide a DateTime now value that updates every 10s.
export const useNow = (timezone?: string, override?: DateTime): DateTime => {
  const [now, setNow] = useState<DateTime>(
    override ?? DateTime.now().setZone(timezone),
  );
  useInterval(() => {
    if (!override) {
      setNow(DateTime.now().setZone(timezone));
    }
  }, 10 * 1000); // every 10s

  return now;
};
