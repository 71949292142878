import { GenericErrorMessage, Loader } from "@incident-ui";
import React from "react";
import {
  IncidentLifecycle,
  IncidentsBuildScopeContextEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAPI } from "src/utils/swr";

import { PostIncidentFlowExpressionEditor } from "./PostIncidentFlowExpressionEditor";

export const PostIncidentFlowSection = ({
  lifecycle,
}: {
  lifecycle: IncidentLifecycle;
}): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.PostIncidentFlow,
  );

  const {
    data: { post_incident_flows: flows },
    isLoading: flowsLoading,
    error: flowsError,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  if (!scope || scopeLoading || flowsLoading) {
    return <Loader />;
  }

  if (scopeError) {
    return <GenericErrorMessage error={scopeError} />;
  }
  if (flowsError) {
    return <GenericErrorMessage error={flowsError} />;
  }

  return (
    <PostIncidentFlowExpressionEditor
      canEditSettings={canEditSettings}
      lifecycle={lifecycle}
      scope={scope}
      flows={flows}
    />
  );
};
