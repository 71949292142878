/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface DebriefSettings
 */
export interface DebriefSettings {
    /**
     * The google calendar ID we should prefill the event with, when should_use_calendar_id is true
     * @type {string}
     * @memberof DebriefSettings
     */
    calendar_id?: string;
    /**
     * The org's own name for a debrief
     * @type {string}
     * @memberof DebriefSettings
     */
    debrief_rename?: string;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof DebriefSettings
     */
    duration: EngineParamBinding;
    /**
     * The evaluated duration (in minutes) of a debrief for a specific incident
     * @type {number}
     * @memberof DebriefSettings
     */
    evaluated_duration?: number;
    /**
     * 
     * @type {TextNode}
     * @memberof DebriefSettings
     */
    event_description: TextNode;
    /**
     * 
     * @type {TextNode}
     * @memberof DebriefSettings
     */
    event_title: TextNode;
    /**
     * Whether or not all debrief invite rules will be ignored for test and tutorial incidents.
     * @type {boolean}
     * @memberof DebriefSettings
     */
    exclude_test_or_tutorial_from_invites: boolean;
    /**
     * Expressions available for use in debrief settings
     * @type {Array<Expression>}
     * @memberof DebriefSettings
     */
    expressions?: Array<Expression>;
    /**
     * Whether or not we should prefill a debrief event with a particular calendar ID
     * @type {boolean}
     * @memberof DebriefSettings
     */
    should_use_calendar_id: boolean;
}

export function DebriefSettingsFromJSON(json: any): DebriefSettings {
    return DebriefSettingsFromJSONTyped(json, false);
}

export function DebriefSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendar_id': !exists(json, 'calendar_id') ? undefined : json['calendar_id'],
        'debrief_rename': !exists(json, 'debrief_rename') ? undefined : json['debrief_rename'],
        'duration': EngineParamBindingFromJSON(json['duration']),
        'evaluated_duration': !exists(json, 'evaluated_duration') ? undefined : json['evaluated_duration'],
        'event_description': TextNodeFromJSON(json['event_description']),
        'event_title': TextNodeFromJSON(json['event_title']),
        'exclude_test_or_tutorial_from_invites': json['exclude_test_or_tutorial_from_invites'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'should_use_calendar_id': json['should_use_calendar_id'],
    };
}

export function DebriefSettingsToJSON(value?: DebriefSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendar_id': value.calendar_id,
        'debrief_rename': value.debrief_rename,
        'duration': EngineParamBindingToJSON(value.duration),
        'evaluated_duration': value.evaluated_duration,
        'event_description': TextNodeToJSON(value.event_description),
        'event_title': TextNodeToJSON(value.event_title),
        'exclude_test_or_tutorial_from_invites': value.exclude_test_or_tutorial_from_invites,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'should_use_calendar_id': value.should_use_calendar_id,
    };
}

