/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DependentResource,
    DependentResourceFromJSON,
    DependentResourceFromJSONTyped,
    DependentResourceToJSON,
} from './DependentResource';

/**
 * 
 * @export
 * @interface StatusPageComponentDependentResource
 */
export interface StatusPageComponentDependentResource {
    /**
     * 
     * @type {DependentResource}
     * @memberof StatusPageComponentDependentResource
     */
    dependent_resource: DependentResource;
    /**
     * ID of the status page component which is depended on by this resource
     * @type {string}
     * @memberof StatusPageComponentDependentResource
     */
    status_page_component_id: string;
}

export function StatusPageComponentDependentResourceFromJSON(json: any): StatusPageComponentDependentResource {
    return StatusPageComponentDependentResourceFromJSONTyped(json, false);
}

export function StatusPageComponentDependentResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageComponentDependentResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dependent_resource': DependentResourceFromJSON(json['dependent_resource']),
        'status_page_component_id': json['status_page_component_id'],
    };
}

export function StatusPageComponentDependentResourceToJSON(value?: StatusPageComponentDependentResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dependent_resource': DependentResourceToJSON(value.dependent_resource),
        'status_page_component_id': value.status_page_component_id,
    };
}

