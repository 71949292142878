import { Button, ButtonTheme, IconEnum, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useAPIMutation } from "src/utils/swr";

export const RemoveConnectionButton = ({
  userLinkID,
  userIDToLink,
}: {
  userLinkID: string;
  userIDToLink?: string;
}): React.ReactElement | null => {
  const showToast = useToast();

  const { trigger: onSubmit, isMutating } = useAPIMutation(
    userIDToLink ? "userLinksListUserLinks" : "userLinksListSelf",
    userIDToLink ? { userId: userIDToLink as string } : undefined,
    async (apiClient, _) => {
      if (userIDToLink) {
        await apiClient.userLinksDeleteUserLink({
          userLinkId: userLinkID,
        });
        return;
      } else {
        await apiClient.userLinksDeleteSelf({
          userLinkId: userLinkID,
        });
        return;
      }
    },
    {
      onSuccess: () => {
        showToast({
          title: "Removed connected account.",
          theme: ToastTheme.Success,
        });
      },
    },
  );

  return (
    <>
      <Button
        theme={ButtonTheme.Naked}
        loading={isMutating}
        onClick={() => onSubmit({ user_link_id: userLinkID })}
        analyticsTrackingId={
          userIDToLink
            ? "userlinks-disconnect-user"
            : "userlinks-disconnect-self"
        }
        title="Remove connection"
        icon={IconEnum.LinkBreak}
      />
    </>
  );
};
