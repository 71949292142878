/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIConfig,
    AIConfigFromJSON,
    AIConfigFromJSONTyped,
    AIConfigToJSON,
} from './AIConfig';

/**
 * 
 * @export
 * @interface AIShowConfigResponseBody
 */
export interface AIShowConfigResponseBody {
    /**
     * 
     * @type {AIConfig}
     * @memberof AIShowConfigResponseBody
     */
    config: AIConfig;
}

export function AIShowConfigResponseBodyFromJSON(json: any): AIShowConfigResponseBody {
    return AIShowConfigResponseBodyFromJSONTyped(json, false);
}

export function AIShowConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIShowConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': AIConfigFromJSON(json['config']),
    };
}

export function AIShowConfigResponseBodyToJSON(value?: AIShowConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': AIConfigToJSON(value.config),
    };
}

