import { createContext, useContext } from "react";

export interface PreviewComponentProps {
  urlParams: URLSearchParams;
  viewParams: URLSearchParams | undefined;
}

interface ISavedViewStatePreviewContext {
  PreviewComponent:
    | ((props: PreviewComponentProps) => React.ReactElement)
    | null;
}

export const SavedViewStatePreviewContext =
  createContext<ISavedViewStatePreviewContext>({
    PreviewComponent: null,
  });

export const useSavedViewStatePreview = () => {
  return useContext<ISavedViewStatePreviewContext>(
    SavedViewStatePreviewContext,
  );
};
