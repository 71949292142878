import type { SVGProps } from "react";
import * as React from "react";
const SvgAlert = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.276 4.931A2.655 2.655 0 0 1 4.93 2.276h10.138a2.655 2.655 0 0 1 2.655 2.655v7.241a2.655 2.655 0 0 1-2.655 2.656h-2.206l-2.318 2.649a.725.725 0 0 1-1.09 0l-2.318-2.65H4.93a2.655 2.655 0 0 1-2.655-2.655V4.931Zm6.974.819a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-1.5 0v-3.5Zm.75 7.069a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAlert;
