/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Condition,
    ConditionFromJSON,
    ConditionFromJSONTyped,
    ConditionToJSON,
} from './Condition';

/**
 * 
 * @export
 * @interface ConditionGroup
 */
export interface ConditionGroup {
    /**
     * All conditions in this list must be satisfied for the group to be satisfied
     * @type {Array<Condition>}
     * @memberof ConditionGroup
     */
    conditions: Array<Condition>;
}

export function ConditionGroupFromJSON(json: any): ConditionGroup {
    return ConditionGroupFromJSONTyped(json, false);
}

export function ConditionGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': ((json['conditions'] as Array<any>).map(ConditionFromJSON)),
    };
}

export function ConditionGroupToJSON(value?: ConditionGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': ((value.conditions as Array<any>).map(ConditionToJSON)),
    };
}

