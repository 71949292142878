import { Link } from "@incident-ui";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceUptimeSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Log in to Uptime.com and navigate to{" "}
        <Link
          analyticsTrackingId={"uptime-alert-source.click-externally"}
          to={"https://uptime.com/integrations/manage/"}
          className={"font-medium hover:cursor-pointer"}
          openInNewTab
        >
          Notifications: Integrations
        </Link>
        .
      </p>
      <p>
        Click <span className={"font-medium"}>New Profile</span> and select{" "}
        <span className={"font-medium"}>Custom Postback URL (Webhook)</span>.
      </p>
      <div className={"flex flex-col gap-4"}>
        <p>Give the check a name of your choosing, like incident.io</p>
      </div>
      <div className={"flex flex-col gap-4"}>
        <p>
          Assign the Integration to a contact or create a new contact for
          incident.io
        </p>
      </div>
      <SetupInfoSingleLineBlocks
        blocks={[
          {
            label: <>Set the URL to be the following:</>,
            code: `${publicApiUrl()}/v2/alert_events/uptimecom/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <SetupInfoSingleLineBlocks
        blocks={[
          {
            label: (
              <> Add an Authorization header in the Custom HTTP headers box.</>
            ),
            code: `Authorization: Bearer ${alertSourceConfig.secret_token}`,
          },
        ]}
      />
      <p>
        Click <span className={"font-medium"}>Save</span> and click{" "}
        <span className={"font-medium"}>Start Test</span> in the modal that
        appears.
      </p>
      <p>
        In your Uptime.com Monitoring, edit the check(s) for which you want to
        receive alerts and assign them the Integration contact you selected so
        you can start receiving real alerts.
      </p>
    </SetupInfoNumberedList>
  );
};
