/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationPathRoundRobinConfig
 */
export interface EscalationPathRoundRobinConfig {
    /**
     * Whether round robin is enabled for this level
     * @type {boolean}
     * @memberof EscalationPathRoundRobinConfig
     */
    enabled: boolean;
    /**
     * How long should we wait before rotating to the next target in a round robin, if not set will stick with a single target per level.
     * @type {number}
     * @memberof EscalationPathRoundRobinConfig
     */
    rotate_after_seconds?: number;
}

export function EscalationPathRoundRobinConfigFromJSON(json: any): EscalationPathRoundRobinConfig {
    return EscalationPathRoundRobinConfigFromJSONTyped(json, false);
}

export function EscalationPathRoundRobinConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathRoundRobinConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'rotate_after_seconds': !exists(json, 'rotate_after_seconds') ? undefined : json['rotate_after_seconds'],
    };
}

export function EscalationPathRoundRobinConfigToJSON(value?: EscalationPathRoundRobinConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'rotate_after_seconds': value.rotate_after_seconds,
    };
}

