import {
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  FormElements,
  marshallFormElementDataToIncidentForm,
  marshallIncidentResponseToFormElementData,
  marshallIncidentRolesToFormData,
  marshallTextDocumentPayload,
  UpdateFormData,
  useElementBindings,
} from "@incident-shared/incident-forms";
import { LoadingModal, ModalFooter } from "@incident-ui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AvailableIncidentFormLifecycleElementElementTypeEnum as ElementTypeEnum,
  CustomField,
  CustomFieldEntry,
  Incident,
  IncidentAlertStateEnum,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
  IncidentRole,
  IncidentsCreateUpdateRequestBodySummarySourceEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentTimestamp,
  ScopeNameEnum,
  Stream,
} from "src/contexts/ClientContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { isTriage } from "../../../../utils/presenters";
import { useRevalidate } from "../../../../utils/use-revalidate";
import {
  getCustomFieldErrorPath,
  isCustomFieldError,
} from "../ActiveIncidentCreateModal";
import { IncidentAcceptModal } from "../IncidentAcceptModal";
import { ActiveIncidentDecision } from "../statuses/ActiveIncidentDecisionRadio";
import { UpdateIncidentInClosedDecision } from "../statuses/ClosedDecisionRadio";
import { PostIncidentFlowDecision } from "../statuses/PostIncidentDecisionRadio";
import { Category } from "../statuses/status-utils";
import { StatusFormElementDecision } from "../statuses/StatusFormElement";
import {
  calculateStatusToTransitionTo,
  TriageDecision,
} from "../statuses/TriageDecisionRadio";
import { OpenStreamsWarning } from "../streams/OpenStreamsWarning";
import {
  IncidentCrudResourceTypes,
  useIncidentCrudResources,
  useStatusesForIncident,
} from "../useIncidentCrudResources";
import { OptOutOfPostIncidentFlowModal } from "./OptOutOfPostIncidentFlowModal";
import {
  doPendingAlertsRequireAction,
  PendingAlertsBlockerWarning,
} from "./PendingAlertsNeedingUpdateModal";
import { IncidentResolveForm } from "./ResolveIncidentModal";

export const UpdateIncidentModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}) => {
  const { loading, ...resources } = useIncidentCrudResources();
  const { statusesLoading, statuses } = useStatusesForIncident({ incident });
  const {
    data: { streams },
    isLoading: streamsLoading,
  } = useAPI(
    "streamsList",
    { parentId: incident.id },
    { fallbackData: { streams: [] } },
  );
  const openStreams = streams.filter(
    (stream) => stream.status.category === IncidentStatusCategoryEnum.Active,
  );

  if (loading || statusesLoading || streamsLoading) {
    return <LoadingModal title={"Share update"} onClose={onClose} />;
  }

  return (
    <IncidentUpdateWrapper
      onClose={onClose}
      incident={incident}
      statuses={statuses}
      resources={resources}
      openStreams={openStreams}
    />
  );
};

export enum IncidentUpdateFormStep {
  Updating = "updating",
  Accepting = "accepting",
  OptOutOfPostIncident = "opt-out-of-post-incident",
  Resolving = "resolving",
}

// IncidentUpdateWrapper controls whether we're showing you the update form, or an
// accept / resolve form.
const IncidentUpdateWrapper = ({
  onClose,
  incident,
  statuses,
  resources,
  openStreams,
}: {
  onClose: () => void;
  incident: Incident;
  statuses: IncidentStatus[];
  resources: IncidentCrudResourceTypes;
  openStreams: Stream[];
}): React.ReactElement => {
  const [formStep, setFormStep] = useState(IncidentUpdateFormStep.Updating);
  const [bufferedUpdate, setBufferedUpdate] = useState<UpdateFormData>();

  if (formStep === IncidentUpdateFormStep.Accepting) {
    return <IncidentAcceptModal onClose={onClose} incident={incident} />;
  }

  if (formStep === IncidentUpdateFormStep.Resolving) {
    return (
      <IncidentResolveForm
        {...resources}
        bufferedUpdate={bufferedUpdate}
        onClose={onClose}
        incident={incident}
        statuses={statuses}
      />
    );
  }

  if (formStep === IncidentUpdateFormStep.OptOutOfPostIncident) {
    return (
      <OptOutOfPostIncidentFlowModal onClose={onClose} incident={incident} />
    );
  }
  return (
    <IncidentUpdateForm
      onClose={onClose}
      incident={incident}
      statuses={statuses}
      resources={resources}
      setBufferedUpdate={setBufferedUpdate}
      setFormStep={setFormStep}
      openStreams={openStreams}
    />
  );
};

const IncidentUpdateForm = ({
  onClose,
  incident,
  statuses,
  resources,
  setBufferedUpdate,
  setFormStep,
  openStreams,
}: {
  onClose: () => void;
  incident: Incident;
  statuses: IncidentStatus[];
  resources: IncidentCrudResourceTypes;
  setBufferedUpdate: (update: UpdateFormData) => void;
  setFormStep: (step: IncidentUpdateFormStep) => void;
  openStreams: Stream[];
}): React.ReactElement => {
  const decisionUrlParam = useQueryParams().get("decision");
  const { customFields, incidentRoles, incidentTimestamps } = resources;

  const convertIncidentResponseToFormData = (
    responseBody: Incident,
    ourCustomFields: CustomField[],
    ourIncidentRoles: IncidentRole[],
    ourTimestamps: IncidentTimestamp[],
  ): UpdateFormData => {
    const elementFormData = marshallIncidentResponseToFormElementData(
      ourCustomFields,
      ourTimestamps,
      ourIncidentRoles,
      responseBody,
    );

    let defaultDecision: StatusFormElementDecision = "none";
    let defaultDecisionStatusID: string | undefined;
    if (incident.incident_status.category === Category.Triage) {
      // We allow triage decisions to be set via a URL parameter, which is what
      // powers "accept" and "decline" deeplinks in Microsoft Teams.
      defaultDecision =
        (decisionUrlParam && urlParamTriageDecisionMapping[decisionUrlParam]) ||
        TriageDecision.Accept;
      defaultDecisionStatusID = calculateStatusToTransitionTo(
        defaultDecision as TriageDecision,
        statuses,
      ).id;
    } else if (incident.incident_status.category === Category.PostIncident) {
      defaultDecision = PostIncidentFlowDecision.Leave;
    } else if (incident.incident_status.category === Category.Closed) {
      defaultDecision = UpdateIncidentInClosedDecision.Stay;
    } else if (incident.incident_status.category === Category.Active) {
      defaultDecision = ActiveIncidentDecision.Stay;
    } else if (incident.incident_status.category === Category.Paused) {
      defaultDecision = ActiveIncidentDecision.Stay;
    }

    return {
      ...elementFormData,
      severity_id: incident.severity?.id,
      incident_status_id:
        defaultDecisionStatusID ?? incident.incident_status.id,
      merged_into_incident_id: undefined,
      incident_status_decision: defaultDecision,
    };
  };

  const defaultValues = convertIncidentResponseToFormData(
    incident,
    customFields,
    incidentRoles,
    incidentTimestamps,
  );

  const formMethods = useForm<UpdateFormData>({
    defaultValues,
  });

  const { watch, setError, setValue, getValues } = formMethods;

  const [isDirty, setIsDirty] = useState(false);
  const [
    selectedSeverityID,
    selectedStatusID,
    selectedCustomFieldEntries,
    selectedIncidentRoleAssignments,
    currentMessage,
    selectedStatusDecision,
  ] = watch([
    "severity_id",
    "incident_status_id",
    "custom_field_entries",
    "incident_role_assignments",
    "message",
    "incident_status_decision",
  ]);

  const { data: listAlertsResponse, isLoading: isLoadingPendingAlerts } =
    useAPI("alertsListIncidentAlerts", { incidentId: incident.id }, {});
  const incidentAlerts = listAlertsResponse?.incident_alerts ?? [];
  const pendingAlerts = incidentAlerts.filter(
    (i) => i.state === IncidentAlertStateEnum.Pending,
  );
  const alertsRequireAction = doPendingAlertsRequireAction({
    pendingAlerts,
    selectedStatusID,
    statuses: statuses,
  });

  // This is a bit gross, but there's a few different possible values and empty
  // states for different custom fields and we need to account for all of them
  // if we want to know if the form has changed
  const compareCustomFields = (
    inc_fields: CustomFieldEntry[],
    form_fields: FormCustomFieldEntries,
  ) => {
    let dirty = false;
    Object.entries(form_fields).forEach(([fieldId, { values }]) => {
      const inc_field = inc_fields.find((x) => x.custom_field.id === fieldId);
      if (
        !_.isEqual(values, inc_field?.values) &&
        values?.some((x) => {
          if (typeof x === "object") {
            if (x == null) {
              return false;
            }
            return x.value_text || x.value_link || x.value_numeric;
          } else {
            return x;
          }
        })
      ) {
        dirty = true;
      }
    });
    return dirty;
  };

  useEffect(
    () => {
      // If it's triage, then it's always dirty
      if (isTriage(incident.incident_status)) {
        setIsDirty(true);
        return;
      }

      // If it is live, check if the form has changed
      setIsDirty(
        selectedSeverityID !== incident.severity?.id ||
          selectedStatusID !== incident.incident_status.id ||
          currentMessage !== undefined ||
          compareCustomFields(
            incident.custom_field_entries,
            selectedCustomFieldEntries,
          ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedSeverityID,
      selectedStatusID,
      currentMessage,
      selectedCustomFieldEntries,
      // Hack: react can't tell when selectedCustomFieldEntries has changed
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(selectedCustomFieldEntries),
      incident,
    ],
  );

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const refreshIncidentActivity = useRevalidate([
    "incidentTimelineListActivityLog",
  ]);
  const refetchTimeline = useAPIRefetch("incidentTimelineListTimelineItems", {
    incidentId: incident.id,
    timezone: localTimezone,
  });
  const refetchUpdates = useRevalidate(["incidentTimelineListActivityLog"]);
  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });

  const customFieldEntries = marshallCustomFieldEntriesToRequestPayload(
    customFields,
    formMethods.formState.touchedFields,
    selectedCustomFieldEntries,
  );
  const incidentRoleAssignments = marshallIncidentRolesToFormData({
    incidentRoles,
    formAssignments: selectedIncidentRoleAssignments,
  });

  const elementBindingsPayload = {
    incident_form_type: IncidentFormType.Update,
    incident_type_id: incident.incident_type?.id,
    incident_status_id: selectedStatusID as string,
    severity_id: selectedSeverityID,
    custom_field_entries: customFieldEntries,
    incident_role_assignments: incidentRoleAssignments,
    show_all_elements_override: false,
    incident_id: incident.id,
  };

  const { elementBindings, isLoading: isLoadingElementBindings } =
    useElementBindings<UpdateFormData>({
      payload: elementBindingsPayload,
      setValue,
      initialValues: formMethods.formState.defaultValues,
      touchedFields: formMethods.formState.touchedFields,
      manualEdits: incident.manual_edits,
    });

  const {
    trigger: createUpdate,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: UpdateFormData) => {
      const formPayload = marshallFormElementDataToIncidentForm(
        elementBindings,
        formData,
        formMethods.formState.touchedFields,
      );

      await apiClient.incidentsCreateUpdate({
        incidentId: incident.id,
        createUpdateRequestBody: {
          ...formData,
          ...formPayload,
          message: marshallTextDocumentPayload(formData.message),
          summary_source:
            IncidentsCreateUpdateRequestBodySummarySourceEnum.Human,
          to_severity_id: formData.severity_id,
          to_incident_status_id: formData.incident_status_id,
        },
      });

      // Ideally we would wait for post-incident tasks to load before closing the
      // modal, as we go straight to them when entering the post-incident flow.
      // However, reloading the incident is in general slower than loading
      // the post-incident tasks, and that we _do_ wait for, so the chance of the
      // post-incident tasks not loading is quite low.
      refetchPostIncidentTasks();

      // These have changed, but can be background reloaded.
      refetchTimeline();
      refetchUpdates();
      refreshIncidentList();

      // The incident activity log is written to async, so we need a small artificial delay
      // before refreshing it.
      setTimeout(() => {
        refreshIncidentActivity();
      }, 250);

      // The hook will reload the incident for us, since we return nothing here.
      return;
    },
    {
      onSuccess: onClose,
      setError: (path, error) => {
        if (!isCustomFieldError(path)) {
          setError(path, error);
          return;
        }

        const formPayload = marshallFormElementDataToIncidentForm(
          elementBindings,
          getValues(),
          formMethods.formState.touchedFields,
        );
        setError(
          getCustomFieldErrorPath<UpdateFormData>(
            path,
            formPayload.custom_field_entries ?? [],
          ),
          error,
        );
      },
    },
  );

  const onSubmit = (formData: UpdateFormData) => {
    if (formData.next_update_in_minutes) {
      formData.next_update_in_minutes = parseInt(
        formData.next_update_in_minutes.toString(),
      );
    }

    if (formData.incident_status_decision === ActiveIncidentDecision.Paused) {
      // Set to incident status to paused
      const pausedStatus = statuses.find((s) => s.category === Category.Paused);
      if (!pausedStatus) {
        throw new Error("No paused status found");
      }
      formData.incident_status_id = pausedStatus.id;

      if (formData.unpause_in_minutes) {
        if ((formData.unpause_in_minutes as unknown) === "custom") {
          const until = formData.unpause_as_date || new Date();
          const now = new Date();
          const untilInMinutes =
            (until.getTime() - now.getTime()) / (1000 * 60);

          formData.unpause_in_minutes = Math.ceil(untilInMinutes);
        } else {
          formData.unpause_in_minutes = parseInt(
            formData.unpause_in_minutes.toString(),
          );
        }
      }
    }

    if (formData.incident_status_decision === ActiveIncidentDecision.Merged) {
      const mergedStatus = statuses.find((s) => s.category === Category.Merged);
      if (!mergedStatus) {
        throw new Error("No merged status found");
      }
      formData.incident_status_id = mergedStatus.id;
    }

    if (formData.incident_status_decision === TriageDecision.Accept) {
      setFormStep(IncidentUpdateFormStep.Accepting);
    } else if (
      formData.incident_status_decision === PostIncidentFlowDecision.OptOut
    ) {
      setFormStep(IncidentUpdateFormStep.OptOutOfPostIncident);
    } else if (
      formData.incident_status_decision === ActiveIncidentDecision.Resolved
    ) {
      // Save state and proceed to next form
      setBufferedUpdate(formData);
      setFormStep(IncidentUpdateFormStep.Resolving);
    } else {
      createUpdate(formData);
    }
  };

  const hasChosenAccept = selectedStatusDecision === TriageDecision.Accept;
  const hasChosenOptOut =
    selectedStatusDecision === PostIncidentFlowDecision.OptOut;
  const hasChosenResolve =
    selectedStatusDecision === ActiveIncidentDecision.Resolved;
  const hasChosenPause =
    selectedStatusDecision === ActiveIncidentDecision.Paused;
  const hasChosenMerge =
    selectedStatusDecision === ActiveIncidentDecision.Merged;
  const willShowAnotherModal =
    hasChosenAccept || hasChosenOptOut || hasChosenResolve || hasChosenMerge;

  const openStreamsBlockResolving = openStreams.length > 0 && hasChosenResolve;

  // Special case: if you're about to go to another modal, tidy up the form and just push
  // you straight to the next part!
  let filteredElementBindings = elementBindings;
  if (willShowAnotherModal) {
    filteredElementBindings = elementBindings.filter(
      ({ element }) =>
        element.available_element.element_type === ElementTypeEnum.Status ||
        // Additionally, when resolving we want the update message input.
        (hasChosenResolve &&
          element.available_element.element_type ===
            ElementTypeEnum.UpdateMessage),
    );
  }

  // Special case: if you're merging, declining, or pausing the incident, hide everything except
  // status and message
  if (
    selectedStatusDecision === TriageDecision.Decline ||
    selectedStatusDecision === TriageDecision.Merge ||
    selectedStatusDecision === ActiveIncidentDecision.Paused ||
    selectedStatusDecision === ActiveIncidentDecision.Merged
  ) {
    filteredElementBindings = elementBindings.filter((x) => {
      const elementType = x.element.available_element.element_type;
      return (
        elementType === ElementTypeEnum.Status ||
        elementType === ElementTypeEnum.UpdateMessage
      );
    });
  }

  return (
    <Form.Modal<UpdateFormData>
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={"Share update"}
      analyticsTrackingId="update-incident"
      disableQuickClose
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          analyticsTrackingId="update-incident-submit"
          saving={saving}
          confirmButtonType="submit"
          disabled={
            !isDirty ||
            alertsRequireAction ||
            isLoadingPendingAlerts ||
            isLoadingElementBindings ||
            openStreamsBlockResolving
          }
          requiredScope={ScopeNameEnum.IncidentsUpdate}
          confirmButtonText={willShowAnotherModal ? "Next" : "Share update"}
        />
      }
      genericError={saving ? undefined : genericError}
    >
      <PendingAlertsBlockerWarning
        alertsRequireAction={alertsRequireAction}
        pendingAlerts={pendingAlerts}
        incident={incident}
      />
      <FormElements<UpdateFormData>
        elementBindings={filteredElementBindings}
        incident={incident}
        selectedIncidentType={incident.incident_type}
        formMethods={formMethods}
        incidentFormType={IncidentFormType.Update}
        customFieldEntryPayloads={customFieldEntries}
        manualEdits={incident.manual_edits}
      />
      {hasChosenAccept && (
        <Form.Helptext className="!text-xs">
          ℹ️ We won&apos;t accept this incident until you click &apos;Next&apos;
          and provide the required information.
        </Form.Helptext>
      )}
      {hasChosenPause && <IncidentPauseSection />}
      {openStreamsBlockResolving && (
        <OpenStreamsWarning openStreams={openStreams} incident={incident} />
      )}
    </Form.Modal>
  );
};

// We allow the following decisions to be specified as a URL parameter
const urlParamTriageDecisionMapping = {
  accept: TriageDecision.Accept,
  decline: TriageDecision.Decline,
  merge: TriageDecision.Merge,
};

export const IncidentPauseSection = () => {
  const formMethods = useFormContext<{
    unpause_in_minutes?: number;
    unpause_as_date?: Date;
  }>();

  const val = formMethods.watch("unpause_in_minutes");
  const showCustom = (val as unknown) === "custom";

  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        label={"When should the incident be paused until?"}
        name="unpause_in_minutes"
        options={[
          { value: "custom", label: "Choose my own date and time" },
          { value: "30", label: "30 minutes" },
          { value: "60", label: "60 minutes" },
          { value: "180", label: "3 hours" },
          { value: "360", label: "6 hours" },
          { value: "720", label: "12 hours" },
          { value: "1440", label: "1 day" },
          { value: "10080", label: "7 days" },
        ]}
        placeholder="Choose a time"
        isClearable={false}
        required={true}
      />
      {showCustom && (
        <DateTimeInputV2
          name="unpause_as_date"
          formMethods={formMethods}
          displayNowButton
          required
          rules={{
            validate: (value: Date): string | undefined => {
              if (value < new Date()) {
                return "Date cannot be in the past";
              }
              return undefined;
            },
          }}
        />
      )}
    </>
  );
};
