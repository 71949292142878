import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useForm } from "react-hook-form";

export type EditPanelTitleAndDescriptionForm = {
  title: string;
  description: string;
};

export const EditPanelTextModal = ({
  onClose,
  onSubmit,
  initialData,
}: {
  onClose: () => void;
  onSubmit: (data: EditPanelTitleAndDescriptionForm) => void;
  initialData: EditPanelTitleAndDescriptionForm;
}): React.ReactElement => {
  const formMethods = useForm<EditPanelTitleAndDescriptionForm>({
    defaultValues: initialData,
  });

  return (
    <Form.Modal<EditPanelTitleAndDescriptionForm>
      analyticsTrackingId="edit-panel-title-and-description"
      formMethods={formMethods}
      title="Edit panel title and description"
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          confirmButtonText="Done"
          onClose={onClose}
        />
      }
    >
      <InputV2
        name="title"
        formMethods={formMethods}
        label="Title"
        placeholder="E.g. Major incidents by user"
        required
      />
      <TextareaV2
        name="description"
        formMethods={formMethods}
        label="Description"
        type={InputType.Text}
        placeholder="E.g. This panel shows the number of major incidents over the past 6 months..."
        rows={6}
      />
    </Form.Modal>
  );
};
