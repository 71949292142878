import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { AutoCloseDelayOptions } from "@incident-shared/settings";
import { SharedToasts, Tooltip } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import {
  AutoCloseIncidentsSettings,
  ScopeNameEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../SettingsSection";

type FormType = {
  enabled: boolean;
  config?: {
    delay_days: string;
  };
};

// Makes the conversion from the API number of days into a string, which is
// what our form deals in.
const toFormType = (body: AutoCloseIncidentsSettings): FormType => ({
  enabled: body.enabled,
  config: {
    delay_days: body.config?.delay_days.toString() as string,
  },
});

export const AutomationAutoCloseIncidentsEditForm =
  (): React.ReactElement | null => {
    const { settings } = useSettings();
    if (!settings) {
      return null;
    }

    return <AutomationAutoCloseIncidentsEditFormInner settings={settings} />;
  };

const AutomationAutoCloseIncidentsEditFormInner = ({
  settings,
}: {
  settings: Settings;
}) => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const showToast = useToast();

  const formMethods = useForm<FormType>({
    defaultValues: toFormType(settings.misc.auto_close_incidents),
  });

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: FormType) => {
      const dataToSubmit: AutoCloseIncidentsSettings = {
        enabled: data.enabled,
      };
      if (data.config) {
        dataToSubmit.config = {
          delay_days: parseInt(data.config.delay_days),
        };
      }

      return await apiClient.settingsUpdateAutoCloseIncidents({
        updateAutoCloseIncidentsRequestBody: {
          auto_close_incidents: dataToSubmit,
        },
      });
    },
    {
      onSuccess: ({ settings }) => {
        formMethods.reset(toFormType(settings.misc.auto_close_incidents));
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      setError: formMethods.setError,
    },
  );

  return (
    <SettingsSection
      formMethods={formMethods}
      mutation={mutation}
      enabledPath="enabled"
      title="Auto-close incidents"
      explanation={<AutoCloseExplanation />}
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        required
        label="Auto-close incidents after:"
        name={"config.delay_days"}
        options={AutoCloseDelayOptions}
        placeholder="Select field type..."
        isClearable={false}
        disabled={!canEditSettings}
      />
    </SettingsSection>
  );
};

const AutoCloseExplanation = () => {
  return (
    <>
      With this feature enabled, we&apos;ll attempt to automatically close
      incidents where we haven&apos;t seen any activity within the configured
      time period.
      <Tooltip
        content={
          <div className="flex flex-col">
            <span>What counts as activity?</span>
            <span>• Posting messages in a channel, including threads</span>
            <span>
              • Updating details like custom fields, timestamps or follow-ups
            </span>
          </div>
        }
      />
    </>
  );
};
