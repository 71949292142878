import type { SVGProps } from "react";
import * as React from "react";
const SvgOpsLevel = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#1890FF"
      fillRule="evenodd"
      d="m6.526 15.952-1.022.596-3.104-1.81v-3.615l1.3-.759V6.968l.087-.05.13-.075L6.971 5.08v-1.27L10.074 2l3.103 1.81v1.27l3.055 1.763.13.074.086.05v3.398l1.3.758v3.614l-3.105 1.811-1.02-.595-3.332 1.922-.216.125-.087-.05-.13-.075-3.332-1.923ZM4.566 9.86l.938-.547 3.104 1.81v3.615l-1.22.713L10.074 17l2.687-1.55-1.221-.713v-3.614l3.103-1.811.939.548V7.468L13.178 6.08v1.346l-3.104 1.81-3.103-1.81V6.08L4.567 7.468v2.39Zm12.09 1.464-2.012-1.173-2.01 1.173 2.01 1.166 2.011-1.166Zm-1.648 4.175 2.016-1.176v-2.375l-2.016 1.169v2.382Zm-2.744-3.552v2.376l2.02 1.178v-2.383l-2.02-1.171Zm-4.75-.623-2.01-1.173-2.01 1.173 2.011 1.166 2.01-1.166Zm-1.646 4.175 2.016-1.176v-2.375l-2.016 1.169v2.382Zm-2.744-3.552v2.376l2.02 1.178v-2.383l-2.02-1.171Zm8.962-7.934-2.012-1.174-2.01 1.173 2.01 1.166 2.01-1.165h.002Zm-1.648 4.174 2.015-1.176V4.635l-2.016 1.168v2.383ZM7.694 4.634V7.01l2.02 1.178V5.805l-2.02-1.17Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOpsLevel;
