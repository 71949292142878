/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionBranchPayload,
    ExpressionBranchPayloadFromJSON,
    ExpressionBranchPayloadFromJSONTyped,
    ExpressionBranchPayloadToJSON,
} from './ExpressionBranchPayload';
import {
    ExpressionElseBranchPayload,
    ExpressionElseBranchPayloadFromJSON,
    ExpressionElseBranchPayloadFromJSONTyped,
    ExpressionElseBranchPayloadToJSON,
} from './ExpressionElseBranchPayload';

/**
 * 
 * @export
 * @interface BranchesOnlyExpressionPayload
 */
export interface BranchesOnlyExpressionPayload {
    /**
     * The branches of the expression
     * @type {Array<ExpressionBranchPayload>}
     * @memberof BranchesOnlyExpressionPayload
     */
    branches: Array<ExpressionBranchPayload>;
    /**
     * 
     * @type {ExpressionElseBranchPayload}
     * @memberof BranchesOnlyExpressionPayload
     */
    else_branch?: ExpressionElseBranchPayload;
}

export function BranchesOnlyExpressionPayloadFromJSON(json: any): BranchesOnlyExpressionPayload {
    return BranchesOnlyExpressionPayloadFromJSONTyped(json, false);
}

export function BranchesOnlyExpressionPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchesOnlyExpressionPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branches': ((json['branches'] as Array<any>).map(ExpressionBranchPayloadFromJSON)),
        'else_branch': !exists(json, 'else_branch') ? undefined : ExpressionElseBranchPayloadFromJSON(json['else_branch']),
    };
}

export function BranchesOnlyExpressionPayloadToJSON(value?: BranchesOnlyExpressionPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branches': ((value.branches as Array<any>).map(ExpressionBranchPayloadToJSON)),
        'else_branch': ExpressionElseBranchPayloadToJSON(value.else_branch),
    };
}

