/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionElseBranchPayload,
    ExpressionElseBranchPayloadFromJSON,
    ExpressionElseBranchPayloadFromJSONTyped,
    ExpressionElseBranchPayloadToJSON,
} from './ExpressionElseBranchPayload';
import {
    ExpressionOperationPayload,
    ExpressionOperationPayloadFromJSON,
    ExpressionOperationPayloadFromJSONTyped,
    ExpressionOperationPayloadToJSON,
} from './ExpressionOperationPayload';

/**
 * 
 * @export
 * @interface ExpressionPayload
 */
export interface ExpressionPayload {
    /**
     * 
     * @type {ExpressionElseBranchPayload}
     * @memberof ExpressionPayload
     */
    else_branch?: ExpressionElseBranchPayload;
    /**
     * The ID of the expression
     * @type {string}
     * @memberof ExpressionPayload
     */
    id?: string;
    /**
     * The human readable label of the expression
     * @type {string}
     * @memberof ExpressionPayload
     */
    label: string;
    /**
     * 
     * @type {Array<ExpressionOperationPayload>}
     * @memberof ExpressionPayload
     */
    operations: Array<ExpressionOperationPayload>;
    /**
     * A short ID that can be used to reference the expression
     * @type {string}
     * @memberof ExpressionPayload
     */
    reference: string;
    /**
     * The root reference for this expression (i.e. where the expression starts)
     * @type {string}
     * @memberof ExpressionPayload
     */
    root_reference: string;
}

export function ExpressionPayloadFromJSON(json: any): ExpressionPayload {
    return ExpressionPayloadFromJSONTyped(json, false);
}

export function ExpressionPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'else_branch': !exists(json, 'else_branch') ? undefined : ExpressionElseBranchPayloadFromJSON(json['else_branch']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': json['label'],
        'operations': ((json['operations'] as Array<any>).map(ExpressionOperationPayloadFromJSON)),
        'reference': json['reference'],
        'root_reference': json['root_reference'],
    };
}

export function ExpressionPayloadToJSON(value?: ExpressionPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'else_branch': ExpressionElseBranchPayloadToJSON(value.else_branch),
        'id': value.id,
        'label': value.label,
        'operations': ((value.operations as Array<any>).map(ExpressionOperationPayloadToJSON)),
        'reference': value.reference,
        'root_reference': value.root_reference,
    };
}

