/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageComponentImpact
 */
export interface StatusPageComponentImpact {
    /**
     * The ID of the component that was affected. Either a status page component or a catalog entry.
     * @type {string}
     * @memberof StatusPageComponentImpact
     */
    component_id: string;
    /**
     * When this impact was created
     * @type {Date}
     * @memberof StatusPageComponentImpact
     */
    created_at: Date;
    /**
     * When the component left this status. If this is null, the impact is ongoing.
     * @type {Date}
     * @memberof StatusPageComponentImpact
     */
    end_at?: Date;
    /**
     * A unique ID for this status page component impact
     * @type {string}
     * @memberof StatusPageComponentImpact
     */
    id: string;
    /**
     * When the component entered this status
     * @type {Date}
     * @memberof StatusPageComponentImpact
     */
    start_at: Date;
    /**
     * The status of the impacted component during this time period
     * @type {string}
     * @memberof StatusPageComponentImpact
     */
    status: StatusPageComponentImpactStatusEnum;
    /**
     * The ID of the status page incident that caused this impact
     * @type {string}
     * @memberof StatusPageComponentImpact
     */
    status_page_incident_id: string;
    /**
     * When this impact was last updated
     * @type {Date}
     * @memberof StatusPageComponentImpact
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageComponentImpactStatusEnum {
    UnderMaintenance = 'under_maintenance',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    FullOutage = 'full_outage'
}

export function StatusPageComponentImpactFromJSON(json: any): StatusPageComponentImpact {
    return StatusPageComponentImpactFromJSONTyped(json, false);
}

export function StatusPageComponentImpactFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageComponentImpact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_id': json['component_id'],
        'created_at': (new Date(json['created_at'])),
        'end_at': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'id': json['id'],
        'start_at': (new Date(json['start_at'])),
        'status': json['status'],
        'status_page_incident_id': json['status_page_incident_id'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function StatusPageComponentImpactToJSON(value?: StatusPageComponentImpact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.component_id,
        'created_at': (value.created_at.toISOString()),
        'end_at': value.end_at === undefined ? undefined : (value.end_at.toISOString()),
        'id': value.id,
        'start_at': (value.start_at.toISOString()),
        'status': value.status,
        'status_page_incident_id': value.status_page_incident_id,
        'updated_at': (value.updated_at.toISOString()),
    };
}

