import {
  CommunityBanner,
  ImpersonateBanner,
  TrialBanner,
  useTrialBanner,
} from "@incident-shared/banners";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { InternalStatusPageHomepage } from "src/components/legacy/internal-status-pages/Homepage";
import { InternalStatusPageIncidentDetailsPage } from "src/components/legacy/internal-status-pages/IncidentDetailsPage";
import { InternalStatusPageNotFound } from "src/components/legacy/internal-status-pages/NotFoundPage";
import { useDisableNPS } from "src/contexts/SurvicateContext";

export const InternalStatusPagesRoute = (): React.ReactElement => {
  // We don't want to show NPS surveys on internal pages
  useDisableNPS();

  const trialBanner = useTrialBanner();

  useEffect(() => {
    // Add the styling for the background
    document.body.classList.add("bg-white", "dark:bg-global");

    // When navigating away remove this class
    return () => document.body.classList.remove("bg-white", "dark:bg-global");
  }, []);

  return (
    <>
      {/* This is used to scroll to the top of the page on nav change*/}
      <div id="top" />
      {/* We don't show all banners here. Specifically, we don't show reinstall or
       ** broken-integration banners, since the viewer of the internal status page
       ** probably can't do a lot about those */}
      <ImpersonateBanner />
      <CommunityBanner />
      {trialBanner && (
        <TrialBanner
          bannerType={trialBanner.bannerType}
          trialData={trialBanner.trialData}
        />
      )}
      <Routes>
        <Route
          path={"incidents/:id"}
          element={<InternalStatusPageIncidentDetailsPage />}
        />
        <Route
          path={":subpath/incidents/:id"}
          element={<InternalStatusPageIncidentDetailsPage />}
        />
        <Route path={":subpath"} element={<InternalStatusPageHomepage />} />
        <Route path={""} element={<InternalStatusPageHomepage />} />
        <Route path={"*"} element={<InternalStatusPageNotFound />} />
      </Routes>
    </>
  );
};
