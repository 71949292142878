/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimestamp,
    IncidentTimestampFromJSON,
    IncidentTimestampFromJSONTyped,
    IncidentTimestampToJSON,
} from './IncidentTimestamp';

/**
 * 
 * @export
 * @interface IncidentTimestampsUpdateResponseBody
 */
export interface IncidentTimestampsUpdateResponseBody {
    /**
     * 
     * @type {IncidentTimestamp}
     * @memberof IncidentTimestampsUpdateResponseBody
     */
    incident_timestamp: IncidentTimestamp;
}

export function IncidentTimestampsUpdateResponseBodyFromJSON(json: any): IncidentTimestampsUpdateResponseBody {
    return IncidentTimestampsUpdateResponseBodyFromJSONTyped(json, false);
}

export function IncidentTimestampsUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampsUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_timestamp': IncidentTimestampFromJSON(json['incident_timestamp']),
    };
}

export function IncidentTimestampsUpdateResponseBodyToJSON(value?: IncidentTimestampsUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_timestamp': IncidentTimestampToJSON(value.incident_timestamp),
    };
}

