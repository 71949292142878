import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Heading,
  Txt,
} from "@incident-ui";
import { useQueryParams } from "src/utils/query-params";

export const MSTeamsInstallFailure = () => {
  const failure = useQueryParams().get("failure") as string | null;

  if (!failure) {
    return null;
  }

  return (
    <Callout theme={CalloutTheme.Danger}>
      <Heading level={2} className="!text-sm">
        Something went wrong.
      </Heading>
      <Txt>{failureMessage(failure)}</Txt>
      <Txt>
        Please{" "}
        <Button
          analyticsTrackingId="downgrade-request"
          href="mailto:support@incident.io"
          theme={ButtonTheme.Link}
        >
          get in touch
        </Button>{" "}
        if this problem persists.
      </Txt>
    </Callout>
  );
};

const failureMessage = (failure: string): React.ReactNode => {
  switch (failure) {
    case "microsoft_teams_tenant_not_present":
      return (
        <div>
          Microsoft didn&apos;t provide us with information on the tenant that
          was used to connect.
        </div>
      );
    case "microsoft_teams_admin_consent_not_present":
      return (
        <div>
          Microsoft didn&apos;t return any details about &ldquo;admin
          consent&rdquo;.
        </div>
      );
    case "microsoft_teams_admin_consent_not_given":
      return (
        <div>Admin consent wasn&apos;t provided during authentication.</div>
      );
    case "microsoft_teams_redirect_failed":
      return (
        <div>
          The redirect from Microsoft&apos;s authentication was unsuccessful.
        </div>
      );
    case "microsoft_teams_obtaining_token_failed":
      return (
        <div>
          We failed to obtain a valid authentication token from Microsoft.
        </div>
      );
    case "microsoft_teams_tenant_mismatch":
      return (
        <div>
          The Microsoft tenant being used is not the same that was initially
          used to sign in. Please make sure you&apos;re signed in to the correct
          Microsoft account.
        </div>
      );
    case "microsoft_teams_install_failed":
      return (
        <div>
          We couldn&rsquo;t install the incident.io bot to your Microsoft Teams
          account. We&rsquo;ve paged our on-call engineer and they will be in
          touch to help you shortly.
        </div>
      );
    case "microsoft_teams_generic_error":
      return (
        <div>
          We&rsquo;ve encountered a problem when trying to authenticate you.
        </div>
      );

    case "microsoft_teams_upgrade_failed":
      return (
        <div>
          We couldn&rsquo;t upgrade the incident.io bot in your Microsoft Teams
          account.
        </div>
      );
    default:
      return <div>An unknown error occurred</div>;
  }
};
