/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentDemoRequestBody
 */
export interface StatusPageContentDemoRequestBody {
    /**
     * The URL of an (Atlassian) statuspage to demo-import
     * @type {string}
     * @memberof StatusPageContentDemoRequestBody
     */
    source_url: string;
}

export function StatusPageContentDemoRequestBodyFromJSON(json: any): StatusPageContentDemoRequestBody {
    return StatusPageContentDemoRequestBodyFromJSONTyped(json, false);
}

export function StatusPageContentDemoRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentDemoRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source_url': json['source_url'],
    };
}

export function StatusPageContentDemoRequestBodyToJSON(value?: StatusPageContentDemoRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source_url': value.source_url,
    };
}

