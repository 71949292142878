import { TimestampDescription } from "@incident-shared/timestamps/TimestampDescription";
import { Icon, IconEnum, Interpose, Tooltip } from "@incident-ui";
import React from "react";
import {
  IncidentStatus,
  IncidentTimestamp,
  IncidentTimestampSetByRule,
  IncidentTimestampTimestampTypeEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { uneditableTimestampTypes } from "../timestamps-metrics/IncidentTimestampsSection";
import { TimestampWithRule } from "./prepareLifecycle";
import {
  SetByRulePrefill,
  TimestampSetByRuleAddPopover,
} from "./TimestampSetByRuleAddPopover";
import { TimestampSetByRuleEditPopover } from "./TimestampSetByRuleEditPopover";

export const TimestampsOnStatusChange = ({
  timestamps,
  disabled = false,
  setByRulePrefill,
  betweenStatuses,
}: {
  timestamps: TimestampWithRule[];
  disabled?: boolean;
  setByRulePrefill?: SetByRulePrefill;
  betweenStatuses?: [IncidentStatus | undefined, IncidentStatus | undefined];
}): React.ReactElement | null => {
  return (
    <div
      className={tcx(
        "inline-flex items-center bg-surface-secondary font-normal text-sm justify-start p-0.5 my-2.5 ml-[8.5px]",
        disabled
          ? "text-slate-400"
          : "text-slate-600 group-hover:text-slate-700",
      )}
    >
      {timestamps.length === 0 ? (
        <Icon
          id={IconEnum.Exclamation}
          className={tcx(
            "shrink-0 mr-1.5",
            disabled ? "text-slate-400" : "text-content-primary",
          )}
        />
      ) : (
        <Icon
          id={IconEnum.Clock}
          className="text-content-tertiary group-hover:text-slate-700 shrink-0 mr-[5px]"
        />
      )}

      <>
        {timestamps.length === 0 ? (
          !!setByRulePrefill && (
            <TimestampSetByRuleAddPopover
              rule={setByRulePrefill}
              disabled={disabled}
            />
          )
        ) : (
          <div className="inline-flex items-center font-normal">
            <Interpose separator={<span className="mr-2">{","}</span>}>
              {timestamps.map(({ timestamp, rule }) => (
                <TimestampViewAndEdit
                  disabled={disabled}
                  timestamp={timestamp}
                  rule={rule}
                  key={timestamp.id}
                  betweenStatuses={betweenStatuses}
                />
              ))}
            </Interpose>
          </div>
        )}
      </>
    </div>
  );
};

const TimestampViewAndEdit = ({
  timestamp,
  rule,
  disabled,
  betweenStatuses,
}: {
  timestamp: IncidentTimestamp;
  rule?: IncidentTimestampSetByRule;
  disabled?: boolean;
  betweenStatuses?: [IncidentStatus | undefined, IncidentStatus | undefined];
}): React.ReactElement | null => {
  if (!rule) {
    return (
      <Tooltip
        content={
          <>
            This timestamp is{" "}
            <TimestampDescription
              timestamp={timestamp}
              compactAndDarkMode
              className="inline"
            />
          </>
        }
        bubbleProps={{ align: "start", className: "!max-w-[270px]" }}
        side="bottom"
      >
        <div>{timestamp.name}</div>
      </Tooltip>
    );
  }

  if (uneditableTimestampTypes.includes(timestamp.timestamp_type)) {
    // We shouldn't get here, but just in case - we don't want to let you edit these.
    return (
      <Tooltip
        content={
          timestamp.timestamp_type !==
          IncidentTimestampTimestampTypeEnum.ClosedAt ? (
            <>This timestamp is not editable</>
          ) : (
            // The closedAt timestamp is set by a rule (whereas the other uneditable ones are determined
            // by a field e.g. SetOnAcceptance). So we still want to describe how it's set, so it looks
            // like the other uneditable timestamps.
            <div>
              This timestamp is set when{" "}
              <div className="font-semibold">an incident is closed</div>
            </div>
          )
        }
        bubbleProps={{ align: "start" }}
        side="bottom"
      >
        <div>{timestamp.name}</div>
      </Tooltip>
    );
  }

  return (
    <TimestampSetByRuleEditPopover
      disabled={disabled}
      timestamp={timestamp}
      rule={rule}
      key={timestamp.id}
      betweenStatuses={betweenStatuses || [undefined, undefined]}
    />
  );
};
