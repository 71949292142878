/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JiraUser
 */
export interface JiraUser {
    /**
     * URL to use as an avatar
     * @type {string}
     * @memberof JiraUser
     */
    avatar_url: string;
    /**
     * Email address of the user
     * @type {string}
     * @memberof JiraUser
     */
    email: string;
    /**
     * Unique ID of the user in Jira
     * @type {string}
     * @memberof JiraUser
     */
    id: string;
    /**
     * Is this user still active?
     * @type {boolean}
     * @memberof JiraUser
     */
    is_active: boolean;
    /**
     * Name of the user
     * @type {string}
     * @memberof JiraUser
     */
    name: string;
}

export function JiraUserFromJSON(json: any): JiraUser {
    return JiraUserFromJSONTyped(json, false);
}

export function JiraUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar_url': json['avatar_url'],
        'email': json['email'],
        'id': json['id'],
        'is_active': json['is_active'],
        'name': json['name'],
    };
}

export function JiraUserToJSON(value?: JiraUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_url': value.avatar_url,
        'email': value.email,
        'id': value.id,
        'is_active': value.is_active,
        'name': value.name,
    };
}

