import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentTicket = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 4h5.75A2.752 2.752 0 0 1 17 6.75V10h-3.25a1.75 1.75 0 0 0-.614 3.39l-1.623 1.623a1.743 1.743 0 0 0-.495.987H8.5a.75.75 0 0 1-.75-.75c0-.551-.449-1-1-1-.551 0-1 .449-1 1A.75.75 0 0 1 5 16h-.25A2.752 2.752 0 0 1 2 13.25v-6.5A2.752 2.752 0 0 1 4.75 4H5a.75.75 0 0 1 .75.75c0 .551.449 1 1 1 .551 0 1-.449 1-1A.75.75 0 0 1 8.5 4ZM6 11.438a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0Zm0-2.875a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0ZM13.75 11h3.5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-1.689l-3.22 3.22a.744.744 0 0 1-1.06 0 .75.75 0 0 1 0-1.061l3.22-3.22h-1.69a.75.75 0 0 1 0-1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIncidentTicket;
