import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusClosed = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#1FA382"
      fillRule="evenodd"
      d="M13.5 8H15a7 7 0 0 0-7-7v1.5A5.5 5.5 0 0 1 13.5 8ZM13.5 8H15a7 7 0 0 1-7 7v-1.5A5.5 5.5 0 0 0 13.5 8ZM2.5 8H1a7 7 0 0 0 7 7v-1.5A5.5 5.5 0 0 1 2.5 8ZM2.5 8H1a7 7 0 0 1 7-7v1.5A5.5 5.5 0 0 0 2.5 8Z"
      clipRule="evenodd"
    />
    <path
      fill="#1FA382"
      d="M8 5.778A2.224 2.224 0 0 0 5.778 8c0 1.226.996 2.222 2.222 2.222A2.224 2.224 0 0 0 10.222 8 2.224 2.224 0 0 0 8 5.778Z"
    />
  </svg>
);
export default SvgIncidentStatusClosed;
