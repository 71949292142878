import { ExternalResource } from "@incident-io/api";
import { Link } from "@incident-ui";

import { AttachmentTypeToRenderProps } from "../../legacy/incident/attachments/IncidentAttachment";
import { ActivityBadge } from "./ActivityBadge";

export const AttachmentContent = ({
  resource,
}: {
  resource: ExternalResource;
}): React.ReactElement => {
  const renderer = AttachmentTypeToRenderProps[resource.resource_type];
  if (!renderer) {
    throw new Error(
      "unable to get render props for attachment type: " +
        resource.resource_type,
    );
  }

  const { title: resourceDescription, safeBadges } = renderer(resource);

  return (
    <div className="space-y-2">
      <Link href={resource.permalink} openInNewTab analyticsTrackingId={null}>
        {resourceDescription}
      </Link>
      <div className="flex-center-y gap-2 flex-wrap">
        {safeBadges?.map((badge) => {
          return (
            <ActivityBadge
              key={badge.text}
              icon={badge.icon}
              value={badge.text}
              tooltipContent={badge.tooltipContent}
            />
          );
        })}
      </div>
    </div>
  );
};
