import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { ButtonProps } from "@incident-ui/Button/Button";
import { uniq } from "lodash";
import React, { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

const Entry = ({
  label,
  labelTooltip,
  value,
  allowWrap = false,
  allowLabelShrink = false,
  className,
}: {
  label: React.ReactNode;
  labelTooltip?: React.ReactNode;
  value: React.ReactNode;
  allowWrap?: boolean;
  allowLabelShrink?: boolean;
  className?: string;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "flex justify-between gap-4",
        allowWrap ? "items-start min-h-7" : "items-center h-7",
        className,
      )}
    >
      <div
        className={tcx(
          "text-content-secondary text-sm-normal max-w-[50%] min-w-[80px]",
          !allowLabelShrink && "shrink-0",
          allowWrap ? "mt-[3px]" : "truncate",
        )}
      >
        <Tooltip content={labelTooltip}>
          <>{label}</>
        </Tooltip>
      </div>
      <div
        className={tcx(
          "text-sm-normal shrink-[2]",
          allowWrap ? "text-wrap" : "truncate",
        )}
      >
        {value}
      </div>
    </div>
  );
};

const EmptyValue = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    className?: string;
  }
>(({ className, children, ...props }, ref) => {
  return (
    <div
      className={tcx("text-content-tertiary text-sm-med", className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

EmptyValue.displayName = "EmptyValue";

const ValueBadge = ({
  icon,
  label,
}: {
  icon: IconEnum;
  label: React.ReactNode;
}): React.ReactElement => {
  return (
    <Badge theme={BadgeTheme.Tertiary} icon={icon} size={BadgeSize.Medium}>
      {label}
    </Badge>
  );
};

const ValueButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { iconNode?: React.ReactNode; suffixNode?: React.ReactNode }
>(({ children, iconNode, suffixNode, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      theme={ButtonTheme.Tertiary}
      size={BadgeSize.Medium}
      {...props}
      className={tcx(
        props.className,
        "text-sm-med justify-start truncate max-w-full",
      )}
    >
      {iconNode}
      <span className="truncate">{children}</span>
      {suffixNode}
    </Button>
  );
});

ValueButton.displayName = "ValueButton";

const EmptyValueButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { iconNode?: React.ReactNode; suffixNode?: React.ReactNode }
>(({ children, iconNode, suffixNode, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      theme={ButtonTheme.Link}
      size={BadgeSize.Medium}
      {...props}
      className={tcx(
        props.className,
        "text-sm-med justify-start truncate max-w-full text-content-tertiary hover:text-content-primary",
      )}
    >
      {iconNode}
      <span className="truncate">{children}</span>
      {suffixNode}
    </Button>
  );
});

EmptyValueButton.displayName = "EmptyValueButton";

export type IconValueButtonItem = {
  icon: IconEnum;
  label: React.ReactNode;
};
const IconValueButton = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, "children"> & {
    items: IconValueButtonItem[];
    alwaysCompact?: boolean;
  }
>(({ items, alwaysCompact, ...props }, ref) => {
  if (items.length === 0) {
    return <EmptyValue>—</EmptyValue>;
  }

  if (items.length === 1 && !alwaysCompact) {
    const item = items[0];
    return (
      <ValueButton {...props} ref={ref} icon={item.icon}>
        {item.label}
      </ValueButton>
    );
  }

  // Show up to 5 unique icons
  const uniqueIcons = uniq(items.map((item) => item.icon)).slice(0, 5);

  return (
    <ValueButton
      {...props}
      ref={ref}
      iconNode={
        <div className="flex gap-1">
          {uniqueIcons.map((icon, index) => (
            <Icon key={index} id={icon} size={IconSize.Medium} />
          ))}
        </div>
      }
    >
      {items.length > 1 ? (
        items.length
      ) : (
        <span className="truncate"> {items[0].label}</span>
      )}
    </ValueButton>
  );
});

IconValueButton.displayName = "IconValueButton";

const Title = ({
  accessory,
  collapsible,
  collapsed,
  onToggleCollapsed,
  children,
}: {
  accessory?: React.ReactNode;
  collapsible: boolean;
  collapsed: boolean;
  onToggleCollapsed: () => void;
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <div className="text-content-primary w-full flex justify-between gap-2">
      {collapsible ? (
        <Button
          analyticsTrackingId={null}
          title=""
          theme={ButtonTheme.Naked}
          size={BadgeSize.Medium}
          onClick={onToggleCollapsed}
          className="flex gap-1 w-fit group/title-label"
        >
          <div className="text-content-primary text-sm-bold">{children}</div>
          <Icon
            id={collapsed ? IconEnum.Expand : IconEnum.Collapse}
            className={tcx(
              "text-content-secondary ",
              collapsed ? "" : "invisible group-hover/title-label:visible", // Only hide it when the section is expanded
            )}
          />
        </Button>
      ) : (
        <div className="text-content-primary text-sm-bold">{children}</div>
      )}
      <div className="invisible group-hover/section:visible">{accessory}</div>
    </div>
  );
};

const TitleAccessoryButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { iconNode?: React.ReactNode }
>(({ children, iconNode, ...props }, ref) => {
  return (
    <Button
      {...props}
      ref={ref}
      size={BadgeSize.Medium}
      title=""
      theme={ButtonTheme.Naked}
    >
      {iconNode}
      <span className="truncate">{children}</span>
    </Button>
  );
});

TitleAccessoryButton.displayName = "TitleAccessoryButton";

const Section = ({
  title,
  accessory,
  collapsible = true,
  collapsedByDefault = false,
  children,
  className,
}: {
  title?: React.ReactNode;
  accessory?: React.ReactNode;
  collapsible?: boolean;
  collapsedByDefault?: boolean;
  children: React.ReactNode;
  className?: string;
}): React.ReactElement => {
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  return (
    <div
      className={tcx("flex flex-col gap-2 truncate group/section", className)}
    >
      {title && (
        <Title
          accessory={accessory}
          onToggleCollapsed={() => setCollapsed(!collapsed)}
          collapsed={collapsed}
          collapsible={collapsible}
        >
          {title}
        </Title>
      )}
      {collapsed ? <></> : <>{children}</>}
    </div>
  );
};

export const Sidebar = {
  Entry,
  EmptyValue,
  EmptyValueButton,
  ValueBadge,
  ValueButton,
  IconValueButton,
  Section,
  TitleAccessoryButton,
};
