import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { StepConfig } from "@incident-ui/Steps/Steps";
import { Route, Routes } from "react-router";

import { ConfigureAPIKeyStep } from "./CatalogTypeManageInGitHubConfigureAPIKeyStep";
import { DownloadTypesStep } from "./CatalogTypeManageInGitHubDownloadTypesStep";
import { SelectTypesStep } from "./CatalogTypeManageInGitHubSelectTypesStep";
import { SyncStep } from "./CatalogTypeManageInGitHubSyncStep";
import { UploadRepoStep } from "./ManageInGithubUploadStep";

export enum ManageInGitHubStepEnum {
  SelectTypes = "select_types",
  UploadRepo = "upload_repo",
  CheckSetup = "check_setup",
  Sync = "sync",
}

export const ManageInGithubStepConfig: StepConfig<ManageInGitHubStepEnum>[] = [
  {
    id: ManageInGitHubStepEnum.SelectTypes,
    name: "Select types",
  },
  {
    id: ManageInGitHubStepEnum.UploadRepo,
    name: "Upload",
  },
  {
    id: ManageInGitHubStepEnum.CheckSetup,
    name: "Configure",
  },
  {
    id: ManageInGitHubStepEnum.Sync,
    name: "Sync",
  },
];

export const ManageInGithubRoute = () => {
  return (
    <PageWrapper
      width={PageWidth.Narrow}
      title="Manage in GitHub"
      icon={IconEnum.Github}
      color={ColorPaletteEnum.Slate}
      backHref="/catalog"
      crumbs={[{ title: "Catalog", to: "/catalog" }]}
    >
      <Routes>
        <Route path="" element={<SelectTypesStep />} />
        {/* The download step isn't visually different but performs a different action */}
        <Route path=":repoId/download" element={<DownloadTypesStep />} />
        <Route path=":repoId/upload" element={<UploadRepoStep />} />
        <Route path=":repoId/api-key" element={<ConfigureAPIKeyStep />} />
        <Route path=":repoId/sync" element={<SyncStep />} />
      </Routes>
    </PageWrapper>
  );
};
