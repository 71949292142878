import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { BadgeSize, ButtonTheme, IconEnum, Txt } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { SettingsDeleteButton } from "../../../@shared/settings/SettingsList/SettingsListButtons";

export const IncidentFormElementListRow = ({
  onEdit,
  onDelete,
  canEdit = true,
  canDelete,
  cannotDeleteReason,
  formElement,
  showIfText,
  requiredIfText,
  label,
}: {
  onEdit: () => void;
  onDelete: () => void;
  canEdit: boolean;
  canDelete: boolean;
  cannotDeleteReason: string | undefined;
  formElement: React.ReactNode;
  showIfText?: string;
  requiredIfText?: React.ReactNode;
  label: string;
}): React.ReactElement => {
  return (
    <div className={tcx("grid grid-cols-3 w-full pl-4")}>
      <div className={tcx("col-span-2 flex items-center")}>
        <div className="p-4 w-full">{formElement}</div>
      </div>
      <div className="flex flex-col gap-2">
        {/* Row 1: top line info */}
        <div className="flex gap-1 p-4 items-end w-full">
          <div className="flex flex-col gap-1 grow">
            <Txt className="!text-content-tertiary" xs>
              {showIfText}
            </Txt>
            <Txt className="!text-content-tertiary" xs>
              {requiredIfText}
            </Txt>
          </div>
          {/* Column 3: edit button */}
          <GatedButton
            onClick={onEdit}
            disabled={!canEdit}
            theme={ButtonTheme.Tertiary}
            size={BadgeSize.Medium}
            icon={IconEnum.Edit}
            analyticsTrackingId="edit-settings-item"
            title="Edit"
          />
          {/* Column 4: delete button */}
          <SettingsDeleteButton
            resourceTitle={label}
            onDelete={onDelete}
            deleteConfirmationTitle="Delete field?"
            deleteConfirmationContent={
              <DeletionConfirmationMessage label={label} />
            }
            theme={ButtonTheme.Tertiary}
            isGatedText={canDelete ? undefined : cannotDeleteReason}
          />
        </div>
      </div>
    </div>
  );
};

const DeletionConfirmationMessage = ({ label }: { label: string }) => {
  return (
    <div className="text-sm gap-1">
      <div className="inline">Are you sure you want to remove </div>
      <div className="inline-flex gap-1">
        <div className="font-bold inline">{label}</div>
      </div>
      <div className="inline"> from this form?</div>
    </div>
  );
};
