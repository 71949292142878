// These props are passed through to the react-select component.
// If you want to use a new feature of react-select, add it here.
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { orderBy } from "lodash";
import React from "react";
import {
  GroupBase,
  InputActionMeta,
  OptionProps,
  PropsValue,
  StylesConfig,
} from "react-select";
import Select from "react-select/dist/declarations/src/Select";

export type SelectRefType<IsMulti extends boolean> = Select<
  SelectOption,
  IsMulti,
  GroupBase<SelectOption>
>;

export interface SelectOption<TLabel = string> {
  value: string;
  label: TLabel;
  sort_key?: string;
  image_url?: string;
  is_image_slack_icon?: boolean;
  description?: string | React.ReactNode;
  icon?: IconEnum;
  color?: ColorPaletteEnum;
  // Provide iconComponent to override the default icon component
  // e.g. if you want to add rounding to the icon
  iconComponent?: React.ReactNode;
  disabled?: boolean;
  unavailable?: boolean;
  renderFn?: (
    props: OptionProps<SelectOption, boolean, GroupBase<SelectOption>>,
  ) => React.ReactElement;
}

export interface SelectOptionGroup<TLabel = string> {
  label: TLabel;
  options: SelectOption<TLabel>[];
}

// This and the associated type checkers are silly but necessary to get
// around problems associated with unions of arrays. See discussion
// here https://github.com/microsoft/TypeScript/issues/33591#issuecomment-786443978
export type SelectOptionOrGroup<TLabel = string> =
  | SelectOption<TLabel>
  | SelectOptionGroup<TLabel>;

// SelectOptions is either a list of select options, or a set of option groups,
// which each get a label.
export type SelectOptions<TLabel = string> = Array<SelectOptionOrGroup<TLabel>>;

export function isSelectOption(
  option: SelectOptionOrGroup,
): option is SelectOption {
  return "value" in option;
}

export function isSelectOptionGroup(
  option: SelectOptionOrGroup,
): option is SelectOptionGroup {
  return "options" in option;
}

export type SelectValue = string | string[] | null;

// These props are shared across the single and multi select components.
export type SharedDynamicSelectProps = ReactSelectProps & {
  loadOptions: (inputValue: string) => Promise<SelectOptions>;
  icon?: IconEnum;
  optionIcon?: IconEnum;
  optionColor?: ColorPaletteEnum;
  id?: string;
  className?: string;
  ignoreDisabledStyling?: boolean;
  invalid?: boolean;
  autoFocus?: boolean;
};

export type HydratedValuesCache = { [key: string]: SelectOption };

export type Order = "asc" | "desc";
export type SelectOptionOrderBy = () => [string[], Order[]];

const defaultSelectOptionOrderByArgs: SelectOptionOrderBy = () => {
  return [["sort_key"], ["asc"]];
};

// sortSelectOptions sorts the options in a SelectOptions array. It can handle
// both SelectOption and SelectOptionGroup arrays.
export const sortSelectOptions = (
  options: SelectOptions,
  sortingOrder: SelectOptionOrderBy = defaultSelectOptionOrderByArgs,
): SelectOptions => {
  if (options.length === 0) {
    return [];
  }

  // This allows us to "order by" a list...
  // e.g. a list of users by name and age
  // var users = [
  //   { 'user': 'fred',   'age': 48 },
  //   { 'user': 'barney', 'age': 34 },
  //   { 'user': 'fred',   'age': 40 },
  //   { 'user': 'barney', 'age': 36 }
  // ];
  //
  // _.orderBy(users, ['user', 'age'], ['asc', 'desc']);
  // > [
  //   { 'user': 'barney', 'age': 36 }
  //   { 'user': 'barney', 'age': 34 },
  //   { 'user': 'fred',   'age': 48 },
  //   { 'user': 'fred',   'age': 40 },
  // ];
  //
  // By default we pick [['sort_key'], ['asc']] as the order by
  const [keys, orders] = sortingOrder();

  if (isSelectOption(options[0])) {
    return orderBy(options, keys, orders);
  } else if (isSelectOptionGroup(options[0])) {
    // When we're dealing with a group, we should sort the options within each group
    const sortedOptions: SelectOptionGroup[] = [];

    options.forEach((option) => {
      sortedOptions.push({
        ...option,
        // @ts-expect-error options definitely exists on the group because we checked this above
        options: orderBy(option.options, keys, orders),
      });
    });

    return sortedOptions;
  } else {
    throw new Error("Invalid select options");
  }
};

// These props are passed through to the react-select component.
// If you want to use a new feature of react-select, add it here.
export type ReactSelectProps = {
  className?: string;
  placeholder?: string;
  onBlur?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  insetSuffixNode?: React.ReactNode;
  defaultValue?: PropsValue<SelectOption>;
  filterOption?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    candidate: { label: string; value: string; data: any },
    input: string,
  ) => boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  closeMenuOnSelect?: boolean;
};

// These props are shared across the single and multi select components.
export type SharedStaticSelectProps = Omit<
  ReactSelectProps,
  "isMulti" | "isDisabled"
> & {
  options: SelectOptions;
  icon?: IconEnum;
  label?: React.ReactNode;
  optionIcon?: IconEnum;
  optionColor?: ColorPaletteEnum;
  id?: string;
  invalid?: boolean;
  // TODO: for parity with RadixSelect, we probably want to implement inside-container here too.
  renderDescription?: "below";
  isDisplayingPill?: boolean;
  noOptionsMessage?: () => React.ReactNode;
  disabledTooltipContent?: React.ReactNode;
  disabled?: boolean;
  styles?: (
    invalid: boolean,
    hasSuffixNode: boolean,
    isDisplayingPill?: boolean,
  ) => StylesConfig<SelectOption, boolean, GroupBase<SelectOption>>;
} & (
    | {
        allowAdding: true;
        onCreateOption: (inputValue: string) => void;
      }
    | {
        allowAdding?: never;
        onCreateOption?: never;
      }
  );
