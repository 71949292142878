/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';

/**
 * 
 * @export
 * @interface IncidentsBulkUpdateCustomFieldEntriesRequestBody
 */
export interface IncidentsBulkUpdateCustomFieldEntriesRequestBody {
    /**
     * 
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof IncidentsBulkUpdateCustomFieldEntriesRequestBody
     */
    custom_field_entries: Array<CustomFieldEntryPayload>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentsBulkUpdateCustomFieldEntriesRequestBody
     */
    incident_ids: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IncidentsBulkUpdateCustomFieldEntriesRequestBody
     */
    mode: IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsBulkUpdateCustomFieldEntriesRequestBodyModeEnum {
    Merge = 'merge',
    Set = 'set',
    Delete = 'delete'
}

export function IncidentsBulkUpdateCustomFieldEntriesRequestBodyFromJSON(json: any): IncidentsBulkUpdateCustomFieldEntriesRequestBody {
    return IncidentsBulkUpdateCustomFieldEntriesRequestBodyFromJSONTyped(json, false);
}

export function IncidentsBulkUpdateCustomFieldEntriesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsBulkUpdateCustomFieldEntriesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'incident_ids': json['incident_ids'],
        'mode': json['mode'],
    };
}

export function IncidentsBulkUpdateCustomFieldEntriesRequestBodyToJSON(value?: IncidentsBulkUpdateCustomFieldEntriesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'incident_ids': value.incident_ids,
        'mode': value.mode,
    };
}

