/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Resource,
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './Resource';

/**
 * 
 * @export
 * @interface EngineListResourcesResponseBody
 */
export interface EngineListResourcesResponseBody {
    /**
     * 
     * @type {Array<Resource>}
     * @memberof EngineListResourcesResponseBody
     */
    resources: Array<Resource>;
}

export function EngineListResourcesResponseBodyFromJSON(json: any): EngineListResourcesResponseBody {
    return EngineListResourcesResponseBodyFromJSONTyped(json, false);
}

export function EngineListResourcesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineListResourcesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(ResourceFromJSON)),
    };
}

export function EngineListResourcesResponseBodyToJSON(value?: EngineListResourcesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(ResourceToJSON)),
    };
}

