import type { SVGProps } from "react";
import * as React from "react";
const SvgCloudBroken = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.294 15.706 14.461 5.539c-.925-1.521-2.591-2.54-4.461-2.54a5.256 5.256 0 0 0-5.25 5.25c0 .129.005.259.017.39A3.733 3.733 0 0 0 2 12.25c0 1.553.948 2.887 2.294 3.457ZM15.624 7.558 7.182 16H13.5c2.481 0 4.5-2.02 4.5-4.5a4.485 4.485 0 0 0-2.376-3.942Z"
    />
    <path
      fill="currentColor"
      d="M3 17.75a.75.75 0 0 1-.53-1.281l14-14a.75.75 0 1 1 1.061 1.062L3.53 17.53a.748.748 0 0 1-.53.22Z"
    />
  </svg>
);
export default SvgCloudBroken;
