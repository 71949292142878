/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleWorkspaceCalendarPayload,
    GoogleWorkspaceCalendarPayloadFromJSON,
    GoogleWorkspaceCalendarPayloadFromJSONTyped,
    GoogleWorkspaceCalendarPayloadToJSON,
} from './GoogleWorkspaceCalendarPayload';

/**
 * 
 * @export
 * @interface IntegrationsGoogleWorkspaceSetCalendarsRequestBody
 */
export interface IntegrationsGoogleWorkspaceSetCalendarsRequestBody {
    /**
     * 
     * @type {Array<GoogleWorkspaceCalendarPayload>}
     * @memberof IntegrationsGoogleWorkspaceSetCalendarsRequestBody
     */
    calendars: Array<GoogleWorkspaceCalendarPayload>;
}

export function IntegrationsGoogleWorkspaceSetCalendarsRequestBodyFromJSON(json: any): IntegrationsGoogleWorkspaceSetCalendarsRequestBody {
    return IntegrationsGoogleWorkspaceSetCalendarsRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsGoogleWorkspaceSetCalendarsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGoogleWorkspaceSetCalendarsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendars': ((json['calendars'] as Array<any>).map(GoogleWorkspaceCalendarPayloadFromJSON)),
    };
}

export function IntegrationsGoogleWorkspaceSetCalendarsRequestBodyToJSON(value?: IntegrationsGoogleWorkspaceSetCalendarsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendars': ((value.calendars as Array<any>).map(GoogleWorkspaceCalendarPayloadToJSON)),
    };
}

