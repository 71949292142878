import {
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  ModalFooter,
  Txt,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  FollowUp,
  FollowUpsUpdateRequestBodyStatusEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";

// We have no formData here, but utilise the same react-hook-form methods as elsewhere, just make the form data {}
type FormData = Record<string, never>;

export const BulkStateChangeModal = ({
  selectedFollowUpIDs: followUpIDs,
  followUps,
  targetStatus,
  onClose,
  onCancel,
}: {
  selectedFollowUpIDs: string[];
  followUps: FollowUp[];
  targetStatus: FollowUpsUpdateRequestBodyStatusEnum;
  onClose: () => void;
  onCancel: () => void;
}): React.ReactElement => {
  const apiClient = useClient();

  const [followUpValidationMessage, setFollowUpValidationMessage] = useState<
    string | null
  >(null);

  const selectedFollowUps = followUps.filter((followUp) => {
    if (!followUpIDs.includes(followUp.id)) {
      return false;
    }
    // Only assign follow-ups that aren't exported
    return followUp.external_issue_reference === undefined;
  });

  // We ignore the ones that are exported.
  const numFollowUpsToIgnore = followUpIDs.length - selectedFollowUps.length;

  const formMethods = useForm<FormData>();

  const [onSubmit, { saving, genericError }] = useMutation(
    async (_: FormData) => {
      return Promise.all(
        selectedFollowUps.map((followUp) => {
          return apiClient.followUpsUpdate({
            id: followUp.id,
            updateRequestBody: {
              ...followUp,
              assignee_id: followUp.assignee?.id,
              status: targetStatus,
            },
          });
        }),
      );
    },
    {
      onSuccess: () => {
        onClose();
      },
      onError: (e, validationErrors) => {
        if (validationErrors && validationErrors?.length > 0) {
          const fieldsError = validationErrors[0];
          const errorMessage = fieldsError?.error?.message;

          if (errorMessage) {
            setFollowUpValidationMessage(errorMessage);
          }
        }
      },
    },
  );

  let statusLabel;

  switch (targetStatus) {
    case FollowUpsUpdateRequestBodyStatusEnum.Outstanding:
      statusLabel = "open";
      break;
    case FollowUpsUpdateRequestBodyStatusEnum.Deleted:
      statusLabel = "deleted";
      break;
    case FollowUpsUpdateRequestBodyStatusEnum.Completed:
      statusLabel = "completed";
      break;
    case FollowUpsUpdateRequestBodyStatusEnum.NotDoing:
      statusLabel = "not doing";
      break;
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={(formData) => {
        setFollowUpValidationMessage(null);
        onSubmit(formData);
      }}
      title={`Mark follow-ups as ${statusLabel}`}
      analyticsTrackingId="follow-ups-assign"
      onClose={onCancel}
      footer={
        <ModalFooter
          hideConfirmButton={selectedFollowUps.length === 0}
          confirmButtonText="Save"
          saving={saving}
          onClose={onCancel}
          confirmButtonType="submit"
        />
      }
    >
      {selectedFollowUps.length === 0 ? (
        <div className="space-y-4">
          <Txt>
            You have selected {numFollowUpsToIgnore} follow-up
            {numFollowUpsToIgnore === 1 ? "" : "s"} that have been exported. To
            change the status of those follow-ups, you&apos;ll need to edit them
            directly in your issue tracker.
          </Txt>
        </div>
      ) : (
        <div className="space-y-4">
          {/* Ignoring 1 follow-up */}
          {numFollowUpsToIgnore === 1 && (
            <Callout theme={CalloutTheme.Plain}>
              You&apos;ve selected 1 follow-up that is has been exported.
              We&apos;ll only update unexported follow-ups.
            </Callout>
          )}
          {/* Ignoring multiple follow-ups */}
          {numFollowUpsToIgnore > 1 && (
            <Callout theme={CalloutTheme.Plain}>
              You&apos;ve selected {numFollowUpsToIgnore} follow-ups that have
              been exported. We&apos;ll only update unexported follow-ups.
            </Callout>
          )}
          {followUpValidationMessage ? (
            <>
              <h2>
                Some follow-ups failed to update with the following message:
              </h2>
              <div className="bg-red-surface border-red-200 border rounded px-3 py-2 text-size-sm">
                <Txt>
                  <Icon
                    id={IconEnum.ExclamationMark}
                    className="inline pr-2 pb-0.5"
                  />
                  {followUpValidationMessage}
                </Txt>
              </div>
            </>
          ) : (
            <Txt>
              We&apos;ll change the status of these {selectedFollowUps.length}{" "}
              follow-up
              {selectedFollowUps.length === 1 ? "" : "s"} to {statusLabel}
            </Txt>
          )}
        </div>
      )}
    </Form.Modal>
  );
};
