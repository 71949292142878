/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionElseBranch,
    ExpressionElseBranchFromJSON,
    ExpressionElseBranchFromJSONTyped,
    ExpressionElseBranchToJSON,
} from './ExpressionElseBranch';
import {
    ExpressionOperation,
    ExpressionOperationFromJSON,
    ExpressionOperationFromJSONTyped,
    ExpressionOperationToJSON,
} from './ExpressionOperation';
import {
    ReturnsMeta,
    ReturnsMetaFromJSON,
    ReturnsMetaFromJSONTyped,
    ReturnsMetaToJSON,
} from './ReturnsMeta';

/**
 * 
 * @export
 * @interface Expression
 */
export interface Expression {
    /**
     * 
     * @type {ExpressionElseBranch}
     * @memberof Expression
     */
    else_branch?: ExpressionElseBranch;
    /**
     * The ID of the expression
     * @type {string}
     * @memberof Expression
     */
    id: string;
    /**
     * The human readable label of the expression
     * @type {string}
     * @memberof Expression
     */
    label: string;
    /**
     * 
     * @type {Array<ExpressionOperation>}
     * @memberof Expression
     */
    operations: Array<ExpressionOperation>;
    /**
     * A short ID that can be used to reference the expression
     * @type {string}
     * @memberof Expression
     */
    reference: string;
    /**
     * 
     * @type {ReturnsMeta}
     * @memberof Expression
     */
    returns: ReturnsMeta;
    /**
     * The root reference for this expression (i.e. where the expression starts)
     * @type {string}
     * @memberof Expression
     */
    root_reference: string;
}

export function ExpressionFromJSON(json: any): Expression {
    return ExpressionFromJSONTyped(json, false);
}

export function ExpressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Expression {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'else_branch': !exists(json, 'else_branch') ? undefined : ExpressionElseBranchFromJSON(json['else_branch']),
        'id': json['id'],
        'label': json['label'],
        'operations': ((json['operations'] as Array<any>).map(ExpressionOperationFromJSON)),
        'reference': json['reference'],
        'returns': ReturnsMetaFromJSON(json['returns']),
        'root_reference': json['root_reference'],
    };
}

export function ExpressionToJSON(value?: Expression | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'else_branch': ExpressionElseBranchToJSON(value.else_branch),
        'id': value.id,
        'label': value.label,
        'operations': ((value.operations as Array<any>).map(ExpressionOperationToJSON)),
        'reference': value.reference,
        'returns': ReturnsMetaToJSON(value.returns),
        'root_reference': value.root_reference,
    };
}

