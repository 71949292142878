/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SystemHealthCheckResponseBody,
    SystemHealthCheckResponseBodyFromJSON,
    SystemHealthCheckResponseBodyToJSON,
    SystemTriggerErrorRequestBody,
    SystemTriggerErrorRequestBodyFromJSON,
    SystemTriggerErrorRequestBodyToJSON,
    SystemVersionResponseBody,
    SystemVersionResponseBodyFromJSON,
    SystemVersionResponseBodyToJSON,
} from '../models';

export interface SystemHealthCheckRequest {
    probe?: SystemHealthCheckProbeEnum;
    delay?: string;
}

export interface SystemTriggerErrorRequest {
    triggerErrorRequestBody: SystemTriggerErrorRequestBody;
}

export interface SystemVersionRequest {
    includeMigrations?: boolean;
}

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI {

    /**
     * Health check for probes
     * HealthCheck System
     */
    async systemHealthCheckRaw(requestParameters: SystemHealthCheckRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SystemHealthCheckResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.probe !== undefined) {
            queryParameters['probe'] = requestParameters.probe;
        }

        if (requestParameters.delay !== undefined) {
            queryParameters['delay'] = requestParameters.delay;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/system/healthz`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemHealthCheckResponseBodyFromJSON(jsonValue));
    }

    /**
     * Health check for probes
     * HealthCheck System
     */
    async systemHealthCheck(requestParameters: SystemHealthCheckRequest, initOverrides?: RequestInit): Promise<SystemHealthCheckResponseBody> {
        const response = await this.systemHealthCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Raise an error to test error reporting
     * TriggerError System
     */
    async systemTriggerErrorRaw(requestParameters: SystemTriggerErrorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.triggerErrorRequestBody === null || requestParameters.triggerErrorRequestBody === undefined) {
            throw new runtime.RequiredError('triggerErrorRequestBody','Required parameter requestParameters.triggerErrorRequestBody was null or undefined when calling systemTriggerError.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/system/errors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SystemTriggerErrorRequestBodyToJSON(requestParameters.triggerErrorRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Raise an error to test error reporting
     * TriggerError System
     */
    async systemTriggerError(requestParameters: SystemTriggerErrorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.systemTriggerErrorRaw(requestParameters, initOverrides);
    }

    /**
     * Version information for the API
     * Version System
     */
    async systemVersionRaw(requestParameters: SystemVersionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SystemVersionResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.includeMigrations !== undefined) {
            queryParameters['include_migrations'] = requestParameters.includeMigrations;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/system/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemVersionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Version information for the API
     * Version System
     */
    async systemVersion(requestParameters: SystemVersionRequest, initOverrides?: RequestInit): Promise<SystemVersionResponseBody> {
        const response = await this.systemVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SystemHealthCheckProbeEnum {
    Ready = 'ready',
    Live = 'live',
    Standby = 'standby'
}
