import React from "react";
import { Spinner } from "src/components/@ui/Spinner/Spinner";
import { tcx } from "src/utils/tailwind-classes";

import { LoadingLogo } from "./LoadingLogo";

export const Loader = ({
  large = true,
  className,
}: {
  large?: boolean;
  className?: string;
}): React.ReactElement => {
  return (
    <div className={tcx("flex-center w-full my-4", className)}>
      <Spinner large={large} />
    </div>
  );
};

export const FullPageLoader = ({
  subtitle,
  stopThrobbing,
}: {
  subtitle?: React.ReactElement;
  stopThrobbing?: boolean;
}): React.ReactElement => {
  return (
    <div className="bg-white h-[100vh] min-h-full pb-24 flex flex-col items-center justify-center">
      <LoadingLogo animate={!stopThrobbing} className="h-24" />
      {subtitle != null && subtitle}
    </div>
  );
};
