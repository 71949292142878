/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackTeamConfig,
    SlackTeamConfigFromJSON,
    SlackTeamConfigFromJSONTyped,
    SlackTeamConfigToJSON,
} from './SlackTeamConfig';

/**
 * 
 * @export
 * @interface SlackTeamConfigsListResponseBody
 */
export interface SlackTeamConfigsListResponseBody {
    /**
     * 
     * @type {Array<SlackTeamConfig>}
     * @memberof SlackTeamConfigsListResponseBody
     */
    slack_team_configs: Array<SlackTeamConfig>;
}

export function SlackTeamConfigsListResponseBodyFromJSON(json: any): SlackTeamConfigsListResponseBody {
    return SlackTeamConfigsListResponseBodyFromJSONTyped(json, false);
}

export function SlackTeamConfigsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackTeamConfigsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slack_team_configs': ((json['slack_team_configs'] as Array<any>).map(SlackTeamConfigFromJSON)),
    };
}

export function SlackTeamConfigsListResponseBodyToJSON(value?: SlackTeamConfigsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slack_team_configs': ((value.slack_team_configs as Array<any>).map(SlackTeamConfigToJSON)),
    };
}

