/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureAndComponentsComponentPayload,
    StatusPageStructureAndComponentsComponentPayloadFromJSON,
    StatusPageStructureAndComponentsComponentPayloadFromJSONTyped,
    StatusPageStructureAndComponentsComponentPayloadToJSON,
} from './StatusPageStructureAndComponentsComponentPayload';
import {
    StatusPageStructureAndComponentsGroupPayload,
    StatusPageStructureAndComponentsGroupPayloadFromJSON,
    StatusPageStructureAndComponentsGroupPayloadFromJSONTyped,
    StatusPageStructureAndComponentsGroupPayloadToJSON,
} from './StatusPageStructureAndComponentsGroupPayload';

/**
 * 
 * @export
 * @interface StatusPageStructureAndComponentsItemPayload
 */
export interface StatusPageStructureAndComponentsItemPayload {
    /**
     * 
     * @type {StatusPageStructureAndComponentsComponentPayload}
     * @memberof StatusPageStructureAndComponentsItemPayload
     */
    component?: StatusPageStructureAndComponentsComponentPayload;
    /**
     * 
     * @type {StatusPageStructureAndComponentsGroupPayload}
     * @memberof StatusPageStructureAndComponentsItemPayload
     */
    group?: StatusPageStructureAndComponentsGroupPayload;
}

export function StatusPageStructureAndComponentsItemPayloadFromJSON(json: any): StatusPageStructureAndComponentsItemPayload {
    return StatusPageStructureAndComponentsItemPayloadFromJSONTyped(json, false);
}

export function StatusPageStructureAndComponentsItemPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureAndComponentsItemPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': !exists(json, 'component') ? undefined : StatusPageStructureAndComponentsComponentPayloadFromJSON(json['component']),
        'group': !exists(json, 'group') ? undefined : StatusPageStructureAndComponentsGroupPayloadFromJSON(json['group']),
    };
}

export function StatusPageStructureAndComponentsItemPayloadToJSON(value?: StatusPageStructureAndComponentsItemPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': StatusPageStructureAndComponentsComponentPayloadToJSON(value.component),
        'group': StatusPageStructureAndComponentsGroupPayloadToJSON(value.group),
    };
}

