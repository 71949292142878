import { ExternalUser } from "@incident-io/api";
import { TableCell, TableCellStacked } from "@incident-ui/Table/Table";

export const ExternalUserNameAndEmail = ({
  user,
  alwaysIncludeExternalId = false,
}: {
  user: ExternalUser;
  alwaysIncludeExternalId?: boolean;
}) => {
  if (user.name && user.email) {
    return (
      <TableCellStacked
        className="pr-2 overflow-hidden"
        primary={
          <span className="truncate w-full max-w-full">{user.name}</span>
        }
        secondary={
          <>
            {user.email}
            {alwaysIncludeExternalId ? (
              <>
                {" "}
                (<ExternalId externalId={user.external_id} />)
              </>
            ) : null}
          </>
        }
      />
    );
  }

  if (user.name) {
    if (alwaysIncludeExternalId) {
      return (
        <TableCellStacked
          primary={user.name}
          secondary={<ExternalId externalId={user.external_id} />}
        />
      );
    } else {
      return <TableCell>{user.name}</TableCell>;
    }
  }

  if (user.email) {
    if (alwaysIncludeExternalId) {
      return (
        <TableCellStacked
          primary={user.email}
          secondary={<ExternalId externalId={user.external_id} />}
        />
      );
    } else {
      return <TableCell>{user.email}</TableCell>;
    }
  }

  return <TableCell className="font-mono">{user.external_id}</TableCell>;
};

const ExternalId = ({ externalId }: { externalId: string }) => (
  <span className="font-mono max-w-[75px] truncate">{externalId}</span>
);
