import { Product } from "@incident-shared/billing";
import { ContentBox, GenericErrorMessage, Loader } from "@incident-ui";
import React from "react";
import graphic from "src/components/settings/banners/banner-decision-flows.svg";
import {
  Plurality,
  ProductUpsellNotice,
} from "src/components/settings/ProductUpsellNotice";
import { SettingsSubPageWrapper } from "src/components/settings/SettingsRoute";
import { useProductAccess } from "src/hooks/useProductAccess";

import { DecisionFlowSettings } from "../../../components/settings/legacy/decision-flows";
import { SettingsHeading } from "../../../components/settings/SettingsHeading";
import { useAPI } from "../../../utils/swr";

export const SettingsDecisionFlowsRoute = (): React.ReactElement => {
  const { hasProduct } = useProductAccess();
  const {
    data: { decision_flows: decisionFlows },
    isLoading,
    error,
  } = useAPI("decisionFlowsList", undefined, {
    fallbackData: { decision_flows: [] },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Guide decisions"
        subtitle="Follow decision flows to navigate through incident decisions smoothly."
        graphic={<img src={graphic} className="h-40" />}
        articleId={6858531}
      />
      {error ? (
        <ContentBox className="p-4">
          <GenericErrorMessage error={error} />
        </ContentBox>
      ) : hasProduct(Product.Response) ? (
        <DecisionFlowSettings decisionFlows={decisionFlows} />
      ) : (
        <ProductUpsellNotice
          requiredProduct={Product.Response}
          plurality={Plurality.Plural}
          featureName="Decision Flows"
        />
      )}
    </SettingsSubPageWrapper>
  );
};
