/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody
 */
export interface BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody {
    /**
     * 
     * @type {boolean}
     * @memberof BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody
     */
    requires_billing_scope: boolean;
}

export function BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBodyFromJSON(json: any): BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody {
    return BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBodyFromJSONTyped(json, false);
}

export function BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requires_billing_scope': json['requires_billing_scope'],
    };
}

export function BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBodyToJSON(value?: BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requires_billing_scope': value.requires_billing_scope,
    };
}

