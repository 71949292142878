/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface CatalogEntry
 */
export interface CatalogEntry {
    /**
     * Optional aliases that can be used to reference this entry
     * @type {Array<string>}
     * @memberof CatalogEntry
     */
    aliases: Array<string>;
    /**
     * When this entry was archived
     * @type {Date}
     * @memberof CatalogEntry
     */
    archived_at?: Date;
    /**
     * Values of this entry
     * @type {{ [key: string]: EngineParamBinding; }}
     * @memberof CatalogEntry
     */
    attribute_values: { [key: string]: EngineParamBinding; };
    /**
     * ID of this catalog type
     * @type {string}
     * @memberof CatalogEntry
     */
    catalog_type_id: string;
    /**
     * When this entry was created
     * @type {Date}
     * @memberof CatalogEntry
     */
    created_at: Date;
    /**
     * An optional alternative ID for this entry, which is ensured to be unique for the type
     * @type {string}
     * @memberof CatalogEntry
     */
    external_id?: string;
    /**
     * ID of this catalog entry
     * @type {string}
     * @memberof CatalogEntry
     */
    id: string;
    /**
     * Name is the human readable name of this entry
     * @type {string}
     * @memberof CatalogEntry
     */
    name: string;
    /**
     * If present, the path to view this resource natively in the web UI
     * @type {string}
     * @memberof CatalogEntry
     */
    native_resource_path?: string;
    /**
     * When catalog type is ranked, this is used to help order things
     * @type {number}
     * @memberof CatalogEntry
     */
    rank: number;
    /**
     * When this entry was last updated
     * @type {Date}
     * @memberof CatalogEntry
     */
    updated_at: Date;
}

export function CatalogEntryFromJSON(json: any): CatalogEntry {
    return CatalogEntryFromJSONTyped(json, false);
}

export function CatalogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aliases': json['aliases'],
        'archived_at': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'attribute_values': (mapValues(json['attribute_values'], EngineParamBindingFromJSON)),
        'catalog_type_id': json['catalog_type_id'],
        'created_at': (new Date(json['created_at'])),
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'id': json['id'],
        'name': json['name'],
        'native_resource_path': !exists(json, 'native_resource_path') ? undefined : json['native_resource_path'],
        'rank': json['rank'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function CatalogEntryToJSON(value?: CatalogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aliases': value.aliases,
        'archived_at': value.archived_at === undefined ? undefined : (value.archived_at.toISOString()),
        'attribute_values': (mapValues(value.attribute_values, EngineParamBindingToJSON)),
        'catalog_type_id': value.catalog_type_id,
        'created_at': (value.created_at.toISOString()),
        'external_id': value.external_id,
        'id': value.id,
        'name': value.name,
        'native_resource_path': value.native_resource_path,
        'rank': value.rank,
        'updated_at': (value.updated_at.toISOString()),
    };
}

