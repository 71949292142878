import {
  ErrorResponse,
  IssueTrackersGetIssueTrackerSyncConfigContextEnum,
  IssueTrackerSyncConfig,
} from "@incident-io/api";
import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers";
import { useAPI } from "src/utils/swr";

export const useIssueSyncConfig = (
  context: IssueTemplateContextEnum,
): {
  syncConfig: IssueTrackerSyncConfig | undefined;
  syncConfigLoading: boolean;
  syncConfigError: ErrorResponse | undefined;
} => {
  const {
    data: syncData,
    isLoading: syncConfigLoading,
    error: syncConfigError,
  } = useAPI("issueTrackersGetIssueTrackerSyncConfig", {
    context:
      context as unknown as IssueTrackersGetIssueTrackerSyncConfigContextEnum,
  });
  const syncConfig = syncData?.sync_config;

  return { syncConfig, syncConfigLoading, syncConfigError };
};
