import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import React from "react";
import {
  Actor,
  IncidentActivityLogEntry,
  IncidentActivityLogEntryTypeEnum,
} from "src/contexts/ClientContext";

import { ActivityItemActionCreated } from "./ActivityItemActionCreated";
import { ActivityItemActionUpdated } from "./ActivityItemActionUpdated";
import { ActivityItemAtlassianStatuspageUpdate } from "./ActivityItemAtlassianStatuspageUpdate";
import { ActivityItemCustomFieldValueUpdate } from "./ActivityItemCustomFieldValueUpdate";
import { ActivityItemEscalate } from "./ActivityItemEscalate";
import { ActivityItemEscalationCreated } from "./ActivityItemEscalationCreated";
import { ActivityItemFollowUpCreated } from "./ActivityItemFollowUpCreated";
import { ActivityItemFollowUpUpdated } from "./ActivityItemFollowUpUpdated";
import { ActivityItemIncidentAttachmentAdded } from "./ActivityItemIncidentAttachmentAdded";
import { ActivityItemIncidentAttachmentRemoved } from "./ActivityItemIncidentAttachmentRemoved";
import { ActivityItemIncidentCallEnded } from "./ActivityItemIncidentCallEnded";
import { ActivityItemIncidentCallStarted } from "./ActivityItemIncidentCallStarted";
import { ActivityItemIncidentMembershipRevoked } from "./ActivityItemIncidentMembershipRevoked";
import { ActivityItemIncidentRename } from "./ActivityItemIncidentRename";
import { ActivityItemIncidentTimestampSet } from "./ActivityItemIncidentTimestampSet";
import { ActivityItemIncidentTypeChanged } from "./ActivityItemIncidentTypeChanged";
import { ActivityItemIncidentUpdateComponent } from "./ActivityItemIncidentUpdateComponent";
import { ActivityItemMerged } from "./ActivityItemMerged";
import { ActivityItemMicrosoftTeamsMessagePinned } from "./ActivityItemMicrosoftTeamsMessagePinned";
import { ActivityItemPagerdutyIncidentAcknowledged } from "./ActivityItemPagerdutyIncidentAcknowledged";
import { ActivityItemPagerdutyIncidentResolved } from "./ActivityItemPagerdutyIncidentResolved";
import { ActivityItemPagerdutyIncidentTriggered } from "./ActivityItemPagerdutyIncidentTriggered";
import { ActivityItemRoleUpdate } from "./ActivityItemRoleUpdate";
import { ActivityItemSlackImage } from "./ActivityItemSlackImage";
import { ActivityItemSlackInferSentry } from "./ActivityItemSlackInferSentry";
import { ActivityItemSlackPin } from "./ActivityItemSlackPin";
import { ActivityItemStatusChange } from "./ActivityItemStatusChange";
import { ActivityItemStatusPageIncidentLinked } from "./ActivityItemStatusPageIncidentLinked";
import { ActivityItemStatusPageIncidentUpdated } from "./ActivityItemStatusPageIncidentUpdated";
import { ActivityItemSummaryUpdate } from "./ActivityItemSummaryUpdate";
import { ActivityItemVisibilityChanged } from "./ActivityItemVisibilityChanged";

export type ActivityItemRenderProps = {
  // Title is usually like "thing happened"
  title: React.ReactNode;
  icon: IconEnum;
  // These are the general rules for colouring:
  // Red - incident reported / accepted / reopened
  // Green - incident resolved or closed
  // Blue - other incident updates
  // Cyan - pinned slack message
  // Pink - custom events
  // Purple - any other actions. This will be the majority of items!
  colour: ColorPaletteEnum;
  // The actor who did the thing. Only in very rare cases is this not present.
  actor?: Actor;
  // Contents that will be quoted underneath
  quotedContent?: React.ReactNode;
  // Contents that will appear at the bottom, not quoted (we do this for badges, links and error messages)
  unquotedContent?: React.ReactNode;
};

export type ActivityItemConstructor = (
  item: IncidentActivityLogEntry,
) => ActivityItemRenderProps | null;

export const ActivityItemTypeToComponentMap: {
  [key in IncidentActivityLogEntryTypeEnum]: ActivityItemConstructor | null;
} = {
  [IncidentActivityLogEntryTypeEnum.RoleUpdate]: ActivityItemRoleUpdate,
  [IncidentActivityLogEntryTypeEnum.SlackInferSentry]:
    ActivityItemSlackInferSentry,
  [IncidentActivityLogEntryTypeEnum.SlackPin]: ActivityItemSlackPin,
  [IncidentActivityLogEntryTypeEnum.SummaryUpdate]: ActivityItemSummaryUpdate,
  [IncidentActivityLogEntryTypeEnum.IncidentEscalate]: ActivityItemEscalate,
  [IncidentActivityLogEntryTypeEnum.ActionCreated]: ActivityItemActionCreated,
  [IncidentActivityLogEntryTypeEnum.ActionUpdated]: ActivityItemActionUpdated,
  [IncidentActivityLogEntryTypeEnum.FollowUpCreated]:
    ActivityItemFollowUpCreated,
  [IncidentActivityLogEntryTypeEnum.FollowUpUpdated]:
    ActivityItemFollowUpUpdated,
  [IncidentActivityLogEntryTypeEnum.StatusChange]: ActivityItemStatusChange,
  [IncidentActivityLogEntryTypeEnum.AtlassianStatuspageUpdate]:
    ActivityItemAtlassianStatuspageUpdate,
  [IncidentActivityLogEntryTypeEnum.IncidentUpdate]:
    ActivityItemIncidentUpdateComponent,
  [IncidentActivityLogEntryTypeEnum.CustomFieldValueUpdate]:
    ActivityItemCustomFieldValueUpdate,
  [IncidentActivityLogEntryTypeEnum.IncidentMembershipRevoked]:
    ActivityItemIncidentMembershipRevoked,
  [IncidentActivityLogEntryTypeEnum.IncidentVisibilityChanged]:
    ActivityItemVisibilityChanged,
  [IncidentActivityLogEntryTypeEnum.SlackImage]: ActivityItemSlackImage,
  [IncidentActivityLogEntryTypeEnum.IncidentRename]: ActivityItemIncidentRename,
  [IncidentActivityLogEntryTypeEnum.IncidentAttachmentAdded]:
    ActivityItemIncidentAttachmentAdded,
  [IncidentActivityLogEntryTypeEnum.IncidentAttachmentRemoved]:
    ActivityItemIncidentAttachmentRemoved,
  [IncidentActivityLogEntryTypeEnum.PagerdutyIncidentTriggered]:
    ActivityItemPagerdutyIncidentTriggered,
  [IncidentActivityLogEntryTypeEnum.PagerdutyIncidentAcknowledged]:
    ActivityItemPagerdutyIncidentAcknowledged,
  [IncidentActivityLogEntryTypeEnum.PagerdutyIncidentResolved]:
    ActivityItemPagerdutyIncidentResolved,
  [IncidentActivityLogEntryTypeEnum.IncidentTimestampSet]:
    ActivityItemIncidentTimestampSet,
  [IncidentActivityLogEntryTypeEnum.IncidentMerged]: ActivityItemMerged,
  [IncidentActivityLogEntryTypeEnum.IncidentTypeChanged]:
    ActivityItemIncidentTypeChanged,
  [IncidentActivityLogEntryTypeEnum.StatusPageIncidentLinked]:
    ActivityItemStatusPageIncidentLinked,
  [IncidentActivityLogEntryTypeEnum.StatusPageIncidentUpdated]:
    ActivityItemStatusPageIncidentUpdated,
  [IncidentActivityLogEntryTypeEnum.EscalationCreated]:
    ActivityItemEscalationCreated,
  [IncidentActivityLogEntryTypeEnum.IncidentCallStarted]:
    ActivityItemIncidentCallStarted,
  [IncidentActivityLogEntryTypeEnum.IncidentCallEnded]:
    ActivityItemIncidentCallEnded,
  [IncidentActivityLogEntryTypeEnum.MicrosoftTeamsPinnedChannelMessage]:
    ActivityItemMicrosoftTeamsMessagePinned,
  [IncidentActivityLogEntryTypeEnum.Handover]: null, // TODO [PINC-3164]: we used to show this when rolled up, what should we do now?
  [IncidentActivityLogEntryTypeEnum.PostmortemChanged]: () => null, // TODO [PINC-3198]: stop hiding this in the backend
  // These items are hidden on the backend interface, so we don't need front end components for them.
  [IncidentActivityLogEntryTypeEnum.ActionsSharedInChannel]: () => null,
  [IncidentActivityLogEntryTypeEnum.Scrub]: () => null,
  [IncidentActivityLogEntryTypeEnum.IncidentCallParticipantsUpdated]: () =>
    null,
  [IncidentActivityLogEntryTypeEnum.CallUrlChanged]: () => null,
  [IncidentActivityLogEntryTypeEnum.IncidentChannelJoin]: () => null,
  [IncidentActivityLogEntryTypeEnum.IncidentChannelLeave]: () => null,
  [IncidentActivityLogEntryTypeEnum.FollowUpsSharedInChannel]: () => null,
  [IncidentActivityLogEntryTypeEnum.UserIntentDeclared]: () => null,
  [IncidentActivityLogEntryTypeEnum.SlackMessage]: () => null, // Soon!
  [IncidentActivityLogEntryTypeEnum.MicrosoftTeamsMessage]: () => null, // Soon!
  [IncidentActivityLogEntryTypeEnum.IncidentCallTranscriptMessage]: () => null,
  [IncidentActivityLogEntryTypeEnum.IncidentCallTranscriptSummaryGenerated]:
    () => null,
  [IncidentActivityLogEntryTypeEnum.IncidentCallTranscriptKeyMomentGenerated]:
    () => null,
  [IncidentActivityLogEntryTypeEnum.IncidentCallTranscriptCurrentTopicGenerated]:
    () => null,
};
