import { Product } from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { UpsellBadge } from "@incident-shared/settings";
import { Callout, CalloutTheme, ContentBox, OrgAwareLink } from "@incident-ui";
import React from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { useProductAccess } from "src/hooks/useProductAccess";

export const IntegrationSettingsSection = <FormType extends FieldValues>({
  children,
  enabled,
  enabledID,
  label,
  helptext,
  disabled,
  isPro,
  isEnterprise,
  isFeatureGated,
  newUntil,
  requiredProduct,
}: {
  children?: React.ReactNode | React.ReactNode[];
  enabled: boolean;
  enabledID: Path<FormType>;
  label: string;
  helptext: React.ReactNode;
  disabled: boolean;
  isPro?: boolean;
  isEnterprise?: boolean;
  newUntil?: Date;
  isFeatureGated?: boolean;
  requiredProduct?: Product;
}): React.ReactElement => {
  const formMethods = useFormContext();
  const { hasProduct } = useProductAccess();

  const hasRequiredProduct = requiredProduct
    ? hasProduct(requiredProduct)
    : true;

  return (
    <ContentBox className="p-4">
      <ToggleV2
        formMethods={formMethods}
        toggleClassName="w-full"
        toggleLabelClassName="grow"
        name={enabledID}
        label={
          <>
            {label}{" "}
            <UpsellBadge
              isPro={isPro}
              isEnterprise={isEnterprise}
              newUntil={newUntil}
            />
          </>
        }
        isDisabledTooltipContent={
          !hasRequiredProduct && requiredProduct != null ? (
            <ProductRequiredMessage requiredProduct={requiredProduct} />
          ) : null
        }
        description={helptext}
        disabled={disabled || !hasRequiredProduct}
      />
      {enabled ? (
        isFeatureGated ? (
          <div className="w-full my-2">
            <Callout theme={CalloutTheme.Warning}>
              <div>
                Your current plan does not support this feature.
                <br />
                Visit your{" "}
                <OrgAwareLink
                  to="/settings/billing"
                  className="text-content-primary underline"
                >
                  Billing Settings
                </OrgAwareLink>{" "}
                to upgrade.
              </div>
            </Callout>
          </div>
        ) : (
          <div className="space-y-4">
            {children && <hr className="mt-4" />}
            {children}
          </div>
        )
      ) : null}
    </ContentBox>
  );
};
