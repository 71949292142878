import type { SVGProps } from "react";
import * as React from "react";
const SvgCompare = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M7.375 18h-4.5c-.497 0-.974-.21-1.326-.586A2.07 2.07 0 0 1 1 16V4c0-.53.198-1.04.55-1.414A1.818 1.818 0 0 1 2.874 2h4.5c.497 0 .974.21 1.326.586.351.375.549.884.549 1.414v12c0 .53-.198 1.04-.55 1.414A1.818 1.818 0 0 1 7.376 18ZM12.25 5.2h-1.5V4c0-.53.197-1.04.55-1.414A1.817 1.817 0 0 1 12.624 2h1.125v1.6h-1.125c-.1 0-.195.042-.265.117a.414.414 0 0 0-.11.283v1.2Zm6.75 0h-1.5V4a.414.414 0 0 0-.11-.283.364.364 0 0 0-.265-.117H16V2h1.125c.246 0 .49.052.718.152.227.1.434.248.608.434s.312.406.406.649c.095.242.143.502.143.765v1.2ZM17.125 18H16v-1.6h1.125c.1 0 .195-.042.265-.117A.414.414 0 0 0 17.5 16v-1.2H19V16c0 .263-.049.523-.143.765a2.015 2.015 0 0 1-.406.65 1.891 1.891 0 0 1-.608.433c-.228.1-.472.152-.718.152Zm-3.375 0h-1.125c-.497 0-.974-.21-1.326-.586A2.07 2.07 0 0 1 10.75 16v-1.2h1.5V16c0 .106.04.208.11.283.07.075.165.117.265.117h1.125V18Zm-1.5-11.2h-1.5v2.4h1.5V6.8Zm6.75 0h-1.5v2.4H19V6.8Zm-6.75 4h-1.5v2.4h1.5v-2.4Zm6.75 0h-1.5v2.4H19v-2.4Z" />
  </svg>
);
export default SvgCompare;
