/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FieldValue
 */
export interface FieldValue {
    /**
     * The array value to use when exporting this field
     * @type {Array<string>}
     * @memberof FieldValue
     */
    array_value?: Array<string>;
    /**
     * The key of the field to set
     * @type {string}
     * @memberof FieldValue
     */
    key: string;
    /**
     * The value to use when exporting this field
     * @type {string}
     * @memberof FieldValue
     */
    value?: string;
}

export function FieldValueFromJSON(json: any): FieldValue {
    return FieldValueFromJSONTyped(json, false);
}

export function FieldValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array_value': !exists(json, 'array_value') ? undefined : json['array_value'],
        'key': json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function FieldValueToJSON(value?: FieldValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array_value': value.array_value,
        'key': value.key,
        'value': value.value,
    };
}

