import type { SVGProps } from "react";
import * as React from "react";
const SvgCrowdstrike = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="crowdstrike_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 116.4 88.8"
    {...props}
  >
    <style>{".st0{fill-rule:evenodd;clip-rule:evenodd;fill:#fc0000}"}</style>
    <g id="crowdstrike_svg__Page-1">
      <g id="crowdstrike_svg__Home-v4.1" transform="translate(-26 -22)">
        <g id="crowdstrike_svg__CS-Logo" transform="translate(26 22)">
          <path
            id="crowdstrike_svg__Fill-110"
            d="M113.3 71.7c-2.6-.2-7.1-.8-12.9 1.8-5.7 2.7-8 2.8-10.8 2.5.8 1.4 2.5 3.3 7.7 3.7 5.2.3 7.8.5 5 6.6.1-1.8-.4-5.4-5.6-4.8s-6.4 5-.8 7.1c-1.8.3-5.7.5-8.4-6.1-1.9.8-4.8 2.3-10.2-1.5 1.9.6 4.2.7 4.2.7-4.7-2.1-9.3-6-12.1-9.7 2.3 1.6 4.8 3.2 7.4 3.5-3-3.4-10-10.3-18.6-17.3 5.5 3.3 12.2 8.6 23 7.4 10.9-1.2 18.2-3.5 32.1 6.1"
            className="st0"
          />
          <path
            id="crowdstrike_svg__Fill-112"
            d="M67.4 70.4c-7.3-2.7-8.8-3.3-18.2-5.3-9.3-2.1-18.5-6.3-24.7-13 4.3 2.8 13.2 8.3 22.3 7.7-1.4-1.8-3.9-3.1-7-4.5 3.4.7 13.8 3 27.6 15.1"
            className="st0"
          />
          <path
            id="crowdstrike_svg__Fill-118"
            d="M104.1 64.3c6.4.6 6.1 1.5 6.1 3.1-2.7-2-6.1-3.1-6.1-3.1M65.5 31.2C37.9 23.3 26.9 13.4 18.4 3.1c3.9 11.9 13.1 16.2 23 24.2s10.5 12.3 13.4 17.1c6.5 10.6 7.5 12.3 14 16.9 7.6 5 16.8 1.6 26.9 3.2s18.4 9.2 20.2 12.1c2.1-3.7-2.9-9.1-4.3-10.5.7-4.9-10.9-7.1-15.4-8.7-.9-.3-3-.8-1.2-5.2 2.5-6.1 5.2-11.4-29.5-21"
            className="st0"
          />
          <path
            id="crowdstrike_svg__Fill-114"
            d="M52.1 45.9c-1.8-4.9-4.9-11.1-19.9-20.4C24.8 20.8 14.1 15 0 0c1 4 5.5 14.5 27.9 28.2 7.4 4.9 17 7.9 24.2 17.7"
            className="st0"
          />
          <path
            id="crowdstrike_svg__Fill-116"
            d="M52.1 55.1c-1.7-4.1-5.3-9.4-19-16.9-6.4-3.6-17.2-9.2-27-19.8.9 3.8 5.4 12.2 24.9 22.7 5.4 3.1 14.5 5.9 21.1 14"
            className="st0"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCrowdstrike;
