import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Heading,
  IconEnum,
} from "@incident-ui";

export const HomeHeading = ({
  title,
  resourceCount,
  seeAllLink,
  accessory,
}: {
  title: string | React.ReactNode;
  resourceCount?: { showing: number; showingMax: boolean };
  seeAllLink?: {
    title: string;
    href: string;
    analyticsTrackingId: string;
  };
  accessory?: React.ReactNode;
}) => {
  return (
    <div className="flex justify-between px-4 md:px-8 flex-wrap gap-2">
      <div className="flex space-x-2 align-bottom">
        <Heading level={2} size="medium">
          {title}
        </Heading>
        {resourceCount && resourceCount.showing > 0 && (
          <Badge
            theme={
              resourceCount.showingMax
                ? BadgeTheme.Warning
                : BadgeTheme.Secondary
            }
            className="px-2"
          >
            {resourceCount.showingMax
              ? `${resourceCount.showing}+`
              : resourceCount.showing}
          </Badge>
        )}
        {seeAllLink && (
          <div className="flex gap-2 md:gap-4 text-sm items-center flex-wrap">
            <Button
              icon={IconEnum.ExternalLink}
              title={seeAllLink.title}
              href={seeAllLink.href}
              openInNewTab={false}
              theme={ButtonTheme.Naked}
              className="text-content-tertiary transition"
              analyticsTrackingId={seeAllLink.analyticsTrackingId}
            />
          </div>
        )}
      </div>
      {accessory && <div className="flex space-x-3">{accessory}</div>}
    </div>
  );
};
