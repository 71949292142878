import type { SVGProps } from "react";
import * as React from "react";
const SvgHistory = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 5a.75.75 0 0 0-.75.75V10c0 .246.121.477.323.617l3.25 2.25a.747.747 0 0 0 1.043-.19.749.749 0 0 0-.19-1.043l-2.927-2.026V5.751a.75.75 0 0 0-.75-.75L10 5Z"
    />
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8a.75.75 0 0 0 1.5 0c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5a6.48 6.48 0 0 1-5.328-2.801l1.457.202a.75.75 0 0 0 .205-1.487l-2.944-.407a.753.753 0 0 0-.845.64l-.408 2.945a.75.75 0 1 0 1.486.206l.116-.834A7.978 7.978 0 0 0 10 18c4.411 0 8-3.589 8-8s-3.589-8-8-8Z"
    />
  </svg>
);
export default SvgHistory;
