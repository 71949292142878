/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationTimelineItem,
    EscalationTimelineItemFromJSON,
    EscalationTimelineItemFromJSONTyped,
    EscalationTimelineItemToJSON,
} from './EscalationTimelineItem';
import {
    TimelineGap,
    TimelineGapFromJSON,
    TimelineGapFromJSONTyped,
    TimelineGapToJSON,
} from './TimelineGap';

/**
 * 
 * @export
 * @interface EscalationTimelineElement
 */
export interface EscalationTimelineElement {
    /**
     * 
     * @type {TimelineGap}
     * @memberof EscalationTimelineElement
     */
    timeline_gap?: TimelineGap;
    /**
     * 
     * @type {EscalationTimelineItem}
     * @memberof EscalationTimelineElement
     */
    timeline_item?: EscalationTimelineItem;
    /**
     * Timestamp of when this element occurred
     * @type {Date}
     * @memberof EscalationTimelineElement
     */
    timestamp: Date;
    /**
     * Type of the timeline element
     * @type {string}
     * @memberof EscalationTimelineElement
     */
    type: EscalationTimelineElementTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationTimelineElementTypeEnum {
    TimelineItem = 'timeline_item',
    TimeGap = 'time_gap'
}

export function EscalationTimelineElementFromJSON(json: any): EscalationTimelineElement {
    return EscalationTimelineElementFromJSONTyped(json, false);
}

export function EscalationTimelineElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationTimelineElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_gap': !exists(json, 'timeline_gap') ? undefined : TimelineGapFromJSON(json['timeline_gap']),
        'timeline_item': !exists(json, 'timeline_item') ? undefined : EscalationTimelineItemFromJSON(json['timeline_item']),
        'timestamp': (new Date(json['timestamp'])),
        'type': json['type'],
    };
}

export function EscalationTimelineElementToJSON(value?: EscalationTimelineElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_gap': TimelineGapToJSON(value.timeline_gap),
        'timeline_item': EscalationTimelineItemToJSON(value.timeline_item),
        'timestamp': (value.timestamp.toISOString()),
        'type': value.type,
    };
}

