"use client";

import {
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentIncident,
} from "@incident-io/api";
import cx from "classnames";
import { groupBy, orderBy, sortBy } from "lodash";
import { DateTime } from "luxon";

import { COMPONENT_STATUSES } from "../../helpers";
import { useUIContext } from "../../UIContext";
import { useTranslations } from "../../use-translations";
import { ComponentStatusIcon } from "../Icons/ComponentStatusIcon";
import { Spinner } from "../Spinner/Spinner";
import { startAtFrom } from "../SystemStatus/helpers";
import { useNow, useTimeMaths } from "../TimeContext";
import { TimePicker } from "../TimePicker/TimePicker";
import styles from "./IncidentList.module.scss";

export const IncidentList = ({
  dataAvailableSince,
  isLoading,
  endAt,
  setEndAt,
  incidents,
}: {
  dataAvailableSince: DateTime;
  isLoading: boolean;
  endAt: DateTime;
  setEndAt: (endAt: DateTime) => void;
  incidents: StatusPageContentIncident[];
}) => {
  const { InternalLink } = useUIContext();

  // Avoid re-rendering due to a not-real change
  const startAt = useTimeMaths(startAtFrom(endAt));
  const now = useNow(10 * 1000, "minute");

  return (
    <div className="flex flex-col gap-6">
      <div className="border-b border-slate-100 dark:border-slate-800 pb-2">
        <TimePicker
          startAt={startAt}
          endAt={endAt}
          setEndAt={setEndAt}
          now={now}
          dataAvailableSince={dataAvailableSince}
          showPicker={true}
          translationsNamespace="IncidentHistory"
          isHistory
        />
      </div>

      <IncidentListInner
        isLoading={isLoading}
        incidents={incidents}
        startAt={startAt}
        endAt={endAt}
      />
    </div>
  );
};

type IncidentWithLastUpdatedDate = StatusPageContentIncident & {
  lastUpdatedDate: Date;
};

const IncidentListInner = ({
  isLoading,
  incidents,
  startAt,
  endAt,
}: {
  isLoading: boolean;
  incidents: StatusPageContentIncident[];
  startAt: DateTime;
  endAt: DateTime;
}) => {
  const t = useTranslations("IncidentHistory");

  const incidentsWithLastUpdatedDate = incidents.map((incident) => ({
    ...incident,
    lastUpdatedDate: incidentLastUpdatedDate(incident),
  }));
  const orderedIncidents = orderBy(
    incidentsWithLastUpdatedDate,
    (incident) => incident.lastUpdatedDate,
    "desc",
  );

  // Group incidents by month
  const groupedIncidents = groupBy(orderedIncidents, (incident) => {
    const d = new Date(incident.lastUpdatedDate);
    return d.toLocaleString("default", { month: "long" });
  });
  // Show all months in the range, regardless of whether there are incidents
  const monthsToDisplay = getMonthsInRange(startAt, endAt);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center gap-4 pt-16">
        <Spinner />
        <div className="text-content-secondary dark:text-slate-200 text-sm-normal">
          {t("loading")}
        </div>
      </div>
    );
  }

  return (
    <>
      {monthsToDisplay.map((month) => {
        return (
          <div key={month} className="flex flex-col gap-6">
            <IncidentListMonthGroup
              incidents={groupedIncidents[month]}
              month={month}
            />
          </div>
        );
      })}
    </>
  );
};

const getMonthsInRange = (startDate: DateTime, endDate: DateTime) => {
  const months: string[] = [];

  // Normalize dates to first of the month to handle partial months
  const currentDate = startDate.toJSDate();
  currentDate.setDate(1);
  const lastDate = endDate.toJSDate();
  lastDate.setDate(1);

  while (currentDate <= lastDate) {
    months.push(currentDate.toLocaleString("default", { month: "long" }));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months.reverse();
};

const IncidentListMonthGroup = ({
  incidents,
  month,
}: {
  incidents: IncidentWithLastUpdatedDate[];
  month: string;
}) => {
  const t = useTranslations("IncidentHistory");

  if (!incidents || incidents.length === 0) {
    return (
      <div className="pb-6 border-b border-slate-100 dark:border-slate-800">
        <div className="flex justify-between items-center">
          <div className="text-sm-bold text-content-primary dark:text-slate-50">
            {month}
          </div>
          <div className="flex gap-2 items-center">
            <ComponentStatusIcon
              componentStatus={
                StatusPageAffectedComponentStatusEnum.Operational
              }
              className="h-3"
            />
            <div className="text-sm-med text-content-secondary dark:text-slate-200">
              {t("no_incidents")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Group incidents by day
  const groupedIncidents = groupBy(incidents, (incident) => {
    const d = new Date(incident.lastUpdatedDate);
    return d.toDateString();
  });

  return (
    <>
      <div className="text-sm-bold text-content-primary dark:text-slate-50">
        {month}
      </div>
      {Object.keys(groupedIncidents).map((day) => {
        return (
          <IncidentListDayGroup
            incidents={groupedIncidents[day]}
            date={day}
            key={day}
          />
        );
      })}
    </>
  );
};

const IncidentListDayGroup = ({
  incidents,
  date,
}: {
  incidents: StatusPageContentIncident[];
  date: string;
}) => {
  const publishedDate = new Date(date);

  return (
    <div className="border-b border-slate-100 dark:border-slate-800 pb-4">
      <div className="flex gap-3">
        {/* Incident date e.g. 24 Tue */}
        <div className="flex items-stretch gap-1 shrink-0 py-3 w-12">
          <div className="text-content-primary dark:text-slate-50 text-sm-bold self-start">
            {publishedDate.toLocaleString("default", { day: "2-digit" })}
          </div>
          <div className="text-content-tertiary dark:text-slate-500 text-xs-med self-start mt-[3px]">
            {publishedDate.toLocaleString("default", { weekday: "short" })}
          </div>
        </div>
        <div className="flex flex-col min-w-0 grow">
          {incidents.map((incident) => (
            <IncidentListItem incident={incident} key={incident.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

const IncidentListItem = ({
  incident,
}: {
  incident: StatusPageContentIncident;
}) => {
  const { InternalLink } = useUIContext();

  // Status colour based on highest component impact
  const componentStatus = componentStatusForIncident(incident);

  const orderedUpdates = orderBy(
    incident.updates,
    (update) => update.published_at,
    "desc",
  );
  const mostRecentUpdate = orderedUpdates[0];
  const publishedDate = new Date(mostRecentUpdate.published_at);

  return (
    <InternalLink
      to={`/incidents/${incident.id}`}
      analyticsTrackingId={"incident-list-view-more"}
      className="flex gap-3 hover:bg-surface-secondary hover:dark:bg-surface-invert rounded-lg py-3"
    >
      {/* Impact coloured rectangle */}
      <div
        className={`w-1 ml-3 shrink-0 ${componentStatus.background} rounded`}
      />
      <div className="flex flex-col gap-1 shrink">
        {/* Incident name and time */}
        <div className="flex items-stretch gap-3">
          <div className="text-sm-med text-content-primary dark:text-slate-50 self-start">
            {incident.name}
          </div>
          <div className="text-xs-med text-content-tertiary dark:text-slate-500 self-start mt-[3px]">
            {publishedDate.toLocaleString("default", {
              hour: "numeric",
              minute: "numeric",
            })}
          </div>
        </div>
        {/* Latest update */}
        <div
          className={cx(
            styles.truncateContent,
            "text-sm-normal text-content-secondary dark:text-slate-200",
          )}
        >
          {mostRecentUpdate.message_string}
        </div>
      </div>
    </InternalLink>
  );
};

const componentStatusForIncident = (incident: StatusPageContentIncident) => {
  const sortedImpacts = sortBy(
    incident.component_impacts,
    (impact) => -COMPONENT_STATUSES[impact.status].rank,
  );
  const worstImpact = sortedImpacts[0];
  if (!worstImpact) {
    return COMPONENT_STATUSES.operational;
  }
  return COMPONENT_STATUSES[worstImpact.status];
};

const incidentLastUpdatedDate = (incident: StatusPageContentIncident) => {
  const orderedUpdates = orderBy(
    incident.updates,
    (update) => update.published_at,
    "desc",
  );
  return new Date(orderedUpdates[0].published_at);
};
