/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequirementsTemplate,
    RequirementsTemplateFromJSON,
    RequirementsTemplateFromJSONTyped,
    RequirementsTemplateToJSON,
} from './RequirementsTemplate';

/**
 * 
 * @export
 * @interface RequirementsTemplatesForType
 */
export interface RequirementsTemplatesForType {
    /**
     * The policy type this relates to
     * @type {string}
     * @memberof RequirementsTemplatesForType
     */
    policy_type: RequirementsTemplatesForTypePolicyTypeEnum;
    /**
     * List of templates for this policy type
     * @type {Array<RequirementsTemplate>}
     * @memberof RequirementsTemplatesForType
     */
    templates: Array<RequirementsTemplate>;
}

/**
* @export
* @enum {string}
*/
export enum RequirementsTemplatesForTypePolicyTypeEnum {
    Debrief = 'debrief',
    FollowUp = 'follow_up',
    PostMortem = 'post_mortem'
}

export function RequirementsTemplatesForTypeFromJSON(json: any): RequirementsTemplatesForType {
    return RequirementsTemplatesForTypeFromJSONTyped(json, false);
}

export function RequirementsTemplatesForTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequirementsTemplatesForType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy_type': json['policy_type'],
        'templates': ((json['templates'] as Array<any>).map(RequirementsTemplateFromJSON)),
    };
}

export function RequirementsTemplatesForTypeToJSON(value?: RequirementsTemplatesForType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy_type': value.policy_type,
        'templates': ((value.templates as Array<any>).map(RequirementsTemplateToJSON)),
    };
}

