import type { SVGProps } from "react";
import * as React from "react";
const SvgIncident = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.244 12.049c0 3.383-2.808 6.126-6.272 6.126-3.464 0-6.272-2.743-6.272-6.126 0-1.66.702-2.978 1.774-4.27.89-1.072 3.087-3.91 3.778-5.476a.474.474 0 0 1 .78-.161c.591.653 1.319 2.08 1.557 3.7.048.33.068.599.084.813.034.459.05.663.287.663.373 0 .618-.567.671-1.153.035-.375.406-.5.662-.375 1.233.604 2.49 3.478 2.794 4.888.095.442.157.9.157 1.37Zm-6.272 2.744c.964 0 1.745-.74 1.745-1.654 0-1.456-1.073-2.498-1.607-2.814-.036-.021-.054-.032-.103-.03a.202.202 0 0 0-.115.048c-.035.034-.045.073-.063.152-.095.399-.393.684-.707.983-.432.413-.894.854-.894 1.661 0 .914.78 1.654 1.744 1.654Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIncident;
