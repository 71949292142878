/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsGitLabInstallRequestBody,
    IntegrationsGitLabInstallRequestBodyFromJSON,
    IntegrationsGitLabInstallRequestBodyToJSON,
    IntegrationsGitLabReceiveWebhookRequestBody,
    IntegrationsGitLabReceiveWebhookRequestBodyFromJSON,
    IntegrationsGitLabReceiveWebhookRequestBodyToJSON,
    IntegrationsGitLabShowResponseBody,
    IntegrationsGitLabShowResponseBodyFromJSON,
    IntegrationsGitLabShowResponseBodyToJSON,
} from '../models';

export interface IntegrationsGitLabInstallRequest {
    installRequestBody: IntegrationsGitLabInstallRequestBody;
}

export interface IntegrationsGitLabReceiveWebhookRequest {
    receiveWebhookRequestBody: IntegrationsGitLabReceiveWebhookRequestBody;
    xGitlabToken?: string;
}

/**
 * 
 */
export class IntegrationsGitLabApi extends runtime.BaseAPI {

    /**
     * Install GitLab using a personal access token
     * Install Integrations - GitLab
     */
    async integrationsGitLabInstallRaw(requestParameters: IntegrationsGitLabInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installRequestBody === null || requestParameters.installRequestBody === undefined) {
            throw new runtime.RequiredError('installRequestBody','Required parameter requestParameters.installRequestBody was null or undefined when calling integrationsGitLabInstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/git_lab`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsGitLabInstallRequestBodyToJSON(requestParameters.installRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Install GitLab using a personal access token
     * Install Integrations - GitLab
     */
    async integrationsGitLabInstall(requestParameters: IntegrationsGitLabInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGitLabInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from GitLab
     * ReceiveWebhook Integrations - GitLab
     */
    async integrationsGitLabReceiveWebhookRaw(requestParameters: IntegrationsGitLabReceiveWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.receiveWebhookRequestBody === null || requestParameters.receiveWebhookRequestBody === undefined) {
            throw new runtime.RequiredError('receiveWebhookRequestBody','Required parameter requestParameters.receiveWebhookRequestBody was null or undefined when calling integrationsGitLabReceiveWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xGitlabToken !== undefined && requestParameters.xGitlabToken !== null) {
            headerParameters['X-Gitlab-Token'] = String(requestParameters.xGitlabToken);
        }

        const response = await this.request({
            path: `/webhooks/gitlab`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsGitLabReceiveWebhookRequestBodyToJSON(requestParameters.receiveWebhookRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from GitLab
     * ReceiveWebhook Integrations - GitLab
     */
    async integrationsGitLabReceiveWebhook(requestParameters: IntegrationsGitLabReceiveWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGitLabReceiveWebhookRaw(requestParameters, initOverrides);
    }

    /**
     * Show GitLab integration information
     * Show Integrations - GitLab
     */
    async integrationsGitLabShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsGitLabShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/git_lab`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsGitLabShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show GitLab integration information
     * Show Integrations - GitLab
     */
    async integrationsGitLabShow(initOverrides?: RequestInit): Promise<IntegrationsGitLabShowResponseBody> {
        const response = await this.integrationsGitLabShowRaw(initOverrides);
        return await response.value();
    }

    /**
     * Remove GitLab OAuth token
     * Uninstall Integrations - GitLab
     */
    async integrationsGitLabUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/git_lab`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove GitLab OAuth token
     * Uninstall Integrations - GitLab
     */
    async integrationsGitLabUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGitLabUninstallRaw(initOverrides);
    }

}
