// For when you add a tab to Microsoft Teams

import { GenericErrorMessage, Icon, IconEnum, Txt } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { assertUnreachable } from "src/utils/utils";

enum ConfigurationState {
  Loading,
  Success,
  Failure,
  Done,
}

export const ConfigureMsTeamsTabRoute = (): React.ReactElement => {
  const [configState, setConfigState] = useState<ConfigurationState>(
    ConfigurationState.Loading,
  );

  useEffect(() => {
    microsoftTeams.app
      .initialize()
      .then(() => {
        microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
          microsoftTeams.pages.config
            .setConfig({
              contentUrl: `https://${window.location.host}/login-msteams-tab`,
              websiteUrl: `https://${window.location.host}/login`,
              entityId: "incident-io",
              suggestedDisplayName: "incident.io",
            })
            .then(() => {
              saveEvent.notifySuccess();
            })
            .catch(() => {
              saveEvent.notifyFailure("Something went wrong.");
            });
        });

        microsoftTeams.app.notifyAppLoaded();
        microsoftTeams.app.notifySuccess();
        microsoftTeams.pages.config.setValidityState(true);
        setConfigState(ConfigurationState.Success);
        setTimeout(() => setConfigState(ConfigurationState.Done), 1000);
      })
      .catch((error) => {
        console.error(error);
        setConfigState(ConfigurationState.Failure);
      });
  }, []);

  switch (configState) {
    case ConfigurationState.Loading:
      return (
        <FullPageLoader
          subtitle={
            <Txt className="text-content-tertiary">
              Configuring your Microsoft Teams tab...
            </Txt>
          }
        />
      );
    case ConfigurationState.Success:
      return (
        <FullPageLoader
          subtitle={
            <Txt className="text-green-600 flex flex-row">
              <Icon id={IconEnum.Tick} /> Tab configured succesfully!
            </Txt>
          }
          stopThrobbing
        />
      );
    case ConfigurationState.Done:
      return (
        <FullPageLoader
          subtitle={
            <Txt className="text-content-tertiary">
              Click &ldquo;Save&rdquo; to continue adding the tab.
            </Txt>
          }
          stopThrobbing
        />
      );
    case ConfigurationState.Failure:
      return (
        <GenericErrorMessage description="This page can only be loaded from within Microsoft Teams." />
      );
    default:
      assertUnreachable(configState);
      return <></>;
  }
};
