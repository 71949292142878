import { CopilotRequest } from "@incident-io/query-api";
import { Icon, IconEnum, IconSize, TabPane, TabSection } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useState } from "react";

import { JsonViewer } from "./JsonViewer";
import { TextViewer } from "./TextViewer";
import { YAMLViewer } from "./YamlViewer";

export const CopilotInteractionTrace = ({
  requests,
}: {
  requests: CopilotRequest[];
}) => {
  // Sort requests by start time
  requests = requests.sort(
    (a, b) =>
      a.created_at.getTime() -
      a.duration_seconds * 1000 -
      (b.created_at.getTime() - b.duration_seconds * 1000),
  );

  const startTime = Math.min(
    ...requests.map((r) => r.created_at.getTime() - r.duration_seconds * 1000),
  );
  const endTime = Math.max(...requests.map((r) => r.created_at.getTime()));
  const totalDuration = endTime - startTime;

  const getBarStyles = (request: CopilotRequest) => {
    const requestStart =
      request.created_at.getTime() - request.duration_seconds * 1000;
    const left = ((requestStart - startTime) / totalDuration) * 100;
    const width = ((request.duration_seconds * 1000) / totalDuration) * 100;

    return {
      left: `${left}%`,
      width: `${width}%`,
    };
  };

  const BAR_HEIGHT = 32; // Original bar height
  const ROW_HEIGHT = 48; // Height including spacing
  const [showingEval, setShowingEval] = useState<CopilotRequest | null>(null);

  return (
    <>
      {showingEval && (
        <Drawer onClose={() => setShowingEval(null)} width="large">
          <DrawerContents>
            <DrawerTitle
              title={showingEval.prompt}
              onClose={() => setShowingEval(null)}
            />
            <DrawerBody className="overflow-y-auto">
              <TabSection
                withIndicator
                defaultTab="rendered"
                tabBarClassName="w-full border-b"
                tabs={[
                  {
                    id: "eval",
                    label: "Eval",
                  },
                  {
                    id: "messages",
                    label: "Completion messages",
                  },
                ]}
              >
                <TabPane tabId="eval">
                  <div className="py-6 flex flex-col gap-6">
                    <TextViewer title="Trace ID" content={showingEval.trace} />
                    <YAMLViewer title="Response" yaml={showingEval.response} />
                    <YAMLViewer title="Eval case" yaml={showingEval.eval} />
                  </div>
                </TabPane>
                <TabPane tabId="messages">
                  <div className="py-6 flex flex-col gap-6">
                    {(showingEval.raw_messages || []).map((msg, idx) => {
                      if (msg.role === "tool" && msg.tool_call) {
                        return (
                          <JsonViewer
                            key={idx}
                            title={msg.role}
                            jsonStr={msg.tool_call}
                          />
                        );
                      } else {
                        return (
                          <TextViewer
                            key={idx}
                            title={msg.role}
                            content={msg.content}
                          />
                        );
                      }
                    })}
                  </div>
                </TabPane>
              </TabSection>
            </DrawerBody>
          </DrawerContents>
        </Drawer>
      )}
      <div className="w-full border-t border-slate-100">
        <div className="flex w-full gap-2">
          {/* Sidebar */}
          <div className="w-64 flex-shrink-0 pr-4 border-r border-slate-100">
            {requests.map((request) => (
              <div
                key={request.id}
                className="text-sm"
                style={{
                  height: `${ROW_HEIGHT}px`,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="font-medium truncate">{request.prompt}</div>
                <div className="text-xs text-slate-500">
                  {request.duration_seconds.toFixed(2)}s | $
                  {(request.cost_cents / 100).toFixed(4)}
                </div>
              </div>
            ))}
          </div>

          {/* Timeline */}
          <div className="flex-grow">
            <div
              className="relative"
              style={{ height: `${requests.length * ROW_HEIGHT}px` }}
            >
              {requests.map((request, index) => {
                const styles = getBarStyles(request);
                const verticalCenter = (ROW_HEIGHT - BAR_HEIGHT) / 2;

                return (
                  <div
                    key={request.id}
                    className="absolute"
                    style={{
                      ...styles,
                      height: `${BAR_HEIGHT}px`,
                      top: `${index * ROW_HEIGHT + verticalCenter}px`,
                    }}
                  >
                    <div
                      className="h-full bg-blue-500/50 hover:bg-blue-500/75 transition group rounded cursor-pointer relative flex items-center px-2 text-white"
                      onClick={() => setShowingEval(request)}
                    >
                      <span className="text-xs font-medium truncate">
                        {request.prompt}
                      </span>
                      <Icon
                        id={IconEnum.View}
                        size={IconSize.Small}
                        className="opacity-0 group-hover:opacity-100 transition ml-1"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
