import { UserWithRoles } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  isHolidayEntry,
  isHolidayPublicEntry,
  isScheduleEntry,
  isScheduleOverride,
  TimelineEntry,
} from "@incident-shared/schedules/ScheduleOverview/types";
import {
  ColorPalette,
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { useUniqueColorGenerator } from "@incident-shared/utils/uniqueColorContext";
import { Avatar, IconSize } from "@incident-ui";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { closeTooltips, Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { DateTime } from "luxon";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { sendWarningToSentry } from "src/utils/utils";

import { CopyDebugID } from "../../../../utils/ShowDebugIDProvider";
import stripeBackground from "./hatched.png";
import styles from "./ScheduleEntryComponent.module.scss";

type TooltipProps =
  | {
      enableTooltip: boolean;
      enableCreateOverride?: boolean;
      entry: TimelineEntry;
      timezone: string;
    }
  | {
      enableTooltip?: never | false;
      entry?: never;
      timezone?: never;
      enableCreateOverride?: boolean; // you can only create overrides if you have a tooltip
    };

export const ScheduleEntryComponent = ({
  userId: userIdProp,
  user: userProp,
  width,
  className,
  roundedLeft = true,
  roundedRight = true,
  enableTooltip,
  isDraft,
  clipContent,
  entry,
  timezone,
  enableCreateOverride,
  height = "28px",
  appearanceMode = "timeline",
}: {
  height?: string;
  className?: string;
  width?: string; // capturing the width separately and using it in styles as tailwind is not accepting it as a className always
  roundedLeft?: boolean;
  roundedRight?: boolean;
  enableTooltip?: boolean;
  clipContent?: boolean;
  isDraft?: boolean;
  // If user is provided, we'll use it, or we'll look-up the user, or we'll show no one on call
  user?: UserWithRoles;
  userId?: string;
  appearanceMode?: "timeline" | "calendar";
} & TooltipProps) => {
  const navigate = useOrgAwareNavigate();

  const colorGenerator = useUniqueColorGenerator();

  const { data: userResp, isLoading } = useAPI(
    isHolidayPublicEntry(entry) || userIdProp === "NOBODY" || userProp
      ? null
      : "usersShow",
    {
      id: userIdProp ?? "",
    },
  );

  const Wrapper = enableCreateOverride ? Button : "div";

  // We might be displaying 'nobody' or a public holiday, so remember this user might never get set.
  const maybeUser = userProp ?? userResp?.user;

  let displayName: string;
  let colorPalette: ColorPalette;
  if (isHolidayEntry(entry)) {
    displayName = entry.name;
    if (isHolidayPublicEntry(entry)) {
      colorPalette = getColorPalette(ColorPaletteEnum.Slate);
    } else {
      colorPalette = colorGenerator(entry.user_id);
    }
  } else {
    if (userIdProp === "NOBODY") {
      colorPalette = getColorPalette(ColorPaletteEnum.Slate);
      displayName = "No one";
    } else {
      const userIsDeactivated = (maybeUser?.state as string) === "deactivated";

      displayName = userIsDeactivated
        ? maybeUser?.name + " (deactivated)"
        : maybeUser?.name ?? "";
      colorPalette = colorGenerator(maybeUser?.id ?? "");
    }
  }

  const { background, text, hover, hoverBg } = colorPalette;

  let overrideId: string | undefined;
  if (isScheduleEntry(entry)) {
    overrideId = entry.override_id;
  } else if (isScheduleOverride(entry)) {
    // 'user_id' is a discrimnator to work out if we've got a ScheduleEntry or a ScheduleOverride
    // if it's an override, then we'll have 'user_id'.
    overrideId = entry.id;
  } else {
    // Otherwise, it must be a holiday, there's no override to edit.
    overrideId = undefined;
  }

  let timeLabel: string | undefined;
  if (appearanceMode === "calendar" && entry) {
    timeLabel = isRoughlyAllDay(entry, timezone)
      ? "All day"
      : formatTimestampLocale({
          timeStyle: "short",
          timeZone: timezone,
          timestamp: entry.start_at,
        });
  }

  return (
    <Tooltip
      key={`tooltip-${userIdProp}-${entry?.start_at.toISOString()}`}
      content={
        enableTooltip && entry ? (
          <ScheduledShiftTooltip
            user={maybeUser}
            entry={entry}
            userId={userIdProp ?? maybeUser?.id}
            timezone={timezone || ""}
            enableCreateOverride={enableCreateOverride}
            overrideId={overrideId}
          />
        ) : null
      }
      bubbleProps={{
        className: "!p-0",
      }}
      delayDuration={180}
      light
      followMousePosition
      noMaxWidth
    >
      {/* Apply the width separately to the border, so that we don't include the border in width */}
      <Wrapper
        style={{ width, height }}
        className={tcx("box-border relative !no-underline !border-0", {
          "hover:cursor-default": !enableCreateOverride,
        })}
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId={"schedule-entry"}
        onClick={() => {
          if (enableCreateOverride) {
            if (entry) {
              navigateToOverrideForEntry(navigate, entry);
            } else {
              sendWarningToSentry("No entry found for override creation");
            }
          }
        }}
      >
        <div
          style={{
            minHeight: height,
          }}
          className={tcx(
            background,
            `flex flex-row justify-between items-center group min-w-full`,
            {
              "border-r-0": !roundedRight,
              "border-l-0": !roundedLeft,
              "rounded-r-md": roundedRight,
              "rounded-l-md": roundedLeft,
              [hover]: enableTooltip,
              [hoverBg]: enableTooltip,
              "animate-pulse": isDraft,
              "absolute left-0 right-0 top-0 bottom-0": clipContent,
            },
            className,
          )}
        >
          {overrideId && (
            <div
              style={{
                opacity: 0.6,
                backgroundImage: `url(${stripeBackground})`,
                backgroundSize: "32px 32px",
                mixBlendMode: "color-burn",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}

          {/* User name */}
          <div
            className={tcx(
              `text-xs text-clip whitespace-nowrap text-xs-bold overflow-hidden grow text-start`,
              text,
              {
                "mx-2": appearanceMode === "timeline",
                "mx-1": appearanceMode === "calendar",
              },
            )}
          >
            {isLoading ? null : displayName}
          </div>

          {timeLabel && (
            <div
              className={tcx(
                `text-xs text-clip whitespace-nowrap text-xs-medium shrink-0`,
                text,
                {
                  "mx-2": appearanceMode === "timeline",
                  "mx-1": appearanceMode === "calendar",
                },
              )}
            >
              {timeLabel}
            </div>
          )}
        </div>
      </Wrapper>
    </Tooltip>
  );
};

type ScheduleEntryTooltipProps = {
  entry: TimelineEntry;
  timezone: string;
  enableCreateOverride?: boolean;
  user: UserWithRoles | undefined;
  userId: string | undefined;
  overrideId: string | undefined;
};

const ScheduledShiftTooltip = ({
  entry,
  timezone,
  enableCreateOverride,
  user,
  userId,
  overrideId,
}: ScheduleEntryTooltipProps) => {
  const navigate = useOrgAwareNavigate();

  const isHoliday = isHolidayEntry(entry);
  const startAt = DateTime.fromJSDate(entry.start_at).setZone(timezone);
  const endAt = DateTime.fromJSDate(entry.end_at).setZone(timezone);

  return (
    <div
      className={tcx("flex flex-col py-1 rounded-lg min-w-[280px]", {
        [styles.overrideCard]: overrideId,
      })}
    >
      <div
        className={tcx({
          "bg-gradient-to-b from-transparent to-surface-primary rounded-lg":
            overrideId,
        })}
      >
        <div
          className={
            "flex flex-row flex-wrap items-center border-b border-stroke px-4 py-3 gap-x-2"
          }
        >
          {user && (
            <Avatar
              url={user?.avatar_url}
              name={user?.name}
              size={IconSize.XL}
              className={"inset-0 rounded-full border border-black/10"}
            />
          )}
          <div className={"flex flex-col justify-start"}>
            <div
              className={
                "text-content-primary font-medium max-w-[300px] truncate"
              }
            >
              {isHolidayEntry(entry)
                ? entry.name
                : userId === "NOBODY"
                ? "Nobody on call"
                : user?.name}
            </div>
          </div>
          <CopyDebugID id={userId ?? user?.id} />
        </div>
        <div className={"px-4 py-3"}>
          <div className={"flex flex-col space-y-2"}>
            <div className="flex-col justify-start items-end gap-1 inline-flex">
              <div className="self-stretch justify-between items-start gap-1 inline-flex">
                <div className="text-content-secondary text-sm font-medium">
                  {formatTimestampLocale({
                    dateStyle: "short",
                    timestamp: entry.start_at,
                    addWeekday: true,
                    timeZone: timezone,
                  })}
                </div>
                <div className="text-right text-content-secondary text-sm font-medium">
                  {startAt.hasSame(endAt, "day")
                    ? ""
                    : formatTimestampLocale({
                        dateStyle: "short",
                        timestamp: entry.end_at,
                        addWeekday: true,
                        timeZone: timezone,
                      })}
                </div>
              </div>
              <div className="self-stretch justify-center items-center gap-1 inline-flex">
                {isRoughlyAllDay(entry, timezone) ? (
                  <div className="grow shrink basis-0 text-content-primary text-sm font-medium">
                    All day
                  </div>
                ) : (
                  <>
                    <div className="grow shrink basis-0 text-content-primary text-sm font-medium">
                      {formatTimestampLocale({
                        timeStyle: "short",
                        timestamp: entry.start_at,
                        timeZone: timezone,
                      })}
                    </div>
                    <div className="text-center text-content-primary text-sm font-medium">
                      →
                    </div>
                    <div className="grow shrink basis-0 text-right text-content-primary text-sm font-medium">
                      {formatTimestampLocale({
                        timeStyle: "short",
                        timestamp: entry.end_at,
                        timeZone: timezone,
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>

            {isHoliday ? (
              <Button
                analyticsTrackingId={"create-override-tooltip"}
                className={"w-full flex justify-center"}
                onClick={() => navigateToOverrideForEntry(navigate, entry)}
              >
                Create override
              </Button>
            ) : enableCreateOverride ? (
              <ScheduleTooltipOverrideButtons
                entry={entry}
                overrideId={overrideId}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const ScheduleTooltipOverrideButtons = ({
  entry,
  overrideId,
}: {
  entry: TimelineEntry;
  overrideId: string | undefined;
}) => {
  const navigate = useOrgAwareNavigate();

  const isInThePast = entry.end_at.getTime() < Date.now();
  // We don't let you delete/edit overrides that are in the past
  if (isInThePast) {
    return null;
  }

  return (
    <div className="flex justify-between w-full space-x-2">
      <Button
        analyticsTrackingId={"create-override-tooltip"}
        className={"flex-1"}
        onClick={() => {
          navigateToOverrideForEntry(navigate, entry);
        }}
      >
        {(overrideId ? "Edit" : "Create") + " override"}
      </Button>
      {overrideId && (
        <Button
          analyticsTrackingId="delete-override-tooltip"
          onClick={() => {
            if (isScheduleEntry(entry) || isScheduleOverride(entry)) {
              navigate(
                `/on-call/schedules/${entry.schedule_id}/overrides/${overrideId}/delete`,
              );
            }
            closeTooltips();
          }}
          icon={IconEnum.Delete}
          theme={ButtonTheme.DestroySecondary}
          title=""
        />
      )}
    </div>
  );
};

const navigateToOverrideForEntry = (
  navigate: ReturnType<typeof useOrgAwareNavigate>,
  entry: TimelineEntry,
) => {
  if (isScheduleOverride(entry)) {
    navigate(
      `/on-call/schedules/${entry.schedule_id}/overrides/${entry.id}/edit`,
    );
  } else if (isScheduleEntry(entry)) {
    if (entry.override_id) {
      navigate(
        `/on-call/schedules/${entry.schedule_id}/overrides/${entry.override_id}/edit`,
      );
    } else {
      navigate(
        `/on-call/schedules/${
          entry.schedule_id
        }/overrides/create?initial_rotation_id=${
          entry.rotation_id
        }&initial_layer_id=${
          entry.layer_id
        }&initial_start_at=${entry.start_at.toISOString()}&initial_end_at=${entry.end_at.toISOString()}`,
      );
    }
  } else {
    navigate(
      `/on-call/schedules/${
        entry.schedule_id
      }/overrides/create?&initial_start_at=${entry.start_at.toISOString()}&initial_end_at=${entry.end_at.toISOString()}`,
    );
  }

  closeTooltips();
};

const isRoughlyAllDay = (
  entry: Pick<TimelineEntry, "start_at" | "end_at">,
  timezone: string | undefined,
) => {
  const start = DateTime.fromJSDate(entry.start_at).setZone(timezone);
  const end = DateTime.fromJSDate(entry.end_at).setZone(timezone);

  // If it's near enough to the start or end of the day, we'll call it all day.
  return (
    Math.abs(start.startOf("day").diff(start, "seconds").seconds) <= 90 &&
    Math.abs(end.endOf("day").diff(end, "seconds").seconds) <= 90
  );
};
