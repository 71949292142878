import {
  CatalogType,
  CatalogTypeCategoriesEnum as CatalogTypeCategoriesEnum,
  CatalogTypeModeEnum as CatalogTypeModeEnum,
} from "@incident-io/api";
import { useAPI } from "src/utils/swr";

export const useHasTypeOfCategory = (category: CatalogTypeCategoriesEnum) => {
  const {
    data: { catalog_types: types },
    isLoading: typesLoading,
    error: typesError,
  } = useAPI(
    "catalogListTypes",
    { includeCount: true },
    { fallbackData: { catalog_types: [] } },
  );

  return {
    allTypes: types,
    typesLoading,
    typesError,
    ...checkHasWizardTypeForCategory(types, category),
  };
};

const categoryToDefaultTypeName = {
  [CatalogTypeCategoriesEnum.Team]: 'Custom["Team"]',
  [CatalogTypeCategoriesEnum.Service]: 'Custom["Service"]',
};

const categoryToWizardName = {
  [CatalogTypeCategoriesEnum.Team]: "catalog-team-wizard",
  [CatalogTypeCategoriesEnum.Service]: "catalog-service-wizard",
};

export const checkHasWizardTypeForCategory = (
  catalogTypes: CatalogType[],
  category,
) => {
  const hasWizardAnnotation = (annotations: CatalogType["annotations"]) => {
    if (!annotations) return false;
    return annotations["incident.io/source"] === categoryToWizardName[category];
  };
  // Find the first type of this category that is manually managed.
  const allMatchingTypes = catalogTypes.filter(
    (type) =>
      (type.categories.includes(category) &&
        type.mode === CatalogTypeModeEnum.Manual) ||
      type.type_name === categoryToDefaultTypeName[category] ||
      hasWizardAnnotation(type.annotations),
  );

  return {
    allMatchingTypes,
    matchingType: allMatchingTypes.length > 0 ? allMatchingTypes[0] : undefined,
    hasMatchingType: allMatchingTypes.length > 0,
  };
};
