import type { SVGProps } from "react";
import * as React from "react";
const SvgSeverity = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.75 3h-.5a1.75 1.75 0 0 0-1.75 1.75v10.5c0 .966.784 1.75 1.75 1.75h.5a1.75 1.75 0 0 0 1.75-1.75V4.75A1.75 1.75 0 0 0 15.75 3ZM10.25 8h-.5A1.75 1.75 0 0 0 8 9.75v5.5c0 .966.784 1.75 1.75 1.75h.5A1.75 1.75 0 0 0 12 15.25v-5.5A1.75 1.75 0 0 0 10.25 8ZM4.75 12h-.5a1.75 1.75 0 0 0-1.75 1.75v1.5c0 .966.784 1.75 1.75 1.75h.5a1.75 1.75 0 0 0 1.75-1.75v-1.5A1.75 1.75 0 0 0 4.75 12Z"
    />
  </svg>
);
export default SvgSeverity;
