import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export enum BannerTheme {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error",
  Impersonation = "impersonation",
}

const ThemeFor: { [key in BannerTheme]: { main: string; icon: string } } = {
  [BannerTheme.Info]: {
    // Needs a bottom-border to separate it from our secondary nav
    main: "bg-surface-secondary text-content-primary border-b border-stroke",
    icon: "text-content-tertiary",
  },
  [BannerTheme.Success]: {
    main: "bg-green-surface text-green-800",
    icon: "text-green-500",
  },
  [BannerTheme.Warning]: {
    main: "bg-amber-surface text-amber-800",
    icon: "text-amber-500",
  },
  [BannerTheme.Error]: {
    main: "bg-red-surface text-red-800",
    icon: "text-red-500",
  },
  [BannerTheme.Impersonation]: {
    main: "bg-red-700 text-white w-full",
    icon: "",
  },
};

export type BannerProps = {
  theme?: BannerTheme;
  icon?: IconEnum;
  iconNode?: React.ReactNode;
  children: React.ReactNode;
  /** What should happen when you click the close button. Leave it out if the banner shouldn't be dismissible. */
  onDismiss?: () => void;
  /** The analytics ID to fire off when clicking onDismiss */
  analyticsTrackingId?: string;
  className?: string;
};
export function Banner({
  theme: themeKey = BannerTheme.Info,
  icon,
  iconNode,
  children,
  analyticsTrackingId = "banner",
  className,
  onDismiss,
}: BannerProps): React.ReactElement {
  const theme = ThemeFor[themeKey];

  return (
    <div
      role="alert"
      className={tcx(
        "w-full px-4 py-3",
        "flex items-center justify-between gap-3",
        theme.main,
        className,
      )}
    >
      <div className="flex grow items-center justify-center gap-3">
        {icon && (
          <Icon id={icon} size={IconSize.Medium} className={theme.icon} />
        )}
        {iconNode}
        <div className="text-center">{children}</div>
      </div>
      {onDismiss != null && (
        <Button
          icon={IconEnum.Close}
          title="Dismiss"
          analyticsTrackingId={`${analyticsTrackingId}.dismiss`}
          onClick={onDismiss}
          theme={ButtonTheme.Unstyled}
          className="justify-self-end"
        />
      )}
    </div>
  );
}
