import type { SVGProps } from "react";
import * as React from "react";
const SvgBriefcaseClock = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#briefcase-clock_svg__a)">
      <path d="M10.444 4.889a.667.667 0 0 1-.666-.667V2c0-.123-.1-.222-.222-.222H6.444c-.122 0-.222.1-.222.222v2.222a.667.667 0 0 1-1.333 0V2c0-.858.698-1.556 1.555-1.556h3.112c.857 0 1.555.698 1.555 1.556v2.222a.667.667 0 0 1-.667.667Z" />
      <path d="M7.333 12.444a4.894 4.894 0 0 1 4.89-4.888 4.86 4.86 0 0 1 2.888.953V6a2.446 2.446 0 0 0-2.444-2.444H3.333A2.446 2.446 0 0 0 .89 6v5.778a2.446 2.446 0 0 0 2.444 2.444h4.34a4.866 4.866 0 0 1-.34-1.778Z" />
      <path d="M12.222 8.889a3.56 3.56 0 0 0-3.555 3.555A3.56 3.56 0 0 0 12.222 16a3.56 3.56 0 0 0 3.556-3.556 3.56 3.56 0 0 0-3.556-3.555Zm2.055 4.4a.665.665 0 0 1-.869.363l-1.44-.59a.668.668 0 0 1-.412-.618V10.89a.667.667 0 0 1 1.333 0v1.108l1.026.422c.34.14.503.53.362.87Z" />
    </g>
    <defs>
      <clipPath id="briefcase-clock_svg__a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBriefcaseClock;
