import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { IconEnum } from "@incident-ui";
import React from "react";

import { ParentPageCreateForm } from "./ParentPageCreateForm";

export const ParentPageCreate = (): React.ReactElement => {
  return (
    <PageWrapper
      icon={IconEnum.Files}
      width={PageWidth.Narrow}
      title="Create status page with sub-pages"
      backHref="/status-pages"
      crumbs={[
        {
          to: "/status-pages",
          title: "Status pages",
        },
      ]}
    >
      <ParentPageCreateForm />
    </PageWrapper>
  );
};
