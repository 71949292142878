/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntryMatch,
    CatalogEntryMatchFromJSON,
    CatalogEntryMatchFromJSONTyped,
    CatalogEntryMatchToJSON,
} from './CatalogEntryMatch';

/**
 * 
 * @export
 * @interface CustomFieldsMatchWithCatalogEntriesResponseBody
 */
export interface CustomFieldsMatchWithCatalogEntriesResponseBody {
    /**
     * 
     * @type {Array<CatalogEntryMatch>}
     * @memberof CustomFieldsMatchWithCatalogEntriesResponseBody
     */
    matches: Array<CatalogEntryMatch>;
}

export function CustomFieldsMatchWithCatalogEntriesResponseBodyFromJSON(json: any): CustomFieldsMatchWithCatalogEntriesResponseBody {
    return CustomFieldsMatchWithCatalogEntriesResponseBodyFromJSONTyped(json, false);
}

export function CustomFieldsMatchWithCatalogEntriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldsMatchWithCatalogEntriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matches': ((json['matches'] as Array<any>).map(CatalogEntryMatchFromJSON)),
    };
}

export function CustomFieldsMatchWithCatalogEntriesResponseBodyToJSON(value?: CustomFieldsMatchWithCatalogEntriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matches': ((value.matches as Array<any>).map(CatalogEntryMatchToJSON)),
    };
}

