import { UserPreferencesUpdateSubscriptionPreferencesRequestBody } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  ContentBox,
  Heading,
  Link,
  ModalFooter,
  Toggle,
  Txt,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { CountryCode } from "libphonenumber-js";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form";
import { Form } from "src/components/@shared/forms";
import { UserPreferences } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPIMutation } from "src/utils/swr";

type IncidentSubscriptionsFormData = Pick<
  UserPreferences,
  | "receives_subscriptions_via_slack_dm"
  | "receives_subscriptions_via_email"
  | "receives_subscriptions_via_sms"
  | "receives_subscriptions_via_app"
>;

export const IncidentSubscriptionsForm = ({
  userPreferences,
}: {
  userPreferences: UserPreferences | null;
}): React.ReactElement => {
  return (
    <ContentBox className={"p-6"}>
      {userPreferences && (
        <IncidentSubscriptionsFormContent userPreferences={userPreferences} />
      )}
    </ContentBox>
  );
};

const IncidentSubscriptionsFormContent = ({
  userPreferences,
}: {
  userPreferences: UserPreferences;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const { trigger: saveSubscriptionPreferences } = useAPIMutation(
    "userPreferencesShow",
    undefined,
    async (
      apiClient,
      data: UserPreferencesUpdateSubscriptionPreferencesRequestBody,
    ) =>
      await apiClient.userPreferencesUpdateSubscriptionPreferences({
        updateSubscriptionPreferencesRequestBody: data,
      }),
  );

  const { setState, state, saving, hasSaved } =
    useOptimisticAutoSave<IncidentSubscriptionsFormData>({
      initialState: userPreferences,
      saveState: (data) => saveSubscriptionPreferences(data),
    });

  const [showVerifySMSModal, setShowVerifySMSModal] = useState<boolean>(false);

  return (
    <>
      <div className="flex justify-between items-start space-x-4">
        <div className="grow max-w-2xl">
          <Heading level={2} id={"subscription-destinations"}>
            Subscription destinations
          </Heading>
          <p className={"mt-1 text-sm text-slate-700 mb-2"}>
            Control where you will receive notifications for incidents
            you&apos;re subscribed to, such as when incident updates are posted.
          </p>
        </div>
        <div className={""}>
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
      </div>
      {identity.slack_info?.user_slack_user_id && (
        <Toggle
          id="receives_subscriptions_via_slack_dm"
          disabled={false}
          align="left"
          label="Slack"
          description="Receive via Slack direct messages."
          on={state.receives_subscriptions_via_slack_dm}
          onToggle={() =>
            setState({
              ...state,
              receives_subscriptions_via_slack_dm:
                !state.receives_subscriptions_via_slack_dm,
            })
          }
        />
      )}

      <Toggle
        toggleClassName={"mt-2"}
        id="receives_subscriptions_via_email"
        disabled={false}
        align="left"
        label="Email"
        description="Receive via email to the address registered with your user."
        on={state.receives_subscriptions_via_email}
        onToggle={() =>
          setState({
            ...state,
            receives_subscriptions_via_email:
              !state.receives_subscriptions_via_email,
          })
        }
      />
      <Toggle
        toggleClassName={"mt-2"}
        id="receives_subscriptions_via_sms"
        disabled={
          (!identity?.feature_gates.use_telecom || // SMS is disabled by feature gate
            !userPreferences.phone_number_verifed_at) && // Phone number is not verified
          !state.receives_subscriptions_via_sms // Always allow people to turn off SMS, even if they can't turn it on
        }
        isDisabledTooltipContent={
          !identity?.feature_gates.use_telecom
            ? "SMS subscriptions are not available on your current plan. Contact us to discuss enabling this."
            : !userPreferences.phone_number_verifed_at
            ? "You must verify your phone number to use SMS subscriptions."
            : undefined
        }
        align="left"
        label="SMS"
        description={
          identity?.feature_gates.use_telecom ? (
            <VerifySMSHelptext
              userPreferences={userPreferences}
              setShowModal={setShowVerifySMSModal}
            />
          ) : (
            "SMS subscriptions are not available on your current plan. Contact us to discuss enabling this."
          )
        }
        on={state.receives_subscriptions_via_sms}
        onToggle={() =>
          setState({
            ...state,
            receives_subscriptions_via_sms:
              !state.receives_subscriptions_via_sms,
          })
        }
      />

      {showVerifySMSModal && (
        <PhoneNumberVerifyModal
          userPreferences={userPreferences}
          onClose={() => {
            setShowVerifySMSModal(false);
          }}
          onComplete={() => {
            setShowVerifySMSModal(false);
            setState({ ...state, receives_subscriptions_via_sms: true });
          }}
        />
      )}

      <Toggle
        toggleClassName={"mt-2"}
        id="receives_subscriptions_via_app"
        disabled={false}
        align="left"
        label="Mobile app"
        description="Receive via push notifications to the incident.io mobile app."
        on={state.receives_subscriptions_via_app}
        onToggle={() =>
          setState({
            ...state,
            receives_subscriptions_via_app:
              !state.receives_subscriptions_via_app,
          })
        }
      />
    </>
  );
};

// Provides the helptext to the SMS toggle, which includes a link to open the
// modal that provides phone number configuration.
const VerifySMSHelptext = ({
  userPreferences,
  setShowModal,
}: {
  userPreferences: UserPreferences;
  setShowModal: (show: boolean) => void;
}): React.ReactElement => {
  const ModalOpenLink = (copy: string): React.ReactElement => {
    return (
      <Link
        href={"#"}
        className={"cursor-pointer"}
        onClick={(e) => {
          e.preventDefault(); // to prevent hitting the toggle
          setShowModal(true);
        }}
        analyticsTrackingId={"verify-phone-number"}
      >
        {copy}
      </Link>
    );
  };

  return (
    <>
      {userPreferences.phone_number_verifed_at ? (
        <span>
          Receive live updates via SMS to {userPreferences.phone_number} or{" "}
          {ModalOpenLink("change to another number.")}
        </span>
      ) : (
        <span>
          You must {ModalOpenLink("verify your phone number")} to use SMS
          subscriptions.
        </span>
      )}
    </>
  );
};

type PhoneNumberVerifyModalState = {
  phone_number?: string;
  code?: string;
};

// Two-step modal, first being entering a number, second confirming the
// verification code that was sent to that number.
export const PhoneNumberVerifyModal = ({
  userPreferences,
  onClose,
  onComplete,
}: {
  userPreferences: UserPreferences;
  onComplete: () => void;
  onClose: () => void;
}): React.ReactElement | null => {
  const [sentCode, setSentCode] = useState<boolean>(false);
  const defaultValues: PhoneNumberVerifyModalState = {
    phone_number: userPreferences.phone_number,
  };

  const formMethods = useForm<PhoneNumberVerifyModalState>({
    defaultValues,
  });
  const { setError, watch, control } = formMethods;

  const identity = useIdentity();

  const phoneNumberFormat = identity?.identity?.user_locale
    ? identity?.identity?.user_locale.split("-")[1]
    : "US";

  const phoneNumber = watch("phone_number");

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "userPreferencesShow",
    undefined,
    async (apiClient, data: PhoneNumberVerifyModalState) => {
      if (data.phone_number && !data.code) {
        await apiClient.userPreferencesUpdatePhoneNumber({
          updatePhoneNumberRequestBody: {
            phone_number: data.phone_number,
          },
        });
      } else if (data.code) {
        await apiClient.userPreferencesVerifyPhoneNumber({
          verifyPhoneNumberRequestBody: {
            code: data.code,
          },
        });
      }
    },
    {
      setError,
      onSuccess: () => {
        if (sentCode) {
          onComplete();
        } else {
          setSentCode(true);
        }
      },
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      analyticsTrackingId={"verify-phone-number"}
      title={"Setup phone number"}
      disableQuickClose
      loading={saving}
      footer={
        <ModalFooter
          confirmButtonText={sentCode ? "Confirm" : "Send verification code"}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      {!sentCode ? (
        <>
          <Form.InputWrapper
            label="Phone number"
            name="phone_number"
            helptext={
              "This is the phone number you want to receive subscriptions to, with country code."
            }
          >
            <PhoneInput
              name={"phone_number"}
              control={control}
              rules={{ required: true }}
              defaultCountry={phoneNumberFormat as CountryCode}
              international
              countrySelectProps={{ unicodeFlags: true }}
              numberInputProps={{
                className:
                  "border border-stroke rounded-[6px] p-2 tabular-nums",
              }}
            />
          </Form.InputWrapper>
          <Txt>
            We&apos;ll send a verification code to this number to confirm it
            belongs to you, so make sure you have access.
          </Txt>
        </>
      ) : (
        <>
          <InputV2
            formMethods={formMethods}
            name="code"
            label="Verification code"
            helptext={`Enter the six digit code sent to ${phoneNumber}.`}
            type={InputType.Number}
            disabled={!sentCode}
            placeholder={"000000"}
            required="Please provide the verification code"
            // @ts-expect-error - it's not passing through `autoComplete`, but this works
            autocomplete={"one-time-code"}
          />
          <Txt>
            If you haven&apos;t received a code, press cancel to quit and try
            again.
          </Txt>
        </>
      )}
    </Form.Modal>
  );
};
