import {
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
  StatusPageIncidentTypeEnum,
} from "@incident-io/api";
import { Icon, IconEnum, IconSize, OrgAwareLink } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import {
  INCIDENT_STATUS_CONFIG,
  MAINTENANCE_STATUS_CONFIG,
} from "../utils/utils";

export const IncidentStatus = ({
  incident,
}: {
  incident: StatusPageIncident;
}): React.ReactElement => {
  const statusValues =
    incident.type === StatusPageIncidentTypeEnum.Maintenance
      ? Object.values(MAINTENANCE_STATUS_CONFIG)
      : Object.values(INCIDENT_STATUS_CONFIG);

  return (
    <OrgAwareLink
      to={`/status-pages/${incident.status_page_id}/incident/${incident.id}/publish-update`}
      className={"group flex"}
    >
      <div className="flex border border-stroke bg-white rounded-[6px] drop-shadow-sm relative">
        <div className="bg-surface-secondary z-50 w-full h-full rounded-[6px] absolute flex-center opacity-0 group-hover:opacity-100 transition">
          <Icon id={IconEnum.Edit} size={IconSize.Medium} className="mr-1" />
          Publish update
        </div>

        {statusValues.map((option, index) => (
          <IncidentStatusItem
            key={index}
            active={
              (option.value as unknown as StatusPageIncidentStatusEnum) ===
              incident.status
            }
            status={option.label}
            statusType={option.value as unknown as StatusPageIncidentStatusEnum}
            index={index}
            length={statusValues.length}
          />
        ))}
      </div>
    </OrgAwareLink>
  );
};

const IncidentStatusItem = ({
  status,
  statusType,
  index,
  active,
  length,
}: {
  status: string;
  statusType: StatusPageIncidentStatusEnum;
  index: number;
  active: boolean;
  length: number;
}) => {
  return (
    <div className="flex-center text-content-primary">
      <div
        className={tcx("flex-center-y", {
          "text-content-tertiary": !active,
          "ml-4": index === 0,
          "mr-4": index === length - 1,
        })}
      >
        {index !== 0 && (
          <Icon
            id={IconEnum.StatusChevron}
            className="text-stroke"
            size={IconSize.XL}
          />
        )}
        {active &&
          (statusType === StatusPageIncidentStatusEnum.Resolved ||
          statusType === StatusPageIncidentStatusEnum.MaintenanceComplete ? (
            <Icon
              id={IconEnum.Tick}
              className="text-green-content rounded-full mr-1 ml-[-4px]"
            />
          ) : (
            <div
              className={
                "w-[8px] h-[8px] mr-2 shrink-0 rounded-full animate-pulse bg-alarmalade-600"
              }
            />
          ))}
        <span>{status}</span>
      </div>
    </div>
  );
};
