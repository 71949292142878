/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface GroupingKey
 */
export interface GroupingKey {
    /**
     * Unique identifier of the alert attribute the user wishes to group on
     * @type {string}
     * @memberof GroupingKey
     */
    id: string;
    /**
     * 
     * @type {Reference}
     * @memberof GroupingKey
     */
    reference?: Reference;
}

export function GroupingKeyFromJSON(json: any): GroupingKey {
    return GroupingKeyFromJSONTyped(json, false);
}

export function GroupingKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupingKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': !exists(json, 'reference') ? undefined : ReferenceFromJSON(json['reference']),
    };
}

export function GroupingKeyToJSON(value?: GroupingKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': ReferenceToJSON(value.reference),
    };
}

