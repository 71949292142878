import {
  Actor as ActorBody,
  ConditionGroup,
  EscalationTimelineItemTypeEnum,
} from "@incident-io/api";
import {
  EscalatedTimelineUserNotification,
  EscalationTimelineItem,
} from "@incident-io/query-api";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { Actor } from "@incident-ui/Actor/Actor";
import React from "react";

import { joinSpansWithCommasAndConnectorWord } from "../../utils/utils";

const EscalatedLevel = ({
  item,
}: {
  item: EscalationTimelineItem;
}): JSX.Element => {
  if ((item.content.escalated_level?.notifications ?? []).length === 0) {
    return <></>;
  }

  return (
    <StackedList>
      {(item.content.escalated_level?.notifications ?? []).map(
        (notification) => (
          <LevelNotification
            key={notification.user.id}
            notification={notification}
          />
        ),
      )}
    </StackedList>
  );
};

const LevelNotification = ({
  notification,
}: {
  notification: EscalatedTimelineUserNotification;
}) => {
  if (notification.is_live_call) {
    return (
      <StackedListItem
        title={
          <span className="inline-flex gap-2 items-center">
            <Avatar size={IconSize.Medium} url={notification.user.avatar_url} />
            Tried to call {notification.user.name}
          </span>
        }
        key={notification.user.id}
      />
    );
  }

  return (
    <StackedListItem
      title={
        <span className="inline-flex gap-2 items-center">
          <Avatar size={IconSize.Medium} url={notification.user.avatar_url} />
          Notified {notification.user.name} via{" "}
          {joinSpansWithCommasAndConnectorWord(notification.methods)}
        </span>
      }
      key={notification.user.id}
    />
  );
};

export const EscalationTimelineItemDisplayInfo: Record<
  EscalationTimelineItemTypeEnum,
  {
    icon: IconEnum;
    color: ColorPaletteEnum;
    Component: React.ComponentType<{
      item: EscalationTimelineItem;
    }> | null;
    renderTitle?: (item: EscalationTimelineItem) => React.ReactNode;
  }
> = {
  [EscalationTimelineItemTypeEnum.Created]: {
    icon: IconEnum.Alert,
    color: ColorPaletteEnum.Red,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => {
      return (
        <div className="align-baseline">
          {item.title} by&nbsp;
          {item.content.created?.creator && (
            <Actor
              className="align-bottom"
              actor={item.content.created?.creator as unknown as ActorBody}
            />
          )}
          {(item.content.created?.deferred_seconds ?? 0) > 0 && (
            <>
              , deferred notifications for{" "}
              {(item.content.created?.deferred_seconds ?? 0) / 60} minutes
            </>
          )}
        </div>
      );
    },
  },
  [EscalationTimelineItemTypeEnum.EscalatedLevel]: {
    icon: IconEnum.Siren,
    color: ColorPaletteEnum.Red,
    Component: EscalatedLevel,
    renderTitle: (item: EscalationTimelineItem) => (
      <>
        {item.title} level {item.content.escalated_level?.level}
        {item.content.escalated_level?.reason === "level_timeout" &&
          " because no one responded in time"}
      </>
    ),
  },
  [EscalationTimelineItemTypeEnum.EscalatedChannel]: {
    icon: IconEnum.Siren,
    color: ColorPaletteEnum.Red,
    renderTitle: (item: EscalationTimelineItem) => (
      <span className="inline-flex items-center gap-1">
        Escalated to{" "}
        {joinSpansWithCommasAndConnectorWord(
          (item.content.escalated_channel?.notifications ?? []).map((n) => (
            <Button
              key={n.slack_channel_url}
              analyticsTrackingId={"escalation-timeline-open-channel"}
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.Small}
              icon={IconEnum.Slack}
              href={n.slack_channel_url}
            >
              #{n.slack_channel_name}
            </Button>
          )),
        )}
      </span>
    ),
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Acknowledged]: {
    icon: IconEnum.Checkmark,
    color: ColorPaletteEnum.Green,
    renderTitle: (item: EscalationTimelineItem) => (
      <>
        {joinSpansWithCommasAndConnectorWord(
          (item.content.acknowledged?.acknowledgements ?? []).map(
            (ack) => ack.user_name ?? "",
          ),
        )}{" "}
        {item.content.acknowledged?.is_live_call
          ? "was connected to the call"
          : "acknowledged"}
      </>
    ),
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Nacked]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Red,
    renderTitle: (item: EscalationTimelineItem) => (
      <>{item.content.nacked?.user?.name} was unavailable</>
    ),
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Cancelled]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Slate,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.ConditionsEvaluated]: {
    icon: IconEnum.GitBranch,
    color: ColorPaletteEnum.Blue,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => (
      <span className="inline-flex gap-1 items-center">
        <span>
          Condition{" "}
          {item.content.conditions_evaluated?.outcome === "condition_true"
            ? "met"
            : item.content.conditions_evaluated?.outcome === "condition_false"
            ? "not met"
            : "failed to evaluate"}
          :
        </span>
        <ConditionGroupsList
          groups={
            item.content.conditions_evaluated
              ?.conditions as unknown as ConditionGroup[]
          }
          mini
          boxless
        />
      </span>
    ),
  },
  [EscalationTimelineItemTypeEnum.Repeated]: {
    icon: IconEnum.Loop,
    color: ColorPaletteEnum.Blue,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Expired]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Slate,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Triggered]: {
    icon: IconEnum.Flag,
    color: ColorPaletteEnum.Blue,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => (
      <span className="inline-flex items-center gap-1">
        Escalation sent to{" "}
        <Button
          analyticsTrackingId="escalation-timeline-open-escalation-path"
          size={BadgeSize.Small}
          theme={ButtonTheme.Tertiary}
          icon={IconEnum.EscalationPath}
          href={`/on-call/escalation-paths/${item.content.triggered?.escalation_path?.id}`}
        >
          {item.content.triggered?.escalation_path?.name}
        </Button>
      </span>
    ),
  },
  [EscalationTimelineItemTypeEnum.IncomingCall]: {
    icon: IconEnum.IncomingPhoneCall,
    color: ColorPaletteEnum.Blue,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.IncomingCallEnded]: {
    icon: IconEnum.IncomingPhoneCall,
    color: ColorPaletteEnum.Green,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.CalledUser]: {
    icon: IconEnum.Phone,
    color: ColorPaletteEnum.Blue,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => {
      if (!item.content.called_user) {
        return "Tried to call";
      }

      return (
        <span className="inline-flex gap-1 items-center">
          Called{" "}
          <Avatar
            size={IconSize.Small}
            url={item.content.called_user.user.avatar_url}
          />
          {item.content.called_user.user.name}
        </span>
      );
    },
  },
  [EscalationTimelineItemTypeEnum.FailedToCallUser]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Red,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => {
      if (!item.content.called_user) {
        return "Failed to make a call";
      }

      return (
        <span className="inline-flex gap-1 items-center">
          Failed to call{" "}
          <Avatar
            size={IconSize.Small}
            url={item.content.called_user.user.avatar_url}
          />
          {item.content.called_user.user.name}
        </span>
      );
    },
  },
};
