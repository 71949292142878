import { ExploDashboard } from "@incident-shared/explo/ExploDashboard";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuLink,
  GatedDropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
} from "@incident-ui";
import { Drawer, DrawerBody, DrawerTitle } from "@incident-ui/Drawer/Drawer";
import { captureException } from "@sentry/core";
import { addHours } from "date-fns";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useState } from "react";
import { Route, Routes } from "react-router";
import { useParams } from "react-router";
import { ExploDashboardID } from "src/components/insights/v3/dashboards/common/types";
import {
  ScopeNameEnum,
  StatusPageIncident,
  StatusPageIncidentTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { MaintenanceTimestampEditModal } from "../common/MaintenanceTimestampEditModal";
import { StatusPageIndcidentDeleteModal } from "../delete/StatusPageIndcidentDeleteModal";
import { StatusPageIncidentCreateUpdateModal } from "../incident-updates/create/StatusPageIncidentCreateUpdateModal";
import { StatusPageIncidentUpdateViewSection } from "../incident-updates/view/StatusPageIncidentUpdateViewSection";
import { getImpactWindow } from "../utils/utils";
import { AffectedComponents } from "./AffectedComponents";
import { IncidentComponentImpactsForm } from "./IncidentComponentImpactsForm";
import { IncidentSidebar } from "./IncidentSidebar";
import { IncidentStatus } from "./IncidentStatus";
import { IncidentTitleEditModal } from "./IncidentTitleEditModal";
import { RetrospectiveBadge } from "./RetrospectiveBadge";

export const StatusPageIncidentDetails = (): React.ReactElement => {
  const { id: pageId, incidentId } = useParams() as {
    id: string;
    incidentId: string;
  };
  const { hasScope, identity } = useIdentity();

  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [
    showEditMaintenanceTimestampsModal,
    setShowEditMaintenanceTimestampsModal,
  ] = useState(false);
  const [showEditComponentImpactsModal, setShowComponentImpactsModal] =
    useState(false);
  const [showDeleteIncidentModal, setShowDeleteIncidentModal] = useState(false);

  const [highlightedUpdateId, setHighlightedUpdateId] = useState<string | null>(
    null,
  );
  const [sideBarOpen, setSidebarOpen] = useState(false);

  const {
    data: pageData,
    error: pageError,
    isLoading: pageLoading,
  } = useAPI("statusPageShow", { id: pageId });
  const page = pageData?.status_page;
  const structure = pageData?.current_structure;
  const subPages = pageData?.subpages;

  const {
    data: templatesData,
    error: templatesError,
    isLoading: templatesLoading,
  } = useAPI("statusPageListTemplates", { statusPageId: pageId });
  const templates = templatesData?.status_page_templates;

  const {
    data: incidentData,
    error: incidentError,
    isLoading: incidentLoading,
  } = useAPI("statusPageShowIncident", { id: incidentId });
  const incident = incidentData?.status_page_incident;

  const error = pageError || incidentError || templatesError;
  if (error) {
    captureException(error);
    return <GenericErrorMessage error={error} />;
  }

  if (
    pageLoading ||
    incidentLoading ||
    templatesLoading ||
    !incident ||
    !page ||
    !structure ||
    !templates
  ) {
    return <Loader />;
  }

  const sortedUpdates = _.reverse(_.sortBy(incident?.updates, "sort_key"));
  const isParentPage = !!page.split_by_catalog_type_id;

  const [impactStart, impactEnd] = getImpactWindow(incident);
  const [timelineStart, timelineEnd] = getViewCountWindow(incident);

  return (
    <>
      <Routes>
        <Route
          path="publish-update"
          element={
            <StatusPageIncidentCreateUpdateModal
              page={page}
              structure={structure}
              templates={templates}
              incident={incident}
            />
          }
        />
      </Routes>

      {showEditTitleModal ? (
        <IncidentTitleEditModal
          incident={incident}
          onClose={() => setShowEditTitleModal(false)}
        />
      ) : null}

      {showEditMaintenanceTimestampsModal && (
        <MaintenanceTimestampEditModal
          incident={incident}
          onClose={() => setShowEditMaintenanceTimestampsModal(false)}
        />
      )}

      {showDeleteIncidentModal ? (
        <StatusPageIndcidentDeleteModal
          page={page}
          incident={incident}
          onClose={() => setShowDeleteIncidentModal(false)}
        />
      ) : null}

      <PageWrapper
        icon={IconEnum.Incident}
        width={PageWidth.Medium}
        title={incident.name}
        crumbs={[
          {
            onClick: () => history.back(),
            title: page.name,
          },
        ]}
        onEditTitle={() => setShowEditTitleModal(true)}
        backHref={`/status-pages/${page?.id}`}
        accessory={
          <div className="flex gap-2 items-center">
            <DropdownMenu
              side="bottom"
              triggerButton={
                <Button
                  title="More options"
                  analyticsTrackingId="incident-more-options"
                  icon={IconEnum.DotsHorizontalNopad}
                  theme={ButtonTheme.Secondary}
                  iconProps={{
                    size: IconSize.Small,
                  }}
                />
              }
            >
              <GatedDropdownMenuItem
                onSelect={() => setShowDeleteIncidentModal(true)}
                analyticsTrackingId={null}
                label={"Delete status page incident"}
                icon={IconEnum.Delete}
                iconProps={{ size: IconSize.Medium }}
              />
            </DropdownMenu>

            {isParentPage && subPages ? (
              <DropdownMenu
                triggerButton={
                  <Button
                    analyticsTrackingId="status-page-incident-visit"
                    icon={IconEnum.ExternalLink}
                    iconProps={{ size: IconSize.Small, className: "mr-1" }}
                    openInNewTab
                  >
                    View on status page
                  </Button>
                }
              >
                {incident.sub_pages.map((sp) => (
                  <DropdownMenuLink
                    className="min-w-[180px] w-[300px]"
                    label={sp.name}
                    key={sp.id}
                    to={`${sp.public_url}/incidents/${incident.id}`}
                    analyticsTrackingId="status-page-incident-visit-subpage"
                    openInNewTab
                  />
                ))}
              </DropdownMenu>
            ) : (
              <Button
                analyticsTrackingId="status-page-incident-visit"
                href={incident.public_url}
                icon={IconEnum.ExternalLink}
                iconProps={{ size: IconSize.Small, className: "mr-1" }}
                openInNewTab
              >
                View on status page
              </Button>
            )}

            <GatedButton
              analyticsTrackingId="status-page-publish-incident"
              theme={ButtonTheme.Primary}
              href={`publish-update`}
              disabled={!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)}
              disabledTooltipContent={
                "You do not have permission to publish updates to this public status page"
              }
            >
              Publish update
            </GatedButton>
            <Button
              analyticsTrackingId="status-page-sidebar-toggle"
              onClick={() => setSidebarOpen(true)}
              className={tcx("xl:hidden mr-2 pl-1.5")}
              icon={IconEnum.ChevronLeft}
            >
              Details
            </Button>
          </div>
        }
        banner={
          <HeaderBanner
            className={tcx(
              "w-full text-sm",
              "flex self-stretch items-center gap-2 flex-wrap",
            )}
          >
            <IncidentStatus incident={incident} />

            <div className="flex flex-wrap gap-2 items-center">
              {incident.is_retrospective && <RetrospectiveBadge />}

              {incident.type === "maintenance" && impactStart && impactEnd && (
                <div className="text-slate-600 flex flex-row items-center gap-1 ml-2 text-sm group/scheduled">
                  Scheduled{" "}
                  {formatTimestampLocale({
                    timestamp: impactStart,
                    timeStyle: "short",
                    dateStyle: "short",
                  })}
                  {" - "}
                  {formatTimestampLocale({
                    timestamp: impactEnd,
                    timeStyle: "short",
                    dateStyle: "short",
                  })}
                  <GatedButton
                    analyticsTrackingId={null}
                    onClick={() => setShowEditMaintenanceTimestampsModal(true)}
                    theme={ButtonTheme.Naked}
                    className="opacity-0 group-hover/scheduled:opacity-100"
                    title=""
                    icon={IconEnum.Edit}
                    iconProps={{
                      size: IconSize.Medium,
                      className: "!mr-1",
                    }}
                    disabled={
                      !hasScope(ScopeNameEnum.StatusPagesPublishUpdates)
                    }
                  />
                </div>
              )}
            </div>
          </HeaderBanner>
        }
      >
        <div className="flex items-stretch gap-8">
          <div className={"space-y-6 grow basis-1 w-fit"}>
            {/* Status page views around the time of this incident */}
            {identity.feature_gates.advanced_insights && (
              <>
                <div className={"text-[16px] font-medium flex items-center"}>
                  <div>Status page view count</div>
                  <Tooltip
                    content={
                      "A count of viewers of this status page grouped into 15 minute windows. Source data is refreshed hourly."
                    }
                  />
                </div>
                <ExploDashboard
                  dashboardEmbedID={ExploDashboardID.StatusPageViewerCount}
                  dashboardVariables={{
                    start_date: timelineStart.toISOString(),
                    end_date: timelineEnd.toISOString(),
                    status_page_id: incident.status_page_id,
                    status_page_incident_id: incident.id,
                  }}
                  initialHeight="280px"
                />
              </>
            )}

            {/* Incident updates */}
            {incident.updates.length > 0 ? (
              <div className="text-sm">
                <div className={"text-base font-medium px-2 pb-4 md:px-0"}>
                  Updates
                </div>
                {sortedUpdates.map((update, index) => (
                  <StatusPageIncidentUpdateViewSection
                    key={update.id}
                    structure={structure}
                    update={update}
                    prevUpdate={sortedUpdates[index + 1]}
                    nextUpdate={sortedUpdates[index - 1]}
                    highlighted={update.id === highlightedUpdateId}
                    canDelete={index !== 0}
                    isLast={index === sortedUpdates.length - 1}
                  />
                ))}
              </div>
            ) : null}

            {/* Affected components */}
            {incident.type !== StatusPageIncidentTypeEnum.Maintenance && (
              <AffectedComponents
                structure={structure}
                incident={incident}
                onEdit={() => setShowComponentImpactsModal(true)}
                highlightUpdate={(id) => {
                  setHighlightedUpdateId(id);
                  // Reset after 1s, unless it's changed already
                  setTimeout(
                    () =>
                      setHighlightedUpdateId((thenId) =>
                        thenId === id ? null : thenId,
                      ),
                    1000,
                  );
                }}
              />
            )}
          </div>
          {/* The sidebar shown next to the incident body */}
          <IncidentSidebar
            page={page}
            structure={structure}
            subPages={subPages}
            incident={incident}
            className="hidden xl:block"
          />
          {/* Same sidebar, but in a drawer that can be explicitly opened for small screens */}
          <AnimatePresence>
            {sideBarOpen && (
              <Drawer onClose={() => setSidebarOpen(false)} width="extra-small">
                <DrawerTitle
                  title="Details"
                  compact
                  onClose={() => setSidebarOpen(false)}
                />
                <DrawerBody>
                  <IncidentSidebar
                    page={page}
                    structure={structure}
                    subPages={subPages}
                    incident={incident}
                  />
                </DrawerBody>
              </Drawer>
            )}
          </AnimatePresence>
        </div>
        {/* Edit impacts modal */}
        {showEditComponentImpactsModal ? (
          <IncidentComponentImpactsForm
            isOpen
            onClose={() => setShowComponentImpactsModal(false)}
            structure={structure}
            incident={incident}
          />
        ) : null}
      </PageWrapper>
    </>
  );
};

// We want to see the view count for a status page for the lenght of the incident.
// We also want to highlight a little either side for comparison (so you can tell what is "normal").
// We also ALWAYS want to return a date - as otherwise our explo queries will be unbounded.
// Assumptions I have made:
// - updates can have a created at set to before the created_at of the incident
//    (letting you retroactively set when an incident started)
// - components can also have a created at set to before the created_at of the incident
// - The incident is only ever ended when in the "resolved" state
export const getViewCountWindow = (
  incident: StatusPageIncident,
): [Date, Date] => {
  const sortedUpdates = _.sortBy(incident?.updates, "sort_key");

  const componentStart = _.minBy(
    incident.component_impacts,
    (impact) => impact.start_at,
  )?.start_at;

  const componentEnd = _.maxBy(
    incident.component_impacts,
    (impact) => impact.end_at,
  )?.end_at;

  // Default to the start of the incident
  let start = incident.created_at;

  // Now check if any update was before the start
  if (sortedUpdates.length > 0) {
    const earliestUpdate = sortedUpdates[0];
    if (earliestUpdate.created_at < start) {
      start = earliestUpdate.created_at;
    }
  }

  // Did someone set a component that was affected to before the start?
  if (componentStart && componentStart < start) {
    start = componentStart;
  }

  // Default the end to now
  let end = new Date();

  // Did we resolve a while back? If so, set the end to the last update
  if (incident.status === "resolved" && sortedUpdates.length > 0) {
    end = sortedUpdates[sortedUpdates.length - 1].created_at;
  }

  // Regardless of if we resolved did someone set a component that was affected
  // to after the end?
  if (componentEnd && componentEnd > end) {
    end = componentEnd;
  }

  // Now give us a little buffer either side.
  start = addHours(start, -1);
  end = addHours(end, 1);

  return [start, end];
};
