/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentChannelConfigsCreateBookmarkRequestBody,
    IncidentChannelConfigsCreateBookmarkRequestBodyFromJSON,
    IncidentChannelConfigsCreateBookmarkRequestBodyToJSON,
    IncidentChannelConfigsCreateBookmarkResponseBody,
    IncidentChannelConfigsCreateBookmarkResponseBodyFromJSON,
    IncidentChannelConfigsCreateBookmarkResponseBodyToJSON,
    IncidentChannelConfigsCreateQuickActionRequestBody,
    IncidentChannelConfigsCreateQuickActionRequestBodyFromJSON,
    IncidentChannelConfigsCreateQuickActionRequestBodyToJSON,
    IncidentChannelConfigsCreateQuickActionResponseBody,
    IncidentChannelConfigsCreateQuickActionResponseBodyFromJSON,
    IncidentChannelConfigsCreateQuickActionResponseBodyToJSON,
    IncidentChannelConfigsListBookmarkOptionsResponseBody,
    IncidentChannelConfigsListBookmarkOptionsResponseBodyFromJSON,
    IncidentChannelConfigsListBookmarkOptionsResponseBodyToJSON,
    IncidentChannelConfigsListEnabledBookmarksResponseBody,
    IncidentChannelConfigsListEnabledBookmarksResponseBodyFromJSON,
    IncidentChannelConfigsListEnabledBookmarksResponseBodyToJSON,
    IncidentChannelConfigsListEnabledQuickActionsResponseBody,
    IncidentChannelConfigsListEnabledQuickActionsResponseBodyFromJSON,
    IncidentChannelConfigsListEnabledQuickActionsResponseBodyToJSON,
    IncidentChannelConfigsListQuickActionOptionsResponseBody,
    IncidentChannelConfigsListQuickActionOptionsResponseBodyFromJSON,
    IncidentChannelConfigsListQuickActionOptionsResponseBodyToJSON,
    IncidentChannelConfigsUpdateBookmarkRanksRequestBody,
    IncidentChannelConfigsUpdateBookmarkRanksRequestBodyFromJSON,
    IncidentChannelConfigsUpdateBookmarkRanksRequestBodyToJSON,
    IncidentChannelConfigsUpdateBookmarkRanksResponseBody,
    IncidentChannelConfigsUpdateBookmarkRanksResponseBodyFromJSON,
    IncidentChannelConfigsUpdateBookmarkRanksResponseBodyToJSON,
    IncidentChannelConfigsUpdateBookmarkRequestBody,
    IncidentChannelConfigsUpdateBookmarkRequestBodyFromJSON,
    IncidentChannelConfigsUpdateBookmarkRequestBodyToJSON,
    IncidentChannelConfigsUpdateBookmarkResponseBody,
    IncidentChannelConfigsUpdateBookmarkResponseBodyFromJSON,
    IncidentChannelConfigsUpdateBookmarkResponseBodyToJSON,
    IncidentChannelConfigsUpdateQuickActionRanksRequestBody,
    IncidentChannelConfigsUpdateQuickActionRanksRequestBodyFromJSON,
    IncidentChannelConfigsUpdateQuickActionRanksRequestBodyToJSON,
    IncidentChannelConfigsUpdateQuickActionRanksResponseBody,
    IncidentChannelConfigsUpdateQuickActionRanksResponseBodyFromJSON,
    IncidentChannelConfigsUpdateQuickActionRanksResponseBodyToJSON,
    IncidentChannelConfigsUpdateQuickActionRequestBody,
    IncidentChannelConfigsUpdateQuickActionRequestBodyFromJSON,
    IncidentChannelConfigsUpdateQuickActionRequestBodyToJSON,
    IncidentChannelConfigsUpdateQuickActionResponseBody,
    IncidentChannelConfigsUpdateQuickActionResponseBodyFromJSON,
    IncidentChannelConfigsUpdateQuickActionResponseBodyToJSON,
} from '../models';

export interface IncidentChannelConfigsCreateBookmarkRequest {
    createBookmarkRequestBody: IncidentChannelConfigsCreateBookmarkRequestBody;
}

export interface IncidentChannelConfigsCreateQuickActionRequest {
    createQuickActionRequestBody: IncidentChannelConfigsCreateQuickActionRequestBody;
}

export interface IncidentChannelConfigsDestroyBookmarkRequest {
    id: string;
}

export interface IncidentChannelConfigsDestroyQuickActionRequest {
    id: string;
}

export interface IncidentChannelConfigsUpdateBookmarkRequest {
    id: string;
    updateBookmarkRequestBody: IncidentChannelConfigsUpdateBookmarkRequestBody;
}

export interface IncidentChannelConfigsUpdateBookmarkRanksRequest {
    updateBookmarkRanksRequestBody: IncidentChannelConfigsUpdateBookmarkRanksRequestBody;
}

export interface IncidentChannelConfigsUpdateQuickActionRequest {
    id: string;
    updateQuickActionRequestBody: IncidentChannelConfigsUpdateQuickActionRequestBody;
}

export interface IncidentChannelConfigsUpdateQuickActionRanksRequest {
    updateQuickActionRanksRequestBody: IncidentChannelConfigsUpdateQuickActionRanksRequestBody;
}

/**
 * 
 */
export class IncidentChannelConfigsApi extends runtime.BaseAPI {

    /**
     * Create a bookmark, by either creating a custom one or enabling a pre-defined option
     * CreateBookmark IncidentChannelConfigs
     */
    async incidentChannelConfigsCreateBookmarkRaw(requestParameters: IncidentChannelConfigsCreateBookmarkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsCreateBookmarkResponseBody>> {
        if (requestParameters.createBookmarkRequestBody === null || requestParameters.createBookmarkRequestBody === undefined) {
            throw new runtime.RequiredError('createBookmarkRequestBody','Required parameter requestParameters.createBookmarkRequestBody was null or undefined when calling incidentChannelConfigsCreateBookmark.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/bookmarks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentChannelConfigsCreateBookmarkRequestBodyToJSON(requestParameters.createBookmarkRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsCreateBookmarkResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a bookmark, by either creating a custom one or enabling a pre-defined option
     * CreateBookmark IncidentChannelConfigs
     */
    async incidentChannelConfigsCreateBookmark(requestParameters: IncidentChannelConfigsCreateBookmarkRequest, initOverrides?: RequestInit): Promise<IncidentChannelConfigsCreateBookmarkResponseBody> {
        const response = await this.incidentChannelConfigsCreateBookmarkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a quick action, by either creating a custom one or enabling a pre-defined option
     * CreateQuickAction IncidentChannelConfigs
     */
    async incidentChannelConfigsCreateQuickActionRaw(requestParameters: IncidentChannelConfigsCreateQuickActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsCreateQuickActionResponseBody>> {
        if (requestParameters.createQuickActionRequestBody === null || requestParameters.createQuickActionRequestBody === undefined) {
            throw new runtime.RequiredError('createQuickActionRequestBody','Required parameter requestParameters.createQuickActionRequestBody was null or undefined when calling incidentChannelConfigsCreateQuickAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/quick_actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentChannelConfigsCreateQuickActionRequestBodyToJSON(requestParameters.createQuickActionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsCreateQuickActionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a quick action, by either creating a custom one or enabling a pre-defined option
     * CreateQuickAction IncidentChannelConfigs
     */
    async incidentChannelConfigsCreateQuickAction(requestParameters: IncidentChannelConfigsCreateQuickActionRequest, initOverrides?: RequestInit): Promise<IncidentChannelConfigsCreateQuickActionResponseBody> {
        const response = await this.incidentChannelConfigsCreateQuickActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives an enabled bookmark, which essentially disables it
     * DestroyBookmark IncidentChannelConfigs
     */
    async incidentChannelConfigsDestroyBookmarkRaw(requestParameters: IncidentChannelConfigsDestroyBookmarkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentChannelConfigsDestroyBookmark.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/bookmarks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an enabled bookmark, which essentially disables it
     * DestroyBookmark IncidentChannelConfigs
     */
    async incidentChannelConfigsDestroyBookmark(requestParameters: IncidentChannelConfigsDestroyBookmarkRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentChannelConfigsDestroyBookmarkRaw(requestParameters, initOverrides);
    }

    /**
     * Archives an enabled quick action, which essentially disables it
     * DestroyQuickAction IncidentChannelConfigs
     */
    async incidentChannelConfigsDestroyQuickActionRaw(requestParameters: IncidentChannelConfigsDestroyQuickActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentChannelConfigsDestroyQuickAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/quick_actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an enabled quick action, which essentially disables it
     * DestroyQuickAction IncidentChannelConfigs
     */
    async incidentChannelConfigsDestroyQuickAction(requestParameters: IncidentChannelConfigsDestroyQuickActionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentChannelConfigsDestroyQuickActionRaw(requestParameters, initOverrides);
    }

    /**
     * List all pre-defined incident channel bookmarks that are available for an organisation
     * ListBookmarkOptions IncidentChannelConfigs
     */
    async incidentChannelConfigsListBookmarkOptionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsListBookmarkOptionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/bookmark_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsListBookmarkOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all pre-defined incident channel bookmarks that are available for an organisation
     * ListBookmarkOptions IncidentChannelConfigs
     */
    async incidentChannelConfigsListBookmarkOptions(initOverrides?: RequestInit): Promise<IncidentChannelConfigsListBookmarkOptionsResponseBody> {
        const response = await this.incidentChannelConfigsListBookmarkOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incident channel bookmarks that are enabled for an organisation
     * ListEnabledBookmarks IncidentChannelConfigs
     */
    async incidentChannelConfigsListEnabledBookmarksRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsListEnabledBookmarksResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/bookmarks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsListEnabledBookmarksResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident channel bookmarks that are enabled for an organisation
     * ListEnabledBookmarks IncidentChannelConfigs
     */
    async incidentChannelConfigsListEnabledBookmarks(initOverrides?: RequestInit): Promise<IncidentChannelConfigsListEnabledBookmarksResponseBody> {
        const response = await this.incidentChannelConfigsListEnabledBookmarksRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incident channel quick actions that are enabled for an organisation
     * ListEnabledQuickActions IncidentChannelConfigs
     */
    async incidentChannelConfigsListEnabledQuickActionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsListEnabledQuickActionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/quick_actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsListEnabledQuickActionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident channel quick actions that are enabled for an organisation
     * ListEnabledQuickActions IncidentChannelConfigs
     */
    async incidentChannelConfigsListEnabledQuickActions(initOverrides?: RequestInit): Promise<IncidentChannelConfigsListEnabledQuickActionsResponseBody> {
        const response = await this.incidentChannelConfigsListEnabledQuickActionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all pre-defined incident channel quick actions that are available for an organisation
     * ListQuickActionOptions IncidentChannelConfigs
     */
    async incidentChannelConfigsListQuickActionOptionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsListQuickActionOptionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/quick_action_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsListQuickActionOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all pre-defined incident channel quick actions that are available for an organisation
     * ListQuickActionOptions IncidentChannelConfigs
     */
    async incidentChannelConfigsListQuickActionOptions(initOverrides?: RequestInit): Promise<IncidentChannelConfigsListQuickActionOptionsResponseBody> {
        const response = await this.incidentChannelConfigsListQuickActionOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a bookmark
     * UpdateBookmark IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateBookmarkRaw(requestParameters: IncidentChannelConfigsUpdateBookmarkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsUpdateBookmarkResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentChannelConfigsUpdateBookmark.');
        }

        if (requestParameters.updateBookmarkRequestBody === null || requestParameters.updateBookmarkRequestBody === undefined) {
            throw new runtime.RequiredError('updateBookmarkRequestBody','Required parameter requestParameters.updateBookmarkRequestBody was null or undefined when calling incidentChannelConfigsUpdateBookmark.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/bookmarks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentChannelConfigsUpdateBookmarkRequestBodyToJSON(requestParameters.updateBookmarkRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsUpdateBookmarkResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a bookmark
     * UpdateBookmark IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateBookmark(requestParameters: IncidentChannelConfigsUpdateBookmarkRequest, initOverrides?: RequestInit): Promise<IncidentChannelConfigsUpdateBookmarkResponseBody> {
        const response = await this.incidentChannelConfigsUpdateBookmarkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing bookmarks
     * UpdateBookmarkRanks IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateBookmarkRanksRaw(requestParameters: IncidentChannelConfigsUpdateBookmarkRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsUpdateBookmarkRanksResponseBody>> {
        if (requestParameters.updateBookmarkRanksRequestBody === null || requestParameters.updateBookmarkRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateBookmarkRanksRequestBody','Required parameter requestParameters.updateBookmarkRanksRequestBody was null or undefined when calling incidentChannelConfigsUpdateBookmarkRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/bookmarks/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentChannelConfigsUpdateBookmarkRanksRequestBodyToJSON(requestParameters.updateBookmarkRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsUpdateBookmarkRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing bookmarks
     * UpdateBookmarkRanks IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateBookmarkRanks(requestParameters: IncidentChannelConfigsUpdateBookmarkRanksRequest, initOverrides?: RequestInit): Promise<IncidentChannelConfigsUpdateBookmarkRanksResponseBody> {
        const response = await this.incidentChannelConfigsUpdateBookmarkRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a quick action
     * UpdateQuickAction IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateQuickActionRaw(requestParameters: IncidentChannelConfigsUpdateQuickActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsUpdateQuickActionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentChannelConfigsUpdateQuickAction.');
        }

        if (requestParameters.updateQuickActionRequestBody === null || requestParameters.updateQuickActionRequestBody === undefined) {
            throw new runtime.RequiredError('updateQuickActionRequestBody','Required parameter requestParameters.updateQuickActionRequestBody was null or undefined when calling incidentChannelConfigsUpdateQuickAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/quick_actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentChannelConfigsUpdateQuickActionRequestBodyToJSON(requestParameters.updateQuickActionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsUpdateQuickActionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a quick action
     * UpdateQuickAction IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateQuickAction(requestParameters: IncidentChannelConfigsUpdateQuickActionRequest, initOverrides?: RequestInit): Promise<IncidentChannelConfigsUpdateQuickActionResponseBody> {
        const response = await this.incidentChannelConfigsUpdateQuickActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing quick actions
     * UpdateQuickActionRanks IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateQuickActionRanksRaw(requestParameters: IncidentChannelConfigsUpdateQuickActionRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentChannelConfigsUpdateQuickActionRanksResponseBody>> {
        if (requestParameters.updateQuickActionRanksRequestBody === null || requestParameters.updateQuickActionRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateQuickActionRanksRequestBody','Required parameter requestParameters.updateQuickActionRanksRequestBody was null or undefined when calling incidentChannelConfigsUpdateQuickActionRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/quick_actions/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentChannelConfigsUpdateQuickActionRanksRequestBodyToJSON(requestParameters.updateQuickActionRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentChannelConfigsUpdateQuickActionRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing quick actions
     * UpdateQuickActionRanks IncidentChannelConfigs
     */
    async incidentChannelConfigsUpdateQuickActionRanks(requestParameters: IncidentChannelConfigsUpdateQuickActionRanksRequest, initOverrides?: RequestInit): Promise<IncidentChannelConfigsUpdateQuickActionRanksResponseBody> {
        const response = await this.incidentChannelConfigsUpdateQuickActionRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
