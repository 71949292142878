/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffSetUserPermissionsRequestBody
 */
export interface StaffSetUserPermissionsRequestBody {
    /**
     * The staff permissions this user has.
     * @type {Array<string>}
     * @memberof StaffSetUserPermissionsRequestBody
     */
    permissions: Array<StaffSetUserPermissionsRequestBodyPermissionsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum StaffSetUserPermissionsRequestBodyPermissionsEnum {
    AdjustPlan = 'adjust_plan',
    AttachSubscription = 'attach_subscription',
    BootstrapCustomerChannel = 'bootstrap_customer_channel',
    GrantStaffPermission = 'grant_staff_permission',
    Impersonate = 'impersonate',
    ImpersonateTrialAndDemo = 'impersonate_trial_and_demo',
    ManageFeatureAccess = 'manage_feature_access',
    ManageTrial = 'manage_trial',
    RenameOrganisation = 'rename_organisation',
    TeardownOrganisation = 'teardown_organisation',
    TeardownOnCallForOrganisation = 'teardown_on_call_for_organisation',
    EnableOnCallForOrganisation = 'enable_on_call_for_organisation',
    TeardownResponseForOrganisation = 'teardown_response_for_organisation',
    EnableResponseForOrganisation = 'enable_response_for_organisation',
    MarkSandboxAndDemo = 'mark_sandbox_and_demo',
    GrantOwnerRole = 'grant_owner_role'
}

export function StaffSetUserPermissionsRequestBodyFromJSON(json: any): StaffSetUserPermissionsRequestBody {
    return StaffSetUserPermissionsRequestBodyFromJSONTyped(json, false);
}

export function StaffSetUserPermissionsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffSetUserPermissionsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissions': json['permissions'],
    };
}

export function StaffSetUserPermissionsRequestBodyToJSON(value?: StaffSetUserPermissionsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissions': value.permissions,
    };
}

