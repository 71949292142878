import { Buffer } from "buffer";
import type { PNG as TPNG } from "pngjs";
import { PNG } from "pngjs/browser";

export const cropPNG = async (input: ArrayBuffer): Promise<ArrayBuffer> => {
  const png = PNG.sync.read(new Buffer(input)) as TPNG;

  // Set up some utilities for:
  // 1. getting the alpha value at a position in the image (0=transparent, 255=opaque)
  const alpha = (x: number, y: number) => {
    const idx = (png.width * y + x) << 2;
    // idx is the red channel, idx+1 green, idx+2 blue, idx+3 alpha
    return png.data[idx + 3];
  };

  // 2. checking if a whole row is transparent
  const rowIsTransparent = (y: number) => {
    for (let x = 0; x < png.width; x++) {
      if (alpha(x, y) !== 0) return false;
    }

    return true;
  };

  // 3. checking if a whole column is transparent
  const colIsTransparent = (x: number) => {
    for (let y = 0; y < png.height; y++) {
      if (alpha(x, y) !== 0) return false;
    }

    return true;
  };

  // We then walk in each side in turn, removing rows/columns which are entirely
  // transparent.
  let newMinX = 0,
    newMaxX = png.width,
    newMinY = 0,
    newMaxY = png.height;

  for (let x = 0; x < png.width; x++) {
    if (colIsTransparent(x)) {
      newMinX = x;
    } else {
      break;
    }
  }

  for (let x = png.width - 1; x > 0; x--) {
    if (colIsTransparent(x)) {
      newMaxX = x;
    } else {
      break;
    }
  }

  for (let y = 0; y < png.height; y++) {
    if (rowIsTransparent(y)) {
      newMinY = y;
    } else {
      break;
    }
  }

  for (let y = png.height - 1; y > 0; y--) {
    if (rowIsTransparent(y)) {
      newMaxY = y;
    } else {
      break;
    }
  }

  // Now we create a new PNG, copying a rectangle from the original
  const newWidth = newMaxX - newMinX;
  const newHeight = newMaxY - newMinY;

  // If the crop is too aggro, abandon it: this feels like something has
  // gone very wrong.
  if (newWidth < 100 || newHeight < 10) {
    return input;
  }

  // Write this back out to a buffer, which can be put in GCS.
  const result = new PNG({ width: newWidth, height: newHeight }) as TPNG;
  PNG.bitblt(png, result, newMinX, newMinY, newWidth, newHeight, 0, 0);
  return PNG.sync.write(result);
};
