/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrafanaConfig
 */
export interface GrafanaConfig {
    /**
     * 
     * @type {string}
     * @memberof GrafanaConfig
     */
    api_url: string;
}

export function GrafanaConfigFromJSON(json: any): GrafanaConfig {
    return GrafanaConfigFromJSONTyped(json, false);
}

export function GrafanaConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrafanaConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_url': json['api_url'],
    };
}

export function GrafanaConfigToJSON(value?: GrafanaConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_url': value.api_url,
    };
}

