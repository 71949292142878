import { WorkflowRun } from "@incident-io/api";
import { Button, ButtonTheme, LocalDateTime, Txt } from "@incident-ui";

import { formatDurationShort } from "../../../../utils/datetime";

export const WorkflowsRunScheduledAt = ({ run }: { run: WorkflowRun }) => {
  const incidentLink = run.incident_id && run.incident_reference && (
    <>
      <div className="font-light mx-1">{" in "}</div>
      <Button
        analyticsTrackingId="workflow-run-incident-link"
        theme={ButtonTheme.Unstyled}
        className="hover:underline"
        href={`/incidents/${run.incident_id}`}
      >
        {run.incident_reference}
      </Button>
    </>
  );

  if (run.enqueued_at) {
    return (
      <Txt bold className="flex flex-row">
        <LocalDateTime timestamp={run.enqueued_at} showIcon>
          {formatDurationShort(run.enqueued_at, new Date(), {
            max: { hours: 12 },
            suffix: "ago",
          })}
        </LocalDateTime>
        {incidentLink}
      </Txt>
    );
  }

  // If scheduled at has gone by, we should be in the process of running this
  // workflow. So we should treat it as if we're due right now, eg. 1s.
  let scheduledAt = run.scheduled_at;
  if (run.scheduled_at < new Date()) {
    scheduledAt = new Date(Date.now() - 1000);
  }

  return (
    <Txt bold className="flex flex-row">
      <LocalDateTime timestamp={scheduledAt} showIcon>
        {formatDurationShort(scheduledAt, new Date(), {
          max: { hours: 12 },
          suffix: "from now...",
        })}
      </LocalDateTime>
      {incidentLink}
    </Txt>
  );
};
