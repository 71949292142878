import { NoPermissionMessage } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  AccordionStackedList,
  Avatar,
  ContentBox,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Link,
  Loader,
  LocalDateTime,
  StackedListItem,
  Txt,
} from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import {
  APIKey,
  APIKeyRoleAndAvailabilityNameEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { formatDurationShort } from "../../../utils/datetime";

export const APIKeyList = (): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canView = hasScope(ScopeNameEnum.ApiKeysView);

  const {
    data: { api_keys: keys },
    isLoading: keysLoading,
    error: keysError,
  } = useAPI(canView ? "aPIKeysList" : null, undefined, {
    fallbackData: { api_keys: [] },
  });

  const {
    data: { roles },
    isLoading: rolesLoading,
    error: rolesError,
  } = useAPI("aPIKeysListRolesAndAvailability", undefined, {
    fallbackData: { roles: [] },
  });
  const error = keysError || rolesError;

  const showToast = useToast();

  const { trigger: deleteKey, isMutating: isDeleting } = useAPIMutation(
    "aPIKeysList",
    undefined,
    async (apiClient, { id }) => {
      await apiClient.aPIKeysDelete({ id });
    },
    {
      onSuccess: () => {
        showToast({ theme: ToastTheme.Success, title: "Key deleted" });
      },
    },
  );

  if (!canView) {
    return (
      <ContentBox className="p-4 text-sm">
        <Txt>Only organization owners can view API keys.</Txt>
      </ContentBox>
    );
  }

  const visibleRoleNames = new Set(roles.map((role) => role.name));
  const canDeleteKey = (key: APIKey): boolean => {
    // if any of the roles on this key aren't visible to us, we won't be able to
    // delete it
    return (
      hasScope(ScopeNameEnum.ApiKeysDestroy) &&
      key.roles
        .map((role) => role.name)
        .every((keyRoleName) =>
          visibleRoleNames.has(
            keyRoleName as unknown as APIKeyRoleAndAvailabilityNameEnum,
          ),
        )
    );
  };

  if (error) {
    return (
      <ContentBox className="p-4">
        <GenericErrorMessage error={error} />
      </ContentBox>
    );
  }

  if (keysLoading || rolesLoading) {
    <Loader />;
  }

  return (
    <AccordionStackedList
      items={keys}
      overflowActions={[]}
      deleteAction={{
        id: "delete",
        label: "Delete API Key",
        icon: IconEnum.Delete,
        onDelete: (deletingKeyId) => deleteKey({ id: deletingKeyId }),
        tooltipContent: (key) =>
          canDeleteKey(key) ? null : <>{NoPermissionMessage}</>,
        isDeleting: () => isDeleting,
        deleteModalTitle: (item) => `Delete API Key "${item.name}"?`,
        renderDeleteModalContent: (item) => (
          <>
            <p className="mb-4">
              Are you sure you want to permanently delete this key?
            </p>
            <ContentBox className="p-4 text-sm">
              <KeyDetails {...item} />
            </ContentBox>
          </>
        ),
      }}
      renderAccordion={(item) => {
        return <KeyDetails {...item} />;
      }}
      renderRow={(item) => (
        <StackedListItem
          icon={IconEnum.Key}
          title={item.name}
          noPadding
          description={
            <span className="text-content-tertiary text-xs">
              Last used:{" "}
              {item.last_used_at ? (
                <LocalDateTime
                  timestamp={item.last_used_at}
                  className="text-xs"
                >
                  {formatDurationShort(item.last_used_at, new Date(), {
                    max: { hours: 12 },
                    suffix: "ago",
                  })}
                </LocalDateTime>
              ) : (
                "never"
              )}
            </span>
          }
        />
      )}
      renderEmptyState={() => (
        <EmptyState
          icon={IconEnum.Key}
          title="You haven't created any API keys."
          content={
            <span>
              Use our{" "}
              <Link
                analyticsTrackingId={null}
                href="https://api-docs.incident.io"
                className="text-content-tertiary"
              >
                public API
              </Link>{" "}
              to build your own automations with incident.io
            </span>
          }
        />
      )}
      getRowClassName={() => "hover:bg-surface-secondary"}
    />
  );
};

const KeyDetails = ({ roles, created_by }: APIKey) => {
  return (
    <div className="flex flex-col gap-4">
      <ul className="list-disc text-xs pl-4 text-content-secondary">
        {(roles || []).map((r) => (
          <li key={r.name}>{r.description}</li>
        ))}
      </ul>
      <Txt grey className="flex items-center gap-1 text-xs">
        <Avatar
          size={IconSize.Small}
          url={created_by.avatar_url}
          name={created_by.name}
        />{" "}
        Created by {created_by.name}
      </Txt>
    </div>
  );
};
