import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import _ from "lodash";
import React, { useState } from "react";

import { StackedList, StackedListProps } from "./StackedList";

export const CollapsibleStackedList = <TItemType,>({
  analyticsTrackingId,
  defaultNumberToShow,
  items,
  renderItem,
  ...props
}: {
  analyticsTrackingId: string;
  defaultNumberToShow: number;
  items: TItemType[];
  renderItem: (i: TItemType) => React.ReactElement;
} & Omit<StackedListProps, "values">): React.ReactElement | null => {
  const [showAll, setShowAll] = useState<boolean>(false);

  if (items.length === 0) {
    return null;
  }

  let itemsToShow = _.cloneDeep(items);
  if (!showAll && items.length > defaultNumberToShow) {
    itemsToShow = items.slice(0, defaultNumberToShow);
  }

  return (
    <>
      <StackedList {...props}>{itemsToShow.map(renderItem)}</StackedList>
      {items.length > defaultNumberToShow && (
        <Button
          analyticsTrackingId={`${analyticsTrackingId}-${
            showAll ? "less" : "more"
          }`}
          theme={ButtonTheme.Naked}
          onClick={() => setShowAll(!showAll)}
          className="mt-2 font-medium"
        >
          {showAll ? "Show less" : "Show more..."}
        </Button>
      )}
    </>
  );
};
