import { Textarea } from "@incident-ui";
import { TextareaProps } from "@incident-ui/Textarea/Textarea";
import { ChangeEvent } from "react";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const TextareaV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, Omit<TextareaProps, "required">>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <Textarea
        id={name}
        {...field}
        {...inputProps}
        className={props.className}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          field.onChange(e.target.value);

          onValueChange && onValueChange(e.target.value);
        }}
      />
    </FormInputWrapper>
  );
};
