import {
  InputElementProps,
  parseProps,
} from "@incident-shared/forms/v2/formsv2";
import { Icon, IconEnum, IconSize, StaticSingleSelect } from "@incident-ui";
import styles from "@incident-ui/RadioButton/RadioButton.module.scss";
import React from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { StatusPageIncidentUpdateToStatusEnum as IncidentStatusEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import {
  incidentStatusSelectOptions,
  maintenanceStatusSelectOptions,
} from "../../incidents/utils/utils";

export const StatusInput = <TFormType extends FieldValues>(
  props: InputElementProps<
    TFormType,
    {
      maintenance?: boolean;
      disableComplete?: boolean;
      newIncident?: boolean;
      disabled?: boolean;
    }
  >,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController<TFormType>({
    name,
    rules,
  });

  const statusSelectOptions = props.maintenance
    ? maintenanceStatusSelectOptions
    : incidentStatusSelectOptions;

  return (
    <Form.InputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <StaticSingleSelect
        id={name}
        options={statusSelectOptions}
        label="Status"
        placeholder="Select a status"
        {...field}
        className="lg:hidden"
      />
      <fieldset className="hidden lg:block">
        <legend className="sr-only">Status</legend>
        <div className="mt-2 text-sm flex border border-stroke bg-white rounded-full drop-shadow-sm items-center">
          {statusSelectOptions.map((opt, index) => {
            const isChecked = opt.value === field.value;
            const inputId = `status-${name}-${opt.value}`;
            const isFirst = index === 0;
            const isLast = index === statusSelectOptions.length - 1;

            return (
              <React.Fragment key={opt.value}>
                {index !== 0 && (
                  <Icon
                    id={IconEnum.StatusChevron}
                    size={IconSize.XL}
                    className="flex-none ml-2 text-slate-300"
                  />
                )}
                <div
                  className={tcx(
                    "flex-1 text-sm flex justify-center",
                    isFirst && "ml-4",
                    isLast && "mr-4",
                  )}
                >
                  <span
                    className={tcx(
                      "!flex items-center justify-center text-sm space-x-1.5 transition cursor-pointer",
                      styles.radioButton,
                      isChecked
                        ? "font-medium !text-content-primary"
                        : "!text-content-tertiary hover:!text-content-primary",
                    )}
                  >
                    <input
                      {...inputProps}
                      className="cursor-pointer"
                      type="radio"
                      id={inputId}
                      checked={isChecked}
                      value={opt.value}
                      onChange={field.onChange}
                      disabled={
                        (opt.value === IncidentStatusEnum.MaintenanceComplete &&
                          props.disableComplete) ||
                        props.disabled
                      }
                    />
                    <label htmlFor={inputId} className="cursor-pointer">
                      {opt.label}
                    </label>
                  </span>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </fieldset>
    </Form.InputWrapper>
  );
};
