/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentTimestampValue
 */
export interface IncidentTimestampValue {
    /**
     * When this timestamp value was set
     * @type {Date}
     * @memberof IncidentTimestampValue
     */
    created_at: Date;
    /**
     * Unique ID of this incident timestamp
     * @type {string}
     * @memberof IncidentTimestampValue
     */
    id: string;
    /**
     * The id of the incident that this incident timestamp is associated with.
     * @type {string}
     * @memberof IncidentTimestampValue
     */
    incident_id: string;
    /**
     * The id of the incident timestamp that this incident timestamp value is associated with.
     * @type {string}
     * @memberof IncidentTimestampValue
     */
    incident_timestamp_id: string;
    /**
     * The current value of this timestamp, for this incident
     * @type {Date}
     * @memberof IncidentTimestampValue
     */
    value?: Date;
}

export function IncidentTimestampValueFromJSON(json: any): IncidentTimestampValue {
    return IncidentTimestampValueFromJSONTyped(json, false);
}

export function IncidentTimestampValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'incident_timestamp_id': json['incident_timestamp_id'],
        'value': !exists(json, 'value') ? undefined : (new Date(json['value'])),
    };
}

export function IncidentTimestampValueToJSON(value?: IncidentTimestampValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'incident_timestamp_id': value.incident_timestamp_id,
        'value': value.value === undefined ? undefined : (value.value.toISOString()),
    };
}

