import { EngineScope, Resource, Trigger } from "@incident-io/api";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { WorkflowFormData } from "../common/types";
import { WorkflowsFrequencySettingsExplanation } from "./WorkflowsFrequencySettingsExplanation";
import { WorkflowsFrequencySettingsForm } from "./WorkflowsFrequencySettingsForm";

export const WorkflowsFrequencySettingsSection = ({
  formMethods,
  trigger,
  resources,
  scope,
}: {
  formMethods: UseFormReturn<WorkflowFormData>;
  trigger: Trigger;
  scope: EngineScope;
  resources: Resource[];
}) => {
  // Determines whether we show the once_for form or the explanation
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onceFor = formMethods.watch("once_for");

  return (
    <>
      {isEditing ? (
        <WorkflowsFrequencySettingsForm
          formMethods={formMethods}
          resources={resources}
          scope={scope}
          onClose={() => setIsEditing(false)}
          setIsEditing={setIsEditing}
          trigger={trigger}
        />
      ) : (
        <WorkflowsFrequencySettingsExplanation
          onceFor={onceFor}
          trigger={trigger}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    </>
  );
};
