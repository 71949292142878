import { useSWRConfig } from "swr";

import { useAPI } from "./swr";

// useRevalidate provides you with a callback to invalidate the cache for a set of
// API endpoints, regardless of the payloads. That is, it will invalidate on only
// the endpoint name section of the cache key, rather than the entire cache key.
//
// This is particularly useful when you need to refresh data from a different
// page, and you don't necessarily know what the request params were, or you
// have a lot of dependent resources that may update as a result of a change,
// and want to make sure they all get refreshed.
//
// This is the best way of invalidating an useAPIInfinite call. Because under the
// hood, useAPIInfinite changes the cache keys so that they do not match a
// regular call from useAPIRefetch.
export const useRevalidate: (
  apiMethods: Parameters<typeof useAPI>[0][],
) => () => void = (keys: Parameters<typeof useAPI>[0][]) => {
  const { mutate } = useSWRConfig();

  return () => {
    mutate(
      (key) => {
        return Array.isArray(key) && key.length > 0 && keys.includes(key[0]);
      },
      undefined,
      { revalidate: true },
    );
  };
};
