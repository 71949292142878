import React from "react";
import {
  IncidentDurationMetric,
  IncidentTimestamp,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { prepareRankUpdate } from "src/utils/utils";

import { SettingsSortableList } from "../../SettingsSortableList";
import { DurationMetricRow } from "./DurationMetricRow";

export const DurationMetricsList = ({
  metrics,
  timestamps,
  onEdit,
}: {
  metrics: IncidentDurationMetric[];
  timestamps: IncidentTimestamp[];
  onEdit: (item: IncidentDurationMetric) => void;
}): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { trigger: updateMetricRanks, isMutating: saving } = useAPIMutation(
    "incidentTimestampsListDurationMetrics",
    undefined,
    async (apiClient, updatedMetrics: IncidentDurationMetric[]) =>
      await apiClient.incidentTimestampsUpdateRanksDurationMetrics({
        updateRanksDurationMetricsRequestBody:
          prepareRankUpdate(updatedMetrics),
      }),
  );

  return (
    <SettingsSortableList
      items={metrics}
      updateItemRanks={updateMetricRanks}
      saving={saving}
      canEdit={canEditSettings}
      renderItem={(item) => (
        <DurationMetricRow
          metric={item}
          timestamps={timestamps}
          onEdit={() => onEdit(item)}
        />
      )}
    />
  );
};
