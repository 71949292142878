/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RBACRoleWithAvailability,
    RBACRoleWithAvailabilityFromJSON,
    RBACRoleWithAvailabilityFromJSONTyped,
    RBACRoleWithAvailabilityToJSON,
} from './RBACRoleWithAvailability';
import {
    SeatDescription,
    SeatDescriptionFromJSON,
    SeatDescriptionFromJSONTyped,
    SeatDescriptionToJSON,
} from './SeatDescription';

/**
 * 
 * @export
 * @interface UsersListAvailableSeatsAndRolesResponseBody
 */
export interface UsersListAvailableSeatsAndRolesResponseBody {
    /**
     * 
     * @type {Array<RBACRoleWithAvailability>}
     * @memberof UsersListAvailableSeatsAndRolesResponseBody
     */
    roles: Array<RBACRoleWithAvailability>;
    /**
     * 
     * @type {Array<SeatDescription>}
     * @memberof UsersListAvailableSeatsAndRolesResponseBody
     */
    seats: Array<SeatDescription>;
}

export function UsersListAvailableSeatsAndRolesResponseBodyFromJSON(json: any): UsersListAvailableSeatsAndRolesResponseBody {
    return UsersListAvailableSeatsAndRolesResponseBodyFromJSONTyped(json, false);
}

export function UsersListAvailableSeatsAndRolesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersListAvailableSeatsAndRolesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': ((json['roles'] as Array<any>).map(RBACRoleWithAvailabilityFromJSON)),
        'seats': ((json['seats'] as Array<any>).map(SeatDescriptionFromJSON)),
    };
}

export function UsersListAvailableSeatsAndRolesResponseBodyToJSON(value?: UsersListAvailableSeatsAndRolesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': ((value.roles as Array<any>).map(RBACRoleWithAvailabilityToJSON)),
        'seats': ((value.seats as Array<any>).map(SeatDescriptionToJSON)),
    };
}

