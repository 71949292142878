import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  LoadingBar,
  Txt,
} from "@incident-ui";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import { useParams } from "react-router";
import { useAPI } from "src/utils/swr";

import { CatalogFixedFooter } from "../common/CatalogFixedFooter";
import { CatalogTypeCheckboxGroup } from "./CatalogTypeCheckboxGroup";
import { SelectTypesStepHeading } from "./CatalogTypeManageInGitHubSelectTypesStep";
import {
  ManageInGithubStepConfig,
  ManageInGitHubStepEnum,
} from "./ManageInGithubRoute";

export const DownloadTypesStep = () => {
  const { repoId } = useParams<{ repoId: string }>();
  const navigate = useOrgAwareNavigate();

  const { data, error } = useAPI(
    repoId ? "catalogShowGeneratedRepository" : null,
    { id: repoId ?? "" },
    {
      // We'll poll every second until we get the signed URL, then stop
      refreshInterval: (data) => (data?.signed_url ? 0 : 1000),
      onSuccess: (data) => {
        if (data.signed_url) {
          navigate(`/catalog/manage-in-github/${repoId}/upload`, {
            replace: true,
          });
        }
      },
    },
  );

  if (!repoId || error?.status === 404) {
    return <OrgAwareNavigate to="/catalog/manage-in-github" />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (data?.signed_url) {
    return (
      <OrgAwareNavigate
        replace
        to={`/catalog/manage-in-github/${repoId}/upload`}
      />
    );
  }

  return (
    <>
      <NakedSteps
        steps={ManageInGithubStepConfig}
        currentStepID={ManageInGitHubStepEnum.SelectTypes}
        color={ColorPaletteEnum.Slate}
      />
      <div className="flex flex-col pt-6 w-full mx-auto">
        <SelectTypesStepHeading />
        {data ? (
          <CatalogTypeCheckboxGroup
            downloadingRepo
            value={data.generated_repository.selected_catalog_type_ids}
            onChange={() => null}
          />
        ) : (
          <LoadingBar className="h-32" />
        )}
      </div>

      <CatalogFixedFooter>
        <Txt className="text-slate-600">Preparing download...</Txt>
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          loading={true}
          disabled={true}
        >
          Continue
        </Button>
      </CatalogFixedFooter>
    </>
  );
};
