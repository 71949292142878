/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageAffectedComponent,
    StatusPageAffectedComponentFromJSON,
    StatusPageAffectedComponentFromJSONTyped,
    StatusPageAffectedComponentToJSON,
} from './StatusPageAffectedComponent';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageContentIncidentUpdate
 */
export interface StatusPageContentIncidentUpdate {
    /**
     * Whether this updated was automatically created by us or not
     * @type {boolean}
     * @memberof StatusPageContentIncidentUpdate
     */
    automated_update: boolean;
    /**
     * The updated statuses of affected components
     * @type {Array<StatusPageAffectedComponent>}
     * @memberof StatusPageContentIncidentUpdate
     */
    component_statuses: Array<StatusPageAffectedComponent>;
    /**
     * A unique ID for this status page incident update
     * @type {string}
     * @memberof StatusPageContentIncidentUpdate
     */
    id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageContentIncidentUpdate
     */
    message: TextNode;
    /**
     * 
     * @type {string}
     * @memberof StatusPageContentIncidentUpdate
     */
    message_string?: string;
    /**
     * When the update was published
     * @type {string}
     * @memberof StatusPageContentIncidentUpdate
     */
    published_at: string;
    /**
     * The new status of this incident
     * @type {string}
     * @memberof StatusPageContentIncidentUpdate
     */
    to_status: StatusPageContentIncidentUpdateToStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageContentIncidentUpdateToStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function StatusPageContentIncidentUpdateFromJSON(json: any): StatusPageContentIncidentUpdate {
    return StatusPageContentIncidentUpdateFromJSONTyped(json, false);
}

export function StatusPageContentIncidentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentIncidentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'automated_update': json['automated_update'],
        'component_statuses': ((json['component_statuses'] as Array<any>).map(StatusPageAffectedComponentFromJSON)),
        'id': json['id'],
        'message': TextNodeFromJSON(json['message']),
        'message_string': !exists(json, 'message_string') ? undefined : json['message_string'],
        'published_at': json['published_at'],
        'to_status': json['to_status'],
    };
}

export function StatusPageContentIncidentUpdateToJSON(value?: StatusPageContentIncidentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automated_update': value.automated_update,
        'component_statuses': ((value.component_statuses as Array<any>).map(StatusPageAffectedComponentToJSON)),
        'id': value.id,
        'message': TextNodeToJSON(value.message),
        'message_string': value.message_string,
        'published_at': value.published_at,
        'to_status': value.to_status,
    };
}

