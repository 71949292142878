import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentStatusDeclined = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <rect
      width={1.771}
      height={6.199}
      x={5.153}
      y={6.406}
      fill="#5F5F68"
      rx={0.886}
      transform="rotate(-45 5.153 6.406)"
    />
    <rect
      width={1.771}
      height={6.199}
      x={9.537}
      y={5.153}
      fill="#5F5F68"
      rx={0.886}
      transform="rotate(45 9.537 5.153)"
    />
    <circle
      cx={8.003}
      cy={8.003}
      r={6.25}
      stroke="#5F5F68"
      strokeWidth={1.5}
      opacity={0.3}
    />
  </svg>
);
export default SvgIncidentStatusDeclined;
