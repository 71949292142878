import { IdentityStaffPermissionsEnum as StaffPermissionEnum } from "@incident-io/api";
import { permissionName } from "src/components/staff-room/staffPermissions";
import { useIdentity } from "src/contexts/IdentityContext";

export { StaffPermissionEnum };

export const useCheckStaffPermissions = (
  permission: StaffPermissionEnum,
  alwaysDisabled?: boolean,
): { disabled?: boolean; disabledTooltipContent?: React.ReactNode } => {
  const { identity } = useIdentity();
  if (!identity) {
    return {};
  }

  const permissions = identity.staff_permissions || [];

  if (permissions.includes(permission)) {
    return alwaysDisabled ? { disabled: true } : {};
  }

  const name = permissionName[permission].label;

  return {
    disabled: true,
    disabledTooltipContent: `You need the '${name}' permission to do this`,
  };
};
