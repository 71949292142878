import { tcx } from "src/utils/tailwind-classes";

import styles from "./ActiveCircle.module.scss";

export const ActiveCircle = ({
  className,
  isAnimated = true,
}: {
  className?: string;
  isAnimated?: boolean;
}) => {
  return (
    <div
      className={tcx(
        styles.activeCircle,
        className,
        isAnimated && styles.animatedActiveCircle,
      )}
    />
  );
};
