import cx from "classnames";
import React from "react";

type Props = { className?: string } & React.HTMLAttributes<HTMLDivElement>;

export const Card = React.forwardRef(
  (
    { children, className, ...props }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      ref={ref}
      {...props}
      className={cx(
        className,
        "w-auto rounded-lg",
        "bg-default",
        "border border-transparent",
        "hover:bg-surface-tertiary/40 dark:hover:bg-surface-invert/60 transition",
      )}
    >
      <>{children}</>
    </div>
  ),
);
Card.displayName = "Card";
