/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManagedResource,
    ManagedResourceFromJSON,
    ManagedResourceFromJSONTyped,
    ManagedResourceToJSON,
} from './ManagedResource';

/**
 * 
 * @export
 * @interface ManagedResourcesListResponseBody
 */
export interface ManagedResourcesListResponseBody {
    /**
     * 
     * @type {Array<ManagedResource>}
     * @memberof ManagedResourcesListResponseBody
     */
    managed_resources: Array<ManagedResource>;
}

export function ManagedResourcesListResponseBodyFromJSON(json: any): ManagedResourcesListResponseBody {
    return ManagedResourcesListResponseBodyFromJSONTyped(json, false);
}

export function ManagedResourcesListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagedResourcesListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managed_resources': ((json['managed_resources'] as Array<any>).map(ManagedResourceFromJSON)),
    };
}

export function ManagedResourcesListResponseBodyToJSON(value?: ManagedResourcesListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managed_resources': ((value.managed_resources as Array<any>).map(ManagedResourceToJSON)),
    };
}

