import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  Input,
  Txt,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useAPIMutation } from "src/utils/swr";

const runbookURL =
  "https://www.notion.so/incidentio/Cancelation-of-lower-ARR-account-Doc-a97aad1a2dfd4649bd8786c114a589aa?utm_content=a97aad1a-2dfd-4649-bd87-86c114a589aa&utm_campaign=T01DMDZT3PD&n=slack&n=slack_link_unfurl&pvs=6";

export const ArchiveButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.TeardownOrganisation,
  );

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        theme={ButtonTheme.Destroy}
        {...disabledProps}
      >
        Archive
      </GatedButton>

      {isOpen && <ArchiveModal data={data} onClose={() => setIsOpen(false)} />}
    </>
  );
};

const ArchiveModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm<{ check: string }>();
  const navigate = useOrgAwareNavigate();

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    { organisationSlug: data.organisation_slug },
    async (apiClient) => {
      await apiClient.staffArchiveOrganisation({
        organisationSlug: data.organisation_slug,
      });
      // Once disabled, the org is not visible in staff room anymore, so
      // redirect away
      navigate("/staff-room");
    },
  );

  const requiredInput = data.organisation_name;

  const subId = data.stripe_subscription_id;

  return (
    <ConfirmationDialog
      isOpen
      analyticsTrackingId={null}
      title="Archive organisation"
      onCancel={onClose}
      onConfirm={() => trigger({})}
      confirmButtonText={`Archive ${data.organisation_name}`}
      saving={isMutating}
      disabled={formMethods.watch("check") !== requiredInput}
      disabledTooltipContent={"That doesn't match the organisation name"}
      className="text-sm space-y-2"
    >
      <Form.Root formMethods={formMethods} onSubmit={trigger}>
        {genericError && <Form.ErrorMessage message={genericError} />}
        <p>
          Archiving an organisation is used for customers that have churned, or
          trials that have ended.
        </p>
        {subId && (
          <p>
            This customer has paid us. Have you confirmed that their{" "}
            <Button
              theme={ButtonTheme.Link}
              href={`https://dashboard.stripe.com/subscriptions/${subId}`}
              analyticsTrackingId={null}
              openInNewTab
            >
              subscription
            </Button>{" "}
            has been cancelled?
          </p>
        )}
        <p>
          Make sure that you have followed the{" "}
          <Button
            href={runbookURL}
            analyticsTrackingId={null}
            openInNewTab
            theme={ButtonTheme.Link}
          >
            runbook
          </Button>{" "}
          for churned accounts before clicking this button
        </p>
        <p>
          You{" "}
          <Txt inline bold>
            can&apos;t undo this action
          </Txt>{" "}
          without paging an on-call engineer.
        </p>
        <hr className="text-slate-200 !my-4" />
        <Form.InputWrapper
          name="name"
          label={`Type '${requiredInput}' to confirm`}
          labelWrapperClassName="mb-1"
        >
          <Input id="check" {...formMethods.register("check")} />
        </Form.InputWrapper>
      </Form.Root>
    </ConfirmationDialog>
  );
};
