/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentAttachmentsCreateRequestBody,
    IncidentAttachmentsCreateRequestBodyFromJSON,
    IncidentAttachmentsCreateRequestBodyToJSON,
    IncidentAttachmentsCreateResponseBody,
    IncidentAttachmentsCreateResponseBodyFromJSON,
    IncidentAttachmentsCreateResponseBodyToJSON,
    IncidentAttachmentsListResponseBody,
    IncidentAttachmentsListResponseBodyFromJSON,
    IncidentAttachmentsListResponseBodyToJSON,
} from '../models';

export interface IncidentAttachmentsCreateRequest {
    createRequestBody: IncidentAttachmentsCreateRequestBody;
}

export interface IncidentAttachmentsListRequest {
    incidentId?: string;
    resourceType?: string;
    resourceExternalId?: string;
}

export interface IncidentAttachmentsRemoveRequest {
    id: string;
}

/**
 * 
 */
export class IncidentAttachmentsApi extends runtime.BaseAPI {

    /**
     * Add an attachment to an incident
     * Create IncidentAttachments
     */
    async incidentAttachmentsCreateRaw(requestParameters: IncidentAttachmentsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentAttachmentsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentAttachmentsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_attachments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentAttachmentsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentAttachmentsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Add an attachment to an incident
     * Create IncidentAttachments
     */
    async incidentAttachmentsCreate(requestParameters: IncidentAttachmentsCreateRequest, initOverrides?: RequestInit): Promise<IncidentAttachmentsCreateResponseBody> {
        const response = await this.incidentAttachmentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List external resources associated with this incident
     * List IncidentAttachments
     */
    async incidentAttachmentsListRaw(requestParameters: IncidentAttachmentsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentAttachmentsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource_type'] = requestParameters.resourceType;
        }

        if (requestParameters.resourceExternalId !== undefined) {
            queryParameters['resource_external_id'] = requestParameters.resourceExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_attachments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentAttachmentsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List external resources associated with this incident
     * List IncidentAttachments
     */
    async incidentAttachmentsList(requestParameters: IncidentAttachmentsListRequest, initOverrides?: RequestInit): Promise<IncidentAttachmentsListResponseBody> {
        const response = await this.incidentAttachmentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove an attachment from an incident
     * Remove IncidentAttachments
     */
    async incidentAttachmentsRemoveRaw(requestParameters: IncidentAttachmentsRemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentAttachmentsRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_attachments/{id}/actions/remove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove an attachment from an incident
     * Remove IncidentAttachments
     */
    async incidentAttachmentsRemove(requestParameters: IncidentAttachmentsRemoveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentAttachmentsRemoveRaw(requestParameters, initOverrides);
    }

}
