/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoUnrelatedAfterIncidentDeclined,
    AutoUnrelatedAfterIncidentDeclinedFromJSON,
    AutoUnrelatedAfterIncidentDeclinedFromJSONTyped,
    AutoUnrelatedAfterIncidentDeclinedToJSON,
} from './AutoUnrelatedAfterIncidentDeclined';
import {
    CreatedIncident,
    CreatedIncidentFromJSON,
    CreatedIncidentFromJSONTyped,
    CreatedIncidentToJSON,
} from './CreatedIncident';
import {
    EscalationDeferred,
    EscalationDeferredFromJSON,
    EscalationDeferredFromJSONTyped,
    EscalationDeferredToJSON,
} from './EscalationDeferred';
import {
    EscalationStarted,
    EscalationStartedFromJSON,
    EscalationStartedFromJSONTyped,
    EscalationStartedToJSON,
} from './EscalationStarted';
import {
    MarkedRelatedToIncident,
    MarkedRelatedToIncidentFromJSON,
    MarkedRelatedToIncidentFromJSONTyped,
    MarkedRelatedToIncidentToJSON,
} from './MarkedRelatedToIncident';
import {
    MarkedUnrelatedToIncident,
    MarkedUnrelatedToIncidentFromJSON,
    MarkedUnrelatedToIncidentFromJSONTyped,
    MarkedUnrelatedToIncidentToJSON,
} from './MarkedUnrelatedToIncident';
import {
    MergedIntoIncident,
    MergedIntoIncidentFromJSON,
    MergedIntoIncidentFromJSONTyped,
    MergedIntoIncidentToJSON,
} from './MergedIntoIncident';
import {
    PendingInIncidents,
    PendingInIncidentsFromJSON,
    PendingInIncidentsFromJSONTyped,
    PendingInIncidentsToJSON,
} from './PendingInIncidents';

/**
 * 
 * @export
 * @interface AlertTimelineItemContent
 */
export interface AlertTimelineItemContent {
    /**
     * 
     * @type {object}
     * @memberof AlertTimelineItemContent
     */
    alert_created?: object;
    /**
     * 
     * @type {object}
     * @memberof AlertTimelineItemContent
     */
    alert_resolved?: object;
    /**
     * 
     * @type {AutoUnrelatedAfterIncidentDeclined}
     * @memberof AlertTimelineItemContent
     */
    auto_unrelated_after_incident_declined?: AutoUnrelatedAfterIncidentDeclined;
    /**
     * 
     * @type {CreatedIncident}
     * @memberof AlertTimelineItemContent
     */
    created_incident?: CreatedIncident;
    /**
     * 
     * @type {EscalationDeferred}
     * @memberof AlertTimelineItemContent
     */
    escalation_deferred?: EscalationDeferred;
    /**
     * 
     * @type {EscalationStarted}
     * @memberof AlertTimelineItemContent
     */
    escalation_started?: EscalationStarted;
    /**
     * 
     * @type {MarkedRelatedToIncident}
     * @memberof AlertTimelineItemContent
     */
    marked_related_to_incident?: MarkedRelatedToIncident;
    /**
     * 
     * @type {MarkedUnrelatedToIncident}
     * @memberof AlertTimelineItemContent
     */
    marked_unrelated_to_incident?: MarkedUnrelatedToIncident;
    /**
     * 
     * @type {MergedIntoIncident}
     * @memberof AlertTimelineItemContent
     */
    merged_into_incident?: MergedIntoIncident;
    /**
     * 
     * @type {PendingInIncidents}
     * @memberof AlertTimelineItemContent
     */
    pending_in_incidents?: PendingInIncidents;
}

export function AlertTimelineItemContentFromJSON(json: any): AlertTimelineItemContent {
    return AlertTimelineItemContentFromJSONTyped(json, false);
}

export function AlertTimelineItemContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertTimelineItemContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_created': !exists(json, 'alert_created') ? undefined : json['alert_created'],
        'alert_resolved': !exists(json, 'alert_resolved') ? undefined : json['alert_resolved'],
        'auto_unrelated_after_incident_declined': !exists(json, 'auto_unrelated_after_incident_declined') ? undefined : AutoUnrelatedAfterIncidentDeclinedFromJSON(json['auto_unrelated_after_incident_declined']),
        'created_incident': !exists(json, 'created_incident') ? undefined : CreatedIncidentFromJSON(json['created_incident']),
        'escalation_deferred': !exists(json, 'escalation_deferred') ? undefined : EscalationDeferredFromJSON(json['escalation_deferred']),
        'escalation_started': !exists(json, 'escalation_started') ? undefined : EscalationStartedFromJSON(json['escalation_started']),
        'marked_related_to_incident': !exists(json, 'marked_related_to_incident') ? undefined : MarkedRelatedToIncidentFromJSON(json['marked_related_to_incident']),
        'marked_unrelated_to_incident': !exists(json, 'marked_unrelated_to_incident') ? undefined : MarkedUnrelatedToIncidentFromJSON(json['marked_unrelated_to_incident']),
        'merged_into_incident': !exists(json, 'merged_into_incident') ? undefined : MergedIntoIncidentFromJSON(json['merged_into_incident']),
        'pending_in_incidents': !exists(json, 'pending_in_incidents') ? undefined : PendingInIncidentsFromJSON(json['pending_in_incidents']),
    };
}

export function AlertTimelineItemContentToJSON(value?: AlertTimelineItemContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_created': value.alert_created,
        'alert_resolved': value.alert_resolved,
        'auto_unrelated_after_incident_declined': AutoUnrelatedAfterIncidentDeclinedToJSON(value.auto_unrelated_after_incident_declined),
        'created_incident': CreatedIncidentToJSON(value.created_incident),
        'escalation_deferred': EscalationDeferredToJSON(value.escalation_deferred),
        'escalation_started': EscalationStartedToJSON(value.escalation_started),
        'marked_related_to_incident': MarkedRelatedToIncidentToJSON(value.marked_related_to_incident),
        'marked_unrelated_to_incident': MarkedUnrelatedToIncidentToJSON(value.marked_unrelated_to_incident),
        'merged_into_incident': MergedIntoIncidentToJSON(value.merged_into_incident),
        'pending_in_incidents': PendingInIncidentsToJSON(value.pending_in_incidents),
    };
}

