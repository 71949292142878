import type { SVGProps } from "react";
import * as React from "react";
const SvgRepeatOnce = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.556 3.074v3.37a.667.667 0 0 0 1.333 0V1.556c0-.3-.203-.561-.493-.64a.673.673 0 0 0-.75.305c-.018.03-.442.728-1.298.892a.667.667 0 0 0 .25 1.31 3.18 3.18 0 0 0 .957-.35Z"
    />
    <path
      fill="currentColor"
      d="M12.222 3.111h-1.555a.667.667 0 0 0 0 1.333h1.555c.613 0 1.111.5 1.111 1.112v4.888c0 .613-.498 1.112-1.11 1.112H8.72l1.084-1.085a.667.667 0 1 0-.943-.943l-2.215 2.215a.667.667 0 0 0 0 .943l2.215 2.216a.665.665 0 0 0 .942 0 .667.667 0 0 0 0-.943l-1.07-1.071h3.488a2.446 2.446 0 0 0 2.445-2.444V5.556a2.446 2.446 0 0 0-2.445-2.445ZM4.667 3.111h-.89a2.446 2.446 0 0 0-2.444 2.445v4.888a2.446 2.446 0 0 0 2.445 2.445h.666a.667.667 0 0 0 0-1.333h-.666a1.113 1.113 0 0 1-1.111-1.111v-4.89c0-.612.498-1.11 1.11-1.11h.89a.667.667 0 0 0 0-1.334Z"
    />
  </svg>
);
export default SvgRepeatOnce;
