/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSlim,
    AlertSlimFromJSON,
    AlertSlimFromJSONTyped,
    AlertSlimToJSON,
} from './AlertSlim';
import {
    IncidentSlim,
    IncidentSlimFromJSON,
    IncidentSlimFromJSONTyped,
    IncidentSlimToJSON,
} from './IncidentSlim';

/**
 * 
 * @export
 * @interface IncidentAlertSlim
 */
export interface IncidentAlertSlim {
    /**
     * 
     * @type {AlertSlim}
     * @memberof IncidentAlertSlim
     */
    alert: AlertSlim;
    /**
     * The ID of the alert route that created this incident alert
     * @type {string}
     * @memberof IncidentAlertSlim
     */
    alert_route_id: string;
    /**
     * The ID of this alert
     * @type {string}
     * @memberof IncidentAlertSlim
     */
    id: string;
    /**
     * 
     * @type {IncidentSlim}
     * @memberof IncidentAlertSlim
     */
    incident: IncidentSlim;
    /**
     * The ID of the incident that this alert is potentially related to
     * @type {string}
     * @memberof IncidentAlertSlim
     */
    incident_id: string;
    /**
     * The state of this incident alert
     * @type {string}
     * @memberof IncidentAlertSlim
     */
    state: IncidentAlertSlimStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentAlertSlimStateEnum {
    Pending = 'pending',
    Related = 'related',
    Unrelated = 'unrelated'
}

export function IncidentAlertSlimFromJSON(json: any): IncidentAlertSlim {
    return IncidentAlertSlimFromJSONTyped(json, false);
}

export function IncidentAlertSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAlertSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert': AlertSlimFromJSON(json['alert']),
        'alert_route_id': json['alert_route_id'],
        'id': json['id'],
        'incident': IncidentSlimFromJSON(json['incident']),
        'incident_id': json['incident_id'],
        'state': json['state'],
    };
}

export function IncidentAlertSlimToJSON(value?: IncidentAlertSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert': AlertSlimToJSON(value.alert),
        'alert_route_id': value.alert_route_id,
        'id': value.id,
        'incident': IncidentSlimToJSON(value.incident),
        'incident_id': value.incident_id,
        'state': value.state,
    };
}

