/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HolidaysCreateFeedRequestBody,
    HolidaysCreateFeedRequestBodyFromJSON,
    HolidaysCreateFeedRequestBodyToJSON,
    HolidaysCreateFeedResponseBody,
    HolidaysCreateFeedResponseBodyFromJSON,
    HolidaysCreateFeedResponseBodyToJSON,
    HolidaysListCountriesResponseBody,
    HolidaysListCountriesResponseBodyFromJSON,
    HolidaysListCountriesResponseBodyToJSON,
    HolidaysListFeedsResponseBody,
    HolidaysListFeedsResponseBodyFromJSON,
    HolidaysListFeedsResponseBodyToJSON,
    HolidaysListPublicEntriesResponseBody,
    HolidaysListPublicEntriesResponseBodyFromJSON,
    HolidaysListPublicEntriesResponseBodyToJSON,
    HolidaysListUserEntriesResponseBody,
    HolidaysListUserEntriesResponseBodyFromJSON,
    HolidaysListUserEntriesResponseBodyToJSON,
    HolidaysUpdateFeedRequestBody,
    HolidaysUpdateFeedRequestBodyFromJSON,
    HolidaysUpdateFeedRequestBodyToJSON,
    HolidaysUpdateFeedResponseBody,
    HolidaysUpdateFeedResponseBodyFromJSON,
    HolidaysUpdateFeedResponseBodyToJSON,
} from '../models';

export interface HolidaysCreateFeedRequest {
    createFeedRequestBody: HolidaysCreateFeedRequestBody;
}

export interface HolidaysDestroyFeedRequest {
    id: string;
}

export interface HolidaysListPublicEntriesRequest {
    countryCodes: Array<string>;
    from?: string;
    until?: string;
}

export interface HolidaysListUserEntriesRequest {
    userIds: Array<string>;
    from?: string;
    until?: string;
}

export interface HolidaysUpdateFeedRequest {
    id: string;
    updateFeedRequestBody: HolidaysUpdateFeedRequestBody;
}

/**
 * 
 */
export class HolidaysApi extends runtime.BaseAPI {

    /**
     * Create a holiday user feed
     * CreateFeed Holidays
     */
    async holidaysCreateFeedRaw(requestParameters: HolidaysCreateFeedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HolidaysCreateFeedResponseBody>> {
        if (requestParameters.createFeedRequestBody === null || requestParameters.createFeedRequestBody === undefined) {
            throw new runtime.RequiredError('createFeedRequestBody','Required parameter requestParameters.createFeedRequestBody was null or undefined when calling holidaysCreateFeed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/holiday_user_feeds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HolidaysCreateFeedRequestBodyToJSON(requestParameters.createFeedRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HolidaysCreateFeedResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a holiday user feed
     * CreateFeed Holidays
     */
    async holidaysCreateFeed(requestParameters: HolidaysCreateFeedRequest, initOverrides?: RequestInit): Promise<HolidaysCreateFeedResponseBody> {
        const response = await this.holidaysCreateFeedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive a holiday user feed
     * DestroyFeed Holidays
     */
    async holidaysDestroyFeedRaw(requestParameters: HolidaysDestroyFeedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling holidaysDestroyFeed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/holiday_user_feeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive a holiday user feed
     * DestroyFeed Holidays
     */
    async holidaysDestroyFeed(requestParameters: HolidaysDestroyFeedRequest, initOverrides?: RequestInit): Promise<void> {
        await this.holidaysDestroyFeedRaw(requestParameters, initOverrides);
    }

    /**
     * List all countries
     * ListCountries Holidays
     */
    async holidaysListCountriesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<HolidaysListCountriesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HolidaysListCountriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all countries
     * ListCountries Holidays
     */
    async holidaysListCountries(initOverrides?: RequestInit): Promise<HolidaysListCountriesResponseBody> {
        const response = await this.holidaysListCountriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all user holiday feeds
     * ListFeeds Holidays
     */
    async holidaysListFeedsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<HolidaysListFeedsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/holiday_user_feeds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HolidaysListFeedsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all user holiday feeds
     * ListFeeds Holidays
     */
    async holidaysListFeeds(initOverrides?: RequestInit): Promise<HolidaysListFeedsResponseBody> {
        const response = await this.holidaysListFeedsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List public holiday entries for a given country
     * ListPublicEntries Holidays
     */
    async holidaysListPublicEntriesRaw(requestParameters: HolidaysListPublicEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HolidaysListPublicEntriesResponseBody>> {
        if (requestParameters.countryCodes === null || requestParameters.countryCodes === undefined) {
            throw new runtime.RequiredError('countryCodes','Required parameter requestParameters.countryCodes was null or undefined when calling holidaysListPublicEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.countryCodes) {
            queryParameters['country_codes'] = requestParameters.countryCodes;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = requestParameters.until;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/holidays_public_entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HolidaysListPublicEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List public holiday entries for a given country
     * ListPublicEntries Holidays
     */
    async holidaysListPublicEntries(requestParameters: HolidaysListPublicEntriesRequest, initOverrides?: RequestInit): Promise<HolidaysListPublicEntriesResponseBody> {
        const response = await this.holidaysListPublicEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List user holiday entries by feeds
     * ListUserEntries Holidays
     */
    async holidaysListUserEntriesRaw(requestParameters: HolidaysListUserEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HolidaysListUserEntriesResponseBody>> {
        if (requestParameters.userIds === null || requestParameters.userIds === undefined) {
            throw new runtime.RequiredError('userIds','Required parameter requestParameters.userIds was null or undefined when calling holidaysListUserEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = requestParameters.until;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/holiday_user_entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HolidaysListUserEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List user holiday entries by feeds
     * ListUserEntries Holidays
     */
    async holidaysListUserEntries(requestParameters: HolidaysListUserEntriesRequest, initOverrides?: RequestInit): Promise<HolidaysListUserEntriesResponseBody> {
        const response = await this.holidaysListUserEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a holiday user feed
     * UpdateFeed Holidays
     */
    async holidaysUpdateFeedRaw(requestParameters: HolidaysUpdateFeedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HolidaysUpdateFeedResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling holidaysUpdateFeed.');
        }

        if (requestParameters.updateFeedRequestBody === null || requestParameters.updateFeedRequestBody === undefined) {
            throw new runtime.RequiredError('updateFeedRequestBody','Required parameter requestParameters.updateFeedRequestBody was null or undefined when calling holidaysUpdateFeed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/holiday_user_feeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HolidaysUpdateFeedRequestBodyToJSON(requestParameters.updateFeedRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HolidaysUpdateFeedResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a holiday user feed
     * UpdateFeed Holidays
     */
    async holidaysUpdateFeed(requestParameters: HolidaysUpdateFeedRequest, initOverrides?: RequestInit): Promise<HolidaysUpdateFeedResponseBody> {
        const response = await this.holidaysUpdateFeedRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
