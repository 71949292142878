/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RankUpdate
 */
export interface RankUpdate {
    /**
     * Desired rank for this resource
     * @type {number}
     * @memberof RankUpdate
     */
    rank: number;
    /**
     * ID of the resource
     * @type {string}
     * @memberof RankUpdate
     */
    resource_id: string;
}

export function RankUpdateFromJSON(json: any): RankUpdate {
    return RankUpdateFromJSONTyped(json, false);
}

export function RankUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RankUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rank': json['rank'],
        'resource_id': json['resource_id'],
    };
}

export function RankUpdateToJSON(value?: RankUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rank': value.rank,
        'resource_id': value.resource_id,
    };
}

