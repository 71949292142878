import {
  AlertAttribute,
  AlertSchema,
  AlertSchemaPayload,
  ScopeNameEnum,
} from "@incident-io/api";
import { CatalogTypeSelectorV2 } from "@incident-shared/catalog";
import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { DisableConfirmationModal } from "@incident-shared/forms/DisableConfirmationModal/DisableConfirmationModal";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { DragHandle } from "@incident-shared/settings";
import {
  Button,
  ButtonTheme,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  IconEnum,
  IconSize,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import { Component, useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";

export const AlertAttributesForm = ({
  alertSchema,
}: {
  alertSchema: AlertSchema;
}) => {
  const showToast = useToast();

  const formMethods = useForm<AlertSchemaPayload>({
    defaultValues: {
      attributes: alertSchema.attributes,
      version: alertSchema.version,
    },
  });

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const onClose = () => setShowDeleteConfirmModal(false);
  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const mutation = useAPIMutation(
    "alertsShowSchema",
    undefined,
    async (client, payload: AlertSchemaPayload) => {
      setShowDeleteConfirmModal(false);
      const data = await client.alertsUpdateSchema({
        updateSchemaRequestBody: {
          alert_schema: payload,
        },
      });

      formMethods.setValue<"version">("version", data.alert_schema.version);
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Alert attributes updated",
          toastSide: ToastSideEnum.TopRight,
        });
      },
      onError: async () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Could not update your alert attributes.",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  const { hasScope } = useIdentity();
  const canEditSchema = hasScope(ScopeNameEnum.AlertSchemaUpdate);

  // Fetch the attributes so we can use them while dragging.
  const attributes = formMethods.watch("attributes");

  const attributesBeingRemoved = alertSchema.attributes.filter(
    (existingAttribute) =>
      !attributes.find((attr) => attr.id === existingAttribute.id),
  );

  const { append, fields, remove } = useFieldArray({
    control: formMethods.control,
    name: "attributes",
    keyName: "key",
  });

  const [isDragging, setIsDragging] = useState(false);

  // We need to fire just before we start dragging to support the locked cells:
  // see the LockedCell component for more detail.
  const onBeforeDragStart = useCallback(() => setIsDragging(true), []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setIsDragging(false);

      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      const attributesClone = _.clone(attributes) || [];

      // Snip out the element we moved
      const [removed] = attributesClone.splice(fromIndex, 1);

      // Insert it back into the list wherever we dragged it to
      attributesClone.splice(toIndex, 0, removed);

      formMethods.setValue("attributes", attributesClone, {
        shouldDirty: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes],
  );

  return (
    <div>
      <div>
        <Form.Root
          onSubmit={(formData: AlertSchemaPayload) => {
            if (formData.version !== alertSchema.version) {
              showToast({
                theme: ToastTheme.Error,
                title:
                  "The alert schema has been updated by another user. Please refresh the page to see the latest changes.",
                toastSide: ToastSideEnum.TopRight,
              });
              return;
            }
            if (attributesBeingRemoved.length > 0) {
              setShowDeleteConfirmModal(true);
            } else {
              mutation.trigger(formData);
            }
          }}
          saving={mutation.isMutating}
          formMethods={formMethods}
          genericError={mutation.genericError}
          warnWhenDirty
        >
          <div>
            <fieldset>
              <DeprecatedTable className={"shadow-none"}>
                <DeprecatedTableHeaderRow
                  className={
                    "bg-white rounded-t-xl text-xs font-medium text-content-secondary"
                  }
                >
                  <DeprecatedTableHeaderCell>Name</DeprecatedTableHeaderCell>
                  <DeprecatedTableHeaderCell className="min-w-[270px]">
                    <div className="flex-center-y gap-1">
                      Resource type
                      <Tooltip
                        content={
                          <span>
                            The resource type defines what kind of value this
                            attribute represents. It can be a primitive value
                            such as a string or number, or can refer to a
                            Catalog type.
                          </span>
                        }
                      />
                    </div>
                  </DeprecatedTableHeaderCell>
                  <DeprecatedTableHeaderCell className={"min-w-[100px]"}>
                    Multi-value
                  </DeprecatedTableHeaderCell>
                  <DeprecatedTableHeaderCell textHidden>
                    delete
                  </DeprecatedTableHeaderCell>
                </DeprecatedTableHeaderRow>
                {attributes.length === 0 && (
                  <tr>
                    <td align="center" colSpan={4}>
                      <div className={"py-10"}>
                        <Txt className="font-medium">
                          You haven&rsquo;t configured any attributes
                        </Txt>
                        <Txt>
                          You can add attributes to use across your alert
                          sources here, or from one of your alert sources.
                        </Txt>
                      </div>
                    </td>
                  </tr>
                )}
                <DragDropContext
                  onBeforeDragStart={onBeforeDragStart}
                  onDragEnd={onDragEnd}
                >
                  <Droppable droppableId="alertSchemaAttributes">
                    {(provided: DroppableProvided) => {
                      return (
                        <tbody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {fields.map((field, idx) => (
                            <Draggable
                              key={field.id}
                              draggableId={field.key}
                              index={idx}
                            >
                              {(provided) => {
                                return (
                                  <AlertAttributeRow
                                    index={idx}
                                    key={field.key}
                                    onDelete={() => remove(idx)}
                                    formMethods={formMethods}
                                    provided={provided}
                                    isDragging={isDragging}
                                    existingAttributes={alertSchema.attributes}
                                  />
                                );
                              }}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </DeprecatedTable>
            </fieldset>
          </div>
          <div className="flex justify-end space-x-3 items-center">
            <Button
              analyticsTrackingId="alert-schema-add-attribute"
              onClick={() => {
                append({
                  id: uuidv4(),
                  name: "",
                  array: false,
                  type: "",
                });
              }}
              className="!ml-3 !my-2"
            >
              Add attribute
            </Button>
            <GatedButton
              type="submit"
              analyticsTrackingId="alert-settings-submit"
              theme={ButtonTheme.Primary}
              loading={mutation.isMutating}
              disabled={!formMethods.formState.isDirty || !canEditSchema}
              disabledTooltipContent={
                !canEditSchema
                  ? "You don't have permission to update alert attributes"
                  : undefined
              }
            >
              Save changes
            </GatedButton>
          </div>
        </Form.Root>
      </div>

      {showDeleteConfirmModal ? (
        <DisableConfirmationModal
          helpText={
            <>
              <p className="mb-2">
                If you remove{" "}
                {joinSpansWithCommasAndConnectorWord(
                  attributesBeingRemoved.map((a) => (
                    <span key={a.id} className={"font-medium"}>
                      {a.name}
                    </span>
                  )),
                )}
                , you&apos;ll no longer be able to see{" "}
                {attributesBeingRemoved.length > 1 ? "them " : "it "} on your
                alerts and you&apos;ll be unable to use them in alert routes.
              </p>
              <p className="mb-2">
                Please type &apos;delete&apos; below to confirm.
              </p>
            </>
          }
          title={"Delete attributes"}
          typeToConfirmPhrase={"delete"}
          submitText={"Confirm"}
          onSubmit={() => mutation.trigger(formMethods.getValues())}
          onClose={() => onCloseWithWarn(isDirty)}
        />
      ) : null}
    </div>
  );
};

export const AlertAttributeRow = ({
  index,
  formMethods,
  onDelete,
  provided,
  isDragging,
  existingAttributes,
}: {
  index: number;
  formMethods: UseFormReturn<AlertSchemaPayload, unknown>;
  onDelete: () => void;
  provided: DraggableProvided;
  isDragging: boolean;
  existingAttributes: AlertAttribute[];
}) => {
  const attributeType = formMethods.watch(`attributes.${index}.type`);
  const attributeName = formMethods.watch(`attributes.${index}.name`);
  const attributeID = formMethods.getValues(`attributes.${index}.id`);
  const arrayNotSupported = ["Text", "Bool", "Number"].includes(attributeType);

  const newAttribute = !existingAttributes.find(
    (attr) => attr.id === attributeID,
  );

  // If you choose a resource type that we don't support arrays for, we lock the
  // multi-value switch and set it to false
  useEffect(() => {
    if (arrayNotSupported) {
      formMethods.setValue<`attributes.${number}.array`>(
        `attributes.${index}.array`,
        false,
      );
    }
    // This should only rerun if the attribute type changes,
    // we don't care if it gets reordered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeType]);

  const { data, isLoading: isLoadingDependentResources } = useAPI(
    newAttribute ? null : "engineFindDependentResourcesForMultiple",
    {
      findDependentResourcesForMultipleRequestBody: {
        resources: [
          {
            resource_type: "AlertAttribute",
            id: attributeID,
          },
        ],
      },
    },
  );

  const attributeDependents = data?.dependent_resources || [];
  const disableBecauseOfDependents =
    isLoadingDependentResources || attributeDependents.length > 0;

  const dependentResourceTooltipContent = (
    <div>
      {isLoadingDependentResources ? (
        <p className="mb-2">
          Loading resources depending on{" "}
          <span className="font-medium">{attributeName}</span>
        </p>
      ) : (
        <DependentResourceList
          title={attributeName}
          requiresDeletionResources={[attributeDependents]}
          whiteText={true}
        />
      )}
    </div>
  );

  const multiValueDependentResourceTooltipContent = (
    <div>
      {isLoadingDependentResources ? (
        <p className="mb-2">
          Loading resources depending on{" "}
          <span className="font-medium">{attributeName}</span>
        </p>
      ) : (
        <DependentResourceList
          title={attributeName}
          verb={"toggle multi-value for"}
          requiresDeletionResources={[attributeDependents]}
          whiteText={true}
        />
      )}
    </div>
  );

  return (
    <tr ref={provided.innerRef} {...provided.draggableProps}>
      <LockedCell isDragging={isDragging}>
        <div className={"flex flex-center-y"}>
          <DragHandle className="mr-2 flex-0" {...provided.dragHandleProps} />
          <InputV2
            name={`attributes.${index}.name`}
            formMethods={formMethods}
            placeholder="Attribute name"
            required
          />
        </div>
      </LockedCell>
      <LockedCell isDragging={isDragging}>
        <CatalogTypeSelectorV2
          disabled={disableBecauseOfDependents}
          disabledTooltipContent={
            disableBecauseOfDependents
              ? dependentResourceTooltipContent
              : undefined
          }
          name={`attributes.${index}.type`}
          formMethods={formMethods}
          mode={"engine"}
          required
          className="w-full"
          triggerClassName="w-full"
        />
      </LockedCell>
      <LockedCell isDragging={isDragging}>
        <ToggleV2
          name={`attributes.${index}.array`}
          formMethods={formMethods}
          disabled={arrayNotSupported || disableBecauseOfDependents}
          isDisabledTooltipContent={
            disableBecauseOfDependents
              ? multiValueDependentResourceTooltipContent
              : `Multi-valued ${
                  attributeType ? attributeType.toLowerCase() : ""
                } attributes are not supported`
          }
        />
      </LockedCell>
      <LockedCell isDragging={isDragging}>
        <GatedButton
          disabled={disableBecauseOfDependents}
          disabledTooltipContent={dependentResourceTooltipContent}
          analyticsTrackingId="alert-schema-delete-attribute"
          theme={ButtonTheme.Naked}
          icon={IconEnum.Delete2}
          iconProps={{
            size: IconSize.Large,
          }}
          title="delete"
          onClick={onDelete}
        />
      </LockedCell>
    </tr>
  );
};

type LockedCellProps = {
  className?: string;
  isDragging: boolean;
  children: React.ReactNode;
};

type LockedCellState = {
  snapshot: {
    width: number;
    height: number;
  };
};

// The getSnapshotBeforeUpdate lifecycle is not possible when using function
// components, so we'll write this as a class instead.
//
// We need this special cell component to support drag-and-drop with tables that
// flexibly adjust column width for their content. That's because when you
// remove the <tr> from the table, you lose the grid sizing that you get from
// being inside the <table>, so we must:
//
// 1. Store our current size just before we begin dragging
// 2. Apply that size to the cells via inline styles so they retain their size
// while being moved
// 3. Remove the inline styles when we detect dragging is finished
class LockedCell extends Component<LockedCellProps, LockedCellState> {
  ref;

  getSnapshotBeforeUpdate(prevProps: LockedCellProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting = this.props.isDragging && !prevProps.isDragging;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height,
    };

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragging) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty("height");
    ref.style.removeProperty("width");
  }

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    return (
      <td className={this.props.className} ref={this.setRef}>
        {this.props.children}
      </td>
    );
  }
}
