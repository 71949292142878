import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import React from "react";

import {
  IncidentActivityLogEntry,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
  TimelineItemIncidentUpdate,
} from "../../../contexts/ClientContext";
import { useIncident } from "../../legacy/incident/hooks";
import { ActivityBadge } from "./ActivityBadge";
import { ActivityItemRenderProps } from "./ActivityItem";
import { StreamButton, StreamDetails } from "./StreamDetails";

export const statusHasChanged = (
  incidentUpdate?: TimelineItemIncidentUpdate,
): boolean => {
  if (!incidentUpdate) {
    return false;
  }

  return (
    incidentUpdate.new_incident_status.id !==
    incidentUpdate.previous_incident_status?.id
  );
};

const severityHasChanged = (
  incidentUpdate?: TimelineItemIncidentUpdate,
): boolean => {
  if (!incidentUpdate) {
    return false;
  }

  return (
    incidentUpdate.new_severity?.id !== incidentUpdate.previous_severity?.id
  );
};

export const ActivityItemIncidentUpdateComponent = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps | null => {
  if (!item.content.incident_update) {
    throw new Error(
      "malformed timeline item: incident_update was missing incident_update field",
    );
  }

  const defaultColour = ColorPaletteEnum.Blue;
  const actor = item.content.incident_update.updater;
  const updateText = item.content.incident_update.update_text && (
    <TemplatedTextDisplay
      value={item.content.incident_update.update_text?.text_node || ""}
      className="whitespace-pre-wrap"
      style={TemplatedTextDisplayStyle.Compact}
    />
  );

  // Special case for stream created/closed
  const streamCreated =
    item.is_stream && !item.content.incident_update.previous_incident_status;
  const streamClosed =
    item.is_stream &&
    item.content.incident_update.previous_incident_status?.category !==
      IncidentStatusCategoryEnum.Closed &&
    item.content.incident_update.new_incident_status.category ===
      IncidentStatusCategoryEnum.Closed;

  if (streamCreated || streamClosed) {
    const streamItemContent = streamCreated ? (
      <StreamDetails streamId={item.incident_id} />
    ) : (
      updateText
    );

    const title = "Stream " + (streamCreated ? "created" : "closed");

    return {
      actor: actor,
      icon: IconEnum.GitBranchNew,
      colour: defaultColour,
      title: title,
      quotedContent: streamItemContent,
      unquotedContent: <StreamButton streamId={item.incident_id} />,
    };
  }

  // These are the default render props for incident updates, but can be overriden in
  // special situations - usually when we want to show a different coloured icon!
  const renderProps: ActivityItemRenderProps = {
    actor: actor,
    icon: IconEnum.Announcement,
    colour: defaultColour,
    title: "Update shared",
    quotedContent: updateText,
    unquotedContent: (
      <IncidentUpdateBadges incidentUpdate={item.content.incident_update} />
    ),
  };

  if (statusHasChanged(item.content.incident_update)) {
    // The incident has just been reported.
    if (!item.content.incident_update.previous_incident_status) {
      renderProps.title = <StatusChangeTitle item={item} />;
      renderProps.icon = IconEnum.Incident;
      renderProps.colour = ColorPaletteEnum.Red;

      // If it's declared in triage, have a slightly different colour.
      if (
        item.content.incident_update.new_incident_status.category ===
        IncidentStatusCategoryEnum.Triage
      ) {
        renderProps.colour = ColorPaletteEnum.Yellow;
      }

      return renderProps;
    }

    // The incident has left triage.
    if (
      item.content.incident_update.previous_incident_status.category ===
        IncidentStatusCategoryEnum.Triage &&
      item.content.incident_update.new_incident_status.category !==
        IncidentStatusCategoryEnum.Triage
    ) {
      // Accepted.
      if (
        item.content.incident_update.new_incident_status.category ===
        IncidentStatusCategoryEnum.Active
      ) {
        renderProps.title = "Incident accepted";
        renderProps.icon = IconEnum.Incident;
        renderProps.colour = ColorPaletteEnum.Red;
        return renderProps;
      }
      // Declined.
      if (
        item.content.incident_update.new_incident_status.category ===
        IncidentStatusCategoryEnum.Declined
      ) {
        renderProps.title = "Incident declined";
        renderProps.icon = IconEnum.Close;
        renderProps.colour = ColorPaletteEnum.SlateOnWhite;
        return renderProps;
      }
      // Merged.
      if (
        item.content.incident_update.new_incident_status.category ===
        IncidentStatusCategoryEnum.Merged
      ) {
        renderProps.title = "Incident merged";
        renderProps.icon = IconEnum.GitBranchNew;
        renderProps.colour = ColorPaletteEnum.SlateOnWhite;
        return renderProps;
      }
    }

    // The incident has been reopened.
    if (
      item.content.incident_update.new_incident_status.category ===
        IncidentStatusCategoryEnum.Active &&
      (item.content.incident_update.previous_incident_status.category ===
        IncidentStatusCategoryEnum.Closed ||
        item.content.incident_update.previous_incident_status.category ===
          IncidentStatusCategoryEnum.PostIncident)
    ) {
      renderProps.title = "Incident reopened";
      renderProps.icon = IconEnum.Incident;
      renderProps.colour = ColorPaletteEnum.Red;
      return renderProps;
    }

    const isClosing =
      item.content.incident_update.new_incident_status.category ===
        IncidentStatusCategoryEnum.Closed &&
      item.content.incident_update.previous_incident_status.category !==
        IncidentStatusCategoryEnum.Closed;

    const isEnteringPostIncident =
      item.content.incident_update.new_incident_status.category ===
        IncidentStatusCategoryEnum.PostIncident &&
      item.content.incident_update.previous_incident_status.category !==
        IncidentStatusCategoryEnum.PostIncident;

    // The incident has been resolved or closed.
    if (isClosing || isEnteringPostIncident) {
      let title = "Incident closed";
      if (isEnteringPostIncident) {
        title = "Incident resolved";
      }
      renderProps.title = title;
      renderProps.colour = ColorPaletteEnum.Green;
      renderProps.icon = IconEnum.Checkmark;
      if (isEnteringPostIncident) {
        renderProps.icon = IconEnum.Clipboard;
      }

      return renderProps;
    }
  }

  // Change the icon if the update is just about changing the severity.
  if (
    !statusHasChanged(item.content.incident_update) &&
    severityHasChanged(item.content.incident_update)
  ) {
    renderProps.icon = IconEnum.Severity;
  }

  // Change the icon if we're pausing.
  if (
    item.content.incident_update.new_incident_status.category ===
    IncidentStatusCategoryEnum.Paused
  ) {
    renderProps.icon = IconEnum.Pause;
    renderProps.colour = ColorPaletteEnum.SlateOnWhite;
  }

  // Change the icon if we're resuming.
  if (
    item.content.incident_update.new_incident_status.category !==
      IncidentStatusCategoryEnum.Paused &&
    item.content.incident_update.previous_incident_status?.category ===
      IncidentStatusCategoryEnum.Paused
  ) {
    renderProps.icon = IconEnum.Resume;
  }

  // Change the icon if the incident was canceled.
  if (
    item.content.incident_update.new_incident_status.category ===
    IncidentStatusCategoryEnum.Canceled
  ) {
    renderProps.icon = IconEnum.Close;
    renderProps.colour = ColorPaletteEnum.SlateOnWhite;
  }

  // Otherwise it's just a normal update.
  return renderProps;
};

const IncidentUpdateBadges = ({
  incidentUpdate,
}: {
  incidentUpdate: TimelineItemIncidentUpdate;
}) => {
  return (
    <div className="flex-center-y gap-2 flex-wrap">
      <ActivityBadge
        icon={IconEnum.Severity}
        value={incidentUpdate.new_severity?.name || "Unset"}
        previousValue={incidentUpdate.previous_severity?.name}
      />
      <ActivityBadge
        icon={IconEnum.Status}
        value={incidentUpdate.new_incident_status.name}
        previousValue={incidentUpdate.previous_incident_status?.name}
      />
      {incidentUpdate.next_update_time && (
        <ActivityBadge
          icon={IconEnum.Clock}
          value={"Next update in " + incidentUpdate.next_update_time}
        />
      )}
    </div>
  );
};

const StatusChangeTitle = ({
  item,
}: {
  item: IncidentActivityLogEntry;
}): React.ReactElement => {
  const { incident, isLoading } = useIncident(item.incident_id);

  if (!incident || isLoading) {
    return <></>;
  }

  let title = "Incident reported";
  if (incident.mode === IncidentModeEnum.Retrospective) {
    title = "Retrospective incident opened";
  }

  return <>{title}</>;
};
