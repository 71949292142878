import { Incident, IncidentAttachment, Stream } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  ContentBox,
  IconEnum,
  LoadingBar,
  LoadingWrapper,
  StackedList,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import _ from "lodash";
import { useState } from "react";
import { IncidentDrawer } from "src/routes/legacy/IncidentRoute";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPIMutation } from "src/utils/swr";

import {
  Attachment,
  AttachmentContexts,
  AttachmentTypeToRenderProps,
} from "../../attachments/IncidentAttachment";
import { incidentInEditableStatus } from "../../helpers";
import { useAttachments } from "../../hooks";
import { IconValueButtonItem, Sidebar } from "../Components";
import { LinkBuilder, LinkBuilderProps } from "./builders";

export const IncidentAttachmentsDrawer = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement | null => {
  const inEditableStatus = incidentInEditableStatus(incident);

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          icon={IconEnum.Attachment}
          onClose={onClose}
          title={"Attachments"}
        />
        <DrawerBody className="overflow-y-auto">
          <IncidentAttachmentsInner
            incident={incident}
            inEditableStatus={inEditableStatus}
          />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

export const IncidentAttachmentsInner = ({
  incident,
  inEditableStatus,
}: {
  incident: Incident | Stream;
  inEditableStatus: boolean;
}): React.ReactElement => {
  const { attachments, isLoading: attachmentsLoading } = useAttachments(
    incident.id,
  );

  const [deletingAttachment, setDeletingAttachment] =
    useState<IncidentAttachment | null>(null);

  const { trigger: onRemove, isMutating: isDeleting } = useAPIMutation(
    "incidentAttachmentsList",
    { incidentId: incident.id },
    async (apiClient, attachment: IncidentAttachment) => {
      await apiClient.incidentAttachmentsRemove({ id: attachment.id });
    },
    {
      onSuccess: () => setDeletingAttachment(null),
    },
  );

  if (attachmentsLoading) {
    return <LoadingBar className="h-18" />;
  }

  const groupedAttachments = _.groupBy(
    attachments.filter((a) => a.resource.google_calendar_event == null),
    (x) => x.resource.resource_type,
  );

  return (
    <LoadingWrapper loading={isDeleting} className="space-y-4">
      {Object.entries(groupedAttachments).map(
        ([resourceType, theseAttachments]) => (
          <StackedList key={resourceType}>
            {theseAttachments.map((attachment) => (
              <li className="p-4 flex-center-y" key={attachment.id}>
                <Attachment
                  resource={attachment.resource}
                  key={attachment.id}
                  className="grow"
                  context={AttachmentContexts.RealTime}
                />
                {incident && inEditableStatus ? (
                  <Button
                    title="Remove attachment"
                    analyticsTrackingId="remove-incident-attachment"
                    onClick={() => setDeletingAttachment(attachment)}
                    icon={IconEnum.LinkBreak}
                    theme={ButtonTheme.Tertiary}
                  />
                ) : null}
              </li>
            ))}
          </StackedList>
        ),
      )}
      {deletingAttachment != null && (
        <ConfirmationDialog
          title="Remove Attachment"
          analyticsTrackingId="remove-attachment"
          isOpen={!!deletingAttachment}
          onCancel={() => setDeletingAttachment(null)}
          onConfirm={() => onRemove(deletingAttachment)}
          confirmButtonText="Remove"
        >
          <>
            <span> Are you sure you want to remove this attachment?</span>
            <ContentBox className="p-4 !pt-0 mt-2">
              <Attachment
                resource={deletingAttachment.resource}
                key={deletingAttachment.id}
                className="mt-4"
                context={AttachmentContexts.RealTime}
              />
            </ContentBox>
          </>
        </ConfirmationDialog>
      )}
    </LoadingWrapper>
  );
};

const IncidentAttachments = ({ attachments }: LinkBuilderProps) => {
  const navigateToDrawer = useNavigateToModal();

  if (attachments.length === 0) {
    return <></>;
  }

  const items: IconValueButtonItem[] = [];
  attachments
    // Hide GCal attachments as they're either calls, or debriefs, neither
    // of which we want to show.
    .filter((a) => a.resource.google_calendar_event == null)
    .forEach((attachment) => {
      const renderer =
        AttachmentTypeToRenderProps[attachment.resource.resource_type];
      if (!renderer) {
        return;
      }

      const { title, icon } = renderer(attachment.resource);

      items.push({
        label: title,
        icon: icon,
      });
    });

  return (
    <Sidebar.Entry
      label="Attachments"
      value={
        <Tooltip content="View attachments">
          <Sidebar.IconValueButton
            items={items}
            analyticsTrackingId="incident-sidebar-attachments"
            onClick={() => navigateToDrawer(IncidentDrawer.Attachments)}
          />
        </Tooltip>
      }
    />
  );
};

export const IncidentAttachmentBuilder: LinkBuilder = {
  Render: IncidentAttachments,
};
