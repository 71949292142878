/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParameterisedResourceOption
 */
export interface ParameterisedResourceOption {
    /**
     * Gives a description of the option to the user
     * @type {string}
     * @memberof ParameterisedResourceOption
     */
    helptext?: string;
    /**
     * If appropriate, URL to an image that can be displayed alongside the option
     * @type {string}
     * @memberof ParameterisedResourceOption
     */
    image_url?: string;
    /**
     * If true, the image_url is a Slack icon and should be displayed as such
     * @type {boolean}
     * @memberof ParameterisedResourceOption
     */
    is_image_slack_icon?: boolean;
    /**
     * Human readable label to be displayed for user to select
     * @type {string}
     * @memberof ParameterisedResourceOption
     */
    label: string;
    /**
     * The registry type that this catalog option is for
     * @type {string}
     * @memberof ParameterisedResourceOption
     */
    registry_type: string;
    /**
     * Gives an indication of how to sort the options when displayed to the user
     * @type {string}
     * @memberof ParameterisedResourceOption
     */
    sort_key: string;
    /**
     * The dynamic resource parameter for this option
     * @type {string}
     * @memberof ParameterisedResourceOption
     */
    value: string;
}

export function ParameterisedResourceOptionFromJSON(json: any): ParameterisedResourceOption {
    return ParameterisedResourceOptionFromJSONTyped(json, false);
}

export function ParameterisedResourceOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterisedResourceOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'helptext': !exists(json, 'helptext') ? undefined : json['helptext'],
        'image_url': !exists(json, 'image_url') ? undefined : json['image_url'],
        'is_image_slack_icon': !exists(json, 'is_image_slack_icon') ? undefined : json['is_image_slack_icon'],
        'label': json['label'],
        'registry_type': json['registry_type'],
        'sort_key': json['sort_key'],
        'value': json['value'],
    };
}

export function ParameterisedResourceOptionToJSON(value?: ParameterisedResourceOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'helptext': value.helptext,
        'image_url': value.image_url,
        'is_image_slack_icon': value.is_image_slack_icon,
        'label': value.label,
        'registry_type': value.registry_type,
        'sort_key': value.sort_key,
        'value': value.value,
    };
}

