import {
  ScheduleTimeWindowAction,
  ScheduleTimeWindowState,
} from "@incident-shared/schedules/ScheduleOverview/scheduleTimeWindowReducer";
import {
  endTimeForTimelinePeriod,
  timelineCalendarToggleOptions,
  TimelineCalendarValue,
  TimePeriodOption,
  timePeriodOpts,
} from "@incident-shared/schedules/ScheduleOverview/types";
import { useKeyboardEvents } from "@incident-shared/schedules/useKeyboardEvents";
import {
  BadgeSize,
  Button,
  ButtonGroup,
  ButtonTheme,
  IconEnum,
  PopoverSingleSelect,
} from "@incident-ui";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { MultiTimezoneSelector } from "./MultiTimezoneSelector";

export const ScheduleOverviewHeader = ({
  className,
  timeWindowState,
  timeWindowDispatch,
  showCalendarOption = true,
  scheduleDefaultTimezone,
  // FocusDate is a point in time that we want to keep displayed as we switch between time periods
  focusDate, // Whether we're in a modal and should use smaller breakpoints.
  selectedTimezones,
  setSelectedTimezones,
}: {
  className?: string;
  timeWindowState: ScheduleTimeWindowState;
  timeWindowDispatch: React.Dispatch<ScheduleTimeWindowAction>;
  showCalendarOption?: boolean;
  scheduleDefaultTimezone: string;
  focusDate?: DateTime;
  inModal?: boolean;
  selectedTimezones: string[];
  setSelectedTimezones: (timezones: string[]) => void;
}) => {
  const currentEndTime = endTimeForTimelinePeriod({
    from: timeWindowState.startTime,
    timePeriod: timeWindowState.timePeriodOption,
  });

  const timePeriodOptions = Object.values(TimePeriodOption).map((value) => ({
    value,
    label: timePeriodOpts[value].label,
  }));

  useKeyboardEvents(
    useCallback(
      (e) => {
        // If you press left or right arrow keys, we should navigate to the previous or next day
        if (e.key === "ArrowLeft") {
          timeWindowDispatch({ type: "previousPageClicked" });
        } else if (e.key === "ArrowRight") {
          timeWindowDispatch({ type: "nextPageClicked" });
        }
      },
      [timeWindowDispatch],
    ),
  );

  return (
    <div
      className={tcx(
        "flex flex-wrap flex-col md:flex-row md:justify-between gap-2 md:gap-0",
        className,
      )}
    >
      <div className={"flex flex-row items-center gap-4"}>
        <div className={"flex flex-row gap-2 max-h-8"}>
          <Button
            analyticsTrackingId={"today"}
            onClick={() => timeWindowDispatch({ type: "todayClicked" })}
            size={BadgeSize.Medium}
          >
            Today
          </Button>
          <div className={"flex flex-row gap-2"}>
            <Button
              analyticsTrackingId={"back"}
              icon={IconEnum.ArrowLeft}
              iconProps={{
                className:
                  "text-content-primary hover:text-content-tertiary transition",
              }}
              size={BadgeSize.Medium}
              theme={ButtonTheme.Secondary}
              title={"back"}
              onClick={() =>
                timeWindowDispatch({ type: "previousPageClicked" })
              }
            />
            <Button
              analyticsTrackingId={"forward"}
              icon={IconEnum.ArrowLeft}
              iconProps={{
                className:
                  "text-content-primary hover:text-content-tertiary transition transform rotate-180",
              }}
              size={BadgeSize.Medium}
              theme={ButtonTheme.Secondary}
              title={"forward"}
              onClick={() => timeWindowDispatch({ type: "nextPageClicked" })}
            />
          </div>
        </div>
        <span className="text-sm whitespace-nowrap">
          {formatTimeRange({
            timelineEndpoint: currentEndTime,
            timelineStartPoint: timeWindowState.startTime,
            selectedTimePeriod: timeWindowState.timePeriodOption,
            selectedTimelineCalendarToggle: timeWindowState.calendarToggle,
            timeZone: scheduleDefaultTimezone,
          })}
        </span>
      </div>
      <div className={"flex flex-row gap-2 max-h-8"}>
        {/* Timeline OR Calendar */}
        {timeWindowState.calendarToggle === TimelineCalendarValue.Timeline && (
          <>
            {timeWindowState.timePeriodOption === TimePeriodOption.ThreeHours ||
            timeWindowState.timePeriodOption === TimePeriodOption.OneDay ? (
              <MultiTimezoneSelector
                currentTimezones={selectedTimezones ?? []}
                onChange={(v) => setSelectedTimezones(v)}
                scheduleDefaultTimezone={scheduleDefaultTimezone}
              />
            ) : null}
            <PopoverSingleSelect
              options={timePeriodOptions}
              value={timeWindowState.timePeriodOption}
              renderSelected={(value) => (
                <p className="text-sm">
                  {value ? timePeriodOpts[value.value].label : ""}
                </p>
              )}
              onChange={(v) =>
                timeWindowDispatch({
                  type: "setTimePeriodOption",
                  payload: { period: v as TimePeriodOption, focusDate },
                })
              }
              triggerClassName="min-h-7 h-7 !rounded-1"
              popoverClassName="w-40"
            />
          </>
        )}
        {showCalendarOption && (
          <ButtonGroup
            value={timeWindowState.calendarToggle}
            className="h-7 !rounded-1"
            buttonClassName="h-7 w-7 !rounded-1 justify-center p-0"
            onChange={(v) =>
              timeWindowDispatch({
                type: "setCalendarToggle",
                payload: v as TimelineCalendarValue,
              })
            }
            options={timelineCalendarToggleOptions}
            analyticsTrackingId={"timeline-calendar-toggle"}
          />
        )}
      </div>
    </div>
  );
};

const formatTimeRange = ({
  selectedTimePeriod,
  timelineEndpoint,
  timelineStartPoint,
  selectedTimelineCalendarToggle,
  timeZone,
}: {
  selectedTimePeriod: TimePeriodOption;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  selectedTimelineCalendarToggle: TimelineCalendarValue;
  timeZone: string;
}): string => {
  if (selectedTimelineCalendarToggle === TimelineCalendarValue.Calendar) {
    return timelineStartPoint.toLocaleString({
      month: "long",
      year: "numeric",
      timeZone: timeZone,
    });
  }

  switch (selectedTimePeriod) {
    case TimePeriodOption.ThreeHours:
      if (timelineStartPoint.day === timelineEndpoint.day) {
        // Tue 30
        const day = timelineStartPoint.toLocaleString({
          day: "numeric",
          weekday: "short",
          timeZone: timeZone,
        });
        return `${day}`;
      } else {
        // Tue 30, 22:20 - Wed 31, 01:10
        return `${timelineStartPoint.toLocaleString({
          hour: "numeric",
          minute: "numeric",
          day: "numeric",
          weekday: "short",
          timeZone: timeZone,
        })} - ${timelineEndpoint.toLocaleString({
          hour: "numeric",
          minute: "numeric",
          day: "numeric",
          weekday: "short",
          timeZone: timeZone,
        })}`;
      }
    case TimePeriodOption.OneDay:
      if (timelineStartPoint.day === timelineEndpoint.day) {
        return timelineStartPoint.toLocaleString({
          day: "numeric",
          weekday: "short",
          timeZone: timeZone,
        });
      } else {
        return `${timelineStartPoint.toLocaleString({
          day: "numeric",
          weekday: "short",
          timeZone: timeZone,
        })} - ${timelineEndpoint.toLocaleString({
          day: "numeric",
          weekday: "short",
          timeZone: timeZone,
        })}`;
      }
    case TimePeriodOption.OneWeek:
    case TimePeriodOption.TwoWeeks:
    case TimePeriodOption.OneMonth:
      // We render the timeline end as midnight of the day. This might seem unintuitive if you're viewing a week from
      // April 1, and we're showing up until April 7th, but the header shows April 1 - 8. We remove a minute from the
      // end point to grab the "intuitive" day instead.
      const intuitiveEndpoint = timelineEndpoint.minus({ minute: 1 });
      if (timelineStartPoint.month === timelineEndpoint.month) {
        return `${timelineStartPoint.toLocaleString({
          month: "long",
          timeZone: timeZone,
        })} ${timelineStartPoint.toLocaleString({
          day: "numeric",
          timeZone: timeZone,
        })} - ${intuitiveEndpoint.toLocaleString({
          day: "numeric",
          timeZone: timeZone,
        })}`;
      } else {
        return `${timelineStartPoint.toLocaleString({
          month: "long",
          timeZone: timeZone,
        })} ${timelineStartPoint.toLocaleString({
          day: "numeric",
          timeZone: timeZone,
        })} - ${intuitiveEndpoint.toLocaleString({
          month: "long",
          timeZone: timeZone,
        })} ${intuitiveEndpoint.toLocaleString({
          day: "numeric",
          timeZone: timeZone,
        })}`;
      }
    default:
      assertUnreachable(selectedTimePeriod);
      return "";
  }
};
