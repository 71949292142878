import { UserOption } from "@incident-io/api";
import { Avatar, IconSize } from "@incident-ui";

export const UserOptionSection = ({
  user,
}: {
  user: Omit<UserOption, "sort_key">;
}) => {
  const avatarUrl = user.image_url;

  return (
    <div className="flex items-center justify-between w-full gap-2">
      <Avatar
        size={IconSize.Large}
        url={avatarUrl}
        name={user.label}
        className={"flex-none"}
      />
      <span className="grow">{user.label}</span>
    </div>
  );
};
