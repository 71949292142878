import type { SVGProps } from "react";
import * as React from "react";
const SvgCaratRight = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.611 8.522 6.687 3.497A1.75 1.75 0 0 0 4 4.975v10.05a1.749 1.749 0 0 0 2.688 1.477l7.923-5.024A1.74 1.74 0 0 0 15.423 10c0-.603-.304-1.155-.812-1.478Z"
    />
  </svg>
);
export default SvgCaratRight;
