/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Priority,
    PriorityFromJSON,
    PriorityFromJSONTyped,
    PriorityToJSON,
} from './Priority';

/**
 * 
 * @export
 * @interface AlertsUpdatePriorityResponseBody
 */
export interface AlertsUpdatePriorityResponseBody {
    /**
     * 
     * @type {Priority}
     * @memberof AlertsUpdatePriorityResponseBody
     */
    priority: Priority;
}

export function AlertsUpdatePriorityResponseBodyFromJSON(json: any): AlertsUpdatePriorityResponseBody {
    return AlertsUpdatePriorityResponseBodyFromJSONTyped(json, false);
}

export function AlertsUpdatePriorityResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsUpdatePriorityResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priority': PriorityFromJSON(json['priority']),
    };
}

export function AlertsUpdatePriorityResponseBodyToJSON(value?: AlertsUpdatePriorityResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priority': PriorityToJSON(value.priority),
    };
}

