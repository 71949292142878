import type { SVGProps } from "react";
import * as React from "react";
const SvgArrowsSpin = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="m14.096 10.72-.6.16c1.371-2.614.75-5.81-1.552-7.693a.668.668 0 0 0-.844 1.032 4.867 4.867 0 0 1 1.133 6.225c-.027.046-.06.086-.09.131l-.311-1.162a.666.666 0 1 0-1.288.346l.65 2.428a.668.668 0 0 0 .817.472l2.428-.65a.667.667 0 0 0-.346-1.289h.003ZM2.41 7.756a.666.666 0 0 0 .765-.55A4.868 4.868 0 0 1 8 3.112c.052 0 .105.01.157.01l-.85.852a.667.667 0 0 0 .942.944l1.777-1.778a.667.667 0 0 0 0-.943L8.25.418a.667.667 0 1 0-.944.943l.43.43a6.19 6.19 0 0 0-5.877 5.2.667.667 0 0 0 .551.765ZM9.722 12.575a4.87 4.87 0 0 1-5.957-2.13c-.027-.047-.044-.096-.07-.143l1.164.312a.667.667 0 0 0 .346-1.288l-2.428-.65a.67.67 0 0 0-.817.472l-.65 2.428a.667.667 0 0 0 1.288.344l.158-.593a6.19 6.19 0 0 0 5.23 2.902c.735 0 1.483-.131 2.21-.406a.668.668 0 0 0-.473-1.247Z"
    />
  </svg>
);
export default SvgArrowsSpin;
