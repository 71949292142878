import { Product } from "@incident-shared/billing";
import { AddIssueTemplateButton } from "@incident-shared/issue-templates/AddIssueTemplateButton";
import { IssueSyncConfigEditor } from "@incident-shared/issue-templates/IssueSyncConfigEditor";
import { IssueTemplateFailureCallout } from "@incident-shared/issue-templates/IssueTemplateFailureCallout";
import { IssueTemplatesList } from "@incident-shared/issue-templates/IssueTemplatesList";
import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers/useAllTemplates";
import React from "react";
import { useProductAccess } from "src/hooks/useProductAccess";

import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { SettingsSubHeading } from "../SettingsSubHeading";
import graphic from "./graphic.svg";

export const SettingsIncidentTicketsPage = (): React.ReactElement => {
  const { hasProduct } = useProductAccess();
  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Create tickets for incidents"
        subtitle={
          <>Define which incidents get exported, and which template to use.</>
        }
        articleId={8217744}
        graphic={<img src={graphic} className="h-40" />}
      />
      {hasProduct(Product.Response) ? (
        <>
          {/* Define templates */}
          <div>
            <SettingsSubHeading
              title={"Incident ticket templates"}
              accessory={
                <AddIssueTemplateButton
                  context={IssueTemplateContextEnum.IncidentTicket}
                />
              }
              explanation={
                <>
                  <p className="mb-2">
                    Automatically create a ticket in your issue tracker whenever
                    an incident matches the conditions you specify, and keep it
                    up-to-date as the incident changes.
                  </p>

                  <p>
                    We&rsquo;ll post incident updates as comments on the ticket.
                  </p>
                </>
              }
            />
            <IssueTemplateFailureCallout
              context={IssueTemplateContextEnum.IncidentTicket}
            />
            <IssueTemplatesList
              context={IssueTemplateContextEnum.IncidentTicket}
            />
          </div>

          {/* Incident-to-template expression */}
          <IssueSyncConfigEditor
            context={IssueTemplateContextEnum.IncidentTicket}
            title="Create tickets for incidents"
            description={
              <>
                When enabled, we&rsquo;ll create a ticket in your issue tracker
                whenever an incident matches the conditions you specify.
              </>
            }
            privateLabel={"Include private incidents"}
            helpLink="https://help.incident.io/en/articles/8217744-syncing-incidents-with-jira"
          />
        </>
      ) : (
        <ProductUpsellNotice
          featureName="Incident tickets"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
    </SettingsSubPageWrapper>
  );
};
