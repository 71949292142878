/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulePayRule
 */
export interface SchedulePayRule {
    /**
     * End time of the rule, in 24hr format
     * @type {string}
     * @memberof SchedulePayRule
     */
    end_time: string;
    /**
     * Rate of pay per hour when this rule applies, in lowest denomination of specified currency
     * @type {number}
     * @memberof SchedulePayRule
     */
    rate_cents: number;
    /**
     * Short ID that can uniquely identify the pay rule
     * @type {string}
     * @memberof SchedulePayRule
     */
    short_id: string;
    /**
     * Sort key to present rules in descending importance
     * @type {number}
     * @memberof SchedulePayRule
     */
    sort_key: number;
    /**
     * Start time of the rule, in 24hr format
     * @type {string}
     * @memberof SchedulePayRule
     */
    start_time: string;
    /**
     * Weekdays this rule applies for
     * @type {Array<string>}
     * @memberof SchedulePayRule
     */
    weekdays: Array<SchedulePayRuleWeekdaysEnum>;
}

/**
* @export
* @enum {string}
*/
export enum SchedulePayRuleWeekdaysEnum {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday'
}

export function SchedulePayRuleFromJSON(json: any): SchedulePayRule {
    return SchedulePayRuleFromJSONTyped(json, false);
}

export function SchedulePayRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulePayRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_time': json['end_time'],
        'rate_cents': json['rate_cents'],
        'short_id': json['short_id'],
        'sort_key': json['sort_key'],
        'start_time': json['start_time'],
        'weekdays': json['weekdays'],
    };
}

export function SchedulePayRuleToJSON(value?: SchedulePayRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_time': value.end_time,
        'rate_cents': value.rate_cents,
        'short_id': value.short_id,
        'sort_key': value.sort_key,
        'start_time': value.start_time,
        'weekdays': value.weekdays,
    };
}

