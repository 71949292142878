import type { SVGProps } from "react";
import * as React from "react";
const SvgZapier = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="#FF4F00" d="M2 2h16v16H2z" />
  </svg>
);
export default SvgZapier;
