import { Button, ButtonTheme, EmptyState, IconEnum } from "@incident-ui";
import React from "react";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export const NoDecisionFlowSelected = (): React.ReactElement => (
  <EmptyState
    icon={IconEnum.DecisionFlows}
    title="Select a decision flow"
    content="Get started by choosing a decision flow from the dropdown above."
  />
);

export const NoDecisionFlowsExist = ({
  onClickCreate,
}: {
  onClickCreate: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  return (
    <EmptyState
      icon={IconEnum.DecisionFlows}
      title="No decision flows"
      content="Get started by creating a new decision flow."
      cta={
        canEditSettings && (
          <Button
            analyticsTrackingId="decision-flow-empty-state-create"
            theme={ButtonTheme.Primary}
            onClick={onClickCreate}
          >
            New Decision Flow
          </Button>
        )
      }
    />
  );
};
