/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface IncidentParticipants
 */
export interface IncidentParticipants {
    /**
     * A list of the active participants (i.e. people who said 'here to help')
     * @type {Array<User>}
     * @memberof IncidentParticipants
     */
    active: Array<User>;
    /**
     * A list of the passive participants (i.e. people who said 'here to observe')
     * @type {Array<User>}
     * @memberof IncidentParticipants
     */
    passive: Array<User>;
}

export function IncidentParticipantsFromJSON(json: any): IncidentParticipants {
    return IncidentParticipantsFromJSONTyped(json, false);
}

export function IncidentParticipantsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentParticipants {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': ((json['active'] as Array<any>).map(UserFromJSON)),
        'passive': ((json['passive'] as Array<any>).map(UserFromJSON)),
    };
}

export function IncidentParticipantsToJSON(value?: IncidentParticipants | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': ((value.active as Array<any>).map(UserToJSON)),
        'passive': ((value.passive as Array<any>).map(UserToJSON)),
    };
}

