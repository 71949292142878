/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RBACRole,
    RBACRoleFromJSON,
    RBACRoleFromJSONTyped,
    RBACRoleToJSON,
} from './RBACRole';

/**
 * 
 * @export
 * @interface RBACRolesUpdateResponseBody
 */
export interface RBACRolesUpdateResponseBody {
    /**
     * 
     * @type {RBACRole}
     * @memberof RBACRolesUpdateResponseBody
     */
    rbac_role: RBACRole;
}

export function RBACRolesUpdateResponseBodyFromJSON(json: any): RBACRolesUpdateResponseBody {
    return RBACRolesUpdateResponseBodyFromJSONTyped(json, false);
}

export function RBACRolesUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RBACRolesUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rbac_role': RBACRoleFromJSON(json['rbac_role']),
    };
}

export function RBACRolesUpdateResponseBodyToJSON(value?: RBACRolesUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rbac_role': RBACRoleToJSON(value.rbac_role),
    };
}

