import {
  ConditionGroup,
  EngineParamBinding,
  EngineScope,
  Expression,
  IncidentTimestamp,
  PolicyPolicyTypeEnum,
  RequirementsTemplate,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  EngineFormElement,
  EngineLiteralBadge,
  getVariableScope,
  isExpression,
} from "@incident-shared/engine";
import { conditionGroupsMatch } from "@incident-shared/engine/conditions/helpers";
import { makeExpressionReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { AnimatePresence } from "framer-motion";
import { isEmpty } from "lodash";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { PolicyTypeConfig, useGetPolicyTypeConfig } from "../common/config";
import { PolicyCreateEditFormData } from "../common/PolicyCreateEditForm";
import { PolicyEditableSection } from "./PolicyEditableSection";
import { PolicySLAExpressionEditor } from "./PolicySLAExpressionEditor";
import { PolicySLAExpressionView, SLABinding } from "./PolicySLAExpressionView";

export type PolicySLAData = Pick<
  PolicyCreateEditFormData,
  "sla_incident_timestamp_id" | "exclude_weekends" | "expressions"
> & {
  mode: "static" | "dynamic";
  staticSLA?: EngineParamBinding;
  dynamicSLA?: EngineParamBinding;
};

export const PolicySLASection = ({
  showDrawer,
  setShowDrawer,
  slaTimestampName,
  scope,
  resources,
  policyType,
  automaticTimestamps,
  defaultExpression,
  templates,
}: {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  slaTimestampName: string;
  scope: EngineScope;
  resources: Resource[];
  policyType: PolicyPolicyTypeEnum;
  automaticTimestamps: IncidentTimestamp[];
  defaultExpression: Expression;
  templates: RequirementsTemplate[];
}) => {
  const formMethods = useFormContext<PolicyCreateEditFormData>();
  const { setValue } = formMethods;

  const [
    sla_incident_timestamp_id,
    sla_days,
    exclude_weekends,
    expressions,
    type_condition_groups,
  ] = formMethods.watch([
    "sla_incident_timestamp_id",
    "sla_days",
    "exclude_weekends",
    "expressions",
    "type_condition_groups",
  ]);

  const config = useGetPolicyTypeConfig()(policyType);

  const onSubmit = (data: PolicySLAData) => {
    // Currently, we don't use expressions anywhere else in the policy form, but we might in the future
    // and debugging this issue (where the SLA days editor always expects to be mutating the first expression)
    // would not be fun.
    const newExpressions =
      expressions?.filter((e) => e.returns.type !== "SLADays") || [];

    let SLADays = data.staticSLA;
    if (
      data.mode === "dynamic" &&
      data.expressions &&
      !isEmpty(data.expressions)
    ) {
      SLADays = {
        value: {
          reference: makeExpressionReference(
            data.expressions[0] as ExpressionFormData,
          ),
          label: "SLA Days",
          sort_key: "",
        },
      };
    }

    // If the SLA is dynamic, we need to push the expression up to the policy level
    // before we can use it in the SLA days binding
    if (data.mode === "dynamic") {
      newExpressions.push(...(data.expressions || []));

      setValue<"expressions">("expressions", newExpressions, {
        shouldDirty: true,
      });

      setValue<"sla_days">("sla_days", SLADays as EngineParamBinding, {
        shouldDirty: true,
      });
    } else {
      // Otherwise we need to update the SLA days binding to use the static value
      // before we can remove the expression from the policy level
      setValue<"sla_days">("sla_days", SLADays as EngineParamBinding, {
        shouldDirty: true,
      });

      setValue<"expressions">("expressions", newExpressions, {
        shouldDirty: true,
      });
    }

    setValue<"sla_incident_timestamp_id">(
      "sla_incident_timestamp_id",
      data.sla_incident_timestamp_id,
      {
        shouldDirty: true,
      },
    );
    setValue<"exclude_weekends">("exclude_weekends", data.exclude_weekends, {
      shouldDirty: true,
    });
    setShowDrawer(false);
  };

  const isUsingExpression = isExpression(sla_days.value?.reference);

  const SLAMode = isUsingExpression ? "dynamic" : "static";

  const initialExpressionsData = isUsingExpression
    ? expressions?.filter((e) => e.returns.type === "SLADays")
    : [defaultExpression];

  return (
    <>
      <AnimatePresence>
        {showDrawer && (
          <SLADrawer
            onSubmit={onSubmit}
            onClose={() => setShowDrawer(false)}
            initialData={{
              sla_incident_timestamp_id,
              exclude_weekends,
              expressions: initialExpressionsData || [],
              mode: SLAMode,
              dynamicSLA: SLAMode === "dynamic" ? sla_days : undefined,
              staticSLA: SLAMode === "static" ? sla_days : undefined,
            }}
            config={config}
            automaticTimestamps={automaticTimestamps}
            scope={scope}
            resources={resources}
          />
        )}
      </AnimatePresence>
      <PolicyEditableSection
        bottomContext={
          exclude_weekends ? "Doesn’t include weekends" : undefined
        }
        onEdit={() => setShowDrawer(true)}
        icon={IconEnum.Timer}
        title={
          <div className="flex flex-wrap gap-1">
            What is the <span className="text-blue-content">SLA</span> to meet
            these requirements?
          </div>
        }
      >
        <ViewPolicySLA
          binding={sla_days}
          slaTimestampName={slaTimestampName}
          scope={scope}
          resources={resources}
          expressions={expressions || []}
          templates={templates}
          requirements={type_condition_groups}
        />
      </PolicyEditableSection>
    </>
  );
};

export const ViewPolicySLA = ({
  binding,
  slaTimestampName,
  scope,
  resources,
  expressions,
  mini = false,
  templates,
  requirements,
}: {
  binding: EngineParamBinding;
  slaTimestampName: string;
  scope: EngineScope;
  resources: Resource[];
  expressions: Expression[];
  mini?: boolean;
  templates: RequirementsTemplate[];
  requirements: ConditionGroup[];
}) => {
  const isUsingExpression = isExpression(binding.value?.reference);
  const variableScope = getVariableScope(scope, resources);
  const matchingTemplate = templates.find((template) =>
    conditionGroupsMatch(template.condition_groups, requirements),
  );

  if (isUsingExpression) {
    return (
      <PolicySLAExpressionView
        reference={binding.value?.reference || ""}
        scope={variableScope}
        slaTimestampName={slaTimestampName}
        mini={mini}
        expressions={expressions}
        matchingTemplate={matchingTemplate}
      />
    );
  }

  return (
    <SLABinding
      binding={binding}
      scope={variableScope}
      slaTimestampName={slaTimestampName}
      matchingTemplate={matchingTemplate}
      mini={mini}
    />
  );
};

const SLADrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  config,
  resources,
  scope,
  automaticTimestamps,
}: {
  onSubmit: (data: PolicySLAData) => void;
  onClose: () => void;
  initialData?: PolicySLAData;
  config: PolicyTypeConfig;
  scope: EngineScope;
  resources: Resource[];
  automaticTimestamps: IncidentTimestamp[];
}) => {
  const formMethods = useForm<PolicySLAData>({
    defaultValues: initialData,
  });

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const expressionsMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const slaTimestampId = formMethods.watch("sla_incident_timestamp_id");

  const slaTimestamp = automaticTimestamps.find(
    (ts) => ts.id === slaTimestampId,
  );

  const [staticSLA, mode, expressions] = formMethods.watch([
    "staticSLA",
    "mode",
    "expressions",
  ]);

  formMethods.register("mode", {
    validate: (v) => {
      if (
        v === "static" &&
        (!staticSLA?.value?.literal || staticSLA?.value?.literal === "")
      ) {
        return "Please enter a number of days";
      } else if (
        v === "dynamic" &&
        (isEmpty(expressions) ||
          isEmpty(expressions?.[0].operations[0].branches))
      ) {
        return "Please add an expression";
      }

      return undefined;
    },
  });

  const radioOptions: RadioButtonGroupOption[] = [
    {
      value: "static",
      label: "A static value",
      description: "The SLA will be the same for all incidents.",
      renderWhenSelectedNode: () => (
        <EngineFormElement
          name="staticSLA"
          resourceType={`SLADays`}
          array={false}
          resources={resources}
          mode="plain_input"
          required={false}
          className="w-[100px]"
          suffixNode={
            <div className="text-content-secondary text-sm-med pl-1">days</div>
          }
        />
      ),
    },
    {
      value: "dynamic",
      label: "A dynamic value",
      description: `Choose an SLA depending on properties of the incident or ${config.subject}.`,
    },
  ];

  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents className="overflow-hidden">
        <DrawerTitle
          title="SLA"
          onClose={onClose}
          icon={IconEnum.Timer}
          subtitle="Choose how long your responders have to meet the requirements of this policy"
        />
        <DrawerBody className="overflow-y-auto">
          <Form.Root
            formMethods={formMethods}
            onSubmit={onSubmit}
            id="policy-sla"
            outerClassName="grow"
          >
            <ExpressionsMethodsProvider
              expressionsMethods={expressionsMethods}
              allowAllOfACatalogType={false}
            >
              <div className="flex flex-col gap-6">
                <StaticSingleSelectV2
                  formMethods={formMethods}
                  required="Please select an incident timestamp"
                  name="sla_incident_timestamp_id"
                  options={automaticTimestamps.map((ts) => ({
                    value: ts.id,
                    label: ts.name,
                    sort_key: ts.rank.toString().padStart(4),
                  }))}
                  isClearable={false}
                  placeholder="Select incident timestamp"
                  label="SLA starting point"
                  helptext={
                    <>
                      <span>Choose a point in the </span>
                      <Button
                        theme={ButtonTheme.Link}
                        href="/settings/lifecycle"
                        analyticsTrackingId="policies-configure-lifecycle"
                        openInNewTab
                      >
                        incident lifecycle
                      </Button>
                      <span> to begin enforcing this policy.</span>
                    </>
                  }
                />
                <RadioButtonGroupV2
                  formMethods={formMethods}
                  options={radioOptions}
                  name="mode"
                  label={
                    <div className="flex flex-wrap items-center gap-2">
                      How many days after{" "}
                      <EngineLiteralBadge
                        label={slaTimestamp?.name || "Choose timestamp"}
                        mini
                      />{" "}
                      should we begin enforcing the policy?
                    </div>
                  }
                  srLabel="SLA calculation mode"
                  boxed
                />
                {mode === "dynamic" && (
                  <PolicySLAExpressionEditor
                    resources={resources}
                    scope={scope}
                    formMethods={formMethods}
                    slaTimestampName={slaTimestamp?.name || ""}
                  />
                )}
                <CheckboxRowV2
                  formMethods={formMethods}
                  name="exclude_weekends"
                  label={
                    <div className="text-content-primary text-sm-med">
                      Exclude weekends?
                    </div>
                  }
                  description="When calculating how many days have passed, should we only consider Monday to Friday?"
                />
              </div>
            </ExpressionsMethodsProvider>
          </Form.Root>
        </DrawerBody>
        <DrawerFooter className="flex gap-2 justify-end">
          <Button onClick={() => onClose()} analyticsTrackingId={null}>
            Back
          </Button>
          <GatedButton
            form="policy-sla"
            requiredScope={ScopeNameEnum.PoliciesCreate}
            type="submit"
            theme={ButtonTheme.Primary}
            analyticsTrackingId={null}
          >
            Apply
          </GatedButton>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};
