/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageSubPage,
    StatusPageSubPageFromJSON,
    StatusPageSubPageFromJSONTyped,
    StatusPageSubPageToJSON,
} from './StatusPageSubPage';

/**
 * 
 * @export
 * @interface StatusPageListSubPagesResponseBody
 */
export interface StatusPageListSubPagesResponseBody {
    /**
     * 
     * @type {Array<StatusPageSubPage>}
     * @memberof StatusPageListSubPagesResponseBody
     */
    status_page_sub_pages: Array<StatusPageSubPage>;
}

export function StatusPageListSubPagesResponseBodyFromJSON(json: any): StatusPageListSubPagesResponseBody {
    return StatusPageListSubPagesResponseBodyFromJSONTyped(json, false);
}

export function StatusPageListSubPagesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageListSubPagesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status_page_sub_pages': ((json['status_page_sub_pages'] as Array<any>).map(StatusPageSubPageFromJSON)),
    };
}

export function StatusPageListSubPagesResponseBodyToJSON(value?: StatusPageListSubPagesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_page_sub_pages': ((value.status_page_sub_pages as Array<any>).map(StatusPageSubPageToJSON)),
    };
}

