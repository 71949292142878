/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Policy,
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';

/**
 * 
 * @export
 * @interface PoliciesShowResponseBody
 */
export interface PoliciesShowResponseBody {
    /**
     * 
     * @type {Policy}
     * @memberof PoliciesShowResponseBody
     */
    policy: Policy;
}

export function PoliciesShowResponseBodyFromJSON(json: any): PoliciesShowResponseBody {
    return PoliciesShowResponseBodyFromJSONTyped(json, false);
}

export function PoliciesShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy': PolicyFromJSON(json['policy']),
    };
}

export function PoliciesShowResponseBodyToJSON(value?: PoliciesShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy': PolicyToJSON(value.policy),
    };
}

