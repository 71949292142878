import { CatalogEntryBadge } from "@incident-shared/attribute";
import { IntegrationConfig } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Heading,
  IconEnum,
  Txt,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { CatalogTypeSchemaSection } from "./CatalogTypeSchemaSection";
import { CatalogTypeCreateEditFormState, CatalogTypeInfo } from "./types";

export const CatalogTypeSchemaDrawer = ({
  selectedDynamicAttribute,
  onClose,
  requiredIntegration,
  parentTypeName,
  onSubmit,
}: {
  selectedDynamicAttribute?: CatalogTypeInfo;
  onClose: () => void;
  requiredIntegration?: IntegrationConfig;
  parentTypeName: string;
  onSubmit: (data: CatalogTypeCreateEditFormState) => void;
}) => {
  return (
    <AnimatePresence>
      {selectedDynamicAttribute && (
        <Drawer warnWhenDirty onClose={onClose} width="large">
          <CatalogTypeSchemaDrawerInner
            onSubmit={onSubmit}
            onClose={onClose}
            parentTypeName={parentTypeName}
            requiredIntegration={requiredIntegration}
            catalogTypeInfo={selectedDynamicAttribute}
          />
        </Drawer>
      )}
    </AnimatePresence>
  );
};

const CatalogTypeSchemaDrawerInner = ({
  catalogTypeInfo,
  onSubmit,
  requiredIntegration,
  parentTypeName,
  onClose,
}: {
  catalogTypeInfo: CatalogTypeInfo;
  onSubmit: (data: CatalogTypeCreateEditFormState) => void;
  requiredIntegration?: IntegrationConfig;
  parentTypeName: string;
  onClose: () => void;
}) => {
  // Most of this form state will be empty, but we're going to use the same type
  // as the type create/edit form for convenience's sake I have tried making
  // this a subset of the request body type, but I can't convince typescript
  // that both are acceptable
  const formMethods = useForm<CatalogTypeCreateEditFormState>({
    defaultValues: {
      schema: {
        data_attributes: [],
        derived_attributes: [],
      },
    },
  });

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const formId = "additional-type-schema-form";
  const attributes = formMethods.watch("schema.data_attributes");
  const newCatalogTypes =
    attributes?.filter((x) => x.catalogTypeInfo?.exists === false) ?? [];

  return (
    <DrawerContents>
      <DrawerTitle
        icon={IconEnum.Box}
        color={ColorPaletteEnum.Blue}
        title={`Create ${catalogTypeInfo.name}`}
        onClose={() => onCloseWithWarn(isDirty)}
      />
      <DrawerBody>
        <Callout
          showIcon={false}
          theme={CalloutTheme.Plain}
          className="border-none p-4"
          title={`${catalogTypeInfo.name} references another Catalog type`}
          subtitle={`Use this drawer to configure its attributes. We'll create it automatically when you finish editing ${parentTypeName}.`}
        />
        <div>
          <Heading level={2} size="medium" className="mb-4">
            Preview
          </Heading>
          <CatalogEntryBadge
            // Just picked a nice colour and default icon - if we decide we care /
            // people get confused, we can add these to the catalogTypeInfo since
            // they're defined on the resource
            color={ColorPaletteEnum.Blue}
            icon={IconEnum.Box}
            label={catalogTypeInfo.name}
            className="max-w-full"
          />
        </div>
        <Form.Root formMethods={formMethods} onSubmit={onSubmit} id={formId}>
          <CatalogTypeSchemaSection
            formMethods={formMethods}
            catalogTypeInfo={catalogTypeInfo}
            requiredIntegration={requiredIntegration}
            hideDerivedAttributes
          />
        </Form.Root>
        {newCatalogTypes?.length > 0 ? (
          // If additionalTypes is populated, its payloads will always have
          // registry_type/parameter/name - ts just doesn't know that yet.
          <Callout
            theme={CalloutTheme.Plain}
            className="p-4 border-none text-slate-700"
          >
            We&apos;ll add{" "}
            {joinSpansWithCommasAndConnectorWord(
              newCatalogTypes.map((t, i) => (
                <Txt inline grey bold key={i}>
                  {t.catalogTypeInfo?.name}
                </Txt>
              )),
            )}{" "}
            as a new type. You can edit it in the Catalog once created.
          </Callout>
        ) : null}
      </DrawerBody>
      <DrawerFooter>
        <Button
          analyticsTrackingId={null}
          type="submit"
          form={formId}
          theme={ButtonTheme.Primary}
          className="ml-auto"
        >
          Continue
        </Button>
      </DrawerFooter>
    </DrawerContents>
  );
};
