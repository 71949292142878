import cx from "classnames";
import React, { ChangeEvent } from "react";

import styles from "./Checkbox.module.scss";

export type CheckboxProps = {
  label?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  value?: string;
  id: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
} & React.HTMLAttributes<HTMLInputElement>;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { label, className, children: _children, ...restProps }: CheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <label
        className={cx(
          styles.checkbox,
          "text-sm text-content-primary dark:text-slate-50 group/check",
          className,
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          className={cx(
            "mr-2",
            "shadow-xs",
            "transition",
            "group-hover/check:border-slate-500",
            "dark:group-hover/check:border-slate-50",
          )}
          {...restProps}
        />
        {label}
      </label>
    );
  },
);

Checkbox.displayName = "Checkbox";
