import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { useParams } from "react-router-dom";

import { CustomFieldCreateEditDrawer } from "./create-edit/CustomFieldCreateEditDrawer";

export const CustomFieldEditRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/custom-fields");
  const { id: customFieldID } = useParams<{ id: string }>();

  return (
    <CustomFieldCreateEditDrawer
      onClose={onClose}
      onSuccess={onClose}
      customFieldID={customFieldID}
    />
  );
};
