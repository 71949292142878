/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentChannelSettings,
    IncidentChannelSettingsFromJSON,
    IncidentChannelSettingsFromJSONTyped,
    IncidentChannelSettingsToJSON,
} from './IncidentChannelSettings';
import {
    SeverityAlias,
    SeverityAliasFromJSON,
    SeverityAliasFromJSONTyped,
    SeverityAliasToJSON,
} from './SeverityAlias';

/**
 * 
 * @export
 * @interface IncidentType
 */
export interface IncidentType {
    /**
     * Whether to automatically archive Slack channels for incidents of this type after a number of days
     * @type {boolean}
     * @memberof IncidentType
     */
    auto_archive_slack_channels: boolean;
    /**
     * Number of days to wait before automatically archiving Slack channels for incidents of this type
     * @type {number}
     * @memberof IncidentType
     */
    auto_archive_slack_channels_delay_days: number;
    /**
     * Whether to automatically close incidents of this type after a number of days
     * @type {boolean}
     * @memberof IncidentType
     */
    auto_close_incidents: boolean;
    /**
     * Number of days to wait before automatically closing incidents of this type
     * @type {number}
     * @memberof IncidentType
     */
    auto_close_incidents_delay_days: number;
    /**
     * Whether incidents of this must always, or can optionally, be created in triage
     * @type {string}
     * @memberof IncidentType
     */
    create_in_triage: IncidentTypeCreateInTriageEnum;
    /**
     * When this resource was created
     * @type {Date}
     * @memberof IncidentType
     */
    created_at: Date;
    /**
     * A postmortem destination to be used as the default
     * @type {string}
     * @memberof IncidentType
     */
    default_postmortem_destination_id?: string;
    /**
     * A postmortem template to be used as the default
     * @type {string}
     * @memberof IncidentType
     */
    default_postmortem_template_id?: string;
    /**
     * What is this incident type for?
     * @type {string}
     * @memberof IncidentType
     */
    description: string;
    /**
     * Unique identifier for this Incident Type
     * @type {string}
     * @memberof IncidentType
     */
    id: string;
    /**
     * 
     * @type {IncidentChannelSettings}
     * @memberof IncidentType
     */
    incident_channel_name_override?: IncidentChannelSettings;
    /**
     * The default Incident Type is used when no other type is explicitly specified
     * @type {boolean}
     * @memberof IncidentType
     */
    is_default: boolean;
    /**
     * The name of this Incident Type
     * @type {string}
     * @memberof IncidentType
     */
    name: string;
    /**
     * Whether to override incident Slack channel auto-archive settings for this incident type
     * @type {boolean}
     * @memberof IncidentType
     */
    override_auto_archive_slack_channels: boolean;
    /**
     * Whether to override incident auto-close settings for this incident type
     * @type {boolean}
     * @memberof IncidentType
     */
    override_auto_close_incidents: boolean;
    /**
     * Unique identifier for the lifecycle to be used for the incident type (if not the default)
     * @type {string}
     * @memberof IncidentType
     */
    override_incident_lifecycle_id?: string;
    /**
     * The name to be used in-place of the noun Postmortem
     * @type {string}
     * @memberof IncidentType
     */
    postmortem_name_override?: string;
    /**
     * Should all incidents created with this Incident Type be private?
     * @type {boolean}
     * @memberof IncidentType
     */
    private_incidents_only: boolean;
    /**
     * Rank to help sort custom fields
     * @type {number}
     * @memberof IncidentType
     */
    rank: number;
    /**
     * Whether the current user is restricted from declaring/accepting incidents of this type
     * @type {boolean}
     * @memberof IncidentType
     */
    restricted?: boolean;
    /**
     * Aliases that are applied to severities for this incident type
     * @type {Array<SeverityAlias>}
     * @memberof IncidentType
     */
    severity_aliases: Array<SeverityAlias>;
    /**
     * When this resource was last updated
     * @type {Date}
     * @memberof IncidentType
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum IncidentTypeCreateInTriageEnum {
    Always = 'always',
    Optional = 'optional'
}

export function IncidentTypeFromJSON(json: any): IncidentType {
    return IncidentTypeFromJSONTyped(json, false);
}

export function IncidentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_archive_slack_channels': json['auto_archive_slack_channels'],
        'auto_archive_slack_channels_delay_days': json['auto_archive_slack_channels_delay_days'],
        'auto_close_incidents': json['auto_close_incidents'],
        'auto_close_incidents_delay_days': json['auto_close_incidents_delay_days'],
        'create_in_triage': json['create_in_triage'],
        'created_at': (new Date(json['created_at'])),
        'default_postmortem_destination_id': !exists(json, 'default_postmortem_destination_id') ? undefined : json['default_postmortem_destination_id'],
        'default_postmortem_template_id': !exists(json, 'default_postmortem_template_id') ? undefined : json['default_postmortem_template_id'],
        'description': json['description'],
        'id': json['id'],
        'incident_channel_name_override': !exists(json, 'incident_channel_name_override') ? undefined : IncidentChannelSettingsFromJSON(json['incident_channel_name_override']),
        'is_default': json['is_default'],
        'name': json['name'],
        'override_auto_archive_slack_channels': json['override_auto_archive_slack_channels'],
        'override_auto_close_incidents': json['override_auto_close_incidents'],
        'override_incident_lifecycle_id': !exists(json, 'override_incident_lifecycle_id') ? undefined : json['override_incident_lifecycle_id'],
        'postmortem_name_override': !exists(json, 'postmortem_name_override') ? undefined : json['postmortem_name_override'],
        'private_incidents_only': json['private_incidents_only'],
        'rank': json['rank'],
        'restricted': !exists(json, 'restricted') ? undefined : json['restricted'],
        'severity_aliases': ((json['severity_aliases'] as Array<any>).map(SeverityAliasFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentTypeToJSON(value?: IncidentType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_archive_slack_channels': value.auto_archive_slack_channels,
        'auto_archive_slack_channels_delay_days': value.auto_archive_slack_channels_delay_days,
        'auto_close_incidents': value.auto_close_incidents,
        'auto_close_incidents_delay_days': value.auto_close_incidents_delay_days,
        'create_in_triage': value.create_in_triage,
        'created_at': (value.created_at.toISOString()),
        'default_postmortem_destination_id': value.default_postmortem_destination_id,
        'default_postmortem_template_id': value.default_postmortem_template_id,
        'description': value.description,
        'id': value.id,
        'incident_channel_name_override': IncidentChannelSettingsToJSON(value.incident_channel_name_override),
        'is_default': value.is_default,
        'name': value.name,
        'override_auto_archive_slack_channels': value.override_auto_archive_slack_channels,
        'override_auto_close_incidents': value.override_auto_close_incidents,
        'override_incident_lifecycle_id': value.override_incident_lifecycle_id,
        'postmortem_name_override': value.postmortem_name_override,
        'private_incidents_only': value.private_incidents_only,
        'rank': value.rank,
        'restricted': value.restricted,
        'severity_aliases': ((value.severity_aliases as Array<any>).map(SeverityAliasToJSON)),
        'updated_at': (value.updated_at.toISOString()),
    };
}

