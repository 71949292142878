import {
  EscalationPreview,
  EscalationStatusEnum,
  Incident,
} from "@incident-io/api";
import { EscalationTarget } from "@incident-io/query-api";
import { Product } from "@incident-shared/billing";
import {
  IconEnum,
  IconSize,
  LoadingWrapper,
  StackedList,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { sortBy, uniqBy } from "lodash";
import { IncidentDrawer } from "src/routes/legacy/IncidentRoute";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import {
  EscalationStackedListItem,
  EscalationTransitionIcon,
} from "../../../../escalations/EscalationStackedListItem";
import { AvatarList, AvatarListClickableType } from "../AvatarList";
import { Sidebar } from "../Components";
import { LinkBuilder, LinkBuilderProps } from "./builders";

const Escalations = (props: LinkBuilderProps) => {
  const navigateToDrawer = useNavigateToModal();

  return (
    <Sidebar.Entry
      label="Escalations"
      value={
        <EscalationsValue
          escalations={props.escalations}
          openEscalateDrawer={() => navigateToDrawer(IncidentDrawer.Escalate)}
          openViewEscalationsDrawer={() =>
            navigateToDrawer(IncidentDrawer.Escalations)
          }
        />
      }
    />
  );
};

export const EscalationsValue = ({
  escalations,
  openEscalateDrawer,
  openViewEscalationsDrawer,
}: {
  escalations: EscalationPreview[];
  openEscalateDrawer?: () => void;
  openViewEscalationsDrawer: () => void;
}) => {
  if (escalations.length === 0) {
    if (openEscalateDrawer) {
      return (
        <Sidebar.EmptyValueButton
          onClick={openEscalateDrawer}
          analyticsTrackingId="incident-sidebar-escalate"
        >
          Escalate
        </Sidebar.EmptyValueButton>
      );
    }
    return <Sidebar.EmptyValue>None</Sidebar.EmptyValue>;
  }

  const uniqueTargets = uniqBy(
    escalations.flatMap((escalation) => escalation.targets),
    (t) => (t ? t.user_id || t.slack_channel_name : ""),
  ).filter(Boolean) as EscalationTarget[];
  const targetUsers = uniqueTargets.map((t: EscalationTarget) => ({
    user: {
      id: t.id,
      name: t.slack_channel_name || t.user_name || "",
      avatar_url: t.image_url,
    },
  }));

  if (escalations.length === 1) {
    const escalation = escalations[0];
    return (
      <Sidebar.ValueButton
        iconNode={
          <AvatarList
            modalTitle={"Escalated users"}
            maxToShow={3}
            users={targetUsers}
            clickableType={AvatarListClickableType.OnlyOnSeeMore}
            avatarClassName="border-surface-secondary group-hover:border-surface-tertiary"
          />
        }
        analyticsTrackingId="view-escalation"
        href={`/on-call/escalations/${escalation.id}`}
        suffixNode={
          <EscalationTransitionIcon
            status={escalation.status as unknown as EscalationStatusEnum}
            size={IconSize.Medium}
          />
        }
      >
        {escalation.title}
      </Sidebar.ValueButton>
    );
  }

  return (
    <Tooltip content="View escalations">
      <Sidebar.ValueButton
        iconNode={
          <AvatarList
            modalTitle={"Escalated users"}
            maxToShow={3}
            users={targetUsers}
            clickableType={AvatarListClickableType.OnlyOnSeeMore}
            avatarClassName="border-surface-secondary group-hover:border-surface-tertiary"
          />
        }
        analyticsTrackingId="incident-sidebar-view-escalations"
        onClick={openViewEscalationsDrawer}
      >
        {escalations.length}
      </Sidebar.ValueButton>
    </Tooltip>
  );
};

const createEscalationItem = ({
  escalationProviders,
  navigateToModal,
}: LinkBuilderProps) => {
  if (escalationProviders.length === 0) {
    return [];
  }

  return [
    {
      icon: IconEnum.Escalate,
      onSelect: () => {
        navigateToModal(IncidentDrawer.Escalate);
      },
      label: "Escalate to someone",
      analyticsTrackingId: "incident-sidebar-escalate",
    },
  ];
};

export const EscalationsDrawer = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement | null => {
  const {
    data: { escalations },
    isLoading: escalationsLoading,
  } = useAPI(
    "escalationsList",
    {
      incident: {
        one_of: [incident.id],
      },
    },
    {
      fallbackData: {
        escalations: [],
        pagination_meta: {
          page_size: 0,
          total_count: 0,
        },
      },
    },
  );

  // Order escalations by status
  const sortedEscalations = sortBy(
    escalations,
    (escalation) => escalation.status,
  );

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          icon={IconEnum.Escalate}
          onClose={onClose}
          title={"Escalations"}
        />
        <DrawerBody className="overflow-y-auto">
          <LoadingWrapper loading={escalationsLoading} className="space-y-4">
            <StackedList>
              {sortedEscalations.map((escalation) => (
                <EscalationStackedListItem
                  key={escalation.id}
                  escalation={escalation}
                />
              ))}
            </StackedList>
          </LoadingWrapper>
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

export const EscalationsBuilder: LinkBuilder = {
  Render: Escalations,
  visible: ({ escalationPaths, escalations }) =>
    escalationPaths.length > 0 || escalations.length > 0,
  requiredProduct: Product.OnCall,
  getDropdownItems: createEscalationItem,
};
