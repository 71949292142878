import {
  ErrorResponse,
  IssueTemplateSlimContextEnum,
  IssueTemplateSlimInvalidReasonEnum,
} from "@incident-io/api";
import { sortBy } from "lodash";
import { useAPI } from "src/utils/swr";

import { IssueTrackerProviderEnum } from "./utils";

export type IssueTemplateContextEnum = IssueTemplateSlimContextEnum;
export const IssueTemplateContextEnum = IssueTemplateSlimContextEnum;
export type InvalidReasonEnum = IssueTemplateSlimInvalidReasonEnum;
export const InvalidReasonEnum = IssueTemplateSlimInvalidReasonEnum;
export type UnifiedIssueTemplate = {
  id: string;
  name: string;
  provider: IssueTrackerProviderEnum;
  context: IssueTemplateContextEnum;
  invalid_reason: InvalidReasonEnum;
  // 1 is legacy, 2 is using the generic methods.
  version: 1 | 2;
};

export const useAllIssueTemplates = (
  context: IssueTemplateContextEnum,
): {
  allTemplates: UnifiedIssueTemplate[];
  isLoading: boolean;
  error?: ErrorResponse;
} => {
  const {
    data: issueTemplatesV1,
    isLoading: v1Loading,
    error: v1Error,
  } = useAPI(
    "issueTrackerIssueTemplatesList",
    {},
    {
      fallbackData: { issue_tracker_issue_templates: [] },
    },
  );

  const {
    data: issueTemplates,
    isLoading: v2Loading,
    error: v2Error,
  } = useAPI(
    "issueTrackersV2ListIssueTemplates",
    {},
    {
      fallbackData: { issue_templates: [] },
    },
  );

  const allTemplates: UnifiedIssueTemplate[] = [
    ...issueTemplatesV1.issue_tracker_issue_templates.map((template) => ({
      id: template.id,
      name: template.name,
      provider:
        template.issue_tracker_provider as unknown as IssueTrackerProviderEnum,
      context: template.context as unknown as IssueTemplateContextEnum,
      invalid_reason: template.invalid_reason as unknown as InvalidReasonEnum,
      version: 1 as const,
    })),
    ...issueTemplates.issue_templates.map((template) => ({
      id: template.id,
      name: template.name,
      provider: template.provider as unknown as IssueTrackerProviderEnum,
      context: template.context,
      invalid_reason: template.invalid_reason,
      version: 2 as const,
    })),
  ];

  return {
    allTemplates: sortBy(
      allTemplates.filter((template) => template.context === context),
      ["provider", "name"],
    ),
    isLoading: v1Loading || v2Loading,
    error: v1Error ?? v2Error,
  };
};
