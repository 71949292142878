import { StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum } from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

export const ChooseUptimeDisplayMode = <FormType extends FieldValues>({
  name,
  label,
  helptext,
  formMethods,
}: {
  name: Path<FormType>;
  label: string;
  helptext: string;
  formMethods: UseFormReturn<FormType>;
}) => {
  return (
    <RadioButtonGroupV2
      formMethods={formMethods}
      name={name}
      label={label}
      srLabel="Historical data"
      boxed
      horizontal
      helptext={helptext}
      options={[
        {
          value: DisplayUptimeModeEnum.ChartAndPercentage,
          label: "Coloured bars and uptime percentage",
        },
        {
          value: DisplayUptimeModeEnum.ChartOnly,
          label: "Coloured bars only",
        },
        {
          value: DisplayUptimeModeEnum.Nothing,
          label: "Nothing",
        },
      ]}
    />
  );
};
