/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SuggestedTemplate,
    SuggestedTemplateFromJSON,
    SuggestedTemplateFromJSONTyped,
    SuggestedTemplateToJSON,
} from './SuggestedTemplate';

/**
 * 
 * @export
 * @interface PostmortemsListSuggestedTemplatesResponseBody
 */
export interface PostmortemsListSuggestedTemplatesResponseBody {
    /**
     * 
     * @type {Array<SuggestedTemplate>}
     * @memberof PostmortemsListSuggestedTemplatesResponseBody
     */
    suggested_templates: Array<SuggestedTemplate>;
}

export function PostmortemsListSuggestedTemplatesResponseBodyFromJSON(json: any): PostmortemsListSuggestedTemplatesResponseBody {
    return PostmortemsListSuggestedTemplatesResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsListSuggestedTemplatesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsListSuggestedTemplatesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suggested_templates': ((json['suggested_templates'] as Array<any>).map(SuggestedTemplateFromJSON)),
    };
}

export function PostmortemsListSuggestedTemplatesResponseBodyToJSON(value?: PostmortemsListSuggestedTemplatesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suggested_templates': ((value.suggested_templates as Array<any>).map(SuggestedTemplateToJSON)),
    };
}

