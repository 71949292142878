/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    IncidentActivityLogEntry,
    IncidentActivityLogEntryFromJSON,
    IncidentActivityLogEntryFromJSONTyped,
    IncidentActivityLogEntryToJSON,
} from './IncidentActivityLogEntry';

/**
 * 
 * @export
 * @interface Evidence
 */
export interface Evidence {
    /**
     * Timestamp when the timeline item was created
     * @type {Date}
     * @memberof Evidence
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof Evidence
     */
    creator: Actor;
    /**
     * Unique identifier of the evidence
     * @type {string}
     * @memberof Evidence
     */
    id: string;
    /**
     * ID of the image associated with this evidence
     * @type {string}
     * @memberof Evidence
     */
    image_id?: string;
    /**
     * 
     * @type {IncidentActivityLogEntry}
     * @memberof Evidence
     */
    incident_activity_log?: IncidentActivityLogEntry;
    /**
     * ID of the activity log entry that this evidence is associated with
     * @type {string}
     * @memberof Evidence
     */
    incident_activity_log_id?: string;
    /**
     * Timestamp when the timeline item was last updated
     * @type {Date}
     * @memberof Evidence
     */
    updated_at: Date;
    /**
     * URL of the evidence
     * @type {string}
     * @memberof Evidence
     */
    url?: string;
}

export function EvidenceFromJSON(json: any): Evidence {
    return EvidenceFromJSONTyped(json, false);
}

export function EvidenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Evidence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'creator': ActorFromJSON(json['creator']),
        'id': json['id'],
        'image_id': !exists(json, 'image_id') ? undefined : json['image_id'],
        'incident_activity_log': !exists(json, 'incident_activity_log') ? undefined : IncidentActivityLogEntryFromJSON(json['incident_activity_log']),
        'incident_activity_log_id': !exists(json, 'incident_activity_log_id') ? undefined : json['incident_activity_log_id'],
        'updated_at': (new Date(json['updated_at'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function EvidenceToJSON(value?: Evidence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'id': value.id,
        'image_id': value.image_id,
        'incident_activity_log': IncidentActivityLogEntryToJSON(value.incident_activity_log),
        'incident_activity_log_id': value.incident_activity_log_id,
        'updated_at': (value.updated_at.toISOString()),
        'url': value.url,
    };
}

