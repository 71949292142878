/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldOption,
    CustomFieldOptionFromJSON,
    CustomFieldOptionFromJSONTyped,
    CustomFieldOptionToJSON,
} from './CustomFieldOption';
import {
    EmbeddedCatalogEntry,
    EmbeddedCatalogEntryFromJSON,
    EmbeddedCatalogEntryFromJSONTyped,
    EmbeddedCatalogEntryToJSON,
} from './EmbeddedCatalogEntry';

/**
 * 
 * @export
 * @interface CustomFieldValue
 */
export interface CustomFieldValue {
    /**
     * 
     * @type {EmbeddedCatalogEntry}
     * @memberof CustomFieldValue
     */
    value_catalog_entry?: EmbeddedCatalogEntry;
    /**
     * If the custom field type is 'link', this will contain the value assigned.
     * @type {string}
     * @memberof CustomFieldValue
     */
    value_link?: string;
    /**
     * If the custom field type is 'numeric', this will contain the value assigned.
     * @type {string}
     * @memberof CustomFieldValue
     */
    value_numeric?: string;
    /**
     * 
     * @type {CustomFieldOption}
     * @memberof CustomFieldValue
     */
    value_option?: CustomFieldOption;
    /**
     * If the custom field type is 'text', this will contain the value assigned.
     * @type {string}
     * @memberof CustomFieldValue
     */
    value_text?: string;
}

export function CustomFieldValueFromJSON(json: any): CustomFieldValue {
    return CustomFieldValueFromJSONTyped(json, false);
}

export function CustomFieldValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value_catalog_entry': !exists(json, 'value_catalog_entry') ? undefined : EmbeddedCatalogEntryFromJSON(json['value_catalog_entry']),
        'value_link': !exists(json, 'value_link') ? undefined : json['value_link'],
        'value_numeric': !exists(json, 'value_numeric') ? undefined : json['value_numeric'],
        'value_option': !exists(json, 'value_option') ? undefined : CustomFieldOptionFromJSON(json['value_option']),
        'value_text': !exists(json, 'value_text') ? undefined : json['value_text'],
    };
}

export function CustomFieldValueToJSON(value?: CustomFieldValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value_catalog_entry': EmbeddedCatalogEntryToJSON(value.value_catalog_entry),
        'value_link': value.value_link,
        'value_numeric': value.value_numeric,
        'value_option': CustomFieldOptionToJSON(value.value_option),
        'value_text': value.value_text,
    };
}

