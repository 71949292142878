/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentRelationshipDetails
 */
export interface IncidentRelationshipDetails {
    /**
     * External ID of this incident often prepended with 'INC-'
     * @type {number}
     * @memberof IncidentRelationshipDetails
     */
    externalId: number;
    /**
     * Unique identifier of this incident
     * @type {string}
     * @memberof IncidentRelationshipDetails
     */
    id: string;
    /**
     * Name of this incident
     * @type {string}
     * @memberof IncidentRelationshipDetails
     */
    name: string;
}

export function IncidentRelationshipDetailsFromJSON(json: any): IncidentRelationshipDetails {
    return IncidentRelationshipDetailsFromJSONTyped(json, false);
}

export function IncidentRelationshipDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRelationshipDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': json['externalId'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function IncidentRelationshipDetailsToJSON(value?: IncidentRelationshipDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'id': value.id,
        'name': value.name,
    };
}

