/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';

/**
 * 
 * @export
 * @interface SchedulesSetMirroredSlackUserGroupResponseBody
 */
export interface SchedulesSetMirroredSlackUserGroupResponseBody {
    /**
     * 
     * @type {Schedule}
     * @memberof SchedulesSetMirroredSlackUserGroupResponseBody
     */
    schedule: Schedule;
}

export function SchedulesSetMirroredSlackUserGroupResponseBodyFromJSON(json: any): SchedulesSetMirroredSlackUserGroupResponseBody {
    return SchedulesSetMirroredSlackUserGroupResponseBodyFromJSONTyped(json, false);
}

export function SchedulesSetMirroredSlackUserGroupResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesSetMirroredSlackUserGroupResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule': ScheduleFromJSON(json['schedule']),
    };
}

export function SchedulesSetMirroredSlackUserGroupResponseBodyToJSON(value?: SchedulesSetMirroredSlackUserGroupResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule': ScheduleToJSON(value.schedule),
    };
}

