import { NotFoundError } from "@incident-io/status-page-ui";

import { PageWrapper } from "./PageWrapper";

export const InternalStatusPageNotFound = () => {
  return (
    <PageWrapper>
      <NotFoundError />
    </PageWrapper>
  );
};
