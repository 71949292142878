/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentAutoSubscribeRule,
    IncidentAutoSubscribeRuleFromJSON,
    IncidentAutoSubscribeRuleFromJSONTyped,
    IncidentAutoSubscribeRuleToJSON,
} from './IncidentAutoSubscribeRule';

/**
 * 
 * @export
 * @interface IncidentSubscriptionsListAutoSubscribeRulesResponseBody
 */
export interface IncidentSubscriptionsListAutoSubscribeRulesResponseBody {
    /**
     * 
     * @type {Array<IncidentAutoSubscribeRule>}
     * @memberof IncidentSubscriptionsListAutoSubscribeRulesResponseBody
     */
    incident_auto_subscribe_rules: Array<IncidentAutoSubscribeRule>;
}

export function IncidentSubscriptionsListAutoSubscribeRulesResponseBodyFromJSON(json: any): IncidentSubscriptionsListAutoSubscribeRulesResponseBody {
    return IncidentSubscriptionsListAutoSubscribeRulesResponseBodyFromJSONTyped(json, false);
}

export function IncidentSubscriptionsListAutoSubscribeRulesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSubscriptionsListAutoSubscribeRulesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_auto_subscribe_rules': ((json['incident_auto_subscribe_rules'] as Array<any>).map(IncidentAutoSubscribeRuleFromJSON)),
    };
}

export function IncidentSubscriptionsListAutoSubscribeRulesResponseBodyToJSON(value?: IncidentSubscriptionsListAutoSubscribeRulesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_auto_subscribe_rules': ((value.incident_auto_subscribe_rules as Array<any>).map(IncidentAutoSubscribeRuleToJSON)),
    };
}

