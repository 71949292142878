import type { SVGProps } from "react";
import * as React from "react";
const SvgEye = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.25 10a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.462 11.545C3.559 13.209 5.997 16 10 16s6.441-2.792 7.538-4.456a2.8 2.8 0 0 0 0-3.09C16.441 6.79 14.003 3.999 10 3.999S3.559 6.79 2.462 8.454a2.8 2.8 0 0 0 0 3.091ZM3.714 9.28C4.645 7.869 6.699 5.5 10 5.5c3.301 0 5.355 2.369 6.286 3.781.284.43.284 1.008 0 1.439-.931 1.411-2.985 3.78-6.286 3.78-3.301 0-5.355-2.369-6.286-3.781a1.322 1.322 0 0 1 0-1.439Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEye;
