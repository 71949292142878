import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import { SelectOption, SelectOptions } from "@incident-ui/Select/types";
import _, { compact, maxBy } from "lodash";
import {
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageComponentImpactStatusEnum,
  StatusPageIncident,
  StatusPageIncidentUpdateToStatusEnum as IncidentStatusEnum,
  StatusPageStructureGroup,
} from "src/contexts/ClientContext";

export const INCIDENT_STATUS_CONFIG = {
  [IncidentStatusEnum.Investigating]: {
    label: "Investigating",
    value: IncidentStatusEnum.Investigating,
    rank: 0,
  },
  [IncidentStatusEnum.Identified]: {
    label: "Identified",
    value: IncidentStatusEnum.Identified,
    rank: 1,
  },
  [IncidentStatusEnum.Monitoring]: {
    label: "Monitoring",
    value: IncidentStatusEnum.Monitoring,
    rank: 2,
  },
  [IncidentStatusEnum.Resolved]: {
    label: "Resolved",
    value: IncidentStatusEnum.Resolved,
    rank: 3,
  },
};

export const MAINTENANCE_STATUS_CONFIG = {
  [IncidentStatusEnum.MaintenanceScheduled]: {
    label: "Scheduled",
    value: IncidentStatusEnum.MaintenanceScheduled,
    rank: 0,
    helptext:
      "This maintenance window will appear in the 'upcoming maintenance' section of your status page",
  },
  [IncidentStatusEnum.MaintenanceInProgress]: {
    label: "In progress",
    value: IncidentStatusEnum.MaintenanceInProgress,
    rank: 1,
    helptext:
      "This maintenance window will appear in the 'active maintenance' section of your status page.",
  },
  [IncidentStatusEnum.MaintenanceComplete]: {
    label: "Complete",
    value: IncidentStatusEnum.MaintenanceComplete,
    rank: 2,
    helptext: "This maintenance window will appear as complete.",
  },
};

type StatusConfig = {
  label: string;
  value: IncidentStatusEnum;
  rank: number;
};

export const ALL_STATUS_CONFIGS: { [key in IncidentStatusEnum]: StatusConfig } =
  {
    ...INCIDENT_STATUS_CONFIG,
    ...MAINTENANCE_STATUS_CONFIG,
  };

export const INITIAL_INCIDENT_STATUS = _.minBy(
  Object.values(INCIDENT_STATUS_CONFIG),
  (value) => value.rank,
)?.value as IncidentStatusEnum;

export const INITIAL_MAINTENANCE_STATUS = _.minBy(
  Object.values(MAINTENANCE_STATUS_CONFIG),
  (value) => value.rank,
)?.value as IncidentStatusEnum;

export const incidentStatusSelectOptions: SelectOption[] = Object.values(
  INCIDENT_STATUS_CONFIG,
).map((cfg) => ({
  label: cfg.label,
  value: cfg.value,
}));

export const maintenanceStatusSelectOptions: SelectOption[] = Object.values(
  MAINTENANCE_STATUS_CONFIG,
).map((cfg) => ({
  label: cfg.label,
  value: cfg.value,
}));

export const impactOptions = ({
  isUpdate,
  componentPreviouslyAffected,
  maintenance,
}: {
  isUpdate?: boolean;
  componentPreviouslyAffected?: boolean;
  maintenance?: boolean;
}): SelectOptions => {
  // if we're in maintenance, only support operational and under maintenance
  const values = maintenance
    ? [ComponentStatusEnum.Operational, ComponentStatusEnum.UnderMaintenance]
    : [
        ComponentStatusEnum.Operational,
        ComponentStatusEnum.DegradedPerformance,
        ComponentStatusEnum.PartialOutage,
        ComponentStatusEnum.FullOutage,
      ];

  return (
    values
      .map((value) => {
        const cfg = COMPONENT_STATUS_CONFIG[value];
        let label = cfg.label;

        if (value === ComponentStatusEnum.Operational && !isUpdate) {
          label = "No impact";
        }

        return {
          value,
          label,
          icon: cfg.icon,
          iconProps: { className: cfg.colour },
        };
      })
      // if we're updating and a component wasn't included until now, it can't be set as operational - they should just remove it
      .filter((option) =>
        isUpdate && !componentPreviouslyAffected
          ? option.value !== ComponentStatusEnum.Operational
          : true,
      )
  );
};

export const INCIDENT_STATUS_NAME = {
  [IncidentStatusEnum.Identified]: "Identified",
  [IncidentStatusEnum.Investigating]: "Investigating",
  [IncidentStatusEnum.Monitoring]: "Monitoring",
  [IncidentStatusEnum.Resolved]: "Resolved",
  [IncidentStatusEnum.MaintenanceScheduled]: "Scheduled",
  [IncidentStatusEnum.MaintenanceInProgress]: "In Progress",
  [IncidentStatusEnum.MaintenanceComplete]: "Complete",
};

export const MAINTENANCE_STATUS_NAME = {
  [IncidentStatusEnum.MaintenanceScheduled]: "Scheduled",
  [IncidentStatusEnum.MaintenanceInProgress]: "In Progress",
  [IncidentStatusEnum.MaintenanceComplete]: "Complete",
};

export const currentComponentStatusesForIncident = (
  incident: StatusPageIncident,
): {
  [x: string]: ComponentStatusEnum | undefined;
} => {
  const componentStatuses = {};

  // we want our affected components to come from a combination of updates, and our
  // component impacts timeline.
  // If a component was ever affected, we want to  show it, even if its status is operational.
  incident.component_impacts.forEach((impact) => {
    if (!impact.end_at || impact.end_at > new Date()) {
      componentStatuses[impact.component_id] = impact.status;
    }
  });

  incident.affected_components.forEach(({ component_id }) => {
    if (!componentStatuses[component_id]) {
      componentStatuses[component_id] = ComponentStatusEnum.Operational;
    }
  });

  return componentStatuses;
};

export const worstStatusForGroup = (
  group: StatusPageStructureGroup,
  worstImpacts: Record<string, StatusPageComponentImpactStatusEnum | undefined>,
): StatusPageComponentImpactStatusEnum | undefined => {
  const componentStatuses = compact(
    group.components.map((comp) => worstImpacts[comp.component_id]),
  );

  const worstStatus = maxBy(
    componentStatuses,
    (status) => COMPONENT_STATUS_CONFIG[status].rank,
  );

  return worstStatus;
};

export const getImpactWindow = (
  incident: StatusPageIncident,
): [Date | undefined, Date | undefined] => {
  // get the min start of component impacts and max end.
  const start = _.minBy(incident.component_impacts, (impact) => impact.start_at)
    ?.start_at;

  const end = _.maxBy(incident.component_impacts, (impact) => impact.end_at)
    ?.end_at;

  return [start, end];
};

export const hasWriteUp = (inc: StatusPageIncident) => {
  return !!inc.write_up_url || !!inc.write_up_contents;
};
