import { useOrganisationHasOnCallAccess } from "src/hooks/useOrganisationHasOnCallAccess";
import { useLocalStorage } from "use-hooks";

const localStorageKey = "onCallBannerDismissed";

export const useShowOnCallBanner = () => {
  const [isDismissed, setDismissed] = useLocalStorage(localStorageKey, false);
  const hasOnCall = useOrganisationHasOnCallAccess();

  return {
    showOnCallBanner: !isDismissed && !hasOnCall,
    dismissOnCallBanner: () => setDismissed(true),
  };
};
