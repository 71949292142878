import type { SVGProps } from "react";
import * as React from "react";
const SvgClickUp = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#click-up_svg__a)"
      fillRule="evenodd"
      d="m3 14.195 2.526-1.935c1.341 1.752 2.766 2.559 4.353 2.559 1.578 0 2.963-.798 4.245-2.536l2.56 1.889C14.837 16.677 12.54 18 9.88 18 7.228 18 4.908 16.686 3 14.195Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#click-up_svg__b)"
      fillRule="evenodd"
      d="M9.87 5.786 5.376 9.66 3.298 7.25l6.581-5.672 6.53 5.676-2.087 2.401-4.452-3.87Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="click-up_svg__a"
        x1={3}
        x2={16.684}
        y1={16.164}
        y2={16.164}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8930FD" />
        <stop offset={1} stopColor="#49CCF9" />
      </linearGradient>
      <linearGradient
        id="click-up_svg__b"
        x1={3.298}
        x2={16.41}
        y1={7.074}
        y2={7.074}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF02F0" />
        <stop offset={1} stopColor="#FFC800" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgClickUp;
