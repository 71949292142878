import {
  AlertRoute,
  AlertSource,
  AlertSourceClassificationEnum,
  AlertSourceConfig,
  AlertSourceConfigStateEnum,
  AlertSourceSourceTypeEnum,
  IntegrationSettingsProviderEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  getBrokenIntegrationsLookup,
  IntegrationConfigFor,
} from "@incident-shared/integrations";
import { getBrokenIntegrations } from "@incident-shared/integrations/getBrokenIntegrations";
import { OrgAwareLink, useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
} from "@incident-ui";
import { LocalRelativeDateTime } from "@incident-ui/LocalDateTime/LocalRelativeDateTime";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { AlertSourceTypeIconBadge } from "../common/AlertSourceTypeConfigs";
import { AlertRouteCreateConnectConfirmationModal } from "./AlertRouteCreateConnectConfirmationModal";

export function AlertSourceItem({
  alertSourceConfig,
  alertSources,
  alertRoutes,
}: {
  alertSourceConfig: AlertSourceConfig;
  alertSources: AlertSourceConfig[];
  alertRoutes: AlertRoute[];
}): React.ReactElement {
  const showToast = useToast();
  const { integrations } = useIntegrations();
  const { hasScope } = useIdentity();

  const [connectAlertSourceModalOpen, setConnectAlertSourceModalOpen] =
    useState(false);

  const {
    data: alertSourcesResponse,
    isLoading: alertSourcesLoading,
    error: alertSourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });

  const { trigger: onDelete } = useAPIMutation(
    "alertsListSourceConfigs",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.alertsDestroySourceConfig({ id: id });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: `${alertSourceConfig.name} deleted.`,
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not delete ${alertSourceConfig.name}`,
        });
      },
    },
  );

  const allSources = alertSourcesResponse.alert_sources;
  const alertSource = allSources.find(
    (s) => s.source_type === alertSourceConfig?.source_type,
  );

  // Pick maximum 3 alert routes, ordered by the ones that were most recently active
  const routesToGroupWith = alertRoutes
    .filter((route) => {
      return route.alert_sources.length > 0;
    })
    .sort((a, b) => {
      const aMostRecent =
        _.max([
          a.most_recent_resources_created?.escalation?.created_at,
          a.most_recent_resources_created?.incident?.created_at,
        ]) || new Date(0);

      const bMostRecent =
        _.max([
          b.most_recent_resources_created?.escalation?.created_at,
          b.most_recent_resources_created?.incident?.created_at,
        ]) || new Date(0);

      return bMostRecent.getTime() - aMostRecent.getTime();
    })
    .slice(0, 3);

  if (alertSourcesLoading) {
    return (
      <div
        className={tcx(
          "flex-center-y p-4 space-x-3 bg-surface-primary border border-stroke-primary rounded-xl shadow-sm",
        )}
      >
        <Loader />
      </div>
    );
  }

  if (!alertSource) {
    return <></>;
  }

  if (alertSourcesError) {
    return <GenericErrorMessage error={alertSourcesError} />;
  }

  const isSetup =
    alertSourceConfig.state !== AlertSourceConfigStateEnum.PendingSetup;

  const brokenIntegrations = getBrokenIntegrations(
    alertSource?.required_integrations ?? [],
    getBrokenIntegrationsLookup(integrations || []),
  ).map((x) =>
    IntegrationConfigFor(x as unknown as IntegrationSettingsProviderEnum),
  );
  const alertSourceForBrokenIntegration = brokenIntegrations.length > 0;
  const canEdit = !alertSourceForBrokenIntegration;
  const openConnectToRoute = () => setConnectAlertSourceModalOpen(true);

  return (
    <>
      <div
        className={tcx(
          "flex flex-row flex-center-y space-between p-4 bg-surface-primary border border-stroke-primary rounded-xl shadow-sm",
          {
            "cursor-pointer": canEdit,
          },
        )}
      >
        <OrgAwareLink
          className={"flex flex-row gap-3 items-center grow"}
          to={`/alerts/sources/${alertSourceConfig.id}/edit${
            !isSetup ? "?step=connect" : ""
          }`}
        >
          <div className="flex-center w-[40px] h-[40px] rounded-2">
            <AlertSourceTypeIconBadge
              sourceType={
                alertSourceConfig.source_type as unknown as AlertSourceSourceTypeEnum
              }
              className="flex-center w-[40px] h-[40px] rounded-lg"
            />
          </div>
          <div className="flex flex-col grow min-w-0">
            <div className="flex break-words">
              <div className="text-sm-bold truncate">
                {alertSourceConfig.name}
              </div>
            </div>
            {alertSourceConfig.alert_last_fired_at ? (
              <div className="text-xs-med text-content-tertiary truncate">
                Received an alert{" "}
                <LocalRelativeDateTime
                  date={alertSourceConfig.alert_last_fired_at}
                  className={
                    "hover:!no-underline text-xs-med text-content-tertiary"
                  }
                />
              </div>
            ) : (
              <div className="text-xs text-content-tertiary truncate">
                No alerts received
              </div>
            )}
          </div>
        </OrgAwareLink>
        <div className={"flex flex-row gap-3 items-center"}>
          {alertRoutes.length > 0 &&
            alertSourceConfig.alert_route_ids.length === 0 && (
              <div className={"hidden 2xl:flex"}>
                <GatedButton
                  disabledTooltipContent={
                    "You don't have permission to edit alert routes so you cannot connect an alert source"
                  }
                  disabled={!hasScope(ScopeNameEnum.AlertRouteUpdate)}
                  analyticsTrackingId="alert-sources.connect-to-route"
                  onClick={() => {
                    setConnectAlertSourceModalOpen(true);
                  }}
                  size={BadgeSize.Medium}
                >
                  Connect to route
                </GatedButton>
              </div>
            )}
          {brokenIntegrations && brokenIntegrations.length > 0 && (
            <Tooltip
              content="We're having trouble using this alert source. Please check your integration settings."
              bubbleProps={{ className: "w-full font-normal" }}
            >
              <div className="text-alarmalade-600">
                <Icon id={IconEnum.Warning} />
              </div>
            </Tooltip>
          )}
          <AlertSourceOverflowMenu
            alertSource={alertSource}
            alertSourceConfig={alertSourceConfig}
            onDelete={() => onDelete({ id: alertSourceConfig.id })}
            hasBrokenIntegration={alertSourceForBrokenIntegration}
            openConnectToRoute={openConnectToRoute}
          />
        </div>
      </div>
      {connectAlertSourceModalOpen && (
        <AlertRouteCreateConnectConfirmationModal
          onClose={() => setConnectAlertSourceModalOpen(false)}
          fromAlertSource={alertSourceConfig}
          alertRoutes={routesToGroupWith}
          alertSources={alertSources}
        />
      )}
    </>
  );
}

const AlertSourceOverflowMenu = ({
  alertSource,
  alertSourceConfig,
  onDelete,
  hasBrokenIntegration,
  openConnectToRoute,
}: {
  alertSource?: AlertSource;
  alertSourceConfig: AlertSourceConfig;
  onDelete: () => void;
  hasBrokenIntegration?: boolean;
  openConnectToRoute?: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  const canEdit = hasScope(ScopeNameEnum.AlertSourceUpdate);
  const hasDeletionPermissions = hasScope(ScopeNameEnum.AlertSourceDestroy);
  const isDeletableSource =
    alertSource?.classification !== AlertSourceClassificationEnum.Native;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useOrgAwareNavigate();
  const fetchDependentResources = useMemo(
    () => [
      {
        resource_type: "AlertSourceConfig",
        id: alertSourceConfig.id,
      },
    ],
    [alertSourceConfig.id],
  );

  return (
    <>
      <DeletionConfirmationModal
        title="Delete alert source"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        fetchDependentResources={fetchDependentResources}
        onDelete={onDelete}
        analyticsTrackingId="delete-settings-item"
        resourceTitle={alertSourceConfig.name}
        deleteConfirmationContent={
          <>
            Are you sure you want to delete{" "}
            <span className="font-bold">{alertSourceConfig.name}</span>?
          </>
        }
      />
      <DropdownMenu
        menuClassName={"w-[140px]"}
        side={"bottom"}
        align={"end"}
        scroll={false}
        triggerButton={
          <Button
            theme={ButtonTheme.Naked}
            type="button"
            className="-ml-2"
            analyticsTrackingId="alert-source-config-options"
            icon={IconEnum.DotsVerticalNopad}
            iconProps={{ size: IconSize.Large, className: "-my-2" }}
            title="Alert source config options"
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        {!hasBrokenIntegration && (
          <DropdownMenuItem
            disabled={!canEdit}
            tooltipContent={
              canEdit
                ? null
                : "You don't have permission to edit this alert source."
            }
            onSelect={() =>
              navigate(`/alerts/sources/${alertSourceConfig.id}/edit`)
            }
            analyticsTrackingId={"alert-source-config-edit"}
            icon={IconEnum.Edit}
            label={"Edit"}
          >
            Edit
          </DropdownMenuItem>
        )}
        {openConnectToRoute && (
          <DropdownMenuItem
            className={"visible 2xl:hidden"}
            tooltipContent={
              hasScope(ScopeNameEnum.AlertRouteUpdate)
                ? null
                : "You don't have permission to edit alert routes so you cannot connect an alert source"
            }
            disabled={!hasScope(ScopeNameEnum.AlertRouteUpdate)}
            analyticsTrackingId="alert-sources.connect-to-route"
            onSelect={openConnectToRoute}
            icon={IconEnum.AlertRoute}
            label={"Connect to route"}
          >
            Connect
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          disabled={!(hasDeletionPermissions && isDeletableSource)}
          tooltipContent={
            hasDeletionPermissions
              ? !isDeletableSource
                ? "This alert source is part of incident.io and can't be deleted."
                : undefined
              : "You don't have permission to delete this source"
          }
          onSelect={() => setShowDeleteModal(true)}
          analyticsTrackingId={"alert-source-config-delete"}
          label="Delete"
          icon={IconEnum.Delete}
          destructive
        >
          Delete
        </DropdownMenuItem>
      </DropdownMenu>
    </>
  );
};
