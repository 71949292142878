/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentsUpdateSummaryRequestBody
 */
export interface IncidentsUpdateSummaryRequestBody {
    /**
     * If true, notify the Slack channel of this update
     * @type {boolean}
     * @memberof IncidentsUpdateSummaryRequestBody
     */
    notify_slack_channel: boolean;
    /**
     * The source of the summary i.e. human / AI
     * @type {string}
     * @memberof IncidentsUpdateSummaryRequestBody
     */
    source: IncidentsUpdateSummaryRequestBodySourceEnum;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentsUpdateSummaryRequestBody
     */
    summary?: TextDocumentPayload;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsUpdateSummaryRequestBodySourceEnum {
    Human = 'human',
    GeneratedAccepted = 'generated_accepted',
    GeneratedEdited = 'generated_edited',
    GeneratedCopilot = 'generated_copilot'
}

export function IncidentsUpdateSummaryRequestBodyFromJSON(json: any): IncidentsUpdateSummaryRequestBody {
    return IncidentsUpdateSummaryRequestBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateSummaryRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateSummaryRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notify_slack_channel': json['notify_slack_channel'],
        'source': json['source'],
        'summary': !exists(json, 'summary') ? undefined : TextDocumentPayloadFromJSON(json['summary']),
    };
}

export function IncidentsUpdateSummaryRequestBodyToJSON(value?: IncidentsUpdateSummaryRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notify_slack_channel': value.notify_slack_channel,
        'source': value.source,
        'summary': TextDocumentPayloadToJSON(value.summary),
    };
}

