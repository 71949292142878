import type { SVGProps } from "react";
import * as React from "react";
const SvgMaximize = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M15.25 4h-4.5a.75.75 0 0 0 0 1.5h3.75v3.75a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-.75-.75Zm-6 10.5H5.5v-3.75a.75.75 0 0 0-1.5 0v4.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5Z" />
  </svg>
);
export default SvgMaximize;
