import { formatDurationInSeconds } from "src/utils/datetime";

import { TimelineSpacer } from "./TimelineSpacer";

type TimelineGapProps = {
  // Duration is the number of seconds
  // between the previous and next item
  duration: number;
};

export const TimelineGap = ({ duration }: TimelineGapProps) => {
  const durationString = formatDurationInSeconds(duration, 1);

  return (
    <div className="-mt-[6px]">
      <TimelineSpacer expanded>
        <div className="text-sm text-content-tertiary">
          {durationString} later...
        </div>
      </TimelineSpacer>
    </div>
  );
};
