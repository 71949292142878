/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfluenceConfig,
    ConfluenceConfigFromJSON,
    ConfluenceConfigFromJSONTyped,
    ConfluenceConfigToJSON,
} from './ConfluenceConfig';

/**
 * 
 * @export
 * @interface IntegrationsConfluenceGetConfigResponseBody
 */
export interface IntegrationsConfluenceGetConfigResponseBody {
    /**
     * 
     * @type {ConfluenceConfig}
     * @memberof IntegrationsConfluenceGetConfigResponseBody
     */
    config: ConfluenceConfig;
}

export function IntegrationsConfluenceGetConfigResponseBodyFromJSON(json: any): IntegrationsConfluenceGetConfigResponseBody {
    return IntegrationsConfluenceGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsConfluenceGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsConfluenceGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': ConfluenceConfigFromJSON(json['config']),
    };
}

export function IntegrationsConfluenceGetConfigResponseBodyToJSON(value?: IntegrationsConfluenceGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': ConfluenceConfigToJSON(value.config),
    };
}

