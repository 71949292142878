/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    IncidentTimestamp,
    IncidentTimestampFromJSON,
    IncidentTimestampFromJSONTyped,
    IncidentTimestampToJSON,
} from './IncidentTimestamp';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * Determines the applicability of a policy to an incident based on its timestamp.
     * @type {Date}
     * @memberof Policy
     */
    applies_from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    created_at: Date;
    /**
     * Human readable description of the policy
     * @type {string}
     * @memberof Policy
     */
    description: string;
    /**
     * The expressions relating to this policy
     * @type {Array<Expression>}
     * @memberof Policy
     */
    expressions?: Array<Expression>;
    /**
     * Unique ID of the policy
     * @type {string}
     * @memberof Policy
     */
    id: string;
    /**
     * Condition groups that must apply to the incident for it to be bound by this policy
     * @type {Array<ConditionGroup>}
     * @memberof Policy
     */
    incident_condition_groups: Array<ConditionGroup>;
    /**
     * Human readable name of the policy
     * @type {string}
     * @memberof Policy
     */
    name: string;
    /**
     * Type of the policy, specifying what this applies to
     * @type {string}
     * @memberof Policy
     */
    policy_type: PolicyPolicyTypeEnum;
    /**
     * Who is responsible for this policy?
     * @type {Array<Reference>}
     * @memberof Policy
     */
    responsible_user_references?: Array<Reference>;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof Policy
     */
    sla_days: EngineParamBinding;
    /**
     * How to calculate how many days have passed
     * @type {string}
     * @memberof Policy
     */
    sla_days_calculation_type: PolicySlaDaysCalculationTypeEnum;
    /**
     * 
     * @type {IncidentTimestamp}
     * @memberof Policy
     */
    sla_incident_timestamp: IncidentTimestamp;
    /**
     * Condition groups that apply to the resources specific for this policy type
     * @type {Array<ConditionGroup>}
     * @memberof Policy
     */
    type_condition_groups: Array<ConditionGroup>;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    updated_at: Date;
    /**
     * Number of days before a policy violation occurs to notify the responsible user
     * @type {number}
     * @memberof Policy
     */
    warning_days?: number;
}

/**
* @export
* @enum {string}
*/
export enum PolicyPolicyTypeEnum {
    Debrief = 'debrief',
    FollowUp = 'follow_up',
    PostMortem = 'post_mortem'
}/**
* @export
* @enum {string}
*/
export enum PolicySlaDaysCalculationTypeEnum {
    SevenDays = 'seven_days',
    Weekdays = 'weekdays'
}

export function PolicyFromJSON(json: any): Policy {
    return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applies_from': !exists(json, 'applies_from') ? undefined : (new Date(json['applies_from'])),
        'created_at': (new Date(json['created_at'])),
        'description': json['description'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'id': json['id'],
        'incident_condition_groups': ((json['incident_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'name': json['name'],
        'policy_type': json['policy_type'],
        'responsible_user_references': !exists(json, 'responsible_user_references') ? undefined : ((json['responsible_user_references'] as Array<any>).map(ReferenceFromJSON)),
        'sla_days': EngineParamBindingFromJSON(json['sla_days']),
        'sla_days_calculation_type': json['sla_days_calculation_type'],
        'sla_incident_timestamp': IncidentTimestampFromJSON(json['sla_incident_timestamp']),
        'type_condition_groups': ((json['type_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
        'warning_days': !exists(json, 'warning_days') ? undefined : json['warning_days'],
    };
}

export function PolicyToJSON(value?: Policy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applies_from': value.applies_from === undefined ? undefined : (value.applies_from.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'id': value.id,
        'incident_condition_groups': ((value.incident_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'name': value.name,
        'policy_type': value.policy_type,
        'responsible_user_references': value.responsible_user_references === undefined ? undefined : ((value.responsible_user_references as Array<any>).map(ReferenceToJSON)),
        'sla_days': EngineParamBindingToJSON(value.sla_days),
        'sla_days_calculation_type': value.sla_days_calculation_type,
        'sla_incident_timestamp': IncidentTimestampToJSON(value.sla_incident_timestamp),
        'type_condition_groups': ((value.type_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'updated_at': (value.updated_at.toISOString()),
        'warning_days': value.warning_days,
    };
}

