import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import { Tooltip } from "@incident-ui";
import _ from "lodash";
import React from "react";
import { EscalationStatusEnum } from "src/contexts/ClientContext";

import { ActiveCircle } from "./ActiveCircle";
import { ESCALATION_LABEL } from "./helpers";

export const EscalationStatusIcon = ({
  status,
  showTooltip,
}: {
  status: EscalationStatusEnum;
  showTooltip?: boolean;
}) => {
  const statusIcons: Record<EscalationStatusEnum, React.ReactElement> = {
    [EscalationStatusEnum.Pending]: (
      <ActiveCircle
        isAnimated={false}
        className="!w-[8px] !h-[8px] !bg-slate-500"
      />
    ),
    [EscalationStatusEnum.Triggered]: (
      <AlertFiringIndicator firing={true} className="ml-1" />
    ),
    [EscalationStatusEnum.Acked]: (
      <ActiveCircle
        isAnimated={false}
        className="!w-[8px] !h-[8px] !bg-green-500"
      />
    ),
    [EscalationStatusEnum.Resolved]: (
      <ActiveCircle
        isAnimated={false}
        className="!w-[8px] !h-[8px] !bg-green-500"
      />
    ),
    [EscalationStatusEnum.Expired]: (
      <ActiveCircle
        isAnimated={false}
        className="!w-[8px] !h-[8px] !bg-slate-400"
      />
    ),
    [EscalationStatusEnum.Cancelled]: (
      <ActiveCircle
        isAnimated={false}
        className="!w-[8px] !h-[8px] !bg-slate-400"
      />
    ),
  };

  return (
    <Tooltip
      content={<span>{_.startCase(ESCALATION_LABEL[status])}</span>}
      side="top"
      bubbleProps={{ arrowClassName: "!visible !fill-slate-900" }}
      delayDuration={0}
      disabled={!showTooltip}
    >
      <span>{statusIcons[status]}</span>
    </Tooltip>
  );
};
