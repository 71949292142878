import { IconEnum } from "@incident-ui";
import { ExternalResource } from "src/contexts/ClientContext";

import { AttachmentRenderProps } from "./IncidentAttachment";

export const DatadogMonitorAlert = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.datadog_monitor_alert) {
    throw new Error("Datadog monitor alert resource without expected content");
  }

  return {
    icon: IconEnum.Datadog,
    title: resource.datadog_monitor_alert.title,
    safeBadges: [
      {
        icon: IconEnum.CustomField,
        text: resource.datadog_monitor_alert.tags.join(", "),
      },
    ],
    unsafeBadges: [
      {
        icon: IconEnum.Severity,
        text: resource.datadog_monitor_alert.alert_priority,
      },
    ],
  };
};
