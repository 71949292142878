import {
  CatalogType,
  StatusPageBuildSubPageStructureResponseBody,
  StatusPageStructure,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  BadgeSize,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  LoadingBar,
  Tooltip,
} from "@incident-ui";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { PreviewItem } from "../../common/ComponentsEditor/utils";
import { ParentFormType } from "../../create/PageSetupForm";
import { SubPageFormType } from "../edit/SubPageEditSettings";

type SubPageItem = {
  name: string;
  defined_by_catalog_entry_id: string;
}[];

type SubPageStructure =
  StatusPageBuildSubPageStructureResponseBody["sub_page_structures"];

const SubPageComponentsHeader = () => {
  return (
    <DeprecatedTableHeaderRow className="font-medium">
      <th className="font-normal w-[230px] truncate">Sub-page name</th>
      <DeprecatedTableHeaderCell>Components</DeprecatedTableHeaderCell>
    </DeprecatedTableHeaderRow>
  );
};

export const SubPageViewComponentPreview = ({
  subPageItems,
  catalogTypes,
  splitByCatalogTypeId,
  splitByAttributeId,
  componentCatalogType,
}: {
  subPageItems: SubPageItem;
  catalogTypes: CatalogType[];
  splitByCatalogTypeId: string;
  splitByAttributeId: string;
  componentCatalogType: CatalogType | undefined;
}): React.ReactElement => {
  const { data: structureLayoutData, isLoading: structureLayoutLoading } =
    useAPI("statusPageBuildSubPageStructure", {
      parentSplitByCatalogTypeId: splitByCatalogTypeId,
      parentSplitByComponentAttributeId: splitByAttributeId,
      definedByCatalogEntryIds: subPageItems.map(
        (subpage) => subpage.defined_by_catalog_entry_id,
      ),
    });

  const placeHolderCatalogType = catalogTypes?.find(
    (type) => type.id === splitByCatalogTypeId,
  );

  if (structureLayoutLoading) {
    return (
      <DeprecatedTable className="overflow-x-auto shadow-none">
        <SubPageComponentsHeader />
        <tbody>
          {subPageItems.map((subpage) => {
            return (
              <tr key={subpage?.name}>
                <td>
                  <CatalogEntryBadge
                    color={placeHolderCatalogType?.color}
                    icon={placeHolderCatalogType?.icon}
                    label={subpage.name}
                    size={BadgeSize.Small}
                  />
                </td>
                <td className="w-full inline-flex items-center flex-wrap gap-2">
                  <LoadingBar className="h-6 rounded-2" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </DeprecatedTable>
    );
  }

  return (
    <DeprecatedTable className="overflow-x-auto shadow-none">
      <SubPageComponentsHeader />
      <SubPageComponentsPreviewBody
        structureDataLoading={false}
        subPageItems={subPageItems}
        subPageStructure={structureLayoutData?.sub_page_structures}
        catalogTypes={catalogTypes || []}
        splitByCatalogTypeId={splitByCatalogTypeId}
        componentCatalogType={componentCatalogType}
      />
    </DeprecatedTable>
  );
};

// used when we want to preview the sub-page components
// inside of a form context
export const SubPageComponentsFormPreview = (): React.ReactElement | null => {
  const formMethods = useFormContext<SubPageFormType>();
  const parentFormMethods = useFormContext<ParentFormType>();

  const {
    data: { catalog_types: catalogTypes },
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const [subpages, splitByCatalogTypeId, splitByComponentAttributeId] =
    formMethods.watch([
      "sub_pages",
      "split_by_catalog_type_id",
      "split_by_component_attribute_id",
    ]);

  const [groupByAttributeId] = parentFormMethods.watch([
    "group_by_defined_by_catalog_attribute_id",
  ]);

  const { data: typeData } = useAPI("catalogShowType", {
    id: splitByCatalogTypeId,
  });

  const catalogType = typeData?.catalog_type;
  const attributes = catalogType?.schema.attributes;
  const allowedAttributes = attributes?.filter((attribute) => attribute.array);
  const selectedType = catalogTypes.find((type) => {
    const selectedAttribute = allowedAttributes?.find(
      (attr) => attr.id === splitByComponentAttributeId,
    );
    return (
      splitByComponentAttributeId &&
      selectedAttribute &&
      type.type_name === selectedAttribute.type
    );
  });

  const subpageItems = subpages
    ? _.compact(Object.values(subpages).filter((subpage) => !subpage?.disabled))
    : [];
  const catalogEntryIds = subpageItems.map(
    (subpage) => subpage.defined_by_catalog_entry_id,
  );

  const { data: structureData, isLoading: structureDataLoading } = useAPI(
    "statusPageBuildSubPageStructure",
    {
      parentSplitByCatalogTypeId: splitByCatalogTypeId,
      parentSplitByComponentAttributeId: splitByComponentAttributeId,
      parentSplitByComponentCatalogTypeId: splitByComponentAttributeId,
      parentGroupByCatalogId: groupByAttributeId || "",
      definedByCatalogEntryIds: catalogEntryIds,
    },
  );

  return subpages ? (
    <DeprecatedTable className="overflow-x-auto shadow-none">
      <SubPageComponentsHeader />
      <SubPageComponentsPreviewBody
        structureDataLoading={structureDataLoading}
        subPageItems={subpageItems}
        subPageStructure={structureData?.sub_page_structures}
        catalogTypes={catalogTypes || []}
        splitByCatalogTypeId={splitByCatalogTypeId}
        componentCatalogType={selectedType}
      />
    </DeprecatedTable>
  ) : null;
};

const SubPageComponentsPreviewBody = ({
  structureDataLoading,
  subPageItems,
  subPageStructure,
  splitByCatalogTypeId,
  catalogTypes,
  componentCatalogType,
}: {
  structureDataLoading: boolean;
  subPageItems: SubPageItem;
  subPageStructure: PreviewItem[] | SubPageStructure | undefined;
  catalogTypes: CatalogType[];
  splitByCatalogTypeId: string;
  componentCatalogType: CatalogType | undefined;
}) => {
  const splitByCatalogType = catalogTypes?.find(
    (type) => type.id === splitByCatalogTypeId,
  );

  if (!subPageStructure && !structureDataLoading) {
    return (
      <tbody>
        {subPageItems.map((subpage) => (
          <tr key={subpage?.name}>
            <td>
              <CatalogEntryBadge
                color={splitByCatalogType?.color}
                icon={splitByCatalogType?.icon}
                label={subpage.name}
                size={BadgeSize.Small}
              />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  if (structureDataLoading) {
    return (
      <tbody>
        {subPageItems.map((subpage) => {
          return (
            <tr key={subpage?.name}>
              <td>
                <CatalogEntryBadge
                  color={splitByCatalogType?.color}
                  icon={splitByCatalogType?.icon}
                  label={subpage.name}
                  size={BadgeSize.Small}
                />
              </td>
              <td className="w-full inline-flex items-center flex-wrap gap-2">
                <LoadingBar className="h-6 rounded-2" />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  const subPageItemsWithStructure = subPageItems.map((subpage) => {
    return {
      ...subpage,
      structure: subPageStructure?.[subpage.defined_by_catalog_entry_id],
    };
  });

  return (
    <tbody>
      {subPageItemsWithStructure.map((subpage) => {
        return (
          <SubPageComponentsPreviewRow
            key={subpage.name}
            subPageItemWithStructure={subpage}
            catalogType={splitByCatalogType}
            componentCatalogType={componentCatalogType}
          />
        );
      })}
    </tbody>
  );
};

const SubPageComponentsPreviewRow = ({
  subPageItemWithStructure,
  catalogType,
  componentCatalogType,
}: {
  subPageItemWithStructure: {
    structure: StatusPageStructure | undefined;
    name: string;
    defined_by_catalog_entry_id: string;
  };
  catalogType: CatalogType | undefined;
  componentCatalogType: CatalogType | undefined;
}) => {
  const { name: subPageName, structure } = subPageItemWithStructure;
  return (
    <tr>
      <td>
        <CatalogEntryBadge
          color={catalogType?.color}
          icon={catalogType?.icon}
          label={subPageName}
          size={BadgeSize.Small}
        />
      </td>
      <td className="inline-flex items-center flex-wrap gap-2">
        {structure?.items.map(({ component, group }) => {
          if (component) {
            return (
              <CatalogEntryBadge
                className="max-w-[200px] truncate ..."
                key={component.component_id}
                color={componentCatalogType?.color}
                icon={componentCatalogType?.icon}
                label={component?.name}
                size={BadgeSize.Small}
              />
            );
          }
          if (group) {
            return (
              <div key={group.id}>
                <Tooltip
                  side={"right"}
                  content={
                    <div
                      className={"flex flex-col gap-y-1 text-content-primary"}
                    >
                      {group.components.map((component) => (
                        <CatalogEntryBadge
                          key={component.component_id}
                          color={componentCatalogType?.color}
                          icon={componentCatalogType?.icon}
                          label={component.name}
                          size={BadgeSize.Small}
                        />
                      ))}
                    </div>
                  }
                  bubbleProps={{
                    className: "!bg-white !border !border-stroke !shadow-none",
                  }}
                >
                  <div className="cursor-pointer truncate">
                    <CatalogEntryBadge
                      color={componentCatalogType?.color}
                      icon={componentCatalogType?.icon}
                      label={group.name}
                      groupIcon={true}
                      size={BadgeSize.Small}
                    />
                  </div>
                </Tooltip>
              </div>
            );
          }
          return undefined;
        })}
      </td>
    </tr>
  );
};
