import { PanelVariable, PanelVariableFormField } from "@incident-io/api";
import { getFilterFieldTypeaheadHelpers } from "@incident-shared/filters/FilterFormElement";
import { TypeaheadTypeEnum } from "@incident-shared/forms/Typeahead";
import {
  PopoverDynamicSingleSelectWithObjV2,
  PopoverMultiSelectWithObjV2,
  PopoverSingleSelectWithObjV2,
} from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import { Path, useFormContext } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { isDevelopment } from "src/utils/environment";

import { VariablesFormData } from "./useInsightsContext";

export const PanelVariableInputs = ({
  variables,
  editable,
  panelIdx,
}: {
  variables: PanelVariable[];
  editable: boolean;
  panelIdx: number;
}) => {
  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <div className="flex items-center gap-2 flex-wrap">
        {variables.map((variable) => (
          <div
            key={variable.form_field.key}
            className="flex items-center gap-1"
          >
            <div className="text-content-secondary text-sm-med shrink-0">
              {variable.form_field.label}
            </div>
            {editable ? (
              <VariableInput
                formField={variable.form_field}
                panelIdx={panelIdx}
              />
            ) : (
              <Badge size={BadgeSize.Medium} theme={BadgeTheme.Tertiary}>
                {variable.value?.label || "Choose..."}
              </Badge>
            )}
          </div>
        ))}
      </div>
    </ErrorBoundary>
  );
};

export const VariableInput = ({
  formField,
  panelIdx,
}: {
  formField: PanelVariableFormField;
  panelIdx: number;
}): React.ReactElement | null => {
  const formMethods = useFormContext<VariablesFormData>();

  const apiClient = useClient();
  const name =
    `panels.${panelIdx}.variables_data.${formField.key}` as Path<VariablesFormData>;

  if (
    formField.select_config.options ||
    formField.select_config.option_groups
  ) {
    if (formField.explo_mapping?.multi_select) {
      return (
        <PopoverMultiSelectWithObjV2
          formMethods={formMethods}
          renderTriggerNode={({ selectedOptions, onClick }) => {
            const labels = selectedOptions
              ?.map((option) => option.label)
              .join(", ");
            return (
              <Button
                size={BadgeSize.Medium}
                analyticsTrackingId={null}
                theme={ButtonTheme.Tertiary}
                onClick={onClick}
              >
                {labels || "Choose..."}
                <Icon size={IconSize.Small} id={IconEnum.ChevronDown} />
              </Button>
            );
          }}
          name={name}
          options={[
            ...(formField.select_config.options || []),
            ...(formField.select_config.option_groups || []),
          ]}
        />
      );
    } else {
      return (
        <PopoverSingleSelectWithObjV2
          formMethods={formMethods}
          renderTriggerNode={({ selectedOption, onClick }) => (
            <Button
              size={BadgeSize.Medium}
              analyticsTrackingId={null}
              theme={ButtonTheme.Tertiary}
              onClick={onClick}
            >
              {selectedOption?.label || "Choose..."}
              <Icon size={IconSize.Small} id={IconEnum.ChevronDown} />
            </Button>
          )}
          name={name}
          options={[
            ...(formField.select_config.options || []),
            ...(formField.select_config.option_groups || []),
          ]}
        />
      );
    }
  } else if (formField.select_config?.typeahead_type) {
    const { loadOptions } = getFilterFieldTypeaheadHelpers({
      apiClient,
      typeaheadType: formField.select_config
        .typeahead_type as unknown as TypeaheadTypeEnum,
      forInsights: true,
    });
    return (
      <PopoverDynamicSingleSelectWithObjV2
        formMethods={formMethods}
        renderTriggerNode={({ selectedOption, onClick }) => (
          <Button
            size={BadgeSize.Medium}
            analyticsTrackingId={null}
            theme={ButtonTheme.Tertiary}
            onClick={onClick}
            className="items-center"
          >
            {selectedOption?.image_url && (
              <Avatar size={IconSize.Small} url={selectedOption.image_url} />
            )}
            {selectedOption?.label || "Choose..."}
            <Icon size={IconSize.Small} id={IconEnum.ChevronDown} />
          </Button>
        )}
        name={name}
        loadOptions={loadOptions}
      />
    );
  } else {
    if (isDevelopment()) {
      return (
        <Callout theme={CalloutTheme.Danger}>
          This form field type is not supported in Insights yet!
        </Callout>
      );
    }
    return null;
  }
};
