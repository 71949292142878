/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentTimestampsCreateDurationMetricRequestBody
 */
export interface IncidentTimestampsCreateDurationMetricRequestBody {
    /**
     * How this metric should be calculated
     * @type {string}
     * @memberof IncidentTimestampsCreateDurationMetricRequestBody
     */
    calculation_mode: IncidentTimestampsCreateDurationMetricRequestBodyCalculationModeEnum;
    /**
     * The id of the incident timestamp where this metric starts.
     * @type {string}
     * @memberof IncidentTimestampsCreateDurationMetricRequestBody
     */
    from_timestamp_id: string;
    /**
     * Unique name of this duration metric
     * @type {string}
     * @memberof IncidentTimestampsCreateDurationMetricRequestBody
     */
    name: string;
    /**
     * The id of the incident timestamp where this metric starts.
     * @type {string}
     * @memberof IncidentTimestampsCreateDurationMetricRequestBody
     */
    to_timestamp_id: string;
    /**
     * Whether this metrics timestamps should be validated
     * @type {boolean}
     * @memberof IncidentTimestampsCreateDurationMetricRequestBody
     */
    validate: boolean;
}

/**
* @export
* @enum {string}
*/
export enum IncidentTimestampsCreateDurationMetricRequestBodyCalculationModeEnum {
    ExcludePaused = 'exclude_paused',
    IncludePaused = 'include_paused'
}

export function IncidentTimestampsCreateDurationMetricRequestBodyFromJSON(json: any): IncidentTimestampsCreateDurationMetricRequestBody {
    return IncidentTimestampsCreateDurationMetricRequestBodyFromJSONTyped(json, false);
}

export function IncidentTimestampsCreateDurationMetricRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampsCreateDurationMetricRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calculation_mode': json['calculation_mode'],
        'from_timestamp_id': json['from_timestamp_id'],
        'name': json['name'],
        'to_timestamp_id': json['to_timestamp_id'],
        'validate': json['validate'],
    };
}

export function IncidentTimestampsCreateDurationMetricRequestBodyToJSON(value?: IncidentTimestampsCreateDurationMetricRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calculation_mode': value.calculation_mode,
        'from_timestamp_id': value.from_timestamp_id,
        'name': value.name,
        'to_timestamp_id': value.to_timestamp_id,
        'validate': value.validate,
    };
}

