/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    AtlassianStatuspageIncident,
    AtlassianStatuspageIncidentFromJSON,
    AtlassianStatuspageIncidentFromJSONTyped,
    AtlassianStatuspageIncidentToJSON,
} from './AtlassianStatuspageIncident';

/**
 * 
 * @export
 * @interface AtlassianStatuspageUpdate
 */
export interface AtlassianStatuspageUpdate {
    /**
     * If this was a creation event, whether it was linked from an existing Statuspage
     * @type {boolean}
     * @memberof AtlassianStatuspageUpdate
     */
    is_link_from_existing: boolean;
    /**
     * 
     * @type {AtlassianStatuspageIncident}
     * @memberof AtlassianStatuspageUpdate
     */
    previous_statuspage?: AtlassianStatuspageIncident;
    /**
     * 
     * @type {AtlassianStatuspageIncident}
     * @memberof AtlassianStatuspageUpdate
     */
    statuspage?: AtlassianStatuspageIncident;
    /**
     * 
     * @type {Actor}
     * @memberof AtlassianStatuspageUpdate
     */
    updater: Actor;
}

export function AtlassianStatuspageUpdateFromJSON(json: any): AtlassianStatuspageUpdate {
    return AtlassianStatuspageUpdateFromJSONTyped(json, false);
}

export function AtlassianStatuspageUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianStatuspageUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_link_from_existing': json['is_link_from_existing'],
        'previous_statuspage': !exists(json, 'previous_statuspage') ? undefined : AtlassianStatuspageIncidentFromJSON(json['previous_statuspage']),
        'statuspage': !exists(json, 'statuspage') ? undefined : AtlassianStatuspageIncidentFromJSON(json['statuspage']),
        'updater': ActorFromJSON(json['updater']),
    };
}

export function AtlassianStatuspageUpdateToJSON(value?: AtlassianStatuspageUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_link_from_existing': value.is_link_from_existing,
        'previous_statuspage': AtlassianStatuspageIncidentToJSON(value.previous_statuspage),
        'statuspage': AtlassianStatuspageIncidentToJSON(value.statuspage),
        'updater': ActorToJSON(value.updater),
    };
}

