/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillingCreateCheckoutSessionRequestBody,
    BillingCreateCheckoutSessionRequestBodyFromJSON,
    BillingCreateCheckoutSessionRequestBodyToJSON,
    BillingCreateCheckoutSessionResponseBody,
    BillingCreateCheckoutSessionResponseBodyFromJSON,
    BillingCreateCheckoutSessionResponseBodyToJSON,
    BillingCreateSequenceCheckoutSessionRequestBody,
    BillingCreateSequenceCheckoutSessionRequestBodyFromJSON,
    BillingCreateSequenceCheckoutSessionRequestBodyToJSON,
    BillingCreateSequenceCheckoutSessionResponseBody,
    BillingCreateSequenceCheckoutSessionResponseBodyFromJSON,
    BillingCreateSequenceCheckoutSessionResponseBodyToJSON,
    BillingListPlansResponseBody,
    BillingListPlansResponseBodyFromJSON,
    BillingListPlansResponseBodyToJSON,
    BillingListSeatCountsResponseBody,
    BillingListSeatCountsResponseBodyFromJSON,
    BillingListSeatCountsResponseBodyToJSON,
    BillingRequiresBillingScopeToPromoteOnCallRespondersRequestBody,
    BillingRequiresBillingScopeToPromoteOnCallRespondersRequestBodyFromJSON,
    BillingRequiresBillingScopeToPromoteOnCallRespondersRequestBodyToJSON,
    BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody,
    BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBodyFromJSON,
    BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBodyToJSON,
    BillingShowBillingSettingsResponseBody,
    BillingShowBillingSettingsResponseBodyFromJSON,
    BillingShowBillingSettingsResponseBodyToJSON,
} from '../models';

export interface BillingCreateCheckoutSessionRequest {
    createCheckoutSessionRequestBody: BillingCreateCheckoutSessionRequestBody;
}

export interface BillingCreateSequenceCheckoutSessionRequest {
    createSequenceCheckoutSessionRequestBody: BillingCreateSequenceCheckoutSessionRequestBody;
}

export interface BillingRequiresBillingScopeToPromoteOnCallRespondersRequest {
    requiresBillingScopeToPromoteOnCallRespondersRequestBody: BillingRequiresBillingScopeToPromoteOnCallRespondersRequestBody;
}

export interface BillingStripeCustomerPortalRequest {
    organisationId: string;
    forSequence?: boolean;
}

/**
 * 
 */
export class BillingApi extends runtime.BaseAPI {

    /**
     * Create a Stripe checkout session
     * CreateCheckoutSession Billing
     */
    async billingCreateCheckoutSessionRaw(requestParameters: BillingCreateCheckoutSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingCreateCheckoutSessionResponseBody>> {
        if (requestParameters.createCheckoutSessionRequestBody === null || requestParameters.createCheckoutSessionRequestBody === undefined) {
            throw new runtime.RequiredError('createCheckoutSessionRequestBody','Required parameter requestParameters.createCheckoutSessionRequestBody was null or undefined when calling billingCreateCheckoutSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/billing/checkout_session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillingCreateCheckoutSessionRequestBodyToJSON(requestParameters.createCheckoutSessionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingCreateCheckoutSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Stripe checkout session
     * CreateCheckoutSession Billing
     */
    async billingCreateCheckoutSession(requestParameters: BillingCreateCheckoutSessionRequest, initOverrides?: RequestInit): Promise<BillingCreateCheckoutSessionResponseBody> {
        const response = await this.billingCreateCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Stripe checkout session for use with Sequence
     * CreateSequenceCheckoutSession Billing
     */
    async billingCreateSequenceCheckoutSessionRaw(requestParameters: BillingCreateSequenceCheckoutSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingCreateSequenceCheckoutSessionResponseBody>> {
        if (requestParameters.createSequenceCheckoutSessionRequestBody === null || requestParameters.createSequenceCheckoutSessionRequestBody === undefined) {
            throw new runtime.RequiredError('createSequenceCheckoutSessionRequestBody','Required parameter requestParameters.createSequenceCheckoutSessionRequestBody was null or undefined when calling billingCreateSequenceCheckoutSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/billing/sequence_checkout_session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillingCreateSequenceCheckoutSessionRequestBodyToJSON(requestParameters.createSequenceCheckoutSessionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingCreateSequenceCheckoutSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Stripe checkout session for use with Sequence
     * CreateSequenceCheckoutSession Billing
     */
    async billingCreateSequenceCheckoutSession(requestParameters: BillingCreateSequenceCheckoutSessionRequest, initOverrides?: RequestInit): Promise<BillingCreateSequenceCheckoutSessionResponseBody> {
        const response = await this.billingCreateSequenceCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of the plans we support
     * ListPlans Billing
     */
    async billingListPlansRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingListPlansResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/billing/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingListPlansResponseBodyFromJSON(jsonValue));
    }

    /**
     * Returns a list of the plans we support
     * ListPlans Billing
     */
    async billingListPlans(initOverrides?: RequestInit): Promise<BillingListPlansResponseBody> {
        const response = await this.billingListPlansRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of the seat counts in use
     * ListSeatCounts Billing
     */
    async billingListSeatCountsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingListSeatCountsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/billing/seat_counts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingListSeatCountsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Returns a list of the seat counts in use
     * ListSeatCounts Billing
     */
    async billingListSeatCounts(initOverrides?: RequestInit): Promise<BillingListSeatCountsResponseBody> {
        const response = await this.billingListSeatCountsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check if the user has the required billing scope to promote on-call responders
     * RequiresBillingScopeToPromoteOnCallResponders Billing
     */
    async billingRequiresBillingScopeToPromoteOnCallRespondersRaw(requestParameters: BillingRequiresBillingScopeToPromoteOnCallRespondersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody>> {
        if (requestParameters.requiresBillingScopeToPromoteOnCallRespondersRequestBody === null || requestParameters.requiresBillingScopeToPromoteOnCallRespondersRequestBody === undefined) {
            throw new runtime.RequiredError('requiresBillingScopeToPromoteOnCallRespondersRequestBody','Required parameter requestParameters.requiresBillingScopeToPromoteOnCallRespondersRequestBody was null or undefined when calling billingRequiresBillingScopeToPromoteOnCallResponders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/billing/requires_billing_scope_to_promote_on_call_responders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillingRequiresBillingScopeToPromoteOnCallRespondersRequestBodyToJSON(requestParameters.requiresBillingScopeToPromoteOnCallRespondersRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBodyFromJSON(jsonValue));
    }

    /**
     * Check if the user has the required billing scope to promote on-call responders
     * RequiresBillingScopeToPromoteOnCallResponders Billing
     */
    async billingRequiresBillingScopeToPromoteOnCallResponders(requestParameters: BillingRequiresBillingScopeToPromoteOnCallRespondersRequest, initOverrides?: RequestInit): Promise<BillingRequiresBillingScopeToPromoteOnCallRespondersResponseBody> {
        const response = await this.billingRequiresBillingScopeToPromoteOnCallRespondersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return stripe billing information for this customer
     * ShowBillingSettings Billing
     */
    async billingShowBillingSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingShowBillingSettingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/billing/billing_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingShowBillingSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Return stripe billing information for this customer
     * ShowBillingSettings Billing
     */
    async billingShowBillingSettings(initOverrides?: RequestInit): Promise<BillingShowBillingSettingsResponseBody> {
        const response = await this.billingShowBillingSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Go to the stripe customer portal for this organisation
     * StripeCustomerPortal Billing
     */
    async billingStripeCustomerPortalRaw(requestParameters: BillingStripeCustomerPortalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling billingStripeCustomerPortal.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        if (requestParameters.forSequence !== undefined) {
            queryParameters['for_sequence'] = requestParameters.forSequence;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/billing/stripe_customer_portal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Go to the stripe customer portal for this organisation
     * StripeCustomerPortal Billing
     */
    async billingStripeCustomerPortal(requestParameters: BillingStripeCustomerPortalRequest, initOverrides?: RequestInit): Promise<void> {
        await this.billingStripeCustomerPortalRaw(requestParameters, initOverrides);
    }

}
