import { IssueTrackerProviderEnabled } from "@incident-shared/issue-trackers";
import { DeprecatedTable } from "@incident-ui";
import { sortBy } from "lodash";
import {
  FollowUp,
  FollowUpStatusEnum,
  Incident,
  IncidentVisibilityEnum,
  Policy,
  PolicyViolation,
  SlackTeamConfig,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import styles from "../FollowUps.module.scss";
import { FollowUpRows } from "./FollowUpRows";
import { FollowUpsTableHeader } from "./FollowUpsTableHeader";

const orderedFollowUpStatuses = [
  FollowUpStatusEnum.Outstanding,
  FollowUpStatusEnum.Completed,
  FollowUpStatusEnum.NotDoing,
  FollowUpStatusEnum.Deleted,
];

export const sortFollowUps = (followUps: FollowUp[]): FollowUp[] => {
  return sortBy(followUps, (followUp) =>
    orderedFollowUpStatuses.findIndex((x) => x === followUp.status),
  );
};

export const FollowUpsTable = ({
  incident,
  slackTeam,
  followUps = incident.follow_ups ?? [],
  policyViolations,
  policies,
  selectedFollowUpIDs,
  setSelectedFollowUpIDs,
  installedIssueTrackers,
  onHomepage = false,
  refetchIncidents,
}: {
  incident: Incident;
  slackTeam: SlackTeamConfig | undefined;
  followUps?: FollowUp[];
  policyViolations?: PolicyViolation[];
  policies?: Policy[];
  selectedFollowUpIDs?: string[];
  setSelectedFollowUpIDs?: (ids: string[]) => void;
  installedIssueTrackers: IssueTrackerProviderEnabled[];
  onHomepage?: boolean;
  refetchIncidents: () => Promise<void>;
}): React.ReactElement => {
  return (
    <DeprecatedTable className={tcx("mt-4", styles.followUpTable)} roundedTop>
      <FollowUpsTableHeader
        incident={incident}
        slackTeam={slackTeam}
        selectedFollowUpIDs={selectedFollowUpIDs}
        setSelectedFollowUpIDs={setSelectedFollowUpIDs}
        colSpan={6}
      />
      <FollowUpRows
        incident={incident}
        followUps={sortFollowUps(followUps)}
        isPrivateIncident={
          incident.visibility === IncidentVisibilityEnum.Private
        }
        policies={policies}
        policyViolations={policyViolations}
        selectedFollowUpIDs={selectedFollowUpIDs}
        setSelectedFollowUpIDs={setSelectedFollowUpIDs}
        installedIssueTrackers={installedIssueTrackers}
        onHomepage={onHomepage}
        refetchIncidents={refetchIncidents}
      />
    </DeprecatedTable>
  );
};
