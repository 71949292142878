import { WorkflowsShowWorkflowResponseBody } from "@incident-io/api";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { ClientType } from "src/contexts/ClientContext";
import { useAPIMutation, ValidationErrors } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

/**
 * useMutateWorkflow is a helper hook to ensure that we revalidate both the show
 * and list endpoints for workflows when a mutation is made. This avoids stale
 * data being used from one or the other.
 *
 * You should use this in preference of a raw useApiMutation call when you are
 * mutating a workflow.
 *
 * @param workflowId
 * @param mutate
 * @param swrOptions
 * @returns either the response made in the mutate method or undefined
 */
export function useMutateWorkflow<TRequest extends FieldValues>(
  workflowId: string,
  mutate: (
    client: ClientType,
    arg: TRequest,
  ) => Promise<WorkflowsShowWorkflowResponseBody | undefined>,
  {
    onSuccess: originalOnSuccess,
    ...options
  }: {
    setError?: UseFormSetError<TRequest>;
    onError?: (err: Error, fieldErrors?: ValidationErrors<TRequest>) => void;
    onSuccess?: (data: WorkflowsShowWorkflowResponseBody) => void;
  } = {},
) {
  const reval = useRevalidate(["workflowsListWorkflows"]);

  const onSuccess = async (data: WorkflowsShowWorkflowResponseBody) => {
    await reval();
    if (originalOnSuccess) {
      originalOnSuccess(data);
    }
  };

  return useAPIMutation("workflowsShowWorkflow", { id: workflowId }, mutate, {
    onSuccess,
    ...options,
  });
}
