/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentMembershipsCreateRequestBody
 */
export interface IncidentMembershipsCreateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof IncidentMembershipsCreateRequestBody
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentMembershipsCreateRequestBody
     */
    user_id: string;
}

export function IncidentMembershipsCreateRequestBodyFromJSON(json: any): IncidentMembershipsCreateRequestBody {
    return IncidentMembershipsCreateRequestBodyFromJSONTyped(json, false);
}

export function IncidentMembershipsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentMembershipsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'user_id': json['user_id'],
    };
}

export function IncidentMembershipsCreateRequestBodyToJSON(value?: IncidentMembershipsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'user_id': value.user_id,
    };
}

