/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsOutlookCalendarGetConfigResponseBody,
    IntegrationsOutlookCalendarGetConfigResponseBodyFromJSON,
    IntegrationsOutlookCalendarGetConfigResponseBodyToJSON,
    IntegrationsOutlookCalendarListCalendarsResponseBody,
    IntegrationsOutlookCalendarListCalendarsResponseBodyFromJSON,
    IntegrationsOutlookCalendarListCalendarsResponseBodyToJSON,
} from '../models';

export interface IntegrationsOutlookCalendarHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsOutlookCalendarHandleRedirectRequest {
    code?: string;
    state?: string;
    sessionState?: string;
    error?: string;
    errorDescription?: string;
    tenant?: string;
}

/**
 * 
 */
export class IntegrationsOutlookCalendarApi extends runtime.BaseAPI {

    /**
     * Get Outlook Calendar configuration for the organisation
     * GetConfig Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsOutlookCalendarGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/outlook_calendar/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsOutlookCalendarGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Outlook Calendar configuration for the organisation
     * GetConfig Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarGetConfig(initOverrides?: RequestInit): Promise<IntegrationsOutlookCalendarGetConfigResponseBody> {
        const response = await this.integrationsOutlookCalendarGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * HandleInstall Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarHandleInstallRaw(requestParameters: IntegrationsOutlookCalendarHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsOutlookCalendarHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/outlook_calendar_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * HandleInstall Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarHandleInstall(requestParameters: IntegrationsOutlookCalendarHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsOutlookCalendarHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarHandleRedirectRaw(requestParameters: IntegrationsOutlookCalendarHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.sessionState !== undefined) {
            queryParameters['session_state'] = requestParameters.sessionState;
        }

        if (requestParameters.error !== undefined) {
            queryParameters['error'] = requestParameters.error;
        }

        if (requestParameters.errorDescription !== undefined) {
            queryParameters['error_description'] = requestParameters.errorDescription;
        }

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/outlook_calendar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarHandleRedirect(requestParameters: IntegrationsOutlookCalendarHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsOutlookCalendarHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove the Outlook calendar integration
     * HandleUninstall Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/outlook_calendar_uninstall`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the Outlook calendar integration
     * HandleUninstall Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsOutlookCalendarHandleUninstallRaw(initOverrides);
    }

    /**
     * List all calendars for the connected user
     * ListCalendars Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarListCalendarsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsOutlookCalendarListCalendarsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/outlook_calendar/calendars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsOutlookCalendarListCalendarsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all calendars for the connected user
     * ListCalendars Integrations - Outlook Calendar
     */
    async integrationsOutlookCalendarListCalendars(initOverrides?: RequestInit): Promise<IntegrationsOutlookCalendarListCalendarsResponseBody> {
        const response = await this.integrationsOutlookCalendarListCalendarsRaw(initOverrides);
        return await response.value();
    }

}
