import { Loader } from "@incident-ui/Loader/Loader";
import _ from "lodash";
import React, { ElementType, useCallback, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from "react-beautiful-dnd";

type SortableItem = {
  rank: number;
  id: string;
};

type SortableListOrder = "asc" | "desc";

export type SortableListRenderCallbackProp<ItemType> = {
  item: ItemType;
  index: number;
  draggableProvidedProps: DraggableProvided;
};

export const SortableList = <ItemType extends SortableItem>({
  items,
  sortOrder = "asc",
  containerTag: Container = "div",
  containerProps,
  droppableID,
  updateItemRanks,
  renderItem,
  saving,
  isItemDragDisabled = (_item) => false,
}: {
  items: ItemType[];
  containerTag?: ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerProps?: any;
  sortOrder: SortableListOrder;
  droppableID: string;
  updateItemRanks: (items: ItemType[]) => void;
  renderItem: (
    props: SortableListRenderCallbackProp<ItemType>,
  ) => React.ReactElement;
  saving?: boolean;
  isItemDragDisabled?: (item: ItemType) => boolean;
}): React.ReactElement => {
  // Sort the items by rank
  const sortedItems = _.orderBy(items, (x) => x.rank, sortOrder);

  const biggestRankAtTheTop = sortOrder === "desc";

  const [locallySortedItems, setLocallySortedItems] = useState<
    ItemType[] | null
  >(null);

  const onDragEnd = useCallback(
    async (result) => {
      // Only listen for "DROP" events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // Note source and destination are available so we _could_ drag between
      // lists at some point e.g. Moving a workflow step out of a group. For now,
      // if we dropped it outside the list no-op.
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;

      // Clone elements so we can mutate them safely
      let itemsCopy = _.cloneDeep(sortedItems);

      // Snip out the element we moved
      const [removed] = itemsCopy.splice(fromIndex, 1);

      // Insert it back into the list wherever we dragged it to
      itemsCopy.splice(toIndex, 0, removed);

      if (biggestRankAtTheTop) {
        // Reverse the list (so higher items are at the end, and get the higher index)
        itemsCopy = itemsCopy.reverse();
      }

      // Now iterate the list, set the rank of each item based on its new
      // position in the list (smallest rank -> largest rank)
      itemsCopy = itemsCopy.map((sc, i) => _.merge({}, sc, { rank: i }));

      if (biggestRankAtTheTop) {
        // Finally, reverse the list, so they're back in biggest -> smallest order
        itemsCopy = itemsCopy.reverse();
      }

      // Set our local items so we render those instead
      setLocallySortedItems(itemsCopy);

      // Update the items state
      await updateItemRanks(itemsCopy);

      // Remove our local cache
      setLocallySortedItems(null);
    },
    [sortedItems, updateItemRanks, biggestRankAtTheTop],
  );

  const itemsToRender =
    locallySortedItems == null ? sortedItems : locallySortedItems;

  return (
    <>
      {/* Show a loader overlay while we're making changes */}
      {saving && (
        <div className="absolute flex items-center justify-center w-full h-full z-10 bg-surface-tertiary/50">
          <Loader />
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={droppableID}
          renderClone={(provided, snapshot, rubric) => (
            <>
              {renderItem({
                draggableProvidedProps: provided,
                item: itemsToRender[rubric.source.index],
                index: rubric.source.index,
              })}
            </>
          )}
        >
          {(droppableProvided) => {
            return (
              <>
                {/* We're able to pass a container tag that is super generic */}
                <Container
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  {...containerProps}
                >
                  {itemsToRender.map((item, index) => {
                    const itemID = `${droppableID}-${index}`;
                    return (
                      <Draggable
                        key={itemID}
                        draggableId={itemID}
                        index={index}
                        isDragDisabled={isItemDragDisabled(item)}
                      >
                        {(draggableProvided) => {
                          return (
                            <>
                              {/* NOTE: We must remember to use the DraggableProvided properties returned below! */}
                              {renderItem({
                                item,
                                index,
                                draggableProvidedProps: draggableProvided,
                              })}
                            </>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                </Container>
                {droppableProvided.placeholder}
              </>
            );
          }}
        </Droppable>
      </DragDropContext>
    </>
  );
};
