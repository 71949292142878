import type { SVGProps } from "react";
import * as React from "react";
const SvgPin = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.384 17.67a.75.75 0 0 1-.53-1.281l3.329-3.33a.75.75 0 1 1 1.06 1.061l-3.328 3.33a.75.75 0 0 1-.531.22Z"
    />
    <path
      fill="currentColor"
      d="m16.8 6.77-2.267-2.268c-1.04-1.04-2.85-1.04-3.89 0L7.846 7.3A8.296 8.296 0 0 0 3.29 9.035a.753.753 0 0 0-.071 1.123l7.924 7.924a.75.75 0 0 0 .53.22l.047-.001a.753.753 0 0 0 .546-.29 8.306 8.306 0 0 0 1.48-2.985 8.27 8.27 0 0 0 .254-1.568l2.799-2.8c.52-.52.806-1.21.806-1.943 0-.734-.286-1.425-.806-1.945Z"
    />
  </svg>
);
export default SvgPin;
