import {
  CatalogEntry,
  CatalogRelation,
  CatalogType,
  CatalogTypeAttributeModeEnum,
} from "@incident-io/api";
import {
  DeprecatedTable,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
  Txt,
} from "@incident-ui";
import { AttributeEntries } from "src/components/@shared/attribute/AttributeEntries";
import { useCatalogResources } from "src/hooks/useCatalogResources";
import { tcx } from "src/utils/tailwind-classes";

import { CatalogEntryReferences } from "../entry-list/CatalogEntryReferences";
import { AttributeList, AttributeListItem } from "./AttributeList";

type Props = {
  catalogEntry: CatalogEntry;
  catalogType: CatalogType;
  excludeUserRelations: boolean;
  catalogRelations: CatalogRelation[];
  basePath?: string;
};

export const CatalogEntryAttributes = ({
  catalogEntry,
  catalogType,
  catalogRelations,
  excludeUserRelations,
  basePath,
}: Props) => {
  const schema = catalogType.schema;
  const { resources, resourcesLoading, resourcesError, userLinkResources } =
    useCatalogResources();

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }
  if (resourcesLoading) {
    return <Loader />;
  }

  let attributesToShow = schema.attributes;

  if (excludeUserRelations) {
    attributesToShow = attributesToShow.filter(
      (attr) => !userLinkResources.includes(attr.type),
    );
  }

  const attributeListItems: AttributeListItem[] = attributesToShow.map(
    (attribute) => ({
      title: attribute.name,
      renderValues: () => (
        <AttributeEntries
          mode={"catalog"}
          typeName={attribute.type}
          catalogResources={resources}
          attributeBinding={catalogEntry.attribute_values[attribute.id]}
          basePath={basePath}
        />
      ),
    }),
  );

  // Now add backlink items
  const backlinkItems = buildAvailableBacklinkItems({
    catalogRelations,
    catalogType,
    basePath,
  });
  attributeListItems.push(...backlinkItems);

  // Now add any IDs
  if (catalogEntry.external_id) {
    attributeListItems.push({
      title: "External ID",
      renderValues: () => (
        <Txt className="font-mono">{catalogEntry.external_id}</Txt>
      ),
    });
  }
  if (catalogType.ranked) {
    attributeListItems.push({
      title: "Rank",
      renderValues: () => <Txt>{catalogEntry.rank}</Txt>,
    });
  }

  // Always add aliases
  attributeListItems.push({
    title: (
      <Tooltip content={ALIASES_HELP_TEXT}>
        <div className={"max-w-[230px] flex items-center gap-1"}>
          <div className="text-content-tertiary max-w-[230px] truncate">
            Aliases
          </div>
          <Icon
            id={IconEnum.Info}
            size={IconSize.Small}
            className="text-content-tertiary"
          />
        </div>
      </Tooltip>
    ),
    renderValues: () =>
      catalogEntry.aliases?.length > 0 ? (
        <Txt> {catalogEntry.aliases.join(", ")}</Txt>
      ) : (
        <Txt lightGrey>None set</Txt>
      ),
  });

  return <AttributeList items={attributeListItems} />;
};

export const ALIASES_HELP_TEXT =
  "Use aliases to refer to this catalog entry from other tools. These should be unique and permanent, meaning even if you rename the catalog entry, the alias should remain the same.";
export const CatalogEntryIDTable = ({
  catalogEntry,
  catalogType,
}: {
  catalogEntry: CatalogEntry;
  catalogType: CatalogType;
}) => {
  return (
    <DeprecatedTable className="p-2">
      <tbody>
        {catalogEntry.external_id && (
          <tr key={"external-id"} className={"!border-0"}>
            <td className="!py-2">
              <div className="text-content-tertiary max-w-[230px] truncate">
                External ID
              </div>
            </td>
            <td className="w-full !py-2 font-mono">
              {catalogEntry.external_id}
            </td>
          </tr>
        )}
        {catalogType.ranked && (
          <tr key={"ranked"} className={"!border-0"}>
            <td className="!py-2">
              <div className="text-content-tertiary max-w-[230px] truncate">
                Rank
              </div>
            </td>
            <td className="w-full !py-2">{catalogEntry.rank}</td>
          </tr>
        )}
        <tr key={"external-id"} className={"!border-0"}>
          <td className="!py-2">
            <Tooltip content={ALIASES_HELP_TEXT}>
              <div className={"max-w-[230px] flex flex-row gap-1"}>
                <div className="text-content-tertiary max-w-[230px] truncate">
                  Aliases
                </div>
                <Icon
                  id={IconEnum.Info}
                  size={IconSize.Small}
                  className="text-content-tertiary"
                />
              </div>
            </Tooltip>
          </td>
          <td
            className={tcx("w-full !py-2", {
              "text-content-tertiary": !catalogEntry.aliases?.length,
            })}
          >
            {(catalogEntry.aliases ?? []).length > 0
              ? catalogEntry.aliases.join(", ")
              : "None set"}
          </td>
        </tr>
      </tbody>
    </DeprecatedTable>
  );
};

const buildAvailableBacklinkItems = ({
  catalogRelations,
  catalogType,
  basePath,
}: {
  catalogRelations: CatalogRelation[];
  catalogType: CatalogType;
  basePath?: string;
}): AttributeListItem[] => {
  const backlinkAttributes = catalogType.schema.attributes.filter(
    (attr) => attr.mode === CatalogTypeAttributeModeEnum.Backlink,
  );

  const flattenedRelations = catalogRelations.flatMap(
    ({ catalog_type, attributes }) => {
      return attributes.map((attribute) => {
        return {
          catalog_type,
          attribute,
        };
      });
    },
  );

  return flattenedRelations
    .filter(
      (relation) =>
        !backlinkAttributes.some(
          (attr) =>
            attr.backlink_attribute === relation.attribute.attribute &&
            attr.type === relation.catalog_type.type_name,
        ),
    )
    .map((relation) => {
      const attr = relation.catalog_type.schema.attributes.find(
        (attr) => attr.id === relation.attribute.attribute,
      );

      if (!attr) {
        throw new Error(
          `could not find attribute with id="${relation.attribute}`,
        );
      }

      return {
        title: (
          <span className="flex items-center text-xs text-slate-600">
            {relation.catalog_type.name}
            <Icon id={IconEnum.ChevronRight} size={IconSize.Small} />
            {attr.name}
            <Tooltip
              content={`This is a derived attribute. If you'd like to use it in other automations, such as workflows, you can add it by editing the ${catalogType.name} type`}
              buttonClassName="ml-1"
            />
          </span>
        ),
        renderValues: () => (
          <CatalogEntryReferences
            catalogType={relation.catalog_type}
            entries={relation.attribute.entries}
            basePath={basePath}
          />
        ),
      };
    }) as AttributeListItem[];
};
