/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillingSetting,
    BillingSettingFromJSON,
    BillingSettingFromJSONTyped,
    BillingSettingToJSON,
} from './BillingSetting';

/**
 * 
 * @export
 * @interface BillingShowBillingSettingsResponseBody
 */
export interface BillingShowBillingSettingsResponseBody {
    /**
     * 
     * @type {BillingSetting}
     * @memberof BillingShowBillingSettingsResponseBody
     */
    billing_setting: BillingSetting;
}

export function BillingShowBillingSettingsResponseBodyFromJSON(json: any): BillingShowBillingSettingsResponseBody {
    return BillingShowBillingSettingsResponseBodyFromJSONTyped(json, false);
}

export function BillingShowBillingSettingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingShowBillingSettingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billing_setting': BillingSettingFromJSON(json['billing_setting']),
    };
}

export function BillingShowBillingSettingsResponseBodyToJSON(value?: BillingShowBillingSettingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_setting': BillingSettingToJSON(value.billing_setting),
    };
}

