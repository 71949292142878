import type { SVGProps } from "react";
import * as React from "react";
const SvgFlag = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.635 3.606a.749.749 0 0 0-.74-.017c-1.281.689-2.481.958-3.565.794-.495-.074-.86-.219-1.284-.387-.47-.187-1.002-.398-1.781-.546A9.114 9.114 0 0 0 5 3.448v8.508a8.274 8.274 0 0 1 3.007-.091c.628.1 1.043.244 1.483.396.416.144.846.292 1.422.388.362.06.73.09 1.104.09 1.092 0 2.237-.255 3.417-.762a.75.75 0 0 0 .566-.728V4.25a.752.752 0 0 0-.365-.644h.001Z"
    />
    <path
      fill="currentColor"
      d="M4.75 18a.75.75 0 0 1-.75-.75V2.75a.75.75 0 0 1 1.5 0v14.5a.75.75 0 0 1-.75.75Z"
    />
  </svg>
);
export default SvgFlag;
