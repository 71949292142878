/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebhookConfig
 */
export interface WebhookConfig {
    /**
     * Unique identifier for the config
     * @type {string}
     * @memberof WebhookConfig
     */
    id: string;
    /**
     * Unique identifier of the corresponding organisation
     * @type {string}
     * @memberof WebhookConfig
     */
    organisation_id: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    svix_portal_url: string;
}

export function WebhookConfigFromJSON(json: any): WebhookConfig {
    return WebhookConfigFromJSONTyped(json, false);
}

export function WebhookConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organisation_id': json['organisation_id'],
        'svix_portal_url': json['svix_portal_url'],
    };
}

export function WebhookConfigToJSON(value?: WebhookConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organisation_id': value.organisation_id,
        'svix_portal_url': value.svix_portal_url,
    };
}

