import {
  EngineParamBinding,
  EngineScope,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { EngineFormElement, getVariableScope } from "@incident-shared/engine";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { FormErrorMessage } from "@incident-shared/forms/ErrorMessage";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence, motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { Prompt } from "src/components/@shared/utils/Prompt";

import { tcx } from "../../../utils/tailwind-classes";
import { AlertRouteConditionsEditor } from "./AlertRouteConditionsEditor";
import {
  AlertRouteFormSection,
  AlertRouteYesNoButtonGroup,
} from "./AlertRouteFormSection";
import { AlertRouteFormData, PulseChannelFormData } from "./types";

export const AlertRoutePulseChannelSection = ({
  scope,
  resources,
}: {
  scope: EngineScope;
  resources: Resource[];
}) => {
  const { featureAlertsPulseChannel } = useFlags();
  const formMethods = useFormContext<AlertRouteFormData>();

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: AlertRouteFormData;
  }>({
    isEditing: false,
  });

  const onSubmit = (data: PulseChannelFormData) => {
    formMethods.setValue<"enablePulseChannels">(
      "enablePulseChannels",
      data.enablePulseChannels,
      {
        shouldDirty: true,
      },
    );
    formMethods.setValue<"channelExpressions">(
      "channelExpressions",
      data.channelExpressions,
      {
        shouldDirty: true,
      },
    );

    if (data.enablePulseChannels) {
      formMethods.setValue<"channelBindings">(
        "channelBindings",
        data.channelBindings,
        {
          shouldDirty: true,
        },
      );
    } else {
      formMethods.setValue<"channelBindings">("channelBindings", [], {
        shouldDirty: true,
      });
      formMethods.setValue<"channelExpressions">("channelExpressions", [], {
        shouldDirty: true,
      });
    }

    setEditingContext({ isEditing: false });
    formMethods.setValue<"hasEverConfiguredPulseChannels">(
      "hasEverConfiguredPulseChannels",
      true,
      {
        shouldDirty: true,
      },
    );
  };

  const hasEverConfiguredIncidents = formMethods.watch(
    "hasEverConfiguredIncidents",
  );
  const hasEverConfiguredPulseChannels = formMethods.watch(
    "hasEverConfiguredPulseChannels",
  );
  const enablePulseChannels = formMethods.watch("enablePulseChannels");
  const channelBindings = formMethods.watch("channelBindings");
  const hasChannelBindings =
    channelBindings &&
    channelBindings.length > 0 &&
    channelBindings.some((binding) => binding.binding.value);

  const expressionMethods = useFieldArray({
    name: "channelExpressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const variableScope = getVariableScope(scopeWithExpressions, resources);

  return (
    <>
      {editingContext.isEditing && (
        <AlertsPulseChannelDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          scope={scope}
          resources={resources}
        />
      )}
      <ExpressionsMethodsProvider
        expressionsMethods={expressionMethods}
        allowAllOfACatalogType={false}
        showExpressionNames={false}
      >
        <AlertRouteFormSection
          title={
            !hasEverConfiguredPulseChannels
              ? "Send alerts to Slack"
              : enablePulseChannels
              ? "Send alerts to Slack:"
              : "Don't send alerts to Slack"
          }
          isCurrentFirstTimeStep={
            !hasEverConfiguredPulseChannels && hasEverConfiguredIncidents
          }
          firstTimeContent={<>Choose Slack channels to send your alerts to.</>}
          disabled={hasEverConfiguredPulseChannels && !enablePulseChannels}
          stacked={featureAlertsPulseChannel}
          icon={
            enablePulseChannels || !hasEverConfiguredPulseChannels
              ? IconEnum.Slack
              : IconEnum.SlackGreyscale
          }
          color={ColorPaletteEnum.Slate200}
          accessory={
            !hasEverConfiguredPulseChannels ? (
              <AlertRouteYesNoButtonGroup
                disabled={!hasEverConfiguredIncidents}
                onChange={(shouldPulse) => {
                  formMethods.setValue<"enablePulseChannels">(
                    "enablePulseChannels",
                    shouldPulse,
                  );
                  if (shouldPulse) {
                    setEditingContext({
                      isEditing: true,
                      initialData: formMethods.getValues(),
                    });
                  } else {
                    formMethods.setValue<"hasEverConfiguredPulseChannels">(
                      "hasEverConfiguredPulseChannels",
                      true,
                    );
                  }
                }}
              />
            ) : (
              <GatedButton
                requiredScope={ScopeNameEnum.AlertRouteUpdate}
                theme={ButtonTheme.Naked}
                onClick={() => {
                  setEditingContext({
                    isEditing: true,
                    initialData: formMethods.getValues(),
                  });
                }}
                icon={IconEnum.Edit}
                className="text-content-tertiary"
                title="Send alerts to Slack"
                analyticsTrackingId="alert-routes-edit-pulse-channel"
              />
            )
          }
        >
          {hasChannelBindings && (
            <>
              <div className={"flex flex-col gap-3"}>
                {channelBindings
                  .filter((binding) => binding.binding.value)
                  .map((binding, index) => {
                    return (
                      <ConditionGroupsList
                        key={index}
                        groups={binding.condition_groups}
                        then={
                          <div
                            className={tcx(
                              "flex flex-col gap-2 p-3",
                              binding.condition_groups.length > 0 &&
                                "border-t border-dotted border-stroke",
                            )}
                          >
                            <div className="font-mono text-xs-med text-content-tertiary w-full text-left">
                              SEND TO
                            </div>
                            <EngineBinding
                              key={index}
                              binding={binding.binding as EngineParamBinding}
                              resourceType={
                                binding.channel_visibility === "public"
                                  ? 'CatalogEntry["SlackChannel"]'
                                  : "SlackChannel" // We show private Slack channel names because we do this in other places and want to remain consistent.
                              }
                              variableScope={variableScope}
                              className="shadow-none"
                              displayExpressionAs="pill"
                            />
                          </div>
                        }
                      />
                    );
                  })}
              </div>
              <FormErrorMessage
                errors={formMethods.formState.errors}
                name={"channel_bindings"}
              />
            </>
          )}
        </AlertRouteFormSection>
      </ExpressionsMethodsProvider>
    </>
  );
};

const AlertsPulseChannelDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  resources,
}: {
  onSubmit: (data: PulseChannelFormData) => void;
  onClose: () => void;
  initialData?: AlertRouteFormData;
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useForm<PulseChannelFormData>({
    defaultValues: initialData,
  });

  const expressionMethods = useFieldArray({
    name: "channelExpressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const {
    fields: channelBindings,
    append: appendChannelBinding,
    remove: removeChannelBinding,
  } = useFieldArray({
    name: "channelBindings",
    control: formMethods.control,
  });

  const handleAddChannel = () => {
    appendChannelBinding({
      binding: { array_value: [] },
      condition_groups: [],
      channel_visibility: "public",
    });
  };

  const handleRemoveChannel = (index: number) => {
    removeChannelBinding(index);
  };

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const enablePulseChannels = formMethods.watch("enablePulseChannels");

  const validateAndSubmit = useCallback(() => {
    const data = formMethods.getValues();
    if (data.enablePulseChannels) {
      if (
        !data.channelBindings.some((binding) =>
          binding.channel_visibility === "public"
            ? binding.binding.value !== undefined
            : binding.binding.value?.literal,
        )
      ) {
        formMethods.setError("enablePulseChannels", {
          type: "manual",
          message:
            "Please select a channel to send alerts to, or turn off alert pulse",
        });
        return;
      }
    }

    onSubmit(data);
  }, [onSubmit, formMethods]);

  // If you change values, then clear the errors
  formMethods.watch(() => {
    formMethods.clearErrors();
  });

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
      showExpressionNames={false}
    >
      <Drawer width="medium" onClose={onClose}>
        <DrawerContents>
          <DrawerTitle
            title="Send alerts to Slack"
            onClose={onClose}
            icon={IconEnum.Slack}
            iconClassName={"bg-slate-surface"}
            compact
            theme={DrawerTitleTheme.Bordered}
          />
          <DrawerBody className="grow">
            <Form.Root
              id="alert-routes-pulse-channel"
              fullHeight
              formMethods={formMethods}
              onSubmit={validateAndSubmit}
              innerClassName={"space-y-6"}
            >
              <Prompt
                when={formMethods.formState.isDirty}
                message={
                  "Your changes have not been saved. Are you sure you want to navigate away?"
                }
              />
              <div className="text-sm text-content-secondary">
                Use this route to send alerts to Slack. You can apply filters or
                use expressions to direct specific alerts to the appropriate
                destinations.
              </div>
              <BooleanRadioButtonGroupV2
                trueOption={{
                  label: "Yes",
                }}
                falseOption={{
                  label: "No",
                }}
                name="enablePulseChannels"
                label="Send alerts to Slack"
                formMethods={formMethods}
                srLabel="Enable alerts pulse"
                boxed
              />
              <AnimatePresence>
                {enablePulseChannels && (
                  <motion.div
                    className="space-y-6 rounded-b-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {channelBindings.map((binding, index) => {
                      const visibility = formMethods.watch(
                        `channelBindings.${index}.channel_visibility`,
                      );
                      const bindingValue = formMethods.watch(
                        `channelBindings.${index}.binding.value.reference`,
                      );
                      const conditionGroups = formMethods.watch(
                        `channelBindings.${index}.condition_groups`,
                      );
                      return (
                        <div key={index} className={"flex flex-col gap-6"}>
                          <div className={"flex flex-col gap-4"}>
                            <div className="flex flex-col gap-1">
                              <span className="text-sm-bold">Destination</span>
                              <div className="flex flex-row relative shadow-sm hover:shadow-none rounded-lg">
                                {(!bindingValue ||
                                  visibility === "private") && (
                                  <StaticSingleSelectV2
                                    isSearchable={false}
                                    name={`channelBindings.${index}.channel_visibility`}
                                    options={[
                                      {
                                        label: "Public",
                                        value: "public",
                                        icon: IconEnum.SlackChannel,
                                      },
                                      {
                                        label: "Private",
                                        value: "private",
                                        icon: IconEnum.LockClosed,
                                      },
                                    ]}
                                    onValueChange={() => {
                                      formMethods.setValue(
                                        `channelBindings.${index}.binding`,
                                        { value: undefined },
                                      );
                                    }}
                                    formMethods={formMethods}
                                    className="group relative [&_div]:shadow-none [&_div]:rounded-r-none [&>div]:relative [&>div]:z-[1] focus-within:[&>div]:z-[2]"
                                  />
                                )}
                                {visibility === "public" ? (
                                  <EngineFormElement
                                    name={`channelBindings.${index}.binding`}
                                    resourceType={`CatalogEntry["SlackChannel"]`}
                                    array={false}
                                    key={"channel-binding-input"}
                                    expressionLabelOverride={`Destination ${
                                      index + 1
                                    }`}
                                    resources={resources}
                                    scope={scopeWithExpressions}
                                    showPlaceholder
                                    mode="variables_and_expressions"
                                    className="group relative [&_div]:shadow-none [&_div]:rounded-l-none ml-[-1px] flex-1 [&>div]:relative [&>div]:z-[1] focus-within:[&>div]:z-[2]"
                                    required={false}
                                  />
                                ) : (
                                  <InputV2
                                    formMethods={formMethods}
                                    placeholder={"Private channel ID"}
                                    name={`channelBindings.${index}.binding.value.literal`}
                                    className={
                                      "grow group relative ml-[-1px] flex-1 hover:shadow-xs"
                                    }
                                    inputClassName={
                                      "relative rounded-l-none z-[1] focus-within:z-[2]"
                                    }
                                  />
                                )}
                              </div>
                              {visibility === "private" && (
                                <span
                                  className={
                                    "text-xs-med text-content-tertiary"
                                  }
                                >
                                  The channel ID can be found at the bottom of
                                  channel settings in Slack.
                                </span>
                              )}
                            </div>
                            {visibility === "private" && (
                              <Callout theme={CalloutTheme.Warning}>
                                Anyone viewing this alert route will be able to
                                see the name of this private channel.
                              </Callout>
                            )}
                          </div>

                          <AlertRouteConditionsEditor
                            name={`channelBindings.${index}.condition_groups`}
                            conditionGroups={conditionGroups || []}
                            scope={scope}
                            introText={`Apply to all alerts`}
                            formMethods={formMethods}
                            expressions={expressionMethods.fields}
                            allowFilteringByExpression={false}
                          />
                          <div className={"flex flex-row"}>
                            {index === channelBindings.length - 1 && (
                              <Button
                                theme={ButtonTheme.Unstyled}
                                icon={IconEnum.Add}
                                onClick={handleAddChannel}
                                analyticsTrackingId={
                                  "add-another-pulse-channel"
                                }
                                className={"text-content-secondary text-sm-med"}
                              >
                                Add another destination
                              </Button>
                            )}
                            <div className={"grow"} />
                            {channelBindings.length > 1 && (
                              <Button
                                theme={ButtonTheme.Unstyled}
                                onClick={() => handleRemoveChannel(index)}
                                analyticsTrackingId={"delete-pulse-channel"}
                                className={"text-content-destroy text-sm-med"}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                          {channelBindings.length > 1 &&
                            index < channelBindings.length - 1 && (
                              <div className="h-px border-t border-stroke-secondary" />
                            )}
                        </div>
                      );
                    })}
                  </motion.div>
                )}
              </AnimatePresence>
            </Form.Root>
          </DrawerBody>
          <DrawerFooter className="flex gap-2 justify-end">
            <Button onClick={() => onClose()} analyticsTrackingId={null}>
              Back
            </Button>
            <GatedButton
              form="alert-routes-pulse-channel"
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              type="submit"
              theme={ButtonTheme.Primary}
              analyticsTrackingId="alert-routes-pulse-save"
            >
              Apply
            </GatedButton>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};
