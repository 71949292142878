import { SeatCount, SeatCountSeatTypeEnum } from "@incident-io/api";
import { UpgradeRequiredProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

export const useCanPromoteToOnCall = (count = 1) => {
  const { identity } = useIdentity();
  const gate = identity?.feature_gates?.on_call_responders_count;

  // Don't bother loading this if there is no gate
  const dontNeedCount = gate === undefined || gate === 0;
  const { data } = useAPI(
    dontNeedCount ? null : "billingListSeatCounts",
    undefined,
  );

  const upgradeRequiredProps: UpgradeRequiredProps = {
    featureName: "On-call users",
    gate: {
      type: "numeric",
      value: gate,
      featureNameSingular: "On-call user",
    },
  };

  if (!identity) {
    return {
      upgradeRequired: false,
      isLoading: true,
      upgradeRequiredProps,
    };
  }
  if (dontNeedCount) {
    return {
      upgradeRequired: gate === 0, // the other option is undefined, which means unlimited
      isLoading: false,
      upgradeRequiredProps,
    };
  }

  if (!data) {
    return {
      upgradeRequired: false,
      isLoading: true,
      upgradeRequiredProps,
    };
  }

  const currentUsage =
    // This should only be not in the response if either:
    // 1. we're still loading the data; or
    // 2. we don't expose seat counts to this customer.
    //
    // In the latter case, assuming no usage is fine: they will not be on a
    // capped plan!
    data?.seat_counts.find(isOnCallSeatCount)?.used ?? 0;

  const amountLeft = gate - currentUsage;

  return {
    isLoading: false,
    upgradeRequired: count > amountLeft,
    upgradeRequiredProps,
  };
};

export const isOnCallSeatCount = (seat: SeatCount) =>
  seat.seat_type === SeatCountSeatTypeEnum.OnCall;
export const isResponderSeatCount = (seat: SeatCount) =>
  seat.seat_type === SeatCountSeatTypeEnum.Responder;
