/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * Alpha-2 ISO country code
     * @type {string}
     * @memberof Country
     */
    code: string;
    /**
     * Which currencies are used in this country
     * @type {Array<string>}
     * @memberof Country
     */
    currencies: Array<string>;
    /**
     * Flag image URL
     * @type {string}
     * @memberof Country
     */
    flag_url: string;
    /**
     * Name of the country
     * @type {string}
     * @memberof Country
     */
    name: string;
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'currencies': json['currencies'],
        'flag_url': json['flag_url'],
        'name': json['name'],
    };
}

export function CountryToJSON(value?: Country | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'currencies': value.currencies,
        'flag_url': value.flag_url,
        'name': value.name,
    };
}

