import {
  Incident,
  IncidentsGetSummarySuggestionResponseBody,
} from "@incident-io/api";
import { Button, ButtonTheme, ContentBox, LoadingBar } from "@incident-ui";
import { useEffect, useState } from "react";
import { useClient } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { PendingSummarySuggestion } from "./PendingSummarySuggestion";
import { SectionWrapper } from "./SectionWrapper";
import { useSummaryContext } from "./SummaryContext";
import { SummaryDisplay } from "./SummaryDisplay";
import { SummaryEditor } from "./SummaryEditor";

export const OverviewSummary = ({ incident }: { incident: Incident }) => {
  const {
    suggestion,
    suggestionLoading,
    refetchSuggestion,
    isEditingSummary,
    setIsEditingSummary,
    acceptedSuggestion,
    onEditCancel,
    onSuggestionAccept,
    onSuggestionReject,
    setAcceptedSuggestion,
  } = useSummary(incident);

  if (suggestionLoading) {
    return <LoadingBar className="w-full h-[28px]" />;
  }

  if (isEditingSummary) {
    return (
      <div className="flex flex-col gap-6">
        {!acceptedSuggestion && (
          <div className="pl-3">
            <PendingSummarySuggestion
              suggestionData={suggestion}
              onAccept={onSuggestionAccept}
              onReject={onSuggestionReject}
              className="w-full"
            />
          </div>
        )}
        <div className="bg-surface-secondary p-3 rounded-2">
          <ContentBox className="p-6 flex flex-col gap-2 bg-white">
            <SummaryEditor
              alignButtons="right"
              incidentId={incident.id}
              summary={incident.summary}
              suggestion={acceptedSuggestion?.suggestion}
              onCancel={onEditCancel}
              onSave={async () => {
                await refetchSuggestion();
                acceptedSuggestion && setAcceptedSuggestion(undefined);
                setIsEditingSummary(false);
              }}
            />
          </ContentBox>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="pl-3 pt-3">
        <PendingSummarySuggestion
          className="w-full"
          suggestionData={suggestion}
          onAccept={onSuggestionAccept}
          onReject={onSuggestionReject}
        />
      </div>
      <Button
        analyticsTrackingId={null}
        theme={ButtonTheme.Unstyled}
        onClick={() => {
          setIsEditingSummary(true);
        }}
        className="text-left cursor-text w-full flex flex-col gap-6 justify-start items-start hover:bg-surface-secondary p-3 rounded-2"
      >
        {incident.summary ? (
          <SummaryDisplay
            summary={incident.summary}
            onStartEditing={() => setIsEditingSummary(true)}
          />
        ) : (
          <div className="text-content-tertiary">Add a summary...</div>
        )}
      </Button>
    </div>
  );
};

export const PostmortemSummary = ({
  id,
  incident,
}: {
  id: string;
  incident: Incident;
}) => {
  const {
    suggestion,
    suggestionLoading,
    refetchSuggestion,
    isEditingSummary,
    setIsEditingSummary,
    acceptedSuggestion,
    onEditCancel,
    onSuggestionAccept,
    onSuggestionReject,
    setAcceptedSuggestion,
  } = useSummary(incident);

  if (suggestionLoading) {
    return <LoadingBar className="w-full h-[28px]" />;
  }

  if (isEditingSummary) {
    return (
      <SectionWrapper id={id} sectionName="Summary">
        {!acceptedSuggestion && (
          <PendingSummarySuggestion
            suggestionData={suggestion}
            onAccept={onSuggestionAccept}
            onReject={onSuggestionReject}
          />
        )}
        {/* Made use of a `is-editing` class here to make use of the has modifier in SectionWrapper */}
        <div className="rounded-lg shadow-md border border-stroke-hover bg-white p-6 is-editing">
          <SummaryEditor
            alignButtons="left"
            incidentId={incident.id}
            summary={incident.summary}
            suggestion={acceptedSuggestion?.suggestion}
            onCancel={onEditCancel}
            onSave={async () => {
              await refetchSuggestion();
              acceptedSuggestion && setAcceptedSuggestion(undefined);
              setIsEditingSummary(false);
            }}
          />
        </div>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper
      id={id}
      sectionName="Summary"
      onClick={() => setIsEditingSummary(true)}
    >
      <PendingSummarySuggestion
        suggestionData={suggestion}
        onAccept={onSuggestionAccept}
        onReject={onSuggestionReject}
      />
      {incident.summary ? (
        <SummaryDisplay
          summary={incident.summary}
          onStartEditing={() => setIsEditingSummary(true)}
        />
      ) : (
        <div>Click to add summary</div>
      )}
    </SectionWrapper>
  );
};

export const useSummary = (incident: Incident) => {
  const apiClient = useClient();
  const [refreshIntervalMs, setRefreshIntervalMs] = useState(0);
  const {
    data: suggestion,
    isLoading: suggestionLoading,
    mutate: refetchSuggestion,
  } = useAPI(
    "incidentsGetSummarySuggestion",
    { id: incident.id },
    { refreshInterval: refreshIntervalMs },
  );

  useEffect(() => {
    if (
      suggestion &&
      suggestion.has_feature &&
      suggestion.has_suggestion === false
    ) {
      setRefreshIntervalMs(10_000);
    }
  }, [suggestion]);

  const { isEditingSummary, setIsEditingSummary } = useSummaryContext();
  const [acceptedSuggestion, setAcceptedSuggestion] =
    useState<IncidentsGetSummarySuggestionResponseBody>();

  const onEditCancel = () => {
    setIsEditingSummary(false);
  };

  const onSuggestionAccept = (
    suggestionData: IncidentsGetSummarySuggestionResponseBody,
  ) => {
    setAcceptedSuggestion(suggestionData);
    setIsEditingSummary(true);
  };

  const onSuggestionReject = async (
    suggestionData: IncidentsGetSummarySuggestionResponseBody,
  ) => {
    setAcceptedSuggestion(undefined);
    if (!incident || !suggestionData.suggestion) {
      return;
    }
    await apiClient.incidentsRejectSummarySuggestion({
      incidentId: incident.id,
      suggestionId: suggestionData.suggestion.id,
    });
    refetchSuggestion();
  };

  return {
    suggestion,
    suggestionLoading,
    refetchSuggestion,
    isEditingSummary,
    setIsEditingSummary,
    acceptedSuggestion,
    onEditCancel,
    onSuggestionAccept,
    onSuggestionReject,
    setAcceptedSuggestion,
  };
};
