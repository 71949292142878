/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsSetIntegrationAPITokenRequestBody
 */
export interface IntegrationsSetIntegrationAPITokenRequestBody {
    /**
     * API token for this integration
     * @type {string}
     * @memberof IntegrationsSetIntegrationAPITokenRequestBody
     */
    api_token: string;
    /**
     * Page ID for the statuspage
     * @type {string}
     * @memberof IntegrationsSetIntegrationAPITokenRequestBody
     */
    atlassian_statuspage_page_id?: string;
    /**
     * Which provider does this api token relate to
     * @type {string}
     * @memberof IntegrationsSetIntegrationAPITokenRequestBody
     */
    provider: IntegrationsSetIntegrationAPITokenRequestBodyProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum IntegrationsSetIntegrationAPITokenRequestBodyProviderEnum {
    Cortex = 'cortex',
    Opslevel = 'opslevel',
    Opsgenie = 'opsgenie',
    Pagerduty = 'pagerduty'
}

export function IntegrationsSetIntegrationAPITokenRequestBodyFromJSON(json: any): IntegrationsSetIntegrationAPITokenRequestBody {
    return IntegrationsSetIntegrationAPITokenRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsSetIntegrationAPITokenRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsSetIntegrationAPITokenRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_token': json['api_token'],
        'atlassian_statuspage_page_id': !exists(json, 'atlassian_statuspage_page_id') ? undefined : json['atlassian_statuspage_page_id'],
        'provider': json['provider'],
    };
}

export function IntegrationsSetIntegrationAPITokenRequestBodyToJSON(value?: IntegrationsSetIntegrationAPITokenRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_token': value.api_token,
        'atlassian_statuspage_page_id': value.atlassian_statuspage_page_id,
        'provider': value.provider,
    };
}

