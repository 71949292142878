import {
  ConditionGroup,
  DebriefInviteRule,
  EngineParamBindingPayload,
  EngineScope,
  IncidentsBuildScopeContextEnum,
  Resource,
} from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions/marshall";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  IconSize,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAllResources } from "src/hooks/useResources";
import { useAPIMutation } from "src/utils/swr";

export const CreateEditDebriefInviteRuleModal = ({
  onClose,
  ...createEditProps
}: CreateEditFormProps<DebriefInviteRule> & {
  onClose: () => void;
}): React.ReactElement => {
  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.DebriefInviteRules,
  );

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (scopeError || resourcesError) {
    return <ErrorModal onClose={onClose} />;
  }

  if (scopeLoading || resourcesLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <CreateEditDebriefInviteRuleModalL2
      {...createEditProps}
      incidentScope={scope}
      resources={resources}
      onClose={onClose}
    />
  );
};

type FormData = {
  param_bindings: {
    emails: EngineParamBindingPayload;
    users: EngineParamBindingPayload;
  };
  condition_groups: ConditionGroup[];
  expressions?: ExpressionFormData[];
  should_create_matching_policy: boolean;
};

type TargetMode = "users" | "emails";

export const CreateEditDebriefInviteRuleModalL2 = ({
  mode,
  initialData: rule,
  incidentScope,
  resources,
  onClose,
}: CreateEditFormProps<DebriefInviteRule> & {
  incidentScope: EngineScope;
  resources: Resource[];
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<FormData>({
    mode: "onSubmit",
    defaultValues: {
      param_bindings: {
        emails: rule?.invitees.emails ?? undefined,
        users: rule?.invitees.users ?? undefined,
      },
      condition_groups: rule?.condition_groups || [],
      expressions: rule?.expressions,
      should_create_matching_policy: false,
    },
  });

  const { control, setError, watch, setValue, getValues } = formMethods;

  const userParams = watch("param_bindings.users");
  const emailParams = watch("param_bindings.emails");

  const hasUsers =
    userParams && userParams.array_value && userParams.array_value.length > 0;

  // Filter out any empty strings
  const emailParamsFiltered = {
    ...emailParams,
    array_value:
      emailParams?.array_value?.filter(
        (email) => email.reference || email.literal,
      ) ?? [],
  };
  const hasEmails =
    emailParamsFiltered &&
    emailParamsFiltered.array_value &&
    emailParamsFiltered.array_value.length > 0;

  const isEditing = mode === Mode.Edit;

  // Is a variable/expression being used for either users or emails
  const referencesInUse =
    _.concat(
      emailParamsFiltered?.array_value || [],
      userParams?.array_value || [],
    ).find((x) => x?.reference !== undefined) !== undefined;

  useEffect(() => {
    // Policies don't support references, so disable it
    if (referencesInUse) {
      setValue<"should_create_matching_policy">(
        "should_create_matching_policy",
        false,
      );
    }
  }, [setValue, referencesInUse]);

  let defaultMode: TargetMode = "users";
  if (hasEmails && !hasUsers) {
    // If any emails exist, use that as the default
    defaultMode = "emails";
  }

  const [inviteTarget, setInviteTarget] = useState<TargetMode>(defaultMode);
  const toggleMode = () => {
    if (inviteTarget === "emails") {
      // @ts-expect-error there's no clearValue function, apparently
      setValue<"param_bindings.emails">("param_bindings.emails", null);
      setInviteTarget("users");
    } else if (inviteTarget === "users") {
      // @ts-expect-error there's no clearValue function, apparently
      setValue<"param_bindings.users">("param_bindings.users", null);
      setInviteTarget("emails");
    }
  };

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "debriefsListInviteRules",
    undefined,
    async (client, formData: FormData) => {
      const emailParams =
        inviteTarget === "emails" ? formData.param_bindings.emails : undefined;
      const userParams =
        inviteTarget === "users" ? formData.param_bindings.users : undefined;

      // Filter out any empty strings
      const emailParamsFiltered = {
        ...emailParams,
        array_value:
          emailParams?.array_value?.filter(
            (email) => email.reference || email.literal,
          ) ?? [],
      };

      if (mode === Mode.Edit) {
        await client.debriefsUpdateInviteRule({
          id: rule.id,
          updateInviteRuleRequestBody: {
            invitees: {
              users: userParams,
              emails: emailParamsFiltered,
            },
            condition_groups: formData.condition_groups
              ? conditionGroupsToGroupPayloads(formData.condition_groups)
              : [],
            expressions: (formData.expressions ?? []).map(expressionToPayload),
          },
        });
      } else {
        await client.debriefsCreateInviteRule({
          createInviteRuleRequestBody: {
            invitees: {
              users: userParams,
              emails: emailParamsFiltered,
            },
            condition_groups: formData.condition_groups
              ? conditionGroupsToGroupPayloads(formData.condition_groups)
              : [],
            expressions: (formData.expressions ?? []).map(expressionToPayload),
            should_create_matching_policy:
              formData.should_create_matching_policy,
          },
        });
      }
    },
    {
      setError,
      onSuccess: () => {
        onClose();
      },
    },
  );

  const expressionsMethods = useFieldArray({
    control,
    name: "expressions",
    keyName: "key",
  });

  const expressions = watch("expressions");

  const scopeWithExpressions = addExpressionsToScope(
    incidentScope,
    expressions ?? [],
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={isEditing ? "Edit invitee" : "Add invitee"}
      analyticsTrackingId="create-debrief-invite-rule"
      onClose={onClose}
      genericError={genericError}
      isExtraLarge
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Save"
          cancelButtonText="Cancel"
        />
      }
    >
      <ExpressionsMethodsProvider
        expressionsMethods={expressionsMethods}
        allowAllOfACatalogType
      >
        <ConditionGroupsEditorV2
          label="Conditions"
          formMethods={formMethods}
          name={"condition_groups"}
          conditionLabel={"condition"}
          wrapperClassName={"mt-2 !bg-white"}
          scope={scopeWithExpressions}
          populatedIntroSentence={
            <span className="font-normal text-sm">
              User(s) will be invited when...
            </span>
          }
          emptyIntroSentence={
            <p>
              User(s) will be invited to{" "}
              <span className="font-medium">all debriefs</span>.
            </p>
          }
        />
        {inviteTarget === "users" && (
          <EngineFormElement
            name={`param_bindings.users`}
            resources={resources}
            resourceType="User"
            array
            label="User(s)"
            description="Choose who you would like to be added to the debrief"
            scope={scopeWithExpressions}
            required={true}
            showPlaceholder
            mode="variables_and_expressions"
          />
        )}

        {inviteTarget === "emails" && (
          <EngineFormElement
            name={`param_bindings.emails`}
            resources={resources}
            resourceType="String"
            array
            label="Email(s)"
            description="Add other email addresses, such as user groups, that you would like to be added to the debrief"
            scope={incidentScope}
            required={true}
            showPlaceholder
            mode="variables_and_expressions"
          />
        )}

        <Button
          analyticsTrackingId={"debrief-invite-rule-switch-mode"}
          onClick={toggleMode}
          theme={ButtonTheme.Naked}
          icon={IconEnum.SwitchHorizontal}
          iconProps={{ size: IconSize.Medium, className: "!mr-0.5" }}
        >
          {inviteTarget === "emails"
            ? "Choose user(s) instead"
            : "Enter email(s) instead"}
        </Button>

        {!isEditing && (
          <div className="space-y-2">
            <Form.Label htmlFor="">Policies</Form.Label>
            <CheckboxV2
              name={"should_create_matching_policy"}
              formMethods={formMethods}
              helptext={
                "If it's critical for this person to be invited, create a policy which notifies the debrief organiser if they are not invited to the event."
              }
              label={"Create a matching policy for this invitee"}
              disabled={referencesInUse} // Policies don't support references
            />
            {getValues("should_create_matching_policy") && (
              <Callout theme={CalloutTheme.Plain}>
                This policy will only be applied to currently open and future
                incidents
              </Callout>
            )}
          </div>
        )}
      </ExpressionsMethodsProvider>
    </Form.Modal>
  );
};
