/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageSubPagePayload
 */
export interface StatusPageSubPagePayload {
    /**
     * The catalog entry of the parent page's split-by type, which defines which incidents are relevant to this subpage.
     * @type {string}
     * @memberof StatusPageSubPagePayload
     */
    defined_by_catalog_entry_id: string;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPageSubPagePayload
     */
    name: string;
    /**
     * The subpath of within the parent status page
     * @type {string}
     * @memberof StatusPageSubPagePayload
     */
    subpath: string;
}

export function StatusPageSubPagePayloadFromJSON(json: any): StatusPageSubPagePayload {
    return StatusPageSubPagePayloadFromJSONTyped(json, false);
}

export function StatusPageSubPagePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageSubPagePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defined_by_catalog_entry_id': json['defined_by_catalog_entry_id'],
        'name': json['name'],
        'subpath': json['subpath'],
    };
}

export function StatusPageSubPagePayloadToJSON(value?: StatusPageSubPagePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defined_by_catalog_entry_id': value.defined_by_catalog_entry_id,
        'name': value.name,
        'subpath': value.subpath,
    };
}

