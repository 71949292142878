import {
  EngineParamBinding,
  Expression,
  ExpressionBranch,
  ExpressionElseBranch,
  ExpressionOperationOperationTypeEnum,
  RequirementsTemplate,
} from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { InterpolatedRef } from "@incident-shared/forms/v1/TemplatedText";
import { Badge, BadgeTheme, ContentBox } from "@incident-ui";
import { EnrichedScope } from "src/utils/scope";

import { PolicyCapsTxt } from "./PolicyEditableSection";

export const PolicySLAExpressionView = ({
  reference,
  scope,
  slaTimestampName,
  mini = false,
  expressions,
  matchingTemplate,
}: {
  reference: string;
  scope: EnrichedScope<InterpolatedRef>;
  slaTimestampName: string;
  mini?: boolean;
  expressions: Expression[];
  matchingTemplate?: RequirementsTemplate;
}) => {
  const expression = expressions.find((expr) =>
    reference.includes(expr.reference),
  );

  if (!expression) {
    return <Badge theme={BadgeTheme.Error} label="Expression not found" />;
  }

  // We have special cased the display of an if/else expression here. If it's a query
  // expression, use the standard view.
  const branches = expression.operations[0].branches;

  const isSingleBranchesOperation =
    expression.operations[0].operation_type ===
      ExpressionOperationOperationTypeEnum.Branches &&
    expression.operations.length === 1 &&
    !!branches;

  if (!isSingleBranchesOperation) {
    return <ViewExpression scope={scope} expression={expression} />;
  }

  return (
    <ContentBox>
      <div
        className="grid items-center py-3 gap-2"
        style={{ gridTemplateColumns: `min-content 1fr` }}
      >
        {branches.branches.map((branch, index) => (
          <ViewSLAExpressionBranch
            key={index}
            branch={branch}
            branchType={index === 0 ? "if" : "else-if"}
            scope={scope}
            slaTimestampName={slaTimestampName}
            mini={mini}
            matchingTemplate={matchingTemplate}
          />
        ))}
        <div className="col-span-2 w-full border-t my-1 border-stroke" />
        <ElseBranch
          slaTimestampName={slaTimestampName}
          branch={expression.else_branch}
          scope={scope}
          mini={mini}
        />
      </div>
    </ContentBox>
  );
};

const ViewSLAExpressionBranch = ({
  branch,
  branchType,
  scope,
  slaTimestampName,
  mini,
  matchingTemplate,
}: {
  branch: ExpressionBranch;
  branchType: "if" | "else-if";
  slaTimestampName: string;
  scope: EnrichedScope<InterpolatedRef>;
  mini: boolean;
  matchingTemplate?: RequirementsTemplate;
}) => {
  const typeToIntroText = {
    if: "If",
    "else-if": "Else if",
  };

  return (
    <>
      {branchType === "if" ? null : (
        <div className="col-span-2 w-full border-t my-1 border-stroke border-dashed" />
      )}
      <BranchRow
        intro={typeToIntroText[branchType]}
        content={
          <ConditionGroupsList
            className="p-0"
            groups={branch.condition_groups}
            iconless
            boxless
            mini={mini}
          />
        }
      />
      <BranchRow
        intro="Then"
        content={
          <SLABinding
            matchingTemplate={matchingTemplate}
            binding={branch.result}
            scope={scope}
            slaTimestampName={slaTimestampName}
            mini={mini}
          />
        }
      />
    </>
  );
};

const ElseBranch = ({
  branch,
  scope,
  slaTimestampName,
  mini,
}: {
  branch?: ExpressionElseBranch;
  scope: EnrichedScope<InterpolatedRef>;
  slaTimestampName: string;
  mini: boolean;
}) => {
  const hasElseBranch = branch?.result && branch?.result.value?.literal;

  return (
    <>
      <BranchRow
        intro="Else"
        content={
          hasElseBranch ? (
            <SLABinding
              binding={branch.result}
              scope={scope}
              slaTimestampName={slaTimestampName}
              mini={mini}
            />
          ) : (
            <EngineLiteralBadge
              noTooltip
              label="Don’t apply policy"
              mini={mini}
            />
          )
        }
      />
    </>
  );
};

const BranchRow = ({
  intro,
  content,
}: {
  intro: React.ReactNode;
  content: React.ReactNode;
}) => {
  return (
    <>
      <PolicyCapsTxt className="text-right w-full pl-3 shrink-0 whitespace-nowrap">
        {intro}
      </PolicyCapsTxt>
      <div className="pr-3">{content}</div>
    </>
  );
};

export const SLABinding = ({
  binding,
  scope,
  slaTimestampName,
  matchingTemplate,
  mini = false,
}: {
  binding: EngineParamBinding;
  scope: EnrichedScope<InterpolatedRef>;
  slaTimestampName: string;
  matchingTemplate?: RequirementsTemplate;
  mini?: boolean;
}) => {
  const resultLiteral = binding.value?.literal;

  if (resultLiteral) {
    const withinPrefix = matchingTemplate
      ? `${matchingTemplate.label} within`
      : "Within";
    const dayOrDays = resultLiteral === "1" ? "day" : "days";
    return (
      <div className="flex items-center gap-1">
        <EngineLiteralBadge
          noTooltip
          label={`${withinPrefix} ${resultLiteral} ${dayOrDays}`}
          mini={mini}
        />
        <PolicyCapsTxt>OF</PolicyCapsTxt>
        <EngineLiteralBadge noTooltip label={slaTimestampName} mini={mini} />
      </div>
    );
  }

  return (
    <EngineBinding
      resourceType="SLADays"
      binding={binding}
      variableScope={scope}
      displayExpressionAs="pill"
      mini={mini}
    />
  );
};
