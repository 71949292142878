/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsServiceNowHandleInstallRequestBody,
    IntegrationsServiceNowHandleInstallRequestBodyFromJSON,
    IntegrationsServiceNowHandleInstallRequestBodyToJSON,
} from '../models';

export interface IntegrationsServiceNowHandleInstallRequest {
    handleInstallRequestBody: IntegrationsServiceNowHandleInstallRequestBody;
}

/**
 * 
 */
export class IntegrationsServiceNowApi extends runtime.BaseAPI {

    /**
     * Handle ServiceNow installation, by validating and storing a set of credentials
     * HandleInstall Integrations - ServiceNow
     */
    async integrationsServiceNowHandleInstallRaw(requestParameters: IntegrationsServiceNowHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.handleInstallRequestBody === null || requestParameters.handleInstallRequestBody === undefined) {
            throw new runtime.RequiredError('handleInstallRequestBody','Required parameter requestParameters.handleInstallRequestBody was null or undefined when calling integrationsServiceNowHandleInstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/servicenow_install`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsServiceNowHandleInstallRequestBodyToJSON(requestParameters.handleInstallRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle ServiceNow installation, by validating and storing a set of credentials
     * HandleInstall Integrations - ServiceNow
     */
    async integrationsServiceNowHandleInstall(requestParameters: IntegrationsServiceNowHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsServiceNowHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Uninstalls the ServiceNow integration, clearing all config we have stored
     * HandleUninstall Integrations - ServiceNow
     */
    async integrationsServiceNowHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/servicenow_install`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uninstalls the ServiceNow integration, clearing all config we have stored
     * HandleUninstall Integrations - ServiceNow
     */
    async integrationsServiceNowHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsServiceNowHandleUninstallRaw(initOverrides);
    }

}
