/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertPulseChannelBinding,
    AlertPulseChannelBindingFromJSON,
    AlertPulseChannelBindingFromJSONTyped,
    AlertPulseChannelBindingToJSON,
} from './AlertPulseChannelBinding';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface AlertPulseConfig
 */
export interface AlertPulseConfig {
    /**
     * The channels that we're going to send this pulse to
     * @type {Array<AlertPulseChannelBinding>}
     * @memberof AlertPulseConfig
     */
    channel_bindings: Array<AlertPulseChannelBinding>;
    /**
     * The expressions used in this template
     * @type {Array<Expression>}
     * @memberof AlertPulseConfig
     */
    expressions: Array<Expression>;
    /**
     * 
     * @type {string}
     * @memberof AlertPulseConfig
     */
    id?: string;
}

export function AlertPulseConfigFromJSON(json: any): AlertPulseConfig {
    return AlertPulseConfigFromJSONTyped(json, false);
}

export function AlertPulseConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertPulseConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel_bindings': ((json['channel_bindings'] as Array<any>).map(AlertPulseChannelBindingFromJSON)),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function AlertPulseConfigToJSON(value?: AlertPulseConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_bindings': ((value.channel_bindings as Array<any>).map(AlertPulseChannelBindingToJSON)),
        'expressions': ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'id': value.id,
    };
}

