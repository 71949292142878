import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type HeaderBannerProps = {
  children: React.ReactNode;
  className?: string;
};

export const HeaderBanner = ({ children, className }: HeaderBannerProps) => {
  return (
    <div
      className={tcx(
        "bg-surface-secondary px-8 py-3 w-full flex items-center text-content-secondary",
        className,
      )}
    >
      {children}
    </div>
  );
};
