/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleMapping,
    RoleMappingFromJSON,
    RoleMappingFromJSONTyped,
    RoleMappingToJSON,
} from './RoleMapping';

/**
 * 
 * @export
 * @interface SCIMShowRoleMappingsResponseBody
 */
export interface SCIMShowRoleMappingsResponseBody {
    /**
     * 
     * @type {Array<RoleMapping>}
     * @memberof SCIMShowRoleMappingsResponseBody
     */
    role_mappings: Array<RoleMapping>;
}

export function SCIMShowRoleMappingsResponseBodyFromJSON(json: any): SCIMShowRoleMappingsResponseBody {
    return SCIMShowRoleMappingsResponseBodyFromJSONTyped(json, false);
}

export function SCIMShowRoleMappingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SCIMShowRoleMappingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role_mappings': ((json['role_mappings'] as Array<any>).map(RoleMappingFromJSON)),
    };
}

export function SCIMShowRoleMappingsResponseBodyToJSON(value?: SCIMShowRoleMappingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role_mappings': ((value.role_mappings as Array<any>).map(RoleMappingToJSON)),
    };
}

