/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemVersionResponseBody
 */
export interface SystemVersionResponseBody {
    /**
     * Commit hash of the API
     * @type {string}
     * @memberof SystemVersionResponseBody
     */
    commit: string;
    /**
     * Migration IDs that are present in this environment
     * @type {Array<string>}
     * @memberof SystemVersionResponseBody
     */
    migrations?: Array<string>;
}

export function SystemVersionResponseBodyFromJSON(json: any): SystemVersionResponseBody {
    return SystemVersionResponseBodyFromJSONTyped(json, false);
}

export function SystemVersionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemVersionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commit': json['commit'],
        'migrations': !exists(json, 'migrations') ? undefined : json['migrations'],
    };
}

export function SystemVersionResponseBodyToJSON(value?: SystemVersionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commit': value.commit,
        'migrations': value.migrations,
    };
}

