import { Form } from "@incident-shared/forms";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  Heading,
  ModalFooter,
  StepsPageContent,
  StepsPageFooter,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ScheduleReport, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { OnCallReport } from "./OnCallReportView/OnCallReportView";
import { dateStrToDisplayStr } from "./pay-configurations/date-time-helpers";
import styles from "./ReportGeneratorPublish.module.scss";
import { TimezoneHelperToolTip } from "./TimezoneHelperToolTip";

export const ReportGeneratorPublish = ({
  report,
  onBack,
}: {
  report: ScheduleReport;
  onBack: () => Promise<void> | void;
}): React.ReactElement => {
  const { identity, hasScope } = useIdentity();
  const [showPublishModal, setShowPublishModal] = useState<boolean>(false);
  return (
    <>
      {showPublishModal && (
        <PublishModal
          report={report}
          onClose={() => setShowPublishModal(false)}
        />
      )}
      <Callout
        className={tcx(styles.callout, "w-full mt-6")}
        theme={CalloutTheme.Plain}
      >
        <div>
          <p className="text-sm">
            <span className="font-semibold">
              This report is currently a draft.
            </span>{" "}
            Publish it in order to export and share it with others.
          </p>
        </div>
      </Callout>
      <StepsPageContent className="!py-6">
        <div className={"text-sm"}>
          <Heading level={2} size="medium" className="mb-4">
            {report.name}
          </Heading>
          <div className="inline-flex">
            <p>
              This report includes shifts between{" "}
              <strong>{dateStrToDisplayStr(report.start_date)}</strong> and{" "}
              <strong>{dateStrToDisplayStr(report.end_date)}</strong>, using the
              timezone set on each respective schedule.{" "}
              <TimezoneHelperToolTip />
            </p>
          </div>
        </div>
      </StepsPageContent>
      <StepsPageContent className="!pt-4">
        <OnCallReport report={report} />
      </StepsPageContent>
      <StepsPageFooter
        // Only let someone go back if they created the report, to avoid problems with missing pay configs.
        onBack={report.creator.id === identity?.user_id ? onBack : undefined}
        continueButtonType="submit"
        continueButtonText="Publish"
        onContinue={() => setShowPublishModal(true)}
        continueDisabled={!hasScope(ScopeNameEnum.ScheduleReportsPublish)}
        continueExplanationText={
          "Only admins can publish reports: share this link with them, and ask them to publish on your behalf."
        }
      />
    </>
  );
};

type PublishModalFormData = {
  cc_emails_str: string;
  send_user_breakdowns: boolean;
};

const PublishModal = ({
  report,
  onClose,
}: {
  report: ScheduleReport;
  onClose: () => void;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const formMethods = useForm<PublishModalFormData>({
    defaultValues: { send_user_breakdowns: false },
  });

  const { setError } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "schedulesShowReport",
    { id: report.id },
    async (apiClient, body: PublishModalFormData) =>
      await apiClient.schedulesPublishReport({
        id: report.id,
        publishReportRequestBody: {
          cc_emails: (body.cc_emails_str || "")
            .split(",")
            .map((email) => email.trim()),
          send_user_breakdowns: body.send_user_breakdowns || false,
        },
      }),
    {
      setError,
      onSuccess: () =>
        navigate(`/on-call/pay-calculator/reports/${report.id}`, {
          replace: true,
        }),
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Publish Report"}
      disableQuickClose
      onClose={onClose}
      analyticsTrackingId={"schedule-publish-report-modal"}
      onSubmit={onSubmit}
      genericError={genericError}
    >
      <p className="text-sm text-slate-700">
        Publishing a report allows you to export or share your report. After
        publishing you will no longer be able to edit the report.
      </p>
      <div>
        <Form.Label htmlFor="send_user_breakdowns" className="block mb-2">
          Email notifications
        </Form.Label>
        <CheckboxV2
          formMethods={formMethods}
          name={"send_user_breakdowns"}
          label={"Email an individual pay breakdown to each payee"}
          defaultChecked={false}
        />
      </div>
      <TextareaV2
        formMethods={formMethods}
        label="Additional recipients"
        name="cc_emails_str"
        helptext="Add a comma separated list of email addresses here"
        placeholder="billing@example.com"
      />
      <ModalFooter
        onClose={onClose}
        confirmButtonType="submit"
        confirmButtonText="Publish"
        saving={saving}
      />
    </Form.Modal>
  );
};
