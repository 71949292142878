/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIAssistantThreadMessage
 */
export interface AIAssistantThreadMessage {
    /**
     * Who sent the message.
     * @type {string}
     * @memberof AIAssistantThreadMessage
     */
    author: AIAssistantThreadMessageAuthorEnum;
    /**
     * Natural language contents of the message.
     * @type {Array<string>}
     * @memberof AIAssistantThreadMessage
     */
    content: Array<string>;
    /**
     * Type of message that was received.
     * @type {string}
     * @memberof AIAssistantThreadMessage
     */
    content_type: string;
    /**
     * When the message was created and added to the thread.
     * @type {Date}
     * @memberof AIAssistantThreadMessage
     */
    created_at: Date;
    /**
     * Internal ID of the message.
     * @type {string}
     * @memberof AIAssistantThreadMessage
     */
    id: string;
    /**
     * List of public presigned URLs to images that were generated alongside the text response.
     * @type {Array<string>}
     * @memberof AIAssistantThreadMessage
     */
    image_presigned_urls?: Array<string>;
    /**
     * If this is a response this is the ID of the question that was asked.
     * @type {string}
     * @memberof AIAssistantThreadMessage
     */
    question_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum AIAssistantThreadMessageAuthorEnum {
    User = 'user',
    Assistant = 'assistant'
}

export function AIAssistantThreadMessageFromJSON(json: any): AIAssistantThreadMessage {
    return AIAssistantThreadMessageFromJSONTyped(json, false);
}

export function AIAssistantThreadMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIAssistantThreadMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': json['author'],
        'content': json['content'],
        'content_type': json['content_type'],
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'image_presigned_urls': !exists(json, 'image_presigned_urls') ? undefined : json['image_presigned_urls'],
        'question_id': !exists(json, 'question_id') ? undefined : json['question_id'],
    };
}

export function AIAssistantThreadMessageToJSON(value?: AIAssistantThreadMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'content': value.content,
        'content_type': value.content_type,
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'image_presigned_urls': value.image_presigned_urls,
        'question_id': value.question_id,
    };
}

