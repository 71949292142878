/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Action,
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * 
 * @export
 * @interface ActionsCreateResponseBody
 */
export interface ActionsCreateResponseBody {
    /**
     * 
     * @type {Action}
     * @memberof ActionsCreateResponseBody
     */
    action: Action;
}

export function ActionsCreateResponseBodyFromJSON(json: any): ActionsCreateResponseBody {
    return ActionsCreateResponseBodyFromJSONTyped(json, false);
}

export function ActionsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': ActionFromJSON(json['action']),
    };
}

export function ActionsCreateResponseBodyToJSON(value?: ActionsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': ActionToJSON(value.action),
    };
}

