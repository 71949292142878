import {
  SeatDescription,
  SeatDescriptionTypeEnum,
  UsersUpdateSeatAndRoleRequestBodySeatTypesEnum,
  UserWithRoles,
} from "@incident-io/api";
import { UpgradeRequiredMessage } from "@incident-shared/billing";
import { DependentResourceLink } from "@incident-shared/engine/DependentResourceList";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  ConfirmationDialog,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Spinner,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { singular } from "pluralize";
import React, { useCallback, useState } from "react";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { useProductAccess } from "../../../../hooks/useProductAccess";
import { useAPI } from "../../../../utils/swr";
import { tcx } from "../../../../utils/tailwind-classes";
import {
  getDefaultUserValues,
  useUserEditFormMethods,
} from "../common/useUserEditFormMethods";
import { upgradePropsFor } from "./SeatSelect";
import { useOnCallSeatDependentResources } from "./useOnCallSeatDependentResources";
import { isOnCallUser, isResponseUser } from "./utils";

export const UserSeatDropdown = ({
  user,
  seat,
  refetchUsers,
}: {
  user: UserWithRoles;
  seat: SeatDescriptionTypeEnum;
  refetchUsers: () => Promise<void>;
}) => {
  const { hasOnCall, hasResponse } = useProductAccess();
  const showToast = useToast();
  const [showConfirmUpgradeModal, setShowConfirmUpgradeModal] = useState(false);

  let currentlyHasAccess = false;

  let isButtonDisabled = false;
  let disabledReason: string | undefined;
  if (seat === SeatDescriptionTypeEnum.OnCall) {
    isButtonDisabled = !hasOnCall;
    disabledReason =
      "Your organisation does not currently have access to on-call";
    currentlyHasAccess = isOnCallUser(user.state);
  }
  if (seat === SeatDescriptionTypeEnum.Responder) {
    isButtonDisabled = !hasResponse;
    disabledReason =
      "Your organisation does not currently have access to response";
    currentlyHasAccess = isResponseUser(user.state);
  }

  const onSave = useCallback(() => {
    showToast({
      title: "User updated",
      description: `Access for ${user.name} has been updated`,
      theme: ToastTheme.Success,
    });
  }, [showToast, user.name]);

  const { onSubmit, saving, genericError } = useUserEditFormMethods(
    user,
    onSave,
    refetchUsers,
  );

  const doUpgrade = useCallback(() => {
    const existingUser = { ...getDefaultUserValues(user) };
    onSubmit({
      ...existingUser,
      seat_types: [
        ...existingUser.seat_types.map(
          (s) => s as unknown as UsersUpdateSeatAndRoleRequestBodySeatTypesEnum,
        ),
        seat as unknown as UsersUpdateSeatAndRoleRequestBodySeatTypesEnum,
      ],
    });
  }, [seat, user, onSubmit]);

  const doDowngrade = useCallback(() => {
    const existingUser = { ...getDefaultUserValues(user) };
    onSubmit({
      ...existingUser,
      seat_types: existingUser.seat_types
        .filter((st) => (st as unknown as SeatDescriptionTypeEnum) !== seat)
        .map(
          (s) => s as unknown as UsersUpdateSeatAndRoleRequestBodySeatTypesEnum,
        ),
    });
  }, [seat, user, onSubmit]);

  if (genericError) {
    throw genericError;
  }

  if (saving) {
    return <Spinner />;
  }

  return (
    <>
      <DropdownMenu
        side="bottom"
        scroll={false}
        triggerButton={
          <GatedButton
            disabledTooltipContent={disabledReason}
            analyticsTrackingId="on-call-seat"
            theme={ButtonTheme.Naked}
            disabled={isButtonDisabled}
          >
            <span>{currentlyHasAccess ? "Full access" : "Viewer only"}</span>
            <Icon
              id={IconEnum.ChevronDown}
              size={IconSize.Small}
              className={tcx("shrink-0")}
            />
          </GatedButton>
        }
      >
        <UserSeatDropdownContent
          user={user}
          seat={seat}
          currentlyHasAccess={currentlyHasAccess}
          doUpgrade={() => setShowConfirmUpgradeModal(true)}
          doDowngrade={doDowngrade}
        />
      </DropdownMenu>
      {showConfirmUpgradeModal && (
        <ConfirmationDialog
          onCancel={() => setShowConfirmUpgradeModal(false)}
          title={`Give ${user.name} full access to ${
            seat === SeatDescriptionTypeEnum.OnCall ? "On-call" : "Response"
          }?`}
          isOpen
          onConfirm={() => {
            setShowConfirmUpgradeModal(false);
            doUpgrade();
          }}
        >
          <p>
            This will add them to your quota of{" "}
            {seat === SeatDescriptionTypeEnum.OnCall ? "on-call" : "response"}{" "}
            seats and may impact your monthly bill.
          </p>
        </ConfirmationDialog>
      )}
    </>
  );
};

const UserSeatDropdownContent = ({
  user,
  currentlyHasAccess,
  seat,
  doUpgrade,
  doDowngrade,
}: {
  user: UserWithRoles;
  currentlyHasAccess;
  seat: SeatDescriptionTypeEnum;
  doUpgrade: () => void;
  doDowngrade: () => void;
}) => {
  const { data: seatData, isLoading } = useAPI(
    "usersListAvailableSeatsAndRoles",
    {
      userId: user.id,
    },
  );

  const { dependentResources, oncResourcesLoading } =
    useOnCallSeatDependentResources({ user });

  const seatDescription: SeatDescription | undefined = seatData?.seats.find(
    (s: SeatDescription) => s.type === seat,
  );

  const hasDependentResources = dependentResources.length > 0;

  const { identity } = useIdentity();
  let upgradeDisabled = false;
  let upgradeDisabledTooltipContent: React.ReactNode = undefined;
  let downgradeDisabled = false;
  let downgradeDisabledTooltipContent: React.ReactNode = undefined;

  if (currentlyHasAccess && seat === SeatDescriptionTypeEnum.OnCall) {
    downgradeDisabled = hasDependentResources;
    const resourcesToShow = dependentResources.slice(0, 5);
    downgradeDisabledTooltipContent = downgradeDisabled && (
      <div>
        <span>You can&apos;t downgrade this user, since they are on-call:</span>
        <ul className="list-disc">
          {resourcesToShow.map((resource) => (
            <li key={resource.id} className="ml-4">
              <DependentResourceLink resource={resource}>
                {singular(resource.resource_type_label)}: {resource.label}
              </DependentResourceLink>
            </li>
          ))}
          {resourcesToShow.length < dependentResources.length && (
            <li className="ml-4">
              <span>
                and {dependentResources.length - resourcesToShow.length} more
              </span>
            </li>
          )}
        </ul>
      </div>
    );
  } else if (!currentlyHasAccess) {
    upgradeDisabled =
      !seatDescription?.can_assign || seatDescription?.upgrade_required;
    upgradeDisabledTooltipContent = seatDescription?.upgrade_required ? (
      <UpgradeRequiredMessage {...upgradePropsFor[seat](identity)} />
    ) : !seatDescription?.can_assign ? (
      seat === SeatDescriptionTypeEnum.Viewer ? (
        "This person has been assigned a role that requires them to be a responder."
      ) : (
        `You don't have permission to give this person a${
          seat === SeatDescriptionTypeEnum.OnCall ? "n" : ""
        } ${seatDescription?.name.toLowerCase()} seat. This requires a billing admin.`
      )
    ) : undefined;
  }

  if (isLoading || oncResourcesLoading) {
    return (
      <div className={"p-4 w-full flex items-center justify-center"}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <DropdownMenuItem
        analyticsTrackingId={null}
        label={""}
        disabled={downgradeDisabled}
        tooltipContent={downgradeDisabledTooltipContent}
        onSelect={() => {
          if (!currentlyHasAccess) {
            return;
          }

          doDowngrade();
        }}
      >
        <div className={"w-full flex flex-row justify-between items-center"}>
          <span>Viewer only</span>
          {!currentlyHasAccess && (
            <Icon id={IconEnum.Tick} size={IconSize.Small} />
          )}
        </div>
      </DropdownMenuItem>
      <DropdownMenuItem
        analyticsTrackingId={null}
        label={""}
        disabled={upgradeDisabled}
        tooltipContent={upgradeDisabledTooltipContent}
        onSelect={() => {
          if (currentlyHasAccess) {
            return;
          }

          doUpgrade();
        }}
      >
        <div className={"w-full flex flex-row justify-between items-center"}>
          <span>Full access</span>
          {currentlyHasAccess && (
            <Icon id={IconEnum.Tick} size={IconSize.Small} />
          )}
        </div>
      </DropdownMenuItem>
    </>
  );
};
