/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Feedback,
    FeedbackFromJSON,
    FeedbackFromJSONTyped,
    FeedbackToJSON,
} from './Feedback';

/**
 * 
 * @export
 * @interface IncidentsListFeedbackResponseBody
 */
export interface IncidentsListFeedbackResponseBody {
    /**
     * 
     * @type {Array<Feedback>}
     * @memberof IncidentsListFeedbackResponseBody
     */
    feedbacks: Array<Feedback>;
}

export function IncidentsListFeedbackResponseBodyFromJSON(json: any): IncidentsListFeedbackResponseBody {
    return IncidentsListFeedbackResponseBodyFromJSONTyped(json, false);
}

export function IncidentsListFeedbackResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsListFeedbackResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbacks': ((json['feedbacks'] as Array<any>).map(FeedbackFromJSON)),
    };
}

export function IncidentsListFeedbackResponseBodyToJSON(value?: IncidentsListFeedbackResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbacks': ((value.feedbacks as Array<any>).map(FeedbackToJSON)),
    };
}

