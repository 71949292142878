import {
  Investigation,
  InvestigationCheck,
  useAiStaffServiceAiStaffShowInvestigation,
} from "@incident-io/query-api";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  Callout,
  CalloutTheme,
  ContentBox,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  Markdown,
  StackedList,
  TabPane,
  TabSection,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { Mrkdwn } from "@incident-ui/Markdown/Mrkdwn";
import { useState } from "react";
import { useParams } from "react-router";

import { CopilotInteractionTrace } from "./CopilotInteractionTrace";
import { JsonViewer } from "./JsonViewer";

export const AIEvalsInvestigationPage = () => {
  const { investigationId } = useParams<{ investigationId: string }>();
  const navigate = useOrgAwareNavigate();

  const { data, isLoading } = useAiStaffServiceAiStaffShowInvestigation({
    id: investigationId || "",
  });

  if (!investigationId) {
    navigate("/ai-evals");
    return null;
  }

  return (
    <PageWrapper
      icon={IconEnum.Robot}
      width={PageWidth.Wide}
      title="AI evals: Investigation"
      backHref="/ai-evals"
      accessory={
        <Button
          href={`/incidents/${data?.investigation.incident_external_id}`}
          icon={IconEnum.ExternalLink}
          analyticsTrackingId={null}
          openInNewTab
        >
          Incident
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading}>
        <div className="flex flex-col gap-4">
          {data?.investigation && (
            <>
              <TopLevelInfo investigation={data?.investigation} />
              <div className="text-2xl-bold">Checks</div>
              <InvestigationChecks checks={data?.investigation?.checks || []} />
            </>
          )}
        </div>
      </LoadingWrapper>
    </PageWrapper>
  );
};

const TopLevelInfo = ({ investigation }: { investigation: Investigation }) => {
  if (!investigation) {
    return null;
  }

  return (
    <ContentBox className="p-4 flex flex-col gap-2">
      <LabeledValue
        label="ID"
        value={<SingleLineCodeBlock code={investigation.id} />}
      />
      <LabeledValue
        label="Cost"
        value={"$" + (investigation.total_cost_in_cents / 100).toFixed(2)}
      />
      <LabeledValue
        label="Incident"
        value={"INC-" + investigation.incident_external_id}
      />
      <LabeledValue label="State" value={investigation.state} />
      <LabeledValue
        label="Findings"
        value={
          <div className="whitespace-pre-wrap">
            <Mrkdwn text={investigation.findings} />
          </div>
        }
      />
    </ContentBox>
  );
};

const InvestigationChecks = ({ checks }: { checks: InvestigationCheck[] }) => {
  return (
    <StackedList>
      {checks.map((check) => (
        <SingleCheck key={check.id} check={check} />
      ))}
    </StackedList>
  );
};

const SingleCheck = ({ check }: { check: InvestigationCheck }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded((expanded) => !expanded);

  return (
    <div className="flex flex-col px-4" key={check.id}>
      <div
        className="flex flex-between gap-2 w-full cursor-pointer group"
        onClick={toggle}
      >
        <div className="flex gap-6 py-4">
          <LocalDateTime timestamp={check.created_at} className="font-medium" />
          <div className="flex">
            <Icon
              id={IconEnum.Robot}
              className="w-6 h-6 p-1 mr-1 rounded-full bg-slate-50  "
            />
            <div className="whitespace-pre-wrap">{check.title}</div>
          </div>
        </div>
        <div className="grow" />
        {!!check.duration_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />
            {(check.total_cost_in_cents / 100).toFixed(2)}$
          </div>
        )}
        {!!check.duration_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Timer} size={IconSize.Small} />
            {check.duration_seconds.toFixed(2)}s
          </div>
        )}
        <Icon
          id={expanded ? IconEnum.Collapse : IconEnum.Expand}
          className="text-slate-600 group-hover:text-slate-900 transition"
        />
      </div>
      {/* Expandable Section */}
      {expanded && (
        <div className="flex flex-col gap-4">
          <CopilotInteractionTrace requests={check.ai_requests} />
          <hr />
          {check.error_message && (
            <Callout
              theme={CalloutTheme.Danger}
              title="Error"
              subtitle={check.error_message}
            />
          )}
          <TabSection
            withIndicator
            defaultTab="rendered"
            tabs={[
              {
                id: "rendered",
                label: "Rendered (user facing)",
              },
              {
                id: "json",
                label: "JSON Result (internal)",
              },
            ]}
          >
            <TabPane tabId="rendered">
              <div className="py-6">
                {check.rendered ? (
                  <Markdown>{check.rendered}</Markdown>
                ) : (
                  <EmptyState content="No result" />
                )}
              </div>
            </TabPane>
            <TabPane tabId="json">
              {check.result ? (
                <JsonViewer jsonStr={check.result} />
              ) : (
                <EmptyState content="No result" />
              )}
            </TabPane>
          </TabSection>
        </div>
      )}
    </div>
  );
};

const LabeledValue = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <div className="w-full flex items-start gap-4">
      <div className="text-content-tertiary w-20 shrink-0 font-medium">
        {label}
      </div>
      <div className="text-content-primary">{value}</div>
    </div>
  );
};
