import {
  fetchTypeaheadOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter, ToastTheme } from "@incident-ui";
import { SelectOption, SelectOptions } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/react";
import { sortBy } from "lodash";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ClientType,
  StatusPageIncident,
  useClient,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

type LinkIncidentData = {
  incidentIds: string[];
};

export const IncidentLinkCreateModal = ({
  onClose,
  incident,
}: {
  onClose: () => void;
  incident: StatusPageIncident;
}) => {
  const apiClient = useClient();
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();

  const formMethods = useForm<LinkIncidentData>({
    defaultValues: {
      incidentIds: incident.linked_response_incidents.map((inc) => inc.id),
    },
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient, data: LinkIncidentData) => {
      const res = await apiClient.statusPageAddResponseIncidentLinks({
        id: incident.id,
        addResponseIncidentLinksRequestBody: {
          incident_ids: data.incidentIds,
        },
      });

      navigate(
        `/status-pages/${res.status_page_incident.status_page_id}/incident/${res.status_page_incident.id}`,
      );

      return;
    },
    {
      onSuccess: () => {
        showToast({
          title: "Link successfully added",
          theme: ToastTheme.Success,
        });
        onClose();
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: "Could not add incident link",
          theme: ToastTheme.Error,
        });
        setError;
      },
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={trigger}
      onClose={onClose}
      disableQuickClose={false}
      genericError={genericError}
      title="Link an internal incident"
      analyticsTrackingId="link-internal-incident-modal"
      footer={
        <ModalFooter
          onClose={onClose}
          saving={isMutating}
          disabled={!isDirty}
          confirmButtonType="submit"
        />
      }
      contentClassName="text-sm space-y-2"
    >
      <>
        <p>
          Choose one or more incidents this status page incident is related to.
        </p>
        <DynamicMultiSelectV2
          formMethods={formMethods}
          name="incidentIds"
          loadOptions={getTypeaheadOptions(
            apiClient,
            TypeaheadTypeEnum.Incident,
          )}
          hydrateOptions={hydrateInitialSelectOptions(
            apiClient,
            TypeaheadTypeEnum.Incident,
          )}
          placeholder={"Start typing or select from the dropdown"}
        />
      </>
    </Form.Modal>
  );
};

export function getTypeaheadOptions(
  apiClient: ClientType,
  typeaheadType: TypeaheadTypeEnum,
  options: { incidentId?: string; sortKey?: string } = {},
) {
  return async function (inputValue: string): Promise<SelectOptions> {
    try {
      const typeaheadOptions = await fetchTypeaheadOptions(
        apiClient,
        typeaheadType,
        inputValue,
        options.incidentId,
      );

      if (!typeaheadOptions) return [];

      if (typeaheadOptions.options?.length > 0) {
        return sortBy(typeaheadOptions.options, options.sortKey ?? "sort_key");
      }

      if (typeaheadOptions.option_groups?.length > 0) {
        return typeaheadOptions.option_groups;
      }

      return [];
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
}

export function hydrateInitialSelectOptions(
  apiClient: ClientType,
  typeaheadType: TypeaheadTypeEnum,
) {
  return async (initialValue: string | string[]): Promise<SelectOption[]> => {
    try {
      const { options } = await apiClient.typeaheadsList({
        typeaheadType: typeaheadType,
        idList: Array.isArray(initialValue) ? initialValue : [initialValue],
      });
      return options as SelectOption[];
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
}
