"use-client";

import { DialogContent, DialogOverlay } from "@reach/dialog";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { useTranslations } from "../../use-translations";
import { Button, ButtonTheme } from "../Button/Button";
import { withSentryErrorBoundary } from "../Error/ErrorBoundary";
import { CloseIcon } from "../Icons/CloseIcon";
import { Spinner } from "../Spinner/Spinner";
import styles from "./Modal.module.scss";

export type FormProps =
  | {
      as: "form";
      onSubmit: () => void;
      submitButtonText?: string;
      disabled?: boolean;
      saving?: boolean;
    }
  | { as?: "div"; onSubmit?: never; submitButtonText?: never };

export type ModalProps = {
  /* Whether or not to render the modal. You may wish to default this to true and render the entire modal conditionally. */
  isOpen: boolean;
  /* The modal content */
  children?: React.ReactNode;
  /* The title which is read out for assistive technology. You must provide this even if you're not using a header. */
  title: string;
  /* Optional styles for the modal container */
  className?: string;
  /* Whether to render a <Loader/> while waiting for the modal to be ready */
  loading?: boolean;
  onClose: () => void;
  isLarge?: boolean;
  hasTabs?: boolean;
  analyticsTrackingId: string | null;
} & FormProps;

export const Modal = (props: ModalProps): React.ReactElement => {
  const { title, as = "div", onSubmit } = props;
  const onSubmitProp = as === "form" ? { onSubmit } : {};

  return (
    <AnimatePresence>
      <MotionDialogOverlay
        as="div"
        isOpen
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        onDismiss={() => {
          return;
        }}
        className={cx(
          "absolute z-[99]",
          "bg-surface-invert/[0.05]",
          "dark:bg-slate-900/70",
          styles.overlay,
        )}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.1,
          }}
        >
          <DialogContent
            as={as}
            aria-label={title}
            {...onSubmitProp}
            className={cx(
              styles.dialogContent,
              "bg-modal drop-shadow-lg dark:border dark:border-slate-700 rounded-lg",
              "!max-w-[624px] min-w-[90vw] md:!min-w-[624px]",
            )}
          >
            <ModalContents {...props} />
          </DialogContent>
        </motion.div>
      </MotionDialogOverlay>
    </AnimatePresence>
  );
};

const ModalContents = withSentryErrorBoundary(
  ({
    title,
    onClose,
    onSubmit,
    submitButtonText,
    hasTabs,
    loading,
    isLarge,
    analyticsTrackingId,
    children,
  }: ModalProps) => {
    const t = useTranslations("Modal");
    return (
      <>
        <div
          className={cx(
            styles.modalHeader,
            "p-4 border-b",
            "border-stroke dark:border-slate-700",
          )}
        >
          <h3 className="text-content-primary dark:text-slate-50 text-lg font-medium">
            {title}
          </h3>

          <button
            className={cx(styles.closeButton, "h-6 w-6")}
            title={t("close")}
            type="button"
            tabIndex={100}
            onClick={onClose}
          >
            <CloseIcon className="w-100 h-100" />
          </button>
        </div>
        <div
          className={cx(
            "px-4",
            "text-content-primary dark:text-slate-50",
            { "min-h-[300px]": isLarge },
            hasTabs ? "py-2" : "py-4",
            "flex flex-col",
          )}
        >
          <>{children}</>
        </div>
        {
          <div
            className={cx(
              "flex flex-row !justify-end w-full items-center space-x-2 p-4 border-t",
              "border-stroke",
              "dark:border-slate-700",
            )}
          >
            {onSubmit ? (
              <Button
                title={submitButtonText || t("confirm")}
                onClick={() => onSubmit()}
                disabled={loading}
                theme={ButtonTheme.Modal}
                analyticsTrackingId={`${analyticsTrackingId}-modal-confirm`}
              >
                {!loading ? (
                  submitButtonText || t("confirm")
                ) : (
                  <Spinner containerClassName="py-0.5" />
                )}
              </Button>
            ) : (
              <Button
                title={t("close")}
                onClick={() => onClose()}
                theme={ButtonTheme.Modal}
                analyticsTrackingId={`${analyticsTrackingId}-modal-close`}
              >
                {t("close")}
              </Button>
            )}
          </div>
        }
      </>
    );
  },
  "ModalContents",
);

const MotionDialogOverlay = motion(DialogOverlay);
