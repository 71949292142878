import { DateTime } from "luxon";

// getCalendarDays gets all the days in the month of the given day, plus any days from the previous and next month
// that are needed to fill out the calendar.
export function getCalendarDays(day: DateTime, timezone: string): DateTime[] {
  const firstDayOfMonth = DateTime.fromObject({
    day: 1,
    month: day.month,
    year: day.year,
  });

  firstDayOfMonth.setZone(timezone);

  // First, make an array of all the days in the month
  const days: DateTime[] = Array.from(
    { length: firstDayOfMonth.daysInMonth },
    (_, i) => firstDayOfMonth.plus({ days: i }),
  );

  // Then, while the first item is not a Sunday, add days from the previous month
  while (days[0].weekday !== 7) {
    days.unshift(days[0].minus({ days: 1 }));
  }

  // Then add any days from the next month up until we hit saturday
  while (days[days.length - 1].weekday !== 6) {
    days.push(days[days.length - 1].plus({ days: 1 }));
  }

  return days;
}
