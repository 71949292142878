/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HolidayUserEntry
 */
export interface HolidayUserEntry {
    /**
     * Description of the holiday
     * @type {string}
     * @memberof HolidayUserEntry
     */
    description?: string;
    /**
     * When does this holiday user entry last until
     * @type {Date}
     * @memberof HolidayUserEntry
     */
    end_at: Date;
    /**
     * ID of the feed this holiday came from
     * @type {string}
     * @memberof HolidayUserEntry
     */
    feed_id: string;
    /**
     * Name of the holiday
     * @type {string}
     * @memberof HolidayUserEntry
     */
    name: string;
    /**
     * When does this holiday user entry start from
     * @type {Date}
     * @memberof HolidayUserEntry
     */
    start_at: Date;
    /**
     * ID of the user this holiday entry is for
     * @type {string}
     * @memberof HolidayUserEntry
     */
    user_id: string;
}

export function HolidayUserEntryFromJSON(json: any): HolidayUserEntry {
    return HolidayUserEntryFromJSONTyped(json, false);
}

export function HolidayUserEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidayUserEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'end_at': (new Date(json['end_at'])),
        'feed_id': json['feed_id'],
        'name': json['name'],
        'start_at': (new Date(json['start_at'])),
        'user_id': json['user_id'],
    };
}

export function HolidayUserEntryToJSON(value?: HolidayUserEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'end_at': (value.end_at.toISOString()),
        'feed_id': value.feed_id,
        'name': value.name,
        'start_at': (value.start_at.toISOString()),
        'user_id': value.user_id,
    };
}

