/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentCallSettingsGetCallProvidersResponseBody,
    IncidentCallSettingsGetCallProvidersResponseBodyFromJSON,
    IncidentCallSettingsGetCallProvidersResponseBodyToJSON,
    IncidentCallSettingsShowResponseBody,
    IncidentCallSettingsShowResponseBodyFromJSON,
    IncidentCallSettingsShowResponseBodyToJSON,
    IncidentCallSettingsUpdateAutoCallRequestBody,
    IncidentCallSettingsUpdateAutoCallRequestBodyFromJSON,
    IncidentCallSettingsUpdateAutoCallRequestBodyToJSON,
    IncidentCallSettingsUpdateAutoCallResponseBody,
    IncidentCallSettingsUpdateAutoCallResponseBodyFromJSON,
    IncidentCallSettingsUpdateAutoCallResponseBodyToJSON,
    IncidentCallSettingsUpdateDefaultCallURLRequestBody,
    IncidentCallSettingsUpdateDefaultCallURLRequestBodyFromJSON,
    IncidentCallSettingsUpdateDefaultCallURLRequestBodyToJSON,
    IncidentCallSettingsUpdateDefaultCallURLResponseBody,
    IncidentCallSettingsUpdateDefaultCallURLResponseBodyFromJSON,
    IncidentCallSettingsUpdateDefaultCallURLResponseBodyToJSON,
} from '../models';

export interface IncidentCallSettingsUpdateAutoCallRequest {
    updateAutoCallRequestBody: IncidentCallSettingsUpdateAutoCallRequestBody;
}

export interface IncidentCallSettingsUpdateDefaultCallURLRequest {
    updateDefaultCallURLRequestBody: IncidentCallSettingsUpdateDefaultCallURLRequestBody;
}

/**
 * 
 */
export class IncidentCallSettingsApi extends runtime.BaseAPI {

    /**
     * Get the available call providers
     * GetCallProviders IncidentCallSettings
     */
    async incidentCallSettingsGetCallProvidersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallSettingsGetCallProvidersResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_calls/settings/providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallSettingsGetCallProvidersResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the available call providers
     * GetCallProviders IncidentCallSettings
     */
    async incidentCallSettingsGetCallProviders(initOverrides?: RequestInit): Promise<IncidentCallSettingsGetCallProvidersResponseBody> {
        const response = await this.incidentCallSettingsGetCallProvidersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the incident call settings
     * Show IncidentCallSettings
     */
    async incidentCallSettingsShowRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallSettingsShowResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_calls/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallSettingsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the incident call settings
     * Show IncidentCallSettings
     */
    async incidentCallSettingsShow(initOverrides?: RequestInit): Promise<IncidentCallSettingsShowResponseBody> {
        const response = await this.incidentCallSettingsShowRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update auto call settings
     * UpdateAutoCall IncidentCallSettings
     */
    async incidentCallSettingsUpdateAutoCallRaw(requestParameters: IncidentCallSettingsUpdateAutoCallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallSettingsUpdateAutoCallResponseBody>> {
        if (requestParameters.updateAutoCallRequestBody === null || requestParameters.updateAutoCallRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutoCallRequestBody','Required parameter requestParameters.updateAutoCallRequestBody was null or undefined when calling incidentCallSettingsUpdateAutoCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_calls/settings/auto_call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCallSettingsUpdateAutoCallRequestBodyToJSON(requestParameters.updateAutoCallRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallSettingsUpdateAutoCallResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update auto call settings
     * UpdateAutoCall IncidentCallSettings
     */
    async incidentCallSettingsUpdateAutoCall(requestParameters: IncidentCallSettingsUpdateAutoCallRequest, initOverrides?: RequestInit): Promise<IncidentCallSettingsUpdateAutoCallResponseBody> {
        const response = await this.incidentCallSettingsUpdateAutoCallRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Default Call URL Settings
     * UpdateDefaultCallURL IncidentCallSettings
     */
    async incidentCallSettingsUpdateDefaultCallURLRaw(requestParameters: IncidentCallSettingsUpdateDefaultCallURLRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallSettingsUpdateDefaultCallURLResponseBody>> {
        if (requestParameters.updateDefaultCallURLRequestBody === null || requestParameters.updateDefaultCallURLRequestBody === undefined) {
            throw new runtime.RequiredError('updateDefaultCallURLRequestBody','Required parameter requestParameters.updateDefaultCallURLRequestBody was null or undefined when calling incidentCallSettingsUpdateDefaultCallURL.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_calls/settings/default_call_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCallSettingsUpdateDefaultCallURLRequestBodyToJSON(requestParameters.updateDefaultCallURLRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallSettingsUpdateDefaultCallURLResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update Default Call URL Settings
     * UpdateDefaultCallURL IncidentCallSettings
     */
    async incidentCallSettingsUpdateDefaultCallURL(requestParameters: IncidentCallSettingsUpdateDefaultCallURLRequest, initOverrides?: RequestInit): Promise<IncidentCallSettingsUpdateDefaultCallURLResponseBody> {
        const response = await this.incidentCallSettingsUpdateDefaultCallURLRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
