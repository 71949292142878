import type { SVGProps } from "react";
import * as React from "react";
const SvgHand = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.906 5.25a1 1 0 0 0-1 1V9.5a.5.5 0 0 1-1 0V4.75a1 1 0 0 0-2 0V9.5a.5.5 0 0 1-1 0v-6a1 1 0 0 0-2 0v6a.5.5 0 0 1-1 0V4.75a1 1 0 0 0-2 0v7.833l-1.688-2.021a1.252 1.252 0 0 0-1.755-.211c-.54.424-.604 1.131-.211 1.755l1.603 2.392c1.273 1.751 2.175 4.501 6.05 4.501h2a5 5 0 0 0 5-5V6.25a1 1 0 0 0-1-1h.001Z"
    />
  </svg>
);
export default SvgHand;
