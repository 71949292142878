import type { SVGProps } from "react";
import * as React from "react";
const SvgNumberOrderedList = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 8.5a.75.75 0 0 0 1.501 0V3.001a.748.748 0 0 0-.554-.72.756.756 0 0 0-.845.344c-.02.034-.496.82-1.459 1.003a.749.749 0 1 0 .281 1.473c.412-.08.769-.222 1.076-.393V8.5ZM10.5 7h6.75a.75.75 0 0 0 0-1.5H10.5a.75.75 0 0 0 0 1.5Zm-7 10.5h4.048a.75.75 0 0 0 0-1.5H4.642c.241-.198.611-.374 1.164-.6 1.719-.701 2.557-1.638 2.489-2.784-.074-1.243-1.146-2.094-2.667-2.116h-.054c-1.346 0-2.466.781-2.796 1.958a.75.75 0 0 0 1.444.405c.183-.649.827-.888 1.384-.863.269.004 1.153.067 1.191.706.034.569-.961 1.062-1.558 1.305-.998.408-2.365.967-2.487 2.686a.75.75 0 0 0 .748.803Zm13.75-3H10.5a.75.75 0 0 1 0-1.5h6.75a.75.75 0 0 1 0 1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNumberOrderedList;
