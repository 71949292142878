/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IncidentFormsUpdateLifecycleElementRequestBody
 */
export interface IncidentFormsUpdateLifecycleElementRequestBody {
    /**
     * Whether the user can select no value for this element
     * @type {boolean}
     * @memberof IncidentFormsUpdateLifecycleElementRequestBody
     */
    can_select_no_value: boolean;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IncidentFormsUpdateLifecycleElementRequestBody
     */
    default_value?: EngineParamBindingPayload;
    /**
     * 
     * @type {TextNode}
     * @memberof IncidentFormsUpdateLifecycleElementRequestBody
     */
    description?: TextNode;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormsUpdateLifecycleElementRequestBody
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormsUpdateLifecycleElementRequestBody
     */
    required_if: IncidentFormsUpdateLifecycleElementRequestBodyRequiredIfEnum;
    /**
     * 
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentFormsUpdateLifecycleElementRequestBody
     */
    required_if_condition_groups: Array<ConditionGroupPayload>;
    /**
     * 
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentFormsUpdateLifecycleElementRequestBody
     */
    show_if_condition_groups: Array<ConditionGroupPayload>;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormsUpdateLifecycleElementRequestBodyRequiredIfEnum {
    CheckEngineConfig = 'check_engine_config',
    AlwaysRequire = 'always_require',
    NeverRequire = 'never_require'
}

export function IncidentFormsUpdateLifecycleElementRequestBodyFromJSON(json: any): IncidentFormsUpdateLifecycleElementRequestBody {
    return IncidentFormsUpdateLifecycleElementRequestBodyFromJSONTyped(json, false);
}

export function IncidentFormsUpdateLifecycleElementRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsUpdateLifecycleElementRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_select_no_value': json['can_select_no_value'],
        'default_value': !exists(json, 'default_value') ? undefined : EngineParamBindingPayloadFromJSON(json['default_value']),
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'required_if': json['required_if'],
        'required_if_condition_groups': ((json['required_if_condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'show_if_condition_groups': ((json['show_if_condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
    };
}

export function IncidentFormsUpdateLifecycleElementRequestBodyToJSON(value?: IncidentFormsUpdateLifecycleElementRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_select_no_value': value.can_select_no_value,
        'default_value': EngineParamBindingPayloadToJSON(value.default_value),
        'description': TextNodeToJSON(value.description),
        'placeholder': value.placeholder,
        'required_if': value.required_if,
        'required_if_condition_groups': ((value.required_if_condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'show_if_condition_groups': ((value.show_if_condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
    };
}

