import {
  CustomFieldFieldTypeEnum,
  CustomFieldRequiredV2Enum as RequiredEnum,
} from "src/contexts/ClientContext";
import { v4 as uuidv4 } from "uuid";

import { CustomFieldFormData } from "./create-edit/CustomFieldCreateEditDrawer";

export const customFieldTemplates: Record<
  string,
  Partial<CustomFieldFormData>
> = {
  "void-impact": {
    name: "Impact",
    description:
      "Use a predefined set of options to categorise incidents by the type of impact.\n\nThese are compatible with the Verica Open Incident Database (VOID), where you can upload public post-mortems.",
    field_type: CustomFieldFieldTypeEnum.MultiSelect,
    show_before_creation: true,
    show_before_closure: true,
    show_in_announcement_post: true,
    options: [
      "Full production outage",
      "Partial production outage",
      "Non-production outage",
      "Data loss",
      "Degraded service/performance",
      "Connection issues",
      "Increased errors",
      "Increased latency",
      "Cascading failure",
      "Near miss",
      "Security",
      "Other",
    ].map((value, idx) => {
      return {
        itemType: "option",
        key: uuidv4(),
        option: {
          value: value,
          sort_key: idx,
        },
      };
    }),
  },
  team: {
    name: "Team",
    description:
      "The team responsible for resolving the incident and following up afterwards.",
    field_type: CustomFieldFieldTypeEnum.SingleSelect,
    show_before_creation: true,
    show_before_closure: true,
    show_in_announcement_post: true,
    options: [
      {
        itemType: "option",
        key: uuidv4(),
        option: {
          value: "",
          sort_key: 10000,
        },
      },
    ],
    required_v2: RequiredEnum.Always,
  },
};
