import { CatalogType, CatalogTypeAttribute } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
  Txt,
} from "@incident-ui";

export const WarningModal = ({
  onClose,
  onSubmit,
  removedAttributes,
  catalogType,
}: {
  onClose: () => void;
  onSubmit: () => void;
  removedAttributes: CatalogTypeAttribute[];
  catalogType: CatalogType;
}) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Are you sure?"
      analyticsTrackingId="catalog-schema-warning-modal"
    >
      <ModalContent>
        <Txt>
          {`You are about to remove the following attributes from ${
            catalogType.name
          }. If you press continue, we will delete these attributes and the data stored in them from all ${
            catalogType?.estimated_count ?? 0 > 0
              ? `${catalogType.estimated_count} `
              : ""
          }entries.`}
          <ul className="list-disc list-inside pl-4 pt-2">
            {removedAttributes.map((attr) => (
              <li key={attr.id}>{attr.name}</li>
            ))}
          </ul>
          <br />
          Would you like to continue?
        </Txt>
      </ModalContent>
      <ModalFooter>
        <div className="flex items-center gap-2">
          <Button
            theme={ButtonTheme.Secondary}
            onClick={onClose}
            analyticsTrackingId="catalog-schema-warning-modal-cancel"
          >
            Cancel
          </Button>
          <Button
            theme={ButtonTheme.Destroy}
            onClick={onSubmit}
            analyticsTrackingId="catalog-schema-warning-modal-submit"
          >
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
