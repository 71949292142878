import type { SVGProps } from "react";
import * as React from "react";
const SvgZendesk = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#78A300"
      fillRule="evenodd"
      d="m5.182 3.5-1.48 5.166h-.001L2 14.603l5.623 1.57 1.59-5.553.002.001 1.59-5.552L5.181 3.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#00363D"
      fillRule="evenodd"
      d="m10.788 10.622 1.59 5.552L18 14.604l-1.59-5.552-5.622 1.57Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgZendesk;
