import {
  ExternalSchedule,
  Schedule,
  ScheduleRotation,
  ScheduleRotationHandover,
  ScheduleRotationHandoverIntervalTypeEnum,
  ScheduleRotationPayload,
  SchedulesCreateRequest,
  SchedulesUpdateRequest,
} from "@incident-io/api";
import { WeekdayIntervalWeekdayEnum } from "@incident-io/api/models/WeekdayInterval";
import _, { flatMap, groupBy } from "lodash";
import { DateTime } from "luxon";
import { ulid } from "ulid";

import { sendWarningToSentry } from "../../../../utils/utils";
import {
  CustomHandoverRule,
  CustomHandoverRuleType,
  IntervalData,
  RotaFormData,
  RotaHandoverType,
  WorkingInterval,
} from "./RotaCreateEditForm";
import { ScheduleFormData } from "./schedule-create-edit-form/ScheduleCreateEditForm";
import { timezoneToCountries } from "./timezoneToCountries";
import { getCurrentlyActiveRotaConfigs } from "./util";

export const rotaFormDataToPayload = (
  data: RotaFormData,
  existingRotation: ScheduleRotation | ScheduleRotationPayload | null,
): ScheduleRotationPayload => {
  const layers = Array(Number(data.layer_count))
    .fill({})
    .map((_, i) => ({
      id: (existingRotation?.layers ?? [])[i]?.id,
    }));

  let handovers: ScheduleRotationHandover[];
  if (data.rota_handover_type !== RotaHandoverType.Custom) {
    handovers = [
      {
        interval_type:
          data.rota_handover_type as unknown as ScheduleRotationHandoverIntervalTypeEnum,
        interval: 1,
      },
    ];
  } else {
    handovers = data.custom_handovers.map((r): ScheduleRotationHandover => {
      return {
        interval_type:
          r.handover_interval_type as unknown as ScheduleRotationHandoverIntervalTypeEnum,
        interval: Number(r.handover_interval),
      };
    });
  }

  const effectiveFrom = data.effective_from ? data.effective_from : undefined;

  return {
    id: existingRotation?.id ?? data?.id,
    name: data?.name,
    user_ids: data.users.map((x) => x.id),
    handover_start_at: data.handover_start_at,
    layers: layers,
    handovers: handovers,
    working_intervals:
      data.has_working_intervals === "specific_times"
        ? intervalFormDataToPayload(data.working_intervals)
        : [],
    effective_from: effectiveFrom,
  };
};

const intervalFormDataToPayload = (data: IntervalData) => {
  // Each interval has a start time, end time and one or more days
  // We need to create an object for each day
  const intervals = [] as {
    weekday: WeekdayIntervalWeekdayEnum;
    start_time: string;
    end_time: string;
  }[];

  // First, we're going to merge any intervals that have the same start and end
  // time together
  const groupedData = groupBy(
    data,
    (interval) => `${interval.start_time}-${interval.end_time}`,
  );
  const mergedIntervals = [] as IntervalData;
  Object.values(groupedData).forEach((group) => {
    const mergedInterval = generateFormInterval(
      // Grab only the enabled days from each interval in the group
      // it doesn't matter if there are duplicates here, they'll just be set to `true` several times
      flatMap(group, (x) => Object.keys(x.days).filter((day) => x.days[day])),
      group[0].start_time,
      group[0].end_time,
    );
    mergedIntervals.push(mergedInterval);
  });

  // Now we can create a new interval payload for each day unique combination of active times & day
  mergedIntervals.forEach((interval) => {
    const activeDays = Object.entries(interval.days).filter(([_, day]) => day);
    activeDays.forEach((day) => {
      intervals.push({
        start_time: interval.start_time,
        end_time: interval.end_time,
        weekday: day[0] as WeekdayIntervalWeekdayEnum,
      });
    });
  });
  return intervals;
};

export const scheduleFormDataToCreatePayload = (
  data: ScheduleFormData,
  usersToPromote: string[],
): SchedulesCreateRequest => {
  return {
    createRequestBody: {
      name: data.name,
      external_schedule_id: data.external_schedule_id,
      timezone: data.timezone,
      holidays_public_config: {
        country_codes: (data.holidays_public_config?.country_codes ?? []).map(
          (c) => c.id,
        ),
      },
      config: {
        rotations: data.rotations.map((x) => rotaFormDataToPayload(x, null)),
      },
      user_ids_to_promote: usersToPromote,
    },
  };
};

// buildRotationsWithEffectiveFrom is a helper function that takes existing rotation configurations and the currently
// modified rota and assembles them together where needed: if the changes that have been made have been set to be
// effective from a certain time, we want to add a new rota configuration to our slice.
export const buildRotationsWithEffectiveFrom = (
  now: DateTime,
  formRotations: RotaFormData[],
  initialData?: Schedule,
): RotaFormData[] => {
  if (!initialData) {
    return formRotations;
  }

  // Grab existing rota configurations and group them by IDs.
  const existingRotaConfigs = _.groupBy(
    _.map(initialData.config?.rotations ?? [], (rota) =>
      rotaToFormData({ rota: rota }),
    ),
    (rota: RotaFormData) => rota.id ?? "",
  );

  // Grab the currently active rota configurations.
  const currentlyActiveConfigs = getCurrentlyActiveRotaConfigs({
    rotas: initialData.config?.rotations ?? [],
    now: now,
  });

  // Update our existing rotations to have the updated rotation form data.
  const results = formRotations
    .map((rotaFormData: RotaFormData): RotaFormData[] => {
      if (!rotaFormData.id) {
        return [rotaFormData];
      }

      const currentlyActiveConfig = currentlyActiveConfigs.find(
        (r) => r.id === rotaFormData.id,
      );
      const activeConfigEffectiveFrom = asDateTime(
        currentlyActiveConfig?.effective_from,
      );
      const rotaFormDataEffectiveFrom = asDateTime(
        rotaFormData?.effective_from,
      );

      let isFutureEdit = false;
      if (activeConfigEffectiveFrom && rotaFormDataEffectiveFrom) {
        isFutureEdit = rotaFormDataEffectiveFrom > activeConfigEffectiveFrom;
      } else if (!activeConfigEffectiveFrom && rotaFormDataEffectiveFrom) {
        isFutureEdit = true;
      }

      return _.chain(existingRotaConfigs[rotaFormData.id] ?? [])
        .filter((existingConfig) => {
          // If effective from is equal, this is an edit, so remove the previous
          // config for that 'effective_from' time, it'll get replaced
          // by the values in the form data
          if (isEffectiveFromEqual(existingConfig, rotaFormData)) {
            return false;
          }

          // If the user has set an effective from, and it happens before
          // a future edit, then remove the future edit, as we want the upcoming
          // changes to overwrite it.
          if (
            isFutureEdit &&
            existingConfig.effective_from &&
            rotaFormDataEffectiveFrom
          ) {
            const shouldBeOverwritten =
              DateTime.fromJSDate(existingConfig.effective_from) >
              rotaFormDataEffectiveFrom;

            if (shouldBeOverwritten) {
              return false;
            }
          }

          return true;
        })
        .concat([rotaFormData])
        .value();
    })
    .flat();

  const rotaIds = _.uniq(formRotations.map((rota) => rota.id));
  for (const rotaId of rotaIds) {
    const rotaConfigs = results.filter((rota) => rota.id === rotaId);

    // Throw an error if we see multiple rota configs with the same effective_from
    const effectiveFroms = rotaConfigs.map((rota) => rota.effective_from);
    if (_.uniq(effectiveFroms).length !== effectiveFroms.length) {
      sendWarningToSentry(
        "Multiple rota configs with the same effective_from",
        {
          rotaId,
          effectiveFroms: effectiveFroms.map((e) => e?.toISOString()),
        },
      );
    }
  }

  return results;
};

const asDateTime = (d: Date | undefined): DateTime | undefined => {
  return d ? DateTime.fromJSDate(d) : undefined;
};

const isEffectiveFromEqual = (
  a: { effective_from?: Date } | undefined,
  b: { effective_from?: Date } | undefined,
) => {
  if (!a?.effective_from && !b?.effective_from) {
    return true;
  }

  if (!a?.effective_from || !b?.effective_from) {
    return false;
  }
  return a.effective_from.getTime() === b.effective_from.getTime();
};

export const scheduleFormDataToUpdatePayload = (
  id: string,
  formData: ScheduleFormData,
  existingSchedule: Schedule,
  configVersion: number,
  usersToPromote: string[],
  now: DateTime,
): SchedulesUpdateRequest => {
  const rotasWithEffectiveFrom = buildRotationsWithEffectiveFrom(
    now,
    formData.rotations,
    existingSchedule,
  );

  return {
    id,
    updateRequestBody: {
      name: formData.name,
      holidays_public_config: {
        country_codes: (
          formData.holidays_public_config?.country_codes ?? []
        ).map((c) => c.id),
      },
      config: {
        rotations: rotasWithEffectiveFrom.map((rota) =>
          rotaFormDataToPayload(
            rota,
            existingSchedule?.config?.rotations.find(
              (existingRota) => existingRota.id === rota.id,
            ) || null,
          ),
        ),
        version: configVersion,
      },
      user_ids_to_promote: usersToPromote,
    },
  };
};

// If we have more than one handover it's definitely custom and if we have one
// that repeats more than once it's also custom.
export const hasCustomOrHourlyHandover = (
  handovers: CustomHandoverRule[],
): boolean => {
  if (handovers.length === 0) {
    throw new Error("We should always have at least one handover");
  } else if (handovers.length > 1) {
    return true;
  } else {
    return (
      Number(handovers[0].handover_interval) > 1 ||
      handovers[0].handover_interval_type === CustomHandoverRuleType.Hourly
    );
  }
};

export const rotaToFormData = ({
  rota,
  isDuplicating,
}: {
  rota: ScheduleRotation | ScheduleRotationPayload;
  isDuplicating?: boolean;
}): RotaFormData => {
  const handovers =
    rota.handovers?.map((r): CustomHandoverRule => {
      return {
        handover_interval_type:
          r.interval_type as unknown as CustomHandoverRuleType,
        handover_interval: r.interval.toString(),
      };
    }) ?? [];

  let handoverType: RotaHandoverType;
  if (hasCustomOrHourlyHandover(handovers)) {
    handoverType = RotaHandoverType.Custom;
  } else {
    if (handovers[0].handover_interval_type === CustomHandoverRuleType.Daily) {
      handoverType = RotaHandoverType.Daily;
    } else if (
      handovers[0].handover_interval_type === CustomHandoverRuleType.Weekly
    ) {
      handoverType = RotaHandoverType.Weekly;
    } else {
      throw new Error(
        "Should never happen, if the type is hourly we have custom handovers",
      );
    }
  }

  return {
    id: isDuplicating ? ulid() : rota.id,
    name: rota.name,
    users: rota.user_ids.map((id) => ({ id })),
    handover_start_at: rota.handover_start_at,
    layer_count: rota.layers?.length ?? 1,
    has_working_intervals:
      rota.working_intervals.length > 0 ? "specific_times" : "all_day",
    working_intervals: parseWorkingIntervalsResponse(rota),
    custom_handovers: handovers,
    rota_handover_type: handoverType,
    effective_from: rota.effective_from,
    is_deferred: "false", // We'll always default to false when opening the form.
  };
};

export const scheduleToFormData = ({
  schedule,
  now,
  isDuplicating,
}: {
  schedule: Schedule;
  now: DateTime;
  isDuplicating?: boolean;
}): ScheduleFormData => {
  return {
    name: `${schedule.name}${isDuplicating ? " (Copy)" : ""}`,
    timezone: schedule.timezone,
    holidays_public_config: {
      country_codes: (schedule.holidays_public_config?.country_codes ?? []).map(
        (c) => ({ id: c }),
      ),
    },
    rotations:
      getCurrentlyActiveRotaConfigs({
        rotas: schedule?.config?.rotations || [],
        now: now,
      }).map((rota) => rotaToFormData({ rota: rota, isDuplicating })) || [],
  };
};

export const externalScheduleToFormData = (
  schedule: ExternalSchedule,
): ScheduleFormData => {
  return {
    name: schedule.name,
    timezone: schedule.timezone,
    external_schedule_id: schedule.id,
    holidays_public_config: {
      country_codes: timezoneToCountries[schedule.timezone] || [],
    },
    rotations:
      schedule?.native_config?.rotations.map((rota) =>
        rotaToFormData({ rota: rota }),
      ) || [],
  };
};

const generateFormInterval = (
  activeDays: string[],
  startTime: string,
  endTime: string,
): WorkingInterval => {
  // First make an interval with the right start and end that is disabled every day
  const formInterval = {
    days: Object.values(WeekdayIntervalWeekdayEnum).reduce(
      (acc, day) => {
        acc[day] = false;
        return acc;
      },
      {} as {
        [day in WeekdayIntervalWeekdayEnum]: boolean;
      },
    ),
    start_time: startTime,
    end_time: endTime,
  };
  // Enable it for the required days
  activeDays.forEach((day) => (formInterval.days[day] = true));

  return formInterval;
};

const parseWorkingIntervalsResponse = (
  rota: ScheduleRotation | ScheduleRotationPayload,
): IntervalData => {
  // We have a set of objects that look like: {weekday: "monday", start_time: "09:00", end_time: "17:00"}
  // We want {days: {monday: true, tuesday: false..., etc}, start: "09:00", end: "17:00"}
  // So we're going to concat start and end time and use that to group the intervals
  const hasWorkingIntervals = rota.working_intervals.length > 0;
  if (hasWorkingIntervals) {
    const formIntervals = [] as IntervalData;
    const groupedIntervals = groupBy(
      rota.working_intervals,
      (interval) => `${interval.start_time}-${interval.end_time}`,
    );
    Object.keys(groupedIntervals).forEach((key) => {
      const formInterval = generateFormInterval(
        flatMap(groupedIntervals[key], (x) => x.weekday),
        key.split("-")[0],
        key.split("-")[1],
      );
      formIntervals.push(formInterval);
    });
    return formIntervals;
  } else {
    // If we've got no existing intervals, default to a single 0900-1700 interval mon-fri
    return [
      {
        days: Object.values(WeekdayIntervalWeekdayEnum)
          .filter(
            (day) =>
              ![
                WeekdayIntervalWeekdayEnum.Saturday,
                WeekdayIntervalWeekdayEnum.Sunday,
              ].includes(day),
          )
          .reduce(
            (acc, day) => {
              acc[day] = true;
              return acc;
            },
            {} as {
              [day in WeekdayIntervalWeekdayEnum]: boolean;
            },
          ),
        start_time: "09:00",
        end_time: "17:00",
      },
    ];
  }
};
