/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface StepConfigPayload
 */
export interface StepConfigPayload {
    /**
     * Reference to an expression that returns resources to run this step over
     * @type {string}
     * @memberof StepConfigPayload
     */
    for_each?: string;
    /**
     * Unique ID of this step in a workflow
     * @type {string}
     * @memberof StepConfigPayload
     */
    id?: string;
    /**
     * Unique name of the step in the engine
     * @type {string}
     * @memberof StepConfigPayload
     */
    name: string;
    /**
     * List of parameter bindings
     * @type {Array<EngineParamBindingPayload>}
     * @memberof StepConfigPayload
     */
    param_bindings: Array<EngineParamBindingPayload>;
}

export function StepConfigPayloadFromJSON(json: any): StepConfigPayload {
    return StepConfigPayloadFromJSONTyped(json, false);
}

export function StepConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'for_each': !exists(json, 'for_each') ? undefined : json['for_each'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'param_bindings': ((json['param_bindings'] as Array<any>).map(EngineParamBindingPayloadFromJSON)),
    };
}

export function StepConfigPayloadToJSON(value?: StepConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'for_each': value.for_each,
        'id': value.id,
        'name': value.name,
        'param_bindings': ((value.param_bindings as Array<any>).map(EngineParamBindingPayloadToJSON)),
    };
}

