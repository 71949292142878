/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemTemplateSectionConfig,
    PostmortemTemplateSectionConfigFromJSON,
    PostmortemTemplateSectionConfigFromJSONTyped,
    PostmortemTemplateSectionConfigToJSON,
} from './PostmortemTemplateSectionConfig';

/**
 * 
 * @export
 * @interface PostmortemsCreateTemplateSectionRequestBody
 */
export interface PostmortemsCreateTemplateSectionRequestBody {
    /**
     * 
     * @type {PostmortemTemplateSectionConfig}
     * @memberof PostmortemsCreateTemplateSectionRequestBody
     */
    config?: PostmortemTemplateSectionConfig;
    /**
     * The template to create the section on
     * @type {string}
     * @memberof PostmortemsCreateTemplateSectionRequestBody
     */
    postmortem_template_id: string;
    /**
     * Type of the section
     * @type {string}
     * @memberof PostmortemsCreateTemplateSectionRequestBody
     */
    type: PostmortemsCreateTemplateSectionRequestBodyTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemsCreateTemplateSectionRequestBodyTypeEnum {
    Custom = 'custom',
    Timeline = 'timeline',
    FollowUps = 'follow_ups',
    Summary = 'summary',
    KeyInformation = 'key_information',
    Header = 'header'
}

export function PostmortemsCreateTemplateSectionRequestBodyFromJSON(json: any): PostmortemsCreateTemplateSectionRequestBody {
    return PostmortemsCreateTemplateSectionRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsCreateTemplateSectionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsCreateTemplateSectionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : PostmortemTemplateSectionConfigFromJSON(json['config']),
        'postmortem_template_id': json['postmortem_template_id'],
        'type': json['type'],
    };
}

export function PostmortemsCreateTemplateSectionRequestBodyToJSON(value?: PostmortemsCreateTemplateSectionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': PostmortemTemplateSectionConfigToJSON(value.config),
        'postmortem_template_id': value.postmortem_template_id,
        'type': value.type,
    };
}

