/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    AlertTimelineItemContent,
    AlertTimelineItemContentFromJSON,
    AlertTimelineItemContentFromJSONTyped,
    AlertTimelineItemContentToJSON,
} from './AlertTimelineItemContent';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface AlertTimelineItem
 */
export interface AlertTimelineItem {
    /**
     * 
     * @type {Actor}
     * @memberof AlertTimelineItem
     */
    actor?: Actor;
    /**
     * ID of the alert
     * @type {string}
     * @memberof AlertTimelineItem
     */
    alert_id: string;
    /**
     * 
     * @type {AlertTimelineItemContent}
     * @memberof AlertTimelineItem
     */
    content: AlertTimelineItemContent;
    /**
     * 
     * @type {TextDocument}
     * @memberof AlertTimelineItem
     */
    description?: TextDocument;
    /**
     * Unique identifier of the timeline item
     * @type {string}
     * @memberof AlertTimelineItem
     */
    id: string;
    /**
     * Timestamp of when this timeline item occurred
     * @type {Date}
     * @memberof AlertTimelineItem
     */
    timestamp: Date;
    /**
     * Title of the timeline item
     * @type {string}
     * @memberof AlertTimelineItem
     */
    title: string;
    /**
     * Type of the timeline item
     * @type {string}
     * @memberof AlertTimelineItem
     */
    type: AlertTimelineItemTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AlertTimelineItemTypeEnum {
    AlertCreated = 'alert_created',
    PendingInIncidents = 'pending_in_incidents',
    CreatedIncident = 'created_incident',
    MergedIntoIncident = 'merged_into_incident',
    MarkedRelatedToIncident = 'marked_related_to_incident',
    AlertResolved = 'alert_resolved',
    EscalationDeferred = 'escalation_deferred',
    EscalationStarted = 'escalation_started',
    MarkedUnrelatedToIncident = 'marked_unrelated_to_incident',
    AutoUnrelatedAfterIncidentDeclined = 'auto_unrelated_after_incident_declined'
}

export function AlertTimelineItemFromJSON(json: any): AlertTimelineItem {
    return AlertTimelineItemFromJSONTyped(json, false);
}

export function AlertTimelineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertTimelineItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': !exists(json, 'actor') ? undefined : ActorFromJSON(json['actor']),
        'alert_id': json['alert_id'],
        'content': AlertTimelineItemContentFromJSON(json['content']),
        'description': !exists(json, 'description') ? undefined : TextDocumentFromJSON(json['description']),
        'id': json['id'],
        'timestamp': (new Date(json['timestamp'])),
        'title': json['title'],
        'type': json['type'],
    };
}

export function AlertTimelineItemToJSON(value?: AlertTimelineItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'alert_id': value.alert_id,
        'content': AlertTimelineItemContentToJSON(value.content),
        'description': TextDocumentToJSON(value.description),
        'id': value.id,
        'timestamp': (value.timestamp.toISOString()),
        'title': value.title,
        'type': value.type,
    };
}

