/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogType,
    CatalogTypeFromJSON,
    CatalogTypeFromJSONTyped,
    CatalogTypeToJSON,
} from './CatalogType';
import {
    DependentResource,
    DependentResourceFromJSON,
    DependentResourceFromJSONTyped,
    DependentResourceToJSON,
} from './DependentResource';

/**
 * 
 * @export
 * @interface CatalogShowTypeResponseBody
 */
export interface CatalogShowTypeResponseBody {
    /**
     * 
     * @type {{ [key: string]: Array<DependentResource>; }}
     * @memberof CatalogShowTypeResponseBody
     */
    attribute_dependent_resources?: { [key: string]: Array<DependentResource>; };
    /**
     * 
     * @type {CatalogType}
     * @memberof CatalogShowTypeResponseBody
     */
    catalog_type: CatalogType;
    /**
     * 
     * @type {Array<DependentResource>}
     * @memberof CatalogShowTypeResponseBody
     */
    dependent_resources: Array<DependentResource>;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogShowTypeResponseBody
     */
    supports_dynamic_attributes: boolean;
}

export function CatalogShowTypeResponseBodyFromJSON(json: any): CatalogShowTypeResponseBody {
    return CatalogShowTypeResponseBodyFromJSONTyped(json, false);
}

export function CatalogShowTypeResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogShowTypeResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute_dependent_resources': !exists(json, 'attribute_dependent_resources') ? undefined : json['attribute_dependent_resources'],
        'catalog_type': CatalogTypeFromJSON(json['catalog_type']),
        'dependent_resources': ((json['dependent_resources'] as Array<any>).map(DependentResourceFromJSON)),
        'supports_dynamic_attributes': json['supports_dynamic_attributes'],
    };
}

export function CatalogShowTypeResponseBodyToJSON(value?: CatalogShowTypeResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute_dependent_resources': value.attribute_dependent_resources,
        'catalog_type': CatalogTypeToJSON(value.catalog_type),
        'dependent_resources': ((value.dependent_resources as Array<any>).map(DependentResourceToJSON)),
        'supports_dynamic_attributes': value.supports_dynamic_attributes,
    };
}

