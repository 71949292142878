import { Transition } from "@headlessui/react";
import { tcx } from "src/utils/tailwind-classes";

export const FadeInOut = ({
  show,
  className,
  children,
}: {
  show: boolean;
  className?: string;
  children: React.ReactElement;
}): React.ReactElement => {
  return (
    <Transition
      show={show}
      enter="transition-grid-template-rows ease-in duration-300"
      enterFrom="grid-rows-[0fr]"
      enterTo="grid-rows-[1fr]"
      leave="transition-grid-template-rows ease-out duration-300 delay-50"
      leaveFrom="grid-rows-[1fr]"
      leaveTo="grid-rows-[0fr]"
      className={tcx("overflow-hidden grid", className)}
    >
      <Transition.Child
        enter="transition-opacity ease-linear duration-300 delay-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={"min-h-0"}
      >
        {children}
      </Transition.Child>
    </Transition>
  );
};
