import { Product } from "@incident-shared/billing";
import {
  BadgeTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Markdown,
  StackedList,
} from "@incident-ui";
import _ from "lodash";
import React from "react";
import { SubmitHandler } from "react-hook-form";
import {
  IncidentRole,
  IncidentRoleRoleTypeEnum,
  IncidentRolesDestroyRequest,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../@shared/settings/SettingsList/SettingsListItem";

const ROLE_RANKS: { [key in IncidentRoleRoleTypeEnum]: number } = {
  [IncidentRoleRoleTypeEnum.Lead]: 1,
  [IncidentRoleRoleTypeEnum.Reporter]: 2, // this won't actually turn up, but here for completeness
  [IncidentRoleRoleTypeEnum.Custom]: 3,
};

export const IncidentRoleList = (): React.ReactElement => {
  const {
    data: rolesData,
    isLoading: rolesLoading,
    error: rolesError,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const { trigger: onDelete } = useAPIMutation(
    "incidentRolesList",
    undefined,
    async (apiClient, data: IncidentRolesDestroyRequest) => {
      await apiClient.incidentRolesDestroy(data);
      return;
    },
  );

  if (rolesError) {
    return <GenericErrorMessage error={rolesError} />;
  }

  if (rolesLoading) {
    return <Loader />;
  }

  return (
    <StackedList>
      {_.sortBy(rolesData.incident_roles, (r) => [
        ROLE_RANKS[r.role_type],
        r.id,
      ]).map((role) => {
        return (
          <IncidentRoleListItem role={role} onDelete={onDelete} key={role.id} />
        );
      })}
    </StackedList>
  );
};

const IncidentRoleListItem = ({
  role,
  onDelete,
}: {
  role: IncidentRole;
  onDelete: SubmitHandler<IncidentRolesDestroyRequest>;
}) => {
  const isCustomRole = role.role_type === IncidentRoleRoleTypeEnum.Custom;

  return (
    <SettingsListItem
      title={role.name}
      icon={IconEnum.User}
      badgeProps={
        role.role_type === IncidentRoleRoleTypeEnum.Lead
          ? {
              children: "Lead role",
              theme: BadgeTheme.Info,
            }
          : undefined
      }
      description={
        <Markdown className="text-content-tertiary text-xs">
          {role.description}
        </Markdown>
      }
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        requiredProduct: Product.Response,
        edit: {
          editHref: `/settings/incident-roles/${role.id}/edit`,
          isGatedText:
            role.role_type === IncidentRoleRoleTypeEnum.Reporter
              ? "You cannot edit the reporter role"
              : undefined,
        },
        delete: {
          resourceTitle: role.name,
          deleteConfirmationTitle: "Delete incident role",
          deleteConfirmationContent: (
            <>
              Are you sure you want to delete the{" "}
              <span className="font-bold">{role.name}</span> role?
            </>
          ),
          onDelete: () => onDelete({ id: role.id }),
          isGatedText: isCustomRole
            ? undefined
            : "You cannot delete a default role",
          fetchDependentResources: [
            {
              resource_type: "IncidentRole",
              id: role.id,
            },
          ],
        },
      }}
    />
  );
};
