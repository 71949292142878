/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentAttachment,
    IncidentAttachmentFromJSON,
    IncidentAttachmentFromJSONTyped,
    IncidentAttachmentToJSON,
} from './IncidentAttachment';

/**
 * 
 * @export
 * @interface IncidentAttachmentsCreateResponseBody
 */
export interface IncidentAttachmentsCreateResponseBody {
    /**
     * 
     * @type {IncidentAttachment}
     * @memberof IncidentAttachmentsCreateResponseBody
     */
    incident_attachment: IncidentAttachment;
}

export function IncidentAttachmentsCreateResponseBodyFromJSON(json: any): IncidentAttachmentsCreateResponseBody {
    return IncidentAttachmentsCreateResponseBodyFromJSONTyped(json, false);
}

export function IncidentAttachmentsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAttachmentsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_attachment': IncidentAttachmentFromJSON(json['incident_attachment']),
    };
}

export function IncidentAttachmentsCreateResponseBodyToJSON(value?: IncidentAttachmentsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_attachment': IncidentAttachmentToJSON(value.incident_attachment),
    };
}

