/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallRoute,
    CallRouteFromJSON,
    CallRouteFromJSONTyped,
    CallRouteToJSON,
} from './CallRoute';

/**
 * 
 * @export
 * @interface CallRoutingCreateResponseBody
 */
export interface CallRoutingCreateResponseBody {
    /**
     * 
     * @type {CallRoute}
     * @memberof CallRoutingCreateResponseBody
     */
    call_route: CallRoute;
}

export function CallRoutingCreateResponseBodyFromJSON(json: any): CallRoutingCreateResponseBody {
    return CallRoutingCreateResponseBodyFromJSONTyped(json, false);
}

export function CallRoutingCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallRoutingCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_route': CallRouteFromJSON(json['call_route']),
    };
}

export function CallRoutingCreateResponseBodyToJSON(value?: CallRoutingCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_route': CallRouteToJSON(value.call_route),
    };
}

