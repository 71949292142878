/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';

/**
 * 
 * @export
 * @interface IncidentsUpdateRoleAssignmentsRequestBody
 */
export interface IncidentsUpdateRoleAssignmentsRequestBody {
    /**
     * Set the incident's role assignments to these values
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof IncidentsUpdateRoleAssignmentsRequestBody
     */
    role_assignments: Array<IncidentRoleAssignmentPayload>;
}

export function IncidentsUpdateRoleAssignmentsRequestBodyFromJSON(json: any): IncidentsUpdateRoleAssignmentsRequestBody {
    return IncidentsUpdateRoleAssignmentsRequestBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateRoleAssignmentsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateRoleAssignmentsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role_assignments': ((json['role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
    };
}

export function IncidentsUpdateRoleAssignmentsRequestBodyToJSON(value?: IncidentsUpdateRoleAssignmentsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role_assignments': ((value.role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
    };
}

