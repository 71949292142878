import { Button, ButtonProps } from "@incident-ui/Button/Button";
import { Modal, ModalProps } from "@incident-ui/Modal/Modal";
import React, { useState } from "react";

export const ButtonModal = ({
  renderModalContents,
  modalProps,
  buttonProps,
}: {
  renderModalContents: ({
    onClose,
  }: {
    onClose: () => void;
  }) => React.ReactElement;
  modalProps: Omit<
    ModalProps,
    "children" | "isOpen" | "onClose" | "disableQuickClose"
  >;
  buttonProps: ButtonProps;
}): React.ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const onClose = () => setShowModal(false);
  return (
    <>
      <Button onClick={() => setShowModal(true)} {...buttonProps} />
      {/* @ts-expect-error I don't know how to tell it that onClose is here, and disableQuickClose isn't required. */}
      <Modal {...modalProps} isOpen={showModal} onClose={onClose}>
        {renderModalContents({ onClose })}
      </Modal>
    </>
  );
};
