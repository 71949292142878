import {
  CallProvidersProvidersEnum,
  Incident,
  IncidentCallsCreateFromURLRequestBody,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { LoadingModal, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { useAPI, useAPIMutation } from "../../../../utils/swr";

export const DemoMagicCallModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}) => {
  const { identity } = useIdentity();

  const formMethods = useForm<IncidentCallsCreateFromURLRequestBody>({
    defaultValues: {
      call_url: "",
      incident_id: incident.id,
    },
  });
  const { trigger, isMutating, genericError } = useAPIMutation(
    "incidentCallsGetForLatestForIncident",
    { incidentId: incident.id },
    async (apiClient, data) => {
      await apiClient.incidentCallsCreateFromURL({
        createFromURLRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const { data: providerData, isLoading: providersAreLoading } = useAPI(
    "incidentCallSettingsGetCallProviders",
    {},
  );
  const availableProviders = providerData?.available_providers?.providers ?? [];

  if (!identity || providersAreLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  if (!identity.organisation_is_demo) {
    return <ErrorModal onClose={onClose} />;
  }

  if (!availableProviders.includes(CallProvidersProvidersEnum.GoogleMeet)) {
    return (
      <ErrorModal
        onClose={onClose}
        title={"Google Meet is not enabled"}
        description={
          "This demo feature requires Google Meet to be enabled. Please connect Google Meet in integration settings."
        }
      />
    );
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      onClose={onClose}
      analyticsTrackingId="set-call-url"
      onSubmit={trigger}
      genericError={genericError}
      saving={isMutating}
      title="Edit call"
      footer={
        <ModalFooter
          confirmButtonText="Save"
          confirmButtonType="submit"
          onClose={onClose}
          saving={isMutating}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        required
        name="call_url"
        label="Set the call URL to start transcription"
        placeholder="e.g. https://meet.google.com/rnq-jfpm-vkb"
      />
    </Form.Modal>
  );
};
