/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsAtlassianStatuspageGetConfigResponseBody,
    IntegrationsAtlassianStatuspageGetConfigResponseBodyFromJSON,
    IntegrationsAtlassianStatuspageGetConfigResponseBodyToJSON,
    IntegrationsAtlassianStatuspageListPagesResponseBody,
    IntegrationsAtlassianStatuspageListPagesResponseBodyFromJSON,
    IntegrationsAtlassianStatuspageListPagesResponseBodyToJSON,
    IntegrationsAtlassianStatuspageSetAPITokenRequestBody,
    IntegrationsAtlassianStatuspageSetAPITokenRequestBodyFromJSON,
    IntegrationsAtlassianStatuspageSetAPITokenRequestBodyToJSON,
    IntegrationsAtlassianStatuspageUpdateConfigRequestBody,
    IntegrationsAtlassianStatuspageUpdateConfigRequestBodyFromJSON,
    IntegrationsAtlassianStatuspageUpdateConfigRequestBodyToJSON,
    IntegrationsAtlassianStatuspageUpdateConfigResponseBody,
    IntegrationsAtlassianStatuspageUpdateConfigResponseBodyFromJSON,
    IntegrationsAtlassianStatuspageUpdateConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsAtlassianStatuspageSetAPITokenRequest {
    setAPITokenRequestBody: IntegrationsAtlassianStatuspageSetAPITokenRequestBody;
}

export interface IntegrationsAtlassianStatuspageUpdateConfigRequest {
    updateConfigRequestBody: IntegrationsAtlassianStatuspageUpdateConfigRequestBody;
}

/**
 * 
 */
export class IntegrationsAtlassianStatuspageApi extends runtime.BaseAPI {

    /**
     * Get organisation\'s Atlassian Statuspage config. There is only one active config per org.
     * GetConfig Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsAtlassianStatuspageGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/atlassian_statuspage/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsAtlassianStatuspageGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s Atlassian Statuspage config. There is only one active config per org.
     * GetConfig Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageGetConfig(initOverrides?: RequestInit): Promise<IntegrationsAtlassianStatuspageGetConfigResponseBody> {
        const response = await this.integrationsAtlassianStatuspageGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Statuspage uninstallation request by archiving the token
     * HandleUninstall Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/atlassian_statuspage`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Statuspage uninstallation request by archiving the token
     * HandleUninstall Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsAtlassianStatuspageHandleUninstallRaw(initOverrides);
    }

    /**
     * List Statuspage pages available from Statuspage
     * ListPages Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageListPagesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsAtlassianStatuspageListPagesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/atlassian_statuspage/pages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsAtlassianStatuspageListPagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List Statuspage pages available from Statuspage
     * ListPages Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageListPages(initOverrides?: RequestInit): Promise<IntegrationsAtlassianStatuspageListPagesResponseBody> {
        const response = await this.integrationsAtlassianStatuspageListPagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the API token for this integration
     * SetAPIToken Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageSetAPITokenRaw(requestParameters: IntegrationsAtlassianStatuspageSetAPITokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setAPITokenRequestBody === null || requestParameters.setAPITokenRequestBody === undefined) {
            throw new runtime.RequiredError('setAPITokenRequestBody','Required parameter requestParameters.setAPITokenRequestBody was null or undefined when calling integrationsAtlassianStatuspageSetAPIToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/atlassian_statuspage/actions/set_api_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsAtlassianStatuspageSetAPITokenRequestBodyToJSON(requestParameters.setAPITokenRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the API token for this integration
     * SetAPIToken Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageSetAPIToken(requestParameters: IntegrationsAtlassianStatuspageSetAPITokenRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsAtlassianStatuspageSetAPITokenRaw(requestParameters, initOverrides);
    }

    /**
     * Update the config for this integration.
     * UpdateConfig Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageUpdateConfigRaw(requestParameters: IntegrationsAtlassianStatuspageUpdateConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsAtlassianStatuspageUpdateConfigResponseBody>> {
        if (requestParameters.updateConfigRequestBody === null || requestParameters.updateConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateConfigRequestBody','Required parameter requestParameters.updateConfigRequestBody was null or undefined when calling integrationsAtlassianStatuspageUpdateConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/atlassian_statuspage/config`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsAtlassianStatuspageUpdateConfigRequestBodyToJSON(requestParameters.updateConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsAtlassianStatuspageUpdateConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the config for this integration.
     * UpdateConfig Integrations - Atlassian Statuspage
     */
    async integrationsAtlassianStatuspageUpdateConfig(requestParameters: IntegrationsAtlassianStatuspageUpdateConfigRequest, initOverrides?: RequestInit): Promise<IntegrationsAtlassianStatuspageUpdateConfigResponseBody> {
        const response = await this.integrationsAtlassianStatuspageUpdateConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
