import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { IntegrationsListObject } from "@incident-shared/integrations";
import { ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IntegrationsSplunkOnCallSetBotUsernameRequestBody,
  IntegrationsSplunkOnCallSetTokenRequestBody as FormData,
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

enum Step {
  Tokens = "tokens",
  BotUsername = "bot_username",
}

export const ConnectToSplunkOnCallModal = ({
  onClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}): React.ReactElement | null => {
  const [step, setStep] = useState<Step>(Step.Tokens);

  switch (step) {
    case Step.Tokens:
      return (
        <SetTokens
          onClose={onClose}
          onSaved={() => setStep(Step.BotUsername)}
        />
      );
    case Step.BotUsername:
      return (
        <SetBotUsername
          onClose={onClose}
          onSuccess={async () => {
            onClose();
          }}
        />
      );
    default:
      assertUnreachable(step);
      return null;
  }
};

function SetTokens({
  onClose,
  onSaved,
}: {
  onClose: () => void;
  onSaved: () => void;
}): ReactElement {
  const formMethods = useForm<FormData>();
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, data: FormData) => {
      await apiClient.integrationsSplunkOnCallSetToken({
        setTokenRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onSaved,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-splunk-on-call-integration"
      title="Connect to Splunk On-Call"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Next"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add your Splunk On-Call API ID and key to activate the integration,
        allowing incident.io to escalate to policies or specific people inside
        your Splunk On-Call account.
      </p>
      <hr className="my-3" />
      <div className="space-y-4">
        <InputV2
          formMethods={formMethods}
          name="api_id"
          helptext={`Splunk requires that you provide your API ID alongside whichever API key you use.`}
          label="Your API ID"
          type={InputType.Text}
          required="Please enter an API ID"
          autoComplete="none"
          placeholder={"xxxxxxxxxxxxxxxxxxxxxxxxx"}
        />
        <InputV2
          formMethods={formMethods}
          label="API Key"
          helptext={`This is the secret API key created in your Splunk On-Call/VictorOps dashboard.`}
          type={InputType.Password}
          required="Please enter an API key"
          name="api_token"
          autoComplete="none"
          placeholder={"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"}
        />
      </div>
    </Form.Modal>
  );
}

function SetBotUsername({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}): ReactElement {
  const formMethods = useForm<
    FormData & IntegrationsSplunkOnCallSetBotUsernameRequestBody
  >();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, { bot_username }) => {
      await apiClient.integrationsSplunkOnCallSetBotUsername({
        setBotUsernameRequestBody: { bot_username },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess,
    },
  );

  const apiClient = useClient();
  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-splunk-on-call-integration"
      title="Connect to Splunk On-Call"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add your Splunk On-Call API ID and key to activate the integration,
        allowing incident.io to escalate to policies or specific people inside
        your Splunk On-Call account.
      </p>
      <hr className="my-3" />
      <InputV2
        formMethods={formMethods}
        label="Your API ID"
        disabled
        type={InputType.Password}
        name="api_id"
        autoComplete="none"
        placeholder={"xxxxxxxxxxxxxxxxxxxxxxxxx"}
      />
      <InputV2
        formMethods={formMethods}
        label="API Key"
        type={InputType.Password}
        disabled
        name="api_token"
        autoComplete="none"
        placeholder={"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"}
      />
      <DynamicSingleSelectV2
        formMethods={formMethods}
        label="Bot user"
        name="bot_username"
        helptext={
          <>
            Choose your bot user account. Splunk On-Call requires all incidents
            to be triggered by a specified user account, which does{" "}
            <strong>not</strong> receive any alerts about the incident. We
            recommend using a limited-access robot account for this.
          </>
        }
        placeholder="Please choose your bot user"
        rules={{ required: "Please choose your bot user" }}
        loadOptions={getTypeaheadOptions(
          apiClient,
          TypeaheadsListTypeaheadTypeEnum.SplunkOnCallUser,
        )}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadsListTypeaheadTypeEnum.SplunkOnCallUser,
        )}
      />
    </Form.Modal>
  );
}
