import { Txt } from "@incident-ui";
import {
  IncidentTimestamp,
  IncidentTimestampTimestampTypeEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import {
  TimestampSetByRulePills,
  TimestampSetByRuleSentence,
} from "./TimestampSetByRule";

export const TimestampDescription = ({
  timestamp,
  compactAndDarkMode,
  className,
}: {
  timestamp: IncidentTimestamp;
  compactAndDarkMode?: boolean;
  className?: string;
}): React.ReactElement => {
  const textClassName = tcx(
    compactAndDarkMode ? "!text-slate-100" : "!text-slate-600",
    className,
  );
  const boldTextClassName = tcx(textClassName, "font-semibold");

  if (timestamp.set_on_creation) {
    return (
      <Txt className={textClassName}>
        set when{" "}
        <Txt inline className={boldTextClassName}>
          an incident is declared
        </Txt>
      </Txt>
    );
  } else if (
    timestamp.timestamp_type === IncidentTimestampTimestampTypeEnum.AcceptedAt
  ) {
    return (
      <Txt className={textClassName}>
        set when{" "}
        <Txt inline className={boldTextClassName}>
          an incident moves from triage into an active status
        </Txt>
      </Txt>
    );
  } else if (
    timestamp.timestamp_type === IncidentTimestampTimestampTypeEnum.ResolvedAt
  ) {
    return (
      <Txt className={textClassName}>
        set when{" "}
        <Txt inline className={boldTextClassName}>
          an incident moves into a post-incident or closed status
        </Txt>
      </Txt>
    );
  }
  if (timestamp.set_by_rules.length === 0) {
    return <Txt className={textClassName}>set manually</Txt>;
  }

  return (
    <Txt className={textClassName}>
      set when{" "}
      {joinSpansWithCommasAndConnectorWord(
        timestamp.set_by_rules.map((rule) =>
          compactAndDarkMode ? (
            <TimestampSetByRuleSentence key={rule.id} rule={rule} />
          ) : (
            <TimestampSetByRulePills key={rule.id} rule={rule} />
          ),
        ),
        "or",
      )}
    </Txt>
  );
};
