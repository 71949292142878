/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertPulseChannelBindingPayload
 */
export interface AlertPulseChannelBindingPayload {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertPulseChannelBindingPayload
     */
    binding: EngineParamBindingPayload;
    /**
     * The visibility of the channel
     * @type {string}
     * @memberof AlertPulseChannelBindingPayload
     */
    channel_visibility: string;
    /**
     * The conditions that must be met for this channel binding to be used
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertPulseChannelBindingPayload
     */
    condition_groups: Array<ConditionGroupPayload>;
}

export function AlertPulseChannelBindingPayloadFromJSON(json: any): AlertPulseChannelBindingPayload {
    return AlertPulseChannelBindingPayloadFromJSONTyped(json, false);
}

export function AlertPulseChannelBindingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertPulseChannelBindingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': EngineParamBindingPayloadFromJSON(json['binding']),
        'channel_visibility': json['channel_visibility'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
    };
}

export function AlertPulseChannelBindingPayloadToJSON(value?: AlertPulseChannelBindingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingPayloadToJSON(value.binding),
        'channel_visibility': value.channel_visibility,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
    };
}

