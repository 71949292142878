import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ChannelInfo,
  StartTutorialModal,
  TutorialStartedModal,
} from "@incident-shared/tutorial";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Card,
  FullPageEmptyState,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { ChatBubble } from "@incident-ui/ProductMarketingBanner/ChattyProductMarketingBanner";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { v4 as uuid } from "uuid";

import { tcx } from "../../../utils/tailwind-classes";
import { useShowCatalogBanner } from "../../catalog/type-list/CatalogTypeSetupBanner";
import {
  IncidentCreateModal,
  IncidentModeQueryParam,
} from "../incident/IncidentCreateModal";
import { ReactComponent as Graphic } from "./empty-state-orange.svg";

export const IncidentsListEmptyState = () => {
  const commsPlatform = usePrimaryCommsPlatform();
  const usesMSTeams = commsPlatform === CommsPlatform.MSTeams;

  return (
    <FullPageEmptyState
      title="You don't have any incidents yet"
      graphic={<Graphic />}
      subtitle="It's the perfect time to get started and explore our features"
      cta={<></>}
      gradientClasses="from-alarmalade-50 to-white from-20% via-80%"
      className="w-full"
      cards={
        <>
          <CatalogWizardCard domain="incidents" />
          {!usesMSTeams && <TutorialCard />}
          <IntegrationsCard />
          {usesMSTeams && <DeclareIncCard />}
        </>
      }
    />
  );
};

export const CatalogWizardCard = ({
  domain,
}: {
  domain: "incidents" | "alerts";
}) => {
  const { data } = useAPI("settingsSupport", undefined);
  const { identity } = useIdentity();
  const { nextWizard, wizardsComplete } = useShowCatalogBanner();

  const wizardType = nextWizard || "team"; // Set a default so that we render something

  // If wizardType == "team"
  let description =
    domain === "incidents"
      ? "Add your teams to Catalog to enable powerful automations and gain insights for each team"
      : "Add your teams to Catalog to tag alerts with owners, affected services, features, and more";

  if (wizardType === "service") {
    description =
      domain === "incidents"
        ? "Add your services to Catalog to connect incidents with their impacted services"
        : "Add your services to Catalog to ensure alerts reach the right people when issues arise";
  }

  if (wizardsComplete) {
    return (
      <Card
        className="h-full bg-purple-surface"
        iconOverride={
          <ChatBubble
            csm={data?.csm}
            className="p-0"
            titleClassName="text-sm-normal text-content-primary"
          />
        }
        title={`Set up your teams`}
        description={description}
        descriptionClassName="text-content-primary"
        color={ColorPaletteEnum.Purple}
        clickable={false}
        footerNode={
          <Badge
            icon={IconEnum.Checkmark}
            iconClassName="text-green-content"
            theme={BadgeTheme.Tertiary}
            size={BadgeSize.Small}
            className="text-sm-medium text-content-primary"
          >
            Completed
          </Badge>
        }
      />
    );
  }

  const config =
    wizardType === "team"
      ? {
          colour: "bg-purple-surface",
          iconColour: ColorPaletteEnum.Purple,
        }
      : {
          colour: "bg-green-surface",
          iconColour: ColorPaletteEnum.Green,
        };

  return (
    <GatedButton
      analyticsTrackingId={`${domain}-empty-state-${wizardType}s-wizard`}
      theme={ButtonTheme.Naked}
      // The wizard will redirect to add-attributes if the type already exists.
      href={`/catalog/${wizardType}-wizard/choose-source-of-truth`}
      requiredScope={ScopeNameEnum.CatalogTypesCreate}
      upgradeRequiredProps={{
        gate: {
          type: "numeric",
          value: identity.feature_gates.custom_catalog_types_count,
          featureNameSingular: "custom catalog type",
        },
        featureName: "custom catalog types",
      }}
    >
      <Card
        className={tcx("h-full", config.colour)}
        iconOverride={
          <ChatBubble
            csm={data?.csm}
            className="p-0"
            titleClassName="text-sm-normal text-content-primary"
          />
        }
        title={`Set up your ${wizardType}s`}
        description={description}
        descriptionClassName="text-content-primary"
        color={config.iconColour}
        clickable={true}
        footerNode={<ChevronFooter />}
      />
    </GatedButton>
  );
};

const IntegrationsCard = () => {
  return (
    <Button
      analyticsTrackingId="incidents-empty-state-integrations"
      theme={ButtonTheme.Naked}
      href="/settings/integrations"
    >
      <Card
        className="h-full"
        icon={IconEnum.Integrations}
        iconSize={IconSize.Medium}
        title="Set up integrations"
        description="Amplify the value of your existing tools by bringing them together in a single place"
        descriptionClassName="text-content-secondary"
        color={ColorPaletteEnum.Red}
        clickable={true}
        footerNode={<ChevronFooter />}
      />
    </Button>
  );
};

const TutorialCard = () => {
  const { identity } = useIdentity();
  const clickable = identity.can_create_tutorial_incidents;

  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [channelInfo, setChannelInfo] = useState<ChannelInfo | null>(null);

  const idempotencyKey = uuid();
  const { trigger: createTutorial, isMutating: loading } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      const { channel_url: channelUrl, channel_name: channelName } =
        await apiClient.tutorialsCreate({
          createRequestBody: {
            idempotency_key: idempotencyKey,
          },
        });
      setChannelInfo({ channelUrl, channelName });
      setShowSecondModal(true);
    },
  );

  return (
    <>
      <Button
        analyticsTrackingId="start-incident-tutorial"
        theme={ButtonTheme.Naked}
        onClick={() => setShowFirstModal(true)}
      >
        <Card
          className="h-full"
          icon={IconEnum.Users}
          iconSize={IconSize.Medium}
          title="Start a tutorial incident"
          description="A tutorial incident is the best way to get to know incident.io"
          descriptionClassName="text-content-secondary"
          color={ColorPaletteEnum.Red}
          clickable={clickable}
          footerNode={clickable && <ChevronFooter />}
        />
      </Button>
      {showFirstModal && (
        <StartTutorialModal
          onClose={() => setShowFirstModal(false)}
          onConfirm={() =>
            createTutorial({}).then(() => setShowFirstModal(false))
          }
          loading={loading}
        />
      )}
      {showSecondModal &&
        !showFirstModal &&
        !loading &&
        channelInfo != null && (
          <TutorialStartedModal
            onClose={() => setShowSecondModal(false)}
            channelURL={channelInfo.channelUrl}
            channelName={channelInfo.channelName}
          />
        )}
    </>
  );
};

const DeclareIncCard = () => {
  const [showIncidentCreateModal, setShowIncidentCreateModal] = useState(false);

  return (
    <>
      <Button
        analyticsTrackingId="start-incident-tutorial"
        theme={ButtonTheme.Naked}
        onClick={() => setShowIncidentCreateModal(true)}
      >
        <Card
          className="h-full"
          icon={IconEnum.IncidentFlame}
          iconSize={IconSize.Medium}
          title="Declare a test incident"
          description="Running an incident is the best way to get to know incident.io"
          descriptionClassName="text-content-secondary"
          color={ColorPaletteEnum.Red}
          clickable={true}
          footerNode={<ChevronFooter />}
        />
      </Button>

      {showIncidentCreateModal && (
        <IncidentCreateModal
          initialMode={IncidentModeQueryParam.Test}
          onClose={() => setShowIncidentCreateModal(false)}
        />
      )}
    </>
  );
};

export const ChevronFooter = () => {
  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Secondary}
      size={BadgeSize.Medium}
      icon={IconEnum.ChevronRight}
      href=""
      className="ml-auto"
    >
      <></>
    </Button>
  );
};
