/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageStructureComponent
 */
export interface StatusPageStructureComponent {
    /**
     * The ID of the component this references
     * @type {string}
     * @memberof StatusPageStructureComponent
     */
    component_id: string;
    /**
     * When data for this component is first available. Before this, we don't know the component's status, and after it we can assume the component was operational unless there's an extra impact.
     * @type {string}
     * @memberof StatusPageStructureComponent
     */
    data_available_since: string;
    /**
     * The description of this component
     * @type {string}
     * @memberof StatusPageStructureComponent
     */
    description?: string;
    /**
     * Whether to display historical uptime for this component
     * @type {boolean}
     * @memberof StatusPageStructureComponent
     */
    display_uptime: boolean;
    /**
     * Whether this component is hidden from display on the status page
     * @type {boolean}
     * @memberof StatusPageStructureComponent
     */
    hidden: boolean;
    /**
     * The name of this component
     * @type {string}
     * @memberof StatusPageStructureComponent
     */
    name: string;
}

export function StatusPageStructureComponentFromJSON(json: any): StatusPageStructureComponent {
    return StatusPageStructureComponentFromJSONTyped(json, false);
}

export function StatusPageStructureComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_id': json['component_id'],
        'data_available_since': json['data_available_since'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'display_uptime': json['display_uptime'],
        'hidden': json['hidden'],
        'name': json['name'],
    };
}

export function StatusPageStructureComponentToJSON(value?: StatusPageStructureComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.component_id,
        'data_available_since': value.data_available_since,
        'description': value.description,
        'display_uptime': value.display_uptime,
        'hidden': value.hidden,
        'name': value.name,
    };
}

