import { Incident } from "@incident-io/api";
import {
  Avatar,
  ContentBox,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  Link,
} from "@incident-ui";
import { SeverityBadge } from "@incident-ui/Badge/SeverityBadge";
import { useSettings } from "src/hooks/useSettings";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { tcx } from "src/utils/tailwind-classes";

import {
  getIncidentLead,
  IconWithLabel,
} from "../../incident-grid/card/IncidentCard";

export type IncidentCardContextType = {
  incident: Incident;
  previousIncidentState: Incident;
  isGridFirstUpdate: boolean;
  showNewness: boolean;
};

export const SlimIncidentCard = ({ incident }: { incident: Incident }) => {
  return (
    <Link
      analyticsTrackingId={null}
      to={`/incidents/${incident.external_id}`}
      className={"w-full h-full"}
      noHoverColor
      noUnderline
    >
      <div className={"h-full"}>
        <IncidentCardContent incident={incident} />
      </div>
    </Link>
  );
};

const IncidentCardContent = ({ incident }: { incident: Incident }) => {
  const { settings } = useSettings();
  const incidentTypesAreEnabled = incidentTypesEnabled(settings);
  const incidentLead = getIncidentLead(incident);

  return (
    <ContentBox
      className={tcx(
        "relative w-full h-full group overflow-hidden",
        "hover:shadow-md hover:border-stroke-hover",
        "p-4 flex flex-col gap-2",
      )}
    >
      <div className="flex gap-2 justify-between truncate pb-3">
        <SeverityBadge severity={incident.severity} />
        <Avatar
          size={IconSize.Medium}
          url={incidentLead?.assignee?.avatar_url}
          name={incidentLead?.assignee?.name}
        />
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-xs text-content-tertiary font-semibold relative overflow-hidden">
          {incident.reference}
        </div>
        <div className="overflow-hidden text-base font-semibold line-clamp-1">
          {incident.name}
        </div>
      </div>
      {/* Badges */}
      <div className="flex gap-2 items-center overflow-hidden w-full">
        <IncidentStatusBadge
          key={incident.incident_status.name}
          status={incident.incident_status}
        />
        {incidentTypesAreEnabled && incident.incident_type && (
          <IconWithLabel
            label={incident.incident_type.name}
            icon={IconEnum.IncidentType}
          />
        )}
      </div>
    </ContentBox>
  );
};
