import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, Loader } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";

import { EmptyState } from "../../../components/legacy/on-call/EmptyState";
import { OnCallConnectPagerDuty } from "../../../components/legacy/on-call-legacy/OnCallConnectPagerDuty";
import { useIdentity } from "../../../contexts/IdentityContext";
import EmptyStateImg from "./images/pay-calc-empty-state.png";

export const CreateFirstOnCallPayReportPrompt = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route
          path={"connect/pagerduty"}
          element={<OnCallConnectPagerDuty />}
        />
        <Route path="*" element={<OnCallEmptyState />} />
      </Routes>
    </>
  );
};

const OnCallEmptyState = (): React.ReactElement => {
  const copy =
    "Let us know how much you pay for on-call, and we'll do the rest.";

  return (
    <>
      <EmptyState
        copy={copy}
        imageSrc={EmptyStateImg}
        title="What are your responders owed?"
        buttons={[]}
      >
        <CreateYourFirstReportOrUpgrade />
      </EmptyState>
    </>
  );
};

const CreateYourFirstReportOrUpgrade = () => {
  const { identity } = useIdentity();
  if (!identity) {
    return <Loader />;
  }

  return (
    <GatedButton
      upgradeRequired={!identity.feature_gates.on_call_calculator}
      upgradeRequiredProps={{
        featureName: `the on-call pay calculator`,
        gate: {
          type: "boolean",
        },
      }}
      analyticsTrackingId={"schedules-create-first-report"}
      theme={ButtonTheme.Primary}
      className="!hidden md:!inline-flex"
      href={"/on-call/pay-calculator/reports/create"}
    >
      <span>Create your first report</span>
    </GatedButton>
  );
};
