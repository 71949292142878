import type { SVGProps } from "react";
import * as React from "react";
const SvgOpsgenie = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#opsgenie_svg__a)"
      d="M10.003 9.97a3.985 3.985 0 1 0 0-7.97 3.985 3.985 0 0 0 0 7.97Z"
    />
    <path
      fill="url(#opsgenie_svg__b)"
      d="M9.667 17.896a19.177 19.177 0 0 1-6.314-6.605c-.112-.224-.044-.492.179-.604h.023l3.022-1.478c.202-.112.47-.022.582.18a14.84 14.84 0 0 0 6.494 5.866 18.847 18.847 0 0 1-3.292 2.664.696.696 0 0 1-.694-.023Z"
    />
    <path
      fill="#2684FF"
      d="M10.34 17.896a19.178 19.178 0 0 0 6.313-6.605c.112-.224.045-.492-.179-.604h-.023L13.43 9.209c-.202-.112-.47-.022-.582.18a14.839 14.839 0 0 1-6.493 5.866 18.84 18.84 0 0 0 3.291 2.664.696.696 0 0 0 .694-.023Z"
    />
    <defs>
      <linearGradient
        id="opsgenie_svg__a"
        x1={10.005}
        x2={10.005}
        y1={3.319}
        y2={11.514}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2684FF" />
        <stop offset={0.82} stopColor="#0052CC" />
      </linearGradient>
      <linearGradient
        id="opsgenie_svg__b"
        x1={7.545}
        x2={10.291}
        y1={11.285}
        y2={17.03}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2684FF" />
        <stop offset={0.62} stopColor="#0052CC" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgOpsgenie;
