import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import introducingKanban from "./introducing-kanban.png";

export const IncidentsKanbanView = "incidents-kanban-view";

export const IntroducingKanbanModal = ({
  onClose,
  loading,
}: {
  onClose: () => void;
  loading: boolean;
}) => {
  return (
    <Modal
      isOpen={true}
      analyticsTrackingId={`welcome-kanban-modal`}
      hideHeader
      onClose={onClose}
      title="Introducing the Kanban View Mode"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        <img className="w-full" src={introducingKanban} />

        <div className="flex">
          <div className="mx-20 my-10 flex-col justify-center items-center gap-6 inline-flex">
            <div className="self-stretch text-center text-gray-800 text-3xl font-bold font-['Inter'] leading-9">
              Introducing the Kanban View Mode
            </div>
            <div className="self-stretch text-center text-slate-600 text-lg font-normal font-['Inter'] leading-7">
              Streamline your incident management workflow with our new Kanban
              View Mode. Prioritize incidents effortlessly with a clear,
              intuitive interface.
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <div className="w-4"></div>
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Primary}
          loading={loading}
          disabled={loading}
        >
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};
