/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathWithLevels,
    EscalationPathWithLevelsFromJSON,
    EscalationPathWithLevelsFromJSONTyped,
    EscalationPathWithLevelsToJSON,
} from './EscalationPathWithLevels';

/**
 * 
 * @export
 * @interface EscalationPathsListForUserV2ResponseBody
 */
export interface EscalationPathsListForUserV2ResponseBody {
    /**
     * 
     * @type {Array<EscalationPathWithLevels>}
     * @memberof EscalationPathsListForUserV2ResponseBody
     */
    escalation_paths: Array<EscalationPathWithLevels>;
}

export function EscalationPathsListForUserV2ResponseBodyFromJSON(json: any): EscalationPathsListForUserV2ResponseBody {
    return EscalationPathsListForUserV2ResponseBodyFromJSONTyped(json, false);
}

export function EscalationPathsListForUserV2ResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsListForUserV2ResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_paths': ((json['escalation_paths'] as Array<any>).map(EscalationPathWithLevelsFromJSON)),
    };
}

export function EscalationPathsListForUserV2ResponseBodyToJSON(value?: EscalationPathsListForUserV2ResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_paths': ((value.escalation_paths as Array<any>).map(EscalationPathWithLevelsToJSON)),
    };
}

