import { IconSize } from "@incident-ui";

import { AssistantAvatar, AssistantAvatarBackground } from "./AssistantAvatar";

// AssistantIntro is the splash screen of Assistant's chat overlay.
export const AssistantIntro = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center max-w-xl mx-auto">
      <AssistantAvatar
        size={IconSize.XL}
        className={"mb-4 p-4"}
        iconClassName="fill-white"
        background={AssistantAvatarBackground.Purple}
      />
      <div className="space-y-3">
        <h2 className="text-2xl font-semibold">
          Ask Assistant about your incidents
        </h2>
        <p className="text-base text-gray-600">
          Understand historical trends,
          <br className="max-md:hidden" /> identify patterns, and build your own
          charts.
        </p>
      </div>
    </div>
  );
};
