import {
  RBACRole,
  RoleMapping,
  RoleMappingPayload,
  SCIMShowSettingsResponseBody,
  ScopeNameEnum,
} from "@incident-io/api";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  EmptyState,
  IconEnum,
  Loader,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import { useEffect, useState } from "react";
import { UsersCreateRequestBodyBaseRoleSlugEnum as BaseRoleSlugEnum } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../SettingsSubHeading";
import { ScimGroupRoleMappingCreateEditModal } from "./ScimGroupRoleMappingCreateEditModal";

export interface GroupMapping {
  groupId: string;
  roles: RBACRole[];
  mappingCreatedAt: string;
  groupName: string;
  groupDescription: string;
}

export const ScimGroupRoleMappingSection = ({
  showHeading,
  roles,
  scimConfigState,
  setHasSetOwnerMapping,
}: {
  showHeading: boolean;
  roles: RBACRole[];
  scimConfigState: SCIMShowSettingsResponseBody;
  setHasSetOwnerMapping: (d: boolean) => void;
}) => {
  const showToast = useToast();
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [editingGroup, setEditingGroup] = useState<GroupMapping | undefined>(
    undefined,
  );
  const {
    data: { role_mappings: groupRoleMappings },
    isLoading: loading,
    error,
  } = useAPI("sCIMShowRoleMappings", undefined, {
    fallbackData: { role_mappings: [] },
  });

  const rolesById = Object.fromEntries(roles.map((role) => [role.id, role]));

  // Collect mappings by group, then sort them newest to oldest
  const sortedMappings: GroupMapping[] = _.chain(
    (groupRoleMappings ?? []) as RoleMapping[],
  )
    .groupBy("scim_group_id")
    .map(
      (elements: RoleMapping[], groupId: string): GroupMapping => ({
        groupId,
        mappingCreatedAt:
          _.minBy(elements, "created_at")?.created_at.toISOString() ??
          "a-long-time-ago-in-a-galaxy-far-far-away",
        roles: elements
          .map((e) => roles.find((r) => r.id === e.rbac_role_id))
          .filter(Boolean) as RBACRole[],
        groupName: elements[0].scim_group_name,
        groupDescription: `Assigned to ${elements
          .map((e) => rolesById[e.rbac_role_id].name)
          .join(", ")}`,
      }),
    )
    .sortBy("mappingCreatedAt")
    .value();

  useEffect(() => {
    const hasOwner = sortedMappings.some((g) =>
      g.roles.some((r) => r.slug === BaseRoleSlugEnum.Owner),
    );
    setHasSetOwnerMapping(hasOwner);
  }, [setHasSetOwnerMapping, roles, sortedMappings]);

  const refetchUsers = useRevalidate(["usersList"]);
  const { trigger: onDelete } = useAPIMutation(
    "sCIMShowRoleMappings",
    undefined,
    async (apiClient, group: GroupMapping) =>
      await apiClient.sCIMUpdateRoleMappings({
        updateRoleMappingsRequestBody: {
          role_mappings: mappingsToFlattenedPayload(sortedMappings).filter(
            (m) => m.scim_group_id !== group.groupId,
          ),
        },
      }),
    {
      onSuccess: () => {
        // Refetch users in case they've been changed
        refetchUsers();
      },
      onError: (err, fieldErrors) => {
        if (Object.values(fieldErrors ?? {}).length === 0) {
          throw err;
        } else {
          showToast({
            title: "Error",
            description: Object.values(fieldErrors ?? {})[0],
            theme: ToastTheme.Error,
          });
        }
      },
    },
  );
  const addAssignmentButton = (
    <GatedButton
      theme={ButtonTheme.Secondary}
      onClick={() => setIsAddingGroup(true)}
      analyticsTrackingId="add-scim-group-role-mapping"
      icon={IconEnum.Add}
      requiredScope={ScopeNameEnum.ScimUpdate}
    >
      Add assignment
    </GatedButton>
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    throw error;
  }

  const showEmptyState = sortedMappings.length === 0;

  const createEditProps: CreateEditFormProps<GroupMapping> = editingGroup
    ? { mode: Mode.Edit, initialData: editingGroup }
    : { mode: Mode.Create };

  return (
    <>
      <div className="mt-5 space-y-5">
        {showHeading && (
          <SettingsSubHeading
            title={"Role assignments"}
            explanation={
              <>
                <p className="!mb-1.5">
                  Each SCIM user is given the &lsquo;Standard&rsquo; role by
                  default. Here, you can assign here additional roles to users
                  based on their SCIM group membership.
                </p>
                <p>
                  If you assign any permissions other than
                  &lsquo;Standard&rsquo;, those users will use a Responder seat.
                </p>
              </>
            }
            className="mb-3"
            accessory={showEmptyState ? null : addAssignmentButton}
          />
        )}
        {showEmptyState ? (
          <EmptyState
            icon={IconEnum.Users}
            cta={addAssignmentButton}
            content="You haven't assigned any roles to SCIM groups yet — you'll need to assign at least one owner before we start syncing."
          />
        ) : (
          <>
            <StackedList>
              {sortedMappings.map((group) => (
                <SettingsListItem
                  key={`${group.groupId}-${group.roles
                    .map((e) => e.id)
                    .join("-")}`}
                  title={group.groupName}
                  description={group.groupDescription}
                  buttons={{
                    requiredScope: ScopeNameEnum.ScimUpdate,
                    edit: {
                      onEdit: () => setEditingGroup(group),
                    },
                    delete: {
                      resourceTitle: group.groupName,
                      deleteConfirmationTitle: "Remove roles?",
                      deleteConfirmationContent: (
                        <>
                          Are you sure you wish to remove the roles assigned to{" "}
                          <span className="font-bold">{group.groupName}</span>?
                          These users will be downgraded to &apos;Viewer&apos;
                          permissions.
                        </>
                      ),
                      onDelete: () => onDelete(group),
                    },
                  }}
                />
              ))}
            </StackedList>
          </>
        )}

        {!showHeading && !showEmptyState && addAssignmentButton}

        {editingGroup || isAddingGroup ? (
          <ScimGroupRoleMappingCreateEditModal
            {...createEditProps}
            currentMappings={sortedMappings}
            scimConfigState={scimConfigState}
            roles={roles}
            onClose={() =>
              editingGroup
                ? setEditingGroup(undefined)
                : setIsAddingGroup(false)
            }
            refetchUsers={refetchUsers}
          />
        ) : null}
      </div>
    </>
  );
};

export const mappingsToFlattenedPayload = (currentMappings: GroupMapping[]) => {
  return currentMappings.flatMap((m) =>
    m.roles.map(
      (e): RoleMappingPayload => ({
        scim_group_id: m.groupId,
        rbac_role_id: e.id,
      }),
    ),
  );
};
