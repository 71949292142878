/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody
 */
export interface UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody {
    /**
     * Defines whether to send notification updates via email
     * @type {boolean}
     * @memberof UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody
     */
    receives_notifications_via_email: boolean;
    /**
     * Defines whether to send notification updates via Slack
     * @type {boolean}
     * @memberof UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody
     */
    receives_notifications_via_slack: boolean;
}

export function UserPreferencesUpdateNotificationDeliveryPreferencesRequestBodyFromJSON(json: any): UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody {
    return UserPreferencesUpdateNotificationDeliveryPreferencesRequestBodyFromJSONTyped(json, false);
}

export function UserPreferencesUpdateNotificationDeliveryPreferencesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'receives_notifications_via_email': json['receives_notifications_via_email'],
        'receives_notifications_via_slack': json['receives_notifications_via_slack'],
    };
}

export function UserPreferencesUpdateNotificationDeliveryPreferencesRequestBodyToJSON(value?: UserPreferencesUpdateNotificationDeliveryPreferencesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'receives_notifications_via_email': value.receives_notifications_via_email,
        'receives_notifications_via_slack': value.receives_notifications_via_slack,
    };
}

