import {
  CatalogType,
  CatalogTypeAttributePayload,
  CatalogTypeAttributePayloadModeEnum,
  CatalogTypeCategoriesEnum,
  IntegrationSettings,
} from "@incident-io/api";
import {
  drawerUrlFor,
  IntegrationConfigFor,
} from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { BadgeSize, Button, ButtonTheme, IconEnum, Loader } from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import _, { compact } from "lodash";
import { useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { useClient } from "../../../contexts/ClientContext";
import { useIntegrations } from "../../../hooks/useIntegrations";
import { useAPI, useAPIInfinite } from "../../../utils/swr";
import { CatalogEntryList } from "../entry-list/CatalogEntryList";
import { CatalogWizardIntegrationDrawerRoutes } from "./CatalogWizardIntegrationDrawerRoutes";
import { WizardCompletedModal } from "./WizardCompletedModal";
import { WizardLayout } from "./WizardLayout";

const OPEN_EDIT_DRAWER_FOR_TYPE_QUERY_PARAM = "editing_type";

interface AttributeButton {
  type: string;
  label: string;
  icon: IconEnum;
  color?: ColorPaletteEnum;
  existing: boolean;
  onClickHandler: () => void;
}

export interface ExtraAddAttributeButton {
  type: string;
  label: string;
  icon: IconEnum;
  color: ColorPaletteEnum;
}

// This is a little bit nasty but it has to handle quite a few flows, including
// creating attributes, opening the bulk edit drawer for them and handling the
// case where you're trying to add an attribute for a type that doesn't have an
// installed integration
export const CatalogWizardAddAttributesStep = ({
  category,
  resourceTypesToDisplayAsButtons,
  additionalButtons,
}: {
  category: CatalogTypeCategoriesEnum;
  resourceTypesToDisplayAsButtons: string[];
  additionalButtons?: ExtraAddAttributeButton[];
}) => {
  const navigate = useOrgAwareNavigate();
  const client = useClient();
  const [hasOverMaxEntriesForBulkEdit] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { typeId } = useParams();
  if (!typeId) {
    throw Error();
  }
  const {
    data: typeData,
    error: typeError,
    mutate: revalidateType,
  } = useAPI("catalogShowType", {
    id: typeId,
  });
  const { data: recommendedAttributeData, error: recommendedAttributeError } =
    useAPI("catalogListRecommendedAttributeResources", {
      type: category,
    });
  const { integrations, integrationsError } = useIntegrations();
  const {
    responses: entriesPages,
    isLoading: entriesLoading,
    refetch: refetchEntries,
    isFullyLoaded: allEntriesLoaded,
    loadMore: loadMoreEntries,
    error: listEntriesError,
  } = useAPIInfinite(
    typeData ? "catalogListEntries" : null,
    {
      catalogTypeId: typeData?.catalog_type.id || "",
      includeDerivedAttributes: true,
      includeReferences: true,
      pageSize: 25,
      search: "",
      revalidateFirstPage: true,
    },
    {
      revalidateOnMount: true,
    },
  );

  const [showCompletedModal, setShowCompletedModal] = useState(false);

  if (
    typeError ||
    recommendedAttributeError ||
    integrationsError ||
    listEntriesError
  ) {
    throw Error();
  }

  const priorityResources = recommendedAttributeData
    ? compact(
        resourceTypesToDisplayAsButtons.map((rt) => {
          const resource = recommendedAttributeData.resources.find(
            (resource) => resource.resource_type === rt,
          );
          if (!resource) {
            console.error(
              `Priority resource ${rt} not in recommended attributes list`,
            );
          }
          return resource;
        }),
      )
    : [];

  const otherResources =
    recommendedAttributeData?.resources
      .filter(
        (resource) =>
          !resourceTypesToDisplayAsButtons.includes(resource.resource_type),
      )
      .sort((a, b): number =>
        a.resource_type_label > b.resource_type_label ? 1 : -1,
      ) || [];
  const sortedResources = [...priorityResources, ...otherResources];

  // Given a catalog type and a resource, create a new attribute of the type of
  // the resource. Once that's done, revalidate the type and open the bulk edit
  // drawer.
  const createAttribute = async ({
    catalogType,
    name,
    resourceType,
  }: {
    catalogType: CatalogType;
    name: string;
    resourceType: string;
  }) => {
    const existingAttributes: CatalogTypeAttributePayload[] =
      catalogType.schema.attributes.map((c) => {
        return {
          ...c,
          mode: c.mode as unknown as CatalogTypeAttributePayloadModeEnum,
        };
      });
    const newAttribute: CatalogTypeAttributePayload = {
      array: false,
      name: name,
      type: resourceType,
      metadata: { "incident.io/source": `catalog-${category}-wizard` },
    };

    const updatedType = await client.catalogUpdateTypeSchema({
      id: catalogType.id,
      updateTypeSchemaRequestBody: {
        attributes: [...existingAttributes, newAttribute],
        version: catalogType.schema.version,
      },
    });

    const attributeId = updatedType.catalog_type.schema.attributes.find(
      (a) => a.type === resourceType,
    );

    await revalidateType();
    navigate(`bulk-edit/${attributeId?.id}?autofill=true`);
  };

  const resourceTypeQueryParam = searchParams.get(
    OPEN_EDIT_DRAWER_FOR_TYPE_QUERY_PARAM,
  );

  // Handle getting redirected back to this page with a query param indicating
  // that we should be creating a new attribute of a specific type and then
  // opening the bulk edit drawer for it.
  useEffect(() => {
    (async () => {
      if (resourceTypeQueryParam && typeData) {
        // Check if it's a valid resource type and return early if it's not
        const resource = sortedResources.find(
          (r) => r.resource_type === resourceTypeQueryParam,
        );
        if (!resource) {
          return;
        }

        // Try and find an existing attribute of this type on the catalog type
        const maybeExistingAttribute =
          typeData.catalog_type?.schema.attributes.find(
            (a) => a.type === resourceTypeQueryParam,
          );

        // If we don't have an attribute of this type, we should create one
        if (!maybeExistingAttribute) {
          await createAttribute({
            catalogType: typeData.catalog_type,
            name: resource.resource_type_label,
            resourceType: resource.resource_type,
          });
        } else {
          setSearchParams({});
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTypeQueryParam, typeData]);

  const loading = !typeData || !recommendedAttributeData || !integrations;

  if (loading) {
    return <Loader />;
  }

  if (typeData.catalog_type.source_repo_url) {
    // The resource is externally managed, lets not mutate with new attributes
    return <Navigate to={`/catalog/${typeId}`} />;
  }

  const existingAttributeTypes = _.uniq(
    typeData.catalog_type?.schema.attributes.map((a) => a.type),
  );

  const buttonOnClickHandler = async ({
    attributeLabel,
    resourceType,
    integration,
  }: {
    attributeLabel: string;
    resourceType: string;
    integration?: IntegrationSettings;
  }) => {
    setPopoverOpen(false);
    if (integration && !integration.installed) {
      navigate(
        `integrate/${drawerUrlFor(
          integration.provider,
        )}?return=/catalog/${category}-wizard/${typeId}/add-attributes?${OPEN_EDIT_DRAWER_FOR_TYPE_QUERY_PARAM}=${resourceType}`,
      );
    } else {
      await createAttribute({
        catalogType: typeData.catalog_type,
        name: attributeLabel,
        resourceType: resourceType,
      });
    }
  };

  const buttons: AttributeButton[] = [];

  // Start with any additional buttons
  if (additionalButtons) {
    additionalButtons.forEach((button) => {
      buttons.push({
        ...button,
        existing: existingAttributeTypes.includes(button.type),
        onClickHandler: () =>
          buttonOnClickHandler({
            attributeLabel: button.label,
            resourceType: button.type,
          }),
      });
    });
  }

  sortedResources.forEach((resource) => {
    const integration = integrations.find(
      (i) => i.provider === resource.required_integration,
    );
    if (!integration) {
      throw new Error(
        `No integration found for ${resource.required_integration}`,
      );
    }
    buttons.push({
      type: resource.resource_type,
      label: resource.resource_type_label,
      icon: IntegrationConfigFor(integration.provider)?.icon,
      existing: existingAttributeTypes.includes(resource.resource_type),
      onClickHandler: () =>
        buttonOnClickHandler({
          attributeLabel: resource.resource_type_label,
          resourceType: resource.resource_type,
          integration,
        }),
    });
  });

  const priorityButtons = buttons.slice(
    0,
    resourceTypesToDisplayAsButtons.length,
  );
  const popoverButtons = buttons
    .slice(resourceTypesToDisplayAsButtons.length)
    .filter((b) => !b.existing);

  return (
    <WizardLayout
      title={
        typeData.catalog_type.registry_type
          ? `Add attributes to ${typeData.catalog_type.name}`
          : "Add attributes"
      }
      subtitle="Attach relevant information to your Teams to power your workflows and routing"
      stepID="add-attributes"
      category={category}
      below={
        <CatalogEntryList
          catalogType={typeData.catalog_type}
          catalogEntries={entriesPages}
          loadMoreEntries={loadMoreEntries}
          entriesLoading={entriesLoading}
          refetchEntries={refetchEntries}
          allEntriesLoaded={allEntriesLoaded}
          probablyStillSyncing={false}
          hasOverMaxEntriesForBulkEdit={hasOverMaxEntriesForBulkEdit}
          editDrawerBackHref={`/catalog/${category}-wizard/${typeId}/add-attributes`}
          scrollToRightHandColumn
          canClickEntries={false}
        />
      }
      footer={
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          loading={false}
          disabled={false}
          onClick={() => setShowCompletedModal(true)}
        >
          Finish setup
        </Button>
      }
    >
      <CatalogWizardIntegrationDrawerRoutes
        backHref={`/catalog/${category}-wizard/${typeId}/add-attributes`}
      />
      <div className="mx-auto">
        <div className={"flex gap-2 flex-wrap justify-center"}>
          {priorityButtons.map((b) => {
            return (
              <Button
                analyticsTrackingId={""}
                key={b.type}
                icon={b.icon}
                size={BadgeSize.Medium}
                theme={ButtonTheme.Secondary}
                onClick={b.onClickHandler}
                disabled={existingAttributeTypes.includes(b.type)}
                className="border border-dashed hover:border-solid border-stroke-hover bg-transparent text-primary-500 shadow-none"
                iconProps={{
                  className: b.color ? getColorPalette(b.color).text : "",
                }}
              >
                {b.label}
              </Button>
            );
          })}
          <Popover
            onInteractOutside={() => setPopoverOpen(false)}
            open={popoverOpen}
            className={"relative left-[93px] bottom-[30px]"}
            trigger={
              <Button
                onClick={() => setPopoverOpen(true)}
                theme={ButtonTheme.Tertiary}
                size={BadgeSize.Medium}
                icon={IconEnum.Add}
                analyticsTrackingId={null}
                className="border border-stroke-hover bg-transparent text-primary-500 shadow-none"
                title=""
              />
            }
          >
            {popoverButtons.map((b) => (
              <PopoverItem
                key={b.label}
                className={"p-3"}
                onClick={b.onClickHandler}
                icon={b.icon}
              >
                {b.label}
              </PopoverItem>
            ))}
          </Popover>
        </div>
      </div>
      {showCompletedModal && <WizardCompletedModal category={category} />}
    </WizardLayout>
  );
};
