/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NudgeWorkflowButton
 */
export interface NudgeWorkflowButton {
    /**
     * The emoji on the button
     * @type {string}
     * @memberof NudgeWorkflowButton
     */
    emoji: string;
    /**
     * The text of the button
     * @type {string}
     * @memberof NudgeWorkflowButton
     */
    text: string;
    /**
     * The ID of the workflow to run
     * @type {string}
     * @memberof NudgeWorkflowButton
     */
    workflow_id: string;
}

export function NudgeWorkflowButtonFromJSON(json: any): NudgeWorkflowButton {
    return NudgeWorkflowButtonFromJSONTyped(json, false);
}

export function NudgeWorkflowButtonFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgeWorkflowButton {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emoji': json['emoji'],
        'text': json['text'],
        'workflow_id': json['workflow_id'],
    };
}

export function NudgeWorkflowButtonToJSON(value?: NudgeWorkflowButton | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emoji': value.emoji,
        'text': value.text,
        'workflow_id': value.workflow_id,
    };
}

