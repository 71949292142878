import {
  InternalStatusPageContentSummary,
  StatusPageAffectedComponentStatusEnum,
  StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum,
} from "@incident-io/api";
import {
  GenericAffectedComponent,
  SystemStatus as SystemStatusRender,
  withSentryErrorBoundary,
} from "@incident-io/status-page-ui";
import { DateTime } from "luxon";
import React, { useState } from "react";

import { toGenericStructureItem } from "./utils";

const InternalSystemStatusReal = ({
  summary,
}: {
  summary: InternalStatusPageContentSummary;
}): React.ReactElement => {
  const [endAt, setEndAt] = useState(DateTime.now);

  const affectedComponents: GenericAffectedComponent[] = [];
  summary.ongoing_incidents.forEach((inc) => {
    inc.affected_components.forEach((comp) => {
      affectedComponents.push({
        component_id: comp.id,
        status: StatusPageAffectedComponentStatusEnum.FullOutage,
      });
    });
  });

  const structure = {
    // For now, we always show uptime mode in internal status pages
    display_uptime_mode: DisplayUptimeModeEnum.ChartAndPercentage,
    items: summary.structure.components.map(toGenericStructureItem),
  };

  return (
    <SystemStatusRender
      endAt={endAt}
      setEndAt={setEndAt}
      affectedComponents={affectedComponents}
      componentImpacts={{
        component_impacts: [],
        component_uptimes: [],
        incident_links: [],
      }}
      // Internal statuspages don't really need a 'data available since', so we can
      // just use 1/1/2000
      dataAvailableSince={DateTime.fromISO("2000-01-01")}
      structure={structure}
      isLoading={false}
      withoutTooltips
      autoExpandGroups={summary.auto_expand_groups}
      incidents={summary.ongoing_incidents}
    />
  );
};

export const InternalSystemStatus = withSentryErrorBoundary(
  InternalSystemStatusReal,
  "InternalSystemStatus",
);
