/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RBACRole
 */
export interface RBACRole {
    /**
     * 
     * @type {Date}
     * @memberof RBACRole
     */
    created_at: Date;
    /**
     * Description of the purpose for the RBAC role
     * @type {string}
     * @memberof RBACRole
     */
    description?: string;
    /**
     * Unique identifier of the RBAC role
     * @type {string}
     * @memberof RBACRole
     */
    id: string;
    /**
     * Whether the role is custom or base (managed by incident.io)
     * @type {boolean}
     * @memberof RBACRole
     */
    is_base_role: boolean;
    /**
     * Name of the RBAC role
     * @type {string}
     * @memberof RBACRole
     */
    name: string;
    /**
     * ID of the organisation who owns the RBAC role
     * @type {string}
     * @memberof RBACRole
     */
    organisation_id: string;
    /**
     * Privileges assigned with this RBAC role
     * @type {Array<string>}
     * @memberof RBACRole
     */
    privileges: Array<string>;
    /**
     * Indicates how the role should be sorted: more important roles have a higher number
     * @type {number}
     * @memberof RBACRole
     */
    rank: number;
    /**
     * Unique human-readable slug for the RBAC role
     * @type {string}
     * @memberof RBACRole
     */
    slug: string;
    /**
     * 
     * @type {Date}
     * @memberof RBACRole
     */
    updated_at: Date;
}

export function RBACRoleFromJSON(json: any): RBACRole {
    return RBACRoleFromJSONTyped(json, false);
}

export function RBACRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RBACRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'is_base_role': json['is_base_role'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'privileges': json['privileges'],
        'rank': json['rank'],
        'slug': json['slug'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function RBACRoleToJSON(value?: RBACRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'is_base_role': value.is_base_role,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'privileges': value.privileges,
        'rank': value.rank,
        'slug': value.slug,
        'updated_at': (value.updated_at.toISOString()),
    };
}

