/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntryReference,
    CatalogEntryReferenceFromJSON,
    CatalogEntryReferenceFromJSONTyped,
    CatalogEntryReferenceToJSON,
} from './CatalogEntryReference';

/**
 * 
 * @export
 * @interface EngineParamBindingValue
 */
export interface EngineParamBindingValue {
    /**
     * 
     * @type {CatalogEntryReference}
     * @memberof EngineParamBindingValue
     */
    catalog_entry?: CatalogEntryReference;
    /**
     * Gives a description of the option to the user
     * @type {string}
     * @memberof EngineParamBindingValue
     */
    helptext?: string;
    /**
     * If appropriate, URL to an image that can be displayed alongside the option
     * @type {string}
     * @memberof EngineParamBindingValue
     */
    image_url?: string;
    /**
     * If true, the image_url is a Slack icon and should be displayed as such
     * @type {boolean}
     * @memberof EngineParamBindingValue
     */
    is_image_slack_icon?: boolean;
    /**
     * Human readable label to be displayed for user to select
     * @type {string}
     * @memberof EngineParamBindingValue
     */
    label: string;
    /**
     * If set, this is the literal value of the step parameter
     * @type {string}
     * @memberof EngineParamBindingValue
     */
    literal?: string;
    /**
     * If set, this is the reference into the trigger scope that is the value of this parameter
     * @type {string}
     * @memberof EngineParamBindingValue
     */
    reference?: string;
    /**
     * Gives an indication of how to sort the options when displayed to the user
     * @type {string}
     * @memberof EngineParamBindingValue
     */
    sort_key: string;
    /**
     * Unavailable is true if we've failed to build the value for this binding
     * @type {boolean}
     * @memberof EngineParamBindingValue
     */
    unavailable?: boolean;
    /**
     * Either the reference or the literal: this field is designed purely to make working with react-select easier
     * @type {string}
     * @memberof EngineParamBindingValue
     */
    value?: string;
}

export function EngineParamBindingValueFromJSON(json: any): EngineParamBindingValue {
    return EngineParamBindingValueFromJSONTyped(json, false);
}

export function EngineParamBindingValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineParamBindingValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry': !exists(json, 'catalog_entry') ? undefined : CatalogEntryReferenceFromJSON(json['catalog_entry']),
        'helptext': !exists(json, 'helptext') ? undefined : json['helptext'],
        'image_url': !exists(json, 'image_url') ? undefined : json['image_url'],
        'is_image_slack_icon': !exists(json, 'is_image_slack_icon') ? undefined : json['is_image_slack_icon'],
        'label': json['label'],
        'literal': !exists(json, 'literal') ? undefined : json['literal'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'sort_key': json['sort_key'],
        'unavailable': !exists(json, 'unavailable') ? undefined : json['unavailable'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function EngineParamBindingValueToJSON(value?: EngineParamBindingValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry': CatalogEntryReferenceToJSON(value.catalog_entry),
        'helptext': value.helptext,
        'image_url': value.image_url,
        'is_image_slack_icon': value.is_image_slack_icon,
        'label': value.label,
        'literal': value.literal,
        'reference': value.reference,
        'sort_key': value.sort_key,
        'unavailable': value.unavailable,
        'value': value.value,
    };
}

