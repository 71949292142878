/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface LinearIssueTemplate
 */
export interface LinearIssueTemplate {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof LinearIssueTemplate
     */
    assignee_id?: EngineParamBinding;
    /**
     * The context in which the issue template will be used
     * @type {string}
     * @memberof LinearIssueTemplate
     */
    context: LinearIssueTemplateContextEnum;
    /**
     * If the issue template is in use
     * @type {boolean}
     * @memberof LinearIssueTemplate
     */
    enabled: boolean;
    /**
     * The expressions to use in this template
     * @type {Array<Expression>}
     * @memberof LinearIssueTemplate
     */
    expressions?: Array<Expression>;
    /**
     * 
     * @type {string}
     * @memberof LinearIssueTemplate
     */
    id: string;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof LinearIssueTemplate
     */
    labels?: EngineParamBinding;
    /**
     * The name of the issue template
     * @type {string}
     * @memberof LinearIssueTemplate
     */
    name: string;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof LinearIssueTemplate
     */
    project_id?: EngineParamBinding;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof LinearIssueTemplate
     */
    team_id?: EngineParamBinding;
}

/**
* @export
* @enum {string}
*/
export enum LinearIssueTemplateContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}

export function LinearIssueTemplateFromJSON(json: any): LinearIssueTemplate {
    return LinearIssueTemplateFromJSONTyped(json, false);
}

export function LinearIssueTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinearIssueTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : EngineParamBindingFromJSON(json['assignee_id']),
        'context': json['context'],
        'enabled': json['enabled'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'id': json['id'],
        'labels': !exists(json, 'labels') ? undefined : EngineParamBindingFromJSON(json['labels']),
        'name': json['name'],
        'project_id': !exists(json, 'project_id') ? undefined : EngineParamBindingFromJSON(json['project_id']),
        'team_id': !exists(json, 'team_id') ? undefined : EngineParamBindingFromJSON(json['team_id']),
    };
}

export function LinearIssueTemplateToJSON(value?: LinearIssueTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': EngineParamBindingToJSON(value.assignee_id),
        'context': value.context,
        'enabled': value.enabled,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'id': value.id,
        'labels': EngineParamBindingToJSON(value.labels),
        'name': value.name,
        'project_id': EngineParamBindingToJSON(value.project_id),
        'team_id': EngineParamBindingToJSON(value.team_id),
    };
}

