/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegacyPostmortemTemplateBlock,
    LegacyPostmortemTemplateBlockFromJSON,
    LegacyPostmortemTemplateBlockFromJSONTyped,
    LegacyPostmortemTemplateBlockToJSON,
} from './LegacyPostmortemTemplateBlock';
import {
    PostmortemDocumentRenderOptions,
    PostmortemDocumentRenderOptionsFromJSON,
    PostmortemDocumentRenderOptionsFromJSONTyped,
    PostmortemDocumentRenderOptionsToJSON,
} from './PostmortemDocumentRenderOptions';

/**
 * 
 * @export
 * @interface PostmortemsRenderRequestBody
 */
export interface PostmortemsRenderRequestBody {
    /**
     * Ordered content blocks to generate this preview
     * @type {Array<LegacyPostmortemTemplateBlock>}
     * @memberof PostmortemsRenderRequestBody
     */
    blocks?: Array<LegacyPostmortemTemplateBlock>;
    /**
     * The document provider we're rendering into
     * @type {string}
     * @memberof PostmortemsRenderRequestBody
     */
    document_provider?: PostmortemsRenderRequestBodyDocumentProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof PostmortemsRenderRequestBody
     */
    incident_id: string;
    /**
     * The legacy template we should use when rendering the postmortem
     * @type {string}
     * @memberof PostmortemsRenderRequestBody
     */
    legacy_template_id?: string;
    /**
     * 
     * @type {PostmortemDocumentRenderOptions}
     * @memberof PostmortemsRenderRequestBody
     */
    postmortem_document_options: PostmortemDocumentRenderOptions;
    /**
     * The template we should use when rendering the postmortem
     * @type {string}
     * @memberof PostmortemsRenderRequestBody
     */
    postmortem_template_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemsRenderRequestBodyDocumentProviderEnum {
    Empty = '',
    Confluence = 'confluence',
    GoogleDocs = 'google_docs',
    Notion = 'notion',
    CopyPasteBasecamp = 'copy_paste_basecamp',
    CopyPasteConfluence = 'copy_paste_confluence',
    CopyPasteGithubWiki = 'copy_paste_github_wiki',
    CopyPasteGoogleDocs = 'copy_paste_google_docs',
    CopyPasteNotion = 'copy_paste_notion',
    CopyPasteQuip = 'copy_paste_quip'
}

export function PostmortemsRenderRequestBodyFromJSON(json: any): PostmortemsRenderRequestBody {
    return PostmortemsRenderRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsRenderRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsRenderRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocks': !exists(json, 'blocks') ? undefined : ((json['blocks'] as Array<any>).map(LegacyPostmortemTemplateBlockFromJSON)),
        'document_provider': !exists(json, 'document_provider') ? undefined : json['document_provider'],
        'incident_id': json['incident_id'],
        'legacy_template_id': !exists(json, 'legacy_template_id') ? undefined : json['legacy_template_id'],
        'postmortem_document_options': PostmortemDocumentRenderOptionsFromJSON(json['postmortem_document_options']),
        'postmortem_template_id': !exists(json, 'postmortem_template_id') ? undefined : json['postmortem_template_id'],
    };
}

export function PostmortemsRenderRequestBodyToJSON(value?: PostmortemsRenderRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocks': value.blocks === undefined ? undefined : ((value.blocks as Array<any>).map(LegacyPostmortemTemplateBlockToJSON)),
        'document_provider': value.document_provider,
        'incident_id': value.incident_id,
        'legacy_template_id': value.legacy_template_id,
        'postmortem_document_options': PostmortemDocumentRenderOptionsToJSON(value.postmortem_document_options),
        'postmortem_template_id': value.postmortem_template_id,
    };
}

