import {
  StatusPageIncident,
  StatusPageIncidentTypeEnum,
  StatusPagePageTypeEnum,
  StatusPageSubPageSlim,
} from "@incident-io/api";
import {
  ButtonTheme,
  DropdownMenu,
  GatedDropdownMenuItem,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useAPIMutation } from "src/utils/swr";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { customerPageVisitURL } from "../../../../utils/utils";

export const AffectedSubpages = ({
  incident,
  pageType,
}: {
  incident: StatusPageIncident;
  pageType: StatusPagePageTypeEnum;
}) => {
  return (
    <div className="space-y-2 pb-1.5">
      <Heading level={3} size="small" className="font-medium">
        Affected{" "}
        {pageType === StatusPagePageTypeEnum.Customer
          ? "customer pages"
          : "sub-pages"}
      </Heading>
      <StackedList className="text-sm">
        {incident.sub_pages.map((subpage) => (
          <AffectedSubPageRow
            subpage={subpage}
            key={subpage.id}
            incident={incident}
            pageType={pageType}
          />
        ))}
      </StackedList>
    </div>
  );
};

export const AffectedSubPageRow = ({
  subpage,
  incident,
  pageType,
}: {
  subpage: StatusPageSubPageSlim;
  incident: StatusPageIncident;
  pageType: StatusPagePageTypeEnum;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { identity } = useIdentity();

  return (
    <>
      {showDeleteModal && (
        <RemoveFromSubPageModal
          subpage={subpage}
          incident={incident}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      <div className="flex items-center justify-between p-2">
        {subpage.name}
        <div className="flex items-center space-x-1">
          <Link
            analyticsTrackingId="status-page-incident-visit-affected-subpage"
            href={
              pageType === StatusPagePageTypeEnum.Customer && identity
                ? customerPageVisitURL(
                    incident.status_page_id,
                    subpage.id,
                    identity.organisation_id,
                  )
                : subpage.public_url
            }
            key={subpage.id}
            className="no-underline !text-content-tertiary hover:!text-content-primary transition"
            title="View sub-page incident"
            openInNewTab
          >
            <Icon id={IconEnum.ExternalLink} size={IconSize.Medium} />
          </Link>
          <DropdownMenu
            triggerButtonTheme={ButtonTheme.Unstyled}
            triggerIconSize={IconSize.Large}
            analyticsTrackingId={"status-page-item-more-options"}
            screenReaderText="More options"
            triggerIcon={IconEnum.DotsVertical}
          >
            <GatedDropdownMenuItem
              onSelect={() => setShowDeleteModal(true)}
              analyticsTrackingId="status-page-remove-incident-from-subpage"
              label={"Remove incident from sub-page"}
            >
              Remove incident from sub-page
            </GatedDropdownMenuItem>
          </DropdownMenu>
        </div>
      </div>
    </>
  );
};

const RemoveFromSubPageModal = ({
  subpage,
  incident,
  onClose,
}: {
  subpage: StatusPageSubPageSlim;
  incident: StatusPageIncident;
  onClose: () => void;
}) => {
  const showToast = useToast();
  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient) =>
      await apiClient.statusPageRemoveIncidentFromSubPage({
        id: incident.id,
        removeIncidentFromSubPageRequestBody: {
          status_page_sub_page_id: subpage.id,
        },
      }),
    {
      onSuccess: () => {
        {
          incident.type === StatusPageIncidentTypeEnum.Maintenance
            ? showToast({
                title: "Maintenance removed from sub-page",
                description: `This maintenance will no longer appear on the ${subpage.name} sub-page.`,
                theme: ToastTheme.Success,
              })
            : showToast({
                title: "Incident removed from sub-page",
                description: `This incident will no longer appear on the ${subpage.name} sub-page.`,
                theme: ToastTheme.Success,
              });
        }

        onClose();
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description:
            incident.type === StatusPageIncidentTypeEnum.Maintenance
              ? "Could not remove maintenance from sub-page"
              : "Could not remove incident from sub-page",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  return (
    <Modal
      isOpen
      onClose={onClose}
      analyticsTrackingId="sp-inc-remove-from-sub-page"
      analyticsTrackingMetadata={{
        statusPageSubPageId: subpage.id,
        statusPageIncidentId: incident.id,
      }}
      title={
        incident.type === StatusPageIncidentTypeEnum.Maintenance
          ? "Remove maintenance from sub-page"
          : "Remove incident from sub-page"
      }
    >
      <ModalContent className={"text-sm space-y-2"}>
        {genericError && <GenericErrorMessage />}
        <p>
          Are you sure you want to remove{" "}
          <span className="font-semibold">{incident.name}</span> from the{" "}
          <span className="font-semibold">{subpage.name}</span> sub-page?
        </p>
        <p>
          It will no longer be visible on that sub-page, and links to it will no
          longer work. This may be confusing for any subscribers who have
          received updates for this incident.
        </p>

        <p>
          We won&rsquo;t automatically add it back to this sub-page even if it
          affects additional components on the sub-page.
        </p>
      </ModalContent>
      <ModalFooter
        confirmButtonType="button"
        confirmButtonText="Remove"
        onConfirm={() => trigger({})}
        onClose={onClose}
        saving={isMutating}
      />
    </Modal>
  );
};
