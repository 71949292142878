import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { isEmpty } from "lodash";
import {
  CustomFieldTypeInfoFieldTypeEnum,
  IncidentActivityLogEntry,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

import { ActivityBadge } from "./ActivityBadge";
import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemCustomFieldValueUpdate = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.custom_field_value_update) {
    throw new Error(
      "malformed timeline item: custom_field_value_update was missing custom_field_value_update field",
    );
  }
  const hasPrevious = !isEmpty(
    item.content.custom_field_value_update?.previous_custom_field_values,
  );
  const hasNext = !isEmpty(
    item.content.custom_field_value_update?.custom_field_values,
  );

  let verb: string;
  if (hasPrevious && hasNext) {
    verb = "updated";
  } else if (hasPrevious && !hasNext) {
    verb = "unset";
  } else {
    verb = "set";
  }

  const updateObj = item.content.custom_field_value_update;

  let previousLabel: string | undefined = undefined;
  let nextLabel = "";

  // Figure out what the previous and next values should be for the badge, according
  // to the type of custom field.
  switch (updateObj.custom_field.field_type) {
    case CustomFieldTypeInfoFieldTypeEnum.SingleSelect:
    case CustomFieldTypeInfoFieldTypeEnum.MultiSelect: {
      previousLabel = updateObj.previous_custom_field_values
        ?.map(
          ({ value_option, value_catalog_entry }) =>
            value_option?.value || value_catalog_entry?.name,
        )
        .join(", ");
      nextLabel =
        updateObj.custom_field_values
          ?.map(
            ({ value_option, value_catalog_entry }) =>
              value_option?.value || value_catalog_entry?.name,
          )
          .join(", ") || "Empty";
      break;
    }

    case CustomFieldTypeInfoFieldTypeEnum.Text: {
      previousLabel = updateObj.previous_custom_field_values
        ?.map(({ value_text }) => value_text)
        .join(", ");
      nextLabel =
        updateObj.custom_field_values
          ?.map(({ value_text }) => value_text)
          .join(", ") || "Empty";
      break;
    }

    case CustomFieldTypeInfoFieldTypeEnum.Link: {
      previousLabel = updateObj.previous_custom_field_values
        ?.map(({ value_link }) => value_link)
        .join(", ");
      nextLabel =
        updateObj.custom_field_values
          ?.map(({ value_link }) => value_link)
          .join(", ") || "Empty";
      break;
    }

    case CustomFieldTypeInfoFieldTypeEnum.Numeric: {
      previousLabel = updateObj.previous_custom_field_values
        ?.map(({ value_numeric }) => value_numeric)
        .join(", ");
      nextLabel =
        updateObj.custom_field_values
          ?.map(({ value_numeric }) => value_numeric)
          .join(", ") || "Empty";
      break;
    }

    default:
      assertUnreachable(updateObj.custom_field.field_type);
  }

  return {
    title:
      item.content.custom_field_value_update.custom_field.name +
      " field " +
      verb,
    icon: IconEnum.CustomField,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.custom_field_value_update.actor,
    unquotedContent: nextLabel && (
      <ActivityBadge
        icon={IconEnum.CustomField}
        value={nextLabel}
        previousValue={previousLabel}
      />
    ),
  };
};
