import { StatusPageStructure } from "@incident-io/api";
import { StaticSingleSelect } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { StylesConfig } from "react-select";
import { tcx } from "src/utils/tailwind-classes";

export const AddComponentOrGroup = ({
  selectedComponentIds,
  structure,
  onAddComponent,
  className,
  styles,
}: {
  selectedComponentIds: Set<string>;
  structure: StatusPageStructure;
  onAddComponent: (componentId: string) => void;
  className?: string;
  styles?: (invalid: boolean) => StylesConfig<SelectOption<string>>;
}): React.ReactElement | null => {
  const componentOptions = structure.items.flatMap(({ group, component }) => {
    if (component) {
      if (selectedComponentIds.has(component.component_id)) {
        return [];
      }

      return [{ label: component.name, value: component.component_id }];
    }

    if (group) {
      let options: SelectOption[] = group.components
        .filter((comp) => !selectedComponentIds.has(comp.component_id))
        .map(({ component_id, name }) => ({
          label: `${group.name} -> ${name}`,
          value: component_id,
        }));

      if (options.length > 1) {
        // Add a convenience option to add the rest of the group
        options = [
          {
            label: `${group.name} (All)`,
            value: options.map(({ value }) => value).join(","),
          },
          ...options,
        ];
      }

      return options;
    }

    return [];
  });

  // There's nothing more to add
  if (componentOptions.length === 0) return null;

  return (
    <StaticSingleSelect
      options={componentOptions}
      value={undefined}
      className={tcx("w-[250px]", className)}
      styles={styles}
      placeholder="Add affected component"
      onChange={(val) => {
        if (typeof val !== "string") return;

        const componentIds = val.split(",");
        componentIds.forEach((componentId) => {
          onAddComponent(componentId);
        });
      }}
    />
  );
};
