import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  ContentBox,
  Heading,
  StackedList,
  StaticSingleSelect,
} from "@incident-ui";
import {
  SortableColumn,
  SortableTable,
} from "@incident-ui/Table/SortableTable";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  ScheduleReport,
  ScheduleReportSchedule,
} from "src/contexts/ClientContext";

import { formatCurrency } from "../../../../../utils/currency";
import { formatDurationFromHours } from "../../../../../utils/datetime";
import { useQueryParams } from "../../../../../utils/query-params";
import { buildAggregate, ShiftAggregate } from "../helpers";
import { OverrideListItem, PayRuleListItem } from "../PayRule";
import { UserLink } from "./UserTab";

// ScheduleLink allows linking to a schedule within the report, changing the tab
// to the appropriate location.
export const ScheduleLink = ({
  schedule,
  changeTab,
}: {
  schedule: ScheduleReportSchedule;
  changeTab: (tabID: string, params: Record<string, string>) => void;
}): React.ReactElement => {
  return (
    <a
      className={
        "link underline text-content-primary hover:text-alarmalade-600 cursor-pointer"
      }
      onClick={() => {
        changeTab("schedule", {
          schedule: schedule.id,
        });
      }}
    >
      {schedule.name}
    </a>
  );
};

export const ScheduleTab = ({
  report,
  changeTab,
}: {
  report: ScheduleReport;
  changeTab: (tabID: string, params: Record<string, string>) => void;
}): React.ReactElement => {
  const query = useQueryParams();
  const scheduleIDs = report.schedules.map((schedule) => schedule.id);

  const [scheduleID, setScheduleID] = useState<string>(
    query.get("schedule") || report.schedules[0].id || "invalid",
  );

  useEffect(() => {
    if (query.get("schedule") === scheduleID) {
      return;
    }

    if (!_.includes(scheduleIDs, scheduleID)) {
      const bestGuess = scheduleIDs[0];

      setScheduleID(bestGuess);
      changeTab("schedule", { schedule: bestGuess });
    } else {
      changeTab("schedule", { schedule: scheduleID });
    }
  }, [scheduleID, scheduleIDs, changeTab, query]);

  const schedule = report.schedules.find(
    (schedule) => schedule.id === scheduleID,
  );
  if (!schedule) {
    throw new Error(`schedule with id ${scheduleID} could not be found`);
  }

  const { totalsByUser, currencies } = buildAggregate(report, [schedule]);

  const usersTableColumns: SortableColumn<
    Omit<ShiftAggregate, "schedule" | "rate">
  >[] = [
    {
      title: "User",
      key: "user",
      dataIndex: "user",
    },
    {
      title: "Duration",
      key: "hours",
      dataIndex: "hours",
      alignRight: true,
    },
  ];

  currencies.forEach((currency) => {
    const key = `total_${currency}`;
    usersTableColumns.push({
      title: `Total (${currency})`,
      key,
      dataIndex: key,
      sortFn: (rowA, rowB) => {
        return rowA.totals[currency] < rowB.totals[currency] ? 1 : -1;
      },
      alignRight: true,
    });
  });

  return (
    <div className={"pt-2 space-y-4"}>
      {/* Selector */}
      <div className="max-w-md">
        <StaticSingleSelect
          onChange={(value) => {
            if (value && typeof value === "string") {
              setScheduleID(value);
            }
          }}
          value={scheduleID}
          isClearable={false}
          options={report.schedules.map((schedule) => {
            const totalCents = _.sum(
              schedule.shifts.map((shift) => shift.value_cents),
            );

            return {
              label: `${schedule.name} (${formatCurrency(
                schedule.currency,
                totalCents,
              )})`,
              value: schedule.id,
            };
          })}
          placeholder="Choose schedule"
        />
      </div>

      <RuleAccordion schedule={schedule} />

      {/* User totals */}
      <div>
        <Heading level={3} className="mb-2" size="small">
          Pay by user
        </Heading>
        <SortableTable
          data={totalsByUser}
          columns={usersTableColumns}
          render={({ user, hours, totals }) => {
            return (
              <tr key={user.external_id}>
                <td>
                  <UserLink user={user} changeTab={changeTab} />
                </td>
                <td className={"text-right"}>
                  {formatDurationFromHours(hours)}
                </td>
                {currencies.map((currency: string) => {
                  return (
                    <td key={currency} className={"text-right"}>
                      {formatCurrency(currency, totals[currency])}
                    </td>
                  );
                })}
              </tr>
            );
          }}
          finalRows={
            <tr>
              {currencies.map((currency: string, idx: number) => {
                return (
                  <td
                    key={currency}
                    colSpan={idx === 0 ? 3 : undefined}
                    className="text-right text-sm font-semibold text-content-primary"
                  >
                    {formatCurrency(
                      currency,
                      _.sum(
                        totalsByUser.map((shift) => shift.totals[currency]),
                      ),
                    )}
                  </td>
                );
              })}
            </tr>
          }
        />
      </div>
    </div>
  );
};

export const RuleAccordion = ({
  schedule,
}: {
  schedule: ScheduleReportSchedule;
}): React.ReactElement => {
  return (
    <AccordionProvider type="multiple">
      <ContentBox className="p-4">
        <Accordion
          id="some-unique-id"
          header={
            <div className="flex-center-y">
              <p className="text-sm">Pay rules for this schedule</p>
              <AccordionTriggerButton />{" "}
            </div>
          }
        >
          <div className="pt-2">
            <p className="text-sm text-slate-600 mb-2">
              These are the recurring weekly rules used to calculate amount owed
              for shifts in this schedule.
            </p>
            <StackedList>
              {/* Put the base rate first */}
              {_.sortBy(
                schedule.pay_rules,
                (rule) => !(rule.short_id === "Base Rate"),
              ).map((rule) => {
                return (
                  <PayRuleListItem
                    key={rule.short_id}
                    schedule={schedule}
                    rule={rule}
                  />
                );
              })}
            </StackedList>
            <div className="mt-4">
              {schedule.overrides.length > 0 ? (
                <>
                  <p className="text-sm text-slate-600 mb-2">
                    These are the holiday rules applying to shifts that happened
                    in the reporting period. Holiday rules will always take
                    priority over weekly rules.
                  </p>
                  <StackedList>
                    {schedule.overrides.map((override, index) => {
                      return (
                        <OverrideListItem
                          key={override.id}
                          schedule={schedule}
                          override={override}
                          index={index}
                        />
                      );
                    })}
                  </StackedList>
                </>
              ) : (
                <p className="text-sm text-slate-600">
                  There were no holiday rules applied to this schedule during
                  the reporting period.
                </p>
              )}
            </div>
          </div>
        </Accordion>
      </ContentBox>
    </AccordionProvider>
  );
};
