import {
  CatalogType,
  CustomFieldFieldModeEnum as FieldModeEnum,
  CustomFieldFieldTypeEnum,
  EngineScope,
  Expression,
  Resource,
} from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { Callout, CalloutTheme, Toggle } from "@incident-ui";
import { ExpandableContentBox } from "@incident-ui/ExpandableContentBox/ExpandableContentBox";
import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { CustomField } from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

import { CustomFieldFormData } from "./CustomFieldCreateEditDrawer";
import { DerivedCustomFieldExpressionEditSection } from "./DerivedCustomFieldExpressionEditSection";
import { ManagementType } from "./types";

export const DerivedCustomFieldSection = ({
  customField,
  formMethods,
  scope,
  resources,
  catalogTypes,
  setEngineExpressionEdited,
  templateExpression,
}: {
  customField?: CustomField;
  formMethods: UseFormReturn<CustomFieldFormData>;
  scope: EngineScope;
  resources: Resource[];
  catalogTypes: CatalogType[];
  setEngineExpressionEdited: (value: boolean) => void;
  templateExpression?: Expression;
}): React.ReactElement => {
  const [derived, setDerived] = useState(
    !!customField?.engine_expression || !!templateExpression,
  );

  const [catalogTypeID, fieldType, fieldMode, management] = formMethods.watch([
    "catalog_type_id",
    "field_type",
    "field_mode",
    "management",
  ]);

  const expressionEditorEnabled =
    !customField?.id ||
    [
      CustomFieldFieldTypeEnum.Text,
      CustomFieldFieldTypeEnum.Numeric,
      CustomFieldFieldTypeEnum.Link,
    ].includes(fieldType) ||
    (management === ManagementType.Catalog && !!catalogTypeID);

  useEffect(() => {
    if (derived) {
      const existingFieldMode = customField?.field_mode;
      const updatedFieldMode =
        existingFieldMode && existingFieldMode === FieldModeEnum.FullyDerived
          ? FieldModeEnum.FullyDerived
          : FieldModeEnum.SensibleDefault;
      formMethods.setValue<"field_mode">(
        "field_mode",
        updatedFieldMode || FieldModeEnum.SensibleDefault,
      );
    } else {
      formMethods.setValue<"field_mode">("field_mode", FieldModeEnum.Manual);
    }
  }, [derived, customField, formMethods]);

  return (
    <div className="flex flex-col gap-1 mt-2">
      <Form.Label htmlFor="engine_expression">
        Set field automatically
      </Form.Label>
      <ExpandableContentBox
        titleNode={
          <Toggle
            id="toggle-derived-custom-field"
            on={derived}
            onToggle={() => setDerived(!derived)}
            disabled={!expressionEditorEnabled || !!templateExpression}
            toggleClassName="grow justify-between"
            isDisabledTooltipContent={
              templateExpression
                ? "You must set an expression if selected at the previous step."
                : "You cannot use expressions for non-catalog option types."
            }
            label={
              <span className="font-normal">
                Use an expression to set the value of this field
              </span>
            }
          />
        }
        isExpanded={derived}
      >
        <div className="flex flex-col gap-4">
          <DerivedCustomFieldExpressionEditSection
            formMethods={formMethods}
            name="engine_expression"
            scope={scope}
            disabled={false}
            customFieldId={customField?.id}
            resources={resources}
            resultType={
              customField?.id &&
              mapCustomFieldToResultType({
                fieldType,
                catalogType: catalogTypes.find((x) => x.id === catalogTypeID),
                customFieldID: customField?.id || "NEW_CUSTOM_FIELD",
              })
            }
            resultArray={fieldType === CustomFieldFieldTypeEnum.MultiSelect}
            catalogTypes={catalogTypes}
            setEngineExpressionEdited={setEngineExpressionEdited}
          />
          {customField?.id &&
            customField?.field_mode !== FieldModeEnum.FullyDerived &&
            fieldMode === FieldModeEnum.FullyDerived && (
              <Callout
                theme={CalloutTheme.Warning}
                title="Are you sure you want to prevent user edits to this field?"
                subtitle=" Existing user-inputted values could be overwritten by the derived values if you apply this change."
              />
            )}
          <RadioButtonGroupV2
            name={"field_mode"}
            label={"Should users be able to override this value?"}
            srLabel={"Should users be able to override this value?"}
            formMethods={formMethods}
            options={[
              {
                label: "Yes",
                value: FieldModeEnum.SensibleDefault,
                description:
                  "Use the expression to set a default value for this field but allow users to override it.",
              },
              {
                label: "No",
                value: FieldModeEnum.FullyDerived,
                description:
                  "Set this field to the value of the expression and prevent users from modifying it.",
              },
            ]}
          />
        </div>
      </ExpandableContentBox>
    </div>
  );
};

const mapCustomFieldToResultType = ({
  fieldType,
  catalogType,
  customFieldID,
}: {
  fieldType: CustomFieldFieldTypeEnum;
  catalogType?: CatalogType;
  customFieldID: string;
}): string => {
  switch (fieldType) {
    case CustomFieldFieldTypeEnum.Text:
      return "String";
    case CustomFieldFieldTypeEnum.Numeric:
      return "Number";
    case CustomFieldFieldTypeEnum.Link:
      return "Link";
    case CustomFieldFieldTypeEnum.SingleSelect:
    case CustomFieldFieldTypeEnum.MultiSelect:
      if (catalogType?.registry_type) {
        return `CatalogEntry["${catalogType?.registry_type}"]`;
      }
      if (catalogType?.id) {
        return `CatalogEntry["${catalogType?.id}"]`;
      } else {
        return `IncidentCustomFieldOption["${customFieldID}"]`;
      }
    default:
      assertUnreachable(fieldType);
  }
  return "";
};
