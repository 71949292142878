/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Escalation,
    EscalationFromJSON,
    EscalationFromJSONTyped,
    EscalationToJSON,
} from './Escalation';
import {
    EscalationPath,
    EscalationPathFromJSON,
    EscalationPathFromJSONTyped,
    EscalationPathToJSON,
} from './EscalationPath';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EscalationsShowResponseBody
 */
export interface EscalationsShowResponseBody {
    /**
     * 
     * @type {Escalation}
     * @memberof EscalationsShowResponseBody
     */
    escalation: Escalation;
    /**
     * 
     * @type {EscalationPath}
     * @memberof EscalationsShowResponseBody
     */
    escalation_path?: EscalationPath;
    /**
     * Users who have been escalated to
     * @type {Array<User>}
     * @memberof EscalationsShowResponseBody
     */
    users: Array<User>;
}

export function EscalationsShowResponseBodyFromJSON(json: any): EscalationsShowResponseBody {
    return EscalationsShowResponseBodyFromJSONTyped(json, false);
}

export function EscalationsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation': EscalationFromJSON(json['escalation']),
        'escalation_path': !exists(json, 'escalation_path') ? undefined : EscalationPathFromJSON(json['escalation_path']),
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function EscalationsShowResponseBodyToJSON(value?: EscalationsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation': EscalationToJSON(value.escalation),
        'escalation_path': EscalationPathToJSON(value.escalation_path),
        'users': ((value.users as Array<any>).map(UserToJSON)),
    };
}

