import type { SVGProps } from "react";
import * as React from "react";
const SvgChevronDownUp = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m14 4.75-3.5 3.5L7 4.75m7 12.5-3.5-3.5-3.5 3.5"
    />
  </svg>
);
export default SvgChevronDownUp;
