import { Workflow } from "@incident-io/api";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Loader,
} from "@incident-ui";
import { DrawerBody, DrawerTitle } from "@incident-ui/Drawer/Drawer";
import { useAPI } from "src/utils/swr";

import { ClonedWorkflow } from "../common/types";
import { WorkflowsRunResult } from "./WorkflowsRunResult";
import { WorkflowsRunScheduledAt } from "./WorkflowsRunScheduledAt";

export const WorkflowsActivityDrawer = ({
  onClose,
  workflow,
}: {
  onClose: () => void;
  workflow: Workflow | ClonedWorkflow;
}) => {
  const {
    data: workflowRunsData,
    isLoading: workflowRunsIsLoading,
    error: workflowRunsError,
  } = useAPI(
    "workflowsListWorkflowRuns",
    {
      workflowId: workflow.id,
    },
    { fallbackData: { workflow_runs: [] } },
  );
  const workflowRuns = workflowRunsData?.workflow_runs;

  const {
    data: stepsData,
    isLoading: stepsIsLoading,
    error: stepsError,
  } = useAPI("workflowsListSteps", undefined, { fallbackData: { steps: [] } });
  const steps = stepsData?.steps;

  if (workflowRunsError || stepsError) {
    return <GenericErrorMessage error={workflowRunsError || stepsError} />;
  }

  if (
    workflowRunsIsLoading ||
    !workflowRunsData ||
    stepsIsLoading ||
    !stepsData
  ) {
    return (
      <div className="p-6">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <DrawerTitle title="Activity" compact onClose={onClose} />
      <DrawerBody className="overflow-y-auto">
        {workflowRuns.length === 0 ? (
          <Callout theme={CalloutTheme.Plain}>
            This workflow has not been run yet.
          </Callout>
        ) : (
          <div className="flex flex-col divide-y divide-stroke text-sm gap-1">
            {workflowRuns
              .filter(({ cancelled_at }) => !cancelled_at)
              .map((run) => (
                <div key={run.id} className="pt-4 space-y-2">
                  <WorkflowsRunScheduledAt run={run} />
                  {run.enqueued_at && (
                    <WorkflowsRunResult steps={steps} workflowRun={run} />
                  )}
                </div>
              ))}
          </div>
        )}
      </DrawerBody>
    </>
  );
};
