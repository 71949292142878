import type { SVGProps } from "react";
import * as React from "react";
const SvgStatus = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.156 17.899a.747.747 0 0 1-.397-.114A7.963 7.963 0 0 1 2 11c0-4.411 3.589-8 8-8s8 3.589 8 8a7.963 7.963 0 0 1-3.759 6.785.75.75 0 0 1-.796-1.272A6.47 6.47 0 0 0 16.5 11c0-3.584-2.916-6.5-6.5-6.5A6.508 6.508 0 0 0 3.5 11a6.47 6.47 0 0 0 3.055 5.513.75.75 0 0 1-.399 1.386Z"
    />
    <path
      fill="currentColor"
      d="M12.253 15.354a.749.749 0 0 1-.399-1.386 3.486 3.486 0 0 0 1.646-2.969c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 1.213.615 2.323 1.646 2.969a.75.75 0 0 1-.797 1.271A4.977 4.977 0 0 1 5 10.999c0-2.757 2.243-5 5-5s5 2.243 5 5a4.977 4.977 0 0 1-2.349 4.24.754.754 0 0 1-.398.115Z"
    />
    <path fill="currentColor" d="M10 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
  </svg>
);
export default SvgStatus;
