import { Loader } from "@incident-ui/Loader/Loader";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export function LoadingWrapper({
  loading,
  children,
  className = "",
  loaderClassName = "",
  large = true,
  isTransparent = false,
}: {
  loading: boolean;
  children: React.ReactNode;
  className?: string;
  loaderClassName?: string;
  large?: boolean;
  isTransparent?: boolean;
}) {
  return (
    <div className={tcx("relative", className)}>
      {loading && (
        <div
          className={tcx(
            "absolute",
            "flex",
            "items-center",
            "justify-center",
            "w-full",
            "h-full",
            "z-10",
            "pointer-events-none",
            isTransparent ? "bg-transparent" : "bg-surface-tertiary/50",
            loaderClassName,
          )}
        >
          <Loader large={large} />
        </div>
      )}
      {children}
    </div>
  );
}
