import { ApiError, ErrorResponse, OpenAPI } from "@incident-io/query-api";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

import { useClient } from "./ClientContext";

// Set the base path for all API requests to be relative to our current URL
// for our react-query client.
OpenAPI.BASE = "";

export const TanstackQueryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { orgHeaders, setIsAuthenticated } = useClient();

  // Sync the org headers with the generated react-query client
  useEffect(() => {
    const middleware = (req: RequestInit) => {
      const headers =
        req.headers instanceof Headers ? req.headers : new Headers();

      const { orgId, impersonatingOrgSlug, impersonationSessionId } =
        orgHeaders.current ?? {};
      if (orgId) {
        headers.append("X-Incident-Organisation-ID", orgId);
      }

      if (impersonatingOrgSlug) {
        headers.append(
          "X-Incident-Impersonate-Organisation-Slug",
          impersonatingOrgSlug,
        );
      }
      if (impersonationSessionId) {
        headers.append(
          "X-Incident-Impersonation-Session-ID",
          impersonationSessionId,
        );
      }

      if (
        process.env.REACT_APP_SENTRY_RELEASE &&
        process.env.REACT_APP_SENTRY_RELEASE !== ""
      ) {
        headers.append(
          "X-Incident-Dashboard-Version",
          process.env.REACT_APP_SENTRY_RELEASE,
        );
      }

      req.headers = headers;

      return req;
    };

    OpenAPI.interceptors.request.use(middleware);

    return () => {
      OpenAPI.interceptors.request.eject(middleware);
    };
  }, [orgHeaders]);

  // Sync the isAuthenticated state with the generated react-query client
  useEffect(() => {
    const middleware = (res: Response) => {
      if (res.status === 401) {
        setIsAuthenticated(false);
      }
      return res;
    };

    OpenAPI.interceptors.response.use(middleware);

    return () => {
      OpenAPI.interceptors.response.eject(middleware);
    };
  }, [setIsAuthenticated]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount: number, error: Error): boolean => {
          if (failureCount >= 3) return false;

          if (error instanceof ApiError && error.status === 404) {
            return false;
          }

          return true;
        },
        retryDelay: (retryAttempt) =>
          Math.floor((Math.random() + 0.5) * Math.pow(2, retryAttempt)) * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

// Get the validation errors and generic errors from the result
// of one or more API errors
export const getErrors = <
  FormType extends FieldValues = Record<string, unknown>,
>(
  ...error: unknown[]
): {
  validationErrors: { name: FieldPath<FormType>; message: string }[];
  genericErrors: string[];
  didError: boolean;
} => {
  if (!Array.isArray(error)) {
    return { validationErrors: [], genericErrors: [], didError: false };
  }

  const validationErrors: { name: FieldPath<FormType>; message: string }[] = [];
  const genericErrors: string[] = [];

  for (const err of error) {
    if (err instanceof ApiError) {
      if (err.status === 422) {
        const errResponse = err.body as ErrorResponse;
        for (const e of errResponse.errors) {
          if (e.source?.pointer) {
            validationErrors.push({
              name: e.source.pointer as FieldPath<FormType>,
              message: e.message,
            });
          } else {
            genericErrors.push(e.message);
          }
        }
      } else {
        genericErrors.push(err.message);
      }
    } else {
      if (err instanceof Error) {
        genericErrors.push(err.toString());
      }
    }
  }

  return {
    validationErrors,
    genericErrors,
    didError: validationErrors.length > 0 || genericErrors.length > 0,
  };
};

// setFormErrors should be passed to an `onError` of a react-query
// mutation block in order to automatically set the form errors
// based on the API response.
export const setFormErrors =
  <FormType extends FieldValues = Record<string, unknown>>(
    formMethods: Pick<UseFormReturn<FormType>, "setError">,
  ) =>
  (error: unknown) => {
    const { validationErrors } = getErrors<FormType>(error);
    for (const e of validationErrors) {
      formMethods.setError(e.name, { message: e.message });
    }

    return validationErrors;
  };
