/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PostIncidentFlowAssignTaskRequestBody,
    PostIncidentFlowAssignTaskRequestBodyFromJSON,
    PostIncidentFlowAssignTaskRequestBodyToJSON,
    PostIncidentFlowAssignTaskResponseBody,
    PostIncidentFlowAssignTaskResponseBodyFromJSON,
    PostIncidentFlowAssignTaskResponseBodyToJSON,
    PostIncidentFlowCompleteTaskResponseBody,
    PostIncidentFlowCompleteTaskResponseBodyFromJSON,
    PostIncidentFlowCompleteTaskResponseBodyToJSON,
    PostIncidentFlowCreateRequestBody,
    PostIncidentFlowCreateRequestBodyFromJSON,
    PostIncidentFlowCreateRequestBodyToJSON,
    PostIncidentFlowCreateResponseBody,
    PostIncidentFlowCreateResponseBodyFromJSON,
    PostIncidentFlowCreateResponseBodyToJSON,
    PostIncidentFlowCreateTaskConfigRequestBody,
    PostIncidentFlowCreateTaskConfigRequestBodyFromJSON,
    PostIncidentFlowCreateTaskConfigRequestBodyToJSON,
    PostIncidentFlowCreateTaskConfigResponseBody,
    PostIncidentFlowCreateTaskConfigResponseBodyFromJSON,
    PostIncidentFlowCreateTaskConfigResponseBodyToJSON,
    PostIncidentFlowEvaluateConditionsRequestBody,
    PostIncidentFlowEvaluateConditionsRequestBodyFromJSON,
    PostIncidentFlowEvaluateConditionsRequestBodyToJSON,
    PostIncidentFlowEvaluateConditionsResponseBody,
    PostIncidentFlowEvaluateConditionsResponseBodyFromJSON,
    PostIncidentFlowEvaluateConditionsResponseBodyToJSON,
    PostIncidentFlowListResponseBody,
    PostIncidentFlowListResponseBodyFromJSON,
    PostIncidentFlowListResponseBodyToJSON,
    PostIncidentFlowListTaskOptionsResponseBody,
    PostIncidentFlowListTaskOptionsResponseBodyFromJSON,
    PostIncidentFlowListTaskOptionsResponseBodyToJSON,
    PostIncidentFlowListTasksResponseBody,
    PostIncidentFlowListTasksResponseBodyFromJSON,
    PostIncidentFlowListTasksResponseBodyToJSON,
    PostIncidentFlowRejectTaskResponseBody,
    PostIncidentFlowRejectTaskResponseBodyFromJSON,
    PostIncidentFlowRejectTaskResponseBodyToJSON,
    PostIncidentFlowShowResponseBody,
    PostIncidentFlowShowResponseBodyFromJSON,
    PostIncidentFlowShowResponseBodyToJSON,
    PostIncidentFlowUndoSkipTaskResponseBody,
    PostIncidentFlowUndoSkipTaskResponseBodyFromJSON,
    PostIncidentFlowUndoSkipTaskResponseBodyToJSON,
    PostIncidentFlowUnresolveTaskResponseBody,
    PostIncidentFlowUnresolveTaskResponseBodyFromJSON,
    PostIncidentFlowUnresolveTaskResponseBodyToJSON,
    PostIncidentFlowUpdateNameRequestBody,
    PostIncidentFlowUpdateNameRequestBodyFromJSON,
    PostIncidentFlowUpdateNameRequestBodyToJSON,
    PostIncidentFlowUpdateNameResponseBody,
    PostIncidentFlowUpdateNameResponseBodyFromJSON,
    PostIncidentFlowUpdateNameResponseBodyToJSON,
    PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody,
    PostIncidentFlowUpdateStatusTaskConfigRanksRequestBodyFromJSON,
    PostIncidentFlowUpdateStatusTaskConfigRanksRequestBodyToJSON,
    PostIncidentFlowUpdateStatusTaskConfigRanksResponseBody,
    PostIncidentFlowUpdateStatusTaskConfigRanksResponseBodyFromJSON,
    PostIncidentFlowUpdateStatusTaskConfigRanksResponseBodyToJSON,
    PostIncidentFlowUpdateTaskConfigRequestBody,
    PostIncidentFlowUpdateTaskConfigRequestBodyFromJSON,
    PostIncidentFlowUpdateTaskConfigRequestBodyToJSON,
    PostIncidentFlowUpdateTaskConfigResponseBody,
    PostIncidentFlowUpdateTaskConfigResponseBodyFromJSON,
    PostIncidentFlowUpdateTaskConfigResponseBodyToJSON,
} from '../models';

export interface PostIncidentFlowAssignTaskRequest {
    id: string;
    assignTaskRequestBody: PostIncidentFlowAssignTaskRequestBody;
}

export interface PostIncidentFlowCompleteTaskRequest {
    id: string;
}

export interface PostIncidentFlowCreateRequest {
    createRequestBody: PostIncidentFlowCreateRequestBody;
}

export interface PostIncidentFlowCreateTaskConfigRequest {
    createTaskConfigRequestBody: PostIncidentFlowCreateTaskConfigRequestBody;
}

export interface PostIncidentFlowDestroyRequest {
    id: string;
}

export interface PostIncidentFlowDestroyTaskConfigRequest {
    id: string;
}

export interface PostIncidentFlowEvaluateConditionsRequest {
    evaluateConditionsRequestBody: PostIncidentFlowEvaluateConditionsRequestBody;
}

export interface PostIncidentFlowListTasksRequest {
    incidentId: string;
}

export interface PostIncidentFlowRejectTaskRequest {
    id: string;
}

export interface PostIncidentFlowShowRequest {
    id: string;
}

export interface PostIncidentFlowUndoSkipTaskRequest {
    id: string;
}

export interface PostIncidentFlowUnresolveTaskRequest {
    id: string;
}

export interface PostIncidentFlowUpdateNameRequest {
    id: string;
    updateNameRequestBody: PostIncidentFlowUpdateNameRequestBody;
}

export interface PostIncidentFlowUpdateStatusTaskConfigRanksRequest {
    id: string;
    updateStatusTaskConfigRanksRequestBody: PostIncidentFlowUpdateStatusTaskConfigRanksRequestBody;
}

export interface PostIncidentFlowUpdateTaskConfigRequest {
    id: string;
    updateTaskConfigRequestBody: PostIncidentFlowUpdateTaskConfigRequestBody;
}

/**
 * 
 */
export class PostIncidentFlowApi extends runtime.BaseAPI {

    /**
     * Assign an post-incident task to a user, or unassign it
     * AssignTask Post-incident Flow
     */
    async postIncidentFlowAssignTaskRaw(requestParameters: PostIncidentFlowAssignTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowAssignTaskResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowAssignTask.');
        }

        if (requestParameters.assignTaskRequestBody === null || requestParameters.assignTaskRequestBody === undefined) {
            throw new runtime.RequiredError('assignTaskRequestBody','Required parameter requestParameters.assignTaskRequestBody was null or undefined when calling postIncidentFlowAssignTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post_incident_tasks/{id}/actions/assign`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostIncidentFlowAssignTaskRequestBodyToJSON(requestParameters.assignTaskRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowAssignTaskResponseBodyFromJSON(jsonValue));
    }

    /**
     * Assign an post-incident task to a user, or unassign it
     * AssignTask Post-incident Flow
     */
    async postIncidentFlowAssignTask(requestParameters: PostIncidentFlowAssignTaskRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowAssignTaskResponseBody> {
        const response = await this.postIncidentFlowAssignTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark a post-incident task item as completed
     * CompleteTask Post-incident Flow
     */
    async postIncidentFlowCompleteTaskRaw(requestParameters: PostIncidentFlowCompleteTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowCompleteTaskResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowCompleteTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_tasks/{id}/actions/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowCompleteTaskResponseBodyFromJSON(jsonValue));
    }

    /**
     * Mark a post-incident task item as completed
     * CompleteTask Post-incident Flow
     */
    async postIncidentFlowCompleteTask(requestParameters: PostIncidentFlowCompleteTaskRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowCompleteTaskResponseBody> {
        const response = await this.postIncidentFlowCompleteTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new post-incident flow
     * Create Post-incident Flow
     */
    async postIncidentFlowCreateRaw(requestParameters: PostIncidentFlowCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling postIncidentFlowCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post_incident_flows`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostIncidentFlowCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new post-incident flow
     * Create Post-incident Flow
     */
    async postIncidentFlowCreate(requestParameters: PostIncidentFlowCreateRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowCreateResponseBody> {
        const response = await this.postIncidentFlowCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new post-incident task config
     * CreateTaskConfig Post-incident Flow
     */
    async postIncidentFlowCreateTaskConfigRaw(requestParameters: PostIncidentFlowCreateTaskConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowCreateTaskConfigResponseBody>> {
        if (requestParameters.createTaskConfigRequestBody === null || requestParameters.createTaskConfigRequestBody === undefined) {
            throw new runtime.RequiredError('createTaskConfigRequestBody','Required parameter requestParameters.createTaskConfigRequestBody was null or undefined when calling postIncidentFlowCreateTaskConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post_incident_task_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostIncidentFlowCreateTaskConfigRequestBodyToJSON(requestParameters.createTaskConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowCreateTaskConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new post-incident task config
     * CreateTaskConfig Post-incident Flow
     */
    async postIncidentFlowCreateTaskConfig(requestParameters: PostIncidentFlowCreateTaskConfigRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowCreateTaskConfigResponseBody> {
        const response = await this.postIncidentFlowCreateTaskConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a post-incident flow
     * Destroy Post-incident Flow
     */
    async postIncidentFlowDestroyRaw(requestParameters: PostIncidentFlowDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_flows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a post-incident flow
     * Destroy Post-incident Flow
     */
    async postIncidentFlowDestroy(requestParameters: PostIncidentFlowDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postIncidentFlowDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a post-incident task config
     * DestroyTaskConfig Post-incident Flow
     */
    async postIncidentFlowDestroyTaskConfigRaw(requestParameters: PostIncidentFlowDestroyTaskConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowDestroyTaskConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_task_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a post-incident task config
     * DestroyTaskConfig Post-incident Flow
     */
    async postIncidentFlowDestroyTaskConfig(requestParameters: PostIncidentFlowDestroyTaskConfigRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postIncidentFlowDestroyTaskConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Evaluate whether or not an incident meets the conditions for entering a post-incident flow, returning which flow the incident should enter (or nothing if the incident should not automatically be opted in)
     * EvaluateConditions Post-incident Flow
     */
    async postIncidentFlowEvaluateConditionsRaw(requestParameters: PostIncidentFlowEvaluateConditionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowEvaluateConditionsResponseBody>> {
        if (requestParameters.evaluateConditionsRequestBody === null || requestParameters.evaluateConditionsRequestBody === undefined) {
            throw new runtime.RequiredError('evaluateConditionsRequestBody','Required parameter requestParameters.evaluateConditionsRequestBody was null or undefined when calling postIncidentFlowEvaluateConditions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post_incident_flows/actions/evaluate_conditions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostIncidentFlowEvaluateConditionsRequestBodyToJSON(requestParameters.evaluateConditionsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowEvaluateConditionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Evaluate whether or not an incident meets the conditions for entering a post-incident flow, returning which flow the incident should enter (or nothing if the incident should not automatically be opted in)
     * EvaluateConditions Post-incident Flow
     */
    async postIncidentFlowEvaluateConditions(requestParameters: PostIncidentFlowEvaluateConditionsRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowEvaluateConditionsResponseBody> {
        const response = await this.postIncidentFlowEvaluateConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists the available post-incident flows for an organisation
     * List Post-incident Flow
     */
    async postIncidentFlowListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_flows`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowListResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists the available post-incident flows for an organisation
     * List Post-incident Flow
     */
    async postIncidentFlowList(initOverrides?: RequestInit): Promise<PostIncidentFlowListResponseBody> {
        const response = await this.postIncidentFlowListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List the options for creating a configured post-incident task
     * ListTaskOptions Post-incident Flow
     */
    async postIncidentFlowListTaskOptionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowListTaskOptionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_task_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowListTaskOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the options for creating a configured post-incident task
     * ListTaskOptions Post-incident Flow
     */
    async postIncidentFlowListTaskOptions(initOverrides?: RequestInit): Promise<PostIncidentFlowListTaskOptionsResponseBody> {
        const response = await this.postIncidentFlowListTaskOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List the post-incident tasks for an incident
     * ListTasks Post-incident Flow
     */
    async postIncidentFlowListTasksRaw(requestParameters: PostIncidentFlowListTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowListTasksResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling postIncidentFlowListTasks.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowListTasksResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the post-incident tasks for an incident
     * ListTasks Post-incident Flow
     */
    async postIncidentFlowListTasks(requestParameters: PostIncidentFlowListTasksRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowListTasksResponseBody> {
        const response = await this.postIncidentFlowListTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject a post-incident task item
     * RejectTask Post-incident Flow
     */
    async postIncidentFlowRejectTaskRaw(requestParameters: PostIncidentFlowRejectTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowRejectTaskResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowRejectTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_tasks/{id}/actions/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowRejectTaskResponseBodyFromJSON(jsonValue));
    }

    /**
     * Reject a post-incident task item
     * RejectTask Post-incident Flow
     */
    async postIncidentFlowRejectTask(requestParameters: PostIncidentFlowRejectTaskRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowRejectTaskResponseBody> {
        const response = await this.postIncidentFlowRejectTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Shows a single post-incident flow. This will return archived flows as well.
     * Show Post-incident Flow
     */
    async postIncidentFlowShowRaw(requestParameters: PostIncidentFlowShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_flows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Shows a single post-incident flow. This will return archived flows as well.
     * Show Post-incident Flow
     */
    async postIncidentFlowShow(requestParameters: PostIncidentFlowShowRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowShowResponseBody> {
        const response = await this.postIncidentFlowShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Undo skip of a post-incident task item
     * UndoSkipTask Post-incident Flow
     */
    async postIncidentFlowUndoSkipTaskRaw(requestParameters: PostIncidentFlowUndoSkipTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowUndoSkipTaskResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowUndoSkipTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_tasks/{id}/actions/undoskip`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowUndoSkipTaskResponseBodyFromJSON(jsonValue));
    }

    /**
     * Undo skip of a post-incident task item
     * UndoSkipTask Post-incident Flow
     */
    async postIncidentFlowUndoSkipTask(requestParameters: PostIncidentFlowUndoSkipTaskRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowUndoSkipTaskResponseBody> {
        const response = await this.postIncidentFlowUndoSkipTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unresolve a post-incident task item so that it is no longer marked as completed or rejected
     * UnresolveTask Post-incident Flow
     */
    async postIncidentFlowUnresolveTaskRaw(requestParameters: PostIncidentFlowUnresolveTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowUnresolveTaskResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowUnresolveTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/post_incident_tasks/{id}/actions/unresolve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowUnresolveTaskResponseBodyFromJSON(jsonValue));
    }

    /**
     * Unresolve a post-incident task item so that it is no longer marked as completed or rejected
     * UnresolveTask Post-incident Flow
     */
    async postIncidentFlowUnresolveTask(requestParameters: PostIncidentFlowUnresolveTaskRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowUnresolveTaskResponseBody> {
        const response = await this.postIncidentFlowUnresolveTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the name of an existing post-incident flow
     * UpdateName Post-incident Flow
     */
    async postIncidentFlowUpdateNameRaw(requestParameters: PostIncidentFlowUpdateNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowUpdateNameResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowUpdateName.');
        }

        if (requestParameters.updateNameRequestBody === null || requestParameters.updateNameRequestBody === undefined) {
            throw new runtime.RequiredError('updateNameRequestBody','Required parameter requestParameters.updateNameRequestBody was null or undefined when calling postIncidentFlowUpdateName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post_incident_flows/{id}/actions/update_name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostIncidentFlowUpdateNameRequestBodyToJSON(requestParameters.updateNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowUpdateNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the name of an existing post-incident flow
     * UpdateName Post-incident Flow
     */
    async postIncidentFlowUpdateName(requestParameters: PostIncidentFlowUpdateNameRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowUpdateNameResponseBody> {
        const response = await this.postIncidentFlowUpdateNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of the statuses and configured tasks within a post-incident flow
     * UpdateStatusTaskConfigRanks Post-incident Flow
     */
    async postIncidentFlowUpdateStatusTaskConfigRanksRaw(requestParameters: PostIncidentFlowUpdateStatusTaskConfigRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowUpdateStatusTaskConfigRanksResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowUpdateStatusTaskConfigRanks.');
        }

        if (requestParameters.updateStatusTaskConfigRanksRequestBody === null || requestParameters.updateStatusTaskConfigRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateStatusTaskConfigRanksRequestBody','Required parameter requestParameters.updateStatusTaskConfigRanksRequestBody was null or undefined when calling postIncidentFlowUpdateStatusTaskConfigRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post_incident_flows/{id}/actions/update_task_ranks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostIncidentFlowUpdateStatusTaskConfigRanksRequestBodyToJSON(requestParameters.updateStatusTaskConfigRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowUpdateStatusTaskConfigRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of the statuses and configured tasks within a post-incident flow
     * UpdateStatusTaskConfigRanks Post-incident Flow
     */
    async postIncidentFlowUpdateStatusTaskConfigRanks(requestParameters: PostIncidentFlowUpdateStatusTaskConfigRanksRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowUpdateStatusTaskConfigRanksResponseBody> {
        const response = await this.postIncidentFlowUpdateStatusTaskConfigRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a post-incident task config
     * UpdateTaskConfig Post-incident Flow
     */
    async postIncidentFlowUpdateTaskConfigRaw(requestParameters: PostIncidentFlowUpdateTaskConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostIncidentFlowUpdateTaskConfigResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postIncidentFlowUpdateTaskConfig.');
        }

        if (requestParameters.updateTaskConfigRequestBody === null || requestParameters.updateTaskConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateTaskConfigRequestBody','Required parameter requestParameters.updateTaskConfigRequestBody was null or undefined when calling postIncidentFlowUpdateTaskConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post_incident_task_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostIncidentFlowUpdateTaskConfigRequestBodyToJSON(requestParameters.updateTaskConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIncidentFlowUpdateTaskConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a post-incident task config
     * UpdateTaskConfig Post-incident Flow
     */
    async postIncidentFlowUpdateTaskConfig(requestParameters: PostIncidentFlowUpdateTaskConfigRequest, initOverrides?: RequestInit): Promise<PostIncidentFlowUpdateTaskConfigResponseBody> {
        const response = await this.postIncidentFlowUpdateTaskConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
