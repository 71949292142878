/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleWorkspaceConfig,
    GoogleWorkspaceConfigFromJSON,
    GoogleWorkspaceConfigFromJSONTyped,
    GoogleWorkspaceConfigToJSON,
} from './GoogleWorkspaceConfig';

/**
 * 
 * @export
 * @interface IntegrationsGoogleWorkspaceGetConfigResponseBody
 */
export interface IntegrationsGoogleWorkspaceGetConfigResponseBody {
    /**
     * 
     * @type {GoogleWorkspaceConfig}
     * @memberof IntegrationsGoogleWorkspaceGetConfigResponseBody
     */
    config: GoogleWorkspaceConfig;
}

export function IntegrationsGoogleWorkspaceGetConfigResponseBodyFromJSON(json: any): IntegrationsGoogleWorkspaceGetConfigResponseBody {
    return IntegrationsGoogleWorkspaceGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsGoogleWorkspaceGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsGoogleWorkspaceGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': GoogleWorkspaceConfigFromJSON(json['config']),
    };
}

export function IntegrationsGoogleWorkspaceGetConfigResponseBodyToJSON(value?: IntegrationsGoogleWorkspaceGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': GoogleWorkspaceConfigToJSON(value.config),
    };
}

