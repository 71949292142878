import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Callout, CalloutTheme, IconEnum, Loader } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import React, { createContext, useContext } from "react";
import { useAPI } from "src/utils/swr";

import { AlertAttributesForm } from "./AlertAttributesForm";

export const AlertAttributesCreateEditDrawer = ({
  title = "Alert attributes",
  onClose,
}: {
  title?: string;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data,
    isLoading: schemaLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);
  if (schemaError) {
    throw schemaError;
  }

  if (!data || schemaLoading) {
    return <Loader />;
  }

  return (
    <Drawer onClose={onClose} warnWhenDirty width="medium">
      <DrawerContents className="font-normal">
        <DrawerTitle
          icon={IconEnum.Tag}
          title={title}
          color={ColorPaletteEnum.Slate}
          onClose={onClose}
          closeIcon={IconEnum.Close}
          sticky
        />
        <DrawerBody>
          <div className={"w-full flex justify-between items-start"}>
            <div className="text-sm">
              All of your alerts share common attributes. You can use these
              fields to capture information about your alerts, such as the
              affected Team, affected Service, or the Environment impacted to
              then be used within alert routes.
            </div>
          </div>
          <Callout
            theme={CalloutTheme.Plain}
            iconOverride={IconEnum.Announcement}
          >
            You can create and assign attributes from your{" "}
            <span className={"font-semibold"}>alert sources</span> and configure
            escalation paths and incident custom fields using those attributes
            from your <span className={"font-semibold"}>alert routes</span>.
          </Callout>
          <AlertAttributesForm alertSchema={data.alert_schema} />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

const AlertAttributesDrawerContext = createContext<{
  open: boolean;
  setOpen: (open: boolean) => void;
}>({
  open: false,
  setOpen: () => void 0,
});

export const useAttributesDrawer = () => {
  return useContext(AlertAttributesDrawerContext);
};

export const AlertAttributesDrawerProvider = ({
  children,
  open,
  setOpen,
}: {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}): React.ReactElement => {
  return (
    <AlertAttributesDrawerContext.Provider value={{ open, setOpen }}>
      {children}
    </AlertAttributesDrawerContext.Provider>
  );
};
