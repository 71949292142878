import type { SVGProps } from "react";
import * as React from "react";
const SvgTextAlignLeft = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 16H3.75a.75.75 0 0 1 0-1.5h12.5a.75.75 0 0 1 0 1.5ZM16.25 5.5H3.75a.75.75 0 0 1 0-1.5h12.5a.75.75 0 0 1 0 1.5ZM9.25 10.75h-5.5a.75.75 0 0 1 0-1.5h5.5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgTextAlignLeft;
