/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    ScheduleConfig,
    ScheduleConfigFromJSON,
    ScheduleConfigFromJSONTyped,
    ScheduleConfigToJSON,
} from './ScheduleConfig';

/**
 * 
 * @export
 * @interface ScheduleVersion
 */
export interface ScheduleVersion {
    /**
     * 
     * @type {Actor}
     * @memberof ScheduleVersion
     */
    actor: Actor;
    /**
     * 
     * @type {ScheduleConfig}
     * @memberof ScheduleVersion
     */
    config: ScheduleConfig;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleVersion
     */
    created_at: Date;
    /**
     * Summary of what this config does
     * @type {string}
     * @memberof ScheduleVersion
     */
    description?: string;
    /**
     * Unique internal ID of the schedule version
     * @type {string}
     * @memberof ScheduleVersion
     */
    id: string;
    /**
     * Unique internal ID this schedule version is for
     * @type {string}
     * @memberof ScheduleVersion
     */
    schedule_id: string;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleVersion
     */
    updated_at: Date;
}

export function ScheduleVersionFromJSON(json: any): ScheduleVersion {
    return ScheduleVersionFromJSONTyped(json, false);
}

export function ScheduleVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'config': ScheduleConfigFromJSON(json['config']),
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'schedule_id': json['schedule_id'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function ScheduleVersionToJSON(value?: ScheduleVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'config': ScheduleConfigToJSON(value.config),
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'schedule_id': value.schedule_id,
        'updated_at': (value.updated_at.toISOString()),
    };
}

