/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertRoutesFindMatchingAlertRoutesRequestBody
 */
export interface AlertRoutesFindMatchingAlertRoutesRequestBody {
    /**
     * The alert source this alert is associated with
     * @type {string}
     * @memberof AlertRoutesFindMatchingAlertRoutesRequestBody
     */
    alert_source_config_id: string;
    /**
     * Alert attributes as param bindings
     * @type {{ [key: string]: EngineParamBindingPayload; }}
     * @memberof AlertRoutesFindMatchingAlertRoutesRequestBody
     */
    attribute_values: { [key: string]: EngineParamBindingPayload; };
}

export function AlertRoutesFindMatchingAlertRoutesRequestBodyFromJSON(json: any): AlertRoutesFindMatchingAlertRoutesRequestBody {
    return AlertRoutesFindMatchingAlertRoutesRequestBodyFromJSONTyped(json, false);
}

export function AlertRoutesFindMatchingAlertRoutesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesFindMatchingAlertRoutesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_config_id': json['alert_source_config_id'],
        'attribute_values': (mapValues(json['attribute_values'], EngineParamBindingPayloadFromJSON)),
    };
}

export function AlertRoutesFindMatchingAlertRoutesRequestBodyToJSON(value?: AlertRoutesFindMatchingAlertRoutesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_config_id': value.alert_source_config_id,
        'attribute_values': (mapValues(value.attribute_values, EngineParamBindingPayloadToJSON)),
    };
}

