import type { SVGProps } from "react";
import * as React from "react";
const SvgServiceNow = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="logosandtypes com"
    viewBox="0 0 150 150"
    {...props}
  >
    <g data-name="Layer 3">
      <g data-name="Layer 2">
        <path
          d="M0 0h150v150H0V0Z"
          data-name="Layer 3"
          style={{
            fill: "none",
          }}
        />
      </g>
    </g>
    <path
      d="M76.01 13.9C39.4 13.69 9.12 43.64 8.81 80.39c-.16 19.45 7.94 37.02 20.98 49.36 4.75 4.49 12.08 4.92 17.23.91 7.6-5.94 17.37-9.47 28.6-9.47s20.99 3.54 28.6 9.47c5.19 4.05 12.55 3.53 17.33-1.01 12.86-12.22 20.89-29.53 20.89-48.7 0-36.9-29.71-66.85-66.42-67.06m-.39 100.59c-19.83 0-33.4-14.93-33.4-33.4s13.57-33.66 33.4-33.66 33.4 15.18 33.4 33.66-13.56 33.4-33.4 33.4"
      style={{
        fill: "#62d84e",
        fillRule: "evenodd",
      }}
    />
  </svg>
);
export default SvgServiceNow;
