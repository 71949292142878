import { getEngineTypeaheadOptions } from "@incident-shared/engine";
import { DynamicSingleSelectWithObjV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectWithObjV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  Callout,
  CalloutTheme,
  DeprecatedTable,
  GenericErrorMessage,
  Loader,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  CatalogEntryMatch,
  CatalogType,
  CustomFieldsConvertToExistingCatalogTypeRequestBodyMismatchStrategyEnum as MismatchStrategyEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { ConvertToCatalogFormType } from "./ConvertCustomFieldToCatalogModal";

export const StepDefineMapping = ({
  customFieldId,
  catalogType,
  formMethods,
}: {
  customFieldId: string;
  catalogType: CatalogType;
  formMethods: UseFormReturn<ConvertToCatalogFormType>;
}): React.ReactElement => {
  const catalogTypeId = catalogType.id;

  const {
    data: { matches },
    isLoading,
    error,
  } = useAPI(
    "customFieldsMatchWithCatalogEntries",
    { id: customFieldId, catalogTypeId: catalogTypeId },
    {
      fallbackData: { matches: [] },
    },
  );

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <StepDefineMappingInner
      customFieldId={customFieldId}
      catalogType={catalogType}
      matches={matches}
      formMethods={formMethods}
    />
  );
};

const StepDefineMappingInner = ({
  customFieldId: _customFieldId,
  matches,
  catalogType,
  formMethods,
}: {
  customFieldId: string;
  catalogType: CatalogType;
  matches: CatalogEntryMatch[];
  formMethods: UseFormReturn<ConvertToCatalogFormType>;
}): React.ReactElement => {
  const matchLookupMemo = useMemo((): { [key: string]: SelectOption } => {
    const matchLookup = {};
    matches.forEach((m) => {
      if (m.catalog_entry) {
        matchLookup[m.custom_field_option.id] = {
          value: m.catalog_entry?.id,
          label: m.catalog_entry.name,
          sort_key: m.catalog_entry.name,
        };
      } else {
        // It is really important that we set the value to empty string
        // if there is no match, this is used to determine that there
        // is **explicitly** no match.
        matchLookup[m.custom_field_option.id] = {
          value: "",
        };
      }
    });
    return matchLookup;
  }, [matches]);

  useEffect(() => {
    formMethods.setValue<"matches">("matches", matchLookupMemo);
  }, [matchLookupMemo, formMethods]);

  const apiClient = useClient();

  let catalogEngineType = `CatalogEntry["${catalogType.id}"]`;
  if (catalogType.registry_type != null) {
    catalogEngineType = `CatalogEntry["${catalogType.registry_type}"]`;
  }

  const optionsWithMatchedEntries = matches.filter(
    (x) => x.catalog_entry != null,
  );
  const optionsWithNoMatchedEntries = matches.filter(
    (x) => x.catalog_entry == null,
  );
  const allOptionsHaveMatchedEntries =
    optionsWithNoMatchedEntries.length === 0 &&
    optionsWithMatchedEntries.length > 0;

  // We want to memoize this function so that we don't hammer the typeahead
  // endpoint with requests. Wrapping it in another function doesn't yield
  // the same results, so we disable the eslint rule.
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const typeaheadOptions = useCallback(
    _.memoize(getEngineTypeaheadOptions(apiClient, catalogEngineType)),
    [apiClient, catalogEngineType],
  );

  return (
    <>
      <div>
        <p className="mb-4 text-sm">
          We&apos;ve done our best to match the existing options to the values
          in your catalog entry. Please review this list, and make any changes
          if you need to.
        </p>
      </div>
      <div>
        <AccordionProvider type="single" collapsible>
          <Accordion
            id="matched-entries"
            header={
              <div className="flex-center-y mb-3">
                <div>
                  <p className="text-sm">
                    <span className="font-semibold">
                      We&apos;ve automatically matched{" "}
                      {allOptionsHaveMatchedEntries ? "all " : ""}
                      {optionsWithMatchedEntries.length}{" "}
                      {pluralize("entry", optionsWithMatchedEntries.length)}.{" "}
                    </span>
                    Toggle to view and edit those mappings.
                  </p>
                </div>
                <AccordionTriggerButton className="text-content-tertiary" />{" "}
              </div>
            }
          >
            <DeprecatedTable className="max-h-60 overflow-y-auto">
              <thead>
                <tr>
                  <th className="font-normal text-content-tertiary">
                    Custom field option
                  </th>
                  <th className="font-normal text-content-tertiary"></th>
                  <th className="font-normal w-[47%] text-content-tertiary">
                    Catalog entry
                  </th>
                </tr>
              </thead>
              <tbody>
                {optionsWithMatchedEntries.map((match, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{match.custom_field_option.value}</td>
                      <td className="text-content-tertiary">&rarr;</td>
                      <td>
                        <DynamicSingleSelectWithObjV2
                          name={`matches.${match.custom_field_option.id}`}
                          formMethods={formMethods}
                          required={false}
                          loadOptions={typeaheadOptions}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </DeprecatedTable>
          </Accordion>
        </AccordionProvider>
      </div>
      {allOptionsHaveMatchedEntries ? null : (
        <div className="mt-6">
          <div className="mb-6">
            <p className="text-sm">
              <span className="font-semibold">
                We could not match {optionsWithNoMatchedEntries.length}{" "}
                {pluralize("entry", optionsWithNoMatchedEntries.length)}.{" "}
              </span>
              Please map them to your desired catalog entries, or leave them
              unmatched and choose a strategy for handling them.
            </p>
          </div>
          <DeprecatedTable className="max-h-60 overflow-y-auto">
            <thead>
              <tr>
                <th className="font-normal text-content-tertiary">
                  Custom field option
                </th>
                <th className="font-normal text-content-tertiary"></th>
                <th className="font-normal w-[47%] text-content-tertiary">
                  Catalog entry
                </th>
              </tr>
            </thead>
            <tbody>
              {optionsWithNoMatchedEntries.map((match, idx) => {
                return (
                  <tr key={idx}>
                    <td>{match.custom_field_option.value}</td>
                    <td className="text-content-tertiary">&rarr;</td>
                    <td>
                      <DynamicSingleSelectWithObjV2
                        name={`matches.${match.custom_field_option.id}`}
                        formMethods={formMethods}
                        required={false}
                        loadOptions={typeaheadOptions}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </DeprecatedTable>
        </div>
      )}
      <div>
        {/*
        Here we assume that if the catalog is of external type
        we need to do something with the options that don't have matches.
        */}
        {catalogType.registry_type == null ? (
          <div>
            <RadioButtonGroupV2
              formMethods={formMethods}
              name="mismatch_strategy"
              srLabel="Mismatch strategy"
              label="What should we do with options that don't have a matching catalog entry?"
              options={[
                {
                  value: MismatchStrategyEnum.ArchiveUnmatched,
                  label: "Archive unmatched options",
                  description:
                    "Any options that are not mapped will be archived. After the conversion is complete, incidents that use these options will be untagged.",
                },
                {
                  value: MismatchStrategyEnum.CreateUnmatched,
                  label: "Create new catalog entries",
                  description:
                    "Any options that are not mapped will be created as new entries in the target catalog type. After the conversion is complete, incidents that use these options will be tagged with the new entries.",
                },
              ]}
            />
          </div>
        ) : (
          <Callout theme={CalloutTheme.Plain}>
            Any options that are not mapped will be archived, and incidents that
            are tagged with them will be untagged.
          </Callout>
        )}
      </div>
    </>
  );
};
