/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpsCreatePriorityOptionRequestBody
 */
export interface FollowUpsCreatePriorityOptionRequestBody {
    /**
     * Description of the follow-up priority option
     * @type {string}
     * @memberof FollowUpsCreatePriorityOptionRequestBody
     */
    description?: string;
    /**
     * The default FollowUpPriorityOption is pre-selected in the dropdown
     * @type {boolean}
     * @memberof FollowUpsCreatePriorityOptionRequestBody
     */
    is_default: boolean;
    /**
     * Name of the follow-up priority option
     * @type {string}
     * @memberof FollowUpsCreatePriorityOptionRequestBody
     */
    name: string;
}

export function FollowUpsCreatePriorityOptionRequestBodyFromJSON(json: any): FollowUpsCreatePriorityOptionRequestBody {
    return FollowUpsCreatePriorityOptionRequestBodyFromJSONTyped(json, false);
}

export function FollowUpsCreatePriorityOptionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsCreatePriorityOptionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'is_default': json['is_default'],
        'name': json['name'],
    };
}

export function FollowUpsCreatePriorityOptionRequestBodyToJSON(value?: FollowUpsCreatePriorityOptionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'is_default': value.is_default,
        'name': value.name,
    };
}

