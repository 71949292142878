import {
  AvailableIncidentFormLifecycleElementElementTypeEnum as FormElementType,
  CustomField,
  CustomFieldEntry,
  IncidentFormLifecycleElementBinding,
  IncidentRole,
  IncidentRoleAssignment,
  IncidentRoleAssignmentPayload,
  IncidentTimestamp,
  IncidentTimestampValuePayload,
  IncidentTimestampWithValue,
  TextDocumentPayload,
} from "@incident-io/api";
import {
  marshallCustomFieldEntriesToRequestPayload,
  marshallCustomFieldsToFormData,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import { FormIncidentRoleAssignments } from "src/components/legacy/incident/IncidentRoleFormElement";

import { IncidentForm, SharedIncidentFormData } from "./FormElements";
import { FormTimestampValues } from "./TimestampElement";

export type IncidentElements = {
  incident_role_assignments: IncidentRoleAssignment[];
  incident_timestamps?: IncidentTimestampWithValue[];
  custom_field_entries: CustomFieldEntry[];
};

// The internal incident response data is a bit different than the form element
// data, so we need to convert between both. We also need to marshall our custom
// field data
export const marshallIncidentResponseToFormElementData = <
  TResponseType extends IncidentElements,
>(
  customFields: CustomField[],
  timestamps: IncidentTimestamp[],
  incidentRoles: IncidentRole[],
  responseBody: TResponseType,
): Pick<
  SharedIncidentFormData,
  | "custom_field_entries"
  | "incident_role_assignments"
  | "incident_timestamp_values"
> => {
  const incidentRoleAssignments: FormIncidentRoleAssignments = {};
  const incidentTimestampValues: FormTimestampValues = {};
  incidentRoles.forEach((role) => {
    const existingAssignment = responseBody.incident_role_assignments.find(
      (x) => x.role.id === role.id,
    );

    incidentRoleAssignments[role.id] = {
      assignee_id: existingAssignment?.assignee?.id,
    };
  });
  timestamps.forEach((timestamp) => {
    const responseTimestamps = responseBody.incident_timestamps || [];
    const existingTimestamp = responseTimestamps.find(
      (x) => x.timestamp.id === timestamp.id,
    );

    incidentTimestampValues[timestamp.id] = existingTimestamp?.value?.value;
  });

  return {
    custom_field_entries: marshallCustomFieldsToFormData({
      customFields: customFields,
      entries: responseBody.custom_field_entries,
    }),
    incident_role_assignments: incidentRoleAssignments,
    incident_timestamp_values: incidentTimestampValues,
  };
};

export const marshallIncidentRolesToFormData = ({
  incidentRoles,
  formAssignments,
}: {
  incidentRoles: IncidentRole[];
  formAssignments: FormIncidentRoleAssignments;
}) => {
  const result: IncidentRoleAssignmentPayload[] = [];
  incidentRoles.forEach((role) => {
    if (formAssignments[role.id]) {
      result.push({
        incident_role_id: role.id,
        assignee: {
          id: formAssignments[role.id].assignee_id,
        },
      });
    }
  });

  return result;
};

// The form element data is a bit different than the request body, so we need to
// convert between both. We also need to marshall our custom field data
export const marshallFormElementDataToIncidentForm = <
  TFormData extends FieldValues,
>(
  ourElementBindings: IncidentFormLifecycleElementBinding[],
  formData: Pick<
    SharedIncidentFormData,
    | "custom_field_entries"
    | "incident_role_assignments"
    | "incident_timestamp_values"
  >,
  touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<TFormData>>>,
): IncidentForm => {
  const incidentRoles: IncidentRole[] = [];
  const incidentTimestampValues: IncidentTimestampValuePayload[] = [];
  const customFields: CustomField[] = [];

  ourElementBindings.forEach((element) => {
    const availableElement = element.element.available_element;
    switch (availableElement.element_type) {
      case FormElementType.IncidentRole:
        incidentRoles.push(availableElement.incident_role as IncidentRole);
        break;
      case FormElementType.Timestamp:
        const timestampID = availableElement.incident_timestamp?.id as string;
        incidentTimestampValues.push({
          incident_timestamp_id: timestampID,
          // Let's force undefined so we never find nulls
          value: formData.incident_timestamp_values[timestampID] || undefined,
        });
        break;
      case FormElementType.CustomField:
        customFields.push(availableElement.custom_field as CustomField);
        break;
    }
  });

  return {
    incident_role_assignments: marshallIncidentRolesToFormData({
      incidentRoles,
      formAssignments: formData.incident_role_assignments,
    }),
    incident_timestamp_values: incidentTimestampValues,
    custom_field_entries: marshallCustomFieldEntriesToRequestPayload(
      customFields,
      touchedFields,
      formData.custom_field_entries,
    ),
  };
};

export const marshallTextDocumentPayload = (
  payload: TextDocumentPayload | undefined,
): TextDocumentPayload | undefined => {
  if (payload?.text_node) {
    return { text_node: payload.text_node };
  }
  return undefined;
};
