import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

export const EscalationNoAccess = () => {
  const navigate = useOrgAwareNavigate();

  return (
    <PageWrapper
      width={PageWidth.Narrow}
      title={`Private escalation`}
      icon={IconEnum.Escalate}
      loading={false}
      backHref="/on-call/escalations"
      crumbs={[
        { title: "On-call", to: "/on-call" },
        { title: "Escalations", to: "/on-call/escalations" },
      ]}
    >
      <Modal
        isOpen
        disableQuickClose
        onClose={() => navigate("/")}
        title="You don't have access to this escalation"
        analyticsTrackingId="private-escalation-no-access"
      >
        <ModalContent className="space-y-4">
          <p>
            This escalation is for a private incident. To protect sensitive
            data, only people who have been granted access to the incident can
            see it.
          </p>
        </ModalContent>
        <ModalFooter>
          <div className="space-x-4">
            <Button
              analyticsTrackingId="private-escalation-back-to-escalations"
              href="/on-call/escalations"
            >
              Back to Dashboard
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  );
};
