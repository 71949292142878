/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SavedViewsCreateRequestBody
 */
export interface SavedViewsCreateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SavedViewsCreateRequestBody
     */
    context: SavedViewsCreateRequestBodyContextEnum;
    /**
     * the name of the saved view
     * @type {string}
     * @memberof SavedViewsCreateRequestBody
     */
    name: string;
    /**
     * the URL parameters that describe the view
     * @type {string}
     * @memberof SavedViewsCreateRequestBody
     */
    url_params: string;
}

/**
* @export
* @enum {string}
*/
export enum SavedViewsCreateRequestBodyContextEnum {
    FollowUps = 'follow_ups',
    Incidents = 'incidents',
    PostIncidentTasks = 'post_incident_tasks',
    InsightsV2 = 'insights.v2'
}

export function SavedViewsCreateRequestBodyFromJSON(json: any): SavedViewsCreateRequestBody {
    return SavedViewsCreateRequestBodyFromJSONTyped(json, false);
}

export function SavedViewsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedViewsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'name': json['name'],
        'url_params': json['url_params'],
    };
}

export function SavedViewsCreateRequestBodyToJSON(value?: SavedViewsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'name': value.name,
        'url_params': value.url_params,
    };
}

