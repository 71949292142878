/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRoleWithoutConditions,
    IncidentRoleWithoutConditionsFromJSON,
    IncidentRoleWithoutConditionsFromJSONTyped,
    IncidentRoleWithoutConditionsToJSON,
} from './IncidentRoleWithoutConditions';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface IncidentRoleAssignment
 */
export interface IncidentRoleAssignment {
    /**
     * 
     * @type {User}
     * @memberof IncidentRoleAssignment
     */
    assignee?: User;
    /**
     * 
     * @type {IncidentRoleWithoutConditions}
     * @memberof IncidentRoleAssignment
     */
    role: IncidentRoleWithoutConditions;
}

export function IncidentRoleAssignmentFromJSON(json: any): IncidentRoleAssignment {
    return IncidentRoleAssignmentFromJSONTyped(json, false);
}

export function IncidentRoleAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRoleAssignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': !exists(json, 'assignee') ? undefined : UserFromJSON(json['assignee']),
        'role': IncidentRoleWithoutConditionsFromJSON(json['role']),
    };
}

export function IncidentRoleAssignmentToJSON(value?: IncidentRoleAssignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': UserToJSON(value.assignee),
        'role': IncidentRoleWithoutConditionsToJSON(value.role),
    };
}

