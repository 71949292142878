import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { IconEnum } from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { Route, Routes } from "react-router";
import { UserPreferencesHeading } from "src/components/user-preferences/common/UserPreferencesHeading";
import { UnsubscribeFromInsightsEmailModal } from "src/components/user-preferences/incident-subscriptions/UnsubscribeFromInsightsEmailModal";
import { InsightsEmailForm } from "src/components/user-preferences/notifications/InsightsEmailForm";
import { NotificationPreferencesForm } from "src/components/user-preferences/notifications/NotificationPreferencesForm";
import { useAPI } from "src/utils/swr";

export const NotificationsPreferencePage = () => {
  const { data, error } = useAPI("userPreferencesShow", undefined);
  const userPreferences = data?.user_preferences ?? null;

  if (error) throw error;

  return (
    <SecondaryNavSubPageWrapper
      title="Notifications"
      icon={IconEnum.Notification}
    >
      <UserPreferencesHeading
        title={"Notifications"}
        description={"Manage the notifications you receive from incident.io."}
      />
      <Routes>
        <Route
          path="unsubscribe/monthly-insights-email"
          element={<UnsubscribeFromInsightsEmailModal />}
        />
      </Routes>
      <LoadingWrapper loading={!userPreferences}>
        <InsightsEmailForm userPreferences={userPreferences} />
      </LoadingWrapper>
      <LoadingWrapper loading={!userPreferences}>
        <NotificationPreferencesForm userPreferences={userPreferences} />
      </LoadingWrapper>
    </SecondaryNavSubPageWrapper>
  );
};
