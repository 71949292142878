/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface InternalStatusPageAutomationRule
 */
export interface InternalStatusPageAutomationRule {
    /**
     * The automation rule will add matching active incidents to the page.
     * @type {Array<ConditionGroup>}
     * @memberof InternalStatusPageAutomationRule
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * 
     * @type {Date}
     * @memberof InternalStatusPageAutomationRule
     */
    created_at: Date;
    /**
     * Unique ID of the rule
     * @type {string}
     * @memberof InternalStatusPageAutomationRule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InternalStatusPageAutomationRule
     */
    internal_status_page_id: string;
    /**
     * Only apply this automation rule to incidents which affect a component that is visible on this status page.
     * @type {boolean}
     * @memberof InternalStatusPageAutomationRule
     */
    only_if_affects_component: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InternalStatusPageAutomationRule
     */
    updated_at: Date;
}

export function InternalStatusPageAutomationRuleFromJSON(json: any): InternalStatusPageAutomationRule {
    return InternalStatusPageAutomationRuleFromJSONTyped(json, false);
}

export function InternalStatusPageAutomationRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageAutomationRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'internal_status_page_id': json['internal_status_page_id'],
        'only_if_affects_component': json['only_if_affects_component'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function InternalStatusPageAutomationRuleToJSON(value?: InternalStatusPageAutomationRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'internal_status_page_id': value.internal_status_page_id,
        'only_if_affects_component': value.only_if_affects_component,
        'updated_at': (value.updated_at.toISOString()),
    };
}

