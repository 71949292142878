/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PoliciesBuildScopeResponseBody,
    PoliciesBuildScopeResponseBodyFromJSON,
    PoliciesBuildScopeResponseBodyToJSON,
    PoliciesCreateReportScheduleRequestBody,
    PoliciesCreateReportScheduleRequestBodyFromJSON,
    PoliciesCreateReportScheduleRequestBodyToJSON,
    PoliciesCreateReportScheduleResponseBody,
    PoliciesCreateReportScheduleResponseBodyFromJSON,
    PoliciesCreateReportScheduleResponseBodyToJSON,
    PoliciesCreateRequestBody,
    PoliciesCreateRequestBodyFromJSON,
    PoliciesCreateRequestBodyToJSON,
    PoliciesCreateResponseBody,
    PoliciesCreateResponseBodyFromJSON,
    PoliciesCreateResponseBodyToJSON,
    PoliciesDefaultSLAExpressionResponseBody,
    PoliciesDefaultSLAExpressionResponseBodyFromJSON,
    PoliciesDefaultSLAExpressionResponseBodyToJSON,
    PoliciesDismissViolationRequestBody,
    PoliciesDismissViolationRequestBodyFromJSON,
    PoliciesDismissViolationRequestBodyToJSON,
    PoliciesDismissViolationResponseBody,
    PoliciesDismissViolationResponseBodyFromJSON,
    PoliciesDismissViolationResponseBodyToJSON,
    PoliciesListReportScheduleResponseBody,
    PoliciesListReportScheduleResponseBodyFromJSON,
    PoliciesListReportScheduleResponseBodyToJSON,
    PoliciesListRequirementsTemplatesResponseBody,
    PoliciesListRequirementsTemplatesResponseBodyFromJSON,
    PoliciesListRequirementsTemplatesResponseBodyToJSON,
    PoliciesListResponseBody,
    PoliciesListResponseBodyFromJSON,
    PoliciesListResponseBodyToJSON,
    PoliciesListViolationsForUserResponseBody,
    PoliciesListViolationsForUserResponseBodyFromJSON,
    PoliciesListViolationsForUserResponseBodyToJSON,
    PoliciesListViolationsResponseBody,
    PoliciesListViolationsResponseBodyFromJSON,
    PoliciesListViolationsResponseBodyToJSON,
    PoliciesPreviewViolationsForPolicyRequestBody,
    PoliciesPreviewViolationsForPolicyRequestBodyFromJSON,
    PoliciesPreviewViolationsForPolicyRequestBodyToJSON,
    PoliciesPreviewViolationsForPolicyResponseBody,
    PoliciesPreviewViolationsForPolicyResponseBodyFromJSON,
    PoliciesPreviewViolationsForPolicyResponseBodyToJSON,
    PoliciesRestorePolicyViolationResponseBody,
    PoliciesRestorePolicyViolationResponseBodyFromJSON,
    PoliciesRestorePolicyViolationResponseBodyToJSON,
    PoliciesShowReportScheduleResponseBody,
    PoliciesShowReportScheduleResponseBodyFromJSON,
    PoliciesShowReportScheduleResponseBodyToJSON,
    PoliciesShowResponseBody,
    PoliciesShowResponseBodyFromJSON,
    PoliciesShowResponseBodyToJSON,
    PoliciesUpdateReportScheduleRequestBody,
    PoliciesUpdateReportScheduleRequestBodyFromJSON,
    PoliciesUpdateReportScheduleRequestBodyToJSON,
    PoliciesUpdateReportScheduleResponseBody,
    PoliciesUpdateReportScheduleResponseBodyFromJSON,
    PoliciesUpdateReportScheduleResponseBodyToJSON,
    PoliciesUpdateRequestBody,
    PoliciesUpdateRequestBodyFromJSON,
    PoliciesUpdateRequestBodyToJSON,
    PoliciesUpdateResponseBody,
    PoliciesUpdateResponseBodyFromJSON,
    PoliciesUpdateResponseBodyToJSON,
} from '../models';

export interface PoliciesCreateRequest {
    createRequestBody: PoliciesCreateRequestBody;
}

export interface PoliciesCreateReportScheduleRequest {
    createReportScheduleRequestBody: PoliciesCreateReportScheduleRequestBody;
}

export interface PoliciesDestroyRequest {
    id: string;
}

export interface PoliciesDestroyReportScheduleRequest {
    id: string;
}

export interface PoliciesDismissViolationRequest {
    id: string;
    dismissViolationRequestBody: PoliciesDismissViolationRequestBody;
}

export interface PoliciesListViolationsRequest {
    policyIds?: Array<string>;
    incidentIds?: Array<string>;
    dismissed?: boolean;
}

export interface PoliciesPreviewViolationsForPolicyRequest {
    previewViolationsForPolicyRequestBody: PoliciesPreviewViolationsForPolicyRequestBody;
}

export interface PoliciesRestorePolicyViolationRequest {
    id: string;
}

export interface PoliciesShowRequest {
    id: string;
}

export interface PoliciesShowReportScheduleRequest {
    id: string;
}

export interface PoliciesUpdateRequest {
    id: string;
    updateRequestBody: PoliciesUpdateRequestBody;
}

export interface PoliciesUpdateReportScheduleRequest {
    id: string;
    updateReportScheduleRequestBody: PoliciesUpdateReportScheduleRequestBody;
}

/**
 * 
 */
export class PoliciesApi extends runtime.BaseAPI {

    /**
     * Build scopes for incident and each policy type
     * BuildScope Policies
     */
    async policiesBuildScopeRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesBuildScopeResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policy_scope`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesBuildScopeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build scopes for incident and each policy type
     * BuildScope Policies
     */
    async policiesBuildScope(initOverrides?: RequestInit): Promise<PoliciesBuildScopeResponseBody> {
        const response = await this.policiesBuildScopeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new incident policy
     * Create Policies
     */
    async policiesCreateRaw(requestParameters: PoliciesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling policiesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PoliciesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident policy
     * Create Policies
     */
    async policiesCreate(requestParameters: PoliciesCreateRequest, initOverrides?: RequestInit): Promise<PoliciesCreateResponseBody> {
        const response = await this.policiesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new policy report schedule
     * CreateReportSchedule Policies
     */
    async policiesCreateReportScheduleRaw(requestParameters: PoliciesCreateReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesCreateReportScheduleResponseBody>> {
        if (requestParameters.createReportScheduleRequestBody === null || requestParameters.createReportScheduleRequestBody === undefined) {
            throw new runtime.RequiredError('createReportScheduleRequestBody','Required parameter requestParameters.createReportScheduleRequestBody was null or undefined when calling policiesCreateReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/report_schedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PoliciesCreateReportScheduleRequestBodyToJSON(requestParameters.createReportScheduleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesCreateReportScheduleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new policy report schedule
     * CreateReportSchedule Policies
     */
    async policiesCreateReportSchedule(requestParameters: PoliciesCreateReportScheduleRequest, initOverrides?: RequestInit): Promise<PoliciesCreateReportScheduleResponseBody> {
        const response = await this.policiesCreateReportScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a default SLA expression that we use to help users get started with dynamic SLAs
     * DefaultSLAExpression Policies
     */
    async policiesDefaultSLAExpressionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesDefaultSLAExpressionResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policies/default_sla_expression`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesDefaultSLAExpressionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a default SLA expression that we use to help users get started with dynamic SLAs
     * DefaultSLAExpression Policies
     */
    async policiesDefaultSLAExpression(initOverrides?: RequestInit): Promise<PoliciesDefaultSLAExpressionResponseBody> {
        const response = await this.policiesDefaultSLAExpressionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Archives an incident policy
     * Destroy Policies
     */
    async policiesDestroyRaw(requestParameters: PoliciesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an incident policy
     * Destroy Policies
     */
    async policiesDestroy(requestParameters: PoliciesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.policiesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Archives an policy report schedule
     * DestroyReportSchedule Policies
     */
    async policiesDestroyReportScheduleRaw(requestParameters: PoliciesDestroyReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesDestroyReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/report_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an policy report schedule
     * DestroyReportSchedule Policies
     */
    async policiesDestroyReportSchedule(requestParameters: PoliciesDestroyReportScheduleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.policiesDestroyReportScheduleRaw(requestParameters, initOverrides);
    }

    /**
     * Mark a policy violation as dismissed.
     * DismissViolation Policies
     */
    async policiesDismissViolationRaw(requestParameters: PoliciesDismissViolationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesDismissViolationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesDismissViolation.');
        }

        if (requestParameters.dismissViolationRequestBody === null || requestParameters.dismissViolationRequestBody === undefined) {
            throw new runtime.RequiredError('dismissViolationRequestBody','Required parameter requestParameters.dismissViolationRequestBody was null or undefined when calling policiesDismissViolation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/policy_violations/{id}/actions/dismiss`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PoliciesDismissViolationRequestBodyToJSON(requestParameters.dismissViolationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesDismissViolationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Mark a policy violation as dismissed.
     * DismissViolation Policies
     */
    async policiesDismissViolation(requestParameters: PoliciesDismissViolationRequest, initOverrides?: RequestInit): Promise<PoliciesDismissViolationResponseBody> {
        const response = await this.policiesDismissViolationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List incident policies
     * List Policies
     */
    async policiesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident policies
     * List Policies
     */
    async policiesList(initOverrides?: RequestInit): Promise<PoliciesListResponseBody> {
        const response = await this.policiesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all policy report schedules
     * ListReportSchedule Policies
     */
    async policiesListReportScheduleRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesListReportScheduleResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/report_schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesListReportScheduleResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all policy report schedules
     * ListReportSchedule Policies
     */
    async policiesListReportSchedule(initOverrides?: RequestInit): Promise<PoliciesListReportScheduleResponseBody> {
        const response = await this.policiesListReportScheduleRaw(initOverrides);
        return await response.value();
    }

    /**
     * List templates that we can use for requirements so we can offer some simple options in the UI
     * ListRequirementsTemplates Policies
     */
    async policiesListRequirementsTemplatesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesListRequirementsTemplatesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policies/requirements_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesListRequirementsTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List templates that we can use for requirements so we can offer some simple options in the UI
     * ListRequirementsTemplates Policies
     */
    async policiesListRequirementsTemplates(initOverrides?: RequestInit): Promise<PoliciesListRequirementsTemplatesResponseBody> {
        const response = await this.policiesListRequirementsTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List policy violations
     * ListViolations Policies
     */
    async policiesListViolationsRaw(requestParameters: PoliciesListViolationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesListViolationsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.policyIds) {
            queryParameters['policy_ids'] = requestParameters.policyIds;
        }

        if (requestParameters.incidentIds) {
            queryParameters['incident_ids'] = requestParameters.incidentIds;
        }

        if (requestParameters.dismissed !== undefined) {
            queryParameters['dismissed'] = requestParameters.dismissed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policy_violations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesListViolationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List policy violations
     * ListViolations Policies
     */
    async policiesListViolations(requestParameters: PoliciesListViolationsRequest, initOverrides?: RequestInit): Promise<PoliciesListViolationsResponseBody> {
        const response = await this.policiesListViolationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List policy violations that a user is responsible for (these are materialised, for performance, so aren\'t 100% up-to-date)
     * ListViolationsForUser Policies
     */
    async policiesListViolationsForUserRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesListViolationsForUserResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policy_violations/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesListViolationsForUserResponseBodyFromJSON(jsonValue));
    }

    /**
     * List policy violations that a user is responsible for (these are materialised, for performance, so aren\'t 100% up-to-date)
     * ListViolationsForUser Policies
     */
    async policiesListViolationsForUser(initOverrides?: RequestInit): Promise<PoliciesListViolationsForUserResponseBody> {
        const response = await this.policiesListViolationsForUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * List potential policy violations for a policy. This is a preview, and does not create any violations.
     * PreviewViolationsForPolicy Policies
     */
    async policiesPreviewViolationsForPolicyRaw(requestParameters: PoliciesPreviewViolationsForPolicyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesPreviewViolationsForPolicyResponseBody>> {
        if (requestParameters.previewViolationsForPolicyRequestBody === null || requestParameters.previewViolationsForPolicyRequestBody === undefined) {
            throw new runtime.RequiredError('previewViolationsForPolicyRequestBody','Required parameter requestParameters.previewViolationsForPolicyRequestBody was null or undefined when calling policiesPreviewViolationsForPolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/policies_violations/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PoliciesPreviewViolationsForPolicyRequestBodyToJSON(requestParameters.previewViolationsForPolicyRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesPreviewViolationsForPolicyResponseBodyFromJSON(jsonValue));
    }

    /**
     * List potential policy violations for a policy. This is a preview, and does not create any violations.
     * PreviewViolationsForPolicy Policies
     */
    async policiesPreviewViolationsForPolicy(requestParameters: PoliciesPreviewViolationsForPolicyRequest, initOverrides?: RequestInit): Promise<PoliciesPreviewViolationsForPolicyResponseBody> {
        const response = await this.policiesPreviewViolationsForPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Undismiss a policy violation.
     * RestorePolicyViolation Policies
     */
    async policiesRestorePolicyViolationRaw(requestParameters: PoliciesRestorePolicyViolationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesRestorePolicyViolationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesRestorePolicyViolation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policy_violations/{id}/actions/restore`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesRestorePolicyViolationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Undismiss a policy violation.
     * RestorePolicyViolation Policies
     */
    async policiesRestorePolicyViolation(requestParameters: PoliciesRestorePolicyViolationRequest, initOverrides?: RequestInit): Promise<PoliciesRestorePolicyViolationResponseBody> {
        const response = await this.policiesRestorePolicyViolationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single incident policy
     * Show Policies
     */
    async policiesShowRaw(requestParameters: PoliciesShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single incident policy
     * Show Policies
     */
    async policiesShow(requestParameters: PoliciesShowRequest, initOverrides?: RequestInit): Promise<PoliciesShowResponseBody> {
        const response = await this.policiesShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single policy report schedule
     * ShowReportSchedule Policies
     */
    async policiesShowReportScheduleRaw(requestParameters: PoliciesShowReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesShowReportScheduleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesShowReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/report_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesShowReportScheduleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single policy report schedule
     * ShowReportSchedule Policies
     */
    async policiesShowReportSchedule(requestParameters: PoliciesShowReportScheduleRequest, initOverrides?: RequestInit): Promise<PoliciesShowReportScheduleResponseBody> {
        const response = await this.policiesShowReportScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident policy
     * Update Policies
     */
    async policiesUpdateRaw(requestParameters: PoliciesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling policiesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoliciesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident policy
     * Update Policies
     */
    async policiesUpdate(requestParameters: PoliciesUpdateRequest, initOverrides?: RequestInit): Promise<PoliciesUpdateResponseBody> {
        const response = await this.policiesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a policy report schedule
     * UpdateReportSchedule Policies
     */
    async policiesUpdateReportScheduleRaw(requestParameters: PoliciesUpdateReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PoliciesUpdateReportScheduleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling policiesUpdateReportSchedule.');
        }

        if (requestParameters.updateReportScheduleRequestBody === null || requestParameters.updateReportScheduleRequestBody === undefined) {
            throw new runtime.RequiredError('updateReportScheduleRequestBody','Required parameter requestParameters.updateReportScheduleRequestBody was null or undefined when calling policiesUpdateReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/report_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PoliciesUpdateReportScheduleRequestBodyToJSON(requestParameters.updateReportScheduleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PoliciesUpdateReportScheduleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a policy report schedule
     * UpdateReportSchedule Policies
     */
    async policiesUpdateReportSchedule(requestParameters: PoliciesUpdateReportScheduleRequest, initOverrides?: RequestInit): Promise<PoliciesUpdateReportScheduleResponseBody> {
        const response = await this.policiesUpdateReportScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
