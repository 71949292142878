import {
  ManagedResourceResourceTypeEnum as ResourceTypeEnum,
  ManagedResourcesUpsertRequestBodyResourceTypeEnum,
  ManagementMeta,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
} from "@incident-ui";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPIMutation } from "src/utils/swr";

import { isTerraform, resourceToShowEndpoint } from "./utils";

type FormData = {
  source_url: string;
};

export const SourceURLForm = ({
  resourceType,
  resourceID,
  managementMeta,
  onSourceURLChange,
  noText = false,
}: {
  resourceType: ResourceTypeEnum;
  resourceID: string;
  managementMeta: ManagementMeta;
  onSourceURLChange?: () => void;
  noText?: boolean;
}) => {
  const showToast = useToast();
  const formMethods = useForm<FormData>({
    defaultValues: {
      source_url: managementMeta.source_url ?? "",
    },
  });

  const [hasJustSaved, setHasJustSaved] = useState(false);

  const isCurrentlyManagedInTF = isTerraform(managementMeta);

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    resourceToShowEndpoint[resourceType],
    {
      id: resourceID,
    },
    async (apiClient, data) => {
      await apiClient.managedResourcesUpsert({
        upsertRequestBody: {
          resource_id: resourceID,
          resource_type:
            resourceType as unknown as ManagedResourcesUpsertRequestBodyResourceTypeEnum,
          source_url: data.source_url,
        },
      });
      formMethods.reset({ source_url: data.source_url });
    },
    {
      onSuccess: () => {
        if (onSourceURLChange) {
          onSourceURLChange();
        }
        setHasJustSaved(true);
        setTimeout(() => setHasJustSaved(false), 2000);
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to set management URL",
          toastSide: ToastSideEnum.TopRight,
        });
      },
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Root
      onSubmit={onSubmit}
      formMethods={formMethods}
      genericError={genericError}
      id="source-url-form"
    >
      <div className="flex flex-row gap-3 w-full">
        <InputV2
          formMethods={formMethods}
          label={
            noText
              ? ""
              : isCurrentlyManagedInTF
              ? "Where is the configuration stored?"
              : "Where will the configuration be stored?"
          }
          className="flex-grow"
          inputClassName="h-10"
          placeholder="https://github.com/org/infrastructure"
          helptext={
            noText || isCurrentlyManagedInTF
              ? ""
              : `We'll show this to help other users find this ${resourceType}'s Terraform configuration.`
          }
          name="source_url"
          required
        />
        <div className="flex-grow-0 flex items-end">
          {hasJustSaved ? (
            <Badge
              className="align-bottom !my-0 text-slate-700 border border-stroke"
              theme={BadgeTheme.Unstyled}
              icon={IconEnum.Success}
              iconClassName="text-green-500"
              size={BadgeSize.Large}
            >
              Saved
            </Badge>
          ) : (
            <Button
              analyticsTrackingId="update-source-url"
              className="align-bottom"
              theme={ButtonTheme.Secondary}
              disabled={!formMethods.formState.isDirty}
              type="button"
              onClick={() => formMethods.handleSubmit(onSubmit)()}
              loading={saving}
              form="source-url-form"
            >
              Apply
            </Button>
          )}
        </div>
      </div>
    </Form.Root>
  );
};
