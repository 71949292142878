import {
  CatalogType,
  CatalogTypeAttributePayload as AttributePayload,
  CatalogTypeAttributePayloadModeEnum as AttributeModeEnum,
} from "@incident-io/api";
import { Tooltip } from "@incident-ui";
import {
  SearchableDropdown,
  SearchableDropdownEntry,
} from "@incident-ui/SearchableDropdown/SearchableDropdown";
import { compact } from "lodash";
import React, { useState } from "react";
import { ulid } from "ulid";

import { BacklinkIsArrayModal } from "./BacklinkIsArrayModal";
import { DerivedAttributeBadge } from "./DerivedAttributeBadge";
import { AttributeFormState } from "./types";
import { DerivedAttributeDeps } from "./useSchemaEditDeps";

export type CatalogTypeInfo = {
  id?: string;
  name: string;
  registryType?: string;
  parameter?: string;
  exists?: boolean;
};

export const AddDerivedAttributesPopover = ({
  catalogTypeName,
  attributeEntries,
  onAddAttribute,
  renderTriggerNode,
}: {
  catalogTypeName: string;
  attributeEntries: SearchableDropdownEntry<AttributePayload>[];
  onAddAttribute: (attr: AttributePayload) => void;
  renderTriggerNode: (props: {
    onClick: () => void;
    disabled?: boolean;
  }) => React.ReactElement;
}): React.ReactElement | null => {
  const [addingBacklinkAttribute, setAddingBacklinkAttribute] =
    useState<AttributePayload | null>(null);

  const onSelectAttribute = (attr: AttributePayload) => {
    if (attr.mode === AttributeModeEnum.Backlink) {
      return setAddingBacklinkAttribute(attr);
    } else {
      return onAddAttribute(attr);
    }
  };

  if (attributeEntries.length === 0) {
    return (
      <Tooltip content="There are no more derived attributes for this type">
        {renderTriggerNode({ disabled: true, onClick: () => null })}
      </Tooltip>
    );
  }

  return (
    <>
      {!!addingBacklinkAttribute && (
        <BacklinkIsArrayModal
          attribute={addingBacklinkAttribute}
          onClose={() => setAddingBacklinkAttribute(null)}
          onSubmit={async (data) => {
            await onAddAttribute(data);
            setAddingBacklinkAttribute(null);
          }}
          catalogTypeName={catalogTypeName}
        />
      )}
      <SearchableDropdown
        onSelectItem={onSelectAttribute}
        emptyState={
          <div className="flex flex-col gap-1 pt-2">
            There are no more derived attributes for this type
          </div>
        }
        entries={attributeEntries}
        hideSearchBar={attributeEntries.length < 10}
        renderTriggerButton={renderTriggerNode}
      />
    </>
  );
};

export const buildDerivedAttributeEntries = ({
  backlinks,
  paths,
  catalogTypes,
}: {
  catalogTypes: CatalogType[];
} & DerivedAttributeDeps): SearchableDropdownEntry<AttributeFormState>[] => {
  const backlinkOpts = backlinks.map((backlink) => {
    const referencedCatalogType = catalogTypes.find(
      (ct) => ct.type_name === backlink.type,
    );
    if (!referencedCatalogType) {
      return null;
    }

    const attributePayload = {
      id: ulid(),
      name: backlink.default_name,
      backlink_attribute: backlink.attribute_id,
      // All backlinks are 'array' by default. If a user then tells us there's going to be just one by flipping the toggle, that's fine!
      array: true,
      type: backlink.type,
      mode: AttributeModeEnum.Backlink,
    };

    return {
      item: attributePayload,
      renderFn: () => (
        <DerivedAttributeBadge
          catalogTypes={catalogTypes}
          attribute={attributePayload}
          className="cursor-pointer max-w-full"
        />
      ),
      label: backlink.path.join(" → "),
      attribute_id: backlink.attribute_id,
    };
  });

  const expressionOpts = paths.map((path) => {
    const referencedCatalogType = catalogTypes.find(
      (ct) => ct.type_name === path.type,
    );
    if (!referencedCatalogType) {
      return null;
    }

    const attributePayload = {
      id: ulid(),
      name: path.default_name,
      array: path.array,
      type: path.type,
      mode: AttributeModeEnum.Path,
      path: path.path,
    };

    return {
      item: attributePayload,
      renderFn: () => (
        <DerivedAttributeBadge
          catalogTypes={catalogTypes}
          attribute={attributePayload}
          className="cursor-pointer max-w-full"
        />
      ),
      label: path.path.map((ea) => ea.attribute_name).join(" → "),
    };
  });

  // Remove any empty entries
  return compact([
    ...backlinkOpts,
    ...expressionOpts,
  ]) as SearchableDropdownEntry<AttributeFormState>[];
};
