import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Spinner.module.scss";

export enum SpinnerTheme {
  // Slate spinners are the default, we use them on light coloured backgrounds
  Slate = "slate",
  // White spinners are generally used in places where we have dark coloured backgrounds
  White = "white",
  // Dark spinners are used in places where we have light coloured backgrounds
  Dark = "dark",
}

const colorClass = {
  [SpinnerTheme.Dark]: {
    segmentColor: "text-slate-950",
    backgroundColor: "text-content-tertiary",
  },
  [SpinnerTheme.Slate]: {
    segmentColor: "text-slate-400",
    backgroundColor: "text-slate-200",
  },
  [SpinnerTheme.White]: {
    segmentColor: "text-white",
    backgroundColor: "text-slate-600",
  },
};

export const Spinner = ({
  theme = SpinnerTheme.Slate,
  className,
  containerClassName,
  large = false,
}: {
  theme?: SpinnerTheme;
  large?: boolean;
  containerClassName?: string;
  className?: string;
}): React.ReactElement => {
  return (
    <div className={containerClassName}>
      <svg
        className={tcx(
          styles.spinner,
          colorClass[theme].segmentColor,
          {
            "w-4 h-4": !large,
            "w-6 h-6": large,
          },
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className={colorClass[theme].backgroundColor}
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className={styles.widget}
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
