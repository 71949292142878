/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    CustomFieldEntry,
    CustomFieldEntryFromJSON,
    CustomFieldEntryFromJSONTyped,
    CustomFieldEntryToJSON,
} from './CustomFieldEntry';
import {
    IncidentRoleAssignment,
    IncidentRoleAssignmentFromJSON,
    IncidentRoleAssignmentFromJSONTyped,
    IncidentRoleAssignmentToJSON,
} from './IncidentRoleAssignment';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';
import {
    InternalStatusPageAffectedComponent,
    InternalStatusPageAffectedComponentFromJSON,
    InternalStatusPageAffectedComponentFromJSONTyped,
    InternalStatusPageAffectedComponentToJSON,
} from './InternalStatusPageAffectedComponent';
import {
    InternalStatusPageIncidentUpdate,
    InternalStatusPageIncidentUpdateFromJSON,
    InternalStatusPageIncidentUpdateFromJSONTyped,
    InternalStatusPageIncidentUpdateToJSON,
} from './InternalStatusPageIncidentUpdate';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface InternalStatusPageIncident
 */
export interface InternalStatusPageIncident {
    /**
     * List of component or group names affected by this component.
     * @type {Array<InternalStatusPageAffectedComponent>}
     * @memberof InternalStatusPageIncident
     */
    affected_components: Array<InternalStatusPageAffectedComponent>;
    /**
     * When the incident was created
     * @type {Date}
     * @memberof InternalStatusPageIncident
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof InternalStatusPageIncident
     */
    creator: Actor;
    /**
     * Custom field entries for this incident
     * @type {Array<CustomFieldEntry>}
     * @memberof InternalStatusPageIncident
     */
    custom_field_entries: Array<CustomFieldEntry>;
    /**
     * If the incident is in a closed status, the length of time from creation through to closure. If the incident is ongoing, this will be null.
     * @type {number}
     * @memberof InternalStatusPageIncident
     */
    duration_seconds?: number;
    /**
     * External identifier for the incident - often displayed with an INC- prefix
     * @type {number}
     * @memberof InternalStatusPageIncident
     */
    external_id: number;
    /**
     * The ID of the internal status page incident. This is different to the ID of the incident itself.
     * @type {string}
     * @memberof InternalStatusPageIncident
     */
    id: string;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof InternalStatusPageIncident
     */
    incident_status: IncidentStatus;
    /**
     * The ID of the internal status page this is linked to
     * @type {string}
     * @memberof InternalStatusPageIncident
     */
    internal_status_page_id: string;
    /**
     * 
     * @type {IncidentRoleAssignment}
     * @memberof InternalStatusPageIncident
     */
    lead_assignment: IncidentRoleAssignment;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof InternalStatusPageIncident
     */
    name: string;
    /**
     * Reference to this incident, as displayed across the product
     * @type {string}
     * @memberof InternalStatusPageIncident
     */
    reference: string;
    /**
     * If there's a reported at timestamp, that value, otherwise fall back to the incident's created_at value
     * @type {Date}
     * @memberof InternalStatusPageIncident
     */
    reported_at: Date;
    /**
     * The unique ID of the linked response incident.
     * @type {string}
     * @memberof InternalStatusPageIncident
     */
    response_incident_id: string;
    /**
     * 
     * @type {Severity}
     * @memberof InternalStatusPageIncident
     */
    severity?: Severity;
    /**
     * URL to link to the slack channel
     * @type {string}
     * @memberof InternalStatusPageIncident
     */
    slack_channel_url?: string;
    /**
     * 
     * @type {TextDocument}
     * @memberof InternalStatusPageIncident
     */
    summary?: TextDocument;
    /**
     * The list of incident updates
     * @type {Array<InternalStatusPageIncidentUpdate>}
     * @memberof InternalStatusPageIncident
     */
    updates: Array<InternalStatusPageIncidentUpdate>;
}

export function InternalStatusPageIncidentFromJSON(json: any): InternalStatusPageIncident {
    return InternalStatusPageIncidentFromJSONTyped(json, false);
}

export function InternalStatusPageIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affected_components': ((json['affected_components'] as Array<any>).map(InternalStatusPageAffectedComponentFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'creator': ActorFromJSON(json['creator']),
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryFromJSON)),
        'duration_seconds': !exists(json, 'duration_seconds') ? undefined : json['duration_seconds'],
        'external_id': json['external_id'],
        'id': json['id'],
        'incident_status': IncidentStatusFromJSON(json['incident_status']),
        'internal_status_page_id': json['internal_status_page_id'],
        'lead_assignment': IncidentRoleAssignmentFromJSON(json['lead_assignment']),
        'name': json['name'],
        'reference': json['reference'],
        'reported_at': (new Date(json['reported_at'])),
        'response_incident_id': json['response_incident_id'],
        'severity': !exists(json, 'severity') ? undefined : SeverityFromJSON(json['severity']),
        'slack_channel_url': !exists(json, 'slack_channel_url') ? undefined : json['slack_channel_url'],
        'summary': !exists(json, 'summary') ? undefined : TextDocumentFromJSON(json['summary']),
        'updates': ((json['updates'] as Array<any>).map(InternalStatusPageIncidentUpdateFromJSON)),
    };
}

export function InternalStatusPageIncidentToJSON(value?: InternalStatusPageIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affected_components': ((value.affected_components as Array<any>).map(InternalStatusPageAffectedComponentToJSON)),
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryToJSON)),
        'duration_seconds': value.duration_seconds,
        'external_id': value.external_id,
        'id': value.id,
        'incident_status': IncidentStatusToJSON(value.incident_status),
        'internal_status_page_id': value.internal_status_page_id,
        'lead_assignment': IncidentRoleAssignmentToJSON(value.lead_assignment),
        'name': value.name,
        'reference': value.reference,
        'reported_at': (value.reported_at.toISOString()),
        'response_incident_id': value.response_incident_id,
        'severity': SeverityToJSON(value.severity),
        'slack_channel_url': value.slack_channel_url,
        'summary': TextDocumentToJSON(value.summary),
        'updates': ((value.updates as Array<any>).map(InternalStatusPageIncidentUpdateToJSON)),
    };
}

