import { useFlags } from "launchdarkly-react-client-sdk";
import { useIdentity } from "src/contexts/IdentityContext";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "./usePrimaryCommsPlatform";

export const useCanEnablePrivateIncidentsForTeams = () => {
  const { identity } = useIdentity();
  const { privateIncidentsInMsTeams } = useFlags();
  const primaryCommsPlatform = usePrimaryCommsPlatform();

  const missingRequiredTeamsScopes =
    identity.ms_teams_info?.missing_token_scopes_for_private_incidents;
  return (
    primaryCommsPlatform === CommsPlatform.MSTeams &&
    !missingRequiredTeamsScopes?.length &&
    privateIncidentsInMsTeams
  );
};
