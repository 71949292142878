/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GitLabConfig
 */
export interface GitLabConfig {
    /**
     * 
     * @type {string}
     * @memberof GitLabConfig
     */
    base_url: string;
    /**
     * 
     * @type {Date}
     * @memberof GitLabConfig
     */
    last_webhook_received_at?: Date;
}

export function GitLabConfigFromJSON(json: any): GitLabConfig {
    return GitLabConfigFromJSONTyped(json, false);
}

export function GitLabConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): GitLabConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_url': json['base_url'],
        'last_webhook_received_at': !exists(json, 'last_webhook_received_at') ? undefined : (new Date(json['last_webhook_received_at'])),
    };
}

export function GitLabConfigToJSON(value?: GitLabConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_url': value.base_url,
        'last_webhook_received_at': value.last_webhook_received_at === undefined ? undefined : (value.last_webhook_received_at.toISOString()),
    };
}

