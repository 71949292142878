/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeValueSuggestion,
    AttributeValueSuggestionFromJSON,
    AttributeValueSuggestionFromJSONTyped,
    AttributeValueSuggestionToJSON,
} from './AttributeValueSuggestion';

/**
 * 
 * @export
 * @interface CatalogSuggestMissingAttributeValuesResponseBody
 */
export interface CatalogSuggestMissingAttributeValuesResponseBody {
    /**
     * 
     * @type {Array<AttributeValueSuggestion>}
     * @memberof CatalogSuggestMissingAttributeValuesResponseBody
     */
    suggestions: Array<AttributeValueSuggestion>;
}

export function CatalogSuggestMissingAttributeValuesResponseBodyFromJSON(json: any): CatalogSuggestMissingAttributeValuesResponseBody {
    return CatalogSuggestMissingAttributeValuesResponseBodyFromJSONTyped(json, false);
}

export function CatalogSuggestMissingAttributeValuesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogSuggestMissingAttributeValuesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suggestions': ((json['suggestions'] as Array<any>).map(AttributeValueSuggestionFromJSON)),
    };
}

export function CatalogSuggestMissingAttributeValuesResponseBodyToJSON(value?: CatalogSuggestMissingAttributeValuesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suggestions': ((value.suggestions as Array<any>).map(AttributeValueSuggestionToJSON)),
    };
}

