/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemShareTemplate,
    PostmortemShareTemplateFromJSON,
    PostmortemShareTemplateFromJSONTyped,
    PostmortemShareTemplateToJSON,
} from './PostmortemShareTemplate';

/**
 * 
 * @export
 * @interface PostmortemSharesListTemplatesResponseBody
 */
export interface PostmortemSharesListTemplatesResponseBody {
    /**
     * 
     * @type {Array<PostmortemShareTemplate>}
     * @memberof PostmortemSharesListTemplatesResponseBody
     */
    postmortem_share_templates: Array<PostmortemShareTemplate>;
}

export function PostmortemSharesListTemplatesResponseBodyFromJSON(json: any): PostmortemSharesListTemplatesResponseBody {
    return PostmortemSharesListTemplatesResponseBodyFromJSONTyped(json, false);
}

export function PostmortemSharesListTemplatesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSharesListTemplatesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postmortem_share_templates': ((json['postmortem_share_templates'] as Array<any>).map(PostmortemShareTemplateFromJSON)),
    };
}

export function PostmortemSharesListTemplatesResponseBodyToJSON(value?: PostmortemSharesListTemplatesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postmortem_share_templates': ((value.postmortem_share_templates as Array<any>).map(PostmortemShareTemplateToJSON)),
    };
}

