/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface SeverityAliasPayload
 */
export interface SeverityAliasPayload {
    /**
     * If true, will hide the severity for this incident type
     * @type {boolean}
     * @memberof SeverityAliasPayload
     */
    hidden: boolean;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof SeverityAliasPayload
     */
    override_description?: TextDocumentPayload;
    /**
     * ID of the severity this alias applies to
     * @type {string}
     * @memberof SeverityAliasPayload
     */
    severity_id: string;
}

export function SeverityAliasPayloadFromJSON(json: any): SeverityAliasPayload {
    return SeverityAliasPayloadFromJSONTyped(json, false);
}

export function SeverityAliasPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeverityAliasPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hidden': json['hidden'],
        'override_description': !exists(json, 'override_description') ? undefined : TextDocumentPayloadFromJSON(json['override_description']),
        'severity_id': json['severity_id'],
    };
}

export function SeverityAliasPayloadToJSON(value?: SeverityAliasPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hidden': value.hidden,
        'override_description': TextDocumentPayloadToJSON(value.override_description),
        'severity_id': value.severity_id,
    };
}

