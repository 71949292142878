/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntry,
    CatalogEntryFromJSON,
    CatalogEntryFromJSONTyped,
    CatalogEntryToJSON,
} from './CatalogEntry';

/**
 * 
 * @export
 * @interface CatalogFindEntriesResponseBody
 */
export interface CatalogFindEntriesResponseBody {
    /**
     * 
     * @type {{ [key: string]: Array<CatalogEntry>; }}
     * @memberof CatalogFindEntriesResponseBody
     */
    results: { [key: string]: Array<CatalogEntry>; };
}

export function CatalogFindEntriesResponseBodyFromJSON(json: any): CatalogFindEntriesResponseBody {
    return CatalogFindEntriesResponseBodyFromJSONTyped(json, false);
}

export function CatalogFindEntriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogFindEntriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': json['results'],
    };
}

export function CatalogFindEntriesResponseBodyToJSON(value?: CatalogFindEntriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results,
    };
}

