import type { SVGProps } from "react";
import * as React from "react";
const SvgKeyboard = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 4H4.75A2.752 2.752 0 0 0 2 6.75v6.5A2.752 2.752 0 0 0 4.75 16h10.5A2.752 2.752 0 0 0 18 13.25v-6.5A2.752 2.752 0 0 0 15.25 4Zm-4.125 5h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1-.5-.5v-.5a.5.5 0 0 1 .5-.5ZM9.25 7.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5V8a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1-.5-.5v-.5ZM8.375 9h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1-.5-.5v-.5a.5.5 0 0 1 .5-.5Zm-1.75 1a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1-.5-.5v-.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5v.5ZM6.5 8v-.5A.5.5 0 0 1 7 7h.5a.5.5 0 0 1 .5.5V8a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5Zm5.75 5h-4.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5ZM13 8.5h-.5A.5.5 0 0 1 12 8v-.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5V8a.5.5 0 0 1-.5.5Zm1.875 1.5a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1-.5-.5v-.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5v.5Z"
    />
  </svg>
);
export default SvgKeyboard;
