/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MSTeamsPreviewTemplate,
    MSTeamsPreviewTemplateFromJSON,
    MSTeamsPreviewTemplateFromJSONTyped,
    MSTeamsPreviewTemplateToJSON,
} from './MSTeamsPreviewTemplate';

/**
 * 
 * @export
 * @interface MSTeamsPreviewsListResponseBody
 */
export interface MSTeamsPreviewsListResponseBody {
    /**
     * 
     * @type {Array<MSTeamsPreviewTemplate>}
     * @memberof MSTeamsPreviewsListResponseBody
     */
    templates: Array<MSTeamsPreviewTemplate>;
}

export function MSTeamsPreviewsListResponseBodyFromJSON(json: any): MSTeamsPreviewsListResponseBody {
    return MSTeamsPreviewsListResponseBodyFromJSONTyped(json, false);
}

export function MSTeamsPreviewsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MSTeamsPreviewsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templates': ((json['templates'] as Array<any>).map(MSTeamsPreviewTemplateFromJSON)),
    };
}

export function MSTeamsPreviewsListResponseBodyToJSON(value?: MSTeamsPreviewsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templates': ((value.templates as Array<any>).map(MSTeamsPreviewTemplateToJSON)),
    };
}

