import type { SVGProps } from "react";
import * as React from "react";
const SvgOnCall = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 7a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM3.75 6c0-.69.56-1.25 1.25-1.25h7a.75.75 0 0 0 0-1.5H5A2.75 2.75 0 0 0 2.25 6v8A2.75 2.75 0 0 0 5 16.75h10A2.75 2.75 0 0 0 17.75 14V9.5a.75.75 0 0 0-1.457-.25H3.75V6Zm2 7a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75Zm6 0a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5h-1a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOnCall;
