/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Escalation,
    EscalationFromJSON,
    EscalationFromJSONTyped,
    EscalationToJSON,
} from './Escalation';

/**
 * 
 * @export
 * @interface EscalationsRespondEscalationResponseBody
 */
export interface EscalationsRespondEscalationResponseBody {
    /**
     * 
     * @type {Escalation}
     * @memberof EscalationsRespondEscalationResponseBody
     */
    escalation: Escalation;
}

export function EscalationsRespondEscalationResponseBodyFromJSON(json: any): EscalationsRespondEscalationResponseBody {
    return EscalationsRespondEscalationResponseBodyFromJSONTyped(json, false);
}

export function EscalationsRespondEscalationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsRespondEscalationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation': EscalationFromJSON(json['escalation']),
    };
}

export function EscalationsRespondEscalationResponseBodyToJSON(value?: EscalationsRespondEscalationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation': EscalationToJSON(value.escalation),
    };
}

