/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CallRoutingCreateRequestBody,
    CallRoutingCreateRequestBodyFromJSON,
    CallRoutingCreateRequestBodyToJSON,
    CallRoutingCreateResponseBody,
    CallRoutingCreateResponseBodyFromJSON,
    CallRoutingCreateResponseBodyToJSON,
    CallRoutingListResponseBody,
    CallRoutingListResponseBodyFromJSON,
    CallRoutingListResponseBodyToJSON,
    CallRoutingUpdateRequestBody,
    CallRoutingUpdateRequestBodyFromJSON,
    CallRoutingUpdateRequestBodyToJSON,
    CallRoutingUpdateResponseBody,
    CallRoutingUpdateResponseBodyFromJSON,
    CallRoutingUpdateResponseBodyToJSON,
} from '../models';

export interface CallRoutingCreateRequest {
    createRequestBody: CallRoutingCreateRequestBody;
}

export interface CallRoutingUpdateRequest {
    id: string;
    updateRequestBody: CallRoutingUpdateRequestBody;
}

/**
 * 
 */
export class CallRoutingApi extends runtime.BaseAPI {

    /**
     * Create a new call route
     * Create CallRouting
     */
    async callRoutingCreateRaw(requestParameters: CallRoutingCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CallRoutingCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling callRoutingCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/call_routes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CallRoutingCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallRoutingCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new call route
     * Create CallRouting
     */
    async callRoutingCreate(requestParameters: CallRoutingCreateRequest, initOverrides?: RequestInit): Promise<CallRoutingCreateResponseBody> {
        const response = await this.callRoutingCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all call routes
     * List CallRouting
     */
    async callRoutingListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CallRoutingListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/call_routes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallRoutingListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all call routes
     * List CallRouting
     */
    async callRoutingList(initOverrides?: RequestInit): Promise<CallRoutingListResponseBody> {
        const response = await this.callRoutingListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an existing call route
     * Update CallRouting
     */
    async callRoutingUpdateRaw(requestParameters: CallRoutingUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CallRoutingUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling callRoutingUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling callRoutingUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/call_routes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CallRoutingUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallRoutingUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing call route
     * Update CallRouting
     */
    async callRoutingUpdate(requestParameters: CallRoutingUpdateRequest, initOverrides?: RequestInit): Promise<CallRoutingUpdateResponseBody> {
        const response = await this.callRoutingUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
