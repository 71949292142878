import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Interpose,
} from "@incident-ui";
import { SyntheticEvent } from "react";
import { tcx } from "src/utils/tailwind-classes";

export type BreadcrumbLink =
  | {
      title: React.ReactNode;
      to?: string;
      onClick?: (
        e?: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>,
      ) => void;
    }
  | {
      renderer: React.ReactNode;
    };

export type Props = {
  className?: string;
  links: Array<BreadcrumbLink>;
};

export const Breadcrumbs = ({
  links,
  className,
}: Props): React.ReactElement => {
  const linkElements = links.map((link, index) => {
    if ("renderer" in link) {
      return link.renderer;
    }

    if (!link.to && !link.onClick) {
      return (
        <span
          key={`link-${index}`}
          className="text-content-tertiary font-normal"
        >
          {link.title}
        </span>
      );
    }

    return (
      <Button
        key={`link-${index}`}
        href={link.to}
        theme={ButtonTheme.Naked}
        analyticsTrackingId={null}
        className="text-xs"
        onClick={link.onClick}
      >
        {link.title}
      </Button>
    );
  });

  return (
    <div className={tcx("flex-center-y text-xs font-medium", className)}>
      <Interpose
        separator={
          <Icon
            id={IconEnum.ChevronRight}
            className="text-slate-300"
            size={IconSize.Small}
          />
        }
      >
        {linkElements}
      </Interpose>
    </div>
  );
};
