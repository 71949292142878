import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import * as ReactAccordion from "@radix-ui/react-accordion";
import styles from "src/components/@ui/Accordion/Accordion.module.scss";
import { tcx } from "src/utils/tailwind-classes";

/**
 *  A style-agnostic accordion component.
 * Must be inside an `<AccordionProvider>` which can be `type="multiple"` (if you want
 * to be able to open more than one accordion at once) or `type="single"` (for one at a time).
 *
 * Ensure you include an `<AccordionTriggerText>` or `<AccordionTriggerButton>` inside the component you pass for the header prop.
 *
 * If you don't like the chevron, you can create your own button as long as it is wrapped in a `<ReactAccordion.Trigger asChild>` component (see Accordion.tsx)
 *
 * This component can be controlled or uncontrolled: I recommend leaving it uncontrolled where possible, but if
 * you need to control when it opens (e.g. when something is enabled) you can pass in `value` and `onValueChange` props to the `AccordionProvider`.
 * See`IntegrationsList.tsx` for an example of this usage.
 *
 * NB. It seems that only the multiple accordion can be both controlled and uncontrolled at the same time. The single accordion must be entirely controlled.
 *
 *
 * If your content is not of a fixed height (e.g. Jira forms which reveal fields step by step) you need to disable animation,
 * or it'll cut off the content. Pass `animated={false}` as a prop.
 *
 * ## Text trigger
 *  With the text trigger you'll likely want to change the value of the text depending on whether it's open or not,
 * so you'll need a controlled accordion.
 *
 *  Radix Docs: https://www.radix-ui.com/docs/primitives/components/accordion
 */
export function Accordion({
  id,
  header,
  children,
  className,
  containerClassName,
  animated = true,
}: {
  id: string;
  header: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  animated?: boolean;
}): React.ReactElement {
  return (
    <ReactAccordion.Item value={id} className={containerClassName}>
      <div className={className}>
        <ReactAccordion.Header asChild>{header}</ReactAccordion.Header>
      </div>
      <ReactAccordion.Content
        className={tcx({ [styles.accordionContent]: animated })}
      >
        {children}
      </ReactAccordion.Content>
    </ReactAccordion.Item>
  );
}

export function AccordionTriggerButton({
  className,
}: {
  className?: string;
}): React.ReactElement {
  return (
    <ReactAccordion.Trigger asChild>
      <Button
        className={tcx(
          styles.triggerButton,
          "rounded-full mr-2 p-2",
          className,
        )}
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId={null}
        title="See more"
        data-accordion-trigger
      >
        <AccordionChevron />
      </Button>
    </ReactAccordion.Trigger>
  );
}

export function AccordionTriggerText({
  className,
  text,
  children,
}: {
  className?: string;
} & (
  | { children: React.ReactNode; text?: never }
  | { text: string; children?: never }
)): React.ReactElement {
  return (
    <ReactAccordion.Trigger asChild>
      <Button
        className={tcx(
          className,
          "text-content-primary font-semibold underline hover:text-slate-700",
        )}
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId={null}
        data-accordion-trigger
      >
        {text || children}
      </Button>
    </ReactAccordion.Trigger>
  );
}

export function AccordionChevron(): React.ReactElement {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="w-3 h-3"
    >
      <path
        d="M8.144 4.289 15.856 12l-7.712 7.711"
        fill="none"
        stroke="currentColor"
        strokeWidth="5.14074"
      />
    </svg>
  );
}
export { Accordion as AccordionProvider } from "@radix-ui/react-accordion";
