/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmbeddedCatalogEntry
 */
export interface EmbeddedCatalogEntry {
    /**
     * Optional aliases that can be used to reference this entry
     * @type {Array<string>}
     * @memberof EmbeddedCatalogEntry
     */
    aliases?: Array<string>;
    /**
     * When this entry was archived
     * @type {Date}
     * @memberof EmbeddedCatalogEntry
     */
    archived_at?: Date;
    /**
     * ID of this catalog type
     * @type {string}
     * @memberof EmbeddedCatalogEntry
     */
    catalog_type_id: string;
    /**
     * An optional alternative ID for this entry, which is ensured to be unique for the type
     * @type {string}
     * @memberof EmbeddedCatalogEntry
     */
    external_id?: string;
    /**
     * ID of this catalog entry
     * @type {string}
     * @memberof EmbeddedCatalogEntry
     */
    id: string;
    /**
     * Name is the human readable name of this entry
     * @type {string}
     * @memberof EmbeddedCatalogEntry
     */
    name: string;
}

export function EmbeddedCatalogEntryFromJSON(json: any): EmbeddedCatalogEntry {
    return EmbeddedCatalogEntryFromJSONTyped(json, false);
}

export function EmbeddedCatalogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmbeddedCatalogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'archived_at': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'catalog_type_id': json['catalog_type_id'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function EmbeddedCatalogEntryToJSON(value?: EmbeddedCatalogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aliases': value.aliases,
        'archived_at': value.archived_at === undefined ? undefined : (value.archived_at.toISOString()),
        'catalog_type_id': value.catalog_type_id,
        'external_id': value.external_id,
        'id': value.id,
        'name': value.name,
    };
}

