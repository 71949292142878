import {
  isSpecialBoolFilter,
  SPECIAL_ALERT_FILTERS,
  SPECIAL_ESCALATION_FILTERS,
  SPECIAL_INCIDENT_FILTERS,
} from "@incident-shared/filters/constants";
import { captureException } from "@sentry/react";
import _ from "lodash";
import { createContext, useContext } from "react";
import { Environment, getEnvironment } from "src/utils/environment";
import { v4 as uuid } from "uuid";

import { assertUnreachable } from "../../../utils/utils";
import { AvailableFilter } from "./utils";

// This type is also used in the dbt user-defined-function that applies these
// filters at the BigQuery layer, for Insights purposes.
//
// Don't change these filters unless you're also changing that file!
//
// https://github.com/incident-io/dbt/blob/master/user-defined-functions/filter_incidents.ts
export type FormFieldValue = {
  field_key: string;
  // unique identifier for this field, will usually match field_key but for custom fields
  // or roles it will be the unique ID of the specific field
  field_id: string;
  operator: string;

  bool_value?: boolean;
  multiple_options_value?: Array<string>;
  single_option_value?: string;
  string_value?: string;
};

export type ExtendedFormFieldValue = FormFieldValue & {
  key: string;
  // unique identifier for this filter, used to know which filter has been added/edited/removed
  filter_id: string;
  // identifier we'll use when calling the typeahead API to get possible values for this filter
  typeahead_lookup_id?: string | null;
  // if this filter is synthesised from a catalog type, this is the ID of that catalog type
  catalog_type_id?: string;
  // is_disabled is set by the backend on default filters to indicate filters that are
  // added and cannot be removed, we don't send them to Explo as our queries don't support them
  // but we show them in the UI to indicate that they are applied
  is_disabled?: boolean;
  // disabled_reason is set by the backend on default filters to indicate why a filter is disabled
  disabled_reason?: string;
};

export type FiltersContextType = {
  filters: ExtendedFormFieldValue[];
  addFilter: (f: ExtendedFormFieldValue) => void;
  editFilter: (f: ExtendedFormFieldValue) => void;
  deleteFilter: (f: string) => void;
  clearFilters: () => void;
  availableFilterFields: AvailableFilter[];
  kind: FiltersContextKind;
  filtersLoading: boolean;
};

export const FiltersContext = createContext<FiltersContextType | null>(null);

export type FiltersContextKind =
  | "incident"
  | "alert"
  | "user"
  | "status"
  | "escalation_targets"
  | "escalations"
  | "insights"
  | "readiness";

export const FiltersContextProvider = ({
  filters,
  setFilters,
  availableFilterFields,
  filtersLoading,
  kind,
  children,
}: React.PropsWithChildren<{
  filters: ExtendedFormFieldValue[];
  setFilters: (newFields: ExtendedFormFieldValue[]) => void;
  filtersLoading: boolean;
  kind: FiltersContextKind;
  availableFilterFields: AvailableFilter[];
}>) => {
  return (
    <FiltersContext.Provider
      value={{
        kind,
        filters,
        addFilter: (f) => {
          setFilters([...filters, { ...f, key: uuid() }]);
        },
        editFilter: (f) =>
          setFilters(
            _.cloneDeep(filters).map((oldFilter) =>
              oldFilter.filter_id === f.filter_id ? f : oldFilter,
            ),
          ),
        deleteFilter: (filterId) =>
          setFilters(
            _.cloneDeep(filters).filter(
              (oldFilter) => oldFilter.filter_id !== filterId,
            ),
          ),
        clearFilters: () => setFilters([]),
        availableFilterFields,
        filtersLoading,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = (): FiltersContextType => {
  const context = useContext(FiltersContext);
  if (context) {
    return context;
  }

  const error = new Error(
    "useFiltersContext hook must be used in a child of FiltersContextProvider",
  );

  const environment = getEnvironment();
  if (environment === Environment.Development) {
    throw error;
  }

  captureException(error);

  return {
    filters: [],
    addFilter: () => {
      // do nothing
    },
    editFilter: () => {
      // do nothing
    },
    deleteFilter: () => {
      // do nothing
    },
    clearFilters: () => {
      // do nothing
    },
    availableFilterFields: [],
    kind: "incident",
    filtersLoading: true,
  };
};

// isViewableFor determines if a filter is viewable for a given context.
// We use it for displaying filters in the AppliedFilters banners,
// or for working out which filters to show in the popover
export const isViewableFor =
  (
    kind: FiltersContextKind,
    usage: { popover: boolean } = { popover: false },
  ) =>
  (filter: ExtendedFormFieldValue | AvailableFilter): boolean => {
    const fieldKey = "field_key" in filter ? filter.field_key : filter.key;

    if (isSpecialBoolFilter(fieldKey)) {
      // The filter popover is the only place we'll show the special bool filters
      // such as "include private", or "include declined incidents"
      return usage.popover;
    }

    switch (kind) {
      case "incident":
        return !SPECIAL_INCIDENT_FILTERS.includes(fieldKey);
      case "alert":
        return !SPECIAL_ALERT_FILTERS.includes(fieldKey);
      case "status":
        return false;
      case "user":
        return true;
      case "escalation_targets":
        return true;
      case "escalations":
        return !SPECIAL_ESCALATION_FILTERS.includes(fieldKey);
      case "insights":
        return true;
      case "readiness":
        return true;
      default:
        assertUnreachable(kind);
        return false;
    }
  };
