import { ConfigureDrawerProps } from "@incident-shared/integrations";
import { OrgAwareNavLink } from "@incident-shared/org-aware";
import { Callout, CalloutTheme, IconEnum } from "@incident-ui";
import React from "react";

import { ConnectingUserAndSiteJira } from "../../atlassian-connect/ConnectingUserAndSite";
import { GenericConfigureDrawerContents } from "../IntegrationDrawer";

export const JiraCloudConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  return (
    <GenericConfigureDrawerContents {...props}>
      <ConnectingUserAndSiteJira />
      <Callout
        theme={CalloutTheme.Info}
        iconOverride={IconEnum.New}
        title="Sync incidents with Jira is now Incident tickets"
        subtitle={
          <>
            If you want to sync incidents to tickets in Jira, you can do so from
            the{" "}
            <OrgAwareNavLink
              className="underline"
              to="/settings/incident-tickets"
            >
              incident tickets
            </OrgAwareNavLink>{" "}
            section.
          </>
        }
      />
    </GenericConfigureDrawerContents>
  );
};
