import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { TabSection } from "@incident-ui";
import React, { useState } from "react";
import graphic from "src/components/settings/banners/banner-lifecycle.svg";
import {
  IncidentDurationMetric,
  IncidentTimestamp,
} from "src/contexts/ClientContext";

import { useQueryParams } from "../../../utils/query-params";
import { LevelUpIncidentResponseBanner } from "../LevelUpIncidentResponseBanner";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { LifecycleList } from "./LifecycleList";
import { TimestampsAndMetricsTab } from "./timestamps-metrics/TimestampsAndMetricsTab";

export enum LifecycleTabs {
  Lifecycles = "lifecycles",
  Timestamps = "timestamps",
}

export const LifecyclePage = ({
  timestamps,
  metrics,
}: {
  timestamps: IncidentTimestamp[];
  metrics: IncidentDurationMetric[];
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const queryParams = useQueryParams();
  const selectedTab =
    Object.values(LifecycleTabs).find((t) => t === queryParams.get("tab")) ??
    LifecycleTabs.Lifecycles;

  const [showAddTimestampModal, setShowAddTimestampModal] = useState<
    string | boolean
  >(false);

  const onTabChange = (tab: string) => {
    const copyOfQueryParams = new URLSearchParams(queryParams);
    copyOfQueryParams.set("tab", tab);
    navigate({ search: copyOfQueryParams.toString() });
  };

  const tabs: Record<LifecycleTabs, { component: JSX.Element; title: string }> =
    {
      [LifecycleTabs.Lifecycles]: {
        title: "Lifecycles",
        component: <LifecycleList />,
      },
      [LifecycleTabs.Timestamps]: {
        title: "Timestamps and metrics",
        component: (
          <TimestampsAndMetricsTab
            metrics={metrics}
            showAddTimestampModal={showAddTimestampModal}
            setShowAddTimestampModal={setShowAddTimestampModal}
            timestamps={timestamps}
          />
        ),
      },
    };

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        graphic={<img src={graphic} className="h-40" />}
        title="Manage incident lifecycle"
        subtitle="Set statuses and timestamps to track the progress of each incident."
        learnMoreURL="https://incident.io/guide/foundations/statuses"
      />
      <>
        <TabSection
          key={selectedTab}
          withIndicator
          defaultTab={selectedTab}
          onTabChange={onTabChange}
          tabBarClassName="border-b border-stroke"
          tabs={Object.entries(tabs).map(([key, value]) => ({
            id: key,
            label: value.title,
          }))}
        />
        <div className={"mt-2"}>{tabs[selectedTab].component}</div>
      </>
      <LevelUpIncidentResponseBanner />
    </SettingsSubPageWrapper>
  );
};
