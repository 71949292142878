import { Tooltip } from "@incident-ui";

export const TimezoneHelperText = ({
  customText,
}: {
  customText?: string;
}): React.ReactElement => {
  return (
    <div className="inline-flex text-slate-600">
      <p className="text-sm">
        {customText ||
          "Dates and times selected will be applied relative to the timezone of the schedule. "}
        <TimezoneHelperToolTip />
      </p>
    </div>
  );
};

export const TimezoneHelperToolTip = (): React.ReactElement => {
  return (
    <Tooltip
      content={
        <div className="p-2">
          <p className="pb-2">
            Reports can include schedules from many timezones.{" "}
          </p>
          <p className="pb-2">
            Any dates or times specified will be interpreted in the timezone
            associated with the schedule.
          </p>
        </div>
      }
    >
      <span className="text-sm underline">Learn more</span>
    </Tooltip>
  );
};
