import { CreateEditExpressionFormData } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { ErrorMessage } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import React, { useEffect, useMemo, useState } from "react";
import { UseFieldArrayUpdate, useFormContext } from "react-hook-form";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useIntercom } from "react-use-intercom";
import { AttributeEntries } from "src/components/@shared/attribute/AttributeEntries";
import { CatalogTypeSelectorV2 } from "src/components/@shared/catalog/CatalogTypeSelector";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "src/components/@shared/forms/v2/inputs/ToggleV2";
import { EngineParamBinding } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-hooks";

import { QueryPreviewWindow } from "../QueryPreview";
import styles from "./ParseTargetForm.module.scss";

export const ParseTargetForm = ({
  update,
  operationIdx,
  operationOption,
  isEditable,
  isFinalOperation,
  removeOperation,
  exampleEnabled,
  exampleInput,
  exampleResult,
}: {
  operationIdx: number;
  operationOption: SelectOption;
  update: UseFieldArrayUpdate<CreateEditExpressionFormData, "operations">;
  isEditable: boolean;
  isFinalOperation: boolean;
  removeOperation: () => void;
  exampleEnabled: boolean;
  exampleInput?: EngineParamBinding;
  exampleResult?: EngineParamBinding;
}): React.ReactElement => {
  const { showArticle } = useIntercom();
  const formMethods = useFormContext<CreateEditExpressionFormData>();
  const operation = formMethods.watch(`operations.${operationIdx}`);

  if (operation?.parse) {
    if (
      operation.returns?.type !== operation.parse.returns?.type ||
      operation.returns?.array !== operation.parse.returns?.array
    ) {
      update(operationIdx, {
        ...operation,
        returns: {
          type: operation.parse.returns?.type,
          array: operation.parse.returns?.array,
        },
        parse: {
          ...operation.parse,
        },
      });
    }
  }

  const operationResultType = operation?.parse?.returns?.type || "";
  const arrayNotSupported = ["Text", "Bool", "Number"].includes(
    operationResultType,
  );

  const { data: resourcesData } = useAPI("catalogListResources", undefined);

  // This is where we try evaluating the Javascript against the example input
  // we've been provided. If we successfully calculate something, we can build a
  // preview of it.
  const {
    result: previewResult,
    isLoading: previewIsLoading,
    errorMessage: previewErrorMessage,
  } = useEvaluateJavascript({
    source: operation?.parse?.source || "",
    subject: exampleInput?.value?.literal || "",
  });

  return (
    <>
      <li>
        <div
          className={tcx("flex", !exampleEnabled && "flex-between space-x-4")}
        >
          <div
            className={tcx(
              `flex-none p-4 space-y-2`,
              exampleEnabled && `w-[434px]`,
            )}
          >
            <div className="flex-center-y">
              {operationOption.icon && (
                <Icon id={operationOption.icon} className="mr-1" />
              )}
              <div className="text-sm">
                <span className="font-semibold">Then </span>
                {operationOption.description}
              </div>
            </div>
            <div className={"flex"}>
              <div>
                <InputV2
                  name={`operations.${operationIdx}.parse.source`}
                  className={"font-mono"}
                  formMethods={formMethods}
                  disabled={!isEditable}
                  helptext={
                    <>
                      <p>
                        This input supports JavaScript. ES6 language features
                        are not supported.
                      </p>
                      You can{" "}
                      <Button
                        analyticsTrackingId="alert-source-json-extract-help"
                        onClick={() => showArticle(8826732)}
                        theme={ButtonTheme.Unstyled}
                        className="underline hover:text-content-primary"
                      >
                        view examples for extracting data in our help center.
                      </Button>
                    </>
                  }
                  placeholder="$.some.nested.field"
                  required
                  autoFocus
                />
                <div className={"mt-2 cursor-pointer"}>
                  <Button
                    analyticsTrackingId={null}
                    title="Reset"
                    icon={IconEnum.Refresh1}
                    iconProps={{ size: IconSize.Medium }}
                    onClick={() =>
                      formMethods.setValue<`operations.${number}.parse.source`>(
                        `operations.${operationIdx}.parse.source`,
                        "$",
                      )
                    }
                    theme={ButtonTheme.Naked}
                  >
                    Reset
                  </Button>
                  <ErrorMessage message={previewErrorMessage} />
                </div>
              </div>
            </div>
          </div>
          {exampleEnabled ? (
            <QueryPreviewWindow
              tooltipMessage={
                previewErrorMessage
                  ? "Error calculating result, showing last successful value or full payload."
                  : undefined
              }
            >
              {!previewResult && previewIsLoading ? (
                <Loader />
              ) : (
                <SyntaxHighlighter
                  language="json"
                  className={tcx(styles.json)}
                  useInlineStyles={false}
                >
                  {previewResult}
                </SyntaxHighlighter>
              )}
            </QueryPreviewWindow>
          ) : null}
        </div>
      </li>
      <li>
        <div className={"flex"}>
          <div className={tcx(`p-4 space-y-2`, exampleEnabled && `w-[434px]`)}>
            <div>
              <div className="flex-center-y">
                {operationOption.icon && (
                  <Icon id={operationOption.icon} className="mr-1" />
                )}
                <div className="text-sm">
                  <span className="font-semibold">Choose </span>
                  what the result should be parsed into
                </div>
              </div>
              <fieldset>
                <CatalogTypeSelectorV2
                  name={`operations.${operationIdx}.parse.returns.type`}
                  inputClassName={"mt-2"}
                  disabled={!isEditable}
                  formMethods={formMethods}
                  mode={"engine"}
                  description={"value_docstring"}
                  required
                />
              </fieldset>

              <ToggleV2
                name={`operations.${operationIdx}.parse.returns.array`}
                disabled={!isEditable || arrayNotSupported}
                isDisabledTooltipContent={
                  arrayNotSupported
                    ? `Multi-valued ${operationResultType.toLowerCase()} attributes are not supported`
                    : undefined
                }
                formMethods={formMethods}
                className={"mt-3"}
                align={"left"}
                label={"Is the result an array?"}
              />
            </div>
            {isFinalOperation ? (
              <div className={"mt-6"}>
                <Button
                  analyticsTrackingId={null}
                  title="Remove operation"
                  icon={IconEnum.Delete2}
                  iconProps={{ size: IconSize.Medium }}
                  onClick={() => removeOperation()}
                  theme={ButtonTheme.Naked}
                >
                  Remove operation
                </Button>
              </div>
            ) : null}
          </div>
          {exampleEnabled ? (
            <QueryPreviewWindow>
              {exampleResult && resourcesData?.resources ? (
                <AttributeEntries
                  mode={"catalog"}
                  typeName={operationResultType}
                  catalogResources={resourcesData.resources}
                  attributeBinding={exampleResult}
                  truncate
                />
              ) : null}
            </QueryPreviewWindow>
          ) : null}
        </div>
      </li>
    </>
  );
};

export const useEvaluateJavascript = ({
  source,
  subject,
}: {
  source?: string;
  subject?: string;
}): {
  isLoading: boolean;
  isTyping: boolean;
  result: string;
  source: string;
  errorMessage?: string;
} => {
  const [isTyping, setIsTyping] = useState(false);

  const unDebouncedParams = useMemo(
    () => ({
      source: source || "",
      subject: subject || "",
    }),
    [source, subject],
  );
  const params = useDebounce(unDebouncedParams, 1000);

  // Track when the user is typing. We'll use this to show busy indicator.
  useEffect(() => {
    const userIsTyping = source !== params.source || subject !== params.subject;
    setIsTyping(userIsTyping);
  }, [source, subject, params.source, params.subject]);

  // If either subject or source are undefined, we shouldn't make any requests.
  const validParams = params.source && params.subject;

  const { data, isLoading, error } =
    // If we don't have either source or subject, we should not make a call
    useAPI(
      validParams ? "engineEvaluateJavascript" : null,
      {
        evaluateJavascriptRequestBody: params,
      },
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
      },
    );

  // Cache the last successful result so we can continue to display it.
  const [lastSuccessfulResult, setLastSuccessfulResult] = useState<
    | {
        source: string;
        result: string;
      }
    | undefined
  >();

  let result = "undefined";
  let errorMessage: string | undefined;
  if (data?.result) {
    try {
      result = JSON.stringify(JSON.parse(data.result), null, "  ");
    } catch (e) {
      result = "error";
    }
  } else if (error) {
    const sourceError = error.errors.find(
      (e) => e?.source?.pointer === "source",
    );
    if (sourceError) {
      errorMessage = sourceError.message;
    } else {
      throw error;
    }
  }

  useEffect(() => {
    if (!error) {
      if (result === "undefined") {
        try {
          const initialPayloadJSON = JSON.stringify(
            JSON.parse(params.subject),
            null,
            "  ",
          );
          setLastSuccessfulResult({
            source: params.source,
            result: initialPayloadJSON,
          });
        } catch (e) {
          // do nothing
        }
      } else {
        setLastSuccessfulResult({
          source: params.source,
          result: result,
        });
      }
    }
  }, [error, params.source, params.subject, result]);

  return {
    isLoading,
    isTyping,
    result: lastSuccessfulResult?.result || result,
    source: lastSuccessfulResult?.source || source || "",
    errorMessage,
  };
};
