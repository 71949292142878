import {
  Condition,
  ConditionGroup,
  ConditionGroupPayload,
  ConditionPayload,
} from "src/contexts/ClientContext";

export function conditionToConditionPayload(
  condition: Condition,
): ConditionPayload {
  return {
    operation: condition.operation.value,
    param_bindings: condition.param_bindings,
    subject: condition.subject.reference,
  };
}

export function conditionGroupToConditionGroupPayload(
  conditionGroup: ConditionGroup,
): ConditionGroupPayload {
  return {
    conditions: conditionGroup.conditions.map(conditionToConditionPayload),
  };
}
