import {
  InternalStatusPageThemeEnum,
  StatusPageThemeEnum,
} from "@incident-io/api";
import { Badge, BadgeTheme, Heading, IconEnum } from "@incident-ui";
import _ from "lodash";
import { tcx } from "src/utils/tailwind-classes";

import { PreviewItems as ComponentPreview } from "../common/ComponentsEditor/Preview";
import { PreviewItem } from "../common/ComponentsEditor/utils";
import defaultFavicon from "../common/images/default-favicon.png";
import defaultFaviconLight from "../common/images/default-favicon-light.png";

export enum PageType {
  PublicStandalone = "public-standalone",
  PublicParentPage = "public-parent",
  Customer = "customer",
  Internal = "internal",
}

export const PageStructurePreview = ({
  name,
  subPageName,
  items,
  logoPreviewURL,
  faviconPreviewURL,
  theme,
  type = PageType.PublicStandalone,
}: {
  name: string;
  subPageName?: string;
  logoPreviewURL: string | null;
  faviconPreviewURL: string | null;
  theme: StatusPageThemeEnum | InternalStatusPageThemeEnum;
  items: PreviewItem[];
  type: PageType;
}) => {
  const faviconSrc =
    faviconPreviewURL ||
    (theme === StatusPageThemeEnum.Dark ? defaultFavicon : defaultFaviconLight);

  return (
    <div>
      <Heading level={3} size="small">
        Preview
      </Heading>
      <div className="pt-8 px-10 bg-surface-tertiary rounded overflow-hidden">
        <div className="rounded-t-md drop-shadow-lg">
          {/* A 'title bar' */}
          <div className="rounded-t-md bg-slate-400 flex px-3 space-x-1.5 items-center">
            <div className="bg-slate-300 h-2.5 w-2.5 rounded-full" />
            <div className="bg-slate-300 h-2.5 w-2.5 rounded-full" />
            <div className="bg-slate-300 h-2.5 w-2.5 rounded-full" />
            {/* The tab for this status page */}
            <div className="pt-1 pl-2">
              <div className="bg-surface-tertiary rounded-t-md p-2 text-xs flex shrink text-slate-700">
                <img
                  src={faviconSrc}
                  alt={""}
                  className="object-scale-down object-center mr-1"
                  width={16}
                  height={16}
                />
                {name}
                {type === PageType.PublicParentPage
                  ? ` ${subPageName} Status`
                  : type === PageType.Customer
                  ? " Status"
                  : type === PageType.PublicStandalone
                  ? " Status"
                  : ""}
              </div>
            </div>
          </div>
          <div
            className="space-y-2 relative"
            style={{
              background:
                theme === StatusPageThemeEnum.Dark
                  ? "radial-gradient(75.93% 33.94% at 50% 0%, rgba(41, 51, 65, 0.75) 0%, rgba(41, 51, 65, 0) 100%), #14171c"
                  : "white",
            }}
          >
            <div>
              {type === PageType.Customer && (
                <div
                  className={tcx(
                    "w-full  py-4 px-6  text-xs",
                    theme === StatusPageThemeEnum.Dark
                      ? "bg-surface-invert text-white"
                      : "bg-surface-secondary text-slate-600",
                  )}
                >
                  Status page for {subPageName}
                </div>
              )}
              <div className="px-2">
                <div className="p-4 pb-3 flex items-center">
                  <div className="flex grow items-center">
                    <h1
                      className={tcx(
                        "text-2xl",
                        theme === StatusPageThemeEnum.Dark
                          ? "text-white"
                          : "text-content-primary",
                      )}
                    >
                      {logoPreviewURL ? (
                        <img
                          src={logoPreviewURL}
                          alt={name}
                          className="object-scale-down object-left h-6"
                        />
                      ) : (
                        <>{name}</>
                      )}
                    </h1>
                    {type === PageType.Internal && (
                      <Badge
                        className="ml-4"
                        theme={BadgeTheme.Info}
                        icon={IconEnum.LockClosed}
                      >
                        Internal
                      </Badge>
                    )}
                  </div>
                </div>
                {subPageName && type === PageType.PublicParentPage ? (
                  <div className="flex text-sm px-4 gap-1.5 pb-2">
                    <div className="text-slate-600"> Status Pages</div>
                    <div className="text-slate-600">/</div>
                    <div
                      className={tcx(
                        theme === StatusPageThemeEnum.Dark
                          ? "text-slate-200"
                          : "text-content-primary",
                      )}
                    >
                      {subPageName}
                    </div>
                  </div>
                ) : null}

                <div
                  className={tcx(
                    "rounded-t-lg border-t border-x mx-4",
                    theme === StatusPageThemeEnum.Dark
                      ? "border-slate-800"
                      : "border-stroke",
                  )}
                >
                  <ComponentPreview
                    items={_.take(
                      items.filter((i) => !i.hidden),
                      2,
                    )}
                    theme={theme}
                  />
                </div>
                <div
                  className={tcx(
                    "bg-gradient-to-t to-transparent absolute bottom-0 left-0 w-full h-20",
                    theme === StatusPageThemeEnum.Dark
                      ? "from-[#14171c]"
                      : "from-white",
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
