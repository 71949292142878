/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface Priority
 */
export interface Priority {
    /**
     * When this type was archived
     * @type {Date}
     * @memberof Priority
     */
    archived_at?: Date;
    /**
     * 
     * @type {TextNode}
     * @memberof Priority
     */
    description?: TextNode;
    /**
     * The ID of this priority
     * @type {string}
     * @memberof Priority
     */
    id: string;
    /**
     * Whether this is the default priority
     * @type {boolean}
     * @memberof Priority
     */
    is_default: boolean;
    /**
     * The human readable label for this priority
     * @type {string}
     * @memberof Priority
     */
    name: string;
    /**
     * The rank of the priority. Higher values are less urgent
     * @type {number}
     * @memberof Priority
     */
    rank: number;
    /**
     * All the slugs that this priority has
     * @type {Array<string>}
     * @memberof Priority
     */
    slugs: Array<string>;
}

export function PriorityFromJSON(json: any): Priority {
    return PriorityFromJSONTyped(json, false);
}

export function PriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Priority {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archived_at': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'id': json['id'],
        'is_default': json['is_default'],
        'name': json['name'],
        'rank': json['rank'],
        'slugs': json['slugs'],
    };
}

export function PriorityToJSON(value?: Priority | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archived_at': value.archived_at === undefined ? undefined : (value.archived_at.toISOString()),
        'description': TextNodeToJSON(value.description),
        'id': value.id,
        'is_default': value.is_default,
        'name': value.name,
        'rank': value.rank,
        'slugs': value.slugs,
    };
}

