import { AvailablePanel } from "@incident-io/api";

import barChart from "./bar_chart.svg";
import gridWithChartsOverTables from "./grid_with_charts_over_tables.svg";
import gridWithThreeChartsAndTable from "./grid_with_three_charts_and_table.svg";
import lineChartOverStackedBarChart from "./line_chart_over_stacked_bar_chart.svg";
import lineChartOverTable from "./line_chart_over_table.svg";
import stackedBarChart from "./stacked_bar_chart.svg";
import stackedBarChartOverStackedBarChart from "./stacked_bar_chart_over_stacked_bar_chart.svg";
import stackedBarChartOverTable from "./stacked_bar_chart_over_table.svg";
import tableOverTable from "./table_over_table.svg";
import text from "./text.svg";
import twoChartsOverTable from "./two_charts_over_table.svg";

const panelGraphics = {
  stacked_bar_chart_over_table: stackedBarChartOverTable,
  stacked_bar_chart_over_stacked_bar_chart: stackedBarChartOverStackedBarChart,
  bar_chart: barChart,
  stacked_bar_chart: stackedBarChart,
  table_over_table: tableOverTable,
  line_chart_over_stacked_bar_chart: lineChartOverStackedBarChart,
  line_chart_over_table: lineChartOverTable,
  grid_with_three_charts_and_table: gridWithThreeChartsAndTable,
  grid_with_charts_over_tables: gridWithChartsOverTables,
  twoChartsOverTable: twoChartsOverTable,
  text: text,
};

export const PanelGraphic = ({ panel }: { panel: AvailablePanel }) => {
  const graphic = panelGraphics[panel.layout] || stackedBarChartOverTable; // Use stackedBarChartOverTable as default

  return <img src={graphic} />;
};
