/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    PostmortemShareMessage,
    PostmortemShareMessageFromJSON,
    PostmortemShareMessageFromJSONTyped,
    PostmortemShareMessageToJSON,
} from './PostmortemShareMessage';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemShare
 */
export interface PostmortemShare {
    /**
     * 
     * @type {Actor}
     * @memberof PostmortemShare
     */
    actor: Actor;
    /**
     * When the postmortem share was created
     * @type {Date}
     * @memberof PostmortemShare
     */
    created_at: Date;
    /**
     * Unique ID for the postmortem share
     * @type {string}
     * @memberof PostmortemShare
     */
    id: string;
    /**
     * ID of the incident
     * @type {string}
     * @memberof PostmortemShare
     */
    incident_id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemShare
     */
    postmortem_description: TextNode;
    /**
     * Array of slack messages sent for this share
     * @type {Array<PostmortemShareMessage>}
     * @memberof PostmortemShare
     */
    share_messages: Array<PostmortemShareMessage>;
    /**
     * When the postmortem share was last updated
     * @type {Date}
     * @memberof PostmortemShare
     */
    updated_at: Date;
}

export function PostmortemShareFromJSON(json: any): PostmortemShare {
    return PostmortemShareFromJSONTyped(json, false);
}

export function PostmortemShareFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemShare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'postmortem_description': TextNodeFromJSON(json['postmortem_description']),
        'share_messages': ((json['share_messages'] as Array<any>).map(PostmortemShareMessageFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function PostmortemShareToJSON(value?: PostmortemShare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'postmortem_description': TextNodeToJSON(value.postmortem_description),
        'share_messages': ((value.share_messages as Array<any>).map(PostmortemShareMessageToJSON)),
        'updated_at': (value.updated_at.toISOString()),
    };
}

