/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttributePayload,
    CatalogTypeAttributePayloadFromJSON,
    CatalogTypeAttributePayloadFromJSONTyped,
    CatalogTypeAttributePayloadToJSON,
} from './CatalogTypeAttributePayload';

/**
 * 
 * @export
 * @interface CatalogListDerivedAttributesRequestBody
 */
export interface CatalogListDerivedAttributesRequestBody {
    /**
     * The attributes of the source catalog type
     * @type {Array<CatalogTypeAttributePayload>}
     * @memberof CatalogListDerivedAttributesRequestBody
     */
    source_attributes: Array<CatalogTypeAttributePayload>;
    /**
     * The type of the source catalog type (empty if the type hasn't been created yet)
     * @type {string}
     * @memberof CatalogListDerivedAttributesRequestBody
     */
    source_type?: string;
}

export function CatalogListDerivedAttributesRequestBodyFromJSON(json: any): CatalogListDerivedAttributesRequestBody {
    return CatalogListDerivedAttributesRequestBodyFromJSONTyped(json, false);
}

export function CatalogListDerivedAttributesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListDerivedAttributesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source_attributes': ((json['source_attributes'] as Array<any>).map(CatalogTypeAttributePayloadFromJSON)),
        'source_type': !exists(json, 'source_type') ? undefined : json['source_type'],
    };
}

export function CatalogListDerivedAttributesRequestBodyToJSON(value?: CatalogListDerivedAttributesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source_attributes': ((value.source_attributes as Array<any>).map(CatalogTypeAttributePayloadToJSON)),
        'source_type': value.source_type,
    };
}

