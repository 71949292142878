import {
  Trigger,
  Workflow,
  WorkflowsShowTriggerResponseBody,
} from "@incident-io/api";
import { useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ErrorResponse } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { referenceToFormOnceFor } from "../../common/marshall";
import { ClonedWorkflow, WorkflowFormData } from "../../common/types";
import { WorkflowsFormContext } from "../WorkflowsFormContext";
import { WorkflowDrawerState } from "./useViewController";

export type TriggerCallbacks = {
  // This is the trigger name that the user has chosen. When you choose a
  // trigger, this will be set before the trigger data is loaded.
  triggerName?: string;
  // This is all the data about the trigger, fetched from the API.
  trigger?: Trigger;
  triggerLoading: boolean;
  isManualTrigger: boolean;
  onEditTrigger: () => void;
  onChooseTrigger: (name: string) => void;
};

type ManageTriggerReturn = {
  triggerError?: ErrorResponse;
  triggerCallbacks: TriggerCallbacks;
};

export const useTriggerController = ({
  formMethods,
  workflow,
  template,
  defaultTrigger,
  onOpenDrawer,
  onCloseDrawer,
}: {
  formMethods: UseFormReturn<WorkflowFormData>;
  workflow?: Workflow | ClonedWorkflow;
  template?: Workflow | ClonedWorkflow;
  defaultTrigger?: Trigger;
  onOpenDrawer: (drawer: WorkflowDrawerState) => void;
  onCloseDrawer: () => void;
}): ManageTriggerReturn => {
  // This stores the trigger that a user has chosen, so that we can load
  // the associated data below.
  const [triggerName, setTriggerName] = useState<string | undefined>(
    workflow?.trigger.name || template?.trigger.name || defaultTrigger?.name,
  );

  // BE CAREFUL HERE - this controls setting the "once_for" for new triggers.
  // If we mess this up, workflows will duplicate if once_for is not set correctly.
  // When we change the trigger we want to ensure we're resetting the once_for to a sensible value.
  // It must be true on first page load, so that we set it on initial trigger selection.
  const [isChangingTrigger, setIsChangingTrigger] = useState(
    !workflow && !template && !defaultTrigger,
  );

  const onEditTrigger = () => {
    // Reset all the fields dependent on trigger, using shouldDirty so
    // we know our state is dirty!
    formMethods.setValue<"step_groups">("step_groups", [], {
      shouldDirty: true,
    });
    formMethods.setValue<"condition_groups">("condition_groups", [], {
      shouldDirty: true,
    });
    formMethods.setValue<"once_for">("once_for", [], { shouldDirty: true });
    formMethods.setValue<"expressions">("expressions", [], {
      shouldDirty: true,
    });

    // Empty our state
    setTriggerName(undefined);
    // Tell us that we are changing the trigger, so we reset the once_for when
    // we choose a new trigger
    setIsChangingTrigger(true);
    onOpenDrawer(WorkflowDrawerState.ChooseTrigger);
  };

  const onChooseTrigger = (trigger: string) => {
    setTriggerName(trigger);
    onCloseDrawer();
  };

  const { setValue } = formMethods;
  // Reload the trigger data when the trigger name changes.
  const {
    data: triggerData,
    error: triggerError,
    isLoading: triggerLoading,
  } = useAPI(
    // NOTE: Conditionally resolve the trigger, only if we have a trigger name set.
    triggerName ? "workflowsShowTrigger" : null,
    {
      name: triggerName ?? "",
    },
    {
      onSuccess: (data: WorkflowsShowTriggerResponseBody) => {
        // If the trigger has been changed, we need to update the once for
        // to the trigger's default once for values.
        if (isChangingTrigger) {
          setValue<"once_for">(
            "once_for",
            data.trigger.default_once_for.map(referenceToFormOnceFor),
          );

          setValue<"condition_groups">(
            "condition_groups",
            data.trigger.default_condition_groups || [],
          );

          setIsChangingTrigger(false);
        }
      },
    },
  );

  const isManualTrigger = triggerName === "manual";

  return {
    triggerCallbacks: {
      triggerName,
      trigger: triggerData?.trigger,
      onChooseTrigger,
      onEditTrigger,
      triggerLoading,
      isManualTrigger,
    },
    // This is handled up high, so we keep it separate (and don't put it in the context) to avoid confusion
    triggerError,
  };
};

export const useWorkflowsTrigger = (): TriggerCallbacks => {
  const ctx = useContext(WorkflowsFormContext);
  if (ctx && ctx.triggerCallbacks) {
    return ctx.triggerCallbacks;
  }
  throw new Error(
    "useWorkflowsTriggers must be used within a WorkflowsFormProvider",
  );
};
