import type { SVGProps } from "react";
import * as React from "react";
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 10.75H4a.75.75 0 0 1 0-1.5h12.25a.75.75 0 0 1 0 1.5Z"
    />
    <path
      fill="currentColor"
      d="M8 15a.75.75 0 0 1-.703-.49c-1.131-3.061-3.629-3.756-3.735-3.784a.753.753 0 0 1-.56-.73.749.749 0 0 1 .563-.723c.103-.027 2.601-.722 3.731-3.783a.75.75 0 1 1 1.406.519c-.782 2.12-2.12 3.328-3.181 3.99 1.061.662 2.398 1.87 3.181 3.99a.749.749 0 0 1-.703 1.009L8 15Z"
    />
  </svg>
);
export default SvgArrowLeft;
