import type { SVGProps } from "react";
import * as React from "react";
const SvgClockRotate = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m3.572 8.436-.191.595 1.19.382.19-.595-1.19-.382Zm1.915 5.206-.358-.512-1.025.715.358.513 1.025-.716Zm2.471.106h.625v-1.25h-.625v1.25Zm-3.5-.625v-.625h-.625v.625h.625Zm-.625 3.5v.625h1.25v-.625h-1.25Zm6.792-9.332v-.625h-1.25v.625h1.25ZM10 10.499h-.625c0 .18.078.352.214.47L10 10.5Zm1.922 2.512.47.412.824-.94-.47-.412-.824.94ZM10 17.248a6.75 6.75 0 0 0 6.75-6.75H15.5a5.5 5.5 0 0 1-5.5 5.5v1.25Zm6.75-6.75A6.75 6.75 0 0 0 10 3.748v1.25a5.5 5.5 0 0 1 5.5 5.5h1.25ZM10 3.748a6.752 6.752 0 0 0-6.428 4.688l1.19.382A5.502 5.502 0 0 1 10 4.998v-1.25Zm-5.538 10.61A6.744 6.744 0 0 0 10 17.248v-1.25a5.494 5.494 0 0 1-4.513-2.356l-1.025.716Zm3.496-1.86h-3.5v1.25h3.5v-1.25Zm-4.125.625v3.5h1.25v-3.5h-1.25Zm5.542-5.832v3.208h1.25V7.291h-1.25Zm.214 3.679 2.333 2.041.823-.94-2.333-2.042-.823.94Z"
    />
  </svg>
);
export default SvgClockRotate;
