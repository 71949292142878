/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedImage,
    EnrichedImageFromJSON,
    EnrichedImageFromJSONTyped,
    EnrichedImageToJSON,
} from './EnrichedImage';
import {
    EnrichedSlackMessage,
    EnrichedSlackMessageFromJSON,
    EnrichedSlackMessageFromJSONTyped,
    EnrichedSlackMessageToJSON,
} from './EnrichedSlackMessage';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SlackPin
 */
export interface SlackPin {
    /**
     * any images associated with the pin
     * @type {Array<EnrichedImage>}
     * @memberof SlackPin
     */
    images?: Array<EnrichedImage>;
    /**
     * 
     * @type {EnrichedSlackMessage}
     * @memberof SlackPin
     */
    message: EnrichedSlackMessage;
    /**
     * 
     * @type {User}
     * @memberof SlackPin
     */
    message_user?: User;
    /**
     * 
     * @type {User}
     * @memberof SlackPin
     */
    user?: User;
}

export function SlackPinFromJSON(json: any): SlackPin {
    return SlackPinFromJSONTyped(json, false);
}

export function SlackPinFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackPin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(EnrichedImageFromJSON)),
        'message': EnrichedSlackMessageFromJSON(json['message']),
        'message_user': !exists(json, 'message_user') ? undefined : UserFromJSON(json['message_user']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function SlackPinToJSON(value?: SlackPin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(EnrichedImageToJSON)),
        'message': EnrichedSlackMessageToJSON(value.message),
        'message_user': UserToJSON(value.message_user),
        'user': UserToJSON(value.user),
    };
}

