import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import { DateInputV2 } from "@incident-shared/forms/v2/inputs/DateInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, ModalFooter } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useAPIMutation } from "src/utils/swr";

export const BlockArchivingButton = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.TeardownOrganisation,
  );

  return (
    <>
      <GatedButton
        analyticsTrackingId={null}
        onClick={() => setIsOpen(true)}
        theme={ButtonTheme.Secondary}
        {...disabledProps}
        className="w-fit"
      >
        Block auto-archiving
      </GatedButton>

      {isOpen && (
        <BlockArchivingModal data={data} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

type FormData = {
  dontWarnUntil: Date;
};

const BlockArchivingModal = ({
  data,
  onClose,
}: {
  data: StaffShowOrganisationResponseBody;
  onClose: () => void;
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      dontWarnUntil: data.archive_info?.dont_warn_until,
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    { organisationSlug: data.organisation_slug },
    async (apiClient, formData: FormData) => {
      await apiClient.staffDontWarnUntil({
        organisationSlug: data.organisation_slug,
        dontWarnUntilRequestBody: {
          dont_warn_until: formData.dontWarnUntil,
        },
      });
    },
    { onSuccess: onClose },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      analyticsTrackingId={null}
      title="Block auto-archiving"
      onSubmit={trigger}
      onClose={onClose}
      genericError={genericError}
      saving={isMutating}
      className="text-sm space-y-2"
      footer={<ModalFooter confirmButtonType="submit" />}
    >
      <p>
        {`We usually archive organisations after one year of inactivity, or 60
        days if they never activate (i.e. don't create an incident or
        escalation). If there's an org who we don't want to archive, you can set
        a date here to give them time to re-activate.`}
      </p>
      <DateInputV2
        formMethods={formMethods}
        name="dontWarnUntil"
        label="Don't archive until"
      />
    </Form.Modal>
  );
};
