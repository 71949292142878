import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

type SectionWrapperProps = {
  id?: string;
  onClick?: (e: React.MouseEvent) => void;
  sectionName: string;
  children: React.ReactNode;
  enableExpansion?: boolean;
  headerAccessory?: React.ReactNode;
  className?: string;
  topLevelHeader?: boolean;
};

export const SectionWrapper = ({ onClick, ...props }: SectionWrapperProps) => {
  if (onClick) {
    return (
      <Button
        analyticsTrackingId={null}
        theme={ButtonTheme.Unstyled}
        onClick={(e) => {
          onClick(e);
        }}
        title={`Edit ${props.sectionName}`}
        // We want this styled like a text input you can click
        className="text-left cursor-text w-full"
      >
        <SectionWrapperContent
          {...props}
          className={tcx("hover:bg-surface-secondary", props.className)}
        />
      </Button>
    );
  }

  return <SectionWrapperContent {...props} />;
};

const SectionWrapperContent = ({
  id,
  sectionName,
  children,
  enableExpansion = false,
  headerAccessory,
  topLevelHeader,
  className,
}: SectionWrapperProps) => {
  const [expanded, setExpanded] = useState(true);

  const onHeaderClick = (e: React.MouseEvent) => {
    if (!enableExpansion) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();

    setExpanded(!expanded);
  };

  const isExpanded = expanded || !enableExpansion;

  return (
    <div className="w-full">
      <div
        id={id}
        className={tcx(
          "w-full",
          "gap-4 flex flex-col w-[calc(100%+24px)] -mx-3 p-3 group/section",
          "rounded-lg transition-colors has-[.is-editing]:bg-surface-secondary",
          className,
        )}
      >
        {/* Header */}
        <div className="justify-between flex">
          <div
            className={tcx(
              "flex flex-row gap-2 items-center",
              enableExpansion && "cursor-pointer select-none",
            )}
            onClick={onHeaderClick}
          >
            <div className={topLevelHeader ? "text-base-bold" : "text-sm-bold"}>
              {sectionName}
            </div>
            {enableExpansion && (
              <Icon
                className="hidden group-hover/section:block"
                id={expanded ? IconEnum.ChevronUp : IconEnum.ChevronDown}
                size={IconSize.Medium}
              />
            )}
          </div>
          {headerAccessory}
        </div>
        {/* Children */}
        {isExpanded && children}
      </div>
    </div>
  );
};
