import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { useProductAccess } from "src/hooks/useProductAccess";

import { useOnCallEntityCount } from "./utils";

/**
 * OnCallSidebarRouter exists solely to redirect the user to either
 * the get-started page or the escalations list depending on whether
 * they have any on-call entities.
 *
 * This allows us to isloate its loading state from the rest of the router,
 * ensuring we dont <Loader /> other pages when we revalidate the schedules/escalations lists.
 */
export const OnCallSidebarRouter = () => {
  const { data: count, isLoading, error } = useOnCallEntityCount();

  const { hasOnCall } = useProductAccess();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (count === 0 || !hasOnCall) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  return <OrgAwareNavigate to="/on-call/escalations" replace={true} />;
};
