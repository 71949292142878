import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  Avatar,
  Badge,
  BadgeTheme,
  ContentBox,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  OrgAwareLink,
  SeverityBadge,
  SlackTeamAvatar,
} from "@incident-ui";
import { motion } from "framer-motion";
import _ from "lodash";
import React from "react";
import { createContext, useState } from "react";
import {
  Incident,
  IncidentRoleAssignment,
  IncidentRoleWithoutConditionsRoleTypeEnum,
  IncidentType,
  SlackTeamConfig,
  TextDocument,
} from "src/contexts/ClientContext";
import { useSettings } from "src/hooks/useSettings";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { tcx, TcxValue } from "src/utils/tailwind-classes";
import { useInterval } from "src/utils/utils";
import { usePrevious } from "use-hooks";

import { formatDurationInSecondsShort } from "../../../../utils/datetime";
import { getDurationInSeconds } from "../../../../utils/presenters";
import styles from "./IncidentCard.module.scss";
import { useShouldHighlightStateDiff } from "./useShouldHighlight";

export type IncidentCardContextType = {
  incident: Incident;
  previousIncidentState: Incident;
  isGridFirstUpdate: boolean;
  showNewness: boolean;
};

export const IncidentCardContext =
  createContext<IncidentCardContextType | null>(null);

export const IncidentCard = React.memo(
  ({
    incident,
    isGridFirstUpdate,
    slackTeamConfig,
  }: {
    incident: Incident;
    isGridFirstUpdate: boolean;
    slackTeamConfig: SlackTeamConfig | undefined;
  }) => {
    const [showNewness, setShowNewness] = useState(!isGridFirstUpdate);
    const previousIncidentState = usePrevious(incident) ?? incident; // usePrevious will return undefined on first run
    return (
      <motion.div
        key={incident.id}
        initial={{ scale: isGridFirstUpdate ? undefined : 0.95 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        <IncidentCardContext.Provider
          value={{
            incident,
            previousIncidentState,
            isGridFirstUpdate,
            showNewness,
          }}
        >
          <OrgAwareLink
            to={`/incidents/${incident.external_id}`}
            className={"w-full h-full"}
            onMouseOver={() => setShowNewness(false)}
          >
            <IncidentCardContent
              key={JSON.stringify(incident)}
              incident={incident}
              showNewness={showNewness}
              slackTeamConfig={slackTeamConfig}
            />
          </OrgAwareLink>
        </IncidentCardContext.Provider>
      </motion.div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps),
);
IncidentCard.displayName = "IncidentCard";

// IncidentCardContent
// upon mount:
// -> if isGridFirstUpdate = false; then highlight its borders for a few seconds
// -> if isGridFirstUpdate = true; then highlight its borders until user hovers over card
const IncidentCardContent = ({
  incident,
  showNewness,
  slackTeamConfig,
}: {
  incident: Incident;
  showNewness: boolean;
  slackTeamConfig: SlackTeamConfig | undefined;
}) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => !_.isEqual(lhs, rhs),
  );
  const incidentLead = getIncidentLead(incident);
  const { settings } = useSettings();
  const incidentTypesAreEnabled = incidentTypesEnabled(settings);

  const transitionProps = "transition-all duration-250 delay-150";

  return (
    <ContentBox
      className={tcx(
        "h-[240px] group overflow-hidden flex flex-col justify-between",
        styles.cardContainer,
        "hover:shadow-md hover:border-stroke-hover",
        transitionProps,
        {
          ["border-blue-400"]: showNewness,
          [styles.highlight]: highlight,
        },
      )}
    >
      <div className="relative z-0">
        <div
          className={tcx(
            "absolute top-6 w-full px-6 group-hover:opacity-0 group-hover:-top-10",
            transitionProps,
          )}
        >
          <div
            className={tcx(
              "flex gap-2 justify-between truncate",
              transitionProps,
            )}
          >
            <div
              className={tcx(
                "text-xs-med text-content-tertiary font-semibold group-hover:opacity-0 relative overflow-hidden",
                transitionProps,
              )}
            >
              {incident.reference}
            </div>
            <IncidentDuration
              incident={incident}
              naked
              className="!text-content-tertiary"
            />
          </div>
        </div>
        <div
          className={tcx(
            "absolute top-14 overflow-hidden group-hover:top-6",
            "px-6 w-full flex flex-col gap-2",
            transitionProps,
          )}
        >
          <IncidentName key={incident.name} incident={incident} />
          <IncidentSummary
            key={incident.summary?.markdown}
            summary={incident.summary}
          />
        </div>
      </div>
      {/* Badges */}
      <div className="flex flex-col w-full z-10">
        <div className="h-4 bg-gradient-to-t from-white to-transparent" />
        <div
          className={tcx(
            "flex justify-between gap-2 overflow-hidden items-end px-6 pb-6 pt-4 bg-white",
            transitionProps,
          )}
        >
          <div
            className={tcx(
              "flex flex-wrap gap-2 items-center overflow-hidden",
              transitionProps,
            )}
          >
            <SeverityBadge severity={incident.severity} />
            {slackTeamConfig && (
              <SlackTeamAvatar
                size={IconSize.Medium}
                url={slackTeamConfig.icon_url}
                name={slackTeamConfig.name}
              />
            )}
            <IncidentStatusBadge
              key={incident.incident_status.name}
              status={incident.incident_status}
            />
            {incidentTypesAreEnabled && (
              <IncidentTypeComponent
                key={incident.incident_type?.name}
                incidentType={incident.incident_type}
              />
            )}
          </div>
          <IncidentLead key={incidentLead?.assignee?.id} lead={incidentLead} />
        </div>
      </div>
    </ContentBox>
  );
};

export const IconWithLabel = ({
  label,
  icon,
  className,
  overflowHidden = true,
  naked = false,
}: {
  label: string;
  icon: IconEnum;
  className?: TcxValue;
  overflowHidden?: boolean;
  naked?: boolean;
}) => (
  <Badge
    theme={naked ? BadgeTheme.Naked : BadgeTheme.Secondary}
    icon={icon}
    className={tcx(
      "cursor-pointer shrink",
      {
        "overflow-hidden": overflowHidden,
      },
      className,
    )}
  >
    <span className="truncate">{label}</span>
  </Badge>
);

export const IncidentDuration = ({
  incident,
  naked = false,
  className,
}: {
  incident: Incident;
  naked?: boolean;
  className?: TcxValue;
}) => {
  const calculateDuration = () => getDurationInSeconds(incident);
  const [duration, setDuration] = useState(calculateDuration);
  useInterval(() => setDuration(calculateDuration), 1 * 1000);
  if (!duration) return null;

  return (
    <IconWithLabel
      label={formatDurationInSecondsShort(duration)}
      icon={IconEnum.Clock}
      overflowHidden={false}
      naked={naked}
      className={className}
    />
  );
};

export const getIncidentLead = ({ incident_role_assignments }: Incident) =>
  incident_role_assignments.find(
    ({ role }) =>
      role.role_type === IncidentRoleWithoutConditionsRoleTypeEnum.Lead,
  );

const IncidentLead = ({
  lead: incidentLead,
}: {
  lead: IncidentRoleAssignment | undefined;
}) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) =>
      getIncidentLead(lhs)?.assignee?.id !== getIncidentLead(rhs)?.assignee?.id,
  );

  return (
    <Avatar
      size={IconSize.Large}
      url={incidentLead?.assignee?.avatar_url}
      name={incidentLead?.assignee?.name}
      className={tcx("border border-transparent", {
        [styles.highlight]: highlight,
      })}
    />
  );
};

const IncidentSummary = ({
  summary,
}: {
  summary: TextDocument | undefined;
}) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => lhs.summary?.markdown !== rhs.summary?.markdown,
  );

  const highlightBackgroundCX = { [styles.highlightBackground]: highlight };
  return (
    <div className="grow text-xs truncate">
      {summary ? (
        <TemplatedTextDisplay
          className={tcx(
            "text-content-secondary leading-snug",
            highlightBackgroundCX,
          )}
          value={summary.text_node}
          style={TemplatedTextDisplayStyle.Compact}
          truncatedText
        />
      ) : (
        <div className="text-content-tertiary">
          <span className={tcx(highlightBackgroundCX)}>
            No summary provided
          </span>
        </div>
      )}
    </div>
  );
};

const IncidentTypeComponent = ({
  incidentType,
}: {
  incidentType: IncidentType | undefined;
}) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => lhs.incident_type?.id !== rhs.incident_type?.id,
  );
  const highlightBackgroundCX = { [styles.highlightBackground]: highlight };
  return incidentType ? (
    <IconWithLabel
      label={incidentType.name}
      icon={IconEnum.IncidentType}
      className={highlightBackgroundCX}
    />
  ) : null;
};

const IncidentName = ({ incident }: { incident: Incident }) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => lhs.name !== rhs.name,
  );
  const highlightBackgroundCX = { [styles.highlightBackground]: highlight };
  return (
    <>
      <span
        className={tcx(
          "text-base font-semibold line-clamp-2",
          highlightBackgroundCX,
        )}
      >
        {incident.name}
      </span>
    </>
  );
};
