/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectConfig,
    SelectConfigFromJSON,
    SelectConfigFromJSONTyped,
    SelectConfigToJSON,
} from './SelectConfig';

/**
 * 
 * @export
 * @interface FormFieldOperator
 */
export interface FormFieldOperator {
    /**
     * What type of form field should be rendered when this operator is selected
     * @type {string}
     * @memberof FormFieldOperator
     */
    field_type: FormFieldOperatorFieldTypeEnum;
    /**
     * Unique identifier for the operator
     * @type {string}
     * @memberof FormFieldOperator
     */
    key: string;
    /**
     * Human readable label for the operator
     * @type {string}
     * @memberof FormFieldOperator
     */
    label: string;
    /**
     * 
     * @type {SelectConfig}
     * @memberof FormFieldOperator
     */
    select_config?: SelectConfig;
}

/**
* @export
* @enum {string}
*/
export enum FormFieldOperatorFieldTypeEnum {
    None = 'none',
    TextInput = 'text_input',
    NumberInput = 'number_input',
    TextAreaInput = 'text_area_input',
    DateInput = 'date_input',
    DateRangeInput = 'date_range_input',
    SingleStaticSelect = 'single_static_select',
    BooleanInput = 'boolean_input',
    SingleExternalSelect = 'single_external_select',
    SingleExternalUserSelect = 'single_external_user_select',
    MultiStaticSelect = 'multi_static_select',
    MultiExternalSelect = 'multi_external_select',
    MultiExternalUserSelect = 'multi_external_user_select'
}

export function FormFieldOperatorFromJSON(json: any): FormFieldOperator {
    return FormFieldOperatorFromJSONTyped(json, false);
}

export function FormFieldOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormFieldOperator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_type': json['field_type'],
        'key': json['key'],
        'label': json['label'],
        'select_config': !exists(json, 'select_config') ? undefined : SelectConfigFromJSON(json['select_config']),
    };
}

export function FormFieldOperatorToJSON(value?: FormFieldOperator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_type': value.field_type,
        'key': value.key,
        'label': value.label,
        'select_config': SelectConfigToJSON(value.select_config),
    };
}

