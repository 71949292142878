/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EngineEvaluateJavascriptRequestBody
 */
export interface EngineEvaluateJavascriptRequestBody {
    /**
     * Javascript source
     * @type {string}
     * @memberof EngineEvaluateJavascriptRequestBody
     */
    source: string;
    /**
     * Subject of the Javascript program, provided as $, that is intended to be transformed
     * @type {string}
     * @memberof EngineEvaluateJavascriptRequestBody
     */
    subject: string;
}

export function EngineEvaluateJavascriptRequestBodyFromJSON(json: any): EngineEvaluateJavascriptRequestBody {
    return EngineEvaluateJavascriptRequestBodyFromJSONTyped(json, false);
}

export function EngineEvaluateJavascriptRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineEvaluateJavascriptRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': json['source'],
        'subject': json['subject'],
    };
}

export function EngineEvaluateJavascriptRequestBodyToJSON(value?: EngineEvaluateJavascriptRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'subject': value.subject,
    };
}

