/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Action,
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * 
 * @export
 * @interface ActionsListResponseBody
 */
export interface ActionsListResponseBody {
    /**
     * 
     * @type {Array<Action>}
     * @memberof ActionsListResponseBody
     */
    actions: Array<Action>;
}

export function ActionsListResponseBodyFromJSON(json: any): ActionsListResponseBody {
    return ActionsListResponseBodyFromJSONTyped(json, false);
}

export function ActionsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': ((json['actions'] as Array<any>).map(ActionFromJSON)),
    };
}

export function ActionsListResponseBodyToJSON(value?: ActionsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': ((value.actions as Array<any>).map(ActionToJSON)),
    };
}

