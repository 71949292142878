/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimDashboard,
    SlimDashboardFromJSON,
    SlimDashboardFromJSONTyped,
    SlimDashboardToJSON,
} from './SlimDashboard';

/**
 * 
 * @export
 * @interface InsightsListCustomDashboardsResponseBody
 */
export interface InsightsListCustomDashboardsResponseBody {
    /**
     * 
     * @type {Array<SlimDashboard>}
     * @memberof InsightsListCustomDashboardsResponseBody
     */
    dashboards: Array<SlimDashboard>;
}

export function InsightsListCustomDashboardsResponseBodyFromJSON(json: any): InsightsListCustomDashboardsResponseBody {
    return InsightsListCustomDashboardsResponseBodyFromJSONTyped(json, false);
}

export function InsightsListCustomDashboardsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsListCustomDashboardsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboards': ((json['dashboards'] as Array<any>).map(SlimDashboardFromJSON)),
    };
}

export function InsightsListCustomDashboardsResponseBodyToJSON(value?: InsightsListCustomDashboardsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboards': ((value.dashboards as Array<any>).map(SlimDashboardToJSON)),
    };
}

