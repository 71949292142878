/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulesCreateCoverRequestRequestBody
 */
export interface SchedulesCreateCoverRequestRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SchedulesCreateCoverRequestRequestBody
     */
    end_at: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesCreateCoverRequestRequestBody
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesCreateCoverRequestRequestBody
     */
    requester_id: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesCreateCoverRequestRequestBody
     */
    schedule_id: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulesCreateCoverRequestRequestBody
     */
    start_at: string;
}

export function SchedulesCreateCoverRequestRequestBodyFromJSON(json: any): SchedulesCreateCoverRequestRequestBody {
    return SchedulesCreateCoverRequestRequestBodyFromJSONTyped(json, false);
}

export function SchedulesCreateCoverRequestRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateCoverRequestRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': json['end_at'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'requester_id': json['requester_id'],
        'schedule_id': json['schedule_id'],
        'start_at': json['start_at'],
    };
}

export function SchedulesCreateCoverRequestRequestBodyToJSON(value?: SchedulesCreateCoverRequestRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': value.end_at,
        'message': value.message,
        'requester_id': value.requester_id,
        'schedule_id': value.schedule_id,
        'start_at': value.start_at,
    };
}

