import {
  CatalogListTypesResponseBody,
  EscalationPathNode,
  EscalationPathNodeLevel,
  EscalationPathNodeTypeEnum as NodeTypes,
} from "@incident-io/api";
import { getCatalogTypeaheadOptions } from "@incident-shared/catalog";
import { SelectOptionGroup, SelectOptions } from "@incident-ui/Select/types";
import _ from "lodash";
import { ClientType } from "src/contexts/ClientContext";

import {
  buildTargets,
  buildUserAndScheduleOptions,
  groupOptions,
} from "./options";

// getEscalationPathLevelNodes recursively walks through an escalation path
// and returns all level nodes in a single array.
//
// DEPRECATED: Don't use this outside of the Smart Escalation Path project
// because we're going to be removing it soon.
export const getEscalationPathLevelNodes = (
  path: EscalationPathNode[],
): EscalationPathNodeLevel[] => {
  // We're going to recursively populate this object with all level nodes.
  let levels: EscalationPathNodeLevel[] = [];

  // Iterate through every node in the path and add all level nodes.
  path.forEach((node) => {
    switch (node.type) {
      case NodeTypes.Level:
        if (!node.level) {
          throw new Error(
            "Unreachable: a level node always needs a level property",
          );
        }
        levels.push(node.level);
        break;
      case NodeTypes.Repeat:
        if (!node.repeat) {
          throw new Error(
            "Unreachable: a repeat node always needs a repeat property",
          );
        }
        break;
      case NodeTypes.IfElse:
        if (!node.if_else) {
          throw new Error(
            "Unreachable: a condition node always needs an if_else property",
          );
        }

        const thenLevels = getEscalationPathLevelNodes(node.if_else.then_path);
        const elseLevels = getEscalationPathLevelNodes(node.if_else.else_path);

        levels = _.concat(levels, _.concat(thenLevels, elseLevels));
        break;
    }
  });

  return levels;
};

export const loadOptionsUsersAndSchedules = (
  apiClient: ClientType,
  catalogTypes: CatalogListTypesResponseBody,
) => {
  const scheduleCatalogType = catalogTypes.catalog_types.find(
    (x) => x.type_name === "Schedule",
  );

  const getScheduleOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: scheduleCatalogType?.id || "",
  });

  return async (inputValue: string): Promise<SelectOptions> => {
    const [{ options: optionsUsers }, optionsSchedules] = await Promise.all([
      apiClient.usersTypeahead({ query: inputValue }),
      getScheduleOptions(inputValue),
    ]);

    return groupOptions(
      buildUserAndScheduleOptions(optionsUsers, optionsSchedules),
    );
  };
};

export const loadTargets = (
  apiClient: ClientType,
  catalogTypes: CatalogListTypesResponseBody,
) => {
  const scheduleCatalogType = catalogTypes.catalog_types.find(
    (x) => x.type_name === "Schedule",
  );

  const getScheduleOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: scheduleCatalogType?.id || "",
  });

  return async (inputValue: string): Promise<SelectOptionGroup[]> => {
    const [{ options: optionsUsers }, optionsSchedules] = await Promise.all([
      apiClient.usersTypeahead({ query: inputValue }),
      getScheduleOptions(inputValue),
    ]);

    return groupOptions(buildTargets(optionsUsers, optionsSchedules));
  };
};
