import type { SVGProps } from "react";
import * as React from "react";
const SvgTag = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16.366 9.556-5.75-5.75A2.73 2.73 0 0 0 8.672 3H3.75C2.785 3 2 3.785 2 4.75v4.921c0 .735.286 1.425.806 1.945l5.75 5.75a2.74 2.74 0 0 0 1.944.804 2.74 2.74 0 0 0 1.944-.804l3.922-3.922c.52-.519.806-1.209.806-1.944 0-.735-.286-1.425-.806-1.944ZM6.75 9C6.061 9 5.5 8.439 5.5 7.75S6.061 6.5 6.75 6.5 8 7.061 8 7.75 7.439 9 6.75 9Z"
    />
  </svg>
);
export default SvgTag;
