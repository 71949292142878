import { TriggerGroupLabelEnum, TriggerSlim } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  GenericErrorMessage,
  IconBadge,
  IconEnum,
  IconSize,
  Loader,
  SearchBar,
} from "@incident-ui";
import { DrawerTitle } from "@incident-ui/Drawer/Drawer";
import { captureException } from "@sentry/react";
import { Searcher } from "fast-fuzzy";
import _ from "lodash";
import { useState } from "react";
import { useAPI } from "src/utils/swr";

import { toSentenceCase } from "../../../../utils/formatting";
import { WorkflowCardButton } from "../common/WorkflowCardButton";
import { useWorkflowsTrigger } from "../create-edit-form/hooks/useTriggerController";

// We want to render the triggers in this order. If we add a trigger and don't
// add it here, that's fine, it'll just go at the end.
const triggersInOrder = [
  "incident.updated",
  "incident-channel.user-joined",
  "incident.update_shared",
  "incident.status-changed",
  "incident.severity-changed",
  "incident.role-assignment-changed",
  "incident.custom-field-value-changed",
  "slack.message_posted",
  "action.updated",
  "follow-up.updated",
  "escalation.acked",
];

type WorkflowTriggerCardProps = {
  trigger: TriggerSlim;
  icon: IconEnum;
  colorPalette: ColorPaletteEnum;
  onClick: () => void;
};

const WorkflowTriggerCard = ({
  trigger,
  icon,
  colorPalette,
  onClick,
}: WorkflowTriggerCardProps): React.ReactElement => {
  return (
    <WorkflowCardButton
      analyticsTrackingId={`workflowv2-choose-trigger-${trigger.label}`}
      onClick={onClick}
    >
      <IconBadge
        icon={icon}
        color={colorPalette}
        size={IconSize.Large}
        className="mr-2"
      />
      <p className="font-normal font-medium text-content-primary">
        {toSentenceCase(trigger.label)}
      </p>
    </WorkflowCardButton>
  );
};

export const WorkflowChooseTriggerDrawer = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const { onChooseTrigger } = useWorkflowsTrigger();
  const setTrigger = (trigger: TriggerSlim) => {
    onChooseTrigger(trigger.name);
  };

  return (
    <WorkflowChooseTriggerForm onChooseTrigger={setTrigger} onClose={onClose} />
  );
};

export const WorkflowChooseTriggerForm = ({
  onChooseTrigger,
  onClose,
}: {
  onChooseTrigger: (t: TriggerSlim) => void;
  onClose: () => void;
}): React.ReactElement => {
  // List all triggers so that we can choose one to create a workflow from.
  const {
    data: { triggers },
    isLoading,
    error,
  } = useAPI("workflowsListTriggers", undefined, {
    fallbackData: { triggers: [] },
  });

  const [searchFilter, setSearchFilter] = useState("");

  let filteredTriggers = triggers;
  if (searchFilter !== "") {
    const searcher = new Searcher(triggers, {
      keySelector: (trigger) => trigger.label,
    });
    filteredTriggers = searcher.search(searchFilter);
  }

  const orderedTriggers = _.sortBy(filteredTriggers, (t) => {
    const idx = triggersInOrder.findIndex((x) => x === t.name);
    return idx === -1 ? 10000 : idx;
  });

  const groupedTriggers = _.groupBy(orderedTriggers, (t) => {
    return t.group_label;
  });

  if (isLoading) {
    return (
      <div className="p-6">
        <Loader />
      </div>
    );
  }

  if (error) {
    captureException(error);
    return <GenericErrorMessage error={error} />;
  }

  return (
    <>
      <DrawerTitle
        title="Choose trigger"
        onClose={onClose}
        compact
        icon={IconEnum.Bolt}
      />
      <div className="p-6 flex flex-col gap-6">
        <SearchBar
          id="search_triggers"
          placeholder={"Search triggers"}
          onChange={setSearchFilter}
          value={searchFilter}
          iconName={IconEnum.Search}
          className={"bg-white w-full"}
          autoFocus={true}
        />
        {Object.entries(groupedTriggers).map(([group, triggersInGroup]) => (
          <div key={group} className="flex flex-col gap-2">
            <span className="text-xs font-semibold text-content-tertiary uppercase tracking-widest">
              {group}
            </span>
            <div className="grid grid-cols-2 gap-[10px]" key={group}>
              {triggersInGroup.map((t, i) => {
                return (
                  <WorkflowTriggerCard
                    key={i}
                    trigger={t}
                    onClick={() => onChooseTrigger(t)}
                    icon={t.icon as unknown as IconEnum}
                    colorPalette={groupToColorPalette(group)}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const groupToColorPalette = (group: string): ColorPaletteEnum => {
  switch (group) {
    case TriggerGroupLabelEnum.General:
      return ColorPaletteEnum.Red;
    case TriggerGroupLabelEnum.UserActions:
      return ColorPaletteEnum.Blue;
    case TriggerGroupLabelEnum.SpecificChanges:
      return ColorPaletteEnum.Red;
    case TriggerGroupLabelEnum.Other:
      return ColorPaletteEnum.Blue;
    default:
      return ColorPaletteEnum.Blue;
  }
};
