/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentRelationshipsCreateRequestBody,
    IncidentRelationshipsCreateRequestBodyFromJSON,
    IncidentRelationshipsCreateRequestBodyToJSON,
    IncidentRelationshipsCreateResponseBody,
    IncidentRelationshipsCreateResponseBodyFromJSON,
    IncidentRelationshipsCreateResponseBodyToJSON,
    IncidentRelationshipsListResponseBody,
    IncidentRelationshipsListResponseBodyFromJSON,
    IncidentRelationshipsListResponseBodyToJSON,
} from '../models';

export interface IncidentRelationshipsCreateRequest {
    createRequestBody: IncidentRelationshipsCreateRequestBody;
}

export interface IncidentRelationshipsDestroyRequest {
    id: string;
}

export interface IncidentRelationshipsListRequest {
    incidentId: string;
}

/**
 * 
 */
export class IncidentRelationshipsApi extends runtime.BaseAPI {

    /**
     * Create an incident relationship
     * Create IncidentRelationships
     */
    async incidentRelationshipsCreateRaw(requestParameters: IncidentRelationshipsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentRelationshipsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentRelationshipsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_relationships`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRelationshipsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRelationshipsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an incident relationship
     * Create IncidentRelationships
     */
    async incidentRelationshipsCreate(requestParameters: IncidentRelationshipsCreateRequest, initOverrides?: RequestInit): Promise<IncidentRelationshipsCreateResponseBody> {
        const response = await this.incidentRelationshipsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an incident relationship
     * Destroy IncidentRelationships
     */
    async incidentRelationshipsDestroyRaw(requestParameters: IncidentRelationshipsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentRelationshipsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_relationships/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an incident relationship
     * Destroy IncidentRelationships
     */
    async incidentRelationshipsDestroy(requestParameters: IncidentRelationshipsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentRelationshipsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List incident relationships
     * List IncidentRelationships
     */
    async incidentRelationshipsListRaw(requestParameters: IncidentRelationshipsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentRelationshipsListResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentRelationshipsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_relationships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRelationshipsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident relationships
     * List IncidentRelationships
     */
    async incidentRelationshipsList(requestParameters: IncidentRelationshipsListRequest, initOverrides?: RequestInit): Promise<IncidentRelationshipsListResponseBody> {
        const response = await this.incidentRelationshipsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
