import {
  ConditionBadge,
  conditionGroupsToConditions,
} from "@incident-shared/engine/conditions";
import { SelectedCondition } from "@incident-shared/engine/conditions/SelectedCondition";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import { Icon, IconEnum } from "@incident-ui";
import { CustomField, EngineScope } from "src/contexts/ClientContext";

export const CustomFieldDetails = ({
  field,
  scope,
}: {
  field: CustomField;
  scope: EngineScope;
}) => {
  const conditions = conditionGroupsToConditions(field.condition_groups);
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-1 items-center">
        <div>
          <Icon id={IconEnum.Filter} className="!w-6 !h-6 text-slate-400" />
        </div>
        {conditions.length > 0 ? (
          <div className="flex">
            {conditions.map((condition, i) => (
              <SelectedCondition
                key={i}
                condition={condition}
                className="mb-0"
                theme="white"
              />
            ))}
          </div>
        ) : (
          <div className="flex">
            <ConditionBadge
              subject="This custom field will be available for"
              operation="all incidents"
              criteria={null}
              theme="white"
            />
          </div>
        )}
      </div>
      {field.engine_expression && (
        <div className="flex flex-row gap-1 items-center">
          <div className="flex">
            <Icon
              id={IconEnum.Expression}
              className="!w-6 !h-6 text-slate-400"
            />
          </div>
          <div className="flex">
            <ViewExpression
              expression={field.engine_expression}
              scope={scope}
              hideTitleBar
            />
          </div>
        </div>
      )}
    </div>
  );
};
