import {
  Alert,
  AlertSchema,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  AlertStatusEnum,
  CatalogResource,
  ErrorResponse,
  GroupedAlerts,
} from "@incident-io/api";
import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import { AttributeEntries } from "@incident-shared/attribute";
import { isEmptyBinding } from "@incident-shared/engine";
import {
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  OrgAwareLink,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";
import { isPrimitive } from "util";

import { AlertOverviewColumn } from "../common/AlertOverviewColumn";
import { AlertPriorityBadge } from "../common/AlertPriorityBadge";
import { AlertSourceTypeIcon } from "../common/AlertSourceTypeConfigs";
import {
  AlertsEmptyState,
  AlertTableColumn,
  COLUMN_MAX_WIDTH,
  makeColumns,
} from "../common/AlertsTable";

export const GroupedAlertsTable = ({
  schema,
  resources,
  alertSourceConfigs,
  groupingKeys,
  groupedAlerts,
  ungroupedAlerts,
  error,
  className,
}: {
  className?: string;
  schema: AlertSchema;
  resources: CatalogResource[];
  alertSourceConfigs: AlertSourceConfig[];
  groupingKeys: string[];
  groupedAlerts: GroupedAlerts[];
  ungroupedAlerts: Alert[];
  error?: ErrorResponse;
}) => {
  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (groupedAlerts.length === 0 && ungroupedAlerts.length === 0) {
    return <AlertsEmptyState />;
  }
  const columns = makeColumns(schema);

  const calculateColumnName = (col: AlertTableColumn) => {
    if (col.type === "attribute") {
      return col.attribute.name;
    } else if (col.type === "priority") {
      return "Priority";
    } else {
      return "Alert Source";
    }
  };

  const showGroupedAlertsCount =
    groupedAlerts.length > 1 || ungroupedAlerts.length > 0 ? 3 : 5;

  const showUngroupedAlertsCount = groupedAlerts.length > 0 ? 3 : 5;

  return (
    <DeprecatedTable className={tcx("w-fit min-w-full", className)}>
      <DeprecatedTableHeaderRow>
        {/*
            Note: if we want to freeze the name column in the future, we can do that by adding
            "sticky left-0" to this and the corresponding td below.
          */}
        <DeprecatedTableHeaderCell className="!w-[32px] !pr-0" textHidden>
          Active
        </DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell className={tcx("text-slate-600 !pl-0")}>
          <span>Alert</span>
        </DeprecatedTableHeaderCell>
        {columns.map((col) => {
          return (
            <DeprecatedTableHeaderCell
              key={`${col.type}-${col.attribute?.id}-header`}
              className={tcx(COLUMN_MAX_WIDTH, "border-stroke text-slate-600")}
            >
              {calculateColumnName(col)}
            </DeprecatedTableHeaderCell>
          );
        })}
      </DeprecatedTableHeaderRow>
      <tbody>
        {groupedAlerts.map((group, idx) => {
          return (
            <>
              <tr
                key={idx}
                className={"bg-surface-secondary border-b border-stroke"}
              >
                <td align={"center"}>
                  <Icon
                    id={IconEnum.FolderNoPadding}
                    size={IconSize.Medium}
                    className={
                      "max-h-[24px] max-w-[24px] text-content-tertiary"
                    }
                  />
                </td>
                {/* This is quite strange!
                 *
                 * Grouping is complex and done in the backend, but we don't then
                 * provide what we have grouped on back in the API response. This
                 * forces us to do some calculation to rebuild the grouping
                 * values just into catalog entries.
                 *
                 * If we have to add anything more here, it is time to refactor
                 * and put the grouping values into the API response instead of
                 * adding more cruft here.
                 *
                 * NB: Without forcing pl-0 the td offsets it slightly.
                 */}
                <td className="!p-0 flex-row" colSpan={columns.length + 1}>
                  {groupingKeys.includes("alert.priority") &&
                  group.alerts[0]?.priority ? (
                    <td key={"priority"} className={"!py-0 !pl-0"}>
                      <AlertPriorityBadge priority={group.alerts[0].priority} />
                    </td>
                  ) : null}
                  {Object.entries(group.alerts[0].attribute_values).map(
                    ([id, binding]) => {
                      if (
                        !groupingKeys.includes("alert.attributes." + id) ||
                        isEmptyBinding(binding)
                      ) {
                        return null;
                      }
                      const attr = schema.attributes.find((x) => x.id === id);
                      if (!attr) {
                        return null;
                      }
                      const isRef = !isPrimitive(attr.type);
                      return (
                        <td
                          key={attr.id}
                          className={isRef ? "!py-0 !pl-0" : "!pl-0"}
                        >
                          <AttributeEntries
                            mode={"engine"}
                            typeName={attr.type}
                            catalogResources={resources}
                            className={COLUMN_MAX_WIDTH}
                            attributeBinding={binding}
                            truncate
                          />
                        </td>
                      );
                    },
                  )}
                </td>
              </tr>
              {/*  Only show 3 alerts from each group */}
              {group.alerts.slice(0, showGroupedAlertsCount).map((alert) => {
                return (
                  <AlertsTableRow
                    key={alert.id}
                    alert={alert}
                    alertSourceConfigs={alertSourceConfigs}
                    resources={resources}
                    columns={columns}
                  />
                );
              })}
            </>
          );
        })}
        {ungroupedAlerts.length > 0 && (
          <tr className={"bg-surface-secondary border-b border-stroke"}>
            <td align={"center"}>
              <Icon
                id={IconEnum.UnorderedList}
                size={IconSize.Medium}
                className={"max-h-[24px] max-w-[24px] text-content-tertiary"}
              />
            </td>
            {/*Without forcing pl-0 the td offsets it slightly */}
            <td className="!pl-0" colSpan={columns.length + 1}>
              <div className="text-sm-med leading-tight text-content-tertiary">
                Ungrouped alerts
              </div>
            </td>
          </tr>
        )}
        {ungroupedAlerts
          // If we've got grouped alerts, just show 2 ungrouped to save space
          // If we don't have any, show a few more
          .slice(0, showUngroupedAlertsCount)
          .map((alert) => {
            return (
              <AlertsTableRow
                key={alert.id}
                alert={alert}
                alertSourceConfigs={alertSourceConfigs}
                resources={resources}
                columns={columns}
              />
            );
          })}
      </tbody>
    </DeprecatedTable>
  );
};

const AlertsTableRow = ({
  alert,
  alertSourceConfigs,
  resources,
  columns,
}: {
  alert: Alert;
  alertSourceConfigs: AlertSourceConfig[];
  resources: CatalogResource[];
  columns: AlertTableColumn[];
}) => {
  return (
    <tr key={alert.id}>
      <td className={tcx("!px-6 md:!pl-6")}>
        <AlertFiringIndicator
          firing={alert.status === AlertStatusEnum.Firing}
        />
      </td>

      <td className="!pl-0">
        <AlertOverviewColumn alert={alert} />
      </td>
      {columns.map((col) => {
        if (col.type === "attribute") {
          const attr = col.attribute;
          const binding = alert.attribute_values[attr.id];
          // If it isn't a primitive, it must be a reference to another entry
          const isRef = !isPrimitive(attr.type);
          return (
            <td key={attr.id} className={isRef ? "!py-0" : ""}>
              <AttributeEntries
                mode={"engine"}
                typeName={attr.type}
                catalogResources={resources}
                className={COLUMN_MAX_WIDTH}
                attributeBinding={binding}
                truncate
              />
            </td>
          );
        } else if (col.type === "priority" && alert.priority) {
          return (
            <td key="priority" className="!py-0">
              <AlertPriorityBadge priority={alert.priority} />
            </td>
          );
        } else {
          const source = alertSourceConfigs.find(
            (s) => s.id === alert.alert_source_config_id,
          );
          return (
            <td
              key={alert.alert_source_config_id}
              className={tcx(
                COLUMN_MAX_WIDTH,
                "text-slate-700 text-sm whitespace-nowrap",
              )}
            >
              {source?.name ? (
                <OrgAwareLink
                  to={`/alerts/sources/${alert.alert_source_config_id}/edit`}
                  className={
                    "flex hover:text-content-primary hover:underline transition text-ellipsis"
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  <AlertSourceTypeIcon
                    sourceType={
                      alert.source_type as unknown as AlertSourceSourceTypeEnum
                    }
                  />
                  <div className="text-sm-med ml-1">{source.name}</div>
                </OrgAwareLink>
              ) : (
                `Archived config (${alert.alert_source_config_id})`
              )}
            </td>
          );
        }
      })}
    </tr>
  );
};
