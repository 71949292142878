import {
  ConfigureDrawerProps,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import {
  Callout,
  CalloutTheme,
  ContentBox,
  GenericErrorMessage,
  Loader,
  StaticSingleSelect,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { Form } from "src/components/@shared/forms";
import {
  IntegrationSettingsProviderEnum,
  IntegrationSettingsReconnectionReasonEnum,
  IntegrationsSalesforceUpdateAccountViewRequestBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { IntegrationConnectedUser } from "../../common/IntegrationConnectedUser";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const SalesforceConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const {
    data: config,
    isLoading: savedAccountViewLoading,
    error,
  } = useAPI("integrationsSalesforceGetConfig", undefined);

  const salesforceConfig = config?.config;

  if (savedAccountViewLoading) {
    return <IntegrationDrawerContentLoader />;
  }
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const syncedAccountViewID = salesforceConfig?.synced_account_list_id;
  const connectedUsername = salesforceConfig?.connected_username;

  return (
    <GenericConfigureDrawerContents {...props}>
      <SelectAccountList
        syncedAccountViewID={syncedAccountViewID}
        connectedUsername={connectedUsername || "unknown"}
        integration={props.integration}
      />
    </GenericConfigureDrawerContents>
  );
};

const SelectAccountList = ({
  syncedAccountViewID,
  connectedUsername,
  integration,
}: {
  syncedAccountViewID?: string;
  connectedUsername: string;
  integration: IntegrationsListObject;
}): React.ReactElement | null => {
  // We want to make sure we don't show the form input until we actually have
  // the data we need to populate it.
  const { data: accountViews, isLoading: accountViewsLoading } = useAPI(
    "integrationsSalesforceGetAccountListViews",
    undefined,
  );

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  // TODO RESP-2134 The synced account list ID does not update immediately after saving a configuration.
  const { trigger: onSubmit } = useAPIMutation(
    "integrationsSalesforceGetConfig",
    undefined,
    async (
      apiClient,
      data: IntegrationsSalesforceUpdateAccountViewRequestBody,
    ) => {
      await apiClient.integrationsSalesforceUpdateAccountView({
        updateAccountViewRequestBody: { id: data.id },
      });
      return;
    },
    {
      onSuccess: () => {
        showToast({
          title: "Salesforce configuration updated.",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast({
          title: "Failed to update Salesforce configuration.",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const {
    setState,
    hasSaved,
    saving,
    state: accountViewID,
  } = useOptimisticAutoSave<string | undefined>({
    initialState: syncedAccountViewID,
    saveState: async (accountViewID: string | undefined) => {
      await onSubmit({ id: accountViewID || "" });
    },
  });

  const showToast = useToast();

  const options =
    accountViews?.account_views.map((x) => {
      return { label: x.label, value: x.id };
    }) || [];

  return (
    <>
      {integration.reconnection_reason ===
      IntegrationSettingsReconnectionReasonEnum.IncompleteConfig ? (
        <Callout
          theme={CalloutTheme.Warning}
          title="Choose an account list"
          subtitle={`To start syncing data to your Catalog, you must first choose an account list from the list below.`}
        />
      ) : (
        <IntegrationConnectedUser
          name={connectedUsername}
          provider={IntegrationSettingsProviderEnum.Salesforce}
        />
      )}
      <ContentBox className="p-4 flex flex-col gap-1">
        <Form.Label
          htmlFor="account_view_id"
          className="flex items-center gap-2"
        >
          Choose an account list to sync to your Catalog:
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </Form.Label>

        {accountViewsLoading ? (
          <>
            <Loader className="flex-none w-14 h-14" />
            <p className="text-sm flex-1">
              Please hold while we retrieve your account lists from Salesforce.
              For large organisations this may take some time.
            </p>
          </>
        ) : (
          <StaticSingleSelect
            value={accountViewID || ""}
            onChange={(value) => setState(value || undefined)}
            id="account_view_id"
            options={options}
            disabled={!canEditSettings}
          />
        )}
      </ContentBox>
    </>
  );
};
