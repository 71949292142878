/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SentryChoice,
    SentryChoiceFromJSON,
    SentryChoiceToJSON,
    SentryLinkResponseBody,
    SentryLinkResponseBodyFromJSON,
    SentryLinkResponseBodyToJSON,
} from '../models';

export interface SentryConfirmAlertRuleRequest {
    sentryAppSignature: string;
}

export interface SentryLinkRequest {
    sentryAppSignature: string;
}

export interface SentryListRequest {
    installationId: string;
    sentryAppSignature: string;
    query?: string;
}

export interface SentryListAlertSourceConfigsRequest {
    installationId: string;
    sentryAppSignature: string;
    query?: string;
}

/**
 * 
 */
export class SentryApi extends runtime.BaseAPI {

    /**
     * ConfirmAlertRule Sentry
     */
    async sentryConfirmAlertRuleRaw(requestParameters: SentryConfirmAlertRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sentryAppSignature === null || requestParameters.sentryAppSignature === undefined) {
            throw new runtime.RequiredError('sentryAppSignature','Required parameter requestParameters.sentryAppSignature was null or undefined when calling sentryConfirmAlertRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.sentryAppSignature !== undefined && requestParameters.sentryAppSignature !== null) {
            headerParameters['Sentry-App-Signature'] = String(requestParameters.sentryAppSignature);
        }

        const response = await this.request({
            path: `/sentry/alert_rule`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ConfirmAlertRule Sentry
     */
    async sentryConfirmAlertRule(requestParameters: SentryConfirmAlertRuleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sentryConfirmAlertRuleRaw(requestParameters, initOverrides);
    }

    /**
     * Called by Sentry when linking an issue
     * Link Sentry
     */
    async sentryLinkRaw(requestParameters: SentryLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SentryLinkResponseBody>> {
        if (requestParameters.sentryAppSignature === null || requestParameters.sentryAppSignature === undefined) {
            throw new runtime.RequiredError('sentryAppSignature','Required parameter requestParameters.sentryAppSignature was null or undefined when calling sentryLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.sentryAppSignature !== undefined && requestParameters.sentryAppSignature !== null) {
            headerParameters['Sentry-App-Signature'] = String(requestParameters.sentryAppSignature);
        }

        const response = await this.request({
            path: `/sentry/issues/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SentryLinkResponseBodyFromJSON(jsonValue));
    }

    /**
     * Called by Sentry when linking an issue
     * Link Sentry
     */
    async sentryLink(requestParameters: SentryLinkRequest, initOverrides?: RequestInit): Promise<SentryLinkResponseBody> {
        const response = await this.sentryLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Used by Sentry\'s typeahead within the create/link issue flow
     * List Sentry
     */
    async sentryListRaw(requestParameters: SentryListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SentryChoice>>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling sentryList.');
        }

        if (requestParameters.sentryAppSignature === null || requestParameters.sentryAppSignature === undefined) {
            throw new runtime.RequiredError('sentryAppSignature','Required parameter requestParameters.sentryAppSignature was null or undefined when calling sentryList.');
        }

        const queryParameters: any = {};

        if (requestParameters.installationId !== undefined) {
            queryParameters['installationId'] = requestParameters.installationId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.sentryAppSignature !== undefined && requestParameters.sentryAppSignature !== null) {
            headerParameters['Sentry-App-Signature'] = String(requestParameters.sentryAppSignature);
        }

        const response = await this.request({
            path: `/sentry/incidents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SentryChoiceFromJSON));
    }

    /**
     * Used by Sentry\'s typeahead within the create/link issue flow
     * List Sentry
     */
    async sentryList(requestParameters: SentryListRequest, initOverrides?: RequestInit): Promise<Array<SentryChoice>> {
        const response = await this.sentryListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Used by Sentry\'s typeahead when configuring alert rules
     * ListAlertSourceConfigs Sentry
     */
    async sentryListAlertSourceConfigsRaw(requestParameters: SentryListAlertSourceConfigsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SentryChoice>>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling sentryListAlertSourceConfigs.');
        }

        if (requestParameters.sentryAppSignature === null || requestParameters.sentryAppSignature === undefined) {
            throw new runtime.RequiredError('sentryAppSignature','Required parameter requestParameters.sentryAppSignature was null or undefined when calling sentryListAlertSourceConfigs.');
        }

        const queryParameters: any = {};

        if (requestParameters.installationId !== undefined) {
            queryParameters['installationId'] = requestParameters.installationId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.sentryAppSignature !== undefined && requestParameters.sentryAppSignature !== null) {
            headerParameters['Sentry-App-Signature'] = String(requestParameters.sentryAppSignature);
        }

        const response = await this.request({
            path: `/sentry/alert_source_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SentryChoiceFromJSON));
    }

    /**
     * Used by Sentry\'s typeahead when configuring alert rules
     * ListAlertSourceConfigs Sentry
     */
    async sentryListAlertSourceConfigs(requestParameters: SentryListAlertSourceConfigsRequest, initOverrides?: RequestInit): Promise<Array<SentryChoice>> {
        const response = await this.sentryListAlertSourceConfigsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
