/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleCoverRequestCandidate,
    ScheduleCoverRequestCandidateFromJSON,
    ScheduleCoverRequestCandidateFromJSONTyped,
    ScheduleCoverRequestCandidateToJSON,
} from './ScheduleCoverRequestCandidate';

/**
 * 
 * @export
 * @interface ScheduleCoverRequest
 */
export interface ScheduleCoverRequest {
    /**
     * Candidates for the cover request
     * @type {Array<ScheduleCoverRequestCandidate>}
     * @memberof ScheduleCoverRequest
     */
    candidates: Array<ScheduleCoverRequestCandidate>;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleCoverRequest
     */
    created_at: Date;
    /**
     * When is this cover request until
     * @type {Date}
     * @memberof ScheduleCoverRequest
     */
    end_at: Date;
    /**
     * Unique ID of the cover request
     * @type {string}
     * @memberof ScheduleCoverRequest
     */
    id: string;
    /**
     * Why is this cover needed?
     * @type {string}
     * @memberof ScheduleCoverRequest
     */
    message?: string;
    /**
     * Which user is requesting cover
     * @type {string}
     * @memberof ScheduleCoverRequest
     */
    requester_id: string;
    /**
     * ID of the schedule this request is for
     * @type {string}
     * @memberof ScheduleCoverRequest
     */
    schedule_id: string;
    /**
     * When is this cover request start from
     * @type {Date}
     * @memberof ScheduleCoverRequest
     */
    start_at: Date;
    /**
     * State of the cover request
     * @type {string}
     * @memberof ScheduleCoverRequest
     */
    state: ScheduleCoverRequestStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleCoverRequest
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleCoverRequestStateEnum {
    Created = 'created',
    Cancelled = 'cancelled',
    Accepted = 'accepted',
    Declined = 'declined'
}

export function ScheduleCoverRequestFromJSON(json: any): ScheduleCoverRequest {
    return ScheduleCoverRequestFromJSONTyped(json, false);
}

export function ScheduleCoverRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleCoverRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidates': ((json['candidates'] as Array<any>).map(ScheduleCoverRequestCandidateFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'end_at': (new Date(json['end_at'])),
        'id': json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'requester_id': json['requester_id'],
        'schedule_id': json['schedule_id'],
        'start_at': (new Date(json['start_at'])),
        'state': json['state'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function ScheduleCoverRequestToJSON(value?: ScheduleCoverRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidates': ((value.candidates as Array<any>).map(ScheduleCoverRequestCandidateToJSON)),
        'created_at': (value.created_at.toISOString()),
        'end_at': (value.end_at.toISOString()),
        'id': value.id,
        'message': value.message,
        'requester_id': value.requester_id,
        'schedule_id': value.schedule_id,
        'start_at': (value.start_at.toISOString()),
        'state': value.state,
        'updated_at': (value.updated_at.toISOString()),
    };
}

