import {
  AlertHeadlineMetric,
  AlertHeadlineMetricTypeEnum,
  AlertsGetInsightsResponseBody,
} from "@incident-io/api";
import { LoadingTiles } from "@incident-shared/insights/LoadingTrendsTiles";
import { Tile, TrendTheme } from "@incident-shared/insights/TrendTile";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui";
import { format, isToday } from "date-fns";

import { formatValueWithUnit } from "./utils";

type MainMetricTypes =
  | AlertHeadlineMetricTypeEnum.NumberIncidents
  | AlertHeadlineMetricTypeEnum.WorkloadTotalIncidents
  | AlertHeadlineMetricTypeEnum.DeclineRate;

const NUM_TILES = 3;

const HEADLINE_METRIC_CARDS: {
  type: MainMetricTypes;
  title: string;
  getTooltip: (groupBy: string, lastSyncAt?: Date) => React.ReactNode;
  getSubtitle: (
    metrics: Record<AlertHeadlineMetricTypeEnum, AlertHeadlineMetric>,
  ) => string;
  getTheme: (
    value: number,
    metrics: Record<AlertHeadlineMetricTypeEnum, AlertHeadlineMetric>,
  ) => TrendTheme;
}[] = [
  {
    type: AlertHeadlineMetricTypeEnum.NumberIncidents,
    title: "Total",
    getTooltip: (groupBy, lastSyncAt) => (
      <div className={"flex flex-col gap-1"}>
        <span>Number of incidents linked to alerts with this {groupBy}</span>
        <LastSyncAt lastSyncAt={lastSyncAt} />
      </div>
    ),
    getSubtitle: (metrics: Record<MainMetricTypes, AlertHeadlineMetric>) => {
      const totalAlerts = metrics[AlertHeadlineMetricTypeEnum.NumberAlerts];
      return `${formatValueWithUnit(totalAlerts.value, totalAlerts.unit)}`;
    },
    getTheme: () => {
      return TrendTheme.Grey;
    },
  },
  {
    type: AlertHeadlineMetricTypeEnum.WorkloadTotalIncidents,
    title: "Workload",
    getTooltip: (groupBy, lastSyncAt) => (
      <div className={"flex flex-col gap-1"}>
        <span>
          Amount of time spent on incidents linked to alerts with this {groupBy}
        </span>
        <LastSyncAt lastSyncAt={lastSyncAt} />
      </div>
    ),
    getSubtitle: (metrics: Record<MainMetricTypes, AlertHeadlineMetric>) => {
      const acceptedWorkload =
        metrics[AlertHeadlineMetricTypeEnum.WorkloadRealIncidents];
      const declinedWorkload =
        metrics[AlertHeadlineMetricTypeEnum.WorkloadDeclinedIncidents];

      return `Accepted: ${formatValueWithUnit(
        acceptedWorkload.value,
        acceptedWorkload.unit,
      )} · Declined: ${formatValueWithUnit(
        declinedWorkload.value,
        declinedWorkload.unit,
      )}`;
    },
    getTheme: (
      value: number,
      metrics: Record<MainMetricTypes, AlertHeadlineMetric>,
    ) => {
      const workloadAccepted =
        metrics[AlertHeadlineMetricTypeEnum.WorkloadRealIncidents];
      const workloadDeclined =
        metrics[AlertHeadlineMetricTypeEnum.WorkloadDeclinedIncidents];
      if (value > 0 && workloadDeclined.value >= workloadAccepted.value) {
        return TrendTheme.Bad;
      }
      return TrendTheme.Grey;
    },
  },
  {
    type: AlertHeadlineMetricTypeEnum.DeclineRate,
    title: "Decline rate",
    getTooltip: (groupBy, lastSyncAt) => (
      <div className={"flex flex-col gap-1"}>
        <span>
          Percentage of incidents linked to alerts with this {groupBy} that were
          declined
        </span>
        <LastSyncAt lastSyncAt={lastSyncAt} />
      </div>
    ),
    getSubtitle: (metrics: Record<MainMetricTypes, AlertHeadlineMetric>) => {
      const declinedIncidents =
        metrics[AlertHeadlineMetricTypeEnum.NumberIncidentsDeclined].value;
      const totalIncidents =
        metrics[AlertHeadlineMetricTypeEnum.NumberIncidents].value;
      if (totalIncidents === 0) {
        return "No incidents";
      }
      return `Declined ${Math.round(declinedIncidents)} of ${Math.round(
        totalIncidents,
      )} incidents`;
    },
    getTheme: (
      value: number,
      _: Record<MainMetricTypes, AlertHeadlineMetric>,
    ) => {
      if (value > 50) {
        return TrendTheme.Bad;
      }
      return TrendTheme.Grey;
    },
  },
];

export const HeadlineMetrics = ({
  data,
  groupBy,
  lastSyncAt,
}: {
  data?: AlertsGetInsightsResponseBody;
  groupBy: {
    label: string;
    value: "title" | "deduplicationKey";
  };
  lastSyncAt?: Date;
}) => {
  const metricsLookup = data?.headline_metrics?.reduce(
    (acc, metric) => {
      acc[metric.type] = metric;
      return acc;
    },
    {} as Record<AlertHeadlineMetricTypeEnum, AlertHeadlineMetric>,
  );

  if (!data || !metricsLookup) {
    return (
      <div className={`gap-4 grid grid-cols-${NUM_TILES}`}>
        <LoadingTiles numTiles={NUM_TILES} overrideTheme={TrendTheme.Grey} />
      </div>
    );
  }

  return (
    <div className={`gap-4 grid grid-cols-${NUM_TILES}`}>
      {HEADLINE_METRIC_CARDS.map((mainMetric, idx) => {
        const metric = metricsLookup[mainMetric.type];
        return (
          <HeadlineMetricCard
            key={idx}
            title={mainMetric.title}
            content={formatValueWithUnit(metric.value, metric.unit)}
            subtitle={mainMetric.getSubtitle(metricsLookup)}
            tooltipContent={mainMetric.getTooltip(
              groupBy.label.toLowerCase(),
              lastSyncAt,
            )}
            theme={mainMetric.getTheme(metric.value, metricsLookup)}
          />
        );
      })}
    </div>
  );
};

const HeadlineMetricCard = ({
  title,
  content,
  subtitle,
  tooltipContent,
  theme,
}: {
  title: string;
  content: string;
  subtitle: string;
  tooltipContent: React.ReactNode;
  theme: TrendTheme;
}) => {
  return (
    <Tile
      smaller
      title={title}
      theme={theme}
      tooltipContent={
        <div className="text-content-invert max-w-[200px]">
          {tooltipContent}
        </div>
      }
      content={
        <div className={"flex flex-col gap-1"}>
          <div className="flex gap-2 items-center">
            <div className="text-base-bold">{content}</div>
            {theme === TrendTheme.Bad ? (
              <Badge theme={BadgeTheme.Error} size={BadgeSize.ExtraSmall}>
                High
              </Badge>
            ) : null}
          </div>
          <span className={"text-xs-med text-content-secondary"}>
            {subtitle}
          </span>
        </div>
      }
    />
  );
};

const LastSyncAt = ({ lastSyncAt }: { lastSyncAt?: Date }) => {
  if (!lastSyncAt) {
    return null;
  }
  return (
    <span className={"text-content-tertiary"}>
      Synced{" "}
      {isToday(lastSyncAt)
        ? `at ${format(lastSyncAt, "HH:mm")}`
        : `on ${format(lastSyncAt, "EEE, d MMM HH:mm")}`}
    </span>
  );
};
