/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnCallNotificationRule,
    OnCallNotificationRuleFromJSON,
    OnCallNotificationRuleFromJSONTyped,
    OnCallNotificationRuleToJSON,
} from './OnCallNotificationRule';

/**
 * 
 * @export
 * @interface OnCallNotificationsListRulesResponseBody
 */
export interface OnCallNotificationsListRulesResponseBody {
    /**
     * 
     * @type {Array<OnCallNotificationRule>}
     * @memberof OnCallNotificationsListRulesResponseBody
     */
    rules: Array<OnCallNotificationRule>;
}

export function OnCallNotificationsListRulesResponseBodyFromJSON(json: any): OnCallNotificationsListRulesResponseBody {
    return OnCallNotificationsListRulesResponseBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsListRulesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsListRulesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rules': ((json['rules'] as Array<any>).map(OnCallNotificationRuleFromJSON)),
    };
}

export function OnCallNotificationsListRulesResponseBodyToJSON(value?: OnCallNotificationsListRulesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rules': ((value.rules as Array<any>).map(OnCallNotificationRuleToJSON)),
    };
}

