import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useParams } from "react-router";
import { useIntercom } from "react-use-intercom";
import { useAPI } from "src/utils/swr";

import { CatalogFixedFooter } from "../common/CatalogFixedFooter";
import {
  ManageInGithubStepConfig,
  ManageInGitHubStepEnum,
} from "./ManageInGithubRoute";

export const SyncStep = () => {
  const { repoId } = useParams<{ repoId: string }>();
  const navigate = useOrgAwareNavigate();
  const { showMessages } = useIntercom();
  const showToast = useToast();

  const [workflowExecuted, setWorkflowExecuted] = useState(false);

  const { data: repo, error: repoError } = useAPI(
    repoId ? "catalogShowGeneratedRepository" : null,
    { id: repoId ?? "" },
  );

  const { error } = useAPI(
    repoId ? "catalogListTypes" : null,
    { includeCount: false },
    {
      fallbackData: { catalog_types: [] },
      // We'll poll every second until we see that the repo was synced, then stop
      refreshInterval: () => (workflowExecuted ? 0 : 1000),
      onSuccess: (data) => {
        if (!repo) return;

        const relevantTypes = data.catalog_types.filter((type) =>
          repo.generated_repository.selected_catalog_type_ids.includes(type.id),
        );

        for (const type of relevantTypes) {
          if (
            !type.source_repo_url ||
            !type.annotations["incident.io/catalog-importer/last-sync-at"]
          ) {
            // This type hasn't been synced via the catalog importer yet
            return;
          }
        }

        setWorkflowExecuted(true);

        showToast({
          title: "Successfully synced from GitHub",
          description: "You've successfully synced your catalog from GitHub.",
          theme: ToastTheme.Success,
        });
      },
    },
  );

  if (error || repoError) {
    return <GenericErrorMessage error={error || repoError} />;
  }

  return (
    <>
      <div className="w-full h-full flex flex-col lg:flex-row">
        <div className="lg:flex-1 lg:h-full p-4 lg:overflow-y-auto lg:pb-[71px]">
          <NakedSteps
            steps={ManageInGithubStepConfig}
            currentStepID={ManageInGitHubStepEnum.Sync}
            color={ColorPaletteEnum.Slate}
          />
          <div className="flex flex-col pt-6 mx-auto">
            <Txt bold className="text-[24px] font-semibold leading-10">
              Test & Sync
            </Txt>
            <Txt className="text-slate-600 mb-6">
              Follow the steps below to configure your repository&rsquo;s
              secrets
            </Txt>
            <NumberedList>
              <Txt grey className="mb-4">
                In GitHub, click <strong>Actions</strong> in the navigation bar
                at the top of the page and select the{" "}
                <code>incident-catalog-importer-dry-run</code> action
              </Txt>

              <Txt grey className="mb-4">
                Click the <strong>Run workflow</strong> button and run the
                workflow against the default branch of your repository. This
                will usually be <code>main</code> or <code>master</code>
              </Txt>

              <Txt grey className="mb-4">
                When the workflow has run, click in to it and then in to the{" "}
                <code>import-incidentio-catalog-dry-run</code> job, and expand
                the <strong>Dry-run incident.io catalog importer</strong> step.
                Check that you see the following output at the bottom:
                <CodeBlock
                  className="my-4"
                  code={`✔ No entries to delete\n✔ No new entries to create\n✔ No existing entries to update`}
                  hideHeader
                  hideCopyButton
                />
                This output means that the repository is successfully connected
                to your catalog and there are no data differences between the
                catalog and the repository. If you don&rsquo;t see this, please{" "}
                <Button
                  analyticsTrackingId={null}
                  theme={ButtonTheme.Unstyled}
                  className="underline"
                  onClick={() => showMessages()}
                >
                  get in touch
                </Button>
                .
              </Txt>

              <Txt grey className="mb-4">
                Click the <strong>Actions</strong> button in the navigation bar,
                and run the <code>incident-catalog-importer</code> workflow like
                you did for the dry-run workflow. Once that has successfully
                run, you&apos;ll see confirmation below
              </Txt>
            </NumberedList>
          </div>
        </div>
      </div>
      <CatalogFixedFooter
        onGoBack={() => {
          navigate(`/catalog/manage-in-github/${repoId}/api-key`);
        }}
      >
        {!workflowExecuted && <Txt grey>Complete all steps to proceed</Txt>}
        <Button
          analyticsTrackingId={null}
          onClick={() => navigate(`/catalog/`)}
          theme={ButtonTheme.Primary}
          disabled={!workflowExecuted}
        >
          Done
        </Button>
      </CatalogFixedFooter>
    </>
  );
};
