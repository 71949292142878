import {
  AlertActorSourceTypeEnum,
  AlertSource,
  AlertSourceSourceTypeEnum,
  AlertSourceTypeEnum,
} from "@incident-io/api";
import { useAPI } from "src/utils/swr";

// useAlertSource is a hook that fetches the alert sources from the API and gives you
// the alert source you're looking for based on the source type you pass in
// (e.g. "Alertmanager", "Checkly", etc.)
//
// If you're passing in `sourceConfig.source_type`, then you should skip calling
// this hook and instead just use the slim `sourceConfig.alert_source` body that already
// has name on it.
export const useAlertSource = (
  sourceType?:
    | AlertSourceSourceTypeEnum
    | AlertSourceTypeEnum
    | AlertActorSourceTypeEnum,
): {
  alertSource: AlertSource | undefined;
  alertSources: AlertSource[] | undefined;
  isLoadingAlertSource: boolean;
} => {
  const { isLoading, data: sourcesResponse } = useAPI(
    "alertsListSources",
    undefined,
  );

  return {
    alertSource: sourcesResponse?.alert_sources.find(
      (s) => s.source_type === sourceType,
    ),
    alertSources: sourcesResponse?.alert_sources,
    isLoadingAlertSource: isLoading,
  };
};
