import {
  AIConfigEnabledFeaturesEnum,
  AIToggleFeatureRequestBodyFeatureEnum,
  IncidentCallExternalProviderEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  ApiError,
  CallParticipant,
  CallSession,
  IncidentCallSessionWithTranscript,
  SelectOption,
} from "@incident-io/query-api";
import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  PopoverSingleSelect,
  SharedToasts,
  TabPane,
  TabSection,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useParams } from "react-router";
import { useIntercom } from "react-use-intercom";
import { useIdentity } from "src/contexts/IdentityContext";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPI, useAPIInfinite, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { stringToHash } from "src/utils/utils";

import { useInternalId } from "../legacy/incident/hooks";
import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../legacy/incident/sidebar/AvatarList";
import { IconForCallProvider } from "../legacy/incident/stacked-lists/CallsStackedList";
import { CallSummary } from "./CallSummary";
import { CallTranscript } from "./CallTranscript";
import { CurrentTopicFancy } from "./CurrentTopicFancy";
import {
  useIncidentCallSessionsWithSummaries,
  useLatestIncidentCall,
} from "./hooks";
import upsellBanner from "./scribe-upsell.svg";

export const CallNotesDrawer = ({
  onClose,
  incidentId,
}: {
  onClose: () => void;
  incidentId: string;
}) => {
  const { streamId } = useParams() as {
    streamId: string;
  };

  const { callData } = useLatestIncidentCall(incidentId);

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title="Call notes"
          icon={IconEnum.Scribe}
          onClose={onClose}
          color={ColorPaletteEnum.Scribe}
          secondaryAccessory={
            callData?.incident_call?.call_url && (
              <Button
                icon={IconForCallProvider(
                  callData.incident_call
                    .external_provider as IncidentCallExternalProviderEnum,
                )}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Medium}
                analyticsTrackingId="close-call-notes-drawer"
                href={callData.incident_call.call_url}
                openInNewTab
              >
                Join call
              </Button>
            )
          }
        />
        <DrawerBody className="flex grow p-6 gap-0">
          <CallNotesDrawerBody incidentId={incidentId} streamId={streamId} />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

const CallNotesDrawerBody = ({
  incidentId,
  streamId,
}: {
  incidentId: string;
  streamId: string;
}) => {
  const { identity } = useIdentity();
  const { featureLiveCallTranscription } = useFlags();
  const { data: aiConfigData } = useAPI(
    identity ? "aIShowConfig" : null,
    undefined,
  );

  const notYetEnabled =
    aiConfigData?.config.enabled &&
    !aiConfigData?.config.enabled_features.includes(
      AIConfigEnabledFeaturesEnum.CallTranscription,
    );

  const {
    internalId: streamInternalId,
    isLoading: isLoadingStreamId,
    error: streamIdError,
  } = useInternalId(streamId);

  const {
    callSessionsWithSummaries,
    isLoading: isSessionsLoading,
    error: sessionError,
    callSessionsWithSummariesIsFetching,
  } = useIncidentCallSessionsWithSummaries(streamInternalId ?? incidentId);

  if (streamIdError) {
    return <GenericErrorMessage />;
  }

  if (isLoadingStreamId || isSessionsLoading) {
    return <Loader />;
  }

  // If it's a 404 we will render empty states below - otherwise show error
  if (
    sessionError &&
    (sessionError instanceof ApiError ? sessionError.status !== 404 : true)
  ) {
    return <GenericErrorMessage />;
  }

  // If the org has not enabled the AI feature, we should show an upsell
  if (featureLiveCallTranscription && notYetEnabled) {
    return <UpsellForScribe />;
  }

  // No call session? Not sure how you got here, but show an empty state
  if (!callSessionsWithSummaries || callSessionsWithSummaries.length === 0) {
    return <NoCallNotes />;
  }

  // Sort our sessions by started_at, and for now pick the latest one
  callSessionsWithSummaries.sort(
    (a, b) =>
      new Date(b.call_session.started_at).getTime() -
      new Date(a.call_session.started_at).getTime(),
  );

  return (
    <CallNotesSessions
      callSessions={callSessionsWithSummaries}
      callSessionsWithSummariesIsFetching={callSessionsWithSummariesIsFetching}
    />
  );
};

const UpsellForScribe = () => {
  const { showArticle } = useIntercom();
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const showToast = useToast();

  const { trigger: enableScribe } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: {
          feature: AIToggleFeatureRequestBodyFeatureEnum.CallTranscription,
          enabled: true,
        },
      }),
    {
      onSuccess: () => {
        showToast({
          title: "Scribe successfully activated",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast(SharedToasts.SETTINGS_SAVE_ERROR);
        showToast({
          title: "Could not activate Scribe",
          theme: ToastTheme.Success,
        });
      },
    },
  );

  return (
    <div className="flex flex-col flex-grow justify-center align-middle self-stretch">
      <div
        className={tcx(
          "w-full grow",
          "rounded-2 bg-surface-primary",
          "flex flex-col items-center justify-center",
          scribeStyles.upsellBackground,
        )}
      >
        <div className="max-w-lg flex flex-col items-center justify-center gap-6">
          <div className="hidden xl:block shrink-0 self-stretch">
            <img src={upsellBanner} />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-center text-2xl-bold">
              Never miss a call detail
            </div>
            <div className="text-sm-normal text-center text-content-tertiary">
              Scribe auto-joins video calls to take notes and deliver concise
              summaries, allowing everyone to participate freely.
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Button
              analyticsTrackingId="learn-more-scribe-notes-page"
              theme={ButtonTheme.Secondary}
              onClick={() => showArticle(10200669)}
            >
              Learn more
            </Button>
            {canEditSettings && (
              <Button
                analyticsTrackingId="enable-scribe"
                theme={ButtonTheme.Primary}
                onClick={() => enableScribe({})}
              >
                Activate
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const NoCallNotes = () => {
  return (
    <div className="py-8 flex flex-col flex-grow justify-center align-middle self-stretch">
      <EmptyState
        className="border-0"
        title={"No call notes available"}
        icon={IconEnum.Scribe}
        content={
          <>
            Looks like we don&apos;t have any call notes for this incident. Join
            the call, and incident.io will automatically transcribe it for you.
          </>
        }
      />
    </div>
  );
};

const CallNotesSessions = ({
  callSessions,
  callSessionsWithSummariesIsFetching,
}: {
  callSessions: IncidentCallSessionWithTranscript[];
  callSessionsWithSummariesIsFetching: boolean;
}) => {
  // We need to manage some state to keep track of the current session
  const [currentSessionCallSessionID, setCurrentSessionCallSessionID] =
    useState<string>(callSessions[0].call_session.id);

  // Track if we've loaded the summary tab before so we avoid animating the
  // gradient again the second time.
  const [shouldAnimateOnMount, setShouldAnimateOnMount] = useState(true);
  // Track the summary - we can then setShouldAnimateOnMount again if it
  // changes which causes it to nicely animate.
  const [previousSummaryHash, setPreviousSummaryHash] = useState(0);

  const {
    responses,
    isLoading,
    isFullyLoaded,
    error,
    loadMore: onLoadMore,
  } = useAPIInfinite(
    "incidentCallTranscriptsListTranscriptEntriesForCallSession",
    {
      incidentCallSessionId: currentSessionCallSessionID,
      pageSize: 50,
    },
    {
      refreshInterval: 10000, // 10 seconds
      revalidateAll: true,
      revalidateOnFocus: true,
    },
  );

  const entries = responses.flatMap(({ entries }) => entries);

  if (error) {
    return <GenericErrorMessage />;
  }

  // Try to map the current session to the one we have in the list
  const currentSessionWithTranscript = callSessions.find(
    (s) => s.call_session.id === currentSessionCallSessionID,
  );

  if (!currentSessionWithTranscript) {
    return <GenericErrorMessage />;
  }

  const overviewTab = "overview";
  const transcriptTab = "transcript";
  const currentCallSession = currentSessionWithTranscript.call_session;

  if (currentSessionWithTranscript.summary) {
    const hashedSummary = stringToHash(
      currentSessionWithTranscript.summary.markdown,
    );
    if (previousSummaryHash !== hashedSummary) {
      // If we don't match - lets reset the shouldAnimate so we re-add
      // the gradient on the summary changing
      setShouldAnimateOnMount(true);
      setPreviousSummaryHash(hashedSummary); // don't forget to update!
    }
  }

  return (
    <>
      <div className="flex flex-start gap-10 self-stretch pb-5">
        <CallSessionSelector
          callSessions={callSessions}
          selectedSession={currentSessionWithTranscript.call_session}
          onSelectSession={(sessionId) => {
            setCurrentSessionCallSessionID(sessionId);
          }}
        />
        <CallSessionParticipants
          participants={currentSessionWithTranscript.call_session.participants}
        />
      </div>
      {!currentSessionWithTranscript.summary && entries.length === 0 ? (
        <NoCallNotes />
      ) : (
        <TabSection
          withIndicator
          defaultTab={overviewTab}
          tabs={[
            {
              id: overviewTab,
              label: "Overview",
            },
            {
              id: transcriptTab,
              label: "Transcript",
            },
          ]}
          tabClassName="!text-sm text-content-tertiary"
          tabBarClassName="border-b border-stroke"
          onTabChange={() => setShouldAnimateOnMount(false)}
        >
          <TabPane tabId={overviewTab}>
            <div className="flex flex-col gap-5 pt-5">
              {currentCallSession.current_topic &&
                currentCallSession.current_topic_last_updated_at && (
                  <CurrentTopicFancy
                    currentTopic={currentCallSession.current_topic}
                    lastUpdatedAt={
                      currentCallSession.current_topic_last_updated_at
                    }
                    animateOnMount={shouldAnimateOnMount}
                  />
                )}
              <CallSummary
                isFetchingSummary={callSessionsWithSummariesIsFetching}
                callSessionWithTranscript={currentSessionWithTranscript}
                animateOnMount={shouldAnimateOnMount}
              />
            </div>
          </TabPane>
          <TabPane tabId={transcriptTab}>
            <CallTranscript
              callSession={currentSessionWithTranscript.call_session}
              entries={entries}
              isFullyLoaded={isFullyLoaded}
              isLoading={isLoading}
              onLoadMore={onLoadMore}
            />
          </TabPane>
        </TabSection>
      )}
    </>
  );
};

const CallSessionSelector = ({
  callSessions,
  selectedSession,
  onSelectSession,
}: {
  callSessions: IncidentCallSessionWithTranscript[];
  selectedSession: CallSession;
  onSelectSession: (sessionId: string) => void;
}) => {
  // Build a list of call sessions into the popover format
  const callSessionOptions = callSessions.map((s, index): SelectOption => {
    // for each one build the label of the right dates
    const label = durationLabelForSession({ callSession: s.call_session });
    return {
      label,
      value: s.call_session.id,
      sort_key: `${index}`,
    };
  });

  const sessionDefinition =
    "A session is a distinct period when the incident call was active.";
  const tooltipContent =
    callSessions.length > 1 ? (
      <div className="flex flex-col gap-2">
        <div>{sessionDefinition}</div>
        <div>
          This incident had multiple call sessions. Select a session to view its
          notes.
        </div>
      </div>
    ) : (
      <>{sessionDefinition}</>
    );

  return (
    <div className="flex flex-col items-start gap-1">
      <div className="flex gap-1 text-content-secondary text-xs-med">
        Session
        <Tooltip content={tooltipContent} />
      </div>
      <div className="flex items-start gap-1">
        {callSessions.length > 1 ? (
          <PopoverSingleSelect
            options={callSessionOptions}
            value={selectedSession.id}
            isClearable={false}
            onChange={(sessionId) => onSelectSession(sessionId ?? "")}
            triggerClassName={"p-0 m-0"}
            renderTriggerNode={({ onClick, selectedOption }) => {
              return (
                <Button
                  onClick={onClick}
                  theme={ButtonTheme.Secondary}
                  analyticsTrackingId={null}
                  title={selectedOption?.label || "Choose"}
                  size={BadgeSize.Medium}
                >
                  {selectedOption?.label || "Choose"}
                  <Icon id={IconEnum.ChevronDown} size={IconSize.Small} />
                </Button>
              );
            }}
          />
        ) : (
          <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Medium}>
            {durationLabelForSession({ callSession: selectedSession })}
          </Badge>
        )}
        {selectedSession.started_at && !selectedSession.ended_at && (
          <Badge theme={BadgeTheme.Error} size={BadgeSize.Medium}>
            <AlertFiringIndicator firing={true} />
            Live
          </Badge>
        )}
      </div>
    </div>
  );
};

const CallSessionParticipants = ({
  participants,
}: {
  participants: CallParticipant[] | undefined;
}) => {
  return (
    <div className="flex flex-col gap-1">
      {!!participants && participants.length > 0 && (
        <>
          <div className="text-content-secondary text-xs-med">Participants</div>
          <dl className="flex-center-y justify-between">
            <AvatarList
              users={participants.map((p) => convertToMaybeUser(p))}
              modalTitle={"Call participants"}
              maxToShow={5}
              clickableType={AvatarListClickableType.OnlyOnSeeMore}
              avatarSize={IconSize.Large}
            />
          </dl>
        </>
      )}
    </div>
  );
};

const durationLabelForSession = ({
  callSession,
}: {
  callSession: CallSession;
}): string => {
  const localCallStartDate = formatTimestampLocale({
    timestamp: callSession.started_at,
    dateStyle: "short",
  });

  const localCallStartTime = formatTimestampLocale({
    timestamp: callSession.started_at,
    timeStyle: "short",
  });

  let timeString = `${localCallStartDate} at ${localCallStartTime}`;
  if (callSession.ended_at) {
    const localCallEndDate = formatTimestampLocale({
      timestamp: callSession.ended_at,
      dateStyle: "short",
    });

    const localCallEndTime = formatTimestampLocale({
      timestamp: callSession.ended_at,
      timeStyle: "short",
    });

    if (localCallStartDate === localCallEndDate) {
      timeString = `${localCallStartDate} from ${localCallStartTime} to ${localCallEndTime}`;
    } else {
      timeString = `${localCallStartDate} at ${localCallStartTime} until ${localCallEndDate} at ${localCallEndTime}`;
    }
  }

  return timeString;
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  maybeUser.greyedOut = participant.last_left_at ? true : false;

  return maybeUser;
};
