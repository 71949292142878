/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentType,
    IncidentTypeFromJSON,
    IncidentTypeFromJSONTyped,
    IncidentTypeToJSON,
} from './IncidentType';

/**
 * 
 * @export
 * @interface IncidentTypesSetDefaultResponseBody
 */
export interface IncidentTypesSetDefaultResponseBody {
    /**
     * 
     * @type {IncidentType}
     * @memberof IncidentTypesSetDefaultResponseBody
     */
    incident_type: IncidentType;
}

export function IncidentTypesSetDefaultResponseBodyFromJSON(json: any): IncidentTypesSetDefaultResponseBody {
    return IncidentTypesSetDefaultResponseBodyFromJSONTyped(json, false);
}

export function IncidentTypesSetDefaultResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTypesSetDefaultResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_type': IncidentTypeFromJSON(json['incident_type']),
    };
}

export function IncidentTypesSetDefaultResponseBodyToJSON(value?: IncidentTypesSetDefaultResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_type': IncidentTypeToJSON(value.incident_type),
    };
}

