import type { SVGProps } from "react";
import * as React from "react";
const SvgBugsnag = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#484ADC"
      d="M7.508 2.96a.66.66 0 0 1 .338.576l.006 5.805h2.086a2.817 2.817 0 1 1-2.817 2.817l-.003-2.082H5.732v2.082a4.205 4.205 0 1 0 4.206-4.205H9.3a.367.367 0 1 1 0-.735h.638a4.94 4.94 0 1 1-4.94 4.94v-2.45c0-.202.164-.367.367-.367h1.752L7.111 3.67l-1.379.848v3.23a.367.367 0 0 1-.734 0V4.475c0-.229.12-.442.314-.562l1.528-.94a.66.66 0 0 1 .668-.014Zm2.428 7.116H7.853v2.082a2.082 2.082 0 1 0 2.083-2.082Zm.002 1.493a.589.589 0 1 1 0 1.178.589.589 0 0 1 0-1.178Z"
    />
  </svg>
);
export default SvgBugsnag;
