/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SeveritiesCreateRequestBody,
    SeveritiesCreateRequestBodyFromJSON,
    SeveritiesCreateRequestBodyToJSON,
    SeveritiesCreateResponseBody,
    SeveritiesCreateResponseBodyFromJSON,
    SeveritiesCreateResponseBodyToJSON,
    SeveritiesListResponseBody,
    SeveritiesListResponseBodyFromJSON,
    SeveritiesListResponseBodyToJSON,
    SeveritiesShowResponseBody,
    SeveritiesShowResponseBodyFromJSON,
    SeveritiesShowResponseBodyToJSON,
    SeveritiesUpdateRanksRequestBody,
    SeveritiesUpdateRanksRequestBodyFromJSON,
    SeveritiesUpdateRanksRequestBodyToJSON,
    SeveritiesUpdateRanksResponseBody,
    SeveritiesUpdateRanksResponseBodyFromJSON,
    SeveritiesUpdateRanksResponseBodyToJSON,
    SeveritiesUpdateRequestBody,
    SeveritiesUpdateRequestBodyFromJSON,
    SeveritiesUpdateRequestBodyToJSON,
    SeveritiesUpdateResponseBody,
    SeveritiesUpdateResponseBodyFromJSON,
    SeveritiesUpdateResponseBodyToJSON,
} from '../models';

export interface SeveritiesCreateRequest {
    createRequestBody: SeveritiesCreateRequestBody;
}

export interface SeveritiesDestroyRequest {
    id: string;
}

export interface SeveritiesShowRequest {
    id: string;
}

export interface SeveritiesUpdateRequest {
    id: string;
    updateRequestBody: SeveritiesUpdateRequestBody;
}

export interface SeveritiesUpdateRanksRequest {
    updateRanksRequestBody: SeveritiesUpdateRanksRequestBody;
}

/**
 * 
 */
export class SeveritiesApi extends runtime.BaseAPI {

    /**
     * Creates an incident severity
     * Create Severities
     */
    async severitiesCreateRaw(requestParameters: SeveritiesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SeveritiesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling severitiesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/severities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SeveritiesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeveritiesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates an incident severity
     * Create Severities
     */
    async severitiesCreate(requestParameters: SeveritiesCreateRequest, initOverrides?: RequestInit): Promise<SeveritiesCreateResponseBody> {
        const response = await this.severitiesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing severity
     * Destroy Severities
     */
    async severitiesDestroyRaw(requestParameters: SeveritiesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling severitiesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/severities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing severity
     * Destroy Severities
     */
    async severitiesDestroy(requestParameters: SeveritiesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.severitiesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List severities
     * List Severities
     */
    async severitiesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SeveritiesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/severities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeveritiesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List severities
     * List Severities
     */
    async severitiesList(initOverrides?: RequestInit): Promise<SeveritiesListResponseBody> {
        const response = await this.severitiesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show a single severity
     * Show Severities
     */
    async severitiesShowRaw(requestParameters: SeveritiesShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SeveritiesShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling severitiesShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/severities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeveritiesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single severity
     * Show Severities
     */
    async severitiesShow(requestParameters: SeveritiesShowRequest, initOverrides?: RequestInit): Promise<SeveritiesShowResponseBody> {
        const response = await this.severitiesShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing severity
     * Update Severities
     */
    async severitiesUpdateRaw(requestParameters: SeveritiesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SeveritiesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling severitiesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling severitiesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/severities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SeveritiesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeveritiesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing severity
     * Update Severities
     */
    async severitiesUpdate(requestParameters: SeveritiesUpdateRequest, initOverrides?: RequestInit): Promise<SeveritiesUpdateResponseBody> {
        const response = await this.severitiesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing severities
     * UpdateRanks Severities
     */
    async severitiesUpdateRanksRaw(requestParameters: SeveritiesUpdateRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SeveritiesUpdateRanksResponseBody>> {
        if (requestParameters.updateRanksRequestBody === null || requestParameters.updateRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateRanksRequestBody','Required parameter requestParameters.updateRanksRequestBody was null or undefined when calling severitiesUpdateRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/severities/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SeveritiesUpdateRanksRequestBodyToJSON(requestParameters.updateRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeveritiesUpdateRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing severities
     * UpdateRanks Severities
     */
    async severitiesUpdateRanks(requestParameters: SeveritiesUpdateRanksRequest, initOverrides?: RequestInit): Promise<SeveritiesUpdateRanksResponseBody> {
        const response = await this.severitiesUpdateRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
