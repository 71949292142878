import { Button, ButtonTheme } from "@incident-ui";
import { ButtonProps } from "@incident-ui/Button/Button";
import { tcx } from "src/utils/tailwind-classes";

import { ReactComponent as Icon } from "./images/google-play.svg";

export const GooglePlayButton = ({
  buttonProps,
  iconClassName,
}: {
  buttonProps: Omit<ButtonProps, "href">;
  iconClassName?: string;
}) => {
  return (
    <Button
      theme={ButtonTheme.Secondary}
      href="https://play.google.com/store/apps/details?id=com.incidentio.incidentio"
      {...buttonProps}
    >
      <Icon className={tcx("max-h-[20px] max-w-[20px] mr-1", iconClassName)} />
      Google Play
    </Button>
  );
};
