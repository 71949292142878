import { GenericErrorMessage } from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";
import { AIConfigEnabledFeaturesEnum } from "src/contexts/ClientContext";
import { useAIFeatureForOrg } from "src/hooks/useAI";
import { tcx } from "src/utils/tailwind-classes";
import { useEventListener, usePrevious } from "use-hooks";

import { findExploreAChartQuestion } from "./AssistantExploreAChart";
import { AssistantHeader } from "./AssistantHeader";
import { AssistantInput } from "./AssistantInput";
import { AssistantIntro } from "./AssistantIntro";
import styles from "./AssistantOverlay.module.scss";
import { useAssistantOverlay } from "./AssistantOverlayContext";
import { AssistantSuggestions } from "./AssistantSuggestions";
import { AssistantThread } from "./AssistantThread";
import { useAssistantThreadContext } from "./AssistantThreadContext";

// Handles the question that was triggered from the explore a chart button
const useSubmitExploreAChartQuestion = () => {
  const { questionID } = useAssistantOverlay();
  const assistantThreadContext = useAssistantThreadContext();

  // If Assistant was opened with a question ID as a query param for explore a chart, then find
  // the question record associated with the ID.
  const exploreAChartQuestion = findExploreAChartQuestion(questionID);
  const previousQuestion = usePrevious(exploreAChartQuestion?.question);

  useEffect(() => {
    // If the overlay was opened with a question ID as a query param, then start processing the
    // question.
    if (
      !previousQuestion &&
      exploreAChartQuestion?.question &&
      assistantThreadContext
    ) {
      assistantThreadContext.submitQuestion(exploreAChartQuestion.question);
    }
  }, [
    previousQuestion,
    exploreAChartQuestion?.question,
    assistantThreadContext,
  ]);
};

export const AssistantOverlay = () => {
  // Callers which open Assistant's chat overlay should be response for checking if Assistant is
  // enabled first, but we'll do a final check here just in case.
  const canUseAI = useAIFeatureForOrg();
  if (!canUseAI(AIConfigEnabledFeaturesEnum.Assistant)) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <AssistantOverlayInner />
    </ErrorBoundary>
  );
};

// AssistantOverlay is the main component for Assistant. It slides in from the right, and
// appears globally with the app.
const AssistantOverlayInner = () => {
  const { isOverlayOpen, shouldShowIntro } = useAssistantOverlay();
  const assistantThreadContext = useAssistantThreadContext();

  // Close the overlay when the user presses the escape key.
  const { toggleOverlay } = useAssistantOverlay();
  useEventListener("keydown", (e: KeyboardEvent) => {
    if (isOverlayOpen && (e.key === "Esc" || e.key === "Escape")) {
      toggleOverlay();
    }
  });

  useSubmitExploreAChartQuestion();

  if (!assistantThreadContext) {
    return null;
  }

  const animateStyles = tcx(
    "fixed top-0 right-0 bottom-0 max-w-4xl w-[calc(100%-2rem)] sm:w-[calc(100%-6rem)] lg:w-full bg-white shadow-xl z-50 transition-transform duration-500 m-4 rounded-2xl",
    {
      "translate-x-full": !isOverlayOpen,
      "translate-x-0": isOverlayOpen,
    },
  );

  return (
    <div className="hidden sm:block">
      <div
        className={tcx(
          { "!bg-opacity-25 !visible": isOverlayOpen },
          "z-40 bg-surface-invert bg-opacity-0 fixed w-full h-full invisible transition-all",
        )}
        onClick={() => toggleOverlay()}
      ></div>
      <div
        className={animateStyles}
        style={{
          transform: !isOverlayOpen ? "translateX(104%)" : "translateX(0)",
        }}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <AssistantHeader />

          {/* Body */}
          {shouldShowIntro ? (
            <div className="flex-grow flex p-6 items-center justify-center">
              <AssistantIntro />
            </div>
          ) : (
            <div
              className={tcx(
                styles.hideScrollbar,
                "flex-grow flex p-6 overflow-y-auto",
              )}
            >
              <AssistantThread messageLog={assistantThreadContext.messageLog} />
            </div>
          )}

          {/* Footer */}
          <div className="p-4">
            {shouldShowIntro && (
              <AssistantSuggestions
                submitQuestion={assistantThreadContext.submitQuestion}
              />
            )}
            <AssistantInput
              assistantId={assistantThreadContext.assistantId}
              threadId={assistantThreadContext.threadId}
              canSubmit={!assistantThreadContext.isLoading}
              submitQuestion={assistantThreadContext.submitQuestion}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
