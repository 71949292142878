/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageUpdateTemplateModeRequestBody
 */
export interface StatusPageUpdateTemplateModeRequestBody {
    /**
     * Which types of templates are used for this page?
     * @type {string}
     * @memberof StatusPageUpdateTemplateModeRequestBody
     */
    template_mode: StatusPageUpdateTemplateModeRequestBodyTemplateModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageUpdateTemplateModeRequestBodyTemplateModeEnum {
    MessageTemplate = 'message_template',
    PreDefinedUpdate = 'pre_defined_update'
}

export function StatusPageUpdateTemplateModeRequestBodyFromJSON(json: any): StatusPageUpdateTemplateModeRequestBody {
    return StatusPageUpdateTemplateModeRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateTemplateModeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateTemplateModeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template_mode': json['template_mode'],
    };
}

export function StatusPageUpdateTemplateModeRequestBodyToJSON(value?: StatusPageUpdateTemplateModeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template_mode': value.template_mode,
    };
}

