import { customComponents } from "@incident-ui/Select/customComponents";
import { customStyles } from "@incident-ui/Select/customStyles";
import {
  isSelectOption,
  isSelectOptionGroup,
  SelectOption,
  SharedStaticSelectProps,
  sortSelectOptions,
} from "@incident-ui/Select/types";
import { useAutoAdjustingMenuPlacement } from "@incident-ui/Select/useAutoAdjustingMenuPlacement";
import ReactSelect, { MultiValue } from "react-select";

import { SelectWrapper } from "./SelectWrapper";

export type StaticMultiSelectProps = SharedStaticSelectProps & {
  value: string[];
  onChange: (val: string[]) => void;
};

export const StaticMultiSelect = ({
  options,
  value,
  onChange: onValueChange,
  ...restProps
}: StaticMultiSelectProps): React.ReactElement => {
  // the outside world wants to deals with values that are strings, but react select expects
  // SelectOptions as values. So we have to do some hacking to make the outside world not
  // know about this sadness.
  const hydratedValues = value
    ? options.flatMap((optOrGroup) => {
        if (isSelectOption(optOrGroup)) {
          return value.includes(optOrGroup.value) ? [optOrGroup] : [];
        }

        if (isSelectOptionGroup(optOrGroup)) {
          return optOrGroup.options.filter((opt) => value.includes(opt.value));
        }

        return [];
      })
    : [];
  const onChange = (selectedOption: MultiValue<SelectOption>) => {
    onValueChange(selectedOption.map((opt) => opt.value));
  };

  return (
    <StaticMultiSelectWithObj
      value={hydratedValues}
      onChange={onChange}
      options={options}
      {...restProps}
    />
  );
};

StaticMultiSelect.displayName = "StaticMultiSelect";

export type StaticMultiSelectWithObjProps = SharedStaticSelectProps & {
  value: SelectOption[];
  onChange: (val: MultiValue<SelectOption>) => void;
  onValueChange?: (val: MultiValue<SelectOption>) => void;
};

export const StaticMultiSelectWithObj = ({
  options,
  icon,
  optionIcon,
  optionColor,
  value,
  id,
  invalid,
  onChange,
  insetSuffixNode,
  styles = customStyles,
  className,
  disabled,
  ...rest
}: StaticMultiSelectWithObjProps): React.ReactElement => {
  const sortedOptions = sortSelectOptions(options);
  const [menuPlacement, internalRef] = useAutoAdjustingMenuPlacement();

  const isDisplayingPill = !!optionIcon && !!optionColor;

  return (
    <SelectWrapper
      suffixNode={insetSuffixNode}
      className={className}
      disabled={disabled}
    >
      <ReactSelect<SelectOption, true>
        value={value}
        inputId={id}
        onChange={onChange}
        options={sortedOptions}
        isOptionDisabled={(option) => option.disabled ?? false}
        closeMenuOnSelect={false}
        isMulti={true}
        isLoading={false}
        isClearable={true}
        styles={styles(!!invalid, !!insetSuffixNode, isDisplayingPill)}
        components={customComponents({ icon, optionIcon, optionColor })}
        menuPortalTarget={document.body}
        // @ts-expect-error this is fine, just about the react-select types
        ref={internalRef}
        menuPlacement={menuPlacement}
        // Inputs get priority over things with tabIndex=0, since inputs are
        // generally more important than other things on screen.
        tabIndex={1}
        isDisabled={disabled}
        {...rest}
      />
    </SelectWrapper>
  );
};

StaticMultiSelectWithObj.displayName = "StaticMultiSelectWithObj";
