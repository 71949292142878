import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Modal, ModalContent, ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useAPIMutation } from "src/utils/swr";

export const UnsubscribeFromInsightsEmailModal = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const { trigger: onConfirm, isMutating: saving } = useAPIMutation(
    "userPreferencesShow",
    undefined,
    async (apiClient) =>
      await apiClient.userPreferencesUpdateScheduledEmailPreferences({
        updateScheduledEmailPreferencesRequestBody: {
          monthly_insights_email_enabled: false,
        },
      }),
    {
      onSuccess: () => {
        showToast({
          title: "Successfully unsubscribed",
          theme: ToastTheme.Success,
        });

        navigate("/user-preferences");
      },
    },
  );

  return (
    <Modal
      isOpen={true}
      title={`Unsubscribe`}
      analyticsTrackingId="unsubscribe-from-monthly-insights"
      onClose={() => {
        navigate("/user-preferences");
      }}
      disableQuickClose={false}
    >
      <ModalContent>
        <p className="pb-4">
          Do you want to unsubscribe from the monthly insights email?
        </p>
        <p>
          If you change your mind, you can subscribe again in your user
          preferences.
        </p>
      </ModalContent>
      <ModalFooter
        analyticsTrackingId={"unsubscribe-from-monthly-insights"}
        onConfirm={() => onConfirm({})}
        onClose={() => navigate("/user-preferences")}
        confirmButtonType="button"
        confirmButtonText={"Unsubscribe"}
        saving={saving}
      />
    </Modal>
  );
};
