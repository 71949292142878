import { Loader } from "@incident-ui";
import React from "react";

import { useAPI } from "../../utils/swr";

// Development use only, redirected to from a development environment into
// production, where we'll piggy-back staff credentials to impersonate their
// local Insights override org in order to store the filter fields back into the
// local instance.
export const IncidentFilterFieldsInsightsRoute = (): React.ReactElement => {
  const { data } = useAPI("incidentFilterFieldsListInsights", undefined);
  if (!data) {
    return <Loader />;
  }

  const redirect = `http://localhost:8080/api/incident_filter_fields_insights_override?fields=${encodeURIComponent(
    JSON.stringify(data),
  )}`;

  return <a href={redirect}>Click to save cache</a>;
};
