import type { SVGProps } from "react";
import * as React from "react";
const SvgStatuscake = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#5AF1C8"
      d="m9.67 8.301 1.857.615 2.252-5.772a.878.878 0 0 0-1.327-.994L4.888 8.276a.878.878 0 0 0 .215 1.562l2.84.938.621-1.905A.878.878 0 0 1 9.67 8.3Z"
    />
    <path
      fill="#FF3180"
      d="m14.252 9.817-2.724-.9-.729 1.867a.88.88 0 0 1-1.112.568l-1.754-.58-.073.235-1.907 5.848a.878.878 0 0 0 1.328.994l7.186-6.471a.877.877 0 0 0-.215-1.561Z"
    />
  </svg>
);
export default SvgStatuscake;
