/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageCreateMigrationRequestBody
 */
export interface StatusPageCreateMigrationRequestBody {
    /**
     * Atlassian Statuspage page ID to import
     * @type {string}
     * @memberof StatusPageCreateMigrationRequestBody
     */
    atlassian_page_id?: string;
    /**
     * Whether the page should be hidden from search engines (go live only)
     * @type {boolean}
     * @memberof StatusPageCreateMigrationRequestBody
     */
    hide_from_search_engines?: boolean;
    /**
     * The URL of a status page to import (demo only)
     * @type {string}
     * @memberof StatusPageCreateMigrationRequestBody
     */
    page_url?: string;
    /**
     * ID of an incident.io Status Page to apply the migration to
     * @type {string}
     * @memberof StatusPageCreateMigrationRequestBody
     */
    status_page_id?: string;
    /**
     * The type of this migration, which defines how this will work
     * @type {string}
     * @memberof StatusPageCreateMigrationRequestBody
     */
    type: StatusPageCreateMigrationRequestBodyTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageCreateMigrationRequestBodyTypeEnum {
    Import = 'import',
    GoLive = 'go_live',
    DemoImport = 'demo_import',
    PublicDemoImport = 'public_demo_import'
}

export function StatusPageCreateMigrationRequestBodyFromJSON(json: any): StatusPageCreateMigrationRequestBody {
    return StatusPageCreateMigrationRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateMigrationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateMigrationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atlassian_page_id': !exists(json, 'atlassian_page_id') ? undefined : json['atlassian_page_id'],
        'hide_from_search_engines': !exists(json, 'hide_from_search_engines') ? undefined : json['hide_from_search_engines'],
        'page_url': !exists(json, 'page_url') ? undefined : json['page_url'],
        'status_page_id': !exists(json, 'status_page_id') ? undefined : json['status_page_id'],
        'type': json['type'],
    };
}

export function StatusPageCreateMigrationRequestBodyToJSON(value?: StatusPageCreateMigrationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atlassian_page_id': value.atlassian_page_id,
        'hide_from_search_engines': value.hide_from_search_engines,
        'page_url': value.page_url,
        'status_page_id': value.status_page_id,
        'type': value.type,
    };
}

