import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import {
  BranchesOnlyExpression,
  EngineParamBinding,
} from "src/contexts/ClientContext";

export type PostIncidentExpressionFormData = {
  expression: BranchesOnlyExpression;
  enabled: boolean;
};

// This hook is used to manage the result of a post-incident flow expression branch
// This allows us abstract away the logic of managing the result of a branch
// And instead allow callers of this hook to:
// 1. `flowIDs` - Get the PINC Flow IDs of a branch
// 2. `setFlowIDs` - Set the PINC Flow IDs of a branch
// 3. `isRequired` - Get if a PINC Flow is required for a branch
// 4. `setIsRequired` - Set if a PINC Flow is required for a branch
export const usePostIncidentBranchResult = <
  TFormType extends FieldValues,
  ResultType extends EngineParamBinding,
>(
  formMethods: UseFormReturn<TFormType>,
  name: Path<TFormType>,
) => {
  const result: ResultType = formMethods.watch(name);
  const arrayResult = result.array_value ?? [];

  const hasIsOptional =
    arrayResult?.find(({ literal }) => literal === PincFlowIsOptionalID) !==
    undefined;
  const flowIDs: string[] = arrayResult
    .map((val) => val.literal)
    .filter((str): str is string => str !== PincFlowIsOptionalID && !!str);

  const isRequired = !hasIsOptional;
  const setArrayValue = (ids: string[], isRequired: boolean) => {
    const newValues = isRequired ? ids : ids.concat(PincFlowIsOptionalID);
    formMethods.setValue(
      `${name}.array_value` as Path<TFormType>,
      newValues.map((val) => ({ literal: val })) as PathValue<
        TFormType,
        Path<TFormType>
      >,
    );
  };

  return {
    isRequired,
    flowIDs,
    setIsRequired: (isRequired: boolean) => setArrayValue(flowIDs, isRequired),
    setFlowIDs: (flowIDs: string[]) => setArrayValue(flowIDs, isRequired),
  };
};
const PincFlowIsOptionalID = "PINC_FLOW_IS_OPTIONAL";
