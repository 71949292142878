/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StatusPageAddResponseIncidentLinksRequestBody,
    StatusPageAddResponseIncidentLinksRequestBodyFromJSON,
    StatusPageAddResponseIncidentLinksRequestBodyToJSON,
    StatusPageAddResponseIncidentLinksResponseBody,
    StatusPageAddResponseIncidentLinksResponseBodyFromJSON,
    StatusPageAddResponseIncidentLinksResponseBodyToJSON,
    StatusPageBuildSubPageStructureResponseBody,
    StatusPageBuildSubPageStructureResponseBodyFromJSON,
    StatusPageBuildSubPageStructureResponseBodyToJSON,
    StatusPageCheckCustomDomainResponseBody,
    StatusPageCheckCustomDomainResponseBodyFromJSON,
    StatusPageCheckCustomDomainResponseBodyToJSON,
    StatusPageCheckSubpathAvailableRequestBody,
    StatusPageCheckSubpathAvailableRequestBodyFromJSON,
    StatusPageCheckSubpathAvailableRequestBodyToJSON,
    StatusPageConfigureSubPageUptimesRequestBody,
    StatusPageConfigureSubPageUptimesRequestBodyFromJSON,
    StatusPageConfigureSubPageUptimesRequestBodyToJSON,
    StatusPageConfigureSubPageUptimesResponseBody,
    StatusPageConfigureSubPageUptimesResponseBodyFromJSON,
    StatusPageConfigureSubPageUptimesResponseBodyToJSON,
    StatusPageCreateComponentRequestBody,
    StatusPageCreateComponentRequestBodyFromJSON,
    StatusPageCreateComponentRequestBodyToJSON,
    StatusPageCreateComponentResponseBody,
    StatusPageCreateComponentResponseBodyFromJSON,
    StatusPageCreateComponentResponseBodyToJSON,
    StatusPageCreateIncidentRequestBody,
    StatusPageCreateIncidentRequestBodyFromJSON,
    StatusPageCreateIncidentRequestBodyToJSON,
    StatusPageCreateIncidentResponseBody,
    StatusPageCreateIncidentResponseBodyFromJSON,
    StatusPageCreateIncidentResponseBodyToJSON,
    StatusPageCreateIncidentUpdateRequestBody,
    StatusPageCreateIncidentUpdateRequestBodyFromJSON,
    StatusPageCreateIncidentUpdateRequestBodyToJSON,
    StatusPageCreateIncidentUpdateResponseBody,
    StatusPageCreateIncidentUpdateResponseBodyFromJSON,
    StatusPageCreateIncidentUpdateResponseBodyToJSON,
    StatusPageCreateMaintenanceRequestBody,
    StatusPageCreateMaintenanceRequestBodyFromJSON,
    StatusPageCreateMaintenanceRequestBodyToJSON,
    StatusPageCreateMaintenanceResponseBody,
    StatusPageCreateMaintenanceResponseBodyFromJSON,
    StatusPageCreateMaintenanceResponseBodyToJSON,
    StatusPageCreateMigrationRequestBody,
    StatusPageCreateMigrationRequestBodyFromJSON,
    StatusPageCreateMigrationRequestBodyToJSON,
    StatusPageCreateMigrationResponseBody,
    StatusPageCreateMigrationResponseBodyFromJSON,
    StatusPageCreateMigrationResponseBodyToJSON,
    StatusPageCreateRequestBody,
    StatusPageCreateRequestBodyFromJSON,
    StatusPageCreateRequestBodyToJSON,
    StatusPageCreateResponseBody,
    StatusPageCreateResponseBodyFromJSON,
    StatusPageCreateResponseBodyToJSON,
    StatusPageCreateRetrospectiveIncidentRequestBody,
    StatusPageCreateRetrospectiveIncidentRequestBodyFromJSON,
    StatusPageCreateRetrospectiveIncidentRequestBodyToJSON,
    StatusPageCreateRetrospectiveIncidentResponseBody,
    StatusPageCreateRetrospectiveIncidentResponseBodyFromJSON,
    StatusPageCreateRetrospectiveIncidentResponseBodyToJSON,
    StatusPageCreateStructureRequestBody,
    StatusPageCreateStructureRequestBodyFromJSON,
    StatusPageCreateStructureRequestBodyToJSON,
    StatusPageCreateStructureResponseBody,
    StatusPageCreateStructureResponseBodyFromJSON,
    StatusPageCreateStructureResponseBodyToJSON,
    StatusPageCreateSubPageRequestBody,
    StatusPageCreateSubPageRequestBodyFromJSON,
    StatusPageCreateSubPageRequestBodyToJSON,
    StatusPageCreateSubPageResponseBody,
    StatusPageCreateSubPageResponseBodyFromJSON,
    StatusPageCreateSubPageResponseBodyToJSON,
    StatusPageCreateTemplateRequestBody,
    StatusPageCreateTemplateRequestBodyFromJSON,
    StatusPageCreateTemplateRequestBodyToJSON,
    StatusPageCreateTemplateResponseBody,
    StatusPageCreateTemplateResponseBodyFromJSON,
    StatusPageCreateTemplateResponseBodyToJSON,
    StatusPageDeleteCustomDomainResponseBody,
    StatusPageDeleteCustomDomainResponseBodyFromJSON,
    StatusPageDeleteCustomDomainResponseBodyToJSON,
    StatusPageGenerateLogoUploadURLRequestBody,
    StatusPageGenerateLogoUploadURLRequestBodyFromJSON,
    StatusPageGenerateLogoUploadURLRequestBodyToJSON,
    StatusPageGenerateLogoUploadURLResponseBody,
    StatusPageGenerateLogoUploadURLResponseBodyFromJSON,
    StatusPageGenerateLogoUploadURLResponseBodyToJSON,
    StatusPageGetSubscriberCountForUpdateResponseBody,
    StatusPageGetSubscriberCountForUpdateResponseBodyFromJSON,
    StatusPageGetSubscriberCountForUpdateResponseBodyToJSON,
    StatusPageGoLiveRequestBody,
    StatusPageGoLiveRequestBodyFromJSON,
    StatusPageGoLiveRequestBodyToJSON,
    StatusPageGoLiveResponseBody,
    StatusPageGoLiveResponseBodyFromJSON,
    StatusPageGoLiveResponseBodyToJSON,
    StatusPageListAvailableMigrationsResponseBody,
    StatusPageListAvailableMigrationsResponseBodyFromJSON,
    StatusPageListAvailableMigrationsResponseBodyToJSON,
    StatusPageListIncidentsResponseBody,
    StatusPageListIncidentsResponseBodyFromJSON,
    StatusPageListIncidentsResponseBodyToJSON,
    StatusPageListNewlyAffectedSubPagesRequestBody,
    StatusPageListNewlyAffectedSubPagesRequestBodyFromJSON,
    StatusPageListNewlyAffectedSubPagesRequestBodyToJSON,
    StatusPageListNewlyAffectedSubPagesResponseBody,
    StatusPageListNewlyAffectedSubPagesResponseBodyFromJSON,
    StatusPageListNewlyAffectedSubPagesResponseBodyToJSON,
    StatusPageListResponseBody,
    StatusPageListResponseBodyFromJSON,
    StatusPageListResponseBodyToJSON,
    StatusPageListSubPagesResponseBody,
    StatusPageListSubPagesResponseBodyFromJSON,
    StatusPageListSubPagesResponseBodyToJSON,
    StatusPageListSubscriptionsResponseBody,
    StatusPageListSubscriptionsResponseBodyFromJSON,
    StatusPageListSubscriptionsResponseBodyToJSON,
    StatusPageListTemplatesResponseBody,
    StatusPageListTemplatesResponseBodyFromJSON,
    StatusPageListTemplatesResponseBodyToJSON,
    StatusPageRemoveIncidentFromSubPageRequestBody,
    StatusPageRemoveIncidentFromSubPageRequestBodyFromJSON,
    StatusPageRemoveIncidentFromSubPageRequestBodyToJSON,
    StatusPageRemoveIncidentFromSubPageResponseBody,
    StatusPageRemoveIncidentFromSubPageResponseBodyFromJSON,
    StatusPageRemoveIncidentFromSubPageResponseBodyToJSON,
    StatusPageSetIncidentComponentImpactsRequestBody,
    StatusPageSetIncidentComponentImpactsRequestBodyFromJSON,
    StatusPageSetIncidentComponentImpactsRequestBodyToJSON,
    StatusPageSetIncidentComponentImpactsResponseBody,
    StatusPageSetIncidentComponentImpactsResponseBodyFromJSON,
    StatusPageSetIncidentComponentImpactsResponseBodyToJSON,
    StatusPageSetIncidentNameRequestBody,
    StatusPageSetIncidentNameRequestBodyFromJSON,
    StatusPageSetIncidentNameRequestBodyToJSON,
    StatusPageSetIncidentNameResponseBody,
    StatusPageSetIncidentNameResponseBodyFromJSON,
    StatusPageSetIncidentNameResponseBodyToJSON,
    StatusPageSetStructureAndComponentsRequestBody,
    StatusPageSetStructureAndComponentsRequestBodyFromJSON,
    StatusPageSetStructureAndComponentsRequestBodyToJSON,
    StatusPageSetStructureAndComponentsResponseBody,
    StatusPageSetStructureAndComponentsResponseBodyFromJSON,
    StatusPageSetStructureAndComponentsResponseBodyToJSON,
    StatusPageSetSubscriptionSettingsRequestBody,
    StatusPageSetSubscriptionSettingsRequestBodyFromJSON,
    StatusPageSetSubscriptionSettingsRequestBodyToJSON,
    StatusPageSetSubscriptionSettingsResponseBody,
    StatusPageSetSubscriptionSettingsResponseBodyFromJSON,
    StatusPageSetSubscriptionSettingsResponseBodyToJSON,
    StatusPageSetWriteUpRequestBody,
    StatusPageSetWriteUpRequestBodyFromJSON,
    StatusPageSetWriteUpRequestBodyToJSON,
    StatusPageSetWriteUpResponseBody,
    StatusPageSetWriteUpResponseBodyFromJSON,
    StatusPageSetWriteUpResponseBodyToJSON,
    StatusPageShowIncidentResponseBody,
    StatusPageShowIncidentResponseBodyFromJSON,
    StatusPageShowIncidentResponseBodyToJSON,
    StatusPageShowMigrationResponseBody,
    StatusPageShowMigrationResponseBodyFromJSON,
    StatusPageShowMigrationResponseBodyToJSON,
    StatusPageShowResponseBody,
    StatusPageShowResponseBodyFromJSON,
    StatusPageShowResponseBodyToJSON,
    StatusPageShowStructureResponseBody,
    StatusPageShowStructureResponseBodyFromJSON,
    StatusPageShowStructureResponseBodyToJSON,
    StatusPageUpdateComponentRequestBody,
    StatusPageUpdateComponentRequestBodyFromJSON,
    StatusPageUpdateComponentRequestBodyToJSON,
    StatusPageUpdateComponentResponseBody,
    StatusPageUpdateComponentResponseBodyFromJSON,
    StatusPageUpdateComponentResponseBodyToJSON,
    StatusPageUpdateCustomDomainRequestBody,
    StatusPageUpdateCustomDomainRequestBodyFromJSON,
    StatusPageUpdateCustomDomainRequestBodyToJSON,
    StatusPageUpdateCustomDomainResponseBody,
    StatusPageUpdateCustomDomainResponseBodyFromJSON,
    StatusPageUpdateCustomDomainResponseBodyToJSON,
    StatusPageUpdateEmailBrandingRequestBody,
    StatusPageUpdateEmailBrandingRequestBodyFromJSON,
    StatusPageUpdateEmailBrandingRequestBodyToJSON,
    StatusPageUpdateEmailBrandingResponseBody,
    StatusPageUpdateEmailBrandingResponseBodyFromJSON,
    StatusPageUpdateEmailBrandingResponseBodyToJSON,
    StatusPageUpdateIncidentUpdateRequestBody,
    StatusPageUpdateIncidentUpdateRequestBodyFromJSON,
    StatusPageUpdateIncidentUpdateRequestBodyToJSON,
    StatusPageUpdateIncidentUpdateResponseBody,
    StatusPageUpdateIncidentUpdateResponseBodyFromJSON,
    StatusPageUpdateIncidentUpdateResponseBodyToJSON,
    StatusPageUpdateIntroTextRequestBody,
    StatusPageUpdateIntroTextRequestBodyFromJSON,
    StatusPageUpdateIntroTextRequestBodyToJSON,
    StatusPageUpdateIntroTextResponseBody,
    StatusPageUpdateIntroTextResponseBodyFromJSON,
    StatusPageUpdateIntroTextResponseBodyToJSON,
    StatusPageUpdatePageSetupRequestBody,
    StatusPageUpdatePageSetupRequestBodyFromJSON,
    StatusPageUpdatePageSetupRequestBodyToJSON,
    StatusPageUpdatePageSetupResponseBody,
    StatusPageUpdatePageSetupResponseBodyFromJSON,
    StatusPageUpdatePageSetupResponseBodyToJSON,
    StatusPageUpdateRequestBody,
    StatusPageUpdateRequestBodyFromJSON,
    StatusPageUpdateRequestBodyToJSON,
    StatusPageUpdateResponseBody,
    StatusPageUpdateResponseBodyFromJSON,
    StatusPageUpdateResponseBodyToJSON,
    StatusPageUpdateSubPageRequestBody,
    StatusPageUpdateSubPageRequestBodyFromJSON,
    StatusPageUpdateSubPageRequestBodyToJSON,
    StatusPageUpdateSubPageResponseBody,
    StatusPageUpdateSubPageResponseBodyFromJSON,
    StatusPageUpdateSubPageResponseBodyToJSON,
    StatusPageUpdateSummaryAPIRequestBody,
    StatusPageUpdateSummaryAPIRequestBodyFromJSON,
    StatusPageUpdateSummaryAPIRequestBodyToJSON,
    StatusPageUpdateSummaryAPIResponseBody,
    StatusPageUpdateSummaryAPIResponseBodyFromJSON,
    StatusPageUpdateSummaryAPIResponseBodyToJSON,
    StatusPageUpdateTemplateModeRequestBody,
    StatusPageUpdateTemplateModeRequestBodyFromJSON,
    StatusPageUpdateTemplateModeRequestBodyToJSON,
    StatusPageUpdateTemplateModeResponseBody,
    StatusPageUpdateTemplateModeResponseBodyFromJSON,
    StatusPageUpdateTemplateModeResponseBodyToJSON,
    StatusPageUpdateTemplateRequestBody,
    StatusPageUpdateTemplateRequestBodyFromJSON,
    StatusPageUpdateTemplateRequestBodyToJSON,
    StatusPageUpdateTemplateResponseBody,
    StatusPageUpdateTemplateResponseBodyFromJSON,
    StatusPageUpdateTemplateResponseBodyToJSON,
} from '../models';

export interface StatusPageAddResponseIncidentLinksRequest {
    id: string;
    addResponseIncidentLinksRequestBody: StatusPageAddResponseIncidentLinksRequestBody;
}

export interface StatusPageBuildSubPageStructureRequest {
    definedByCatalogEntryIds: Array<string>;
    parentSplitByCatalogTypeId: string;
    parentSplitByComponentAttributeId: string;
    parentGroupByCatalogId?: string;
}

export interface StatusPageCheckCustomDomainRequest {
    id: string;
}

export interface StatusPageCheckSubpathAvailableRequest {
    checkSubpathAvailableRequestBody: StatusPageCheckSubpathAvailableRequestBody;
}

export interface StatusPageConfigureSubPageUptimesRequest {
    id: string;
    configureSubPageUptimesRequestBody: StatusPageConfigureSubPageUptimesRequestBody;
}

export interface StatusPageCreateRequest {
    createRequestBody: StatusPageCreateRequestBody;
}

export interface StatusPageCreateComponentRequest {
    createComponentRequestBody: StatusPageCreateComponentRequestBody;
}

export interface StatusPageCreateIncidentRequest {
    createIncidentRequestBody: StatusPageCreateIncidentRequestBody;
}

export interface StatusPageCreateIncidentUpdateRequest {
    createIncidentUpdateRequestBody: StatusPageCreateIncidentUpdateRequestBody;
}

export interface StatusPageCreateMaintenanceRequest {
    createMaintenanceRequestBody: StatusPageCreateMaintenanceRequestBody;
}

export interface StatusPageCreateMigrationRequest {
    createMigrationRequestBody: StatusPageCreateMigrationRequestBody;
}

export interface StatusPageCreateRetrospectiveIncidentRequest {
    createRetrospectiveIncidentRequestBody: StatusPageCreateRetrospectiveIncidentRequestBody;
}

export interface StatusPageCreateStructureRequest {
    createStructureRequestBody: StatusPageCreateStructureRequestBody;
}

export interface StatusPageCreateSubPageRequest {
    createSubPageRequestBody: StatusPageCreateSubPageRequestBody;
}

export interface StatusPageCreateTemplateRequest {
    createTemplateRequestBody: StatusPageCreateTemplateRequestBody;
}

export interface StatusPageCreateTestAlertRequest {
    id: string;
}

export interface StatusPageDeleteComponentRequest {
    id: string;
}

export interface StatusPageDeleteCustomDomainRequest {
    id: string;
}

export interface StatusPageDeleteIncidentRequest {
    id: string;
}

export interface StatusPageDeleteIncidentUpdateRequest {
    id: string;
}

export interface StatusPageDeleteSubPageRequest {
    id: string;
}

export interface StatusPageDeleteTemplateRequest {
    id: string;
}

export interface StatusPageDestroyRequest {
    id: string;
}

export interface StatusPageGenerateLogoUploadURLRequest {
    generateLogoUploadURLRequestBody: StatusPageGenerateLogoUploadURLRequestBody;
}

export interface StatusPageGetSubscriberCountForUpdateRequest {
    statusPageId: string;
    statusPageIncidentId?: string;
    componentIds?: Array<string>;
    subPageIds?: Array<string>;
}

export interface StatusPageGoLiveRequest {
    id: string;
    goLiveRequestBody: StatusPageGoLiveRequestBody;
}

export interface StatusPageListIncidentsRequest {
    statusPageId?: string;
    responseIncidentId?: string;
    subPageId?: string;
}

export interface StatusPageListNewlyAffectedSubPagesRequest {
    listNewlyAffectedSubPagesRequestBody: StatusPageListNewlyAffectedSubPagesRequestBody;
}

export interface StatusPageListSubPagesRequest {
    parentStatusPageId: string;
}

export interface StatusPageListSubscriptionsRequest {
    statusPageId: string;
    statusPageIncidentId?: string;
    componentIds?: Array<string>;
    search?: string;
    after?: string;
    pageSize?: number;
}

export interface StatusPageListTemplatesRequest {
    statusPageId: string;
}

export interface StatusPageRemoveIncidentFromSubPageRequest {
    id: string;
    removeIncidentFromSubPageRequestBody: StatusPageRemoveIncidentFromSubPageRequestBody;
}

export interface StatusPageSetIncidentComponentImpactsRequest {
    id: string;
    setIncidentComponentImpactsRequestBody: StatusPageSetIncidentComponentImpactsRequestBody;
}

export interface StatusPageSetIncidentNameRequest {
    id: string;
    setIncidentNameRequestBody: StatusPageSetIncidentNameRequestBody;
}

export interface StatusPageSetStructureAndComponentsRequest {
    statusPageId: string;
    setStructureAndComponentsRequestBody: StatusPageSetStructureAndComponentsRequestBody;
}

export interface StatusPageSetSubscriptionSettingsRequest {
    id: string;
    setSubscriptionSettingsRequestBody: StatusPageSetSubscriptionSettingsRequestBody;
}

export interface StatusPageSetWriteUpRequest {
    id: string;
    setWriteUpRequestBody: StatusPageSetWriteUpRequestBody;
}

export interface StatusPageShowRequest {
    id: string;
}

export interface StatusPageShowIncidentRequest {
    id: string;
}

export interface StatusPageShowMigrationRequest {
    id: string;
}

export interface StatusPageShowStructureRequest {
    statusPageId: string;
}

export interface StatusPageUpdateRequest {
    id: string;
    updateRequestBody: StatusPageUpdateRequestBody;
}

export interface StatusPageUpdateComponentRequest {
    id: string;
    updateComponentRequestBody: StatusPageUpdateComponentRequestBody;
}

export interface StatusPageUpdateCustomDomainRequest {
    id: string;
    updateCustomDomainRequestBody: StatusPageUpdateCustomDomainRequestBody;
}

export interface StatusPageUpdateEmailBrandingRequest {
    id: string;
    updateEmailBrandingRequestBody: StatusPageUpdateEmailBrandingRequestBody;
}

export interface StatusPageUpdateIncidentUpdateRequest {
    id: string;
    updateIncidentUpdateRequestBody: StatusPageUpdateIncidentUpdateRequestBody;
}

export interface StatusPageUpdateIntroTextRequest {
    id: string;
    updateIntroTextRequestBody: StatusPageUpdateIntroTextRequestBody;
}

export interface StatusPageUpdatePageSetupRequest {
    id: string;
    updatePageSetupRequestBody: StatusPageUpdatePageSetupRequestBody;
}

export interface StatusPageUpdateSubPageRequest {
    id: string;
    updateSubPageRequestBody: StatusPageUpdateSubPageRequestBody;
}

export interface StatusPageUpdateSummaryAPIRequest {
    id: string;
    updateSummaryAPIRequestBody: StatusPageUpdateSummaryAPIRequestBody;
}

export interface StatusPageUpdateTemplateRequest {
    id: string;
    updateTemplateRequestBody: StatusPageUpdateTemplateRequestBody;
}

export interface StatusPageUpdateTemplateModeRequest {
    id: string;
    updateTemplateModeRequestBody: StatusPageUpdateTemplateModeRequestBody;
}

export interface StatusPageVisitPageRequest {
    organisationId: string;
    statusPageId: string;
    id: string;
}

/**
 * 
 */
export class StatusPageApi extends runtime.BaseAPI {

    /**
     * Add a link to an internal response incident
     * AddResponseIncidentLinks StatusPage
     */
    async statusPageAddResponseIncidentLinksRaw(requestParameters: StatusPageAddResponseIncidentLinksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageAddResponseIncidentLinksResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageAddResponseIncidentLinks.');
        }

        if (requestParameters.addResponseIncidentLinksRequestBody === null || requestParameters.addResponseIncidentLinksRequestBody === undefined) {
            throw new runtime.RequiredError('addResponseIncidentLinksRequestBody','Required parameter requestParameters.addResponseIncidentLinksRequestBody was null or undefined when calling statusPageAddResponseIncidentLinks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incidents/{id}/actions/add_links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageAddResponseIncidentLinksRequestBodyToJSON(requestParameters.addResponseIncidentLinksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageAddResponseIncidentLinksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Add a link to an internal response incident
     * AddResponseIncidentLinks StatusPage
     */
    async statusPageAddResponseIncidentLinks(requestParameters: StatusPageAddResponseIncidentLinksRequest, initOverrides?: RequestInit): Promise<StatusPageAddResponseIncidentLinksResponseBody> {
        const response = await this.statusPageAddResponseIncidentLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Build an example sub-page structure for a set of catalog entry ids
     * BuildSubPageStructure StatusPage
     */
    async statusPageBuildSubPageStructureRaw(requestParameters: StatusPageBuildSubPageStructureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageBuildSubPageStructureResponseBody>> {
        if (requestParameters.definedByCatalogEntryIds === null || requestParameters.definedByCatalogEntryIds === undefined) {
            throw new runtime.RequiredError('definedByCatalogEntryIds','Required parameter requestParameters.definedByCatalogEntryIds was null or undefined when calling statusPageBuildSubPageStructure.');
        }

        if (requestParameters.parentSplitByCatalogTypeId === null || requestParameters.parentSplitByCatalogTypeId === undefined) {
            throw new runtime.RequiredError('parentSplitByCatalogTypeId','Required parameter requestParameters.parentSplitByCatalogTypeId was null or undefined when calling statusPageBuildSubPageStructure.');
        }

        if (requestParameters.parentSplitByComponentAttributeId === null || requestParameters.parentSplitByComponentAttributeId === undefined) {
            throw new runtime.RequiredError('parentSplitByComponentAttributeId','Required parameter requestParameters.parentSplitByComponentAttributeId was null or undefined when calling statusPageBuildSubPageStructure.');
        }

        const queryParameters: any = {};

        if (requestParameters.definedByCatalogEntryIds) {
            queryParameters['defined_by_catalog_entry_ids'] = requestParameters.definedByCatalogEntryIds;
        }

        if (requestParameters.parentGroupByCatalogId !== undefined) {
            queryParameters['parent_group_by_catalog_id'] = requestParameters.parentGroupByCatalogId;
        }

        if (requestParameters.parentSplitByCatalogTypeId !== undefined) {
            queryParameters['parent_split_by_catalog_type_id'] = requestParameters.parentSplitByCatalogTypeId;
        }

        if (requestParameters.parentSplitByComponentAttributeId !== undefined) {
            queryParameters['parent_split_by_component_attribute_id'] = requestParameters.parentSplitByComponentAttributeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_sub_pages/build_structure`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageBuildSubPageStructureResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build an example sub-page structure for a set of catalog entry ids
     * BuildSubPageStructure StatusPage
     */
    async statusPageBuildSubPageStructure(requestParameters: StatusPageBuildSubPageStructureRequest, initOverrides?: RequestInit): Promise<StatusPageBuildSubPageStructureResponseBody> {
        const response = await this.statusPageBuildSubPageStructureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CheckCustomDomain StatusPage
     */
    async statusPageCheckCustomDomainRaw(requestParameters: StatusPageCheckCustomDomainRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCheckCustomDomainResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageCheckCustomDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_pages/{id}/action/check_custom_domain`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCheckCustomDomainResponseBodyFromJSON(jsonValue));
    }

    /**
     * CheckCustomDomain StatusPage
     */
    async statusPageCheckCustomDomain(requestParameters: StatusPageCheckCustomDomainRequest, initOverrides?: RequestInit): Promise<StatusPageCheckCustomDomainResponseBody> {
        const response = await this.statusPageCheckCustomDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CheckSubpathAvailable StatusPage
     */
    async statusPageCheckSubpathAvailableRaw(requestParameters: StatusPageCheckSubpathAvailableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.checkSubpathAvailableRequestBody === null || requestParameters.checkSubpathAvailableRequestBody === undefined) {
            throw new runtime.RequiredError('checkSubpathAvailableRequestBody','Required parameter requestParameters.checkSubpathAvailableRequestBody was null or undefined when calling statusPageCheckSubpathAvailable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/actions/check_subpath_available`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCheckSubpathAvailableRequestBodyToJSON(requestParameters.checkSubpathAvailableRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * CheckSubpathAvailable StatusPage
     */
    async statusPageCheckSubpathAvailable(requestParameters: StatusPageCheckSubpathAvailableRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageCheckSubpathAvailableRaw(requestParameters, initOverrides);
    }

    /**
     * Toggle whether or not to display uptimes for all components of all sub-pages
     * ConfigureSubPageUptimes StatusPage
     */
    async statusPageConfigureSubPageUptimesRaw(requestParameters: StatusPageConfigureSubPageUptimesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageConfigureSubPageUptimesResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageConfigureSubPageUptimes.');
        }

        if (requestParameters.configureSubPageUptimesRequestBody === null || requestParameters.configureSubPageUptimesRequestBody === undefined) {
            throw new runtime.RequiredError('configureSubPageUptimesRequestBody','Required parameter requestParameters.configureSubPageUptimesRequestBody was null or undefined when calling statusPageConfigureSubPageUptimes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/actions/configure_sub_page_uptimes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageConfigureSubPageUptimesRequestBodyToJSON(requestParameters.configureSubPageUptimesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageConfigureSubPageUptimesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Toggle whether or not to display uptimes for all components of all sub-pages
     * ConfigureSubPageUptimes StatusPage
     */
    async statusPageConfigureSubPageUptimes(requestParameters: StatusPageConfigureSubPageUptimesRequest, initOverrides?: RequestInit): Promise<StatusPageConfigureSubPageUptimesResponseBody> {
        const response = await this.statusPageConfigureSubPageUptimesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create StatusPage
     */
    async statusPageCreateRaw(requestParameters: StatusPageCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling statusPageCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create StatusPage
     */
    async statusPageCreate(requestParameters: StatusPageCreateRequest, initOverrides?: RequestInit): Promise<StatusPageCreateResponseBody> {
        const response = await this.statusPageCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CreateComponent StatusPage
     */
    async statusPageCreateComponentRaw(requestParameters: StatusPageCreateComponentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateComponentResponseBody>> {
        if (requestParameters.createComponentRequestBody === null || requestParameters.createComponentRequestBody === undefined) {
            throw new runtime.RequiredError('createComponentRequestBody','Required parameter requestParameters.createComponentRequestBody was null or undefined when calling statusPageCreateComponent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_components`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateComponentRequestBodyToJSON(requestParameters.createComponentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateComponentResponseBodyFromJSON(jsonValue));
    }

    /**
     * CreateComponent StatusPage
     */
    async statusPageCreateComponent(requestParameters: StatusPageCreateComponentRequest, initOverrides?: RequestInit): Promise<StatusPageCreateComponentResponseBody> {
        const response = await this.statusPageCreateComponentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Status Page incident
     * CreateIncident StatusPage
     */
    async statusPageCreateIncidentRaw(requestParameters: StatusPageCreateIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateIncidentResponseBody>> {
        if (requestParameters.createIncidentRequestBody === null || requestParameters.createIncidentRequestBody === undefined) {
            throw new runtime.RequiredError('createIncidentRequestBody','Required parameter requestParameters.createIncidentRequestBody was null or undefined when calling statusPageCreateIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incidents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateIncidentRequestBodyToJSON(requestParameters.createIncidentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Status Page incident
     * CreateIncident StatusPage
     */
    async statusPageCreateIncident(requestParameters: StatusPageCreateIncidentRequest, initOverrides?: RequestInit): Promise<StatusPageCreateIncidentResponseBody> {
        const response = await this.statusPageCreateIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Post an update to a Status Page incident
     * CreateIncidentUpdate StatusPage
     */
    async statusPageCreateIncidentUpdateRaw(requestParameters: StatusPageCreateIncidentUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateIncidentUpdateResponseBody>> {
        if (requestParameters.createIncidentUpdateRequestBody === null || requestParameters.createIncidentUpdateRequestBody === undefined) {
            throw new runtime.RequiredError('createIncidentUpdateRequestBody','Required parameter requestParameters.createIncidentUpdateRequestBody was null or undefined when calling statusPageCreateIncidentUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incident_updates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateIncidentUpdateRequestBodyToJSON(requestParameters.createIncidentUpdateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateIncidentUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Post an update to a Status Page incident
     * CreateIncidentUpdate StatusPage
     */
    async statusPageCreateIncidentUpdate(requestParameters: StatusPageCreateIncidentUpdateRequest, initOverrides?: RequestInit): Promise<StatusPageCreateIncidentUpdateResponseBody> {
        const response = await this.statusPageCreateIncidentUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Status Page maintenance window
     * CreateMaintenance StatusPage
     */
    async statusPageCreateMaintenanceRaw(requestParameters: StatusPageCreateMaintenanceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateMaintenanceResponseBody>> {
        if (requestParameters.createMaintenanceRequestBody === null || requestParameters.createMaintenanceRequestBody === undefined) {
            throw new runtime.RequiredError('createMaintenanceRequestBody','Required parameter requestParameters.createMaintenanceRequestBody was null or undefined when calling statusPageCreateMaintenance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_maintenances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateMaintenanceRequestBodyToJSON(requestParameters.createMaintenanceRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateMaintenanceResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Status Page maintenance window
     * CreateMaintenance StatusPage
     */
    async statusPageCreateMaintenance(requestParameters: StatusPageCreateMaintenanceRequest, initOverrides?: RequestInit): Promise<StatusPageCreateMaintenanceResponseBody> {
        const response = await this.statusPageCreateMaintenanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a migration from Atlassian Statuspage
     * CreateMigration StatusPage
     */
    async statusPageCreateMigrationRaw(requestParameters: StatusPageCreateMigrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateMigrationResponseBody>> {
        if (requestParameters.createMigrationRequestBody === null || requestParameters.createMigrationRequestBody === undefined) {
            throw new runtime.RequiredError('createMigrationRequestBody','Required parameter requestParameters.createMigrationRequestBody was null or undefined when calling statusPageCreateMigration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_migrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateMigrationRequestBodyToJSON(requestParameters.createMigrationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateMigrationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a migration from Atlassian Statuspage
     * CreateMigration StatusPage
     */
    async statusPageCreateMigration(requestParameters: StatusPageCreateMigrationRequest, initOverrides?: RequestInit): Promise<StatusPageCreateMigrationResponseBody> {
        const response = await this.statusPageCreateMigrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Status Page retrospective incident
     * CreateRetrospectiveIncident StatusPage
     */
    async statusPageCreateRetrospectiveIncidentRaw(requestParameters: StatusPageCreateRetrospectiveIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateRetrospectiveIncidentResponseBody>> {
        if (requestParameters.createRetrospectiveIncidentRequestBody === null || requestParameters.createRetrospectiveIncidentRequestBody === undefined) {
            throw new runtime.RequiredError('createRetrospectiveIncidentRequestBody','Required parameter requestParameters.createRetrospectiveIncidentRequestBody was null or undefined when calling statusPageCreateRetrospectiveIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_retrospective_incidents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateRetrospectiveIncidentRequestBodyToJSON(requestParameters.createRetrospectiveIncidentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateRetrospectiveIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a Status Page retrospective incident
     * CreateRetrospectiveIncident StatusPage
     */
    async statusPageCreateRetrospectiveIncident(requestParameters: StatusPageCreateRetrospectiveIncidentRequest, initOverrides?: RequestInit): Promise<StatusPageCreateRetrospectiveIncidentResponseBody> {
        const response = await this.statusPageCreateRetrospectiveIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CreateStructure StatusPage
     */
    async statusPageCreateStructureRaw(requestParameters: StatusPageCreateStructureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateStructureResponseBody>> {
        if (requestParameters.createStructureRequestBody === null || requestParameters.createStructureRequestBody === undefined) {
            throw new runtime.RequiredError('createStructureRequestBody','Required parameter requestParameters.createStructureRequestBody was null or undefined when calling statusPageCreateStructure.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_structures`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateStructureRequestBodyToJSON(requestParameters.createStructureRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateStructureResponseBodyFromJSON(jsonValue));
    }

    /**
     * CreateStructure StatusPage
     */
    async statusPageCreateStructure(requestParameters: StatusPageCreateStructureRequest, initOverrides?: RequestInit): Promise<StatusPageCreateStructureResponseBody> {
        const response = await this.statusPageCreateStructureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new sub-page.
     * CreateSubPage StatusPage
     */
    async statusPageCreateSubPageRaw(requestParameters: StatusPageCreateSubPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateSubPageResponseBody>> {
        if (requestParameters.createSubPageRequestBody === null || requestParameters.createSubPageRequestBody === undefined) {
            throw new runtime.RequiredError('createSubPageRequestBody','Required parameter requestParameters.createSubPageRequestBody was null or undefined when calling statusPageCreateSubPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_sub_pages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateSubPageRequestBodyToJSON(requestParameters.createSubPageRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateSubPageResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create new sub-page.
     * CreateSubPage StatusPage
     */
    async statusPageCreateSubPage(requestParameters: StatusPageCreateSubPageRequest, initOverrides?: RequestInit): Promise<StatusPageCreateSubPageResponseBody> {
        const response = await this.statusPageCreateSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a template
     * CreateTemplate StatusPage
     */
    async statusPageCreateTemplateRaw(requestParameters: StatusPageCreateTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageCreateTemplateResponseBody>> {
        if (requestParameters.createTemplateRequestBody === null || requestParameters.createTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('createTemplateRequestBody','Required parameter requestParameters.createTemplateRequestBody was null or undefined when calling statusPageCreateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageCreateTemplateRequestBodyToJSON(requestParameters.createTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageCreateTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a template
     * CreateTemplate StatusPage
     */
    async statusPageCreateTemplate(requestParameters: StatusPageCreateTemplateRequest, initOverrides?: RequestInit): Promise<StatusPageCreateTemplateResponseBody> {
        const response = await this.statusPageCreateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CreateTestAlert StatusPage
     */
    async statusPageCreateTestAlertRaw(requestParameters: StatusPageCreateTestAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageCreateTestAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_pages/{id}/actions/create_test_alert`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * CreateTestAlert StatusPage
     */
    async statusPageCreateTestAlert(requestParameters: StatusPageCreateTestAlertRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageCreateTestAlertRaw(requestParameters, initOverrides);
    }

    /**
     * DeleteComponent StatusPage
     */
    async statusPageDeleteComponentRaw(requestParameters: StatusPageDeleteComponentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageDeleteComponent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * DeleteComponent StatusPage
     */
    async statusPageDeleteComponent(requestParameters: StatusPageDeleteComponentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageDeleteComponentRaw(requestParameters, initOverrides);
    }

    /**
     * DeleteCustomDomain StatusPage
     */
    async statusPageDeleteCustomDomainRaw(requestParameters: StatusPageDeleteCustomDomainRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageDeleteCustomDomainResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageDeleteCustomDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_pages/{id}/action/delete_custom_domain`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageDeleteCustomDomainResponseBodyFromJSON(jsonValue));
    }

    /**
     * DeleteCustomDomain StatusPage
     */
    async statusPageDeleteCustomDomain(requestParameters: StatusPageDeleteCustomDomainRequest, initOverrides?: RequestInit): Promise<StatusPageDeleteCustomDomainResponseBody> {
        const response = await this.statusPageDeleteCustomDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a status page incident. Will no longer appear in the dashboard, or the public page.
     * DeleteIncident StatusPage
     */
    async statusPageDeleteIncidentRaw(requestParameters: StatusPageDeleteIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageDeleteIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_incidents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a status page incident. Will no longer appear in the dashboard, or the public page.
     * DeleteIncident StatusPage
     */
    async statusPageDeleteIncident(requestParameters: StatusPageDeleteIncidentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageDeleteIncidentRaw(requestParameters, initOverrides);
    }

    /**
     * Remove an incident update. Component impacts will not be recalculated.
     * DeleteIncidentUpdate StatusPage
     */
    async statusPageDeleteIncidentUpdateRaw(requestParameters: StatusPageDeleteIncidentUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageDeleteIncidentUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_incident_updates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove an incident update. Component impacts will not be recalculated.
     * DeleteIncidentUpdate StatusPage
     */
    async statusPageDeleteIncidentUpdate(requestParameters: StatusPageDeleteIncidentUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageDeleteIncidentUpdateRaw(requestParameters, initOverrides);
    }

    /**
     * Delete sub-page.
     * DeleteSubPage StatusPage
     */
    async statusPageDeleteSubPageRaw(requestParameters: StatusPageDeleteSubPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageDeleteSubPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_sub_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete sub-page.
     * DeleteSubPage StatusPage
     */
    async statusPageDeleteSubPage(requestParameters: StatusPageDeleteSubPageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageDeleteSubPageRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a template
     * DeleteTemplate StatusPage
     */
    async statusPageDeleteTemplateRaw(requestParameters: StatusPageDeleteTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageDeleteTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a template
     * DeleteTemplate StatusPage
     */
    async statusPageDeleteTemplate(requestParameters: StatusPageDeleteTemplateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageDeleteTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Destroy StatusPage
     */
    async statusPageDestroyRaw(requestParameters: StatusPageDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroy StatusPage
     */
    async statusPageDestroy(requestParameters: StatusPageDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Generates a single-use URL that can have a logo image uploaded to it
     * GenerateLogoUploadURL StatusPage
     */
    async statusPageGenerateLogoUploadURLRaw(requestParameters: StatusPageGenerateLogoUploadURLRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageGenerateLogoUploadURLResponseBody>> {
        if (requestParameters.generateLogoUploadURLRequestBody === null || requestParameters.generateLogoUploadURLRequestBody === undefined) {
            throw new runtime.RequiredError('generateLogoUploadURLRequestBody','Required parameter requestParameters.generateLogoUploadURLRequestBody was null or undefined when calling statusPageGenerateLogoUploadURL.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/actions/generate_logo_upload_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageGenerateLogoUploadURLRequestBodyToJSON(requestParameters.generateLogoUploadURLRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageGenerateLogoUploadURLResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generates a single-use URL that can have a logo image uploaded to it
     * GenerateLogoUploadURL StatusPage
     */
    async statusPageGenerateLogoUploadURL(requestParameters: StatusPageGenerateLogoUploadURLRequest, initOverrides?: RequestInit): Promise<StatusPageGenerateLogoUploadURLResponseBody> {
        const response = await this.statusPageGenerateLogoUploadURLRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the number of subscribers that will be notified for a given status page change
     * GetSubscriberCountForUpdate StatusPage
     */
    async statusPageGetSubscriberCountForUpdateRaw(requestParameters: StatusPageGetSubscriberCountForUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageGetSubscriberCountForUpdateResponseBody>> {
        if (requestParameters.statusPageId === null || requestParameters.statusPageId === undefined) {
            throw new runtime.RequiredError('statusPageId','Required parameter requestParameters.statusPageId was null or undefined when calling statusPageGetSubscriberCountForUpdate.');
        }

        const queryParameters: any = {};

        if (requestParameters.statusPageIncidentId !== undefined) {
            queryParameters['status_page_incident_id'] = requestParameters.statusPageIncidentId;
        }

        if (requestParameters.componentIds) {
            queryParameters['component_ids'] = requestParameters.componentIds;
        }

        if (requestParameters.subPageIds) {
            queryParameters['sub_page_ids'] = requestParameters.subPageIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_pages/{status_page_id}/actions/get_subscriber_count_for_update`.replace(`{${"status_page_id"}}`, encodeURIComponent(String(requestParameters.statusPageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageGetSubscriberCountForUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the number of subscribers that will be notified for a given status page change
     * GetSubscriberCountForUpdate StatusPage
     */
    async statusPageGetSubscriberCountForUpdate(requestParameters: StatusPageGetSubscriberCountForUpdateRequest, initOverrides?: RequestInit): Promise<StatusPageGetSubscriberCountForUpdateResponseBody> {
        const response = await this.statusPageGetSubscriberCountForUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark a status page as live
     * GoLive StatusPage
     */
    async statusPageGoLiveRaw(requestParameters: StatusPageGoLiveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageGoLiveResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageGoLive.');
        }

        if (requestParameters.goLiveRequestBody === null || requestParameters.goLiveRequestBody === undefined) {
            throw new runtime.RequiredError('goLiveRequestBody','Required parameter requestParameters.goLiveRequestBody was null or undefined when calling statusPageGoLive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/action/go_live`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageGoLiveRequestBodyToJSON(requestParameters.goLiveRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageGoLiveResponseBodyFromJSON(jsonValue));
    }

    /**
     * Mark a status page as live
     * GoLive StatusPage
     */
    async statusPageGoLive(requestParameters: StatusPageGoLiveRequest, initOverrides?: RequestInit): Promise<StatusPageGoLiveResponseBody> {
        const response = await this.statusPageGoLiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all public status pages
     * List StatusPage
     */
    async statusPageListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_pages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all public status pages
     * List StatusPage
     */
    async statusPageList(initOverrides?: RequestInit): Promise<StatusPageListResponseBody> {
        const response = await this.statusPageListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Atlassian Statuspages which are available to be migrated
     * ListAvailableMigrations StatusPage
     */
    async statusPageListAvailableMigrationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageListAvailableMigrationsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_migrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageListAvailableMigrationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List Atlassian Statuspages which are available to be migrated
     * ListAvailableMigrations StatusPage
     */
    async statusPageListAvailableMigrations(initOverrides?: RequestInit): Promise<StatusPageListAvailableMigrationsResponseBody> {
        const response = await this.statusPageListAvailableMigrationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List incidents for a status page
     * ListIncidents StatusPage
     */
    async statusPageListIncidentsRaw(requestParameters: StatusPageListIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageListIncidentsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.statusPageId !== undefined) {
            queryParameters['status_page_id'] = requestParameters.statusPageId;
        }

        if (requestParameters.responseIncidentId !== undefined) {
            queryParameters['response_incident_id'] = requestParameters.responseIncidentId;
        }

        if (requestParameters.subPageId !== undefined) {
            queryParameters['sub_page_id'] = requestParameters.subPageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_incidents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageListIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incidents for a status page
     * ListIncidents StatusPage
     */
    async statusPageListIncidents(requestParameters: StatusPageListIncidentsRequest, initOverrides?: RequestInit): Promise<StatusPageListIncidentsResponseBody> {
        const response = await this.statusPageListIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List sub-pages newly affected by a list of component impacts.
     * ListNewlyAffectedSubPages StatusPage
     */
    async statusPageListNewlyAffectedSubPagesRaw(requestParameters: StatusPageListNewlyAffectedSubPagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageListNewlyAffectedSubPagesResponseBody>> {
        if (requestParameters.listNewlyAffectedSubPagesRequestBody === null || requestParameters.listNewlyAffectedSubPagesRequestBody === undefined) {
            throw new runtime.RequiredError('listNewlyAffectedSubPagesRequestBody','Required parameter requestParameters.listNewlyAffectedSubPagesRequestBody was null or undefined when calling statusPageListNewlyAffectedSubPages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_sub_pages/actions/list_newly_affected_sub_pages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageListNewlyAffectedSubPagesRequestBodyToJSON(requestParameters.listNewlyAffectedSubPagesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageListNewlyAffectedSubPagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List sub-pages newly affected by a list of component impacts.
     * ListNewlyAffectedSubPages StatusPage
     */
    async statusPageListNewlyAffectedSubPages(requestParameters: StatusPageListNewlyAffectedSubPagesRequest, initOverrides?: RequestInit): Promise<StatusPageListNewlyAffectedSubPagesResponseBody> {
        const response = await this.statusPageListNewlyAffectedSubPagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List sub-pages of a parent page
     * ListSubPages StatusPage
     */
    async statusPageListSubPagesRaw(requestParameters: StatusPageListSubPagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageListSubPagesResponseBody>> {
        if (requestParameters.parentStatusPageId === null || requestParameters.parentStatusPageId === undefined) {
            throw new runtime.RequiredError('parentStatusPageId','Required parameter requestParameters.parentStatusPageId was null or undefined when calling statusPageListSubPages.');
        }

        const queryParameters: any = {};

        if (requestParameters.parentStatusPageId !== undefined) {
            queryParameters['parent_status_page_id'] = requestParameters.parentStatusPageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_sub_pages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageListSubPagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List sub-pages of a parent page
     * ListSubPages StatusPage
     */
    async statusPageListSubPages(requestParameters: StatusPageListSubPagesRequest, initOverrides?: RequestInit): Promise<StatusPageListSubPagesResponseBody> {
        const response = await this.statusPageListSubPagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of subscribers to a status page
     * ListSubscriptions StatusPage
     */
    async statusPageListSubscriptionsRaw(requestParameters: StatusPageListSubscriptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageListSubscriptionsResponseBody>> {
        if (requestParameters.statusPageId === null || requestParameters.statusPageId === undefined) {
            throw new runtime.RequiredError('statusPageId','Required parameter requestParameters.statusPageId was null or undefined when calling statusPageListSubscriptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.statusPageId !== undefined) {
            queryParameters['status_page_id'] = requestParameters.statusPageId;
        }

        if (requestParameters.statusPageIncidentId !== undefined) {
            queryParameters['status_page_incident_id'] = requestParameters.statusPageIncidentId;
        }

        if (requestParameters.componentIds) {
            queryParameters['component_ids'] = requestParameters.componentIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageListSubscriptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a list of subscribers to a status page
     * ListSubscriptions StatusPage
     */
    async statusPageListSubscriptions(requestParameters: StatusPageListSubscriptionsRequest, initOverrides?: RequestInit): Promise<StatusPageListSubscriptionsResponseBody> {
        const response = await this.statusPageListSubscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List templates for a page
     * ListTemplates StatusPage
     */
    async statusPageListTemplatesRaw(requestParameters: StatusPageListTemplatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageListTemplatesResponseBody>> {
        if (requestParameters.statusPageId === null || requestParameters.statusPageId === undefined) {
            throw new runtime.RequiredError('statusPageId','Required parameter requestParameters.statusPageId was null or undefined when calling statusPageListTemplates.');
        }

        const queryParameters: any = {};

        if (requestParameters.statusPageId !== undefined) {
            queryParameters['status_page_id'] = requestParameters.statusPageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageListTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List templates for a page
     * ListTemplates StatusPage
     */
    async statusPageListTemplates(requestParameters: StatusPageListTemplatesRequest, initOverrides?: RequestInit): Promise<StatusPageListTemplatesResponseBody> {
        const response = await this.statusPageListTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an incident from a specific sub-page
     * RemoveIncidentFromSubPage StatusPage
     */
    async statusPageRemoveIncidentFromSubPageRaw(requestParameters: StatusPageRemoveIncidentFromSubPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageRemoveIncidentFromSubPageResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageRemoveIncidentFromSubPage.');
        }

        if (requestParameters.removeIncidentFromSubPageRequestBody === null || requestParameters.removeIncidentFromSubPageRequestBody === undefined) {
            throw new runtime.RequiredError('removeIncidentFromSubPageRequestBody','Required parameter requestParameters.removeIncidentFromSubPageRequestBody was null or undefined when calling statusPageRemoveIncidentFromSubPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incidents/{id}/actions/remove_from_sub_page`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageRemoveIncidentFromSubPageRequestBodyToJSON(requestParameters.removeIncidentFromSubPageRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageRemoveIncidentFromSubPageResponseBodyFromJSON(jsonValue));
    }

    /**
     * Removes an incident from a specific sub-page
     * RemoveIncidentFromSubPage StatusPage
     */
    async statusPageRemoveIncidentFromSubPage(requestParameters: StatusPageRemoveIncidentFromSubPageRequest, initOverrides?: RequestInit): Promise<StatusPageRemoveIncidentFromSubPageResponseBody> {
        const response = await this.statusPageRemoveIncidentFromSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upsert the component impact timeline for an incident
     * SetIncidentComponentImpacts StatusPage
     */
    async statusPageSetIncidentComponentImpactsRaw(requestParameters: StatusPageSetIncidentComponentImpactsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageSetIncidentComponentImpactsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageSetIncidentComponentImpacts.');
        }

        if (requestParameters.setIncidentComponentImpactsRequestBody === null || requestParameters.setIncidentComponentImpactsRequestBody === undefined) {
            throw new runtime.RequiredError('setIncidentComponentImpactsRequestBody','Required parameter requestParameters.setIncidentComponentImpactsRequestBody was null or undefined when calling statusPageSetIncidentComponentImpacts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incidents/{id}/actions/set_component_impacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageSetIncidentComponentImpactsRequestBodyToJSON(requestParameters.setIncidentComponentImpactsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageSetIncidentComponentImpactsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Upsert the component impact timeline for an incident
     * SetIncidentComponentImpacts StatusPage
     */
    async statusPageSetIncidentComponentImpacts(requestParameters: StatusPageSetIncidentComponentImpactsRequest, initOverrides?: RequestInit): Promise<StatusPageSetIncidentComponentImpactsResponseBody> {
        const response = await this.statusPageSetIncidentComponentImpactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the name of a status page incident
     * SetIncidentName StatusPage
     */
    async statusPageSetIncidentNameRaw(requestParameters: StatusPageSetIncidentNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageSetIncidentNameResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageSetIncidentName.');
        }

        if (requestParameters.setIncidentNameRequestBody === null || requestParameters.setIncidentNameRequestBody === undefined) {
            throw new runtime.RequiredError('setIncidentNameRequestBody','Required parameter requestParameters.setIncidentNameRequestBody was null or undefined when calling statusPageSetIncidentName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incidents/{id}/actions/set_name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageSetIncidentNameRequestBodyToJSON(requestParameters.setIncidentNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageSetIncidentNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Sets the name of a status page incident
     * SetIncidentName StatusPage
     */
    async statusPageSetIncidentName(requestParameters: StatusPageSetIncidentNameRequest, initOverrides?: RequestInit): Promise<StatusPageSetIncidentNameResponseBody> {
        const response = await this.statusPageSetIncidentNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the structure for a status page, along with updating all referenced components.  Unlike the CreateStructure method, this transactionally updates the components for the page, deleting any components that are no longer referenced in the structure.
     * SetStructureAndComponents StatusPage
     */
    async statusPageSetStructureAndComponentsRaw(requestParameters: StatusPageSetStructureAndComponentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageSetStructureAndComponentsResponseBody>> {
        if (requestParameters.statusPageId === null || requestParameters.statusPageId === undefined) {
            throw new runtime.RequiredError('statusPageId','Required parameter requestParameters.statusPageId was null or undefined when calling statusPageSetStructureAndComponents.');
        }

        if (requestParameters.setStructureAndComponentsRequestBody === null || requestParameters.setStructureAndComponentsRequestBody === undefined) {
            throw new runtime.RequiredError('setStructureAndComponentsRequestBody','Required parameter requestParameters.setStructureAndComponentsRequestBody was null or undefined when calling statusPageSetStructureAndComponents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{status_page_id}/actions/set_structure_and_components`.replace(`{${"status_page_id"}}`, encodeURIComponent(String(requestParameters.statusPageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageSetStructureAndComponentsRequestBodyToJSON(requestParameters.setStructureAndComponentsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageSetStructureAndComponentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set the structure for a status page, along with updating all referenced components.  Unlike the CreateStructure method, this transactionally updates the components for the page, deleting any components that are no longer referenced in the structure.
     * SetStructureAndComponents StatusPage
     */
    async statusPageSetStructureAndComponents(requestParameters: StatusPageSetStructureAndComponentsRequest, initOverrides?: RequestInit): Promise<StatusPageSetStructureAndComponentsResponseBody> {
        const response = await this.statusPageSetStructureAndComponentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manage settings for status page for subscriptions from end users.
     * SetSubscriptionSettings StatusPage
     */
    async statusPageSetSubscriptionSettingsRaw(requestParameters: StatusPageSetSubscriptionSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageSetSubscriptionSettingsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageSetSubscriptionSettings.');
        }

        if (requestParameters.setSubscriptionSettingsRequestBody === null || requestParameters.setSubscriptionSettingsRequestBody === undefined) {
            throw new runtime.RequiredError('setSubscriptionSettingsRequestBody','Required parameter requestParameters.setSubscriptionSettingsRequestBody was null or undefined when calling statusPageSetSubscriptionSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page/{id}/actions/set_subscription_settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageSetSubscriptionSettingsRequestBodyToJSON(requestParameters.setSubscriptionSettingsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageSetSubscriptionSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Manage settings for status page for subscriptions from end users.
     * SetSubscriptionSettings StatusPage
     */
    async statusPageSetSubscriptionSettings(requestParameters: StatusPageSetSubscriptionSettingsRequest, initOverrides?: RequestInit): Promise<StatusPageSetSubscriptionSettingsResponseBody> {
        const response = await this.statusPageSetSubscriptionSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Link or update a public write-up
     * SetWriteUp StatusPage
     */
    async statusPageSetWriteUpRaw(requestParameters: StatusPageSetWriteUpRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageSetWriteUpResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageSetWriteUp.');
        }

        if (requestParameters.setWriteUpRequestBody === null || requestParameters.setWriteUpRequestBody === undefined) {
            throw new runtime.RequiredError('setWriteUpRequestBody','Required parameter requestParameters.setWriteUpRequestBody was null or undefined when calling statusPageSetWriteUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incidents/{id}/actions/set_write_up`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageSetWriteUpRequestBodyToJSON(requestParameters.setWriteUpRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageSetWriteUpResponseBodyFromJSON(jsonValue));
    }

    /**
     * Link or update a public write-up
     * SetWriteUp StatusPage
     */
    async statusPageSetWriteUp(requestParameters: StatusPageSetWriteUpRequest, initOverrides?: RequestInit): Promise<StatusPageSetWriteUpResponseBody> {
        const response = await this.statusPageSetWriteUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show information for a single status page
     * Show StatusPage
     */
    async statusPageShowRaw(requestParameters: StatusPageShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show information for a single status page
     * Show StatusPage
     */
    async statusPageShow(requestParameters: StatusPageShowRequest, initOverrides?: RequestInit): Promise<StatusPageShowResponseBody> {
        const response = await this.statusPageShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load a status page incident
     * ShowIncident StatusPage
     */
    async statusPageShowIncidentRaw(requestParameters: StatusPageShowIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageShowIncidentResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageShowIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_incidents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageShowIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Load a status page incident
     * ShowIncident StatusPage
     */
    async statusPageShowIncident(requestParameters: StatusPageShowIncidentRequest, initOverrides?: RequestInit): Promise<StatusPageShowIncidentResponseBody> {
        const response = await this.statusPageShowIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check the status of a migration from Atlassian Statuspage
     * ShowMigration StatusPage
     */
    async statusPageShowMigrationRaw(requestParameters: StatusPageShowMigrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageShowMigrationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageShowMigration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_migrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageShowMigrationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Check the status of a migration from Atlassian Statuspage
     * ShowMigration StatusPage
     */
    async statusPageShowMigration(requestParameters: StatusPageShowMigrationRequest, initOverrides?: RequestInit): Promise<StatusPageShowMigrationResponseBody> {
        const response = await this.statusPageShowMigrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ShowStructure StatusPage
     */
    async statusPageShowStructureRaw(requestParameters: StatusPageShowStructureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageShowStructureResponseBody>> {
        if (requestParameters.statusPageId === null || requestParameters.statusPageId === undefined) {
            throw new runtime.RequiredError('statusPageId','Required parameter requestParameters.statusPageId was null or undefined when calling statusPageShowStructure.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_structures/{status_page_id}`.replace(`{${"status_page_id"}}`, encodeURIComponent(String(requestParameters.statusPageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageShowStructureResponseBodyFromJSON(jsonValue));
    }

    /**
     * ShowStructure StatusPage
     */
    async statusPageShowStructure(requestParameters: StatusPageShowStructureRequest, initOverrides?: RequestInit): Promise<StatusPageShowStructureResponseBody> {
        const response = await this.statusPageShowStructureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update StatusPage
     */
    async statusPageUpdateRaw(requestParameters: StatusPageUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling statusPageUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update StatusPage
     */
    async statusPageUpdate(requestParameters: StatusPageUpdateRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateResponseBody> {
        const response = await this.statusPageUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateComponent StatusPage
     */
    async statusPageUpdateComponentRaw(requestParameters: StatusPageUpdateComponentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateComponentResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateComponent.');
        }

        if (requestParameters.updateComponentRequestBody === null || requestParameters.updateComponentRequestBody === undefined) {
            throw new runtime.RequiredError('updateComponentRequestBody','Required parameter requestParameters.updateComponentRequestBody was null or undefined when calling statusPageUpdateComponent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateComponentRequestBodyToJSON(requestParameters.updateComponentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateComponentResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateComponent StatusPage
     */
    async statusPageUpdateComponent(requestParameters: StatusPageUpdateComponentRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateComponentResponseBody> {
        const response = await this.statusPageUpdateComponentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateCustomDomain StatusPage
     */
    async statusPageUpdateCustomDomainRaw(requestParameters: StatusPageUpdateCustomDomainRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateCustomDomainResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateCustomDomain.');
        }

        if (requestParameters.updateCustomDomainRequestBody === null || requestParameters.updateCustomDomainRequestBody === undefined) {
            throw new runtime.RequiredError('updateCustomDomainRequestBody','Required parameter requestParameters.updateCustomDomainRequestBody was null or undefined when calling statusPageUpdateCustomDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/action/update_custom_domain`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateCustomDomainRequestBodyToJSON(requestParameters.updateCustomDomainRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateCustomDomainResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateCustomDomain StatusPage
     */
    async statusPageUpdateCustomDomain(requestParameters: StatusPageUpdateCustomDomainRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateCustomDomainResponseBody> {
        const response = await this.statusPageUpdateCustomDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateEmailBranding StatusPage
     */
    async statusPageUpdateEmailBrandingRaw(requestParameters: StatusPageUpdateEmailBrandingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateEmailBrandingResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateEmailBranding.');
        }

        if (requestParameters.updateEmailBrandingRequestBody === null || requestParameters.updateEmailBrandingRequestBody === undefined) {
            throw new runtime.RequiredError('updateEmailBrandingRequestBody','Required parameter requestParameters.updateEmailBrandingRequestBody was null or undefined when calling statusPageUpdateEmailBranding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/actions/update_email_branding`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateEmailBrandingRequestBodyToJSON(requestParameters.updateEmailBrandingRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateEmailBrandingResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateEmailBranding StatusPage
     */
    async statusPageUpdateEmailBranding(requestParameters: StatusPageUpdateEmailBrandingRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateEmailBrandingResponseBody> {
        const response = await this.statusPageUpdateEmailBrandingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the message and timestamp of an update on a Status Page incident
     * UpdateIncidentUpdate StatusPage
     */
    async statusPageUpdateIncidentUpdateRaw(requestParameters: StatusPageUpdateIncidentUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateIncidentUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateIncidentUpdate.');
        }

        if (requestParameters.updateIncidentUpdateRequestBody === null || requestParameters.updateIncidentUpdateRequestBody === undefined) {
            throw new runtime.RequiredError('updateIncidentUpdateRequestBody','Required parameter requestParameters.updateIncidentUpdateRequestBody was null or undefined when calling statusPageUpdateIncidentUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_incident_updates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateIncidentUpdateRequestBodyToJSON(requestParameters.updateIncidentUpdateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateIncidentUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the message and timestamp of an update on a Status Page incident
     * UpdateIncidentUpdate StatusPage
     */
    async statusPageUpdateIncidentUpdate(requestParameters: StatusPageUpdateIncidentUpdateRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateIncidentUpdateResponseBody> {
        const response = await this.statusPageUpdateIncidentUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateIntroText StatusPage
     */
    async statusPageUpdateIntroTextRaw(requestParameters: StatusPageUpdateIntroTextRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateIntroTextResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateIntroText.');
        }

        if (requestParameters.updateIntroTextRequestBody === null || requestParameters.updateIntroTextRequestBody === undefined) {
            throw new runtime.RequiredError('updateIntroTextRequestBody','Required parameter requestParameters.updateIntroTextRequestBody was null or undefined when calling statusPageUpdateIntroText.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/action/update_intro_text`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateIntroTextRequestBodyToJSON(requestParameters.updateIntroTextRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateIntroTextResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateIntroText StatusPage
     */
    async statusPageUpdateIntroText(requestParameters: StatusPageUpdateIntroTextRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateIntroTextResponseBody> {
        const response = await this.statusPageUpdateIntroTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdatePageSetup StatusPage
     */
    async statusPageUpdatePageSetupRaw(requestParameters: StatusPageUpdatePageSetupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdatePageSetupResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdatePageSetup.');
        }

        if (requestParameters.updatePageSetupRequestBody === null || requestParameters.updatePageSetupRequestBody === undefined) {
            throw new runtime.RequiredError('updatePageSetupRequestBody','Required parameter requestParameters.updatePageSetupRequestBody was null or undefined when calling statusPageUpdatePageSetup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/actions/update_branding`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdatePageSetupRequestBodyToJSON(requestParameters.updatePageSetupRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdatePageSetupResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdatePageSetup StatusPage
     */
    async statusPageUpdatePageSetup(requestParameters: StatusPageUpdatePageSetupRequest, initOverrides?: RequestInit): Promise<StatusPageUpdatePageSetupResponseBody> {
        const response = await this.statusPageUpdatePageSetupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update sub-page.
     * UpdateSubPage StatusPage
     */
    async statusPageUpdateSubPageRaw(requestParameters: StatusPageUpdateSubPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateSubPageResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateSubPage.');
        }

        if (requestParameters.updateSubPageRequestBody === null || requestParameters.updateSubPageRequestBody === undefined) {
            throw new runtime.RequiredError('updateSubPageRequestBody','Required parameter requestParameters.updateSubPageRequestBody was null or undefined when calling statusPageUpdateSubPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_sub_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateSubPageRequestBodyToJSON(requestParameters.updateSubPageRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateSubPageResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update sub-page.
     * UpdateSubPage StatusPage
     */
    async statusPageUpdateSubPage(requestParameters: StatusPageUpdateSubPageRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateSubPageResponseBody> {
        const response = await this.statusPageUpdateSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable or disable the summary API (aka Widget API) for a status page
     * UpdateSummaryAPI StatusPage
     */
    async statusPageUpdateSummaryAPIRaw(requestParameters: StatusPageUpdateSummaryAPIRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateSummaryAPIResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateSummaryAPI.');
        }

        if (requestParameters.updateSummaryAPIRequestBody === null || requestParameters.updateSummaryAPIRequestBody === undefined) {
            throw new runtime.RequiredError('updateSummaryAPIRequestBody','Required parameter requestParameters.updateSummaryAPIRequestBody was null or undefined when calling statusPageUpdateSummaryAPI.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/action/update_summary_api`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateSummaryAPIRequestBodyToJSON(requestParameters.updateSummaryAPIRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateSummaryAPIResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enable or disable the summary API (aka Widget API) for a status page
     * UpdateSummaryAPI StatusPage
     */
    async statusPageUpdateSummaryAPI(requestParameters: StatusPageUpdateSummaryAPIRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateSummaryAPIResponseBody> {
        const response = await this.statusPageUpdateSummaryAPIRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing template
     * UpdateTemplate StatusPage
     */
    async statusPageUpdateTemplateRaw(requestParameters: StatusPageUpdateTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateTemplate.');
        }

        if (requestParameters.updateTemplateRequestBody === null || requestParameters.updateTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateRequestBody','Required parameter requestParameters.updateTemplateRequestBody was null or undefined when calling statusPageUpdateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateTemplateRequestBodyToJSON(requestParameters.updateTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing template
     * UpdateTemplate StatusPage
     */
    async statusPageUpdateTemplate(requestParameters: StatusPageUpdateTemplateRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateTemplateResponseBody> {
        const response = await this.statusPageUpdateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateTemplateMode StatusPage
     */
    async statusPageUpdateTemplateModeRaw(requestParameters: StatusPageUpdateTemplateModeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageUpdateTemplateModeResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageUpdateTemplateMode.');
        }

        if (requestParameters.updateTemplateModeRequestBody === null || requestParameters.updateTemplateModeRequestBody === undefined) {
            throw new runtime.RequiredError('updateTemplateModeRequestBody','Required parameter requestParameters.updateTemplateModeRequestBody was null or undefined when calling statusPageUpdateTemplateMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_pages/{id}/action/update_template_mode`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageUpdateTemplateModeRequestBodyToJSON(requestParameters.updateTemplateModeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageUpdateTemplateModeResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateTemplateMode StatusPage
     */
    async statusPageUpdateTemplateMode(requestParameters: StatusPageUpdateTemplateModeRequest, initOverrides?: RequestInit): Promise<StatusPageUpdateTemplateModeResponseBody> {
        const response = await this.statusPageUpdateTemplateModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Visit a customer status page, automatically authenticating the user to the page.
     * VisitPage StatusPage
     */
    async statusPageVisitPageRaw(requestParameters: StatusPageVisitPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling statusPageVisitPage.');
        }

        if (requestParameters.statusPageId === null || requestParameters.statusPageId === undefined) {
            throw new runtime.RequiredError('statusPageId','Required parameter requestParameters.statusPageId was null or undefined when calling statusPageVisitPage.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statusPageVisitPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_customer_pages/{status_page_id}/{id}/visit`.replace(`{${"status_page_id"}}`, encodeURIComponent(String(requestParameters.statusPageId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Visit a customer status page, automatically authenticating the user to the page.
     * VisitPage StatusPage
     */
    async statusPageVisitPage(requestParameters: StatusPageVisitPageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageVisitPageRaw(requestParameters, initOverrides);
    }

}
