import { ConfigureDrawerProps } from "@incident-shared/integrations";
import {
  Button,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import React from "react";
import { useAPI } from "src/utils/swr";

import { PostmortemDestinationList } from "../../../post-mortem/destination/PostmortemDestinationList";
import { IntegrationConnectedUser } from "../../common/IntegrationConnectedUser";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const GoogleDocsConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const {
    data: { destinations },
    isLoading: loadingDestinations,
    error: destinationsError,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });
  const filteredDestinations = destinations?.filter(
    (d) => d.document_provider === "google_docs",
  );

  const {
    data: configData,
    error: configError,
    isLoading: loadingUser,
  } = useAPI("integrationsGoogleWorkspaceGetConfig", undefined);
  const user = configData?.config?.connecting_user;

  if (destinationsError) {
    return <GenericErrorMessage error={destinationsError || configError} />;
  }

  const loading = loadingUser || loadingDestinations;
  if (loading) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      {user ? (
        <IntegrationConnectedUser
          {...user}
          provider={props.integration.provider}
        />
      ) : undefined}
      {filteredDestinations.length > 0 ? (
        <PostmortemDestinationList destinations={filteredDestinations} />
      ) : (
        <EmptyState
          className="grow-0"
          icon={IconEnum.Folder}
          title="Document destinations"
          content={`You'll need to define at least one post-mortem destination connected to Google Docs to export your documents to.`}
          cta={
            <Button
              analyticsTrackingId={null}
              href="/settings/post-mortem/destinations/create"
            >
              Add destination
            </Button>
          }
        />
      )}
    </GenericConfigureDrawerContents>
  );
};
