import type { SVGProps } from "react";
import * as React from "react";
const SvgAddBelow = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M10.221 10.556H8.665V9a.667.667 0 0 0-1.333 0v1.556H5.777a.667.667 0 0 0 0 1.333h1.555v1.555a.667.667 0 0 0 1.333 0V11.89h1.556a.667.667 0 0 0 0-1.333ZM13.5 2h-11A1.5 1.5 0 0 0 1 3.5v1A1.5 1.5 0 0 0 2.5 6h11A1.5 1.5 0 0 0 15 4.5v-1A1.5 1.5 0 0 0 13.5 2Z" />
  </svg>
);
export default SvgAddBelow;
