import {
  Alert,
  AlertSlimSourceTypeEnum,
  AlertSource,
  AlertSourceSourceTypeEnum,
  AlertSourceTypeEnum,
} from "@incident-io/api";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { AlertSourceTypeIcon } from "src/components/alerts/common/AlertSourceTypeConfigs";
import { tcx } from "src/utils/tailwind-classes";

export function AlertSourceDetails(props: {
  alert: Alert;
  sourceType: AlertSourceTypeEnum | AlertSlimSourceTypeEnum;
  alertSource: AlertSource;
  className?: string;
}) {
  return (
    <div className="inline-flex">
      <AlertSourceTypeIcon
        sourceType={props.sourceType as unknown as AlertSourceSourceTypeEnum}
      />
      {props.alertSource && (
        <div>
          <a
            href={props.alert.source_url}
            target={"_blank"}
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            className={tcx(
              "ml-1 text-content-tertiary flex flex-row items-center",
              {
                "cursor-pointer hover:text-slate-700 hover:underline transition":
                  !!props.alert.source_url,
              },
              props.className,
            )}
          >
            {props.alertSource?.name}
            {props.alert.source_url && (
              <Icon
                id={IconEnum.ExternalLink}
                size={IconSize.Small}
                className={"ml-0.5"}
              />
            )}
          </a>
        </div>
      )}
    </div>
  );
}
