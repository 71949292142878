import { OrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { useIsAuthenticated } from "src/contexts/ClientContext";

export const BaseRoute = (): React.ReactElement => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <OrgAwareNavigate to={"/dashboard"} replace />;
  }

  return <OrgAwareNavigate to={"/login"} replace />;
};
