/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageContentSubPagesStatusSummary,
    StatusPageContentSubPagesStatusSummaryFromJSON,
    StatusPageContentSubPagesStatusSummaryFromJSONTyped,
    StatusPageContentSubPagesStatusSummaryToJSON,
} from './StatusPageContentSubPagesStatusSummary';
import {
    StatusPageContentSummary,
    StatusPageContentSummaryFromJSON,
    StatusPageContentSummaryFromJSONTyped,
    StatusPageContentSummaryToJSON,
} from './StatusPageContentSummary';

/**
 * 
 * @export
 * @interface StatusPageContentShowPageResponseBody
 */
export interface StatusPageContentShowPageResponseBody {
    /**
     * List of sub-pages and their statuses, if this is a parent page
     * @type {Array<StatusPageContentSubPagesStatusSummary>}
     * @memberof StatusPageContentShowPageResponseBody
     */
    subpages?: Array<StatusPageContentSubPagesStatusSummary>;
    /**
     * 
     * @type {StatusPageContentSummary}
     * @memberof StatusPageContentShowPageResponseBody
     */
    summary?: StatusPageContentSummary;
}

export function StatusPageContentShowPageResponseBodyFromJSON(json: any): StatusPageContentShowPageResponseBody {
    return StatusPageContentShowPageResponseBodyFromJSONTyped(json, false);
}

export function StatusPageContentShowPageResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentShowPageResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subpages': !exists(json, 'subpages') ? undefined : ((json['subpages'] as Array<any>).map(StatusPageContentSubPagesStatusSummaryFromJSON)),
        'summary': !exists(json, 'summary') ? undefined : StatusPageContentSummaryFromJSON(json['summary']),
    };
}

export function StatusPageContentShowPageResponseBodyToJSON(value?: StatusPageContentShowPageResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subpages': value.subpages === undefined ? undefined : ((value.subpages as Array<any>).map(StatusPageContentSubPagesStatusSummaryToJSON)),
        'summary': StatusPageContentSummaryToJSON(value.summary),
    };
}

