import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemIncidentMembershipRevoked = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_membership_revoked) {
    throw new Error(
      "malformed timeline item: incident_membership_revoked was missing incident_membership_revoked field",
    );
  }
  const title =
    item.content.incident_membership_revoked.user.name + "'s access revoked";

  return {
    title: title,
    icon: IconEnum.LockClosed,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_membership_revoked.revoker,
  };
};
