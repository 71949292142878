/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserReferencePayload
 */
export interface UserReferencePayload {
    /**
     * The user's email address, matching the email on their Slack account
     * @type {string}
     * @memberof UserReferencePayload
     */
    email?: string;
    /**
     * The incident.io ID of a user
     * @type {string}
     * @memberof UserReferencePayload
     */
    id?: string;
    /**
     * The ID of the user's Slack account.
     * @type {string}
     * @memberof UserReferencePayload
     */
    slack_user_id?: string;
}

export function UserReferencePayloadFromJSON(json: any): UserReferencePayload {
    return UserReferencePayloadFromJSONTyped(json, false);
}

export function UserReferencePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserReferencePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slack_user_id': !exists(json, 'slack_user_id') ? undefined : json['slack_user_id'],
    };
}

export function UserReferencePayloadToJSON(value?: UserReferencePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'id': value.id,
        'slack_user_id': value.slack_user_id,
    };
}

