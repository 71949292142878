/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReport,
    ScheduleReportFromJSON,
    ScheduleReportFromJSONTyped,
    ScheduleReportToJSON,
} from './ScheduleReport';

/**
 * 
 * @export
 * @interface SchedulesShowReportResponseBody
 */
export interface SchedulesShowReportResponseBody {
    /**
     * 
     * @type {ScheduleReport}
     * @memberof SchedulesShowReportResponseBody
     */
    schedule_report: ScheduleReport;
}

export function SchedulesShowReportResponseBodyFromJSON(json: any): SchedulesShowReportResponseBody {
    return SchedulesShowReportResponseBodyFromJSONTyped(json, false);
}

export function SchedulesShowReportResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesShowReportResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_report': ScheduleReportFromJSON(json['schedule_report']),
    };
}

export function SchedulesShowReportResponseBodyToJSON(value?: SchedulesShowReportResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_report': ScheduleReportToJSON(value.schedule_report),
    };
}

