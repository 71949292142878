import { CheckboxGroupV2 } from "@incident-shared/forms/v2/inputs/CheckboxGroupV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { Callout, CalloutTheme, Loader, ModalFooter, Txt } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AvailableReminderTarget,
  RemindersAvailableTargetsReminderTypeEnum,
  RemindersCreateRequestBodyReminderTypeEnum,
  TextNode,
  useClient,
} from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useAPI } from "src/utils/swr";

import FollowUpRemindersScreenshot from "./follow_up_reminders.png";
import PostIncidentTaskRemindersScreenshot from "./post_incident_task_reminders.png";

type ResourceDetails = {
  create: RemindersCreateRequestBodyReminderTypeEnum;
  targets: RemindersAvailableTargetsReminderTypeEnum;
  img: string;
  name: string;
};

const resourceTypeMap: {
  [key in RemindersCreateRequestBodyReminderTypeEnum]: ResourceDetails;
} = {
  [RemindersCreateRequestBodyReminderTypeEnum.FollowUp]: {
    create: RemindersCreateRequestBodyReminderTypeEnum.FollowUp,
    targets: RemindersAvailableTargetsReminderTypeEnum.FollowUp,
    img: FollowUpRemindersScreenshot,
    name: "follow-up",
  },
  [RemindersCreateRequestBodyReminderTypeEnum.PostIncidentTask]: {
    create: RemindersCreateRequestBodyReminderTypeEnum.PostIncidentTask,
    targets: RemindersAvailableTargetsReminderTypeEnum.PostIncidentTask,
    img: PostIncidentTaskRemindersScreenshot,
    name: "post-incident task",
  },
};

type FormData = {
  message: string;
  targets: string[];
};

export const SendRemindersModal = ({
  selectedResourceIDs,
  numResourcesToIgnore,
  onClose,
  onCancel,
  resourceType,
}: {
  selectedResourceIDs: string[];
  numResourcesToIgnore: number;
  onClose: () => void;
  onCancel: () => void;
  resourceType: RemindersCreateRequestBodyReminderTypeEnum;
}): React.ReactElement => {
  const showToast = useToast();

  const formMethods = useForm<FormData>({
    mode: "onSubmit",
  });
  const { watch, setError } = formMethods;

  const selectedTargets = watch("targets");

  const resourceName = resourceTypeMap[resourceType].name;

  const apiClient = useClient();
  const [onSubmit, { saving, genericError }] = useMutation(
    async (data: FormData) =>
      await apiClient.remindersCreate({
        createRequestBody: {
          reminder_type: resourceTypeMap[resourceType].create,
          resource_ids: selectedResourceIDs,
          targets: data.targets,
          message: data.message as unknown as TextNode,
        },
      }),
    {
      onSuccess: () => {
        onClose();
        showToast({
          theme: ToastTheme.Success,
          title: `We've sent reminders for ${
            selectedResourceIDs.length
          } ${resourceName}${selectedResourceIDs.length === 1 ? "" : "s"}`,
        });
      },
      setError: (field, error, opt) => {
        if (error.message === `"message" is missing from body`) {
          error.message = "Please enter a message";
        }

        setError(field, error, opt);
      },
    },
  );

  // Pull the available reminder targets
  const {
    data: { targets: reminderTargets },
    isLoading: targetsLoading,
    error: targetsError,
  } = useAPI(
    "remindersAvailableTargets",
    {
      reminderType: resourceTypeMap[resourceType].targets,
      resourceIds: selectedResourceIDs,
    },
    {
      fallbackData: { targets: [] },
    },
  );
  if (targetsError) {
    return <ErrorModal error={targetsError} onClose={onClose} />;
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      title={"Send Reminder" + (selectedResourceIDs.length > 1 ? "s" : "")}
      analyticsTrackingId={`${resourceName.replace(" ", "-")}-send-reminders`}
      onClose={onCancel}
      loading={targetsLoading}
      isExtraLarge
      footer={
        <ModalFooter
          hideConfirmButton={selectedResourceIDs.length === 0}
          confirmButtonText="Send reminders"
          saving={saving}
          disabled={selectedTargets == null || selectedTargets.length === 0}
          onClose={onCancel}
          confirmButtonType="submit"
        />
      }
    >
      {selectedResourceIDs.length === 0 ? (
        <div className="space-y-4">
          <Txt>
            You have selected {numResourcesToIgnore} {resourceName}
            {numResourcesToIgnore === 1 ? "" : "s"} that are not open.
            We&apos;ll only send reminders for the open {resourceName}s.
          </Txt>
        </div>
      ) : (
        <div className="space-y-4">
          {/* Ignoring 1 follow-up */}
          {numResourcesToIgnore === 1 && (
            <Callout theme={CalloutTheme.Plain}>
              You&apos;ve selected 1 {resourceName} that is not open. We&apos;ll
              only send reminders for the open {resourceName}s.
            </Callout>
          )}
          {/* Ignoring multiple follow-ups */}
          {numResourcesToIgnore > 1 && (
            <Callout theme={CalloutTheme.Plain}>
              You&apos;ve selected {numResourcesToIgnore} {resourceName}s that
              are not open. We&apos;ll only send reminders for the open{" "}
              {resourceName}s.
            </Callout>
          )}
          <SendRemindersModalForm
            targets={reminderTargets}
            numResources={selectedResourceIDs.length}
            resourceType={resourceType}
          />
        </div>
      )}
    </Form.Modal>
  );
};

const SendRemindersModalForm = ({
  targets,
  numResources,
  resourceType,
}: {
  targets: AvailableReminderTarget[] | null;
  numResources: number;
  resourceType: RemindersCreateRequestBodyReminderTypeEnum;
}): React.ReactElement => {
  const formMethods = useFormContext<FormData>();

  const resourceName = resourceTypeMap[resourceType].name;

  if (targets == null) {
    return <Loader />;
  }

  const targetOptions = targets.map((t) => ({
    value: t.reference.key,
    label: t.reference.label,
    helptext: `(${t.distinct_user_count} ${t.reference.label}${
      t.distinct_user_count === 1 ? "" : "s"
    } for your selected ${resourceName}${numResources === 1 ? "" : "s"})`,
    disabled: t.distinct_user_count === 0,
  }));

  return (
    <>
      {targets.length > 0 ? (
        <CheckboxGroupV2
          label={
            "Who should be reminded about " +
            (numResources === 1
              ? `this ${resourceName}?`
              : `these ${resourceName}s?`)
          }
          formMethods={formMethods}
          name="targets"
          className="space-y-2 mt-2"
          options={targetOptions}
        />
      ) : (
        <Callout theme={CalloutTheme.Plain}>
          You can&apos;t remind anyone about{" "}
          {numResources === 1
            ? `this ${resourceName}`
            : `these ${resourceName}s`}
          .
        </Callout>
      )}
      <TemplatedTextInputV2
        name="message"
        formMethods={formMethods}
        label="Your message"
        format="mrkdwn"
        includeVariables={false}
        includeExpressions={false}
      />
      <Form.Helptext>
        {"Your message will be sent to the recipients via Slack and will also include " +
          (numResources === 1
            ? `the ${resourceName}`
            : `the list of ${resourceName}s`) +
          " that you've selected, alongside information about the incident. It'll look something like:"}
      </Form.Helptext>
      <img
        src={resourceTypeMap[resourceType].img}
        alt={"screenshot of an example reminder message in Slack"}
        className={
          "w-[400px] bg-white my-4 mx-auto shadow-lg rounded-2 px-[4px] py-[8px] border border-1 border-stroke"
        }
      />
    </>
  );
};
