import { OrgAwareLink } from "@incident-shared/org-aware";
import { Banner, BannerTheme, Button, ButtonTheme, Link } from "@incident-ui";
import pluralize from "pluralize";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import {
  TrialStatusPlanNameEnum as PlanNameEnum,
  TrialStatusTrialStatusEnum as TrialStatusEnum,
} from "src/contexts/ClientContext";
import { isTrialPlan, TrialData, useTrialData } from "src/utils/trial";
import { assertUnreachable } from "src/utils/utils";

export enum TrialBannerType {
  SalesLedNotStarted = "sales_led_not_started",
  SalesLedActive = "sales_led_active",
  SalesLedGracePeriod = "sales_led_grace_period",
  SalesLedExpired = "sales_led_expired",
  BasicActive = "basic_active",
  BasicGracePeriod = "basic_grace_period",
  BasicExpired = "basic_expired",
}

export const useTrialBanner = () => {
  const trialData = useTrialData();

  if (!trialData) {
    return null;
  }

  const { trialStatus, planName } = trialData;

  let bannerType: TrialBannerType | undefined = undefined;

  if (planName === PlanNameEnum.BasicV2) {
    switch (trialStatus) {
      case TrialStatusEnum.Active:
        bannerType = TrialBannerType.BasicActive;
        break;
      case TrialStatusEnum.GracePeriod:
        bannerType = TrialBannerType.BasicGracePeriod;
        break;
      case TrialStatusEnum.Expired:
        bannerType = TrialBannerType.BasicExpired;
        break;
    }
  }

  // Don't show the banner if the plan isn't a trial.
  if (!isTrialPlan(planName)) {
    return null;
  }

  switch (trialStatus) {
    case TrialStatusEnum.NotStarted:
      bannerType = TrialBannerType.SalesLedNotStarted;
      break;
    case TrialStatusEnum.Active:
      bannerType = TrialBannerType.SalesLedActive;
      break;
    case TrialStatusEnum.GracePeriod:
      bannerType = TrialBannerType.SalesLedGracePeriod;
      break;
    case TrialStatusEnum.Expired:
      bannerType = TrialBannerType.SalesLedExpired;
      break;
  }

  if (!bannerType) {
    // If we didn't resolve a banner type, bad times: return nothing.
    return null;
  }

  return {
    bannerType,
    trialData,
    showWithReinstall: trialStatus === TrialStatusEnum.Expired,
  };
};

type BannerProps = {
  trialData: TrialData;
};

export const TrialBanner = ({
  bannerType,
  trialData,
}: {
  bannerType: TrialBannerType;
  trialData: TrialData;
}) => {
  const location = useLocation();

  // Special case any billing routes, this likely means someone has just paid.
  // unfortunately, the Stripe webhook takes a second for us to process, meaning
  // the banner usually renders when the user is redirected to this page.
  //
  // We also don't want to show the banner on the setup pages.
  if (
    location.pathname.includes("/settings/billing") ||
    location.pathname.includes("/setup/")
  ) {
    return null;
  }

  switch (bannerType) {
    case TrialBannerType.SalesLedNotStarted:
      return <SalesLedNotStartedBanner trialData={trialData} />;
    case TrialBannerType.SalesLedActive:
      return <SalesLedActiveBanner trialData={trialData} />;
    case TrialBannerType.SalesLedGracePeriod:
      return <SalesLedGracePeriodBanner trialData={trialData} />;
    case TrialBannerType.SalesLedExpired:
      return <SalesLedExpiredBanner trialData={trialData} />;
    case TrialBannerType.BasicActive:
      return <BasicActiveBanner trialData={trialData} />;
    case TrialBannerType.BasicGracePeriod:
      return <BasicGracePeriodBanner trialData={trialData} />;
    case TrialBannerType.BasicExpired:
      return <BasicExpiredBanner trialData={trialData} />;
    default:
      assertUnreachable(bannerType);
      return null;
  }
};

const SalesLedNotStartedBanner = (_: BannerProps) => {
  const { showMessages } = useIntercom();

  return (
    <Banner theme={BannerTheme.Info}>
      <>
        Welcome to your incident.io trial. Questions?{" "}
        <Button
          analyticsTrackingId="sales-led-trial-welcome-banner-chat"
          onClick={() => showMessages()}
          theme={ButtonTheme.Unstyled}
          className="font-semibold text-content-primary hover:text-alarmalade-600 underline"
        >
          Get in touch
        </Button>
        , or visit our{" "}
        <Link
          href="https://help.incident.io/en"
          analyticsTrackingId="sales-led-trial-welcome-banner-help"
        >
          help center
        </Link>
        .
      </>
    </Banner>
  );
};

const SalesLedActiveBanner = ({ trialData }: BannerProps) => {
  return (
    <Banner theme={BannerTheme.Info}>
      <>
        You&apos;ve got {pluralize("day", trialData.daysLeft, true)} left in
        your incident.io trial.
      </>
    </Banner>
  );
};

const SalesLedGracePeriodBanner = (_: BannerProps) => {
  const { showMessages } = useIntercom();

  return (
    <Banner theme={BannerTheme.Error}>
      <>
        Your trial has ended.{" "}
        <Button
          analyticsTrackingId="sales-led-trial-grace-period-expired-chat"
          onClick={() => showMessages()}
          theme={ButtonTheme.Unstyled}
          className="font-semibold underline"
        >
          Please get in touch.
        </Button>{" "}
      </>
    </Banner>
  );
};

const SalesLedExpiredBanner = (_: BannerProps) => {
  const { showMessages } = useIntercom();

  return (
    <Banner theme={BannerTheme.Error}>
      <>
        Your trial has expired, and you can no longer declare incidents.{" "}
        <Button
          analyticsTrackingId="sales-led-trial-expired-chat"
          onClick={() => showMessages()}
          theme={ButtonTheme.Unstyled}
          className="font-semibold underline"
        >
          Please get in touch.
        </Button>{" "}
      </>
    </Banner>
  );
};

const BasicActiveBanner = ({ trialData }: BannerProps) => {
  return (
    <Banner theme={BannerTheme.Warning}>
      <>
        You&apos;ve exceeded the limits of your plan please{" "}
        <OrgAwareLink to={"/settings/billing?buy_team"}>upgrade</OrgAwareLink>{" "}
        within the next {pluralize("day", trialData.daysLeft, true)} to continue
        using incident.io
      </>
    </Banner>
  );
};

const BasicGracePeriodBanner = (_: BannerProps) => {
  return (
    <Banner theme={BannerTheme.Error}>
      <>
        You&apos;ve exceeded the limits of your plan for more than 2 weeks
        please{" "}
        <OrgAwareLink to={"/settings/billing?buy_team"}>upgrade</OrgAwareLink>{" "}
        to continue using incident.io
      </>
    </Banner>
  );
};

const BasicExpiredBanner = (_: BannerProps) => {
  return (
    <Banner theme={BannerTheme.Error}>
      <>
        You&apos;ve exceeded the limits of your plan for more than 2 weeks and
        can no longer declare incidents. Please{" "}
        <OrgAwareLink to={"/settings/billing?buy_team"}>upgrade</OrgAwareLink>{" "}
        to continue using incident.io
      </>
    </Banner>
  );
};
