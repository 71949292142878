/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianConnectAppInstallationInfo,
    AtlassianConnectAppInstallationInfoFromJSON,
    AtlassianConnectAppInstallationInfoFromJSONTyped,
    AtlassianConnectAppInstallationInfoToJSON,
} from './AtlassianConnectAppInstallationInfo';

/**
 * 
 * @export
 * @interface AtlassianConnectAppShowInstallationInfoResponseBody
 */
export interface AtlassianConnectAppShowInstallationInfoResponseBody {
    /**
     * 
     * @type {AtlassianConnectAppInstallationInfo}
     * @memberof AtlassianConnectAppShowInstallationInfoResponseBody
     */
    installation_info?: AtlassianConnectAppInstallationInfo;
}

export function AtlassianConnectAppShowInstallationInfoResponseBodyFromJSON(json: any): AtlassianConnectAppShowInstallationInfoResponseBody {
    return AtlassianConnectAppShowInstallationInfoResponseBodyFromJSONTyped(json, false);
}

export function AtlassianConnectAppShowInstallationInfoResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianConnectAppShowInstallationInfoResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'installation_info': !exists(json, 'installation_info') ? undefined : AtlassianConnectAppInstallationInfoFromJSON(json['installation_info']),
    };
}

export function AtlassianConnectAppShowInstallationInfoResponseBodyToJSON(value?: AtlassianConnectAppShowInstallationInfoResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'installation_info': AtlassianConnectAppInstallationInfoToJSON(value.installation_info),
    };
}

