import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { Callout, CalloutTheme, Link, ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentLifecycle,
  IncidentLifecyclesCreateStatusRequestBody,
  IncidentLifecyclesCreateStatusRequestBodyCategoryEnum,
  IncidentStatus,
  IncidentTimestampsCreateRequestBody,
  IncidentTimestampSetByRulePayload,
  IncidentTimestampSetByRulePayloadSetOnTransitionEnum,
  IncidentTimestampSetByRulePayloadSetOnVisitEnum as SetOnVisitEnum,
  PostIncidentFlow,
} from "src/contexts/ClientContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

type FormState = IncidentLifecyclesCreateStatusRequestBody & {
  // Typescript can't deal with the self-referencing type for templated text
  description: { text_node: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const getEditableCategoryConfigs = (): {
  [key in IncidentLifecyclesCreateStatusRequestBodyCategoryEnum]: {
    label: string;
    sort_key: string;
    description: React.ReactNode;
    defaultSetByRuleSettings: Pick<
      IncidentTimestampSetByRulePayload,
      "set_on_visit"
    >;

    backgroundColourClass: string;
  };
} => {
  return {
    [IncidentLifecyclesCreateStatusRequestBodyCategoryEnum.Active]: {
      label: "Active",
      sort_key: "1",
      description: (
        <p>
          Incidents with the following statuses are currently ongoing, and need
          to be driven to a conclusion. Examples include: &quot;Mitigated&quot;,
          &quot;Actively monitoring&quot; and &quot;Passively monitoring&quot;
        </p>
      ),
      defaultSetByRuleSettings: {
        set_on_visit: SetOnVisitEnum.First,
      },
      backgroundColourClass: "bg-brand",
    },
    [IncidentLifecyclesCreateStatusRequestBodyCategoryEnum.PostIncident]: {
      label: "Post-incident",
      sort_key: "2",
      description: (
        <p>
          Incidents with the following statuses have been resolved, but are
          going through a post-incident flow before they are marked as closed.
          Any incidents that match your conditions below will automatically be
          moved into your first post-incident status. Learn more in our{" "}
          <Link
            href="https://help.incident.io/en/articles/8031305"
            analyticsTrackingId="cta-post-incident-flow-convert-help-center"
          >
            help center
          </Link>
          .
        </p>
      ),
      defaultSetByRuleSettings: {
        set_on_visit: SetOnVisitEnum.Last,
      },
      backgroundColourClass: "bg-blue-500",
    },
    [IncidentLifecyclesCreateStatusRequestBodyCategoryEnum.Closed]: {
      label: "Closed",
      sort_key: "3",
      description: (
        <p>Incidents with the following statuses are no longer active.</p>
      ),
      defaultSetByRuleSettings: {
        // For closed statuses, you're more likely to want the _last_ visit to
        // this status, to discard premature-closes.
        set_on_visit: SetOnVisitEnum.Last,
      },
      backgroundColourClass: "bg-blue-900",
    },
  };
};

type Props = { onClose: () => void } & (
  | {
      category: IncidentLifecyclesCreateStatusRequestBodyCategoryEnum.PostIncident;
      lifecycle?: never;
      postIncidentFlow: PostIncidentFlow;
    }
  | {
      category: IncidentLifecyclesCreateStatusRequestBodyCategoryEnum.Active;
      lifecycle: IncidentLifecycle;
      postIncidentFlow?: never;
    }
);

export const IncidentStatusCreateModal = ({
  onClose,
  category,
  lifecycle,
  postIncidentFlow,
}: Props): React.ReactElement | null => {
  const formMethods = useForm<FormState>();
  const EDITABLE_CATEGORY_CONFIGS = getEditableCategoryConfigs();
  const categoryConfig = EDITABLE_CATEGORY_CONFIGS[category];

  function defaultTimestampForStatus(
    status: IncidentStatus,
  ): IncidentTimestampsCreateRequestBody {
    return {
      name: `${status.name} at`,
      description: status.description.text_node,
      set_on_creation: false,
      set_by_rules: [
        {
          set_on_status_id: status.id,

          set_on_transition:
            IncidentTimestampSetByRulePayloadSetOnTransitionEnum.Enter,
          ...categoryConfig.defaultSetByRuleSettings,
        },
      ],
    };
  }

  const refetchTimestamps = useAPIRefetch("incidentTimestampsList", undefined);

  // In general, we think people will want to track most statuses as
  // timestamps, so we create a timestamp that is set based on this status.
  // It's easy to delete it afterwards!
  let shouldAutoCreateTimestamp = true;
  if (lifecycle && lifecycle.is_default) {
    // We don't do this for non-default lifecycles, as we'd like you to
    // choose an existing timestamp instead if possible. No lifecycle means you're
    // creating a post-incident status, and we apply different logic there.
    shouldAutoCreateTimestamp = false;
  } else if (postIncidentFlow != null) {
    // We don't want to create timestamps for the post-incident flow as we don't
    // think they add very much value either.
    shouldAutoCreateTimestamp = false;
  }

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    lifecycle
      ? "incidentLifecyclesList"
      : postIncidentFlow
      ? "postIncidentFlowList"
      : "incidentLifecyclesListAllStatuses",
    undefined,
    async (apiClient, data: FormState) => {
      const { incident_status } =
        await apiClient.incidentLifecyclesCreateStatus({
          createStatusRequestBody: {
            ...data,
            category,
            incident_lifecycle_id: lifecycle?.id,
            post_incident_flow_id: postIncidentFlow?.id,
          },
        });

      if (!shouldAutoCreateTimestamp) {
        return;
      }

      await apiClient.incidentTimestampsCreate({
        createRequestBody: defaultTimestampForStatus(incident_status),
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: async () => {
        await Promise.all([refetchTimestamps()]);
        onClose();
      },
    },
  );

  return (
    <Form.Modal<FormState>
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      analyticsTrackingId="create-incident-status"
      title={`Create ${categoryConfig.label} status`}
      disableQuickClose
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText="Create"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        name="name"
        required="Please provide a name"
        label="Name"
      />
      <TemplatedTextInputV2
        formMethods={formMethods}
        includeVariables={false}
        includeExpressions={false}
        name="description.text_node"
        label="Description"
        placeholder="e.g. We've identified what's wrong, and we're in the process of fixing it."
        helptext="This will be displayed to help a user choose the correct status."
        format="mrkdwn"
        required="Please provide a description"
        multiLine
      />
      {shouldAutoCreateTimestamp && (
        <Callout theme={CalloutTheme.Plain} className="text-slate-700">
          {"We'll automatically create a matching timestamp for you."}
        </Callout>
      )}
    </Form.Modal>
  );
};
