import type { SVGProps } from "react";
import * as React from "react";
const SvgNotion = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#fff"
      d="m3.263 2.69 8.853-.654c1.087-.093 1.367-.03 2.05.467l2.827 1.99c.466.343.62.436.62.81V16.22c0 .684-.248 1.089-1.117 1.15l-10.281.623c-.653.031-.964-.062-1.306-.498L2.828 14.79c-.373-.498-.528-.87-.528-1.307V3.778c0-.56.249-1.026.963-1.088Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="m12.116 2.036-8.853.654c-.714.062-.963.529-.963 1.088v9.705c0 .436.155.809.528 1.307l2.081 2.706c.342.436.653.53 1.306.498l10.28-.622c.87-.062 1.119-.467 1.119-1.151V5.302c0-.353-.14-.455-.55-.757l-2.898-2.042c-.683-.497-.963-.56-2.05-.467ZM6.447 5.124c-.84.056-1.03.069-1.507-.319l-1.212-.964c-.123-.125-.06-.28.25-.311l8.51-.622c.715-.063 1.087.186 1.367.404l1.46 1.058c.062.031.217.217.03.217l-8.789.53-.109.007Zm-.979 11.004v-9.27c0-.404.125-.59.497-.622l10.095-.591c.342-.031.497.186.497.59v9.208c0 .405-.063.747-.621.778l-9.66.56c-.56.03-.807-.155-.807-.653Zm9.536-8.772c.062.28 0 .56-.28.592l-.465.092v6.843c-.405.218-.777.342-1.088.342-.497 0-.621-.155-.993-.622l-3.045-4.79v4.635l.963.218s0 .56-.777.56l-2.142.124c-.063-.125 0-.436.217-.498l.56-.155V8.57l-.777-.062c-.063-.28.093-.685.528-.716l2.298-.155 3.168 4.852V8.196l-.807-.093c-.063-.343.186-.592.496-.623l2.144-.124Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNotion;
