/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackEnterpriseSettings,
    SlackEnterpriseSettingsFromJSON,
    SlackEnterpriseSettingsFromJSONTyped,
    SlackEnterpriseSettingsToJSON,
} from './SlackEnterpriseSettings';

/**
 * 
 * @export
 * @interface SettingsGetSlackEnterpriseSettingsResponseBody
 */
export interface SettingsGetSlackEnterpriseSettingsResponseBody {
    /**
     * 
     * @type {SlackEnterpriseSettings}
     * @memberof SettingsGetSlackEnterpriseSettingsResponseBody
     */
    slack_enterprise_settings: SlackEnterpriseSettings;
}

export function SettingsGetSlackEnterpriseSettingsResponseBodyFromJSON(json: any): SettingsGetSlackEnterpriseSettingsResponseBody {
    return SettingsGetSlackEnterpriseSettingsResponseBodyFromJSONTyped(json, false);
}

export function SettingsGetSlackEnterpriseSettingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsGetSlackEnterpriseSettingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slack_enterprise_settings': SlackEnterpriseSettingsFromJSON(json['slack_enterprise_settings']),
    };
}

export function SettingsGetSlackEnterpriseSettingsResponseBodyToJSON(value?: SettingsGetSlackEnterpriseSettingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slack_enterprise_settings': SlackEnterpriseSettingsToJSON(value.slack_enterprise_settings),
    };
}

