import React from "react";
import { Route, Routes } from "react-router";

import { TeamsChannelPage } from "./TeamsChannelPage";

export const TeamsChannelRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<TeamsChannelPage />} />
    </Routes>
  );
};
