import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { ModalFooter } from "@incident-ui";
import { PopoverSingleSelect } from "@incident-ui";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  EngineScope,
  ExpressionBranch,
  PostIncidentFlow,
} from "src/contexts/ClientContext";

import {
  PostIncidentExpressionFormData,
  usePostIncidentBranchResult,
} from "./formHooks";

export const CreateEditRuleModal = ({
  scope,
  flows,
  onClose,
  formMethods,
  branchIndex,
}: {
  scope: EngineScope;
  flows: PostIncidentFlow[];
  onClose: () => void;
  formMethods: UseFormReturn<PostIncidentExpressionFormData>;
} & (
  | {
      branchIndex: number;
    }
  | {
      branchIndex?: never;
    }
)) => {
  const editMode = typeof branchIndex === "number";
  const branchFormMethods = useForm<ExpressionBranch>({
    defaultValues: editMode
      ? {
          ...formMethods.watch(`expression.branches.${branchIndex}`),
        }
      : {
          condition_groups: [
            {
              conditions: [],
            },
          ],
          result: {
            array_value: [{ literal: flows[0].id }],
          },
        },
  });
  const { isRequired, setIsRequired, flowIDs, setFlowIDs } =
    usePostIncidentBranchResult(branchFormMethods, "result");

  return (
    <Form.Modal
      analyticsTrackingId={null}
      onClose={onClose}
      title={editMode ? "Edit rule" : "Add rule"}
      formMethods={branchFormMethods}
      onSubmit={(data) => {
        if (editMode) {
          formMethods.setValue<`expression.branches.${number}`>(
            `expression.branches.${branchIndex}`,
            data,
          );
        } else {
          const branchIndex =
            formMethods.watch("expression.branches")?.length ?? 0;
          formMethods.setValue<`expression.branches.${number}`>(
            `expression.branches.${branchIndex}`,
            data,
          );
        }
        onClose();
      }}
      footer={
        <ModalFooter
          confirmButtonText={editMode ? "Update" : "Add"}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <ConditionGroupsEditorV2
        formMethods={branchFormMethods}
        name="condition_groups"
        allowFilteringByExpression={false}
        emptyIntroSentence={
          <>
            Add conditions to specify which incidents this rule should apply to
          </>
        }
        populatedIntroSentence="For incidents that match the following rules..."
        scope={scope}
        rules={{ minLength: 1 }}
        required
      />
      <div className="flex-wrap text-content-primary">
        Responders will{" "}
        <div className="inline-flex">
          <PopoverSingleSelect
            options={[
              {
                value: "required",
                label: "be required",
              },
              {
                value: "not_required",
                label: "have the choice",
              },
            ]}
            value={isRequired ? "required" : "not_required"}
            onChange={(val) => setIsRequired(val === "required")}
          />
        </div>{" "}
        to enter the{" "}
        <div className="inline-flex">
          <PopoverSingleSelect
            onChange={(val) => setFlowIDs(val ? [val] : [])}
            options={flows.map((flow) => ({
              value: flow.id,
              label: flow.name,
            }))}
            value={flowIDs[0] ?? flows[0].id}
          />
        </div>
        &nbsp;post-incident flow
      </div>
    </Form.Modal>
  );
};
