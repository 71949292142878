import {
  EngineParam,
  EngineScope,
  Reference,
  Resource,
} from "@incident-io/api";
import { EnrichedScope, filterScope } from "src/utils/scope";
import { sendToSentry } from "src/utils/utils";

import { WorkflowStep } from "../common/types";
import { rehydrateStepParamBindings } from "../common/utils";

export const prepareStep = ({
  data,
  scope,
  resources,
}: {
  data: WorkflowStep;
  scope: EngineScope;
  resources: Resource[];
}): WorkflowStep => {
  data.param_bindings = data.param_bindings || [];
  const { param_bindings, params } = data;

  params?.forEach((param, idx) => {
    if (!param.infer_reference) {
      return;
    }

    // If there's a param that needs to infer a reference and there isn't
    // already a param_binding with the correct type, add one.
    // These params are hidden from the user (they're inferred from the trigger
    // scope) but we do want to send the value to the backend so that the
    // reference is available to the engine.
    const relevantReference = findInferReferenceParamInScope(scope, param);
    if (relevantReference == null) {
      throw sendToSentry(
        "unreachable: no relevant reference provided by the scope, but there is an infer_reference param.",
        { params, scope },
      );
    }
    param_bindings[idx] = {
      value: {
        reference: relevantReference?.key,
        label: relevantReference?.label ?? "",
        sort_key: relevantReference?.key,
      },
    };
    data = {
      ...data,
      param_bindings,
    };
  });

  data = rehydrateStepParamBindings(data, resources);

  // Remove empty values from array params
  data.param_bindings?.forEach((binding, idx) => {
    const param = data.params[idx];
    if (param.array) {
      binding.array_value = binding.array_value?.filter(
        (x) => x.literal || x.reference,
      );
    }
  });

  return data;
};

export const findInferReferenceParamInScope = (
  scope: EnrichedScope<Reference>,
  param: EngineParam,
) => {
  const filteredScope = filterScope(
    scope,
    (ref) => ref.array === param.array && ref.type === param.type,
  );
  if (filteredScope.references.length === 1) {
    return filteredScope.references[0];
  }
  return null;
};
