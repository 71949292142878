import type { SVGProps } from "react";
import * as React from "react";
const SvgPingdom = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#FF6200"
      fillRule="evenodd"
      d="m9.617 9.56.138-.043a25.243 25.243 0 0 0 3.014-1.202 10.943 10.943 0 0 0 2.774-1.718 6.578 6.578 0 0 0 1.945-2.99c.08-.195.14-.398.176-.607-1.349 2.004-6.695 2.213-6.695 2.213l1.262-1.258C8.558 3.969 4.981 5.23 2 7.565c2.02.236 3.919 1.16 5.426 2.639.724-.244 1.464-.418 2.191-.638V9.56ZM18 9.36c-3.402-.171-6.805.3-10.058 1.395a10.598 10.598 0 0 1 1.691 2.624c2.694-1.631 6.43-3.715 8.367-4.012V9.36Zm-8.027 4.852c.32.895.524 1.833.608 2.788l4.7-5.252-5.308 2.464Zm2.466 2.576v-.056a.126.126 0 0 0-.019-.063.059.059 0 0 0-.02-.02.054.054 0 0 0-.028-.008h.042a.092.092 0 0 0 .029-.036.102.102 0 0 0 .009-.047.109.109 0 0 0-.013-.063.097.097 0 0 0-.045-.042.244.244 0 0 0-.099 0h-.131v.394h.07v-.143h.051a.13.13 0 0 1 .068 0 .09.09 0 0 1 .029.08v.059h.07l-.013-.055Zm-.208-.307h.045a.15.15 0 0 1 .074 0c.014.011.02.03.019.049a.056.056 0 0 1-.007.034.049.049 0 0 1-.025.021.17.17 0 0 1-.058 0h-.048v-.132.028Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPingdom;
