/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentUpdatesListForIncidentResponseBody,
    IncidentUpdatesListForIncidentResponseBodyFromJSON,
    IncidentUpdatesListForIncidentResponseBodyToJSON,
    IncidentUpdatesRequestRequestBody,
    IncidentUpdatesRequestRequestBodyFromJSON,
    IncidentUpdatesRequestRequestBodyToJSON,
    IncidentUpdatesUpdateRequestBody,
    IncidentUpdatesUpdateRequestBodyFromJSON,
    IncidentUpdatesUpdateRequestBodyToJSON,
    IncidentUpdatesUpdateResponseBody,
    IncidentUpdatesUpdateResponseBodyFromJSON,
    IncidentUpdatesUpdateResponseBodyToJSON,
} from '../models';

export interface IncidentUpdatesListForIncidentRequest {
    incidentId: string;
}

export interface IncidentUpdatesRequestRequest {
    requestRequestBody: IncidentUpdatesRequestRequestBody;
}

export interface IncidentUpdatesUpdateRequest {
    id: string;
    updateRequestBody: IncidentUpdatesUpdateRequestBody;
}

/**
 * 
 */
export class IncidentUpdatesApi extends runtime.BaseAPI {

    /**
     * Lists incident updates and status page incident updates for an incident
     * ListForIncident IncidentUpdates
     */
    async incidentUpdatesListForIncidentRaw(requestParameters: IncidentUpdatesListForIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentUpdatesListForIncidentResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentUpdatesListForIncident.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_updates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentUpdatesListForIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists incident updates and status page incident updates for an incident
     * ListForIncident IncidentUpdates
     */
    async incidentUpdatesListForIncident(requestParameters: IncidentUpdatesListForIncidentRequest, initOverrides?: RequestInit): Promise<IncidentUpdatesListForIncidentResponseBody> {
        const response = await this.incidentUpdatesListForIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request an incident update
     * Request IncidentUpdates
     */
    async incidentUpdatesRequestRaw(requestParameters: IncidentUpdatesRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestRequestBody === null || requestParameters.requestRequestBody === undefined) {
            throw new runtime.RequiredError('requestRequestBody','Required parameter requestParameters.requestRequestBody was null or undefined when calling incidentUpdatesRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_updates/actions/request_update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentUpdatesRequestRequestBodyToJSON(requestParameters.requestRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request an incident update
     * Request IncidentUpdates
     */
    async incidentUpdatesRequest(requestParameters: IncidentUpdatesRequestRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentUpdatesRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Edit an existing incident update
     * Update IncidentUpdates
     */
    async incidentUpdatesUpdateRaw(requestParameters: IncidentUpdatesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentUpdatesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentUpdatesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling incidentUpdatesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_updates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentUpdatesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentUpdatesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Edit an existing incident update
     * Update IncidentUpdates
     */
    async incidentUpdatesUpdate(requestParameters: IncidentUpdatesUpdateRequest, initOverrides?: RequestInit): Promise<IncidentUpdatesUpdateResponseBody> {
        const response = await this.incidentUpdatesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
