/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageComponent,
    StatusPageComponentFromJSON,
    StatusPageComponentFromJSONTyped,
    StatusPageComponentToJSON,
} from './StatusPageComponent';

/**
 * 
 * @export
 * @interface StatusPageSubscription
 */
export interface StatusPageSubscription {
    /**
     * The list of components to which the person is subscribed
     * @type {Array<StatusPageComponent>}
     * @memberof StatusPageSubscription
     */
    components: Array<StatusPageComponent>;
    /**
     * The email address of the subscriber
     * @type {string}
     * @memberof StatusPageSubscription
     */
    email: string;
    /**
     * The unique ID of the status page subscription
     * @type {string}
     * @memberof StatusPageSubscription
     */
    id: string;
    /**
     * The status page this subscription is for
     * @type {string}
     * @memberof StatusPageSubscription
     */
    status_page_id: string;
    /**
     * The incident to which the person is subscribed
     * @type {string}
     * @memberof StatusPageSubscription
     */
    status_page_incident_id?: string;
    /**
     * The name of the sub-page this subscription is for
     * @type {string}
     * @memberof StatusPageSubscription
     */
    sub_page_name?: string;
    /**
     * When the subscription email was verified
     * @type {Date}
     * @memberof StatusPageSubscription
     */
    verified_at: Date;
}

export function StatusPageSubscriptionFromJSON(json: any): StatusPageSubscription {
    return StatusPageSubscriptionFromJSONTyped(json, false);
}

export function StatusPageSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'components': ((json['components'] as Array<any>).map(StatusPageComponentFromJSON)),
        'email': json['email'],
        'id': json['id'],
        'status_page_id': json['status_page_id'],
        'status_page_incident_id': !exists(json, 'status_page_incident_id') ? undefined : json['status_page_incident_id'],
        'sub_page_name': !exists(json, 'sub_page_name') ? undefined : json['sub_page_name'],
        'verified_at': (new Date(json['verified_at'])),
    };
}

export function StatusPageSubscriptionToJSON(value?: StatusPageSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'components': ((value.components as Array<any>).map(StatusPageComponentToJSON)),
        'email': value.email,
        'id': value.id,
        'status_page_id': value.status_page_id,
        'status_page_incident_id': value.status_page_incident_id,
        'sub_page_name': value.sub_page_name,
        'verified_at': (value.verified_at.toISOString()),
    };
}

