import * as Sentry from "@sentry/react";

import { UnexpectedError } from "./UnexpectedError";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withSentryErrorBoundary = <P extends Record<string, any>>(
  wrappedComponent: React.ComponentType<P>,
  componentName: string,
): React.FC<P> => {
  return Sentry.withErrorBoundary(wrappedComponent, {
    fallback: UnexpectedError,
    beforeCapture: (scope) => {
      scope.setTag("componentName", componentName);
    },
  });
};
