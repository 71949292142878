/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Settings,
    SettingsFromJSON,
    SettingsFromJSONTyped,
    SettingsToJSON,
} from './Settings';

/**
 * 
 * @export
 * @interface SettingsUpdateShoutoutChannelResponseBody
 */
export interface SettingsUpdateShoutoutChannelResponseBody {
    /**
     * 
     * @type {Settings}
     * @memberof SettingsUpdateShoutoutChannelResponseBody
     */
    settings: Settings;
}

export function SettingsUpdateShoutoutChannelResponseBodyFromJSON(json: any): SettingsUpdateShoutoutChannelResponseBody {
    return SettingsUpdateShoutoutChannelResponseBodyFromJSONTyped(json, false);
}

export function SettingsUpdateShoutoutChannelResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdateShoutoutChannelResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': SettingsFromJSON(json['settings']),
    };
}

export function SettingsUpdateShoutoutChannelResponseBodyToJSON(value?: SettingsUpdateShoutoutChannelResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': SettingsToJSON(value.settings),
    };
}

