import {
  ExploDashboardWithDrilldownInfo,
  PanelVariable,
} from "@incident-io/api";
import {
  DateAggregationMap,
  ExploDashboard,
} from "@incident-shared/explo/ExploDashboard";
import { useGetContextForFilter } from "@incident-shared/filters";
import { dateRangePickerStateToTimestamps } from "@incident-shared/forms/v1/DateRangePicker";
import { calculatePreviousPeriod } from "@incident-shared/insights/calculatePreviousPeriod";
import { GenericErrorMessage } from "@incident-ui";
import { Loader } from "@incident-ui/Loader/Loader";

import { TrendsTiles } from "../trends/TrendsTiles";
import { exploVariablesFrom, stripTimezone } from "./ExploDashboardPanel";
import { PanelVariableInputs } from "./PanelVariables";
import { ComparisonModeHeading, PanelWrapper } from "./PanelWrapper";
import { useDashboardAndPanelFilters } from "./useDashboardAndPanelFilters";
import {
  DashboardViewMode,
  PanelVariableFormData,
  useInsightsContext,
} from "./useInsightsContext";

export const ExploDashboardWithDrilldownPanel = ({
  drilldownInfo,
  panelIdx,
  panelName,
  variableData,
  variables,
}: {
  drilldownInfo: ExploDashboardWithDrilldownInfo;
  panelIdx: number;
  panelName: string;
  variableData: PanelVariableFormData;
  variables: PanelVariable[];
}) => {
  const {
    title,
    description,
    top_half_explo_dashboard_id,
    top_half_initial_height,
    drilldown_explo_dashboard_id,
    drilldown_initial_height,
  } = drilldownInfo;

  const { dateRange, viewMode } = useInsightsContext();

  const isComparisonMode = dateRange.is_comparison;

  const { from: startDate, to: endDate } = dateRangePickerStateToTimestamps(
    dateRange.range,
  );
  const { prevEndDate, prevStartDate } = calculatePreviousPeriod(
    startDate,
    endDate,
  );

  // For now going to use an offset of '+00:00' to make sure we don't do any
  // timezone calculations within Explo in order to show everything in UTC
  const timeZoneOffset = "+00:00";

  const filters = useDashboardAndPanelFilters(panelIdx);

  const {
    isLoading: contextLoading,
    error: contextError,
    getContextForFilter,
  } = useGetContextForFilter();

  if (contextLoading || !getContextForFilter) {
    return <Loader />;
  }
  if (contextError) {
    return <GenericErrorMessage error={contextError} />;
  }

  const filterJSON = JSON.stringify(filters);

  const topHalfDashboardProps = {
    dashboardEmbedID: top_half_explo_dashboard_id,
    initialHeight: top_half_initial_height,
    dashboardVariables: {
      filter_json: filterJSON,
      timezone_offset: timeZoneOffset, // used to truncate dates properly
      // Strip the timezone information from the datetime as we're only dealing with UTC
      start_date: stripTimezone(startDate),
      end_date: stripTimezone(endDate),
      date_aggregation: DateAggregationMap[dateRange.aggregation],
    },
  };

  const drilldownDashboardProps = {
    dashboardEmbedID: drilldown_explo_dashboard_id,
    initialHeight: drilldown_initial_height,
    dashboardVariables: {
      filter_json: filterJSON,
      timezone_offset: timeZoneOffset, // used to truncate dates properly
      // Strip the timezone information from the datetime as we're only dealing with UTC
      start_date: stripTimezone(startDate),
      end_date: stripTimezone(endDate),
      date_aggregation: DateAggregationMap[dateRange.aggregation],
      ...exploVariablesFrom(variables, variableData),
    },
  };

  return (
    <PanelWrapper
      title={title}
      description={description}
      panelIdx={panelIdx}
      // We handle our variables ourselves, so they're between the two Explo dashboards
      variables={[]}
      trends={
        <TrendsTiles
          dateRange={dateRange.range}
          filters={filters.length > 0 ? filterJSON : undefined}
          panelID={panelName}
          // TODO: onClick -> enable comparison mode.
          smaller={true}
          reverse={true}
        />
      }
    >
      <div className="flex-1 flex-col gap-3 w-auto">
        {/* Date range badges */}
        {isComparisonMode && (
          <ComparisonModeHeading startDate={startDate} endDate={endDate} />
        )}
        {isComparisonMode ? (
          <div className="flex flex-col gap-6">
            <div className="flex flex-row gap-4 w-full">
              {/* The two side by side dashboards */}
              <div className="w-1/2">
                <ExploDashboard
                  {...topHalfDashboardProps}
                  dashboardVariables={{
                    ...topHalfDashboardProps.dashboardVariables,
                    // Strip the timezone information from the datetime as we're only dealing with UTC
                    start_date: stripTimezone(prevStartDate),
                    end_date: stripTimezone(prevEndDate),
                  }}
                />
              </div>
              <div className="w-1/2">
                <ExploDashboard {...topHalfDashboardProps} />
              </div>
            </div>
            <div className="pt-4">
              <PanelVariableInputs
                variables={variables}
                panelIdx={panelIdx}
                editable={
                  viewMode === DashboardViewMode.EditDashboard ||
                  viewMode === DashboardViewMode.EditFiltersAndVariables
                }
              />
            </div>
            <div className="flex flex-row gap-4 w-full">
              <div className="w-1/2">
                <ExploDashboard
                  {...drilldownDashboardProps}
                  dashboardVariables={{
                    ...drilldownDashboardProps.dashboardVariables,
                    // Strip the timezone information from the datetime as we're only dealing with UTC
                    start_date: stripTimezone(prevStartDate),
                    end_date: stripTimezone(prevEndDate),
                  }}
                />
              </div>
              <div className="w-1/2">
                <ExploDashboard {...drilldownDashboardProps} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <ExploDashboard {...topHalfDashboardProps} />
            <div className="pt-4">
              <PanelVariableInputs
                variables={variables}
                panelIdx={panelIdx}
                editable={
                  viewMode === DashboardViewMode.EditDashboard ||
                  viewMode === DashboardViewMode.EditFiltersAndVariables
                }
              />
            </div>
            <ExploDashboard {...drilldownDashboardProps} />
          </div>
        )}
      </div>
    </PanelWrapper>
  );
};
