/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOption,
    SelectOptionFromJSON,
    SelectOptionFromJSONTyped,
    SelectOptionToJSON,
} from './SelectOption';
import {
    SelectOptionGroup,
    SelectOptionGroupFromJSON,
    SelectOptionGroupFromJSONTyped,
    SelectOptionGroupToJSON,
} from './SelectOptionGroup';

/**
 * 
 * @export
 * @interface SelectConfig
 */
export interface SelectConfig {
    /**
     * 
     * @type {SelectOption}
     * @memberof SelectConfig
     */
    initial_option?: SelectOption;
    /**
     * 
     * @type {Array<SelectOptionGroup>}
     * @memberof SelectConfig
     */
    option_groups?: Array<SelectOptionGroup>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof SelectConfig
     */
    options?: Array<SelectOption>;
    /**
     * Placeholder to render in the select
     * @type {string}
     * @memberof SelectConfig
     */
    placeholder: string;
    /**
     * Unique string that the UI should use to get typeahead options for this select
     * @type {string}
     * @memberof SelectConfig
     */
    typeahead_type?: SelectConfigTypeaheadTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum SelectConfigTypeaheadTypeEnum {
    User = 'user',
    MicrosoftTeamsUser = 'microsoft_teams_user',
    OnCallUser = 'on_call_user',
    SlackUser = 'slack_user',
    SlackChannel = 'slack_channel',
    PagerDutyService = 'pager_duty_service',
    PagerDutyUser = 'pager_duty_user',
    SplunkOnCallUser = 'splunk_on_call_user',
    ConfluenceContent = 'confluence_content',
    Timestamp = 'timestamp',
    ScimGroup = 'scim_group',
    GithubRepository = 'github_repository',
    GitlabRepository = 'gitlab_repository',
    GitlabUsers = 'gitlab_users',
    CatalogEntry = 'catalog_entry',
    CustomField = 'custom_field',
    CustomFieldInsights = 'custom_field_insights',
    Incident = 'incident'
}

export function SelectConfigFromJSON(json: any): SelectConfig {
    return SelectConfigFromJSONTyped(json, false);
}

export function SelectConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initial_option': !exists(json, 'initial_option') ? undefined : SelectOptionFromJSON(json['initial_option']),
        'option_groups': !exists(json, 'option_groups') ? undefined : ((json['option_groups'] as Array<any>).map(SelectOptionGroupFromJSON)),
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(SelectOptionFromJSON)),
        'placeholder': json['placeholder'],
        'typeahead_type': !exists(json, 'typeahead_type') ? undefined : json['typeahead_type'],
    };
}

export function SelectConfigToJSON(value?: SelectConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initial_option': SelectOptionToJSON(value.initial_option),
        'option_groups': value.option_groups === undefined ? undefined : ((value.option_groups as Array<any>).map(SelectOptionGroupToJSON)),
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(SelectOptionToJSON)),
        'placeholder': value.placeholder,
        'typeahead_type': value.typeahead_type,
    };
}

