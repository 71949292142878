import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

export type CodeBlockProps = {
  title?: string;
  code: string;
  className?: string;
};

export const SingleLineCodeBlock = ({
  title,
  code,
  className,
}: CodeBlockProps) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <div
      className={tcx(
        "flex gap-2 truncate bg-surface-secondary items-center px-4 py-2 rounded-2 text-content-primary w-full",
        className,
      )}
    >
      {title && <div className="text-sm-med">{title}</div>}
      <code className="truncate grow"> {code}</code>
      <Button
        theme={ButtonTheme.Unstyled}
        icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
        analyticsTrackingId="copy-token"
        type="button"
        title="Copy to clipboard"
        iconProps={{
          className: tcx(
            "text-content-tertiary group-hover:text-content-primary",
            {
              "!text-green-content": hasCopied,
            },
          ),
          size: IconSize.Medium,
        }}
        onClick={() => copyTextToClipboard(code)}
      />
    </div>
  );
};
