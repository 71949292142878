/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostIncidentFlowUpdateTaskConfigRequestBody
 */
export interface PostIncidentFlowUpdateTaskConfigRequestBody {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    assignee?: EngineParamBindingPayload;
    /**
     * IDs of the custom fields that should be set when the post-incident task involves setting custom fields
     * @type {Array<string>}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    custom_field_ids?: Array<string>;
    /**
     * 
     * @type {TextNode}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    description?: TextNode;
    /**
     * Expressions available for use in post-inc task configs
     * @type {Array<ExpressionPayload>}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    hours_until_due: EngineParamBindingPayload;
    /**
     * ID of the incident role when the post-incident task involves a role
     * @type {string}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    incident_role_id?: string;
    /**
     * IDs of the timestamps that should be set when the post-incident task involves setting timestamps
     * @type {Array<string>}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    timestamp_ids?: Array<string>;
    /**
     * Title of the task
     * @type {string}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    title?: string;
    /**
     * Check if the tasks is skippable
     * @type {boolean}
     * @memberof PostIncidentFlowUpdateTaskConfigRequestBody
     */
    unskippable: boolean;
}

export function PostIncidentFlowUpdateTaskConfigRequestBodyFromJSON(json: any): PostIncidentFlowUpdateTaskConfigRequestBody {
    return PostIncidentFlowUpdateTaskConfigRequestBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowUpdateTaskConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowUpdateTaskConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': !exists(json, 'assignee') ? undefined : EngineParamBindingPayloadFromJSON(json['assignee']),
        'custom_field_ids': !exists(json, 'custom_field_ids') ? undefined : json['custom_field_ids'],
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'hours_until_due': EngineParamBindingPayloadFromJSON(json['hours_until_due']),
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'timestamp_ids': !exists(json, 'timestamp_ids') ? undefined : json['timestamp_ids'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'unskippable': json['unskippable'],
    };
}

export function PostIncidentFlowUpdateTaskConfigRequestBodyToJSON(value?: PostIncidentFlowUpdateTaskConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': EngineParamBindingPayloadToJSON(value.assignee),
        'custom_field_ids': value.custom_field_ids,
        'description': TextNodeToJSON(value.description),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'hours_until_due': EngineParamBindingPayloadToJSON(value.hours_until_due),
        'incident_role_id': value.incident_role_id,
        'timestamp_ids': value.timestamp_ids,
        'title': value.title,
        'unskippable': value.unskippable,
    };
}

