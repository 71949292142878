import { ContentBox, GenericErrorMessage, Txt } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { assertUnreachable } from "src/utils/utils";

enum ConfigurationState {
  Loading,
  Redirecting,
  Failure,
}

export const LoginMsTeamsTabRoute = (): React.ReactElement => {
  const navigate = useNavigate();

  const [configState, setConfigState] = useState<ConfigurationState>(
    ConfigurationState.Loading,
  );
  useEffect(() => {
    const handleTabLogin = async () => {
      try {
        await microsoftTeams.app.initialize();
        microsoftTeams.app.notifyAppLoaded();
        microsoftTeams.app.notifySuccess();
        const token = await microsoftTeams.authentication.getAuthToken();
        const context = await microsoftTeams.app.getContext();
        const params = new URLSearchParams({
          token,
          tenant_id: context.user?.tenant?.id ?? "",
          channel_id: context.channel?.id || "",
        });
        if (context.chat?.id) {
          params.set("chat_id", context.chat.id);
        }
        // The mismatch between page and entity here is intentional: in the
        // tab deeplink context you construct for Microsoft, it is subEntityId.
        // See https://learn.microsoft.com/en-us/microsoftteams/platform/concepts/build-and-test/deep-link-application?tabs=teamsjs-v2#configure-deep-link-to-browse-within-your-app-manually
        if (context.page.subPageId) {
          params.set("sub_entity_id", context.page.subPageId);
        }
        const location = "/auth/microsoft_tab_login?" + params.toString();
        const response = await fetch(location, {
          method: "GET",
          credentials: "same-origin",
        });

        // We should get redirected to the right sub-location we are looking for
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // We should get redirected and have a url to go to
        if (!response.redirected || !response.url) {
          throw new Error("Expected redirect was not received");
        }

        // We cannot navigate to the full URL - we need the path and any search params we were using
        const url = new URL(response.url);
        if (!url.pathname) {
          throw new Error("Could not parse the url for navigation");
        }

        // lets go!
        navigate(url.pathname + url.search);
      } catch (error) {
        console.error(error);
        setConfigState(ConfigurationState.Failure);
      }
    };

    handleTabLogin();
  }, [navigate]);

  switch (configState) {
    case ConfigurationState.Loading:
      return (
        <FullPageLoader
          subtitle={
            <Txt className="text-content-tertiary">Authenticating...</Txt>
          }
        />
      );
    case ConfigurationState.Redirecting:
      return <FullPageLoader subtitle={<Txt>Loading...</Txt>} />;
    case ConfigurationState.Failure:
      return (
        <ContentBox className="p-4">
          <GenericErrorMessage description="This page can only be loaded from within Microsoft Teams." />
        </ContentBox>
      );
    default:
      assertUnreachable(configState);
      return <></>;
  }
};
