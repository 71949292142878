/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CatalogBootstrapServiceTypeRequestBody,
    CatalogBootstrapServiceTypeRequestBodyFromJSON,
    CatalogBootstrapServiceTypeRequestBodyToJSON,
    CatalogBootstrapServiceTypeResponseBody,
    CatalogBootstrapServiceTypeResponseBodyFromJSON,
    CatalogBootstrapServiceTypeResponseBodyToJSON,
    CatalogBootstrapTeamTypeRequestBody,
    CatalogBootstrapTeamTypeRequestBodyFromJSON,
    CatalogBootstrapTeamTypeRequestBodyToJSON,
    CatalogBootstrapTeamTypeResponseBody,
    CatalogBootstrapTeamTypeResponseBodyFromJSON,
    CatalogBootstrapTeamTypeResponseBodyToJSON,
    CatalogBulkCreateEntriesRequestBody,
    CatalogBulkCreateEntriesRequestBodyFromJSON,
    CatalogBulkCreateEntriesRequestBodyToJSON,
    CatalogBulkCreateEntriesResponseBody,
    CatalogBulkCreateEntriesResponseBodyFromJSON,
    CatalogBulkCreateEntriesResponseBodyToJSON,
    CatalogBulkUpdateAttributeValuesRequestBody,
    CatalogBulkUpdateAttributeValuesRequestBodyFromJSON,
    CatalogBulkUpdateAttributeValuesRequestBodyToJSON,
    CatalogCreateEntryRequestBody,
    CatalogCreateEntryRequestBodyFromJSON,
    CatalogCreateEntryRequestBodyToJSON,
    CatalogCreateEntryResponseBody,
    CatalogCreateEntryResponseBodyFromJSON,
    CatalogCreateEntryResponseBodyToJSON,
    CatalogCreateParameterisedTypeRequestBody,
    CatalogCreateParameterisedTypeRequestBodyFromJSON,
    CatalogCreateParameterisedTypeRequestBodyToJSON,
    CatalogCreateParameterisedTypeResponseBody,
    CatalogCreateParameterisedTypeResponseBodyFromJSON,
    CatalogCreateParameterisedTypeResponseBodyToJSON,
    CatalogCreateTypeFromAttributeRequestBody,
    CatalogCreateTypeFromAttributeRequestBodyFromJSON,
    CatalogCreateTypeFromAttributeRequestBodyToJSON,
    CatalogCreateTypeFromAttributeResponseBody,
    CatalogCreateTypeFromAttributeResponseBodyFromJSON,
    CatalogCreateTypeFromAttributeResponseBodyToJSON,
    CatalogCreateTypeRequestBody,
    CatalogCreateTypeRequestBodyFromJSON,
    CatalogCreateTypeRequestBodyToJSON,
    CatalogCreateTypeResponseBody,
    CatalogCreateTypeResponseBodyFromJSON,
    CatalogCreateTypeResponseBodyToJSON,
    CatalogFindEntriesRequestBody,
    CatalogFindEntriesRequestBodyFromJSON,
    CatalogFindEntriesRequestBodyToJSON,
    CatalogFindEntriesResponseBody,
    CatalogFindEntriesResponseBodyFromJSON,
    CatalogFindEntriesResponseBodyToJSON,
    CatalogGenerateZipForTypesRequestBody,
    CatalogGenerateZipForTypesRequestBodyFromJSON,
    CatalogGenerateZipForTypesRequestBodyToJSON,
    CatalogGenerateZipForTypesResponseBody,
    CatalogGenerateZipForTypesResponseBodyFromJSON,
    CatalogGenerateZipForTypesResponseBodyToJSON,
    CatalogGetCatalogConfigResponseBody,
    CatalogGetCatalogConfigResponseBodyFromJSON,
    CatalogGetCatalogConfigResponseBodyToJSON,
    CatalogGetPotentialParameterisedTypePayloadResponseBody,
    CatalogGetPotentialParameterisedTypePayloadResponseBodyFromJSON,
    CatalogGetPotentialParameterisedTypePayloadResponseBodyToJSON,
    CatalogHydrateOptionsResponseBody,
    CatalogHydrateOptionsResponseBodyFromJSON,
    CatalogHydrateOptionsResponseBodyToJSON,
    CatalogListAdditionalAttributesResponseBody,
    CatalogListAdditionalAttributesResponseBodyFromJSON,
    CatalogListAdditionalAttributesResponseBodyToJSON,
    CatalogListDerivedAttributesRequestBody,
    CatalogListDerivedAttributesRequestBodyFromJSON,
    CatalogListDerivedAttributesRequestBodyToJSON,
    CatalogListDerivedAttributesResponseBody,
    CatalogListDerivedAttributesResponseBodyFromJSON,
    CatalogListDerivedAttributesResponseBodyToJSON,
    CatalogListEntriesResponseBody,
    CatalogListEntriesResponseBodyFromJSON,
    CatalogListEntriesResponseBodyToJSON,
    CatalogListExpressionSuggestionsRequestBody,
    CatalogListExpressionSuggestionsRequestBodyFromJSON,
    CatalogListExpressionSuggestionsRequestBodyToJSON,
    CatalogListExpressionSuggestionsResponseBody,
    CatalogListExpressionSuggestionsResponseBodyFromJSON,
    CatalogListExpressionSuggestionsResponseBodyToJSON,
    CatalogListIntegrationsResponseBody,
    CatalogListIntegrationsResponseBodyFromJSON,
    CatalogListIntegrationsResponseBodyToJSON,
    CatalogListOptionsResponseBody,
    CatalogListOptionsResponseBodyFromJSON,
    CatalogListOptionsResponseBodyToJSON,
    CatalogListParameterisedResourceOptionsResponseBody,
    CatalogListParameterisedResourceOptionsResponseBodyFromJSON,
    CatalogListParameterisedResourceOptionsResponseBodyToJSON,
    CatalogListRecommendedAttributeResourcesResponseBody,
    CatalogListRecommendedAttributeResourcesResponseBodyFromJSON,
    CatalogListRecommendedAttributeResourcesResponseBodyToJSON,
    CatalogListRelationsResponseBody,
    CatalogListRelationsResponseBodyFromJSON,
    CatalogListRelationsResponseBodyToJSON,
    CatalogListResourcesResponseBody,
    CatalogListResourcesResponseBodyFromJSON,
    CatalogListResourcesResponseBodyToJSON,
    CatalogListTeamSourceOfTruthResourcesResponseBody,
    CatalogListTeamSourceOfTruthResourcesResponseBodyFromJSON,
    CatalogListTeamSourceOfTruthResourcesResponseBodyToJSON,
    CatalogListTypesResponseBody,
    CatalogListTypesResponseBodyFromJSON,
    CatalogListTypesResponseBodyToJSON,
    CatalogShowEntryResponseBody,
    CatalogShowEntryResponseBodyFromJSON,
    CatalogShowEntryResponseBodyToJSON,
    CatalogShowGeneratedRepositoryResponseBody,
    CatalogShowGeneratedRepositoryResponseBodyFromJSON,
    CatalogShowGeneratedRepositoryResponseBodyToJSON,
    CatalogShowTypeResponseBody,
    CatalogShowTypeResponseBodyFromJSON,
    CatalogShowTypeResponseBodyToJSON,
    CatalogSuggestMissingAttributeValuesRequestBody,
    CatalogSuggestMissingAttributeValuesRequestBodyFromJSON,
    CatalogSuggestMissingAttributeValuesRequestBodyToJSON,
    CatalogSuggestMissingAttributeValuesResponseBody,
    CatalogSuggestMissingAttributeValuesResponseBodyFromJSON,
    CatalogSuggestMissingAttributeValuesResponseBodyToJSON,
    CatalogUpdateEntriesRanksRequestBody,
    CatalogUpdateEntriesRanksRequestBodyFromJSON,
    CatalogUpdateEntriesRanksRequestBodyToJSON,
    CatalogUpdateEntriesRanksResponseBody,
    CatalogUpdateEntriesRanksResponseBodyFromJSON,
    CatalogUpdateEntriesRanksResponseBodyToJSON,
    CatalogUpdateEntryRequestBody,
    CatalogUpdateEntryRequestBodyFromJSON,
    CatalogUpdateEntryRequestBodyToJSON,
    CatalogUpdateEntryResponseBody,
    CatalogUpdateEntryResponseBodyFromJSON,
    CatalogUpdateEntryResponseBodyToJSON,
    CatalogUpdateTypeRequestBody,
    CatalogUpdateTypeRequestBodyFromJSON,
    CatalogUpdateTypeRequestBodyToJSON,
    CatalogUpdateTypeResponseBody,
    CatalogUpdateTypeResponseBodyFromJSON,
    CatalogUpdateTypeResponseBodyToJSON,
    CatalogUpdateTypeSchemaRequestBody,
    CatalogUpdateTypeSchemaRequestBodyFromJSON,
    CatalogUpdateTypeSchemaRequestBodyToJSON,
    CatalogUpdateTypeSchemaResponseBody,
    CatalogUpdateTypeSchemaResponseBodyFromJSON,
    CatalogUpdateTypeSchemaResponseBodyToJSON,
} from '../models';

export interface CatalogBootstrapServiceTypeRequest {
    bootstrapServiceTypeRequestBody: CatalogBootstrapServiceTypeRequestBody;
}

export interface CatalogBootstrapTeamTypeRequest {
    bootstrapTeamTypeRequestBody: CatalogBootstrapTeamTypeRequestBody;
}

export interface CatalogBulkCreateEntriesRequest {
    bulkCreateEntriesRequestBody: CatalogBulkCreateEntriesRequestBody;
}

export interface CatalogBulkUpdateAttributeValuesRequest {
    bulkUpdateAttributeValuesRequestBody: CatalogBulkUpdateAttributeValuesRequestBody;
}

export interface CatalogCreateEntryRequest {
    createEntryRequestBody: CatalogCreateEntryRequestBody;
}

export interface CatalogCreateParameterisedTypeRequest {
    createParameterisedTypeRequestBody: CatalogCreateParameterisedTypeRequestBody;
}

export interface CatalogCreateTypeRequest {
    createTypeRequestBody: CatalogCreateTypeRequestBody;
}

export interface CatalogCreateTypeFromAttributeRequest {
    attributeId: string;
    createTypeFromAttributeRequestBody: CatalogCreateTypeFromAttributeRequestBody;
}

export interface CatalogDestroyEntryRequest {
    id: string;
}

export interface CatalogDestroyTypeRequest {
    id: string;
}

export interface CatalogFindEntriesRequest {
    findEntriesRequestBody: CatalogFindEntriesRequestBody;
}

export interface CatalogGenerateZipForTypesRequest {
    generateZipForTypesRequestBody: CatalogGenerateZipForTypesRequestBody;
}

export interface CatalogGetPotentialParameterisedTypePayloadRequest {
    dynamicResourceParameter: string;
    registryType: string;
}

export interface CatalogHydrateOptionsRequest {
    id: string;
    idList?: Array<string>;
}

export interface CatalogListAdditionalAttributesRequest {
    registryType: string;
    dynamicResourceParameter?: string;
}

export interface CatalogListDerivedAttributesRequest {
    listDerivedAttributesRequestBody: CatalogListDerivedAttributesRequestBody;
}

export interface CatalogListEntriesRequest {
    catalogTypeId: string;
    includeReferences?: boolean;
    includeDerivedAttributes?: boolean;
    search?: string;
    pageSize?: number;
    after?: string;
}

export interface CatalogListExpressionSuggestionsRequest {
    listExpressionSuggestionsRequestBody: CatalogListExpressionSuggestionsRequestBody;
}

export interface CatalogListOptionsRequest {
    id: string;
    query?: string;
}

export interface CatalogListParameterisedResourceOptionsRequest {
    integrationProvider: string;
    query?: string;
}

export interface CatalogListRecommendedAttributeResourcesRequest {
    type: string;
}

export interface CatalogListRelationsRequest {
    id: string;
}

export interface CatalogListTypesRequest {
    includeCount?: boolean;
}

export interface CatalogShowEntryRequest {
    id: string;
    includeReferences?: boolean;
    includeDerivedAttributes?: boolean;
}

export interface CatalogShowGeneratedRepositoryRequest {
    id: string;
}

export interface CatalogShowTypeRequest {
    id: string;
    includeAttributeDependentResources?: boolean;
}

export interface CatalogSuggestMissingAttributeValuesRequest {
    suggestMissingAttributeValuesRequestBody: CatalogSuggestMissingAttributeValuesRequestBody;
}

export interface CatalogSyncTypeRequest {
    id: string;
}

export interface CatalogUpdateEntriesRanksRequest {
    updateEntriesRanksRequestBody: CatalogUpdateEntriesRanksRequestBody;
}

export interface CatalogUpdateEntryRequest {
    id: string;
    updateEntryRequestBody: CatalogUpdateEntryRequestBody;
}

export interface CatalogUpdateTypeRequest {
    id: string;
    updateTypeRequestBody: CatalogUpdateTypeRequestBody;
}

export interface CatalogUpdateTypeSchemaRequest {
    id: string;
    updateTypeSchemaRequestBody: CatalogUpdateTypeSchemaRequestBody;
}

/**
 * 
 */
export class CatalogApi extends runtime.BaseAPI {

    /**
     * Create a new Service type using an existing type and its entries
     * BootstrapServiceType Catalog
     */
    async catalogBootstrapServiceTypeRaw(requestParameters: CatalogBootstrapServiceTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogBootstrapServiceTypeResponseBody>> {
        if (requestParameters.bootstrapServiceTypeRequestBody === null || requestParameters.bootstrapServiceTypeRequestBody === undefined) {
            throw new runtime.RequiredError('bootstrapServiceTypeRequestBody','Required parameter requestParameters.bootstrapServiceTypeRequestBody was null or undefined when calling catalogBootstrapServiceType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/actions/bootstrap_service`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogBootstrapServiceTypeRequestBodyToJSON(requestParameters.bootstrapServiceTypeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogBootstrapServiceTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new Service type using an existing type and its entries
     * BootstrapServiceType Catalog
     */
    async catalogBootstrapServiceType(requestParameters: CatalogBootstrapServiceTypeRequest, initOverrides?: RequestInit): Promise<CatalogBootstrapServiceTypeResponseBody> {
        const response = await this.catalogBootstrapServiceTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Team type using an existing type and its entries
     * BootstrapTeamType Catalog
     */
    async catalogBootstrapTeamTypeRaw(requestParameters: CatalogBootstrapTeamTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogBootstrapTeamTypeResponseBody>> {
        if (requestParameters.bootstrapTeamTypeRequestBody === null || requestParameters.bootstrapTeamTypeRequestBody === undefined) {
            throw new runtime.RequiredError('bootstrapTeamTypeRequestBody','Required parameter requestParameters.bootstrapTeamTypeRequestBody was null or undefined when calling catalogBootstrapTeamType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/actions/bootstrap_team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogBootstrapTeamTypeRequestBodyToJSON(requestParameters.bootstrapTeamTypeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogBootstrapTeamTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new Team type using an existing type and its entries
     * BootstrapTeamType Catalog
     */
    async catalogBootstrapTeamType(requestParameters: CatalogBootstrapTeamTypeRequest, initOverrides?: RequestInit): Promise<CatalogBootstrapTeamTypeResponseBody> {
        const response = await this.catalogBootstrapTeamTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a list of entries within the catalog. You can create up to 100 entries at a time. This will either completely succeed, or completely fail.
     * BulkCreateEntries Catalog
     */
    async catalogBulkCreateEntriesRaw(requestParameters: CatalogBulkCreateEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogBulkCreateEntriesResponseBody>> {
        if (requestParameters.bulkCreateEntriesRequestBody === null || requestParameters.bulkCreateEntriesRequestBody === undefined) {
            throw new runtime.RequiredError('bulkCreateEntriesRequestBody','Required parameter requestParameters.bulkCreateEntriesRequestBody was null or undefined when calling catalogBulkCreateEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_entries/actions/bulk_create_entries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogBulkCreateEntriesRequestBodyToJSON(requestParameters.bulkCreateEntriesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogBulkCreateEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a list of entries within the catalog. You can create up to 100 entries at a time. This will either completely succeed, or completely fail.
     * BulkCreateEntries Catalog
     */
    async catalogBulkCreateEntries(requestParameters: CatalogBulkCreateEntriesRequest, initOverrides?: RequestInit): Promise<CatalogBulkCreateEntriesResponseBody> {
        const response = await this.catalogBulkCreateEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bulk update a list of entry values for a given attribute for this catalog type.
     * BulkUpdateAttributeValues Catalog
     */
    async catalogBulkUpdateAttributeValuesRaw(requestParameters: CatalogBulkUpdateAttributeValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bulkUpdateAttributeValuesRequestBody === null || requestParameters.bulkUpdateAttributeValuesRequestBody === undefined) {
            throw new runtime.RequiredError('bulkUpdateAttributeValuesRequestBody','Required parameter requestParameters.bulkUpdateAttributeValuesRequestBody was null or undefined when calling catalogBulkUpdateAttributeValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_entries/actions/bulk_update_attribute_values`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogBulkUpdateAttributeValuesRequestBodyToJSON(requestParameters.bulkUpdateAttributeValuesRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk update a list of entry values for a given attribute for this catalog type.
     * BulkUpdateAttributeValues Catalog
     */
    async catalogBulkUpdateAttributeValues(requestParameters: CatalogBulkUpdateAttributeValuesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.catalogBulkUpdateAttributeValuesRaw(requestParameters, initOverrides);
    }

    /**
     * Create an entry within the catalog. We support a maximum of 50,000 entries per type.
     * CreateEntry Catalog
     */
    async catalogCreateEntryRaw(requestParameters: CatalogCreateEntryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogCreateEntryResponseBody>> {
        if (requestParameters.createEntryRequestBody === null || requestParameters.createEntryRequestBody === undefined) {
            throw new runtime.RequiredError('createEntryRequestBody','Required parameter requestParameters.createEntryRequestBody was null or undefined when calling catalogCreateEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_entries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogCreateEntryRequestBodyToJSON(requestParameters.createEntryRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogCreateEntryResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an entry within the catalog. We support a maximum of 50,000 entries per type.
     * CreateEntry Catalog
     */
    async catalogCreateEntry(requestParameters: CatalogCreateEntryRequest, initOverrides?: RequestInit): Promise<CatalogCreateEntryResponseBody> {
        const response = await this.catalogCreateEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a parameterised catalog type
     * CreateParameterisedType Catalog
     */
    async catalogCreateParameterisedTypeRaw(requestParameters: CatalogCreateParameterisedTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogCreateParameterisedTypeResponseBody>> {
        if (requestParameters.createParameterisedTypeRequestBody === null || requestParameters.createParameterisedTypeRequestBody === undefined) {
            throw new runtime.RequiredError('createParameterisedTypeRequestBody','Required parameter requestParameters.createParameterisedTypeRequestBody was null or undefined when calling catalogCreateParameterisedType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/parameterised`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogCreateParameterisedTypeRequestBodyToJSON(requestParameters.createParameterisedTypeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogCreateParameterisedTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a parameterised catalog type
     * CreateParameterisedType Catalog
     */
    async catalogCreateParameterisedType(requestParameters: CatalogCreateParameterisedTypeRequest, initOverrides?: RequestInit): Promise<CatalogCreateParameterisedTypeResponseBody> {
        const response = await this.catalogCreateParameterisedTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a catalog type
     * CreateType Catalog
     */
    async catalogCreateTypeRaw(requestParameters: CatalogCreateTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogCreateTypeResponseBody>> {
        if (requestParameters.createTypeRequestBody === null || requestParameters.createTypeRequestBody === undefined) {
            throw new runtime.RequiredError('createTypeRequestBody','Required parameter requestParameters.createTypeRequestBody was null or undefined when calling catalogCreateType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogCreateTypeRequestBodyToJSON(requestParameters.createTypeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogCreateTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a catalog type
     * CreateType Catalog
     */
    async catalogCreateType(requestParameters: CatalogCreateTypeRequest, initOverrides?: RequestInit): Promise<CatalogCreateTypeResponseBody> {
        const response = await this.catalogCreateTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allows you to turn an existing attribute into a catalog type, meaning you can then extend it with further attributes.
     * CreateTypeFromAttribute Catalog
     */
    async catalogCreateTypeFromAttributeRaw(requestParameters: CatalogCreateTypeFromAttributeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogCreateTypeFromAttributeResponseBody>> {
        if (requestParameters.attributeId === null || requestParameters.attributeId === undefined) {
            throw new runtime.RequiredError('attributeId','Required parameter requestParameters.attributeId was null or undefined when calling catalogCreateTypeFromAttribute.');
        }

        if (requestParameters.createTypeFromAttributeRequestBody === null || requestParameters.createTypeFromAttributeRequestBody === undefined) {
            throw new runtime.RequiredError('createTypeFromAttributeRequestBody','Required parameter requestParameters.createTypeFromAttributeRequestBody was null or undefined when calling catalogCreateTypeFromAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/{attribute_id}/create_from_attribute`.replace(`{${"attribute_id"}}`, encodeURIComponent(String(requestParameters.attributeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogCreateTypeFromAttributeRequestBodyToJSON(requestParameters.createTypeFromAttributeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogCreateTypeFromAttributeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Allows you to turn an existing attribute into a catalog type, meaning you can then extend it with further attributes.
     * CreateTypeFromAttribute Catalog
     */
    async catalogCreateTypeFromAttribute(requestParameters: CatalogCreateTypeFromAttributeRequest, initOverrides?: RequestInit): Promise<CatalogCreateTypeFromAttributeResponseBody> {
        const response = await this.catalogCreateTypeFromAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives an entry
     * DestroyEntry Catalog
     */
    async catalogDestroyEntryRaw(requestParameters: CatalogDestroyEntryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogDestroyEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_entries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an entry
     * DestroyEntry Catalog
     */
    async catalogDestroyEntry(requestParameters: CatalogDestroyEntryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.catalogDestroyEntryRaw(requestParameters, initOverrides);
    }

    /**
     * Archives a catalog type
     * DestroyType Catalog
     */
    async catalogDestroyTypeRaw(requestParameters: CatalogDestroyTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogDestroyType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a catalog type
     * DestroyType Catalog
     */
    async catalogDestroyType(requestParameters: CatalogDestroyTypeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.catalogDestroyTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Find entries for catalog types using ids, external_ids, or names
     * FindEntries Catalog
     */
    async catalogFindEntriesRaw(requestParameters: CatalogFindEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogFindEntriesResponseBody>> {
        if (requestParameters.findEntriesRequestBody === null || requestParameters.findEntriesRequestBody === undefined) {
            throw new runtime.RequiredError('findEntriesRequestBody','Required parameter requestParameters.findEntriesRequestBody was null or undefined when calling catalogFindEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/find_entries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogFindEntriesRequestBodyToJSON(requestParameters.findEntriesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogFindEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Find entries for catalog types using ids, external_ids, or names
     * FindEntries Catalog
     */
    async catalogFindEntries(requestParameters: CatalogFindEntriesRequest, initOverrides?: RequestInit): Promise<CatalogFindEntriesResponseBody> {
        const response = await this.catalogFindEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a zip file of the types and entries for given catalog types
     * GenerateZipForTypes Catalog
     */
    async catalogGenerateZipForTypesRaw(requestParameters: CatalogGenerateZipForTypesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogGenerateZipForTypesResponseBody>> {
        if (requestParameters.generateZipForTypesRequestBody === null || requestParameters.generateZipForTypesRequestBody === undefined) {
            throw new runtime.RequiredError('generateZipForTypesRequestBody','Required parameter requestParameters.generateZipForTypesRequestBody was null or undefined when calling catalogGenerateZipForTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/generate-zip-for-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogGenerateZipForTypesRequestBodyToJSON(requestParameters.generateZipForTypesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogGenerateZipForTypesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generate a zip file of the types and entries for given catalog types
     * GenerateZipForTypes Catalog
     */
    async catalogGenerateZipForTypes(requestParameters: CatalogGenerateZipForTypesRequest, initOverrides?: RequestInit): Promise<CatalogGenerateZipForTypesResponseBody> {
        const response = await this.catalogGenerateZipForTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the catalog configuration for a given organisation
     * GetCatalogConfig Catalog
     */
    async catalogGetCatalogConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogGetCatalogConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_configs/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogGetCatalogConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the catalog configuration for a given organisation
     * GetCatalogConfig Catalog
     */
    async catalogGetCatalogConfig(initOverrides?: RequestInit): Promise<CatalogGetCatalogConfigResponseBody> {
        const response = await this.catalogGetCatalogConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the payload for a parameterised type without actually creating it
     * GetPotentialParameterisedTypePayload Catalog
     */
    async catalogGetPotentialParameterisedTypePayloadRaw(requestParameters: CatalogGetPotentialParameterisedTypePayloadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogGetPotentialParameterisedTypePayloadResponseBody>> {
        if (requestParameters.dynamicResourceParameter === null || requestParameters.dynamicResourceParameter === undefined) {
            throw new runtime.RequiredError('dynamicResourceParameter','Required parameter requestParameters.dynamicResourceParameter was null or undefined when calling catalogGetPotentialParameterisedTypePayload.');
        }

        if (requestParameters.registryType === null || requestParameters.registryType === undefined) {
            throw new runtime.RequiredError('registryType','Required parameter requestParameters.registryType was null or undefined when calling catalogGetPotentialParameterisedTypePayload.');
        }

        const queryParameters: any = {};

        if (requestParameters.dynamicResourceParameter !== undefined) {
            queryParameters['dynamic_resource_parameter'] = requestParameters.dynamicResourceParameter;
        }

        if (requestParameters.registryType !== undefined) {
            queryParameters['registry_type'] = requestParameters.registryType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_types/parameterised`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogGetPotentialParameterisedTypePayloadResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the payload for a parameterised type without actually creating it
     * GetPotentialParameterisedTypePayload Catalog
     */
    async catalogGetPotentialParameterisedTypePayload(requestParameters: CatalogGetPotentialParameterisedTypePayloadRequest, initOverrides?: RequestInit): Promise<CatalogGetPotentialParameterisedTypePayloadResponseBody> {
        const response = await this.catalogGetPotentialParameterisedTypePayloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Hydrate values for a catalog type, for use with typeaheads
     * HydrateOptions Catalog
     */
    async catalogHydrateOptionsRaw(requestParameters: CatalogHydrateOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogHydrateOptionsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogHydrateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.idList) {
            queryParameters['id_list'] = requestParameters.idList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/{id}/hydrate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogHydrateOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Hydrate values for a catalog type, for use with typeaheads
     * HydrateOptions Catalog
     */
    async catalogHydrateOptions(requestParameters: CatalogHydrateOptionsRequest, initOverrides?: RequestInit): Promise<CatalogHydrateOptionsResponseBody> {
        const response = await this.catalogHydrateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List dynamic attribute options for a catalog type
     * ListAdditionalAttributes Catalog
     */
    async catalogListAdditionalAttributesRaw(requestParameters: CatalogListAdditionalAttributesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListAdditionalAttributesResponseBody>> {
        if (requestParameters.registryType === null || requestParameters.registryType === undefined) {
            throw new runtime.RequiredError('registryType','Required parameter requestParameters.registryType was null or undefined when calling catalogListAdditionalAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.registryType !== undefined) {
            queryParameters['registry_type'] = requestParameters.registryType;
        }

        if (requestParameters.dynamicResourceParameter !== undefined) {
            queryParameters['dynamic_resource_parameter'] = requestParameters.dynamicResourceParameter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/additional_attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListAdditionalAttributesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List dynamic attribute options for a catalog type
     * ListAdditionalAttributes Catalog
     */
    async catalogListAdditionalAttributes(requestParameters: CatalogListAdditionalAttributesRequest, initOverrides?: RequestInit): Promise<CatalogListAdditionalAttributesResponseBody> {
        const response = await this.catalogListAdditionalAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the available derived attributes to get from a source type to a target type
     * ListDerivedAttributes Catalog
     */
    async catalogListDerivedAttributesRaw(requestParameters: CatalogListDerivedAttributesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListDerivedAttributesResponseBody>> {
        if (requestParameters.listDerivedAttributesRequestBody === null || requestParameters.listDerivedAttributesRequestBody === undefined) {
            throw new runtime.RequiredError('listDerivedAttributesRequestBody','Required parameter requestParameters.listDerivedAttributesRequestBody was null or undefined when calling catalogListDerivedAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/derived_attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogListDerivedAttributesRequestBodyToJSON(requestParameters.listDerivedAttributesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListDerivedAttributesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the available derived attributes to get from a source type to a target type
     * ListDerivedAttributes Catalog
     */
    async catalogListDerivedAttributes(requestParameters: CatalogListDerivedAttributesRequest, initOverrides?: RequestInit): Promise<CatalogListDerivedAttributesResponseBody> {
        const response = await this.catalogListDerivedAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List catalog entries for a catalog type
     * ListEntries Catalog
     */
    async catalogListEntriesRaw(requestParameters: CatalogListEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListEntriesResponseBody>> {
        if (requestParameters.catalogTypeId === null || requestParameters.catalogTypeId === undefined) {
            throw new runtime.RequiredError('catalogTypeId','Required parameter requestParameters.catalogTypeId was null or undefined when calling catalogListEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeReferences !== undefined) {
            queryParameters['include_references'] = requestParameters.includeReferences;
        }

        if (requestParameters.includeDerivedAttributes !== undefined) {
            queryParameters['include_derived_attributes'] = requestParameters.includeDerivedAttributes;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.catalogTypeId !== undefined) {
            queryParameters['catalog_type_id'] = requestParameters.catalogTypeId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List catalog entries for a catalog type
     * ListEntries Catalog
     */
    async catalogListEntries(requestParameters: CatalogListEntriesRequest, initOverrides?: RequestInit): Promise<CatalogListEntriesResponseBody> {
        const response = await this.catalogListEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a list of suggested expressions that can be used to navigate to a target type
     * ListExpressionSuggestions Catalog
     */
    async catalogListExpressionSuggestionsRaw(requestParameters: CatalogListExpressionSuggestionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListExpressionSuggestionsResponseBody>> {
        if (requestParameters.listExpressionSuggestionsRequestBody === null || requestParameters.listExpressionSuggestionsRequestBody === undefined) {
            throw new runtime.RequiredError('listExpressionSuggestionsRequestBody','Required parameter requestParameters.listExpressionSuggestionsRequestBody was null or undefined when calling catalogListExpressionSuggestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/expression_suggestions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogListExpressionSuggestionsRequestBodyToJSON(requestParameters.listExpressionSuggestionsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListExpressionSuggestionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a list of suggested expressions that can be used to navigate to a target type
     * ListExpressionSuggestions Catalog
     */
    async catalogListExpressionSuggestions(requestParameters: CatalogListExpressionSuggestionsRequest, initOverrides?: RequestInit): Promise<CatalogListExpressionSuggestionsResponseBody> {
        const response = await this.catalogListExpressionSuggestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List installed integrations that support the catalog, with some useful information attached
     * ListIntegrations Catalog
     */
    async catalogListIntegrationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListIntegrationsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog/integrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListIntegrationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List installed integrations that support the catalog, with some useful information attached
     * ListIntegrations Catalog
     */
    async catalogListIntegrations(initOverrides?: RequestInit): Promise<CatalogListIntegrationsResponseBody> {
        const response = await this.catalogListIntegrationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List options for a catalog type, for use with typeaheads
     * ListOptions Catalog
     */
    async catalogListOptionsRaw(requestParameters: CatalogListOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListOptionsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogListOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/{id}/options`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options for a catalog type, for use with typeaheads
     * ListOptions Catalog
     */
    async catalogListOptions(requestParameters: CatalogListOptionsRequest, initOverrides?: RequestInit): Promise<CatalogListOptionsResponseBody> {
        const response = await this.catalogListOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List parameterised catalog resource options for an integration provider
     * ListParameterisedResourceOptions Catalog
     */
    async catalogListParameterisedResourceOptionsRaw(requestParameters: CatalogListParameterisedResourceOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListParameterisedResourceOptionsResponseBody>> {
        if (requestParameters.integrationProvider === null || requestParameters.integrationProvider === undefined) {
            throw new runtime.RequiredError('integrationProvider','Required parameter requestParameters.integrationProvider was null or undefined when calling catalogListParameterisedResourceOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.integrationProvider !== undefined) {
            queryParameters['integration_provider'] = requestParameters.integrationProvider;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_resources/parameterised`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListParameterisedResourceOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List parameterised catalog resource options for an integration provider
     * ListParameterisedResourceOptions Catalog
     */
    async catalogListParameterisedResourceOptions(requestParameters: CatalogListParameterisedResourceOptionsRequest, initOverrides?: RequestInit): Promise<CatalogListParameterisedResourceOptionsResponseBody> {
        const response = await this.catalogListParameterisedResourceOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List resources that we can recommend as attributes for a custom catalog type
     * ListRecommendedAttributeResources Catalog
     */
    async catalogListRecommendedAttributeResourcesRaw(requestParameters: CatalogListRecommendedAttributeResourcesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListRecommendedAttributeResourcesResponseBody>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling catalogListRecommendedAttributeResources.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog/recommended_attribute_resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListRecommendedAttributeResourcesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List resources that we can recommend as attributes for a custom catalog type
     * ListRecommendedAttributeResources Catalog
     */
    async catalogListRecommendedAttributeResources(requestParameters: CatalogListRecommendedAttributeResourcesRequest, initOverrides?: RequestInit): Promise<CatalogListRecommendedAttributeResourcesResponseBody> {
        const response = await this.catalogListRecommendedAttributeResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the catalog entries and attributes through which they point at a target entry
     * ListRelations Catalog
     */
    async catalogListRelationsRaw(requestParameters: CatalogListRelationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListRelationsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogListRelations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_relations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListRelationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the catalog entries and attributes through which they point at a target entry
     * ListRelations Catalog
     */
    async catalogListRelations(requestParameters: CatalogListRelationsRequest, initOverrides?: RequestInit): Promise<CatalogListRelationsResponseBody> {
        const response = await this.catalogListRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available engine resources for the catalog
     * ListResources Catalog
     */
    async catalogListResourcesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListResourcesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListResourcesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List available engine resources for the catalog
     * ListResources Catalog
     */
    async catalogListResources(initOverrides?: RequestInit): Promise<CatalogListResourcesResponseBody> {
        const response = await this.catalogListResourcesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List resources that can be used as a source-of-truth for a custom catalog type
     * ListTeamSourceOfTruthResources Catalog
     */
    async catalogListTeamSourceOfTruthResourcesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListTeamSourceOfTruthResourcesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog/team_source_of_truth_resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListTeamSourceOfTruthResourcesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List resources that can be used as a source-of-truth for a custom catalog type
     * ListTeamSourceOfTruthResources Catalog
     */
    async catalogListTeamSourceOfTruthResources(initOverrides?: RequestInit): Promise<CatalogListTeamSourceOfTruthResourcesResponseBody> {
        const response = await this.catalogListTeamSourceOfTruthResourcesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List catalog types
     * ListTypes Catalog
     */
    async catalogListTypesRaw(requestParameters: CatalogListTypesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogListTypesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.includeCount !== undefined) {
            queryParameters['include_count'] = requestParameters.includeCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogListTypesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List catalog types
     * ListTypes Catalog
     */
    async catalogListTypes(requestParameters: CatalogListTypesRequest, initOverrides?: RequestInit): Promise<CatalogListTypesResponseBody> {
        const response = await this.catalogListTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single catalog entry
     * ShowEntry Catalog
     */
    async catalogShowEntryRaw(requestParameters: CatalogShowEntryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogShowEntryResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogShowEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeReferences !== undefined) {
            queryParameters['include_references'] = requestParameters.includeReferences;
        }

        if (requestParameters.includeDerivedAttributes !== undefined) {
            queryParameters['include_derived_attributes'] = requestParameters.includeDerivedAttributes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_entries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogShowEntryResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single catalog entry
     * ShowEntry Catalog
     */
    async catalogShowEntry(requestParameters: CatalogShowEntryRequest, initOverrides?: RequestInit): Promise<CatalogShowEntryResponseBody> {
        const response = await this.catalogShowEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single catalog type generated repository
     * ShowGeneratedRepository Catalog
     */
    async catalogShowGeneratedRepositoryRaw(requestParameters: CatalogShowGeneratedRepositoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogShowGeneratedRepositoryResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogShowGeneratedRepository.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_type_generated_respositories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogShowGeneratedRepositoryResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single catalog type generated repository
     * ShowGeneratedRepository Catalog
     */
    async catalogShowGeneratedRepository(requestParameters: CatalogShowGeneratedRepositoryRequest, initOverrides?: RequestInit): Promise<CatalogShowGeneratedRepositoryResponseBody> {
        const response = await this.catalogShowGeneratedRepositoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single catalog type
     * ShowType Catalog
     */
    async catalogShowTypeRaw(requestParameters: CatalogShowTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogShowTypeResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogShowType.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeAttributeDependentResources !== undefined) {
            queryParameters['include_attribute_dependent_resources'] = requestParameters.includeAttributeDependentResources;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogShowTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single catalog type
     * ShowType Catalog
     */
    async catalogShowType(requestParameters: CatalogShowTypeRequest, initOverrides?: RequestInit): Promise<CatalogShowTypeResponseBody> {
        const response = await this.catalogShowTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Suggest values for a given attribute for this catalog type, for any entries where it\'s missing.
     * SuggestMissingAttributeValues Catalog
     */
    async catalogSuggestMissingAttributeValuesRaw(requestParameters: CatalogSuggestMissingAttributeValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogSuggestMissingAttributeValuesResponseBody>> {
        if (requestParameters.suggestMissingAttributeValuesRequestBody === null || requestParameters.suggestMissingAttributeValuesRequestBody === undefined) {
            throw new runtime.RequiredError('suggestMissingAttributeValuesRequestBody','Required parameter requestParameters.suggestMissingAttributeValuesRequestBody was null or undefined when calling catalogSuggestMissingAttributeValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_entries/actions/suggest_missing_attribute_values`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogSuggestMissingAttributeValuesRequestBodyToJSON(requestParameters.suggestMissingAttributeValuesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogSuggestMissingAttributeValuesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Suggest values for a given attribute for this catalog type, for any entries where it\'s missing.
     * SuggestMissingAttributeValues Catalog
     */
    async catalogSuggestMissingAttributeValues(requestParameters: CatalogSuggestMissingAttributeValuesRequest, initOverrides?: RequestInit): Promise<CatalogSuggestMissingAttributeValuesResponseBody> {
        const response = await this.catalogSuggestMissingAttributeValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Proactively syncs a catalog type (if it\'s an external type)
     * SyncType Catalog
     */
    async catalogSyncTypeRaw(requestParameters: CatalogSyncTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogSyncType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/catalog_types/{id}/actions/sync`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Proactively syncs a catalog type (if it\'s an external type)
     * SyncType Catalog
     */
    async catalogSyncType(requestParameters: CatalogSyncTypeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.catalogSyncTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Updates the ranks of existing entries
     * UpdateEntriesRanks Catalog
     */
    async catalogUpdateEntriesRanksRaw(requestParameters: CatalogUpdateEntriesRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogUpdateEntriesRanksResponseBody>> {
        if (requestParameters.updateEntriesRanksRequestBody === null || requestParameters.updateEntriesRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateEntriesRanksRequestBody','Required parameter requestParameters.updateEntriesRanksRequestBody was null or undefined when calling catalogUpdateEntriesRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_entries/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogUpdateEntriesRanksRequestBodyToJSON(requestParameters.updateEntriesRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogUpdateEntriesRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates the ranks of existing entries
     * UpdateEntriesRanks Catalog
     */
    async catalogUpdateEntriesRanks(requestParameters: CatalogUpdateEntriesRanksRequest, initOverrides?: RequestInit): Promise<CatalogUpdateEntriesRanksResponseBody> {
        const response = await this.catalogUpdateEntriesRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing entry
     * UpdateEntry Catalog
     */
    async catalogUpdateEntryRaw(requestParameters: CatalogUpdateEntryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogUpdateEntryResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogUpdateEntry.');
        }

        if (requestParameters.updateEntryRequestBody === null || requestParameters.updateEntryRequestBody === undefined) {
            throw new runtime.RequiredError('updateEntryRequestBody','Required parameter requestParameters.updateEntryRequestBody was null or undefined when calling catalogUpdateEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_entries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogUpdateEntryRequestBodyToJSON(requestParameters.updateEntryRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogUpdateEntryResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates an existing entry
     * UpdateEntry Catalog
     */
    async catalogUpdateEntry(requestParameters: CatalogUpdateEntryRequest, initOverrides?: RequestInit): Promise<CatalogUpdateEntryResponseBody> {
        const response = await this.catalogUpdateEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing catalog type
     * UpdateType Catalog
     */
    async catalogUpdateTypeRaw(requestParameters: CatalogUpdateTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogUpdateTypeResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogUpdateType.');
        }

        if (requestParameters.updateTypeRequestBody === null || requestParameters.updateTypeRequestBody === undefined) {
            throw new runtime.RequiredError('updateTypeRequestBody','Required parameter requestParameters.updateTypeRequestBody was null or undefined when calling catalogUpdateType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogUpdateTypeRequestBodyToJSON(requestParameters.updateTypeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogUpdateTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates an existing catalog type
     * UpdateType Catalog
     */
    async catalogUpdateType(requestParameters: CatalogUpdateTypeRequest, initOverrides?: RequestInit): Promise<CatalogUpdateTypeResponseBody> {
        const response = await this.catalogUpdateTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a types schema
     * UpdateTypeSchema Catalog
     */
    async catalogUpdateTypeSchemaRaw(requestParameters: CatalogUpdateTypeSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CatalogUpdateTypeSchemaResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogUpdateTypeSchema.');
        }

        if (requestParameters.updateTypeSchemaRequestBody === null || requestParameters.updateTypeSchemaRequestBody === undefined) {
            throw new runtime.RequiredError('updateTypeSchemaRequestBody','Required parameter requestParameters.updateTypeSchemaRequestBody was null or undefined when calling catalogUpdateTypeSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/catalog_types/{id}/actions/update_schema`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogUpdateTypeSchemaRequestBodyToJSON(requestParameters.updateTypeSchemaRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CatalogUpdateTypeSchemaResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a types schema
     * UpdateTypeSchema Catalog
     */
    async catalogUpdateTypeSchema(requestParameters: CatalogUpdateTypeSchemaRequest, initOverrides?: RequestInit): Promise<CatalogUpdateTypeSchemaResponseBody> {
        const response = await this.catalogUpdateTypeSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
