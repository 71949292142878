/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Scope
 */
export interface Scope {
    /**
     * What action does this scope enable
     * @type {string}
     * @memberof Scope
     */
    description: string;
    /**
     * Unique name of the scope
     * @type {string}
     * @memberof Scope
     */
    name: ScopeNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum ScopeNameEnum {
    ActionsView = 'actions.view',
    AlertRouteCreate = 'alert_route.create',
    AlertRouteDestroy = 'alert_route.destroy',
    AlertRouteUpdate = 'alert_route.update',
    AlertRoutesView = 'alert_routes.view',
    AlertSchemaUpdate = 'alert_schema.update',
    AlertSchemaView = 'alert_schema.view',
    AlertSourceCreate = 'alert_source.create',
    AlertSourceDestroy = 'alert_source.destroy',
    AlertSourceUpdate = 'alert_source.update',
    AlertSourcesView = 'alert_sources.view',
    AlertsEdit = 'alerts.edit',
    AlertsView = 'alerts.view',
    AnnouncementPostsCreate = 'announcement_posts.create',
    AnnouncementPostsDestroy = 'announcement_posts.destroy',
    AnnouncementPostsUpdate = 'announcement_posts.update',
    AnnouncementPostsView = 'announcement_posts.view',
    AnnouncementRulesCreate = 'announcement_rules.create',
    AnnouncementRulesDestroy = 'announcement_rules.destroy',
    AnnouncementRulesUpdate = 'announcement_rules.update',
    AnnouncementRulesView = 'announcement_rules.view',
    AnnouncementRulesViewPrivateChannel = 'announcement_rules.view_private_channel',
    ApiKeysCreate = 'api_keys.create',
    ApiKeysDestroy = 'api_keys.destroy',
    ApiKeysView = 'api_keys.view',
    AttachmentsCreate = 'attachments.create',
    AttachmentsDestroy = 'attachments.destroy',
    AttachmentsView = 'attachments.view',
    AuditLogsView = 'audit_logs.view',
    BaseRbacRolesEdit = 'base_rbac_roles.edit',
    BillingSettingsEdit = 'billing_settings.edit',
    BillingSettingsView = 'billing_settings.view',
    CallRoutesCreate = 'call_routes.create',
    CallRoutesDestroy = 'call_routes.destroy',
    CallRoutesUpdate = 'call_routes.update',
    CallRoutesView = 'call_routes.view',
    CatalogEntriesCreate = 'catalog_entries.create',
    CatalogEntriesDestroy = 'catalog_entries.destroy',
    CatalogEntriesEdit = 'catalog_entries.edit',
    CatalogEntriesView = 'catalog_entries.view',
    CatalogTypesCreate = 'catalog_types.create',
    CatalogTypesDestroy = 'catalog_types.destroy',
    CatalogTypesEdit = 'catalog_types.edit',
    CatalogTypesView = 'catalog_types.view',
    CustomFieldsView = 'custom_fields.view',
    CustomRbacRolesAssign = 'custom_rbac_roles.assign',
    CustomRbacRolesCreate = 'custom_rbac_roles.create',
    CustomRbacRolesDestroy = 'custom_rbac_roles.destroy',
    CustomRbacRolesUpdate = 'custom_rbac_roles.update',
    EngineView = 'engine.view',
    EscalationPathsCreate = 'escalation_paths.create',
    EscalationPathsDestroy = 'escalation_paths.destroy',
    EscalationPathsUpdate = 'escalation_paths.update',
    EscalationPathsView = 'escalation_paths.view',
    EscalationsCreate = 'escalations.create',
    EscalationsView = 'escalations.view',
    ExternalSchedulesCreate = 'external_schedules.create',
    HolidayPublicEntriesView = 'holiday_public_entries.view',
    HolidayUserEntriesView = 'holiday_user_entries.view',
    HolidayUserFeedsCreate = 'holiday_user_feeds.create',
    HolidayUserFeedsDestroy = 'holiday_user_feeds.destroy',
    HolidayUserFeedsUpdate = 'holiday_user_feeds.update',
    HolidayUserFeedsView = 'holiday_user_feeds.view',
    IncidentFormsUpdate = 'incident_forms.update',
    IncidentMembershipsGrant = 'incident_memberships.grant',
    IncidentMembershipsRevoke = 'incident_memberships.revoke',
    IncidentRolesView = 'incident_roles.view',
    IncidentStatusesView = 'incident_statuses.view',
    IncidentTypesEdit = 'incident_types.edit',
    IncidentTypesView = 'incident_types.view',
    IncidentsBulkUpdate = 'incidents.bulk_update',
    IncidentsCreate = 'incidents.create',
    IncidentsGlobalAccess = 'incidents.global_access',
    IncidentsUpdate = 'incidents.update',
    IncidentsView = 'incidents.view',
    InsightsCustomDashboardsCreate = 'insights_custom_dashboards.create',
    InsightsCustomDashboardsDestroy = 'insights_custom_dashboards.destroy',
    InsightsCustomDashboardsUpdate = 'insights_custom_dashboards.update',
    ManagedResourcesView = 'managed_resources.view',
    NudgesCreate = 'nudges.create',
    NudgesDestroy = 'nudges.destroy',
    NudgesUpdate = 'nudges.update',
    NudgesView = 'nudges.view',
    OrganisationSettingsUpdate = 'organisation_settings.update',
    PoliciesCreate = 'policies.create',
    PoliciesDestroy = 'policies.destroy',
    PoliciesView = 'policies.view',
    PolicyViolationsDismiss = 'policy_violations.dismiss',
    PolicyViolationsRestore = 'policy_violations.restore',
    PostMortemsMarkAsComplete = 'post_mortems.mark_as_complete',
    SamlBypass = 'saml.bypass',
    SamlUpdate = 'saml.update',
    SavedViewsCreate = 'saved_views.create',
    SavedViewsDestroy = 'saved_views.destroy',
    SavedViewsUpdate = 'saved_views.update',
    SavedViewsView = 'saved_views.view',
    ScheduleOverridesCreate = 'schedule_overrides.create',
    ScheduleOverridesDestroy = 'schedule_overrides.destroy',
    ScheduleOverridesUpdate = 'schedule_overrides.update',
    SchedulePayConfigsCreate = 'schedule_pay_configs.create',
    SchedulePayConfigsDestroy = 'schedule_pay_configs.destroy',
    SchedulePayConfigsUpdate = 'schedule_pay_configs.update',
    SchedulePayConfigsUpdatePublished = 'schedule_pay_configs.update_published',
    SchedulePayConfigsView = 'schedule_pay_configs.view',
    ScheduleReportsCreate = 'schedule_reports.create',
    ScheduleReportsDestroy = 'schedule_reports.destroy',
    ScheduleReportsPublish = 'schedule_reports.publish',
    ScheduleReportsUnpublish = 'schedule_reports.unpublish',
    ScheduleReportsView = 'schedule_reports.view',
    SchedulesCreate = 'schedules.create',
    SchedulesDestroy = 'schedules.destroy',
    SchedulesFeed = 'schedules.feed',
    SchedulesUpdate = 'schedules.update',
    SchedulesView = 'schedules.view',
    ScimUpdate = 'scim.update',
    SecuritySettingsUpdate = 'security_settings.update',
    SeveritiesView = 'severities.view',
    StatusPagesConfigure = 'status_pages.configure',
    StatusPagesPublishUpdates = 'status_pages.publish_updates',
    UsersView = 'users.view',
    WebhooksManage = 'webhooks.manage',
    WorkflowsApprovePrivate = 'workflows.approve_private',
    WorkflowsCreate = 'workflows.create',
    WorkflowsCreateWorkflowRun = 'workflows.create_workflow_run',
    WorkflowsDestroy = 'workflows.destroy',
    WorkflowsUpdate = 'workflows.update',
    WorkflowsView = 'workflows.view'
}

export function ScopeFromJSON(json: any): Scope {
    return ScopeFromJSONTyped(json, false);
}

export function ScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Scope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
    };
}

export function ScopeToJSON(value?: Scope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
    };
}

