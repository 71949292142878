import { tcx } from "src/utils/tailwind-classes";

export const SecondaryPageWrapper = ({
  children,
  className,
  withPadding,
}: {
  children: React.ReactNode;
  className?: string;
  withPadding?: boolean;
}) => {
  return (
    <div
      className={tcx(
        "w-full flex-col pt-4 space-y-4",
        //  This uses the same values as PageWrapperInner - but the padding lives around the page rather than
        // around the nav + page.
        { "px-4 md:px-8 py-4 md:py-6": withPadding },
        className,
      )}
    >
      {children}
    </div>
  );
};
