import {
  StatusPageContentComponentImpact,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentComponentUptime,
} from "@incident-io/api";
import _ from "lodash";
import { DateTime } from "luxon";

import { STATUS_FROM_SEVERITY, STATUS_SEVERITY } from "../../helpers";
import { hasSameDay } from "../../time-helpers";

export const startAtFrom = (endAt: DateTime): DateTime => {
  return endAt.minus({ days: 90 });
};

type Impact = Omit<
  StatusPageContentComponentImpact,
  "start_at" | "end_at"
> & {
  start_at: DateTime;
  end_at?: DateTime;
};
export type Day = {
  date: DateTime;
  impacts: Impact[];
  severity: StatusPageContentComponentImpactStatusEnum;
};

// Group impacts into days, which can be used to present data in the component
// status. Impacts should be filtered to the specific component before passed to
// this function.
export const calculateDays = (
  startAt: DateTime,
  endAt: DateTime,
  rawImpacts: StatusPageContentComponentImpact[],
  parse: (dt: string) => DateTime,
): Day[] => {
  // Re-parse the dates, as they may have been stringified between server and
  // client, and adjust them so they are in the current time zone.
  const impacts = rawImpacts.map((impact) => ({
    ...impact,
    start_at: parse(impact.start_at),
    end_at: impact.end_at ? parse(impact.end_at) : undefined,
  }));

  const days: Day[] = [];
  const between = (cursor: DateTime, start: DateTime, end: DateTime) => {
    return start < cursor && cursor <= end;
  };

  // For each day in the given range, bucket the impacts that apply to that day.
  for (
    let day = startAt.startOf("day");
    !(day > endAt);
    day = day.plus({ day: 1 })
  ) {
    const dayAfter = day.plus({ day: 1 });
    const severities: number[] = [];
    const impactsForDay = impacts.filter((impact) => {
      const startAt = impact.start_at,
        endAt = impact.end_at;
      if (endAt === undefined) {
        // This means "ongoing", so only check the start_at
        if (hasSameDay(startAt, day) || startAt <= day) {
          // Keep track of the severity of each impact for this component
          severities.push(STATUS_SEVERITY[impact.status]);

          return true;
        }

        return false;
      }

      if (
        between(startAt, day, dayAfter) || // either you started this day
        between(endAt, day, dayAfter) || // or you ended this day
        (startAt < day && endAt > dayAfter) // or you straddle the range
      ) {
        // Keep track of the severity of each impact for this component
        severities.push(STATUS_SEVERITY[impact.status]);

        // Add it to this day's impacts
        return true;
      }

      return false;
    });

    days.push({
      date: day,
      // On the way out, transform DateTimes back into Dates, so they can be
      // serialized to rehydrate
      // TODO: I think we could just tell TS to ignore this and it'll work ok
      impacts: impactsForDay,
      // Display the highest ranking severity of all of the impacts for the day
      severity: STATUS_FROM_SEVERITY[_.max(severities) || 1],
    });
  }

  return days;
};

export const getUptime = (
  uptimes: StatusPageContentComponentUptime[],
  id: string,
): string | undefined => {
  const uptime = uptimes.find(
    (uptime) =>
      uptime.component_id === id ||
      uptime.status_page_component_group_id === id,
  );

  if (uptime?.uptime === "100.00") {
    return "100";
  }

  return uptime?.uptime;
};

export const getDataAvailableSince =
  (parse: (s: string) => DateTime) =>
  (
    uptimes: StatusPageContentComponentUptime[],
    id: string,
  ): DateTime | null => {
    const uptime = uptimes.find(
      (uptime) =>
        uptime.component_id === id ||
        uptime.status_page_component_group_id === id,
    );

    if (uptime) {
      return parse(uptime.data_available_since);
    }

    return null;
  };
