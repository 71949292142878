import {
  AIConfig,
  AIUpdateIncidentSummariesConfigRequestBody,
  ConditionGroup,
  IncidentsBuildScopeContextEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { ContentBox, Input, LoadingModal, ModalFooter } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import { ChangeEvent, useState } from "react";
import {
  FieldValues,
  Path,
  useController,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAPIMutation } from "src/utils/swr";

const problemLabelOptions = [
  { label: "Problem", value: "Problem" },
  { label: "Symptoms", value: "Symptoms" },
  { label: "Issue", value: "Issue" },
  { label: "Situation", value: "Situation" },
];
const impactLabelOptions = [
  { value: "Impact", label: "Impact" },
  { value: "Consequences", label: "Consequences" },
  { value: "Effect", label: "Effect" },
];
const causesLabelOptions = [
  { value: "Causes", label: "Causes" },
  { value: "Root cause", label: "Root cause" },
  { value: "Trigger", label: "Trigger" },
  { value: "Contributing factors", label: "Contributing factors" },
];
const stepsLabelOptions = [
  { value: "Steps to resolve", label: "Steps to resolve" },
  { value: "Steps to mitigate", label: "Steps to mitigate" },
  { value: "Response strategy", label: "Response strategy" },
];

const unknownLabelOptions = [
  { value: "Unknown", label: "Unknown" },
  { value: "Pending", label: "Pending" },
  { value: "Not known yet", label: "Not known yet" },
  { value: "Unclear", label: "Unclear" },
];

type FormData = Omit<
  AIUpdateIncidentSummariesConfigRequestBody,
  "condition_groups"
> & {
  condition_groups: ConditionGroup[];
};

export const SummariseIncidentsSettingsEditModal = ({
  onClose,
  config,
}: {
  onClose: () => void;
  config: AIConfig;
}): React.ReactElement => {
  const canEdit = useIdentity().hasScope(
    ScopeNameEnum.OrganisationSettingsUpdate,
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      problem_label: config.incident_summaries_problem_label,
      impact_label: config.incident_summaries_impact_label,
      causes_label: config.incident_summaries_causes_label,
      steps_label: config.incident_summaries_steps_label,
      unknown_label: config.incident_summaries_unknown_label,
      condition_groups: config.incident_summaries_condition_groups,
    },
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, data: FormData) => {
      await apiClient.aIUpdateIncidentSummariesConfig({
        updateIncidentSummariesConfigRequestBody: {
          ...data,
          condition_groups: conditionGroupsToGroupPayloads(
            data.condition_groups,
          ),
        },
      });
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.AiConfig,
  );

  const title = "Suggested summary settings";

  if (scopeError) {
    return <ErrorModal title={title} onClose={onClose} />;
  }

  if (scopeLoading) {
    return <LoadingModal title={title} onClose={onClose} />;
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      analyticsTrackingId="summarise-incidents-settings"
      title={title}
      disableQuickClose={false}
      onClose={onClose}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={!isDirty}
        />
      }
    >
      <div className="space-y-6">
        <ConditionGroupsEditorV2
          formMethods={formMethods}
          label="Conditions"
          helptext="If you only want to see summary suggestions for certain incidents, you can configure that here."
          name="condition_groups"
          scope={scope || []}
          emptyIntroSentence={
            <>
              We will suggest summaries for{" "}
              <span className="font-medium">all incidents</span>.
            </>
          }
          populatedIntroSentence="Summaries will be suggested when..."
          explanationStyle="available"
          wrapperClassName="mt-4"
        />
        <div>
          <span className="text-content-primary font-medium">
            Section names
          </span>
          <p className="text-content-secondary !mt-1 mb-2">
            AI will generate a summary which is broken down into four sections.
            You can customise the name for each section using the preset options
            below.
          </p>
          <ContentBox className="p-3 space-y-3">
            <SummariseSettingInput
              formMethods={formMethods}
              name="problem_label"
              label="Problem"
              placeholder="Enter a label name for this section"
              helptext={
                "This will describe the problem that is occurring from a high level"
              }
              options={problemLabelOptions}
              canEdit={canEdit}
            />
            <SummariseSettingInput
              formMethods={formMethods}
              name="impact_label"
              label="Impact"
              placeholder="Enter a label name for this section"
              helptext={
                "This will describe how this problem has affected you or your customers"
              }
              options={impactLabelOptions}
              canEdit={canEdit}
            />
            <SummariseSettingInput
              formMethods={formMethods}
              name="causes_label"
              label="Cause or trigger"
              placeholder="Enter a label name for this section"
              helptext={
                "This will describe what triggered, caused, or contributed to this incident happening"
              }
              options={causesLabelOptions}
              canEdit={canEdit}
            />
            <SummariseSettingInput
              formMethods={formMethods}
              name="steps_label"
              label="Next steps"
              placeholder="Enter a label name for this section"
              helptext={
                "This will describe what actions have been taken so far and next steps you are planning to take"
              }
              options={stepsLabelOptions}
              canEdit={canEdit}
            />
            <SummariseSettingInput
              formMethods={formMethods}
              name="unknown_label"
              label="Unknown placeholder"
              placeholder="Enter a placeholder name"
              helptext={
                "This will be used as the placeholder text when a section of the summary is not yet clear or unknown"
              }
              options={unknownLabelOptions}
              canEdit={canEdit}
            />
          </ContentBox>
        </div>
      </div>
    </Form.Modal>
  );
};

const SummariseSettingInput = <FormData extends FieldValues>({
  name,
  label,
  formMethods,
  helptext,
  options, // Does not need to contain "other" option - we add that.
  placeholder,
  canEdit = true,
}: {
  name: Path<FormData>;
  label: string;
  helptext?: string;
  placeholder: string;
  formMethods: UseFormReturn<FormData>;
  options: Array<{ label: string; value: string }>;
  canEdit?: boolean;
}) => {
  // Add the other option to the dropdown
  const dropDownOptions = options.concat([{ label: "Other", value: "Other" }]);

  const { field } = useController({
    name,
    rules: {
      validate: (value) => {
        return !!value.trim() || "Please enter a label name";
      },
      required: "Please enter a section name",
      minLength: {
        value: 1,
        message: "Please enter a section name",
      },
      maxLength: {
        value: 30,
        message: "Section name cannot exceed 30 characters",
      },
    },
  });

  const isPresetName = !!options.find(({ value }) => field.value === value);
  const [selectedOption, setSelectedOption] = useState<string>(
    isPresetName ? field.value : "Other",
  );

  const [customName, setCustomName] = useState<string | undefined>(
    isPresetName ? undefined : field.value,
  );

  const selectOnChange = (value: string) => {
    if (value !== "Other") {
      field.onChange(value);
    } else {
      field.onChange(undefined);
    }
    setCustomName(undefined);
    setSelectedOption(value);
  };

  const customNameOnChange = (value: string) => {
    setCustomName(value);
    field.onChange(value);
  };

  return (
    <Form.InputWrapper
      {...formMethods}
      name={name}
      label={label}
      helptext={helptext}
    >
      <div className="space-y-2">
        <StaticSingleSelect
          options={dropDownOptions}
          value={selectedOption}
          label={label}
          disabled={!canEdit}
          onChange={(value) => value && selectOnChange(value)}
        />
        {selectedOption === "Other" && (
          <Input
            placeholder={placeholder}
            disabled={!canEdit}
            className="w-[230px]"
            id="postmortem_custom_name"
            value={customName ?? undefined}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              customNameOnChange(e.target.value);
            }}
          />
        )}
      </div>
    </Form.InputWrapper>
  );
};
