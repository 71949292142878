import { Modal, ModalContent } from "@incident-ui";
import React from "react";

import { Gate, UpgradeRequiredMessage } from "./UpgradeRequiredMessage";

export const UpgradeRequiredModal = ({
  gate,
  featureName,
  title,
  onClose,
  analyticsTrackingId,
}: {
  featureName: string;
  gate: Gate;
  title: string;
  onClose: () => void;
  analyticsTrackingId: string;
}): React.ReactElement => {
  return (
    <Modal
      isOpen
      title={title}
      onClose={onClose}
      analyticsTrackingId={analyticsTrackingId}
    >
      <ModalContent>
        <UpgradeRequiredMessage gate={gate} featureName={featureName} />
      </ModalContent>
    </Modal>
  );
};
