/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueTemplate,
    IssueTemplateFromJSON,
    IssueTemplateFromJSONTyped,
    IssueTemplateToJSON,
} from './IssueTemplate';

/**
 * 
 * @export
 * @interface IssueTrackersV2ShowIssueTemplateResponseBody
 */
export interface IssueTrackersV2ShowIssueTemplateResponseBody {
    /**
     * 
     * @type {IssueTemplate}
     * @memberof IssueTrackersV2ShowIssueTemplateResponseBody
     */
    issue_template?: IssueTemplate;
}

export function IssueTrackersV2ShowIssueTemplateResponseBodyFromJSON(json: any): IssueTrackersV2ShowIssueTemplateResponseBody {
    return IssueTrackersV2ShowIssueTemplateResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersV2ShowIssueTemplateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersV2ShowIssueTemplateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_template': !exists(json, 'issue_template') ? undefined : IssueTemplateFromJSON(json['issue_template']),
    };
}

export function IssueTrackersV2ShowIssueTemplateResponseBodyToJSON(value?: IssueTrackersV2ShowIssueTemplateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_template': IssueTemplateToJSON(value.issue_template),
    };
}

