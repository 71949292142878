import { Icon, IconEnum, IconProps, IconSize } from "@incident-ui/Icon/Icon";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export enum InputType {
  /** @deprecated Date is deprecated, use DateInputV2 instead */
  Date = "date",
  /** @deprecated Datetimelocal is deprecated, use DateTimeInputV2 instead */
  DatetimeLocal = "datetime-local",
  Email = "email",
  Number = "number",
  Password = "password",
  Range = "range",
  Search = "search",
  Text = "text",
  Time = "time",
  Url = "url",
}

export enum InputSize {
  Medium = "medium",
  Large = "large",
}

const sizeStyles: {
  [key in InputSize]: string;
} = {
  [InputSize.Medium]: "h-7 px-2 rounded-1",
  [InputSize.Large]: "h-10 rounded-2 p-2",
};

export function containerClasses({
  invalid,
  className,
  size,
}: {
  invalid?: boolean;
  className?: string;
  size: InputSize;
}): string {
  return tcx(
    "w-full text-sm",
    sizeStyles[size],
    "focus:ring-slate-900 active:ring-slate-900",
    "text-content-primary border border-stroke focus:border-slate-900",
    "disabled:bg-surface-secondary disabled:text-slate-600 disabled:cursor-not-allowed",
    "placeholder-slate-500",
    { "border-red-500": invalid },
    className,
  );
}

export type InputProps = {
  id: string;
  className?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  value?: string;
  type?: InputType;
  size?: InputSize;
  disabled?: boolean;
  iconName?: IconEnum;
  maxLength?: number;
  pattern?: string;
  iconProps?: Partial<IconProps>;
  invalid?: boolean;
  min?: number | string;
  max?: number | string;
  inputPrefixNode?: React.ReactNode;
  insetSuffixNode?: React.ReactNode;
  ref?: React.ForwardedRef<HTMLInputElement>;
  // I don't know why, but `step` is missing from react's html attributes.
  // We need it for currency inputs, so adding it here.
} & Omit<React.HTMLAttributes<HTMLInputElement>, "type"> & { step?: string };

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      value,
      invalid,
      disabled,
      type = InputType.Text,
      size = InputSize.Large,
      step,
      iconName,
      iconProps,
      id,
      autoComplete = "off",
      inputPrefixNode,
      maxLength,
      pattern,
      insetSuffixNode: insetSuffixNode,
      ...props
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const inputProps = {
      ref,
      type,
      id,
      value,
      disabled,
      autoComplete,
      step,
      maxLength,
      pattern,
      // Disabled elements should not be keyboard-focusable. Enabled inputs get
      // priority over things with tabIndex=0, since inputs are generally more
      // important than other things on screen.
      tabIndex: disabled ? -1 : 1,
      "data-1p-ignore": "true", // hide the 1Password button on inputs: https://developer.1password.com/docs/web/compatible-website-design/
      "data-lpignore": "true", // hide LastPass: https://support.lastpass.com/s/document-item?language=en_US&bundleId=lastpass&topicId=LastPass/c_lp_prevent_fields_from_being_filled_automatically.html&_LANG=enus
      "data-form-type": "other", // hide Dashlane: https://dashlane.github.io/SAWF/#data-form-type-attribute-other-value (Guideline 4)
    };

    // There's a bug in Safari which assumes that if you don't set it, the step
    // for a datetime input is "2" (minutes), meaning that values with seconds
    // set on them can't be saved.
    if (type === InputType.DatetimeLocal) {
      inputProps.step = inputProps.step ?? "1";
    }

    // if there's an icon, we need to return a div with the input inside
    if (iconName || inputPrefixNode || insetSuffixNode) {
      return (
        <div
          className={tcx(
            containerClasses({ invalid, className, size }),
            { "bg-surface-secondary text-slate-600": disabled },
            "flex items-center gap-2",
          )}
        >
          {iconName && (
            <Icon
              id={iconName}
              size={size === InputSize.Large ? IconSize.Large : IconSize.Medium}
              {...iconProps}
            />
          )}
          {inputPrefixNode}
          <input
            // Strip all the default styles from the input
            className="w-full border-none bg-transparent focus:outline-none p-0 text-sm"
            {...props}
            {...inputProps}
          />
          {insetSuffixNode}
        </div>
      );
    }
    return (
      <input
        {...props}
        {...inputProps}
        className={containerClasses({ invalid, className, size })}
      />
    );
  },
);

Input.displayName = "Input";
