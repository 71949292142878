/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    MessageTemplateConfig,
    MessageTemplateConfigFromJSON,
    MessageTemplateConfigFromJSONTyped,
    MessageTemplateConfigToJSON,
} from './MessageTemplateConfig';
import {
    PreDefinedUpdateConfig,
    PreDefinedUpdateConfigFromJSON,
    PreDefinedUpdateConfigFromJSONTyped,
    PreDefinedUpdateConfigToJSON,
} from './PreDefinedUpdateConfig';

/**
 * 
 * @export
 * @interface StatusPageTemplate
 */
export interface StatusPageTemplate {
    /**
     * When this incident was created
     * @type {Date}
     * @memberof StatusPageTemplate
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof StatusPageTemplate
     */
    creator: Actor;
    /**
     * A unique ID for this status page template
     * @type {string}
     * @memberof StatusPageTemplate
     */
    id: string;
    /**
     * 
     * @type {MessageTemplateConfig}
     * @memberof StatusPageTemplate
     */
    message_template_config?: MessageTemplateConfig;
    /**
     * 
     * @type {PreDefinedUpdateConfig}
     * @memberof StatusPageTemplate
     */
    predefined_update_config?: PreDefinedUpdateConfig;
    /**
     * The ID of the status page this template relates to
     * @type {string}
     * @memberof StatusPageTemplate
     */
    status_page_id: string;
    /**
     * The type of incident this template relates to
     * @type {string}
     * @memberof StatusPageTemplate
     */
    status_page_incident_type: StatusPageTemplateStatusPageIncidentTypeEnum;
    /**
     * The type of template
     * @type {string}
     * @memberof StatusPageTemplate
     */
    template_type: StatusPageTemplateTemplateTypeEnum;
    /**
     * When this incident was last updated
     * @type {Date}
     * @memberof StatusPageTemplate
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageTemplateStatusPageIncidentTypeEnum {
    Incident = 'incident',
    Maintenance = 'maintenance'
}/**
* @export
* @enum {string}
*/
export enum StatusPageTemplateTemplateTypeEnum {
    MessageTemplate = 'message_template',
    PreDefinedUpdate = 'pre_defined_update'
}

export function StatusPageTemplateFromJSON(json: any): StatusPageTemplate {
    return StatusPageTemplateFromJSONTyped(json, false);
}

export function StatusPageTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'creator': ActorFromJSON(json['creator']),
        'id': json['id'],
        'message_template_config': !exists(json, 'message_template_config') ? undefined : MessageTemplateConfigFromJSON(json['message_template_config']),
        'predefined_update_config': !exists(json, 'predefined_update_config') ? undefined : PreDefinedUpdateConfigFromJSON(json['predefined_update_config']),
        'status_page_id': json['status_page_id'],
        'status_page_incident_type': json['status_page_incident_type'],
        'template_type': json['template_type'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function StatusPageTemplateToJSON(value?: StatusPageTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'id': value.id,
        'message_template_config': MessageTemplateConfigToJSON(value.message_template_config),
        'predefined_update_config': PreDefinedUpdateConfigToJSON(value.predefined_update_config),
        'status_page_id': value.status_page_id,
        'status_page_incident_type': value.status_page_incident_type,
        'template_type': value.template_type,
        'updated_at': (value.updated_at.toISOString()),
    };
}

