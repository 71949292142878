import type { SVGProps } from "react";
import * as React from "react";
const SvgSns = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#D72B6C"
      fillRule="evenodd"
      stroke="#D72B6C"
      strokeWidth={0.3}
      d="M3.164 9.418a.582.582 0 1 1-.002 1.165.582.582 0 0 1 .002-1.165Zm6.982 7.564c-3.4 0-6.316-2.483-6.926-5.824a1.159 1.159 0 0 0 1.066-.867h1.787v-.582H4.286a1.16 1.16 0 0 0-1.066-.867c.608-3.29 3.583-5.824 6.926-5.824 1.254 0 2.569.472 3.906 1.403l.332-.478c-1.436-1-2.863-1.507-4.239-1.507-3.703 0-6.996 2.87-7.545 6.552-.356.2-.6.576-.6 1.012 0 .436.244.813.6 1.012.555 3.742 3.78 6.552 7.546 6.552 1.572 0 3.183-.537 4.533-1.51l-.34-.472c-1.253.903-2.742 1.4-4.194 1.4ZM6.975 8.965c.54.132 1.214.162 1.716.162.478 0 1.112-.027 1.637-.143l-1.316 2.631a.291.291 0 0 0-.03.13v1.296c-.233.149-.63.395-.873.446v-1.742a.29.29 0 0 0-.022-.112L6.975 8.965ZM8.69 7.964c1.17 0 1.796.175 1.99.29-.194.116-.82.291-1.99.291-1.171 0-1.796-.175-1.99-.29.194-.116.819-.291 1.99-.291Zm-.873 6.109h.291c.013 0 .025-.001.037-.003.356-.046.81-.321 1.206-.575l.076-.05a.292.292 0 0 0 .136-.245v-1.386L11.18 8.58a.49.49 0 0 0 .128-.325c0-.76-1.64-.873-2.618-.873s-2.618.113-2.618.873c0 .106.035.198.093.28l1.361 3.269v1.978a.291.291 0 0 0 .291.29Zm8.437-.291a.582.582 0 1 1-.002 1.165.582.582 0 0 1 .002-1.165Zm0-8.727a.582.582 0 1 1-.002 1.164.582.582 0 0 1 .002-1.164Zm.581 4.363a.582.582 0 1 1 0 1.165.582.582 0 0 1 0-1.165Zm-2.909.873h1.787c.13.5.582.873 1.122.873a1.165 1.165 0 0 0 0-2.328c-.54 0-.992.373-1.122.873h-1.787V5.927h1.206a1.162 1.162 0 0 0 2.285-.29c0-.642-.522-1.164-1.163-1.164-.541 0-.993.372-1.122.872h-1.497a.29.29 0 0 0-.29.291V9.71h-2.037v.582h2.037v4.073a.29.29 0 0 0 .29.29h1.497a1.162 1.162 0 0 0 2.285-.29c0-.642-.522-1.164-1.163-1.164-.541 0-.993.372-1.122.873h-1.206V10.29Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSns;
