import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { ScopeNameEnum } from "src/contexts/ClientContext";

export const DeclareIncidentButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <GatedButton
      analyticsTrackingId="incident-declare-modal-open"
      theme={ButtonTheme.Primary}
      icon={IconEnum.Incident}
      onClick={onClick}
      requiredScope={ScopeNameEnum.IncidentsCreate}
    >
      <span className="mobile-hidden">Declare incident</span>
    </GatedButton>
  );
};

export const DeclareIncidentAndEscalateButton = ({
  onDeclareIncident,
  onEscalate,
}: {
  onDeclareIncident: () => void;
  onEscalate: () => void;
}) => {
  return (
    <div className="flex flex-center !max-h-8 gap-[1px]">
      <GatedButton
        analyticsTrackingId="incident-declare-modal-open"
        className="ml-auto mr-auto !rounded-r-none"
        theme={ButtonTheme.Primary}
        icon={IconEnum.Incident}
        onClick={onDeclareIncident}
        requiredScope={ScopeNameEnum.IncidentsCreate}
      >
        <span className="mobile-hidden">Declare incident</span>
      </GatedButton>
      <DropdownMenu
        triggerButton={
          <Button
            theme={ButtonTheme.Primary}
            type="button"
            className="!px-2 !rounded-l-none"
            analyticsTrackingId="declare-and-escalate-dropdown"
            icon={IconEnum.ChevronDown}
            iconProps={{
              size: IconSize.Medium,
              className: "text-white",
            }}
            title="Declare and escalate"
          />
        }
        align={"end"}
      >
        <DropdownMenuItem
          analyticsTrackingId={null}
          onSelect={onDeclareIncident}
          label={"Declare incident"}
          icon={IconEnum.Incident}
        />
        <DropdownMenuItem
          analyticsTrackingId={null}
          onSelect={onEscalate}
          icon={IconEnum.Escalate}
          label={"Escalate to someone"}
        />
      </DropdownMenu>
    </div>
  );
};
