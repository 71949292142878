import React, { createContext, useContext, useRef } from "react";

type DrawerContextT = {
  // pageDrawerRef is our main ref used by the Drawer component
  // it renders full height over the page header
  pageDrawerRef: React.RefObject<HTMLDivElement>;
};

const defaultValues = {
  pageDrawerRef: React.createRef<HTMLDivElement>(),
};
export const DrawerContext = createContext<DrawerContextT>(defaultValues);

export function DrawerProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const pageDrawerRef = useRef<HTMLDivElement>(null);

  return (
    <DrawerContext.Provider
      value={{
        pageDrawerRef,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export const useDrawer = (): DrawerContextT => {
  return useContext(DrawerContext);
};
