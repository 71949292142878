import type { SVGProps } from "react";
import * as React from "react";
const SvgStatusPage = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <rect width={4} height={10} x={8.5} y={5} fill="currentColor" rx={2} />
    <rect width={4} height={10} x={3} y={5} fill="currentColor" rx={2} />
    <rect width={4} height={10} x={14} y={5} fill="currentColor" rx={2} />
  </svg>
);
export default SvgStatusPage;
