import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { DeprecatedTable, DeprecatedTableHeaderRow } from "./DeprecatedTable";

enum SortDirection {
  Ascending = "ascending",
  Descending = "descending",
}
export type SortableColumn<T> = {
  title: string;
  key: string;
  dataIndex: string;
  sortFn?: (a: T, b: T) => number;
  alignRight?: boolean;
};

type Props<T> = {
  data: T[];
  columns: SortableColumn<T>[];
  render: (row: T) => React.ReactNode;
  finalRows?: React.ReactNode;
};

type SortInfo = {
  columnKey: string;
  direction: SortDirection;
};

/**
 * Renders a table where each column can be sorted ascending or descending
 * @param data array where each element corresponds to a row
 * @param columns array of columns, with custom sorting function and alignment
 * @param render fn which takes an entry from data and renders a table row
 * @param finalRows any additional rows to render (e.g. totals) which shouldnt be sorted
 */
export function SortableTable<T>({
  data,
  columns,
  render,
  finalRows,
}: Props<T>): React.ReactElement {
  const [sortInfo, setSortInfo] = useState<SortInfo | null>(null);

  let sortedData = data;

  if (sortInfo) {
    const selectedCol = columns.find((col) => col.key === sortInfo.columnKey);
    if (!selectedCol) {
      throw new Error(
        "unreachable: expected sort info to contain existing col",
      );
    }

    const defaultSortFn = (rowA: T, rowB: T) => {
      return (rowA[selectedCol.dataIndex] as string) <
        (rowB[selectedCol.dataIndex] as string)
        ? -1
        : 1;
    };

    const ascSortFn = selectedCol.sortFn || defaultSortFn;
    let sortFn = ascSortFn;

    if (sortInfo.direction === SortDirection.Descending) {
      sortFn = (a, b) => (ascSortFn(a, b) === 1 ? -1 : 1);
    }

    sortedData = [...data].sort(sortFn);
  }
  return (
    <DeprecatedTable>
      <DeprecatedTableHeaderRow>
        {columns.map((col) => {
          return (
            <th key={col.key} scope="col" className={tcx("font-normal")}>
              <span>
                <div
                  className={tcx(
                    "group inline-flex flex-center-y",
                    col.alignRight ? "justify-end" : "",
                  )}
                >
                  <span>{col.title}</span>
                  <Button
                    analyticsTrackingId={"schedule-sort-table"}
                    type="button"
                    onClick={() => {
                      let direction = SortDirection.Ascending;

                      // if the same column key was selected, change to the opposite direction
                      if (
                        col.key === sortInfo?.columnKey &&
                        sortInfo?.direction === SortDirection.Ascending
                      ) {
                        direction = SortDirection.Descending;
                      }

                      setSortInfo({
                        columnKey: col.key,
                        direction,
                      });
                    }}
                    title="sort"
                    theme={ButtonTheme.Unstyled}
                    icon={IconEnum.Sort}
                    iconProps={{
                      size: IconSize.Medium,
                      className: tcx(
                        "mr-0",
                        col.key === sortInfo?.columnKey
                          ? "text-blue-500"
                          : "text-content-secondary",
                      ),
                    }}
                  />
                </div>
              </span>
            </th>
          );
        })}
      </DeprecatedTableHeaderRow>
      <tbody>
        {sortedData.map((row) => render(row))}
        {finalRows}
      </tbody>
    </DeprecatedTable>
  );
}
