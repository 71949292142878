import { CreateEditJiraIssueTemplateModal } from "@incident-shared/issue-templates/CreateEditJiraIssueTemplateModal";
import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers/useAllTemplates";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { useParams } from "react-router";
import { assertUnreachable } from "src/utils/utils";

export const JiraIssueTemplateCreateEditModal = ({
  context,
}: {
  context: IssueTemplateContextEnum;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () =>
    navigate(
      context === IssueTemplateContextEnum.IncidentTicket
        ? `/settings/incident-tickets`
        : context === IssueTemplateContextEnum.FollowUp
        ? `/settings/follow-ups`
        : assertUnreachable(context),
    );

  const { id } = useParams();

  return (
    <CreateEditJiraIssueTemplateModal
      id={id}
      context={context}
      onClose={onClose}
    />
  );
};
