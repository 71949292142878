/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StatusPageContentDemoRequestBody,
    StatusPageContentDemoRequestBodyFromJSON,
    StatusPageContentDemoRequestBodyToJSON,
    StatusPageContentDemoResponseBody,
    StatusPageContentDemoResponseBodyFromJSON,
    StatusPageContentDemoResponseBodyToJSON,
    StatusPageContentListComponentImpactsResponseBody,
    StatusPageContentListComponentImpactsResponseBodyFromJSON,
    StatusPageContentListComponentImpactsResponseBodyToJSON,
    StatusPageContentListIncidentsResponseBody,
    StatusPageContentListIncidentsResponseBodyFromJSON,
    StatusPageContentListIncidentsResponseBodyToJSON,
    StatusPageContentListPagesResponseBody,
    StatusPageContentListPagesResponseBodyFromJSON,
    StatusPageContentListPagesResponseBodyToJSON,
    StatusPageContentShowIncidentResponseBody,
    StatusPageContentShowIncidentResponseBodyFromJSON,
    StatusPageContentShowIncidentResponseBodyToJSON,
    StatusPageContentShowPageResponseBody,
    StatusPageContentShowPageResponseBodyFromJSON,
    StatusPageContentShowPageResponseBodyToJSON,
    StatusPageContentSubscribeRequestBody,
    StatusPageContentSubscribeRequestBodyFromJSON,
    StatusPageContentSubscribeRequestBodyToJSON,
    StatusPageContentTrackStatusPageViewRequestBody,
    StatusPageContentTrackStatusPageViewRequestBodyFromJSON,
    StatusPageContentTrackStatusPageViewRequestBodyToJSON,
    StatusPageContentUnsubscribeRequestBody,
    StatusPageContentUnsubscribeRequestBodyFromJSON,
    StatusPageContentUnsubscribeRequestBodyToJSON,
    StatusPageContentVerifySubscriptionRequestBody,
    StatusPageContentVerifySubscriptionRequestBodyFromJSON,
    StatusPageContentVerifySubscriptionRequestBodyToJSON,
    StatusPageContentVerifySubscriptionResponseBody,
    StatusPageContentVerifySubscriptionResponseBodyFromJSON,
    StatusPageContentVerifySubscriptionResponseBodyToJSON,
} from '../models';

export interface StatusPageContentDemoRequest {
    demoRequestBody: StatusPageContentDemoRequestBody;
}

export interface StatusPageContentListComponentImpactsRequest {
    startAt: Date;
    endAt: Date;
    mainSlug: string;
    subpageSlug?: string;
}

export interface StatusPageContentListIncidentsRequest {
    mainSlug: string;
    subpageSlug?: string;
    startAt?: string;
    endAt?: string;
}

export interface StatusPageContentShowIncidentRequest {
    mainSlug: string;
    incidentId: string;
    subpageSlug?: string;
}

export interface StatusPageContentShowPageRequest {
    mainSlug: string;
    subpageSlug?: string;
}

export interface StatusPageContentSubscribeRequest {
    mainSlug: string;
    subscribeRequestBody: StatusPageContentSubscribeRequestBody;
    subpageSlug?: string;
}

export interface StatusPageContentTrackStatusPageViewRequest {
    mainSlug: string;
    trackStatusPageViewRequestBody: StatusPageContentTrackStatusPageViewRequestBody;
    subpageSlug?: string;
}

export interface StatusPageContentUnsubscribeRequest {
    mainSlug: string;
    unsubscribeRequestBody: StatusPageContentUnsubscribeRequestBody;
    subpageSlug?: string;
}

export interface StatusPageContentVerifySubscriptionRequest {
    mainSlug: string;
    verifySubscriptionRequestBody: StatusPageContentVerifySubscriptionRequestBody;
    subpageSlug?: string;
}

/**
 * 
 */
export class StatusPageContentApi extends runtime.BaseAPI {

    /**
     * Creates a demo page from an Atlassian Statuspage
     * Demo Status Page Content
     */
    async statusPageContentDemoRaw(requestParameters: StatusPageContentDemoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageContentDemoResponseBody>> {
        if (requestParameters.demoRequestBody === null || requestParameters.demoRequestBody === undefined) {
            throw new runtime.RequiredError('demoRequestBody','Required parameter requestParameters.demoRequestBody was null or undefined when calling statusPageContentDemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_demo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageContentDemoRequestBodyToJSON(requestParameters.demoRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageContentDemoResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a demo page from an Atlassian Statuspage
     * Demo Status Page Content
     */
    async statusPageContentDemo(requestParameters: StatusPageContentDemoRequest, initOverrides?: RequestInit): Promise<StatusPageContentDemoResponseBody> {
        const response = await this.statusPageContentDemoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ListComponentImpacts Status Page Content
     */
    async statusPageContentListComponentImpactsRaw(requestParameters: StatusPageContentListComponentImpactsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageContentListComponentImpactsResponseBody>> {
        if (requestParameters.startAt === null || requestParameters.startAt === undefined) {
            throw new runtime.RequiredError('startAt','Required parameter requestParameters.startAt was null or undefined when calling statusPageContentListComponentImpacts.');
        }

        if (requestParameters.endAt === null || requestParameters.endAt === undefined) {
            throw new runtime.RequiredError('endAt','Required parameter requestParameters.endAt was null or undefined when calling statusPageContentListComponentImpacts.');
        }

        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentListComponentImpacts.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        if (requestParameters.startAt !== undefined) {
            queryParameters['start_at'] = (requestParameters.startAt as any).toISOString();
        }

        if (requestParameters.endAt !== undefined) {
            queryParameters['end_at'] = (requestParameters.endAt as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}/component_impacts`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageContentListComponentImpactsResponseBodyFromJSON(jsonValue));
    }

    /**
     * ListComponentImpacts Status Page Content
     */
    async statusPageContentListComponentImpacts(requestParameters: StatusPageContentListComponentImpactsRequest, initOverrides?: RequestInit): Promise<StatusPageContentListComponentImpactsResponseBody> {
        const response = await this.statusPageContentListComponentImpactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ListIncidents Status Page Content
     */
    async statusPageContentListIncidentsRaw(requestParameters: StatusPageContentListIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageContentListIncidentsResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentListIncidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        if (requestParameters.startAt !== undefined) {
            queryParameters['start_at'] = requestParameters.startAt;
        }

        if (requestParameters.endAt !== undefined) {
            queryParameters['end_at'] = requestParameters.endAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}/incidents`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageContentListIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * ListIncidents Status Page Content
     */
    async statusPageContentListIncidents(requestParameters: StatusPageContentListIncidentsRequest, initOverrides?: RequestInit): Promise<StatusPageContentListIncidentsResponseBody> {
        const response = await this.statusPageContentListIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ListPages Status Page Content
     */
    async statusPageContentListPagesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageContentListPagesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_content_slugs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageContentListPagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * ListPages Status Page Content
     */
    async statusPageContentListPages(initOverrides?: RequestInit): Promise<StatusPageContentListPagesResponseBody> {
        const response = await this.statusPageContentListPagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * ShowIncident Status Page Content
     */
    async statusPageContentShowIncidentRaw(requestParameters: StatusPageContentShowIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageContentShowIncidentResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentShowIncident.');
        }

        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling statusPageContentShowIncident.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}/incidents/{incident_id}`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageContentShowIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * ShowIncident Status Page Content
     */
    async statusPageContentShowIncident(requestParameters: StatusPageContentShowIncidentRequest, initOverrides?: RequestInit): Promise<StatusPageContentShowIncidentResponseBody> {
        const response = await this.statusPageContentShowIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ShowPage Status Page Content
     */
    async statusPageContentShowPageRaw(requestParameters: StatusPageContentShowPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageContentShowPageResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentShowPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageContentShowPageResponseBodyFromJSON(jsonValue));
    }

    /**
     * ShowPage Status Page Content
     */
    async statusPageContentShowPage(requestParameters: StatusPageContentShowPageRequest, initOverrides?: RequestInit): Promise<StatusPageContentShowPageResponseBody> {
        const response = await this.statusPageContentShowPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Subscribe Status Page Content
     */
    async statusPageContentSubscribeRaw(requestParameters: StatusPageContentSubscribeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentSubscribe.');
        }

        if (requestParameters.subscribeRequestBody === null || requestParameters.subscribeRequestBody === undefined) {
            throw new runtime.RequiredError('subscribeRequestBody','Required parameter requestParameters.subscribeRequestBody was null or undefined when calling statusPageContentSubscribe.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}/actions/subscribe`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageContentSubscribeRequestBodyToJSON(requestParameters.subscribeRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Subscribe Status Page Content
     */
    async statusPageContentSubscribe(requestParameters: StatusPageContentSubscribeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageContentSubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * Tracks a view of a status page
     * TrackStatusPageView Status Page Content
     */
    async statusPageContentTrackStatusPageViewRaw(requestParameters: StatusPageContentTrackStatusPageViewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentTrackStatusPageView.');
        }

        if (requestParameters.trackStatusPageViewRequestBody === null || requestParameters.trackStatusPageViewRequestBody === undefined) {
            throw new runtime.RequiredError('trackStatusPageViewRequestBody','Required parameter requestParameters.trackStatusPageViewRequestBody was null or undefined when calling statusPageContentTrackStatusPageView.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}/track_status_page_view`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageContentTrackStatusPageViewRequestBodyToJSON(requestParameters.trackStatusPageViewRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Tracks a view of a status page
     * TrackStatusPageView Status Page Content
     */
    async statusPageContentTrackStatusPageView(requestParameters: StatusPageContentTrackStatusPageViewRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageContentTrackStatusPageViewRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribe Status Page Content
     */
    async statusPageContentUnsubscribeRaw(requestParameters: StatusPageContentUnsubscribeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentUnsubscribe.');
        }

        if (requestParameters.unsubscribeRequestBody === null || requestParameters.unsubscribeRequestBody === undefined) {
            throw new runtime.RequiredError('unsubscribeRequestBody','Required parameter requestParameters.unsubscribeRequestBody was null or undefined when calling statusPageContentUnsubscribe.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}/actions/unsubscribe`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageContentUnsubscribeRequestBodyToJSON(requestParameters.unsubscribeRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe Status Page Content
     */
    async statusPageContentUnsubscribe(requestParameters: StatusPageContentUnsubscribeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.statusPageContentUnsubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * VerifySubscription Status Page Content
     */
    async statusPageContentVerifySubscriptionRaw(requestParameters: StatusPageContentVerifySubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusPageContentVerifySubscriptionResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling statusPageContentVerifySubscription.');
        }

        if (requestParameters.verifySubscriptionRequestBody === null || requestParameters.verifySubscriptionRequestBody === undefined) {
            throw new runtime.RequiredError('verifySubscriptionRequestBody','Required parameter requestParameters.verifySubscriptionRequestBody was null or undefined when calling statusPageContentVerifySubscription.');
        }

        const queryParameters: any = {};

        if (requestParameters.subpageSlug !== undefined) {
            queryParameters['subpage_slug'] = requestParameters.subpageSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/status_page_content/{main_slug}/actions/verify_subscription`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatusPageContentVerifySubscriptionRequestBodyToJSON(requestParameters.verifySubscriptionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusPageContentVerifySubscriptionResponseBodyFromJSON(jsonValue));
    }

    /**
     * VerifySubscription Status Page Content
     */
    async statusPageContentVerifySubscription(requestParameters: StatusPageContentVerifySubscriptionRequest, initOverrides?: RequestInit): Promise<StatusPageContentVerifySubscriptionResponseBody> {
        const response = await this.statusPageContentVerifySubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
