export type QuestionRecord = {
  id: string;
  label: string;
  question: string;
};

// exploreAChartQuestions each correlate to one of the embedded "At a Glance" insights charts within
// the insights page. The ID is passed to AssistantOverlay when it is opened to trigger processing
// of the question. The label is used for the CTA within the dropdown on the "At a Glance" tab.
export const exploreAChartQuestions: QuestionRecord[] = [
  {
    id: "g101",
    label: "incidents by size and severity",
    question:
      "Visualize how many incidents have happened in the past 3 months, as a bar chart split by severity. After that, suggest 3 follow-on questions that could be asked.",
  },
];

// findExploreAChartQuestion returns the question record associated with the given ID or undefined
// if not found.
export const findExploreAChartQuestion = (
  id: string | null,
): QuestionRecord | undefined => {
  if (id == null) {
    return undefined;
  }

  return exploreAChartQuestions.find((question) => question.id === id);
};
