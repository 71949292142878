/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentDebrief,
    IncidentDebriefFromJSON,
    IncidentDebriefFromJSONTyped,
    IncidentDebriefToJSON,
} from './IncidentDebrief';

/**
 * 
 * @export
 * @interface DebriefsListIncidentDebriefsResponseBody
 */
export interface DebriefsListIncidentDebriefsResponseBody {
    /**
     * 
     * @type {Array<IncidentDebrief>}
     * @memberof DebriefsListIncidentDebriefsResponseBody
     */
    incident_debriefs: Array<IncidentDebrief>;
}

export function DebriefsListIncidentDebriefsResponseBodyFromJSON(json: any): DebriefsListIncidentDebriefsResponseBody {
    return DebriefsListIncidentDebriefsResponseBodyFromJSONTyped(json, false);
}

export function DebriefsListIncidentDebriefsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsListIncidentDebriefsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_debriefs': ((json['incident_debriefs'] as Array<any>).map(IncidentDebriefFromJSON)),
    };
}

export function DebriefsListIncidentDebriefsResponseBodyToJSON(value?: DebriefsListIncidentDebriefsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_debriefs': ((value.incident_debriefs as Array<any>).map(IncidentDebriefToJSON)),
    };
}

