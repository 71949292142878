import { SelectOption, SelectOptionGroup } from "@incident-ui/Select/types";
import { singular } from "pluralize";
import React from "react";
import {
  EngineParamBindingPayload,
  Reference,
} from "src/contexts/ClientContext";

// We extend the standard reference with an example, allowing us to seed an
// expression editor with example values for a given scope value.
export type ReferenceWithExample = Reference & {
  // If provided, a list of examples that can be used to preview the result of
  // evaluating a query.
  example: ReferenceExample | null;
};

// Extend the normal select option with an example binding.
export type ReferenceExampleOption = SelectOption & {
  binding: EngineParamBindingPayload;
};

export type ReferenceExampleGroup = SelectOptionGroup & {
  options: ReferenceExampleOption[];
};

export type ReferenceExample = {
  // The initially selected example option value.
  initial: string;
  // Displayed to explain what options are available in the UI.
  message: string;
  // Grouped options so you can distinguish between "Documentation" or "Recent
  // alerts/incidents/etc".
  groups: ReferenceExampleGroup[];
};

export type ExpressionDeletionUsages = {
  label: string;
  usages: React.ReactNode[];
}[];

export const expressionIsInUse = (
  usages?: ExpressionDeletionUsages,
): usages is ExpressionDeletionUsages => {
  return !!usages && usages.some((res) => res?.usages?.length > 0);
};

export const getUsagesNode = (
  usageCategories: ExpressionDeletionUsages,
): React.ReactNode => {
  const actualUsages = usageCategories.filter(
    (usage) => usage.usages.length > 0,
  );
  const usageStrings = actualUsages.map(
    (usage) =>
      `${usage.usages.length} ${
        usage.usages.length > 1
          ? usage.label.toLowerCase()
          : singular(usage.label).toLowerCase()
      }`,
  );
  if (actualUsages.length === 1) {
    // Special case: if it's just one usage, include the info about the specific usage.
    if (actualUsages[0].usages.length === 1) {
      return (
        <>
          {usageStrings[0]} ({actualUsages[0].usages[0]})
        </>
      );
    }
    return usageStrings[0];
  }
  const finalUsage = usageStrings.pop();
  return usageStrings.join(", ") + " and " + finalUsage;
};
