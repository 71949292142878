/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompetitorImport
 */
export interface CompetitorImport {
    /**
     * The name of the competitor account you are importing from
     * @type {string}
     * @memberof CompetitorImport
     */
    competitor_name: CompetitorImportCompetitorNameEnum;
    /**
     * Unique ID for the import
     * @type {string}
     * @memberof CompetitorImport
     */
    id: string;
    /**
     * The number of incidents processed so far
     * @type {number}
     * @memberof CompetitorImport
     */
    incidents_processed?: number;
    /**
     * Status of the import
     * @type {string}
     * @memberof CompetitorImport
     */
    status: CompetitorImportStatusEnum;
    /**
     * The domain of your Blameless instance. In my-org.blameless.io, this should be 'my-org'.
     * @type {string}
     * @memberof CompetitorImport
     */
    subdomain?: string;
    /**
     * The total number of incidents to process
     * @type {number}
     * @memberof CompetitorImport
     */
    total_incidents?: number;
}

/**
* @export
* @enum {string}
*/
export enum CompetitorImportCompetitorNameEnum {
    Blameless = 'blameless',
    Firehydrant = 'firehydrant',
    CoupangJira = 'coupang_jira',
    Servicenow = 'servicenow'
}/**
* @export
* @enum {string}
*/
export enum CompetitorImportStatusEnum {
    Pending = 'pending',
    Completed = 'completed',
    Failed = 'failed'
}

export function CompetitorImportFromJSON(json: any): CompetitorImport {
    return CompetitorImportFromJSONTyped(json, false);
}

export function CompetitorImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompetitorImport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'competitor_name': json['competitor_name'],
        'id': json['id'],
        'incidents_processed': !exists(json, 'incidents_processed') ? undefined : json['incidents_processed'],
        'status': json['status'],
        'subdomain': !exists(json, 'subdomain') ? undefined : json['subdomain'],
        'total_incidents': !exists(json, 'total_incidents') ? undefined : json['total_incidents'],
    };
}

export function CompetitorImportToJSON(value?: CompetitorImport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'competitor_name': value.competitor_name,
        'id': value.id,
        'incidents_processed': value.incidents_processed,
        'status': value.status,
        'subdomain': value.subdomain,
        'total_incidents': value.total_incidents,
    };
}

