import { Button, ContentBox, EmptyState, IconEnum, Toggle } from "@incident-ui";

export const IntegrationTeaserToggle = ({
  label,
  description,
}: {
  label: string;
  description?: string;
}) => {
  return (
    <ContentBox className="px-4 py-3 flex items-center justify-between">
      <Toggle
        id="fake-integration-toggle"
        label={label}
        description={
          <span className="text-content-tertiary">{description}</span>
        }
        toggleLabelClassName="text-content-tertiary"
        on={false}
        onToggle={() => null}
        disabled
      />
    </ContentBox>
  );
};

export const AutoCallTeaserToggle = ({
  providerName,
}: {
  providerName: string;
}) => {
  return (
    <IntegrationTeaserToggle
      label="Automatically create incident call"
      description={`When enabled, we'll use ${providerName} to start a video conferencing call whenever we create an incident, and assign it as the incident call link.`}
    />
  );
};

export const DocDestinationTeaser = () => {
  return (
    <EmptyState
      className="grow-0 text-content-tertiary"
      icon={IconEnum.Folder}
      title="Document destinations"
      content={`Define where your post-mortems should be created`}
      cta={
        <Button
          analyticsTrackingId={null}
          disabled
          href="/settings/post-mortem/destinations/create"
        >
          Add destination
        </Button>
      }
    />
  );
};
