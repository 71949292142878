/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface EngineParam
 */
export interface EngineParam {
    /**
     * Whether this parameter is an array
     * @type {boolean}
     * @memberof EngineParam
     */
    array: boolean;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof EngineParam
     */
    default_value?: EngineParamBinding;
    /**
     * A string describing the param
     * @type {string}
     * @memberof EngineParam
     */
    description: string;
    /**
     * Whether this parameter should be inferred as a reference from the scope
     * @type {boolean}
     * @memberof EngineParam
     */
    infer_reference: boolean;
    /**
     * Human readable label for this parameter
     * @type {string}
     * @memberof EngineParam
     */
    label: string;
    /**
     * The unique identifier for the parameter
     * @type {string}
     * @memberof EngineParam
     */
    name: string;
    /**
     * Whether this parameter is optional
     * @type {boolean}
     * @memberof EngineParam
     */
    optional: boolean;
    /**
     * The type of the parameter
     * @type {string}
     * @memberof EngineParam
     */
    type: string;
}

export function EngineParamFromJSON(json: any): EngineParam {
    return EngineParamFromJSONTyped(json, false);
}

export function EngineParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array': json['array'],
        'default_value': !exists(json, 'default_value') ? undefined : EngineParamBindingFromJSON(json['default_value']),
        'description': json['description'],
        'infer_reference': json['infer_reference'],
        'label': json['label'],
        'name': json['name'],
        'optional': json['optional'],
        'type': json['type'],
    };
}

export function EngineParamToJSON(value?: EngineParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array': value.array,
        'default_value': EngineParamBindingToJSON(value.default_value),
        'description': value.description,
        'infer_reference': value.infer_reference,
        'label': value.label,
        'name': value.name,
        'optional': value.optional,
        'type': value.type,
    };
}

