/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Action,
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * 
 * @export
 * @interface ActionsUpdateResponseBody
 */
export interface ActionsUpdateResponseBody {
    /**
     * 
     * @type {Action}
     * @memberof ActionsUpdateResponseBody
     */
    action: Action;
}

export function ActionsUpdateResponseBodyFromJSON(json: any): ActionsUpdateResponseBody {
    return ActionsUpdateResponseBodyFromJSONTyped(json, false);
}

export function ActionsUpdateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionsUpdateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': ActionFromJSON(json['action']),
    };
}

export function ActionsUpdateResponseBodyToJSON(value?: ActionsUpdateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': ActionToJSON(value.action),
    };
}

