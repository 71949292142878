import type { SVGProps } from "react";
import * as React from "react";
const SvgChecklist = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 5.5h-7A2.752 2.752 0 0 0 5.5 8.25v7A2.752 2.752 0 0 0 8.25 18h7A2.752 2.752 0 0 0 18 15.25v-7a2.752 2.752 0 0 0-2.75-2.75Zm-.398 4.452-3.397 4.5a.754.754 0 0 1-1.11.097l-1.609-1.5a.75.75 0 0 1 1.022-1.098l1.001.933 2.896-3.836a.751.751 0 0 1 1.197.905v-.001Z"
    />
    <path
      fill="currentColor"
      d="M3.8 13.748a.75.75 0 0 1-.74-.64L2.03 6.184a2.753 2.753 0 0 1 2.315-3.125L11.27 2.03a2.737 2.737 0 0 1 2.954 1.689.75.75 0 0 1-1.39.561 1.246 1.246 0 0 0-1.344-.767l-6.923 1.03a1.252 1.252 0 0 0-1.053 1.42l1.03 6.924a.75.75 0 0 1-.743.86v.001Z"
    />
  </svg>
);
export default SvgChecklist;
