import { UpgradeRequiredMessage } from "@incident-shared/billing";
import { SettingsListItemButtonsProps } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import { EmptyState, Icon, IconEnum, StackedList, Tooltip } from "@incident-ui";
import {
  RBACPrivilege,
  RBACRole,
  ScopeNameEnum,
  UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum as BaseRoleSlugEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../SettingsSubHeading";
import { RoleListItemTitle } from "./RoleListItemTitle";

type Counts = { [key: string]: number };

export const RoleList = ({
  title,
  explanation,
  roles,
  allPrivileges,
  roleCounts,
  refetchRoles,
  emptyStateText,
  accessory,
}: {
  title: string;
  explanation?: string;
  roles: RBACRole[];
  allPrivileges: RBACPrivilege[];
  roleCounts: Counts;
  refetchRoles: () => void;
  emptyStateText: string;
  accessory?: React.ReactNode;
}) => {
  return (
    <div>
      <SettingsSubHeading
        title={title}
        explanation={explanation}
        className="mb-3"
        accessory={accessory}
      />
      {roles.length === 0 ? (
        <EmptyState icon={IconEnum.Users} content={emptyStateText} />
      ) : (
        <StackedList>
          {roles.map((role) => (
            <RoleListItem
              key={role.id}
              role={role}
              roleCounts={roleCounts}
              allPrivileges={allPrivileges}
              afterDelete={refetchRoles}
            />
          ))}
        </StackedList>
      )}
    </div>
  );
};

const RoleListItem = ({
  role,
  allPrivileges,
  roleCounts,
  afterDelete,
}: {
  role: RBACRole;
  allPrivileges: RBACPrivilege[];
  roleCounts: Counts;
  afterDelete: () => void;
}) => {
  const { identity, hasScope } = useIdentity();

  const { trigger: onDelete } = useAPIMutation(
    "rBACRolesList",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.rBACRolesDestroy({ id });
      afterDelete();
    },
  );

  // The role privileges only have the ID, so we need to look them up in
  // the list of all privileges.
  const privileges = allPrivileges.filter(({ name }) => {
    return role.privileges.includes(name);
  });

  const count = roleCounts[role.slug];

  const assignmentDescription =
    !count || count === 0
      ? "It is not currently assigned to any users."
      : `It is currently assigned to ${count} ${
          count === 1 ? "user" : "users"
        }.`;

  const deleteConfirmation = (
    <div className="space-y-2">
      <div>
        Are you sure you want to delete the{" "}
        <span className="font-bold">{role.name}</span> custom role?{" "}
        {assignmentDescription}
      </div>
      <div>It has the following permissions:</div>
      <ul className="list-disc list-inside pl-4 mt-1 space-y-1">
        {privileges.map((p) => (
          <li key={p.name}>
            <div className="inline-flex items-center">
              {p.label}
              <Tooltip content={p.description}>
                <div>
                  <Icon
                    id={IconEnum.Info}
                    className="ml-1 text-content-tertiary"
                  />
                </div>
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  const editIsGatedContent = identity?.feature_gates
    .rbac_base_roles_customisable ? undefined : (
    <UpgradeRequiredMessage
      gate={{ type: "boolean" }}
      featureName={"Customising base roles"}
    />
  );

  const deleteIsGatedText = hasScope(ScopeNameEnum.CustomRbacRolesDestroy)
    ? undefined
    : "You don't have permission to delete custom user roles.";

  const buttons: SettingsListItemButtonsProps = {};

  // Instead of buttons, we render an accessory, if it's the owner role
  if (role.slug !== BaseRoleSlugEnum.Owner) {
    buttons.edit = {
      editHref: `/settings/users/roles/${role.id}/edit`,
      requiredScope: role.is_base_role
        ? ScopeNameEnum.BaseRbacRolesEdit
        : ScopeNameEnum.CustomRbacRolesUpdate,
      isGatedText: editIsGatedContent,
    };
  }

  // We only render Delete on custom roles
  if (!role.is_base_role) {
    buttons.delete = {
      resourceTitle: role.name,
      deleteConfirmationTitle: "Delete role",
      deleteConfirmationContent: deleteConfirmation,
      onDelete: () => onDelete({ id: role.id }),
      isGatedText: deleteIsGatedText,
    };
  }

  return (
    <SettingsListItem
      title={<RoleListItemTitle roleName={role.name} count={count} />}
      accessory={
        role.slug === BaseRoleSlugEnum.Owner ? (
          <Tooltip
            content={
              <>
                Owners always hold all available permissions within incident.io
                (including managing all settings and viewing all private
                incidents).
              </>
            }
          >
            <span className="text-sm flex gap-1 items-center text-content-secondary">
              Has all permissions
              <Icon id={IconEnum.Info} className="ml-1 mt-0.5" />
            </span>
          </Tooltip>
        ) : undefined
      }
      buttons={buttons}
      description={
        role.is_base_role ? null : (
          <span className="text-sm text-slate-700 mt-2">
            {role.description}
          </span>
        )
      }
    />
  );
};
