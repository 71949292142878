import {
  SeatDescriptionTypeEnum,
  UsersUpdateSeatAndRoleRequestBody,
  UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum,
  UsersUpdateSeatAndRoleRequestBodyStateEnum as UserStates,
  UserWithRoles,
} from "@incident-io/api";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

export type UserEditFormData = UsersUpdateSeatAndRoleRequestBody;

export const useUserEditFormMethods = (
  user: UserWithRoles,
  onSave: () => void,
  refetchUsers: () => void,
) => {
  const user_state = (user.state ?? UserStates.Viewer) as unknown as UserStates;

  const formMethods = useForm<UserEditFormData>({
    defaultValues: {
      state: user_state,
      base_role_slug: user.base_role
        .slug as UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum,
      custom_role_ids: user.custom_roles.map(({ id }) => id),
    },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "usersList",
    {},
    async (apiClient, data: UserEditFormData) => {
      await apiClient.usersUpdateSeatAndRole({
        id: user.id,
        updateSeatAndRoleRequestBody: {
          ...data,
          state: data.state,
        },
      });

      await refetchUsers();
    },
    { onSuccess: onSave, setError: formMethods.setError },
  );

  return { formMethods, onSubmit, saving, genericError };
};

export const getDefaultUserValues = (user: UserWithRoles) => {
  const state = (user.state ?? UserStates.Viewer) as unknown as UserStates;

  return {
    state,
    seat_types: getSeatTypes(state),
    base_role_slug: user.base_role
      .slug as UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum,
    custom_role_ids: user.custom_roles.map(({ id }) => id),
  };
};

const getSeatTypes = (state: UserStates) => {
  const seat_types: SeatDescriptionTypeEnum[] = [];

  if (state === UserStates.OnCallResponder) {
    seat_types.push(SeatDescriptionTypeEnum.OnCall);
    seat_types.push(SeatDescriptionTypeEnum.Responder);
  } else if (state === UserStates.OnCall) {
    seat_types.push(SeatDescriptionTypeEnum.OnCall);
  } else if (state === UserStates.Responder) {
    seat_types.push(SeatDescriptionTypeEnum.Responder);
  }

  return seat_types;
};
