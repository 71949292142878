import { useEffect, useRef, useState } from "react";

// useMouse gives you a ref and then tracks the position of the mouse within that ref
export function useMouse<T extends HTMLElement = never>(
  options: { resetOnExit?: boolean } = { resetOnExit: false },
) {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const ref = useRef<T>(null);

  const setMousePosition = (event: MouseEvent) => {
    if (ref.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const rect = event.currentTarget?.getBoundingClientRect();

      const x = Math.max(
        0,
        Math.round(
          event.pageX - rect.left - (window.pageXOffset || window.scrollX),
        ),
      );

      const y = Math.max(
        0,
        Math.round(
          event.pageY - rect.top - (window.pageYOffset || window.scrollY),
        ),
      );

      setPosition({ x, y });
    } else {
      setPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const resetMousePosition = () => setPosition({ x: 0, y: 0 });

  useEffect(() => {
    const element = ref?.current ? ref.current : document;
    element.addEventListener("mousemove", setMousePosition as never);
    if (options.resetOnExit)
      element.addEventListener("mouseleave", resetMousePosition as never);

    return () => {
      element.removeEventListener("mousemove", setMousePosition as never);
      if (options.resetOnExit)
        element.removeEventListener("mouseleave", resetMousePosition as never);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return { ref, ...position };
}
