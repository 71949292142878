/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZendeskConfig
 */
export interface ZendeskConfig {
    /**
     * The ID of the organisation's active Zendesk API key, if present
     * @type {string}
     * @memberof ZendeskConfig
     */
    api_key_id?: string;
    /**
     * Whether or not to mute all attached zendesk tickets
     * @type {boolean}
     * @memberof ZendeskConfig
     */
    mute_attachments: boolean;
    /**
     * Whether or not to sync organisations from Zendesk in to Catalog
     * @type {boolean}
     * @memberof ZendeskConfig
     */
    should_sync_organisations: boolean;
    /**
     * Zendesk subdomain that the integration was configured with
     * @type {string}
     * @memberof ZendeskConfig
     */
    subdomain: string;
}

export function ZendeskConfigFromJSON(json: any): ZendeskConfig {
    return ZendeskConfigFromJSONTyped(json, false);
}

export function ZendeskConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_key_id': !exists(json, 'api_key_id') ? undefined : json['api_key_id'],
        'mute_attachments': json['mute_attachments'],
        'should_sync_organisations': json['should_sync_organisations'],
        'subdomain': json['subdomain'],
    };
}

export function ZendeskConfigToJSON(value?: ZendeskConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_key_id': value.api_key_id,
        'mute_attachments': value.mute_attachments,
        'should_sync_organisations': value.should_sync_organisations,
        'subdomain': value.subdomain,
    };
}

