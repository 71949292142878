/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface TextInfo
 */
export interface TextInfo {
    /**
     * 
     * @type {TextNode}
     * @memberof TextInfo
     */
    text: TextNode;
}

export function TextInfoFromJSON(json: any): TextInfo {
    return TextInfoFromJSONTyped(json, false);
}

export function TextInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': TextNodeFromJSON(json['text']),
    };
}

export function TextInfoToJSON(value?: TextInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': TextNodeToJSON(value.text),
    };
}

