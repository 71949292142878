/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureAndComponentsItemPayload,
    StatusPageStructureAndComponentsItemPayloadFromJSON,
    StatusPageStructureAndComponentsItemPayloadFromJSONTyped,
    StatusPageStructureAndComponentsItemPayloadToJSON,
} from './StatusPageStructureAndComponentsItemPayload';

/**
 * 
 * @export
 * @interface StatusPageSetStructureAndComponentsRequestBody
 */
export interface StatusPageSetStructureAndComponentsRequestBody {
    /**
     * How should we display uptime against this page's components?
     * @type {string}
     * @memberof StatusPageSetStructureAndComponentsRequestBody
     */
    display_uptime_mode: StatusPageSetStructureAndComponentsRequestBodyDisplayUptimeModeEnum;
    /**
     * Array of components and groups to display in the status page
     * @type {Array<StatusPageStructureAndComponentsItemPayload>}
     * @memberof StatusPageSetStructureAndComponentsRequestBody
     */
    items: Array<StatusPageStructureAndComponentsItemPayload>;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageSetStructureAndComponentsRequestBodyDisplayUptimeModeEnum {
    ChartAndPercentage = 'chart_and_percentage',
    ChartOnly = 'chart_only',
    Nothing = 'nothing'
}

export function StatusPageSetStructureAndComponentsRequestBodyFromJSON(json: any): StatusPageSetStructureAndComponentsRequestBody {
    return StatusPageSetStructureAndComponentsRequestBodyFromJSONTyped(json, false);
}

export function StatusPageSetStructureAndComponentsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageSetStructureAndComponentsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'display_uptime_mode': json['display_uptime_mode'],
        'items': ((json['items'] as Array<any>).map(StatusPageStructureAndComponentsItemPayloadFromJSON)),
    };
}

export function StatusPageSetStructureAndComponentsRequestBodyToJSON(value?: StatusPageSetStructureAndComponentsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_uptime_mode': value.display_uptime_mode,
        'items': ((value.items as Array<any>).map(StatusPageStructureAndComponentsItemPayloadToJSON)),
    };
}

