/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathShift,
    EscalationPathShiftFromJSON,
    EscalationPathShiftFromJSONTyped,
    EscalationPathShiftToJSON,
} from './EscalationPathShift';

/**
 * 
 * @export
 * @interface EscalationPathLevelV2
 */
export interface EscalationPathLevelV2 {
    /**
     * The shifts containing who is on call for this level
     * @type {Array<EscalationPathShift>}
     * @memberof EscalationPathLevelV2
     */
    current_shifts: Array<EscalationPathShift>;
    /**
     * 
     * @type {EscalationPathShift}
     * @memberof EscalationPathLevelV2
     */
    next_shift_for_user?: EscalationPathShift;
    /**
     * Which level node in an escalation path is this for
     * @type {string}
     * @memberof EscalationPathLevelV2
     */
    node_id: string;
    /**
     * The level count for this level - this is the depth of the node, ignoring any non-level nodes.
     * @type {number}
     * @memberof EscalationPathLevelV2
     */
    node_level_depth: number;
}

export function EscalationPathLevelV2FromJSON(json: any): EscalationPathLevelV2 {
    return EscalationPathLevelV2FromJSONTyped(json, false);
}

export function EscalationPathLevelV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathLevelV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_shifts': ((json['current_shifts'] as Array<any>).map(EscalationPathShiftFromJSON)),
        'next_shift_for_user': !exists(json, 'next_shift_for_user') ? undefined : EscalationPathShiftFromJSON(json['next_shift_for_user']),
        'node_id': json['node_id'],
        'node_level_depth': json['node_level_depth'],
    };
}

export function EscalationPathLevelV2ToJSON(value?: EscalationPathLevelV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_shifts': ((value.current_shifts as Array<any>).map(EscalationPathShiftToJSON)),
        'next_shift_for_user': EscalationPathShiftToJSON(value.next_shift_for_user),
        'node_id': value.node_id,
        'node_level_depth': value.node_level_depth,
    };
}

