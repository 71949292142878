import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { SearchProvider } from "@incident-ui/SearchBar/SearchBar";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router";
import { TriggerSlim, WorkflowTemplate } from "src/contexts/ClientContext";

import { WorkflowChooseTriggerForm } from "../triggers/WorkflowChooseTriggerForm";
import { WorkflowsTemplateGrid } from "./WorkflowsTemplateGrid";

export type WorkflowCreateModalProps = {
  templates: WorkflowTemplate[];
  onClose: () => void;
};

export const WorkflowsCreateDrawer = ({
  templates,
  onClose,
}: WorkflowCreateModalProps) => {
  const [showChooseTrigger, setShowChooseTrigger] = useState(false);

  return (
    <>
      <Drawer
        onClose={onClose}
        width="medium"
        isInBackground={showChooseTrigger}
      >
        <DrawerContents>
          <DrawerTitle
            onClose={onClose}
            icon={IconEnum.Workflows}
            title="Create workflow"
            subtitle="Start from scratch or pick from one of our templates"
            footer={
              <Button
                theme={ButtonTheme.Primary}
                analyticsTrackingId="workflows-start-from-scratch"
                onClick={() => setShowChooseTrigger(true)}
                disabled={false}
                className="w-fit"
              >
                Create new workflow
              </Button>
            }
          />
          {/* Remove padding so we can keep the scrollbar on the right of the drawer */}
          <DrawerBody className="pr-0 pb-0 overflow-y-hidden">
            <SearchProvider>
              <WorkflowsTemplateGrid templates={templates} />
            </SearchProvider>
          </DrawerBody>
        </DrawerContents>
      </Drawer>
      <AnimatePresence>
        {showChooseTrigger && (
          <WorkflowsChooseTriggerDrawer
            onClose={() => setShowChooseTrigger(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const WorkflowsChooseTriggerDrawer = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const setTrigger = (trigger: TriggerSlim) =>
    navigate(`/workflows/create?trigger=${trigger.name}`);

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <WorkflowChooseTriggerForm
          onChooseTrigger={setTrigger}
          onClose={onClose}
        />
      </DrawerContents>
    </Drawer>
  );
};
