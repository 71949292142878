import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceGrafanaSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>Alerting</strong> section in Grafana
      </p>
      <p>
        Click <strong>Contact points</strong> and then{" "}
        <strong>Add contact point</strong>
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then copy and paste in the <strong>name</strong>,{" "}
            <strong>integration</strong> and <strong>URL</strong> below
          </>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "Integration",
            code: "Webhook",
          },
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/grafana/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Then, in the <strong>Optional webhook settings</strong> section, add
            an authorization token in the{" "}
            <strong>Authorization Header - Credentials</strong> field with the
            value
          </>
        }
        blocks={[
          {
            title: "Authorization Header",
            code: alertSourceConfig.secret_token as string,
          },
        ]}
      />
      <p>
        If you&rsquo;re using an older version of Grafana which does not support
        Bearer token authentication, you can place set the{" "}
        <strong>HTTP Basic Authentication - Password</strong> field to the value
        of the <strong>Authorization Header</strong> field instead
      </p>
      <p>
        Once you&rsquo;ve saved your channel, you can use it when creating or
        editing an alert in Grafana. After saving the alert, you can test it by
        clicking the <strong>Test</strong> button back in the contact point
        list.
      </p>
    </SetupInfoNumberedList>
  );
};
