/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface ExpressionElseBranch
 */
export interface ExpressionElseBranch {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof ExpressionElseBranch
     */
    result: EngineParamBinding;
}

export function ExpressionElseBranchFromJSON(json: any): ExpressionElseBranch {
    return ExpressionElseBranchFromJSONTyped(json, false);
}

export function ExpressionElseBranchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionElseBranch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': EngineParamBindingFromJSON(json['result']),
    };
}

export function ExpressionElseBranchToJSON(value?: ExpressionElseBranch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': EngineParamBindingToJSON(value.result),
    };
}

