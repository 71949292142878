/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Alert,
    AlertFromJSON,
    AlertFromJSONTyped,
    AlertToJSON,
} from './Alert';

/**
 * 
 * @export
 * @interface GroupedAlerts
 */
export interface GroupedAlerts {
    /**
     * 
     * @type {Array<Alert>}
     * @memberof GroupedAlerts
     */
    alerts: Array<Alert>;
}

export function GroupedAlertsFromJSON(json: any): GroupedAlerts {
    return GroupedAlertsFromJSONTyped(json, false);
}

export function GroupedAlertsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupedAlerts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alerts': ((json['alerts'] as Array<any>).map(AlertFromJSON)),
    };
}

export function GroupedAlertsToJSON(value?: GroupedAlerts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alerts': ((value.alerts as Array<any>).map(AlertToJSON)),
    };
}

