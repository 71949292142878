/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AtlassianAuthHandleConnectAppInstalledRequestBody,
    AtlassianAuthHandleConnectAppInstalledRequestBodyFromJSON,
    AtlassianAuthHandleConnectAppInstalledRequestBodyToJSON,
    AtlassianAuthHandleConnectAppUninstallRequestBody,
    AtlassianAuthHandleConnectAppUninstallRequestBodyFromJSON,
    AtlassianAuthHandleConnectAppUninstallRequestBodyToJSON,
} from '../models';

export interface AtlassianAuthHandleConnectAppInstalledRequest {
    handleConnectAppInstalledRequestBody: AtlassianAuthHandleConnectAppInstalledRequestBody;
}

export interface AtlassianAuthHandleConnectAppUninstallRequest {
    handleConnectAppUninstallRequestBody: AtlassianAuthHandleConnectAppUninstallRequestBody;
}

export interface AtlassianAuthHandleInstallRequest {
    organisationId: string;
    product: AtlassianAuthHandleInstallProductEnum;
}

export interface AtlassianAuthHandleRedirectRequest {
    code?: string;
    state?: string;
}

export interface AtlassianAuthHandleUninstallRequest {
    productType: AtlassianAuthHandleUninstallProductTypeEnum;
}

/**
 * 
 */
export class AtlassianAuthApi extends runtime.BaseAPI {

    /**
     * Endpoint to receive installation events from Atlassian for our Atlassian Connect app
     * HandleConnectAppInstalled Atlassian Auth
     */
    async atlassianAuthHandleConnectAppInstalledRaw(requestParameters: AtlassianAuthHandleConnectAppInstalledRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.handleConnectAppInstalledRequestBody === null || requestParameters.handleConnectAppInstalledRequestBody === undefined) {
            throw new runtime.RequiredError('handleConnectAppInstalledRequestBody','Required parameter requestParameters.handleConnectAppInstalledRequestBody was null or undefined when calling atlassianAuthHandleConnectAppInstalled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/atlassian_connect_app/installed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AtlassianAuthHandleConnectAppInstalledRequestBodyToJSON(requestParameters.handleConnectAppInstalledRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint to receive installation events from Atlassian for our Atlassian Connect app
     * HandleConnectAppInstalled Atlassian Auth
     */
    async atlassianAuthHandleConnectAppInstalled(requestParameters: AtlassianAuthHandleConnectAppInstalledRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianAuthHandleConnectAppInstalledRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint to receive uninstallation events from Atlassian for our Atlassian Connect app
     * HandleConnectAppUninstall Atlassian Auth
     */
    async atlassianAuthHandleConnectAppUninstallRaw(requestParameters: AtlassianAuthHandleConnectAppUninstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.handleConnectAppUninstallRequestBody === null || requestParameters.handleConnectAppUninstallRequestBody === undefined) {
            throw new runtime.RequiredError('handleConnectAppUninstallRequestBody','Required parameter requestParameters.handleConnectAppUninstallRequestBody was null or undefined when calling atlassianAuthHandleConnectAppUninstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/atlassian_connect_app/uninstalled`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AtlassianAuthHandleConnectAppUninstallRequestBodyToJSON(requestParameters.handleConnectAppUninstallRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint to receive uninstallation events from Atlassian for our Atlassian Connect app
     * HandleConnectAppUninstall Atlassian Auth
     */
    async atlassianAuthHandleConnectAppUninstall(requestParameters: AtlassianAuthHandleConnectAppUninstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianAuthHandleConnectAppUninstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Atlassian installation by redirecting to the Atlassian Cloud install endpoint
     * HandleInstall Atlassian Auth
     */
    async atlassianAuthHandleInstallRaw(requestParameters: AtlassianAuthHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling atlassianAuthHandleInstall.');
        }

        if (requestParameters.product === null || requestParameters.product === undefined) {
            throw new runtime.RequiredError('product','Required parameter requestParameters.product was null or undefined when calling atlassianAuthHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        if (requestParameters.product !== undefined) {
            queryParameters['product'] = requestParameters.product;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/atlassian_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Atlassian installation by redirecting to the Atlassian Cloud install endpoint
     * HandleInstall Atlassian Auth
     */
    async atlassianAuthHandleInstall(requestParameters: AtlassianAuthHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianAuthHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Atlassian redirect
     * HandleRedirect Atlassian Auth
     */
    async atlassianAuthHandleRedirectRaw(requestParameters: AtlassianAuthHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/atlassian`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Atlassian redirect
     * HandleRedirect Atlassian Auth
     */
    async atlassianAuthHandleRedirect(requestParameters: AtlassianAuthHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianAuthHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Atlassian integration for the productType (Jira or Confluence)
     * HandleUninstall Atlassian Auth
     */
    async atlassianAuthHandleUninstallRaw(requestParameters: AtlassianAuthHandleUninstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productType === null || requestParameters.productType === undefined) {
            throw new runtime.RequiredError('productType','Required parameter requestParameters.productType was null or undefined when calling atlassianAuthHandleUninstall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/atlassian/{productType}`.replace(`{${"productType"}}`, encodeURIComponent(String(requestParameters.productType))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Atlassian integration for the productType (Jira or Confluence)
     * HandleUninstall Atlassian Auth
     */
    async atlassianAuthHandleUninstall(requestParameters: AtlassianAuthHandleUninstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.atlassianAuthHandleUninstallRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum AtlassianAuthHandleInstallProductEnum {
    Jira = 'jira',
    Confluence = 'confluence'
}
/**
    * @export
    * @enum {string}
    */
export enum AtlassianAuthHandleUninstallProductTypeEnum {
    Jira = 'jira',
    Confluence = 'confluence'
}
