/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface AlertsCreatePriorityRequestBody
 */
export interface AlertsCreatePriorityRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof AlertsCreatePriorityRequestBody
     */
    description?: TextNode;
    /**
     * The human readable label for this priority
     * @type {string}
     * @memberof AlertsCreatePriorityRequestBody
     */
    name: string;
    /**
     * All the slugs that this priority has
     * @type {Array<string>}
     * @memberof AlertsCreatePriorityRequestBody
     */
    slugs: Array<string>;
}

export function AlertsCreatePriorityRequestBodyFromJSON(json: any): AlertsCreatePriorityRequestBody {
    return AlertsCreatePriorityRequestBodyFromJSONTyped(json, false);
}

export function AlertsCreatePriorityRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsCreatePriorityRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'name': json['name'],
        'slugs': json['slugs'],
    };
}

export function AlertsCreatePriorityRequestBodyToJSON(value?: AlertsCreatePriorityRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': TextNodeToJSON(value.description),
        'name': value.name,
        'slugs': value.slugs,
    };
}

