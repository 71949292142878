/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConditionOperation
 */
export interface ConditionOperation {
    /**
     * Human readable label to be displayed for user to select
     * @type {string}
     * @memberof ConditionOperation
     */
    label: string;
    /**
     * Unique identifier for this option
     * @type {string}
     * @memberof ConditionOperation
     */
    value: string;
}

export function ConditionOperationFromJSON(json: any): ConditionOperation {
    return ConditionOperationFromJSONTyped(json, false);
}

export function ConditionOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'value': json['value'],
    };
}

export function ConditionOperationToJSON(value?: ConditionOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'value': value.value,
    };
}

