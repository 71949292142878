import {
  AIAssistantThreadMessage,
  AIAssistantThreadMessageAuthorEnum,
} from "@incident-io/api";
import { captureException } from "@sentry/react";

// addErrorMessageToMessageLog manually adds an error message to the message log, so that it appears
// in the chat overlay for the user to see.
export const addErrorMessageToMessageLog = (
  message: string,
  addMessageToMessageLog: (message: AIAssistantThreadMessage) => void,
) => {
  addMessageToMessageLog({
    author: AIAssistantThreadMessageAuthorEnum.Assistant,
    content: [message],
    created_at: new Date(),
    id: `error-${Date.now()}-${Math.random().toString(36).slice(2, 11)}`,
    content_type: "error_message",
  });
};

export const assistantErrorHandler = (
  tags: Record<string, string | null>,
  addMessageToMessageLog: (message: string) => void,
) => {
  return (
    humanFriendlyError: string,
    exceptionErr,
    additionalTags?: Record<string, string>,
  ) => {
    captureException(exceptionErr, {
      tags: {
        ...tags,
        ...additionalTags,
      },
    });
    addMessageToMessageLog(humanFriendlyError);
  };
};
