import { CallParticipant, IncidentActivityLogEntry } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { getProviderNameAndLogo } from "src/components/incident-calls/helpers";

import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../../legacy/incident/sidebar/AvatarList";
import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemIncidentCallEnded = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_call_ended) {
    throw new Error(
      "malformed timeline item: missing incident_call_ended field",
    );
  }

  const { name } = getProviderNameAndLogo(
    item.content.incident_call_ended.incident_call.external_provider,
  );

  return {
    title: name + " call ended",
    icon: IconEnum.Call,
    colour: ColorPaletteEnum.Purple,
    quotedContent: ParticipantsList({
      participants: item.content.incident_call_ended.call_session?.participants,
    }),
  };
};

const ParticipantsList = ({
  participants,
}: {
  participants?: CallParticipant[];
}) => {
  if (!participants || participants.length === 0) {
    return null;
  }

  return (
    <dl className="flex-center-y gap-3">
      <dt className="text-content-secondary">Participants</dt>
      <AvatarList
        users={participants.map((p) => convertToMaybeUser(p))}
        modalTitle={"Call participants"}
        maxToShow={5}
        clickableType={AvatarListClickableType.OnlyOnSeeMore}
      />
    </dl>
  );
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  return maybeUser;
};
