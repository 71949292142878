/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageIncident,
    StatusPageIncidentFromJSON,
    StatusPageIncidentFromJSONTyped,
    StatusPageIncidentToJSON,
} from './StatusPageIncident';

/**
 * 
 * @export
 * @interface StatusPageListIncidentsResponseBody
 */
export interface StatusPageListIncidentsResponseBody {
    /**
     * 
     * @type {Array<StatusPageIncident>}
     * @memberof StatusPageListIncidentsResponseBody
     */
    status_page_incidents: Array<StatusPageIncident>;
}

export function StatusPageListIncidentsResponseBodyFromJSON(json: any): StatusPageListIncidentsResponseBody {
    return StatusPageListIncidentsResponseBodyFromJSONTyped(json, false);
}

export function StatusPageListIncidentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageListIncidentsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status_page_incidents': ((json['status_page_incidents'] as Array<any>).map(StatusPageIncidentFromJSON)),
    };
}

export function StatusPageListIncidentsResponseBodyToJSON(value?: StatusPageListIncidentsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_page_incidents': ((value.status_page_incidents as Array<any>).map(StatusPageIncidentToJSON)),
    };
}

