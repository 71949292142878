import { DragHandle } from "@incident-shared/settings";
import { Loader, Tooltip } from "@incident-ui";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { tcx } from "src/utils/tailwind-classes";

type SortableItem = {
  rank: number;
  id: string;
};

type Props<ItemType extends SortableItem> = {
  items: ItemType[];
  updateItemRanks: (items: ItemType[]) => void;
  canEdit: boolean;
  renderItem: (item: ItemType, index: number) => React.ReactElement;
  biggestRankAtTheTop?: boolean;
  headerRow?: React.ReactElement;
  footerRow?: React.ReactElement;
  className?: string;
  containerClassName?: string;
  saving?: boolean;
  offset?: number;
  extraWide?: boolean;
  dragHandleAtTop?: boolean;
  dragHandleClassName?: string;
  blockSortingFirstNItems?: number;
  isItemDragDisabled?: (item: ItemType) => boolean;
  itemClassName?: string;
  getNotConfigurableText?: (item: ItemType) => string | undefined;
  getGatedContent?: (item: ItemType) => React.ReactNode | undefined;
  border?: boolean;
  sortItemsOverride?: (items: ItemType[]) => ItemType[];
};

export const SettingsSortableList = <ItemType extends SortableItem>({
  items,
  updateItemRanks,
  canEdit,
  renderItem,
  biggestRankAtTheTop,
  headerRow,
  footerRow,
  className,
  containerClassName,
  dragHandleClassName,
  saving,
  offset = 0,
  extraWide,
  dragHandleAtTop,
  isItemDragDisabled = (_item) => false,
  blockSortingFirstNItems, // Hack: sometimes, we don't want to allow you to reorder the first N items because they're special. This is a hack to allow us to do that: it just rejects the proposed change from the user (not amazing UI, but works).
  itemClassName,
  getNotConfigurableText, // Not configurable items are not configurable for any org; we do not show the drag handle or edit/delete icons for them
  getGatedContent, // Gated items are normally configurable but not for this org
  border = true,
  sortItemsOverride: sortItemsOverride,
}: Props<ItemType>): React.ReactElement => {
  let sortedItems;
  if (sortItemsOverride) {
    sortedItems = sortItemsOverride(items);
  } else {
    sortedItems = _.orderBy(
      items,
      (x) => x.rank,
      biggestRankAtTheTop ? "desc" : "asc",
    );
  }

  const [locallySortedItems, setLocallySortedItems] = useState<
    ItemType[] | null
  >(null);

  // Note source and destination exist so we _could_ drag between lists at some
  // point in future, but for now they're the same thing.
  const onDragEnd = useCallback(
    async (result) => {
      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;

      if (
        blockSortingFirstNItems !== undefined &&
        toIndex < blockSortingFirstNItems
      ) {
        // Do nothing - this was illegal!
        return;
      }

      let itemsCopy = [...sortedItems];

      // Snip out the element we moved
      const [removed] = itemsCopy.splice(fromIndex, 1);

      // Insert it back into the list wherever we dragged it to
      itemsCopy.splice(toIndex, 0, removed);

      if (biggestRankAtTheTop) {
        // Reverse the list (so higher items are at the end, and get the higher index)
        itemsCopy = itemsCopy.reverse();
      }

      // Now iterate the list, set the rank of each item based on its new
      // position in the list (smallest rank -> largest rank)
      itemsCopy = itemsCopy.map((sc, i) =>
        _.merge({}, sc, { rank: i + offset }),
      );

      if (biggestRankAtTheTop) {
        // Finally, reverse the list, so they're back in biggest -> smallest order
        itemsCopy = itemsCopy.reverse();
      }

      // set our local items so we render those instead
      setLocallySortedItems(itemsCopy);

      // Update the items state
      await updateItemRanks(itemsCopy);

      // Remove our local cache
      setLocallySortedItems(null);
    },
    [
      sortedItems,
      updateItemRanks,
      biggestRankAtTheTop,
      offset,
      blockSortingFirstNItems,
    ],
  );

  const itemsToRender =
    locallySortedItems == null ? sortedItems : locallySortedItems;

  return (
    <div
      className={tcx(
        "relative",
        "border-stroke",
        extraWide && "w-3/4",
        {
          "rounded-2 shadow-sm border": border, // Standard StackedList-style border/shadow
          "overflow-hidden bg-clip-border": border, // Ensure items can't clip outside the rounded corner
        },
        containerClassName,
      )}
    >
      {/* Show a loader overlay while we're making changes */}
      {saving && (
        <div className="absolute flex items-center justify-center w-full h-full z-10 bg-surface-tertiary/50">
          <Loader />
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="items">
          {(provided) => {
            return (
              <ol
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={tcx(
                  extraWide && "!w-full",
                  "divide-y divide-slate-200 bg-surface-secondary",
                  className,
                )}
              >
                {headerRow ? <div className="bg-white">{headerRow}</div> : null}
                {itemsToRender.map((item, index) => {
                  const isNotConfigurableText = getNotConfigurableText
                    ? getNotConfigurableText(item)
                    : undefined;
                  const isNotDraggable =
                    !!isNotConfigurableText || isItemDragDisabled(item);
                  const isGatedContent = getGatedContent
                    ? getGatedContent(item)
                    : undefined;
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={
                        !canEdit ||
                        isGatedContent !== undefined ||
                        isNotDraggable
                      }
                    >
                      {(provided, scope) => {
                        return (
                          <Tooltip
                            delayDuration={50}
                            content={isNotConfigurableText || isGatedContent}
                          >
                            <li
                              className={tcx(
                                "bg-white",
                                {
                                  "bg-surface-secondary cursor-not-allowed":
                                    isNotDraggable || isGatedContent,
                                  "rounded-2 shadow-sm border ":
                                    scope.isDragging,
                                },
                                itemClassName,
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className={tcx(
                                  "flex flex-row pl-1",
                                  dragHandleAtTop
                                    ? "items-start"
                                    : "items-center",
                                )}
                              >
                                {isNotDraggable ? (
                                  <div className="w-6" />
                                ) : (
                                  <DragHandle
                                    className={tcx(
                                      "flex-0 w-6 flex justify-end",
                                      dragHandleAtTop && "mt-3.5",
                                      dragHandleClassName,
                                    )}
                                    {...provided.dragHandleProps}
                                  />
                                )}
                                {renderItem(item, index)}
                              </div>
                            </li>
                          </Tooltip>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {footerRow}
                {provided.placeholder}
              </ol>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
