import { CurrencyInputV2 } from "@incident-shared/forms/v2/inputs/CurrencyInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { currencyValidationRules } from "@incident-ui/Input/CurrencyInput";
import { areIntervalsOverlapping, isAfter, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { v4 as uuidv4 } from "uuid";

import { formatCurrency } from "../../../../utils/currency";
import { parseSimpleDate } from "../ReportGenerator";
import { TimezoneHelperText } from "../TimezoneHelperToolTip";
import {
  displayDateRangeFromLocalObj,
  toDateObjFromLocal,
  toDateObjFromUTC,
} from "./date-time-helpers";
import { DateTimeFormType, DateTimeInput } from "./DateTimeInput";
import { OverrideFormType } from "./PayConfigCreateEditForm";

export const OverrideModal = ({
  onClose,
  onAdd: onAddCallback,
  onEdit: onEditCallback,
  editingOverride,
  selectedCurrency,
  baseRateInCents,
  // This is used so we can tell if you've already got an override that
  // overlaps
  existingOverrides,
}: {
  onClose: () => void;
  onAdd: (override: OverrideFormType) => void;
  onEdit: (override: OverrideFormType) => void;
  editingOverride: OverrideFormType | null;
  selectedCurrency: string;
  baseRateInCents: number;
  existingOverrides: OverrideFormType[];
}): React.ReactElement | null => {
  const [genericError, setGenericError] = useState<string | null>(null);

  const checkIsValid = (newOverride: OverrideFormType) => {
    // check if it overlaps with any existing overrides
    for (const override of existingOverrides) {
      // No need to compare it to itself
      if (override.key === newOverride.key) {
        continue;
      }

      if (
        areIntervalsOverlapping(
          overrideFormTypeToInterval(override),
          overrideFormTypeToInterval(newOverride),
        )
      ) {
        setGenericError(
          `Overrides must be mutually exclusive. This override overlaps with ${
            override.name
          } (${displayDateRangeFromLocalObj(
            override.start_at,
            override.end_at,
          )}).`,
        );
        return false;
      }
    }
    return true;
  };

  const onAdd = (data: OverrideFormType) => {
    if (checkIsValid(data)) {
      return onAddCallback(data);
    }
    return undefined;
  };

  const onEdit = (data: OverrideFormType) => {
    if (checkIsValid(data)) {
      return onEditCallback(data);
    }
    return undefined;
  };

  const formMethods = useForm<OverrideFormType>({
    defaultValues: editingOverride || {
      key: uuidv4(),
      start_at: {
        time: "00:00",
        isEnd: false,
      },

      end_at: {
        time: "00:00",
        isEnd: true,
      },
    },
  });
  const { control, reset, setError, watch, setValue } = formMethods;

  const startAt = watch("start_at");
  const endAt = watch("end_at");

  // Default end at to be whatever we first change start at to (if the end is unset).
  useEffect(() => {
    if (
      startAt.date &&
      isAfter(toDateObjFromUTC(startAt), new Date(2000)) &&
      endAt.date === ""
    ) {
      setValue<"end_at">("end_at", startAt);
    }
  }, [setValue, startAt, endAt]);

  const onSubmit = (override: OverrideFormType) => {
    if (isAfter(toDateObjFromUTC(startAt), toDateObjFromUTC(endAt))) {
      setError("end_at", {
        type: "manual",
        message: "Holiday must end after it starts",
      });

      return undefined;
    }

    reset();

    if (override.rate_pounds === "") {
      override.rate_pounds = "0";
    }

    if (editingOverride) {
      return onEdit(override);
    }

    return onAdd(override);
  };

  const wrappedOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Add custom holiday rule"}
      onClose={wrappedOnClose}
      onSubmit={onSubmit}
      disableQuickClose
      analyticsTrackingId={"pay-config-override-modal"}
      genericError={genericError}
      footer={
        <ModalFooter onClose={wrappedOnClose} confirmButtonType="submit" />
      }
    >
      <InputV2
        formMethods={formMethods}
        label="What should this rule be called?"
        name="name"
        placeholder="Christmas 2022"
        required="Please name your rule"
      />

      <CurrencyInputV2
        formMethods={formMethods}
        label="How much do you pay per hour during this period?"
        currency={selectedCurrency}
        name="rate_pounds"
        defaultValue={editingOverride?.rate_pounds}
        rules={{ validate: currencyValidationRules }}
        contextText={`As a reminder, your base rate for this pay configuration is ${formatCurrency(
          selectedCurrency,
          baseRateInCents,
        )} per hour.`}
      />

      <Form.InputWrapper
        className="mt-2"
        label="When does this holiday start?"
        name="start_at"
      >
        <Controller
          control={control}
          name={"start_at"}
          rules={{
            validate: {
              invalid: validateDate,
            },
          }}
          render={({ field: { onChange, value, onBlur } }) => (
            <DateTimeInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              id="start_at"
            />
          )}
        />
      </Form.InputWrapper>
      <Form.InputWrapper label="When does this holiday end?" name="end_at">
        <Controller
          control={control}
          name={"end_at"}
          rules={{
            validate: {
              invalid: validateDate,
            },
          }}
          render={({ field: { onChange, value, onBlur } }) => (
            <DateTimeInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              id="end_at"
            />
          )}
        />
      </Form.InputWrapper>
      <Callout theme={CalloutTheme.Plain}>
        <TimezoneHelperText />
      </Callout>
    </Form.Modal>
  );
};

const validateDate = (dateTimeObj: DateTimeFormType): string | true => {
  const d = parseSimpleDate(dateTimeObj.date);
  if (!isValid(d)) {
    return "Please choose a valid date";
  }

  if (d.getUTCFullYear() < 2000) {
    return "This date is too far in the past";
  }

  if (d.getUTCFullYear() > 2100) {
    return "This date is too far in the future";
  }

  return true;
};

export const overrideFormTypeToInterval = (
  override: OverrideFormType,
): Interval => {
  return {
    start: toDateObjFromLocal(override.start_at),
    end: toDateObjFromLocal(override.end_at),
  };
};
