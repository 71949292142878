/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtlassianAuthHandleConnectAppInstalledRequestBody
 */
export interface AtlassianAuthHandleConnectAppInstalledRequestBody {
    /**
     * The Atlassian account ID of the user who installed the app
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    accountId?: string;
    /**
     * URL prefix for this Atlassian product instance. All of its REST endpoints begin with this `baseUrl`. Do not use the `baseUrl` as an identifier for the Atlassian product as this value may not be unique.
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    baseUrl: string;
    /**
     * The client key of the app that was installed
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    clientKey: string;
    /**
     * If the Atlassian product instance has an associated custom domain, this is the URL through which users will access the product. Any links which an app renders server-side should use this as the prefix of the link. This ensures links are rendered in the same context as the remainder of the user's site.
     * 
     * 	If a custom domain is not configured, this field will still be present but will be the same as the baseUrl. Note that API requests from your App should always use the baseUrl value.
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    displayUrl: string;
    /**
     * The key of the app that was installed
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    key: string;
    /**
     * The OAuth client ID of the app that was installed
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    oauthClientId?: string;
    /**
     * The type of Atlassian product that the app was installed into
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    productType: AtlassianAuthHandleConnectAppInstalledRequestBodyProductTypeEnum;
    /**
     * Use this string to sign outgoing JWT tokens and validate incoming JWT tokens. A new secret is shared for each installation event.
     * @type {string}
     * @memberof AtlassianAuthHandleConnectAppInstalledRequestBody
     */
    sharedSecret: string;
}

/**
* @export
* @enum {string}
*/
export enum AtlassianAuthHandleConnectAppInstalledRequestBodyProductTypeEnum {
    Jira = 'jira',
    Confluence = 'confluence'
}

export function AtlassianAuthHandleConnectAppInstalledRequestBodyFromJSON(json: any): AtlassianAuthHandleConnectAppInstalledRequestBody {
    return AtlassianAuthHandleConnectAppInstalledRequestBodyFromJSONTyped(json, false);
}

export function AtlassianAuthHandleConnectAppInstalledRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianAuthHandleConnectAppInstalledRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountId': !exists(json, 'accountId') ? undefined : json['accountId'],
        'baseUrl': json['baseUrl'],
        'clientKey': json['clientKey'],
        'displayUrl': json['displayUrl'],
        'key': json['key'],
        'oauthClientId': !exists(json, 'oauthClientId') ? undefined : json['oauthClientId'],
        'productType': json['productType'],
        'sharedSecret': json['sharedSecret'],
    };
}

export function AtlassianAuthHandleConnectAppInstalledRequestBodyToJSON(value?: AtlassianAuthHandleConnectAppInstalledRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountId': value.accountId,
        'baseUrl': value.baseUrl,
        'clientKey': value.clientKey,
        'displayUrl': value.displayUrl,
        'key': value.key,
        'oauthClientId': value.oauthClientId,
        'productType': value.productType,
        'sharedSecret': value.sharedSecret,
    };
}

