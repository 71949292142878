import type { SVGProps } from "react";
import * as React from "react";
const SvgLockOpen = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.25 10a.75.75 0 0 1-.75-.75V6c0-2.206 1.794-4 4-4s4 1.794 4 4A.75.75 0 0 1 12 6c0-1.378-1.122-2.5-2.5-2.5A2.503 2.503 0 0 0 7 6v3.25a.75.75 0 0 1-.75.75Z"
    />
    <path
      fill="currentColor"
      d="M13.25 8.5h-7.5A2.752 2.752 0 0 0 3 11.25v4A2.752 2.752 0 0 0 5.75 18h7.5A2.752 2.752 0 0 0 16 15.25v-4a2.752 2.752 0 0 0-2.75-2.75Zm-3 5.25a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 1.5 0v1Z"
    />
  </svg>
);
export default SvgLockOpen;
