import {
  EscalationPathNodeTypeEnum,
  WeekdayIntervalWeekdayEnum,
} from "@incident-io/api";
import { SingleTimezoneSelectV2 } from "@incident-shared/forms/v2/inputs/SingleTimezoneSelectV2";
import { TimeInputV2 } from "@incident-shared/forms/v2/inputs/TimeInputV2";
import { Button, ButtonTheme } from "@incident-ui";
import { Collapsible } from "@incident-ui/Collapsable/Collapsible";
import _ from "lodash";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { tcx } from "src/utils/tailwind-classes";

import {
  EscalationPathConditionType,
  EscalationPathFormData,
  PathNode,
} from "../common/types";

export const WorkingHoursInput = () => {
  const { showArticle } = useIntercom();

  const formMethods = useFormContext<EscalationPathFormData>();
  const workingHours = formMethods.watch("working_hours");
  const weekdays = Object.values(WeekdayIntervalWeekdayEnum);
  const nodes = formMethods.watch("nodes");

  const tooltip = (
    <>
      Defer escalations until the start or end of a working hour window.{" "}
      <Button
        theme={ButtonTheme.Link}
        onClick={() => showArticle(9310668)}
        analyticsTrackingId="escalation-paths-working-hours-helpcenter"
      >
        Learn more{" "}
      </Button>
    </>
  );

  const collapsibleRef = useRef<{
    open: () => void;
    getIsOpen: () => boolean;
  }>(null);

  formMethods.watch((formData) => {
    if (collapsibleRef.current?.getIsOpen()) {
      return;
    }

    // Sadly formData.nodes returns something else than formMethods.watch("nodes") so I can't reuse my nice method...
    const shouldOpenWorkingHoursDrawer = Object.values(formData.nodes ?? {})
      .filter(
        (node) => node?.data?.nodeType === EscalationPathNodeTypeEnum.IfElse,
      )
      .some(
        (node) =>
          node?.data?.ifElse?.conditionType ===
          EscalationPathConditionType.WorkingHoursActive,
      );

    if (shouldOpenWorkingHoursDrawer) {
      collapsibleRef.current?.open();
    }
  });

  return (
    <Collapsible
      ref={collapsibleRef}
      initialOpen={workingHoursAreUsed(nodes)}
      title="Working hours"
      tooltipContent={tooltip}
      className="hover:bg-surface-secondary"
    >
      <div className="flex flex-col gap-4">
        <div className="p-1 bg-white border border-stroke rounded-2 grid grid-cols-7">
          {weekdays.map((day, index) => {
            const previousDay = weekdays[index - 1];
            const nextDay = weekdays[index + 1];

            return (
              <WorkingDay
                day={day}
                key={day}
                isFirstOfGroup={
                  index === 0 ||
                  (workingHours?.weekdays &&
                    !workingHours?.weekdays[previousDay])
                }
                isLastOfGroup={
                  index === 6 ||
                  (workingHours?.weekdays && !workingHours?.weekdays[nextDay])
                }
              />
            );
          })}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex gap-x-2 items-center">
            <TimeInputV2
              name="working_hours.start_time"
              formMethods={formMethods}
              inputClassName="h-10 !w-[145px]"
            />
            <span className="text-content-secondary">to</span>
            <TimeInputV2
              name="working_hours.end_time"
              formMethods={formMethods}
              inputClassName="h-10 !w-[145px]"
            />
          </div>
        </div>
        <SingleTimezoneSelectV2
          formMethods={formMethods}
          name="working_hours.timezone"
          label="Timezone"
          fullWidth
        />
      </div>
    </Collapsible>
  );
};

const WorkingDay = ({
  day,
  isFirstOfGroup,
  isLastOfGroup,
}: {
  day: WeekdayIntervalWeekdayEnum;
  isFirstOfGroup?: boolean;
  isLastOfGroup?: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const formKey =
    `working_hours.weekdays.${day}` as "working_hours.weekdays.${string}";

  const isSelected = formMethods.watch(formKey);

  return (
    <div
      key={day}
      className={tcx(
        "text-xs font-medium text-center p-2 cursor-pointer",
        isFirstOfGroup ? "rounded-l-[5px]" : "",
        isLastOfGroup ? "rounded-r-[5px]" : "",
        isSelected
          ? "bg-surface-invert text-white"
          : "bg-white hover:bg-surface-secondary text-content-primary hover:rounded-[5px]",
      )}
      onClick={() => formMethods.setValue(formKey, !isSelected)}
    >
      {_.capitalize(day.substring(0, 2))}
    </div>
  );
};

export const workingHoursAreUsed = (
  nodes: Record<string, PathNode>,
): boolean => {
  return Object.values(nodes).some(
    (node) =>
      node.data.ifElse?.conditionType ===
      EscalationPathConditionType.WorkingHoursActive,
  );
};
