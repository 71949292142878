/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentDurationMetric,
    IncidentDurationMetricFromJSON,
    IncidentDurationMetricFromJSONTyped,
    IncidentDurationMetricToJSON,
} from './IncidentDurationMetric';

/**
 * 
 * @export
 * @interface IncidentTimestampsShowDurationMetricResponseBody
 */
export interface IncidentTimestampsShowDurationMetricResponseBody {
    /**
     * 
     * @type {IncidentDurationMetric}
     * @memberof IncidentTimestampsShowDurationMetricResponseBody
     */
    incident_duration_metric: IncidentDurationMetric;
}

export function IncidentTimestampsShowDurationMetricResponseBodyFromJSON(json: any): IncidentTimestampsShowDurationMetricResponseBody {
    return IncidentTimestampsShowDurationMetricResponseBodyFromJSONTyped(json, false);
}

export function IncidentTimestampsShowDurationMetricResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampsShowDurationMetricResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_duration_metric': IncidentDurationMetricFromJSON(json['incident_duration_metric']),
    };
}

export function IncidentTimestampsShowDurationMetricResponseBodyToJSON(value?: IncidentTimestampsShowDurationMetricResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_duration_metric': IncidentDurationMetricToJSON(value.incident_duration_metric),
    };
}

