/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSlim,
    IncidentSlimFromJSON,
    IncidentSlimFromJSONTyped,
    IncidentSlimToJSON,
} from './IncidentSlim';

/**
 * 
 * @export
 * @interface AlertWorkloadDatapoint
 */
export interface AlertWorkloadDatapoint {
    /**
     * 
     * @type {Date}
     * @memberof AlertWorkloadDatapoint
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof AlertWorkloadDatapoint
     */
    declined_incident_workload: number;
    /**
     * 
     * @type {number}
     * @memberof AlertWorkloadDatapoint
     */
    real_incident_workload: number;
    /**
     * 
     * @type {Array<IncidentSlim>}
     * @memberof AlertWorkloadDatapoint
     */
    relevant_incidents: Array<IncidentSlim>;
}

export function AlertWorkloadDatapointFromJSON(json: any): AlertWorkloadDatapoint {
    return AlertWorkloadDatapointFromJSONTyped(json, false);
}

export function AlertWorkloadDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertWorkloadDatapoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'declined_incident_workload': json['declined_incident_workload'],
        'real_incident_workload': json['real_incident_workload'],
        'relevant_incidents': ((json['relevant_incidents'] as Array<any>).map(IncidentSlimFromJSON)),
    };
}

export function AlertWorkloadDatapointToJSON(value?: AlertWorkloadDatapoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'declined_incident_workload': value.declined_incident_workload,
        'real_incident_workload': value.real_incident_workload,
        'relevant_incidents': ((value.relevant_incidents as Array<any>).map(IncidentSlimToJSON)),
    };
}

