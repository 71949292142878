import type { SVGProps } from "react";
import * as React from "react";
const SvgEnvelopeCheck = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.757 15H4.75c-.689 0-1.25-.561-1.25-1.25V8.021l5.654 3.12a1.752 1.752 0 0 0 1.692 0l5.654-3.12v2.454a.75.75 0 0 0 1.5 0V6.25a2.752 2.752 0 0 0-2.75-2.75H4.75A2.752 2.752 0 0 0 2 6.25v7.5a2.752 2.752 0 0 0 2.75 2.75h6.007a.75.75 0 0 0 0-1.5Z"
    />
    <path
      fill="currentColor"
      d="M18.71 12.151a.75.75 0 0 0-1.052.146l-2.896 3.836-1-.933a.75.75 0 1 0-1.024 1.098l1.61 1.5a.75.75 0 0 0 .511.201c.021 0 .043 0 .065-.003a.75.75 0 0 0 .533-.295l3.397-4.5a.75.75 0 0 0-.146-1.051l.001.001Z"
    />
  </svg>
);
export default SvgEnvelopeCheck;
