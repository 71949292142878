import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { useState } from "react";
import {
  DependentResource as DependentResource,
  DependentResourcePayload,
  ScopeNameEnum,
} from "src/contexts/ClientContext";

import { DeletionConfirmationModal } from "../../../settings/DeletionConfirmationModal";

export type SettingsListItemButtonsProps = {
  view?: SettingsViewButtonProps;
  edit?: SettingsEditButtonProps;
  delete?: SettingsDeleteButtonProps;
  // Allow you to set some props globally, that apply to both buttons
  requiredScope?: ScopeNameEnum;
  requiredProduct?: Product;
  isGatedText?: React.ReactNode;
  disabled?: boolean;
};

export const SettingsListItemButtons = ({
  view,
  edit,
  delete: _delete,
  requiredScope,
  requiredProduct,
  isGatedText,
  disabled,
}: SettingsListItemButtonsProps) => {
  return (
    <div className="flex gap-2">
      {view && <SettingsViewButton disabled={disabled} {...view} />}
      {edit && (
        <SettingsEditButton
          requiredScope={requiredScope}
          requiredProduct={requiredProduct}
          isGatedText={isGatedText}
          disabled={disabled}
          {...edit}
        />
      )}
      {_delete && (
        <SettingsDeleteButton
          requiredScope={requiredScope}
          requiredProduct={requiredProduct}
          isGatedText={isGatedText}
          disabled={disabled}
          {..._delete}
        />
      )}
    </div>
  );
};

type SharedButtonProps = {
  isGatedText?: React.ReactNode;
  requiredScope?: ScopeNameEnum;
  requiredProduct?: Product;
  disabled?: boolean;
};

type SettingsViewButtonProps = ViewProps;

type ViewProps = SharedButtonProps &
  (
    | {
        onView: () => Promise<void> | void;
        viewHref?: never;
      }
    | {
        onView?: never;
        viewHref: string;
      }
  );

export const SettingsViewButton = ({
  onView,
  viewHref,
  disabled,
}: SettingsViewButtonProps) => {
  return (
    <Button
      analyticsTrackingId="view-settings-item"
      icon={IconEnum.View}
      title="View"
      onClick={onView}
      size={BadgeSize.Medium}
      theme={ButtonTheme.Tertiary}
      href={viewHref}
      disabled={disabled}
    />
  );
};

type SettingsEditButtonProps = SharedButtonProps & EditProps;
type EditProps =
  | {
      onEdit: () => Promise<void> | void;
      editHref?: never;
    }
  | {
      onEdit?: never;
      editHref: string;
    };

export const SettingsEditButton = ({
  onEdit,
  editHref,
  disabled,
  isGatedText,
  requiredScope,
  requiredProduct,
}: SettingsEditButtonProps) => {
  return (
    <GatedButton
      analyticsTrackingId="edit-settings-item"
      icon={IconEnum.Edit}
      title="Edit"
      onClick={onEdit}
      size={BadgeSize.Medium}
      theme={ButtonTheme.Tertiary}
      href={editHref}
      disabledTooltipContent={disabled ? undefined : isGatedText}
      disabled={isGatedText != null || disabled}
      requiredScope={requiredScope}
      requiredProduct={requiredProduct}
    />
  );
};

type DeleteConfirmationProps =
  | {
      deleteConfirmationTitle: string;
      deleteConfirmationContent: React.ReactNode;
      noDeleteConfirmation?: never;
      resourceTitle: React.ReactNode;
    }
  | {
      deleteConfirmationTitle?: never;
      deleteConfirmationContent?: never;
      noDeleteConfirmation: true;
      resourceTitle?: React.ReactNode;
    }
  | {
      deleteConfirmationTitle?: never;
      deleteConfirmationContent?: never;
      noDeleteConfirmation?: never;
      resourceTitle: React.ReactNode;
    };

type DeletionProtectionProps = {
  dependentResources?: DependentResource[];
  extraDependentResources?: DependentResource[];
  fetchDependentResources?: DependentResourcePayload[];
};

export type SettingsDeleteButtonProps = SharedButtonProps & {
  onDelete?: () => Promise<unknown> | unknown;

  // Control the size/shape button
  theme?: ButtonTheme;
  buttonSize?: BadgeSize;

  // General overrides
  className?: string;
} & DeleteConfirmationProps &
  DeletionProtectionProps;

export const SettingsDeleteButton = ({
  dependentResources,
  fetchDependentResources,
  extraDependentResources,
  resourceTitle,
  onDelete,
  noDeleteConfirmation,
  deleteConfirmationTitle = "",
  deleteConfirmationContent = <></>,
  theme = ButtonTheme.Tertiary,
  buttonSize = BadgeSize.Medium,
  isGatedText,
  requiredScope,
  requiredProduct,
  disabled,
  className,
}: SettingsDeleteButtonProps): React.ReactElement | null => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] =
    useState<boolean>(false);

  const deleteItem = async () => {
    setIsDeleting(true);
    if (!onDelete) {
      throw new Error(
        "tried to delete settings list item, but no onDelete callback was provided",
      );
    }
    await onDelete();
    setDeleteConfirmationIsOpen(false);
    setIsDeleting(false);
  };

  const onClick = () => {
    if (noDeleteConfirmation) {
      if (onDelete) {
        onDelete();
      }
    } else {
      setDeleteConfirmationIsOpen(true);
    }
  };

  return (
    <>
      <DeletionConfirmationModal
        title={deleteConfirmationTitle}
        isOpen={deleteConfirmationIsOpen}
        onClose={() => setDeleteConfirmationIsOpen(false)}
        dependentResources={dependentResources}
        extraDependentResources={extraDependentResources}
        fetchDependentResources={fetchDependentResources}
        resourceTitle={resourceTitle}
        onDelete={deleteItem}
        isDeleting={isDeleting}
        analyticsTrackingId="delete-settings-item"
        deleteConfirmationContent={deleteConfirmationContent}
      />
      <GatedButton
        disabledTooltipContent={disabled ? undefined : isGatedText}
        disabled={!!isGatedText || disabled}
        requiredScope={requiredScope}
        requiredProduct={requiredProduct}
        loading={isDeleting}
        analyticsTrackingId="delete-settings-item"
        title="Delete"
        theme={theme}
        icon={IconEnum.Delete}
        size={buttonSize}
        onClick={onClick}
        className={className}
      />
    </>
  );
};
