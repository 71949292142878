/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SlackAPIInteractionRequestBody,
    SlackAPIInteractionRequestBodyFromJSON,
    SlackAPIInteractionRequestBodyToJSON,
    SlackAPISlashRequestBody,
    SlackAPISlashRequestBodyFromJSON,
    SlackAPISlashRequestBodyToJSON,
} from '../models';

export interface SlackAPIEventsRequest {
    any: object;
}

export interface SlackAPIInteractionRequest {
    interactionRequestBody: SlackAPIInteractionRequestBody;
}

export interface SlackAPISlashRequest {
    slashRequestBody: SlackAPISlashRequestBody;
}

/**
 * 
 */
export class SlackAPIApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving Slack events
     * Events SlackAPI
     */
    async slackAPIEventsRaw(requestParameters: SlackAPIEventsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.any === null || requestParameters.any === undefined) {
            throw new runtime.RequiredError('any','Required parameter requestParameters.any was null or undefined when calling slackAPIEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/slack/events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.any as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Endpoint for receiving Slack events
     * Events SlackAPI
     */
    async slackAPIEvents(requestParameters: SlackAPIEventsRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.slackAPIEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving Slack interactions
     * Interaction SlackAPI
     */
    async slackAPIInteractionRaw(requestParameters: SlackAPIInteractionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.interactionRequestBody === null || requestParameters.interactionRequestBody === undefined) {
            throw new runtime.RequiredError('interactionRequestBody','Required parameter requestParameters.interactionRequestBody was null or undefined when calling slackAPIInteraction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/slack/interaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SlackAPIInteractionRequestBodyToJSON(requestParameters.interactionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Endpoint for receiving Slack interactions
     * Interaction SlackAPI
     */
    async slackAPIInteraction(requestParameters: SlackAPIInteractionRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.slackAPIInteractionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving Slack slash commands
     * Slash SlackAPI
     */
    async slackAPISlashRaw(requestParameters: SlackAPISlashRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.slashRequestBody === null || requestParameters.slashRequestBody === undefined) {
            throw new runtime.RequiredError('slashRequestBody','Required parameter requestParameters.slashRequestBody was null or undefined when calling slackAPISlash.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/slack/slash`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SlackAPISlashRequestBodyToJSON(requestParameters.slashRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for receiving Slack slash commands
     * Slash SlackAPI
     */
    async slackAPISlash(requestParameters: SlackAPISlashRequest, initOverrides?: RequestInit): Promise<void> {
        await this.slackAPISlashRaw(requestParameters, initOverrides);
    }

}
