/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    IncidentAttachmentBookmarkConfig,
    IncidentAttachmentBookmarkConfigFromJSON,
    IncidentAttachmentBookmarkConfigFromJSONTyped,
    IncidentAttachmentBookmarkConfigToJSON,
} from './IncidentAttachmentBookmarkConfig';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IncidentChannelConfigsCreateBookmarkRequestBody
 */
export interface IncidentChannelConfigsCreateBookmarkRequestBody {
    /**
     * 
     * @type {IncidentAttachmentBookmarkConfig}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    attachment_config?: IncidentAttachmentBookmarkConfig;
    /**
     * Type of bookmark
     * @type {string}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    bookmark_type: IncidentChannelConfigsCreateBookmarkRequestBodyBookmarkTypeEnum;
    /**
     * What conditions must be true for this bookmark to be enabled in an incident channel?
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * 
     * @type {string}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    custom_emoji?: string;
    /**
     * ID of the custom field when the bookmark involves a custom field
     * @type {string}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    custom_field_id?: string;
    /**
     * 
     * @type {TextNode}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    custom_templated_url?: TextNode;
    /**
     * Text to use when the bookmark is of the custom type
     * @type {string}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    custom_text?: string;
    /**
     * URL to use when the bookmark is of the custom type
     * @type {string}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    custom_url?: string;
    /**
     * ID of the incident role when the bookmark involves a role
     * @type {string}
     * @memberof IncidentChannelConfigsCreateBookmarkRequestBody
     */
    incident_role_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentChannelConfigsCreateBookmarkRequestBodyBookmarkTypeEnum {
    Status = 'bookmarks_status',
    Severity = 'bookmarks_severity',
    Role = 'bookmarks_role',
    CustomField = 'bookmarks_custom_field',
    IncidentType = 'bookmarks_incident_type',
    JiraTicket = 'bookmarks_jira_ticket',
    StatusPage = 'bookmarks_status_page',
    Call = 'bookmarks_call',
    IncidentAttachment = 'bookmarks_incident_attachment',
    Postmortem = 'bookmarks_postmortem',
    Custom = 'bookmarks_custom',
    Homepage = 'bookmarks_homepage'
}

export function IncidentChannelConfigsCreateBookmarkRequestBodyFromJSON(json: any): IncidentChannelConfigsCreateBookmarkRequestBody {
    return IncidentChannelConfigsCreateBookmarkRequestBodyFromJSONTyped(json, false);
}

export function IncidentChannelConfigsCreateBookmarkRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelConfigsCreateBookmarkRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachment_config': !exists(json, 'attachment_config') ? undefined : IncidentAttachmentBookmarkConfigFromJSON(json['attachment_config']),
        'bookmark_type': json['bookmark_type'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'custom_emoji': !exists(json, 'custom_emoji') ? undefined : json['custom_emoji'],
        'custom_field_id': !exists(json, 'custom_field_id') ? undefined : json['custom_field_id'],
        'custom_templated_url': !exists(json, 'custom_templated_url') ? undefined : TextNodeFromJSON(json['custom_templated_url']),
        'custom_text': !exists(json, 'custom_text') ? undefined : json['custom_text'],
        'custom_url': !exists(json, 'custom_url') ? undefined : json['custom_url'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
    };
}

export function IncidentChannelConfigsCreateBookmarkRequestBodyToJSON(value?: IncidentChannelConfigsCreateBookmarkRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachment_config': IncidentAttachmentBookmarkConfigToJSON(value.attachment_config),
        'bookmark_type': value.bookmark_type,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'custom_emoji': value.custom_emoji,
        'custom_field_id': value.custom_field_id,
        'custom_templated_url': TextNodeToJSON(value.custom_templated_url),
        'custom_text': value.custom_text,
        'custom_url': value.custom_url,
        'incident_role_id': value.incident_role_id,
    };
}

