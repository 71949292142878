import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { ActorAvatar } from "@incident-ui/Avatar/ActorAvatar";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import _ from "lodash";
import {
  Actor,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  Stream,
} from "src/contexts/ClientContext";

import { IncidentHeaderModal } from "../../../../routes/legacy/IncidentRoute";
import { useNavigateToModal } from "../../../../utils/query-params";
import { incidentInEditableStatus } from "../helpers";
import { useIncident } from "../hooks";
import { Sidebar } from "./Components";
import { Participants, StreamParticipants } from "./Participants";

export function StreamRoleAssignments({
  stream,
}: {
  stream: Stream | null;
}): React.ReactElement {
  const assignments = _.sortBy(
    stream?.incident_role_assignments ?? [],
    // Lead, Reporter, the rest
    ({ role }, i) => {
      if (role.role_type === RoleTypeEnum.Lead) {
        return -2;
      }
      return i;
    },
  ).filter(
    // Only show assigned roles and don't show the reporter, the lead will always be assigned
    ({ role, assignee }) =>
      assignee && role.role_type !== RoleTypeEnum.Reporter,
  );

  const navigateToModal = useNavigateToModal();
  return (
    <Sidebar.Section title="Assignees" className="pb-4">
      {stream == null ? (
        <LoadingBar className="w-full h-14" />
      ) : (
        <div>
          {assignments.map(({ role, assignee }) => {
            if (role.role_type === RoleTypeEnum.Custom && !assignee)
              return null;
            return (
              <Sidebar.Entry
                key={role.id}
                label={role.stream_name}
                value={
                  <Assignee
                    editable={true}
                    onEdit={() =>
                      navigateToModal(IncidentHeaderModal.EditRoleAssignments)
                    }
                    analyticsTrackingId={"edit-role"}
                    iconNode={
                      <Avatar
                        size={IconSize.Medium}
                        url={assignee?.avatar_url}
                        name={assignee?.name}
                      />
                    }
                  >
                    {assignee?.name || "Not assigned"}
                  </Assignee>
                }
              />
            );
          })}
          <StreamParticipants stream={stream} />
        </div>
      )}
    </Sidebar.Section>
  );
}

export function IncidentRoleAssignments({
  incidentId,
  onEdit,
}: {
  incidentId: string | null;
  onEdit: () => void;
}): React.ReactElement {
  const { incident } = useIncident(incidentId);
  const assignments = _.sortBy(
    incident?.incident_role_assignments ?? [],
    // Lead, the rest, Reporter
    ({ role }) => {
      if (role.role_type === RoleTypeEnum.Lead) {
        return -2;
      }
      if (role.role_type === RoleTypeEnum.Reporter) {
        return 1;
      }
      return 0;
    },
  ).filter(
    // Only show assigned roles, except the lead
    ({ role, assignee }) => role.role_type === RoleTypeEnum.Lead || assignee,
  );

  return (
    <Sidebar.Section
      title="Roles"
      accessory={
        <Tooltip content="Edit roles">
          <Sidebar.TitleAccessoryButton
            icon={IconEnum.Edit}
            title=""
            onClick={onEdit}
            analyticsTrackingId="edit-roles"
          />
        </Tooltip>
      }
    >
      {incident == null ? (
        <LoadingBar className="w-full h-14" />
      ) : (
        <>
          {assignments.map(({ role, assignee }) => {
            const editable =
              role.role_type !== RoleTypeEnum.Reporter &&
              incidentInEditableStatus(incident);

            // If there's a non-lead role that's unassigned, ignore it.
            if (role.role_type === RoleTypeEnum.Custom && !assignee)
              return null;

            return (
              <Sidebar.Entry
                label={role.name}
                key={role.id}
                value={
                  <Assignee
                    editable={editable}
                    onEdit={onEdit}
                    analyticsTrackingId={"edit-role"}
                    iconNode={
                      assignee?.name ? (
                        <Avatar
                          size={IconSize.Medium}
                          url={assignee?.avatar_url}
                          name={assignee?.name}
                        />
                      ) : (
                        <Icon id={IconEnum.UserAdd} size={IconSize.Medium} />
                      )
                    }
                  >
                    {assignee?.name || "Assign"}
                  </Assignee>
                }
              />
            );
          })}
          {!incident.creator.user && (
            <NonUserCreator creator={incident.creator} />
          )}
          <Participants incidentId={incident.id} />
        </>
      )}
    </Sidebar.Section>
  );
}

export const Assignee = ({
  editable,
  onEdit,
  analyticsTrackingId,
  iconNode,
  children,
}: React.PropsWithChildren<{
  editable: boolean;
  onEdit: () => void;
  analyticsTrackingId: string;
  iconNode?: React.ReactNode;
}>) => {
  return editable ? (
    <Sidebar.ValueButton
      onClick={onEdit}
      analyticsTrackingId={analyticsTrackingId}
      iconNode={iconNode}
    >
      {children}
    </Sidebar.ValueButton>
  ) : (
    <Badge
      size={BadgeSize.Medium}
      theme={BadgeTheme.Naked}
      className="text-content-primary gap-1 flex"
    >
      {iconNode}
      {children}
    </Badge>
  );
};

const NonUserCreator = ({ creator }: { creator: Actor }) => {
  if (creator.api_key) {
    return (
      <Sidebar.Entry
        label="Created via API key"
        value={
          <Sidebar.ValueButton
            href="/settings/api-keys"
            openInNewTab
            analyticsTrackingId="created-via-api-key"
            icon={IconEnum.Key}
          >
            {creator.api_key.name}
          </Sidebar.ValueButton>
        }
      />
    );
  }
  if (creator.external_resource) {
    return (
      <Sidebar.Entry
        label="Triggered by"
        value={
          <Sidebar.ValueButton
            href={creator.external_resource.permalink}
            openInNewTab
            analyticsTrackingId="created-via-external-resource"
          >
            <ActorAvatar actor={creator} size={IconSize.Medium} />
            {creator.external_resource.resource_type_label}
          </Sidebar.ValueButton>
        }
      />
    );
  }
  return null;
};
