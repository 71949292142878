/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueTemplateSlim,
    IssueTemplateSlimFromJSON,
    IssueTemplateSlimFromJSONTyped,
    IssueTemplateSlimToJSON,
} from './IssueTemplateSlim';

/**
 * 
 * @export
 * @interface IssueTrackersV2ListIssueTemplatesResponseBody
 */
export interface IssueTrackersV2ListIssueTemplatesResponseBody {
    /**
     * 
     * @type {Array<IssueTemplateSlim>}
     * @memberof IssueTrackersV2ListIssueTemplatesResponseBody
     */
    issue_templates: Array<IssueTemplateSlim>;
}

export function IssueTrackersV2ListIssueTemplatesResponseBodyFromJSON(json: any): IssueTrackersV2ListIssueTemplatesResponseBody {
    return IssueTrackersV2ListIssueTemplatesResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersV2ListIssueTemplatesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersV2ListIssueTemplatesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_templates': ((json['issue_templates'] as Array<any>).map(IssueTemplateSlimFromJSON)),
    };
}

export function IssueTrackersV2ListIssueTemplatesResponseBodyToJSON(value?: IssueTrackersV2ListIssueTemplatesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_templates': ((value.issue_templates as Array<any>).map(IssueTemplateSlimToJSON)),
    };
}

