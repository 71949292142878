import { Actor, IntegrationActorNameEnum } from "@incident-io/api";
import { Button, ButtonTheme } from "@incident-ui";
import React from "react";

interface ActorSourceProps {
  actor: Actor;
  useLowerCaseAutomation?: boolean;
}

export const ActorSource = ({
  actor,
  useLowerCaseAutomation = false,
}: ActorSourceProps): React.ReactElement => {
  if (actor.user) {
    return <>{actor.user.name}</>;
  }
  if (actor.workflow) {
    return (
      <a href={`/workflows/${actor.workflow.id}`}>
        Workflow: {actor.workflow.name}
      </a>
    );
  }
  if (actor.api_key) {
    return (
      <a href={"/settings/api-keys"} target="_blank" rel="noopener noreferrer">
        API Key: {actor.api_key.name}
      </a>
    );
  }
  if (actor.integration) {
    if (actor.integration.name === IntegrationActorNameEnum.IncidentBackfill) {
      return <>someone</>;
    }

    if (actor.integration?.name === IntegrationActorNameEnum.LiveCall) {
      return <>a live call</>;
    }
  }
  if (actor.alert) {
    return (
      <Button
        analyticsTrackingId={null}
        href={`/alerts/${actor.alert.id}/details`}
        theme={ButtonTheme.Link}
      >
        an alert
      </Button>
    );
  }

  if (useLowerCaseAutomation) {
    return <>an automation</>;
  }

  return <>An automation</>;
};
