import type { SVGProps } from "react";
import * as React from "react";
const SvgMultiSelect = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m6.913 10.876-2.877 3.74-.755-.755a.75.75 0 1 0-1.061 1.06l1.359 1.36c.142.14.332.22.53.22l.049-.001a.752.752 0 0 0 .546-.291L8.1 11.792a.751.751 0 0 0-1.19-.915l.002-.001ZM16.75 5.5h-6a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5ZM16.75 13h-6a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5ZM6.25 3h-2.5A1.75 1.75 0 0 0 2 4.75v2.5C2 8.216 2.785 9 3.75 9h2.5A1.75 1.75 0 0 0 8 7.25v-2.5A1.75 1.75 0 0 0 6.25 3Z"
    />
  </svg>
);
export default SvgMultiSelect;
