import type { SVGProps } from "react";
import * as React from "react";
const SvgComplexList = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#95959D"
      d="M6.5 7.357a.643.643 0 0 0 0 1.286h3a.643.643 0 1 0 0-1.286h-3ZM4.143 10.357c0-.355.288-.643.643-.643h3a.643.643 0 1 1 0 1.286h-3a.643.643 0 0 1-.643-.643ZM8.214 5a.643.643 0 0 0 0 1.286h3a.643.643 0 0 0 0-1.286h-3Z"
    />
    <path
      fill="#95959D"
      fillRule="evenodd"
      d="M4.357 2A2.357 2.357 0 0 0 2 4.357v7.286A2.357 2.357 0 0 0 4.357 14h7.286A2.357 2.357 0 0 0 14 11.643V4.357A2.357 2.357 0 0 0 11.643 2H4.357ZM3.286 4.357c0-.592.48-1.071 1.071-1.071h7.286c.592 0 1.071.48 1.071 1.071v7.286c0 .592-.48 1.071-1.071 1.071H4.357c-.592 0-1.071-.48-1.071-1.071V4.357Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComplexList;
