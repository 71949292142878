/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionsUpdateRequestBody
 */
export interface ActionsUpdateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ActionsUpdateRequestBody
     */
    assignee_id?: string;
    /**
     * Description of the action
     * @type {string}
     * @memberof ActionsUpdateRequestBody
     */
    description: string;
    /**
     * Status of the action
     * @type {string}
     * @memberof ActionsUpdateRequestBody
     */
    status: ActionsUpdateRequestBodyStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum ActionsUpdateRequestBodyStatusEnum {
    Outstanding = 'outstanding',
    Completed = 'completed',
    Deleted = 'deleted',
    NotDoing = 'not_doing'
}

export function ActionsUpdateRequestBodyFromJSON(json: any): ActionsUpdateRequestBody {
    return ActionsUpdateRequestBodyFromJSONTyped(json, false);
}

export function ActionsUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionsUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'description': json['description'],
        'status': json['status'],
    };
}

export function ActionsUpdateRequestBodyToJSON(value?: ActionsUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': value.assignee_id,
        'description': value.description,
        'status': value.status,
    };
}

