import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { AnnouncementSettingsPage } from "./AnnouncementSettingsPage";
import { AnnouncementPostEditPage } from "./posts/update/AnnouncementPostEditPage";
import {
  AnnouncementRuleCreateModal,
  AnnouncementRuleEditModal,
} from "./rules/AnnouncementRuleModals";

export const SettingsAnnouncementsRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route path="post" element={<AnnouncementPostEditPage />} />
        <Route path="*" element={<SettingsMainAnnouncementRoute />} />
      </Routes>
    </>
  );
};

const SettingsMainAnnouncementRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <Routes>
        <Route
          path=":id/edit"
          element={<AnnouncementRuleEditModal onClose={() => navigate("")} />}
        />
        <Route
          path="create"
          element={<AnnouncementRuleCreateModal onClose={() => navigate("")} />}
        />
      </Routes>

      <AnnouncementSettingsPage />
    </>
  );
};
