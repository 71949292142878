import type { SVGProps } from "react";
import * as React from "react";
const SvgBacklinkLarge = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.172 17.968a4.261 4.261 0 0 1-3.026-1.252l-2.175-2.175a4.29 4.29 0 0 1 0-6.06 4.293 4.293 0 0 1 1.19-.847.75.75 0 1 1 .646 1.354 2.767 2.767 0 0 0-.766.543 2.793 2.793 0 0 0-.01 3.95l2.175 2.175a2.79 2.79 0 0 0 3.94 0 2.791 2.791 0 0 0 .01-3.949l-.932-.93a.75.75 0 1 1 1.061-1.062l.931.931a4.292 4.292 0 0 1-3.044 7.322Z"
    />
    <path
      fill="currentColor"
      d="M10.485 13.407a.75.75 0 0 1-.323-1.427c.283-.135.541-.318.766-.543a2.793 2.793 0 0 0 .01-3.95L8.763 5.312a2.79 2.79 0 0 0-3.939 0 2.791 2.791 0 0 0-.01 3.95l.931.93a.75.75 0 1 1-1.06 1.061l-.932-.93a4.29 4.29 0 0 1 0-6.06c1.682-1.682 4.4-1.683 6.07-.01l2.175 2.174a4.29 4.29 0 0 1 0 6.061 4.293 4.293 0 0 1-1.19.846.742.742 0 0 1-.323.073Z"
    />
  </svg>
);
export default SvgBacklinkLarge;
