import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { Button, EmptyState, IconEnum } from "@incident-ui";

export const CatalogNotFoundPage = ({
  notFoundNoun,
  returnPath,
  returnPageName,
}: {
  notFoundNoun: string;
  returnPath: string;
  returnPageName: string;
}) => {
  return (
    <PageWrapper
      width={PageWidth.Narrow}
      title="Not Found"
      crumbs={[{ title: "Catalog", to: `/catalog` }]}
      backHref="/catalog"
      icon={IconEnum.Book}
    >
      <EmptyState
        content={`We weren't able to find that ${notFoundNoun}.`}
        icon={IconEnum.Search}
        cta={
          <Button
            href={returnPath}
            analyticsTrackingId="catalog-404-return"
            className="w-fit"
          >
            Take me back to the {returnPageName}
          </Button>
        }
      />
    </PageWrapper>
  );
};
