import {
  useIncidentCallsServiceIncidentCallsGetForLatestForIncident,
  useIncidentCallsServiceIncidentCallsListCallSessionsWithSummaries,
} from "@incident-io/query-api";
import { differenceInSeconds } from "date-fns";
import { useState } from "react";
import { DurationEnum, formatDurationInSeconds } from "src/utils/datetime";
import { useInterval } from "usehooks-ts";

export const useLatestIncidentCall = (incidentId: string) => {
  const {
    data: callData,
    isLoading: callIsLoading,
    error: callError,
  } = useIncidentCallsServiceIncidentCallsGetForLatestForIncident({
    incidentId,
  });

  return {
    callData,
    isLoading: callIsLoading,
    error: callError,
  };
};

export const useIncidentCallSessionsWithSummaries = (incidentId: string) => {
  const {
    data: callData,
    isLoading: callIsLoading,
    error: callError,
  } = useIncidentCallsServiceIncidentCallsGetForLatestForIncident({
    incidentId,
  });

  const {
    data: callSessionsWithSummariesData,
    isLoading: callSessionsWithSummariesIsLoading,
    error: callSessionsWithSummariesError,
    isFetching: callSessionsWithSummariesIsFetching,
  } = useIncidentCallsServiceIncidentCallsListCallSessionsWithSummaries(
    {
      incidentCallId: callData?.incident_call?.id || "",
    },
    undefined,
    {
      refetchInterval: 15000, // 15 seconds
      refetchOnWindowFocus: true,
    },
  );

  return {
    callSessionsWithSummaries:
      callSessionsWithSummariesData?.call_sessions_with_transcripts,
    isLoading: callSessionsWithSummariesIsLoading || callIsLoading,
    error: callSessionsWithSummariesError || callError,
    callSessionsWithSummariesIsFetching,
  };
};

export const useHowLongAgoFromDate = (inputDate: Date) => {
  const [now, setNow] = useState(new Date());
  useInterval(() => setNow(new Date()), 15000);

  const diff = differenceInSeconds(now, inputDate);
  const durationString = formatDurationInSeconds(diff, 1, DurationEnum.minutes);
  return diff === 0
    ? "now"
    : durationString
    ? `${durationString} ago`
    : "less than a minute ago";
};
