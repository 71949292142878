import type { SVGProps } from "react";
import * as React from "react";
const SvgTable = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.3 7.4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h.9c1.214 0 2.2.986 2.2 2.2v2.3a.5.5 0 0 1-.5.5h-2.6ZM6.9 8.6h2.2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H6.9a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5Zm2.2-6.2H6.9a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h2.2a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5Zm1.7 6.7a.5.5 0 0 1 .5-.5h2.6a.5.5 0 0 1 .5.5v2.3c0 1.214-.986 2.2-2.2 2.2h-.9a.5.5 0 0 1-.5-.5v-4Zm-5.6 0a.5.5 0 0 0-.5-.5H2.1a.5.5 0 0 0-.5.5v2.3c0 1.214.986 2.2 2.2 2.2h.9a.5.5 0 0 0 .5-.5v-4Zm0-2.2a.5.5 0 0 1-.5.5H2.1a.5.5 0 0 1-.5-.5V4.6c0-1.214.986-2.2 2.2-2.2h.9a.5.5 0 0 1 .5.5v4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTable;
