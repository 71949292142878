/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertRoutesMarkAllUnrelatedResponseBody
 */
export interface AlertRoutesMarkAllUnrelatedResponseBody {
    /**
     * 
     * @type {string}
     * @memberof AlertRoutesMarkAllUnrelatedResponseBody
     */
    incident_id?: string;
}

export function AlertRoutesMarkAllUnrelatedResponseBodyFromJSON(json: any): AlertRoutesMarkAllUnrelatedResponseBody {
    return AlertRoutesMarkAllUnrelatedResponseBodyFromJSONTyped(json, false);
}

export function AlertRoutesMarkAllUnrelatedResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesMarkAllUnrelatedResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
    };
}

export function AlertRoutesMarkAllUnrelatedResponseBodyToJSON(value?: AlertRoutesMarkAllUnrelatedResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
    };
}

