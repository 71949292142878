/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    CustomFieldTypeInfo,
    CustomFieldTypeInfoFromJSON,
    CustomFieldTypeInfoFromJSONTyped,
    CustomFieldTypeInfoToJSON,
} from './CustomFieldTypeInfo';
import {
    CustomFieldValue,
    CustomFieldValueFromJSON,
    CustomFieldValueFromJSONTyped,
    CustomFieldValueToJSON,
} from './CustomFieldValue';

/**
 * 
 * @export
 * @interface CustomFieldValueUpdate
 */
export interface CustomFieldValueUpdate {
    /**
     * 
     * @type {Actor}
     * @memberof CustomFieldValueUpdate
     */
    actor: Actor;
    /**
     * 
     * @type {CustomFieldTypeInfo}
     * @memberof CustomFieldValueUpdate
     */
    custom_field: CustomFieldTypeInfo;
    /**
     * Updated custom field values
     * @type {Array<CustomFieldValue>}
     * @memberof CustomFieldValueUpdate
     */
    custom_field_values?: Array<CustomFieldValue>;
    /**
     * Previous custom field values
     * @type {Array<CustomFieldValue>}
     * @memberof CustomFieldValueUpdate
     */
    previous_custom_field_values?: Array<CustomFieldValue>;
}

export function CustomFieldValueUpdateFromJSON(json: any): CustomFieldValueUpdate {
    return CustomFieldValueUpdateFromJSONTyped(json, false);
}

export function CustomFieldValueUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldValueUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'custom_field': CustomFieldTypeInfoFromJSON(json['custom_field']),
        'custom_field_values': !exists(json, 'custom_field_values') ? undefined : ((json['custom_field_values'] as Array<any>).map(CustomFieldValueFromJSON)),
        'previous_custom_field_values': !exists(json, 'previous_custom_field_values') ? undefined : ((json['previous_custom_field_values'] as Array<any>).map(CustomFieldValueFromJSON)),
    };
}

export function CustomFieldValueUpdateToJSON(value?: CustomFieldValueUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'custom_field': CustomFieldTypeInfoToJSON(value.custom_field),
        'custom_field_values': value.custom_field_values === undefined ? undefined : ((value.custom_field_values as Array<any>).map(CustomFieldValueToJSON)),
        'previous_custom_field_values': value.previous_custom_field_values === undefined ? undefined : ((value.previous_custom_field_values as Array<any>).map(CustomFieldValueToJSON)),
    };
}

