import { PolicyPolicyTypeEnum } from "@incident-io/api";
import { IconEnum } from "@incident-ui";
import pluralize from "pluralize";
import { useDebriefName, usePostmortemName } from "src/utils/postmortem-name";

export interface PolicyTypeConfig {
  value: PolicyPolicyTypeEnum;
  label: string;
  label_plural: string;
  description: string;
  subject: string;
  subject_plural: string;
  icon: IconEnum;
}

export const useGetPolicyTypeConfig = (): ((
  policyType: PolicyPolicyTypeEnum,
) => PolicyTypeConfig) => {
  const config = usePolicyTypeConfig();

  return (policyType: PolicyPolicyTypeEnum): PolicyTypeConfig => {
    return config[policyType];
  };
};

export const usePolicyTypeConfig = (): {
  [key in PolicyPolicyTypeEnum]: PolicyTypeConfig;
} => {
  const { postmortemName, postmortemNameFormatted } = usePostmortemName(null);
  const { debriefName, debriefNameLower } = useDebriefName();

  return {
    [PolicyPolicyTypeEnum.Debrief]: {
      value: PolicyPolicyTypeEnum.Debrief,
      label: debriefName,
      label_plural: pluralize(debriefName),
      subject: debriefNameLower,
      subject_plural: pluralize(debriefNameLower),
      icon: IconEnum.Calendar,
      description: `Ensure that ${pluralize(
        debriefNameLower,
      )} are scheduled and completed while the information is fresh.`,
    },
    [PolicyPolicyTypeEnum.FollowUp]: {
      value: PolicyPolicyTypeEnum.FollowUp,
      label: "Follow-up",
      label_plural: "Follow-ups",
      subject: "follow-up",
      subject_plural: "follow-ups",
      icon: IconEnum.FollowUps,
      description: `Ensure that high priority follow-ups are exported and completed.`,
    },
    [PolicyPolicyTypeEnum.PostMortem]: {
      value: PolicyPolicyTypeEnum.PostMortem,
      label: postmortemName,
      label_plural: pluralize(postmortemName),
      subject: postmortemNameFormatted,
      subject_plural: pluralize(postmortemNameFormatted),
      icon: IconEnum.Doc,
      description: `Ensure that ${pluralize(
        postmortemNameFormatted,
      )} are written and shared in good time.`,
    },
  };
};
