/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemShareTemplate,
    PostmortemShareTemplateFromJSON,
    PostmortemShareTemplateFromJSONTyped,
    PostmortemShareTemplateToJSON,
} from './PostmortemShareTemplate';

/**
 * 
 * @export
 * @interface PostmortemSharesShowTemplateResponseBody
 */
export interface PostmortemSharesShowTemplateResponseBody {
    /**
     * 
     * @type {PostmortemShareTemplate}
     * @memberof PostmortemSharesShowTemplateResponseBody
     */
    postmortem_share_template: PostmortemShareTemplate;
}

export function PostmortemSharesShowTemplateResponseBodyFromJSON(json: any): PostmortemSharesShowTemplateResponseBody {
    return PostmortemSharesShowTemplateResponseBodyFromJSONTyped(json, false);
}

export function PostmortemSharesShowTemplateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSharesShowTemplateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postmortem_share_template': PostmortemShareTemplateFromJSON(json['postmortem_share_template']),
    };
}

export function PostmortemSharesShowTemplateResponseBodyToJSON(value?: PostmortemSharesShowTemplateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postmortem_share_template': PostmortemShareTemplateToJSON(value.postmortem_share_template),
    };
}

