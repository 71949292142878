import {
  EngineScope,
  ErrorResponse,
  IncidentsBuildScopeContextEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

export const useIncidentScope = (
  context: IncidentsBuildScopeContextEnum,
  { skip }: { skip?: boolean } = { skip: false },
): {
  scope: EngineScope;
  scopeLoading: boolean;
  refetchScope: () => Promise<void>;
  scopeError: ErrorResponse | undefined;
} => {
  const { data, isLoading, mutate, error } = useAPI(
    skip ? null : "incidentsBuildScope",
    { context },
    {
      fallbackData: { scope: { references: [], aliases: {} } },
    },
  );

  return {
    scope: data.scope,
    scopeLoading: isLoading,
    refetchScope: async () => {
      await mutate();
    },
    scopeError: error,
  };
};
