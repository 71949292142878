import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { DynamicMultiSelectWithObjV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectWithObjV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { SelectOption, SelectOptionOrGroup } from "@incident-ui/Select/types";
import { useFormContext } from "react-hook-form";

import {
  EscalationOpsgenieOptionsPriorityEnum,
  EscalationProviderEnum,
  ExternalEscalationTarget,
  useClient,
} from "../../../contexts/ClientContext";
import { useAPI } from "../../../utils/swr";

export type OpsgenieFormData = {
  title: string;
  provider: EscalationProviderEnum.Opsgenie;
  opsgenie_service_id: string;
  priority: EscalationOpsgenieOptionsPriorityEnum;
  targets: ExternalEscalationTarget[];
};

export const OpsgenieEscalateForm = () => {
  const formMethods = useFormContext<OpsgenieFormData>();

  // Find the catalog type ID for Opsgenie services
  const { data: catalogData, isLoading: loadingCatalogTypes } = useAPI(
    "catalogListTypes",
    {},
  );

  const ogServiceCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "OpsgenieService",
  );
  const ogUserCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "OpsgenieUser",
  );
  const ogTeamCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "OpsgenieTeam",
  );

  const apiClient = useClient();

  const loadDefaultValueOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: ogServiceCatalogType?.id ?? "",
  });

  const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: ogServiceCatalogType?.id ?? "",
  });

  const opsgeniePriorities = [
    EscalationOpsgenieOptionsPriorityEnum.P1,
    EscalationOpsgenieOptionsPriorityEnum.P2,
    EscalationOpsgenieOptionsPriorityEnum.P3,
    EscalationOpsgenieOptionsPriorityEnum.P4,
    EscalationOpsgenieOptionsPriorityEnum.P5,
  ];
  const opsgeniePriorityOptions = opsgeniePriorities.map((priority) => ({
    label: priority,
    value: priority,
  }));

  // create a loader and hydrator that combines the OG users and teams
  // into a single list of options. This will be used for the multi-select input
  // for "who should be notified?"
  const loadUserOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: ogUserCatalogType?.id ?? "",
  });

  const loadTeamOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: ogTeamCatalogType?.id ?? "",
  });

  const loadEscalationTargetOptions = async (
    inputValue: string,
  ): Promise<SelectOptionOrGroup[]> => {
    const userOptions = await loadUserOptions(inputValue);
    const teamOptions = await loadTeamOptions(inputValue);

    return [
      {
        label: "Users",
        options: (userOptions as SelectOption[]).map((option) => ({
          ...option,
          id: option.value,
          type: "User",
        })),
      },
      {
        label: "Teams",
        options: (teamOptions as SelectOption[]).map((option) => ({
          ...option,
          id: option.value,
          type: "Team",
        })),
      },
    ];
  };

  // When the affected service is set, automatically set the team to be the
  // one referenced by the service. To do that, we'll need to fetch the
  // catalog entry for the service, and find the team.
  const onServiceChange = async (serviceID: string | null) => {
    if (!serviceID || !ogServiceCatalogType || !ogServiceCatalogType?.id) {
      return;
    }
    const data = await apiClient.catalogFindEntries({
      findEntriesRequestBody: {
        lookups: [
          {
            catalog_type_id: ogServiceCatalogType.id,
            lookup_terms: [serviceID],
          },
        ],
      },
    });

    const team =
      data?.results?.[ogServiceCatalogType.id]?.[0]?.attribute_values["team"]
        ?.value ?? null;

    if (team && !team.unavailable) {
      // If we find the team, convert it into a valid select option that can be rendered
      // by the multi-select component.
      const policy: SelectOption & ExternalEscalationTarget = {
        label: team.label,
        value: team.value ?? "",
        id: team.value ?? "",
        sort_key: team.sort_key,
        type: "Team",
      };

      formMethods.setValue("targets", [policy]);
    }
  };

  if (loadingCatalogTypes) {
    return <Loader />;
  }

  if (!ogServiceCatalogType) {
    return <GenericErrorMessage />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <div className="text-base-bold">Who do you need?</div>
        <div className="text-xs-med text-content-primary pt-[2px] pb-1">
          Pick the service and the people who should be notified.
        </div>
      </div>
      <div className="bg-surface-secondary p-4 rounded-3 flex flex-col gap-6 mt-3">
        <DynamicSingleSelectV2
          label="Which service is affected?"
          loadOptions={loadDefaultValueOptions}
          hydrateOptions={hydrateDefaultValueOptions}
          name="opsgenie_service_id"
          formMethods={formMethods}
          onValueChange={onServiceChange}
        />
        <DynamicMultiSelectWithObjV2
          label="Who should be notified?"
          loadOptions={loadEscalationTargetOptions}
          name="targets"
          formMethods={formMethods}
          required
        />
        <StaticSingleSelectV2
          options={opsgeniePriorityOptions}
          label="Priority?"
          name="priority"
          required={true}
          isClearable={false}
          formMethods={formMethods}
          helptext="Choose the priority level for this notification"
        />
      </div>
      <div className="text-base-bold pt-5 pb-1">Why do you need them?</div>
      <div className="text-xs-med text-content-primary pt-[2px] pb-1">
        This will be what they see or hear when we contact them.
      </div>
      <InputV2
        name="title"
        className="pb-3"
        formMethods={formMethods}
        placeholder="Something's wrong with the database"
      />
    </div>
  );
};
