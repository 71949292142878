import {
  Actor as ActorResponse,
  AlertTimelineItem,
  AlertTimelineItemTypeEnum,
  IntegrationActorNameEnum,
} from "@incident-io/api";
import { Timeline } from "@incident-shared/timeline/Timeline";
import { TimelineItem } from "@incident-shared/timeline/TimelineItem";
import { GenericErrorMessage, Spinner } from "@incident-ui";
import { Actor } from "@incident-ui/Actor/Actor";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useState } from "react";

import { useAPI } from "../../../utils/swr";
import { AlertTimelineItemDisplayInfo } from "./AlertTimelineItemDisplayInfo";

export const isActorRenderedSpecially = (actor: ActorResponse) => {
  if (actor.integration) {
    return [
      IntegrationActorNameEnum.AlertRoute,
      IntegrationActorNameEnum.AlertSource,
    ].includes(actor.integration.name);
  }
  if (actor.alert) {
    return true;
  }
  return false;
};

export const AlertTimeline = ({ alertId }: { alertId: string }) => {
  const { alertTimeline } = useFlags();
  const [minimizedItems, setMinimizedItems] = useState<string[]>([]);

  const {
    data: timelineResp,
    isLoading: isLoadingAlertTimeline,
    error: alertTimelineError,
  } = useAPI("alertsListTimelineItems", {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    alertId,
  });

  const renderItem = useCallback(
    ({
      item,
      hideSpacer,
    }: {
      item: AlertTimelineItem;
      hideSpacer: boolean;
    }): React.ReactNode => {
      const DisplayInfo = AlertTimelineItemDisplayInfo[item.type];

      let title: React.ReactNode = item.title;
      if (item.actor && !isActorRenderedSpecially(item.actor)) {
        title = (
          <div className="flex items-center gap-2">
            {item.title} by <Actor actor={item.actor} />
          </div>
        );
      }

      const ContentComponent: React.ComponentType<{
        item: AlertTimelineItem;
      }> = DisplayInfo.Component ?? (() => null);

      return (
        <TimelineItem
          key={item.id}
          id={item.id}
          description={item.description}
          title={title}
          timestamp={item.timestamp}
          hasChildren={!!DisplayInfo.Component}
          hideSpacer={hideSpacer}
          // Never show items as expanded if they have no content to render
          minimized={
            DisplayInfo.Component ? minimizedItems.includes(item.id) : true
          }
          setMinimized={(minimized) =>
            minimized
              ? setMinimizedItems([...minimizedItems, item.id])
              : setMinimizedItems(minimizedItems.filter((id) => id !== item.id))
          }
          allowCommenting={false}
          color={DisplayInfo.color}
          icon={DisplayInfo.icon}
        >
          <ContentComponent item={item} />
        </TimelineItem>
      );
    },
    [minimizedItems],
  );

  if (!alertTimeline) {
    return null;
  }

  if (isLoadingAlertTimeline) {
    return <Spinner />;
  }

  if (alertTimelineError) {
    return <GenericErrorMessage error={alertTimelineError} />;
  }

  // We only built our timeline for alerts created after the launch of the timeline
  // feature, to avoid complex backfilling.
  // If the first timeline item is older than the launch date, we don't show the timeline.
  if (
    timelineResp?.timeline_items.length &&
    timelineResp.timeline_items[0].date < new Date("2024-10-17")
  ) {
    return null;
  }

  // If any of the items have 'children' content, then we support minimizing
  const supportsMinimizing =
    timelineResp?.timeline_items
      ?.flatMap((group) => group.items)
      .filter((item) => item.timeline_item)
      .some(
        (item) =>
          AlertTimelineItemDisplayInfo[
            item.timeline_item?.type ?? AlertTimelineItemTypeEnum.AlertCreated
          ]?.Component,
      ) ?? false;

  return (
    <div className={"space-y-2"}>
      <div className={"text-base-bold"}>Timeline</div>
      <Timeline<AlertTimelineItem>
        accessorKey={"timeline_item"}
        items={timelineResp?.timeline_items ?? []}
        renderItem={renderItem}
        minimizedItems={minimizedItems ?? []}
        setMinimizedItems={setMinimizedItems}
        supportsMinimizing={supportsMinimizing}
      />
    </div>
  );
};
