import { IntegrationConfigFor } from "@incident-shared/integrations";
import { Button, ButtonTheme } from "@incident-ui";
import React from "react";
import { ExternalIssueReference } from "src/contexts/ClientContext";

export const ExternalIssueLink = ({
  reference,
}: {
  reference: ExternalIssueReference;
}): React.ReactElement => {
  return (
    <Button
      analyticsTrackingId="external-issue-link"
      href={reference.issue_permalink}
      openInNewTab
      theme={ButtonTheme.Unstyled}
      className="truncate hover:underline"
      icon={IntegrationConfigFor(reference.provider).icon}
    >
      {reference.issue_name}
    </Button>
  );
};
