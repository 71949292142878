/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageSubPageSlim
 */
export interface StatusPageSubPageSlim {
    /**
     * The catalog entry of the parent page's split-by type, which defines which incidents are relevant to this subpage.
     * @type {string}
     * @memberof StatusPageSubPageSlim
     */
    defined_by_catalog_entry_id: string;
    /**
     * A short description of this subpage
     * @type {string}
     * @memberof StatusPageSubPageSlim
     */
    description?: string;
    /**
     * The ID of the status page
     * @type {string}
     * @memberof StatusPageSubPageSlim
     */
    id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageSubPageSlim
     */
    intro_text?: TextNode;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPageSubPageSlim
     */
    name: string;
    /**
     * The primary URL where this status page can be accessed.
     * @type {string}
     * @memberof StatusPageSubPageSlim
     */
    public_url: string;
    /**
     * The subpath of within the parent status page
     * @type {string}
     * @memberof StatusPageSubPageSlim
     */
    subpath: string;
    /**
     * The worst component status during this time period
     * @type {string}
     * @memberof StatusPageSubPageSlim
     */
    worst_component_status: StatusPageSubPageSlimWorstComponentStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageSubPageSlimWorstComponentStatusEnum {
    Operational = 'operational',
    UnderMaintenance = 'under_maintenance',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    FullOutage = 'full_outage'
}

export function StatusPageSubPageSlimFromJSON(json: any): StatusPageSubPageSlim {
    return StatusPageSubPageSlimFromJSONTyped(json, false);
}

export function StatusPageSubPageSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageSubPageSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defined_by_catalog_entry_id': json['defined_by_catalog_entry_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'intro_text': !exists(json, 'intro_text') ? undefined : TextNodeFromJSON(json['intro_text']),
        'name': json['name'],
        'public_url': json['public_url'],
        'subpath': json['subpath'],
        'worst_component_status': json['worst_component_status'],
    };
}

export function StatusPageSubPageSlimToJSON(value?: StatusPageSubPageSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defined_by_catalog_entry_id': value.defined_by_catalog_entry_id,
        'description': value.description,
        'id': value.id,
        'intro_text': TextNodeToJSON(value.intro_text),
        'name': value.name,
        'public_url': value.public_url,
        'subpath': value.subpath,
        'worst_component_status': value.worst_component_status,
    };
}

