import {
  IncidentStatusCategoryEnum,
  InternalStatusPageIncident,
  StatusPageIncident,
  TextDocument,
} from "@incident-io/api";
import {
  Button,
  ButtonProps,
  ButtonTheme,
  ChevronIcon,
  ContentBox,
  ContentBoxTheme,
  TemplatedText,
} from "@incident-io/status-page-ui";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Icon,
  IconEnum,
  IncidentStatusBadge,
  OrgAwareLink,
} from "@incident-ui";
import { SeverityBadge } from "@incident-ui/Badge/SeverityBadge";
import _ from "lodash";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { formatDurationShort } from "../../../../utils/datetime";
import { useStatusPageIncidents } from "../../incident/hooks";
import { Duration } from "../HeadsUp/HeadsUp";

export const IncidentHeader = ({
  incident,
  subpath,
  showIncidentSummary,
}: {
  incident: InternalStatusPageIncident;
  subpath: string;
  showIncidentSummary: boolean;
}): React.ReactElement => {
  const { statusPageIncidents: publicStatusPageIncidents } =
    useStatusPageIncidents(incident.response_incident_id);

  const latestUpdate = _.last(_.orderBy(incident.updates, "created_at"));

  return (
    <ContentBox
      title={
        <div className="flex items-center text-content-primary dark:text-slate-100 text-lg py-0.5">
          <OrgAwareLink
            to={subpath === "default" ? "/status" : `/status/${subpath}`}
          >
            <ChevronIcon
              flavour="left"
              className={tcx(
                "flex-none w-4 h-4 opacity-50 hover:opacity-100 font-semibold transition mr-2 -ml-1 mt-0.5",
                "text-slate-800",
                "dark:text-slate-400",
              )}
            />
          </OrgAwareLink>
          <span className="leading-6">{incident.name}</span>
        </div>
      }
      padded={false}
      theme={
        isOngoing(incident) || isPaused(incident)
          ? ContentBoxTheme.FullOutage
          : ContentBoxTheme.Operational
      }
    >
      <div className="flex flex-col divide-y divide-slate-300 dark:divide-slate-700 p-4">
        <div className="flex items-center gap-3 text-sm flex-wrap text-content-primary pb-4">
          <IncidentStatusBadge
            status={incident.incident_status}
            size={BadgeSize.Medium}
            naked
            pausedUntil={latestUpdate?.paused_until}
            truncate={false}
            className="text-content-primary text-sm-med"
          />
          {incident.severity ? (
            <SeverityBadge
              severity={incident.severity}
              size={BadgeSize.Medium}
              naked
              className="text-content-primary text-sm-med"
            />
          ) : undefined}
          {
            <div className="text-content-tertiary">
              {isOngoing(incident) && <Duration since={incident.reported_at} />}
            </div>
          }
        </div>
        {showIncidentSummary && incident.summary && (
          <SummarySection summary={incident.summary} />
        )}
        <div className="pt-4 space-y-4">
          {latestUpdate !== undefined && latestUpdate.message !== undefined && (
            <TemplatedText value={latestUpdate.message} className="text-sm" />
          )}
          <div className="flex flex-col gap-2 md:flex-row justify-between">
            <div className="flex space-x-2 text-sm items-center text-content-tertiary">
              {latestUpdate !== undefined && (
                <div>
                  Updated{" "}
                  {formatDurationShort(latestUpdate.created_at, new Date(), {
                    significantFigures: 1,
                  })}{" "}
                  ago
                </div>
              )}
              <div className="text-slate-400 dark:text-slate-600">&bull;</div>
              <IconButton
                title="Jump to updates"
                theme={ButtonTheme.Invisible}
                padded={false}
                href="#updates"
                openInNewTab={false}
                className="!text-content-tertiary dark:!text-slate-600 hover:!text-slate-700 dark:hover:!text-content-tertiary flex"
                onClick={(e) => {
                  // Rather than adding a new URL to the back-stack, we instead just scroll
                  // to that part of the page.
                  e?.preventDefault();
                  document
                    .getElementById("updates")
                    ?.scrollIntoView({ block: "center", behavior: "auto" });
                }}
                analyticsTrackingId={"internal-sp-view-all-incident-updates"}
                icon={IconEnum.ChevronDoubleDown}
              />
            </div>
            <div className="flex gap-2 md:gap-4 text-sm items-center flex-wrap">
              {incident.slack_channel_url && (
                <IconButton
                  icon={IconEnum.SlackGreyscale}
                  title="Join incident channel"
                  href={incident.slack_channel_url}
                  openInNewTab={false}
                  analyticsTrackingId={"internal-sp-join-incident-slack"}
                />
              )}
              {publicStatusPageIncidents.length ? (
                <PublicStatusPageLink
                  publicInc={publicStatusPageIncidents[0]}
                />
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
    </ContentBox>
  );
};

const SummarySection = ({
  summary,
}: {
  summary: TextDocument;
}): React.ReactElement => {
  return (
    <div className="flex items-center gap-3 text-sm-med flex-wrap py-4">
      <Badge
        size={BadgeSize.Large}
        theme={BadgeTheme.Naked}
        icon={IconEnum.Quote}
        className="text-content-primary text-sm-med dark:text-slate-100"
      >
        Summary
      </Badge>
      <TemplatedText
        value={summary.text_node}
        className="text-sm-normal text-content-primary dark:text-slate-100"
      />
    </div>
  );
};

const PublicStatusPageLink = ({
  publicInc,
}: {
  publicInc: StatusPageIncident;
}): React.ReactElement => {
  let linkProps:
    | { href: string; to?: never; openInNewTab?: boolean }
    | { href?: never; to: string; openInNewTab?: never };

  if (publicInc.public_url) {
    linkProps = { href: publicInc.public_url };
  } else {
    linkProps = {
      to: `/status-pages/${publicInc.status_page_id}/incident/${publicInc.id}`,
    };
  }

  return (
    <IconButton
      icon={IconEnum.ExternalLink}
      title="View public incident"
      {...linkProps}
      analyticsTrackingId={"internal-sp-view-public-sp"}
    />
  );
};

const isOngoing = (incident: InternalStatusPageIncident): boolean =>
  incident.incident_status.category === IncidentStatusCategoryEnum.Active;

const isPaused = (incident: InternalStatusPageIncident): boolean =>
  incident.incident_status.category === IncidentStatusCategoryEnum.Paused;

export const IconButton = ({
  icon,
  iconClassName,
  iconOnly,
  ...props
}: {
  icon: IconEnum;
  iconClassName?: string;
  iconOnly?: boolean;
} & ButtonProps): React.ReactElement => {
  return (
    <Button
      theme={ButtonTheme.Invisible}
      className={tcx(
        "!flex items-center",
        "!text-content-tertiary dark:!text-slate-600 hover:!text-slate-700 dark:hover:!text-content-tertiary",
        props.className,
      )}
      padded={false}
      {...props}
    >
      <Icon id={icon} className={tcx("mr-0.5", iconClassName)} />
      {iconOnly ? null : props.title}
    </Button>
  );
};
