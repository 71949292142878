import type { SVGProps } from "react";
import * as React from "react";
const SvgCronitor = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#50CC84"
      d="M10.878 8.072a1.774 1.774 0 0 0-1.203.482 1.957 1.957 0 0 0-.593 1.972c.213.79.955 1.405 1.775 1.45.263.009.529-.033.78-.11.348-.128.66-.358.898-.643.152-.22.281-.465.351-.725a4.84 4.84 0 0 0 .011-.894c-.05-.333-.232-.636-.45-.888-.332-.366-.801-.64-1.306-.64a3.683 3.683 0 0 0-.263-.004Z"
    />
    <path
      fill="#000"
      d="M10.686 3.298c-.507.007-1.018.042-1.505.194-1.423.39-2.728 1.233-3.616 2.418a6.809 6.809 0 0 0-1.347 3.259 8.779 8.779 0 0 0 .055 1.98 6.769 6.769 0 0 0 2.255 3.937 6.902 6.902 0 0 0 3.537 1.58c.658.045 1.322.014 1.977-.06a6.89 6.89 0 0 0 3.14-1.455c.237-.191.446-.412.656-.631-.745-.652-1.434-1.364-2.176-2.019-.057-.06-.144-.069-.2-.001-.322.286-.657.567-1.057.74-.713.328-1.532.396-2.291.243a4.172 4.172 0 0 1-1.361-.525 3.594 3.594 0 0 1-.773-.753 4.572 4.572 0 0 1-.573-1.32c-.107-.49-.102-.99-.054-1.49.05-.416.217-.835.425-1.197A3.629 3.629 0 0 1 9.524 6.67a2.59 2.59 0 0 1 1.01-.231c.43-.014.87-.026 1.29.08.462.14.909.382 1.311.663.09.096.216.028.284-.057.535-.573 1.027-1.185 1.548-1.772l.347-.397a5.73 5.73 0 0 0-1.533-1.022c-.6-.274-1.232-.494-1.887-.589a10.111 10.111 0 0 0-1.208-.047Z"
    />
  </svg>
);
export default SvgCronitor;
