import { ButtonTheme, IconEnum, LocalDateTime, Tooltip } from "@incident-ui";
import { captureMessage } from "@sentry/react";
import React from "react";
import {
  Incident,
  IncidentDurationMetricWithValue,
  IncidentDurationMetricWithValueStatusEnum,
  IncidentTimestamp,
  IncidentTimestampWithValue,
} from "src/contexts/ClientContext";
import { formatDurationInSeconds } from "src/utils/datetime";
import { useNavigateToModal } from "src/utils/query-params";

import { incidentInEditableStatus } from "../helpers";
import { Sidebar } from "./Components";

type Props = {
  timestampValues: IncidentTimestampWithValue[];
  metrics: IncidentDurationMetricWithValue[];
  incident: Incident;
  onEdit: () => void;
};

export const TimestampsAndMetrics = ({
  timestampValues: rawValues,
  incident,
  metrics,
  onEdit,
}: Props): React.ReactElement | null => {
  const navigateToModal = useNavigateToModal();

  // Filter for timestamps that have a value, then sort them
  const timestampValues = rawValues
    .filter(
      (timestamp) =>
        timestamp.value !== undefined && timestamp.value?.value !== undefined,
    )
    .sort((a, b): number => {
      // @ts-expect-error they are always defined because we've filtered first
      return a.value.value - b.value.value;
    });

  const timestamps =
    incident.incident_timestamps?.map((x) => x.timestamp) || [];

  return (
    <Sidebar.Section
      collapsedByDefault
      title="Timestamps and duration metrics"
      accessory={
        incidentInEditableStatus(incident) ? (
          <Sidebar.TitleAccessoryButton
            onClick={onEdit}
            icon={IconEnum.Edit}
            analyticsTrackingId="edit-timestamp"
            title=""
          />
        ) : undefined
      }
    >
      {timestampValues.length === 0 ? (
        // As we force you to have a reported timestamp, this should basically never happen
        <div className="text-content-tertiary mt-2">
          There are no timestamps set for this incident.
        </div>
      ) : (
        <>
          {timestampValues.map((timestamp) => {
            return (
              <Sidebar.Entry
                key={timestamp.timestamp.id}
                label={timestamp.timestamp.name}
                value={
                  (timestamp.value?.value && (
                    <LocalDateTime
                      className="tabular-nums text-sm-med"
                      timestamp={timestamp.value.value}
                    />
                  )) ||
                  "No value"
                }
              />
            );
          })}
          {metrics.length === 0 ? (
            // As there's an in-built duration metric, this should basically never happen
            <div className="text-content-tertiary mt-2">
              There are no metrics available for this incident.
            </div>
          ) : (
            <div className="space-y-2">
              {metrics.map((metric) => {
                const fromTimestamp = timestamps.find(
                  (t) => t.id === metric.duration_metric.from_timestamp_id,
                );
                const toTimestamp = timestamps.find(
                  (t) => t.id === metric.duration_metric.to_timestamp_id,
                );
                if (!fromTimestamp || !toTimestamp) {
                  captureMessage(
                    "metric pointing to timestamps that we don't have in our list",
                    { extra: { metric, timestamps } },
                  );
                  return null;
                }

                const formattedValue =
                  typeof metric.value_seconds === "number"
                    ? formatDurationInSeconds(
                        metric.value_seconds,
                        undefined,
                        undefined,
                        true,
                      )
                    : undefined;
                const invalid =
                  typeof metric.value_seconds === "number" &&
                  metric.value_seconds < 0;

                return (
                  <Sidebar.Entry
                    key={metric.duration_metric.id}
                    label={metric.duration_metric.name}
                    value={
                      <Sidebar.ValueButton
                        onClick={() =>
                          navigateToModal(
                            `duration/${metric.duration_metric.id}`,
                          )
                        }
                        theme={ButtonTheme.Link}
                        className={
                          formattedValue
                            ? "text-content-primary"
                            : "text-content-tertiary"
                        }
                        icon={invalid ? IconEnum.Warning : undefined}
                        analyticsTrackingId={null}
                      >
                        {formattedValue ?? (
                          <MissingDurationExplanation
                            metric={metric}
                            timestamps={timestamps}
                          />
                        )}
                      </Sidebar.ValueButton>
                    }
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </Sidebar.Section>
  );
};

const MissingDurationExplanation = ({
  metric,
  timestamps,
}: {
  metric: IncidentDurationMetricWithValue;
  timestamps: IncidentTimestamp[];
}) => {
  const fromTimestamp = timestamps.find(
    (t) => t.id === metric.duration_metric.from_timestamp_id,
  );
  const toTimestamp = timestamps.find(
    (t) => t.id === metric.duration_metric.to_timestamp_id,
  );
  if (!fromTimestamp || !toTimestamp) {
    captureMessage(
      "metric pointing to timestamps that we don't have in our list",
      { extra: { metric, timestamps } },
    );
    return (
      <span className="text-right text-content-tertiary">Error occurred</span>
    );
  }

  return (
    <>
      {metric.status ===
      IncidentDurationMetricWithValueStatusEnum.Calculating ? (
        <Tooltip
          content={
            <div className="text-left">
              This metric is currently being calculated, please check back later
            </div>
          }
        >
          <span className="text-right text-content-tertiary">
            Calculating...
          </span>
        </Tooltip>
      ) : metric.status ===
        IncidentDurationMetricWithValueStatusEnum.InvalidTimestamps ? (
        <Tooltip
          content={
            <div className="text-left">
              <span className="font-semibold">{fromTimestamp.name}</span> must
              be before{" "}
              <span className="font-semibold">{toTimestamp.name}</span>
            </div>
          }
        >
          <span className="text-right">Invalid timestamps</span>
        </Tooltip>
      ) : (
        <span className="text-right">Missing timestamps</span>
      )}
    </>
  );
};
