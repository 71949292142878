import type { SVGProps } from "react";
import * as React from "react";
const SvgRobot = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 1.5h-4.5A2.252 2.252 0 0 0 9.5 3.75v2c0 1.156.876 2.111 2 2.236V9.25a.75.75 0 0 0 1.251.558L14.764 8h1.486a2.252 2.252 0 0 0 2.25-2.25v-2a2.252 2.252 0 0 0-2.25-2.25Z"
    />
    <path
      fill="currentColor"
      d="M15.75 9a.75.75 0 0 0-.75.75v6c0 .689-.561 1.25-1.25 1.25h-9.5C3.561 17 3 16.439 3 15.75v-5.5C3 9.561 3.561 9 4.25 9h4.38a.75.75 0 0 0 0-1.5H6.5V5.292A1.495 1.495 0 0 0 5.75 2.5 1.495 1.495 0 0 0 5 5.292V7.5h-.75a2.752 2.752 0 0 0-2.75 2.75v5.5a2.752 2.752 0 0 0 2.75 2.75h9.5a2.752 2.752 0 0 0 2.75-2.75v-6a.75.75 0 0 0-.75-.75Z"
    />
    <path
      fill="currentColor"
      d="M6 13.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 13.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM7.5 14a1.5 1.5 0 0 0 3 0 .5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5Z"
    />
  </svg>
);
export default SvgRobot;
