/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentLifecyclesCreateStatusRequestBody
 */
export interface IncidentLifecyclesCreateStatusRequestBody {
    /**
     * What category of status it is. All statuses apart from live (renamed in the app to Active) and learning (renamed in the app to Post-incident) are managed by incident.io and cannot be configured
     * @type {string}
     * @memberof IncidentLifecyclesCreateStatusRequestBody
     */
    category: IncidentLifecyclesCreateStatusRequestBodyCategoryEnum;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentLifecyclesCreateStatusRequestBody
     */
    description: TextDocumentPayload;
    /**
     * ID of the incident lifecycle this status belongs to (active statuses only)
     * @type {string}
     * @memberof IncidentLifecyclesCreateStatusRequestBody
     */
    incident_lifecycle_id?: string;
    /**
     * Unique name of this status
     * @type {string}
     * @memberof IncidentLifecyclesCreateStatusRequestBody
     */
    name: string;
    /**
     * The ID of the post-incident flow this status is part of. This is only set for post-incident statuses.
     * @type {string}
     * @memberof IncidentLifecyclesCreateStatusRequestBody
     */
    post_incident_flow_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentLifecyclesCreateStatusRequestBodyCategoryEnum {
    Active = 'active',
    PostIncident = 'post-incident',
    Closed = 'closed'
}

export function IncidentLifecyclesCreateStatusRequestBodyFromJSON(json: any): IncidentLifecyclesCreateStatusRequestBody {
    return IncidentLifecyclesCreateStatusRequestBodyFromJSONTyped(json, false);
}

export function IncidentLifecyclesCreateStatusRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentLifecyclesCreateStatusRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': json['category'],
        'description': TextDocumentPayloadFromJSON(json['description']),
        'incident_lifecycle_id': !exists(json, 'incident_lifecycle_id') ? undefined : json['incident_lifecycle_id'],
        'name': json['name'],
        'post_incident_flow_id': !exists(json, 'post_incident_flow_id') ? undefined : json['post_incident_flow_id'],
    };
}

export function IncidentLifecyclesCreateStatusRequestBodyToJSON(value?: IncidentLifecyclesCreateStatusRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'description': TextDocumentPayloadToJSON(value.description),
        'incident_lifecycle_id': value.incident_lifecycle_id,
        'name': value.name,
        'post_incident_flow_id': value.post_incident_flow_id,
    };
}

