/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerPageContentAuthEmailMagicLinkRequestBody,
    CustomerPageContentAuthEmailMagicLinkRequestBodyFromJSON,
    CustomerPageContentAuthEmailMagicLinkRequestBodyToJSON,
    CustomerPageContentAuthLoginWithPasswordlessSessionRequestBody,
    CustomerPageContentAuthLoginWithPasswordlessSessionRequestBodyFromJSON,
    CustomerPageContentAuthLoginWithPasswordlessSessionRequestBodyToJSON,
    CustomerPageContentAuthLoginWithPasswordlessSessionResponseBody,
    CustomerPageContentAuthLoginWithPasswordlessSessionResponseBodyFromJSON,
    CustomerPageContentAuthLoginWithPasswordlessSessionResponseBodyToJSON,
} from '../models';

export interface CustomerPageContentAuthEmailMagicLinkRequest {
    mainSlug: string;
    subpageSlug: string;
    emailMagicLinkRequestBody: CustomerPageContentAuthEmailMagicLinkRequestBody;
}

export interface CustomerPageContentAuthLoginWithPasswordlessSessionRequest {
    mainSlug: string;
    subpageSlug: string;
    loginWithPasswordlessSessionRequestBody: CustomerPageContentAuthLoginWithPasswordlessSessionRequestBody;
}

export interface CustomerPageContentAuthRedirectToCustomerPageRequest {
    code?: string;
    state?: string;
}

/**
 * 
 */
export class CustomerPageContentAuthApi extends runtime.BaseAPI {

    /**
     * Tells WorkOS to send a magic link to a user with an allowlisted domain.
     * EmailMagicLink Customer Page Content Auth
     */
    async customerPageContentAuthEmailMagicLinkRaw(requestParameters: CustomerPageContentAuthEmailMagicLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentAuthEmailMagicLink.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentAuthEmailMagicLink.');
        }

        if (requestParameters.emailMagicLinkRequestBody === null || requestParameters.emailMagicLinkRequestBody === undefined) {
            throw new runtime.RequiredError('emailMagicLinkRequestBody','Required parameter requestParameters.emailMagicLinkRequestBody was null or undefined when calling customerPageContentAuthEmailMagicLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer_status_page_content_auth/{main_slug}/{subpage_slug}/email_magic_link`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerPageContentAuthEmailMagicLinkRequestBodyToJSON(requestParameters.emailMagicLinkRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Tells WorkOS to send a magic link to a user with an allowlisted domain.
     * EmailMagicLink Customer Page Content Auth
     */
    async customerPageContentAuthEmailMagicLink(requestParameters: CustomerPageContentAuthEmailMagicLinkRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerPageContentAuthEmailMagicLinkRaw(requestParameters, initOverrides);
    }

    /**
     * Takes an authorization code and a page ID, and returns a JWT token for a long-term authenticated session.
     * LoginWithPasswordlessSession Customer Page Content Auth
     */
    async customerPageContentAuthLoginWithPasswordlessSessionRaw(requestParameters: CustomerPageContentAuthLoginWithPasswordlessSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomerPageContentAuthLoginWithPasswordlessSessionResponseBody>> {
        if (requestParameters.mainSlug === null || requestParameters.mainSlug === undefined) {
            throw new runtime.RequiredError('mainSlug','Required parameter requestParameters.mainSlug was null or undefined when calling customerPageContentAuthLoginWithPasswordlessSession.');
        }

        if (requestParameters.subpageSlug === null || requestParameters.subpageSlug === undefined) {
            throw new runtime.RequiredError('subpageSlug','Required parameter requestParameters.subpageSlug was null or undefined when calling customerPageContentAuthLoginWithPasswordlessSession.');
        }

        if (requestParameters.loginWithPasswordlessSessionRequestBody === null || requestParameters.loginWithPasswordlessSessionRequestBody === undefined) {
            throw new runtime.RequiredError('loginWithPasswordlessSessionRequestBody','Required parameter requestParameters.loginWithPasswordlessSessionRequestBody was null or undefined when calling customerPageContentAuthLoginWithPasswordlessSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/customer_status_page_content_auth/{main_slug}/{subpage_slug}/login`.replace(`{${"main_slug"}}`, encodeURIComponent(String(requestParameters.mainSlug))).replace(`{${"subpage_slug"}}`, encodeURIComponent(String(requestParameters.subpageSlug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerPageContentAuthLoginWithPasswordlessSessionRequestBodyToJSON(requestParameters.loginWithPasswordlessSessionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPageContentAuthLoginWithPasswordlessSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Takes an authorization code and a page ID, and returns a JWT token for a long-term authenticated session.
     * LoginWithPasswordlessSession Customer Page Content Auth
     */
    async customerPageContentAuthLoginWithPasswordlessSession(requestParameters: CustomerPageContentAuthLoginWithPasswordlessSessionRequest, initOverrides?: RequestInit): Promise<CustomerPageContentAuthLoginWithPasswordlessSessionResponseBody> {
        const response = await this.customerPageContentAuthLoginWithPasswordlessSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a redirect from WorkOS with a passwordless session, and redirects to a customer status page.
     * RedirectToCustomerPage Customer Page Content Auth
     */
    async customerPageContentAuthRedirectToCustomerPageRaw(requestParameters: CustomerPageContentAuthRedirectToCustomerPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/customer_status_page_content_auth/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Takes a redirect from WorkOS with a passwordless session, and redirects to a customer status page.
     * RedirectToCustomerPage Customer Page Content Auth
     */
    async customerPageContentAuthRedirectToCustomerPage(requestParameters: CustomerPageContentAuthRedirectToCustomerPageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerPageContentAuthRedirectToCustomerPageRaw(requestParameters, initOverrides);
    }

}
