import { ErrorMessage } from "@incident-ui";
import { ConfirmationDialog } from "@incident-ui";
import React from "react";
import { SavedView } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { useSavedViews } from "./SavedViewContext";

type Props = {
  onClose: () => void;
  savedView: SavedView;
};

export const SavedViewDeleteModal = ({
  onClose,
  savedView,
}: Props): React.ReactElement => {
  const { destroySavedView, context } = useSavedViews();
  const {
    trigger: deleteSavedView,
    isMutating: deleting,
    genericError,
  } = useAPIMutation("savedViewsList", { context }, destroySavedView, {
    onSuccess: onClose,
  });

  return (
    <ConfirmationDialog
      onCancel={onClose}
      onConfirm={() => deleteSavedView({ id: savedView.id })}
      isOpen={true}
      title="Delete saved view"
      analyticsTrackingId="delete-saved-view"
      saving={deleting}
    >
      <p>
        This will delete the saved view ‘{savedView.name}’ for everyone, are you
        sure?
      </p>
      <ErrorMessage message={genericError} />
    </ConfirmationDialog>
  );
};
