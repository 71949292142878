import {
  StatusPage,
  StatusPageComponent,
  StatusPageListSubscriptionsRequest,
  StatusPageStructure,
  StatusPageSubscription,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  Icon,
  IconEnum,
  IconSize,
  LocalDateTime,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import _, { compact } from "lodash";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Form } from "src/components/@shared/forms";
import { useAPIInfinite } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-hooks";

import { DownloadSubscribers } from "../common/DownloadSubscribers";

export const SubscriptionsList = ({
  page,
  structure,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement | null => {
  const searchFormMethods = useForm<
    Required<
      Pick<StatusPageListSubscriptionsRequest, "search" | "componentIds">
    >
  >({
    defaultValues: {
      search: "",
      componentIds: [],
    },
  });

  const search = searchFormMethods.watch("search");
  const componentIds = searchFormMethods.watch("componentIds");

  const {
    responses,
    isLoading,
    isFullyLoaded: allEntriesLoaded,
    loadMore: loadMoreEntries,
  } = useAPIInfinite("statusPageListSubscriptions", {
    statusPageId: page.id,
    pageSize: 50,
    search: useDebounce(search, 200),
    componentIds: componentIds.length > 0 ? componentIds : undefined, // we need to explicitly pass undefined for this query param to be omitted
  });

  const totalCount = responses[0]?.pagination_meta?.total_record_count;

  const subscriptions = responses.flatMap(({ subscriptions }) => subscriptions);
  const isParentPage = !!page?.split_by_catalog_type_id;

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !allEntriesLoaded,
    onLoadMore: loadMoreEntries,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const [isExporting, setIsExporting] = useState(false);
  const handleExportCompleted = useCallback(() => setIsExporting(false), []);

  const componentOptions = compact(
    structure.items.map(({ group, component }) =>
      group
        ? {
            label: group.name,
            options: group.components.map((comp) => ({
              value: comp.component_id,
              label: comp.name,
            })),
          }
        : component
        ? {
            value: component.component_id,
            label: component.name,
          }
        : undefined,
    ),
  );

  return (
    <section className="bg-surface-secondary rounded-[6px] border border-stroke p-4 space-y-2">
      <Form.Root onSubmit={() => void 0} formMethods={searchFormMethods}>
        <h3 className="font-medium">Email subscribers</h3>
        <Form.Helptext>
          This is everyone who has subscribed to email updates on your status
          page, and confirmed their subscription.
        </Form.Helptext>

        <div className="flex flex-col lg:flex-row lg:items-center gap-4">
          <div className="flex flex-col sm:flex-row items-center gap-2 grow">
            <InputV2
              placeholder="Search"
              className="w-full grow sm:w-auto flex-1"
              inputClassName="bg-white"
              name="search"
              formMethods={searchFormMethods}
              type={InputType.Search}
              iconName={IconEnum.Search}
            />
            <StaticMultiSelectV2
              placeholder="Filter by component"
              className="w-full sm:min-w-[180px] flex-1"
              name="componentIds"
              formMethods={searchFormMethods}
              options={componentOptions}
            />
          </div>
          <div className="flex items-center justify-end gap-2">
            <span className="text-sm whitespace-nowrap">
              Showing {totalCount === undefined ? "..." : totalCount} subscriber
              {totalCount === 1 ? "" : "s"}
            </span>
            <Button
              analyticsTrackingId="export-incident-csv-modal-open"
              icon={IconEnum.Export}
              loading={isExporting}
              onClick={() => setIsExporting(true)}
              iconProps={{ size: IconSize.Medium }}
              theme={ButtonTheme.Naked}
            >
              Export CSV
            </Button>
            {isExporting ? (
              <DownloadSubscribers
                search={search}
                componentIds={componentIds}
                statusPageId={page.id}
                onDownload={handleExportCompleted}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Form.Root>
      <DeprecatedTable className="overflow-x-auto shadow-none !mt-4">
        <DeprecatedTableHeaderRow className="font-medium">
          <DeprecatedTableHeaderCell>Email</DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell>Subscribed at</DeprecatedTableHeaderCell>
          {isParentPage && (
            <DeprecatedTableHeaderCell>Sub-page</DeprecatedTableHeaderCell>
          )}
          <DeprecatedTableHeaderCell>Components</DeprecatedTableHeaderCell>
        </DeprecatedTableHeaderRow>
        <tbody>
          {subscriptions.map((subscription) => (
            <SubscriberRow
              key={subscription.id}
              structure={structure}
              subscription={subscription}
              isParentPage={isParentPage}
            />
          ))}
          {!allEntriesLoaded && (
            <tr ref={sentryRef}>
              <td>
                <LoadingBar />
              </td>
              <td>
                <LoadingBar />
              </td>
              {isParentPage && (
                <td>
                  <LoadingBar />
                </td>
              )}
              <td>
                <LoadingBar />
              </td>
            </tr>
          )}
          {subscriptions.length === 0 && allEntriesLoaded && (
            <tr>
              <td colSpan={isParentPage ? 4 : 3} className="text-center">
                You don&rsquo;t have any email subscribers yet.
              </td>
            </tr>
          )}
        </tbody>
      </DeprecatedTable>
    </section>
  );
};

const MAX_COLLAPSED_COMPONENTS = 2;

const SubscriberRow = ({
  structure,
  subscription,
  isParentPage,
}: {
  structure: StatusPageStructure;
  subscription: StatusPageSubscription;
  isParentPage: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const canExpand = subscription.components.length > MAX_COLLAPSED_COMPONENTS;

  const fullComponentName = (component: StatusPageComponent) => {
    const group = structure.items.find(
      (item) =>
        item.group?.components.some(
          (comp) => comp.component_id === component.id,
        ),
    );
    if (group) {
      return (
        <>
          {group.group?.name} &raquo; {component.name}
        </>
      );
    }

    return component.name;
  };

  // If there are >3 components, only show the first three
  let components = _.sortBy(subscription.components, "name");
  if (canExpand && !expanded) {
    components = _.take(components, MAX_COLLAPSED_COMPONENTS);
  }

  return (
    <tr className="hover:bg-surface-secondary/50 transition">
      <td className="w-[1px] whitespace-nowrap">{subscription.email}</td>
      <td className="w-[1px] whitespace-nowrap">
        <LocalDateTime timestamp={subscription.verified_at} />
      </td>
      {isParentPage && <td>{subscription.sub_page_name || ""}</td>}
      <td>
        <div
          className={tcx(
            "min-w-[150px] h-full flex items-center gap-2",
            expanded ? "flex-wrap" : "flex-nowrap",
          )}
        >
          {subscription.components.length > 0
            ? components.map((component) => (
                <Badge
                  key={component.id}
                  theme={BadgeTheme.Unstyled}
                  className="border-stroke border bg-transparent !text-content-primary"
                >
                  {fullComponentName(component)}
                </Badge>
              ))
            : "All components"}

          {canExpand && (
            <button
              className={tcx(
                "border border-stroke border-dashed rounded-full",
                "cursor-pointer text-xs font-medium",
                "bg-transparent",
                "px-2 py-1",
                "transition",
                "group hover:border-solid",
                "flex items-center gap-1",
                "whitespace-nowrap",
              )}
              onClick={() => setExpanded((prev) => !prev)}
            >
              <Icon
                id={expanded ? IconEnum.Collapse : IconEnum.Collapse}
                size={IconSize.Medium}
                className="flex-none text-slate-600 group-hover:text-content-primary transition"
              />
              {expanded ? "Show less" : "Show more"}
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};
