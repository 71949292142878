/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertsCreateDraftSourceConfigRequestBody
 */
export interface AlertsCreateDraftSourceConfigRequestBody {
    /**
     * Unique name of the alert source
     * @type {string}
     * @memberof AlertsCreateDraftSourceConfigRequestBody
     */
    name: string;
    /**
     * Type of alert source
     * @type {string}
     * @memberof AlertsCreateDraftSourceConfigRequestBody
     */
    source_type: string;
}

export function AlertsCreateDraftSourceConfigRequestBodyFromJSON(json: any): AlertsCreateDraftSourceConfigRequestBody {
    return AlertsCreateDraftSourceConfigRequestBodyFromJSONTyped(json, false);
}

export function AlertsCreateDraftSourceConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsCreateDraftSourceConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'source_type': json['source_type'],
    };
}

export function AlertsCreateDraftSourceConfigRequestBodyToJSON(value?: AlertsCreateDraftSourceConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'source_type': value.source_type,
    };
}

