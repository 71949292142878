/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentsBulkUpdateModeRequestBody
 */
export interface IncidentsBulkUpdateModeRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentsBulkUpdateModeRequestBody
     */
    incident_ids: Array<string>;
    /**
     * Whether the incident is real, a test, or a tutorial
     * @type {string}
     * @memberof IncidentsBulkUpdateModeRequestBody
     */
    mode: IncidentsBulkUpdateModeRequestBodyModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsBulkUpdateModeRequestBodyModeEnum {
    Standard = 'standard',
    Retrospective = 'retrospective',
    Test = 'test',
    Tutorial = 'tutorial'
}

export function IncidentsBulkUpdateModeRequestBodyFromJSON(json: any): IncidentsBulkUpdateModeRequestBody {
    return IncidentsBulkUpdateModeRequestBodyFromJSONTyped(json, false);
}

export function IncidentsBulkUpdateModeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsBulkUpdateModeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_ids': json['incident_ids'],
        'mode': json['mode'],
    };
}

export function IncidentsBulkUpdateModeRequestBodyToJSON(value?: IncidentsBulkUpdateModeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_ids': value.incident_ids,
        'mode': value.mode,
    };
}

