import { ButtonTheme, Loader, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureMessage } from "@sentry/react";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { RadioButtonGroupV2 } from "src/components/@shared/forms/v2/inputs/RadioButtonGroupV2";
import { TemplatedTextInputV2 } from "src/components/@shared/forms/v2/inputs/TemplatedTextInputV2";
import { TimeZoneSelectV2 } from "src/components/@shared/forms/v2/inputs/TimeZoneSelectV2";
import { SettingsSubHeading } from "src/components/settings/SettingsSubHeading";
import {
  EngineScope,
  IncidentChannelConfig,
  IncidentChannelConfigFormatEnum as ChannelPrefixEnum,
  IncidentStatusCategoryEnum,
  ScopeNameEnum,
  Settings,
  Severity,
  TextNode,
} from "src/contexts/ClientContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { GatedButton } from "../../gates/GatedButton/GatedButton";

type PrefixConfig = {
  name: string;
  slackExample?: string;
  teamsExample?: string;
};

const availableChannelPrefixes: {
  [key in ChannelPrefixEnum]: PrefixConfig;
} = {
  [ChannelPrefixEnum.IncidentYyyyMmDd]: {
    name: "Date and incident prefixed",
    slackExample: "#incident-2020-04-01-the-api-is-down",
    teamsExample: "Incident 2020-04-01 - The API is down",
  },
  [ChannelPrefixEnum.IncYyyyMmDd]: {
    name: "Date prefixed",
    slackExample: "#inc-2020-04-01-the-api-is-down",
    teamsExample: "INC 2020-04-01 - The API is down",
  },
  [ChannelPrefixEnum.IncId]: {
    name: "ID prefixed",
    slackExample: "#inc-123-the-api-is-down",
    teamsExample: "INC-123 - The API is down",
  },
  [ChannelPrefixEnum.Inc]: {
    name: "No prefix",
    slackExample: "#inc-the-api-is-down",
    teamsExample: "INC - The API is down",
  },
  [ChannelPrefixEnum.Custom]: {
    name: "Custom",
  },
};

type FormData = {
  channel_config: IncidentChannelConfig & {
    // Typescript can't deal with the nested nature of the TextNode type
    custom_format: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  };
};

const toForm = (settings: Settings): FormData =>
  // This yuckiness is because we are auto-generating different enums for the request and response types.
  // I think this is fixable, but I don't know how.
  settings.misc.incident_channels as unknown as FormData;

export const SlackChannelNameEditForm = ({
  settings,
}: {
  settings: Settings;
}): React.ReactElement => {
  const showToast = useToast();
  const formMethods = useForm<FormData>({ defaultValues: toForm(settings) });
  const { setError, reset } = formMethods;
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: FormData) =>
      await apiClient.settingsUpdateIncidentChannel({
        updateIncidentChannelRequestBody: {
          incident_channels: data,
        },
      }),
    {
      setError,
      onSuccess: async ({ settings }) => {
        reset(toForm(settings));
        showToast(SharedToasts.SETTINGS_SAVED);
      },
    },
  );

  return (
    <div>
      <SettingsSubHeading
        title="Channel name"
        titleHeadingLevel={2}
        className="!mb-2"
        explanation={`Choose the naming convention that we'll use when we create incident channels.`}
      />

      <Form.Root
        onSubmit={onSubmit}
        formMethods={formMethods}
        genericError={genericError}
      >
        <SlackChannelNameEditFormInner formMethods={formMethods} />
        <GatedButton
          className="mt-2"
          analyticsTrackingId="update-channel-name"
          theme={ButtonTheme.Primary}
          loading={saving}
          type="submit"
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        >
          Save
        </GatedButton>
      </Form.Root>
    </div>
  );
};

export const SlackChannelNameEditFormInner = ({
  formMethods,
}: {
  // We're using this form in two different places - typescript gets
  // very unhappy that the formdata might have different types
  // I've tried solving this with generics but it just got more angry...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any, any>;
}) => {
  const commsPlatform = usePrimaryCommsPlatform() ?? CommsPlatform.Slack;
  const { watch } = formMethods;

  const {
    data: { severities },
    isLoading: severitiesLoading,
  } = useAPI("severitiesList", undefined, { fallbackData: { severities: [] } });
  const referenceKey = "incident_for_channel_prefix";
  const { data: scopeData, isLoading: scopeLoading } = useAPI(
    "engineBuildScope",
    {
      buildScopeRequestBody: {
        scope: [
          {
            type: "IncidentForChannelPrefix",
            label: "Incident",
            key: referenceKey,
            array: false,
          },
        ],
      },
    },
  );
  const customFormat = watch("channel_config.custom_format");
  const formattedCustomPrefix = buildPrefixFormatter({
    severities: severities || [],
  })(customFormat);

  if (scopeLoading || severitiesLoading) {
    return <Loader />;
  }

  const radioButtonOptions = Object.entries(availableChannelPrefixes)
    .filter(([prefixId]) => {
      // Don't show custom as an option for Microsoft Teams
      return !(
        prefixId === ChannelPrefixEnum.Custom &&
        commsPlatform === CommsPlatform.MSTeams
      );
    })
    .map(([prefixID, prefixConfig]) => ({
      value: prefixID,
      label: prefixConfig.name,
      description: descriptionFor(prefixConfig, commsPlatform),
      renderWhenSelectedNode: renderWhenSelectedNode({
        prefixID: prefixID as ChannelPrefixEnum,
        scope: scopeData?.scope,
        formMethods: formMethods,
        formattedCustomPrefix: formattedCustomPrefix,
        commsPlatform: commsPlatform,
      }),
    }));

  return (
    <RadioButtonGroupV2
      formMethods={formMethods}
      name={"channel_config.format"}
      srLabel="Channel prefix"
      boxed
      options={radioButtonOptions}
    />
  );
};

const descriptionFor = (
  prefixConfig: PrefixConfig,
  commsPlatform: CommsPlatform,
): string | undefined => {
  if (commsPlatform === CommsPlatform.Slack && prefixConfig.slackExample) {
    return `e.g. ${prefixConfig.slackExample}`;
  } else if (
    commsPlatform === CommsPlatform.MSTeams &&
    prefixConfig.teamsExample
  ) {
    return `e.g. ${prefixConfig.teamsExample}`;
  }

  return undefined;
};

const renderWhenSelectedNode = (
  props: WhenSelectedNodeProps,
): (() => React.ReactNode) | undefined => {
  if (
    [ChannelPrefixEnum.Inc, ChannelPrefixEnum.IncId].includes(props.prefixID)
  ) {
    return undefined;
  }
  // eslint-disable-next-line react/display-name
  return () => <WhenSelectedNode {...props} />;
};

type WhenSelectedNodeProps = {
  prefixID: ChannelPrefixEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any, any>;
  scope?: EngineScope;
  formattedCustomPrefix: string | null;
  commsPlatform: CommsPlatform;
};

const WhenSelectedNode = ({
  prefixID,
  scope,
  formMethods,
  formattedCustomPrefix,
  commsPlatform,
}: WhenSelectedNodeProps): React.ReactElement => {
  const placeholder =
    commsPlatform === CommsPlatform.MSTeams ? "e.g. INC" : "e.g. #inc-";

  return (
    <div className="space-y-4">
      {prefixID === ChannelPrefixEnum.Custom && (
        <div className="w-full">
          <TemplatedTextInputV2
            formMethods={formMethods}
            name={"channel_config.custom_format"}
            format={"plain"}
            scope={scope}
            includeVariables={true}
            includeExpressions={false}
            required="Please enter a custom format"
            placeholder={placeholder}
          />
          {formattedCustomPrefix && (
            <div className="text-xs text-slate-700 mb-2 ml-2 mt-1">
              e.g. #{formattedCustomPrefix}
            </div>
          )}
        </div>
      )}
      {!prefixID ||
      [ChannelPrefixEnum.Inc, ChannelPrefixEnum.IncId].includes(
        prefixID as ChannelPrefixEnum,
      ) ? null : (
        <TimeZoneSelectV2
          formMethods={formMethods}
          name={"channel_config.timezone"}
          label="Which timezone should we use to calculate the date of the incident?"
        />
      )}
    </div>
  );
};

// formatCustomPrefix takes a custom prefix and formats it with a sample date or ID, so the user can
// see what it might look like.
const buildPrefixFormatter =
  (deps: InterpolationDeps) =>
  (customPrefix: TextNode | undefined): string | null => {
    const getInterpolatedVariableForType = buildVariableInterpolator(deps);
    if (!customPrefix || !customPrefix.content) {
      return null;
    }

    const content = customPrefix.content[0].content;
    if (!content) {
      captureMessage("could not extract content from parsed string", {
        extra: { customPrefix },
      });
      return null;
    }

    let formattedString = "";

    // Each item will either be text, or a variable that needs interpolating with something sensible.
    content.forEach((item) => {
      if (item.type === "text") {
        formattedString += item.text;
      } else {
        if (!item.attrs?.name) {
          captureMessage("could not extract name from attrs", {
            extra: { item },
          });
        } else {
          formattedString += getInterpolatedVariableForType(item.attrs.name);
        }
      }
    });

    return formattedString;
  };

type InterpolationDeps = {
  severities: Severity[];
};

const buildVariableInterpolator =
  ({ severities }: InterpolationDeps) =>
  (type: string): string => {
    switch (type) {
      case "incident_for_channel_prefix.external_id":
        return "123";
      case "incident_for_channel_prefix.date-yyyy-MM-dd":
        return "2020-04-01";
      case "incident_for_channel_prefix.date-dd-MM-yyyy":
        return "01-04-2020";
      case "incident_for_channel_prefix.date-MM-dd-yyyy":
        return "04-01-2020";
      case "incident_for_channel_prefix.date-MM-dd":
        return "04-01";
      case "incident_for_channel_prefix.date-MMM-d":
        return "apr-1";
      case "incident_for_channel_prefix.date-dd-MMMM":
        return "01-april";
      case "incident_for_channel_prefix.date-MMMM-dd":
        return "april-01";
      case "incident_for_channel_prefix.date-MMMM-dd-yy":
        return "april-01-20";
      case "incident_for_channel_prefix.date-yyMMdd":
        return "200401";
      case "incident_for_channel_prefix.date-yyyyMMdd":
        return "20200401";
      case "incident_for_channel_prefix.name":
        return "the-api-is-down";
      case "incident_for_channel_prefix.severity":
        return (
          severities[0]?.name?.toLowerCase()?.replaceAll(" ", "-") || "major"
        );
      case "incident_for_channel_prefix.status_category":
        return IncidentStatusCategoryEnum.Active;
      case "incident_for_channel_prefix.external_issue_reference":
        return "JT-123";
      default:
        captureMessage("unknown variable type", { extra: { type } });
        return "";
    }
  };
