import { TranscriptEntry } from "@incident-io/api";
import { CallSession } from "@incident-io/query-api";
import { Avatar, Heading, IconSize, Loader, LocalDateTime } from "@incident-ui";
import { formatDistanceToNow, parseISO } from "date-fns";
import useInfiniteScroll from "react-infinite-scroll-hook";

export const CallTranscript = ({
  callSession,
  entries,
  isFullyLoaded,
  isLoading,
  onLoadMore,
}: {
  callSession: CallSession;
  entries: TranscriptEntry[];
  isFullyLoaded: boolean;
  isLoading: boolean;
  onLoadMore: () => Promise<void>;
}) => {
  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry gets close to
    // the viewport, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  let headingTitle = "Live transcript";
  if (callSession.ended_at) {
    headingTitle = "Full transcript";
  }

  let latestMessageTime = "";
  if (callSession.ended_at) {
    latestMessageTime = formatDistanceToNow(callSession.ended_at, {
      addSuffix: true,
      includeSeconds: true,
    });
  } else if (entries.length > 0) {
    const latestMessage = entries[0].timestamp;
    const timestamp =
      typeof latestMessage === "string"
        ? parseISO(latestMessage)
        : latestMessage;
    latestMessageTime = formatDistanceToNow(timestamp, {
      addSuffix: true,
      includeSeconds: true,
    });
  }

  return (
    <div className="pt-6">
      <Heading level={2} size="medium" className="shrink-0">
        {headingTitle}
      </Heading>
      {callSession.ended_at && (
        <span className="text-content-tertiary text-xs">
          Live transcription concluded {latestMessageTime}
        </span>
      )}
      <ul className="flex flex-col">
        {entries.map((entry, i) => (
          <TranscriptEntryItem entry={entry} key={i} />
        ))}
        {!isFullyLoaded && (
          <div ref={infiniteScrollRef}>
            <Loader />
          </div>
        )}
      </ul>
    </div>
  );
};

const TranscriptEntryItem = ({ entry }: { entry: TranscriptEntry }) => {
  return (
    <li className="flex flex-col py-4">
      <div className="flex gap-2">
        <Avatar size={IconSize.Large} name={entry.speaker_name} />
        <div>
          <div className="flex items-center gap-2">
            <div className="text-sm-bold">{entry.speaker_name}</div>
            <LocalDateTime
              timestamp={entry.timestamp}
              format="HH:mm"
              className="text-sm-normal text-content-tertiary"
            />
          </div>
          <div className="text-sm-normal text-content-secondary">
            {entry.content}
          </div>
        </div>
      </div>
    </li>
  );
};
