/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SalesforceConfig,
    SalesforceConfigFromJSON,
    SalesforceConfigFromJSONTyped,
    SalesforceConfigToJSON,
} from './SalesforceConfig';

/**
 * 
 * @export
 * @interface IntegrationsSalesforceGetConfigResponseBody
 */
export interface IntegrationsSalesforceGetConfigResponseBody {
    /**
     * 
     * @type {SalesforceConfig}
     * @memberof IntegrationsSalesforceGetConfigResponseBody
     */
    config: SalesforceConfig;
}

export function IntegrationsSalesforceGetConfigResponseBodyFromJSON(json: any): IntegrationsSalesforceGetConfigResponseBody {
    return IntegrationsSalesforceGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsSalesforceGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsSalesforceGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': SalesforceConfigFromJSON(json['config']),
    };
}

export function IntegrationsSalesforceGetConfigResponseBodyToJSON(value?: IntegrationsSalesforceGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': SalesforceConfigToJSON(value.config),
    };
}

