/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentApplicableFields,
    IncidentApplicableFieldsFromJSON,
    IncidentApplicableFieldsFromJSONTyped,
    IncidentApplicableFieldsToJSON,
} from './IncidentApplicableFields';
import {
    Stream,
    StreamFromJSON,
    StreamFromJSONTyped,
    StreamToJSON,
} from './Stream';

/**
 * 
 * @export
 * @interface StreamsShowResponseBody
 */
export interface StreamsShowResponseBody {
    /**
     * 
     * @type {IncidentApplicableFields}
     * @memberof StreamsShowResponseBody
     */
    applicable_fields?: IncidentApplicableFields;
    /**
     * 
     * @type {Stream}
     * @memberof StreamsShowResponseBody
     */
    stream?: Stream;
}

export function StreamsShowResponseBodyFromJSON(json: any): StreamsShowResponseBody {
    return StreamsShowResponseBodyFromJSONTyped(json, false);
}

export function StreamsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicable_fields': !exists(json, 'applicable_fields') ? undefined : IncidentApplicableFieldsFromJSON(json['applicable_fields']),
        'stream': !exists(json, 'stream') ? undefined : StreamFromJSON(json['stream']),
    };
}

export function StreamsShowResponseBodyToJSON(value?: StreamsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicable_fields': IncidentApplicableFieldsToJSON(value.applicable_fields),
        'stream': StreamToJSON(value.stream),
    };
}

