import type { SVGProps } from "react";
import * as React from "react";
const SvgThumbsUp = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.331 9.073A2.73 2.73 0 0 0 15.152 8h-3.326l.798-2.167a2.62 2.62 0 0 0-1.289-3.254.75.75 0 0 0-.903.181L6.168 7.697A2.753 2.753 0 0 0 5.5 9.494v5.756A2.753 2.753 0 0 0 8.25 18h5.71a2.754 2.754 0 0 0 2.659-2.046l1.191-4.5a2.733 2.733 0 0 0-.479-2.381Z"
    />
    <path
      fill="currentColor"
      d="M5.25 18h-1.5C2.785 18 2 17.215 2 16.25v-6.5C2 8.785 2.785 8 3.75 8h1.5C6.215 8 7 8.785 7 9.75v6.5C7 17.215 6.215 18 5.25 18Zm-1.5-8.5a.25.25 0 0 0-.25.25v6.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-6.5a.25.25 0 0 0-.25-.25h-1.5Z"
    />
  </svg>
);
export default SvgThumbsUp;
