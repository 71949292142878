/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IssueTrackersV2CreateIssueTemplateRequestBody,
    IssueTrackersV2CreateIssueTemplateRequestBodyFromJSON,
    IssueTrackersV2CreateIssueTemplateRequestBodyToJSON,
    IssueTrackersV2CreateIssueTemplateResponseBody,
    IssueTrackersV2CreateIssueTemplateResponseBodyFromJSON,
    IssueTrackersV2CreateIssueTemplateResponseBodyToJSON,
    IssueTrackersV2ListCreationFieldsRequestBody,
    IssueTrackersV2ListCreationFieldsRequestBodyFromJSON,
    IssueTrackersV2ListCreationFieldsRequestBodyToJSON,
    IssueTrackersV2ListCreationFieldsResponseBody,
    IssueTrackersV2ListCreationFieldsResponseBodyFromJSON,
    IssueTrackersV2ListCreationFieldsResponseBodyToJSON,
    IssueTrackersV2ListIssueTemplatesResponseBody,
    IssueTrackersV2ListIssueTemplatesResponseBodyFromJSON,
    IssueTrackersV2ListIssueTemplatesResponseBodyToJSON,
    IssueTrackersV2ListSupportedProvidersAndContextsResponseBody,
    IssueTrackersV2ListSupportedProvidersAndContextsResponseBodyFromJSON,
    IssueTrackersV2ListSupportedProvidersAndContextsResponseBodyToJSON,
    IssueTrackersV2ShowIssueTemplateResponseBody,
    IssueTrackersV2ShowIssueTemplateResponseBodyFromJSON,
    IssueTrackersV2ShowIssueTemplateResponseBodyToJSON,
    IssueTrackersV2UpdateIssueTemplateRequestBody,
    IssueTrackersV2UpdateIssueTemplateRequestBodyFromJSON,
    IssueTrackersV2UpdateIssueTemplateRequestBodyToJSON,
    IssueTrackersV2UpdateIssueTemplateResponseBody,
    IssueTrackersV2UpdateIssueTemplateResponseBodyFromJSON,
    IssueTrackersV2UpdateIssueTemplateResponseBodyToJSON,
} from '../models';

export interface IssueTrackersV2CreateIssueTemplateRequest {
    createIssueTemplateRequestBody: IssueTrackersV2CreateIssueTemplateRequestBody;
}

export interface IssueTrackersV2DestroyIssueTemplateRequest {
    id: string;
}

export interface IssueTrackersV2ListCreationFieldsRequest {
    listCreationFieldsRequestBody: IssueTrackersV2ListCreationFieldsRequestBody;
}

export interface IssueTrackersV2ListIssueTemplatesRequest {
    provider?: string;
}

export interface IssueTrackersV2ShowIssueTemplateRequest {
    id: string;
}

export interface IssueTrackersV2UpdateIssueTemplateRequest {
    id: string;
    updateIssueTemplateRequestBody: IssueTrackersV2UpdateIssueTemplateRequestBody;
}

/**
 * 
 */
export class IssueTrackersV2Api extends runtime.BaseAPI {

    /**
     * Create an issue template for a given provider
     * CreateIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2CreateIssueTemplateRaw(requestParameters: IssueTrackersV2CreateIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersV2CreateIssueTemplateResponseBody>> {
        if (requestParameters.createIssueTemplateRequestBody === null || requestParameters.createIssueTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('createIssueTemplateRequestBody','Required parameter requestParameters.createIssueTemplateRequestBody was null or undefined when calling issueTrackersV2CreateIssueTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers_v2/issue_templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersV2CreateIssueTemplateRequestBodyToJSON(requestParameters.createIssueTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersV2CreateIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an issue template for a given provider
     * CreateIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2CreateIssueTemplate(requestParameters: IssueTrackersV2CreateIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersV2CreateIssueTemplateResponseBody> {
        const response = await this.issueTrackersV2CreateIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an issue template
     * DestroyIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2DestroyIssueTemplateRaw(requestParameters: IssueTrackersV2DestroyIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackersV2DestroyIssueTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers_v2/issue_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an issue template
     * DestroyIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2DestroyIssueTemplate(requestParameters: IssueTrackersV2DestroyIssueTemplateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.issueTrackersV2DestroyIssueTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * List the fields that can be used to create an issue in an issue tracker
     * ListCreationFields IssueTrackersV2
     */
    async issueTrackersV2ListCreationFieldsRaw(requestParameters: IssueTrackersV2ListCreationFieldsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersV2ListCreationFieldsResponseBody>> {
        if (requestParameters.listCreationFieldsRequestBody === null || requestParameters.listCreationFieldsRequestBody === undefined) {
            throw new runtime.RequiredError('listCreationFieldsRequestBody','Required parameter requestParameters.listCreationFieldsRequestBody was null or undefined when calling issueTrackersV2ListCreationFields.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers_v2/list_creation_fields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersV2ListCreationFieldsRequestBodyToJSON(requestParameters.listCreationFieldsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersV2ListCreationFieldsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the fields that can be used to create an issue in an issue tracker
     * ListCreationFields IssueTrackersV2
     */
    async issueTrackersV2ListCreationFields(requestParameters: IssueTrackersV2ListCreationFieldsRequest, initOverrides?: RequestInit): Promise<IssueTrackersV2ListCreationFieldsResponseBody> {
        const response = await this.issueTrackersV2ListCreationFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the issue templates for a given provider
     * ListIssueTemplates IssueTrackersV2
     */
    async issueTrackersV2ListIssueTemplatesRaw(requestParameters: IssueTrackersV2ListIssueTemplatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersV2ListIssueTemplatesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers_v2/issue_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersV2ListIssueTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the issue templates for a given provider
     * ListIssueTemplates IssueTrackersV2
     */
    async issueTrackersV2ListIssueTemplates(requestParameters: IssueTrackersV2ListIssueTemplatesRequest, initOverrides?: RequestInit): Promise<IssueTrackersV2ListIssueTemplatesResponseBody> {
        const response = await this.issueTrackersV2ListIssueTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the supported issue tracker providers and contexts for the current organisation
     * ListSupportedProvidersAndContexts IssueTrackersV2
     */
    async issueTrackersV2ListSupportedProvidersAndContextsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersV2ListSupportedProvidersAndContextsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers_v2/supported_providers_and_contexts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersV2ListSupportedProvidersAndContextsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the supported issue tracker providers and contexts for the current organisation
     * ListSupportedProvidersAndContexts IssueTrackersV2
     */
    async issueTrackersV2ListSupportedProvidersAndContexts(initOverrides?: RequestInit): Promise<IssueTrackersV2ListSupportedProvidersAndContextsResponseBody> {
        const response = await this.issueTrackersV2ListSupportedProvidersAndContextsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show an issue template for a given ID
     * ShowIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2ShowIssueTemplateRaw(requestParameters: IssueTrackersV2ShowIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersV2ShowIssueTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackersV2ShowIssueTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/issue_trackers_v2/issue_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersV2ShowIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an issue template for a given ID
     * ShowIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2ShowIssueTemplate(requestParameters: IssueTrackersV2ShowIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersV2ShowIssueTemplateResponseBody> {
        const response = await this.issueTrackersV2ShowIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an issue template for a given provider
     * UpdateIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2UpdateIssueTemplateRaw(requestParameters: IssueTrackersV2UpdateIssueTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IssueTrackersV2UpdateIssueTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling issueTrackersV2UpdateIssueTemplate.');
        }

        if (requestParameters.updateIssueTemplateRequestBody === null || requestParameters.updateIssueTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('updateIssueTemplateRequestBody','Required parameter requestParameters.updateIssueTemplateRequestBody was null or undefined when calling issueTrackersV2UpdateIssueTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/issue_trackers_v2/issue_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTrackersV2UpdateIssueTemplateRequestBodyToJSON(requestParameters.updateIssueTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTrackersV2UpdateIssueTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an issue template for a given provider
     * UpdateIssueTemplate IssueTrackersV2
     */
    async issueTrackersV2UpdateIssueTemplate(requestParameters: IssueTrackersV2UpdateIssueTemplateRequest, initOverrides?: RequestInit): Promise<IssueTrackersV2UpdateIssueTemplateResponseBody> {
        const response = await this.issueTrackersV2UpdateIssueTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
