import {
  DurationInput,
  DurationInputProps,
} from "@incident-ui/DurationInput/DurationInput";
import React from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  InputElementProps,
  parseProps,
} from "src/components/@shared/forms/v2/formsv2";
import { formatDurationInSeconds } from "src/utils/datetime";

export const EngineDurationInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<
    TFormType,
    Omit<DurationInputProps, "initialValue">
  > & {
    onBlurCallback?: () => void;
  },
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  const { value: valueAsBinding, onChange } = field;

  const onChangeSeconds = (val: number) => {
    if (!val) {
      return onChange({ label: "", literal: undefined });
    }
    const label = formatDurationInSeconds(val);
    return onChange({
      label,
      literal: val.toString(),
    });
  };

  return (
    <Form.InputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <DurationInput
        {...inputProps}
        {...field}
        value={parseInt(valueAsBinding?.literal)}
        onChange={onChangeSeconds}
      />
    </Form.InputWrapper>
  );
};
