/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntryMatchPayload,
    CatalogEntryMatchPayloadFromJSON,
    CatalogEntryMatchPayloadFromJSONTyped,
    CatalogEntryMatchPayloadToJSON,
} from './CatalogEntryMatchPayload';

/**
 * 
 * @export
 * @interface CustomFieldsConvertToExistingCatalogTypeRequestBody
 */
export interface CustomFieldsConvertToExistingCatalogTypeRequestBody {
    /**
     * ID of the catalog type to connect to
     * @type {string}
     * @memberof CustomFieldsConvertToExistingCatalogTypeRequestBody
     */
    catalog_type_id: string;
    /**
     * List of mappings from custom field option IDs to catalog entry IDs
     * @type {Array<CatalogEntryMatchPayload>}
     * @memberof CustomFieldsConvertToExistingCatalogTypeRequestBody
     */
    matches: Array<CatalogEntryMatchPayload>;
    /**
     * What strategy should we use if we can't match a given option?
     * @type {string}
     * @memberof CustomFieldsConvertToExistingCatalogTypeRequestBody
     */
    mismatch_strategy: CustomFieldsConvertToExistingCatalogTypeRequestBodyMismatchStrategyEnum;
}

/**
* @export
* @enum {string}
*/
export enum CustomFieldsConvertToExistingCatalogTypeRequestBodyMismatchStrategyEnum {
    ArchiveUnmatched = 'archive_unmatched',
    CreateUnmatched = 'create_unmatched'
}

export function CustomFieldsConvertToExistingCatalogTypeRequestBodyFromJSON(json: any): CustomFieldsConvertToExistingCatalogTypeRequestBody {
    return CustomFieldsConvertToExistingCatalogTypeRequestBodyFromJSONTyped(json, false);
}

export function CustomFieldsConvertToExistingCatalogTypeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldsConvertToExistingCatalogTypeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_id': json['catalog_type_id'],
        'matches': ((json['matches'] as Array<any>).map(CatalogEntryMatchPayloadFromJSON)),
        'mismatch_strategy': json['mismatch_strategy'],
    };
}

export function CustomFieldsConvertToExistingCatalogTypeRequestBodyToJSON(value?: CustomFieldsConvertToExistingCatalogTypeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_id': value.catalog_type_id,
        'matches': ((value.matches as Array<any>).map(CatalogEntryMatchPayloadToJSON)),
        'mismatch_strategy': value.mismatch_strategy,
    };
}

