import { AlertRoute, AlertSourceConfig } from "@incident-io/api";
import { Node } from "reactflow";

import {
  AlertsConfigurationNodeData,
  AlertsConfigurationNodeType,
} from "../AlertsConfigurationNode";

export const drawStackedNodes = ({
  alertSources,
  alertRoutes,
  containerWidth,
  sourceHeaderData,
  routeHeaderData,
  hasNoAlertRoutes,
}: {
  alertSources: AlertSourceConfig[];
  alertRoutes: AlertRoute[];
  containerWidth: number;
  sourceHeaderData: AlertsConfigurationNodeData;
  routeHeaderData: AlertsConfigurationNodeData;
  hasNoAlertRoutes: boolean;
}) => {
  const NODE_WIDTH = containerWidth - 48;
  const NODE_HEIGHT = 72;
  const PADDING = 24;
  const SPACING = 12;
  const GROUP_SPACING = 24;

  const nodes: Node[] = [];

  let nodeY = PADDING + NODE_HEIGHT;

  // Add the sources header
  nodes.push({
    id: "sources-header",
    type: "alertsConfigurationNode",
    data: sourceHeaderData,
    position: { x: PADDING, y: PADDING },
    style: {
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
    },
  });

  // Add the sources nodes
  alertSources.forEach((source) => {
    nodes.push({
      id: `source-${source.id}`,
      type: "alertsConfigurationNode",
      data: {
        label: source.name,
        nodeType: AlertsConfigurationNodeType.AlertSource,
        alertSource: source,
        allAlertSources: alertSources,
        allAlertRoutes: alertRoutes,
      },
      position: { x: PADDING, y: nodeY },
      style: {
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
      },
    });

    nodeY += NODE_HEIGHT + SPACING;
  });

  nodeY += GROUP_SPACING;

  // Add the routes header
  nodes.push({
    id: "routes-header",
    type: "alertsConfigurationNode",
    data: routeHeaderData,
    position: { x: PADDING, y: nodeY },
    style: {
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
    },
  });

  nodeY += NODE_HEIGHT + SPACING;

  if (hasNoAlertRoutes) {
    // Add an empty state node
    nodes.push({
      id: `no-alert-routes`,
      type: "alertsConfigurationNode",
      data: {
        label: "No alert routes",
        nodeType: AlertsConfigurationNodeType.AlertRoute,
      },
      position: { x: NODE_WIDTH * 2 - PADDING, y: nodeY },
      style: {
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
      },
    });
  }

  // Add the route nodes
  alertRoutes.forEach((route) => {
    nodes.push({
      id: `route-${route.id}`,
      type: "alertsConfigurationNode",
      data: {
        label: route.name,
        nodeType: AlertsConfigurationNodeType.AlertRoute,
        alertRoute: route,
      },
      position: { x: PADDING, y: nodeY },
      style: {
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
      },
    });

    nodeY += NODE_HEIGHT + SPACING;
  });

  return { nodes, height: Math.max(nodeY, window.innerHeight) };
};
