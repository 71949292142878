import type { SVGProps } from "react";
import * as React from "react";
const SvgVibrate = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="currentColor" d="M17 8h1.5v5H17zM1.5 8H3v5H1.5z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.25 2h-6.5A2.752 2.752 0 0 0 4 4.75v10.5A2.752 2.752 0 0 0 6.75 18h6.5A2.752 2.752 0 0 0 16 15.25V4.75A2.752 2.752 0 0 0 13.25 2Zm-2.139 13.111a1.111 1.111 0 1 1-2.222 0 1.111 1.111 0 0 1 2.222 0ZM9.167 4a.833.833 0 1 0 0 1.667h1.666a.833.833 0 1 0 0-1.667H9.167Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVibrate;
