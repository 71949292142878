import { StepSlim } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import {
  WorkflowFormData,
  WorkflowStep,
  WorkflowStepGroup,
} from "../../common/types";
import { WorkflowsFormContext } from "../WorkflowsFormContext";
import { WorkflowDrawerState } from "./useViewController";

export type StepFormState = {
  mode: Mode;
  selectedGroup: number | null;
  selectedStep: number | null;
};

export type WorkflowStepCallbacks = {
  newStepName: string | undefined;
  stepFormState: StepFormState | null;
  clearStepFormState: () => void;

  showEditStepForm: (groupIndex: number, stepIndex: number) => void;
  onClickAddStep: (groupIndex: number | null, stepIndex: number | null) => void;
  onCreateStep: (groupIndex: number | null, data: WorkflowStep) => void;
  onUpdateStepGroup: (
    groupIndex: number | null,
    stepIndex: number | null,
    data: WorkflowStep,
  ) => void;
  onDeleteStepGroup: (groupIndex: number) => void;
  onDeleteStep: (groupIndex: number, stepIndex: number) => void;
  onSetForEach: (groupIndex: number, forEach: string) => void;
  onReorderStepsWithinGroup: (
    groupIndex: number,
    steps: WorkflowStep[],
  ) => void;
  onReorderGroupSteps: (wfStepGroups: WorkflowStepGroup[]) => void;
  onCreateLoopingStep: () => void;
  onChooseStep: (step: StepSlim) => void;

  hasSteps: boolean;
  hasStepGroups: boolean;
  editStepFormMethods: UseFormReturn<WorkflowStep>;
  isDirty: boolean;
};

export const useStepsController = ({
  formMethods,
  onOpenDrawer,
  onCloseDrawer,
}: {
  formMethods: UseFormReturn<WorkflowFormData>;
  onOpenDrawer: (state: WorkflowDrawerState) => void;
  onCloseDrawer: () => void;
}): WorkflowStepCallbacks => {
  const { control, getValues } = formMethods;

  // stepGroups is used to group steps together.
  //
  // This is needed because in the UI, we need to allow for
  // a state in which a loop has no steps.
  // On submission stepGroup are marshalled into the
  // workflow.steps array.
  const {
    fields: stepGroupFields,
    append: appendStepGroup,
    remove: removeStepGroup,
    update: updateStepGroup,
    replace: replaceStepGroup,
  } = useFieldArray({
    control,
    name: "step_groups",
  });

  // stepFormState tracks what we're currently doing in the step drawer (adding
  // or editing, and if editing, which step)
  const [stepFormState, setStepFormMode] = useState<StepFormState | null>(null);

  // Step name, used to know when we've chosen a step to add.
  const [newStepName, setNewStepName] = useState<string | undefined>();

  const clearStepFormState = () => {
    setStepFormMode(null);
    editStepFormMethods.reset({}, { keepValues: false });
  };

  // NOTE: We do not provide the form with any default values, because
  // we want to always call reset() when the drawer is opened, to the
  // currently selected step - this is to ensure that the edit form
  // is always in sync with the currently selected step and in a pristine
  // state.
  const editStepFormMethods = useForm<WorkflowStep>({
    // It is important to pass defaultValues as an empty object
    // so that calls to reset the form actually clear it!
    defaultValues: {
      // For some reason, we need to pass undefined for the
      // param_bindings field, otherwise the form will
      // not reset correctly.
      param_bindings: undefined,
    },
  });

  const { watch: watchEditStepForm, formState } = editStepFormMethods;
  // Any changes to the editStepForm should re-render the main component.
  watchEditStepForm();

  // The below looks weird, but basically:
  //    if we are **creating**:
  //      - we want to check if the user has touched any fields, but we dirty the fields ourselves
  //        when we initialise the form
  //    if we are **editing**:
  //      - we want to check if the fields are *dirty* so we can prompt the user to save changes only
  //        when they change something from what the step originally had
  const isDirty =
    stepFormState?.mode === Mode.Edit
      ? !!Object.keys(formState.dirtyFields).length
      : !!Object.keys(formState.touchedFields).length;

  const onCreateLoopingStep = () => {
    // This functionality is key, as it allows us to add a new
    // WorkflowStepGroup without any steps i.e.
    // the first step in creating a looping step.
    const newStepGroup: WorkflowStepGroup = {
      isLoop: true,
      key: uuidv4(),
      steps: [],
      forEach: undefined,
    };

    appendStepGroup(newStepGroup);
    onCloseDrawer();
  };

  const onChooseStep = (step: StepSlim): void => {
    setNewStepName(step.name);
    editStepFormMethods.reset({}, { keepValues: false });
    onOpenDrawer(WorkflowDrawerState.EditStep);
  };

  const onCreateStep = (groupIndex: number | null, data: WorkflowStep) => {
    if (groupIndex == null) {
      // If group is _not_ set, we're just creating a standalone
      // step, which is simply a new group with one step in it.
      appendStepGroup({
        isLoop: false,
        key: uuidv4(),
        steps: [data],
        forEach: undefined,
      });
    } else {
      // If group is set, that means we're adding a step to a loop
      // and need to find the correct stepGroup to update.
      const currentStepGroup = cloneDeep(
        getValues(`step_groups.${groupIndex}`),
      );
      // Add step to current step group
      currentStepGroup.steps.push(data);
      updateStepGroup(groupIndex, currentStepGroup);
    }

    onCloseDrawer();
  };

  const onClickAddStep = (
    groupIndex: number | null,
    stepIndex: number | null,
  ) => {
    setStepFormMode({
      mode: Mode.Create,
      selectedGroup: groupIndex,
      selectedStep: stepIndex,
    });
    onOpenDrawer(WorkflowDrawerState.AddStep);
  };

  const onUpdateStepGroup = (
    groupIndex: number | null,
    stepIndex: number | null,
    data: WorkflowStep,
  ): void => {
    if (groupIndex == null || stepIndex == null) {
      return;
    }
    const currentStepGroup = cloneDeep(getValues(`step_groups.${groupIndex}`));
    currentStepGroup.steps[stepIndex] = data;
    updateStepGroup(groupIndex, currentStepGroup);
    onCloseDrawer();
  };

  const showEditStepForm = (groupIndex: number, stepIndex: number) => {
    setNewStepName(undefined);
    setStepFormMode({
      mode: Mode.Edit,
      selectedGroup: groupIndex,
      selectedStep: stepIndex,
    });

    // Reset the form to the currently selected step.
    editStepFormMethods.reset(
      getValues(`step_groups.${groupIndex}.steps.${stepIndex}`) as WorkflowStep,
      { keepValues: false },
    );
    onOpenDrawer(WorkflowDrawerState.EditStep);
  };

  const onDeleteStepGroup = (groupIndex: number) => {
    removeStepGroup(groupIndex);
  };

  const onDeleteStep = (groupIndex: number, stepIndex: number) => {
    const currentStepGroup = cloneDeep(getValues(`step_groups.${groupIndex}`));
    // Remove step from step group
    currentStepGroup.steps.splice(stepIndex, 1);
    updateStepGroup(groupIndex, currentStepGroup);
  };

  const onSetForEach = (groupIndex: number, forEach: string) => {
    const currentStepGroup = getValues(`step_groups.${groupIndex}`);
    currentStepGroup.forEach = forEach;
    updateStepGroup(groupIndex, currentStepGroup);
  };

  const onReorderStepsWithinGroup = (
    groupIndex: number,
    steps: WorkflowStep[],
  ) => {
    const currentStepGroup = cloneDeep(getValues(`step_groups.${groupIndex}`));
    currentStepGroup.steps = steps;
    updateStepGroup(groupIndex, currentStepGroup);
  };

  const onReorderGroupSteps = (wfStepGroups: WorkflowStepGroup[]) => {
    replaceStepGroup(wfStepGroups);
  };

  const hasSteps =
    stepGroupFields.length > 0 &&
    stepGroupFields.some((stepGroup) => stepGroup.steps.length > 0);

  return {
    // What do we show in the step drawer
    newStepName,
    stepFormState,
    clearStepFormState,
    showEditStepForm,
    // Do stuff with step groups
    onClickAddStep,
    onCreateLoopingStep,
    onChooseStep,
    onCreateStep,
    onUpdateStepGroup,
    onDeleteStepGroup,
    onDeleteStep,
    onSetForEach,
    onReorderStepsWithinGroup,
    onReorderGroupSteps,
    // Reflection of form state
    hasSteps,
    hasStepGroups: stepGroupFields.length > 0,
    editStepFormMethods,
    isDirty,
  };
};

export const useWorkflowsSteps = (): WorkflowStepCallbacks => {
  const ctx = useContext(WorkflowsFormContext);
  if (ctx && ctx.stepCallbacks) {
    return ctx.stepCallbacks;
  }
  throw new Error(
    "useWorkflowsSteps must be used within a WorkflowsFormProvider",
  );
};
