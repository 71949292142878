import { OrgAwareLink } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeProps,
  BadgeSize,
  BadgeTheme,
} from "@incident-ui/Badge/Badge";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { IconBadge } from "@incident-ui/IconBadge/IconBadge";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type StackedListItemProps = {
  title: React.ReactNode;
  badgeProps?: Omit<BadgeProps, "size" | "theme"> & {
    theme?: BadgeTheme;
    size?: BadgeSize;
  };
  badgeNode?: React.ReactNode;
  description?: React.ReactNode;
  descriptionPosition?: "below" | "inline";
  descriptionClassName?: string;
  rowHref?: string;
  hrefTarget?: string;
  onClick?: () => void;
  icon?: IconEnum;
  iconColor?: ColorPaletteEnum;
  iconNode?: React.ReactNode;
  accessory?: React.ReactNode;
  contentContainerClassName?: string;
  footerAccessory?: React.ReactNode;
  disabled?: boolean;
  disabledExplanation?: React.ReactNode;
  dragHandle?: React.ReactNode;
  noPadding?: boolean;
  allowShrink?: boolean;
  className?: string;
};

export const StackedListItem = ({
  title,
  badgeProps,
  badgeNode,
  description,
  descriptionPosition = "below",
  dragHandle,
  icon,
  iconColor = ColorPaletteEnum.Blue,
  iconNode,
  accessory,
  contentContainerClassName,
  footerAccessory,
  rowHref,
  onClick,
  disabled,
  disabledExplanation,
  noPadding = false,
  allowShrink = false,
  className,
  hrefTarget,
  descriptionClassName,
}: StackedListItemProps): React.ReactElement => {
  const compact = !description || descriptionPosition === "inline";
  const Component = rowHref ? OrgAwareLink : onClick ? "button" : "div";

  const clickable = !!rowHref || !!onClick;

  const descriptionNode = (
    <div className="text-content-tertiary text-sm flex text-left items-center gap-1 font-normal">
      {description}
    </div>
  );

  const doRender = () => (
    <div
      className={tcx(
        "block w-full overflow-auto group",
        {
          "py-3 px-4": !noPadding,
          "text-content-tertiary": disabled,
          "hover:bg-surface-secondary": clickable && !disabled,
        },
        className,
      )}
    >
      <div
        className={tcx("flex flex-col gap-3 grow", contentContainerClassName)}
      >
        <div className={"flex items-center gap-3"}>
          <Component
            to={rowHref || ""}
            target={hrefTarget}
            onClick={onClick}
            className={
              "flex items-center gap-3 grow overflow-auto scrollbar-none"
            }
          >
            {dragHandle}
            {icon && (
              <IconBadge
                icon={icon}
                color={disabled ? ColorPaletteEnum.Slate : iconColor}
                size={compact ? IconSize.Small : IconSize.Medium}
              />
            )}
            {iconNode}
            <div
              className={tcx(
                "grow my-1 overflow-auto scrollbar-none",
                descriptionClassName,
              )}
            >
              <div className="flex items-center gap-2">
                <div
                  className={tcx(
                    "flex font-semibold text-sm items-center gap-2 text-content-primary",
                    { "text-content-tertiary": disabled },
                    { "shrink-0": !allowShrink },
                  )}
                >
                  {title}
                  {badgeProps && (
                    <Badge
                      size={BadgeSize.ExtraSmall}
                      theme={BadgeTheme.Tertiary}
                      {...badgeProps}
                    />
                  )}
                  {badgeNode}
                  {descriptionPosition === "inline" && descriptionNode}
                </div>
              </div>
              {descriptionPosition === "below" && descriptionNode}
            </div>
          </Component>
          {accessory}
        </div>
        {footerAccessory}
      </div>
    </div>
  );

  if (disabled && disabledExplanation) {
    return <Tooltip content={disabledExplanation}>{doRender()}</Tooltip>;
  }

  return doRender();
};
