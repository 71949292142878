import { CreateEditExpressionFormData } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Loader } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { WrappedSingleStaticSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import {
  ExpressionOperationOperationTypeEnum,
  Resource,
} from "src/contexts/ClientContext";
import { filterScope, getEmptyScope, lookupInScope } from "src/utils/scope";

import { useGetScope } from "../ComponentForOperation";
import { ReferenceWithResource } from "../useGetPreviousReference";

export const NavigateTargetSelect = ({
  previousRef,
  operationIdx,
  isEditable,
  resources,
}: {
  previousRef: ReferenceWithResource;
  operationIdx: number;
  isEditable: boolean;
  resources: Resource[];
}): React.ReactElement => {
  const formMethods = useFormContext<CreateEditExpressionFormData>();

  const { scope: inputScope, isLoading } = useGetScope({
    ...previousRef,
    // Operations have access to each element, even though the input
    // type is an array of things.
    array: false,
  });

  // Find all direct children of the input reference.
  const navigateTargets = inputScope
    ? filterScope(inputScope, (ref) => ref.parent === "input")
    : getEmptyScope();

  const stripReferencePrefix = (ref: string) => ref.replace("input.", "");

  const navigateOptions: SelectOption[] = navigateTargets.references.map(
    (target) => {
      const resource = resources.find((res) => res.type === target.type);

      return {
        label: target.node_label,
        value: stripReferencePrefix(target.key),
        icon:
          target.icon || (resource?.field_config?.icon as unknown as IconEnum),
        color: (target.color ||
          resource?.field_config?.color ||
          ColorPaletteEnum.Blue) as unknown as ColorPaletteEnum,
        sort_key: target.node_label,
      };
    },
  );

  return isLoading ? (
    <Loader />
  ) : (
    <WrappedSingleStaticSelectV2
      formMethods={formMethods}
      name={`operations.${operationIdx}`}
      options={navigateOptions}
      disabled={!isEditable}
      required
      wrap={(value: string) => {
        const target = lookupInScope(navigateTargets, `input.${value}`);
        return {
          // If the input is an array OR the output is an array, this is going to return
          // an array.
          returns: {
            array: target?.array || previousRef.array,
            type: target?.type,
          },
          operation_type: ExpressionOperationOperationTypeEnum.Navigate,
          navigate: target?.key
            ? {
                reference: stripReferencePrefix(target.key),
                reference_label: target?.node_label,
              }
            : undefined,
        };
      }}
      unwrap={(operation) => {
        return operation?.navigate?.reference;
      }}
    />
  );
};
