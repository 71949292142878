import { OrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { Routes } from "react-router";
import { Route } from "react-router-dom";

import { CustomFieldContext, CustomFieldProvider } from "./CustomFieldContext";
import { CustomFieldPage } from "./CustomFieldPage";
import { CustomFieldCreateRoute } from "./SettingsCustomFieldCreateRoute";
import { CustomFieldEditRoute } from "./SettingsCustomFieldEditRoute";

// SettingsCustomFieldRoute sets the expression context on the provider
// so that the component on one route (CustomFieldPage) can set an expression
// and the component on another route (SettingsCustomFieldCreateRoute) can read it.
export const SettingsCustomFieldsRoute = (): React.ReactElement => {
  return (
    <>
      <CustomFieldProvider>
        <CustomFieldContext.Consumer>
          {({ expression, setExpression }) => (
            <Routes>
              <Route
                path=""
                element={<CustomFieldPage setExpression={setExpression} />}
              >
                <Route
                  path="create"
                  element={<CustomFieldCreateRoute expression={expression} />}
                />
                <Route path=":id/edit" element={<CustomFieldEditRoute />} />
              </Route>
              <Route
                path="*"
                element={<OrgAwareNavigate to="/404" replace />}
              />
            </Routes>
          )}
        </CustomFieldContext.Consumer>
      </CustomFieldProvider>
    </>
  );
};
