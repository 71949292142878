import { useDroppable } from "@dnd-kit/core";
import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { DragHandle } from "@incident-shared/settings";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
} from "@incident-ui";
import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import { FieldPath, useController, useFormContext } from "react-hook-form";
import { TooltipButton } from "src/components/legacy/status-page-config/shared/TooltipButton";
import { tcx } from "src/utils/tailwind-classes";

import { EditableComponent } from "./EditableComponent";
import {
  EditablePath,
  FormType,
  GroupStructureItem,
  StructureItem,
} from "./utils";

type GroupRowProps = {
  item: GroupStructureItem;
  index: number;
  editing: EditablePath;
  setEditing: (editable: EditablePath) => void;
  deleteGroup: () => void;
  canEdit: boolean;
  hideUptimeToggle: boolean;
  deleteComponent: (componentKey: string) => void;
};

export const GroupRow = ({
  item,
  index,
  editing,
  setEditing,
  hideUptimeToggle,
  deleteGroup,
  deleteComponent,
}: GroupRowProps): React.ReactElement => {
  const formMethods = useFormContext<FormType>();
  const { getValues, setValue } = formMethods;
  const contents = item.contents;

  const canShow = contents.length > 0;
  const allHidden = contents.every(({ hidden }) => hidden);
  const canAggregateUptime = contents.some(
    ({ hidden, displayUptime }) => !hidden && displayUptime,
  );

  useEffect(() => {
    // If we can't show this group, automatically hide it
    if (allHidden && !item.hidden) {
      setValue(`structureItems.${index}.hidden`, true, { shouldDirty: true });
    }

    // If we can't aggregate uptime, automatically disable that
    if (!canAggregateUptime && item.displayUptime) {
      setValue(`structureItems.${index}.displayUptime`, false, {
        shouldDirty: true,
      });
    }
  }, [
    allHidden,
    item.hidden,
    canAggregateUptime,
    item.displayUptime,
    index,
    setValue,
  ]);

  const hideHelp =
    contents.length === 0
      ? "Add a component to show this group"
      : item.hidden
      ? "Show group"
      : "Hide group";

  const uptimeHelp = canAggregateUptime
    ? item.displayUptime
      ? "Hide historical data"
      : "Show historical data"
    : canShow
    ? "Enable historical data for at least one visible component in this group to show it on the group overall"
    : contents.length > 0
    ? "All components in this group are hidden"
    : "Add a component to configure this group";

  const finishEditing = useCallback(() => {
    // We never want to keep empty strings around, since we use:
    // - null = "no description please"
    // - "" = "just clicked add description - autofocus that input"
    const path = `structureItems.${index}.description` as FieldPath<FormType>;
    const description = getValues(path);
    if (description === "") {
      setValue(path, null);
    }
    setEditing(null);
  }, [getValues, index, setEditing, setValue]);

  const checkForFinishEditing = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Escape" || e.code === "Enter") {
      // Don't submit the whole form
      e.stopPropagation();

      finishEditing();
    }
  };

  const isEditing = editing?.groupId === item.groupId || !item.name;
  const toggleGroupVisibility = () => {
    // Flip the group's current value
    const target = !item.hidden;
    // Get the component hiddens to match
    item.contents.forEach((component, componentIndex) => {
      if (component.hidden !== target) {
        setValue(
          `structureItems.${index}.contents.${componentIndex}.hidden`,
          target,
          { shouldDirty: true },
        );
      }
    });
    // And the group
    setValue(`structureItems.${index}.hidden`, target, { shouldDirty: true });
  };
  const toggleDisplayAggregatedUptime = () => {
    setValue(`structureItems.${index}.displayUptime`, !item.displayUptime, {
      shouldDirty: true,
    });
  };

  const startEditing = () => setEditing({ groupId: item.groupId });

  const {
    attributes,
    isDragging,
    listeners,

    setDraggableNodeRef,
    setDroppableNodeRef,
    setActivatorNodeRef,
    transition,
    transform,
  } = useSortable({
    id: item.id,
    data: {
      type: "container",
      containerId: item.id,
      children: item.contents,
    },
    animateLayoutChanges: defaultAnimateLayoutChanges,
  });

  // If the focus leaves the div that contains the editable inputs, end the edit
  const editingAreaRef = useRef<HTMLDivElement | null>();
  // If we're currently being edited and someone clicks outside the div that
  // contains the form inputs, end the editing.
  const detectClickOutside = useCallback(
    (ev: MouseEvent | TouchEvent) => {
      if (!editingAreaRef.current) return;
      // For some reason Typescript doesn't understand that ev.target is a Node
      if (editingAreaRef.current.contains(ev.target as Node | null)) return;

      finishEditing();
    },
    [finishEditing],
  );
  useEffect(() => {
    if (!isEditing) return undefined;

    window.addEventListener("mousedown", detectClickOutside);
    window.addEventListener("touchstart", detectClickOutside);
    return () => {
      window.removeEventListener("mousedown", detectClickOutside);
      window.removeEventListener("touchstart", detectClickOutside);
    };
  }, [detectClickOutside, isEditing]);

  return (
    <div
      className="w-full flex flex-col text-sm px-3"
      ref={setDraggableNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      <div
        ref={(val) => {
          setDroppableNodeRef(val);
          editingAreaRef.current = val;
        }}
        className={tcx("flex space-x-2 items-center", {
          "py-1": isEditing,
          "cursor-pointer py-3.5": !isEditing,
        })}
      >
        <button ref={setActivatorNodeRef} {...attributes} {...listeners}>
          <DragHandle />
        </button>
        <Icon
          id={IconEnum.FolderNoPadding}
          size={IconSize.Small}
          className="text-slate-400 shrink-0 self-center !mr-0.5"
        />
        {isEditing ? (
          <>
            <InputV2
              formMethods={formMethods}
              name={`structureItems.${index}.name`}
              onKeyDown={checkForFinishEditing}
              onFocus={startEditing}
              autoFocus
              className="w-1/2 invalid:border-red !ml-1"
              required={" "}
              highlightErrors
              placeholder="Set a title"
            />
            {item.description == null ? (
              <Button
                analyticsTrackingId={null}
                className="flex-1"
                theme={ButtonTheme.Naked}
                onClick={() =>
                  setValue(`structureItems.${index}.description`, "")
                }
              >
                Add description
              </Button>
            ) : (
              <InputV2
                formMethods={formMethods}
                onFocus={startEditing}
                name={`structureItems.${index}.description`}
                className="w-2/3"
                onKeyDown={checkForFinishEditing}
                highlightErrors
                /* We only want this to auto-focus if we're already editing this item (which means someone just clicked 'add description') */
                autoFocus={item.description === ""}
              />
            )}
          </>
        ) : (
          <>
            <div
              className="flex-none !ml-1 cursor-pointer"
              onClick={startEditing}
            >
              {item.name}
            </div>
            <div
              className="text-content-tertiary truncate flex-1 cursor-pointer"
              onClick={startEditing}
            >
              {item.description}
            </div>
          </>
        )}

        <div className="flex-none flex items-center space-x-1">
          {!hideUptimeToggle && (
            <TooltipButton
              title={uptimeHelp}
              onClick={() => toggleDisplayAggregatedUptime()}
              iconName={
                item.displayUptime ? IconEnum.Chart : IconEnum.ChartCrossedOut
              }
              disabled={!canAggregateUptime}
            />
          )}

          <TooltipButton
            title={hideHelp}
            onClick={() => toggleGroupVisibility()}
            iconName={item.hidden ? IconEnum.Hide : IconEnum.View}
            disabled={!canShow}
          />

          <TooltipButton
            title="Delete group"
            iconName={IconEnum.Delete2}
            onClick={() => deleteGroup()}
          />
        </div>
      </div>

      <GroupDropArea
        group={item}
        groupIndex={index}
        editing={editing}
        setEditing={setEditing}
        hideUptimeToggle={hideUptimeToggle}
        isGroupDragging={isDragging}
        deleteComponent={(componentKey: string) => {
          // Remove the component from this group
          setValue(
            `structureItems.${index}.contents`,
            item.contents.filter(
              (groupedComponent) =>
                groupedComponent.componentKey !== componentKey,
            ),
            { shouldDirty: true },
          );

          // Remove the component overall
          deleteComponent(componentKey);
        }}
      />
    </div>
  );
};

const GroupDropArea = ({
  group,
  groupIndex,
  isGroupDragging,
  editing,
  setEditing,
  deleteComponent,
  hideUptimeToggle,
}: {
  group: StructureItem & { groupId: string };
  groupIndex: number;
  isGroupDragging: boolean;
  editing: EditablePath;
  setEditing: (path: EditablePath) => void;
  deleteComponent: (componentKey: string) => void;
  hideUptimeToggle: boolean;
}): React.ReactElement => {
  const droppable = useDroppable({
    id: `${group.id}:contents`,
  });

  // Add a validation rule on the group having at least one component in it
  const { fieldState } = useController({
    name: `structureItems.${groupIndex}.contents`,
    rules: {
      validate: (contents) =>
        contents && contents.length > 0
          ? true
          : "You must add at least once component to a group",
    },
  });
  const isInvalid = fieldState.error !== undefined;

  return (
    <div
      className={tcx("ml-6 mb-3", {
        "!pb-8 !border-dashed border-slate-600": droppable.isOver,
      })}
      ref={droppable.setNodeRef}
      style={isGroupDragging ? { display: "none" } : {}}
    >
      {group.contents.length > 0 ? (
        <StackedList>
          {group.contents.map((item, itemIndex) => {
            return (
              <EditableComponent
                key={item.id}
                item={item}
                structurePath={`structureItems.${groupIndex}.contents.${itemIndex}`}
                editing={editing}
                setEditing={setEditing}
                hideUptimeToggle={hideUptimeToggle}
                displayUptime={item.displayUptime}
                hidden={item.hidden}
                deleteComponent={deleteComponent}
                group={group}
              />
            );
          })}
        </StackedList>
      ) : (
        <div
          className={tcx(
            "p-2 py-4 mb-2 mt-1 bg-surface-secondary text-center rounded-[6px]",
            isInvalid
              ? "border border-brand text-slate-700"
              : "text-content-tertiary",
          )}
        >
          {isInvalid
            ? "Groups cannot be empty. Drag a component into this group, or delete it."
            : "Drag components here to add to this group"}
        </div>
      )}
    </div>
  );
};
