import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  EscalationOpsgenieOptionsPriorityEnum,
  EscalationPagerDutyOptionsUrgencyEnum,
  EscalationProviderDisplay,
  EscalationProviderDisplayProviderEnum,
  EscalationProviderEnum,
  EscalationsCreateExternalRequestBodyProviderEnum,
  EscalationsListRequest,
  EscalationsListResponseBody,
  ExternalEscalationTarget,
  Incident,
  IncidentForm,
  IncidentFormEscalationElement,
  IncidentVisibilityEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorOption } from "react-hook-form/dist/types/errors";
import { FieldPath } from "react-hook-form/dist/types/path";
import { Form } from "src/components/@shared/forms";
import { IncidentFormFormTypeEnum as FormTypeEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useIncident } from "../legacy/incident/hooks";
import {
  asArray,
  ElementBindings,
  ElementBindingValue,
} from "./element-bindings";
import {
  NativeEscalateForm,
  NativeFormData,
} from "./providers/NativeEscalateForm";
import {
  OpsgenieEscalateForm,
  OpsgenieFormData,
} from "./providers/OpsgenieEscalateForm";
import {
  PagerDutyEscalateForm,
  PagerDutyFormData,
} from "./providers/PagerDutyEscalateForm";
import {
  SplunkEscalateForm,
  SplunkFormData,
} from "./providers/SplunkEscalateForm";

export type EscalateFormData = {
  idempotency_key: string;
} & (NativeFormData | PagerDutyFormData | SplunkFormData | OpsgenieFormData);

export const EscalationDrawer = ({
  incidentId,
  showDeclareIncident = true,
  onClose,
  onDeclareIncident,
  shouldWarnWhenDirty,
}: {
  incidentId?: string;
  showDeclareIncident?: boolean;
  onClose: () => void;
  onDeclareIncident?: (string) => void;
  shouldWarnWhenDirty: boolean;
}) => {
  const incident = useIncident(incidentId || null)?.incident;

  const { data: providerData } = useAPI("escalationsListProviders", undefined, {
    fallbackData: {
      providers: [],
    },
  });

  const providers = providerData.providers;

  const {
    data: { incident_forms: forms },
    error: formsError,
    isLoading: formsLoading,
  } = useAPI("incidentFormsListForms", undefined, {
    fallbackData: { incident_forms: [] },
  });

  const incidentTypeSpecificForm = forms.find(
    (f) =>
      f.form_type === FormTypeEnum.Escalate &&
      incident?.incident_type?.id &&
      f.incident_type_id === incident?.incident_type?.id,
  );

  const defaultForm = forms.find(
    (f) => f.form_type === FormTypeEnum.Escalate && !f.incident_type_id,
  );

  const escalateForm = incidentTypeSpecificForm ?? defaultForm;

  const {
    data: { incident_form_elements: elements },
    error: elementsError,
    isLoading: elementsLoading,
  } = useAPI(
    escalateForm ? "incidentFormsListEscalationElements" : null,
    { incidentFormId: escalateForm?.id || "" },
    { fallbackData: { incident_form_elements: [] } },
  );

  if (formsLoading || elementsLoading) {
    return (
      <Drawer onClose={onClose} width="medium">
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  if (elementsError || formsError || !escalateForm) {
    return <ErrorModal onClose={onClose} />;
  }

  return (
    <EscalateFormInner
      incidentId={incidentId}
      showDeclareIncident={showDeclareIncident}
      onClose={onClose}
      onDeclareIncident={onDeclareIncident}
      escalateForm={escalateForm}
      elements={elements}
      providers={providers}
      shouldWarnWhenDirty={shouldWarnWhenDirty}
    />
  );
};

const EscalateFormInner = ({
  incidentId,
  showDeclareIncident = true,
  onClose,
  onDeclareIncident,
  escalateForm,
  elements,
  providers,
  shouldWarnWhenDirty,
}: {
  incidentId?: string;
  showDeclareIncident?: boolean;
  onClose: () => void;
  onDeclareIncident?: (string) => void;
  escalateForm: IncidentForm;
  elements: IncidentFormEscalationElement[];
  providers: EscalationProviderDisplay[];
  shouldWarnWhenDirty: boolean;
}) => {
  const incident = useIncident(incidentId || null)?.incident;

  const { identity } = useIdentity();
  const now = new Date().getTime();

  const [idempotencyKey] = useState<string>(
    `manual_escalation:${identity?.user_id}:${now}`,
  ); // This is to keep the idempotencyKey from the first render

  const showToast = useToast();

  let defaultProvider = escalateForm.escalate_config
    ?.default_escalation_provider as EscalationProviderEnum | undefined;

  if (!defaultProvider) {
    if (providers.length > 0) {
      // Default to incident.io's native escalation provider if no default is set, and
      // it is available. Otherwise, take the first one.
      defaultProvider = providers.find(
        (p) => p.provider === EscalationProviderDisplayProviderEnum.Native,
      )
        ? EscalationProviderEnum.Native
        : (providers[0].provider as unknown as EscalationProviderEnum);
    } else {
      // If no providers were passed through, we'll just show the native one.
      defaultProvider = EscalationProviderEnum.Native;
    }
  }

  const defaultElementBindings: ElementBindings = {};

  elements.forEach((element) => {
    if (element.default_value?.value?.value) {
      const isPriority =
        element.available_element.element_type ===
        AvailableIncidentFormEscalationElementElementTypeEnum.Priority;
      const vals: Partial<ElementBindingValue> = {
        array_value: isPriority
          ? undefined
          : [element.default_value.value.value],
        scalar_value: isPriority
          ? element.default_value.value.value
          : undefined,
      };
      defaultElementBindings[element.id] = vals as ElementBindingValue;
    }
  });

  const formMethods = useForm<EscalateFormData>({
    defaultValues: {
      title: incident?.name || "",
      idempotency_key: idempotencyKey,
      element_bindings: defaultElementBindings,
      priority: EscalationOpsgenieOptionsPriorityEnum.P1,
      incident_id: incidentId,
      declare_incident: showDeclareIncident && incidentId == null,
      provider: defaultProvider,
      targets: [],
      user_targets: [],
      escalation_policy_targets: [],
    },
  });
  const provider = formMethods.watch("provider");

  // Clear errors when the provider changes
  useEffect(() => {
    formMethods.clearErrors();
  }, [provider, formMethods]);

  const { trigger, isMutating: isSubmitting } = useAPIMutation<
    EscalateFormData,
    "escalationsList",
    (req: EscalationsListRequest) => Promise<EscalationsListResponseBody>,
    EscalationsListRequest,
    EscalationsListResponseBody
  >(
    "escalationsList",
    {
      pageSize: 50,
    },
    async (apiClient, formData) => {
      // If this is an external escalation provider, we need to submit external
      let targets: ExternalEscalationTarget[] = [];
      switch (formData.provider) {
        case EscalationProviderEnum.Pagerduty:
          targets = formData.targets;
          if (formData.pagerduty_service_id) {
            targets.push({
              type: "Service",
              id: formData.pagerduty_service_id,
            });
          }
          await apiClient.escalationsCreateExternal({
            createExternalRequestBody: {
              title: formData.title,
              idempotency_key: formData.idempotency_key,
              incident_id: incidentId,
              provider:
                provider as unknown as EscalationsCreateExternalRequestBodyProviderEnum,
              targets,
              pager_duty_options: {
                urgency: EscalationPagerDutyOptionsUrgencyEnum.High,
              },
            },
          });
          return;
        case EscalationProviderEnum.Opsgenie:
          targets = formData.targets;
          if (formData.opsgenie_service_id) {
            targets.push({
              type: "Service",
              id: formData.opsgenie_service_id,
            });
          }
          await apiClient.escalationsCreateExternal({
            createExternalRequestBody: {
              title: formData.title,
              idempotency_key: formData.idempotency_key,
              incident_id: incidentId,
              provider:
                provider as unknown as EscalationsCreateExternalRequestBodyProviderEnum,
              targets,
              opsgenie_options: {
                priority: formData.priority,
              },
            },
          });
          return;
        case EscalationProviderEnum.SplunkOnCall:
          await apiClient.escalationsCreateExternal({
            createExternalRequestBody: {
              title: formData.title,
              idempotency_key: formData.idempotency_key,
              incident_id: incidentId,
              provider:
                EscalationsCreateExternalRequestBodyProviderEnum.SplunkOnCall,
              targets: [
                ...formData.user_targets.map((id) => ({
                  type: "User",
                  id,
                })),
                ...formData.escalation_policy_targets.map((id) => ({
                  type: "EscalationPolicy",
                  id,
                })),
              ],
            },
          });
          return;
        case EscalationProviderEnum.Native:
          const result = await apiClient.incidentFormsSubmitEscalateForm({
            id: escalateForm.id,
            submitEscalateFormRequestBody: {
              ...formData,
              incident_id: incidentId,
              element_bindings: Object.fromEntries(
                Object.entries(formData.element_bindings)
                  .map(([key, value]) => [key, asArray(value)])
                  .filter(([, value]) => value.length > 1 || value[0] != null),
              ),
            },
          });

          if (
            formData.declare_incident &&
            result &&
            result.escalations &&
            result.escalations.length > 0 &&
            onDeclareIncident
          ) {
            onDeclareIncident(result.escalations[0].id);
          }
          break;
        default:
          return;
      }
    },
    {
      onSuccess: () => {
        showToast({
          title: "Escalation created",
          theme: ToastTheme.Success,
        });
        onClose();
      },
      onError: () => {
        showToast({
          title: "Failed to create escalation",
          description: "Please try again.",
          theme: ToastTheme.Error,
        });
      },
      setError: (
        name: FieldPath<EscalateFormData> | `root.${string}` | "root",
        error: ErrorOption,
        options?: {
          shouldFocus: boolean;
        },
      ) => {
        // If this is PagerDuty, and the error is on the targets, it's possible
        // we got an error for not selecting targets _or_ for not selecting a service.
        // For that case, we'll have to look at the error message to determine which
        // field to highlight.
        if (
          provider === EscalationProviderEnum.Pagerduty &&
          name === "targets" &&
          error.message &&
          (error.message.includes("service") ||
            error.message.includes("Service"))
        ) {
          formMethods.setError("pagerduty_service_id", {
            type: "required",
            message: "Please select a service",
          });
          return;
        }

        formMethods.setError(name, error, options);
      },
    },
  );

  const handleSubmit = (data: EscalateFormData) => {
    if ((data.title?.trim() ?? "") === "") {
      formMethods.setError("title", {
        type: "required",
        message: "Please enter a title",
      });
      return;
    }

    trigger(data);
  };

  // Figure out if we should submit the form
  const elementBindings = formMethods.watch("element_bindings");
  const title = formMethods.watch("title");
  const boundValues = Object.values(elementBindings).reduce(
    (acc, eb) => acc + (eb.array_value?.length ?? 0),
    0,
  );

  const canSubmit =
    !EscalationProviderEnum.Native || (boundValues > 0 && title !== "");

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          color={ColorPaletteEnum.Slate}
          title="Create escalation"
          onClose={onClose}
          titleAccessory={
            <IconBadge
              icon={IconEnum.Escalate}
              size={IconSize.Small}
              color={ColorPaletteEnum.Slate}
              className="bg-slate-700 text-white"
            />
          }
          closeIcon={IconEnum.Close}
          compact
        />
        <Form.Root
          id="escalate-form"
          onSubmit={handleSubmit}
          formMethods={formMethods}
          outerClassName="grow"
          loadingWrapperClassName="h-full"
          innerClassName="flex flex-col h-full px-6 pt-4"
          warnWhenDirty={shouldWarnWhenDirty}
        >
          {incident?.visibility === IncidentVisibilityEnum.Private && (
            <Callout theme={CalloutTheme.Warning} className="mb-6">
              <span>
                This is a <span className="text-sm-bold">private incident</span>
                . You&apos;ll need to{" "}
                <span className="text-sm-bold">invite any new responders</span>{" "}
                to this channel so they can access the incident.
              </span>
            </Callout>
          )}
          {/* render a grouped radio to set provider, if the config says the user is allowed to choose */}
          {escalateForm.escalate_config?.default_escalation_provider ===
            undefined &&
            providers.length > 1 && (
              <RadioButtonGroupV2
                boxed
                options={providers.map((p) => ({
                  label: p.name,
                  value: p.provider as unknown as EscalationProviderEnum,
                }))}
                name="provider"
                label={
                  <span className="text-base-bold">
                    How would you like to escalate?
                  </span>
                }
                srLabel="How would you like to escalate?"
                helptext={
                  <p className="text-xs-med text-content-primary">
                    You have multiple integrations configured to get hold of
                    people. How would you like to escalate here?
                  </p>
                }
                formMethods={formMethods}
              />
            )}
          <AnimatePresence>
            <EscalationForm
              elements={elements}
              provider={provider}
              incident={incident}
            />
          </AnimatePresence>
        </Form.Root>
        <DrawerFooter className="flex gap-2 justify-end">
          <div className="flex-grow"></div>
          <Button onClick={() => onClose()} analyticsTrackingId={null}>
            Cancel
          </Button>
          <GatedButton
            disabled={!canSubmit || isSubmitting}
            form="escalate"
            requiredScope={ScopeNameEnum.EscalationsCreate}
            onClick={() => handleSubmit(formMethods.getValues())}
            theme={ButtonTheme.Primary}
            loading={isSubmitting}
            analyticsTrackingId="escalate-form-submit"
          >
            Escalate
          </GatedButton>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const EscalationForm = ({
  elements,
  provider,
  incident,
}: {
  elements: IncidentFormEscalationElement[];
  provider: EscalationProviderEnum;
  incident?: Incident | null;
}) => {
  switch (provider) {
    case EscalationProviderEnum.Native:
      return <NativeEscalateForm elements={elements} incident={incident} />;
    case EscalationProviderEnum.Pagerduty:
      return <PagerDutyEscalateForm />;
    case EscalationProviderEnum.SplunkOnCall:
      return <SplunkEscalateForm />;
    case EscalationProviderEnum.Opsgenie:
      return <OpsgenieEscalateForm />;
    default:
      assertUnreachable(provider);
      return null;
  }
};
