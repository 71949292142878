/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleReportOverridePayload
 */
export interface ScheduleReportOverridePayload {
    /**
     * End of the override
     * @type {string}
     * @memberof ScheduleReportOverridePayload
     */
    end_at: string;
    /**
     * Unique ID for the override
     * @type {string}
     * @memberof ScheduleReportOverridePayload
     */
    id?: string;
    /**
     * Name of the override
     * @type {string}
     * @memberof ScheduleReportOverridePayload
     */
    name: string;
    /**
     * Rate of pay per hour when this override applies, in lowest denomination of specified currency
     * @type {number}
     * @memberof ScheduleReportOverridePayload
     */
    rate_cents: number;
    /**
     * Start of the override
     * @type {string}
     * @memberof ScheduleReportOverridePayload
     */
    start_at: string;
}

export function ScheduleReportOverridePayloadFromJSON(json: any): ScheduleReportOverridePayload {
    return ScheduleReportOverridePayloadFromJSONTyped(json, false);
}

export function ScheduleReportOverridePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleReportOverridePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': json['end_at'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'rate_cents': json['rate_cents'],
        'start_at': json['start_at'],
    };
}

export function ScheduleReportOverridePayloadToJSON(value?: ScheduleReportOverridePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': value.end_at,
        'id': value.id,
        'name': value.name,
        'rate_cents': value.rate_cents,
        'start_at': value.start_at,
    };
}

