import { EngineScope, Resource } from "@incident-io/api";
import { getVariableScope, MaybeRef } from "@incident-shared/engine";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  SortableList,
} from "@incident-ui";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import React from "react";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { lookupInScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { WorkflowStepListItem } from "../common/types";
import { useLoopScope } from "../common/utils";
import { WorkflowsLoopVariableSelector } from "./WorkflowsLoopVariableSelector";
import { WorkflowStepCard } from "./WorkflowStepCard";

type WorkflowLoopingStepCardProps = {
  forEachValue: string | undefined;
  resources: Resource[];
  scope: EngineScope;
  steps: WorkflowStepListItem[];
  // Required to make the card draggable.
  innerRef?: React.Ref<HTMLDivElement>;
  draggableProps: DraggableProvidedDraggableProps | null | undefined;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onClickAddStep: () => void;
  onEditStep: (stepIndex: number) => void;
  onRemoveStep: (stepIndex: number) => void;
  onRemoveStepGroup: () => void;
  onReorderStepsWithinGroup: (steps: WorkflowStepListItem[]) => void;
  onSetForEach: (forEach: string) => void;
};

// WorkflowLoopingStepCard renders a card that represents a looping
// step in a workflow.
export const WorkflowLoopingStepCard = (
  props: WorkflowLoopingStepCardProps,
): React.ReactElement => {
  const {
    resources,
    scope,
    steps,
    innerRef,
    draggableProps,
    dragHandleProps,
    forEachValue,
    onClickAddStep,
    onEditStep,
    onRemoveStep,
    onRemoveStepGroup,
    onReorderStepsWithinGroup,
    onSetForEach,
  } = props;

  const hasSteps = steps.length > 0;
  const scopeVar = forEachValue
    ? lookupInScope(scope, forEachValue)
    : undefined;
  const isForEachValueSet = forEachValue !== undefined;
  const isSelectable = (entry: MaybeRef) => {
    return entry.array;
  };

  const loopScope = useLoopScope(scopeVar?.key || "", scope);

  const variableScope = getVariableScope(loopScope, resources);

  return (
    <FlowCards.Card
      ref={innerRef}
      {...draggableProps}
      iconNode={
        <div {...dragHandleProps}>
          <Icon id={IconEnum.Draggable} size={IconSize.Small} />
        </div>
      }
      title={
        <div className="flex gap-2 items-center grow min-w-0">
          <span className="min-w-[116px]">For each value of</span>
          <WorkflowsLoopVariableSelector
            label={scopeVar?.label || "Select a variable"}
            scope={scope}
            onSelectReference={(val) => onSetForEach(val.key)}
            isSelectable={isSelectable}
            disabled={hasSteps}
            referenceKey={scopeVar?.key || ""}
          />
        </div>
      }
      accessory={
        <Button
          analyticsTrackingId="workflowsv2-looping-step-delete"
          onClick={onRemoveStepGroup}
          theme={ButtonTheme.Naked}
          icon={IconEnum.Delete}
          // We need some negative margin to make the icon align with the
          // padding of the card.
          className="-mr-[4px]"
          iconProps={{
            size: IconSize.Medium,
          }}
          title="Delete"
        />
      }
    >
      {/* Looping step body section */}
      <SortableList
        items={steps}
        containerProps={{
          className: "flex flex-col items-center w-full",
        }}
        sortOrder="asc"
        // At this level we want to reorder the steps within a group.
        updateItemRanks={onReorderStepsWithinGroup}
        droppableID="groupsteps-steps"
        renderItem={(renderItemProps) => {
          const {
            item: step,
            index: stepIndex,
            draggableProvidedProps,
          } = renderItemProps;

          // Take common draggable props, which are needed to make
          // the child component draggable.
          const commonDraggableProps = {
            innerRef: draggableProvidedProps.innerRef,
            draggableProps: draggableProvidedProps.draggableProps,
            dragHandleProps: draggableProvidedProps.dragHandleProps,
          };

          const isLastStep = stepIndex === steps.length - 1;
          const isFirstStep = stepIndex === 0;

          return (
            <WorkflowStepCard
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
              variableScope={variableScope}
              className={tcx(
                "max-w-full w-full border-b-0",
                "rounded-none",
                isFirstStep && "rounded-t-2",
                isLastStep && "rounded-b-2 border-b",
              )}
              step={step}
              {...commonDraggableProps}
              stepNumber={stepIndex + 1}
              onEditStep={() => {
                onEditStep(stepIndex);
              }}
              onRemoveStep={() => {
                onRemoveStep(stepIndex);
              }}
            />
          );
        }}
      />

      <Button
        theme={ButtonTheme.Naked}
        icon={IconEnum.Add}
        onClick={() => {
          onClickAddStep();
        }}
        className={tcx("mx-auto", {
          "mt-1": hasSteps,
          "opacity-50": !isForEachValueSet,
        })}
        disabled={!isForEachValueSet}
        analyticsTrackingId={"workflows-v2-add-step-within-loop"}
      >
        Add step within loop
      </Button>
    </FlowCards.Card>
  );
};
