/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlamelessImportOptions
 */
export interface BlamelessImportOptions {
    /**
     * API key for your Blameless account
     * @type {string}
     * @memberof BlamelessImportOptions
     */
    api_key: string;
    /**
     * The subdomain of your Blameless account
     * @type {string}
     * @memberof BlamelessImportOptions
     */
    subdomain: string;
}

export function BlamelessImportOptionsFromJSON(json: any): BlamelessImportOptions {
    return BlamelessImportOptionsFromJSONTyped(json, false);
}

export function BlamelessImportOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlamelessImportOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'api_key': json['api_key'],
        'subdomain': json['subdomain'],
    };
}

export function BlamelessImportOptionsToJSON(value?: BlamelessImportOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_key': value.api_key,
        'subdomain': value.subdomain,
    };
}

