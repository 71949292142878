import { StatusPageIncident } from "@incident-io/api";
import { Button, ButtonTheme, Heading, IconEnum, IconSize } from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { IncidentLinkCreateModal } from "./IncidentLinkCreateModal";

export const LinkedInternalIncidents = ({
  incident,
}: {
  incident: StatusPageIncident;
}) => {
  const [showLinkIncidentModal, setShowLinkIncidentModal] = useState(false);
  return (
    <>
      {showLinkIncidentModal ? (
        <IncidentLinkCreateModal
          incident={incident}
          onClose={() => setShowLinkIncidentModal(false)}
        />
      ) : null}
      <div className="space-y-2">
        <Heading level={3} size="small" className="font-medium">
          Linked incidents{" "}
        </Heading>
        {incident.linked_response_incidents.map((inc) => (
          <div key={inc.id}>
            <Button
              analyticsTrackingId="status-page-related-incident-link"
              analyticsTrackingMetadata={{ incident_id: inc.id }}
              href={`/incidents/${inc.id}`}
              theme={ButtonTheme.Naked}
              icon={IconEnum.Incident}
              iconProps={{ size: IconSize.Medium }}
              className={tcx(
                "flex items-center truncate",
                "rounded-full border !border-stroke",
                "pl-2 pr-3 py-1.5",
                "hover:shadow",
              )}
              title={`${inc.reference}: ${inc.name}`}
              openInNewTab
            >
              <span className="max-w-[200px] truncate transition">
                {inc.reference}: {inc.name}
              </span>
            </Button>
          </div>
        ))}

        <Button
          analyticsTrackingId="status-page-related-incident-link-new"
          onClick={() => setShowLinkIncidentModal(true)}
          theme={ButtonTheme.Naked}
          icon={IconEnum.Link}
          iconProps={{ size: IconSize.Medium }}
        >
          Link incident
        </Button>
      </div>
    </>
  );
};
