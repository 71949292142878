/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface IssueTrackersLinearUpdateIssueTemplateV2RequestBody
 */
export interface IssueTrackersLinearUpdateIssueTemplateV2RequestBody {
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IssueTrackersLinearUpdateIssueTemplateV2RequestBody
     */
    assignee_id?: EngineParamBindingPayload;
    /**
     * If this issue template should be enabled
     * @type {boolean}
     * @memberof IssueTrackersLinearUpdateIssueTemplateV2RequestBody
     */
    enabled: boolean;
    /**
     * The expressions to use in this config
     * @type {Array<ExpressionPayload>}
     * @memberof IssueTrackersLinearUpdateIssueTemplateV2RequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IssueTrackersLinearUpdateIssueTemplateV2RequestBody
     */
    labels?: EngineParamBindingPayload;
    /**
     * The name of the issue template
     * @type {string}
     * @memberof IssueTrackersLinearUpdateIssueTemplateV2RequestBody
     */
    name: string;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IssueTrackersLinearUpdateIssueTemplateV2RequestBody
     */
    project_id?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof IssueTrackersLinearUpdateIssueTemplateV2RequestBody
     */
    team_id?: EngineParamBindingPayload;
}

export function IssueTrackersLinearUpdateIssueTemplateV2RequestBodyFromJSON(json: any): IssueTrackersLinearUpdateIssueTemplateV2RequestBody {
    return IssueTrackersLinearUpdateIssueTemplateV2RequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersLinearUpdateIssueTemplateV2RequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersLinearUpdateIssueTemplateV2RequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : EngineParamBindingPayloadFromJSON(json['assignee_id']),
        'enabled': json['enabled'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'labels': !exists(json, 'labels') ? undefined : EngineParamBindingPayloadFromJSON(json['labels']),
        'name': json['name'],
        'project_id': !exists(json, 'project_id') ? undefined : EngineParamBindingPayloadFromJSON(json['project_id']),
        'team_id': !exists(json, 'team_id') ? undefined : EngineParamBindingPayloadFromJSON(json['team_id']),
    };
}

export function IssueTrackersLinearUpdateIssueTemplateV2RequestBodyToJSON(value?: IssueTrackersLinearUpdateIssueTemplateV2RequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': EngineParamBindingPayloadToJSON(value.assignee_id),
        'enabled': value.enabled,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'labels': EngineParamBindingPayloadToJSON(value.labels),
        'name': value.name,
        'project_id': EngineParamBindingPayloadToJSON(value.project_id),
        'team_id': EngineParamBindingPayloadToJSON(value.team_id),
    };
}

