import { createContext, useContext } from "react";

// SummaryContext allows other children of the IncidentBody to trigger opening the summary editor
export const SummaryContext = createContext<{
  isEditingSummary: boolean;
  setIsEditingSummary: (a: boolean) => void;
} | null>(null);

export const useSummaryContext = (): {
  isEditingSummary: boolean;
  setIsEditingSummary: (a: boolean) => void;
} => {
  const context = useContext(SummaryContext);
  if (!context) {
    throw new Error(
      `useSummaryContext must be used as a child of a SummaryContext provider`,
    );
  }

  return context;
};
