/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HydratedSlackChannel,
    HydratedSlackChannelFromJSON,
    HydratedSlackChannelFromJSONTyped,
    HydratedSlackChannelToJSON,
} from './HydratedSlackChannel';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemShareTemplate
 */
export interface PostmortemShareTemplate {
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemShareTemplate
     */
    content: TextNode;
    /**
     * When the postmortem share template was created
     * @type {Date}
     * @memberof PostmortemShareTemplate
     */
    created_at: Date;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemShareTemplate
     */
    description?: TextNode;
    /**
     * Unique ID for the template
     * @type {string}
     * @memberof PostmortemShareTemplate
     */
    id: string;
    /**
     * Whether this template is the default postmortem share template for this org, if sent as true will override the current default
     * @type {boolean}
     * @memberof PostmortemShareTemplate
     */
    is_default: boolean;
    /**
     * Name of the postmortem share template
     * @type {string}
     * @memberof PostmortemShareTemplate
     */
    name: string;
    /**
     * Whether to add the incident channel into the slack channels automatically
     * @type {boolean}
     * @memberof PostmortemShareTemplate
     */
    share_to_incident_channel: boolean;
    /**
     * List of the slack channels in which we will share the postmortem
     * @type {Array<HydratedSlackChannel>}
     * @memberof PostmortemShareTemplate
     */
    slack_channels: Array<HydratedSlackChannel>;
    /**
     * When the postmortem share template was last updated
     * @type {Date}
     * @memberof PostmortemShareTemplate
     */
    updated_at: Date;
}

export function PostmortemShareTemplateFromJSON(json: any): PostmortemShareTemplate {
    return PostmortemShareTemplateFromJSONTyped(json, false);
}

export function PostmortemShareTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemShareTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': TextNodeFromJSON(json['content']),
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'id': json['id'],
        'is_default': json['is_default'],
        'name': json['name'],
        'share_to_incident_channel': json['share_to_incident_channel'],
        'slack_channels': ((json['slack_channels'] as Array<any>).map(HydratedSlackChannelFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function PostmortemShareTemplateToJSON(value?: PostmortemShareTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': TextNodeToJSON(value.content),
        'created_at': (value.created_at.toISOString()),
        'description': TextNodeToJSON(value.description),
        'id': value.id,
        'is_default': value.is_default,
        'name': value.name,
        'share_to_incident_channel': value.share_to_incident_channel,
        'slack_channels': ((value.slack_channels as Array<any>).map(HydratedSlackChannelToJSON)),
        'updated_at': (value.updated_at.toISOString()),
    };
}

