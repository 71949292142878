/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnnouncementRulesCreateRequestBody,
    AnnouncementRulesCreateRequestBodyFromJSON,
    AnnouncementRulesCreateRequestBodyToJSON,
    AnnouncementRulesCreateResponseBody,
    AnnouncementRulesCreateResponseBodyFromJSON,
    AnnouncementRulesCreateResponseBodyToJSON,
    AnnouncementRulesListResponseBody,
    AnnouncementRulesListResponseBodyFromJSON,
    AnnouncementRulesListResponseBodyToJSON,
    AnnouncementRulesShowResponseBody,
    AnnouncementRulesShowResponseBodyFromJSON,
    AnnouncementRulesShowResponseBodyToJSON,
    AnnouncementRulesUpdateRequestBody,
    AnnouncementRulesUpdateRequestBodyFromJSON,
    AnnouncementRulesUpdateRequestBodyToJSON,
    AnnouncementRulesUpdateResponseBody,
    AnnouncementRulesUpdateResponseBodyFromJSON,
    AnnouncementRulesUpdateResponseBodyToJSON,
} from '../models';

export interface AnnouncementRulesCreateRequest {
    createRequestBody: AnnouncementRulesCreateRequestBody;
}

export interface AnnouncementRulesDestroyRequest {
    id: string;
}

export interface AnnouncementRulesShowRequest {
    id: string;
}

export interface AnnouncementRulesUpdateRequest {
    id: string;
    updateRequestBody: AnnouncementRulesUpdateRequestBody;
}

/**
 * 
 */
export class AnnouncementRulesApi extends runtime.BaseAPI {

    /**
     * Create an announcement rule
     * Create Announcement Rules
     */
    async announcementRulesCreateRaw(requestParameters: AnnouncementRulesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementRulesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling announcementRulesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementRulesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementRulesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an announcement rule
     * Create Announcement Rules
     */
    async announcementRulesCreate(requestParameters: AnnouncementRulesCreateRequest, initOverrides?: RequestInit): Promise<AnnouncementRulesCreateResponseBody> {
        const response = await this.announcementRulesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an announcement rule
     * Destroy Announcement Rules
     */
    async announcementRulesDestroyRaw(requestParameters: AnnouncementRulesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling announcementRulesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an announcement rule
     * Destroy Announcement Rules
     */
    async announcementRulesDestroy(requestParameters: AnnouncementRulesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.announcementRulesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List all announcement rules for this org
     * List Announcement Rules
     */
    async announcementRulesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementRulesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementRulesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all announcement rules for this org
     * List Announcement Rules
     */
    async announcementRulesList(initOverrides?: RequestInit): Promise<AnnouncementRulesListResponseBody> {
        const response = await this.announcementRulesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show an announcement rule
     * Show Announcement Rules
     */
    async announcementRulesShowRaw(requestParameters: AnnouncementRulesShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementRulesShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling announcementRulesShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/announcement_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementRulesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an announcement rule
     * Show Announcement Rules
     */
    async announcementRulesShow(requestParameters: AnnouncementRulesShowRequest, initOverrides?: RequestInit): Promise<AnnouncementRulesShowResponseBody> {
        const response = await this.announcementRulesShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an announcement rule
     * Update Announcement Rules
     */
    async announcementRulesUpdateRaw(requestParameters: AnnouncementRulesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementRulesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling announcementRulesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling announcementRulesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/announcement_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AnnouncementRulesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementRulesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an announcement rule
     * Update Announcement Rules
     */
    async announcementRulesUpdate(requestParameters: AnnouncementRulesUpdateRequest, initOverrides?: RequestInit): Promise<AnnouncementRulesUpdateResponseBody> {
        const response = await this.announcementRulesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
