/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogRelationAttribute,
    CatalogRelationAttributeFromJSON,
    CatalogRelationAttributeFromJSONTyped,
    CatalogRelationAttributeToJSON,
} from './CatalogRelationAttribute';
import {
    CatalogType,
    CatalogTypeFromJSON,
    CatalogTypeFromJSONTyped,
    CatalogTypeToJSON,
} from './CatalogType';

/**
 * 
 * @export
 * @interface CatalogRelation
 */
export interface CatalogRelation {
    /**
     * The attributes through which entries point at the target
     * @type {Array<CatalogRelationAttribute>}
     * @memberof CatalogRelation
     */
    attributes: Array<CatalogRelationAttribute>;
    /**
     * 
     * @type {CatalogType}
     * @memberof CatalogRelation
     */
    catalog_type: CatalogType;
}

export function CatalogRelationFromJSON(json: any): CatalogRelation {
    return CatalogRelationFromJSONTyped(json, false);
}

export function CatalogRelationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogRelation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(CatalogRelationAttributeFromJSON)),
        'catalog_type': CatalogTypeFromJSON(json['catalog_type']),
    };
}

export function CatalogRelationToJSON(value?: CatalogRelation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(CatalogRelationAttributeToJSON)),
        'catalog_type': CatalogTypeToJSON(value.catalog_type),
    };
}

