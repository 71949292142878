import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Loader,
} from "@incident-ui";
import { IntegrationSettingsProviderEnum as IntegrationProvider } from "src/contexts/ClientContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";

const atlassianAppSpecificWarnings = {
  [IntegrationProvider.Jira]: (
    <>
      <p>You&apos;ll no longer be able to export follow-ups to Jira.</p>
      <p>
        Previously exported follow-ups won&apos;t be kept in sync with Jira.
      </p>
    </>
  ),
  [IntegrationProvider.Confluence]: (
    <>
      <p>You&apos;ll no longer be able to export post-mortems.</p>
    </>
  ),
};

export const DisconnectAtlassianWarning = ({
  provider,
}: {
  provider: IntegrationProvider;
}): React.ReactElement => {
  const { integrations } = useIntegrations();

  // Check if we have any Atlassian Connect app config
  const {
    data: config,
    error,
    isLoading,
  } = useAPI(
    provider === IntegrationProvider.Jira
      ? "integrationsJiraCloudGetConfig"
      : "integrationsConfluenceGetConfig",
    undefined,
  );

  if (!integrations || isLoading) {
    return <Loader />;
  }
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  // If we're using connect app installations, show the normal warning
  if ((config?.config.connect_app_installations.length ?? 0) > 0) {
    return <p className="text-sm">{atlassianAppSpecificWarnings[provider]}</p>;
  }

  // Get all other Atlassian integrations that are installed
  const otherInstallations = integrations
    .filter(
      (i) =>
        i.provider !== provider &&
        (i.provider === "jira" || i.provider === "confluence"),
    )
    .filter((i) => i.installed);

  return (
    <div className="flex flex-col gap-4">
      {otherInstallations.length > 0 && (
        <Callout theme={CalloutTheme.Info}>
          As you are using the deprecated OAuth connection method this will also
          disconnect{" "}
          <span className="text-sm-bold">
            {otherInstallations[0].provider_name}
          </span>
          . We recommend switching to our Atlassian Connect App.
        </Callout>
      )}
      <div className="text-sm">{atlassianAppSpecificWarnings[provider]}</div>
      {otherInstallations.length > 0 && (
        <div>
          {atlassianAppSpecificWarnings[otherInstallations[0].provider]}
        </div>
      )}
    </div>
  );
};
