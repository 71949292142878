/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageAffectedComponentPayload,
    StatusPageAffectedComponentPayloadFromJSON,
    StatusPageAffectedComponentPayloadFromJSONTyped,
    StatusPageAffectedComponentPayloadToJSON,
} from './StatusPageAffectedComponentPayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageCreateIncidentRequestBody
 */
export interface StatusPageCreateIncidentRequestBody {
    /**
     * Whether or not maintenance window statuses are automatically updated
     * @type {boolean}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    automate_maintenance_status: boolean;
    /**
     * The updated statuses of affected components
     * @type {Array<StatusPageAffectedComponentPayload>}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    component_statuses: Array<StatusPageAffectedComponentPayload>;
    /**
     * The ID to the response incident this update was created from
     * @type {string}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    created_from_response_incident_id?: string;
    /**
     * The ID of the template that was applied when sending this update
     * @type {string}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    created_from_template_id?: string;
    /**
     * A unique key to de-duplicate incidents
     * @type {string}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    idempotency_key?: string;
    /**
     * The IDs of subpages that this incident should be linked to. When `null` or empty, the sub-page will be inferred from the components.
     * @type {Array<string>}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    manual_sub_page_ids?: Array<string>;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    message: TextNode;
    /**
     * A title for the incident
     * @type {string}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    name: string;
    /**
     * Current status for this incident
     * @type {string}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    status: StatusPageCreateIncidentRequestBodyStatusEnum;
    /**
     * The ID of the status page this incident is displayed on
     * @type {string}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    status_page_id: string;
    /**
     * If true, don't notify subscribers of this update
     * @type {boolean}
     * @memberof StatusPageCreateIncidentRequestBody
     */
    suppress_notifications: boolean;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageCreateIncidentRequestBodyStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function StatusPageCreateIncidentRequestBodyFromJSON(json: any): StatusPageCreateIncidentRequestBody {
    return StatusPageCreateIncidentRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateIncidentRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateIncidentRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'automate_maintenance_status': json['automate_maintenance_status'],
        'component_statuses': ((json['component_statuses'] as Array<any>).map(StatusPageAffectedComponentPayloadFromJSON)),
        'created_from_response_incident_id': !exists(json, 'created_from_response_incident_id') ? undefined : json['created_from_response_incident_id'],
        'created_from_template_id': !exists(json, 'created_from_template_id') ? undefined : json['created_from_template_id'],
        'idempotency_key': !exists(json, 'idempotency_key') ? undefined : json['idempotency_key'],
        'manual_sub_page_ids': !exists(json, 'manual_sub_page_ids') ? undefined : json['manual_sub_page_ids'],
        'message': TextNodeFromJSON(json['message']),
        'name': json['name'],
        'status': json['status'],
        'status_page_id': json['status_page_id'],
        'suppress_notifications': json['suppress_notifications'],
    };
}

export function StatusPageCreateIncidentRequestBodyToJSON(value?: StatusPageCreateIncidentRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automate_maintenance_status': value.automate_maintenance_status,
        'component_statuses': ((value.component_statuses as Array<any>).map(StatusPageAffectedComponentPayloadToJSON)),
        'created_from_response_incident_id': value.created_from_response_incident_id,
        'created_from_template_id': value.created_from_template_id,
        'idempotency_key': value.idempotency_key,
        'manual_sub_page_ids': value.manual_sub_page_ids,
        'message': TextNodeToJSON(value.message),
        'name': value.name,
        'status': value.status,
        'status_page_id': value.status_page_id,
        'suppress_notifications': value.suppress_notifications,
    };
}

