import { tcx } from "src/utils/tailwind-classes";

export const Difference = ({
  prev,
  next,
}: {
  prev: string | undefined;
  next: string;
}): React.ReactElement => {
  if (prev === next || prev === undefined) {
    return <>{next}</>;
  }

  return (
    <>
      <span className="sr-only">Previous value</span>
      <del className={tcx("line-through")}>{prev}</del>
      <span aria-hidden className="px-2">
        &rarr;
      </span>
      <span className="sr-only">New value</span>
      <span>{next}</span>
    </>
  );
};
