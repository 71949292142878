import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { IconEnum } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";
import { useProductAccess } from "src/hooks/useProductAccess";

import { ResponseGetStartedPage } from "../response-preview/ResponseGetStartedPage";
import { FollowUpsPage } from "./follow-ups/FollowUpsPage";
import { PostIncidentPage } from "./post-incident-flow/PostIncidentPage";
import { PostIncidentPageWrapper } from "./PostIncidentPageWrapper";

export const PostIncidentRoute = (): React.ReactElement => {
  const { hasResponse } = useProductAccess();

  return (
    <Routes>
      {!hasResponse ? (
        <Route
          path=""
          element={
            <ResponseGetStartedPage
              title="Post-incident"
              icon={IconEnum.Action}
            />
          }
        />
      ) : (
        <Route element={<PostIncidentPageWrapper />}>
          <Route
            path=""
            element={
              <OrgAwareNavigate to={"post-incident-flow"} replace={true} />
            }
          />
          <Route path="post-incident-flow" element={<PostIncidentPage />} />
          <Route path="follow-ups" element={<FollowUpsPage />} />
        </Route>
      )}

      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};
