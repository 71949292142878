import {
  AlertRoute,
  CatalogType,
  CustomField,
  EngineScope,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBarTitle } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { Prompt } from "src/components/@shared/utils/Prompt";
import { useOrganisationAIAccess } from "src/hooks/useAI";
import { useAllResources } from "src/hooks/useResources";
import { useQueryParams } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../hooks/usePrimaryCommsPlatform";
import { tcx } from "../../../utils/tailwind-classes";
import { useAlertResources } from "../common/useAlertResources";
import { AlertRouteChooseSourcesSection } from "./ChooseSourcesSection";
import { AlertRouteCreateIncidentSection } from "./CreateIncidentSection";
import { AlertRouteEscalateSection } from "./EscalateSection";
import { AlertRouteFilterSection } from "./FilterSection";
import { AlertRoutePulseChannelSection } from "./PulseChannelSection";
import {
  AlertRouteCreationSource,
  AlertRouteFormData,
  getDefaultAlertRouteValues,
  parseAlertRoute,
  parseFormData,
} from "./types";

export const AlertRouteCreateEditForm = ({
  source,
  mode,
  initialData,
}: {
  source?: AlertRouteCreationSource;
} & CreateEditFormProps<AlertRoute>) => {
  const {
    data: { scope },
    isLoading: scopeLoading,
    error: scopeError,
  } = useAPI("alertRoutesBuildScope", undefined, {
    fallbackData: { scope: { references: [], aliases: {} } },
  });

  const {
    data: { custom_fields: customFields },
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  // Check if we have any routes already created
  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined, {
    fallbackData: {
      alert_routes: [],
    },
  });

  const {
    data: catalogTypesResp,
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {});

  const { isLoading: alertResourcesLoading } = useAlertResources();
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const error =
    scopeError ||
    resourcesError ||
    customFieldsError ||
    alertRoutesError ||
    catalogTypesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (
    scopeLoading ||
    resourcesLoading ||
    alertResourcesLoading ||
    customFieldsLoading ||
    alertRoutesLoading ||
    catalogTypesLoading
  ) {
    return <Loader />;
  }

  const modeProps = {
    mode,
    initialData,
  } as CreateEditFormProps<AlertRoute>;

  return (
    <AlertRouteCreateEditFormInner
      {...modeProps}
      source={source}
      alertRoutes={alertRoutesData?.alert_routes || []}
      scope={scope}
      resources={resources}
      customFields={customFields}
      catalogTypes={catalogTypesResp?.catalog_types ?? []}
    />
  );
};

const AlertRouteCreateEditFormInner = ({
  mode,
  initialData,
  source,
  scope,
  resources,
  customFields,
  alertRoutes,
  catalogTypes,
}: {
  source?: AlertRouteCreationSource;
  scope: EngineScope;
  resources: Resource[];
  customFields: CustomField[];
  alertRoutes: AlertRoute[];
  catalogTypes: CatalogType[];
} & CreateEditFormProps<AlertRoute>): React.ReactElement => {
  const { featureAlertsPulseChannel } = useFlags();
  const primaryCommsPlatform = usePrimaryCommsPlatform();
  const queryParams = useQueryParams();
  const initialSourceID = queryParams.get("initial_source_id");

  const showToast = useToast();

  const navigate = useOrgAwareNavigate();
  const onClose = () => {
    navigate("/alerts/configuration");
  };
  const canUseAI = useOrganisationAIAccess();

  const formMethods = useForm<AlertRouteFormData>({
    defaultValues: initialData
      ? parseAlertRoute(initialData, initialSourceID)
      : getDefaultAlertRouteValues(
          scope,
          customFields,
          initialSourceID,
          alertRoutes,
          catalogTypes,
          source,
          canUseAI,
        ),
  });

  const name = formMethods.watch("name");
  const isPrivate = formMethods.watch("is_private");
  const [
    hasEverConfiguredFilters,
    hasEverConfiguredEscalations,
    hasEverConfiguredIncidents,
    hasEverConfiguredPulseChannels,
  ] = formMethods.watch([
    "hasEverConfiguredFilters",
    "hasEverConfiguredEscalations",
    "hasEverConfiguredIncidents",
    "hasEverConfiguredPulseChannels",
  ]);
  const [enableEscalations, enableIncidents, enablePulseChannels] =
    formMethods.watch([
      "enableEscalations",
      "enableIncidents",
      "enablePulseChannels",
    ]);
  const hasNotConfiguredAnything =
    !enableEscalations && !enableIncidents && !enablePulseChannels;

  // We need to update the "show" api response so that
  // the next time we open the edit form it initialises
  // with our changes
  const { mutate: mutateSchema } = useAPI(
    initialData ? "alertRoutesShowAlertRoute" : null,
    {
      id: initialData?.id || "",
    },
  );

  const createMutation = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, formData: AlertRouteFormData) => {
      const result = await apiClient.alertRoutesCreateAlertRoute({
        createAlertRouteRequestBody: parseFormData(formData, customFields),
      });

      await mutateSchema(result);

      // Manually reset the form so its no longer dirty
      formMethods.reset(parseAlertRoute(result.alert_route, null));
    },
    {
      onSuccess: async () => {
        onClose();
        showToast({
          theme: ToastTheme.Success,
          title: "Alert route created",
          toastSide: ToastSideEnum.TopRight,
        });
      },
      showErrorToast: "Could not create alert route",
      setError: formMethods.setError,
    },
  );

  const editMutation = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, formData: AlertRouteFormData) => {
      if (!initialData) {
        return;
      }

      const result = await apiClient.alertRoutesUpdateAlertRoute({
        // this mutation only ever gets called when this is defined, but TS doesn't know that
        id: initialData?.id as string,
        updateAlertRouteRequestBody: parseFormData(
          {
            ...parseAlertRoute(initialData, initialData.id),
            ...formData,
          },
          customFields,
        ),
      });
      await mutateSchema(result);
      // Manually reset the form so its no longer dirty
      formMethods.reset(parseAlertRoute(result.alert_route, null));
    },
    {
      onSuccess: () => {
        onClose();
        showToast({
          theme: ToastTheme.Success,
          title: "Alert route updated",
          toastSide: ToastSideEnum.TopRight,
        });
      },
      showErrorToast: "Could not save alert route",
      setError: formMethods.setError,
    },
  );
  const { trigger: onSubmit, isMutating: saving } =
    mode === Mode.Edit ? editMutation : createMutation;

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const crumbs = [
    {
      title: "Alerts",
      to: "/alerts",
    },
    {
      title: "Configuration",
      to: "/alerts/configuration",
    },
  ];

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <Form.Root
        formMethods={formMethods}
        warnWhenDirty
        onSubmit={onSubmit}
        fullHeight
        saving={saving}
      >
        <Prompt
          when={formMethods.formState.isDirty}
          message={
            "Your changes have not been saved. Are you sure you want to navigate away?"
          }
        />
        <PageWrapper
          dottedBackground
          noPadding
          className="bg-slate-50 h-full"
          width={PageWidth.Full}
          icon={IconEnum.SplitArrow}
          title="Edit alert route"
          backHref={"/alerts/configuration"}
          titleNode={
            <HeaderBarTitle
              crumbs={crumbs}
              title="Edit alert route"
              titleNode={<div>{name}</div>}
            />
          }
          accessory={
            <div className="flex flex-row items-center gap-4">
              <ToggleV2
                formMethods={formMethods}
                name="enabled"
                label={"Active"}
                description="A deactivated alert route will not create incidents or escalations from alerts."
                descriptionInTooltip
              />
              <GatedButton
                theme={ButtonTheme.Primary}
                analyticsTrackingId="alert-route-edit-save"
                requiredScope={ScopeNameEnum.AlertSourceUpdate}
                type="submit"
                loading={saving}
                disabled={
                  (!formMethods.formState.isDirty && !initialSourceID) || saving
                }
                onClick={() => {
                  // If our form state is dirty, but we have errors that need to be externally fixed e.g. channel invite
                  // errors, we need to reset the form errors so we're allowed to save again.
                  formMethods.clearErrors();
                }}
              >
                Save changes
              </GatedButton>
            </div>
          }
          overflowX
        >
          <div className={"flex flex-row h-full self-stretch grow"}>
            <div className={"flex flex-col bg-white border-r p-6 w-[380px]"}>
              <div className={"flex flex-col gap-6"}>
                <InputV2
                  name={"name"}
                  formMethods={formMethods}
                  label={"Name"}
                  placeholder={"Default alert route"}
                />
                <div className={"flex flex-col gap-1"}>
                  <BooleanRadioButtonGroupV2
                    name="is_private"
                    formMethods={formMethods}
                    boxed
                    label={"Incident visibility"}
                    srLabel={"Incident visibility"}
                    trueOption={{
                      label: "Private",
                    }}
                    falseOption={{
                      label: "Public",
                    }}
                  />
                  <div className={"text-xs-med text-content-tertiary"}>
                    {isPrivate
                      ? "Incidents are only visible to invited users"
                      : "Incidents are visible to everyone"}
                  </div>
                </div>
              </div>
              <div className={"grow"} />
              <div className="flex flex-col gap-2">
                <div className="text-xs text-content-tertiary">
                  Useful links
                </div>
                <ul className="flex flex-col gap-1">
                  <li>
                    <Button
                      theme={ButtonTheme.Naked}
                      className="text-sm-med shadow-button-inner"
                      analyticsTrackingId="creating-escalations-and-incidents-help-docs"
                      href="https://help.incident.io/en/articles/9565315-creating-escalations-and-incidents-from-alerts#h_563a396f07"
                      openInNewTab
                    >
                      Creating escalations and incidents
                    </Button>
                  </li>
                  <li>
                    <Button
                      theme={ButtonTheme.Naked}
                      className="text-sm-med shadow-button-inner"
                      analyticsTrackingId="escalating-to-the-right-team-help-docs"
                      href="https://help.incident.io/en/articles/8973151-escalating-to-the-right-team-from-an-alert"
                      openInNewTab
                    >
                      Escalating to the right team from an alert
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                "w-full flex flex-col justify-start items-center p-10 gap-6"
              }
            >
              {hasEverConfiguredPulseChannels && hasNotConfiguredAnything && (
                <Callout
                  showIcon
                  theme={CalloutTheme.Danger}
                  title={"This alert route isn't doing anything"}
                  subtitle={
                    "You've turned everything off. If you want to temporarily disable your alert route, you can disable it with the Active toggle."
                  }
                  className={"w-[560px]"}
                />
              )}
              {featureAlertsPulseChannel ? (
                <FlowCards.List>
                  <AlertRouteChooseSourcesSection
                    scope={scopeWithExpressions}
                    key="choose-sources"
                    alertRouteId={initialData?.id}
                    alertRoutes={alertRoutes}
                  />
                  <AlertRouteFilterSection
                    key="filter"
                    scope={scopeWithExpressions}
                  />
                  <StackedList
                    className={tcx({
                      "border border-blue-500":
                        hasEverConfiguredFilters &&
                        (!hasEverConfiguredEscalations ||
                          !hasEverConfiguredIncidents ||
                          !hasEverConfiguredPulseChannels),
                    })}
                  >
                    <AlertRouteEscalateSection
                      key="escalate"
                      resources={resources}
                      scopeWithExpressions={scopeWithExpressions}
                    />
                    <AlertRouteCreateIncidentSection
                      key="create-incident"
                      resources={resources}
                      scope={scopeWithExpressions}
                    />
                    {primaryCommsPlatform === CommsPlatform.Slack && (
                      <AlertRoutePulseChannelSection
                        key={"alerts-pulse-channel"}
                        resources={resources}
                        scope={scopeWithExpressions}
                      />
                    )}
                  </StackedList>
                </FlowCards.List>
              ) : (
                <FlowCards.List>
                  <AlertRouteChooseSourcesSection
                    scope={scopeWithExpressions}
                    key="choose-sources"
                    alertRouteId={initialData?.id}
                    alertRoutes={alertRoutes}
                  />
                  <AlertRouteFilterSection
                    key="filter"
                    scope={scopeWithExpressions}
                  />
                  <AlertRouteEscalateSection
                    key="escalate"
                    resources={resources}
                    scopeWithExpressions={scopeWithExpressions}
                  />
                  <AlertRouteCreateIncidentSection
                    key="create-incident"
                    resources={resources}
                    scope={scopeWithExpressions}
                  />
                </FlowCards.List>
              )}
            </div>
          </div>
        </PageWrapper>
      </Form.Root>
    </ExpressionsMethodsProvider>
  );
};
