/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopilotInteractionScores
 */
export interface CopilotInteractionScores {
    /**
     * The score for this axis
     * @type {number}
     * @memberof CopilotInteractionScores
     */
    alignment: CopilotInteractionScoresAlignmentEnum;
    /**
     * The score for this axis
     * @type {number}
     * @memberof CopilotInteractionScores
     */
    ease: CopilotInteractionScoresEaseEnum;
    /**
     * The score for this axis
     * @type {number}
     * @memberof CopilotInteractionScores
     */
    predictability: CopilotInteractionScoresPredictabilityEnum;
    /**
     * The score for this axis
     * @type {number}
     * @memberof CopilotInteractionScores
     */
    tone: CopilotInteractionScoresToneEnum;
}

/**
* @export
* @enum {string}
*/
export enum CopilotInteractionScoresAlignmentEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}/**
* @export
* @enum {string}
*/
export enum CopilotInteractionScoresEaseEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}/**
* @export
* @enum {string}
*/
export enum CopilotInteractionScoresPredictabilityEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}/**
* @export
* @enum {string}
*/
export enum CopilotInteractionScoresToneEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

export function CopilotInteractionScoresFromJSON(json: any): CopilotInteractionScores {
    return CopilotInteractionScoresFromJSONTyped(json, false);
}

export function CopilotInteractionScoresFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotInteractionScores {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alignment': json['alignment'],
        'ease': json['ease'],
        'predictability': json['predictability'],
        'tone': json['tone'],
    };
}

export function CopilotInteractionScoresToJSON(value?: CopilotInteractionScores | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alignment': value.alignment,
        'ease': value.ease,
        'predictability': value.predictability,
        'tone': value.tone,
    };
}

