/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsJiraServerReceiveWebhookRequestBody
 */
export interface IntegrationsJiraServerReceiveWebhookRequestBody {
    /**
     * A set of changes that were made in this event
     * @type {object}
     * @memberof IntegrationsJiraServerReceiveWebhookRequestBody
     */
    changelog?: object;
    /**
     * The issue that was affected, if this is an issue-type event
     * @type {object}
     * @memberof IntegrationsJiraServerReceiveWebhookRequestBody
     */
    issue?: object;
    /**
     * An issue link that was created or destroyed
     * @type {object}
     * @memberof IntegrationsJiraServerReceiveWebhookRequestBody
     */
    issueLink?: object;
    /**
     * For an issue event, the type of the event
     * @type {string}
     * @memberof IntegrationsJiraServerReceiveWebhookRequestBody
     */
    issue_event_type_name?: string;
    /**
     * Unix timestamp of when this happened, in milliseconds
     * @type {number}
     * @memberof IntegrationsJiraServerReceiveWebhookRequestBody
     */
    timestamp?: number;
    /**
     * The user who triggered the event
     * @type {object}
     * @memberof IntegrationsJiraServerReceiveWebhookRequestBody
     */
    user?: object;
    /**
     * The type of event that triggered this webhook
     * @type {string}
     * @memberof IntegrationsJiraServerReceiveWebhookRequestBody
     */
    webhookEvent?: string;
}

export function IntegrationsJiraServerReceiveWebhookRequestBodyFromJSON(json: any): IntegrationsJiraServerReceiveWebhookRequestBody {
    return IntegrationsJiraServerReceiveWebhookRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsJiraServerReceiveWebhookRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsJiraServerReceiveWebhookRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changelog': !exists(json, 'changelog') ? undefined : json['changelog'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'issueLink': !exists(json, 'issueLink') ? undefined : json['issueLink'],
        'issue_event_type_name': !exists(json, 'issue_event_type_name') ? undefined : json['issue_event_type_name'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'webhookEvent': !exists(json, 'webhookEvent') ? undefined : json['webhookEvent'],
    };
}

export function IntegrationsJiraServerReceiveWebhookRequestBodyToJSON(value?: IntegrationsJiraServerReceiveWebhookRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changelog': value.changelog,
        'issue': value.issue,
        'issueLink': value.issueLink,
        'issue_event_type_name': value.issue_event_type_name,
        'timestamp': value.timestamp,
        'user': value.user,
        'webhookEvent': value.webhookEvent,
    };
}

