import type { SVGProps } from "react";
import * as React from "react";
const SvgCall = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16.729 11.982-2.926-1.299a1.721 1.721 0 0 0-2.04.494l-1.367 1.701a11.28 11.28 0 0 1-3.277-3.28L8.824 8.24A1.722 1.722 0 0 0 9.32 6.2l-1.3-2.928a1.716 1.716 0 0 0-2.004-.967l-2.476.643a1.725 1.725 0 0 0-1.273 1.907c.934 6.649 6.229 11.945 12.88 12.879a1.728 1.728 0 0 0 1.905-1.274l.642-2.476a1.715 1.715 0 0 0-.966-2.002Z"
    />
  </svg>
);
export default SvgCall;
