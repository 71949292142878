/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleOverridePayload
 */
export interface ScheduleOverridePayload {
    /**
     * End of the override
     * @type {Date}
     * @memberof ScheduleOverridePayload
     */
    end_at: Date;
    /**
     * The layer on the rotation on the schedule that this override applies to
     * @type {string}
     * @memberof ScheduleOverridePayload
     */
    layer_id: string;
    /**
     * The rotation on the schedule that this override applies to
     * @type {string}
     * @memberof ScheduleOverridePayload
     */
    rotation_id: string;
    /**
     * Start of the override
     * @type {Date}
     * @memberof ScheduleOverridePayload
     */
    start_at: Date;
    /**
     * The user that is covering this override, or NOBODY
     * @type {string}
     * @memberof ScheduleOverridePayload
     */
    user_id: string;
}

export function ScheduleOverridePayloadFromJSON(json: any): ScheduleOverridePayload {
    return ScheduleOverridePayloadFromJSONTyped(json, false);
}

export function ScheduleOverridePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleOverridePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': (new Date(json['end_at'])),
        'layer_id': json['layer_id'],
        'rotation_id': json['rotation_id'],
        'start_at': (new Date(json['start_at'])),
        'user_id': json['user_id'],
    };
}

export function ScheduleOverridePayloadToJSON(value?: ScheduleOverridePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': (value.end_at.toISOString()),
        'layer_id': value.layer_id,
        'rotation_id': value.rotation_id,
        'start_at': (value.start_at.toISOString()),
        'user_id': value.user_id,
    };
}

