import { ScopeNameEnum } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Callout,
  CalloutTheme,
  StepsPageContent,
  StepsPageFooter,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import {
  differenceInCalendarDays,
  differenceInHours,
  isAfter,
  isValid,
} from "date-fns";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";

import { parseSimpleDate, ReportGeneratorFormType } from "./ReportGenerator";
import { TimezoneHelperText } from "./TimezoneHelperToolTip";

// ReportGeneratorChooseWindow allows you to choose the window
// you want to calculate your report over. In future, it may
// include a prompt to add holidays to your report.
export const ReportGeneratorChooseWindow = ({
  formMethods,
  startDate,
  endDate,
  onContinue,
}: {
  formMethods: UseFormReturn<ReportGeneratorFormType>;
  startDate?: string;
  endDate?: string;
  onContinue: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  let startDateAfterEndDate = false;
  let reportNumberOfDays = 0;

  if (startDate != null && endDate != null) {
    const startDateAsDate = parseSimpleDate(startDate);
    const endDateAsDate = parseSimpleDate(endDate);
    startDateAfterEndDate = isAfter(startDateAsDate, endDateAsDate);

    // we need to add 1 as our dates are inclusive
    reportNumberOfDays =
      differenceInCalendarDays(endDateAsDate, startDateAsDate) + 1;
  }

  return (
    <>
      <StepsPageContent className="space-y-4">
        <InputV2
          formMethods={formMethods}
          label="What should this report be called?"
          name="name"
          required
          placeholder="e.g. May '22 platform on-call report"
        />
        <InputV2
          formMethods={formMethods}
          helptext={<TimezoneHelperText />}
          label="Choose the first full day which should be included in the report"
          name="start_date"
          type={InputType.Date}
          required
        />
        <InputV2
          formMethods={formMethods}
          label="Choose the last full day which should be included in the report"
          helptext={<TimezoneHelperText />}
          name="end_date"
          type={InputType.Date}
          rules={{
            validate: {
              invalid: (dateStr: string): string | true =>
                isValid(parseSimpleDate(dateStr))
                  ? true
                  : "Please choose a valid date",
              // We want to make sure we've got the schedule data before trying to create a report.
              in_future: (dateStr: string): string | true =>
                differenceInHours(new Date(), parseSimpleDate(dateStr)) > 25
                  ? true
                  : "Please choose a date in the past.",
            },
          }}
        />
        <div>
          {startDate && endDate && startDateAfterEndDate ? (
            <Callout theme={CalloutTheme.Warning}>
              Please select an end date which is after your start date.
            </Callout>
          ) : reportNumberOfDays != null && !isNaN(reportNumberOfDays) ? (
            <Callout theme={CalloutTheme.Plain} className="text-sm mb-1 w-full">
              The report will cover <strong>{reportNumberOfDays}</strong> full
              day
              {reportNumberOfDays > 1 ? "s" : ""}. If this doesn&apos;t look
              right, adjust the dates above.
            </Callout>
          ) : null}
        </div>
      </StepsPageContent>
      <StepsPageFooter
        onContinue={onContinue}
        continueButtonText="Continue"
        continueDisabled={!hasScope(ScopeNameEnum.ScheduleReportsCreate)}
        continueExplanationText={
          !hasScope(ScopeNameEnum.ScheduleReportsCreate)
            ? "You do not have permission to create reports."
            : undefined
        }
      />
    </>
  );
};
