import { IconEnum } from "@incident-ui";
import _ from "lodash";
import { ExternalResource } from "src/contexts/ClientContext";

import { AttachmentRenderProps } from "./IncidentAttachment";

export const AtlassianStatuspageIncident = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.atlassian_statuspage_incident) {
    throw new Error("Atlassian status page resource without expected content");
  }

  return {
    icon: IconEnum.AtlassianStatuspage,
    title: resource.atlassian_statuspage_incident.title,
    safeBadges: [
      {
        icon: IconEnum.Severity,
        text: _.startCase(resource.atlassian_statuspage_incident.impact),
      },
    ],
    unsafeBadges: [
      {
        icon: IconEnum.Status,
        text: _.startCase(resource.atlassian_statuspage_incident.status),
      },
    ],
  };
};
