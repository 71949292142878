import { Country } from "@incident-io/api";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Loader,
  ModalFooter,
} from "@incident-ui";
import { SelectOption, SelectOptions } from "@incident-ui/Select/types";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPI } from "src/utils/swr";

export const AddPublicHolidaysModal = ({
  onAddCountry,
  onClose,
  selectedCountryCodes,
}: {
  selectedCountryCodes: { id: string }[];
  onClose: () => void;
  onAddCountry: (countryCode: string) => void;
}) => {
  // Once we have https://linear.app/incident-io/issue/ONC-3143/add-a-users-fetch-by-id-endpoint
  // it'd be nice to fetch the users in the rotations and float their countries to the top

  const {
    data: countriesData,
    isLoading: loading,
    error,
  } = useAPI("holidaysListCountries", {});

  const formMethods = useForm<{
    country: string;
  }>();

  const onSubmit = (formData: { country: string }) => {
    onAddCountry(formData.country);
  };

  const countryOptions = useMemo((): SelectOptions => {
    return (
      countriesData?.countries.map(
        (c: Country): SelectOption => ({
          label: c.name,
          value: c.code,
          disabled: (selectedCountryCodes ?? []).some(
            (selected) => selected.id === c.code,
          ),
        }),
      ) ?? []
    );
  }, [countriesData?.countries, selectedCountryCodes]);

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={"Add public holidays"}
      analyticsTrackingId={"add-public-holidays"}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Add"
        />
      }
    >
      <StaticSingleSelectV2
        options={countryOptions}
        name={"country"}
        formMethods={formMethods}
      />
      <Callout theme={CalloutTheme.Info} className={"mt-4"}>
        {
          "We'll show you these holidays above your schedule timeline, so that you can plan ahead of your team's holidays."
        }
      </Callout>
    </Form.Modal>
  );
};
