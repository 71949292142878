import { Mode } from "@incident-shared/forms/v2/formsv2";
import { IconEnum } from "@incident-ui";
import React from "react";
import { Route, Routes } from "react-router";
import { ResponseGetStartedPage } from "src/components/response-preview/ResponseGetStartedPage";
import { useProductAccess } from "src/hooks/useProductAccess";

import { WorkflowsCreatePage } from "../../components/legacy/workflows/create/WorkflowsCreatePage";
import { WorkflowsEditPage } from "../../components/legacy/workflows/edit/WorkflowsEditPage";
import { WorkflowsListPage } from "../../components/legacy/workflows/list/WorkflowsListPage";

export const WorkflowsRoute = (): React.ReactElement => {
  const { hasResponse } = useProductAccess();

  if (!hasResponse) {
    return (
      <Routes>
        <Route
          path=""
          element={
            <ResponseGetStartedPage
              title="Workflows"
              icon={IconEnum.Workflows}
            />
          }
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path="create"
        element={<WorkflowsCreatePage mode={Mode.Create} />}
      />
      <Route path=":id/" element={<WorkflowsEditPage />} />
      {/* Temporarily linking to the new page through this so old links don't break */}
      <Route path=":id/view" element={<WorkflowsEditPage />} />
      {/* Fallthrough to list */}
      <Route path="*" element={<WorkflowsListPage />} />
    </Routes>
  );
};
