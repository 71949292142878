import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ErrorMessage } from "@incident-ui";
import { BadgeSize, Loader, ModalFooter } from "@incident-ui";
import { SeverityBadge } from "@incident-ui/Badge/SeverityBadge";
import { compact } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { BulkNoTriggersNotice } from "./BulkNoTriggersNotice";

export type BulkSetIncidentSeverityFormData = {
  to_incident_severity_id: string;
};

export function BulkSetIncidentSeverityForm({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const formMethods = useForm<BulkSetIncidentSeverityFormData>({});
  const { watch } = formMethods;

  const [bulkErrors, setBulkErrors] = useState<string[] | null>(null);

  const {
    data: { severities: incidentSeverities },
    error: incidentSeveritiesError,
  } = useAPI("severitiesList", undefined, { fallbackData: { severities: [] } });
  if (incidentSeveritiesError) {
    throw incidentSeveritiesError;
  }

  const severityID = watch("to_incident_severity_id");
  const analytics = useAnalytics();
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, data: BulkSetIncidentSeverityFormData) => {
      analytics?.track("bulkActionApplied", {
        action: "set severity",
        numIncidents: incidentIDs.length,
      });

      const { results } = await apiClient.incidentsBulkCreateUpdate({
        bulkCreateUpdateRequestBody: {
          incident_ids: incidentIDs,
          incident_update: {
            to_severity_id: data.to_incident_severity_id,
          },
        },
      });

      const errors = compact(
        results.filter((res) => res.error).map((result) => result.error),
      );

      if (errors && errors.length !== 0) {
        setBulkErrors(errors);
      } else {
        onSubmit();
      }
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  const severityDescription = incidentSeverities?.find(
    (severity) => severity.id === severityID,
  )?.description;

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={submit}
      title={"Set Incident Severity"}
      analyticsTrackingId="bulk-set-severity"
      genericError={genericError}
      saving={saving}
      onClose={onClose}
      footer={
        <ModalFooter
          hideBorder
          confirmButtonText={"Apply"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      {incidentSeverities ? (
        <>
          <StaticSingleSelectV2
            formMethods={formMethods}
            name={`to_incident_severity_id`}
            label={"Severity"}
            placeholder="Select a severity"
            required="Please choose a severity"
            options={incidentSeverities.map((incidentSeverity) => {
              return {
                value: incidentSeverity.id,
                label: incidentSeverity.name,
                iconComponent: (
                  <SeverityBadge
                    severity={incidentSeverity}
                    iconOnly
                    naked
                    className="mr-1"
                    size={BadgeSize.Small}
                  />
                ),
              };
            })}
          />
          {severityDescription && (
            <TemplatedTextDisplay
              style={TemplatedTextDisplayStyle.Compact}
              value={severityDescription.text_node}
              className="mt-1 ml-0.5 text-xs text-slate-700"
            />
          )}
          <BulkNoTriggersNotice className="mt-4" />
        </>
      ) : (
        <Loader />
      )}
      {bulkErrors && (
        <ErrorMessage
          message={`We encountered a problem updating ${bulkErrors.length} of your incidents. If you keep encountering errors, please contact support.`}
        />
      )}
    </Form.Modal>
  );
}
