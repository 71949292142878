import { OrgAwareNavLink } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./MainSidebar.module.scss";
import { SidebarItem } from "./useSidebarItems";

export const NavigationLink = ({
  item,
  closeSidebar,
  fadedOut,
  isExpanded,
}: {
  item: SidebarItem;
  closeSidebar?: () => void;
  fadedOut?: boolean;
  isExpanded: boolean;
}): React.ReactElement => {
  return (
    <Tooltip
      content={item.label}
      side="right"
      delayDuration={0}
      disabled={isExpanded}
      sideOffset={16}
    >
      <div>
        <OrgAwareNavLink
          onClick={() => {
            if (closeSidebar) {
              closeSidebar();
            }
            // You're on a different 'page', so scroll to the top
            window.scrollTo({ top: 0, left: 0 });
          }}
          to={item.slug}
          className={({ isActive }) =>
            tcx(
              isExpanded ? "min-w-[200px]" : "",
              "font-medium hover:bg-slate-700 hover:text-white rounded-2",
              "p-2 flex items-center gap-2 group",
              "text-sm",
              {
                "text-white [&>svg]:!text-brand bg-slate-700 [&>*]:!border-white":
                  isActive,
                "text-slate-600": fadedOut,
              },
            )
          }
          data-intercom-target={`nav-${item.slug}`}
        >
          <Icon
            id={item.icon}
            size={IconSize.Large}
            className={tcx(fadedOut && "text-slate-700")}
          />
          <span
            className={tcx(
              "grow",
              "truncate", // Fix the width so it doesn't wrap when we expand the sidebar
              !isExpanded && "hidden",
            )}
          >
            {item.label}
          </span>
          {item.isNew && (
            <div
              className={tcx(
                "h-4 flex items-center px-1 rounded",
                "border border-slate-300 group-hover:border-white",
                "text-[10px] tabular-nums lining-nums font-semibold",
                !isExpanded && "hidden",
              )}
            >
              NEW
            </div>
          )}
        </OrgAwareNavLink>
      </div>
    </Tooltip>
  );
};

export const BottomNavLink = ({
  title,
  href,
  icon,
  onClick,
  analyticsTrackingId,
  className,
}: {
  title: string;
  onClick?: () => void;
  href?: string;
  icon: IconEnum;
  analyticsTrackingId: string;
  className?: string;
}): React.ReactElement => {
  return (
    <Tooltip
      side="right"
      content={title}
      bubbleProps={{ className: styles.navLinkTooltip }}
    >
      <Button
        analyticsTrackingId={analyticsTrackingId}
        title={title}
        onClick={onClick}
        href={href}
        theme={ButtonTheme.Unstyled}
        iconProps={{ size: IconSize.Large, className: "ml-0.5 !mr-[9px]" }}
        icon={icon}
        className={tcx(
          styles.bottomNavLink,
          "!text-sm mx-1.5 py-1.5 px-2 rounded-[6px] grow",
          className,
        )}
      >
        <span className={tcx(styles.visuallyHiddenTabletText)}>{title}</span>
      </Button>
    </Tooltip>
  );
};
