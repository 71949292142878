import { InternalStatusPageComponentOrGroup } from "@incident-io/api";
import { GenericStructureItem } from "@incident-io/status-page-ui";

export const toGenericStructureItem = (
  item: InternalStatusPageComponentOrGroup,
): GenericStructureItem => {
  if (item.group) {
    const group = {
      id: item.group.id,
      displayUptime: false,
      hidden: item.group.hidden,
      name: item.group.label,
      components: item.group.components.map((component) => {
        return {
          id: component.id,
          displayUptime: false,
          hidden: component.hidden,
          name: component.label,
        };
      }),
    };
    return { group: group };
  }

  if (item.component) {
    return {
      component: {
        id: item.component.id,
        displayUptime: false,
        hidden: item.component.hidden,
        name: item.component.label,
      },
    };
  }

  throw new Error("Undefined component");
};
