// A helper function that lets us easily pull in tailwind config values

import { flatten } from "lodash";
import React from "react";
import resolveConfig from "tailwindcss/resolveConfig";

import { StoryHeading } from "./storybook-helpers";
import tailwindConfig from "./tailwind.config.js";

export const twConfig = resolveConfig(tailwindConfig);

export function getColor(color: string, shade?: string): string {
  if (twConfig.theme?.colors) {
    if (shade === "" || shade == null || shade === "DEFAULT") {
      return twConfig.theme.colors[color] || "";
    }
    return twConfig.theme.colors[color][shade] || "";
  }
  return "";
}

const semanticColors = ["brand", "surface", "stroke", "content"];
const blessedColors = [
  "slate",
  "blue",
  "green",
  "red",
  "amber",
  "alarmalade",
  "pink",
  "purple",
  "black",
  "white",
];

export const SemanticPalette = () => {
  const findOriginalColor = (hex: string): [string, string] => {
    for (const color of blessedColors) {
      const palette = twConfig.theme.colors[color];
      if (palette) {
        if (typeof palette === "string") {
          if (palette === hex) {
            return [color, ""];
          }
          continue;
        }
        for (const shade in palette) {
          if (palette[shade] === hex) {
            return [color, shade];
          }
        }
      }
    }
    return ["could not find original", hex];
  };

  return (
    <div
      className="grid gap-4 items-center"
      style={{ gridTemplateColumns: "150px auto" }}
    >
      {semanticColors.map((color) => {
        const palette = twConfig.theme.colors[color];
        if (!palette) {
          return <div key={color}>could not find palette: {color}</div>;
        }
        return (
          <React.Fragment key={color}>
            <StoryHeading>{color}</StoryHeading>
            <div
              className="grid gap-1"
              style={{
                gridTemplateColumns: `repeat(${
                  Object.keys(palette).length
                }, 1fr)`,
              }}
            >
              {Object.keys(palette).map((shade) => {
                return (
                  <div
                    className="!text-xs text-content-secondary justify-self-center self-end text-center"
                    key={shade}
                  >
                    {color}-{shade}
                  </div>
                );
              })}
              {Object.keys(palette).map((shade) => {
                const [ogColor, ogTint] = findOriginalColor(palette[shade]);
                return (
                  <div
                    key={shade}
                    className="h-10 grow border-[1px] border-white flex items-center justify-center !text-xs"
                    style={{
                      backgroundColor: palette[shade],
                      color: colorForShade(ogTint),
                    }}
                  >
                    {ogColor}-{ogTint}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const FullPalette = () => {
  return (
    <div
      className="grid gap-4 items-center"
      style={{ gridTemplateColumns: "150px auto" }}
    >
      {blessedColors.map((color) => {
        let palette = twConfig.theme.colors[color];
        if (!palette) {
          return <div key={color}>could not find palette: {color}</div>;
        }
        if (typeof palette === "string") {
          palette = { "": palette };
        }
        return (
          <React.Fragment key={color}>
            <StoryHeading>{color}</StoryHeading>
            <div className="flex grow">
              {Object.keys(palette).map((shade) => (
                <div
                  className="flex flex-col gap-1 text-content-secondary grow basis-0"
                  key={shade}
                >
                  <div
                    className="h-10 grow border-[1px] border-white !text-xs flex items-center justify-center font-mono "
                    style={{
                      backgroundColor: palette[shade],
                      color: colorForShade(shade),
                    }}
                  >
                    {shade}
                  </div>
                  <div className="self-center !text-xs font-mono">
                    {palette[shade].toLowerCase()}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const colorForShade = (shade: string) => {
  if (shade === "content") {
    return "white";
  } else if (shade === "surface") {
    return "black";
  }

  return parseInt(shade) > 300 ? "white" : "black";
};

// Typography
export const TypographyPalette = () => {
  const fontSizes = twConfig.theme.fontSize;

  return (
    <div className="flex flex-col gap-4">
      {Object.entries(fontSizes).map(([key, content]) => {
        if (!key.includes("-")) {
          // Exclude all the base styles
          return <></>;
        }

        // @ts-expect-error - this is what it looks like, promise.
        const [size, styles] = content;

        return (
          <div className="flex flex-col gap-1" key={key}>
            <div
              style={{
                fontSize: size,
                ...styles,
              }}
            >
              text-{key}
            </div>
            <div className="text-content-tertiary !text-xs-med">
              Size: {size} | Weight: {styles.fontWeight} | Line height:{" "}
              {styles.lineHeight} | Letter spacing: {styles.letterSpacing}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const listTypographys = () => {
  return Object.keys(twConfig.theme.fontSize);
};

export const listColorShades = () => {
  return flatten(
    Object.entries(twConfig.theme.colors).map(([color, shades]) =>
      // @ts-expect-error shades is always an object with keys.
      Object.keys(shades).map((shade) =>
        shade === "DEFAULT" ? color : `${color}-${shade}`,
      ),
    ),
  );
};
