import type { SVGProps } from "react";
import * as React from "react";
const SvgDownloadCloud = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.146 7.327C14.704 4.864 12.535 3 10 3a5.256 5.256 0 0 0-5.25 5.25c0 .128.005.258.017.39A3.733 3.733 0 0 0 2 12.25 3.754 3.754 0 0 0 5.75 16h7.75c2.481 0 4.5-2.019 4.5-4.5a4.495 4.495 0 0 0-2.854-4.173Zm-2.366 3.704-2.25 2.25a.748.748 0 0 1-1.06 0l-2.25-2.25A.75.75 0 1 1 8.281 9.97l.97.97V7.751a.75.75 0 0 1 1.5 0v3.189l.97-.97a.75.75 0 1 1 1.061 1.061h-.002Z"
    />
  </svg>
);
export default SvgDownloadCloud;
