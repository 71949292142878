import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Loader } from "@incident-ui";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  JiraConfig,
  JiraFieldEnums,
  JiraOptionFetcher,
  JiraStep,
  useGetJiraOptions,
} from "src/components/@shared/integrations/jira/jiraConfig";
import { BulkExportTitleAndDescription } from "src/components/post-incident/follow-ups/bulk-update/bulk-export/helpers";
import {
  JiraIssueFieldInput,
  JiraSelect,
} from "src/components/settings/integrations/list/jira/JiraHelpers";
import {
  JiraIssueDefaultValues,
  JiraIssueField,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { LabelWithReset } from "./LabelWithReset";

export function FormSelectSite({
  onChangeSite,
  canChange = true,
}: {
  onChangeSite: () => void;
  canChange?: boolean;
}) {
  const formMethods = useFormContext<{ site_id: string }>();
  const {
    data: { options: sites },
    isLoading,
  } = useAPI(
    "engineTypeahead",
    {
      resource: `CatalogEntry["JiraCloudSite"]`,
    },
    { fallbackData: { options: [], option_groups: [] } },
  );
  const { getValues, setValue } = formMethods;
  const siteId = formMethods.watch("site_id");

  useEffect(() => {
    if (sites.length === 1) {
      const prevSiteId = getValues("site_id");

      if (!prevSiteId) {
        setValue<"site_id">("site_id", sites[0].value);
      }
    }
  }, [getValues, setValue, sites]);

  if (isLoading) {
    return <Loader />;
  }

  if (sites.length === 1) {
    return null;
  }

  return (
    <StaticSingleSelectV2
      label={
        <LabelWithReset
          label={"Jira Site"}
          onReset={onChangeSite}
          hasValue={!!siteId}
          canChange={canChange}
        />
      }
      formMethods={formMethods}
      name={"site_id"}
      options={sites}
      placeholder="Select..."
      isLoading={isLoading}
      disabled={!canChange || !!siteId}
      required={"Please choose an option"}
    />
  );
}

export function FormSelectProject<TFieldEnum extends JiraFieldEnums>({
  config,
  step,
  siteId,
  onChangeProject,
}: {
  config: JiraConfig<TFieldEnum>;
  step: JiraStep;
  siteId: string;
  onChangeProject: () => void;
}) {
  const getJiraOptions = useGetJiraOptions(config);

  if (step === JiraStep.Step0) {
    return null;
  }

  const hasValue = step !== JiraStep.Step1;

  return (
    <JiraSelect
      label={
        <LabelWithReset
          label="Choose a project"
          onReset={onChangeProject}
          hasValue={hasValue}
        />
      }
      disabled={step !== JiraStep.Step1}
      fieldKey={"project_id"}
      required={true}
      getJiraOptions={() =>
        getJiraOptions({ field: config.fieldEnum.Project, siteId })
      }
      dependencies={[siteId]}
    />
  );
}

export function FormSelectIssueType<TFieldEnum extends JiraFieldEnums>({
  config,
  step,
  siteId,
  projectId,
  onChangeIssueType,
}: {
  config: JiraConfig<TFieldEnum>;
  step: JiraStep;
  siteId: string;
  projectId: string;
  onChangeIssueType: () => void;
}): React.ReactElement | null {
  const getJiraOptions = useGetJiraOptions(config);
  if (step === JiraStep.Step0 || step === JiraStep.Step1) {
    return null;
  }

  return (
    <>
      <JiraSelect
        label={
          <LabelWithReset
            label="Issue type"
            hasValue={step !== JiraStep.Step2}
            onReset={onChangeIssueType}
          />
        }
        disabled={step === JiraStep.Step3}
        fieldKey={"issue_type_id"}
        required={true}
        getJiraOptions={() =>
          getJiraOptions({
            field: config.fieldEnum.Issuetype,
            siteId,
            projectId,
          })
        }
        dependencies={[siteId, projectId]}
      />
    </>
  );
}

// To keep the form short, we don't include optional fields, except for these
// two, which people really often want to set!
export const RENDERED_OPTIONAL_FIELDS = ["assignee", "labels"];

type Props<TFieldEnum extends JiraFieldEnums> = {
  config: JiraConfig<TFieldEnum>;
  step: JiraStep;
  siteId: string;
  projectId: string;
  issueTypeId: string;
  issueFields: JiraIssueField[];
  hasDescription: boolean;
  defaultValuesFromIssueTemplate?: JiraIssueDefaultValues;
  loading: boolean;
} & (
  | {
      providerName?: never;
      isBulkExport?: never;
    }
  | {
      providerName: string;
      isBulkExport: boolean;
    }
);

export function FormEverythingElse<TFieldEnum extends JiraFieldEnums>(
  props: Props<TFieldEnum>,
): React.ReactElement | null {
  const { step, issueFields, hasDescription, providerName, isBulkExport } =
    props;
  const formMethods = useFormContext();

  // before they've moved to our part of the form, we shouldn't show these extra fields
  if (step !== JiraStep.Step3) {
    return null;
  }

  if (!issueFields) {
    return <Loader />;
  }

  if (!isBulkExport) {
    return (
      <>
        <InputV2
          formMethods={formMethods}
          label="Title"
          name="title"
          placeholder="Any more details you want to add while you're here?"
          required="Jira issues must have a title"
          rules={{
            maxLength: {
              value: 255,
              message:
                "Jira ticket titles must be less than 255 characters long",
            },
          }}
        />
        {hasDescription ? (
          <TextareaV2
            formMethods={formMethods}
            label="Description"
            required={false}
            name="description"
            rows={4}
            placeholder="Any more details you want to add while you're here?"
          />
        ) : null}
        <RenderIssueFields {...props} />
      </>
    );
  } else {
    // eslint is complaining about the providerName passed to BulkExportTitleAndDescription being
    // possibly undefined, but it can't be, because we're in the isBulkExport branch. We can't use
    // disable-next-line in a fragment so lets do this and get on with our lives.
    if (!providerName) {
      throw new Error("providerName is undefined");
    }
    return (
      <>
        <BulkExportTitleAndDescription providerName={providerName} />
        <RenderIssueFields {...props} />
      </>
    );
  }
}

const RenderIssueFields = <TFieldEnum extends JiraFieldEnums>({
  config,
  issueFields,
  defaultValuesFromIssueTemplate,
  siteId,
  projectId,
  issueTypeId,
  loading,
}: Props<TFieldEnum>) => {
  const apiClient = useClient();

  const loadOptions = useCallback(
    async (params) => {
      const getJiraOptions = apiClient[config.getTypeaheadOptionsAPI].bind(
        apiClient,
      ) as JiraOptionFetcher<TFieldEnum>;
      const typeahead_options =
        (await getJiraOptions(params)).typeahead_options || [];

      return typeahead_options;
    },
    [apiClient, config.getTypeaheadOptionsAPI],
  );

  return (
    <>
      {issueFields
        .filter(
          (field) =>
            field.required || RENDERED_OPTIONAL_FIELDS.includes(field.key),
        )
        .map((field) => {
          // Dynamic fields are the fields that are specific to a project and issue type.
          // They include custom fields on the issue (created in Jira settings by the user)
          // and fields like "assignee" and "labels" that are defined by Jira but not set
          // on every issue type. We need to prefix them to handle separately in the request
          const fieldKey = `dynamic_fields.${field.key}`;

          let initialValue: string | string[] | undefined;
          if (field.default_value) {
            if (typeof field.default_value === "string") {
              initialValue = field.default_value;
            } else if (Array.isArray(field.default_value)) {
              initialValue = field.default_value.map((value) => value.id);
            }
          }

          if (field.key === "labels") {
            initialValue = defaultValuesFromIssueTemplate?.labels;
          }

          return (
            <JiraIssueFieldInput
              key={fieldKey}
              field={field}
              fieldKey={fieldKey}
              siteId={siteId}
              projectId={projectId}
              issueTypeId={issueTypeId}
              initialValue={initialValue}
              loadOptions={loadOptions}
              loading={loading}
            />
          );
        })}
    </>
  );
};
