/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemSharesRenderPreviewRequestBody
 */
export interface PostmortemSharesRenderPreviewRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemSharesRenderPreviewRequestBody
     */
    content?: TextNode;
    /**
     * ID of the incident
     * @type {string}
     * @memberof PostmortemSharesRenderPreviewRequestBody
     */
    incident_id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemSharesRenderPreviewRequestBody
     */
    postmortem_description?: TextNode;
}

export function PostmortemSharesRenderPreviewRequestBodyFromJSON(json: any): PostmortemSharesRenderPreviewRequestBody {
    return PostmortemSharesRenderPreviewRequestBodyFromJSONTyped(json, false);
}

export function PostmortemSharesRenderPreviewRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSharesRenderPreviewRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : TextNodeFromJSON(json['content']),
        'incident_id': json['incident_id'],
        'postmortem_description': !exists(json, 'postmortem_description') ? undefined : TextNodeFromJSON(json['postmortem_description']),
    };
}

export function PostmortemSharesRenderPreviewRequestBodyToJSON(value?: PostmortemSharesRenderPreviewRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': TextNodeToJSON(value.content),
        'incident_id': value.incident_id,
        'postmortem_description': TextNodeToJSON(value.postmortem_description),
    };
}

