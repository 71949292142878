import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useClipboard } from "src/utils/useClipboard";

export const TextViewer = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <div className="w-full bg-white rounded-2 shadow-sm border font-mono">
      <div className="flex justify-between items-center p-2 border-b">
        <span className="text-sm font-medium text-gray-700">{title}</span>
        <Button
          theme={ButtonTheme.Naked}
          onClick={() => copyTextToClipboard(content)}
          icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
          analyticsTrackingId={null}
        >
          Copy
        </Button>
      </div>
      <div className="p-4 bg-surface-secondary rounded-b-2 font-mono text-xs overflow-y-hidden">
        <pre className="whitespace-pre-wrap">{content}</pre>
      </div>
    </div>
  );
};
