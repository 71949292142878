import { TextNode } from "@incident-io/api";

import { schema } from "./schema";

export const isEqual = (
  left: TextNode | undefined,
  right: TextNode | undefined,
) => {
  if (left === undefined && right === undefined) return true;

  if (left === undefined || right === undefined) return false;

  const leftDoc = schema.nodeFromJSON(left);
  const rightDoc = schema.nodeFromJSON(right);
  return leftDoc.eq(rightDoc);
};

export const textNodeToPlainText = (templatedText: TextNode): string => {
  const body = schema.nodeFromJSON(templatedText);
  return body.textBetween(0, body.nodeSize - 2, "\n");
};
