import { Expression } from "@incident-io/api";
import { createContext, useState } from "react";

export type CustomFieldContextType = {
  expression: Expression | undefined;
  setExpression: (expression: Expression | undefined) => void;
};

const unimplemented = () => {
  throw new Error("unimplemented");
};

export const CustomFieldContext = createContext<CustomFieldContextType>({
  expression: undefined,
  setExpression: unimplemented,
});

export const CustomFieldProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [expression, setExpression] = useState<Expression | undefined>(
    undefined,
  );

  return (
    <CustomFieldContext.Provider
      value={{
        expression,
        setExpression,
      }}
    >
      {children}
    </CustomFieldContext.Provider>
  );
};
