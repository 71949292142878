import {
  EscalationPath,
  EscalationPathNode,
  OnCallNotificationRuleRuleTypeEnum,
} from "@incident-io/api";
import { Callout, CalloutTheme, Link, Txt } from "@incident-ui";
import _ from "lodash";
import React from "react";
import { useAPI } from "src/utils/swr";

import { formatAsMinutes } from "./helpers";

export const OnCallNotificationConfigurationInvalidCallout = ({
  shortestEscalationPath,
  currentLowestNotificationTime,
  mode,
}: {
  shortestEscalationPath: ShortestEscalationPath;
  currentLowestNotificationTime: number;
  mode: "modal" | "page";
}): React.ReactElement => {
  if (!shortestEscalationPath) {
    return <></>;
  }

  return (
    <Callout
      theme={CalloutTheme.Danger}
      showIcon={false}
      className="col-span-2 m-0.5"
    >
      <div className="flex flex-col m-0.5">
        <div className="text-content-primary">
          <Link
            analyticsTrackingId="on-call-notifications-esclation-policy-link"
            href={`/on-call/escalation-paths/${shortestEscalationPath.path.id}`}
            openInNewTab={true}
          >
            {shortestEscalationPath.path.name}
          </Link>{" "}
          escalates{" "}
          {shortestEscalationPath.seconds > 0 ? (
            <>
              after{" "}
              <Txt bold inline>
                {formatAsMinutes(shortestEscalationPath.seconds)} minute
                {shortestEscalationPath.seconds > 60 ? "s" : ""}
              </Txt>
            </>
          ) : (
            <>
              <Txt bold inline>
                immediately
              </Txt>
            </>
          )}
          . With your current {mode === "page" ? "preferences" : "selection"},
          you&apos;ll be notified after{" "}
          <Txt bold inline>
            {formatAsMinutes(currentLowestNotificationTime)} minute
            {currentLowestNotificationTime > 60 ? "s" : ""}
          </Txt>{" "}
          and we will not be able to reach you before escalating.
        </div>
      </div>
    </Callout>
  );
};

export const OnCallNoNotificationCallout = ({
  type,
}: {
  type: OnCallNotificationRuleRuleTypeEnum;
}) => {
  const word =
    type === OnCallNotificationRuleRuleTypeEnum.ShiftChanges
      ? "shift"
      : "alert";
  return (
    <Callout
      theme={CalloutTheme.Danger}
      showIcon={false}
      className="col-span-2 m-0.5"
    >
      <div className="flex flex-col m-0.5">
        <div className="text-content-primary">
          You have{" "}
          <Txt inline bold>
            no
          </Txt>{" "}
          {word} notifications rules configured. You will{" "}
          <Txt inline bold>
            not
          </Txt>{" "}
          be notified for incoming {word}s.
        </div>
      </div>
    </Callout>
  );
};

export type ShortestEscalationPath = {
  path: EscalationPath;
  seconds: number;
};

// Load all of our escalation paths so we can check the lowest time-to-respond
// on each of the paths to help people avoid misconfigurations.
export const useShortestEscalationPathForUser = (
  userID: string,
): ShortestEscalationPath | undefined => {
  const { data } = useAPI("escalationPathsListForUser", {
    user: userID,
  });

  return shortestEscalationPath(data?.escalation_paths ?? []);
};

// Find the path with the lowest time to respond, from the paths we know this
// user is on and having ignored the conditionals.
export const shortestEscalationPath = (
  escalationPaths: EscalationPath[],
):
  | {
      path: EscalationPath;
      seconds: number;
    }
  | undefined => {
  const shortest = _.chain(escalationPaths)
    .map((path) => {
      return {
        path: path,
        seconds: getMinTimeToAck(path.path ?? []) || Infinity,
      };
    })
    .minBy("seconds")
    .value();

  if (shortest) {
    return shortest[0];
  }

  return undefined;
};

const getMinTimeToAck = (path: Array<EscalationPathNode>) => {
  return _.chain(path)
    .flatMap((node) => {
      if (node.level) {
        return node.level?.time_to_ack_seconds;
      }
      if (node.if_else) {
        return [
          getMinTimeToAck(node.if_else?.then_path),
          getMinTimeToAck(node.if_else?.else_path),
        ];
      }

      return Infinity;
    })
    .compact()
    .min()
    .value();
};
