import React from "react";
import { Route, Routes } from "react-router";

import { APIKeysPage } from "./APIKeysPage";

export const APIKeysRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<APIKeysPage />} />
    </Routes>
  );
};
