import {
  StatusPage,
  StatusPageCreateIncidentRequestBodyStatusEnum,
  StatusPageCreateIncidentUpdateRequestBodyToStatusEnum,
  StatusPageCreateMaintenanceRequestBodyStatusEnum,
  StatusPageIncidentUpdatePayloadToStatusEnum,
  StatusPageIncidentUpdateToStatusEnum,
  StatusPageTemplate,
  StatusPageTemplateModeEnum as TemplateModeEnum,
  TextNode,
} from "@incident-io/api";
import { isEqual } from "@incident-shared/forms/v1/TemplatedText/helpers";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { SelectOption } from "@incident-ui/Select/types";
import { useEffect } from "react";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import { usePrevious } from "use-hooks";

import { PreDefinedUpdate } from "../../settings/edit/PreDefinedUpdatesSection";

export const isTemplate = (
  templates: StatusPageTemplate[],
  message: TextNode | undefined,
) => {
  for (const template of templates) {
    const content =
      template.message_template_config?.message_content ||
      template.predefined_update_config?.message_content;
    if (content !== undefined && isEqual(message, content)) {
      return true;
    }
  }
  return false;
};

type StatusEnum =
  | StatusPageCreateIncidentRequestBodyStatusEnum
  | StatusPageCreateIncidentUpdateRequestBodyToStatusEnum
  | StatusPageIncidentUpdatePayloadToStatusEnum
  | StatusPageIncidentUpdateToStatusEnum
  | StatusPageCreateMaintenanceRequestBodyStatusEnum;

export const messageTemplateFor = (
  templates: StatusPageTemplate[] | null,
  status: StatusEnum,
) => {
  const template = templates?.find(
    (template) =>
      (template.message_template_config?.incident_status as unknown) === status,
  );
  if (!template) {
    return undefined;
  }
  return {
    content: template.message_template_config?.message_content,
    id: template.id,
  };
};

export type PreDefinedUpdateOption = SelectOption & {
  template: PreDefinedUpdate;
};

// useTemplatedMessageInput keeps watch of an incident status input, and updates a corresponding
// message input with templates as necessary.
export const useTemplatedMessageInput = <
  TFormType extends FieldValues,
  TStatusPath1 extends Path<TFormType>,
  TStatusPath extends PathValue<TFormType, TStatusPath1> extends StatusEnum
    ? TStatusPath1
    : never,
>({
  formMethods,
  namePath,
  statusPath,
  messagePath,
  templateIdPath,
  templates,
  page,
}: {
  formMethods: UseFormReturn<TFormType>;
  namePath?: Path<TFormType>;
  statusPath: TStatusPath;
  messagePath: Path<TFormType>;
  templateIdPath: Path<TFormType>;
  templates: StatusPageTemplate[];
  page: StatusPage;
}): {
  renderTemplateSelect: () => JSX.Element | null;
} => {
  useMessageTemplates<TFormType, TStatusPath1, TStatusPath>({
    formMethods,
    statusPath,
    messagePath,
    templateIdPath,
    templates,
    page,
  });

  const { renderTemplateSelect } = usePredefinedUpdateInput<
    TFormType,
    TStatusPath1,
    TStatusPath
  >({
    formMethods,
    namePath,
    statusPath,
    messagePath,
    templates,
    templateIdPath,
    page,
  });

  return { renderTemplateSelect };
};

// useMessageTemplates keeps watch of an incident status input, and updates a corresponding
// message input with templates as necessary.
const useMessageTemplates = <
  TFormType extends FieldValues,
  TStatusPath1 extends Path<TFormType>,
  TStatusPath extends PathValue<TFormType, TStatusPath1> extends StatusEnum
    ? TStatusPath1
    : never,
>({
  formMethods,
  statusPath,
  messagePath,
  templateIdPath,
  templates,
  page,
}: {
  formMethods: UseFormReturn<TFormType>;
  statusPath: TStatusPath;
  messagePath: Path<TFormType>;
  templateIdPath: Path<TFormType>;
  templates: StatusPageTemplate[];
  page: StatusPage;
}) => {
  const { getValues, setValue, watch } = formMethods;

  const status = watch<TStatusPath>(statusPath);
  const message = getValues<Path<TFormType>>(messagePath) as
    | TextNode
    | undefined;

  const prevStatus = usePrevious(status);

  // Handle when template mode = MessageTemplate
  useEffect(() => {
    if (page.template_mode !== TemplateModeEnum.MessageTemplate) {
      return;
    }

    if (status === prevStatus) return;

    // If there's no new template, don't do anything - we don't want to _clear_
    // any input
    const newTemplate = messageTemplateFor(templates, status);
    if (newTemplate === undefined) return;

    // If the status is changing, and the message is currently either empty or
    // the value from the previous template, update it with the new template
    if (message === undefined || isTemplate(templates, message)) {
      setValue<Path<TFormType>>(
        messagePath,
        newTemplate.content as PathValue<TFormType, Path<TFormType>>,
      );
      setValue<Path<TFormType>>(
        templateIdPath,
        newTemplate.id as PathValue<TFormType, Path<TFormType>>,
      );
    }
  }, [
    status,
    prevStatus,
    message,
    setValue,
    messagePath,
    templates,
    templateIdPath,
    page.template_mode,
  ]);
};

// usePredefinedUpdateInput returns a function that renders a dropdown for choosing
// a predefined update template, and updates the form fields accordingly.
const usePredefinedUpdateInput = <
  TFormType extends FieldValues,
  TStatusPath1 extends Path<TFormType>,
  TStatusPath extends PathValue<TFormType, TStatusPath1> extends StatusEnum
    ? TStatusPath1
    : never,
>({
  formMethods,
  namePath,
  statusPath,
  messagePath,
  templateIdPath,
  templates,
  page,
}: {
  formMethods: UseFormReturn<TFormType>;
  namePath?: Path<TFormType>;
  statusPath: TStatusPath;
  messagePath: Path<TFormType>;
  templateIdPath: Path<TFormType>;
  templates: StatusPageTemplate[];
  page: StatusPage;
}): {
  renderTemplateSelect: () => JSX.Element | null;
} => {
  const { setValue } = formMethods;

  // Handle when template mode = PreDefinedUpdate
  const preDefinedUpdateOptions: PreDefinedUpdateOption[] = templates.map(
    (template) => ({
      label: template.predefined_update_config?.template_name || "",
      value: template.id,
      template: template as PreDefinedUpdate,
    }),
  );

  const onSelectPreDefinedUpdate = (selectedId: string) => {
    const selectedPreDefinedUpdate = templates.find(
      (template) => template.id === selectedId,
    );
    const config = selectedPreDefinedUpdate?.predefined_update_config;
    if (config === undefined) {
      return;
    }

    setValue(
      messagePath,
      config.message_content as PathValue<TFormType, Path<TFormType>>,
    );

    if (config.incident_status) {
      setValue(
        statusPath,
        config.incident_status as unknown as PathValue<TFormType, TStatusPath>,
      );
    }
    if (namePath && config.incident_name) {
      // Sometimes, there won't be a 'name' field (e.g. when you're updating an
      // existing incident). If so, that's fine, we'll just ignore this part of
      // the template.
      setValue(
        namePath,
        config.incident_name as PathValue<TFormType, Path<TFormType>>,
      );
    }
  };

  const hasPreDefinedUpdates =
    page.template_mode === TemplateModeEnum.PreDefinedUpdate &&
    templates.length > 0;

  const renderTemplateSelect = () => {
    if (!hasPreDefinedUpdates) {
      return null;
    }

    return (
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Template"
        name={templateIdPath}
        helptext="Choose a template to use as a starting point. You can edit the message before publishing."
        placeholder="Select a template"
        options={preDefinedUpdateOptions}
        onValueChange={(selected) =>
          onSelectPreDefinedUpdate(selected as string)
        }
      />
    );
  };

  return {
    renderTemplateSelect,
  };
};
