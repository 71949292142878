import {
  AlertRouteSlim,
  AlertSlim,
  AlertTimelineItem,
  AlertTimelineItemTypeEnum,
  IncidentSlim,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  AccordionStackedList,
  BadgeSize,
  Icon,
  IconEnum,
  IncidentStatusBadge,
  OrgAwareLink,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import _ from "lodash";
import React from "react";

import { EscalationStackedListItem } from "../../escalations/EscalationStackedListItem";
import { isActorRenderedSpecially } from "./AlertTimeline";

const EscalationStartedItem = (props: { item: AlertTimelineItem }) => {
  if (props.item.content.escalation_started === undefined) {
    throw new Error(
      "Expected escalation_started content for resolved item type",
    );
  }

  const alertRoute = props.item.content.escalation_started.alert_route;

  return (
    <StackedList>
      <EscalationStackedListItem
        className="h-[60px] items-center py-0 flex"
        escalation={props.item.content.escalation_started.escalation}
        hrefInTitle
        description={
          alertRoute ? (
            <span className="text-xs font-medium">
              Via <AlertRouteLink alertRoute={alertRoute} />
            </span>
          ) : null
        }
      />
    </StackedList>
  );
};

const AlertTimelinePendingInIncidentsItem = (props: {
  item: AlertTimelineItem;
}) => {
  const pendingInIncidents = props.item.content.pending_in_incidents;
  if (pendingInIncidents === undefined) {
    throw new Error(
      "Expected pending_in_incidents content for pending_in_incidents item type",
    );
  }

  const incidentsToAlerts = _.chain(pendingInIncidents.incident_alerts)
    .groupBy((ia) => ia.incident.id)
    .map(
      (
        incidentAlerts,
      ): {
        incident: IncidentSlim;
        alertRoute: AlertRouteSlim | undefined;
        alerts: AlertSlim[];
      } => {
        return {
          incident: incidentAlerts[0].incident,
          alertRoute: pendingInIncidents.alert_route,
          alerts: incidentAlerts.flatMap((ia) => ia.alert),
        };
      },
    )
    .value();

  const incidentAlertsByIncident = _.groupBy(
    incidentsToAlerts,
    ({ incident }) => incident.id,
  );
  const accordionItems = incidentsToAlerts.map(
    ({ incident }): { id: string; name: string } => {
      return {
        name: incident.name,
        id: incident.id,
      };
    },
  );

  return (
    <AccordionStackedList
      items={accordionItems}
      hideChevron={({ id }) =>
        incidentAlertsByIncident[id][0].alerts.length === 0
      }
      expandAll
      renderRow={({ id }) => {
        // There'll always be one, as we've grouped by
        const incident = incidentAlertsByIncident[id][0].incident;
        if (!incident) {
          return null;
        }

        return (
          <StackedListItem
            className={"items-center py-0 flex"}
            title={
              <OrgAwareLink
                className="font-medium hover:underline"
                to={`/incidents/${incident.id}`}
              >{`${incident.reference} ${incident.name}`}</OrgAwareLink>
            }
            noPadding
            descriptionClassName={"!my-0"}
            description={
              <span className="text-xs font-medium">
                Via{" "}
                <AlertRouteLink alertRoute={pendingInIncidents.alert_route} />
              </span>
            }
            iconNode={
              incident?.status_category && (
                <IncidentStatusBadge
                  size={BadgeSize.Large}
                  iconOnly
                  naked
                  status={{
                    name: "",
                    category:
                      incident?.status_category as unknown as IncidentStatusCategoryEnum,
                  }}
                />
              )
            }
          />
        );
      }}
      renderAccordion={({ id }) => {
        const alertRoute = incidentAlertsByIncident[id][0].alertRoute;
        const alerts = incidentAlertsByIncident[id].flatMap((ia) => ia.alerts);

        if (alerts.length === 0) {
          return null;
        }

        const maxAlertsToShow = 3;
        const alertsToShow = alerts.slice(0, maxAlertsToShow);
        const remainingAlerts = alerts.length - maxAlertsToShow;

        return (
          <div className={"flex flex-col"}>
            {alertRoute && (
              <div className={"text-sm text-content-secondary mb-3"}>
                Your grouping rules matched:
              </div>
            )}
            <StackedList>
              {alertsToShow.map((alert: AlertSlim) => {
                const config = ALERT_SOURCE_TYPE_CONFIGS[alert.source_type];

                return (
                  <StackedListItem
                    title={alert.title}
                    key={alert.id}
                    descriptionClassName={"!my-0"}
                    className={"py-3 px-4"}
                    iconNode={
                      <Icon id={config?.icon} className={config.className} />
                    }
                    noPadding
                    rowHref={`/alerts/${alert.id}/details`}
                  />
                );
              })}
            </StackedList>

            {remainingAlerts > 0 && (
              <div className={"text-sm text-content-secondary mt-3"}>
                and {remainingAlerts} more alert{remainingAlerts > 1 ? "s" : ""}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

const AlertRouteLink = ({ alertRoute }: { alertRoute: AlertRouteSlim }) => {
  return (
    <OrgAwareLink
      to={`/alerts/routes/${alertRoute.id}/edit`}
      className={"hover:text-content-primary"}
    >
      {alertRoute.name}
    </OrgAwareLink>
  );
};

const MergedIntoIncidentItem = (props: { item: AlertTimelineItem }) => {
  const mergedIntoIncident = props.item.content.merged_into_incident;
  if (mergedIntoIncident === undefined) {
    throw new Error(
      "Expected merged_into_incident content for merged_into_incident item type",
    );
  }

  const incident = mergedIntoIncident.incident;

  return <IncidentRow incident={incident} />;
};

const AlertTimelineCreatedIncidentItem = (props: {
  item: AlertTimelineItem;
}) => {
  const createdIncident = props.item.content.created_incident;
  if (createdIncident === undefined) {
    throw new Error(
      "Expected created_incident content for created_incident item type",
    );
  }

  const alertRoute = createdIncident.alert_route;

  let shouldShowAlertRoute = true;
  if (props.item.actor) {
    shouldShowAlertRoute = isActorRenderedSpecially(props.item.actor);
  }

  return (
    <IncidentRow
      incident={createdIncident.incident}
      // Only show "Via an alert route" if it wasn't a user
      // that created it
      alertRoute={shouldShowAlertRoute ? alertRoute : undefined}
    />
  );
};

const IncidentRow = ({
  incident,
  alertRoute,
}: {
  incident: IncidentSlim;
  alertRoute?: AlertRouteSlim;
}) => {
  return (
    <StackedList>
      <StackedListItem
        className="h-[60px] items-center py-0 flex"
        title={
          <OrgAwareLink
            className="font-medium hover:underline"
            to={`/incidents/${incident.id}`}
          >{`${incident.reference} ${incident.name}`}</OrgAwareLink>
        }
        description={
          alertRoute ? (
            <span className="text-xs font-medium">
              Via <AlertRouteLink alertRoute={alertRoute} />
            </span>
          ) : null
        }
        iconNode={
          incident?.status_category && (
            <IncidentStatusBadge
              size={BadgeSize.Large}
              iconOnly
              naked
              status={{
                name: "",
                category:
                  incident?.status_category as unknown as IncidentStatusCategoryEnum,
              }}
            />
          )
        }
      />
    </StackedList>
  );
};

export const AlertTimelineItemDisplayInfo: Record<
  AlertTimelineItemTypeEnum,
  {
    icon: IconEnum;
    color: ColorPaletteEnum;
    Component: React.ComponentType<{
      item: AlertTimelineItem;
    }> | null;
  }
> = {
  [AlertTimelineItemTypeEnum.AlertCreated]: {
    icon: IconEnum.Alert,
    color: ColorPaletteEnum.Red,
    Component: null,
  },
  [AlertTimelineItemTypeEnum.AlertResolved]: {
    icon: IconEnum.Tick,
    color: ColorPaletteEnum.Green,
    Component: null,
  },
  [AlertTimelineItemTypeEnum.CreatedIncident]: {
    icon: IconEnum.Incident,
    color: ColorPaletteEnum.Red,
    Component: AlertTimelineCreatedIncidentItem,
  },
  [AlertTimelineItemTypeEnum.EscalationDeferred]: {
    icon: IconEnum.Clock,
    color: ColorPaletteEnum.Slate,
    Component: null,
  },
  [AlertTimelineItemTypeEnum.EscalationStarted]: {
    icon: IconEnum.Escalate,
    color: ColorPaletteEnum.Yellow,
    Component: EscalationStartedItem,
  },
  [AlertTimelineItemTypeEnum.MarkedRelatedToIncident]: {
    icon: IconEnum.Folder,
    color: ColorPaletteEnum.Blue,
    Component: null,
  },
  [AlertTimelineItemTypeEnum.MergedIntoIncident]: {
    icon: IconEnum.Merge,
    color: ColorPaletteEnum.Blue,
    Component: MergedIntoIncidentItem,
  },
  [AlertTimelineItemTypeEnum.PendingInIncidents]: {
    icon: IconEnum.FolderOpen,
    color: ColorPaletteEnum.Blue,
    Component: AlertTimelinePendingInIncidentsItem,
  },
  [AlertTimelineItemTypeEnum.AutoUnrelatedAfterIncidentDeclined]: {
    icon: IconEnum.FolderXMark,
    color: ColorPaletteEnum.Blue,
    Component: null,
  },
  [AlertTimelineItemTypeEnum.MarkedUnrelatedToIncident]: {
    icon: IconEnum.FolderXMark,
    color: ColorPaletteEnum.Blue,
    Component: null,
  },
};
