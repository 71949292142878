import { IntegrationConfigFor } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { groupBy, sortBy } from "lodash";
import {
  CatalogType,
  IntegrationSettingsProviderEnum as IntegrationProvider,
} from "src/contexts/ClientContext";

export const getCatalogTypeOptionGroups = (catalogTypes: CatalogType[]) => {
  // Array the types so we always display the custom types first, then the
  // synced types in alphabetical order.
  const typeGroups = groupBy(
    catalogTypes,
    (type) => type.required_integration || "custom",
  );
  const typeGroupKeys = sortBy(Object.keys(typeGroups), (key) =>
    key === "custom" ? "_custom" : key,
  );

  return typeGroupKeys.map((groupKey) => ({
    label:
      groupKey === "custom"
        ? "Custom"
        : IntegrationConfigFor(groupKey as IntegrationProvider)?.label ??
          groupKey,
    options: typeGroups[groupKey].map(({ id, name, icon, color }) => ({
      value: id,
      label: name,
      icon: icon,
      color: color as unknown as ColorPaletteEnum,
    })),
  }));
};
