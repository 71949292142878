/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleAndPayConfig,
    ScheduleAndPayConfigFromJSON,
    ScheduleAndPayConfigFromJSONTyped,
    ScheduleAndPayConfigToJSON,
} from './ScheduleAndPayConfig';

/**
 * 
 * @export
 * @interface SchedulesCreateReportRequestBody
 */
export interface SchedulesCreateReportRequestBody {
    /**
     * Last date (YYYY-MM-DD form) this report should include shifts
     * @type {string}
     * @memberof SchedulesCreateReportRequestBody
     */
    end_date: string;
    /**
     * Name of the report, given when published
     * @type {string}
     * @memberof SchedulesCreateReportRequestBody
     */
    name: string;
    /**
     * List of schedule IDs to generate a report for
     * @type {Array<ScheduleAndPayConfig>}
     * @memberof SchedulesCreateReportRequestBody
     */
    schedules: Array<ScheduleAndPayConfig>;
    /**
     * First date (YYYY-MM-DD form) this report will include shifts from
     * @type {string}
     * @memberof SchedulesCreateReportRequestBody
     */
    start_date: string;
}

export function SchedulesCreateReportRequestBodyFromJSON(json: any): SchedulesCreateReportRequestBody {
    return SchedulesCreateReportRequestBodyFromJSONTyped(json, false);
}

export function SchedulesCreateReportRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateReportRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_date': json['end_date'],
        'name': json['name'],
        'schedules': ((json['schedules'] as Array<any>).map(ScheduleAndPayConfigFromJSON)),
        'start_date': json['start_date'],
    };
}

export function SchedulesCreateReportRequestBodyToJSON(value?: SchedulesCreateReportRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_date': value.end_date,
        'name': value.name,
        'schedules': ((value.schedules as Array<any>).map(ScheduleAndPayConfigToJSON)),
        'start_date': value.start_date,
    };
}

