import type { SVGProps } from "react";
import * as React from "react";
const SvgArchive = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 3H3.75C2.785 3 2 3.785 2 4.75v1.5c0 .879.654 1.602 1.5 1.725v6.275A2.752 2.752 0 0 0 6.25 17h7.5a2.752 2.752 0 0 0 2.75-2.75V7.975A1.747 1.747 0 0 0 18 6.25v-1.5C18 3.785 17.215 3 16.25 3ZM12 11H8a.75.75 0 0 1 0-1.5h4a.75.75 0 0 1 0 1.5Zm4.5-4.75a.25.25 0 0 1-.25.25H3.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h12.5a.25.25 0 0 1 .25.25v1.5Z"
    />
  </svg>
);
export default SvgArchive;
