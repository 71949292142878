import type { SVGProps } from "react";
import * as React from "react";
const SvgFollowUps = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m7.494 4.491-.946-.315-.316-.947c-.102-.306-.609-.306-.711 0l-.316.947-.946.315a.375.375 0 0 0 0 .712l.946.315.316.947a.374.374 0 0 0 .71 0l.316-.947.946-.315a.375.375 0 0 0 .001-.712ZM2.25 7a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
    />
    <path
      fill="currentColor"
      d="m17.586 10.003-6.58-4.991a1.245 1.245 0 0 0-1.312-.125A1.241 1.241 0 0 0 9 6.007V8H3.75C2.785 8 2 8.785 2 9.75v2.432c.37-.417.904-.682 1.5-.682.868 0 1.631.534 1.896 1.33l.186.554.552.184c.272.09.498.249.701.433h2.166v1.993a1.254 1.254 0 0 0 1.251 1.251c.267 0 .531-.086.754-.255l6.581-4.994a1.24 1.24 0 0 0 .494-.996c0-.395-.18-.757-.494-.996l-.001-.001Z"
    />
    <path
      fill="currentColor"
      d="m5.659 14.99-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.421a.5.5 0 0 0 0 .948l1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0-.001-.948Z"
    />
  </svg>
);
export default SvgFollowUps;
