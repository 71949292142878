export function toSentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.substring(1);
}

// A hack from the "thanks I hate it" school of HTML: strip character entities
// like &gt; and &amp; from strings by... creating a textarea element,
// assigning the string as its inner HTML, and then getting the value.
// I hate that this works, and I hate even more that this is the only sensible way
// of removing HTML character entities from strings
export function stripCharacterEntities(str: string): string {
  const el = document.createElement("textarea");
  el.innerHTML = str;
  return el.value;
}
