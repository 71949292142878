import { Route, Routes } from "react-router";

import { PostIncidentFlowViewPage } from "./PostIncidentFlowComponent";
import { PostIncidentFlowCreateModal } from "./PostIncidentFlowCreateModal";
import { PostIncidentFlowWrapper } from "./PostIncidentFlowWrapper";
import { PostIncidentTaskCreateEditPage } from "./task-create-edit/PostIncidentTaskCreateEditPage";

export const PostIncidentFlowRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route
        path="create"
        element={
          <>
            <PostIncidentFlowCreateModal />
            <PostIncidentFlowWrapper />
          </>
        }
      />
      <Route path=":id" element={<PostIncidentFlowViewPage />} />
      <Route
        path=":status_id/create"
        element={<PostIncidentTaskCreateEditPage />}
      />
      <Route
        path=":status_id/:task_id/edit"
        element={<PostIncidentTaskCreateEditPage />}
      />
      <Route path="" element={<PostIncidentFlowWrapper />} />
    </Routes>
  );
};
