import type { SVGProps } from "react";
import * as React from "react";
const SvgSalesforce = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#00A1E0"
      d="M8.669 5.818a2.876 2.876 0 0 1 4.49.573 3.4 3.4 0 0 1 1.401-.288C16.461 6.103 18 7.59 18 9.42c0 1.828-1.541 3.314-3.45 3.314-.23 0-.459-.027-.68-.067-.43.744-1.25 1.24-2.2 1.24a2.77 2.77 0 0 1-1.1-.238c-.44.98-1.46 1.664-2.65 1.664-1.24 0-2.3-.741-2.701-1.788-.18.037-.36.047-.55.047C3.202 13.592 2 12.45 2 11.02c0-.953.539-1.782 1.338-2.228a2.773 2.773 0 0 1-.26-1.173c0-1.638 1.401-2.952 3.102-2.952A3.19 3.19 0 0 1 8.659 5.81l.01.008Z"
    />
  </svg>
);
export default SvgSalesforce;
