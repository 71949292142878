/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentTypeApplicableFields,
    IncidentTypeApplicableFieldsFromJSON,
    IncidentTypeApplicableFieldsToJSON,
    IncidentTypesBuildApplicableFieldsRequestBody,
    IncidentTypesBuildApplicableFieldsRequestBodyFromJSON,
    IncidentTypesBuildApplicableFieldsRequestBodyToJSON,
    IncidentTypesCreateRequestBody,
    IncidentTypesCreateRequestBodyFromJSON,
    IncidentTypesCreateRequestBodyToJSON,
    IncidentTypesCreateResponseBody,
    IncidentTypesCreateResponseBodyFromJSON,
    IncidentTypesCreateResponseBodyToJSON,
    IncidentTypesListResponseBody,
    IncidentTypesListResponseBodyFromJSON,
    IncidentTypesListResponseBodyToJSON,
    IncidentTypesSetDefaultResponseBody,
    IncidentTypesSetDefaultResponseBodyFromJSON,
    IncidentTypesSetDefaultResponseBodyToJSON,
    IncidentTypesShowResponseBody,
    IncidentTypesShowResponseBodyFromJSON,
    IncidentTypesShowResponseBodyToJSON,
    IncidentTypesUpdateOverrideIncidentLifecycleRequestBody,
    IncidentTypesUpdateOverrideIncidentLifecycleRequestBodyFromJSON,
    IncidentTypesUpdateOverrideIncidentLifecycleRequestBodyToJSON,
    IncidentTypesUpdateOverrideIncidentLifecycleResponseBody,
    IncidentTypesUpdateOverrideIncidentLifecycleResponseBodyFromJSON,
    IncidentTypesUpdateOverrideIncidentLifecycleResponseBodyToJSON,
    IncidentTypesUpdateRanksRequestBody,
    IncidentTypesUpdateRanksRequestBodyFromJSON,
    IncidentTypesUpdateRanksRequestBodyToJSON,
    IncidentTypesUpdateRanksResponseBody,
    IncidentTypesUpdateRanksResponseBodyFromJSON,
    IncidentTypesUpdateRanksResponseBodyToJSON,
    IncidentTypesUpdateRequestBody,
    IncidentTypesUpdateRequestBodyFromJSON,
    IncidentTypesUpdateRequestBodyToJSON,
    IncidentTypesUpdateResponseBody,
    IncidentTypesUpdateResponseBodyFromJSON,
    IncidentTypesUpdateResponseBodyToJSON,
} from '../models';

export interface IncidentTypesBuildApplicableFieldsRequest {
    buildApplicableFieldsRequestBody: IncidentTypesBuildApplicableFieldsRequestBody;
}

export interface IncidentTypesCreateRequest {
    createRequestBody: IncidentTypesCreateRequestBody;
}

export interface IncidentTypesDestroyRequest {
    id: string;
}

export interface IncidentTypesSetDefaultRequest {
    id: string;
}

export interface IncidentTypesShowRequest {
    id: string;
}

export interface IncidentTypesUpdateRequest {
    id: string;
    updateRequestBody: IncidentTypesUpdateRequestBody;
}

export interface IncidentTypesUpdateOverrideIncidentLifecycleRequest {
    id: string;
    updateOverrideIncidentLifecycleRequestBody: IncidentTypesUpdateOverrideIncidentLifecycleRequestBody;
}

export interface IncidentTypesUpdateRanksRequest {
    updateRanksRequestBody: IncidentTypesUpdateRanksRequestBody;
}

/**
 * 
 */
export class IncidentTypesApi extends runtime.BaseAPI {

    /**
     * Get applicable roles and fields for an incident type
     * BuildApplicableFields Incident Types
     */
    async incidentTypesBuildApplicableFieldsRaw(requestParameters: IncidentTypesBuildApplicableFieldsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypeApplicableFields>> {
        if (requestParameters.buildApplicableFieldsRequestBody === null || requestParameters.buildApplicableFieldsRequestBody === undefined) {
            throw new runtime.RequiredError('buildApplicableFieldsRequestBody','Required parameter requestParameters.buildApplicableFieldsRequestBody was null or undefined when calling incidentTypesBuildApplicableFields.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_types/applicable_fields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTypesBuildApplicableFieldsRequestBodyToJSON(requestParameters.buildApplicableFieldsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypeApplicableFieldsFromJSON(jsonValue));
    }

    /**
     * Get applicable roles and fields for an incident type
     * BuildApplicableFields Incident Types
     */
    async incidentTypesBuildApplicableFields(requestParameters: IncidentTypesBuildApplicableFieldsRequest, initOverrides?: RequestInit): Promise<IncidentTypeApplicableFields> {
        const response = await this.incidentTypesBuildApplicableFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an Incident Type
     * Create Incident Types
     */
    async incidentTypesCreateRaw(requestParameters: IncidentTypesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentTypesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTypesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates an Incident Type
     * Create Incident Types
     */
    async incidentTypesCreate(requestParameters: IncidentTypesCreateRequest, initOverrides?: RequestInit): Promise<IncidentTypesCreateResponseBody> {
        const response = await this.incidentTypesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing type
     * Destroy Incident Types
     */
    async incidentTypesDestroyRaw(requestParameters: IncidentTypesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTypesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing type
     * Destroy Incident Types
     */
    async incidentTypesDestroy(requestParameters: IncidentTypesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentTypesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List incident types
     * List Incident Types
     */
    async incidentTypesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident types
     * List Incident Types
     */
    async incidentTypesList(initOverrides?: RequestInit): Promise<IncidentTypesListResponseBody> {
        const response = await this.incidentTypesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set an incident type as the default
     * SetDefault Incident Types
     */
    async incidentTypesSetDefaultRaw(requestParameters: IncidentTypesSetDefaultRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypesSetDefaultResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTypesSetDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_types/{id}/actions/make_default`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypesSetDefaultResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set an incident type as the default
     * SetDefault Incident Types
     */
    async incidentTypesSetDefault(requestParameters: IncidentTypesSetDefaultRequest, initOverrides?: RequestInit): Promise<IncidentTypesSetDefaultResponseBody> {
        const response = await this.incidentTypesSetDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single incident type
     * Show Incident Types
     */
    async incidentTypesShowRaw(requestParameters: IncidentTypesShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypesShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTypesShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single incident type
     * Show Incident Types
     */
    async incidentTypesShow(requestParameters: IncidentTypesShowRequest, initOverrides?: RequestInit): Promise<IncidentTypesShowResponseBody> {
        const response = await this.incidentTypesShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing incident type
     * Update Incident Types
     */
    async incidentTypesUpdateRaw(requestParameters: IncidentTypesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTypesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling incidentTypesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTypesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing incident type
     * Update Incident Types
     */
    async incidentTypesUpdate(requestParameters: IncidentTypesUpdateRequest, initOverrides?: RequestInit): Promise<IncidentTypesUpdateResponseBody> {
        const response = await this.incidentTypesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the lifecycle associated with this incident type
     * UpdateOverrideIncidentLifecycle Incident Types
     */
    async incidentTypesUpdateOverrideIncidentLifecycleRaw(requestParameters: IncidentTypesUpdateOverrideIncidentLifecycleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypesUpdateOverrideIncidentLifecycleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentTypesUpdateOverrideIncidentLifecycle.');
        }

        if (requestParameters.updateOverrideIncidentLifecycleRequestBody === null || requestParameters.updateOverrideIncidentLifecycleRequestBody === undefined) {
            throw new runtime.RequiredError('updateOverrideIncidentLifecycleRequestBody','Required parameter requestParameters.updateOverrideIncidentLifecycleRequestBody was null or undefined when calling incidentTypesUpdateOverrideIncidentLifecycle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_types/{id}/actions/update_override_incident_lifecycle`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTypesUpdateOverrideIncidentLifecycleRequestBodyToJSON(requestParameters.updateOverrideIncidentLifecycleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypesUpdateOverrideIncidentLifecycleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the lifecycle associated with this incident type
     * UpdateOverrideIncidentLifecycle Incident Types
     */
    async incidentTypesUpdateOverrideIncidentLifecycle(requestParameters: IncidentTypesUpdateOverrideIncidentLifecycleRequest, initOverrides?: RequestInit): Promise<IncidentTypesUpdateOverrideIncidentLifecycleResponseBody> {
        const response = await this.incidentTypesUpdateOverrideIncidentLifecycleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing incident types
     * UpdateRanks Incident Types
     */
    async incidentTypesUpdateRanksRaw(requestParameters: IncidentTypesUpdateRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTypesUpdateRanksResponseBody>> {
        if (requestParameters.updateRanksRequestBody === null || requestParameters.updateRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateRanksRequestBody','Required parameter requestParameters.updateRanksRequestBody was null or undefined when calling incidentTypesUpdateRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_types/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTypesUpdateRanksRequestBodyToJSON(requestParameters.updateRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTypesUpdateRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing incident types
     * UpdateRanks Incident Types
     */
    async incidentTypesUpdateRanks(requestParameters: IncidentTypesUpdateRanksRequest, initOverrides?: RequestInit): Promise<IncidentTypesUpdateRanksResponseBody> {
        const response = await this.incidentTypesUpdateRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
