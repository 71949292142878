import {
  FollowUp,
  FollowUpStatusEnum,
  Incident,
  IncidentVisibilityEnum,
  RemindersCreateRequestBodyReminderTypeEnum,
  SuggestedFollowUp,
} from "@incident-io/api";
import {
  FollowUpCompleteModal,
  FollowUpCreateModal,
  FollowUpDeleteModal,
  FollowUpEditModal,
} from "@incident-shared/follow-ups";
import { FollowUpNotDoingModal } from "@incident-shared/follow-ups/FollowUpNotDoingModal";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  ExportToProviderModal,
  GetInstalledIssueTrackers,
  IssueTrackerProviderEnum,
} from "@incident-shared/issue-trackers";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { PolicyViolationNotification } from "@incident-shared/policy/PolicyViolationNotification";
import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import { SendRemindersModal } from "src/components/post-incident/common/SendRemindersModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { usePrimaryCommsPlatformFeatures } from "src/hooks/usePrimaryCommsPlatform";

import { useFollowUps, usePoliciesAndViolations } from "../hooks";
import { IncidentStackedList } from "../stacked-lists/Components";
import { useSkip } from "./hooks";

export type Props = {
  incident: Incident;
  followUps: FollowUp[];
  followUpSuggestions?: SuggestedFollowUp[];
  editingFollowUp: FollowUp | undefined;
};

export const FollowUpsList = ({
  incident,
  followUps = [],
  editingFollowUp,
  followUpSuggestions = [],
}: Props) => {
  const navigate = useOrgAwareNavigate();
  const { platformHasReminders } = usePrimaryCommsPlatformFeatures();

  // Modals
  const [isDeletingFollowUpModalOpen, setIsDeletingFollowUpModalOpen] =
    useState<FollowUp | null>(null);
  const [isNotDoingFollowUpModalOpen, setIsNotDoingFollowUpModalOpen] =
    useState<FollowUp | null>(null);
  const [isCompletingFollowUpModalOpen, setIsCompletingFollowUpModalOpen] =
    useState<FollowUp | null>(null);
  const [isSendReminderModalOpen, setIsSendReminderModalOpen] =
    useState<FollowUp | null>(null);
  const [exportToProvider, setExportToProvider] = useState<{
    provider: IssueTrackerProviderEnum;
    followUp: FollowUp;
  } | null>(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    SuggestedFollowUp | undefined
  >(undefined);

  const { policies, policyViolations, refetchPolicyViolations } =
    usePoliciesAndViolations(incident.id);

  const { skipFollowUpSuggestion, isSkipping } = useSkip(incident.id);

  const { mutate: refetchFollowUps } = useFollowUps(incident.id);

  const refetch = async () => {
    await Promise.all([refetchFollowUps(), refetchPolicyViolations()]);
  };

  const { integrations } = useIntegrations();
  const issueTrackerIntegrations = GetInstalledIssueTrackers(
    integrations || [],
  );

  if (followUps == null) {
    return <Loader />;
  }

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  return (
    <>
      <IncidentStackedList.Section loading={false}>
        {followUpSuggestions.map((suggestion) => (
          <IncidentStackedList.Item
            key={suggestion.id}
            iconNode={
              <Icon id={IconEnum.SparklesColoured} size={IconSize.Small} />
            }
            title={suggestion.title}
            accessoryOne={
              <Button
                analyticsTrackingId={"follow-up-suggestion-skip"}
                disabled={isSkipping}
                onClick={() => skipFollowUpSuggestion({ id: suggestion.id })}
                size={BadgeSize.Small}
              >
                Skip
              </Button>
            }
            accessoryTwo={
              <Button
                analyticsTrackingId={"follow-up-suggestion-add"}
                disabled={isSkipping}
                onClick={() => setSelectedSuggestion(suggestion)}
                size={BadgeSize.Small}
              >
                Accept
              </Button>
            }
          />
        ))}
        {followUps.map((followUp) => {
          const policyViolation =
            policyViolations &&
            policyViolations.find(
              (violation) => violation.resource_id === followUp.id,
            );

          const violatedPolicy =
            policyViolation &&
            policies?.find((policy) => policy.id === policyViolation.policy_id);

          return (
            <IncidentStackedList.Item
              key={followUp.id}
              iconNode={<FollowUpCheckmark followUp={followUp} />}
              title={
                <div
                  className={
                    followUp.status === FollowUpStatusEnum.NotDoing
                      ? "text-content-tertiary"
                      : ""
                  }
                >
                  {followUp.title}
                </div>
              }
              onClick={() => {
                navigate(
                  {
                    pathname: location.pathname,
                    search: `?tab=post-incident&follow_up_id=${followUp.id}`,
                  },
                  {
                    replace: true,
                  },
                );
              }}
              accessoryOne={
                <div className="flex items-center gap-2">
                  {policyViolation && violatedPolicy && (
                    <PolicyViolationNotification
                      resourceName="follow-up"
                      policy={violatedPolicy}
                      level={policyViolation.level}
                      daysUntil={policyViolation.days}
                      iconOnly
                      violationID={policyViolation.id}
                    />
                  )}
                  <FollowUpPriorityBadge followUp={followUp} />
                  <FollowUpExternalIssueBadge followUp={followUp} />
                  {followUp.assignee && (
                    <Tooltip content={followUp.assignee.name}>
                      <Avatar
                        size={IconSize.Medium}
                        url={followUp.assignee.avatar_url}
                        name={followUp.assignee.name}
                        className="mr-2"
                      />
                    </Tooltip>
                  )}
                </div>
              }
              accessoryTwo={
                <DropdownMenu
                  triggerButtonTheme={ButtonTheme.Naked}
                  triggerIconSize={IconSize.Small}
                  triggerIcon={IconEnum.DotsVerticalNopad}
                  analyticsTrackingId="action-more-options"
                  screenReaderText="More options"
                >
                  <DropdownMenuItem
                    analyticsTrackingId="follow-ups-list-edit"
                    label="Edit"
                    onSelect={() =>
                      navigate({
                        pathname: location.pathname,
                        search: `?tab=follow-ups&follow_up_id=${followUp.id}`,
                      })
                    }
                  />
                  <DropdownMenuItem
                    analyticsTrackingId="follow-ups-list-delete-modal-open"
                    label="Delete"
                    onSelect={() => setIsDeletingFollowUpModalOpen(followUp)}
                  />
                  <DropdownMenuItem
                    analyticsTrackingId="follow-ups-list-mark-as-not-doing"
                    label="Mark as not doing"
                    disabled={
                      followUp.status === FollowUpStatusEnum.NotDoing ||
                      !!followUp.external_issue_reference
                    }
                    onSelect={() => setIsNotDoingFollowUpModalOpen(followUp)}
                  >
                    <span>
                      Mark as <span className="font-semibold">Not doing</span>
                    </span>
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    analyticsTrackingId="follow-ups-list-mark-as-complete"
                    label="Mark as complete"
                    disabled={
                      followUp.status === FollowUpStatusEnum.Completed ||
                      !!followUp.external_issue_reference
                    }
                    onSelect={() => setIsCompletingFollowUpModalOpen(followUp)}
                  >
                    <span>
                      Mark as <span className="font-semibold">Complete</span>
                    </span>
                  </DropdownMenuItem>

                  {platformHasReminders && (
                    <DropdownMenuItem
                      analyticsTrackingId="follow-ups-list-remind"
                      disabled={
                        followUp.status === FollowUpStatusEnum.Completed ||
                        followUp.status === FollowUpStatusEnum.NotDoing
                      }
                      onSelect={() => setIsSendReminderModalOpen(followUp)}
                      label={"Send a reminder"}
                    />
                  )}

                  {!followUp.external_issue_reference &&
                    _.sortBy(issueTrackerIntegrations, (i) => i.provider).map(
                      (integration) => {
                        return (
                          <DropdownMenuItem
                            analyticsTrackingId="follow-ups-list-export-modal-open"
                            analyticsTrackingMetadata={{
                              provider: integration.provider,
                            }}
                            key={integration.provider}
                            onSelect={() =>
                              setExportToProvider({
                                provider: integration.provider,
                                followUp,
                              })
                            }
                            icon={
                              IntegrationConfigFor(integration.provider).icon
                            }
                            label={`${
                              integration.provider ===
                              IssueTrackerProviderEnum.Shortcut
                                ? `Create`
                                : `Create or link`
                            } a ${
                              IntegrationConfigFor(integration.provider).label
                            } issue`}
                            disabled={integration.disabled}
                            tooltipContent={
                              integration.disabled
                                ? `There is a problem with your connection to ${
                                    IntegrationConfigFor(integration.provider)
                                      .label
                                  }`
                                : undefined
                            }
                          />
                        );
                      },
                    )}
                </DropdownMenu>
              }
            />
          );
        })}
      </IncidentStackedList.Section>
      {/* Modals */}
      {isNotDoingFollowUpModalOpen && (
        <FollowUpNotDoingModal
          followUp={isNotDoingFollowUpModalOpen}
          onClose={() => setIsNotDoingFollowUpModalOpen(null)}
        />
      )}
      {isCompletingFollowUpModalOpen && (
        <FollowUpCompleteModal
          followUp={isCompletingFollowUpModalOpen}
          onClose={() => setIsCompletingFollowUpModalOpen(null)}
        />
      )}
      {isDeletingFollowUpModalOpen && (
        <FollowUpDeleteModal
          followUp={isDeletingFollowUpModalOpen}
          onClose={() => setIsDeletingFollowUpModalOpen(null)}
        />
      )}
      {isSendReminderModalOpen && (
        <SendRemindersModal
          selectedResourceIDs={[isSendReminderModalOpen.id]}
          // You'll only be able to click "send reminder" if the follow-up is open
          numResourcesToIgnore={0}
          resourceType={RemindersCreateRequestBodyReminderTypeEnum.FollowUp}
          onClose={() => {
            setIsSendReminderModalOpen(null);
          }}
          onCancel={() => setIsSendReminderModalOpen(null)}
        />
      )}
      {editingFollowUp && (
        <FollowUpEditModal
          isPrivateIncident={isPrivate}
          followUp={editingFollowUp}
          onClose={() =>
            navigate(
              {
                pathname: location.pathname,
                search: `?tab=post-incident`,
              },
              {
                replace: true,
              },
            )
          }
        />
      )}
      {exportToProvider != null && incident != null && (
        <ExportToProviderModal
          provider={exportToProvider.provider}
          incident={incident}
          followUp={exportToProvider.followUp}
          onClose={() => setExportToProvider(null)}
          isPrivate={isPrivate}
          updateCallback={refetch}
        />
      )}
      {selectedSuggestion && (
        <FollowUpCreateModal
          incidentId={incident.id}
          onClose={() => {
            setSelectedSuggestion(undefined);
          }}
          followUpSuggestion={selectedSuggestion}
          isPrivateIncident={
            incident.visibility === IncidentVisibilityEnum.Private
          }
        />
      )}
    </>
  );
};

const FollowUpExternalIssueBadge = ({ followUp }: { followUp: FollowUp }) => {
  const { external_issue_reference } = followUp;

  if (!external_issue_reference) {
    return null;
  }

  const config = IntegrationConfigFor(external_issue_reference.provider);

  return (
    <a
      href={external_issue_reference.issue_permalink}
      target="_blank"
      rel="noreferrer"
      className=" flex items-center"
    >
      <Badge
        theme={BadgeTheme.Tertiary}
        size={BadgeSize.ExtraSmall}
        icon={config.icon}
        className="cursor-pointer"
      >
        {external_issue_reference.issue_name}
      </Badge>
    </a>
  );
};

const FollowUpPriorityBadge = ({ followUp }: { followUp: FollowUp }) => {
  const { identity } = useIdentity();
  const canUseFollowUpPriorities = identity?.feature_gates.follow_up_priorities;

  const { priority } = followUp;

  if (!priority || !canUseFollowUpPriorities) {
    return null;
  }

  return (
    <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
      {priority.name}
    </Badge>
  );
};

const FollowUpCheckmark = ({ followUp }: { followUp: FollowUp }) => {
  switch (followUp.status) {
    case FollowUpStatusEnum.Outstanding:
      return <Icon id={IconEnum.FakeCheckbox} size={IconSize.Small} />;
    case FollowUpStatusEnum.Completed:
      return (
        <Icon
          id={IconEnum.FakeCheckboxChecked}
          className="text-green-500"
          size={IconSize.Small}
        />
      );
    case FollowUpStatusEnum.NotDoing:
      return (
        <Icon
          id={IconEnum.FakeCheckboxCross}
          className="text-red-content"
          size={IconSize.Small}
        />
      );
    case FollowUpStatusEnum.Deleted:
      // Doesn't matter, we filter these out
      return <></>;
    default:
      return <Icon id={IconEnum.FakeCheckbox} size={IconSize.Small} />;
  }
};
