import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow";
import { tcx } from "src/utils/tailwind-classes";

import { PathEdge } from "../common/types";

// CustomEdge is a custom edge component used in ReactFlow to render edges between
// nodes.
export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data?: PathEdge;
}) => {
  const [edgePath, labelX, labelY] = generatePath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    data,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          stroke: "#CACACE", // slate-200 - we should make this a CSS variable!
          strokeWidth: "2px",
        }}
      />
      <EdgeLabelRenderer>
        <EdgeLabel
          label={data?.label ?? "Then"}
          conditionResult={data?.conditionResult}
          labelX={labelX}
          labelY={labelY}
        />
      </EdgeLabelRenderer>
    </>
  );
};

const generatePath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data?: PathEdge;
}) => {
  if (data?.label === "End") {
    // We do something special here to generate a fixed-height edge with a label at the end
    return getSmoothStepPath({
      sourceX,
      sourceY,
      targetX: sourceX,
      targetY: sourceY + 60,
      centerY: sourceY + 60,
      borderRadius: 12,
    });
  }

  return getSmoothStepPath({
    sourceX,
    sourceY,
    // Place the horizontal part of the edge near the target: we don't know know
    // how tall the source node is compared to other nodes at the same rank, so we
    // put the center of the edge just above the top of the target, which we do
    // know will be the same as other nodes at that rank.
    //
    // The exception is if this is a simple vertical edge, in which case we want the center to be the center, since:
    // (a) nothing will get in between and screw it up; and
    // (b) it looks weird to have the label off-center.
    centerY: targetX === sourceX ? undefined : targetY - 50,
    targetX,
    targetY,
    borderRadius: 12,
  });
};

const EdgeLabel = ({
  label,
  conditionResult,
  labelX,
  labelY,
}: {
  label: string;
  conditionResult?: "left" | "right";
  labelX: number;
  labelY: number;
}) => {
  return (
    <div
      style={{
        transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
      }}
      className={tcx(
        "absolute",
        "w-fit rounded py-0.5 px-1",
        "nodrag nopan",
        "text-xs font-semibold",
        {
          "bg-slate-100 text-content-primary": conditionResult === undefined,
          "bg-green-500 text-white": conditionResult === "left",
          "bg-red-600 text-white": conditionResult === "right",
        },
      )}
    >
      {label}
    </div>
  );
};
