/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallOnboardingTaskCompletionState
 */
export interface OnCallOnboardingTaskCompletionState {
    /**
     * Whether the task has been completed
     * @type {boolean}
     * @memberof OnCallOnboardingTaskCompletionState
     */
    completed: boolean;
    /**
     * The task to mark as completed
     * @type {string}
     * @memberof OnCallOnboardingTaskCompletionState
     */
    task: OnCallOnboardingTaskCompletionStateTaskEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnCallOnboardingTaskCompletionStateTaskEnum {
    AndroidPushNotificationsAccepted = 'android_push_notifications_accepted',
    AndroidPlatformNameHasSignedIntoApp = 'android_platform_name_has_signed_into_app',
    IosPushNotificationsAccepted = 'ios_push_notifications_accepted',
    IosPlatformNameHasSignedIntoApp = 'ios_platform_name_has_signed_into_app',
    DashboardHasVerifiedPhoneNumber = 'dashboard_has_verified_phone_number',
    DashboardHasDismissedAppInstall = 'dashboard_has_dismissed_app_install'
}

export function OnCallOnboardingTaskCompletionStateFromJSON(json: any): OnCallOnboardingTaskCompletionState {
    return OnCallOnboardingTaskCompletionStateFromJSONTyped(json, false);
}

export function OnCallOnboardingTaskCompletionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallOnboardingTaskCompletionState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed': json['completed'],
        'task': json['task'],
    };
}

export function OnCallOnboardingTaskCompletionStateToJSON(value?: OnCallOnboardingTaskCompletionState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed': value.completed,
        'task': value.task,
    };
}

