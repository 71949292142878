/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageGoLiveRequestBody
 */
export interface StatusPageGoLiveRequestBody {
    /**
     * Whether the page should be hidden from search engines (go live only)
     * @type {boolean}
     * @memberof StatusPageGoLiveRequestBody
     */
    hide_from_search_engines: boolean;
}

export function StatusPageGoLiveRequestBodyFromJSON(json: any): StatusPageGoLiveRequestBody {
    return StatusPageGoLiveRequestBodyFromJSONTyped(json, false);
}

export function StatusPageGoLiveRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageGoLiveRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hide_from_search_engines': json['hide_from_search_engines'],
    };
}

export function StatusPageGoLiveRequestBodyToJSON(value?: StatusPageGoLiveRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hide_from_search_engines': value.hide_from_search_engines,
    };
}

