/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogEntryReference
 */
export interface CatalogEntryReference {
    /**
     * When this entry was archived
     * @type {Date}
     * @memberof CatalogEntryReference
     */
    archived_at?: Date;
    /**
     * ID of this catalog entry
     * @type {string}
     * @memberof CatalogEntryReference
     */
    catalog_entry_id: string;
    /**
     * The name of this entry
     * @type {string}
     * @memberof CatalogEntryReference
     */
    catalog_entry_name: string;
    /**
     * ID of this catalog type
     * @type {string}
     * @memberof CatalogEntryReference
     */
    catalog_type_id: string;
}

export function CatalogEntryReferenceFromJSON(json: any): CatalogEntryReference {
    return CatalogEntryReferenceFromJSONTyped(json, false);
}

export function CatalogEntryReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogEntryReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archived_at': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'catalog_entry_id': json['catalog_entry_id'],
        'catalog_entry_name': json['catalog_entry_name'],
        'catalog_type_id': json['catalog_type_id'],
    };
}

export function CatalogEntryReferenceToJSON(value?: CatalogEntryReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archived_at': value.archived_at === undefined ? undefined : (value.archived_at.toISOString()),
        'catalog_entry_id': value.catalog_entry_id,
        'catalog_entry_name': value.catalog_entry_name,
        'catalog_type_id': value.catalog_type_id,
    };
}

