import { Product } from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import { ContentBox, Heading, Toggle } from "@incident-ui";
import {
  UserPreferences,
  UserPreferencesUpdateScheduledEmailPreferencesRequestBody,
} from "src/contexts/ClientContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPIMutation } from "src/utils/swr";

type InsightsEmailFormData =
  UserPreferencesUpdateScheduledEmailPreferencesRequestBody;

export const InsightsEmailForm = ({
  userPreferences,
}: {
  userPreferences: UserPreferences | null;
}) => {
  return (
    <ContentBox className={"p-6"}>
      {userPreferences && (
        <InsightsEmailFormContent userPreferences={userPreferences} />
      )}
    </ContentBox>
  );
};

const InsightsEmailFormContent = ({
  userPreferences,
}: {
  userPreferences: UserPreferences;
}) => {
  const { trigger: saveState } = useAPIMutation(
    "userPreferencesShow",
    undefined,
    async (
      apiClient,
      data: UserPreferencesUpdateScheduledEmailPreferencesRequestBody,
    ) =>
      await apiClient.userPreferencesUpdateScheduledEmailPreferences({
        updateScheduledEmailPreferencesRequestBody: data,
      }),
  );
  const { hasResponse } = useProductAccess();

  const { setState, state, hasSaved, saving } =
    useOptimisticAutoSave<InsightsEmailFormData>({
      initialState: userPreferences,
      saveState,
    });

  return (
    <>
      <div className="flex justify-between items-start space-x-4">
        <div className="grow max-w-2xl">
          <Heading level={2} id={"unread-notifications"}>
            Monthly insights
          </Heading>
          <p className={"mt-1 text-sm text-slate-700 mb-2"}>
            Receive a monthly round-up highlighting key insights for your
            organisation, such as number of incidents and personal statistics.
          </p>
        </div>
        <div className={""}>
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
      </div>
      <div className="mt-2">
        <Toggle
          id="monthly_insights_email_enabled"
          disabled={!hasResponse}
          isDisabledTooltipContent={
            <ProductRequiredMessage requiredProduct={Product.Response} />
          }
          align="left"
          label="Emails"
          description="Receive insights via Email."
          on={state.monthly_insights_email_enabled}
          onToggle={() =>
            setState({
              ...state,
              monthly_insights_email_enabled:
                !state.monthly_insights_email_enabled,
            })
          }
        />
      </div>
    </>
  );
};
