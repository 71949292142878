/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageMigration
 */
export interface StatusPageMigration {
    /**
     * When this migration completed successfully
     * @type {Date}
     * @memberof StatusPageMigration
     */
    completed_at?: Date;
    /**
     * When this migration failed
     * @type {Date}
     * @memberof StatusPageMigration
     */
    failed_at?: Date;
    /**
     * Unique ID for this migration
     * @type {string}
     * @memberof StatusPageMigration
     */
    id: string;
    /**
     * The amount of work completed
     * @type {number}
     * @memberof StatusPageMigration
     */
    progress_complete?: number;
    /**
     * The amount of work to do for this migration
     * @type {number}
     * @memberof StatusPageMigration
     */
    progress_total?: number;
    /**
     * The ID of the status page that was modified by this migration
     * @type {string}
     * @memberof StatusPageMigration
     */
    status_page_id?: string;
    /**
     * The type of this migration, which defines how this will work
     * @type {string}
     * @memberof StatusPageMigration
     */
    type: StatusPageMigrationTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageMigrationTypeEnum {
    Import = 'import',
    GoLive = 'go_live',
    DemoImport = 'demo_import',
    PublicDemoImport = 'public_demo_import'
}

export function StatusPageMigrationFromJSON(json: any): StatusPageMigration {
    return StatusPageMigrationFromJSONTyped(json, false);
}

export function StatusPageMigrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageMigration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'failed_at': !exists(json, 'failed_at') ? undefined : (new Date(json['failed_at'])),
        'id': json['id'],
        'progress_complete': !exists(json, 'progress_complete') ? undefined : json['progress_complete'],
        'progress_total': !exists(json, 'progress_total') ? undefined : json['progress_total'],
        'status_page_id': !exists(json, 'status_page_id') ? undefined : json['status_page_id'],
        'type': json['type'],
    };
}

export function StatusPageMigrationToJSON(value?: StatusPageMigration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'failed_at': value.failed_at === undefined ? undefined : (value.failed_at.toISOString()),
        'id': value.id,
        'progress_complete': value.progress_complete,
        'progress_total': value.progress_total,
        'status_page_id': value.status_page_id,
        'type': value.type,
    };
}

