/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPreview,
    EscalationPreviewFromJSON,
    EscalationPreviewFromJSONTyped,
    EscalationPreviewToJSON,
} from './EscalationPreview';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';

/**
 * 
 * @export
 * @interface EscalationsListResponseBody
 */
export interface EscalationsListResponseBody {
    /**
     * 
     * @type {Array<EscalationPreview>}
     * @memberof EscalationsListResponseBody
     */
    escalations: Array<EscalationPreview>;
    /**
     * 
     * @type {PaginationMeta}
     * @memberof EscalationsListResponseBody
     */
    pagination_meta: PaginationMeta;
}

export function EscalationsListResponseBodyFromJSON(json: any): EscalationsListResponseBody {
    return EscalationsListResponseBodyFromJSONTyped(json, false);
}

export function EscalationsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalations': ((json['escalations'] as Array<any>).map(EscalationPreviewFromJSON)),
        'pagination_meta': PaginationMetaFromJSON(json['pagination_meta']),
    };
}

export function EscalationsListResponseBodyToJSON(value?: EscalationsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalations': ((value.escalations as Array<any>).map(EscalationPreviewToJSON)),
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
    };
}

