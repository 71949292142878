import { Button } from "@incident-ui";
import React from "react";

// This allows us to deliberately throw an error in a couple of different ways to help
// validate our tooling (primarily sentry + PagerDuty alerting)
export const PingErrorRoute = (): React.ReactElement => {
  const onThrowError = () => {
    throw new Error("someone is testing client errors. Dont panic");
  };

  const foo = {};
  const onCallBrokenMethod = () => {
    //@ts-expect-error this is deliberately broken
    foo.dontexist();
  };

  return (
    <div>
      <Button analyticsTrackingId={null} onClick={onThrowError}>
        Click to throw error
      </Button>
      <Button analyticsTrackingId={null} onClick={onCallBrokenMethod}>
        Click to throw another error
      </Button>
    </div>
  );
};
