/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageComponentOrGroup,
    InternalStatusPageComponentOrGroupFromJSON,
    InternalStatusPageComponentOrGroupFromJSONTyped,
    InternalStatusPageComponentOrGroupToJSON,
} from './InternalStatusPageComponentOrGroup';

/**
 * 
 * @export
 * @interface InternalStatusPageStructure
 */
export interface InternalStatusPageStructure {
    /**
     * The ID of the custom field that this structure bases its components on.
     * @type {string}
     * @memberof InternalStatusPageStructure
     */
    component_custom_field_id: string;
    /**
     * Element of the page structure, either a component or a group of components.
     * @type {Array<InternalStatusPageComponentOrGroup>}
     * @memberof InternalStatusPageStructure
     */
    components: Array<InternalStatusPageComponentOrGroup>;
    /**
     * Whether this structure is based on a catalog custom field
     * @type {boolean}
     * @memberof InternalStatusPageStructure
     */
    from_catalog: boolean;
    /**
     * The total number of components. This may be larger than the number of components returned, if the list has been truncated.
     * @type {number}
     * @memberof InternalStatusPageStructure
     */
    total_components_count: number;
}

export function InternalStatusPageStructureFromJSON(json: any): InternalStatusPageStructure {
    return InternalStatusPageStructureFromJSONTyped(json, false);
}

export function InternalStatusPageStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageStructure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_custom_field_id': json['component_custom_field_id'],
        'components': ((json['components'] as Array<any>).map(InternalStatusPageComponentOrGroupFromJSON)),
        'from_catalog': json['from_catalog'],
        'total_components_count': json['total_components_count'],
    };
}

export function InternalStatusPageStructureToJSON(value?: InternalStatusPageStructure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_custom_field_id': value.component_custom_field_id,
        'components': ((value.components as Array<any>).map(InternalStatusPageComponentOrGroupToJSON)),
        'from_catalog': value.from_catalog,
        'total_components_count': value.total_components_count,
    };
}

