/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * AlertRouteEscalationBinding is a wrapper for an engine binding. We may in future make each binding conditional, so that you could add conditions to only escalate to a target if certain conditions are met. For now, each just has a binding.
 * @export
 * @interface AlertRouteEscalationBinding
 */
export interface AlertRouteEscalationBinding {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof AlertRouteEscalationBinding
     */
    binding: EngineParamBinding;
}

export function AlertRouteEscalationBindingFromJSON(json: any): AlertRouteEscalationBinding {
    return AlertRouteEscalationBindingFromJSONTyped(json, false);
}

export function AlertRouteEscalationBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteEscalationBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'binding': EngineParamBindingFromJSON(json['binding']),
    };
}

export function AlertRouteEscalationBindingToJSON(value?: AlertRouteEscalationBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'binding': EngineParamBindingToJSON(value.binding),
    };
}

