import {
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
  StatusPageIncidentStatusEnum,
} from "@incident-io/api";
import { IconEnum } from "@incident-ui";
import { ComponentStatusConfig } from "src/components/status-pages/common/utils/utils";

export const STATUS_PAGE_INCIDENT_STATUS_NAME: {
  [key in StatusPageIncidentStatusEnum]: string;
} = {
  [StatusPageIncidentStatusEnum.Identified]: "Identified",
  [StatusPageIncidentStatusEnum.Investigating]: "Investigating",
  [StatusPageIncidentStatusEnum.Monitoring]: "Monitoring",
  [StatusPageIncidentStatusEnum.Resolved]: "Resolved",
  [StatusPageIncidentStatusEnum.MaintenanceScheduled]: "Scheduled",
  [StatusPageIncidentStatusEnum.MaintenanceInProgress]: "In Progress",
  [StatusPageIncidentStatusEnum.MaintenanceComplete]: "Complete",
};

export const COMPONENT_STATUS_CONFIG: ComponentStatusConfig<ComponentStatusEnum> =
  {
    [ComponentStatusEnum.Operational]: {
      rank: 0,
      label: "Operational",
      icon: IconEnum.Success,
      colour: "text-green-content",
      // New green inbetween tailwind's presets
      barColour: "#91D994",
      border: "border-green-300",
      background: "bg-green-surface",
    },
    [ComponentStatusEnum.UnderMaintenance]: {
      rank: 1,
      label: "Under maintenance",
      icon: IconEnum.Maintenance,
      colour: "text-blue-500",
      barColour: "#76B4DD",
      border: "border-blue-300",
      background: "bg-blue-surface",
    },
    [ComponentStatusEnum.DegradedPerformance]: {
      rank: 2,
      label: "Degraded performance",
      icon: IconEnum.Warning,
      // New shade for our yellow icon, more yellow to give contrast from orange
      colour: "text-[#E4BA31]",
      // amber-300
      barColour: "#FCD34D",
      // A special dark yellow which matches better than amber
      textColour: "text-[#ae8736]",
      border: "border-amber-300",
      // colour lighter than amber-surface as that was too dark for our badge background
      background: "bg-[#FFF8DA]",
    },
    [ComponentStatusEnum.PartialOutage]: {
      rank: 3,
      label: "Partial outage",
      icon: IconEnum.Warning,
      colour: "text-orange-400",
      // orange-400
      barColour: "#fb923c",
      textColour: "text-amber-content",
      border: "border-orange-300",
      background: "bg-orange-100",
    },
    [ComponentStatusEnum.FullOutage]: {
      rank: 4,
      label: "Full outage",
      icon: IconEnum.Warning,
      // New alarmalade shade specifically for this bar
      barColour: "#FF7253",
      colour: "text-alarmalade-600",
      border: "border-red-300",
      background: "bg-alarmalade-100",
    },
  };

export const MAINTENANCE_CONFIG = {
  label: "Maintenance",
  icon: IconEnum.Maintenance,
  // New blue shade specifically for this bar
  barColour: "#",
  colour: "text-blue-400",
  border: "border-blue-300",
  background: "bg-blue-surface",
};
