/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostIncidentTaskConfigSelectOption
 */
export interface PostIncidentTaskConfigSelectOption {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    assignee?: EngineParamBinding;
    /**
     * IDs of the custom fields that should be set when the post-incident task involves setting custom fields
     * @type {Array<string>}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    custom_field_ids?: Array<string>;
    /**
     * 
     * @type {TextNode}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    description?: TextNode;
    /**
     * Expressions available for use in post-inc task configs
     * @type {Array<Expression>}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    expressions?: Array<Expression>;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    hours_until_due?: EngineParamBinding;
    /**
     * Unique identifier for this post-incident task config
     * @type {string}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    id: string;
    /**
     * ID of the incident role when the post-incident task involves a role
     * @type {string}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    incident_role_id?: string;
    /**
     * ID of the incident status that this task belongs to
     * @type {string}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    incident_status_id?: string;
    /**
     * The ID of the post-incident flow this task configuration is part of
     * @type {string}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    post_incident_flow_id: string;
    /**
     * Rank of this post-incident task, used for sorting
     * @type {number}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    rank: number;
    /**
     * Type of post-incident task
     * @type {string}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    task_type?: PostIncidentTaskConfigSelectOptionTaskTypeEnum;
    /**
     * IDs of the timestamps that should be set when the post-incident task involves setting timestamps
     * @type {Array<string>}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    timestamp_ids?: Array<string>;
    /**
     * Title of the task
     * @type {string}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    title: string;
    /**
     * Check if the tasks is skippable
     * @type {boolean}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    unskippable?: boolean;
    /**
     * Whether this config will automatically assign tasks to someone
     * @type {boolean}
     * @memberof PostIncidentTaskConfigSelectOption
     */
    uses_auto_assign?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum PostIncidentTaskConfigSelectOptionTaskTypeEnum {
    Custom = 'custom',
    ReviewFollowups = 'review_followups',
    ReviewTimeline = 'review_timeline',
    UpdateTimestamps = 'update_timestamps',
    CreatePostmortem = 'create_postmortem',
    InReviewPostmortem = 'in_review_postmortem',
    CompletePostmortem = 'complete_postmortem',
    SharePostmortem = 'share_postmortem',
    ScheduleDebrief = 'schedule_debrief',
    AssignRole = 'assign_role',
    UpdateIncidentSummary = 'update_incident_summary',
    SetCustomFields = 'set_custom_fields',
    SetTimestamps = 'set_timestamps',
    GiveShoutout = 'give_shoutout'
}

export function PostIncidentTaskConfigSelectOptionFromJSON(json: any): PostIncidentTaskConfigSelectOption {
    return PostIncidentTaskConfigSelectOptionFromJSONTyped(json, false);
}

export function PostIncidentTaskConfigSelectOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentTaskConfigSelectOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': !exists(json, 'assignee') ? undefined : EngineParamBindingFromJSON(json['assignee']),
        'custom_field_ids': !exists(json, 'custom_field_ids') ? undefined : json['custom_field_ids'],
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'hours_until_due': !exists(json, 'hours_until_due') ? undefined : EngineParamBindingFromJSON(json['hours_until_due']),
        'id': json['id'],
        'incident_role_id': !exists(json, 'incident_role_id') ? undefined : json['incident_role_id'],
        'incident_status_id': !exists(json, 'incident_status_id') ? undefined : json['incident_status_id'],
        'post_incident_flow_id': json['post_incident_flow_id'],
        'rank': json['rank'],
        'task_type': !exists(json, 'task_type') ? undefined : json['task_type'],
        'timestamp_ids': !exists(json, 'timestamp_ids') ? undefined : json['timestamp_ids'],
        'title': json['title'],
        'unskippable': !exists(json, 'unskippable') ? undefined : json['unskippable'],
        'uses_auto_assign': !exists(json, 'uses_auto_assign') ? undefined : json['uses_auto_assign'],
    };
}

export function PostIncidentTaskConfigSelectOptionToJSON(value?: PostIncidentTaskConfigSelectOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': EngineParamBindingToJSON(value.assignee),
        'custom_field_ids': value.custom_field_ids,
        'description': TextNodeToJSON(value.description),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'hours_until_due': EngineParamBindingToJSON(value.hours_until_due),
        'id': value.id,
        'incident_role_id': value.incident_role_id,
        'incident_status_id': value.incident_status_id,
        'post_incident_flow_id': value.post_incident_flow_id,
        'rank': value.rank,
        'task_type': value.task_type,
        'timestamp_ids': value.timestamp_ids,
        'title': value.title,
        'unskippable': value.unskippable,
        'uses_auto_assign': value.uses_auto_assign,
    };
}

