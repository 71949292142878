import { Banner, Button, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { useLocation } from "react-router";
import {
  TrialStatusTrialStatusEnum as TrialStatusEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export const BuyTeamBanner = () => {
  const apiClient = useClient();
  const [hasDismissed, setHasDismissed] = useState(false);

  const onDismiss = async () => {
    setHasDismissed(true);
    try {
      await apiClient.onboardingDismissBuyPlanBanner();
    } catch (e) {
      captureException(e);
      console.error(e);
    }
  };

  const location = useLocation();
  const { identity } = useIdentity();
  if (!identity) return null;

  // If they've paid (or are a demo), never show this
  if (
    [TrialStatusEnum.Converted, TrialStatusEnum.Demo].includes(
      identity.trial_status.trial_status,
    )
  )
    return null;

  // Hide immediately on dismiss - pray that the backend saves it!
  if (hasDismissed) return null;
  // If you're on the billing page or setting up the app, you don't need this banner
  if (
    location.pathname.includes("/settings/billing") ||
    location.pathname.includes("/setup/")
  ) {
    return null;
  }

  return (
    <Banner analyticsTrackingId="buy-team" onDismiss={onDismiss}>
      <>
        To activate your &lsquo;Team&rsquo; plan, please add your billing
        details{" "}
        <Button
          analyticsTrackingId={"buy-team-banner-cta"}
          theme={ButtonTheme.Unstyled}
          disabled={!identity}
          href="/settings/billing"
        >
          here
          <Icon
            id={IconEnum.ArrowRight}
            className="inline mr-1 group-hover:mr-0 group-hover:ml-1 transition-all"
          />
        </Button>
      </>
    </Banner>
  );
};
