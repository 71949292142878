/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationAcknowledgement,
    EscalationAcknowledgementFromJSON,
    EscalationAcknowledgementFromJSONTyped,
    EscalationAcknowledgementToJSON,
} from './EscalationAcknowledgement';

/**
 * 
 * @export
 * @interface AcknowledgedTimelineData
 */
export interface AcknowledgedTimelineData {
    /**
     * The acknowledgements that were made
     * @type {Array<EscalationAcknowledgement>}
     * @memberof AcknowledgedTimelineData
     */
    acknowledgements: Array<EscalationAcknowledgement>;
    /**
     * Whether the acknowledgement was made during a live call
     * @type {boolean}
     * @memberof AcknowledgedTimelineData
     */
    is_live_call: boolean;
}

export function AcknowledgedTimelineDataFromJSON(json: any): AcknowledgedTimelineData {
    return AcknowledgedTimelineDataFromJSONTyped(json, false);
}

export function AcknowledgedTimelineDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcknowledgedTimelineData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acknowledgements': ((json['acknowledgements'] as Array<any>).map(EscalationAcknowledgementFromJSON)),
        'is_live_call': json['is_live_call'],
    };
}

export function AcknowledgedTimelineDataToJSON(value?: AcknowledgedTimelineData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acknowledgements': ((value.acknowledgements as Array<any>).map(EscalationAcknowledgementToJSON)),
        'is_live_call': value.is_live_call,
    };
}

