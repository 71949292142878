import type { SVGProps } from "react";
import * as React from "react";
const SvgSparkles3 = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.025 6.623a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0 0-.948L5.396 3.57l-.421-1.263c-.137-.408-.812-.408-.949 0L3.605 3.57l-1.263.421a.5.5 0 0 0 0 .948l1.263.421.42 1.263ZM17.525 9.803 12.99 8.01l-1.793-4.535c-.227-.572-1.168-.572-1.395 0L8.009 8.01 3.474 9.803a.75.75 0 0 0 0 1.394l4.535 1.793 1.793 4.535a.75.75 0 0 0 1.394 0l1.793-4.535 4.535-1.793a.751.751 0 0 0 .001-1.394Z"
    />
  </svg>
);
export default SvgSparkles3;
