import { CatalogConfigType } from "@incident-io/api";
import { CatalogTypeBadge } from "@incident-shared/catalog/CatalogEntryBadge";
import {
  AccordionStackedList,
  Badge,
  BadgeSize,
  BadgeTheme,
  Link,
  StackedListItem,
} from "@incident-ui";
import {
  CodeSnippet,
  CodeSnippetTheme,
} from "@incident-ui/CodeSnippet/CodeSnippet";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";

export const AlertSourceSentryTaggingInfo = ({
  catalogType,
}: {
  catalogType: CatalogConfigType;
}): React.ReactElement => {
  const suggestedTagKey = catalogType.name.toLowerCase().split(" ").join("-");

  const items = [
    {
      id: "option-1",
      name: "Option 1: Single alert rule",
      description: `Dynamically set a ${catalogType.name} using Sentry tags`,
      instructions: (
        <NumberedList>
          <div>
            Create a single alert rule in Sentry which is hooked up to your
            incident.io integration and this alert source.
          </div>
          <div>
            <Link
              href={"https://docs.sentry.io/concepts/key-terms/enrich-data/"}
              analyticsTrackingId={"alert-source-sentry-tagging-info"}
              noUnderline
              openInNewTab={true}
            >
              Tag all Sentry events
            </Link>{" "}
            with a{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              {suggestedTagKey}
            </CodeSnippet>{" "}
            using your{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            identifier below
          </div>
          <div>
            Parse your{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              {suggestedTagKey}
            </CodeSnippet>{" "}
            tag into an alert attribute within this alert source using{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>event.tags</CodeSnippet>{" "}
            in the alert payload
          </div>
        </NumberedList>
      ),
    },
    {
      id: "option-2",
      name: "Option 2: Multiple alert rules",
      description: `Each alert rule is owned by a ${catalogType.name}`,
      instructions: (
        <NumberedList>
          <div>
            Create an alert rule in Sentry per{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            and hook them up to your incident.io integration and this alert
            source.
          </div>
          <div>
            Configure your alert rule to match Sentry issues related to your{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />
          </div>
          <div>
            Name the alert rule by a{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            identifier
          </div>
          <div>
            Parse the{" "}
            <CodeSnippet theme={CodeSnippetTheme.White}>
              issue_alert.title
            </CodeSnippet>{" "}
            in the alert payload into a{" "}
            <CatalogTypeBadge
              type={catalogType.engine_type_name}
              size={BadgeSize.Small}
              className="align-middle"
            />{" "}
            to identify the owner of each event
          </div>
        </NumberedList>
      ),
    },
  ];

  return (
    <div>
      <AccordionStackedList
        items={items}
        initialExpandedItems={["option-1"]}
        renderRow={(item) => (
          <StackedListItem
            title={item.name}
            noPadding
            badgeNode={
              item.id === "option-1" ? (
                <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Info}>
                  Recommended
                </Badge>
              ) : null
            }
            description={item.description}
            descriptionClassName="text-xs-med"
          />
        )}
        renderAccordion={(item) => item.instructions}
      />
    </div>
  );
};
