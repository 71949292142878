import { TimestampFormData } from "@incident-shared/incident-forms";
import { TriageIncidentTimestamps } from "@incident-shared/triage-incidents";
import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import * as ReactAccordion from "@radix-ui/react-accordion";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, UseFormGetValues } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentDurationMetricWithValue,
  IncidentModeEnum,
  IncidentTimestamp,
  IncidentTimestampTimestampTypeEnum,
  IncidentTimestampWithValue,
} from "src/contexts/ClientContext";
import { getLocalTimeZone } from "src/utils/datetime";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useRevalidate } from "../../../../utils/use-revalidate";
import { IncidentTimestampFormElementV2 } from "../IncidentTimestampFormElementV2";
import { useStatusesForIncident } from "../useIncidentCrudResources";
import styles from "./EditTimestampsModal.module.scss";

export function EditTimestampsModal({
  incident,
  onClose,
  filterToTimestampIds,
  onTaskComplete,
}: {
  incident: Incident;
  onClose: () => void;
  onTaskComplete?: () => void;
  filterToTimestampIds?: string[];
}): React.ReactElement {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isReseted, setReset] = useState(false);

  const { statuses } = useStatusesForIncident({ incident });
  const refetchTimeline = useAPIRefetch("incidentTimelineListTimelineItems", {
    incidentId: incident.id,
    timezone: localTimezone,
  });
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });

  const visibleTimestamps = incident?.incident_timestamps?.filter((t) => {
    if (t.value?.value) {
      // Always include if a value exists
      return true;
    }

    if (
      t.timestamp.timestamp_type ===
      IncidentTimestampTimestampTypeEnum.CanceledAt
    ) {
      // Don't include the CanceledAt Timestamp
      return false;
    }

    // Don't include triage timestamps
    return !TriageIncidentTimestamps.includes(t.timestamp.timestamp_type);
  });

  // Sort the timestamps by rank
  const sortedTimestamps = _.sortBy(visibleTimestamps, (t) => t.timestamp.rank);

  const [manuallySetTimestamps, otherTimestamps] = _.partition(
    sortedTimestamps,
    (x) =>
      x.timestamp.timestamp_type ===
        IncidentTimestampTimestampTypeEnum.Custom &&
      x.timestamp.set_by_rules.length === 0,
  );

  const filterToTimestamps: IncidentTimestampWithValue[] = [];
  if (filterToTimestampIds && filterToTimestampIds.length > 0) {
    filterToTimestampIds?.forEach((id) => {
      const timestamp = sortedTimestamps.find((t) => t.timestamp.id === id);
      if (timestamp) {
        filterToTimestamps.push(timestamp);
      }
    });
  }

  // Build a map of timestamp ID to timestamp
  const mappedTimestamps = sortedTimestamps.reduce((map, t) => {
    map[t.timestamp.id] = t;
    return map;
  }, {});

  const durationsToValidate = incident?.duration_metrics?.filter((d) => {
    // We'll only bother validating if this is a standard incident --
    // we don't want to constrain timestamps in test or restrospective modes.
    return (
      d.duration_metric.validate &&
      incident.mode !== IncidentModeEnum.Retrospective
    );
  });

  // Map to the relevant durations for each timestamp (may be none)
  const timestampsToDurations = sortedTimestamps.reduce((map, t) => {
    const associatedDurations = durationsToValidate?.filter((d) => {
      return (
        d.duration_metric.from_timestamp_id === t.timestamp.id ||
        d.duration_metric.to_timestamp_id === t.timestamp.id
      );
    });

    map[t.timestamp.id] = associatedDurations;
    return map;
  }, {});

  const defaultValues = {};
  sortedTimestamps.forEach((ts) => {
    defaultValues[ts.timestamp.id] = ts.value?.value;
  });

  const formMethods = useForm<TimestampFormData>({
    mode: "onChange",
    defaultValues: { incident_timestamp_values: defaultValues },
  });
  const {
    handleSubmit,
    setError,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: TimestampFormData) => {
      // filter the payload to only values that have changed
      const payload = Object.entries(formData.incident_timestamp_values)
        .filter(([_, value], i) => {
          return (
            // check if one is null and the other is not
            !!value !== !!sortedTimestamps[i].value?.value ||
            // if they're both not null, check if they're different
            value?.getTime() !== sortedTimestamps[i].value?.value?.getTime()
          );
        })
        .map(([timestamp_id, value]) => {
          // transform nulls to undefined's
          // { value: null } -> { value : undefined}
          return {
            incident_timestamp_id: timestamp_id,
            value: value ?? undefined,
          };
        });

      // if there's no diff, don't do anything!
      if (payload.length === 0) {
        return;
      }
      await apiClient.incidentsUpdateTimestamps({
        id: incident.id,
        updateTimestampsRequestBody: {
          incident_timestamps: payload,
        },
      });

      await refetchTimeline();
    },
    {
      onSuccess: () => {
        if (onTaskComplete) {
          onTaskComplete();
        }
        refetchPostIncidentTasks();
        refreshIncidentList();
        onClose();
      },
      setError,
    },
  );

  // The two following effects are to enable us to run validations on load
  // The combination of resetting and triggering when the form first loads allows us to have the validations run on all
  // fields (which does not happen on load annoyingly)
  useEffect(() => {
    reset();
    setReset(true);
  }, [reset]);

  useEffect(() => {
    isReseted && trigger();
  }, [trigger, isReseted]);

  const invalidDurations =
    durationsToValidate && errors
      ? buildInvalidDurations(durationsToValidate, getValues)
      : [];

  const isRetrospective = incident.mode === IncidentModeEnum.Retrospective;

  const TIMESTAMP_ACCORDION = "incident-timestamps-set-automatically";

  return (
    <Modal
      isOpen
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      analyticsTrackingId="update-timestamps"
      title={`Update timestamps`}
      onClose={onClose}
    >
      <ModalContent>
        <Form.Helptext>
          <>
            {`All times are in your local time zone: `}
            <span className="font-semibold">
              {getLocalTimeZone(new Date())}.
            </span>
          </>
        </Form.Helptext>

        <hr className="my-3" />

        <FormProvider<TimestampFormData> {...formMethods}>
          {filterToTimestamps?.length > 0 ? (
            // If we're filtering to a subset of timestamps (e.g. if we're coming from a post-inc
            // task to set specific ones), then we only need to show the filtered timestamps.
            // We'll also show them as all required (even though we don't have a concept of this for
            // timestamps just yet). Annoyingly, this means they are all red until you fill them in,
            // and I can't figure out how to relax that without messing with the custom validation logic.
            <div className="space-y-4">
              {filterToTimestamps?.map((t) => {
                const ts = t.timestamp;
                const durations = timestampsToDurations[ts.id];

                return (
                  <IncidentTimestampFormElementV2
                    validateOnChange
                    required={true}
                    name={`incident_timestamp_values.${ts.id}`}
                    key={`${ts.id}`}
                    formMethods={formMethods}
                    timestamp={ts}
                    statuses={statuses}
                    customValidate={validateTimestampInputForDurationsFunc(
                      ts,
                      mappedTimestamps,
                      durations,
                      getValues,
                    )}
                  />
                );
              })}
            </div>
          ) : (
            // Otherwise we show all timestamps, but split into two sections - those that are
            // manually set (i.e. not by rules) and those that are set by rules.
            <>
              <div className="space-y-4">
                {manuallySetTimestamps?.map((t) => {
                  const ts = t.timestamp;
                  const durations = timestampsToDurations[ts.id];

                  return (
                    <IncidentTimestampFormElementV2
                      validateOnChange
                      required={false}
                      name={`incident_timestamp_values.${ts.id}`}
                      key={`${ts.id}`}
                      formMethods={formMethods}
                      timestamp={ts}
                      statuses={statuses}
                      customValidate={validateTimestampInputForDurationsFunc(
                        ts,
                        mappedTimestamps,
                        durations,
                        getValues,
                      )}
                    />
                  );
                })}
              </div>

              <hr className="my-4" />

              <AccordionProvider
                type="single"
                collapsible
                defaultValue={isRetrospective ? TIMESTAMP_ACCORDION : undefined}
              >
                <Accordion
                  id={TIMESTAMP_ACCORDION}
                  className={styles.accordionContainer}
                  header={
                    <ReactAccordion.Trigger asChild>
                      <div className="flex items-center mb-2 hover:cursor-pointer">
                        <div className="grow mr-4">
                          <div className="text-slate-700 tracking-widest text-sm font-medium uppercase">
                            {isRetrospective
                              ? "Timestamps"
                              : "Automatically set"}
                          </div>

                          <Form.Helptext>
                            {isRetrospective
                              ? "We usually prefill these timestamps based on when the incident state changes, but as this is a retrospective incident you'll need to set them here."
                              : "We've prefilled these timestamps for this incident based on when the states changed, but you can override them here."}
                          </Form.Helptext>
                        </div>
                        <AccordionTriggerButton
                          className={tcx("mr-0", styles.chevron)}
                        />
                      </div>
                    </ReactAccordion.Trigger>
                  }
                >
                  <div className="space-y-4">
                    {otherTimestamps?.map((t) => {
                      const ts = t.timestamp;
                      const durations = timestampsToDurations[ts.id];

                      return (
                        <IncidentTimestampFormElementV2
                          validateOnChange
                          name={`incident_timestamp_values.${ts.id}`}
                          key={ts.id}
                          required={false}
                          formMethods={formMethods}
                          timestamp={ts}
                          statuses={statuses}
                          customValidate={validateTimestampInputForDurationsFunc(
                            ts,
                            mappedTimestamps,
                            durations,
                            getValues,
                          )}
                        />
                      );
                    })}
                  </div>
                </Accordion>
              </AccordionProvider>
            </>
          )}
        </FormProvider>

        {genericError && <Form.ErrorMessage message={genericError} />}
        {invalidDurations.length > 0 && (
          <Callout className="mb-2" theme={CalloutTheme.Warning}>
            <div className="mb-1">
              Cannot save as the following durations will become invalid:
            </div>
            <ul>
              {invalidDurations.map((d) => {
                const duration = d.duration_metric;
                const from = mappedTimestamps[duration.from_timestamp_id];
                const to = mappedTimestamps[duration.to_timestamp_id];
                return (
                  <li key={duration.name}>
                    <strong>{duration.name}</strong>{" "}
                    <em>
                      ({from.timestamp.name} → {to.timestamp.name})
                    </em>{" "}
                  </li>
                );
              })}
            </ul>
          </Callout>
        )}
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        confirmButtonText="Save"
        saving={saving}
        confirmButtonType="submit"
      />
    </Modal>
  );
}

function buildInvalidDurations(
  durationsToValidate: IncidentDurationMetricWithValue[],
  getValues: UseFormGetValues<TimestampFormData>,
) {
  const invalidDurations = durationsToValidate.filter((d) => {
    const duration = d.duration_metric;

    const formData = getValues().incident_timestamp_values;

    const fromValue = formData[duration.from_timestamp_id];
    const toValue = formData[duration.to_timestamp_id];
    if (fromValue === undefined || toValue === undefined) {
      return false;
    }

    return fromValue > toValue;
  });

  return invalidDurations;
}

export function validateTimestampInputForDurationsFunc<
  TFormData extends TimestampFormData,
>(
  timestamp: IncidentTimestamp,
  mapOfOtherTimestampsById: {
    [key: string]: IncidentTimestampWithValue;
  },
  durations: IncidentDurationMetricWithValue[] | undefined,
  getValues: UseFormGetValues<TFormData>,
) {
  return (value: Date | undefined): string | undefined => {
    // If we're not set to a real date, do nothing
    if (value === undefined) {
      return undefined;
    }

    // We can have potentially multiple durations for this timestamp, which could all be invalid
    let errorMessage = "";
    durations?.forEach((d) => {
      // Pull out which of the other timestamps is in this relationship
      const duration = d.duration_metric;

      const formData = getValues().incident_timestamp_values;
      // And we need the value from the form (as it might have been edited)
      const fromValue = formData[duration.from_timestamp_id];
      const toValue = formData[duration.to_timestamp_id];

      // in cases where one of the dates is not set - do nothing
      if (fromValue === undefined || toValue === undefined) {
        return;
      }

      // Lookup the timestamps so we can print their names
      const to = mapOfOtherTimestampsById[duration.to_timestamp_id];
      const from = mapOfOtherTimestampsById[duration.from_timestamp_id];
      if (fromValue > toValue) {
        // As the duration is invalid lets try to help by pointing out which other timestamp is wrong
        // Check which way around the relationship - which is from and which is to
        if (duration.from_timestamp_id === timestamp.id) {
          errorMessage += `'${from.timestamp.name}' cannot be after '${to.timestamp.name}'.\n`;
        } else {
          errorMessage += `'${to.timestamp.name}' cannot be before '${from.timestamp.name}'.\n`;
        }
      }
    });
    return errorMessage === "" ? undefined : errorMessage;
  };
}
