/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemTemplateSection,
    PostmortemTemplateSectionFromJSON,
    PostmortemTemplateSectionFromJSONTyped,
    PostmortemTemplateSectionToJSON,
} from './PostmortemTemplateSection';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemTemplate
 */
export interface PostmortemTemplate {
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemTemplate
     */
    export_title: TextNode;
    /**
     * Whether follow ups are exported just as links or within a table
     * @type {boolean}
     * @memberof PostmortemTemplate
     */
    follow_ups_links_only: boolean;
    /**
     * Unique ID for the template
     * @type {string}
     * @memberof PostmortemTemplate
     */
    id: string;
    /**
     * Whether this template is the default postmortem template for this incident.
     * @type {boolean}
     * @memberof PostmortemTemplate
     */
    is_default: boolean;
    /**
     * Name of the postmortem template
     * @type {string}
     * @memberof PostmortemTemplate
     */
    name: string;
    /**
     * 
     * @type {Array<PostmortemTemplateSection>}
     * @memberof PostmortemTemplate
     */
    sections: Array<PostmortemTemplateSection>;
    /**
     * The writing mode for the template
     * @type {string}
     * @memberof PostmortemTemplate
     */
    writing_mode: PostmortemTemplateWritingModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemTemplateWritingModeEnum {
    InHouse = 'in_house',
    External = 'external'
}

export function PostmortemTemplateFromJSON(json: any): PostmortemTemplate {
    return PostmortemTemplateFromJSONTyped(json, false);
}

export function PostmortemTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'export_title': TextNodeFromJSON(json['export_title']),
        'follow_ups_links_only': json['follow_ups_links_only'],
        'id': json['id'],
        'is_default': json['is_default'],
        'name': json['name'],
        'sections': ((json['sections'] as Array<any>).map(PostmortemTemplateSectionFromJSON)),
        'writing_mode': json['writing_mode'],
    };
}

export function PostmortemTemplateToJSON(value?: PostmortemTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'export_title': TextNodeToJSON(value.export_title),
        'follow_ups_links_only': value.follow_ups_links_only,
        'id': value.id,
        'is_default': value.is_default,
        'name': value.name,
        'sections': ((value.sections as Array<any>).map(PostmortemTemplateSectionToJSON)),
        'writing_mode': value.writing_mode,
    };
}

