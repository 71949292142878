import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ButtonTheme, ModalFooter, Txt } from "@incident-ui";
import { capitalize } from "lodash";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ManagedResourceResourceTypeEnum as ResourceTypeEnum,
  ManagedResourcesUpsertRequestBodyManagedByEnum as ManagedByEnum,
  ManagedResourcesUpsertRequestBodyResourceTypeEnum as RequestResourceTypeEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { resourceToShowEndpoint } from "./utils";

export const DisconnectTerraformModal = ({
  onClose,
  resourceType,
  resourceID,
  resourceName,
}: {
  onClose: () => void;
  resourceType: ResourceTypeEnum;
  resourceID: string;
  resourceName: string;
}) => {
  const formMethods = useForm<{ confirmed_name: string }>();

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    resourceToShowEndpoint[resourceType],
    {
      id: resourceID,
    },
    async (apiClient, _data) => {
      await apiClient.managedResourcesUpsert({
        upsertRequestBody: {
          resource_id: resourceID,
          resource_type: resourceType as unknown as RequestResourceTypeEnum,
          managed_by: ManagedByEnum.Dashboard,
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Are you sure?"}
      analyticsTrackingId={`${resourceType}s-disconnect-terraform`}
      genericError={genericError}
      onClose={onClose}
      onSubmit={onSubmit}
      contentClassName="flex flex-col space-y-6"
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          confirmButtonType={"submit"}
          confirmButtonText={"Disconnect"}
          confirmButtonTheme={ButtonTheme.Destroy}
        />
      }
    >
      <div className="flex flex-col gap-3 text-slate-700">
        <Txt>
          Disconnecting will override this {resourceType}&rsquo;s connection
          with Terraform, requiring significant effort to resync.
        </Txt>
        <Txt>
          Confirm you want to do this by typing the name of the {resourceType}{" "}
          in the box and clicking &rsquo;Disconnect&rsquo;.
        </Txt>
      </div>
      <InputV2
        formMethods={formMethods}
        name="confirmed_name"
        placeholder={resourceName}
        label={`${capitalize(resourceType)} name`}
        rules={{
          validate: (value) =>
            value === resourceName
              ? undefined
              : `Please input the name of the ${resourceType}`,
        }}
      />
    </Form.Modal>
  );
};
