/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkflowTemplate,
    WorkflowTemplateFromJSON,
    WorkflowTemplateFromJSONTyped,
    WorkflowTemplateToJSON,
} from './WorkflowTemplate';

/**
 * 
 * @export
 * @interface WorkflowsListWorkflowTemplatesResponseBody
 */
export interface WorkflowsListWorkflowTemplatesResponseBody {
    /**
     * 
     * @type {Array<WorkflowTemplate>}
     * @memberof WorkflowsListWorkflowTemplatesResponseBody
     */
    templates: Array<WorkflowTemplate>;
}

export function WorkflowsListWorkflowTemplatesResponseBodyFromJSON(json: any): WorkflowsListWorkflowTemplatesResponseBody {
    return WorkflowsListWorkflowTemplatesResponseBodyFromJSONTyped(json, false);
}

export function WorkflowsListWorkflowTemplatesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsListWorkflowTemplatesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templates': ((json['templates'] as Array<any>).map(WorkflowTemplateFromJSON)),
    };
}

export function WorkflowsListWorkflowTemplatesResponseBodyToJSON(value?: WorkflowsListWorkflowTemplatesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templates': ((value.templates as Array<any>).map(WorkflowTemplateToJSON)),
    };
}

