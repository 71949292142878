/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsGoogleWorkspaceGetCalendarInfoRequestBody,
    IntegrationsGoogleWorkspaceGetCalendarInfoRequestBodyFromJSON,
    IntegrationsGoogleWorkspaceGetCalendarInfoRequestBodyToJSON,
    IntegrationsGoogleWorkspaceGetCalendarInfoResponseBody,
    IntegrationsGoogleWorkspaceGetCalendarInfoResponseBodyFromJSON,
    IntegrationsGoogleWorkspaceGetCalendarInfoResponseBodyToJSON,
    IntegrationsGoogleWorkspaceGetCalendarsResponseBody,
    IntegrationsGoogleWorkspaceGetCalendarsResponseBodyFromJSON,
    IntegrationsGoogleWorkspaceGetCalendarsResponseBodyToJSON,
    IntegrationsGoogleWorkspaceGetConfigResponseBody,
    IntegrationsGoogleWorkspaceGetConfigResponseBodyFromJSON,
    IntegrationsGoogleWorkspaceGetConfigResponseBodyToJSON,
    IntegrationsGoogleWorkspaceSetCalendarsRequestBody,
    IntegrationsGoogleWorkspaceSetCalendarsRequestBodyFromJSON,
    IntegrationsGoogleWorkspaceSetCalendarsRequestBodyToJSON,
    IntegrationsGoogleWorkspaceSetCalendarsResponseBody,
    IntegrationsGoogleWorkspaceSetCalendarsResponseBodyFromJSON,
    IntegrationsGoogleWorkspaceSetCalendarsResponseBodyToJSON,
} from '../models';

export interface IntegrationsGoogleWorkspaceGetCalendarInfoRequest {
    getCalendarInfoRequestBody: IntegrationsGoogleWorkspaceGetCalendarInfoRequestBody;
}

export interface IntegrationsGoogleWorkspaceHandleInstallRequest {
    workspaceApp: IntegrationsGoogleWorkspaceHandleInstallWorkspaceAppEnum;
    organisationId: string;
}

export interface IntegrationsGoogleWorkspaceHandleRedirectRequest {
    code?: string;
    state?: string;
    scope?: string;
}

export interface IntegrationsGoogleWorkspaceSetCalendarsRequest {
    setCalendarsRequestBody: IntegrationsGoogleWorkspaceSetCalendarsRequestBody;
}

/**
 * 
 */
export class IntegrationsGoogleWorkspaceApi extends runtime.BaseAPI {

    /**
     * Fetches information for a single Google Calendar, which the org may or may not be subscribed to
     * GetCalendarInfo Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceGetCalendarInfoRaw(requestParameters: IntegrationsGoogleWorkspaceGetCalendarInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsGoogleWorkspaceGetCalendarInfoResponseBody>> {
        if (requestParameters.getCalendarInfoRequestBody === null || requestParameters.getCalendarInfoRequestBody === undefined) {
            throw new runtime.RequiredError('getCalendarInfoRequestBody','Required parameter requestParameters.getCalendarInfoRequestBody was null or undefined when calling integrationsGoogleWorkspaceGetCalendarInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/google_workspace/calendar/info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsGoogleWorkspaceGetCalendarInfoRequestBodyToJSON(requestParameters.getCalendarInfoRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsGoogleWorkspaceGetCalendarInfoResponseBodyFromJSON(jsonValue));
    }

    /**
     * Fetches information for a single Google Calendar, which the org may or may not be subscribed to
     * GetCalendarInfo Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceGetCalendarInfo(requestParameters: IntegrationsGoogleWorkspaceGetCalendarInfoRequest, initOverrides?: RequestInit): Promise<IntegrationsGoogleWorkspaceGetCalendarInfoResponseBody> {
        const response = await this.integrationsGoogleWorkspaceGetCalendarInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get organisation\'s Google Calendars that we subscribe to.
     * GetCalendars Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceGetCalendarsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsGoogleWorkspaceGetCalendarsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/google_workspace/calendars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsGoogleWorkspaceGetCalendarsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s Google Calendars that we subscribe to.
     * GetCalendars Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceGetCalendars(initOverrides?: RequestInit): Promise<IntegrationsGoogleWorkspaceGetCalendarsResponseBody> {
        const response = await this.integrationsGoogleWorkspaceGetCalendarsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get organisation\'s Google Workspace config. There is only one active config per org.
     * GetConfig Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsGoogleWorkspaceGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/google_workspace/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsGoogleWorkspaceGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s Google Workspace config. There is only one active config per org.
     * GetConfig Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceGetConfig(initOverrides?: RequestInit): Promise<IntegrationsGoogleWorkspaceGetConfigResponseBody> {
        const response = await this.integrationsGoogleWorkspaceGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Google Workspace install by redirecting to the Google OAuth endpoint
     * HandleInstall Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceHandleInstallRaw(requestParameters: IntegrationsGoogleWorkspaceHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.workspaceApp === null || requestParameters.workspaceApp === undefined) {
            throw new runtime.RequiredError('workspaceApp','Required parameter requestParameters.workspaceApp was null or undefined when calling integrationsGoogleWorkspaceHandleInstall.');
        }

        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsGoogleWorkspaceHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.workspaceApp !== undefined) {
            queryParameters['workspace_app'] = requestParameters.workspaceApp;
        }

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/google_workspace_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Google Workspace install by redirecting to the Google OAuth endpoint
     * HandleInstall Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceHandleInstall(requestParameters: IntegrationsGoogleWorkspaceHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGoogleWorkspaceHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Google Workspace OAuth redirect
     * HandleRedirect Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceHandleRedirectRaw(requestParameters: IntegrationsGoogleWorkspaceHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/google_workspace`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Google Workspace OAuth redirect
     * HandleRedirect Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceHandleRedirect(requestParameters: IntegrationsGoogleWorkspaceHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGoogleWorkspaceHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Google Workspace OAuth token
     * HandleUninstall Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/google_workspace_uninstall`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Google Workspace OAuth token
     * HandleUninstall Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGoogleWorkspaceHandleUninstallRaw(initOverrides);
    }

    /**
     * Set which Google Calendars we subscribe to for an organisation.
     * SetCalendars Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceSetCalendarsRaw(requestParameters: IntegrationsGoogleWorkspaceSetCalendarsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsGoogleWorkspaceSetCalendarsResponseBody>> {
        if (requestParameters.setCalendarsRequestBody === null || requestParameters.setCalendarsRequestBody === undefined) {
            throw new runtime.RequiredError('setCalendarsRequestBody','Required parameter requestParameters.setCalendarsRequestBody was null or undefined when calling integrationsGoogleWorkspaceSetCalendars.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/google_workspace/calendars`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsGoogleWorkspaceSetCalendarsRequestBodyToJSON(requestParameters.setCalendarsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsGoogleWorkspaceSetCalendarsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set which Google Calendars we subscribe to for an organisation.
     * SetCalendars Integrations - Google Workspace
     */
    async integrationsGoogleWorkspaceSetCalendars(requestParameters: IntegrationsGoogleWorkspaceSetCalendarsRequest, initOverrides?: RequestInit): Promise<IntegrationsGoogleWorkspaceSetCalendarsResponseBody> {
        const response = await this.integrationsGoogleWorkspaceSetCalendarsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IntegrationsGoogleWorkspaceHandleInstallWorkspaceAppEnum {
    Docs = 'google_docs',
    Meet = 'google_meet',
    Calendar = 'google_calendar'
}
