import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Loader } from "@incident-ui";
import _ from "lodash";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import { IncidentType } from "src/contexts/ClientContext";

import { aliasSeverities, applyAlias } from "../../../utils/severities";
import { getSeverityOptions } from "../../legacy/incident/ActiveIncidentCreateModal";
import { useIncidentCrudResources } from "../../legacy/incident/useIncidentCrudResources";

export const SeverityElementSelect = <TFormType extends FieldValues>({
  formMethods,
  name,
  incidentType,
  required,
  placeholder,
  disabled = false,
}: {
  formMethods: UseFormReturn<TFormType>;
  name: Path<TFormType>;
  incidentType: IncidentType | undefined;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const { severities, loading } = useIncidentCrudResources();
  const aliasedSeverities = aliasSeverities(severities, incidentType?.id);
  const visibleSeverities = aliasedSeverities.filter((sev) => !sev.hidden);
  const severityOptions = getSeverityOptions(visibleSeverities);

  if (loading) {
    return <Loader />;
  }

  const selectedSeverityID = formMethods.watch(name);

  // Check if the currently selected severity is not included in the
  // list of visible severities. If not, reset the value to the new min value.
  if (
    selectedSeverityID &&
    !visibleSeverities.find((sev) => sev.id === selectedSeverityID)
  ) {
    const minSeverityId = _.minBy(visibleSeverities, "rank")?.id;
    formMethods.setValue(
      name,
      minSeverityId as PathValue<TFormType, Path<TFormType>>,
    );
  }

  const selectedSeverity = severities.find(
    (sev) => sev.id === selectedSeverityID,
  );
  const severityDescription =
    selectedSeverity &&
    applyAlias(selectedSeverity, incidentType?.id).description;

  if (!incidentType) {
    // If the user has selected 'not sure', or they're updating a triage incident,
    // that doesn't have a type yet - don't show severity options.
    return null;
  }
  return (
    <div>
      <StaticSingleSelectV2
        formMethods={formMethods}
        name={name}
        label={"Severity"}
        options={severityOptions}
        isLoading={loading}
        isClearable={!required}
        required={required}
        placeholder={placeholder}
        disabled={disabled}
      />
      {severityDescription && (
        <TemplatedTextDisplay
          style={TemplatedTextDisplayStyle.Compact}
          value={severityDescription.text_node}
          className="mt-1 text-xs text-slate-700"
        />
      )}
    </div>
  );
};
