import { CommaSeparatedConditionGroups } from "@incident-shared/engine/conditions";
import { ConditionBadgeTheme } from "@incident-shared/engine/conditions/ConditionBadge/ConditionBadge";
import { SelectedCondition } from "@incident-shared/engine/conditions/SelectedCondition";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Interpose } from "@incident-ui";
import {
  StackedListItem,
  StackedListItemProps,
} from "@incident-ui/StackedList/StackedListItem";
import { isEmpty } from "lodash";
import React from "react";
import { Condition, ConditionGroup } from "src/contexts/ClientContext";

import {
  SettingsListItemButtons,
  SettingsListItemButtonsProps,
} from "./SettingsListButtons";

// One, the other or neither, but never both
type SettingsListItemConditions =
  | {
      conditions: Condition[];
      groups?: never;
    }
  | {
      conditions?: never;
      groups: ConditionGroup[];
    }
  | {
      conditions?: never;
      groups?: never;
    };

export type SettingsListItemProps = {
  // Core props of the list item
  dragHandle?: React.ReactNode;
  icon?: IconEnum;
  iconNode?: React.ReactNode;
  iconColor?: ColorPaletteEnum;
  title?: React.ReactNode;
  badgeProps?: StackedListItemProps["badgeProps"];
  badgeNode?: React.ReactNode;
  description?: React.ReactNode;
  descriptionPosition?: "below" | "inline";
  rowHref?: string;
  disabled?: boolean;
  disabledExplanation?: React.ReactNode;

  allowShrink?: boolean;

  // Either specify a custom accessory, or use the default buttons
  accessory?: React.ReactNode;
  buttons?: SettingsListItemButtonsProps;
} & SettingsListItemConditions;

export const SettingsListItem = ({
  dragHandle,
  icon,
  iconNode,
  iconColor,
  title,
  badgeProps,
  badgeNode,
  description,
  descriptionPosition,
  rowHref,
  accessory,
  disabled = false,
  disabledExplanation,
  conditions,
  groups,
  buttons,
  allowShrink,
}: SettingsListItemProps): React.ReactElement => {
  return (
    <>
      <StackedListItem
        title={title}
        badgeProps={badgeProps}
        badgeNode={badgeNode}
        description={
          conditions ? (
            <SettingsListItemConditions conditions={conditions} prefix="When" />
          ) : groups ? (
            <SettingsListItemConditionGroups groups={groups} prefix="When" />
          ) : (
            description
          )
        }
        descriptionPosition={descriptionPosition}
        rowHref={rowHref}
        icon={icon}
        iconNode={iconNode}
        dragHandle={dragHandle}
        disabled={disabled}
        disabledExplanation={disabledExplanation}
        allowShrink={allowShrink}
        accessory={
          !isEmpty(buttons) ? (
            <SettingsListItemButtons disabled={disabled} {...buttons} />
          ) : (
            accessory
          )
        }
        iconColor={iconColor}
      />
    </>
  );
};

const SettingsListItemConditionGroups = ({
  prefix,
  groups,
  theme = "slate",
}: {
  prefix: string;
  groups?: ConditionGroup[];
  theme?: ConditionBadgeTheme;
}): React.ReactElement | null => {
  if (!groups || groups.length === 0) {
    return null;
  }
  return (
    <div className="pt-1 gap-1 flex text-xs items-center text-content-secondary">
      <span className="font-medium shrink-0">{`${prefix} `}</span>
      <CommaSeparatedConditionGroups groups={groups} theme={theme} />
    </div>
  );
};

const SettingsListItemConditions = ({
  prefix,
  conditions,
  theme = "slate",
}: {
  prefix: string;
  conditions?: Condition[];
  theme?: ConditionBadgeTheme;
}): React.ReactElement | null => {
  if (!conditions || conditions.length === 0) {
    return null;
  }
  return (
    <div className="pt-1 gap-1 flex text-xs items-center text-content-secondary">
      <span className="font-medium shrink-0">{`${prefix} `}</span>
      <Interpose separator={<span className="font-semibold">and</span>}>
        {conditions.map((condition) => (
          <SelectedCondition
            key={condition.subject.reference}
            condition={condition}
            mini
            className="!mb-0"
            theme={theme}
          />
        ))}
      </Interpose>
    </div>
  );
};
