import { IconEnum } from "@incident-ui";
import _ from "lodash";
import { ExternalResource } from "src/contexts/ClientContext";

import { AttachmentRenderProps } from "./IncidentAttachment";

export const SentryIssue = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.sentry_issue) {
    throw new Error("Sentry issue resource without expected content");
  }

  return {
    icon: IconEnum.Sentry,
    title: resource.sentry_issue.title,
    safeBadges: [
      {
        icon: IconEnum.Severity,
        text: _.startCase(resource.sentry_issue.level),
      },
    ],
    unsafeBadges: [
      {
        icon: IconEnum.Status,
        text: _.startCase(resource.sentry_issue.status),
      },
    ],
  };
};
