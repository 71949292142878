import { Product } from "@incident-shared/billing";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { BadgeSize, IconSize } from "@incident-ui";
import { SeverityBadge } from "@incident-ui/Badge/SeverityBadge";
import { IconBadge } from "@incident-ui/IconBadge/IconBadge";
import React from "react";
import {
  ScopeNameEnum,
  SeveritiesDestroyRequest,
  Severity,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SeverityRankingLabel } from "../common/SeverityRankingLabel";

export const SeverityViewListItem = ({
  severity,
  severities,
}: {
  severity: Severity;
  severities: Severity[];
}): React.ReactElement => {
  const { trigger: deleteSeverity } = useAPIMutation(
    "severitiesList",
    undefined,
    async (apiClient, data: SeveritiesDestroyRequest) => {
      await apiClient.severitiesDestroy(data);
    },
  );

  return (
    <SettingsListItem
      iconNode={
        <IconBadge
          color={
            severity.bucketed_rank === 4
              ? ColorPaletteEnum.Red
              : ColorPaletteEnum.Slate
          }
          size={IconSize.Medium}
          iconNode={
            <SeverityBadge
              severity={severity}
              size={BadgeSize.Medium}
              iconOnly
              naked
            />
          }
        />
      }
      title={severity.name}
      badgeNode={
        <SeverityRankingLabel severity={severity} severities={severities} />
      }
      description={
        <TemplatedTextDisplay
          value={severity.description.text_node}
          style={TemplatedTextDisplayStyle.Compact}
          className="text-content-tertiary text-xs"
        />
      }
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        requiredProduct: Product.Response,
        edit: {
          editHref: `/settings/severities/${severity.id}/edit`,
        },
        delete: {
          onDelete: () => deleteSeverity({ id: severity.id }),
          deleteConfirmationTitle: "Delete severity",
          deleteConfirmationContent: (
            <>
              Are you sure you want to delete the{" "}
              <span className="font-bold">{severity.name}</span> severity?
            </>
          ),
          resourceTitle: severity.name,
          fetchDependentResources: [
            {
              resource_type: "IncidentSeverity",
              id: severity.id,
            },
          ],
        },
      }}
    />
  );
};
