/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemSharesCreateShareRequestBody
 */
export interface PostmortemSharesCreateShareRequestBody {
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemSharesCreateShareRequestBody
     */
    content: TextNode;
    /**
     * ID of the incident
     * @type {string}
     * @memberof PostmortemSharesCreateShareRequestBody
     */
    incident_id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemSharesCreateShareRequestBody
     */
    postmortem_description: TextNode;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostmortemSharesCreateShareRequestBody
     */
    slack_channel_ids: Array<string>;
}

export function PostmortemSharesCreateShareRequestBodyFromJSON(json: any): PostmortemSharesCreateShareRequestBody {
    return PostmortemSharesCreateShareRequestBodyFromJSONTyped(json, false);
}

export function PostmortemSharesCreateShareRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSharesCreateShareRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': TextNodeFromJSON(json['content']),
        'incident_id': json['incident_id'],
        'postmortem_description': TextNodeFromJSON(json['postmortem_description']),
        'slack_channel_ids': json['slack_channel_ids'],
    };
}

export function PostmortemSharesCreateShareRequestBodyToJSON(value?: PostmortemSharesCreateShareRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': TextNodeToJSON(value.content),
        'incident_id': value.incident_id,
        'postmortem_description': TextNodeToJSON(value.postmortem_description),
        'slack_channel_ids': value.slack_channel_ids,
    };
}

