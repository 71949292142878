/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationsCreateAutoAckRuleRequestBody
 */
export interface EscalationsCreateAutoAckRuleRequestBody {
    /**
     * The duration that this rule should apply for, starting from now. Should always be less than 1 hour.
     * @type {number}
     * @memberof EscalationsCreateAutoAckRuleRequestBody
     */
    duration_seconds: number;
    /**
     * The ID of the escalation path
     * @type {string}
     * @memberof EscalationsCreateAutoAckRuleRequestBody
     */
    escalation_path_id: string;
    /**
     * ID of a user to apply rule for.
     * @type {string}
     * @memberof EscalationsCreateAutoAckRuleRequestBody
     */
    user_id: string;
}

export function EscalationsCreateAutoAckRuleRequestBodyFromJSON(json: any): EscalationsCreateAutoAckRuleRequestBody {
    return EscalationsCreateAutoAckRuleRequestBodyFromJSONTyped(json, false);
}

export function EscalationsCreateAutoAckRuleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsCreateAutoAckRuleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration_seconds': json['duration_seconds'],
        'escalation_path_id': json['escalation_path_id'],
        'user_id': json['user_id'],
    };
}

export function EscalationsCreateAutoAckRuleRequestBodyToJSON(value?: EscalationsCreateAutoAckRuleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration_seconds': value.duration_seconds,
        'escalation_path_id': value.escalation_path_id,
        'user_id': value.user_id,
    };
}

