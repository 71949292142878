import { CatalogType } from "@incident-io/api";

export const slugForCatalogType = (catalogType: CatalogType): string => {
  if (
    catalogType.registry_type &&
    catalogType.registry_type === catalogType.type_name
  ) {
    return catalogType.registry_type;
  }
  return catalogType.id;
};
