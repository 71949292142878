import type { SVGProps } from "react";
import * as React from "react";
const SvgFakeCheckboxChecked = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.6 2.4h6.8c1.213 0 2.2.986 2.2 2.2v6.8c0 1.214-.986 2.2-2.2 2.2H4.6c-1.214 0-2.2-.986-2.2-2.2V4.6c0-1.214.986-2.2 2.2-2.2Zm6.372 3.818c-1.4 1.041-2.587 2.478-3.526 4.269a.564.564 0 0 1-1.002-.004c-.433-.841-.917-1.525-1.48-2.09a.565.565 0 1 1 .801-.797c.432.434.82.925 1.177 1.485.933-1.54 2.059-2.805 3.357-3.77a.565.565 0 0 1 .673.907Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFakeCheckboxChecked;
