/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParam,
    EngineParamFromJSON,
    EngineParamFromJSONTyped,
    EngineParamToJSON,
} from './EngineParam';

/**
 * 
 * @export
 * @interface IssueTrackerField
 */
export interface IssueTrackerField {
    /**
     * The param names of other fields that this field depends on
     * @type {Array<string>}
     * @memberof IssueTrackerField
     */
    depends_on: Array<string>;
    /**
     * Whether the value of this field affects other fields. If this is false, then changing this field's value doesn't require refetching creation fields
     * @type {boolean}
     * @memberof IssueTrackerField
     */
    has_dependent_fields: boolean;
    /**
     * If true, this field should never be shown to a user
     * @type {boolean}
     * @memberof IssueTrackerField
     */
    is_hidden: boolean;
    /**
     * If true, this field will be disabled on an issue template
     * @type {boolean}
     * @memberof IssueTrackerField
     */
    is_not_templatable: boolean;
    /**
     * 
     * @type {EngineParam}
     * @memberof IssueTrackerField
     */
    param: EngineParam;
    /**
     * By default, manual exports only ask about required fields; this flag overrides that behaviour for fields we consider important enough to always ask about.
     * @type {boolean}
     * @memberof IssueTrackerField
     */
    rendered_when_optional: boolean;
    /**
     * If true, users will be able to use variables when templating this field
     * @type {boolean}
     * @memberof IssueTrackerField
     */
    variables_allowed: boolean;
}

export function IssueTrackerFieldFromJSON(json: any): IssueTrackerField {
    return IssueTrackerFieldFromJSONTyped(json, false);
}

export function IssueTrackerFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackerField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'depends_on': json['depends_on'],
        'has_dependent_fields': json['has_dependent_fields'],
        'is_hidden': json['is_hidden'],
        'is_not_templatable': json['is_not_templatable'],
        'param': EngineParamFromJSON(json['param']),
        'rendered_when_optional': json['rendered_when_optional'],
        'variables_allowed': json['variables_allowed'],
    };
}

export function IssueTrackerFieldToJSON(value?: IssueTrackerField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'depends_on': value.depends_on,
        'has_dependent_fields': value.has_dependent_fields,
        'is_hidden': value.is_hidden,
        'is_not_templatable': value.is_not_templatable,
        'param': EngineParamToJSON(value.param),
        'rendered_when_optional': value.rendered_when_optional,
        'variables_allowed': value.variables_allowed,
    };
}

