/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface IncidentVisibilityChanged
 */
export interface IncidentVisibilityChanged {
    /**
     * New incident visibility
     * @type {string}
     * @memberof IncidentVisibilityChanged
     */
    new_visibility: IncidentVisibilityChangedNewVisibilityEnum;
    /**
     * Previous incident visibility
     * @type {string}
     * @memberof IncidentVisibilityChanged
     */
    previous_visibility: IncidentVisibilityChangedPreviousVisibilityEnum;
    /**
     * 
     * @type {User}
     * @memberof IncidentVisibilityChanged
     */
    user?: User;
}

/**
* @export
* @enum {string}
*/
export enum IncidentVisibilityChangedNewVisibilityEnum {
    Public = 'public',
    Private = 'private'
}/**
* @export
* @enum {string}
*/
export enum IncidentVisibilityChangedPreviousVisibilityEnum {
    Public = 'public',
    Private = 'private'
}

export function IncidentVisibilityChangedFromJSON(json: any): IncidentVisibilityChanged {
    return IncidentVisibilityChangedFromJSONTyped(json, false);
}

export function IncidentVisibilityChangedFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentVisibilityChanged {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'new_visibility': json['new_visibility'],
        'previous_visibility': json['previous_visibility'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function IncidentVisibilityChangedToJSON(value?: IncidentVisibilityChanged | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_visibility': value.new_visibility,
        'previous_visibility': value.previous_visibility,
        'user': UserToJSON(value.user),
    };
}

