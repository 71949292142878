/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BulkIncidentUpdateResult,
    BulkIncidentUpdateResultFromJSON,
    BulkIncidentUpdateResultFromJSONTyped,
    BulkIncidentUpdateResultToJSON,
} from './BulkIncidentUpdateResult';

/**
 * 
 * @export
 * @interface IncidentsBulkUpdateModeResponseBody
 */
export interface IncidentsBulkUpdateModeResponseBody {
    /**
     * 
     * @type {Array<BulkIncidentUpdateResult>}
     * @memberof IncidentsBulkUpdateModeResponseBody
     */
    result: Array<BulkIncidentUpdateResult>;
}

export function IncidentsBulkUpdateModeResponseBodyFromJSON(json: any): IncidentsBulkUpdateModeResponseBody {
    return IncidentsBulkUpdateModeResponseBodyFromJSONTyped(json, false);
}

export function IncidentsBulkUpdateModeResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsBulkUpdateModeResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ((json['result'] as Array<any>).map(BulkIncidentUpdateResultFromJSON)),
    };
}

export function IncidentsBulkUpdateModeResponseBodyToJSON(value?: IncidentsBulkUpdateModeResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ((value.result as Array<any>).map(BulkIncidentUpdateResultToJSON)),
    };
}

