/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntegrationSettings,
    IntegrationSettingsFromJSON,
    IntegrationSettingsFromJSONTyped,
    IntegrationSettingsToJSON,
} from './IntegrationSettings';

/**
 * 
 * @export
 * @interface IntegrationsListResponseBody
 */
export interface IntegrationsListResponseBody {
    /**
     * The number of integrations this organisation have installed, excluding integrations like 'Slack', or 'Okta' that we don't count towards their billing
     * @type {number}
     * @memberof IntegrationsListResponseBody
     */
    installed_integrations_count: number;
    /**
     * 
     * @type {Array<IntegrationSettings>}
     * @memberof IntegrationsListResponseBody
     */
    integrations: Array<IntegrationSettings>;
}

export function IntegrationsListResponseBodyFromJSON(json: any): IntegrationsListResponseBody {
    return IntegrationsListResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'installed_integrations_count': json['installed_integrations_count'],
        'integrations': ((json['integrations'] as Array<any>).map(IntegrationSettingsFromJSON)),
    };
}

export function IntegrationsListResponseBodyToJSON(value?: IntegrationsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'installed_integrations_count': value.installed_integrations_count,
        'integrations': ((value.integrations as Array<any>).map(IntegrationSettingsToJSON)),
    };
}

