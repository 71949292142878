import {
  PopoverMultiSelect,
  PopoverMultiSelectProps,
  PopoverSelectOption,
  PopoverSingleSelect,
  PopoverSingleSelectProps,
} from "@incident-ui/PopoverSelect";
import { SelectValue } from "@incident-ui/Select/types";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

type PopoverSingleSelectV2Props<TOption extends PopoverSelectOption> =
  PopoverSingleSelectProps<true, false, TOption> & {
    onValueChange?: (val: string | null | undefined) => void;
  };

export const PopoverSingleSelectV2 = <
  TFormType extends FieldValues,
  TOption extends PopoverSelectOption,
>(
  props: InputElementProps<TFormType, PopoverSingleSelectV2Props<TOption>>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    PopoverSingleSelectV2Props<TOption>
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      disabled={inputProps.disabled}
    >
      <PopoverSingleSelect
        {...field}
        {...inputProps}
        onChange={(val: string | null | undefined) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type PopoverSingleSelectWithObjV2Props<TOption extends PopoverSelectOption> =
  Omit<PopoverSingleSelectProps<true, true, TOption>, "object"> & {
    onValueChange?: (val?: TOption) => void;
  };

export const PopoverSingleSelectWithObjV2 = <
  TFormType extends FieldValues,
  TOption extends PopoverSelectOption,
>(
  props: InputElementProps<
    TFormType,
    PopoverSingleSelectWithObjV2Props<TOption>
  >,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    PopoverSingleSelectWithObjV2Props<TOption>
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      disabled={inputProps.disabled}
    >
      <PopoverSingleSelect
        {...field}
        {...inputProps}
        object
        onChange={(val?: TOption) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type PopoverMultiSelectWithObjV2Props<TOption extends PopoverSelectOption> =
  Omit<PopoverMultiSelectProps<true, true, TOption>, "object"> & {
    onValueChange?: (val?: TOption[]) => void;
  };

export const PopoverMultiSelectWithObjV2 = <
  TFormType extends FieldValues,
  TOption extends PopoverSelectOption,
>(
  props: InputElementProps<
    TFormType,
    PopoverMultiSelectWithObjV2Props<TOption>
  >,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    PopoverMultiSelectWithObjV2Props<TOption>
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      disabled={inputProps.disabled}
    >
      <PopoverMultiSelect
        {...field}
        {...inputProps}
        object
        onChange={(val?: TOption[]) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type PopoverMultiSelectV2Props<TOption extends PopoverSelectOption> =
  PopoverMultiSelectProps<true, false, TOption> & {
    onValueChange?: (val: SelectValue) => void;
  };

export const PopoverMultiSelectV2 = <
  TFormType extends FieldValues,
  TOption extends PopoverSelectOption = PopoverSelectOption,
>(
  props: InputElementProps<TFormType, PopoverMultiSelectV2Props<TOption>>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    PopoverMultiSelectV2Props<TOption>
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <PopoverMultiSelect
        object={false}
        {...field}
        {...inputProps}
        onChange={(val: string[]) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type PopoverDynamicSingleSelectV2Props<TOption extends PopoverSelectOption> =
  PopoverSingleSelectProps<false, false, TOption> & {
    onValueChange?: (val: string | null | undefined) => void;
  };

export const PopoverDynamicSingleSelectV2 = <
  TFormType extends FieldValues,
  TOption extends PopoverSelectOption,
>(
  props: InputElementProps<
    TFormType,
    PopoverDynamicSingleSelectV2Props<TOption>
  >,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    PopoverDynamicSingleSelectV2Props<TOption>
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      disabled={inputProps.disabled}
    >
      <PopoverSingleSelect
        {...field}
        {...inputProps}
        onChange={(val: string | null | undefined) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type PopoverDynamicSingleSelectWithObjV2Props<
  TOption extends PopoverSelectOption,
> = Omit<PopoverSingleSelectProps<false, true, TOption>, "object"> & {
  onValueChange?: (val?: TOption) => void;
};

export const PopoverDynamicSingleSelectWithObjV2 = <
  TFormType extends FieldValues,
  TOption extends PopoverSelectOption,
>(
  props: InputElementProps<
    TFormType,
    PopoverDynamicSingleSelectWithObjV2Props<TOption>
  >,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    PopoverDynamicSingleSelectWithObjV2Props<TOption>
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      disabled={inputProps.disabled}
    >
      <PopoverSingleSelect
        {...field}
        {...inputProps}
        object
        onChange={(val?: TOption) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};
