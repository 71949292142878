/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelecomTwilioSMSRequestBody
 */
export interface TelecomTwilioSMSRequestBody {
    /**
     * The text body of the message
     * @type {string}
     * @memberof TelecomTwilioSMSRequestBody
     */
    Body: string;
    /**
     * The phone number that sent the message
     * @type {string}
     * @memberof TelecomTwilioSMSRequestBody
     */
    From: string;
    /**
     * Twilio's unique ID of the message
     * @type {string}
     * @memberof TelecomTwilioSMSRequestBody
     */
    MessageSid: string;
    /**
     * The reserved keyword used as part of an advanced opt-out flow
     * @type {string}
     * @memberof TelecomTwilioSMSRequestBody
     */
    OptOutType?: TelecomTwilioSMSRequestBodyOptOutTypeEnum;
    /**
     * The phone number that the message was sent to. Should be one of our Twilio active numbers.
     * @type {string}
     * @memberof TelecomTwilioSMSRequestBody
     */
    To: string;
}

/**
* @export
* @enum {string}
*/
export enum TelecomTwilioSMSRequestBodyOptOutTypeEnum {
    Start = 'START',
    Stop = 'STOP',
    Help = 'HELP'
}

export function TelecomTwilioSMSRequestBodyFromJSON(json: any): TelecomTwilioSMSRequestBody {
    return TelecomTwilioSMSRequestBodyFromJSONTyped(json, false);
}

export function TelecomTwilioSMSRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelecomTwilioSMSRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Body': json['Body'],
        'From': json['From'],
        'MessageSid': json['MessageSid'],
        'OptOutType': !exists(json, 'OptOutType') ? undefined : json['OptOutType'],
        'To': json['To'],
    };
}

export function TelecomTwilioSMSRequestBodyToJSON(value?: TelecomTwilioSMSRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Body': value.Body,
        'From': value.From,
        'MessageSid': value.MessageSid,
        'OptOutType': value.OptOutType,
        'To': value.To,
    };
}

