import type { SVGProps } from "react";
import * as React from "react";
const SvgEnvelopeClock = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 3.5H4.75A2.752 2.752 0 0 0 2 6.25v7.5a2.752 2.752 0 0 0 2.75 2.75h4.555a.75.75 0 0 0 0-1.5H4.75c-.689 0-1.25-.561-1.25-1.25V8.021l5.654 3.119a1.743 1.743 0 0 0 1.691.001l5.655-3.12V9.71a.75.75 0 0 0 1.5 0V6.251A2.753 2.753 0 0 0 15.25 3.5Z"
    />
    <path
      fill="currentColor"
      d="M15 11c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4Zm2.312 4.95a.749.749 0 0 1-.978.409l-1.619-.665A.751.751 0 0 1 14.25 15v-1.75a.75.75 0 0 1 1.5 0v1.247l1.154.474a.75.75 0 0 1 .408.979Z"
    />
  </svg>
);
export default SvgEnvelopeClock;
