/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ZendeskConfig,
    ZendeskConfigFromJSON,
    ZendeskConfigFromJSONTyped,
    ZendeskConfigToJSON,
} from './ZendeskConfig';

/**
 * 
 * @export
 * @interface IntegrationsZendeskUpdateConfigResponseBody
 */
export interface IntegrationsZendeskUpdateConfigResponseBody {
    /**
     * 
     * @type {ZendeskConfig}
     * @memberof IntegrationsZendeskUpdateConfigResponseBody
     */
    zendesk_config: ZendeskConfig;
}

export function IntegrationsZendeskUpdateConfigResponseBodyFromJSON(json: any): IntegrationsZendeskUpdateConfigResponseBody {
    return IntegrationsZendeskUpdateConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsZendeskUpdateConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsZendeskUpdateConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zendesk_config': ZendeskConfigFromJSON(json['zendesk_config']),
    };
}

export function IntegrationsZendeskUpdateConfigResponseBodyToJSON(value?: IntegrationsZendeskUpdateConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zendesk_config': ZendeskConfigToJSON(value.zendesk_config),
    };
}

