/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackResponseRating,
    FeedbackResponseRatingFromJSON,
    FeedbackResponseRatingFromJSONTyped,
    FeedbackResponseRatingToJSON,
} from './FeedbackResponseRating';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * Any other comments that the user wanted to provide
     * @type {string}
     * @memberof Feedback
     */
    comments: string;
    /**
     * When the feedback was created
     * @type {Date}
     * @memberof Feedback
     */
    created_at: Date;
    /**
     * Unique id of the feedback
     * @type {string}
     * @memberof Feedback
     */
    id: string;
    /**
     * ID that of the incident that this feedback belongs to
     * @type {string}
     * @memberof Feedback
     */
    incident_id: string;
    /**
     * Whether the submission was anonymous
     * @type {boolean}
     * @memberof Feedback
     */
    is_anonymous: boolean;
    /**
     * Organisation ID that the incident and user belong to
     * @type {string}
     * @memberof Feedback
     */
    organisation_id: string;
    /**
     * 
     * @type {FeedbackResponseRating}
     * @memberof Feedback
     */
    rating_communication: FeedbackResponseRating;
    /**
     * 
     * @type {FeedbackResponseRating}
     * @memberof Feedback
     */
    rating_coordination: FeedbackResponseRating;
    /**
     * 
     * @type {FeedbackResponseRating}
     * @memberof Feedback
     */
    rating_response: FeedbackResponseRating;
    /**
     * When the feedback was last updated
     * @type {Date}
     * @memberof Feedback
     */
    updated_at: Date;
    /**
     * 
     * @type {User}
     * @memberof Feedback
     */
    user: User;
}

export function FeedbackFromJSON(json: any): Feedback {
    return FeedbackFromJSONTyped(json, false);
}

export function FeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): Feedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comments': json['comments'],
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'is_anonymous': json['is_anonymous'],
        'organisation_id': json['organisation_id'],
        'rating_communication': FeedbackResponseRatingFromJSON(json['rating_communication']),
        'rating_coordination': FeedbackResponseRatingFromJSON(json['rating_coordination']),
        'rating_response': FeedbackResponseRatingFromJSON(json['rating_response']),
        'updated_at': (new Date(json['updated_at'])),
        'user': UserFromJSON(json['user']),
    };
}

export function FeedbackToJSON(value?: Feedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comments': value.comments,
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'is_anonymous': value.is_anonymous,
        'organisation_id': value.organisation_id,
        'rating_communication': FeedbackResponseRatingToJSON(value.rating_communication),
        'rating_coordination': FeedbackResponseRatingToJSON(value.rating_coordination),
        'rating_response': FeedbackResponseRatingToJSON(value.rating_response),
        'updated_at': (value.updated_at.toISOString()),
        'user': UserToJSON(value.user),
    };
}

