/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteIncidentTemplatePayload,
    AlertRouteIncidentTemplatePayloadFromJSON,
    AlertRouteIncidentTemplatePayloadFromJSONTyped,
    AlertRouteIncidentTemplatePayloadToJSON,
} from './AlertRouteIncidentTemplatePayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface AlertRoutesPreviewIncidentRequestBody
 */
export interface AlertRoutesPreviewIncidentRequestBody {
    /**
     * The ID of the alert you'd you like to use to preview the incident
     * @type {string}
     * @memberof AlertRoutesPreviewIncidentRequestBody
     */
    alert_id: string;
    /**
     * The expressions used in this template
     * @type {Array<ExpressionPayload>}
     * @memberof AlertRoutesPreviewIncidentRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * 
     * @type {AlertRouteIncidentTemplatePayload}
     * @memberof AlertRoutesPreviewIncidentRequestBody
     */
    template?: AlertRouteIncidentTemplatePayload;
}

export function AlertRoutesPreviewIncidentRequestBodyFromJSON(json: any): AlertRoutesPreviewIncidentRequestBody {
    return AlertRoutesPreviewIncidentRequestBodyFromJSONTyped(json, false);
}

export function AlertRoutesPreviewIncidentRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesPreviewIncidentRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_id': json['alert_id'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'template': !exists(json, 'template') ? undefined : AlertRouteIncidentTemplatePayloadFromJSON(json['template']),
    };
}

export function AlertRoutesPreviewIncidentRequestBodyToJSON(value?: AlertRoutesPreviewIncidentRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_id': value.alert_id,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'template': AlertRouteIncidentTemplatePayloadToJSON(value.template),
    };
}

