import {
  Banner,
  BannerTheme,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
} from "@incident-ui";
import React from "react";
import { useIdentity } from "src/contexts/IdentityContext";

export const OrgCustomBanner = (): React.ReactElement | null => {
  const { identity } = useIdentity();

  // We only want to show this banner if we're logged in and have a custom banner message
  if (
    !identity ||
    !identity.organisation_id ||
    !identity.custom_banner_message
  ) {
    return null;
  }

  return (
    <Banner
      theme={BannerTheme.Info}
      iconNode={
        identity.organisation_icon_url ? (
          <SlackTeamAvatar
            name={identity.organisation_name}
            url={identity.organisation_icon_url}
            size={IconSize.Medium}
          />
        ) : undefined
      }
      icon={identity.organisation_icon_url ? undefined : IconEnum.Exclamation}
    >
      {identity.custom_banner_message}
    </Banner>
  );
};
