import type { SVGProps } from "react";
import * as React from "react";
const SvgShieldExclamation = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.783 3.813-5.25-1.68a1.74 1.74 0 0 0-1.066 0l-5.251 1.68A1.744 1.744 0 0 0 3 5.48V12c0 3.508 4.946 5.379 6.46 5.869a1.757 1.757 0 0 0 1.078 0C12.054 17.38 17 15.51 17 12.002V5.48c0-.764-.489-1.434-1.217-1.667ZM9.25 6.75a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-1.5 0v-3.5ZM10 14c-.551 0-1-.45-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .55-.449 1-1 1Z"
    />
  </svg>
);
export default SvgShieldExclamation;
