import {
  IncidentsListContextProvider,
  useGetIncidents,
} from "@incident-shared/incidents";
import {
  Icon,
  IconEnum,
  Loader,
  OrgAwareLink,
  StackedList,
  Txt,
} from "@incident-ui";
import pluralize from "pluralize";
import { CondensedIncidentListItem } from "src/components/@shared/incidents/CondensedIncidentListItem";

export const WorkflowsDeploySettingsLiveIncidents = () => {
  const { incidents, totalNumberOfIncidents, isLoading } = useGetIncidents({
    filters: [],
    fixedFilters: { pageSize: 5, statusCategory: { one_of: ["active"] } },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Txt>
        You have {totalNumberOfIncidents} active{" "}
        {pluralize("incident", totalNumberOfIncidents)}:
      </Txt>
      <IncidentsListContextProvider>
        <StackedList>
          {incidents.map((incident) => {
            return (
              <CondensedIncidentListItem
                key={incident.id}
                incident={incident}
                showIncidentTypes={false}
                openInNewTab
                hideIncidentStatus
                hideArrow
              />
            );
          })}
        </StackedList>
      </IncidentsListContextProvider>
      {(totalNumberOfIncidents || 0) > 5 && (
        <div className="flex flex-center-y text-content-tertiary text-sm hover:text-content-primary !mt-2">
          <OrgAwareLink to={"/"} target="_blank">
            {`View all ${totalNumberOfIncidents} active incidents`}
          </OrgAwareLink>
          <Icon id={IconEnum.ChevronRight} />
        </div>
      )}
    </>
  );
};
