import { IconEnum } from "@incident-ui";
import { CommandPaletteItem } from "@incident-ui/CommandPalette/CommandPalette";
import { useSettings } from "src/hooks/useSettings";

import { BulkAssignRolesForm } from "./BulkAssignRoles";
import { BulkDeclineForm } from "./BulkDecline";
import { BulkMarkAsTestForm } from "./BulkMarkAsTest";
import { BulkSetIncidentSeverityForm } from "./BulkSetIncidentSeverity";
import { BulkSetIncidentStatusForm } from "./BulkSetIncidentStatus";
import { BulkUpdateCustomFieldsForm } from "./BulkUpdateCustomFields";
import { BulkUpdateIncidentTypesForm } from "./BulkUpdateIncidentTypes";

export type BulkAction = {
  label: string;
  icon: IconEnum;
  name: string;
  form?: (props: {
    incidentIDs: string[];
    onClose: () => void;
    onSubmit: () => void;
  }) => React.ReactElement;
  unavailable?: boolean;
  shouldInclude?: CommandPaletteItem["shouldInclude"];
};

export const useBulkActions = (): BulkAction[] => {
  const { settings } = useSettings();

  let actions = [
    {
      label: "Update custom field",
      icon: IconEnum.CustomField,
      name: "update_custom_fields",
      form: BulkUpdateCustomFieldsForm,
    },
    {
      label: "Set severity",
      icon: IconEnum.Severity,
      name: "set_incident_severity",
      form: BulkSetIncidentSeverityForm,
    },
    {
      label: "Set status",
      icon: IconEnum.Status,
      name: "set_incident_status",
      form: BulkSetIncidentStatusForm,
    },
    {
      label: "Assign role",
      icon: IconEnum.User,
      name: "assign_roles",
      form: BulkAssignRolesForm,
    },
    {
      label: "Update types",
      icon: IconEnum.IncidentType,
      name: "incident_types",
      form: BulkUpdateIncidentTypesForm,
    },
    {
      label: "Mark as test",
      icon: IconEnum.Test,
      name: "mark_as_test",
      form: BulkMarkAsTestForm,
    },
    {
      label: "Decline",
      icon: IconEnum.Close,
      name: "decline_incidents",
      form: BulkDeclineForm,
    },
    {
      label: "Test an AI Prompt",
      icon: IconEnum.Robot,
      name: "test_ai_prompt",
      shouldInclude: (identity) => {
        return identity.organisation_is_staff ?? false;
      },
    },
  ];
  if (!settings?.misc.incident_types_enabled) {
    actions = actions.filter((action) => action.name !== "incident_types");
  }
  return actions;
};
