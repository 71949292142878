import { addDays, format, parse } from "date-fns";

import { DateTimeFormType } from "./DateTimeInput";

const DATE_INPUT_FORMAT = "yyyy-MM-dd";
const TIME_INPUT_FORMAT = "HH:mm";

// For configuring pay configurations, we want to be handling DateTimes without time zones.
// We apply the time zones right at the last minute in the backend, when we apply the rules to
// a particular schedule.
// Javacsript deliberately makes this very difficult for you to do - it (and our libraries) assume
// that you really want to be working in local time zones.
// This is a file full of sad and scary hacks to make this work for this particular use-case.

// fromISOUTCDateStr takes a UTC date string (like 2022-05-12T00:00:00Z) and converts it
// into our form type { date: "2022-05-12", time: "00:00" }, and uses the provided isEnd.
// Note that it ignores all time zones (deliberately) so it'll do the same in any location.
export const fromISOUTCDateStr = (
  d: string,
  isEnd: boolean,
): DateTimeFormType => {
  // This is the biggest hack of the lot. It's really hard to get date-fns to parse
  // our ISO string as a UTC date. So instead, we do the dumb thing, and chop it up.
  let dateStr = d.slice(0, 10); // e.g. 2022-05-26
  const timeStr = d.slice(11, 16); // e.g. 00:00

  // To make it even worse, if you have an end date which is saved as 13/5/2022 00:00
  // we actually want to parse that as 12/5/2022, so we render the thing that makes sense
  // in the UI. Aren't we smart. So, to do that, we do some even nastier hacking.
  if (timeStr === "00:00" && isEnd) {
    // So now we want to minus a day from our date. Let's parse it, take away the day, and
    // reformat it (mad).
    const parsedDate = parse(dateStr, DATE_INPUT_FORMAT, new Date());
    const newDate = addDays(parsedDate, -1);
    dateStr = format(newDate, DATE_INPUT_FORMAT);
  }

  return {
    date: dateStr,
    time: timeStr,
    isEnd,
  };
};

// toUTCISODateStr takes our form type and converts it to a UTC ISO string which we
// can send to the backend. Again, I can't get date-fns to do this, so I've done
// it manually which is fairly scary.
export const toUTCISODateStr = (d: DateTimeFormType): string => {
  let dateStr = d.date;
  // To make it even worse, if you have an end date which is stored in our form config as 12/5/2022 00:00
  // we actually want to send that to the API as 13/5/2022.
  if (d.time === "00:00" && d.isEnd) {
    // So now we want to minus a day from our date. Let's parse it, take away the day, and
    // reformat it (mad).
    const parsedDate = parse(dateStr, DATE_INPUT_FORMAT, new Date());
    const newDate = addDays(parsedDate, 1);
    dateStr = format(newDate, DATE_INPUT_FORMAT);
  }

  return `${dateStr}T${d.time}:00Z`;
};

// toDateObjFromUTC takes our form type and converts it to a Date, interpreting the
// dates as UTC. I'm not yet sure this is a good idea.
// This is useful if you want to use date-fns (e.g. is date a after date b).
export const toDateObjFromUTC = (d: DateTimeFormType): Date => {
  let date = parse(
    `${d.date} ${d.time}Z`,
    `${DATE_INPUT_FORMAT} ${TIME_INPUT_FORMAT}X`,
    new Date(),
  );
  // If it's an end date, we interpret '00:00' as the end of the day, instead
  // of the start of the day - that means it's really the start of the following
  // day.
  if (d.time === "00:00" && d.isEnd) {
    date = addDays(date, 1);
  }
  return date;
};

// toDateObjFromLocal takes our form type and converts it to a Date, interpreting the
// dates as a local timestamp.
// This is useful if you want to use date-fns (e.g. is date a after date b).
export const toDateObjFromLocal = (d: DateTimeFormType): Date => {
  let date = parse(
    `${d.date} ${d.time}`,
    `${DATE_INPUT_FORMAT} ${TIME_INPUT_FORMAT}`,
    new Date(),
  );
  // If it's an end date, we interpret '00:00' as the end of the day, instead
  // of the start of the day - that means it's really the start of the following
  // day.
  if (d.time === "00:00" && d.isEnd) {
    date = addDays(date, 1);
  }
  return date;
};

export const fromLocalDateObj = (
  date: Date,
  isEnd: boolean,
): DateTimeFormType => {
  return {
    time: format(date, TIME_INPUT_FORMAT),
    date: format(date, DATE_INPUT_FORMAT),
    isEnd,
  };
};

// displayDateObj allows us to display our date the way someone expects it (e.g.
// with mm/dd/yyyy for Americans). To do this, we parse the date using the browser's
// timezone and then convert it toLocaleDateString.
export const displayDateObj = (
  d: DateTimeFormType,
  withTime = true,
): string => {
  const date = parse(
    `${d.date} ${d.time}`,
    `${DATE_INPUT_FORMAT} ${TIME_INPUT_FORMAT}`,
    new Date(),
  );
  const dateStr = date.toLocaleDateString();
  if (!withTime) {
    return dateStr;
  }
  return `${dateStr} (${d.time})`;
};

// displayDateRangeFromLocalObj helps us display date ranges nicely from Local Date formats,
// and tries to strip out complexity where it can (e.g. it'll hide times if they aren't relevant).
export const displayDateRangeFromLocalObj = (
  start: DateTimeFormType,
  end: DateTimeFormType,
): string => {
  if (start.time === "00:00" && end.time === "00:00") {
    // If the start and end are on the same day, and they both say 00:00, we can just return the date.
    if (start.date === end.date) {
      return displayDateObj(start, false);
    }
    // If they aren't the same day, show the two dates with a hyphen
    return `${displayDateObj(start, false)} - ${displayDateObj(end, false)}`;
  }

  // If they're the same day, show just the day and then the times
  if (start.date === end.date) {
    return `${displayDateObj(start, false)} (${start.time} - ${end.time})`;
  }

  // Otherwise, show both fully - e.g. 25/12/2022 (09:00) - 26/12/2022 (17:00)
  return `${displayDateObj(start)} - ${displayDateObj(end)}`;
};

// dateStrToDisplayStr takes a UTC date string for just a date (like 2022-05-12) and converts it
// into a string which can be rendered in the UI, localised (e.g. Americans will have the month first).
export const dateStrToDisplayStr = (d: string): string => {
  const parsedDate = parse(d, DATE_INPUT_FORMAT, new Date());
  return parsedDate.toLocaleDateString();
};
