/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemShare,
    PostmortemShareFromJSON,
    PostmortemShareFromJSONTyped,
    PostmortemShareToJSON,
} from './PostmortemShare';

/**
 * 
 * @export
 * @interface PostmortemSharesListSharesResponseBody
 */
export interface PostmortemSharesListSharesResponseBody {
    /**
     * 
     * @type {Array<PostmortemShare>}
     * @memberof PostmortemSharesListSharesResponseBody
     */
    shares: Array<PostmortemShare>;
}

export function PostmortemSharesListSharesResponseBodyFromJSON(json: any): PostmortemSharesListSharesResponseBody {
    return PostmortemSharesListSharesResponseBodyFromJSONTyped(json, false);
}

export function PostmortemSharesListSharesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemSharesListSharesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shares': ((json['shares'] as Array<any>).map(PostmortemShareFromJSON)),
    };
}

export function PostmortemSharesListSharesResponseBodyToJSON(value?: PostmortemSharesListSharesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shares': ((value.shares as Array<any>).map(PostmortemShareToJSON)),
    };
}

