import { EngineScope, Resource, Step } from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { isEditMode, Mode } from "@incident-shared/forms/v2/formsv2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Txt,
} from "@incident-ui";
import { DrawerBody, DrawerTitle } from "@incident-ui/Drawer/Drawer";
import React, { useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { ErrorResponse, useClient } from "../../../../contexts/ClientContext";
import {
  WorkflowFormData,
  WorkflowStep,
  WorkflowStepGroup,
} from "../common/types";
import { useLoopScope } from "../common/utils";
import {
  StepFormState,
  useWorkflowsSteps,
} from "../create-edit-form/hooks/useStepsController";
import {
  useWorkflowsViewState,
  WorkflowDrawerState,
} from "../create-edit-form/hooks/useViewController";
import { useWorkflowsDeps } from "../create-edit-form/WorkflowsFormContext";
import { prepareStep } from "./prepareStep";
import { validateStep } from "./validateStep";

export const WorkflowEditStepForm = ({
  onClose,
}: {
  onClose: (opts?: { forceClose: boolean }) => void;
}) => {
  const { stepFormState } = useWorkflowsSteps();
  const { getValues } = useFormContext<WorkflowFormData>();
  const { step, stepError } = useFetchAndInitialiseStep();

  if (stepError) return <GenericErrorMessage error={stepError} />;

  if (!step || !stepFormState) {
    return (
      <div className="p-6">
        <Loader />
      </div>
    );
  }

  const selectedGroup = stepFormState?.selectedGroup;
  const initialData =
    selectedGroup != null
      ? getValues(`step_groups.${selectedGroup}`)
      : undefined;

  return (
    <WorkflowEditStepFormInner
      step={step}
      stepFormState={stepFormState}
      onClose={onClose}
      initialData={initialData}
    />
  );
};

const WorkflowEditStepFormInner = ({
  step,
  stepFormState,
  initialData,
  onClose,
}: {
  stepFormState: StepFormState;
  step: Step;
  initialData?: WorkflowStepGroup;
  onClose: (opts?: { forceClose: boolean }) => void;
}): React.ReactElement => {
  const { scope, resources } = useWorkflowsDeps();

  const {
    editStepFormMethods: formMethods,
    onCreateStep,
    onUpdateStepGroup,
  } = useWorkflowsSteps();
  const { handleSubmit, reset, setError } = formMethods;

  const { mode, selectedGroup, selectedStep } = stepFormState;

  const loopScope = useLoopScope(initialData?.forEach, scope);
  const apiClient = useClient();

  const onSubmit = async (rawData: WorkflowStep) => {
    const data = prepareStep({ data: rawData, scope: loopScope, resources });

    const isValid = await validateStep({
      data,
      resources,
      setError,
      apiClient,
    });

    // validateStep will have set the errors if there are any, so
    // we can bail out here.
    if (!isValid) return;

    if (mode === Mode.Create) {
      onCreateStep(selectedGroup, data);
    } else {
      onUpdateStepGroup(selectedGroup, selectedStep, data);
    }
  };

  return (
    <>
      <EditStepFormTitle step={step} mode={mode} onClose={onClose} />
      <DrawerBody className="overflow-y-auto">
        <FormProvider<WorkflowStep> {...formMethods}>
          <form
            className="space-y-4 text-sm"
            onSubmit={(e) => {
              // If validation failed, we need this in order to resubmit the form
              reset({}, { keepValues: true });
              // Because we have a nested form we must prevent the event from bubbling up
              // the DOM and triggering the parent form.
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(onSubmit)();
            }}
          >
            <ParamsInputs step={step} resources={resources} scope={loopScope} />
            <Button
              analyticsTrackingId={
                "workflows-v2-edit-step-form-add-step-button"
              }
              theme={ButtonTheme.Primary}
              type="submit"
            >
              {mode === Mode.Create ? "Add" : "Save"} step
            </Button>
          </form>
        </FormProvider>
      </DrawerBody>
    </>
  );
};

const ParamsInputs = ({
  step,
  resources,
  scope,
}: {
  step: Step;
  resources: Resource[];
  scope: EngineScope;
}): React.ReactElement => {
  return (
    <>
      {step.warning && (
        <Callout theme={CalloutTheme.Warning}>{step.warning}</Callout>
      )}
      <Txt>{step.description}</Txt>
      {step.params.map((param, i) => {
        if (param.infer_reference) {
          // don't show params that need to be inferred, but retain the correct indices!
          return null;
        }

        return (
          <EngineFormElement<WorkflowStep>
            key={i}
            name={`param_bindings.${i}`}
            resources={[...resources, ...resources]}
            resourceType={param.type}
            array={param.array}
            label={param.label}
            description={param.description}
            scope={scope}
            required={!param.optional}
            showPlaceholder
            mode="variables_and_expressions"
          />
        );
      })}
    </>
  );
};

// EditStepFormTitle controls the title and the back button
const EditStepFormTitle = ({
  step,
  mode,
  onClose,
}: {
  step: Step;
  mode: Mode;
  onClose: (opts?: { forceClose: boolean }) => void;
}): React.ReactElement => {
  const { onOpenDrawer: onOpenDrawer } = useWorkflowsViewState();
  const { editStepFormMethods } = useWorkflowsSteps();

  return (
    <DrawerTitle
      title={step.label}
      titleAccessory={
        isEditMode(mode) ? null : (
          <Button
            analyticsTrackingId="workflowv2-edit-step-back-button"
            theme={ButtonTheme.Naked}
            onClick={() => {
              onOpenDrawer(WorkflowDrawerState.AddStep);
              editStepFormMethods.reset({}, { keepValues: false });
            }}
            className="mt-1"
            title="Go back to choosing a step"
            icon={IconEnum.Back}
          />
        )
      }
      compact
      // NOTE: If we press on the close or back icon, we want to close the modal without
      // worrying about losing the form's state.
      onClose={() => onClose({ forceClose: true })}
    />
  );
};

const useFetchAndInitialiseStep = (): {
  step?: Step;
  stepError?: ErrorResponse;
} => {
  const { trigger } = useWorkflowsDeps();
  const { stepFormState, editStepFormMethods, newStepName } =
    useWorkflowsSteps();

  const mode = stepFormState?.mode;

  let chosenStepName = newStepName;
  if (mode && isEditMode(mode)) {
    chosenStepName = editStepFormMethods.getValues().name;
  }

  const readyToInitialise = !!trigger && !!mode && !!chosenStepName;

  // Make sure we only initialise default values once
  const [defaultValuesInitialised, setDefaultValuesInitialised] =
    useState(false);

  const { setValue } = editStepFormMethods;

  const { data: stepData, error: stepError } = useAPI(
    readyToInitialise ? "workflowsShowStep" : null,
    readyToInitialise
      ? { name: chosenStepName as string, trigger: trigger.name as string }
      : { name: "", trigger: "" },
    {
      onSuccess: ({ step }) => {
        setValue<"label">("label", step.label);
        setValue<"params">("params", step.params);
        setValue<"name">("name", step.name);
        if (mode === Mode.Create && !defaultValuesInitialised) {
          step.params.forEach((param, idx) => {
            if (param.default_value) {
              setValue<`param_bindings.${number}`>(
                `param_bindings.${idx}`,
                param.default_value,
              );
            }
          });
          setDefaultValuesInitialised(true);
        }
      },
    },
  );

  return { step: stepData?.step, stepError };
};
