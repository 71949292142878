import { ScopeNameEnum } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { PolicyCreateEditFormData } from "../common/PolicyCreateEditForm";

type NameAndDescriptionData = Pick<
  PolicyCreateEditFormData,
  "name" | "description"
>;

export const PolicyNameAndDescSection = ({
  showDrawer,
  setShowDrawer,
  mode,
}: {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  mode: Mode;
}) => {
  const formMethods = useFormContext<PolicyCreateEditFormData>();

  const [name, description] = formMethods.watch(["name", "description"]);

  const onSubmit = (data: NameAndDescriptionData) => {
    formMethods.setValue<"name">("name", data.name, {
      shouldDirty: true,
    });
    formMethods.setValue<"description">("description", data.description, {
      shouldDirty: true,
    });
    setShowDrawer(false);
  };

  return (
    <>
      <AnimatePresence>
        {showDrawer && (
          <NameAndDescriptionDrawer
            onSubmit={onSubmit}
            onClose={() => setShowDrawer(false)}
            initialData={{ name, description }}
          />
        )}
      </AnimatePresence>
      {mode === Mode.Create ? (
        <>
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            required="Please enter a name"
            data-testid="name-input"
            placeholder="e.g. Major incidents should close follow-ups within 3 days"
          />
          <TextareaV2
            formMethods={formMethods}
            name="description"
            placeholder="e.g. All major incidents should close their follow-ups within 3 days, as required by our ISO27001 certification."
            label="Description"
            required="Please enter a description"
            data-testid="description-textbox"
          />
        </>
      ) : (
        <div className="flex flex-col gap-2 group w-full">
          <div className="flex gap-2 w-full">
            <Form.InputWrapper name="name">
              <div className="text-base-bold">{name}</div>
            </Form.InputWrapper>
            <Button
              title="Edit"
              analyticsTrackingId={null}
              icon={IconEnum.Edit}
              onClick={() => setShowDrawer(true)}
              theme={ButtonTheme.Naked}
              className="hidden group-hover:flex"
            />
          </div>
          <Form.InputWrapper name="description">
            {description && (
              <div className="text-sm-normal text-content-secondary">
                {description}
              </div>
            )}
          </Form.InputWrapper>
        </div>
      )}
    </>
  );
};

const NameAndDescriptionDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
}: {
  onSubmit: (data: NameAndDescriptionData) => void;
  onClose: () => void;
  initialData?: NameAndDescriptionData;
}) => {
  const formMethods = useForm<NameAndDescriptionData>({
    defaultValues: initialData,
  });

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents className="overflow-hidden">
        <DrawerTitle
          title="Name & Description"
          onClose={onClose}
          icon={IconEnum.Shield}
        />
        <DrawerBody className="overflow-y-hidden">
          <Form.Root
            fullHeight
            formMethods={formMethods}
            onSubmit={onSubmit}
            id="name-and-description"
          >
            <InputV2
              formMethods={formMethods}
              name="name"
              label="Name"
              helptext="This will be shown next to violations."
              required="Please enter a name"
              data-testid="name-input"
              placeholder="e.g. Major incidents should close follow-ups within 3 days"
            />
            <TextareaV2
              formMethods={formMethods}
              name="description"
              placeholder="e.g. All major incidents should close their follow-ups within 3 days, as required by our ISO27001 certification."
              label="Description"
              helptext="More detail about the policy, used to help explain why someone should care about it."
              required="Please enter a description"
              data-testid="description-textbox"
            />
          </Form.Root>
        </DrawerBody>
        <DrawerFooter className="flex gap-2 justify-end">
          <Button onClick={() => onClose()} analyticsTrackingId={null}>
            Back
          </Button>
          <GatedButton
            form="name-and-description"
            requiredScope={ScopeNameEnum.PoliciesCreate}
            type="submit"
            theme={ButtonTheme.Primary}
            analyticsTrackingId={null}
          >
            Apply
          </GatedButton>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};
