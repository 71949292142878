import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GenericErrorMessage, ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { IncidentsListSortByEnum } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { FormProps } from "./DemoMagicRoute";

export const CreateScribeCallTranscriptForm = ({
  onClose,
  onSubmit,
}: FormProps) => {
  const formMethods = useForm<{ incident_id: string }>();

  const { data, isLoading, error } = useAPI("incidentsList", {
    pageSize: 20,
    sortBy: IncidentsListSortByEnum.NewestFirst,
  });

  const options = data?.incidents.map((incident) => ({
    label: `${incident.external_id}: ${incident.name}`,
    value: incident.id,
  }));

  return (
    <Form.Modal
      title="Create Scribe Call Transcript"
      analyticsTrackingId={null}
      formMethods={formMethods}
      onClose={onClose}
      onSubmit={() => onSubmit({ payload: formMethods.getValues() })}
      loading={isLoading}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          onClose={onClose}
          confirmButtonText="Create"
        />
      }
    >
      {error && <GenericErrorMessage />}
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Incident"
        required
        name="incident_id"
        options={options ?? []}
        placeholder="Select an incident"
        isClearable={false}
      />
    </Form.Modal>
  );
};
