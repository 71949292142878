/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentTimelineEditTimelineItemRequestBody
 */
export interface IncidentTimelineEditTimelineItemRequestBody {
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentTimelineEditTimelineItemRequestBody
     */
    description?: TextDocumentPayload;
    /**
     * Timestamp of where this item should sit on the timeline
     * @type {Date}
     * @memberof IncidentTimelineEditTimelineItemRequestBody
     */
    timestamp: Date;
    /**
     * Title of the timeline item
     * @type {string}
     * @memberof IncidentTimelineEditTimelineItemRequestBody
     */
    title: string;
}

export function IncidentTimelineEditTimelineItemRequestBodyFromJSON(json: any): IncidentTimelineEditTimelineItemRequestBody {
    return IncidentTimelineEditTimelineItemRequestBodyFromJSONTyped(json, false);
}

export function IncidentTimelineEditTimelineItemRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimelineEditTimelineItemRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : TextDocumentPayloadFromJSON(json['description']),
        'timestamp': (new Date(json['timestamp'])),
        'title': json['title'],
    };
}

export function IncidentTimelineEditTimelineItemRequestBodyToJSON(value?: IncidentTimelineEditTimelineItemRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': TextDocumentPayloadToJSON(value.description),
        'timestamp': (value.timestamp.toISOString()),
        'title': value.title,
    };
}

