import {
  AllIncidentFormData,
  useElementBindings,
} from "@incident-shared/incident-forms";
import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useStatusesForIncidentType } from "src/components/legacy/incident/useIncidentCrudResources";
import {
  IncidentForm,
  IncidentFormLifecycleElementBinding,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum,
  IncidentStatusCategoryEnum,
  IncidentType,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

export const useIncidentFormPreviewBindings = ({
  form,
  incidentTypes,
}: {
  form: IncidentForm;
  incidentTypes: IncidentType[];
}): {
  formMethods: UseFormReturn<AllIncidentFormData>;
  elementBindings: IncidentFormLifecycleElementBinding[];
  isLoading: boolean;
  refreshPreview: () => void;
} => {
  const {
    data: { severities },
    isLoading: severitiesLoading,
  } = useAPI("severitiesList", undefined, { fallbackData: { severities: [] } });

  const defaultType = incidentTypes.find((t) => t.is_default);
  const formMethods = useForm<AllIncidentFormData>({
    defaultValues: {
      incident_type_id: form?.incident_type_id || defaultType?.id,
    },
  });

  const [selectedIncidentTypeId, selectedStatusId] = formMethods.watch([
    "incident_type_id",
    "incident_status_id",
  ]);
  const { statuses, statusesLoading } = useStatusesForIncidentType({
    incidentTypeId: selectedIncidentTypeId,
  });

  const activeStatuses = statuses.filter(
    (x) => x.category === IncidentStatusCategoryEnum.Active,
  );
  const firstActiveStatus = activeStatuses[0];
  useEffect(() => {
    if (activeStatuses.length === 0) {
      return;
    }
    if (activeStatuses.some((status) => status.id === selectedStatusId)) {
      return;
    }
    // If the selected status isn't in the (maybe new) list, select the first
    // valid value.
    formMethods.resetField("incident_status_id", {
      defaultValue: activeStatuses[0].id,
    });
  }, [selectedStatusId, activeStatuses, formMethods]);

  const elementBindingsPayload = {
    incident_form_type:
      form.form_type as unknown as IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum,
    incident_type_id: form.incident_type_id,
    incident_status_id: firstActiveStatus?.id,
    severity_id: severities[0]?.id,
    custom_field_entries: [],
    incident_role_assignments: [],
    show_all_elements_override: true,
  };

  const {
    elementBindings,
    refetch,
    isLoading: bindingsLoading,
  } = useElementBindings<AllIncidentFormData>({
    payload: elementBindingsPayload,
    setValue: formMethods.setValue,
    initialValues: formMethods.formState.defaultValues,
    touchedFields: formMethods.formState.touchedFields,
    manualEdits: [],
  });

  const refreshPreview = () => {
    refetch();
    formMethods.reset();
  };

  return {
    elementBindings,
    refreshPreview,
    formMethods,
    isLoading: severitiesLoading || bindingsLoading || statusesLoading,
  };
};
