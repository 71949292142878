import { AvailableIncidentFormLifecycleElementElementTypeEnum as FormElementType } from "@incident-io/api";
import {
  Incident,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  IncidentStatusCategoryEnum,
} from "src/contexts/ClientContext";

export function reporterMessage({
  incident_role_assignments,
  creator,
}: Incident): string {
  // If the reporter role is set, that's the more interesting bit, but we want
  // to show the "via" as well (e.g. if the API was used to trigger the
  // incident).
  const reporter = incident_role_assignments.find(
    ({ role }) => role.role_type === RoleTypeEnum.Reporter,
  )?.assignee;

  if (reporter) {
    if (creator.api_key) {
      return `Reported by ${reporter.name} (via ${creator.api_key.name})`;
    }

    if (creator.workflow) {
      return `Reported by ${reporter.name} (via ${creator.workflow.name})`;
    }

    if (creator.external_resource) {
      return `Reported by ${reporter.name} (via ${creator.external_resource.resource_type_label})`;
    }

    return `Reported by ${reporter.name}`;
  }

  // We don't have an explicitly-set reporter user, so rely on the creator
  // instead
  return creator.api_key
    ? `Reported from ${creator.api_key.name}`
    : creator.user
    ? `Reported by ${creator.user.name}`
    : creator.workflow
    ? `Reported by ${creator.workflow.name}`
    : creator.external_resource
    ? `Raised from ${creator.external_resource.resource_type_label}`
    : creator.alert
    ? `Raised from ${creator.alert.source_type} alert`
    : "An automation";
}

const nonEditableStatusCategories = [
  IncidentStatusCategoryEnum.Declined,
  IncidentStatusCategoryEnum.Merged,
];

export const incidentInEditableStatus = (incident: Incident): boolean => {
  return !nonEditableStatusCategories.includes(
    incident.incident_status?.category,
  );
};

// Filters down ElementBindings to just name & incident type when a restricted incident type is selected.
// This is used for the declare & accept modals in the dashboard.
export function getRestrictedElementBindings(elementBindings) {
  return elementBindings.filter(
    (binding) =>
      binding.element.available_element.element_type === FormElementType.Name ||
      binding.element.available_element.element_type ===
        FormElementType.IncidentType,
  );
}
