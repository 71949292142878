/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRelationshipDetails,
    IncidentRelationshipDetailsFromJSON,
    IncidentRelationshipDetailsFromJSONTyped,
    IncidentRelationshipDetailsToJSON,
} from './IncidentRelationshipDetails';

/**
 * 
 * @export
 * @interface IncidentRelationship
 */
export interface IncidentRelationship {
    /**
     * Unique identifier of this incident relationship
     * @type {string}
     * @memberof IncidentRelationship
     */
    id: string;
    /**
     * 
     * @type {IncidentRelationshipDetails}
     * @memberof IncidentRelationship
     */
    left_incident_details: IncidentRelationshipDetails;
    /**
     * 
     * @type {IncidentRelationshipDetails}
     * @memberof IncidentRelationship
     */
    right_incident_details: IncidentRelationshipDetails;
    /**
     * Status of the relationship
     * @type {string}
     * @memberof IncidentRelationship
     */
    status: IncidentRelationshipStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentRelationshipStatusEnum {
    Created = 'created',
    Suggested = 'suggested',
    Confirmed = 'confirmed'
}

export function IncidentRelationshipFromJSON(json: any): IncidentRelationship {
    return IncidentRelationshipFromJSONTyped(json, false);
}

export function IncidentRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRelationship {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'left_incident_details': IncidentRelationshipDetailsFromJSON(json['left_incident_details']),
        'right_incident_details': IncidentRelationshipDetailsFromJSON(json['right_incident_details']),
        'status': json['status'],
    };
}

export function IncidentRelationshipToJSON(value?: IncidentRelationship | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'left_incident_details': IncidentRelationshipDetailsToJSON(value.left_incident_details),
        'right_incident_details': IncidentRelationshipDetailsToJSON(value.right_incident_details),
        'status': value.status,
    };
}

