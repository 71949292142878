import { Button, StackedList } from "@incident-ui";
import { currencyStrToCents } from "@incident-ui/Input/CurrencyInput";
import { addDays, addMonths } from "date-fns";
import _ from "lodash";
import { useState } from "react";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { formatCurrency } from "../../../../utils/currency";
import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { parseSimpleDate } from "../ReportGenerator";
import { displayDateRangeFromLocalObj } from "./date-time-helpers";
import { OverrideModal } from "./OnCallPayOverrideModal";
import { OverrideFormType } from "./PayConfigCreateEditForm";
import { PublicHolidaysModal } from "./PublicHolidaysModal";

export const OverridesEditor = ({
  value: overrides = [],
  currency,
  onChange,
  baseRateInCents,
}: {
  value: OverrideFormType[];
  currency: string;
  onChange: (overrides: OverrideFormType[]) => void;
  baseRateInCents: number;
}): React.ReactElement => {
  const {
    showEditOverrideModal,
    showPublicHolidaysModal,
    editingOverride,
    onAddOverride,
    onAddOverridesFromPublicHolidays,
    onEditOverride,
    onDeleteOverride,
    onClosePublicHolidaysModal,
    onCloseEditOverrideModal,
    onOpenPublicHolidaysModal,
    onOpenEditModalForOverride,
    onOpenAddOverrideModal,
  } = useOverridesEditorHooks({ overrides, onChange });

  const today = new Date();
  const publicHolidayWindows = [
    {
      label: "In the last three months",
      interval: {
        start: addMonths(today, -3),
        end: today,
      },
    },
    {
      label: "In the next six months",
      interval: {
        start: addDays(today, 1),
        end: addMonths(today, 6),
      },
    },
  ];

  return (
    <>
      {showEditOverrideModal && (
        <OverrideModal
          onClose={onCloseEditOverrideModal}
          onAdd={onAddOverride}
          onEdit={onEditOverride}
          editingOverride={editingOverride}
          selectedCurrency={currency}
          existingOverrides={overrides}
          baseRateInCents={baseRateInCents}
        />
      )}
      {showPublicHolidaysModal && (
        <PublicHolidaysModal
          onClose={onClosePublicHolidaysModal}
          onAdd={onAddOverridesFromPublicHolidays}
          currency={currency}
          windows={publicHolidayWindows}
          existingOverrides={overrides}
          baseRateInCents={baseRateInCents}
        />
      )}
      <div data-testid="pay-overrides-list">
        <OverridesList
          overrides={overrides}
          currency={currency}
          onDeleteOverride={onDeleteOverride}
          onOpenEditModalForOverride={onOpenEditModalForOverride}
        />
        <div>
          <Button
            analyticsTrackingId="add-override"
            onClick={onOpenPublicHolidaysModal}
          >
            Import from our list of public holidays
          </Button>
          <span className="ml-2 mr-1 text-slate-600 text-sm">{"or"} </span>
          <Button
            analyticsTrackingId="add-override"
            onClick={onOpenAddOverrideModal}
          >
            Add custom holiday rule
          </Button>
        </div>
      </div>
    </>
  );
};

export const OverridesList = ({
  overrides,
  currency,
  onDeleteOverride,
  onOpenEditModalForOverride,
}: {
  overrides: OverrideFormType[];
  currency: string;
  onDeleteOverride: (override: OverrideFormType) => void;
  onOpenEditModalForOverride: (override: OverrideFormType) => void;
}): React.ReactElement => {
  const sortedOverrides = _.sortBy(overrides, (override) =>
    parseSimpleDate(override.start_at.date),
  );

  return (
    <StackedList className="mt-2 mb-4">
      {sortedOverrides.map((override) => {
        return (
          <SettingsListItem
            title={
              <div className="flex-center-y text-sm">
                <span className="grow">{override.name}</span>
                {" ("}
                <span className="tabular-nums">
                  {displayDateRangeFromLocalObj(
                    override.start_at,
                    override.end_at,
                  )}
                </span>
                {")"}
              </div>
            }
            description={
              <div>
                <span className={"tabular-nums"}>
                  {formatCurrency(
                    currency,
                    currencyStrToCents(override.rate_pounds),
                  )}{" "}
                  / hr
                </span>
              </div>
            }
            descriptionPosition="inline"
            key={override.key}
            buttons={{
              requiredScope: ScopeNameEnum.SchedulesCreate,
              edit: {
                onEdit: () => onOpenEditModalForOverride(override),
              },
              delete: {
                onDelete: () => onDeleteOverride(override),
                noDeleteConfirmation: true,
              },
            }}
          />
        );
      })}
    </StackedList>
  );
};

export const useOverridesEditorHooks = ({
  overrides,
  onChange,
}: {
  overrides: OverrideFormType[];
  onChange: (overrides: OverrideFormType[]) => void;
}): {
  canCreateSchedules: boolean;
  editingOverride: OverrideFormType | null;
  showEditOverrideModal: boolean;
  showPublicHolidaysModal: boolean;
  onAddOverride: (override: OverrideFormType) => void;
  onAddOverridesFromPublicHolidays: (newOverrides: OverrideFormType[]) => void;
  onEditOverride: (updatedOverride: OverrideFormType) => void;
  onDeleteOverride: (override: OverrideFormType) => void;
  onClosePublicHolidaysModal: () => void;
  onCloseEditOverrideModal: () => void;
  onOpenPublicHolidaysModal: () => void;
  onOpenAddOverrideModal: () => void;
  onOpenEditModalForOverride: (override: OverrideFormType) => void;
} => {
  const { hasScope } = useIdentity();

  const [showEditOverrideModal, setShowEditOverrideModal] =
    useState<boolean>(false);
  const [editingOverride, setEditingOverride] =
    useState<OverrideFormType | null>(null);

  const [showPublicHolidaysModal, setShowPublicHolidaysModal] =
    useState<boolean>(false);

  const onAddOverride = (override: OverrideFormType) => {
    onChange([...overrides, override]);
    setShowEditOverrideModal(false);
  };

  const onAddOverridesFromPublicHolidays = (
    newOverrides: OverrideFormType[],
  ) => {
    onChange([...overrides, ...newOverrides]);
    setShowPublicHolidaysModal(false);
  };

  const onEditOverride = (updatedOverride: OverrideFormType) => {
    if (!overrides || editingOverride == null) {
      return;
    }

    // Copy overrides and replace the old element to preserve the order
    const updatedOverrides = [...overrides];
    const previousIndex = overrides.findIndex(
      (override) => override.key === editingOverride.key,
    );
    updatedOverrides[previousIndex] = updatedOverride;

    onChange(updatedOverrides);
    setEditingOverride(null);
    setShowEditOverrideModal(false);
  };

  const onDeleteOverride = (override: OverrideFormType) => {
    if (!overrides) {
      return;
    }

    onChange(overrides.filter((other) => other.key !== override.key));
  };

  return {
    canCreateSchedules: hasScope(ScopeNameEnum.SchedulesCreate),
    editingOverride,
    showEditOverrideModal,
    showPublicHolidaysModal,
    onAddOverride,
    onAddOverridesFromPublicHolidays,
    onEditOverride,
    onDeleteOverride,
    onClosePublicHolidaysModal: () => setShowPublicHolidaysModal(false),
    onCloseEditOverrideModal: () => setShowEditOverrideModal(false),
    onOpenPublicHolidaysModal: () => setShowPublicHolidaysModal(true),
    onOpenEditModalForOverride: (override: OverrideFormType) => {
      setEditingOverride(override);
      setShowEditOverrideModal(true);
    },
    onOpenAddOverrideModal: () => {
      setEditingOverride(null);
      setShowEditOverrideModal(true);
    },
  };
};
