import {
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  Heading,
} from "@incident-ui";
import {
  SortableColumn,
  SortableTable,
} from "@incident-ui/Table/SortableTable";
import _ from "lodash";
import { ScheduleReport } from "src/contexts/ClientContext";

import { formatCurrency } from "../../../../../utils/currency";
import { formatDurationFromHours } from "../../../../../utils/datetime";
import { buildAggregate, ShiftAggregate } from "../helpers";
import { ScheduleLink } from "./ScheduleTab";
import { UserLink } from "./UserTab";

export const OverviewTab = ({
  report,
  changeTab,
}: {
  report: ScheduleReport;
  changeTab: (tabID: string, params: Record<string, string>) => void;
}): React.ReactElement => {
  const { totalsByUser, totalsBySchedule, currencies } = buildAggregate(
    report,
    report.schedules,
  );

  const usersTableColumns: SortableColumn<
    Omit<ShiftAggregate, "schedule" | "rate">
  >[] = [
    {
      title: "User",
      key: "user",
      dataIndex: "user",
    },
    {
      title: "Duration",
      key: "hours",
      dataIndex: "hours",
      alignRight: true,
    },
  ];

  currencies.forEach((currency) => {
    const key = `total_${currency}`;
    usersTableColumns.push({
      title: `Total (${currency})`,
      key,
      dataIndex: key,
      sortFn: (rowA, rowB) => {
        return rowA.totals[currency] < rowB.totals[currency] ? 1 : -1;
      },
      alignRight: true,
    });
  });

  return (
    <div className={"mt-2"}>
      {/* Pay by schedule */}
      <div className="mb-6">
        <Heading level={3} className="mb-4" size="small">
          Pay by schedule
        </Heading>
        <DeprecatedTable>
          <DeprecatedTableHeaderRow>
            <DeprecatedTableHeaderCell>Schedule</DeprecatedTableHeaderCell>
            <DeprecatedTableHeaderCell className={"text-right"}>
              Duration
            </DeprecatedTableHeaderCell>
            {currencies.map((currency: string) => {
              return (
                <DeprecatedTableHeaderCell
                  key={currency}
                  className={"text-right"}
                >
                  Total ({currency})
                </DeprecatedTableHeaderCell>
              );
            })}
          </DeprecatedTableHeaderRow>
          <tbody>
            {_.map(totalsBySchedule, ({ schedule, hours, totals }) => {
              return (
                <tr key={schedule.id}>
                  <td>
                    <ScheduleLink schedule={schedule} changeTab={changeTab} />
                  </td>
                  <td className={"text-right"}>
                    {formatDurationFromHours(hours)}
                  </td>
                  {currencies.map((currency: string) => {
                    return (
                      <td key={currency} className={"text-right"}>
                        {formatCurrency(currency, totals[currency])}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              {currencies.map((currency: string, idx: number) => {
                return (
                  <td
                    key={currency}
                    colSpan={idx === 0 ? 3 : undefined}
                    className="text-right text-sm font-semibold text-content-primary"
                  >
                    {formatCurrency(
                      currency,
                      _.sum(
                        totalsBySchedule.map((shift) => shift.totals[currency]),
                      ),
                    )}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </DeprecatedTable>
      </div>

      {/* Pay by users */}
      <div>
        <Heading level={3} className="mb-4" size="small">
          Pay by user
        </Heading>
        <SortableTable
          data={totalsByUser}
          columns={usersTableColumns}
          render={({ user, hours, totals }) => {
            return (
              <tr key={user.external_id}>
                <td>
                  <UserLink user={user} changeTab={changeTab} />
                </td>
                <td className={"text-right"}>
                  {formatDurationFromHours(hours)}
                </td>
                {currencies.map((currency: string) => {
                  return (
                    <td key={currency} className={"text-right"}>
                      {formatCurrency(currency, totals[currency])}
                    </td>
                  );
                })}
              </tr>
            );
          }}
          finalRows={
            <tr>
              {currencies.map((currency: string, idx: number) => {
                return (
                  <td
                    key={currency}
                    colSpan={idx === 0 ? 3 : undefined}
                    className="text-right text-sm font-semibold text-content-primary"
                  >
                    {formatCurrency(
                      currency,
                      _.sum(
                        totalsByUser.map((shift) => shift.totals[currency]),
                      ),
                    )}
                  </td>
                );
              })}
            </tr>
          }
        />
      </div>
    </div>
  );
};
