/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageIncidentSlim
 */
export interface StatusPageIncidentSlim {
    /**
     * A unique ID for this status page incident
     * @type {string}
     * @memberof StatusPageIncidentSlim
     */
    id: string;
    /**
     * A title for the incident
     * @type {string}
     * @memberof StatusPageIncidentSlim
     */
    name: string;
    /**
     * The canonical public URL for viewing this incident
     * @type {string}
     * @memberof StatusPageIncidentSlim
     */
    public_url?: string;
    /**
     * Current status for this incident
     * @type {string}
     * @memberof StatusPageIncidentSlim
     */
    status: StatusPageIncidentSlimStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageIncidentSlimStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function StatusPageIncidentSlimFromJSON(json: any): StatusPageIncidentSlim {
    return StatusPageIncidentSlimFromJSONTyped(json, false);
}

export function StatusPageIncidentSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageIncidentSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'public_url': !exists(json, 'public_url') ? undefined : json['public_url'],
        'status': json['status'],
    };
}

export function StatusPageIncidentSlimToJSON(value?: StatusPageIncidentSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'public_url': value.public_url,
        'status': value.status,
    };
}

