import { Button, StackedList } from "@incident-ui";
import { currencyStrToCents } from "@incident-ui/Input/CurrencyInput";
import { useState } from "react";
import { SchedulePayRule, ScopeNameEnum } from "src/contexts/ClientContext";

import { formatCurrency } from "../../../../utils/currency";
import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { describeRule, PayRuleBadge } from "../OnCallReportView/PayRule";
import { PayRuleFormType } from "./PayConfigCreateEditForm";
import { PayRuleModal } from "./PayRuleModal";

export const PayRulesEditor = ({
  value: payRules,
  onChange,
  currency,
  baseRate,
}: {
  value: PayRuleFormType[];
  onChange: (rules: PayRuleFormType[]) => void;
  currency: string;
  baseRate: string;
}): React.ReactElement => {
  const [showPayRuleModal, setShowPayRuleModal] = useState<boolean>(false);
  const [editingPayRule, setEditingPayRule] = useState<PayRuleFormType | null>(
    null,
  );

  const onAddPayRule = (payRule: PayRuleFormType) => {
    onChange(
      [...payRules, payRule].map((rule, idx) => ({
        ...rule,
        short_id: `Rule ${idx + 1}`,
      })),
    );
    setShowPayRuleModal(false);
  };

  const onEditPayRule = (updatedRule: PayRuleFormType) => {
    if (!payRules || editingPayRule == null) {
      return;
    }

    // Copy payRules and replace the old element to preserve the order
    const updatedPayRules = [...payRules];
    const previousIndex = payRules.findIndex(
      (rule) => rule.key === editingPayRule.key,
    );
    updatedPayRules[previousIndex] = updatedRule;

    onChange(updatedPayRules);
    setEditingPayRule(null);
    setShowPayRuleModal(false);
  };

  const onDeletePayRule = (payRule: PayRuleFormType) => {
    if (!payRules) {
      return;
    }

    onChange(payRules.filter((rule) => rule.key !== payRule.key));
  };

  return (
    <>
      {showPayRuleModal && (
        <PayRuleModal
          onClose={() => setShowPayRuleModal(false)}
          onAdd={onAddPayRule}
          onEdit={onEditPayRule}
          editingRule={editingPayRule}
          selectedCurrency={currency}
          baseRate={baseRate}
        />
      )}
      <div data-testid="pay-rules-list">
        <StackedList className="my-4">
          {payRules.map((rule) => {
            return (
              <SettingsListItem
                iconNode={<PayRuleBadge shortID={rule.short_id} />}
                title={describeRule({
                  ...rule,
                  rate_cents: currencyStrToCents(rule.rate_pounds),
                } as unknown as SchedulePayRule)}
                description={
                  <>
                    {formatCurrency(
                      currency,
                      currencyStrToCents(rule.rate_pounds),
                    )}{" "}
                    / hr
                  </>
                }
                buttons={{
                  requiredScope: ScopeNameEnum.SchedulesCreate,
                  edit: {
                    onEdit: () => {
                      setEditingPayRule(rule);
                      setShowPayRuleModal(true);
                    },
                  },
                  delete: {
                    onDelete: () => onDeletePayRule(rule),
                    noDeleteConfirmation: true,
                  },
                }}
                key={rule.key}
              />
            );
          })}
        </StackedList>
        <Button
          analyticsTrackingId="add-pay-rule"
          onClick={() => setShowPayRuleModal(true)}
        >
          Add new weekly rule
        </Button>
      </div>
    </>
  );
};
