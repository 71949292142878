import { SlackTeamConfig } from "@incident-io/api";
import { Icon, IconEnum, IconSize, SlackTeamAvatar } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";

export function buildSlackTeamOptions({
  slackTeamConfigs,
  selectedSlackTeamID,
}: {
  slackTeamConfigs: SlackTeamConfig[];
  selectedSlackTeamID: string | undefined;
}): {
  selectedSlackTeam: SlackTeamConfig | undefined;
  slackTeamOptions: SelectOption[];
} {
  const selectedSlackTeam = slackTeamConfigs.find(
    (it) => it.slack_team_id === selectedSlackTeamID,
  );

  const slackTeamOptions: SelectOption[] = slackTeamConfigs.map((it) => ({
    value: it.slack_team_id,
    label: it.name,
    sort_key: it.name,
    renderFn: () => {
      return (
        <div className="flex items-center space-x-1.5">
          {it.icon_url ? (
            <SlackTeamAvatar
              url={it.icon_url}
              size={IconSize.Large}
              name={it.name}
            />
          ) : (
            <Icon id={IconEnum.Slack} size={IconSize.Large} />
          )}
          <p className="grow">{it.name}</p>
        </div>
      );
    },
  }));

  return { slackTeamOptions, selectedSlackTeam };
}
