/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface EscalationCreatedTimelineData
 */
export interface EscalationCreatedTimelineData {
    /**
     * 
     * @type {Actor}
     * @memberof EscalationCreatedTimelineData
     */
    creator?: Actor;
    /**
     * The number of seconds the escalation was deferred for
     * @type {number}
     * @memberof EscalationCreatedTimelineData
     */
    deferred_seconds: number;
}

export function EscalationCreatedTimelineDataFromJSON(json: any): EscalationCreatedTimelineData {
    return EscalationCreatedTimelineDataFromJSONTyped(json, false);
}

export function EscalationCreatedTimelineDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationCreatedTimelineData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': !exists(json, 'creator') ? undefined : ActorFromJSON(json['creator']),
        'deferred_seconds': json['deferred_seconds'],
    };
}

export function EscalationCreatedTimelineDataToJSON(value?: EscalationCreatedTimelineData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': ActorToJSON(value.creator),
        'deferred_seconds': value.deferred_seconds,
    };
}

