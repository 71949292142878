import { IdentitySelfResponseBody } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Modal,
  ModalContent,
  Txt,
} from "@incident-ui";
import { captureException } from "@sentry/react";
import React from "react";

import { useAPIMutation } from "../../../../utils/swr";

export const DisconnectModal = ({
  isOpen,
  onClose,
  refetchIdentity,
}: {
  isOpen: boolean;
  onClose: () => void;
  refetchIdentity: () => Promise<IdentitySelfResponseBody | undefined>;
}): React.ReactElement => {
  const {
    trigger: removeToken,
    genericError: removeTokenError,
    isMutating: removingToken,
  } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.authRemoveSlackUserToken();
    },
    {
      onSuccess: async () => {
        // Identity is not in SWR yet
        await refetchIdentity();
        onClose();
      },
    },
  );

  if (removeTokenError) {
    captureException(removeTokenError);
  }

  return (
    <Modal
      title={"Disconnect Slack user"}
      isOpen={isOpen}
      onClose={onClose}
      analyticsTrackingId={"disconnect-slack-user"}
    >
      <ModalContent className="space-y-2">
        <Txt>
          Without privileged Slack access, we won&apos;t be able to perform some
          actions in your Slack workspace, and these may require manual
          intervention.
        </Txt>
        {removeTokenError ? (
          <GenericErrorMessage />
        ) : (
          <div className="flex justify-center mt-4">
            <Button
              theme={ButtonTheme.Destroy}
              analyticsTrackingId="disconnect-slack-user-token"
              loading={removingToken}
              onClick={() => removeToken({})}
              icon={IconEnum.Delete}
            >
              Disconnect Slack user
            </Button>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};
