/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogEntriesLookup
 */
export interface CatalogEntriesLookup {
    /**
     * ID of this catalog type
     * @type {string}
     * @memberof CatalogEntriesLookup
     */
    catalog_type_id: string;
    /**
     * List of IDs, External IDs, or names to get catalog entries for
     * @type {Array<string>}
     * @memberof CatalogEntriesLookup
     */
    lookup_terms: Array<string>;
}

export function CatalogEntriesLookupFromJSON(json: any): CatalogEntriesLookup {
    return CatalogEntriesLookupFromJSONTyped(json, false);
}

export function CatalogEntriesLookupFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogEntriesLookup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_id': json['catalog_type_id'],
        'lookup_terms': json['lookup_terms'],
    };
}

export function CatalogEntriesLookupToJSON(value?: CatalogEntriesLookup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_id': value.catalog_type_id,
        'lookup_terms': value.lookup_terms,
    };
}

