/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface AlertTemplatePayload
 */
export interface AlertTemplatePayload {
    /**
     * Alert schema attributes mapped to values
     * @type {{ [key: string]: EngineParamBindingPayload; }}
     * @memberof AlertTemplatePayload
     */
    bindings: { [key: string]: EngineParamBindingPayload; };
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertTemplatePayload
     */
    description: EngineParamBindingPayload;
    /**
     * Expressions available for use in bindings
     * @type {Array<ExpressionPayload>}
     * @memberof AlertTemplatePayload
     */
    expressions: Array<ExpressionPayload>;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertTemplatePayload
     */
    priority?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertTemplatePayload
     */
    title: EngineParamBindingPayload;
}

export function AlertTemplatePayloadFromJSON(json: any): AlertTemplatePayload {
    return AlertTemplatePayloadFromJSONTyped(json, false);
}

export function AlertTemplatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertTemplatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bindings': (mapValues(json['bindings'], EngineParamBindingPayloadFromJSON)),
        'description': EngineParamBindingPayloadFromJSON(json['description']),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'priority': !exists(json, 'priority') ? undefined : EngineParamBindingPayloadFromJSON(json['priority']),
        'title': EngineParamBindingPayloadFromJSON(json['title']),
    };
}

export function AlertTemplatePayloadToJSON(value?: AlertTemplatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bindings': (mapValues(value.bindings, EngineParamBindingPayloadToJSON)),
        'description': EngineParamBindingPayloadToJSON(value.description),
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'priority': EngineParamBindingPayloadToJSON(value.priority),
        'title': EngineParamBindingPayloadToJSON(value.title),
    };
}

