/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSourceConfig,
    AlertSourceConfigFromJSON,
    AlertSourceConfigFromJSONTyped,
    AlertSourceConfigToJSON,
} from './AlertSourceConfig';

/**
 * 
 * @export
 * @interface AlertsShowSourceConfigResponseBody
 */
export interface AlertsShowSourceConfigResponseBody {
    /**
     * 
     * @type {AlertSourceConfig}
     * @memberof AlertsShowSourceConfigResponseBody
     */
    alert_source_config: AlertSourceConfig;
}

export function AlertsShowSourceConfigResponseBodyFromJSON(json: any): AlertsShowSourceConfigResponseBody {
    return AlertsShowSourceConfigResponseBodyFromJSONTyped(json, false);
}

export function AlertsShowSourceConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsShowSourceConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_config': AlertSourceConfigFromJSON(json['alert_source_config']),
    };
}

export function AlertsShowSourceConfigResponseBodyToJSON(value?: AlertsShowSourceConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_config': AlertSourceConfigToJSON(value.alert_source_config),
    };
}

