/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableIncidentFormLifecycleElement,
    AvailableIncidentFormLifecycleElementFromJSON,
    AvailableIncidentFormLifecycleElementFromJSONTyped,
    AvailableIncidentFormLifecycleElementToJSON,
} from './AvailableIncidentFormLifecycleElement';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IncidentFormLifecycleElement
 */
export interface IncidentFormLifecycleElement {
    /**
     * 
     * @type {AvailableIncidentFormLifecycleElement}
     * @memberof IncidentFormLifecycleElement
     */
    available_element: AvailableIncidentFormLifecycleElement;
    /**
     * Whether the user can select no value for this element
     * @type {boolean}
     * @memberof IncidentFormLifecycleElement
     */
    can_select_no_value: boolean;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof IncidentFormLifecycleElement
     */
    default_value?: EngineParamBinding;
    /**
     * 
     * @type {TextNode}
     * @memberof IncidentFormLifecycleElement
     */
    description?: TextNode;
    /**
     * Unique ID per incident form element
     * @type {string}
     * @memberof IncidentFormLifecycleElement
     */
    id: string;
    /**
     * ID of the associated incident form
     * @type {string}
     * @memberof IncidentFormLifecycleElement
     */
    incident_form_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormLifecycleElement
     */
    placeholder?: string;
    /**
     * The position this element takes within the form it belongs to
     * @type {number}
     * @memberof IncidentFormLifecycleElement
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormLifecycleElement
     */
    required_if: IncidentFormLifecycleElementRequiredIfEnum;
    /**
     * 
     * @type {Array<ConditionGroup>}
     * @memberof IncidentFormLifecycleElement
     */
    required_if_condition_groups: Array<ConditionGroup>;
    /**
     * 
     * @type {Array<ConditionGroup>}
     * @memberof IncidentFormLifecycleElement
     */
    show_if_condition_groups: Array<ConditionGroup>;
}

/**
* @export
* @enum {string}
*/
export enum IncidentFormLifecycleElementRequiredIfEnum {
    CheckEngineConfig = 'check_engine_config',
    AlwaysRequire = 'always_require',
    NeverRequire = 'never_require'
}

export function IncidentFormLifecycleElementFromJSON(json: any): IncidentFormLifecycleElement {
    return IncidentFormLifecycleElementFromJSONTyped(json, false);
}

export function IncidentFormLifecycleElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormLifecycleElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available_element': AvailableIncidentFormLifecycleElementFromJSON(json['available_element']),
        'can_select_no_value': json['can_select_no_value'],
        'default_value': !exists(json, 'default_value') ? undefined : EngineParamBindingFromJSON(json['default_value']),
        'description': !exists(json, 'description') ? undefined : TextNodeFromJSON(json['description']),
        'id': json['id'],
        'incident_form_id': json['incident_form_id'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'rank': json['rank'],
        'required_if': json['required_if'],
        'required_if_condition_groups': ((json['required_if_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'show_if_condition_groups': ((json['show_if_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
    };
}

export function IncidentFormLifecycleElementToJSON(value?: IncidentFormLifecycleElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_element': AvailableIncidentFormLifecycleElementToJSON(value.available_element),
        'can_select_no_value': value.can_select_no_value,
        'default_value': EngineParamBindingToJSON(value.default_value),
        'description': TextNodeToJSON(value.description),
        'id': value.id,
        'incident_form_id': value.incident_form_id,
        'placeholder': value.placeholder,
        'rank': value.rank,
        'required_if': value.required_if,
        'required_if_condition_groups': ((value.required_if_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'show_if_condition_groups': ((value.show_if_condition_groups as Array<any>).map(ConditionGroupToJSON)),
    };
}

