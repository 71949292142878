import type { SVGProps } from "react";
import * as React from "react";
const SvgKanban = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.75 3h8.5A2.752 2.752 0 0 1 17 5.75v8.5A2.752 2.752 0 0 1 14.25 17h-8.5A2.752 2.752 0 0 1 3 14.25v-8.5A2.752 2.752 0 0 1 5.75 3Zm4.75 10.25c0 .414.336.75.75.75h2a.75.75 0 0 0 .75-.75v-6.5a.75.75 0 0 0-.75-.75h-2a.75.75 0 0 0-.75.75v6.5Zm-4.5-4c0 .414.336.75.75.75h2a.75.75 0 0 0 .75-.75v-2.5A.75.75 0 0 0 8.75 6h-2a.75.75 0 0 0-.75.75v2.5Z"
    />
  </svg>
);
export default SvgKanban;
