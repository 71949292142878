import {
  endTimeForTimelinePeriod,
  startTimeForPeriod,
  TimelineCalendarValue,
  TimePeriodOption,
  timePeriodOpts,
} from "@incident-shared/schedules/ScheduleOverview/types";
import { getCalendarDays } from "@incident-shared/schedules/ScheduleOverview/utils/getCalendarDays";
import { DateTime, Duration } from "luxon";
import React, { Dispatch, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useSafeUpdateQueryString } from "src/utils/query-params";
import { assertUnreachable } from "src/utils/utils";

export interface ScheduleTimeWindowState {
  now: DateTime;
  startTime: DateTime;
  timePeriodOption: TimePeriodOption;
  calendarToggle: TimelineCalendarValue;
  hasMovedPage?: boolean;
}

export type ScheduleTimeWindowAction =
  | {
      type: "previousPageClicked";
    }
  | {
      type: "nextPageClicked";
    }
  | {
      type: "todayClicked";
    }
  | {
      type: "setTimePeriodOption";
      payload: { period: TimePeriodOption; focusDate?: DateTime };
    }
  | {
      type: "setCalendarToggle";
      payload: TimelineCalendarValue;
    }
  | {
      type: "recalculatePreview";
      payload: { overrideStartAt: DateTime; overrideEndAt: DateTime };
    };

export const useScheduleTimeWindowReducer = (
  now: DateTime,
  loadFromQuery = false,
): [ScheduleTimeWindowState, Dispatch<ScheduleTimeWindowAction>] => {
  // By default, we'll return this.
  const defaults = defaultScheduleTimeWindowState(now);

  // But check the query parameters and override the defaults if it's in the
  // URL.
  const { search } = useLocation();
  const existingParams = React.useMemo(
    () => new URLSearchParams(search),
    [search],
  );
  const setQueryString = useSafeUpdateQueryString();

  if (loadFromQuery) {
    const startTime = existingParams.get("startTime");
    if (startTime) {
      try {
        // We use setZone so that it uses the timezone from the string, which comes from the schedule
        // timezone itself.
        const startTimeDate = DateTime.fromISO(startTime, { setZone: true });
        if (startTimeDate.isValid) {
          defaults.startTime = startTimeDate;
        }
      } catch (e) {
        console.error("Ignoring invalid start time in URL", e);
      }
    }

    const timePeriodOption = existingParams.get("timePeriodOption");
    if (
      Object.values(TimePeriodOption).includes(
        timePeriodOption as unknown as TimePeriodOption,
      )
    ) {
      // Assign defaults.timePeriodOption is the string timePeriodOption is a
      // member of the TimePeriodOption enum.
      defaults.timePeriodOption = timePeriodOption as TimePeriodOption;
    }

    const calendarToggle = existingParams.get("calendarToggle");
    if (
      Object.values(TimelineCalendarValue).includes(
        calendarToggle as unknown as TimelineCalendarValue,
      )
    ) {
      defaults.calendarToggle = calendarToggle as TimelineCalendarValue;
    }

    // If you specify a start time in the url, make sure it's rounded to the
    // nearest start time for the given time period.
    //
    // Our timeline only supports displaying full days/hours/minutes, depending
    // on the selected time period option. E.g. when viewing a week, we won't show half
    // of a day.
    switch (defaults.timePeriodOption) {
      case TimePeriodOption.ThreeHours:
        defaults.startTime = defaults.startTime.startOf("minute");
        break;
      case TimePeriodOption.OneDay:
        defaults.startTime = defaults.startTime.startOf("hour");
        break;
      // The other views have an increment of 1 day, so start of previous day
      case TimePeriodOption.OneWeek:
      case TimePeriodOption.TwoWeeks:
      case TimePeriodOption.OneMonth:
        defaults.startTime = defaults.startTime.startOf("day");
        break;
      default:
        assertUnreachable(defaults.timePeriodOption);
    }
  }

  const [state, reduce] = useReducer(scheduleTimeWindowReducer, defaults);

  if (loadFromQuery) {
    const params = new URLSearchParams(search);
    params.set("startTime", state.startTime.toISO());
    params.set("timePeriodOption", state.timePeriodOption);
    params.set("calendarToggle", state.calendarToggle);

    if (
      params.get("startTime") !== existingParams.get("startTime") ||
      params.get("timePeriodOption") !==
        existingParams.get("timePeriodOption") ||
      params.get("calendarToggle") !== existingParams.get("calendarToggle")
    ) {
      setQueryString(params.toString(), true);
    }
  }

  return [state, reduce];
};

export const defaultScheduleTimeWindowState: (now: DateTime) => {
  now: DateTime;
  startTime: DateTime;
  calendarToggle: TimelineCalendarValue;
  timePeriodOption: TimePeriodOption;
} = (now: DateTime) => ({
  now,
  startTime: startTimeForPeriod({
    time: now,
    timePeriod: TimePeriodOption.TwoWeeks,
  }),
  timePeriodOption: TimePeriodOption.TwoWeeks,
  calendarToggle: TimelineCalendarValue.Timeline,
});

export const scheduleTimeWindowReducer = (
  state: ScheduleTimeWindowState,
  action: ScheduleTimeWindowAction,
): ScheduleTimeWindowState => {
  const visibleDuration =
    state.calendarToggle === TimelineCalendarValue.Calendar
      ? { months: 1 }
      : timePeriodOpts[state.timePeriodOption].duration;

  switch (action.type) {
    case "previousPageClicked":
      return {
        ...state,
        startTime: state.startTime.minus(visibleDuration),
        hasMovedPage: true,
      };
    case "nextPageClicked":
      return {
        ...state,
        startTime: state.startTime.plus(visibleDuration),
        hasMovedPage: true,
      };
    case "todayClicked":
      return {
        ...state,
        startTime: startTimeForPeriod({
          time: state.now,
          timePeriod: state.timePeriodOption,
        }),
        hasMovedPage: false,
      };
    case "setTimePeriodOption":
      return {
        ...state,
        timePeriodOption: action.payload.period,
        startTime: getNewStartTime(state, action.payload),
      };
    case "setCalendarToggle":
      return {
        ...state,
        calendarToggle: action.payload,
      };
    case "recalculatePreview":
      return {
        ...state,
        ...recalculatePreviewFromOverrideLength(action.payload),
      };
    default:
      assertUnreachable(action);
  }
  return state;
};

const getNewStartTime = (
  state: ScheduleTimeWindowState,
  payload: { period: TimePeriodOption; focusDate?: DateTime },
) => {
  const currentEndTime = endTimeForTimelinePeriod({
    timePeriod: state.timePeriodOption,
    from: state.startTime,
  });

  // If the user hasn't manually clicked around any pages,
  // then just give them the default start time for the window they're looking at
  if (!state.hasMovedPage) {
    return startTimeForPeriod({
      time: payload.focusDate ?? state.now,
      timePeriod: payload.period,
    });
  }

  // Otherwise, we want to zoom in or out on the current center point
  const midPoint = state.startTime.plus({
    seconds: currentEndTime.diff(state.startTime, "seconds").seconds / 2,
  });
  const nonRoundedNewStartTime = midPoint.minus({
    seconds:
      Duration.fromDurationLike(
        timePeriodOpts[payload.period].duration,
      ).shiftTo("seconds").seconds / 2,
  });

  // Once we've got a roughly correct start time, we want to round it to the nearest
  // start time for the given time period
  return startTimeForPeriod({
    time: nonRoundedNewStartTime,
    timePeriod: payload.period,
  });
};

// recalculatePreview responds to the override start/end changing by recalculating the period
// of time the preview window should be displaying. It does this by first approximating the
// length of time we need to display from the override duration (i.e. what time window do we want)
// and then finding a sensible start point so that the override is displayed in full.
const recalculatePreviewFromOverrideLength = ({
  overrideStartAt,
  overrideEndAt,
}: {
  overrideStartAt: DateTime;
  overrideEndAt: DateTime;
}) => {
  const overrideDurationHours = overrideEndAt.diff(overrideStartAt).as("hours");
  let timePeriodOption: TimePeriodOption;
  // Invalid length: default to 2 weeks
  if (overrideDurationHours < 0) {
    timePeriodOption = TimePeriodOption.TwoWeeks;
    // Less than 2 hours: show the 3-hour view
  } else if (overrideDurationHours < 2) {
    timePeriodOption = TimePeriodOption.ThreeHours;
    // Less than 23 hours: show the 1-day view
  } else if (overrideDurationHours < 23) {
    timePeriodOption = TimePeriodOption.OneDay;
    // Less than 6 days: show the 1-week view
  } else if (overrideDurationHours < 6 * 24) {
    timePeriodOption = TimePeriodOption.OneWeek;
    // Less than 13 days: show the 2-week view
  } else if (overrideDurationHours < 13 * 24) {
    timePeriodOption = TimePeriodOption.TwoWeeks;
  } else {
    // More than 13 days: show the month view
    timePeriodOption = TimePeriodOption.OneMonth;
  }

  const startTime = startTimeForPeriod({
    timePeriod: timePeriodOption,
    time: overrideStartAt,
  });

  return {
    startTime,
    timePeriodOption,
  };
};

export const makeQueryParams = (
  state: ScheduleTimeWindowState,
  timezone: string,
): [string, string] => {
  const currentEndTime = endTimeForTimelinePeriod({
    from: state.startTime,
    timePeriod: state.timePeriodOption,
  });

  let from, until: string;
  if (state.calendarToggle === TimelineCalendarValue.Calendar) {
    // Get the first and last days of the visible calendar, note that these can be from different months
    const calendarDays = getCalendarDays(state.startTime, timezone);
    from = calendarDays[0].toISO();
    until = calendarDays[calendarDays.length - 1].endOf("day").toISO();
  } else {
    // Add an hour on either side so that we know if a shift is 'clipped' at the edge of the view
    from = state.startTime.minus({ hours: 1 }).toISO();
    until = currentEndTime.plus({ hours: 1 }).toISO();
  }

  return [from, until];
};
