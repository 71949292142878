/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TranscriptEntry
 */
export interface TranscriptEntry {
    /**
     * What they said
     * @type {string}
     * @memberof TranscriptEntry
     */
    content: string;
    /**
     * The name of the speaker
     * @type {string}
     * @memberof TranscriptEntry
     */
    speaker_name: string;
    /**
     * The time they started speaking
     * @type {string}
     * @memberof TranscriptEntry
     */
    timestamp: string;
}

export function TranscriptEntryFromJSON(json: any): TranscriptEntry {
    return TranscriptEntryFromJSONTyped(json, false);
}

export function TranscriptEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'speaker_name': json['speaker_name'],
        'timestamp': json['timestamp'],
    };
}

export function TranscriptEntryToJSON(value?: TranscriptEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'speaker_name': value.speaker_name,
        'timestamp': value.timestamp,
    };
}

