import { PostIncidentFlow } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Link, LoadingModal } from "@incident-ui";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { DeletionConfirmationModal } from "../DeletionConfirmationModal";

export const PostIncidentFlowDeleteModal = ({
  onClose,
  flow,
}: {
  onClose: () => void;
  flow: PostIncidentFlow;
}): React.ReactElement => {
  const {
    data: { dependent_resources: dependentResources },
    isValidating: dependentResourcesLoading,
  } = useAPI(
    "engineFindDependentResourcesForMultiple",
    {
      findDependentResourcesForMultipleRequestBody: {
        resources: [
          {
            resource_type: "PostIncidentFlow",
            id: flow.id,
          },
          ...flow.incident_statuses.map((status) => ({
            resource_type: "IncidentStatus",
            id: status.id,
          })),
        ],
      },
    },
    { fallbackData: { dependent_resources: [] } },
  );

  const { data: incidentsResp, isLoading: incidentsLoading } = useAPI(
    "incidentsList",
    {
      pageSize: 10,
      status: { one_of: flow.incident_statuses.map((status) => status.id) },
      includeTest: { is: true },
      // This won't return all private incidents, since the user might not be able to see some, but it's better than nothing!
      includePrivate: { is: true },
    },
    {
      fallbackData: {
        incidents: [],
        available_statuses: [],
        pagination_meta: { page_size: 10, total_record_count: 0 },
      },
    },
  );
  const incidents = incidentsResp.incidents;
  const incidentsCount =
    incidentsResp?.pagination_meta?.total_record_count ?? incidents.length;

  const navigate = useOrgAwareNavigate();
  const { trigger, isMutating } = useAPIMutation(
    "postIncidentFlowList",
    undefined,
    async (apiClient) => {
      await apiClient.postIncidentFlowDestroy({ id: flow.id });
    },
    {
      onSuccess: () => {
        navigate("/settings/post-incident-flow");
      },
    },
  );
  const doDelete = async () => {
    await trigger({});
  };

  if (dependentResourcesLoading || incidentsLoading) {
    return <LoadingModal title="Delete post-incident flow" onClose={onClose} />;
  }

  return (
    <DeletionConfirmationModal
      isOpen
      resourceTitle={flow.name}
      analyticsTrackingId={"post-incident-flow-delete"}
      title="Delete post-incident flow"
      confirmButtonText="Delete flow"
      onClose={onClose}
      onDelete={doDelete}
      isDeleting={isMutating}
      dependentResources={dependentResources}
      deleteConfirmationContent={
        incidentsCount > 0 ? (
          <>
            <p>
              Are you sure you want to delete the{" "}
              <span className="text-semibold">{flow.name}</span> post-incident
              flow? These {incidentsCount} incident
              {incidentsCount > 1 ? "s" : ""} are currently in this flow, and
              will continue through it until they are closed.
            </p>
            <ul>
              {incidents.map((incident) => (
                <li key={incident.id} className="list-disc list-inside">
                  <Link
                    openInNewTab
                    href={`/incidents/${incident.external_id}`}
                    analyticsTrackingId={null}
                  >
                    {`#${incident.reference}: ${incident.name}`}
                  </Link>
                </li>
              ))}
              {incidentsCount > 10 && <p>...</p>}
            </ul>
          </>
        ) : (
          <>
            Are you sure you want to delete the{" "}
            <span className="text-semibold">{flow.name}</span> post-incident
            flow?
          </>
        )
      }
    />
  );
};
