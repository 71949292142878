import {
  AnnouncementPostFieldOptionParamFieldTypeEnum,
  AnnouncementPostFieldParam,
  AnnouncementPostsCreateFieldRequestBody,
  AnnouncementPostsCreateFieldRequestBodyFieldTypeEnum as FieldTypeEnum,
  CustomField,
  IncidentRole,
  IncidentTimestamp,
} from "@incident-io/api";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { OrgAwareNavLink } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  IconEnum,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SLACK_EMOJI_TO_UNICODE } from "../../../../../utils/slack";

export const AnnouncementPostFieldCreateModal = ({
  onClose,
  customFields,
  timestamps,
  roles,
  maxRank,
  refetchPreview,
  postFields,
}: {
  onClose: () => void;
  customFields: CustomField[];
  timestamps: IncidentTimestamp[];
  roles: IncidentRole[];
  maxRank: number;
  refetchPreview: () => void;
  postFields: AnnouncementPostFieldParam[];
}): React.ReactElement => {
  const showToast = useToast();

  const { trigger: onSubmit, isMutating } = useAPIMutation(
    "announcementPostsListFields",
    undefined,
    async (apiClient, data: AnnouncementPostsCreateFieldRequestBody) => {
      // Add the new field to the bottom of the list
      data.rank = maxRank + 1;
      await apiClient.announcementPostsCreateField({
        createFieldRequestBody: data,
      });
    },
    {
      onSuccess: () => {
        refetchPreview();
        onClose();
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error updating announcement post configuration",
        });
      },
    },
  );

  const formMethods = useForm<AnnouncementPostsCreateFieldRequestBody>();

  const fieldType = formMethods.watch("field_type");

  const customFieldIDs = postFields.map((field) => field.custom_field_id);
  const customFieldOptions: SelectOption[] = customFields
    .filter((c) => !customFieldIDs.includes(c.id))
    .map((field) => ({
      label: field.name,
      value: field.id,
    }));

  const timestampIDs = postFields.map((field) => field.incident_timestamp_id);
  const timestampOptions: SelectOption[] = timestamps
    .filter((t) => !timestampIDs.includes(t.id))
    .map((timestamp) => ({
      label: timestamp.name,
      value: timestamp.id,
    }));

  const roleIDs = postFields.map((field) => field.incident_role_id);
  const roleOptions: SelectOption[] = roles
    .filter((r) => !roleIDs.includes(r.id))
    .map((role) => ({
      label: role.name,
      value: role.id,
    }));

  const { data: fieldOptionData, error: errorFieldOptionData } = useAPI(
    "announcementPostsListFieldOptions",
    undefined,
  );

  if (errorFieldOptionData) throw errorFieldOptionData;

  const { watch, setValue } = formMethods;

  useEffect(() => {
    const subscription = watch((formState, { name }) => {
      // Ignore changes to other fields
      if (name !== "field_type") {
        return;
      }

      if (fieldOptionData?.post_fields) {
        const field = fieldOptionData.post_fields.find(
          (field) =>
            field.field_type ===
            (formState.field_type as unknown as AnnouncementPostFieldOptionParamFieldTypeEnum),
        );
        setValue("emoji", field?.emoji);
      }
    });

    return () => subscription.unsubscribe();
  }, [fieldOptionData, setValue, watch]);

  const typeOptions: SelectOption[] = [];
  fieldOptionData?.post_fields.forEach((postField) => {
    typeOptions.push({
      label: postField.title,
      value: postField.field_type,
    });
  });

  const emojiOptions: SelectOption[] = [];
  Object.entries(SLACK_EMOJI_TO_UNICODE).forEach(([key, value]) => {
    emojiOptions.push({
      label: value + "  " + key,
      sort_key: key,
      value: key.slice(1, -1),
    });
  });
  // Fake slack as an emoji
  emojiOptions.push({
    label: ":slack:",
    sort_key: ":slack:",
    value: "slack",
    icon: IconEnum.Slack,
  });

  function isSaveDisabled(fieldType: FieldTypeEnum) {
    switch (fieldType) {
      case FieldTypeEnum.CustomField:
        return customFieldOptions.length < 1;
      case FieldTypeEnum.Role:
        return roleOptions.length < 1;
      case FieldTypeEnum.Timestamp:
        return timestampOptions.length < 1;
      default:
        return false;
    }
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={"Add field"}
      analyticsTrackingId={"announcement-post-add-field-modal"}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          onConfirm={formMethods.handleSubmit(onSubmit)}
          confirmButtonText="Save"
          confirmButtonType="button"
          saving={isMutating}
          disabled={isSaveDisabled(fieldType)}
        />
      }
    >
      <StaticSingleSelectV2
        options={typeOptions}
        label={"Field type"}
        name="field_type"
        required
        formMethods={formMethods}
        placeholder="Select field type..."
      />
      {fieldType === FieldTypeEnum.CustomField && (
        <>
          {customFieldOptions.length > 0 ? (
            <StaticSingleSelectV2
              formMethods={formMethods}
              label="Custom field"
              name="custom_field_id"
              options={customFieldOptions}
              required
              placeholder="Select custom field..."
            />
          ) : (
            <Callout theme={CalloutTheme.Info} className="mt-3">
              All of your custom fields are in use. You can create more in{" "}
              <OrgAwareNavLink
                className="underline"
                to="/settings/custom-fields"
              >
                Settings → Custom fields
              </OrgAwareNavLink>
            </Callout>
          )}
        </>
      )}
      {fieldType === FieldTypeEnum.Role && (
        <>
          {roleOptions.length > 0 ? (
            <StaticSingleSelectV2
              formMethods={formMethods}
              label="Role"
              name="incident_role_id"
              options={roleOptions}
              required
              placeholder="Select role..."
            />
          ) : (
            <Callout theme={CalloutTheme.Info} className="mt-3">
              All of your roles are in use. You can create more in{" "}
              <OrgAwareNavLink className="underline" to="/settings/roles">
                Settings → Roles
              </OrgAwareNavLink>
            </Callout>
          )}
        </>
      )}
      {fieldType === FieldTypeEnum.Timestamp && (
        <>
          <StaticSingleSelectV2
            formMethods={formMethods}
            label="Timestamp"
            name="incident_timestamp_id"
            options={timestampOptions}
            required
            placeholder="Select timestamp..."
          />
          {timestampOptions.length < 1 && (
            <Callout theme={CalloutTheme.Info} className="mt-3">
              You can create new timestamps in{" "}
              <OrgAwareNavLink
                className="underline"
                to="/settings/lifecycle?tab=timestamps"
              >
                Settings → Lifecycle → Timestamps and metrics
              </OrgAwareNavLink>
            </Callout>
          )}
        </>
      )}
      {fieldType &&
        !(
          fieldType === FieldTypeEnum.Creator ||
          fieldType === FieldTypeEnum.Description
        ) && (
          <StaticSingleSelectV2
            formMethods={formMethods}
            label="Emoji"
            required={false}
            name="emoji"
            options={emojiOptions}
            isClearable={true}
            placeholder="Select emoji..."
          />
        )}
    </Form.Modal>
  );
};
