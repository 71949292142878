/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageAutoCreateComponents,
    StatusPageAutoCreateComponentsFromJSON,
    StatusPageAutoCreateComponentsFromJSONTyped,
    StatusPageAutoCreateComponentsToJSON,
} from './StatusPageAutoCreateComponents';
import {
    StatusPageAutoCreateStatusPages,
    StatusPageAutoCreateStatusPagesFromJSON,
    StatusPageAutoCreateStatusPagesFromJSONTyped,
    StatusPageAutoCreateStatusPagesToJSON,
} from './StatusPageAutoCreateStatusPages';

/**
 * 
 * @export
 * @interface StatusPageAutoCreateCatalogConfiguration
 */
export interface StatusPageAutoCreateCatalogConfiguration {
    /**
     * The name of the catalog type to automatically create for this page
     * @type {string}
     * @memberof StatusPageAutoCreateCatalogConfiguration
     */
    catalog_type_name: string;
    /**
     * The components to automatically create for this page
     * @type {Array<StatusPageAutoCreateComponents>}
     * @memberof StatusPageAutoCreateCatalogConfiguration
     */
    components: Array<StatusPageAutoCreateComponents>;
    /**
     * The status pages to automatically create for this page
     * @type {Array<StatusPageAutoCreateStatusPages>}
     * @memberof StatusPageAutoCreateCatalogConfiguration
     */
    status_pages: Array<StatusPageAutoCreateStatusPages>;
}

export function StatusPageAutoCreateCatalogConfigurationFromJSON(json: any): StatusPageAutoCreateCatalogConfiguration {
    return StatusPageAutoCreateCatalogConfigurationFromJSONTyped(json, false);
}

export function StatusPageAutoCreateCatalogConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageAutoCreateCatalogConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_name': json['catalog_type_name'],
        'components': ((json['components'] as Array<any>).map(StatusPageAutoCreateComponentsFromJSON)),
        'status_pages': ((json['status_pages'] as Array<any>).map(StatusPageAutoCreateStatusPagesFromJSON)),
    };
}

export function StatusPageAutoCreateCatalogConfigurationToJSON(value?: StatusPageAutoCreateCatalogConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_name': value.catalog_type_name,
        'components': ((value.components as Array<any>).map(StatusPageAutoCreateComponentsToJSON)),
        'status_pages': ((value.status_pages as Array<any>).map(StatusPageAutoCreateStatusPagesToJSON)),
    };
}

