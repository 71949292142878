/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageComponent,
    InternalStatusPageComponentFromJSON,
    InternalStatusPageComponentFromJSONTyped,
    InternalStatusPageComponentToJSON,
} from './InternalStatusPageComponent';

/**
 * 
 * @export
 * @interface InternalStatusPageComponentGroup
 */
export interface InternalStatusPageComponentGroup {
    /**
     * Components that form this group.
     * @type {Array<InternalStatusPageComponent>}
     * @memberof InternalStatusPageComponentGroup
     */
    components: Array<InternalStatusPageComponent>;
    /**
     * Unique ID of this group, if it is a standard custom field group.
     * @type {string}
     * @memberof InternalStatusPageComponentGroup
     */
    custom_field_group_id?: string;
    /**
     * Whether this group is hidden from the page structure
     * @type {boolean}
     * @memberof InternalStatusPageComponentGroup
     */
    hidden: boolean;
    /**
     * Unique ID of this group. Either the ID of the custom field group, or the value of the catalog grouping attribute.
     * @type {string}
     * @memberof InternalStatusPageComponentGroup
     */
    id: string;
    /**
     * Human readable name of this group.
     * @type {string}
     * @memberof InternalStatusPageComponentGroup
     */
    label: string;
}

export function InternalStatusPageComponentGroupFromJSON(json: any): InternalStatusPageComponentGroup {
    return InternalStatusPageComponentGroupFromJSONTyped(json, false);
}

export function InternalStatusPageComponentGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageComponentGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'components': ((json['components'] as Array<any>).map(InternalStatusPageComponentFromJSON)),
        'custom_field_group_id': !exists(json, 'custom_field_group_id') ? undefined : json['custom_field_group_id'],
        'hidden': json['hidden'],
        'id': json['id'],
        'label': json['label'],
    };
}

export function InternalStatusPageComponentGroupToJSON(value?: InternalStatusPageComponentGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'components': ((value.components as Array<any>).map(InternalStatusPageComponentToJSON)),
        'custom_field_group_id': value.custom_field_group_id,
        'hidden': value.hidden,
        'id': value.id,
        'label': value.label,
    };
}

