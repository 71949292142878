/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GroupedIncidentTimelineElement,
    GroupedIncidentTimelineElementFromJSON,
    GroupedIncidentTimelineElementFromJSONTyped,
    GroupedIncidentTimelineElementToJSON,
} from './GroupedIncidentTimelineElement';

/**
 * 
 * @export
 * @interface IncidentTimelineListTimelineItemsResponseBody
 */
export interface IncidentTimelineListTimelineItemsResponseBody {
    /**
     * 
     * @type {Array<GroupedIncidentTimelineElement>}
     * @memberof IncidentTimelineListTimelineItemsResponseBody
     */
    timeline_items: Array<GroupedIncidentTimelineElement>;
}

export function IncidentTimelineListTimelineItemsResponseBodyFromJSON(json: any): IncidentTimelineListTimelineItemsResponseBody {
    return IncidentTimelineListTimelineItemsResponseBodyFromJSONTyped(json, false);
}

export function IncidentTimelineListTimelineItemsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimelineListTimelineItemsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeline_items': ((json['timeline_items'] as Array<any>).map(GroupedIncidentTimelineElementFromJSON)),
    };
}

export function IncidentTimelineListTimelineItemsResponseBodyToJSON(value?: IncidentTimelineListTimelineItemsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_items': ((value.timeline_items as Array<any>).map(GroupedIncidentTimelineElementToJSON)),
    };
}

