import { Button, ButtonTheme, IconEnum, ModalFooter, Txt } from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { useAPIMutation } from "../../../utils/swr";

export const OnCallNotificationsPhoneOnboarding = ({
  userOnboarded,
  phoneOnboarded,
  setShowAddMethodModal,
}: {
  userOnboarded: boolean;
  phoneOnboarded: boolean;
  setShowAddMethodModal: ({
    show,
    allowEmail,
  }: {
    show: boolean;
    allowEmail: boolean;
  }) => void;
}) => {
  const mutation = useAPIMutation(
    "onCallOnboardingCurrentOnboardingState",
    undefined,
    async (apiClient) => {
      await apiClient.onCallOnboardingMarkTaskAsCompleted({
        task: "dashboard_has_dismissed_app_install",
      });
    },
  );
  const formMethods = useForm();

  const [showNoLongerUsingAppModal, setShowNoLongerUsingAppModal] =
    useState(false);
  const onClose = () => setShowNoLongerUsingAppModal(false);
  if (userOnboarded) {
    return null;
  }

  return (
    <div>
      <h3 className="text-lg font-semibold mt-2">Not using the app?</h3>
      {!phoneOnboarded ? (
        <Txt grey className="mt-4">
          For the best experience with On-call, we recommend using the mobile
          app. Alternatively, you’ll need to add and verify your phone number.
        </Txt>
      ) : (
        <Txt grey className="mt-4">
          If you’re unable to use our mobile app, set up your on-call
          preferences to receive escalations via SMS or phone call.
        </Txt>
      )}
      {!phoneOnboarded ? (
        <Button
          analyticsTrackingId="on-call-onboarding-user-phone"
          className="mt-4"
          theme={ButtonTheme.Secondary}
          onClick={() =>
            setShowAddMethodModal({
              show: true,
              allowEmail: false,
            })
          }
          icon={IconEnum.CallPlus}
        >
          Add phone number
        </Button>
      ) : (
        <Button
          analyticsTrackingId="on-call-onboarding-opt-out"
          className="mt-4"
          theme={ButtonTheme.Secondary}
          onClick={() => setShowNoLongerUsingAppModal(true)}
        >
          I&apos;m not using the app
        </Button>
      )}
      {showNoLongerUsingAppModal && (
        <Form.Modal
          title="Opt out of using the incident.io app"
          analyticsTrackingId="on-call-onboarding-opt-out-modal"
          onClose={onClose}
          onSubmit={() => mutation.trigger({})}
          formMethods={formMethods}
          footer={
            <ModalFooter
              confirmButtonText={"I'm not using the app"}
              confirmButtonType="submit"
              disabledTooltipContent="Please enter a valid phone number"
              onClose={onClose}
            />
          }
        >
          <Txt grey>
            You&apos;re about to opt out of using the on-call app. You&apos;ll
            still be able to receive alerts via SMS or phone call.
          </Txt>
        </Form.Modal>
      )}
    </div>
  );
};
