/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorTriggerTriggerErrorRequestBody,
    ErrorTriggerTriggerErrorRequestBodyFromJSON,
    ErrorTriggerTriggerErrorRequestBodyToJSON,
} from '../models';

export interface ErrorTriggerTriggerErrorRequest {
    triggerErrorRequestBody: ErrorTriggerTriggerErrorRequestBody;
}

/**
 * 
 */
export class ErrorTriggerApi extends runtime.BaseAPI {

    /**
     * Raise an error to test error reporting
     * TriggerError ErrorTrigger
     */
    async errorTriggerTriggerErrorRaw(requestParameters: ErrorTriggerTriggerErrorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.triggerErrorRequestBody === null || requestParameters.triggerErrorRequestBody === undefined) {
            throw new runtime.RequiredError('triggerErrorRequestBody','Required parameter requestParameters.triggerErrorRequestBody was null or undefined when calling errorTriggerTriggerError.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/error_trigger/errors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ErrorTriggerTriggerErrorRequestBodyToJSON(requestParameters.triggerErrorRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Raise an error to test error reporting
     * TriggerError ErrorTrigger
     */
    async errorTriggerTriggerError(requestParameters: ErrorTriggerTriggerErrorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.errorTriggerTriggerErrorRaw(requestParameters, initOverrides);
    }

}
