import { Banner, BannerTheme, IconEnum } from "@incident-ui";
import React from "react";
import { useIdentity } from "src/contexts/IdentityContext";

const COMMUNITY_ORG_ID = "01F5DQYNXKH7V01M29ANMNW95E";

export const CommunityBanner = (): React.ReactElement | null => {
  const { identity } = useIdentity();

  // If the org isn't the community, no need to show anything
  if (
    !identity ||
    !identity.organisation_id ||
    identity.organisation_id !== COMMUNITY_ORG_ID
  ) {
    return null;
  }

  return (
    <Banner theme={BannerTheme.Warning} icon={IconEnum.Exclamation}>
      You are logged in to the incident.io Community workspace
    </Banner>
  );
};
