import type { SVGProps } from "react";
import * as React from "react";
const SvgExternalLinkCircle = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8 0 1.936.692 3.713 1.841 5.099L10.44 8.5H7.751a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V9.561l-6.6 6.598A7.962 7.962 0 0 0 10 18c4.411 0 8-3.589 8-8s-3.589-8-8-8Z"
    />
  </svg>
);
export default SvgExternalLinkCircle;
