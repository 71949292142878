import cx from "classnames";
import React from "react";

import { useTranslations } from "../../use-translations";
import styles from "./Spinner.module.scss";

const colorClass = {
  segmentColor: "text-slate-100 dark:text-slate-500",
  backgroundColor: "text-slate-300 dark:text-content-primary",
};

export const Spinner = ({
  className,
  containerClassName,
  large = false,
}: {
  large?: boolean;
  containerClassName?: string;
  className?: string;
}): React.ReactElement => {
  const t = useTranslations("Spinner");

  return (
    <div className={containerClassName}>
      <svg
        className={cx(
          "overflow-visible",
          styles.spinner,
          colorClass.segmentColor,
          {
            "w-4 h-4": !large,
            "w-6 h-6": large,
          },
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className={colorClass.backgroundColor}
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className={styles.widget}
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span className="sr-only">{t("loading")}</span>
    </div>
  );
};
