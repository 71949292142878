import { Banner, BannerTheme, IconEnum, OrgAwareLink } from "@incident-ui";
import { ReactElement } from "react";
import { IntegrationConfigFor } from "src/components/@shared/integrations";
import { IntegrationSettings } from "src/contexts/ClientContext";

export const BrokenIntegrationBanner = ({
  integrations,
}: {
  integrations: IntegrationSettings[];
}): ReactElement => {
  if (integrations.length === 0) {
    return <></>;
  }
  const names = integrations.map((integration) => {
    const config = IntegrationConfigFor(integration.provider);
    return config.label;
  });
  const sortedNames = names.sort();
  let nameStr = sortedNames[0];
  if (sortedNames.length > 1) {
    const last = sortedNames.pop();
    nameStr = sortedNames.join(", ") + " and " + last;
  }

  let icon: IconEnum | undefined;
  if (integrations.length === 1) {
    icon = IntegrationConfigFor(integrations[0].provider)?.icon;
  }
  return (
    <Banner theme={BannerTheme.Warning} icon={icon}>
      <>
        There&apos;s a problem with your connection to {nameStr}.{" "}
        <OrgAwareLink to="/settings/integrations">
          Reconnect on the Integrations page
        </OrgAwareLink>{" "}
      </>
    </Banner>
  );
};
