/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionBranchesOpts,
    ExpressionBranchesOptsFromJSON,
    ExpressionBranchesOptsFromJSONTyped,
    ExpressionBranchesOptsToJSON,
} from './ExpressionBranchesOpts';
import {
    ExpressionFilterOpts,
    ExpressionFilterOptsFromJSON,
    ExpressionFilterOptsFromJSONTyped,
    ExpressionFilterOptsToJSON,
} from './ExpressionFilterOpts';
import {
    ExpressionNavigateOpts,
    ExpressionNavigateOptsFromJSON,
    ExpressionNavigateOptsFromJSONTyped,
    ExpressionNavigateOptsToJSON,
} from './ExpressionNavigateOpts';
import {
    ExpressionParseOpts,
    ExpressionParseOptsFromJSON,
    ExpressionParseOptsFromJSONTyped,
    ExpressionParseOptsToJSON,
} from './ExpressionParseOpts';
import {
    ReturnsMeta,
    ReturnsMetaFromJSON,
    ReturnsMetaFromJSONTyped,
    ReturnsMetaToJSON,
} from './ReturnsMeta';

/**
 * 
 * @export
 * @interface ExpressionOperation
 */
export interface ExpressionOperation {
    /**
     * 
     * @type {ExpressionBranchesOpts}
     * @memberof ExpressionOperation
     */
    branches?: ExpressionBranchesOpts;
    /**
     * 
     * @type {ExpressionFilterOpts}
     * @memberof ExpressionOperation
     */
    filter?: ExpressionFilterOpts;
    /**
     * 
     * @type {ExpressionNavigateOpts}
     * @memberof ExpressionOperation
     */
    navigate?: ExpressionNavigateOpts;
    /**
     * The type of the operation
     * @type {string}
     * @memberof ExpressionOperation
     */
    operation_type: ExpressionOperationOperationTypeEnum;
    /**
     * 
     * @type {ExpressionParseOpts}
     * @memberof ExpressionOperation
     */
    parse?: ExpressionParseOpts;
    /**
     * 
     * @type {ReturnsMeta}
     * @memberof ExpressionOperation
     */
    returns: ReturnsMeta;
}

/**
* @export
* @enum {string}
*/
export enum ExpressionOperationOperationTypeEnum {
    Navigate = 'navigate',
    Filter = 'filter',
    Count = 'count',
    Min = 'min',
    Max = 'max',
    Sum = 'sum',
    Random = 'random',
    First = 'first',
    Parse = 'parse',
    Branches = 'branches'
}

export function ExpressionOperationFromJSON(json: any): ExpressionOperation {
    return ExpressionOperationFromJSONTyped(json, false);
}

export function ExpressionOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branches': !exists(json, 'branches') ? undefined : ExpressionBranchesOptsFromJSON(json['branches']),
        'filter': !exists(json, 'filter') ? undefined : ExpressionFilterOptsFromJSON(json['filter']),
        'navigate': !exists(json, 'navigate') ? undefined : ExpressionNavigateOptsFromJSON(json['navigate']),
        'operation_type': json['operation_type'],
        'parse': !exists(json, 'parse') ? undefined : ExpressionParseOptsFromJSON(json['parse']),
        'returns': ReturnsMetaFromJSON(json['returns']),
    };
}

export function ExpressionOperationToJSON(value?: ExpressionOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branches': ExpressionBranchesOptsToJSON(value.branches),
        'filter': ExpressionFilterOptsToJSON(value.filter),
        'navigate': ExpressionNavigateOptsToJSON(value.navigate),
        'operation_type': value.operation_type,
        'parse': ExpressionParseOptsToJSON(value.parse),
        'returns': ReturnsMetaToJSON(value.returns),
    };
}

