import type { SVGProps } from "react";
import * as React from "react";
const SvgBrowser = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 2H3.75A2.752 2.752 0 0 0 1 4.75v8.5A2.752 2.752 0 0 0 3.75 16h5.862a.75.75 0 0 0 0-1.5H3.75c-.689 0-1.25-.561-1.25-1.25V8h13v1.978a.75.75 0 0 0 1.5 0V4.75A2.752 2.752 0 0 0 14.25 2ZM4 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
    />
    <path
      fill="currentColor"
      d="m17.324 12.233-5.94-2.17a1.021 1.021 0 0 0-1.082.24 1.018 1.018 0 0 0-.237 1.081l2.17 5.941c.149.406.536.675.967.675h.022a1.03 1.03 0 0 0 .958-.716l.753-2.351 2.352-.752a1.03 1.03 0 0 0 .039-1.948h-.002Z"
    />
  </svg>
);
export default SvgBrowser;
