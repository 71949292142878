import type { SVGProps } from "react";
import * as React from "react";
const SvgWarningTriangle = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.189 14.516 11.764 5.12a2.3 2.3 0 0 0-2.012-1.161A2.3 2.3 0 0 0 7.74 5.12l-5.425 9.396a2.297 2.297 0 0 0 0 2.322A2.299 2.299 0 0 0 4.327 18h10.85c.84 0 1.592-.434 2.012-1.162.42-.727.42-1.595 0-2.322ZM9.002 8.5a.75.75 0 0 1 1.5 0V12a.75.75 0 0 1-1.5 0V8.5Zm.75 7.069a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2Z"
    />
  </svg>
);
export default SvgWarningTriangle;
