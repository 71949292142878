/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody
 */
export interface PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody {
    /**
     * Default document provider for copy&paste
     * @type {string}
     * @memberof PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody
     */
    default_document_provider_copy_paste?: PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyDefaultDocumentProviderCopyPasteEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyDefaultDocumentProviderCopyPasteEnum {
    Empty = '',
    Confluence = 'confluence',
    GoogleDocs = 'google_docs',
    Notion = 'notion',
    CopyPasteBasecamp = 'copy_paste_basecamp',
    CopyPasteConfluence = 'copy_paste_confluence',
    CopyPasteGithubWiki = 'copy_paste_github_wiki',
    CopyPasteGoogleDocs = 'copy_paste_google_docs',
    CopyPasteNotion = 'copy_paste_notion',
    CopyPasteQuip = 'copy_paste_quip'
}

export function PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyFromJSON(json: any): PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody {
    return PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_document_provider_copy_paste': !exists(json, 'default_document_provider_copy_paste') ? undefined : json['default_document_provider_copy_paste'],
    };
}

export function PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBodyToJSON(value?: PostmortemsSettingsUpdateDefaultCopyPasteProviderRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_document_provider_copy_paste': value.default_document_provider_copy_paste,
    };
}

