import {
  ManagementMeta,
  Resource,
  ScopeNameEnum,
  StepSlim,
  Trigger,
  Workflow,
  WorkflowStateEnum,
} from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { defaultManagementMeta } from "@incident-shared/management-meta/utils";
import { GenericErrorMessage, LoadingBar } from "@incident-ui";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAllResources } from "src/hooks/useResources";
import { getEmptyScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { addExpressionsToScope } from "../../../@shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "../../../@shared/engine/expressions/ExpressionsMethodsProvider";
import { ClonedWorkflow, WorkflowFormData } from "../common/types";
import { useMutationController } from "./hooks/useMutationController";
import { useStepsController } from "./hooks/useStepsController";
import { useTriggerController } from "./hooks/useTriggerController";
import {
  useSafeCloseWorkflowDrawer,
  useViewController,
  WorkflowDrawerState,
} from "./hooks/useViewController";
import { makeFormDefaultValues } from "./makeFormDefaultValues";
import { WorkflowsCreateEditBody } from "./sections/WorkflowsCreateEditBody";
import { WorkflowsCreateEditDrawers } from "./sections/WorkflowsCreateEditDrawers";
import { WorkflowsCreateEditHeader } from "./sections/WorkflowsCreateEditHeader";
import { WorkflowsCreateEditModals } from "./sections/WorkflowsCreateEditModals";
import { WorkflowsFormProvider } from "./WorkflowsFormContext";

export const WorkflowsCreateEditForm = ({
  workflow,
  management,
  defaultTrigger,
  titleInputRef,
  buttonsRef,
  domReady,
  template,
  templateName,
  mode,
}: {
  workflow?: Workflow | ClonedWorkflow;
  management?: ManagementMeta;
  defaultTrigger?: Trigger;
  titleInputRef: React.RefObject<HTMLDivElement>;
  buttonsRef: React.RefObject<HTMLDivElement>;
  domReady: boolean;
  mode: Mode;
  template?: Workflow | ClonedWorkflow;
  templateName?: string;
  backHref?: string;
}): React.ReactElement => {
  const defaultValues = makeFormDefaultValues({
    workflow,
    template,
    defaultTrigger,
  });

  const formMethods = useForm<WorkflowFormData>({
    defaultValues,
  });

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  // Steps
  const {
    data: { steps },
    isLoading: stepsLoading,
    error: stepsError,
  } = useAPI("workflowsListSteps", undefined, { fallbackData: { steps: [] } });

  if (resourcesError || stepsError) {
    return <GenericErrorMessage error={resourcesError || stepsError} />;
  }

  if (resourcesLoading || stepsLoading) {
    return <LoadingBar />;
  }

  return (
    <WorkflowsCreateEditFormInner
      workflow={workflow}
      defaultTrigger={defaultTrigger}
      management={management}
      titleInputRef={titleInputRef}
      buttonsRef={buttonsRef}
      domReady={domReady}
      mode={mode}
      template={template}
      templateName={templateName}
      resources={resources}
      steps={steps}
      formMethods={formMethods}
    />
  );
};

// WorkflowsCreateEditFormInner hold all the state about what 'view' of the page we're looking
// at: e.g. is there a drawer or a modal open, are we adding a trigger, etc.
// There is a lot of state being stored here, and it can't really be put into a meaningful tree
// so instead we split ourselves into `Controllers`. Each Controller manages a part of the state of
// the page. These are then made available to components lower down via the `WorkflowsFormProvider`.
const WorkflowsCreateEditFormInner = ({
  workflow,
  management,
  titleInputRef,
  buttonsRef,
  domReady,
  template,
  templateName,
  defaultTrigger,
  mode,
  resources,
  steps,
  formMethods,
}: {
  workflow?: Workflow | ClonedWorkflow;
  management?: ManagementMeta;
  titleInputRef: React.RefObject<HTMLDivElement>;
  buttonsRef: React.RefObject<HTMLDivElement>;
  domReady: boolean;
  mode: Mode;
  template?: Workflow | ClonedWorkflow;
  templateName?: string;
  backHref?: string;
  defaultTrigger?: Trigger;
  resources: Resource[];
  steps: StepSlim[];
  formMethods: UseFormReturn<WorkflowFormData>;
}): React.ReactElement => {
  const { formState, control } = formMethods;
  const { hasScope } = useIdentity();

  const viewState = useViewController({ workflow, template, defaultTrigger });
  const { onCloseDrawer, onOpenDrawer, onCloseModal } = viewState;

  const { triggerCallbacks, triggerError } = useTriggerController({
    workflow,
    template,
    formMethods,
    defaultTrigger,
    onCloseDrawer,
    onOpenDrawer,
  });
  const trigger = triggerCallbacks.trigger;

  const isDraft =
    mode === Mode.Create || workflow?.state === WorkflowStateEnum.Draft;

  const mutations = useMutationController({
    formMethods,
    workflow,
    trigger,
    isDraft,
    onCloseModal,
    templateName,
  });

  const stepCallbacks = useStepsController({
    formMethods,
    onOpenDrawer,
    onCloseDrawer,
  });

  const onSafeCloseDrawer = useSafeCloseWorkflowDrawer({
    onCloseDrawer: viewState.onCloseDrawer,
    drawerState: viewState.drawerState,
    isStepsFormDirty: stepCallbacks.isDirty,
    clearStepState: stepCallbacks.clearStepFormState,
  });

  const expressionsMethods = useFieldArray({
    control,
    name: "expressions",
    keyName: "key",
  });

  if (triggerError) {
    return <GenericErrorMessage error={triggerError} />;
  }

  const scope = trigger
    ? addExpressionsToScope(trigger.scope, expressionsMethods.fields || [])
    : getEmptyScope();

  // The below is an alternative to formState.isDirty
  // formState.isDirty doesn't work for us because it always
  // seems to be set to true.
  //
  // More info here: https://github.com/react-hook-form/react-hook-form/issues/3213#issuecomment-1242630481
  const isMainFormDirty = !!Object.keys(formState.dirtyFields).length;

  const scopeRequiredToEdit = workflow?.include_private_incidents
    ? ScopeNameEnum.WorkflowsApprovePrivate
    : ScopeNameEnum.WorkflowsUpdate;

  const canEdit = hasScope(
    mode === Mode.Edit ? scopeRequiredToEdit : ScopeNameEnum.WorkflowsCreate,
  );

  const deps = {
    scope,
    trigger,
    resources,
    steps,
    isMainFormDirty,
    canEdit,
    isDraft,
    workflow,
    management: management || defaultManagementMeta(),
  };

  // We prompt if you're currently mutating the workflow to give the mutation time to succeed / fail
  // before you leave the page.
  const shouldPromptBeforeNavigation = isMainFormDirty && !mutations.isMutating;

  return (
    <WorkflowsFormProvider
      content={{
        viewState,
        mutations,
        stepCallbacks,
        deps,
        triggerCallbacks,
      }}
    >
      {/* Overlay the main section when the drawers are open to prevent interactions. */}
      <AnimatePresence>
        {viewState.hasOpenDrawer && (
          <motion.div
            onClick={() => {
              if (viewState.drawerState !== WorkflowDrawerState.None) {
                onSafeCloseDrawer();
              }
            }}
            className="w-full h-full absolute bg-black z-10 !mt-0 top-0 left-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.05 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          />
        )}
      </AnimatePresence>
      <Form.Root
        outerClassName={tcx("h-full", {
          // Prevent scrolling in the main section when the drawers are open.
          "overflow-hidden": viewState.hasOpenDrawer,
        })}
        innerClassName="h-full"
        loadingWrapperClassName={"h-full"}
        onSubmit={mutations.updateWorkflow}
        formMethods={formMethods}
        warnWhenDirty
        overrideIsDirty={shouldPromptBeforeNavigation}
      >
        <ExpressionsMethodsProvider
          expressionsMethods={expressionsMethods}
          allowAllOfACatalogType
          showExpressionNames
        >
          <WorkflowsCreateEditHeader
            buttonsRef={buttonsRef}
            titleInputRef={titleInputRef}
            domReady={domReady}
          />
          <div className="flex mx-6 !pb-12 !mt-0">
            <WorkflowsCreateEditBody />
            <WorkflowsCreateEditDrawers onClose={onSafeCloseDrawer} />
            <WorkflowsCreateEditModals />
          </div>
        </ExpressionsMethodsProvider>
      </Form.Root>
    </WorkflowsFormProvider>
  );
};
