/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface TimelineItemIncidentUpdate
 */
export interface TimelineItemIncidentUpdate {
    /**
     * 
     * @type {IncidentStatus}
     * @memberof TimelineItemIncidentUpdate
     */
    new_incident_status: IncidentStatus;
    /**
     * 
     * @type {Severity}
     * @memberof TimelineItemIncidentUpdate
     */
    new_severity?: Severity;
    /**
     * the minutes until the next update
     * @type {number}
     * @memberof TimelineItemIncidentUpdate
     */
    next_update_in_minutes?: number;
    /**
     * the update time the user supplied
     * @type {string}
     * @memberof TimelineItemIncidentUpdate
     */
    next_update_time: string;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof TimelineItemIncidentUpdate
     */
    previous_incident_status?: IncidentStatus;
    /**
     * 
     * @type {Severity}
     * @memberof TimelineItemIncidentUpdate
     */
    previous_severity?: Severity;
    /**
     * the id of the update
     * @type {string}
     * @memberof TimelineItemIncidentUpdate
     */
    update_id?: string;
    /**
     * 
     * @type {TextDocument}
     * @memberof TimelineItemIncidentUpdate
     */
    update_text?: TextDocument;
    /**
     * 
     * @type {Actor}
     * @memberof TimelineItemIncidentUpdate
     */
    updater: Actor;
}

export function TimelineItemIncidentUpdateFromJSON(json: any): TimelineItemIncidentUpdate {
    return TimelineItemIncidentUpdateFromJSONTyped(json, false);
}

export function TimelineItemIncidentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineItemIncidentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'new_incident_status': IncidentStatusFromJSON(json['new_incident_status']),
        'new_severity': !exists(json, 'new_severity') ? undefined : SeverityFromJSON(json['new_severity']),
        'next_update_in_minutes': !exists(json, 'next_update_in_minutes') ? undefined : json['next_update_in_minutes'],
        'next_update_time': json['next_update_time'],
        'previous_incident_status': !exists(json, 'previous_incident_status') ? undefined : IncidentStatusFromJSON(json['previous_incident_status']),
        'previous_severity': !exists(json, 'previous_severity') ? undefined : SeverityFromJSON(json['previous_severity']),
        'update_id': !exists(json, 'update_id') ? undefined : json['update_id'],
        'update_text': !exists(json, 'update_text') ? undefined : TextDocumentFromJSON(json['update_text']),
        'updater': ActorFromJSON(json['updater']),
    };
}

export function TimelineItemIncidentUpdateToJSON(value?: TimelineItemIncidentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_incident_status': IncidentStatusToJSON(value.new_incident_status),
        'new_severity': SeverityToJSON(value.new_severity),
        'next_update_in_minutes': value.next_update_in_minutes,
        'next_update_time': value.next_update_time,
        'previous_incident_status': IncidentStatusToJSON(value.previous_incident_status),
        'previous_severity': SeverityToJSON(value.previous_severity),
        'update_id': value.update_id,
        'update_text': TextDocumentToJSON(value.update_text),
        'updater': ActorToJSON(value.updater),
    };
}

