import { GenericErrorMessage, Toast, ToastTheme } from "@incident-ui";
import { ToastContext, useToast } from "@incident-ui/Toast/ToastProvider";
import { ErrorBoundary } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Banners,
  CommunityBanner,
  ImpersonateBanner,
  OrgCustomBanner,
} from "src/components/@shared/banners";
import { AssistantOverlay } from "src/components/insights/assistant/AssistantOverlay";
import { useNotifications } from "src/components/notifications/useNotifications";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { MainSidebar } from "../MainSidebar/MainSidebar";
import { MobileSidebar } from "../MainSidebar/MobileSidebar";
import { MobileHeader } from "../MobileHeader/MobileHeader";

export function AppWrapper({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    toastProps,
    open: toastOpen,
    setOpen: setToastOpen,
  } = useContext(ToastContext);

  const { isImpersonating } = useIdentity();
  const version = process.env.REACT_APP_SENTRY_RELEASE;
  const showToast = useToast();
  const { newVersionToastEnabled } = useFlags();
  useAPI(
    newVersionToastEnabled ? "systemVersion" : null,
    {},
    {
      refreshInterval: 15 * 60 * 1000,
      onSuccess: ({ commit: latestVersion }) => {
        if (!newVersionToastEnabled) return;
        if (!latestVersion) return;
        if (latestVersion === version) return;

        // show a toast if the backend & frontend versions don't match
        showToast({
          title: "New version available",
          description: (
            <>
              A new version of the app is available.{" "}
              <a
                className="text-content-primary underline cursor-pointer"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh to update
              </a>
              .
            </>
          ),
          theme: ToastTheme.Info,
        });
      },
    },
  );

  const showNotificationsQueryParam = new URLSearchParams(
    window.location.search,
  ).get("show-notifications");

  const [showNotifications, setShowNotifications] = useState<boolean>(
    showNotificationsQueryParam ? true : false,
  );

  // If we're opening the notifications bar and we're on a medium screen, make sure we shut the
  // sidebar first so we they don't get laid on top of each other.
  useEffect(() => {
    // 768 pixels is the breakpoint for when the notifications bar takes up 100% of the screen ("medium").
    if (showNotifications && window.innerWidth < 768) {
      setSidebarOpen(false);
    }
  }, [showNotifications]);

  // Similarly, shut the notifications bar if we're opening sidebar.
  useEffect(() => {
    if (sidebarOpen && window.innerWidth < 768) {
      setShowNotifications(false);
    }
  }, [sidebarOpen]);

  const { markNotificationsAsRead } = useNotifications();
  const closeNotificationsDrawer = async () => {
    setShowNotifications(false);

    markNotificationsAsRead();
  };

  const openNotificationsDrawer = () => {
    setShowNotifications(true);
  };

  return (
    <>
      {/* This is used to scroll to the top of the page on nav change*/}
      <div id="top" />
      <MobileHeader
        sidebarIsOpen={sidebarOpen}
        toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        openNotificationsDrawer={openNotificationsDrawer}
        closeNotificationsDrawer={closeNotificationsDrawer}
        showNotifications={showNotifications}
      />
      <div
        className={tcx(
          "relative flex w-full min-h-full max-h-full overflow-hidden bg-surface-invert",
          {
            ["bg-purple-800"]: process.env.REACT_APP_DEV_SIDEBAR === "true",
            ["bg-red-700"]: isImpersonating,
          },
        )}
      >
        <MobileSidebar
          isOpen={sidebarOpen}
          closeSidebar={() => setSidebarOpen(false)}
        />
        <MainSidebar
          openNotificationsDrawer={openNotificationsDrawer}
          closeNotificationsDrawer={closeNotificationsDrawer}
          showNotifications={showNotifications}
        />
        <div className={tcx("grow overflow-hidden h-[100vh]", "p-0 md:p-2")}>
          <div
            className={tcx(
              "bg-white overflow-hidden w-full",
              "md:rounded-2 h-[100vh] md:h-[calc(100vh-16px)]",
            )}
          >
            <main
              className={tcx(
                "overflow-hidden w-full min-h-full flex flex-col relative",
                "h-[calc(100vh-72px)] md:h-[calc(100vh-32px)]",
              )}
            >
              <ErrorBoundary fallback={<GenericErrorMessage />}>
                <OrgCustomBanner />
                <CommunityBanner />
                <Banners />
                {/* We don't want the overflow to spread to the entire page */}
                <div className="flex-1 overflow-hidden">{children}</div>
                <ImpersonateBanner />
              </ErrorBoundary>
            </main>
          </div>
        </div>
        <AssistantOverlay />
        <Toast open={toastOpen} setOpen={setToastOpen} {...toastProps} />
      </div>
    </>
  );
}
