import { Txt } from "@incident-ui/Txt/Txt";
import React, { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { JSONPreviewLines } from "./JSONElements";
import { getListOfJsonNodes } from "./jsonTree";

export type JSONPreviewProps = {
  payload: object;
  containerClassName?: string;
  className?: string;
  defaultExpanded?: boolean;
  compact?: boolean;
  lightMode?: boolean;
} & ( // with the requested content, or it calls the callback. // You can't have both of these: Either clicking a key shows a popover
  | {
      getKeyPopoverContent?: (path: string) => React.ReactElement | string;
      onClickKey?: never;
    }
  | {
      getKeyPopoverContent?: never;
      onClickKey?: (path: string) => void;
    }
) &
  titleProps;

type titleProps =
  | {
      title?: string;
      titleElement?: never;
    }
  | {
      title?: never;
      titleElement?: React.ReactElement;
    };

export const JSONPreview = ({
  payload,
  getKeyPopoverContent,
  containerClassName,
  className,
  title,
  titleElement,
  defaultExpanded = true,
  onClickKey,
  compact,
  lightMode = false,
}: JSONPreviewProps) => {
  const [toggledNodes, setToggledNodes] = useState<Set<string>>(new Set());

  const toggleNode = (path: string) => {
    setToggledNodes((currentSet) => {
      const newSet = new Set(currentSet);
      if (newSet.has(path)) {
        newSet.delete(path);
      } else {
        newSet.add(path);
      }
      return newSet;
    });
  };

  const nodes = getListOfJsonNodes(payload, toggledNodes, defaultExpanded);

  return (
    <div
      className={tcx(
        !lightMode ? "bg-surface-invert flex flex-col text-sm" : "bg-surface",
        containerClassName,
      )}
    >
      {titleElement}
      {title && (
        <Txt xs greySmallCaps className={"w-full p-6"}>
          {title}
        </Txt>
      )}

      <div
        className={tcx(
          "text-slate-200 h-full overflow-auto",
          "scrollbar scrollbar-w-2 scrollbar-h-2 scrollbar-track-rounded-full scrollbar-thumb-rounded-full",
          {
            "scrollbar-track-slate-900 scrollbar-corner-slate-900 scrollbar-thumb-slate-700":
              !lightMode,
            "scrollbar-track-transparent scrollbar-corner-slate-100 scrollbar-thumb-slate-300":
              lightMode,
          },
          className,
        )}
      >
        <ul
          className={tcx("group flex flex-col space-y-1 mono", {
            "mt-6": !title && !compact,
            "mx-6 mb-6": !compact,
          })}
        >
          <JSONPreviewLines
            lightMode={lightMode}
            nodes={nodes}
            toggleNode={toggleNode}
            getKeyPopoverContent={getKeyPopoverContent}
            onClickKey={onClickKey}
          />
        </ul>
      </div>
    </div>
  );
};
