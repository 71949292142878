import {
  useEditorState,
  useEditorView,
} from "@aeaton/react-prosemirror/EditorProvider";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
} from "@incident-ui";
import { SharedButtonProps } from "@incident-ui/Button/Button";
import { EditorState, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import React, { useCallback } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { TemplatedTextDisplayStyle } from "./TemplatedTextDisplay";

// This was originally copied from @aeaton/react-prosemirror/Toolbar.tsx,
// but we've made updates to enable dropdown menus and also changed a lot
// of the styles.

export type ToolbarItem = {
  id: string;
  action: (
    state: EditorState,
    dispatch: (tr: Transaction) => void,
    view: EditorView,
  ) => boolean;
  title: string;
  active?: (state: EditorState) => boolean;
  enable?: (state: EditorState) => boolean;
  icon?: IconEnum;
  content?: React.ReactNode;
};

export type ToolbarGroup = {
  id: string;
} & (
  | {
      items: ToolbarItem[];
      render?: never;
      dropDownButton?: never;
    }
  | {
      items: ToolbarItem[];
      dropDownButton: React.ReactNode;
      render?: never;
    }
  | {
      items?: never;
      dropDownButton?: never;
      render: (props: {
        view: EditorView;
        state: EditorState;
      }) => React.ReactNode;
    }
);

const ToolbarButtonForItem: React.FC<{ item: ToolbarItem }> = ({ item }) => {
  const state = useEditorState();
  const view = useEditorView();

  const executeAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      item.action(state, view.dispatch, view);
    },
    [item, state, view],
  );

  return (
    <ToolbarButton
      title={item.title}
      icon={item.icon}
      onMouseDown={executeAction}
      active={item.active && item.active(state)}
      disabled={item.enable && !item.enable(state)}
    >
      {item.content}
    </ToolbarButton>
  );
};

type ToolbarButtonProps = {
  title: string;
  icon?: IconEnum;
  active?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
} & Pick<SharedButtonProps, "onClick">;

export const toolbarButtonProps = (
  active: boolean,
  disabled: boolean,
): Pick<SharedButtonProps, "theme" | "size" | "className"> => ({
  theme: ButtonTheme.Ghost,
  size: BadgeSize.Medium,
  className: tcx("text-content-tertiary hover:text-content-primary", {
    "text-content-secondary border-slate-200": active,
    "text-content-invert": disabled,
  }),
});

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  title,
  icon,
  onMouseDown,
  onClick,
  active = false,
  disabled = false,
  children,
}) => {
  const buttonProps = toolbarButtonProps(active, disabled);

  return (
    <Button
      analyticsTrackingId={null}
      disabled={disabled}
      onMouseDown={onMouseDown}
      onClick={onClick}
      title={title}
      icon={icon}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

const ToolbarDropDownButton: React.FC<{ item: ToolbarItem }> = ({ item }) => {
  const state = useEditorState();
  const view = useEditorView();

  const executeAction = useCallback(() => {
    item.action(state, view.dispatch, view);
  }, [item, state, view]);

  const disabled = item.enable && !item.enable(state);

  return (
    <DropdownMenuItem
      key={item.id}
      analyticsTrackingId={null}
      label={item.title || item.id}
      onSelect={executeAction}
      icon={item.icon}
      disabled={disabled}
      className={tcx({ "!px-1.5": disabled, "!py-2": true })}
    />
  );
};

const ToolbarGroup = ({
  items,
  render,
  dropDownButton,
}: ToolbarGroup): React.ReactElement => {
  const state = useEditorState();
  const view = useEditorView();

  // Option 1: there's a custom render function (e.g. for variables)
  if (render) {
    return <>{render({ state, view })}</>;
  }

  // Option 2: it's a dropdown (e.g. for tables)
  if (dropDownButton) {
    return (
      <DropdownMenu scroll triggerButton={dropDownButton}>
        {items.map((item) => (
          <ToolbarDropDownButton key={item.id} item={item} />
        ))}
      </DropdownMenu>
    );
  }

  // Option 3: it's just a normal list of items (e.g. Bold/Italic)
  return (
    <>
      {items.map((item) => (
        <ToolbarButtonForItem key={item.id} item={item} />
      ))}
    </>
  );
};

/* eslint-disable react/prop-types */
export const Toolbar: React.FC<{
  toolbar: ToolbarGroup[];
  style: TemplatedTextDisplayStyle;
  disabled?: boolean;
  className?: string;
  headerNode?: React.ReactNode;
}> = React.memo(
  ({ toolbar, style, className = "", disabled = false, headerNode = null }) => {
    return (
      <div
        className={tcx(
          className,
          "relative",
          "flex justify-between",
          "text-content-tertiary",
          {
            "p-2 bg-surface-secondary":
              style !== TemplatedTextDisplayStyle.Naked,
          },
        )}
      >
        <div className="flex gap-1 divide-x divide-solid divide-slate-100">
          {toolbar.map((group, idx) => (
            <div
              className={tcx("flex items-center gap-1 px-2", {
                "text-slate-400 cursor-not-allowed pointer-events-none":
                  disabled,
                // The classes below make the divider on the parent class behave properly,
                // sadly divide doesn't work with flex and gap.
                "first:pl-0": idx === 0,
                "last:pr-0": idx === toolbar.length - 1,
              })}
              key={group.id}
            >
              <ToolbarGroup {...group} />
            </div>
          ))}
        </div>
        {headerNode && (
          <div className="flex items-center gap-1 px-2">{headerNode}</div>
        )}
      </div>
    );
  },
);
Toolbar.displayName = "Toolbar";
