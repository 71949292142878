import {
  InternalStatusPageContentSummary,
  InternalStatusPageContentSummaryThemeEnum,
} from "@incident-io/api";
import {
  AppWrapper,
  LoadingBar,
  NotFoundError,
  OuterContainerProvider,
  TimeProvider,
  UIProvider,
  UnexpectedError,
} from "@incident-io/status-page-ui";
import { OrgAwareLink } from "@incident-shared/org-aware";
import { captureException, ErrorBoundary } from "@sentry/react";
import { DateTime } from "luxon";
import React, { useEffect, useRef } from "react";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { InternalStatusPageBanner } from "./Banner/Banner";
import { Footer } from "./Footer";
import { Header } from "./Header/Header";
import styles from "./Homepage.module.scss";

export const PageWrapper = ({
  subpath = "default",
  incidentExternalId,
  children,
}: {
  subpath?: string;
  incidentExternalId?: number;
  children: React.ReactNode;
}) => {
  const analytics = useAnalytics();
  const { data: summaryData, error: summaryError } = useAPI(
    "internalStatusPageContentShowPage",
    {
      subpath,
    },
  );
  useTheme(summaryData?.summary);
  const initialNow = useRef(DateTime.now());

  if (summaryError?.status === 404) {
    return (
      <AppWrapper className={tcx(styles.interVariable, "mt-[76px]")}>
        <NotFoundError />
      </AppWrapper>
    );
  }

  if (summaryError) {
    console.error(summaryError);
    captureException(summaryError);
    return (
      <AppWrapper className={tcx(styles.interVariable, "mt-[76px]")}>
        <UnexpectedError />
      </AppWrapper>
    );
  }

  if (!summaryData) {
    return (
      <AppWrapper className={tcx(styles.interVariable, "mt-[76px]")}>
        <LoadingBar className="rounded-2 !h-32" />
        <LoadingBar className="rounded-2 !h-24" />
        <LoadingBar className="rounded-2 !h-64" />
      </AppWrapper>
    );
  }

  return (
    <OuterContainerProvider className={styles.interVariable}>
      <InternalStatusPageBanner />
      <AppWrapper>
        <TimeProvider
          initialNow={{
            isoDate: initialNow.current.toISO(),
            zone: initialNow.current.zoneName,
            locale: initialNow.current.locale,
          }}
        >
          <UIProvider
            onError={captureException}
            IncidentLink={({
              incident: { incident_id },
              className,
              children,
              analyticsTrackingId,
            }) => (
              <OrgAwareLink
                className={className}
                to={
                  subpath === "default"
                    ? `/status/incidents/${incident_id}`
                    : `/status/${subpath}/incidents/${incident_id}`
                }
                analyticsTrackingId={
                  analyticsTrackingId ? analyticsTrackingId : undefined
                }
              >
                {children}
              </OrgAwareLink>
            )}
            InternalLink={({
              to,
              className,
              children,
              analyticsTrackingId,
            }) => (
              <OrgAwareLink
                className={className}
                to={to}
                analyticsTrackingId={
                  analyticsTrackingId ? analyticsTrackingId : undefined
                }
              >
                {children}
              </OrgAwareLink>
            )}
            analytics={analytics}
          >
            <Header
              incidentExternalId={incidentExternalId}
              summary={summaryData.summary}
            />
            <ErrorBoundary fallback={<UnexpectedError />}>
              {children}
            </ErrorBoundary>
          </UIProvider>
          <Footer />
        </TimeProvider>
      </AppWrapper>
    </OuterContainerProvider>
  );
};

const useTheme = (summary?: InternalStatusPageContentSummary) => {
  const theme =
    summary?.theme ?? InternalStatusPageContentSummaryThemeEnum.Light;

  useEffect(() => {
    // We set `.dark.` on the parent of `body` - i.e. `<html>`. This lets us use
    // darkmode styles on the body element to set the background.
    const target = document.body.parentElement;
    if (!target) return void 0;

    // Set the theme
    if (theme === InternalStatusPageContentSummaryThemeEnum.Dark) {
      target.classList.add("dark");
    } else {
      target.classList.remove("dark");
    }

    // When navigating away, remove any dark class
    return () => target.classList.remove("dark");
  }, [theme]);
};
