import { WorkflowSlim } from "@incident-io/api";
import { ConditionBadge } from "@incident-shared/engine/conditions";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { Icon, IconEnum, Txt } from "@incident-ui";
import pluralize from "pluralize";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

export const WorkflowsListItemAccordion = ({
  workflow,
}: {
  workflow: WorkflowSlim;
}) => {
  const stepsToShow = workflow.steps.slice(0, 3);
  const hiddenStepCount = workflow.steps.length - stepsToShow.length;

  const incidentModesMap = {
    private: workflow.include_private_incidents,
    retrospective: workflow.include_retrospective_incidents,
    test: workflow.include_test_incidents,
  };

  const matchingModes = Object.keys(incidentModesMap).filter(function (i) {
    return incidentModesMap[i];
  });

  const runsForString = joinSpansWithCommasAndConnectorWord(
    matchingModes.map((o) => (
      <Txt inline bold key={o}>
        {o}
      </Txt>
    )),
  );

  return (
    <div
      // In a folder, this spans the central two columns as the outer two are padding
      // Out of a folder, it should be fullwidth
      className="border-t border-stroke bg-white text-content-primary grow p-3 flex flex-col gap-2"
    >
      {/* Also runs for... */}
      {runsForString && (
        <div className="flex flex-row items-start gap-1">
          <Icon id={IconEnum.Incident} className="text-slate-400" />
          <span className="truncate">
            Also runs for {runsForString} incidents
          </span>
        </div>
      )}
      {/* Trigger */}
      <div className="flex flex-row items-start gap-1">
        <Icon id={IconEnum.Workflows} className="text-slate-400" />
        <span className="truncate">When {workflow.trigger.label}</span>
      </div>
      {/* Conditions */}
      <div className="flex flex-row items-start gap-1">
        <Icon id={IconEnum.Filter} className="text-slate-400" />
        {workflow.condition_groups.length > 0 ? (
          <ConditionGroupsList groups={workflow.condition_groups} boxless />
        ) : (
          <div className="flex">
            <ConditionBadge
              subject="This workflow will apply to"
              operation={noConditionsOperationText(workflow.trigger.name)}
              criteria={null}
              className="border border-stroke"
              theme="naked"
            />
          </div>
        )}
      </div>
      {/* Steps */}
      <div className="flex flex-row items-start gap-1">
        {/* Show first three steps in workflow */}
        {stepsToShow.length > 0 ? (
          <>
            {stepsToShow.map((step, index) => (
              <div key={index} className="flex flow-row gap-1 items-center">
                <Icon
                  id={index === 0 ? IconEnum.CaratRight : IconEnum.ArrowRight}
                  className="text-slate-400"
                />
                <span>{step.label}</span>
              </div>
            ))}
            {/* Show count of remaining hidden steps */}
            {hiddenStepCount > 0 && (
              <span>
                (+ {hiddenStepCount} more {pluralize("step", hiddenStepCount)})
              </span>
            )}
          </>
        ) : (
          <div className="flex flow-row gap-1 items-center">
            <Icon
              id={IconEnum.CloseCircle}
              className="!w-6 !h-6 text-slate-400"
            />
            <span>You haven&apos;t added any steps to this workflow yet</span>
          </div>
        )}
      </div>
    </div>
  );
};

export function noConditionsOperationText(triggerName: string): string {
  return `all incidents${
    triggerName.includes("user")
      ? " and users"
      : triggerName.includes("action")
      ? " and actions"
      : triggerName.includes("follow-up")
      ? " and follow-ups"
      : triggerName.includes("message")
      ? " and messages"
      : ""
  }`;
}
