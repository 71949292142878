/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageComponentImpactPayload,
    StatusPageComponentImpactPayloadFromJSON,
    StatusPageComponentImpactPayloadFromJSONTyped,
    StatusPageComponentImpactPayloadToJSON,
} from './StatusPageComponentImpactPayload';

/**
 * 
 * @export
 * @interface StatusPageSetIncidentComponentImpactsRequestBody
 */
export interface StatusPageSetIncidentComponentImpactsRequestBody {
    /**
     * A list of time periods that this incident had an impact on a component
     * @type {Array<StatusPageComponentImpactPayload>}
     * @memberof StatusPageSetIncidentComponentImpactsRequestBody
     */
    component_impacts: Array<StatusPageComponentImpactPayload>;
}

export function StatusPageSetIncidentComponentImpactsRequestBodyFromJSON(json: any): StatusPageSetIncidentComponentImpactsRequestBody {
    return StatusPageSetIncidentComponentImpactsRequestBodyFromJSONTyped(json, false);
}

export function StatusPageSetIncidentComponentImpactsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageSetIncidentComponentImpactsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_impacts': ((json['component_impacts'] as Array<any>).map(StatusPageComponentImpactPayloadFromJSON)),
    };
}

export function StatusPageSetIncidentComponentImpactsRequestBodyToJSON(value?: StatusPageSetIncidentComponentImpactsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_impacts': ((value.component_impacts as Array<any>).map(StatusPageComponentImpactPayloadToJSON)),
    };
}

