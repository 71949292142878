import {
  Incident,
  IncidentPostmortemStatusEnum,
  PolicyViolationLevelEnum,
  PolicyViolationPolicyTypeEnum,
  PostmortemDocument,
  PostmortemTemplate,
  PostmortemTemplateWritingModeEnum,
} from "@incident-io/api";
import { PolicyViolationNotification } from "@incident-shared/policy/PolicyViolationNotification";
import { ExportPostmortemDrawer } from "@incident-shared/postmortems/ExportPostmortemDrawer";
import { getColorPalette } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Markdown,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { DropdownMenuSubItem } from "@incident-ui/DropdownMenu/DropdownMenu";
import { SelectOption } from "@incident-ui/Select/types";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import React from "react";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { formatTimestampLocale } from "src/utils/datetime";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { SectionWrapper } from "../body/SectionWrapper";
import { usePoliciesAndViolations } from "../hooks";
import { usePostmortem } from "../postincidentflow/usePostmortem";
import { getDocumentProvider } from "../sidebar/PostMortemPrompt";
import { StatusProps } from "./utils";

type PostmortemHeaderProps = {
  incident: Incident;
  templates: PostmortemTemplate[];
};

export const PostmortemHeader = ({
  incident,
  templates,
}: PostmortemHeaderProps) => {
  const [exportDrawerOpen, setExportDrawerOpen] = useState(false);
  const { postmortemNameFormatted, postmortemName } =
    usePostmortemName(incident);

  const { availableDocument: document, enqueuedDocument } =
    usePostmortem(incident);

  const { postmortem_template_id: postmortemTemplateId } = incident;

  const isExporting = !!enqueuedDocument;

  const noDocuments = !document && !isExporting;

  const templateIsInHouse =
    templates.find((template) => template.id === postmortemTemplateId)
      ?.writing_mode === PostmortemTemplateWritingModeEnum.InHouse;

  // we want to hide the link card if the template is in-house and they haven't
  // exported the document yet
  const hideLinkCard =
    (templateIsInHouse && !document && !isExporting) || noDocuments;

  return (
    <>
      <SectionWrapper
        sectionName={postmortemName}
        topLevelHeader
        className={templateIsInHouse ? "pb-0" : ""} // Without this, the padding looks really bizzare
        headerAccessory={
          <div className="flex items-center gap-2">
            <PostmortemHeaderAccessory
              incident={incident}
              templates={templates}
              onCreatePostmortemClick={() => setExportDrawerOpen(true)}
            />
          </div>
        }
      >
        {!hideLinkCard && (
          <div className="bg-surface-secondary rounded-lg flex items-center justify-center w-full h-16 p-4">
            {isExporting && (
              <div className="text-content-tertiary">
                Creating your {postmortemNameFormatted}…
              </div>
            )}
            {document && <PostmortemLink incident={incident} />}
          </div>
        )}
      </SectionWrapper>
      {exportDrawerOpen && (
        <ExportPostmortemDrawer
          incident={incident}
          onClose={() => setExportDrawerOpen(false)}
          templateId={postmortemTemplateId}
        />
      )}
    </>
  );
};

type PostmortemHeaderAccessoryProps = {
  incident: Incident;
  templates: PostmortemTemplate[];
  onCreatePostmortemClick: () => void;
};

const PostmortemHeaderAccessory = ({
  incident,
  onCreatePostmortemClick,
  templates,
}: PostmortemHeaderAccessoryProps) => {
  const { postmortem_template_id } = incident;

  const { enqueuedDocument, availableDocument, failedDocument } =
    usePostmortem(incident);

  const { postmortemNameFormatted } = usePostmortemName(incident);

  const incidentHasPostmortemTemplate = !!postmortem_template_id;
  const postmortemSuccessfullyExported = !!availableDocument;
  const postmortemFailedExport = !!failedDocument;
  const isCreating = !!enqueuedDocument;

  if (isCreating) {
    return (
      <Tooltip content="Creating...">
        <Button
          analyticsTrackingId={"postmortem-export"}
          size={BadgeSize.Medium}
          disabled
        >
          <Icon
            id={IconEnum.Loader}
            className="animate-spin"
            size={IconSize.Small}
          />
        </Button>
      </Tooltip>
    );
  }

  // If postmortem has been successfully exported, show the status and overflow.
  if (postmortemSuccessfullyExported) {
    return (
      <>
        <PostmortemViolations incident={incident} />
        <StatusDropdown incident={incident} />
        <ExportedPostmortemOverflow incident={incident} />
      </>
    );
  }

  // If the postmortem failed to export, show the failure message with the ability to reexport.
  if (postmortemFailedExport) {
    return (
      <>
        <PostmortemViolations incident={incident} />
        <RetryButton
          incident={incident}
          failedDocument={failedDocument}
          isExporting={isCreating}
          postmortemNameFormatted={postmortemNameFormatted}
        />
      </>
    );
  }

  // If the postmortem was written within the app, show the status, export button and overflow.
  if (incidentHasPostmortemTemplate) {
    return (
      <>
        <PostmortemViolations incident={incident} />
        <StatusDropdown incident={incident} />
        <InHousePostmortemOverflow
          incident={incident}
          templates={templates}
          isExporting={isCreating}
          setShowExportDrawer={onCreatePostmortemClick}
        />
      </>
    );
  }

  // Otherwise just show a "Create postmortem" button.
  return (
    <>
      <PostmortemViolations incident={incident} />
      <Button
        analyticsTrackingId={"create-post-mortem"}
        icon={IconEnum.FilePlus}
        size={BadgeSize.Medium}
        onClick={onCreatePostmortemClick}
      >
        Create {postmortemNameFormatted}
      </Button>
    </>
  );
};

type ExportButtonProps = {
  isExporting: boolean;
  setShowExportDrawer: (show: boolean) => void;
  failedDocument?: PostmortemDocument;
  mode: "button" | "dropdown";
  postmortemNameFormatted: string;
};

const ExportButton = ({
  isExporting,
  setShowExportDrawer,
  failedDocument,
  mode,
  postmortemNameFormatted,
}: ExportButtonProps) => {
  let tooltipContent: string | React.ReactNode = undefined;
  let documentProviderName: string | undefined;
  if (failedDocument) {
    tooltipContent = `Failed to export, click to retry`;
    const { providerName } = getDocumentProvider(failedDocument);
    documentProviderName = providerName;
  }

  if (failedDocument?.human_readable_error) {
    tooltipContent = (
      <div className="gap-x-2">
        <p className="mb-2">
          {`There was an error whilst creating the ${postmortemNameFormatted}`}
        </p>
        <Markdown unstyled>{failedDocument?.human_readable_error}</Markdown>
        {failedDocument?.response_body && (
          <>
            <p className="mb-1">
              Latest error returned from {documentProviderName}:
            </p>
            <code className="whitespace-pre-wrap">
              {failedDocument?.response_body
                .replaceAll("\\n", "\n")
                .replaceAll('\\"', "'")}
            </code>
          </>
        )}
      </div>
    );
  }

  if (mode === "dropdown") {
    return (
      <DropdownMenuItem
        analyticsTrackingId={"postmortem-export"}
        tooltipContent={tooltipContent}
        onSelect={() => setShowExportDrawer(true)}
        label={isExporting ? "Exporting..." : "Export"}
        icon={failedDocument ? IconEnum.Warning : IconEnum.Export}
        disabled={!!isExporting}
      />
    );
  }

  return (
    <Tooltip content={tooltipContent}>
      <Button
        analyticsTrackingId={"postmortem-export"}
        icon={IconEnum.Export}
        size={BadgeSize.Medium}
        onClick={() => setShowExportDrawer(true)}
        disabled={!!isExporting}
      >
        {isExporting ? "Exporting..." : "Export"}
        {failedDocument && (
          <Icon
            id={IconEnum.Warning}
            size={IconSize.Small}
            className="text-red-content"
          />
        )}
      </Button>
    </Tooltip>
  );
};

type RetryButtonProps = {
  failedDocument?: PostmortemDocument;
  postmortemNameFormatted: string;
  incident: Incident;
  isExporting: boolean;
};

const RetryButton = ({
  incident,
  failedDocument,
  isExporting,
  postmortemNameFormatted,
}: RetryButtonProps) => {
  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );

  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  const { trigger: retryDocument, isMutating: retrying } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.postmortemsExportDocument({
        exportDocumentRequestBody: {
          incident_id: incident.id,
          postmortem_document_id: id,
        },
      });
      await refetchPostIncidentFlowTasks();
      await refetchIncident();
    },
  );

  let tooltipContent: string | React.ReactNode = undefined;
  let documentProviderName: string | undefined;
  if (failedDocument) {
    tooltipContent = `Failed to export, click to retry`;
    const { providerName } = getDocumentProvider(failedDocument);
    documentProviderName = providerName;
  }

  if (failedDocument?.human_readable_error) {
    tooltipContent = (
      <div className="gap-x-2">
        <p className="mb-2">
          {`There was an error whilst creating the ${postmortemNameFormatted}`}
        </p>
        <Markdown unstyled>{failedDocument?.human_readable_error}</Markdown>
        {failedDocument?.response_body && (
          <>
            <p className="mb-1">
              Latest error returned from {documentProviderName}:
            </p>
            <code className="whitespace-pre-wrap">
              {failedDocument?.response_body
                .replaceAll("\\n", "\n")
                .replaceAll('\\"', "'")}
            </code>
          </>
        )}
      </div>
    );
  }

  const inProgress = isExporting || retrying;

  return (
    <Tooltip content={tooltipContent}>
      <Button
        analyticsTrackingId={"postmortem-export"}
        icon={IconEnum.Refresh}
        size={BadgeSize.Medium}
        disabled={inProgress}
        onClick={() =>
          failedDocument && retryDocument({ id: failedDocument.id })
        }
      >
        {inProgress ? "Exporting..." : "Retry export"}
        {failedDocument && (
          <Icon
            id={IconEnum.Warning}
            size={IconSize.Small}
            className="text-red-content"
          />
        )}
      </Button>
    </Tooltip>
  );
};

const InHousePostmortemOverflow = ({
  incident,
  templates,
  isExporting,
  setShowExportDrawer,
}: {
  incident: Incident;
  templates: PostmortemTemplate[];
  isExporting: boolean;
  setShowExportDrawer: (show: boolean) => void;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(incident);
  const showToast = useToast();
  const { trigger: onUpdateTemplate, isMutating: savingTemplate } =
    useAPIMutation(
      "incidentsShow",
      { id: incident?.id ?? "" },
      async (apiClient, { postmortem_template_id }) => {
        await apiClient.postmortemsUpdateTemplateForIncident({
          incidentId: incident?.id ?? "",
          postmortemTemplateId: postmortem_template_id,
        });
      },
      {
        onSuccess: () => {
          showToast({
            theme: ToastTheme.Success,
            title: `We'll use this ${postmortemNameFormatted} template for the incident going forward.`,
            toastSide: ToastSideEnum.TopRight,
          });
        },
      },
    );

  const setTemplate = async (templateId: string) => {
    await onUpdateTemplate({ postmortem_template_id: templateId });
  };

  const templateOptions: SelectOption[] = templates.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  return (
    <DropdownMenu
      align="end"
      triggerButton={
        <Button
          size={BadgeSize.Medium}
          icon={IconEnum.DotsVertical}
          iconProps={{
            size: IconSize.Large,
          }}
          analyticsTrackingId={null}
          title="More"
        />
      }
    >
      <DropdownMenuSubItem
        trigger="Change template"
        icon={IconEnum.Doc}
        disabled={savingTemplate}
      >
        {templateOptions.map((option) => (
          <DropdownMenuItem
            key={option.value}
            analyticsTrackingId={null}
            label={option.label}
            onSelect={() => setTemplate(option.value)}
            disabled={option.value === incident.postmortem_template_id}
          />
        ))}
      </DropdownMenuSubItem>
      <ExportButton
        mode="dropdown"
        isExporting={isExporting}
        setShowExportDrawer={setShowExportDrawer}
        postmortemNameFormatted={postmortemNameFormatted}
      />
    </DropdownMenu>
  );
};

const ExportedPostmortemOverflow = ({
  incident,
}: {
  incident: Incident;
}): React.ReactElement => {
  const navigateToModal = useNavigateToModal();

  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );

  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  const {
    trigger: destroyDocument,
    isMutating: destroying,
    genericError: destroyError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.postmortemsDestroyDocument({ id });
      await refetchPostIncidentFlowTasks();
      await refetchIncident();
    },
  );

  const { availableDocument: document } = usePostmortem(incident);
  if (!document) {
    // This overflow should only be shown when a document has been exported.
    return <></>;
  }

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <>
      <DropdownMenu
        align="end"
        triggerButton={
          <Button
            size={BadgeSize.Medium}
            icon={IconEnum.DotsVertical}
            iconProps={{
              size: IconSize.Large,
            }}
            analyticsTrackingId={null}
            title="More"
          />
        }
      >
        {!destroying && (
          <DropdownMenuItem
            onSelect={() => destroyDocument({ id: document.id })}
            analyticsTrackingId={"delete-post-mortem"}
            label={"Unlink doc"}
            icon={IconEnum.LinkBreak}
          />
        )}
        <DropdownMenuItem
          onSelect={() => navigateToModal(IncidentHeaderModal.SharePostmortem)}
          analyticsTrackingId={"share-post-mortem"}
          label="Share"
          icon={IconEnum.Share}
        />
      </DropdownMenu>
    </>
  );
};

type StatusDropdownProps = {
  incident: Incident;
};

const StatusDropdown = ({ incident }: StatusDropdownProps) => {
  const { trigger: onUpdateStatus, isMutating: savingStatus } = useAPIMutation(
    "incidentsShow",
    { id: incident?.id ?? "" },
    async (apiClient, { status }) => {
      await apiClient.postmortemsUpdateStatus({
        updateStatusRequestBody: {
          incident_id: incident?.id ?? "",
          status: status,
        },
      });
    },
  );

  const statusOptions: SelectOption[] = Object.values(
    IncidentPostmortemStatusEnum,
  )
    .filter((status) => status !== IncidentPostmortemStatusEnum.NotStarted)
    .map((status) => ({
      label: StatusProps[status].label,
      value: status,
    }));

  const currentStatusProps = StatusProps[incident.postmortem_status];
  const currentStatusColors = getColorPalette(currentStatusProps.colors);

  const setPostmortemStatus = async (status: string) => {
    await onUpdateStatus({ status });
  };

  return (
    <DropdownMenu
      align="start"
      disabled={savingStatus}
      triggerButton={
        <Button
          theme={ButtonTheme.Ghost}
          analyticsTrackingId={"postmortem-change-status"}
          icon={IconEnum.Circle}
          className={tcx(
            currentStatusColors.background,
            currentStatusColors.hoverBg,
            currentStatusColors.activeBg,
            "text-content-primary",
          )}
          iconProps={{
            size: IconSize.XS,
            className: tcx("w-2 h-2", currentStatusColors.text),
          }}
          iconPosition={"left"}
          size={BadgeSize.Medium}
        >
          {currentStatusProps.label}
        </Button>
      }
    >
      {statusOptions.map((option) => (
        <DropdownMenuItem
          key={option.value}
          analyticsTrackingId={null}
          label={option.label}
          onSelect={() => setPostmortemStatus(option.value)}
          disabled={option.value === incident.postmortem_status}
        />
      ))}
    </DropdownMenu>
  );
};

type PostmortemLinkProps = {
  incident: Incident;
};

const PostmortemLink = ({ incident }: PostmortemLinkProps) => {
  const { availableDocument: document } = usePostmortem(incident);

  if (!document) {
    return null;
  }

  let documentProviderName: string | undefined;
  let icon: IconEnum | undefined;
  if (document) {
    const { providerName, providerIcon } = getDocumentProvider(document);
    [documentProviderName, icon] = [providerName, providerIcon];
  }

  return (
    <div className="w-full flex flex-row items-center gap-4">
      <div className="flex flex-col overflow-hidden justify-center">
        <div className="text-xs-bold text-content-primary">
          {incident.reference}: {incident.name}
        </div>
        <div className="text-xs-med text-content-tertiary truncate">
          Created at{" "}
          {formatTimestampLocale({
            timestamp: document.created_at,
            dateStyle: "medium",
            timeStyle: "short",
            addWeekday: true,
          })}
        </div>
      </div>
      <div className="flex ml-auto items-center justify-end shrink-0">
        <Button
          analyticsTrackingId={"open-external-pm"}
          size={BadgeSize.Medium}
          href={document.permalink}
          icon={icon}
          openInNewTab
        >
          Open {documentProviderName ? `in ${documentProviderName}` : ""}
        </Button>
      </div>
    </div>
  );
};

const PostmortemViolations = ({ incident }: { incident: Incident }) => {
  const { postmortemNameFormatted } = usePostmortemName(incident);

  const { policies, policyViolations } = usePoliciesAndViolations(incident.id);

  const policyViolation =
    policyViolations &&
    policyViolations.find(
      (violation) =>
        violation.resource_id === incident.id &&
        violation.policy_type === PolicyViolationPolicyTypeEnum.PostMortem,
    );

  const violatedPolicy =
    policyViolation &&
    policies?.find((policy) => policy.id === policyViolation.policy_id);

  if (!violatedPolicy) {
    return null;
  }

  return (
    <PolicyViolationNotification
      policy={violatedPolicy}
      resourceName={postmortemNameFormatted}
      level={policyViolation.level}
      violationID={policyViolation.id}
    >
      <div>
        <PostmortemViolationBadge level={policyViolation.level} />
      </div>
    </PolicyViolationNotification>
  );
};

const PostmortemViolationBadge = ({
  level,
}: {
  level: PolicyViolationLevelEnum;
}) => {
  if (level === PolicyViolationLevelEnum.Warning) {
    return (
      <Badge
        size={BadgeSize.Medium}
        theme={BadgeTheme.Warning}
        icon={IconEnum.Warning}
      >
        Policy warning
      </Badge>
    );
  }

  return (
    <Badge
      size={BadgeSize.Medium}
      theme={BadgeTheme.Error}
      icon={IconEnum.Warning}
    >
      Policy violations
    </Badge>
  );
};
