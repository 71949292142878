import { StaffShowOrganisationResponseBody } from "@incident-io/api";
import {
  Button,
  GenericErrorMessage,
  LoadingBar,
  Tab,
  TabPane,
  TabSection,
} from "@incident-ui";
import { useNavigate, useParams } from "react-router";
import { DisableOnCallButton } from "src/components/staff-room/DisableOnCallButton";
import { useAPI } from "src/utils/swr";

import { ArchiveButton } from "./ArchiveButton";
import { BlockArchivingButton } from "./BlockArchivingButton";
import { Controls } from "./Controls";
import { DisableButton } from "./DisableButton";
import { DisableResponseButton } from "./DisableResponseButton";
import { KeyInformation } from "./KeyInformation";
import { RenameOrganisation } from "./RenameOrganisation";

export const BillingStaffOrgPage = () => {
  const { targetSlug } = useParams();
  if (!targetSlug) {
    throw new Error("No organisation slug provided");
  }

  const { data, error } = useAPI("staffShowOrganisation", {
    organisationSlug: targetSlug,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (!data) {
    return <LoadingBar className="h-16" />;
  }

  return (
    <>
      <Header data={data} />

      <TabSection tabs={tabs} defaultTab="key-information" withIndicator>
        <TabPane tabId="key-information" className="space-y-4">
          <KeyInformation data={data} />
        </TabPane>

        <TabPane tabId="controls" className="space-y-4">
          <Controls data={data} />
        </TabPane>

        <TabPane tabId="danger-zone" className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 bg-surface-secondary p-4 rounded-2">
            <div className="text-base-bold"> Danger danger</div>
            <div className="flex flex-wrap gap-2">
              <DisableButton data={data} />
              <ArchiveButton data={data} />
              <DisableOnCallButton data={data} />
              <DisableResponseButton data={data} />
            </div>
          </div>
          <div className="flex flex-col gap-2 bg-surface-secondary p-4 rounded-2">
            <div className="text-base-bold"> Auto-archiving</div>
            <div>{`Organisations are automatically archived after 1 year of inactivity, or 60 days if they never create an incident or ack an escalation.`}</div>
            {data.archive_info?.dont_warn_until && (
              <div>
                This org will not be archived until after{" "}
                {new Date(
                  data.archive_info.dont_warn_until,
                ).toLocaleDateString()}
              </div>
            )}
            {data.archive_info?.pre_archive_warning_sent_at && (
              <div>
                A pre-archive warning was sent at{" "}
                {new Date(
                  data.archive_info.pre_archive_warning_sent_at,
                ).toLocaleString()}
              </div>
            )}
            <BlockArchivingButton data={data} />
          </div>
        </TabPane>
      </TabSection>
    </>
  );
};

const tabs: Tab[] = [
  {
    label: "Key information",
    id: "key-information",
  },
  {
    label: "Controls",
    id: "controls",
  },
  {
    label: "Danger zone",
    id: "danger-zone",
  },
];

const Header = ({ data }: { data: StaffShowOrganisationResponseBody }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/${data.organisation_slug}/dashboard`);
  };

  return (
    <div className="flex gap-2">
      <RenameOrganisation data={data} />
      <Button onClick={handleOnClick} analyticsTrackingId={null}>
        🫣 Impersonate
      </Button>
    </div>
  );
};
