import { EngineScope, Reference } from "@incident-io/api";
import { cloneDeep } from "lodash";

/**
EnrichedScopes are scopes where we have "enriched" the references with some
additional information (eg attaching the relevant resource).

EnrichedScope<Reference> is exactly equal to
EngineScope.
*/
export interface EnrichedScope<TRefType extends Reference>
  extends Omit<EngineScope, "references"> {
  references: Array<TRefType>;
}

function dealias(path: string, aliases: { [key: string]: string }) {
  // aliases contain the whole path up to the aliased key.
  // if we have an alias incident.foo -> incident.bar, and we're trying to
  // dealias incident.foo.baz, we need to check whether our path _starts with_
  // any of our aliases, and replace that section with dealised one.
  for (const key in aliases) {
    if (path.startsWith(key)) {
      // replace() only replaces the first occurrence, and we know the path
      // starts with our key, so we're guaranteed to splat the right bit of the
      // string here.
      return path.replace(key, aliases[key]);
    }
  }
  return path;
}

/** lookupInScope returns the ref in a scope whose key matches the search string */
export function lookupInScope<T extends Reference = Reference>(
  scope: EnrichedScope<T>,
  key: string,
): T | undefined {
  if (!key) {
    return undefined;
  }
  let result = scope.references.find((ref) => ref.key === key);
  // If we don't find the reference in our scope, attempt to dealias it and look
  // again
  if (!result) {
    result = scope.references.find(
      (ref) => ref.key === dealias(key, scope.aliases),
    );
  }

  return result;
}

/** lookupInScopeByType returns the ref in a scope whose type matches the search string */
export function lookupInScopeByType<T extends Reference = Reference>(
  scope: EnrichedScope<T>,
  type: string,
) {
  return scope.references.find((x) => x.type === type);
}

/** FindIndexInScope returns the index of the ref in a scope whose key matches the search string */
export function findIndexInScope<T extends Reference = Reference>(
  scope: EnrichedScope<T>,
  key: string,
): number {
  let result = scope.references.findIndex((ref) => ref.key === key);
  // If we don't find the reference in our scope, attempt to dealias it and look
  // again
  if (!result) {
    result = scope.references.findIndex(
      (ref) => ref.key === dealias(key, scope.aliases),
    );
  }

  return result;
}

/**
getScopeChildrenByParent constructs a lookup of {parentKey: children[]} for a
given scope's references
*/
export function getScopeChildrenByParent(
  scope: EngineScope,
): Record<string, string[]> {
  const childrenByParent = {};

  scope.references.forEach((x: Reference) => {
    if (x.parent) {
      if (!childrenByParent[x.parent]) {
        childrenByParent[x.parent] = [];
      }
      childrenByParent[x.parent].push(x.key);
    }
  });

  return childrenByParent;
}

/**
mergeScopes creates a new scope whose references and aliases are the original's
concatenated with the additional provided refs
*/
export function mergeScopes<T extends Reference = Reference>(
  scope: EnrichedScope<T>,
  additionalScope: EnrichedScope<T>,
): EnrichedScope<T> {
  const newScope = cloneDeep(scope);
  newScope.references = scope.references.concat(additionalScope.references);
  newScope.aliases = { ...scope.aliases, ...additionalScope.aliases };
  return newScope;
}

/**
filterScope creates a new scope whose references are the originals, filtered
using the provided callback
*/
export function filterScope<T extends Reference = Reference>(
  scope: EnrichedScope<T>,
  filterFn: (ref: T, i: number, arr: T[]) => boolean,
): EnrichedScope<T> {
  const newScope = cloneDeep(scope);
  newScope.references = newScope.references.filter(filterFn);
  return newScope;
}

/**
enrichScope creates a new scope whose references have been enriched by the
provided map function. Use this to add extra information (eg an example, or a
resource) to the references.

Its type parameters are the opposite to the order you might expect because
the original ref type is optional, so must come second.
*/
export function enrichScope<
  TEnrichedRefType extends TOriginalRefType,
  TOriginalRefType extends Reference = Reference,
>(
  scope: EnrichedScope<TOriginalRefType>,
  enrichFn: (
    ref: TOriginalRefType,
    i: number,
    arr: TOriginalRefType[],
  ) => TEnrichedRefType,
): EnrichedScope<TEnrichedRefType> {
  const newScope = cloneDeep(scope);
  newScope.references = newScope.references.map(enrichFn);
  return newScope as EnrichedScope<TEnrichedRefType>;
}

export function scopeFrom<T extends Reference = Reference>(
  refs: T[],
): EnrichedScope<T> {
  return {
    references: refs,
    aliases: {},
  };
}

export function getEmptyScope<
  T extends Reference = Reference,
>(): EnrichedScope<T> {
  return {
    references: [],
    aliases: {},
  };
}

/** omitScopeReference filters any references that match the provided key out of the scope */
export function omitScopeReference<T extends Reference = Reference>(
  scope: EnrichedScope<T>,
  filterKey: string,
): EnrichedScope<T> {
  const newScope = cloneDeep(scope);
  const newRefs = newScope.references
    // Make sure we filter both the raw and dealiased versions of our key
    .filter((x) => x.key !== filterKey)
    .filter((x) => x.key !== dealias(filterKey, scope.aliases));
  newScope.references = newRefs;
  return newScope;
}
