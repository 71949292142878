import { Link } from "@incident-ui";
import React from "react";
import { CommsPlatform } from "src/hooks/usePrimaryCommsPlatform";

import { Product } from "../types";

/**
 * This component does not include any logic to decide when it should not be
 * shown, because the parent component should be in charge of determining
 * whether this message needs to be shown or not.
 */
export const ProductRequiredMessage = ({
  requiredProduct,
  commsPlatform,
}: {
  requiredProduct: Product;
  commsPlatform?: CommsPlatform;
}): React.ReactElement => {
  const productLabel =
    requiredProduct === Product.OnCall ? "On-call" : "Response";

  const link =
    requiredProduct === Product.OnCall
      ? "https://incident.io/on-call"
      : commsPlatform === CommsPlatform.Slack
      ? "https://incident.io/respond-slack"
      : "https://incident.io/respond-teams";

  return (
    <div>
      <span className="text-xs-med">Available with {productLabel}.</span>{" "}
      <Link
        to={link}
        analyticsTrackingId="product-required-learn-more"
        openInNewTab
        className="text-xs-med text-alarmalade-500 !no-underline"
      >
        Learn more
      </Link>
    </div>
  );
};
