import { Trigger } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { BadgeSize, Button, ButtonTheme, IconEnum, Loader } from "@incident-ui";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import {
  Popover,
  PopoverBody,
  PopoverClose,
} from "@incident-ui/Popover/Popover";
import { useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";

import { WorkflowFormData } from "../common/types";
import { useWorkflowsTrigger } from "../create-edit-form/hooks/useTriggerController";
import { WorkflowActiveTriggerCard } from "./WorkflowActiveTriggerCard";

export const WorkflowsTriggerSelector = ({
  trigger,
  isDraft,
  onClickEditTrigger,
  onClickChooseTrigger,
  triggerLoading,
}: {
  triggerLoading: boolean;
  trigger?: Trigger;
  isDraft: boolean;
  onClickEditTrigger: () => void;
  onClickChooseTrigger: () => void;
}) => {
  const { isManualTrigger } = useWorkflowsTrigger();
  const { identity } = useIdentity();

  const isSlackOrg = identity.slack_info != null;

  return (
    <FlowCards.Card
      title="Workflow is triggered when..."
      icon={IconEnum.Bolt}
      isHighlighted={!trigger}
    >
      {triggerLoading ? (
        <Loader />
      ) : trigger ? (
        <WorkflowActiveTriggerCard
          trigger={trigger}
          iconName={trigger.icon as unknown as IconEnum}
          deleteButton={
            isDraft ? (
              <Button
                onClick={() => {
                  onClickEditTrigger();
                }}
                icon={IconEnum.Close}
                iconProps={{ className: "!w-5 !h-5" }}
                theme={ButtonTheme.Naked}
                title="delete-trigger"
                analyticsTrackingId="workflows-v2-delete-trigger-button"
              />
            ) : undefined
          }
        />
      ) : (
        <Button
          onClick={onClickChooseTrigger}
          analyticsTrackingId={"workflows-v2-choose-trigger-button"}
          icon={IconEnum.FastForward}
        >
          Choose trigger
        </Button>
      )}
      {isManualTrigger && isSlackOrg && <WorkflowShortformSelector />}
    </FlowCards.Card>
  );
};

const WorkflowShortformSelector = () => {
  const formMethods = useFormContext<WorkflowFormData>();

  const [shortform] = formMethods.watch(["shortform"]);

  return (
    <div className="flex items-center gap-2">
      <div className="font-mono">/inc workflow</div>
      <Popover
        align="start"
        sideOffset={-33}
        trigger={
          <Button
            analyticsTrackingId="workflows-add-shortcut"
            theme={ButtonTheme.Naked}
            size={BadgeSize.Medium}
          >
            {shortform ? (
              <div className="font-mono">{shortform}</div>
            ) : (
              <div className="font-mono underline">Add shortcut</div>
            )}
          </Button>
        }
      >
        <PopoverBody className="p-4 flex flex-row items-center gap-2">
          <InputV2 formMethods={formMethods} name="shortform" />
          <PopoverClose>
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Primary}
              size={BadgeSize.Medium}
              type="button"
              title=""
              icon={IconEnum.Tick}
            />
          </PopoverClose>
        </PopoverBody>
      </Popover>
      <Form.ErrorMessage
        errors={formMethods.formState.errors}
        name="shortform"
      />
    </div>
  );
};
