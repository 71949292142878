import type { SVGProps } from "react";
import * as React from "react";
const SvgGitBranch = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14 5.75a.75.75 0 0 0-.75.75v1c0 .689-.561 1.25-1.25 1.25H7.5c-.452 0-.873.12-1.25.314V6.5a.75.75 0 0 0-1.5 0V13a.75.75 0 0 0 1.5 0v-1.5c0-.689.561-1.25 1.25-1.25H12a2.752 2.752 0 0 0 2.75-2.75v-1a.75.75 0 0 0-.75-.75Z"
    />
    <path
      fill="currentColor"
      d="M5.5 7a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM14 7a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM5.5 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
    />
  </svg>
);
export default SvgGitBranch;
