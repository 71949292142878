import { CatalogTypeCategoriesEnum } from "@incident-io/api";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { Navigate, Route, Routes } from "react-router";

import { CatalogWizardAddAttributesStep } from "../CatalogWizardAddAttributesStep";
import { useHasTypeOfCategory } from "../useHasTypeOfCategory";
import { TeamWizardChooseSourceOfTruthStep } from "./TeamWizardChooseSourceOfTruthStep";
import { TeamWizardChooseTeamsStep } from "./TeamWizardChooseTeamsStep";
import { TeamWizardManualTeamsStep } from "./TeamWizardManualTeamsStep";

export const TeamWizardRoutes = () => {
  return (
    <PageWrapper
      width={PageWidth.Full}
      crumbs={[{ title: "Catalog", to: "/catalog" }]}
      title="Set up your teams"
      icon={IconEnum.Users}
      color={ColorPaletteEnum.Violet}
      backHref="/catalog"
      overflowY={false}
    >
      <Routes>
        <Route path="/" element={<TeamWizardHomeRoute />} />
        <Route
          path="choose-source-of-truth/*"
          element={<TeamWizardChooseSourceOfTruthStep />}
        />
        <Route path="choose-teams/*" element={<TeamWizardChooseTeamsStep />} />
        <Route path="manual-teams/*" element={<TeamWizardManualTeamsStep />} />
        <Route
          path=":typeId/add-attributes/*"
          element={
            <CatalogWizardAddAttributesStep
              category={CatalogTypeCategoriesEnum.Team}
              resourceTypesToDisplayAsButtons={[
                "SlackChannel",
                "SlackUserGroup",
                "GithubTeam",
                "JiraCloudProject",
              ]}
            />
          }
        />
      </Routes>
    </PageWrapper>
  );
};

const TeamWizardHomeRoute = () => {
  const { matchingType, typesLoading, typesError } = useHasTypeOfCategory(
    CatalogTypeCategoriesEnum.Team,
  );

  if (typesLoading) {
    return <FullPageLoader />;
  }
  if (typesError) {
    return <GenericErrorMessage />;
  }

  if (matchingType) {
    return <Navigate to={`${matchingType.id}/add-attributes`} />;
  }

  return <Navigate to="choose-source-of-truth" />;
};
