import type { SVGProps } from "react";
import * as React from "react";
const SvgQuestionMark = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm-.209 12.567a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2Zm1.529-4.352c-.457.319-.68.491-.755.915a.749.749 0 1 1-1.476-.26c.186-1.057.872-1.536 1.374-1.886.527-.368.71-.522.71-1.049 0-.837-.698-1.058-1.067-1.058-.414 0-1.157.13-1.477 1a.75.75 0 1 1-1.408-.516c.456-1.242 1.534-1.984 2.885-1.984 1.244 0 2.567.896 2.567 2.558 0 1.337-.781 1.882-1.353 2.28Z"
    />
  </svg>
);
export default SvgQuestionMark;
