/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PanelVariableValue
 */
export interface PanelVariableValue {
    /**
     * Image URL of the variable (if applicable)
     * @type {string}
     * @memberof PanelVariableValue
     */
    image_url?: string;
    /**
     * Label of the variable
     * @type {string}
     * @memberof PanelVariableValue
     */
    label: string;
    /**
     * Value of the variable
     * @type {string}
     * @memberof PanelVariableValue
     */
    value: string;
}

export function PanelVariableValueFromJSON(json: any): PanelVariableValue {
    return PanelVariableValueFromJSONTyped(json, false);
}

export function PanelVariableValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelVariableValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image_url': !exists(json, 'image_url') ? undefined : json['image_url'],
        'label': json['label'],
        'value': json['value'],
    };
}

export function PanelVariableValueToJSON(value?: PanelVariableValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_url': value.image_url,
        'label': value.label,
        'value': value.value,
    };
}

