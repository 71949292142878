/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulesUnpublishReportRequestBody
 */
export interface SchedulesUnpublishReportRequestBody {
    /**
     * If unpublished, the reason why
     * @type {string}
     * @memberof SchedulesUnpublishReportRequestBody
     */
    unpublish_reason: string;
}

export function SchedulesUnpublishReportRequestBodyFromJSON(json: any): SchedulesUnpublishReportRequestBody {
    return SchedulesUnpublishReportRequestBodyFromJSONTyped(json, false);
}

export function SchedulesUnpublishReportRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesUnpublishReportRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unpublish_reason': json['unpublish_reason'],
    };
}

export function SchedulesUnpublishReportRequestBodyToJSON(value?: SchedulesUnpublishReportRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unpublish_reason': value.unpublish_reason,
    };
}

