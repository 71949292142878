import { ManagedResourceResourceTypeEnum as ResourceTypeEnum } from "@incident-io/api";
import { DisconnectTerraformModal } from "@incident-shared/management-meta/DisconnectTerraformModal";
import { SourceURLForm } from "@incident-shared/management-meta/SourceURLForm";
import { SourceURLLink } from "@incident-shared/management-meta/SourceURLLink";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  LoadingWrapper,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { useWorkflowsMutations } from "../hooks/useMutationController";
import { useWorkflowsDeps } from "../WorkflowsFormContext";

export const WorkflowsCreateEditBanners = (): React.ReactElement => {
  const { workflow, management, canEdit, isDraft, isMainFormDirty } =
    useWorkflowsDeps();

  const {
    enableWorkflow,
    state: { isEnabling, isDisabling },
  } = useWorkflowsMutations();

  const [showEditURLForm, setShowEditURLForm] = React.useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = React.useState(false);

  const disabledBannerIsLoading = isEnabling || isDisabling;
  const showDisabledBanner =
    (!isDraft && workflow?.disabled_at) || disabledBannerIsLoading;

  const showTerraformBanner = management.managed_by === "terraform";

  return (
    <>
      {/* View only, if applicable */}
      {!canEdit && (
        <WorkflowBanner palette={ColorPaletteEnum.Yellow}>
          <div className="flex flex-row gap-1 text-sm">
            <Icon id={IconEnum.Warning} className="shrink-0" />
            <span className="text-black">
              You do not have permission to edit this workflow. Any changes will
              not be saved.
            </span>
          </div>
        </WorkflowBanner>
      )}

      {/* Disabled state, if applicable */}
      {showDisabledBanner && (
        <WorkflowBanner
          palette={ColorPaletteEnum.Red}
          loading={disabledBannerIsLoading}
        >
          <div className="flex flex-row gap-1 text-sm">
            <Icon id={IconEnum.Exclamation} className="shrink-0" />
            <span className="text-black">
              This workflow is currently disabled and will not run
            </span>
          </div>
          <Tooltip
            content={
              isMainFormDirty ? "Save your changes before enabling" : undefined
            }
          >
            <Button
              theme={ButtonTheme.Naked}
              onClick={enableWorkflow}
              analyticsTrackingId="workflows-v2-enable-workflow-button"
              className={tcx(isMainFormDirty && "opacity-50")}
              disabled={isMainFormDirty || disabledBannerIsLoading}
            >
              Enable
            </Button>
          </Tooltip>
        </WorkflowBanner>
      )}

      {showTerraformBanner && (
        <WorkflowBanner palette={ColorPaletteEnum.Violet}>
          <div className="flex flex-row gap-1 text-sm min-w-[0px]">
            <Icon
              id={IconEnum.Terraform}
              className="shrink-0 !text-terraform-purple-500"
            />
            <div className="flex flex-col gap-1 min-w-[0px]">
              <span className="text-black">
                This workflow is managed in Terraform. Any changes performed
                here must be exported and applied via Terraform.
              </span>
              {showEditURLForm ? (
                <SourceURLForm
                  resourceType={ResourceTypeEnum.Workflow}
                  resourceID={workflow?.id || ""}
                  managementMeta={management}
                  onSourceURLChange={() => setShowEditURLForm(false)}
                  noText
                />
              ) : management.source_url ? (
                <SourceURLLink
                  sourceURL={management.source_url}
                  beginEdit={() => setShowEditURLForm(true)}
                />
              ) : (
                <Button
                  theme={ButtonTheme.Unstyled}
                  analyticsTrackingId={"workflow-terraform-add-source-url"}
                  className="text-xs text-terraform-purple-500 font-medium"
                  onClick={() => setShowEditURLForm(true)}
                >
                  Add source URL
                </Button>
              )}
              {!showEditURLForm && (
                <Button
                  theme={ButtonTheme.Unstyled}
                  analyticsTrackingId={"workflow-terraform-disconnect"}
                  className="text-xs text-terraform-purple-500 !flex font-medium"
                  onClick={() => setShowDisconnectModal(true)}
                >
                  Manage in the dashboard
                </Button>
              )}
            </div>
          </div>
        </WorkflowBanner>
      )}

      {showDisconnectModal && (
        <DisconnectTerraformModal
          onClose={() => {
            setShowDisconnectModal(false);
          }}
          resourceType={ResourceTypeEnum.Workflow}
          resourceID={workflow?.id || ""}
          resourceName={workflow?.name || ""}
        />
      )}
    </>
  );
};

const WorkflowBanner = ({
  palette,
  loading = false,
  children,
}: {
  palette: ColorPaletteEnum;
  loading?: boolean;
  children: React.ReactNode;
}) => {
  const colors = getColorPalette(palette);

  return (
    <LoadingWrapper
      loading={loading}
      className={tcx(
        "p-4 border-[1px] !rounded-2 w-full mb-4 flex flex-row justify-between items-center",
        colors.background,
        colors.border,
        colors.text,
      )}
      loaderClassName="left-0 !rounded-2"
    >
      {children}
    </LoadingWrapper>
  );
};
