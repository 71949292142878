import {
  DynamicMultiSelectWithObj,
  DynamicMultiSelectWithObjProps,
} from "@incident-ui/Select/DynamicMultiSelect";
import {
  DynamicSingleSelectWithObj,
  DynamicSingleSelectWithObjProps,
} from "@incident-ui/Select/DynamicSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import { FieldValues, Path, useController, useWatch } from "react-hook-form";
import { MultiValue, SingleValue } from "react-select";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

type TMultiInputType = Omit<DynamicMultiSelectWithObjProps, "isDisabled"> & {
  disabled?: boolean;
};

export const DynamicMultiSelectWithObjV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TMultiInputType> & {
    onValueChange?: (val: MultiValue<SelectOption>) => void;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    TMultiInputType
  >(rest);

  const { field } = useController({
    name,
    rules,
  });

  const value = useWatch({ name, control: props.formMethods.control });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <DynamicMultiSelectWithObj
        id={name}
        {...field}
        value={value}
        {...inputProps}
        isDisabled={inputProps.disabled}
        onChange={(val: MultiValue<SelectOption>) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type TSingleInputType = Omit<DynamicSingleSelectWithObjProps, "isDisabled"> & {
  disabled?: boolean;
};

export const DynamicSingleSelectWithObjV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, TSingleInputType> & {
    onValueChange?: (val: SingleValue<SelectOption | null>) => void;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    TSingleInputType
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  // We're using the value from formMethods here because the value from
  // useController is wrong. This happens specifically when trying to set the
  // value of the field to null with setValue() - formMethods shows the field is
  // null, but useController shows its initial value, so the field is never
  // cleared. This works for now but we haven't understood why we're getting
  // conflicting values.
  const value = useWatch({ name, control: props.formMethods.control });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <DynamicSingleSelectWithObj
        id={name}
        innerRef={field.ref}
        onBlur={field.onBlur}
        value={value}
        isDisabled={inputProps.disabled}
        onChange={(val: SingleValue<SelectOption | null>) => {
          field.onChange(val);
          onValueChange && onValueChange(val);
        }}
        {...inputProps}
      />
    </FormInputWrapper>
  );
};
