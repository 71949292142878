import { tcx } from "src/utils/tailwind-classes";

type TxtProps = {
  className?: string;
  bold?: boolean;
  medium?: boolean;
  grey?: boolean;
  lightGrey?: boolean;
  inline?: boolean;
  greySmallCaps?: boolean;
  children: React.ReactNode;
  xs?: boolean;
};

/** @deprecated Txt is deprecated: we used to use it to make sure we rendered text in consistent ways across
// the app. We now use Tailwind classes directly for this purpose, to avoid the overhead of
// the extra layer of abstraction. See the 'Typography' docs in storybook for more details. */
export const Txt = ({
  className,
  bold,
  medium,
  grey,
  lightGrey,
  inline,
  children,
  greySmallCaps,
  xs,
}: TxtProps) => {
  const Tag = inline ? "span" : "div";
  let textColor = "text-content-primary";
  if (lightGrey) {
    textColor = "text-slate-600";
  } else if (grey) {
    textColor = "text-content-secondary";
  }

  let fontWeight = "font-normal";
  if (bold) {
    fontWeight = "font-semibold";
  } else if (medium) {
    fontWeight = "font-medium";
  }

  return (
    <Tag
      className={tcx(
        xs ? "text-xs" : "text-sm",
        "text-pretty",
        textColor,
        fontWeight,
        {
          "font-medium uppercase tracking-widest !text-content-tertiary":
            greySmallCaps,
        },
        className,
      )}
    >
      {children}
    </Tag>
  );
};
