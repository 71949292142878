/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface SlackPreviewsShowRequest {
    name: string;
    variant?: string;
}

/**
 * 
 */
export class SlackPreviewsApi extends runtime.BaseAPI {

    /**
     * List available Slack message previews
     * List Slack Previews
     */
    async slackPreviewsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/slack_previews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * List available Slack message previews
     * List Slack Previews
     */
    async slackPreviewsList(initOverrides?: RequestInit): Promise<string> {
        const response = await this.slackPreviewsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Shows a single template, redirecting to the BlockKit builder
     * Show Slack Previews
     */
    async slackPreviewsShowRaw(requestParameters: SlackPreviewsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling slackPreviewsShow.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.variant !== undefined) {
            queryParameters['variant'] = requestParameters.variant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/slack_previews/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Shows a single template, redirecting to the BlockKit builder
     * Show Slack Previews
     */
    async slackPreviewsShow(requestParameters: SlackPreviewsShowRequest, initOverrides?: RequestInit): Promise<void> {
        await this.slackPreviewsShowRaw(requestParameters, initOverrides);
    }

}
