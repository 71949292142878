import { AnnouncementRule } from "@incident-io/api";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import {
  BadgeSize,
  Button,
  Icon,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
} from "@incident-ui";

export const AnnouncementRuleDetails = ({
  rule,
  displayIcon = false,
  boxlessConditionGroupsList = true,
}: {
  rule: AnnouncementRule;
  displayIcon?: boolean;
  boxlessConditionGroupsList?: boolean;
}) => {
  return (
    <div className="space-y-2 bg-surface-primary">
      <div className="text-content-primary">
        Applies to
        {rule.condition_groups.length > 0 ? (
          <>
            <span>&nbsp;incidents where...</span>
            <div className="pt-2">
              <ConditionGroupsList
                groups={rule.condition_groups}
                boxless={boxlessConditionGroupsList}
              />
            </div>
          </>
        ) : (
          <span className="text-sm-med">&nbsp;all incidents</span>
        )}
      </div>
      <div className="flex flex-row gap-1 items-center flex-wrap">
        <div className="text-content-primary">Incidents announced in</div>
        <div className="flex flex-row gap-1">
          {rule.channels.map((channel, i) => (
            <Button
              key={channel.value}
              href={channel.url}
              openInNewTab
              size={BadgeSize.Medium}
              analyticsTrackingId={null}
            >
              <div className="flex items-center gap-1">
                {displayIcon && channel.icon_url && (
                  <SlackTeamAvatar
                    url={channel.icon_url}
                    size={IconSize.Small}
                  />
                )}
                {channel.is_private ? (
                  <Icon
                    id={IconEnum.LockClosed}
                    className="w-4 h-4 inline mb-0.5"
                  />
                ) : (
                  "#"
                )}
                {channel.label}
                {i < rule.channels.length - 1 ? ", " : ""}
              </div>
            </Button>
          ))}
        </div>
      </div>
      <div className="flex flex-row gap-1 items-center flex-wrap pt-0.5">
        <span className="text-content-tertiary text-sm">
          {rule.share_updates_to_channel
            ? "Share incident updates to channel and thread"
            : "Share incident updates to thread only"}
        </span>
      </div>
    </div>
  );
};
