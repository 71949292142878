/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManagementMeta,
    ManagementMetaFromJSON,
    ManagementMetaFromJSONTyped,
    ManagementMetaToJSON,
} from './ManagementMeta';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';
import {
    UserWithRoles,
    UserWithRolesFromJSON,
    UserWithRolesFromJSONTyped,
    UserWithRolesToJSON,
} from './UserWithRoles';

/**
 * 
 * @export
 * @interface SchedulesCreateResponseBody
 */
export interface SchedulesCreateResponseBody {
    /**
     * 
     * @type {ManagementMeta}
     * @memberof SchedulesCreateResponseBody
     */
    management_meta: ManagementMeta;
    /**
     * 
     * @type {Schedule}
     * @memberof SchedulesCreateResponseBody
     */
    schedule: Schedule;
    /**
     * Users that are part of this schedule
     * @type {Array<UserWithRoles>}
     * @memberof SchedulesCreateResponseBody
     */
    users: Array<UserWithRoles>;
}

export function SchedulesCreateResponseBodyFromJSON(json: any): SchedulesCreateResponseBody {
    return SchedulesCreateResponseBodyFromJSONTyped(json, false);
}

export function SchedulesCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'management_meta': ManagementMetaFromJSON(json['management_meta']),
        'schedule': ScheduleFromJSON(json['schedule']),
        'users': ((json['users'] as Array<any>).map(UserWithRolesFromJSON)),
    };
}

export function SchedulesCreateResponseBodyToJSON(value?: SchedulesCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'management_meta': ManagementMetaToJSON(value.management_meta),
        'schedule': ScheduleToJSON(value.schedule),
        'users': ((value.users as Array<any>).map(UserWithRolesToJSON)),
    };
}

