/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface ExpressionBranch
 */
export interface ExpressionBranch {
    /**
     * When one of these condition groups are satisfied, this branch will be evaluated
     * @type {Array<ConditionGroup>}
     * @memberof ExpressionBranch
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof ExpressionBranch
     */
    result: EngineParamBinding;
}

export function ExpressionBranchFromJSON(json: any): ExpressionBranch {
    return ExpressionBranchFromJSONTyped(json, false);
}

export function ExpressionBranchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionBranch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'result': EngineParamBindingFromJSON(json['result']),
    };
}

export function ExpressionBranchToJSON(value?: ExpressionBranch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'result': EngineParamBindingToJSON(value.result),
    };
}

