/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSubscription,
    IncidentSubscriptionFromJSON,
    IncidentSubscriptionFromJSONTyped,
    IncidentSubscriptionToJSON,
} from './IncidentSubscription';

/**
 * 
 * @export
 * @interface IncidentSubscriptionsCreateResponseBody
 */
export interface IncidentSubscriptionsCreateResponseBody {
    /**
     * 
     * @type {IncidentSubscription}
     * @memberof IncidentSubscriptionsCreateResponseBody
     */
    incident_subscription: IncidentSubscription;
}

export function IncidentSubscriptionsCreateResponseBodyFromJSON(json: any): IncidentSubscriptionsCreateResponseBody {
    return IncidentSubscriptionsCreateResponseBodyFromJSONTyped(json, false);
}

export function IncidentSubscriptionsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSubscriptionsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_subscription': IncidentSubscriptionFromJSON(json['incident_subscription']),
    };
}

export function IncidentSubscriptionsCreateResponseBodyToJSON(value?: IncidentSubscriptionsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_subscription': IncidentSubscriptionToJSON(value.incident_subscription),
    };
}

