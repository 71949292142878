/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationTarget,
    EscalationTargetFromJSON,
    EscalationTargetFromJSONTyped,
    EscalationTargetToJSON,
} from './EscalationTarget';

/**
 * 
 * @export
 * @interface EscalationTransition
 */
export interface EscalationTransition {
    /**
     * 
     * @type {Date}
     * @memberof EscalationTransition
     */
    created_at: Date;
    /**
     * ID of escalation in external system
     * @type {string}
     * @memberof EscalationTransition
     */
    error?: string;
    /**
     * Unique identifier of the parent escalation
     * @type {string}
     * @memberof EscalationTransition
     */
    escalation_id: string;
    /**
     * The depth of the node this transition came from
     * @type {number}
     * @memberof EscalationTransition
     */
    from_node_depth?: number;
    /**
     * The path node this transition came from
     * @type {string}
     * @memberof EscalationTransition
     */
    from_node_id?: string;
    /**
     * Unique internal ID of the escalation transition
     * @type {string}
     * @memberof EscalationTransition
     */
    id: string;
    /**
     * The level of the escalation this transition is on
     * @type {number}
     * @memberof EscalationTransition
     */
    level?: number;
    /**
     * Description of the transition
     * @type {string}
     * @memberof EscalationTransition
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof EscalationTransition
     */
    occurred_at: Date;
    /**
     * The reason for this transition
     * @type {string}
     * @memberof EscalationTransition
     */
    reason?: EscalationTransitionReasonEnum;
    /**
     * Counter into the loop identified by repeat_id
     * @type {number}
     * @memberof EscalationTransition
     */
    repeat_count?: number;
    /**
     * Uniquely identifies the repeat this counter is for
     * @type {string}
     * @memberof EscalationTransition
     */
    repeat_id?: string;
    /**
     * Number to sort by
     * @type {number}
     * @memberof EscalationTransition
     */
    sort_key: number;
    /**
     * 
     * @type {Array<EscalationTarget>}
     * @memberof EscalationTransition
     */
    targets?: Array<EscalationTarget>;
    /**
     * The depth of the node this transition is moving into
     * @type {number}
     * @memberof EscalationTransition
     */
    to_node_depth?: number;
    /**
     * The path node this transition is moving into
     * @type {string}
     * @memberof EscalationTransition
     */
    to_node_id?: string;
    /**
     * The state of the transition
     * @type {string}
     * @memberof EscalationTransition
     */
    to_state: EscalationTransitionToStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof EscalationTransition
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum EscalationTransitionReasonEnum {
    InitialPending = 'initial_pending',
    UserAcked = 'user_acked',
    UserAckedExternally = 'user_acked_externally',
    IncidentDeclined = 'incident_declined',
    AutoAcked = 'auto_acked',
    GracePeriodEnded = 'grace_period_ended',
    NoRemainingRepeats = 'no_remaining_repeats',
    MaxErrors = 'max_errors',
    IncidentTriaged = 'incident_triaged',
    LevelActive = 'level_active',
    LevelTimeout = 'level_timeout',
    NoTargets = 'no_targets',
    AllNacked = 'all_nacked',
    ConditionTrue = 'condition_true',
    ConditionFalse = 'condition_false',
    ConditionFailed = 'condition_failed',
    RepeatTriggered = 'repeat_triggered',
    AlertMarkedRelated = 'alert_marked_related',
    AlertResolved = 'alert_resolved',
    MarkedUnrelatedToAllIncidents = 'marked_unrelated_to_all_incidents',
    InboundCallEnded = 'inbound_call_ended'
}/**
* @export
* @enum {string}
*/
export enum EscalationTransitionToStateEnum {
    Pending = 'pending',
    Triggered = 'triggered',
    Acked = 'acked',
    Resolved = 'resolved',
    Expired = 'expired',
    Cancelled = 'cancelled'
}

export function EscalationTransitionFromJSON(json: any): EscalationTransition {
    return EscalationTransitionFromJSONTyped(json, false);
}

export function EscalationTransitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationTransition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'escalation_id': json['escalation_id'],
        'from_node_depth': !exists(json, 'from_node_depth') ? undefined : json['from_node_depth'],
        'from_node_id': !exists(json, 'from_node_id') ? undefined : json['from_node_id'],
        'id': json['id'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'occurred_at': (new Date(json['occurred_at'])),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'repeat_count': !exists(json, 'repeat_count') ? undefined : json['repeat_count'],
        'repeat_id': !exists(json, 'repeat_id') ? undefined : json['repeat_id'],
        'sort_key': json['sort_key'],
        'targets': !exists(json, 'targets') ? undefined : ((json['targets'] as Array<any>).map(EscalationTargetFromJSON)),
        'to_node_depth': !exists(json, 'to_node_depth') ? undefined : json['to_node_depth'],
        'to_node_id': !exists(json, 'to_node_id') ? undefined : json['to_node_id'],
        'to_state': json['to_state'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EscalationTransitionToJSON(value?: EscalationTransition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'error': value.error,
        'escalation_id': value.escalation_id,
        'from_node_depth': value.from_node_depth,
        'from_node_id': value.from_node_id,
        'id': value.id,
        'level': value.level,
        'message': value.message,
        'occurred_at': (value.occurred_at.toISOString()),
        'reason': value.reason,
        'repeat_count': value.repeat_count,
        'repeat_id': value.repeat_id,
        'sort_key': value.sort_key,
        'targets': value.targets === undefined ? undefined : ((value.targets as Array<any>).map(EscalationTargetToJSON)),
        'to_node_depth': value.to_node_depth,
        'to_node_id': value.to_node_id,
        'to_state': value.to_state,
        'updated_at': (value.updated_at.toISOString()),
    };
}

