import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceDynatraceSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <div>
        Go to the <strong>Settings/Integrations/Problem Notifications</strong>{" "}
        section in Dynatrace
      </div>
      <div>
        Click <strong>Add notification</strong>
      </div>
      <div>
        Select <strong>Custom Integration</strong> as{" "}
        <strong>Notification type</strong>
      </div>
      <SetupInfoSingleLineBlocks
        intro={
          <div>
            Copy <strong>Name</strong> below and paste in the{" "}
            <strong>Display name</strong> field
          </div>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
        ]}
      />
      <div>
        Toggle <strong>Secret webhook URL</strong>
      </div>
      <SetupInfoSingleLineBlocks
        intro={
          <div>
            Copy <strong>URL</strong> below and paste it into{" "}
            <strong>Webhook URL</strong>
          </div>
        }
        blocks={[
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/dynatrace/${
              alertSourceConfig.id
            }?token=${alertSourceConfig.secret_token}`,
          },
        ]}
      />
      <div>
        Toggle <strong>Call webhook if problem is closed</strong>
      </div>
      <div className="space-y-4">
        <div>
          Copy <strong>Payload</strong> below and paste it into{" "}
          <strong>Webhook URL</strong>
        </div>
        <CodeBlock
          title={"Payload"}
          code={`{
  "PID": "{PID}",
  "ProblemTitle": "{ProblemTitle}",
  "State": "{State}",
  "Metadata": {
    "ImpactedEntities": {ImpactedEntities},
    "ImpactedEntity": "{ImpactedEntity}",
    "PID": "{PID}",
    "ProblemDetailsJSON": {ProblemDetailsJSON},
    "ProblemID": "{ProblemID}",
    "ProblemImpact": "{ProblemImpact}",
    "Problem URL": "https://example.com",
    "State": "{State}",
    "Tags": "{Tags}"
  }
}`}
        />
      </div>
      <div>
        Once you&rsquo;ve entered your payload you can click{" "}
        <strong>Send test notification</strong>
      </div>
    </SetupInfoNumberedList>
  );
};
