import cx from "classnames";
export const CloseIcon = ({
  className,
}: {
  className?: string;
}): React.ReactElement => {
  return (
    <svg
      className={cx(
        "text-slate-600 hover:text-content-tertiary transition",
        className,
      )}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7 7 17M7 7l10 10"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
