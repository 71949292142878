import { sortBy } from "lodash";
import {
  CustomField,
  Incident,
  IncidentRole,
  IncidentTimestamp,
  IncidentType,
  Settings,
  Severity,
  SlackTeamConfig,
} from "src/contexts/ClientContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI } from "src/utils/swr";

export interface IncidentCrudResourceTypes {
  customFields: Array<CustomField>;
  incidentRoles: Array<IncidentRole>;
  incidentTypes: Array<IncidentType>;
  severities: Array<Severity>;
  incidentTimestamps: Array<IncidentTimestamp>;
  slackTeamConfigs: Array<SlackTeamConfig>;
  settings: Settings | null;
}

export function useIncidentCrudResources(): IncidentCrudResourceTypes & {
  loading: boolean;
} {
  const { settings, settingsLoading } = useSettings();

  const {
    data: { severities },
    isLoading: severitiesLoading,
    error: severitiesError,
  } = useAPI("severitiesList", undefined, { fallbackData: { severities: [] } });

  const {
    data: fieldsData,
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined);
  const customFields = fieldsData
    ? sortBy(fieldsData.custom_fields, (field) => field.rank)
    : [];

  const {
    data: { incident_types: incidentTypes },
    isLoading: incidentTypesLoading,
    error: incidentTypesError,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  const {
    data: { incident_roles: incidentRoles },
    isLoading: incidentRolesLoading,
    error: incidentRolesError,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const {
    data: { incident_timestamps: incidentTimestamps },
    isLoading: incidentTimestampsLoading,
    error: incidentTimestampsError,
  } = useAPI("incidentTimestampsList", undefined, {
    fallbackData: { incident_timestamps: [] },
  });

  const {
    data: { slack_team_configs: slackTeamConfigs },
    isLoading: slackTeamConfigsLoading,
    error: slackTeamConfigsError,
  } = useAPI("slackTeamConfigsList", undefined, {
    fallbackData: { slack_team_configs: [] },
  });

  if (severitiesError) throw severitiesError;
  if (customFieldsError) throw customFieldsError;
  if (incidentTypesError) throw incidentTypesError;
  if (incidentRolesError) throw incidentRolesError;
  if (incidentTimestampsError) throw incidentTimestampsError;
  if (slackTeamConfigsError) throw slackTeamConfigsError;

  return {
    severities,
    incidentTypes,
    customFields,
    incidentRoles,
    incidentTimestamps,
    slackTeamConfigs,
    settings,
    loading:
      settingsLoading ||
      severitiesLoading ||
      incidentTypesLoading ||
      customFieldsLoading ||
      incidentRolesLoading ||
      incidentTimestampsLoading ||
      slackTeamConfigsLoading,
  };
}

export const useAllStatuses = () => {
  const {
    data: { incident_statuses: allStatuses },
    isLoading,
    error,
  } = useAPI("incidentLifecyclesListAllStatuses", undefined, {
    fallbackData: { incident_statuses: [] },
  });
  if (error) throw error;

  return { allStatuses, allStatusesLoading: isLoading };
};

export const useStatusesForIncident = ({
  incident,
  allLifecycles,
  forIncidentTypeId,
}:
  | {
      incident: Incident;
      allLifecycles?: never;
      forIncidentTypeId?: never;
    }
  | { incident?: never; allLifecycles: true; forIncidentTypeId?: never }
  | {
      incident?: never;
      allLifecycles?: never;
      forIncidentTypeId: string | true;
    }) => {
  const {
    data: { incident_statuses: statuses },
    isLoading,
    error,
  } = useAPI(
    allLifecycles
      ? "incidentLifecyclesListAllStatuses"
      : forIncidentTypeId
      ? "incidentLifecyclesListStatusesForIncidentType"
      : "incidentLifecyclesListStatusesForIncident",
    allLifecycles
      ? undefined
      : forIncidentTypeId !== undefined
      ? forIncidentTypeId === true
        ? {}
        : { incidentTypeId: forIncidentTypeId }
      : { incidentId: incident.id },
    {
      fallbackData: { incident_statuses: [] },
    },
  );

  if (error) throw error;

  return { statuses, statusesLoading: isLoading };
};

export const useStatusesForIncidentType = ({
  incidentTypeId,
}: {
  incidentTypeId: string | undefined;
}) => {
  const {
    data: { incident_statuses: statuses },
    isLoading,
    error,
  } = useAPI(
    "incidentLifecyclesListStatusesForIncidentType",
    incidentTypeId ? { incidentTypeId } : {},
    {
      fallbackData: { incident_statuses: [] },
    },
  );

  if (error) throw error;

  return { statuses, statusesLoading: isLoading };
};
