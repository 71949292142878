/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SchedulesCancelCoverRequestResponseBody,
    SchedulesCancelCoverRequestResponseBodyFromJSON,
    SchedulesCancelCoverRequestResponseBodyToJSON,
    SchedulesCreateCoverRequestRequestBody,
    SchedulesCreateCoverRequestRequestBodyFromJSON,
    SchedulesCreateCoverRequestRequestBodyToJSON,
    SchedulesCreateCoverRequestResponseBody,
    SchedulesCreateCoverRequestResponseBodyFromJSON,
    SchedulesCreateCoverRequestResponseBodyToJSON,
    SchedulesCreateExternalRequestBody,
    SchedulesCreateExternalRequestBodyFromJSON,
    SchedulesCreateExternalRequestBodyToJSON,
    SchedulesCreateExternalResponseBody,
    SchedulesCreateExternalResponseBodyFromJSON,
    SchedulesCreateExternalResponseBodyToJSON,
    SchedulesCreateFeedRequestBody,
    SchedulesCreateFeedRequestBodyFromJSON,
    SchedulesCreateFeedRequestBodyToJSON,
    SchedulesCreateFeedResponseBody,
    SchedulesCreateFeedResponseBodyFromJSON,
    SchedulesCreateFeedResponseBodyToJSON,
    SchedulesCreateOverrideRequestBody,
    SchedulesCreateOverrideRequestBodyFromJSON,
    SchedulesCreateOverrideRequestBodyToJSON,
    SchedulesCreateOverrideResponseBody,
    SchedulesCreateOverrideResponseBodyFromJSON,
    SchedulesCreateOverrideResponseBodyToJSON,
    SchedulesCreatePayConfigRequestBody,
    SchedulesCreatePayConfigRequestBodyFromJSON,
    SchedulesCreatePayConfigRequestBodyToJSON,
    SchedulesCreatePayConfigResponseBody,
    SchedulesCreatePayConfigResponseBodyFromJSON,
    SchedulesCreatePayConfigResponseBodyToJSON,
    SchedulesCreateReplicaRequestBody,
    SchedulesCreateReplicaRequestBodyFromJSON,
    SchedulesCreateReplicaRequestBodyToJSON,
    SchedulesCreateReplicaResponseBody,
    SchedulesCreateReplicaResponseBodyFromJSON,
    SchedulesCreateReplicaResponseBodyToJSON,
    SchedulesCreateReportRequestBody,
    SchedulesCreateReportRequestBodyFromJSON,
    SchedulesCreateReportRequestBodyToJSON,
    SchedulesCreateReportResponseBody,
    SchedulesCreateReportResponseBodyFromJSON,
    SchedulesCreateReportResponseBodyToJSON,
    SchedulesCreateRequestBody,
    SchedulesCreateRequestBodyFromJSON,
    SchedulesCreateRequestBodyToJSON,
    SchedulesCreateResponseBody,
    SchedulesCreateResponseBodyFromJSON,
    SchedulesCreateResponseBodyToJSON,
    SchedulesGenerateScheduleExportRequestBody,
    SchedulesGenerateScheduleExportRequestBodyFromJSON,
    SchedulesGenerateScheduleExportRequestBodyToJSON,
    SchedulesGenerateScheduleExportResponseBody,
    SchedulesGenerateScheduleExportResponseBodyFromJSON,
    SchedulesGenerateScheduleExportResponseBodyToJSON,
    SchedulesListCoverRequestResponseBody,
    SchedulesListCoverRequestResponseBodyFromJSON,
    SchedulesListCoverRequestResponseBodyToJSON,
    SchedulesListEntriesResponseBody,
    SchedulesListEntriesResponseBodyFromJSON,
    SchedulesListEntriesResponseBodyToJSON,
    SchedulesListExternalResponseBody,
    SchedulesListExternalResponseBodyFromJSON,
    SchedulesListExternalResponseBodyToJSON,
    SchedulesListForUserResponseBody,
    SchedulesListForUserResponseBodyFromJSON,
    SchedulesListForUserResponseBodyToJSON,
    SchedulesListHolidayEntriesResponseBody,
    SchedulesListHolidayEntriesResponseBodyFromJSON,
    SchedulesListHolidayEntriesResponseBodyToJSON,
    SchedulesListPayConfigResponseBody,
    SchedulesListPayConfigResponseBodyFromJSON,
    SchedulesListPayConfigResponseBodyToJSON,
    SchedulesListReportResponseBody,
    SchedulesListReportResponseBodyFromJSON,
    SchedulesListReportResponseBodyToJSON,
    SchedulesListResponseBody,
    SchedulesListResponseBodyFromJSON,
    SchedulesListResponseBodyToJSON,
    SchedulesListVersionsResponseBody,
    SchedulesListVersionsResponseBodyFromJSON,
    SchedulesListVersionsResponseBodyToJSON,
    SchedulesPreviewEntriesRequestBody,
    SchedulesPreviewEntriesRequestBodyFromJSON,
    SchedulesPreviewEntriesRequestBodyToJSON,
    SchedulesPreviewEntriesResponseBody,
    SchedulesPreviewEntriesResponseBodyFromJSON,
    SchedulesPreviewEntriesResponseBodyToJSON,
    SchedulesPublishReportRequestBody,
    SchedulesPublishReportRequestBodyFromJSON,
    SchedulesPublishReportRequestBodyToJSON,
    SchedulesPublishReportResponseBody,
    SchedulesPublishReportResponseBodyFromJSON,
    SchedulesPublishReportResponseBodyToJSON,
    SchedulesRespondCoverRequestRequestBody,
    SchedulesRespondCoverRequestRequestBodyFromJSON,
    SchedulesRespondCoverRequestRequestBodyToJSON,
    SchedulesRespondCoverRequestResponseBody,
    SchedulesRespondCoverRequestResponseBodyFromJSON,
    SchedulesRespondCoverRequestResponseBodyToJSON,
    SchedulesRestoreRequestBody,
    SchedulesRestoreRequestBodyFromJSON,
    SchedulesRestoreRequestBodyToJSON,
    SchedulesRestoreResponseBody,
    SchedulesRestoreResponseBodyFromJSON,
    SchedulesRestoreResponseBodyToJSON,
    SchedulesSetMirroredSlackUserGroupRequestBody,
    SchedulesSetMirroredSlackUserGroupRequestBodyFromJSON,
    SchedulesSetMirroredSlackUserGroupRequestBodyToJSON,
    SchedulesSetMirroredSlackUserGroupResponseBody,
    SchedulesSetMirroredSlackUserGroupResponseBodyFromJSON,
    SchedulesSetMirroredSlackUserGroupResponseBodyToJSON,
    SchedulesShowCoverRequestResponseBody,
    SchedulesShowCoverRequestResponseBodyFromJSON,
    SchedulesShowCoverRequestResponseBodyToJSON,
    SchedulesShowExternalResponseBody,
    SchedulesShowExternalResponseBodyFromJSON,
    SchedulesShowExternalResponseBodyToJSON,
    SchedulesShowOverrideResponseBody,
    SchedulesShowOverrideResponseBodyFromJSON,
    SchedulesShowOverrideResponseBodyToJSON,
    SchedulesShowPayConfigResponseBody,
    SchedulesShowPayConfigResponseBodyFromJSON,
    SchedulesShowPayConfigResponseBodyToJSON,
    SchedulesShowReportResponseBody,
    SchedulesShowReportResponseBodyFromJSON,
    SchedulesShowReportResponseBodyToJSON,
    SchedulesShowResponseBody,
    SchedulesShowResponseBodyFromJSON,
    SchedulesShowResponseBodyToJSON,
    SchedulesShowVersionResponseBody,
    SchedulesShowVersionResponseBodyFromJSON,
    SchedulesShowVersionResponseBodyToJSON,
    SchedulesUnpublishReportRequestBody,
    SchedulesUnpublishReportRequestBodyFromJSON,
    SchedulesUnpublishReportRequestBodyToJSON,
    SchedulesUnpublishReportResponseBody,
    SchedulesUnpublishReportResponseBodyFromJSON,
    SchedulesUnpublishReportResponseBodyToJSON,
    SchedulesUpdateNameRequestBody,
    SchedulesUpdateNameRequestBodyFromJSON,
    SchedulesUpdateNameRequestBodyToJSON,
    SchedulesUpdateNameResponseBody,
    SchedulesUpdateNameResponseBodyFromJSON,
    SchedulesUpdateNameResponseBodyToJSON,
    SchedulesUpdateOverrideRequestBody,
    SchedulesUpdateOverrideRequestBodyFromJSON,
    SchedulesUpdateOverrideRequestBodyToJSON,
    SchedulesUpdateOverrideResponseBody,
    SchedulesUpdateOverrideResponseBodyFromJSON,
    SchedulesUpdateOverrideResponseBodyToJSON,
    SchedulesUpdatePayConfigRequestBody,
    SchedulesUpdatePayConfigRequestBodyFromJSON,
    SchedulesUpdatePayConfigRequestBodyToJSON,
    SchedulesUpdatePayConfigResponseBody,
    SchedulesUpdatePayConfigResponseBodyFromJSON,
    SchedulesUpdatePayConfigResponseBodyToJSON,
    SchedulesUpdateRequestBody,
    SchedulesUpdateRequestBodyFromJSON,
    SchedulesUpdateRequestBodyToJSON,
    SchedulesUpdateResponseBody,
    SchedulesUpdateResponseBodyFromJSON,
    SchedulesUpdateResponseBodyToJSON,
} from '../models';

export interface SchedulesArchiveOverrideRequest {
    id: string;
}

export interface SchedulesCancelCoverRequestRequest {
    id: string;
}

export interface SchedulesCreateRequest {
    createRequestBody: SchedulesCreateRequestBody;
}

export interface SchedulesCreateCoverRequestRequest {
    createCoverRequestRequestBody: SchedulesCreateCoverRequestRequestBody;
}

export interface SchedulesCreateExternalRequest {
    createExternalRequestBody: SchedulesCreateExternalRequestBody;
}

export interface SchedulesCreateFeedRequest {
    createFeedRequestBody: SchedulesCreateFeedRequestBody;
}

export interface SchedulesCreateOverrideRequest {
    createOverrideRequestBody: SchedulesCreateOverrideRequestBody;
}

export interface SchedulesCreatePayConfigRequest {
    createPayConfigRequestBody: SchedulesCreatePayConfigRequestBody;
}

export interface SchedulesCreateReplicaRequest {
    createReplicaRequestBody: SchedulesCreateReplicaRequestBody;
}

export interface SchedulesCreateReportRequest {
    createReportRequestBody: SchedulesCreateReportRequestBody;
}

export interface SchedulesDestroyRequest {
    id: string;
}

export interface SchedulesDestroyPayConfigRequest {
    id: string;
}

export interface SchedulesDestroyReportRequest {
    id: string;
}

export interface SchedulesDownloadReportRequest {
    organisationId: string;
    id: string;
}

export interface SchedulesGenerateScheduleExportRequest {
    generateScheduleExportRequestBody: SchedulesGenerateScheduleExportRequestBody;
}

export interface SchedulesListCoverRequestRequest {
    state?: SchedulesListCoverRequestStateEnum;
    userIds?: Array<string>;
}

export interface SchedulesListEntriesRequest {
    scheduleIds: Array<string>;
    from?: string;
    until?: string;
}

export interface SchedulesListExternalRequest {
    includeNativeConfigPayload?: boolean;
    pageSize?: number;
    after?: string;
    search?: string;
}

export interface SchedulesListForUserRequest {
    user: string;
}

export interface SchedulesListHolidayEntriesRequest {
    from: string;
    until: string;
    userIds: Array<string>;
    countryCodes: Array<string>;
}

export interface SchedulesListVersionsRequest {
    scheduleId: string;
    pageSize?: number;
    after?: string;
}

export interface SchedulesPreviewEntriesRequest {
    previewEntriesRequestBody: SchedulesPreviewEntriesRequestBody;
}

export interface SchedulesPublishReportRequest {
    id: string;
    publishReportRequestBody: SchedulesPublishReportRequestBody;
}

export interface SchedulesRespondCoverRequestRequest {
    id: string;
    respondCoverRequestRequestBody: SchedulesRespondCoverRequestRequestBody;
}

export interface SchedulesRestoreRequest {
    versionId: string;
    id: string;
    restoreRequestBody: SchedulesRestoreRequestBody;
}

export interface SchedulesSetMirroredSlackUserGroupRequest {
    id: string;
    setMirroredSlackUserGroupRequestBody: SchedulesSetMirroredSlackUserGroupRequestBody;
}

export interface SchedulesShowRequest {
    id: string;
}

export interface SchedulesShowCalendarFeedRequest {
    token: string;
}

export interface SchedulesShowCoverRequestRequest {
    id: string;
}

export interface SchedulesShowExternalRequest {
    id: string;
    includeNativeConfigPayload?: boolean;
}

export interface SchedulesShowOverrideRequest {
    id: string;
}

export interface SchedulesShowPayConfigRequest {
    id: string;
}

export interface SchedulesShowReportRequest {
    id: string;
}

export interface SchedulesShowVersionRequest {
    id: string;
}

export interface SchedulesUnpublishReportRequest {
    id: string;
    unpublishReportRequestBody: SchedulesUnpublishReportRequestBody;
}

export interface SchedulesUpdateRequest {
    id: string;
    updateRequestBody: SchedulesUpdateRequestBody;
}

export interface SchedulesUpdateNameRequest {
    id: string;
    updateNameRequestBody: SchedulesUpdateNameRequestBody;
}

export interface SchedulesUpdateOverrideRequest {
    id: string;
    updateOverrideRequestBody: SchedulesUpdateOverrideRequestBody;
}

export interface SchedulesUpdatePayConfigRequest {
    id: string;
    updatePayConfigRequestBody: SchedulesUpdatePayConfigRequestBody;
}

/**
 * 
 */
export class SchedulesApi extends runtime.BaseAPI {

    /**
     * Archive an override for schedule
     * ArchiveOverride Schedules
     */
    async schedulesArchiveOverrideRaw(requestParameters: SchedulesArchiveOverrideRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesArchiveOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_overrides/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an override for schedule
     * ArchiveOverride Schedules
     */
    async schedulesArchiveOverride(requestParameters: SchedulesArchiveOverrideRequest, initOverrides?: RequestInit): Promise<void> {
        await this.schedulesArchiveOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * Cancel a cover request.
     * CancelCoverRequest Schedules
     */
    async schedulesCancelCoverRequestRaw(requestParameters: SchedulesCancelCoverRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCancelCoverRequestResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesCancelCoverRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_cover_requests/{id}/actions/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCancelCoverRequestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Cancel a cover request.
     * CancelCoverRequest Schedules
     */
    async schedulesCancelCoverRequest(requestParameters: SchedulesCancelCoverRequestRequest, initOverrides?: RequestInit): Promise<SchedulesCancelCoverRequestResponseBody> {
        const response = await this.schedulesCancelCoverRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new schedule.
     * Create Schedules
     */
    async schedulesCreateRaw(requestParameters: SchedulesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling schedulesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new schedule.
     * Create Schedules
     */
    async schedulesCreate(requestParameters: SchedulesCreateRequest, initOverrides?: RequestInit): Promise<SchedulesCreateResponseBody> {
        const response = await this.schedulesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an on-call cover request
     * CreateCoverRequest Schedules
     */
    async schedulesCreateCoverRequestRaw(requestParameters: SchedulesCreateCoverRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreateCoverRequestResponseBody>> {
        if (requestParameters.createCoverRequestRequestBody === null || requestParameters.createCoverRequestRequestBody === undefined) {
            throw new runtime.RequiredError('createCoverRequestRequestBody','Required parameter requestParameters.createCoverRequestRequestBody was null or undefined when calling schedulesCreateCoverRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_cover_requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreateCoverRequestRequestBodyToJSON(requestParameters.createCoverRequestRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreateCoverRequestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an on-call cover request
     * CreateCoverRequest Schedules
     */
    async schedulesCreateCoverRequest(requestParameters: SchedulesCreateCoverRequestRequest, initOverrides?: RequestInit): Promise<SchedulesCreateCoverRequestResponseBody> {
        const response = await this.schedulesCreateCoverRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new schedule from an existing schedule in an external system.
     * CreateExternal Schedules
     */
    async schedulesCreateExternalRaw(requestParameters: SchedulesCreateExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreateExternalResponseBody>> {
        if (requestParameters.createExternalRequestBody === null || requestParameters.createExternalRequestBody === undefined) {
            throw new runtime.RequiredError('createExternalRequestBody','Required parameter requestParameters.createExternalRequestBody was null or undefined when calling schedulesCreateExternal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_from_external`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreateExternalRequestBodyToJSON(requestParameters.createExternalRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreateExternalResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new schedule from an existing schedule in an external system.
     * CreateExternal Schedules
     */
    async schedulesCreateExternal(requestParameters: SchedulesCreateExternalRequest, initOverrides?: RequestInit): Promise<SchedulesCreateExternalResponseBody> {
        const response = await this.schedulesCreateExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a schedule feed link for some given schedules
     * CreateFeed Schedules
     */
    async schedulesCreateFeedRaw(requestParameters: SchedulesCreateFeedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreateFeedResponseBody>> {
        if (requestParameters.createFeedRequestBody === null || requestParameters.createFeedRequestBody === undefined) {
            throw new runtime.RequiredError('createFeedRequestBody','Required parameter requestParameters.createFeedRequestBody was null or undefined when calling schedulesCreateFeed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_feeds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreateFeedRequestBodyToJSON(requestParameters.createFeedRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreateFeedResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a schedule feed link for some given schedules
     * CreateFeed Schedules
     */
    async schedulesCreateFeed(requestParameters: SchedulesCreateFeedRequest, initOverrides?: RequestInit): Promise<SchedulesCreateFeedResponseBody> {
        const response = await this.schedulesCreateFeedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an override for schedule
     * CreateOverride Schedules
     */
    async schedulesCreateOverrideRaw(requestParameters: SchedulesCreateOverrideRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreateOverrideResponseBody>> {
        if (requestParameters.createOverrideRequestBody === null || requestParameters.createOverrideRequestBody === undefined) {
            throw new runtime.RequiredError('createOverrideRequestBody','Required parameter requestParameters.createOverrideRequestBody was null or undefined when calling schedulesCreateOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_overrides`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreateOverrideRequestBodyToJSON(requestParameters.createOverrideRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreateOverrideResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an override for schedule
     * CreateOverride Schedules
     */
    async schedulesCreateOverride(requestParameters: SchedulesCreateOverrideRequest, initOverrides?: RequestInit): Promise<SchedulesCreateOverrideResponseBody> {
        const response = await this.schedulesCreateOverrideRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a schedule pay config
     * CreatePayConfig Schedules
     */
    async schedulesCreatePayConfigRaw(requestParameters: SchedulesCreatePayConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreatePayConfigResponseBody>> {
        if (requestParameters.createPayConfigRequestBody === null || requestParameters.createPayConfigRequestBody === undefined) {
            throw new runtime.RequiredError('createPayConfigRequestBody','Required parameter requestParameters.createPayConfigRequestBody was null or undefined when calling schedulesCreatePayConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_pay_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreatePayConfigRequestBodyToJSON(requestParameters.createPayConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreatePayConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a schedule pay config
     * CreatePayConfig Schedules
     */
    async schedulesCreatePayConfig(requestParameters: SchedulesCreatePayConfigRequest, initOverrides?: RequestInit): Promise<SchedulesCreatePayConfigResponseBody> {
        const response = await this.schedulesCreatePayConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Setup a replica schedule in an external provider
     * CreateReplica Schedules
     */
    async schedulesCreateReplicaRaw(requestParameters: SchedulesCreateReplicaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreateReplicaResponseBody>> {
        if (requestParameters.createReplicaRequestBody === null || requestParameters.createReplicaRequestBody === undefined) {
            throw new runtime.RequiredError('createReplicaRequestBody','Required parameter requestParameters.createReplicaRequestBody was null or undefined when calling schedulesCreateReplica.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_replicas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreateReplicaRequestBodyToJSON(requestParameters.createReplicaRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreateReplicaResponseBodyFromJSON(jsonValue));
    }

    /**
     * Setup a replica schedule in an external provider
     * CreateReplica Schedules
     */
    async schedulesCreateReplica(requestParameters: SchedulesCreateReplicaRequest, initOverrides?: RequestInit): Promise<SchedulesCreateReplicaResponseBody> {
        const response = await this.schedulesCreateReplicaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a schedule report including shifts and pay, for a selection of schedules
     * CreateReport Schedules
     */
    async schedulesCreateReportRaw(requestParameters: SchedulesCreateReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesCreateReportResponseBody>> {
        if (requestParameters.createReportRequestBody === null || requestParameters.createReportRequestBody === undefined) {
            throw new runtime.RequiredError('createReportRequestBody','Required parameter requestParameters.createReportRequestBody was null or undefined when calling schedulesCreateReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesCreateReportRequestBodyToJSON(requestParameters.createReportRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesCreateReportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a schedule report including shifts and pay, for a selection of schedules
     * CreateReport Schedules
     */
    async schedulesCreateReport(requestParameters: SchedulesCreateReportRequest, initOverrides?: RequestInit): Promise<SchedulesCreateReportResponseBody> {
        const response = await this.schedulesCreateReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives a schedule
     * Destroy Schedules
     */
    async schedulesDestroyRaw(requestParameters: SchedulesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a schedule
     * Destroy Schedules
     */
    async schedulesDestroy(requestParameters: SchedulesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.schedulesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Archives an existing pay config
     * DestroyPayConfig Schedules
     */
    async schedulesDestroyPayConfigRaw(requestParameters: SchedulesDestroyPayConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesDestroyPayConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_pay_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an existing pay config
     * DestroyPayConfig Schedules
     */
    async schedulesDestroyPayConfig(requestParameters: SchedulesDestroyPayConfigRequest, initOverrides?: RequestInit): Promise<void> {
        await this.schedulesDestroyPayConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a report
     * DestroyReport Schedules
     */
    async schedulesDestroyReportRaw(requestParameters: SchedulesDestroyReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesDestroyReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a report
     * DestroyReport Schedules
     */
    async schedulesDestroyReport(requestParameters: SchedulesDestroyReportRequest, initOverrides?: RequestInit): Promise<void> {
        await this.schedulesDestroyReportRaw(requestParameters, initOverrides);
    }

    /**
     * Download an XLSX spreadsheet of the report
     * DownloadReport Schedules
     */
    async schedulesDownloadReportRaw(requestParameters: SchedulesDownloadReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling schedulesDownloadReport.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesDownloadReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_reports/{id}/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Download an XLSX spreadsheet of the report
     * DownloadReport Schedules
     */
    async schedulesDownloadReport(requestParameters: SchedulesDownloadReportRequest, initOverrides?: RequestInit): Promise<void> {
        await this.schedulesDownloadReportRaw(requestParameters, initOverrides);
    }

    /**
     * For a given schedule, export the configuration in a recognisable format
     * GenerateScheduleExport Schedules
     */
    async schedulesGenerateScheduleExportRaw(requestParameters: SchedulesGenerateScheduleExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesGenerateScheduleExportResponseBody>> {
        if (requestParameters.generateScheduleExportRequestBody === null || requestParameters.generateScheduleExportRequestBody === undefined) {
            throw new runtime.RequiredError('generateScheduleExportRequestBody','Required parameter requestParameters.generateScheduleExportRequestBody was null or undefined when calling schedulesGenerateScheduleExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_exports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesGenerateScheduleExportRequestBodyToJSON(requestParameters.generateScheduleExportRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesGenerateScheduleExportResponseBodyFromJSON(jsonValue));
    }

    /**
     * For a given schedule, export the configuration in a recognisable format
     * GenerateScheduleExport Schedules
     */
    async schedulesGenerateScheduleExport(requestParameters: SchedulesGenerateScheduleExportRequest, initOverrides?: RequestInit): Promise<SchedulesGenerateScheduleExportResponseBody> {
        const response = await this.schedulesGenerateScheduleExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List configured schedules
     * List Schedules
     */
    async schedulesListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List configured schedules
     * List Schedules
     */
    async schedulesList(initOverrides?: RequestInit): Promise<SchedulesListResponseBody> {
        const response = await this.schedulesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List on-call cover requests
     * ListCoverRequest Schedules
     */
    async schedulesListCoverRequestRaw(requestParameters: SchedulesListCoverRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListCoverRequestResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_cover_requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListCoverRequestResponseBodyFromJSON(jsonValue));
    }

    /**
     * List on-call cover requests
     * ListCoverRequest Schedules
     */
    async schedulesListCoverRequest(requestParameters: SchedulesListCoverRequestRequest, initOverrides?: RequestInit): Promise<SchedulesListCoverRequestResponseBody> {
        const response = await this.schedulesListCoverRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List schedule entries
     * ListEntries Schedules
     */
    async schedulesListEntriesRaw(requestParameters: SchedulesListEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListEntriesResponseBody>> {
        if (requestParameters.scheduleIds === null || requestParameters.scheduleIds === undefined) {
            throw new runtime.RequiredError('scheduleIds','Required parameter requestParameters.scheduleIds was null or undefined when calling schedulesListEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.scheduleIds) {
            queryParameters['schedule_ids'] = requestParameters.scheduleIds;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = requestParameters.until;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List schedule entries
     * ListEntries Schedules
     */
    async schedulesListEntries(requestParameters: SchedulesListEntriesRequest, initOverrides?: RequestInit): Promise<SchedulesListEntriesResponseBody> {
        const response = await this.schedulesListEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List external schedules using the default provider
     * ListExternal Schedules
     */
    async schedulesListExternalRaw(requestParameters: SchedulesListExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListExternalResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.includeNativeConfigPayload !== undefined) {
            queryParameters['include_native_config_payload'] = requestParameters.includeNativeConfigPayload;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external_schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListExternalResponseBodyFromJSON(jsonValue));
    }

    /**
     * List external schedules using the default provider
     * ListExternal Schedules
     */
    async schedulesListExternal(requestParameters: SchedulesListExternalRequest, initOverrides?: RequestInit): Promise<SchedulesListExternalResponseBody> {
        const response = await this.schedulesListExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List schedules that are relevant to this user
     * ListForUser Schedules
     */
    async schedulesListForUserRaw(requestParameters: SchedulesListForUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListForUserResponseBody>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling schedulesListForUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedules_for_user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListForUserResponseBodyFromJSON(jsonValue));
    }

    /**
     * List schedules that are relevant to this user
     * ListForUser Schedules
     */
    async schedulesListForUser(requestParameters: SchedulesListForUserRequest, initOverrides?: RequestInit): Promise<SchedulesListForUserResponseBody> {
        const response = await this.schedulesListForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List public holiday and user holiday entries for a given schedule
     * ListHolidayEntries Schedules
     */
    async schedulesListHolidayEntriesRaw(requestParameters: SchedulesListHolidayEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListHolidayEntriesResponseBody>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling schedulesListHolidayEntries.');
        }

        if (requestParameters.until === null || requestParameters.until === undefined) {
            throw new runtime.RequiredError('until','Required parameter requestParameters.until was null or undefined when calling schedulesListHolidayEntries.');
        }

        if (requestParameters.userIds === null || requestParameters.userIds === undefined) {
            throw new runtime.RequiredError('userIds','Required parameter requestParameters.userIds was null or undefined when calling schedulesListHolidayEntries.');
        }

        if (requestParameters.countryCodes === null || requestParameters.countryCodes === undefined) {
            throw new runtime.RequiredError('countryCodes','Required parameter requestParameters.countryCodes was null or undefined when calling schedulesListHolidayEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = requestParameters.until;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.countryCodes) {
            queryParameters['country_codes'] = requestParameters.countryCodes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_holiday_entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListHolidayEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List public holiday and user holiday entries for a given schedule
     * ListHolidayEntries Schedules
     */
    async schedulesListHolidayEntries(requestParameters: SchedulesListHolidayEntriesRequest, initOverrides?: RequestInit): Promise<SchedulesListHolidayEntriesResponseBody> {
        const response = await this.schedulesListHolidayEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List schedule pay configs
     * ListPayConfig Schedules
     */
    async schedulesListPayConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListPayConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_pay_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListPayConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * List schedule pay configs
     * ListPayConfig Schedules
     */
    async schedulesListPayConfig(initOverrides?: RequestInit): Promise<SchedulesListPayConfigResponseBody> {
        const response = await this.schedulesListPayConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all schedule reports
     * ListReport Schedules
     */
    async schedulesListReportRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListReportResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListReportResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all schedule reports
     * ListReport Schedules
     */
    async schedulesListReport(initOverrides?: RequestInit): Promise<SchedulesListReportResponseBody> {
        const response = await this.schedulesListReportRaw(initOverrides);
        return await response.value();
    }

    /**
     * List schedule versions
     * ListVersions Schedules
     */
    async schedulesListVersionsRaw(requestParameters: SchedulesListVersionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesListVersionsResponseBody>> {
        if (requestParameters.scheduleId === null || requestParameters.scheduleId === undefined) {
            throw new runtime.RequiredError('scheduleId','Required parameter requestParameters.scheduleId was null or undefined when calling schedulesListVersions.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.scheduleId !== undefined) {
            queryParameters['schedule_id'] = requestParameters.scheduleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesListVersionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List schedule versions
     * ListVersions Schedules
     */
    async schedulesListVersions(requestParameters: SchedulesListVersionsRequest, initOverrides?: RequestInit): Promise<SchedulesListVersionsResponseBody> {
        const response = await this.schedulesListVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview schedule entries
     * PreviewEntries Schedules
     */
    async schedulesPreviewEntriesRaw(requestParameters: SchedulesPreviewEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesPreviewEntriesResponseBody>> {
        if (requestParameters.previewEntriesRequestBody === null || requestParameters.previewEntriesRequestBody === undefined) {
            throw new runtime.RequiredError('previewEntriesRequestBody','Required parameter requestParameters.previewEntriesRequestBody was null or undefined when calling schedulesPreviewEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesPreviewEntriesRequestBodyToJSON(requestParameters.previewEntriesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesPreviewEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Preview schedule entries
     * PreviewEntries Schedules
     */
    async schedulesPreviewEntries(requestParameters: SchedulesPreviewEntriesRequest, initOverrides?: RequestInit): Promise<SchedulesPreviewEntriesResponseBody> {
        const response = await this.schedulesPreviewEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish a report, emailing a copy to whoever needs access
     * PublishReport Schedules
     */
    async schedulesPublishReportRaw(requestParameters: SchedulesPublishReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesPublishReportResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesPublishReport.');
        }

        if (requestParameters.publishReportRequestBody === null || requestParameters.publishReportRequestBody === undefined) {
            throw new runtime.RequiredError('publishReportRequestBody','Required parameter requestParameters.publishReportRequestBody was null or undefined when calling schedulesPublishReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_reports/{id}/actions/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesPublishReportRequestBodyToJSON(requestParameters.publishReportRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesPublishReportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Publish a report, emailing a copy to whoever needs access
     * PublishReport Schedules
     */
    async schedulesPublishReport(requestParameters: SchedulesPublishReportRequest, initOverrides?: RequestInit): Promise<SchedulesPublishReportResponseBody> {
        const response = await this.schedulesPublishReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Respond to a cover request.
     * RespondCoverRequest Schedules
     */
    async schedulesRespondCoverRequestRaw(requestParameters: SchedulesRespondCoverRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesRespondCoverRequestResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesRespondCoverRequest.');
        }

        if (requestParameters.respondCoverRequestRequestBody === null || requestParameters.respondCoverRequestRequestBody === undefined) {
            throw new runtime.RequiredError('respondCoverRequestRequestBody','Required parameter requestParameters.respondCoverRequestRequestBody was null or undefined when calling schedulesRespondCoverRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_cover_requests/{id}/actions/respond`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesRespondCoverRequestRequestBodyToJSON(requestParameters.respondCoverRequestRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesRespondCoverRequestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Respond to a cover request.
     * RespondCoverRequest Schedules
     */
    async schedulesRespondCoverRequest(requestParameters: SchedulesRespondCoverRequestRequest, initOverrides?: RequestInit): Promise<SchedulesRespondCoverRequestResponseBody> {
        const response = await this.schedulesRespondCoverRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore a previous schedule version
     * Restore Schedules
     */
    async schedulesRestoreRaw(requestParameters: SchedulesRestoreRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesRestoreResponseBody>> {
        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling schedulesRestore.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesRestore.');
        }

        if (requestParameters.restoreRequestBody === null || requestParameters.restoreRequestBody === undefined) {
            throw new runtime.RequiredError('restoreRequestBody','Required parameter requestParameters.restoreRequestBody was null or undefined when calling schedulesRestore.');
        }

        const queryParameters: any = {};

        if (requestParameters.versionId !== undefined) {
            queryParameters['version_id'] = requestParameters.versionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedules/{id}/actions/restore`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesRestoreRequestBodyToJSON(requestParameters.restoreRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesRestoreResponseBodyFromJSON(jsonValue));
    }

    /**
     * Restore a previous schedule version
     * Restore Schedules
     */
    async schedulesRestore(requestParameters: SchedulesRestoreRequest, initOverrides?: RequestInit): Promise<SchedulesRestoreResponseBody> {
        const response = await this.schedulesRestoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set a Slack user group to be mirrored to by this schedule
     * SetMirroredSlackUserGroup Schedules
     */
    async schedulesSetMirroredSlackUserGroupRaw(requestParameters: SchedulesSetMirroredSlackUserGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesSetMirroredSlackUserGroupResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesSetMirroredSlackUserGroup.');
        }

        if (requestParameters.setMirroredSlackUserGroupRequestBody === null || requestParameters.setMirroredSlackUserGroupRequestBody === undefined) {
            throw new runtime.RequiredError('setMirroredSlackUserGroupRequestBody','Required parameter requestParameters.setMirroredSlackUserGroupRequestBody was null or undefined when calling schedulesSetMirroredSlackUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedules/{id}/actions/set_mirrored_slack_user_group`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesSetMirroredSlackUserGroupRequestBodyToJSON(requestParameters.setMirroredSlackUserGroupRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesSetMirroredSlackUserGroupResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set a Slack user group to be mirrored to by this schedule
     * SetMirroredSlackUserGroup Schedules
     */
    async schedulesSetMirroredSlackUserGroup(requestParameters: SchedulesSetMirroredSlackUserGroupRequest, initOverrides?: RequestInit): Promise<SchedulesSetMirroredSlackUserGroupResponseBody> {
        const response = await this.schedulesSetMirroredSlackUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single schedule
     * Show Schedules
     */
    async schedulesShowRaw(requestParameters: SchedulesShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single schedule
     * Show Schedules
     */
    async schedulesShow(requestParameters: SchedulesShowRequest, initOverrides?: RequestInit): Promise<SchedulesShowResponseBody> {
        const response = await this.schedulesShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns iCalendar file for the given schedule feed
     * ShowCalendarFeed Schedules
     */
    async schedulesShowCalendarFeedRaw(requestParameters: SchedulesShowCalendarFeedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling schedulesShowCalendarFeed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_feeds/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns iCalendar file for the given schedule feed
     * ShowCalendarFeed Schedules
     */
    async schedulesShowCalendarFeed(requestParameters: SchedulesShowCalendarFeedRequest, initOverrides?: RequestInit): Promise<void> {
        await this.schedulesShowCalendarFeedRaw(requestParameters, initOverrides);
    }

    /**
     * Show an on-call cover request
     * ShowCoverRequest Schedules
     */
    async schedulesShowCoverRequestRaw(requestParameters: SchedulesShowCoverRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesShowCoverRequestResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesShowCoverRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_cover_requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesShowCoverRequestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an on-call cover request
     * ShowCoverRequest Schedules
     */
    async schedulesShowCoverRequest(requestParameters: SchedulesShowCoverRequestRequest, initOverrides?: RequestInit): Promise<SchedulesShowCoverRequestResponseBody> {
        const response = await this.schedulesShowCoverRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an external schedule using its default provider
     * ShowExternal Schedules
     */
    async schedulesShowExternalRaw(requestParameters: SchedulesShowExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesShowExternalResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesShowExternal.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeNativeConfigPayload !== undefined) {
            queryParameters['include_native_config_payload'] = requestParameters.includeNativeConfigPayload;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesShowExternalResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an external schedule using its default provider
     * ShowExternal Schedules
     */
    async schedulesShowExternal(requestParameters: SchedulesShowExternalRequest, initOverrides?: RequestInit): Promise<SchedulesShowExternalResponseBody> {
        const response = await this.schedulesShowExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an override for schedule
     * ShowOverride Schedules
     */
    async schedulesShowOverrideRaw(requestParameters: SchedulesShowOverrideRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesShowOverrideResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesShowOverride.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_overrides`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesShowOverrideResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an override for schedule
     * ShowOverride Schedules
     */
    async schedulesShowOverride(requestParameters: SchedulesShowOverrideRequest, initOverrides?: RequestInit): Promise<SchedulesShowOverrideResponseBody> {
        const response = await this.schedulesShowOverrideRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show schedule pay config
     * ShowPayConfig Schedules
     */
    async schedulesShowPayConfigRaw(requestParameters: SchedulesShowPayConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesShowPayConfigResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesShowPayConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_pay_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesShowPayConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show schedule pay config
     * ShowPayConfig Schedules
     */
    async schedulesShowPayConfig(requestParameters: SchedulesShowPayConfigRequest, initOverrides?: RequestInit): Promise<SchedulesShowPayConfigResponseBody> {
        const response = await this.schedulesShowPayConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single report
     * ShowReport Schedules
     */
    async schedulesShowReportRaw(requestParameters: SchedulesShowReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesShowReportResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesShowReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesShowReportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single report
     * ShowReport Schedules
     */
    async schedulesShowReport(requestParameters: SchedulesShowReportRequest, initOverrides?: RequestInit): Promise<SchedulesShowReportResponseBody> {
        const response = await this.schedulesShowReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a schedule version
     * ShowVersion Schedules
     */
    async schedulesShowVersionRaw(requestParameters: SchedulesShowVersionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesShowVersionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesShowVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/schedule_versions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesShowVersionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a schedule version
     * ShowVersion Schedules
     */
    async schedulesShowVersion(requestParameters: SchedulesShowVersionRequest, initOverrides?: RequestInit): Promise<SchedulesShowVersionResponseBody> {
        const response = await this.schedulesShowVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unpublish a report, if it is discovered to be inaccurate
     * UnpublishReport Schedules
     */
    async schedulesUnpublishReportRaw(requestParameters: SchedulesUnpublishReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesUnpublishReportResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesUnpublishReport.');
        }

        if (requestParameters.unpublishReportRequestBody === null || requestParameters.unpublishReportRequestBody === undefined) {
            throw new runtime.RequiredError('unpublishReportRequestBody','Required parameter requestParameters.unpublishReportRequestBody was null or undefined when calling schedulesUnpublishReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_reports/{id}/actions/unpublish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesUnpublishReportRequestBodyToJSON(requestParameters.unpublishReportRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesUnpublishReportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Unpublish a report, if it is discovered to be inaccurate
     * UnpublishReport Schedules
     */
    async schedulesUnpublishReport(requestParameters: SchedulesUnpublishReportRequest, initOverrides?: RequestInit): Promise<SchedulesUnpublishReportResponseBody> {
        const response = await this.schedulesUnpublishReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a schedule.
     * Update Schedules
     */
    async schedulesUpdateRaw(requestParameters: SchedulesUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling schedulesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a schedule.
     * Update Schedules
     */
    async schedulesUpdate(requestParameters: SchedulesUpdateRequest, initOverrides?: RequestInit): Promise<SchedulesUpdateResponseBody> {
        const response = await this.schedulesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rename a schedule
     * UpdateName Schedules
     */
    async schedulesUpdateNameRaw(requestParameters: SchedulesUpdateNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesUpdateNameResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesUpdateName.');
        }

        if (requestParameters.updateNameRequestBody === null || requestParameters.updateNameRequestBody === undefined) {
            throw new runtime.RequiredError('updateNameRequestBody','Required parameter requestParameters.updateNameRequestBody was null or undefined when calling schedulesUpdateName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedules/{id}/actions/update_name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesUpdateNameRequestBodyToJSON(requestParameters.updateNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesUpdateNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Rename a schedule
     * UpdateName Schedules
     */
    async schedulesUpdateName(requestParameters: SchedulesUpdateNameRequest, initOverrides?: RequestInit): Promise<SchedulesUpdateNameResponseBody> {
        const response = await this.schedulesUpdateNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an override for schedule
     * UpdateOverride Schedules
     */
    async schedulesUpdateOverrideRaw(requestParameters: SchedulesUpdateOverrideRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesUpdateOverrideResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesUpdateOverride.');
        }

        if (requestParameters.updateOverrideRequestBody === null || requestParameters.updateOverrideRequestBody === undefined) {
            throw new runtime.RequiredError('updateOverrideRequestBody','Required parameter requestParameters.updateOverrideRequestBody was null or undefined when calling schedulesUpdateOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_overrides/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesUpdateOverrideRequestBodyToJSON(requestParameters.updateOverrideRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesUpdateOverrideResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an override for schedule
     * UpdateOverride Schedules
     */
    async schedulesUpdateOverride(requestParameters: SchedulesUpdateOverrideRequest, initOverrides?: RequestInit): Promise<SchedulesUpdateOverrideResponseBody> {
        const response = await this.schedulesUpdateOverrideRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a schedule pay config
     * UpdatePayConfig Schedules
     */
    async schedulesUpdatePayConfigRaw(requestParameters: SchedulesUpdatePayConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchedulesUpdatePayConfigResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling schedulesUpdatePayConfig.');
        }

        if (requestParameters.updatePayConfigRequestBody === null || requestParameters.updatePayConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updatePayConfigRequestBody','Required parameter requestParameters.updatePayConfigRequestBody was null or undefined when calling schedulesUpdatePayConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/schedule_pay_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchedulesUpdatePayConfigRequestBodyToJSON(requestParameters.updatePayConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesUpdatePayConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a schedule pay config
     * UpdatePayConfig Schedules
     */
    async schedulesUpdatePayConfig(requestParameters: SchedulesUpdatePayConfigRequest, initOverrides?: RequestInit): Promise<SchedulesUpdatePayConfigResponseBody> {
        const response = await this.schedulesUpdatePayConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SchedulesListCoverRequestStateEnum {
    Created = 'created',
    Cancelled = 'cancelled',
    Accepted = 'accepted',
    Declined = 'declined'
}
