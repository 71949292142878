import { EngineLiteralBadge } from "@incident-shared/engine";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { SettingsDeleteButton } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { ErrorMessage } from "@incident-ui";
import {
  Accordion,
  AccordionProvider,
  AccordionStackedList,
  AccordionTriggerButton,
  AccordionTriggerText,
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  ContentBox,
  EmptyState,
  GenericErrorMessage,
  Heading,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  Interpose,
  Loader,
  LoadingWrapper,
  SeverityBadge,
  StackedList,
  StackedListItem,
  Tooltip,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useState } from "react";
import styles from "src/components/@ui/Accordion/Accordion.module.scss";
import {
  Incident,
  PoliciesDestroyRequest,
  PoliciesListViolationsResponseBody,
  Policy,
  PolicyPolicyTypeEnum,
  PolicyViolation,
  PolicyViolationLevelEnum,
  PolicyViolationPolicyTypeEnum,
  ReportChannelTypeEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useAllResources } from "src/hooks/useResources";
import { getEmptyScope, mergeScopes } from "src/utils/scope";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { formatTimestampLocale } from "../../../../utils/datetime";
import { useGetPolicyTypeConfig } from "../common/config";
import { DismissViolationConfirmationModal } from "../common/PolicyDismissConfirmationModal";
import {
  PolicyReportSchedulePreview,
  PolicyReportScheduleSummary,
} from "../common/PolicyReportSchedulesPreview";
import { PolicyCapsTxt } from "../create-edit/PolicyEditableSection";
import { ViewPolicyRequirements } from "../create-edit/PolicyRequirementsEditor";
import { ViewPolicySLA } from "../create-edit/PolicySLASection";

export const PolicyView = ({
  policy,
}: {
  policy: Policy;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const { trigger: deletePolicy } = useAPIMutation(
    "policiesList",
    undefined,
    async (apiClient, data: PoliciesDestroyRequest) => {
      await apiClient.policiesDestroy(data);
    },
    {
      onSuccess: () => navigate("/settings/policies"),
    },
  );

  const {
    data: { requirements_templates: requirementsTemplates },
    isLoading: requirementsTemplatesLoading,
    error: requirementsTemplatesError,
  } = useAPI("policiesListRequirementsTemplates", undefined, {
    fallbackData: { requirements_templates: [] },
  });

  const config = useGetPolicyTypeConfig()(policy.policy_type);

  const {
    data: scopeData,
    isLoading: scopeLoading,
    error: scopeError,
  } = useAPI("policiesBuildScope", undefined);

  const {
    data: { report_schedules: reportSchedules },
    isLoading: scheduleLoading,
    error: scheduleError,
  } = useAPI("policiesListReportSchedule", undefined, {
    fallbackData: { report_schedules: [] },
  });

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  const commsPlatform = usePrimaryCommsPlatform();

  const error =
    scopeError || requirementsTemplatesError || resourcesError || scheduleError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const templates =
    requirementsTemplates.find(
      (t) =>
        (t.policy_type as unknown as PolicyPolicyTypeEnum) ===
        policy.policy_type,
    )?.templates || [];

  const loading =
    scopeLoading ||
    requirementsTemplatesLoading ||
    resourcesLoading ||
    scheduleLoading;

  const scope = scopeData
    ? mergeScopes(
        scopeData.policy_scopes[policy.policy_type],
        scopeData.incident_scope,
      )
    : getEmptyScope();

  const reportSchedulesForPolicy = reportSchedules.filter((report) =>
    report.policy_ids.includes(policy.id),
  );
  const aReportHasEmail = reportSchedulesForPolicy.some((report) =>
    report.channels.some((ch) => ch.type === ReportChannelTypeEnum.Email),
  );

  return (
    <SecondaryNavSubPageWrapper
      title={policy.name}
      backHref="/settings/policies"
      icon={IconEnum.Shield}
      crumbs={[
        {
          title: "Policies",
          to: "/settings/policies",
        },
      ]}
      accessory={
        <div className="flex gap-2">
          <SettingsDeleteButton
            resourceTitle={policy.name}
            requiredScope={ScopeNameEnum.PoliciesDestroy}
            theme={ButtonTheme.DestroySecondary}
            buttonSize={BadgeSize.Large}
            dependentResources={undefined}
            deleteConfirmationTitle={"Delete policy"}
            deleteConfirmationContent={
              <>
                Are you sure you want to delete the{" "}
                <span className="font-bold">{policy.name}</span> policy?
              </>
            }
            onDelete={() => deletePolicy({ id: policy.id })}
          />
          <GatedButton
            requiredScope={ScopeNameEnum.PoliciesCreate}
            onClick={() => navigate(`/settings/policies/${policy.id}/edit`)}
            icon={IconEnum.Edit}
            analyticsTrackingId={"edit-policy"}
          >
            Edit policy
          </GatedButton>
        </div>
      }
    >
      <div className="flex flex-col gap-10">
        {policy.description && (
          <Callout theme={CalloutTheme.Plain} showIcon={false}>
            {policy.description}
          </Callout>
        )}
        <div className="flex flex-col gap-3">
          <Heading level={2} size="medium">
            Policy details
          </Heading>
          <LoadingWrapper loading={loading}>
            <StackedList>
              <PolicyViewItem
                icon={IconEnum.Shield}
                titleNode={
                  <div className="flex items-center gap-2">
                    <PolicyViewItemTitle>
                      This policy ensures {config.subject_plural} are
                    </PolicyViewItemTitle>
                    <ViewPolicyRequirements
                      conditionGroups={policy.type_condition_groups}
                      templates={templates || []}
                    />
                  </div>
                }
              />
              <PolicyViewItem
                icon={IconEnum.Timer}
                // Show the conditions inline if there's no expression, or within the item when there's an expression
                titleNode={
                  <div className="flex items-center gap-2">
                    <PolicyViewItemTitle>This must happen </PolicyViewItemTitle>
                    {!!policy.sla_days?.value?.literal && (
                      <ViewPolicySLA
                        binding={policy.sla_days}
                        scope={scope}
                        resources={resources}
                        slaTimestampName={policy.sla_incident_timestamp.name}
                        expressions={policy.expressions || []}
                        mini
                        templates={templates}
                        requirements={policy.type_condition_groups}
                      />
                    )}
                  </div>
                }
              >
                {!policy.sla_days?.value?.literal && (
                  <ViewPolicySLA
                    binding={policy.sla_days}
                    scope={scope}
                    resources={resources}
                    slaTimestampName={policy.sla_incident_timestamp.name}
                    expressions={policy.expressions || []}
                    mini
                    templates={templates}
                    requirements={policy.type_condition_groups}
                  />
                )}
              </PolicyViewItem>
              {/* Show the conditions inline if there's only one group, or within the item when there's multiple groups */}
              <PolicyViewItem
                icon={IconEnum.Filter}
                titleNode={
                  <div className="flex items-center gap-2">
                    <PolicyViewItemTitle>It applies to </PolicyViewItemTitle>
                    {policy.incident_condition_groups.length === 0 ? (
                      <EngineLiteralBadge
                        label="All incidents"
                        noTooltip
                        mini
                      />
                    ) : policy.incident_condition_groups.length === 1 ? (
                      <ConditionGroupsList
                        boxless
                        mini
                        groups={policy.incident_condition_groups}
                        iconless
                      />
                    ) : undefined}
                  </div>
                }
              >
                {policy.incident_condition_groups.length > 1 && (
                  <ConditionGroupsList
                    mini
                    groups={policy.incident_condition_groups}
                    iconless
                  />
                )}
              </PolicyViewItem>
              {policy.responsible_user_references &&
              policy.responsible_user_references?.length > 0 &&
              commsPlatform === CommsPlatform.Slack ? (
                <PolicyViewItem
                  icon={IconEnum.User}
                  titleNode={
                    <div className="flex items-center gap-2">
                      <PolicyViewItemTitle>
                        The person responsible is{" "}
                      </PolicyViewItemTitle>
                      <Interpose
                        separator={
                          <PolicyCapsTxt>or, if not set</PolicyCapsTxt>
                        }
                      >
                        {policy.responsible_user_references.map((ref) => (
                          <EngineLiteralBadge
                            key={ref.key}
                            mini
                            label={ref.label}
                          />
                        ))}
                      </Interpose>
                    </div>
                  }
                />
              ) : undefined}
              {reportSchedulesForPolicy.length > 0 ? (
                <PolicyViewItem
                  icon={
                    aReportHasEmail ? IconEnum.Email : IconEnum.SlackGreyscale
                  }
                  iconColor={ColorPaletteEnum.Blue}
                  titleNode={
                    reportSchedulesForPolicy.length === 1 ? (
                      <div className="flex flex-row items-center gap-2">
                        <PolicyViewItemTitle className="flex flex-row items-center gap-2">
                          It will be reported{" "}
                          <PolicyReportScheduleSummary
                            report={reportSchedulesForPolicy[0]}
                            withReportingVerb={false}
                          />
                        </PolicyViewItemTitle>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-2">
                        <PolicyViewItemTitle className="flex flex-row items-center gap-2">
                          It will be reported in{" "}
                        </PolicyViewItemTitle>
                      </div>
                    )
                  }
                >
                  {reportSchedulesForPolicy.length > 1 && (
                    <PolicyReportSchedulePreview
                      reportSchedules={reportSchedulesForPolicy}
                    />
                  )}
                </PolicyViewItem>
              ) : (
                // Show empty state
                <PolicyViewItem
                  icon={IconEnum.Email}
                  iconColor={ColorPaletteEnum.Slate}
                  titleNode={
                    <div className="flex items-center gap-2 ">
                      <PolicyViewItemTitle className="!text-content-tertiary">
                        It is not connected to a report
                      </PolicyViewItemTitle>
                    </div>
                  }
                />
              )}
            </StackedList>
          </LoadingWrapper>
        </div>
        {/* Active Policy Violations List */}
        <div className="flex flex-col gap-3">
          <Heading level={2} size="medium">
            Violations
          </Heading>
          <PolicyViolationsList policy={policy} />
        </div>
        {/* Dismissed Policy Violations List */}
        <DismissedPolicyViolationsList policy={policy} />
      </div>
    </SecondaryNavSubPageWrapper>
  );
};

const PolicyViewItemTitle = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("text-sm-bold text-content-primary", className)}>
      {children}
    </div>
  );
};

const PolicyViewItem = ({
  children,
  icon,
  iconColor,
  titleNode,
}: {
  children?: React.ReactNode;
  titleNode: React.ReactNode;
  icon: IconEnum;
  iconColor?: ColorPaletteEnum;
}) => {
  return (
    <div className="py-3 px-4 flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <IconBadge
          size={IconSize.Small}
          color={iconColor || ColorPaletteEnum.Blue}
          icon={icon}
        />
        {titleNode}
      </div>
      {!!children && <div className="pl-10">{children}</div>}
    </div>
  );
};

const PolicyViolationsList = ({
  policy,
}: {
  policy: Policy;
}): React.ReactElement => {
  const {
    data: resource,
    isLoading: loading,
    error,
  } = useAPI("policiesListViolations", { policyIds: [policy.id] });

  if (loading) {
    return <Loader />;
  }

  if (!loading && (error || !resource?.policy_violations)) {
    return (
      <ContentBox className={"p-4 mt-4"}>
        <GenericErrorMessage description="We couldn't load violations." />
      </ContentBox>
    );
  }

  // We only want to show violation errors and not warnings in this page.
  const errorViolations =
    resource?.policy_violations.filter(
      (v) => v.level === PolicyViolationLevelEnum.Error,
    ) || [];

  const incidentsWithViolations = resource?.incidents.filter((incident) =>
    errorViolations.some((v) => v.incident_id === incident.id),
  );

  return (
    <AccordionStackedList
      items={incidentsWithViolations ?? []}
      overflowActions={[]}
      whiteBg
      renderAccordion={(item) => {
        return (
          <ViolationDetails
            violations={(resource?.policy_violations ?? []).filter(
              (v) => v.incident_id === item.id,
            )}
            policy={policy}
          />
        );
      }}
      renderEmptyState={() => (
        <EmptyState
          icon={IconEnum.Success}
          content="All incidents are in compliance with this policy"
        />
      )}
      renderRow={(item) => {
        return (
          <ViolatingIncidentRow
            noPadding
            incident={item}
            violations={(resource?.policy_violations ?? []).filter(
              (v) => v.incident_id === item.id,
            )}
            violationType="error"
          />
        );
      }}
    />
  );
};

const DismissedPolicyViolationsList = ({
  policy,
}: {
  policy: Policy;
}): React.ReactElement => {
  const [openAccordion, setOpenAccordion] = useState<string[]>([]);

  const {
    data: dismissedViolations,
    isLoading: loading,
    error,
  } = useAPI("policiesListViolations", {
    policyIds: [policy.id],
    dismissed: true,
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ContentBox className={"p-4"}>
        <GenericErrorMessage description="We couldn't load violations." />
      </ContentBox>
    );
  }

  const noDismissedViolations =
    !dismissedViolations?.policy_violations ||
    dismissedViolations.policy_violations.length === 0;
  if (noDismissedViolations) {
    return <></>;
  }

  return (
    <AccordionProvider
      type={"multiple"}
      value={openAccordion}
      onValueChange={(ids: string[]) => setOpenAccordion(ids)}
    >
      <Accordion
        id={"violation-accordion"}
        header={
          <div className="flex items-center">
            <AccordionTriggerText
              className={tcx(
                "!text-content-secondary !text-sm-med !no-underline hover:text-slate-700",
              )}
              text={
                openAccordion.includes("violation-accordion")
                  ? "Hide dismissed violations"
                  : "View dismissed violations"
              }
            />
            <AccordionTriggerButton
              className={tcx(styles.chevron, "text-content-secondary")}
            />
          </div>
        }
      >
        <DismissedPolicyViolationsListInner
          dismissedViolations={dismissedViolations}
          policy={policy}
        />
      </Accordion>
    </AccordionProvider>
  );
};

const DismissedPolicyViolationsListInner = ({
  dismissedViolations,
  policy,
}: {
  dismissedViolations: PoliciesListViolationsResponseBody;
  policy: Policy;
}): React.ReactElement => {
  // We only want to show violation errors and not warnings in this page.
  const errorViolations =
    dismissedViolations?.policy_violations.filter(
      (v) => v.level === PolicyViolationLevelEnum.Error,
    ) || [];

  const incidentsWithViolations = dismissedViolations?.incidents.filter(
    (incident) => errorViolations.some((v) => v.incident_id === incident.id),
  );

  return (
    <div className="mt-2">
      <AccordionStackedList
        items={incidentsWithViolations ?? []}
        overflowActions={[]}
        whiteBg
        renderAccordion={(item) => {
          return (
            <ViolationDetails
              policy={policy}
              violations={(dismissedViolations?.policy_violations ?? []).filter(
                (v) => v.incident_id === item.id,
              )}
              dismissed
            />
          );
        }}
        renderEmptyState={() => (
          // If there are no dismissed violations, the 'Hide dismissed violations' section will not be rendered.
          <></>
        )}
        renderRow={(item) => {
          return (
            <ViolatingIncidentRow
              noPadding
              incident={item}
              violations={(dismissedViolations?.policy_violations ?? []).filter(
                (v) => v.incident_id === item.id,
              )}
              violationType="dismissed"
            />
          );
        }}
      />
    </div>
  );
};

export const ViolatingIncidentRow = ({
  incident,
  violations,
  noPadding,
  violationType = "error",
}: {
  incident: Incident;
  violations: PolicyViolation[];
  noPadding?: boolean;
  violationType?: "error" | "warning" | "dismissed";
}): React.ReactElement => {
  if (violations.length === 0) {
    return <></>;
  }

  const reportedAtString = formatTimestampLocale({
    timestamp: incident.reported_at,
    dateStyle: "short",
  });

  const overdueByDays = _.max(violations.map((v) => v.days));
  const overdueByString = `Overdue by ${overdueByDays} ${pluralize(
    "day",
    overdueByDays,
  )}`;

  const violationTypeToColour: {
    [key in string]: ColorPaletteEnum;
  } = {
    ["error"]: ColorPaletteEnum.Red,
    ["warning"]: ColorPaletteEnum.Yellow,
    ["dismissed"]: ColorPaletteEnum.Slate,
  };

  return (
    <StackedListItem
      noPadding={noPadding}
      icon={violationType === "warning" ? IconEnum.Timer : IconEnum.Warning}
      iconColor={violationTypeToColour[violationType]}
      title={`#INC-${incident.external_id}: ${incident.name}`}
      badgeNode={
        <div className="flex gap-2">
          <Badge
            theme={BadgeTheme.Tertiary}
            icon={IconEnum.Calendar}
            size={BadgeSize.ExtraSmall}
          >
            {violationType === "error"
              ? overdueByString
              : `Reported at: ${reportedAtString}`}
          </Badge>
          <SeverityBadge
            severity={incident.severity}
            size={BadgeSize.ExtraSmall}
          />
        </div>
      }
    />
  );
};

export const ViolationDetails = ({
  violations,
  policy,
  dismissed,
}: {
  violations: PolicyViolation[];
  policy: Policy;
  dismissed?: boolean;
}): React.ReactElement => {
  const [openDismissModal, setOpenDismissModal] = React.useState(false);
  const [openRestoreModal, setOpenRestoreModal] = React.useState(false);

  const { hasScope } = useIdentity();
  const canDismissViolations = hasScope(ScopeNameEnum.PolicyViolationsDismiss);
  const canRestoreViolations = hasScope(ScopeNameEnum.PolicyViolationsRestore);

  return (
    <Txt className="flex flex-col">
      {violations.map((v) => (
        <li
          key={`${v.resource_id}${v.incident_id}${v.policy_id}`}
          className="flex gap-2 flex-col my-1"
        >
          <div className="flex gap-2 flex-row items-center">
            {v.message}{" "}
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              href={makeHrefFromViolation(v)}
            >
              <Icon id={IconEnum.ExternalLink} size={IconSize.Medium} />
            </Button>
            {canDismissViolations && !dismissed && (
              <Tooltip content={<>Dismiss</>}>
                <Button
                  theme={ButtonTheme.Naked}
                  analyticsTrackingId={null}
                  onClick={() => {
                    setOpenDismissModal(true);
                  }}
                >
                  <Icon id={IconEnum.Close} size={IconSize.Medium} />
                </Button>
              </Tooltip>
            )}
            {canRestoreViolations && dismissed && (
              <Tooltip content={<>Restore</>}>
                <Button
                  theme={ButtonTheme.Naked}
                  analyticsTrackingId={null}
                  onClick={() => {
                    setOpenRestoreModal(true);
                  }}
                >
                  <Icon id={IconEnum.Loop} size={IconSize.Medium} />
                </Button>
              </Tooltip>
            )}
          </div>
          {/* Dismissed by actor and reason */}
          {dismissed && <DismissedReasonAndActor violation={v} />}
          {openDismissModal && (
            <DismissViolationConfirmationModal
              violationId={v.id}
              onClose={() => setOpenDismissModal(false)}
            />
          )}
          {openRestoreModal && (
            <RestoreViolationConfirmationModal
              violationId={v.id}
              policy={policy}
              onClose={() => setOpenRestoreModal(false)}
            />
          )}
        </li>
      ))}
    </Txt>
  );
};

const DismissedReasonAndActor = ({
  violation,
}: {
  violation: PolicyViolation;
}) => {
  const dismissedReasonBlock = (
    <div className="flex flex-row w-fit gap-2">
      <div className="vertical-line bg-stroke-hover w-0.5 rounded-1 flex-shrink-0"></div>
      <Txt className="text-xs-med text-content-secondary w-fit">
        {violation.dismissed_reason}
      </Txt>
    </div>
  );

  return (
    <div className="my-1.5 p-3 gap-3 rounded-3 bg-surface-secondary flex flex-row w-fit">
      <div className="flex flex-col gap-1">
        {!!violation.dismissed_by?.user && (
          <div className="flex flex-row items-center gap-2">
            <span className="text-xs-bold text-content-primary">
              Dismissed by
            </span>
            <Avatar
              size={IconSize.Small}
              url={violation.dismissed_by.user?.avatar_url}
              name={violation.dismissed_by.user?.name}
            />
            <span className="text-xs-med text-content-primary">
              {violation.dismissed_by.user?.name}
            </span>
            <span className="text-xs text-content-tertiary">
              {violation.dismissed_at &&
                formatTimestampLocale({
                  timestamp: violation.dismissed_at,
                  dateStyle: "short",
                  timeStyle: "short",
                  addWeekday: true,
                })}
            </span>
          </div>
        )}
        {dismissedReasonBlock}
      </div>
    </div>
  );
};

const RestoreViolationConfirmationModal = ({
  violationId,
  policy,
  onClose,
}: {
  violationId: string;
  policy: Policy;
  onClose: () => void;
}) => {
  const refetchPolicyViolations = useAPIRefetch("policiesListViolations", {
    policyIds: [policy.id],
    dismissed: true,
  });

  const { trigger, genericError } = useAPIMutation(
    "policiesListViolations",
    { policyIds: [policy.id] },
    async (apiClient) => {
      await apiClient.policiesRestorePolicyViolation({
        id: violationId,
      });
    },
    {
      onSuccess: () => {
        refetchPolicyViolations();
        onClose();
      },
    },
  );

  return (
    <ConfirmationDialog
      onCancel={() => onClose()}
      title={"Restore dismissed policy violation"}
      isOpen
      onConfirm={() => {
        trigger({ id: violationId });
      }}
      confirmButtonText="Restore"
    >
      <p>Are you sure you want to restore this policy violation?</p>
      <ErrorMessage message={genericError} />
    </ConfirmationDialog>
  );
};

export const makeHrefFromViolation = (violation: PolicyViolation): string => {
  switch (violation.policy_type) {
    case PolicyViolationPolicyTypeEnum.Debrief:
      return `/incidents/${violation.incident_id}`;
    case PolicyViolationPolicyTypeEnum.PostMortem:
      return `/incidents/${violation.incident_id}`;
    case PolicyViolationPolicyTypeEnum.FollowUp:
      return `/incidents/${violation.incident_id}?tab=follow-ups`;
    default:
      assertUnreachable(violation.policy_type);
      return "";
  }
};
