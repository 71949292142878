import { BillingSetting, SeatCount } from "@incident-io/api";
import { Tooltip, Txt } from "@incident-ui";
import { ReactElement } from "react";
import { tcx } from "src/utils/tailwind-classes";

const ExplanationBlock = ({
  label,
  value,
  tooltip,
}: {
  label: string;
  value: string | ReactElement;
  tooltip?: string;
}): ReactElement => {
  return (
    <div className="flex-1 p-6">
      <div className="flex">
        <div className="text-sm leading-5 font-medium text-slate-600">
          {label}
        </div>
        <Tooltip content={tooltip} buttonClassName="ml-0.5 mt-[1px]" />
      </div>
      <div>
        {typeof value === "string" ? (
          <Txt className="text-2xl !font-semibold leading-8">{value}</Txt>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

export const CurrentPlanExplanation = ({
  billingSetting,
  seatCounts,
}: {
  billingSetting: BillingSetting;
  seatCounts: SeatCount[];
}): React.ReactElement | null => {
  if (seatCounts.length === 0) {
    // You're someone special like Netflix, and so we just hide this entirely.
    return null;
  }

  const className = tcx(
    "border border-stroke rounded-xl", // Whole thing should have a rounded border
    "divide-stroke divide-y", // Default: lay out vertically
    "lg:flex lg:divide-x lg:divide-y-0", // At md and above, lay out horizontally
  );

  return (
    <div className={className}>
      <ExplanationBlock label="Your plan" value={billingSetting.plan_label} />
      {seatCounts.map(({ rank, label, used, limit, seat_description }) => {
        const denominator = limit === undefined ? "" : `/ ${limit}`;

        return (
          <ExplanationBlock
            key={`exp-${rank}`}
            label={label}
            value={
              <div className="flex flex-row items-end">
                <Txt className="text-2xl !font-semibold leading-8">
                  {used ?? "-"}
                </Txt>
                <Txt className="text-sm font-medium mb-1 ml-1">
                  {denominator}
                </Txt>
              </div>
            }
            tooltip={seat_description}
          />
        );
      })}
    </div>
  );
};
