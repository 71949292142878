/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOptionGroup,
    SelectOptionGroupFromJSON,
    SelectOptionGroupFromJSONTyped,
    SelectOptionGroupToJSON,
} from './SelectOptionGroup';

/**
 * 
 * @export
 * @interface InsightsListCustomFieldOptionsResponseBody
 */
export interface InsightsListCustomFieldOptionsResponseBody {
    /**
     * 
     * @type {Array<SelectOptionGroup>}
     * @memberof InsightsListCustomFieldOptionsResponseBody
     */
    option_groups: Array<SelectOptionGroup>;
}

export function InsightsListCustomFieldOptionsResponseBodyFromJSON(json: any): InsightsListCustomFieldOptionsResponseBody {
    return InsightsListCustomFieldOptionsResponseBodyFromJSONTyped(json, false);
}

export function InsightsListCustomFieldOptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsListCustomFieldOptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'option_groups': ((json['option_groups'] as Array<any>).map(SelectOptionGroupFromJSON)),
    };
}

export function InsightsListCustomFieldOptionsResponseBodyToJSON(value?: InsightsListCustomFieldOptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'option_groups': ((value.option_groups as Array<any>).map(SelectOptionGroupToJSON)),
    };
}

