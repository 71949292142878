/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageTemplateConfig,
    MessageTemplateConfigFromJSON,
    MessageTemplateConfigFromJSONTyped,
    MessageTemplateConfigToJSON,
} from './MessageTemplateConfig';
import {
    PreDefinedUpdateConfig,
    PreDefinedUpdateConfigFromJSON,
    PreDefinedUpdateConfigFromJSONTyped,
    PreDefinedUpdateConfigToJSON,
} from './PreDefinedUpdateConfig';

/**
 * 
 * @export
 * @interface StatusPageCreateTemplateRequestBody
 */
export interface StatusPageCreateTemplateRequestBody {
    /**
     * 
     * @type {MessageTemplateConfig}
     * @memberof StatusPageCreateTemplateRequestBody
     */
    message_template_config?: MessageTemplateConfig;
    /**
     * 
     * @type {PreDefinedUpdateConfig}
     * @memberof StatusPageCreateTemplateRequestBody
     */
    predefined_update_config?: PreDefinedUpdateConfig;
    /**
     * The ID of the status page this template relates to
     * @type {string}
     * @memberof StatusPageCreateTemplateRequestBody
     */
    status_page_id: string;
    /**
     * The type of incident this template relates to
     * @type {string}
     * @memberof StatusPageCreateTemplateRequestBody
     */
    status_page_incident_type: StatusPageCreateTemplateRequestBodyStatusPageIncidentTypeEnum;
    /**
     * The type of template
     * @type {string}
     * @memberof StatusPageCreateTemplateRequestBody
     */
    template_type: StatusPageCreateTemplateRequestBodyTemplateTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageCreateTemplateRequestBodyStatusPageIncidentTypeEnum {
    Incident = 'incident',
    Maintenance = 'maintenance'
}/**
* @export
* @enum {string}
*/
export enum StatusPageCreateTemplateRequestBodyTemplateTypeEnum {
    MessageTemplate = 'message_template',
    PreDefinedUpdate = 'pre_defined_update'
}

export function StatusPageCreateTemplateRequestBodyFromJSON(json: any): StatusPageCreateTemplateRequestBody {
    return StatusPageCreateTemplateRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message_template_config': !exists(json, 'message_template_config') ? undefined : MessageTemplateConfigFromJSON(json['message_template_config']),
        'predefined_update_config': !exists(json, 'predefined_update_config') ? undefined : PreDefinedUpdateConfigFromJSON(json['predefined_update_config']),
        'status_page_id': json['status_page_id'],
        'status_page_incident_type': json['status_page_incident_type'],
        'template_type': json['template_type'],
    };
}

export function StatusPageCreateTemplateRequestBodyToJSON(value?: StatusPageCreateTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message_template_config': MessageTemplateConfigToJSON(value.message_template_config),
        'predefined_update_config': PreDefinedUpdateConfigToJSON(value.predefined_update_config),
        'status_page_id': value.status_page_id,
        'status_page_incident_type': value.status_page_incident_type,
        'template_type': value.template_type,
    };
}

