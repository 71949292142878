import { SlackChannelNameEditForm } from "@incident-shared/settings";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import React from "react";
import graphic from "src/components/settings/banners/banner-teams-channels.svg";
import { SettingsHeading } from "src/components/settings/SettingsHeading";
import { useSettings } from "src/hooks/useSettings";

import { SettingsSubPageWrapper } from "../SettingsRoute";

export const TeamsChannelPage = (): React.ReactElement => {
  const { settings } = useSettings();

  if (!settings) {
    return <FullPageLoader />;
  }

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Organize Teams channels"
        subtitle="Customize the naming and setup of your Microsoft Teams channels for better collaboration."
        graphic={<img src={graphic} className="h-40" />}
      />
      <SlackChannelNameEditForm settings={settings} />
    </SettingsSubPageWrapper>
  );
};
