import type { SVGProps } from "react";
import * as React from "react";
const SvgEmail = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.88 10.827a.244.244 0 0 0 .24 0l7.777-4.283A2.747 2.747 0 0 0 15.25 4.5H4.75a2.745 2.745 0 0 0-2.643 2.033l7.773 4.294Z"
    />
    <path
      fill="currentColor"
      d="M10.845 12.14a1.742 1.742 0 0 1-1.69-.001L2 8.188v6.562a2.752 2.752 0 0 0 2.75 2.75h10.5A2.752 2.752 0 0 0 18 14.75V8.2l-7.155 3.94Z"
    />
  </svg>
);
export default SvgEmail;
