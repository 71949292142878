/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogEntry,
    CatalogEntryFromJSON,
    CatalogEntryFromJSONTyped,
    CatalogEntryToJSON,
} from './CatalogEntry';

/**
 * 
 * @export
 * @interface CatalogCreateEntryResponseBody
 */
export interface CatalogCreateEntryResponseBody {
    /**
     * 
     * @type {CatalogEntry}
     * @memberof CatalogCreateEntryResponseBody
     */
    catalog_entry: CatalogEntry;
}

export function CatalogCreateEntryResponseBodyFromJSON(json: any): CatalogCreateEntryResponseBody {
    return CatalogCreateEntryResponseBodyFromJSONTyped(json, false);
}

export function CatalogCreateEntryResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogCreateEntryResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry': CatalogEntryFromJSON(json['catalog_entry']),
    };
}

export function CatalogCreateEntryResponseBodyToJSON(value?: CatalogCreateEntryResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry': CatalogEntryToJSON(value.catalog_entry),
    };
}

