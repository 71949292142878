import {
  AlertSource,
  AlertSourceConfig,
  GrafanaConfig,
  IntegrationSettings,
} from "@incident-io/api";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import {
  Button,
  Callout,
  CalloutTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";

import { AlertSourceConfigRow } from "../AlertSourceConfigDrawer";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";

export const GrafanaConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { data, isLoading, error } = useAPI(
    "integrationsGrafanaShow",
    undefined,
  );

  const {
    data: { alert_source_configs: alertSourceConfigs },
    isLoading: sourceConfigsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const { integrations, integrationsLoading, integrationsError } =
    useIntegrations();

  const {
    data: { alert_sources: alertSources },
    isLoading: sourcesLoading,
    error: sourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });

  if (error || integrationsError || sourcesError || sourceConfigsError) {
    return <GenericErrorMessage error={error} />;
  }

  if (
    !data ||
    isLoading ||
    sourceConfigsLoading ||
    integrationsLoading ||
    sourcesLoading ||
    !integrations
  ) {
    return <IntegrationDrawerContentLoader />;
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      <ConfigInner
        config={data.grafana_config}
        alertSourceConfigs={alertSourceConfigs}
        alertSource={alertSources.find((s) => s.source_type === "grafana")}
        integrations={integrations}
      />
    </GenericConfigureDrawerContents>
  );
};

type ConfigInnerProps = {
  config?: GrafanaConfig;
  alertSourceConfigs: AlertSourceConfig[];
  alertSource?: AlertSource;
  integrations: IntegrationSettings[];
};

const ConfigInner = ({
  config,
  alertSourceConfigs,
  alertSource,
  integrations,
}: ConfigInnerProps) => {
  const ourSources = alertSourceConfigs.filter(
    (s) => s.source_type === "grafana",
  );
  return (
    <div className="flex flex-col gap-3">
      {config && (
        <Callout theme={CalloutTheme.Info}>
          You are connected to the{" "}
          <span className="font-semibold">{config.api_url}</span> Grafana
          instance.
        </Callout>
      )}
      <div className="text-base-bold text-content-primary">Alert sources</div>
      {ourSources.length > 0 ? (
        <div className="flex flex-col rounded-2 border border-stroke divide-y divide-slate-100">
          {ourSources.map((s) => (
            <AlertSourceConfigRow
              key={s.id}
              alertSourceConfig={s}
              alertSource={alertSource}
              integrations={integrations}
            />
          ))}
        </div>
      ) : (
        <EmptyState
          icon={IconEnum.Alert}
          content={
            "No alert sources have been configured for this integration yet."
          }
          cta={
            <Button
              analyticsTrackingId={null}
              href="/alerts/sources/create?source_type=grafana"
            >
              Create an alert source
            </Button>
          }
        />
      )}
    </div>
  );
};
