/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface AlertSlim
 */
export interface AlertSlim {
    /**
     * Which alert source config produced this alert
     * @type {string}
     * @memberof AlertSlim
     */
    alert_source_config_id: string;
    /**
     * When this entry was created
     * @type {Date}
     * @memberof AlertSlim
     */
    created_at: Date;
    /**
     * 
     * @type {TextDocument}
     * @memberof AlertSlim
     */
    description?: TextDocument;
    /**
     * The ID of this alert
     * @type {string}
     * @memberof AlertSlim
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AlertSlim
     */
    source_type: AlertSlimSourceTypeEnum;
    /**
     * Current status of this alert
     * @type {string}
     * @memberof AlertSlim
     */
    status: AlertSlimStatusEnum;
    /**
     * Alert title which is used when summarising the alert
     * @type {string}
     * @memberof AlertSlim
     */
    title: string;
    /**
     * When this alert was last updated
     * @type {Date}
     * @memberof AlertSlim
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum AlertSlimSourceTypeEnum {
    Alertmanager = 'alertmanager',
    AppOptics = 'app_optics',
    AzureMonitor = 'azure_monitor',
    Bugsnag = 'bugsnag',
    Checkly = 'checkly',
    Chronosphere = 'chronosphere',
    Cloudwatch = 'cloudwatch',
    Cloudflare = 'cloudflare',
    Cronitor = 'cronitor',
    CrowdstrikeFalcon = 'crowdstrike_falcon',
    Datadog = 'datadog',
    Dynatrace = 'dynatrace',
    Elasticsearch = 'elasticsearch',
    Email = 'email',
    Expel = 'expel',
    GithubIssue = 'github_issue',
    GoogleCloud = 'google_cloud',
    Grafana = 'grafana',
    Http = 'http',
    Honeycomb = 'honeycomb',
    IncomingCalls = 'incoming_calls',
    Jira = 'jira',
    MonteCarlo = 'monte_carlo',
    Nagios = 'nagios',
    NewRelic = 'new_relic',
    Opsgenie = 'opsgenie',
    Prtg = 'prtg',
    PagerDuty = 'pager_duty',
    Panther = 'panther',
    Pingdom = 'pingdom',
    Runscope = 'runscope',
    Sns = 'sns',
    Sentry = 'sentry',
    Splunk = 'splunk',
    StatusCake = 'status_cake',
    StatusPageViews = 'status_page_views',
    SumoLogic = 'sumo_logic',
    Uptime = 'uptime',
    Zendesk = 'zendesk'
}/**
* @export
* @enum {string}
*/
export enum AlertSlimStatusEnum {
    Firing = 'firing',
    Resolved = 'resolved'
}

export function AlertSlimFromJSON(json: any): AlertSlim {
    return AlertSlimFromJSONTyped(json, false);
}

export function AlertSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_config_id': json['alert_source_config_id'],
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : TextDocumentFromJSON(json['description']),
        'id': json['id'],
        'source_type': json['source_type'],
        'status': json['status'],
        'title': json['title'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AlertSlimToJSON(value?: AlertSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_config_id': value.alert_source_config_id,
        'created_at': (value.created_at.toISOString()),
        'description': TextDocumentToJSON(value.description),
        'id': value.id,
        'source_type': value.source_type,
        'status': value.status,
        'title': value.title,
        'updated_at': (value.updated_at.toISOString()),
    };
}

