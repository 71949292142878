import { CatalogResource, EngineParamBindingValue } from "@incident-io/api";
import { BadgeSize, Tooltip } from "@incident-ui";
import _ from "lodash";

import { NonPrimitiveEntry } from "./NonPrimitiveEntry";

export const NonPrimitiveEntryList = ({
  arrayValue,
  collapseList,
  catalogResource,
  innerClassName,
  outerClassName,
  clickable = true,
  basePath,
  withTooltipForSingleValue = false,
  catalogEntryBadgeSize,
}: {
  arrayValue: EngineParamBindingValue[];
  collapseList: boolean;
  catalogResource: Pick<CatalogResource, "color" | "icon">;
  innerClassName?: string;
  outerClassName?: string;
  clickable?: boolean;
  basePath?: string;
  withTooltipForSingleValue?: boolean;
  catalogEntryBadgeSize?: BadgeSize.Small | BadgeSize.Medium;
}) => {
  const sharedProps = {
    clickable,
    basePath,
    icon: catalogResource.icon,
    color: catalogResource.color,
    className: innerClassName,
    catalogEntryBadgeSize,
  };
  // Order the array values so that any without catalog entry references come
  // last. This means we won't colour and entire group as broken unless all of
  // them are broken.
  arrayValue = _.sortBy(arrayValue, (value) => -!!value.catalog_entry);
  return arrayValue.length > 1 && collapseList ? (
    <Tooltip
      side={"right"}
      light
      content={
        <div className={"flex flex-col gap-y-1"}>
          {arrayValue.map((x) => (
            <NonPrimitiveEntry
              key={x.label}
              value={x}
              enableTooltip={false}
              {...sharedProps}
            />
          ))}
        </div>
      }
      bubbleProps={{
        className: "!shadow-none",
      }}
    >
      <div className={outerClassName}>
        <NonPrimitiveEntry
          value={{
            ...arrayValue[0],
            label: arrayValue[0].label,
          }}
          enableTooltip={false}
          suffix={` (+${arrayValue.length - 1})`}
          {...sharedProps}
        />
      </div>
    </Tooltip>
  ) : (
    <div className={outerClassName}>
      {arrayValue.map((val) => (
        <Tooltip
          key={val.literal}
          content={withTooltipForSingleValue ? val.label : undefined}
        >
          <div>
            <NonPrimitiveEntry
              value={val}
              enableTooltip={false}
              {...sharedProps}
            />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
