/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationsServiceNowHandleInstallRequestBody
 */
export interface IntegrationsServiceNowHandleInstallRequestBody {
    /**
     * OAuth client ID in the customer's ServiceNow instance
     * @type {string}
     * @memberof IntegrationsServiceNowHandleInstallRequestBody
     */
    oauth_client_id: string;
    /**
     * OAuth client secret in the customer's ServiceNow instance
     * @type {string}
     * @memberof IntegrationsServiceNowHandleInstallRequestBody
     */
    oauth_client_secret: string;
    /**
     * Password of the ServiceNow user to authenticate as
     * @type {string}
     * @memberof IntegrationsServiceNowHandleInstallRequestBody
     */
    password: string;
    /**
     * Subdomain of `service-now.com` that the customers' instance is hosted on
     * @type {string}
     * @memberof IntegrationsServiceNowHandleInstallRequestBody
     */
    subdomain: string;
    /**
     * Username of the ServiceNow user to authenticate as
     * @type {string}
     * @memberof IntegrationsServiceNowHandleInstallRequestBody
     */
    username: string;
}

export function IntegrationsServiceNowHandleInstallRequestBodyFromJSON(json: any): IntegrationsServiceNowHandleInstallRequestBody {
    return IntegrationsServiceNowHandleInstallRequestBodyFromJSONTyped(json, false);
}

export function IntegrationsServiceNowHandleInstallRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsServiceNowHandleInstallRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oauth_client_id': json['oauth_client_id'],
        'oauth_client_secret': json['oauth_client_secret'],
        'password': json['password'],
        'subdomain': json['subdomain'],
        'username': json['username'],
    };
}

export function IntegrationsServiceNowHandleInstallRequestBodyToJSON(value?: IntegrationsServiceNowHandleInstallRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oauth_client_id': value.oauth_client_id,
        'oauth_client_secret': value.oauth_client_secret,
        'password': value.password,
        'subdomain': value.subdomain,
        'username': value.username,
    };
}

