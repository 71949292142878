import {
  OrgAwareLink,
  prependSlugToPathIfNeeded,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { LocalRelativeDateTime } from "@incident-ui";
import {
  Table,
  TableCell,
  TableCellStacked,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import _ from "lodash";
import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import { useParams } from "react-router-dom";
import { useAlertSource } from "src/components/alerts/useAlertSource";
import {
  EscalationPreview,
  EscalationStatusEnum,
} from "src/contexts/ClientContext";

import { CopyDebugID } from "../../../../utils/ShowDebugIDProvider";
import { EscalationStatusIcon } from "./EscalationStatusIcon";

export const EscalationsTable = ({
  escalations,
  infiniteScrollRef,
  isLoading,
  isFullyLoaded,
}: {
  escalations: EscalationPreview[];
  infiniteScrollRef: UseInfiniteScrollHookRefCallback;
  isLoading: boolean;
  isFullyLoaded: boolean;
}) => {
  const navigate = useOrgAwareNavigate();
  const { slug } = useParams();

  return (
    <Table
      gridTemplateColumns="6fr max-content 2fr 2fr"
      header={
        <>
          <TableHeaderCell title="Title" />
          <TableHeaderCell title="Incident" />
          <TableHeaderCell title="Path" />
          <TableHeaderCell title="Latest action" />
        </>
      }
      data={escalations}
      renderRow={(escalation, index) => {
        const isCancelled =
          escalation.latest_action_description === "cancelled";
        return (
          <TableRow
            key={escalation.id}
            isLastRow={index === escalations.length - 1}
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                // cmd+click
                const win = window.open(
                  prependSlugToPathIfNeeded(
                    `/on-call/escalations/${escalation.id}`,
                    slug || "",
                  ),
                  "_blank",
                );
                win?.focus();
              } else {
                navigate(`/on-call/escalations/${escalation.id}`);
              }
            }}
          >
            {/* Title */}
            <TableCellStacked
              className="gap-4"
              prefix={
                <EscalationStatusIcon
                  status={escalation.status as unknown as EscalationStatusEnum}
                />
              }
              primary={
                <>
                  <span className="text-sm font-semibold line-clamp-1 break-all text-wrap">
                    {!escalation.alert_id && escalation.description
                      ? escalation.description
                      : escalation.title}
                  </span>
                  <CopyDebugID id={escalation.id} />
                </>
              }
              secondary={<EscalationVia escalation={escalation} />}
            />
            {/* Incident */}
            <TableCell>
              {escalation.incident ? (
                <span className="text-sm font-medium line-clamp-1">
                  {escalation.incident.reference}
                </span>
              ) : (
                <span>―</span>
              )}
            </TableCell>
            {/* Path */}
            <TableCell>
              {escalation.escalation_path_name ? (
                <span className=" line-clamp-2">
                  {escalation.escalation_path_name}
                </span>
              ) : (
                <span>―</span>
              )}
            </TableCell>
            {/* Latest action */}
            <TableCellStacked
              primary={
                <span className="line-clamp-1">
                  {_.capitalize(escalation.latest_action_description)}{" "}
                  {!isCancelled ? (
                    <span className="text-slate-800 font-semibold">
                      {escalation.latest_action_actor?.user?.name}
                    </span>
                  ) : null}
                </span>
              }
              secondary={
                !isNaN(escalation.latest_action_at.getTime()) && (
                  <LocalRelativeDateTime
                    className="text-xs-med"
                    date={escalation.latest_action_at}
                  />
                )
              }
            />
          </TableRow>
        );
      }}
      infiniteScroll={{
        ref: infiniteScrollRef,
        isFullyLoaded,
        isLoading,
      }}
    />
  );
};

// Provide a subtext of "Via {source}" to distinguish between escalations
// created from alerts or otherwise.
const EscalationVia = ({ escalation }: { escalation: EscalationPreview }) => {
  const alert = escalation?.creator?.alert;
  const { alertSource } = useAlertSource(alert?.source_type);

  return (
    <>
      {escalation.alert_id && (
        <div className="flex gap-1">
          <span>Via </span>
          <span>{alertSource?.name ?? alert?.source_type}</span>
        </div>
      )}
      {escalation.creator?.user && (
        <span>Via {escalation.creator.user.name}</span>
      )}
      {escalation.creator?.workflow && (
        <span className="flex flex-row  gap-1">
          Via{" "}
          <OrgAwareLink
            to={`/workflows/${escalation.creator.workflow.id}`}
            className={
              "hover:text-content-primary hover:underline transition text-ellipsis"
            }
            onClick={(e) => e.stopPropagation()}
          >
            {escalation.creator.workflow.name}
          </OrgAwareLink>{" "}
          workflow
        </span>
      )}
    </>
  );
};
