/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Plan,
    PlanFromJSON,
    PlanFromJSONTyped,
    PlanToJSON,
} from './Plan';

/**
 * 
 * @export
 * @interface BillingListPlansResponseBody
 */
export interface BillingListPlansResponseBody {
    /**
     * 
     * @type {Array<Plan>}
     * @memberof BillingListPlansResponseBody
     */
    plans: Array<Plan>;
}

export function BillingListPlansResponseBodyFromJSON(json: any): BillingListPlansResponseBody {
    return BillingListPlansResponseBodyFromJSONTyped(json, false);
}

export function BillingListPlansResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingListPlansResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plans': ((json['plans'] as Array<any>).map(PlanFromJSON)),
    };
}

export function BillingListPlansResponseBodyToJSON(value?: BillingListPlansResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plans': ((value.plans as Array<any>).map(PlanToJSON)),
    };
}

