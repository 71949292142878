import { ScopeNameEnum, StatusPage } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import { ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";

import { GoLiveModal } from "./GoLiveModal";

export const GoLiveCTA = ({ page }: { page: StatusPage }) => {
  // This is for "finalise migration from Atlassian"
  const [showGoLive, setShowGoLive] = useState(false);

  const { hasScope } = useIdentity();

  if (page.went_live_at && !showGoLive) return null;

  return (
    <>
      {!page.went_live_at && (
        <HeaderBanner className="text-content-primary gap-4">
          <div className="text-sm space-y-2">
            <div className="font-medium">
              {page.mirroring_atlassian_page ? (
                <>
                  <Icon
                    id={IconEnum.AtlassianStatuspage}
                    className="inline mr-1 -mt-[2px]"
                  />
                  This page currently fetches its content from your Atlassian
                  Statuspage
                </>
              ) : (
                "Review your status page configuration to complete set-up"
              )}
            </div>
          </div>
          <GatedButton
            analyticsTrackingId={
              page.mirroring_atlassian_page
                ? "status-page-stop-mirroring"
                : "status-page-go-live"
            }
            icon={IconEnum.Sparkles}
            disabled={!hasScope(ScopeNameEnum.StatusPagesConfigure)}
            theme={ButtonTheme.Secondary}
            disabledTooltipContent={
              "You do not have permission to configure this status page"
            }
            onClick={() => setShowGoLive(true)}
            className="flex-none"
          >
            Go live
          </GatedButton>
        </HeaderBanner>
      )}
      {showGoLive && (
        <GoLiveModal page={page} onClose={() => setShowGoLive(false)} />
      )}
    </>
  );
};
