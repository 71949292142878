import {
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
  Txt,
} from "@incident-ui";
import { ReactElement } from "react";

export const getBulkExportTitle = (numFollowUpsToExport: number): string => {
  return `Export ${numFollowUpsToExport} follow-up${
    numFollowUpsToExport === 1 ? "" : "s"
  }`;
};

export const getBulkToastTitle = (
  numFollowUpsToExport: number,
  providerName: string,
): string => {
  return `${numFollowUpsToExport} follow-up${
    numFollowUpsToExport === 1 ? " was" : "s were"
  } successfully exported to ${providerName}`;
};

export const BulkExportBlurb = ({
  providerIssues,
  providerName,
}: {
  providerIssues: string;
  providerName: string;
}): ReactElement => {
  return (
    <Txt>
      Create {providerIssues} based on your selected follow-ups. Once exported,
      you must use {providerName} to change the status of these follow-ups.
      We&apos;ll automatically sync any status changes from {providerName} to
      us.
    </Txt>
  );
};

export const BulkExportTitleAndDescription = ({
  providerName,
}: {
  providerName: string;
}): ReactElement => {
  return (
    <>
      <Txt bold>Title &amp; description</Txt>
      <Txt inline grey>
        Titles in {providerName} will be set automatically based on follow-up
        titles. Descriptions will be left blank for you to complete in{" "}
        {providerName}.
      </Txt>
    </>
  );
};

export const BulkExportCallouts = ({
  numFollowUpsToExportFromPrivate,
  numFollowUpsAlreadyExported,
  accountName,
}: {
  numFollowUpsToExportFromPrivate: number;
  numFollowUpsAlreadyExported: number;
  accountName: string;
}): ReactElement => {
  return (
    <>
      {/* Exporting 1 private */}
      {numFollowUpsToExportFromPrivate === 1 && (
        <Callout theme={CalloutTheme.Warning}>
          You are exporting 1 follow-up for a private incident. This issue will
          be visible to anyone with access to your {accountName}.
        </Callout>
      )}
      {/* Exporting multiple private */}
      {numFollowUpsToExportFromPrivate > 1 && (
        <Callout theme={CalloutTheme.Warning}>
          You are exporting {numFollowUpsToExportFromPrivate} follow-ups for
          private incidents. These issues will be visible to anyone with access
          to your {accountName}.
        </Callout>
      )}
      {/* Ignoring 1 follow-up */}
      {numFollowUpsAlreadyExported === 1 && (
        <Callout theme={CalloutTheme.Plain}>
          You&apos;ve selected 1 follow-up that has already been exported to an
          issue tracker. No action will be performed on this follow-up.
        </Callout>
      )}
      {/* Ignoring multiple follow-ups */}
      {numFollowUpsAlreadyExported > 1 && (
        <Callout theme={CalloutTheme.Plain}>
          You&apos;ve selected {numFollowUpsAlreadyExported} follow-ups that
          have already been exported to an issue tracker. No action will be
          performed on these follow-ups.
        </Callout>
      )}
    </>
  );
};

export const NoFollowUpsToExportModal = ({
  numFollowUpsAlreadyExported,
  onClose,
}: {
  numFollowUpsAlreadyExported: number;
  onClose: () => void;
}): ReactElement => {
  return (
    <Modal
      isOpen={true}
      title="No follow-ups to export"
      analyticsTrackingId="bulk-add-to-linear-no-follow-ups"
      disableQuickClose
      onClose={onClose}
    >
      <ModalContent>
        <div className="space-y-4">
          <Txt>
            You have selected {numFollowUpsAlreadyExported} follow-up
            {numFollowUpsAlreadyExported === 1 ? "" : "s"} that have already
            been exported to an issue tracker.
          </Txt>
        </div>
      </ModalContent>
      <ModalFooter hideConfirmButton onClose={onClose} />
    </Modal>
  );
};
