/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';
import {
    StatusPageSubscription,
    StatusPageSubscriptionFromJSON,
    StatusPageSubscriptionFromJSONTyped,
    StatusPageSubscriptionToJSON,
} from './StatusPageSubscription';

/**
 * 
 * @export
 * @interface StatusPageListSubscriptionsResponseBody
 */
export interface StatusPageListSubscriptionsResponseBody {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof StatusPageListSubscriptionsResponseBody
     */
    pagination_meta: PaginationMeta;
    /**
     * 
     * @type {Array<StatusPageSubscription>}
     * @memberof StatusPageListSubscriptionsResponseBody
     */
    subscriptions: Array<StatusPageSubscription>;
}

export function StatusPageListSubscriptionsResponseBodyFromJSON(json: any): StatusPageListSubscriptionsResponseBody {
    return StatusPageListSubscriptionsResponseBodyFromJSONTyped(json, false);
}

export function StatusPageListSubscriptionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageListSubscriptionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination_meta': PaginationMetaFromJSON(json['pagination_meta']),
        'subscriptions': ((json['subscriptions'] as Array<any>).map(StatusPageSubscriptionFromJSON)),
    };
}

export function StatusPageListSubscriptionsResponseBodyToJSON(value?: StatusPageListSubscriptionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
        'subscriptions': ((value.subscriptions as Array<any>).map(StatusPageSubscriptionToJSON)),
    };
}

