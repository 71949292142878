import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";
import { AttachmentContent } from "./AttachmentContent";

export const ActivityItemIncidentAttachmentAdded = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_attachment_added) {
    throw new Error(
      "malformed timeline item: incident_attachment_added was missing incident_attachment_added field",
    );
  }

  let title =
    "Attached a " +
    item.content.incident_attachment_added.external_resource
      .resource_type_label;
  if (item.content.incident_attachment_added.creator.external_resource) {
    title +=
      " from a " +
      item.content.incident_attachment_added.creator.external_resource
        .resource_type_label;
  }

  return {
    title: title,
    icon: IconEnum.Attachment,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_attachment_added.creator,
    quotedContent: (
      <AttachmentContent
        resource={item.content.incident_attachment_added.external_resource}
      />
    ),
  };
};
