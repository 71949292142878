import type { SVGProps } from "react";
import * as React from "react";
const SvgSwitchHorizontal = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.06 8h6.19a.75.75 0 0 0 0-1.5H5.06l1.97-1.97a.75.75 0 1 0-1.06-1.061l-3.25 3.25a.75.75 0 0 0 0 1.062l3.25 3.25a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.061L5.06 8ZM14.03 8.97a.75.75 0 1 0-1.061 1.06l1.97 1.97h-6.19a.75.75 0 0 0 0 1.5h6.19l-1.97 1.97a.75.75 0 0 0 1.06 1.062l3.25-3.25a.75.75 0 0 0 0-1.061l-3.25-3.25V8.97Z"
    />
  </svg>
);
export default SvgSwitchHorizontal;
