import { LocalDateTime } from "@incident-ui";
import { differenceInDays } from "date-fns";

import { formatDurationShort } from "../../../../utils/datetime";

export const Timestamp = ({ ts }: { ts: Date }) => {
  const now = new Date();
  return (
    <>
      <LocalDateTime timestamp={ts} format="yyyy-MM-dd HH:mm" />
      {differenceInDays(now, ts) < 28 ? (
        <>
          {" "}
          &bull;{" "}
          {formatDurationShort(ts, now, {
            significantFigures: 1,
            suffix: "ago",
          })}
        </>
      ) : null}
    </>
  );
};
