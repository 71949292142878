/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnCallOnboardingTaskCompletionState,
    OnCallOnboardingTaskCompletionStateFromJSON,
    OnCallOnboardingTaskCompletionStateFromJSONTyped,
    OnCallOnboardingTaskCompletionStateToJSON,
} from './OnCallOnboardingTaskCompletionState';

/**
 * 
 * @export
 * @interface OnCallOnboardingTaskGroupState
 */
export interface OnCallOnboardingTaskGroupState {
    /**
     * Whether the user has completed onboarding
     * @type {boolean}
     * @memberof OnCallOnboardingTaskGroupState
     */
    onboarded: boolean;
    /**
     * The tasks the user has completed
     * @type {Array<OnCallOnboardingTaskCompletionState>}
     * @memberof OnCallOnboardingTaskGroupState
     */
    tasks: Array<OnCallOnboardingTaskCompletionState>;
}

export function OnCallOnboardingTaskGroupStateFromJSON(json: any): OnCallOnboardingTaskGroupState {
    return OnCallOnboardingTaskGroupStateFromJSONTyped(json, false);
}

export function OnCallOnboardingTaskGroupStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallOnboardingTaskGroupState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onboarded': json['onboarded'],
        'tasks': ((json['tasks'] as Array<any>).map(OnCallOnboardingTaskCompletionStateFromJSON)),
    };
}

export function OnCallOnboardingTaskGroupStateToJSON(value?: OnCallOnboardingTaskGroupState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onboarded': value.onboarded,
        'tasks': ((value.tasks as Array<any>).map(OnCallOnboardingTaskCompletionStateToJSON)),
    };
}

