import {
  ConditionBadge,
  ConditionBadgeTheme,
} from "@incident-shared/engine/conditions/ConditionBadge/ConditionBadge";
import { IconEnum } from "@incident-ui";
import React from "react";
import { Condition, ConditionGroup } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { SelectedCondition } from "./SelectedCondition";

const separatorClassName =
  "font-mono font-medium text-content-primary uppercase shrink-0";

export const CommaSeparatedConditions = ({
  conditions,
  theme,
  canBeEmpty,
  mini = false,
}: {
  conditions: Condition[];
  theme: ConditionBadgeTheme;
  canBeEmpty?: boolean;
  mini?: boolean;
}): React.ReactElement | null => {
  const sharedProps = {
    className: "align-middle",
    inline: true,
    theme,
    mini,
  };

  // If there are no items, show `...`
  if (conditions.length === 0) {
    if (canBeEmpty) {
      return null;
    }
    return (
      <ConditionBadge
        {...sharedProps}
        icon={IconEnum.Warning}
        subject={"You must select at least one requirement"}
        operation={""}
        criteria={""}
      />
    );
  }

  // If there's just one item, show it without `and`
  if (conditions.length === 1) {
    return <SelectedCondition condition={conditions[0]} {...sharedProps} />;
  }

  // Otherwise, join the items with commas and 'and'
  const commaSeparatedItems = conditions.slice(0, -1);
  const lastItem = conditions[conditions.length - 1];

  return (
    <>
      {commaSeparatedItems.map((condition, i) => (
        <span
          className="flex items-baseline max-w-full"
          key={condition.subject.reference}
        >
          <SelectedCondition key={i} condition={condition} {...sharedProps} />
          {i < commaSeparatedItems.length - 1 && (
            <span className={separatorClassName}>, </span>
          )}
        </span>
      ))}
      <span className={tcx(separatorClassName, "mx-2")}>and</span>
      <SelectedCondition condition={lastItem} {...sharedProps} />
    </>
  );
};

export const CommaSeparatedConditionGroups = ({
  groups,
  className,
  theme = "slate",
  canBeEmpty,
  mini = false,
}: {
  groups: ConditionGroup[];
  className?: string;
  theme?: ConditionBadgeTheme;
  canBeEmpty?: boolean;
  mini?: boolean;
}) => {
  return (
    <div className={tcx("flex-center-y flex-wrap gap-y-3", className)}>
      {groups.map((group, i) => (
        <>
          <CommaSeparatedConditions
            conditions={group.conditions}
            canBeEmpty={canBeEmpty}
            theme={theme}
            mini={mini}
          />
          {i < groups.length - 1 && (
            <span className={tcx(separatorClassName, "mx-2")}>OR</span>
          )}
        </>
      ))}
    </div>
  );
};
