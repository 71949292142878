/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    IncidentApplicableFields,
    IncidentApplicableFieldsFromJSON,
    IncidentApplicableFieldsFromJSONTyped,
    IncidentApplicableFieldsToJSON,
} from './IncidentApplicableFields';
import {
    IncidentChannelSettings,
    IncidentChannelSettingsFromJSON,
    IncidentChannelSettingsFromJSONTyped,
    IncidentChannelSettingsToJSON,
} from './IncidentChannelSettings';
import {
    SeverityAliasPayload,
    SeverityAliasPayloadFromJSON,
    SeverityAliasPayloadFromJSONTyped,
    SeverityAliasPayloadToJSON,
} from './SeverityAliasPayload';

/**
 * 
 * @export
 * @interface IncidentTypesCreateRequestBody
 */
export interface IncidentTypesCreateRequestBody {
    /**
     * 
     * @type {IncidentApplicableFields}
     * @memberof IncidentTypesCreateRequestBody
     */
    applicable_fields: IncidentApplicableFields;
    /**
     * Whether to automatically archive Slack channels for incidents of this type after a number of days
     * @type {boolean}
     * @memberof IncidentTypesCreateRequestBody
     */
    auto_archive_slack_channels: boolean;
    /**
     * Number of days to wait before automatically archiving Slack channels for incidents of this type
     * @type {number}
     * @memberof IncidentTypesCreateRequestBody
     */
    auto_archive_slack_channels_delay_days: number;
    /**
     * Whether to automatically close incidents of this type after a number of days
     * @type {boolean}
     * @memberof IncidentTypesCreateRequestBody
     */
    auto_close_incidents: boolean;
    /**
     * Number of days to wait before automatically closing incidents of this type
     * @type {number}
     * @memberof IncidentTypesCreateRequestBody
     */
    auto_close_incidents_delay_days: number;
    /**
     * What conditions must be true for this incident type to be available to a user
     * @type {Array<ConditionGroupPayload>}
     * @memberof IncidentTypesCreateRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * Whether incidents of this must always, or can optionally, be created in triage
     * @type {string}
     * @memberof IncidentTypesCreateRequestBody
     */
    create_in_triage: IncidentTypesCreateRequestBodyCreateInTriageEnum;
    /**
     * A postmortem destination to be used as the default
     * @type {string}
     * @memberof IncidentTypesCreateRequestBody
     */
    default_postmortem_destination_id?: string;
    /**
     * A postmortem template to be used as the default
     * @type {string}
     * @memberof IncidentTypesCreateRequestBody
     */
    default_postmortem_template_id?: string;
    /**
     * What is this incident type for?
     * @type {string}
     * @memberof IncidentTypesCreateRequestBody
     */
    description: string;
    /**
     * 
     * @type {IncidentChannelSettings}
     * @memberof IncidentTypesCreateRequestBody
     */
    incident_channel_name_override?: IncidentChannelSettings;
    /**
     * The name of this Incident Type
     * @type {string}
     * @memberof IncidentTypesCreateRequestBody
     */
    name: string;
    /**
     * Whether to override incident Slack channel auto-archive settings for this incident type
     * @type {boolean}
     * @memberof IncidentTypesCreateRequestBody
     */
    override_auto_archive_slack_channels: boolean;
    /**
     * Whether to override incident auto-close settings for this incident type
     * @type {boolean}
     * @memberof IncidentTypesCreateRequestBody
     */
    override_auto_close_incidents: boolean;
    /**
     * The name to be used in-place of the noun Postmortem
     * @type {string}
     * @memberof IncidentTypesCreateRequestBody
     */
    postmortem_name_override?: string;
    /**
     * Should all incidents created with this Incident Type be private?
     * @type {boolean}
     * @memberof IncidentTypesCreateRequestBody
     */
    private_incidents_only: boolean;
    /**
     * Aliases that are to be applied to severities for this incident type
     * @type {Array<SeverityAliasPayload>}
     * @memberof IncidentTypesCreateRequestBody
     */
    severity_aliases: Array<SeverityAliasPayload>;
}

/**
* @export
* @enum {string}
*/
export enum IncidentTypesCreateRequestBodyCreateInTriageEnum {
    Always = 'always',
    Optional = 'optional'
}

export function IncidentTypesCreateRequestBodyFromJSON(json: any): IncidentTypesCreateRequestBody {
    return IncidentTypesCreateRequestBodyFromJSONTyped(json, false);
}

export function IncidentTypesCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTypesCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicable_fields': IncidentApplicableFieldsFromJSON(json['applicable_fields']),
        'auto_archive_slack_channels': json['auto_archive_slack_channels'],
        'auto_archive_slack_channels_delay_days': json['auto_archive_slack_channels_delay_days'],
        'auto_close_incidents': json['auto_close_incidents'],
        'auto_close_incidents_delay_days': json['auto_close_incidents_delay_days'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'create_in_triage': json['create_in_triage'],
        'default_postmortem_destination_id': !exists(json, 'default_postmortem_destination_id') ? undefined : json['default_postmortem_destination_id'],
        'default_postmortem_template_id': !exists(json, 'default_postmortem_template_id') ? undefined : json['default_postmortem_template_id'],
        'description': json['description'],
        'incident_channel_name_override': !exists(json, 'incident_channel_name_override') ? undefined : IncidentChannelSettingsFromJSON(json['incident_channel_name_override']),
        'name': json['name'],
        'override_auto_archive_slack_channels': json['override_auto_archive_slack_channels'],
        'override_auto_close_incidents': json['override_auto_close_incidents'],
        'postmortem_name_override': !exists(json, 'postmortem_name_override') ? undefined : json['postmortem_name_override'],
        'private_incidents_only': json['private_incidents_only'],
        'severity_aliases': ((json['severity_aliases'] as Array<any>).map(SeverityAliasPayloadFromJSON)),
    };
}

export function IncidentTypesCreateRequestBodyToJSON(value?: IncidentTypesCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicable_fields': IncidentApplicableFieldsToJSON(value.applicable_fields),
        'auto_archive_slack_channels': value.auto_archive_slack_channels,
        'auto_archive_slack_channels_delay_days': value.auto_archive_slack_channels_delay_days,
        'auto_close_incidents': value.auto_close_incidents,
        'auto_close_incidents_delay_days': value.auto_close_incidents_delay_days,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'create_in_triage': value.create_in_triage,
        'default_postmortem_destination_id': value.default_postmortem_destination_id,
        'default_postmortem_template_id': value.default_postmortem_template_id,
        'description': value.description,
        'incident_channel_name_override': IncidentChannelSettingsToJSON(value.incident_channel_name_override),
        'name': value.name,
        'override_auto_archive_slack_channels': value.override_auto_archive_slack_channels,
        'override_auto_close_incidents': value.override_auto_close_incidents,
        'postmortem_name_override': value.postmortem_name_override,
        'private_incidents_only': value.private_incidents_only,
        'severity_aliases': ((value.severity_aliases as Array<any>).map(SeverityAliasPayloadToJSON)),
    };
}

