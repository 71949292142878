"use client";

import { StatusPageContentSummaryLocaleEnum as Locale } from "@incident-io/api";
import { createContext, useContext, useMemo } from "react";
import type { NamespaceKeys, NestedKeyOf } from "use-intl";
import { createTranslator } from "use-intl";

import enMessages from "./dictionaries/en-US";

// This is a much simplified version of `useTranslations` from `next-intl`.
//
// The issue with the library hook is that it throws when used outside of an
// `IntlProvider`, which means that we cannot use translation strings in places
// that are outside of a slug-specific layout (e.g. on an error or not-found
// page).
//
// This will increase our bundle size a bit (because we're always importing
// en-US messages), but our translation size is _reasonably_ small so the impact
// is tiny.
type IntlContextT = {
  locale: Locale;
  messages: IntlMessages;
};
const IntlContext = createContext<IntlContextT>({
  locale: Locale.EnUs,
  messages: enMessages,
});

export const TranslationsProvider = ({
  locale,
  messages,
  children,
}: IntlContextT & { children: React.ReactNode }) => (
  <IntlContext.Provider value={{ locale, messages }}>
    {children}
  </IntlContext.Provider>
);

export function useTranslations<
  NestedKey extends NamespaceKeys<
    IntlMessages,
    NestedKeyOf<IntlMessages>
  > = never,
>(namespace?: NestedKey) {
  const { locale, messages } = useContext(IntlContext);

  return useMemo(
    () => createTranslator({ messages, locale, namespace }),
    [messages, locale, namespace],
  );
}
