import type { SVGProps } from "react";
import * as React from "react";
const SvgHttp = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M3.416 7.234a.507.507 0 0 1 .506.506v2.026h1.352V7.74a.507.507 0 1 1 1.013 0v4.728a.506.506 0 1 1-1.013 0V10.78H3.922v1.688a.506.506 0 1 1-1.013 0V7.74a.507.507 0 0 1 .507-.506Zm3.375.506a.507.507 0 0 1 .507-.506h5.403a.507.507 0 0 1 0 1.013h-.676v4.22a.507.507 0 0 1-1.013 0v-4.22H8.986v4.22a.506.506 0 1 1-1.013 0v-4.22h-.675a.507.507 0 0 1-.507-.507Zm7.94.676v-.17h1.012c.187 0 .338.152.338.339v1.013a.338.338 0 0 1-.338.337H14.73V8.416Zm0 2.533h1.012a1.351 1.351 0 0 0 1.351-1.351V8.585a1.35 1.35 0 0 0-1.35-1.351h-1.352a.675.675 0 0 0-.675.675v4.56a.507.507 0 0 0 1.013 0v-1.52Z"
      clipRule="evenodd"
    />
    <path
      fill="#F25533"
      fillRule="evenodd"
      d="M8.079 3.322a6.227 6.227 0 0 0-2.493 1.555 6.343 6.343 0 0 0-.804 1.006H3.608a7.379 7.379 0 0 1 1.255-1.715A7.198 7.198 0 0 1 10 2c1.929 0 3.776.78 5.137 2.168.498.508.92 1.086 1.254 1.715h-1.174a6.35 6.35 0 0 0-.804-1.006 6.226 6.226 0 0 0-2.493-1.555c.167.237.327.502.477.791.261.507.496 1.1.682 1.77h-1.056a8.085 8.085 0 0 0-.527-1.305c-.286-.555-.595-.963-.879-1.224-.295-.272-.511-.34-.618-.34-.108 0-.324.068-.619.34-.284.261-.593.67-.88 1.224a8.121 8.121 0 0 0-.526 1.305H6.919a9.34 9.34 0 0 1 .683-1.77c.15-.29.31-.554.477-.79ZM3.542 13.988a7.38 7.38 0 0 0 1.32 1.845A7.197 7.197 0 0 0 10.002 18c1.929 0 3.776-.78 5.137-2.168a7.383 7.383 0 0 0 1.32-1.844H15.3c-.252.41-.549.791-.886 1.135a6.227 6.227 0 0 1-2.493 1.555c.168-.237.328-.502.477-.791.279-.54.526-1.176.718-1.899h-1.052a8.201 8.201 0 0 1-.566 1.434c-.286.555-.595.962-.88 1.224-.294.272-.51.34-.617.34-.108 0-.325-.068-.62-.34-.283-.261-.593-.67-.879-1.224a8.2 8.2 0 0 1-.566-1.434H6.884c.193.723.44 1.36.718 1.899.15.29.31.554.477.79a6.226 6.226 0 0 1-2.493-1.554 6.354 6.354 0 0 1-.885-1.135H3.542Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHttp;
