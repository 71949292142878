import {
  Condition,
  ConditionGroup,
  EngineParamBinding,
} from "@incident-io/api/models";
import { isEmpty } from "lodash";

export const conditionGroupsMatch = (
  left: ConditionGroup[],
  right: ConditionGroup[],
): boolean => {
  // Build a 'hash' that we can use to compare L and R semantically.
  const leftStr = left.map(conditionGroupToConsistentStr).sort().join("_OR_");
  const rightStr = right.map(conditionGroupToConsistentStr).sort().join("_OR_");

  return leftStr === rightStr;
};

const conditionGroupToConsistentStr = (group: ConditionGroup): string => {
  const parts = group.conditions?.map(conditionToConsistentStr);

  return parts.sort().join("_AND_");
};

const conditionToConsistentStr = (condition: Condition): string => {
  const parts = [condition.subject.reference, condition.operation.value];

  if (!isEmpty(condition.param_bindings)) {
    const bindings = condition.param_bindings.map(bindingToConsistentStr);
    parts.push(...bindings);
  }

  return parts.join("|");
};

const bindingToConsistentStr = (binding: EngineParamBinding): string => {
  const parts: string[] = [];
  if (binding.value) {
    parts.push(binding.value.value as string);
  }
  if (!isEmpty(binding.array_value)) {
    binding.array_value?.forEach((val) => {
      parts.push(val.value as string);
    });
  }

  return parts.sort().join(",");
};
