import "./styles.css";

import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";

import responseGetStartedGraphic from "./response-get-started-image.png";

export const ResponseGetStartedPage = ({
  title,
  icon,
}: {
  title: string;
  icon: IconEnum;
}) => {
  const primaryCommsPlatform = usePrimaryCommsPlatform();

  return (
    <PageWrapper icon={icon} title={title} width={PageWidth.Full}>
      <div className="h-full flex-1 flex flex-col items-center w-full custom-gradient-bg rounded-xl">
        <div className="grow"></div>
        <div className="relative flex items-center justify-center w-[440px] h-[298px]">
          <div className="gradient-circle circle-1"></div>
          <div className="gradient-circle circle-2"></div>
          <div className="gradient-circle circle-3"></div>
          <div className="gradient-circle circle-4"></div>
          <div className="gradient-circle circle-5"></div>
          <img
            src={responseGetStartedGraphic}
            alt="Your Image"
            className="relative z-10"
          />
        </div>
        <div className="text-alarmalade-800 text-2xl-bold text-center mt-10 z-10">
          Level up your incident response
        </div>
        <div className="text-alarmalade-700 text-base-bold text-center mt-2 z-10">
          Focus on fixing the problem with powerful
          <br /> automations and insights, supercharged by AI.
        </div>
        <div className="flex mt-6 space-x-2">
          <Button
            analyticsTrackingId="request-access-response"
            theme={ButtonTheme.Primary}
            href="/settings/billing"
          >
            Request access
          </Button>
          <Button
            analyticsTrackingId="learn-more-response"
            theme={ButtonTheme.Secondary}
            href={
              primaryCommsPlatform === CommsPlatform.Slack
                ? "https://incident.io/respond-slack"
                : "https://incident.io/respond-teams"
            }
          >
            <div className="text-sm-med">Learn more</div>
          </Button>
        </div>
        <div className="grow"></div>
      </div>
    </PageWrapper>
  );
};
