/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageAutoCreateCatalogConfiguration,
    StatusPageAutoCreateCatalogConfigurationFromJSON,
    StatusPageAutoCreateCatalogConfigurationFromJSONTyped,
    StatusPageAutoCreateCatalogConfigurationToJSON,
} from './StatusPageAutoCreateCatalogConfiguration';
import {
    StatusPageSubPagePayload,
    StatusPageSubPagePayloadFromJSON,
    StatusPageSubPagePayloadFromJSONTyped,
    StatusPageSubPagePayloadToJSON,
} from './StatusPageSubPagePayload';

/**
 * 
 * @export
 * @interface StatusPageParentPageOptions
 */
export interface StatusPageParentPageOptions {
    /**
     * 
     * @type {StatusPageAutoCreateCatalogConfiguration}
     * @memberof StatusPageParentPageOptions
     */
    auto_create_catalog_configuration?: StatusPageAutoCreateCatalogConfiguration;
    /**
     * The attribute on the component type that should be used for email whitelisting
     * @type {string}
     * @memberof StatusPageParentPageOptions
     */
    email_whitelist_defined_by_attribute_id?: string;
    /**
     * The attribute on the component type that should be used for grouping
     * @type {string}
     * @memberof StatusPageParentPageOptions
     */
    group_by_catalog_attribute_id?: string;
    /**
     * The type of page, which defines how it is used
     * @type {string}
     * @memberof StatusPageParentPageOptions
     */
    page_type: StatusPageParentPageOptionsPageTypeEnum;
    /**
     * If this is a parent page, the catalog type that should define the possible subpages that sit under it.
     * @type {string}
     * @memberof StatusPageParentPageOptions
     */
    split_by_catalog_type_id?: string;
    /**
     * If this is a parent page, this defines the components visible on each sub page.
     * @type {string}
     * @memberof StatusPageParentPageOptions
     */
    split_by_component_attribute_id?: string;
    /**
     * 
     * @type {Array<StatusPageSubPagePayload>}
     * @memberof StatusPageParentPageOptions
     */
    sub_pages?: Array<StatusPageSubPagePayload>;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageParentPageOptionsPageTypeEnum {
    Parent = 'parent',
    Customer = 'customer'
}

export function StatusPageParentPageOptionsFromJSON(json: any): StatusPageParentPageOptions {
    return StatusPageParentPageOptionsFromJSONTyped(json, false);
}

export function StatusPageParentPageOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageParentPageOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_create_catalog_configuration': !exists(json, 'auto_create_catalog_configuration') ? undefined : StatusPageAutoCreateCatalogConfigurationFromJSON(json['auto_create_catalog_configuration']),
        'email_whitelist_defined_by_attribute_id': !exists(json, 'email_whitelist_defined_by_attribute_id') ? undefined : json['email_whitelist_defined_by_attribute_id'],
        'group_by_catalog_attribute_id': !exists(json, 'group_by_catalog_attribute_id') ? undefined : json['group_by_catalog_attribute_id'],
        'page_type': json['page_type'],
        'split_by_catalog_type_id': !exists(json, 'split_by_catalog_type_id') ? undefined : json['split_by_catalog_type_id'],
        'split_by_component_attribute_id': !exists(json, 'split_by_component_attribute_id') ? undefined : json['split_by_component_attribute_id'],
        'sub_pages': !exists(json, 'sub_pages') ? undefined : ((json['sub_pages'] as Array<any>).map(StatusPageSubPagePayloadFromJSON)),
    };
}

export function StatusPageParentPageOptionsToJSON(value?: StatusPageParentPageOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_create_catalog_configuration': StatusPageAutoCreateCatalogConfigurationToJSON(value.auto_create_catalog_configuration),
        'email_whitelist_defined_by_attribute_id': value.email_whitelist_defined_by_attribute_id,
        'group_by_catalog_attribute_id': value.group_by_catalog_attribute_id,
        'page_type': value.page_type,
        'split_by_catalog_type_id': value.split_by_catalog_type_id,
        'split_by_component_attribute_id': value.split_by_component_attribute_id,
        'sub_pages': value.sub_pages === undefined ? undefined : ((value.sub_pages as Array<any>).map(StatusPageSubPagePayloadToJSON)),
    };
}

