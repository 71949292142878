import {
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentStatusSummaryWorstComponentStatusEnum,
  StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum,
} from "@incident-io/api";
import cx from "classnames";

import { assertUnreachable } from "../../helpers";
import { useTranslations } from "../../use-translations";
import { Tooltip } from "../Tooltip";

type ComponentStatusIconProps = {
  className?: string;
  flavour?: "ok" | "yellow" | "orange" | "alarmalade" | "blue";
  componentStatus?:
    | StatusPageContentComponentImpactStatusEnum
    | StatusPageAffectedComponentStatusEnum
    | StatusPageContentStatusSummaryWorstComponentStatusEnum
    | StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum
    | "ok";
  tooltip?: boolean;
};

// Contextual icon depending on the state:
// - ok: green tick in circle
// - yellow/orange: exclaimation point in triangle
// - alarmalade: same as above, but alarmalade
// - blue: wrench icon for maintenance
export const ComponentStatusIcon = ({
  className,
  flavour = "ok",
  componentStatus,
  tooltip = false,
}: ComponentStatusIconProps): React.ReactElement => {
  if (componentStatus) {
    switch (componentStatus) {
      case StatusPageContentComponentImpactStatusEnum.PartialOutage:
      case StatusPageAffectedComponentStatusEnum.PartialOutage:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.PartialOutage:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.PartialOutage:
        flavour = "orange";
        break;

      case StatusPageContentComponentImpactStatusEnum.DegradedPerformance:
      case StatusPageAffectedComponentStatusEnum.DegradedPerformance:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.DegradedPerformance:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.DegradedPerformance:
        flavour = "yellow";
        break;

      case StatusPageContentComponentImpactStatusEnum.FullOutage:
      case StatusPageAffectedComponentStatusEnum.FullOutage:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.FullOutage:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.FullOutage:
        flavour = "alarmalade";
        break;

      case StatusPageContentComponentImpactStatusEnum.UnderMaintenance:
      case StatusPageAffectedComponentStatusEnum.UnderMaintenance:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.UnderMaintenance:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.UnderMaintenance:
        flavour = "blue";
        break;

      case StatusPageAffectedComponentStatusEnum.Operational:
      case StatusPageContentStatusSummaryWorstComponentStatusEnum.Operational:
      case StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum.Operational:
      case "ok":
        flavour = "ok";
        break;

      default:
        assertUnreachable(componentStatus);
    }
  }

  let Icon: React.FC<{ className?: string }> | undefined = undefined;

  if (flavour === "ok") {
    Icon = OperationalIcon;
  }
  if (flavour === "yellow") {
    Icon = DegradedPerformanceIcon;
  }
  if (flavour === "orange") {
    Icon = PartialOutageIcon;
  }
  if (flavour === "alarmalade") {
    Icon = FullOutageIcon;
  }
  if (flavour === "blue") {
    Icon = MaintenanceIcon;
  }

  const t = useTranslations("Icons");
  if (!Icon) {
    throw new Error(`unreachable: ${componentStatus}`);
  }

  return tooltip ? (
    <Tooltip content={t(flavour)}>
      <Icon className={className} />
    </Tooltip>
  ) : (
    <Icon className={className} />
  );
};

export const OperationalIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, "text-icon-operational")}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.5C6.27609 1.5 4.62279 2.18482 3.40381 3.40381C2.18482 4.62279 1.5 6.27609 1.5 8C1.5 8.85359 1.66813 9.69883 1.99478 10.4874C2.32144 11.2761 2.80022 11.9926 3.40381 12.5962C4.00739 13.1998 4.72394 13.6786 5.51256 14.0052C6.30117 14.3319 7.14641 14.5 8 14.5C8.85359 14.5 9.69883 14.3319 10.4874 14.0052C11.2761 13.6786 11.9926 13.1998 12.5962 12.5962C13.1998 11.9926 13.6786 11.2761 14.0052 10.4874C14.3319 9.69883 14.5 8.85359 14.5 8C14.5 6.27609 13.8152 4.62279 12.5962 3.40381C11.3772 2.18482 9.72391 1.5 8 1.5ZM2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.05058 15.7931 10.0909 15.391 11.0615C14.989 12.0321 14.3997 12.914 13.6569 13.6569C12.914 14.3997 12.0321 14.989 11.0615 15.391C10.0909 15.7931 9.05058 16 8 16C6.94943 16 5.90914 15.7931 4.93853 15.391C3.96793 14.989 3.08601 14.3997 2.34315 13.6569C1.60028 12.914 1.011 12.0321 0.608964 11.0615C0.206926 10.0909 0 9.05058 0 8C5.96046e-08 5.87827 0.842855 3.84344 2.34315 2.34315ZM10.947 5.85856C11.2399 6.15145 11.2399 6.62633 10.947 6.91922L7.72477 10.1414C7.43188 10.4343 6.95701 10.4343 6.66411 10.1414L5.053 8.53033C4.76011 8.23744 4.76011 7.76256 5.053 7.46967C5.3459 7.17678 5.82077 7.17678 6.11366 7.46967L7.19444 8.55045L9.88634 5.85856C10.1792 5.56567 10.6541 5.56567 10.947 5.85856Z"
      fill="currentColor"
    />
  </svg>
);

export const DegradedPerformanceIcon = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    className={cx(className, "text-icon-degraded")}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13422 2.19165C8.67333 1.27161 7.36032 1.27141 6.89915 2.19124L6.89914 2.19125L1.63614 12.6883L0.965694 12.3521L1.63614 12.6883C1.2194 13.5194 1.82373 14.4985 2.75356 14.4985H13.2748C14.2044 14.4985 14.8087 13.5198 14.3924 12.6887L14.3924 12.6887L9.13423 2.19166L9.13422 2.19165ZM5.55824 1.51894C6.57287 -0.504757 9.46145 -0.504181 10.4754 1.51983L10.4754 1.51984L15.7336 12.0169L15.7336 12.0169C16.6495 13.8454 15.32 15.9985 13.2748 15.9985H2.75356C0.707937 15.9985 -0.621611 13.8446 0.295244 12.016L5.55824 1.51895L5.55824 1.51894ZM8.01342 4.99851C8.56571 4.99851 9.01342 5.44623 9.01342 5.99851V7.99851C9.01342 8.5508 8.56571 8.99851 8.01342 8.99851C7.46114 8.99851 7.01342 8.5508 7.01342 7.99851V5.99851C7.01342 5.44623 7.46114 4.99851 8.01342 4.99851ZM9.01342 11.9985C9.01342 12.5508 8.56571 12.9985 8.01342 12.9985C7.46114 12.9985 7.01342 12.5508 7.01342 11.9985C7.01342 11.4462 7.46114 10.9985 8.01342 10.9985C8.56571 10.9985 9.01342 11.4462 9.01342 11.9985Z"
      fill="currentColor"
    />
  </svg>
);

export const MaintenanceIcon = ({ className }: { className?: string }) => (
  <svg
    className={cx(className, "text-icon-maintenance")}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7145 4.94966C10.324 4.55914 10.324 3.92597 10.7145 3.53545L13.0848 1.1652C12.5294 0.898978 11.9072 0.749878 11.2501 0.749878C8.90295 0.749878 7.00015 2.65267 7.00015 4.99988C7.00015 5.65693 7.14925 6.27918 7.41545 6.83458L1.41422 12.8358C0.861928 13.3881 0.861928 14.2836 1.41422 14.8358C1.9665 15.3881 2.86193 15.3881 3.41422 14.8358L9.41545 8.83458C9.97085 9.10078 10.5931 9.24988 11.2501 9.24988C13.5973 9.24988 15.5001 7.34708 15.5001 4.99988C15.5001 4.35115 15.3548 3.73637 15.0949 3.18633L12.7301 5.55104C12.3396 5.94157 11.7064 5.94157 11.3159 5.55105L10.7145 4.94966Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PartialOutageIcon = ({ className }: { className?: string }) => (
  <svg
    className={cx(className, "text-icon-partial-outage")}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13422 2.19165C8.67333 1.27161 7.36032 1.27141 6.89915 2.19124L6.89914 2.19125L1.63614 12.6883L0.965694 12.3521L1.63614 12.6883C1.2194 13.5194 1.82373 14.4985 2.75356 14.4985H13.2748C14.2044 14.4985 14.8087 13.5198 14.3924 12.6887L14.3924 12.6887L9.13423 2.19166L9.13422 2.19165ZM5.55824 1.51894C6.57287 -0.504757 9.46145 -0.504181 10.4754 1.51983L10.4754 1.51984L15.7336 12.0169L15.7336 12.0169C16.6495 13.8454 15.32 15.9985 13.2748 15.9985H2.75356C0.707937 15.9985 -0.621611 13.8446 0.295244 12.016L5.55824 1.51895L5.55824 1.51894ZM8.01342 4.99851C8.56571 4.99851 9.01342 5.44623 9.01342 5.99851V7.99851C9.01342 8.5508 8.56571 8.99851 8.01342 8.99851C7.46114 8.99851 7.01342 8.5508 7.01342 7.99851V5.99851C7.01342 5.44623 7.46114 4.99851 8.01342 4.99851ZM9.01342 11.9985C9.01342 12.5508 8.56571 12.9985 8.01342 12.9985C7.46114 12.9985 7.01342 12.5508 7.01342 11.9985C7.01342 11.4462 7.46114 10.9985 8.01342 10.9985C8.56571 10.9985 9.01342 11.4462 9.01342 11.9985Z"
      fill="currentColor"
    />
  </svg>
);

export const FullOutageIcon = ({ className }: { className?: string }) => (
  <svg
    className={cx(className, "text-icon-full-outage")}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13422 2.19165C8.67333 1.27161 7.36032 1.27141 6.89915 2.19124L6.89914 2.19125L1.63614 12.6883L0.965694 12.3521L1.63614 12.6883C1.2194 13.5194 1.82373 14.4985 2.75356 14.4985H13.2748C14.2044 14.4985 14.8087 13.5198 14.3924 12.6887L14.3924 12.6887L9.13423 2.19166L9.13422 2.19165ZM5.55824 1.51894C6.57287 -0.504757 9.46145 -0.504181 10.4754 1.51983L10.4754 1.51984L15.7336 12.0169L15.7336 12.0169C16.6495 13.8454 15.32 15.9985 13.2748 15.9985H2.75356C0.707937 15.9985 -0.621611 13.8446 0.295244 12.016L5.55824 1.51895L5.55824 1.51894ZM8.01342 4.99851C8.56571 4.99851 9.01342 5.44623 9.01342 5.99851V7.99851C9.01342 8.5508 8.56571 8.99851 8.01342 8.99851C7.46114 8.99851 7.01342 8.5508 7.01342 7.99851V5.99851C7.01342 5.44623 7.46114 4.99851 8.01342 4.99851ZM9.01342 11.9985C9.01342 12.5508 8.56571 12.9985 8.01342 12.9985C7.46114 12.9985 7.01342 12.5508 7.01342 11.9985C7.01342 11.4462 7.46114 10.9985 8.01342 10.9985C8.56571 10.9985 9.01342 11.4462 9.01342 11.9985Z"
      fill="currentColor"
    />
  </svg>
);
