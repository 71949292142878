import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { SharedToasts } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import {
  IntegrationSettingsProviderEnum,
  PostmortemSettings,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SettingsSection } from "../../SettingsSection";

type FormType = {
  id?: string;
  enabled: boolean;
};

const marshallToFormType = (settings: PostmortemSettings): FormType => ({
  id: settings.test_or_tutorial_destination_id,
  enabled: !!settings.test_or_tutorial_destination_id,
});

export const PostmortemDestinationTestOrTutorialForm = ({
  postmortemSettings,
}: {
  postmortemSettings: PostmortemSettings;
}): React.ReactElement | null => {
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const {
    data: { destinations },
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });

  const formMethods = useForm<FormType>({
    defaultValues: marshallToFormType(postmortemSettings),
  });
  const { setError, reset } = formMethods;

  const mutation = useAPIMutation(
    "postmortemsSettingsShow",
    undefined,
    async (apiClient, data: FormType) =>
      await apiClient.postmortemsSettingsUpdateDefaultTestOrTutorialDestination(
        {
          updateDefaultTestOrTutorialDestinationRequestBody: {
            test_or_tutorial_destination_id: data.enabled ? data.id : undefined,
          },
        },
      ),
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(marshallToFormType(settings));
      },
      setError,
    },
  );

  const postmortemDestinationOptions = destinations.map(
    (destination): SelectOption => {
      const config = IntegrationConfigFor(
        destination.document_provider as unknown as IntegrationSettingsProviderEnum,
      );

      return {
        label: destination.name,
        value: destination.id,
        icon: config.icon,
      };
    },
  );

  if (destinations.length === 0) {
    return null;
  }

  return (
    <SettingsSection
      formMethods={formMethods}
      mutation={mutation}
      enabledPath="enabled"
      title="Use a specific destination for tests and tutorials"
      titleHeadingLevel={3}
      explanation={
        <>
          Use this to route post-mortems from test and tutorial incidents to a
          particular destination, so they don&apos;t get confused with the real
          ones!
        </>
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="id"
        placeholder="Select a destination"
        options={postmortemDestinationOptions}
        disabled={!canEditSettings}
      />
    </SettingsSection>
  );
};
