import { ScheduleLayer, ScheduleRotation } from "@incident-io/api";
import { ScheduleEntryComponent } from "@incident-shared/schedules/ScheduleOverview/ScheduleEntryComponent";
import { ScheduleEntryOrOverride } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import { flattenScheduleEntries } from "@incident-shared/schedules/ScheduleOverview/utils/flattenScheduleEntries";
import { getCalendarDays } from "@incident-shared/schedules/ScheduleOverview/utils/getCalendarDays";
import { useKeyboardEvents } from "@incident-shared/schedules/useKeyboardEvents";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { motion } from "framer-motion";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { tcx } from "../../../../utils/tailwind-classes";

export const CalendarSection = ({
  now,
  timelineStartPoint,
  rotations,
  entries,
  timezone,
}: {
  timezone: string;
  entries: ScheduleEntryOrOverride[];
  rotations: (ScheduleRotation & { isDraft?: boolean })[];
  isLoadingEntries: boolean;
  now: DateTime;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
}) => {
  const [expandedRowIndexes, setExpandedRowIndexes] = useState<number[]>([]);

  // We're going to calculate the days for the current month,
  // plus any days in the previous month and next month that'll be visible (but faded out)
  const calendarDays: DateTime[] = getCalendarDays(
    timelineStartPoint,
    timezone,
  );

  useKeyboardEvents(
    useCallback(
      (e) => {
        if (e.key === "ArrowDown") {
          setExpandedRowIndexes(Array.from({ length: 10 }, (_, i) => i));
        } else if (e.key === "ArrowUp") {
          setExpandedRowIndexes([]);
        }
      },
      [setExpandedRowIndexes],
    ),
  );

  return (
    <div className={"flex flex-col w-full h-full"}>
      <div className={"grid grid-cols-7"}>
        {/* Render the days of the week */}
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div
            key={day}
            className={tcx(
              "text-xs text-content-tertiary text-center h-8 justify-center items-center flex first:bg-surface-secondary last:bg-surface-secondary first:bg-opacity-40 last:bg-opacity-40",
              {
                "border-r border-stroke-secondary": true,
              },
            )}
          >
            {day}
          </div>
        ))}
      </div>

      <CalendarGrid
        calendarDays={calendarDays}
        now={now}
        timezone={timezone}
        entries={entries}
        rotations={rotations}
        timelineStartPoint={timelineStartPoint}
        expandedRowIndexes={expandedRowIndexes}
        setExpandedRowIndexes={setExpandedRowIndexes}
      />
    </div>
  );
};

const CalendarGrid = ({
  calendarDays,
  now,
  entries,
  timelineStartPoint,
  timezone,
  rotations,
  setExpandedRowIndexes,
  expandedRowIndexes,
}: {
  timezone: string;
  rotations: (ScheduleRotation & { isDraft?: boolean })[];
  calendarDays: DateTime[];
  now: DateTime;
  entries: ScheduleEntryOrOverride[];
  timelineStartPoint: DateTime;
  expandedRowIndexes: number[];
  setExpandedRowIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <div ref={ref} className={"flex-1 grid grid-cols-7 h-full"}>
      {/* Render the days of the month */}
      {calendarDays.map((day, i) => {
        return (
          <CalendarDay
            rotations={rotations}
            now={now}
            key={day.toISO()}
            day={day}
            entries={entries}
            timezone={timezone}
            timelineStartPoint={timelineStartPoint}
            rowIndex={Math.floor(i / 7)}
            isFirstColum={day.weekday === 7}
            setExpandedRowIndexes={setExpandedRowIndexes}
            expandedRowIndexes={expandedRowIndexes}
          />
        );
      })}
    </div>
  );
};

const CalendarDay = ({
  day,
  entries,
  rotations,
  now,
  isFirstColum,
  timezone,
  rowIndex,
  expandedRowIndexes,
  setExpandedRowIndexes,
}: {
  timezone: string;
  rotations: (ScheduleRotation & { isDraft?: boolean })[];
  now: DateTime;
  day: DateTime;
  entries: ScheduleEntryOrOverride[];
  timelineStartPoint: DateTime;
  isFirstColum: boolean;
  rowIndex: number;
  expandedRowIndexes: number[];
  setExpandedRowIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isWeekend = day.weekday === 6 || day.weekday === 7;

  const isToday = day.day === now.day && day.month === now.month;

  const entriesForDay = useMemo(() => {
    const endOfToday = day.endOf("day");

    const uniqueLayers: ScheduleLayer[] = _.chain(rotations)
      .flatMap((r) => r.layers)
      .uniqBy((l) => l.id)
      .value();

    return _.chain(uniqueLayers)
      .flatMap((l) => {
        return flattenScheduleEntries(entries)
          .filter((e) => {
            if (e.layer_id !== l.id) {
              return false;
            }

            const entryStart = DateTime.fromJSDate(e.start_at);
            const entryEnd = DateTime.fromJSDate(e.end_at);

            return entryStart <= endOfToday && entryEnd >= day;
          })
          .map((e) => {
            const entryStart = DateTime.fromJSDate(e.start_at);
            const entryEnd = DateTime.fromJSDate(e.end_at);
            const start = entryStart < day ? day : entryStart;
            const end = entryEnd > endOfToday ? endOfToday : entryEnd;
            return {
              ...e,
              start_at: start.toJSDate(),
              end_at: end.toJSDate(),
            };
          });
      })
      .sortBy((e) => e.start_at)
      .value();
  }, [rotations, entries, day]);

  const isCollapsed = !expandedRowIndexes.includes(rowIndex);
  const entriesToShow = isCollapsed ? entriesForDay.slice(0, 3) : entriesForDay;
  const canExpand = entriesForDay.length > 3;

  return (
    <div
      ref={ref}
      className={tcx("p-1 flex flex-col box-border gap-y-1", {
        "bg-surface-secondary bg-opacity-40": isWeekend,
        // Render the grid lines for the calendar
        "border-r border-b border-stroke-secondary": true,
        "border-l": isFirstColum,
        "border-t border-t-stroke": rowIndex === 0,
      })}
    >
      {/* Render the day of the month */}
      <div
        className={tcx(
          "self-center flex items-center justify-center h-6",
          "text-sm font-medium text-content-tertiary",
          {
            "bg-red-500 rounded-md text-white p-1 w-6": isToday,
            // Show the day in a lighter color if it's in the past
            "!text-slate-200": day < now.startOf("day"),
          },
        )}
      >
        {day.day}
      </div>
      <div className={"flex flex-col gap-y-1 w-full min-h-[72px]"}>
        {entriesToShow.map((entry) => {
          const userId =
            "user_id" in entry ? entry.user_id : entry.external_user_id;

          // don't show entries < 60s long
          if (entry.end_at.getTime() - entry.start_at.getTime() < 60000) {
            return null;
          }

          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              key={`${userId}-${entry.start_at}-${entry.end_at.toISOString()}-${
                entry.layer_id
              }`}
            >
              <ScheduleEntryComponent
                appearanceMode={"calendar"}
                height={"24px"}
                entry={entry}
                width={"100%"}
                userId={userId}
                enableTooltip
                roundedLeft
                enableCreateOverride
                roundedRight
                timezone={timezone}
              />
            </motion.div>
          );
        })}
      </div>
      {canExpand && (
        <Button
          analyticsTrackingId={"show-more-calendar-view"}
          onClick={() => {
            if (isCollapsed) {
              setExpandedRowIndexes((prev) => [...prev, rowIndex]);
            } else {
              setExpandedRowIndexes((prev) =>
                prev.filter((i) => i !== rowIndex),
              );
            }
          }}
          className={tcx(
            "w-full overflow-hidden text-content-secondary hover:text-content-primary text-xs p-1",
          )}
          theme={ButtonTheme.Unstyled}
        >
          <span className={"text-nowrap overflow-visible"}>
            {isCollapsed ? `Show ${entriesForDay.length - 3} more` : "Hide"}
          </span>
          <Icon
            id={isCollapsed ? IconEnum.Expand : IconEnum.Collapse}
            size={IconSize.Small}
          />
        </Button>
      )}
    </div>
  );
};
