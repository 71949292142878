/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimestampSetByRule,
    IncidentTimestampSetByRuleFromJSON,
    IncidentTimestampSetByRuleFromJSONTyped,
    IncidentTimestampSetByRuleToJSON,
} from './IncidentTimestampSetByRule';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface IncidentTimestamp
 */
export interface IncidentTimestamp {
    /**
     * 
     * @type {Date}
     * @memberof IncidentTimestamp
     */
    created_at: Date;
    /**
     * 
     * @type {TextNode}
     * @memberof IncidentTimestamp
     */
    description: TextNode;
    /**
     * Unique ID of this incident timestamp
     * @type {string}
     * @memberof IncidentTimestamp
     */
    id: string;
    /**
     * Unique name of this timestamp
     * @type {string}
     * @memberof IncidentTimestamp
     */
    name: string;
    /**
     * Order in which this timestamp should be shown
     * @type {number}
     * @memberof IncidentTimestamp
     */
    rank: number;
    /**
     * Rules that are used to auto-set this timestamp
     * @type {Array<IncidentTimestampSetByRule>}
     * @memberof IncidentTimestamp
     */
    set_by_rules: Array<IncidentTimestampSetByRule>;
    /**
     * Whether this incident timestamp should be set on incident acceptance.
     * @type {boolean}
     * @memberof IncidentTimestamp
     */
    set_on_acceptance: boolean;
    /**
     * Whether this incident timestamp should be set on incident creation.
     * @type {boolean}
     * @memberof IncidentTimestamp
     */
    set_on_creation: boolean;
    /**
     * Whether this incident timestamp should be set on incident resolution.
     * @type {boolean}
     * @memberof IncidentTimestamp
     */
    set_on_resolution: boolean;
    /**
     * The transition that this incident timestamp should be set on.
     * @type {string}
     * @memberof IncidentTimestamp
     */
    set_on_transition?: IncidentTimestampSetOnTransitionEnum;
    /**
     * The visit that this incident timestamp should be set on.
     * @type {string}
     * @memberof IncidentTimestamp
     */
    set_on_visit?: IncidentTimestampSetOnVisitEnum;
    /**
     * What type of timestamp this is
     * @type {string}
     * @memberof IncidentTimestamp
     */
    timestamp_type: IncidentTimestampTimestampTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof IncidentTimestamp
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum IncidentTimestampSetOnTransitionEnum {
    Enter = 'enter',
    Leave = 'leave'
}/**
* @export
* @enum {string}
*/
export enum IncidentTimestampSetOnVisitEnum {
    First = 'first',
    Last = 'last'
}/**
* @export
* @enum {string}
*/
export enum IncidentTimestampTimestampTypeEnum {
    ReportedAt = 'reported_at',
    AcceptedAt = 'accepted_at',
    DeclinedAt = 'declined_at',
    CanceledAt = 'canceled_at',
    MergedAt = 'merged_at',
    ResolvedAt = 'resolved_at',
    ClosedAt = 'closed_at',
    Custom = 'custom'
}

export function IncidentTimestampFromJSON(json: any): IncidentTimestamp {
    return IncidentTimestampFromJSONTyped(json, false);
}

export function IncidentTimestampFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestamp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'description': TextNodeFromJSON(json['description']),
        'id': json['id'],
        'name': json['name'],
        'rank': json['rank'],
        'set_by_rules': ((json['set_by_rules'] as Array<any>).map(IncidentTimestampSetByRuleFromJSON)),
        'set_on_acceptance': json['set_on_acceptance'],
        'set_on_creation': json['set_on_creation'],
        'set_on_resolution': json['set_on_resolution'],
        'set_on_transition': !exists(json, 'set_on_transition') ? undefined : json['set_on_transition'],
        'set_on_visit': !exists(json, 'set_on_visit') ? undefined : json['set_on_visit'],
        'timestamp_type': json['timestamp_type'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentTimestampToJSON(value?: IncidentTimestamp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'description': TextNodeToJSON(value.description),
        'id': value.id,
        'name': value.name,
        'rank': value.rank,
        'set_by_rules': ((value.set_by_rules as Array<any>).map(IncidentTimestampSetByRuleToJSON)),
        'set_on_acceptance': value.set_on_acceptance,
        'set_on_creation': value.set_on_creation,
        'set_on_resolution': value.set_on_resolution,
        'set_on_transition': value.set_on_transition,
        'set_on_visit': value.set_on_visit,
        'timestamp_type': value.timestamp_type,
        'updated_at': (value.updated_at.toISOString()),
    };
}

