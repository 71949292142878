/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Condition,
    ConditionFromJSON,
    ConditionFromJSONTyped,
    ConditionToJSON,
} from './Condition';
import {
    InternalStatusPageSlim,
    InternalStatusPageSlimFromJSON,
    InternalStatusPageSlimFromJSONTyped,
    InternalStatusPageSlimToJSON,
} from './InternalStatusPageSlim';

/**
 * 
 * @export
 * @interface IncidentAutoSubscribeRule
 */
export interface IncidentAutoSubscribeRule {
    /**
     * 
     * @type {Array<Condition>}
     * @memberof IncidentAutoSubscribeRule
     */
    conditions: Array<Condition>;
    /**
     * 
     * @type {Date}
     * @memberof IncidentAutoSubscribeRule
     */
    created_at: Date;
    /**
     * Unique ID of the rule
     * @type {string}
     * @memberof IncidentAutoSubscribeRule
     */
    id: string;
    /**
     * 
     * @type {InternalStatusPageSlim}
     * @memberof IncidentAutoSubscribeRule
     */
    internal_status_page?: InternalStatusPageSlim;
    /**
     * 
     * @type {Date}
     * @memberof IncidentAutoSubscribeRule
     */
    updated_at: Date;
}

export function IncidentAutoSubscribeRuleFromJSON(json: any): IncidentAutoSubscribeRule {
    return IncidentAutoSubscribeRuleFromJSONTyped(json, false);
}

export function IncidentAutoSubscribeRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAutoSubscribeRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': ((json['conditions'] as Array<any>).map(ConditionFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'internal_status_page': !exists(json, 'internal_status_page') ? undefined : InternalStatusPageSlimFromJSON(json['internal_status_page']),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentAutoSubscribeRuleToJSON(value?: IncidentAutoSubscribeRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': ((value.conditions as Array<any>).map(ConditionToJSON)),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'internal_status_page': InternalStatusPageSlimToJSON(value.internal_status_page),
        'updated_at': (value.updated_at.toISOString()),
    };
}

