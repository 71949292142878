import type { SVGProps } from "react";
import * as React from "react";
const SvgWrench = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m8.835 8.208-5.65 5.65a2.096 2.096 0 0 0 0 2.956c.396.395.92.612 1.48.612.559 0 1.084-.217 1.479-.612l5.649-5.649-2.958-2.957ZM17.737 8l-4.75-4.75a1.752 1.752 0 0 0-1.033-.5l-4.19-.494a.74.74 0 0 0-.618.214l-.434.434a.75.75 0 0 0 0 1.061l7.53 7.531c.34.341.789.512 1.237.512.448 0 .896-.17 1.237-.512l1.02-1.02c.33-.332.514-.77.514-1.239 0-.468-.182-.908-.513-1.238Z"
    />
  </svg>
);
export default SvgWrench;
