import type { SVGProps } from "react";
import * as React from "react";
const SvgHeadphones = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g filter="url(#headphones_svg__a)">
      <g filter="url(#headphones_svg__b)">
        <path
          fill="currentColor"
          d="M12.11 12.8h-.91a.602.602 0 0 1-.586-.726l.867-4c.082-.338.12-.61.12-.873 0-1.985-1.616-3.6-3.6-3.6a3.604 3.604 0 0 0-3.6 3.6c0 .263.036.535.115.857l.87 4.016a.6.6 0 0 1-.586.726h-.91a2.214 2.214 0 0 1-2.15-1.734L1.473 9.83A2.199 2.199 0 0 1 2.63 7.4l.57-.286A4.806 4.806 0 0 1 8 2.4a4.806 4.806 0 0 1 4.798 4.713l.57.287a2.198 2.198 0 0 1 1.158 2.43l-.267 1.236a2.212 2.212 0 0 1-2.15 1.733Z"
        />
      </g>
    </g>
    <defs>
      <filter
        id="headphones_svg__a"
        width={16}
        height={16}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_2625_4819" />
      </filter>
      <filter
        id="headphones_svg__b"
        width={13.155}
        height={10.399}
        x={1.423}
        y={2.401}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_2625_4819" />
      </filter>
    </defs>
  </svg>
);
export default SvgHeadphones;
