/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ManagedResourcesListResponseBody,
    ManagedResourcesListResponseBodyFromJSON,
    ManagedResourcesListResponseBodyToJSON,
    ManagedResourcesUpsertRequestBody,
    ManagedResourcesUpsertRequestBodyFromJSON,
    ManagedResourcesUpsertRequestBodyToJSON,
    ManagedResourcesUpsertResponseBody,
    ManagedResourcesUpsertResponseBodyFromJSON,
    ManagedResourcesUpsertResponseBodyToJSON,
} from '../models';

export interface ManagedResourcesListRequest {
    resourceType?: string;
}

export interface ManagedResourcesUpsertRequest {
    upsertRequestBody: ManagedResourcesUpsertRequestBody;
}

/**
 * 
 */
export class ManagedResourcesApi extends runtime.BaseAPI {

    /**
     * List all managed resources
     * List Managed Resources
     */
    async managedResourcesListRaw(requestParameters: ManagedResourcesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManagedResourcesListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource_type'] = requestParameters.resourceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/managed_resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagedResourcesListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all managed resources
     * List Managed Resources
     */
    async managedResourcesList(requestParameters: ManagedResourcesListRequest, initOverrides?: RequestInit): Promise<ManagedResourcesListResponseBody> {
        const response = await this.managedResourcesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create or update the managed resource which tells us how this resource is managed
     * Upsert Managed Resources
     */
    async managedResourcesUpsertRaw(requestParameters: ManagedResourcesUpsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManagedResourcesUpsertResponseBody>> {
        if (requestParameters.upsertRequestBody === null || requestParameters.upsertRequestBody === undefined) {
            throw new runtime.RequiredError('upsertRequestBody','Required parameter requestParameters.upsertRequestBody was null or undefined when calling managedResourcesUpsert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/managed_resources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManagedResourcesUpsertRequestBodyToJSON(requestParameters.upsertRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagedResourcesUpsertResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create or update the managed resource which tells us how this resource is managed
     * Upsert Managed Resources
     */
    async managedResourcesUpsert(requestParameters: ManagedResourcesUpsertRequest, initOverrides?: RequestInit): Promise<ManagedResourcesUpsertResponseBody> {
        const response = await this.managedResourcesUpsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
