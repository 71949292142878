/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleWorkspaceCalendar
 */
export interface GoogleWorkspaceCalendar {
    /**
     * The access role that the connected user has to this calendar
     * @type {string}
     * @memberof GoogleWorkspaceCalendar
     */
    access_role: GoogleWorkspaceCalendarAccessRoleEnum;
    /**
     * Google's identifier of the calendar
     * @type {string}
     * @memberof GoogleWorkspaceCalendar
     */
    calendar_id: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleWorkspaceCalendar
     */
    id: string;
    /**
     * Error experienced when subscribing to the calendar
     * @type {string}
     * @memberof GoogleWorkspaceCalendar
     */
    invalid_reason?: GoogleWorkspaceCalendarInvalidReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GoogleWorkspaceCalendar
     */
    is_primary: boolean;
    /**
     * 
     * @type {string}
     * @memberof GoogleWorkspaceCalendar
     */
    name?: string;
}

/**
* @export
* @enum {string}
*/
export enum GoogleWorkspaceCalendarAccessRoleEnum {
    None = 'none',
    FreeBusyReader = 'freeBusyReader',
    Reader = 'reader',
    Writer = 'writer',
    Owner = 'owner'
}/**
* @export
* @enum {string}
*/
export enum GoogleWorkspaceCalendarInvalidReasonEnum {
    Unknown = 'unknown',
    NotFound = 'not_found'
}

export function GoogleWorkspaceCalendarFromJSON(json: any): GoogleWorkspaceCalendar {
    return GoogleWorkspaceCalendarFromJSONTyped(json, false);
}

export function GoogleWorkspaceCalendarFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleWorkspaceCalendar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'access_role': json['access_role'],
        'calendar_id': json['calendar_id'],
        'id': json['id'],
        'invalid_reason': !exists(json, 'invalid_reason') ? undefined : json['invalid_reason'],
        'is_primary': json['is_primary'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function GoogleWorkspaceCalendarToJSON(value?: GoogleWorkspaceCalendar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_role': value.access_role,
        'calendar_id': value.calendar_id,
        'id': value.id,
        'invalid_reason': value.invalid_reason,
        'is_primary': value.is_primary,
        'name': value.name,
    };
}

