import type { SVGProps } from "react";
import * as React from "react";
const SvgRunscope = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#0A0B09"
      d="m15.764 9.485.007.038c.02.097.105.172.208.172h1.243a.214.214 0 0 0 .208-.216 7.466 7.466 0 0 0-.482-1.794l.655-.536a.252.252 0 0 0 .054-.316l-.7-1.148a.252.252 0 0 0-.306-.096l-.911.398a7.284 7.284 0 0 0-1.207-1.23l.371-.911a.252.252 0 0 0-.104-.304l-1.165-.672a.252.252 0 0 0-.314.061l-.593.764a7.187 7.187 0 0 0-1.67-.454l-.14-1.029A.253.253 0 0 0 10.674 2H9.331a.253.253 0 0 0-.242.212l-.141 1.025a7.193 7.193 0 0 0-1.671.448l-.595-.767a.252.252 0 0 0-.315-.062l-1.165.673a.252.252 0 0 0-.104.303l.37.908c-.45.36-.857.77-1.21 1.226l-.92-.393a.252.252 0 0 0-.305.098l-.692 1.153c-.06.1-.035.243.057.316l.645.519a7.45 7.45 0 0 0-.493 1.819c0 .117.092.214.208.217h1.215c.147-.005.234-.093.24-.202A5.874 5.874 0 0 1 9.99 4.66a5.864 5.864 0 0 1 5.774 4.824Zm-11.546 2.01a.21.21 0 0 0-.208-.17h-.028v.001H2.767a.212.212 0 0 0-.209.212v.001a7.5 7.5 0 0 0 14.854 0v-.001a.211.211 0 0 0-.207-.212h-1.217l-.027-.003a.212.212 0 0 0-.212.202c0 .002-.002.003-.003.005a5.852 5.852 0 0 1-11.522 0l-.006-.036Z"
    />
    <path
      fill="#3E64A0"
      d="M12.65 10.516a2.642 2.642 0 0 0-2.647-2.637 2.642 2.642 0 0 0-2.647 2.637 2.642 2.642 0 0 0 2.647 2.637 2.642 2.642 0 0 0 2.647-2.637Z"
    />
  </svg>
);
export default SvgRunscope;
