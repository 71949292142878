import { AvailablePanelPanelTypeEnum } from "@incident-io/api";
import { BadgeSize, Button, GenericErrorMessage, IconEnum } from "@incident-ui";
import { DrawerContentsLoading } from "@incident-ui/Drawer/Drawer";
import { UseFieldArrayInsert } from "react-hook-form";

import { useAPI } from "../../../../../utils/swr";
import { availablePanelToFormData } from "../common/marshall";
import { EditDashboardFormData } from "../common/useInsightsContext";

export type InsertPanelTileProps = {
  indexToInsert: number;
  insertPanelHandler: UseFieldArrayInsert<EditDashboardFormData, "panels">;
  openPanelCreateDrawer: () => void;
};

export const InsertPanelTile = ({
  indexToInsert,
  insertPanelHandler,
  openPanelCreateDrawer,
}: InsertPanelTileProps) => {
  const {
    data: panelsData,
    error: panelsError,
    isLoading: panelsLoading,
  } = useAPI("insightsListAvailablePanels", undefined);

  if (panelsError) {
    return <GenericErrorMessage description="Unable to fetch panels" />;
  }

  if (panelsLoading || !panelsData) {
    return <DrawerContentsLoading />;
  }

  const textPanelPrototype = panelsData.panels.find(
    (panel) => panel.panel_type === AvailablePanelPanelTypeEnum.Text,
  );

  const addTextPanel = () => {
    if (textPanelPrototype) {
      insertPanelHandler(
        indexToInsert,
        availablePanelToFormData(textPanelPrototype),
      );
    }
  };

  return (
    <div className="flex gap-2 py-4 w-full border border-dashed border-gray-300 justify-center rounded-md hover:border-stroke-hover">
      <Button
        onClick={openPanelCreateDrawer}
        size={BadgeSize.Medium}
        icon={IconEnum.BarChart}
        analyticsTrackingId="add-chart-panel-from-page"
      >
        Add chart
      </Button>
      <Button
        onClick={addTextPanel}
        size={BadgeSize.Medium}
        icon={IconEnum.Text}
        analyticsTrackingId="add-text-panel-from-page"
      >
        Add text
      </Button>
    </div>
  );
};
