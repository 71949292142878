import type { SVGProps } from "react";
import * as React from "react";
const SvgAddAbove = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M10.221 5.445H8.665V7a.667.667 0 0 1-1.333 0V5.445H5.777a.667.667 0 0 1 0-1.334h1.555V2.556a.667.667 0 0 1 1.333 0V4.11h1.556a.667.667 0 0 1 0 1.334ZM13.5 14h-11A1.5 1.5 0 0 1 1 12.5v-1A1.5 1.5 0 0 1 2.5 10h11a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5Z" />
  </svg>
);
export default SvgAddAbove;
