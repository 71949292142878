/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalAtlassianStatuspageIncident
 */
export interface ExternalAtlassianStatuspageIncident {
    /**
     * Statuspage impact
     * @type {string}
     * @memberof ExternalAtlassianStatuspageIncident
     */
    impact: ExternalAtlassianStatuspageIncidentImpactEnum;
    /**
     * Page ID of the statuspage
     * @type {string}
     * @memberof ExternalAtlassianStatuspageIncident
     */
    page_id: string;
    /**
     * Current status of the issue
     * @type {string}
     * @memberof ExternalAtlassianStatuspageIncident
     */
    status: ExternalAtlassianStatuspageIncidentStatusEnum;
    /**
     * Title of the incident
     * @type {string}
     * @memberof ExternalAtlassianStatuspageIncident
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum ExternalAtlassianStatuspageIncidentImpactEnum {
    None = 'none',
    Minor = 'minor',
    Major = 'major',
    Critical = 'critical'
}/**
* @export
* @enum {string}
*/
export enum ExternalAtlassianStatuspageIncidentStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved'
}

export function ExternalAtlassianStatuspageIncidentFromJSON(json: any): ExternalAtlassianStatuspageIncident {
    return ExternalAtlassianStatuspageIncidentFromJSONTyped(json, false);
}

export function ExternalAtlassianStatuspageIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalAtlassianStatuspageIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'impact': json['impact'],
        'page_id': json['page_id'],
        'status': json['status'],
        'title': json['title'],
    };
}

export function ExternalAtlassianStatuspageIncidentToJSON(value?: ExternalAtlassianStatuspageIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'impact': value.impact,
        'page_id': value.page_id,
        'status': value.status,
        'title': value.title,
    };
}

