/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InternalStatusPageUpdateGroupingBehaviourRequestBody
 */
export interface InternalStatusPageUpdateGroupingBehaviourRequestBody {
    /**
     * Whether or not groups should be expanded by default
     * @type {boolean}
     * @memberof InternalStatusPageUpdateGroupingBehaviourRequestBody
     */
    auto_expand_groups: boolean;
    /**
     * In the headsup bar at the top of an internal status page, configure if groups or components are shown
     * @type {boolean}
     * @memberof InternalStatusPageUpdateGroupingBehaviourRequestBody
     */
    use_currently_affected_components_over_groups: boolean;
}

export function InternalStatusPageUpdateGroupingBehaviourRequestBodyFromJSON(json: any): InternalStatusPageUpdateGroupingBehaviourRequestBody {
    return InternalStatusPageUpdateGroupingBehaviourRequestBodyFromJSONTyped(json, false);
}

export function InternalStatusPageUpdateGroupingBehaviourRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageUpdateGroupingBehaviourRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_expand_groups': json['auto_expand_groups'],
        'use_currently_affected_components_over_groups': json['use_currently_affected_components_over_groups'],
    };
}

export function InternalStatusPageUpdateGroupingBehaviourRequestBodyToJSON(value?: InternalStatusPageUpdateGroupingBehaviourRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_expand_groups': value.auto_expand_groups,
        'use_currently_affected_components_over_groups': value.use_currently_affected_components_over_groups,
    };
}

