/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalizeConfig
 */
export interface LocalizeConfig {
    /**
     * Whether translations should be autoaccepted in Localize for this website.
     * @type {boolean}
     * @memberof LocalizeConfig
     */
    auto_accept_translations: boolean;
    /**
     * The project key for the Localize project for this status page
     * @type {string}
     * @memberof LocalizeConfig
     */
    project_key: string;
}

export function LocalizeConfigFromJSON(json: any): LocalizeConfig {
    return LocalizeConfigFromJSONTyped(json, false);
}

export function LocalizeConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalizeConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_accept_translations': json['auto_accept_translations'],
        'project_key': json['project_key'],
    };
}

export function LocalizeConfigToJSON(value?: LocalizeConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_accept_translations': value.auto_accept_translations,
        'project_key': value.project_key,
    };
}

