import { Callout, CalloutTheme, OrgAwareLink } from "@incident-ui";
import Stripe from "@stripe/stripe-js";
import React from "react";
import { Helmet } from "react-helmet";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

import { SettingsSubPageWrapper } from "../SettingsRoute";
import { BillingPageContent } from "./BillingPageContent";

// Extend Window with the Stripe types. This is not how you are meant to use
// Stripe, but it does solve the TypeScript errors and give us type-checked
// client interactions.
declare global {
  interface Window {
    Stripe?: Stripe.StripeConstructor;
  }
}
export const BillingPage = (): React.ReactElement => {
  const { hasScope, identity } = useIdentity();

  if (!hasScope(ScopeNameEnum.BillingSettingsView)) {
    return (
      <SettingsSubPageWrapper>
        <div className="bg-white shadow overflow-hidden sm:rounded-[6px] text-base font-medium rounded-[6px] text-slate-700 px-6 py-6 text-center">
          <Callout theme={CalloutTheme.Warning}>
            <div>
              You don&apos;t have access to view this page. If you&apos;d like
              to view or edit Billing Settings, contact an Administrator or
              Owner. You can see a list of Administrators and Owners on the{" "}
              <OrgAwareLink to="/settings/users">Users Settings</OrgAwareLink>{" "}
              page.
            </div>
          </Callout>
        </div>
      </SettingsSubPageWrapper>
    );
  }

  if (identity?.organisation_is_demo) {
    return (
      <SettingsSubPageWrapper>
        <Callout theme={CalloutTheme.Warning}>
          <div>
            This is marked as a demo account, so there&apos;s no relevant
            billing information to display.
          </div>
        </Callout>
      </SettingsSubPageWrapper>
    );
  }

  return (
    <>
      <Helmet>
        <script src="https://js.stripe.com/v3/"></script>
      </Helmet>
      <BillingPageContent />
    </>
  );
};
