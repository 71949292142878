import { IconEnum } from "@incident-ui";
import _ from "lodash";
import { ExternalResource } from "src/contexts/ClientContext";

import { AttachmentRenderProps } from "./IncidentAttachment";

export const GitHubPullRequest = (
  resource: ExternalResource,
): AttachmentRenderProps => {
  if (!resource.github_pull_request) {
    throw new Error("GitHub Pull Request resource without expected content");
  }

  return {
    icon: IconEnum.Github,
    title: `#${resource.github_pull_request.number}: ${resource.github_pull_request.title}`,
    safeBadges: [
      {
        icon: IconEnum.User,
        text: resource.github_pull_request.author,
        tooltipContent: "The author of the GitHub PR",
      },
    ],
    unsafeBadges: [
      {
        icon: IconEnum.Status,
        text: resource.github_pull_request.merged
          ? "Merged"
          : _.startCase(resource.github_pull_request.status),
      },
    ],
  };
};
