/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnabledSlackQuickAction,
    EnabledSlackQuickActionFromJSON,
    EnabledSlackQuickActionFromJSONTyped,
    EnabledSlackQuickActionToJSON,
} from './EnabledSlackQuickAction';

/**
 * 
 * @export
 * @interface IncidentChannelConfigsUpdateQuickActionRanksResponseBody
 */
export interface IncidentChannelConfigsUpdateQuickActionRanksResponseBody {
    /**
     * 
     * @type {Array<EnabledSlackQuickAction>}
     * @memberof IncidentChannelConfigsUpdateQuickActionRanksResponseBody
     */
    quick_actions: Array<EnabledSlackQuickAction>;
}

export function IncidentChannelConfigsUpdateQuickActionRanksResponseBodyFromJSON(json: any): IncidentChannelConfigsUpdateQuickActionRanksResponseBody {
    return IncidentChannelConfigsUpdateQuickActionRanksResponseBodyFromJSONTyped(json, false);
}

export function IncidentChannelConfigsUpdateQuickActionRanksResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelConfigsUpdateQuickActionRanksResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quick_actions': ((json['quick_actions'] as Array<any>).map(EnabledSlackQuickActionFromJSON)),
    };
}

export function IncidentChannelConfigsUpdateQuickActionRanksResponseBodyToJSON(value?: IncidentChannelConfigsUpdateQuickActionRanksResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quick_actions': ((value.quick_actions as Array<any>).map(EnabledSlackQuickActionToJSON)),
    };
}

