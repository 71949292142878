/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlaceholderTimeslot,
    PlaceholderTimeslotFromJSON,
    PlaceholderTimeslotFromJSONTyped,
    PlaceholderTimeslotToJSON,
} from './PlaceholderTimeslot';

/**
 * 
 * @export
 * @interface DebriefsCreateDebriefRequestBody
 */
export interface DebriefsCreateDebriefRequestBody {
    /**
     * ID of the incident we're creating the debrief for
     * @type {string}
     * @memberof DebriefsCreateDebriefRequestBody
     */
    incident_id: string;
    /**
     * 
     * @type {PlaceholderTimeslot}
     * @memberof DebriefsCreateDebriefRequestBody
     */
    timeslot: PlaceholderTimeslot;
}

export function DebriefsCreateDebriefRequestBodyFromJSON(json: any): DebriefsCreateDebriefRequestBody {
    return DebriefsCreateDebriefRequestBodyFromJSONTyped(json, false);
}

export function DebriefsCreateDebriefRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsCreateDebriefRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'timeslot': PlaceholderTimeslotFromJSON(json['timeslot']),
    };
}

export function DebriefsCreateDebriefRequestBodyToJSON(value?: DebriefsCreateDebriefRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'timeslot': PlaceholderTimeslotToJSON(value.timeslot),
    };
}

