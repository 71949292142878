import type { SVGProps } from "react";
import * as React from "react";
const SvgCallPlus = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.748 5.5h-1.75V3.75a.75.75 0 0 0-1.5 0V5.5h-1.75a.75.75 0 0 0 0 1.5h1.75v1.75a.75.75 0 0 0 1.5 0V7h1.75a.75.75 0 0 0 0-1.5ZM16.477 12.732l-2.925-1.299a1.721 1.721 0 0 0-2.04.494l-1.367 1.701a11.28 11.28 0 0 1-3.278-3.28L8.572 8.99a1.722 1.722 0 0 0 .497-2.04l-1.3-2.928a1.716 1.716 0 0 0-2.004-.967l-2.476.643a1.725 1.725 0 0 0-1.273 1.907c.934 6.65 6.23 11.945 12.88 12.88a1.728 1.728 0 0 0 1.905-1.274l.643-2.477a1.715 1.715 0 0 0-.966-2.002Z"
    />
  </svg>
);
export default SvgCallPlus;
