/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOption,
    SelectOptionFromJSON,
    SelectOptionFromJSONTyped,
    SelectOptionToJSON,
} from './SelectOption';

/**
 * 
 * @export
 * @interface JiraIssueField
 */
export interface JiraIssueField {
    /**
     * If provided, a static list of typeahead options
     * @type {Array<SelectOption>}
     * @memberof JiraIssueField
     */
    allowed_values?: Array<SelectOption>;
    /**
     * If provided, the default value for this field. This is a string or an array of strings - matching the type we use for the CreateIssue payload.
     * @type {object}
     * @memberof JiraIssueField
     */
    default_value?: object;
    /**
     * Used with the typeahead endpoint, identifies the dynamic field to generate options for
     * @type {string}
     * @memberof JiraIssueField
     */
    dynamic_typeahead_field?: JiraIssueFieldDynamicTypeaheadFieldEnum;
    /**
     * Uniquely identifies the field
     * @type {string}
     * @memberof JiraIssueField
     */
    key: string;
    /**
     * Human readable label for this field
     * @type {string}
     * @memberof JiraIssueField
     */
    label: string;
    /**
     * Whether this field is a selection and takes multiple values
     * @type {boolean}
     * @memberof JiraIssueField
     */
    multi_select: boolean;
    /**
     * Whether this field is required for issue creation
     * @type {boolean}
     * @memberof JiraIssueField
     */
    required: boolean;
    /**
     * The type of this field
     * @type {string}
     * @memberof JiraIssueField
     */
    type: JiraIssueFieldTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JiraIssueFieldDynamicTypeaheadFieldEnum {
    Project = 'project',
    Issuetype = 'issuetype',
    Label = 'label',
    Epic = 'epic',
    User = 'user'
}/**
* @export
* @enum {string}
*/
export enum JiraIssueFieldTypeEnum {
    Date = 'date',
    DateTime = 'date-time',
    EpicLink = 'epic-link',
    Labels = 'labels',
    Link = 'link',
    MultiSelect = 'multi-select',
    Number = 'number',
    RichText = 'rich-text',
    SingleSelect = 'single-select',
    Text = 'text',
    User = 'user'
}

export function JiraIssueFieldFromJSON(json: any): JiraIssueField {
    return JiraIssueFieldFromJSONTyped(json, false);
}

export function JiraIssueFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraIssueField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowed_values': !exists(json, 'allowed_values') ? undefined : ((json['allowed_values'] as Array<any>).map(SelectOptionFromJSON)),
        'default_value': !exists(json, 'default_value') ? undefined : json['default_value'],
        'dynamic_typeahead_field': !exists(json, 'dynamic_typeahead_field') ? undefined : json['dynamic_typeahead_field'],
        'key': json['key'],
        'label': json['label'],
        'multi_select': json['multi_select'],
        'required': json['required'],
        'type': json['type'],
    };
}

export function JiraIssueFieldToJSON(value?: JiraIssueField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowed_values': value.allowed_values === undefined ? undefined : ((value.allowed_values as Array<any>).map(SelectOptionToJSON)),
        'default_value': value.default_value,
        'dynamic_typeahead_field': value.dynamic_typeahead_field,
        'key': value.key,
        'label': value.label,
        'multi_select': value.multi_select,
        'required': value.required,
        'type': value.type,
    };
}

