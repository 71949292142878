import { getTimeZones, TimeZone } from "@vvo/tzdb";

// findTimeZone returns the TimeZone by either name, or alias
// Example: "Australia/Canberra" is an alias of the timezone named
// "Australia/Sydney", and is included in the "group" array.
export const findTimeZone = (tzName?: string): TimeZone | null => {
  if (!tzName) {
    return null;
  }
  return (
    getTimeZones({ includeUtc: true }).find(
      ({ name, group }) => name === tzName || group.includes(tzName),
    ) ?? null
  );
};
