/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HolidayPublicEntry,
    HolidayPublicEntryFromJSON,
    HolidayPublicEntryFromJSONTyped,
    HolidayPublicEntryToJSON,
} from './HolidayPublicEntry';

/**
 * 
 * @export
 * @interface HolidaysListPublicEntriesResponseBody
 */
export interface HolidaysListPublicEntriesResponseBody {
    /**
     * 
     * @type {Array<HolidayPublicEntry>}
     * @memberof HolidaysListPublicEntriesResponseBody
     */
    holiday_public_entries: Array<HolidayPublicEntry>;
}

export function HolidaysListPublicEntriesResponseBodyFromJSON(json: any): HolidaysListPublicEntriesResponseBody {
    return HolidaysListPublicEntriesResponseBodyFromJSONTyped(json, false);
}

export function HolidaysListPublicEntriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidaysListPublicEntriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'holiday_public_entries': ((json['holiday_public_entries'] as Array<any>).map(HolidayPublicEntryFromJSON)),
    };
}

export function HolidaysListPublicEntriesResponseBodyToJSON(value?: HolidaysListPublicEntriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'holiday_public_entries': ((value.holiday_public_entries as Array<any>).map(HolidayPublicEntryToJSON)),
    };
}

