import { InternalStatusPage } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Path, UseFormReturn, ValidateResult } from "react-hook-form";
import { ErrorResponse, useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";

export const BasicSettingsFormContent = <
  TFormType extends { name: string; subpath: string },
>({
  page,
  showSubpath,
  formMethods,
}: {
  page: InternalStatusPage | null;
  showSubpath: boolean;
  formMethods: UseFormReturn<TFormType>;
}) => {
  const { identity } = useIdentity();
  const apiClient = useClient();
  const checkAvailable = async (subpath: string): Promise<ValidateResult> => {
    try {
      await apiClient.internalStatusPageCheckSubpathAvailable({
        checkSubpathAvailableRequestBody: {
          internal_status_page_id: page?.id,
          subpath,
        },
      });

      return true;
    } catch (e) {
      if (e instanceof Response) {
        const jsonErr: ErrorResponse = await e.json();
        if (jsonErr.type === "validation_error") {
          const firstErr = jsonErr.errors[0];
          return firstErr.message;
        }
      }

      // Bit yikes here - something has gone wrong that isn't a validation
      // error. We could let the user carry on, but they might get stuck later
      // in the form. Let's crash instead.
      throw e;
    }
  };

  return (
    <>
      <InputV2
        formMethods={formMethods}
        name={"name" as Path<TFormType>}
        required="Please choose a title for your internal status page"
        label="Page title"
        helptext="We'll show this as the page title when someone views this page, and as a header on the page if you don't upload a logo."
      />
      {showSubpath && (
        <InputV2
          formMethods={formMethods}
          name={"subpath" as Path<TFormType>}
          spellCheck={false}
          required="Please choose a URL for your internal status page"
          label="Status page URL"
          inputClassName="bg-white"
          helptext="The link that anyone in your organisation can visit to view your internal status page."
          inputPrefixNode={
            <label
              htmlFor="subpath"
              className="text-slate-400 mt-[2px] whitespace-nowrap"
            >
              {window.location.origin}/{identity?.organisation_slug}/status/
            </label>
          }
          rules={{ validate: { checkAvailable } }}
        />
      )}
    </>
  );
};
