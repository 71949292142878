/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImpersonationSession,
    ImpersonationSessionFromJSON,
    ImpersonationSessionFromJSONTyped,
    ImpersonationSessionToJSON,
} from './ImpersonationSession';

/**
 * 
 * @export
 * @interface StaffDestroyImpersonationSessionResponseBody
 */
export interface StaffDestroyImpersonationSessionResponseBody {
    /**
     * 
     * @type {ImpersonationSession}
     * @memberof StaffDestroyImpersonationSessionResponseBody
     */
    impersonation_session: ImpersonationSession;
}

export function StaffDestroyImpersonationSessionResponseBodyFromJSON(json: any): StaffDestroyImpersonationSessionResponseBody {
    return StaffDestroyImpersonationSessionResponseBodyFromJSONTyped(json, false);
}

export function StaffDestroyImpersonationSessionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffDestroyImpersonationSessionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'impersonation_session': ImpersonationSessionFromJSON(json['impersonation_session']),
    };
}

export function StaffDestroyImpersonationSessionResponseBodyToJSON(value?: StaffDestroyImpersonationSessionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'impersonation_session': ImpersonationSessionToJSON(value.impersonation_session),
    };
}

