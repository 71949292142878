/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExploDashboardInfo
 */
export interface ExploDashboardInfo {
    /**
     * Additional variables to pass to the Explo dashboard to provide context for this organisation
     * @type {{ [key: string]: string; }}
     * @memberof ExploDashboardInfo
     */
    additional_variables: { [key: string]: string; };
    /**
     * Description of the panel
     * @type {string}
     * @memberof ExploDashboardInfo
     */
    description: string;
    /**
     * ID of the explo dashboard
     * @type {string}
     * @memberof ExploDashboardInfo
     */
    explo_dashboard_id: string;
    /**
     * Initial height of the panel (while we're waiting for it to load)
     * @type {string}
     * @memberof ExploDashboardInfo
     */
    initial_height: string;
    /**
     * Title of the panel
     * @type {string}
     * @memberof ExploDashboardInfo
     */
    title: string;
}

export function ExploDashboardInfoFromJSON(json: any): ExploDashboardInfo {
    return ExploDashboardInfoFromJSONTyped(json, false);
}

export function ExploDashboardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExploDashboardInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additional_variables': json['additional_variables'],
        'description': json['description'],
        'explo_dashboard_id': json['explo_dashboard_id'],
        'initial_height': json['initial_height'],
        'title': json['title'],
    };
}

export function ExploDashboardInfoToJSON(value?: ExploDashboardInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'additional_variables': value.additional_variables,
        'description': value.description,
        'explo_dashboard_id': value.explo_dashboard_id,
        'initial_height': value.initial_height,
        'title': value.title,
    };
}

