import {
  CheckboxGroup,
  CheckboxGroupProps,
} from "@incident-ui/Checkbox/CheckboxGroup";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const CheckboxGroupV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, CheckboxGroupProps>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    CheckboxGroupProps
  >(rest);
  const {
    // We don't want to pass the ref onwards here: a checkbox group
    // references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <CheckboxGroup
        {...field}
        {...inputProps}
        onChange={(val: string[]) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};
