import type { SVGProps } from "react";
import * as React from "react";
const SvgArrowDownCircle = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm3.03 9.28-2.5 2.5a.748.748 0 0 1-1.06 0l-2.5-2.5a.75.75 0 1 1 1.061-1.061l1.22 1.22V6.75a.75.75 0 0 1 1.5 0v4.689l1.22-1.22a.75.75 0 1 1 1.061 1.061h-.002Z"
    />
  </svg>
);
export default SvgArrowDownCircle;
