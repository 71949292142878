import {
  AlertSource,
  AlertSourceClassificationEnum,
  AlertSourceConfig,
  AlertSourceConfigStateEnum,
  AlertSourceSourceTypeEnum,
  IntegrationSettings,
  IntegrationSettingsProviderEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ConfigureDrawerProps,
  getBrokenIntegrations,
  getBrokenIntegrationsLookup,
  IntegrationConfigFor,
} from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  LocalRelativeDateTime,
  ToastTheme,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { AlertSourceTypeIcon } from "../../../alerts/common/AlertSourceTypeConfigs";
import { DeletionConfirmationModal } from "../../DeletionConfirmationModal";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "./IntegrationDrawer";

export const AlertSourceDrawer = (props: ConfigureDrawerProps) => {
  const {
    data: { alert_source_configs: alertSourceConfigs },
    isLoading: sourceConfigsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const { integrations, integrationsLoading, integrationsError } =
    useIntegrations();

  const {
    data: { alert_sources: alertSources },
    isLoading: sourcesLoading,
    error: sourcesError,
  } = useAPI("alertsListSources", undefined, {
    fallbackData: { alert_sources: [] },
  });

  if (
    sourceConfigsLoading ||
    integrationsLoading ||
    sourcesLoading ||
    !integrations
  ) {
    return <IntegrationDrawerContentLoader />;
  }
  if (sourceConfigsError || integrationsError || sourcesError) {
    return <GenericErrorMessage error={sourceConfigsError} />;
  }

  const thisAlertSource = alertSources.find(
    (as) => as.source_type === props.integration.provider,
  );

  return (
    <GenericConfigureDrawerContents {...props}>
      <div className="flex flex-col gap-3">
        <div className="text-base-bold text-content-primary">Alert sources</div>
        <div className="flex flex-col rounded-2 border border-stroke divide-y divide-slate-100">
          {alertSourceConfigs
            .filter((s) => s.source_type === props.integration.provider)
            .map((s) => (
              <AlertSourceConfigRow
                key={s.id}
                alertSourceConfig={s}
                alertSource={thisAlertSource}
                integrations={integrations}
              />
            ))}
        </div>
      </div>
    </GenericConfigureDrawerContents>
  );
};

export function AlertSourceConfigRow({
  alertSourceConfig,
  alertSource,
  integrations,
}: {
  alertSourceConfig: AlertSourceConfig;
  alertSource?: AlertSource;
  integrations: IntegrationSettings[];
}): React.ReactElement {
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();

  const isSetup =
    alertSourceConfig.state !== AlertSourceConfigStateEnum.PendingSetup;
  const isConnected = (alertSourceConfig.alert_route_ids || []).length > 0;

  const { trigger: onDelete } = useAPIMutation(
    "alertsListSourceConfigs",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.alertsDestroySourceConfig({ id: id });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: `${alertSourceConfig.name} deleted.`,
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not delete ${alertSourceConfig.name}`,
        });
      },
    },
  );

  const brokenIntegrations = getBrokenIntegrations(
    alertSource?.required_integrations ?? [],
    getBrokenIntegrationsLookup(integrations),
  ).map((x) =>
    IntegrationConfigFor(x as unknown as IntegrationSettingsProviderEnum),
  );
  const alertSourceForBrokenIntegration = brokenIntegrations.length > 0;
  const canEdit =
    hasScope(ScopeNameEnum.AlertSourceUpdate) &&
    !alertSourceForBrokenIntegration;

  return (
    <div
      className={tcx("flex-center-y p-5 space-x-3", {
        "cursor-pointer hover:bg-surface-secondary": canEdit,
      })}
      onClick={
        canEdit
          ? () =>
              navigate(
                `/alerts/sources/${alertSourceConfig.id}/edit${
                  !isSetup ? "?step=connect" : ""
                }`,
              )
          : undefined
      }
    >
      <div className="flex-center w-[40px] h-[40px] p-1 border border-stroke rounded-2">
        <AlertSourceTypeIcon
          size={IconSize.XL}
          sourceType={
            alertSourceConfig.source_type as unknown as AlertSourceSourceTypeEnum
          }
        />
      </div>
      <div className="flex flex-col grow min-w-0">
        <div className="flex break-words">
          <Txt inline bold className="flex-center">
            {alertSourceConfig.name}
            <Tooltip
              content={alertSourceConfig.alert_source?.docstring}
              buttonClassName="ml-0.5" // ml is there so that hovering doesn't show the tiniest gap between.
            />
          </Txt>
        </div>
        {alertSourceConfig.alert_last_fired_at ? (
          <Txt lightGrey className="text-xs">
            Last fired{" "}
            <LocalRelativeDateTime
              date={alertSourceConfig.alert_last_fired_at}
              className={"hover:!no-underline text-xs"}
            />
          </Txt>
        ) : (
          <Txt lightGrey className="text-xs">
            Never fired
          </Txt>
        )}
      </div>{" "}
      {brokenIntegrations && brokenIntegrations.length > 0 && (
        <Tooltip
          content="We're having trouble using this alert source. Please check your integration settings."
          bubbleProps={{ className: "w-full font-normal" }}
        >
          <div className="text-alarmalade-600">
            <Icon id={IconEnum.Warning} />
          </div>
        </Tooltip>
      )}
      {/* If the alert source is not setup, prompt to finish, otherwise if it is also not connected to a route,
       prompt to connect to one. */}
      {!alertSourceForBrokenIntegration && !isSetup ? (
        <>
          <Tooltip
            content={
              "This alert source isn't fully setup. It is not receiving alerts, and no incidents will be created from it."
            }
          >
            <div>
              <Badge
                size={BadgeSize.Small}
                theme={BadgeTheme.Warning}
                className={"border border-amber-200"}
              >
                <span className={"px-1"}>Setup incomplete</span>
              </Badge>
            </div>
          </Tooltip>
          <GatedButton
            disabledTooltipContent={
              "You don't have permission to edit this alert source"
            }
            disabled={!canEdit}
            analyticsTrackingId="alert-sources.finish-setup"
            href={`/alerts/sources/${alertSourceConfig.id}/edit?step=connect`}
            size={BadgeSize.Medium}
            className={"!text-xs"}
          >
            Finish setup
          </GatedButton>
        </>
      ) : (
        !alertSourceForBrokenIntegration &&
        !isConnected && (
          <>
            <Tooltip
              content={
                "This alert source has not been connected to any routes. While it is unconnected, you'll receive alerts, but no incidents will be created."
              }
            >
              <div>
                <Badge
                  size={BadgeSize.Small}
                  theme={BadgeTheme.Tertiary}
                  className={"border border-stroke"}
                >
                  <span className={"px-1"}>Not connected</span>
                </Badge>
              </div>
            </Tooltip>
            <GatedButton
              disabledTooltipContent={
                "You don't have permission to edit alert routes"
              }
              disabled={!hasScope(ScopeNameEnum.AlertRouteUpdate)}
              analyticsTrackingId="alert-sources.connect-to-route"
              href={
                "/alerts/routes/create?initial_source_id=" +
                alertSourceConfig.id
              }
              size={BadgeSize.Medium}
              className={"!text-xs"}
            >
              Connect to route
            </GatedButton>
          </>
        )
      )}
      <AlertSourceConfigOverflowMenu
        alertSource={alertSource}
        alertSourceConfig={alertSourceConfig}
        onDelete={() => onDelete({ id: alertSourceConfig.id })}
        hasBrokenIntegration={alertSourceForBrokenIntegration}
      />
    </div>
  );
}

const AlertSourceConfigOverflowMenu = ({
  alertSource,
  alertSourceConfig,
  onDelete,
  hasBrokenIntegration,
}: {
  alertSource?: AlertSource;
  alertSourceConfig: AlertSourceConfig;
  onDelete: () => void;
  hasBrokenIntegration?: boolean;
}): React.ReactElement => {
  const { hasScope } = useIdentity();

  const canEdit = hasScope(ScopeNameEnum.AlertSourceUpdate);
  const hasDeletionPermissions = hasScope(ScopeNameEnum.AlertSourceDestroy);
  const isDeletableSource =
    alertSource?.classification !== AlertSourceClassificationEnum.Native;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useOrgAwareNavigate();
  return (
    <>
      <DeletionConfirmationModal
        title="Delete alert source"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        fetchDependentResources={[
          {
            resource_type: "AlertSourceConfig",
            id: alertSourceConfig.id,
          },
        ]}
        onDelete={onDelete}
        analyticsTrackingId="delete-settings-item"
        resourceTitle={alertSourceConfig.name}
        deleteConfirmationContent={
          <>
            Are you sure you want to delete{" "}
            <span className="font-bold">{alertSourceConfig.name}</span>?
          </>
        }
      />
      <DropdownMenu
        menuClassName={"w-[140px]"}
        side={"bottom"}
        scroll={false}
        triggerButton={
          <Button
            theme={ButtonTheme.Naked}
            type="button"
            className="-ml-2"
            analyticsTrackingId="alert-source-config-options"
            icon={IconEnum.DotsVerticalNopad}
            iconProps={{ size: IconSize.Large, className: "-my-2" }}
            title="Alert source config options"
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        {!hasBrokenIntegration && (
          <DropdownMenuItem
            disabled={!canEdit}
            tooltipContent={
              canEdit
                ? null
                : "You don't have permission to edit this alert source."
            }
            onSelect={() =>
              navigate(`/alerts/sources/${alertSourceConfig.id}/edit`)
            }
            analyticsTrackingId={"alert-source-config-edit"}
            icon={IconEnum.Edit}
            label={"Edit"}
          >
            Edit
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          disabled={!(hasDeletionPermissions && isDeletableSource)}
          tooltipContent={
            hasDeletionPermissions
              ? !isDeletableSource
                ? "This alert source is part of incident.io and can't be deleted."
                : undefined
              : "You don't have permission to delete this source"
          }
          onSelect={() => setShowDeleteModal(true)}
          analyticsTrackingId={"alert-source-config-delete"}
          label="Delete"
          icon={IconEnum.Delete}
          destructive
        >
          Delete
        </DropdownMenuItem>
      </DropdownMenu>
    </>
  );
};
