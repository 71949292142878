import type { SVGProps } from "react";
import * as React from "react";
const SvgThumbsDown = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.81 8.546-1.191-4.5A2.753 2.753 0 0 0 13.96 2H8.25A2.753 2.753 0 0 0 5.5 4.75v5.756c0 .659.237 1.297.669 1.797l4.264 4.937a.75.75 0 0 0 .903.181 2.623 2.623 0 0 0 1.289-3.254L11.827 12h3.326a2.73 2.73 0 0 0 2.179-1.073 2.733 2.733 0 0 0 .479-2.381h-.001Z"
    />
    <path
      fill="currentColor"
      d="M5.25 12h-1.5C2.785 12 2 11.215 2 10.25v-6.5C2 2.785 2.785 2 3.75 2h1.5C6.215 2 7 2.785 7 3.75v6.5C7 11.215 6.215 12 5.25 12Zm-1.5-8.5a.25.25 0 0 0-.25.25v6.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-6.5a.25.25 0 0 0-.25-.25h-1.5Z"
    />
  </svg>
);
export default SvgThumbsDown;
