/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleWorkspaceUser,
    GoogleWorkspaceUserFromJSON,
    GoogleWorkspaceUserFromJSONTyped,
    GoogleWorkspaceUserToJSON,
} from './GoogleWorkspaceUser';

/**
 * 
 * @export
 * @interface GoogleWorkspaceConfig
 */
export interface GoogleWorkspaceConfig {
    /**
     * 
     * @type {GoogleWorkspaceUser}
     * @memberof GoogleWorkspaceConfig
     */
    connecting_user: GoogleWorkspaceUser;
}

export function GoogleWorkspaceConfigFromJSON(json: any): GoogleWorkspaceConfig {
    return GoogleWorkspaceConfigFromJSONTyped(json, false);
}

export function GoogleWorkspaceConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleWorkspaceConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connecting_user': GoogleWorkspaceUserFromJSON(json['connecting_user']),
    };
}

export function GoogleWorkspaceConfigToJSON(value?: GoogleWorkspaceConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connecting_user': GoogleWorkspaceUserToJSON(value.connecting_user),
    };
}

