import {
  DeprecatedTextEditor,
  DeprecatedTextEditorProps,
} from "@incident-shared/forms/v1/deprecated-text-editor/TextEditor";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const DeprecatedTextEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, DeprecatedTextEditorProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <DeprecatedTextEditor
        id={name}
        {...field}
        {...inputProps}
        placeholder={props.placeholder}
        className={props.className}
        onChange={(e: string) => {
          field.onChange(e);
        }}
      />
    </FormInputWrapper>
  );
};
