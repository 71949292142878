import type { SVGProps } from "react";
import * as React from "react";
const SvgSolarwinds = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 86 86"
    {...props}
  >
    <g fill="#F99D1C" clipPath="url(#solarwinds_svg__a)">
      <path d="M40.8 32.3c.3-.1.5-.1.7-.2 5.5-1.6 10.8-3.7 16.2-5.9 5.2-2.2 10.3-4.8 14.9-8.5C77.2 13.9 80.9 9 83 3c.3-.8.9-2.1.9-3C76.7 9.9 48 10.9 48 10.9l6.8-6.2C27.6 4.8 8.6 16.3-.1 22.5c11.1 1.2 21.2 5.9 29.1 13 3.9-1.2 7.9-2.1 11.8-3.2ZM85.7 31.3s-26.4-2-53.9 6.8c3.6 3.8 6.7 8.2 9.1 12.9 14.4-7.9 34.5-18.2 44.8-19.7ZM42.7 55.2c1.7 4.3 2.8 8.9 3.3 13.7L71.2 43 42.7 55.2Z" />
    </g>
    <defs>
      <clipPath id="solarwinds_svg__a">
        <path fill="#fff" d="M0-7h86v86H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSolarwinds;
