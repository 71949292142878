import type { SVGProps } from "react";
import * as React from "react";
const SvgDynatrace = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#1496FF"
      d="M7.336 3.34a44.347 44.347 0 0 0-.594 4.33c-.24 2.878-.092 4.809-.092 4.809l-4.055 3.85s-.309-2.16-.469-4.593a64.475 64.475 0 0 1-.125-3.632c0-.046.023-.092.023-.137 0-.058.068-.595.594-1.097.57-.549 4.786-3.85 4.718-3.53Z"
    />
    <path
      fill="#1284EA"
      d="M7.336 3.34a44.344 44.344 0 0 0-.594 4.33S2.252 7.132 2 8.217c0-.057.08-.72.605-1.223.571-.548 4.798-3.975 4.73-3.655Z"
    />
    <path
      fill="#B4DC00"
      d="M2.001 7.955v.251c.046-.194.126-.33.286-.548.33-.423.868-.537 1.085-.56a42.824 42.824 0 0 1 4.352-.365c2.89-.092 4.799.148 4.799.148l4.055-3.85s-2.125-.4-4.547-.685a61.64 61.64 0 0 0-3.77-.343c-.057 0-.616-.068-1.142.434-.571.549-3.473 3.302-4.638 4.41C1.955 7.35 2 7.909 2 7.955Z"
    />
    <path
      fill="#6F2DA8"
      d="M16.43 12.879a45.523 45.523 0 0 1-4.353.388c-2.89.092-4.81-.148-4.81-.148L3.212 16.98s2.147.423 4.57.697a63.84 63.84 0 0 0 3.598.308c.057 0 .148-.045.205-.045.058 0 .617-.103 1.143-.606.571-.548 4.021-4.49 3.701-4.455Z"
    />
    <path
      fill="#591F91"
      d="M16.43 12.879a45.528 45.528 0 0 1-4.353.388s.308 4.513-.777 4.707c.057 0 .8-.034 1.325-.537.571-.548 4.124-4.592 3.805-4.558Z"
    />
    <path
      fill="#73BE28"
      d="M11.54 17.997c-.08 0-.16-.012-.251-.012.205-.034.342-.102.56-.262.434-.309.57-.846.616-1.063.194-1.085.457-2.696.583-4.33.228-2.878.091-4.798.091-4.798l4.056-3.86s.297 2.147.468 4.58c.103 1.588.137 2.993.149 3.77 0 .057.045.617-.48 1.12-.571.548-3.473 3.313-4.627 4.42-.548.503-1.108.435-1.165.435Z"
    />
  </svg>
);
export default SvgDynatrace;
