import type { SVGProps } from "react";
import * as React from "react";
const SvgSentry = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      d="M11.273 3.224a1.496 1.496 0 0 0-2.563 0l-2.107 3.61a10.318 10.318 0 0 1 5.685 8.54h-1.48a8.867 8.867 0 0 0-4.952-7.278l-1.95 3.373a5.1 5.1 0 0 1 2.956 3.899H3.463a.242.242 0 0 1-.198-.356l.941-1.601a3.44 3.44 0 0 0-1.076-.61l-.932 1.602a1.455 1.455 0 0 0 .541 2c.221.124.47.19.724.192h4.655a6.215 6.215 0 0 0-2.563-5.545l.74-1.282a7.646 7.646 0 0 1 3.3 6.827h3.943A11.494 11.494 0 0 0 8.28 6.408l1.496-2.563a.247.247 0 0 1 .336-.086c.17.093 6.5 11.138 6.619 11.266a.244.244 0 0 1-.218.362h-1.525c.02.408.02.815 0 1.22h1.531a1.47 1.47 0 0 0 1.281-2.207L11.273 3.224Z"
    />
  </svg>
);
export default SvgSentry;
