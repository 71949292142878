/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InternalStatusPageBuildStructureRequestBody,
    InternalStatusPageBuildStructureRequestBodyFromJSON,
    InternalStatusPageBuildStructureRequestBodyToJSON,
    InternalStatusPageBuildStructureResponseBody,
    InternalStatusPageBuildStructureResponseBodyFromJSON,
    InternalStatusPageBuildStructureResponseBodyToJSON,
    InternalStatusPageCheckSubpathAvailableRequestBody,
    InternalStatusPageCheckSubpathAvailableRequestBodyFromJSON,
    InternalStatusPageCheckSubpathAvailableRequestBodyToJSON,
    InternalStatusPageCreateAutomationRuleRequestBody,
    InternalStatusPageCreateAutomationRuleRequestBodyFromJSON,
    InternalStatusPageCreateAutomationRuleRequestBodyToJSON,
    InternalStatusPageCreateAutomationRuleResponseBody,
    InternalStatusPageCreateAutomationRuleResponseBodyFromJSON,
    InternalStatusPageCreateAutomationRuleResponseBodyToJSON,
    InternalStatusPageCreateIncidentLinkRequestBody,
    InternalStatusPageCreateIncidentLinkRequestBodyFromJSON,
    InternalStatusPageCreateIncidentLinkRequestBodyToJSON,
    InternalStatusPageCreateIncidentLinkResponseBody,
    InternalStatusPageCreateIncidentLinkResponseBodyFromJSON,
    InternalStatusPageCreateIncidentLinkResponseBodyToJSON,
    InternalStatusPageCreatePageRequestBody,
    InternalStatusPageCreatePageRequestBodyFromJSON,
    InternalStatusPageCreatePageRequestBodyToJSON,
    InternalStatusPageCreatePageResponseBody,
    InternalStatusPageCreatePageResponseBodyFromJSON,
    InternalStatusPageCreatePageResponseBodyToJSON,
    InternalStatusPageListAutomationRulesResponseBody,
    InternalStatusPageListAutomationRulesResponseBodyFromJSON,
    InternalStatusPageListAutomationRulesResponseBodyToJSON,
    InternalStatusPageListIncidentLinksResponseBody,
    InternalStatusPageListIncidentLinksResponseBodyFromJSON,
    InternalStatusPageListIncidentLinksResponseBodyToJSON,
    InternalStatusPageListIncidentsResponseBody,
    InternalStatusPageListIncidentsResponseBodyFromJSON,
    InternalStatusPageListIncidentsResponseBodyToJSON,
    InternalStatusPageListPagesResponseBody,
    InternalStatusPageListPagesResponseBodyFromJSON,
    InternalStatusPageListPagesResponseBodyToJSON,
    InternalStatusPageShowPageResponseBody,
    InternalStatusPageShowPageResponseBodyFromJSON,
    InternalStatusPageShowPageResponseBodyToJSON,
    InternalStatusPageUpdateAutomationRuleRequestBody,
    InternalStatusPageUpdateAutomationRuleRequestBodyFromJSON,
    InternalStatusPageUpdateAutomationRuleRequestBodyToJSON,
    InternalStatusPageUpdateAutomationRuleResponseBody,
    InternalStatusPageUpdateAutomationRuleResponseBodyFromJSON,
    InternalStatusPageUpdateAutomationRuleResponseBodyToJSON,
    InternalStatusPageUpdateBrandingRequestBody,
    InternalStatusPageUpdateBrandingRequestBodyFromJSON,
    InternalStatusPageUpdateBrandingRequestBodyToJSON,
    InternalStatusPageUpdateBrandingResponseBody,
    InternalStatusPageUpdateBrandingResponseBodyFromJSON,
    InternalStatusPageUpdateBrandingResponseBodyToJSON,
    InternalStatusPageUpdateDisplayComponentsRequestBody,
    InternalStatusPageUpdateDisplayComponentsRequestBodyFromJSON,
    InternalStatusPageUpdateDisplayComponentsRequestBodyToJSON,
    InternalStatusPageUpdateDisplayComponentsResponseBody,
    InternalStatusPageUpdateDisplayComponentsResponseBodyFromJSON,
    InternalStatusPageUpdateDisplayComponentsResponseBodyToJSON,
    InternalStatusPageUpdateGroupingBehaviourRequestBody,
    InternalStatusPageUpdateGroupingBehaviourRequestBodyFromJSON,
    InternalStatusPageUpdateGroupingBehaviourRequestBodyToJSON,
    InternalStatusPageUpdateGroupingBehaviourResponseBody,
    InternalStatusPageUpdateGroupingBehaviourResponseBodyFromJSON,
    InternalStatusPageUpdateGroupingBehaviourResponseBodyToJSON,
    InternalStatusPageUpdatePageRequestBody,
    InternalStatusPageUpdatePageRequestBodyFromJSON,
    InternalStatusPageUpdatePageRequestBodyToJSON,
    InternalStatusPageUpdatePageResponseBody,
    InternalStatusPageUpdatePageResponseBodyFromJSON,
    InternalStatusPageUpdatePageResponseBodyToJSON,
    InternalStatusPageUpdateSettingDisplaySummaryRequestBody,
    InternalStatusPageUpdateSettingDisplaySummaryRequestBodyFromJSON,
    InternalStatusPageUpdateSettingDisplaySummaryRequestBodyToJSON,
    InternalStatusPageUpdateSettingDisplaySummaryResponseBody,
    InternalStatusPageUpdateSettingDisplaySummaryResponseBodyFromJSON,
    InternalStatusPageUpdateSettingDisplaySummaryResponseBodyToJSON,
    InternalStatusPageUpdateStructureRequestBody,
    InternalStatusPageUpdateStructureRequestBodyFromJSON,
    InternalStatusPageUpdateStructureRequestBodyToJSON,
    InternalStatusPageUpdateStructureResponseBody,
    InternalStatusPageUpdateStructureResponseBodyFromJSON,
    InternalStatusPageUpdateStructureResponseBodyToJSON,
} from '../models';

export interface InternalStatusPageBuildStructureRequest {
    buildStructureRequestBody: InternalStatusPageBuildStructureRequestBody;
}

export interface InternalStatusPageCheckSubpathAvailableRequest {
    checkSubpathAvailableRequestBody: InternalStatusPageCheckSubpathAvailableRequestBody;
}

export interface InternalStatusPageCreateAutomationRuleRequest {
    createAutomationRuleRequestBody: InternalStatusPageCreateAutomationRuleRequestBody;
}

export interface InternalStatusPageCreateIncidentLinkRequest {
    internalStatusPageId: string;
    createIncidentLinkRequestBody: InternalStatusPageCreateIncidentLinkRequestBody;
}

export interface InternalStatusPageCreatePageRequest {
    createPageRequestBody: InternalStatusPageCreatePageRequestBody;
}

export interface InternalStatusPageDestroyAutomationRuleRequest {
    id: string;
}

export interface InternalStatusPageDestroyIncidentRequest {
    id: string;
}

export interface InternalStatusPageDestroyPageRequest {
    id: string;
}

export interface InternalStatusPageListAutomationRulesRequest {
    id: string;
}

export interface InternalStatusPageListIncidentLinksRequest {
    responseIncidentId: string;
}

export interface InternalStatusPageListIncidentsRequest {
    internalStatusPageId: string;
}

export interface InternalStatusPageResyncIncidentsRequest {
    id: string;
}

export interface InternalStatusPageShowPageRequest {
    id: string;
}

export interface InternalStatusPageUpdateAutomationRuleRequest {
    id: string;
    updateAutomationRuleRequestBody: InternalStatusPageUpdateAutomationRuleRequestBody;
}

export interface InternalStatusPageUpdateBrandingRequest {
    id: string;
    updateBrandingRequestBody: InternalStatusPageUpdateBrandingRequestBody;
}

export interface InternalStatusPageUpdateDisplayComponentsRequest {
    id: string;
    updateDisplayComponentsRequestBody: InternalStatusPageUpdateDisplayComponentsRequestBody;
}

export interface InternalStatusPageUpdateGroupingBehaviourRequest {
    id: string;
    updateGroupingBehaviourRequestBody: InternalStatusPageUpdateGroupingBehaviourRequestBody;
}

export interface InternalStatusPageUpdatePageRequest {
    id: string;
    updatePageRequestBody: InternalStatusPageUpdatePageRequestBody;
}

export interface InternalStatusPageUpdateSettingDisplaySummaryRequest {
    id: string;
    updateSettingDisplaySummaryRequestBody: InternalStatusPageUpdateSettingDisplaySummaryRequestBody;
}

export interface InternalStatusPageUpdateStructureRequest {
    id: string;
    updateStructureRequestBody: InternalStatusPageUpdateStructureRequestBody;
}

/**
 * 
 */
export class InternalStatusPageApi extends runtime.BaseAPI {

    /**
     * Will build the structure of an internal status page from a chosen custom field ID.
     * BuildStructure InternalStatusPage
     */
    async internalStatusPageBuildStructureRaw(requestParameters: InternalStatusPageBuildStructureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageBuildStructureResponseBody>> {
        if (requestParameters.buildStructureRequestBody === null || requestParameters.buildStructureRequestBody === undefined) {
            throw new runtime.RequiredError('buildStructureRequestBody','Required parameter requestParameters.buildStructureRequestBody was null or undefined when calling internalStatusPageBuildStructure.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/actions/build_structure`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageBuildStructureRequestBodyToJSON(requestParameters.buildStructureRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageBuildStructureResponseBodyFromJSON(jsonValue));
    }

    /**
     * Will build the structure of an internal status page from a chosen custom field ID.
     * BuildStructure InternalStatusPage
     */
    async internalStatusPageBuildStructure(requestParameters: InternalStatusPageBuildStructureRequest, initOverrides?: RequestInit): Promise<InternalStatusPageBuildStructureResponseBody> {
        const response = await this.internalStatusPageBuildStructureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CheckSubpathAvailable InternalStatusPage
     */
    async internalStatusPageCheckSubpathAvailableRaw(requestParameters: InternalStatusPageCheckSubpathAvailableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.checkSubpathAvailableRequestBody === null || requestParameters.checkSubpathAvailableRequestBody === undefined) {
            throw new runtime.RequiredError('checkSubpathAvailableRequestBody','Required parameter requestParameters.checkSubpathAvailableRequestBody was null or undefined when calling internalStatusPageCheckSubpathAvailable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/actions/check_subpath_available`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageCheckSubpathAvailableRequestBodyToJSON(requestParameters.checkSubpathAvailableRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * CheckSubpathAvailable InternalStatusPage
     */
    async internalStatusPageCheckSubpathAvailable(requestParameters: InternalStatusPageCheckSubpathAvailableRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalStatusPageCheckSubpathAvailableRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new internal status page automation rule
     * CreateAutomationRule InternalStatusPage
     */
    async internalStatusPageCreateAutomationRuleRaw(requestParameters: InternalStatusPageCreateAutomationRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageCreateAutomationRuleResponseBody>> {
        if (requestParameters.createAutomationRuleRequestBody === null || requestParameters.createAutomationRuleRequestBody === undefined) {
            throw new runtime.RequiredError('createAutomationRuleRequestBody','Required parameter requestParameters.createAutomationRuleRequestBody was null or undefined when calling internalStatusPageCreateAutomationRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/automation_rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageCreateAutomationRuleRequestBodyToJSON(requestParameters.createAutomationRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageCreateAutomationRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new internal status page automation rule
     * CreateAutomationRule InternalStatusPage
     */
    async internalStatusPageCreateAutomationRule(requestParameters: InternalStatusPageCreateAutomationRuleRequest, initOverrides?: RequestInit): Promise<InternalStatusPageCreateAutomationRuleResponseBody> {
        const response = await this.internalStatusPageCreateAutomationRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CreateIncidentLink InternalStatusPage
     */
    async internalStatusPageCreateIncidentLinkRaw(requestParameters: InternalStatusPageCreateIncidentLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageCreateIncidentLinkResponseBody>> {
        if (requestParameters.internalStatusPageId === null || requestParameters.internalStatusPageId === undefined) {
            throw new runtime.RequiredError('internalStatusPageId','Required parameter requestParameters.internalStatusPageId was null or undefined when calling internalStatusPageCreateIncidentLink.');
        }

        if (requestParameters.createIncidentLinkRequestBody === null || requestParameters.createIncidentLinkRequestBody === undefined) {
            throw new runtime.RequiredError('createIncidentLinkRequestBody','Required parameter requestParameters.createIncidentLinkRequestBody was null or undefined when calling internalStatusPageCreateIncidentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/{internal_status_page_id}/actions/create_link`.replace(`{${"internal_status_page_id"}}`, encodeURIComponent(String(requestParameters.internalStatusPageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageCreateIncidentLinkRequestBodyToJSON(requestParameters.createIncidentLinkRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageCreateIncidentLinkResponseBodyFromJSON(jsonValue));
    }

    /**
     * CreateIncidentLink InternalStatusPage
     */
    async internalStatusPageCreateIncidentLink(requestParameters: InternalStatusPageCreateIncidentLinkRequest, initOverrides?: RequestInit): Promise<InternalStatusPageCreateIncidentLinkResponseBody> {
        const response = await this.internalStatusPageCreateIncidentLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an internal status page
     * CreatePage InternalStatusPage
     */
    async internalStatusPageCreatePageRaw(requestParameters: InternalStatusPageCreatePageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageCreatePageResponseBody>> {
        if (requestParameters.createPageRequestBody === null || requestParameters.createPageRequestBody === undefined) {
            throw new runtime.RequiredError('createPageRequestBody','Required parameter requestParameters.createPageRequestBody was null or undefined when calling internalStatusPageCreatePage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageCreatePageRequestBodyToJSON(requestParameters.createPageRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageCreatePageResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an internal status page
     * CreatePage InternalStatusPage
     */
    async internalStatusPageCreatePage(requestParameters: InternalStatusPageCreatePageRequest, initOverrides?: RequestInit): Promise<InternalStatusPageCreatePageResponseBody> {
        const response = await this.internalStatusPageCreatePageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives an internal status page automation rule
     * DestroyAutomationRule InternalStatusPage
     */
    async internalStatusPageDestroyAutomationRuleRaw(requestParameters: InternalStatusPageDestroyAutomationRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageDestroyAutomationRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/automation_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an internal status page automation rule
     * DestroyAutomationRule InternalStatusPage
     */
    async internalStatusPageDestroyAutomationRule(requestParameters: InternalStatusPageDestroyAutomationRuleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalStatusPageDestroyAutomationRuleRaw(requestParameters, initOverrides);
    }

    /**
     * Removes an incident from an internal status page. Automation rules will not re-add it even if it matches the rule.
     * DestroyIncident InternalStatusPage
     */
    async internalStatusPageDestroyIncidentRaw(requestParameters: InternalStatusPageDestroyIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageDestroyIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_page_incidents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an incident from an internal status page. Automation rules will not re-add it even if it matches the rule.
     * DestroyIncident InternalStatusPage
     */
    async internalStatusPageDestroyIncident(requestParameters: InternalStatusPageDestroyIncidentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalStatusPageDestroyIncidentRaw(requestParameters, initOverrides);
    }

    /**
     * DestroyPage InternalStatusPage
     */
    async internalStatusPageDestroyPageRaw(requestParameters: InternalStatusPageDestroyPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageDestroyPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * DestroyPage InternalStatusPage
     */
    async internalStatusPageDestroyPage(requestParameters: InternalStatusPageDestroyPageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalStatusPageDestroyPageRaw(requestParameters, initOverrides);
    }

    /**
     * List internal status page automation rules
     * ListAutomationRules InternalStatusPage
     */
    async internalStatusPageListAutomationRulesRaw(requestParameters: InternalStatusPageListAutomationRulesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageListAutomationRulesResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageListAutomationRules.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/automation_rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageListAutomationRulesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List internal status page automation rules
     * ListAutomationRules InternalStatusPage
     */
    async internalStatusPageListAutomationRules(requestParameters: InternalStatusPageListAutomationRulesRequest, initOverrides?: RequestInit): Promise<InternalStatusPageListAutomationRulesResponseBody> {
        const response = await this.internalStatusPageListAutomationRulesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists internal status page incidents that are linked to a given response incident.
     * ListIncidentLinks InternalStatusPage
     */
    async internalStatusPageListIncidentLinksRaw(requestParameters: InternalStatusPageListIncidentLinksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageListIncidentLinksResponseBody>> {
        if (requestParameters.responseIncidentId === null || requestParameters.responseIncidentId === undefined) {
            throw new runtime.RequiredError('responseIncidentId','Required parameter requestParameters.responseIncidentId was null or undefined when calling internalStatusPageListIncidentLinks.');
        }

        const queryParameters: any = {};

        if (requestParameters.responseIncidentId !== undefined) {
            queryParameters['response_incident_id'] = requestParameters.responseIncidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_page_incidents_links`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageListIncidentLinksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists internal status page incidents that are linked to a given response incident.
     * ListIncidentLinks InternalStatusPage
     */
    async internalStatusPageListIncidentLinks(requestParameters: InternalStatusPageListIncidentLinksRequest, initOverrides?: RequestInit): Promise<InternalStatusPageListIncidentLinksResponseBody> {
        const response = await this.internalStatusPageListIncidentLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists incidents that have been pushed to an internal status page
     * ListIncidents InternalStatusPage
     */
    async internalStatusPageListIncidentsRaw(requestParameters: InternalStatusPageListIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageListIncidentsResponseBody>> {
        if (requestParameters.internalStatusPageId === null || requestParameters.internalStatusPageId === undefined) {
            throw new runtime.RequiredError('internalStatusPageId','Required parameter requestParameters.internalStatusPageId was null or undefined when calling internalStatusPageListIncidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.internalStatusPageId !== undefined) {
            queryParameters['internal_status_page_id'] = requestParameters.internalStatusPageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_page_incidents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageListIncidentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists incidents that have been pushed to an internal status page
     * ListIncidents InternalStatusPage
     */
    async internalStatusPageListIncidents(requestParameters: InternalStatusPageListIncidentsRequest, initOverrides?: RequestInit): Promise<InternalStatusPageListIncidentsResponseBody> {
        const response = await this.internalStatusPageListIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all internal status pages
     * ListPages InternalStatusPage
     */
    async internalStatusPageListPagesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageListPagesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_pages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageListPagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all internal status pages
     * ListPages InternalStatusPage
     */
    async internalStatusPageListPages(initOverrides?: RequestInit): Promise<InternalStatusPageListPagesResponseBody> {
        const response = await this.internalStatusPageListPagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Resyncs the incidents on an internal status page according to current automation rules. Removes incidents which no longer match rules, and adds incidents which now match rules. Does not affect manually added incidents.
     * ResyncIncidents InternalStatusPage
     */
    async internalStatusPageResyncIncidentsRaw(requestParameters: InternalStatusPageResyncIncidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageResyncIncidents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_pages/{id}/actions/resync_incidents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resyncs the incidents on an internal status page according to current automation rules. Removes incidents which no longer match rules, and adds incidents which now match rules. Does not affect manually added incidents.
     * ResyncIncidents InternalStatusPage
     */
    async internalStatusPageResyncIncidents(requestParameters: InternalStatusPageResyncIncidentsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalStatusPageResyncIncidentsRaw(requestParameters, initOverrides);
    }

    /**
     * Details of an internal status page
     * ShowPage InternalStatusPage
     */
    async internalStatusPageShowPageRaw(requestParameters: InternalStatusPageShowPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageShowPageResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageShowPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal_status_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageShowPageResponseBodyFromJSON(jsonValue));
    }

    /**
     * Details of an internal status page
     * ShowPage InternalStatusPage
     */
    async internalStatusPageShowPage(requestParameters: InternalStatusPageShowPageRequest, initOverrides?: RequestInit): Promise<InternalStatusPageShowPageResponseBody> {
        const response = await this.internalStatusPageShowPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an internal status page automation rule
     * UpdateAutomationRule InternalStatusPage
     */
    async internalStatusPageUpdateAutomationRuleRaw(requestParameters: InternalStatusPageUpdateAutomationRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageUpdateAutomationRuleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageUpdateAutomationRule.');
        }

        if (requestParameters.updateAutomationRuleRequestBody === null || requestParameters.updateAutomationRuleRequestBody === undefined) {
            throw new runtime.RequiredError('updateAutomationRuleRequestBody','Required parameter requestParameters.updateAutomationRuleRequestBody was null or undefined when calling internalStatusPageUpdateAutomationRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/automation_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageUpdateAutomationRuleRequestBodyToJSON(requestParameters.updateAutomationRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageUpdateAutomationRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an internal status page automation rule
     * UpdateAutomationRule InternalStatusPage
     */
    async internalStatusPageUpdateAutomationRule(requestParameters: InternalStatusPageUpdateAutomationRuleRequest, initOverrides?: RequestInit): Promise<InternalStatusPageUpdateAutomationRuleResponseBody> {
        const response = await this.internalStatusPageUpdateAutomationRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateBranding InternalStatusPage
     */
    async internalStatusPageUpdateBrandingRaw(requestParameters: InternalStatusPageUpdateBrandingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageUpdateBrandingResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageUpdateBranding.');
        }

        if (requestParameters.updateBrandingRequestBody === null || requestParameters.updateBrandingRequestBody === undefined) {
            throw new runtime.RequiredError('updateBrandingRequestBody','Required parameter requestParameters.updateBrandingRequestBody was null or undefined when calling internalStatusPageUpdateBranding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/{id}/actions/update_branding`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageUpdateBrandingRequestBodyToJSON(requestParameters.updateBrandingRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageUpdateBrandingResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateBranding InternalStatusPage
     */
    async internalStatusPageUpdateBranding(requestParameters: InternalStatusPageUpdateBrandingRequest, initOverrides?: RequestInit): Promise<InternalStatusPageUpdateBrandingResponseBody> {
        const response = await this.internalStatusPageUpdateBrandingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateDisplayComponents InternalStatusPage
     */
    async internalStatusPageUpdateDisplayComponentsRaw(requestParameters: InternalStatusPageUpdateDisplayComponentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageUpdateDisplayComponentsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageUpdateDisplayComponents.');
        }

        if (requestParameters.updateDisplayComponentsRequestBody === null || requestParameters.updateDisplayComponentsRequestBody === undefined) {
            throw new runtime.RequiredError('updateDisplayComponentsRequestBody','Required parameter requestParameters.updateDisplayComponentsRequestBody was null or undefined when calling internalStatusPageUpdateDisplayComponents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/{id}/actions/update_display_components`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageUpdateDisplayComponentsRequestBodyToJSON(requestParameters.updateDisplayComponentsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageUpdateDisplayComponentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateDisplayComponents InternalStatusPage
     */
    async internalStatusPageUpdateDisplayComponents(requestParameters: InternalStatusPageUpdateDisplayComponentsRequest, initOverrides?: RequestInit): Promise<InternalStatusPageUpdateDisplayComponentsResponseBody> {
        const response = await this.internalStatusPageUpdateDisplayComponentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateGroupingBehaviour InternalStatusPage
     */
    async internalStatusPageUpdateGroupingBehaviourRaw(requestParameters: InternalStatusPageUpdateGroupingBehaviourRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageUpdateGroupingBehaviourResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageUpdateGroupingBehaviour.');
        }

        if (requestParameters.updateGroupingBehaviourRequestBody === null || requestParameters.updateGroupingBehaviourRequestBody === undefined) {
            throw new runtime.RequiredError('updateGroupingBehaviourRequestBody','Required parameter requestParameters.updateGroupingBehaviourRequestBody was null or undefined when calling internalStatusPageUpdateGroupingBehaviour.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/{id}/actions/update_grouping_behaviour`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageUpdateGroupingBehaviourRequestBodyToJSON(requestParameters.updateGroupingBehaviourRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageUpdateGroupingBehaviourResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateGroupingBehaviour InternalStatusPage
     */
    async internalStatusPageUpdateGroupingBehaviour(requestParameters: InternalStatusPageUpdateGroupingBehaviourRequest, initOverrides?: RequestInit): Promise<InternalStatusPageUpdateGroupingBehaviourResponseBody> {
        const response = await this.internalStatusPageUpdateGroupingBehaviourRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdatePage InternalStatusPage
     */
    async internalStatusPageUpdatePageRaw(requestParameters: InternalStatusPageUpdatePageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageUpdatePageResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageUpdatePage.');
        }

        if (requestParameters.updatePageRequestBody === null || requestParameters.updatePageRequestBody === undefined) {
            throw new runtime.RequiredError('updatePageRequestBody','Required parameter requestParameters.updatePageRequestBody was null or undefined when calling internalStatusPageUpdatePage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageUpdatePageRequestBodyToJSON(requestParameters.updatePageRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageUpdatePageResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdatePage InternalStatusPage
     */
    async internalStatusPageUpdatePage(requestParameters: InternalStatusPageUpdatePageRequest, initOverrides?: RequestInit): Promise<InternalStatusPageUpdatePageResponseBody> {
        const response = await this.internalStatusPageUpdatePageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * UpdateSettingDisplaySummary InternalStatusPage
     */
    async internalStatusPageUpdateSettingDisplaySummaryRaw(requestParameters: InternalStatusPageUpdateSettingDisplaySummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageUpdateSettingDisplaySummaryResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageUpdateSettingDisplaySummary.');
        }

        if (requestParameters.updateSettingDisplaySummaryRequestBody === null || requestParameters.updateSettingDisplaySummaryRequestBody === undefined) {
            throw new runtime.RequiredError('updateSettingDisplaySummaryRequestBody','Required parameter requestParameters.updateSettingDisplaySummaryRequestBody was null or undefined when calling internalStatusPageUpdateSettingDisplaySummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/{id}/actions/update_setting_display_summary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageUpdateSettingDisplaySummaryRequestBodyToJSON(requestParameters.updateSettingDisplaySummaryRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageUpdateSettingDisplaySummaryResponseBodyFromJSON(jsonValue));
    }

    /**
     * UpdateSettingDisplaySummary InternalStatusPage
     */
    async internalStatusPageUpdateSettingDisplaySummary(requestParameters: InternalStatusPageUpdateSettingDisplaySummaryRequest, initOverrides?: RequestInit): Promise<InternalStatusPageUpdateSettingDisplaySummaryResponseBody> {
        const response = await this.internalStatusPageUpdateSettingDisplaySummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the component structure for an internal status page
     * UpdateStructure InternalStatusPage
     */
    async internalStatusPageUpdateStructureRaw(requestParameters: InternalStatusPageUpdateStructureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InternalStatusPageUpdateStructureResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling internalStatusPageUpdateStructure.');
        }

        if (requestParameters.updateStructureRequestBody === null || requestParameters.updateStructureRequestBody === undefined) {
            throw new runtime.RequiredError('updateStructureRequestBody','Required parameter requestParameters.updateStructureRequestBody was null or undefined when calling internalStatusPageUpdateStructure.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/internal_status_pages/{id}/actions/update_structure`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalStatusPageUpdateStructureRequestBodyToJSON(requestParameters.updateStructureRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalStatusPageUpdateStructureResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the component structure for an internal status page
     * UpdateStructure InternalStatusPage
     */
    async internalStatusPageUpdateStructure(requestParameters: InternalStatusPageUpdateStructureRequest, initOverrides?: RequestInit): Promise<InternalStatusPageUpdateStructureResponseBody> {
        const response = await this.internalStatusPageUpdateStructureRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
