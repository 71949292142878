/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentTimelineCreateTimelineItemRequestBody
 */
export interface IncidentTimelineCreateTimelineItemRequestBody {
    /**
     * ID of the activity log entry to create a timeline item from
     * @type {string}
     * @memberof IncidentTimelineCreateTimelineItemRequestBody
     */
    activity_log_id?: string;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentTimelineCreateTimelineItemRequestBody
     */
    description?: TextDocumentPayload;
    /**
     * Timestamp of where this item should sit on the timeline
     * @type {Date}
     * @memberof IncidentTimelineCreateTimelineItemRequestBody
     */
    timestamp?: Date;
    /**
     * Title of the timeline item
     * @type {string}
     * @memberof IncidentTimelineCreateTimelineItemRequestBody
     */
    title?: string;
}

export function IncidentTimelineCreateTimelineItemRequestBodyFromJSON(json: any): IncidentTimelineCreateTimelineItemRequestBody {
    return IncidentTimelineCreateTimelineItemRequestBodyFromJSONTyped(json, false);
}

export function IncidentTimelineCreateTimelineItemRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimelineCreateTimelineItemRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity_log_id': !exists(json, 'activity_log_id') ? undefined : json['activity_log_id'],
        'description': !exists(json, 'description') ? undefined : TextDocumentPayloadFromJSON(json['description']),
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function IncidentTimelineCreateTimelineItemRequestBodyToJSON(value?: IncidentTimelineCreateTimelineItemRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity_log_id': value.activity_log_id,
        'description': TextDocumentPayloadToJSON(value.description),
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'title': value.title,
    };
}

