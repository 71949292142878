/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalUser
 */
export interface ExternalUser {
    /**
     * The URL of this external user's avatar in the integration
     * @type {string}
     * @memberof ExternalUser
     */
    avatar_url?: string;
    /**
     * The email address of this external user
     * @type {string}
     * @memberof ExternalUser
     */
    email?: string;
    /**
     * The ID of this external user within the integration provider
     * @type {string}
     * @memberof ExternalUser
     */
    external_id: string;
    /**
     * The ID of this external user within incident.io
     * @type {string}
     * @memberof ExternalUser
     */
    id: string;
    /**
     * The name of this external user
     * @type {string}
     * @memberof ExternalUser
     */
    name?: string;
    /**
     * The integration provider that this user exists in
     * @type {string}
     * @memberof ExternalUser
     */
    provider: ExternalUserProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof ExternalUser
     */
    user_type: ExternalUserUserTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ExternalUserProviderEnum {
    Asana = 'asana',
    Statuspage = 'statuspage',
    ClickUp = 'click_up',
    Confluence = 'confluence',
    Cortex = 'cortex',
    Datadog = 'datadog',
    Github = 'github',
    Gitlab = 'gitlab',
    GoogleDocs = 'google_docs',
    GoogleMeet = 'google_meet',
    GoogleCalendar = 'google_calendar',
    GoogleWorkspace = 'google_workspace',
    Grafana = 'grafana',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Linear = 'linear',
    MicrosoftTeams = 'microsoft_teams',
    Notion = 'notion',
    OutlookCalendar = 'outlook_calendar',
    Opsgenie = 'opsgenie',
    Opslevel = 'opslevel',
    Pagerduty = 'pagerduty',
    Salesforce = 'salesforce',
    Sentry = 'sentry',
    ServiceNow = 'service_now',
    Shortcut = 'shortcut',
    Scim = 'scim',
    Slack = 'slack',
    SplunkOnCall = 'splunk_on_call',
    IncidentIoStatusPages = 'incident_io_status_pages',
    IncidentIoOnCall = 'incident_io_on_call',
    Vanta = 'vanta',
    Webhooks = 'webhooks',
    Zendesk = 'zendesk',
    Zoom = 'zoom'
}/**
* @export
* @enum {string}
*/
export enum ExternalUserUserTypeEnum {
    Person = 'person',
    Bot = 'bot',
    SharedAccount = 'shared_account'
}

export function ExternalUserFromJSON(json: any): ExternalUser {
    return ExternalUserFromJSONTyped(json, false);
}

export function ExternalUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'external_id': json['external_id'],
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'provider': json['provider'],
        'user_type': json['user_type'],
    };
}

export function ExternalUserToJSON(value?: ExternalUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_url': value.avatar_url,
        'email': value.email,
        'external_id': value.external_id,
        'id': value.id,
        'name': value.name,
        'provider': value.provider,
        'user_type': value.user_type,
    };
}

