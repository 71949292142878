/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleRotationPayload,
    ScheduleRotationPayloadFromJSON,
    ScheduleRotationPayloadFromJSONTyped,
    ScheduleRotationPayloadToJSON,
} from './ScheduleRotationPayload';

/**
 * 
 * @export
 * @interface ScheduleConfigPayload
 */
export interface ScheduleConfigPayload {
    /**
     * Rotas in this schedule
     * @type {Array<ScheduleRotationPayload>}
     * @memberof ScheduleConfigPayload
     */
    rotations: Array<ScheduleRotationPayload>;
    /**
     * The version number of this config
     * @type {number}
     * @memberof ScheduleConfigPayload
     */
    version?: number;
}

export function ScheduleConfigPayloadFromJSON(json: any): ScheduleConfigPayload {
    return ScheduleConfigPayloadFromJSONTyped(json, false);
}

export function ScheduleConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rotations': ((json['rotations'] as Array<any>).map(ScheduleRotationPayloadFromJSON)),
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function ScheduleConfigPayloadToJSON(value?: ScheduleConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rotations': ((value.rotations as Array<any>).map(ScheduleRotationPayloadToJSON)),
        'version': value.version,
    };
}

