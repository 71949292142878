import { InternalStatusPage } from "@incident-io/api";
import { Link, StackedList } from "@incident-ui";
import { sortBy } from "lodash";
import { StatusPageStatusIcon } from "src/components/status-pages/incidents/view/StatusIcons";

export const InternalStatusPagesList = ({
  pages,
}: {
  pages: InternalStatusPage[];
}) => {
  return (
    <StackedList>
      {sortBy(pages, "name").map((page) => (
        <StatusPageListItem page={page} key={page.id} />
      ))}
    </StackedList>
  );
};

const StatusPageListItem = ({
  page,
}: {
  page: InternalStatusPage;
}): React.ReactElement => {
  return (
    <Link
      className="flex p-4 cursor-pointer no-underline text-sm hover:bg-surface-secondary hover:!text-content-primary"
      to={`/status-pages/internal/${page.id}`}
      analyticsTrackingId={"internal-status-page-view-page"}
      analyticsTrackingMetadata={{ status_page_id: page.id }}
    >
      <div className="block grow">
        <div className="font-medium flex items-center">
          <StatusPageStatusIcon
            hasOngoingIncidents={page.has_ongoing_incident}
            className="mr-1"
          />
          {page.name}
        </div>
        <div className="text-content-tertiary">{page.page_url}</div>
      </div>
    </Link>
  );
};
