import { Button, ContentBox, GenericErrorMessage, Loader } from "@incident-ui";
import { captureMessage } from "@sentry/react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { heading, ScimUpsellBanner } from "../../users/scim/ScimShowPageInner";

export const ScimRedirectCard = () => {
  // Grab the config state to know if we should display the upsell component.
  const { identity } = useIdentity();
  const { data: scimConfigState, error: scimConfigError } = useAPI(
    "sCIMShowSettings",
    undefined,
  );

  // Wait for things to load.
  if (!identity || !scimConfigState) {
    return <Loader />;
  }

  // Render an error if the API request for the config failed.
  if (scimConfigError) {
    captureMessage("loading SCIM config", {
      extra: { error: scimConfigError },
    });
    return <GenericErrorMessage error={scimConfigError} />;
  }

  // If the org doesn't have the billing gate, show the upsell banner.
  // If they have SCIM enabled however, show them SCIM. It's possible they downgraded from
  // enterprise, we'll let them disable SCIM, but they won't be able to re-enable it.
  if (!identity?.feature_gates.scim && !scimConfigState.enabled) {
    return <ScimUpsellBanner bare={false} />;
  }

  return (
    <ContentBox className="p-5 space-y-3">
      <div className="space-x-2 flex items-center">
        <span className="font-semibold text-base">{heading}</span>
      </div>
      <div className="text-sm text-slate-700 mb-2 max-w-2xl">
        Configure SCIM in the Users page to automatically provision users and
        configure their roles using your organisation&apos;s identity provider,
        such as Okta or OneLogin.
      </div>
      <Button
        href="/settings/users/scim"
        analyticsTrackingId={`link-to-scim-from-security`}
        className="w-fit"
      >
        SCIM Settings
      </Button>
    </ContentBox>
  );
};
