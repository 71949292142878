/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Settings,
    SettingsFromJSON,
    SettingsFromJSONTyped,
    SettingsToJSON,
} from './Settings';

/**
 * 
 * @export
 * @interface SettingsUpdateFollowUpOwnerRequiredResponseBody
 */
export interface SettingsUpdateFollowUpOwnerRequiredResponseBody {
    /**
     * 
     * @type {Settings}
     * @memberof SettingsUpdateFollowUpOwnerRequiredResponseBody
     */
    settings: Settings;
}

export function SettingsUpdateFollowUpOwnerRequiredResponseBodyFromJSON(json: any): SettingsUpdateFollowUpOwnerRequiredResponseBody {
    return SettingsUpdateFollowUpOwnerRequiredResponseBodyFromJSONTyped(json, false);
}

export function SettingsUpdateFollowUpOwnerRequiredResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsUpdateFollowUpOwnerRequiredResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': SettingsFromJSON(json['settings']),
    };
}

export function SettingsUpdateFollowUpOwnerRequiredResponseBodyToJSON(value?: SettingsUpdateFollowUpOwnerRequiredResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': SettingsToJSON(value.settings),
    };
}

