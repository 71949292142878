/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureItem,
    StatusPageStructureItemFromJSON,
    StatusPageStructureItemFromJSONTyped,
    StatusPageStructureItemToJSON,
} from './StatusPageStructureItem';

/**
 * 
 * @export
 * @interface StatusPageStructure
 */
export interface StatusPageStructure {
    /**
     * A unique ID for this structure
     * @type {string}
     * @memberof StatusPageStructure
     */
    id: string;
    /**
     * Array of components and groups to display in the status page
     * @type {Array<StatusPageStructureItem>}
     * @memberof StatusPageStructure
     */
    items: Array<StatusPageStructureItem>;
    /**
     * The ID of the status page this structure is for
     * @type {string}
     * @memberof StatusPageStructure
     */
    status_page_id: string;
}

export function StatusPageStructureFromJSON(json: any): StatusPageStructure {
    return StatusPageStructureFromJSONTyped(json, false);
}

export function StatusPageStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'items': ((json['items'] as Array<any>).map(StatusPageStructureItemFromJSON)),
        'status_page_id': json['status_page_id'],
    };
}

export function StatusPageStructureToJSON(value?: StatusPageStructure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'items': ((value.items as Array<any>).map(StatusPageStructureItemToJSON)),
        'status_page_id': value.status_page_id,
    };
}

