import {
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

export const WorkflowsTriggerWarningModal = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal
      isOpen={true}
      title={"Are you sure?"}
      analyticsTrackingId="workflows-v2-trigger-warning-modal"
      onClose={onClose}
    >
      <ModalContent>
        <Callout theme={CalloutTheme.Warning}>
          Steps and conditions are trigger-dependent: changing the trigger will
          cause all steps and conditions to be removed.
        </Callout>
      </ModalContent>
      <ModalFooter
        onClose={onClose}
        onConfirm={onConfirm}
        confirmButtonType={"button"}
        confirmButtonText={"Change trigger"}
      />
    </Modal>
  );
};
