import { EngineScope, ScopeNameEnum } from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { DateInputV2 } from "@incident-shared/forms/v2/inputs/DateInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { formatTimestampLocale } from "src/utils/datetime";

import { PolicyCreateEditFormData } from "../common/PolicyCreateEditForm";
import { PolicyEditableSection } from "./PolicyEditableSection";

type IncidentsData = Pick<
  PolicyCreateEditFormData,
  "incident_condition_groups" | "applies_to_all" | "applies_from"
>;

export const PolicyIncidentsSection = ({
  showDrawer,
  setShowDrawer,
  scope,
  slaTimestampName,
}: {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  scope: EngineScope;
  slaTimestampName: string;
}) => {
  const formMethods = useFormContext<PolicyCreateEditFormData>();
  const { setValue } = formMethods;

  const [incident_condition_groups, applies_to_all, applies_from] =
    formMethods.watch([
      "incident_condition_groups",
      "applies_to_all",
      "applies_from",
    ]);

  const onSubmit = (data: IncidentsData) => {
    setValue<"incident_condition_groups">(
      "incident_condition_groups",
      data.incident_condition_groups,
      {
        shouldDirty: true,
      },
    );
    setValue<"applies_to_all">("applies_to_all", data.applies_to_all, {
      shouldDirty: true,
    });
    setValue<"applies_from">("applies_from", data.applies_from, {
      shouldDirty: true,
    });
    setShowDrawer(false);
  };

  return (
    <>
      <AnimatePresence>
        {showDrawer && (
          <IncidentsDrawer
            onSubmit={onSubmit}
            onClose={() => setShowDrawer(false)}
            initialData={{
              incident_condition_groups,
              applies_to_all,
              applies_from,
            }}
            slaTimestampName={slaTimestampName}
            scope={scope}
          />
        )}
      </AnimatePresence>
      <PolicyEditableSection
        onEdit={() => setShowDrawer(true)}
        icon={IconEnum.Filter}
        title={
          <div className="flex flex-wrap gap-1">
            Which <span className="text-blue-content">incidents</span> should
            this policy apply to?
          </div>
        }
        bottomContext={
          applies_from && (
            <>
              Applies to incidents with {slaTimestampName} on or after{" "}
              {formatTimestampLocale({
                timestamp: applies_from,
                dateStyle: "short",
              })}
            </>
          )
        }
      >
        {incident_condition_groups.length === 0 ? (
          <EngineLiteralBadge label="All incidents" noTooltip />
        ) : (
          <ConditionGroupsList
            boxless
            groups={incident_condition_groups}
            iconless
          />
        )}
      </PolicyEditableSection>
    </>
  );
};

const IncidentsDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  slaTimestampName,
}: {
  onSubmit: (data: IncidentsData) => void;
  onClose: () => void;
  initialData?: IncidentsData;
  scope: EngineScope;
  slaTimestampName: string;
}) => {
  const formMethods = useForm<IncidentsData>({
    defaultValues: initialData,
  });

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);
  const [appliesToAll] = formMethods.watch(["applies_to_all"]);

  return (
    <Drawer width="medium" onClose={onClose}>
      <DrawerContents className="overflow-hidden">
        <DrawerTitle
          title="Incidents"
          onClose={onClose}
          icon={IconEnum.Filter}
          subtitle="Choose which incidents this policy should apply to"
        />
        <DrawerBody className="overflow-y-hidden">
          <Form.Root
            fullHeight
            formMethods={formMethods}
            onSubmit={onSubmit}
            id="which-incidents"
          >
            <div className="flex flex-col gap-6">
              <ConditionGroupsEditorV2
                formMethods={formMethods}
                label="Incident conditions"
                name="incident_condition_groups"
                conditionLabel="condition"
                wrapperClassName="mt-2 max-w-full"
                scope={scope}
                entityNameLabel="policy"
                subjectsLabel="incidents"
              />
              <BooleanRadioButtonGroupV2
                className="w-full"
                label="Should this policy apply to all previous incidents?"
                boxed
                trueFirst
                labelClassName="pt-0"
                formMethods={formMethods}
                name="applies_to_all"
                srLabel="Applies to past incidents?"
                trueOption={{
                  label: (
                    <div className="py-2">Apply to all previous incidents</div>
                  ),
                }}
                falseOption={{
                  label: (
                    <div className="flex flex-wrap items-center gap-1">
                      Apply to incidents where
                      <EngineLiteralBadge
                        icon={IconEnum.Clock}
                        label={slaTimestampName}
                      />
                      is on or after
                      <DateInputV2
                        formMethods={formMethods}
                        name="applies_from"
                        required={!appliesToAll}
                      />
                    </div>
                  ),
                }}
              />
            </div>
          </Form.Root>
        </DrawerBody>
        <DrawerFooter className="flex gap-2 justify-end">
          <Button onClick={() => onClose()} analyticsTrackingId={null}>
            Back
          </Button>
          <GatedButton
            form="which-incidents"
            requiredScope={ScopeNameEnum.PoliciesCreate}
            type="submit"
            theme={ButtonTheme.Primary}
            analyticsTrackingId={null}
          >
            Apply
          </GatedButton>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};
