import {
  EngineScope,
  Resource,
  ResourceFieldConfigArrayTypeEnum,
  ResourceFieldConfigTypeEnum,
} from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { CreateEditExpressionFormData } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { UNKNOWN_TYPE } from "@incident-shared/engine/expressions/query/QueryExpressionEditModal";
import { Input, Txt } from "@incident-ui";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { usePrevious } from "use-hooks";

export const ExpressionElseBranchInput = ({
  label,
  className,
  resourceType,
  scope,
  availableReturnTypeResources,
  isArray,
  disabled,
  required,
}: {
  disabled?: boolean;
  label?: string | React.ReactElement;
  className?: string;
  scope: EngineScope;
  isArray: boolean;
  resourceType: string | undefined;
  // The resources currently available, given the users' single/multi return type option.
  // This is determined by whether a resource type can display a single or array form field or not.
  availableReturnTypeResources: Resource[];
  required?: boolean;
}) => {
  const formMethods = useFormContext<CreateEditExpressionFormData>();
  const { watch, setValue } = formMethods;

  const currentResult = watch("else_branch.result");

  const isUnknown = resourceType === UNKNOWN_TYPE;
  const resource = availableReturnTypeResources.find(
    (r) => r.type === resourceType,
  );
  const isFormFieldTypeNone = isArray
    ? resource?.field_config.array_type ===
      ResourceFieldConfigArrayTypeEnum.None
    : resource?.field_config.type === ResourceFieldConfigTypeEnum.None;

  const labelNode =
    typeof label === "string" ? (
      <Txt inline bold>
        {label}{" "}
        <Txt inline grey bold={false}>
          {required ? "" : "(optional)"}
        </Txt>
      </Txt>
    ) : (
      <>{label}</>
    );

  // When the result type / result array of the expression changes, we need
  // to update the else branch accordingly and clear it if it's no longer
  // valid.
  const previousResourceType = usePrevious(resourceType);
  const previousIsArray = usePrevious(isArray);
  useEffect(() => {
    // 1. If the resource type changes, we need to clear everything (unless
    // it's the initial 'mount' of the component).
    if (previousResourceType && previousResourceType !== resourceType) {
      setValue<"else_branch.result.value">(
        "else_branch.result.value",
        undefined,
      );
      setValue<"else_branch.result.array_value">(
        "else_branch.result.array_value",
        undefined,
      );
    }

    // 2. If the array type changes, we need to pull the value into the array_value
    // or vice versa.
    if (previousIsArray !== isArray) {
      const scalarValue = currentResult?.value;
      const scalarIsSet =
        scalarValue && (scalarValue.literal || scalarValue.reference);

      const arrayValue = currentResult?.array_value ?? [];
      const arrayIsSet = arrayValue.length > 0;

      if (isArray) {
        // We're going from scalar to array
        if (scalarIsSet) {
          setValue<"else_branch.result.array_value">(
            "else_branch.result.array_value",
            [scalarValue],
          );
        }
      } else {
        if (arrayIsSet) {
          setValue("else_branch.result.value", arrayValue?.[0]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, isArray]);

  return (
    <Form.InputWrapper
      name={"else_branch.result"}
      className={className}
      label={labelNode}
    >
      {isUnknown || isFormFieldTypeNone ? (
        <Input disabled id={""} />
      ) : (
        <EngineFormElement<CreateEditExpressionFormData>
          // this should re-render if the result type changes, otherwise the dropdowns stay populated with options from the previous result type.
          key={`${resourceType}${isArray}`}
          className="mt-1"
          name={"else_branch.result"}
          resources={availableReturnTypeResources}
          showPlaceholder={!!resourceType}
          array={isArray}
          resourceType={
            // If the resource type of the expression is not one of the
            // available types, use the first available type rather than
            // offering an invalid input.
            resourceType && resource
              ? resourceType
              : availableReturnTypeResources[0]?.type
          }
          mode="variables_only"
          scope={scope}
          required={required || false}
          disabled={!resourceType || !resource || disabled}
        />
      )}
    </Form.InputWrapper>
  );
};
