/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StepConfigSlim
 */
export interface StepConfigSlim {
    /**
     * Human readable identifier for the group this step belongs to
     * @type {string}
     * @memberof StepConfigSlim
     */
    group_label: StepConfigSlimGroupLabelEnum;
    /**
     * Human readable identifier for this step
     * @type {string}
     * @memberof StepConfigSlim
     */
    label: string;
    /**
     * Unique name of the step in the engine
     * @type {string}
     * @memberof StepConfigSlim
     */
    name: string;
}

/**
* @export
* @enum {string}
*/
export enum StepConfigSlimGroupLabelEnum {
    General = 'general',
    Communicate = 'communicate',
    Escalate = 'escalate',
    Announce = 'announce',
    Assign = 'assign'
}

export function StepConfigSlimFromJSON(json: any): StepConfigSlim {
    return StepConfigSlimFromJSONTyped(json, false);
}

export function StepConfigSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepConfigSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group_label': json['group_label'],
        'label': json['label'],
        'name': json['name'],
    };
}

export function StepConfigSlimToJSON(value?: StepConfigSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_label': value.group_label,
        'label': value.label,
        'name': value.name,
    };
}

