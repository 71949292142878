/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalSentryIssue
 */
export interface ExternalSentryIssue {
    /**
     * Sentry issue level
     * @type {string}
     * @memberof ExternalSentryIssue
     */
    level: string;
    /**
     * Current status of the issue
     * @type {string}
     * @memberof ExternalSentryIssue
     */
    status: string;
    /**
     * Title of the issue
     * @type {string}
     * @memberof ExternalSentryIssue
     */
    title: string;
}

export function ExternalSentryIssueFromJSON(json: any): ExternalSentryIssue {
    return ExternalSentryIssueFromJSONTyped(json, false);
}

export function ExternalSentryIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalSentryIssue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
        'status': json['status'],
        'title': json['title'],
    };
}

export function ExternalSentryIssueToJSON(value?: ExternalSentryIssue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'status': value.status,
        'title': value.title,
    };
}

