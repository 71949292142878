/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BecomeEligibleCTA
 */
export interface BecomeEligibleCTA {
    /**
     * Description for the CTA button
     * @type {string}
     * @memberof BecomeEligibleCTA
     */
    description?: string;
    /**
     * If true, open an intercom message instead of a link
     * @type {boolean}
     * @memberof BecomeEligibleCTA
     */
    is_talk_to_us: boolean;
    /**
     * Label for the CTA button
     * @type {string}
     * @memberof BecomeEligibleCTA
     */
    label: string;
    /**
     * URL to send the user to
     * @type {string}
     * @memberof BecomeEligibleCTA
     */
    url: string;
}

export function BecomeEligibleCTAFromJSON(json: any): BecomeEligibleCTA {
    return BecomeEligibleCTAFromJSONTyped(json, false);
}

export function BecomeEligibleCTAFromJSONTyped(json: any, ignoreDiscriminator: boolean): BecomeEligibleCTA {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'is_talk_to_us': json['is_talk_to_us'],
        'label': json['label'],
        'url': json['url'],
    };
}

export function BecomeEligibleCTAToJSON(value?: BecomeEligibleCTA | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'is_talk_to_us': value.is_talk_to_us,
        'label': value.label,
        'url': value.url,
    };
}

