/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamInstall
 */
export interface TeamInstall {
    /**
     * The version of the incident.io bot installed in the Team
     * @type {string}
     * @memberof TeamInstall
     */
    installed_bot_version: string;
    /**
     * Whether this is an older version of the bot that needs upgrading
     * @type {boolean}
     * @memberof TeamInstall
     */
    needs_upgrade: boolean;
    /**
     * ID of the Team in MS Teams
     * @type {string}
     * @memberof TeamInstall
     */
    team_id: string;
}

export function TeamInstallFromJSON(json: any): TeamInstall {
    return TeamInstallFromJSONTyped(json, false);
}

export function TeamInstallFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamInstall {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'installed_bot_version': json['installed_bot_version'],
        'needs_upgrade': json['needs_upgrade'],
        'team_id': json['team_id'],
    };
}

export function TeamInstallToJSON(value?: TeamInstall | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'installed_bot_version': value.installed_bot_version,
        'needs_upgrade': value.needs_upgrade,
        'team_id': value.team_id,
    };
}

