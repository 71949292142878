import type { SVGProps } from "react";
import * as React from "react";
const SvgFakeCheckboxCross = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.067 2.4h-6.8c-1.214 0-2.2.986-2.2 2.2v6.8c0 1.214.986 2.2 2.2 2.2h6.8c1.213 0 2.2-.986 2.2-2.2V4.6c0-1.214-.987-2.2-2.2-2.2Zm-1 3.638a.438.438 0 0 1-.129.31L9.286 8l1.652 1.652a.438.438 0 1 1-.62.62L8.668 8.62l-1.652 1.652a.438.438 0 0 1-.62-.62L8.047 8 6.395 6.348a.438.438 0 0 1 .62-.62L8.667 7.38l1.652-1.652a.438.438 0 0 1 .748.31Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFakeCheckboxCross;
