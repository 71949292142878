import type { SVGProps } from "react";
import * as React from "react";
const SvgChart = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 3H4.75A2.752 2.752 0 0 0 2 5.75v8.5A2.752 2.752 0 0 0 4.75 17h10.5A2.752 2.752 0 0 0 18 14.25v-8.5A2.752 2.752 0 0 0 15.25 3Zm-7.5 2.5a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm-3.25.75a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm10.421.835-2.25 4.5a.752.752 0 0 1-1.314.05l-.841-1.402-1.364 2.388a.749.749 0 0 1-1.237.096l-.44-.55-1.16 1.325a.75.75 0 1 1-1.129-.988l1.75-2a.783.783 0 0 1 .581-.256c.222.005.43.108.569.281l.311.389 1.452-2.542a.751.751 0 0 1 .644-.378c.245 0 .514.136.651.364l.797 1.328 1.639-3.278a.75.75 0 0 1 1.341.671v.002Z"
    />
  </svg>
);
export default SvgChart;
