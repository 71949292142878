import {
  IntegrationSettingsProviderEnum,
  PolicyPolicyTypeEnum,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Card, Modal, ModalContent } from "@incident-ui";
import { useIntegrations } from "src/hooks/useIntegrations";

import { usePolicyTypeConfig } from "../common/config";

export const useAvailablePolicyTypes = () => {
  const { integrations } = useIntegrations();
  const allConfigs = usePolicyTypeConfig();
  let availablePolicyTypes = Object.keys(allConfigs);

  // Currently we only support debrief policies if you have google calendar installed
  const canUseDebriefPolicies =
    (integrations || []).find(
      (x) => x.provider === IntegrationSettingsProviderEnum.GoogleCalendar,
    )?.installed || false;

  if (!canUseDebriefPolicies) {
    availablePolicyTypes = availablePolicyTypes.filter(
      (t) => t !== PolicyPolicyTypeEnum.Debrief,
    );
  }

  return availablePolicyTypes;
};

export const PolicyChooseTypeModal = ({ onClose }: { onClose: () => void }) => {
  const allConfigs = usePolicyTypeConfig();
  const availablePolicyTypes = useAvailablePolicyTypes();
  const navigate = useOrgAwareNavigate();

  const countPolicyTypes = availablePolicyTypes.length;
  const numColumns = countPolicyTypes === 3 ? 3 : 2;

  return (
    <Modal
      analyticsTrackingId="policy-choose-type"
      isOpen={true}
      onClose={onClose}
      title="Create a new policy"
      isExtraLarge
    >
      <ModalContent>
        <div className="flex flex-col gap-4">
          <p>What kind of policy would you like to create?</p>
          <div
            className="grid gap-4 w-full"
            style={{ gridTemplateColumns: `repeat(${numColumns}, 1fr)` }}
          >
            {availablePolicyTypes.map((type) => {
              const config = allConfigs[type];
              return (
                <button
                  onClick={() => {
                    navigate(`/settings/policies/create?type=${type}`);
                    onClose();
                  }}
                  key={type}
                  className="grow"
                >
                  <Card
                    title={config.label}
                    icon={config.icon}
                    clickable
                    description={config.description}
                  />
                </button>
              );
            })}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
