import { Button, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const SourceURLLink = ({
  sourceURL,
  beginEdit,
  textClassName,
}: {
  sourceURL: string;
  beginEdit: () => void;
  textClassName?: string;
}) => {
  return (
    <div className="flex-center-y min-w-[0px] gap-1">
      {/* min-w-[0px] prevents flex from overflowing the container, allowing us to wrap with break-anywhere line-clamp-1 */}
      <a
        className={tcx(
          "text-xs text-terraform-purple-500 font-medium line-clamp-1 break-all",
          textClassName,
        )}
        href={sourceURL}
        target="_blank"
        rel="noreferrer"
      >
        {sourceURL}
      </a>
      <Button
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId={"workflow-terraform-edit-source-url"}
        onClick={beginEdit}
      >
        <Icon
          id={IconEnum.Edit}
          className="text-white !text-terraform-purple-500"
        />
      </Button>
    </div>
  );
};
