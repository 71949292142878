/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttributePayload,
    CatalogTypeAttributePayloadFromJSON,
    CatalogTypeAttributePayloadFromJSONTyped,
    CatalogTypeAttributePayloadToJSON,
} from './CatalogTypeAttributePayload';

/**
 * 
 * @export
 * @interface CatalogUpdateTypeSchemaRequestBody
 */
export interface CatalogUpdateTypeSchemaRequestBody {
    /**
     * 
     * @type {Array<CatalogTypeAttributePayload>}
     * @memberof CatalogUpdateTypeSchemaRequestBody
     */
    attributes: Array<CatalogTypeAttributePayload>;
    /**
     * 
     * @type {number}
     * @memberof CatalogUpdateTypeSchemaRequestBody
     */
    version: number;
}

export function CatalogUpdateTypeSchemaRequestBodyFromJSON(json: any): CatalogUpdateTypeSchemaRequestBody {
    return CatalogUpdateTypeSchemaRequestBodyFromJSONTyped(json, false);
}

export function CatalogUpdateTypeSchemaRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogUpdateTypeSchemaRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(CatalogTypeAttributePayloadFromJSON)),
        'version': json['version'],
    };
}

export function CatalogUpdateTypeSchemaRequestBodyToJSON(value?: CatalogUpdateTypeSchemaRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(CatalogTypeAttributePayloadToJSON)),
        'version': value.version,
    };
}

