/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageAutoCreateStatusPages
 */
export interface StatusPageAutoCreateStatusPages {
    /**
     * The email domains that are allowed to access this auto-create status pages configuration
     * @type {Array<string>}
     * @memberof StatusPageAutoCreateStatusPages
     */
    allowed_email_domains: Array<string>;
    /**
     * The components to include in this auto-create status pages configuration
     * @type {Array<string>}
     * @memberof StatusPageAutoCreateStatusPages
     */
    components: Array<string>;
    /**
     * A unique ID for this auto-create status pages configuration
     * @type {string}
     * @memberof StatusPageAutoCreateStatusPages
     */
    id: string;
    /**
     * A human readable name for this auto-create status pages configuration
     * @type {string}
     * @memberof StatusPageAutoCreateStatusPages
     */
    name: string;
    /**
     * The path of this auto-create status pages configuration
     * @type {string}
     * @memberof StatusPageAutoCreateStatusPages
     */
    path: string;
}

export function StatusPageAutoCreateStatusPagesFromJSON(json: any): StatusPageAutoCreateStatusPages {
    return StatusPageAutoCreateStatusPagesFromJSONTyped(json, false);
}

export function StatusPageAutoCreateStatusPagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageAutoCreateStatusPages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowed_email_domains': json['allowed_email_domains'],
        'components': json['components'],
        'id': json['id'],
        'name': json['name'],
        'path': json['path'],
    };
}

export function StatusPageAutoCreateStatusPagesToJSON(value?: StatusPageAutoCreateStatusPages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowed_email_domains': value.allowed_email_domains,
        'components': value.components,
        'id': value.id,
        'name': value.name,
        'path': value.path,
    };
}

