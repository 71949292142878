/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowsUpdateWorkflowFolderRequestBody
 */
export interface WorkflowsUpdateWorkflowFolderRequestBody {
    /**
     * Folder to display the workflow in
     * @type {string}
     * @memberof WorkflowsUpdateWorkflowFolderRequestBody
     */
    folder?: string;
}

export function WorkflowsUpdateWorkflowFolderRequestBodyFromJSON(json: any): WorkflowsUpdateWorkflowFolderRequestBody {
    return WorkflowsUpdateWorkflowFolderRequestBodyFromJSONTyped(json, false);
}

export function WorkflowsUpdateWorkflowFolderRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsUpdateWorkflowFolderRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
    };
}

export function WorkflowsUpdateWorkflowFolderRequestBodyToJSON(value?: WorkflowsUpdateWorkflowFolderRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folder': value.folder,
    };
}

