import {
  Incident,
  PostmortemDocument,
  PostmortemDocumentStatusEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

const availableDocumentStatuses = [
  PostmortemDocumentStatusEnum.Created,
  PostmortemDocumentStatusEnum.Review,
  PostmortemDocumentStatusEnum.Complete,
];

export const usePostmortem = (incident: Incident) => {
  const {
    data: { documents },
  } = useAPI(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    {
      fallbackData: { documents: [] },
      refreshInterval: (data) => {
        const { enqueuedDocument } = getDocuments(data?.documents);

        // Keep polling until there are no enqueued documents
        return enqueuedDocument ? 1000 : 0;
      },
    },
  );

  return getDocuments(documents);
};

const getDocuments = (
  documents: PostmortemDocument[] | undefined,
): {
  availableDocument: PostmortemDocument | undefined;
  enqueuedDocument: PostmortemDocument | undefined;
  failedDocument: PostmortemDocument | undefined;
} => {
  const availableDocument = (documents ?? []).find((document) =>
    availableDocumentStatuses.includes(document.status),
  );
  const enqueuedDocument = (documents ?? []).find(
    (document) => document.status === PostmortemDocumentStatusEnum.Enqueued,
  );
  const failedDocument = (documents ?? []).find(
    (document) => document.status === PostmortemDocumentStatusEnum.Failed,
  );

  return { availableDocument, enqueuedDocument, failedDocument };
};
