/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManagementMeta,
    ManagementMetaFromJSON,
    ManagementMetaFromJSONTyped,
    ManagementMetaToJSON,
} from './ManagementMeta';
import {
    Workflow,
    WorkflowFromJSON,
    WorkflowFromJSONTyped,
    WorkflowToJSON,
} from './Workflow';

/**
 * 
 * @export
 * @interface WorkflowsDisableWorkflowResponseBody
 */
export interface WorkflowsDisableWorkflowResponseBody {
    /**
     * 
     * @type {ManagementMeta}
     * @memberof WorkflowsDisableWorkflowResponseBody
     */
    management_meta: ManagementMeta;
    /**
     * 
     * @type {Workflow}
     * @memberof WorkflowsDisableWorkflowResponseBody
     */
    workflow: Workflow;
}

export function WorkflowsDisableWorkflowResponseBodyFromJSON(json: any): WorkflowsDisableWorkflowResponseBody {
    return WorkflowsDisableWorkflowResponseBodyFromJSONTyped(json, false);
}

export function WorkflowsDisableWorkflowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsDisableWorkflowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'management_meta': ManagementMetaFromJSON(json['management_meta']),
        'workflow': WorkflowFromJSON(json['workflow']),
    };
}

export function WorkflowsDisableWorkflowResponseBodyToJSON(value?: WorkflowsDisableWorkflowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'management_meta': ManagementMetaToJSON(value.management_meta),
        'workflow': WorkflowToJSON(value.workflow),
    };
}

