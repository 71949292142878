import type { SVGProps } from "react";
import * as React from "react";
const SvgRank = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.28 3.47a.75.75 0 1 0-1.061 1.06l.97.97H7.5A5.504 5.504 0 0 0 2 11c0 3.033 2.467 5.5 5.5 5.5h1.75a.75.75 0 0 0 0-1.5H7.5c-2.206 0-4-1.794-4-4s1.794-4 4-4h.689l-.97.97a.75.75 0 0 0 1.06 1.06l2.25-2.25a.75.75 0 0 0 0-1.06L8.28 3.47ZM17.25 10.25h-4.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5ZM17.25 5.5h-4.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5ZM17.25 15h-4.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5Z"
    />
  </svg>
);
export default SvgRank;
