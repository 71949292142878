import { Button, ButtonTheme } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const CatalogFixedFooter = ({
  children,
  onGoBack,
  backHref,
}: {
  children: React.ReactNode;
  onGoBack?: () => void;
  backHref?: string;
}) => {
  const showBackButton = !!onGoBack || !!backHref;
  return (
    <>
      <div
        className={tcx(
          "absolute bottom-0 right-0 rounded-b-lg",
          "bg-white border-t border-stroke flex items-center justify-between p-4 w-full",
        )}
      >
        {showBackButton ? (
          <Button
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={null}
            onClick={onGoBack}
            href={backHref}
          >
            Back
          </Button>
        ) : (
          // Empty div to keep the layout consistent
          <div />
        )}
        <div className="flex items-center justify-end gap-6">{children}</div>
      </div>
      {/* This ensures that the footer doesn't cover the contents */}
      <div className="h-32" />
    </>
  );
};
