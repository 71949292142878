/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteAlertSourcePayload,
    AlertRouteAlertSourcePayloadFromJSON,
    AlertRouteAlertSourcePayloadFromJSONTyped,
    AlertRouteAlertSourcePayloadToJSON,
} from './AlertRouteAlertSourcePayload';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface AlertRoutesPreviewFindMatchingAlertsRequestBody
 */
export interface AlertRoutesPreviewFindMatchingAlertsRequestBody {
    /**
     * The alert sources to select, including any specific filters per source
     * @type {Array<AlertRouteAlertSourcePayload>}
     * @memberof AlertRoutesPreviewFindMatchingAlertsRequestBody
     */
    alert_sources: Array<AlertRouteAlertSourcePayload>;
    /**
     * The condition groups that the alerts should meet
     * @type {Array<ConditionGroupPayload>}
     * @memberof AlertRoutesPreviewFindMatchingAlertsRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * The expressions to use for the conditions
     * @type {Array<ExpressionPayload>}
     * @memberof AlertRoutesPreviewFindMatchingAlertsRequestBody
     */
    expressions: Array<ExpressionPayload>;
}

export function AlertRoutesPreviewFindMatchingAlertsRequestBodyFromJSON(json: any): AlertRoutesPreviewFindMatchingAlertsRequestBody {
    return AlertRoutesPreviewFindMatchingAlertsRequestBodyFromJSONTyped(json, false);
}

export function AlertRoutesPreviewFindMatchingAlertsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesPreviewFindMatchingAlertsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertRouteAlertSourcePayloadFromJSON)),
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
    };
}

export function AlertRoutesPreviewFindMatchingAlertsRequestBodyToJSON(value?: AlertRoutesPreviewFindMatchingAlertsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertRouteAlertSourcePayloadToJSON)),
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
    };
}

