import type { SVGProps } from "react";
import * as React from "react";
const SvgText = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 17a.75.75 0 0 1-.75-.75V3.75a.75.75 0 0 1 1.5 0v12.5A.75.75 0 0 1 10 17Z"
    />
    <path
      fill="currentColor"
      d="M15.25 4.5H4.75a.75.75 0 0 1 0-1.5h10.5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgText;
