/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';
import {
    IssueTrackerFieldValuePayload,
    IssueTrackerFieldValuePayloadFromJSON,
    IssueTrackerFieldValuePayloadFromJSONTyped,
    IssueTrackerFieldValuePayloadToJSON,
} from './IssueTrackerFieldValuePayload';

/**
 * 
 * @export
 * @interface IssueTrackersV2UpdateIssueTemplateRequestBody
 */
export interface IssueTrackersV2UpdateIssueTemplateRequestBody {
    /**
     * Expressions available for use in templates
     * @type {Array<ExpressionPayload>}
     * @memberof IssueTrackersV2UpdateIssueTemplateRequestBody
     */
    expressions: Array<ExpressionPayload>;
    /**
     * The values to use for each field
     * @type {Array<IssueTrackerFieldValuePayload>}
     * @memberof IssueTrackersV2UpdateIssueTemplateRequestBody
     */
    field_values: Array<IssueTrackerFieldValuePayload>;
    /**
     * The name of the issue template
     * @type {string}
     * @memberof IssueTrackersV2UpdateIssueTemplateRequestBody
     */
    name: string;
}

export function IssueTrackersV2UpdateIssueTemplateRequestBodyFromJSON(json: any): IssueTrackersV2UpdateIssueTemplateRequestBody {
    return IssueTrackersV2UpdateIssueTemplateRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersV2UpdateIssueTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersV2UpdateIssueTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'field_values': ((json['field_values'] as Array<any>).map(IssueTrackerFieldValuePayloadFromJSON)),
        'name': json['name'],
    };
}

export function IssueTrackersV2UpdateIssueTemplateRequestBodyToJSON(value?: IssueTrackersV2UpdateIssueTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'field_values': ((value.field_values as Array<any>).map(IssueTrackerFieldValuePayloadToJSON)),
        'name': value.name,
    };
}

