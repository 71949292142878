import {
  StatusPageCreateRequestBodyDateViewEnum,
  StatusPageCreateRequestBodyThemeEnum,
  StatusPagePageTypeEnum,
  StatusPageParentPageOptionsPageTypeEnum,
  StatusPageThemeEnum,
} from "@incident-io/api";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Button, ButtonTheme, Heading } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { kebabCase } from "lodash";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { useAPIMutation } from "../../../../utils/swr";
import { PageTypeProvider } from "../../common/PageTypeContext";
import {
  ParentFormType,
  SubPagePageSetupForm,
} from "../../create/PageSetupForm";
import { BasicSettingsFormContent } from "../../settings/edit/BasicSettings";
import { buildPageSetupFormData } from "../../settings/edit/PageSetupForm";
import { SubPageCreationForm } from "./SubPageCreateForm";

export const CustomerPageCreateForm = () => {
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const pageSetupDefaults = buildPageSetupFormData();
  const { identity } = useIdentity();

  const formMethods = useForm<ParentFormType>({
    defaultValues: {
      ...pageSetupDefaults,
      name: identity?.organisation_name ?? "",
      subpath: kebabCase(identity?.organisation_name ?? ""),
      mode: "auto_create",
      reason_for_split: "Customer",
      parent_page_type: StatusPagePageTypeEnum.Customer,
      auto_create_sub_pages: {
        first_sub_page: {
          name: "ACME Inc.",
          subpath: "acme-inc",
          allowed_email_domains: [],
        },
      },
      auto_create_components: [
        {
          id: "first_component",
          name: "App",
        },
        {
          id: "second_component",
          name: "Website",
        },
      ],
    },
  });
  const { trigger: onSubmit } = useAPIMutation(
    "statusPageList",
    undefined,
    async (apiClient, data: ParentFormType) => {
      // Create the page
      const { status_page: page } = await apiClient.statusPageCreate({
        createRequestBody: {
          ...data,
          theme: data.theme as unknown as StatusPageCreateRequestBodyThemeEnum,
          // We don't allow list view for customer pages
          date_view: StatusPageCreateRequestBodyDateViewEnum.Calendar,
          darkmode_logo_key:
            data.theme === StatusPageThemeEnum.Dark
              ? data.logo?.key
              : undefined,
          lightmode_logo_key:
            data.theme === StatusPageThemeEnum.Light
              ? data.logo?.key
              : undefined,
          favicon_key: data.favicon?.key,
          parent_page_options: {
            page_type: StatusPageParentPageOptionsPageTypeEnum.Customer,
            auto_create_catalog_configuration:
              data.mode === "auto_create"
                ? {
                    catalog_type_name: data.reason_for_split,
                    status_pages: Object.entries(
                      data.auto_create_sub_pages || {},
                    ).map(([id, page]) => ({
                      ...page,
                      id,
                      path: page.subpath,
                    })),
                    components: data.auto_create_components.map(
                      (component, index) => ({
                        ...component,
                        rank: index,
                      }),
                    ),
                  }
                : undefined,
            split_by_catalog_type_id:
              data.mode === "catalog_import"
                ? data.split_by_catalog_type_id
                : undefined,
            split_by_component_attribute_id:
              data.mode === "catalog_import"
                ? data.components_defined_by_catalog_attribute_id
                : undefined,
            group_by_catalog_attribute_id:
              data.mode === "catalog_import"
                ? data.group_by_defined_by_catalog_attribute_id
                : undefined,
            email_whitelist_defined_by_attribute_id:
              data.mode === "catalog_import"
                ? data.email_whitelist_defined_by_attribute_id
                : undefined,
            sub_pages:
              data.mode === "catalog_import"
                ? _.compact(
                    Object.entries(data.catalog_import_sub_pages || {})
                      .filter((page) => page[1].enabled)
                      .map((page) => ({
                        ...page[1],
                        defined_by_catalog_entry_id: page[0],
                      })),
                  )
                : [],
          },
        },
      });

      navigate(`/status-pages/${page.id}`);
    },
    {
      setError: (path, error) => {
        if (path && path.startsWith("auto_create_sub_pages")) {
          // Find the right part of our form state
          const [_, pageIdx, ...rest] = path.split(".");
          const currentFormState = formMethods.getValues();

          const thisPageID = Object.keys(
            // @ts-expect-error - TS doesn't know this is here, but we know it probably is (and can handle it if we're wrong).
            currentFormState.auto_create_sub_pages || [],
          )[pageIdx];

          path = `auto_create_sub_pages.${thisPageID}.${rest.join(".")}`;
        }

        return formMethods.setError(path, error);
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Could not create status page",
          description:
            "Please try again, or get in touch if the problem persists.",
        });
      },
    },
  );

  return (
    <PageTypeProvider value={StatusPagePageTypeEnum.Customer}>
      <Form.Root
        outerClassName="pb-8"
        innerClassName="space-y-12"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <div className="space-y-6">
          <Heading size="2xl" level={2}>
            Basic details
          </Heading>
          <BasicSettingsFormContent isParentPage />
        </div>
        <FormDivider />
        <div className="space-y-2">
          <Heading size="2xl" level={2}>
            Customer pages
          </Heading>
          <SubPageCreationForm />
        </div>
        <FormDivider />
        <div className="space-y-6">
          <Heading size="2xl" level={2}>
            Page setup
          </Heading>
          <SubPagePageSetupForm />
        </div>
        <Button
          className="ml-auto"
          analyticsTrackingId={"create-status-page"}
          theme={ButtonTheme.Primary}
          type={"submit"}
        >
          Create status page
        </Button>
      </Form.Root>
    </PageTypeProvider>
  );
};
