import {
  CurrencyInput,
  CurrencyInputProps,
} from "@incident-ui/Input/CurrencyInput";
import { ChangeEvent } from "react";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const CurrencyInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, CurrencyInputProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <CurrencyInput
        id={name}
        {...field}
        {...inputProps}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          field.onChange(e);
        }}
      />
    </FormInputWrapper>
  );
};
