import type { SVGProps } from "react";
import * as React from "react";
const SvgPrometheus = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#DA4E31"
      d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm0 14.973c-1.257 0-2.276-.84-2.276-1.876h4.552c0 1.036-1.019 1.876-2.276 1.876Zm3.76-2.497H6.24v-1.363h7.52v1.363Zm-.027-2.065H6.262l-.075-.086c-.77-.935-.95-1.422-1.127-1.92-.003-.016.934.192 1.598.34 0 0 .341.08.84.171a3.122 3.122 0 0 1-.764-2.007c0-1.604 1.23-3.005.787-4.138.431.035.893.912.925 2.282.459-.635.65-1.793.65-2.504 0-.735.486-1.59.97-1.619-.432.712.112 1.323.596 2.838.181.569.158 1.526.298 2.133.047-1.26.263-3.101 1.064-3.736-.353.8.052 1.8.329 2.282.447.777.718 1.365.718 2.477 0 .746-.275 1.449-.74 1.998.528-.1.893-.189.893-.189l1.716-.334s-.25 1.025-1.207 2.012Z"
    />
  </svg>
);
export default SvgPrometheus;
