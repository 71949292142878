/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileSetNotificationTokensRequestBody
 */
export interface MobileSetNotificationTokensRequestBody {
    /**
     * Whether critical notifications are enabled on iOS
     * @type {boolean}
     * @memberof MobileSetNotificationTokensRequestBody
     */
    critical_notifications_enabled?: boolean;
    /**
     * The native push token
     * @type {string}
     * @memberof MobileSetNotificationTokensRequestBody
     */
    device_push_token?: string;
    /**
     * Whether Do Not Disturb access is enabled on Android. This permission powers our ability to temporarily unmute devices.
     * @type {boolean}
     * @memberof MobileSetNotificationTokensRequestBody
     */
    dnd_access_enabled?: boolean;
    /**
     * Whether the user has automatically synced the vCard using Contacts access
     * @type {boolean}
     * @memberof MobileSetNotificationTokensRequestBody
     */
    has_automatically_synced_vcard?: boolean;
    /**
     * Whether the user has manually saved the vCard
     * @type {boolean}
     * @memberof MobileSetNotificationTokensRequestBody
     */
    has_manually_saved_vcard?: boolean;
    /**
     * Whether push notifications are enabled
     * @type {boolean}
     * @memberof MobileSetNotificationTokensRequestBody
     */
    push_notifications_enabled?: boolean;
}

export function MobileSetNotificationTokensRequestBodyFromJSON(json: any): MobileSetNotificationTokensRequestBody {
    return MobileSetNotificationTokensRequestBodyFromJSONTyped(json, false);
}

export function MobileSetNotificationTokensRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileSetNotificationTokensRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'critical_notifications_enabled': !exists(json, 'critical_notifications_enabled') ? undefined : json['critical_notifications_enabled'],
        'device_push_token': !exists(json, 'device_push_token') ? undefined : json['device_push_token'],
        'dnd_access_enabled': !exists(json, 'dnd_access_enabled') ? undefined : json['dnd_access_enabled'],
        'has_automatically_synced_vcard': !exists(json, 'has_automatically_synced_vcard') ? undefined : json['has_automatically_synced_vcard'],
        'has_manually_saved_vcard': !exists(json, 'has_manually_saved_vcard') ? undefined : json['has_manually_saved_vcard'],
        'push_notifications_enabled': !exists(json, 'push_notifications_enabled') ? undefined : json['push_notifications_enabled'],
    };
}

export function MobileSetNotificationTokensRequestBodyToJSON(value?: MobileSetNotificationTokensRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'critical_notifications_enabled': value.critical_notifications_enabled,
        'device_push_token': value.device_push_token,
        'dnd_access_enabled': value.dnd_access_enabled,
        'has_automatically_synced_vcard': value.has_automatically_synced_vcard,
        'has_manually_saved_vcard': value.has_manually_saved_vcard,
        'push_notifications_enabled': value.push_notifications_enabled,
    };
}

