/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrialStatus
 */
export interface TrialStatus {
    /**
     * The trial end date
     * @type {Date}
     * @memberof TrialStatus
     */
    end_at?: Date;
    /**
     * The number of grace incidents remaining
     * @type {number}
     * @memberof TrialStatus
     */
    grace_incidents_remaining: number;
    /**
     * The name of the plan that this organisation is currently on
     * @type {string}
     * @memberof TrialStatus
     */
    plan_name: TrialStatusPlanNameEnum;
    /**
     * Current trial status
     * @type {string}
     * @memberof TrialStatus
     */
    trial_status: TrialStatusTrialStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum TrialStatusPlanNameEnum {
    Legacy = 'legacy',
    StarterV1 = 'starter_v1',
    ProV1 = 'pro_v1',
    EnterpriseV1 = 'enterprise_v1',
    BasicV2 = 'basic_v2',
    TeamV2 = 'team_v2',
    ProV2 = 'pro_v2',
    EnterpriseV2 = 'enterprise_v2',
    Trial = 'trial',
    ProV2Trial = 'pro_v2_trial',
    EnterpriseV2Trial = 'enterprise_v2_trial'
}/**
* @export
* @enum {string}
*/
export enum TrialStatusTrialStatusEnum {
    NotStarted = 'not_started',
    Active = 'active',
    GracePeriod = 'grace_period',
    Expired = 'expired',
    Converted = 'converted',
    Unknown = 'unknown',
    Demo = 'demo'
}

export function TrialStatusFromJSON(json: any): TrialStatus {
    return TrialStatusFromJSONTyped(json, false);
}

export function TrialStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrialStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'grace_incidents_remaining': json['grace_incidents_remaining'],
        'plan_name': json['plan_name'],
        'trial_status': json['trial_status'],
    };
}

export function TrialStatusToJSON(value?: TrialStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': value.end_at === undefined ? undefined : (value.end_at.toISOString()),
        'grace_incidents_remaining': value.grace_incidents_remaining,
        'plan_name': value.plan_name,
        'trial_status': value.trial_status,
    };
}

