import type { SVGProps } from "react";
import * as React from "react";
const SvgMoney = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm2.105 11.243c-.352.36-.828.605-1.355.726v.281a.75.75 0 0 1-1.5 0v-.278c-.986-.206-1.676-.81-1.967-1.764a.75.75 0 1 1 1.435-.437c.119.389.369.786 1.321.786.4 0 .772-.136.994-.362a.737.737 0 0 0 .218-.552c-.008-.323-.123-.688-1.392-.915-1.977-.353-2.378-1.502-2.441-2.156-.062-.646.117-1.221.518-1.662.357-.393.847-.614 1.315-.735V5.75a.75.75 0 0 1 1.5 0v.402a2.51 2.51 0 0 1 1.758 1.507.75.75 0 0 1-1.382.584c-.072-.17-.29-.686-1.088-.686-.276 0-.768.112-.994.361-.071.079-.163.217-.135.51.015.153.06.618 1.212.824 1.712.307 2.597 1.099 2.628 2.354a2.245 2.245 0 0 1-.645 1.637Z"
    />
  </svg>
);
export default SvgMoney;
