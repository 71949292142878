import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import _ from "lodash";

import { PathNode } from "../../common/types";
import {
  makeIfElseNode,
  makeLevelNode,
  makeNotifyChannelNode,
  makeRepeatNode,
} from "./makeNodes";

type UpdateNodesCallback = (
  nodes: Record<string, PathNode>,
  newNode?: { node: PathNode; isFirst: boolean },
) => void;

// insertAboveNode inserts a default level node above the provided node.
export const insertAboveNode = ({
  node,
  nodes,
  updateNodes,
  originalFirstNodeId,
}: {
  node: PathNode;
  nodes: Record<string, PathNode>;
  updateNodes: UpdateNodesCallback;
  originalFirstNodeId: string;
}) => {
  let addToThenBranch: boolean | undefined = undefined;

  // For now, we only support inserting a level node above if the parent node is a level node.
  const parentNode = Object.values(nodes).find((n) => {
    switch (n.data.nodeType) {
      case NodeTypes.Level:
        return n.data.level.nextNodeId === node.id;
      case NodeTypes.NotifyChannel:
        return n.data.notifyChannel.nextNodeId === node.id;
      case NodeTypes.IfElse:
        // Check both the first nodes in both branches
        const childInThenBranch = n.data.ifElse.thenNodeId === node.id;
        const childInElseBranch = n.data.ifElse.elseNodeId === node.id;

        // Set this value so we can pass it to insertBelowNode
        addToThenBranch = childInThenBranch;

        // If either of the first nodes are the child, then this is the parent node
        return childInThenBranch || childInElseBranch;
      default:
        return false;
    }
  });

  // Insert the node below the parent node, if we found one.
  if (parentNode) {
    insertBelowNode({
      node: parentNode,
      nodes,
      updateNodes,
      addToThenBranch: addToThenBranch,
    });
  } else {
    // If we didn't find a parent node, we must be trying to insert something
    // above the first node in the path.
    insertFirstNode({
      nextNode: node,
      nodes,
      updateNodes,
      originalFirstNodeId,
    });
  }
};

// insertFirstNode inserts a node pointing to another node, but does
// not update any parent node. This is useful when inserting
// a new node at the beginning of the path.
const insertFirstNode = ({
  nextNode,
  nodes,
  updateNodes,
  originalFirstNodeId,
}: {
  nextNode: PathNode;
  nodes: Record<string, PathNode>;
  updateNodes: UpdateNodesCallback;
  originalFirstNodeId: string;
}) => {
  const newNodes: Record<string, PathNode> = { ...nodes };

  // Create a new node pointing to the next node
  const newNode = makeLevelNode({ nextNodeId: nextNode.id });
  newNodes[newNode.id] = newNode;

  // We need to update repeat nodes that point to the old
  // first node
  Object.values(nodes).forEach((node) => {
    if (node.data.nodeType === NodeTypes.Repeat) {
      // Update the to_node to point to the new node
      if (node.data.repeat.to_node === originalFirstNodeId) {
        newNodes[node.id] = {
          ...node,
          data: {
            ...node.data,
            repeat: { ...node.data.repeat, to_node: newNode.id },
          },
        };
      }
    }
  });

  updateNodes(newNodes, { node: newNode, isFirst: true });
};

// insertAboveNode inserts a default level node below the provided node.
export const insertBelowNode = ({
  node,
  nodes,
  updateNodes,
  addToThenBranch,
}: {
  node: PathNode;
  nodes: Record<string, PathNode>;
  updateNodes: UpdateNodesCallback;
  addToThenBranch?: boolean;
}) => {
  const newNodes: Record<string, PathNode> = {};
  let newNode: PathNode | undefined;

  Object.entries(nodes).forEach(([key, value]) => {
    if (value.id === node.id) {
      switch (node.data.nodeType) {
        case NodeTypes.Level:
          // Add the new node and make it point to the node that the
          // parent node used to point to.
          const oldLevelNextNodeId = node.data.level.nextNodeId;
          newNode = makeLevelNode({
            nextNodeId: oldLevelNextNodeId,
          });
          newNodes[newNode.id] = newNode;

          // Make the parent node point to the new node.
          node.data.level.nextNodeId = newNode.id;
          break;

        case NodeTypes.NotifyChannel:
          // Add the new node and make it point to the node that the
          // parent node used to point to.
          const oldNextNodeId = node.data.notifyChannel.nextNodeId;
          newNode = makeLevelNode({
            nextNodeId: oldNextNodeId,
          });
          newNodes[newNode.id] = newNode;

          // Make the parent node point to the new node.
          node.data.notifyChannel.nextNodeId = newNode.id;
          break;

        case NodeTypes.IfElse:
          if (addToThenBranch === undefined) {
            throw new Error(
              "Unreachable: addToThenBranch must be provided if adding node below an if else node",
            );
          }

          // Add the new node and make it the first node in the correct branch.
          const oldChildNodeId = addToThenBranch
            ? node.data.ifElse.thenNodeId
            : node.data.ifElse.elseNodeId;
          newNode = makeLevelNode({
            nextNodeId: oldChildNodeId,
          });
          newNodes[newNode.id] = newNode;

          // Make the parent node point to the new node.
          if (addToThenBranch) {
            node.data.ifElse.thenNodeId = newNode.id;
          } else {
            node.data.ifElse.elseNodeId = newNode.id;
          }
          break;

        case NodeTypes.Repeat:
          throw new Error(
            "Unreachable: you can't add a node below a repeat node",
          );
      }
    }

    // Add the original node back.
    newNodes[key] = value;
  });

  updateNodes(
    newNodes,
    newNode ? { node: newNode, isFirst: false } : undefined,
  );
};

// insertIfElseAboveNode adds an ifElse node above the provided node
export const insertIfElseAboveNode = ({
  node,
  nodes,
  firstNodeId,
  updateNodes,
  updateFirstNodeId,
}: {
  node: PathNode;
  nodes: Record<string, PathNode>;
  firstNodeId: string;
  updateNodes: (nodes: Record<string, PathNode>) => void;
  updateFirstNodeId: (id: string) => void;
}): PathNode | undefined => {
  const newNodes: Record<string, PathNode> = {};
  let newNode: PathNode | undefined;

  // Iterates through all the nodes and adds them to newNodes,
  Object.entries(nodes).forEach(([key, value]) => {
    if (value.id === node.id) {
      if (!node.data.level && !node.data.notifyChannel) {
        throw new Error(
          "Unreachable: node must have a level or notify channel data field",
        );
      }

      // Make the else branch nodes
      const repeatNode = makeRepeatNode({ toNodeId: firstNodeId });
      const elseNode = node.data.level
        ? makeLevelNode({
            nextNodeId: repeatNode.id,
          })
        : makeNotifyChannelNode({ nextNodeId: repeatNode.id });

      // Add the new node and make it point to the provided node and
      // the newly created elseNode.
      newNode = makeIfElseNode({
        thenNodeId: node.id,
        elseNodeId: elseNode.id,
      });

      // Make the parent node point to the new node, or if the node is being inserted
      // above the first node, then update the firstNodeId
      if (node.id === firstNodeId) {
        // We need to iterate over all repeat nodes in both the old and new nodes and make them
        // repeat from the new first node. We need to do this in both the old and new nodes
        // in case the repeat node has already been added to newNodes.
        _.union(Object.values(nodes), Object.values(newNodes)).forEach(
          (node) => {
            if (node.data.nodeType === NodeTypes.Repeat) {
              // Update the to_node to point to the new node
              if (node.data.repeat.to_node === firstNodeId)
                node.data.repeat.to_node = newNode?.id || "";
            }
          },
        );

        // Set the new firstNodeId
        updateFirstNodeId(newNode.id);

        // Make the repeat node in the new else branch point to the new first node
        repeatNode.data.repeat.to_node = newNode.id;
      } else {
        const parentNode = setChildOfParentNode({
          nodes,
          oldChildId: node.id,
          newChildId: newNode.id,
        });
        newNodes[parentNode.id] = parentNode;
      }

      // Add all nodes to newNodes
      newNodes[newNode.id] = newNode;
      newNodes[node.id] = node;
      newNodes[elseNode.id] = elseNode;
      newNodes[repeatNode.id] = repeatNode;
    } else {
      // We want to add all other nodes apart from the parent node,
      // because the parent node is updated when the new node is created.
      let isParent = false;

      // Check if the current node is the parent node
      switch (value.data.nodeType) {
        case NodeTypes.Level:
          // This is the parent node if the next node is equal to the provided node
          isParent = value.data.level.nextNodeId === node.id;
          break;
        case NodeTypes.NotifyChannel:
          // This is the parent node if the next node is equal to the provided node
          isParent = value.data.notifyChannel.nextNodeId === node.id;
          break;
        case NodeTypes.IfElse:
          const thenChild = value.data.ifElse.thenNodeId === node.id;
          const elseChild = value.data.ifElse.elseNodeId === node.id;

          // This is the parent node if either the first node in the then branch
          // or the first node in the else branch is the provided node.
          isParent = thenChild || elseChild;
          break;
      }

      // If the current node is not the parent node, add it to the new nodes.
      if (!isParent) newNodes[key] = value;
    }
  });

  updateNodes(newNodes);

  return newNode;
};

// setChildOfParentNode iterates through all nodes to find which node is the parent of
// the node with id oldChildId and update the parent node to point to newChildId.
export const setChildOfParentNode = ({
  nodes,
  oldChildId,
  newChildId,
}: {
  nodes: Record<string, PathNode>;
  oldChildId: string;
  newChildId: string;
}): PathNode => {
  let newParentNode: PathNode | undefined = undefined;

  // Iterate through all the nodes to find the parent node by inspecting
  // the nextNodeId on level nodes, and both thenNodeId and elseNodeId on if/else nodes.
  Object.values(nodes).forEach((node) => {
    switch (node.data.nodeType) {
      case NodeTypes.Level:
        if (node.data.level.nextNodeId === oldChildId) {
          // If the child node is the next node, update the nextNodeId to be newChildId
          newParentNode = {
            ...node,
            data: {
              ...node.data,
              level: { ...node.data.level, nextNodeId: newChildId },
            },
          };
          break;
        }
        break;

      case NodeTypes.NotifyChannel:
        if (node.data.notifyChannel.nextNodeId === oldChildId) {
          // If the child node is the next node, update the nextNodeId to be newChildId
          newParentNode = {
            ...node,
            data: {
              ...node.data,
              notifyChannel: {
                ...node.data.notifyChannel,
                nextNodeId: newChildId,
              },
            },
          };
          break;
        }
        break;

      case NodeTypes.IfElse:
        if (node.data.ifElse.thenNodeId === oldChildId) {
          // If the child node is the first node in the then branch, update the thenNodeId
          // to be newChildId,
          newParentNode = {
            ...node,
            data: {
              ...node.data,
              ifElse: { ...node.data.ifElse, thenNodeId: newChildId },
            },
          };
          break;
        } else if (node.data.ifElse.elseNodeId === oldChildId) {
          // If the child node is the first node in the else branch, update the thenNodeId
          // to be newChildId,
          newParentNode = {
            ...node,
            data: {
              ...node.data,
              ifElse: { ...node.data.ifElse, elseNodeId: newChildId },
            },
          };
          break;
        }
        break;
    }
  });

  if (!newParentNode)
    throw new Error("Unreachable: parent node not found in nodes");

  return newParentNode;
};
