/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsConfluenceGetConfigResponseBody,
    IntegrationsConfluenceGetConfigResponseBodyFromJSON,
    IntegrationsConfluenceGetConfigResponseBodyToJSON,
    IntegrationsConfluenceGetSitesResponseBody,
    IntegrationsConfluenceGetSitesResponseBodyFromJSON,
    IntegrationsConfluenceGetSitesResponseBodyToJSON,
    IntegrationsConfluenceSetEnabledSitesRequestBody,
    IntegrationsConfluenceSetEnabledSitesRequestBodyFromJSON,
    IntegrationsConfluenceSetEnabledSitesRequestBodyToJSON,
    IntegrationsConfluenceSetEnabledSitesResponseBody,
    IntegrationsConfluenceSetEnabledSitesResponseBodyFromJSON,
    IntegrationsConfluenceSetEnabledSitesResponseBodyToJSON,
} from '../models';

export interface IntegrationsConfluenceSetEnabledSitesRequest {
    setEnabledSitesRequestBody: IntegrationsConfluenceSetEnabledSitesRequestBody;
}

/**
 * 
 */
export class IntegrationsConfluenceApi extends runtime.BaseAPI {

    /**
     * Get organisation\'s Confluence config. There is only one active config per org.
     * GetConfig Integrations - Confluence
     */
    async integrationsConfluenceGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsConfluenceGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/confluence/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsConfluenceGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get organisation\'s Confluence config. There is only one active config per org.
     * GetConfig Integrations - Confluence
     */
    async integrationsConfluenceGetConfig(initOverrides?: RequestInit): Promise<IntegrationsConfluenceGetConfigResponseBody> {
        const response = await this.integrationsConfluenceGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Atlassian sites connected user has access to
     * GetSites Integrations - Confluence
     */
    async integrationsConfluenceGetSitesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsConfluenceGetSitesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/confluence/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsConfluenceGetSitesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Atlassian sites connected user has access to
     * GetSites Integrations - Confluence
     */
    async integrationsConfluenceGetSites(initOverrides?: RequestInit): Promise<IntegrationsConfluenceGetSitesResponseBody> {
        const response = await this.integrationsConfluenceGetSitesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set enabled sites
     * SetEnabledSites Integrations - Confluence
     */
    async integrationsConfluenceSetEnabledSitesRaw(requestParameters: IntegrationsConfluenceSetEnabledSitesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsConfluenceSetEnabledSitesResponseBody>> {
        if (requestParameters.setEnabledSitesRequestBody === null || requestParameters.setEnabledSitesRequestBody === undefined) {
            throw new runtime.RequiredError('setEnabledSitesRequestBody','Required parameter requestParameters.setEnabledSitesRequestBody was null or undefined when calling integrationsConfluenceSetEnabledSites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/confluence/sites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsConfluenceSetEnabledSitesRequestBodyToJSON(requestParameters.setEnabledSitesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsConfluenceSetEnabledSitesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set enabled sites
     * SetEnabledSites Integrations - Confluence
     */
    async integrationsConfluenceSetEnabledSites(requestParameters: IntegrationsConfluenceSetEnabledSitesRequest, initOverrides?: RequestInit): Promise<IntegrationsConfluenceSetEnabledSitesResponseBody> {
        const response = await this.integrationsConfluenceSetEnabledSitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
