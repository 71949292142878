import {
  CommandPalette,
  CommandPaletteItem,
} from "@incident-ui/CommandPalette/CommandPalette";
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createContext } from "react";
import { OverrideIdentityModal } from "src/components/dev-tools/OverrideIdentity";
import { ImpersonateModal } from "src/components/impersonation/ImpersonateModal";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useIsAuthenticated } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useLogoutAll } from "src/hooks/useLogoutAll";
import { useAPI } from "src/utils/swr";

export type CommandPaletteContextT = {
  setOpen: (open: boolean) => void;
  open: boolean;
  contextualItems: MutableRefObject<Record<string, CommandPaletteItem[]>>;
  openImpersonateModal: () => void;
  openOverrideIdentityModal: () => void;
  logoutAll: () => Promise<void>;
};

const defaultValues: CommandPaletteContextT = {
  setOpen: () => {
    return;
  },
  open: false,
  contextualItems: { current: {} },
  openOverrideIdentityModal: () => {
    return;
  },
  openImpersonateModal: () => {
    return;
  },
  logoutAll: () => {
    return Promise.resolve();
  },
};

const CommandPaletteContext =
  createContext<CommandPaletteContextT>(defaultValues);

export const useCommandPaletteContext = () => useContext(CommandPaletteContext);

export function CommandPaletteProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [open, setOpen] = useState(false);
  const contextualItems = useRef<Record<string, CommandPaletteItem[]>>({});
  const { data: sessionsData } = useAPI("identitySessionsList", undefined);
  const [impersonateModalOpen, setImpersonateModalOpen] = useState(false);
  const [overrideIdentityModalOpen, setOverrideIdentityModalOpen] =
    useState(false);
  const analytics = useAnalytics();
  const isAuthenticated = useIsAuthenticated();
  const logoutAll = useLogoutAll();
  const { identity } = useIdentity();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isAuthenticated) {
        return;
      }
      if (!identity?.app_installed) {
        return;
      }

      // We also support Windows.
      if ((e.key === "k" && e.metaKey) || (e.key === "k" && e.ctrlKey)) {
        analytics?.track(`command_palette_${!open ? "open" : "close"}`, {});
        setOpen(!open);
        e.preventDefault();
        e.stopPropagation();
      }

      if (e.key === "Escape" && open) {
        setOpen(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown, true);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [setOpen, open, analytics, isAuthenticated, identity]);

  return (
    <CommandPaletteContext.Provider
      value={{
        setOpen,
        open,
        contextualItems,
        openImpersonateModal: () => setImpersonateModalOpen(true),
        openOverrideIdentityModal: () => setOverrideIdentityModalOpen(true),
        logoutAll,
      }}
    >
      {children}
      <CommandPalette
        sessions={sessionsData?.sessions}
        contextualItems={contextualItems}
        open={open}
        setOpen={setOpen}
      />
      {impersonateModalOpen && (
        <ImpersonateModal onClose={() => setImpersonateModalOpen(false)} />
      )}
      {overrideIdentityModalOpen && (
        <OverrideIdentityModal
          onClose={() => setOverrideIdentityModalOpen(false)}
        />
      )}
    </CommandPaletteContext.Provider>
  );
}

export const useContextualItems = (
  groupTitle: string,
  items: CommandPaletteItem[],
) => {
  const { contextualItems } = useCommandPaletteContext();

  contextualItems.current[groupTitle] = items;

  // When the title changes, remove any items from the previous title.
  // That includes when this hook unmounts: it should remove the whole group of
  // items.
  useEffect(
    () => {
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        delete contextualItems.current[groupTitle];
      };
    },
    // We only want to
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupTitle],
  );
};
