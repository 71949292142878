import { DynamicMultiSelect } from "@incident-ui";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { SelectOption } from "src/contexts/ClientContext";

type DynamicFieldSelectProps = {
  fieldKey: string; // dynamic_fields.customfield_10014
  required?: boolean; // false
  label: string;
  helptext?: string | React.ReactElement;
  isMulti?: boolean; // false
  getJiraOptions: (queryOrID: string) => Promise<SelectOption[]>;
  dependencies: unknown[];
  disabled?: boolean;
};

export const JiraCloudDynamicFieldSelect = ({
  fieldKey,
  required,
  label,
  helptext,
  isMulti = false,
  getJiraOptions,
  dependencies,
  disabled,
}: DynamicFieldSelectProps): React.ReactElement => {
  const { control } = useFormContext();

  const [options, setOptions] = useState<SelectOption[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const notReady = dependencies.some(_.isEmpty);

  // Only call getJiraOptions whenever any of our dependencies change, limiting the
  // reloads we make.
  useEffect(() => {
    if (notReady) {
      return;
    }

    async function loadJiraOptions() {
      try {
        setOptionsLoading(true);
        const typeaheadOptions = await getJiraOptions("");
        if (typeaheadOptions == null) {
          return;
        }
        setOptions(typeaheadOptions);
      } catch (error) {
        if (error instanceof Response && error.status === 403) {
          setError(
            "The connection with Jira has expired. Please reconnect your Jira account from the Integrations page.",
          );
        } else {
          setError("Something's gone wrong. Please try again.");
        }
        console.error(error);
      } finally {
        setOptionsLoading(false);
      }
    }

    loadJiraOptions();

    // eslint-disable-next-line
  }, [notReady, ...dependencies]);

  if (isMulti) {
    return (
      <Form.InputWrapper<FieldValues>
        name={fieldKey}
        label={label}
        required={required}
        helptext={helptext}
      >
        <Controller<FieldValues>
          name={fieldKey}
          rules={{
            required: required ? "Please select at least one option" : false,
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <DynamicMultiSelect
                id={`${fieldKey}.array_value`}
                placeholder="Select..."
                invalid={!!error}
                isLoading={optionsLoading}
                isDisabled={disabled}
                onBlur={onBlur}
                onChange={(newVal) => {
                  onChange({
                    array_value: newVal.map((literal) => ({
                      literal,
                    })),
                  });
                }}
                value={value?.array_value?.map(
                  (val: { literal: string }) => val.literal,
                )}
                hydrateOptions={async (ids) => {
                  // NOTE: This is the same as in
                  // `src/components/legacy/integrations/jira/JiraHelpers.tsx`.
                  // We will figure out how to dedupe this when we tackle
                  // IssueTrackerV2!
                  const results = await Promise.all(
                    ids.map((id) => getJiraOptions(id)),
                  );

                  // It's very important that we return at least one option for each
                  // of the ids we've been asked to hydrate. Therefore if the API
                  // response doesn't return an option we need to fake one here.
                  const existingOptions = results.flat();
                  const existingOptionsSet = new Set(
                    existingOptions.map(({ value }) => value),
                  );
                  const missingIds = ids.filter(
                    (id) => !existingOptionsSet.has(id),
                  );

                  return [
                    ...existingOptions,
                    ...missingIds.map((id) => ({
                      value: id,
                      label: `${id} (archived)`,
                    })),
                  ];
                }}
                loadOptions={(query: string) => {
                  const typeaheadOptions = getJiraOptions(query);
                  return typeaheadOptions;
                }}
              />
            );
          }}
          control={control}
        />
      </Form.InputWrapper>
    );
  } else {
    return (
      <Form.InputWrapper
        name={fieldKey}
        label={label}
        required={required}
        helptext={helptext}
      >
        <Controller<FieldValues>
          name={fieldKey}
          rules={{ required: required ? "Please select an option" : false }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <StaticSingleSelect
                id={`${fieldKey}.value.literal`}
                options={options}
                placeholder="Select..."
                invalid={!!error}
                isLoading={optionsLoading}
                disabled={disabled}
                onBlur={onBlur}
                onChange={(newVal) => {
                  onChange({ value: { literal: newVal || "" } });
                }}
                value={value?.value?.literal}
              />
            );
          }}
          control={control}
        />
      </Form.InputWrapper>
    );
  }
};
