import type { SVGProps } from "react";
import * as React from "react";
const SvgOnelogin = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <circle cx={10} cy={10} r={8} fill="#1D1F29" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.385 6.923H7.846v2.462h1.539v3.692h2.154V6.923H9.385Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOnelogin;
