/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SentryChoice
 */
export interface SentryChoice {
    /**
     * Whether we default to this option
     * @type {boolean}
     * @memberof SentryChoice
     */
    _default: boolean;
    /**
     * Human readable label for this option
     * @type {string}
     * @memberof SentryChoice
     */
    label: string;
    /**
     * The value chosen from the option
     * @type {string}
     * @memberof SentryChoice
     */
    value: string;
}

export function SentryChoiceFromJSON(json: any): SentryChoice {
    return SentryChoiceFromJSONTyped(json, false);
}

export function SentryChoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SentryChoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_default': json['default'],
        'label': json['label'],
        'value': json['value'],
    };
}

export function SentryChoiceToJSON(value?: SentryChoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default': value._default,
        'label': value.label,
        'value': value.value,
    };
}

