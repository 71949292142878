import "reactflow/dist/style.css";

import { Button, ButtonTheme, Icon, IconEnum, Tooltip } from "@incident-ui";
import _ from "lodash";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Node,
  useEdgesState,
  useNodesState,
  useOnViewportChange,
  useReactFlow,
  Viewport,
} from "reactflow";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { EscalationPathFormData } from "../common/types";
import { CustomEdge } from "./CustomEdge";
import { CustomNode } from "./CustomNode";
import { getLayoutElements } from "./helpers/getLayoutElements";
import { useViewportProps } from "./helpers/useViewportProps";
import { useZoomContext } from "./ZoomContext";

const nodeTypes = { customNode: CustomNode };
const edgeTypes = { customEdge: CustomEdge };

const proOptions = { hideAttribution: true };

type NodeEditorFormData = Pick<EscalationPathFormData, "firstNodeId" | "nodes">;

export const EscalationPathNodeEditor = () => {
  const formMethods = useFormContext<NodeEditorFormData>();
  const [firstNodeId, nodes] = formMethods.watch(["firstNodeId", "nodes"]);
  const [reactFlowNodes, setReactFlowNodes, onReactFlowNodesChange] =
    useNodesState([]);
  const [reactFlowEdges, setReactFlowEdges, onReactFlowEdgesChange] =
    useEdgesState([]);

  // Listen for changes to the zoom level, and disable the node inputs if we're
  // zoomed in or out
  const { zoomLevel, setZoomLevel } = useZoomContext();
  useOnViewportChange({
    onEnd: (viewport: Viewport) => {
      setZoomLevel(viewport.zoom);
    },
  });

  const {
    data: { priorities },
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  const [nodeHash, setNodeHash] = useState<string>(JSON.stringify(nodes));

  // Hash our nodes by just plain old stringifying them. We need to redraw our graph edges
  // every time that our nodes change, so that we update the content on the edge labels
  // when we change conditions. This wasn't happening before as our nodes are an object,
  // and useEffect doesn't deep compare objects - it just looks at whether the ref has
  // changed.
  if (nodeHash !== JSON.stringify(nodes)) {
    setNodeHash(JSON.stringify(nodes));
  }

  useEffect(() => {
    const { flowNodes, flowEdges } = getLayoutElements({ nodes, priorities });
    setReactFlowNodes(flowNodes);
    setReactFlowEdges(flowEdges);
  }, [
    nodes,
    setReactFlowEdges,
    setReactFlowNodes,
    priorities,
    // Extras!
    // - zoomLevel we want to re-render the grid when it changes, to disable or
    //   enable the card inputs.
    // - nodeHash is helpful because 'nodes' isn't deep-compared (see above)
    zoomLevel,
    nodeHash,
  ]);

  const ref = useRef<HTMLDivElement>(null);

  // Center the grid on the node we've clicked, if we're zoomed in or out
  const { setCenter } = useReactFlow();

  const onNodeClick = useCallback(
    (_: MouseEvent, node: Node) => {
      if (zoomLevel === 1) {
        return;
      }
      const { x, y } = node.position;
      setCenter(x, y, { zoom: 1, duration: 250 });
      setZoomLevel(1);
    },
    [setCenter, setZoomLevel, zoomLevel],
  );

  const [viewportProps, ready] = useViewportProps(
    ref,
    reactFlowNodes,
    firstNodeId, // Focus on the first node
  );

  if (!priorities?.length || !ready) {
    return null;
  }

  return (
    <div className="relative h-full w-full bg-slate-50 !mt-0" ref={ref}>
      <ReactFlow
        nodes={reactFlowNodes}
        onNodesChange={onReactFlowNodesChange}
        edges={reactFlowEdges}
        onEdgesChange={onReactFlowEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={{
          focusable: false,
          style: {
            cursor: "arrow",
          },
          labelBgStyle: {
            cursor: "arrow",
          },
          labelStyle: {
            cursor: "arrow",
          },
        }}
        selectionKeyCode={null}
        proOptions={proOptions}
        edgesFocusable={false}
        nodesConnectable={false}
        panOnScroll
        panOnDrag
        nodesDraggable={false}
        onNodeClick={onNodeClick}
        {...viewportProps}
        onPaneClick={() => {
          //
        }}
      >
        <ZoomControls />
        <Background
          id="1"
          gap={15}
          size={2}
          color="#e1e6eb"
          variant={BackgroundVariant.Dots}
        />
      </ReactFlow>
    </div>
  );
};

const ZoomControls = () => {
  const { zoomLevel, updateZoom } = useZoomContext();
  const MIN_ZOOM = 0.6;
  const MAX_ZOOM = 2;

  return (
    <Controls
      // Hide all of the default controls, because we want to customise them
      // ourselves
      showFitView={false}
      showInteractive={false}
      showZoom={false}
      className="bg-white rounded-[6px] !p-0.5 flex items-center !b-4"
    >
      <Button
        onClick={() => updateZoom(zoomLevel - 0.1)}
        title="Zoom out"
        className={tcx(
          "bg-white border-none cursor-pointer hover:bg-surface-secondary !rounded-[5px]",
          {
            "hover:bg-white": zoomLevel < MIN_ZOOM,
          },
        )}
        analyticsTrackingId={"escalation-path-zoom-out"}
        theme={ButtonTheme.Unstyled}
        disabled={zoomLevel < MIN_ZOOM}
      >
        <Icon
          id={IconEnum.Minus}
          className={tcx("text-slate-600 w-5 h-5", {
            "text-slate-200": zoomLevel < MIN_ZOOM,
          })}
        />
      </Button>
      <Tooltip content="Reset zoom">
        <div
          onClick={() => updateZoom(1)}
          title="Zoom level"
          className="bg-white text-slate-600 font-medium text-sm w-10 text-center hover:bg-white cursor-pointer"
        >
          {_.round(zoomLevel * 100)}%
        </div>
      </Tooltip>
      <Button
        onClick={() => updateZoom(zoomLevel + 0.1)}
        title="Zoom in"
        className={tcx(
          "bg-white border-none cursor-pointer hover:bg-surface-secondary !rounded-[5px]",
          {
            "hover:bg-white": zoomLevel >= MAX_ZOOM,
          },
        )}
        theme={ButtonTheme.Unstyled}
        disabled={zoomLevel >= MAX_ZOOM}
        analyticsTrackingId={"escalation-path-zoom-in"}
      >
        <Icon
          id={IconEnum.Add}
          className={tcx("text-slate-600 w-5 h-5", {
            "text-slate-200": zoomLevel >= MAX_ZOOM,
          })}
        />
      </Button>
    </Controls>
  );
};
