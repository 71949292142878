import type { SVGProps } from "react";
import * as React from "react";
const SvgSort = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.22 13.47-1.97 1.97V9.25a.75.75 0 0 0-1.5 0v6.189l-1.97-1.97a.75.75 0 1 0-1.06 1.061l3.25 3.25a.748.748 0 0 0 1.06 0l3.25-3.25a.75.75 0 1 0-1.061-1.061l.002.001ZM9.72 7.53a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.061l-3.25-3.25a.75.75 0 0 0-1.06 0L3.22 6.47a.75.75 0 1 0 1.06 1.062l1.97-1.97v6.189a.75.75 0 0 0 1.5 0V5.56l1.97 1.97Z"
    />
  </svg>
);
export default SvgSort;
