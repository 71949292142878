import { Alert, AlertSourceConfigSlimSourceTypeEnum } from "@incident-io/api";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import { Sidebar } from "src/components/legacy/incident/sidebar/Components";

export const LinksSection = ({ alert }: { alert: Alert }) => {
  return (
    <Sidebar.Section title="Links" collapsible={false}>
      {alert.dashboard_url && (
        <Sidebar.Entry
          label={`${
            ALERT_SOURCE_TYPE_CONFIGS[alert.alert_source_config.source_type]
              .label
          } dashboard`}
          value={
            <Button
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.Medium}
              href={alert.dashboard_url}
              icon={IconEnum.ExternalLink}
              analyticsTrackingId="alerts-view-dashboard"
            >
              View
            </Button>
          }
        />
      )}
      {alert.source_url && (
        <Sidebar.Entry
          label={`${
            alert.alert_source_config.source_type ===
            AlertSourceConfigSlimSourceTypeEnum.PagerDuty
              ? // For PD alerts, we want to say "Source alert" instead of
                // "PagerDuty alert" since we link to the underlying alert
                "Source"
              : ALERT_SOURCE_TYPE_CONFIGS[alert.alert_source_config.source_type]
                  .label
          } alert`}
          value={
            <Button
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.Medium}
              href={alert.source_url}
              icon={IconEnum.ExternalLink}
              analyticsTrackingId="alerts-view-external-alert"
            >
              View
            </Button>
          }
        />
      )}
    </Sidebar.Section>
  );
};
