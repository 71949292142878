/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssueTrackerField,
    IssueTrackerFieldFromJSON,
    IssueTrackerFieldFromJSONTyped,
    IssueTrackerFieldToJSON,
} from './IssueTrackerField';
import {
    Resource,
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './Resource';

/**
 * 
 * @export
 * @interface IssueTrackersV2ListCreationFieldsResponseBody
 */
export interface IssueTrackersV2ListCreationFieldsResponseBody {
    /**
     * Fields that are available for creating an issue in the given issue tracker
     * @type {Array<IssueTrackerField>}
     * @memberof IssueTrackersV2ListCreationFieldsResponseBody
     */
    fields: Array<IssueTrackerField>;
    /**
     * Whether the set of fields returned is the full set of fields required to create an issue. If this is false, the user needs to provider more field values to discover which fields are available.
     * @type {boolean}
     * @memberof IssueTrackersV2ListCreationFieldsResponseBody
     */
    fields_complete: boolean;
    /**
     * Resources referenced in the fields returned.
     * @type {Array<Resource>}
     * @memberof IssueTrackersV2ListCreationFieldsResponseBody
     */
    resources: Array<Resource>;
    /**
     * Names of fields that are required but not supported by our issue tracker implementation.
     * @type {Array<string>}
     * @memberof IssueTrackersV2ListCreationFieldsResponseBody
     */
    unsupported_required_fields?: Array<string>;
}

export function IssueTrackersV2ListCreationFieldsResponseBodyFromJSON(json: any): IssueTrackersV2ListCreationFieldsResponseBody {
    return IssueTrackersV2ListCreationFieldsResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackersV2ListCreationFieldsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersV2ListCreationFieldsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': ((json['fields'] as Array<any>).map(IssueTrackerFieldFromJSON)),
        'fields_complete': json['fields_complete'],
        'resources': ((json['resources'] as Array<any>).map(ResourceFromJSON)),
        'unsupported_required_fields': !exists(json, 'unsupported_required_fields') ? undefined : json['unsupported_required_fields'],
    };
}

export function IssueTrackersV2ListCreationFieldsResponseBodyToJSON(value?: IssueTrackersV2ListCreationFieldsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': ((value.fields as Array<any>).map(IssueTrackerFieldToJSON)),
        'fields_complete': value.fields_complete,
        'resources': ((value.resources as Array<any>).map(ResourceToJSON)),
        'unsupported_required_fields': value.unsupported_required_fields,
    };
}

