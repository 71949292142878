/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Alert,
    AlertFromJSON,
    AlertFromJSONTyped,
    AlertToJSON,
} from './Alert';
import {
    GroupedAlerts,
    GroupedAlertsFromJSON,
    GroupedAlertsFromJSONTyped,
    GroupedAlertsToJSON,
} from './GroupedAlerts';

/**
 * 
 * @export
 * @interface AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody
 */
export interface AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody {
    /**
     * 
     * @type {Array<GroupedAlerts>}
     * @memberof AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody
     */
    grouped_alerts: Array<GroupedAlerts>;
    /**
     * 
     * @type {Array<Alert>}
     * @memberof AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody
     */
    ungrouped_alerts: Array<Alert>;
}

export function AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBodyFromJSON(json: any): AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody {
    return AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBodyFromJSONTyped(json, false);
}

export function AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grouped_alerts': ((json['grouped_alerts'] as Array<any>).map(GroupedAlertsFromJSON)),
        'ungrouped_alerts': ((json['ungrouped_alerts'] as Array<any>).map(AlertFromJSON)),
    };
}

export function AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBodyToJSON(value?: AlertRoutesPreviewFindAndGroupMatchingAlertsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grouped_alerts': ((value.grouped_alerts as Array<any>).map(GroupedAlertsToJSON)),
        'ungrouped_alerts': ((value.ungrouped_alerts as Array<any>).map(AlertToJSON)),
    };
}

