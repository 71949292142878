import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Card,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  OrgAwareLink,
  SearchBar,
} from "@incident-ui";
import { useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import { Searcher, sortKind } from "fast-fuzzy";
import { groupBy } from "lodash";
import { WorkflowTemplate } from "src/contexts/ClientContext";

import { usePrimaryCommsPlatform } from "../../../../hooks/usePrimaryCommsPlatform";
import {
  WORKFLOW_PRODUCT_MARKETING_REDIRECTS,
  WorkflowProductMarketingRedirect,
} from "../steps/WorkflowAddStepForm";

export type WorkflowTemplateGridProps = {
  templates: WorkflowTemplate[];
};

export const WorkflowsTemplateGrid = ({
  templates,
}: WorkflowTemplateGridProps) => {
  const searchBarProps = useSearchContext();

  const { value: search } = searchBarProps;

  const searcher = new Searcher(templates, {
    keySelector: (template) => [
      template.label,
      template.description,
      template.group,
    ],
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  const filteredTemplates = search ? searcher.search(search) : templates;
  const groupedTemplates = groupBy(filteredTemplates, (t) => t.group);
  const primaryCommsPlatform = usePrimaryCommsPlatform();

  const applicableRedirects = WORKFLOW_PRODUCT_MARKETING_REDIRECTS.filter(
    (redirect) =>
      primaryCommsPlatform &&
      redirect.platforms.includes(primaryCommsPlatform) &&
      redirect.searchTerms.some(
        (term) =>
          !!search &&
          (search.toLowerCase().includes(term.toLowerCase()) ||
            term.toLocaleLowerCase().includes(search.toLowerCase())),
      ),
  );

  return (
    <div className="flex flex-col gap-6 w-full h-full">
      <SearchBar
        {...searchBarProps}
        placeholder="Search workflows"
        autoFocus
        className="w-full pr-6"
      />
      <div className="flex flex-col gap-6 overflow-y-auto pr-6 pb-6 grow">
        {Object.keys(groupedTemplates)
          .sort()
          .map((group, idx) => {
            return (
              <div className="flex flex-col gap-2" key={idx}>
                <div className="text-sm-med text-content-tertiary">{group}</div>
                <div className="grid grid-cols-2 gap-3">
                  {groupedTemplates[group].map((template, templateIdx) => (
                    <WorkflowTemplateCard
                      key={templateIdx}
                      template={template}
                      createHref="/workflows/create"
                    />
                  ))}
                </div>
              </div>
            );
          })}
        {search != null && applicableRedirects.length > 0 && (
          <>
            {applicableRedirects.map((redirect) => (
              <WorkflowProductMarketingRedirect
                key={redirect.key}
                redirect={redirect}
              />
            ))}
          </>
        )}
        {filteredTemplates.length === 0 && (
          <EmptyState
            icon={IconEnum.Workflows}
            title="No templates match your search"
            content="Try adjusting your search terms"
            className="w-full grow"
          />
        )}
      </div>
    </div>
  );
};

export type WorkflowTemplateCardProps = {
  template: WorkflowTemplate;
  createHref: string;
};

export const WorkflowTemplateCard = ({
  template,
  createHref,
}: WorkflowTemplateCardProps) => {
  const isEligible = template.eligibility.is_eligible;
  const href = isEligible
    ? `${createHref}?template=${template.name}`
    : template.eligibility.ineligible_call_to_action_path;

  return (
    <OrgAwareLink
      analyticsTrackingId={`workflows-v2-start-from-template-${template.name}`}
      to={href}
    >
      <Card
        icon={template.icon as unknown as IconEnum}
        title={template.label}
        description={
          isEligible
            ? template.description
            : template.eligibility.ineligible_explanation_text
        }
        clickable={isEligible}
        className={isEligible ? "" : "bg-surface-secondary"}
        color={isEligible ? ColorPaletteEnum.Blue : ColorPaletteEnum.Slate200}
        headerAccessory={
          !isEligible && (
            <Button
              analyticsTrackingId={null}
              size={BadgeSize.Medium}
              theme={ButtonTheme.Secondary}
            >
              {template.eligibility.ineligible_call_to_action_text}
              <Icon id={IconEnum.ExternalLink} size={IconSize.Small} />
            </Button>
          )
        }
      />
    </OrgAwareLink>
  );
};
