/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIAssistantThreadMessage,
    AIAssistantThreadMessageFromJSON,
    AIAssistantThreadMessageFromJSONTyped,
    AIAssistantThreadMessageToJSON,
} from './AIAssistantThreadMessage';

/**
 * 
 * @export
 * @interface AIShowAssistantThreadRunResponseBody
 */
export interface AIShowAssistantThreadRunResponseBody {
    /**
     * Duration of the Run in seconds.
     * @type {number}
     * @memberof AIShowAssistantThreadRunResponseBody
     */
    duration: number;
    /**
     * OpenAI ID of the Run.
     * @type {string}
     * @memberof AIShowAssistantThreadRunResponseBody
     */
    id: string;
    /**
     * Current status of the Run.
     * @type {string}
     * @memberof AIShowAssistantThreadRunResponseBody
     */
    status: AIShowAssistantThreadRunResponseBodyStatusEnum;
    /**
     * An array of messages for this thread, including any new ones generated by this in progress run
     * @type {Array<AIAssistantThreadMessage>}
     * @memberof AIShowAssistantThreadRunResponseBody
     */
    thread_messages: Array<AIAssistantThreadMessage>;
}

/**
* @export
* @enum {string}
*/
export enum AIShowAssistantThreadRunResponseBodyStatusEnum {
    Queued = 'queued',
    InProgress = 'in_progress',
    Completed = 'completed',
    Error = 'error'
}

export function AIShowAssistantThreadRunResponseBodyFromJSON(json: any): AIShowAssistantThreadRunResponseBody {
    return AIShowAssistantThreadRunResponseBodyFromJSONTyped(json, false);
}

export function AIShowAssistantThreadRunResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIShowAssistantThreadRunResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': json['duration'],
        'id': json['id'],
        'status': json['status'],
        'thread_messages': ((json['thread_messages'] as Array<any>).map(AIAssistantThreadMessageFromJSON)),
    };
}

export function AIShowAssistantThreadRunResponseBodyToJSON(value?: AIShowAssistantThreadRunResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': value.duration,
        'id': value.id,
        'status': value.status,
        'thread_messages': ((value.thread_messages as Array<any>).map(AIAssistantThreadMessageToJSON)),
    };
}

