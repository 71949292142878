/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnnouncementPostActionParam
 */
export interface AnnouncementPostActionParam {
    /**
     * Type of this action
     * @type {string}
     * @memberof AnnouncementPostActionParam
     */
    action_type: AnnouncementPostActionParamActionTypeEnum;
    /**
     * Emoji for this action
     * @type {string}
     * @memberof AnnouncementPostActionParam
     */
    emoji: string;
    /**
     * Unique identifier for this announcement post action
     * @type {string}
     * @memberof AnnouncementPostActionParam
     */
    id: string;
    /**
     * Order of this action
     * @type {number}
     * @memberof AnnouncementPostActionParam
     */
    rank: number;
    /**
     * Title of this action
     * @type {string}
     * @memberof AnnouncementPostActionParam
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum AnnouncementPostActionParamActionTypeEnum {
    Homepage = 'announcement_post_actions_homepage',
    Subscribe = 'announcement_post_actions_subscribe',
    JoinCall = 'announcement_post_actions_join_call',
    JiraTicket = 'announcement_post_actions_jira_ticket',
    InternalStatusPage = 'announcement_post_actions_internal_status_page',
    PublicStatusPage = 'announcement_post_actions_public_status_page',
    Postmortem = 'announcement_post_actions_postmortem'
}

export function AnnouncementPostActionParamFromJSON(json: any): AnnouncementPostActionParam {
    return AnnouncementPostActionParamFromJSONTyped(json, false);
}

export function AnnouncementPostActionParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostActionParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_type': json['action_type'],
        'emoji': json['emoji'],
        'id': json['id'],
        'rank': json['rank'],
        'title': json['title'],
    };
}

export function AnnouncementPostActionParamToJSON(value?: AnnouncementPostActionParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': value.action_type,
        'emoji': value.emoji,
        'id': value.id,
        'rank': value.rank,
        'title': value.title,
    };
}

