/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AICreateAssistantThreadQuestionResponseBody
 */
export interface AICreateAssistantThreadQuestionResponseBody {
    /**
     * OpenAI ID of the Run that was created after the question was asked.
     * @type {string}
     * @memberof AICreateAssistantThreadQuestionResponseBody
     */
    run_id: string;
}

export function AICreateAssistantThreadQuestionResponseBodyFromJSON(json: any): AICreateAssistantThreadQuestionResponseBody {
    return AICreateAssistantThreadQuestionResponseBodyFromJSONTyped(json, false);
}

export function AICreateAssistantThreadQuestionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AICreateAssistantThreadQuestionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'run_id': json['run_id'],
    };
}

export function AICreateAssistantThreadQuestionResponseBodyToJSON(value?: AICreateAssistantThreadQuestionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'run_id': value.run_id,
    };
}

