/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PolicyReportSchedule,
    PolicyReportScheduleFromJSON,
    PolicyReportScheduleFromJSONTyped,
    PolicyReportScheduleToJSON,
} from './PolicyReportSchedule';

/**
 * 
 * @export
 * @interface PoliciesListReportScheduleResponseBody
 */
export interface PoliciesListReportScheduleResponseBody {
    /**
     * 
     * @type {Array<PolicyReportSchedule>}
     * @memberof PoliciesListReportScheduleResponseBody
     */
    report_schedules: Array<PolicyReportSchedule>;
}

export function PoliciesListReportScheduleResponseBodyFromJSON(json: any): PoliciesListReportScheduleResponseBody {
    return PoliciesListReportScheduleResponseBodyFromJSONTyped(json, false);
}

export function PoliciesListReportScheduleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesListReportScheduleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report_schedules': ((json['report_schedules'] as Array<any>).map(PolicyReportScheduleFromJSON)),
    };
}

export function PoliciesListReportScheduleResponseBodyToJSON(value?: PoliciesListReportScheduleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report_schedules': ((value.report_schedules as Array<any>).map(PolicyReportScheduleToJSON)),
    };
}

