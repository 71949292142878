import { Condition } from "@incident-io/api";
import {
  ConditionsListSentenceProps,
  EditableConditionsList,
  EditableConditionsListProps,
  FixedConditionProps,
} from "@incident-shared/engine/conditions";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import _ from "lodash";
import {
  ArrayPath,
  FieldValues,
  useController,
  UseFieldArrayReturn,
} from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

type ExpressionsProps =
  | {
      allowFilteringByExpression?: boolean;
      expressions?: ExpressionFormData[];
      allowAllOfACatalogTypeInQueryExpression: boolean;
    }
  | {
      allowFilteringByExpression?: never;
      expressions?: never;
      allowAllOfACatalogTypeInQueryExpression?: never;
    };

type ConditionsEditorV2Props<TFormType extends FieldValues> = Omit<
  EditableConditionsListProps,
  | "onDeleteCondition"
  | "onAddCondition"
  | "onEditCondition"
  | "selectedConditions"
> & {
  expressionsMethods?: UseFieldArrayReturn<
    TFormType,
    ArrayPath<TFormType>,
    "key"
  >;
} & ExpressionsProps &
  ConditionsListSentenceProps &
  FixedConditionProps;

export const ConditionsEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, ConditionsEditorV2Props<TFormType>>,
): React.ReactElement => {
  const { name, inputProps, wrapperProps } = parseProps(props);

  const {
    field,
    // We don't want to pass the ref onwards here: this is a complex component
    // which references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.,
  } = useController({
    name,
    rules: props.rules,
  });

  const value = (field.value ?? []) as Condition[];
  const onChange = field.onChange as (value: Condition[]) => void;

  const onAddCondition = (condition: Condition) => {
    onChange([...value, condition]);
  };

  const onEditCondition = (idx: number, condition: Condition) => {
    const newConditions = [...value];
    newConditions[idx] = condition;
    onChange(newConditions);
  };

  const onDeleteCondition = (idx: number) => {
    onChange(value.filter((_, conditionIdx) => conditionIdx !== idx));
  };

  const mutations = {
    onAddCondition,
    onEditCondition,
    onDeleteCondition,
  };

  let scope = props.scope;
  const expressions = props.expressions;
  if (expressions) {
    scope = addExpressionsToScope(scope, expressions);
  }

  // Ensure we don't have any duplicates in our scope
  scope.references = _.unionBy(scope.references, "key");

  return (
    <FormInputWrapper<TFormType> {...wrapperProps} name={name}>
      {props.expressionsMethods ? (
        <ExpressionsMethodsProvider
          expressionsMethods={props.expressionsMethods}
          allowAllOfACatalogType={
            props.allowAllOfACatalogTypeInQueryExpression || false
          }
        >
          <EditableConditionsList
            {...(inputProps as EditableConditionsListProps)}
            {...mutations}
            selectedConditions={value}
            scope={scope}
          />
        </ExpressionsMethodsProvider>
      ) : (
        <EditableConditionsList
          {...(inputProps as EditableConditionsListProps)}
          {...mutations}
          selectedConditions={value}
          scope={scope}
        />
      )}
    </FormInputWrapper>
  );
};
