/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableExpressionOperation,
    AvailableExpressionOperationFromJSON,
    AvailableExpressionOperationFromJSONTyped,
    AvailableExpressionOperationToJSON,
} from './AvailableExpressionOperation';

/**
 * 
 * @export
 * @interface AvailableExpressionOperations
 */
export interface AvailableExpressionOperations {
    /**
     * the operations that can be applied to an array value of this resource type
     * @type {Array<AvailableExpressionOperation>}
     * @memberof AvailableExpressionOperations
     */
    array: Array<AvailableExpressionOperation>;
    /**
     * the operations that can be applied to a scalar value of this resource type
     * @type {Array<AvailableExpressionOperation>}
     * @memberof AvailableExpressionOperations
     */
    scalar: Array<AvailableExpressionOperation>;
}

export function AvailableExpressionOperationsFromJSON(json: any): AvailableExpressionOperations {
    return AvailableExpressionOperationsFromJSONTyped(json, false);
}

export function AvailableExpressionOperationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableExpressionOperations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array': ((json['array'] as Array<any>).map(AvailableExpressionOperationFromJSON)),
        'scalar': ((json['scalar'] as Array<any>).map(AvailableExpressionOperationFromJSON)),
    };
}

export function AvailableExpressionOperationsToJSON(value?: AvailableExpressionOperations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array': ((value.array as Array<any>).map(AvailableExpressionOperationToJSON)),
        'scalar': ((value.scalar as Array<any>).map(AvailableExpressionOperationToJSON)),
    };
}

