import { CatalogEntry, CatalogType } from "@incident-io/api";
import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import { CopiableElement } from "@incident-ui/CopiableElement/CopiableElement";
import React from "react";

export const CatalogEntryIdentifiers = ({
  entry,
  type,
  onEditEntry,
  description,
}: {
  entry: CatalogEntry;
  type: CatalogType;
  onEditEntry?: () => void;
  description?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col items-start gap-4 border-stroke-primary border-l-[3px] pl-4">
      <div className="text-xs-med text-content-secondary">{description}</div>
      <Identifier
        label="External ID"
        content={entry.external_id ? [entry.external_id] : []}
        description="Unique identifier defined by external system"
      />

      <Identifier
        label="Aliases"
        content={entry.aliases}
        description="Any other identifiers for this entry"
      />
      {type.use_name_as_identifier ? (
        <Identifier
          label="Name"
          content={[entry.name]}
          description="The name of this entry. This may change, so be careful using it in external systems."
        />
      ) : null}
      {onEditEntry ? (
        <Button
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Edit}
          size={BadgeSize.Medium}
          analyticsTrackingId="catalog-identifiers-edit-entry"
          onClick={onEditEntry}
        >
          Edit this {type.name}
        </Button>
      ) : null}
    </div>
  );
};

const Identifier = ({
  label,
  content,
  description,
}: {
  label: string;
  content: string[];
  description: string;
}) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <div className="text-sm-bold">{label}</div>
      <div className="flex items-center gap-1">
        {content.length ? (
          content.map((c) => <CopiableElement content={c} key={c} />)
        ) : (
          <div className="text-content-tertiary">Not set</div>
        )}
      </div>
      <div className="text-xs-med text-content-tertiary">{description}</div>
    </div>
  );
};
