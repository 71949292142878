/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    StatusPageAffectedComponent,
    StatusPageAffectedComponentFromJSON,
    StatusPageAffectedComponentFromJSONTyped,
    StatusPageAffectedComponentToJSON,
} from './StatusPageAffectedComponent';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageIncidentUpdate
 */
export interface StatusPageIncidentUpdate {
    /**
     * The updated statuses of affected components
     * @type {Array<StatusPageAffectedComponent>}
     * @memberof StatusPageIncidentUpdate
     */
    component_statuses: Array<StatusPageAffectedComponent>;
    /**
     * When this incident update was created
     * @type {Date}
     * @memberof StatusPageIncidentUpdate
     */
    created_at: Date;
    /**
     * The ID to the response incident this update was created from
     * @type {string}
     * @memberof StatusPageIncidentUpdate
     */
    created_from_response_incident_id?: string;
    /**
     * The ID of the template that was applied when sending this update
     * @type {string}
     * @memberof StatusPageIncidentUpdate
     */
    created_from_template_id?: string;
    /**
     * 
     * @type {Actor}
     * @memberof StatusPageIncidentUpdate
     */
    creator: Actor;
    /**
     * A unique ID for this status page incident update
     * @type {string}
     * @memberof StatusPageIncidentUpdate
     */
    id: string;
    /**
     * The IDs of subpages that this incident should be linked to. When `null` or empty, the sub-page will be inferred from the components.
     * @type {Array<string>}
     * @memberof StatusPageIncidentUpdate
     */
    manual_sub_page_ids?: Array<string>;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageIncidentUpdate
     */
    message: TextNode;
    /**
     * When this incident update was published to the status page
     * @type {Date}
     * @memberof StatusPageIncidentUpdate
     */
    published_at: Date;
    /**
     * Order of this update among others. This must be unique within the incident.
     * @type {number}
     * @memberof StatusPageIncidentUpdate
     */
    sort_key: number;
    /**
     * The ID of the status page incident this relates to
     * @type {string}
     * @memberof StatusPageIncidentUpdate
     */
    status_page_incident_id: string;
    /**
     * If true, don't notify subscribers of this update
     * @type {boolean}
     * @memberof StatusPageIncidentUpdate
     */
    suppress_notifications: boolean;
    /**
     * Set to true if the update's timestamp has been edited
     * @type {boolean}
     * @memberof StatusPageIncidentUpdate
     */
    timestamp_updated?: boolean;
    /**
     * The new status of this incident
     * @type {string}
     * @memberof StatusPageIncidentUpdate
     */
    to_status: StatusPageIncidentUpdateToStatusEnum;
    /**
     * When this incident update was last updated
     * @type {Date}
     * @memberof StatusPageIncidentUpdate
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageIncidentUpdateToStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function StatusPageIncidentUpdateFromJSON(json: any): StatusPageIncidentUpdate {
    return StatusPageIncidentUpdateFromJSONTyped(json, false);
}

export function StatusPageIncidentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageIncidentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_statuses': ((json['component_statuses'] as Array<any>).map(StatusPageAffectedComponentFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'created_from_response_incident_id': !exists(json, 'created_from_response_incident_id') ? undefined : json['created_from_response_incident_id'],
        'created_from_template_id': !exists(json, 'created_from_template_id') ? undefined : json['created_from_template_id'],
        'creator': ActorFromJSON(json['creator']),
        'id': json['id'],
        'manual_sub_page_ids': !exists(json, 'manual_sub_page_ids') ? undefined : json['manual_sub_page_ids'],
        'message': TextNodeFromJSON(json['message']),
        'published_at': (new Date(json['published_at'])),
        'sort_key': json['sort_key'],
        'status_page_incident_id': json['status_page_incident_id'],
        'suppress_notifications': json['suppress_notifications'],
        'timestamp_updated': !exists(json, 'timestamp_updated') ? undefined : json['timestamp_updated'],
        'to_status': json['to_status'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function StatusPageIncidentUpdateToJSON(value?: StatusPageIncidentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_statuses': ((value.component_statuses as Array<any>).map(StatusPageAffectedComponentToJSON)),
        'created_at': (value.created_at.toISOString()),
        'created_from_response_incident_id': value.created_from_response_incident_id,
        'created_from_template_id': value.created_from_template_id,
        'creator': ActorToJSON(value.creator),
        'id': value.id,
        'manual_sub_page_ids': value.manual_sub_page_ids,
        'message': TextNodeToJSON(value.message),
        'published_at': (value.published_at.toISOString()),
        'sort_key': value.sort_key,
        'status_page_incident_id': value.status_page_incident_id,
        'suppress_notifications': value.suppress_notifications,
        'timestamp_updated': value.timestamp_updated,
        'to_status': value.to_status,
        'updated_at': (value.updated_at.toISOString()),
    };
}

