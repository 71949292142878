/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttribute,
    CatalogTypeAttributeFromJSON,
    CatalogTypeAttributeFromJSONTyped,
    CatalogTypeAttributeToJSON,
} from './CatalogTypeAttribute';

/**
 * 
 * @export
 * @interface CatalogTypeSchema
 */
export interface CatalogTypeSchema {
    /**
     * Attributes of this catalog type
     * @type {Array<CatalogTypeAttribute>}
     * @memberof CatalogTypeSchema
     */
    attributes: Array<CatalogTypeAttribute>;
    /**
     * The version number of this schema
     * @type {number}
     * @memberof CatalogTypeSchema
     */
    version: number;
}

export function CatalogTypeSchemaFromJSON(json: any): CatalogTypeSchema {
    return CatalogTypeSchemaFromJSONTyped(json, false);
}

export function CatalogTypeSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogTypeSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(CatalogTypeAttributeFromJSON)),
        'version': json['version'],
    };
}

export function CatalogTypeSchemaToJSON(value?: CatalogTypeSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(CatalogTypeAttributeToJSON)),
        'version': value.version,
    };
}

