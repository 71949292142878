/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageUpdatePageSetupRequestBody
 */
export interface StatusPageUpdatePageSetupRequestBody {
    /**
     * The GCS key where the logo for this status page is stored
     * @type {string}
     * @memberof StatusPageUpdatePageSetupRequestBody
     */
    darkmode_logo_key?: string;
    /**
     * Whether to use Calendar or List view
     * @type {string}
     * @memberof StatusPageUpdatePageSetupRequestBody
     */
    date_view?: StatusPageUpdatePageSetupRequestBodyDateViewEnum;
    /**
     * The GCS key where the favicon for this status page is stored
     * @type {string}
     * @memberof StatusPageUpdatePageSetupRequestBody
     */
    favicon_key?: string;
    /**
     * The GCS key where the lightmode logo for this status page is stored
     * @type {string}
     * @memberof StatusPageUpdatePageSetupRequestBody
     */
    lightmode_logo_key?: string;
    /**
     * Whether to use Light mode or Dark mode
     * @type {string}
     * @memberof StatusPageUpdatePageSetupRequestBody
     */
    theme: StatusPageUpdatePageSetupRequestBodyThemeEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageUpdatePageSetupRequestBodyDateViewEnum {
    Calendar = 'calendar',
    List = 'list'
}/**
* @export
* @enum {string}
*/
export enum StatusPageUpdatePageSetupRequestBodyThemeEnum {
    Light = 'light',
    Dark = 'dark'
}

export function StatusPageUpdatePageSetupRequestBodyFromJSON(json: any): StatusPageUpdatePageSetupRequestBody {
    return StatusPageUpdatePageSetupRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdatePageSetupRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdatePageSetupRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'darkmode_logo_key': !exists(json, 'darkmode_logo_key') ? undefined : json['darkmode_logo_key'],
        'date_view': !exists(json, 'date_view') ? undefined : json['date_view'],
        'favicon_key': !exists(json, 'favicon_key') ? undefined : json['favicon_key'],
        'lightmode_logo_key': !exists(json, 'lightmode_logo_key') ? undefined : json['lightmode_logo_key'],
        'theme': json['theme'],
    };
}

export function StatusPageUpdatePageSetupRequestBodyToJSON(value?: StatusPageUpdatePageSetupRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'darkmode_logo_key': value.darkmode_logo_key,
        'date_view': value.date_view,
        'favicon_key': value.favicon_key,
        'lightmode_logo_key': value.lightmode_logo_key,
        'theme': value.theme,
    };
}

