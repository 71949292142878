/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentParticipantsListResponseBody,
    IncidentParticipantsListResponseBodyFromJSON,
    IncidentParticipantsListResponseBodyToJSON,
} from '../models';

export interface IncidentParticipantsListRequest {
    incidentId: string;
}

/**
 * 
 */
export class IncidentParticipantsApi extends runtime.BaseAPI {

    /**
     * List incident participants
     * List IncidentParticipants
     */
    async incidentParticipantsListRaw(requestParameters: IncidentParticipantsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentParticipantsListResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentParticipantsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_participants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentParticipantsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident participants
     * List IncidentParticipants
     */
    async incidentParticipantsList(requestParameters: IncidentParticipantsListRequest, initOverrides?: RequestInit): Promise<IncidentParticipantsListResponseBody> {
        const response = await this.incidentParticipantsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
