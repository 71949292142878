/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';
import {
    StepConfig,
    StepConfigFromJSON,
    StepConfigFromJSONTyped,
    StepConfigToJSON,
} from './StepConfig';
import {
    TriggerSlim,
    TriggerSlimFromJSON,
    TriggerSlimFromJSONTyped,
    TriggerSlimToJSON,
} from './TriggerSlim';

/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * Conditions that apply to the workflow trigger
     * @type {Array<ConditionGroup>}
     * @memberof Workflow
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * If this workflow is delayed, whether the conditions should be rechecked between trigger firing and execution
     * @type {boolean}
     * @memberof Workflow
     */
    conditions_apply_over_delay?: boolean;
    /**
     * Whether to continue executing the workflow if a step fails
     * @type {boolean}
     * @memberof Workflow
     */
    continue_on_step_error: boolean;
    /**
     * When the resource was created
     * @type {Date}
     * @memberof Workflow
     */
    created_at: Date;
    /**
     * Delay in seconds between trigger firing and running the workflow
     * @type {number}
     * @memberof Workflow
     */
    delay_for_seconds?: number;
    /**
     * Whether the workflow is disabled, and if so, when
     * @type {Date}
     * @memberof Workflow
     */
    disabled_at?: Date;
    /**
     * Expressions that make variables available in the scope
     * @type {Array<Expression>}
     * @memberof Workflow
     */
    expressions: Array<Expression>;
    /**
     * Folder to display the workflow in
     * @type {string}
     * @memberof Workflow
     */
    folder?: string;
    /**
     * Unique identifier for the workflow
     * @type {string}
     * @memberof Workflow
     */
    id: string;
    /**
     * Whether to include private incidents
     * @type {boolean}
     * @memberof Workflow
     */
    include_private_incidents: boolean;
    /**
     * Whether to include retrospective incidents
     * @type {boolean}
     * @memberof Workflow
     */
    include_retrospective_incidents: boolean;
    /**
     * Whether to include test incidents
     * @type {boolean}
     * @memberof Workflow
     */
    include_test_incidents: boolean;
    /**
     * Name provided by the user when creating the workflow
     * @type {string}
     * @memberof Workflow
     */
    name: string;
    /**
     * This workflow will run 'once for' a list of references
     * @type {Array<Reference>}
     * @memberof Workflow
     */
    once_for: Array<Reference>;
    /**
     * Unique identifier for the organisation that owns this workflow
     * @type {string}
     * @memberof Workflow
     */
    organisation_id: string;
    /**
     * The time from which this workflow will run on incidents
     * @type {Date}
     * @memberof Workflow
     */
    runs_from?: Date;
    /**
     * Which incidents should the workflow be applied to?
     * @type {string}
     * @memberof Workflow
     */
    runs_on_incidents: WorkflowRunsOnIncidentsEnum;
    /**
     * The shortform used to trigger this workflow (only applicable for manual triggers)
     * @type {string}
     * @memberof Workflow
     */
    shortform?: string;
    /**
     * What state this workflow is in
     * @type {string}
     * @memberof Workflow
     */
    state: WorkflowStateEnum;
    /**
     * Steps that are executed as part of the workflow
     * @type {Array<StepConfig>}
     * @memberof Workflow
     */
    steps: Array<StepConfig>;
    /**
     * 
     * @type {TriggerSlim}
     * @memberof Workflow
     */
    trigger: TriggerSlim;
    /**
     * When the resource was last updated
     * @type {Date}
     * @memberof Workflow
     */
    updated_at: Date;
    /**
     * Revision of the workflow, uniquely identifying it's version
     * @type {number}
     * @memberof Workflow
     */
    version: number;
}

/**
* @export
* @enum {string}
*/
export enum WorkflowRunsOnIncidentsEnum {
    Created = 'newly_created',
    CreatedAndActive = 'newly_created_and_active'
}/**
* @export
* @enum {string}
*/
export enum WorkflowStateEnum {
    Active = 'active',
    Disabled = 'disabled',
    Draft = 'draft',
    Error = 'error'
}

export function WorkflowFromJSON(json: any): Workflow {
    return WorkflowFromJSONTyped(json, false);
}

export function WorkflowFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workflow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'conditions_apply_over_delay': !exists(json, 'conditions_apply_over_delay') ? undefined : json['conditions_apply_over_delay'],
        'continue_on_step_error': json['continue_on_step_error'],
        'created_at': (new Date(json['created_at'])),
        'delay_for_seconds': !exists(json, 'delay_for_seconds') ? undefined : json['delay_for_seconds'],
        'disabled_at': !exists(json, 'disabled_at') ? undefined : (new Date(json['disabled_at'])),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'id': json['id'],
        'include_private_incidents': json['include_private_incidents'],
        'include_retrospective_incidents': json['include_retrospective_incidents'],
        'include_test_incidents': json['include_test_incidents'],
        'name': json['name'],
        'once_for': ((json['once_for'] as Array<any>).map(ReferenceFromJSON)),
        'organisation_id': json['organisation_id'],
        'runs_from': !exists(json, 'runs_from') ? undefined : (new Date(json['runs_from'])),
        'runs_on_incidents': json['runs_on_incidents'],
        'shortform': !exists(json, 'shortform') ? undefined : json['shortform'],
        'state': json['state'],
        'steps': ((json['steps'] as Array<any>).map(StepConfigFromJSON)),
        'trigger': TriggerSlimFromJSON(json['trigger']),
        'updated_at': (new Date(json['updated_at'])),
        'version': json['version'],
    };
}

export function WorkflowToJSON(value?: Workflow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'conditions_apply_over_delay': value.conditions_apply_over_delay,
        'continue_on_step_error': value.continue_on_step_error,
        'created_at': (value.created_at.toISOString()),
        'delay_for_seconds': value.delay_for_seconds,
        'disabled_at': value.disabled_at === undefined ? undefined : (value.disabled_at.toISOString()),
        'expressions': ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'folder': value.folder,
        'id': value.id,
        'include_private_incidents': value.include_private_incidents,
        'include_retrospective_incidents': value.include_retrospective_incidents,
        'include_test_incidents': value.include_test_incidents,
        'name': value.name,
        'once_for': ((value.once_for as Array<any>).map(ReferenceToJSON)),
        'organisation_id': value.organisation_id,
        'runs_from': value.runs_from === undefined ? undefined : (value.runs_from.toISOString()),
        'runs_on_incidents': value.runs_on_incidents,
        'shortform': value.shortform,
        'state': value.state,
        'steps': ((value.steps as Array<any>).map(StepConfigToJSON)),
        'trigger': TriggerSlimToJSON(value.trigger),
        'updated_at': (value.updated_at.toISOString()),
        'version': value.version,
    };
}

