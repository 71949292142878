/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianStatuspagePage,
    AtlassianStatuspagePageFromJSON,
    AtlassianStatuspagePageFromJSONTyped,
    AtlassianStatuspagePageToJSON,
} from './AtlassianStatuspagePage';

/**
 * 
 * @export
 * @interface IntegrationsAtlassianStatuspageListPagesResponseBody
 */
export interface IntegrationsAtlassianStatuspageListPagesResponseBody {
    /**
     * 
     * @type {Array<AtlassianStatuspagePage>}
     * @memberof IntegrationsAtlassianStatuspageListPagesResponseBody
     */
    pages: Array<AtlassianStatuspagePage>;
}

export function IntegrationsAtlassianStatuspageListPagesResponseBodyFromJSON(json: any): IntegrationsAtlassianStatuspageListPagesResponseBody {
    return IntegrationsAtlassianStatuspageListPagesResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsAtlassianStatuspageListPagesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsAtlassianStatuspageListPagesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pages': ((json['pages'] as Array<any>).map(AtlassianStatuspagePageFromJSON)),
    };
}

export function IntegrationsAtlassianStatuspageListPagesResponseBodyToJSON(value?: IntegrationsAtlassianStatuspageListPagesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pages': ((value.pages as Array<any>).map(AtlassianStatuspagePageToJSON)),
    };
}

