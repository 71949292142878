import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import * as RadixPopover from "@radix-ui/react-popover";
import { PopoverContentProps } from "@radix-ui/react-popover";
import { createContext, useState } from "react";
import styles from "src/components/@ui/Popover/Popover.module.scss";
import { usePortal } from "src/contexts/PortalContext";
import { tcx } from "src/utils/tailwind-classes";

export type PopoverProps = {
  children: React.ReactNode;
  className?: string;
  side?: "top" | "right" | "bottom" | "left";
  align?: "end" | "start" | "center" | undefined;
  sideOffset?: number;
  alignOffset?: number;
  disabled?: boolean;
  onOpenChange?: (open: boolean) => void;
  onOpenAutoFocus?: (e: Event) => void;
  open?: boolean; // If omitted, the popover controls its own state
  onInteractOutside?: PopoverContentProps["onInteractOutside"];
  onEscapeKeyDown?: PopoverContentProps["onEscapeKeyDown"];
  triggerAsChild?: boolean;
  trigger?: React.ReactElement;
  // Trigger class name will only have an effect if triggerAsChild=false
  triggerClassName?: string;
  scroll?: boolean;
  menuWidthMatchesTrigger?: boolean;
  tooltipContent?: React.ReactNode;
  tooltipSide?: "top" | "right" | "bottom" | "left";
};

type PopoverContextType = {
  content?: () => React.ReactElement;
  setContent?: (content: PopoverContextType["content"]) => void;
};

export const PopoverContext = createContext<PopoverContextType>({});

export const popoverBodyClasses = () => {
  return tcx(
    "bg-white rounded-2 shadow-md transition border border-stroke z-50",
    "text-content-primary font-normal",
    "min-w-[160px] max-w-[400px]",
  );
};

export const Popover = ({
  trigger,
  children,
  className,
  side,
  align,
  // Default to a 2px offset
  sideOffset = 2,
  alignOffset,
  disabled,
  onInteractOutside,
  onOpenChange,
  onOpenAutoFocus,
  onEscapeKeyDown,
  open,
  triggerAsChild = true,
  triggerClassName,
  scroll,
  menuWidthMatchesTrigger,
  tooltipContent,
  tooltipSide,
}: PopoverProps) => {
  const [content, setContent] = useState<PopoverContextType["content"]>();
  const portalRef = usePortal();

  const handleOpenChange = (open: boolean) => {
    onOpenChange?.(open);
    if (!open) {
      setContent(undefined);
    }
  };

  return (
    <PopoverContext.Provider value={{ content, setContent }}>
      <RadixPopover.Root onOpenChange={handleOpenChange} open={open}>
        <Tooltip content={tooltipContent} side={tooltipSide}>
          <RadixPopover.Trigger
            disabled={disabled}
            asChild={triggerAsChild}
            className={triggerClassName}
          >
            {trigger}
          </RadixPopover.Trigger>
        </Tooltip>
        <RadixPopover.Portal container={portalRef?.current}>
          <RadixPopover.Content
            onOpenAutoFocus={onOpenAutoFocus}
            onInteractOutside={onInteractOutside}
            onEscapeKeyDown={onEscapeKeyDown}
            side={side}
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
            collisionPadding={16}
            className={tcx(
              popoverBodyClasses(),
              scroll && "max-h-80 overflow-y-auto",
              menuWidthMatchesTrigger && styles.match_popover_trigger_width,
              className,
            )}
          >
            {content ?? children}
          </RadixPopover.Content>
        </RadixPopover.Portal>
      </RadixPopover.Root>
    </PopoverContext.Provider>
  );
};

Popover.displayName = "Popover";

export const PopoverClose = RadixPopover.Close;

type PopoverHeaderProps = {
  children: React.ReactNode;
};

export const PopoverHeader = ({ children }: PopoverHeaderProps) => {
  return <div className="px-1 pt-1 flex flex-col">{children}</div>;
};

type PopoverBodyProps = {
  className?: string;
  children: React.ReactNode;
  scroll?: boolean;
};

export const PopoverBody = ({
  className,
  children,
  scroll,
}: PopoverBodyProps) => {
  return (
    <div
      className={tcx(
        "p-1 overflow-y-auto flex flex-col",
        scroll && "max-h-80 overflow-y-auto",
        className,
      )}
    >
      {children}
    </div>
  );
};

type PopoverTitleBarProps = {
  title: string | React.ReactNode;
  action?: {
    title: string;
    icon: IconEnum;
    onClick: () => void;
  };
  handleBack?: () => void;
  className?: string;
} & ( // We'll either show an exit button or a submit button in the top right.
  | {
      submitButton: React.ReactNode;
      onClose?: never;
    }
  | {
      submitButton?: never;
      onClose?: () => void;
    }
);

export const PopoverTitleBar = ({
  title,
  action,
  handleBack,
  onClose,
  className,
  submitButton,
}: PopoverTitleBarProps) => {
  return (
    <div
      className={tcx(
        "flex-center-y justify-between text-xs font-medium text-content-tertiary py-3 px-4",
        className,
      )}
    >
      <div className="flex-center-y gap-1">
        {handleBack ? (
          <Button
            title={"Back"}
            analyticsTrackingId={null}
            theme={ButtonTheme.Unstyled}
            className={"hover:text-content-primary"}
            icon={IconEnum.ArrowLeft}
            iconProps={{
              className: "mr-1",
              size: IconSize.Small,
            }}
            onClick={handleBack}
          >
            {title}
          </Button>
        ) : (
          <div>{title}</div>
        )}
        {action && (
          <Button
            title={action.title}
            analyticsTrackingId={null}
            theme={ButtonTheme.Naked}
            icon={action.icon}
            onClick={action.onClick}
          />
        )}
      </div>
      {onClose ? (
        <Button
          title="Close"
          analyticsTrackingId="conditions-popover-close"
          theme={ButtonTheme.Naked}
          icon={IconEnum.Close}
          onClick={onClose}
        />
      ) : submitButton ? (
        submitButton
      ) : null}
    </div>
  );
};
