import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  Button,
  Callout,
  CalloutTheme,
  IconEnum,
  TabPane,
  TabSection,
} from "@incident-ui";
import { useSearchParams } from "react-router-dom";
import { useIdentity } from "src/contexts/IdentityContext";

import { AIInvestigationsList } from "./AIInvestigationsList";
import { AIRequestsList } from "./AIRequestsList";
import { AIThreadsList } from "./AIThreadsList";

export const AIEvalsIndexPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { isImpersonating } = useIdentity();

  return (
    <PageWrapper icon={IconEnum.Robot} width={PageWidth.Wide} title="AI evals">
      <Callout
        theme={CalloutTheme.Info}
        title="You can view trends of our interaction ratings in Omni."
        subtitle={
          <>
            Use drill-downs to explore the data and click on the ID of any
            interaction to view its details back in this dashboard.
          </>
        }
        cta={
          <Button
            href="https://incident.omniapp.co/dashboards/09028a79"
            analyticsTrackingId={null}
          >
            View trends and performance
          </Button>
        }
      ></Callout>
      <TabSection
        withIndicator
        // No-one else has investigations, so starting there is annoying.
        defaultTab={tab || isImpersonating ? "interactions" : "investigations"}
        tabBarClassName="w-full border-b mt-6"
        tabs={[
          {
            id: "investigations",
            label: "Investigations",
          },
          {
            id: "interactions",
            label: "Interactions",
          },
          {
            id: "requests",
            label: "Non-interactive requests",
          },
        ]}
        onTabChange={(tabId) => {
          setSearchParams({ tab: tabId });
        }}
      >
        <TabPane tabId="investigations" className="flex flex-col gap-6">
          <AIInvestigationsList />
        </TabPane>
        <TabPane tabId="interactions" className="flex flex-col gap-6">
          <AIThreadsList />
        </TabPane>

        <TabPane tabId="requests" className="flex flex-col gap-6">
          <AIRequestsList />
        </TabPane>
      </TabSection>
    </PageWrapper>
  );
};
