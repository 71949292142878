import {
  Link,
  Modal,
  ModalContent,
  ModalFooter,
  OrgAwareLink,
  Txt,
} from "@incident-ui";

export const TrialExpiredModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      title="Your trial is over"
      analyticsTrackingId="incident-declare-trial-expired"
    >
      <ModalContent className="space-y-2">
        <Txt bold>Your free trial of incident.io has ended</Txt>
        <Txt>
          We hope you enjoyed it! Please{" "}
          <OrgAwareLink
            to="settings/billing"
            className="underline hover:text-alarmalade-600 transition"
          >
            sign up here
          </OrgAwareLink>{" "}
          if you&apos;d like to continue using us.
        </Txt>
        <Txt>
          If you have any questions about pricing, send an email to{" "}
          <Link
            href="mailto:support@incident.io"
            openInNewTab
            analyticsTrackingId="trial-expired-email"
            className="underline transition"
          >
            support@incident.io
          </Link>
          .
        </Txt>
      </ModalContent>
      <ModalFooter onClose={onClose} hideConfirmButton />
    </Modal>
  );
};
