/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReturnsMeta
 */
export interface ReturnsMeta {
    /**
     * Whether the return value should be single or multi-value
     * @type {boolean}
     * @memberof ReturnsMeta
     */
    array: boolean;
    /**
     * Expected return type of this expression (what to try casting the result to)
     * @type {string}
     * @memberof ReturnsMeta
     */
    type: string;
}

export function ReturnsMetaFromJSON(json: any): ReturnsMeta {
    return ReturnsMetaFromJSONTyped(json, false);
}

export function ReturnsMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReturnsMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'array': json['array'],
        'type': json['type'],
    };
}

export function ReturnsMetaToJSON(value?: ReturnsMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'array': value.array,
        'type': value.type,
    };
}

