import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { CurrencyInputV2 } from "@incident-shared/forms/v2/inputs/CurrencyInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ContentBox, ModalFooter } from "@incident-ui";
import { currencyValidationRules } from "@incident-ui/Input/CurrencyInput";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { SchedulePayConfig } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { formatCurrency } from "../../../../utils/currency";

type PayConfigEntry = {
  payConfig: SchedulePayConfig;
  applied: boolean;
  rate_in_pounds: string;
};

export type OverrideMultiInputFormType = {
  pay_config_entries: PayConfigEntry[];
  name: string;
};

export const OverrideModalMultiPayConfig = ({
  onClose: onCloseCallback,
  onSubmit,
  payConfigs,
  date,
}: {
  onClose: () => void;
  onSubmit: (formData: OverrideMultiInputFormType, date: Date) => void;
  payConfigs: SchedulePayConfig[];
  date: Date;
}): React.ReactElement | null => {
  const formMethods = useForm<OverrideMultiInputFormType>({
    defaultValues: {
      pay_config_entries: payConfigs.map((conf) => ({
        payConfig: conf,
        applied: true,
      })),
    },
  });
  const { watch, reset } = formMethods;

  const payConfigEntries = watch("pay_config_entries");

  const onClose = () => {
    reset();
    onCloseCallback();
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title={`Add holiday rule (${date.toLocaleDateString()})`}
      disableQuickClose
      onClose={onClose}
      onSubmit={(data) => onSubmit(data, date)}
      analyticsTrackingId={"pay-config-multi-holiday-modal"}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          disabled={payConfigEntries.every((entry) => !entry.applied)}
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        label="What should this rule be called?"
        name="name"
        placeholder="Christmas 2022"
        required
      />
      {payConfigEntries?.map((entry, idx) => {
        const entryIDPrefix: `pay_config_entries.${number}` = `pay_config_entries.${idx}`;
        return (
          <ContentBox
            key={entry.payConfig.id}
            className={tcx(
              "p-3 border-slate-400",
              entry.applied ? "bg-white" : "bg-surface-secondary",
            )}
          >
            <CheckboxV2
              formMethods={formMethods}
              className="font-medium !text-content-primary"
              label={entry.payConfig.name}
              name={`${entryIDPrefix}.applied`}
            />
            {entry.applied && (
              <CurrencyInputV2
                formMethods={formMethods}
                label="How much do you pay per hour on this day?"
                className="mt-1"
                name={`${entryIDPrefix}.rate_in_pounds`}
                currency={entry.payConfig.currency}
                rules={{ validate: currencyValidationRules }}
                contextText={`As a reminder, your base rate for this pay configuration is ${formatCurrency(
                  entry.payConfig.currency,
                  entry.payConfig.base_rate_cents,
                )} per hour.`}
              />
            )}
          </ContentBox>
        );
      })}
    </Form.Modal>
  );
};
