import {
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { OrgAwareLink } from "@incident-shared/org-aware";
import { BadgeSize, Button, ButtonTheme, EmptyState } from "@incident-ui";
import React, { useState } from "react";
import { useAPI } from "src/utils/swr";

import {
  findNextPostIncidentStatus,
  PostIncidentFlowDecision,
} from "../statuses/PostIncidentDecisionRadio";
import { MoveToNextStatusModal } from "./MoveToNextStatusModal";

export const EmptyPostIncidentStatusDetails = ({
  incident,
  status,
  statuses,
}: {
  incident: Incident;
  status: IncidentStatus;
  statuses: IncidentStatus[];
}) => {
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const currentIncidentStatus = incident?.incident_status;

  const {
    data: { incident_tasks: incidentTasks },
    isLoading: loadingIncidentTasks,
  } = useAPI(
    incident?.id === "" ? null : "postIncidentFlowListTasks",
    { incidentId: incident?.id ?? "" },
    { fallbackData: { incident_tasks: [] } },
  );

  if (loadingIncidentTasks || !incident || !currentIncidentStatus) {
    return null;
  }
  const currentStatusIsArchived = incident.incident_status.archived_at != null;
  if (currentStatusIsArchived && status.id === currentIncidentStatus.id) {
    status = currentIncidentStatus;
  }

  if (!status) {
    // This means the status isn't the current incident status, and isn't in out list of postIncidentFlow tasks
    // so we don't know what to do!
    return null;
  }

  const nextStatus = findNextPostIncidentStatus(
    PostIncidentFlowDecision.MoveOutOfCurrentStatus,
    incident.incident_status,
    statuses,
    incidentTasks,
  );

  const isAdvancable = status?.id === currentIncidentStatus.id;

  if (currentIncidentStatus.category === IncidentStatusCategoryEnum.Closed) {
    <EmptyState content="This status does not have any post-incident tasks associated with it" />;
  }

  return (
    <>
      <EmptyStatusContent
        isAdvanceable={isAdvancable}
        nextStatus={nextStatus}
        currentStatusIsArchived={currentStatusIsArchived}
        setIsShowingModal={setIsShowingModal}
      />
      {isShowingModal && nextStatus && (
        <MoveToNextStatusModal
          statusToMoveTo={nextStatus}
          incidentId={incident.id}
          onClose={() => setIsShowingModal(false)}
        />
      )}
    </>
  );
};

const EmptyStatusContent = ({
  isAdvanceable,
  nextStatus,
  setIsShowingModal,
  currentStatusIsArchived,
}: {
  isAdvanceable: boolean;
  nextStatus: IncidentStatus | null;
  setIsShowingModal: (val: boolean) => void;
  currentStatusIsArchived: boolean;
}) => {
  if (currentStatusIsArchived) {
    return (
      <EmptyContent
        content={
          <>
            This status has been deleted from the post-incident flow. When
            you&apos;re ready, click the button below to move the incident to
            the <span className="font-medium">{nextStatus?.name}</span> status.
          </>
        }
        cta={
          <Button
            theme={ButtonTheme.Primary}
            analyticsTrackingId={"move-to-next-status"}
            onClick={() => setIsShowingModal(true)}
            size={BadgeSize.Small}
          >
            Continue
          </Button>
        }
      />
    );
  }

  if (isAdvanceable) {
    return (
      <EmptyContent
        content={
          <>
            You haven&apos;t configured{" "}
            <OrgAwareLink
              to="/settings/lifecycle?tab=lifecycles"
              className="underline cursor-pointer font-medium"
            >
              any tasks
            </OrgAwareLink>{" "}
            for this post-incident status yet. When you&apos;re ready, click the
            button below to move the incident to the{" "}
            <span className="font-medium">{nextStatus?.name}</span> status.
          </>
        }
        cta={
          <Button
            theme={ButtonTheme.Primary}
            analyticsTrackingId={"move-to-next-status"}
            onClick={() => setIsShowingModal(true)}
            size={BadgeSize.Small}
          >
            Continue
          </Button>
        }
      />
    );
  }

  return (
    <EmptyContent
      content={
        <>
          This status did not have any tasks associated with it, and was
          manually marked as complete. You can{" "}
          <OrgAwareLink
            to="/settings/lifecycle?tab=post-incident"
            className="underline cursor-pointer font-bold"
          >
            configure tasks
          </OrgAwareLink>{" "}
          for this status in your lifecycle settings for future incidents.
        </>
      }
    />
  );
};

const EmptyContent = ({
  content,
  cta,
}: {
  content: React.ReactNode;
  cta?: React.ReactNode;
}) => {
  return (
    <div className="rounded-2 border-stroke border bg-surface-primary py-3 px-4 w-full grow flex gap-4 items-center justify-between text-content-tertiary text-xs">
      <div className="max-w-xl"> {content}</div>
      {cta}
    </div>
  );
};
