import { Icon, IconEnum } from "@incident-ui";
import validator from "validator";

import { AttributeExternalLink } from "./AttributeExternalLink";
import { AttributeBinding } from "./types";
import { PrimitiveType } from "./utils";

export const PrimitiveEntry = ({
  className,
  label,
  typeName,
  attributeBinding,
  jsonPreview = false,
}: {
  className?: string;
  label: string;
  typeName: PrimitiveType;
  attributeBinding: AttributeBinding;
  jsonPreview: boolean;
}) => {
  if (typeName === "Bool") {
    const isTruthy = attributeBinding.value?.literal === "true";
    if (jsonPreview) {
      // If we're in the JSON preview mode, we want to render monospace text
      return (
        <div className={className}>
          <code>{isTruthy ? "true" : "false"}</code>
        </div>
      );
    }
    return <Icon id={isTruthy ? IconEnum.Tick : IconEnum.Close} />;
  }

  // Text/URL/numbers
  if (validator.isURL(label) && !validator.isEmail(label)) {
    return <AttributeExternalLink label={label} className={className} />;
  }

  return <div className={className}>{label}</div>;
};
