import { EmptyState, IconEnum } from "@incident-ui";
import React, { ReactElement } from "react";

export const EmptyTab = ({
  icon,
  subtitle,
}: {
  icon: IconEnum;
  subtitle: string | ReactElement;
}): React.ReactElement => {
  return <EmptyState className="mt-1" icon={icon} content={subtitle} />;
};
