/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageUpdateRequestBody
 */
export interface StatusPageUpdateRequestBody {
    /**
     * Whether this status page should be indexed by search engines
     * @type {boolean}
     * @memberof StatusPageUpdateRequestBody
     */
    allow_search_engine_indexing: boolean;
    /**
     * The unique tag for this page from the organisation's google analytics account
     * @type {string}
     * @memberof StatusPageUpdateRequestBody
     */
    google_analytics_tag?: string;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPageUpdateRequestBody
     */
    name: string;
    /**
     * The publicly accessible URL of the privacy policy for this status page
     * @type {string}
     * @memberof StatusPageUpdateRequestBody
     */
    privacy_policy_url?: string;
    /**
     * The subpath of the status page for our standard domain
     * @type {string}
     * @memberof StatusPageUpdateRequestBody
     */
    subpath: string;
    /**
     * The text to be displayed on the button redirecting users to a support channel
     * @type {string}
     * @memberof StatusPageUpdateRequestBody
     */
    support_label: string;
    /**
     * A URL where users can report issues to the company that runs this status page
     * @type {string}
     * @memberof StatusPageUpdateRequestBody
     */
    support_url?: string;
    /**
     * The publicly accessible URL of the terms of service for this status page
     * @type {string}
     * @memberof StatusPageUpdateRequestBody
     */
    terms_of_service_url?: string;
}

export function StatusPageUpdateRequestBodyFromJSON(json: any): StatusPageUpdateRequestBody {
    return StatusPageUpdateRequestBodyFromJSONTyped(json, false);
}

export function StatusPageUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allow_search_engine_indexing': json['allow_search_engine_indexing'],
        'google_analytics_tag': !exists(json, 'google_analytics_tag') ? undefined : json['google_analytics_tag'],
        'name': json['name'],
        'privacy_policy_url': !exists(json, 'privacy_policy_url') ? undefined : json['privacy_policy_url'],
        'subpath': json['subpath'],
        'support_label': json['support_label'],
        'support_url': !exists(json, 'support_url') ? undefined : json['support_url'],
        'terms_of_service_url': !exists(json, 'terms_of_service_url') ? undefined : json['terms_of_service_url'],
    };
}

export function StatusPageUpdateRequestBodyToJSON(value?: StatusPageUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allow_search_engine_indexing': value.allow_search_engine_indexing,
        'google_analytics_tag': value.google_analytics_tag,
        'name': value.name,
        'privacy_policy_url': value.privacy_policy_url,
        'subpath': value.subpath,
        'support_label': value.support_label,
        'support_url': value.support_url,
        'terms_of_service_url': value.terms_of_service_url,
    };
}

