/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageAutomationRule,
    InternalStatusPageAutomationRuleFromJSON,
    InternalStatusPageAutomationRuleFromJSONTyped,
    InternalStatusPageAutomationRuleToJSON,
} from './InternalStatusPageAutomationRule';

/**
 * 
 * @export
 * @interface InternalStatusPageUpdateAutomationRuleResponseBody
 */
export interface InternalStatusPageUpdateAutomationRuleResponseBody {
    /**
     * 
     * @type {InternalStatusPageAutomationRule}
     * @memberof InternalStatusPageUpdateAutomationRuleResponseBody
     */
    automation_rule: InternalStatusPageAutomationRule;
}

export function InternalStatusPageUpdateAutomationRuleResponseBodyFromJSON(json: any): InternalStatusPageUpdateAutomationRuleResponseBody {
    return InternalStatusPageUpdateAutomationRuleResponseBodyFromJSONTyped(json, false);
}

export function InternalStatusPageUpdateAutomationRuleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageUpdateAutomationRuleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'automation_rule': InternalStatusPageAutomationRuleFromJSON(json['automation_rule']),
    };
}

export function InternalStatusPageUpdateAutomationRuleResponseBodyToJSON(value?: InternalStatusPageUpdateAutomationRuleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automation_rule': InternalStatusPageAutomationRuleToJSON(value.automation_rule),
    };
}

