import { Tooltip } from "@incident-ui";
import { Checkbox, CheckboxProps } from "@incident-ui/Checkbox/Checkbox";
import styles from "@incident-ui/Checkbox/Checkbox.module.scss";
import { TooltipAlign } from "@incident-ui/Tooltip/Tooltip";
import { omit } from "lodash";
import { ChangeEvent } from "react";
import {
  FieldValues,
  Path,
  useController,
  UseFormReturn,
} from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const CheckboxV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, CheckboxProps> & {
    onValueChange?: (checked: boolean) => void;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    CheckboxProps
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  // Checkbox is a bit odd as you don't really want the label to be in the input wrapper,
  // so we can fudge it:
  wrapperProps.label = undefined;
  inputProps.label = props.label;

  return (
    <FormInputWrapper<TFormType>
      {...omit(wrapperProps, "helptext")}
      name={name as unknown as Path<TFormType>}
    >
      <Checkbox
        id={name}
        {...field}
        {...inputProps}
        helptext={props.helptext} // For most other components, this is handled by the wrapper
        checked={props.checked ?? field.value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          field.onChange(e);

          onValueChange && onValueChange(e.target.checked);
        }}
      />
    </FormInputWrapper>
  );
};

// This component is a bit odd as it renders its own label and helptext
// (so we don't want a wrapper), and the _entire container_ is clickable and
// controls the input, so the actual checkbox is just a dummy visual indicator.
// I have foregone implementing support for all of the props we pass to our
// usual inputs, as some of them don't make sense, but they should be straightforward
// to add as needed.
export const CheckboxRowV2 = <TFormType extends FieldValues>(props: {
  name: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  label: React.ReactNode;
  description?: string;
  helptext?: string;
  helptextAlign?: TooltipAlign;
  disabled?: boolean;
  onSelectedNode?: React.ReactNode;
  className?: string;
}): React.ReactElement => {
  const { field } = useController({
    name: props.name,
  });

  return (
    <div
      className={tcx(
        styles.checkbox,
        "border border-stroke rounded-2 shadow-sm w-full",
        "flex flex-col",
        {
          "gap-1": props.description !== undefined,
          "cursor-not-allowed": props.disabled,
          "cursor-pointer hover:bg-slate-50": !props.disabled,
        },
        props.className,
      )}
    >
      {/* Clickable area: if you click this, we'll check/uncheck the box */}
      <div
        className={tcx("p-4 pl-5 group/check flex flex-col", {
          "gap-1": props.description !== undefined,
        })}
        onClick={(e) => {
          e.stopPropagation();
          if (!props.disabled) {
            field.onChange(!field.value);
          }
        }}
      >
        {/* Top row: checkbox, label + tooltip */}
        <div className="flex justify-between">
          <div className="flex items-center">
            <input
              tabIndex={1}
              type="checkbox"
              className={
                "text-white cursor-pointer border-stroke shadow-sm group-hover/check:border-slate-600 mr-3"
              }
              disabled={props.disabled}
              checked={field.value}
              readOnly
            />
            <span className="text-content-primary text-sm-med">
              {props.label}
            </span>
          </div>
          {props.helptext && (
            <div className="flex items-center">
              <Tooltip content={props.helptext} align={props.helptextAlign} />
            </div>
          )}
        </div>
        <div className="pl-7">
          {props.description && (
            <span className="text-content-secondary text-xs">
              {props.description}
            </span>
          )}
        </div>
      </div>
      {field.value && !!props.onSelectedNode ? (
        <div className="pl-12 pr-4 pb-4">{props.onSelectedNode}</div>
      ) : undefined}
    </div>
  );
};

export const CheckboxAsStringV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, CheckboxProps> & {
    onValueChange?: (checked: string) => void;
  },
): React.ReactElement => {
  const { onValueChange } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    CheckboxProps
  >(props);
  const { field } = useController({
    name,
    rules,
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked ? "true" : "false";
    field.onChange(value);

    onValueChange && onValueChange(value);
  };

  // Checkbox is a bit odd as you don't really want the label to be in the input wrapper,
  // so we can fudge it:
  wrapperProps.label = undefined;
  inputProps.label = props.label;

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <Checkbox
        id={name}
        {...field}
        {...inputProps}
        onChange={onChange}
        checked={field.value === "true"}
      />
    </FormInputWrapper>
  );
};
