/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InternalStatusPageComponent
 */
export interface InternalStatusPageComponent {
    /**
     * Unique ID of the catalog entry that this component corresponds to.
     * @type {string}
     * @memberof InternalStatusPageComponent
     */
    catalog_entry_id?: string;
    /**
     * Unique ID of the custom field option that this component corresponds to.
     * @type {string}
     * @memberof InternalStatusPageComponent
     */
    custom_field_option_id?: string;
    /**
     * Whether this component is hidden from the page structure
     * @type {boolean}
     * @memberof InternalStatusPageComponent
     */
    hidden: boolean;
    /**
     * Unique ID for the component. This will either be the custom field option ID or the catalog entry ID.
     * @type {string}
     * @memberof InternalStatusPageComponent
     */
    id: string;
    /**
     * Human readable value for this option.
     * @type {string}
     * @memberof InternalStatusPageComponent
     */
    label: string;
}

export function InternalStatusPageComponentFromJSON(json: any): InternalStatusPageComponent {
    return InternalStatusPageComponentFromJSONTyped(json, false);
}

export function InternalStatusPageComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_entry_id': !exists(json, 'catalog_entry_id') ? undefined : json['catalog_entry_id'],
        'custom_field_option_id': !exists(json, 'custom_field_option_id') ? undefined : json['custom_field_option_id'],
        'hidden': json['hidden'],
        'id': json['id'],
        'label': json['label'],
    };
}

export function InternalStatusPageComponentToJSON(value?: InternalStatusPageComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_entry_id': value.catalog_entry_id,
        'custom_field_option_id': value.custom_field_option_id,
        'hidden': value.hidden,
        'id': value.id,
        'label': value.label,
    };
}

