/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TypeaheadsListResponseBody,
    TypeaheadsListResponseBodyFromJSON,
    TypeaheadsListResponseBodyToJSON,
} from '../models';

export interface TypeaheadsListRequest {
    typeaheadType: TypeaheadsListTypeaheadTypeEnum;
    query?: string;
    idList?: Array<string>;
    incidentId?: string;
    forInsights?: boolean;
}

/**
 * 
 */
export class TypeaheadsApi extends runtime.BaseAPI {

    /**
     * List options for a typeahead filter field
     * List Typeaheads
     */
    async typeaheadsListRaw(requestParameters: TypeaheadsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TypeaheadsListResponseBody>> {
        if (requestParameters.typeaheadType === null || requestParameters.typeaheadType === undefined) {
            throw new runtime.RequiredError('typeaheadType','Required parameter requestParameters.typeaheadType was null or undefined when calling typeaheadsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeaheadType !== undefined) {
            queryParameters['typeahead_type'] = requestParameters.typeaheadType;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.idList) {
            queryParameters['id_list'] = requestParameters.idList;
        }

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.forInsights !== undefined) {
            queryParameters['for_insights'] = requestParameters.forInsights;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/typeaheads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeaheadsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options for a typeahead filter field
     * List Typeaheads
     */
    async typeaheadsList(requestParameters: TypeaheadsListRequest, initOverrides?: RequestInit): Promise<TypeaheadsListResponseBody> {
        const response = await this.typeaheadsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum TypeaheadsListTypeaheadTypeEnum {
    User = 'user',
    MicrosoftTeamsUser = 'microsoft_teams_user',
    OnCallUser = 'on_call_user',
    SlackUser = 'slack_user',
    SlackChannel = 'slack_channel',
    PagerDutyService = 'pager_duty_service',
    PagerDutyUser = 'pager_duty_user',
    SplunkOnCallUser = 'splunk_on_call_user',
    ConfluenceContent = 'confluence_content',
    Timestamp = 'timestamp',
    ScimGroup = 'scim_group',
    GithubRepository = 'github_repository',
    GitlabRepository = 'gitlab_repository',
    GitlabUsers = 'gitlab_users',
    CatalogEntry = 'catalog_entry',
    CustomField = 'custom_field',
    CustomFieldInsights = 'custom_field_insights',
    Incident = 'incident'
}
