import {
  Avatar,
  Checkbox,
  Icon,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  Link,
  LocalDateTime,
  SeverityBadge,
  Tooltip,
} from "@incident-ui";
import {
  Incident,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  IncidentStatus,
  IncidentTimestampTimestampTypeEnum,
  IncidentVisibilityEnum,
  PostIncidentTaskSlim,
  Settings,
} from "src/contexts/ClientContext";
import { formatDurationInSecondsShort } from "src/utils/datetime";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { tcx } from "src/utils/tailwind-classes";

import { getTaskStatus } from "./PostIncidentList";

export const PostIncidentTableHeader = ({
  incident,
  settings,
  selectedTasks,
  setSelectedTasks,
}: {
  incident: Incident;
  settings: Settings;
  selectedTasks: PostIncidentTaskSlim[];
  setSelectedTasks?: (taskIDs: PostIncidentTaskSlim[]) => void;
}): React.ReactElement => {
  const resolvedAt = incident.incident_timestamps?.find(
    (t) =>
      t.timestamp.timestamp_type ===
      IncidentTimestampTimestampTypeEnum.ResolvedAt,
  );

  const showIncidentTypes = incidentTypesEnabled(settings);

  const leadRole = incident.incident_role_assignments.find(
    ({ role }) => role.role_type === RoleTypeEnum.Lead,
  );
  const leadAlias = leadRole?.role?.name;
  const lead = leadRole?.assignee;

  const selectableTasks =
    incident.post_incident_tasks?.filter(
      (t) => getTaskStatus(t) === "outstanding",
    ) ?? [];

  const incidentTasksSelected = setSelectedTasks
    ? selectableTasks.length > 0 &&
      selectableTasks.every(
        (task) => selectedTasks?.some((selected) => selected.id === task.id),
      )
    : false;

  const toggleSelectionOfIncidentTasks = (e?: React.ChangeEvent) => {
    if (!setSelectedTasks) return;
    if (e !== undefined) {
      e.stopPropagation();
    }

    if (incidentTasksSelected) {
      setSelectedTasks(
        selectedTasks.filter((task) => task.incident_id !== incident.id),
      );
    } else {
      setSelectedTasks([...selectedTasks, ...selectableTasks]);
    }
  };

  const onRowClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    // This is to help prevent mis-clicks when a row is selected. If the user clicks on the row
    // and any incidents are selected, we don't want to navigate to the incident, we want to toggle
    // the checkbox.
    if (selectedTasks.length > 0) {
      toggleSelectionOfIncidentTasks();
      e.preventDefault();
    }
  };

  return (
    <Link
      analyticsTrackingId={null}
      to={`/incidents/${incident.external_id}`}
      onClick={onRowClick}
      className="flex items-center px-4 h-[52px] hover:cursor-pointer bg-surface-secondary overflow-hidden"
      noUnderline
    >
      <Checkbox
        id="select_all"
        checked={incidentTasksSelected}
        onChange={(e) => toggleSelectionOfIncidentTasks(e)}
        disabled={selectableTasks.length === 0}
        className="block mr-3"
      />
      <div className="flex items-center w-full text-sm gap-2">
        {/* Inc reference and name */}
        <div className="font-regular items-center text-content-primary flex min-w-[10px] !ml-0.5 truncate grow">
          {incident.visibility === IncidentVisibilityEnum.Private && (
            <div className="-ml-0.5 mr-1">
              <Icon id={IconEnum.LockClosed} className="text-slate-600" />
            </div>
          )}
          <div className="truncate text-sm-med grow">
            <span className="text-sm-bold"> INC-{incident.external_id}</span>{" "}
            {incident.name}
          </div>
        </div>
        <div className="flex gap-3 items-center">
          {incident.severity && (
            <SeverityBadge
              naked
              severity={incident.severity}
              className="text-content-primary text-xs-med"
            />
          )}
          <IncidentStatusBadge
            status={incident.incident_status}
            naked
            className="text-content-primary text-xs-med"
          />
          {resolvedAt && resolvedAt.value && resolvedAt.value.value && (
            <DetailItem
              icon={<Icon id={IconEnum.Timestamp} size={IconSize.Small} />}
              className="hidden xl:flex"
              content={
                <LocalDateTime
                  timestamp={resolvedAt.value.value}
                  className="hover:!no-underline flex-row text-xs"
                >
                  <span>
                    Resolved{" "}
                    {formatDurationInSecondsShort(
                      (new Date().getTime() -
                        resolvedAt.value.value.getTime()) /
                        1000,
                    )}{" "}
                    ago
                  </span>
                </LocalDateTime>
              }
            />
          )}
          <div className="flex gap-3.5 items-center">
            {showIncidentTypes && incident.incident_type ? (
              <DetailItem
                icon={<Icon id={IconEnum.IncidentType} size={IconSize.Small} />}
                content={incident.incident_type.name}
              />
            ) : null}

            <DetailItem
              icon={
                <Avatar
                  size={IconSize.Medium}
                  url={lead?.avatar_url}
                  name={lead?.name}
                  title={lead?.name ?? "Unassigned"}
                  className="mx-1"
                />
              }
              content={
                <Tooltip content={<>{leadAlias}</>}>
                  <div>{lead?.name ?? "Unassigned"}</div>
                </Tooltip>
              }
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

function DetailItem({
  icon,
  content,
  className,
}: {
  icon: React.ReactElement;
  content: React.ReactElement | string;
  className?: string;
}): React.ReactElement {
  return (
    <span
      className={tcx(
        className,
        "text-content-primary flex-center-y gap-0.5 whitespace-nowrap text-xs-med",
      )}
    >
      {icon}
      {content}
    </span>
  );
}

export const PostIncidentStatusHeader = ({
  status,
}: {
  status: IncidentStatus;
}): React.ReactElement => {
  return (
    <div className="flex items-center gap-3 px-4 h-[52px] hover:cursor-pointer bg-surface-secondary w-full">
      <IncidentStatusBadge status={status} naked iconOnly />
      <div className="flex items-center w-full text-sm-bold">{status.name}</div>
    </div>
  );
};
