/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleAndPayConfig
 */
export interface ScheduleAndPayConfig {
    /**
     * Unique internal ID of the pay config
     * @type {string}
     * @memberof ScheduleAndPayConfig
     */
    pay_config_id: string;
    /**
     * Unique internal ID of the schedule
     * @type {string}
     * @memberof ScheduleAndPayConfig
     */
    schedule_id: string;
}

export function ScheduleAndPayConfigFromJSON(json: any): ScheduleAndPayConfig {
    return ScheduleAndPayConfigFromJSONTyped(json, false);
}

export function ScheduleAndPayConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleAndPayConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pay_config_id': json['pay_config_id'],
        'schedule_id': json['schedule_id'],
    };
}

export function ScheduleAndPayConfigToJSON(value?: ScheduleAndPayConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pay_config_id': value.pay_config_id,
        'schedule_id': value.schedule_id,
    };
}

