/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportChannel
 */
export interface ReportChannel {
    /**
     * ID of the slack channel, slack user or email address
     * @type {string}
     * @memberof ReportChannel
     */
    id: string;
    /**
     * If the channel is a Slack channel, whether the channel is private
     * @type {boolean}
     * @memberof ReportChannel
     */
    is_private: boolean;
    /**
     * Human readable label for the channel
     * @type {string}
     * @memberof ReportChannel
     */
    label: string;
    /**
     * Type of channel to send the report to
     * @type {string}
     * @memberof ReportChannel
     */
    type: ReportChannelTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ReportChannelTypeEnum {
    SlackChannel = 'slack_channel',
    SlackDirectMessage = 'slack_direct_message',
    Email = 'email',
    ChatChannel = 'chat_channel'
}

export function ReportChannelFromJSON(json: any): ReportChannel {
    return ReportChannelFromJSONTyped(json, false);
}

export function ReportChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'is_private': json['is_private'],
        'label': json['label'],
        'type': json['type'],
    };
}

export function ReportChannelToJSON(value?: ReportChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_private': value.is_private,
        'label': value.label,
        'type': value.type,
    };
}

