/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpPriority
 */
export interface FollowUpPriority {
    /**
     * When the follow-up priority option was created
     * @type {Date}
     * @memberof FollowUpPriority
     */
    created_at: Date;
    /**
     * Description of the follow-up priority option
     * @type {string}
     * @memberof FollowUpPriority
     */
    description?: string;
    /**
     * Unique identifier for the follow-up priority option
     * @type {string}
     * @memberof FollowUpPriority
     */
    id: string;
    /**
     * The default FollowUpPriorityOption is pre-selected in the dropdown
     * @type {boolean}
     * @memberof FollowUpPriority
     */
    is_default: boolean;
    /**
     * Name of the follow-up priority option
     * @type {string}
     * @memberof FollowUpPriority
     */
    name: string;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof FollowUpPriority
     */
    organisation_id: string;
    /**
     * Rank is used to order the follow-up priority options correctly
     * @type {number}
     * @memberof FollowUpPriority
     */
    rank: number;
    /**
     * When the follow-up priority option was last updated
     * @type {Date}
     * @memberof FollowUpPriority
     */
    updated_at: Date;
}

export function FollowUpPriorityFromJSON(json: any): FollowUpPriority {
    return FollowUpPriorityFromJSONTyped(json, false);
}

export function FollowUpPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpPriority {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'is_default': json['is_default'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'rank': json['rank'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function FollowUpPriorityToJSON(value?: FollowUpPriority | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'is_default': value.is_default,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'rank': value.rank,
        'updated_at': (value.updated_at.toISOString()),
    };
}

