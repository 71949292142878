import type { SVGProps } from "react";
import * as React from "react";
const SvgChartCrossedOut = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.53 3.47a.75.75 0 0 1 0 1.06L4.802 17.258a.75.75 0 0 1-1.06-1.06L16.47 3.47a.75.75 0 0 1 1.06 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.769 3.049A2.757 2.757 0 0 0 14.25 3h-8.5A2.752 2.752 0 0 0 3 5.75v8.5c0 .177.017.35.049.519L6 11.818V9a.75.75 0 0 1 1.5 0v1.318l1.75-1.75V6.75a.75.75 0 0 1 1.5 0v.318l4.019-4.019ZM16.93 5.13a2.75 2.75 0 0 0-.767-1.354L10.75 9.189v2.122l6.18-6.18ZM17 7.182l-3.403 3.403a.75.75 0 0 1 .403.665v2a.75.75 0 0 1-1.5 0v-1.568l-1.78 1.78a.752.752 0 0 1-.508.507L7.182 17h7.068A2.752 2.752 0 0 0 17 14.25V7.182ZM5.13 16.93l4.12-4.12v-2.12L7.5 12.44v.81a.75.75 0 0 1-1.31.499l-2.414 2.414c.363.374.83.646 1.355.767Z"
    />
  </svg>
);
export default SvgChartCrossedOut;
