import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useClipboard } from "src/utils/useClipboard";

export const JsonViewer = ({
  title = "JSON",
  jsonStr,
}: {
  title?: string;
  jsonStr: string;
}) => {
  const processedJson = JSON.stringify(JSON.parse(jsonStr), null, 2);

  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <div className="w-full bg-white rounded-lg shadow-sm border font-mono">
      <div className="flex justify-between items-center p-2 border-b">
        <span className="text-sm font-medium text-gray-700">{title}</span>
        <Button
          theme={ButtonTheme.Naked}
          onClick={() => copyTextToClipboard(processedJson)}
          icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
          analyticsTrackingId={null}
        >
          Copy
        </Button>
      </div>
      <div className="p-4 bg-surface-secondary font-mono text-sm overflow-y-hidden">
        <pre className="whitespace-pre-wrap">
          {processedJson.split("\n").map((line, index) => (
            <div
              key={index}
              style={{
                paddingLeft: `${(line.match(/^\s*/)?.[0]?.length ?? 0) * 3}px`,
              }}
              className="flex"
            >
              {line}
            </div>
          ))}
        </pre>
      </div>
    </div>
  );
};
