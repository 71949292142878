import { AIConfigEnabledFeaturesEnum } from "@incident-io/api";
import { Button, ButtonTheme, ToastTheme } from "@incident-ui";
import { ChatBubble } from "@incident-ui/ProductMarketingBanner/ChattyProductMarketingBanner";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAIFeatureForOrg } from "src/hooks/useAI";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

const COPILOT_HOME_BANNER = "copilot-home-banner";

export const useShowCopilotBanner = () => {
  const { hasDismissedCTA } = useIdentity();

  const canUseAI = useAIFeatureForOrg();
  const copilotEnabled = canUseAI(AIConfigEnabledFeaturesEnum.Copilot);

  return {
    showCopilotBanner: copilotEnabled && !hasDismissedCTA(COPILOT_HOME_BANNER),
  };
};
export const CopilotBanner = () => {
  const { data } = useAPI("settingsSupport", undefined);
  const showToast = useToast();

  const { trigger: dismissBanner, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: COPILOT_HOME_BANNER,
          for_whole_organisation: false,
        },
      });
    },
    {
      onError: () =>
        showToast({
          title: "Could not dismiss banner",
          theme: ToastTheme.Error,
        }),
    },
  );

  if (dismissing) {
    return null;
  }

  return (
    <div
      className={tcx(
        "w-full text-sm rounded-2",
        "xl:grid items-center gap-6 px-6",
        "bg-gradient-to-r from-alarmalade-100 to-[#f8f5ef]",
      )}
      style={{ gridTemplateColumns: "1fr auto" }}
    >
      <div className="flex flex-col gap-8 grow items-start justify-between py-6 max-w-prose h-full">
        <div>
          <ChatBubble csm={data?.csm} />
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-2xl-bold flex items-center gap-2">
            {
              <div>
                Ask <span className="text-alarmalade-500">@incident</span>{" "}
                questions, have it draft customer comms, create follow-ups, and
                more. It&apos;s your new incident responder.
              </div>
            }
          </div>
          <Button
            analyticsTrackingId={`copilot-home-banner-dismiss`}
            theme={ButtonTheme.Naked}
            onClick={() => dismissBanner({})}
          >
            Dismiss
          </Button>
        </div>
      </div>
      <div className="hidden xl:block py-6 pl-6 shrink-0">
        <video
          className="rounded-2 h-[280px] w-[500px] border border-stroke shadow-lg object-cover"
          muted
          autoPlay
          loop
        >
          <source
            src="/product-marketing/copilot-banner-video.webm"
            type="video/webm"
            height="280px"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
