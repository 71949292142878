import {
  ImagesCreateUploadURLRequestBodyContentTypeEnum,
  ImagesUpdateStatusRequestBodyStatusEnum,
} from "@incident-io/api";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";

export const UploadImageValidationError =
  "We can only display PNG, JPEG, or SVG images up to 10MB";

export enum ImageUploadStatus {
  Uploading,
  Completed,
  Error,
}

export const useImageUpload = <TFormType extends FieldValues>({
  formMethods,
  name,
  incidentId,
  hiddenFileInputRef,
}: {
  formMethods: UseFormReturn<TFormType>;
  name: Path<TFormType>;
  incidentId?: string;
  hiddenFileInputRef: React.RefObject<HTMLInputElement>;
}) => {
  const [uploadStatus, setUploadStatus] = useState<
    ImageUploadStatus | undefined
  >(undefined);

  const apiClient = useClient();

  const uploadImage = async (file: File): Promise<string | null> => {
    setUploadStatus(ImageUploadStatus.Uploading);

    // 0: Validate the file type
    let contentType: ImagesCreateUploadURLRequestBodyContentTypeEnum;
    switch (file.type) {
      case "image/png":
        contentType = ImagesCreateUploadURLRequestBodyContentTypeEnum.Png;
        break;
      case "image/jpg":
      case "image/jpeg":
        contentType = ImagesCreateUploadURLRequestBodyContentTypeEnum.Jpeg;
        break;
      case "image/svg+xml":
        contentType = ImagesCreateUploadURLRequestBodyContentTypeEnum.Svgxml;
        break;
      default:
        // File type is invalid.
        formMethods.setError(name, { message: UploadImageValidationError });
        return null;
    }

    // 1: Get upload URL
    let data;
    try {
      data = await apiClient.imagesCreateUploadURL({
        createUploadURLRequestBody: {
          incident_id: incidentId,
          content_type: contentType,
        },
      });
    } catch (error) {
      console.error("Failed to get image upload URL with error: ", error);
      setUploadStatus(ImageUploadStatus.Error);
      return null;
    }
    if (!data) {
      console.error("Received empty response for upload URL");
      setUploadStatus(ImageUploadStatus.Error);
      return null;
    }

    // 2: Upload image to the URL
    let status = ImagesUpdateStatusRequestBodyStatusEnum.Uploaded;
    try {
      await fetch(data.upload_url, {
        method: "PUT",
        body: file,
        headers: { "content-type": contentType },
      });
    } catch (error) {
      status = ImagesUpdateStatusRequestBodyStatusEnum.UploadFailed;
      captureException(error);
      console.error("Failed to upload image with error: ", error);
      // We do not return here, as we still want to update the image status in the db
    }

    // 3: Update image status in the db
    try {
      await apiClient.imagesUpdateStatus({
        imageId: data.image_id,
        updateStatusRequestBody: {
          status: status,
        },
      });
    } catch (error) {
      console.error("Failed to update image status with error: ", error);
      setUploadStatus(ImageUploadStatus.Error);
      return null;
    }

    // we need to reset the file input to allow the user to upload the same file again,
    // this will be hit if they upload, delete, and then try to upload the same again.
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = "";
    }
    // Finally, return the imageID
    setUploadStatus(ImageUploadStatus.Completed);
    return data.image_id;
  };

  return { uploadImage, uploadStatus };
};
