import styles from "@incident-shared/incidents/IncidentListItem.module.scss";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Avatar,
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  SeverityBadge,
  Tooltip,
} from "@incident-ui";
import {
  Incident,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  IncidentStatusCategoryEnum,
  IncidentVisibilityEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { formatDurationInSecondsShort } from "../../../utils/datetime";
import { getDurationInSeconds } from "../../../utils/presenters";
export type IncidentCondensedListItemProps = {
  incident: Incident;
  showIncidentTypes: boolean;
  openInNewTab?: boolean;
  hideIncidentStatus?: boolean;
  hideArrow?: boolean;
  prefix?: React.ReactNode;
  accessory?: React.ReactNode;
};
export function CondensedIncidentListItem({
  prefix,
  accessory,
  incident,
  showIncidentTypes,
  openInNewTab,
  hideIncidentStatus,
  hideArrow,
}: IncidentCondensedListItemProps): React.ReactElement {
  const duration = getDurationInSeconds(incident);
  const lead = incident.incident_role_assignments.find(
    ({ role }) => role.role_type === RoleTypeEnum.Lead,
  )?.assignee;

  return (
    <li
      className={tcx(
        styles.incidentListItem,
        "hover:bg-surface-secondary text-sm",
      )}
    >
      {prefix}
      <Button
        className="!p-0 w-full !flex-shrink"
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId="incident-list-item"
        analyticsTrackingMetadata={{ incidentID: incident.id }}
        href={`/incidents/${incident.external_id}`}
        openInNewTab={openInNewTab}
      >
        <div className="flex flex-col lg:flex-row w-full p-2">
          {/* LHS */}
          <div className="grow flex items-center space-x-2 min-w-0">
            {hideIncidentStatus && (
              <Tooltip
                bubbleProps={{
                  className: "!bg-surface-invert border border-slate-600",
                }}
                content={
                  <div className="flex">
                    <Icon
                      id={IconEnum.Status}
                      className="mr-1 text-slate-600"
                    />
                    <span className="text-sm font-normal text-slate-200">
                      {incident.incident_status.name}
                    </span>
                  </div>
                }
              >
                {[
                  IncidentStatusCategoryEnum.Triage,
                  IncidentStatusCategoryEnum.Active,
                ].includes(incident.incident_status?.category) ? (
                  <div className={styles.activeCircle} />
                ) : (
                  <div>
                    <Icon
                      id={IconEnum.Tick}
                      color={ColorPaletteEnum.Green}
                      size={IconSize.Small}
                    />
                  </div>
                )}
              </Tooltip>
            )}
            <div className="flex items-center gap-2 min-w-0">
              <div className="flex items-center gap-1 min-w-0">
                <span className="font-semibold shrink-0 whitespace-nowrap">
                  INC-{incident.external_id}
                </span>
                <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                  {incident.name}
                </span>
              </div>
              {incident.visibility === IncidentVisibilityEnum.Private && (
                <Badge icon={IconEnum.LockClosed} theme={BadgeTheme.Tertiary}>
                  Private
                </Badge>
              )}
              <div className="shrink-0">
                <SeverityBadge severity={incident.severity} />
              </div>
            </div>
          </div>
          {/* RHS */}
          <div className="grow-0 flex items-center space-x-3 shrink-0 ml-5">
            {duration && (
              <Badge
                theme={BadgeTheme.Naked}
                icon={IconEnum.Clock}
                className="text-content-tertiary"
              >
                {formatDurationInSecondsShort(duration)}
              </Badge>
            )}
            {showIncidentTypes && incident.incident_type && (
              <span className="text-content-tertiary flex-center-y">
                <Icon
                  id={IconEnum.IncidentType}
                  className="text-content-tertiary mr-1"
                />
                {incident.incident_type.name}
              </span>
            )}
            {!hideIncidentStatus && (
              <IncidentStatusBadge status={incident.incident_status} />
            )}
            <div className="flex items-center gap-1.5">
              <Avatar
                size={IconSize.Large}
                url={lead?.avatar_url}
                name={lead?.name}
                title={lead?.name}
                className="flex"
              />
              {!hideArrow && (
                <Icon
                  id={IconEnum.ChevronRight}
                  className="text-content-tertiary"
                  size={IconSize.Medium}
                />
              )}
            </div>
          </div>
        </div>
      </Button>
      {accessory}
    </li>
  );
}
