/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoCallSettings,
    AutoCallSettingsFromJSON,
    AutoCallSettingsFromJSONTyped,
    AutoCallSettingsToJSON,
} from './AutoCallSettings';
import {
    DefaultCallURLSettings,
    DefaultCallURLSettingsFromJSON,
    DefaultCallURLSettingsFromJSONTyped,
    DefaultCallURLSettingsToJSON,
} from './DefaultCallURLSettings';

/**
 * 
 * @export
 * @interface IncidentCallSettings
 */
export interface IncidentCallSettings {
    /**
     * 
     * @type {AutoCallSettings}
     * @memberof IncidentCallSettings
     */
    auto_call: AutoCallSettings;
    /**
     * 
     * @type {DefaultCallURLSettings}
     * @memberof IncidentCallSettings
     */
    default_call_url: DefaultCallURLSettings;
}

export function IncidentCallSettingsFromJSON(json: any): IncidentCallSettings {
    return IncidentCallSettingsFromJSONTyped(json, false);
}

export function IncidentCallSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCallSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_call': AutoCallSettingsFromJSON(json['auto_call']),
        'default_call_url': DefaultCallURLSettingsFromJSON(json['default_call_url']),
    };
}

export function IncidentCallSettingsToJSON(value?: IncidentCallSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_call': AutoCallSettingsToJSON(value.auto_call),
        'default_call_url': DefaultCallURLSettingsToJSON(value.default_call_url),
    };
}

