/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnrichedMicrosoftTeamsMessage
 */
export interface EnrichedMicrosoftTeamsMessage {
    /**
     * The content of the message, transformed from HTML to plain text
     * @type {string}
     * @memberof EnrichedMicrosoftTeamsMessage
     */
    content: string;
    /**
     * The subject of the message
     * @type {string}
     * @memberof EnrichedMicrosoftTeamsMessage
     */
    subject: string;
}

export function EnrichedMicrosoftTeamsMessageFromJSON(json: any): EnrichedMicrosoftTeamsMessage {
    return EnrichedMicrosoftTeamsMessageFromJSONTyped(json, false);
}

export function EnrichedMicrosoftTeamsMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnrichedMicrosoftTeamsMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'subject': json['subject'],
    };
}

export function EnrichedMicrosoftTeamsMessageToJSON(value?: EnrichedMicrosoftTeamsMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'subject': value.subject,
    };
}

