/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CSM,
    CSMFromJSON,
    CSMFromJSONTyped,
    CSMToJSON,
} from './CSM';

/**
 * 
 * @export
 * @interface SettingsSupportResponseBody
 */
export interface SettingsSupportResponseBody {
    /**
     * 
     * @type {CSM}
     * @memberof SettingsSupportResponseBody
     */
    csm: CSM;
}

export function SettingsSupportResponseBodyFromJSON(json: any): SettingsSupportResponseBody {
    return SettingsSupportResponseBodyFromJSONTyped(json, false);
}

export function SettingsSupportResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsSupportResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'csm': CSMFromJSON(json['csm']),
    };
}

export function SettingsSupportResponseBodyToJSON(value?: SettingsSupportResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'csm': CSMToJSON(value.csm),
    };
}

