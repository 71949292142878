import { Fragment, Node, Slice } from "prosemirror-model";

import { schema } from "./schema";

// parseLinks parses any text nodes and checks for URLs that haven't been
// linked. Prosemirror doesn't have any plugins for this, but this feels like it
// should come out of the box when we migrate to a different tool
export const parseLinks = (doc: Node): Node => {
  type row = {
    node: Node;
    pos: number;
    parent: Node | null;
  };

  const results: Array<row> = [];
  doc.descendants((node: Node, pos: number, parent: Node | null) => {
    // Ignore invalid parent types
    if (
      node?.type.isText &&
      parent?.type.name !== "codeBlock" &&
      parent?.type.name !== "blockquote" &&
      parent?.type.name !== "heading" &&
      parent?.type.name !== "listItem"
    )
      results.push({ node, pos, parent });
  });

  // Loop through each node in the document, and parse links node by node
  results.forEach(({ node, pos }, _index) => {
    const linkMatches = node.text?.matchAll(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
    );
    if (!linkMatches) return;

    // If there are no links in this node, just return - nothing to do here
    const links = Array.from(linkMatches);
    if (links.length === 0) return;

    // Now loop through each link and start to reconstruct the node with the
    // desired marks
    const newNodes: Node[] = [];
    links.forEach((match, index) => {
      if (!match) return;
      const matchIndex = match.index ?? 0;

      const link = match[0];
      const marks = [
        ...node.marks,
        schema.marks.link.create({
          title: link,
          href:
            link.startsWith("http") || link.startsWith("www")
              ? link
              : `http://${link}`,
        }),
      ];

      // If the node content only contains the entire link, then just add the
      // link mark and return the original node
      if (node.nodeSize === link.length) {
        newNodes.push(node.mark(marks));
        return;
      }

      // Otherwise, we need to do a bit of stitching to break the original node into chunks:
      // 1. Normal text nodes for text in between links
      // 2. Link nodes (e.g. a text node with a link mark) for the links
      //
      // Create a text node for any text before our link.
      // If the link is the first part of the text, we don't have to do anything.
      if (matchIndex !== 0) {
        // Did we have a previous link before this? If so, start from the end of
        // that link as our starting point
        const previousMatch = index === 0 ? null : links[index - 1];
        const firstNode = node.cut(
          previousMatch && previousMatch.index
            ? previousMatch.index + previousMatch[0].length
            : 0,
          matchIndex,
        );
        newNodes.push(firstNode);
      }

      // Create a new link node
      const linkNode = node
        .cut(matchIndex, matchIndex + link.length)
        .mark(marks);
      newNodes.push(linkNode);

      // Create a text node for any text after our link, only if we're on the
      // last match. If the link is at the end of the text, we don't have to do
      // anything.
      const nextMatch = index === links.length - 1 ? null : links[index + 1];
      if (!nextMatch && matchIndex + link.length !== node.nodeSize) {
        const lastNode = node.cut(matchIndex + link.length, node.nodeSize);
        newNodes.push(lastNode);
      }
    });

    const newNodesSlice = new Slice(Fragment.from(newNodes), 0, 0);

    // Now replace our original document with the new nodes we've stitched together. If
    // a single operation fails, catch this: don't fail the entire process.
    try {
      doc = doc.replace(pos, pos + node.nodeSize, newNodesSlice);
    } catch (e) {
      // Skip over any failures to replace. Specifically, we've seen cases where calling
      // this returns a "Invalid content for node tableDataCell" error, and we don't want
      // to fail to convert something to a proper link if we hit that error.
    }
  });

  return doc;
};
