import type { SVGProps } from "react";
import * as React from "react";
const SvgGitBranchNew = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14 7.75a.75.75 0 0 0-.75.75c0 .689-.561 1.25-1.25 1.25H7.5c-.452 0-.873.12-1.25.314V6.5a.75.75 0 0 0-1.5 0V13a.75.75 0 0 0 1.5 0v-.5c0-.689.561-1.25 1.25-1.25H12a2.752 2.752 0 0 0 2.75-2.75.75.75 0 0 0-.75-.75Z"
    />
    <path
      fill="currentColor"
      d="M5.5 7a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM5.5 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
    />
    <rect width={1.5} height={5} x={13.2} y={2} fill="currentColor" rx={0.75} />
    <rect
      width={1.5}
      height={5}
      x={11.45}
      y={5.25}
      fill="currentColor"
      rx={0.75}
      transform="rotate(-90 11.45 5.25)"
    />
  </svg>
);
export default SvgGitBranchNew;
