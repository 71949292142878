import {
  Badge,
  BadgeTheme,
  IconEnum,
  Link,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { sortBy } from "lodash";
import React from "react";
import { StatusPage } from "src/contexts/ClientContext";

import { StatusPageStatusIcon } from "../incidents/view/StatusIcons";

export const StatusPagesList = ({
  pages,
}: {
  pages: StatusPage[];
}): React.ReactElement => {
  return (
    <StackedList>
      {sortBy(pages, "name").map((page) => (
        <StatusPageListItem page={page} key={page.id} />
      ))}
    </StackedList>
  );
};

const StatusPageListItem = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement => {
  return (
    <Link
      className="flex p-4 cursor-pointer no-underline text-sm hover:bg-surface-secondary hover:!text-content-primary"
      to={`/status-pages/${page.id}`}
      analyticsTrackingId={"status-page-view-page"}
      analyticsTrackingMetadata={{ status_page_id: page.id }}
    >
      <div className="block grow">
        <div className="font-medium flex items-center">
          <StatusPageStatusIcon
            hasOngoingIncidents={page.has_ongoing_incident}
            className="mr-1"
          />
          {page.name}
        </div>
        <div className="text-content-tertiary">{page.public_url}</div>
      </div>
      {page.mirroring_atlassian_page && (
        <Tooltip
          content={
            <>
              This page currently fetches its content from your Atlassian
              Statuspage. You can&apos;t update it from Slack until you complete
              the migration to your new incident.io status page.
            </>
          }
          side="left"
        >
          <button>
            <Badge icon={IconEnum.AtlassianStatuspage} theme={BadgeTheme.Info}>
              Mirror of Atlassian page
            </Badge>
          </button>
        </Tooltip>
      )}
    </Link>
  );
};
