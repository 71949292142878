import { createContext, useContext } from "react";
import { FieldInfo } from "src/components/@shared/filters/IncidentsFilterControlSearchBar";

import { useStatefulQueryParamFilters } from "../filters/useStatefulQueryParamFilters";

export type IncidentsListContextType = {
  fieldsToDisplay: FieldInfo[];
  setFieldsToDisplay: (newFields: FieldInfo[]) => void;
  layoutToDisplay: string;
  setLayoutToDisplay: (value: string) => void;
};

export const IncidentsListContext =
  createContext<IncidentsListContextType | null>(null);

export enum DisplayLayout {
  Kanban = "kanban",
  List = "list",
}

const FIELD_DISPLAY_INFO_KEY = "display_info";
const FIELD_DISPLAY_LAYOUT_KEY = "display_layout";

export const IncidentsListContextProvider = ({
  children,
}: {
  children: React.ReactChildren | React.ReactChild | React.ReactChild[];
}) => {
  // Get info from saved-views
  const { useQueryParamArray, useQueryParam } = useStatefulQueryParamFilters({
    availableFilterFields: [], // hack
    availableParams: [],
  });

  const [fieldsDisplayInfo, setFieldsDisplayInfo] = useQueryParamArray({
    key: FIELD_DISPLAY_INFO_KEY,
    isValid: (item: string) => {
      const [type, id] = item.split(":");
      return (
        (type === "role" || type === "custom_field" || type === "other") &&
        id !== ""
      );
    },
  });

  const fieldsToDisplay = fieldsDisplayInfo.map<FieldInfo>((item) => ({
    type: item.split(":")[0] as "role" | "custom_field",
    id: item.split(":")[1],
  }));

  const [fieldsDisplayLayout, setFieldsDisplayLayout] = useQueryParam({
    param: FIELD_DISPLAY_LAYOUT_KEY,
    isValid: (item) => {
      return item === DisplayLayout.Kanban || item === DisplayLayout.List;
    },
    defaultValue: DisplayLayout.List,
  });

  return (
    <IncidentsListContext.Provider
      value={{
        fieldsToDisplay,
        setFieldsToDisplay: (newFields) => {
          setFieldsDisplayInfo(
            newFields.map((fieldInfo) => `${fieldInfo.type}:${fieldInfo.id}`),
          );
        },
        layoutToDisplay: fieldsDisplayLayout,
        setLayoutToDisplay: setFieldsDisplayLayout,
      }}
    >
      {children}
    </IncidentsListContext.Provider>
  );
};

export const useIncidentsListContext = () => {
  const context = useContext(IncidentsListContext);
  if (!context) {
    throw new Error(
      "useIncidentsListContext hook must be used in a child of IncidentsListContextProvider",
    );
  }
  return context;
};
