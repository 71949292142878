import {
  ScopeNameEnum,
  StatusPageAffectedComponentStatusEnum,
  StatusPageIncidentUpdate,
  StatusPageIncidentUpdateToStatusEnum,
  StatusPageStructure,
  StatusPageStructureItem,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  COMPONENT_STATUS_CONFIG,
  STATUS_PAGE_INCIDENT_STATUS_NAME,
} from "@incident-shared/utils/StatusPages";
import { ButtonTheme, Difference } from "@incident-ui";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Timestamp } from "src/components/legacy/status-page-config/shared/Timestamp";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { UpdatedGroupStatus } from "../../view/UpdatedGroupStatus";
import { StatusPageIncidentUpdateDeleteModal } from "../delete/StatusPageIncidentUpdateDeleteModal";
import { StatusPageIncidentUpdateEditModal } from "../edit/StatusPageIncidentUpdateEditModal";

const statusPageDotColour = (status: StatusPageIncidentUpdateToStatusEnum) => {
  if (status === StatusPageIncidentUpdateToStatusEnum.Resolved) {
    return "bg-green-500";
  } else if (
    status === StatusPageIncidentUpdateToStatusEnum.Identified ||
    status === StatusPageIncidentUpdateToStatusEnum.Investigating ||
    status === StatusPageIncidentUpdateToStatusEnum.Monitoring
  ) {
    return "bg-alarmalade-600";
  } else if (
    status === StatusPageIncidentUpdateToStatusEnum.MaintenanceScheduled ||
    status === StatusPageIncidentUpdateToStatusEnum.MaintenanceInProgress
  ) {
    return "bg-blue-surface";
  } else {
    return "bg-green-500";
  }
};

export const StatusPageIncidentUpdateViewSection = ({
  structure,
  update,
  prevUpdate,
  nextUpdate,
  highlighted,
  canDelete,
  isLast,
}: {
  structure: StatusPageStructure;
  update: StatusPageIncidentUpdate;
  prevUpdate?: StatusPageIncidentUpdate;
  nextUpdate?: StatusPageIncidentUpdate;
  highlighted: boolean;
  canDelete: boolean;
  isLast: boolean;
}): React.ReactElement => {
  const [showEditIncidentUpdateModal, setShowEditIncidentUpdateModal] =
    useState(false);
  const [showDeleteIncidentUpdateModal, setShowDeleteIncidentUpdateModal] =
    useState(false);
  const { hasScope } = useIdentity();

  // When this gets highlighted, scroll to it
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (highlighted && ref.current) {
      ref.current.scrollIntoView({ block: "center" });
    }
  }, [highlighted]);

  // Get updated statuses of each component
  const newStatuses = new Map(
    update.component_statuses.map(({ component_id, status }) => [
      component_id,
      status,
    ]),
  );
  const prevStatuses = new Map(
    prevUpdate?.component_statuses?.map(({ component_id, status }) => [
      component_id,
      status,
    ]) || [],
  );

  // Get the actual affected components
  const affectedComponents = structure.items.filter(({ group, component }) => {
    if (component) {
      const newStatus = newStatuses.get(component.component_id);
      return !!newStatus;
    }

    if (group) {
      return group.components.some((component) =>
        newStatuses.has(component.component_id),
      );
    }
    return undefined;
  });

  return (
    <>
      {showEditIncidentUpdateModal && (
        <StatusPageIncidentUpdateEditModal
          onClose={() => setShowEditIncidentUpdateModal(false)}
          update={update}
          previousUpdate={prevUpdate}
          nextUpdate={nextUpdate}
        />
      )}
      {showDeleteIncidentUpdateModal && (
        <StatusPageIncidentUpdateDeleteModal
          onClose={() => setShowDeleteIncidentUpdateModal(false)}
          update={update}
        />
      )}

      <div
        className={tcx({
          "animate-status-page-update-pop": highlighted,
        })}
        ref={ref}
      >
        <div className="flex flex-col">
          {/* Status block */}
          <StatusHeader
            canDelete={canDelete}
            canPublishUpdates={
              !hasScope(ScopeNameEnum.StatusPagesPublishUpdates)
            }
            status={update.to_status}
            setShowDeleteIncidentUpdateModal={() =>
              setShowDeleteIncidentUpdateModal(true)
            }
            setShowEditIncidentUpdateModal={() =>
              setShowEditIncidentUpdateModal(true)
            }
          />

          {/* Update message */}
          <BorderedContainer
            // isLast={isLast}
            className="pt-4"
            status={update.to_status}
          >
            <TemplatedTextDisplay
              value={update.message}
              style={TemplatedTextDisplayStyle.Compact}
            />
          </BorderedContainer>

          {/* Component metadata */}
          {affectedComponents.length > 0 && (
            <BorderedContainer
              // isLast={isLast}
              className="py-3"
              status={update.to_status}
            >
              <AffectedComponentsBlock
                affectedComponents={affectedComponents}
                newStatuses={newStatuses}
                prevStatuses={prevStatuses}
              />
            </BorderedContainer>
          )}

          {/* Timestamp metadata */}
          <BorderedContainer
            // isLast={isLast}
            className={tcx(!isLast && "pb-6")}
            status={update.to_status}
          >
            <span className="text-content-tertiary">
              <Timestamp ts={update.published_at} />{" "}
              {update.timestamp_updated && "(edited)"}
            </span>
          </BorderedContainer>
        </div>
      </div>
    </>
  );
};

const BorderedContainer = ({
  children,
  className,
  status,
}: {
  children: ReactNode;
  className?: string;
  status: StatusPageIncidentUpdateToStatusEnum;
}) => {
  return (
    <div className="flex">
      <div className="w-6">
        <div
          className={tcx("ml-[2.5px] w-px h-full", statusPageDotColour(status))}
        ></div>
      </div>
      <div
        className={tcx(className, "flex items-center justify-between w-full")}
      >
        {children}
      </div>
    </div>
  );
};

const StatusHeader = ({
  canDelete,
  canPublishUpdates,
  status,
  setShowEditIncidentUpdateModal,
  setShowDeleteIncidentUpdateModal,
}: {
  canDelete: boolean;
  canPublishUpdates: boolean;
  status: StatusPageIncidentUpdateToStatusEnum;
  setShowEditIncidentUpdateModal: () => void;
  setShowDeleteIncidentUpdateModal: () => void;
}) => {
  return (
    <div className="flex items-center w-full">
      {/* The status dot */}
      <div className="w-6">
        <div
          className={tcx(
            "w-1.5 h-1.5 rounded-full",
            statusPageDotColour(status),
          )}
        ></div>
      </div>

      {/* The actual status */}
      <div className="flex items-center justify-between w-full">
        <div className="font-medium">
          {STATUS_PAGE_INCIDENT_STATUS_NAME[status]}
        </div>

        {/* Edit/delete metadata */}
        <div>
          <GatedButton
            analyticsTrackingId={null}
            onClick={setShowEditIncidentUpdateModal}
            theme={ButtonTheme.Naked}
            disabled={canPublishUpdates}
          >
            Edit
          </GatedButton>
          {canDelete && (
            <>
              <span className="px-1 text-content-tertiary">&bull;</span>
              <GatedButton
                analyticsTrackingId={null}
                onClick={setShowDeleteIncidentUpdateModal}
                theme={ButtonTheme.Naked}
                disabled={canPublishUpdates}
              >
                Delete
              </GatedButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AffectedComponentsBlock = ({
  affectedComponents,
  newStatuses,
  prevStatuses,
}: {
  affectedComponents: StatusPageStructureItem[];
  newStatuses: Map<string, StatusPageAffectedComponentStatusEnum>;
  prevStatuses: Map<string, StatusPageAffectedComponentStatusEnum>;
}) => {
  return (
    <div className="w-full bg-surface-secondary p-4 rounded-2 flex flex-col space-y-2">
      {affectedComponents.map(({ group, component }, index) => {
        if (component) {
          const newStatus = newStatuses.get(component.component_id);
          if (!newStatus) return undefined;

          const prevStatus = prevStatuses.get(component.component_id);

          return (
            <div key={component.component_id}>
              <span className="font-medium">{component.name}</span>
              <div className="text-content-tertiary">
                <Difference
                  prev={
                    prevStatus
                      ? COMPONENT_STATUS_CONFIG[prevStatus].label
                      : undefined
                  }
                  next={COMPONENT_STATUS_CONFIG[newStatus].label}
                />
              </div>
            </div>
          );
        }

        if (group) {
          return (
            <UpdatedGroupStatus
              key={index}
              group={group}
              newStatuses={newStatuses}
              prevStatuses={prevStatuses}
            />
          );
        }
        return undefined;
      })}
    </div>
  );
};
