import React from "react";
import { Route, Routes } from "react-router";

import { AutomationPage } from "./AutomationPage";

export const AutomationRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<AutomationPage />} />
    </Routes>
  );
};
