import { CatalogEntryReference, CatalogType } from "@incident-io/api";
import { NonPrimitiveEntry } from "@incident-shared/attribute";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Txt } from "@incident-ui";

export const CatalogEntryReferences = ({
  catalogType,
  entries,
  colorOverride,
  basePath,
}: {
  catalogType: CatalogType;
  entries?: CatalogEntryReference[];
  colorOverride?: ColorPaletteEnum;
  basePath?: string;
}) => {
  if (!entries || entries.length === 0) {
    return <Txt lightGrey>None</Txt>;
  }

  return (
    <div className="flex flex-wrap gap-1.5">
      {entries.map((entry) => (
        <NonPrimitiveEntry
          icon={catalogType.icon}
          color={colorOverride || catalogType.color}
          key={entry.catalog_entry_id}
          value={{
            label: entry.catalog_entry_name,
            sort_key: "",
            catalog_entry: entry,
          }}
          basePath={basePath}
          clickable
        />
      ))}
    </div>
  );
};
