/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';
import {
    ImagePayload,
    ImagePayloadFromJSON,
    ImagePayloadFromJSONTyped,
    ImagePayloadToJSON,
} from './ImagePayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PostmortemsCreateTemplateResponseRequestBody
 */
export interface PostmortemsCreateTemplateResponseRequestBody {
    /**
     * Set the incident's custom fields to these values
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof PostmortemsCreateTemplateResponseRequestBody
     */
    custom_field_entries: Array<CustomFieldEntryPayload>;
    /**
     * Images associated with the response
     * @type {Array<ImagePayload>}
     * @memberof PostmortemsCreateTemplateResponseRequestBody
     */
    images: Array<ImagePayload>;
    /**
     * 
     * @type {TextNode}
     * @memberof PostmortemsCreateTemplateResponseRequestBody
     */
    response: TextNode;
    /**
     * 
     * @type {string}
     * @memberof PostmortemsCreateTemplateResponseRequestBody
     */
    section_id: string;
}

export function PostmortemsCreateTemplateResponseRequestBodyFromJSON(json: any): PostmortemsCreateTemplateResponseRequestBody {
    return PostmortemsCreateTemplateResponseRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsCreateTemplateResponseRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsCreateTemplateResponseRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'images': ((json['images'] as Array<any>).map(ImagePayloadFromJSON)),
        'response': TextNodeFromJSON(json['response']),
        'section_id': json['section_id'],
    };
}

export function PostmortemsCreateTemplateResponseRequestBodyToJSON(value?: PostmortemsCreateTemplateResponseRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'images': ((value.images as Array<any>).map(ImagePayloadToJSON)),
        'response': TextNodeToJSON(value.response),
        'section_id': value.section_id,
    };
}

