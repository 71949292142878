/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RBACRole,
    RBACRoleFromJSON,
    RBACRoleFromJSONTyped,
    RBACRoleToJSON,
} from './RBACRole';

/**
 * 
 * @export
 * @interface UserWithRoles
 */
export interface UserWithRoles {
    /**
     * URL of user avatar image
     * @type {string}
     * @memberof UserWithRoles
     */
    avatar_url?: string;
    /**
     * 
     * @type {RBACRole}
     * @memberof UserWithRoles
     */
    base_role: RBACRole;
    /**
     * Any custom roles that the user has
     * @type {Array<RBACRole>}
     * @memberof UserWithRoles
     */
    custom_roles: Array<RBACRole>;
    /**
     * This only exists for use in the public API via User.Role.
     * @type {string}
     * @memberof UserWithRoles
     */
    deprecated_base_role: UserWithRolesDeprecatedBaseRoleEnum;
    /**
     * Email address of the user.
     * @type {string}
     * @memberof UserWithRoles
     */
    email?: string;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof UserWithRoles
     */
    id: string;
    /**
     * Set if the Slack user is a bot (e.g. another app or automation)
     * @type {boolean}
     * @memberof UserWithRoles
     */
    is_bot?: boolean;
    /**
     * Name of the user
     * @type {string}
     * @memberof UserWithRoles
     */
    name: string;
    /**
     * ID of the user's organisation
     * @type {string}
     * @memberof UserWithRoles
     */
    organisation_id: string;
    /**
     * Scim User ID of the user
     * @type {string}
     * @memberof UserWithRoles
     */
    scim_user_id?: string;
    /**
     * Role of this user in Slack
     * @type {string}
     * @memberof UserWithRoles
     */
    slack_role: UserWithRolesSlackRoleEnum;
    /**
     * Slack ID of the user
     * @type {string}
     * @memberof UserWithRoles
     */
    slack_user_id?: string;
    /**
     * User state
     * @type {string}
     * @memberof UserWithRoles
     */
    state: UserWithRolesStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum UserWithRolesDeprecatedBaseRoleEnum {
    Viewer = 'viewer',
    Responder = 'responder',
    Administrator = 'administrator',
    Owner = 'owner',
    Unset = 'unset'
}/**
* @export
* @enum {string}
*/
export enum UserWithRolesSlackRoleEnum {
    Empty = '',
    Owner = 'owner',
    Admin = 'admin',
    Regular = 'regular'
}/**
* @export
* @enum {string}
*/
export enum UserWithRolesStateEnum {
    Deactivated = 'deactivated',
    Viewer = 'viewer',
    Responder = 'responder',
    OnCall = 'on_call',
    OnCallResponder = 'on_call_responder',
    NotYetActive = 'not_yet_active'
}

export function UserWithRolesFromJSON(json: any): UserWithRoles {
    return UserWithRolesFromJSONTyped(json, false);
}

export function UserWithRolesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithRoles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'base_role': RBACRoleFromJSON(json['base_role']),
        'custom_roles': ((json['custom_roles'] as Array<any>).map(RBACRoleFromJSON)),
        'deprecated_base_role': json['deprecated_base_role'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': json['id'],
        'is_bot': !exists(json, 'is_bot') ? undefined : json['is_bot'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'scim_user_id': !exists(json, 'scim_user_id') ? undefined : json['scim_user_id'],
        'slack_role': json['slack_role'],
        'slack_user_id': !exists(json, 'slack_user_id') ? undefined : json['slack_user_id'],
        'state': json['state'],
    };
}

export function UserWithRolesToJSON(value?: UserWithRoles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_url': value.avatar_url,
        'base_role': RBACRoleToJSON(value.base_role),
        'custom_roles': ((value.custom_roles as Array<any>).map(RBACRoleToJSON)),
        'deprecated_base_role': value.deprecated_base_role,
        'email': value.email,
        'id': value.id,
        'is_bot': value.is_bot,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'scim_user_id': value.scim_user_id,
        'slack_role': value.slack_role,
        'slack_user_id': value.slack_user_id,
        'state': value.state,
    };
}

