import { PostmortemTemplateSectionTypeEnum } from "@incident-io/api";

type SectionSettings = {
  section: PostmortemTemplateSectionTypeEnum;
  editable: boolean;
  removable: boolean;
};

// sort order here is important as it reflects
// the order in which the sections will be displayed
export const PresetSections: SectionSettings[] = [
  {
    section: PostmortemTemplateSectionTypeEnum.Summary,
    editable: false,
    removable: true,
  },
  {
    section: PostmortemTemplateSectionTypeEnum.KeyInformation,
    editable: false,
    removable: true,
  },
  {
    section: PostmortemTemplateSectionTypeEnum.Timeline,
    editable: false,
    removable: false,
  },
  {
    section: PostmortemTemplateSectionTypeEnum.FollowUps,
    editable: true,
    removable: false,
  },
];
