import {
  IntegrationSettingsProviderEnum,
  StatusPage,
  StatusPageCreateMigrationRequestBodyTypeEnum,
  StatusPageThemeEnum,
} from "@incident-io/api";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  Link,
  Modal,
  ModalContent,
  ModalFooter,
  ProgressBar,
} from "@incident-ui";
import {
  GenericStatusBadge,
  GenericStatusBadgeEnum,
} from "@incident-ui/Badge/GenericStatusBadge";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { PollMigrationModal } from "../atlassian-import/PollMigrationModal";
import { SettingsTabIds } from "../view/StatusPagesDetailsViewPage";

type FormType = { show_in_search_engines: boolean };

export const GoLiveModal = ({
  page,
  onClose,
}: {
  page: StatusPage;
  onClose: () => void;
}) => {
  const { integrations } = useIntegrations();
  const hasAtlassianInstalled = integrations?.some(
    (int) => int.provider === IntegrationSettingsProviderEnum.Statuspage,
  );
  const {
    data: { pages: allAtlassianPages },
    isLoading: pagesLoading,
  } = useAPI(
    hasAtlassianInstalled ? "integrationsAtlassianStatuspageListPages" : null,
    undefined,
    {
      fallbackData: { pages: [] },
      shouldRetryOnError: (e) => e.status !== 404,
    },
  );
  const {
    data: {
      config: { included_pages: installedPageIds },
    },
    isLoading: configLoading,
  } = useAPI(
    hasAtlassianInstalled ? "integrationsAtlassianStatuspageGetConfig" : null,
    undefined,
    {
      fallbackData: { config: { included_pages: [] } },
      shouldRetryOnError: (e) => e.status !== 404,
    },
  );
  const willUninstall =
    installedPageIds.length === 1 &&
    installedPageIds[0] === page.imported_from_atlassian_page_id;
  const willChangeSlashCommand =
    page.imported_from_atlassian_page_id &&
    installedPageIds.includes(page.imported_from_atlassian_page_id);
  const hasPolicy =
    page.terms_of_service_url !== undefined ||
    page.privacy_policy_url !== undefined;
  const hasCustomDomain = page.custom_domain_verified_at !== undefined;

  const pageName = allAtlassianPages?.find(
    (atlassianPage) =>
      atlassianPage.id === page.imported_from_atlassian_page_id,
  )?.name;

  const {
    data: { status_page_templates: templates },
  } = useAPI(
    "statusPageListTemplates",
    { statusPageId: page.id },
    {
      fallbackData: { status_page_templates: [] },
    },
  );
  const hasTemplates = templates.length > 0;
  const formMethods = useForm<FormType>({
    defaultValues: {
      show_in_search_engines: true,
    },
  });

  const hasLogo =
    page.theme === StatusPageThemeEnum.Dark
      ? page.darkmode_logo_key !== undefined
      : page.lightmode_logo_key !== undefined;

  const indexSearch = formMethods.watch("show_in_search_engines");

  const [migrationId, setMigrationId] = useState<string | null>(null);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const { trigger: goLiveAtlassian, isMutating: isMutatingAtlassian } =
    useAPIMutation(
      "statusPageShow",
      { id: page.id },
      async (apiClient, { show_in_search_engines }: FormType) => {
        const res = await apiClient.statusPageCreateMigration({
          createMigrationRequestBody: {
            type: StatusPageCreateMigrationRequestBodyTypeEnum.GoLive,
            status_page_id: page.id,
            hide_from_search_engines: !show_in_search_engines,
          },
        });
        setMigrationId(res.migration.id);
      },
    );
  const { trigger: goLive, isMutating } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, { show_in_search_engines }: FormType) => {
      await apiClient.statusPageGoLive({
        goLiveRequestBody: {
          hide_from_search_engines: !show_in_search_engines,
        },
        id: page.id,
      });

      setHasSucceeded(true);
    },
  );

  const [progress, setProgress] = useState(50);
  const [showSuccessText, setShowSuccessText] = useState(false);
  useEffect(() => {
    if (hasSucceeded) {
      setTimeout(() => setProgress(100), 200);
      setTimeout(() => setShowSuccessText(true), 350);
    }
  });

  if (pagesLoading || configLoading) {
    return (
      <Modal
        isOpen
        loading
        title=""
        analyticsTrackingId={null}
        disableQuickClose
        onClose={onClose}
      />
    );
  }

  if (hasSucceeded) {
    return (
      <Modal
        isOpen
        disableQuickClose={false}
        onClose={onClose}
        title="Go live"
        analyticsTrackingId={null}
      >
        <ModalContent className="space-y-4">
          <p>
            <span className="font-semibold">Congratulations!</span>
            {migrationId && " You're all switched over."}
          </p>
          {!migrationId && (
            <ProgressBar
              transitionCompletedFrom={progress}
              numCompleted={progress}
              numTotal={100}
              animateEmptyBar
            />
          )}
          <p
            className={`text-sm ${
              showSuccessText || migrationId ? "opacity-100" : "opacity-0"
            } transition`}
          >
            {migrationId
              ? "We'd love to hear your feedback on how your migration went."
              : "Your status page is setup and ready to go."}
          </p>
          {migrationId && (
            <p className=" text-sm">
              Drop us a line in Slack, or email{" "}
              <a href="mailto:hello@incident.io">hello@incident.io</a>.
            </p>
          )}
        </ModalContent>
        <ModalFooter
          onClose={onClose}
          hideConfirmButton
          cancelButtonText="Close"
        />
      </Modal>
    );
  }

  if (migrationId != null) {
    return (
      <PollMigrationModal
        migrationId={migrationId}
        verb="importing your subscribers"
        onSuccess={() => setHasSucceeded(true)}
        onClose={onClose}
      />
    );
  }

  const pageNamePresented = pageName ? (
    <>
      the <span className="font-semibold">{pageName}</span>
    </>
  ) : (
    "source"
  );
  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={page.mirroring_atlassian_page ? goLiveAtlassian : goLive}
      title="Go live"
      analyticsTrackingId={"status-page-go-live"}
      disableQuickClose
      saving={page.mirroring_atlassian_page ? isMutatingAtlassian : isMutating}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Go live"
          confirmButtonType="submit"
          saving={
            page.mirroring_atlassian_page ? isMutatingAtlassian : isMutating
          }
        />
      }
    >
      <div className="text-sm space-y-4">
        {page.mirroring_atlassian_page && (
          <>
            <div className="space-y-4">
              <p>
                When you click &lsquo;
                <span className="font-semibold">Go live</span>
                &rsquo;, we will:
              </p>
              <ul className="list-disc space-y-2 pl-4">
                <li>Import your email and RSS subscribers from Statuspage</li>
                <li>
                  Stop syncing updates from {pageNamePresented} Atlassian
                  Statuspage
                </li>
                {willChangeSlashCommand && (
                  <li>
                    {willUninstall ? (
                      "Disconnect your Atlassian Statuspage integration"
                    ) : (
                      <>
                        Remove the {pageNamePresented} Atlassian Statuspage from{" "}
                        <code>/incident statuspage</code>
                      </>
                    )}
                  </li>
                )}
              </ul>

              <p>
                If you have Slack, webhook or SMS subscribers, they can&apos;t
                be imported from Atlassian.
              </p>
            </div>

            <hr />
          </>
        )}
        <div className="space-y-2">
          <p>
            Before you go live, there are a few settings we recommend you
            review:
          </p>
          <ul className="bg-white rounded border border-stroke shadow divide-y divide-slate-200">
            <ChecklistItem
              heading={
                hasLogo ? "Your logo has been added" : "Add your branding"
              }
              isComplete={hasLogo}
              onClose={onClose}
              link={`/status-pages/${page.id}/settings/${SettingsTabIds.PageSetup}`}
            />
            <ChecklistItem
              heading={
                hasPolicy
                  ? "Your privacy policy and terms of service are linked"
                  : "Link to your privacy policy and terms of service"
              }
              isComplete={hasPolicy}
              onClose={onClose}
              link={`/status-pages/${page.id}/settings/${SettingsTabIds.BasicSettings}#customization`}
            />
            <ChecklistItem
              heading={
                hasCustomDomain
                  ? `Your page is public on ${page.custom_domain}`
                  : "Host your status page on your own domain"
              }
              isComplete={hasCustomDomain}
              onClose={onClose}
              link={`/status-pages/${page.id}/settings/${SettingsTabIds.CustomDomain}`}
            />
            <ChecklistItem
              heading="Create incident update templates"
              isComplete={hasTemplates}
              onClose={onClose}
              link={`/status-pages/${page.id}/settings/${SettingsTabIds.Templates}`}
            />
            <ChecklistItem
              isComplete={indexSearch}
              onClose={onClose}
              heading="Let people find my status page from search engines"
              action={
                <ToggleV2
                  align="left"
                  name="show_in_search_engines"
                  formMethods={formMethods}
                />
              }
            >
              {!indexSearch && (
                <Form.Helptext className="text-content-tertiary">
                  Your customers won&apos;t be able to find your status page
                  through search engines.
                </Form.Helptext>
              )}
            </ChecklistItem>
          </ul>
        </div>
      </div>
    </Form.Modal>
  );
};

const ChecklistItem = ({
  isComplete,
  link,
  action,
  onClose,
  children,
  heading,
}: {
  isComplete: boolean;
  link?: string;
  action?: ReactElement;
  onClose: () => void;
  children?: React.ReactNode;
  heading: string;
}) => {
  return (
    <li className={tcx("flex w-full items-center p-3 justify-between")}>
      <div className="grid grid-cols-[auto,1fr,auto] items-center gap-2 w-full">
        <GenericStatusBadge
          status={
            isComplete
              ? GenericStatusBadgeEnum.Completed
              : GenericStatusBadgeEnum.Outstanding
          }
        />
        <span>{heading}</span>
        {action || (
          <Link
            to={
              link! /* eslint-disable-line @typescript-eslint/no-non-null-assertion*/
            }
            analyticsTrackingId={null}
            onClick={onClose}
            className="text-content-tertiary !no-underline hover:!text-content-primary transition"
          >
            {isComplete ? "Change" : "Setup"}
          </Link>
        )}
        {children && <div className="col-start-2 col-span-2">{children}</div>}
      </div>
    </li>
  );
};
