import { StepSlim, StepSlimReleaseChannelEnum } from "@incident-io/api";
import {
  Badge,
  BadgeTheme,
  Button,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { AnchorProps, ButtonTheme } from "@incident-ui/Button/Button";

export type WorkflowStepButtonProps = {
  step: StepSlim;
  onClick?: () => void;
  analyticsTrackingId: string | null;
} & AnchorProps;

// WorkflowStepButton wraps its children with a button
// that can be clicked to trigger an action.
export const WorkflowStepButton = ({
  step,
  onClick,
  analyticsTrackingId,
  target,
  href,
}: WorkflowStepButtonProps): React.ReactElement => {
  const isEligible = step.organisation_is_eligible;
  const hasCta = step.become_eligible_cta !== undefined;

  return (
    <Button
      analyticsTrackingId={analyticsTrackingId}
      onClick={onClick}
      href={href}
      target={target}
      theme={ButtonTheme.Unstyled}
      className="!flex w-full p-[10px] group hover:bg-surface-secondary"
    >
      <div>
        <p className="font-normal text-sm text-content-primary">{step.label}</p>
      </div>
      {step.release_channel === StepSlimReleaseChannelEnum.Rapid && (
        <Badge
          theme={BadgeTheme.Info}
          className="mr-1 ml-2 !font-normal text-xs"
        >
          Beta
        </Badge>
      )}
      {!isEligible && hasCta && (
        <Badge
          theme={
            step.become_eligible_cta?.is_talk_to_us
              ? BadgeTheme.Success
              : BadgeTheme.Info
          }
          className="mr-1 ml-2 !font-normal text-xs"
        >
          {step.become_eligible_cta?.is_talk_to_us
            ? "Talk to us"
            : "Needs setup"}
        </Badge>
      )}
      <div className="ml-auto -mr-[9px]">
        <Icon
          id={IconEnum.ChevronRight}
          size={IconSize.Medium}
          className="text-slate-400 group-hover:text-content-primary"
        />
      </div>
    </Button>
  );
};
