/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';

/**
 * 
 * @export
 * @interface InternalStatusPageUpdateAutomationRuleRequestBody
 */
export interface InternalStatusPageUpdateAutomationRuleRequestBody {
    /**
     * The automation rule will add matching active incidents to the page.
     * @type {Array<ConditionGroupPayload>}
     * @memberof InternalStatusPageUpdateAutomationRuleRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * Only apply this automation rule to incidents which affect a component that is visible on this status page.
     * @type {boolean}
     * @memberof InternalStatusPageUpdateAutomationRuleRequestBody
     */
    only_if_affects_component: boolean;
}

export function InternalStatusPageUpdateAutomationRuleRequestBodyFromJSON(json: any): InternalStatusPageUpdateAutomationRuleRequestBody {
    return InternalStatusPageUpdateAutomationRuleRequestBodyFromJSONTyped(json, false);
}

export function InternalStatusPageUpdateAutomationRuleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageUpdateAutomationRuleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'only_if_affects_component': json['only_if_affects_component'],
    };
}

export function InternalStatusPageUpdateAutomationRuleRequestBodyToJSON(value?: InternalStatusPageUpdateAutomationRuleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'only_if_affects_component': value.only_if_affects_component,
    };
}

