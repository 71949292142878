/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TutorialInfo
 */
export interface TutorialInfo {
    /**
     * External identifier for the incident - often displayed with an INC- prefix
     * @type {number}
     * @memberof TutorialInfo
     */
    external_id: number;
    /**
     * Unique identifier for the incident
     * @type {string}
     * @memberof TutorialInfo
     */
    id: string;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof TutorialInfo
     */
    name: string;
    /**
     * Reference to this incident, as displayed across the product
     * @type {string}
     * @memberof TutorialInfo
     */
    reference: string;
    /**
     * URL to link to the slack channel
     * @type {string}
     * @memberof TutorialInfo
     */
    slack_channel_url: string;
}

export function TutorialInfoFromJSON(json: any): TutorialInfo {
    return TutorialInfoFromJSONTyped(json, false);
}

export function TutorialInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': json['external_id'],
        'id': json['id'],
        'name': json['name'],
        'reference': json['reference'],
        'slack_channel_url': json['slack_channel_url'],
    };
}

export function TutorialInfoToJSON(value?: TutorialInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'id': value.id,
        'name': value.name,
        'reference': value.reference,
        'slack_channel_url': value.slack_channel_url,
    };
}

