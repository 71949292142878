import type { SVGProps } from "react";
import * as React from "react";
const SvgFakeRadioChecked = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="#212121"
      d="M10.444 1.111c-4.9 0-8.888 3.988-8.888 8.889s3.987 8.889 8.889 8.889c4.9 0 8.888-3.988 8.888-8.889s-3.987-8.889-8.888-8.889Zm0 12.222A3.337 3.337 0 0 1 7.111 10a3.337 3.337 0 0 1 3.333-3.333A3.337 3.337 0 0 1 13.778 10a3.337 3.337 0 0 1-3.334 3.333Z"
    />
  </svg>
);
export default SvgFakeRadioChecked;
