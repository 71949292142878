import type { SVGProps } from "react";
import * as React from "react";
const SvgAnnouncement = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.697 17.684c-.217 0-.436-.041-.646-.125l-1.945-.773a1.74 1.74 0 0 1-1.104-1.626v-2.704h1.5v2.704c0 .103.062.194.158.232l1.945.773a.25.25 0 0 0 .283-.071l1.666-1.978 1.147.966-1.667 1.979a1.75 1.75 0 0 1-1.337.623Z"
    />
    <path
      fill="currentColor"
      d="M14.501 3c-.283 0-.599.088-.817.174-.218.086-3.502 1.386-5.182 2.066a356.99 356.99 0 0 1-5.16 2.057 1.784 1.784 0 0 0-.995.977 4.475 4.475 0 0 0 .008 3.472c.188.443.538.783.977.953l10.343 4.123c.203.081.539.178.824.178 1.995 0 3.5-3.009 3.5-7s-1.505-7-3.5-7h.002Zm0 12.5c-.815 0-2-2.143-2-5.5s1.185-5.5 2-5.5 2 2.143 2 5.5-1.185 5.5-2 5.5Z"
    />
    <path
      fill="currentColor"
      d="M15.501 10a1.5 1.5 0 0 0-1.5-1.5c-.053 0-.103.01-.155.016a11.61 11.61 0 0 0 0 2.968c.052.005.102.016.155.016a1.5 1.5 0 0 0 1.5-1.5Z"
    />
  </svg>
);
export default SvgAnnouncement;
