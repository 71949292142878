/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentForm,
    IncidentFormFromJSON,
    IncidentFormFromJSONTyped,
    IncidentFormToJSON,
} from './IncidentForm';

/**
 * 
 * @export
 * @interface IncidentFormsUpdateFormResponseBody
 */
export interface IncidentFormsUpdateFormResponseBody {
    /**
     * 
     * @type {IncidentForm}
     * @memberof IncidentFormsUpdateFormResponseBody
     */
    form: IncidentForm;
}

export function IncidentFormsUpdateFormResponseBodyFromJSON(json: any): IncidentFormsUpdateFormResponseBody {
    return IncidentFormsUpdateFormResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsUpdateFormResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsUpdateFormResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form': IncidentFormFromJSON(json['form']),
    };
}

export function IncidentFormsUpdateFormResponseBodyToJSON(value?: IncidentFormsUpdateFormResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form': IncidentFormToJSON(value.form),
    };
}

