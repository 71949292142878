/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleReport,
    ScheduleReportFromJSON,
    ScheduleReportFromJSONTyped,
    ScheduleReportToJSON,
} from './ScheduleReport';

/**
 * 
 * @export
 * @interface SchedulesListReportResponseBody
 */
export interface SchedulesListReportResponseBody {
    /**
     * 
     * @type {Array<ScheduleReport>}
     * @memberof SchedulesListReportResponseBody
     */
    schedule_reports: Array<ScheduleReport>;
}

export function SchedulesListReportResponseBodyFromJSON(json: any): SchedulesListReportResponseBody {
    return SchedulesListReportResponseBodyFromJSONTyped(json, false);
}

export function SchedulesListReportResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesListReportResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schedule_reports': ((json['schedule_reports'] as Array<any>).map(ScheduleReportFromJSON)),
    };
}

export function SchedulesListReportResponseBodyToJSON(value?: SchedulesListReportResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schedule_reports': ((value.schedule_reports as Array<any>).map(ScheduleReportToJSON)),
    };
}

