/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImagePayload
 */
export interface ImagePayload {
    /**
     * Caption associated with the image
     * @type {string}
     * @memberof ImagePayload
     */
    caption?: string;
    /**
     * Unique identifier for this image in the db
     * @type {string}
     * @memberof ImagePayload
     */
    id: string;
}

export function ImagePayloadFromJSON(json: any): ImagePayload {
    return ImagePayloadFromJSONTyped(json, false);
}

export function ImagePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImagePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
        'id': json['id'],
    };
}

export function ImagePayloadToJSON(value?: ImagePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caption': value.caption,
        'id': value.id,
    };
}

