/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnabledSlackQuickAction,
    EnabledSlackQuickActionFromJSON,
    EnabledSlackQuickActionFromJSONTyped,
    EnabledSlackQuickActionToJSON,
} from './EnabledSlackQuickAction';

/**
 * 
 * @export
 * @interface IncidentChannelConfigsCreateQuickActionResponseBody
 */
export interface IncidentChannelConfigsCreateQuickActionResponseBody {
    /**
     * 
     * @type {EnabledSlackQuickAction}
     * @memberof IncidentChannelConfigsCreateQuickActionResponseBody
     */
    quick_action: EnabledSlackQuickAction;
}

export function IncidentChannelConfigsCreateQuickActionResponseBodyFromJSON(json: any): IncidentChannelConfigsCreateQuickActionResponseBody {
    return IncidentChannelConfigsCreateQuickActionResponseBodyFromJSONTyped(json, false);
}

export function IncidentChannelConfigsCreateQuickActionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelConfigsCreateQuickActionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quick_action': EnabledSlackQuickActionFromJSON(json['quick_action']),
    };
}

export function IncidentChannelConfigsCreateQuickActionResponseBodyToJSON(value?: IncidentChannelConfigsCreateQuickActionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quick_action': EnabledSlackQuickActionToJSON(value.quick_action),
    };
}

