/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailableCatalogType
 */
export interface AvailableCatalogType {
    /**
     * Sets the display color of this type in the dashboard
     * @type {string}
     * @memberof AvailableCatalogType
     */
    color: AvailableCatalogTypeColorEnum;
    /**
     * Human readble description of this type
     * @type {string}
     * @memberof AvailableCatalogType
     */
    description: string;
    /**
     * If populated, gives an estimated count of entries for this type
     * @type {number}
     * @memberof AvailableCatalogType
     */
    estimated_count?: number;
    /**
     * Sets the display icon of this type in the dashboard
     * @type {string}
     * @memberof AvailableCatalogType
     */
    icon: AvailableCatalogTypeIconEnum;
    /**
     * The ID of the catalog type if it exists
     * @type {string}
     * @memberof AvailableCatalogType
     */
    id?: string;
    /**
     * Name is the human readable name of this type
     * @type {string}
     * @memberof AvailableCatalogType
     */
    name: string;
    /**
     * The type name of this catalog type, to be used when defining attributes. This is immutable once a CatalogType has been created. For non-externally sync types, it must follow the pattern Custom["SomeName "]
     * @type {string}
     * @memberof AvailableCatalogType
     */
    type_name: string;
}

/**
* @export
* @enum {string}
*/
export enum AvailableCatalogTypeColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum AvailableCatalogTypeIconEnum {
    Alert = 'alert',
    Bolt = 'bolt',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Clock = 'clock',
    Cog = 'cog',
    Components = 'components',
    Database = 'database',
    Doc = 'doc',
    Email = 'email',
    EscalationPath = 'escalation-path',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    Globe = 'globe',
    Money = 'money',
    Server = 'server',
    Severity = 'severity',
    StatusPage = 'status-page',
    Store = 'store',
    Star = 'star',
    Tag = 'tag',
    User = 'user',
    Users = 'users'
}

export function AvailableCatalogTypeFromJSON(json: any): AvailableCatalogType {
    return AvailableCatalogTypeFromJSONTyped(json, false);
}

export function AvailableCatalogTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableCatalogType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'color': json['color'],
        'description': json['description'],
        'estimated_count': !exists(json, 'estimated_count') ? undefined : json['estimated_count'],
        'icon': json['icon'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'type_name': json['type_name'],
    };
}

export function AvailableCatalogTypeToJSON(value?: AvailableCatalogType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'color': value.color,
        'description': value.description,
        'estimated_count': value.estimated_count,
        'icon': value.icon,
        'id': value.id,
        'name': value.name,
        'type_name': value.type_name,
    };
}

