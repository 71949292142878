/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BranchesOnlyExpression,
    BranchesOnlyExpressionFromJSON,
    BranchesOnlyExpressionFromJSONTyped,
    BranchesOnlyExpressionToJSON,
} from './BranchesOnlyExpression';
import {
    LegacyIssueTemplate,
    LegacyIssueTemplateFromJSON,
    LegacyIssueTemplateFromJSONTyped,
    LegacyIssueTemplateToJSON,
} from './LegacyIssueTemplate';

/**
 * 
 * @export
 * @interface IssueTrackerSyncConfig
 */
export interface IssueTrackerSyncConfig {
    /**
     * The context in which this sync config is used
     * @type {string}
     * @memberof IssueTrackerSyncConfig
     */
    context: IssueTrackerSyncConfigContextEnum;
    /**
     * Whether auto-export is enabled
     * @type {boolean}
     * @memberof IssueTrackerSyncConfig
     */
    enabled: boolean;
    /**
     * Whether private incidents will be exported
     * @type {boolean}
     * @memberof IssueTrackerSyncConfig
     */
    include_private_incidents: boolean;
    /**
     * 
     * @type {BranchesOnlyExpression}
     * @memberof IssueTrackerSyncConfig
     */
    issue_template_expression?: BranchesOnlyExpression;
    /**
     * 
     * @type {Array<LegacyIssueTemplate>}
     * @memberof IssueTrackerSyncConfig
     */
    issue_templates: Array<LegacyIssueTemplate>;
}

/**
* @export
* @enum {string}
*/
export enum IssueTrackerSyncConfigContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}

export function IssueTrackerSyncConfigFromJSON(json: any): IssueTrackerSyncConfig {
    return IssueTrackerSyncConfigFromJSONTyped(json, false);
}

export function IssueTrackerSyncConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackerSyncConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'enabled': json['enabled'],
        'include_private_incidents': json['include_private_incidents'],
        'issue_template_expression': !exists(json, 'issue_template_expression') ? undefined : BranchesOnlyExpressionFromJSON(json['issue_template_expression']),
        'issue_templates': ((json['issue_templates'] as Array<any>).map(LegacyIssueTemplateFromJSON)),
    };
}

export function IssueTrackerSyncConfigToJSON(value?: IssueTrackerSyncConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'enabled': value.enabled,
        'include_private_incidents': value.include_private_incidents,
        'issue_template_expression': BranchesOnlyExpressionToJSON(value.issue_template_expression),
        'issue_templates': ((value.issue_templates as Array<any>).map(LegacyIssueTemplateToJSON)),
    };
}

