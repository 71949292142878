import {
  IncidentStatus,
  IncidentStatusCategoryEnum as StatusCategory,
} from "src/contexts/ClientContext";
import { formatTimestampLocale } from "src/utils/datetime";
import { tcx } from "src/utils/tailwind-classes";

import { IconEnum } from "../Icon/Icon";
import { Badge, BadgeSize, BadgeTheme } from "./Badge";

export type IncidentStatusBadgeProps = {
  status: Pick<IncidentStatus, "category" | "name"> | "empty";
  size?: BadgeSize;
  className?: string;
  iconOnly?: boolean;
  iconClassName?: string;
  naked?: boolean;
  pausedUntil?: Date;
  truncate?: boolean;
};

export function IncidentStatusBadge({
  status,
  iconOnly,
  iconClassName,
  size = BadgeSize.Small,
  className,
  naked = false,
  pausedUntil,
  truncate = true,
}: IncidentStatusBadgeProps): React.ReactElement {
  return (
    <Badge
      size={size}
      theme={naked ? BadgeTheme.Naked : BadgeTheme.Secondary}
      className={tcx(
        "shrink dark:text-slate-100",
        truncate && "truncate max-w-[150px]",
        iconOnly ? "justify-center" : "justify-start",
        !naked && "dark:border-slate-700",
        className,
      )}
      icon={
        status === "empty"
          ? IconEnum.IncidentStatusEmpty
          : statusToIconEnum[status.category]
      }
      iconClassName={iconClassName}
    >
      {!iconOnly && (
        <span className={truncate ? "truncate" : ""}>
          {status === "empty" ? "Empty" : status.name}
          <>
            {pausedUntil && (
              <span>
                {" "}
                until{" "}
                {formatTimestampLocale({
                  timestamp: pausedUntil,
                  dateStyle: "short",
                  timeStyle: "short",
                  addWeekday: true,
                })}
              </span>
            )}
          </>
        </span>
      )}
    </Badge>
  );
}

export const statusToIconEnum = {
  [StatusCategory.Triage]: IconEnum.IncidentStatusTriage,
  [StatusCategory.Declined]: IconEnum.IncidentStatusDeclined,
  [StatusCategory.Merged]: IconEnum.IncidentStatusMerged,
  [StatusCategory.Closed]: IconEnum.IncidentStatusClosed,
  [StatusCategory.Canceled]: IconEnum.IncidentStatusCancelled,
  [StatusCategory.PostIncident]: IconEnum.IncidentStatusPostIncident,
  [StatusCategory.Paused]: IconEnum.IncidentStatusPaused,
  [StatusCategory.Active]: IconEnum.IncidentStatusActive,
};
