import type { SVGProps } from "react";
import * as React from "react";
const SvgBilling = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18 6.75A2.752 2.752 0 0 0 15.25 4H4.75A2.752 2.752 0 0 0 2 6.75v.75h16v-.75ZM2 13.25A2.752 2.752 0 0 0 4.75 16h10.5A2.752 2.752 0 0 0 18 13.25V9H2v4.25Zm11.75-1.75h1a.75.75 0 0 1 0 1.5h-1a.75.75 0 0 1 0-1.5Zm-8.5 0h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1 0-1.5Z"
    />
  </svg>
);
export default SvgBilling;
