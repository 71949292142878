import {
  EscalationPathNodeTypeEnum,
  EscalationPathTargetScheduleModeEnum,
  EscalationPathTargetTypeEnum,
  EscalationPathTargetUrgencyEnum,
  UserOptionStateEnum,
} from "@incident-io/api";
import { SelectOption } from "@incident-ui/Select/types";

// Escalation path form data type. The nodes should be updated by ReactFlow using
// callbacks, rather than the form data being updated directly and passed into ReactFlow.
export type EscalationPathFormData = {
  id: string;
  name: string;
  firstNodeId: string;
  nodes: Record<string, PathNode>;
  working_hours?: {
    weekdays: {
      [key: string]: boolean;
    };
    start_time: string;
    end_time: string;
    timezone: string;
  };
};

// In the form state itself, we ban the ReactFlowNodeCustomType options, since those are render-only.
export type PathNode = ReactFlowNodeType & {
  data: { nodeType: EscalationPathNodeTypeEnum };
};

// PathEdge is the data that is passed to the custom edge component by ReactFlow when it is
// rendered.
export type PathEdge = {
  label?: string;
  conditionResult?: "left" | "right";
  skipRender?: boolean;
};

// A standard "notify" node in the escalation path - this is where users choose
// who to page.
export type NodeLevel = {
  nextNodeId?: string;
  targets: EscalationPathTargetFormData[];
  urgency: EscalationPathTargetUrgencyEnum;
  targetSelectionMode: EscalationPathTargetSelectionMode;
  targetRotateAfterMinutes?: number;
} & EscalationPathTimeToAckConfig;

// A standard "notify channel" node in the escalation path - this is where you
// can choose to page one or more channels.
export type NodeNotifyChannel = {
  nextNodeId?: string;
  targets: EscalationPathTargetFormData[];
} & EscalationPathTimeToAckConfig;

// You can either repeat from the very start, or from any condition node in the path
// from the first node to the repeat node.
export type NodeRepeat = {
  repeat_times: string;
  to_node: string;
};

// A condition node: if the condition is met, go to the "then" node, otherwise go to the "else" node.
export type NodeIfElse = {
  thenNodeId: string;
  elseNodeId: string;
} & EscalationPathConditionConfig;

export type EscalationPathConditionConfig = {
  conditionType?: EscalationPathConditionType;
  priorityIds?: string[];
};

export enum EscalationPathConditionType {
  WorkingHoursActive = "working_hours_active",
  Priority = "priority",
}

export interface StartNode {
  id: string;
  data: {
    nodeType: ReactFlowNodeCustomType.Start;
    nextNodeId: string;
  };
}
export interface NodeStart {
  nextNodeId: string;
}

// It's annoying that we have to duplicate so many fields here, but we don't want to put anything related
// to start nodes in the form data type as it's not relevant to the form / API data.
export interface ReactFlowNodeType {
  id: string;
  data: { nodeType: EscalationPathNodeTypeEnum | ReactFlowNodeCustomType } & (
    | { nodeType: ReactFlowNodeCustomType.Start; nextNodeId: string }
    | { nodeType: ReactFlowNodeCustomType.Terminus }
    | {
        nodeType: EscalationPathNodeTypeEnum.Level;
        level: NodeLevel;
        notifyChannel?: never;
        repeat?: never;
        ifElse?: never;
      }
    | {
        nodeType: EscalationPathNodeTypeEnum.NotifyChannel;
        level?: never;
        notifyChannel: NodeNotifyChannel;
        repeat?: never;
        ifElse?: never;
      }
    | {
        nodeType: EscalationPathNodeTypeEnum.Repeat;
        level?: never;
        notifyChannel?: never;
        repeat: NodeRepeat;
        ifElse?: never;
      }
    | {
        nodeType: EscalationPathNodeTypeEnum.IfElse;
        level?: never;
        notifyChannel?: never;
        repeat?: never;
        ifElse: NodeIfElse;
      }
  );
}

export type ReactFlowDataType = ReactFlowNodeType["data"];

export enum ReactFlowNodeCustomType {
  Start = "start",
  Terminus = "terminus",
}

export enum EscalationPathTargetSelectionMode {
  AllAtOnce = "all_at_once",
  RoundRobin = "round_robin",
}

// All possible options for time to ack in a node
export enum EscalationPathTimeToAckOption {
  WorkingHoursActive = "working_hours_active",
  WorkingHoursInactive = "working_hours_inactive",
  MinutesZero = "minutes_zero", // This will only be allowed on channel nodes.
  MinutesFive = "minutes_five",
  MinutesTen = "minutes_ten",
  MinutesFifteen = "minutes_fifteen",
  MinutesCustom = "minutes_custom",
}

export type EscalationPathTimeToAckConfig = {
  time_to_ack_option: EscalationPathTimeToAckOption;
  time_to_ack_custom_minutes?: number;
};

export type EscalationPathTargetFormData =
  | EscalationPathUserTargetFormData
  | EscalationPathScheduleTargetFormData
  | EscalationPathSlackChannelTargetFormData;

export type EscalationPathUserTargetFormData = SelectOption & {
  type: EscalationPathTargetTypeEnum.User;
  state: UserOptionStateEnum;

  // Required for other target types
  schedule_mode?: never;
  selected_rota_id?: never;
};

export type EscalationPathScheduleTargetFormData = SelectOption & {
  type: EscalationPathTargetTypeEnum.Schedule;
  schedule_mode?: EscalationPathTargetScheduleModeEnum;
  selected_rota_id?: string;

  // Required for other target types
  state?: never;
};

export type EscalationPathSlackChannelTargetFormData = SelectOption & {
  type: EscalationPathTargetTypeEnum.SlackChannel;

  // Required for other target types
  schedule_mode?: never;
  selected_rota_id?: never;
  state?: never;
};

export type EscalationPathCatalogBindingData = {
  [catalog_type_id: string]: string[];
};
