import {
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPageStructureGroup,
} from "@incident-io/api";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";

import { ComponentStatuses } from "../view/AffectedComponentsEditor";

export const GroupStatusIndicator = ({
  group,
  statuses,
}: {
  group: StatusPageStructureGroup;
  statuses: ComponentStatuses;
}): React.ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false);

  const impactedComponents = group.components.filter(
    (component) => statuses[component.component_id],
  );
  if (impactedComponents.length === 0) return null;

  const worstStatus = _.maxBy(
    impactedComponents.map((component) => statuses[component.component_id]),
    (x) => x && COMPONENT_STATUS_CONFIG[x].rank,
  );

  if (worstStatus === ComponentStatusEnum.Operational) return null;

  return (
    <>
      <Button
        theme={ButtonTheme.Naked}
        analyticsTrackingId={"status-page-update-toggle-group"}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center w-full"
      >
        <div className="text-content-primary mr-0.5 !font-medium shrink truncate">
          {group.name}
        </div>
        {group.description && <Tooltip content={group.description} />}
        <Icon
          id={isOpen ? IconEnum.Collapse : IconEnum.Expand}
          size={IconSize.Medium}
          className="text-content-tertiary group-hover:text-content-primary transition"
        />
        {worstStatus && !isOpen && (
          <div className="text-content-tertiary ml-2 flex-none">
            {COMPONENT_STATUS_CONFIG[worstStatus].label}
          </div>
        )}
      </Button>
      {isOpen &&
        group.components.map(({ component_id, name, description }) => {
          const status = statuses[component_id];
          if (!status || status === ComponentStatusEnum.Operational) {
            return undefined;
          }

          return (
            <div
              className="text-content-primary flex items-center w-full"
              key={component_id}
            >
              <Icon
                id={IconEnum.NestedInfo}
                className="text-content-tertiary mr-1 flex-none"
                size={IconSize.XS}
              />
              <div className="mr-0.5 shrink truncate">{name}</div>
              {description && <Tooltip content={description} />}
              <div className="text-content-tertiary pl-2 flex-none whitespace-nowrap">
                {COMPONENT_STATUS_CONFIG[status].label}
              </div>
            </div>
          );
        })}
    </>
  );
};
