/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface InternalStatusPageBuildStructureRequestBody
 */
export interface InternalStatusPageBuildStructureRequestBody {
    /**
     * The ID of the custom field that this internal status page uses for components
     * @type {string}
     * @memberof InternalStatusPageBuildStructureRequestBody
     */
    component_custom_field_id: string;
    /**
     * 
     * @type {ExpressionPayload}
     * @memberof InternalStatusPageBuildStructureRequestBody
     */
    component_source_expression?: ExpressionPayload;
    /**
     * IDs of the catalog custom field entries that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageBuildStructureRequestBody
     */
    hidden_catalog_entry_ids: Array<string>;
    /**
     * Values of the catalog custom field groups that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageBuildStructureRequestBody
     */
    hidden_catalog_group_values: Array<string>;
    /**
     * IDs of the custom field option groups that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageBuildStructureRequestBody
     */
    hidden_custom_field_group_ids: Array<string>;
    /**
     * IDs of the custom field options that are hidden from the page structure
     * @type {Array<string>}
     * @memberof InternalStatusPageBuildStructureRequestBody
     */
    hidden_custom_field_option_ids: Array<string>;
}

export function InternalStatusPageBuildStructureRequestBodyFromJSON(json: any): InternalStatusPageBuildStructureRequestBody {
    return InternalStatusPageBuildStructureRequestBodyFromJSONTyped(json, false);
}

export function InternalStatusPageBuildStructureRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageBuildStructureRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_custom_field_id': json['component_custom_field_id'],
        'component_source_expression': !exists(json, 'component_source_expression') ? undefined : ExpressionPayloadFromJSON(json['component_source_expression']),
        'hidden_catalog_entry_ids': json['hidden_catalog_entry_ids'],
        'hidden_catalog_group_values': json['hidden_catalog_group_values'],
        'hidden_custom_field_group_ids': json['hidden_custom_field_group_ids'],
        'hidden_custom_field_option_ids': json['hidden_custom_field_option_ids'],
    };
}

export function InternalStatusPageBuildStructureRequestBodyToJSON(value?: InternalStatusPageBuildStructureRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_custom_field_id': value.component_custom_field_id,
        'component_source_expression': ExpressionPayloadToJSON(value.component_source_expression),
        'hidden_catalog_entry_ids': value.hidden_catalog_entry_ids,
        'hidden_catalog_group_values': value.hidden_catalog_group_values,
        'hidden_custom_field_group_ids': value.hidden_custom_field_group_ids,
        'hidden_custom_field_option_ids': value.hidden_custom_field_option_ids,
    };
}

