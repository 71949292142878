import { TemplatedTextDisplay } from "@incident-shared/forms/v1/TemplatedText";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { STATUS_PAGE_INCIDENT_STATUS_NAME } from "@incident-shared/utils/StatusPages";
import { ErrorMessage } from "@incident-ui";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  MessageTemplateConfig,
  MessageTemplateConfigIncidentStatusEnum as IncidentStatusEnum,
  ScopeNameEnum,
  StatusPage,
  StatusPageCreateTemplateRequestBodyStatusPageIncidentTypeEnum as IncidentTypeEnum,
  StatusPageCreateTemplateRequestBodyTemplateTypeEnum as TemplateTypeEnum,
  StatusPageTemplate,
  TextNode,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { INCIDENT_TYPE_TO_CONFIG } from "./TemplateSettingsPage";

type FormData = {
  content: Record<IncidentStatusEnum, TextNode>;
};

export type MessageTemplate = Omit<
  StatusPageTemplate,
  "predefined_update_config"
> & {
  message_template_config: MessageTemplateConfig; // This is now mandatory!
};

export const MessageTemplatesSection = ({
  incidentType,
  page,
  templates,
}: {
  incidentType: IncidentTypeEnum;
  page: StatusPage;
  templates: MessageTemplate[];
}): React.ReactElement => {
  const {
    statuses,
    label,
    messageTemplateHelptext: helptext,
  } = INCIDENT_TYPE_TO_CONFIG[incidentType];
  const [isEditing, setIsEditing] = useState(false);
  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  if (isEditing) {
    return (
      <MessageTemplatesForm
        page={page}
        onSave={() => setIsEditing(false)}
        incidentType={incidentType}
        templates={templates}
      />
    );
  }

  return (
    <>
      <div className="flex justify-between items-start space-x-4">
        <div className="space-y-4">
          <h3 className="font-medium">{label} templates</h3>
          <div className="mt-1 mb-2 text-sm text-slate-700">{helptext}</div>
        </div>
        <GatedButton
          theme={ButtonTheme.Naked}
          icon={IconEnum.Edit}
          analyticsTrackingId={"edit-message-templates"}
          analyticsTrackingMetadata={{ status_page_id: page.id }}
          onClick={() => setIsEditing(true)}
          disabled={missingPermission}
          disabledTooltipContent={
            missingPermission
              ? "You do not have permission to configure this public status page"
              : undefined
          }
        >
          Edit templates
        </GatedButton>
      </div>
      <div className="flex flex-col space-y-3">
        {statuses.map((status) => {
          const template = _.find(
            templates,
            (template) =>
              (template.message_template_config
                .incident_status as unknown as IncidentStatusEnum) === status,
          );

          return template ? (
            <div key={status}>
              <div className="font-medium text-sm mb-1">
                {STATUS_PAGE_INCIDENT_STATUS_NAME[status]}
              </div>
              <div className="bg-white rounded-[6px] p-4 border border-stroke max-w-3xl text-sm drop-shadow-sm">
                <TemplatedTextDisplay
                  className="!border-0 !p-0"
                  value={template.message_template_config.message_content}
                />
              </div>
            </div>
          ) : (
            <div key={status} className="flex items-center space-x-2 text-sm">
              <div className="font-medium">
                {STATUS_PAGE_INCIDENT_STATUS_NAME[status]}
              </div>
              <div className="text-content-tertiary">No template set</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const MessageTemplatesForm = ({
  page,
  onSave,
  incidentType,
  templates,
}: {
  page: StatusPage;
  onSave: () => void;
  incidentType: IncidentTypeEnum;
  templates: MessageTemplate[];
}): React.ReactElement | null => {
  const { statuses, label } = INCIDENT_TYPE_TO_CONFIG[incidentType];

  const defaultValues = { content: {} };
  templates.forEach((template) => {
    defaultValues.content[template.message_template_config?.incident_status] =
      template.message_template_config?.message_content;
  });

  const formMethods = useForm<FormData>({
    defaultValues: defaultValues,
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageListTemplates",
    { statusPageId: page.id },
    async (apiClient, data: FormData) => {
      await Promise.all(
        statuses.map(async (status) => {
          const template = _.find(
            templates,
            (template) =>
              (template.message_template_config
                .incident_status as unknown as IncidentStatusEnum) === status,
          );
          if (!template && data.content[status]) {
            await apiClient.statusPageCreateTemplate({
              createTemplateRequestBody: {
                status_page_id: page.id,
                template_type: TemplateTypeEnum.MessageTemplate,
                message_template_config: {
                  incident_status: status as IncidentStatusEnum,
                  message_content: data.content[status] as unknown as TextNode,
                },
                status_page_incident_type: incidentType,
              },
            });
          } else if (template && data.content[status]) {
            await apiClient.statusPageUpdateTemplate({
              id: template.id,
              updateTemplateRequestBody: {
                message_template_config: {
                  message_content: data.content[
                    template.message_template_config.incident_status
                  ] as unknown as TextNode,
                },
              },
            });
          } else if (template && !data.content[status]) {
            await apiClient.statusPageDeleteTemplate({
              id: template.id,
            });
          }
        }),
      );
    },
    {
      setError: formMethods.setError,
      onSuccess: () => onSave(),
    },
  );

  return (
    <>
      <h3 className="font-medium">{label} templates</h3>
      <Form.Root
        formMethods={formMethods}
        onSubmit={trigger}
        saving={isMutating}
      >
        <ErrorMessage message={genericError} />
        {statuses.map((status) => {
          return (
            <div key={status}>
              <div className="text-sm font-medium pb-1">
                {STATUS_PAGE_INCIDENT_STATUS_NAME[status]}
              </div>
              <TemplatedTextInputV2
                includeVariables={false}
                includeExpressions={false}
                name={`content.${status}`}
                formMethods={formMethods}
                format="mrkdwn"
              />
            </div>
          );
        })}

        <div className="flex items-center space-x-2">
          <Button
            type="submit"
            theme={ButtonTheme.Primary}
            analyticsTrackingId={"status-page-edit-message-templates"}
            analyticsTrackingMetadata={{ status_page_id: page.id }}
            loading={isMutating}
            className="block"
          >
            Save
          </Button>
          <Button
            type="button"
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={"status-page-cancel-message-templates"}
            analyticsTrackingMetadata={{ status_page_id: page.id }}
            loading={isMutating}
            onClick={() => onSave()}
            className="block"
          >
            Cancel
          </Button>
        </div>
      </Form.Root>
    </>
  );
};
