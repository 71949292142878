import { IssueSyncConfigEditor } from "@incident-shared/issue-templates/IssueSyncConfigEditor";
import { IssueTemplateFailureCallout } from "@incident-shared/issue-templates/IssueTemplateFailureCallout";
import { IssueTemplatesList } from "@incident-shared/issue-templates/IssueTemplatesList";
import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers/useAllTemplates";

import { AddIssueTemplateButton } from "../../@shared/issue-templates/AddIssueTemplateButton";
import { SettingsSubHeading } from "../SettingsSubHeading";

export const FollowUpExportingSection = (): React.ReactElement => {
  return (
    <>
      {/* Define templates */}
      <div>
        <SettingsSubHeading
          title={"Export templates"}
          accessory={
            <AddIssueTemplateButton
              context={IssueTemplateContextEnum.FollowUp}
            />
          }
          explanation={
            <span>
              Use templates to define what fields should be used when exporting
              follow-ups to your issue tracker. These will be available when
              exporting manually, or can be used to automatically export
              follow-ups based on conditions below.
            </span>
          }
        />
        <IssueTemplateFailureCallout
          context={IssueTemplateContextEnum.FollowUp}
        />
        <IssueTemplatesList context={IssueTemplateContextEnum.FollowUp} />
      </div>

      {/* Auto-export expression */}
      <div>
        <SettingsSubHeading title={"Auto-export"} />

        <IssueSyncConfigEditor
          context={IssueTemplateContextEnum.FollowUp}
          title="Enable auto-export"
          description={
            <>
              When enabled, we&apos;ll automatically export follow-ups to your
              issue tracker when they are created.
            </>
          }
          helpLink="https://help.incident.io/en/articles/8447561-auto-exporting-follow-ups"
          privateLabel="Include follow-ups from private incidents"
        />
      </div>
    </>
  );
};
