// For when you don't have the right auth scopes and need to reinstall.

import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  ContentBox,
  IconEnum,
  Loader,
} from "@incident-ui";
import { useLocation } from "react-router";

import { AddToSlackButton } from "../../components/slack/AddToSlackButton";
import { SlackInfoReconnectionReasonEnum as ReconnectionReason } from "../../contexts/ClientContext";
import { useIdentity } from "../../contexts/IdentityContext";
import { useAPI } from "../../utils/swr";
import { ScopesNeededReinstallMessage } from "./ReinstallSlackRoute";

export const ReinstallSlackEnterpriseGridRoute = (): React.ReactElement => {
  const { search } = useLocation();
  const { identity } = useIdentity();

  const { data } = useAPI("settingsGetSlackEnterpriseSettings", undefined);
  const slackEnterpriseSettings = data?.slack_enterprise_settings;

  if (!slackEnterpriseSettings) {
    return <Loader />;
  }
  if (!identity.slack_info) {
    // If you aren't a Slack org, you can't do any reinstalling
    return <OrgAwareNavigate to="/dashboard" />;
  }

  const {
    enterprise_install_url: addToSlackEnterpriseUrl,
    missing_token_scopes: missingTokenScopes,
    reconnection_reason: globalReinstallReason,
  } = identity.slack_info;
  const returnPath = new URLSearchParams(search).get("return_to");

  return (
    <PageWrapper
      icon={IconEnum.SlackGreyscale}
      width={PageWidth.Narrow}
      title="Reinstall incident.io"
      className="text-center"
    >
      <h1 className="mt-8 text-2xl mb-4 font-medium text-content-primary">
        Reinstall incident.io to your Slack Enterprise Grid
      </h1>
      <ContentBox className="space-y-4 p-6">
        <div className="block text-base font-base text-slate-700 prose mx-auto">
          {globalReinstallReason !== ReconnectionReason.Empty ? (
            <>
              {globalReinstallReason === ReconnectionReason.NoWorkspaces ? (
                <>
                  <p className="mb-2">
                    incident.io is installed into your Slack Enterprise Grid
                    organization, however you don&apos;t have any Slack
                    workspaces connected.
                  </p>
                  <p className="text-center mb-4">
                    Click &quot;Add to Slack&quot; below to install the app into
                    a workspace.
                  </p>
                  <Callout theme={CalloutTheme.Info}>
                    Only Slack admins will be able to install incident.io into
                    an Enterprise Grid workspace.
                  </Callout>
                </>
              ) : (
                <>
                  <p className="mb-2">
                    Our connection with your Slack workspace has expired.
                    <br />
                    To fix this, you&apos;ll need to reinstall incident.io to
                    Slack.
                  </p>
                  <p className="text-center mb-4">
                    Click &quot;Add to Slack&quot; below to reinstall the app.
                  </p>
                </>
              )}
            </>
          ) : (
            <ScopesNeededReinstallMessage
              missingTokenScopes={missingTokenScopes}
            />
          )}
        </div>
        {addToSlackEnterpriseUrl && (
          <AddToSlackButton
            url={addToSlackEnterpriseUrl}
            returnPath={returnPath || undefined}
          />
        )}
      </ContentBox>
    </PageWrapper>
  );
};
