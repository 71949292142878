/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Extra data for retrospective incident updates
 * @export
 * @interface RetrospectiveUpdateInfo
 */
export interface RetrospectiveUpdateInfo {
    /**
     * When the original incident update was published
     * @type {Date}
     * @memberof RetrospectiveUpdateInfo
     */
    published_at: Date;
}

export function RetrospectiveUpdateInfoFromJSON(json: any): RetrospectiveUpdateInfo {
    return RetrospectiveUpdateInfoFromJSONTyped(json, false);
}

export function RetrospectiveUpdateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetrospectiveUpdateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'published_at': (new Date(json['published_at'])),
    };
}

export function RetrospectiveUpdateInfoToJSON(value?: RetrospectiveUpdateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'published_at': (value.published_at.toISOString()),
    };
}

