import { CatalogConfigType } from "@incident-io/query-api";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { FC } from "react";
import { AlertSourceAlertManagerTaggingInfo } from "src/components/alerts/alert-source-create-edit-v2/alert-source-tagging-info/AlertSourceAlertManagerTaggingInfo";
import { AlertSourceDatadogTaggingInfo } from "src/components/alerts/alert-source-create-edit-v2/alert-source-tagging-info/AlertSourceDatadogTaggingInfo";
import { AlertSourceGrafanaTaggingInfo } from "src/components/alerts/alert-source-create-edit-v2/alert-source-tagging-info/AlertSourceGrafanaTaggingInfo";
import { IntegrationFeature } from "src/components/settings/integrations/common/AboutThisIntegration";
import { IntegrationCategory } from "src/components/settings/integrations/common/categories";
import {
  AutoCallTeaserToggle,
  DocDestinationTeaser,
  IntegrationTeaserToggle,
} from "src/components/settings/integrations/common/IntegrationTeasers";
import { ZoomOrGoogleMeetConfigureDrawer } from "src/components/settings/integrations/common/ZoomOrGoogleMeetConfigureDrawer";
import { ConnectToAsanaModal } from "src/components/settings/integrations/list/asana/ConnectToAsanaModal";
import { DisconnectAtlassianWarning } from "src/components/settings/integrations/list/atlassian/DisconnectAtlassianWarning";
import { AtlassianStatuspageConfigureDrawer } from "src/components/settings/integrations/list/atlassian-statuspage/AtlassianStatuspageConfigureDrawer";
import { ConnectToAtlassianStatuspageModal } from "src/components/settings/integrations/list/atlassian-statuspage/ConnectToAtlassianStatuspageModal";
import { ConnectToClickUpModal } from "src/components/settings/integrations/list/clickup/ConnectToClickUpModal";
import {
  ConfluenceConfigureDrawer,
  useCanDisconnectConfluence,
} from "src/components/settings/integrations/list/confluence/ConfluenceConfigureDrawer";
import { ConnectConfluenceModal } from "src/components/settings/integrations/list/confluence/ConnectConfluenceModal";
import { ConnectToCortexModal } from "src/components/settings/integrations/list/cortex/ConfigureCortexModal";
import { GithubConfigDrawer } from "src/components/settings/integrations/list/github/GitHubConfigDrawer";
import { ConnectToGitLabModal } from "src/components/settings/integrations/list/gitlab/ConnectGitLabModal";
import { GitLabConfigureDrawer } from "src/components/settings/integrations/list/gitlab/GitLabConfigureDrawer";
import { GoogleCalendarConfigureDrawer } from "src/components/settings/integrations/list/google-calendar/GoogleCalendarConfigureDrawer";
import { GoogleDocsConfigureDrawer } from "src/components/settings/integrations/list/google-docs/GoogleDocsConfigureDrawer";
import { ConnectGoogleWorkspaceModal } from "src/components/settings/integrations/list/google-workspace/ConnectGoogleWorkspaceModal";
import { DisconnectGoogleWorkspaceWarning } from "src/components/settings/integrations/list/google-workspace/DisconnectGoogleWorkspaceWarning";
import { ConnectToGrafanaModal } from "src/components/settings/integrations/list/grafana/ConnectToGrafanaModal";
import { GrafanaConfigureDrawer } from "src/components/settings/integrations/list/grafana/GrafanaConfigureDrawer";
import { ConnectJiraCloudModal } from "src/components/settings/integrations/list/jira/ConnectJiraCloudModal";
import { JiraCloudConfigureDrawer } from "src/components/settings/integrations/list/jira/JiraCloudConfigureDrawer";
import { ConnectToJiraServerModal } from "src/components/settings/integrations/list/jiraserver/ConnectToJiraServerModal";
import { JiraServerConfigureDrawer } from "src/components/settings/integrations/list/jiraserver/JiraServerConfigureDrawer";
import { LinearConfigureDrawer } from "src/components/settings/integrations/list/linear/LinearConfigureDrawer";
import { ConnectToNotionModal } from "src/components/settings/integrations/list/notion/ConnectToNotionModal";
import { NotionConfigureDrawer } from "src/components/settings/integrations/list/notion/NotionConfigureDrawer";
import { ConnectToOpsgenieModal } from "src/components/settings/integrations/list/opsgenie/ConnectToOpsgenieModal";
import { ConnectToOpsLevelModal } from "src/components/settings/integrations/list/opslevel/ConfigureOpsLevelModal";
import { ConnectOutlookCalendarModal } from "src/components/settings/integrations/list/outlook-calendar/ConnectOutlookCalendarModal";
import { OutlookCalendarConfigureDrawer } from "src/components/settings/integrations/list/outlook-calendar/OutlookCalendarConfigureDrawer";
import { ConnectToPagerDutyModal } from "src/components/settings/integrations/list/pagerduty/ConnectToPagerDutyModal";
import { PagerDutyConfigureDrawer } from "src/components/settings/integrations/list/pagerduty/PagerDutyConfigureDrawer";
import { ConnectToSalesforceModal } from "src/components/settings/integrations/list/salesforce/ConnectToSalesforceModal";
import { SalesforceConfigureDrawer } from "src/components/settings/integrations/list/salesforce/SalesforceConfigureDrawer";
import { ConnectToServiceNowModal } from "src/components/settings/integrations/list/service-now/ConnectToServiceNowModal";
import { ConnectToShortcutModal } from "src/components/settings/integrations/list/shortcut/ConnectToShortcutModal";
import { ConnectToSplunkOnCallModal } from "src/components/settings/integrations/list/splunk-on-call/ConnectToSplunkOnCallModal";
import { SplunkOnCallConfigureDrawer } from "src/components/settings/integrations/list/splunk-on-call/SplunkOnCallConfigureDrawer";
import { VantaConfigureDrawer } from "src/components/settings/integrations/list/vanta/VantaConfigureDrawer";
import {
  ConnectToZendeskModal,
  ZendeskConfigureDrawer,
} from "src/components/settings/integrations/list/zendesk/ConnectToZendeskModal";
import {
  AlertSourceSourceTypeEnum,
  AtlassianAuthHandleUninstallProductTypeEnum as UninstallProducType,
  AvailableCatalogTypeColorEnum,
  AvailableCatalogTypeIconEnum,
  ClientType,
  ErrorResponse,
  ExternalUserProviderEnum,
  IntegrationsDestroyIntegrationApiTokenProviderEnum as RemoveIntegrationProvider,
  IntegrationSettings as IntegrationObject,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  IntegrationSettingsReconnectionReasonEnum,
} from "src/contexts/ClientContext";

import { AlertSourceCloudWatchTaggingInfo } from "../../alerts/alert-source-create-edit-v2/alert-source-tagging-info/AlertSourceCloudWatchTaggingInfo";
import { AlertSourceEmailTaggingInfo } from "../../alerts/alert-source-create-edit-v2/alert-source-tagging-info/AlertSourceEmailTaggingInfo";
import { AlertSourceSentryTaggingInfo } from "../../alerts/alert-source-create-edit-v2/alert-source-tagging-info/AlertSourceSentryTaggingInfo";
import { CalendarFeedCustomCTAs } from "../../settings/integrations/list/calendar-feeds/CalendarFeedCustomCTAs";
import { CalendarFeedsConfigureDrawer } from "../../settings/integrations/list/calendar-feeds/CalendarFeedsConfigureDrawer";
import { ZoomConnectModal } from "../../settings/integrations/list/zoom/ZoomConnectModal";

export type ConfigureDrawerProps = {
  integration: IntegrationsListObject;
  aboutThis: React.ReactNode;
  connectionError: React.ReactNode;
  catalogTypeList: React.ReactNode;
};

export type ConnectModalProps = {
  integration: IntegrationsListObject;
  allIntegrations: IntegrationsListObject[];
  onClose: () => void;
  isReconnect?: boolean;
};

export type IntegrationConfig = {
  // How we render the integration
  label: string;
  urlAlias?: string;
  description: string;
  icon: IconEnum;
  hideFromListPage?: boolean;
  hexColor?: string;
  categories: IntegrationCategory[];
  helpcenterArticleID: number;

  // Disconnection info
  onDisconnect?: (apiClient: ClientType) => Promise<void>;
  disconnectWarning?: React.ReactElement;
  canDisconnectHook?: () => {
    canDisconnect: boolean;
    cannotDisconnectExplanation?: string;
    loading: boolean;
    error?: ErrorResponse;
  };

  // Product marketing information

  // These are used to populate that `about this integration` section
  features: IntegrationFeature[];
  // This should be a component that shows a teaser of the feature. For example, a disabled
  // empty state or toggle, that shows the user what they'll get if they install.
  FeatureTeaser?: FC;
  // This allows a component to be rendered in the drawer that shows the user the raw link.
  CopyLink?: (string) => React.ReactNode;

  // Custom components:

  // CustomConnectButton hijacks our default connect button to have different styling.
  // It will open up `CustomConnectModal` if it exists, or redirect to the URL provided by the API.
  CustomConnectButton?: FC;
  // If there's no CustomConnectModal provided, we'll show you a generic "We're about to redirect you"
  // modal which pushes you to the URL provided by the API.
  CustomConnectModal?: FC<ConnectModalProps>;
  CustomConnectCTAs?: FC;
  // If there's no ConfigureDrawerContent provided, we'll default to a simple
  // drawer that shows any available catalog types and the feature cards. This is where
  // you can put additional information about the integration.
  CustomConfigureDrawer?: FC<ConfigureDrawerProps>;
  // hideDefaultCTAs is used to hide the default "Learn more", "Install" and "Disconnect" buttons
  // in the integration drawer title.
  hideDefaultCTAs?: boolean;
};

export const ExportFollowUpsWarning = ({
  providerLabel,
}: {
  providerLabel: string;
}): React.ReactElement => {
  return (
    <>
      <p>
        You&apos;ll no longer be able to export follow-ups to {providerLabel}.
      </p>
      <p>
        Previously exported follow-ups won&apos;t be kept in sync with{" "}
        {providerLabel}.
      </p>
    </>
  );
};

type IntegrationListProviderExcludingAlertSources =
  | IntegrationProvider
  | IssueTrackerProviderEnum
  | ExternalUserProviderEnum
  | "zapier"
  | "backstage"
  | "calendar_feeds";

export type IntegrationListProvider =
  | IntegrationListProviderExcludingAlertSources
  | AlertSourceSourceTypeEnum
  | SCIMProviderEnum;

export const INTEGRATION_CONFIGS: {
  [key in IntegrationListProviderExcludingAlertSources]: IntegrationConfig;
} = {
  [IntegrationProvider.Asana]: {
    label: "Asana",
    categories: [IntegrationCategory.IssueTracker],
    description:
      "Use Asana to export follow-ups and have incident.io monitor the state of them.",
    disconnectWarning: <ExportFollowUpsWarning providerLabel="Asana" />,
    CustomConnectModal: ConnectToAsanaModal,
    onDisconnect: (apiClient) => apiClient.integrationsAsanaUninstall(),
    urlAlias: "asana",
    helpcenterArticleID: 6788488,
    icon: IconEnum.Asana,
    hexColor: "#F06A6A",
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.SyncFollowUps,
    ],
  },
  [IntegrationProvider.ClickUp]: {
    label: "ClickUp",
    categories: [IntegrationCategory.IssueTracker],
    description:
      "Use ClickUp to export follow-ups and have incident.io monitor the state of them.",
    disconnectWarning: <ExportFollowUpsWarning providerLabel="ClickUp" />,
    CustomConnectModal: ConnectToClickUpModal,
    onDisconnect: (apiClient) => apiClient.integrationsClickUpUninstall(),
    urlAlias: "clickup",
    helpcenterArticleID: 8477101,
    icon: IconEnum.ClickUp,
    hexColor: "#8930FD",
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.SyncFollowUps,
    ],
  },
  [IntegrationProvider.Cortex]: {
    label: "Cortex",
    categories: [IntegrationCategory.Collaboration],
    description: "Sync your catalog from Cortex.",
    icon: IconEnum.Cortex,
    hexColor: "#7650E9",
    CustomConnectModal: ConnectToCortexModal,
    disconnectWarning: (
      <>
        <p>
          You&apos;ll no longer sync your Cortex catalog, and the resources that
          were synced into the incident catalog will be removed.
        </p>
      </>
    ),
    onDisconnect: (apiClient) =>
      apiClient.integrationsDestroyIntegrationApiToken({
        provider: RemoveIntegrationProvider.Cortex,
      }),
    helpcenterArticleID: 0,
    features: [
      IntegrationFeature.PowerCustomFields,
      IntegrationFeature.PowerAutomations,
    ],
  },
  [IntegrationProvider.Shortcut]: {
    label: "Shortcut",
    categories: [IntegrationCategory.IssueTracker],
    description:
      "Use Shortcut to export follow-ups and have incident.io monitor the state of them.",
    disconnectWarning: <ExportFollowUpsWarning providerLabel="Shortcut" />,
    CustomConnectModal: ConnectToShortcutModal,
    onDisconnect: (apiClient) => apiClient.integrationsShortcutUninstall(),
    urlAlias: "shortcut",
    helpcenterArticleID: 5948009,
    icon: IconEnum.Shortcut,
    hexColor: "#3C98CB",
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.SyncFollowUps,
    ],
  },
  [IntegrationProvider.Github]: {
    label: "GitHub",
    categories: [
      IntegrationCategory.IssueTracker,
      IntegrationCategory.AlertSource,
    ],
    description:
      "Use Github to export follow-ups and have incident.io monitor the state of them.",
    disconnectWarning: <ExportFollowUpsWarning providerLabel="GitHub" />,
    onDisconnect: (apiClient) => apiClient.integrationsGithubHandleUninstall(),
    CustomConfigureDrawer: GithubConfigDrawer,
    helpcenterArticleID: 5947999,
    icon: IconEnum.Github,
    hexColor: "#0A69DA",
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.AttachPullRequests,
    ],
  },
  [IntegrationProvider.Gitlab]: {
    label: "GitLab",
    categories: [IntegrationCategory.IssueTracker],
    description:
      "Use GitLab to export follow-ups and have incident.io monitor the state of them.",
    disconnectWarning: <ExportFollowUpsWarning providerLabel="GitLab" />,
    onDisconnect: (apiClient) => apiClient.integrationsGitLabUninstall(),
    helpcenterArticleID: 9072207,
    icon: IconEnum.Gitlab,
    hexColor: "#E24329",
    CustomConnectModal: ConnectToGitLabModal,
    CustomConfigureDrawer: GitLabConfigureDrawer,
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.SyncFollowUps,
    ],
  },
  [IntegrationProvider.GoogleDocs]: {
    label: "Google Docs",
    categories: [IntegrationCategory.Document],
    CustomConnectModal: ConnectGoogleWorkspaceModal,
    CustomConfigureDrawer: GoogleDocsConfigureDrawer,
    description: `Use Google Docs to export post-mortems for collaboration.`,
    disconnectWarning: <DisconnectGoogleWorkspaceWarning />,
    onDisconnect: (apiClient) =>
      apiClient.integrationsGoogleWorkspaceHandleUninstall(),
    helpcenterArticleID: 6970632,
    icon: IconEnum.GoogleDocs,
    hexColor: "#4285F4",
    features: [
      IntegrationFeature.ExportPostMortems,
      IntegrationFeature.OrganisePostMortems,
    ],
    FeatureTeaser: () => <DocDestinationTeaser />,
  },
  [IntegrationProvider.GoogleMeet]: {
    label: "Google Meet",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Use Google Meet to automatically start call links for your incident channels.",
    disconnectWarning: <DisconnectGoogleWorkspaceWarning />,
    CustomConnectModal: ConnectGoogleWorkspaceModal,
    CustomConfigureDrawer: ZoomOrGoogleMeetConfigureDrawer,
    onDisconnect: (apiClient) =>
      apiClient.integrationsGoogleWorkspaceHandleUninstall(),
    helpcenterArticleID: 5963058,
    icon: IconEnum.GoogleMeet,
    hexColor: "#00AC47",
    features: [IntegrationFeature.AutoCall, IntegrationFeature.AutoCallTwo],
    FeatureTeaser: () => <AutoCallTeaserToggle providerName="Google Meet" />,
  },
  [IntegrationProvider.GoogleCalendar]: {
    label: "Google Calendar",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Use Google Calendar to automatically link Google Calendar events to incidents.",
    disconnectWarning: <DisconnectGoogleWorkspaceWarning />,
    CustomConnectModal: ConnectGoogleWorkspaceModal,
    onDisconnect: (apiClient) =>
      apiClient.integrationsGoogleWorkspaceHandleUninstall(),
    helpcenterArticleID: 8146184,
    CustomConfigureDrawer: GoogleCalendarConfigureDrawer,
    icon: IconEnum.GoogleCalendar,
    hexColor: "#1E88E5",
    features: [
      IntegrationFeature.DebriefCalendarEvents,
      IntegrationFeature.DebriefPlaceholders,
    ],
  },
  [IntegrationProvider.GoogleWorkspace]: {
    label: "Google Workspace",
    categories: [IntegrationCategory.Escalation],
    icon: IconEnum.Google,
    description: "",
    onDisconnect: async () => void 0,
    disconnectWarning: <>Not supported</>,
    helpcenterArticleID: 0,
    features: [],
    hideFromListPage: true,
  },
  [IntegrationProvider.Grafana]: {
    label: "Grafana",
    categories: [IntegrationCategory.AlertSource],
    helpcenterArticleID: 0,
    description: "Use Grafana to attach alerts to incidents.",
    disconnectWarning: (
      <>
        You&apos;ll no longer receieve screenshots of dashboards in incidents,
        however your alert sources will still continue to function.
      </>
    ),
    onDisconnect: (apiClient) => apiClient.integrationsGrafanaUninstall(),
    icon: IconEnum.Grafana,
    hexColor: "#F15A29",
    CustomConnectModal: ConnectToGrafanaModal,
    CustomConfigureDrawer: GrafanaConfigureDrawer,
    features: [
      IntegrationFeature.AlertSource,
      IntegrationFeature.AlertAttributes,
    ],
  },
  [IntegrationProvider.Confluence]: {
    label: "Confluence",
    categories: [IntegrationCategory.Document],
    description: `Use Confluence to export post-mortems for archival or collaboration.`,
    disconnectWarning: (
      <DisconnectAtlassianWarning provider={IntegrationProvider.Confluence} />
    ),
    canDisconnectHook: useCanDisconnectConfluence,
    CustomConnectModal: ConnectConfluenceModal,
    CustomConfigureDrawer: ConfluenceConfigureDrawer,
    onDisconnect: (apiClient) =>
      apiClient.atlassianAuthHandleUninstall({
        productType: UninstallProducType.Confluence,
      }),
    icon: IconEnum.Confluence,
    hexColor: "#0052CC",
    helpcenterArticleID: 6643127,
    features: [
      IntegrationFeature.ExportPostMortems,
      IntegrationFeature.OrganisePostMortems,
    ],
    FeatureTeaser: () => <DocDestinationTeaser />,
  },
  [IntegrationProvider.Jira]: {
    label: "Jira",
    categories: [
      IntegrationCategory.IssueTracker,
      IntegrationCategory.AlertSource,
    ],
    description:
      "Use Jira Cloud to export follow-ups, and have incident.io monitor the state of them.",
    disconnectWarning: (
      <DisconnectAtlassianWarning provider={IntegrationProvider.Jira} />
    ),
    onDisconnect: (apiClient) =>
      apiClient.atlassianAuthHandleUninstall({
        productType: UninstallProducType.Jira,
      }),
    CustomConnectModal: ConnectJiraCloudModal,
    CustomConfigureDrawer: JiraCloudConfigureDrawer,
    helpcenterArticleID: 5947993,
    icon: IconEnum.Jira,
    hexColor: "#2684FF",
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.SyncFollowUps,
    ],
  },
  [IntegrationProvider.JiraServer]: {
    label: "Jira Server",
    categories: [IntegrationCategory.IssueTracker],
    description:
      "Use Jira Server to export follow-ups, and have incident.io monitor the state of them.",
    disconnectWarning: <ExportFollowUpsWarning providerLabel="Jira Server" />,
    CustomConnectModal: ConnectToJiraServerModal,
    CustomConfigureDrawer: JiraServerConfigureDrawer,
    onDisconnect: (apiClient) =>
      apiClient.integrationsDestroyIntegrationApiToken({
        provider: RemoveIntegrationProvider.JiraServer,
      }),
    // TODO: make this specific to jira server
    helpcenterArticleID: 5947993,
    icon: IconEnum.JiraServer,
    hexColor: "#2684FF",
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.SyncFollowUps,
    ],
    FeatureTeaser: () => (
      <IntegrationTeaserToggle
        label="Sync incidents with Jira Server"
        description="When enabled, we’ll automatically create a ticket in Jira whenever you create an incident with a specified minimum severity."
      />
    ),
  },
  [IntegrationProvider.Linear]: {
    label: "Linear",
    categories: [IntegrationCategory.IssueTracker],
    description:
      "Export follow-ups to Linear and sync on-call schedules which can be used in Linear to assign incoming triage issues.",
    disconnectWarning: <ExportFollowUpsWarning providerLabel="Linear" />,
    onDisconnect: (apiClient) => apiClient.integrationsLinearHandleUninstall(),
    helpcenterArticleID: 5948002,
    CustomConfigureDrawer: LinearConfigureDrawer,
    icon: IconEnum.Linear,
    hexColor: "#5E6AD2",
    features: [
      IntegrationFeature.ExportFollowUps,
      IntegrationFeature.SyncFollowUps,
    ],
  },
  [IntegrationProvider.MicrosoftTeams]: {
    label: "Microsoft Teams",
    categories: [IntegrationCategory.Communication],
    description: "Seamlessly share updates on incidents via Microsoft Teams",
    disconnectWarning: (
      <span className="text-sm">
        You won&apos;t be able to post messages to your colleagues in Microsoft
        Teams.
      </span>
    ),
    onDisconnect: (apiClient) =>
      apiClient.integrationsMicrosoftHandleUninstall(),
    helpcenterArticleID: 5948002,
    icon: IconEnum.MicrosoftTeams,
    features: [],
    hideFromListPage: true,
  },
  [IntegrationProvider.Notion]: {
    label: "Notion",
    categories: [IntegrationCategory.Document],
    description: `Use Notion to export post-mortems for collaboration.`,
    disconnectWarning: (
      <span className="text-sm">
        You&apos;ll no longer be able to export post-mortems to Notion.
      </span>
    ),
    onDisconnect: (apiClient) => apiClient.integrationsNotionHandleUninstall(),
    helpcenterArticleID: 6804517,
    CustomConnectModal: ConnectToNotionModal,
    CustomConfigureDrawer: NotionConfigureDrawer,
    icon: IconEnum.Notion,
    hexColor: "#0885D1",
    features: [
      IntegrationFeature.ExportPostMortems,
      IntegrationFeature.OrganisePostMortems,
    ],
    FeatureTeaser: () => <DocDestinationTeaser />,
  },
  [IntegrationProvider.IncidentIoOnCall]: {
    label: "On-call",
    categories: [IntegrationCategory.Escalation],
    icon: IconEnum.OnCall,
    hexColor: "#F25533",
    description:
      "Use our on-call system to manage your schedules and escalations.",
    onDisconnect: async () => void 0,
    disconnectWarning: <>Not supported</>,
    helpcenterArticleID: 0,
    features: [],
    hideFromListPage: true,
  },
  [IntegrationProvider.OutlookCalendar]: {
    label: "Outlook Calendar",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Use Outlook Calendar to automatically link Outlook Calendar events to incidents.",
    icon: IconEnum.Outlook,
    hexColor: "#316ab7",
    features: [IntegrationFeature.DebriefCalendarEvents],
    disconnectWarning: (
      <>
        We&apos;ll no longer be able to detect debrief meetings in your Outlook
        Calendar
      </>
    ),
    onDisconnect: (apiClient) =>
      apiClient.integrationsOutlookCalendarHandleUninstall(),
    helpcenterArticleID: 0, // TODO: Update with the correct ID
    // https://linear.app/incident-io/issue/RESP-7340/write-a-help-centre-article
    CustomConfigureDrawer: OutlookCalendarConfigureDrawer,
    CustomConnectModal: ConnectOutlookCalendarModal,
  },
  [IntegrationProvider.Opsgenie]: {
    label: "Opsgenie",
    categories: [
      IntegrationCategory.Escalation,
      IntegrationCategory.AlertSource,
    ],
    description: "Use Opsgenie to escalate during incidents.",
    disconnectWarning: (
      <>
        You&apos;ll no longer be able to escalate to Opsgenie from your incident
        channels.
      </>
    ),
    CustomConnectModal: ConnectToOpsgenieModal,
    onDisconnect: (apiClient) =>
      apiClient.integrationsDestroyIntegrationApiToken({
        provider: RemoveIntegrationProvider.Opsgenie,
      }),
    helpcenterArticleID: 5947991,
    icon: IconEnum.Opsgenie,
    hexColor: "#2684FF",
    features: [
      IntegrationFeature.EscalateRightPerson,
      IntegrationFeature.AutoEscalate,
    ],
  },
  [IntegrationProvider.Opslevel]: {
    label: "OpsLevel",
    categories: [IntegrationCategory.Collaboration],
    description: "Sync your catalog from OpsLevel.",
    icon: IconEnum.OpsLevel,
    hexColor: "#0A53E0",
    CustomConnectModal: ConnectToOpsLevelModal,
    disconnectWarning: (
      <>
        <p>
          You&apos;ll no longer sync your OpsLevel catalog, and the resources
          that were synced into the incident catalog will be removed.
        </p>
      </>
    ),
    onDisconnect: (apiClient) =>
      apiClient.integrationsDestroyIntegrationApiToken({
        provider: RemoveIntegrationProvider.Opslevel,
      }),
    helpcenterArticleID: 0,
    features: [
      IntegrationFeature.PowerCustomFields,
      IntegrationFeature.PowerAutomations,
    ],
  },
  [IntegrationProvider.Pagerduty]: {
    label: "PagerDuty",
    categories: [
      IntegrationCategory.Escalation,
      IntegrationCategory.AlertSource,
    ],
    description:
      "Use PagerDuty to escalate during incidents, or auto-create incidents.",
    disconnectWarning: (
      <>
        You&apos;ll no longer be able to create incidents from or escalate to
        PagerDuty.
      </>
    ),
    CustomConnectModal: ConnectToPagerDutyModal,
    CustomConfigureDrawer: PagerDutyConfigureDrawer,
    onDisconnect: (apiClient) => apiClient.integrationsPagerDutyUninstall(),
    helpcenterArticleID: 6180917,
    icon: IconEnum.Pagerduty,
    hexColor: "#06AC38",
    features: [
      IntegrationFeature.EscalateRightPerson,
      IntegrationFeature.AutoEscalate,
    ],
  },
  [IntegrationProvider.Salesforce]: {
    label: "Salesforce",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Connect your Salesforce account to bring in customer information.",
    disconnectWarning: <></>,
    CustomConfigureDrawer: SalesforceConfigureDrawer,
    onDisconnect: (apiClient) =>
      apiClient.integrationsSalesforceHandleUninstall(),
    urlAlias: "salesforce",
    helpcenterArticleID: 8727835,
    icon: IconEnum.Salesforce,
    hexColor: "#0076D3",
    CustomConnectModal: ConnectToSalesforceModal,
    features: [
      IntegrationFeature.PowerCustomFields,
      IntegrationFeature.PowerAutomations,
    ],
  },
  [IntegrationProvider.Sentry]: {
    label: "Sentry",
    categories: [IntegrationCategory.AlertSource],
    description:
      "Use Sentry to link issues with incidents, to hear about updates and resolve them when the incident has closed.",
    CustomConfigureDrawer: undefined,
    disconnectWarning: (
      <>
        <p>
          You&apos;ll no longer be able to attach Sentry issues to incidents, or
          receive updates for those issues into the incident channel.
        </p>
        <br></br>
        <p>
          If that sounds good, we&apos;ll send you to Sentry where you can
          uninstall the incident.io app.
        </p>
      </>
    ),
    //@ts-expect-error type checking is moot when we're about to exit our app
    onDisconnect: (apiClient) => {
      window.location.replace(
        `/auth/sentry_uninstall?organisation_id=${apiClient.orgHeaders.current.orgId}`,
      );
    },
    helpcenterArticleID: 6370788,
    icon: IconEnum.Sentry,
    hexColor: "#362D59",
    features: [
      IntegrationFeature.AlertSource,
      IntegrationFeature.AlertAttributes,
    ],
  },
  [IntegrationProvider.ServiceNow]: {
    label: "ServiceNow",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Connect your ServiceNow account to bring in service information.",
    CustomConfigureDrawer: undefined,
    disconnectWarning: <></>,
    onDisconnect: (apiClient) =>
      apiClient.integrationsServiceNowHandleUninstall(),
    urlAlias: "servicenow",
    helpcenterArticleID: 10132378,
    icon: IconEnum.ServiceNow,
    CustomConnectModal: ConnectToServiceNowModal,
    hexColor: "#1f8476",
    features: [
      IntegrationFeature.PowerAutomations,
      IntegrationFeature.PowerCustomFields,
      IntegrationFeature.ManageTeamMembership,
    ],
  },
  [IntegrationProvider.Scim]: {
    label: "SCIM",
    categories: [IntegrationCategory.Collaboration],
    description: "Use SCIM to manage your user permissions in incident.io.",
    helpcenterArticleID: 0,
    disconnectWarning: <></>,
    onDisconnect: async () => {
      // noop
    },
    icon: IconEnum.Scim,
    features: [],
    hideFromListPage: true,
  },
  [IntegrationProvider.Slack]: {
    label: "Slack",
    categories: [IntegrationCategory.Communication],
    description:
      "Slack is the account connected to incident.io, and used to power the incident bot.",
    CustomConfigureDrawer: undefined,
    disconnectWarning: (
      <>
        <p>You cannot disconnect Slack.</p>
      </>
    ),
    onDisconnect: (_) => {
      throw new Error("unsupported");
    },
    helpcenterArticleID: 0, // no help center for Slack
    icon: IconEnum.Slack,
    features: [],
    hideFromListPage: true,
  },
  [IntegrationProvider.IncidentIoStatusPages]: {
    label: "Status pages",
    categories: [IntegrationCategory.Communication],
    icon: IconEnum.StatusPage,
    description: "Use our status pages to keep your customers in the loop.",
    onDisconnect: async () => void 0,
    disconnectWarning: <>Not supported</>,
    helpcenterArticleID: 0,
    features: [],
    hideFromListPage: true,
  },
  [IntegrationProvider.Statuspage]: {
    label: "Statuspage",
    categories: [IntegrationCategory.Communication],
    description:
      "Use Statuspage to manage your external Statuspage from within an incident channel.",
    disconnectWarning: (
      <>
        You&apos;ll no longer be able to update Statuspage from your incident
        channels.
      </>
    ),
    CustomConnectModal: ConnectToAtlassianStatuspageModal,
    CustomConfigureDrawer: AtlassianStatuspageConfigureDrawer,
    onDisconnect: (apiClient) => {
      return apiClient.integrationsAtlassianStatuspageHandleUninstall();
    },
    helpcenterArticleID: 5948011,
    icon: IconEnum.AtlassianStatuspage,
    hexColor: "#2684FF",
    features: [
      IntegrationFeature.AtlassianStatuspageOne,
      IntegrationFeature.AtlassianStatuspageTwo,
    ],
  },
  [IntegrationProvider.SplunkOnCall]: {
    label: "Splunk On-Call (VictorOps)",
    categories: [
      IntegrationCategory.Escalation,
      IntegrationCategory.AlertSource,
    ],
    description:
      "Use Splunk On-Call to escalate incidents and page policies or people.",
    CustomConnectModal: ConnectToSplunkOnCallModal,
    CustomConfigureDrawer: SplunkOnCallConfigureDrawer,
    disconnectWarning: (
      <>
        <p>
          You&apos;ll no longer be able to escalate incidents to Splunk On-Call
          policies or people.
        </p>
      </>
    ),
    onDisconnect: (apiClient) =>
      apiClient.integrationsSplunkOnCallDestroyToken(),
    helpcenterArticleID: 6679445,
    icon: IconEnum.Splunk,
    hexColor: "#64A525",
    features: [
      IntegrationFeature.EscalateRightPerson,
      IntegrationFeature.AlertSource,
    ],
  },
  [IntegrationProvider.Vanta]: {
    label: "Vanta",
    categories: [IntegrationCategory.Compliance],
    description:
      "Maintain policy compliance by having incident.io data synced with Vanta.",
    CustomConfigureDrawer: VantaConfigureDrawer,
    disconnectWarning: <p>We&apos;ll no longer sync your data with Vanta.</p>,
    onDisconnect: (apiClient) => apiClient.integrationsVantaHandleUninstall(),
    helpcenterArticleID: 6702428,
    icon: IconEnum.Vanta,
    hexColor: "#240642",
    features: [IntegrationFeature.VantaOne, IntegrationFeature.VantaTwo],
    FeatureTeaser: () => (
      <IntegrationTeaserToggle label="Track follow-ups in Vanta" />
    ),
  },
  [IntegrationProvider.Zendesk]: {
    label: "Zendesk",
    categories: [
      IntegrationCategory.CustomerSupport,
      IntegrationCategory.AlertSource,
    ],
    description:
      "Use Zendesk to link tickets with incidents, and to push updates back into Zendesk.",
    CustomConnectModal: ConnectToZendeskModal,
    CustomConfigureDrawer: ZendeskConfigureDrawer,
    disconnectWarning: (
      <>
        <p>
          You&apos;ll no longer be able to attach Zendesk tickets to incidents,
          or receive updates for the incident inside of Zendesk.
        </p>
      </>
    ),
    onDisconnect: (apiClient) => apiClient.integrationsZendeskHandleUninstall(),
    helpcenterArticleID: 6474803,
    icon: IconEnum.Zendesk,
    hexColor: "#00363D",
    features: [IntegrationFeature.ZendeskOne, IntegrationFeature.ZendeskTwo],
  },
  [IntegrationProvider.Zoom]: {
    label: "Zoom",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Use Zoom to automatically start call links for your incident channels, and more.",
    disconnectWarning: (
      <>
        We&apos;ll no longer be able to start call links for your incident
        channels.
      </>
    ),
    CustomConnectModal: ZoomConnectModal,
    CustomConfigureDrawer: ZoomOrGoogleMeetConfigureDrawer,
    onDisconnect: (apiClient) => apiClient.integrationsZoomHandleUninstall(),
    helpcenterArticleID: 5948013,
    icon: IconEnum.Zoom,
    hexColor: "#0b5cff",
    FeatureTeaser: () => <AutoCallTeaserToggle providerName="Zoom" />,
    features: [IntegrationFeature.AutoCall, IntegrationFeature.AutoCallTwo],
  },
  [IntegrationProvider.Webhooks]: {
    label: "Webhooks",
    categories: [],
    icon: IconEnum.IncidentFlame,
    description:
      "Use webhooks to notify external systems when something happens in incident.io.",
    onDisconnect: async () => void 0,
    disconnectWarning: <>Not supported</>,
    helpcenterArticleID: 6984344,
    features: [],
    hideFromListPage: true,
  },
  backstage: {
    label: "Backstage",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Sync your catalog data from Backstage, and use our plugin to surface incident data.",
    disconnectWarning: <></>,
    onDisconnect: async () => {
      // noop
    },
    helpcenterArticleID: 8062021,
    icon: IconEnum.Backstage,
    features: [
      IntegrationFeature.PowerCustomFields,
      IntegrationFeature.PowerAutomations,
    ],
    hexColor: "#36baa2",
    CustomConnectCTAs: () => (
      <div className="flex gap-2">
        <Button
          icon={IconEnum.ExternalLink}
          href="https://github.com/incident-io/catalog-importer/tree/master/docs/backstage"
          analyticsTrackingId="settings-backstage-catalog-importer"
          openInNewTab
        >
          Start syncing catalog data
        </Button>
        <Button
          icon={IconEnum.ExternalLink}
          href="https://github.com/incident-io/backstage-plugins"
          analyticsTrackingId="settings-backstage-plugin"
        >
          Install the plugin
        </Button>
      </div>
    ),
  },
  calendar_feeds: {
    label: "Calendar feeds",
    categories: [],
    description: "View holidays in your On-call schedules",
    CustomConnectCTAs: CalendarFeedCustomCTAs,
    CustomConfigureDrawer: CalendarFeedsConfigureDrawer,
    helpcenterArticleID: 9596297,
    icon: IconEnum.Calendar,
    hexColor: "#29292F",
    features: [
      IntegrationFeature.CalendarFeedsOne,
      IntegrationFeature.CalendarFeedsTwo,
    ],
  },
  zapier: {
    label: "Zapier",
    categories: [IntegrationCategory.Collaboration],
    description:
      "Use Zapier to automate tasks and integrate with 2000+ other apps.",
    disconnectWarning: <></>,
    onDisconnect: async () => {
      // noop
    },
    helpcenterArticleID: 6299984,
    icon: IconEnum.Zapier,
    hexColor: "#ff4f00",
    features: [IntegrationFeature.ZapierOne, IntegrationFeature.ZapierTwo],
    CustomConnectCTAs: () => (
      <div className="flex gap-4">
        <Button
          href="https://incident.io/zapier-app"
          analyticsTrackingId="settings-zapier-app"
          icon={IconEnum.ExternalLink}
          style={{
            color: "#ff4f00",
          }}
        >
          Use our app
        </Button>
        <Button
          href="https://help.incident.io/en/articles/6299984-using-zapier"
          analyticsTrackingId="settings-zapier-learn-more"
          theme={ButtonTheme.Naked}
          className="text-white hover:text-slate-100"
        >
          Learn more
        </Button>
      </div>
    ),
  },
};

export type IntegrationsListObject = Omit<IntegrationObject, "provider"> & {
  provider: IntegrationListProvider;
};

export const fakeIntegrations: IntegrationsListObject[] = [
  {
    available_catalog_types: [
      {
        name: "Backstage API",
        color: AvailableCatalogTypeColorEnum.Green,
        description: "APIs synced from Backstage",
        icon: AvailableCatalogTypeIconEnum.Cog,
        type_name: `Custom["BackstageAPI"]`,
      },
      {
        name: "Backstage Component",
        color: AvailableCatalogTypeColorEnum.Yellow,
        description: "Components synced from Backstage",
        icon: AvailableCatalogTypeIconEnum.Server,
        type_name: `Custom["BackstageComponent"]`,
      },
      {
        name: "Backstage Domain",
        color: AvailableCatalogTypeColorEnum.Blue,
        description: "Groups of systems that share terminology or purpose",
        icon: AvailableCatalogTypeIconEnum.Box,
        type_name: `Custom["BackstageDomain"]`,
      },
      {
        name: "Backstage Group",
        color: AvailableCatalogTypeColorEnum.Violet,
        description: "Groups synced from Backstage",
        icon: AvailableCatalogTypeIconEnum.Users,
        type_name: `Custom["BackstageGroup"]`,
      },
      {
        name: "Backstage System",
        color: AvailableCatalogTypeColorEnum.Orange,
        description: "Collections of resources",
        icon: AvailableCatalogTypeIconEnum.Box,
        type_name: `Custom["BackstageSystem"]`,
      },
      {
        name: "Backstage User",
        color: AvailableCatalogTypeColorEnum.Blue,
        description: "Users synced from Backstage",
        icon: AvailableCatalogTypeIconEnum.User,
        type_name: `Custom["BackstageUser"]`,
      },
    ],
    installed: false,
    provider: "backstage",
    provider_name: "Backstage",
    requires_upgrade: false,
    reconnection_reason: IntegrationSettingsReconnectionReasonEnum.Empty,
  },
  {
    available_catalog_types: [],
    installed: false,
    provider: "zapier",
    provider_name: "Zapier",
    requires_upgrade: false,
    reconnection_reason: IntegrationSettingsReconnectionReasonEnum.Empty,
  },
  {
    provider_name: "Calendar feeds",
    provider: "calendar_feeds",
    installed: true,
    available_catalog_types: [],
    requires_upgrade: false,
    reconnection_reason: IntegrationSettingsReconnectionReasonEnum.Empty,
  },
];

export type SourceTypeConfig = {
  icon: IconEnum;
  label: string;
  className?: string;
  hideFromIntegrationsList?: boolean;
  hexColor: string;
  taggingInstructionsComponent?: ({
    catalogType,
  }: {
    catalogType: CatalogConfigType;
  }) => React.ReactElement;
};

export const ALERT_SOURCE_TYPE_CONFIGS: {
  [key in AlertSourceSourceTypeEnum]: SourceTypeConfig;
} = {
  [AlertSourceSourceTypeEnum.AppOptics]: {
    icon: IconEnum.SolarWinds,
    label: "AppOptics",
    hexColor: "#00B0FF",
  },
  [AlertSourceSourceTypeEnum.Nagios]: {
    icon: IconEnum.Nagios,
    label: "Nagios Core",
    hexColor: "#4D8AFA",
  },
  [AlertSourceSourceTypeEnum.Http]: {
    icon: IconEnum.Http,
    label: "HTTP",
    className: "text-slate-400 !mr-0",
    hideFromIntegrationsList: true,
    hexColor: "#F25533",
  },
  [AlertSourceSourceTypeEnum.Bugsnag]: {
    icon: IconEnum.BugSnag,
    label: "Bugsnag",
    hexColor: "#84a8ff",
  },
  [AlertSourceSourceTypeEnum.Sentry]: {
    icon: IconEnum.Sentry,
    label: "Sentry",
    className: "text-[#362d59]",
    hideFromIntegrationsList: true,
    hexColor: INTEGRATION_CONFIGS[IntegrationProvider.Sentry]
      .hexColor as string,
    taggingInstructionsComponent: AlertSourceSentryTaggingInfo,
  },
  [AlertSourceSourceTypeEnum.GithubIssue]: {
    icon: IconEnum.Github,
    label: "GitHub",
    hideFromIntegrationsList: true,
    hexColor: INTEGRATION_CONFIGS[IntegrationProvider.Github]
      .hexColor as string,
  },
  [AlertSourceSourceTypeEnum.Datadog]: {
    icon: IconEnum.Datadog,
    label: "Datadog",
    hexColor: "#632CA6",
    taggingInstructionsComponent: AlertSourceDatadogTaggingInfo,
  },
  [AlertSourceSourceTypeEnum.Dynatrace]: {
    icon: IconEnum.Dynatrace,
    label: "Dynatrace",
    hexColor: "#73BE28",
  },
  [AlertSourceSourceTypeEnum.Elasticsearch]: {
    icon: IconEnum.Elastic,
    label: "Elastic",
    hexColor: "#3CBEB1",
  },
  [AlertSourceSourceTypeEnum.Email]: {
    icon: IconEnum.Email,
    label: "Email",
    hexColor: "#29292F",
    taggingInstructionsComponent: AlertSourceEmailTaggingInfo,
  },
  [AlertSourceSourceTypeEnum.Expel]: {
    icon: IconEnum.Expel,
    label: "Expel",
    hexColor: "#1AAE51",
    // We should stop hiding this when we turn on the feature flag: expel-alert-source.
    hideFromIntegrationsList: true,
  },
  [AlertSourceSourceTypeEnum.Opsgenie]: {
    icon: IconEnum.Opsgenie,
    label: "Opsgenie",
    hideFromIntegrationsList: true,
    hexColor: INTEGRATION_CONFIGS[IntegrationProvider.Opsgenie]
      .hexColor as string,
  },
  [AlertSourceSourceTypeEnum.Cloudflare]: {
    icon: IconEnum.Cloudflare,
    label: "Cloudflare",
    hexColor: "#F38020",
  },
  [AlertSourceSourceTypeEnum.StatusCake]: {
    icon: IconEnum.StatusCake,
    label: "StatusCake",
    hexColor: "#FF3180",
  },
  [AlertSourceSourceTypeEnum.Cloudwatch]: {
    icon: IconEnum.CloudWatch,
    className: "rounded-full",
    label: "CloudWatch",
    hexColor: "#D72B6B",
    taggingInstructionsComponent: AlertSourceCloudWatchTaggingInfo,
  },
  [AlertSourceSourceTypeEnum.Cronitor]: {
    icon: IconEnum.Cronitor,
    label: "Cronitor",
    hexColor: "#50CC84",
  },
  [AlertSourceSourceTypeEnum.CrowdstrikeFalcon]: {
    icon: IconEnum.Crowdstrike,
    label: "Crowdstrike Falcon",
    hexColor: "#D30000",
  },
  [AlertSourceSourceTypeEnum.Chronosphere]: {
    icon: IconEnum.Chronosphere,
    label: "Chronosphere",
    hexColor: "#2FBF71",
  },
  [AlertSourceSourceTypeEnum.Zendesk]: {
    icon: IconEnum.Zendesk,
    label: "Zendesk",
    hideFromIntegrationsList: true,
    hexColor: INTEGRATION_CONFIGS[IntegrationProvider.Zendesk]
      .hexColor as string,
  },
  [AlertSourceSourceTypeEnum.Alertmanager]: {
    icon: IconEnum.Prometheus,
    label: "Prometheus Alertmanager",
    hexColor: "#DA4E31",
    taggingInstructionsComponent: AlertSourceAlertManagerTaggingInfo,
  },
  [AlertSourceSourceTypeEnum.AzureMonitor]: {
    icon: IconEnum.AzureMonitor,
    label: "Azure Monitor",
    hexColor: "#0078D4",
  },
  [AlertSourceSourceTypeEnum.Grafana]: {
    icon: IconEnum.Grafana,
    hideFromIntegrationsList: true,
    label: "Grafana",
    hexColor: "#F15A29",
    taggingInstructionsComponent: AlertSourceGrafanaTaggingInfo,
  },
  [AlertSourceSourceTypeEnum.Honeycomb]: {
    icon: IconEnum.Honeycomb,
    label: "Honeycomb",
    hexColor: "#0298EC",
  },
  [AlertSourceSourceTypeEnum.Prtg]: {
    icon: IconEnum.PRTG,
    label: "PRTG",
    hexColor: "#C9D111",
  },
  [AlertSourceSourceTypeEnum.GoogleCloud]: {
    icon: IconEnum.GoogleCloud,
    label: "Google Cloud Platform",
    hexColor: "#4285F4",
  },
  [AlertSourceSourceTypeEnum.Jira]: {
    icon: IconEnum.Jira,
    label: "Jira / ITSM",
    hideFromIntegrationsList: true,
    hexColor: INTEGRATION_CONFIGS[IntegrationProvider.Jira].hexColor as string,
  },
  [AlertSourceSourceTypeEnum.MonteCarlo]: {
    icon: IconEnum.MonteCarlo,
    label: "Monte Carlo",
    hexColor: "#0073b4",
  },
  [AlertSourceSourceTypeEnum.NewRelic]: {
    icon: IconEnum.NewRelic,
    label: "New Relic",
    hexColor: "#00AC69",
  },
  [AlertSourceSourceTypeEnum.Checkly]: {
    icon: IconEnum.Checkly,
    label: "Checkly",
    hexColor: "#0075FF",
  },
  [AlertSourceSourceTypeEnum.Panther]: {
    icon: IconEnum.Panther,
    label: "Panther",
    hexColor: "#0087CA",
  },
  [AlertSourceSourceTypeEnum.PagerDuty]: {
    icon: IconEnum.Pagerduty,
    className: "rounded-full p-[1px]",
    label: "PagerDuty",
    hexColor: INTEGRATION_CONFIGS[IntegrationProvider.Pagerduty]
      .hexColor as string,
    hideFromIntegrationsList: true,
  },
  [AlertSourceSourceTypeEnum.Pingdom]: {
    icon: IconEnum.Pingdom,
    label: "Pingdom",
    hexColor: "#FF6200",
  },
  [AlertSourceSourceTypeEnum.Runscope]: {
    icon: IconEnum.Runscope,
    label: "Runscope",
    hexColor: "#3E64A0",
  },
  [AlertSourceSourceTypeEnum.Sns]: {
    icon: IconEnum.Sns,
    label: "SNS",
    hexColor: "#D72B6C",
  },
  [AlertSourceSourceTypeEnum.Splunk]: {
    icon: IconEnum.Splunk,
    label: "Splunk",
    hexColor: INTEGRATION_CONFIGS[IntegrationProvider.SplunkOnCall]
      .hexColor as string,
    hideFromIntegrationsList: true,
  },
  [AlertSourceSourceTypeEnum.StatusPageViews]: {
    icon: IconEnum.StatusPage,
    className: "text-green-600 !mr-0",
    label: "Status Page Views",
    hexColor: "#1FA382",
  },
  [AlertSourceSourceTypeEnum.IncomingCalls]: {
    icon: IconEnum.IncomingPhoneCall,
    className: "text-blue-600 !mr-0",
    label: "Incoming calls",
    hexColor: "#0229FF",
  },
  [AlertSourceSourceTypeEnum.SumoLogic]: {
    icon: IconEnum.SumoLogic,
    label: "Sumo Logic",
    hexColor: "#000099",
  },
  [AlertSourceSourceTypeEnum.Uptime]: {
    icon: IconEnum.Uptime,
    label: "Uptime",
    hexColor: "#00AA66",
  },
};

// You can get a list of the supported SCIM providers from WorkOS at https://workos.com/docs/reference/directory-sync/directory,
// by expanding the `type` key on the right hand side

export enum SCIMProviderEnum {
  Azure = "azure",
  CyberArk = "cyberark",
  GoogleWorkspace = "google-workspace",
  JumpCloud = "jump cloud",
  Okta = "okta",
  OneLogin = "onelogin",
  PingFederate = "pingfederate",
  Rippling = "rippling",
}

export const SCIM_PROVIDER_CONFIGS: {
  [key in SCIMProviderEnum]: {
    icon: IconEnum;
    label: string;
    hexColor: string;
    // These are the strings that WorkOS sends us when someone connects a SCIM or SAML provider
    scimWorkOSProvider: string;
    samlWorkOSProvider: string;
    teamName: string;
  };
} = {
  [SCIMProviderEnum.Azure]: {
    icon: IconEnum.Microsoft,
    label: "Microsoft Entra ID (Azure ID)",
    hexColor: "#2368B4",
    scimWorkOSProvider: "azure scim v2.0",
    samlWorkOSProvider: "AzureSAML",
    teamName: "Entra Group",
  },
  [SCIMProviderEnum.CyberArk]: {
    icon: IconEnum.CyberArk,
    label: "CyberArk",
    hexColor: "#153159",
    scimWorkOSProvider: "cyberark",
    samlWorkOSProvider: "CyberArkSAML",
    teamName: "CyberArk Group",
  },
  [SCIMProviderEnum.GoogleWorkspace]: {
    icon: IconEnum.Google,
    label: "Google Workspace",
    hexColor: "#4285F4",
    scimWorkOSProvider: "gsuite directory",
    samlWorkOSProvider: "GoogleSAML",
    teamName: "Google Group",
  },
  [SCIMProviderEnum.JumpCloud]: {
    icon: IconEnum.JumpCloud,
    label: "JumpCloud",
    hexColor: "#002B49",
    scimWorkOSProvider: "jump cloud scim v2.0",
    samlWorkOSProvider: "JumpCloudSAML",
    teamName: "JumpCloud Group",
  },
  [SCIMProviderEnum.OneLogin]: {
    icon: IconEnum.OneLogin,
    label: "OneLogin",
    hexColor: "#1A2C36",
    scimWorkOSProvider: "onelogin scim v2.0",
    samlWorkOSProvider: "OneLoginSAML",
    teamName: "OneLogin Group",
  },
  [SCIMProviderEnum.Okta]: {
    icon: IconEnum.Okta,
    label: "Okta",
    hexColor: "#00297A",
    scimWorkOSProvider: "okta scim v2.0",
    samlWorkOSProvider: "OktaSAML",
    teamName: "Okta Group",
  },
  [SCIMProviderEnum.PingFederate]: {
    icon: IconEnum.PingFederate,
    label: "PingFederate",
    hexColor: "#B3282D",
    scimWorkOSProvider: "pingfederate scim v2.0",
    samlWorkOSProvider: "PingFederateSAML",
    teamName: "PingFederate Group",
  },
  [SCIMProviderEnum.Rippling]: {
    icon: IconEnum.Rippling,
    label: "Rippling",
    hexColor: "#FEB71E",
    scimWorkOSProvider: "rippling scim v2.0",
    samlWorkOSProvider: "RipplingSAML",
    teamName: "Rippling Group",
  },
};
