/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface MessageTemplateConfig
 */
export interface MessageTemplateConfig {
    /**
     * Incident status this template applies for
     * @type {string}
     * @memberof MessageTemplateConfig
     */
    incident_status: MessageTemplateConfigIncidentStatusEnum;
    /**
     * 
     * @type {TextNode}
     * @memberof MessageTemplateConfig
     */
    message_content: TextNode;
}

/**
* @export
* @enum {string}
*/
export enum MessageTemplateConfigIncidentStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function MessageTemplateConfigFromJSON(json: any): MessageTemplateConfig {
    return MessageTemplateConfigFromJSONTyped(json, false);
}

export function MessageTemplateConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageTemplateConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_status': json['incident_status'],
        'message_content': TextNodeFromJSON(json['message_content']),
    };
}

export function MessageTemplateConfigToJSON(value?: MessageTemplateConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_status': value.incident_status,
        'message_content': TextNodeToJSON(value.message_content),
    };
}

