import type { SVGProps } from "react";
import * as React from "react";
const SvgSparkles3Coloured = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g filter="url(#sparkles-3-coloured_svg__filter0_i_2157_31531)">
      <path
        fill="url(#sparkles-3-coloured_svg__a)"
        d="m14.02 7.823-3.627-1.44-1.435-3.639c-.181-.459-.934-.459-1.116 0l-1.434 3.64L2.78 7.823a.603.603 0 0 0 0 1.118l3.628 1.44 1.434 3.639a.6.6 0 0 0 1.116 0l1.434-3.64 3.628-1.439a.603.603 0 0 0 0-1.118Z"
      />
      <path
        fill="url(#sparkles-3-coloured_svg__b)"
        d="m5.473 3.408-1.21-.48-.478-1.213c-.06-.153-.311-.153-.372 0l-.478 1.213-1.21.48a.2.2 0 0 0 0 .372l1.21.48.478 1.213a.2.2 0 0 0 .372 0l.478-1.213 1.21-.48a.2.2 0 0 0 0-.372Z"
      />
    </g>
    <defs>
      <linearGradient
        id="sparkles-3-coloured_svg__a"
        x1={14.4}
        x2={-3.056}
        y1={8.4}
        y2={8.731}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#60A5FA" />
        <stop offset={0.4} stopColor="#A855F7" />
        <stop offset={0.7} stopColor="#F5785C" />
      </linearGradient>
      <linearGradient
        id="sparkles-3-coloured_svg__b"
        x1={5.599}
        x2={-0.219}
        y1={3.6}
        y2={3.71}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#60A5FA" />
        <stop offset={0.4} stopColor="#A855F7" />
        <stop offset={0.7} stopColor="#F5785C" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSparkles3Coloured;
