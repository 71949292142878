import {
  HolidayPublicEntry,
  HolidayUserEntry,
  SchedulesListHolidayEntriesResponseBody,
} from "@incident-io/api";
import {
  calculateSegments,
  TimePeriodOption,
} from "@incident-shared/schedules/ScheduleOverview/types";
import _ from "lodash";
import { DateTime } from "luxon";

export type HolidayEntry =
  | HolidayUserEntry
  | (HolidayPublicEntry & {
      start_at: Date;
      end_at: Date;
    });

export const COLLAPSED_ROW_COUNT = 2;

export type Holiday = HolidayUserEntry | HolidayPublicEntry;

const isPublicHoliday = (h: Holiday): h is HolidayPublicEntry => "date" in h;

// aggregateHolidaysToRows takes a holidays response
// and builds up a list of rows, where each row is a list of holidays that don't overlap
export const aggregateHolidaysToRows = ({
  holidaysResponse,
  scheduleTimezone,
}: {
  holidaysResponse: SchedulesListHolidayEntriesResponseBody;
  scheduleTimezone: string;
}) => {
  const allSortedHolidays = _.chain([
    ...holidaysResponse.holiday_public_entries,
    ...holidaysResponse.holiday_user_entries,
  ])
    .map((h: Holiday): HolidayEntry => {
      // For user holidays, we already have start and end time,
      // just return it
      if (!isPublicHoliday(h)) {
        return h;
      }

      // Otherwise, let's calculate the start and endtime from the date
      const date = h.date as string;
      const dateTime = DateTime.fromISO(date, {
        zone: scheduleTimezone,
      });
      return {
        ...h,
        start_at: dateTime.startOf("day").toJSDate(),
        // We want the end of this holiday to be at 00:00 for consistency.
        end_at: dateTime.startOf("day").plus({ day: 1 }).toJSDate(),
      };
    })
    // Sort by earliest start time, followed by longest shifts
    .sortBy((h) => [h.start_at.toISOString(), h.end_at.toISOString(), h.name])
    .value();

  const rows: HolidayEntry[][] = [];
  for (const holiday of allSortedHolidays) {
    let added = false;
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      // If there's any space at any point in this row, add the holiday

      // E.g. '2024-06-23 < 2024-07-08 && 2024-07-05 > 2024-07-07'
      const overlapsExisting = row.some((existing) => {
        return (
          DateTime.fromJSDate(existing.start_at).startOf("minute") <
            DateTime.fromJSDate(holiday.end_at).startOf("minute") &&
          DateTime.fromJSDate(existing.end_at).startOf("minute") >
            DateTime.fromJSDate(holiday.start_at).startOf("minute")
        );
      });
      if (!overlapsExisting) {
        row.push(holiday);
        added = true;
        break;
      }
    }
    if (!added) {
      rows.push([holiday]);
    }
  }

  return rows;
};

// showMorePlaceholdersFromRows calculates which segments
// (i.e. days) have hidden holidays, and returns a list of
// placeholders that can be used to show the user that there
// are hidden holidays
export const showMorePlaceholdersFromRows = ({
  holidayRows,
  timePeriod,
  timelineStartPoint,
  timelineEndpoint,
}: {
  holidayRows: HolidayEntry[][];
  timePeriod: TimePeriodOption;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
}) => {
  if (holidayRows.length <= COLLAPSED_ROW_COUNT) {
    return [];
  }

  const tzSegments = calculateSegments({
    timePeriod,
    timelineStartPoint,
  });

  const hiddenPlaceholders: {
    start_at: DateTime;
    end_at: DateTime;
    count: number;
  }[] = [];
  // For each segment, we want to find the holidays that are hidden
  for (let i = 0; i < tzSegments.length - 1; i++) {
    const segmentStart = tzSegments[i];
    const segmentEnd = tzSegments[i + 1] ?? timelineEndpoint;

    const hiddenHolidays: HolidayEntry[] = [];
    for (let j = COLLAPSED_ROW_COUNT; j < holidayRows.length; j++) {
      const row = holidayRows[j];

      // Add the holidays that start within this segment
      hiddenHolidays.push(
        ...row.filter(
          (r) =>
            DateTime.fromJSDate(r.start_at) >= segmentStart &&
            DateTime.fromJSDate(r.start_at) < segmentEnd,
        ),
      );
    }

    if (hiddenHolidays.length === 0) {
      continue;
    }

    hiddenPlaceholders.push({
      count: hiddenHolidays.length,
      start_at: segmentStart,
      end_at: segmentEnd,
    });
  }

  // If there's only one hidden placeholder, we just end up showing the row
  // as it feels sad to show a "Show more" button for just one row
  if (hiddenPlaceholders.every((p) => p.count === 1)) {
    return [];
  }

  return hiddenPlaceholders;
};
