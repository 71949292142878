/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectOption,
    SelectOptionFromJSON,
    SelectOptionFromJSONTyped,
    SelectOptionToJSON,
} from './SelectOption';
import {
    SelectOptionGroup,
    SelectOptionGroupFromJSON,
    SelectOptionGroupFromJSONTyped,
    SelectOptionGroupToJSON,
} from './SelectOptionGroup';

/**
 * 
 * @export
 * @interface EngineTypeaheadResponseBody
 */
export interface EngineTypeaheadResponseBody {
    /**
     * 
     * @type {Array<SelectOptionGroup>}
     * @memberof EngineTypeaheadResponseBody
     */
    option_groups: Array<SelectOptionGroup>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof EngineTypeaheadResponseBody
     */
    options: Array<SelectOption>;
}

export function EngineTypeaheadResponseBodyFromJSON(json: any): EngineTypeaheadResponseBody {
    return EngineTypeaheadResponseBodyFromJSONTyped(json, false);
}

export function EngineTypeaheadResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineTypeaheadResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'option_groups': ((json['option_groups'] as Array<any>).map(SelectOptionGroupFromJSON)),
        'options': ((json['options'] as Array<any>).map(SelectOptionFromJSON)),
    };
}

export function EngineTypeaheadResponseBodyToJSON(value?: EngineTypeaheadResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'option_groups': ((value.option_groups as Array<any>).map(SelectOptionGroupToJSON)),
        'options': ((value.options as Array<any>).map(SelectOptionToJSON)),
    };
}

