import {
  Badge,
  BadgeTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import React from "react";
import { TaskDueDateExplanation } from "src/components/settings/post-incident-flow/task-create-edit/PostIncidentTaskCreateEditPage";
import {
  IncidentStatus,
  PostIncidentTask,
  PostIncidentTaskSlim,
} from "src/contexts/ClientContext";
import {
  formatDurationInSeconds,
  formatTimestampLocale,
} from "src/utils/datetime";

import { getTaskStatus } from "./PostIncidentList";

export const isTaskOverdue = (
  task: PostIncidentTaskSlim | PostIncidentTask,
) => {
  if (task.completed_at || task.rejected_at) {
    return false;
  }
  return task.due_at && new Date(task.due_at).getTime() < new Date().getTime();
};

export const TaskDueDateBadge = ({
  task,
  statuses,
}: {
  task: PostIncidentTaskSlim | PostIncidentTask;
  statuses: IncidentStatus[];
}) => {
  if (getTaskStatus(task) !== "outstanding") {
    // If the task isn't outstanding, we have nothing to show.
    return null;
  }

  let tooltipContent: React.ReactNode = null;
  let tooltipChildren: React.ReactNode = null;

  if (!task.due_at) {
    // If there's no due date yet, show a tooltip explaining when it'll be set.
    const status = statuses.find(
      (s) => s.id === task.config.incident_status_id,
    );
    if (!status) {
      return null;
    }
    tooltipContent = (
      <TaskDueDateExplanation
        paramBinding={null} // We just say "calculated once entering the status"...
        incidentStatus={status}
        className="whitespace-normal"
      />
    );
    tooltipChildren = <Icon id={IconEnum.Clock} size={IconSize.Small} />;
  } else {
    // Otherwise, we'll show the due date and how long we've got left.
    const formattedTimestamp = formatTimestampLocale({
      timestamp: task.due_at,
      dateStyle: "short",
      timeStyle: "short",
    });

    tooltipContent = `Due on ${formattedTimestamp}`;

    if (isTaskOverdue(task)) {
      // If it's overdue, show the duration since the due date
      const durationInSeconds =
        (new Date().getTime() - new Date(task.due_at).getTime()) / 1000;

      tooltipChildren = (
        <Badge
          theme={BadgeTheme.Warning}
          className="mr-2"
          icon={IconEnum.Clock}
        >
          Overdue by{" "}
          {formatDurationInSeconds(
            durationInSeconds,
            1,
            undefined,
            undefined,
            true,
          )}
        </Badge>
      );
    } else {
      // Otherwise, show how long we've got left
      const durationInSeconds =
        (new Date(task.due_at).getTime() - new Date().getTime()) / 1000;

      tooltipChildren = (
        <Badge
          theme={BadgeTheme.Unstyled}
          className="mr-2 bg-white text-slate-700 border-stroke border"
          icon={IconEnum.Clock}
        >
          Due in{" "}
          {formatDurationInSeconds(
            durationInSeconds,
            1,
            undefined,
            undefined,
            true,
          )}
        </Badge>
      );
    }
  }

  return (
    <TooltipForTaskRow content={tooltipContent} className="text-slate-700">
      {tooltipChildren}
    </TooltipForTaskRow>
  );
};

export const TooltipForTaskRow = ({
  content,
  children,
  className,
}: {
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Tooltip content={content}>
      {/* Wrap the content in a div so that the tooltip can inject a ref */}
      <div className={className}>{children}</div>
    </Tooltip>
  );
};
