import { InputOrVariable, MaybeRef } from "@incident-shared/engine";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Callout, CalloutTheme, Spinner } from "@incident-ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  CustomFieldRequiredV2Enum,
  EngineScope,
  JiraIssueField as JiraIssueField,
  Reference,
  Resource,
} from "src/contexts/ClientContext";
import { lookupInScope } from "src/utils/scope";
import { useAPI, useAPIInfinite } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

export function JiraCloudDropdownOrRefSelect({
  field,
  fieldKey,
  disabled = false,
  resources,
  scope,
  allowedValues = [],
}: {
  field: JiraIssueField;
  fieldKey: string;
  disabled?: boolean;
  scope: EngineScope;
  resources: Resource[];
  allowedValues: string[];
}): React.ReactElement {
  const formMethods = useFormContext();

  const usingRef = formMethods.watch(fieldKey)?.value?.reference;

  const literalKey = `${fieldKey}.value.literal`;
  const referenceKey = `${fieldKey}.value.reference`;

  const defaultAllowedValue = field.allowed_values ? (
    <span>
      &quot;<b>{field.allowed_values[0].value}</b>&quot;
    </span>
  ) : (
    <span>the first allowed value</span>
  );

  const selectedReferenceKey = formMethods.watch(referenceKey); // e.g. "incident.severity"
  const selectedReference = lookupInScope(scope, selectedReferenceKey);
  const selectedResource = resources.find((resource) => {
    return resource.type === selectedReference?.type;
  });

  const selectOptions = allowedValues.map((value) => ({ label: value, value }));

  // Hack alert! This is part two of 'here be dragons'. This is where we decide
  // what variables we're willing to allow the user to choose (currently severity
  // or static custom fields). If you add anything here, you'll probably want to handle
  // it in the RefMatchesWithJiraFieldCallout code below, to help users avoid configuring
  // things that won't work.
  const isSelectableOverride = (entry: MaybeRef) => {
    if (entry.resource.can_be_interpolated) {
      return (
        entry.resource.type === "IncidentSeverity" ||
        entry.resource.type.startsWith("IncidentCustomFieldOption") ||
        entry.resource.type.startsWith("CatalogEntry")
      );
    }
    return false;
  };

  return (
    <div className="space-y-2">
      <Form.InputWrapper
        name={`${fieldKey}.value`}
        label={field.label}
        required={field.required}
      >
        <InputOrVariable
          name={`${fieldKey}.value`}
          label={field.label}
          scopeAndIsAlert={{ scope }}
          required={field.required}
          disabled={disabled}
          array={false}
          includeStatic
          includeExpressions={false}
          includeVariables
          allowAnyResource
          isSelectableOverride={isSelectableOverride}
          renderChildren={(renderLightningButton) => (
            <StaticSingleSelectV2
              formMethods={formMethods}
              className="w-full"
              // We mustn't make this required, or we render the 'please provide a value' error twice, once
              // in InputOrVariable and once here
              required={false}
              name={literalKey}
              options={selectOptions}
              placeholder="Select an option"
              disabled={disabled}
              insetSuffixNode={renderLightningButton()}
            />
          )}
        />
      </Form.InputWrapper>
      {usingRef && (
        <RefMatchesWithJiraFieldCallout
          field={field}
          defaultAllowedValue={defaultAllowedValue}
          selectedReference={selectedReference}
          selectedResource={selectedResource}
        />
      )}
    </div>
  );
}

type RefMatchesWithJiraFieldCalloutProps = {
  field: JiraIssueField;
  defaultAllowedValue: React.ReactElement;
  selectedReference?: Reference;
  selectedResource?: Resource;
};

const RefMatchesWithJiraFieldCallout = (
  props: RefMatchesWithJiraFieldCalloutProps,
): React.ReactElement => {
  if (props.selectedResource?.type.startsWith("IncidentCustomFieldOption")) {
    return <RefMatchesWithJiraFieldCalloutCustomField {...props} />;
  }

  if (props.selectedReference?.type.startsWith("CatalogEntry")) {
    return <RefMatchesWithJiraFieldCalloutCatalogEntry {...props} />;
  }

  if (props.selectedResource) {
    return <RefMatchesWithJiraFieldCalloutInBuiltValue {...props} />;
  }

  return <></>;
};

const RefMatchesWithJiraFieldCalloutCustomField = ({
  field,
  defaultAllowedValue,
  selectedReference,
  selectedResource,
}: RefMatchesWithJiraFieldCalloutProps): React.ReactElement => {
  // "IncidentCustomFieldOption["01H1PHK5B8MCJPXW4YFQ4GV8ES"]" -> "01H1PHK5B8MCJPXW4YFQ4GV8ES"
  const customFieldID =
    selectedResource?.type
      .replaceAll('IncidentCustomFieldOption["', "")
      .replaceAll('"]', "") || "";

  const { data, isLoading } = useAPI("customFieldsShow", { id: customFieldID });

  const customFieldOptions = (data?.custom_field.options || []).map(
    (x) => x.value,
  );

  if (isLoading) {
    return (
      <Callout theme={CalloutTheme.Info} showIcon={false}>
        <div className="flex items-center">
          <div className="mr-1">
            <Spinner />
          </div>
          <span>
            Checking if <b>{selectedReference?.label}</b> can be used with{" "}
            <b>{field.label}</b>.
          </span>
        </div>
      </Callout>
    );
  }

  return (
    <RefMatchesWithJiraFieldCalloutInner
      field={field}
      defaultAllowedValue={defaultAllowedValue}
      selectedReference={selectedReference}
      options={customFieldOptions}
      optionAlwaysPresent={
        data?.custom_field.required_v2 === CustomFieldRequiredV2Enum.Always
      }
    />
  );
};

const RefMatchesWithJiraFieldCalloutCatalogEntry = ({
  field,
  defaultAllowedValue,
  selectedReference,
  selectedResource,
}: RefMatchesWithJiraFieldCalloutProps): React.ReactElement => {
  // "CatalogEntry["01H1PHK5B8MCJPXW4YFQ4GV8ES"]" -> "01H1PHK5B8MCJPXW4YFQ4GV8ES"
  const catalogTypeId =
    selectedResource?.type
      .replaceAll('CatalogEntry["', "")
      .replaceAll('"]', "") || "";

  const { responses, isLoading, isFullyLoaded } = useAPIInfinite(
    "catalogListEntries",
    {
      catalogTypeId,
    },
    { eagerLoad: true },
  );

  const entries = responses.flatMap(({ catalog_entries }) => catalog_entries);

  if (isLoading || !isFullyLoaded) {
    return (
      <Callout theme={CalloutTheme.Info} showIcon={false}>
        <div className="flex items-center">
          <div className="mr-1">
            <Spinner />
          </div>
          <span>
            Checking if <b>{selectedReference?.label}</b> can be used with{" "}
            <b>{field.label}</b>.
          </span>
        </div>
      </Callout>
    );
  }

  return (
    <RefMatchesWithJiraFieldCalloutInner
      field={field}
      defaultAllowedValue={defaultAllowedValue}
      selectedReference={selectedReference}
      options={entries.map((entry) => entry.name)}
      optionAlwaysPresent={false}
    />
  );
};

const RefMatchesWithJiraFieldCalloutInBuiltValue = ({
  field,
  defaultAllowedValue,
  selectedReference,
  selectedResource,
}: RefMatchesWithJiraFieldCalloutProps): React.ReactElement => {
  const selectedResourceOptions = (selectedResource?.options || []).map(
    (x) => x.label,
  );

  return (
    <RefMatchesWithJiraFieldCalloutInner
      field={field}
      defaultAllowedValue={defaultAllowedValue}
      selectedReference={selectedReference}
      selectedResource={selectedResource}
      options={selectedResourceOptions}
      optionAlwaysPresent={false}
    />
  );
};

const RefMatchesWithJiraFieldCalloutInner = ({
  field,
  defaultAllowedValue,
  selectedReference,
  options,
  optionAlwaysPresent,
}: RefMatchesWithJiraFieldCalloutProps & {
  options: string[];
  optionAlwaysPresent: boolean;
}): React.ReactElement => {
  let status:
    | "no_overlap"
    | "some_overlap"
    | "all_overlap"
    | "all_but_null_overlap";

  const valsOverlapping = options.filter((x) =>
    (field.allowed_values || []).map((y) => y.label).includes(x),
  );

  if (options.length === 0) {
    status = "no_overlap";
  } else if (valsOverlapping.length === options.length) {
    if (field.required && !optionAlwaysPresent) {
      // If the Jira field is required, but the option won't always be present,
      // we only have a partial overlap
      status = "all_but_null_overlap";
    } else {
      status = "all_overlap";
    }
  } else if (valsOverlapping.length > 0) {
    status = "some_overlap";
  } else {
    status = "no_overlap";
  }

  switch (status) {
    case "all_overlap":
      return (
        <Callout theme={CalloutTheme.Success}>
          All possible values of <b>{selectedReference?.label}</b> are allowed
          options of <b>{field.label}</b>.
        </Callout>
      );

    case "all_but_null_overlap":
      return (
        <Callout theme={CalloutTheme.Warning}>
          <b>{selectedReference?.label}</b> may not always be set, but{" "}
          <b>{field.label}</b> is required in Jira
          <br />
          If the value is not set on the incident, we will default to using{" "}
          {defaultAllowedValue}.
        </Callout>
      );

    case "some_overlap":
      return (
        <Callout theme={CalloutTheme.Warning}>
          There are some values of <b>{selectedReference?.label}</b> that are
          not allowed options of your Jira field <b>{field.label}</b>.
          <br />
          If one of these values is used, we will default to using{" "}
          {defaultAllowedValue}.
        </Callout>
      );

    case "no_overlap":
      return (
        <Callout theme={CalloutTheme.Danger}>
          There is no overlap between the values of{" "}
          <b>{selectedReference?.label}</b>, and the allowed options of your
          Jira field <b>{field.label}</b>.
          <br />
          We will always use {defaultAllowedValue} instead.
        </Callout>
      );

    default:
      assertUnreachable(status);
  }

  return <></>;
};
