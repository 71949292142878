/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlackTeamConfig,
    SlackTeamConfigFromJSON,
    SlackTeamConfigFromJSONTyped,
    SlackTeamConfigToJSON,
} from './SlackTeamConfig';

/**
 * 
 * @export
 * @interface SlackEnterpriseSettings
 */
export interface SlackEnterpriseSettings {
    /**
     * Whether Slack is installed at the enterprise (grid) level.
     * @type {boolean}
     * @memberof SlackEnterpriseSettings
     */
    is_slack_enterprise_installed: boolean;
    /**
     * Slack's app ID for the incident.io app.
     * @type {string}
     * @memberof SlackEnterpriseSettings
     */
    slack_app_id: string;
    /**
     * The URL to the icon of the Slack Enterprise Grid
     * @type {string}
     * @memberof SlackEnterpriseSettings
     */
    slack_enterprise_icon_url: string;
    /**
     * Slack's enterprise ID for the organisation.
     * @type {string}
     * @memberof SlackEnterpriseSettings
     */
    slack_enterprise_id: string;
    /**
     * Slack team configurations for the organisation.
     * @type {Array<SlackTeamConfig>}
     * @memberof SlackEnterpriseSettings
     */
    slack_team_configs: Array<SlackTeamConfig>;
}

export function SlackEnterpriseSettingsFromJSON(json: any): SlackEnterpriseSettings {
    return SlackEnterpriseSettingsFromJSONTyped(json, false);
}

export function SlackEnterpriseSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlackEnterpriseSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_slack_enterprise_installed': json['is_slack_enterprise_installed'],
        'slack_app_id': json['slack_app_id'],
        'slack_enterprise_icon_url': json['slack_enterprise_icon_url'],
        'slack_enterprise_id': json['slack_enterprise_id'],
        'slack_team_configs': ((json['slack_team_configs'] as Array<any>).map(SlackTeamConfigFromJSON)),
    };
}

export function SlackEnterpriseSettingsToJSON(value?: SlackEnterpriseSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_slack_enterprise_installed': value.is_slack_enterprise_installed,
        'slack_app_id': value.slack_app_id,
        'slack_enterprise_icon_url': value.slack_enterprise_icon_url,
        'slack_enterprise_id': value.slack_enterprise_id,
        'slack_team_configs': ((value.slack_team_configs as Array<any>).map(SlackTeamConfigToJSON)),
    };
}

