import { GenericErrorMessage, Loader } from "@incident-ui";
import { useParams } from "react-router";

import { useAPI } from "../../../../utils/swr";
import { PolicyView } from "./PolicyView";

export const PolicyViewPage = (): React.ReactElement => {
  const { id } = useParams() as { id: string };
  const { data, isLoading, error } = useAPI(id ? "policiesShow" : null, {
    id: id,
  });
  const resource = data?.policy;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return isLoading || !resource ? <Loader /> : <PolicyView policy={resource} />;
};
