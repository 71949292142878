import { cooerceToProduct } from "@incident-shared/billing";
import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import { CatalogEntryBadge } from "@incident-shared/catalog/CatalogEntryBadge";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  GenericErrorMessage,
  IconEnum,
  Loader,
  SearchableDropdown,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { SlackModalFooterButton } from "@incident-ui/MessagePreviews/SlackPreviews/SlackPreviews";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { SelectOption } from "@incident-ui/Select/types";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useCallback, useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { Spinner } from "src/components/@ui/Spinner/Spinner";
import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  CatalogType,
  CatalogTypeColorEnum,
  ClientType,
  IncidentForm,
  IncidentFormEscalateConfigDefaultEscalationProviderEnum,
  IncidentFormEscalationElement,
  IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum,
  IncidentType,
  IntegrationSettingsProviderEnum,
  ScopeNameEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";

import { useIntegrations } from "../../../../../hooks/useIntegrations";
import { useAPI, useAPIMutation } from "../../../../../utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "../../../../../utils/utils";
import {
  getIntegrationConfig,
  integrationGroup,
} from "../../../../catalog/type-list/getIntegrationConfig";
import { SettingsSortableList } from "../../../SettingsSortableList";
import { FORM_TYPE_CONFIG } from "../../common/config";
import { IncidentFormElementListRow } from "../IncidentFormElementListRow";
import { elementIsDivider } from "../lifecycle/IncidentLifecycleFormsEditPage";
import { SlackFormBorder, SlackFormHeaderRow } from "../Slack";
import {
  IncidentEscalateFormElementCreateEditModal,
  IncidentEscalateFormElementCreateEditModalState,
} from "./IncidentEscalateFormElementCreateEditModal";

export const IncidentEscalateFormsEditPage = (): React.ReactElement => {
  const { id: formId } = useParams<{ id: string }>();

  if (!formId) {
    throw new Error("Missing form id");
  }

  const {
    data: formData,
    error: formError,
    isLoading: formLoading,
  } = useAPI("incidentFormsShowForm", { id: formId });

  const {
    data: { incident_form_elements: elements },
    error: elementsError,
    isLoading: elementsLoading,
  } = useAPI(
    "incidentFormsListEscalationElements",
    { incidentFormId: formId },
    { fallbackData: { incident_form_elements: [] } },
  );

  const {
    data: { incident_types: incidentTypes },
    isLoading: incidentTypesLoading,
    error: incidentTypesError,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  if (formError || elementsError || incidentTypesError) {
    return (
      <GenericErrorMessage
        error={formError || elementsError || incidentTypesError}
      />
    );
  }

  if (!formData || formLoading || elementsLoading || incidentTypesLoading) {
    return <FullPageLoader />;
  }

  const formTypeConfig = FORM_TYPE_CONFIG[formData.form.form_type];

  const formIncidentType = incidentTypes.find(
    ({ id }) => id === formData.form.incident_type_id,
  );

  return (
    <SecondaryNavSubPageWrapper
      icon={formTypeConfig.icon}
      title={`Edit ${
        formIncidentType ? formIncidentType.name + " " : ""
      }${formTypeConfig.label.toLowerCase()} form`}
      crumbs={[{ title: "Forms", to: "/settings/forms" }]}
      backHref="/settings/forms"
    >
      <IncidentEscalateFormsEditForm
        elements={elements}
        form={formData.form}
        formIncidentType={formIncidentType}
      />
    </SecondaryNavSubPageWrapper>
  );
};

export type EscalateFormType = {
  elements: Record<string, string | undefined>;
  choose_escalator_option:
    | IncidentFormEscalateConfigDefaultEscalationProviderEnum
    | "let-user-choose";
};

const IncidentEscalateFormsEditForm = ({
  elements,
  form,
  formIncidentType,
}: {
  elements: IncidentFormEscalationElement[];
  form: IncidentForm;
  formIncidentType: IncidentType | undefined;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canModifyScope = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const { hasProduct } = useProductAccess();

  const defaultEscalatorOption = form.escalate_config
    ?.default_escalation_provider
    ? form.escalate_config?.default_escalation_provider
    : "let-user-choose";
  const previewFormMethods = useForm<EscalateFormType>({
    defaultValues: {
      choose_escalator_option: defaultEscalatorOption,
      elements: elements.reduce((defaults, elem) => {
        if (elem.default_value) {
          defaults[elem.id] = elem.default_value.value?.literal;
        }
        return defaults;
      }, {}),
    },
    disabled: !hasProduct(cooerceToProduct(form.required_product)),
  });

  const formTypeConfig = FORM_TYPE_CONFIG[form.form_type];

  const backHref = form.incident_type_id
    ? `/settings/forms/?incident_type=${form.incident_type_id}`
    : "/settings/forms";

  const [createEditModalState, setCreateEditModalState] =
    useState<IncidentEscalateFormElementCreateEditModalState>({
      open: false,
      mode: Mode.Create,
    });

  const { trigger: onDelete } = useAPIMutation(
    "incidentFormsListEscalationElements",
    { incidentFormId: form.id },
    async (apiClient, { id }) => {
      await apiClient.incidentFormsDestroyEscalationElement({ id });
      return;
    },
  );

  const { trigger: onReset, isMutating: resetting } = useAPIMutation(
    "incidentFormsListEscalationElements",
    { incidentFormId: form.id },
    async (apiClient, { id }) => {
      await apiClient.incidentFormsResetEscalationElements({
        incidentFormId: id,
      });
      return;
    },
  );

  const { trigger: updateElementRanks, isMutating: saving } = useAPIMutation(
    "incidentFormsListEscalationElements",
    { incidentFormId: form.id },
    async (apiClient, data: IncidentFormEscalationElement[]) => {
      const rankUpdates = elements
        .filter((el) => el.available_element.can_be_reordered)
        .map((field) => {
          let rank = field.rank;
          const updated = data.find(({ id }) => id === field.id);
          if (updated) {
            rank = updated.rank;
          }
          return {
            resource_id: field.id,
            rank,
          };
        });

      return await apiClient.incidentFormsUpdateEscalationRanks({
        id: form.id,
        updateEscalationRanksRequestBody: {
          rank_updates: rankUpdates,
        },
      });
    },
  );

  const { integrations } = useIntegrations();
  const hasPagerDuty = integrations?.find(
    (i) =>
      i.provider === IntegrationSettingsProviderEnum.Pagerduty && i.installed,
  );
  const hasOpsgenie = integrations?.find(
    (i) =>
      i.provider === IntegrationSettingsProviderEnum.Opsgenie && i.installed,
  );
  const hasSplunkOnCall = integrations?.find(
    (i) =>
      i.provider === IntegrationSettingsProviderEnum.SplunkOnCall &&
      i.installed,
  );

  const hasExternalEscalator = hasPagerDuty || hasOpsgenie || hasSplunkOnCall;

  const escalatorOptions: RadioButtonGroupOption[] = [
    {
      label: "incident.io",
      value: IncidentFormEscalateConfigDefaultEscalationProviderEnum.Native,
    },
  ];

  if (hasPagerDuty) {
    escalatorOptions.push({
      label: "PagerDuty",
      value: IncidentFormEscalateConfigDefaultEscalationProviderEnum.Pagerduty,
    });
  }
  if (hasOpsgenie) {
    escalatorOptions.push({
      label: "Opsgenie",
      value: IncidentFormEscalateConfigDefaultEscalationProviderEnum.Opsgenie,
    });
  }
  if (hasSplunkOnCall) {
    escalatorOptions.push({
      label: "Splunk On-Call",
      value:
        IncidentFormEscalateConfigDefaultEscalationProviderEnum.SplunkOnCall,
    });
  }

  const externalEscalatorOptions = escalatorOptions.filter(
    (option) =>
      option.value !==
      IncidentFormEscalateConfigDefaultEscalationProviderEnum.Native,
  );

  escalatorOptions.push({
    label: "Let user choose",
    value: "let-user-choose",
  });

  const selectedEscalatorOption = previewFormMethods.watch(
    "choose_escalator_option",
  );

  const isEverUsingNativeEscalator =
    selectedEscalatorOption === "let-user-choose" ||
    selectedEscalatorOption ===
      IncidentFormEscalateConfigDefaultEscalationProviderEnum.Native;

  const showToast = useToast();
  const { trigger: updateForm } = useAPIMutation(
    "incidentFormsShowForm",
    { id: form.id },
    async (apiClient, payload: EscalateFormType) => {
      await apiClient.incidentFormsUpdateForm({
        id: form.id,
        updateFormRequestBody: {
          escalate_config:
            payload.choose_escalator_option === "let-user-choose"
              ? undefined
              : {
                  default_escalation_provider:
                    payload.choose_escalator_option as unknown as IncidentFormEscalateConfigDefaultEscalationProviderEnum,
                },
        },
      });
    },
    {
      onError: () => {
        showToast({
          title: "Error updating form",
          theme: ToastTheme.Error,
        });

        previewFormMethods.resetField("choose_escalator_option", {
          defaultValue: defaultEscalatorOption,
        });
      },
    },
  );

  const { trigger: reAddDefaultElement, isMutating: reAddingDefaultElement } =
    useAPIMutation(
      "incidentFormsListEscalationElements",
      { incidentFormId: form.id },
      async (
        apiClient: ClientType,
        formData: {
          element_type:
            | IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum.User
            | IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum.Priority
            | IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum.EscalationPolicy;
        },
      ) => {
        await apiClient.incidentFormsCreateEscalationElement({
          createEscalationElementRequestBody: {
            incident_form_id: form.id,
            element_type: formData.element_type,
          },
        });
      },
    );

  const onAddField = useCallback(
    (type: CatalogType) => {
      if (type.registry_type === "User") {
        reAddDefaultElement({
          element_type:
            IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum.User,
        });
        return;
      }
      if (type.registry_type === "EscalationPath") {
        reAddDefaultElement({
          element_type:
            IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum.EscalationPolicy,
        });
        return;
      }
      if (type.registry_type === "AlertPriority") {
        reAddDefaultElement({
          element_type:
            IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum.Priority,
        });
        return;
      }

      setCreateEditModalState({
        catalogType: type,
        mode: Mode.Create,
        open: true,
        formId: form.id,
        setValue: previewFormMethods.setValue,
        onClose: () =>
          setCreateEditModalState({
            open: false,
            mode: Mode.Create,
          }),
        elementType:
          IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum.CatalogType,
      });
    },
    [form.id, previewFormMethods, setCreateEditModalState, reAddDefaultElement],
  );

  const { data: prioritiesData, isLoading } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );
  const defaultPriority = prioritiesData.priorities.find(
    (priority) => priority.is_default,
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {/* We add a load of margin so you can scroll down the page past the last element */}
      <div className="space-y-4 mb-60">
        <FormProvider<EscalateFormType> {...previewFormMethods}>
          {hasExternalEscalator && (
            <div className={"w-full flex"}>
              {/*  Recreate the poadding and grid for the form, so everything aligns nicely */}
              <div className={"w-[42px] pl-4"}></div>
              <div className={"grid grid-cols-3 w-full"}>
                <div className={"col-span-2"}>
                  <RadioButtonGroupV2
                    boxed
                    name={"choose_escalator_option"}
                    formMethods={previewFormMethods}
                    onValueChange={() =>
                      updateForm(previewFormMethods.getValues())
                    }
                    label={"Send manual escalations to"}
                    srLabel={"Send manual escalations to"}
                    helptext={
                      <>
                        If you choose incident.io, you can choose to fallback to{" "}
                        {joinSpansWithCommasAndConnectorWord(
                          externalEscalatorOptions.map(
                            (o) => o.label as string,
                          ),
                        )}{" "}
                        for specific teams by configuring an additional field
                        using Catalog.
                      </>
                    }
                    className={"z-10"}
                    options={escalatorOptions}
                  />

                  {isEverUsingNativeEscalator && (
                    <div className="mt-6 -mb-2">
                      <h3 className="font-medium text-content-primary text-sm">
                        Customise incident.io manual escalation form
                      </h3>
                      {!elements.some(
                        (el) =>
                          el.available_element.element_type ===
                          AvailableIncidentFormEscalationElementElementTypeEnum.Priority,
                      ) &&
                        defaultPriority !== undefined && (
                          <Callout
                            theme={CalloutTheme.Plain}
                            className="mt-2 inline-flex items-center"
                          >
                            Your form doesn&rsquo;t include a priority field.
                            Escalations will be created with your default
                            priority{" "}
                            <CatalogEntryBadge
                              icon={IconEnum.AlertPriority}
                              label={defaultPriority.name}
                              color={CatalogTypeColorEnum.Yellow}
                              size={BadgeSize.Small}
                              className="align-middle"
                            />
                            .
                          </Callout>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {isEverUsingNativeEscalator && (
            <div className="space-y-6">
              <div className="relative">
                <SlackFormBorder />
                <SettingsSortableList
                  items={elements}
                  canEdit={true}
                  updateItemRanks={updateElementRanks}
                  saving={saving}
                  className="shadow-none [&>li]:!border-x-0 [&>li]:!border-dashed"
                  dragHandleAtTop
                  dragHandleClassName="!mt-5"
                  border={false}
                  itemClassName="bg-white"
                  headerRow={
                    <SlackFormHeaderRow
                      title={`Create escalation${
                        formIncidentType?.name
                          ? ` (${formIncidentType?.name})`
                          : ""
                      }`}
                      backHref={backHref}
                      topRightNode={<DeleteFormButton form={form} />}
                    />
                  }
                  renderItem={(element: IncidentFormEscalationElement) => {
                    return (
                      <IncidentFormElementListRow
                        key={element.id}
                        onEdit={() =>
                          setCreateEditModalState({
                            catalogType: element?.available_element
                              ?.catalog_type as unknown as CatalogType,
                            initialData: element,
                            mode: Mode.Edit,
                            open: true,
                            formId: form.id,
                            setValue: previewFormMethods.setValue,
                            onClose: () =>
                              setCreateEditModalState({
                                open: false,
                                mode: Mode.Create,
                              }),
                            elementType: element.available_element
                              .element_type as unknown as IncidentFormsCreateEscalationElementRequestBodyElementTypeEnum,
                          })
                        }
                        onDelete={() => onDelete({ id: element.id })}
                        canEdit={
                          !elementIsDivider(element) &&
                          canModifyScope &&
                          hasProduct(cooerceToProduct(form.required_product))
                        }
                        label={element.available_element.label}
                        canDelete={
                          elements.length > 1 &&
                          hasProduct(cooerceToProduct(form.required_product))
                        }
                        cannotDeleteReason={
                          "You must include at least one of these fields."
                        }
                        requiredIfText={
                          element?.available_element.catalog_type && (
                            <div className={"flex flex-col gap-y-1"}>
                              <span className={"font-medium mb-1"}>
                                Escalates to
                              </span>
                              {element?.navigation_expression && (
                                <span>incident.io Escalation Path</span>
                              )}
                              {element?.pagerduty_service_navigation_expression && (
                                <span>PagerDuty Service</span>
                              )}
                              {element?.opsgenie_team_navigation_expression && (
                                <span>Opsgenie Team</span>
                              )}
                            </div>
                          )
                        }
                        formElement={
                          <EscalateFormElement
                            element={element}
                            formMethods={previewFormMethods}
                          />
                        }
                      />
                    );
                  }}
                />
                <div className="grid grid-cols-3 w-full pl-[43px]">
                  <div className="flex items-end justify-between flex-wrap col-span-2">
                    <div className="p-4 py-6 border-b border-dashed border-stroke flex items-center z-20 w-full">
                      <div className="flex space-x-5 flex-wrap">
                        {reAddingDefaultElement ? (
                          <Spinner className="inline-block w-[30px]" />
                        ) : (
                          <AddFieldDropdownButton
                            elements={elements}
                            onAdd={onAddField}
                          />
                        )}
                        <GatedButton
                          className="text-red-600 hover:text-red-800 text-sm"
                          icon={IconEnum.Refresh1}
                          requiredScope={
                            ScopeNameEnum.OrganisationSettingsUpdate
                          }
                          onClick={() => onReset({ id: form.id })}
                          loading={resetting}
                          analyticsTrackingId="form-elements-reset-escalation-form"
                          theme={ButtonTheme.Unstyled}
                        >
                          Reset to defaults
                        </GatedButton>
                      </div>
                    </div>
                    <div className="flex items-center justify-end gap-2 p-4">
                      <SlackModalFooterButton>Cancel</SlackModalFooterButton>
                      <SlackModalFooterButton primary>
                        {formTypeConfig.label}
                      </SlackModalFooterButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </FormProvider>
      </div>
      {createEditModalState?.open && (
        <IncidentEscalateFormElementCreateEditModal {...createEditModalState} />
      )}
    </>
  );
};

const AddFieldDropdownButton = ({
  onAdd,
  elements,
}: {
  onAdd: (el: CatalogType) => void;
  elements: IncidentFormEscalationElement[];
}): React.ReactElement => {
  const { data: allCatalogTypesData, error } = useAPI(
    "catalogListTypes",
    { includeCount: false },
    { fallbackData: { catalog_types: [] } },
  );
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const usedCatalogTypes = new Set(
    elements
      .filter((el) => !!el.available_element.catalog_type?.id)
      .map((el) => el.available_element.catalog_type?.id),
  );

  const allCatalogTypes = allCatalogTypesData.catalog_types;

  const hasPriorityField = elements
    .map((e) => e.available_element.element_type)
    .includes(AvailableIncidentFormEscalationElementElementTypeEnum.Priority);

  const hasEscalationPathField = elements
    .map((e) => e.available_element.element_type)
    .includes(
      AvailableIncidentFormEscalationElementElementTypeEnum.EscalationPolicy,
    );

  const hasUserField = elements
    .map((e) => e.available_element.element_type)
    .includes(AvailableIncidentFormEscalationElementElementTypeEnum.User);

  const entries = allCatalogTypes
    .filter((catalogType) => !usedCatalogTypes.has(catalogType.id))
    .filter(
      (catalogType) =>
        (catalogType.registry_type !== "AlertPriority" || !hasPriorityField) &&
        (catalogType.registry_type !== "EscalationPath" ||
          !hasEscalationPathField) &&
        (catalogType.registry_type !== "User" || !hasUserField),
    )
    .map((catalogType) => {
      const isDefaultGroup = [
        "AlertPriority",
        "User",
        "EscalationPath",
      ].includes(catalogType.registry_type ?? "");
      const group = isDefaultGroup ? "default" : integrationGroup(catalogType);
      const name =
        group === "default"
          ? "Default"
          : getIntegrationConfig(group).label ?? "";

      return {
        item: catalogType,
        label: catalogType.name,
        icon: catalogType.icon as unknown as IconEnum,
        group: {
          name: name,
          label: name,
        },
        sortKey: isDefaultGroup ? "000" : group === "custom" ? "001" : group,
      };
    });

  // Sort the entries to ensure the "Default" group appears first
  entries.sort((a, b) => a.sortKey.localeCompare(b.sortKey));

  return (
    <SearchableDropdown
      onSelectItem={onAdd}
      emptyState="There are no more fields to add"
      entries={entries}
      renderTriggerButton={({ onClick }) => (
        <Button
          icon={IconEnum.Add}
          onClick={onClick}
          analyticsTrackingId="form-elements-add-element"
          theme={ButtonTheme.Naked}
        >
          Add another field
        </Button>
      )}
    />
  );
};

export const DeleteFormButton = ({
  form,
}: {
  form: IncidentForm;
}): React.ReactElement | null => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const navigate = useOrgAwareNavigate();
  const { hasProduct } = useProductAccess();
  const config = FORM_TYPE_CONFIG[form.form_type];

  const { hasScope, isImpersonating } = useIdentity();
  const canDelete =
    hasScope(ScopeNameEnum.IncidentFormsUpdate) &&
    !isImpersonating &&
    hasProduct(cooerceToProduct(form.required_product));

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "incidentFormsListForms",
    undefined,
    async (apiClient) => {
      await apiClient.incidentFormsDestroyForm({ id: form.id });
    },
    {
      onSuccess: () =>
        navigate(`/settings/forms?incident_type=${form.incident_type_id}`),
    },
  );

  // Use the list incident types API as that's what we use all over the app, so saves us
  // making an extra call for this specific type.
  const {
    data: { incident_types: incidentTypes },
    isLoading,
    error,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });
  if (error) {
    throw error;
  }

  if (!form.incident_type_id) {
    // you can't delete a non-incident-type form!
    return null;
  }
  const incidentType = incidentTypes.find(
    (t) => t.id === form.incident_type_id,
  );

  return (
    <>
      <ConfirmationDialog
        title={`Delete form`}
        analyticsTrackingId="delete-incident-form"
        isOpen={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={() => onDelete({})}
        confirmButtonTheme={ButtonTheme.DestroySecondary}
        saving={deleting}
        loading={isLoading}
      >
        <Txt>
          Are you sure that you want to delete the{" "}
          <Txt inline bold>
            {config.label}
          </Txt>{" "}
          form for{" "}
          <Txt inline bold>
            {incidentType?.name}
          </Txt>{" "}
          incidents? <br />
          This will mean we use the global form configuration instead.
        </Txt>
      </ConfirmationDialog>
      <div className="text-left">
        <Button
          analyticsTrackingId="delete-incident-form"
          theme={ButtonTheme.Destroy}
          icon={IconEnum.Delete}
          onClick={() => setShowDeleteDialog(true)}
          disabled={!canDelete}
        >
          Delete form
        </Button>
      </div>
    </>
  );
};

const EscalateFormElement = ({
  element,
  formMethods,
}: {
  element: IncidentFormEscalationElement;
  formMethods: UseFormReturn<EscalateFormType>;
}) => {
  switch (element.available_element.element_type) {
    case AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType:
      return (
        <CatalogTypeEscalationFormElement
          element={element}
          formMethods={formMethods}
        />
      );
    case AvailableIncidentFormEscalationElementElementTypeEnum.EscalationPolicy:
      return (
        <EscalationPolicyEscalationFormElement
          element={element}
          formMethods={formMethods}
        />
      );
    case AvailableIncidentFormEscalationElementElementTypeEnum.User:
      return (
        <UserEscalationFormElement
          element={element}
          formMethods={formMethods}
        />
      );
    case AvailableIncidentFormEscalationElementElementTypeEnum.Priority:
      return (
        <PriorityEscalationFormElement
          element={element}
          formMethods={formMethods}
        />
      );
    default:
      throw new Error(
        `Unrecognised element type: ${element.available_element.element_type}`,
      );
  }
};

const EscalationPolicyEscalationFormElement = ({
  element,
  formMethods,
}: {
  element: IncidentFormEscalationElement;
  formMethods: UseFormReturn<EscalateFormType>;
}) => {
  const apiClient = useClient();

  const {
    data: allCatalogTypesData,
    error,
    isLoading,
  } = useAPI(
    "catalogListTypes",
    { includeCount: false },
    { fallbackData: { catalog_types: [] } },
  );
  if (error) {
    return <GenericErrorMessage error={error} />;
  }
  if (!allCatalogTypesData || isLoading) {
    return <Loader />;
  }
  const allCatalogTypes = allCatalogTypesData.catalog_types;

  const nativeEscalationPoliciesCatalogType = allCatalogTypes.find(
    (x) => x.type_name === "EscalationPath",
  );

  if (!nativeEscalationPoliciesCatalogType) {
    throw new Error(
      "Unable to find catalog type for native escalation policies",
    );
  }

  const loadDefaultValueOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: nativeEscalationPoliciesCatalogType.id,
  });

  const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: nativeEscalationPoliciesCatalogType.id,
  });
  return (
    <>
      <Form.Label htmlFor={element.id}>Escalation path</Form.Label>
      <DynamicSingleSelectV2
        name={`elements.${element.id}`}
        loadOptions={loadDefaultValueOptions}
        hydrateOptions={hydrateDefaultValueOptions}
        formMethods={formMethods}
      />
      {element.description && (
        <Form.Helptext className="text-xs">{element.description}</Form.Helptext>
      )}
    </>
  );
};

const UserEscalationFormElement = ({
  element,
  formMethods,
}: {
  element: IncidentFormEscalationElement;
  formMethods: UseFormReturn<EscalateFormType>;
}) => {
  const apiClient = useClient();

  return (
    <>
      <Form.Label htmlFor={element.id}>User</Form.Label>
      <DynamicSingleSelectV2
        name={`elements.${element.id}`}
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          sortKey: "name",
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
        formMethods={formMethods}
      />
      {element.description && (
        <Form.Helptext className="text-xs">{element.description}</Form.Helptext>
      )}
    </>
  );
};

const PriorityEscalationFormElement = ({
  element,
  formMethods,
}: {
  element: IncidentFormEscalationElement;
  formMethods: UseFormReturn<EscalateFormType>;
}) => {
  const {
    data: { priorities },
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  const priorityOptions = priorities.map((p): SelectOption => {
    return { label: p.name, value: p.id };
  });

  return (
    <>
      <Form.Label htmlFor={element.id}>Priority</Form.Label>
      <StaticSingleSelectV2
        formMethods={formMethods}
        name={`elements.${element.id}`}
        options={priorityOptions}
      />
      {element.description && (
        <Form.Helptext className="text-xs">{element.description}</Form.Helptext>
      )}
    </>
  );
};

const CatalogTypeEscalationFormElement = ({
  element,
  formMethods,
}: {
  element: IncidentFormEscalationElement;
  formMethods: UseFormReturn<EscalateFormType>;
}) => {
  if (!element.available_element.catalog_type) {
    throw new Error("Element of type CatalogType has no attached catalog type");
  }
  const catalogType = element.available_element.catalog_type;
  const apiClient = useClient();
  const loadDefaultValueOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: catalogType.id,
  });

  const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: catalogType.id,
  });

  return (
    <>
      <Form.Label htmlFor={catalogType.id}>{catalogType.name}</Form.Label>
      <DynamicSingleSelectV2
        name={`elements.${element.id}`}
        loadOptions={loadDefaultValueOptions}
        hydrateOptions={hydrateDefaultValueOptions}
        formMethods={formMethods}
      />
      {element.description && (
        <Form.Helptext className="text-xs">{element.description}</Form.Helptext>
      )}
    </>
  );
};
