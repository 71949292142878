import {
  EngineScope,
  IncidentsBuildScopeContextEnum,
  PostIncidentFlow,
  PostIncidentTaskConfigTaskTypeEnum,
  PostmortemsCreateTemplateSectionRequestBodyTypeEnum,
  PostmortemsUpdateTemplateRequestBodyWritingModeEnum,
  PostmortemTemplate,
  PostmortemTemplateSection,
  PostmortemTemplateSectionTypeEnum,
  PostmortemTemplateWritingModeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  ErrorMessage,
  GenericErrorMessage,
  IconBadge,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  getSectionName,
  getSectionTypeName,
} from "src/components/legacy/incident/postincident/utils";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { prepareRankUpdate } from "src/utils/utils";

import { SettingsSortableList } from "../../SettingsSortableList";
import { PostmortemTemplateSectionDrawer } from "./PostmortemTemplateSectionDrawer";
import { PresetSections } from "./sections";

export const PostmortemTemplateDrawer = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { id } = useParams();

  const {
    data,
    isLoading: loadingTemplates,
    error: errorTemplates,
  } = useAPI("postmortemsShowTemplate", { id: id ?? "" });

  // We load this upfront so we can have a nice smooth transition when opening each section drawer.
  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.FullScope,
  );

  const {
    data: { post_incident_flows: flows },
    isLoading: flowsLoading,
    error: flowsError,
  } = useAPI("postIncidentFlowList", undefined, {
    fallbackData: { post_incident_flows: [] },
  });

  const template = data?.postmortem_template;

  if (errorTemplates || scopeError || !template || flowsError) {
    return (
      <Drawer width="medium" onClose={onClose}>
        <PostmortemTemplateDrawerTitle onClose={onClose} />
        <GenericErrorMessage description="We couldn't load your post-mortem template." />
      </Drawer>
    );
  }

  if (loadingTemplates || scopeLoading || flowsLoading) {
    // We purposefully do not show a loading drawer here because it glitches when we replace it with the actual drawer.
    // The trade-off is that the drawer is slightly slower to open.
    return <></>;
  }

  return (
    <PostmortemTemplateDrawerInner
      onClose={onClose}
      template={template}
      scope={scope}
      flows={flows}
    />
  );
};

type PostmortemTemplateDrawerFormData = Pick<
  PostmortemTemplate,
  "name" | "export_title" | "writing_mode"
>;

export const PostmortemTemplateDrawerInner = ({
  onClose,
  template,
  scope,
  flows,
}: {
  onClose: () => void;
  template: PostmortemTemplate;
  scope: EngineScope;
  flows: PostIncidentFlow[];
}) => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const [showSectionCreateDrawer, setShowSectionCreateDrawer] = useState(false);
  const [sectionEditing, setSectionEditing] =
    useState<PostmortemTemplateSection>();

  const formMethods = useForm<PostmortemTemplateDrawerFormData>({
    defaultValues: {
      name: template.name,
      export_title: template.export_title,
      writing_mode: template.writing_mode,
    },
  });

  const { watch } = formMethods;
  const selectedWritingMode = watch("writing_mode");

  const refetchTemplates = useAPIRefetch("postmortemsListTemplates", {});

  const {
    trigger: updateRanks,
    genericError: updateRanksError,
    isMutating: savingRanks,
  } = useAPIMutation(
    "postmortemsShowTemplate",
    { id: template.id },
    async (apiClient, updatedSections: PostmortemTemplateSection[]) =>
      await apiClient.postmortemsUpdateTemplateSectionRanks({
        id: template.id,
        updateTemplateSectionRanksRequestBody:
          prepareRankUpdate(updatedSections),
      }),
  );

  const { setError } = formMethods;

  const {
    trigger: updateTemplate,
    genericError: updateTemplateError,
    isMutating: savingTemplate,
  } = useAPIMutation(
    "postmortemsShowTemplate",
    { id: template.id },
    async (apiClient, updatedTemplate: PostmortemTemplateDrawerFormData) => {
      await apiClient.postmortemsUpdateTemplate({
        id: template.id,
        updateTemplateRequestBody: {
          ...updatedTemplate,
          writing_mode:
            updatedTemplate.writing_mode as unknown as PostmortemsUpdateTemplateRequestBodyWritingModeEnum,
          follow_ups_links_only: template.follow_ups_links_only,
        },
      });
    },
    {
      setError,
      onSuccess: async () => {
        await refetchTemplates();
        onClose();
      },
    },
  );

  const { trigger: createSection, isMutating: isCreatingTemplate } =
    useAPIMutation(
      "postmortemsShowTemplate",
      {
        id: template.id,
      },
      async (
        apiClient,
        { type }: { type: PostmortemsCreateTemplateSectionRequestBodyTypeEnum },
      ) => {
        await apiClient.postmortemsCreateTemplateSection({
          createTemplateSectionRequestBody: {
            postmortem_template_id: template.id,
            type,
          },
        });
      },
    );

  const options: RadioButtonGroupOption[] = [
    {
      label: "Write in incident.io",
      value: PostmortemTemplateWritingModeEnum.InHouse,
      description:
        "Use incident.io’s internal writing experience with direct access to your incident data, the full timeline, and follow-up actions. Once written, you can still export to an external tool.",
    },
    {
      label: "Write in an external tool",
      value: PostmortemTemplateWritingModeEnum.External,
      description:
        "Immediately export your post-mortem template to an external document provider (like Notion, Confluence, and Google Docs) and complete the sections there.",
    },
  ];

  const flowContainsExportTask = flows.some((flow) =>
    flow.task_configs.some(
      (task_config) =>
        task_config.task_type ===
        PostIncidentTaskConfigTaskTypeEnum.CreatePostmortem,
    ),
  );

  const shouldUpdatePostIncFlow =
    selectedWritingMode === PostmortemTemplateWritingModeEnum.InHouse &&
    template.writing_mode === PostmortemTemplateWritingModeEnum.External &&
    flowContainsExportTask;

  return (
    <AnimatePresence>
      <Drawer
        onClose={onClose}
        width="medium"
        side="right"
        isInBackground={!!sectionEditing || showSectionCreateDrawer}
      >
        <DrawerContents>
          <PostmortemTemplateDrawerTitle onClose={onClose} />
          <DrawerBody className="h-full overflow-y-auto">
            <ErrorMessage message={updateRanksError} />
            <Form.Root
              formMethods={formMethods}
              onSubmit={updateTemplate}
              id="create-edit-template-sections"
              saving={savingTemplate}
              genericError={updateTemplateError}
            >
              <InputV2
                formMethods={formMethods}
                name="name"
                label="Name"
                placeholder="Default"
              />
              {/* Sections list */}
              <div className="font-medium text-content-primary text-sm space-y-2 !mb-6">
                <div className="flex justify-between items-center">
                  <div className="font-medium text-content-primary text-sm">
                    Sections
                  </div>
                  <DropdownMenu
                    align="end"
                    triggerButton={
                      <Button
                        analyticsTrackingId="add-section"
                        title="Add"
                        icon={IconEnum.ChevronDown}
                        iconPosition="right"
                        theme={ButtonTheme.Secondary}
                        size={BadgeSize.Medium}
                        disabled={!canEditSettings}
                      >
                        Add
                      </Button>
                    }
                  >
                    {PresetSections.map(({ section, removable }) => {
                      const hasSection = template.sections.some(
                        (sections) => sections.type === section,
                      );
                      return (
                        <DropdownMenuItem
                          key={section}
                          analyticsTrackingId={null}
                          onSelect={() =>
                            removable
                              ? createSection({
                                  // These will match up as this is generated from the true type, but TS doesn't know that
                                  type: section as unknown as PostmortemsCreateTemplateSectionRequestBodyTypeEnum,
                                })
                              : // eslint-disable-next-line @typescript-eslint/no-empty-function
                                () => {}
                          }
                          disabled={
                            removable ? hasSection || isCreatingTemplate : true
                          }
                          icon={hasSection ? IconEnum.Tick : IconEnum.Add}
                          label={getSectionTypeName(section)}
                        />
                      );
                    })}
                    <DropdownMenuItem
                      analyticsTrackingId={null}
                      onSelect={() => setShowSectionCreateDrawer(true)}
                      icon={IconEnum.Add}
                      label="Custom section"
                    />
                  </DropdownMenu>
                </div>
                {template ? (
                  <SettingsSortableList
                    canEdit={canEditSettings}
                    updateItemRanks={updateRanks}
                    saving={savingRanks}
                    items={_.sortBy(
                      template.sections,
                      (section) => section.rank,
                    )}
                    renderItem={(section) => (
                      <SectionRow
                        section={section}
                        setSectionEditing={setSectionEditing}
                        template={template}
                      />
                    )}
                  />
                ) : (
                  <EmptyState
                    icon={IconEnum.Doc}
                    title={"This template is empty"}
                    content="Add a section for responders to complete as part of their post-mortem."
                  />
                )}
              </div>
              <TemplatedTextInputV2
                className="space-y-2"
                scope={scope}
                formMethods={formMethods}
                name="export_title"
                format="plain"
                includeVariables
                includeExpressions={false}
                label="Export title"
                helptext="Choose what your post-mortem will be called when exported."
                required="Please provide a title"
                multiLine={false}
              />
              <RadioButtonGroupV2
                formMethods={formMethods}
                name="writing_mode"
                boxed
                label="Post-mortem writing experience"
                srLabel="Select where to write post-mortems"
                options={options}
              />
              {shouldUpdatePostIncFlow && (
                <Callout
                  theme={CalloutTheme.Warning}
                  cta={
                    <Button
                      href="https://help.incident.io/en/articles/10139050-where-should-i-write-my-post-mortem"
                      analyticsTrackingId="settings-postmortem-writing-experience-learn-more"
                      theme={ButtonTheme.Secondary}
                      openInNewTab
                    >
                      Learn more
                    </Button>
                  }
                >
                  If you plan on using this post-mortem template in a
                  post-incident flow, consider replacing your{" "}
                  <span className="font-semibold">Export the post-mortem</span>{" "}
                  task with the{" "}
                  <span className="font-semibold">Draft the post-mortem</span>{" "}
                  task if you no longer require the post-mortem to be exported.{" "}
                </Callout>
              )}
            </Form.Root>
            {showSectionCreateDrawer && (
              <PostmortemTemplateSectionDrawer
                onClose={() => setShowSectionCreateDrawer(false)}
                template={template}
                scope={scope}
              />
            )}
            {sectionEditing && (
              <PostmortemTemplateSectionDrawer
                onClose={() => setSectionEditing(undefined)}
                template={template}
                section={sectionEditing}
                scope={scope}
              />
            )}
          </DrawerBody>
          <DrawerFooter className="flex justify-end">
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Primary}
              type={"submit"}
              disabled={savingTemplate}
              form="create-edit-template-sections"
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </AnimatePresence>
  );
};

const SectionRow = ({
  section,
  setSectionEditing,
  template,
}: {
  section: PostmortemTemplateSection;
  setSectionEditing: (section: PostmortemTemplateSection) => void;
  template: PostmortemTemplate;
}) => {
  const { trigger: onDelete } = useAPIMutation(
    "postmortemsShowTemplate",
    { id: template.id },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.postmortemsDestroyTemplateSection({ id });
    },
  );

  const isCustom = section.type === PostmortemTemplateSectionTypeEnum.Custom;
  const systemSection = PresetSections.find(
    (systemSection) => systemSection.section === section.type,
  );

  const isEditable = isCustom || systemSection?.editable;
  const isDeletable = isCustom || systemSection?.removable;

  const sectionName = getSectionName(section);

  return (
    <SettingsListItem
      title={sectionName}
      badgeNode={
        section.type === PostmortemTemplateSectionTypeEnum.KeyInformation ? (
          <Tooltip content="Changes to this section will only be reflected in your exported post-mortem. When viewing a post-mortem in the dashboard this information is already incorporated in the page.">
            <Badge
              className="flex-row-reverse"
              theme={BadgeTheme.Tertiary}
              icon={IconEnum.Info}
            >
              Export only
            </Badge>
          </Tooltip>
        ) : undefined
      }
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        edit: isEditable
          ? {
              onEdit: () => setSectionEditing(section),
            }
          : undefined,
        delete: isDeletable
          ? {
              onDelete: () => onDelete({ id: section.id }),
              deleteConfirmationTitle: "Delete section",
              deleteConfirmationContent: (
                <>
                  <div>
                    Are you sure you wish to delete the{" "}
                    <span className="font-bold">{sectionName}</span> section?
                  </div>
                  <div className="mt-2">
                    If this section has been completed on past post-mortems, it
                    will still be visible.
                  </div>
                </>
              ),
              resourceTitle: sectionName,
            }
          : undefined,
      }}
    />
  );
};

const PostmortemTemplateDrawerTitle = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  return (
    <DrawerTitle
      title={`Edit template`}
      onClose={onClose}
      titleAccessory={
        <IconBadge
          icon={IconEnum.Doc} // TODO(PINC-3366): swap icon out
          size={IconSize.Small}
          color={ColorPaletteEnum.Red}
        />
      }
    />
  );
};
