/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagementMeta
 */
export interface ManagementMeta {
    /**
     * Annotations that track metadata about this resource
     * @type {{ [key: string]: string; }}
     * @memberof ManagementMeta
     */
    annotations: { [key: string]: string; };
    /**
     * How is this resource managed
     * @type {string}
     * @memberof ManagementMeta
     */
    managed_by: ManagementMetaManagedByEnum;
    /**
     * The ID of the resource that this relates to
     * @type {string}
     * @memberof ManagementMeta
     */
    resource_id?: string;
    /**
     * The type of the related resource
     * @type {string}
     * @memberof ManagementMeta
     */
    resource_type?: ManagementMetaResourceTypeEnum;
    /**
     * The url of the external repository where this resource is managed (if there is one)
     * @type {string}
     * @memberof ManagementMeta
     */
    source_url?: string;
}

/**
* @export
* @enum {string}
*/
export enum ManagementMetaManagedByEnum {
    Dashboard = 'dashboard',
    Terraform = 'terraform',
    External = 'external'
}/**
* @export
* @enum {string}
*/
export enum ManagementMetaResourceTypeEnum {
    Workflow = 'workflow',
    Schedule = 'schedule'
}

export function ManagementMetaFromJSON(json: any): ManagementMeta {
    return ManagementMetaFromJSONTyped(json, false);
}

export function ManagementMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': json['annotations'],
        'managed_by': json['managed_by'],
        'resource_id': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'resource_type': !exists(json, 'resource_type') ? undefined : json['resource_type'],
        'source_url': !exists(json, 'source_url') ? undefined : json['source_url'],
    };
}

export function ManagementMetaToJSON(value?: ManagementMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'managed_by': value.managed_by,
        'resource_id': value.resource_id,
        'resource_type': value.resource_type,
        'source_url': value.source_url,
    };
}

