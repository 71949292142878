import { ErrorResponse } from "@incident-io/api";
import {
  AvailableFilter,
  enrichAvailableFilterFields,
  useInitialiseInsightsFilters,
} from "@incident-shared/filters";
import _ from "lodash";

import { useQueryParams } from "../../../../../utils/query-params";
import { PanelFormData } from "./useInsightsContext";

export const useGetPanelFilterFields = ({
  panels,
}: {
  panels: PanelFormData[];
}): {
  filterFields: AvailableFilter[];
  filtersLoading: boolean;
  filtersError: ErrorResponse | null;
} => {
  const urlParams = useQueryParams();

  const {
    insightsV3CustomFilterFields,
    loading: filtersLoading,
    error: filtersError,
  } = useInitialiseInsightsFilters(urlParams.toString());

  const nonUniqueFilterContexts = _.flatMap(panels, (panel) => {
    if (panel.filter_contexts) {
      return panel.filter_contexts;
    }
    return []; // to ignore undefined filter_contexts
  });

  const uniqueFilterContexts = _.unionBy(nonUniqueFilterContexts);

  // Add only the filter fields that correspond the the filter contexts
  const availableFilterFields = _.flatMap(
    uniqueFilterContexts,
    (filterContext) => insightsV3CustomFilterFields[filterContext],
  );

  const enrichedFields = enrichAvailableFilterFields(availableFilterFields);

  return { filterFields: enrichedFields, filtersLoading, filtersError };
};
