/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    DebriefInviteesPayload,
    DebriefInviteesPayloadFromJSON,
    DebriefInviteesPayloadFromJSONTyped,
    DebriefInviteesPayloadToJSON,
} from './DebriefInviteesPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface DebriefsUpdateInviteRuleRequestBody
 */
export interface DebriefsUpdateInviteRuleRequestBody {
    /**
     * 
     * @type {Array<ConditionGroupPayload>}
     * @memberof DebriefsUpdateInviteRuleRequestBody
     */
    condition_groups?: Array<ConditionGroupPayload>;
    /**
     * 
     * @type {Array<ExpressionPayload>}
     * @memberof DebriefsUpdateInviteRuleRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * 
     * @type {DebriefInviteesPayload}
     * @memberof DebriefsUpdateInviteRuleRequestBody
     */
    invitees: DebriefInviteesPayload;
}

export function DebriefsUpdateInviteRuleRequestBodyFromJSON(json: any): DebriefsUpdateInviteRuleRequestBody {
    return DebriefsUpdateInviteRuleRequestBodyFromJSONTyped(json, false);
}

export function DebriefsUpdateInviteRuleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsUpdateInviteRuleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': !exists(json, 'condition_groups') ? undefined : ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'invitees': DebriefInviteesPayloadFromJSON(json['invitees']),
    };
}

export function DebriefsUpdateInviteRuleRequestBodyToJSON(value?: DebriefsUpdateInviteRuleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': value.condition_groups === undefined ? undefined : ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'invitees': DebriefInviteesPayloadToJSON(value.invitees),
    };
}

