/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeSchemaPayload,
    CatalogTypeSchemaPayloadFromJSON,
    CatalogTypeSchemaPayloadFromJSONTyped,
    CatalogTypeSchemaPayloadToJSON,
} from './CatalogTypeSchemaPayload';
import {
    ParameterisedResourceArguments,
    ParameterisedResourceArgumentsFromJSON,
    ParameterisedResourceArgumentsFromJSONTyped,
    ParameterisedResourceArgumentsToJSON,
} from './ParameterisedResourceArguments';

/**
 * 
 * @export
 * @interface ParameterisedTypeSchemaPayload
 */
export interface ParameterisedTypeSchemaPayload {
    /**
     * 
     * @type {ParameterisedResourceArguments}
     * @memberof ParameterisedTypeSchemaPayload
     */
    parameterised_resource_arguments?: ParameterisedResourceArguments;
    /**
     * 
     * @type {CatalogTypeSchemaPayload}
     * @memberof ParameterisedTypeSchemaPayload
     */
    schema?: CatalogTypeSchemaPayload;
}

export function ParameterisedTypeSchemaPayloadFromJSON(json: any): ParameterisedTypeSchemaPayload {
    return ParameterisedTypeSchemaPayloadFromJSONTyped(json, false);
}

export function ParameterisedTypeSchemaPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterisedTypeSchemaPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameterised_resource_arguments': !exists(json, 'parameterised_resource_arguments') ? undefined : ParameterisedResourceArgumentsFromJSON(json['parameterised_resource_arguments']),
        'schema': !exists(json, 'schema') ? undefined : CatalogTypeSchemaPayloadFromJSON(json['schema']),
    };
}

export function ParameterisedTypeSchemaPayloadToJSON(value?: ParameterisedTypeSchemaPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameterised_resource_arguments': ParameterisedResourceArgumentsToJSON(value.parameterised_resource_arguments),
        'schema': CatalogTypeSchemaPayloadToJSON(value.schema),
    };
}

