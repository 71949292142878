import {
  ScopeNameEnum,
  StatusPageAffectedComponentStatusEnum,
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
  StatusPageStructure,
  StatusPageStructureComponent,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  LocalDateTime,
} from "@incident-ui";
import { roundToNearestMinutes } from "date-fns";
import _ from "lodash";
import { useIdentity } from "src/contexts/IdentityContext";

import { ComponentImpactTimeline } from "../../common/ComponentImpactTimeline/ComponentImpactTimeline";
import { Impacts } from "../../common/ComponentImpactTimeline/helpers";
import { INCIDENT_STATUS_NAME } from "../utils/utils";
import { StatusPageAffectedItemBadge } from "./StatusIcons";

export const AffectedComponents = ({
  structure,
  incident,
  onEdit,
  highlightUpdate,
}: {
  structure: StatusPageStructure;
  incident: StatusPageIncident;
  onEdit: () => void;
  highlightUpdate: (updateId: string) => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const buildComponent = (component: StatusPageStructureComponent) => {
    const impacts = incident.component_impacts.filter(
      ({ component_id }) => component_id === component.component_id,
    );
    if (impacts.length === 0) return undefined;

    return {
      id: component.component_id,
      label: component.name,
      description: component.description,
      impacts: impacts.map(({ id, start_at, end_at, status }) => ({
        id,
        start_at,
        end_at,
        status: status as unknown as StatusPageAffectedComponentStatusEnum,
      })),
    };
  };

  const impacts: Impacts<StatusPageAffectedComponentStatusEnum>[] = _.compact(
    structure.items.map(
      ({
        group,
        component: comp,
      }): Impacts<StatusPageAffectedComponentStatusEnum> | undefined => {
        if (group) {
          const components = _.compact(
            group.components.map((comp) => buildComponent(comp)),
          );

          if (components.length === 0) return undefined;

          return {
            group: {
              name: group.name,
              description: group.description,
              components,
            },
          };
        }

        if (comp) {
          const component = buildComponent(comp);
          if (!component) return undefined;
          return { component };
        }
        return undefined;
      },
    ),
  );

  const updateAnnotations = _.compact(
    incident.updates.map((update) => {
      return {
        // Round to the nearest minute, since we don't allow sub-minute accuracy
        // on manual timestamps
        timestamp: roundToNearestMinutes(update.published_at),
        onClick: () => highlightUpdate(update.id),
        bubbleContent: (
          <div className="flex flex-col text-white space-y-1">
            <div className="font-medium">
              {INCIDENT_STATUS_NAME[update.to_status]}
            </div>
            <TemplatedTextDisplay
              style={TemplatedTextDisplayStyle.Compact}
              value={update.message}
              className="truncate min-h-8"
            />
            <LocalDateTime
              timestamp={update.published_at}
              className="text-slate-400"
              format="yyyy-MM-dd HH:mm"
            />
          </div>
        ),
      };
    }),
  );

  if (impacts.length === 0) {
    return (
      <div className="text-sm">
        <span className={"font-medium mr-2 text-content-primary"}>
          Affected components
        </span>
        <div className="bg-surface-secondary rounded-2 p-8 border border-stroke mt-2 text-center text-slate-600 space-y-0.5">
          {incident.created_from_atlassian_statuspage_incident ? (
            <>
              <div>
                This incident was imported from Atlassian Statuspage, which does
                not link incidents with component impacts.
              </div>
              <div>
                <GatedButton
                  onClick={onEdit}
                  analyticsTrackingId={"status-page-incident-edit-impacts"}
                  theme={ButtonTheme.Naked}
                  className="underline"
                  disabled={!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)}
                  disabledTooltipContent="You do not have permission to manage incidents on this public status page"
                >
                  Manually modify the timeline
                </GatedButton>{" "}
                to show your customers exactly which components were impacted
                and when.
              </div>
            </>
          ) : (
            <>
              <div className="!m-0">
                This incident has not marked any components as impacted.
              </div>
              <div>
                Publish an update to mark components as currently impacted, or
                you can{" "}
                <GatedButton
                  onClick={onEdit}
                  analyticsTrackingId={"status-page-incident-edit-impacts"}
                  theme={ButtonTheme.Naked}
                  className="underline"
                  disabled={!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)}
                  disabledTooltipContent="You do not have permission to manage incidents on this public status page"
                >
                  manually modify the timeline
                </GatedButton>
                .
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="group/affected-components">
      <div className="flex items-center mb-3 px-2 md:px-0">
        <GatedButton
          theme={ButtonTheme.Naked}
          analyticsTrackingId="rename-incident"
          title="edit"
          onClick={onEdit}
          disabled={!hasScope(ScopeNameEnum.StatusPagesPublishUpdates)}
          disabledTooltipContent="You do not have permission to manage incidents on this public status page"
        >
          Affected components
          <Icon id={IconEnum.Edit} size={IconSize.Medium} />
        </GatedButton>
      </div>

      <ComponentImpactTimeline
        annotations={updateAnnotations}
        impacts={impacts}
        incident={incident}
      />
    </div>
  );
};

export const AffectedComponentsOverview = ({
  incident,
  structure,
}: {
  incident: StatusPageIncident;
  structure: StatusPageStructure;
}) => {
  const maintenanceOrResolvedStatuses = [
    StatusPageIncidentStatusEnum.Resolved,
    StatusPageIncidentStatusEnum.MaintenanceScheduled,
    StatusPageIncidentStatusEnum.MaintenanceInProgress,
    StatusPageIncidentStatusEnum.MaintenanceComplete,
  ];
  return (
    <div className="space-y-2">
      <Heading level={3} size="small" className="font-medium">
        {maintenanceOrResolvedStatuses.includes(incident.status)
          ? "Impacted components"
          : "Current status"}
      </Heading>

      {structure.items.map((item, idx) => (
        <div key={idx}>
          <StatusPageAffectedItemBadge
            className="inline-flex"
            structureItem={item}
            incident={incident}
          />
        </div>
      ))}
    </div>
  );
};
