/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormEscalationElementBinding,
    FormEscalationElementBindingFromJSON,
    FormEscalationElementBindingFromJSONTyped,
    FormEscalationElementBindingToJSON,
} from './FormEscalationElementBinding';
import {
    IncidentFormEscalationElement,
    IncidentFormEscalationElementFromJSON,
    IncidentFormEscalationElementFromJSONTyped,
    IncidentFormEscalationElementToJSON,
} from './IncidentFormEscalationElement';

/**
 * 
 * @export
 * @interface IncidentFormEscalationElementBinding
 */
export interface IncidentFormEscalationElementBinding {
    /**
     * 
     * @type {FormEscalationElementBinding}
     * @memberof IncidentFormEscalationElementBinding
     */
    default_value?: FormEscalationElementBinding;
    /**
     * 
     * @type {IncidentFormEscalationElement}
     * @memberof IncidentFormEscalationElementBinding
     */
    element: IncidentFormEscalationElement;
    /**
     * Whether this field is required
     * @type {boolean}
     * @memberof IncidentFormEscalationElementBinding
     */
    required: boolean;
}

export function IncidentFormEscalationElementBindingFromJSON(json: any): IncidentFormEscalationElementBinding {
    return IncidentFormEscalationElementBindingFromJSONTyped(json, false);
}

export function IncidentFormEscalationElementBindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormEscalationElementBinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_value': !exists(json, 'default_value') ? undefined : FormEscalationElementBindingFromJSON(json['default_value']),
        'element': IncidentFormEscalationElementFromJSON(json['element']),
        'required': json['required'],
    };
}

export function IncidentFormEscalationElementBindingToJSON(value?: IncidentFormEscalationElementBinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_value': FormEscalationElementBindingToJSON(value.default_value),
        'element': IncidentFormEscalationElementToJSON(value.element),
        'required': value.required,
    };
}

