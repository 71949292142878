import { Badge, BadgeTheme } from "@incident-ui";
import _ from "lodash";
import {
  SchedulePayRule,
  ScheduleReportOverride,
  ScheduleReportSchedule,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { formatCurrency } from "../../../../utils/currency";
import { sortWeekdays } from "../../../../utils/frequencies";
import {
  displayDateRangeFromLocalObj,
  fromISOUTCDateStr,
} from "../pay-configurations/date-time-helpers";
import styles from "./PayRule.module.scss";

export const OverrideListItem = ({
  schedule,
  override,
  index,
}: {
  schedule: ScheduleReportSchedule;
  override: ScheduleReportOverride;
  index: number;
}): React.ReactElement => {
  return (
    <li key={override.id} className={"p-3 text-sm flex-center-y"}>
      <PayRuleBadge shortID={`Holiday ${index + 1}`} />
      <span className={"pl-2"}>{override.name}</span>
      <span className={"pl-2"}>
        {displayDateRangeFromLocalObj(
          fromISOUTCDateStr(override.start_at, false),
          fromISOUTCDateStr(override.end_at, true),
        )}
      </span>
      <span className={"ml-2 text-slate-600"}>
        {formatCurrency(schedule.currency, override.rate_cents)}
      </span>
    </li>
  );
};

export const PayRuleListItem = ({
  schedule,
  rule,
}: {
  schedule: ScheduleReportSchedule;
  rule: SchedulePayRule;
}): React.ReactElement => {
  return (
    <li key={rule.short_id} className={"p-3 text-sm flex-center-y"}>
      <PayRuleBadge shortID={rule.short_id} />
      <span className={"pl-2"}>{describeRule(rule)}</span>
      <span className={"ml-2 text-slate-600"}>
        {formatCurrency(schedule.currency, rule.rate_cents)}
      </span>
    </li>
  );
};

export const PayRuleBadge = ({
  shortID,
  className,
}: {
  shortID: string;
  className?: string;
}): React.ReactElement => {
  let theme = BadgeTheme.Primary;
  if (shortID.startsWith("Rule")) {
    theme = BadgeTheme.Unstyled;
    className = tcx("bg-alarmalade-600 text-white", className);
  }
  if (shortID.startsWith("Holiday")) {
    theme = BadgeTheme.Warning;
  }
  return (
    <Badge className={tcx(styles.payRuleBadge, className)} theme={theme}>
      {shortID}
    </Badge>
  );
};

export const describeRule = (rule: SchedulePayRule): string => {
  if (rule.short_id.startsWith("Base")) {
    return "Applies to any hours outside other pay rules";
  }

  const sortedWeekdays = sortWeekdays(_.uniq(rule.weekdays));

  let dayRangeText = "";
  if (_.isEqual(sortedWeekdays, ["saturday", "sunday"])) {
    dayRangeText = "Weekends (Sat-Sun)";
  } else if (
    _.isEqual(sortedWeekdays, [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
    ])
  ) {
    dayRangeText = "Weekdays (Mon-Fri)";
  } else if (sortedWeekdays.length === 7) {
    dayRangeText = "Every day";
  } else {
    dayRangeText = sortedWeekdays
      .map((wd) => _.capitalize(wd.slice(0, 3)))
      .join("/");
  }

  let hourlyRangeText = "";
  if (rule.start_time === "00:00" && rule.end_time === "00:00") {
    hourlyRangeText = "for entire 24hrs";
  } else {
    hourlyRangeText = `from ${rule.start_time} to ${rule.end_time}`;
  }

  return `${dayRangeText}, ${hourlyRangeText}`;
};
