import {
  AlertRouteIncidentTemplatePayload,
  AlertRouteIncidentTemplatePayloadPrioritySeverityEnum as SeverityPriorityRule,
  CatalogType,
  ConditionGroup,
  CustomField,
  CustomFieldFieldModeEnum,
  CustomFieldFieldTypeEnum,
  EngineParamBinding,
  EngineScope,
  Expression,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import { RainbowContainer } from "@incident-shared/aisuggestions/RainbowContainer";
import {
  EngineFormElement,
  EngineReferenceBadge,
  ReferenceSelectorPopover,
} from "@incident-shared/engine";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import {
  CheckboxRowV2,
  CheckboxV2,
} from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { ToggleRowV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Loader,
  SlackButtonPreview,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { AnimateChangeInHeight } from "@incident-ui/AnimateChangeInHeight";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { MessagePreview } from "@incident-ui/MessagePreviews/MessagePreview";
import { PopoverDropdownMenu } from "@incident-ui/PopoverDropdownMenu/PopoverDropdownMenu";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { AnimatePresence, motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { cloneDeep } from "lodash";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import {
  Path,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { Prompt } from "src/components/@shared/utils/Prompt";
import { useOrganisationAIAccess } from "src/hooks/useAI";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { filterScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { purgeEmpty } from "src/utils/utils";
import { useDebounce } from "use-hooks";

import { useProductAccess } from "../../../hooks/useProductAccess";
import {
  AssistantAvatar,
  AssistantAvatarBackground,
} from "../../insights/assistant/AssistantAvatar";
import { customFieldTypeToIcon } from "../../settings/custom-fields/common/utils";
import { HorizontalLine } from "../../settings/lifecycle/overview/LifecycleUIElements";
import { useAlertResources } from "../common/useAlertResources";
import { AlertRouteConditionsEditor } from "./AlertRouteConditionsEditor";
import {
  AlertRouteFormSection,
  AlertRouteSplitDrawerSection,
  AlertRouteYesNoButtonGroup,
} from "./AlertRouteFormSection";
import { AlertRouteGroupBySelector } from "./AlertRouteGroupBySelector";
import { AlertRouteIncidentPreview } from "./AlertRouteIncidentPreview";
import { GroupedAlertsTable } from "./GroupedAlertsTable";
import {
  AlertRouteFormData,
  alertSourcesToPayload,
  CreateIncidentsFormData,
  findApplicableCustomFields,
  parseIncidentTemplateData,
} from "./types";

export const AlertRouteCreateIncidentSection = ({
  scope,
  resources,
}: {
  scope: EngineScope;
  resources: Resource[];
}) => {
  const { featureAlertsPulseChannel } = useFlags();
  const { hasOnCall } = useProductAccess();
  const formMethods = useFormContext<AlertRouteFormData>();

  const onSubmit = (data: CreateIncidentsFormData): void => {
    formMethods.setValue<"enableIncidents">(
      "enableIncidents",
      data.enableIncidents,
      {
        shouldDirty: true,
      },
    );

    formMethods.setValue<"expressions">("expressions", data.expressions, {
      shouldDirty: true,
    });

    if (data.enableIncidents) {
      formMethods.setValue<"incidentConditionGroups">(
        "incidentConditionGroups",
        data.incidentConditionGroups,
        {
          shouldDirty: true,
        },
      );

      formMethods.setValue<"enableGrouping">(
        "enableGrouping",
        data.enableGrouping,
        {
          shouldDirty: true,
        },
      );
      if (data.enableGrouping) {
        formMethods.setValue<"groupingKeys">("groupingKeys", data.groupingKeys);
        formMethods.setValue<"groupingWindow">(
          "groupingWindow",
          data.groupingWindow,
        );
        formMethods.setValue<"deferTime">("deferTime", data.deferTime);
      }

      formMethods.setValue<"template">("template", data.template);
      formMethods.setValue<"template.name_autogenerated">(
        "template.name_autogenerated",
        data.template?.name_autogenerated,
      );
      formMethods.setValue<"template.summary_autogenerated">(
        "template.summary_autogenerated",
        data.template?.summary_autogenerated,
      );
      formMethods.setValue<"autoDeclineEnabled">(
        "autoDeclineEnabled",
        data.autoDeclineEnabled,
      );
    }

    setEditingContext({ isEditing: false });
    formMethods.setValue<"hasEverConfiguredIncidents">(
      "hasEverConfiguredIncidents",
      true,
      {
        shouldDirty: true,
      },
    );
  };

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: AlertRouteFormData;
  }>({
    isEditing: false,
  });

  const [
    isPrivate,
    hasEverConfiguredEscalations,
    hasEverConfiguredIncidents,
    enableIncidents,
    enableGrouping,
    groupingKeys,
    groupingWindow,
    incidentConditionGroups,
  ]: [
    boolean,
    boolean,
    boolean,
    boolean | undefined,
    boolean | undefined,
    { key: string; label: string }[],
    string,
    ConditionGroup[],
  ] = formMethods.watch([
    "is_private",
    "hasEverConfiguredEscalations",
    "hasEverConfiguredIncidents",
    "enableIncidents",
    "enableGrouping",
    "groupingKeys",
    "groupingWindow",
    "incidentConditionGroups",
  ]);
  const hasGroupingEnabled = !isPrivate && enableGrouping;

  const groupingWindowLabel: string | undefined =
    groupingWindow &&
    DEBOUNCE_WINDOW_OPTIONS.find(
      (opt) => opt.value === groupingWindow.toString(),
    )?.label;

  return (
    <>
      {editingContext.isEditing && (
        <CreateIncidentsDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          scope={scope}
          resources={resources}
        />
      )}
      <AlertRouteFormSection
        title={getIncidentSectionTitle({
          enableIncidents,
          hasGroupingEnabled,
          isPrivate,
          incidentConditionGroups,
          hasEverConfiguredIncidents,
        })}
        color={ColorPaletteEnum.Red}
        isCurrentFirstTimeStep={
          !hasEverConfiguredIncidents && hasEverConfiguredEscalations
        }
        firstTimeContent={
          <>
            Choose whether to create incidents from this route. You can also set
            up alert grouping to decrease noise when multiple alerts are
            happening.
          </>
        }
        disabled={hasEverConfiguredIncidents && !enableIncidents}
        stacked={featureAlertsPulseChannel}
        icon={IconEnum.Incident}
        accessory={
          !hasEverConfiguredIncidents ? (
            <AlertRouteYesNoButtonGroup
              disabled={hasOnCall && !hasEverConfiguredEscalations}
              onChange={(shouldCreateIncidents) => {
                formMethods.setValue<"enableIncidents">(
                  "enableIncidents",
                  shouldCreateIncidents,
                );

                if (shouldCreateIncidents) {
                  setEditingContext({
                    isEditing: true,
                    initialData: formMethods.getValues(),
                  });
                } else {
                  formMethods.setValue<"hasEverConfiguredIncidents">(
                    "hasEverConfiguredIncidents",
                    true,
                  );
                }
              }}
            />
          ) : (
            <GatedButton
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              theme={ButtonTheme.Naked}
              onClick={() =>
                setEditingContext({
                  isEditing: true,
                  initialData: formMethods.getValues(),
                })
              }
              icon={IconEnum.Edit}
              className="text-content-tertiary"
              title="Edit incident configuration"
              analyticsTrackingId="alert-routes-edit-create-incidents"
            />
          )
        }
      >
        {hasEverConfiguredIncidents && enableIncidents && (
          <div className={"flex flex-col gap-3"}>
            {incidentConditionGroups?.length > 0 && (
              <ConditionGroupsList
                groups={incidentConditionGroups}
                className="w-full p-0 shadow-none"
              />
            )}
            {incidentConditionGroups?.length > 0 &&
              hasGroupingEnabled &&
              !isPrivate && (
                <div className="text-sm-bold">And group alerts by</div>
              )}
            {hasGroupingEnabled && !isPrivate && (
              <div className="rounded-2 border flex flex-wrap border-stroke p-3 gap-2 w-full">
                {groupingKeys.length > 0 ? (
                  groupingKeys.map(({ key, label }) => (
                    <EngineReferenceBadge
                      key={key}
                      label={label}
                      reference={key}
                      scope={scope}
                    />
                  ))
                ) : (
                  <EngineReferenceBadge
                    label={`Time window (${groupingWindowLabel})`}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </AlertRouteFormSection>
    </>
  );
};

export type AlertRouteEngineFormProps = {
  resources: Resource[];
  scope: EngineScope;
  array: boolean;
  required: boolean;
  showPlaceholder: boolean;
  disabled?: boolean;
  mode: "variables_and_expressions" | "variables_only";
};

const getIncidentSectionTitle = ({
  enableIncidents,
  hasGroupingEnabled,
  isPrivate,
  incidentConditionGroups,
  hasEverConfiguredIncidents,
}: {
  enableIncidents?: boolean;
  hasGroupingEnabled?: boolean;
  isPrivate: boolean;
  incidentConditionGroups: ConditionGroup[];
  hasEverConfiguredIncidents: boolean;
}) => {
  const incidentsString = isPrivate ? "private incidents" : "incidents";
  if (!hasEverConfiguredIncidents) {
    return `Create ${incidentsString}`;
  }

  if (!enableIncidents) {
    return `Don't create ${incidentsString}`;
  }

  if (incidentConditionGroups?.length > 0) {
    return `Create ${incidentsString} from alerts matching`;
  }

  // If you have grouping enabled, you don't have private incidents on.
  if (hasGroupingEnabled) {
    return "Create incidents, and group alerts by";
  }

  return `Create ${incidentsString} from all alerts`;
};

const CreateIncidentsDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  resources,
}: {
  onSubmit: (data: CreateIncidentsFormData) => void;
  onClose: () => void;
  initialData?: AlertRouteFormData;
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useForm<CreateIncidentsFormData>({
    defaultValues: initialData,
  });
  const isPrivateAlertRoute = initialData?.is_private;

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const canUseAI = useOrganisationAIAccess();

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const [
    enableIncidents,
    enableGrouping,
    groupingKeys,
    incidentConditionGroups,
    expressions,
    template,
  ]: [
    boolean | undefined,
    boolean | undefined,
    { key: string; label: string }[],
    ConditionGroup[],
    ExpressionFormData[],
    CreateIncidentsFormData["template"],
  ] = formMethods.watch([
    "enableIncidents",
    "enableGrouping",
    "groupingKeys",
    "incidentConditionGroups",
    "expressions",
    "template",
  ]);

  const [useGeneratedIncidentName, useGeneratedIncidentSummary] =
    formMethods.watch([
      "template.name_autogenerated",
      "template.summary_autogenerated",
    ]);

  const selectedSeverityLiteral = template?.severity?.value?.literal;
  const { data: severityData } = useAPI(
    selectedSeverityLiteral ? "severitiesShow" : null,
    {
      id: selectedSeverityLiteral || "",
    },
  );

  const {
    data: { custom_fields: customFields },
    isLoading: customFieldsLoading,
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  const {
    data: { catalog_types: catalogTypes },
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    schemaResponse,
    configsResp,
    resourcesListResp,
  } = useAlertResources();

  const selectedIncTypeLiteral = template?.incident_type?.value?.literal;
  const { data: incTypeData } = useAPI(
    selectedIncTypeLiteral ? "incidentTypesShow" : null,
    {
      id: selectedIncTypeLiteral || "",
    },
  );

  const {
    data: previewAlerts,
    isLoading: previewAlertsLoading,
    mutate: previewAlertsMutate,
  } = useAPI("alertRoutesPreviewFindAndGroupMatchingAlerts", {
    previewFindAndGroupMatchingAlertsRequestBody: {
      grouping_keys: enableGrouping
        ? groupingKeys.map((x) => {
            return { id: x.key };
          })
        : [],
      expressions: expressions?.map(expressionToPayload) || [],
      condition_groups: conditionGroupsToGroupPayloads([
        ...(initialData?.conditionGroups || []),
        ...incidentConditionGroups,
      ]),
      alert_sources: alertSourcesToPayload(initialData?.alertSources || {}),
    },
  });

  const selectedIncTypeName = incTypeData?.incident_type.name;
  const selectedSeverityName = severityData?.severity.name;

  const allAlerts = [
    ...(previewAlerts?.ungrouped_alerts ?? []),
    ...(previewAlerts?.grouped_alerts?.flatMap(
      (groupedAlerts) => groupedAlerts.alerts,
    ) || []),
  ];

  const {
    data: incidentPreview,
    isLoading: incidentPreviewLoading,
    mutate: refreshIncidentPreview,
  } = useAPI(
    allAlerts.length >= 1 && template ? "alertRoutesPreviewIncident" : null,
    {
      previewIncidentRequestBody: {
        alert_id: allAlerts.length >= 1 ? allAlerts[0].id : "",
        template: parseIncidentTemplateData(
          purgeEmpty(template as AlertRouteIncidentTemplatePayload),
        ),
        expressions: expressions
          ? expressions.map((e) =>
              expressionToPayload(e as unknown as Expression),
            )
          : undefined,
      },
    },
  );

  const generatedIncidentDetails = useAPI(
    canUseAI && allAlerts.length >= 1
      ? "alertsGenerateIncidentDetailsFromAlert"
      : null,
    {
      alertId: allAlerts.length >= 1 ? allAlerts[0].id : "",
    },
  );

  // We JSON.stringify this so that we can trigger the use effect whenever the
  // template changes, as otherwise swr does not refire.
  const templateChecksum = useDebounce(JSON.stringify(template), 500);

  useEffect(() => {
    previewAlertsMutate();
    refreshIncidentPreview();
  }, [templateChecksum, previewAlertsMutate, refreshIncidentPreview]);

  const returnedGroupedAlerts =
    previewAlerts &&
    (previewAlerts.grouped_alerts.length > 0 ||
      previewAlerts.ungrouped_alerts.length > 0);

  const engineFormProps: AlertRouteEngineFormProps = {
    resources: resources,
    scope: scopeWithExpressions,
    array: false,
    required: false,
    showPlaceholder: true,
    mode: "variables_and_expressions" as const,
  };

  if (customFieldsError || catalogTypesError || resourcesError) {
    return (
      <GenericErrorMessage error={customFieldsError ?? catalogTypesError} />
    );
  }

  if (
    customFieldsLoading ||
    catalogTypesLoading ||
    resourcesLoading ||
    !schemaResponse ||
    !resourcesListResp ||
    !configsResp
  ) {
    return (
      <Drawer width="full" onClose={onClose}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  const selectedSourceConfigs = configsResp.alert_source_configs.filter(
    (config) => initialData?.alertSources[config.id]?.selected,
  );

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <Drawer width="full" onClose={onClose}>
        <div className="flex flex-col h-full">
          <DrawerContents className=" grow overflow-hidden">
            <DrawerTitle
              title="Create incidents"
              onClose={onClose}
              icon={IconEnum.Incident}
              compact
              theme={DrawerTitleTheme.Bordered}
            />

            <div className="flex flex-col grow overflow-y-auto lg:flex-row">
              <DrawerBody className="p-0 overflow-y-hidden">
                <Form.Root
                  id="alert-route-create-incidents"
                  fullHeight
                  formMethods={formMethods}
                  onSubmit={onSubmit}
                  outerClassName="overflow-auto"
                >
                  <Prompt
                    when={formMethods.formState.isDirty}
                    message={
                      "Your changes have not been saved. Are you sure you want to navigate away?"
                    }
                  />
                  <div className="flex flex-col h-full">
                    <AlertRouteSplitDrawerSection
                      className="grow"
                      left={
                        <>
                          <div className="text-sm text-content-secondary">
                            Choose whether to create incidents from alerts in
                            this route. You can also filter on which alerts in
                            this route should create incidents and group to
                            decrease noise when multiple alerts are happening.
                          </div>
                          <BooleanRadioButtonGroupV2
                            trueOption={{
                              label: "Yes",
                            }}
                            falseOption={{
                              label: "No",
                            }}
                            name="enableIncidents"
                            label="Create incidents from this route?"
                            formMethods={formMethods}
                            srLabel="Enable escalations"
                            boxed
                          />

                          {!enableIncidents && (
                            <Callout
                              showIcon={false}
                              theme={CalloutTheme.Warning}
                            >
                              You are not creating incidents from the alerts in
                              this route. This means your alerts will not be
                              grouped, which can increase response and
                              resolution time for alerts due to alert noise.
                            </Callout>
                          )}
                        </>
                      }
                    />
                    {enableIncidents && (
                      <>
                        <AlertRouteSplitDrawerSection
                          left={
                            <>
                              <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                  <div className="text-base-bold">
                                    Filtering
                                  </div>
                                  <div className="text-sm text-content-secondary">
                                    {incidentConditionGroups.length > 0 ? (
                                      <>
                                        Create incidents when alerts match the
                                        following criteria:
                                      </>
                                    ) : (
                                      <>
                                        Add further filtering to only create
                                        incidents from certain alerts
                                      </>
                                    )}
                                  </div>
                                </div>

                                <AlertRouteConditionsEditor
                                  name="incidentConditionGroups"
                                  conditionGroups={
                                    incidentConditionGroups || []
                                  }
                                  scope={scopeWithExpressions}
                                  introText={`Create incidents from all alerts`}
                                  formMethods={formMethods}
                                  expressions={expressionMethods.fields}
                                  allowFilteringByExpression
                                />
                              </div>

                              <div className="flex flex-col gap-4 pt-8">
                                <div className="flex flex-col gap-2">
                                  <div className="text-base-bold">Grouping</div>
                                  {
                                    <div className="text-sm text-content-secondary">
                                      When an alert fires, we find incidents
                                      with similar alerts and ask the incident
                                      channel if it’s related.
                                    </div>
                                  }
                                </div>

                                {isPrivateAlertRoute ? (
                                  <Callout
                                    theme={CalloutTheme.Info}
                                    iconOverride={IconEnum.LockClosed}
                                  >
                                    You&rsquo;ve selected to create private
                                    incidents, so this alert route will not do
                                    any alert grouping.
                                  </Callout>
                                ) : (
                                  <div>
                                    <ToggleRowV2
                                      name="enableGrouping"
                                      formMethods={formMethods}
                                      label="Group alerts"
                                    />
                                    <AnimatePresence>
                                      {enableGrouping && (
                                        <GroupingForm
                                          scope={scopeWithExpressions}
                                          resources={resources}
                                        />
                                      )}
                                    </AnimatePresence>
                                  </div>
                                )}
                              </div>
                            </>
                          }
                          right={
                            <div
                              className={tcx(
                                returnedGroupedAlerts
                                  ? "mb-24 space-y-2"
                                  : "h-full",
                              )}
                            >
                              <div className="text-sm-med text-content-tertiary">
                                Preview
                              </div>
                              {previewAlertsLoading ? (
                                <Loader />
                              ) : (
                                <GroupedAlertsTable
                                  className={tcx("!w-full overflow-x-auto")}
                                  schema={schemaResponse.alert_schema}
                                  resources={resourcesListResp.resources}
                                  alertSourceConfigs={
                                    selectedSourceConfigs || []
                                  }
                                  groupingKeys={groupingKeys.map((x) => x.key)}
                                  groupedAlerts={
                                    previewAlerts?.grouped_alerts ?? []
                                  }
                                  ungroupedAlerts={
                                    previewAlerts?.ungrouped_alerts ?? []
                                  }
                                />
                              )}
                            </div>
                          }
                        />
                        <HorizontalLine />
                        <AlertRouteSplitDrawerSection
                          left={
                            <>
                              <div>
                                <div className="flex flex-col gap-2 mb-4">
                                  <div className="text-base-bold">
                                    Incident details
                                  </div>
                                  <div className="text-sm text-content-secondary">
                                    Customise the details these incidents will
                                    be created with.
                                  </div>
                                </div>

                                {/* Incident Template */}
                                <IncidentTemplateForm
                                  engineFormProps={engineFormProps}
                                  resources={resources}
                                  customFields={customFields}
                                  catalogTypes={catalogTypes}
                                  scope={scopeWithExpressions}
                                  isPrivateAlertRoute={
                                    isPrivateAlertRoute || false
                                  }
                                />
                              </div>
                              {/* Auto-decline */}
                              <CheckboxRowV2
                                className="w-full"
                                formMethods={formMethods}
                                name="autoDeclineEnabled"
                                label="Decline triage incidents if the linked alerts are resolved"
                                helptext="Triage incidents which have yet to be accepted will be automatically declined if the alert(s) are resolved."
                                helptextAlign={"end"}
                              />
                            </>
                          }
                          right={
                            previewAlertsLoading || incidentPreviewLoading ? (
                              <Loader />
                            ) : (
                              <AlertRouteIncidentPreview
                                alert={allAlerts?.[0]}
                                incidentPreview={incidentPreview}
                                customFields={customFields}
                                customFieldValues={template?.custom_fields}
                                alertRouteName={initialData?.name || ""}
                                incidentTypeLabel={selectedIncTypeName}
                                severityLabel={selectedSeverityName}
                                aiConfig={{
                                  generatedContent: {
                                    incidentName:
                                      generatedIncidentDetails?.data
                                        ?.generated_name || "",
                                    incidentSummary:
                                      generatedIncidentDetails?.data
                                        ?.generated_summary || "",
                                  },
                                  useGeneratedIncidentName:
                                    useGeneratedIncidentName,
                                  useGeneratedIncidentSummary:
                                    useGeneratedIncidentSummary,
                                }}
                              />
                            )
                          }
                        />
                      </>
                    )}
                  </div>
                </Form.Root>
              </DrawerBody>
            </div>

            <DrawerFooter className="flex gap-2 justify-end">
              <Button onClick={() => onClose()} analyticsTrackingId={null}>
                Back
              </Button>
              <GatedButton
                form="alert-route-create-incidents"
                requiredScope={ScopeNameEnum.AlertRouteUpdate}
                type="submit"
                theme={ButtonTheme.Primary}
                analyticsTrackingId="alert-routes-edit-sources-save"
              >
                Apply
              </GatedButton>
            </DrawerFooter>
          </DrawerContents>
        </div>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};

const GroupingForm = ({
  scope,
  resources,
}: {
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useFormContext<CreateIncidentsFormData>();

  const groupingKeyFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "groupingKeys",
  });

  return (
    <motion.div
      className="bg-slate-50 flex flex-col gap-5 p-5 rounded-b-lg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col gap-1">
        <div className="text-sm-med">Grouping rule</div>
        <div>
          If multiple alerts are received within
          <PopoverSingleSelectV2
            formMethods={formMethods}
            required={true}
            name="groupingWindow"
            className="inline-flex mx-1"
            options={DEBOUNCE_WINDOW_OPTIONS}
            placeholder="Select grouping window..."
            triggerStyle="inline-button"
          />
          <span>
            group by
            {groupingKeyFieldMethods.fields.length > 0 && ":"}
          </span>
          {groupingKeyFieldMethods.fields.length === 0 ? (
            <ReferenceSelectorPopover
              scope={filterScope(scope, (x) => {
                return (
                  !x.type.includes("TemplatedText") &&
                  !x.key.startsWith("expressions") &&
                  !groupingKeyFieldMethods.fields.find(
                    (field) => field.key === x.key,
                  )
                );
              })}
              allowExpressions={false}
              renderTriggerButton={({ onClick }) => (
                <Button
                  theme={ButtonTheme.Unstyled}
                  onClick={onClick}
                  analyticsTrackingId="alert-route-add-property"
                  className={tcx(
                    "shadow-button mx-1 !p-0.5 !pl-2 !-mt-1 !font-normal rounded-[6px] border !border-stroke !bg-surface-secondary !text-content-primary",
                  )}
                >
                  <div className="flex items-center gap-1">
                    <div>time-window only</div>
                    <Icon id={IconEnum.Expand} className="text-slate-400" />
                  </div>
                </Button>
              )}
              onSelectReference={(ref) => {
                groupingKeyFieldMethods.append({
                  key: ref.key,
                  label: ref.label,
                });
              }}
            />
          ) : null}
        </div>
        {groupingKeyFieldMethods.fields.length > 0 ? (
          <AlertRouteGroupBySelector
            fieldMethods={groupingKeyFieldMethods}
            scope={scope}
            resources={resources}
          />
        ) : null}
      </div>
      <InputV2
        formMethods={formMethods}
        label="Grace period"
        helptext="For subsequent grouped alerts, how long should we wait for a response within the incident before escalating?"
        type={InputType.Number}
        name="deferTime"
        placeholder="2"
        insetSuffixNode={
          <span className="text-content-secondary">minutes</span>
        }
        inputClassName="bg-white"
      />
      <div className="flex flex-col gap-1">
        <div className="text-sm-med">Preview</div>
        <div>
          When an alert is grouped in an active incident, we will send a message
          to the channel.
        </div>
        <AnotherAlertSlackMessagePreview />
      </div>
    </motion.div>
  );
};

const INCIDENT_TYPE_TYPE = "IncidentType";
const INCIDENT_SEVERITY_TYPE = "IncidentSeverity";
const INCIDENT_MODE_TYPE = "IncidentMode";
const INCIDENT_NAME_TYPE = `TemplatedText["plain_single_line"]`;
const INCIDENT_SUMMARY_TYPE = `TemplatedText["plain_multi_line"]`;
export const INCIDENT_SLACK_TEAM_TYPE = "SlackTeam";

const AIFeatureCheckbox = ({
  formMethods,
  name,
  onValueChange,
}: {
  formMethods: UseFormReturn<CreateIncidentsFormData>;
  name: Path<CreateIncidentsFormData>;
  onValueChange?: (value: boolean) => void;
}) => {
  const canUseAI = useOrganisationAIAccess();
  if (!canUseAI) {
    return null;
  }
  return (
    <div className="flex flex-row items-center gap-1">
      <CheckboxV2
        formMethods={formMethods}
        name={name}
        label="Set with AI"
        onValueChange={onValueChange}
      />
      <Icon
        id={IconEnum.SparklesColoured}
        size={IconSize.Small}
        className="mb-1"
      />
    </div>
  );
};

const IncidentTemplateForm = ({
  resources,
  engineFormProps,
  customFields,
  catalogTypes,
  scope,
  isPrivateAlertRoute,
}: {
  resources: Resource[];
  engineFormProps: AlertRouteEngineFormProps;
  customFields: CustomField[];
  catalogTypes: CatalogType[];
  scope: EngineScope;
  isPrivateAlertRoute: boolean;
}) => {
  const formMethods = useFormContext<CreateIncidentsFormData>();

  // Fully derived custom fields should be automatically set by other values, and cannot be
  // overridden in any way - including in alerts.
  const customFieldsNoFullyDerivedFields = customFields.filter(
    (x) => x.field_mode !== CustomFieldFieldModeEnum.FullyDerived,
  );

  const initialCustomFields = formMethods.getValues("template.custom_fields");
  const initialCustomFieldIDs = initialCustomFields
    ? Object.keys(initialCustomFields)
    : [];
  const [selectedCustomFieldIDs, setSelectedCustomFieldIDs] = useState(
    new Set<string>(initialCustomFieldIDs),
  );

  const addCustomField = (id: string) => {
    // Since these are dynamic form elements, we manually set
    // the default value as we create the element.
    const selectedField = customFieldsNoFullyDerivedFields.find(
      (x) => x.id === id,
    );
    const selectedFieldPriority =
      selectedField?.field_type === CustomFieldFieldTypeEnum.MultiSelect
        ? "append"
        : "first-wins";
    formMethods.setValue<`template.custom_field_priorities.${typeof id}`>(
      `template.custom_field_priorities.${id}`,
      selectedFieldPriority,
    );
    setSelectedCustomFieldIDs((prev) => new Set(prev.add(id)));
  };

  const [aiGeneratedNameEnabled, aiGeneratedSummaryEnabled] = formMethods.watch(
    ["template.name_autogenerated", "template.summary_autogenerated"],
  );

  const removeCustomField = (id: string) => {
    // When removing a custom field, we want to reset all the form
    // fields associated with it to avoid junk data in the form state.
    const template = cloneDeep(formMethods.getValues("template"));
    if (template?.custom_fields) {
      delete template.custom_fields[id];
    }
    if (template?.custom_field_priorities) {
      delete template.custom_field_priorities[id];
    }
    formMethods.setValue<"template">("template", template, {
      shouldDirty: true,
    });
    setSelectedCustomFieldIDs((prev) => {
      const next = new Set(prev);
      next.delete(id);
      return next;
    });
  };

  // Existing customers can have fully derived custom fields set already, so we need to fetch them from that list.
  const selectedCustomFields = customFields.filter((x) =>
    selectedCustomFieldIDs.has(x.id),
  );
  const availableCustomFields = customFieldsNoFullyDerivedFields.filter(
    (x) => !selectedCustomFieldIDs.has(x.id),
  );

  const [selectedIncType] = formMethods.watch(["template.incident_type"]);

  const selectedIncTypeLiteral = selectedIncType?.value?.literal;
  const { data: incTypeData } = useAPI(
    selectedIncTypeLiteral ? "incidentTypesShow" : null,
    {
      id: selectedIncTypeLiteral || "",
    },
  );

  const selectedPrivateIncType =
    incTypeData?.incident_type.private_incidents_only;
  const selectedExpressionIncType = !!selectedIncType?.value?.reference;

  const workspace = resources.find((x) => x.type === INCIDENT_SLACK_TEAM_TYPE);

  return (
    <div className="space-y-6">
      <CustomFieldSuggestions
        customFields={customFieldsNoFullyDerivedFields}
        scope={scope}
        catalogTypes={catalogTypes}
        addCustomField={addCustomField}
      />

      {/* Workspaces */}
      {(workspace?.options ?? []).length > 1 ? (
        <EngineFormElement<CreateIncidentsFormData>
          {...engineFormProps}
          name="template.workspace"
          resourceType={INCIDENT_SLACK_TEAM_TYPE}
          label="Incident Workspace"
          description="When the incident is created, which workspace should it be created in?"
          required
        />
      ) : null}
      <EngineFormElement<CreateIncidentsFormData>
        name={`template.name`}
        resourceType={INCIDENT_NAME_TYPE}
        label="Name"
        description={
          "We default to the alert title, but you can customise it with attributes and text here."
        }
        disabled={aiGeneratedNameEnabled}
        key={
          aiGeneratedNameEnabled
            ? "ai-engine-form-element-template-name"
            : "engine-form-element-template-name"
        }
        placeholderOverride={
          aiGeneratedNameEnabled
            ? "The name will be automatically set"
            : undefined
        }
        headerNode={
          <AIFeatureCheckbox
            formMethods={formMethods}
            name="template.name_autogenerated"
            onValueChange={(value) => {
              if (value) {
                formMethods.setValue("template.name", undefined);
              } else {
                formMethods.setValue("template.name", {
                  value: {
                    literal: JSON.stringify({
                      content: [
                        {
                          content: [
                            {
                              attrs: {
                                label: "Alert -> Title",
                                missing: false,
                                name: "alert.title",
                              },
                              type: "varSpec",
                            },
                          ],
                          type: "paragraph",
                        },
                      ],
                      type: "doc",
                    }),
                  },
                });
              }
            }}
          />
        }
        {...engineFormProps}
      />
      <EngineFormElement<CreateIncidentsFormData>
        key={
          aiGeneratedSummaryEnabled
            ? "ai-engine-form-element-template-summary"
            : "engine-form-element-template-summary"
        }
        name={`template.summary`}
        resourceType={INCIDENT_SUMMARY_TYPE}
        label="Summary"
        description={"Do you want to set a default summary?"}
        disabled={aiGeneratedSummaryEnabled}
        placeholderOverride={
          aiGeneratedSummaryEnabled
            ? "The summary will be automatically set"
            : undefined
        }
        headerNode={
          <AIFeatureCheckbox
            formMethods={formMethods}
            name="template.summary_autogenerated"
          />
        }
        {...engineFormProps}
      />
      <EngineFormElement<CreateIncidentsFormData>
        name={`template.incident_mode`}
        resourceType={INCIDENT_MODE_TYPE}
        label="Incident mode"
        description={
          "We will default to real incidents, but you can also create test incidents if needed."
        }
        {...engineFormProps}
      />

      {resources.find((x) => x.type === INCIDENT_TYPE_TYPE) ? (
        <EngineFormElement<CreateIncidentsFormData>
          name={`template.incident_type`}
          resourceType={INCIDENT_TYPE_TYPE}
          label="Type"
          description={
            "You can choose to set an incident type based on alert attributes."
          }
          {...engineFormProps}
        />
      ) : null}
      <PrivateIncidentCallout
        privateAlertRoute={isPrivateAlertRoute}
        selectedPrivateIncType={selectedPrivateIncType || false}
        selectedExpressionIncType={selectedExpressionIncType}
      />
      <fieldset>
        <EngineFormElement<CreateIncidentsFormData>
          name={`template.severity`}
          resourceType={INCIDENT_SEVERITY_TYPE}
          label="Severity"
          description={
            "You can choose to set an incident severity based on alert attributes."
          }
          {...engineFormProps}
        />
        <RadioButtonGroupV2
          name={"template.priority_severity"}
          className="mt-2"
          horizontal
          options={[
            {
              label: "Maximum",
              value: SeverityPriorityRule.Max,
              tooltip:
                "Incident severity will be the maximum severity derived from all attached alerts.",
              tooltipProps: {
                align: "start",
              },
            },
            {
              label: "First wins",
              value: SeverityPriorityRule.FirstWins,
              tooltip:
                "Only the first relevant alert will set the incident severity.",
              tooltipProps: {
                align: "start",
              },
            },
          ]}
          srLabel={"priority_single_value"}
          formMethods={formMethods}
        />
      </fieldset>

      {/* Custom fields configuration */}
      {selectedCustomFields?.map((field) => {
        const fieldPriorityOptions: RadioButtonGroupOption[] = [
          {
            label: "First wins",
            value: "first-wins",
            tooltip: `Only the first relevant alert will set the value of ${field.name}.`,
          },
          {
            label: "Last wins",
            value: "last-wins",
            tooltip: `The most recent relevant alert will set the value of ${field.name}.`,
          },
        ];
        if (field.field_type === CustomFieldFieldTypeEnum.MultiSelect) {
          fieldPriorityOptions.unshift({
            label: "Accumulate",
            value: "append",
            tooltip: `${field.name} will accumulate new values each time a new alert is attached.`,
            tooltipProps: {
              align: "start",
            },
          });
        }
        return (
          // We rely on the ID here to scroll this into view from a custom field suggestion
          <fieldset key={field.id} id={`custom-field-${field.id}`}>
            <EngineFormElement<CreateIncidentsFormData>
              className="w-full"
              name={`template.custom_fields.${field.id}`}
              resourceType={resourceTypeForCustomField(field, catalogTypes)}
              label={field.name}
              {...engineFormProps}
              array={field.field_type === CustomFieldFieldTypeEnum.MultiSelect}
              labelAccessory={
                <Button
                  theme={ButtonTheme.Unstyled}
                  className="text-red-500 hover:text-red-800 ml-auto text-sm"
                  onClick={() => {
                    removeCustomField(field.id);
                  }}
                  analyticsTrackingId={null}
                >
                  Remove
                </Button>
              }
            />
            <RadioButtonGroupV2
              name={`template.custom_field_priorities.${field.id}`}
              className="mt-2"
              horizontal
              options={fieldPriorityOptions}
              srLabel={"priority_single_value"}
              formMethods={formMethods}
            />
          </fieldset>
        );
      })}

      {/* Add custom fields */}
      <PopoverDropdownMenu
        triggerButton={
          <Button analyticsTrackingId={null} icon={IconEnum.Add}>
            Add custom field
          </Button>
        }
        elementName="custom fields"
        options={availableCustomFields.map((x) => {
          return {
            label: x.name,
            value: x.id,
            icon: iconForCustomField(x),
          };
        })}
        onSelect={(field) => addCustomField(field.value)}
      />
    </div>
  );
};

const iconForCustomField = (customField: CustomField): IconEnum => {
  switch (customField.field_type) {
    case CustomFieldFieldTypeEnum.Link:
    case CustomFieldFieldTypeEnum.Text:
      return IconEnum.Text;
    case CustomFieldFieldTypeEnum.Numeric:
      return IconEnum.Numeric;
    case CustomFieldFieldTypeEnum.SingleSelect:
      return IconEnum.SingleSelect;
    case CustomFieldFieldTypeEnum.MultiSelect:
      return IconEnum.MultiSelect;
    default:
      throw new Error("Unknown custom field type");
  }
};

export const resourceTypeForCustomField = (
  customField: CustomField,
  catalogTypes: CatalogType[],
): string => {
  switch (customField.field_type) {
    case CustomFieldFieldTypeEnum.Link:
      return "String";
    case CustomFieldFieldTypeEnum.Numeric:
      return "Number";
    case CustomFieldFieldTypeEnum.Text:
      return "String";
    case CustomFieldFieldTypeEnum.MultiSelect:
    case CustomFieldFieldTypeEnum.SingleSelect:
      if (customField.catalog_type_id) {
        const catalogType = catalogTypes.find(
          (ct) => ct.id === customField.catalog_type_id,
        );
        if (!catalogType) {
          throw new Error("Catalog type not found");
        }
        if (catalogType.registry_type) {
          return `CatalogEntry["${catalogType.registry_type}"]`;
        }
        return `CatalogEntry["${customField.catalog_type_id}"]`;
      }
      return `IncidentCustomFieldOption["${customField.id}"]`;
    default:
      assertUnreachable(customField.field_type);
  }
  return "";
};

const AnotherAlertSlackMessagePreview = () => {
  const commsPlatform = usePrimaryCommsPlatform();

  return (
    <MessagePreview
      commsPlatform={commsPlatform || CommsPlatform.Slack}
      className="min-h-[116px] overflow-hidden"
      showTitle={false}
    >
      <div className={"space-y-2"}>
        <div className="flex items-center space-x-1 !my-0.5">
          <span>⌛</span>
          <span className="font-['lato'] font-bold">
            We&apos;ve received another alert...
          </span>
        </div>
        <div className={"flex gap-4"}>
          <div className="bg-[#DDDDDD] rounded-2 max-h-[70px] min-w-[5px]" />
          <div className={"flex-row"}>
            <div className={"text-[#39629B] hover:underline"}>
              Database Error: Connection timeouts on DB01
            </div>
            <div className={"flex items-center space-x-1"}>
              <span className={"text-[#39629B] font-semibold hover:underline"}>
                Dashboard
              </span>
              <span>| Team: </span>
              <span className="bg-[#EFEFEF] text-[#e03165] border-stroke border-[0.01rem] rounded p-0.5">
                Core
              </span>
            </div>
          </div>
        </div>
        <div className={"flex gap-2 flex-wrap"}>
          <SlackButtonPreview
            emoji="paperclip"
            text={"Part of this incident"}
          />
          <SlackButtonPreview emoji="-1" text={"Unrelated to incident"} />
          <SlackButtonPreview
            emoji="rotating_light"
            text={"Manage pending alerts"}
          />
        </div>
      </div>
    </MessagePreview>
  );
};

const DEBOUNCE_WINDOW_OPTIONS = [
  {
    label: "10 minutes",
    sort_key: "2",
    value: "600",
  },
  {
    label: "15 minutes",
    sort_key: "3",
    value: "900",
  },
  {
    label: "20 minutes",
    sort_key: "4",
    value: "1200",
  },
  {
    label: "30 minutes",
    sort_key: "5",
    value: "1800",
  },
  {
    label: "1 hour",
    sort_key: "6",
    value: "3600",
  },
  {
    label: "3 hours",
    sort_key: "7",
    value: "10800",
  },
  {
    label: "24 hours",
    sort_key: "8",
    value: "86400",
  },
];

const CustomFieldSuggestions = ({
  scope,
  customFields,
  catalogTypes,
  addCustomField,
}: {
  scope: EngineScope;
  customFields: CustomField[];
  catalogTypes: CatalogType[];
  addCustomField: (id: string) => void;
}) => {
  const formMethods = useFormContext<CreateIncidentsFormData>();
  const customFieldValues = formMethods.watch("template.custom_fields");

  const suggestedCustomFields = findApplicableCustomFields({
    customFields,
    scope,
    catalogTypes,
  });

  const suggestionsToShow = Object.keys(suggestedCustomFields)
    .map(
      (
        c,
      ): EngineParamBinding & {
        custom_field_id: string;
        custom_field: CustomField | undefined;
      } => ({
        custom_field_id: c,
        custom_field: customFields.find((cf) => cf.id === c),
        ...suggestedCustomFields[c],
      }),
    )
    .filter((c) => {
      const hasBeenSet =
        customFieldValues &&
        customFieldValues[c.custom_field_id] &&
        (customFieldValues[c.custom_field_id].value ||
          customFieldValues[c.custom_field_id].array_value?.length);

      const wasAutomaticallySetInDefaultState =
        customFieldValues &&
        customFieldValues[c.custom_field_id] &&
        customFieldValues[c.custom_field_id].was_automatically_set;

      return !hasBeenSet || wasAutomaticallySetInDefaultState;
    });

  if (suggestionsToShow.length === 0) {
    return null;
  }

  const hasAutomaticallySetFields = suggestionsToShow.some(
    (s) =>
      customFieldValues &&
      customFieldValues[s.custom_field_id] &&
      customFieldValues[s.custom_field_id].was_automatically_set,
  );

  return (
    <AnimateChangeInHeight className={"flex-1"}>
      <RainbowContainer className="border border-purple-100 rounded-lg bg-clip-border p-4">
        <div className="flex items-center mb-4">
          <AssistantAvatar
            size={IconSize.Medium}
            className={"!p-0 !rounded w-7 h-7 flex items-center justify-center"}
            iconClassName="fill-white"
            background={AssistantAvatarBackground.Purple}
          />
          <h3 className="font-semibold ml-2">
            {hasAutomaticallySetFields
              ? `We've automatically set ${
                  suggestionsToShow.length
                } ${pluralize("custom field", suggestionsToShow.length)}`
              : `${suggestionsToShow.length} suggested ${pluralize(
                  "custom field",
                  suggestionsToShow.length,
                )}`}
          </h3>
        </div>
        <div>
          <StackedList>
            {suggestionsToShow.map((s) => {
              const poweredByCatalog = s.custom_field?.catalog_type_id ?? false;
              const selectedCatalogType = poweredByCatalog
                ? catalogTypes.find(
                    (x) => x.id === s.custom_field?.catalog_type_id,
                  )
                : undefined;

              const icon =
                selectedCatalogType?.icon ||
                customFieldTypeToIcon(
                  s.custom_field?.field_type ??
                    CustomFieldFieldTypeEnum.SingleSelect,
                );
              const color =
                selectedCatalogType?.color || ColorPaletteEnum.SlateOnWhite;

              const isMultiSelect =
                s.custom_field?.field_type ===
                CustomFieldFieldTypeEnum.MultiSelect;

              return (
                <StackedListItem
                  key={s.custom_field_id}
                  className={"flex flex-row gap-1 items-center"}
                  title={<div>{s.custom_field?.name} </div>}
                  description={
                    isMultiSelect ? s.array_value?.[0].label : s.value?.label
                  }
                  contentContainerClassName={"w-full"}
                  accessory={
                    hasAutomaticallySetFields ? (
                      <Button
                        analyticsTrackingId={"confirm-suggested-field"}
                        size={BadgeSize.Medium}
                        title={"View"}
                        theme={ButtonTheme.Secondary}
                        onClick={() => {
                          const customFieldId = s.custom_field_id;
                          const customFieldElement = document.getElementById(
                            `custom-field-${customFieldId}`,
                          );
                          customFieldElement?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }}
                      >
                        View
                      </Button>
                    ) : (
                      <Button
                        analyticsTrackingId={"confirm-suggested-field"}
                        size={BadgeSize.Medium}
                        title={"Add"}
                        theme={ButtonTheme.Secondary}
                        onClick={() => {
                          addCustomField(s.custom_field_id);
                          formMethods.setValue<`template.custom_fields.${string}`>(
                            `template.custom_fields.${s.custom_field_id}`,
                            s,
                          );
                        }}
                      >
                        Add
                      </Button>
                    )
                  }
                  icon={icon}
                  iconColor={color as unknown as ColorPaletteEnum}
                />
              );
            })}
          </StackedList>
        </div>
      </RainbowContainer>
    </AnimateChangeInHeight>
  );
};

const PrivateIncidentCallout = ({
  selectedPrivateIncType,
  selectedExpressionIncType,
  privateAlertRoute,
}: {
  selectedPrivateIncType: boolean;
  selectedExpressionIncType: boolean;
  privateAlertRoute: boolean;
}) => {
  if (!privateAlertRoute) {
    if (selectedPrivateIncType) {
      return (
        <Callout theme={CalloutTheme.Info} iconOverride={IconEnum.LockClosed}>
          <div>
            If you want to create private incidents from alerts, you need to
            apply this to your alert route.{" "}
            <Link
              openInNewTab
              analyticsTrackingId="alert-routes-private-incidents-learn-more"
              href="https://help.incident.io/en/articles/10072915-creating-private-incidents-from-alerts"
            >
              Learn more here.
            </Link>
          </div>
        </Callout>
      );
    } else if (selectedExpressionIncType) {
      return (
        <Callout
          theme={CalloutTheme.Warning}
          iconOverride={IconEnum.LockClosed}
        >
          <div>
            <div className="text-sm-bold">
              This incident type relies on an expression.
            </div>
            <div>
              If your expression returns a private incident type, no incident
              will be triggered by this alert route.{" "}
              <Link
                openInNewTab
                analyticsTrackingId="alert-routes-private-incidents-learn-more"
                href="https://help.incident.io/en/articles/10072915-creating-private-incidents-from-alerts"
              >
                Learn more about making your alert route private.
              </Link>
            </div>
          </div>
        </Callout>
      );
    }
  }
  if (
    privateAlertRoute &&
    (!selectedPrivateIncType || selectedExpressionIncType)
  ) {
    return (
      <Callout theme={CalloutTheme.Warning} iconOverride={IconEnum.LockClosed}>
        <div>
          You&rsquo;ve selected to create private incidents, so this alert route
          will always create private incidents no matter the incident type you
          pick.
        </div>
      </Callout>
    );
  }
  return null;
};
