import { Trigger } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { WorkflowOnceFor } from "../common/types";

const EXPECTED_ONCE_FOR_REF_KEYS = [
  "incident",
  "message",
  "action",
  "followup",
  "slack_message",
];

export const WorkflowsFrequencySettingsExplanation = ({
  onceFor,
  trigger,
  isEditing,
  setIsEditing,
}: {
  onceFor: WorkflowOnceFor[];
  trigger: Trigger;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  const onceForLabels = onceFor.map((item) => {
    return (
      <span key={`oncefor-${item.key}`} className="font-medium">
        {item.label}
      </span>
    );
  });

  const hasAtLeastOneOnceFor = onceFor.length > 0;
  const hasMultipleOnceFor = onceFor.length > 1;

  let connectingText = "";
  if (hasMultipleOnceFor) {
    connectingText = "unique combination of";
  }

  // If the user has selected at least one "Once For" but it does not either contain the "Incident"
  // "Action", "Followup" or "Message" type, then we should display a warning. For example:
  //
  // "Every time an incident is created/changed, once for severity" → result in workflow running
  // first for a given incident, but then will not run again for subsequent incidents if the
  // severity is the same.
  const includesExpectedVal = onceFor.some((onceFor: WorkflowOnceFor) =>
    EXPECTED_ONCE_FOR_REF_KEYS.includes(onceFor.key),
  );
  const hasOnceForWithoutExpectedVal =
    hasAtLeastOneOnceFor && !includesExpectedVal;

  let warningType: string;

  switch (trigger.name) {
    case triggers.SlackMessagePosted:
      warningType = warnings.Message;
      break;
    case triggers.ActionUpdated:
      warningType = warnings.Action;
      break;
    case triggers.FollowupUpdated:
      warningType = warnings.Followup;
      break;
    default:
      warningType = warnings.Incident;
      break;
  }

  return (
    <>
      {hasOnceForWithoutExpectedVal && (
        <Callout theme={CalloutTheme.Warning} className="my-3">
          This is an unusual configuration as it is missing{" "}
          <span className="font-medium">{warningType}</span> option. It will
          only run on subsequent trigger events if the{" "}
          {onceForLabels.length > 1 ? "values for" : "value of"}{" "}
          {joinSpansWithCommasAndConnectorWord(onceForLabels)}{" "}
          {onceForLabels.length > 1 ? "are" : "is"} the same.
        </Callout>
      )}
      <div className="py-1">
        {isEditing ? (
          <p>
            {"This workflow will run once for each "}
            {connectingText}...
          </p>
        ) : (
          <div className="flex flex-row gap-1 border border-stroke rounded-[6px] p-3 grow text-sm justify-between bg-surface-secondary">
            {onceFor.length > 0 ? (
              <div className="">
                {"This workflow will run once for each "}
                {connectingText}{" "}
                {joinSpansWithCommasAndConnectorWord(onceForLabels)}
                {"."}
              </div>
            ) : (
              <span>
                You have no configuration here. This workflow will run every
                time {trigger.when_description} if your conditions apply.
              </span>
            )}
            {!isEditing && (
              <Button
                analyticsTrackingId="workflows-v2-edit-frequency"
                theme={ButtonTheme.Naked}
                onClick={() => setIsEditing(true)}
                icon={IconEnum.Edit}
                iconProps={{
                  size: IconSize.Medium,
                }}
                title="Edit run frequency"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

enum triggers {
  SlackMessagePosted = "slack.message_posted",
  ActionUpdated = "action.updated",
  FollowupUpdated = "followup.updated",
}

enum warnings {
  Message = "Message",
  Action = "Action",
  Followup = "Follow-up",
  Incident = "Incident",
}
