/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Trend,
    TrendFromJSON,
    TrendFromJSONTyped,
    TrendToJSON,
} from './Trend';

/**
 * 
 * @export
 * @interface InsightsGetTrendsResponseBody
 */
export interface InsightsGetTrendsResponseBody {
    /**
     * 
     * @type {Array<Trend>}
     * @memberof InsightsGetTrendsResponseBody
     */
    trends: Array<Trend>;
}

export function InsightsGetTrendsResponseBodyFromJSON(json: any): InsightsGetTrendsResponseBody {
    return InsightsGetTrendsResponseBodyFromJSONTyped(json, false);
}

export function InsightsGetTrendsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsGetTrendsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trends': ((json['trends'] as Array<any>).map(TrendFromJSON)),
    };
}

export function InsightsGetTrendsResponseBodyToJSON(value?: InsightsGetTrendsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trends': ((value.trends as Array<any>).map(TrendToJSON)),
    };
}

