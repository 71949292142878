import cx from "classnames";
export const Resolved = () => (
  <svg
    width="15"
    height="24"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx("text-icon-operational", "ml-1 mr-2")}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1929 0.709376C14.4915 0.996471 14.5008 1.47125 14.2137 1.76983L5.07909 11.2698C4.93471 11.42 4.73438 11.5033 4.5261 11.4999C4.31782 11.4965 4.12035 11.4066 3.981 11.2517L0.692536 7.59788C0.415442 7.29 0.4404 6.81578 0.748283 6.53868C1.05617 6.26159 1.53038 6.28655 1.80748 6.59443L4.55662 9.64904L13.1325 0.730169C13.4196 0.431591 13.8943 0.422281 14.1929 0.709376Z"
      fill="currentColor"
    />
  </svg>
);
