/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DependentResourcePayload,
    DependentResourcePayloadFromJSON,
    DependentResourcePayloadFromJSONTyped,
    DependentResourcePayloadToJSON,
} from './DependentResourcePayload';

/**
 * 
 * @export
 * @interface EngineFindDependentResourcesForMultipleRequestBody
 */
export interface EngineFindDependentResourcesForMultipleRequestBody {
    /**
     * Resources whose dependents you want
     * @type {Array<DependentResourcePayload>}
     * @memberof EngineFindDependentResourcesForMultipleRequestBody
     */
    resources: Array<DependentResourcePayload>;
}

export function EngineFindDependentResourcesForMultipleRequestBodyFromJSON(json: any): EngineFindDependentResourcesForMultipleRequestBody {
    return EngineFindDependentResourcesForMultipleRequestBodyFromJSONTyped(json, false);
}

export function EngineFindDependentResourcesForMultipleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineFindDependentResourcesForMultipleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(DependentResourcePayloadFromJSON)),
    };
}

export function EngineFindDependentResourcesForMultipleRequestBodyToJSON(value?: EngineFindDependentResourcesForMultipleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(DependentResourcePayloadToJSON)),
    };
}

