/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FollowUpPriority,
    FollowUpPriorityFromJSON,
    FollowUpPriorityFromJSONTyped,
    FollowUpPriorityToJSON,
} from './FollowUpPriority';

/**
 * 
 * @export
 * @interface FollowUpsCreatePriorityOptionResponseBody
 */
export interface FollowUpsCreatePriorityOptionResponseBody {
    /**
     * 
     * @type {FollowUpPriority}
     * @memberof FollowUpsCreatePriorityOptionResponseBody
     */
    priority_option: FollowUpPriority;
}

export function FollowUpsCreatePriorityOptionResponseBodyFromJSON(json: any): FollowUpsCreatePriorityOptionResponseBody {
    return FollowUpsCreatePriorityOptionResponseBodyFromJSONTyped(json, false);
}

export function FollowUpsCreatePriorityOptionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsCreatePriorityOptionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priority_option': FollowUpPriorityFromJSON(json['priority_option']),
    };
}

export function FollowUpsCreatePriorityOptionResponseBodyToJSON(value?: FollowUpsCreatePriorityOptionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priority_option': FollowUpPriorityToJSON(value.priority_option),
    };
}

