/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StepProgress
 */
export interface StepProgress {
    /**
     * Status of the step
     * @type {Date}
     * @memberof StepProgress
     */
    completed_at?: Date;
    /**
     * The cause of an errored step
     * @type {string}
     * @memberof StepProgress
     */
    error?: string;
    /**
     * Status of the step
     * @type {string}
     * @memberof StepProgress
     */
    status: StepProgressStatusEnum;
    /**
     * Name of the step
     * @type {string}
     * @memberof StepProgress
     */
    step: string;
}

/**
* @export
* @enum {string}
*/
export enum StepProgressStatusEnum {
    Complete = 'complete',
    Pending = 'pending',
    Error = 'error'
}

export function StepProgressFromJSON(json: any): StepProgress {
    return StepProgressFromJSONTyped(json, false);
}

export function StepProgressFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepProgress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'status': json['status'],
        'step': json['step'],
    };
}

export function StepProgressToJSON(value?: StepProgress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'error': value.error,
        'status': value.status,
        'step': value.step,
    };
}

