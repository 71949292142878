import type { SVGProps } from "react";
import * as React from "react";
const SvgIntegrations = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.164 9.665a.75.75 0 0 0 1.086-.67V7.251a2.752 2.752 0 0 0-2.75-2.75h-1.775c.01-.083.025-.164.025-.25 0-1.103-.897-2-2-2s-2 .897-2 2c0 .086.015.167.025.25H7a2.752 2.752 0 0 0-2.75 2.75v1.775c-.083-.011-.164-.025-.25-.025-1.103 0-2 .897-2 2s.897 2 2 2c.086 0 .167-.015.25-.025v1.775A2.752 2.752 0 0 0 7 17.501h1.744c.26 0 .501-.135.638-.355a.753.753 0 0 0 .033-.73 1.485 1.485 0 0 1-.165-.665c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5c0 .221-.056.445-.166.666a.749.749 0 0 0 .672 1.084H14.5a2.752 2.752 0 0 0 2.75-2.75v-1.744a.75.75 0 0 0-1.086-.67c-1.027.515-2.164-.285-2.164-1.335s1.134-1.85 2.164-1.335v-.002Z"
    />
  </svg>
);
export default SvgIntegrations;
