import { ErrorResponse } from "@incident-io/api";
import { Button } from "@incident-ui/Button/Button";
import { GenericErrorMessage } from "@incident-ui/GenericErrorMessage/GenericErrorMessage";
import { Modal, ModalContent, ModalFooter } from "@incident-ui/Modal";
import { captureException } from "@sentry/react";
import { useEffect } from "react";

export function ErrorModal({
  onClose,
  description = "",
  title = "Something went wrong.",
  analyticsTrackingId = null,
  error = null,
}: {
  onClose: () => void;
  description?: string;
  title?: string;
  analyticsTrackingId?: string | null;
  error?: Error | ErrorResponse | null;
}): React.ReactElement {
  useEffect(() => {
    if (error != null) {
      const err =
        error instanceof Error ? error : new Error("API error response");
      if (err !== error) {
        err.cause = error;
      }
      captureException(err);
      console.error(err.message);
    }
  });
  return (
    <Modal
      onClose={onClose}
      title={title}
      isOpen
      disableQuickClose
      analyticsTrackingId={analyticsTrackingId}
    >
      <ModalContent>
        <GenericErrorMessage
          hideHeading
          description={description}
          error={error as ErrorResponse}
        />
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} analyticsTrackingId={null}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
