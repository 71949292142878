/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileCreateKeyRequestBody
 */
export interface MobileCreateKeyRequestBody {
    /**
     * The ID of the app that this mobile key was registered for
     * @type {string}
     * @memberof MobileCreateKeyRequestBody
     */
    application_id?: string;
    /**
     * The name of this device if it has one
     * @type {string}
     * @memberof MobileCreateKeyRequestBody
     */
    device_name?: string;
    /**
     * JWT provides by the mobile redirect
     * @type {string}
     * @memberof MobileCreateKeyRequestBody
     */
    jwt: string;
    /**
     * The model ID of this device if known (for Apple only)
     * @type {string}
     * @memberof MobileCreateKeyRequestBody
     */
    model_id?: string;
    /**
     * The model name of this device if known
     * @type {string}
     * @memberof MobileCreateKeyRequestBody
     */
    model_name?: string;
    /**
     * The platform of this mobile
     * @type {string}
     * @memberof MobileCreateKeyRequestBody
     */
    platform: MobileCreateKeyRequestBodyPlatformEnum;
}

/**
* @export
* @enum {string}
*/
export enum MobileCreateKeyRequestBodyPlatformEnum {
    Ios = 'ios',
    Android = 'android',
    Windows = 'windows',
    Macos = 'macos',
    Web = 'web'
}

export function MobileCreateKeyRequestBodyFromJSON(json: any): MobileCreateKeyRequestBody {
    return MobileCreateKeyRequestBodyFromJSONTyped(json, false);
}

export function MobileCreateKeyRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileCreateKeyRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'application_id': !exists(json, 'application_id') ? undefined : json['application_id'],
        'device_name': !exists(json, 'device_name') ? undefined : json['device_name'],
        'jwt': json['jwt'],
        'model_id': !exists(json, 'model_id') ? undefined : json['model_id'],
        'model_name': !exists(json, 'model_name') ? undefined : json['model_name'],
        'platform': json['platform'],
    };
}

export function MobileCreateKeyRequestBodyToJSON(value?: MobileCreateKeyRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'application_id': value.application_id,
        'device_name': value.device_name,
        'jwt': value.jwt,
        'model_id': value.model_id,
        'model_name': value.model_name,
        'platform': value.platform,
    };
}

