import { EngineFormElement, isEmptyBinding } from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import {
  ExpressionsMethodsProvider,
  useExpressionsMethods,
} from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import {
  JIRA_CLOUD_CONFIG,
  useGetJiraOptions,
} from "@incident-shared/integrations";
import { FormSelectSite } from "@incident-shared/issue-trackers/jira/JiraFormFields";
import { LabelWithReset } from "@incident-shared/issue-trackers/jira/LabelWithReset";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Loader,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import _, { cloneDeep, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Path, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { JiraSelect } from "src/components/settings/integrations/list/jira/JiraHelpers";
import { JiraIssueFieldInput } from "src/components/settings/integrations/list/jira/JiraIssueFieldInput";
import {
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineScope,
  ErrorSingle,
  Expression,
  IssueTrackersJiraCreateIssueTemplateRequestBodyContextEnum,
  IssueTrackersJiraTypeaheadOptionsFieldEnum as JiraFieldEnum,
  IssueTrackersJiraUpdateIssueTemplateRequestBody,
  JiraIssueField as JiraIssueField,
  JiraIssueTemplate,
  Resource,
  ScopeNameEnum as ScopeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useFollowUpScope } from "src/hooks/useFollowUpScope";
import { useAllResources } from "src/hooks/useResources";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { IssueTemplateContextEnum } from "../issue-trackers/useAllTemplates";

type FormData = Omit<
  IssueTrackersJiraUpdateIssueTemplateRequestBody,
  "expressions"
> & { expressions: Expression[] };

const MaybeCheckYourIntegrationError = ({ error }: { error }) => (
  <GenericErrorMessage
    description="If this error persists, you may want to check the status of your jira integration in integration settings."
    error={error}
  />
);

export const CreateEditJiraIssueTemplateModal = ({
  id,
  context,
  onClose,
}: {
  id?: string;
  context: IssueTemplateContextEnum;
  onClose: () => void;
}): React.ReactElement => {
  const isEditing = id !== undefined;

  // Currently this API will return the default template if the ID is not found.
  // When creating a new template, we don't want to use the default though
  const {
    data: issueTemplate,
    isLoading: jiraIssueTemplateLoading,
    error: loadIssueTemplateError,
  } = useAPI(id ? "issueTrackersJiraGetIssueTemplate" : null, { id: id ?? "" });

  const { scope, scopeLoading, scopeError } = useFollowUpScope();

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesError || scopeError || loadIssueTemplateError) {
    return (
      <MaybeCheckYourIntegrationError
        error={resourcesError ?? scopeError ?? loadIssueTemplateError}
      />
    );
  }

  if (jiraIssueTemplateLoading || scopeLoading || !scope || resourcesLoading) {
    return <LoadingModal onClose={onClose} isExtraLarge />;
  }

  return (
    <CreateEditJiraIssueTemplateL2
      context={context}
      isEditing={isEditing}
      // Override the loaded issue template if we're creating a new one.
      issueTemplate={isEditing ? issueTemplate?.issue_template : undefined}
      followUpScope={scope}
      resources={resources}
      onClose={onClose}
    />
  );
};

const CreateEditJiraIssueTemplateL2 = ({
  context,
  isEditing,
  issueTemplate,
  followUpScope,
  resources,
  onClose,
}: {
  context: IssueTemplateContextEnum;
  isEditing: boolean;
  issueTemplate?: JiraIssueTemplate;
  followUpScope: EngineScope;
  resources: Resource[];
  onClose: () => void;
}): React.ReactElement => {
  // Sanity check we've not loaded a template that doesn't match our context
  if (
    isEditing &&
    issueTemplate !== undefined &&
    (issueTemplate.context as string) !== (context as string)
  ) {
    throw new Error(
      `Issue template context ${issueTemplate?.context} does not match expected context ${context}`,
    );
  }

  const formMethods = useForm<FormData>({
    defaultValues: issueTemplate
      ? (issueTemplate as FormData)
      : {
          enabled: true,
          description:
            context === IssueTemplateContextEnum.IncidentTicket
              ? defaultDescriptionTemplate
              : undefined,
          expressions: [],
        },
    mode: "onSubmit",
  });

  const { setError } = formMethods;

  const expressionsMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const refetchTemplates = useAPIRefetch("issueTrackerIssueTemplatesList", {});

  const {
    trigger: onEdit,
    isMutating: savingEdit,
    genericError: editError,
  } = useAPIMutation(
    "issueTrackersJiraGetIssueTemplate",
    // @ts-expect-error initial data will always be defined when editing
    { id: issueTemplate?.id },
    async (apiClient, data: FormData) => {
      if (issueTemplate === undefined) {
        throw new Error("IssueTemplate should never be undefined when editing");
      }

      return await apiClient.issueTrackersJiraUpdateIssueTemplate({
        id: issueTemplate.id,
        jiraUpdateIssueTemplateRequestBody: {
          ...data,
          dynamic_fields: stripEmptyParams(data.dynamic_fields),
          expressions: data.expressions?.map(expressionToPayload),
        },
      });
    },
    {
      onSuccess: () => {
        refetchTemplates();
        onClose();
      },
      setError,
    },
  );

  const {
    trigger: onCreate,
    isMutating: savingCreate,
    genericError: createError,
  } = useAPIMutation(
    "issueTrackerIssueTemplatesList",
    {},
    async (apiClient, data: FormData) => {
      await apiClient.issueTrackersJiraCreateIssueTemplate({
        jiraCreateIssueTemplateRequestBody: {
          ...data,
          expressions: data.expressions?.map(expressionToPayload),
          context:
            context as unknown as IssueTrackersJiraCreateIssueTemplateRequestBodyContextEnum,
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
      },
      setError,
    },
  );

  const modalName =
    context === IssueTemplateContextEnum.IncidentTicket
      ? "Jira incident ticket template"
      : "Jira follow-up export template";

  if (context === IssueTemplateContextEnum.IncidentTicket) {
    formMethods.register("project_id", {
      required: "Must choose a project",
    });
    formMethods.register("issue_type_id", {
      required: "Must choose an issue type",
    });
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      title={isEditing ? `Edit ${modalName}` : `Add a ${modalName}`}
      analyticsTrackingId="edit-issue-tracker-template-jira"
      isExtraLarge
      onClose={onClose}
      onSubmit={isEditing ? onEdit : onCreate}
      genericError={isEditing ? editError : createError}
      // Our engine form elements look much nicer on a white background
      contentClassName="!bg-white"
      footer={
        <ModalFooter
          confirmButtonText={isEditing ? "Save" : "Create"}
          confirmButtonType="submit"
          saving={savingEdit || savingCreate}
          onClose={onClose}
        />
      }
    >
      <ErrorBoundary fallback={MaybeCheckYourIntegrationError}>
        <ExpressionsMethodsProvider
          expressionsMethods={expressionsMethods}
          allowAllOfACatalogType={false}
        >
          {context === IssueTemplateContextEnum.IncidentTicket && (
            <Form.Helptext>
              {`This template defines where the incident ticket will be created and the fields it will be populated with.`}
            </Form.Helptext>
          )}
          <CreateEditJiraIssueTemplateL3
            context={context}
            followUpScope={followUpScope}
            resources={resources}
          />
        </ExpressionsMethodsProvider>
      </ErrorBoundary>
    </Form.Modal>
  );
};

enum Step {
  // Step0 is when you select the site. Single-site organisations don't see this
  // step.
  Step0 = "step_0",
  // Step1 is when you select the project
  Step1 = "step_1",
  // Step2 is when you fill in the issue type
  Step2 = "step_2",
  // Step3 is when you fill in everything else
  Step3 = "step_3",
}

const CreateEditJiraIssueTemplateL3 = ({
  context,
  followUpScope,
  resources,
}: {
  context: IssueTemplateContextEnum;
  followUpScope: EngineScope;
  resources: Resource[];
}): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);

  const formMethods = useFormContext<FormData>();

  const {
    watch,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = formMethods;

  const [siteId, projectIdEngineParam, issueTypeId] = watch([
    "site_id",
    "project_id",
    "issue_type_id",
  ]);
  const projectId = projectIdEngineParam?.value?.literal;

  const { data: unsortedIssueFields, isLoading: issueFieldsLoading } = useAPI(
    isEmpty(siteId) || isEmpty(projectId) || isEmpty(issueTypeId)
      ? null
      : "issueTrackersJiraGetCreateIssueFields",
    {
      siteId: siteId || "",
      projectId: projectId || "",
      issueTypeId: issueTypeId || "",
    },
    {
      onError: async (jsonErr) => {
        // If we get a validation error, apply that to the form
        jsonErr.errors.forEach((e: ErrorSingle) => {
          if (e?.source?.pointer) {
            const pointer = e.source.pointer as unknown as Path<FormData>;
            if (!_.get(errors, pointer)) {
              if (pointer === "project_id") {
                setError("project_id.value", {
                  type: "manual",
                  message: e.message,
                });
              } else {
                setError(pointer, {
                  type: "manual",
                  message: e.message,
                });
              }
            }
          } else {
            // This is a very gross hack to avoid having to set the generic
            // error on the parent form - something has gone wrong here and we
            // want to surface the error to the end user, while disabling the
            // ability to let them save the form. We'll always have a project_id
            // input, so just throw the error on there. (We should probably
            // revisit this soon)
            setError("project_id.value", e);
            console.error(e);
            throw e;
          }
        });
      },
    },
  );

  const issueFields =
    unsortedIssueFields?.issue_fields &&
    _.sortBy(unsortedIssueFields.issue_fields, "type");

  const getJiraOptions = useGetJiraOptions(JIRA_CLOUD_CONFIG);
  const [shouldRenameTemplate, setShouldRenameTemplate] = useState(false);
  // Whenever we change site ID /  project ID / issue type, we need to refresh our fields.
  useEffect(() => {
    // isEmpty is what we want, but TypeScript doesn't understand that it
    // excludes undefined, sigh
    if (_.isEmpty(siteId) || _.isEmpty(projectId) || _.isEmpty(issueTypeId)) {
      return;
    }

    if (!shouldRenameTemplate) {
      return;
    }

    Promise.all([
      getJiraOptions({
        field: JiraFieldEnum.Project,
        siteId: siteId ?? "",
      }).then((projects) =>
        projects.find((project) => project.value === projectId),
      ),
      getJiraOptions({
        field: JiraFieldEnum.Issuetype,
        siteId: siteId ?? "",
        projectId,
      }).then((issueTypes) =>
        issueTypes.find((issueType) => issueType.value === issueTypeId),
      ),
    ]).then(([project, issueType]) => {
      if (project && issueType) {
        setValue<"name">("name", `${project.label}`);
        setShouldRenameTemplate(false);
      }
    });
  }, [
    getJiraOptions,
    siteId,
    projectId,
    issueTypeId,
    setValue,
    shouldRenameTemplate,
  ]);

  const step =
    siteId && projectId && issueTypeId
      ? Step.Step3
      : siteId && projectId
      ? Step.Step2
      : siteId
      ? Step.Step1
      : Step.Step0;

  useEffect(() => {
    if (siteId && projectId) {
      // clear errors when the project changes, otherwise we might show
      // irrelevant ones
      clearErrors();
    }
  }, [siteId, projectId, clearErrors]);

  const clearDynamicFields = () => {
    setValue<"dynamic_fields">(`dynamic_fields`, {});
  };

  const onChangeSite = () => {
    // @ts-expect-error this clears the field, but TS doesn't like it
    setValue<"issue_type_id">("issue_type_id", null);
    // @ts-expect-error this clears the field, but TS doesn't like it
    setValue<"project_id">("project_id", null);
    // @ts-expect-error this clears the field, but TS doesn't like it
    setValue<"site_id">("site_id", null);
    setShouldRenameTemplate(true);
    clearErrors();
  };
  const onChangeProject = () => {
    // @ts-expect-error this clears the field, but TS doesn't like it
    setValue<"issue_type_id">("issue_type_id", null);
    // @ts-expect-error this clears the field, but TS doesn't like it
    setValue<"project_id">("project_id", null);
    setShouldRenameTemplate(true);
    clearErrors();
  };

  const onChangeIssueType = () => {
    // @ts-expect-error this clears the field, but TS doesn't like it
    setValue<"issue_type_id">("issue_type_id", null);
    clearDynamicFields();
    setShouldRenameTemplate(true);
    clearErrors();
  };

  const { expressionsMethods: methods } = useExpressionsMethods<
    FormData,
    "expressions"
  >();

  const scope = addExpressionsToScope(followUpScope, methods?.fields || []);

  const sharedFormElementProps = {
    resources,
    scope: scope,
    required: false, // override all fields to be optional, as this is a template
    showPlaceholder: true,
    disabled: step === Step.Step3,
    allowAllOfACatalogTypeInQueryExpression: false,
  };

  return (
    <div className="space-y-4">
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Template name"
        placeholder="Enter a name for your template"
        required="Please provide a name for your template"
      />
      <FormSelectSite onChangeSite={onChangeSite} canChange={canEditSettings} />
      {siteId && (
        <>
          <EngineFormElement
            key={siteId}
            className="grow"
            {...sharedFormElementProps}
            name={`project_id`}
            resourceType={
              siteId ? `JiraCloudProject["${siteId}"]` : "JiraCloudProject"
            }
            label="Jira Project"
            labelNode={
              <LabelWithReset
                label="Jira Project"
                onReset={onChangeProject}
                canChange={canEditSettings}
                hasValue={!!projectId}
              />
            }
            array={false}
            required={true}
            disabled={!siteId || projectIdEngineParam?.value !== undefined}
            // Don't show the expression button (or allow variables). However, templates with existing
            // expressions will still show. This is to ensure backwards-compatibility
            // whilst not allowing people to create new templates with expressions,
            // as we intend to replace this with auto-export followups.
            mode="plain_input"
            // This is a little lie, but lets us _view_ expressions without allowing someone to create a new one
            scope={sharedFormElementProps.scope as unknown as undefined}
          />
        </>
      )}
      <FormSelectIssueType
        siteId={siteId ?? ""}
        projectId={projectId || ""}
        step={step}
        onChangeIssueType={onChangeIssueType}
      />
      <FormEverythingElse
        context={context}
        siteId={siteId ?? ""}
        projectId={projectId || ""}
        step={step}
        issueTypeID={issueTypeId || ""}
        issueFields={issueFields || undefined}
        loading={issueFieldsLoading}
        scope={followUpScope}
        resources={resources}
      />
    </div>
  );
};

const FormSelectIssueType = ({
  siteId,
  projectId,
  step,
  onChangeIssueType,
}: {
  siteId: string;
  projectId: string;
  step: Step;
  onChangeIssueType: () => void;
}): React.ReactElement | null => {
  const getJiraOptions = useGetJiraOptions(JIRA_CLOUD_CONFIG);

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);

  if (step === Step.Step0 || step === Step.Step1 || !siteId) {
    return null;
  }

  return (
    <div className="mb-2">
      <JiraSelect
        label={
          <LabelWithReset
            label="Jira Issue Type"
            onReset={onChangeIssueType}
            canChange={canEditSettings}
            hasValue={step === Step.Step3}
          />
        }
        required
        disabled={!canEditSettings || step === Step.Step3}
        fieldKey={"issue_type_id"}
        getJiraOptions={() =>
          getJiraOptions({
            field: JiraFieldEnum.Issuetype,
            siteId,
            projectId,
          })
        }
        dependencies={[siteId, projectId]}
      />
    </div>
  );
};

const FormEverythingElse = ({
  context,
  issueFields,
  siteId,
  projectId,
  issueTypeID,
  step,
  loading,
  scope,
  resources,
}: {
  context: IssueTemplateContextEnum;
  step: Step;
  siteId: string;
  projectId: string;
  issueTypeID: string;
  issueFields?: JiraIssueField[];
  loading: boolean;
  scope: EngineScope;
  resources: Resource[];
}): React.ReactElement | null => {
  const formMethods = useFormContext<FormData>();

  const getJiraOptions = useGetJiraOptions(JIRA_CLOUD_CONFIG);

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeEnum.OrganisationSettingsUpdate);

  // before they've moved to our part of the form, we shouldn't show these extra fields
  if (step !== Step.Step3) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  if (!issueFields) {
    return null;
  }

  return (
    <>
      {context === IssueTemplateContextEnum.IncidentTicket && (
        <>
          <TemplatedTextInputV2
            formMethods={formMethods}
            name="description"
            format="jira"
            label="Description"
            scope={scope}
            required
            includeVariables={true}
            includeExpressions={false}
          />
          <Callout theme={CalloutTheme.Info}>
            Fields set here will <strong>not be editable in Jira</strong>. If
            someone tries to change them we’ll set the value back to match the
            incident, and send the editor a Slack message explaining what’s
            happened.
          </Callout>
        </>
      )}
      {issueFields.map((field) => (
        <JiraIssueFieldInput
          key={`dynamic_fields.${field.key}`}
          field={field}
          fieldKey={`dynamic_fields.${field.key}`}
          siteId={siteId}
          projectId={projectId}
          scope={scope}
          resources={resources}
          issueTypeId={issueTypeID}
          loadOptions={({ field, projectId, issueTypeId, query }) => {
            return getJiraOptions({
              field: field as unknown as JiraFieldEnum,
              siteId,
              projectId,
              issueTypeId,
              query,
            });
          }}
          disabled={!canEditSettings}
        />
      ))}
    </>
  );
};

const defaultDescriptionTemplate = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 2 },
      content: [
        {
          type: "varSpec",
          attrs: {
            label: "Incident Name",
            missing: false,
            name: "incident.name",
          },
        },
        {
          type: "text",
          text: " (",
        },
        {
          type: "varSpec",
          attrs: {
            label: "Incident Severity",
            missing: false,
            name: "incident.severity",
          },
        },
        {
          type: "text",
          text: ")",
        },
      ],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "varSpec",
          attrs: {
            label: "Incident Summary",
            missing: false,
            name: "incident.summary",
          },
        },
      ],
    },
  ],
};

type DynamicFields = { [key: string]: EngineParamBindingPayload };
const stripEmptyParams = (
  params?: DynamicFields,
): DynamicFields | undefined => {
  if (!params) {
    return params;
  }

  const newParams = cloneDeep(params) as DynamicFields;

  Object.entries(params).forEach(([key, value]) => {
    if (isEmptyBinding(value as EngineParamBinding)) {
      delete newParams[key];
    }
  });

  return newParams;
};
