import {
  EngineScope,
  PostmortemTemplate,
  PostmortemTemplateSection,
  PostmortemTemplateSectionTypeEnum,
} from "@incident-io/api";

import { PostmortemTemplateCustomSectionDrawer } from "./PostmortemTemplateCustomSectionDrawer";
import { PostmortemTemplateFollowUpSectionDrawer } from "./PostmortemTemplateFollowUpSectionDrawer";

type PostmortemTemplateSectionEditProps = {
  onClose: () => void;
  template: PostmortemTemplate;
  section?: PostmortemTemplateSection;
  scope: EngineScope;
};

export const PostmortemTemplateSectionDrawer = ({
  onClose,
  template,
  section,
  scope,
}: PostmortemTemplateSectionEditProps) => {
  if (section?.type === PostmortemTemplateSectionTypeEnum.FollowUps) {
    return (
      <PostmortemTemplateFollowUpSectionDrawer
        onClose={onClose}
        template={template}
      />
    );
  }

  return (
    <PostmortemTemplateCustomSectionDrawer
      onClose={onClose}
      template={template}
      section={section}
      scope={scope}
    />
  );
};
