import { StatusPageIncident } from "@incident-io/api";
import { TemplatedTextDisplay } from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import {
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Modal,
  ModalContent,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useAPIMutation } from "src/utils/swr";

export const PublicWriteUpDeleteModal = ({
  incident,
  onSuccess,
  onClose,
}: {
  incident: StatusPageIncident;
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const showToast = useToast();
  const { trigger, isMutating } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient) =>
      await apiClient.statusPageSetWriteUp({
        id: incident.id,
        setWriteUpRequestBody: {},
      }),
    {
      onSuccess: () => {
        showToast({
          title: "Write-up successfully removed",
          description:
            "This write-up will no longer appear on your status page incident",
          theme: ToastTheme.Success,
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: "Could not remove write-up",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  return (
    <Modal
      analyticsTrackingId="remove-write-up-modal"
      as="div"
      onClose={onClose}
      isOpen
      title="Remove a write-up"
    >
      <ModalContent className="text-sm space-y-2">
        <p>Are you sure you want to remove the following write-up?</p>
        {incident.write_up_url ? (
          <a
            href={incident.write_up_url}
            target="_href"
            className="hover:text-slate-700 transition"
          >
            <span className="inline align-middle break-words">
              <span className="font-semibold">{incident.write_up_url}</span>
              <Icon
                size={IconSize.Small}
                className="ml-0.5 inline"
                id={IconEnum.ExternalLink}
              />
            </span>
          </a>
        ) : incident.write_up_contents ? (
          <TemplatedTextDisplay value={incident.write_up_contents} />
        ) : null}
        <p>It will no longer be visible on your public status page.</p>
        <p>
          This may be confusing for any subscribers who have already received an
          update about the public write-up for this incident.
        </p>
      </ModalContent>
      <ModalFooter
        confirmButtonType="button"
        confirmButtonTheme={ButtonTheme.Destroy}
        confirmButtonText={"Remove write-up"}
        onConfirm={() => trigger({})}
        onClose={onClose}
        saving={isMutating}
      />
    </Modal>
  );
};
