/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleConfigPayload,
    ScheduleConfigPayloadFromJSON,
    ScheduleConfigPayloadFromJSONTyped,
    ScheduleConfigPayloadToJSON,
} from './ScheduleConfigPayload';
import {
    ScheduleOverridePayload,
    ScheduleOverridePayloadFromJSON,
    ScheduleOverridePayloadFromJSONTyped,
    ScheduleOverridePayloadToJSON,
} from './ScheduleOverridePayload';

/**
 * 
 * @export
 * @interface SchedulesPreviewEntriesRequestBody
 */
export interface SchedulesPreviewEntriesRequestBody {
    /**
     * 
     * @type {ScheduleConfigPayload}
     * @memberof SchedulesPreviewEntriesRequestBody
     */
    config: ScheduleConfigPayload;
    /**
     * Timestamp to begin listing entries from, defaults to now
     * @type {string}
     * @memberof SchedulesPreviewEntriesRequestBody
     */
    from?: string;
    /**
     * Any overrides we want to factor into the preview
     * @type {Array<ScheduleOverridePayload>}
     * @memberof SchedulesPreviewEntriesRequestBody
     */
    overrides: Array<ScheduleOverridePayload>;
    /**
     * The schedule that we're previewing
     * @type {string}
     * @memberof SchedulesPreviewEntriesRequestBody
     */
    schedule_id?: string;
    /**
     * Timezone of the schedule, as interpreted at the point of generating the report
     * @type {string}
     * @memberof SchedulesPreviewEntriesRequestBody
     */
    timezone?: string;
    /**
     * Timestamp to list entries up until, defaulting to 4 weeks after from
     * @type {string}
     * @memberof SchedulesPreviewEntriesRequestBody
     */
    until?: string;
}

export function SchedulesPreviewEntriesRequestBodyFromJSON(json: any): SchedulesPreviewEntriesRequestBody {
    return SchedulesPreviewEntriesRequestBodyFromJSONTyped(json, false);
}

export function SchedulesPreviewEntriesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesPreviewEntriesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': ScheduleConfigPayloadFromJSON(json['config']),
        'from': !exists(json, 'from') ? undefined : json['from'],
        'overrides': ((json['overrides'] as Array<any>).map(ScheduleOverridePayloadFromJSON)),
        'schedule_id': !exists(json, 'schedule_id') ? undefined : json['schedule_id'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'until': !exists(json, 'until') ? undefined : json['until'],
    };
}

export function SchedulesPreviewEntriesRequestBodyToJSON(value?: SchedulesPreviewEntriesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': ScheduleConfigPayloadToJSON(value.config),
        'from': value.from,
        'overrides': ((value.overrides as Array<any>).map(ScheduleOverridePayloadToJSON)),
        'schedule_id': value.schedule_id,
        'timezone': value.timezone,
        'until': value.until,
    };
}

