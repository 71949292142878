/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentFormLifecycleElementBinding,
    IncidentFormLifecycleElementBindingFromJSON,
    IncidentFormLifecycleElementBindingFromJSONTyped,
    IncidentFormLifecycleElementBindingToJSON,
} from './IncidentFormLifecycleElementBinding';

/**
 * 
 * @export
 * @interface IncidentFormsGetLifecycleElementBindingsResponseBody
 */
export interface IncidentFormsGetLifecycleElementBindingsResponseBody {
    /**
     * Elements and their bindings for this incident type
     * @type {Array<IncidentFormLifecycleElementBinding>}
     * @memberof IncidentFormsGetLifecycleElementBindingsResponseBody
     */
    element_bindings: Array<IncidentFormLifecycleElementBinding>;
}

export function IncidentFormsGetLifecycleElementBindingsResponseBodyFromJSON(json: any): IncidentFormsGetLifecycleElementBindingsResponseBody {
    return IncidentFormsGetLifecycleElementBindingsResponseBodyFromJSONTyped(json, false);
}

export function IncidentFormsGetLifecycleElementBindingsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormsGetLifecycleElementBindingsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'element_bindings': ((json['element_bindings'] as Array<any>).map(IncidentFormLifecycleElementBindingFromJSON)),
    };
}

export function IncidentFormsGetLifecycleElementBindingsResponseBodyToJSON(value?: IncidentFormsGetLifecycleElementBindingsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'element_bindings': ((value.element_bindings as Array<any>).map(IncidentFormLifecycleElementBindingToJSON)),
    };
}

