import { unstable_usePrompt } from "react-router-dom";

// react-router deprecated this in v6, but then re-added the code with an unstable tag
// to help migration. This is useful, and although it's not perfect it's a lot better
// than nothing, so wanted to keep it around.
export const Prompt = ({
  when,
  message,
}: {
  when: boolean;
  message: string;
}) => {
  unstable_usePrompt({ when, message });
  return null;
};
