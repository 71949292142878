/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExploVariableMapping
 */
export interface ExploVariableMapping {
    /**
     * What key should the label of the select option be passed as
     * @type {string}
     * @memberof ExploVariableMapping
     */
    label_key?: string;
    /**
     * If the variable is a multi-select
     * @type {boolean}
     * @memberof ExploVariableMapping
     */
    multi_select?: boolean;
    /**
     * If present, we'll split the value on a pipe character, and pass the second half as this key
     * @type {string}
     * @memberof ExploVariableMapping
     */
    pipe_separated_value_key?: string;
    /**
     * What key should the value of the select option be passed as
     * @type {string}
     * @memberof ExploVariableMapping
     */
    value_key: string;
}

export function ExploVariableMappingFromJSON(json: any): ExploVariableMapping {
    return ExploVariableMappingFromJSONTyped(json, false);
}

export function ExploVariableMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExploVariableMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label_key': !exists(json, 'label_key') ? undefined : json['label_key'],
        'multi_select': !exists(json, 'multi_select') ? undefined : json['multi_select'],
        'pipe_separated_value_key': !exists(json, 'pipe_separated_value_key') ? undefined : json['pipe_separated_value_key'],
        'value_key': json['value_key'],
    };
}

export function ExploVariableMappingToJSON(value?: ExploVariableMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label_key': value.label_key,
        'multi_select': value.multi_select,
        'pipe_separated_value_key': value.pipe_separated_value_key,
        'value_key': value.value_key,
    };
}

