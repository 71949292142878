import { ScopeNameEnum } from "@incident-io/api";
import {
  HolidayUserFeed,
  HolidayUserFeedPayload,
  useHolidaysServiceHolidaysCreateFeed,
  UseHolidaysServiceHolidaysListFeedsKeyFn,
  useHolidaysServiceHolidaysUpdateFeed,
} from "@incident-io/query-api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Callout,
  CalloutTheme,
  Link,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { getErrors, setFormErrors } from "src/contexts/TanstackQueryProvider";

type Props = {
  onClose: () => void;
  // We don't use CreateEditFormProps here because IntegrationConfig complains, but ideally we would.
  initialData?: HolidayUserFeed;
};

export const CalendarFeedCreateEditModal = ({
  initialData,
  onClose,
}: Props): React.ReactElement => {
  const formMethods = useForm<HolidayUserFeedPayload>({
    defaultValues: initialData || {},
  });
  const toast = useToast();
  const { register } = formMethods;

  const onSuccess = () => {
    toast({
      title: `Calendar feed ${initialData ? "updated" : "added"}`,
      theme: ToastTheme.Success,
    });
    onClose();
  };

  const client = useQueryClient();

  const {
    mutate: onMutateCreate,
    isPending: saving,
    error: createError,
  } = useHolidaysServiceHolidaysCreateFeed({
    onSettled() {
      client.invalidateQueries({
        queryKey: UseHolidaysServiceHolidaysListFeedsKeyFn(),
      });
    },
    onSuccess: onSuccess,
    onError: setFormErrors(formMethods),
  });

  const {
    mutate: onMutateUpdate,
    isPending: updating,
    error: updateError,
  } = useHolidaysServiceHolidaysUpdateFeed({
    onSettled() {
      client.invalidateQueries({
        queryKey: UseHolidaysServiceHolidaysListFeedsKeyFn(),
      });
    },
    onSuccess: onSuccess,
    onError: setFormErrors(formMethods),
  });
  const onSubmitUpdate = (data: HolidayUserFeedPayload) => {
    onMutateUpdate({
      requestBody: data,
      id: initialData?.id || "",
    });
  };
  const onSubmitCreate = (data: HolidayUserFeedPayload) => {
    onMutateCreate({ requestBody: data });
  };

  const mutating = saving || updating;
  const { genericErrors } = getErrors(createError, updateError);

  return (
    <Form.Modal
      formMethods={formMethods}
      analyticsTrackingId={null}
      onClose={onClose}
      title={initialData ? "Edit calendar feed" : "Add calendar feed"}
      onSubmit={initialData ? onSubmitUpdate : onSubmitCreate}
      genericError={genericErrors?.[0]}
      footer={
        <ModalFooter
          requiredScope={
            initialData
              ? ScopeNameEnum.HolidayUserFeedsUpdate
              : ScopeNameEnum.HolidayUserFeedsCreate
          }
          onClose={onClose}
          saving={mutating}
          confirmButtonType="submit"
          confirmButtonText={initialData ? "Save" : "Add"}
        />
      }
    >
      {!initialData && (
        <Callout theme={CalloutTheme.Info} showIcon>
          <Link
            to={
              "https://help.incident.io/en/articles/9596297-seeing-holidays-in-on-call-schedules#h_205b147520"
            }
            analyticsTrackingId={"calendar-feed-help-centre-article-from-add"}
            openInNewTab
          >
            Read our help centre article
          </Link>{" "}
          for instructions on getting a calendar feed URL from your HR software
        </Callout>
      )}
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        required="Please provide a name for the calendar feed"
        placeholder={"Company Holidays"}
      />
      <InputV2
        formMethods={formMethods}
        autoFocus={!initialData} // Autofocusing feels off when editing.
        label="URL"
        required="Please provide a URL for the calendar feed"
        placeholder={"https://calendar.google.com/calendar/ical/..."}
        {...register("url", {
          required: "Please enter a valid link",
          validate: (value) => {
            if ((value || "").length > 1000) {
              return "URL is greater than 1,000 characters";
            }
            return true;
          },
        })}
      />
    </Form.Modal>
  );
};
