/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreamsCreateRequestBody,
    StreamsCreateRequestBodyFromJSON,
    StreamsCreateRequestBodyToJSON,
    StreamsCreateResponseBody,
    StreamsCreateResponseBodyFromJSON,
    StreamsCreateResponseBodyToJSON,
    StreamsListResponseBody,
    StreamsListResponseBodyFromJSON,
    StreamsListResponseBodyToJSON,
    StreamsShowResponseBody,
    StreamsShowResponseBodyFromJSON,
    StreamsShowResponseBodyToJSON,
} from '../models';

export interface StreamsCreateRequest {
    createRequestBody: StreamsCreateRequestBody;
}

export interface StreamsListRequest {
    parentId: string;
}

export interface StreamsShowRequest {
    id: string;
}

/**
 * 
 */
export class StreamsApi extends runtime.BaseAPI {

    /**
     * Create a new stream
     * Create Streams
     */
    async streamsCreateRaw(requestParameters: StreamsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StreamsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling streamsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/streams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreamsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StreamsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new stream
     * Create Streams
     */
    async streamsCreate(requestParameters: StreamsCreateRequest, initOverrides?: RequestInit): Promise<StreamsCreateResponseBody> {
        const response = await this.streamsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all streams for an incident
     * List Streams
     */
    async streamsListRaw(requestParameters: StreamsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StreamsListResponseBody>> {
        if (requestParameters.parentId === null || requestParameters.parentId === undefined) {
            throw new runtime.RequiredError('parentId','Required parameter requestParameters.parentId was null or undefined when calling streamsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/streams/parent/{parent_id}`.replace(`{${"parent_id"}}`, encodeURIComponent(String(requestParameters.parentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StreamsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all streams for an incident
     * List Streams
     */
    async streamsList(requestParameters: StreamsListRequest, initOverrides?: RequestInit): Promise<StreamsListResponseBody> {
        const response = await this.streamsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a stream
     * Show Streams
     */
    async streamsShowRaw(requestParameters: StreamsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StreamsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling streamsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/streams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StreamsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a stream
     * Show Streams
     */
    async streamsShow(requestParameters: StreamsShowRequest, initOverrides?: RequestInit): Promise<StreamsShowResponseBody> {
        const response = await this.streamsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
