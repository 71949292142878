import {
  IntegrationSettingsReconnectionReasonEnum as ReconnectionReasonEnum,
  OnboardingInformationDesiredPlanEnum as DesiredPlanEnum,
  ScopeNameEnum as ScopeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";

import { BrokenIntegrationBanner } from "./BrokenIntegrationBanner";
import { BuyTeamBanner } from "./BuyTeamBanner";
import { ReinstallBanner } from "./ReinstallBanner";
import { TrialBanner, useTrialBanner } from "./TrialBanner";

// Returns banners in order of importance.
export function Banners(): React.ReactElement | null {
  const { identity, hasScope, isImpersonating } = useIdentity();
  const { integrations } = useIntegrations();

  const needsReinstall =
    identity != null &&
    identity.slack_info &&
    identity.slack_info.missing_token_scopes.length > 0;

  // We only want to show a reinstall/reconnect banner if either the user has that scope, or is impersonating.
  const canManageIntegrations =
    hasScope(ScopeEnum.OrganisationSettingsUpdate) || isImpersonating;

  const showReinstallBanner = needsReinstall && canManageIntegrations;

  const trialBanner = useTrialBanner();

  if (showReinstallBanner) {
    return (
      <>
        {trialBanner && trialBanner.showWithReinstall && (
          <TrialBanner
            bannerType={trialBanner.bannerType}
            trialData={trialBanner.trialData}
          />
        )}
        <ReinstallBanner />
      </>
    );
  }

  if (trialBanner) {
    return (
      <TrialBanner
        bannerType={trialBanner.bannerType}
        trialData={trialBanner.trialData}
      />
    );
  }

  // If the installer expressed an interest in Team, the current user has
  // permission to change billing (i.e. buy Team), and no-one has dismissed the
  // banner yet, show the 'buy team' banner.
  if (
    identity?.onboarding_information?.desired_plan === DesiredPlanEnum.TeamV2 &&
    hasScope(ScopeEnum.BillingSettingsEdit) &&
    !identity.onboarding_information?.buy_plan_banner_dismissed
  ) {
    return <BuyTeamBanner />;
  }

  const brokenIntegrations = integrations?.filter((integration) => {
    return (
      integration.installed &&
      integration.reconnection_reason !== "" &&
      integration.reconnection_reason !==
        ReconnectionReasonEnum.IncompleteConfig
    );
  });

  if (
    brokenIntegrations &&
    brokenIntegrations.length > 0 &&
    canManageIntegrations &&
    identity.app_installed
  ) {
    return <BrokenIntegrationBanner integrations={brokenIntegrations} />;
  }

  return null;
}
