/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationProviderDisplay,
    EscalationProviderDisplayFromJSON,
    EscalationProviderDisplayFromJSONTyped,
    EscalationProviderDisplayToJSON,
} from './EscalationProviderDisplay';

/**
 * 
 * @export
 * @interface EscalationsListProvidersResponseBody
 */
export interface EscalationsListProvidersResponseBody {
    /**
     * 
     * @type {Array<EscalationProviderDisplay>}
     * @memberof EscalationsListProvidersResponseBody
     */
    providers: Array<EscalationProviderDisplay>;
}

export function EscalationsListProvidersResponseBodyFromJSON(json: any): EscalationsListProvidersResponseBody {
    return EscalationsListProvidersResponseBodyFromJSONTyped(json, false);
}

export function EscalationsListProvidersResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsListProvidersResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providers': ((json['providers'] as Array<any>).map(EscalationProviderDisplayFromJSON)),
    };
}

export function EscalationsListProvidersResponseBodyToJSON(value?: EscalationsListProvidersResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providers': ((value.providers as Array<any>).map(EscalationProviderDisplayToJSON)),
    };
}

