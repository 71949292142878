/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextMark,
    TextMarkFromJSON,
    TextMarkFromJSONTyped,
    TextMarkToJSON,
} from './TextMark';

/**
 * 
 * @export
 * @interface TextNode
 */
export interface TextNode {
    /**
     * type-specific node attributes
     * @type {object}
     * @memberof TextNode
     */
    attrs?: object;
    /**
     * children of this node
     * @type {Array<TextNode>}
     * @memberof TextNode
     */
    content?: any;
    /**
     * marks that apply to this node, eg 'bold'
     * @type {Array<TextMark>}
     * @memberof TextNode
     */
    marks?: Array<TextMark>;
    /**
     * if this node doesn't have children, it has text content
     * @type {string}
     * @memberof TextNode
     */
    text?: string;
    /**
     * the type of the node
     * @type {string}
     * @memberof TextNode
     */
    type: string;
}

export function TextNodeFromJSON(json: any): TextNode {
    return TextNodeFromJSONTyped(json, false);
}

export function TextNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attrs': !exists(json, 'attrs') ? undefined : json['attrs'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(TextNodeFromJSON)),
        'marks': !exists(json, 'marks') ? undefined : ((json['marks'] as Array<any>).map(TextMarkFromJSON)),
        'text': !exists(json, 'text') ? undefined : json['text'],
        'type': json['type'],
    };
}

export function TextNodeToJSON(value?: TextNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attrs': value.attrs,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(TextNodeToJSON)),
        'marks': value.marks === undefined ? undefined : ((value.marks as Array<any>).map(TextMarkToJSON)),
        'text': value.text,
        'type': value.type,
    };
}

