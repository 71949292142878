/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionPayload,
    ConditionPayloadFromJSON,
    ConditionPayloadFromJSONTyped,
    ConditionPayloadToJSON,
} from './ConditionPayload';

/**
 * 
 * @export
 * @interface IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody
 */
export interface IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody {
    /**
     * 
     * @type {Array<ConditionPayload>}
     * @memberof IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody
     */
    conditions: Array<ConditionPayload>;
    /**
     * The ID of an internal status page, to subscribe to all incidents of that page
     * @type {string}
     * @memberof IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody
     */
    internal_status_page_id?: string;
}

export function IncidentSubscriptionsCreateAutoSubscribeRuleRequestBodyFromJSON(json: any): IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody {
    return IncidentSubscriptionsCreateAutoSubscribeRuleRequestBodyFromJSONTyped(json, false);
}

export function IncidentSubscriptionsCreateAutoSubscribeRuleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': ((json['conditions'] as Array<any>).map(ConditionPayloadFromJSON)),
        'internal_status_page_id': !exists(json, 'internal_status_page_id') ? undefined : json['internal_status_page_id'],
    };
}

export function IncidentSubscriptionsCreateAutoSubscribeRuleRequestBodyToJSON(value?: IncidentSubscriptionsCreateAutoSubscribeRuleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': ((value.conditions as Array<any>).map(ConditionPayloadToJSON)),
        'internal_status_page_id': value.internal_status_page_id,
    };
}

