import { Icon, IconEnum } from "@incident-ui";

export const RoleListItemTitle = ({
  roleName,
  count,
}: {
  roleName: string;
  count: number;
}): React.ReactElement => {
  return (
    <div className="flex items-center">
      <div>{roleName}</div>
      <div className="flex items-center text-slate-600 font-normal ml-1">
        <Icon id={IconEnum.User} />
        <div>{count || 0}</div>
      </div>
    </div>
  );
};
