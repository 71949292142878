/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentMembership,
    IncidentMembershipFromJSON,
    IncidentMembershipFromJSONTyped,
    IncidentMembershipToJSON,
} from './IncidentMembership';

/**
 * 
 * @export
 * @interface IncidentMembershipsListResponseBody
 */
export interface IncidentMembershipsListResponseBody {
    /**
     * 
     * @type {Array<IncidentMembership>}
     * @memberof IncidentMembershipsListResponseBody
     */
    incident_memberships: Array<IncidentMembership>;
}

export function IncidentMembershipsListResponseBodyFromJSON(json: any): IncidentMembershipsListResponseBody {
    return IncidentMembershipsListResponseBodyFromJSONTyped(json, false);
}

export function IncidentMembershipsListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentMembershipsListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_memberships': ((json['incident_memberships'] as Array<any>).map(IncidentMembershipFromJSON)),
    };
}

export function IncidentMembershipsListResponseBodyToJSON(value?: IncidentMembershipsListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_memberships': ((value.incident_memberships as Array<any>).map(IncidentMembershipToJSON)),
    };
}

