import { Alert, AlertStatusEnum, Priority } from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  LocalDateTime,
  LocalRelativeDateTime,
} from "@incident-ui";
import { CopiableElement } from "@incident-ui/CopiableElement/CopiableElement";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Sidebar } from "src/components/legacy/incident/sidebar/Components";

import { AlertSourceBadge } from "../common/AlertOverviewColumn";
import { AlertPriorityBadge } from "../common/AlertPriorityBadge";

export const DetailsSection = ({
  alert,
  priorities,
}: {
  alert: Alert;
  priorities: Priority[];
}) => {
  const priority = priorities.find((p) => p.id === alert.priority_id);

  const { featureAlertThemes } = useFlags();

  return (
    <div className="space-y-2">
      <Sidebar.Entry
        label="Status"
        value={<AlertStatusBadge status={alert.status} />}
      />
      <Sidebar.Entry
        label="Source"
        value={<AlertSourceBadge alert={alert} clickable />}
      />
      <Sidebar.Entry
        label="Created"
        value={
          <div className={"flex items-center gap-1"}>
            <LocalDateTime timestamp={alert.created_at} />
            <div>
              (<LocalRelativeDateTime date={alert.created_at} />)
            </div>
          </div>
        }
      />
      {alert.resolved_at && (
        <Sidebar.Entry
          label="Resolved"
          value={
            <div className={"flex items-center gap-1"}>
              <LocalDateTime timestamp={alert.resolved_at} />
              <div>
                (<LocalRelativeDateTime date={alert.resolved_at} />)
              </div>
            </div>
          }
        />
      )}
      {priority ? (
        <Sidebar.Entry
          label="Priority"
          value={<AlertPriorityBadge priority={priority} />}
        />
      ) : null}
      <Sidebar.Entry
        label={"Deduplication key"}
        value={<CopiableElement content={alert.deduplication_key} />}
      />
      {featureAlertThemes && (
        <Sidebar.Entry
          label={"Themes"}
          allowWrap
          value={
            <div className={"flex flex-col gap-1 items-end"}>
              {alert.themes.map((theme) => (
                <div key={theme} className={"text-right shrink-1"}>
                  <CopiableElement content={theme} />
                </div>
              ))}
            </div>
          }
        />
      )}
    </div>
  );
};

const AlertStatusBadge = ({ status }: { status: AlertStatusEnum }) => {
  switch (status) {
    case AlertStatusEnum.Firing:
      return (
        <Badge
          theme={BadgeTheme.Warning}
          className="!bg-red-surface !text-red-content"
          size={BadgeSize.Medium}
        >
          Triggered
        </Badge>
      );
    case AlertStatusEnum.Resolved:
      return (
        <Badge size={BadgeSize.Medium} theme={BadgeTheme.Success}>
          Resolved
        </Badge>
      );
    default:
      return (
        <Badge
          theme={BadgeTheme.Warning}
          size={BadgeSize.Medium}
          className="!bg-red-surface !text-red-content"
        >
          Triggered
        </Badge>
      );
  }
};
