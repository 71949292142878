import { Analytics, AnalyticsSettings, Context } from "@segment/analytics-next";
import React from "react";
import { AnalyticsContext } from "src/contexts/AnalyticsContext";

export function MockAnalyticsProvider({
  children,
  trackFn,
}: {
  children: React.ReactNode;
  trackFn?: jest.MockedFunction<() => Promise<Context>>;
}): React.ReactElement {
  const settings: AnalyticsSettings = {
    writeKey: "hi",
  };
  const analytics = new Analytics(settings);
  analytics.track =
    trackFn ||
    (async (_: string) => {
      return new Context({ type: "track" }, "");
    });

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}
