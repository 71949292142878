/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DebriefsCreateDebriefRequestBody,
    DebriefsCreateDebriefRequestBodyFromJSON,
    DebriefsCreateDebriefRequestBodyToJSON,
    DebriefsCreateDebriefResponseBody,
    DebriefsCreateDebriefResponseBodyFromJSON,
    DebriefsCreateDebriefResponseBodyToJSON,
    DebriefsCreateInviteRuleRequestBody,
    DebriefsCreateInviteRuleRequestBodyFromJSON,
    DebriefsCreateInviteRuleRequestBodyToJSON,
    DebriefsCreateInviteRuleResponseBody,
    DebriefsCreateInviteRuleResponseBodyFromJSON,
    DebriefsCreateInviteRuleResponseBodyToJSON,
    DebriefsDestroyIncidentDebriefRequestBody,
    DebriefsDestroyIncidentDebriefRequestBodyFromJSON,
    DebriefsDestroyIncidentDebriefRequestBodyToJSON,
    DebriefsGenerateCalendarURLResponseBody,
    DebriefsGenerateCalendarURLResponseBodyFromJSON,
    DebriefsGenerateCalendarURLResponseBodyToJSON,
    DebriefsListIncidentDebriefsResponseBody,
    DebriefsListIncidentDebriefsResponseBodyFromJSON,
    DebriefsListIncidentDebriefsResponseBodyToJSON,
    DebriefsListInviteRulesResponseBody,
    DebriefsListInviteRulesResponseBodyFromJSON,
    DebriefsListInviteRulesResponseBodyToJSON,
    DebriefsListPlaceholdersResponseBody,
    DebriefsListPlaceholdersResponseBodyFromJSON,
    DebriefsListPlaceholdersResponseBodyToJSON,
    DebriefsShowInviteRuleResponseBody,
    DebriefsShowInviteRuleResponseBodyFromJSON,
    DebriefsShowInviteRuleResponseBodyToJSON,
    DebriefsShowSettingsForIncidentResponseBody,
    DebriefsShowSettingsForIncidentResponseBodyFromJSON,
    DebriefsShowSettingsForIncidentResponseBodyToJSON,
    DebriefsShowSettingsResponseBody,
    DebriefsShowSettingsResponseBodyFromJSON,
    DebriefsShowSettingsResponseBodyToJSON,
    DebriefsSuggestTimeslotsResponseBody,
    DebriefsSuggestTimeslotsResponseBodyFromJSON,
    DebriefsSuggestTimeslotsResponseBodyToJSON,
    DebriefsUpdateCalendarIDRequestBody,
    DebriefsUpdateCalendarIDRequestBodyFromJSON,
    DebriefsUpdateCalendarIDRequestBodyToJSON,
    DebriefsUpdateCalendarIDResponseBody,
    DebriefsUpdateCalendarIDResponseBodyFromJSON,
    DebriefsUpdateCalendarIDResponseBodyToJSON,
    DebriefsUpdateDebriefNameRequestBody,
    DebriefsUpdateDebriefNameRequestBodyFromJSON,
    DebriefsUpdateDebriefNameRequestBodyToJSON,
    DebriefsUpdateDebriefNameResponseBody,
    DebriefsUpdateDebriefNameResponseBodyFromJSON,
    DebriefsUpdateDebriefNameResponseBodyToJSON,
    DebriefsUpdateDebriefTitleAndDescriptionRequestBody,
    DebriefsUpdateDebriefTitleAndDescriptionRequestBodyFromJSON,
    DebriefsUpdateDebriefTitleAndDescriptionRequestBodyToJSON,
    DebriefsUpdateDebriefTitleAndDescriptionResponseBody,
    DebriefsUpdateDebriefTitleAndDescriptionResponseBodyFromJSON,
    DebriefsUpdateDebriefTitleAndDescriptionResponseBodyToJSON,
    DebriefsUpdateDurationRequestBody,
    DebriefsUpdateDurationRequestBodyFromJSON,
    DebriefsUpdateDurationRequestBodyToJSON,
    DebriefsUpdateDurationResponseBody,
    DebriefsUpdateDurationResponseBodyFromJSON,
    DebriefsUpdateDurationResponseBodyToJSON,
    DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody,
    DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBodyFromJSON,
    DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBodyToJSON,
    DebriefsUpdateExcludeTestOrTutorialFromInvitesResponseBody,
    DebriefsUpdateExcludeTestOrTutorialFromInvitesResponseBodyFromJSON,
    DebriefsUpdateExcludeTestOrTutorialFromInvitesResponseBodyToJSON,
    DebriefsUpdateInviteRuleRanksRequestBody,
    DebriefsUpdateInviteRuleRanksRequestBodyFromJSON,
    DebriefsUpdateInviteRuleRanksRequestBodyToJSON,
    DebriefsUpdateInviteRuleRanksResponseBody,
    DebriefsUpdateInviteRuleRanksResponseBodyFromJSON,
    DebriefsUpdateInviteRuleRanksResponseBodyToJSON,
    DebriefsUpdateInviteRuleRequestBody,
    DebriefsUpdateInviteRuleRequestBodyFromJSON,
    DebriefsUpdateInviteRuleRequestBodyToJSON,
    DebriefsUpdateInviteRuleResponseBody,
    DebriefsUpdateInviteRuleResponseBodyFromJSON,
    DebriefsUpdateInviteRuleResponseBodyToJSON,
} from '../models';

export interface DebriefsCreateDebriefRequest {
    createDebriefRequestBody: DebriefsCreateDebriefRequestBody;
}

export interface DebriefsCreateInviteRuleRequest {
    createInviteRuleRequestBody: DebriefsCreateInviteRuleRequestBody;
}

export interface DebriefsDestroyIncidentDebriefRequest {
    id: string;
    destroyIncidentDebriefRequestBody: DebriefsDestroyIncidentDebriefRequestBody;
}

export interface DebriefsDestroyInviteRuleRequest {
    id: string;
}

export interface DebriefsGenerateCalendarURLRequest {
    incidentId: string;
}

export interface DebriefsListIncidentDebriefsRequest {
    incidentId: string;
}

export interface DebriefsShowInviteRuleRequest {
    id: string;
}

export interface DebriefsShowSettingsForIncidentRequest {
    incidentId: string;
}

export interface DebriefsSuggestTimeslotsRequest {
    incidentId: string;
    pageSize?: number;
    after?: string;
}

export interface DebriefsUpdateCalendarIDRequest {
    updateCalendarIDRequestBody: DebriefsUpdateCalendarIDRequestBody;
}

export interface DebriefsUpdateDebriefNameRequest {
    updateDebriefNameRequestBody: DebriefsUpdateDebriefNameRequestBody;
}

export interface DebriefsUpdateDebriefTitleAndDescriptionRequest {
    updateDebriefTitleAndDescriptionRequestBody: DebriefsUpdateDebriefTitleAndDescriptionRequestBody;
}

export interface DebriefsUpdateDurationRequest {
    updateDurationRequestBody: DebriefsUpdateDurationRequestBody;
}

export interface DebriefsUpdateExcludeTestOrTutorialFromInvitesRequest {
    updateExcludeTestOrTutorialFromInvitesRequestBody: DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBody;
}

export interface DebriefsUpdateInviteRuleRequest {
    id: string;
    updateInviteRuleRequestBody: DebriefsUpdateInviteRuleRequestBody;
}

export interface DebriefsUpdateInviteRuleRanksRequest {
    updateInviteRuleRanksRequestBody: DebriefsUpdateInviteRuleRanksRequestBody;
}

/**
 * 
 */
export class DebriefsApi extends runtime.BaseAPI {

    /**
     * Create a debrief event within a placeholder
     * CreateDebrief Debriefs
     */
    async debriefsCreateDebriefRaw(requestParameters: DebriefsCreateDebriefRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsCreateDebriefResponseBody>> {
        if (requestParameters.createDebriefRequestBody === null || requestParameters.createDebriefRequestBody === undefined) {
            throw new runtime.RequiredError('createDebriefRequestBody','Required parameter requestParameters.createDebriefRequestBody was null or undefined when calling debriefsCreateDebrief.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/placeholders/actions/create_debrief`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsCreateDebriefRequestBodyToJSON(requestParameters.createDebriefRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsCreateDebriefResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a debrief event within a placeholder
     * CreateDebrief Debriefs
     */
    async debriefsCreateDebrief(requestParameters: DebriefsCreateDebriefRequest, initOverrides?: RequestInit): Promise<DebriefsCreateDebriefResponseBody> {
        const response = await this.debriefsCreateDebriefRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a debrief invite rule
     * CreateInviteRule Debriefs
     */
    async debriefsCreateInviteRuleRaw(requestParameters: DebriefsCreateInviteRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsCreateInviteRuleResponseBody>> {
        if (requestParameters.createInviteRuleRequestBody === null || requestParameters.createInviteRuleRequestBody === undefined) {
            throw new runtime.RequiredError('createInviteRuleRequestBody','Required parameter requestParameters.createInviteRuleRequestBody was null or undefined when calling debriefsCreateInviteRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/invite_rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsCreateInviteRuleRequestBodyToJSON(requestParameters.createInviteRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsCreateInviteRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a debrief invite rule
     * CreateInviteRule Debriefs
     */
    async debriefsCreateInviteRule(requestParameters: DebriefsCreateInviteRuleRequest, initOverrides?: RequestInit): Promise<DebriefsCreateInviteRuleResponseBody> {
        const response = await this.debriefsCreateInviteRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Destroy a debrief for an incident
     * DestroyIncidentDebrief Debriefs
     */
    async debriefsDestroyIncidentDebriefRaw(requestParameters: DebriefsDestroyIncidentDebriefRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling debriefsDestroyIncidentDebrief.');
        }

        if (requestParameters.destroyIncidentDebriefRequestBody === null || requestParameters.destroyIncidentDebriefRequestBody === undefined) {
            throw new runtime.RequiredError('destroyIncidentDebriefRequestBody','Required parameter requestParameters.destroyIncidentDebriefRequestBody was null or undefined when calling debriefsDestroyIncidentDebrief.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/incident_debriefs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsDestroyIncidentDebriefRequestBodyToJSON(requestParameters.destroyIncidentDebriefRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroy a debrief for an incident
     * DestroyIncidentDebrief Debriefs
     */
    async debriefsDestroyIncidentDebrief(requestParameters: DebriefsDestroyIncidentDebriefRequest, initOverrides?: RequestInit): Promise<void> {
        await this.debriefsDestroyIncidentDebriefRaw(requestParameters, initOverrides);
    }

    /**
     * Destroy a debrief invite rule
     * DestroyInviteRule Debriefs
     */
    async debriefsDestroyInviteRuleRaw(requestParameters: DebriefsDestroyInviteRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling debriefsDestroyInviteRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/invite_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroy a debrief invite rule
     * DestroyInviteRule Debriefs
     */
    async debriefsDestroyInviteRule(requestParameters: DebriefsDestroyInviteRuleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.debriefsDestroyInviteRuleRaw(requestParameters, initOverrides);
    }

    /**
     * Generates a URL to create a calendar event, with pre-populated data
     * GenerateCalendarURL Debriefs
     */
    async debriefsGenerateCalendarURLRaw(requestParameters: DebriefsGenerateCalendarURLRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsGenerateCalendarURLResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling debriefsGenerateCalendarURL.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/calendar_url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsGenerateCalendarURLResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generates a URL to create a calendar event, with pre-populated data
     * GenerateCalendarURL Debriefs
     */
    async debriefsGenerateCalendarURL(requestParameters: DebriefsGenerateCalendarURLRequest, initOverrides?: RequestInit): Promise<DebriefsGenerateCalendarURLResponseBody> {
        const response = await this.debriefsGenerateCalendarURLRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all debriefs for an incident
     * ListIncidentDebriefs Debriefs
     */
    async debriefsListIncidentDebriefsRaw(requestParameters: DebriefsListIncidentDebriefsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsListIncidentDebriefsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling debriefsListIncidentDebriefs.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/incident_debriefs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsListIncidentDebriefsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all debriefs for an incident
     * ListIncidentDebriefs Debriefs
     */
    async debriefsListIncidentDebriefs(requestParameters: DebriefsListIncidentDebriefsRequest, initOverrides?: RequestInit): Promise<DebriefsListIncidentDebriefsResponseBody> {
        const response = await this.debriefsListIncidentDebriefsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all debrief invite rules
     * ListInviteRules Debriefs
     */
    async debriefsListInviteRulesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsListInviteRulesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/invite_rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsListInviteRulesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all debrief invite rules
     * ListInviteRules Debriefs
     */
    async debriefsListInviteRules(initOverrides?: RequestInit): Promise<DebriefsListInviteRulesResponseBody> {
        const response = await this.debriefsListInviteRulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List any debrief placeholders
     * ListPlaceholders Debriefs
     */
    async debriefsListPlaceholdersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsListPlaceholdersResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/placeholders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsListPlaceholdersResponseBodyFromJSON(jsonValue));
    }

    /**
     * List any debrief placeholders
     * ListPlaceholders Debriefs
     */
    async debriefsListPlaceholders(initOverrides?: RequestInit): Promise<DebriefsListPlaceholdersResponseBody> {
        const response = await this.debriefsListPlaceholdersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show a debrief invite rule
     * ShowInviteRule Debriefs
     */
    async debriefsShowInviteRuleRaw(requestParameters: DebriefsShowInviteRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsShowInviteRuleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling debriefsShowInviteRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/invite_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsShowInviteRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a debrief invite rule
     * ShowInviteRule Debriefs
     */
    async debriefsShowInviteRule(requestParameters: DebriefsShowInviteRuleRequest, initOverrides?: RequestInit): Promise<DebriefsShowInviteRuleResponseBody> {
        const response = await this.debriefsShowInviteRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the debrief settings
     * ShowSettings Debriefs
     */
    async debriefsShowSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsShowSettingsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsShowSettingsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the debrief settings
     * ShowSettings Debriefs
     */
    async debriefsShowSettings(initOverrides?: RequestInit): Promise<DebriefsShowSettingsResponseBody> {
        const response = await this.debriefsShowSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the debrief settings for a specific incident
     * ShowSettingsForIncident Debriefs
     */
    async debriefsShowSettingsForIncidentRaw(requestParameters: DebriefsShowSettingsForIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsShowSettingsForIncidentResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling debriefsShowSettingsForIncident.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/show_for_incident`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsShowSettingsForIncidentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the debrief settings for a specific incident
     * ShowSettingsForIncident Debriefs
     */
    async debriefsShowSettingsForIncident(requestParameters: DebriefsShowSettingsForIncidentRequest, initOverrides?: RequestInit): Promise<DebriefsShowSettingsForIncidentResponseBody> {
        const response = await this.debriefsShowSettingsForIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Suggests available timeslots for a debrief to occur in
     * SuggestTimeslots Debriefs
     */
    async debriefsSuggestTimeslotsRaw(requestParameters: DebriefsSuggestTimeslotsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsSuggestTimeslotsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling debriefsSuggestTimeslots.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/debriefs/placeholders/actions/suggest_timeslots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsSuggestTimeslotsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Suggests available timeslots for a debrief to occur in
     * SuggestTimeslots Debriefs
     */
    async debriefsSuggestTimeslots(requestParameters: DebriefsSuggestTimeslotsRequest, initOverrides?: RequestInit): Promise<DebriefsSuggestTimeslotsResponseBody> {
        const response = await this.debriefsSuggestTimeslotsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the calendar we create debriefs in by default.
     * UpdateCalendarID Debriefs
     */
    async debriefsUpdateCalendarIDRaw(requestParameters: DebriefsUpdateCalendarIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsUpdateCalendarIDResponseBody>> {
        if (requestParameters.updateCalendarIDRequestBody === null || requestParameters.updateCalendarIDRequestBody === undefined) {
            throw new runtime.RequiredError('updateCalendarIDRequestBody','Required parameter requestParameters.updateCalendarIDRequestBody was null or undefined when calling debriefsUpdateCalendarID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/calendar_id`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsUpdateCalendarIDRequestBodyToJSON(requestParameters.updateCalendarIDRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsUpdateCalendarIDResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the calendar we create debriefs in by default.
     * UpdateCalendarID Debriefs
     */
    async debriefsUpdateCalendarID(requestParameters: DebriefsUpdateCalendarIDRequest, initOverrides?: RequestInit): Promise<DebriefsUpdateCalendarIDResponseBody> {
        const response = await this.debriefsUpdateCalendarIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the name we use for debriefs.
     * UpdateDebriefName Debriefs
     */
    async debriefsUpdateDebriefNameRaw(requestParameters: DebriefsUpdateDebriefNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsUpdateDebriefNameResponseBody>> {
        if (requestParameters.updateDebriefNameRequestBody === null || requestParameters.updateDebriefNameRequestBody === undefined) {
            throw new runtime.RequiredError('updateDebriefNameRequestBody','Required parameter requestParameters.updateDebriefNameRequestBody was null or undefined when calling debriefsUpdateDebriefName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/debrief_rename`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsUpdateDebriefNameRequestBodyToJSON(requestParameters.updateDebriefNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsUpdateDebriefNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the name we use for debriefs.
     * UpdateDebriefName Debriefs
     */
    async debriefsUpdateDebriefName(requestParameters: DebriefsUpdateDebriefNameRequest, initOverrides?: RequestInit): Promise<DebriefsUpdateDebriefNameResponseBody> {
        const response = await this.debriefsUpdateDebriefNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the default debrief title and description
     * UpdateDebriefTitleAndDescription Debriefs
     */
    async debriefsUpdateDebriefTitleAndDescriptionRaw(requestParameters: DebriefsUpdateDebriefTitleAndDescriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsUpdateDebriefTitleAndDescriptionResponseBody>> {
        if (requestParameters.updateDebriefTitleAndDescriptionRequestBody === null || requestParameters.updateDebriefTitleAndDescriptionRequestBody === undefined) {
            throw new runtime.RequiredError('updateDebriefTitleAndDescriptionRequestBody','Required parameter requestParameters.updateDebriefTitleAndDescriptionRequestBody was null or undefined when calling debriefsUpdateDebriefTitleAndDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/debrief_event_details`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsUpdateDebriefTitleAndDescriptionRequestBodyToJSON(requestParameters.updateDebriefTitleAndDescriptionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsUpdateDebriefTitleAndDescriptionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the default debrief title and description
     * UpdateDebriefTitleAndDescription Debriefs
     */
    async debriefsUpdateDebriefTitleAndDescription(requestParameters: DebriefsUpdateDebriefTitleAndDescriptionRequest, initOverrides?: RequestInit): Promise<DebriefsUpdateDebriefTitleAndDescriptionResponseBody> {
        const response = await this.debriefsUpdateDebriefTitleAndDescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the default duration of a debrief.
     * UpdateDuration Debriefs
     */
    async debriefsUpdateDurationRaw(requestParameters: DebriefsUpdateDurationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsUpdateDurationResponseBody>> {
        if (requestParameters.updateDurationRequestBody === null || requestParameters.updateDurationRequestBody === undefined) {
            throw new runtime.RequiredError('updateDurationRequestBody','Required parameter requestParameters.updateDurationRequestBody was null or undefined when calling debriefsUpdateDuration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/duration`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsUpdateDurationRequestBodyToJSON(requestParameters.updateDurationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsUpdateDurationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the default duration of a debrief.
     * UpdateDuration Debriefs
     */
    async debriefsUpdateDuration(requestParameters: DebriefsUpdateDurationRequest, initOverrides?: RequestInit): Promise<DebriefsUpdateDurationResponseBody> {
        const response = await this.debriefsUpdateDurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update whether or not all debrief invite rules will be ignored for test and tutorial incidents
     * UpdateExcludeTestOrTutorialFromInvites Debriefs
     */
    async debriefsUpdateExcludeTestOrTutorialFromInvitesRaw(requestParameters: DebriefsUpdateExcludeTestOrTutorialFromInvitesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsUpdateExcludeTestOrTutorialFromInvitesResponseBody>> {
        if (requestParameters.updateExcludeTestOrTutorialFromInvitesRequestBody === null || requestParameters.updateExcludeTestOrTutorialFromInvitesRequestBody === undefined) {
            throw new runtime.RequiredError('updateExcludeTestOrTutorialFromInvitesRequestBody','Required parameter requestParameters.updateExcludeTestOrTutorialFromInvitesRequestBody was null or undefined when calling debriefsUpdateExcludeTestOrTutorialFromInvites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/exclude_test_or_tutorial_from_invites`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsUpdateExcludeTestOrTutorialFromInvitesRequestBodyToJSON(requestParameters.updateExcludeTestOrTutorialFromInvitesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsUpdateExcludeTestOrTutorialFromInvitesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update whether or not all debrief invite rules will be ignored for test and tutorial incidents
     * UpdateExcludeTestOrTutorialFromInvites Debriefs
     */
    async debriefsUpdateExcludeTestOrTutorialFromInvites(requestParameters: DebriefsUpdateExcludeTestOrTutorialFromInvitesRequest, initOverrides?: RequestInit): Promise<DebriefsUpdateExcludeTestOrTutorialFromInvitesResponseBody> {
        const response = await this.debriefsUpdateExcludeTestOrTutorialFromInvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a debrief invite rule
     * UpdateInviteRule Debriefs
     */
    async debriefsUpdateInviteRuleRaw(requestParameters: DebriefsUpdateInviteRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsUpdateInviteRuleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling debriefsUpdateInviteRule.');
        }

        if (requestParameters.updateInviteRuleRequestBody === null || requestParameters.updateInviteRuleRequestBody === undefined) {
            throw new runtime.RequiredError('updateInviteRuleRequestBody','Required parameter requestParameters.updateInviteRuleRequestBody was null or undefined when calling debriefsUpdateInviteRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/invite_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsUpdateInviteRuleRequestBodyToJSON(requestParameters.updateInviteRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsUpdateInviteRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a debrief invite rule
     * UpdateInviteRule Debriefs
     */
    async debriefsUpdateInviteRule(requestParameters: DebriefsUpdateInviteRuleRequest, initOverrides?: RequestInit): Promise<DebriefsUpdateInviteRuleResponseBody> {
        const response = await this.debriefsUpdateInviteRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of debrief invite rules
     * UpdateInviteRuleRanks Debriefs
     */
    async debriefsUpdateInviteRuleRanksRaw(requestParameters: DebriefsUpdateInviteRuleRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DebriefsUpdateInviteRuleRanksResponseBody>> {
        if (requestParameters.updateInviteRuleRanksRequestBody === null || requestParameters.updateInviteRuleRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateInviteRuleRanksRequestBody','Required parameter requestParameters.updateInviteRuleRanksRequestBody was null or undefined when calling debriefsUpdateInviteRuleRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/debriefs/invite_rules/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DebriefsUpdateInviteRuleRanksRequestBodyToJSON(requestParameters.updateInviteRuleRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebriefsUpdateInviteRuleRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of debrief invite rules
     * UpdateInviteRuleRanks Debriefs
     */
    async debriefsUpdateInviteRuleRanks(requestParameters: DebriefsUpdateInviteRuleRanksRequest, initOverrides?: RequestInit): Promise<DebriefsUpdateInviteRuleRanksResponseBody> {
        const response = await this.debriefsUpdateInviteRuleRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
