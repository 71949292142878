import { CatalogType, CatalogTypeAttributePathItem } from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import { TruncatingReferenceLabel } from "@incident-shared/engine";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Badge, BadgeSize, BadgeTheme, IconEnum } from "@incident-ui";
import React from "react";

import { AttributeFormState } from "./types";

export const DeprecatedBacklinkAttributeBadge = ({
  referencedType,
  attributeName,
  clickable,
  suffix,
  className,
}: {
  referencedType: CatalogType;
  attributeName: string;
  clickable?: boolean;
  suffix?: React.ReactNode;
  className?: string;
}) => {
  return (
    <CatalogEntryBadge
      color={referencedType.color as unknown as ColorPaletteEnum}
      labelNode={
        <div className="flex items-center truncate">
          <TruncatingReferenceLabel
            path={[
              { key: referencedType.name, label: referencedType.name },
              { key: attributeName, label: attributeName },
            ]}
          />
        </div>
      }
      icon={referencedType.icon as unknown as IconEnum}
      clickable={clickable}
      className={className}
      suffix={suffix}
    />
  );
};

type DerivedAttributeBadgeProps = {
  attribute: AttributeFormState;
  catalogTypes: CatalogType[];
  clickable?: boolean;
  suffix?: React.ReactNode;
  className?: string;
};

export const DerivedAttributeBadge = (props: DerivedAttributeBadgeProps) => {
  if (props.attribute.backlink_attribute) {
    return (
      <BacklinkAttributeBadge
        backlinkAttribute={props.attribute.backlink_attribute}
        {...props}
      />
    );
  }

  if (props.attribute.path) {
    return <PathAttributeBadge path={props.attribute.path} {...props} />;
  }

  return <></>;
};

const BacklinkAttributeBadge = ({
  backlinkAttribute,
  attribute,
  catalogTypes,
  clickable,
  suffix,
  className,
}: DerivedAttributeBadgeProps & {
  backlinkAttribute: string;
}) => {
  const resultType = catalogTypes.find((r) => r.type_name === attribute.type);
  const referencedAttribute = resultType?.schema?.attributes?.find(
    (attr) => attr.id === backlinkAttribute,
  );

  if (!resultType || !referencedAttribute) {
    return <UnknownTypeBadge className={className} />;
  }

  return (
    <CatalogEntryBadge
      withTooltip
      color={resultType.color as unknown as ColorPaletteEnum}
      labelNode={
        <div className="flex items-center truncate">
          <TruncatingReferenceLabel
            path={[
              { key: resultType.name, label: resultType.name },
              {
                key: referencedAttribute.name,
                label: referencedAttribute.name,
              },
            ]}
          />
        </div>
      }
      label={`${resultType.name} → ${referencedAttribute.name}`}
      icon={resultType.icon as unknown as IconEnum}
      clickable={clickable}
      className={className}
      suffix={suffix}
    />
  );
};

const PathAttributeBadge = ({
  attribute,
  path,
  catalogTypes,
  clickable,
  suffix,
  className,
}: DerivedAttributeBadgeProps & {
  path: CatalogTypeAttributePathItem[];
}) => {
  const resultType = catalogTypes.find((r) => r.type_name === attribute.type);

  if (!resultType) {
    return (
      <Badge
        theme={BadgeTheme.Error}
        icon={IconEnum.Warning}
        size={BadgeSize.Medium}
      >
        Unknown type
      </Badge>
    );
  }

  return (
    <CatalogEntryBadge
      color={resultType.color as unknown as ColorPaletteEnum}
      labelNode={
        <div className="flex items-center truncate">
          <TruncatingReferenceLabel
            path={path.map((item) => ({
              key: item.attribute_id,
              label: item.attribute_name,
            }))}
          />
        </div>
      }
      icon={resultType.icon as unknown as IconEnum}
      clickable={clickable}
      className={className}
      suffix={suffix}
      label={path.map((item) => item.attribute_name).join(" → ")}
      withTooltip
    />
  );
};

const UnknownTypeBadge = ({ className }: { className?: string }) => {
  return (
    <Badge
      theme={BadgeTheme.Error}
      icon={IconEnum.Warning}
      size={BadgeSize.Medium}
      className={className}
    >
      Unknown type
    </Badge>
  );
};
