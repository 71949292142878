import {
  PostIncidentFlow,
  PostIncidentFlowUpdateNameRequestBody,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPIMutation } from "src/utils/swr";

export const PostIncidentFlowRenameModal = ({
  flow,
  onClose,
}: {
  flow: PostIncidentFlow;
  onClose: () => void;
}) => {
  const formMethods = useForm<PostIncidentFlowUpdateNameRequestBody>({
    defaultValues: { name: flow.name },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postIncidentFlowList",
    undefined,
    async (apiClient, data) => {
      await apiClient.postIncidentFlowUpdateName({
        id: flow.id,
        updateNameRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal
      onClose={onClose}
      title="Rename post-incident flow"
      analyticsTrackingId="rename-post-incident-flow"
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Rename"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
      />
    </Form.Modal>
  );
};
