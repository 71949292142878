/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RBACRolesCreateRequestBody
 */
export interface RBACRolesCreateRequestBody {
    /**
     * Optional description describing the purpose of the RBAC role
     * @type {string}
     * @memberof RBACRolesCreateRequestBody
     */
    description?: string;
    /**
     * Name of the custom RBAC role
     * @type {string}
     * @memberof RBACRolesCreateRequestBody
     */
    name: string;
    /**
     * Privileges assigned with this RBAC role
     * @type {Array<string>}
     * @memberof RBACRolesCreateRequestBody
     */
    privileges: Array<string>;
    /**
     * Optional custom slug (will be auto generated if not provided)
     * @type {string}
     * @memberof RBACRolesCreateRequestBody
     */
    slug?: string;
}

export function RBACRolesCreateRequestBodyFromJSON(json: any): RBACRolesCreateRequestBody {
    return RBACRolesCreateRequestBodyFromJSONTyped(json, false);
}

export function RBACRolesCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RBACRolesCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'privileges': json['privileges'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
    };
}

export function RBACRolesCreateRequestBodyToJSON(value?: RBACRolesCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'privileges': value.privileges,
        'slug': value.slug,
    };
}

