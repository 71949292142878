import { Callout, CalloutTheme, OrgAwareLink } from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";

import { isOnCallUser } from "../../settings/users/users/utils";

export const WrongSeatTypeWarning = () => {
  const { identity } = useIdentity();

  if (isOnCallUser(identity?.user_state)) return null;

  return (
    <Callout
      theme={CalloutTheme.Warning}
      className="p-4 pb-3.5 mb-6 !border-none"
    >
      <div>
        You&apos;ll need to have On-call enabled for your user in{" "}
        <OrgAwareLink className="underline" to="/settings/users/users">
          Settings &rarr; Users
        </OrgAwareLink>{" "}
        before you can get paged.
      </div>
    </Callout>
  );
};
