import type { SVGProps } from "react";
import * as React from "react";
const SvgChevronUp = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.47 6.97a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.56l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronUp;
