/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DependentResource,
    DependentResourceFromJSON,
    DependentResourceFromJSONTyped,
    DependentResourceToJSON,
} from './DependentResource';

/**
 * 
 * @export
 * @interface JiraCloudSite
 */
export interface JiraCloudSite {
    /**
     * url to avatar of the atlassian site
     * @type {string}
     * @memberof JiraCloudSite
     */
    avatar_url?: string;
    /**
     * Resources that depend on this site being installed. This site cannot be disabled until these resources are deleted.
     * @type {Array<DependentResource>}
     * @memberof JiraCloudSite
     */
    dependent_resources: Array<DependentResource>;
    /**
     * Whether Jira Cloud integration is enabled for this site
     * @type {boolean}
     * @memberof JiraCloudSite
     */
    enabled: boolean;
    /**
     * id of the atlassian site
     * @type {string}
     * @memberof JiraCloudSite
     */
    id: string;
    /**
     * name of atlassian site
     * @type {string}
     * @memberof JiraCloudSite
     */
    name: string;
}

export function JiraCloudSiteFromJSON(json: any): JiraCloudSite {
    return JiraCloudSiteFromJSONTyped(json, false);
}

export function JiraCloudSiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraCloudSite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'dependent_resources': ((json['dependent_resources'] as Array<any>).map(DependentResourceFromJSON)),
        'enabled': json['enabled'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function JiraCloudSiteToJSON(value?: JiraCloudSite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_url': value.avatar_url,
        'dependent_resources': ((value.dependent_resources as Array<any>).map(DependentResourceToJSON)),
        'enabled': value.enabled,
        'id': value.id,
        'name': value.name,
    };
}

