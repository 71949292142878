import { Icon, IconEnum, IconSize } from "@incident-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { useCounter } from "usehooks-ts";

export const IncidentFlameAvatar = () => {
  return (
    <div className="w-[60px] h-[60px] rounded-4 flex items-center justify-center bg-gradient-to-b from-alarmalade-400 to-alarmalade-500">
      <Icon
        id={IconEnum.IncidentFlameNoFill}
        size={IconSize.XL}
        className="text-white"
      />
    </div>
  );
};

export const AnimatedIncidentAvatar = () => {
  const states = [
    {
      icon: IconEnum.IncidentFlameNoFill,
      className: "bg-gradient-to-b from-alarmalade-400 to-alarmalade-500",
    },
    {
      icon: IconEnum.OnCall,
      className: "bg-gradient-to-b from-green-400 to-green-500",
    },
    {
      icon: IconEnum.StatusPage,
      className: "bg-gradient-to-b from-blue-400 to-blue-500",
    },
    {
      icon: IconEnum.Chart,
      className: "bg-gradient-to-b from-pink-400 to-pink-500",
    },
    {
      icon: IconEnum.IncidentFlameNoFill,
      className: "bg-gradient-to-b from-alarmalade-400 to-alarmalade-500",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const { count: countWaited, increment } = useCounter();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        // We want to 'do nothing' for the first two ticks, so we use 0.1 and 0.2
        if (countWaited < 2) {
          increment();
          return prevIndex;
        }

        if (prevIndex < 4) {
          // Go to next item
          return prevIndex + 1;
        } else {
          // Stop on last item
          return 4;
        }
      });
    }, 2000);

    return () => clearInterval(timer);
  }, [setCurrentIndex, increment, countWaited]);

  return (
    <div className="relative size-[60px] flex items-center justify-center">
      <motion.div
        animate={{ rotate: currentIndex * -90 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="absolute w-full h-full overflow-hidden rounded-4"
      >
        <AnimatePresence initial={false}>
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className={tcx(
              "absolute w-full h-full flex items-center justify-center rounded-2xl",
              states[currentIndex].className,
            )}
          />
        </AnimatePresence>
      </motion.div>

      <AnimatePresence initial={false} mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ x: 15, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -15, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="absolute w-full h-full flex items-center justify-center z-10"
        >
          <Icon
            id={states[currentIndex].icon}
            size={IconSize.XL}
            className="text-white"
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
