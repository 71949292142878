import { inputRules, textblockTypeInputRule } from "prosemirror-inputrules";
import { wrappingInputRule } from "prosemirror-inputrules";
import { Schema } from "prosemirror-model";

// listAutocompleteRules automatically turns "*" or "-" then the space keybinding in to a list.
export const listAutocompleteRules = (schema: Schema) => {
  const rules = [
    wrappingInputRule(/^\s*([*-])\s$/, schema.nodes.list, { type: "bulleted" }),
  ];
  return inputRules({ rules });
};

// orderedListAutocompleteRules automatically turns "1." or "2." then the space keybinding in to a list.
export const orderedListAutocompleteRules = (schema: Schema) => {
  const rules = [
    wrappingInputRule(/^\s*(\d\.)\s$/, schema.nodes.list, { type: "ordered" }),
  ];
  return inputRules({ rules });
};

// quoteAutocompleteRules turns lines starting with "> " into a quote
export const quoteAutocompleteRules = (schema: Schema) => {
  const rules = [wrappingInputRule(/^\s*(>)\s$/, schema.nodes.blockquote)];
  return inputRules({ rules });
};

// codeAutocompleteRules turns lines starting with "```" into a code block
export const codeAutocompleteRules = (schema: Schema) => {
  const rules = [textblockTypeInputRule(/^\s*(```)$/, schema.nodes.codeBlock)];
  return inputRules({ rules });
};
