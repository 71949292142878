/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateRangeAbsoluteState
 */
export interface DateRangeAbsoluteState {
    /**
     * Start date of the range
     * @type {Date}
     * @memberof DateRangeAbsoluteState
     */
    from: Date;
    /**
     * End date of the range
     * @type {Date}
     * @memberof DateRangeAbsoluteState
     */
    to: Date;
}

export function DateRangeAbsoluteStateFromJSON(json: any): DateRangeAbsoluteState {
    return DateRangeAbsoluteStateFromJSONTyped(json, false);
}

export function DateRangeAbsoluteStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRangeAbsoluteState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': (new Date(json['from'])),
        'to': (new Date(json['to'])),
    };
}

export function DateRangeAbsoluteStateToJSON(value?: DateRangeAbsoluteState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': (value.from.toISOString()),
        'to': (value.to.toISOString()),
    };
}

