import React, { createContext, useContext } from "react";

type PortalContextType = {
  portalRef?: React.RefObject<HTMLDivElement>;
};

export const PortalContext = createContext<PortalContextType>({
  portalRef: undefined,
});

// Provider component which tells any children that want to portal a component
// (e.g. a Popover) to attach it to a specific ref, instead of document.body. This
// means we can correctly handle popovers inside elements like drawers.
export const PortalProvider = ({
  portalRef,
  children,
}: {
  portalRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <PortalContext.Provider
      value={{
        portalRef,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};

export const usePortal = (): React.RefObject<HTMLDivElement> | undefined => {
  const ctx = useContext(PortalContext);
  if (ctx) {
    return ctx.portalRef;
  }
  return undefined;
};
