/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIKeyRole,
    APIKeyRoleFromJSON,
    APIKeyRoleFromJSONTyped,
    APIKeyRoleToJSON,
} from './APIKeyRole';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface APIKey
 */
export interface APIKey {
    /**
     * 
     * @type {User}
     * @memberof APIKey
     */
    created_by: User;
    /**
     * Unique identifier for this API key
     * @type {string}
     * @memberof APIKey
     */
    id: string;
    /**
     * When the key was last used to authenticate a request
     * @type {Date}
     * @memberof APIKey
     */
    last_used_at?: Date;
    /**
     * The name of the API key, for the user's reference
     * @type {string}
     * @memberof APIKey
     */
    name: string;
    /**
     * The roles that this key has - and therefore what it is allowed to do
     * @type {Array<APIKeyRole>}
     * @memberof APIKey
     */
    roles: Array<APIKeyRole>;
}

export function APIKeyFromJSON(json: any): APIKey {
    return APIKeyFromJSONTyped(json, false);
}

export function APIKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_by': UserFromJSON(json['created_by']),
        'id': json['id'],
        'last_used_at': !exists(json, 'last_used_at') ? undefined : (new Date(json['last_used_at'])),
        'name': json['name'],
        'roles': ((json['roles'] as Array<any>).map(APIKeyRoleFromJSON)),
    };
}

export function APIKeyToJSON(value?: APIKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_by': UserToJSON(value.created_by),
        'id': value.id,
        'last_used_at': value.last_used_at === undefined ? undefined : (value.last_used_at.toISOString()),
        'name': value.name,
        'roles': ((value.roles as Array<any>).map(APIKeyRoleToJSON)),
    };
}

