import { useIdentity } from "src/contexts/IdentityContext";

export const useAuthRedirectPath = (
  basePath: string,
  returnPath: string,
  purpose?: string,
): { path: string } => {
  const { identity } = useIdentity();

  const params = new URLSearchParams();
  params.append("organisation_id", identity.organisation_id);
  params.append("return_path", returnPath);

  if (purpose) {
    params.append("purpose", purpose);
  }

  return {
    path: `${basePath}?${params.toString()}`,
  };
};
