import { AvailableNudge } from "@incident-io/api";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Link,
  LoadingModal,
} from "@incident-ui";
import { Modal } from "@incident-ui/Modal";
import { captureException } from "@sentry/react";
import _ from "lodash";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

export function NudgeSelectTypeModal({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement {
  // List all available nudges so we can choose from the list.
  const {
    data: { available_nudges: availableNudges },
    isLoading,
    error,
  } = useAPI("nudgesListAvailableNudges", undefined, {
    fallbackData: { available_nudges: [] },
  });

  // Order them by icon so it's consistent, otherwise it jumps when you close and open it.
  // Sorting by icon also keeps the two statuspage nudges together
  const sortedNudges = _.sortBy(availableNudges, (nudge) => [
    nudge.icon,
    nudge.label,
  ]);

  if (isLoading) {
    return (
      <LoadingModal title="Choose an action for the nudge" onClose={onClose} />
    );
  }

  if (error) {
    captureException(error);
    return <GenericErrorMessage error={error} />;
  }

  return (
    <Modal
      analyticsTrackingId="nudges-choose-type"
      onClose={onClose}
      title="Choose an action for the nudge"
      isOpen
    >
      <div className="grid grid-cols-2 gap-[12px] text-sm p-4">
        {sortedNudges.map((nudge) => (
          <NudgeTypeCard key={nudge.nudge_type} nudge={nudge} />
        ))}
      </div>
    </Modal>
  );
}

export const NudgeTypeCard = ({ nudge }: { nudge: AvailableNudge }) => {
  const colors = getColorPalette(ColorPaletteEnum.Blue);

  return (
    <div
      className={tcx(
        "flex flex-col border shadow-sm rounded-2 border-radius border-stroke hover:border-slate-500 hover:shadow-md transition",
      )}
    >
      <Link
        noHoverColor={true}
        openInNewTab={false}
        className={tcx("no-underline p-3")}
        analyticsTrackingId={`nudges-choose-type-${nudge.nudge_type}`}
        to={`/settings/nudges/create?nudge_type=${nudge.nudge_type}`}
      >
        <div className="space-y-1.5 text-content-primary">
          <div className={"flex flex-row items-center"}>
            <div
              className={tcx(
                "rounded flex-center !min-w-[36px] !max-w-[36px] !max-h-[36px] !min-h-[36px] p-[5px] mr-[10px]",
                colors.background,
                colors.border,
                colors.text,
              )}
            >
              <Icon
                id={nudge.icon as unknown as IconEnum}
                size={IconSize.Large}
                className={colors.icon}
              />
            </div>
            <div className="font-medium">{nudge.label}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
