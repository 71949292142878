import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  ButtonTheme,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  Txt,
} from "@incident-ui";
import { IncidentStatusCategoryEnum as StatusCategoryEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { useLifecycleOverview } from "../LifecycleOverviewContext";
export enum LifecycleSectionThemeEnum {
  Light = "light",
  Dark = "dark",
}

export const LIFECYCLE_GRID_CLASSNAME = "grid grid-cols-[300px_1fr]";

export const LifecycleOverviewSection = ({
  className,
  diagram,
  diagramClassName,
  bottomBorderNode,
  configurationNode,
  configurationNodeClassName,
  title,
  description,
  category,
}: {
  diagram: React.ReactNode;
  configurationNode?: React.ReactNode;
  bottomBorderNode?: React.ReactNode;
  className?: string;
  diagramClassName?: string;
  configurationNodeClassName?: string;
  title?: string;
  description?: string;
  category?: StatusCategoryEnum;
}) => {
  const { onToggleConfiguring, getConfigurationState } = useLifecycleOverview();

  const state = getConfigurationState(category);
  const isEditing = state === "editing";
  const isBackground = state === "background";

  return (
    <>
      <div
        className={tcx(
          LIFECYCLE_GRID_CLASSNAME,
          " w-full !border-dashed border-b last-of-type:border-0 border-slate-400",
          className,
        )}
      >
        {/* Col 1: the category name */}
        <div className="pr-4 flex flex-col gap-4 -mt-2 pb-4">
          <div className="flex items-center justify-between w-full pr-4">
            <div className="flex items-center gap-2">
              {category && title && (
                <IncidentStatusBadge
                  status={{ category, name: title }}
                  size={BadgeSize.Small}
                  className={
                    isBackground
                      ? "bg-surface-secondary text-content-tertiary"
                      : ""
                  }
                />
              )}
              {isEditing && (
                <Badge
                  theme={BadgeTheme.Tertiary}
                  className="border border-stroke"
                  size={BadgeSize.ExtraSmall}
                >
                  Editing
                </Badge>
              )}
            </div>
            {configurationNode && (
              <div className="flex justify-start items-start -mr-2">
                <GatedButton
                  requiredProduct={Product.Response}
                  analyticsTrackingId={null}
                  className={tcx(
                    isEditing
                      ? "font-medium !text-content-primary hover:!underline"
                      : "",
                  )}
                  disabled={isBackground}
                  theme={ButtonTheme.Naked}
                  icon={isEditing ? IconEnum.Tick : IconEnum.Edit}
                  iconProps={{
                    size: IconSize.Small,
                    className: isEditing ? "text-content-primary" : "",
                  }}
                  onClick={() => onToggleConfiguring(category)}
                >
                  {isEditing ? "Done" : "Edit"}
                </GatedButton>
              </div>
            )}
          </div>
          <Txt lightGrey>{description}</Txt>
        </div>
        {/* Col 2: the main content (diagram or configuration form) */}
        <div className="overflow-x-hidden">
          <div
            className={tcx(
              "h-full w-full p-5 pt-0",
              { hidden: !isEditing },
              configurationNodeClassName,
            )}
          >
            {configurationNode}
          </div>

          <div
            className={tcx(
              "flex flex-col px-5 pb-5 pt-1 w-full",
              { hidden: isEditing },
              diagramClassName,
            )}
          >
            {diagram}
          </div>
        </div>
      </div>
      {bottomBorderNode && (
        <div className={tcx(LIFECYCLE_GRID_CLASSNAME)}>
          <div />
          <div className="-mt-[24px] pl-5">{bottomBorderNode}</div>
        </div>
      )}
    </>
  );
};
