/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TelecomTwilioAnsweringMachineCallbackRequestBody,
    TelecomTwilioAnsweringMachineCallbackRequestBodyFromJSON,
    TelecomTwilioAnsweringMachineCallbackRequestBodyToJSON,
    TelecomTwilioConferenceStatusRequestBody,
    TelecomTwilioConferenceStatusRequestBodyFromJSON,
    TelecomTwilioConferenceStatusRequestBodyToJSON,
    TelecomTwilioConferenceWaitRequestBody,
    TelecomTwilioConferenceWaitRequestBodyFromJSON,
    TelecomTwilioConferenceWaitRequestBodyToJSON,
    TelecomTwilioErrorWebhookRequestBody,
    TelecomTwilioErrorWebhookRequestBodyFromJSON,
    TelecomTwilioErrorWebhookRequestBodyToJSON,
    TelecomTwilioInboundCallRequestBody,
    TelecomTwilioInboundCallRequestBodyFromJSON,
    TelecomTwilioInboundCallRequestBodyToJSON,
    TelecomTwilioInboundVoiceStatusRequestBody,
    TelecomTwilioInboundVoiceStatusRequestBodyFromJSON,
    TelecomTwilioInboundVoiceStatusRequestBodyToJSON,
    TelecomTwilioMessageStatusRequestBody,
    TelecomTwilioMessageStatusRequestBodyFromJSON,
    TelecomTwilioMessageStatusRequestBodyToJSON,
    TelecomTwilioSMSRequestBody,
    TelecomTwilioSMSRequestBodyFromJSON,
    TelecomTwilioSMSRequestBodyToJSON,
    TelecomTwilioVoiceRequestBody,
    TelecomTwilioVoiceRequestBodyFromJSON,
    TelecomTwilioVoiceRequestBodyToJSON,
    TwilioVoiceStatusPayload,
    TwilioVoiceStatusPayloadFromJSON,
    TwilioVoiceStatusPayloadToJSON,
} from '../models';

export interface TelecomTwilioAnsweringMachineCallbackRequest {
    xTwilioSignature: string;
    answeringMachineCallbackRequestBody: TelecomTwilioAnsweringMachineCallbackRequestBody;
}

export interface TelecomTwilioConferenceStatusRequest {
    xTwilioSignature: string;
    conferenceStatusRequestBody: TelecomTwilioConferenceStatusRequestBody;
}

export interface TelecomTwilioConferenceWaitRequest {
    xTwilioSignature: string;
    conferenceWaitRequestBody: TelecomTwilioConferenceWaitRequestBody;
}

export interface TelecomTwilioErrorWebhookRequest {
    xTwilioSignature: string;
    errorWebhookRequestBody: TelecomTwilioErrorWebhookRequestBody;
}

export interface TelecomTwilioInboundCallRequest {
    callRouteId: string;
    xTwilioSignature: string;
    inboundCallRequestBody: TelecomTwilioInboundCallRequestBody;
}

export interface TelecomTwilioInboundVoiceStatusRequest {
    callRouteId: string;
    xTwilioSignature: string;
    inboundVoiceStatusRequestBody: TelecomTwilioInboundVoiceStatusRequestBody;
}

export interface TelecomTwilioMessageStatusRequest {
    xTwilioSignature: string;
    messageStatusRequestBody: TelecomTwilioMessageStatusRequestBody;
}

export interface TelecomTwilioSMSRequest {
    xTwilioSignature: string;
    sMSRequestBody: TelecomTwilioSMSRequestBody;
}

export interface TelecomTwilioVoiceRequest {
    sourceType: string;
    sourceId: string;
    xTwilioSignature: string;
    voiceRequestBody: TelecomTwilioVoiceRequestBody;
    emptyResponseCount?: number;
}

export interface TelecomTwilioVoiceStatusRequest {
    xTwilioSignature: string;
    voiceStatusRequestBody: TwilioVoiceStatusPayload;
}

/**
 * 
 */
export class TelecomTwilioApi extends runtime.BaseAPI {

    /**
     * Callback for Twilio to tell us about the answering machine state of a call
     * AnsweringMachineCallback Telecom - Twilio
     */
    async telecomTwilioAnsweringMachineCallbackRaw(requestParameters: TelecomTwilioAnsweringMachineCallbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioAnsweringMachineCallback.');
        }

        if (requestParameters.answeringMachineCallbackRequestBody === null || requestParameters.answeringMachineCallbackRequestBody === undefined) {
            throw new runtime.RequiredError('answeringMachineCallbackRequestBody','Required parameter requestParameters.answeringMachineCallbackRequestBody was null or undefined when calling telecomTwilioAnsweringMachineCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/voice/answering_machine_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioAnsweringMachineCallbackRequestBodyToJSON(requestParameters.answeringMachineCallbackRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio to tell us about the answering machine state of a call
     * AnsweringMachineCallback Telecom - Twilio
     */
    async telecomTwilioAnsweringMachineCallback(requestParameters: TelecomTwilioAnsweringMachineCallbackRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioAnsweringMachineCallbackRaw(requestParameters, initOverrides);
    }

    /**
     * Callback for Twilio voice call status updates
     * ConferenceStatus Telecom - Twilio
     */
    async telecomTwilioConferenceStatusRaw(requestParameters: TelecomTwilioConferenceStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioConferenceStatus.');
        }

        if (requestParameters.conferenceStatusRequestBody === null || requestParameters.conferenceStatusRequestBody === undefined) {
            throw new runtime.RequiredError('conferenceStatusRequestBody','Required parameter requestParameters.conferenceStatusRequestBody was null or undefined when calling telecomTwilioConferenceStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/conference/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioConferenceStatusRequestBodyToJSON(requestParameters.conferenceStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio voice call status updates
     * ConferenceStatus Telecom - Twilio
     */
    async telecomTwilioConferenceStatus(requestParameters: TelecomTwilioConferenceStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioConferenceStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Instructs Twilio what to play while someone is waiting for a conference to start
     * ConferenceWait Telecom - Twilio
     */
    async telecomTwilioConferenceWaitRaw(requestParameters: TelecomTwilioConferenceWaitRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioConferenceWait.');
        }

        if (requestParameters.conferenceWaitRequestBody === null || requestParameters.conferenceWaitRequestBody === undefined) {
            throw new runtime.RequiredError('conferenceWaitRequestBody','Required parameter requestParameters.conferenceWaitRequestBody was null or undefined when calling telecomTwilioConferenceWait.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/conference/wait`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioConferenceWaitRequestBodyToJSON(requestParameters.conferenceWaitRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Instructs Twilio what to play while someone is waiting for a conference to start
     * ConferenceWait Telecom - Twilio
     */
    async telecomTwilioConferenceWait(requestParameters: TelecomTwilioConferenceWaitRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioConferenceWaitRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook that Twilio triggers each time an error/warning occurs in our account.
     * ErrorWebhook Telecom - Twilio
     */
    async telecomTwilioErrorWebhookRaw(requestParameters: TelecomTwilioErrorWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioErrorWebhook.');
        }

        if (requestParameters.errorWebhookRequestBody === null || requestParameters.errorWebhookRequestBody === undefined) {
            throw new runtime.RequiredError('errorWebhookRequestBody','Required parameter requestParameters.errorWebhookRequestBody was null or undefined when calling telecomTwilioErrorWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/error`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioErrorWebhookRequestBodyToJSON(requestParameters.errorWebhookRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook that Twilio triggers each time an error/warning occurs in our account.
     * ErrorWebhook Telecom - Twilio
     */
    async telecomTwilioErrorWebhook(requestParameters: TelecomTwilioErrorWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioErrorWebhookRaw(requestParameters, initOverrides);
    }

    /**
     * Default callback for Twilio voice calls
     * InboundCall Telecom - Twilio
     */
    async telecomTwilioInboundCallRaw(requestParameters: TelecomTwilioInboundCallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.callRouteId === null || requestParameters.callRouteId === undefined) {
            throw new runtime.RequiredError('callRouteId','Required parameter requestParameters.callRouteId was null or undefined when calling telecomTwilioInboundCall.');
        }

        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioInboundCall.');
        }

        if (requestParameters.inboundCallRequestBody === null || requestParameters.inboundCallRequestBody === undefined) {
            throw new runtime.RequiredError('inboundCallRequestBody','Required parameter requestParameters.inboundCallRequestBody was null or undefined when calling telecomTwilioInboundCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/inbound_voice/{call_route_id}`.replace(`{${"call_route_id"}}`, encodeURIComponent(String(requestParameters.callRouteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioInboundCallRequestBodyToJSON(requestParameters.inboundCallRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Default callback for Twilio voice calls
     * InboundCall Telecom - Twilio
     */
    async telecomTwilioInboundCall(requestParameters: TelecomTwilioInboundCallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioInboundCallRaw(requestParameters, initOverrides);
    }

    /**
     * Callback for Twilio voice call status updates on inbound calls
     * InboundVoiceStatus Telecom - Twilio
     */
    async telecomTwilioInboundVoiceStatusRaw(requestParameters: TelecomTwilioInboundVoiceStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.callRouteId === null || requestParameters.callRouteId === undefined) {
            throw new runtime.RequiredError('callRouteId','Required parameter requestParameters.callRouteId was null or undefined when calling telecomTwilioInboundVoiceStatus.');
        }

        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioInboundVoiceStatus.');
        }

        if (requestParameters.inboundVoiceStatusRequestBody === null || requestParameters.inboundVoiceStatusRequestBody === undefined) {
            throw new runtime.RequiredError('inboundVoiceStatusRequestBody','Required parameter requestParameters.inboundVoiceStatusRequestBody was null or undefined when calling telecomTwilioInboundVoiceStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/inbound_voice/{call_route_id}/status`.replace(`{${"call_route_id"}}`, encodeURIComponent(String(requestParameters.callRouteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioInboundVoiceStatusRequestBodyToJSON(requestParameters.inboundVoiceStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio voice call status updates on inbound calls
     * InboundVoiceStatus Telecom - Twilio
     */
    async telecomTwilioInboundVoiceStatus(requestParameters: TelecomTwilioInboundVoiceStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioInboundVoiceStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Callback for Twilio message status updates
     * MessageStatus Telecom - Twilio
     */
    async telecomTwilioMessageStatusRaw(requestParameters: TelecomTwilioMessageStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioMessageStatus.');
        }

        if (requestParameters.messageStatusRequestBody === null || requestParameters.messageStatusRequestBody === undefined) {
            throw new runtime.RequiredError('messageStatusRequestBody','Required parameter requestParameters.messageStatusRequestBody was null or undefined when calling telecomTwilioMessageStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/message/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioMessageStatusRequestBodyToJSON(requestParameters.messageStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio message status updates
     * MessageStatus Telecom - Twilio
     */
    async telecomTwilioMessageStatus(requestParameters: TelecomTwilioMessageStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioMessageStatusRaw(requestParameters, initOverrides);
    }

    /**
     * SMS webhook from Twilio
     * SMS Telecom - Twilio
     */
    async telecomTwilioSMSRaw(requestParameters: TelecomTwilioSMSRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioSMS.');
        }

        if (requestParameters.sMSRequestBody === null || requestParameters.sMSRequestBody === undefined) {
            throw new runtime.RequiredError('sMSRequestBody','Required parameter requestParameters.sMSRequestBody was null or undefined when calling telecomTwilioSMS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioSMSRequestBodyToJSON(requestParameters.sMSRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SMS webhook from Twilio
     * SMS Telecom - Twilio
     */
    async telecomTwilioSMS(requestParameters: TelecomTwilioSMSRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioSMSRaw(requestParameters, initOverrides);
    }

    /**
     * Default callback for Twilio voice calls
     * Voice Telecom - Twilio
     */
    async telecomTwilioVoiceRaw(requestParameters: TelecomTwilioVoiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sourceType === null || requestParameters.sourceType === undefined) {
            throw new runtime.RequiredError('sourceType','Required parameter requestParameters.sourceType was null or undefined when calling telecomTwilioVoice.');
        }

        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError('sourceId','Required parameter requestParameters.sourceId was null or undefined when calling telecomTwilioVoice.');
        }

        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioVoice.');
        }

        if (requestParameters.voiceRequestBody === null || requestParameters.voiceRequestBody === undefined) {
            throw new runtime.RequiredError('voiceRequestBody','Required parameter requestParameters.voiceRequestBody was null or undefined when calling telecomTwilioVoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.emptyResponseCount !== undefined) {
            queryParameters['empty_response_count'] = requestParameters.emptyResponseCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/voice/{source_type}/{source_id}`.replace(`{${"source_type"}}`, encodeURIComponent(String(requestParameters.sourceType))).replace(`{${"source_id"}}`, encodeURIComponent(String(requestParameters.sourceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioVoiceRequestBodyToJSON(requestParameters.voiceRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Default callback for Twilio voice calls
     * Voice Telecom - Twilio
     */
    async telecomTwilioVoice(requestParameters: TelecomTwilioVoiceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioVoiceRaw(requestParameters, initOverrides);
    }

    /**
     * Callback for Twilio voice call status updates
     * VoiceStatus Telecom - Twilio
     */
    async telecomTwilioVoiceStatusRaw(requestParameters: TelecomTwilioVoiceStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioVoiceStatus.');
        }

        if (requestParameters.voiceStatusRequestBody === null || requestParameters.voiceStatusRequestBody === undefined) {
            throw new runtime.RequiredError('voiceStatusRequestBody','Required parameter requestParameters.voiceStatusRequestBody was null or undefined when calling telecomTwilioVoiceStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/voice/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwilioVoiceStatusPayloadToJSON(requestParameters.voiceStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio voice call status updates
     * VoiceStatus Telecom - Twilio
     */
    async telecomTwilioVoiceStatus(requestParameters: TelecomTwilioVoiceStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioVoiceStatusRaw(requestParameters, initOverrides);
    }

}
