/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureComponentPayload,
    StatusPageStructureComponentPayloadFromJSON,
    StatusPageStructureComponentPayloadFromJSONTyped,
    StatusPageStructureComponentPayloadToJSON,
} from './StatusPageStructureComponentPayload';
import {
    StatusPageStructureGroupPayload,
    StatusPageStructureGroupPayloadFromJSON,
    StatusPageStructureGroupPayloadFromJSONTyped,
    StatusPageStructureGroupPayloadToJSON,
} from './StatusPageStructureGroupPayload';

/**
 * 
 * @export
 * @interface StatusPageStructureItemPayload
 */
export interface StatusPageStructureItemPayload {
    /**
     * 
     * @type {StatusPageStructureComponentPayload}
     * @memberof StatusPageStructureItemPayload
     */
    component?: StatusPageStructureComponentPayload;
    /**
     * 
     * @type {StatusPageStructureGroupPayload}
     * @memberof StatusPageStructureItemPayload
     */
    group?: StatusPageStructureGroupPayload;
}

export function StatusPageStructureItemPayloadFromJSON(json: any): StatusPageStructureItemPayload {
    return StatusPageStructureItemPayloadFromJSONTyped(json, false);
}

export function StatusPageStructureItemPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureItemPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': !exists(json, 'component') ? undefined : StatusPageStructureComponentPayloadFromJSON(json['component']),
        'group': !exists(json, 'group') ? undefined : StatusPageStructureGroupPayloadFromJSON(json['group']),
    };
}

export function StatusPageStructureItemPayloadToJSON(value?: StatusPageStructureItemPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': StatusPageStructureComponentPayloadToJSON(value.component),
        'group': StatusPageStructureGroupPayloadToJSON(value.group),
    };
}

