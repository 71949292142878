/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkflowPayload,
    WorkflowPayloadFromJSON,
    WorkflowPayloadFromJSONTyped,
    WorkflowPayloadToJSON,
} from './WorkflowPayload';

/**
 * 
 * @export
 * @interface WorkflowsCreateWorkflowRequestBody
 */
export interface WorkflowsCreateWorkflowRequestBody {
    /**
     * Template that this workflow was created from (if it was)
     * @type {string}
     * @memberof WorkflowsCreateWorkflowRequestBody
     */
    created_from_template?: string;
    /**
     * Trigger to set on the workflow
     * @type {string}
     * @memberof WorkflowsCreateWorkflowRequestBody
     */
    trigger: string;
    /**
     * 
     * @type {WorkflowPayload}
     * @memberof WorkflowsCreateWorkflowRequestBody
     */
    workflow: WorkflowPayload;
}

export function WorkflowsCreateWorkflowRequestBodyFromJSON(json: any): WorkflowsCreateWorkflowRequestBody {
    return WorkflowsCreateWorkflowRequestBodyFromJSONTyped(json, false);
}

export function WorkflowsCreateWorkflowRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowsCreateWorkflowRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_from_template': !exists(json, 'created_from_template') ? undefined : json['created_from_template'],
        'trigger': json['trigger'],
        'workflow': WorkflowPayloadFromJSON(json['workflow']),
    };
}

export function WorkflowsCreateWorkflowRequestBodyToJSON(value?: WorkflowsCreateWorkflowRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_from_template': value.created_from_template,
        'trigger': value.trigger,
        'workflow': WorkflowPayloadToJSON(value.workflow),
    };
}

