import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceAppOpticsSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>Go to the AppOptics Alerts dashboard</p>
      <p>
        On the alert you want to send to incident.io, under{" "}
        <strong>Set Notifications</strong>, add an outbound service and choose{" "}
        <strong>Webhook</strong>.
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>In the create webhook dialog, fill in the details as follows:</>
        }
        blocks={[
          {
            title: "Title",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/app_optics/${
              alertSourceConfig.id
            }`,
          },
        ]}
      />
      <p>
        Click <strong>Save</strong>
      </p>
      <p>
        On the <strong>Webhook</strong> item, click the <strong>+</strong> and
        select the webhook you just created.
      </p>
      <p>
        Click <strong>Next</strong> and then <strong>Save</strong> the alert.
      </p>
      <p>
        You can test the alert by going to the alert in the dashboard, and
        clicking the <strong>Test fire</strong> button.
      </p>
    </SetupInfoNumberedList>
  );
};
