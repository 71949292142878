/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AICreateAssistantResponseFeedbackRequestBody
 */
export interface AICreateAssistantResponseFeedbackRequestBody {
    /**
     * Additional feedback in message form that was given.
     * @type {string}
     * @memberof AICreateAssistantResponseFeedbackRequestBody
     */
    feedback?: string;
    /**
     * Type of feedback that was given.
     * @type {string}
     * @memberof AICreateAssistantResponseFeedbackRequestBody
     */
    feedback_type: AICreateAssistantResponseFeedbackRequestBodyFeedbackTypeEnum;
    /**
     * ID of the ai_thread_question that was asked for this response.
     * @type {string}
     * @memberof AICreateAssistantResponseFeedbackRequestBody
     */
    question_id: string;
    /**
     * List of reasons why the feedback was given.
     * @type {Array<string>}
     * @memberof AICreateAssistantResponseFeedbackRequestBody
     */
    reasons?: Array<AICreateAssistantResponseFeedbackRequestBodyReasonsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum AICreateAssistantResponseFeedbackRequestBodyFeedbackTypeEnum {
    Positive = 'positive',
    Negative = 'negative'
}/**
* @export
* @enum {string}
*/
export enum AICreateAssistantResponseFeedbackRequestBodyReasonsEnum {
    ThisCalculationIsIncorrect = 'This calculation is incorrect',
    ThisGraphIsIncorrect = 'This graph is incorrect',
    ThisResponseIsntHelpful = 'This response isn\'t helpful',
    ThisResponseHarmful = 'This response harmful',
    IDontKnowWhatThisMeans = 'I don\'t know what this means',
    ThisResponseIsTooSlow = 'This response is too slow'
}

export function AICreateAssistantResponseFeedbackRequestBodyFromJSON(json: any): AICreateAssistantResponseFeedbackRequestBody {
    return AICreateAssistantResponseFeedbackRequestBodyFromJSONTyped(json, false);
}

export function AICreateAssistantResponseFeedbackRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AICreateAssistantResponseFeedbackRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
        'feedback_type': json['feedback_type'],
        'question_id': json['question_id'],
        'reasons': !exists(json, 'reasons') ? undefined : json['reasons'],
    };
}

export function AICreateAssistantResponseFeedbackRequestBodyToJSON(value?: AICreateAssistantResponseFeedbackRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedback': value.feedback,
        'feedback_type': value.feedback_type,
        'question_id': value.question_id,
        'reasons': value.reasons,
    };
}

