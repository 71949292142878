/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileKey
 */
export interface MobileKey {
    /**
     * The name of this device if it has one
     * @type {string}
     * @memberof MobileKey
     */
    device_name?: string;
    /**
     * Unique identifier for this mobile key
     * @type {string}
     * @memberof MobileKey
     */
    id: string;
    /**
     * When the key was last used to authenticate a request
     * @type {Date}
     * @memberof MobileKey
     */
    last_used_at?: Date;
    /**
     * What platform is this mobile
     * @type {string}
     * @memberof MobileKey
     */
    platform: MobileKeyPlatformEnum;
    /**
     * Whether push notifications are enabled
     * @type {boolean}
     * @memberof MobileKey
     */
    push_notifications_enabled: boolean;
}

/**
* @export
* @enum {string}
*/
export enum MobileKeyPlatformEnum {
    Ios = 'ios',
    Android = 'android',
    Windows = 'windows',
    Macos = 'macos',
    Web = 'web'
}

export function MobileKeyFromJSON(json: any): MobileKey {
    return MobileKeyFromJSONTyped(json, false);
}

export function MobileKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'device_name': !exists(json, 'device_name') ? undefined : json['device_name'],
        'id': json['id'],
        'last_used_at': !exists(json, 'last_used_at') ? undefined : (new Date(json['last_used_at'])),
        'platform': json['platform'],
        'push_notifications_enabled': json['push_notifications_enabled'],
    };
}

export function MobileKeyToJSON(value?: MobileKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_name': value.device_name,
        'id': value.id,
        'last_used_at': value.last_used_at === undefined ? undefined : (value.last_used_at.toISOString()),
        'platform': value.platform,
        'push_notifications_enabled': value.push_notifications_enabled,
    };
}

