import type { SVGProps } from "react";
import * as React from "react";
const SvgElastic = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#F4BD19"
      d="m8.147 8.744 3.789 1.735 3.83-3.358A4.276 4.276 0 0 0 11.576 2a4.251 4.251 0 0 0-3.526 1.858l-.638 3.303.735 1.582Z"
    />
    <path
      fill="#3CBEB1"
      d="M4.22 12.755a4.3 4.3 0 0 0 4.205 5.148c1.43 0 2.748-.708 3.553-1.874l.624-3.302-.846-1.61-3.802-1.735-3.733 3.373Z"
    />
    <path
      fill="#E9478C"
      d="m4.193 6.19 2.595.611.583-2.955a2.02 2.02 0 0 0-1.25-.417 2.058 2.058 0 0 0-1.928 2.762Z"
    />
    <path
      fill="#2C458F"
      d="M3.97 6.815C2.805 7.19 2 8.314 2 9.535c0 1.194.735 2.262 1.846 2.678l3.65-3.302-.667-1.43-2.859-.666Z"
    />
    <path
      fill="#95C63D"
      d="M12.643 16.016c.36.277.791.43 1.235.43a2.058 2.058 0 0 0 1.93-2.762l-2.596-.61-.569 2.942Z"
    />
    <path
      fill="#176655"
      d="m13.17 12.394 2.86.666A2.894 2.894 0 0 0 18 10.34a2.87 2.87 0 0 0-1.846-2.678l-3.746 3.275.763 1.457Z"
    />
  </svg>
);
export default SvgElastic;
