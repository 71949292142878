import type { SVGProps } from "react";
import * as React from "react";
const SvgAtlassianStatuspage = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#atlassian-statuspage_svg__a)"
      d="M10 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
    />
    <path
      fill="#2684FF"
      d="M2.091 7.559 4.1 9.855c.158.174.429.195.61.044 3.273-2.817 7.315-2.817 10.565 0 .181.151.452.13.61-.044l2.01-2.296a.403.403 0 0 0-.046-.585c-4.741-3.965-10.995-3.965-15.736 0a.474.474 0 0 0-.022.585Z"
    />
    <defs>
      <linearGradient
        id="atlassian-statuspage_svg__a"
        x1={10}
        x2={10}
        y1={14.63}
        y2={7.532}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2684FF" />
        <stop offset={0.824} stopColor="#0052CC" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgAtlassianStatuspage;
