import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemActionCreated = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.action_created) {
    throw new Error(
      "malformed timeline item: action_created was missing action_created field",
    );
  }

  return {
    title: "Action created",
    icon: IconEnum.Action,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.action_created.creator,
    quotedContent: (
      <Markdown>{item.content.action_created.action.description}</Markdown>
    ),
  };
};
