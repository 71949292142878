/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SAMLSettings,
    SAMLSettingsFromJSON,
    SAMLSettingsFromJSONTyped,
    SAMLSettingsToJSON,
} from './SAMLSettings';

/**
 * 
 * @export
 * @interface SAMLCreateResponseBody
 */
export interface SAMLCreateResponseBody {
    /**
     * 
     * @type {SAMLSettings}
     * @memberof SAMLCreateResponseBody
     */
    saml_settings: SAMLSettings;
}

export function SAMLCreateResponseBodyFromJSON(json: any): SAMLCreateResponseBody {
    return SAMLCreateResponseBodyFromJSONTyped(json, false);
}

export function SAMLCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saml_settings': SAMLSettingsFromJSON(json['saml_settings']),
    };
}

export function SAMLCreateResponseBodyToJSON(value?: SAMLCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saml_settings': SAMLSettingsToJSON(value.saml_settings),
    };
}

