import {
  CatalogResource,
  CatalogTypeAttributePayload,
  CatalogTypeAttributePayloadModeEnum as AttributePayloadModeEnum,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  CatalogResourceOption,
  getPrimitiveIcon,
} from "@incident-shared/catalog";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { PopoverSingleSelect } from "@incident-ui";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import { PopoverSelectOptions } from "@incident-ui/PopoverSelect";
import { chain, sortBy } from "lodash";

export const AddDataAttributesPopover = ({
  onAddAttribute,
  resources,
}: {
  onAddAttribute: (attr: CatalogTypeAttributePayload) => void;
  resources: CatalogResource[];
}): React.ReactElement | null => {
  const resourceOptions = buildDataAttributeOptions(resources);

  const addManualAttribute = (option: CatalogResourceOption) =>
    onAddAttribute({
      name: option.label,
      array: false,
      type: option.value,
      mode: AttributePayloadModeEnum.Manual,
    });

  return (
    <PopoverSingleSelect<true, true, CatalogResourceOption>
      options={resourceOptions}
      object
      align="start"
      popoverClassName="!min-w-[300px] !max-w-[300px] overflow-x-none"
      sideOffset={-38}
      onChange={(value) => addManualAttribute(value as CatalogResourceOption)}
      value={undefined}
      renderTriggerNode={({ onClick }) => (
        <Button
          onClick={onClick}
          analyticsTrackingId="catalog-schema-add-attribute"
          title="Add attribute"
          icon={IconEnum.Add}
          theme={ButtonTheme.Naked}
        >
          Add attribute
        </Button>
      )}
    />
  );
};

export const buildDataAttributeOptions = (
  resources: CatalogResource[],
  mode?: "catalog" | "engine",
): PopoverSelectOptions<CatalogResourceOption> => {
  if (!mode) {
    mode = "catalog";
  }

  return chain(resources)
    .groupBy((res) => res.category)
    .map((resources, category) => {
      const sortedResources = sortBy(resources, "label");
      return {
        label: category === "primitive" ? "Primitive" : "Reference",
        options: sortedResources.map((resource) => {
          const icon = resource.icon || getPrimitiveIcon(resource.type);
          return {
            label: resource.label,
            value: resource.type,
            resource: resource,
            render: ({ onClick }) => (
              <PopoverItem onClick={onClick} className="p-1">
                <CatalogEntryBadge
                  color={resource.color}
                  label={resource.label}
                  icon={icon}
                  className="cursor-pointer max-w-full"
                />
              </PopoverItem>
            ),
          };
        }),
      };
    })
    .value();
};
