import { Mode } from "@incident-shared/forms/v2/formsv2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { LoadingModal } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import React from "react";
import { useParams } from "react-router";

import { useAPI } from "../../../../utils/swr";
import { SeverityCreateEditModal } from "../common/SeverityCreateEditModal";

export const SeveritiesEditPage = (): React.ReactElement => {
  const { id } = useParams() as { id: string };
  const navigate = useOrgAwareNavigate();

  const { data, isLoading, error } = useAPI("severitiesShow", {
    id: id,
  });
  const severity = data?.severity;

  const onClose = () => navigate("/settings/severities");
  if (error) {
    return <ErrorModal onClose={onClose} />;
  }
  if (isLoading || !severity) {
    return <LoadingModal onClose={onClose} />;
  }

  return <SeverityCreateEditModal mode={Mode.Edit} initialData={severity} />;
};
