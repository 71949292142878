import {
  IncidentDurationMetric,
  IncidentStatusCategoryEnum,
  IncidentTimestamp,
  IncidentTimestampTimestampTypeEnum,
} from "src/contexts/ClientContext";

export const TriageIncidentTimestamps: IncidentTimestampTimestampTypeEnum[] = [
  IncidentTimestampTimestampTypeEnum.AcceptedAt,
  IncidentTimestampTimestampTypeEnum.DeclinedAt,
  IncidentTimestampTimestampTypeEnum.MergedAt,
];

export const TriageIncidentStatuses: IncidentStatusCategoryEnum[] = [
  IncidentStatusCategoryEnum.Triage,
  IncidentStatusCategoryEnum.Declined,
  IncidentStatusCategoryEnum.Merged,
];

export function hideTriageMetrics(
  timestamps: IncidentTimestamp[],
  metrics: IncidentDurationMetric[] | null,
): IncidentDurationMetric[] {
  const visibleMetrics = metrics?.filter((metric) => {
    const fromTimestamp = timestamps.find(
      (timestamp) => timestamp.id === metric.from_timestamp_id,
    );
    const toTimestamp = timestamps.find(
      (timestamp) => timestamp.id === metric.to_timestamp_id,
    );
    // If we can't find either of the timestamps at all, hide the metric
    if (!fromTimestamp || !toTimestamp) {
      return false;
    }
    // Otherwise, hide the metric if it involves a triage timestamp
    return (
      !TriageIncidentTimestamps.includes(fromTimestamp.timestamp_type) &&
      !TriageIncidentTimestamps.includes(toTimestamp.timestamp_type)
    );
  });
  return visibleMetrics || [];
}
