import { OrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { Route, Routes } from "react-router";

import { IncidentEscalateFormsEditPage } from "./edit/escalate/IncidentEscalateFormsEditPage";
import { IncidentLifecycleFormsEditPage } from "./edit/lifecycle/IncidentLifecycleFormsEditPage";
import { IncidentFormsListPage } from "./list/IncidentFormsListPage";

export const IncidentFormsRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<IncidentFormsListPage />} />
      <Route
        path="/lifecycle/:id"
        element={<IncidentLifecycleFormsEditPage />}
      />
      <Route path="/escalate/:id" element={<IncidentEscalateFormsEditPage />} />
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};
