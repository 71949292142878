/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentTimelineElement,
    IncidentTimelineElementFromJSON,
    IncidentTimelineElementFromJSONTyped,
    IncidentTimelineElementToJSON,
} from './IncidentTimelineElement';

/**
 * 
 * @export
 * @interface GroupedIncidentTimelineElement
 */
export interface GroupedIncidentTimelineElement {
    /**
     * Date grouping the timeline items
     * @type {Date}
     * @memberof GroupedIncidentTimelineElement
     */
    date: Date;
    /**
     * 
     * @type {Array<IncidentTimelineElement>}
     * @memberof GroupedIncidentTimelineElement
     */
    items: Array<IncidentTimelineElement>;
}

export function GroupedIncidentTimelineElementFromJSON(json: any): GroupedIncidentTimelineElement {
    return GroupedIncidentTimelineElementFromJSONTyped(json, false);
}

export function GroupedIncidentTimelineElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupedIncidentTimelineElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'items': ((json['items'] as Array<any>).map(IncidentTimelineElementFromJSON)),
    };
}

export function GroupedIncidentTimelineElementToJSON(value?: GroupedIncidentTimelineElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'items': ((value.items as Array<any>).map(IncidentTimelineElementToJSON)),
    };
}

