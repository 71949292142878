import type { SVGProps } from "react";
import * as React from "react";
const SvgMapIcon = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m6.5 3.149-3.129.695A1.74 1.74 0 0 0 2 5.552v8.45c0 .535.239 1.032.655 1.366.416.334.953.46 1.475.343l2.371-.526L6.5 3.149ZM12 4.679 8 3.224V15.32l4 1.455V4.679ZM17.345 4.632a1.737 1.737 0 0 0-1.475-.343l-2.371.526V16.85l3.129-.695a1.74 1.74 0 0 0 1.371-1.708V5.997c0-.534-.238-1.031-.654-1.365Z"
    />
  </svg>
);
export default SvgMapIcon;
