/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianUpdateInfo,
    AtlassianUpdateInfoFromJSON,
    AtlassianUpdateInfoFromJSONTyped,
    AtlassianUpdateInfoToJSON,
} from './AtlassianUpdateInfo';
import {
    RetrospectiveUpdateInfo,
    RetrospectiveUpdateInfoFromJSON,
    RetrospectiveUpdateInfoFromJSONTyped,
    RetrospectiveUpdateInfoToJSON,
} from './RetrospectiveUpdateInfo';
import {
    StatusPageAffectedComponentPayload,
    StatusPageAffectedComponentPayloadFromJSON,
    StatusPageAffectedComponentPayloadFromJSONTyped,
    StatusPageAffectedComponentPayloadToJSON,
} from './StatusPageAffectedComponentPayload';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageCreateIncidentUpdateRequestBody
 */
export interface StatusPageCreateIncidentUpdateRequestBody {
    /**
     * 
     * @type {AtlassianUpdateInfo}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    atlassian_update_info?: AtlassianUpdateInfo;
    /**
     * The updated statuses of affected components
     * @type {Array<StatusPageAffectedComponentPayload>}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    component_statuses: Array<StatusPageAffectedComponentPayload>;
    /**
     * The ID to the response incident this update was created from
     * @type {string}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    created_from_response_incident_id?: string;
    /**
     * The ID of the template that was applied when sending this update
     * @type {string}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    created_from_template_id?: string;
    /**
     * Whether or not the status was manually updated
     * @type {boolean}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    manual_status_update?: boolean;
    /**
     * The IDs of subpages that this incident should be linked to. When `null` or empty, the sub-page will be inferred from the components.
     * @type {Array<string>}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    manual_sub_page_ids?: Array<string>;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    message: TextNode;
    /**
     * A title for the incident
     * @type {string}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    name?: string;
    /**
     * 
     * @type {RetrospectiveUpdateInfo}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    retrospective_update_info?: RetrospectiveUpdateInfo;
    /**
     * Order of this update among others. This must be unique within the incident.
     * @type {number}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    sort_key: number;
    /**
     * The ID of the status page incident this relates to
     * @type {string}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    status_page_incident_id: string;
    /**
     * If true, don't notify subscribers of this update
     * @type {boolean}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    suppress_notifications: boolean;
    /**
     * The new status of this incident
     * @type {string}
     * @memberof StatusPageCreateIncidentUpdateRequestBody
     */
    to_status: StatusPageCreateIncidentUpdateRequestBodyToStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageCreateIncidentUpdateRequestBodyToStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function StatusPageCreateIncidentUpdateRequestBodyFromJSON(json: any): StatusPageCreateIncidentUpdateRequestBody {
    return StatusPageCreateIncidentUpdateRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateIncidentUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateIncidentUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atlassian_update_info': !exists(json, 'atlassian_update_info') ? undefined : AtlassianUpdateInfoFromJSON(json['atlassian_update_info']),
        'component_statuses': ((json['component_statuses'] as Array<any>).map(StatusPageAffectedComponentPayloadFromJSON)),
        'created_from_response_incident_id': !exists(json, 'created_from_response_incident_id') ? undefined : json['created_from_response_incident_id'],
        'created_from_template_id': !exists(json, 'created_from_template_id') ? undefined : json['created_from_template_id'],
        'manual_status_update': !exists(json, 'manual_status_update') ? undefined : json['manual_status_update'],
        'manual_sub_page_ids': !exists(json, 'manual_sub_page_ids') ? undefined : json['manual_sub_page_ids'],
        'message': TextNodeFromJSON(json['message']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'retrospective_update_info': !exists(json, 'retrospective_update_info') ? undefined : RetrospectiveUpdateInfoFromJSON(json['retrospective_update_info']),
        'sort_key': json['sort_key'],
        'status_page_incident_id': json['status_page_incident_id'],
        'suppress_notifications': json['suppress_notifications'],
        'to_status': json['to_status'],
    };
}

export function StatusPageCreateIncidentUpdateRequestBodyToJSON(value?: StatusPageCreateIncidentUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'atlassian_update_info': AtlassianUpdateInfoToJSON(value.atlassian_update_info),
        'component_statuses': ((value.component_statuses as Array<any>).map(StatusPageAffectedComponentPayloadToJSON)),
        'created_from_response_incident_id': value.created_from_response_incident_id,
        'created_from_template_id': value.created_from_template_id,
        'manual_status_update': value.manual_status_update,
        'manual_sub_page_ids': value.manual_sub_page_ids,
        'message': TextNodeToJSON(value.message),
        'name': value.name,
        'retrospective_update_info': RetrospectiveUpdateInfoToJSON(value.retrospective_update_info),
        'sort_key': value.sort_key,
        'status_page_incident_id': value.status_page_incident_id,
        'suppress_notifications': value.suppress_notifications,
        'to_status': value.to_status,
    };
}

