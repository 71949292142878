import {
  Alert,
  AlertHeadlineMetricTypeEnum,
  AlertHeadlineMetricUnitEnum,
  AlertsGetInsightsResponseBody,
  IncidentSlim,
  IncidentSlimStatusCategoryEnum,
} from "@incident-io/api";
import { GradientBar } from "@incident-shared/insights/LoadingTrendsTiles";
import { TrendTheme } from "@incident-shared/insights/TrendTile";
import { BadgeSize, Button, ButtonTheme } from "@incident-ui";
import { BarChart } from "@incident-ui/Chart/BarChart";
import _ from "lodash";
import { tcx } from "src/utils/tailwind-classes";
import { getColor } from "src/utils/twConfig";

import { formatValueWithUnit } from "./utils";

type WorkloadData = {
  id: string;
  label: string;
  real: string;
  declined: string;
  relevantIncidents: IncidentSlim[];
};

const workloadChartConfig = {
  real: {
    label: "Accepted incidents",
    color: getColor("alarmalade", "200"),
    background: "bg-alarmalade-200",
  },
  declined: {
    label: "Declined incidents",
    color: getColor("alarmalade", "500"),
    background: "bg-alarmalade-500",
  },
};

export const WorkloadChart = ({
  alert,
  data,
  lastSyncAt,
}: {
  alert: Alert;
  data?: AlertsGetInsightsResponseBody;
  lastSyncAt?: Date;
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const datapoints =
    data?.workload.datapoints.map((data): WorkloadData => {
      return {
        id: data.date.toISOString(),
        // format label as Tue 3rd Dec
        label: data.date.toLocaleDateString("en-GB", {
          timeZone: timezone,
          weekday: "short",
          day: "numeric",
          month: "short",
        }),
        real: Number.isInteger(data.real_incident_workload)
          ? data.real_incident_workload.toString()
          : data.real_incident_workload.toFixed(2),
        declined: Number.isInteger(data.declined_incident_workload)
          ? data.declined_incident_workload.toString()
          : data.declined_incident_workload.toFixed(2),
        relevantIncidents: data.relevant_incidents,
      };
    }) || [];

  const totalWorkload = data?.headline_metrics.find(
    (metric) =>
      metric.type === AlertHeadlineMetricTypeEnum.WorkloadTotalIncidents,
  );
  const totalIncidents = data?.headline_metrics.find(
    (metric) => metric.type === AlertHeadlineMetricTypeEnum.NumberIncidents,
  );

  // If your alert was created before the last sync, and there is no workload data, just show the empty graph
  // Otherwise:
  // - If you have no workload, but you have incidents, say to check back later
  // - If you have no workload and no incidents, say there are no incidents created from this alert
  const emptyStateMessage = getEmptyStateMessage(
    alert,
    lastSyncAt,
    totalIncidents,
    totalWorkload,
  );

  if (!data) {
    return <LoadingWorkloadChart />;
  }

  return (
    <div
      className={
        "flex flex-col p-4 gap-4 rounded-2 border border-stroke-primary"
      }
    >
      <div className={"flex flex-col gap-1"}>
        <span className={"text-xs-med"}>Workload over time</span>
        <span className={"leading-normal text-base-bold"}>
          {formatValueWithUnit(
            totalWorkload?.value || 0,
            totalWorkload?.unit || AlertHeadlineMetricUnitEnum.Hour,
          )}
        </span>
      </div>
      <BarChart<WorkloadData>
        dataKeys={["real", "declined"]}
        data={datapoints}
        chartConfig={workloadChartConfig}
        containerClassName={"w-full max-h-[108px]"}
        cartesianGridProps={{
          vertical: false,
          horizontal: true,
          stroke: getColor("slate", "50"),
        }}
        barProps={{
          minPointSize: 1,
          defaultFill:
            totalIncidents?.value === 0
              ? getColor("slate", "200")
              : workloadChartConfig.real.color,
        }}
        renderTooltipContent={(data) => <WorkloadTooltip data={data} />}
        emptyStateMessage={emptyStateMessage}
      />
    </div>
  );
};

const WorkloadTooltip = ({ data }: { data: WorkloadData }) => {
  const incidents = _.sortBy(
    data.relevantIncidents || [],
    (incident) =>
      incident.status_category === IncidentSlimStatusCategoryEnum.Declined,
  );
  return (
    <div className="w-[360px] flex flex-col">
      <div className="p-3 flex flex-col gap-2">
        <div className="text-xs-bold">{data.label}</div>
        <div className="flex w-full gap-2 text-xs-med text-content-secondary">
          <div className="flex items-center gap-2 grow">
            <div
              className={tcx(
                "w-2 h-2 shrink-0 rounded-[2px]",
                workloadChartConfig.real.background,
              )}
            />
            <div>{`Accepted (${formatValueWithUnit(
              parseFloat(data.real),
              AlertHeadlineMetricUnitEnum.Hour,
            )})`}</div>
          </div>
          <div className="flex items-center gap-2 grow">
            <div
              className={tcx(
                "w-2 h-2 shrink-0 rounded-[2px]",
                workloadChartConfig.declined.background,
              )}
            />
            <div>{`Declined (${formatValueWithUnit(
              parseFloat(data.declined),
              AlertHeadlineMetricUnitEnum.Hour,
            )})`}</div>
          </div>
        </div>
      </div>
      {data.relevantIncidents.length ? (
        <div className="p-1 border-t border-stroke">
          {incidents.map((inc) => {
            return (
              <Button
                key={inc.id}
                href={`/incidents/${inc.id}`}
                analyticsTrackingId="alert-insights-view-incident"
                size={BadgeSize.Small}
                className={tcx(
                  "!justify-start w-full gap-2 px-2 py-[6px]",
                  "hover:bg-surface-secondary transition rounded",
                  "text-xs-med text-content-primary",
                )}
                theme={ButtonTheme.Naked}
              >
                <div
                  className={tcx(
                    "w-2 h-2 shrink-0 rounded-[2px]",
                    inc.status_category ===
                      IncidentSlimStatusCategoryEnum.Declined
                      ? workloadChartConfig.declined.background
                      : workloadChartConfig.real.background,
                  )}
                />
                <div className="truncate">
                  INC-{inc.external_id} {inc.name}
                </div>
              </Button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const LoadingWorkloadChart = () => {
  return (
    <div
      className={
        "flex flex-col p-4 gap-4 rounded-xl border border-stroke-primary"
      }
    >
      <div className={"flex flex-col gap-1 animate-pulse"}>
        <div className={"w-[181px]"}>
          <GradientBar theme={TrendTheme.Grey} />
        </div>
        <div className={"w-[156px] h-6"}>
          <GradientBar theme={TrendTheme.Grey} taller stopPoint="to-80%" />
        </div>
      </div>
      <BarChart
        dataKeys={["real", "declined"]}
        data={[
          {
            id: "loading",
            label: "loading",
            real: 0,
            declined: 0,
          },
        ]}
        chartConfig={workloadChartConfig}
        containerClassName={"w-full max-h-[108px]"}
        cartesianGridProps={{
          vertical: false,
          horizontal: true,
          stroke: getColor("slate", "50"),
        }}
      />
    </div>
  );
};

/**
 * Determines the appropriate empty state message for an alert's workload graph
 *
 * Rules:
 * 1. If we have synced and there's no workload data, return an empty state so that we show an empty graph
 * 2. If there are incidents but no workload data, show "check back later"
 * 3. If there are no incidents, show "no incidents created"
 */
export const getEmptyStateMessage = (
  alert: Alert,
  lastSyncAt: Date | undefined,
  totalIncidents: { value: number } | undefined,
  totalWorkload: { value: number } | undefined,
): string | undefined => {
  // Default to 0 if metrics are null
  const incidentCount = totalIncidents?.value || 0;
  const workloadCount = totalWorkload?.value || 0;

  // We've synced but have no workload, show empty graph
  if (lastSyncAt && alert.created_at < lastSyncAt && workloadCount === 0) {
    return undefined;
  }

  // For recent alerts with incidents but no workload, show "check back"
  if (
    lastSyncAt &&
    incidentCount > 0 &&
    workloadCount === 0 &&
    alert.created_at >= lastSyncAt
  ) {
    return "No data yet. Check back later";
  }

  // Default case: no incidents created from this alert
  return "No incidents created from this alert";
};
