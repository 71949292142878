import { IconEnum } from "@incident-ui/Icon/Icon";
import { Input, InputProps, InputType } from "@incident-ui/Input/Input";
import { createContext, ReactNode, useContext, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

export const SearchBar = ({
  className,
  inputClassName,
  onChange,
  id,
  ...props
}: {
  id?: string;
  value: string;
  onChange: (newValue: string) => void;
  inputClassName?: string;
} & Omit<InputProps, "onChange" | "id">): React.ReactElement | null => {
  return (
    <div className={tcx("w-[250px]", className)}>
      <Input
        id={id ?? "search-bar"}
        type={InputType.Search}
        iconName={IconEnum.Search}
        onChange={(e) => onChange(e.currentTarget.value)}
        className={inputClassName}
        {...props}
      />
    </div>
  );
};

const SearchContext = createContext<{
  value: string;
  onChange: (value: string) => void;
}>({
  value: "",
  onChange: () => void 0,
});

export const SearchProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const [search, setSearch] = useState("");

  return (
    <SearchContext.Provider
      value={{
        onChange: setSearch,
        value: search,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  return useContext(SearchContext);
};
