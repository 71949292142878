import {
  CopilotRequest,
  useAiStaffServiceAiStaffListCopilotRequests,
} from "@incident-io/query-api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
} from "@incident-ui";
import { StackedList } from "@incident-ui/StackedList/StackedList";
import { uniq } from "lodash";
import { useState } from "react";
import { useForm, useFormContext, UseFormReturn } from "react-hook-form";
import { useClipboard } from "src/utils/useClipboard";

import { YAMLViewer } from "./YamlViewer";

export type AIFilterValues = {
  prompt: string;
  trace: string;
  interaction: string;
  thread: string;
};

export const AIRequestsList = () => {
  const formMethods = useForm<AIFilterValues>({
    defaultValues: {},
  });
  const filters = formMethods.watch();
  const { data, isLoading } = useAiStaffServiceAiStaffListCopilotRequests({
    traceId: filters.trace ?? undefined,
    prompt: filters.prompt ?? undefined,
    threadId: filters.thread ?? undefined,
    copilotInteractionId: filters.interaction ?? undefined,
  });

  const requests = data?.requests ?? [];

  const [filterByText, setFilterByText] = useState(false);

  const { data: allRequestsData } =
    useAiStaffServiceAiStaffListCopilotRequests();
  const promptOptions = uniq(
    (allRequestsData?.requests ?? []).map((req) => req.prompt),
  )
    .sort()
    .map((prompt) => ({
      value: prompt,
      label: prompt,
    }));

  return (
    <Form.Root formMethods={formMethods} onSubmit={() => null}>
      <h2 className="text-lg font-semibold mt-8">
        Recent non-interactive requests
      </h2>
      <FilterRequestsInput
        formMethods={formMethods}
        setFilterByText={setFilterByText}
        filterByText={filterByText}
        promptOptions={promptOptions}
      />
      <LoadingWrapper loading={isLoading}>
        {requests.length === 0 ? (
          <EmptyState icon={IconEnum.Filter} content="No requests found" />
        ) : (
          <>
            <StackedList>
              {requests.map((req) => (
                <AIEvalRow key={req.id} request={req} />
              ))}
            </StackedList>
          </>
        )}
      </LoadingWrapper>
    </Form.Root>
  );
};

export const FilterRequestsInput = ({
  formMethods,
  filterByText,
  setFilterByText,
  promptOptions,
}: {
  formMethods: UseFormReturn<AIFilterValues, undefined>;
  filterByText: boolean;
  setFilterByText: (value: boolean) => void;
  promptOptions: { value: string; label: string }[];
}): React.ReactElement => {
  return (
    <div className="py-4 w-full flex gap-4">
      {filterByText ? (
        <InputV2
          formMethods={formMethods}
          name="prompt"
          placeholder="Prompt name (e.g. PromptAlertName)"
          className="min-w-[400px]"
        />
      ) : (
        <StaticMultiSelectV2
          formMethods={formMethods}
          className="min-w-[400px]"
          options={promptOptions}
          name="prompt"
          placeholder="Prompt"
          isClearable
        />
      )}
      <Button
        analyticsTrackingId={null}
        theme={ButtonTheme.Naked}
        onClick={() => setFilterByText(!filterByText)}
      >
        {filterByText
          ? "Use dropdown to choose prompt"
          : "Filter by text instead"}
      </Button>
    </div>
  );
};

const AIEvalRow = ({ request }: { request: CopilotRequest }) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();
  const { setValue, watch } = useFormContext<AIFilterValues>();
  const [expanded, setExpanded] = useState(false);

  const filters = watch();

  const filteredByPrompt = !!filters.prompt;
  const filteredByTrace = !!filters.trace;

  return (
    <div className="flex flex-col gap-4 px-4">
      <div className="flex flex-between gap-2 w-full flex-wrap overflow-hidden">
        <div className="flex gap-6 py-4">
          <LocalDateTime
            timestamp={request.created_at}
            className="font-medium"
          />
          {!filteredByPrompt && (
            <div className="flex gap-0.5 font-mono">
              {request.prompt}
              <Button
                theme={ButtonTheme.Naked}
                icon={IconEnum.Filter}
                analyticsTrackingId={null}
                type="button"
                title="Filter"
                onClick={() => setValue<"prompt">("prompt", request.prompt)}
                iconProps={{ className: "size-4" }}
                disabled={filteredByPrompt}
              />
              <Button
                theme={ButtonTheme.Naked}
                icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
                analyticsTrackingId={null}
                type="button"
                title="Copy to clipboard"
                onClick={() => copyTextToClipboard(request.prompt)}
                iconProps={{ className: "size-4" }}
              />
            </div>
          )}
          {!filteredByTrace && (
            <div className="flex gap-0.5 font-mono">
              {request.trace}
              <Button
                theme={ButtonTheme.Naked}
                icon={IconEnum.Filter}
                analyticsTrackingId={null}
                type="button"
                title="Filter"
                onClick={() => setValue<"trace">("trace", request.trace)}
                iconProps={{ className: "size-4" }}
                disabled={filteredByTrace}
              />
              <Button
                theme={ButtonTheme.Naked}
                icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
                analyticsTrackingId={null}
                type="button"
                title="Copy to clipboard"
                onClick={() => copyTextToClipboard(request.trace)}
                iconProps={{ className: "size-4" }}
              />
            </div>
          )}
          {request.incident && (
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              href={"/incidents/" + request.incident}
            >
              INC-{request.incident}
            </Button>
          )}
        </div>
        <div className="grow" />
        {!!request.duration_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Timer} size={IconSize.Small} />
            {request.duration_seconds.toFixed(2)}s
          </div>
        )}
        {!!request.cost_cents && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(request.cost_cents / 100.0).toFixed(2)}
          </div>
        )}
        <Button
          theme={ButtonTheme.Naked}
          icon={expanded ? IconEnum.Collapse : IconEnum.Expand}
          onClick={() => setExpanded((expanded) => !expanded)}
          analyticsTrackingId={null}
          title="Expand"
          className="py-4"
        />
      </div>
      {/* Expandable Section */}
      {expanded && (
        <div>
          <YAMLViewer yaml={request.eval} />
        </div>
      )}
    </div>
  );
};
