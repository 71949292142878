/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface SummaryUpdate
 */
export interface SummaryUpdate {
    /**
     * 
     * @type {Actor}
     * @memberof SummaryUpdate
     */
    actor: Actor;
    /**
     * 
     * @type {TextDocument}
     * @memberof SummaryUpdate
     */
    from?: TextDocument;
    /**
     * was the summary previously unset
     * @type {boolean}
     * @memberof SummaryUpdate
     */
    previously_unset: boolean;
    /**
     * 
     * @type {TextDocument}
     * @memberof SummaryUpdate
     */
    to?: TextDocument;
}

export function SummaryUpdateFromJSON(json: any): SummaryUpdate {
    return SummaryUpdateFromJSONTyped(json, false);
}

export function SummaryUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'from': !exists(json, 'from') ? undefined : TextDocumentFromJSON(json['from']),
        'previously_unset': json['previously_unset'],
        'to': !exists(json, 'to') ? undefined : TextDocumentFromJSON(json['to']),
    };
}

export function SummaryUpdateToJSON(value?: SummaryUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'from': TextDocumentToJSON(value.from),
        'previously_unset': value.previously_unset,
        'to': TextDocumentToJSON(value.to),
    };
}

