import { Mode } from "@incident-shared/forms/v2/formsv2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconBadge,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import {
  CatalogEntry,
  CatalogResource,
  CatalogType,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { CatalogEntryCreateEditForm } from "../common/CatalogEntryCreateEditForm";

export const CatalogEntryEditDrawer = ({
  refetchEntries,
  onClose,
  entryReference,
}: {
  refetchEntries: () => void;
  onClose: () => void;
  // this can be the entryID or the externalID
  entryReference: string;
}) => {
  const {
    data: resourcesData,
    error: resourcesError,
    isLoading: resourcesIsLoading,
  } = useAPI("catalogListResources", undefined);

  const {
    data: catalogEntryData,
    error: catalogEntryError,
    isLoading: catalogEntryIsLoading,
  } = useAPI(entryReference ? "catalogShowEntry" : null, {
    id: entryReference,
    includeReferences: true,
    // We don't use these, but this means we hit the same SWR cache key as on
    // the view page
    includeDerivedAttributes: true,
  });

  if (resourcesError || catalogEntryError) {
    return <GenericErrorMessage error={resourcesError || catalogEntryError} />;
  }
  const loading =
    resourcesIsLoading ||
    catalogEntryIsLoading ||
    !resourcesData ||
    !catalogEntryData;

  return (
    <Drawer width="medium" onClose={onClose} warnWhenDirty>
      {loading ? (
        <Loader />
      ) : (
        <CatalogEntryEditDrawerContents
          catalogEntry={catalogEntryData.catalog_entry}
          catalogType={catalogEntryData.catalog_type}
          resources={resourcesData.resources}
          onCloseDrawer={onClose}
          refetchEntries={refetchEntries}
        />
      )}
    </Drawer>
  );
};

export const CatalogEntryEditDrawerContents = ({
  catalogEntry,
  catalogType,
  resources,
  onCloseDrawer,
  refetchEntries,
}: {
  catalogEntry: CatalogEntry;
  catalogType: CatalogType;
  resources: CatalogResource[];
  onCloseDrawer: () => void;
  refetchEntries: () => void;
}): React.ReactElement => {
  return (
    <DrawerContents>
      <DrawerTitle
        compact
        title={`Edit ${catalogEntry.name}`}
        onClose={onCloseDrawer}
        titleAccessory={
          <IconBadge
            size={IconSize.Small}
            icon={catalogType.icon}
            color={catalogType.color as unknown as ColorPaletteEnum}
            invert
          />
        }
        secondaryAccessory={
          catalogEntry.native_resource_path ? (
            <Button
              analyticsTrackingId={null}
              icon={IconEnum.ExternalLink}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Medium}
              href={catalogEntry.native_resource_path}
              openInNewTab
            >
              Go to {catalogType.name}
            </Button>
          ) : undefined
        }
      />
      <CatalogEntryCreateEditForm
        mode={Mode.Edit}
        initialData={catalogEntry}
        onClose={onCloseDrawer}
        catalogType={catalogType}
        resources={resources}
        refetchEntries={refetchEntries}
      />
    </DrawerContents>
  );
};
