/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssueTrackersJiraCreateIssueRequestBody
 */
export interface IssueTrackersJiraCreateIssueRequestBody {
    /**
     * Jira user ID to assign to the issue
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    assignee_id?: string;
    /**
     * If true, the Jira issue for the incident will be connected to the follow-up issue as its parent
     * @type {boolean}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    connect_to_parent_issue: boolean;
    /**
     * Description body of the Jira issue
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    description?: string;
    /**
     * Dynamic field keys mapped to values
     * @type {object}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    dynamic_fields?: object;
    /**
     * ID of the follow-up that we're exporting this issue from
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    follow_up_id: string;
    /**
     * The machine readable ID for the issue type
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    issue_type_id: string;
    /**
     * The project ID in Jira in which to create this issue
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    project_id: string;
    /**
     * Jira user ID to mark as the reporter
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    reporter_id?: string;
    /**
     * The ID of the Jira Cloud instance to use
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    site_id: string;
    /**
     * Title of the Jira issue
     * @type {string}
     * @memberof IssueTrackersJiraCreateIssueRequestBody
     */
    title: string;
}

export function IssueTrackersJiraCreateIssueRequestBodyFromJSON(json: any): IssueTrackersJiraCreateIssueRequestBody {
    return IssueTrackersJiraCreateIssueRequestBodyFromJSONTyped(json, false);
}

export function IssueTrackersJiraCreateIssueRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackersJiraCreateIssueRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'connect_to_parent_issue': json['connect_to_parent_issue'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dynamic_fields': !exists(json, 'dynamic_fields') ? undefined : json['dynamic_fields'],
        'follow_up_id': json['follow_up_id'],
        'issue_type_id': json['issue_type_id'],
        'project_id': json['project_id'],
        'reporter_id': !exists(json, 'reporter_id') ? undefined : json['reporter_id'],
        'site_id': json['site_id'],
        'title': json['title'],
    };
}

export function IssueTrackersJiraCreateIssueRequestBodyToJSON(value?: IssueTrackersJiraCreateIssueRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': value.assignee_id,
        'connect_to_parent_issue': value.connect_to_parent_issue,
        'description': value.description,
        'dynamic_fields': value.dynamic_fields,
        'follow_up_id': value.follow_up_id,
        'issue_type_id': value.issue_type_id,
        'project_id': value.project_id,
        'reporter_id': value.reporter_id,
        'site_id': value.site_id,
        'title': value.title,
    };
}

