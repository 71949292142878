import { Route, Routes } from "react-router";

import { SetupMsTeamsLoginPage } from "./SetupMsTeamsLogin";

export const SetupMsTeamsLoginUnauthenticatedRoute = () => {
  return (
    <Routes>
      <Route path="" element={<SetupMsTeamsLoginPage />} />
    </Routes>
  );
};
