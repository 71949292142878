import {
  ContentBox,
  Heading,
  Loader,
  StepsPageContent,
  StepsPageFooter,
} from "@incident-ui";
import { areIntervalsOverlapping } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { SchedulePayConfig } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { v4 as uuidv4 } from "uuid";

import { formatCurrency } from "../../../utils/currency";
import {
  OverrideData,
  YearlyCalendar,
} from "../../on-call-legacy/common/calendar/YearlyCalendar";
import { fromLocalDateObj } from "./pay-configurations/date-time-helpers";
import {
  overrideFormTypeToInterval,
  OverrideModal,
} from "./pay-configurations/OnCallPayOverrideModal";
import { OverridesList } from "./pay-configurations/OnCallPayOverridesEditor";
import {
  OverrideModalMultiPayConfig,
  OverrideMultiInputFormType,
} from "./pay-configurations/OverrideModalMultiPayConfig";
import {
  marshallConfigToOverrideFormType,
  marshallOverrideFormTypeToConfig,
  OverrideFormType,
} from "./pay-configurations/PayConfigCreateEditForm";
import { SerialisedScheduleWithPayConfig } from "./ReportGenerator";

// ReportGeneratorConfirmHolidays allows you to confirm any holidays where
// you support pay overrides against the pay config.
// We iterate through all the pay configs that you're using in this report,
// and get you to confirm that you're happy with the configuration.

// It starts by displaying all the holidays you've already got configured
// against your pay config.
// We store a whole load of data in state in this component (yay us) - you can think of
// these as a TODO list of all the payconfig changes we need to make. When you press
// continue, we first update all the relevant pay configs with all your overrides.
// Then, we either show the next pay config, or we create the report!
export const ReportGeneratorConfirmHolidays = ({
  startDate,
  endDate,
  selectedSchedules,
  onBack,
  onContinue,
}: {
  startDate?: Date;
  endDate?: Date;
  selectedSchedules: SerialisedScheduleWithPayConfig[];
  onBack: () => void;
  onContinue: () => void;
}): React.ReactElement => {
  if (startDate == null || endDate == null) {
    throw new Error(
      "unreachable: should always have start and end dates when confirming holidays",
    );
  }
  if (selectedSchedules.some((ea) => ea.pay_config == null)) {
    throw new Error(
      "unreachable: there's a selected schedule with no pay configuration attached!",
    );
  }

  const selectedPayConfigs = _.uniq(
    selectedSchedules.map((x) => x.pay_config),
  ) as SchedulePayConfig[];

  if (selectedPayConfigs.length === 0) {
    throw new Error(
      "unreachable: should always have at least one selected pay configuration",
    );
  }

  const { trigger: updatePayConfigs, isMutating: saving } = useAPIMutation(
    "schedulesListPayConfig",
    undefined,
    async (apiClient, data: ConfirmHolidayFormType) => {
      // forEach payConfig, we need to make an API call.
      await Promise.all(
        selectedPayConfigs.map((payConfig) => {
          return apiClient.schedulesUpdatePayConfig({
            id: payConfig.id,
            // @ts-expect-error it's getting in a tizz because the weekday enums look different,
            // but they actually aren't I promise.
            updatePayConfigRequestBody: {
              ...payConfig,
              overrides: marshallOverrideFormTypeToConfig(data[payConfig.id]),
            },
          });
        }),
      );
    },
    {
      onSuccess: onContinue,
    },
  );

  return (
    <ConfirmHolidaysForPayConfig
      startDate={startDate}
      endDate={endDate}
      payConfigs={selectedPayConfigs}
      onBack={onBack}
      onContinue={updatePayConfigs}
      saving={saving}
    />
  );
};

type ConfirmHolidayFormType = {
  [payConfigID: string]: OverrideFormType[];
};

const ConfirmHolidaysForPayConfig = ({
  startDate,
  endDate,
  payConfigs,
  onBack,
  onContinue,
  saving,
}: {
  startDate: Date;
  endDate: Date;
  payConfigs: SchedulePayConfig[];
  onBack: () => void;
  onContinue: (data: ConfirmHolidayFormType) => void;
  saving: boolean;
}): React.ReactElement => {
  const [overridesByPayConfig, setOverridesByPayConfig] =
    useState<ConfirmHolidayFormType | null>(null);

  const [addHolidayFromCalendarDate, setAddHolidayFromCalendarDate] =
    useState<Date | null>(null);

  // ------ Handle per-pay-config override lists
  const [showEditOverrideModal, setShowEditOverrideModal] =
    useState<boolean>(false);
  const [[editingPayConfig, editingOverride], setEditingOverrideInfo] =
    useState<[SchedulePayConfig, OverrideFormType] | [null, null]>([
      null,
      null,
    ]);

  useEffect(() => {
    setOverridesByPayConfig((prev) => {
      if (prev != null) return prev;

      const overrideLookup = {};
      payConfigs.forEach((config) => {
        overrideLookup[config.id] = marshallConfigToOverrideFormType(
          config.overrides,
        );
      });
      return overrideLookup;
    });
  }, [setOverridesByPayConfig, payConfigs]);

  const onDeleteOverride = (
    payConfigId: string,
    override: OverrideFormType,
  ) => {
    setOverridesByPayConfig((existingConfig) => {
      const updatedConfig = { ...existingConfig };
      updatedConfig[payConfigId] = updatedConfig[payConfigId].filter(
        (x) => x.key !== override.key,
      );
      return updatedConfig;
    });
  };

  const onAddOverride = (payConfigId: string, override: OverrideFormType) => {
    setOverridesByPayConfig((existingConfig) => {
      const updatedConfig = { ...existingConfig };
      updatedConfig[payConfigId] = updatedConfig[payConfigId].concat(override);
      return updatedConfig;
    });
    setShowEditOverrideModal(false);
    setEditingOverrideInfo([null, null]);
  };

  const onEditOverride = (payConfigId: string, override: OverrideFormType) => {
    if (editingOverride == null) {
      return;
    }

    setOverridesByPayConfig((existingConfig) => {
      const updatedConfig = { ...existingConfig };
      const previousIndex = updatedConfig[payConfigId].findIndex(
        (override) => override.key === editingOverride.key,
      );

      updatedConfig[payConfigId][previousIndex] = override;
      return updatedConfig;
    });
    setShowEditOverrideModal(false);
    setEditingOverrideInfo([null, null]);
  };

  const onAddHolidayFromCalendar = (
    formData: OverrideMultiInputFormType,
    selectedDate: Date,
  ) => {
    // for each pay config, if it applied, add the override to the relevant part
    // of our object
    setOverridesByPayConfig((existingConfig) => {
      const updatedConfig = { ...existingConfig };
      formData.pay_config_entries.forEach((entry) => {
        if (entry.applied) {
          updatedConfig[entry.payConfig.id].push({
            key: uuidv4(),
            name: formData.name,
            rate_pounds: entry.rate_in_pounds,
            start_at: fromLocalDateObj(selectedDate, false),
            end_at: fromLocalDateObj(selectedDate, true),
          });
        }
      });

      return updatedConfig;
    });

    setAddHolidayFromCalendarDate(null);
  };

  if (!overridesByPayConfig) {
    return <Loader />;
  }

  // ----- Handle calendar ------

  // Put our override data into a slightlier friendlier format for the calendar
  const overridesWithConfig: OverrideData[] = [];
  payConfigs.forEach((config) => {
    overridesByPayConfig[config.id]?.forEach((override) => {
      overridesWithConfig.push({ payConfig: config, override });
    });
  });

  const filterToWindow = (
    overrides: OverrideFormType[],
  ): OverrideFormType[] => {
    return overrides.filter((override) => {
      return areIntervalsOverlapping(overrideFormTypeToInterval(override), {
        start: startDate,
        end: endDate,
      });
    });
  };

  return (
    <>
      {/* We use this to power the 'edit' buttons against all the existing overrides. */}
      {showEditOverrideModal && editingPayConfig && (
        <OverrideModal
          onClose={() => setShowEditOverrideModal(false)}
          onAdd={(override) => onAddOverride(editingPayConfig.id, override)}
          onEdit={(override) => onEditOverride(editingPayConfig.id, override)}
          editingOverride={editingOverride}
          selectedCurrency={editingPayConfig.currency}
          existingOverrides={overridesByPayConfig[editingPayConfig.id]}
          baseRateInCents={editingPayConfig.base_rate_cents}
        />
      )}
      {addHolidayFromCalendarDate && (
        <OverrideModalMultiPayConfig
          onClose={() => setAddHolidayFromCalendarDate(null)}
          onSubmit={onAddHolidayFromCalendar}
          payConfigs={payConfigs}
          date={addHolidayFromCalendarDate}
        />
      )}
      <StepsPageContent className="space-y-6">
        <p className="text-sm text-slate-700 max-w-xl">
          Please confirm that the holiday rules shown below for your pay
          configurations include all your expected holiday rules for your
          reporting period.
        </p>
        {payConfigs.map((conf) => (
          <div key={conf.id}>
            <div className="flex-center-y w-full">
              <Heading level={3} size="small">
                {conf.name}
              </Heading>
              <p className="text-sm text-slate-600 ml-2">
                Base rate {formatCurrency(conf.currency, conf.base_rate_cents)}{" "}
                / hr
              </p>
            </div>
            {filterToWindow(overridesByPayConfig[conf.id]).length > 0 ? (
              <OverridesList
                overrides={filterToWindow(overridesByPayConfig[conf.id])}
                currency={conf.currency}
                onDeleteOverride={(override: OverrideFormType) =>
                  onDeleteOverride(conf.id, override)
                }
                onOpenEditModalForOverride={(override: OverrideFormType) => {
                  setEditingOverrideInfo([conf, override]);
                  setShowEditOverrideModal(true);
                }}
              />
            ) : (
              <p className="text-sm text-content-tertiary mt-2">
                There are currently no holiday rules applied during this period
              </p>
            )}
          </div>
        ))}

        <hr />
        <p className="text-sm text-slate-700 max-w-xl">
          You can also view your configured holiday rules on the calendar below,
          as well as add new rules by clicking on the individual date cells.
        </p>
        <ContentBox className="!bg-surface-secondary p-4 text-center">
          <YearlyCalendar
            overrideData={overridesWithConfig}
            startDate={startDate}
            endDate={endDate}
            onClick={(selectedDate: Date) =>
              setAddHolidayFromCalendarDate(selectedDate)
            }
          />
        </ContentBox>
      </StepsPageContent>

      <StepsPageFooter
        saving={saving}
        onBack={onBack}
        continueButtonText="Continue"
        onContinue={() => onContinue(overridesByPayConfig)}
      />
    </>
  );
};
