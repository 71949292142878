import {
  AlertTemplatePayload,
  EngineParamBindingPayload,
  EngineParamBindingValuePayload,
} from "@incident-io/api";

// Remove invalid or unused bindings from the alert template.
export const stripInvalidBindings = (
  template: AlertTemplatePayload,
): AlertTemplatePayload => {
  const isValidValue = (
    v: EngineParamBindingValuePayload | undefined,
  ): boolean => {
    if (v?.reference && v.reference.includes("expressions")) {
      // There's a rubbish race condition where we can update the template
      // before react state updates the expressions. This filters out bindings
      // for those until the expressions are valid.
      return template.expressions
        .map((e) => `expressions["${e.reference}"]`)
        .includes(v.reference);
    }
    return Boolean(v?.literal ?? v?.reference);
  };

  const isValidBinding = (b: EngineParamBindingPayload): boolean => {
    if (b.value) {
      return isValidValue(b.value);
    } else if (b.array_value) {
      return b.array_value.every(isValidValue);
    }
    return false;
  };

  const applicableBindings: Record<string, EngineParamBindingPayload> = {};
  for (const [k, v] of Object.entries(template.bindings)) {
    if (isValidBinding(v)) {
      applicableBindings[k] = v;
    }
  }

  return {
    ...template,
    bindings: applicableBindings,
  };
};
