/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Shoutout
 */
export interface Shoutout {
    /**
     * 
     * @type {string}
     * @memberof Shoutout
     */
    creator_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof Shoutout
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof Shoutout
     */
    message_text: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Shoutout
     */
    recipient_user_ids: Array<string>;
}

export function ShoutoutFromJSON(json: any): Shoutout {
    return ShoutoutFromJSONTyped(json, false);
}

export function ShoutoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shoutout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator_user_id': json['creator_user_id'],
        'incident_id': json['incident_id'],
        'message_text': json['message_text'],
        'recipient_user_ids': json['recipient_user_ids'],
    };
}

export function ShoutoutToJSON(value?: Shoutout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator_user_id': value.creator_user_id,
        'incident_id': value.incident_id,
        'message_text': value.message_text,
        'recipient_user_ids': value.recipient_user_ids,
    };
}

