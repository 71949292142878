import {
  DateTimeInputV2,
  EditAsISOSuffix,
} from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import {
  AddNewButton,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  Modal,
  ModalFooter,
  Tooltip,
} from "@incident-ui";
import { roundToNearestMinutes } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  DeepPartial,
  FieldErrors,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StatusPageAffectedComponentStatusEnum,
  StatusPageComponentImpactPayload,
  StatusPageComponentImpactPayloadStatusEnum,
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
  StatusPageStructure,
  StatusPageStructureComponent,
  StatusPageStructureGroup,
} from "src/contexts/ClientContext";
import { getLocalTimeZone } from "src/utils/datetime";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { ComponentImpactTimeline } from "../../common/ComponentImpactTimeline/ComponentImpactTimeline";
import { Impacts } from "../../common/ComponentImpactTimeline/helpers";
import { AddComponentOrGroup } from "../common/AddComponentOrGroup";
import styles from "./IncidentComponentImpactsForm.module.scss";

type FormType = {
  component_impacts: {
    [componentId: string]: StatusPageComponentImpactPayload[];
  };
};

export const IncidentComponentImpactsForm = ({
  isOpen,
  onClose,
  incident,
  structure,
}: {
  isOpen: boolean;
  onClose: () => void;
  incident: StatusPageIncident;
  structure: StatusPageStructure;
}): React.ReactElement => {
  const firstComponentId = incident.component_impacts[0]?.component_id || null;
  const formMethods = useForm<FormType>({
    defaultValues: {
      component_impacts:
        incident.component_impacts.reduce((formType, i) => {
          const impact: StatusPageComponentImpactPayload = {
            component_id: i.component_id,
            status_page_incident_id: i.status_page_incident_id,
            status:
              i.status as unknown as StatusPageComponentImpactPayloadStatusEnum,
            start_at: i.start_at,
            end_at: i.end_at && i.end_at,
          };

          if (formType[impact.component_id]) {
            formType[impact.component_id].push(impact);
          } else {
            formType[impact.component_id] = [impact];
          }

          return formType;
        }, {}) || {},
    },
  });

  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = formMethods;
  const { component_impacts: impactErrors } = errors;
  const componentImpacts = watch("component_impacts");
  const [componentToPop, setComponentToPop] = useState<string | null>(null);

  // For convenience, our form groups impacts by component, but the API just wants one big array of impacts.
  // No problemo, we just need to be able to transform an error on something
  // like `component_impacts.${number}.start_at` into
  // `component_impacts.${string}.${number}.start_at`
  const componentKeys = Object.keys(componentImpacts).sort();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "statusPageShowIncident",
    { id: incident.id },
    async (apiClient, data: FormType) =>
      await apiClient.statusPageSetIncidentComponentImpacts({
        id: incident.id,
        setIncidentComponentImpactsRequestBody: {
          component_impacts: componentKeys.flatMap((key) =>
            data.component_impacts[key].map((x) => ({
              ...x,
              end_at: x.end_at == null ? undefined : x.end_at,
            })),
          ),
        },
      }),
    {
      onSuccess: onClose,

      setError: (key, error) => {
        // We're special-casing errors that look like
        // `component_impacts.${number}.<something>` here, and mapping them into
        // our form type that groups the impacts by component to make useForm
        // and useFieldArray work for us.
        const split = key.split(".");
        if (
          split.length === 3 &&
          split[0] === "component_impacts" &&
          !isNaN(parseInt(split[1]))
        ) {
          // Let's say we have:
          //   {
          //     "key1": [a, b],
          //     "key2": [c],
          //     "key3": [d, e, f],
          //   }
          //
          // And an error on index 3.
          // Let's consider each key in order:
          let index = parseInt(split[1]);
          for (const key of componentKeys) {
            const numImpacts = componentImpacts[key].length;
            // If the index points to inside this array of components, golden.
            // On the first pass in our example, this will be 3 < 2 -> no
            // On the second pass, it'll be 2 < 1 -> no
            // On the third pass, it'll be 1 < 3 -> yes. So we will put the error on `key3.1`
            if (index < numImpacts) {
              setError(`component_impacts.${key}.${index}.${split[2]}`, error);
              return;
            }

            index -= numImpacts;
          }
        } else {
          setError(key, error);
        }
      },
    },
  );

  // Whenever values change, check for overlapping impact windows, so we can
  // mark them as sad.
  useEffect(() => {
    const { unsubscribe } = watch((data) => {
      const now = new Date();
      Object.keys(data.component_impacts || {}).forEach((componentKey) => {
        const impacts = (data.component_impacts || {})[componentKey];
        if (!impacts) return;

        impacts.forEach((impact, index) => {
          const start = impact?.start_at;
          if (!impact || start === undefined) return;

          if (
            impact.end_at &&
            impact.start_at &&
            impact.end_at < impact.start_at
          ) {
            setError(`component_impacts.${componentKey}.${index}.end_at`, {
              type: "custom",
              message: "End at must be after start at",
            });
            return;
          }

          // Don't validate against the current impact, or half-build impacts
          const otherImpacts = _.compact(impacts).filter(
            (otherImpact, otherIndex) =>
              otherIndex !== index && otherImpact.start_at !== undefined,
          );

          // The start is invalid if it is within the interval of a different impact
          const startInvalid = otherImpacts.some(
            (otherImpact) =>
              otherImpact.start_at &&
              otherImpact.start_at < start &&
              start < (otherImpact.end_at || now),
          );
          if (startInvalid) {
            setError(`component_impacts.${componentKey}.${index}.start_at`, {
              type: "custom",
              message: "Impacts must not overlap",
            });
          } else {
            clearErrors(`component_impacts.${componentKey}.${index}.start_at`);
          }

          // end_at is invalid if either:
          let endInvalid = false;
          if (
            impact.end_at &&
            impact.start_at &&
            impact.end_at < impact.start_at
          ) {
            endInvalid = true;
          }
          if (impact.end_at === undefined) {
            // it's not set, and another impact for this component is also unset
            if (
              otherImpacts.some(
                (otherImpact) => otherImpact.end_at === undefined,
              )
            ) {
              endInvalid = true;
            }
          } else {
            // or it's within the window of another impact
            endInvalid = otherImpacts.some(
              (otherImpact) =>
                otherImpact.start_at &&
                otherImpact.start_at < (impact.end_at || now) &&
                (impact.end_at || now) < (otherImpact.end_at || now),
            );
          }

          if (endInvalid) {
            setError(`component_impacts.${componentKey}.${index}.end_at`, {
              type: "custom",
              message: "Impacts must not overlap",
            });
          } else {
            clearErrors(`component_impacts.${componentKey}.${index}.end_at`);
          }
        });
      });
    });
    return () => unsubscribe();
  }, [clearErrors, setError, watch]);

  const modalProps = {
    isXXL: true,
    title: "Edit affected components",
    onClose: onClose,
    analyticsTrackingId: "IncidentComponentImpactsForm",
    suppressInitialAnimation: true,
  };
  if (!isOpen) {
    return <Modal isOpen={false} disableQuickClose={true} {...modalProps} />;
  }

  const impactsToPreview = buildImpacts(
    structure,
    componentImpacts,
    impactErrors,
  );

  return (
    <Form.Modal<FormType>
      formMethods={formMethods}
      genericError={genericError}
      disableQuickClose
      onSubmit={onSubmit}
      {...modalProps}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          disabled={!_.isEmpty(errors)}
          saving={saving}
        />
      }
    >
      <Form.Helptext>
        <>
          {`All times are in your local time zone: `}
          <strong>{getLocalTimeZone(new Date())}.</strong>
        </>{" "}
      </Form.Helptext>

      <div className="space-y-3">
        {structure.items.map(({ group, component }) => {
          if (component) {
            return (
              <ComponentImpactSection
                key={component.component_id}
                incident={incident}
                component={component}
                componentToPop={componentToPop}
                firstComponentId={firstComponentId}
              />
            );
          }

          if (group) {
            return (
              <GroupImpactSection
                key={group.name}
                incident={incident}
                group={group}
                componentToPop={componentToPop}
                firstComponentId={firstComponentId}
              />
            );
          }
          return <></>;
        })}
        <AddComponentOrGroup
          structure={structure}
          selectedComponentIds={new Set(Object.keys(componentImpacts))}
          onAddComponent={(componentId) =>
            setValue(`component_impacts.${componentId}`, [
              {
                component_id: componentId,
                status_page_incident_id: incident.id,
                status:
                  StatusPageComponentImpactPayloadStatusEnum.DegradedPerformance,
                start_at:
                  incident.updates[incident.updates.length - 1].created_at,
              },
            ])
          }
        />
      </div>

      {impactsToPreview.length > 0 ? (
        <>
          <div className="text-sm font-medium">Preview</div>
          <div className="bg-white p-4 pb-8 rounded relative">
            <ComponentImpactTimeline
              incident={incident}
              impacts={buildImpacts(structure, componentImpacts, impactErrors)}
              annotations={buildAnnotations(incident)}
              onClickComponent={(componentId: string) => {
                setComponentToPop(componentId);
                // Revert it after 500ms (unless it's changed again)
                setTimeout(
                  () =>
                    setComponentToPop((isSame) =>
                      isSame === componentId ? null : isSame,
                    ),
                  500,
                );
              }}
            />
          </div>
        </>
      ) : null}
    </Form.Modal>
  );
};

function buildImpacts(
  structure: StatusPageStructure,
  componentImpacts: DeepPartial<FormType["component_impacts"]>,
  errors: FieldErrors<FormType>["component_impacts"],
): Impacts<StatusPageAffectedComponentStatusEnum>[] {
  const buildComponent = ({
    name,
    component_id,
  }: StatusPageStructureComponent) => {
    const compImpacts = componentImpacts[component_id] || [];
    if (compImpacts.length === 0) return undefined;

    const hasError = errors && !_.isEmpty(errors[component_id]);

    const impacts = _.compact(
      _.compact(compImpacts).map(({ status, start_at, end_at }, idx) =>
        status && start_at
          ? {
              id: `${component_id}::${idx}`,
              status:
                status as unknown as StatusPageAffectedComponentStatusEnum,
              start_at,
              end_at,
            }
          : null,
      ),
    );

    return {
      id: component_id,
      label: name,
      impacts,
      error: hasError ? ErrorRow() : undefined,
    };
  };

  return _.compact(
    structure.items.map(({ group, component: comp }) => {
      if (group) {
        const components = _.compact(
          group.components.map((comp) => buildComponent(comp)),
        );

        if (components.length === 0) return undefined;
        return {
          group: {
            name: group.name,
            components,
          },
        };
      }

      if (comp) {
        const component = buildComponent(comp);
        if (!component) return undefined;
        return { component };
      }
      return undefined;
    }),
  );
}

function buildAnnotations(incident: StatusPageIncident) {
  return _.compact(
    incident.updates.map((update) => ({
      timestamp: roundToNearestMinutes(update.published_at),
    })),
  );
}

const ErrorRow = (): React.ReactNode => {
  return (
    <div className="text-center rounded text-xs p-1 border border-red-500 bg-red-200 text-content-primary">
      Impacts must not overlap for a component
    </div>
  );
};

const statusSelectOptions = Object.values(
  StatusPageComponentImpactPayloadStatusEnum,
)
  .filter(
    (status) =>
      status !== StatusPageComponentImpactPayloadStatusEnum.UnderMaintenance,
  )
  .map((status) => ({
    label: COMPONENT_STATUS_CONFIG[status].label,
    value: status,
  }));

const ComponentImpactRow = ({
  incident,
  component,
}: {
  incident: StatusPageIncident;
  component: StatusPageStructureComponent;
}): React.ReactElement => {
  const { append, remove, fields, update, replace } = useFieldArray<
    FormType,
    `component_impacts.${string}`
  >({
    name: `component_impacts.${component.component_id}`,
  });

  const formMethods = useFormContext<FormType>();

  const allImpacts = formMethods.watch(`component_impacts`);
  const impacts = formMethods.watch(
    `component_impacts.${component.component_id}`,
  );

  const sortImpacts = () => {
    // don't do anything if the impacts are already sorted - we don't want to overwrite the state if that hasn't changed
    const impactsAreSorted = impacts.every((impact, index) => {
      if (index === 0) return true;
      const previousImpact = impacts[index - 1];

      if (!impact?.start_at || !previousImpact?.start_at) return true;

      return impact.start_at >= previousImpact.start_at;
    });

    if (impactsAreSorted) return;

    // sort impacts by start at value
    const sortedImpacts = _.sortBy(
      impacts,
      (impact) => impact?.start_at,
    ) as StatusPageComponentImpactPayload[];

    replace(sortedImpacts);
  };

  const onAddImpact = () => {
    const lastUpdateTime =
      incident.updates[incident.updates.length - 1].created_at;

    const latestWindow = _.minBy(
      Object.values(allImpacts).flat(),
      (impact) => impact.end_at || impact.start_at,
    );
    const latestWindowEndAt = latestWindow?.end_at || latestWindow?.start_at;

    const previousImpact = impacts[impacts.length - 1];

    // Calculating our new start value happens in this order of precedence:
    // 1. The end of the previous impact, if it's set
    // 2. The last update time of the incident
    // 3. The most recent impact window value for any component
    // 4. The start of the previous impact (meaning we'll set the previous value to a 0 length window)
    const newStartAt =
      previousImpact.end_at ||
      (lastUpdateTime > previousImpact.start_at
        ? lastUpdateTime
        : latestWindowEndAt) ||
      previousImpact.start_at;
    // Calculate new end at with following order
    // 1. If previous value didn't have an end at, this one doesn't need one
    // 2. Otherwise set to last update time if it's after the new start time
    // 3. Otherwise set to the latest window end time
    // 4. Otherwise set to the new start time (meaning a 0 length window)
    const newEndAt =
      previousImpact.end_at &&
      (lastUpdateTime > newStartAt
        ? lastUpdateTime
        : latestWindowEndAt && latestWindowEndAt > newStartAt
        ? latestWindowEndAt
        : newStartAt);

    // Set the end of our last window to the start of our new window - we don't want more than one open window
    update(impacts.length - 1, { ...previousImpact, end_at: newStartAt });

    append({
      component_id: component.component_id,
      status: StatusPageComponentImpactPayloadStatusEnum.DegradedPerformance,
      status_page_incident_id: incident.id,
      start_at: newStartAt,
      end_at: newEndAt && newEndAt,
    });
  };

  const endRequired =
    incident.status === StatusPageIncidentStatusEnum.Resolved &&
    "Impact end date must be set for resolved incidents";
  return (
    <div className={"text-sm md:space-y-3"}>
      {fields.map((field, index) => {
        const canClear = endRequired ? false : impacts[index].end_at;

        return (
          <div
            key={field.id}
            className={tcx(
              "flex gap-2 flex-col md:flex-row md:flex-nowrap pb-4 md:pb-0",
            )}
          >
            <StaticSingleSelectV2
              formMethods={formMethods}
              name={`component_impacts.${component.component_id}.${index}.status`}
              options={statusSelectOptions}
              label="Component impact"
              labelWrapperClassName="hidden"
              className="min-w-[225px]"
            />
            <DateTimeInputV2
              formMethods={formMethods}
              hideErrors
              name={`component_impacts.${component.component_id}.${index}.start_at`}
              label="from"
              labelWrapperClassName={tcx(
                styles.dateInputLabel,
                "mb-0 mr-2 w-[80px] md:w-auto text-right",
              )}
              labelClassName="text-content-tertiary"
              className="flex items-center w-full md:w-auto"
              onBlurCallback={() => sortImpacts()}
              suffixNode={
                <EditAsISOSuffix
                  id={`component_impacts.${component.component_id}.${index}.start_at`}
                  formMethods={formMethods}
                  showClearButton={false}
                  showCopyButton={false}
                />
              }
            />
            <DateTimeInputV2
              formMethods={formMethods}
              hideErrors
              name={`component_impacts.${component.component_id}.${index}.end_at`}
              label="until"
              labelWrapperClassName={tcx(
                styles.dateInputLabel,
                "mb-0 mr-2 w-[80px] md:w-auto text-right",
              )}
              inputWrapperClassName="relative"
              labelClassName="text-content-tertiary"
              className="flex items-center"
              required={endRequired}
              suffixNode={
                <EditAsISOSuffix
                  id={`component_impacts.${component.component_id}.${index}.end_at`}
                  formMethods={formMethods}
                  showClearButton={!!canClear}
                  showCopyButton={false}
                />
              }
            />
            <Tooltip
              content={"Remove impact window"}
              bubbleProps={{ className: "hidden md:block" }}
            >
              <Button
                title={"Remove impact window"}
                icon={IconEnum.Delete}
                analyticsTrackingId={null}
                onClick={() => remove(index)}
                theme={ButtonTheme.Naked}
                iconProps={{ size: IconSize.Medium, className: "!mr-1" }}
              >
                <span className="md:hidden">Remove impact window</span>
              </Button>
            </Tooltip>
          </div>
        );
      })}
      <AddNewButton
        analyticsTrackingId={"status-page-add-impact-window"}
        className="mt-3"
        onClick={onAddImpact}
        title="Add impact window"
        iconOnlyOnMobile={false}
      />
    </div>
  );
};

const ComponentImpactSection = ({
  incident,
  component,
  componentToPop,
  firstComponentId,
}: {
  incident: StatusPageIncident;
  component: StatusPageStructureComponent;
  componentToPop: string | null;
  firstComponentId: string | null;
}): React.ReactElement => {
  const { watch } = useFormContext<FormType>();
  const impacts = watch(`component_impacts.${component.component_id}`);

  const [isCollapsed, setIsCollapsed] = useState(
    firstComponentId === component.component_id,
  );
  const [renderedItems, setrenderedItems] = useState(impacts?.length || 0);

  const shouldPop = componentToPop === component.component_id;

  const isAddingNewComponent = !incident.affected_components.some(
    (x) => x.component_id === component.component_id,
  );

  useEffect(() => {
    if (impacts?.length > renderedItems || shouldPop) {
      setIsCollapsed(false);
    }
    setrenderedItems(impacts?.length || 0);
  }, [setrenderedItems, impacts, setIsCollapsed, renderedItems, shouldPop]);

  if ((impacts || []).length === 0) return <></>;

  return (
    <ContentBox
      className={tcx(
        "-mx-4 md:mx-0 space-y-2",
        isCollapsed && "hover:border-slate-400 transition",
        componentToPop === component.component_id &&
          "animate-status-page-update-pop",
      )}
    >
      <Button
        theme={ButtonTheme.Naked}
        title={isCollapsed ? "Expand component" : "Collapse component"}
        onClick={() => setIsCollapsed(!isCollapsed)}
        analyticsTrackingId={null}
        className={tcx(
          "w-full text-left p-4 !flex",
          isCollapsed ? "pb-4" : "pb-0",
        )}
      >
        {component.name}
        <Icon
          id={isCollapsed ? IconEnum.Expand : IconEnum.Collapse}
          size={IconSize.Medium}
        />
      </Button>
      {isCollapsed ? null : (
        <div className="px-4 pb-4 py-1 space-y-3">
          {isAddingNewComponent && <NewComponentInfoCallout />}

          <ComponentImpactRow incident={incident} component={component} />
        </div>
      )}
    </ContentBox>
  );
};

const NewComponentInfoCallout = () => (
  <Callout theme={CalloutTheme.Info}>
    Adding an affected component will display it as impacted on your status
    page, but it will not be linked to any existing updates. If you want to link
    this component to an update, please create a new update.
  </Callout>
);

const GroupImpactSection = ({
  incident,
  group,
  componentToPop,
  firstComponentId,
}: {
  incident: StatusPageIncident;
  group: StatusPageStructureGroup;
  componentToPop: string | null;
  firstComponentId: string | null;
}): React.ReactElement => {
  const {
    formState: {
      defaultValues,
      errors: { component_impacts: impactErrors },
    },
    watch,
  } = useFormContext<FormType>();

  const componentImpacts = watch("component_impacts");
  const initialImpacts = defaultValues?.component_impacts || {};

  const initialImpactedComponents = group.components.filter(
    ({ component_id }) => (initialImpacts[component_id] || []).length > 0,
  );
  const containsFirstComponent = initialImpactedComponents.some(
    ({ component_id }) => component_id === firstComponentId,
  );
  const impactedGroupComponents = group.components.filter(
    ({ component_id }) => (componentImpacts[component_id] || []).length > 0,
  );
  const [isCollapsed, setIsCollapsed] = useState(
    // If this group is at the top of the list, start it open, so that _something_
    // is expanded when you first land in this modal.
    containsFirstComponent
      ? false
      : // Otherwise, if the group was impacted in the saved state, start it off closed and pop
        // it open when new components are added for the first time. If it wasn't
        // originally impacted then it should pop open when it is first rendered into
        // the form.
        initialImpactedComponents.length > 0,
  );
  const componentsHaveErrors =
    impactErrors !== undefined &&
    group.components.some(
      ({ component_id }) => !_.isEmpty(impactErrors[component_id]),
    );

  // If we want one of the contained components to pop, un-collapse the group
  const shouldPopContents = group.components.some(
    ({ component_id }) => component_id === componentToPop,
  );

  const [renderedItems, setRenderedItems] = useState(
    impactedGroupComponents.length,
  );

  useEffect(() => {
    if (
      componentsHaveErrors ||
      impactedGroupComponents.length > renderedItems ||
      shouldPopContents
    ) {
      setIsCollapsed(false);
    }
    setRenderedItems(impactedGroupComponents.length);
  }, [
    setRenderedItems,
    impactedGroupComponents,
    setIsCollapsed,
    renderedItems,
    isCollapsed,
    componentsHaveErrors,
    shouldPopContents,
  ]);

  if (!impactedGroupComponents.length) {
    return <></>;
  }

  return (
    <ContentBox
      className={tcx(
        "-mx-4 md:mx-0 space-y-2 text-sm",
        isCollapsed && "hover:border-slate-400 transition",
        shouldPopContents && "animate-status-page-update-pop",
      )}
    >
      <Button
        theme={ButtonTheme.Naked}
        title={isCollapsed ? "Expand group" : "Collapse group"}
        onClick={() => setIsCollapsed(!isCollapsed)}
        analyticsTrackingId={null}
        className={tcx(
          "w-full text-left p-4 !flex",
          isCollapsed ? "pb-4" : "pb-0",
        )}
      >
        {group.name}
        <Icon
          id={isCollapsed ? IconEnum.Expand : IconEnum.Collapse}
          size={IconSize.Medium}
          className="text-content-tertiary group-hover:text-content-primary transition"
        />
      </Button>
      {!isCollapsed && (
        <div className="px-4 pb-4 py-1">
          {impactedGroupComponents.map((component, index) => {
            const impacts = componentImpacts[component.component_id];

            const impact = impacts.find(
              (impact) => impact.component_id === component.component_id,
            );

            if (!impact) {
              return undefined;
            }

            return (
              <div key={component.component_id}>
                {index > 0 && <hr className="!my-4 text-slate-200" />}
                <p className="mb-2 font-medium">{component.name}</p>
                <ComponentImpactRow
                  key={component.component_id}
                  incident={incident}
                  component={component}
                />
              </div>
            );
          })}
        </div>
      )}
    </ContentBox>
  );
};
