/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertTimelineElement,
    AlertTimelineElementFromJSON,
    AlertTimelineElementFromJSONTyped,
    AlertTimelineElementToJSON,
} from './AlertTimelineElement';

/**
 * 
 * @export
 * @interface GroupedAlertTimelineElement
 */
export interface GroupedAlertTimelineElement {
    /**
     * Date grouping the timeline item
     * @type {Date}
     * @memberof GroupedAlertTimelineElement
     */
    date: Date;
    /**
     * 
     * @type {Array<AlertTimelineElement>}
     * @memberof GroupedAlertTimelineElement
     */
    items: Array<AlertTimelineElement>;
}

export function GroupedAlertTimelineElementFromJSON(json: any): GroupedAlertTimelineElement {
    return GroupedAlertTimelineElementFromJSONTyped(json, false);
}

export function GroupedAlertTimelineElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupedAlertTimelineElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'items': ((json['items'] as Array<any>).map(AlertTimelineElementFromJSON)),
    };
}

export function GroupedAlertTimelineElementToJSON(value?: GroupedAlertTimelineElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'items': ((value.items as Array<any>).map(AlertTimelineElementToJSON)),
    };
}

