/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedbackResponseRating
 */
export interface FeedbackResponseRating {
    /**
     * Human readable string describing the response rating
     * @type {string}
     * @memberof FeedbackResponseRating
     */
    label: string;
    /**
     * Value for the rating
     * @type {string}
     * @memberof FeedbackResponseRating
     */
    value: FeedbackResponseRatingValueEnum;
}

/**
* @export
* @enum {string}
*/
export enum FeedbackResponseRatingValueEnum {
    NotAnswered = 'not_answered',
    Low = 'low',
    Medium = 'medium',
    High = 'high'
}

export function FeedbackResponseRatingFromJSON(json: any): FeedbackResponseRating {
    return FeedbackResponseRatingFromJSONTyped(json, false);
}

export function FeedbackResponseRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackResponseRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'value': json['value'],
    };
}

export function FeedbackResponseRatingToJSON(value?: FeedbackResponseRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'value': value.value,
    };
}

