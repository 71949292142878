import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { ErrorMessage } from "@incident-ui";
import {
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import React, { useState } from "react";
import {
  IncidentsRequestAccessRequest,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { useMutation } from "../../../../utils/fetchData";
import { IncidentBody } from "../body/IncidentBody";
import { IncidentSidebar } from "../sidebar/IncidentSidebar";

type RequestState = {
  disabled: boolean;
  loading: boolean;
  text: string;
  message?: string;
};

const NotRequested: RequestState = {
  disabled: false,
  loading: false,
  text: "Request access",
};

const RequestSent: RequestState = {
  disabled: true,
  loading: false,
  text: "Request sent",
};
const RequestError: RequestState = {
  disabled: false,
  loading: false,
  text: "Request access",
  message: "Something went wrong. Please try again.",
};

export function PrivateIncidentNoAccess({
  externalId,
}: {
  externalId: string;
}): React.ReactElement {
  const apiClient = useClient();
  const navigate = useOrgAwareNavigate();
  const [requestState, setRequestState] = useState(NotRequested);

  const [requestAccess, { saving: loading, genericError }] = useMutation(
    async (data: IncidentsRequestAccessRequest) => {
      await apiClient.incidentsRequestAccess(data);
    },
    {
      onSuccess: () => {
        setRequestState(RequestSent);
      },
      onError: () => {
        setRequestState(RequestError);
      },
    },
  );

  return (
    <PageWrapper
      icon={IconEnum.LockClosed}
      width={PageWidth.Medium}
      title={`Private incident`}
    >
      <div aria-hidden="true">
        <div>
          <IncidentBody incidentId={null} />
          <IncidentSidebar incidentId={null} />
        </div>
      </div>
      <Modal
        isOpen
        disableQuickClose
        onClose={() => navigate("/")}
        title="You don't have access to this incident"
        analyticsTrackingId="private-incident-no-access"
      >
        <ModalContent className="space-y-4">
          <p>
            This incident is private. To protect sensitive data, only people who
            have been granted access can see it.
          </p>
          {!loading && requestState.message && <p>{requestState.message}</p>}
          <ErrorMessage message={genericError} />
          {loading && <p>Requesting...</p>}
        </ModalContent>
        <ModalFooter>
          <div className="flex gap-2">
            <Button
              analyticsTrackingId="private-incident-back-to-incidents"
              href="/"
            >
              Back to Dashboard
            </Button>
            <Button
              analyticsTrackingId="private-incident-request-access"
              onClick={() => {
                requestAccess({ id: externalId });
              }}
              theme={ButtonTheme.Primary}
              type="button"
              disabled={requestState.disabled}
              loading={loading}
            >
              {requestState.text}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  );
}

export const PrivateStreamNoAccess = ({
  externalId,
  onClose,
}: {
  externalId: string;
  onClose: () => void;
}) => {
  const apiClient = useClient();
  const [requestState, setRequestState] = useState(NotRequested);

  const [requestAccess, { saving: loading, genericError }] = useMutation(
    async (data: IncidentsRequestAccessRequest) => {
      await apiClient.incidentsRequestAccess(data);
    },
    {
      onSuccess: () => {
        setRequestState(RequestSent);
      },
      onError: () => {
        setRequestState(RequestError);
      },
    },
  );

  const {
    data: { stream },
  } = useAPI(
    "streamsShow",
    {
      id: externalId,
    },
    { fallbackData: { stream: undefined } },
  );

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          icon={IconEnum.LockClosed}
          color={ColorPaletteEnum.Violet}
          onClose={onClose}
          title={stream ? stream.name : "Private stream"}
        />
        <DrawerBody>
          {genericError ? (
            <GenericErrorMessage description={genericError} />
          ) : (
            <EmptyState
              content="This stream is private. To protect sensitive data, only people who
            have been granted access can see it."
              cta={
                <div className="flex gap-2">
                  <Button
                    analyticsTrackingId="private-stream-back-to-incidents"
                    onClick={onClose}
                  >
                    Back to incident
                  </Button>
                  <Button
                    analyticsTrackingId="private-stream-request-access"
                    onClick={() => {
                      requestAccess({ id: externalId });
                    }}
                    theme={ButtonTheme.Primary}
                    type="button"
                    disabled={requestState.disabled}
                    loading={loading}
                  >
                    {requestState.text}
                  </Button>
                </div>
              }
              icon={IconEnum.LockClosed}
              title="Private stream"
              className="border-none grow-0"
            />
          )}
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};
