import {
  HolidayPublicEntry,
  HolidayUserEntry,
  ScheduleEntry,
  ScheduleOverride,
} from "@incident-io/api";
import { Badge, BadgeTheme, IconEnum } from "@incident-ui";
import { DateTime } from "luxon";
import { DurationLike } from "luxon/src/duration";
import { assertUnreachable } from "src/utils/utils";

export enum TimelineCalendarValue {
  Timeline = "timeline",
  Calendar = "calendar",
}

export const timelineCalendarToggleOptions = [
  {
    value: TimelineCalendarValue.Timeline,
    label: (
      <Badge
        icon={IconEnum.ComplexList}
        theme={BadgeTheme.Naked}
        iconClassName="text-content-tertiary cursor-pointer"
      />
    ),
  },
  {
    value: TimelineCalendarValue.Calendar,
    label: (
      <Badge
        icon={IconEnum.Calendar}
        theme={BadgeTheme.Naked}
        iconClassName="text-content-tertiary cursor-pointer"
      />
    ),
  },
];

export enum TimePeriodOption {
  ThreeHours = "three_hours",
  OneDay = "one_day",
  OneWeek = "one_week",
  TwoWeeks = "two_weeks",
  OneMonth = "one_month",
}

// startTimeForPeriod finds the appropriate start time for the preview window to make sure two things are true:
// 1. The preview window starts at a nice round time
// 2. The "focused" time appears at some point in the second increment of the current view
export const startTimeForPeriod = ({
  timePeriod,
  time,
}: {
  timePeriod: TimePeriodOption;
  time: DateTime;
}): DateTime => {
  if (!time) {
    time = DateTime.now();
  }
  switch (timePeriod) {
    // For the 3-hour view, we round to the nearest hour. If the minute is less than 15, we want the previous hour
    case TimePeriodOption.ThreeHours:
      if (time.minute < 15) {
        return time.startOf("hour").minus({ hour: 1 });
      }
      return time.startOf("hour");
    case TimePeriodOption.OneDay:
      return time.startOf("day");
    // The other views have an increment of 1 day, so start of previous day
    case TimePeriodOption.OneWeek:
    case TimePeriodOption.TwoWeeks:
      return time.startOf("week");
    case TimePeriodOption.OneMonth:
      return time.startOf("month");
    default:
      assertUnreachable(timePeriod);
      // We'll never get here, but ES doesn't know that
      return DateTime.now();
  }
};

export const timePeriodOpts: Record<
  TimePeriodOption,
  { label: string; duration: DurationLike }
> = {
  [TimePeriodOption.ThreeHours]: {
    label: "3 hours",
    duration: { hours: 3 },
  },
  [TimePeriodOption.OneDay]: {
    label: "1 day",
    duration: { day: 1 },
  },
  [TimePeriodOption.OneWeek]: {
    duration: { week: 1 },
    label: "1 week",
  },
  [TimePeriodOption.TwoWeeks]: {
    duration: { week: 2 },
    label: "2 weeks",
  },
  [TimePeriodOption.OneMonth]: {
    duration: { month: 1 },
    label: "1 month",
  },
};

export const endTimeForTimelinePeriod = ({
  timePeriod,
  from,
}: {
  timePeriod: TimePeriodOption;
  from: DateTime;
}): DateTime => {
  switch (timePeriod) {
    case TimePeriodOption.ThreeHours:
      return from.plus(timePeriodOpts[timePeriod].duration);
    case TimePeriodOption.OneDay:
      return from.plus(timePeriodOpts[timePeriod].duration);
    case TimePeriodOption.OneWeek:
      return from.plus(timePeriodOpts[timePeriod].duration);
    case TimePeriodOption.TwoWeeks:
      return from.plus(timePeriodOpts[timePeriod].duration);
    case TimePeriodOption.OneMonth:
      return from.plus(timePeriodOpts[timePeriod].duration);
    default:
      return from;
  }
};

export const calculateSegments = ({
  timePeriod,
  timelineStartPoint,
}: {
  timePeriod: TimePeriodOption;
  timelineStartPoint: DateTime;
}) => {
  if (timePeriod === TimePeriodOption.ThreeHours) {
    return Array.from(Array(12).keys()).map((i) =>
      timelineStartPoint.plus({ minutes: 15 * i }),
    );
  } else if (timePeriod === TimePeriodOption.OneDay) {
    return Array.from(Array(24).keys()).map((i) =>
      timelineStartPoint.plus({ hours: i }),
    );
  } else if (timePeriod === TimePeriodOption.OneMonth) {
    const daysInMonth = timelineStartPoint.daysInMonth;
    return Array.from(Array(daysInMonth).keys()).map((i) =>
      timelineStartPoint.plus({ days: i }),
    );
  }
  const days = {
    [TimePeriodOption.OneWeek]: 7,
    [TimePeriodOption.TwoWeeks]: 14,
  }[timePeriod];

  return Array.from(Array(days).keys()).map((i) =>
    timelineStartPoint.plus({ days: i }),
  );
};

type AugmentedHolidayPublicEntry = HolidayPublicEntry & {
  start_at: Date;
  end_at: Date;
  schedule_id: string | undefined;
};

type AugmentedHolidayUserEntry = HolidayUserEntry & {
  schedule_id: string | undefined;
};

export type TimelineEntry =
  | ScheduleEntry
  | ScheduleOverride
  | AugmentedHolidayPublicEntry
  | AugmentedHolidayUserEntry;

export const isScheduleEntry = (
  e: TimelineEntry | undefined,
): e is ScheduleEntry =>
  !!e && "rotation_id" in e && "layer_id" in e && "external_user_id" in e;

export const isScheduleOverride = (
  e: TimelineEntry | undefined,
): e is ScheduleOverride =>
  !!e &&
  "rotation_id" in e &&
  "layer_id" in e &&
  "created_at" in e &&
  "id" in e;

export const isHolidayUserEntry = (
  e: TimelineEntry | undefined,
): e is AugmentedHolidayUserEntry =>
  !!e && "start_at" in e && "end_at" in e && "feed_id" in e;

export const isHolidayPublicEntry = (
  e: TimelineEntry | undefined,
): e is AugmentedHolidayPublicEntry => !!e && "country_code" in e;

export const isHolidayEntry = (
  e: TimelineEntry | undefined,
): e is AugmentedHolidayPublicEntry | AugmentedHolidayUserEntry =>
  isHolidayPublicEntry(e) || isHolidayUserEntry(e);

export type UpcomingRotaChange = {
  at: DateTime;
  rotaId: string;
};
