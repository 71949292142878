/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BranchesOnlyExpressionPayload,
    BranchesOnlyExpressionPayloadFromJSON,
    BranchesOnlyExpressionPayloadFromJSONTyped,
    BranchesOnlyExpressionPayloadToJSON,
} from './BranchesOnlyExpressionPayload';

/**
 * 
 * @export
 * @interface IssueTrackerSyncConfigPayload
 */
export interface IssueTrackerSyncConfigPayload {
    /**
     * The context in which this sync config is used
     * @type {string}
     * @memberof IssueTrackerSyncConfigPayload
     */
    context: IssueTrackerSyncConfigPayloadContextEnum;
    /**
     * Whether auto-export is enabled
     * @type {boolean}
     * @memberof IssueTrackerSyncConfigPayload
     */
    enabled: boolean;
    /**
     * Whether private incidents will be exported
     * @type {boolean}
     * @memberof IssueTrackerSyncConfigPayload
     */
    include_private_incidents: boolean;
    /**
     * 
     * @type {BranchesOnlyExpressionPayload}
     * @memberof IssueTrackerSyncConfigPayload
     */
    issue_template_expression?: BranchesOnlyExpressionPayload;
}

/**
* @export
* @enum {string}
*/
export enum IssueTrackerSyncConfigPayloadContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}

export function IssueTrackerSyncConfigPayloadFromJSON(json: any): IssueTrackerSyncConfigPayload {
    return IssueTrackerSyncConfigPayloadFromJSONTyped(json, false);
}

export function IssueTrackerSyncConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackerSyncConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'enabled': json['enabled'],
        'include_private_incidents': json['include_private_incidents'],
        'issue_template_expression': !exists(json, 'issue_template_expression') ? undefined : BranchesOnlyExpressionPayloadFromJSON(json['issue_template_expression']),
    };
}

export function IssueTrackerSyncConfigPayloadToJSON(value?: IssueTrackerSyncConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'enabled': value.enabled,
        'include_private_incidents': value.include_private_incidents,
        'issue_template_expression': BranchesOnlyExpressionPayloadToJSON(value.issue_template_expression),
    };
}

