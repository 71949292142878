/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    ExternalResource,
    ExternalResourceFromJSON,
    ExternalResourceFromJSONTyped,
    ExternalResourceToJSON,
} from './ExternalResource';

/**
 * 
 * @export
 * @interface IncidentAttachmentAdded
 */
export interface IncidentAttachmentAdded {
    /**
     * 
     * @type {Actor}
     * @memberof IncidentAttachmentAdded
     */
    creator: Actor;
    /**
     * 
     * @type {ExternalResource}
     * @memberof IncidentAttachmentAdded
     */
    external_resource: ExternalResource;
}

export function IncidentAttachmentAddedFromJSON(json: any): IncidentAttachmentAdded {
    return IncidentAttachmentAddedFromJSONTyped(json, false);
}

export function IncidentAttachmentAddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAttachmentAdded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': ActorFromJSON(json['creator']),
        'external_resource': ExternalResourceFromJSON(json['external_resource']),
    };
}

export function IncidentAttachmentAddedToJSON(value?: IncidentAttachmentAdded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': ActorToJSON(value.creator),
        'external_resource': ExternalResourceToJSON(value.external_resource),
    };
}

