/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';

/**
 * 
 * @export
 * @interface IncidentsBulkUpdateRoleAssignmentsRequestBody
 */
export interface IncidentsBulkUpdateRoleAssignmentsRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentsBulkUpdateRoleAssignmentsRequestBody
     */
    incident_ids: Array<string>;
    /**
     * Set the incident's role assignments to these values
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof IncidentsBulkUpdateRoleAssignmentsRequestBody
     */
    role_assignments: Array<IncidentRoleAssignmentPayload>;
}

export function IncidentsBulkUpdateRoleAssignmentsRequestBodyFromJSON(json: any): IncidentsBulkUpdateRoleAssignmentsRequestBody {
    return IncidentsBulkUpdateRoleAssignmentsRequestBodyFromJSONTyped(json, false);
}

export function IncidentsBulkUpdateRoleAssignmentsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsBulkUpdateRoleAssignmentsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_ids': json['incident_ids'],
        'role_assignments': ((json['role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
    };
}

export function IncidentsBulkUpdateRoleAssignmentsRequestBodyToJSON(value?: IncidentsBulkUpdateRoleAssignmentsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_ids': value.incident_ids,
        'role_assignments': ((value.role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
    };
}

