import { ReactComponent as IncidentIOLogo } from "@incident-shared/layout/MainSidebar/img/logo-full.svg";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum, IconSize, SlackTeamAvatar, Toast } from "@incident-ui";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import { ToastContext } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useContext } from "react";
import { AssistantOverlay } from "src/components/insights/assistant/AssistantOverlay";
import {
  InstallationStepID,
  useSetupSteps,
} from "src/components/setup/useSetupSteps";
import { useIdentity } from "src/contexts/IdentityContext";
import { useLogoutAll } from "src/hooks/useLogoutAll";
import { tcx } from "src/utils/tailwind-classes";

import { SidebarDropdownMenu } from "../MainSidebar/SidebarDropdownMenu";
import { PageWidth, PageWrapper } from "../PageWrapper";

export const SetupPageWrapper = ({
  title,
  footer,
  currentStepID,
  children,
  topRightAccessory,
}: {
  title?: React.ReactNode;
  footer?: React.ReactNode;
  currentStepID?: InstallationStepID;
  children: React.ReactNode;
  topRightAccessory?: React.ReactNode;
}): React.ReactElement => {
  const steps = useSetupSteps();

  const {
    toastProps,
    open: toastOpen,
    setOpen: setToastOpen,
  } = useContext(ToastContext);

  // We might not have an identity! That's ok, but this component should always handle an
  // empty identity.
  const { isImpersonating } = useIdentity();

  return (
    <div
      className={tcx(
        "relative w-full min-h-full max-h-full overflow-hidden bg-slate-950 text-white",
        {
          ["bg-purple-800"]: process.env.REACT_APP_DEV_SIDEBAR === "true",
          ["bg-red-700"]: isImpersonating,
        },
      )}
    >
      <div className="flex flex-col overflow-hidden h-[100vh] p-6">
        <div className="pb-6 flex w-full justify-between">
          <SetupPageHeader />
          {topRightAccessory}
        </div>
        <div
          className={tcx(
            "grow overflow-hidden w-full rounded-t-3 bg-gradient-to-b from-slate-800 to-slate-950",
          )}
        >
          <main
            className={tcx(
              "overflow-hidden w-full min-h-full flex flex-col relative",
              "h-[calc(100vh-88px)]",
            )}
          >
            <div className="flex-1 overflow-hidden">
              <PageWrapper
                title="incident.io"
                noHeader
                width={PageWidth.Narrow}
                transparent
                noPadding
              >
                <div className="p-4 md:p-10 grow flex flex-col">
                  {currentStepID && (
                    <NakedSteps
                      steps={steps}
                      currentStepID={currentStepID}
                      color={ColorPaletteEnum.Red}
                      invert
                      containerClassName="w-full pb-10"
                      separatorClassName="border-slate-700"
                      completedIconClassName="bg-alarmalade-500 text-content-primary"
                    />
                  )}
                  <div className="grow w-full mx-auto items-center justify-center text-center flex flex-col gap-10 pb-10">
                    {title && (
                      <h1 className="text-center text-base md:text-2xl-bold text-white">
                        {title}
                      </h1>
                    )}

                    {children}
                    <div className="text-slate-400 text-sm -mt-5">{footer}</div>
                  </div>
                </div>
              </PageWrapper>
            </div>
          </main>
        </div>
      </div>
      <AssistantOverlay />
      <Toast open={toastOpen} setOpen={setToastOpen} {...toastProps} />
    </div>
  );
};

const SetupPageHeader = () => {
  const { identity, outsideOfProvider } = useIdentity();
  const logoutAll = useLogoutAll();
  if (!identity || outsideOfProvider) {
    return <IncidentIOLogo className="w-[125px] h-[30px]" />;
  }

  return (
    <SidebarDropdownMenu
      identity={identity}
      logoutAll={logoutAll}
      inSetupFlow
      offset={4}
      renderTriggerButton={() => (
        <button
          type="button"
          className={tcx("flex items-center gap-0.5 group shrink-0 group")}
        >
          <SlackTeamAvatar
            title=""
            url={identity.organisation_icon_url}
            className="w-10 h-10 rounded-2"
            placeholderImg="/slack-team-avatar-placeholder-dark.svg"
          />
          <div className="flex flex-col pl-3 items-start">
            <div className="text-xs-med text-slate-400">Logged in as</div>
            <div className="text-sm-bold text-white">
              {identity.organisation_name}
            </div>
          </div>
          <Icon
            id={IconEnum.Expand}
            className="text-content-tertiary hidden group-hover:block"
            size={IconSize.Small}
          />
        </button>
      )}
    />
  );
};
