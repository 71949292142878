import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  SecondaryNavHorizontal,
  SecondaryNavHorizontalItemType,
} from "@incident-shared/layout/SecondaryNavHorizontal";
import { SecondaryPageWrapper } from "@incident-shared/layout/SecondaryPageWrapper";
import { IconEnum } from "@incident-ui";
import { Outlet } from "react-router";

export const PostIncidentPageWrapper = () => {
  const pageGroups: SecondaryNavHorizontalItemType[] = [
    {
      label: "Post-incident flow",
      slug: "post-incident-flow",
    },
    {
      label: "Follow-ups",
      slug: "follow-ups",
    },
  ];

  return (
    <PageWrapper
      icon={IconEnum.Action}
      title="Post-incident"
      width={PageWidth.Medium}
    >
      <SecondaryNavHorizontal
        items={pageGroups}
        pathPrefix={"post-incident-flow"}
      />
      <SecondaryPageWrapper>
        <Outlet />
      </SecondaryPageWrapper>
    </PageWrapper>
  );
};
