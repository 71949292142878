import { CatalogTypeCategoriesEnum, ScopeNameEnum } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { EmptyState, IconEnum } from "@incident-ui";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import React from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { CatalogFixedFooter } from "../common/CatalogFixedFooter";
import { ServiceWizardStepConfig } from "./service-wizard/steps";
import { TeamWizardStepConfig, TeamWizardStepID } from "./team-wizard/steps";

type WizardLayoutProps = React.PropsWithChildren<{
  title?: string;
  subtitle?: string;
  category: CatalogTypeCategoriesEnum;
  footer: React.ReactElement;
  stepID: TeamWizardStepID;
  below?: React.ReactElement;
  backHref?: string;
}>;

const CategoryToWizardConfig = {
  [CatalogTypeCategoriesEnum.Team]: {
    steps: TeamWizardStepConfig,
    gradientClasses: "from-[#F3E8FFFF] to-[#F3E8FF00]",
    color: ColorPaletteEnum.Purple,
  },
  [CatalogTypeCategoriesEnum.Service]: {
    steps: ServiceWizardStepConfig,
    gradientClasses: "from-[#D8F8F0FF] to-[#D8F8F000]",
    color: ColorPaletteEnum.Green,
  },
};

export const WizardLayout = ({
  title,
  subtitle,
  category,
  footer,
  stepID,
  below,
  children,
  backHref,
}: WizardLayoutProps) => {
  const { hasScope } = useIdentity();

  const wizardConfig = CategoryToWizardConfig[category];
  if (!wizardConfig) {
    throw new Error(`No wizard config found for category ${category}`);
  }

  const hasPermissionsToDoWizard =
    hasScope(ScopeNameEnum.CatalogTypesCreate) &&
    hasScope(ScopeNameEnum.CatalogTypesEdit) &&
    hasScope(ScopeNameEnum.CatalogEntriesCreate);

  if (!hasPermissionsToDoWizard) {
    return (
      <>
        <div
          className={tcx(
            "flex flex-col grow gap-10 rounded-lg bg-gradient-to-b py-8 h-full",
            wizardConfig.gradientClasses,
          )}
        >
          <div className="px-8 max-w-[960px] mx-auto w-full">
            <NakedSteps
              steps={wizardConfig.steps}
              currentStepID={stepID}
              color={wizardConfig.color}
            />
          </div>
          <div className="overflow-y-auto grow">
            <div className="flex flex-col gap-10 max-w-[960px] mx-auto px-8 pb-8">
              <TitleAndSubtitle title={title} subtitle={subtitle} />
              <EmptyState
                icon={IconEnum.LockClosed}
                title="You don't have permission to manage your catalog"
                content="Please contact your administrator to ask for permissions to manage catalog entries and types so that you can configure your Catalog."
              />
            </div>
          </div>
        </div>
        {/* The footer is positioned with 'absolute' against the relative page wrapper */}
        <CatalogFixedFooter backHref={backHref}>{footer}</CatalogFixedFooter>
      </>
    );
  }

  // We really have two different layouts here, one when the whole page is gradient-ed and one when
  // it's only the top section. To make this not unreadable, we double it up.

  if (below) {
    return (
      <>
        <div
          className={tcx(
            "flex flex-col rounded-lg bg-gradient-to-b pt-8",
            wizardConfig.gradientClasses,
          )}
        >
          <div className="flex flex-col gap-10 w-full">
            {/* Apply the padding and max-width separately for the two sections so the scrollbar is in the right place */}
            <div className="px-8 max-w-[960px] w-full mx-auto">
              <NakedSteps
                steps={wizardConfig.steps}
                currentStepID={stepID}
                color={wizardConfig.color}
              />
            </div>
            <div className="overflow-y-auto grow">
              <div className="flex flex-col gap-10 max-w-[960px] mx-auto pb-8">
                <TitleAndSubtitle title={title} subtitle={subtitle} />
                {children}
              </div>
            </div>
          </div>
        </div>
        {/* Any children to render beneath the purple fade must still also be above the footer */}
        <div className="grow flex flex-col gap-10 pt-10 overflow-y-auto">
          {below}
        </div>
        {/* The footer is positioned with 'absolute' against the relative page wrapper */}
        <CatalogFixedFooter backHref={backHref}>{footer}</CatalogFixedFooter>
      </>
    );
  }

  return (
    <>
      <div
        className={tcx(
          "flex flex-col grow gap-10 rounded-lg bg-gradient-to-b py-8 h-full",
          wizardConfig.gradientClasses,
        )}
      >
        {/* Apply the padding and max-width separately for the two sections so the scrollbar is in the right place */}
        <div className="px-8 max-w-[960px] mx-auto w-full">
          <NakedSteps
            steps={wizardConfig.steps}
            currentStepID={stepID}
            color={wizardConfig.color}
          />
        </div>
        <div className="overflow-y-auto grow">
          <div className="flex flex-col gap-10 max-w-[960px] mx-auto px-8 pb-8">
            <TitleAndSubtitle title={title} subtitle={subtitle} />
            {children}
          </div>
        </div>
      </div>
      {/* The footer is positioned with 'absolute' against the relative page wrapper */}
      <CatalogFixedFooter backHref={backHref}>{footer}</CatalogFixedFooter>
    </>
  );
};

const TitleAndSubtitle = ({
  title,
  subtitle,
}: {
  title?: string;
  subtitle?: string;
}) => {
  if (!title && !subtitle) return null;

  return (
    <div className="flex flex-col gap-2 text-content-primary text-center self-stretch px-8">
      {!!title && <span className=" text-2xl-bold">{title}</span>}
      {!!subtitle && <span className="text-base">{subtitle}</span>}
    </div>
  );
};
