import {
  AvailableExpressionOperationOperationTypeEnum as OperationTypeEnum,
  CustomField,
  IncidentsBuildScopeContextEnum,
} from "@incident-io/api";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { Button } from "@incident-ui";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAllResources } from "src/hooks/useResources";
import { getEmptyScope } from "src/utils/scope";
import { useAPI } from "src/utils/swr";

import { addExpressionsToScope } from "../../../@shared/engine/expressions/addExpressionsToScope";
import { QueryExpressionEditModal } from "../../../@shared/engine/expressions/query/QueryExpressionEditModal";
import { ViewExpression } from "../../../@shared/engine/expressions/ViewExpression";
import { StructureFormType } from "./StructureFormContent";

export const QueryExpressionInput = ({
  selectedField,
}: {
  selectedField: CustomField;
}) => {
  const [showExpressionEditor, setShowExpressionEditor] = useState(false);
  const { resources } = useAllResources();
  const formMethods = useFormContext<StructureFormType>();
  const expression = formMethods.watch("component_source_expression_payload");
  // Memoise this, to prevent a lot of re-renders
  const scope = useMemo(
    () =>
      addExpressionsToScope(getEmptyScope(), expression ? [expression] : []),
    [expression],
  );

  const {
    data: { catalog_types: catalogTypes },
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const {
    data: {
      scope: { references: fullScope },
    },
  } = useAPI(
    "incidentsBuildScope",
    {
      context: IncidentsBuildScopeContextEnum.FullScope,
    },
    {
      fallbackData: { scope: { references: [], aliases: {} } },
    },
  );

  const selectedCatalogEngineType = fullScope.find(
    (ref) => ref.key === `incident.custom_field["${selectedField.id}"]`,
  )?.type;

  const selectedCatalogType = catalogTypes.find(
    (type) => type.id === selectedField?.catalog_type_id,
  );

  if (!selectedCatalogEngineType || !selectedCatalogType) {
    return null;
  }

  return (
    <ExpressionsMethodsProvider
      allowAllOfACatalogType
      allowedOperations={[OperationTypeEnum.Filter, OperationTypeEnum.Navigate]}
    >
      <div>
        <Form.Label htmlFor="">Filter your components</Form.Label>
        <Form.Helptext>
          If your custom field has a lot of options, you can use a query
          expression to filter your list of components.
        </Form.Helptext>
        {expression ? (
          <ViewExpression
            expression={expression}
            scope={scope}
            showExpressionName
            onEdit={() => setShowExpressionEditor(true)}
            onDelete={() =>
              formMethods.setValue(
                "component_source_expression_payload",
                undefined,
                {
                  shouldDirty: true,
                },
              )
            }
          />
        ) : (
          <Button
            onClick={() => setShowExpressionEditor(true)}
            analyticsTrackingId="internal-sp-component-settings-use-expression"
          >
            Use an expression
          </Button>
        )}

        {showExpressionEditor && (
          <>
            <QueryExpressionEditModal
              fixedResult={{
                type: selectedCatalogEngineType,
                // catalog types aren't autocompleteable, right now at least
                typeIsAutocompletable: false,
                label: selectedCatalogType.name,
                typeLabel: selectedCatalogType.name,
                array: true,
              }}
              onAddExpression={(expr) => {
                setShowExpressionEditor(false);
                formMethods.setValue(
                  "component_source_expression_payload",
                  expr,
                  {
                    shouldDirty: true,
                  },
                );
              }}
              onEditExpression={(expr) => {
                setShowExpressionEditor(false);
                formMethods.setValue(
                  "component_source_expression_payload",
                  expr,
                  {
                    shouldDirty: true,
                  },
                );
              }}
              defaultExpression={{
                root_reference: `catalog.catalog_type["${selectedCatalogType.type_name}"]`,
              }}
              onClose={() => setShowExpressionEditor(false)}
              scope={scope}
              resources={resources}
              analyticsTrackingContext="internal-sp-settings"
              initialExpression={expression}
              existingExpressions={expression ? [expression] : []}
            />
          </>
        )}
      </div>
    </ExpressionsMethodsProvider>
  );
};
