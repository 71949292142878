/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextMark
 */
export interface TextMark {
    /**
     * type-specific mark attributes
     * @type {object}
     * @memberof TextMark
     */
    attrs?: object;
    /**
     * the type of the node, eg 'text'
     * @type {string}
     * @memberof TextMark
     */
    type: string;
}

export function TextMarkFromJSON(json: any): TextMark {
    return TextMarkFromJSONTyped(json, false);
}

export function TextMarkFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextMark {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attrs': !exists(json, 'attrs') ? undefined : json['attrs'],
        'type': json['type'],
    };
}

export function TextMarkToJSON(value?: TextMark | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attrs': value.attrs,
        'type': value.type,
    };
}

