/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    PostIncidentTaskConfig,
    PostIncidentTaskConfigFromJSON,
    PostIncidentTaskConfigFromJSONTyped,
    PostIncidentTaskConfigToJSON,
} from './PostIncidentTaskConfig';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface PostIncidentTask
 */
export interface PostIncidentTask {
    /**
     * 
     * @type {User}
     * @memberof PostIncidentTask
     */
    assignee?: User;
    /**
     * 
     * @type {Actor}
     * @memberof PostIncidentTask
     */
    assigner?: Actor;
    /**
     * When the item was completed
     * @type {Date}
     * @memberof PostIncidentTask
     */
    completed_at?: Date;
    /**
     * 
     * @type {Actor}
     * @memberof PostIncidentTask
     */
    completer?: Actor;
    /**
     * 
     * @type {PostIncidentTaskConfig}
     * @memberof PostIncidentTask
     */
    config: PostIncidentTaskConfig;
    /**
     * When the task is due
     * @type {Date}
     * @memberof PostIncidentTask
     */
    due_at?: Date;
    /**
     * Unique identifier for this post-incident task
     * @type {string}
     * @memberof PostIncidentTask
     */
    id: string;
    /**
     * ID of the incident that the task relates to
     * @type {string}
     * @memberof PostIncidentTask
     */
    incident_id: string;
    /**
     * When the item was marked as not relevant for this incident
     * @type {Date}
     * @memberof PostIncidentTask
     */
    rejected_at?: Date;
    /**
     * 
     * @type {Actor}
     * @memberof PostIncidentTask
     */
    rejecter?: Actor;
}

export function PostIncidentTaskFromJSON(json: any): PostIncidentTask {
    return PostIncidentTaskFromJSONTyped(json, false);
}

export function PostIncidentTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': !exists(json, 'assignee') ? undefined : UserFromJSON(json['assignee']),
        'assigner': !exists(json, 'assigner') ? undefined : ActorFromJSON(json['assigner']),
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'completer': !exists(json, 'completer') ? undefined : ActorFromJSON(json['completer']),
        'config': PostIncidentTaskConfigFromJSON(json['config']),
        'due_at': !exists(json, 'due_at') ? undefined : (new Date(json['due_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'rejected_at': !exists(json, 'rejected_at') ? undefined : (new Date(json['rejected_at'])),
        'rejecter': !exists(json, 'rejecter') ? undefined : ActorFromJSON(json['rejecter']),
    };
}

export function PostIncidentTaskToJSON(value?: PostIncidentTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': UserToJSON(value.assignee),
        'assigner': ActorToJSON(value.assigner),
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'completer': ActorToJSON(value.completer),
        'config': PostIncidentTaskConfigToJSON(value.config),
        'due_at': value.due_at === undefined ? undefined : (value.due_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'rejected_at': value.rejected_at === undefined ? undefined : (value.rejected_at.toISOString()),
        'rejecter': ActorToJSON(value.rejecter),
    };
}

