/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostmortemDestination,
    PostmortemDestinationFromJSON,
    PostmortemDestinationFromJSONTyped,
    PostmortemDestinationToJSON,
} from './PostmortemDestination';

/**
 * 
 * @export
 * @interface PostmortemsCreateDestinationResponseBody
 */
export interface PostmortemsCreateDestinationResponseBody {
    /**
     * 
     * @type {PostmortemDestination}
     * @memberof PostmortemsCreateDestinationResponseBody
     */
    destination: PostmortemDestination;
}

export function PostmortemsCreateDestinationResponseBodyFromJSON(json: any): PostmortemsCreateDestinationResponseBody {
    return PostmortemsCreateDestinationResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsCreateDestinationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsCreateDestinationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'destination': PostmortemDestinationFromJSON(json['destination']),
    };
}

export function PostmortemsCreateDestinationResponseBodyToJSON(value?: PostmortemsCreateDestinationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'destination': PostmortemDestinationToJSON(value.destination),
    };
}

