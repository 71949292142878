import { Button, Callout, CalloutTheme, IconEnum } from "@incident-ui";

import { SetupInfoNumberedList } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceSentrySetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        <div className={"flex flex-col items-start"}>
          Go to your Sentry dashboard where you can configure an alert rule.
          <Button
            className={"my-2"}
            analyticsTrackingId={"sentry-alert-open-sentry"}
            href={"https://sentry.io/alerts/rules/"}
            openInNewTab
            icon={IconEnum.ExternalLink}
          >
            Open Sentry
          </Button>
        </div>
      </p>
      <p>
        Within Sentry, click <strong>Create Alert</strong> in the top-right of
        the Alerts page.
      </p>
      <div className={"flex flex-col gap-2 mb-2"}>
        <p>
          Choose an <strong>Issues</strong> alert rule, and progress by clicking{" "}
          <strong>Set Conditions</strong>.
        </p>
        <Callout theme={CalloutTheme.Info} className="p-2 gap-1">
          We currently only support Issue alerts for the Sentry alert source.
        </Callout>
      </div>
      <p>
        Filter for whichever alerts you&rsquo; want to ingest in this alert
        source, and in the perform these actions dropdown, choose{" "}
        <strong>Notify integration... incident.io</strong>.
      </p>
      <p>
        Click <strong>Settings</strong> on the action and choose this alert rule
        (<strong>{alertSourceConfig.name}</strong>) from the dropdown.
      </p>
      <p>
        You can send a test notification from the Alert Rule configuration. Once
        the rule is saved you are good to go!
      </p>
    </SetupInfoNumberedList>
  );
};
