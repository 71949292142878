/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchedulePayRulePayload,
    SchedulePayRulePayloadFromJSON,
    SchedulePayRulePayloadFromJSONTyped,
    SchedulePayRulePayloadToJSON,
} from './SchedulePayRulePayload';
import {
    ScheduleReportOverridePayload,
    ScheduleReportOverridePayloadFromJSON,
    ScheduleReportOverridePayloadFromJSONTyped,
    ScheduleReportOverridePayloadToJSON,
} from './ScheduleReportOverridePayload';

/**
 * 
 * @export
 * @interface SchedulesUpdatePayConfigRequestBody
 */
export interface SchedulesUpdatePayConfigRequestBody {
    /**
     * Base rate of pay per hour, in lowest denomination of specified currency
     * @type {number}
     * @memberof SchedulesUpdatePayConfigRequestBody
     */
    base_rate_cents: number;
    /**
     * Currency used to send schedule, in ISO 4217 format
     * @type {string}
     * @memberof SchedulesUpdatePayConfigRequestBody
     */
    currency: string;
    /**
     * Human readable name of the pay config
     * @type {string}
     * @memberof SchedulesUpdatePayConfigRequestBody
     */
    name: string;
    /**
     * Overrides for the pay config
     * @type {Array<ScheduleReportOverridePayload>}
     * @memberof SchedulesUpdatePayConfigRequestBody
     */
    overrides: Array<ScheduleReportOverridePayload>;
    /**
     * Configuration for pay rules
     * @type {Array<SchedulePayRulePayload>}
     * @memberof SchedulesUpdatePayConfigRequestBody
     */
    pay_rules: Array<SchedulePayRulePayload>;
}

export function SchedulesUpdatePayConfigRequestBodyFromJSON(json: any): SchedulesUpdatePayConfigRequestBody {
    return SchedulesUpdatePayConfigRequestBodyFromJSONTyped(json, false);
}

export function SchedulesUpdatePayConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesUpdatePayConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_rate_cents': json['base_rate_cents'],
        'currency': json['currency'],
        'name': json['name'],
        'overrides': ((json['overrides'] as Array<any>).map(ScheduleReportOverridePayloadFromJSON)),
        'pay_rules': ((json['pay_rules'] as Array<any>).map(SchedulePayRulePayloadFromJSON)),
    };
}

export function SchedulesUpdatePayConfigRequestBodyToJSON(value?: SchedulesUpdatePayConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_rate_cents': value.base_rate_cents,
        'currency': value.currency,
        'name': value.name,
        'overrides': ((value.overrides as Array<any>).map(ScheduleReportOverridePayloadToJSON)),
        'pay_rules': ((value.pay_rules as Array<any>).map(SchedulePayRulePayloadToJSON)),
    };
}

