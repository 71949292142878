import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GenericErrorMessage, Loader } from "@incident-ui";
import React from "react";
import { useParams } from "react-router";
import { useAPI } from "src/utils/swr";

import { AlertRouteCreateEditForm } from "./AlertRouteCreateEditForm";

export const AlertRouteEditPage = (): React.ReactElement => {
  const { id } = useParams();
  if (!id) {
    throw new Error(
      "Attempted to render alert route edit route without an ID in the URL. Are you rendering this in the right place?",
    );
  }

  const { data, isLoading, error } = useAPI("alertRoutesShowAlertRoute", {
    id,
  });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <AlertRouteCreateEditForm mode={Mode.Edit} initialData={data.alert_route} />
  );
};
