import React, { createContext, useContext, useState } from "react";
import {
  IncidentStatusCategoryEnum as StatusCategoryEnum,
  IncidentTimestamp,
  IncidentTimestampTimestampTypeEnum as TimestampTypeEnum,
} from "src/contexts/ClientContext";

import { PreparedLifecycle } from "./prepareLifecycle";

type LifecycleOverviewSectionState = "neutral" | "editing" | "background";

type LifecycleOverviewContextType = {
  getConfigurationState: (
    category: StatusCategoryEnum | undefined,
  ) => LifecycleOverviewSectionState;
  onToggleConfiguring: (category: StatusCategoryEnum | undefined) => void;
  availableTimestamps: IncidentTimestamp[];
};

export const LifecycleOverviewContext =
  createContext<LifecycleOverviewContextType>({
    getConfigurationState: () => "neutral",
    onToggleConfiguring: () => null,
    availableTimestamps: [],
  });

// Provider component which tells any children that want to portal a component
// (e.g. a Popover) to attach it to a specific ref, instead of document.body. This
// means we can correctly handle popovers inside elements like drawers.
export const LifecycleOverviewProvider = ({
  timestamps,
  preparedLifecycle,
  children,
}: {
  timestamps: IncidentTimestamp[];
  preparedLifecycle: PreparedLifecycle;
  children: React.ReactNode;
}): JSX.Element => {
  const [currentlyConfiguring, setCurrentlyConfiguring] =
    useState<StatusCategoryEnum | null>(null);

  const isConfiguring = (category: StatusCategoryEnum | undefined) => {
    if (!category) {
      return false;
    }
    return category === currentlyConfiguring;
  };

  const getConfigurationState = (category: StatusCategoryEnum | undefined) => {
    if (isConfiguring(category)) {
      return "editing";
    }

    return currentlyConfiguring == null ? "neutral" : "background";
  };

  const onToggleConfiguring = (category: StatusCategoryEnum | undefined) => {
    if (!category) {
      return false;
    }
    if (isConfiguring(category)) {
      return setCurrentlyConfiguring(null);
    } else {
      return setCurrentlyConfiguring(category);
    }
  };

  const availableTimestamps = timestamps.filter(
    (t) =>
      // can only use custom timestamps
      t.timestamp_type === TimestampTypeEnum.Custom &&
      // can't use timestamps that are already used
      !preparedLifecycle.allTimestampIds.includes(t.id),
  );

  return (
    <LifecycleOverviewContext.Provider
      value={{
        getConfigurationState,
        onToggleConfiguring,
        availableTimestamps,
      }}
    >
      {children}
    </LifecycleOverviewContext.Provider>
  );
};

export const useLifecycleOverview = (): LifecycleOverviewContextType => {
  return useContext(LifecycleOverviewContext);
};
