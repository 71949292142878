import type { SVGProps } from "react";
import * as React from "react";
const SvgNumberBox3Filled = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#212121"
      fillRule="evenodd"
      d="M14.722 2.222H5.278a3.058 3.058 0 0 0-3.056 3.056v9.444a3.058 3.058 0 0 0 3.056 3.056h9.444a3.058 3.058 0 0 0 3.056-3.056V5.278a3.058 3.058 0 0 0-3.056-3.056Zm-7.29 10.182c.332.693 1.158 1.274 2.5 1.274 1.636 0 2.744-.85 2.744-2.129 0-.971-.678-1.586-1.728-1.684v-.03c.83-.17 1.47-.756 1.47-1.65 0-1.128-1.006-1.865-2.495-1.865-1.246 0-2.046.576-2.359 1.265a1.114 1.114 0 0 0-.107.473c0 .357.21.596.62.596.332 0 .503-.122.664-.454.23-.498.59-.747 1.186-.747.713 0 1.094.376 1.094.942 0 .576-.478.977-1.216.977h-.288c-.356 0-.561.215-.561.522 0 .317.205.527.561.527h.308c.854 0 1.357.381 1.352 1.065 0 .586-.498 1.006-1.22 1.006-.733 0-1.114-.293-1.363-.786-.146-.269-.332-.386-.625-.386-.405 0-.649.24-.649.62 0 .146.034.303.112.464Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNumberBox3Filled;
