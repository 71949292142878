/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    EnrichedAction,
    EnrichedActionFromJSON,
    EnrichedActionFromJSONTyped,
    EnrichedActionToJSON,
} from './EnrichedAction';

/**
 * 
 * @export
 * @interface ActionCreated
 */
export interface ActionCreated {
    /**
     * 
     * @type {EnrichedAction}
     * @memberof ActionCreated
     */
    action: EnrichedAction;
    /**
     * 
     * @type {Actor}
     * @memberof ActionCreated
     */
    creator: Actor;
}

export function ActionCreatedFromJSON(json: any): ActionCreated {
    return ActionCreatedFromJSONTyped(json, false);
}

export function ActionCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionCreated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': EnrichedActionFromJSON(json['action']),
        'creator': ActorFromJSON(json['creator']),
    };
}

export function ActionCreatedToJSON(value?: ActionCreated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': EnrichedActionToJSON(value.action),
        'creator': ActorToJSON(value.creator),
    };
}

