import type { SVGProps } from "react";
import * as React from "react";
const SvgExternalLink = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 3h-8.5A2.75 2.75 0 0 0 3 5.75v6.568c0 1.336 1.616 2.006 2.561 1.06L10.44 8.5H7.751a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V9.56L6.622 14.44c-.945.945-.276 2.56 1.061 2.56h6.568A2.75 2.75 0 0 0 17 14.25v-8.5A2.75 2.75 0 0 0 14.25 3h-.001Z"
    />
  </svg>
);
export default SvgExternalLink;
