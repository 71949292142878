/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomField,
    CustomFieldFromJSON,
    CustomFieldFromJSONTyped,
    CustomFieldToJSON,
} from './CustomField';

/**
 * 
 * @export
 * @interface CustomFieldsShowResponseBody
 */
export interface CustomFieldsShowResponseBody {
    /**
     * 
     * @type {CustomField}
     * @memberof CustomFieldsShowResponseBody
     */
    custom_field: CustomField;
}

export function CustomFieldsShowResponseBodyFromJSON(json: any): CustomFieldsShowResponseBody {
    return CustomFieldsShowResponseBodyFromJSONTyped(json, false);
}

export function CustomFieldsShowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldsShowResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field': CustomFieldFromJSON(json['custom_field']),
    };
}

export function CustomFieldsShowResponseBodyToJSON(value?: CustomFieldsShowResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field': CustomFieldToJSON(value.custom_field),
    };
}

