/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSource,
    AlertSourceFromJSON,
    AlertSourceFromJSONTyped,
    AlertSourceToJSON,
} from './AlertSource';

/**
 * 
 * @export
 * @interface AlertsListSourcesResponseBody
 */
export interface AlertsListSourcesResponseBody {
    /**
     * 
     * @type {Array<AlertSource>}
     * @memberof AlertsListSourcesResponseBody
     */
    alert_sources: Array<AlertSource>;
}

export function AlertsListSourcesResponseBodyFromJSON(json: any): AlertsListSourcesResponseBody {
    return AlertsListSourcesResponseBodyFromJSONTyped(json, false);
}

export function AlertsListSourcesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsListSourcesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_sources': ((json['alert_sources'] as Array<any>).map(AlertSourceFromJSON)),
    };
}

export function AlertsListSourcesResponseBodyToJSON(value?: AlertsListSourcesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_sources': ((value.alert_sources as Array<any>).map(AlertSourceToJSON)),
    };
}

