/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleRotationHandover
 */
export interface ScheduleRotationHandover {
    /**
     * How many of the interval type we use to form a single rotation
     * @type {number}
     * @memberof ScheduleRotationHandover
     */
    interval: number;
    /**
     * Whether we rotate hourly, daily or weekly
     * @type {string}
     * @memberof ScheduleRotationHandover
     */
    interval_type: ScheduleRotationHandoverIntervalTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleRotationHandoverIntervalTypeEnum {
    Hourly = 'hourly',
    Daily = 'daily',
    Weekly = 'weekly'
}

export function ScheduleRotationHandoverFromJSON(json: any): ScheduleRotationHandover {
    return ScheduleRotationHandoverFromJSONTyped(json, false);
}

export function ScheduleRotationHandoverFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleRotationHandover {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interval': json['interval'],
        'interval_type': json['interval_type'],
    };
}

export function ScheduleRotationHandoverToJSON(value?: ScheduleRotationHandover | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interval': value.interval,
        'interval_type': value.interval_type,
    };
}

