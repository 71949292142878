import cx from "classnames";

import styles from "./LoadingBar.module.scss";

export const LoadingBar = ({
  className,
}: {
  className?: string;
}): React.ReactElement => (
  <div
    className={cx("h-full w-full rounded", styles.loadingGradient, className)}
  />
);
