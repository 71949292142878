/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersUpdateSeatAndRoleRequestBody
 */
export interface UsersUpdateSeatAndRoleRequestBody {
    /**
     * The slug (user, admin, owner) of the base role that this user should have.
     * @type {string}
     * @memberof UsersUpdateSeatAndRoleRequestBody
     */
    base_role_slug: UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum;
    /**
     * IDs of custom roles to be assigned to this user
     * @type {Array<string>}
     * @memberof UsersUpdateSeatAndRoleRequestBody
     */
    custom_role_ids: Array<string>;
    /**
     * The seats that this user should have
     * @type {Array<string>}
     * @memberof UsersUpdateSeatAndRoleRequestBody
     */
    seat_types?: Array<UsersUpdateSeatAndRoleRequestBodySeatTypesEnum>;
    /**
     * The state that this user is in
     * @type {string}
     * @memberof UsersUpdateSeatAndRoleRequestBody
     */
    state?: UsersUpdateSeatAndRoleRequestBodyStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum UsersUpdateSeatAndRoleRequestBodyBaseRoleSlugEnum {
    User = 'user',
    Admin = 'admin',
    Owner = 'owner'
}/**
* @export
* @enum {string}
*/
export enum UsersUpdateSeatAndRoleRequestBodySeatTypesEnum {
    OnCall = 'on_call',
    Responder = 'responder',
    Viewer = 'viewer',
    OnCallResponder = 'on_call_responder'
}/**
* @export
* @enum {string}
*/
export enum UsersUpdateSeatAndRoleRequestBodyStateEnum {
    Viewer = 'viewer',
    Responder = 'responder',
    OnCall = 'on_call',
    OnCallResponder = 'on_call_responder'
}

export function UsersUpdateSeatAndRoleRequestBodyFromJSON(json: any): UsersUpdateSeatAndRoleRequestBody {
    return UsersUpdateSeatAndRoleRequestBodyFromJSONTyped(json, false);
}

export function UsersUpdateSeatAndRoleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersUpdateSeatAndRoleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_role_slug': json['base_role_slug'],
        'custom_role_ids': json['custom_role_ids'],
        'seat_types': !exists(json, 'seat_types') ? undefined : json['seat_types'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function UsersUpdateSeatAndRoleRequestBodyToJSON(value?: UsersUpdateSeatAndRoleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_role_slug': value.base_role_slug,
        'custom_role_ids': value.custom_role_ids,
        'seat_types': value.seat_types,
        'state': value.state,
    };
}

