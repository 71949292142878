import {
  IncidentSummarySuggestion,
  IncidentsUpdateSummaryRequestBody,
  IncidentsUpdateSummaryRequestBodySourceEnum,
} from "@incident-io/api";
import { marshallTextDocumentPayload } from "@incident-shared/incident-forms";
import { useAPIMutation } from "src/utils/swr";

export const useUpdateSummary = (
  incidentId: string,
  suggestion: IncidentSummarySuggestion | undefined,
  onSuccess: () => void,
) => {
  return useAPIMutation(
    "incidentsShow",
    { id: incidentId },
    async (apiClient, data: IncidentsUpdateSummaryRequestBody) => {
      if (suggestion && data.summary) {
        // If there's a suggestion, we're accepting (and maybe editing) a suggestion
        await apiClient.incidentsUpdateSummaryFromSuggestion({
          updateSummaryFromSuggestionRequestBody: {
            summary: data.summary,
            notify_channel: data.notify_slack_channel,
            incident_summary_suggestion_id: suggestion.id,
          },
          id: incidentId,
        });
      } else {
        // Otherwise, it's just a regular incident update
        await apiClient.incidentsUpdateSummary({
          updateSummaryRequestBody: {
            summary: marshallTextDocumentPayload(data.summary),
            notify_slack_channel: data.notify_slack_channel,
            source: IncidentsUpdateSummaryRequestBodySourceEnum.Human,
          },
          id: incidentId,
        });
      }
    },
    {
      onSuccess: onSuccess,
    },
  );
};
