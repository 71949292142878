/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnCallNotificationMethodV2,
    OnCallNotificationMethodV2FromJSON,
    OnCallNotificationMethodV2FromJSONTyped,
    OnCallNotificationMethodV2ToJSON,
} from './OnCallNotificationMethodV2';

/**
 * 
 * @export
 * @interface OnCallNotificationsListMethodsV2ResponseBody
 */
export interface OnCallNotificationsListMethodsV2ResponseBody {
    /**
     * 
     * @type {Array<OnCallNotificationMethodV2>}
     * @memberof OnCallNotificationsListMethodsV2ResponseBody
     */
    methods: Array<OnCallNotificationMethodV2>;
}

export function OnCallNotificationsListMethodsV2ResponseBodyFromJSON(json: any): OnCallNotificationsListMethodsV2ResponseBody {
    return OnCallNotificationsListMethodsV2ResponseBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsListMethodsV2ResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsListMethodsV2ResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'methods': ((json['methods'] as Array<any>).map(OnCallNotificationMethodV2FromJSON)),
    };
}

export function OnCallNotificationsListMethodsV2ResponseBodyToJSON(value?: OnCallNotificationsListMethodsV2ResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'methods': ((value.methods as Array<any>).map(OnCallNotificationMethodV2ToJSON)),
    };
}

