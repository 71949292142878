import type { SVGProps } from "react";
import * as React from "react";
const SvgGitlab = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#E24329"
      d="m17.733 8.103-.017-.05-2.19-5.695a.58.58 0 0 0-.22-.27.485.485 0 0 0-.336-.084.627.627 0 0 0-.337.118.452.452 0 0 0-.186.287l-1.465 4.498H7.017L5.551 2.41a.65.65 0 0 0-.185-.287.582.582 0 0 0-.337-.118.592.592 0 0 0-.337.085.493.493 0 0 0-.219.27l-2.19 5.677-.017.05a4.024 4.024 0 0 0 1.348 4.668l.017.017 3.319 2.476 1.634 1.247.994.758a.658.658 0 0 0 .809 0l.994-.758 1.634-1.247 3.336-2.493h.017c1.466-1.062 2.005-2.966 1.365-4.65Z"
    />
    <path
      fill="#FC6D26"
      d="m17.733 8.103-.017-.05a7.348 7.348 0 0 0-2.932 1.314c-.017 0-2.594 1.954-4.785 3.606 1.635 1.23 3.05 2.308 3.05 2.308l3.336-2.494h.017a4.072 4.072 0 0 0 1.33-4.684Z"
    />
    <path
      fill="#FCA326"
      d="m6.95 15.281 1.634 1.247.994.758a.658.658 0 0 0 .809 0l.994-.758 1.634-1.247s-1.415-1.078-3.05-2.308c-1.6 1.23-3.015 2.308-3.015 2.308Z"
    />
    <path
      fill="#FC6D26"
      d="M5.214 9.35a7.348 7.348 0 0 0-2.931-1.314l-.017.05a4.024 4.024 0 0 0 1.348 4.668l.017.017 3.319 2.476S8.365 14.17 10 12.94A448.1 448.1 0 0 1 5.213 9.35Z"
    />
  </svg>
);
export default SvgGitlab;
