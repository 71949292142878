import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Avatar,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Link,
} from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import {
  DebriefInviteRule,
  EngineParamBindingValue,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSortableList } from "../SettingsSortableList";
import { SettingsSubHeading } from "../SettingsSubHeading";
import { CreateEditDebriefInviteRuleModal } from "./CreateEditDebriefInviteRuleModal";

const connectedUserID = "connected_user";

export const DebriefInviteesSection = ({
  inviteRules,
}: {
  inviteRules: DebriefInviteRule[];
}): React.ReactElement => {
  const { integrations } = useIntegrations();

  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const [showInviteRuleModal, setShowAddInviteRuleModal] =
    useState<boolean>(false);
  const [editingInviteRule, setEditingInviteRule] =
    useState<DebriefInviteRule | null>(null);

  const { trigger: onDelete, isMutating: deleting } = useAPIMutation(
    "debriefsListInviteRules",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.debriefsDestroyInviteRule({ id });
    },
  );

  const { trigger: updateRanks, isMutating: updatingRanks } = useAPIMutation(
    "debriefsListInviteRules",
    undefined,
    async (apiClient, updatedInviteRules: DebriefInviteRule[]) => {
      const rank_updates = inviteRules.map((rule) => {
        let rank = rule.rank;
        const updated = updatedInviteRules.find((x) => x.id === rule.id);
        if (updated) {
          rank = updated.rank;
        }
        return {
          resource_id: rule.id,
          rank,
        };
      });
      return await apiClient.debriefsUpdateInviteRuleRanks({
        updateInviteRuleRanksRequestBody: { rank_updates },
      });
    },
  );
  const saving = deleting || updatingRanks;

  const GCalIntegration = integrations?.find(
    (integration) =>
      integration.provider === IntegrationProvider.GoogleCalendar,
  );
  if (!GCalIntegration) {
    throw new Error("unreachable: GCal integration not found");
  }
  const googleCalendarIsInstalled = GCalIntegration.installed;
  const googleCalendarIsBroken = GCalIntegration.reconnection_reason !== "";

  return (
    <div>
      <SettingsSubHeading
        title={"Invitees"}
        titleHeadingLevel={2}
        explanationClassName="mr-4 mt-1 max-w-4xl"
        explanation={
          <>
            {`You can decide who should be invited to the debrief event we pre-populate for you. Users can always modify this list when scheduling a debrief.`}
            {googleCalendarIsInstalled &&
              !googleCalendarIsBroken && ( // If the connection is broken, we might not be able to show the connected user here.
                <InviteConnectedUser />
              )}
          </>
        }
        accessory={
          <AddInviteeButton onClick={() => setShowAddInviteRuleModal(true)} />
        }
      />
      {inviteRules.length === 0 && (
        <EmptyState
          icon={IconEnum.Users}
          content="We won't automatically invite anyone to your debriefs"
          cta={
            <AddInviteeButton onClick={() => setShowAddInviteRuleModal(true)} />
          }
        />
      )}

      <SettingsSortableList
        canEdit={canEditSettings}
        updateItemRanks={updateRanks}
        saving={saving}
        items={_.sortBy(inviteRules, (qa) => qa.rank)}
        getNotConfigurableText={(rule) =>
          rule.id === connectedUserID
            ? "We invite the user who is connected to your Google Workspace integration so that we can detect the event."
            : undefined
        }
        renderItem={(rule) => (
          <DebriefInviteRuleRow
            key={rule.id}
            rule={rule}
            onEdit={() => setEditingInviteRule(rule)}
            onDelete={() => onDelete(rule)}
          />
        )}
      />
      {showInviteRuleModal && (
        <CreateEditDebriefInviteRuleModal
          mode={Mode.Create}
          onClose={() => setShowAddInviteRuleModal(false)}
        />
      )}
      {editingInviteRule && (
        <CreateEditDebriefInviteRuleModal
          mode={Mode.Edit}
          initialData={editingInviteRule}
          onClose={() => setEditingInviteRule(null)}
        />
      )}
    </div>
  );
};

const InviteConnectedUser = (): React.ReactElement => {
  const { data: configData } = useAPI(
    "integrationsGoogleWorkspaceGetConfig",
    undefined,
  );
  const user = configData?.config?.connecting_user;

  return (
    <>
      {" "}
      Note that we&apos;ll automatically invite your connected user{" "}
      <Link
        to="/settings/integrations/google-calendar"
        analyticsTrackingId={null}
      >
        {user?.avatar_url && (
          <Avatar
            size={IconSize.Medium}
            url={user?.avatar_url}
            name={user?.name}
            className="mx-1 inline"
          />
        )}
        {user?.name ? (
          <span className="font-semibold">
            {user.name} ({user?.email})
          </span>
        ) : (
          <span className="font-semibold"> {user?.email}</span>
        )}
      </Link>{" "}
      in order to detect the event.
    </>
  );
};

const AddInviteeButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <GatedButton
      onClick={onClick}
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      analyticsTrackingId="add-debrief-invite-rule"
      icon={IconEnum.Add}
      theme={ButtonTheme.Secondary}
    >
      Add invitee
    </GatedButton>
  );
};

const DebriefInviteRuleRow = ({
  rule,
  onDelete,
  onEdit,
}: {
  rule: DebriefInviteRule;
  onDelete: () => Promise<unknown>;
  onEdit: () => void;
}): React.ReactElement => {
  return (
    <SettingsListItem
      title={buildRuleName(rule)}
      groups={rule.condition_groups ?? []}
      buttons={{
        requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
        edit: {
          onEdit,
        },
        delete: {
          resourceTitle: "invitee",
          onDelete,
          deleteConfirmationTitle: "Delete invitee",
          deleteConfirmationContent: (
            // This is not as helpful as it could be, but its hard to name the invitee when there can be
            // multiple and they might be expressions or references.
            <>Are you sure you want to remove this invitee?</>
          ),
        },
      }}
    />
  );
};

function buildRuleName(rule: DebriefInviteRule): React.ReactNode {
  const bindings: EngineParamBindingValue[] = [];

  rule.invitees.users?.array_value?.forEach((user) => {
    bindings.push(user);
  });
  rule.invitees.emails?.array_value?.forEach((email) => {
    bindings.push(email);
  });

  return (
    <div className="text-slate-700 text-sm flex space-x-1">
      {bindings.map((binding) => buildInvitee(binding))}
    </div>
  );
}

function buildInvitee(binding: EngineParamBindingValue): React.ReactNode {
  // Default to using a generic icon
  let icon = <Icon id={IconEnum.User} className="!mr-1.5 text-slate-600" />;

  // If we have a user with an image, use that image
  if (binding.image_url) {
    icon = (
      <Avatar
        size={IconSize.Large}
        url={binding.image_url}
        name={binding.label}
        className="mr-1.5"
      />
    );
    // If we have an expression, use our expression icon
  } else if (binding.reference && binding.reference.includes("expressions")) {
    icon = <Icon id={IconEnum.Expression} className="!mr-1.5 text-slate-600" />;
  }

  return (
    <div className="text-slate-700 text-sm rounded-2 border border-stroke flex-center-y text-left px-2 py-1.5 shrink-0">
      {icon}
      <span className="text-right truncate">{binding.label}</span>
    </div>
  );
}
