import {
  IntegrationConfigFor,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import {
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
  Txt,
} from "@incident-ui";
import React from "react";

import { InstallIntegrationButton } from "../../common/InstallIntegrationButton";

export const ConnectToSalesforceModal = ({
  integration,
  onClose,
  isReconnect,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
  isReconnect?: boolean;
}): React.ReactElement => {
  const config = IntegrationConfigFor(integration.provider);

  return (
    <Modal
      title={`Connect to ${config.label}`}
      isOpen
      analyticsTrackingId="connect-integration-deeplink"
      analyticsTrackingMetadata={{ integration: integration.provider }}
      onClose={onClose}
    >
      <ModalContent>
        <p className="text-sm text-slate-700">
          We&apos;re about to send you to {config.label}, where (if you give
          permission) you can set up the incident.io integration.
        </p>
        <Callout theme={CalloutTheme.Plain} className="my-4">
          <Txt bold className="mb-1">
            We recommend using a service account
          </Txt>
          <Txt className="mb-1">
            Any users who have permissions to manage Catalog types and entries
            will be able to choose what data to sync.
          </Txt>
          <Txt className="mb-1">
            To control what can be synced, connect using a service account that
            only has access to the required objects and fields.
          </Txt>
        </Callout>
        <p className="text-sm text-slate-700">
          Once installed, you&apos;ll be redirected back to the integrations
          page.
        </p>
      </ModalContent>
      <ModalFooter>
        <InstallIntegrationButton
          integration={integration}
          onOAuthModal={true}
          isReconnect={isReconnect}
        />
      </ModalFooter>
    </Modal>
  );
};
