import type { SVGProps } from "react";
import * as React from "react";
const SvgQuote = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.123 3.478a.75.75 0 0 1-.427.971c-.426.165-.692.375-.87.671-.121.204-.223.483-.279.88H5.75a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-.75.75h-2A.75.75 0 0 1 3 8.25v-1.5c0-.97.16-1.768.54-2.401.39-.652.962-1.046 1.612-1.298a.75.75 0 0 1 .971.427ZM13.25 4a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Zm-.75 4.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75ZM3.75 11a.75.75 0 1 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75Zm0 3.5a.75.75 0 1 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75Zm6.446-10.05a.75.75 0 1 0-.544-1.4c-.65.253-1.221.647-1.612 1.299-.38.633-.54 1.43-.54 2.401v1.5c0 .414.336.75.75.75h2a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75H9.047c.056-.397.158-.676.28-.88.177-.296.443-.506.869-.67Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQuote;
