/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageContentSubPagesStatusSummary
 */
export interface StatusPageContentSubPagesStatusSummary {
    /**
     * Extra info about a sub-page, to help someone pick the one they're interested in.
     * @type {string}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    description?: string;
    /**
     * Unique ID for the sub-page
     * @type {string}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    intro_text?: TextNode;
    /**
     * The title for the sub-page (e.g. the region name)
     * @type {string}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    name: string;
    /**
     * Primary URL where this sub-page is served.
     * @type {string}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    public_url: string;
    /**
     * List of ISO-3166-2 country codes for which we want to redirect
     * @type {Array<string>}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    redirect_countries: Array<string>;
    /**
     * Path within the parent page for this sub-page
     * @type {string}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    subpath: string;
    /**
     * The worst component status during this time period
     * @type {string}
     * @memberof StatusPageContentSubPagesStatusSummary
     */
    worst_component_status: StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageContentSubPagesStatusSummaryWorstComponentStatusEnum {
    Operational = 'operational',
    UnderMaintenance = 'under_maintenance',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    FullOutage = 'full_outage'
}

export function StatusPageContentSubPagesStatusSummaryFromJSON(json: any): StatusPageContentSubPagesStatusSummary {
    return StatusPageContentSubPagesStatusSummaryFromJSONTyped(json, false);
}

export function StatusPageContentSubPagesStatusSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentSubPagesStatusSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'intro_text': !exists(json, 'intro_text') ? undefined : TextNodeFromJSON(json['intro_text']),
        'name': json['name'],
        'public_url': json['public_url'],
        'redirect_countries': json['redirect_countries'],
        'subpath': json['subpath'],
        'worst_component_status': json['worst_component_status'],
    };
}

export function StatusPageContentSubPagesStatusSummaryToJSON(value?: StatusPageContentSubPagesStatusSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'id': value.id,
        'intro_text': TextNodeToJSON(value.intro_text),
        'name': value.name,
        'public_url': value.public_url,
        'redirect_countries': value.redirect_countries,
        'subpath': value.subpath,
        'worst_component_status': value.worst_component_status,
    };
}

