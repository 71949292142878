/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NudgePreviewButton,
    NudgePreviewButtonFromJSON,
    NudgePreviewButtonFromJSONTyped,
    NudgePreviewButtonToJSON,
} from './NudgePreviewButton';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface NudgesRenderPreviewResponseBody
 */
export interface NudgesRenderPreviewResponseBody {
    /**
     * The buttons on the message
     * @type {Array<NudgePreviewButton>}
     * @memberof NudgesRenderPreviewResponseBody
     */
    buttons: Array<NudgePreviewButton>;
    /**
     * 
     * @type {TextNode}
     * @memberof NudgesRenderPreviewResponseBody
     */
    message_content: TextNode;
}

export function NudgesRenderPreviewResponseBodyFromJSON(json: any): NudgesRenderPreviewResponseBody {
    return NudgesRenderPreviewResponseBodyFromJSONTyped(json, false);
}

export function NudgesRenderPreviewResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgesRenderPreviewResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buttons': ((json['buttons'] as Array<any>).map(NudgePreviewButtonFromJSON)),
        'message_content': TextNodeFromJSON(json['message_content']),
    };
}

export function NudgesRenderPreviewResponseBodyToJSON(value?: NudgesRenderPreviewResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buttons': ((value.buttons as Array<any>).map(NudgePreviewButtonToJSON)),
        'message_content': TextNodeToJSON(value.message_content),
    };
}

