import {
  hideTriageMetrics,
  TriageIncidentStatuses,
  TriageIncidentTimestamps,
} from "@incident-shared/triage-incidents";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useAllStatuses } from "src/components/legacy/incident/useIncidentCrudResources";
import {
  IncidentDurationMetric,
  IncidentStatus,
  IncidentTimestamp,
} from "src/contexts/ClientContext";

import { LifecyclePage } from "../../../components/settings/lifecycle/LifecyclePage";
import { useIncidentTriageIncidentsFeatureGateEnabled } from "../../../utils/incident-manual-triage";
import { useAPI } from "../../../utils/swr";

export const LifecyclePageWrapper = (): React.ReactElement => {
  const { timestamps, metrics, isLoading } = useLifecyclePageDeps();

  if (isLoading) {
    return <FullPageLoader />;
  }

  return <LifecyclePage timestamps={timestamps} metrics={metrics} />;
};

export const useLifecyclePageDeps = (): {
  isLoading: boolean;
  statuses: IncidentStatus[];
  timestamps: IncidentTimestamp[];
  metrics: IncidentDurationMetric[];
} => {
  const featureGateTriageIncidents =
    useIncidentTriageIncidentsFeatureGateEnabled();

  const { allStatuses, allStatusesLoading } = useAllStatuses();

  const {
    data: { incident_timestamps: timestamps },
    isLoading: timestampsLoading,
  } = useAPI("incidentTimestampsList", undefined, {
    fallbackData: { incident_timestamps: [] },
  });

  const {
    data: { incident_duration_metrics: metrics },
    isLoading: metricsLoading,
  } = useAPI("incidentTimestampsListDurationMetrics", undefined, {
    fallbackData: { incident_duration_metrics: [] },
  });

  if (allStatusesLoading || timestampsLoading || metricsLoading) {
    return { isLoading: true, statuses: [], timestamps: [], metrics: [] };
  }

  // If the org is not on pro, hide the timestamps related to triage.
  const hideTriage = !featureGateTriageIncidents;

  let visibleTimestamps = timestamps;
  if (hideTriage) {
    visibleTimestamps = timestamps.filter(
      (timestamp) =>
        !TriageIncidentTimestamps.includes(timestamp.timestamp_type),
    );
  }

  // Similarly, hide the statuses related to triage.
  let visibleStatuses = allStatuses;
  if (hideTriage) {
    visibleStatuses = allStatuses.filter(
      (status) => !TriageIncidentStatuses.includes(status.category),
    );
  }

  // Similarly, hide any durations if they involve a timestamp that isn't visible (this might happen
  // if an Org downgrades from Pro).
  let visibleMetrics = metrics || [];
  if (hideTriage) {
    visibleMetrics = hideTriageMetrics(timestamps, metrics);
  }

  return {
    isLoading: false,
    statuses: visibleStatuses,
    timestamps: visibleTimestamps,
    metrics: visibleMetrics,
  };
};
