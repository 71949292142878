import {
  InsightsCreateDashboardRequestBodyColorEnum,
  InsightsCreateDashboardRequestBodyIconEnum as DashboardIconEnum,
} from "@incident-io/api";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { DashboardDetailsFormData } from "./CreateCustomDashboardModal";

export const DashboardColorIconPicker = () => {
  const formMethods = useFormContext<DashboardDetailsFormData>();
  const [currentColor, currentIcon] = formMethods.watch(["color", "icon"]);

  if (!currentColor && !currentIcon) {
    formMethods.setValue<"color">(
      "color",
      InsightsCreateDashboardRequestBodyColorEnum.Pink,
    );
    formMethods.setValue<"icon">("icon", DashboardIconEnum.Chart);
  }

  return (
    <div className="flex flex-col gap-2">
      <div className={"shadow border-stroke border rounded-[6px]"}>
        <div className="bg-surface-secondary rounded-t-md p-2 border-stroke border-b">
          <ColorPicker
            onClick={(color) => formMethods.setValue<"color">("color", color)}
            currentColor={currentColor}
          />
        </div>
        <IconPicker
          onClick={(icon) => formMethods.setValue<"icon">("icon", icon)}
          currentIcon={currentIcon}
          currentColor={currentColor}
        />
      </div>
    </div>
  );
};

const ColorPicker = ({
  onClick,
  currentColor,
}: {
  onClick: (newColor: string) => void;
  currentColor: string;
}) => {
  const colors = Object.entries(InsightsCreateDashboardRequestBodyColorEnum);

  return (
    <div className="flex justify-center gap-6">
      {colors.map(([colorName, colorVal]) => {
        const colorClass = getColorPalette(
          colorVal as unknown as ColorPaletteEnum,
        );
        const isSelected = currentColor === colorVal;
        return (
          <div
            key={colorName}
            className={tcx(
              "p-[2px] rounded-full flex items-center justify-center",
              isSelected ? colorClass.preview : "bg-surface-secondary",
            )}
          >
            <div
              className={tcx(
                colorClass.preview,
                "rounded-full w-5 h-5 flex-center group cursor-pointer border-2 border-slate-100",
              )}
              onClick={() => onClick(colorVal)}
            >
              {isSelected ? (
                <Icon
                  id={IconEnum.Tick}
                  size={IconSize.Small}
                  className={"text-white"}
                />
              ) : (
                <Icon
                  id={IconEnum.Tick}
                  size={IconSize.Small}
                  className={
                    "text-white opacity-50 invisible group-hover:visible"
                  }
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const IconPicker = ({
  onClick,
  currentIcon,
  currentColor,
}: {
  onClick: (newIcon: string) => void;
  currentIcon: string;
  currentColor: string;
}) => {
  const selectedPalette = getColorPalette(currentColor as ColorPaletteEnum);
  const unselectedPalette = getColorPalette(ColorPaletteEnum.Slate);

  return (
    <div className="grid grid-cols-6 lg:grid-cols-12 gap-4 justify-items-center px-8 p-4">
      {DASHBOARD_ICONS.map((icon) => {
        const isSelected = currentIcon === icon;
        const palette = isSelected ? selectedPalette : unselectedPalette;
        return (
          <Icon
            key={icon}
            id={icon as unknown as IconEnum}
            onClick={() => onClick(icon)}
            size={IconSize.Large}
            className={tcx(
              palette.background,
              palette.border,
              palette.icon,
              "inline hover:border-slate-900 rounded cursor-pointer p-0.5",
            )}
          />
        );
      })}
    </div>
  );
};

const DASHBOARD_ICONS = [
  DashboardIconEnum.Chart,
  DashboardIconEnum.Warning,
  DashboardIconEnum.OnCall,
  DashboardIconEnum.Bulb,
  DashboardIconEnum.Escalate,
  DashboardIconEnum.Message,
  DashboardIconEnum.Priority,
  DashboardIconEnum.Calendar,
  DashboardIconEnum.Triage,
  DashboardIconEnum.Book,
  DashboardIconEnum.Briefcase,
  DashboardIconEnum.Globe,
  DashboardIconEnum.Server,
  DashboardIconEnum.Status,
  DashboardIconEnum.IncidentType,
  DashboardIconEnum.Cog,
  DashboardIconEnum.Flag,
  DashboardIconEnum.Users,
  DashboardIconEnum.Action,
  DashboardIconEnum.Bolt,
  DashboardIconEnum.Severity,
  DashboardIconEnum.Key,
  DashboardIconEnum.Tag,
  DashboardIconEnum.Incident,
];
