/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageStructureComponent,
    StatusPageStructureComponentFromJSON,
    StatusPageStructureComponentFromJSONTyped,
    StatusPageStructureComponentToJSON,
} from './StatusPageStructureComponent';
import {
    StatusPageStructureGroup,
    StatusPageStructureGroupFromJSON,
    StatusPageStructureGroupFromJSONTyped,
    StatusPageStructureGroupToJSON,
} from './StatusPageStructureGroup';

/**
 * 
 * @export
 * @interface StatusPageStructureItem
 */
export interface StatusPageStructureItem {
    /**
     * 
     * @type {StatusPageStructureComponent}
     * @memberof StatusPageStructureItem
     */
    component?: StatusPageStructureComponent;
    /**
     * 
     * @type {StatusPageStructureGroup}
     * @memberof StatusPageStructureItem
     */
    group?: StatusPageStructureGroup;
}

export function StatusPageStructureItemFromJSON(json: any): StatusPageStructureItem {
    return StatusPageStructureItemFromJSONTyped(json, false);
}

export function StatusPageStructureItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageStructureItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': !exists(json, 'component') ? undefined : StatusPageStructureComponentFromJSON(json['component']),
        'group': !exists(json, 'group') ? undefined : StatusPageStructureGroupFromJSON(json['group']),
    };
}

export function StatusPageStructureItemToJSON(value?: StatusPageStructureItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': StatusPageStructureComponentToJSON(value.component),
        'group': StatusPageStructureGroupToJSON(value.group),
    };
}

