import type { SVGProps } from "react";
import * as React from "react";
const SvgAutomation = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.152 16.958a.833.833 0 0 1-.79-.57L4.824 8.773a.834.834 0 0 1 1.582-.528l2.537 7.615a.834.834 0 0 1-.79 1.098ZM16.742 8.357l-2.684.671-1.181-2.362 1.18-2.362 2.685.67a.832.832 0 1 0 .406-1.616l-3.333-.833a.832.832 0 0 0-.948.435L11.43 5.833H6.944a.834.834 0 0 0 0 1.666h4.485l1.437 2.872a.835.835 0 0 0 .947.437l3.334-.833a.833.833 0 1 0-.406-1.617h.001Z"
    />
    <path
      fill="currentColor"
      d="M5 9.166a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM16.111 15.277H3.89a1.667 1.667 0 1 0 0 3.333H16.11a1.667 1.667 0 1 0 0-3.333Z"
    />
  </svg>
);
export default SvgAutomation;
