/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface IssueTrackerFieldValue
 */
export interface IssueTrackerFieldValue {
    /**
     * The name of the param this value is for
     * @type {string}
     * @memberof IssueTrackerFieldValue
     */
    key: string;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof IssueTrackerFieldValue
     */
    param_binding?: EngineParamBinding;
}

export function IssueTrackerFieldValueFromJSON(json: any): IssueTrackerFieldValue {
    return IssueTrackerFieldValueFromJSONTyped(json, false);
}

export function IssueTrackerFieldValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackerFieldValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'param_binding': !exists(json, 'param_binding') ? undefined : EngineParamBindingFromJSON(json['param_binding']),
    };
}

export function IssueTrackerFieldValueToJSON(value?: IssueTrackerFieldValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'param_binding': EngineParamBindingToJSON(value.param_binding),
    };
}

