/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PanelVariableFormField,
    PanelVariableFormFieldFromJSON,
    PanelVariableFormFieldFromJSONTyped,
    PanelVariableFormFieldToJSON,
} from './PanelVariableFormField';
import {
    PanelVariableValue,
    PanelVariableValueFromJSON,
    PanelVariableValueFromJSONTyped,
    PanelVariableValueToJSON,
} from './PanelVariableValue';

/**
 * 
 * @export
 * @interface PanelVariable
 */
export interface PanelVariable {
    /**
     * 
     * @type {PanelVariableFormField}
     * @memberof PanelVariable
     */
    form_field: PanelVariableFormField;
    /**
     * 
     * @type {PanelVariableValue}
     * @memberof PanelVariable
     */
    value: PanelVariableValue;
}

export function PanelVariableFromJSON(json: any): PanelVariable {
    return PanelVariableFromJSONTyped(json, false);
}

export function PanelVariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelVariable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form_field': PanelVariableFormFieldFromJSON(json['form_field']),
        'value': PanelVariableValueFromJSON(json['value']),
    };
}

export function PanelVariableToJSON(value?: PanelVariable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_field': PanelVariableFormFieldToJSON(value.form_field),
        'value': PanelVariableValueToJSON(value.value),
    };
}

