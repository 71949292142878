/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentsUpdateSummaryFromSuggestionRequestBody
 */
export interface IncidentsUpdateSummaryFromSuggestionRequestBody {
    /**
     * The ID of the suggestion that this update was based on
     * @type {string}
     * @memberof IncidentsUpdateSummaryFromSuggestionRequestBody
     */
    incident_summary_suggestion_id: string;
    /**
     * If true, notify the channel of the resulting incident update
     * @type {boolean}
     * @memberof IncidentsUpdateSummaryFromSuggestionRequestBody
     */
    notify_channel: boolean;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentsUpdateSummaryFromSuggestionRequestBody
     */
    summary: TextDocumentPayload;
}

export function IncidentsUpdateSummaryFromSuggestionRequestBodyFromJSON(json: any): IncidentsUpdateSummaryFromSuggestionRequestBody {
    return IncidentsUpdateSummaryFromSuggestionRequestBodyFromJSONTyped(json, false);
}

export function IncidentsUpdateSummaryFromSuggestionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsUpdateSummaryFromSuggestionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_summary_suggestion_id': json['incident_summary_suggestion_id'],
        'notify_channel': json['notify_channel'],
        'summary': TextDocumentPayloadFromJSON(json['summary']),
    };
}

export function IncidentsUpdateSummaryFromSuggestionRequestBodyToJSON(value?: IncidentsUpdateSummaryFromSuggestionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_summary_suggestion_id': value.incident_summary_suggestion_id,
        'notify_channel': value.notify_channel,
        'summary': TextDocumentPayloadToJSON(value.summary),
    };
}

