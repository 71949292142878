/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';

/**
 * 
 * @export
 * @interface DebriefInvitees
 */
export interface DebriefInvitees {
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof DebriefInvitees
     */
    emails?: EngineParamBinding;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof DebriefInvitees
     */
    users?: EngineParamBinding;
}

export function DebriefInviteesFromJSON(json: any): DebriefInvitees {
    return DebriefInviteesFromJSONTyped(json, false);
}

export function DebriefInviteesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefInvitees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': !exists(json, 'emails') ? undefined : EngineParamBindingFromJSON(json['emails']),
        'users': !exists(json, 'users') ? undefined : EngineParamBindingFromJSON(json['users']),
    };
}

export function DebriefInviteesToJSON(value?: DebriefInvitees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': EngineParamBindingToJSON(value.emails),
        'users': EngineParamBindingToJSON(value.users),
    };
}

