/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface TextDocument
 */
export interface TextDocument {
    /**
     * the original Markdown string
     * @type {string}
     * @memberof TextDocument
     */
    markdown: string;
    /**
     * 
     * @type {TextNode}
     * @memberof TextDocument
     */
    text_node: TextNode;
}

export function TextDocumentFromJSON(json: any): TextDocument {
    return TextDocumentFromJSONTyped(json, false);
}

export function TextDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'markdown': json['markdown'],
        'text_node': TextNodeFromJSON(json['text_node']),
    };
}

export function TextDocumentToJSON(value?: TextDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'markdown': value.markdown,
        'text_node': TextNodeToJSON(value.text_node),
    };
}

