import { ExpressionsFormMethods } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { FieldArrayWithId } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

// useIncidentFormExpressionsMethods is a hook which returns the methods as if we were using useFieldArray,
// but instead we are just pushing updates to the back end whenever the user makes a change. This is probably
// the direction of travel we're going to want with our forms more generally, I suspect, so this is a bit
// of an experiment.
export const useIncidentFormExpressionsMethods = ({
  expressions,
  incidentFormId,
}: {
  expressions: ExpressionFormData[];
  incidentFormId: string;
}): {
  expressionsMethods: ExpressionsFormMethods<
    { expressions: ExpressionFormData[] },
    "expressions"
  >;
  saving: boolean;
  genericError?: string;
} => {
  const {
    trigger: createExpression,
    isMutating: createSaving,
    genericError: createError,
  } = useAPIMutation(
    "incidentFormsShowForm",
    { id: incidentFormId },
    async (apiClient, expression: ExpressionFormData) => {
      await apiClient.incidentFormsCreateExpression({
        incidentFormId,
        createExpressionRequestBody: {
          expression: expressionToPayload(expression),
        },
      });
    },
  );

  const {
    trigger: updateExpression,
    isMutating: updateSaving,
    genericError: updateError,
  } = useAPIMutation(
    "incidentFormsShowForm",
    { id: incidentFormId },
    async (apiClient, expression: ExpressionFormData) => {
      if (!expression.id) {
        throw new Error("Expression must have an id to update");
      }
      await apiClient.incidentFormsUpdateExpression({
        incidentFormId,
        expressionId: expression.id,
        updateExpressionRequestBody: {
          expression: expressionToPayload(expression),
        },
      });
    },
  );

  const {
    trigger: destroyExpression,
    isMutating: destroySaving,
    genericError: destroyError,
  } = useAPIMutation(
    "incidentFormsShowForm",
    { id: incidentFormId },
    async (apiClient, expression: ExpressionFormData) => {
      if (!expression.id) {
        throw new Error("Expression must have an id to update");
      }

      await apiClient.incidentFormsDestroyExpression({
        incidentFormId,
        expressionId: expression.id,
      });
    },
  );

  const onCreateExpression = async (
    expressions: ExpressionFormData | ExpressionFormData[],
  ): Promise<void> => {
    if (Array.isArray(expressions)) {
      // These will actually come in one at a time, but the form methods expects us to handle both
      await Promise.all(
        expressions.map((expression) => createExpression(expression)),
      );
    } else {
      await createExpression(expressions);
    }
  };

  const onUpdateExpression = async (
    _idx: number,
    expression: ExpressionFormData,
  ): Promise<void> => {
    await updateExpression(expression);
  };

  const onDeleteExpression = async (idx?: number | number[]): Promise<void> => {
    // This makes no sense, but it's what formMethods expects so we have to do it
    if (idx === undefined) return;

    if (Array.isArray(idx)) {
      await Promise.all(
        idx.map((idx) => {
          const expression = expressions[idx];
          if (expression) {
            return destroyExpression(expression);
          } else {
            return Promise.resolve();
          }
        }),
      );

      // These will actually come in one at a time, but the form methods expects us to handle both
    } else {
      const expression = expressions[idx];
      if (expression) {
        await destroyExpression(expression);
      }
    }
  };

  return {
    expressionsMethods: {
      fields: expressions as FieldArrayWithId<
        { expressions: ExpressionFormData[] },
        "expressions",
        "key"
      >[],
      append: onCreateExpression,
      update: onUpdateExpression,
      remove: onDeleteExpression,
    },
    genericError: createError || updateError || destroyError,
    saving: createSaving || updateSaving || destroySaving,
  };
};
