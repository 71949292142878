/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentMembership,
    IncidentMembershipFromJSON,
    IncidentMembershipFromJSONTyped,
    IncidentMembershipToJSON,
} from './IncidentMembership';

/**
 * 
 * @export
 * @interface IncidentMembershipsCreateResponseBody
 */
export interface IncidentMembershipsCreateResponseBody {
    /**
     * 
     * @type {IncidentMembership}
     * @memberof IncidentMembershipsCreateResponseBody
     */
    incident_membership: IncidentMembership;
}

export function IncidentMembershipsCreateResponseBodyFromJSON(json: any): IncidentMembershipsCreateResponseBody {
    return IncidentMembershipsCreateResponseBodyFromJSONTyped(json, false);
}

export function IncidentMembershipsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentMembershipsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_membership': IncidentMembershipFromJSON(json['incident_membership']),
    };
}

export function IncidentMembershipsCreateResponseBodyToJSON(value?: IncidentMembershipsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_membership': IncidentMembershipToJSON(value.incident_membership),
    };
}

