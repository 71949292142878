import { GenericErrorMessage, IncidentStatusBadge } from "@incident-ui";
import {
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
} from "src/contexts/ClientContext";

import { useLifecycleOverview } from "../LifecycleOverviewContext";
import { Pill } from "../LifecycleUIElements";
import { LifecycleOverviewSection } from "./LifecycleOverviewSection";

export const ClosedSection = ({ statuses }: { statuses: IncidentStatus[] }) => {
  const { getConfigurationState } = useLifecycleOverview();
  const state = getConfigurationState(StatusCategoryEnum.Closed);
  const isBackground = state === "background";

  const closed = statuses.find(
    (status) => status.category === IncidentStatusCategoryEnum.Closed,
  );
  if (!closed) {
    return <GenericErrorMessage description={"Closed status not found"} />;
  }

  return (
    <LifecycleOverviewSection
      title="Closed"
      category={StatusCategoryEnum.Closed}
      diagram={
        // We add some bottom-padding to offset our margin hack in LifecycleOverviewSection
        <div className="space-y-2">
          <Pill disabled={isBackground}>
            <IncidentStatusBadge naked status={closed} iconOnly />
            <span className="truncate">{closed.name}</span>
          </Pill>
        </div>
      }
    />
  );
};
