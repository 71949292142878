import type { SVGProps } from "react";
import * as React from "react";
const SvgSync = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#sync_svg__a)">
      <path d="M10.032 13.22a.75.75 0 1 0-1.061 1.06l1.208 1.209c-.059.002-.118.012-.178.012a5.506 5.506 0 0 1-5.5-5.5c0-1.616.706-3.143 1.938-4.191a.75.75 0 1 0-.973-1.142A6.992 6.992 0 0 0 3 10c0 3.76 2.983 6.829 6.704 6.985l-.735.735a.75.75 0 0 0 1.06 1.06l2.25-2.25a.75.75 0 0 0 0-1.06l-2.25-2.25.003-.001ZM10.298 3.015l.735-.735a.75.75 0 1 0-1.061-1.061l-2.25 2.25a.75.75 0 0 0 0 1.06l2.25 2.25a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L9.824 4.51c.059-.002.118-.012.177-.012 3.032 0 5.5 2.467 5.5 5.5a5.493 5.493 0 0 1-1.938 4.19.75.75 0 1 0 .972 1.143 6.992 6.992 0 0 0 2.466-5.333c0-3.76-2.983-6.83-6.704-6.985h.001Z" />
    </g>
    <defs>
      <clipPath id="sync_svg__a">
        <path fill="#fff" d="M0-.001h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSync;
