import { CatalogType, CatalogTypeAttributePayload } from "@incident-io/api";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import {
  Callout,
  CalloutTheme,
  LoadingModal,
  ModalFooter,
  Txt,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import pluralize from "pluralize";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { DeprecatedBacklinkAttributeBadge } from "./DerivedAttributeBadge";
import { useBacklinkAttributeInfo } from "./useBacklinkAttributeInfo";

export const BacklinkIsArrayModal = ({
  catalogTypeName,
  attribute,
  onClose,
  onSubmit,
}: {
  catalogTypeName: string;
  attribute: CatalogTypeAttributePayload;
  onClose: () => void;
  onSubmit: (array: CatalogTypeAttributePayload) => void;
}) => {
  const {
    referencedType,
    referencedAttribute,
    catalogTypesError,
    catalogTypesLoading,
  } = useBacklinkAttributeInfo({ attribute });

  const formMethods = useForm<CatalogTypeAttributePayload>({
    defaultValues: {
      ...attribute,
    },
  });

  const currentArrayValue = formMethods.watch("array");

  if (catalogTypesError) {
    return <ErrorModal error={catalogTypesError} onClose={onClose} />;
  }

  const referencedTypeName = referencedType?.name;
  const referencedAttributeName = referencedAttribute?.name;

  if (catalogTypesLoading || !referencedTypeName || !referencedAttributeName) {
    return <LoadingModal onClose={onClose} title="Update attribute" />;
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      analyticsTrackingId="backlink-choose-single-or-multi-value"
      title="Update attribute"
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Update attribute"
        />
      }
    >
      <div
        className={"py-1 font-normal text-sm flex flex-col gap-4 items-start"}
      >
        <DeprecatedBacklinkAttributeBadge
          attributeName={referencedAttribute?.name as string}
          referencedType={referencedType as CatalogType}
        />
        <div className="flex flex-col gap-2 w-full">
          <span className="mb-2">
            Can each {catalogTypeName} be a {referencedAttributeName} of{" "}
            <Txt inline bold>
              multiple
            </Txt>{" "}
            {pluralize(referencedTypeName)}, or{" "}
            <Txt inline bold>
              just one
            </Txt>
            ?
          </span>
          <BooleanRadioButtonGroupV2
            formMethods={formMethods}
            srLabel="Multi-value"
            className="w-full"
            trueFirst
            boxed
            name="array"
            trueOption={{
              label: "Multiple",
              description: (
                <Txt xs lightGrey>
                  Each {catalogTypeName} can be a {referencedAttributeName} of
                  multiple {pluralize(referencedTypeName)}.
                </Txt>
              ),
            }}
            falseOption={{
              label: "Just one",
              description: (
                <div className="flex flex-col gap-2">
                  <Txt xs lightGrey>
                    Each {catalogTypeName} is a {referencedAttributeName} of
                    only one {referencedTypeName}.
                  </Txt>
                </div>
              ),
            }}
          />
          {!currentArrayValue && (
            <Callout
              theme={CalloutTheme.Plain}
              className="text-xs !border-none mt-2 p-4"
              iconClassName="!mr-1.5"
            >
              If there are multiple {pluralize(referencedTypeName)} which
              reference a {catalogTypeName}, we will choose the first one we
              find, which could be inconsistent.
            </Callout>
          )}
        </div>
      </div>
    </Form.Modal>
  );
};
