/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotionConnectingUser,
    NotionConnectingUserFromJSON,
    NotionConnectingUserFromJSONTyped,
    NotionConnectingUserToJSON,
} from './NotionConnectingUser';

/**
 * 
 * @export
 * @interface NotionConfig
 */
export interface NotionConfig {
    /**
     * 
     * @type {NotionConnectingUser}
     * @memberof NotionConfig
     */
    connecting_user?: NotionConnectingUser;
    /**
     * Unique identifier for the config
     * @type {string}
     * @memberof NotionConfig
     */
    id: string;
    /**
     * Unique identifier of the corresponding organisation
     * @type {string}
     * @memberof NotionConfig
     */
    organisation_id: string;
    /**
     * Notion ID for the page the app is installed into
     * @type {string}
     * @memberof NotionConfig
     */
    page_id?: string;
}

export function NotionConfigFromJSON(json: any): NotionConfig {
    return NotionConfigFromJSONTyped(json, false);
}

export function NotionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotionConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connecting_user': !exists(json, 'connecting_user') ? undefined : NotionConnectingUserFromJSON(json['connecting_user']),
        'id': json['id'],
        'organisation_id': json['organisation_id'],
        'page_id': !exists(json, 'page_id') ? undefined : json['page_id'],
    };
}

export function NotionConfigToJSON(value?: NotionConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connecting_user': NotionConnectingUserToJSON(value.connecting_user),
        'id': value.id,
        'organisation_id': value.organisation_id,
        'page_id': value.page_id,
    };
}

