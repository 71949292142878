import {
  CatalogEntry,
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  CatalogType,
  CatalogTypeAttribute,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  Link,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useMemo } from "react";
import { CatalogEntryBadge } from "src/components/@shared/attribute";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { DropDownStateEnum } from "./CatalogEntryListColumnHeader";

export const CreateTypeFromAttributeModal = ({
  attribute,
  entries,
  close,
  catalogType,
  setDropdown,
}: {
  attribute: CatalogTypeAttribute;
  entries: CatalogEntry[];
  close: () => void;
  catalogType: CatalogType;
  setDropdown: (state: DropDownStateEnum) => void;
}) => {
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();
  const bustType = useAPIRefetch("catalogShowType", {
    id: catalogType.id,
  });
  const bustEntries = useRevalidate(["catalogListEntries"]);

  const bustCaches = () => {
    bustType();
    bustEntries();
  };
  const promoteAttributeToType = useAPIMutation(
    "catalogListTypes",
    {},
    async (client) => {
      const resp = await client.catalogCreateTypeFromAttribute({
        attributeId: attribute.id,
        createTypeFromAttributeRequestBody: {
          catalog_type_id: catalogType.id,
        },
      });
      navigate(`/catalog/${slugForCatalogType(resp.catalog_type)}`);
      showToast({
        theme: ToastTheme.Success,
        title: `New ${attribute.name} type created!`,
      });
    },
    {
      onSuccess: () => {
        // We have changed the schema of this type, so bust that and the entries
        bustCaches();
      },
      onError: () => {
        setDropdown(DropDownStateEnum.Error);
        setTimeout(() => setDropdown(DropDownStateEnum.Inactive), 1200);
        showToast({
          theme: ToastTheme.Error,
          title: `Type creation failed!`,
        });
      },
    },
  );

  const uniqueEntries = useMemo(
    () =>
      entries
        .map((e) => e.attribute_values[attribute.id]?.value?.literal)
        .filter((v, i, array) => v !== undefined && array.indexOf(v) === i),
    [entries, attribute.id],
  );

  const typeWasImported =
    "incident.io/catalog-importer/last-sync-at" in catalogType.annotations;

  const clippedUniqueEntries = uniqueEntries.slice(0, 5);
  const hasMore = clippedUniqueEntries.length !== uniqueEntries.length;

  return (
    <Modal
      isOpen
      onClose={close}
      title="Are you sure?"
      analyticsTrackingId="catalog-create-type-from-attribute-modal"
      loading={!uniqueEntries}
    >
      <ModalContent>
        {typeWasImported && (
          <Callout theme={CalloutTheme.Warning} className="mb-4">
            This type was last synced using the{" "}
            <Link
              href="https://github.com/incident-io/catalog-importer"
              analyticsTrackingId={null}
              openInNewTab
            >
              catalog-importer
            </Link>
            . When it&apos;s synced again, you might lose any changes made here.
          </Callout>
        )}
        <div>
          <div>
            You are about to promote{" "}
            <span className="font-semibold">{attribute.name}</span> to a type.
            If you press continue, we will create a new type with the following
            entries:
          </div>
          <div className="flex flex-row flex-wrap gap-1 pt-2">
            {uniqueEntries.map((entry) => (
              <CatalogEntryBadge
                color={CatalogResourceColorEnum.Violet}
                icon={CatalogResourceIconEnum.Box}
                label={entry}
                key={`promotion-badge-${entry}`}
              />
            ))}
            {hasMore && (
              <div className="text-content-tertiary pt-1">and more...</div>
            )}
          </div>
        </div>
      </ModalContent>
      <ModalFooter
        onClose={close}
        onConfirm={() => promoteAttributeToType.trigger({})}
        confirmButtonText="Continue"
        confirmButtonType="button"
      />
    </Modal>
  );
};
