import { SchedulePayConfig } from "@incident-io/api";
import { Tooltip } from "@incident-ui";
import { currencyStrToCents } from "@incident-ui/Input/CurrencyInput";
import {
  addDays,
  addMonths,
  addSeconds,
  areIntervalsOverlapping,
  endOfMonth,
  endOfWeek,
  format,
  getDate,
  isAfter,
  isBefore,
  setDate,
  startOfWeek,
} from "date-fns";
import _ from "lodash";
import { tcx } from "src/utils/tailwind-classes";

import { formatCurrency } from "../../../../utils/currency";
import { overrideFormTypeToInterval } from "../../../legacy/on-call-legacy/pay-configurations/OnCallPayOverrideModal";
import { OverrideFormType } from "../../../legacy/on-call-legacy/pay-configurations/PayConfigCreateEditForm";

enum DayType {
  Normal = "normal",
  NotInMonth = "not_in_month",
  NotInRange = "not_in_range",
}

export type OverrideData = {
  payConfig: SchedulePayConfig;
  override: OverrideFormType;
};

type DayData = {
  date: Date;
  type: DayType;
  overrides: OverrideData[];
};

type MonthData = {
  name: string;
  days: DayData[];
};

export const YearlyCalendar = ({
  startDate,
  endDate,
  overrideData,
  onClick,
}: {
  startDate: Date;
  endDate: Date;
  overrideData: OverrideData[];
  onClick: (selectedDate: Date) => void;
}): React.ReactElement => {
  const startMonth = setDate(startDate, 1);

  const monthlyData: MonthData[] = [];

  // Iterate through each month, starting with the beginning of the start date month.
  // Once we get to a date which is after the end date, we should stop as we no longer need
  // to show those months.
  for (
    let firstDayOfMonth = startMonth;
    !isAfter(firstDayOfMonth, endDate);
    firstDayOfMonth = addMonths(firstDayOfMonth, 1)
  ) {
    // start at the beginning of the WEEK at the start of the month.
    const startOfThisWeek = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });

    const endOfThisMonth = endOfMonth(firstDayOfMonth);
    const endOfTheWeekAtTheEndOfThisMonth = endOfWeek(endOfThisMonth, {
      weekStartsOn: 1,
    });

    const days: DayData[] = [];

    for (
      let day = startOfThisWeek;
      !isAfter(day, endOfTheWeekAtTheEndOfThisMonth);
      day = addDays(day, 1)
    ) {
      let type: DayType = DayType.NotInRange;
      if (day.getMonth() !== firstDayOfMonth.getMonth()) {
        type = DayType.NotInMonth;
      } else if (!isBefore(day, startDate) && !isAfter(day, endDate)) {
        type = DayType.Normal;
      }

      days.push({
        date: day,
        type,
        overrides: getOverridesIfPresent(overrideData, day, type),
      });
    }

    monthlyData.push({
      name: format(firstDayOfMonth, "MMM yyyy"),
      days,
    });
  }

  return (
    <div>
      <div
        className={tcx(
          "mx-auto grid grid-cols-1 gap-x-8 gap-y-16 xl:max-w-none text-center w-fit",
          { "md:grid-cols-2": monthlyData.length >= 2 },
          { "lg:grid-cols-3": monthlyData.length >= 3 },
        )}
      >
        {monthlyData.map((month) => (
          <section key={month.name} className="text-center max-w-xl">
            <h2 className="font-semibold text-content-primary">{month.name}</h2>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-content-tertiary">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-2 bg-surface-tertiary text-sm shadow ring-1 ring-slate-200 w-64">
              {month.days.map((day, dayIdx) => {
                if (day.overrides.length === 0) {
                  return (
                    <button
                      key={day.date.toString()}
                      type="button"
                      onClick={() => onClick(day.date)}
                      disabled={
                        day.type === DayType.NotInMonth ||
                        day.type === DayType.NotInRange
                      }
                      className={tcx(
                        classNameFromDayType[day.type],
                        dayIdx === 0 && "rounded-tl-lg",
                        dayIdx === 6 && "rounded-tr-lg",
                        dayIdx === month.days.length - 7 && "rounded-bl-lg",
                        dayIdx === month.days.length - 1 && "rounded-br-lg",
                        "py-1.5",
                      )}
                    >
                      <time dateTime={day.date.toDateString()}>
                        {getDate(day.date)}
                      </time>
                    </button>
                  );
                }
                // Return a blue circle in the cell, with a tooltip around it!
                return (
                  <Tooltip
                    key={day.date.toString()}
                    analyticsTrackingId={null}
                    content={
                      <div className="text-sm space-y-2 p-1">
                        {Object.entries(
                          _.groupBy(day.overrides, (ov) => ov.override.name),
                        ).map(([overideName, overrides]) => (
                          <div key={overideName}>
                            <p className="font-semibold mb-1">{overideName}</p>{" "}
                            {overrides.map((override) => (
                              <p key={override.override.key}>
                                {override.payConfig.name}
                                {": "}
                                {formatCurrency(
                                  override.payConfig.currency,
                                  currencyStrToCents(
                                    override.override.rate_pounds,
                                  ),
                                )}
                              </p>
                            ))}
                          </div>
                        ))}
                      </div>
                    }
                    delayDuration={0}
                  >
                    <div
                      key={day.date.toString()}
                      className={tcx(
                        classNameFromDayType[day.type],
                        dayIdx === 0 && "rounded-tl-lg",
                        dayIdx === 6 && "rounded-tr-lg",
                        dayIdx === month.days.length - 7 && "rounded-bl-lg",
                        dayIdx === month.days.length - 1 && "rounded-br-lg",
                        "py-1.5",
                      )}
                    >
                      <time
                        dateTime={day.date.toDateString()}
                        className="bg-surface-invert font-semibold text-white mx-auto flex h-7 w-7 items-center justify-center rounded-full"
                      >
                        {getDate(day.date)}
                      </time>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

const getOverridesIfPresent = (
  overrides: OverrideData[],
  date: Date,
  dayType: DayType,
): OverrideData[] => {
  if (dayType !== DayType.Normal) {
    return [];
  }
  const overridesForThisDay: OverrideData[] = [];
  for (const override of overrides) {
    if (
      areIntervalsOverlapping(
        // This is a fudge to stop a day being included in the following date - i.e. an override
        // that covers just May 1st (midnight to midnight) shouldn't appear in May 2nd. It's effectively
        // doing '{inclusive: false}' except this fn doesn't have that option.
        { start: addSeconds(date, 1), end: addDays(date, 1) },
        overrideFormTypeToInterval(override.override),
      )
    ) {
      overridesForThisDay.push(override);
    }
  }
  return overridesForThisDay;
};

const classNameFromDayType: { [key in DayType]: string } = {
  [DayType.Normal]:
    "bg-surface-secondary text-content-primary hover:bg-surface-tertiary",
  [DayType.NotInMonth]:
    "bg-surface-secondary text-content-tertiary cursor-default",
  [DayType.NotInRange]: "bg-surface-tertiary text-slate-400 cursor-default",
};
