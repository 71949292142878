import type { SVGProps } from "react";
import * as React from "react";
const SvgFakeCheckbox = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 17h-8.5A2.752 2.752 0 0 1 3 14.25v-8.5A2.752 2.752 0 0 1 5.75 3h8.5A2.752 2.752 0 0 1 17 5.75v8.5A2.752 2.752 0 0 1 14.25 17ZM5.75 4.5c-.689 0-1.25.561-1.25 1.25v8.5c0 .689.561 1.25 1.25 1.25h8.5c.689 0 1.25-.561 1.25-1.25v-8.5c0-.689-.561-1.25-1.25-1.25h-8.5Z"
    />
  </svg>
);
export default SvgFakeCheckbox;
