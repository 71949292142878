import {
  SelectOption,
  SelectOptionGroup,
  SelectOptionOrGroup,
} from "@incident-ui/Select/types";
import { isEmpty } from "lodash";
import {
  BranchesOnlyExpression,
  ClientType,
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineParamBindingValue,
  EngineParamBindingValuePayload,
} from "src/contexts/ClientContext";

export const isEmptyBinding = (
  binding: EngineParamBindingPayload | EngineParamBinding,
): boolean => {
  // When clearing, we sometimes set this to null rather than removing it
  // altogether.
  if (binding == null) return true;
  if (!isEmpty(binding.array_value)) {
    return (
      binding.array_value?.some((val) => isEmptyBindingValue(val)) || false
    );
  } else if (binding.value) {
    return isEmptyBindingValue(binding.value);
  } else {
    return true;
  }
};

export const isEmptyBindingValue = (
  bindingValue: EngineParamBindingValuePayload | EngineParamBindingValue | null,
): boolean => {
  // When clearing, we sometimes set this to null rather than removing it
  // altogether.
  if (bindingValue == null) return true;

  return (
    (bindingValue.literal === undefined ||
      bindingValue.literal === "" ||
      bindingValue.literal == null) &&
    (bindingValue.reference === undefined || bindingValue.reference == null)
  );
};

export const bindingToPayload = (
  binding: EngineParamBinding,
): EngineParamBindingPayload => {
  if (binding.value && !isEmptyBindingValue(binding.value)) {
    return {
      value: binding.value,
    };
  }

  return {
    array_value: binding.array_value
      // Don't include empty bindings in the payload
      ?.filter((val) => !isEmptyBindingValue(val)),
  };
};

export const getEngineTypeaheadOptions = (
  apiClient: ClientType,
  resourceType: string,
) => {
  return async (query: string): Promise<SelectOptionOrGroup[]> => {
    try {
      const { options, option_groups } = await apiClient.engineTypeahead({
        resource: resourceType,
        query,
      });

      if (options.length > 0) {
        return options;
      } else {
        return option_groups;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};

// This typeahead function type is bound to a particular resource.
export type BoundParamTypeaheadFn = (
  query: string,
) => Promise<SelectOptionOrGroup[]>;

// This typeahead fn type returns the bound function by passing in a client and
// resource.
export type ParamTypeaheadFn = (
  apiClient: ClientType,
  resourceType: string,
) => BoundParamTypeaheadFn;

// Each ParamBindingOption will have:
// (1) a label (for rendering), ReactSelect knows how to handle this
// (2) a value (for state tracking). It needs to be called value of ReactSelect
// gets quite sad. This will be the same as either the literal or the reference
// (3) a reference OR a literal - depending on if this is a literal option or
// a reference option
// (4) optional image_url and sort_key to help with rendering.
export type ParamBindingOption = SelectOption & EngineParamBindingValue;
type ParamBindingOptionGroup = SelectOptionGroup & {
  options: ParamBindingOption[];
};

export type ParamBindingOptionOrGroup =
  | ParamBindingOption
  | ParamBindingOptionGroup;

export function isParamBindingOptionGroup(
  value: ParamBindingOptionOrGroup,
): value is ParamBindingOptionGroup {
  return "options" in value;
}

// expressionReferencesId checks if the given expression references the given
// ID. This is useful for determining if a resource is in use.
export const expressionReferencesId = (
  expression: BranchesOnlyExpression | undefined,
  id: string,
): boolean => {
  // Gather all the ID's referenced by the expression.
  let referencedIds = new Set(
    expression?.branches?.map((branch) => branch.result.value?.literal),
  );

  const elseBranch = expression?.else_branch;
  if (elseBranch) {
    referencedIds = referencedIds.add(elseBranch.result.value?.literal);
  }

  return referencedIds.has(id);
};
