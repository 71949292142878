import {
  EngineScope,
  IntegrationSettingsProviderEnum as ProviderEnum,
  Reference,
  Resource,
} from "@incident-io/api";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ExpressionFixedResultType } from "@incident-shared/engine/expressions/ifelse/createDefaultExpressionFormValues";
import { GenericErrorMessage, IconEnum, Spinner } from "@incident-ui";
import {
  Popover,
  PopoverBody,
  PopoverTitleBar,
} from "@incident-ui/Popover/Popover";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import {
  PopoverEmptyState,
  PopoverSearch,
} from "@incident-ui/Popover/PopoverSearch";
import { FullOptions, Searcher, sortKind } from "fast-fuzzy";
import { groupBy, isEmpty, last, partition } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { EngineParamBindingValue } from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import {
  filterScope,
  getScopeChildrenByParent,
  lookupInScope,
} from "src/utils/scope";

import {
  isExpression,
  referenceSource,
  splitReferencesBySource,
} from "../referenceSource";
import { AddExpressionsModalWrapper } from "./AddExpressionsModalWrapper";

export type MenuEntry = Reference & {
  resource: Resource;
  // parentLabel is something like Incident -> Status for category,
  // to help orient yourself. It's only rendered when you're searching
  // (i.e. the path isn't present at the top of the component).
  parentLabel: string | undefined;
  children?: string[];
  isSameReferenceAgain?: boolean;
};

export type MenuPathItem = {
  key: string;
  label: React.ReactNode;
};

type OnSelectProps =
  | {
      onSelectReference?: never;
      renderOnSelectedForm: (args: {
        onClose: () => void;
        selectedEntry: MenuEntry;
        path: MenuPathItem[];
      }) => React.ReactNode;
    }
  | {
      onSelectReference: (ref: MenuEntry) => void;
      renderOnSelectedForm?: never;
    };

export type MaybeRef = {
  resource: Resource;
  array: boolean;
} & Partial<Reference>;

export type EngineRefIsSelectable = (
  ref: MaybeRef,
) => boolean | string | undefined;

export type ReferenceSelectorPopoverProps = {
  renderTriggerButton: (props: { onClick: () => void }) => React.ReactElement;
  allowExpressions?: boolean;
  scope: EngineScope;
  isSelectable?: EngineRefIsSelectable;
  refValue?: EngineParamBindingValue & { icon: IconEnum };
  expressionFixedResultType?: ExpressionFixedResultType;
  pauseOnAddExpression?: boolean;
  isOpenOverride?: boolean | undefined;
  setIsOpenOverride?: (isOpen: boolean) => void;
  // If set, clicking "Add new expression" will take you to a different modal, rather than our standard one.
  // This is only used in Alerts right now: search 'resourceHasCandidatesInScope' for the context.
  overrideOnAddExpression?: () => void;
  elseBranchRequired?: boolean;
  // When true, we'll close the popover when someone interacts outside. This overrides our usual
  // behaviour which prevents closing.
  stopPreventClose?: boolean;
  // Additional content to be displayed at the bottom of the popover
  renderSuffixNode?: (props: { onClose: () => void }) => React.ReactNode;
} & OnSelectProps;

// ReferenceSelectorPopover is the popover menu you get when e.g. adding a condition or inserting
// a variable. It can be used whenever you want a user to select a reference from a scope, or add
// an expression. You can provide an 'onSelectedForm' to inline into the form (e.g. the conditions
// operator select, or any other extra information you want about the reference).
export const ReferenceSelectorPopover = (
  props: ReferenceSelectorPopoverProps,
) => {
  const {
    renderTriggerButton,
    isOpenOverride,
    setIsOpenOverride,
    stopPreventClose,
    ...restProps
  } = props;
  const [isOpenState, setIsOpenState] = useState<boolean>(false);
  const isOpen = isOpenOverride !== undefined ? isOpenOverride : isOpenState;
  const setIsOpen = setIsOpenOverride ? setIsOpenOverride : setIsOpenState;
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  // This ensures that the onInteractOutside handler can always tell whether or
  // not we're in the middle of selecting an entry (or adding an expression),
  // and prevent auto-closure if that's happening.
  const preventCloseRef = useRef(false);
  const onInteractOutside = useCallback(
    (e) => {
      // Try as I might, I can't prevent clicks outside the popover.
      // I think this is because this event handler receives the event after whatever we've clicked on;
      // this is a best effort to prevent any further action
      if (preventCloseRef.current && !stopPreventClose) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
      } else {
        onClose();
      }
    },
    [onClose, stopPreventClose],
  );

  // We want to trigger resources all getting loaded asap - this doesn't cause
  // many re-renders, and means the popover can start working right away when it
  // opens!
  useAllResources();

  return (
    <>
      <Popover
        onInteractOutside={onInteractOutside}
        trigger={renderTriggerButton({
          onClick: () => setIsOpen(true),
        })}
        className="w-[350px]"
        sideOffset={-33}
        align="start"
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        open={isOpen}
      >
        {isOpen && (
          <OpenReferenceSelectorPopover
            {...restProps}
            onClose={onClose}
            preventCloseRef={preventCloseRef}
          />
        )}
      </Popover>
    </>
  );
};

const OpenReferenceSelectorPopover = ({
  onSelectReference,
  scope: fullScope,
  renderOnSelectedForm,
  isSelectable: isSelectableCallback,
  refValue,
  allowExpressions = true,
  expressionFixedResultType,
  pauseOnAddExpression,
  overrideOnAddExpression,
  elseBranchRequired,
  renderSuffixNode,
  onClose,
  preventCloseRef,
}: Omit<
  ReferenceSelectorPopoverProps,
  "isOpenOverride" | "setIsOpenOverride" | "renderTriggerButton"
> & { onClose: () => void; preventCloseRef: MutableRefObject<unknown> }) => {
  const [search, setSearch] = useState("");
  const [path, setPath] = useState<MenuPathItem[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<MenuEntry>();
  const [autoSelectedSingleRootReference, setAutoSelectedSingleRootReference] =
    useState(false);

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  // Build a memoised lookup of resource by type
  const getResource = useCallback(
    (type: string): Resource => {
      const resource = resources.find((resource) => resource.type === type);
      if (!resource) {
        throw new Error(`could not find resource of type "${type}"`);
      }
      return resource;
    },
    [resources],
  );

  const scope = filterScope(fullScope, (ref) => {
    if (allowExpressions) {
      return true;
    }
    return !isExpression(ref.key);
  });

  // Hydrate each reference with its relevant resource
  const allMenuEntries = buildMenuEntriesFromScope(scope, getResource);

  const isSelectable: EngineRefIsSelectable = (entry) => {
    // HACK ALERT: we want to avoid circular references, where a user is presented with something like
    // 'User -> Slack User -> User' as an option. This is confusing and looks broken (even
    // though it's actually totally fine). It's not possible to programatically identify these
    // in a dry-run context (you can't know that SlackUser -> User is always going to return you
    // back where you started) so we special case based on the conventions we use to navigate between
    // users. As it's just a frontend shim, it's pretty safe, although at some point I'm sure
    // we'll discover it's restricting something it shouldn't be.
    if (isCircularUserRef(entry, allMenuEntries)) {
      return false;
    }
    if (isSelectableCallback) {
      return isSelectableCallback(entry);
    }
    return true;
  };

  const selectableMenuEntries = allMenuEntries.filter(isSelectable);

  const searcher = new Searcher(selectableMenuEntries, {
    keySelector: (s) => s.label,
    threshold: 0.8,
    sortBy: sortKind.insertOrder,
  });

  // Get either a list of search results, or the children of the last
  // entry in the path, depending on whether we're currently searching or not
  const menuEntries = getMenuEntries({
    menuEntries: allMenuEntries,
    searcher,
    search,
    path,
    isSelectable,
  });

  const handleBack = () => {
    setSelectedEntry(undefined);
    setPath((prev) => prev.slice(0, -1));
  };

  const handleSelect = (entry: MenuEntry) => {
    setSearch("");
    setPath((prev) => {
      // If we've selected, say incident -> status -> status, don't add
      // the duplicate entry to the path as it looks weird
      if (prev.length > 0 && prev[prev.length - 1].key === entry.key) {
        return prev;
      }
      return prev.concat({ key: entry.key, label: entry.node_label });
    });
    if (!entry.children) {
      if (onSelectReference) {
        onSelectReference(entry);
        onClose();
      } else {
        setSelectedEntry(entry);
      }
    }
  };

  const onAddExpression = allowExpressions
    ? overrideOnAddExpression
      ? overrideOnAddExpression
      : () => {
          setShowExpressionsModal(true);
        }
    : () => {
        return;
      };

  const [showExpressionsModal, setShowExpressionsModal] = useState(false);
  const isSelectingNestedProperty = path.length > 0;
  const isRefAnExpression = isExpression(refValue?.reference);
  const shouldShowExpressionsModal = allowExpressions && showExpressionsModal;

  // We prevent users from building query expressions with loop variables, or any properties of the loop variable.
  const scopeWithoutLoops = filterScope(
    scope,
    (ref) => ref.parent !== "loop_variable" && ref.key !== "loop_variable",
  );

  const insertExpression = (expression: ExpressionFormData) => {
    // Create a menu entry for the expression in order to insert immediately
    const menuEntryForExpression: MenuEntry = {
      key: `expressions["${expression.reference}"]`,
      label: expression.label,
      node_label: expression.label,
      type: expression.returns.type,
      resource: getResource(expression.returns.type),
      array: expression.returns.array,
      hide_filter: false,
      parentLabel: "",
    };

    handleSelect(menuEntryForExpression);
  };

  // Special case: if there is no 'add expression' button, and there's a single root reference in the initial menu,
  // automatically select it to save the user a click
  if (
    !allowExpressions &&
    !renderSuffixNode &&
    menuEntries.length === 1 &&
    path.length === 0
  ) {
    setAutoSelectedSingleRootReference(true);
    const entry = menuEntries[0];
    setPath([{ key: entry.key, label: entry.label }]);
  }

  // This effect ensures that we don't close the popover if we're in the middle
  // of selecting an entry
  useEffect(() => {
    preventCloseRef.current =
      selectedEntry !== undefined || shouldShowExpressionsModal;
  }, [preventCloseRef, selectedEntry, shouldShowExpressionsModal]);

  return (
    <>
      {shouldShowExpressionsModal && (
        // NOTE: Within ReferenceSelectorPopover we cannot really edit an
        // expression, since we're selecting one to be used. We *can* add an
        // expression which essentially means (1) showing the modal and (2)
        // having a callback to add the expression to state (via the arrayField
        // methods) using the useExpressionsMethods.
        <AddExpressionsModalWrapper
          isRefAnExpression={isRefAnExpression}
          reference={refValue?.reference}
          scope={scopeWithoutLoops}
          resources={resources}
          onClose={() => {
            setShowExpressionsModal(false);
          }}
          expressionFixedResultType={expressionFixedResultType}
          insertExpression={insertExpression}
          pauseOnAddExpression={pauseOnAddExpression}
          elseBranchRequired={elseBranchRequired}
          validateReturnType={isSelectable}
        />
      )}

      {resourcesLoading ? (
        <Spinner />
      ) : resourcesError ? (
        <GenericErrorMessage error={resourcesError} />
      ) : // If we've selected an entry, just render the form provided by the caller
      selectedEntry && renderOnSelectedForm ? (
        renderOnSelectedForm({ onClose, selectedEntry, path })
      ) : (
        <>
          {/* Header */}
          {path.length > 0 && (
            <PopoverTitleBar
              title={path.map((p) => p.label).join(" / ")}
              handleBack={
                autoSelectedSingleRootReference && path.length === 1
                  ? undefined
                  : handleBack
              }
            />
          )}
          {/* Search */}
          {!selectedEntry && (
            <PopoverSearch
              value={search}
              onChange={setSearch}
              placeholder="Search variables"
            />
          )}
          {/* Menu */}
          {!selectedEntry && (
            <PopoverBody className="max-h-[400px]">
              <ReferenceMenuEntries
                menuEntries={menuEntries}
                isSelectingNestedProperty={isSelectingNestedProperty}
                handleSelect={handleSelect}
                isSearching={!!search}
                onAddExpression={onAddExpression}
                allowExpressions={allowExpressions}
                renderSuffixNode={renderSuffixNode}
                onClose={onClose}
              />
            </PopoverBody>
          )}
        </>
      )}
    </>
  );
};

const ReferenceMenuEntries = ({
  menuEntries,
  handleSelect,
  isSearching,
  isSelectingNestedProperty,
  onAddExpression,
  allowExpressions,
  renderSuffixNode,
  onClose,
}: {
  menuEntries: MenuEntry[];
  handleSelect: (entry: MenuEntry) => void;
  isSearching: boolean;
  isSelectingNestedProperty: boolean;
  onAddExpression?: () => void;
  allowExpressions?: boolean;
  renderSuffixNode?: (props: { onClose: () => void }) => React.ReactNode;
  onClose: () => void;
}): React.ReactElement => {
  const hasExtraActions = allowExpressions || !!renderSuffixNode;

  if (menuEntries.length === 0) {
    if (isSearching) {
      // If we're searching, doesn't matter if there's an expression button, it's still empty
      return <PopoverEmptyState />;
    }

    // if we aren't searching, we're only 'empty' if there aren't extra actions
    if (!hasExtraActions) {
      return <PopoverEmptyState message={"No variables found"} />;
    }
  }
  // If we're searching, we need to group our entries by the parentPath.
  if (isSearching) {
    // Special case: if the parentLabel is undefined, stick those at the top
    const [entriesWithParent, entriesWithUndefinedParent] = partition(
      menuEntries,
      (x) => x.parentLabel,
    );
    const groupedEntries = groupBy(entriesWithParent, (x) => x.parentLabel);
    return (
      <>
        {entriesWithUndefinedParent.length > 0 && (
          <PopoverItemGroup>
            {entriesWithUndefinedParent.map((result) => (
              <ReferenceMenuItem
                key={result.key}
                value={result}
                onClick={handleSelect}
              />
            ))}
          </PopoverItemGroup>
        )}
        {Object.entries(groupedEntries).map(([parentLabel, entries]) => {
          return (
            <PopoverItemGroup key={parentLabel} label={parentLabel}>
              {entries.map((result) => (
                <ReferenceMenuItem
                  key={result.key}
                  value={result}
                  onClick={handleSelect}
                />
              ))}
            </PopoverItemGroup>
          );
        })}
      </>
    );
  }

  const { expressions, loopVariables, references } =
    splitReferencesBySource(menuEntries);

  return (
    <>
      {references.length > 0 && (
        <PopoverItemGroup>
          {references.map((result) => (
            <ReferenceMenuItem
              key={result.key}
              value={result}
              onClick={handleSelect}
            />
          ))}
        </PopoverItemGroup>
      )}
      {/* Display loop variables header if we have existing
          loop variables or are allowed to add new ones
      */}
      {loopVariables.length > 0 && (
        <PopoverItemGroup label="Provided by loop">
          {loopVariables.map((entry) => (
            <ReferenceMenuItem
              key={entry.key}
              value={entry}
              onClick={handleSelect}
            />
          ))}
        </PopoverItemGroup>
      )}
      {/* Display expressions header if we have existing
          expressions or are allowed to add new ones
      */}
      {(expressions.length > 0 || allowExpressions) &&
        !isSelectingNestedProperty && (
          <PopoverItemGroup label="Expressions">
            {expressions.map((entry) => (
              <ReferenceMenuItem
                key={entry.key}
                value={entry}
                onClick={handleSelect}
              />
            ))}

            {allowExpressions && (
              <PopoverItem icon={IconEnum.Expression} onClick={onAddExpression}>
                Add new expression
              </PopoverItem>
            )}
          </PopoverItemGroup>
        )}
      {renderSuffixNode && renderSuffixNode({ onClose })}
    </>
  );
};

const ReferenceMenuItem = ({
  value,
  onClick,
}: {
  onClick: (val: MenuEntry) => void;
  value: MenuEntry;
}) => {
  let icon = (value.icon ||
    value.resource.field_config.icon) as unknown as IconEnum;
  let iconClassName = "";
  switch (referenceSource(value.key)) {
    case "expression":
      icon = IconEnum.Expression;
      iconClassName = "!text-amber-500";
      break;
    case "loop":
      icon = IconEnum.Refresh1;
      iconClassName = "!text-violet-600";
      break;
  }

  return (
    <PopoverItem
      icon={icon}
      iconProps={{ className: iconClassName }}
      onClick={() => onClick(value)}
      className={
        value.isSameReferenceAgain ? "border-b border-stroke" : undefined
      }
      showContinueChevron={(value.children?.length ?? 0) > 0}
    >
      {value.node_label}
    </PopoverItem>
  );
};

const getMenuEntries = ({
  menuEntries,
  searcher,
  search,
  path,
  isSelectable = () => true,
}: {
  menuEntries: MenuEntry[];
  searcher: Searcher<MenuEntry, FullOptions<MenuEntry>>;
  search: string;
  path: MenuPathItem[];
  isSelectable?: EngineRefIsSelectable;
}): MenuEntry[] => {
  if (search) {
    return searcher.search(search);
  } else {
    const parentEntry = path.length
      ? menuEntries.find((x) => x.key === path[path.length - 1].key)
      : undefined;
    const childEntries = menuEntries.filter((entry) => {
      if (
        !entryIsSelectable({ entry, allEntries: menuEntries, isSelectable })
      ) {
        return false;
      }

      // If we've got a parent, is it the currently selected parent
      if (entry.parent) {
        return entry.parent && parentEntry?.key.endsWith(entry.parent);
      } else {
        // If we don't, is this a top level reference?
        return !path.length && !entry.parent;
      }
    });
    // If the parent is also something you can use as a filter
    // we want to add it with no children to so you can select it
    if (parentEntry && isSelectable(parentEntry)) {
      const sameEntryAgain = cloneDeep(parentEntry);
      delete sameEntryAgain.children;
      sameEntryAgain.isSameReferenceAgain = true;
      childEntries.unshift(sameEntryAgain);
    }

    // Now, filter out the `children` of any childEntries which are not selectable
    return childEntries.map((entry) => {
      if (!entry.children) {
        return entry;
      }

      const selectableChildren = entry.children.filter((child) => {
        const childEntry = menuEntries.find((x) => x.key === child);
        if (childEntry) {
          return entryIsSelectable({
            entry: childEntry,
            allEntries: menuEntries,
            isSelectable,
          });
        }
        return false;
      });

      return {
        ...entry,
        children:
          selectableChildren.length > 0 ? selectableChildren : undefined,
      };
    });
  }
};

const entryIsSelectable = ({
  entry,
  allEntries,
  isSelectable,
}: {
  entry: MenuEntry;
  allEntries: MenuEntry[];
  isSelectable: EngineRefIsSelectable;
}): boolean => {
  // If the entry is directly selectable, all good!
  if (isSelectable(entry)) {
    return true;
  }
  // Does it have any children?
  if (isEmpty(entry.children)) {
    return false;
  }

  // Are any of its children selectable?
  const oneChildSelectable = entry.children?.some((child) => {
    const childEntry = allEntries.find((x) => x.key === child);
    if (childEntry) {
      return entryIsSelectable({ entry: childEntry, allEntries, isSelectable });
    }
    return false;
  });

  return !!oneChildSelectable;
};

const buildParentLabel = ({
  reference,
  scope,
}: {
  reference: Reference;
  scope: EngineScope;
}): string | undefined => {
  if (!reference.parent) {
    return undefined;
  }
  const parentRef = lookupInScope(scope, reference.parent);
  return parentRef?.label;
};

// canBeCastTo is a helper for any callsites that want to only include
// references that can be cast to a particular type. You can pass this as your
// isSelectable function, for example.
//
// In this function, our goal is to determine whether a reference (entry in the
// menu) can be used for as particular param, based on its resourceType and
// whether it's an array or not (multi value).
export const canBeCastTo =
  ({
    resource: expectedResource,
    array: expectedArrayValue,
  }: {
    resource: Resource;
    array: boolean;
  }) =>
  (entry: { resource: Resource; array: boolean }) => {
    const entryArrayValue = entry.array;
    const entryResourceType = entry.resource.type;

    // Single values are compatible with single and array fields,
    // but array values are only compatible with array fields.
    const hasCompatibleArrayValue =
      expectedArrayValue === entryArrayValue || entryArrayValue === false;
    const hasSameResourceType = expectedResource.type === entryResourceType;

    // We can exit early, if we found a perfect match.
    if (hasSameResourceType && hasCompatibleArrayValue) {
      return true;
    }

    // We can never cast an array resource to a scalar, so we can exit early, as
    // casting is applied on a per-resource basis.
    if (entryArrayValue && !expectedArrayValue) {
      return false;
    }

    for (const cast of entry.resource.cast_types) {
      const castType = cast.type;
      const castArray = cast.array;

      if (expectedResource.type !== castType) {
        // If the types don't match, then no dice
        continue;
      }

      if (expectedArrayValue) {
        // If we're looking for an array value, then we're good whether the cast is
        // an array or a single value
        return true;
      } else {
        // If we are looking for a scalar, we can only accept another scalar
        if (!castArray) {
          return true;
        }
        continue;
      }
    }

    // Autocompleteable resources can be effectively casted from a String.
    if (expectedResource.autocompletable && entry.resource.type === "String") {
      return true;
    }

    // No dice.
    return false;
  };

const buildMenuEntriesFromScope = (
  scope: EngineScope,
  getResource: (type: string) => Resource,
): MenuEntry[] => {
  // Loop through our scope, building a lookup of children by parent
  const childrenByParent = getScopeChildrenByParent(scope);

  // Hydrate each reference with its relevant resource
  return scope.references.map((x: Reference) => {
    return {
      ...x,
      parentLabel: buildParentLabel({ reference: x, scope }),
      resource: getResource(x.type),
      children: childrenByParent[x.key],
    };
  });
};

// isCircularUserRef is a helper function to identify circular references like
// User -> PagerDuty User -> User and remove them from the menu entries. This is a
// frontend hack to avoid confusing users, and is not an ideal solution.
// We also remove User -> SlackUser as User can be cast to Slack User (and vice-versa)
// so it's not useful on it's own (but can be useful to go User -> Slack User  -> Timezone)
const isCircularUserRef = (
  entry: MaybeRef,
  allEntries: MenuEntry[],
): boolean => {
  // Step 1: Is it User -> SlackUser
  if (isUserToSlackUser(entry, allEntries)) {
    return true;
  }

  // Step 2: Is it User -> PagerDuty User -> User
  if (isUserToIntegrationAndBackAgain(entry, allEntries)) {
    return true;
  } else {
    // What if it's User -> PagerDuty User -> User -> Something?
    const parentEntry = allEntries.find((x) => x.key === entry.parent);
    if (
      parentEntry &&
      isUserToIntegrationAndBackAgain(parentEntry, allEntries)
    ) {
      return true;
    }
    // We only serialize scopes 3-levels deep, so we can't go any deeper than this,
    // so no need to recursively check.
  }

  return false;
};

const isUserType = (refType: string) =>
  [`CatalogEntry["User"]`, "User"].includes(refType);

const isUserToSlackUser = (
  entry: MaybeRef,
  allEntries: MenuEntry[],
): boolean => {
  // If it's not a SlackUser, we don't care
  if (entry.resource.type !== `CatalogEntry["SlackUser"]`) {
    return false;
  }

  // Who's the parent?
  const parent = allEntries.find((x) => x.key === entry.parent);
  if (!parent) {
    // If we can't find the parent, it's not User -> SlackUser
    return false;
  }

  // If the parent isn't a user, it's not User -> SlackUser
  if (!isUserType(parent.type)) {
    return false;
  }

  // If the attribute isn't slack, it's not User -> SlackUser
  if (!entry.key?.endsWith(`catalog_attribute["slack"]`)) {
    return false;
  }

  // This is looking like User -> SlackUser!
  return true;
};

const isUserToIntegrationAndBackAgain = (
  entry: MaybeRef,
  allEntries: MenuEntry[],
): boolean => {
  if (!isUserType(entry.resource.type)) {
    return false;
  }

  const parent = allEntries.find((x) => x.key === entry.parent);
  if (!parent) {
    // If we can't find the parent, it's not the end of a circle
    return false;
  }

  const grandparent = allEntries.find((x) => x.key === parent.parent);
  if (!grandparent) {
    // If we can't find the grandparent, it's not the end of a circle
    return false;
  }

  if (!isUserType(grandparent.type)) {
    // If the grandparent isn't a user, it's not the end of a circle
    return false;
  }

  // Let's check the first jump: grandparent => parent. That should be using
  // an integration provider as the attribute ID (e.g. catalog_attribute["click_up"])
  const firstJumpAttribute = last(parent.key.split("."));
  const providerAttrs = Object.values(ProviderEnum).map(
    (pr) => `catalog_attribute["${pr}"]`,
  );
  if (!providerAttrs.includes(firstJumpAttribute as string)) {
    // If the first jump isn't a provider, it's not the end of a circle
    return false;
  }

  // Now lets check the second jump: parent => entry.
  if (!entry.key?.endsWith(`catalog_attribute["user"]`)) {
    return false;
  }

  // This is looking like a circle!
  return true;
};
