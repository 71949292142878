/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoCallConfig,
    AutoCallConfigFromJSON,
    AutoCallConfigFromJSONTyped,
    AutoCallConfigToJSON,
} from './AutoCallConfig';

/**
 * 
 * @export
 * @interface AutoCallSettings
 */
export interface AutoCallSettings {
    /**
     * 
     * @type {AutoCallConfig}
     * @memberof AutoCallSettings
     */
    config?: AutoCallConfig;
    /**
     * Should we create a video conference call whenever an incident is created
     * @type {boolean}
     * @memberof AutoCallSettings
     */
    enabled: boolean;
}

export function AutoCallSettingsFromJSON(json: any): AutoCallSettings {
    return AutoCallSettingsFromJSONTyped(json, false);
}

export function AutoCallSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoCallSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : AutoCallConfigFromJSON(json['config']),
        'enabled': json['enabled'],
    };
}

export function AutoCallSettingsToJSON(value?: AutoCallSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': AutoCallConfigToJSON(value.config),
        'enabled': value.enabled,
    };
}

