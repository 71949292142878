/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingSetting
 */
export interface BillingSetting {
    /**
     * The ID of the organisation this billing setting relates to
     * @type {string}
     * @memberof BillingSetting
     */
    organisation_id: string;
    /**
     * The label of the plan this organisation is on
     * @type {string}
     * @memberof BillingSetting
     */
    plan_label: string;
    /**
     * The name of the plan this organisation is on
     * @type {string}
     * @memberof BillingSetting
     */
    plan_name: BillingSettingPlanNameEnum;
    /**
     * The ID of the sequence customer associated with this org
     * @type {string}
     * @memberof BillingSetting
     */
    sequence_customer_id?: string;
    /**
     * The name of the plan this organisation is on
     * @type {string}
     * @memberof BillingSetting
     */
    stripe_billing_mode: BillingSettingStripeBillingModeEnum;
    /**
     * The ID of the stripe customer associated with this org (if it exists)
     * @type {string}
     * @memberof BillingSetting
     */
    stripe_customer_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum BillingSettingPlanNameEnum {
    Legacy = 'legacy',
    StarterV1 = 'starter_v1',
    ProV1 = 'pro_v1',
    EnterpriseV1 = 'enterprise_v1',
    BasicV2 = 'basic_v2',
    TeamV2 = 'team_v2',
    ProV2 = 'pro_v2',
    EnterpriseV2 = 'enterprise_v2',
    Trial = 'trial',
    ProV2Trial = 'pro_v2_trial',
    EnterpriseV2Trial = 'enterprise_v2_trial'
}/**
* @export
* @enum {string}
*/
export enum BillingSettingStripeBillingModeEnum {
    Unset = 'unset',
    FlatRate = 'flat_rate',
    PerSeat = 'per_seat'
}

export function BillingSettingFromJSON(json: any): BillingSetting {
    return BillingSettingFromJSONTyped(json, false);
}

export function BillingSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisation_id': json['organisation_id'],
        'plan_label': json['plan_label'],
        'plan_name': json['plan_name'],
        'sequence_customer_id': !exists(json, 'sequence_customer_id') ? undefined : json['sequence_customer_id'],
        'stripe_billing_mode': json['stripe_billing_mode'],
        'stripe_customer_id': !exists(json, 'stripe_customer_id') ? undefined : json['stripe_customer_id'],
    };
}

export function BillingSettingToJSON(value?: BillingSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisation_id': value.organisation_id,
        'plan_label': value.plan_label,
        'plan_name': value.plan_name,
        'sequence_customer_id': value.sequence_customer_id,
        'stripe_billing_mode': value.stripe_billing_mode,
        'stripe_customer_id': value.stripe_customer_id,
    };
}

