import { InternalStatusPageContentSummary } from "@incident-io/api";
import {
  ANIMATION_MODIFIER,
  ChevronIcon,
  GenericStructureItem,
  GroupStructureItem,
  useTranslations,
} from "@incident-io/status-page-ui";
import { captureException } from "@sentry/react";
import { AnimatePresence, motion } from "framer-motion";
import { compact } from "lodash";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { toGenericStructureItem } from "../utils";

export const AffectedComponents = ({
  summary,
  affectedComponentIds,
}: {
  summary: InternalStatusPageContentSummary;
  affectedComponentIds: Set<string>;
}) => {
  const relevantStructure: GenericStructureItem[] = compact(
    summary.structure.components.map(({ component, group }) => {
      if (component) {
        if (component.hidden) return null;

        if (!affectedComponentIds.has(component.id)) return null;

        return toGenericStructureItem({ component });
      }

      if (group) {
        if (group.hidden) return null;

        const filteredGroup = group.components.filter(
          ({ id, hidden }) => affectedComponentIds.has(id) && !hidden,
        );
        if (filteredGroup.length === 0) return null;

        return toGenericStructureItem({
          group: {
            ...group,
            components: filteredGroup,
          },
        });
      }

      return null;
    }),
  );

  if (relevantStructure.length === 0) {
    return (
      <div className="text-slate-600">No components marked as affected</div>
    );
  }

  return (
    <>
      {relevantStructure.map((item, idx) => (
        <Item key={idx} summary={summary} item={item} />
      ))}
    </>
  );
};

const Item = ({
  summary,
  item,
}: {
  summary: InternalStatusPageContentSummary;
  item: GenericStructureItem;
}) => {
  if (item.component) {
    return <ComponentItem name={item.component.name} />;
  }

  if (item.group) {
    return <GroupItem summary={summary} group={item.group} />;
  }

  const err = new Error("structure item has neither component nor group");
  captureException(err);
  console.error(err);
  return null;
};

const ComponentItem = ({
  name,
  accessory,
  children,
}: {
  name: string;
  accessory?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <h3 className="font-medium text-content-primary dark:text-slate-200">
          {name}
        </h3>
        {accessory || null}
      </div>

      {children}
    </div>
  );
};

const GroupItem = ({
  summary,
  group,
}: {
  summary: InternalStatusPageContentSummary;
  group: GroupStructureItem;
}) => {
  const [showChildren, setShowChildren] = useState(summary.auto_expand_groups);
  const t = useTranslations("SystemStatus");

  return (
    <ComponentItem
      name={group.name}
      accessory={
        <>
          <span
            className={tcx(
              "flex items-center cursor-pointer group transition",
              "text-slate-600 hover:text-content-primary",
              "dark:hover:text-slate-300",
            )}
            onClick={() => setShowChildren(!showChildren)}
          >
            <span className={"hidden md:inline"}>
              {t("components_in_group", { count: group.components.length })}
            </span>
            <span className="flex items-center justify-center w-3 mt-[2px] ml-1">
              <ChevronIcon
                className={tcx(
                  "transition",
                  "text-slate-400 group-hover:text-content-primary",
                  "dark:text-slate-600 dark:group-hover:text-slate-400",
                )}
                flavour={showChildren ? "up" : "down"}
              />
            </span>
          </span>
        </>
      }
    >
      <AnimatePresence initial={false}>
        {showChildren && (
          <motion.div
            initial={{
              opacity: 0,
              height: 0,
              overflowY: "hidden",
            }}
            exit={{
              opacity: 0,
              height: 0,
              overflowY: "hidden",
            }}
            animate={{
              opacity: 1,
              height: "auto",
              overflowY: "hidden",
            }}
            transition={{
              type: "linear",
              duration: 0.4 * ANIMATION_MODIFIER,
            }}
            className="w-full"
          >
            <div className="flex flex-col space-y-2 pt-2">
              {group.components.map((component) => (
                <span
                  key={component.id}
                  className={tcx("text-content-primary", "dark:text-slate-200")}
                >
                  {component.name}
                </span>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ComponentItem>
  );
};
