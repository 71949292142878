import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Popover } from "@incident-ui/Popover/Popover";
import { ReactChild, ReactElement } from "react";
import { tcx } from "src/utils/tailwind-classes";

import {
  getJSONValueType,
  isPrimitive,
  JsonNode,
  JsonNodeType,
} from "./jsonTree";

type Bracket = {
  depth: number;
  character: string;
};

// JSONPreviewLines takes a list of flattened nodes and renders each
// as a list item, appending opening and closing brackets and handling
// indenting as necessary.
export const JSONPreviewLines = ({
  nodes,
  toggleNode,
  getKeyPopoverContent,
  onClickKey,
  lightMode = false,
}: {
  nodes: JsonNode[];
  toggleNode: (path: string) => void;
  getKeyPopoverContent?: (path: string) => React.ReactElement | string;
  onClickKey?: (path: string) => void;
  lightMode?: boolean;
}) => {
  // If we've just got one (or several) "flat" nodes, we don't want to render
  // the opening and closing brackets
  const isFlat = nodes.every((node) => node.type === JsonNodeType.Flat);
  // We track the depth of the last thing we added to the list so we
  // know when we're going up or down in the tree
  let prevDepth = 0;
  // Initialise the result with the opening line, which is just "{"
  const result: ReactElement[] = isFlat
    ? []
    : [<Bracket key={"ROOT"} depth={0} idx={1} character="{" omitComma />];
  let lineIdx = result.length;
  // The bracketStack is a stack of brackets that we know we need to close.
  // We initialise it with the closing bracket for the root node.
  const bracketStack: Bracket[] = isFlat ? [] : [{ depth: 0, character: "}" }];
  // Since we've just hardcoded the first line, let's increment the line index.
  lineIdx++;

  // Now we're going to iterate over our list of nodes, rendering a line
  // for each one, and opening/closing brackets as necessary.
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const nodeKey = `${lineIdx}-${node.path}`;
    // If the current line is less deep than the previous line, we know we
    // need to close one or more brackets. We pop from the stack until we
    // reach the correct depth.
    if (node.depth < prevDepth) {
      for (let diff = prevDepth - node.depth; diff > 0; diff--) {
        const closingBracket = bracketStack.pop() as Bracket;
        result.push(
          <Bracket
            key={`${nodeKey}-closing-${diff}`}
            depth={closingBracket.depth}
            idx={lineIdx}
            character={closingBracket.character}
          />,
        );
        lineIdx++;
      }
    }
    // If the node is flat, we just render a primitive value with no key
    if (node.type === JsonNodeType.Flat) {
      result.push(
        <LineWrapper idx={lineIdx} depth={node.depth} key={nodeKey}>
          <span>{getStringValue(node.value)}</span>
        </LineWrapper>,
      );

      // If the value is a primitive, we just render "key": value
    } else if (isPrimitive(node.type)) {
      result.push(
        <PrimitiveValue
          lightMode={lightMode}
          onClickKey={onClickKey}
          key={nodeKey}
          node={node}
          lineIdx={lineIdx}
          getKeyPopoverContent={getKeyPopoverContent}
        />,
      );
    } else {
      // Now render "key": <opening bracket>, or "key": { n elements } as appropriate.
      // The children will be the next nodes in our flat list, if the node is expanded.
      result.push(
        <NestedValue
          onClickKey={onClickKey}
          key={nodeKey}
          getKeyPopoverContent={getKeyPopoverContent}
          node={node}
          toggleNode={toggleNode}
          lineIdx={lineIdx}
          lightMode={lightMode}
        />,
      );
      // If the node is expanded, we have just opened a new set of brackets,
      // so push the corresponding closing bracket onto the stack.
      if (node.isExpanded) {
        bracketStack.push({
          character: node.type === JsonNodeType.Array ? "]" : "}",
          depth: node.depth,
        });
      }
    }
    prevDepth = node.depth;
    lineIdx++;
  }

  // We've now rendered the whole tree, so close any remaining open brackets
  while (bracketStack.length > 0) {
    prevDepth--;
    const closingBracket = bracketStack.pop() as Bracket;
    result.push(
      <Bracket
        key={`${lineIdx}-closing-${prevDepth}`}
        depth={closingBracket.depth}
        idx={lineIdx}
        character={closingBracket.character}
        omitComma={bracketStack.length === 0}
      />,
    );
    lineIdx++;
  }
  return <>{result}</>;
};

const LineWrapper = ({
  children,
  idx,
  depth,
  className,
  toggleNode,
  path,
  expanded,
}: {
  children?: ReactChild | ReactChild[];
  idx: number;
  depth: number;
  className?: string;
  toggleNode?: (path: string) => void;
  path?: string;
  expanded?: boolean;
}) => {
  return (
    <li className={tcx("flex-center-y", className)}>
      <Gutter
        idx={idx}
        depth={depth}
        toggleNode={toggleNode}
        path={path}
        expanded={expanded}
      />
      <span className="flex">{children}</span>
    </li>
  );
};

const Bracket = ({
  depth,
  idx,
  character,
  omitComma = false,
}: {
  depth: number;
  idx: number;
  character: string;
  omitComma?: boolean;
}) => {
  return (
    <LineWrapper depth={depth} idx={idx} className="text-content-tertiary">
      {character}
      {omitComma ? "" : ","}
    </LineWrapper>
  );
};

const Gutter = ({
  idx,
  depth,
  toggleNode,
  path,
  expanded,
}: {
  idx: number;
  depth: number;
  toggleNode?: (path: string) => void;
  path?: string;
  expanded?: boolean;
}) => {
  const indentPx = 24;
  return (
    // We have to use style here for margin as tailwind doesn't support dynamic values
    // It's our base amount of margin (16px) + our indent (which is depth * indentPx)
    <span
      style={{ marginRight: 8 + depth * indentPx }}
      className={tcx("flex text-slate-500 select-none", {
        "cursor-pointer": toggleNode,
      })}
      onClick={() => (toggleNode && path ? toggleNode(path) : void 0)}
    >
      <span className="w-[2ch] text-right">{idx.toString()}</span>
      {
        <span
          className={tcx(
            "invisible group-hover:visible ml-2 transition w-[1ch]",
            {
              "rotate-90": expanded,
            },
          )}
        >
          {toggleNode && path ? ">" : ""}
        </span>
      }
    </span>
  );
};

const Key = ({
  label,
  popoverContent,
  onClick,
  lightMode = false,
}: {
  label: string;
  popoverContent?: React.ReactElement | string;
  onClick?: () => void;
  lightMode?: boolean;
}) => {
  const LabelElement = (
    <span className="flex-center mr-2">
      <div
        className={tcx(
          lightMode
            ? `bg-surface-primary shadow-[0px_-1px_2px_0px_rgba(0,0,0,0.04),_0px_1px_2px_0px_rgba(0,0,0,0.08),_0px_1px_0px_0px_rgba(255,255,255,0.24)]`
            : "bg-slate-950",
          "flex rounded-[6px] group/line gap-1 px-1 py-0.5",
          { "cursor-pointer pl-0.5 pr-1": popoverContent || onClick },
        )}
        onClick={onClick}
      >
        {popoverContent && (
          <Icon
            size={IconSize.Medium}
            id={IconEnum.Add}
            className={tcx(
              "rounded",
              lightMode
                ? "bg-surface-primary text-content-link group-hover/line:text-yellow-200"
                : "bg-surface-invert text-content-tertiary group-hover/line:bg-slate-600 group-hover/line:text-content-invert",
            )}
          />
        )}
        <span
          className={tcx(!lightMode ? "text-yellow-200" : "text-content-link", {
            "group-hover/line:text-orange-300": popoverContent || onClick,
          })}
        >
          &quot;{label}&quot;
        </span>
      </div>
      <span
        className={tcx(
          !lightMode ? "text-content-tertiary" : "text-content-secondary",
        )}
      >
        :
      </span>
    </span>
  );
  return popoverContent ? (
    <Popover trigger={LabelElement} className="min-w-[200px]">
      {popoverContent}
    </Popover>
  ) : (
    LabelElement
  );
};

const PrimitiveValue = ({
  node,
  lineIdx,
  getKeyPopoverContent,
  onClickKey,
  lightMode = false,
}: {
  node: JsonNode;
  lineIdx: number;
  getKeyPopoverContent?: (path: string) => React.ReactElement | string;
  onClickKey?: (path: string) => void;
  lightMode?: boolean;
}) => {
  const value = getStringValue(node.value, node.type);

  return (
    <LineWrapper depth={node.depth} idx={lineIdx}>
      <span
        className={tcx(
          !lightMode ? "text-content-tertiary" : "text-content-secondary",
          "flex-center-y whitespace-nowrap",
        )}
      >
        <Key
          onClick={onClickKey ? () => onClickKey(node.path) : undefined}
          label={node.key}
          popoverContent={
            getKeyPopoverContent && getKeyPopoverContent(node.path)
          }
          lightMode={lightMode}
        />
        {value}
        <span
          className={tcx(
            !lightMode ? "text-content-tertiary" : "text-content-secondary",
          )}
        >
          ,
        </span>
      </span>
    </LineWrapper>
  );
};

const NestedValue = ({
  node,
  lineIdx,
  toggleNode,
  getKeyPopoverContent,
  onClickKey,
  lightMode = false,
}: {
  node: JsonNode;
  lineIdx: number;
  toggleNode: (path: string) => void;
  getKeyPopoverContent?: (path: string) => React.ReactElement | string;
  onClickKey?: (path: string) => void;
  lightMode?: boolean;
}) => {
  const openBracket = node.type === JsonNodeType.Array ? "[" : "{";
  const closeBracket = node.type === JsonNodeType.Array ? "]" : "}";
  const hasChildren = node.numChildren > 0;
  const bracketContent = hasChildren ? ` ${node.numChildren} elements ` : "";

  return (
    <LineWrapper
      key={`${node.key}-${lineIdx}`}
      depth={node.depth}
      idx={lineIdx}
      toggleNode={hasChildren ? toggleNode : undefined}
      path={node.path}
      expanded={node.isExpanded}
    >
      <Key
        label={node.key}
        popoverContent={getKeyPopoverContent && getKeyPopoverContent(node.path)}
        onClick={onClickKey ? () => onClickKey(node.path) : undefined}
        lightMode={lightMode}
      />
      <span
        className={tcx(
          !lightMode ? "text-content-tertiary" : "text-content-secondary",
          "flex-center-y",
          {
            "hover:text-slate-300 hover:cursor-pointer": hasChildren,
          },
        )}
        onClick={hasChildren ? () => toggleNode(node.path) : undefined}
      >
        {node.isExpanded
          ? openBracket
          : `${openBracket}${bracketContent}${closeBracket},`}
      </span>
    </LineWrapper>
  );
};

const getStringValue = (
  nodeValue: unknown,
  type: JsonNodeType | null = null,
): string | undefined => {
  if (type == null) {
    type = getJSONValueType(nodeValue);
  }

  let stringValue: string | undefined;
  switch (type) {
    case JsonNodeType.Boolean:
      stringValue = nodeValue?.toString();
      break;
    case JsonNodeType.Null:
      stringValue = "null";
      break;
    case JsonNodeType.Undefined:
      stringValue = "undefined";
      break;
    case JsonNodeType.Number:
      stringValue = nodeValue?.toString();
      break;
    case JsonNodeType.String:
      stringValue = `"${nodeValue}"`;
      break;
    default:
      throw new Error(
        `Tried to stringify non-primitive value: ${JSON.stringify({
          value: nodeValue,
          inferredType: type,
        })}`,
      );
  }
  return stringValue;
};
