/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalStatusPageContentSummary,
    InternalStatusPageContentSummaryFromJSON,
    InternalStatusPageContentSummaryFromJSONTyped,
    InternalStatusPageContentSummaryToJSON,
} from './InternalStatusPageContentSummary';

/**
 * 
 * @export
 * @interface InternalStatusPageContentShowPageResponseBody
 */
export interface InternalStatusPageContentShowPageResponseBody {
    /**
     * 
     * @type {InternalStatusPageContentSummary}
     * @memberof InternalStatusPageContentShowPageResponseBody
     */
    summary: InternalStatusPageContentSummary;
}

export function InternalStatusPageContentShowPageResponseBodyFromJSON(json: any): InternalStatusPageContentShowPageResponseBody {
    return InternalStatusPageContentShowPageResponseBodyFromJSONTyped(json, false);
}

export function InternalStatusPageContentShowPageResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageContentShowPageResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': InternalStatusPageContentSummaryFromJSON(json['summary']),
    };
}

export function InternalStatusPageContentShowPageResponseBodyToJSON(value?: InternalStatusPageContentShowPageResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': InternalStatusPageContentSummaryToJSON(value.summary),
    };
}

