import { IssueTrackerIssueSyncFailure } from "@incident-io/api";
import {
  InvalidReasonEnum,
  IssueTemplateContextEnum,
  useAllIssueTemplates,
} from "@incident-shared/issue-trackers";
import { Callout, CalloutTheme } from "@incident-ui";
import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  Button,
  ButtonTheme,
  ContentBox,
  LocalDateTime,
  Txt,
} from "@incident-ui";
import { formatDurationShort } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";

export const IssueTemplateFailureCallout = ({
  context,
}: {
  context: IssueTemplateContextEnum;
}) => {
  const { allTemplates: followUpTemplates } = useAllIssueTemplates(context);

  const invalidTemplatesCount = followUpTemplates.filter(
    (template) => template.invalid_reason !== InvalidReasonEnum.Empty,
  ).length;

  if (invalidTemplatesCount === 0) {
    return null;
  }

  return (
    <Callout theme={CalloutTheme.Danger} className="mb-3">
      {invalidTemplatesCount} of the issue templates listed below have
      experienced an error exporting a followup. This is often due to required
      fields that are either missing or invalid. It is likely we will not be
      able to export follow-ups to your issue tracker until these issues are
      resolved.
      <FailureActivity context={context} />
    </Callout>
  );
};

const FailureActivity = ({
  context,
}: {
  context: IssueTemplateContextEnum;
}): React.ReactElement | null => {
  const { data: failures } = useAPI(
    "issueTrackersGetAllIssueTrackerIssueSyncFailures",
    {},
  );

  const filteredForType = filterForType(failures?.sync_failures ?? [], context);

  // If we got nothing once filtered, return null
  if (filteredForType.length === 0) {
    return null;
  }

  return (
    <ContentBox className="py-1 pl-4 mt-2 mb-1 mr-1 shadow-none bg-red-50 border-red-200">
      <AccordionProvider type="single" collapsible>
        <Accordion id="text-example" header={<AccordionHeader />}>
          <div className="mb-4 mr-4">
            {filteredForType
              .slice(0, 4)
              .map((issue: IssueTrackerIssueSyncFailure) => (
                <div key={issue.id} className="pt-2">
                  <Issue issue={issue} />
                </div>
              ))}
          </div>
        </Accordion>
      </AccordionProvider>
    </ContentBox>
  );
};

const AccordionHeader = () => (
  <div className="flex justify-between items-center">
    <div className="flex items-center">
      <p className="text-sm flex flex-col text-content-primary font-medium grow mr-2.5">
        View latest failures
      </p>
    </div>

    <AccordionTriggerButton />
  </div>
);

const Issue = ({ issue: issue }: { issue: IssueTrackerIssueSyncFailure }) => {
  const incidentLink = getLinkToErrorSource(issue);

  return (
    <div className="">
      <Txt bold className="flex flex-row">
        <LocalDateTime timestamp={issue.created_at} showIcon>
          {formatDurationShort(issue.created_at, new Date(), {
            max: { hours: 12 },
            suffix: "ago",
          })}
        </LocalDateTime>
        {incidentLink}
      </Txt>
      <div className="overflow-x-auto bg-surface-invert rounded-2 mt-2 w-full">
        <code className="text-sm block text-left text-white p-4 pl-6 break-words">
          {issue.raw_error_json}
        </code>
      </div>
    </div>
  );
};

const filterForType = (
  syncFailures: IssueTrackerIssueSyncFailure[],
  context: IssueTemplateContextEnum,
) => {
  return syncFailures.filter((syncFailure) => {
    return syncFailure.followup_title
      ? context === IssueTemplateContextEnum.FollowUp
      : context === IssueTemplateContextEnum.IncidentTicket;
  });
};

const getLinkToErrorSource = (issue: IssueTrackerIssueSyncFailure) => {
  if (issue.incident_id) {
    const linkProps = {
      analyticsTrackingId: issue.followup_title
        ? "issue-sync-run-followup-link"
        : "issue-sync-run-incident-link",
      theme: ButtonTheme.Link,
      href: `/incidents/${issue.incident_id}`,
      children: issue.incident_reference,
    };

    return (
      <>
        <div className="font-light mx-1">{" in "}</div>
        <Button {...linkProps} />
      </>
    );
  }

  return null;
};
