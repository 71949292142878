import { CatalogType } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage } from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useAPI } from "src/utils/swr";

import { CatalogEntryCreateEditForm } from "../common/CatalogEntryCreateEditForm";
import { CatalogEntrySkeletonDrawerContents } from "../entry-view/CatalogEntryViewDrawer";

export const CatalogEntryCreateDrawer = ({
  refetchEntries,
  onClose,
  catalogType,
}: {
  onClose: () => void;
  refetchEntries: () => void;
  catalogType: CatalogType;
}) => {
  const {
    data: resourcesData,
    error: resourcesError,
    isLoading: resourcesIsLoading,
  } = useAPI("catalogListResources", undefined);

  const loading = resourcesIsLoading || !resourcesData;

  const error = resourcesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <Drawer width="medium" onClose={onClose} warnWhenDirty>
      {loading ? (
        <CatalogEntrySkeletonDrawerContents />
      ) : (
        <DrawerContents>
          <DrawerTitle
            compact
            title={`Create new entry`}
            onClose={onClose}
            color={catalogType.color as unknown as ColorPaletteEnum}
            icon={catalogType.icon}
          />
          <CatalogEntryCreateEditForm
            mode={Mode.Create}
            onClose={onClose}
            catalogType={catalogType}
            resources={resourcesData.resources}
            refetchEntries={refetchEntries}
          />
        </DrawerContents>
      )}
    </Drawer>
  );
};
