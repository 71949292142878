import clsx from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

import { listColorShades, listTypographys } from "./twConfig";

// This covers all the things clsx supports https://github.com/lukeed/clsx/blob/v2.1.1/clsx.d.ts
type TcxArray = TcxValue[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TcxDict = Record<string, any>;
export type TcxValue =
  | TcxArray
  | TcxDict
  | string
  | number
  | null
  | boolean
  | undefined;

// We have to tell twmerge about our funky semantic palette and typographys, so it doesn't
// think that they're clashing and de-dupe them.
export const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "text-color": [{ text: listColorShades() }],
      "font-size": [{ text: listTypographys() }],
    },
  },
});

//
/**
 *
 * tcx merges lists of classnames and handles conflict resolution using last-wins.
 *
 * It supports strings, lists of strings, and objects:
 * @example
 * <div className={tcx(
 *   "p-4", ["flex", "items-center"], {"text-red-500": isDangerous}
 * )}
 */
export const tcx = (...args: TcxValue[]) => {
  // twMerge doesn't support objects, so we're first going to run everything
  // through clsx first to get a flat list of strings
  const normalised = clsx(args);
  const merged = customTwMerge(normalised);
  return merged;
};
