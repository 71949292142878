/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegacyPostmortemTemplate,
    LegacyPostmortemTemplateFromJSON,
    LegacyPostmortemTemplateFromJSONTyped,
    LegacyPostmortemTemplateToJSON,
} from './LegacyPostmortemTemplate';

/**
 * 
 * @export
 * @interface PostmortemsListLegacyTemplatesResponseBody
 */
export interface PostmortemsListLegacyTemplatesResponseBody {
    /**
     * 
     * @type {Array<LegacyPostmortemTemplate>}
     * @memberof PostmortemsListLegacyTemplatesResponseBody
     */
    postmortem_templates: Array<LegacyPostmortemTemplate>;
}

export function PostmortemsListLegacyTemplatesResponseBodyFromJSON(json: any): PostmortemsListLegacyTemplatesResponseBody {
    return PostmortemsListLegacyTemplatesResponseBodyFromJSONTyped(json, false);
}

export function PostmortemsListLegacyTemplatesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsListLegacyTemplatesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postmortem_templates': ((json['postmortem_templates'] as Array<any>).map(LegacyPostmortemTemplateFromJSON)),
    };
}

export function PostmortemsListLegacyTemplatesResponseBodyToJSON(value?: PostmortemsListLegacyTemplatesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postmortem_templates': ((value.postmortem_templates as Array<any>).map(LegacyPostmortemTemplateToJSON)),
    };
}

