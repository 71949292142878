import React from "react";
import { Route, Routes } from "react-router";

import { SeveritiesCreatePage } from "./create/SeveritiesCreatePage";
import { SeveritiesEditPage } from "./edit/SeveritiesEditPage";
import { SeveritiesListPage } from "./view/SeveritiesListPage";

export const SeveritiesRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route path="/:id/edit" element={<SeveritiesEditPage />} />
        <Route path="/create" element={<SeveritiesCreatePage />} />
      </Routes>
      <SeveritiesListPage />
    </>
  );
};
