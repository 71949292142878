import * as Sentry from "@sentry/react";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";
import { useClient } from "src/contexts/ClientContext";

export const useLogoutAll = () => {
  const apiClient = useClient();
  const intercom = useIntercom();

  return useCallback(
    async (opts?: { redirect?: boolean }) => {
      const { redirect = true } = opts || {};

      try {
        await apiClient.authLogoutAll().catch((err) => {
          if (err.status === 401) return; // we were already logged out
          throw err;
        });
        // End their session in Intercom
        intercom.shutdown();

        if (redirect) {
          // Send them back to the login screen (otherwise they'll end up in the org-scoped login screen, which in
          // this case is probably not what they want)
          window.location.href = window.location.origin + "/login";
        }
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    [apiClient, intercom],
  );
};
