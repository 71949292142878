import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { BadgeSize, Button, ButtonTheme } from "@incident-ui";
import { isEmpty } from "lodash";
import React from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { EngineScope, Resource } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { EngineFormElement } from "../..";
import { CreateEditExpressionFormData } from "../AddEditExpressionModal";
import { Branch } from "./ExpressionBranchesList";

export type RenderResultInputProps = {
  key?: string;
  resources: Resource[];
  array: boolean;
  resourceType: string;
  scope: EngineScope;
};

type AddEditExpressionBranchProps = {
  editingBranch: Branch | undefined;
  setEditingState: (EditingBranchState) => void;
  selectedExpressionTypeResource: Resource | undefined;
  scope: EngineScope;
  onAdd: (branch: Branch) => void;
  onEdit: (branch: Branch) => void;
  canCancel: boolean;
  isElseCase: boolean;
  // The resources currently available, given the users' single/multi return type option.
  // This is determined by whether a resource type can display a single or array form field or not.
  availableReturnTypeResources: Resource[];
  renderResultInput?: (props: RenderResultInputProps) => React.ReactElement;
};

export const AddEditExpressionBranch = ({
  editingBranch,
  setEditingState,
  selectedExpressionTypeResource,
  scope,
  onEdit,
  onAdd,
  canCancel,
  isElseCase,
  availableReturnTypeResources,
  renderResultInput,
}: AddEditExpressionBranchProps): React.ReactElement | null => {
  const expressionFormMethods = useFormContext<CreateEditExpressionFormData>();
  const [resultArray] = expressionFormMethods.watch(["returns.array"]);

  const formMethods = useForm<Branch>({
    defaultValues: editingBranch ?? {
      condition_groups: [],
      result: {
        value: {},
        array_value: [],
      },
    },
  });
  const { handleSubmit, setError } = formMethods;

  const onOk = (branch: Branch) => {
    if ((branch.condition_groups?.[0]?.conditions ?? []).length === 0) {
      setError("condition_groups", {
        type: "manual",
        message: "Please set at least one condition",
      });
      return;
    } else if (
      isEmpty(branch.result.value) &&
      isEmpty(branch.result.array_value)
    ) {
      setError("result.value", {
        type: "manual",
        message: "You must choose a return value",
      });
      return;
    }

    if (editingBranch) {
      onEdit(branch);
    } else {
      onAdd(branch);
    }
  };

  // If the user hasn't yet selected a return type for the expression, we don't let them edit the branch
  // just show the editor disabled state.
  const hasSelectedExpressionType = !!selectedExpressionTypeResource;

  return (
    <div className={tcx("p-4", isElseCase ? "border-t border-stroke" : "")}>
      <FormProvider<Branch> {...formMethods}>
        {/* If [conditions] input */}
        <div className={"mb-4 flex flex-row flex-align-start"}>
          <span className={"text-sm font-medium flex-shrink-0 my-[9px] w-16"}>
            {isElseCase ? "Else if" : "If"}...
          </span>
          <div className={"w-full"}>
            <ConditionGroupsEditorV2
              formMethods={formMethods}
              name="condition_groups"
              scope={scope}
              disabled={!hasSelectedExpressionType}
              allowAllOfACatalogTypeInQueryExpression={false}
              hideIntroSentence
            />
          </div>
        </div>

        {/* Expression return input */}
        <div className={"flex flex-row"}>
          <span className={"text-sm font-medium flex-shrink-0 my-[9px] w-16"}>
            Return
          </span>
          <div className={"flex-grow"}>
            {renderResultInput ? (
              renderResultInput({
                key: selectedExpressionTypeResource?.type,
                resources: availableReturnTypeResources,
                array: resultArray,
                scope,
                resourceType:
                  selectedExpressionTypeResource?.type ??
                  availableReturnTypeResources[0].type,
              })
            ) : (
              <EngineFormElement<Branch>
                // this should re-render if the result type changes, otherwise the dropdowns stay populated with options from the previous result type.
                key={selectedExpressionTypeResource?.type}
                name={`result`}
                resources={availableReturnTypeResources}
                array={resultArray}
                resourceType={
                  selectedExpressionTypeResource?.type ??
                  availableReturnTypeResources[0].type
                }
                mode="variables_only"
                scope={scope}
                required={true}
                disabled={!hasSelectedExpressionType}
              />
            )}
          </div>
        </div>

        {/* Footer */}
        <div className={"w-100 flex justify-end mt-4 space-x-4"}>
          {canCancel && (
            <Button
              analyticsTrackingId="workflow-expression-cancel-create-edit-branch"
              onClick={() => setEditingState(null)}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Medium}
            >
              Cancel
            </Button>
          )}
          <Button
            analyticsTrackingId="workflow-expression-submit-create-edit-branch"
            onClick={(e) => {
              handleSubmit(onOk)(e);
            }}
            size={BadgeSize.Medium}
            disabled={!hasSelectedExpressionType}
            theme={ButtonTheme.Primary}
          >
            Add rule
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};
