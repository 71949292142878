/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentComponentUptime
 */
export interface StatusPageContentComponentUptime {
    /**
     * If a component, the unique ID of that component
     * @type {string}
     * @memberof StatusPageContentComponentUptime
     */
    component_id?: string;
    /**
     * When data for this component is first available. Before this, we don't know the component's status, and after it we can assume the component was operational unless there's an extra impact.
     * @type {string}
     * @memberof StatusPageContentComponentUptime
     */
    data_available_since: string;
    /**
     * If a component group, the unique ID of that group
     * @type {string}
     * @memberof StatusPageContentComponentUptime
     */
    status_page_component_group_id?: string;
    /**
     * Uptime over the specified window rounded to 2 decimal places. If it's missing, we shouldn't display it.
     * @type {string}
     * @memberof StatusPageContentComponentUptime
     */
    uptime?: string;
}

export function StatusPageContentComponentUptimeFromJSON(json: any): StatusPageContentComponentUptime {
    return StatusPageContentComponentUptimeFromJSONTyped(json, false);
}

export function StatusPageContentComponentUptimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentComponentUptime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_id': !exists(json, 'component_id') ? undefined : json['component_id'],
        'data_available_since': json['data_available_since'],
        'status_page_component_group_id': !exists(json, 'status_page_component_group_id') ? undefined : json['status_page_component_group_id'],
        'uptime': !exists(json, 'uptime') ? undefined : json['uptime'],
    };
}

export function StatusPageContentComponentUptimeToJSON(value?: StatusPageContentComponentUptime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.component_id,
        'data_available_since': value.data_available_since,
        'status_page_component_group_id': value.status_page_component_group_id,
        'uptime': value.uptime,
    };
}

