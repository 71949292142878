/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpsCreateRequestBody
 */
export interface FollowUpsCreateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof FollowUpsCreateRequestBody
     */
    assignee_id?: string;
    /**
     * When the follow-up was completed
     * @type {Date}
     * @memberof FollowUpsCreateRequestBody
     */
    completed_at?: Date;
    /**
     * When the follow-up was created
     * @type {Date}
     * @memberof FollowUpsCreateRequestBody
     */
    created_at?: Date;
    /**
     * Description of the follow-up
     * @type {string}
     * @memberof FollowUpsCreateRequestBody
     */
    description?: string;
    /**
     * If this follow-up is related to an external issue, the ID of that issue
     * @type {string}
     * @memberof FollowUpsCreateRequestBody
     */
    external_issue_reference_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FollowUpsCreateRequestBody
     */
    follow_up_priority_option_id?: string;
    /**
     * If this follow-up was created from a suggestion, the ID of that suggestion
     * @type {string}
     * @memberof FollowUpsCreateRequestBody
     */
    incident_follow_up_suggestion_id?: string;
    /**
     * Unique identifier of the incident the follow-up belongs to
     * @type {string}
     * @memberof FollowUpsCreateRequestBody
     */
    incident_id: string;
    /**
     * Title of the follow-up
     * @type {string}
     * @memberof FollowUpsCreateRequestBody
     */
    title: string;
}

export function FollowUpsCreateRequestBodyFromJSON(json: any): FollowUpsCreateRequestBody {
    return FollowUpsCreateRequestBodyFromJSONTyped(json, false);
}

export function FollowUpsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee_id': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'external_issue_reference_id': !exists(json, 'external_issue_reference_id') ? undefined : json['external_issue_reference_id'],
        'follow_up_priority_option_id': !exists(json, 'follow_up_priority_option_id') ? undefined : json['follow_up_priority_option_id'],
        'incident_follow_up_suggestion_id': !exists(json, 'incident_follow_up_suggestion_id') ? undefined : json['incident_follow_up_suggestion_id'],
        'incident_id': json['incident_id'],
        'title': json['title'],
    };
}

export function FollowUpsCreateRequestBodyToJSON(value?: FollowUpsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_id': value.assignee_id,
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString()),
        'description': value.description,
        'external_issue_reference_id': value.external_issue_reference_id,
        'follow_up_priority_option_id': value.follow_up_priority_option_id,
        'incident_follow_up_suggestion_id': value.incident_follow_up_suggestion_id,
        'incident_id': value.incident_id,
        'title': value.title,
    };
}

