/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeGeneratedRepository,
    CatalogTypeGeneratedRepositoryFromJSON,
    CatalogTypeGeneratedRepositoryFromJSONTyped,
    CatalogTypeGeneratedRepositoryToJSON,
} from './CatalogTypeGeneratedRepository';

/**
 * 
 * @export
 * @interface CatalogGenerateZipForTypesResponseBody
 */
export interface CatalogGenerateZipForTypesResponseBody {
    /**
     * 
     * @type {CatalogTypeGeneratedRepository}
     * @memberof CatalogGenerateZipForTypesResponseBody
     */
    catalog_type_generated_repository: CatalogTypeGeneratedRepository;
}

export function CatalogGenerateZipForTypesResponseBodyFromJSON(json: any): CatalogGenerateZipForTypesResponseBody {
    return CatalogGenerateZipForTypesResponseBodyFromJSONTyped(json, false);
}

export function CatalogGenerateZipForTypesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogGenerateZipForTypesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_generated_repository': CatalogTypeGeneratedRepositoryFromJSON(json['catalog_type_generated_repository']),
    };
}

export function CatalogGenerateZipForTypesResponseBodyToJSON(value?: CatalogGenerateZipForTypesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_generated_repository': CatalogTypeGeneratedRepositoryToJSON(value.catalog_type_generated_repository),
    };
}

