import { InternalStatusPageContentSummary } from "@incident-io/api";
import { OrgAwareLink } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { SubscribeToUpdatesButton } from "../Subscriptions/SubscribeToUpdatesButton";

export const Header = ({
  summary,
  incidentExternalId,
}: {
  summary: InternalStatusPageContentSummary;
  incidentExternalId?: number;
}): React.ReactElement => {
  if (!summary) {
    throw new Error("summary undefined");
  }

  return (
    <HeaderRender summary={summary} incidentExternalId={incidentExternalId} />
  );
};

export const HeaderRender = ({
  summary,
  incidentExternalId,
}: {
  summary: InternalStatusPageContentSummary;
  incidentExternalId?: number;
}) => {
  return (
    <>
      <div className="flex items-center mt-2">
        <div className="flex items-center grow h-8 relative">
          <OrgAwareLink
            to={
              summary.subpath === "default"
                ? "/status"
                : `/status/${summary.subpath}`
            }
          >
            <h1
              className={tcx(
                "text-2xl text-content-primary dark:text-slate-100 font-medium",
                "text-center",
              )}
            >
              {summary.logo_url ? (
                <img
                  src={summary.logo_url}
                  alt={summary.name}
                  className={tcx(
                    "h-8 w-auto",
                    "object-scale-down",
                    "object-center",
                  )}
                />
              ) : (
                summary.name
              )}
            </h1>
          </OrgAwareLink>
          <SubscribeToUpdatesButton
            externalIncidentId={incidentExternalId}
            summary={summary}
          />
        </div>
      </div>
    </>
  );
};
