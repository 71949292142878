import type { SVGProps } from "react";
import * as React from "react";
const SvgSeverityMedium = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="#B45309" filter="url(#severity-medium_svg__a)">
      <rect width={4} height={5} x={2} y={12} rx={1.7} />
      <rect width={4} height={9} x={7.5} y={8} rx={1.7} />
      <rect width={4} height={14} x={13} y={3} opacity={0.25} rx={1.7} />
    </g>
    <defs>
      <filter
        id="severity-medium_svg__a"
        width={20}
        height={20}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_2233_7130" />
      </filter>
    </defs>
  </svg>
);
export default SvgSeverityMedium;
