import {
  Button,
  ButtonTheme,
  DropdownMenu,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { statusToIconEnum } from "@incident-ui/Badge/IncidentStatusBadge";
import { severityToIconEnum } from "@incident-ui/Badge/SeverityBadge";
import {
  DropdownMenuItem,
  DropdownMenuItemProps,
} from "@incident-ui/DropdownMenu/DropdownMenu";
import { PopoverItemGroup } from "@incident-ui/Popover/PopoverItem";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import {
  Incident,
  IncidentModeEnum,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
  IncidentsUpdateModeRequestBodyModeEnum,
  IncidentVisibilityEnum,
} from "src/contexts/ClientContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate as useRevalidateSWR } from "src/utils/use-revalidate";

import { incidentInEditableStatus } from "../helpers";
import { useIsIncidentTypesEnabled } from "../sidebar/IncidentKeyDetails";
import {
  useIncidentSidebarLinkProps,
  useSidebarLinkActions,
} from "../sidebar/Links";

export const EditIncidentOverflowButton = ({
  incident,
  setModalOpen,
}: {
  incident: Incident;
  setModalOpen: (modal: IncidentHeaderModal) => void;
}): React.ReactElement | null => {
  const linkBuilderProps = useIncidentSidebarLinkProps({
    incidentId: incident.id,
  });
  const linksDropdownItems = useSidebarLinkActions({
    incident,
    ...linkBuilderProps,
  });

  const incidentModeItems = useIncidentModeItems({ incident, setModalOpen });
  const keyDetailsItems = useKeyInfoItems({ incident, setModalOpen });
  const miscItems = useMiscItems({ setModalOpen });

  // The dropdown items are displayed in groups that don't have titles (they're just
  // separated by a line to give some visual hierarchy).
  const groups: DropdownMenuItemProps[][] = [
    incidentModeItems,
    keyDetailsItems,
    linksDropdownItems,
    miscItems,
  ];

  const [search, setSearch] = useState("");

  const filteredGroups = groups
    .map((grp) => {
      return grp.filter((item) => {
        return item.label.toLowerCase().includes(search.toLowerCase());
      });
    })
    .filter((grp) => grp.length > 0);

  return (
    <DropdownMenu
      align="end"
      search={search}
      setSearch={setSearch}
      menuClassName="max-h-[calc(100vh-60px)]" // Max out at the height of the screen, minus a bit so it's not ugly.
      triggerButton={
        <Button
          title="More options"
          className="!py-1.5"
          analyticsTrackingId="incident-more-options"
          theme={ButtonTheme.Secondary}
          icon={IconEnum.DotsVertical}
          iconProps={{ size: IconSize.Large }}
        />
      }
    >
      {filteredGroups.map((itemGroup, i) => (
        <PopoverItemGroup key={i}>
          {itemGroup.map((item, j) => (
            <DropdownMenuItem {...item} key={j} />
          ))}
        </PopoverItemGroup>
      ))}
    </DropdownMenu>
  );
};

const useIncidentModeItems = ({
  incident,
  setModalOpen,
}: {
  incident: Incident;
  setModalOpen: (modal: IncidentHeaderModal) => void;
}): DropdownMenuItemProps[] => {
  const commsPlatform = usePrimaryCommsPlatform();
  const { permanentConvertPublicChannelsToPrivate } = useFlags();

  const revalidateISPIncidentLink = useRevalidateSWR([
    "internalStatusPageListIncidentLinks",
  ]);

  const { trigger: updateMode } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (
      client,
      payload: { mode: IncidentsUpdateModeRequestBodyModeEnum },
    ) => {
      await client.incidentsUpdateMode({
        id: incident.id,
        updateModeRequestBody: payload,
      });

      // Marking incident as test will remove the incident from the internal status page
      if (payload.mode === IncidentsUpdateModeRequestBodyModeEnum.Test) {
        revalidateISPIncidentLink();
      }
    },
  );

  if (
    [
      StatusCategoryEnum.Canceled,
      StatusCategoryEnum.Merged,
      StatusCategoryEnum.Declined,
    ].includes(incident.incident_status.category)
  ) {
    // If the incident is already canceled/declined etc. then there are no options to show
    return [];
  }

  const items: DropdownMenuItemProps[] = [];
  const statusCategory = incident.incident_status.category;
  // Can this be resolved?
  if (statusCategory === StatusCategoryEnum.Active) {
    items.push({
      label: "Mark as resolved",
      analyticsTrackingId: "incident-resolve-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.Resolve),
      icon: IconEnum.Tick,
    });
  }

  // You can't cancel a triage incident (well you can, but we'd rather you decline it)
  if (statusCategory !== StatusCategoryEnum.Triage) {
    items.push({
      label: "Mark as canceled",
      analyticsTrackingId: "incident-cancel-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.Cancel),
      icon: IconEnum.Close,
    });
  }

  // Non-test incidents can be marked as test
  if (
    incident.mode === IncidentModeEnum.Retrospective ||
    incident.mode === IncidentModeEnum.Standard
  ) {
    items.push({
      label: "Mark as test",
      analyticsTrackingId: "incident-mark-as-test",
      onSelect: () =>
        updateMode({ mode: IncidentsUpdateModeRequestBodyModeEnum.Test }),
      icon: IconEnum.Test,
    });
  }

  // Test incidents can be marked as standard
  if (incident.mode === IncidentModeEnum.Test) {
    items.push({
      label: "Mark as not a test",
      analyticsTrackingId: "incident-mark-as-standard",
      onSelect: () =>
        updateMode({ mode: IncidentsUpdateModeRequestBodyModeEnum.Standard }),
      icon: IconEnum.Incident,
    });
  }

  // We only allow users to make things private if they have the feature enabled,
  // and the incident is not already private
  if (
    commsPlatform === CommsPlatform.Slack &&
    incident.visibility !== IncidentVisibilityEnum.Private &&
    permanentConvertPublicChannelsToPrivate
  ) {
    items.push({
      label: "Make private",
      analyticsTrackingId: "incident-make-private-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.ChangePrivacy),
      icon: IconEnum.LockClosed,
    });
  }

  return items;
};

const useKeyInfoItems = ({
  incident,
  setModalOpen,
}: {
  incident: Incident;
  setModalOpen: (modal: IncidentHeaderModal) => void;
}): DropdownMenuItemProps[] => {
  const isEditable = incidentInEditableStatus(incident);
  const showIncidentTypes = useIsIncidentTypesEnabled();

  if (!isEditable) {
    return [];
  }

  const items: DropdownMenuItemProps[] = [];

  items.push({
    label: "Edit name",
    analyticsTrackingId: "incident-edit-name",
    onSelect: () => setModalOpen(IncidentHeaderModal.RenameIncident),
    icon: IconEnum.Edit,
  });

  items.push({
    label: "Update status",
    icon: statusToIconEnum[incident.incident_status.category],
    onSelect: () => setModalOpen(IncidentHeaderModal.UpdateStatus),
    analyticsTrackingId: "incident-update-status",
  });

  // If we're in the post-incident phase, offer an opt-out
  if (incident.incident_status.category === StatusCategoryEnum.PostIncident) {
    items.push({
      label: "Opt out of post-incident",
      analyticsTrackingId: "incident-opt-out-post-incident-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.OptOutOfPostIncident),
      icon: IconEnum.Close,
    });
  }

  if (incident.severity) {
    items.push({
      label: "Update severity",
      icon: severityToIconEnum[incident.severity.bucketed_rank],
      onSelect: () => setModalOpen(IncidentHeaderModal.UpdateSeverity),
      analyticsTrackingId: "incident-update-severity",
    });
  } else {
    items.push({
      label: "Set severity",
      icon: IconEnum.Severity,
      onSelect: () => setModalOpen(IncidentHeaderModal.UpdateSeverity),
      analyticsTrackingId: "incident-update-severity",
    });
  }

  if (showIncidentTypes) {
    items.push({
      label: "Change type",
      icon: IconEnum.IncidentType,
      onSelect: () => setModalOpen(IncidentHeaderModal.UpdateIncidentType),
      analyticsTrackingId: "incident-update-type",
    });
  }

  items.push({
    label: "Assign roles",
    icon: IconEnum.User,
    onSelect: () => setModalOpen(IncidentHeaderModal.EditRoleAssignments),
    analyticsTrackingId: "incident-assign-roles",
  });

  return items;
};

const useMiscItems = ({
  setModalOpen,
}: {
  setModalOpen: (modal: IncidentHeaderModal) => void;
}): DropdownMenuItemProps[] => {
  const { hasResponse } = useProductAccess();

  const items: DropdownMenuItemProps[] = [];

  if (hasResponse) {
    items.push({
      label: "Set custom fields",
      analyticsTrackingId: "incident-edit-custom-fields",
      onSelect: () => setModalOpen(IncidentHeaderModal.EditCustomFields),
      icon: IconEnum.Tag,
    });
  }

  items.push({
    label: "Update timestamps",
    analyticsTrackingId: "incident-set-timestamps",
    onSelect: () => setModalOpen(IncidentHeaderModal.EditTimestamps),
    icon: IconEnum.Timestamp,
  });

  if (hasResponse) {
    items.push({
      label: "Give a shoutout",
      analyticsTrackingId: "incident-shoutout-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.GiveShoutout),
      icon: IconEnum.Announcement,
    });
  }

  items.push({
    label: "Run a workflow",
    analyticsTrackingId: "incident-run-workflow-modal-open",
    onSelect: () => setModalOpen(IncidentHeaderModal.RunWorkflow),
    icon: IconEnum.Workflows,
  });

  return items;
};
