/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPreview,
    EscalationPreviewFromJSON,
    EscalationPreviewFromJSONTyped,
    EscalationPreviewToJSON,
} from './EscalationPreview';

/**
 * 
 * @export
 * @interface EscalationsListActiveResponseBody
 */
export interface EscalationsListActiveResponseBody {
    /**
     * 
     * @type {Array<EscalationPreview>}
     * @memberof EscalationsListActiveResponseBody
     */
    escalations: Array<EscalationPreview>;
}

export function EscalationsListActiveResponseBodyFromJSON(json: any): EscalationsListActiveResponseBody {
    return EscalationsListActiveResponseBodyFromJSONTyped(json, false);
}

export function EscalationsListActiveResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsListActiveResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalations': ((json['escalations'] as Array<any>).map(EscalationPreviewFromJSON)),
    };
}

export function EscalationsListActiveResponseBodyToJSON(value?: EscalationsListActiveResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalations': ((value.escalations as Array<any>).map(EscalationPreviewToJSON)),
    };
}

