import type { SVGProps } from "react";
import * as React from "react";
const SvgSubscription = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.704 3.704a.696.696 0 0 1 .492-.204h.233C11.096 3.5 16.5 8.904 16.5 15.571v.233a.696.696 0 0 1-.696.696h-.465a.697.697 0 0 1-.696-.696v-.233c0-5.64-4.573-10.214-10.214-10.214h-.233a.697.697 0 0 1-.696-.696v-.465c0-.184.073-.361.204-.492Zm0 4.643a.696.696 0 0 0-.204.492v.465a.696.696 0 0 0 .696.696h.233A5.572 5.572 0 0 1 10 15.571v.233a.696.696 0 0 0 .696.696h.465a.697.697 0 0 0 .696-.696v-.233A7.429 7.429 0 0 0 4.43 8.143h-.233a.696.696 0 0 0-.492.204Zm2.966 7.61a1.857 1.857 0 1 0-2.626-2.627 1.857 1.857 0 0 0 2.626 2.626Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSubscription;
