import { TabSectionProps } from "@incident-ui/TabSection/TabSection";

export enum TabId {
  Overview = "overview",
  Settings = "settings",
}

export const TABS: TabSectionProps["tabs"] = [
  {
    id: TabId.Overview,
    label: "Overview",
  },
  {
    id: TabId.Settings,
    label: "Settings",
  },
];

export enum OverviewTabIds {
  Now = "now",
  Past = "past",
}

export enum SettingsTabIds {
  BasicSettings = "basic",
  Branding = "branding",
  Components = "components",
  Automation = "automation",
}

export const SETTINGS_TAB_LABELS: { [key in SettingsTabIds]: string } = {
  [SettingsTabIds.BasicSettings]: "Basic settings",
  [SettingsTabIds.Branding]: "Branding",
  [SettingsTabIds.Components]: "Components",
  [SettingsTabIds.Automation]: "Automation",
};

export const SETTINGS_TABS = Object.values(SettingsTabIds).map((slug) => ({
  slug,
  label: SETTINGS_TAB_LABELS[slug],
}));
