import type { SVGProps } from "react";
import * as React from "react";
const SvgCodeBlock = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 41 40"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 10a5 5 0 0 1 5-5h20a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5h-20a5 5 0 0 1-5-5V10Zm23.75 10c0 .331-.132.65-.366.883l-3.75 3.75a1.253 1.253 0 0 1-1.799.032 1.25 1.25 0 0 1 .032-1.798L26.233 20l-2.866-2.867a1.25 1.25 0 1 1 1.767-1.766l3.75 3.75c.235.233.366.551.366.883Zm-17.133-.883a1.25 1.25 0 0 0 0 1.766l3.75 3.75a1.25 1.25 0 1 0 1.767-1.766L14.767 20l2.867-2.867a1.25 1.25 0 1 0-1.767-1.766l-3.75 3.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCodeBlock;
