import {
  IncidentStatusCategoryEnum,
  IncidentType,
  IncidentTypeCreateInTriageEnum,
} from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useStatusesForIncidentType } from "src/components/legacy/incident/useIncidentCrudResources";

export type TriageFormData = {
  active_or_triage?: "active" | "triage";
  incident_status_id?: string;
};
export const TriageElementSelect = ({
  formMethods,
  selectedIncidentType,
  disabled = false,
}: {
  formMethods: UseFormReturn<TriageFormData>;
  selectedIncidentType?: IncidentType;
  disabled?: boolean;
}) => {
  // If someone changes the incident type, the lifecycle might change. While
  // this doesn't change the triage status, it could change the first active
  // status.
  const { statuses } = useStatusesForIncidentType({
    incidentTypeId: selectedIncidentType?.id,
  });
  const firstActiveStatus = statuses.filter(
    (x) => x.category === IncidentStatusCategoryEnum.Active,
  )[0];

  const incidentTypeShouldCreateInTriage =
    selectedIncidentType?.create_in_triage ===
    IncidentTypeCreateInTriageEnum.Always;

  const activeHint = `You've confirmed there's a problem, and you'd like to investigate it right away.`;
  const triageHint = `Collaborate with team members before determining if it's an active incident.`;

  const activeOrTriage = formMethods.watch("active_or_triage");
  const incidentStatusId = formMethods.watch("incident_status_id");

  const isActive = !isIncidentInTriage(activeOrTriage, selectedIncidentType);

  const radioButtonHintText = isActive ? activeHint : triageHint;

  useEffect(() => {
    // if we have an incident status id, but the flag isn't set, it means
    // we're loading defaults
    if (
      !incidentTypeShouldCreateInTriage &&
      incidentStatusId &&
      typeof activeOrTriage === "undefined"
    ) {
      formMethods.setValue(
        "active_or_triage",
        // yeah fun one, so, at this stage, incidentStatusId is populated
        // by the element bindings hook, so is actually the category
        // and not an id as you would be lead to believe
        incidentStatusId === "triage" ? "triage" : "active",
      );
    }
  }, [
    activeOrTriage,
    incidentStatusId,
    formMethods,
    incidentTypeShouldCreateInTriage,
  ]);

  return (
    <>
      {incidentTypeShouldCreateInTriage ? (
        <Form.InputWrapper
          name="incident_status_id"
          label={
            <>
              All{" "}
              {selectedIncidentType ? (
                <>&lsquo;{selectedIncidentType?.name}&rsquo; incidents</>
              ) : (
                "incidents"
              )}{" "}
              start in triage.
            </>
          }
        >
          <Form.Helptext>
            <>
              We&apos;ll create a space for determining whether you&apos;re
              dealing with an incident. You can decide the severity and promote
              it to the &apos;
              {firstActiveStatus?.name ?? "active"}
              &apos; stage later, if you wish.
            </>
          </Form.Helptext>
        </Form.InputWrapper>
      ) : (
        <div className="flex flex-col gap-1">
          <RadioButtonGroupV2
            formMethods={formMethods}
            label="Initial status"
            srLabel="Initial status"
            required
            boxed
            horizontal
            options={[
              {
                label: "Active incident",
                value: "active",
                isDisabled: selectedIncidentType?.restricted,
                isDisabledTooltipContent: (
                  <>
                    You only have permission to declare{" "}
                    {selectedIncidentType?.name} incidents in triage.
                  </>
                ),
              },
              {
                label: "Triage a problem",
                value: "triage",
              },
            ]}
            name="active_or_triage"
            disabled={disabled}
          />
          <Form.Helptext className="text-xs">
            {radioButtonHintText}
          </Form.Helptext>
        </div>
      )}
    </>
  );
};

export const isIncidentInTriage = (
  activeOrTriage?: "active" | "triage",
  selectedIncidentType?: IncidentType,
) => {
  const incidentTypeShouldCreateInTriage =
    selectedIncidentType?.create_in_triage ===
    IncidentTypeCreateInTriageEnum.Always;

  return incidentTypeShouldCreateInTriage || activeOrTriage === "triage";
};
