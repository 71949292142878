import { CatalogResource, ErrorResponse } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

export const useCatalogResources = (): {
  resources: CatalogResource[];
  resourcesLoading: boolean;
  userLinkResources: string[];
  refetchResources: () => Promise<void>;
  resourcesError: ErrorResponse | undefined;
} => {
  const {
    data: { resources },
    isLoading,
    mutate,
    error,
  } = useAPI("catalogListResources", undefined, {
    fallbackData: { resources: [] },
  });

  const userLinkResources = resources
    .filter((r) => r.is_user_link)
    .map((r) => r.type);

  return {
    resources,
    resourcesLoading: isLoading,
    refetchResources: async () => {
      await mutate();
    },
    userLinkResources,
    resourcesError: error,
  };
};
