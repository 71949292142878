import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";

import { PathNode } from "../../common/types";

export const getNextNodeId = ({
  node,
}: {
  node: PathNode;
}): string | undefined => {
  if (node.data.nodeType === NodeTypes.Level) {
    return node.data.level.nextNodeId;
  } else if (node.data.nodeType === NodeTypes.NotifyChannel) {
    return node.data.notifyChannel.nextNodeId;
  }
  return undefined;
};
