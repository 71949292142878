import {
  EmptyStateAllFiltered,
  EmptyStateNoIncidentsYet,
  useFiltersContext,
} from "@incident-shared/filters";
import { useGetIncidentsWithSyntheticFilters } from "@incident-shared/incidents";
import { Loader } from "@incident-ui";
import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";

import {
  FollowUpsStatisticsSplitTypeEnum,
  IncidentsListSortByEnum,
  SavedViewsListContextEnum,
} from "../../../contexts/ClientContext";
import { SavedViewsProvider } from "../../saved-views/SavedViewContext";
import { useSavedViews } from "../../saved-views/SavedViewContext";
import { FollowUpsFiltersProvider, useSortBy } from "./FollowUpFiltersProvider";
import { FollowUpsListHeader } from "./FollowUpsListHeader";
import {
  FollowUpsStatisticsTable,
  useFollowupStatistics,
} from "./FollowUpsStatisticsTable";
import { IncidentsFollowUpsList } from "./list/IncidentFollowUpsList";

export enum FollowUpParam {
  SortBy = "sortBy",
  SplitBy = "splitBy",
}

export type FollowUpParams = {
  [FollowUpParam.SortBy]: IncidentsListSortByEnum;
  [FollowUpParam.SplitBy]: string;
};

export const FollowUpsPage = (): React.ReactElement => {
  const defaultQueryParams = new URLSearchParams();

  const defaultSortByOption = IncidentsListSortByEnum.NewestFirst;
  const defaultSplitByOption = FollowUpsStatisticsSplitTypeEnum.Severity;

  defaultQueryParams.set(FollowUpParam.SortBy, defaultSortByOption);
  defaultQueryParams.set(FollowUpParam.SplitBy, defaultSplitByOption);

  return (
    <SavedViewsProvider
      context={SavedViewsListContextEnum.FollowUps}
      defaultQueryParams={defaultQueryParams}
    >
      <FollowUpsFiltersProvider>
        <FollowUpsFilteredPage />
      </FollowUpsFiltersProvider>
    </SavedViewsProvider>
  );
};

export const FollowUpsFilteredPage = () => {
  const { sortBy } = useSortBy();
  const { filters } = useFiltersContext();

  const {
    incidents,
    refetchIncidents,
    isLoading,
    loadMore,
    allIncidentsLoaded,
    totalNumberOfIncidents,
  } = useGetIncidentsWithSyntheticFilters({
    filters,
    fixedFilters: {
      includeFollowUps: true,
      sortBy,
      pageSize: 150,
    },
  });

  const [loadRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !allIncidentsLoaded,
    onLoadMore: loadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the ref comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const { savedViews } = useSavedViews();
  const { settings } = useSettings();
  const { identity } = useIdentity();

  const loading = !settings || !identity || !savedViews;

  const followUpStatistics = useFollowupStatistics();

  return (
    <>
      {loading ? (
        <div className={"bg-white pb-24"}>
          <Loader />
        </div>
      ) : (
        <>
          <FollowUpsListHeader
            totalNumberOfIncidents={totalNumberOfIncidents ?? null}
          />
          <div className="bg-white pb-24">
            {!isLoading && incidents.length === 0 ? (
              <EmptyFollowUpsPage />
            ) : (
              <>
                <FollowUpsStatisticsTable {...followUpStatistics} />
                <IncidentsFollowUpsList
                  incidents={incidents}
                  allIncidentsLoaded={allIncidentsLoaded}
                  totalFollowupsCount={followUpStatistics.totalFollowupsCount}
                  loadRef={loadRef}
                  refetchIncidents={async () => {
                    await refetchIncidents();
                  }}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

// EmptyFollowUpsPage provides an explanation message when the list of incidents with
// follow-ups is empty.
const EmptyFollowUpsPage = (): React.ReactElement => {
  const { filters } = useFiltersContext();
  if (filters.length > 0) {
    return <EmptyStateAllFiltered isKanban={false} />;
  }

  return <EmptyStateNoIncidentsYet />;
};
