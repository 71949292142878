import { AlertSourceSourceTypeEnum } from "@incident-io/api";
import { Icon, IconProps, IconSize } from "@incident-ui/Icon/Icon";
import { ALERT_SOURCE_TYPE_CONFIGS } from "src/components/@shared/integrations/IntegrationConfig";
import { tcx } from "src/utils/tailwind-classes";

export const AlertSourceTypeIcon = ({
  sourceType,
  ...props
}: {
  sourceType: AlertSourceSourceTypeEnum;
} & Omit<IconProps, "id">) => {
  if (!sourceType) return null;

  const config = ALERT_SOURCE_TYPE_CONFIGS[sourceType];

  return <Icon {...props} id={config.icon} className={config.className} />;
};

export const AlertSourceTypeIconBadge = ({
  className,
  sourceType,
  iconSize = IconSize.Medium,
}: {
  className?: string;
  sourceType: AlertSourceSourceTypeEnum;
  iconSize?: IconSize;
}) => {
  const config = ALERT_SOURCE_TYPE_CONFIGS[sourceType];
  if (!config) {
    return null;
  }

  return (
    <div
      className={tcx(
        "py-1 px-1 flex items-center gap-1 rounded-1 text-sm-med",
        className,
      )}
      style={
        config.hexColor
          ? // Our hex color with opacity 10
            { backgroundColor: `${config.hexColor}10` }
          : undefined
      }
    >
      <Icon id={config.icon} className={config.className} size={iconSize} />
    </div>
  );
};
