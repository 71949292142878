import {
  RadioButtonGroup,
  RadioButtonGroupProps,
} from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const RadioButtonGroupV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, RadioButtonGroupProps> & {
    onValueChange?: (val: string) => void;
    preValueChange?: (opts: { new_value?: string; old_value?: string }) => void;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    RadioButtonGroupProps
  >(rest);
  const {
    // We don't want to pass the ref onwards here: a radio group
    // references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <RadioButtonGroup
        {...field}
        {...inputProps}
        onChange={(val: string) => {
          if (props.preValueChange) {
            props.preValueChange({ new_value: val, old_value: field.value });
          }
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};
