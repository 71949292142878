import { Product } from "@incident-shared/billing";
import { TimestampDescriptionPill } from "@incident-shared/timestamps/TimestampSetByRule";
import { IconEnum, Tooltip } from "@incident-ui";
import { captureMessage } from "@sentry/react";
import React from "react";
import {
  IncidentDurationMetric,
  IncidentDurationMetricMetricTypeEnum,
  IncidentTimestamp,
  IncidentTimestampsDestroyDurationMetricRequest,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";

export const DurationMetricRow = ({
  metric,
  timestamps,
  onEdit,
}: {
  metric: IncidentDurationMetric;
  timestamps: IncidentTimestamp[];
  onEdit: () => void;
}): React.ReactElement | null => {
  const { trigger: deleteMetric } = useAPIMutation(
    "incidentTimestampsListDurationMetrics",
    undefined,
    async (apiClient, data: IncidentTimestampsDestroyDurationMetricRequest) => {
      await apiClient.incidentTimestampsDestroyDurationMetric(data);
    },
  );

  const fromTimestamp = timestamps.find(
    (t) => t.id === metric.from_timestamp_id,
  );
  const toTimestamp = timestamps.find((t) => t.id === metric.to_timestamp_id);

  if (!fromTimestamp || !toTimestamp) {
    captureMessage(
      "metric pointing to timestamps that we don't have in our list",
      { extra: { metric, timestamps } },
    );
    return null;
  }

  const isLastedMetric =
    metric.metric_type === IncidentDurationMetricMetricTypeEnum.Lasted;

  return (
    <div className="w-full">
      <SettingsListItem
        title={metric.name}
        icon={IconEnum.Timer}
        description={
          <div className="text-slate-600 flex items-center gap-2 text-sm bg-white font-normal">
            from{" "}
            <TimestampDescriptionPill>
              {fromTimestamp.name}
            </TimestampDescriptionPill>{" "}
            to{" "}
            <TimestampDescriptionPill>
              {toTimestamp.name}
            </TimestampDescriptionPill>
            {/* We pass the badge as part of the description here to ensure it is at the end */}
            {isLastedMetric && (
              <Tooltip content="This metric is used to determine how long an incident lasted (which is shown on the incidents page)" />
            )}
          </div>
        }
        descriptionPosition="inline"
        buttons={{
          requiredProduct: Product.Response,
          requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
          edit: { onEdit },
          delete: {
            onDelete: () => deleteMetric({ id: metric.id }),
            isGatedText: isLastedMetric
              ? "This metric is used to determine how long an incident lasted (which is shown on the incidents page), and cannot be deleted"
              : undefined,
            deleteConfirmationTitle: "Delete metric",
            deleteConfirmationContent: (
              <>
                Are you sure you want to delete the{" "}
                <span className="font-bold">{metric.name}</span> metric?
              </>
            ),
            resourceTitle: metric.name,
            fetchDependentResources: [
              {
                resource_type: "IncidentDurationMetric",
                id: metric.id,
              },
            ],
          },
        }}
      />
    </div>
  );
};
