import {
  WorkflowPayloadRunsOnIncidentsEnum as RunsOnIncidentsEnum,
  WorkflowsUpdateWorkflowResponseBody,
} from "@incident-io/api";
import { ModalFooter } from "@incident-ui";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { WorkflowFormData } from "../common/types";
import { WorkflowsDeploySettingsForm } from "./WorkflowsDeploySettingsForm";
import { WorkflowsDeploySettingsLiveIncidents } from "./WorkflowsDeploySettingsLiveIncidents";

export const WorkflowsDeploySettingsModal = ({
  formMethods,
  onSubmit,
  onClose,
  showPendingRunStrategies,
  triggerLabel,
  triggerName,
  saving,
  showRunsOnIncidents,
}: {
  formMethods: UseFormReturn<WorkflowFormData>;
  onSubmit: (
    req: WorkflowFormData,
  ) => Promise<WorkflowsUpdateWorkflowResponseBody>;
  onClose: () => void;
  showPendingRunStrategies: boolean;
  triggerLabel?: string;
  triggerName?: string;
  saving?: boolean;
  showRunsOnIncidents: boolean;
}) => {
  // Instead of defaulting to how the workflow was deployed during the last update, we default to
  // the most common case, which is to apply to all currently open incidents and future incidents.
  //
  // This avoids users accidentally deploying workflows which don't run on existing incidents.
  const { setValue, watch } = formMethods;
  useEffect(() => {
    if (watch("runs_on_incidents") !== RunsOnIncidentsEnum.CreatedAndActive) {
      setValue<"runs_on_incidents">(
        "runs_on_incidents",
        RunsOnIncidentsEnum.CreatedAndActive,
      );
    }
  }, [setValue, watch]);

  return (
    <>
      <Form.Modal
        formMethods={formMethods}
        onSubmit={onSubmit}
        onClose={onClose}
        analyticsTrackingId="workflows-v2-deploy-settings-modal"
        title="How should we deploy this workflow?"
        footer={
          <ModalFooter
            onClose={onClose}
            confirmButtonType="submit"
            saving={saving}
          />
        }
      >
        {showRunsOnIncidents && <WorkflowsDeploySettingsLiveIncidents />}
        <WorkflowsDeploySettingsForm
          formMethods={formMethods}
          showPendingRunStrategies={showPendingRunStrategies}
          showRunsOnIncidents={showRunsOnIncidents}
          triggerLabel={triggerLabel}
          triggerName={triggerName}
        />
      </Form.Modal>
    </>
  );
};
