/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationActor
 */
export interface IntegrationActor {
    /**
     * Human readable name of the integration
     * @type {string}
     * @memberof IntegrationActor
     */
    label: string;
    /**
     * Machine readable name of the integration
     * @type {string}
     * @memberof IntegrationActor
     */
    name: IntegrationActorNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum IntegrationActorNameEnum {
    Statuspage = 'statuspage',
    GoogleCalendar = 'google_calendar',
    GoogleMeet = 'google_meet',
    Gitlab = 'gitlab',
    Jira = 'jira',
    MicrosoftTeams = 'microsoft_teams',
    Sentry = 'sentry',
    Slack = 'slack',
    Zendesk = 'zendesk',
    Zoom = 'zoom',
    AiSuggestion = 'ai_suggestion',
    IncidentAutoSubscribeRule = 'incident_auto_subscribe_rule',
    AlertSource = 'alert_source',
    AlertRoute = 'alert_route',
    IncidentAnnouncements = 'incident_announcements',
    IncidentAutoClose = 'incident_auto_close',
    IncidentAutoDecline = 'incident_auto_decline',
    IncidentCustomFieldEvaluator = 'incident_custom_field_evaluator',
    IncidentDefault = 'incident_default',
    GithubSecretScanning = 'github_secret_scanning',
    IncidentBackfill = 'incident_backfill',
    IncidentCatalog = 'incident_catalog',
    IncidentCopilot = 'incident_copilot',
    IncidentDefaultCallUrl = 'incident_default_call_url',
    IncidentEscalator = 'incident_escalator',
    IncidentLifecycle = 'incident_lifecycle',
    IncidentInsightReports = 'incident_insight_reports',
    IncidentSchedule = 'incident_schedule',
    IncidentSetup = 'incident_setup',
    IncidentTutorial = 'incident_tutorial',
    IncidentTicketSync = 'incident_ticket_sync',
    IncidentStaleOrgArchiver = 'incident_stale_org_archiver',
    InternalStatusPageAutomationRule = 'internal_status_page_automation_rule',
    LiveCall = 'live_call',
    Policy = 'policy',
    PostIncidentFlow = 'post_incident_flow',
    Saml = 'saml',
    Scim = 'scim',
    IncidentSchedules = 'incident_schedules',
    IncidentStatusPage = 'incident_status_page',
    IncidentStatusPageImport = 'incident_status_page_import',
    IncidentStatusPagePublicViewer = 'incident_status_page_public_viewer'
}

export function IntegrationActorFromJSON(json: any): IntegrationActor {
    return IntegrationActorFromJSONTyped(json, false);
}

export function IntegrationActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationActor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'name': json['name'],
    };
}

export function IntegrationActorToJSON(value?: IntegrationActor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'name': value.name,
    };
}

