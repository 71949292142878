import {
  InternalStatusPageThemeEnum,
  StatusPageAffectedComponentStatusEnum as ComponentStatusEnum,
  StatusPageThemeEnum,
} from "@incident-io/api";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { StatusPageComponentStatusIcon } from "../../incidents/view/StatusIcons";
import { PreviewItem } from "./utils";

export const Preview = ({
  showWarning = false,
  theme,
  items,
}: {
  showWarning?: boolean;
  theme: StatusPageThemeEnum;
  items: PreviewItem[];
}): React.ReactElement => {
  const isEmpty = items.every(({ hidden }) => hidden);

  return (
    <>
      <div
        className={tcx(
          "rounded-2 divide-y divide-slate-600 border mx-auto max-w-[694px]",
          theme === StatusPageThemeEnum.Dark
            ? "border-slate-700"
            : "border-stroke drop-shadow-sm",
        )}
        style={{
          background:
            theme === StatusPageThemeEnum.Dark
              ? "radial-gradient(75.93% 33.94% at 50% 0%, rgba(41, 51, 65, 0.75) 0%, rgba(41, 51, 65, 0) 100%), #14171c"
              : "white",
        }}
      >
        {isEmpty ? (
          <div className="bg-white rounded-[6px] p-4 drop-shadow text-center text-slate-700 border border-brand">
            You must add at least one visible component or group to your status
            page
          </div>
        ) : null}
        <PreviewItems items={items} theme={theme} />
      </div>
      {!isEmpty && showWarning ? (
        <p className="text-center text-content-tertiary text-sm mt-4">
          Using example data
        </p>
      ) : null}
    </>
  );
};

type ThemeEnum = StatusPageThemeEnum | InternalStatusPageThemeEnum;

export const PreviewItems = ({
  items,
  theme,
}: {
  items: PreviewItem[];
  theme: ThemeEnum;
}) => {
  return (
    <div
      className={tcx(
        "text-sm divide-y",
        theme === StatusPageThemeEnum.Dark
          ? "text-slate-200 divide-slate-700"
          : "text-content-primary divide-stroke",
      )}
    >
      {items.map((item) => {
        if (item.children) {
          return <GroupRow key={item.id} group={item} theme={theme} />;
        } else {
          return (
            <ComponentRow
              key={item.id}
              item={item}
              className="px-4 py-3"
              theme={theme}
            />
          );
        }
      })}
    </div>
  );
};

const GroupRow = ({
  group,
  theme,
}: {
  group: PreviewItem;
  theme: ThemeEnum;
}): React.ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false);
  if (group.hidden || !group.children) {
    return null;
  }

  return (
    <div className="space-y-2 px-4 py-3">
      <p
        className="flex items-center mb-1 group cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="grow flex items-center">
          <StatusPageComponentStatusIcon
            status={ComponentStatusEnum.Operational}
            className={tcx("-ml-0.5 mr-1", isOpen && "hidden")}
          />
          <span className="font-medium mr-2">{group.name}</span>

          <Icon
            id={isOpen ? IconEnum.Collapse : IconEnum.Expand}
            size={IconSize.Medium}
            className="text-slate-300 group-hover:text-content-tertiary transition"
          />
        </span>
        {group.displayUptimePercentage && !isOpen && (
          <span className="text-slate-600">99.99% uptime</span>
        )}
      </p>
      {group.displayUptimeChart && !isOpen ? <Chart theme={theme} /> : null}
      <div className={tcx(isOpen ? "block" : "hidden")}>
        {group.children.map((item) => (
          <ComponentRow
            key={item.id}
            item={item}
            className="mt-3"
            theme={theme}
          />
        ))}
      </div>
    </div>
  );
};

const ComponentRow = ({
  item,
  className,
  theme,
}: {
  item: PreviewItem;
  className?: string;
  theme: ThemeEnum;
}): React.ReactElement | null => {
  if (item.hidden) return <></>;

  return (
    <div className={tcx("space-y-2", className)}>
      <p className="flex">
        <StatusPageComponentStatusIcon
          status={ComponentStatusEnum.Operational}
          className="-ml-0.5"
        />
        <span className="font-medium ml-0.5 mr-2 grow">{item.name}</span>
        {item.displayUptimePercentage && (
          <span
            className={tcx(
              theme === StatusPageThemeEnum.Dark
                ? "text-slate-600"
                : "text-slate-400",
            )}
          >
            99.99% uptime
          </span>
        )}
      </p>
      {item.displayUptimeChart ? <Chart theme={theme} /> : null}
    </div>
  );
};

const Chart = ({ theme }: { theme: ThemeEnum }) => {
  return (
    <svg height={15} viewBox={"0 0 660 15"} className="w-full">
      {/* On very small screens, crushing in 90 blobs looks quite awful, and we
        hide them on the public page. But this is just a preview so I think it's
        better to show that there are bars there! */}
      {_.times(90).map((_, index) => (
        <rect
          key={index}
          x={index * 7.34065934065934}
          y={0}
          height={15}
          width={5.36}
          rx={3}
          className={tcx(
            theme === "dark" ? "fill-[#31653F]" : "fill-[#84DA83]",
          )}
        />
      ))}
    </svg>
  );
};
