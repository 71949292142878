import {
  Banner,
  BannerTheme,
  Button,
  ButtonTheme,
  IconEnum,
} from "@incident-ui";
import { useContextualItems } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import React, { useMemo } from "react";
import { useStopImpersonating } from "src/components/impersonation/useStopImpersonating";
import { useIdentity } from "src/contexts/IdentityContext";

export const ImpersonateBanner = (): React.ReactElement | null => {
  const { isImpersonating } = useIdentity();
  // If we're not impersonating, no need to show a banner
  if (!isImpersonating) {
    return null;
  }

  return <ActualBanner />;
};

const ActualBanner = () => {
  const { identity } = useIdentity();
  const stopImpersonating = useStopImpersonating();
  const items = useMemo(
    () => [
      {
        label: "Stop impersonating",
        analyticsId: "action_stop_impersonating",
        key: "action_stop_impersonating",
        searchString: "stop impersonate impersonating",
        icon: IconEnum.Warning,
        hideByDefault: true,
        onSelect: stopImpersonating,
      },
    ],
    [stopImpersonating],
  );

  useContextualItems("Impersonation", items);

  return (
    <Banner theme={BannerTheme.Impersonation} icon={IconEnum.Warning}>
      <span className="mr-2">
        You are impersonating
        <strong className="mx-1">{identity?.organisation_name}</strong> (
        <code>{identity?.organisation_id}</code>)
      </span>{" "}
      <Button
        theme={ButtonTheme.Secondary}
        analyticsTrackingId="impersonate-banner-stop-impersonating"
        onClick={stopImpersonating}
        className="!inline"
      >
        Stop impersonating
      </Button>
    </Banner>
  );
};
