import {
  IntegrationConfigFor,
  IntegrationListProvider,
} from "@incident-shared/integrations";
import {
  IssueTemplateContextEnum,
  useAllIssueTemplates,
} from "@incident-shared/issue-trackers";
import { SyncFailureTooltip } from "@incident-shared/issue-trackers/SyncFailureTooltip";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  OrgAwareLink,
  Tooltip,
} from "@incident-ui";

import { Sidebar } from "../Components";
import { LinkBuilder, LinkBuilderProps } from "./builders";

const IncidentTicket = ({ incident, syncFailures }: LinkBuilderProps) => {
  const externalIssue = incident.external_issue_reference;
  const { allTemplates } = useAllIssueTemplates(
    IssueTemplateContextEnum.IncidentTicket,
  );
  const issueTemplate = allTemplates.find(
    (template) => template.id === externalIssue?.issue_template_id,
  );

  if (externalIssue) {
    const tooltipContent = issueTemplate ? (
      <OrgAwareLink
        analyticsTrackingId={"incident-sidebar-jira-issue-template"}
        to={"/settings/incident-tickets"}
      >
        <p>
          Created from the template &apos;{issueTemplate.name}
          &apos;
        </p>
      </OrgAwareLink>
    ) : null;

    const config = IntegrationConfigFor(externalIssue.provider);

    return (
      <Sidebar.Entry
        label={`${config.label} issue`}
        value={
          <Tooltip content={tooltipContent}>
            <Sidebar.ValueButton
              icon={config.icon}
              href={externalIssue.issue_permalink}
              openInNewTab
              analyticsTrackingId="incident-sidebar-external-issue"
            >
              <code>{externalIssue.issue_name}</code>
            </Sidebar.ValueButton>
          </Tooltip>
        }
      />
    );
  }

  // Show the most recent issue tracker sync failure error message.
  const firstFailure = syncFailures[0];
  const config = IntegrationConfigFor(
    firstFailure.provider as unknown as IntegrationListProvider,
  );

  return (
    <Sidebar.Entry
      label={`${config.label} issue`}
      value={
        <SyncFailureTooltip syncFailures={syncFailures}>
          <Badge
            size={BadgeSize.Medium}
            theme={
              firstFailure.is_transient ? BadgeTheme.Warning : BadgeTheme.Error
            }
          >
            Error
          </Badge>
        </SyncFailureTooltip>
      }
    />
  );
};

export const IncidentTicketBuilder: LinkBuilder = {
  Render: IncidentTicket,
  visible: ({ incident, syncFailures }) =>
    incident.external_issue_reference != null || syncFailures.length > 0,
};
