import type { SVGProps } from "react";
import * as React from "react";
const SvgTrophy = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.75 4a.75.75 0 0 1 .749.71c.007.128.15 3.167-1.48 4.887-.667.705-1.528 1.073-2.546 1.13-.706 1.54-1.589 2.545-2.647 3.004.19.747.847 2.15 3.128 2.797A.75.75 0 0 1 14.75 18h-8.5a.75.75 0 0 1-.204-1.472c2.3-.652 2.95-2.073 3.132-2.816-1.06-.47-1.94-1.47-2.641-2.983-1.022-.055-1.887-.424-2.556-1.13-1.63-1.72-1.487-4.76-1.48-4.888A.75.75 0 0 1 3.25 4h1.632c-.047-.383-.09-.774-.13-1.179A.748.748 0 0 1 5.5 2h10a.752.752 0 0 1 .748.822c-.04.405-.083.796-.13 1.18h1.633ZM4.012 5.5c.055.842.268 2.235 1.06 3.068.24.253.522.427.843.54-.331-1.04-.606-2.24-.824-3.608H4.01Zm11.076 3.606c.319-.112.6-.287.839-.538.792-.833 1.005-2.225 1.06-3.067h-1.08c-.217 1.366-.491 2.566-.82 3.605Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrophy;
