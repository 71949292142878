import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { Modal } from "@incident-ui/Modal/Modal";
import { ModalContent } from "@incident-ui/Modal/ModalContent";
import { ModalFooter } from "@incident-ui/Modal/ModalFooter";
import React, { useState } from "react";
import {
  Actor,
  IncidentActivityLogEntry,
  SlackImage,
} from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemSlackImage = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps | null => {
  const slackImage = item.content.slack_image;
  if (!slackImage) {
    throw new Error(
      "malformed timeline item: slack_image was missing slack_image field",
    );
  }

  // Don't try to return a timeline item if the image has been deleted.
  if (slackImage.slack_message?.deleted) {
    return null;
  }

  let title = "An image was posted";
  if (slackImage.images.length > 1) {
    title = "Some images were posted";
  }

  const userActor: Actor = {
    user: slackImage.message_user,
  };

  return {
    icon: IconEnum.Files,
    colour: ColorPaletteEnum.Pink,
    // An actor should only be shown if there is a user associated with the message.
    // This could happen if the message was posted by a bot!
    actor: slackImage.message_user && userActor,
    title: title,
    unquotedContent: (
      <ActivityItemSlackImageUnquotedContent slackImage={slackImage} />
    ),
  };
};

const ActivityItemSlackImageUnquotedContent = ({
  slackImage,
}: {
  slackImage: SlackImage;
}): React.ReactElement => {
  const [zoomImageSource, setZoomImageSource] = useState<string | undefined>(
    undefined,
  );

  return (
    <>
      <Modal
        title="No title shown"
        isOpen={!!zoomImageSource}
        analyticsTrackingId={null}
        onClose={() => setZoomImageSource(undefined)}
        hideHeader
        maximised
      >
        <ModalContent>
          <img src={zoomImageSource} alt="" />
        </ModalContent>
        <ModalFooter
          hideConfirmButton={true}
          confirmButtonType="button"
          onConfirm={() => setZoomImageSource(undefined)}
          onClose={() => setZoomImageSource(undefined)}
          cancelButtonText={"Close"}
        />
      </Modal>
      <div className="space-y-3">
        <Markdown>{slackImage.slack_message?.text}</Markdown>
        {slackImage.images &&
          slackImage.images.map((im) => (
            <img
              key={im.id}
              alt=""
              src={im.url}
              className={"rounded-2 border border-stroke shadow-sm"}
              onClick={() => setZoomImageSource(im.url)}
            />
          ))}
      </div>
    </>
  );
};
