import { SourceTypeConfig } from "@incident-shared/integrations";
import { Button, ButtonTheme, FloatingFooter } from "@incident-ui";
import { motion } from "framer-motion";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

const AlertSourceSplitLayoutContext = React.createContext<{
  leftPanelRef: React.RefObject<HTMLDivElement>;
  rightPanelRef: React.RefObject<HTMLDivElement>;
}>({
  leftPanelRef: { current: null },
  rightPanelRef: { current: null },
});

export const useAlertSourceSplitLayout = () =>
  React.useContext(AlertSourceSplitLayoutContext);

// Creates a split page with the footer that is used consistently across all
// the alert source pages optionally toggled.
export const AlertSourceSplitLayout = ({
  header,
  left,
  right,
  footer,
  bordered = false,
  className,
  contentClassName,
  splitClassName,
  expandToContent = false,
}: {
  header?: React.ReactNode;
  left: React.ReactNode;
  right: React.ReactNode;
  footer?: React.ReactElement;
  bordered?: boolean;
  className?: string;
  contentClassName?: string;
  splitClassName?: string;
  expandToContent?: boolean;
}) => {
  const leftPanelRef = React.useRef<HTMLDivElement>(null);
  const rightPanelRef = React.useRef<HTMLDivElement>(null);

  return (
    <AlertSourceSplitLayoutContext.Provider
      value={{ leftPanelRef, rightPanelRef }}
    >
      <div
        className={tcx(
          "flex flex-col grow",
          expandToContent ? "h-auto" : "h-full",
          className,
        )}
      >
        {/* Header */}
        {header && <div className="flex-shrink-0">{header}</div>}

        {/* Content container */}
        <div
          className={tcx(
            "flex-grow flex flex-col",
            bordered && "p-6",
            !expandToContent && "overflow-hidden",
            contentClassName,
          )}
        >
          {/* Inner container with optional border */}
          <div
            className={tcx(
              "flex-grow flex flex-col",
              bordered && "border border-stroke rounded-lg bg-white",
              !expandToContent && "overflow-hidden",
            )}
          >
            {/* Split container */}
            <div
              className={tcx(
                "flex-grow flex flex-col lg:flex-row",
                !expandToContent && "overflow-auto",
                "divide-y lg:divide-y-0 lg:divide-x",
                splitClassName,
              )}
            >
              {/* Left panel */}
              <div
                className={tcx(
                  "flex flex-col",
                  "w-full",
                  "lg:min-w-[640px] lg:max-w-[640px] lg:w-[50%]",
                  "transition-all duration-300",
                )}
              >
                <div className="overflow-y-auto grow" ref={leftPanelRef}>
                  {left}
                </div>
              </div>
              {/* Right panel */}
              <div
                className={tcx(
                  "flex flex-col grow",
                  "w-full",
                  "transition-all duration-300 overflow-x-hidden",
                )}
              >
                <div className="overflow-y-auto grow" ref={rightPanelRef}>
                  {right}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        {footer && <div className="flex-shrink-0">{footer}</div>}
      </div>
    </AlertSourceSplitLayoutContext.Provider>
  );
};

// Rounded content box that adapts the gradient to match the colours of the
// currently selected or active alert source.
//
// We either show the gradient box as a header or fullscreen, which alters the
// gradient pitch slightly.
export const AlertSourceGradientBox = ({
  className,
  sourceTypeConfig,
  children,
}: {
  className?: string;
  sourceTypeConfig?: SourceTypeConfig;
  children: React.ReactNode;
}) => {
  const background = sourceTypeConfig
    ? `linear-gradient(${sourceTypeConfig.hexColor}10 0%, white 90%, white 100%)`
    : `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`;

  return (
    <div className={`flex flex-col grow ${className || ""}`}>
      <motion.div
        className="flex flex-col grow rounded-lg bg-gradient-to-b h-full"
        animate={{ background }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

// We use the same footer across alert source pages.
export const AlertSourceFooter = ({
  backHref,
  continueButton,
}: {
  backHref?: string;
  continueButton: React.ReactElement;
}) => {
  return (
    <FloatingFooter containerClassName={"px-4 !mt-0"}>
      {/* Show back button if we have a href */}
      {backHref ? (
        <Button
          analyticsTrackingId="alert-source-create-back"
          theme={ButtonTheme.Secondary}
          href={backHref}
        >
          Back
        </Button>
      ) : null}
      {continueButton}
    </FloatingFooter>
  );
};
