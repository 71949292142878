/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulesCreateExternalRequestBody
 */
export interface SchedulesCreateExternalRequestBody {
    /**
     * The configuration of the schedule in the external provider's format
     * @type {string}
     * @memberof SchedulesCreateExternalRequestBody
     */
    config?: string;
    /**
     * ID of the pay config connected with this schedule
     * @type {string}
     * @memberof SchedulesCreateExternalRequestBody
     */
    default_pay_config_id?: string;
    /**
     * Name of external provider, e.g. pagerduty
     * @type {string}
     * @memberof SchedulesCreateExternalRequestBody
     */
    external_provider: SchedulesCreateExternalRequestBodyExternalProviderEnum;
    /**
     * ID of existing schedule in external system
     * @type {string}
     * @memberof SchedulesCreateExternalRequestBody
     */
    external_provider_id: string;
}

/**
* @export
* @enum {string}
*/
export enum SchedulesCreateExternalRequestBodyExternalProviderEnum {
    Native = 'native',
    Pagerduty = 'pagerduty',
    Opsgenie = 'opsgenie'
}

export function SchedulesCreateExternalRequestBodyFromJSON(json: any): SchedulesCreateExternalRequestBody {
    return SchedulesCreateExternalRequestBodyFromJSONTyped(json, false);
}

export function SchedulesCreateExternalRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateExternalRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : json['config'],
        'default_pay_config_id': !exists(json, 'default_pay_config_id') ? undefined : json['default_pay_config_id'],
        'external_provider': json['external_provider'],
        'external_provider_id': json['external_provider_id'],
    };
}

export function SchedulesCreateExternalRequestBodyToJSON(value?: SchedulesCreateExternalRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': value.config,
        'default_pay_config_id': value.default_pay_config_id,
        'external_provider': value.external_provider,
        'external_provider_id': value.external_provider_id,
    };
}

