/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizeConfig,
    LocalizeConfigFromJSON,
    LocalizeConfigFromJSONTyped,
    LocalizeConfigToJSON,
} from './LocalizeConfig';
import {
    StatusPageComponent,
    StatusPageComponentFromJSON,
    StatusPageComponentFromJSONTyped,
    StatusPageComponentToJSON,
} from './StatusPageComponent';
import {
    StatusPageCustomDomainRecord,
    StatusPageCustomDomainRecordFromJSON,
    StatusPageCustomDomainRecordFromJSONTyped,
    StatusPageCustomDomainRecordToJSON,
} from './StatusPageCustomDomainRecord';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';
import {
    UnbabelConfig,
    UnbabelConfigFromJSON,
    UnbabelConfigFromJSONTyped,
    UnbabelConfigToJSON,
} from './UnbabelConfig';

/**
 * 
 * @export
 * @interface StatusPage
 */
export interface StatusPage {
    /**
     * The number of active subscribers to all incidents on this status page.
     * @type {number}
     * @memberof StatusPage
     */
    active_subscriber_count: number;
    /**
     * Whether this status page should be indexed by search engines
     * @type {boolean}
     * @memberof StatusPage
     */
    allow_search_engine_indexing: boolean;
    /**
     * If this is a parent page, the catalog type that should define the possible components that can be selected.
     * @type {string}
     * @memberof StatusPage
     */
    component_catalog_type_id?: string;
    /**
     * All the available components that can be shown on this status page
     * @type {Array<StatusPageComponent>}
     * @memberof StatusPage
     */
    components: Array<StatusPageComponent>;
    /**
     * When the page was created
     * @type {Date}
     * @memberof StatusPage
     */
    created_at: Date;
    /**
     * The custom domain this page is hosted under, if any
     * @type {string}
     * @memberof StatusPage
     */
    custom_domain?: string;
    /**
     * The DNS records that relate to the given custom domain
     * @type {Array<StatusPageCustomDomainRecord>}
     * @memberof StatusPage
     */
    custom_domain_records: Array<StatusPageCustomDomainRecord>;
    /**
     * When the custom domain was last updated
     * @type {Date}
     * @memberof StatusPage
     */
    custom_domain_updated_at?: Date;
    /**
     * When the custom domain was last successfully verified
     * @type {Date}
     * @memberof StatusPage
     */
    custom_domain_verified_at?: Date;
    /**
     * The GCS key where the logo for this status page is stored
     * @type {string}
     * @memberof StatusPage
     */
    darkmode_logo_key?: string;
    /**
     * The publicly-accessible URL of the logo for this status page
     * @type {string}
     * @memberof StatusPage
     */
    darkmode_logo_url?: string;
    /**
     * Whether to use Calendar or List view
     * @type {string}
     * @memberof StatusPage
     */
    date_view?: StatusPageDateViewEnum;
    /**
     * How should we display uptime against this page's components?
     * @type {string}
     * @memberof StatusPage
     */
    display_uptime_mode: StatusPageDisplayUptimeModeEnum;
    /**
     * The GCS key where the email logo for this status page is stored
     * @type {string}
     * @memberof StatusPage
     */
    email_logo_key?: string;
    /**
     * The publicly-accessible URL of the email logo for this status page
     * @type {string}
     * @memberof StatusPage
     */
    email_logo_url?: string;
    /**
     * Whether this public page exposes a JSON endpoint with its current status
     * @type {boolean}
     * @memberof StatusPage
     */
    expose_status_summary_api: boolean;
    /**
     * The GCS key where the favicon for this status page is stored
     * @type {string}
     * @memberof StatusPage
     */
    favicon_key?: string;
    /**
     * The publicly-accessible URL of the favicon for this status page
     * @type {string}
     * @memberof StatusPage
     */
    favicon_url?: string;
    /**
     * The unique tag for this page from the organisation's google analytics account
     * @type {string}
     * @memberof StatusPage
     */
    google_analytics_tag?: string;
    /**
     * Does this status page have any ongoing incidents?
     * @type {boolean}
     * @memberof StatusPage
     */
    has_ongoing_incident: boolean;
    /**
     * The ID of the status page
     * @type {string}
     * @memberof StatusPage
     */
    id: string;
    /**
     * Which Atlassian Statuspage page this was imported from, if any
     * @type {string}
     * @memberof StatusPage
     */
    imported_from_atlassian_page_id?: string;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPage
     */
    intro_text?: TextNode;
    /**
     * The GCS key where the lightmode logo for this status page is stored
     * @type {string}
     * @memberof StatusPage
     */
    lightmode_logo_key?: string;
    /**
     * The publicly-accessible URL of the lightmode logo for this status page
     * @type {string}
     * @memberof StatusPage
     */
    lightmode_logo_url?: string;
    /**
     * 
     * @type {LocalizeConfig}
     * @memberof StatusPage
     */
    localize_config?: LocalizeConfig;
    /**
     * Is this page mirroring an Atlassian Statuspage page?
     * @type {boolean}
     * @memberof StatusPage
     */
    mirroring_atlassian_page: boolean;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPage
     */
    name: string;
    /**
     * The type of page, which defines how it is used
     * @type {string}
     * @memberof StatusPage
     */
    page_type: StatusPagePageTypeEnum;
    /**
     * The publicly accessible URL of the privacy policy for this status page
     * @type {string}
     * @memberof StatusPage
     */
    privacy_policy_url?: string;
    /**
     * The primary URL where this status page can be accessed.
     * @type {string}
     * @memberof StatusPage
     */
    public_url: string;
    /**
     * If this is a parent page, the catalog type that should define the possible subpages that sit under it.
     * @type {string}
     * @memberof StatusPage
     */
    split_by_catalog_type_id?: string;
    /**
     * If this is a parent page, this defines the components visible on each sub page.
     * @type {string}
     * @memberof StatusPage
     */
    split_by_component_attribute_id?: string;
    /**
     * The subpath of the status page for our standard domain
     * @type {string}
     * @memberof StatusPage
     */
    subpath: string;
    /**
     * Whether subscriptions have been entirely disabled for this status page
     * @type {boolean}
     * @memberof StatusPage
     */
    subscriptions_disabled: boolean;
    /**
     * The email address that subscription updates should be sent to.
     * @type {string}
     * @memberof StatusPage
     */
    subscriptions_reply_to?: string;
    /**
     * The text to be displayed on the button redirecting users to a support channel
     * @type {string}
     * @memberof StatusPage
     */
    support_label?: string;
    /**
     * A URL where users can report issues to the company that runs this status page
     * @type {string}
     * @memberof StatusPage
     */
    support_url?: string;
    /**
     * Which types of templates are used for this page?
     * @type {string}
     * @memberof StatusPage
     */
    template_mode: StatusPageTemplateModeEnum;
    /**
     * The publicly accessible URL of the terms of service for this status page
     * @type {string}
     * @memberof StatusPage
     */
    terms_of_service_url?: string;
    /**
     * Whether to use Light mode or Dark mode
     * @type {string}
     * @memberof StatusPage
     */
    theme: StatusPageThemeEnum;
    /**
     * 
     * @type {UnbabelConfig}
     * @memberof StatusPage
     */
    unbabel_config?: UnbabelConfig;
    /**
     * When the status page was marked as live
     * @type {Date}
     * @memberof StatusPage
     */
    went_live_at?: Date;
    /**
     * The ID of the attribute that defines the whitelist of users who can access this page
     * @type {string}
     * @memberof StatusPage
     */
    whitelist_defined_by_attribute_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageDateViewEnum {
    Calendar = 'calendar',
    List = 'list'
}/**
* @export
* @enum {string}
*/
export enum StatusPageDisplayUptimeModeEnum {
    ChartAndPercentage = 'chart_and_percentage',
    ChartOnly = 'chart_only',
    Nothing = 'nothing'
}/**
* @export
* @enum {string}
*/
export enum StatusPagePageTypeEnum {
    Standalone = 'standalone',
    Parent = 'parent',
    Customer = 'customer'
}/**
* @export
* @enum {string}
*/
export enum StatusPageTemplateModeEnum {
    MessageTemplate = 'message_template',
    PreDefinedUpdate = 'pre_defined_update'
}/**
* @export
* @enum {string}
*/
export enum StatusPageThemeEnum {
    Light = 'light',
    Dark = 'dark'
}

export function StatusPageFromJSON(json: any): StatusPage {
    return StatusPageFromJSONTyped(json, false);
}

export function StatusPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active_subscriber_count': json['active_subscriber_count'],
        'allow_search_engine_indexing': json['allow_search_engine_indexing'],
        'component_catalog_type_id': !exists(json, 'component_catalog_type_id') ? undefined : json['component_catalog_type_id'],
        'components': ((json['components'] as Array<any>).map(StatusPageComponentFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'custom_domain': !exists(json, 'custom_domain') ? undefined : json['custom_domain'],
        'custom_domain_records': ((json['custom_domain_records'] as Array<any>).map(StatusPageCustomDomainRecordFromJSON)),
        'custom_domain_updated_at': !exists(json, 'custom_domain_updated_at') ? undefined : (new Date(json['custom_domain_updated_at'])),
        'custom_domain_verified_at': !exists(json, 'custom_domain_verified_at') ? undefined : (new Date(json['custom_domain_verified_at'])),
        'darkmode_logo_key': !exists(json, 'darkmode_logo_key') ? undefined : json['darkmode_logo_key'],
        'darkmode_logo_url': !exists(json, 'darkmode_logo_url') ? undefined : json['darkmode_logo_url'],
        'date_view': !exists(json, 'date_view') ? undefined : json['date_view'],
        'display_uptime_mode': json['display_uptime_mode'],
        'email_logo_key': !exists(json, 'email_logo_key') ? undefined : json['email_logo_key'],
        'email_logo_url': !exists(json, 'email_logo_url') ? undefined : json['email_logo_url'],
        'expose_status_summary_api': json['expose_status_summary_api'],
        'favicon_key': !exists(json, 'favicon_key') ? undefined : json['favicon_key'],
        'favicon_url': !exists(json, 'favicon_url') ? undefined : json['favicon_url'],
        'google_analytics_tag': !exists(json, 'google_analytics_tag') ? undefined : json['google_analytics_tag'],
        'has_ongoing_incident': json['has_ongoing_incident'],
        'id': json['id'],
        'imported_from_atlassian_page_id': !exists(json, 'imported_from_atlassian_page_id') ? undefined : json['imported_from_atlassian_page_id'],
        'intro_text': !exists(json, 'intro_text') ? undefined : TextNodeFromJSON(json['intro_text']),
        'lightmode_logo_key': !exists(json, 'lightmode_logo_key') ? undefined : json['lightmode_logo_key'],
        'lightmode_logo_url': !exists(json, 'lightmode_logo_url') ? undefined : json['lightmode_logo_url'],
        'localize_config': !exists(json, 'localize_config') ? undefined : LocalizeConfigFromJSON(json['localize_config']),
        'mirroring_atlassian_page': json['mirroring_atlassian_page'],
        'name': json['name'],
        'page_type': json['page_type'],
        'privacy_policy_url': !exists(json, 'privacy_policy_url') ? undefined : json['privacy_policy_url'],
        'public_url': json['public_url'],
        'split_by_catalog_type_id': !exists(json, 'split_by_catalog_type_id') ? undefined : json['split_by_catalog_type_id'],
        'split_by_component_attribute_id': !exists(json, 'split_by_component_attribute_id') ? undefined : json['split_by_component_attribute_id'],
        'subpath': json['subpath'],
        'subscriptions_disabled': json['subscriptions_disabled'],
        'subscriptions_reply_to': !exists(json, 'subscriptions_reply_to') ? undefined : json['subscriptions_reply_to'],
        'support_label': !exists(json, 'support_label') ? undefined : json['support_label'],
        'support_url': !exists(json, 'support_url') ? undefined : json['support_url'],
        'template_mode': json['template_mode'],
        'terms_of_service_url': !exists(json, 'terms_of_service_url') ? undefined : json['terms_of_service_url'],
        'theme': json['theme'],
        'unbabel_config': !exists(json, 'unbabel_config') ? undefined : UnbabelConfigFromJSON(json['unbabel_config']),
        'went_live_at': !exists(json, 'went_live_at') ? undefined : (new Date(json['went_live_at'])),
        'whitelist_defined_by_attribute_id': !exists(json, 'whitelist_defined_by_attribute_id') ? undefined : json['whitelist_defined_by_attribute_id'],
    };
}

export function StatusPageToJSON(value?: StatusPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_subscriber_count': value.active_subscriber_count,
        'allow_search_engine_indexing': value.allow_search_engine_indexing,
        'component_catalog_type_id': value.component_catalog_type_id,
        'components': ((value.components as Array<any>).map(StatusPageComponentToJSON)),
        'created_at': (value.created_at.toISOString()),
        'custom_domain': value.custom_domain,
        'custom_domain_records': ((value.custom_domain_records as Array<any>).map(StatusPageCustomDomainRecordToJSON)),
        'custom_domain_updated_at': value.custom_domain_updated_at === undefined ? undefined : (value.custom_domain_updated_at.toISOString()),
        'custom_domain_verified_at': value.custom_domain_verified_at === undefined ? undefined : (value.custom_domain_verified_at.toISOString()),
        'darkmode_logo_key': value.darkmode_logo_key,
        'darkmode_logo_url': value.darkmode_logo_url,
        'date_view': value.date_view,
        'display_uptime_mode': value.display_uptime_mode,
        'email_logo_key': value.email_logo_key,
        'email_logo_url': value.email_logo_url,
        'expose_status_summary_api': value.expose_status_summary_api,
        'favicon_key': value.favicon_key,
        'favicon_url': value.favicon_url,
        'google_analytics_tag': value.google_analytics_tag,
        'has_ongoing_incident': value.has_ongoing_incident,
        'id': value.id,
        'imported_from_atlassian_page_id': value.imported_from_atlassian_page_id,
        'intro_text': TextNodeToJSON(value.intro_text),
        'lightmode_logo_key': value.lightmode_logo_key,
        'lightmode_logo_url': value.lightmode_logo_url,
        'localize_config': LocalizeConfigToJSON(value.localize_config),
        'mirroring_atlassian_page': value.mirroring_atlassian_page,
        'name': value.name,
        'page_type': value.page_type,
        'privacy_policy_url': value.privacy_policy_url,
        'public_url': value.public_url,
        'split_by_catalog_type_id': value.split_by_catalog_type_id,
        'split_by_component_attribute_id': value.split_by_component_attribute_id,
        'subpath': value.subpath,
        'subscriptions_disabled': value.subscriptions_disabled,
        'subscriptions_reply_to': value.subscriptions_reply_to,
        'support_label': value.support_label,
        'support_url': value.support_url,
        'template_mode': value.template_mode,
        'terms_of_service_url': value.terms_of_service_url,
        'theme': value.theme,
        'unbabel_config': UnbabelConfigToJSON(value.unbabel_config),
        'went_live_at': value.went_live_at === undefined ? undefined : (value.went_live_at.toISOString()),
        'whitelist_defined_by_attribute_id': value.whitelist_defined_by_attribute_id,
    };
}

