/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionPayload,
    ConditionPayloadFromJSON,
    ConditionPayloadFromJSONTyped,
    ConditionPayloadToJSON,
} from './ConditionPayload';
import {
    EscalationPathNodePayload,
    EscalationPathNodePayloadFromJSON,
    EscalationPathNodePayloadFromJSONTyped,
    EscalationPathNodePayloadToJSON,
} from './EscalationPathNodePayload';

/**
 * 
 * @export
 * @interface EscalationPathNodeIfElsePayload
 */
export interface EscalationPathNodeIfElsePayload {
    /**
     * The condition that defines which branch to take
     * @type {Array<ConditionPayload>}
     * @memberof EscalationPathNodeIfElsePayload
     */
    conditions?: Array<ConditionPayload>;
    /**
     * The nodes that form the levels if our condition is not met
     * @type {Array<EscalationPathNodePayload>}
     * @memberof EscalationPathNodeIfElsePayload
     */
    else_path: Array<EscalationPathNodePayload>;
    /**
     * The nodes that form the levels if our condition is met
     * @type {Array<EscalationPathNodePayload>}
     * @memberof EscalationPathNodeIfElsePayload
     */
    then_path: Array<EscalationPathNodePayload>;
}

export function EscalationPathNodeIfElsePayloadFromJSON(json: any): EscalationPathNodeIfElsePayload {
    return EscalationPathNodeIfElsePayloadFromJSONTyped(json, false);
}

export function EscalationPathNodeIfElsePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNodeIfElsePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': !exists(json, 'conditions') ? undefined : ((json['conditions'] as Array<any>).map(ConditionPayloadFromJSON)),
        'else_path': ((json['else_path'] as Array<any>).map(EscalationPathNodePayloadFromJSON)),
        'then_path': ((json['then_path'] as Array<any>).map(EscalationPathNodePayloadFromJSON)),
    };
}

export function EscalationPathNodeIfElsePayloadToJSON(value?: EscalationPathNodeIfElsePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': value.conditions === undefined ? undefined : ((value.conditions as Array<any>).map(ConditionPayloadToJSON)),
        'else_path': ((value.else_path as Array<any>).map(EscalationPathNodePayloadToJSON)),
        'then_path': ((value.then_path as Array<any>).map(EscalationPathNodePayloadToJSON)),
    };
}

