import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ButtonTheme, ConfirmationDialog, ErrorMessage } from "@incident-ui";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

export const DeleteDashboardModal = ({
  onClose,
  id,
  name,
}: {
  onClose: () => void;
  id: string;
  name: string;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const refetchDashboards = useRevalidate(["insightsListCustomDashboards"]);

  const {
    trigger: deleteDashboard,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "insightsListCustomDashboards",
    {},
    async (apiClient) => {
      await apiClient.insightsDestroyDashboard({
        id,
      });
    },
    {
      onSuccess: () => {
        refetchDashboards();
        onClose();
        navigate("/insights");
      },
    },
  );

  return (
    <ConfirmationDialog
      confirmButtonText="Delete"
      onCancel={onClose}
      onConfirm={() => deleteDashboard({ id })}
      isOpen={true}
      title={`Delete dashboard?`}
      analyticsTrackingId="delete-dashboard"
      saving={saving}
      confirmButtonTheme={ButtonTheme.Destroy}
    >
      <p>This will remove the dashboard and all of its contents</p>
      <p className="font-medium mt-2 p-3 rounded-lg bg-surface-secondary text-content-primary">
        {name}
      </p>
      <ErrorMessage message={genericError} />
    </ConfirmationDialog>
  );
};
