import {
  CatalogType,
  CatalogTypeCategoriesEnum as CategoriesEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, ToastTheme } from "@incident-ui";
import { CSMChattyProductMarketingBanner } from "@incident-ui/ProductMarketingBanner/CSMChattyProductMarketingBanner";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useCanAddCustomCatalogType } from "../common/useCanAddCustomCatalogType";
import { checkHasWizardTypeForCategory } from "../wizards/useHasTypeOfCategory";
import servicesCTAGraphic from "./services-cta-graphic.svg";
import teamsCTAGraphic from "./teams-cta-graphic.svg";

export const CATALOG_TEAMS_WIZARD_CTA = "catalog-teams-wizard";
export const CATALOG_SERVICES_WIZARD_CTA = "catalog-services-wizard";

export type CatalogBannerType = "team" | "service";

export const CatalogHeroBanner = ({
  bannerType,
}: {
  bannerType: CatalogBannerType;
}) => {
  switch (bannerType) {
    case "team":
      return <SetUpTeamsBanner />;
    case "service":
      return <SetUpServicesBanner />;
    default:
      return null;
  }
};

type CatalogWizardState = {
  hasScope: boolean;
  nextWizard?: CatalogBannerType;
  wizardsComplete: boolean;
};

export const useShowCatalogBanner = (): CatalogWizardState => {
  const {
    data: { catalog_types: catalogTypes },
    isLoading: typesLoading,
    error: typesError,
  } = useAPI(
    "catalogListTypes",
    { includeCount: true },
    { fallbackData: { catalog_types: [] } },
  );
  const { hasScope } = useIdentity();

  // Check custom catalog type billing gate
  const { upgradeRequired } = useCanAddCustomCatalogType();

  // Check if user can create catalog types
  const hasRequiredScope = hasScope(ScopeNameEnum.CatalogTypesCreate);

  const { hasMatchingType: hasTeamType } = checkHasWizardTypeForCategory(
    catalogTypes,
    CategoriesEnum.Team,
  );
  const { hasMatchingType: hasServiceType } = checkHasWizardTypeForCategory(
    catalogTypes,
    CategoriesEnum.Service,
  );

  const result: CatalogWizardState = {
    hasScope: hasRequiredScope && !upgradeRequired,
    wizardsComplete: hasTeamType && hasServiceType,
  };

  if (!result.hasScope) {
    return result;
  }

  if (typesLoading || typesError) {
    // We don't want to show the banner if we're loading the types or error
    return result;
  }

  if (!hasTeamType) {
    result.nextWizard = "team";
  } else if (!hasServiceType) {
    result.nextWizard = "service";
  }

  return result;
};

type BannerConfig = {
  cta: string;
  copy: React.ReactNode;
  buttonText: string;
  slug: CatalogBannerType;
  bg: string;
};

export const SetUpTeamsBanner = ({
  catalogType,
}: {
  catalogType?: CatalogType;
}) => {
  const config: BannerConfig = catalogType
    ? {
        cta: `${CATALOG_TEAMS_WIZARD_CTA}-${catalogType.id}`,
        copy: (
          <div>
            <span className="text-purple-content">Add more attributes</span> to
            extend types to leverage Catalog in workflows, follow-ups, policies
            and more
          </div>
        ),
        buttonText: "Continue",
        slug: "team",
        bg: "bg-purple-surface",
      }
    : {
        cta: CATALOG_TEAMS_WIZARD_CTA,
        copy: (
          <div>
            <span className="text-purple-content">
              Set up your teams in Catalog
            </span>{" "}
            to build powerful automations and get team-by-team insights
          </div>
        ),
        buttonText: "Set up teams",
        slug: "team",
        bg: "bg-purple-surface",
      };

  return <SetUpBanner catalogType={catalogType} config={config} />;
};

export const SetUpServicesBanner = ({
  catalogType,
}: {
  catalogType?: CatalogType;
}) => {
  const config: BannerConfig = catalogType
    ? {
        cta: `${CATALOG_SERVICES_WIZARD_CTA}-${catalogType.id}`,
        copy: (
          <div>
            <span className="text-green-content">Add more attributes</span> to
            extend types to leverage Catalog in workflows, alert routes and more
          </div>
        ),
        buttonText: "Continue",
        slug: "service",
        bg: "bg-green-surface",
      }
    : {
        cta: CATALOG_SERVICES_WIZARD_CTA,
        copy: (
          <div>
            <span className="text-green-content">
              Set up your services in Catalog
            </span>{" "}
            to route alerts to the right people
          </div>
        ),
        buttonText: "Set up services",
        slug: "service",
        bg: "bg-green-surface",
      };

  return <SetUpBanner catalogType={catalogType} config={config} />;
};

export const SetUpBanner = ({
  catalogType,
  config,
}: {
  catalogType?: CatalogType;
  config: BannerConfig;
}) => {
  const { identity, hasDismissedCTA } = useIdentity();
  const showToast = useToast();
  const { isLimited } = useCanAddCustomCatalogType();

  const { trigger: dismissBanner, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: config.cta,
          for_whole_organisation: false,
        },
      });
    },
    {
      onError: () =>
        showToast({
          title: "Could not dismiss banner",
          theme: ToastTheme.Error,
        }),
    },
  );

  if (dismissing || hasDismissedCTA(config.cta)) {
    return null;
  }

  if (catalogType) {
    // If the type already has a few attributes, don't show the banner
    if (catalogType.schema.attributes.length >= 3) {
      return null;
    }
  }

  return (
    <CSMChattyProductMarketingBanner
      className={config.bg}
      copy={config.copy}
      graphic={<SetupCatalogGraphic bannerType={config.slug} />}
      cta={
        <GatedButton
          theme={ButtonTheme.Primary}
          href={
            catalogType
              ? `/catalog/${config.slug}-wizard/${catalogType.id}/add-attributes`
              : `/catalog/${config.slug}-wizard`
          }
          analyticsTrackingId={`set-up-${config.slug}s-banner`}
          requiredScope={ScopeNameEnum.CatalogTypesCreate}
          upgradeRequiredProps={{
            gate: {
              type: "numeric",
              value: identity.feature_gates.custom_catalog_types_count,
              featureNameSingular: "custom catalog type",
            },
            featureName: "custom catalog types",
          }}
        >
          {config.buttonText}
        </GatedButton>
      }
      secondaryCta={
        // Hide the dismiss button if the org can only create 2 catalog types, so
        // they don't lose the banner
        isLimited ? undefined : (
          <Button
            analyticsTrackingId={`set-up-${config.slug}s-dismiss`}
            theme={ButtonTheme.Naked}
            onClick={() => dismissBanner({})}
          >
            Dismiss
          </Button>
        )
      }
    />
  );
};

export const SetupCatalogGraphic = ({
  bannerType,
  className,
}: {
  bannerType: CatalogBannerType;
  className?: string;
}) => {
  const graphic = bannerType === "team" ? teamsCTAGraphic : servicesCTAGraphic;
  return <img src={graphic} className={className} />;
};
