/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsListResponseBody,
    IntegrationsListResponseBodyFromJSON,
    IntegrationsListResponseBodyToJSON,
    IntegrationsSetIntegrationAPITokenRequestBody,
    IntegrationsSetIntegrationAPITokenRequestBodyFromJSON,
    IntegrationsSetIntegrationAPITokenRequestBodyToJSON,
    IntegrationsSetIntegrationAPITokenResponseBody,
    IntegrationsSetIntegrationAPITokenResponseBodyFromJSON,
    IntegrationsSetIntegrationAPITokenResponseBodyToJSON,
} from '../models';

export interface IntegrationsDestroyIntegrationApiTokenRequest {
    provider: IntegrationsDestroyIntegrationApiTokenProviderEnum;
}

export interface IntegrationsSetIntegrationApiTokenRequest {
    setIntegrationApiTokenRequestBody: IntegrationsSetIntegrationAPITokenRequestBody;
}

/**
 * 
 */
export class IntegrationsApi extends runtime.BaseAPI {

    /**
     * Remove Integration API Token
     * DestroyIntegrationApiToken Integrations
     */
    async integrationsDestroyIntegrationApiTokenRaw(requestParameters: IntegrationsDestroyIntegrationApiTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling integrationsDestroyIntegrationApiToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/self/actions/integrations/api_token/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Integration API Token
     * DestroyIntegrationApiToken Integrations
     */
    async integrationsDestroyIntegrationApiToken(requestParameters: IntegrationsDestroyIntegrationApiTokenRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsDestroyIntegrationApiTokenRaw(requestParameters, initOverrides);
    }

    /**
     * List all integrations, and whether or not they\'re installed and usable for this org
     * List Integrations
     */
    async integrationsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all integrations, and whether or not they\'re installed and usable for this org
     * List Integrations
     */
    async integrationsList(initOverrides?: RequestInit): Promise<IntegrationsListResponseBody> {
        const response = await this.integrationsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set Integration API Token
     * SetIntegrationApiToken Integrations
     */
    async integrationsSetIntegrationApiTokenRaw(requestParameters: IntegrationsSetIntegrationApiTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsSetIntegrationAPITokenResponseBody>> {
        if (requestParameters.setIntegrationApiTokenRequestBody === null || requestParameters.setIntegrationApiTokenRequestBody === undefined) {
            throw new runtime.RequiredError('setIntegrationApiTokenRequestBody','Required parameter requestParameters.setIntegrationApiTokenRequestBody was null or undefined when calling integrationsSetIntegrationApiToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/integrations/self/actions/integrations/set_api_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationsSetIntegrationAPITokenRequestBodyToJSON(requestParameters.setIntegrationApiTokenRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsSetIntegrationAPITokenResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set Integration API Token
     * SetIntegrationApiToken Integrations
     */
    async integrationsSetIntegrationApiToken(requestParameters: IntegrationsSetIntegrationApiTokenRequest, initOverrides?: RequestInit): Promise<IntegrationsSetIntegrationAPITokenResponseBody> {
        const response = await this.integrationsSetIntegrationApiTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IntegrationsDestroyIntegrationApiTokenProviderEnum {
    Cortex = 'cortex',
    JiraServer = 'jira_server',
    Opslevel = 'opslevel',
    Opsgenie = 'opsgenie'
}
