import { ScheduleEntry, ScheduleOverride } from "@incident-io/api";
import _ from "lodash";
import { DateTime } from "luxon";

type ScheduleEntryOrOverride = ScheduleEntry | ScheduleOverride;

// flattenScheduleEntries takes a list of schedule entries and merges any contiguous entries for the same person and layer
// e.g. [A][A][B] -> [AA][B]
export const flattenScheduleEntries = (
  scheduleEntries: ScheduleEntryOrOverride[],
) => {
  const uniqueLayers = _.chain(scheduleEntries)
    .map((s) => s.layer_id)
    .uniq()
    .value();

  const results: ScheduleEntryOrOverride[] = [];
  for (const layerId of uniqueLayers) {
    // Filter for this layer, and then sort, so we do them in the right order
    const sortedEntries = _.chain(scheduleEntries)
      .filter((e) => e.layer_id === layerId)
      .sortBy((s) => s.start_at.toISOString())
      .value();

    for (let i = 0; i < sortedEntries.length; i++) {
      const currentEntry = sortedEntries[i];
      const previousEntry = results[results.length - 1];

      if (!currentEntry) {
        continue;
      }

      // If the previous entry is a different layer, then we can't merge them
      if (previousEntry && previousEntry.layer_id !== currentEntry.layer_id) {
        results.push(currentEntry);
        continue;
      }

      // We don't want to merge differing overrides
      if (
        previousEntry &&
        "override_id" in previousEntry &&
        "override_id" in currentEntry &&
        previousEntry.override_id !== currentEntry.override_id
      ) {
        results.push(currentEntry);
        continue;
      }

      // If the previous entry is the same user, and the start time is within 1 minute of the previous end time, then
      // we can merge them together
      if (
        previousEntry &&
        !!getId(previousEntry) &&
        getId(previousEntry) === getId(currentEntry)
      ) {
        const previousEndTime = DateTime.fromJSDate(previousEntry.end_at);
        const startTime = DateTime.fromJSDate(currentEntry.start_at);
        if (startTime.diff(previousEndTime, "minutes").minutes < 1) {
          previousEntry.end_at = currentEntry.end_at;
        } else {
          results.push(currentEntry);
        }
      } else {
        results.push(currentEntry);
      }
    }
  }

  return results;
};

const getId = (entry: ScheduleEntryOrOverride) => {
  // If it's an override, it'll have an id
  if ("external_user_id" in entry) {
    // If it's a schedule entry, it'll have an external_user_id
    return entry.external_user_id;
  } else if ("user_id" in entry) {
    return entry.user_id;
  }
  return undefined;
};
