/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedMicrosoftTeamsMessage,
    EnrichedMicrosoftTeamsMessageFromJSON,
    EnrichedMicrosoftTeamsMessageFromJSONTyped,
    EnrichedMicrosoftTeamsMessageToJSON,
} from './EnrichedMicrosoftTeamsMessage';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface MicrosoftTeamsPin
 */
export interface MicrosoftTeamsPin {
    /**
     * 
     * @type {EnrichedMicrosoftTeamsMessage}
     * @memberof MicrosoftTeamsPin
     */
    message?: EnrichedMicrosoftTeamsMessage;
    /**
     * the message details
     * @type {string}
     * @memberof MicrosoftTeamsPin
     */
    message_enrichment_failure?: string;
    /**
     * 
     * @type {User}
     * @memberof MicrosoftTeamsPin
     */
    message_user?: User;
    /**
     * 
     * @type {User}
     * @memberof MicrosoftTeamsPin
     */
    user?: User;
}

export function MicrosoftTeamsPinFromJSON(json: any): MicrosoftTeamsPin {
    return MicrosoftTeamsPinFromJSONTyped(json, false);
}

export function MicrosoftTeamsPinFromJSONTyped(json: any, ignoreDiscriminator: boolean): MicrosoftTeamsPin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : EnrichedMicrosoftTeamsMessageFromJSON(json['message']),
        'message_enrichment_failure': !exists(json, 'message_enrichment_failure') ? undefined : json['message_enrichment_failure'],
        'message_user': !exists(json, 'message_user') ? undefined : UserFromJSON(json['message_user']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function MicrosoftTeamsPinToJSON(value?: MicrosoftTeamsPin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': EnrichedMicrosoftTeamsMessageToJSON(value.message),
        'message_enrichment_failure': value.message_enrichment_failure,
        'message_user': UserToJSON(value.message_user),
        'user': UserToJSON(value.user),
    };
}

