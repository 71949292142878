/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentRoleWithoutConditions
 */
export interface IncidentRoleWithoutConditions {
    /**
     * When the role was created
     * @type {Date}
     * @memberof IncidentRoleWithoutConditions
     */
    created_at: Date;
    /**
     * Describes the purpose of the role
     * @type {string}
     * @memberof IncidentRoleWithoutConditions
     */
    description: string;
    /**
     * Unique identifier for the role
     * @type {string}
     * @memberof IncidentRoleWithoutConditions
     */
    id: string;
    /**
     * Provided to whoever is nominated for the role. Note that this will be empty for the 'reporter' role.
     * @type {string}
     * @memberof IncidentRoleWithoutConditions
     */
    instructions: string;
    /**
     * Human readable name of the incident role
     * @type {string}
     * @memberof IncidentRoleWithoutConditions
     */
    name: string;
    /**
     * Type of incident role
     * @type {string}
     * @memberof IncidentRoleWithoutConditions
     */
    role_type: IncidentRoleWithoutConditionsRoleTypeEnum;
    /**
     * Short human readable name for Slack. Note that this will be empty for the 'reporter' role.
     * @type {string}
     * @memberof IncidentRoleWithoutConditions
     */
    shortform?: string;
    /**
     * Human readable name of the incident role, when in a stream
     * @type {string}
     * @memberof IncidentRoleWithoutConditions
     */
    stream_name?: string;
    /**
     * When the role was last updated
     * @type {Date}
     * @memberof IncidentRoleWithoutConditions
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum IncidentRoleWithoutConditionsRoleTypeEnum {
    Lead = 'lead',
    Reporter = 'reporter',
    Custom = 'custom'
}

export function IncidentRoleWithoutConditionsFromJSON(json: any): IncidentRoleWithoutConditions {
    return IncidentRoleWithoutConditionsFromJSONTyped(json, false);
}

export function IncidentRoleWithoutConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentRoleWithoutConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'description': json['description'],
        'id': json['id'],
        'instructions': json['instructions'],
        'name': json['name'],
        'role_type': json['role_type'],
        'shortform': !exists(json, 'shortform') ? undefined : json['shortform'],
        'stream_name': !exists(json, 'stream_name') ? undefined : json['stream_name'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentRoleWithoutConditionsToJSON(value?: IncidentRoleWithoutConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'id': value.id,
        'instructions': value.instructions,
        'name': value.name,
        'role_type': value.role_type,
        'shortform': value.shortform,
        'stream_name': value.stream_name,
        'updated_at': (value.updated_at.toISOString()),
    };
}

