import type { SVGProps } from "react";
import * as React from "react";
const SvgInsightsDashboard = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.611.223H2.056C.982.223.11 1.093.11 2.167v3.889C.111 7.13.981 8 2.056 8H7.61c1.074 0 1.945-.87 1.945-1.944V2.167c0-1.074-.871-1.944-1.945-1.944ZM15.944 2.445h-2.222c-1.074 0-1.944.87-1.944 1.945v7.222c0 1.074.87 1.944 1.944 1.944h2.222c1.074 0 1.945-.87 1.945-1.944V4.39c0-1.074-.87-1.945-1.945-1.945ZM8.167 9.667h-3.89c-1.073 0-1.944.87-1.944 1.944v2.223c0 1.074.87 1.944 1.945 1.944h3.889c1.074 0 1.944-.87 1.944-1.944V11.61c0-1.074-.87-1.944-1.944-1.944Z"
    />
  </svg>
);
export default SvgInsightsDashboard;
