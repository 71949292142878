import { AccordionStackedListItem } from "@incident-ui/StackedList/AccordionStackedList";
import _ from "lodash";
import React from "react";
import { CatalogType, CustomField } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSortableList } from "../SettingsSortableList";
import { CustomFieldRow } from "./CustomFieldRow";

export const CustomFieldOrderingList = ({
  customFields,
  catalogTypes,
}: {
  customFields: CustomField[];
  catalogTypes: CatalogType[];
}): React.ReactElement => {
  const { trigger: updateRanksTrigger, isMutating: isUpdatingRanks } =
    useAPIMutation("customFieldsList", undefined, (apiClient, data) => {
      const rank_updates = customFields.map((field) => {
        let rank = field.rank;
        const updated = data.find((x) => x.id === field.id);
        if (updated) {
          rank = updated.rank;
        }
        return {
          resource_id: field.id,
          rank,
        };
      });
      return apiClient.customFieldsUpdateRanks({
        updateRanksRequestBody: { rank_updates },
      });
    });

  return (
    <SettingsSortableList
      canEdit={true}
      updateItemRanks={updateRanksTrigger}
      saving={isUpdatingRanks}
      items={_.sortBy(customFields, (field) => field.rank)}
      renderItem={(item) => (
        <CustomFieldOrderingRow
          customField={item}
          catalogTypes={catalogTypes}
        />
      )}
    ></SettingsSortableList>
  );
};

export const CustomFieldOrderingRow = ({
  customField,
  catalogTypes,
}: {
  customField: CustomField;
  catalogTypes: CatalogType[];
}): React.ReactElement => {
  return (
    <AccordionStackedListItem
      key={customField.id}
      state={undefined}
      item={customField}
      hideChevron={(_) => true}
      renderAccordion={(_) => <></>}
      renderRow={(item) => {
        return <CustomFieldRow field={item} catalogTypes={catalogTypes} />;
      }}
      getRowClassName={() => "w-full"}
      rowOnClick={(_) => void 0}
      overrideIsExpanded={false}
      overflowActions={[]}
    />
  );
};
