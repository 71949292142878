import type { SVGProps } from "react";
import * as React from "react";
const SvgTerraform = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.707 7.346v5.048l4.374-2.522V4.818l-4.373 2.528ZM7.855 4.82l4.372 2.527v5.048L7.855 9.869V4.82ZM3 2v5.05l4.374 2.527V4.524L3 2Zm4.855 13.474L12.23 18v-5.052l-4.374-2.524v5.05Z"
    />
  </svg>
);
export default SvgTerraform;
