/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface ExpressionFilterOpts
 */
export interface ExpressionFilterOpts {
    /**
     * The condition groups to apply in this filter. Only one group needs to be satisfied for the filter to pass.
     * @type {Array<ConditionGroup>}
     * @memberof ExpressionFilterOpts
     */
    condition_groups: Array<ConditionGroup>;
}

export function ExpressionFilterOptsFromJSON(json: any): ExpressionFilterOpts {
    return ExpressionFilterOptsFromJSONTyped(json, false);
}

export function ExpressionFilterOptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpressionFilterOpts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
    };
}

export function ExpressionFilterOptsToJSON(value?: ExpressionFilterOpts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
    };
}

