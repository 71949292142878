import type { SVGProps } from "react";
import * as React from "react";
const SvgCalendarPlus = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.25 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75ZM12.75 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75ZM17.75 14H16v-1.75a.75.75 0 0 0-1.5 0V14h-1.75a.75.75 0 0 0 0 1.5h1.75v1.75a.75.75 0 0 0 1.5 0V15.5h1.75a.75.75 0 0 0 0-1.5Z"
    />
    <path
      fill="currentColor"
      d="M14.25 4h-9.5A2.752 2.752 0 0 0 2 6.75v8.5A2.752 2.752 0 0 0 4.75 18h7.75a.75.75 0 0 0 0-1.5H4.75c-.689 0-1.25-.561-1.25-1.25V9h12v.961a.75.75 0 0 0 1.5 0V6.75A2.752 2.752 0 0 0 14.25 4Z"
    />
  </svg>
);
export default SvgCalendarPlus;
