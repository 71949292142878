/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NudgePreviewButton,
    NudgePreviewButtonFromJSON,
    NudgePreviewButtonFromJSONTyped,
    NudgePreviewButtonToJSON,
} from './NudgePreviewButton';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface NudgePreviewDefinition
 */
export interface NudgePreviewDefinition {
    /**
     * The buttons on the message
     * @type {Array<NudgePreviewButton>}
     * @memberof NudgePreviewDefinition
     */
    buttons?: Array<NudgePreviewButton>;
    /**
     * 
     * @type {TextNode}
     * @memberof NudgePreviewDefinition
     */
    message_content?: TextNode;
}

export function NudgePreviewDefinitionFromJSON(json: any): NudgePreviewDefinition {
    return NudgePreviewDefinitionFromJSONTyped(json, false);
}

export function NudgePreviewDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NudgePreviewDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buttons': !exists(json, 'buttons') ? undefined : ((json['buttons'] as Array<any>).map(NudgePreviewButtonFromJSON)),
        'message_content': !exists(json, 'message_content') ? undefined : TextNodeFromJSON(json['message_content']),
    };
}

export function NudgePreviewDefinitionToJSON(value?: NudgePreviewDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buttons': value.buttons === undefined ? undefined : ((value.buttons as Array<any>).map(NudgePreviewButtonToJSON)),
        'message_content': TextNodeToJSON(value.message_content),
    };
}

