import { Callout, CalloutTheme } from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceSNSSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <div>
        <p>
          Go to the <strong>AWS</strong> console and open <strong>SNS</strong>
        </p>
        <Callout theme={CalloutTheme.Info} className={"my-4"}>
          If you&rsquo;re looking to send messages from{" "}
          <strong>CloudWatch</strong>, we have a CloudWatch specific alert
          source. Click the back button and select the CloudWatch source
          instead.
        </Callout>
      </div>
      <p>
        Choose <strong>Topics</strong> and click <strong>Create topic</strong>
      </p>
      <p>
        Select <strong>Standard</strong> as the queue type and enter a{" "}
        <strong>name</strong>. We&apos;d recommend naming it match this alert
        source, e.g. &quot;{alertSourceConfig.name} incident.io&quot;.
        <Callout theme={CalloutTheme.Info} className={"my-4"}>
          If you&rsquo;re using AWS Managed Grafana you&rsquo;ll need to set the
          contact point format to <strong>JSON</strong>, we&rsquo;ll
          automatically parse the payload.
        </Callout>
      </p>
      <p>
        Leave all the remaining fields as their defaults and click{" "}
        <strong>Create topic</strong>.
      </p>
      <p>
        Select your newly created topic and click{" "}
        <strong>Create subscription</strong>.
      </p>
      <div className="overflow-hidden">
        <p>Enter the following values:</p>

        <SetupInfoNumberedList>
          <p>
            <strong>Topic ARN</strong>: Leave this as the default, the topic you
            just created.
          </p>
          <p>
            <strong>Protocol</strong>: HTTPS
          </p>
          <div className="overflow-hidden">
            <strong>Endpoint</strong>: Copy the endpoint below
            <SingleLineCodeBlock
              className={"my-2"}
              title={"Endpoint"}
              code={`${publicApiUrl()}/v2/alert_events/sns/${
                alertSourceConfig.id
              }`}
            />
          </div>
          <p>
            <strong>Raw message delivery</strong>: Leave this unchecked
          </p>
        </SetupInfoNumberedList>
      </div>

      <p>
        Save your subscription and check that the status is{" "}
        <strong>Confirmed</strong>
      </p>
      <p>You can now use your SNS topic to send alerts to incident.io.</p>
    </SetupInfoNumberedList>
  );
};
