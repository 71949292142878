/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelecomTwilioAnsweringMachineCallbackRequestBody
 */
export interface TelecomTwilioAnsweringMachineCallbackRequestBody {
    /**
     * Who picked up the phone
     * @type {string}
     * @memberof TelecomTwilioAnsweringMachineCallbackRequestBody
     */
    AnsweredBy: TelecomTwilioAnsweringMachineCallbackRequestBodyAnsweredByEnum;
    /**
     * Twilio's unique ID of the call
     * @type {string}
     * @memberof TelecomTwilioAnsweringMachineCallbackRequestBody
     */
    CallSid: string;
    /**
     * The time to detect who made the call, in milliseconds
     * @type {string}
     * @memberof TelecomTwilioAnsweringMachineCallbackRequestBody
     */
    MachineDetectionDuration: string;
}

/**
* @export
* @enum {string}
*/
export enum TelecomTwilioAnsweringMachineCallbackRequestBodyAnsweredByEnum {
    MachineStart = 'machine_start',
    Fax = 'fax',
    Human = 'human',
    Unknown = 'unknown'
}

export function TelecomTwilioAnsweringMachineCallbackRequestBodyFromJSON(json: any): TelecomTwilioAnsweringMachineCallbackRequestBody {
    return TelecomTwilioAnsweringMachineCallbackRequestBodyFromJSONTyped(json, false);
}

export function TelecomTwilioAnsweringMachineCallbackRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelecomTwilioAnsweringMachineCallbackRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AnsweredBy': json['AnsweredBy'],
        'CallSid': json['CallSid'],
        'MachineDetectionDuration': json['MachineDetectionDuration'],
    };
}

export function TelecomTwilioAnsweringMachineCallbackRequestBodyToJSON(value?: TelecomTwilioAnsweringMachineCallbackRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AnsweredBy': value.AnsweredBy,
        'CallSid': value.CallSid,
        'MachineDetectionDuration': value.MachineDetectionDuration,
    };
}

