import React from "react";
import { Routes } from "react-router";
import { Route } from "react-router-dom";

import { WebhookViewPage } from "./WebhookViewPage";

export const WebhooksRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<WebhookViewPage />} />
    </Routes>
  );
};
