import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFormContext } from "react-hook-form";

import { ActiveIncidentInput } from "./ActiveIncidentInput";
import { IncidentStatusFormData } from "./StatusFormElement";

export enum ActiveIncidentDecision {
  Stay = "stay",
  Resolved = "resolved",
  Merged = "merged",
  Paused = "paused",
}

export const ActiveIncidentDecisionRadio = ({
  selectedDecision,
  incidentId,
}: {
  selectedDecision?: ActiveIncidentDecision;
  incidentId?: string;
}) => {
  const formMethods = useFormContext<IncidentStatusFormData>();

  const { featureMergeActiveIncidents } = useFlags();

  const selectOptions: RadioButtonGroupOption[] = [
    {
      label: "Active - it's still ongoing",
      value: ActiveIncidentDecision.Stay,
    },
    {
      label: "Paused - it's temporarily on hold",
      value: ActiveIncidentDecision.Paused,
    },
    {
      label: "Resolved - it's no longer a problem",
      value: ActiveIncidentDecision.Resolved,
    },
  ];

  if (featureMergeActiveIncidents) {
    selectOptions.push({
      label: "Merged - this is part of another incident",
      value: ActiveIncidentDecision.Merged,
      renderWhenSelectedNode: () =>
        incidentId && (
          <ActiveIncidentInput
            incidentId={incidentId}
            formMethods={formMethods}
          />
        ),
    });
  }

  return (
    <div>
      <RadioButtonGroupV2
        formMethods={formMethods}
        label="Which category best describes the current state?"
        srLabel="Select category"
        options={selectOptions}
        name="incident_status_decision"
        boxed
      />
      {selectedDecision === ActiveIncidentDecision.Resolved && (
        <p className="mt-1 text-xs text-slate-700">
          {`ℹ️ We won't mark this incident as resolved until you click 'Next' and provide the required information.`}
        </p>
      )}
    </div>
  );
};
