/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulesCreateFeedRequestBody
 */
export interface SchedulesCreateFeedRequestBody {
    /**
     * Which type of feed are we creating
     * @type {string}
     * @memberof SchedulesCreateFeedRequestBody
     */
    feed_type: SchedulesCreateFeedRequestBodyFeedTypeEnum;
    /**
     * Whether to include all users for the given schedules
     * @type {boolean}
     * @memberof SchedulesCreateFeedRequestBody
     */
    include_all_users?: boolean;
    /**
     * Which schedules are we creating a feed for, if the feed is filtered
     * @type {Array<string>}
     * @memberof SchedulesCreateFeedRequestBody
     */
    schedule_ids?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum SchedulesCreateFeedRequestBodyFeedTypeEnum {
    All = 'all',
    Filtered = 'filtered'
}

export function SchedulesCreateFeedRequestBodyFromJSON(json: any): SchedulesCreateFeedRequestBody {
    return SchedulesCreateFeedRequestBodyFromJSONTyped(json, false);
}

export function SchedulesCreateFeedRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesCreateFeedRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feed_type': json['feed_type'],
        'include_all_users': !exists(json, 'include_all_users') ? undefined : json['include_all_users'],
        'schedule_ids': !exists(json, 'schedule_ids') ? undefined : json['schedule_ids'],
    };
}

export function SchedulesCreateFeedRequestBodyToJSON(value?: SchedulesCreateFeedRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feed_type': value.feed_type,
        'include_all_users': value.include_all_users,
        'schedule_ids': value.schedule_ids,
    };
}

