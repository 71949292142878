import type { SVGProps } from "react";
import * as React from "react";
const SvgTShirt = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18.44 8.71-1.604-3.813a2.759 2.759 0 0 0-1.904-1.61l-2.263-.533a.75.75 0 0 0-.92.791 2.002 2.002 0 0 1-2 1.967A2.002 2.002 0 0 1 7.75 3.484a.75.75 0 0 0-.922-.73l-2.263.533a2.759 2.759 0 0 0-1.904 1.61L1.059 8.71a.75.75 0 0 0 .396.98l2.339 1.01a53.769 53.769 0 0 0-.041-2.044.625.625 0 1 1 1.249-.057c0 .025.113 2.56-.033 5.757 0 .005-.08 1.596-.215 2.812A.75.75 0 0 0 5.5 18H14a.75.75 0 0 0 .746-.833 53.439 53.439 0 0 1-.215-2.812c-.146-3.199-.034-5.732-.033-5.757a.625.625 0 1 1 1.249.057c0 .014-.036.801-.041 2.044l2.339-1.01a.75.75 0 0 0 .396-.98Z"
    />
  </svg>
);
export default SvgTShirt;
