import type { SVGProps } from "react";
import * as React from "react";
const SvgBacklinkSmall = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.238 7.827a3.501 3.501 0 0 0-.973.69l-.01.01a3.535 3.535 0 0 0 0 5l2.175 2.175a3.535 3.535 0 0 0 5 0l.01-.01a3.535 3.535 0 0 0 0-5l-.931-.931m-4.271 2.412a3.5 3.5 0 0 0 .973-.69l.01-.01a3.535 3.535 0 0 0 0-5L9.046 4.298a3.535 3.535 0 0 0-5 0l-.01.01a3.535 3.535 0 0 0 0 5l.93.931"
    />
  </svg>
);
export default SvgBacklinkSmall;
