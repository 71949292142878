import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { ErrorResponse, IntegrationSettings } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

type Return = {
  integrations: IntegrationSettings[] | null;
  installedIntegrationsCount: number | null;
  integrationsLoading: boolean;
  refetchIntegrations: () => Promise<void>;
  integrationsError?: ErrorResponse;
  noMoreIntegrationsInPlan: boolean;
};

export const useIntegrations = (): Return => {
  const { identity } = useIdentity();
  const { data, isLoading, mutate, error } = useAPI(
    "integrationsList",
    undefined,
  );

  const noMoreIntegrationsInPlan = numericGateLimitReached(
    identity.feature_gates.integrations_count,
    data === undefined ? 0 : data.installed_integrations_count,
  );

  return {
    integrations: data === undefined ? null : data.integrations,
    installedIntegrationsCount:
      data === undefined ? null : data.installed_integrations_count,
    integrationsLoading: isLoading,
    integrationsError: error,
    refetchIntegrations: async () => {
      await mutate();
    },
    noMoreIntegrationsInPlan,
  };
};
