import { Product } from "@incident-shared/billing";
import { DurationMetricCreateEditModal } from "@incident-shared/durations/DurationMetricCreateEditModal";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, GenericErrorMessage, IconEnum } from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import React, { useState } from "react";
import {
  IncidentDurationMetric,
  IncidentTimestamp,
  ScopeNameEnum,
} from "src/contexts/ClientContext";

import { SettingsSubHeading } from "../../SettingsSubHeading";
import { DurationMetricsList } from "./DurationMetricsList";

export const DurationMetricsPageSection = ({
  timestamps,
  metrics,
}: {
  timestamps: IncidentTimestamp[];
  metrics: IncidentDurationMetric[];
}): React.ReactElement => {
  const [showAddMetricModal, setShowAddMetricModal] = useState<boolean>(false);
  const [editingMetric, setEditingMetric] =
    useState<IncidentDurationMetric | null>(null);

  return (
    <div>
      <ErrorBoundary fallback={<GenericErrorMessage />}>
        <SettingsSubHeading
          title="Duration metrics"
          explanation={`Duration metrics track the difference between two timestamps. This provides a convenient way to understand how long certain phases of the incident lasted: for example how long it took the team to mitigate the customer impact.`}
          className="!mb-4"
          accessory={
            <GatedButton
              onClick={() => setShowAddMetricModal(true)}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
              requiredProduct={Product.Response}
              analyticsTrackingId="add-duration-metric"
              icon={IconEnum.Add}
              theme={ButtonTheme.Secondary}
            >
              Add new metric
            </GatedButton>
          }
        />
        <DurationMetricsList
          metrics={metrics}
          timestamps={timestamps}
          onEdit={setEditingMetric}
        />
        {showAddMetricModal && (
          <DurationMetricCreateEditModal
            mode={Mode.Create}
            onClose={() => setShowAddMetricModal(false)}
            timestamps={timestamps}
          />
        )}
        {editingMetric && (
          <DurationMetricCreateEditModal
            onClose={() => setEditingMetric(null)}
            initialData={editingMetric}
            mode={Mode.Edit}
            timestamps={timestamps}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};
