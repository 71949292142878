import type { SVGProps } from "react";
import * as React from "react";
const SvgDottedCircle = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.873 15.877a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM2.75 10.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM4.873 5.623a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM7.226 17.448a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM3.302 13.524a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM3.302 7.976a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM7.226 4.052a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10 3.5A.75.75 0 1 0 10 2a.75.75 0 0 0 0 1.5ZM10 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15.127 15.877a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM17.25 10.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15.127 5.623a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.774 17.448a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM16.698 13.524a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM16.698 7.976a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.774 4.052a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
    />
  </svg>
);
export default SvgDottedCircle;
