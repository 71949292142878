/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferencePayload,
    ReferencePayloadFromJSON,
    ReferencePayloadFromJSONTyped,
    ReferencePayloadToJSON,
} from './ReferencePayload';

/**
 * 
 * @export
 * @interface EngineBuildScopeRequestBody
 */
export interface EngineBuildScopeRequestBody {
    /**
     * List of engine references to expand
     * @type {Array<ReferencePayload>}
     * @memberof EngineBuildScopeRequestBody
     */
    scope: Array<ReferencePayload>;
}

export function EngineBuildScopeRequestBodyFromJSON(json: any): EngineBuildScopeRequestBody {
    return EngineBuildScopeRequestBodyFromJSONTyped(json, false);
}

export function EngineBuildScopeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineBuildScopeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scope': ((json['scope'] as Array<any>).map(ReferencePayloadFromJSON)),
    };
}

export function EngineBuildScopeRequestBodyToJSON(value?: EngineBuildScopeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scope': ((value.scope as Array<any>).map(ReferencePayloadToJSON)),
    };
}

