/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JiraIssueUnexpandedFields,
    JiraIssueUnexpandedFieldsFromJSON,
    JiraIssueUnexpandedFieldsFromJSONTyped,
    JiraIssueUnexpandedFieldsToJSON,
} from './JiraIssueUnexpandedFields';

/**
 * 
 * @export
 * @interface JiraIssue
 */
export interface JiraIssue {
    /**
     * 
     * @type {JiraIssueUnexpandedFields}
     * @memberof JiraIssue
     */
    fields?: JiraIssueUnexpandedFields;
    /**
     * Unique identifier for the jira issue
     * @type {string}
     * @memberof JiraIssue
     */
    id?: string;
    /**
     * Reference for the jira issue
     * @type {string}
     * @memberof JiraIssue
     */
    key?: string;
    /**
     * URL to the jira issue
     * @type {string}
     * @memberof JiraIssue
     */
    self?: string;
}

export function JiraIssueFromJSON(json: any): JiraIssue {
    return JiraIssueFromJSONTyped(json, false);
}

export function JiraIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraIssue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : JiraIssueUnexpandedFieldsFromJSON(json['fields']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'self': !exists(json, 'self') ? undefined : json['self'],
    };
}

export function JiraIssueToJSON(value?: JiraIssue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': JiraIssueUnexpandedFieldsToJSON(value.fields),
        'id': value.id,
        'key': value.key,
        'self': value.self,
    };
}

