import { Route, Routes } from "react-router";
import { IncidentTypesListPage } from "src/components/settings/incident-types/view/IncidentTypesListPage";

import { IncidentTypesCreatePage } from "./create/IncidentTypesCreatePage";
import { IncidentTypesEditPage } from "./edit/IncidentTypesEditPage";

export const IncidentTypesRoute = () => {
  return (
    <Routes>
      <Route path="create" element={<IncidentTypesCreatePage />} />
      <Route path=":id/edit" element={<IncidentTypesEditPage />} />
      <Route path="*" element={<IncidentTypesListPage />} />
    </Routes>
  );
};
