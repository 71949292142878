import {
  GenericErrorMessage,
  InlineErrorMessage,
  LoadingModal,
  ModalFooter,
  Steps,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { StepConfig } from "@incident-ui/Steps/Steps";
import _ from "lodash";
import { sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useFormContext, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  CatalogTypeSuggestion,
  CustomFieldsConvertToExistingCatalogTypeRequestBodyMismatchStrategyEnum as MismatchStrategyEnum,
} from "src/contexts/ClientContext";
import { CustomField } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

import {
  CustomFieldFormData,
  getDefaultValues,
} from "../create-edit/CustomFieldCreateEditDrawer";
import { StepChooseCatalogType } from "./StepChooseCatalogType";
import { StepDefineMapping } from "./StepDefineMapping";

export type ConvertToCatalogFormType = {
  custom_field_id: string;
  catalog_type_id: string;
  matches: { [key: string]: SelectOption };
  mismatch_strategy: MismatchStrategyEnum;
};

export const ConvertCustomFieldToCatalogModal = ({
  customField,
  onClose,
}: {
  customField: CustomField;
  onClose: () => void;
}): React.ReactElement => {
  const customFieldId = customField.id;

  const [currentStep, setStep] = useState(Step.ChooseCatalogType);

  // Get catalog resources, used to type our attributes.
  const {
    data: { catalog_type_suggestions },
    isLoading,
    error,
  } = useAPI(
    "customFieldsCatalogTypeSuggestions",
    { id: customFieldId },
    {
      fallbackData: { catalog_type_suggestions: [] },
    },
  );

  const catalogTypeSuggestions = useMemo(
    () => sortBy(catalog_type_suggestions, "score").reverse(),
    [catalog_type_suggestions],
  );

  // We want to reset this when we succeed submission of the inner form.
  const outerFormMethods = useFormContext<CustomFieldFormData>();

  const formMethods = useForm<ConvertToCatalogFormType>({
    defaultValues: {
      custom_field_id: customFieldId,
      mismatch_strategy: MismatchStrategyEnum.ArchiveUnmatched,
    },
  });

  const { trigger: onSubmit, isMutating: isMutating } = useAPIMutation(
    "customFieldsShow",
    { id: customFieldId },
    async (apiClient, data: ConvertToCatalogFormType) => {
      if (currentStep === Step.ChooseCatalogType) {
        if (data.catalog_type_id) {
          // We have a catalog type id, so we can move on to the next step.
          setStep(Step.ReviewMapping);
        } else {
          formMethods.setError(
            "catalog_type_id",
            { message: "Please select a catalog type", type: "required" },
            { shouldFocus: true },
          );
        }
        return Promise.resolve();
      }

      return await apiClient
        .customFieldsConvertToExistingCatalogType({
          id: customFieldId,
          convertToExistingCatalogTypeRequestBody: {
            catalog_type_id: data.catalog_type_id,
            mismatch_strategy: data.mismatch_strategy,
            matches: Object.entries(data.matches).map(([option_id, entry]) => ({
              custom_field_option_id: option_id,
              catalog_entry_id: entry.value,
            })),
          },
        })
        .then(() => {
          onClose();
        });
    },
    {
      onSuccess: (data) => {
        if (currentStep === Step.ReviewMapping) {
          // We're done!
          // The migration should be complete.
          //
          // Since we render inside the custom field edit form,
          // we must also reset the outer form so that it
          // re-renders otherwise, the user will see the old data.
          outerFormMethods.reset(getDefaultValues(data.custom_field));
        }
      },
      setError: formMethods.setError,
    },
  );

  const catalogTypeSuggestionsWithScoreGreaterThan0 = useMemo(() => {
    return catalogTypeSuggestions.filter((sug) => sug.score > 0);
  }, [catalogTypeSuggestions]);

  // If there's only one suggestion, pre-select it for the user
  useEffect(() => {
    if (catalogTypeSuggestionsWithScoreGreaterThan0.length === 1) {
      formMethods.setValue(
        "catalog_type_id",
        catalogTypeSuggestionsWithScoreGreaterThan0[0].catalog_type.id,
      );
    }
  }, [catalogTypeSuggestionsWithScoreGreaterThan0, formMethods]);

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Convert to catalog type"
      analyticsTrackingId="convert-to-catalog-type"
      onClose={onClose}
      onSubmit={onSubmit}
      isExtraLarge
      saving={isMutating}
      loading={isMutating}
      footer={
        <ModalFooter
          disabled={isMutating}
          saving={isMutating}
          onClose={() => {
            switch (currentStep) {
              case Step.ChooseCatalogType:
                return onClose();
              case Step.ReviewMapping:
                return setStep(Step.ChooseCatalogType);
              default:
                return assertUnreachable(currentStep);
            }
          }}
          cancelButtonText={
            currentStep === Step.ChooseCatalogType ? "Cancel" : "Back"
          }
          confirmButtonType="submit"
          confirmButtonText={
            currentStep === Step.ReviewMapping ? "Convert" : "Continue"
          }
        />
      }
    >
      <Steps steps={stepConfig} currentStep={currentStep} />
      <ConvertToCatalogModalInner
        step={currentStep}
        allCatalogTypes={catalog_type_suggestions}
        suggestions={catalogTypeSuggestionsWithScoreGreaterThan0}
        formMethods={formMethods}
      />
    </Form.Modal>
  );
};

enum Step {
  // Start state
  // Step 1: Select catalog type you want to convert this custom filed to
  ChooseCatalogType = "choose-catalog-type",
  // End state
  // Step 2: Map each custom field option to a catalog entry and pick a strategy for unmatched options
  // and perform migration.
  ReviewMapping = "define-mapping",
}

const stepConfig: StepConfig<Step>[] = [
  {
    id: Step.ChooseCatalogType,
    name: "Choose catalog type",
  },
  {
    id: Step.ReviewMapping,
    name: "Review mappings",
  },
];

const ConvertToCatalogModalInner = ({
  step,
  allCatalogTypes,
  suggestions,
  formMethods,
}: {
  step: Step;
  allCatalogTypes: CatalogTypeSuggestion[];
  suggestions: CatalogTypeSuggestion[];
  formMethods: UseFormReturn<ConvertToCatalogFormType>;
}): React.ReactElement => {
  const [catalogTypeId, customFieldId] = formMethods.watch([
    "catalog_type_id",
    "custom_field_id",
  ]);

  // selected catalog type
  const selectedCatalogType = useMemo(
    () => _.find(allCatalogTypes, (ct) => ct.catalog_type.id === catalogTypeId),
    [allCatalogTypes, catalogTypeId],
  );

  return (
    <>
      {{
        [Step.ChooseCatalogType]: () => {
          return (
            <StepChooseCatalogType
              allCatalogTypes={allCatalogTypes}
              suggestions={suggestions}
              formMethods={formMethods}
            />
          );
        },
        [Step.ReviewMapping]: () => {
          if (!selectedCatalogType) {
            return (
              <InlineErrorMessage description="Catalog type not available!" />
            );
          }
          return (
            <StepDefineMapping
              customFieldId={customFieldId}
              catalogType={selectedCatalogType.catalog_type}
              formMethods={formMethods}
            />
          );
        },
      }[step]()}
    </>
  );
};
