import {
  Avatar,
  Checkbox,
  Icon,
  IconEnum,
  IconSize,
  OrgAwareLink,
  SeverityBadge,
  SlackTeamAvatar,
  Tooltip,
} from "@incident-ui";
import {
  Incident,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  SlackTeamConfig,
} from "src/contexts/ClientContext";

export const FollowUpsTableHeader = ({
  incident,
  slackTeam,
  selectedFollowUpIDs,
  setSelectedFollowUpIDs,
  colSpan,
}: {
  incident: Incident;
  slackTeam: SlackTeamConfig | undefined;
  selectedFollowUpIDs?: string[];
  setSelectedFollowUpIDs?: (ids: string[]) => void;
  colSpan: number;
}): React.ReactElement => {
  const leadRole = incident.incident_role_assignments.find(
    ({ role }) => role.role_type === RoleTypeEnum.Lead,
  );

  const followUps = incident.follow_ups || [];
  const selectable = !!(setSelectedFollowUpIDs && selectedFollowUpIDs);

  return (
    <thead className={`border-b-2 border-stroke bg-surface-secondary`}>
      <tr>
        {setSelectedFollowUpIDs && selectedFollowUpIDs && (
          <th>
            <div className="flex items-center">
              <Checkbox
                id={incident.id}
                onChange={(boolval) => {
                  if (boolval.target.checked) {
                    // If we've just checked it, add any follow-up ID that isn't already in the list.
                    const addFollowUps = followUps.filter((followUp) => {
                      return !selectedFollowUpIDs.includes(followUp.id);
                    });
                    setSelectedFollowUpIDs([
                      ...selectedFollowUpIDs,
                      ...addFollowUps.map((followUp) => followUp.id),
                    ]);
                  } else {
                    // Otherwise, remove any follow-up ID that is in the list.
                    const updatedFollowUpIDs = selectedFollowUpIDs.filter(
                      (id) => !followUps.map((x) => x.id).includes(id),
                    );
                    setSelectedFollowUpIDs(updatedFollowUpIDs);
                  }
                }}
                checked={followUps.every((followUp) =>
                  selectedFollowUpIDs.includes(followUp.id),
                )}
              />
            </div>
          </th>
        )}
        <th colSpan={colSpan} className={`!py-3 ${selectable && "!pl-0"}`}>
          <div className="flex flex-between space-x-4">
            <div className="flex items-center">
              <OrgAwareLink
                to={`/incidents/${incident.external_id}?tab=follow-ups`}
              >
                <p className="text-slate-800 font-normal mr-2 hover:underline">
                  <span className="font-semibold shrink-0">
                    INC-{incident.external_id}
                  </span>{" "}
                  {incident.name}
                </p>
              </OrgAwareLink>
              <SeverityBadge severity={incident.severity} />
              <span className="flex-center-y text-content-tertiary font-normal ml-1">
                <Icon
                  className="text-content-tertiary mr-1"
                  id={IconEnum.Calendar}
                />{" "}
                {incident.reported_at.toLocaleDateString()}
              </span>
            </div>
            <div className="shrink-0 flex space-x-2 items-center mr-1 text-content-tertiary font-normal">
              {slackTeam && (
                <>
                  <Tooltip content={slackTeam.name}>
                    <SlackTeamAvatar
                      size={IconSize.Medium}
                      url={slackTeam.icon_url}
                      name={slackTeam.name}
                    />
                  </Tooltip>
                  <p className="ml-1 text-slate-300">|</p>
                </>
              )}

              <span>{leadRole?.role.name}</span>
              <div className="flex justify-center">
                <Tooltip
                  content={<p>{leadRole?.assignee?.name || "Not assigned"}</p>}
                >
                  <div>
                    <Avatar
                      size={IconSize.Large}
                      url={leadRole?.assignee?.avatar_url}
                      name={leadRole?.assignee?.name}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
  );
};
