/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentAutoSubscribeRule,
    IncidentAutoSubscribeRuleFromJSON,
    IncidentAutoSubscribeRuleFromJSONTyped,
    IncidentAutoSubscribeRuleToJSON,
} from './IncidentAutoSubscribeRule';

/**
 * 
 * @export
 * @interface IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody
 */
export interface IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody {
    /**
     * 
     * @type {IncidentAutoSubscribeRule}
     * @memberof IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody
     */
    incident_auto_subscribe_rule: IncidentAutoSubscribeRule;
}

export function IncidentSubscriptionsCreateAutoSubscribeRuleResponseBodyFromJSON(json: any): IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody {
    return IncidentSubscriptionsCreateAutoSubscribeRuleResponseBodyFromJSONTyped(json, false);
}

export function IncidentSubscriptionsCreateAutoSubscribeRuleResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_auto_subscribe_rule': IncidentAutoSubscribeRuleFromJSON(json['incident_auto_subscribe_rule']),
    };
}

export function IncidentSubscriptionsCreateAutoSubscribeRuleResponseBodyToJSON(value?: IncidentSubscriptionsCreateAutoSubscribeRuleResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_auto_subscribe_rule': IncidentAutoSubscribeRuleToJSON(value.incident_auto_subscribe_rule),
    };
}

