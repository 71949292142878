import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import PropTypes from "prop-types";
import React, { ComponentType } from "react";
import { UseFieldArrayUpdate, useFormContext } from "react-hook-form";
import { AttributeEntries } from "src/components/@shared/attribute/AttributeEntries";
import {
  EngineParamBinding,
  EngineScope,
  ExpressionOperationOperationTypeEnum as OperationType,
  Resource,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CreateEditExpressionFormData } from "../AddEditExpressionModal";
import { FilterConditionEdit } from "./operations/FilterConditionEdit";
import { NavigateTargetSelect } from "./operations/NavigateTargetSelect";
import { ParseTargetForm } from "./operations/ParseTargetForm";
import { QueryPreviewWindow } from "./QueryPreview";
import {
  ReferenceWithResource,
  useGetPreviousReference,
} from "./useGetPreviousReference";

export const ComponentForOperation = ({
  operationType,
  operationIdx,
  operationOption,
  scope,
  resources,
  update,
  isEditable,
  isFinalOperation,
  removeOperation,
  exampleEnabled,
  exampleInput,
  exampleResult,
}: {
  operationType: OperationType;
  operationIdx: number;
  operationOption: SelectOption;
  scope: EngineScope;
  resources: Resource[];
  update: UseFieldArrayUpdate<CreateEditExpressionFormData, "operations">;
  isEditable: boolean;
  isFinalOperation: boolean;
  removeOperation: () => void;
  exampleEnabled: boolean;
  exampleInput?: EngineParamBinding;
  exampleResult?: EngineParamBinding;
}): React.ReactElement | null => {
  const formMethods = useFormContext<CreateEditExpressionFormData>();

  const getPreviousReference = useGetPreviousReference({
    scope,
    resources,
    formMethods,
  });
  const previousRef = getPreviousReference(operationIdx);

  const Wrapper: ComponentType<{
    children?: React.ReactNode;
    exampleRender: (result: EngineParamBinding) => React.ReactNode;
  }> = ({ children, exampleRender }) => (
    <OperationWrapper
      operationOption={operationOption}
      isFinalOperation={isFinalOperation}
      removeOperation={removeOperation}
      exampleEnabled={exampleEnabled}
      exampleResult={exampleResult}
      exampleRender={exampleRender}
    >
      {children}
    </OperationWrapper>
  );
  Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    exampleRender: PropTypes.func.isRequired,
  };

  const { data: resourcesData } = useAPI("catalogListResources", undefined);

  if (!previousRef) {
    return null;
  }

  switch (operationType) {
    case OperationType.Count:
      return (
        <Wrapper
          exampleRender={(result: EngineParamBinding) => {
            return resourcesData?.resources ? (
              <AttributeEntries
                clickable={false}
                mode={"catalog"}
                typeName={`Number`}
                catalogResources={resourcesData.resources}
                attributeBinding={result}
                truncate
              />
            ) : null;
          }}
        />
      );
    case OperationType.Max:
    case OperationType.Sum:
    case OperationType.Min:
    case OperationType.Random:
    case OperationType.First:
      return (
        <Wrapper
          exampleRender={(result: EngineParamBinding) => {
            return resourcesData?.resources ? (
              <AttributeEntries
                clickable={false}
                mode={"engine"}
                typeName={previousRef.type}
                catalogResources={resourcesData.resources}
                attributeBinding={result}
                truncate
              />
            ) : null;
          }}
        />
      );

    case OperationType.Navigate:
      return (
        <Wrapper
          exampleRender={(result: EngineParamBinding) => {
            return resourcesData?.resources ? (
              <AttributeEntries
                clickable={false}
                mode={"engine"}
                typeName={getPreviousReference(operationIdx + 1)?.type ?? ""}
                catalogResources={resourcesData.resources}
                attributeBinding={result}
                truncate
              />
            ) : null;
          }}
        >
          <NavigateTargetSelect
            operationIdx={operationIdx}
            previousRef={previousRef}
            isEditable={isEditable}
            resources={resources}
          />
        </Wrapper>
      );

    case OperationType.Filter:
      return (
        <Wrapper
          exampleRender={(result: EngineParamBinding) => {
            return resourcesData?.resources ? (
              <AttributeEntries
                clickable={false}
                mode={"engine"}
                typeName={previousRef.type}
                catalogResources={resourcesData.resources}
                attributeBinding={result}
                truncate
              />
            ) : null;
          }}
        >
          <FilterConditionEdit
            scope={scope}
            resources={resources}
            operationIdx={operationIdx}
            previousRef={previousRef}
            isEditable={isEditable}
          />
        </Wrapper>
      );

    case OperationType.Parse:
      return (
        <ParseTargetForm
          update={update}
          operationIdx={operationIdx}
          operationOption={operationOption}
          isEditable={isEditable}
          isFinalOperation={isFinalOperation}
          removeOperation={removeOperation}
          exampleInput={exampleInput}
          exampleEnabled={exampleEnabled}
          exampleResult={exampleResult}
        />
      );

    default:
      return <></>;
  }
};

// Most operations use very similar styling, but some – like parse – need more
// customisation.
//
// This component applies default styling, and should be used to wrap all but
// exceptional operations.
export const OperationWrapper = ({
  operationOption,
  isFinalOperation,
  removeOperation,
  children,
  exampleEnabled,
  exampleResult,
  exampleRender,
}: {
  operationOption: SelectOption;
  isFinalOperation: boolean;
  removeOperation: () => void;
  children: React.ReactNode;
  exampleEnabled: boolean;
  exampleResult?: EngineParamBinding;
  exampleRender: (result: EngineParamBinding) => React.ReactNode;
}) => {
  return (
    <li>
      <div className={tcx("flex", !exampleEnabled && "flex-between space-x-4")}>
        <div
          className={tcx(
            `p-4 space-y-2 min-w-[434px]`,
            exampleEnabled && `w-[434px]`,
          )}
        >
          <div className="flex-center-y">
            {operationOption.iconComponent ? (
              operationOption.iconComponent
            ) : operationOption.icon ? (
              <Icon id={operationOption.icon} className="mr-1" />
            ) : null}
            <div className="text-sm">
              <span className="font-semibold">Then </span>
              {operationOption.description}
            </div>
          </div>
          {children}
          {isFinalOperation && exampleEnabled ? (
            <div className={"mt-6"}>
              <Button
                analyticsTrackingId={null}
                title="Remove operation"
                icon={IconEnum.Delete2}
                iconProps={{ size: IconSize.Medium }}
                onClick={() => removeOperation()}
                theme={ButtonTheme.Naked}
              >
                Remove operation
              </Button>
            </div>
          ) : null}
        </div>
        {isFinalOperation && !exampleEnabled ? (
          <Button
            className={"pr-4"}
            analyticsTrackingId={null}
            title="Remove operation"
            icon={IconEnum.Delete2}
            onClick={() => removeOperation()}
            theme={ButtonTheme.Naked}
          />
        ) : null}
        {exampleEnabled ? (
          <QueryPreviewWindow>
            {exampleResult ? exampleRender(exampleResult) : null}
          </QueryPreviewWindow>
        ) : null}
      </div>
    </li>
  );
};

export const useGetScope = (
  ref: ReferenceWithResource,
): {
  scope: EngineScope;
  isLoading: boolean;
} => {
  // Get scope and resources to power an upcoming filter operation.
  const {
    data: resp,
    isLoading,
    error: scopeError,
  } = useAPI(
    ref.resource ? "engineBuildScope" : null,
    {
      buildScopeRequestBody: {
        scope: [
          {
            key: "input",
            label: ref.label,
            type: ref.type || "",
            array: ref.array,
          },
        ],
      },
    },
    { fallbackData: { scope: { references: [], aliases: {} }, resources: [] } },
  );

  if (scopeError) {
    throw scopeError;
  }

  return {
    scope: resp.scope,
    isLoading,
  };
};
