/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ExternalGoogleCalendarEventAttendee
 */
export interface ExternalGoogleCalendarEventAttendee {
    /**
     * Email of the attendee
     * @type {string}
     * @memberof ExternalGoogleCalendarEventAttendee
     */
    email: string;
    /**
     * Whether or not the attendee is optional
     * @type {boolean}
     * @memberof ExternalGoogleCalendarEventAttendee
     */
    is_optional: boolean;
    /**
     * Status of the attendee
     * @type {string}
     * @memberof ExternalGoogleCalendarEventAttendee
     */
    response: ExternalGoogleCalendarEventAttendeeResponseEnum;
    /**
     * 
     * @type {User}
     * @memberof ExternalGoogleCalendarEventAttendee
     */
    user?: User;
}

/**
* @export
* @enum {string}
*/
export enum ExternalGoogleCalendarEventAttendeeResponseEnum {
    None = 'none',
    Yes = 'yes',
    No = 'no',
    Maybe = 'maybe'
}

export function ExternalGoogleCalendarEventAttendeeFromJSON(json: any): ExternalGoogleCalendarEventAttendee {
    return ExternalGoogleCalendarEventAttendeeFromJSONTyped(json, false);
}

export function ExternalGoogleCalendarEventAttendeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalGoogleCalendarEventAttendee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'is_optional': json['is_optional'],
        'response': json['response'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function ExternalGoogleCalendarEventAttendeeToJSON(value?: ExternalGoogleCalendarEventAttendee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'is_optional': value.is_optional,
        'response': value.response,
        'user': UserToJSON(value.user),
    };
}

