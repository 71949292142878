import type { SVGProps } from "react";
import * as React from "react";
const SvgNestedInfo = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16 11.5H5.75A2.752 2.752 0 0 1 3 8.75v-4a.75.75 0 0 1 1.5 0v4c0 .689.561 1.25 1.25 1.25H16a.75.75 0 0 1 0 1.5Z"
    />
    <path
      fill="currentColor"
      d="M12 15.75a.75.75 0 0 1-.53-1.281l3.72-3.72-3.72-3.72a.75.75 0 1 1 1.061-1.061l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.748.748 0 0 1-.53.22L12 15.75Z"
    />
  </svg>
);
export default SvgNestedInfo;
