/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';

/**
 * 
 * @export
 * @interface PostIncidentFlowEvaluateConditionsRequestBody
 */
export interface PostIncidentFlowEvaluateConditionsRequestBody {
    /**
     * The currently selected custom fields for the incident, which will be used when evaluating if conditions match
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof PostIncidentFlowEvaluateConditionsRequestBody
     */
    custom_field_entries: Array<CustomFieldEntryPayload>;
    /**
     * The ID of the incident to evaluate
     * @type {string}
     * @memberof PostIncidentFlowEvaluateConditionsRequestBody
     */
    incident_id: string;
    /**
     * The selected role assignments for the incident, which will be used when evaluating if conditions match
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof PostIncidentFlowEvaluateConditionsRequestBody
     */
    incident_role_assignments: Array<IncidentRoleAssignmentPayload>;
    /**
     * The ID of the incident type this incident is configured to use
     * @type {string}
     * @memberof PostIncidentFlowEvaluateConditionsRequestBody
     */
    incident_type_id?: string;
    /**
     * Whether the incident is real, a test, a tutorial, or importing as a retrospective incident
     * @type {string}
     * @memberof PostIncidentFlowEvaluateConditionsRequestBody
     */
    mode?: string;
    /**
     * The ID of the current severity of this incident
     * @type {string}
     * @memberof PostIncidentFlowEvaluateConditionsRequestBody
     */
    severity_id?: string;
}

export function PostIncidentFlowEvaluateConditionsRequestBodyFromJSON(json: any): PostIncidentFlowEvaluateConditionsRequestBody {
    return PostIncidentFlowEvaluateConditionsRequestBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowEvaluateConditionsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowEvaluateConditionsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'incident_id': json['incident_id'],
        'incident_role_assignments': ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
        'incident_type_id': !exists(json, 'incident_type_id') ? undefined : json['incident_type_id'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'severity_id': !exists(json, 'severity_id') ? undefined : json['severity_id'],
    };
}

export function PostIncidentFlowEvaluateConditionsRequestBodyToJSON(value?: PostIncidentFlowEvaluateConditionsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'incident_id': value.incident_id,
        'incident_role_assignments': ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
        'incident_type_id': value.incident_type_id,
        'mode': value.mode,
        'severity_id': value.severity_id,
    };
}

