import {
  IncidentsCreateRequestBodyModeEnum as IncidentModeEnum,
  IncidentStatus,
  TrialStatusTrialStatusEnum,
} from "@incident-io/api";
import { DeclareFormData } from "@incident-shared/incident-forms";
import { LoadingModal, ModalFooter, Tab } from "@incident-ui";
import React, { useState } from "react";
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useDisableNPS } from "src/contexts/SurvicateContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useTrialData } from "src/utils/trial";
import { assertUnreachable } from "src/utils/utils";

import { useIdentity } from "../../../contexts/IdentityContext";
import {
  ActiveIncidentCreateForm,
  useActiveIncidentCreateForm,
} from "./ActiveIncidentCreateModal";
import {
  RetrospectiveIncidentForm,
  RetrospectiveIncidentFormData,
  useRetrospectiveIncidentForm,
} from "./RetrospectiveIncidentCreateModal";
import { TrialExpiredModal } from "./TrialExpiredModal";
import {
  IncidentCrudResourceTypes,
  useAllStatuses,
  useIncidentCrudResources,
} from "./useIncidentCrudResources";
import { usePrefillDefaultValues } from "./usePrefillDefaultValues";

export enum IncidentModeQueryParam {
  Real = "real",
  Retrospective = "retrospective",
  Test = "test",
}

export type IncidentCreateRequiredProps<T extends FieldValues> = {
  genericError: string | undefined;
  saving: boolean;
  formMethods: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
  confirmButtonText: string;
  disabled: boolean;
};

export function IncidentCreateModal({
  initialMode = IncidentModeQueryParam.Real,
  onClose,
  escalationId,
}: {
  onClose: () => void;
  initialMode?: IncidentModeQueryParam;
  escalationId?: string;
}): React.ReactElement {
  // Disable NPS on this modal as it is sensitive and could be annoying
  useDisableNPS();

  const { loading, ...resources } = useIncidentCrudResources();
  const { allStatuses, allStatusesLoading } = useAllStatuses();
  const trialData = useTrialData();
  const { prefillDefaultValues, prefillLoading } = usePrefillDefaultValues();

  if (
    trialData &&
    trialData.trialStatus === TrialStatusTrialStatusEnum.Expired
  ) {
    return <TrialExpiredModal onClose={onClose} />;
  }

  if (loading || allStatusesLoading || prefillLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <IncidentCreateModalInner
      allStatuses={allStatuses}
      resources={resources}
      onClose={onClose}
      initialMode={initialMode}
      prefillDefaultValues={prefillDefaultValues}
      escalationId={escalationId}
    />
  );
}

export const TABS: Tab[] = [
  {
    id: IncidentModeQueryParam.Real,
    label: "Active incident",
  },
  {
    id: IncidentModeQueryParam.Retrospective,
    label: "Retrospective incident",
  },
  {
    id: IncidentModeQueryParam.Test,
    label: "Test incident",
  },
];

const IncidentCreateModalInner = ({
  resources,
  allStatuses,
  onClose,
  initialMode,
  prefillDefaultValues,
  escalationId,
}: {
  resources: IncidentCrudResourceTypes;
  allStatuses: IncidentStatus[];
  initialMode: IncidentModeQueryParam;
  onClose: () => void;
  prefillDefaultValues: Partial<DeclareFormData>;
  escalationId?: string;
}) => {
  const { hasResponse } = useProductAccess();
  const [mode, setMode] = useState<IncidentModeQueryParam>(initialMode);

  const activeForm = useActiveIncidentCreateForm({
    ...resources,
    onSuccess: onClose,
    mode: IncidentModeEnum.Standard,
    prefillDefaultValues,
    escalationId,
  });
  const retroForm = useRetrospectiveIncidentForm({
    ...resources,
    allStatuses,
    onSuccess: onClose,
  });
  const testForm = useActiveIncidentCreateForm({
    ...resources,
    onSuccess: onClose,
    mode: IncidentModeEnum.Test,
    prefillDefaultValues,
  });

  const modeProps = (
    mode === IncidentModeQueryParam.Real
      ? activeForm
      : mode === IncidentModeQueryParam.Retrospective
      ? retroForm
      : mode === IncidentModeQueryParam.Test
      ? testForm
      : assertUnreachable(mode)
  ) as IncidentCreateRequiredProps<
    typeof mode extends IncidentModeQueryParam.Retrospective
      ? RetrospectiveIncidentFormData
      : DeclareFormData
  >;

  const { identity } = useIdentity();
  const tabs =
    identity.can_create_retrospective_incidents && hasResponse
      ? TABS
      : TABS.filter((tab) => tab.id !== IncidentModeQueryParam.Retrospective);

  const {
    formMethods,
    onSubmit,
    genericError,
    saving,
    confirmButtonText,
    disabled,
  } = modeProps;

  return (
    <Form.TabModal
      isExtraLarge
      onClose={onClose}
      disableQuickClose
      analyticsTrackingId={"declare-incident"}
      title="Declare incident"
      formMethods={formMethods}
      saving={saving}
      genericError={genericError}
      onSubmit={onSubmit}
      tabs={tabs}
      withIndicator
      defaultTab={mode}
      onTabChange={(tabId) => setMode(tabId as IncidentModeQueryParam)}
      value={mode}
      footer={
        <ModalFooter
          confirmButtonText={confirmButtonText}
          confirmButtonType="submit"
          saving={saving}
          onClose={onClose}
          disabled={disabled}
        />
      }
    >
      {/* The keys are required here to force a re-mount when you switch tabs */}
      {mode === IncidentModeQueryParam.Real ? (
        <ActiveIncidentCreateForm key="real" {...activeForm} {...resources} />
      ) : mode === IncidentModeQueryParam.Retrospective ? (
        <RetrospectiveIncidentForm key="retro" {...retroForm} {...resources} />
      ) : mode === IncidentModeQueryParam.Test ? (
        <ActiveIncidentCreateForm key="test" {...testForm} {...resources} />
      ) : (
        assertUnreachable(mode)
      )}
    </Form.TabModal>
  );
};
