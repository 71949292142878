/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydratedSlackChannel
 */
export interface HydratedSlackChannel {
    /**
     * URL to the icon for this channel's workspace
     * @type {string}
     * @memberof HydratedSlackChannel
     */
    icon_url?: string;
    /**
     * Whether this channel is archived
     * @type {boolean}
     * @memberof HydratedSlackChannel
     */
    is_archived?: boolean;
    /**
     * Whether this channel is deleted
     * @type {boolean}
     * @memberof HydratedSlackChannel
     */
    is_deleted?: boolean;
    /**
     * Whether this is a private channel
     * @type {boolean}
     * @memberof HydratedSlackChannel
     */
    is_private: boolean;
    /**
     * Human readable name for the channel
     * @type {string}
     * @memberof HydratedSlackChannel
     */
    label: string;
    /**
     * URL that links to the channel in slack
     * @type {string}
     * @memberof HydratedSlackChannel
     */
    url: string;
    /**
     * Unique identifier for this channel in slack
     * @type {string}
     * @memberof HydratedSlackChannel
     */
    value: string;
}

export function HydratedSlackChannelFromJSON(json: any): HydratedSlackChannel {
    return HydratedSlackChannelFromJSONTyped(json, false);
}

export function HydratedSlackChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydratedSlackChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icon_url': !exists(json, 'icon_url') ? undefined : json['icon_url'],
        'is_archived': !exists(json, 'is_archived') ? undefined : json['is_archived'],
        'is_deleted': !exists(json, 'is_deleted') ? undefined : json['is_deleted'],
        'is_private': json['is_private'],
        'label': json['label'],
        'url': json['url'],
        'value': json['value'],
    };
}

export function HydratedSlackChannelToJSON(value?: HydratedSlackChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icon_url': value.icon_url,
        'is_archived': value.is_archived,
        'is_deleted': value.is_deleted,
        'is_private': value.is_private,
        'label': value.label,
        'url': value.url,
        'value': value.value,
    };
}

