import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentTypes = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.25 11.516h-2.5A1.75 1.75 0 0 0 3 13.266v2.5c0 .967.784 1.75 1.75 1.75h2.5A1.75 1.75 0 0 0 9 15.766v-2.5a1.75 1.75 0 0 0-1.75-1.75ZM15.25 11.516h-2.5a1.75 1.75 0 0 0-1.75 1.75v2.5c0 .967.784 1.75 1.75 1.75h2.5a1.75 1.75 0 0 0 1.75-1.75v-2.5a1.75 1.75 0 0 0-1.75-1.75ZM17.005 5.28l-1.769-1.77a1.754 1.754 0 0 0-2.474 0l-1.769 1.77a1.753 1.753 0 0 0 0 2.473l1.769 1.77c.341.34.789.51 1.237.51.448 0 .896-.17 1.237-.51l1.769-1.77a1.753 1.753 0 0 0 0-2.474ZM7.25 3.516h-2.5A1.75 1.75 0 0 0 3 5.266v2.5c0 .967.784 1.75 1.75 1.75h2.5A1.75 1.75 0 0 0 9 7.766v-2.5a1.75 1.75 0 0 0-1.75-1.75Z"
    />
  </svg>
);
export default SvgIncidentTypes;
