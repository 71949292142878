import type { SVGProps } from "react";
import * as React from "react";
const SvgBarChart = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10.75 3h-.5A1.75 1.75 0 0 0 8.5 4.75v10.5c0 .966.784 1.75 1.75 1.75h.5a1.75 1.75 0 0 0 1.75-1.75V4.75A1.75 1.75 0 0 0 10.75 3Zm-5.5 5h-.5A1.75 1.75 0 0 0 3 9.75v5.5c0 .966.784 1.75 1.75 1.75h.5A1.75 1.75 0 0 0 7 15.25v-5.5A1.75 1.75 0 0 0 5.25 8Zm11 4h-.5A1.75 1.75 0 0 0 14 13.75v1.5c0 .966.784 1.75 1.75 1.75h.5A1.75 1.75 0 0 0 18 15.25v-1.5A1.75 1.75 0 0 0 16.25 12Z" />
  </svg>
);
export default SvgBarChart;
