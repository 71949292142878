/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageContentStatusSummary
 */
export interface StatusPageContentStatusSummary {
    /**
     * When the incident left this state
     * @type {string}
     * @memberof StatusPageContentStatusSummary
     */
    end_at?: string;
    /**
     * When the incident entered this state
     * @type {string}
     * @memberof StatusPageContentStatusSummary
     */
    start_at: string;
    /**
     * The worst component status during this time period
     * @type {string}
     * @memberof StatusPageContentStatusSummary
     */
    worst_component_status: StatusPageContentStatusSummaryWorstComponentStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageContentStatusSummaryWorstComponentStatusEnum {
    Operational = 'operational',
    UnderMaintenance = 'under_maintenance',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    FullOutage = 'full_outage'
}

export function StatusPageContentStatusSummaryFromJSON(json: any): StatusPageContentStatusSummary {
    return StatusPageContentStatusSummaryFromJSONTyped(json, false);
}

export function StatusPageContentStatusSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentStatusSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_at': !exists(json, 'end_at') ? undefined : json['end_at'],
        'start_at': json['start_at'],
        'worst_component_status': json['worst_component_status'],
    };
}

export function StatusPageContentStatusSummaryToJSON(value?: StatusPageContentStatusSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_at': value.end_at,
        'start_at': value.start_at,
        'worst_component_status': value.worst_component_status,
    };
}

