import { max } from "lodash";
import React from "react";
import {
  IncidentDurationMetric,
  IncidentTimestamp,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { prepareRankUpdate } from "src/utils/utils";

import { SettingsSortableList } from "../../SettingsSortableList";
import { CustomTimestampRow } from "./CustomTimestampRow";
import {
  reservedTimestampRanks,
  uneditableTimestampTypes,
} from "./IncidentTimestampsSection";

export const CustomTimestampsList = ({
  onEdit,
  metrics,
}: {
  onEdit: (item: IncidentTimestamp) => void;
  metrics: IncidentDurationMetric[];
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const {
    data: { incident_timestamps: allTimestamps },
  } = useAPI("incidentTimestampsList", undefined, {
    fallbackData: { incident_timestamps: [] },
  });

  const { trigger: updateTimestampRanks, isMutating: saving } = useAPIMutation(
    "incidentTimestampsList",
    undefined,
    async (apiClient, customisableTimestamps: IncidentTimestamp[]) => {
      // As the user can't edit the default timestamps, add them back in ahead of the customisable ones
      const timestamps = [
        ...allTimestamps.filter((timestamp) =>
          uneditableTimestampTypes.includes(timestamp.timestamp_type),
        ),
        ...customisableTimestamps,
      ];
      // Ensure ClosedAt always has the largest rank
      const highestRank =
        max(customisableTimestamps.map((t) => t.rank)) ||
        reservedTimestampRanks;
      const withClosedAtLast = timestamps.map((timestamp) => {
        if (timestamp.timestamp_type === "closed_at") {
          return { ...timestamp, rank: highestRank + 1 };
        }
        return timestamp;
      });
      await apiClient.incidentTimestampsUpdateRanks({
        updateRanksRequestBody: prepareRankUpdate(withClosedAtLast),
      });
    },
  );

  const visibleTimestamps = allTimestamps.filter(
    (timestamp) => !uneditableTimestampTypes.includes(timestamp.timestamp_type),
  );

  return (
    <SettingsSortableList
      items={visibleTimestamps}
      updateItemRanks={updateTimestampRanks}
      saving={saving}
      canEdit={canEditSettings}
      offset={reservedTimestampRanks}
      getNotConfigurableText={(item) =>
        uneditableTimestampTypes.includes(item.timestamp_type)
          ? "This timestamp is not configurable"
          : undefined
      }
      renderItem={(item) => (
        <CustomTimestampRow
          timestamp={item}
          onEdit={() => onEdit(item)}
          metrics={metrics}
        />
      )}
    />
  );
};
