import { StaticSingleSelect } from "@incident-ui";
import {
  StaticMultiSelect,
  StaticMultiSelectProps,
} from "@incident-ui/Select/StaticMultiSelect";
import { StaticSingleSelectProps } from "@incident-ui/Select/StaticSingleSelect";
import { SelectValue } from "@incident-ui/Select/types";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

type StaticSingleSelectV2Props = StaticSingleSelectProps & {
  onValueChange?: (val: string | null | undefined) => void;
};

export const StaticSingleSelectV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, StaticSingleSelectV2Props>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    StaticSingleSelectV2Props
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
      disabled={inputProps.disabled}
    >
      <StaticSingleSelect
        id={name}
        {...field}
        {...(inputProps as StaticSingleSelectProps)}
        onChange={(val: string | null | undefined) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type StaticMultiSelectV2Props = StaticMultiSelectProps & {
  onValueChange?: (val: SelectValue) => void;
};

export const StaticMultiSelectV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, StaticMultiSelectV2Props>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    StaticMultiSelectV2Props
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <StaticMultiSelect
        id={name}
        {...field}
        {...(inputProps as StaticMultiSelectProps)}
        onChange={(val: SelectValue) => {
          field.onChange(val);

          onValueChange && onValueChange(val);
        }}
      />
    </FormInputWrapper>
  );
};

type WrappedSelectProps<TOptionType> = StaticSingleSelectProps & {
  wrap: (value: string) => TOptionType;
  unwrap: (value: TOptionType) => string | undefined;
  onValueChange?: (val: TOptionType | undefined) => void;
};

// WrappedSingleStaticSelectV2 is like StaticSelectV2, but you can use it when you
// want to return a complex object instead of the select option or a string.
export const WrappedSingleStaticSelectV2 = <
  TFormType extends FieldValues,
  TOptionType,
>(
  props: InputElementProps<TFormType, WrappedSelectProps<TOptionType>>,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    WrappedSelectProps<TOptionType>
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  const staticSelectProps = inputProps as Omit<
    StaticSingleSelectProps,
    "value" | "onChange"
  >;

  const { wrap, unwrap } = inputProps;

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <StaticSingleSelect
        id={name}
        {...field}
        {...(staticSelectProps as StaticSingleSelectProps)}
        value={unwrap(field.value as TOptionType) || null}
        onChange={(val: string | null | undefined) => {
          const wrappedVal = val ? wrap(val) : undefined;

          field.onChange(wrappedVal);

          onValueChange && onValueChange(wrappedVal);
        }}
      />
    </FormInputWrapper>
  );
};
