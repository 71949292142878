import {
  IncidentTimestampSetByRule,
  IncidentTimestampSetByRuleSetOnTransitionEnum,
  IncidentTimestampSetByRuleSetOnVisitEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

export const TimestampSetByRulePills = ({
  rule,
}: {
  rule: IncidentTimestampSetByRule;
}): React.ReactElement => {
  return (
    <div className="inline-flex gap-2 items-center">
      <TimestampDescriptionPill>
        {rule.set_on_visit === IncidentTimestampSetByRuleSetOnVisitEnum.First
          ? "first"
          : "last"}
      </TimestampDescriptionPill>
      <TimestampDescriptionPill>
        {rule.set_on_transition ===
        IncidentTimestampSetByRuleSetOnTransitionEnum.Enter
          ? "entering"
          : "leaving"}
      </TimestampDescriptionPill>
      <TimestampDescriptionPill>
        {rule.set_on_status.name}
      </TimestampDescriptionPill>
    </div>
  );
};

export const TimestampDescriptionPill = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "py-0.5 px-1.5 font-normal rounded-[6px] border border-stroke bg-surface-secondary text-content-primary",
      )}
    >
      {children}
    </div>
  );
};

export const TimestampSetByRuleSentence = ({
  rule,
}: {
  rule: IncidentTimestampSetByRule;
}): React.ReactElement => {
  return (
    <>
      {rule.set_on_visit === IncidentTimestampSetByRuleSetOnVisitEnum.First
        ? "first "
        : "last "}
      {rule.set_on_transition ===
      IncidentTimestampSetByRuleSetOnTransitionEnum.Enter
        ? "entering "
        : "leaving "}
      {rule.set_on_status.name}
    </>
  );
};
