import { MSTeamsPreviewVariant } from "@incident-io/api";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  GenericErrorMessage,
  IconEnum,
  Loader,
  Markdown,
  Txt,
} from "@incident-ui";
import { AdaptiveCard } from "adaptivecards-react";
import { useParams } from "react-router";

import { toSentenceCase } from "../../utils/formatting";
import { useAPI } from "../../utils/swr";

export const MSTeamsPreviewMessagePage = () => {
  const { targetName } = useParams();
  if (!targetName) {
    throw new Error("No message name provided");
  }

  const { data, isLoading, error } = useAPI("mSTeamsPreviewsShow", {
    name: targetName,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error || !data?.template) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <PageWrapper
      title={data.template.name || ""}
      width={PageWidth.Narrow}
      icon={IconEnum.Message}
      backHref="/msteams-previews"
    >
      <div className="h-full p-6 space-y-3">
        <Txt>{data.template.description}</Txt>
        {data.template.variants?.map((v) => (
          <VariantPreview key={v.name} variant={v} />
        ))}
      </div>
    </PageWrapper>
  );
};

const VariantPreview = ({ variant }: { variant: MSTeamsPreviewVariant }) => {
  const hostConfig = {
    fontFamily: "Segoe UI, Helvetica Neue, sans-serif",
  };
  const payload = JSON.parse(variant.adaptiveCardJSON || "{}");
  const isAdaptiveCard = !!variant.adaptiveCardJSON;

  return (
    <>
      <Txt>
        {toSentenceCase(variant.name || "unknown")} (
        {isAdaptiveCard ? "Adaptive card" : "Markdown"})
      </Txt>
      <div className="border p-2 max-w-[450px]">
        {variant.adaptiveCardJSON ? (
          <AdaptiveCard payload={payload} hostConfig={hostConfig} />
        ) : (
          variant.markdown && (
            <Markdown key={variant.name}>{variant.markdown}</Markdown>
          )
        )}
      </div>
    </>
  );
};
