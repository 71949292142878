/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpressionSuggestion,
    ExpressionSuggestionFromJSON,
    ExpressionSuggestionFromJSONTyped,
    ExpressionSuggestionToJSON,
} from './ExpressionSuggestion';

/**
 * 
 * @export
 * @interface CatalogListExpressionSuggestionsResponseBody
 */
export interface CatalogListExpressionSuggestionsResponseBody {
    /**
     * 
     * @type {Array<ExpressionSuggestion>}
     * @memberof CatalogListExpressionSuggestionsResponseBody
     */
    expression_suggestions: Array<ExpressionSuggestion>;
}

export function CatalogListExpressionSuggestionsResponseBodyFromJSON(json: any): CatalogListExpressionSuggestionsResponseBody {
    return CatalogListExpressionSuggestionsResponseBodyFromJSONTyped(json, false);
}

export function CatalogListExpressionSuggestionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListExpressionSuggestionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expression_suggestions': ((json['expression_suggestions'] as Array<any>).map(ExpressionSuggestionFromJSON)),
    };
}

export function CatalogListExpressionSuggestionsResponseBodyToJSON(value?: CatalogListExpressionSuggestionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expression_suggestions': ((value.expression_suggestions as Array<any>).map(ExpressionSuggestionToJSON)),
    };
}

