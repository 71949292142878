import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  TabSection,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { captureMessage } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { Outlet } from "react-router";
import graphic from "src/components/settings/banners/banner-users.png";
import graphicNewTable from "src/components/settings/banners/banner-users-new-table.png";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { isScimEnabled } from "./scim/ScimShowPage";
import { UserInviteContext } from "./users/UserListPage";

export enum Tab {
  Users = "users",
  Roles = "roles",
  Scim = "scim",
}

const tabs = [
  {
    id: Tab.Users,
    label: "Users",
  },
  {
    id: Tab.Roles,
    label: "Roles",
  },
  {
    id: Tab.Scim,
    label: "SCIM",
  },
];

export const SettingsUsersPageWrapper = () => {
  // We do some work in this wrapper because it's possible for each tab to have an accessory which
  // exists on the tab bar.
  const navigate = useOrgAwareNavigate();
  const { onCallOnlyBillingSeats } = useFlags();
  const { data: scimConfigState, error: scimConfigError } = useAPI(
    "sCIMShowSettings",
    undefined,
  );

  const selectedTab = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1,
  );

  const [showInviteModal, setShowInviteModal] = useState(false);
  const { identity } = useIdentity();

  if (!identity || !scimConfigState) {
    return <FullPageLoader />;
  }
  if (scimConfigError) {
    captureMessage("loading SCIM config", {
      extra: { error: scimConfigError },
    });
    return <GenericErrorMessage error={scimConfigError} />;
  }

  return (
    <SettingsSubPageWrapper
      accessory={
        <GatedButton
          onClick={() => setShowInviteModal(true)}
          analyticsTrackingId="invite-user"
          icon={IconEnum.Add}
          theme={ButtonTheme.Primary}
          className="ml-auto mr-0"
          disabled={isScimEnabled(scimConfigState)}
          disabledTooltipContent={
            scimConfigState
              ? "You cannot manually add users when SCIM is enabled. You must either add users via your Identity Provider, or disable SCIM."
              : undefined
          }
        >
          Invite user
        </GatedButton>
      }
    >
      <SettingsHeading
        articleId={5948389}
        graphic={
          <img
            src={onCallOnlyBillingSeats ? graphicNewTable : graphic}
            className="h-40"
          />
        }
        title="Manage user roles"
        subtitle="Handle permissions and roles to manage user access effectively."
      />
      <div className="mb-3">
        <TabSection
          key={selectedTab}
          withIndicator
          defaultTab={selectedTab as Tab}
          onTabChange={(tab) => {
            navigate(`/settings/users/${tab}`);
          }}
          tabBarClassName="border-b border-stroke"
          tabs={tabs}
        />
      </div>
      <Outlet
        context={
          {
            showInviteModal,
            closeInviteModal: () => setShowInviteModal(false),
          } as UserInviteContext
        }
      />
    </SettingsSubPageWrapper>
  );
};
