/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LegacyIssueTemplate,
    LegacyIssueTemplateFromJSON,
    LegacyIssueTemplateFromJSONTyped,
    LegacyIssueTemplateToJSON,
} from './LegacyIssueTemplate';

/**
 * 
 * @export
 * @interface IssueTrackerIssueTemplatesListResponseBody
 */
export interface IssueTrackerIssueTemplatesListResponseBody {
    /**
     * 
     * @type {Array<LegacyIssueTemplate>}
     * @memberof IssueTrackerIssueTemplatesListResponseBody
     */
    issue_tracker_issue_templates: Array<LegacyIssueTemplate>;
}

export function IssueTrackerIssueTemplatesListResponseBodyFromJSON(json: any): IssueTrackerIssueTemplatesListResponseBody {
    return IssueTrackerIssueTemplatesListResponseBodyFromJSONTyped(json, false);
}

export function IssueTrackerIssueTemplatesListResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTrackerIssueTemplatesListResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_tracker_issue_templates': ((json['issue_tracker_issue_templates'] as Array<any>).map(LegacyIssueTemplateFromJSON)),
    };
}

export function IssueTrackerIssueTemplatesListResponseBodyToJSON(value?: IssueTrackerIssueTemplatesListResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_tracker_issue_templates': ((value.issue_tracker_issue_templates as Array<any>).map(LegacyIssueTemplateToJSON)),
    };
}

