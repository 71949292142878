import { DateRangeRelativeStateIntervalEnum as Interval } from "@incident-io/api";

import {
  DateRangePickerMode,
  DateRangePickerState,
  QUICK_SELECT_INTERVAL_CONFIG,
  QuickSelectInterval,
} from "./types";

// Note! We're also using this to serialize dates for our own filters.
// If you're changing it for metabase reasons please make a duplicate
// that preserves the current behaviour and call that in FilterModal.
export const serializeDateRange = (state: DateRangePickerState): string => {
  if (state.mode === DateRangePickerMode.Absolute) {
    return `${state.absolute.from}~${state.absolute.to}`;
  }

  const isQuickSelect = state.mode === DateRangePickerMode.QuickSelect;
  const relativeState = isQuickSelect
    ? QUICK_SELECT_INTERVAL_CONFIG[state.quick_select].state
    : state.relative;

  // "~" or "-" at the end indicate if you want to include the current interval
  // in the date range. ("~" means include, "-" means exclude, see deserializeDateRange())
  // This is only relevant for Relative mode and NOT QuickSelect.
  const dateRangeString = `past${
    relativeState.numberOfIntervals
  }${relativeState.interval.toLowerCase()}${
    !isQuickSelect ? (relativeState.includeThisInterval ? "~" : "-") : ""
  }`;
  return dateRangeString;
};

// This takes the metabase format produced by marshallDateRangePickerStateToMetabase
// and returns the corresponding DateRangePickerState.
export const deserializeDateRange = (s: string): DateRangePickerState => {
  if (s.startsWith("past")) {
    // Mode is either a QuickSelect or Relative date range.
    const matches = s
      .matchAll(
        /(past)(?<numberOfIntervals>[0-9]*)(?<interval>\w*)(?<includeThisInterval>[~-]?)/g,
      )
      .next().value?.groups;

    if (!matches) {
      // Fallback to the default state
      return {
        mode: DateRangePickerMode.QuickSelect,
        quick_select: QuickSelectInterval.LastWeek,
      };
    }

    const isQuickSelect = !(s.endsWith("-") || s.endsWith("~"));
    const quickSelect = getQuickSelect(
      matches.interval,
      parseInt(matches.numberOfIntervals),
    );
    if (isQuickSelect && quickSelect) {
      return {
        mode: DateRangePickerMode.QuickSelect,
        quick_select: quickSelect,
      };
    } else {
      return {
        mode: DateRangePickerMode.Relative,
        relative: {
          interval: matches.interval as Interval,
          numberOfIntervals:
            typeof matches.numberOfIntervals === "string"
              ? parseInt(matches.numberOfIntervals)
              : matches.numberOfIntervals,
          includeThisInterval: matches.includeThisInterval === "~",
        },
      };
    }
  } else {
    // Mode is Absolute date range.
    const dates = s.split("~");
    return {
      mode: DateRangePickerMode.Absolute,
      absolute: {
        from: dates[0],
        to: dates[1],
      },
    };
  }
};

const getQuickSelect = (
  interval: string,
  n: number,
): QuickSelectInterval | undefined => {
  if (interval === "days" && n === 7) {
    return QuickSelectInterval.Last7Days;
  }
  if (interval === "days" && n === 30) {
    return QuickSelectInterval.Last30Days;
  }
  if (interval === "weeks" && n === 1) {
    return QuickSelectInterval.LastWeek;
  }
  if (interval === "weeks" && n === 4) {
    return QuickSelectInterval.Last4Weeks;
  }
  if (interval === "months" && n === 1) {
    return QuickSelectInterval.LastMonth;
  }
  if (interval === "months" && n === 1) {
    return QuickSelectInterval.Last3Months;
  }
  if (interval === "months" && n === 3) {
    return QuickSelectInterval.Last3Months;
  }
  if (interval === "months" && n === 6) {
    return QuickSelectInterval.Last6Months;
  }
  if (interval === "months" && n === 12) {
    return QuickSelectInterval.Last12Months;
  }

  return void 0;
};
