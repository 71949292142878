import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ErrorMessage } from "@incident-ui";
import { Callout, CalloutTheme, Loader, ModalFooter } from "@incident-ui";
import { compact } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { BulkNoTriggersNotice } from "./BulkNoTriggersNotice";

export type BulkUpdateIncidentTypesFormData = {
  incident_type_id: string;
};

export function BulkUpdateIncidentTypesForm({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const formMethods = useForm<BulkUpdateIncidentTypesFormData>({});
  const { watch } = formMethods;

  const [bulkErrors, setBulkErrors] = useState<string[]>([]);
  const analytics = useAnalytics();

  const {
    data: { incident_types: incidentTypes },
    error: incidentTypesError,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });
  if (incidentTypesError) {
    throw incidentTypesError;
  }

  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, data: BulkUpdateIncidentTypesFormData) => {
      analytics?.track("bulkActionApplied", {
        action: "update incident type",
        numIncidents: incidentIDs.length,
      });

      const { results } = await apiClient.incidentsBulkUpdateIncidentTypes({
        bulkUpdateIncidentTypesRequestBody: {
          incident_ids: incidentIDs,
          incident_type_id: data.incident_type_id,
        },
      });

      const errors = compact(results.map((result) => result.error));
      if (errors && errors.length !== 0) {
        setBulkErrors(errors);
      } else {
        onSubmit();
      }
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  const incidentTypeID = watch("incident_type_id");

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      saving={saving}
      onSubmit={submit}
      title={"Update Incident Type"}
      analyticsTrackingId="bulk-update-incident-types"
      onClose={onClose}
      footer={
        <ModalFooter
          hideBorder
          confirmButtonText={"Apply"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      {incidentTypes ? (
        <div className="mb-4">
          <StaticSingleSelectV2<BulkUpdateIncidentTypesFormData>
            formMethods={formMethods}
            name={`incident_type_id`}
            label={"Incident Type"}
            placeholder="Select an incident type"
            required={`Please choose an incident type`}
            options={incidentTypes.map((incidentType) => {
              return {
                value: incidentType.id,
                label: incidentType.name,
              };
            })}
          />
          <p className="mt-1 text-xs text-slate-700">
            {
              incidentTypes.find(
                (incidentType) => incidentType.id === incidentTypeID,
              )?.description
            }
          </p>
        </div>
      ) : (
        <Loader />
      )}
      {bulkErrors && <ErrorMessage message={bulkErrors.join(" ")} />}
      <Callout theme={CalloutTheme.Warning}>
        Changing the incident type may trigger automations such as:
        <ul className="list-disc list-inside">
          <li>New announcement posts being created</li>
          <li>Different custom fields and roles becoming applicable</li>
        </ul>
      </Callout>
      <BulkNoTriggersNotice className="mt-4" />
    </Form.Modal>
  );
}
