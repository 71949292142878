import { DecisionFlowsCreateRequestBody as CreateReqBody } from "src/contexts/ClientContext";

export type DecisionFlowTemplate = CreateReqBody & {
  template_label: string;
};

export const GDPRTree = {
  root: {
    id: 1,
    name: "Has a breach occurred?",
    prompt:
      "A personal data breach (PDB) can be broadly defined as a security incident that has affected the confidentiality, integrity or availability of personal data. Have you determined whether a PDB has occurred?",
  },
  nodes: [
    {
      id: 83979986,
      name: "Does it involve living individuals?",
      prompt:
        "Making your own assessment, does the breach involve the personal data of living individuals?",
      parent_id: 1,
      sort_order: 10,
      option_label: "✅ Yes",
    },
    {
      id: 4041973498,
      name: "No data breach",
      prompt:
        "A personal data breach has not occurred. There is no requirement to notify the ICO.",
      parent_id: 1,
      sort_order: 20,
      option_label: "❌ No",
    },
    {
      id: 4057610759,
      name: "Call the ICO",
      prompt:
        "Please ring our helpline to discuss the breach in more detail. We can also offer advice on how to manage the breach, mitigate its effect and if necessary take details of the breach over the telephone.\n\nThe helpline, on 0303 123 1113 is usually open Monday to Friday from 9am until 5pm. If you need to report outside of these hours you should do so online. You are required to report breaches within 72 hours of becoming aware of them.",
      parent_id: 1,
      sort_order: 30,
      option_label: "❓ I don't know",
    },
    {
      id: 1001997940,
      name: "High risk to individuals' rights?",
      prompt:
        "Following your own assessment, is there likely to be a high risk to individuals' rights and freedoms?",
      parent_id: 83979986,
      sort_order: 10,
      option_label: "✅ Yes",
    },
    {
      id: 731165635,
      name: "No data breach",
      prompt:
        "A personal data breach has not occurred. There is no requirement to notify the ICO.",
      parent_id: 83979986,
      sort_order: 20,
      option_label: "❌ No",
    },
    {
      id: 4098378183,
      name: "Report to ICO",
      prompt:
        "You need to tell the people affected by the breach without delay. You should inform them about any steps you are taking to mitigate the effects of the breach and provide them with advice on what to do to protect themselves.\n\nAs you've made an assessment there is likely to be a high risk then you must also notify the ICO. This must be done within 72 hours of becoming aware of the breach.",
      parent_id: 1001997940,
      sort_order: 10,
      option_label: "✅ Yes",
    },
    {
      id: 3508515868,
      name: "How likely to result in risk?",
      prompt:
        "How likely is it that the breach will result in a risk to individuals?",
      parent_id: 1001997940,
      sort_order: 20,
      option_label: "❌ No, it's not likely to be a high risk",
    },
    {
      id: 1303846609,
      name: "Report to ICO",
      prompt:
        "As you've made an assessment that it is likely there will be a risk then you must notify the ICO. This must be done within 72 hours of becoming aware of the breach.",
      parent_id: 3508515868,
      sort_order: 10,
      option_label: "🔼 Likely",
    },
    {
      id: 1887417817,
      name: "Keep a record",
      prompt:
        "You should keep an internal record of the breach as detailed in Article 33 (5) of the GDPR, including what happened, the effects of the breach and remedial actions taken. ",
      parent_id: 3508515868,
      sort_order: 20,
      option_label: "🔽 Unlikely",
    },
    {
      id: 4192788359,
      name: "Data contained?",
      prompt:
        "Do you consider the data to be contained and the risk to data subjects mitigated?",
      parent_id: 3508515868,
      sort_order: 30,
      option_label: "❓ I'm not sure",
    },
    {
      id: 910092159,
      name: "Keep a record",
      prompt:
        "Based on your assessment the likelihood of risk to data subjects appears to be unlikely, you should keep an internal record of the breach as detailed in Article 33 (5) of the GDPR, including what happened, the effects of the breach and remedial actions taken. ",
      parent_id: 4192788359,
      sort_order: 10,
      option_label: "✅ Yes",
    },
    {
      id: 3607700528,
      name: "Call the ICO",
      prompt:
        "Please ring the ICO helpline to discuss the breach in more detail. They can also offer advice on how to manage the breach, mitigate its effect and if necessary take details of the breach over the telephone.\n\nThe helpline, on 0303 123 1113 is usually open Monday to Friday from 9am until 5pm. If you need to report outside of these hours you should do so online. You are required to report breaches within 72 hours of becoming aware of them.",
      parent_id: 4192788359,
      sort_order: 20,
      option_label: "❌ No",
    },
  ],
};

const GDPRFlow: DecisionFlowTemplate = {
  template_label: "Data Breach (GDPR)",
  name: "Data Breach (GDPR)",
  description: `A personal data breach is a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data.

If you experience a personal data breach you need to consider whether this poses a risk to people. You need to consider the likelihood and severity of the risk to people’s rights and freedoms, following the breach. When you’ve made this assessment, if it’s likely there will be a risk then you must notify the ICO; if it’s unlikely then you don’t have to report. You do not need to report every breach to the ICO.

This decision flow has been adapted from https://ico.org.uk/for-organisations/report-a-breach`,
  tree: GDPRTree,
  is_live: true,
};

const BlankFlow = {
  template_label: "Blank",
  name: "",
  description: "",
  is_live: true,
  tree: {
    nodes: [],
    root: {
      id: 1,
      name: "",
      prompt: "",
    },
  },
};

export const flowTemplates: DecisionFlowTemplate[] = [BlankFlow, GDPRFlow];
