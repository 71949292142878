/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsLinearGetConfigResponseBody,
    IntegrationsLinearGetConfigResponseBodyFromJSON,
    IntegrationsLinearGetConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsLinearHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsLinearHandleRedirectRequest {
    code?: string;
    state?: string;
}

/**
 * 
 */
export class IntegrationsLinearApi extends runtime.BaseAPI {

    /**
     * Get the organisation\'s Linear config
     * GetConfig Integrations - Linear
     */
    async integrationsLinearGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsLinearGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/linear/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsLinearGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the organisation\'s Linear config
     * GetConfig Integrations - Linear
     */
    async integrationsLinearGetConfig(initOverrides?: RequestInit): Promise<IntegrationsLinearGetConfigResponseBody> {
        const response = await this.integrationsLinearGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Linear installation by redirecting to the Linear install endpoint
     * HandleInstall Integrations - Linear
     */
    async integrationsLinearHandleInstallRaw(requestParameters: IntegrationsLinearHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsLinearHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/linear_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Linear installation by redirecting to the Linear install endpoint
     * HandleInstall Integrations - Linear
     */
    async integrationsLinearHandleInstall(requestParameters: IntegrationsLinearHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsLinearHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Linear redirect
     * HandleRedirect Integrations - Linear
     */
    async integrationsLinearHandleRedirectRaw(requestParameters: IntegrationsLinearHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/linear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Linear redirect
     * HandleRedirect Integrations - Linear
     */
    async integrationsLinearHandleRedirect(requestParameters: IntegrationsLinearHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsLinearHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Linear OAuth token
     * HandleUninstall Integrations - Linear
     */
    async integrationsLinearHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/linear`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Linear OAuth token
     * HandleUninstall Integrations - Linear
     */
    async integrationsLinearHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsLinearHandleUninstallRaw(initOverrides);
    }

}
