/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface PoliciesCreateRequestBody
 */
export interface PoliciesCreateRequestBody {
    /**
     * Determines the applicability of a policy to an incident based on its timestamp.
     * @type {Date}
     * @memberof PoliciesCreateRequestBody
     */
    applies_from?: Date;
    /**
     * Human readable description of the policy
     * @type {string}
     * @memberof PoliciesCreateRequestBody
     */
    description: string;
    /**
     * The expressions to use in this policy
     * @type {Array<ExpressionPayload>}
     * @memberof PoliciesCreateRequestBody
     */
    expressions?: Array<ExpressionPayload>;
    /**
     * Condition groups that apply to incidents in this policy
     * @type {Array<ConditionGroupPayload>}
     * @memberof PoliciesCreateRequestBody
     */
    incident_condition_groups: Array<ConditionGroupPayload>;
    /**
     * Human readable name of the policy
     * @type {string}
     * @memberof PoliciesCreateRequestBody
     */
    name: string;
    /**
     * Type of the policy, specifying what this applies to
     * @type {string}
     * @memberof PoliciesCreateRequestBody
     */
    policy_type: PoliciesCreateRequestBodyPolicyTypeEnum;
    /**
     * Policy Report IDs to include this Policy into
     * @type {Array<string>}
     * @memberof PoliciesCreateRequestBody
     */
    report_schedule_ids?: Array<string>;
    /**
     * A list of reference keys for the person responsible for this policy (if the first can't be found, we'll try to resolve the second, and so on
     * @type {Array<string>}
     * @memberof PoliciesCreateRequestBody
     */
    responsible_user_references?: Array<string>;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof PoliciesCreateRequestBody
     */
    sla_days: EngineParamBindingPayload;
    /**
     * How to calculate how many days have passed
     * @type {string}
     * @memberof PoliciesCreateRequestBody
     */
    sla_days_calculation_type: PoliciesCreateRequestBodySlaDaysCalculationTypeEnum;
    /**
     * The incident lifecycle timestamp from which to start the SLA timer
     * @type {string}
     * @memberof PoliciesCreateRequestBody
     */
    sla_incident_timestamp_id: string;
    /**
     * Condition groups that apply to the resources of this policy
     * @type {Array<ConditionGroupPayload>}
     * @memberof PoliciesCreateRequestBody
     */
    type_condition_groups: Array<ConditionGroupPayload>;
    /**
     * Number of days before a policy violation occurs to notify the responsible user
     * @type {number}
     * @memberof PoliciesCreateRequestBody
     */
    warning_days?: number;
}

/**
* @export
* @enum {string}
*/
export enum PoliciesCreateRequestBodyPolicyTypeEnum {
    Debrief = 'debrief',
    FollowUp = 'follow_up',
    PostMortem = 'post_mortem'
}/**
* @export
* @enum {string}
*/
export enum PoliciesCreateRequestBodySlaDaysCalculationTypeEnum {
    SevenDays = 'seven_days',
    Weekdays = 'weekdays'
}

export function PoliciesCreateRequestBodyFromJSON(json: any): PoliciesCreateRequestBody {
    return PoliciesCreateRequestBodyFromJSONTyped(json, false);
}

export function PoliciesCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoliciesCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applies_from': !exists(json, 'applies_from') ? undefined : (new Date(json['applies_from'])),
        'description': json['description'],
        'expressions': !exists(json, 'expressions') ? undefined : ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'incident_condition_groups': ((json['incident_condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'name': json['name'],
        'policy_type': json['policy_type'],
        'report_schedule_ids': !exists(json, 'report_schedule_ids') ? undefined : json['report_schedule_ids'],
        'responsible_user_references': !exists(json, 'responsible_user_references') ? undefined : json['responsible_user_references'],
        'sla_days': EngineParamBindingPayloadFromJSON(json['sla_days']),
        'sla_days_calculation_type': json['sla_days_calculation_type'],
        'sla_incident_timestamp_id': json['sla_incident_timestamp_id'],
        'type_condition_groups': ((json['type_condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'warning_days': !exists(json, 'warning_days') ? undefined : json['warning_days'],
    };
}

export function PoliciesCreateRequestBodyToJSON(value?: PoliciesCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applies_from': value.applies_from === undefined ? undefined : (value.applies_from.toISOString()),
        'description': value.description,
        'expressions': value.expressions === undefined ? undefined : ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'incident_condition_groups': ((value.incident_condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'name': value.name,
        'policy_type': value.policy_type,
        'report_schedule_ids': value.report_schedule_ids,
        'responsible_user_references': value.responsible_user_references,
        'sla_days': EngineParamBindingPayloadToJSON(value.sla_days),
        'sla_days_calculation_type': value.sla_days_calculation_type,
        'sla_incident_timestamp_id': value.sla_incident_timestamp_id,
        'type_condition_groups': ((value.type_condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'warning_days': value.warning_days,
    };
}

