import { Tooltip } from "@incident-ui";
import { Checkbox } from "@incident-ui/Checkbox/Checkbox";
import { SelectOption } from "@incident-ui/Select/types";
import { FieldValues, Path, useController } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export type CheckboxGroupOption = SelectOption<React.ReactNode> & {
  description?: React.ReactNode;
  isDisabled?: boolean;
  isDisabledTooltipContent?: React.ReactNode;
  suffixNode?: React.ReactNode;
};

export type CheckboxGroupProps = {
  options: CheckboxGroupOption[];
  name: string;
  onChange: (e: string[]) => void;
  value: string[];
};

export const BoxedCheckboxGroup = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, CheckboxGroupProps> & {
    onValueChange?: (val: string[]) => void;
  },
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, wrapperProps } = parseProps<
    TFormType,
    CheckboxGroupProps
  >(rest);
  const {
    // We don't want to pass the ref onwards here: a boxed checkbox group
    // references multiple inputs, so we have to use a controller here rather
    // than an uncontrolled form input.
    field: { ref: _ref, ...field },
  } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      {props.options.map((option, index) => {
        return (
          <BoxedCheckbox
            key={option.value}
            index={index}
            lastIndex={props.options.length - 1}
            option={option}
            value={field.value}
            onChange={(val: string[]) => {
              field.onChange(val);
              onValueChange && onValueChange(val);
            }}
          />
        );
      })}
    </FormInputWrapper>
  );
};

const BoxedCheckbox = ({
  index,
  lastIndex,
  value,
  option,
  onChange,
}: {
  index: number;
  lastIndex: number;
  value: string[];
  option: CheckboxGroupOption;
  onChange: (val: string[]) => void;
}) => {
  return (
    <Tooltip content={option.isDisabled && option.isDisabledTooltipContent}>
      <label
        htmlFor={option.value}
        className={tcx(
          "py-4",
          "border-t border-l border-r",
          "flex items-center",
          "group/check",
          index === 0 ? "rounded-t-2" : "",
          index === lastIndex ? "border-b rounded-b-2" : "",
          option.isDisabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
      >
        <div className="flex flex-grow">
          <Checkbox
            className={tcx("ml-4 mt-[2px] items-start")}
            id={option.value}
            checked={value.includes(option.value)}
            disabled={option.isDisabled}
            onChange={(boolVal) => {
              if (boolVal.target.checked) {
                return onChange([...value, option.value]);
              }

              return onChange(value.filter((item) => item !== option.value));
            }}
          />
          <div
            className={tcx("ml-2", {
              "opacity-50": option.isDisabled,
            })}
          >
            <div className="text-sm-med">{option.label}</div>
            <div className="text-xs-med text-content-secondary">
              {option.description}
            </div>
          </div>
        </div>
      </label>
    </Tooltip>
  );
};
