/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostIncidentTask,
    PostIncidentTaskFromJSON,
    PostIncidentTaskFromJSONTyped,
    PostIncidentTaskToJSON,
} from './PostIncidentTask';

/**
 * 
 * @export
 * @interface PostIncidentFlowAssignTaskResponseBody
 */
export interface PostIncidentFlowAssignTaskResponseBody {
    /**
     * 
     * @type {PostIncidentTask}
     * @memberof PostIncidentFlowAssignTaskResponseBody
     */
    incident_task: PostIncidentTask;
}

export function PostIncidentFlowAssignTaskResponseBodyFromJSON(json: any): PostIncidentFlowAssignTaskResponseBody {
    return PostIncidentFlowAssignTaskResponseBodyFromJSONTyped(json, false);
}

export function PostIncidentFlowAssignTaskResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentFlowAssignTaskResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_task': PostIncidentTaskFromJSON(json['incident_task']),
    };
}

export function PostIncidentFlowAssignTaskResponseBodyToJSON(value?: PostIncidentFlowAssignTaskResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_task': PostIncidentTaskToJSON(value.incident_task),
    };
}

