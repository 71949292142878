import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { MockAnalyticsProvider } from "src/test/MockAnalyticsProvider";
import { Environment, getEnvironment } from "src/utils/environment";

export const AnalyticsContext = createContext<Analytics | null>(null);

const SegmentKeys: Record<Environment, string> = {
  // TODO: remove this once testing is over as it'll only work on Lisa's machine.
  [Environment.Development]: "KeY9InPA9NDoNTXXZXjntO76raPv4Q09",
  [Environment.Staging]: "YT3sgJhFjx127AOZMHcs4G6BOW1ovlrT",
  [Environment.Production]: "3JiqYMw3BNjNBkXLbr777RyOQ6MBvPhw",
};

export type AnalyticsMetadata = { [key: string]: string | number };

// Provider component that wraps the app and makes auth object
// available to any child component that calls useAnalytics(). API requests are all
// authenticated anyway, so this is just a nice convenience context which lets
// us check the current authentication state in our react components.
export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  const writeKey = SegmentKeys[getEnvironment()];
  const location = useLocation();
  const pathname = location?.pathname;

  const { identity, isImpersonating } = useIdentity();

  // Initialise segment
  useEffect(() => {
    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [setAnalytics, writeKey]);

  // whenever we authenticate, we should tell Segment
  // which user and org this is.
  useEffect(() => {
    if (analytics && identity) {
      if (process.env.REACT_APP_STOP_SEGMENT_PAGE === "true") {
        return;
      }
      analytics.identify(identity.user_id);

      analytics.group(identity.organisation_id, {
        name: identity.organisation_name,
      });
    }
  }, [identity, analytics, isImpersonating]);

  // whenever react-router changes pathname, we should send a 'page' event to segment.
  useEffect(() => {
    if (analytics && pathname) {
      if (process.env.REACT_APP_STOP_SEGMENT_PAGE === "true") {
        return;
      }
      // We make title null as, due to the react-router hack, the title is usually wrong.
      // I think that's because the pathname changes a split-second before the
      // window's page title from Helmet, so we've still got the old title here.
      // One to fix in future, but for now this avoids having misleading data
      // in Segment.
      analytics.page(pathname, { title: null });
    }
  }, [pathname, analytics]);

  if (process.env.REACT_APP_STOP_SEGMENT_PAGE === "true") {
    return <MockAnalyticsProvider>{children}</MockAnalyticsProvider>;
  }

  return (
    <>
      <AnalyticsContext.Provider value={analytics}>
        {children}
      </AnalyticsContext.Provider>
    </>
  );
};

export const useAnalytics = (): Analytics | null =>
  useContext(AnalyticsContext);
