import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { DropdownMenuItem, IconEnum } from "@incident-ui";
import { isEmpty } from "lodash";
import { groupDependentResources } from "src/components/settings/DeletionConfirmationModal";
import { useAPI } from "src/utils/swr";

export const WorkflowsDisableDropdownItem = ({
  disabled,
  onDisable,
  workflowId,
  workflowName,
  disabledTooltipContent,
}: {
  disabled: boolean;
  onDisable: () => void;
  workflowId: string;
  workflowName: string;
  disabledTooltipContent: React.ReactNode;
}) => {
  const {
    data: dependentResourcesData,
    isLoading: dependentResourcesIsLoading,
    error: dependentResourcesError,
  } = useAPI(workflowId ? "engineFindDependentResourcesForMultiple" : null, {
    findDependentResourcesForMultipleRequestBody: {
      resources: [{ id: workflowId || "", resource_type: "Workflow" }],
    },
  });
  const { requiresDeletionResources } = groupDependentResources(
    dependentResourcesData?.dependent_resources ?? [],
  );
  const hasDependentResources = !isEmpty(requiresDeletionResources);

  if (!disabledTooltipContent && hasDependentResources) {
    disabledTooltipContent = (
      <DependentResourceList
        title={workflowName}
        whiteText
        requiresDeletionResources={requiresDeletionResources}
      />
    );
  }

  if (dependentResourcesError) {
    return (
      <DropdownMenuItem
        onSelect={onDisable}
        analyticsTrackingId={null}
        label={"Disable"}
        icon={IconEnum.Warning}
        disabled
        tooltipContent={"Failed to load dependent resources"}
      />
    );
  }

  return (
    <DropdownMenuItem
      onSelect={onDisable}
      analyticsTrackingId={"disable-workflow-button"}
      label="Disable"
      icon={IconEnum.ToggleLeft}
      iconProps={{ className: "!w-6 !h-6" }}
      disabled={
        disabled || dependentResourcesIsLoading || hasDependentResources
      }
      tooltipContent={disabledTooltipContent}
    />
  );
};
