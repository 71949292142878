import {
  BadgeSize,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { escalationPathWorkingHoursToPayload } from "../common/marshall";
import { EscalationPathFormData, PathNode } from "../common/types";
import { insertIfElseAboveNode } from "../node-editor/helpers/insertNode";
import { LoadingNodeCard, NodeCard } from "./NodeCard";

// NodeStart is used only for the first node in an escalation path, regardless of the node type (IfElse or Level).
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NodeStart = ({ id }: { id: string }) => {
  const icon = (
    <div className="bg-blue-surface rounded-2 w-10 h-10 flex items-center justify-center">
      <Icon
        id={IconEnum.Flag}
        size={IconSize.Large}
        className="fill-blue-500 text-blue-500 bg-blue-surface"
      />
    </div>
  );

  const formMethods = useFormContext<EscalationPathFormData>();

  const workingHours = formMethods.watch("working_hours");
  const firstNodeId = formMethods.watch("firstNodeId");

  const { data: scopeData } = useAPI(
    "escalationPathsBuildScope",
    {
      buildScopeRequestBody: {
        working_hours: escalationPathWorkingHoursToPayload(workingHours),
      },
    },
    {},
  );

  if (!scopeData) {
    return (
      <LoadingNodeCard
        id={id}
        title={<div className="text-xs text-slate-600">Start</div>}
        icon={icon}
        subtitle="For all escalations"
        allowAddAbove={false}
      />
    );
  }

  return (
    <NodeCard
      nodeId={id}
      title={<div className="text-xs text-slate-600">Start</div>}
      subtitle="For all escalations"
      icon={icon}
      allowAddAbove={false}
      allowAddBelow={false}
    >
      <div className="pt-4">
        <Button
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={null}
          title="Add branch"
          size={BadgeSize.Medium}
          icon={IconEnum.Add}
          onClick={() => {
            insertIfElseAboveNode({
              node: formMethods.getValues().nodes[firstNodeId],
              nodes: formMethods.getValues().nodes,
              firstNodeId: formMethods.getValues().firstNodeId,
              updateNodes: (nodes: Record<string, PathNode>) => {
                formMethods.setValue<"nodes">("nodes", nodes);
              },
              updateFirstNodeId: (firstNodeId: string) => {
                formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
              },
            });
          }}
        >
          Add branch
        </Button>
      </div>
    </NodeCard>
  );
};
