import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Txt,
} from "@incident-ui";

export const starterQuestions = [
  {
    displayText:
      "Suggest three questions for exploring my organization's incident data",
    promptText:
      "Suggest 3 insightful questions for exploring my organization's incident data.",
  },
  {
    displayText:
      "Create a bar chart showing monthly workload, split by incident severity",
    promptText:
      "Create a 6-month stacked bar chart showing monthly workload, split by incident severity.",
  },
  {
    displayText:
      "Visualize Time to Resolution (TTR) in a boxplot, split by incident severity",
    promptText:
      "Visualize Time to Resolution (TTR) in a boxplot, split by incident severity.",
  },
];

// AssistantSuggestions is a block of suggested questions that the user can click on to ask the
// Assistant, instead of typing their own question.
export const AssistantSuggestions = ({
  submitQuestion,
}: {
  submitQuestion: (question: string) => void;
}) => {
  return (
    <div className="mb-2">
      <Txt className="mb-2 font-medium text-slate-600 text-sm pl-1.5">
        Suggestions
      </Txt>
      {starterQuestions.map(
        (q: { displayText: string; promptText: string }, i: number) => (
          <Button
            key={i}
            className="w-full p-1.5 rounded font-medium !no-underline group text-left md:justify-between max-md:items-start"
            theme={ButtonTheme.Ghost}
            icon={IconEnum.Sparkles}
            analyticsTrackingId="ask-suggested-question"
            onClick={() => submitQuestion(q.promptText)}
          >
            {q.displayText}
            <Icon
              id={IconEnum.ArrowCircleUp}
              size={IconSize.Medium}
              className="mr-1 hidden md:block ml-auto opacity-0 group-hover:opacity-100 transition"
            />
          </Button>
        ),
      )}
    </div>
  );
};
