/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Priority,
    PriorityFromJSON,
    PriorityFromJSONTyped,
    PriorityToJSON,
} from './Priority';

/**
 * 
 * @export
 * @interface AlertsListPrioritiesResponseBody
 */
export interface AlertsListPrioritiesResponseBody {
    /**
     * 
     * @type {Array<Priority>}
     * @memberof AlertsListPrioritiesResponseBody
     */
    priorities: Array<Priority>;
}

export function AlertsListPrioritiesResponseBodyFromJSON(json: any): AlertsListPrioritiesResponseBody {
    return AlertsListPrioritiesResponseBodyFromJSONTyped(json, false);
}

export function AlertsListPrioritiesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsListPrioritiesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priorities': ((json['priorities'] as Array<any>).map(PriorityFromJSON)),
    };
}

export function AlertsListPrioritiesResponseBodyToJSON(value?: AlertsListPrioritiesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priorities': ((value.priorities as Array<any>).map(PriorityToJSON)),
    };
}

