/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TutorialsCreateResponseBody
 */
export interface TutorialsCreateResponseBody {
    /**
     * The name of the Slack channel
     * @type {string}
     * @memberof TutorialsCreateResponseBody
     */
    channel_name: string;
    /**
     * The URL to open up the tutorial channel
     * @type {string}
     * @memberof TutorialsCreateResponseBody
     */
    channel_url: string;
}

export function TutorialsCreateResponseBodyFromJSON(json: any): TutorialsCreateResponseBody {
    return TutorialsCreateResponseBodyFromJSONTyped(json, false);
}

export function TutorialsCreateResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialsCreateResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel_name': json['channel_name'],
        'channel_url': json['channel_url'],
    };
}

export function TutorialsCreateResponseBodyToJSON(value?: TutorialsCreateResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_name': value.channel_name,
        'channel_url': value.channel_url,
    };
}

