/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DebriefsUpdateCalendarIDRequestBody
 */
export interface DebriefsUpdateCalendarIDRequestBody {
    /**
     * The google calendar ID we should prefill the event with, when should_use_calendar_id is true
     * @type {string}
     * @memberof DebriefsUpdateCalendarIDRequestBody
     */
    calendar_id?: string;
    /**
     * Whether or not we should prefill a debrief event with a particular calendar ID
     * @type {boolean}
     * @memberof DebriefsUpdateCalendarIDRequestBody
     */
    should_use_calendar_id?: boolean;
}

export function DebriefsUpdateCalendarIDRequestBodyFromJSON(json: any): DebriefsUpdateCalendarIDRequestBody {
    return DebriefsUpdateCalendarIDRequestBodyFromJSONTyped(json, false);
}

export function DebriefsUpdateCalendarIDRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsUpdateCalendarIDRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendar_id': !exists(json, 'calendar_id') ? undefined : json['calendar_id'],
        'should_use_calendar_id': !exists(json, 'should_use_calendar_id') ? undefined : json['should_use_calendar_id'],
    };
}

export function DebriefsUpdateCalendarIDRequestBodyToJSON(value?: DebriefsUpdateCalendarIDRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendar_id': value.calendar_id,
        'should_use_calendar_id': value.should_use_calendar_id,
    };
}

