import { AlertRoute, AlertSourceConfig } from "@incident-io/api";
import { Handle, Position } from "reactflow";

import { tcx } from "../../../utils/tailwind-classes";
import { AlertRouteItem } from "./AlertRouteItem";
import { AlertRoutesEmptyState } from "./AlertRoutesEmptyState";
import { AlertRoutesHeader } from "./AlertRoutesHeader";
import { AlertSourceItem } from "./AlertSourceItem";
import { AlertSourcesHeader } from "./AlertSourcesHeader";

export enum AlertsConfigurationNodeType {
  SourcesHeader = "sources_header",
  RoutesHeader = "routes_header",
  NoAlertRoutes = "no_alert_routes",
  AlertSource = "alert_source",
  AlertRoute = "alert_route",
}

export interface AlertsConfigurationNodeData {
  nodeType: AlertsConfigurationNodeType;
  label: string;
  alertSource?: AlertSourceConfig;
  alertRoute?: AlertRoute;
  allAlertSources?: AlertSourceConfig[];
  allAlertRoutes?: AlertRoute[];
  searchValue?: string;
  setSearchValue?: (string) => void;
  reduceOpacity?: boolean;
}

export const AlertsConfigurationNode = ({
  id,
  data,
}: {
  id: string;
  data: AlertsConfigurationNodeData;
}) => {
  const render = () => {
    switch (data.nodeType) {
      case "sources_header":
        if (data.searchValue == null || data.searchValue === undefined) {
          throw new Error("Unreachable: search value is missing");
        }

        if (!data.setSearchValue) {
          throw new Error("Unreachable: search value is missing");
        }

        return (
          <AlertSourcesHeader
            search={data.searchValue}
            setSearch={data.setSearchValue}
          />
        );
      case "routes_header":
        if (data.searchValue == null || data.searchValue === undefined) {
          throw new Error("Unreachable: search value is missing");
        }

        if (!data.setSearchValue) {
          throw new Error("Unreachable: search value is missing");
        }

        if (!data.allAlertSources || !data.allAlertRoutes) {
          throw new Error(
            "Unreachable: alert sources or alert routes are missing",
          );
        }

        return (
          <AlertRoutesHeader
            alertSources={data.allAlertSources}
            alertRoutes={data.allAlertRoutes}
            search={data.searchValue}
            setSearch={data.setSearchValue}
          />
        );
      case "no_alert_routes":
        return <AlertRoutesEmptyState />;
      case "alert_source":
        if (!data.alertSource) {
          throw new Error("Unreachable: alert source data is missing");
        }
        if (!data.allAlertRoutes) {
          throw new Error("Unreachable: alert routes are missing");
        }
        if (!data.allAlertSources) {
          throw new Error("Unreachable: alert sources are missing");
        }

        return (
          <div
            className={tcx({
              "opacity-20": data.reduceOpacity,
            })}
            style={{
              transition: "opacity 0.2s ease-in-out",
            }}
          >
            <AlertSourceItem
              alertSourceConfig={data.alertSource}
              alertSources={data.allAlertSources}
              alertRoutes={data.allAlertRoutes}
            />
          </div>
        );
      case "alert_route":
        if (!data.alertRoute) {
          throw new Error("Unreachable: alert route data is missing");
        }

        return (
          <div
            className={tcx({
              "opacity-20": data.reduceOpacity,
            })}
            style={{
              transition: "opacity 0.2s ease-in-out",
            }}
          >
            <AlertRouteItem alertRoute={data.alertRoute} />
          </div>
        );
      default:
        return (
          <div>
            {data.label} {id}
          </div>
        );
    }
  };

  return (
    <div className="cursor-default">
      <Handle
        type="target"
        position={Position.Left}
        style={{
          opacity: 0,
          left: 10,
          cursor: "default",
          pointerEvents: "none",
        }}
      />
      {render()}
      <Handle
        type="source"
        position={Position.Right}
        style={{
          opacity: 0,
          right: 10,
          cursor: "default",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};
