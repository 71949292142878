import { PolicyReportSchedule } from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { Badge, BadgeTheme, ContentBox } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import { PolicyCapsTxt } from "../create-edit/PolicyEditableSection";
import { policyChannelsDescription } from "../policy-report/view/PolicyReportList";
import { createPolicyReportScheduleDescription } from "./PolicyReportDescription";

export const PolicyReportSchedulePreview = ({
  reportSchedules,
  miniBadge = true,
}: {
  reportSchedules: PolicyReportSchedule[];
  miniBadge?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-4">
      {reportSchedules.map((report) => (
        <div key={report.id} className="flex flex-col gap-2">
          <EngineLiteralBadge label={report.name} noTooltip />
          <ContentBox className="p-3">
            <PolicyReportScheduleSummary
              report={report}
              miniBadge={miniBadge}
            />
          </ContentBox>
        </div>
      ))}
    </div>
  );
};

export const PolicyReportScheduleSummary = ({
  report,
  withReportingVerb = true,
  miniBadge = true,
  whiteBadge = false,
}: {
  report: PolicyReportSchedule;
  withReportingVerb?: boolean;
  miniBadge?: boolean;
  whiteBadge?: boolean;
}) => {
  return (
    <div className="flex flex-wrap items-center gap-1">
      <PolicyCapsTxt>{withReportingVerb && "REPORTING"} to </PolicyCapsTxt>
      <span>
        <Badge theme={whiteBadge ? BadgeTheme.Secondary : BadgeTheme.Tertiary}>
          {policyChannelsDescription(
            report,
            tcx(
              "!text-content-primary",
              miniBadge ? "!text-xs-med" : "!text-sm-med",
            ),
          )}
        </Badge>
      </span>
      <PolicyCapsTxt> EVERY </PolicyCapsTxt>
      <span>
        <EngineLiteralBadge
          label={createPolicyReportScheduleDescription(report)}
          noTooltip
          mini={miniBadge}
          className={
            whiteBadge ? "!bg-surface-primary !border !border-stroke" : ""
          }
        />
      </span>
    </div>
  );
};
