import type { SVGProps } from "react";
import * as React from "react";
const SvgShow = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.003 16C6 16 3.562 13.209 2.465 11.545a2.8 2.8 0 0 1 0-3.091C3.562 6.79 6 3.999 10.003 3.999s6.441 2.791 7.538 4.455a2.8 2.8 0 0 1 0 3.09C16.444 13.208 14.006 16 10.003 16Zm0-10.5c-3.301 0-5.355 2.369-6.286 3.78a1.322 1.322 0 0 0 0 1.439c.931 1.412 2.985 3.781 6.286 3.781 3.301 0 5.355-2.369 6.286-3.78a1.322 1.322 0 0 0 0-1.439C15.358 7.869 13.304 5.5 10.003 5.5Z"
    />
    <path
      fill="currentColor"
      d="M10.003 13.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
    />
  </svg>
);
export default SvgShow;
