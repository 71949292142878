import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum } from "@incident-ui";
import { ScopeNameEnum } from "src/contexts/ClientContext";

export const EscalateButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <GatedButton
      analyticsTrackingId="create-escalation-modal-open"
      theme={ButtonTheme.Primary}
      icon={IconEnum.Escalate}
      onClick={onClick}
      requiredScope={ScopeNameEnum.EscalationsCreate}
    >
      <span className="mobile-hidden">Escalate to someone</span>
    </GatedButton>
  );
};
