import type { SVGProps } from "react";
import * as React from "react";
const SvgHome = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16.309 6.603-5.251-3.99a1.76 1.76 0 0 0-2.118 0l-5.251 3.99C3.257 6.933 3 7.454 3 7.996v7.254A2.752 2.752 0 0 0 5.75 18h8.499a2.752 2.752 0 0 0 2.75-2.75V7.996c0-.542-.258-1.062-.69-1.393ZM13.249 15h-6.5a.75.75 0 0 1 0-1.5h6.5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgHome;
