/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertsMarkSuggestionResponseBody
 */
export interface AlertsMarkSuggestionResponseBody {
    /**
     * The ID of the attribute this suggestion should be bound to, if the suggestion was accepted
     * @type {string}
     * @memberof AlertsMarkSuggestionResponseBody
     */
    attribute_id?: string;
}

export function AlertsMarkSuggestionResponseBodyFromJSON(json: any): AlertsMarkSuggestionResponseBody {
    return AlertsMarkSuggestionResponseBodyFromJSONTyped(json, false);
}

export function AlertsMarkSuggestionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsMarkSuggestionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute_id': !exists(json, 'attribute_id') ? undefined : json['attribute_id'],
    };
}

export function AlertsMarkSuggestionResponseBodyToJSON(value?: AlertsMarkSuggestionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute_id': value.attribute_id,
    };
}

