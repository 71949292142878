/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageComponentImpactPayload
 */
export interface StatusPageComponentImpactPayload {
    /**
     * The component that is impacted. For a standalone page, this is the ID of a status page component; for a parent page this is a catalog entry ID
     * @type {string}
     * @memberof StatusPageComponentImpactPayload
     */
    component_id: string;
    /**
     * When the component left this status. If this is null, the impact is ongoing.
     * @type {Date}
     * @memberof StatusPageComponentImpactPayload
     */
    end_at?: Date;
    /**
     * When the component entered this status
     * @type {Date}
     * @memberof StatusPageComponentImpactPayload
     */
    start_at: Date;
    /**
     * The status of the impacted component during this time period
     * @type {string}
     * @memberof StatusPageComponentImpactPayload
     */
    status: StatusPageComponentImpactPayloadStatusEnum;
    /**
     * The ID of the status page incident that caused this impact
     * @type {string}
     * @memberof StatusPageComponentImpactPayload
     */
    status_page_incident_id: string;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageComponentImpactPayloadStatusEnum {
    UnderMaintenance = 'under_maintenance',
    DegradedPerformance = 'degraded_performance',
    PartialOutage = 'partial_outage',
    FullOutage = 'full_outage'
}

export function StatusPageComponentImpactPayloadFromJSON(json: any): StatusPageComponentImpactPayload {
    return StatusPageComponentImpactPayloadFromJSONTyped(json, false);
}

export function StatusPageComponentImpactPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageComponentImpactPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component_id': json['component_id'],
        'end_at': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'start_at': (new Date(json['start_at'])),
        'status': json['status'],
        'status_page_incident_id': json['status_page_incident_id'],
    };
}

export function StatusPageComponentImpactPayloadToJSON(value?: StatusPageComponentImpactPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_id': value.component_id,
        'end_at': value.end_at === undefined ? undefined : (value.end_at.toISOString()),
        'start_at': (value.start_at.toISOString()),
        'status': value.status,
        'status_page_incident_id': value.status_page_incident_id,
    };
}

