import { EngineFormElement } from "@incident-shared/engine";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import {
  EngineScope,
  IssueTrackersLinearCreateIssueTemplateRequestBodyContextEnum,
  LinearIssueTemplate,
  Resource,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useFollowUpScope } from "src/hooks/useFollowUpScope";
import { useScopeResources } from "src/hooks/useResources";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

const LINEAR_TEAM_TYPE = `CatalogEntry["LinearTeam"]`;
const LINEAR_PROJECT_TYPE = `CatalogEntry["LinearProject"]`;
const LINEAR_USER_TYPE = `CatalogEntry["LinearUser"]`;
const LINEAR_LABEL_TYPE = "LinearWorkspaceLabel";

export const LinearIssueTemplateCreateEditModal = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate(`/settings/follow-ups`);

  const { id } = useParams();

  const { data: initialData, isLoading: linearIssueTemplateLoading } = useAPI(
    id ? "issueTrackersLinearGetIssueTemplate" : null,
    { id: id ?? "" },
  );

  const { scope, scopeLoading, scopeError } = useFollowUpScope();

  const { resources, resourcesLoading, resourcesError } = useScopeResources(
    scope,
    [
      LINEAR_TEAM_TYPE,
      LINEAR_PROJECT_TYPE,
      LINEAR_USER_TYPE,
      LINEAR_LABEL_TYPE,
    ],
  );

  if (resourcesError || scopeError) {
    return <GenericErrorMessage error={resourcesError || scopeError} />;
  }
  if (linearIssueTemplateLoading || scopeLoading || resourcesLoading) {
    return <LoadingModal onClose={onClose} isExtraLarge />;
  }

  const props: CreateEditFormProps<LinearIssueTemplate> =
    initialData?.issue_template
      ? { mode: Mode.Edit, initialData: initialData.issue_template }
      : { mode: Mode.Create };

  return (
    <LinearIssueTemplateCreateEditFormInner
      {...props}
      followUpScope={scope}
      resources={resources}
      onClose={onClose}
    />
  );
};

type FormData = LinearIssueTemplate & {
  expressions: ExpressionFormData[];
};

const LinearIssueTemplateCreateEditFormInner = ({
  initialData,
  mode,
  followUpScope,
  resources: resources,
  onClose,
}: {
  onClose: () => void;
  followUpScope: EngineScope;
  resources: Resource[];
} & CreateEditFormProps<LinearIssueTemplate>): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const formMethods = useForm<FormData>({
    defaultValues: { expressions: [], ...initialData } as FormData,
  });

  const expressionsMethods = useFieldArray<FormData, "expressions", "key">({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });

  const refetchTemplates = useAPIRefetch("issueTrackerIssueTemplatesList", {});

  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const {
    trigger: onEdit,
    isMutating: savingEdit,
    genericError: editError,
  } = useAPIMutation(
    "issueTrackersLinearGetIssueTemplate",
    // @ts-expect-error initial data will always be defined when editing
    { id: initialData?.id },
    async (apiClient, data: FormData) => {
      if (initialData === undefined) {
        throw new Error("IssueTemplate should never be undefined when editing");
      }

      return await apiClient.issueTrackersLinearUpdateIssueTemplateV2({
        id: initialData.id,
        linearUpdateIssueTemplateV2RequestBody: {
          ...data,
          enabled: true,
          expressions: (data.expressions || []).map(expressionToPayload),
        },
      });
    },
    {
      onSuccess: () => {
        refetchTemplates();
        onClose();
      },
      setError,
    },
  );

  const {
    trigger: onCreate,
    isMutating: savingCreate,
    genericError: createError,
  } = useAPIMutation(
    "issueTrackerIssueTemplatesList",
    {},
    async (apiClient, data: FormData) => {
      await apiClient.issueTrackersLinearCreateIssueTemplate({
        linearCreateIssueTemplateRequestBody: {
          ...data,
          enabled: true,
          expressions: (data.expressions || []).map(expressionToPayload),
          context:
            IssueTrackersLinearCreateIssueTemplateRequestBodyContextEnum.FollowUp,
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
      },
      setError,
    },
  );

  const sharedFormElementProps = {
    array: false,
    scope: followUpScope,
    required: false,
    showPlaceholder: true,
    disabled: !canEditSettings,
    resources,
    mode: "variables_and_expressions" as const,
  };

  const isEditing = mode === Mode.Edit;

  return (
    <Form.Modal
      formMethods={formMethods}
      title={
        isEditing
          ? `Edit Linear export template`
          : `Add a Linear export template`
      }
      analyticsTrackingId="edit-issue-tracker-template-linear"
      isExtraLarge
      onClose={onClose}
      onSubmit={isEditing ? onEdit : onCreate}
      genericError={isEditing ? editError : createError}
      footer={
        <ModalFooter
          confirmButtonText={isEditing ? "Save" : "Create"}
          confirmButtonType="submit"
          saving={savingEdit || savingCreate}
          onClose={onClose}
          disabled={!isDirty && !isEditing}
        />
      }
    >
      <ExpressionsMethodsProvider
        expressionsMethods={expressionsMethods}
        allowAllOfACatalogType={false}
      >
        <InputV2
          formMethods={formMethods}
          name="name"
          label="Export Template Name"
          placeholder="Enter a name for your template"
          required="Please provide a name for your template"
        />
        <EngineFormElement
          name={`team_id`}
          resourceType={LINEAR_TEAM_TYPE}
          label="Linear Team"
          {...sharedFormElementProps}
          required={true}
        />
        <div>
          <EngineFormElement
            name={`project_id`}
            resourceType={LINEAR_PROJECT_TYPE}
            label="Linear Project"
            {...sharedFormElementProps}
          />
          <Callout theme={CalloutTheme.Info} className="mt-2">
            Make sure the Linear Project that you select is part of the chosen
            Team, or the configuration won&apos;t work correctly.
          </Callout>
        </div>
        <EngineFormElement
          name={`assignee_id`}
          resourceType={LINEAR_USER_TYPE}
          label="Assignee"
          {...sharedFormElementProps}
        />
        <EngineFormElement
          name={`labels`}
          resourceType={LINEAR_LABEL_TYPE}
          label="Labels"
          {...sharedFormElementProps}
          array={true}
        />
      </ExpressionsMethodsProvider>
    </Form.Modal>
  );
};
