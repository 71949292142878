/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IdentityDismissCtaRequestBody,
    IdentityDismissCtaRequestBodyFromJSON,
    IdentityDismissCtaRequestBodyToJSON,
    IdentityDismissCtaResponseBody,
    IdentityDismissCtaResponseBodyFromJSON,
    IdentityDismissCtaResponseBodyToJSON,
    IdentitySelfResponseBody,
    IdentitySelfResponseBodyFromJSON,
    IdentitySelfResponseBodyToJSON,
    IdentitySessionsListResponseBody,
    IdentitySessionsListResponseBodyFromJSON,
    IdentitySessionsListResponseBodyToJSON,
} from '../models';

export interface IdentityDismissCtaRequest {
    dismissCtaRequestBody: IdentityDismissCtaRequestBody;
}

/**
 * 
 */
export class IdentityApi extends runtime.BaseAPI {

    /**
     * Dismiss CTA by providing the unique reference to that CTA
     * DismissCta Identity
     */
    async identityDismissCtaRaw(requestParameters: IdentityDismissCtaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IdentityDismissCtaResponseBody>> {
        if (requestParameters.dismissCtaRequestBody === null || requestParameters.dismissCtaRequestBody === undefined) {
            throw new runtime.RequiredError('dismissCtaRequestBody','Required parameter requestParameters.dismissCtaRequestBody was null or undefined when calling identityDismissCta.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/identity/self/actions/dismiss_cta`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdentityDismissCtaRequestBodyToJSON(requestParameters.dismissCtaRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityDismissCtaResponseBodyFromJSON(jsonValue));
    }

    /**
     * Dismiss CTA by providing the unique reference to that CTA
     * DismissCta Identity
     */
    async identityDismissCta(requestParameters: IdentityDismissCtaRequest, initOverrides?: RequestInit): Promise<IdentityDismissCtaResponseBody> {
        const response = await this.identityDismissCtaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Dismiss the setup checklist from the dashboard
     * DismissSetupChecklist Identity
     */
    async identityDismissSetupChecklistRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/identity/dismiss_setup_checklist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Dismiss the setup checklist from the dashboard
     * DismissSetupChecklist Identity
     */
    async identityDismissSetupChecklist(initOverrides?: RequestInit): Promise<void> {
        await this.identityDismissSetupChecklistRaw(initOverrides);
    }

    /**
     * Return the currently authenticated session
     * Self Identity
     */
    async identitySelfRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IdentitySelfResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/identity/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentitySelfResponseBodyFromJSON(jsonValue));
    }

    /**
     * Return the currently authenticated session
     * Self Identity
     */
    async identitySelf(initOverrides?: RequestInit): Promise<IdentitySelfResponseBody> {
        const response = await this.identitySelfRaw(initOverrides);
        return await response.value();
    }

    /**
     * Enumerate which organisations the user is currently authenticated with, ordered by last_used descending
     * SessionsList Identity
     */
    async identitySessionsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IdentitySessionsListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/identity/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentitySessionsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enumerate which organisations the user is currently authenticated with, ordered by last_used descending
     * SessionsList Identity
     */
    async identitySessionsList(initOverrides?: RequestInit): Promise<IdentitySessionsListResponseBody> {
        const response = await this.identitySessionsListRaw(initOverrides);
        return await response.value();
    }

}
