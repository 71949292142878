import { Alert, AlertSchema, CatalogResource } from "@incident-io/api";
import { isEmpty } from "@incident-shared/attribute";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  DeprecatedTable,
  IconEnum,
  Txt,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useState } from "react";
import { AttributeEntries } from "src/components/@shared/attribute/AttributeEntries";
import { Sidebar } from "src/components/legacy/incident/sidebar/Components";

export const AttributesSection = ({
  alert,
  resources,
  schema,
}: {
  alert: Alert;
  resources: CatalogResource[];
  schema: AlertSchema;
}) => {
  const [showAttributeDrawer, setShowAttributeDrawer] =
    useState<boolean>(false);

  const nonNullAttributes = schema.attributes.filter((attr) => {
    const setAttr = alert.attribute_values[attr.id];
    if (!setAttr) {
      return false;
    }
    return !isEmpty(setAttr);
  });
  const nonNullAttributesCount = nonNullAttributes.length;

  return (
    <>
      {showAttributeDrawer ? (
        <AttributesDrawer
          alert={alert}
          resources={resources}
          schema={schema}
          onClose={() => setShowAttributeDrawer(false)}
        />
      ) : null}
      <Sidebar.Section
        title="Attributes"
        collapsible={false}
        accessory={
          nonNullAttributesCount > 0 ? (
            <Button
              icon={IconEnum.Maximize}
              theme={ButtonTheme.Naked}
              title="View attributes"
              analyticsTrackingId={"alert-attributes-view-detail"}
              onClick={() => setShowAttributeDrawer(true)}
            />
          ) : null
        }
      >
        {/* Attributes */}
        {nonNullAttributesCount === 0 ? (
          <div className="text-content-secondary">
            No attributes are set for this alert
          </div>
        ) : (
          nonNullAttributes.map((attr) => {
            return (
              <Sidebar.Entry
                key={attr.id}
                label={attr.name}
                className="max-w-full"
                value={
                  <AttributeEntries
                    mode={"engine"}
                    typeName={attr.type}
                    catalogResources={resources}
                    attributeBinding={alert.attribute_values[attr.id]}
                    truncate
                    className="tte"
                  />
                }
              />
            );
          })
        )}
      </Sidebar.Section>
    </>
  );
};

const AttributesDrawer = ({
  alert,
  resources,
  schema,
  onClose,
}: {
  alert: Alert;
  resources: CatalogResource[];
  schema: AlertSchema;
  onClose: () => void;
}) => {
  const nonNullAttributes = schema.attributes.filter((attr) => {
    const setAttr = alert.attribute_values[attr.id];
    if (!setAttr) {
      return false;
    }
    return !isEmpty(setAttr);
  });

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          onClose={onClose}
          title={"Attributes"}
          icon={IconEnum.Tag}
          color={ColorPaletteEnum.Blue}
        />
        <DrawerBody className="overflow-y-auto">
          <DeprecatedTable className="p-3">
            <tbody>
              {nonNullAttributes.map((attr) => {
                return (
                  <tr key={attr.id} className={"!border-0"}>
                    <td className="!py-2">
                      <Txt lightGrey className="max-w-[230px] truncate">
                        {attr.name}
                      </Txt>
                    </td>
                    <td className="w-full !py-2 break-anywhere">
                      <AttributeEntries
                        mode={"engine"}
                        typeName={attr.type}
                        catalogResources={resources}
                        attributeBinding={alert.attribute_values[attr.id]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </DeprecatedTable>
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};
