import { InternalStatusPage } from "@incident-io/api";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

import EmptyStateSVG from "./empty-state.svg";

export const EmptyState = ({
  page,
}: {
  page: InternalStatusPage;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "bg-surface-secondary",
        "md:rounded-2",
        "border-y md:border-x border-stroke",
        "flex flex-col",
        "justify-center self-start",
        "grow",
        "p-6 gap-3",
        "max-w-4xl mx-auto",
      )}
    >
      <div className="flex flex-col lg:flex-row items-center w-full justify-between border-b border-stroke mb-4">
        <div className="flex flex-col items-center lg:items-start gap-4 w-full py-6 lg:py-0">
          <h3 className="font-medium text-content-primary">
            Your internal status page has been created!
          </h3>
          <Button
            openInNewTab
            href={page.page_url}
            analyticsTrackingId={"internal-status-page-see-new-page"}
            className="text-md"
            theme={ButtonTheme.Primary}
          >
            See how it looks
          </Button>
        </div>
        <img src={EmptyStateSVG} />
      </div>

      <div className="flex items-center font-medium text-sm">
        <Icon
          id={IconEnum.Info}
          size={IconSize.Medium}
          className="text-black"
        />
        Adding incidents to your internal status page
      </div>
      <p className="text-sm">
        Incidents can be added to your internal status page using{" "}
        <code>/incident statuspage</code>, or automatically added based on
        automation rules. Status updates shared from Slack are added directly to
        the page.
      </p>
      <div>
        <Button
          href={`/status-pages/internal/${page.id}/settings/automation`}
          analyticsTrackingId={"status-page-set-up-automation-rules"}
        >
          Configure automation rules
        </Button>
      </div>
    </div>
  );
};
