import type { SVGProps } from "react";
import * as React from "react";
const SvgChevronUpDown = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.26 3.22a.75.75 0 0 0-1.06 0L6.95 6.47a.75.75 0 0 0 1.06 1.06l2.72-2.72 2.72 2.72a.749.749 0 1 0 1.06-1.06l-3.25-3.25Zm-4.31 9.81 3.25 3.25a.75.75 0 0 0 1.06 0l3.25-3.25a.75.75 0 1 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronUpDown;
