/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompletionMessage
 */
export interface CompletionMessage {
    /**
     * The content of the request
     * @type {string}
     * @memberof CompletionMessage
     */
    content: string;
    /**
     * The role specified on the request (user or system)
     * @type {string}
     * @memberof CompletionMessage
     */
    role: string;
    /**
     * If this was a tool call, the request and response from that
     * @type {string}
     * @memberof CompletionMessage
     */
    tool_call?: string;
}

export function CompletionMessageFromJSON(json: any): CompletionMessage {
    return CompletionMessageFromJSONTyped(json, false);
}

export function CompletionMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompletionMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'role': json['role'],
        'tool_call': !exists(json, 'tool_call') ? undefined : json['tool_call'],
    };
}

export function CompletionMessageToJSON(value?: CompletionMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'role': value.role,
        'tool_call': value.tool_call,
    };
}

