/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogCreateParameterisedTypeRequestBody
 */
export interface CatalogCreateParameterisedTypeRequestBody {
    /**
     * The ID of the attribute being turned into a type
     * @type {string}
     * @memberof CatalogCreateParameterisedTypeRequestBody
     */
    dynamic_resource_parameter: string;
    /**
     * The registry type this dynamic resource will use
     * @type {string}
     * @memberof CatalogCreateParameterisedTypeRequestBody
     */
    registry_type: string;
}

export function CatalogCreateParameterisedTypeRequestBodyFromJSON(json: any): CatalogCreateParameterisedTypeRequestBody {
    return CatalogCreateParameterisedTypeRequestBodyFromJSONTyped(json, false);
}

export function CatalogCreateParameterisedTypeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogCreateParameterisedTypeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamic_resource_parameter': json['dynamic_resource_parameter'],
        'registry_type': json['registry_type'],
    };
}

export function CatalogCreateParameterisedTypeRequestBodyToJSON(value?: CatalogCreateParameterisedTypeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamic_resource_parameter': value.dynamic_resource_parameter,
        'registry_type': value.registry_type,
    };
}

