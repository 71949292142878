/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageAffectedComponent,
    StatusPageAffectedComponentFromJSON,
    StatusPageAffectedComponentFromJSONTyped,
    StatusPageAffectedComponentToJSON,
} from './StatusPageAffectedComponent';
import {
    StatusPageContentComponentImpact,
    StatusPageContentComponentImpactFromJSON,
    StatusPageContentComponentImpactFromJSONTyped,
    StatusPageContentComponentImpactToJSON,
} from './StatusPageContentComponentImpact';
import {
    StatusPageContentIncidentUpdate,
    StatusPageContentIncidentUpdateFromJSON,
    StatusPageContentIncidentUpdateFromJSONTyped,
    StatusPageContentIncidentUpdateToJSON,
} from './StatusPageContentIncidentUpdate';
import {
    StatusPageContentStatusSummary,
    StatusPageContentStatusSummaryFromJSON,
    StatusPageContentStatusSummaryFromJSONTyped,
    StatusPageContentStatusSummaryToJSON,
} from './StatusPageContentStatusSummary';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface StatusPageContentIncident
 */
export interface StatusPageContentIncident {
    /**
     * A summary of the affected components
     * @type {Array<StatusPageAffectedComponent>}
     * @memberof StatusPageContentIncident
     */
    affected_components: Array<StatusPageAffectedComponent>;
    /**
     * List of component impacts
     * @type {Array<StatusPageContentComponentImpact>}
     * @memberof StatusPageContentIncident
     */
    component_impacts: Array<StatusPageContentComponentImpact>;
    /**
     * A unique ID for this status page incident
     * @type {string}
     * @memberof StatusPageContentIncident
     */
    id: string;
    /**
     * A title for the incident
     * @type {string}
     * @memberof StatusPageContentIncident
     */
    name: string;
    /**
     * When the incident was published
     * @type {string}
     * @memberof StatusPageContentIncident
     */
    published_at: string;
    /**
     * Current status for this incident
     * @type {string}
     * @memberof StatusPageContentIncident
     */
    status: StatusPageContentIncidentStatusEnum;
    /**
     * The ID of the status page this incident is displayed on
     * @type {string}
     * @memberof StatusPageContentIncident
     */
    status_page_id: string;
    /**
     * List of status summaries, showing how to render the incident at different points in time
     * @type {Array<StatusPageContentStatusSummary>}
     * @memberof StatusPageContentIncident
     */
    status_summaries: Array<StatusPageContentStatusSummary>;
    /**
     * The type of incident
     * @type {string}
     * @memberof StatusPageContentIncident
     */
    type: StatusPageContentIncidentTypeEnum;
    /**
     * List of incident updates
     * @type {Array<StatusPageContentIncidentUpdate>}
     * @memberof StatusPageContentIncident
     */
    updates: Array<StatusPageContentIncidentUpdate>;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageContentIncident
     */
    write_up_contents?: TextNode;
    /**
     * The URL of a public write-up for the incident
     * @type {string}
     * @memberof StatusPageContentIncident
     */
    write_up_url?: string;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageContentIncidentStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}/**
* @export
* @enum {string}
*/
export enum StatusPageContentIncidentTypeEnum {
    Incident = 'incident',
    Maintenance = 'maintenance'
}

export function StatusPageContentIncidentFromJSON(json: any): StatusPageContentIncident {
    return StatusPageContentIncidentFromJSONTyped(json, false);
}

export function StatusPageContentIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affected_components': ((json['affected_components'] as Array<any>).map(StatusPageAffectedComponentFromJSON)),
        'component_impacts': ((json['component_impacts'] as Array<any>).map(StatusPageContentComponentImpactFromJSON)),
        'id': json['id'],
        'name': json['name'],
        'published_at': json['published_at'],
        'status': json['status'],
        'status_page_id': json['status_page_id'],
        'status_summaries': ((json['status_summaries'] as Array<any>).map(StatusPageContentStatusSummaryFromJSON)),
        'type': json['type'],
        'updates': ((json['updates'] as Array<any>).map(StatusPageContentIncidentUpdateFromJSON)),
        'write_up_contents': !exists(json, 'write_up_contents') ? undefined : TextNodeFromJSON(json['write_up_contents']),
        'write_up_url': !exists(json, 'write_up_url') ? undefined : json['write_up_url'],
    };
}

export function StatusPageContentIncidentToJSON(value?: StatusPageContentIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affected_components': ((value.affected_components as Array<any>).map(StatusPageAffectedComponentToJSON)),
        'component_impacts': ((value.component_impacts as Array<any>).map(StatusPageContentComponentImpactToJSON)),
        'id': value.id,
        'name': value.name,
        'published_at': value.published_at,
        'status': value.status,
        'status_page_id': value.status_page_id,
        'status_summaries': ((value.status_summaries as Array<any>).map(StatusPageContentStatusSummaryToJSON)),
        'type': value.type,
        'updates': ((value.updates as Array<any>).map(StatusPageContentIncidentUpdateToJSON)),
        'write_up_contents': TextNodeToJSON(value.write_up_contents),
        'write_up_url': value.write_up_url,
    };
}

