import {
  Alert,
  AlertSchema,
  AlertSourceSourceTypeEnum,
  CatalogResource,
  Priority,
} from "@incident-io/api";
import {
  AttributeEntries,
  CatalogEntryBadge,
} from "@incident-shared/attribute";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ContentBox,
  EmptyState,
  IconEnum,
  IconSize,
  LoadingWrapper,
} from "@incident-ui";
import { useAPI } from "src/utils/swr";

import { AlertSourceTypeIconBadge } from "../../common/AlertSourceTypeConfigs";

// Preview how alerts will be parsed either as a table of recent alerts, a
// single alert, or the JSON payload of an alert, controlled by a button group
// in the top left.
//
// This component is what we display in the AlertsPreviewSection component.
export const SingleAlertPreview = ({
  alert,
  resources,
  schema,
  isLoading,
}: {
  alert: Alert;
  resources: CatalogResource[];
  schema: AlertSchema;
  isLoading: boolean;
}) => {
  const {
    data: { priorities },
    isLoading: prioritiesLoading,
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  return (
    <LoadingWrapper loading={isLoading || prioritiesLoading}>
      <ContentBox className="w-full grow flex flex-col gap-6 p-6">
        <div className="flex flex-row gap-4 items-center">
          <AlertSourceTypeIconBadge
            className={"p-2"}
            iconSize={IconSize.Large}
            sourceType={
              alert.alert_source_config
                .source_type as unknown as AlertSourceSourceTypeEnum
            }
          />
          <NativeProperties alert={alert} />
        </div>
        <hr />
        <Attributes
          resources={resources}
          priorities={priorities}
          alert={alert}
          schema={schema}
        />
      </ContentBox>
    </LoadingWrapper>
  );
};

const prettyTimeSince = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}m ago`;
  } else {
    return `${seconds}s ago`;
  }
};

const NativeProperties = ({ alert }: { alert: Alert }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="text-base-bold">{alert.title}</div>
      <div className="flex flex-row gap-2 text-content-secondary">
        <p className="text-xs-med">
          Created from {alert.alert_source_config.name}
        </p>
        <p className="text-xs-med">·</p>
        <p className="text-xs-med">
          {prettyTimeSince(new Date(alert.created_at))}
        </p>
      </div>
    </div>
  );
};

const Attributes = ({
  alert,
  resources,
  schema,
  priorities,
}: {
  alert: Alert;
  resources: CatalogResource[];
  schema: AlertSchema;
  priorities: Priority[];
}) => {
  if (schema.attributes.length === 0) {
    return (
      <EmptyState
        icon={IconEnum.Tag}
        title="You've not configured any attributes yet."
        content="Add attributes to your alerts to pull in more information from your source."
      />
    );
  }

  const priority = priorities.find((p) => p.id === alert.priority_id);

  return (
    <div
      className="grid gap-3"
      style={{
        gridTemplateColumns: "minmax(100px, 30%) auto",
        gridAutoRows: "minmax(28px, auto)",
      }}
    >
      {priority && (
        <>
          <div className="flex items-center truncate text-content-secondary">
            Priority
          </div>
          <div className="w-full break-anywhere">
            <CatalogEntryBadge
              icon={IconEnum.Alert}
              label={priority.name}
              color={ColorPaletteEnum.Yellow}
            />
          </div>
        </>
      )}
      {schema.attributes.map((attr) => {
        return (
          <>
            <div className="flex items-center truncate text-content-secondary">
              {attr.name}
            </div>
            <div className="w-full break-anywhere">
              <AttributeEntries
                mode={"engine"}
                typeName={attr.type}
                catalogResources={resources}
                attributeBinding={alert.attribute_values[attr.id]}
              />
            </div>
          </>
        );
      })}
    </div>
  );
};
