/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentTimestampsUpdateSetByRuleRequestBody
 */
export interface IncidentTimestampsUpdateSetByRuleRequestBody {
    /**
     * ID of the incident status that this rule targets
     * @type {string}
     * @memberof IncidentTimestampsUpdateSetByRuleRequestBody
     */
    set_on_status_id: string;
    /**
     * The transition that this incident timestamp should be set on.
     * @type {string}
     * @memberof IncidentTimestampsUpdateSetByRuleRequestBody
     */
    set_on_transition: IncidentTimestampsUpdateSetByRuleRequestBodySetOnTransitionEnum;
    /**
     * The visit that this incident timestamp should be set on.
     * @type {string}
     * @memberof IncidentTimestampsUpdateSetByRuleRequestBody
     */
    set_on_visit: IncidentTimestampsUpdateSetByRuleRequestBodySetOnVisitEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentTimestampsUpdateSetByRuleRequestBodySetOnTransitionEnum {
    Enter = 'enter',
    Leave = 'leave'
}/**
* @export
* @enum {string}
*/
export enum IncidentTimestampsUpdateSetByRuleRequestBodySetOnVisitEnum {
    First = 'first',
    Last = 'last'
}

export function IncidentTimestampsUpdateSetByRuleRequestBodyFromJSON(json: any): IncidentTimestampsUpdateSetByRuleRequestBody {
    return IncidentTimestampsUpdateSetByRuleRequestBodyFromJSONTyped(json, false);
}

export function IncidentTimestampsUpdateSetByRuleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentTimestampsUpdateSetByRuleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'set_on_status_id': json['set_on_status_id'],
        'set_on_transition': json['set_on_transition'],
        'set_on_visit': json['set_on_visit'],
    };
}

export function IncidentTimestampsUpdateSetByRuleRequestBodyToJSON(value?: IncidentTimestampsUpdateSetByRuleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'set_on_status_id': value.set_on_status_id,
        'set_on_transition': value.set_on_transition,
        'set_on_visit': value.set_on_visit,
    };
}

