import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { Button, ButtonTheme } from "@incident-ui";
import { TextDocument } from "src/contexts/ClientContext";

type Props = {
  summary: TextDocument;
  onStartEditing: () => void;
};

export const SummaryDisplay = ({ summary, onStartEditing }: Props) => {
  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Unstyled}
      onClick={() => onStartEditing()}
      title="Edit summary"
      // We want this styled like a text input you can click
      className="text-left cursor-text"
    >
      <TemplatedTextDisplay
        className="grow"
        value={summary.text_node}
        style={TemplatedTextDisplayStyle.Compact}
      />
    </Button>
  );
};
