import { Icon, IconEnum } from "@incident-ui";
import { ActionStatusEnum as ActionStatusEnum } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import styles from "./Actions.module.scss";

export function ActionStatus({
  status,
}: {
  status: ActionStatusEnum;
}): React.ReactElement | null {
  switch (status) {
    case ActionStatusEnum.Outstanding:
      return (
        <>
          <Icon
            id={IconEnum.Circle}
            className="text-content-primary md:hidden"
          />
          <span
            className={tcx(
              styles.statusText,
              "text-content-primary font-medium",
            )}
          >
            Open
          </span>
        </>
      );
    case ActionStatusEnum.Completed:
      return (
        <>
          <Icon
            className="text-green-content md:hidden"
            id={IconEnum.Success}
          />
          <span
            className={tcx(styles.statusText, "text-green-content font-medium")}
          >
            Completed
          </span>
        </>
      );
    case ActionStatusEnum.NotDoing:
      return (
        <>
          <Icon
            className="text-content-tertiary md:hidden rotate-45"
            id={IconEnum.AddCircle}
          />
          <span
            className={tcx(styles.statusText, "text-slate-700 font-medium")}
          >
            Not doing
          </span>
        </>
      );
    case ActionStatusEnum.Deleted:
      return null;
    default:
      assertUnreachable(status);
  }
  throw new Error("unreachable");
}
