/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeAttributePayload,
    CatalogTypeAttributePayloadFromJSON,
    CatalogTypeAttributePayloadFromJSONTyped,
    CatalogTypeAttributePayloadToJSON,
} from './CatalogTypeAttributePayload';

/**
 * 
 * @export
 * @interface CatalogTypeSchemaPayload
 */
export interface CatalogTypeSchemaPayload {
    /**
     * Attributes of this catalog type
     * @type {Array<CatalogTypeAttributePayload>}
     * @memberof CatalogTypeSchemaPayload
     */
    attributes?: Array<CatalogTypeAttributePayload>;
    /**
     * The version number of this schema
     * @type {number}
     * @memberof CatalogTypeSchemaPayload
     */
    version?: number;
}

export function CatalogTypeSchemaPayloadFromJSON(json: any): CatalogTypeSchemaPayload {
    return CatalogTypeSchemaPayloadFromJSONTyped(json, false);
}

export function CatalogTypeSchemaPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogTypeSchemaPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(CatalogTypeAttributePayloadFromJSON)),
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function CatalogTypeSchemaPayloadToJSON(value?: CatalogTypeSchemaPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(CatalogTypeAttributePayloadToJSON)),
        'version': value.version,
    };
}

