import { Policy, PolicyReportSchedule } from "@incident-io/api";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { AnimatePresence } from "framer-motion";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useOutlet,
  useParams,
} from "react-router";
import { useAPI } from "src/utils/swr";

import { PolicyCreateEditDrawer } from "./common/PolicyCreateEditDrawer";
import { PolicyReportCreateEditDrawer } from "./common/PolicyReportCreateEditDrawer";
import { SettingsPoliciesHome } from "./PolicyPage";
import { PolicyViewPage } from "./view/PolicyViewPage";

export const PolicyRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="" element={<SettingsPolicyPageRoute />}>
        {/* Both the create drawers are placed here as direct children of SettingsPolicyPageRoute
			so that we can a nice exit animation. */}
        <Route path="create" element={<PolicyCreateRoute />} />
        <Route path="reports/create" element={<PolicyReportCreateRoute />} />
      </Route>

      <Route path=":id" element={<PolicyViewPageRoute />}>
        <Route path="view" element={<PolicyViewPageRedirect />} />
        <Route path="edit" element={<PolicyEditRoute />} />
      </Route>

      <Route
        path="reports"
        element={<OrgAwareNavigate to="/settings/policies" />}
      />

      <Route path="reports">
        <Route path=":id/edit" element={<PolicyReportEditRoute />} />
      </Route>

      <Route path="*" element={<OrgAwareNavigate to={"/404"} replace />} />
    </Routes>
  );
};

const PolicyViewPageRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/settings/policies/${id}`} />;
};

const SettingsPolicyPageRoute = () => {
  const drawer = useOutlet();

  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      <SettingsPoliciesHome />
    </>
  );
};

const PolicyViewPageRoute = () => {
  const drawer = useOutlet();

  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      <PolicyViewPage />
    </>
  );
};

const PolicyCreateRoute = () => {
  const navigate = useNavigate();
  const onClose = () => navigate(-1);

  return (
    <PolicyCreateEditDrawer
      title={`Create a new policy`}
      onClose={onClose}
      initialData={{
        mode: Mode.Create,
      }}
    />
  );
};

const PolicyEditRoute = () => {
  const navigate = useNavigate();
  const onClose = () => navigate(-1);

  const { id } = useParams() as { id: string };

  const {
    data: policyData,
    isLoading,
    error,
  } = useAPI(id ? "policiesShow" : null, { id });
  const resource = policyData?.policy;

  const initialData: CreateEditFormProps<Policy> =
    resource && id
      ? { mode: Mode.Edit, initialData: resource }
      : { mode: Mode.Create };

  return (
    <PolicyCreateEditDrawer
      title={`Edit policy`}
      onClose={onClose}
      initialData={initialData}
      loading={isLoading}
      error={error}
    />
  );
};

const PolicyReportCreateRoute = () => {
  const navigate = useNavigate();
  const onClose = () => navigate(-1);

  return (
    <PolicyReportCreateEditDrawer
      title={`Create a new policy report`}
      onClose={onClose}
      initialData={{
        mode: Mode.Create,
      }}
    />
  );
};

const PolicyReportEditRoute = () => {
  const navigate = useNavigate();
  const onClose = () => navigate(-1);

  const { id } = useParams();

  const { data, error, isLoading } = useAPI(
    id ? "policiesShowReportSchedule" : null,
    {
      id: id || "",
    },
  );
  const resource = data?.report_schedule;

  const initialData: CreateEditFormProps<PolicyReportSchedule> =
    resource && id
      ? { mode: Mode.Edit, initialData: resource }
      : { mode: Mode.Create };

  return (
    <PolicyReportCreateEditDrawer
      title={`Edit policy report`}
      onClose={onClose}
      initialData={initialData}
      loading={isLoading}
      error={error}
    />
  );
};
