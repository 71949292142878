import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { useResize } from "@incident-io/status-page-ui/use-resize";
import { countBy, max } from "lodash";
import { RefObject } from "react";
import { getNodesBounds, Node, ReactFlowProps } from "reactflow";

const NODE_WIDTH = 400;
const NODE_HEIGHT = 200;
const CONTAINER_WIDTH_MULTIPLIER = 0.66;
const CONTAINER_HEIGHT_MULTIPLIER = 0.9;
// We need to grow the size of the view downwards and to the right to make sure
// you can navigate everywhere in the grid.
const PADDING_MULTIPLIER = 1;
const VERTICAL_PADDING = NODE_HEIGHT * PADDING_MULTIPLIER;

export const useViewportProps = (
  ref: RefObject<HTMLDivElement>,
  reactFlowNodes: Node[],
  rootNodeId: string,
): [Partial<ReactFlowProps>, boolean] => {
  let { width: containerWidth, height: containerHeight } = useResize(ref);
  const bounds = getNodesBounds(reactFlowNodes);
  // Approximate the size of the container if it is not available.
  if (!containerWidth) {
    containerWidth = window.innerWidth * CONTAINER_WIDTH_MULTIPLIER;
  }
  if (!containerHeight) {
    containerHeight = window.innerHeight * CONTAINER_HEIGHT_MULTIPLIER;
  }

  // Makes sure that you can't pan the grid too far to the left/top
  // Subtract node size to account for the fact that the node position
  // is the top left hand corner
  const translateExtentTopLeft = {
    x: -(containerWidth / 2) - NODE_WIDTH,
    y: -(containerHeight / 2) - NODE_HEIGHT,
  };

  // If there is a condition node, we want to align the grid to the top left hand corner
  const conditionCount =
    countBy(reactFlowNodes, (node) => node.data.nodeType)[NodeTypes.IfElse] ||
    0;

  // The number of condition nodes gives us a good estimate of how wide the grid will be
  // (will always be smaller than this value)
  const horizontalExtension =
    (conditionCount + 1) * NODE_WIDTH * PADDING_MULTIPLIER;

  // The bounds of the grid multiplied by 2 gives us a good estimate of
  // how tall the grid will be (will always be smaller than this value)
  const gridHeight =
    (max([bounds.height * 2, containerHeight]) || 0) + NODE_HEIGHT * 2;

  const translateExtentBottomRight = {
    x: containerWidth / 2 + horizontalExtension,
    y: gridHeight / 2 + VERTICAL_PADDING,
  };

  const firstNode = reactFlowNodes.find((node) => node.id === rootNodeId);
  const firstNodePosition = firstNode?.position ?? { x: 0, y: 0 };

  // If we have an unbranched path, use fitView to easily centre our path.
  // If we have a branched one, instead use defaultViewport to put our start node in the
  // top left of the screen with some padding.
  const positionOptions = {
    defaultViewport: {
      x: -firstNodePosition.x - 150 + containerWidth / 2,
      y: firstNodePosition.y + 50,
      zoom: 1,
    },
  };

  return [
    {
      translateExtent: [
        [translateExtentTopLeft.x, translateExtentTopLeft.y],
        [translateExtentBottomRight.x, translateExtentBottomRight.y],
      ],
      ...positionOptions,
    },
    // On our first render, we might not have built & laid out the graph yet, in
    // which case we can't be sure where the root node will be placed. Therefore
    // we tell the caller to not render the graph until we have this layout info
    // and can configure the viewport correctly.
    firstNode !== undefined,
  ];
};
