/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';

/**
 * 
 * @export
 * @interface PreDefinedUpdateConfig
 */
export interface PreDefinedUpdateConfig {
    /**
     * The name of the resulting status page incident
     * @type {string}
     * @memberof PreDefinedUpdateConfig
     */
    incident_name?: string;
    /**
     * Incident status this template applies for
     * @type {string}
     * @memberof PreDefinedUpdateConfig
     */
    incident_status?: PreDefinedUpdateConfigIncidentStatusEnum;
    /**
     * 
     * @type {TextNode}
     * @memberof PreDefinedUpdateConfig
     */
    message_content: TextNode;
    /**
     * The human-readable name for this template
     * @type {string}
     * @memberof PreDefinedUpdateConfig
     */
    template_name: string;
}

/**
* @export
* @enum {string}
*/
export enum PreDefinedUpdateConfigIncidentStatusEnum {
    Investigating = 'investigating',
    Identified = 'identified',
    Monitoring = 'monitoring',
    Resolved = 'resolved',
    MaintenanceScheduled = 'maintenance_scheduled',
    MaintenanceInProgress = 'maintenance_in_progress',
    MaintenanceComplete = 'maintenance_complete'
}

export function PreDefinedUpdateConfigFromJSON(json: any): PreDefinedUpdateConfig {
    return PreDefinedUpdateConfigFromJSONTyped(json, false);
}

export function PreDefinedUpdateConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreDefinedUpdateConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_name': !exists(json, 'incident_name') ? undefined : json['incident_name'],
        'incident_status': !exists(json, 'incident_status') ? undefined : json['incident_status'],
        'message_content': TextNodeFromJSON(json['message_content']),
        'template_name': json['template_name'],
    };
}

export function PreDefinedUpdateConfigToJSON(value?: PreDefinedUpdateConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_name': value.incident_name,
        'incident_status': value.incident_status,
        'message_content': TextNodeToJSON(value.message_content),
        'template_name': value.template_name,
    };
}

