import type { SVGProps } from "react";
import * as React from "react";
const SvgShortcut = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#58B1E4"
      d="M18 5.997A3.998 3.998 0 0 0 14 2H6c-1.023 0-2.048.39-2.828 1.171a3.994 3.994 0 0 0 0 5.652l1.425 1.424A3.998 3.998 0 0 0 6 17.989h8c1.023 0 2.048-.39 2.828-1.172a3.994 3.994 0 0 0 0-5.652l-1.425-1.423A3.999 3.999 0 0 0 18 5.997ZM4.27 15.722a2.427 2.427 0 0 1-.718-1.729c0-.654.255-1.268.718-1.73a2.434 2.434 0 0 1 1.628-.714l4.894 4.89H6a2.444 2.444 0 0 1-1.73-.717Zm11.46-3.46c.463.463.718 1.076.718 1.73 0 .653-.255 1.268-.718 1.729a2.43 2.43 0 0 1-1.73.716c-.654 0-1.27-.254-1.73-.716l-8-7.993a2.427 2.427 0 0 1-.718-1.73c0-.653.255-1.267.718-1.728A2.43 2.43 0 0 1 6 3.553c.654 0 1.27.254 1.73.717l8 7.992Zm0-4.534a2.434 2.434 0 0 1-1.628.715L9.208 3.551H14c.654 0 1.27.254 1.73.717.463.463.718 1.076.718 1.73a2.43 2.43 0 0 1-.718 1.73Z"
    />
  </svg>
);
export default SvgShortcut;
