/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomFieldsCatalogTypeSuggestionsResponseBody,
    CustomFieldsCatalogTypeSuggestionsResponseBodyFromJSON,
    CustomFieldsCatalogTypeSuggestionsResponseBodyToJSON,
    CustomFieldsConvertToExistingCatalogTypeRequestBody,
    CustomFieldsConvertToExistingCatalogTypeRequestBodyFromJSON,
    CustomFieldsConvertToExistingCatalogTypeRequestBodyToJSON,
    CustomFieldsConvertToExistingCatalogTypeResponseBody,
    CustomFieldsConvertToExistingCatalogTypeResponseBodyFromJSON,
    CustomFieldsConvertToExistingCatalogTypeResponseBodyToJSON,
    CustomFieldsCreateRequestBody,
    CustomFieldsCreateRequestBodyFromJSON,
    CustomFieldsCreateRequestBodyToJSON,
    CustomFieldsCreateResponseBody,
    CustomFieldsCreateResponseBodyFromJSON,
    CustomFieldsCreateResponseBodyToJSON,
    CustomFieldsHydrateOptionsResponseBody,
    CustomFieldsHydrateOptionsResponseBodyFromJSON,
    CustomFieldsHydrateOptionsResponseBodyToJSON,
    CustomFieldsListOptionsRequestBody,
    CustomFieldsListOptionsRequestBodyFromJSON,
    CustomFieldsListOptionsRequestBodyToJSON,
    CustomFieldsListOptionsResponseBody,
    CustomFieldsListOptionsResponseBodyFromJSON,
    CustomFieldsListOptionsResponseBodyToJSON,
    CustomFieldsListResponseBody,
    CustomFieldsListResponseBodyFromJSON,
    CustomFieldsListResponseBodyToJSON,
    CustomFieldsMatchWithCatalogEntriesResponseBody,
    CustomFieldsMatchWithCatalogEntriesResponseBodyFromJSON,
    CustomFieldsMatchWithCatalogEntriesResponseBodyToJSON,
    CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequestBody,
    CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequestBodyFromJSON,
    CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequestBodyToJSON,
    CustomFieldsShowResponseBody,
    CustomFieldsShowResponseBodyFromJSON,
    CustomFieldsShowResponseBodyToJSON,
    CustomFieldsUpdateRanksRequestBody,
    CustomFieldsUpdateRanksRequestBodyFromJSON,
    CustomFieldsUpdateRanksRequestBodyToJSON,
    CustomFieldsUpdateRanksResponseBody,
    CustomFieldsUpdateRanksResponseBodyFromJSON,
    CustomFieldsUpdateRanksResponseBodyToJSON,
    CustomFieldsUpdateRequestBody,
    CustomFieldsUpdateRequestBodyFromJSON,
    CustomFieldsUpdateRequestBodyToJSON,
    CustomFieldsUpdateResponseBody,
    CustomFieldsUpdateResponseBodyFromJSON,
    CustomFieldsUpdateResponseBodyToJSON,
} from '../models';

export interface CustomFieldsCatalogTypeSuggestionsRequest {
    id: string;
}

export interface CustomFieldsConvertToExistingCatalogTypeRequest {
    id: string;
    convertToExistingCatalogTypeRequestBody: CustomFieldsConvertToExistingCatalogTypeRequestBody;
}

export interface CustomFieldsCreateRequest {
    createRequestBody: CustomFieldsCreateRequestBody;
}

export interface CustomFieldsDestroyRequest {
    id: string;
}

export interface CustomFieldsHydrateOptionsRequest {
    id: string;
    idList?: Array<string>;
}

export interface CustomFieldsListOptionsRequest {
    id: string;
    listOptionsRequestBody: CustomFieldsListOptionsRequestBody;
}

export interface CustomFieldsMatchWithCatalogEntriesRequest {
    catalogTypeId: string;
    id: string;
}

export interface CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequest {
    id: string;
    resetManuallyEditedDerivedCustomFieldValuesRequestBody: CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequestBody;
}

export interface CustomFieldsShowRequest {
    id: string;
}

export interface CustomFieldsUpdateRequest {
    id: string;
    updateRequestBody: CustomFieldsUpdateRequestBody;
}

export interface CustomFieldsUpdateRanksRequest {
    updateRanksRequestBody: CustomFieldsUpdateRanksRequestBody;
}

/**
 * 
 */
export class CustomFieldsApi extends runtime.BaseAPI {

    /**
     * List suggested catalog types for a custom field, with scores of how well we can match them
     * CatalogTypeSuggestions Custom Fields
     */
    async customFieldsCatalogTypeSuggestionsRaw(requestParameters: CustomFieldsCatalogTypeSuggestionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsCatalogTypeSuggestionsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsCatalogTypeSuggestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom_fields/{id}/catalog_type_suggestions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsCatalogTypeSuggestionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List suggested catalog types for a custom field, with scores of how well we can match them
     * CatalogTypeSuggestions Custom Fields
     */
    async customFieldsCatalogTypeSuggestions(requestParameters: CustomFieldsCatalogTypeSuggestionsRequest, initOverrides?: RequestInit): Promise<CustomFieldsCatalogTypeSuggestionsResponseBody> {
        const response = await this.customFieldsCatalogTypeSuggestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Convert a custom field to a catalog type, connecting its options to catalog entries
     * ConvertToExistingCatalogType Custom Fields
     */
    async customFieldsConvertToExistingCatalogTypeRaw(requestParameters: CustomFieldsConvertToExistingCatalogTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsConvertToExistingCatalogTypeResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsConvertToExistingCatalogType.');
        }

        if (requestParameters.convertToExistingCatalogTypeRequestBody === null || requestParameters.convertToExistingCatalogTypeRequestBody === undefined) {
            throw new runtime.RequiredError('convertToExistingCatalogTypeRequestBody','Required parameter requestParameters.convertToExistingCatalogTypeRequestBody was null or undefined when calling customFieldsConvertToExistingCatalogType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom_fields/{id}/convert_to_existing_catalog_type`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldsConvertToExistingCatalogTypeRequestBodyToJSON(requestParameters.convertToExistingCatalogTypeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsConvertToExistingCatalogTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Convert a custom field to a catalog type, connecting its options to catalog entries
     * ConvertToExistingCatalogType Custom Fields
     */
    async customFieldsConvertToExistingCatalogType(requestParameters: CustomFieldsConvertToExistingCatalogTypeRequest, initOverrides?: RequestInit): Promise<CustomFieldsConvertToExistingCatalogTypeResponseBody> {
        const response = await this.customFieldsConvertToExistingCatalogTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a custom field
     * Create Custom Fields
     */
    async customFieldsCreateRaw(requestParameters: CustomFieldsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling customFieldsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom_fields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a custom field
     * Create Custom Fields
     */
    async customFieldsCreate(requestParameters: CustomFieldsCreateRequest, initOverrides?: RequestInit): Promise<CustomFieldsCreateResponseBody> {
        const response = await this.customFieldsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a custom field
     * Destroy Custom Fields
     */
    async customFieldsDestroyRaw(requestParameters: CustomFieldsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom_fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a custom field
     * Destroy Custom Fields
     */
    async customFieldsDestroy(requestParameters: CustomFieldsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customFieldsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Hydrate values for a custom field, for use with typeaheads
     * HydrateOptions Custom Fields
     */
    async customFieldsHydrateOptionsRaw(requestParameters: CustomFieldsHydrateOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsHydrateOptionsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsHydrateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.idList) {
            queryParameters['id_list'] = requestParameters.idList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom_fields/{id}/hydrate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsHydrateOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Hydrate values for a custom field, for use with typeaheads
     * HydrateOptions Custom Fields
     */
    async customFieldsHydrateOptions(requestParameters: CustomFieldsHydrateOptionsRequest, initOverrides?: RequestInit): Promise<CustomFieldsHydrateOptionsResponseBody> {
        const response = await this.customFieldsHydrateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all custom fields for an organisation
     * List Custom Fields
     */
    async customFieldsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom_fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all custom fields for an organisation
     * List Custom Fields
     */
    async customFieldsList(initOverrides?: RequestInit): Promise<CustomFieldsListResponseBody> {
        const response = await this.customFieldsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * List options for a custom field, for use with typeaheads
     * ListOptions Custom Fields
     */
    async customFieldsListOptionsRaw(requestParameters: CustomFieldsListOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsListOptionsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsListOptions.');
        }

        if (requestParameters.listOptionsRequestBody === null || requestParameters.listOptionsRequestBody === undefined) {
            throw new runtime.RequiredError('listOptionsRequestBody','Required parameter requestParameters.listOptionsRequestBody was null or undefined when calling customFieldsListOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom_fields/{id}/option_groups`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldsListOptionsRequestBodyToJSON(requestParameters.listOptionsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsListOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options for a custom field, for use with typeaheads
     * ListOptions Custom Fields
     */
    async customFieldsListOptions(requestParameters: CustomFieldsListOptionsRequest, initOverrides?: RequestInit): Promise<CustomFieldsListOptionsResponseBody> {
        const response = await this.customFieldsListOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List suggested catalog entries for a custom field\'s options
     * MatchWithCatalogEntries Custom Fields
     */
    async customFieldsMatchWithCatalogEntriesRaw(requestParameters: CustomFieldsMatchWithCatalogEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsMatchWithCatalogEntriesResponseBody>> {
        if (requestParameters.catalogTypeId === null || requestParameters.catalogTypeId === undefined) {
            throw new runtime.RequiredError('catalogTypeId','Required parameter requestParameters.catalogTypeId was null or undefined when calling customFieldsMatchWithCatalogEntries.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsMatchWithCatalogEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.catalogTypeId !== undefined) {
            queryParameters['catalog_type_id'] = requestParameters.catalogTypeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom_fields/{id}/match_with_catalog_entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsMatchWithCatalogEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List suggested catalog entries for a custom field\'s options
     * MatchWithCatalogEntries Custom Fields
     */
    async customFieldsMatchWithCatalogEntries(requestParameters: CustomFieldsMatchWithCatalogEntriesRequest, initOverrides?: RequestInit): Promise<CustomFieldsMatchWithCatalogEntriesResponseBody> {
        const response = await this.customFieldsMatchWithCatalogEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset manually edited derived custom field values for a given incident and custom field
     * ResetManuallyEditedDerivedCustomFieldValues Custom Fields
     */
    async customFieldsResetManuallyEditedDerivedCustomFieldValuesRaw(requestParameters: CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsResetManuallyEditedDerivedCustomFieldValues.');
        }

        if (requestParameters.resetManuallyEditedDerivedCustomFieldValuesRequestBody === null || requestParameters.resetManuallyEditedDerivedCustomFieldValuesRequestBody === undefined) {
            throw new runtime.RequiredError('resetManuallyEditedDerivedCustomFieldValuesRequestBody','Required parameter requestParameters.resetManuallyEditedDerivedCustomFieldValuesRequestBody was null or undefined when calling customFieldsResetManuallyEditedDerivedCustomFieldValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom_fields/{id}/reset_manually_edited_derived_custom_field_values`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequestBodyToJSON(requestParameters.resetManuallyEditedDerivedCustomFieldValuesRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset manually edited derived custom field values for a given incident and custom field
     * ResetManuallyEditedDerivedCustomFieldValues Custom Fields
     */
    async customFieldsResetManuallyEditedDerivedCustomFieldValues(requestParameters: CustomFieldsResetManuallyEditedDerivedCustomFieldValuesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customFieldsResetManuallyEditedDerivedCustomFieldValuesRaw(requestParameters, initOverrides);
    }

    /**
     * Get details for a custom field
     * Show Custom Fields
     */
    async customFieldsShowRaw(requestParameters: CustomFieldsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom_fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get details for a custom field
     * Show Custom Fields
     */
    async customFieldsShow(requestParameters: CustomFieldsShowRequest, initOverrides?: RequestInit): Promise<CustomFieldsShowResponseBody> {
        const response = await this.customFieldsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a custom field
     * Update Custom Fields
     */
    async customFieldsUpdateRaw(requestParameters: CustomFieldsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customFieldsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling customFieldsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom_fields/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a custom field
     * Update Custom Fields
     */
    async customFieldsUpdate(requestParameters: CustomFieldsUpdateRequest, initOverrides?: RequestInit): Promise<CustomFieldsUpdateResponseBody> {
        const response = await this.customFieldsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing custom fields
     * UpdateRanks Custom Fields
     */
    async customFieldsUpdateRanksRaw(requestParameters: CustomFieldsUpdateRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFieldsUpdateRanksResponseBody>> {
        if (requestParameters.updateRanksRequestBody === null || requestParameters.updateRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updateRanksRequestBody','Required parameter requestParameters.updateRanksRequestBody was null or undefined when calling customFieldsUpdateRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom_fields/actions/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldsUpdateRanksRequestBodyToJSON(requestParameters.updateRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldsUpdateRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing custom fields
     * UpdateRanks Custom Fields
     */
    async customFieldsUpdateRanks(requestParameters: CustomFieldsUpdateRanksRequest, initOverrides?: RequestInit): Promise<CustomFieldsUpdateRanksResponseBody> {
        const response = await this.customFieldsUpdateRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
