/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AutoCloseIncidentsConfig,
    AutoCloseIncidentsConfigFromJSON,
    AutoCloseIncidentsConfigFromJSONTyped,
    AutoCloseIncidentsConfigToJSON,
} from './AutoCloseIncidentsConfig';

/**
 * 
 * @export
 * @interface AutoCloseIncidentsSettings
 */
export interface AutoCloseIncidentsSettings {
    /**
     * 
     * @type {AutoCloseIncidentsConfig}
     * @memberof AutoCloseIncidentsSettings
     */
    config?: AutoCloseIncidentsConfig;
    /**
     * Should incidents be automatically closed after a number of days
     * @type {boolean}
     * @memberof AutoCloseIncidentsSettings
     */
    enabled: boolean;
}

export function AutoCloseIncidentsSettingsFromJSON(json: any): AutoCloseIncidentsSettings {
    return AutoCloseIncidentsSettingsFromJSONTyped(json, false);
}

export function AutoCloseIncidentsSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoCloseIncidentsSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : AutoCloseIncidentsConfigFromJSON(json['config']),
        'enabled': json['enabled'],
    };
}

export function AutoCloseIncidentsSettingsToJSON(value?: AutoCloseIncidentsSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': AutoCloseIncidentsConfigToJSON(value.config),
        'enabled': value.enabled,
    };
}

