import type { SVGProps } from "react";
import * as React from "react";
const SvgCloud = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.157 7.326c-1.524-.602-3.389-.296-4.63.819a.746.746 0 0 1-1.055-.058.752.752 0 0 1 .058-1.059c1.347-1.211 3.233-1.702 4.993-1.42C13.61 4.047 11.923 3 10.03 3 7.144 3 4.8 5.355 4.8 8.25c-.001.125.004.25.015.377a3.747 3.747 0 0 0-1.664 6.331A3.687 3.687 0 0 0 5.715 16h7.801C15.99 16 18 13.982 18 11.499a4.48 4.48 0 0 0-2.843-4.173Z"
    />
  </svg>
);
export default SvgCloud;
