import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { Callout, CalloutTheme, ContentBox } from "@incident-ui";
import { Link, ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  CreateEditFormProps,
  Mode,
} from "src/components/@shared/forms/v2/formsv2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import {
  IncidentDurationMetric,
  IncidentDurationMetricCalculationModeEnum as ResponseBodyCalculationMode,
  IncidentDurationMetricMetricTypeEnum,
  IncidentTimestamp,
  IncidentTimestampsCreateDurationMetricRequestBodyCalculationModeEnum as CreateCalculationMode,
  IncidentTimestampsUpdateDurationMetricRequestBody,
  IncidentTimestampsUpdateDurationMetricRequestBodyCalculationModeEnum as UpdateCalculationMode,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

type Props = {
  onClose: () => void;
  timestamps: IncidentTimestamp[];
  includeAdditionalContext?: boolean;
} & CreateEditFormProps<IncidentDurationMetric>;

type FormData = Omit<
  IncidentTimestampsUpdateDurationMetricRequestBody,
  "calculation_mode"
> & { include_paused: boolean };

export const DurationMetricCreateEditModal = ({
  onClose,
  initialData,
  mode,
  timestamps,
  includeAdditionalContext,
}: Props): React.ReactElement | null => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      validate: false,
      ...initialData,
      include_paused:
        initialData?.calculation_mode ===
        ResponseBodyCalculationMode.IncludePaused,
    },
  });

  const {
    trigger: onSubmit,
    genericError,
    isMutating: saving,
  } = useAPIMutation(
    "incidentTimestampsListDurationMetrics",
    undefined,
    async (apiClient, data: FormData) => {
      switch (mode) {
        case Mode.Edit:
          // Patch for a react-hook-form bug where it doesn't correctly load
          // the default values into state.
          if (
            data.name == null &&
            initialData.metric_type ===
              IncidentDurationMetricMetricTypeEnum.Lasted
          ) {
            data.name = initialData.name;
          }

          await apiClient.incidentTimestampsUpdateDurationMetric({
            id: initialData.id,
            updateDurationMetricRequestBody: {
              ...data,
              calculation_mode: data.include_paused
                ? UpdateCalculationMode.IncludePaused
                : UpdateCalculationMode.ExcludePaused,
            },
          });
          break;

        case Mode.Create:
          await apiClient.incidentTimestampsCreateDurationMetric({
            createDurationMetricRequestBody: {
              ...data,
              calculation_mode: data.include_paused
                ? CreateCalculationMode.IncludePaused
                : CreateCalculationMode.ExcludePaused,
            },
          });
          break;
      }
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const isLastedMetric =
    initialData?.metric_type === IncidentDurationMetricMetricTypeEnum.Lasted;

  const timestampOptions = timestamps.map((timestamp) => ({
    label: timestamp.name,
    value: timestamp.id,
    sort_key: timestamp.rank.toString().padStart(4),
  }));

  const fromTimestampID = formMethods.watch("from_timestamp_id");
  const toTimestampID = formMethods.watch("to_timestamp_id");

  const fromTimestamp =
    fromTimestampID &&
    timestamps.find((timestamp) => timestamp.id === fromTimestampID);
  const toTimestamp =
    toTimestampID &&
    timestamps.find((timestamp) => timestamp.id === toTimestampID);

  const exampleOfInvalid =
    toTimestamp && fromTimestamp ? (
      <span>
        For example, when <b>{fromTimestamp.name}</b> is after{" "}
        <b>{toTimestamp.name}</b>.
      </span>
    ) : (
      ``
    );

  return (
    <Form.Modal<FormData>
      formMethods={formMethods}
      onSubmit={onSubmit}
      onClose={onClose}
      analyticsTrackingId="create-edit-duration-metric"
      title={
        mode === Mode.Edit ? "Edit duration metric" : "Create duration metric"
      }
      disableQuickClose
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          confirmButtonText="Save"
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      {includeAdditionalContext && (
        <>
          <p className="text-sm text-slate-700">
            {`Duration metrics track the difference between two timestamps.
              You'll be able to see them on the incident homepage, and use them in insights.
            You can learn more about them in `}
            <Link
              href="/settings/lifecycle"
              openInNewTab
              analyticsTrackingId={null}
            >
              Lifecycle settings
            </Link>
          </p>
          <hr />
        </>
      )}
      <Callout theme={CalloutTheme.Plain}>
        After {mode === Mode.Edit ? "editing" : "creating"} a duration metric,
        please allow some time for the durations to be{" "}
        {mode === Mode.Edit ? "re-calculated" : "calculated"} for each incident.
      </Callout>
      <InputV2
        formMethods={formMethods}
        name="name"
        label="Name"
        disabled={isLastedMetric}
        required="Please name this metric"
        placeholder="e.g. Time to fix"
      />
      {isLastedMetric ? (
        <div className="text-slate-700 text-xs mt-1">
          {`This metric is used to determine how long an incident lasted (which is shown on the incidents page), and cannot be renamed.`}
        </div>
      ) : null}
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="Start of the duration"
        required
        name="from_timestamp_id"
        options={timestampOptions}
        placeholder="Select a timestamp"
        isClearable={false}
      />
      <StaticSingleSelectV2
        formMethods={formMethods}
        label="End of the duration"
        required
        name="to_timestamp_id"
        options={timestampOptions}
        placeholder="Select a timestamp"
        isClearable={false}
      />
      <div className="flex flex-col gap-2">
        <Form.Label htmlFor={"validate"}>Validation</Form.Label>
        <ContentBox className="p-4 flex flex-col gap-2 bg-surface-secondary">
          <ToggleV2
            formMethods={formMethods}
            name="validate"
            align="left"
            label="Enable validation"
          />
          <div className="text-content-secondary">
            When validation is enabled, we’ll notify the incident channel and
            show you a warning on the incident homepage if the duration becomes
            invalid. {exampleOfInvalid}
            <p className="pt-4">
              <b>Note:</b> Metrics for retrospective incidents will not be
              validated.
            </p>
          </div>
        </ContentBox>
      </div>
      <div className="flex flex-col gap-2">
        <Form.Label htmlFor={"include_paused"}>Paused incidents</Form.Label>
        <ContentBox className="p-4 flex flex-col gap-2 bg-surface-secondary">
          <ToggleV2
            formMethods={formMethods}
            name="include_paused"
            label="Include time that an incident was paused"
            align="left"
          />
          <div className="text-content-secondary">
            By default, we exclude paused time from metrics, but you can choose
            to include it here.
          </div>
        </ContentBox>
      </div>
    </Form.Modal>
  );
};
