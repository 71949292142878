import type { SVGProps } from "react";
import * as React from "react";
const SvgRefresh2 = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 9a.75.75 0 0 0-.75.75v3c0 .689-.561 1.25-1.25 1.25H5.561l1.22-1.22a.75.75 0 1 0-1.061-1.061l-2.5 2.5a.75.75 0 0 0 0 1.06l2.5 2.5a.748.748 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-1.22-1.22h8.69a2.752 2.752 0 0 0 2.75-2.75v-3a.75.75 0 0 0-.75-.75ZM3.75 11a.75.75 0 0 0 .75-.75v-3C4.5 6.56 5.061 6 5.75 6h8.689l-1.22 1.22a.75.75 0 0 0 1.06 1.06l2.5-2.5a.75.75 0 0 0 0-1.06l-2.5-2.5a.75.75 0 1 0-1.061 1.06l1.22 1.22H5.75A2.752 2.752 0 0 0 3 7.25v3c0 .415.336.75.75.75Z"
    />
  </svg>
);
export default SvgRefresh2;
