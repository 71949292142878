import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ConfirmationDialog, IconEnum, Link, LoadingModal } from "@incident-ui";
import React, { useState } from "react";
import {
  ClientType,
  DependentResource,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { SettingsListItem } from "../../../../@shared/settings/SettingsList/SettingsListItem";
import {
  AutoDeletingDependentResourceList,
  groupDependentResources,
} from "../../../DeletionConfirmationModal";

export const IncidentStatusRow = ({
  status,
  onEdit,
  isOnlyStatusInCategory,
}: {
  status: IncidentStatus;
  onEdit: () => void;
  isOnlyStatusInCategory: boolean;
}): React.ReactElement => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  // We have to handle our own delete here, for two reasons:
  // 1. we need to check if any incidents are in this status
  // 2. we want to lazily pull dependent resources: if you change a timestamp so
  //    that it relies on this status, that won't force this component to
  //    refresh. However, the dependent resources have changed. Our current
  //    helpers assume you won't have this issue in a single parent render, but
  //    sadly, we do. Also, given 1, solving this problem here seemed pointless.

  return (
    <div className="flex-col flex-grow">
      <SettingsListItem
        title={status.name}
        description={
          <TemplatedTextDisplay
            value={status.description.text_node}
            style={TemplatedTextDisplayStyle.Compact}
            className="text-content-tertiary text-xs ml-1"
          />
        }
        icon={IconEnum.Status}
        buttons={{
          requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
          edit:
            status.category === IncidentStatusCategoryEnum.Closed
              ? undefined
              : { onEdit },
          delete: {
            isGatedText: isOnlyStatusInCategory
              ? `You must have at least one ${status.category} status`
              : undefined,
            onDelete: () => setShowConfirmDeleteModal(true),
            // We're handling our own deleteConfirmation, sadly.
            noDeleteConfirmation: true,
          },
        }}
      />
      {showConfirmDeleteModal && (
        <DeleteStatusModal
          status={status}
          onDelete={(client) =>
            client.incidentLifecyclesDestroyStatus({ id: status.id })
          }
          onClose={() => setShowConfirmDeleteModal(false)}
        />
      )}
    </div>
  );
};

export const DeleteStatusModal = ({
  status,
  onDelete,
  onClose,
}: {
  status: IncidentStatus;
  onDelete: (apiClient: ClientType) => Promise<void>;
  onClose: () => void;
}): React.ReactElement => {
  // We need to check dependent resources, as usual. We show a loading spinner
  // even if we have the data, but it's a bit out of date. It's quite important
  // that this info is very fresh.
  const {
    data: { dependent_resources: dependentResources },
    isValidating: loadingDependentResources,
  } = useAPI(
    "engineFindDependentResourcesForMultiple",
    {
      findDependentResourcesForMultipleRequestBody: {
        resources: [{ resource_type: "IncidentStatus", id: status.id }],
      },
    },
    { fallbackData: { dependent_resources: [] } },
  );

  const { requiresDeletionResources, autoDeletingResources } =
    groupDependentResources(dependentResources ?? []);

  const refetchFlows = useAPIRefetch("postIncidentFlowList", undefined);

  const { trigger: deleteItem, isMutating: isDeleting } = useAPIMutation(
    "incidentLifecyclesList",
    undefined,
    async (apiClient) => {
      await onDelete(apiClient);
    },
    {
      onSuccess: () => {
        // The flows may have changed too
        refetchFlows();
        onClose();
      },
    },
  );

  if (loadingDependentResources) {
    return <LoadingModal title="Delete status" isOpen onClose={onClose} />;
  }

  if (dependentResources && dependentResources.length > 0) {
    return (
      <ConfirmationDialog
        title="Delete status"
        analyticsTrackingId="delete-status-dependent-resources"
        isOpen
        onCancel={onClose}
        hideConfirmButton // so all confirm configuration is moot
        onConfirm={() => {
          return;
        }} // do nothing!
        cancelButtonText="OK"
      >
        <DependentResourceList
          title={status.name}
          requiresDeletionResources={requiresDeletionResources}
        />
      </ConfirmationDialog>
    );
  }

  // If it's a closed status, we do an extra check to make sure there aren't any
  // incidents currently in this state.
  if (status.category === IncidentStatusCategoryEnum.Closed) {
    return (
      <DeleteClosedStatusModal
        status={status}
        isDeleting={isDeleting}
        onClose={onClose}
        onDelete={() => deleteItem({ id: status.id })}
        autoDeletingResources={autoDeletingResources}
      />
    );
  }

  return (
    <IncidentStatusDeleteConfirmation
      status={status}
      isDeleting={isDeleting}
      onClose={onClose}
      onDelete={() => deleteItem({ id: status.id })}
      autoDeletingResources={autoDeletingResources}
    />
  );
};

const DeleteClosedStatusModal = ({
  status,
  onDelete,
  onClose,
  isDeleting,
  autoDeletingResources,
}: {
  status: IncidentStatus;
  onClose: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  autoDeletingResources: DependentResource[][];
}): React.ReactElement => {
  // We also need to check if any incidents have this status
  const { data: incidentResp, isLoading: loadingIncidents } = useAPI(
    "incidentsList",
    {
      // there's no advantage to loading loads of these, and serializing them is slow, so let's just request the first ten and do a 'more' in the UI.
      pageSize: 10,
      status: {
        one_of: [status.id],
      },
      includeTest: {
        is: true,
      },
      includePrivate: {
        is: true, // this won't include ALL private incidents, but we'll at least help you out with the ones you can see.
      },
    },
  );

  if (loadingIncidents) {
    return <LoadingModal title="Delete status" isOpen onClose={onClose} />;
  }

  if (incidentResp && incidentResp.pagination_meta) {
    const incidentCount = incidentResp.pagination_meta.total_record_count;
    if (incidentCount) {
      return (
        <ConfirmationDialog
          title="Delete status"
          analyticsTrackingId="delete-status-incident"
          isOpen
          onCancel={onClose}
          hideConfirmButton // so all confirm configuration is moot
          onConfirm={() => {
            return;
          }} // do nothing!
          cancelButtonText="OK"
        >
          <div className="space-y-2">
            <p>
              We can&apos;t delete <b>{status.name}</b>,
              {` as there ${
                incidentCount === 1 ? "is" : "are"
              } ${incidentCount} incident${
                incidentCount === 1 ? "" : "s"
              } currently in this status:`}
            </p>
            <ul>
              {incidentResp.incidents.map((incident) => (
                <li key={incident.id} className="list-disc list-inside">
                  <Link
                    openInNewTab
                    href={`/incidents/${incident.id}`}
                    analyticsTrackingId={null}
                  >
                    {`#${incident.reference}: ${incident.name}`}
                  </Link>
                </li>
              ))}
              {incidentCount > incidentResp.pagination_meta?.page_size && (
                <p>...</p>
              )}
            </ul>
            <p>
              Change the status of these incidents using{" "}
              <code>/incident update</code>, and then try again.
            </p>
          </div>
        </ConfirmationDialog>
      );
    }
  }

  return (
    <IncidentStatusDeleteConfirmation
      status={status}
      isDeleting={isDeleting}
      onClose={onClose}
      onDelete={onDelete}
      autoDeletingResources={autoDeletingResources}
    />
  );
};

const IncidentStatusDeleteConfirmation = ({
  status,
  onClose,
  isDeleting,
  onDelete,
  autoDeletingResources,
}: {
  status: IncidentStatus;
  onClose: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  autoDeletingResources: DependentResource[][];
}): React.ReactElement => {
  return (
    <ConfirmationDialog
      title="Delete status"
      analyticsTrackingId="delete-status-confirm"
      isOpen
      onCancel={onClose}
      onConfirm={onDelete}
      saving={isDeleting}
    >
      <>
        Are you sure you want to delete the{" "}
        <span className="font-bold">{status.name}</span> status?
        <AutoDeletingDependentResourceList
          autoDeletingResources={autoDeletingResources}
        />
      </>
    </ConfirmationDialog>
  );
};
