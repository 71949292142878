import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export enum StepStatus {
  Complete = "complete",
  Current = "current",
  Upcoming = "upcoming",
}

export type StepConfig<StepID extends string> = {
  id: StepID;
  name: string;
  hidden?: boolean;
  label?: React.ReactNode;
};

export const getStepStatus = <StepID extends string>(
  step: StepConfig<StepID>,
  currentStepID: StepID,
  allSteps: StepConfig<StepID>[],
): StepStatus => {
  const currentStepIndex = allSteps.findIndex((x) => x.id === currentStepID);
  const thisStepIndex = allSteps.findIndex((x) => x.id === step.id);

  if (currentStepIndex === thisStepIndex) {
    return StepStatus.Current;
  } else if (
    currentStepIndex >= 0 &&
    thisStepIndex >= 0 &&
    currentStepIndex < thisStepIndex
  ) {
    return StepStatus.Upcoming;
  } else {
    return StepStatus.Complete;
  }
};

export type StepsProps<StepID extends string> = {
  steps: StepConfig<StepID>[];
  currentStep: StepID;
  hideNumbers?: boolean;
};

// N.B. Consider using NakedSteps instead
export const Steps = <StepID extends string>({
  steps,
  currentStep,
  hideNumbers,
  containerClassName,
}: StepsProps<StepID> & {
  containerClassName?: string;
}): React.ReactElement => {
  return (
    <nav aria-label="Progress" className={containerClassName}>
      <div className="text-sm flex items-center border border-stroke bg-white rounded-full relative items px-4 w-full">
        {steps.map((step, stepIdx) => (
          <StepListItem
            key={step.name}
            step={step}
            idx={stepIdx}
            status={getStepStatus(step, currentStep, steps)}
            hideNumber={hideNumbers}
          />
        ))}
      </div>
    </nav>
  );
};

const StepListItem = <StepID extends string>({
  step,
  status,
  idx,
  hideNumber,
}: {
  step: StepConfig<StepID>;
  status: StepStatus;
  idx: number;
  hideNumber?: boolean;
}): React.ReactElement => {
  return (
    <>
      {idx > 0 && (
        <Icon
          id={IconEnum.StatusChevron}
          size={IconSize.XL}
          className="grow-0 text-slate-200"
        />
      )}
      <div
        className={tcx("grow text-center text-content-tertiary", {
          "text-content-primary font-medium": status === StepStatus.Current,
        })}
      >
        {hideNumber ? step.name : `${idx + 1}. ${step.name}`}
      </div>
    </>
  );
};
