import type { SVGProps } from "react";
import * as React from "react";
const SvgFileMinus = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.25 16.5A2.253 2.253 0 0 1 10 14.25c0-1.241 1.01-2.25 2.25-2.25H16V7.664c0-.467-.182-.907-.513-1.237l-3.914-3.914A1.738 1.738 0 0 0 10.336 2H4.75A2.752 2.752 0 0 0 2 4.75v10.5A2.752 2.752 0 0 0 4.75 18h8.5a2.742 2.742 0 0 0 2.435-1.5H12.25ZM10.5 3.579l.013-.005 3.922 3.921-.002.005h-2.932c-.55 0-1-.45-1-1V3.579H10.5ZM5.75 7h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1 0-1.5ZM5 10.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"
    />
    <path
      fill="currentColor"
      d="M17.25 15h-5a.75.75 0 0 1 0-1.5h5a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgFileMinus;
