import { ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum } from "@incident-ui";
import React from "react";
import graphic from "src/components/settings/banners/banner-roles.png";

import { LevelUpIncidentResponseBanner } from "../LevelUpIncidentResponseBanner";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { IncidentRoleList } from "./IncidentRoleList";

export const IncidentRoleListPage = (): React.ReactElement => {
  return (
    <SettingsSubPageWrapper
      accessory={
        <GatedButton
          href="/settings/incident-roles/create"
          requiredProduct={Product.Response}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          analyticsTrackingId="add-incident-role"
          icon={IconEnum.Add}
          theme={ButtonTheme.Primary}
        >
          Add role
        </GatedButton>
      }
    >
      <SettingsHeading
        graphic={<img src={graphic} className="h-40" />}
        title="Define clear roles"
        subtitle="Assign roles to ensure everyone knows their responsibilities during an incident."
        learnMoreURL="https://incident.io/guide/foundations/roles"
      />
      <IncidentRoleList />
      <LevelUpIncidentResponseBanner />
    </SettingsSubPageWrapper>
  );
};
