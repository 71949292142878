import type { SVGProps } from "react";
import * as React from "react";
const SvgNumberBox2Filled = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#212121"
      fillRule="evenodd"
      d="M14.722 2.222H5.278a3.058 3.058 0 0 0-3.056 3.056v9.444a3.058 3.058 0 0 0 3.056 3.056h9.444a3.058 3.058 0 0 0 3.056-3.056V5.278a3.058 3.058 0 0 0-3.056-3.056ZM7.39 12.972c0 .371.23.63.693.63h3.902c.38 0 .61-.24.61-.586 0-.356-.23-.586-.61-.586H9.328v-.029l1.524-1.465c1.152-1.089 1.572-1.626 1.572-2.485 0-1.211-1.006-2.051-2.5-2.051-1.333 0-2.217.728-2.456 1.509a.984.984 0 0 0-.054.317c0 .366.235.6.63.6.318 0 .489-.136.655-.429.254-.566.644-.85 1.21-.85.63 0 1.08.416 1.08.997 0 .507-.225.85-1.094 1.684l-2.1 2.007c-.298.273-.405.459-.405.737Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNumberBox2Filled;
