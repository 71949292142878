import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  IconEnum,
  Modal,
  ModalContent,
  StackedList,
  StackedListButton,
} from "@incident-ui";

import { CreateModalProps } from "../view/StatusPagesHomepage";

export const PAGE_TYPE_TO_ICON = {
  public: IconEnum.Globe,
  customer: IconEnum.Users,
  internal: IconEnum.LockClosed,
  standalone: IconEnum.Globe,
  subpage: IconEnum.Globe,
};

export const ChoosePageTypeModal = (props: CreateModalProps) => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/status-pages");

  return (
    <Modal
      isOpen
      title={"Create status page"}
      analyticsTrackingId="create-status-page-choose"
      onClose={onClose}
    >
      <ModalContent className="p-4">
        <StackedList>
          <StackedListButton
            key="public"
            analyticsTrackingId={"status-page-select-public"}
            icon={PAGE_TYPE_TO_ICON["public"]}
            title="Public status page"
            description="Effortlessly communicate real-time status updates publicly to all your users."
            href={"/status-pages/create-public"}
            disabled={!props.canCreatePublicPages}
          />
          <StackedListButton
            title="Customer status page"
            analyticsTrackingId={"status-page-select-customer"}
            icon={PAGE_TYPE_TO_ICON["customer"]}
            description="Securely provide private status updates to each of your customers."
            href={"/status-pages/customer/create"}
            disabled={!props.canCreateCustomerPages}
          />
          <StackedListButton
            key="internal"
            analyticsTrackingId={"status-page-select-internal"}
            icon={PAGE_TYPE_TO_ICON["internal"]}
            title="Internal status page"
            description="Share active status updates with your internal employees, only accessible to your incident.io users."
            href={"/status-pages/internal/create"}
            disabled={!props.canCreateInternalPages}
          />
        </StackedList>
      </ModalContent>
    </Modal>
  );
};
