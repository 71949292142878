/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectedUser
 */
export interface ConnectedUser {
    /**
     * The email address of the connected user/service account
     * @type {string}
     * @memberof ConnectedUser
     */
    email: string;
    /**
     * The Microsoft User ID of the connected user/service account
     * @type {string}
     * @memberof ConnectedUser
     */
    id: string;
    /**
     * The Microsoft name of the connected user/service account
     * @type {string}
     * @memberof ConnectedUser
     */
    name: string;
}

export function ConnectedUserFromJSON(json: any): ConnectedUser {
    return ConnectedUserFromJSONTyped(json, false);
}

export function ConnectedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function ConnectedUserToJSON(value?: ConnectedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'id': value.id,
        'name': value.name,
    };
}

