/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationPagerDutyOptions
 */
export interface EscalationPagerDutyOptions {
    /**
     * ID of a priority in PagerDuty to assign the escalation
     * @type {string}
     * @memberof EscalationPagerDutyOptions
     */
    priority_id?: string;
    /**
     * Urgency level to escalate at.
     * @type {string}
     * @memberof EscalationPagerDutyOptions
     */
    urgency?: EscalationPagerDutyOptionsUrgencyEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPagerDutyOptionsUrgencyEnum {
    High = 'high',
    Low = 'low'
}

export function EscalationPagerDutyOptionsFromJSON(json: any): EscalationPagerDutyOptions {
    return EscalationPagerDutyOptionsFromJSONTyped(json, false);
}

export function EscalationPagerDutyOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPagerDutyOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priority_id': !exists(json, 'priority_id') ? undefined : json['priority_id'],
        'urgency': !exists(json, 'urgency') ? undefined : json['urgency'],
    };
}

export function EscalationPagerDutyOptionsToJSON(value?: EscalationPagerDutyOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priority_id': value.priority_id,
        'urgency': value.urgency,
    };
}

