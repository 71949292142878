/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamSourceOfTruthResource,
    TeamSourceOfTruthResourceFromJSON,
    TeamSourceOfTruthResourceFromJSONTyped,
    TeamSourceOfTruthResourceToJSON,
} from './TeamSourceOfTruthResource';

/**
 * 
 * @export
 * @interface CatalogListTeamSourceOfTruthResourcesResponseBody
 */
export interface CatalogListTeamSourceOfTruthResourcesResponseBody {
    /**
     * 
     * @type {Array<TeamSourceOfTruthResource>}
     * @memberof CatalogListTeamSourceOfTruthResourcesResponseBody
     */
    resources: Array<TeamSourceOfTruthResource>;
}

export function CatalogListTeamSourceOfTruthResourcesResponseBodyFromJSON(json: any): CatalogListTeamSourceOfTruthResourcesResponseBody {
    return CatalogListTeamSourceOfTruthResourcesResponseBodyFromJSONTyped(json, false);
}

export function CatalogListTeamSourceOfTruthResourcesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogListTeamSourceOfTruthResourcesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(TeamSourceOfTruthResourceFromJSON)),
    };
}

export function CatalogListTeamSourceOfTruthResourcesResponseBodyToJSON(value?: CatalogListTeamSourceOfTruthResourcesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(TeamSourceOfTruthResourceToJSON)),
    };
}

