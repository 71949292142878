/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SAMLSetIsOptionalRequestBody
 */
export interface SAMLSetIsOptionalRequestBody {
    /**
     * Whether users will be able to login via Slack in addition to SAML SSO
     * @type {boolean}
     * @memberof SAMLSetIsOptionalRequestBody
     */
    is_optional: boolean;
}

export function SAMLSetIsOptionalRequestBodyFromJSON(json: any): SAMLSetIsOptionalRequestBody {
    return SAMLSetIsOptionalRequestBodyFromJSONTyped(json, false);
}

export function SAMLSetIsOptionalRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SAMLSetIsOptionalRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_optional': json['is_optional'],
    };
}

export function SAMLSetIsOptionalRequestBodyToJSON(value?: SAMLSetIsOptionalRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_optional': value.is_optional,
    };
}

