/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';

/**
 * 
 * @export
 * @interface SeverityUpdate
 */
export interface SeverityUpdate {
    /**
     * 
     * @type {Actor}
     * @memberof SeverityUpdate
     */
    actor: Actor;
    /**
     * 
     * @type {Severity}
     * @memberof SeverityUpdate
     */
    from?: Severity;
    /**
     * 
     * @type {Severity}
     * @memberof SeverityUpdate
     */
    to?: Severity;
}

export function SeverityUpdateFromJSON(json: any): SeverityUpdate {
    return SeverityUpdateFromJSONTyped(json, false);
}

export function SeverityUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeverityUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': ActorFromJSON(json['actor']),
        'from': !exists(json, 'from') ? undefined : SeverityFromJSON(json['from']),
        'to': !exists(json, 'to') ? undefined : SeverityFromJSON(json['to']),
    };
}

export function SeverityUpdateToJSON(value?: SeverityUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': ActorToJSON(value.actor),
        'from': SeverityToJSON(value.from),
        'to': SeverityToJSON(value.to),
    };
}

