import { TemplatedTextDisplay } from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ContentBox, EmptyState, IconEnum, Loader, Txt } from "@incident-ui";
import { useMemo } from "react";
import {
  IncidentStatusCategoryEnum,
  LegacyPostmortemTemplateBlock,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

export const LegacyPostmortemTemplatePreview = ({
  blocks,
}: {
  blocks: LegacyPostmortemTemplateBlock[] | null;
}): React.ReactElement => {
  const { data: incData, isLoading: loadingIncident } = useAPI(
    "incidentsList",
    {
      pageSize: 1,
      statusCategory: {
        one_of: [IncidentStatusCategoryEnum.Closed],
      },
    },
  );
  const incident = incData?.incidents?.[0];

  const { data: doc, isLoading: loadingDoc } = useAPI(
    incident ? "postmortemsRender" : null,
    useMemo(
      () => ({
        renderRequestBody: {
          incident_id: incident?.id || "",
          postmortem_document_options: {},
          blocks: blocks || undefined,
        },
      }),
      [blocks, incident?.id],
    ),
  );

  if (loadingDoc || loadingIncident) {
    return <Loader />;
  }

  if (!incident) {
    return (
      <EmptyState
        icon={IconEnum.Doc}
        content="You currently have no closed incidents. To preview this post-mortem template you'll need to close an incident first."
      />
    );
  }

  return (
    <>
      {doc ? (
        <>
          <Txt className="m-2">
            Using{" "}
            <Txt inline bold>
              #INC-{incident.external_id}: {incident.name}
            </Txt>
          </Txt>
          <ContentBox>
            <TemplatedTextDisplay value={doc.doc} />
          </ContentBox>
        </>
      ) : (
        <Txt>Unable to generate a preview</Txt>
      )}
    </>
  );
};
