/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShoutoutChannelConfig,
    ShoutoutChannelConfigFromJSON,
    ShoutoutChannelConfigFromJSONTyped,
    ShoutoutChannelConfigToJSON,
} from './ShoutoutChannelConfig';

/**
 * 
 * @export
 * @interface ShoutoutChannelSettings
 */
export interface ShoutoutChannelSettings {
    /**
     * 
     * @type {ShoutoutChannelConfig}
     * @memberof ShoutoutChannelSettings
     */
    config?: ShoutoutChannelConfig;
    /**
     * Should shoutouts also be posted to a shoutout channel
     * @type {boolean}
     * @memberof ShoutoutChannelSettings
     */
    enabled: boolean;
}

export function ShoutoutChannelSettingsFromJSON(json: any): ShoutoutChannelSettings {
    return ShoutoutChannelSettingsFromJSONTyped(json, false);
}

export function ShoutoutChannelSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoutoutChannelSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : ShoutoutChannelConfigFromJSON(json['config']),
        'enabled': json['enabled'],
    };
}

export function ShoutoutChannelSettingsToJSON(value?: ShoutoutChannelSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': ShoutoutChannelConfigToJSON(value.config),
        'enabled': value.enabled,
    };
}

