import { ExternalResourceResourceTypeEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { DropdownMenu, IconEnum } from "@incident-ui";
import { Icon } from "@incident-ui/Icon/Icon";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import { uniq } from "lodash";

import { IconValueButtonItem, Sidebar } from "../Components";
import { LinkBuilder, LinkBuilderProps } from "./builders";

const StatusPageLink = (props: LinkBuilderProps): React.ReactElement => {
  const links = statusPageLinks(props);

  if (links.length === 0) {
    return (
      <Sidebar.Entry
        label="Status page"
        value={<Sidebar.EmptyValue>—</Sidebar.EmptyValue>}
      />
    );
  }

  if (links.length === 1) {
    return (
      <Sidebar.Entry
        label="Status page"
        value={
          <Sidebar.ValueButton
            icon={links[0].icon}
            analyticsTrackingId="incident-sidebar-status-page"
            href={links[0].href}
          >
            {links[0].label}
          </Sidebar.ValueButton>
        }
      />
    );
  }

  return (
    <Sidebar.Entry
      label="Status pages"
      value={
        <DropdownMenu
          triggerButton={
            <Sidebar.IconValueButton
              items={links}
              analyticsTrackingId="incident-sidebar-status-page"
            />
          }
        >
          {links.map((link, idx) => (
            <PopoverItem
              key={idx}
              to={link.href}
              openInNewTab
              analyticsTrackingId="incident-sidebar-status-page"
              icon={link.icon}
              className="truncate"
              suffix={<Icon id={IconEnum.ExternalLink} />}
            >
              <span className="truncate">{link.label}</span>
            </PopoverItem>
          ))}
        </DropdownMenu>
      }
    />
  );
};

type StatusPageItem = IconValueButtonItem & { href: string };

const statusPageLinks = ({
  statusPageIncidents,
  internalStatusPageData,
  attachments,
}: LinkBuilderProps): StatusPageItem[] => {
  const atlassianStatuspageAttachments = attachments.filter(
    (a) =>
      a.resource.resource_type ===
      ExternalResourceResourceTypeEnum.AtlassianStatuspageIncident,
  );
  const includePageName =
    uniq(statusPageIncidents.map((inc) => inc.status_page_id)).length > 1;

  return [
    ...atlassianStatuspageAttachments.map(({ resource }) => ({
      href: resource.permalink,
      label: resource.title,
      icon: IconEnum.AtlassianStatuspage,
    })),
    ...statusPageIncidents.map((spInc) => ({
      href: `/status-pages/${spInc.status_page_id}/incident/${spInc.id}`,
      label: includePageName
        ? `${spInc.status_page.name}: ${spInc.name}`
        : spInc.name,
      icon: IconEnum.StatusPage,
    })),
    ...internalStatusPageData.internal_status_page_incident_links.map(
      (link) => ({
        href: link.url,
        label: link.internal_status_page_name,
        icon: IconEnum.StatusPage,
      }),
    ),
  ];
};

export const StatusPageBuilder: LinkBuilder = {
  Render: StatusPageLink,
  requiredProduct: Product.Response,
};
