/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnnouncementPostFieldOptionParam
 */
export interface AnnouncementPostFieldOptionParam {
    /**
     * Emoji for this field
     * @type {string}
     * @memberof AnnouncementPostFieldOptionParam
     */
    emoji: string;
    /**
     * Type of this field
     * @type {string}
     * @memberof AnnouncementPostFieldOptionParam
     */
    field_type: AnnouncementPostFieldOptionParamFieldTypeEnum;
    /**
     * Title of this field
     * @type {string}
     * @memberof AnnouncementPostFieldOptionParam
     */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum AnnouncementPostFieldOptionParamFieldTypeEnum {
    Status = 'announcement_post_fields_status',
    IncidentType = 'announcement_post_fields_incident_type',
    Severity = 'announcement_post_fields_severity',
    Role = 'announcement_post_fields_role',
    Description = 'announcement_post_fields_description',
    CustomField = 'announcement_post_fields_custom_field',
    Timestamp = 'announcement_post_fields_timestamp',
    Creator = 'announcement_post_fields_creator',
    Slack = 'announcement_post_fields_slack'
}

export function AnnouncementPostFieldOptionParamFromJSON(json: any): AnnouncementPostFieldOptionParam {
    return AnnouncementPostFieldOptionParamFromJSONTyped(json, false);
}

export function AnnouncementPostFieldOptionParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPostFieldOptionParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emoji': json['emoji'],
        'field_type': json['field_type'],
        'title': json['title'],
    };
}

export function AnnouncementPostFieldOptionParamToJSON(value?: AnnouncementPostFieldOptionParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emoji': value.emoji,
        'field_type': value.field_type,
        'title': value.title,
    };
}

