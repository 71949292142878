import { CustomField, IncidentsListSortByEnum } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { IncidentCard } from "src/components/legacy/incident-grid/card/IncidentCard";
import { useAPI } from "src/utils/swr";

export const CatalogEntryIncidents = ({
  catalogTypeID,
  catalogEntryID,
}: {
  catalogTypeID: string;
  catalogEntryID: string;
}) => {
  const { data, error, isLoading } = useAPI("customFieldsList", undefined);

  if (error) {
    return (
      <GenericErrorMessage
        error={error}
        description="Unable to fetch custom fields"
      />
    );
  }

  if (isLoading || !data) {
    return null;
  }

  const relevantFields = data.custom_fields.filter((customField) => {
    return customField.catalog_type_id === catalogTypeID;
  });

  if (relevantFields.length === 0) {
    return null;
  }

  return (
    <>
      <div className="border-t border-stroke-primary w-full !my-10" />
      <Heading level={2} size="medium" className={"items-center gap-1"}>
        Related incidents
      </Heading>
      {relevantFields.map((customField) => {
        return (
          <CatalogEntryCustomFieldIncidents
            key={customField.id}
            customField={customField}
            catalogEntryId={catalogEntryID}
            showTitle={relevantFields.length > 1}
          />
        );
      })}
    </>
  );
};

const CatalogEntryCustomFieldIncidents = ({
  customField,
  catalogEntryId,
  showTitle,
}: {
  customField: CustomField;
  catalogEntryId: string;
  showTitle: boolean;
}) => {
  const { data, error, isLoading } = useAPI("incidentsList", {
    pageSize: 2,
    sortBy: IncidentsListSortByEnum.NewestFirst,
    customField: {
      [customField.id]: {
        one_of: [catalogEntryId],
      },
    },
  });

  if (error) {
    return (
      <GenericErrorMessage
        error={error}
        description="Unable to fetch incidents"
      />
    );
  }

  if (isLoading || !data) {
    return <Loader />;
  }

  if (data.incidents.length === 0) {
    return (
      <CatalogEntryCustomFieldEmptyState
        customField={customField}
        showTitle={showTitle}
      />
    );
  }

  const hasMore = (data.pagination_meta?.total_record_count ?? 0) > 2;

  return (
    <>
      {showTitle && (
        <Heading level={3} className={"items-center text-l gap-1"}>
          {customField.name}
        </Heading>
      )}

      <div className="space-y-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 items-stretch auto-rows-fr">
          {data.incidents.map((incident) => (
            <IncidentCard
              key={incident.id}
              incident={incident}
              isGridFirstUpdate={true}
              slackTeamConfig={undefined}
            />
          ))}
        </div>
        {hasMore && (
          <div className="flex flex-row-reverse">
            <Button
              theme={ButtonTheme.Naked}
              href={`/incidents?custom_field[${customField.id}][one_of]=${catalogEntryId}`}
              analyticsTrackingId={null}
            >
              View more
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const CatalogEntryCustomFieldEmptyState = ({
  customField,
  showTitle,
}: {
  customField: CustomField;
  showTitle: boolean;
}) => {
  return (
    <>
      {showTitle && (
        <Heading level={3} className={"items-center text-l gap-1"}>
          {customField.name}
        </Heading>
      )}

      <ContentBox className="bg-surface-secondary min-h-[160px] text-slate-600">
        <div className="max-h-full max-w-full text-center pt-7">
          <Icon
            id={IconEnum.Sparkles}
            size={IconSize.Large}
            className="child:stroke-1.5 mx-auto"
          />
          <div className="text-lg font-semibold pb-2 pt-1">No incidents</div>
          <div className="text-sm max-w-md m-auto">
            You don&apos;t have any incidents with this custom field value
            &mdash; when you do, they&apos;ll appear here
          </div>
        </div>
      </ContentBox>
    </>
  );
};
