import { Schedule } from "@incident-io/api";
import { CalendarSection } from "@incident-shared/schedules/ScheduleOverview/CalendarSection";
import { ScheduleOverviewHeader } from "@incident-shared/schedules/ScheduleOverview/ScheduleOverviewHeader";
import {
  makeQueryParams,
  useScheduleTimeWindowReducer,
} from "@incident-shared/schedules/ScheduleOverview/scheduleTimeWindowReducer";
import { TimelineSection } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import {
  endTimeForTimelinePeriod,
  TimelineCalendarValue,
} from "@incident-shared/schedules/ScheduleOverview/types";
import { GenericErrorMessage } from "@incident-ui";
import { captureException } from "@sentry/core";
import _ from "lodash";
import { useMemo, useRef } from "react";
import {
  getCurrentlyActiveRotaConfigs,
  getUpcomingRotaConfigChanges,
} from "src/components/legacy/on-call/schedules/util";
import { useAPI } from "src/utils/swr";
import { useNow } from "src/utils/use-now";
import { useResize } from "src/utils/use-resize";
import { useLocalStorage } from "use-hooks";

export const ScheduleOverviewV2 = ({ schedule }: { schedule: Schedule }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { width } = useResize(ref);

  const [selectedTimezones, setSelectedTimezones] = useLocalStorage<string[]>(
    `${schedule.id}-selected-timezones`,
    [schedule.timezone],
  );
  const now = useNow(schedule.timezone);

  const [state, dispatch] = useScheduleTimeWindowReducer(now, true);

  const currentEndTime = endTimeForTimelinePeriod({
    from: state.startTime,
    timePeriod: state.timePeriodOption,
  });

  const [from, until] = makeQueryParams(state, schedule.timezone);

  const {
    data: entriesResp,
    isLoading: entriesLoading,
    error: entriesErr,
  } = useAPI("schedulesListEntries", {
    scheduleIds: [schedule.id],
    from,
    until,
  });

  const activeUsersOnSchedules = useMemo(() => {
    return _.uniq([
      ...(getCurrentlyActiveRotaConfigs({
        rotas: schedule.config?.rotations || [],
        now: now,
      })
        .map((r) => r.user_ids)
        .flat() ?? []),
      ...(entriesResp?._final.map((e) => e.external_user_id) ?? []),
      ...(entriesResp?.overrides.map((e) => e.user_id) ?? []),
    ]);
  }, [schedule.config, now, entriesResp]);

  const { data: holidaysResponse } = useAPI(
    "schedulesListHolidayEntries",
    {
      from,
      until,
      countryCodes: schedule.holidays_public_config?.country_codes ?? [],
      userIds: activeUsersOnSchedules,
    },
    {
      fallbackData: {
        holiday_public_entries: [],
        holiday_user_entries: [],
      },
    },
  );

  const upcomingRotaChanges = useMemo(
    () => getUpcomingRotaConfigChanges(schedule.config?.rotations ?? [], now),
    [schedule.config, now],
  );

  const finalEntries = useMemo(
    () =>
      (entriesResp?._final ?? []).map((e) => ({
        ...e,
        user_id: ("user_id" in e ? e.user_id : e.external_user_id) as string,
      })),
    [entriesResp],
  );

  if (entriesErr) {
    captureException(entriesErr);
    return <GenericErrorMessage error={entriesErr} />;
  }

  return (
    <div className="flex flex-col h-full overflow-y-auto" ref={ref}>
      <div className={"px-8 pt-6"}>
        <ScheduleOverviewHeader
          className={"mb-3"}
          timeWindowState={state}
          timeWindowDispatch={dispatch}
          scheduleDefaultTimezone={schedule.timezone}
          showCalendarOption={true}
          selectedTimezones={selectedTimezones}
          setSelectedTimezones={setSelectedTimezones}
        />
      </div>
      <div className={"mt-6 flex-1"}>
        {state.calendarToggle === TimelineCalendarValue.Timeline ? (
          <TimelineSection
            width={width}
            now={now}
            rotations={schedule.config?.rotations ?? []}
            isLoadingEntries={entriesLoading}
            timelineStartPoint={state.startTime}
            timelineEndpoint={currentEndTime}
            timePeriod={state.timePeriodOption}
            entriesResponse={entriesResp}
            scheduleTimezone={schedule.timezone}
            selectedTimezones={selectedTimezones}
            holidaysResponse={holidaysResponse}
            upcomingRotaChanges={upcomingRotaChanges}
            scheduleId={schedule.id}
          />
        ) : (
          <CalendarSection
            now={now}
            timezone={schedule.timezone}
            isLoadingEntries={entriesLoading}
            timelineStartPoint={state.startTime}
            timelineEndpoint={currentEndTime}
            rotations={schedule.config?.rotations ?? []}
            entries={finalEntries}
          />
        )}
      </div>
    </div>
  );
};
