/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MSTeamsPreviewsListResponseBody,
    MSTeamsPreviewsListResponseBodyFromJSON,
    MSTeamsPreviewsListResponseBodyToJSON,
    MSTeamsPreviewsShowResponseBody,
    MSTeamsPreviewsShowResponseBodyFromJSON,
    MSTeamsPreviewsShowResponseBodyToJSON,
} from '../models';

export interface MSTeamsPreviewsShowRequest {
    name: string;
}

/**
 * 
 */
export class MSTeamsPreviewsApi extends runtime.BaseAPI {

    /**
     * List available MS Teams message previews
     * List MSTeamsPreviews
     */
    async mSTeamsPreviewsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<MSTeamsPreviewsListResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ms_teams_previews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MSTeamsPreviewsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List available MS Teams message previews
     * List MSTeamsPreviews
     */
    async mSTeamsPreviewsList(initOverrides?: RequestInit): Promise<MSTeamsPreviewsListResponseBody> {
        const response = await this.mSTeamsPreviewsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Shows a single message
     * Show MSTeamsPreviews
     */
    async mSTeamsPreviewsShowRaw(requestParameters: MSTeamsPreviewsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MSTeamsPreviewsShowResponseBody>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling mSTeamsPreviewsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ms_teams_previews/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MSTeamsPreviewsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Shows a single message
     * Show MSTeamsPreviews
     */
    async mSTeamsPreviewsShow(requestParameters: MSTeamsPreviewsShowRequest, initOverrides?: RequestInit): Promise<MSTeamsPreviewsShowResponseBody> {
        const response = await this.mSTeamsPreviewsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
