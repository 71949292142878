/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface EmailsListRequest {
    triggered?: string;
    toTestService?: boolean;
}

export interface EmailsPreviewCustomRequest {
    name: string;
    plaintext?: boolean;
}

export interface EmailsTriggerRequest {
    name: string;
    toTestService?: boolean;
}

/**
 * 
 */
export class EmailsApi extends runtime.BaseAPI {

    /**
     * List available email templates
     * List Emails
     */
    async emailsListRaw(requestParameters: EmailsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.triggered !== undefined) {
            queryParameters['triggered'] = requestParameters.triggered;
        }

        if (requestParameters.toTestService !== undefined) {
            queryParameters['toTestService'] = requestParameters.toTestService;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * List available email templates
     * List Emails
     */
    async emailsList(requestParameters: EmailsListRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.emailsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview a custom email template
     * PreviewCustom Emails
     */
    async emailsPreviewCustomRaw(requestParameters: EmailsPreviewCustomRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling emailsPreviewCustom.');
        }

        const queryParameters: any = {};

        if (requestParameters.plaintext !== undefined) {
            queryParameters['plaintext'] = requestParameters.plaintext;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/emails/custom/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Preview a custom email template
     * PreviewCustom Emails
     */
    async emailsPreviewCustom(requestParameters: EmailsPreviewCustomRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.emailsPreviewCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger an email to be sent
     * Trigger Emails
     */
    async emailsTriggerRaw(requestParameters: EmailsTriggerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling emailsTrigger.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.toTestService !== undefined) {
            queryParameters['toTestService'] = requestParameters.toTestService;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/emails/trigger`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Trigger an email to be sent
     * Trigger Emails
     */
    async emailsTrigger(requestParameters: EmailsTriggerRequest, initOverrides?: RequestInit): Promise<void> {
        await this.emailsTriggerRaw(requestParameters, initOverrides);
    }

}
