/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InternalStatusPageIncidentLink
 */
export interface InternalStatusPageIncidentLink {
    /**
     * The ID of the internal status page incident. This is different to the ID of the incident itself.
     * @type {string}
     * @memberof InternalStatusPageIncidentLink
     */
    id: string;
    /**
     * The ID of the internal status page this is linked to
     * @type {string}
     * @memberof InternalStatusPageIncidentLink
     */
    internal_status_page_id: string;
    /**
     * The human readable name of the internal status page this is linked to
     * @type {string}
     * @memberof InternalStatusPageIncidentLink
     */
    internal_status_page_name: string;
    /**
     * The unique ID of the linked response incident.
     * @type {string}
     * @memberof InternalStatusPageIncidentLink
     */
    response_incident_id: string;
    /**
     * The url to view the status page incident on the internal status page.
     * @type {string}
     * @memberof InternalStatusPageIncidentLink
     */
    url: string;
}

export function InternalStatusPageIncidentLinkFromJSON(json: any): InternalStatusPageIncidentLink {
    return InternalStatusPageIncidentLinkFromJSONTyped(json, false);
}

export function InternalStatusPageIncidentLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalStatusPageIncidentLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'internal_status_page_id': json['internal_status_page_id'],
        'internal_status_page_name': json['internal_status_page_name'],
        'response_incident_id': json['response_incident_id'],
        'url': json['url'],
    };
}

export function InternalStatusPageIncidentLinkToJSON(value?: InternalStatusPageIncidentLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'internal_status_page_id': value.internal_status_page_id,
        'internal_status_page_name': value.internal_status_page_name,
        'response_incident_id': value.response_incident_id,
        'url': value.url,
    };
}

