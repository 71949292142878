import { IncidentsBuildScopeContextEnum } from "@incident-io/api";
import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers";
import { useFollowUpScope } from "src/hooks/useFollowUpScope";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { assertUnreachable } from "src/utils/utils";

export const useScopeForIssueTemplateContext = (
  context: IssueTemplateContextEnum,
) => {
  const incidentResult = useIncidentScope(
    IncidentsBuildScopeContextEnum.FullScope,
    { skip: context !== IssueTemplateContextEnum.IncidentTicket },
  );
  const followUpResult = useFollowUpScope({
    skip: context !== IssueTemplateContextEnum.FollowUp,
  });

  switch (context) {
    case IssueTemplateContextEnum.IncidentTicket:
      return incidentResult;

    case IssueTemplateContextEnum.FollowUp:
      return followUpResult;

    default:
      return assertUnreachable(context);
  }
};
