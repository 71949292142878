/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementPostActionParam,
    AnnouncementPostActionParamFromJSON,
    AnnouncementPostActionParamFromJSONTyped,
    AnnouncementPostActionParamToJSON,
} from './AnnouncementPostActionParam';
import {
    AnnouncementPostFieldParamWithPreview,
    AnnouncementPostFieldParamWithPreviewFromJSON,
    AnnouncementPostFieldParamWithPreviewFromJSONTyped,
    AnnouncementPostFieldParamWithPreviewToJSON,
} from './AnnouncementPostFieldParamWithPreview';

/**
 * 
 * @export
 * @interface AnnouncementPost
 */
export interface AnnouncementPost {
    /**
     * Actions available in this announcement post
     * @type {Array<AnnouncementPostActionParam>}
     * @memberof AnnouncementPost
     */
    post_actions: Array<AnnouncementPostActionParam>;
    /**
     * Fields available in this announcement post
     * @type {Array<AnnouncementPostFieldParamWithPreview>}
     * @memberof AnnouncementPost
     */
    post_fields: Array<AnnouncementPostFieldParamWithPreview>;
}

export function AnnouncementPostFromJSON(json: any): AnnouncementPost {
    return AnnouncementPostFromJSONTyped(json, false);
}

export function AnnouncementPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'post_actions': ((json['post_actions'] as Array<any>).map(AnnouncementPostActionParamFromJSON)),
        'post_fields': ((json['post_fields'] as Array<any>).map(AnnouncementPostFieldParamWithPreviewFromJSON)),
    };
}

export function AnnouncementPostToJSON(value?: AnnouncementPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'post_actions': ((value.post_actions as Array<any>).map(AnnouncementPostActionParamToJSON)),
        'post_fields': ((value.post_fields as Array<any>).map(AnnouncementPostFieldParamWithPreviewToJSON)),
    };
}

