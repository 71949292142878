import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GatedDropdownMenuItem,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { SummaryContext } from "src/components/legacy/incident/body/SummaryContext";
import { PostIncidentTaskActionItem } from "src/components/legacy/incident/postincidentflow/PostIncidentTaskActionItem";
import { taskCanBeManuallyCompleted } from "src/components/legacy/incident/postincidentflow/taskCanBeManuallyCompleted";
import { usePostIncidentTaskMutations } from "src/components/legacy/incident/postincidentflow/usePostIncidentTaskMutations";
import {
  PostIncidentTask,
  PostIncidentTaskSlim,
  RemindersCreateRequestBodyReminderTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { tcx } from "src/utils/tailwind-classes";

export const PostIncidentTaskOverflow = ({
  task,
  refetchIncidents,
  disabled,
}: {
  task: PostIncidentTaskSlim | PostIncidentTask;
  refetchIncidents: () => Promise<void>;
  disabled?: boolean;
}): React.ReactElement => {
  const [isEditingSummary, setIsEditingSummary] = useState(false);

  const { rejectTask, completeTask } = usePostIncidentTaskMutations({
    task,
    refetchIncidents,
  });

  const cannotSkip = task.config.unskippable;

  return (
    <DropdownMenu
      align="end"
      triggerButton={
        <Button
          size={BadgeSize.Medium}
          theme={ButtonTheme.Tertiary}
          icon={IconEnum.DotsVerticalNopad}
          title=""
          analyticsTrackingId={null}
          disabled={disabled}
        />
      }
    >
      {taskCanBeManuallyCompleted(task.config) && (
        <DropdownMenuItem
          onSelect={() => completeTask({})}
          analyticsTrackingId="post-incident-list-task-complete"
          analyticsTrackingMetadata={{
            incident_task_id: task.id,
          }}
          icon={IconEnum.Tick}
          label="Complete"
        />
      )}
      {/* Remind */}
      <RemindPostIncidentTaskItem task={task} />
      {/* Skip */}
      <GatedDropdownMenuItem
        onSelect={() => rejectTask({})}
        analyticsTrackingId="post-incident-list-task-reject"
        analyticsTrackingMetadata={{
          incident_task_id: task.id,
        }}
        className={tcx({
          "!bg-surface-secondary": cannotSkip,
        })}
        disabled={cannotSkip}
        explanationText="This task is required"
        icon={IconEnum.Close}
        label="Skip"
      />
      <SummaryContext.Provider
        value={{ setIsEditingSummary, isEditingSummary }}
      >
        <PostIncidentTaskActionItem
          incidentTask={task}
          onTaskComplete={() => {
            completeTask({});
            refetchIncidents();
          }}
          mode="dropdown"
        />
      </SummaryContext.Provider>
    </DropdownMenu>
  );
};

const RemindPostIncidentTaskItem = ({
  task,
  buttonClassName,
}: {
  task: PostIncidentTaskSlim | PostIncidentTask;
  buttonClassName?: string;
}) => {
  const apiClient = useClient();
  const showToast = useToast();

  const [sendReminder] = useMutation(
    async () => {
      await apiClient.remindersCreate({
        createRequestBody: {
          reminder_type:
            RemindersCreateRequestBodyReminderTypeEnum.PostIncidentTask,
          resource_ids: [task.id],
          targets: ["post_incident_task.assignee"],
        },
      });
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Reminder sent",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  const taskPending = !task.completed_at && !task.rejected_at;

  if (!task.assignee || !taskPending) {
    return null;
  }

  return (
    <DropdownMenuItem
      onSelect={() => sendReminder({})}
      analyticsTrackingId="post-incident-task-reminder-send"
      analyticsTrackingMetadata={{
        incident_task_id: task.id,
      }}
      icon={IconEnum.SpeechImportant}
      className={buttonClassName}
      label="Send reminder"
    />
  );
};
