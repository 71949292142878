import {
  InsightsCreateReportScheduleRequestBodyDayOfWeekEnum,
  InsightsCreateReportScheduleRequestBodyIntervalEnum,
  InsightsReportSchedule,
  InsightsReportScheduleDayOfWeekEnum,
  InsightsReportScheduleIntervalEnum,
  InsightsTestInsightsReportRequestBodyChannelEnum,
  InsightsUpdateReportScheduleRequestBodyDayOfWeekEnum,
  InsightsUpdateReportScheduleRequestBodyIntervalEnum,
  ReportChannel,
} from "@incident-io/api";
import { ListEditorValue } from "@incident-shared/forms/v1/ListEditor";
import { ListEditorV2 } from "@incident-shared/forms/v2/editors";
import {
  Button,
  ButtonTheme,
  ErrorMessage,
  Icon,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import { DrawerBody, DrawerFooter } from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { InputType } from "@incident-ui/Input/Input";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { v4 as uuidv4 } from "uuid";

import { InsightsScheduledReportIntervalEditor } from "./InsightsScheduledReportIntervalEditor";

export type InsightsScheduledReportFormData = {
  emails?: ListEditorValue<string>[];
  interval: InsightsReportScheduleIntervalEnum;
  hourString: string;
  dayOfTheWeek?: InsightsReportScheduleDayOfWeekEnum;
  dayOfTheMonthString?: string;
  timezone?: string;
  countryCode?: string;
};

export const InsightsScheduledReportCreateEditForm = ({
  dashboardID,
  dashboardName,
  existingSchedule,
  onClose,
}: {
  dashboardID: string;
  dashboardName: string;
  existingSchedule?: InsightsReportSchedule;
  onClose: () => void;
}) => {
  const showToast = useToast();

  const creating = !existingSchedule;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formMethods = useForm<InsightsScheduledReportFormData>({
    defaultValues: creating
      ? {
          emails: [getDefaultEmailRow()],
          interval: InsightsReportScheduleIntervalEnum.Daily,
          hourString: "9",
          timezone: userTimezone,
          countryCode: "etc", // This appears to be unused
        }
      : {
          emails: existingSchedule?.channels.map((channel, i) => ({
            id: channel.id,
            value: channel.label,
            sort_key: i,
          })),
          interval: existingSchedule.interval,
          hourString: String(existingSchedule.hour),
          dayOfTheWeek: existingSchedule.day_of_week,
          dayOfTheMonthString: existingSchedule.day_of_month
            ? String(existingSchedule.day_of_month)
            : undefined,
          timezone: existingSchedule.time_zone,
          countryCode: existingSchedule.country_code,
        },
  });

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );
  const closeWithWarn = () => onCloseWithWarn(isDirty);

  const {
    trigger: createOrUpdate,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "insightsListReportSchedule",
    undefined,
    async (apiClient, data: InsightsScheduledReportFormData) => {
      if (creating) {
        await apiClient.insightsCreateReportSchedule({
          createReportScheduleRequestBody: {
            dashboard_id: dashboardID,
            ...formStateToPayload(data),
            interval:
              data.interval as unknown as InsightsCreateReportScheduleRequestBodyIntervalEnum,
            day_of_week: data.dayOfTheWeek
              ? (data.dayOfTheWeek as unknown as InsightsCreateReportScheduleRequestBodyDayOfWeekEnum)
              : undefined,
          },
        });
      } else {
        await apiClient.insightsUpdateReportSchedule({
          id: existingSchedule.id,
          updateReportScheduleRequestBody: {
            dashboard_id: dashboardID,
            ...formStateToPayload(data),
            interval:
              data.interval as unknown as InsightsUpdateReportScheduleRequestBodyIntervalEnum,
            day_of_week: data.dayOfTheWeek
              ? (data.dayOfTheWeek as unknown as InsightsUpdateReportScheduleRequestBodyDayOfWeekEnum)
              : undefined,
          },
        });
      }
      onClose(); // Close the drawer
      showToast({
        theme: ToastTheme.Success,
        title: creating ? "Schedule created" : "Schedule updated",
        toastSide: ToastSideEnum.TopRight,
      });
    },
  );

  const onSubmit = (data: InsightsScheduledReportFormData) => {
    if (!data.emails || data.emails.length === 0) {
      formMethods.setError("emails", {
        type: "required",
        message: "Please specify at least one email",
      });
      return;
    }
    for (const email of data.emails) {
      if (email.value === "") {
        formMethods.setError("emails", {
          type: "required",
          message: "Please specify a non-empty email",
        });
        return;
      }
    }
    // Nil out fields only used in certain modes
    if (data.interval !== InsightsReportScheduleIntervalEnum.Weekly) {
      data.dayOfTheWeek = undefined;
    }
    if (data.interval !== InsightsReportScheduleIntervalEnum.Monthly) {
      data.dayOfTheMonthString = undefined;
    }
    createOrUpdate(data);
  };

  return (
    <>
      <DrawerBody className="flex flex-col gap-6">
        <Form.Root
          genericError={genericError}
          onSubmit={onSubmit}
          formMethods={formMethods}
          saving={saving}
          innerClassName="flex flex-col gap-4"
          id="insights-report-create-edit"
        >
          <p className="text-sm-normal">
            Send the contents of{" "}
            <span className="text-sm-med">{dashboardName}</span> to one or more
            people at specified intervals
          </p>
          <Recipients formMethods={formMethods} />
          <Scheduling formMethods={formMethods} />
          <Preview dashboardID={dashboardID} />
        </Form.Root>
      </DrawerBody>
      <DrawerFooterButtons
        onClose={closeWithWarn}
        saving={saving}
        scheduleID={existingSchedule?.id}
      />
    </>
  );
};

const Preview = ({ dashboardID }: { dashboardID: string }) => {
  // As the preview is async and expensive, only allow the user to click it once
  const [previewSent, setPreviewSent] = useState(false);

  const {
    trigger: preview,
    isMutating: queueingPreview,
    genericError,
  } = useAPIMutation(
    "insightsListReportSchedule",
    undefined,
    async (apiClient) => {
      await apiClient
        .insightsTestInsightsReport({
          testInsightsReportRequestBody: {
            custom_dashboard_id: dashboardID,
            channel: InsightsTestInsightsReportRequestBodyChannelEnum.Email,
          },
        })
        .then(() => setPreviewSent(true));
    },
  );

  const { identity } = useIdentity();
  if (!identity) {
    return <></>;
  }

  if (genericError) {
    return <ErrorMessage message={genericError} />;
  }

  return (
    <div className="flex flex-col gap-2 text-sm-normal">
      <div>
        Want a preview?{" "}
        <Button
          analyticsTrackingId={"insights-scheduled-report-preview"}
          className="text-sm-med"
          theme={ButtonTheme.Link}
          onClick={() => preview({})}
          disabled={queueingPreview || previewSent}
        >
          Send a test report to {identity.user_email}
        </Button>
        {previewSent && (
          <Icon
            id={IconEnum.Checkmark}
            className="inline mx-1 text-green-500"
          />
        )}
      </div>
      {previewSent && <div>Previews may take a few minutes to arrive</div>}
    </div>
  );
};

const DrawerFooterButtons = ({
  onClose,
  saving,
  scheduleID,
}: {
  onClose: () => void;
  saving: boolean;
  scheduleID?: string;
}) => {
  const {
    trigger: destroy,
    isMutating: destroying,
    genericError,
  } = useAPIMutation(
    "insightsListReportSchedule",
    undefined,
    async (apiClient) => {
      if (scheduleID) {
        await apiClient.insightsDestroyReportSchedule({
          id: scheduleID || "",
        });
        onClose();
      }
    },
  );

  if (genericError) {
    return <ErrorMessage message={genericError} />;
  }

  return (
    <>
      <DrawerFooter className="flex justify-end gap-2">
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        {scheduleID && (
          <Button
            analyticsTrackingId={null}
            onClick={() => destroy({})}
            theme={ButtonTheme.DestroySecondary}
            disabled={destroying}
            loading={destroying}
            icon={IconEnum.Delete}
          >
            Delete report
          </Button>
        )}
        <Button
          type={"submit"}
          analyticsTrackingId={"insights-scheduled-report-create"}
          theme={ButtonTheme.Primary}
          disabled={saving}
          loading={saving}
          form="insights-report-create-edit"
        >
          {scheduleID ? "Update" : "Schedule"}
        </Button>
      </DrawerFooter>
    </>
  );
};

const Recipients = ({
  formMethods,
}: {
  formMethods: UseFormReturn<InsightsScheduledReportFormData>;
}) => {
  return (
    <div className="mt-4 min-w-lg max-w-lg mr-4 grow">
      <div className="flex flex-row items-center mb-2">
        <Form.Label htmlFor={"email"} className="font-semibold">
          Who would you like to receive the report?
        </Form.Label>
      </div>
      <ListEditorV2
        formMethods={formMethods}
        name="emails"
        getDefaultRow={getDefaultEmailRow}
        rowPlaceholder="e.g. example@email.com"
        onClearErrors={() => formMethods.clearErrors("emails")}
        addNewText="Add another email"
        inputType={InputType.Email}
        allowReordering={false}
      />
    </div>
  );
};

const Scheduling = ({
  formMethods,
}: {
  formMethods: UseFormReturn<InsightsScheduledReportFormData>;
}) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-sm-bold">When would you like the report?</p>
      <InsightsScheduledReportIntervalEditor formMethods={formMethods} />
    </div>
  );
};

const getDefaultEmailRow = () => ({
  id: uuidv4(),
  value: "",
  sort_key: 10000,
});

// formStateToPayload extracts the payload conversion logic that is common
// to both the create and update requests.
const formStateToPayload = (data: InsightsScheduledReportFormData) => ({
  channels:
    data.emails?.map(
      (email) =>
        ({
          id: email.value,
          label: email.value,
          type: "email",
          is_private: false,
        }) as ReportChannel,
    ) || [],
  hour: Number(data.hourString),
  day_of_month: data.dayOfTheMonthString
    ? Number(data.dayOfTheMonthString)
    : undefined,
  time_zone: data.timezone || "Etc/UTC",
  country_code: data.countryCode || "etc",
});
