/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfluencePage,
    ConfluencePageFromJSON,
    ConfluencePageFromJSONTyped,
    ConfluencePageToJSON,
} from './ConfluencePage';

/**
 * 
 * @export
 * @interface AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody
 */
export interface AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody {
    /**
     * 
     * @type {ConfluencePage}
     * @memberof AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody
     */
    page?: ConfluencePage;
    /**
     * ID of the user triggering the webhook
     * @type {string}
     * @memberof AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody
     */
    userAccountId?: string;
}

export function AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBodyFromJSON(json: any): AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody {
    return AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBodyFromJSONTyped(json, false);
}

export function AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : ConfluencePageFromJSON(json['page']),
        'userAccountId': !exists(json, 'userAccountId') ? undefined : json['userAccountId'],
    };
}

export function AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBodyToJSON(value?: AtlassianConnectAppReceiveConfluenceCloudWebhookRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': ConfluencePageToJSON(value.page),
        'userAccountId': value.userAccountId,
    };
}

