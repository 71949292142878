import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { GenericErrorMessage, Loader, Txt } from "@incident-ui";
import { useParams } from "react-router";
import { useAPI } from "src/utils/swr";

import {
  AutoDeletingDependentResourceList,
  groupDependentResources,
} from "../../settings/DeletionConfirmationModal";

export const CatalogTypeDependentResourcesPage = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>() as { id: string };

  const {
    data: typeData,
    isLoading: typeLoading,
    error: typeError,
  } = useAPI("catalogShowType", { id: id });

  if (typeError) {
    return <GenericErrorMessage error={typeError} />;
  }

  if (typeLoading || !typeData) {
    return <Loader />;
  }

  const { requiresDeletionResources, autoDeletingResources } =
    groupDependentResources(typeData.dependent_resources ?? []);

  return (
    <PageWrapper
      width={PageWidth.Narrow}
      icon={typeData.catalog_type.icon}
      color={typeData.catalog_type.color as unknown as ColorPaletteEnum}
      title={`${typeData.catalog_type.name}: Dependent resources`}
      crumbs={[
        {
          title: typeData.catalog_type.name,
          to: `/catalog/${slugForCatalogType(typeData.catalog_type)}`,
        },
      ]}
    >
      <div className="mt-6 space-y-4">
        {requiresDeletionResources.length > 0 && (
          <DependentResourceList
            title={typeData.catalog_type.name}
            requiresDeletionResources={requiresDeletionResources}
          />
        )}
        {autoDeletingResources.length > 0 && (
          <AutoDeletingDependentResourceList
            autoDeletingResources={autoDeletingResources}
          />
        )}
        {requiresDeletionResources.length === 0 &&
          autoDeletingResources.length === 0 && (
            <Txt>
              This catalog type has no dependent resources and is safe to
              delete.
            </Txt>
          )}
      </div>
    </PageWrapper>
  );
};
