/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRouteMostRecentResourceCreated,
    AlertRouteMostRecentResourceCreatedFromJSON,
    AlertRouteMostRecentResourceCreatedFromJSONTyped,
    AlertRouteMostRecentResourceCreatedToJSON,
} from './AlertRouteMostRecentResourceCreated';

/**
 * 
 * @export
 * @interface AlertRouteMostRecentResourcesCreated
 */
export interface AlertRouteMostRecentResourcesCreated {
    /**
     * 
     * @type {AlertRouteMostRecentResourceCreated}
     * @memberof AlertRouteMostRecentResourcesCreated
     */
    escalation: AlertRouteMostRecentResourceCreated;
    /**
     * 
     * @type {AlertRouteMostRecentResourceCreated}
     * @memberof AlertRouteMostRecentResourcesCreated
     */
    incident: AlertRouteMostRecentResourceCreated;
}

export function AlertRouteMostRecentResourcesCreatedFromJSON(json: any): AlertRouteMostRecentResourcesCreated {
    return AlertRouteMostRecentResourcesCreatedFromJSONTyped(json, false);
}

export function AlertRouteMostRecentResourcesCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteMostRecentResourcesCreated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation': AlertRouteMostRecentResourceCreatedFromJSON(json['escalation']),
        'incident': AlertRouteMostRecentResourceCreatedFromJSON(json['incident']),
    };
}

export function AlertRouteMostRecentResourcesCreatedToJSON(value?: AlertRouteMostRecentResourcesCreated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation': AlertRouteMostRecentResourceCreatedToJSON(value.escalation),
        'incident': AlertRouteMostRecentResourceCreatedToJSON(value.incident),
    };
}

