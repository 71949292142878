/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentChannelConfig,
    IncidentChannelConfigFromJSON,
    IncidentChannelConfigFromJSONTyped,
    IncidentChannelConfigToJSON,
} from './IncidentChannelConfig';

/**
 * 
 * @export
 * @interface IncidentChannelSettings
 */
export interface IncidentChannelSettings {
    /**
     * 
     * @type {IncidentChannelConfig}
     * @memberof IncidentChannelSettings
     */
    channel_config: IncidentChannelConfig;
}

export function IncidentChannelSettingsFromJSON(json: any): IncidentChannelSettings {
    return IncidentChannelSettingsFromJSONTyped(json, false);
}

export function IncidentChannelSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentChannelSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel_config': IncidentChannelConfigFromJSON(json['channel_config']),
    };
}

export function IncidentChannelSettingsToJSON(value?: IncidentChannelSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel_config': IncidentChannelConfigToJSON(value.channel_config),
    };
}

