import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GenericErrorMessage } from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { sortBy } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  IncidentTimestampTimestampTypeEnum as TimestampType,
  PostIncidentFlowCreateTaskConfigRequestBodyTaskTypeEnum as TaskType,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { FormDataType } from "./FormDataType";

export const SetTimestampsTaskInputs = () => {
  const formMethods = useFormContext<FormDataType>();
  const taskType = formMethods.watch("task_type");

  const {
    data: fieldsData,
    isLoading,
    error: timestampsError,
  } = useAPI(
    taskType === TaskType.SetTimestamps ? "incidentTimestampsList" : null,
    undefined,
  );

  return taskType === TaskType.SetTimestamps ? (
    <LoadingWrapper loading={isLoading}>
      {timestampsError && <GenericErrorMessage error={timestampsError} />}
      <StaticMultiSelectV2
        formMethods={formMethods}
        label="Timestamps to set"
        name="timestamp_ids"
        options={sortBy(
          fieldsData?.incident_timestamps.filter(
            (timestamp) =>
              ![
                TimestampType.AcceptedAt,
                TimestampType.MergedAt,
                TimestampType.DeclinedAt,
                TimestampType.CanceledAt,
              ].includes(timestamp.timestamp_type),
          ),
          (field) => field.rank,
        ).map((f) => ({
          value: f.id,
          label: f.name,
        }))}
        placeholder="Select timestamps..."
        helptext={
          "We'll check the task off when these timestamps are set. If you leave this empty, someone will need to manually confirm that the timestamps are correct."
        }
      />
    </LoadingWrapper>
  ) : null;
};
