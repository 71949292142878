/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserLink,
    UserLinkFromJSON,
    UserLinkFromJSONTyped,
    UserLinkToJSON,
} from './UserLink';

/**
 * 
 * @export
 * @interface UserLinksListSelfResponseBody
 */
export interface UserLinksListSelfResponseBody {
    /**
     * 
     * @type {Array<UserLink>}
     * @memberof UserLinksListSelfResponseBody
     */
    user_links: Array<UserLink>;
}

export function UserLinksListSelfResponseBodyFromJSON(json: any): UserLinksListSelfResponseBody {
    return UserLinksListSelfResponseBodyFromJSONTyped(json, false);
}

export function UserLinksListSelfResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLinksListSelfResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_links': ((json['user_links'] as Array<any>).map(UserLinkFromJSON)),
    };
}

export function UserLinksListSelfResponseBodyToJSON(value?: UserLinksListSelfResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_links': ((value.user_links as Array<any>).map(UserLinkToJSON)),
    };
}

