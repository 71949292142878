/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogConfigType,
    CatalogConfigTypeFromJSON,
    CatalogConfigTypeFromJSONTyped,
    CatalogConfigTypeToJSON,
} from './CatalogConfigType';

/**
 * 
 * @export
 * @interface CatalogConfig
 */
export interface CatalogConfig {
    /**
     * List of catalog types that provide a link to an escalation path
     * @type {Array<CatalogConfigType>}
     * @memberof CatalogConfig
     */
    escalation_types: Array<CatalogConfigType>;
    /**
     * List of catalog types that are owned by what we think are teams
     * @type {Array<CatalogConfigType>}
     * @memberof CatalogConfig
     */
    owned_types: Array<CatalogConfigType>;
    /**
     * List of catalog types that we believe are teams
     * @type {Array<CatalogConfigType>}
     * @memberof CatalogConfig
     */
    team_types: Array<CatalogConfigType>;
}

export function CatalogConfigFromJSON(json: any): CatalogConfig {
    return CatalogConfigFromJSONTyped(json, false);
}

export function CatalogConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_types': ((json['escalation_types'] as Array<any>).map(CatalogConfigTypeFromJSON)),
        'owned_types': ((json['owned_types'] as Array<any>).map(CatalogConfigTypeFromJSON)),
        'team_types': ((json['team_types'] as Array<any>).map(CatalogConfigTypeFromJSON)),
    };
}

export function CatalogConfigToJSON(value?: CatalogConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_types': ((value.escalation_types as Array<any>).map(CatalogConfigTypeToJSON)),
        'owned_types': ((value.owned_types as Array<any>).map(CatalogConfigTypeToJSON)),
        'team_types': ((value.team_types as Array<any>).map(CatalogConfigTypeToJSON)),
    };
}

