import {
  CatalogEntry,
  CatalogEntryReference,
  CatalogType,
  UserLinkConfirmationStatusEnum as ConfirmationStatus,
} from "@incident-io/api";
import {
  EmptyState,
  GenericErrorMessage,
  Heading,
  IconEnum,
  Tooltip,
} from "@incident-ui";
import { Table, TableHeaderCell } from "@incident-ui/Table/Table";
import React from "react";
import { ConnectAccountButton } from "src/components/user-preferences/connected-accounts/ConnectAccountButton";
import { useAPI } from "src/utils/swr";

import { ConnectedAccountRow } from "./ConnectedAccountRow";

export const CatalogUserEntryConnectedAccountsTable = ({
  entry,
}: {
  entry: CatalogEntry;
}): React.ReactElement => {
  const userID = entry.external_id ? entry.external_id : entry.id;

  const {
    data: userLinksData,
    isLoading: userLinksLoading,
    error: userLinksError,
  } = useAPI(
    "userLinksListUserLinks",
    { userId: userID },
    { fallbackData: { user_links: [] } },
  );

  const {
    data: catalogRelationsData,
    isLoading: catalogRelationsLoading,
    error: catalogRelationsError,
  } = useAPI(
    "catalogListRelations",
    { id: entry.id },
    { fallbackData: { catalog_relations: [] } },
  );

  const confirmedLinks = userLinksData.user_links
    .filter((link) => link.confirmation_status === ConfirmationStatus.Linked)
    .sort((a, b) => {
      return (a.catalog_type || "") < (b.catalog_type || "") ||
        b.catalog_type === "SlackUser"
        ? 1
        : -1;
    });

  const errors = userLinksError || catalogRelationsError;

  if (errors) {
    return <GenericErrorMessage error={errors} />;
  }

  const isLoading = userLinksLoading || catalogRelationsLoading;

  const catalogRelationForCatalogType = (catalogType: string) => {
    return catalogRelationsData.catalog_relations.find(
      (relation) => relation.catalog_type.registry_type === catalogType,
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading level={1} className="flex items-center" size="2xl">
        Connected accounts
        <Tooltip
          analyticsTrackingId={"connected-accounts-help"}
          content={
            <>
              <p>
                If this user has connected accounts with catalog users that
                reference this user, we&apos;ll show them here.
              </p>
            </>
          }
          side="right"
          buttonClassName="ml-1"
        />
      </Heading>
      <Table
        wrappedInBox
        gridTemplateColumns="min-content minmax(auto, 200px) auto minmax(auto, 200px) min-content"
        className="w-fit min-w-full max-w-full"
        loading={isLoading}
        emptyStateRow={
          <EmptyState
            icon={IconEnum.Link}
            content="This user has no connected accounts"
            className="border-none"
          />
        }
        header={<CatalogUserEntryTableHeader />}
        data={confirmedLinks}
        renderRow={(link, idx) => {
          const catalogRelation = catalogRelationForCatalogType(
            link.catalog_type as string,
          );
          const catalogEntry = catalogRelation?.attributes[0].entries.find(
            (entry) => entry.catalog_entry_id === link.catalog_entry_id,
          );
          return (
            <ConnectedAccountRow
              linkID={link.id}
              externalUser={link.external_user}
              catalogEntry={catalogEntry as CatalogEntryReference}
              catalogType={catalogRelation?.catalog_type as CatalogType}
              userID={userID}
              editable={link.editable}
              key={link.id}
              isLastRow={idx === confirmedLinks.length - 1}
            />
          );
        }}
      />
      <div>
        <ConnectAccountButton userIDToLink={userID} />
      </div>
    </div>
  );
};

const CatalogUserEntryTableHeader = () => {
  return (
    <>
      <TableHeaderCell></TableHeaderCell>
      <TableHeaderCell>Name</TableHeaderCell>
      <TableHeaderCell>External ID</TableHeaderCell>
      <TableHeaderCell>Reference</TableHeaderCell>
      <TableHeaderCell> </TableHeaderCell>
    </>
  );
};
