/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogBootstrapServiceTypeRequestBody
 */
export interface CatalogBootstrapServiceTypeRequestBody {
    /**
     * The ID of catalog type we'll use as our canonical service type (e.g. Cortex or OpsLevel). Leave blank to create a new type.
     * @type {string}
     * @memberof CatalogBootstrapServiceTypeRequestBody
     */
    catalog_type_id?: string;
    /**
     * A list of service names which we'll use to create entries for the new type
     * @type {Array<string>}
     * @memberof CatalogBootstrapServiceTypeRequestBody
     */
    manual_entries?: Array<string>;
}

export function CatalogBootstrapServiceTypeRequestBodyFromJSON(json: any): CatalogBootstrapServiceTypeRequestBody {
    return CatalogBootstrapServiceTypeRequestBodyFromJSONTyped(json, false);
}

export function CatalogBootstrapServiceTypeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogBootstrapServiceTypeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_type_id': !exists(json, 'catalog_type_id') ? undefined : json['catalog_type_id'],
        'manual_entries': !exists(json, 'manual_entries') ? undefined : json['manual_entries'],
    };
}

export function CatalogBootstrapServiceTypeRequestBodyToJSON(value?: CatalogBootstrapServiceTypeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_type_id': value.catalog_type_id,
        'manual_entries': value.manual_entries,
    };
}

